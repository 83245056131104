"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseDataStream = void 0;
const json_logic_util_fn_1 = require("../util/json-logic-util.fn");
class BaseDataStream {
    name;
    subscriptionId;
    dateCreated;
    dateLastUpdated;
    target;
    filters;
    authDetails;
    ruleType;
    constructor(props, subscriptionInfo) {
        (this.name = props.name), (this.target = props.target);
        this.subscriptionId = props.subscriptionId;
        this.filters = props.filters;
        this.dateCreated = subscriptionInfo?.dateCreated;
        this.dateLastUpdated = subscriptionInfo?.dateLastUpdated;
        this.authDetails = props.authDetails;
        this.ruleType = props.ruleType;
    }
    toSubscription(excludeRuleType) {
        const serializedFilter = json_logic_util_fn_1.jsonLogicUtil.serialize(this.filters);
        let datastream;
        if (serializedFilter) {
            datastream = {
                subscriptionId: this.subscriptionId,
                name: this.name || "Untitled",
                dataStream: this.id,
                filter: serializedFilter,
                authDetails: this.authDetails,
                ruleType: this.ruleType,
                ...this.target,
            };
        }
        else {
            datastream = {
                subscriptionId: this.subscriptionId,
                name: this.name || "Untitled",
                dataStream: this.id,
                authDetails: this.authDetails,
                ruleType: this.ruleType,
                ...this.target,
            };
        }
        if (excludeRuleType)
            delete datastream.ruleType;
        return datastream;
    }
}
exports.BaseDataStream = BaseDataStream;
