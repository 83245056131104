import { InjectedWalletBase } from './InjectedWalletBase.js';

class Phantom extends InjectedWalletBase {
    constructor() {
        super(...arguments);
        this.name = 'Phantom';
    }
}

export { Phantom };
