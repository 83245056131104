"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccountMetasAndSigners = exports.expectPda = exports.expectPublicKey = exports.expectSome = void 0;
const umi_1 = require("@metaplex-foundation/umi");
/**
 * Asserts that the given value is not null or undefined.
 * @internal
 */
function expectSome(value) {
    if (value == null) {
        throw new Error('Expected a value but received null or undefined.');
    }
    return value;
}
exports.expectSome = expectSome;
/**
 * Asserts that the given value is a PublicKey.
 * @internal
 */
function expectPublicKey(value) {
    if (!value) {
        throw new Error('Expected a PublicKey.');
    }
    return (0, umi_1.publicKey)(value, false);
}
exports.expectPublicKey = expectPublicKey;
/**
 * Asserts that the given value is a PDA.
 * @internal
 */
function expectPda(value) {
    if (!value || !Array.isArray(value) || !(0, umi_1.isPda)(value)) {
        throw new Error('Expected a PDA.');
    }
    return value;
}
exports.expectPda = expectPda;
/**
 * Get account metas and signers from resolved accounts.
 * @internal
 */
function getAccountMetasAndSigners(accounts, optionalAccountStrategy, programId) {
    const keys = [];
    const signers = [];
    accounts.forEach((account) => {
        if (!account.value) {
            if (optionalAccountStrategy === 'omitted')
                return;
            keys.push({ pubkey: programId, isSigner: false, isWritable: false });
            return;
        }
        if ((0, umi_1.isSigner)(account.value)) {
            signers.push(account.value);
        }
        keys.push({
            pubkey: (0, umi_1.publicKey)(account.value, false),
            isSigner: (0, umi_1.isSigner)(account.value),
            isWritable: account.isWritable,
        });
    });
    return [keys, signers];
}
exports.getAccountMetasAndSigners = getAccountMetasAndSigners;
