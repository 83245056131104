"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllTimeTxnsByUserRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class AllTimeTxnsByUserRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/solana/all-time/txns-by-user";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.AllTimeTxnsByUserRequest = AllTimeTxnsByUserRequest;
