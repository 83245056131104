"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGovBoardingErrorFromName = exports.getGovBoardingErrorFromCode = exports.InvalidStatusError = exports.InvalidVerifierRealmError = exports.VerifierNotSignerError = exports.IncorrectVerifierError = exports.IncorrectRealmError = exports.GovernanceNotFoundError = void 0;
const umi_1 = require("@metaplex-foundation/umi");
const codeToErrorMap = new Map();
const nameToErrorMap = new Map();
/** GovernanceNotFound: No governance has been specified for the verifier */
class GovernanceNotFoundError extends umi_1.ProgramError {
    constructor(program, cause) {
        super('No governance has been specified for the verifier', program, cause);
        this.name = 'GovernanceNotFound';
        this.code = 0x1770; // 6000
    }
}
exports.GovernanceNotFoundError = GovernanceNotFoundError;
codeToErrorMap.set(0x1770, GovernanceNotFoundError);
nameToErrorMap.set('GovernanceNotFound', GovernanceNotFoundError);
/** IncorrectRealm: The given governance is not in the verifier realm */
class IncorrectRealmError extends umi_1.ProgramError {
    constructor(program, cause) {
        super('The given governance is not in the verifier realm', program, cause);
        this.name = 'IncorrectRealm';
        this.code = 0x1771; // 6001
    }
}
exports.IncorrectRealmError = IncorrectRealmError;
codeToErrorMap.set(0x1771, IncorrectRealmError);
nameToErrorMap.set('IncorrectRealm', IncorrectRealmError);
/** IncorrectVerifier: The signer is neither the governance or the native treasury account */
class IncorrectVerifierError extends umi_1.ProgramError {
    constructor(program, cause) {
        super('The signer is neither the governance or the native treasury account', program, cause);
        this.name = 'IncorrectVerifier';
        this.code = 0x1772; // 6002
    }
}
exports.IncorrectVerifierError = IncorrectVerifierError;
codeToErrorMap.set(0x1772, IncorrectVerifierError);
nameToErrorMap.set('IncorrectVerifier', IncorrectVerifierError);
/** VerifierNotSigner: The verifier is not a signer */
class VerifierNotSignerError extends umi_1.ProgramError {
    constructor(program, cause) {
        super('The verifier is not a signer', program, cause);
        this.name = 'VerifierNotSigner';
        this.code = 0x1773; // 6003
    }
}
exports.VerifierNotSignerError = VerifierNotSignerError;
codeToErrorMap.set(0x1773, VerifierNotSignerError);
nameToErrorMap.set('VerifierNotSigner', VerifierNotSignerError);
/** InvalidVerifierRealm: Verifier realm cannot be set to None */
class InvalidVerifierRealmError extends umi_1.ProgramError {
    constructor(program, cause) {
        super('Verifier realm cannot be set to None', program, cause);
        this.name = 'InvalidVerifierRealm';
        this.code = 0x1774; // 6004
    }
}
exports.InvalidVerifierRealmError = InvalidVerifierRealmError;
codeToErrorMap.set(0x1774, InvalidVerifierRealmError);
nameToErrorMap.set('InvalidVerifierRealm', InvalidVerifierRealmError);
/** InvalidStatus: Status cannot be set to Pending */
class InvalidStatusError extends umi_1.ProgramError {
    constructor(program, cause) {
        super('Status cannot be set to Pending', program, cause);
        this.name = 'InvalidStatus';
        this.code = 0x1775; // 6005
    }
}
exports.InvalidStatusError = InvalidStatusError;
codeToErrorMap.set(0x1775, InvalidStatusError);
nameToErrorMap.set('InvalidStatus', InvalidStatusError);
/**
 * Attempts to resolve a custom program error from the provided error code.
 * @category Errors
 */
function getGovBoardingErrorFromCode(code, program, cause) {
    const constructor = codeToErrorMap.get(code);
    return constructor ? new constructor(program, cause) : null;
}
exports.getGovBoardingErrorFromCode = getGovBoardingErrorFromCode;
/**
 * Attempts to resolve a custom program error from the provided error name, i.e. 'Unauthorized'.
 * @category Errors
 */
function getGovBoardingErrorFromName(name, program, cause) {
    const constructor = nameToErrorMap.get(name);
    return constructor ? new constructor(program, cause) : null;
}
exports.getGovBoardingErrorFromName = getGovBoardingErrorFromName;
