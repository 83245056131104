"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RetrieveStreamRequest = void 0;
const deserialize_subscription_fn_1 = require("../../subscriptions/util/deserialize-subscription.fn");
const BaseRequest_class_1 = require("../BaseRequest.class");
class RetrieveStreamRequest extends BaseRequest_class_1.BaseRequest {
    id;
    method = "GET";
    url = "/v0/subscriptions/retrieve/{id}";
    constructor(id) {
        super(undefined, { id });
        this.id = id;
    }
    async _send(apiKey, baseUrl) {
        const response = (await super._send(apiKey, baseUrl));
        if (!response) {
            return null;
        }
        else {
            return (0, deserialize_subscription_fn_1.deserializeSubscription)(response);
        }
    }
}
exports.RetrieveStreamRequest = RetrieveStreamRequest;
