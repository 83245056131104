"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftLoanSummaryRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class NftLoanSummaryRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/nft/loans";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.NftLoanSummaryRequest = NftLoanSummaryRequest;
