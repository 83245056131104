import { jsx, jsxs } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Input } from '../Input/Input.js';
import { Button } from '../Button/Button.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../utils/constants/colors.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '@dynamic-labs/multi-wallet';
import { getOrdinalNumber } from '../../utils/functions/getOrdinalNumber/getOrdinalNumber.js';

const sendBalanceFormInitialValues = {
    amount: '',
    recipient: '',
};
const mapFieldAndErrorToMessage = {
    amount: {
        invalidDecimals: 'Please enter a value up to the {decimals} decimal place.',
        invalidFormat: 'The amount is in invalid format',
        overBalance: 'Insufficient funds to send this amount.',
        required: 'The amount field is required',
    },
    recipient: {
        invalidFormat: 'The address is in invalid format',
        required: 'The recipient is required',
    },
};
const getDisplayErrorMessage = (errors, field, decimals) => {
    const errorType = errors[field];
    if (errorType) {
        const errorMessage = mapFieldAndErrorToMessage[field][errorType];
        return errorMessage.replace('{decimals}', getOrdinalNumber(decimals !== null && decimals !== void 0 ? decimals : 0));
    }
    return '';
};
const SendBalanceForm = ({ initialValues, onSubmit, balance, decimals = 18, }) => {
    const sendBalanceFormValidationSchema = useMemo(() => {
        const decimalsRegex = new RegExp(`^\\d*(\\.\\d{1,${decimals}})?$`);
        return object().shape({
            amount: string()
                .required('required')
                .matches(/^\d*(\.\d+)?$/, 'invalidFormat')
                .matches(decimalsRegex, 'invalidDecimals')
                .test({
                message: 'overBalance',
                test: (value) => {
                    if (!value || balance === undefined)
                        return true;
                    try {
                        const valueNumber = parseFloat(value);
                        return valueNumber < balance;
                    }
                    catch (err) {
                        return true;
                    }
                },
            }),
            recipient: string()
                .required('required')
                .matches(/^0x[0-9a-fA-F]{40}$/, 'invalidFormat'),
        });
    }, [balance, decimals]);
    return (jsx(Formik, { initialValues: initialValues || sendBalanceFormInitialValues, validationSchema: sendBalanceFormValidationSchema, onSubmit: (values) => onSubmit(values), validateOnChange: false, validateOnBlur: false, children: ({ errors, touched }) => (jsxs(Form, { className: 'send-balance-form', children: [jsx(Field, { variant: 'regular', className: 'send-balance-form__field', name: 'amount', label: 'Amount', placeholder: 'Select amount', as: Input, error: errors['amount'], message: touched['amount'] &&
                        getDisplayErrorMessage(errors, 'amount', decimals) }), jsx(Field, { variant: 'regular', className: 'send-balance-form__field', placeholder: 'Enter wallet address...', label: 'Recipient', id: 'recipient', name: 'recipient', as: Input, error: errors['recipient'], message: touched['recipient'] &&
                        getDisplayErrorMessage(errors, 'recipient') }), jsx(Button, { dataTestId: 'sendBalanceFormSubmitButton', expanded: true, type: 'submit', buttonVariant: 'primary', buttonPadding: 'medium', buttonClassName: 'send-balance-form__button', children: "Send now" })] })) }));
};

export { SendBalanceForm };
