import { jsxs, jsx } from 'react/jsx-runtime';
import { WalletIcon } from '@dynamic-labs/wallet-book';
import 'react';
import { Typography } from '../../../../components/Typography/Typography.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import { shortenWalletAddress } from '../../../../shared/utils/functions/shortenWalletAddress/shortenWalletAddress.js';
import '@dynamic-labs/utils';
import '../../../../utils/constants/colors.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/multi-wallet';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../context/ThemeContext/ThemeContext.js';
import 'react-dom';
import 'react-focus-lock';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../context/MockContext/MockContext.js';
import '../../../../context/QrCodeContext/QrCodeContext.js';
import 'react-i18next';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { DefaultPromptModal } from '../DefaultPromptModal/DefaultPromptModal.js';
import { Divider } from '../../../../components/Divider/Divider.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../context/DynamicWidgetContext.js';
import 'yup';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import { LogoutButton } from '../../../../components/LogoutButton/LogoutButton.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';

const PrimaryNotConnectedModal = () => {
    var _a;
    const { primaryWallet } = useInternalDynamicContext();
    return (jsxs(DefaultPromptModal, { dataTestId: 'wallet-not-connected', icon: jsx(WalletIcon, { walletKey: (_a = primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector) === null || _a === void 0 ? void 0 : _a.key }), title: `Switch wallet in ${primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector.name}`, children: [jsxs(Typography, { variant: 'body_normal', color: 'secondary', weight: 'regular', "data-testid": 'content', children: ["Your active wallet in", ' ', jsx(Typography, { as: 'span', weight: 'medium', color: 'primary', children: primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector.name }), ' ', "is not linked. Please switch to wallet", ' ', jsx(Typography, { as: 'span', weight: 'medium', color: 'primary', children: shortenWalletAddress(primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.address, 3, 3) }), ' ', "to log in."] }), jsx(Typography, { variant: 'body_normal', color: 'secondary', weight: 'regular', "data-testid": 'content', children: "You can link this wallet to this account after you log in." }), jsx(Divider, { fullWidth: true, text: 'Or' }), jsx(LogoutButton, { isTextButton: false, buttonClassName: 'primary-not-connected__log-out-button' })] }));
};

export { PrimaryNotConnectedModal };
