const createRef = () => {
    const ref = { current: null };
    const get = () => {
        if (!ref.current) {
            throw new Error('Ref is not set');
        }
        return ref.current;
    };
    const set = (value) => {
        ref.current = value;
    };
    return [get, set];
};

export { createRef };
