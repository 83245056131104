"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.setChildStatus = exports.getSetChildStatusInstructionDataSerializer = void 0;
const umi_1 = require("@metaplex-foundation/umi");
const serializers_1 = require("@metaplex-foundation/umi/serializers");
const shared_1 = require("../shared");
const types_1 = require("../types");
function getSetChildStatusInstructionDataSerializer() {
    return (0, serializers_1.mapSerializer)((0, serializers_1.struct)([
        ['discriminator', (0, serializers_1.array)((0, serializers_1.u8)(), { size: 8 })],
        ['status', (0, types_1.getRequestStatusSerializer)()],
    ], { description: 'SetChildStatusInstructionData' }), (value) => (Object.assign(Object.assign({}, value), { discriminator: [124, 8, 77, 68, 53, 102, 165, 74] })));
}
exports.getSetChildStatusInstructionDataSerializer = getSetChildStatusInstructionDataSerializer;
// Instruction.
function setChildStatus(context, input) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    // Program ID.
    const programId = context.programs.getPublicKey('govBoarding', 'GovyJPza6EV6srUcmwA1vS3EmWGdLSkkDafRE54X1Dir');
    // Accounts.
    const resolvedAccounts = {
        childEntry: {
            index: 0,
            isWritable: true,
            value: (_a = input.childEntry) !== null && _a !== void 0 ? _a : null,
        },
        config: {
            index: 1,
            isWritable: false,
            value: (_b = input.config) !== null && _b !== void 0 ? _b : null,
        },
        verifier: {
            index: 2,
            isWritable: true,
            value: (_c = input.verifier) !== null && _c !== void 0 ? _c : null,
        },
        parentRealm: {
            index: 3,
            isWritable: false,
            value: (_d = input.parentRealm) !== null && _d !== void 0 ? _d : null,
        },
        parentEntry: {
            index: 4,
            isWritable: true,
            value: (_e = input.parentEntry) !== null && _e !== void 0 ? _e : null,
        },
        governanceProgram: {
            index: 5,
            isWritable: false,
            value: (_f = input.governanceProgram) !== null && _f !== void 0 ? _f : null,
        },
        governance: {
            index: 6,
            isWritable: false,
            value: (_g = input.governance) !== null && _g !== void 0 ? _g : null,
        },
        systemProgram: {
            index: 7,
            isWritable: false,
            value: (_h = input.systemProgram) !== null && _h !== void 0 ? _h : null,
        },
    };
    // Arguments.
    const resolvedArgs = Object.assign({}, input);
    // Default values.
    if (!resolvedAccounts.systemProgram.value) {
        resolvedAccounts.systemProgram.value = context.programs.getPublicKey('splSystem', '11111111111111111111111111111111');
        resolvedAccounts.systemProgram.isWritable = false;
    }
    // Accounts in order.
    const orderedAccounts = Object.values(resolvedAccounts).sort((a, b) => a.index - b.index);
    // Keys and Signers.
    const [keys, signers] = (0, shared_1.getAccountMetasAndSigners)(orderedAccounts, 'programId', programId);
    // Data.
    const data = getSetChildStatusInstructionDataSerializer().serialize(resolvedArgs);
    // Bytes Created On Chain.
    const bytesCreatedOnChain = 0;
    return (0, umi_1.transactionBuilder)([
        { instruction: { keys, programId, data }, signers, bytesCreatedOnChain },
    ]);
}
exports.setChildStatus = setChildStatus;
