import { http, createPublicClient, createWalletClient } from 'viem';
import { getOrMapViemChain } from '@dynamic-labs/utils';
import { getGatedTurnkeyAccount } from '../getGatedTurnkeyAccount/getGatedTurnkeyAccount.js';
import { createRef } from '../createRef/createRef.js';
import { createTurnkeyTransport } from '../createTurnkeyTransport/createTurnkeyTransport.js';

const createTurnkeyWalletClient = ({ account, evmNetwork, rpcUrl, connector, walletUiUtils, }) => {
    const rpcTransport = http(rpcUrl);
    const provider = createPublicClient({ transport: rpcTransport });
    const gatedAccount = getGatedTurnkeyAccount({
        account,
        connector,
        provider,
        walletUiUtils: walletUiUtils,
    });
    const chain = getOrMapViemChain(evmNetwork);
    const [getClient, setClient] = createRef();
    const customTransport = createTurnkeyTransport({
        account,
        getClient,
        transport: rpcTransport,
    });
    const client = createWalletClient({
        account: gatedAccount,
        chain,
        transport: customTransport,
    });
    setClient(client);
    return client;
};

export { createTurnkeyWalletClient };
