"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RpcRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class RpcRequest extends BaseRequest_class_1.BaseRequest {
    body;
    static rpcUrl = "https://rpc.hellomoon.io";
    method = "POST";
    url = "/v0/rpc";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
    // Overrides the URL set in BaseRequest
    get requestUrl() {
        return RpcRequest.rpcUrl;
    }
}
exports.RpcRequest = RpcRequest;
