"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnparsedTransactionStreamUpdateRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class UnparsedTransactionStreamUpdateRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "PUT";
    url = "/v0/transaction/stream/update";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.UnparsedTransactionStreamUpdateRequest = UnparsedTransactionStreamUpdateRequest;
