import { getAccount, getMint } from '@solana/spl-token';
import { Connection, PublicKey } from '@solana/web3.js';

export async function getLamports(conn: Connection, owner: PublicKey): Promise<BigInt> {
  return BigInt(await conn.getBalance(owner));
}

export async function getTokenBalance(conn: Connection, ata: PublicKey): Promise<bigint> {
  return BigInt((await conn.getTokenAccountBalance(ata)).value.amount);
}

export async function getTokenDecimals(conn: Connection, mint: PublicKey): Promise<number> {
  const token = await getMint(conn, mint);
  return token.decimals;
}
