"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDAOTypeSerializer = exports.DAOType = void 0;
const serializers_1 = require("@metaplex-foundation/umi/serializers");
var DAOType;
(function (DAOType) {
    DAOType[DAOType["Social"] = 0] = "Social";
    DAOType[DAOType["Finance"] = 1] = "Finance";
})(DAOType || (exports.DAOType = DAOType = {}));
function getDAOTypeSerializer() {
    return (0, serializers_1.scalarEnum)(DAOType, { description: 'DAOType' });
}
exports.getDAOTypeSerializer = getDAOTypeSerializer;
