import { createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import { Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';

interface GetOrCreateATAResponse {
  ataPubKey: PublicKey;
  ix?: TransactionInstruction;
}

export const getOrCreateATAInstruction = async (
  connection: Connection,
  tokenMint: PublicKey,
  owner: PublicKey,
  payer: PublicKey = owner,
  allowOwnerOffCurve = true,
): Promise<GetOrCreateATAResponse> => {
  try {
    const toAccount = await getAssociatedTokenAddress(tokenMint, owner, allowOwnerOffCurve);

    const account = await connection.getAccountInfo(toAccount);

    if (account) return { ataPubKey: toAccount, ix: undefined };

    const ix = createAssociatedTokenAccountInstruction(payer, toAccount, owner, tokenMint);

    return { ataPubKey: toAccount, ix };
  } catch (e) {
    /* handle error */
    console.error('Error::getOrCreateATAInstruction', e);
    throw e;
  }
};
