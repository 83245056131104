import { jsx, jsxs } from 'react/jsx-runtime';
import 'react';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import { useViewContext } from '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../utils/constants/colors.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/multi-wallet';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../context/ThemeContext/ThemeContext.js';
import 'react-dom';
import 'react-focus-lock';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../components/OverlayCard/OverlayCard.context.js';
import { OverlayCardTarget } from '../../components/OverlayCard/OverlayCardTarget.js';
import { useFooterAnimationContext } from '../../context/FooterAnimationContext/index.js';
import { useAuthLayoutChecks } from '../../utils/hooks/useAuthLayoutChecks/useAuthLayoutChecks.js';
import { useDynamicLayoutData } from '../../utils/hooks/useDynamicLayoutData/useDynamicLayoutData.js';
import { DynamicFooter } from '../../components/DynamicFooter/DynamicFooter.js';
import { Header } from './Header/header.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import { HelpContent } from './HelpContent/HelpContent.js';
import 'react-i18next';
import { ToSFooter } from './ToSFooter/ToSFooter.js';
import { WalletProgressStepper } from './WalletProgressStepper/WalletProgressStepper.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../context/QrCodeContext/QrCodeContext.js';
import '../../context/LoadingContext/LoadingContext.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import '../../context/MockContext/MockContext.js';
import '../../components/IconButton/IconButton.js';
import '../../components/Alert/Alert.js';
import { VerticalAccordion } from '../../components/Accordion/components/VerticalAccordion/VerticalAccordion.js';
import { AccordionItem } from '../../components/Accordion/components/AccordionItem/AccordionItem.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/MenuList/Dropdown/Dropdown.js';

const DynamicAuthLayout = ({ children, className, style, onClose, projectSettings, hideBridgeProgressSteppers, }) => {
    const { view } = useViewContext();
    const { walletConnector, authMode } = useInternalDynamicContext();
    const { isFooterExpanded } = useFooterAnimationContext();
    const { showHeader, showHelpContent, showToSFooter } = useAuthLayoutChecks(walletConnector);
    const { headerData, helpHeaderData } = useDynamicLayoutData({
        authMode,
        view,
    });
    const { showConnectedWalletProgress } = useAuthLayoutChecks(walletConnector);
    const { showDynamicFooter } = useAuthLayoutChecks(walletConnector);
    return (jsx(OverlayCardTarget, { children: jsxs(VerticalAccordion, { style: style, className: className, children: [jsxs(AccordionItem, { isOpen: !isFooterExpanded, dimOnHide: true, children: [showConnectedWalletProgress && !hideBridgeProgressSteppers && (jsx(WalletProgressStepper, {})), showHeader && (jsx(Header, { onClose: onClose, heading: (headerData === null || headerData === void 0 ? void 0 : headerData.heading) || '', projectSettings: projectSettings, walletConnector: walletConnector })), children, showDynamicFooter && jsx(DynamicFooter, {}), showToSFooter && jsx(ToSFooter, {})] }), helpHeaderData && showHelpContent && (jsx(AccordionItem, { isOpen: isFooterExpanded, children: jsx("div", { children: jsx(HelpContent, {}) }, isFooterExpanded.toString()) }))] }) }));
};

export { DynamicAuthLayout };
