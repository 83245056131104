"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FraktEventStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    blockId: { type: "NUMERIC" },
    blockTime: { type: "DATE" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
    eventType: { type: "ENUM" },
    programId: { type: "TEXT" },
    instructionName: { type: "TEXT" },
    "lender.adminPaymentAmount": { type: "NUMERIC" },
    "lender.lender": { type: "TEXT" },
    "lender.liquidityPool": { type: "TEXT" },
    "lender.liquidityOwner": { type: "TEXT" },
    "lender.lenderWithdrawAmount": { type: "NUMERIC" },
    "lender.depositAmount": { type: "NUMERIC" },
    "lender.depositAccount": { type: "TEXT" },
    "lender.withdrawAmount": { type: "NUMERIC" },
    "lender.withdrawAccount": { type: "TEXT" },
    "lender.admin": { type: "TEXT" },
    "lender.depositBump": { type: "NUMERIC" },
    "lender.baseBorrowRate": { type: "NUMERIC" },
    "lender.variableSlope1": { type: "NUMERIC" },
    "lender.variableSlope2": { type: "NUMERIC" },
    "lender.utilizationRateOptimal": { type: "NUMERIC" },
    "lender.reserveFactor": { type: "NUMERIC" },
    "lender.borrowCommission": { type: "NUMERIC" },
    "lender.depositCommission": { type: "NUMERIC" },
    "collectionInfo.creator": { type: "TEXT" },
    "collectionInfo.pricingLookup": { type: "TEXT" },
    "collectionInfo.liquidityPool": { type: "TEXT" },
    "collectionInfo.collectionInfoAccount": { type: "TEXT" },
    "collectionInfo.royaltyAccount": { type: "TEXT" },
    "collectionInfo.admin": { type: "TEXT" },
    "collectionInfo.loanToValue": { type: "NUMERIC" },
    "collectionInfo.collaterizationRate": { type: "NUMERIC" },
    "collectionInfo.royaltyFeeTime": { type: "NUMERIC" },
    "collectionInfo.royaltyFeePrice": { type: "NUMERIC" },
    "collectionInfo.expirationTime": { type: "NUMERIC" },
    "loan.adminPaymentAmount": { type: "NUMERIC" },
    "loan.liquidityPool": { type: "TEXT" },
    "loan.liquidityOwner": { type: "TEXT" },
    "loan.collectionInfoAccount": { type: "TEXT" },
    "loan.communityPoolsAuthority": { type: "TEXT" },
    "loan.loan": { type: "TEXT" },
    "loan.borrower": { type: "TEXT" },
    "loan.nft": { type: "TEXT" },
    "loan.borrowerNftTokenAccount": { type: "TEXT" },
    "loan.vaultNftTokenAccount": { type: "TEXT" },
    "loan.adminNftTokenAccount": { type: "TEXT" },
    "loan.originalPriceFromBorrower": { type: "NUMERIC" },
    "loan.loanToValue": { type: "NUMERIC" },
    "loan.adminRefundAmount": { type: "NUMERIC" },
    "loan.nftPrice": { type: "NUMERIC" },
    "loan.discount": { type: "NUMERIC" },
    "loan.loanAmount": { type: "NUMERIC" },
    "loan.royaltyAccount": { type: "TEXT" },
    "loan.royaltyPaymentAmount": { type: "NUMERIC" },
    "loan.paybackAmount": { type: "NUMERIC" },
    "loan.gracePeriod": { type: "NUMERIC" },
    "loan.liquidationLot": { type: "TEXT" },
    "loan.admin": { type: "TEXT" },
    "loan.amountToLiqOwner": { type: "NUMERIC" },
    "loan.nftAmount": { type: "NUMERIC" },
    "loan.liquidator": { type: "TEXT" },
    "loan.nftMetadata": { type: "TEXT" },
    "loan.tokenRecordInfo": { type: "TEXT" },
    "loan.destTokenRecord": { type: "TEXT" },
    "loan.ownerTokenRecord": { type: "TEXT" },
    "loan.lotTicket": { type: "TEXT" },
    "loan.nftAttempts": { type: "TEXT" },
    "loan.nftLiquidationAmount": { type: "NUMERIC" },
    "loan.attemptsNftMint": { type: "TEXT" },
    "loan.nftAttemptsBump": { type: "NUMERIC" },
    "loan.lotTicketIsWinning": { type: "TEXT" },
    "loan.liquidatorNftTokenAccount": { type: "TEXT" },
    "admin.liquidityPool": { type: "TEXT" },
    "admin.liquidityOwner": { type: "TEXT" },
    "admin.admin": { type: "TEXT" },
    "admin.depositAmount": { type: "NUMERIC" },
    "admin.withdrawAmount": { type: "NUMERIC" },
};
class FraktEventStream extends base_data_stream_class_1.BaseDataStream {
    id = "FraktEvent";
    static fieldMap = fieldMap;
}
exports.FraktEventStream = FraktEventStream;
