"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftPrimarySaleWithoutCollectionStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    programId: { type: "TEXT" },
    instructionName: { type: "TEXT" },
    price: { type: "NUMERIC" },
    payerAccount: { type: "TEXT" },
    isSmartMinter: { type: "BOOLEAN" },
    sellerAccount: { type: "TEXT" },
    mint: { type: "TEXT" },
    blockTime: { type: "DATE" },
    blockId: { type: "NUMERIC" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
};
class NftPrimarySaleWithoutCollectionStream extends base_data_stream_class_1.BaseDataStream {
    id = "NftPrimarySaleWithoutCollection";
    static fieldMap = fieldMap;
}
exports.NftPrimarySaleWithoutCollectionStream = NftPrimarySaleWithoutCollectionStream;
