import { PublicKey, Cluster } from '@solana/web3.js';

export const DCA_SEED_KEY = 'dca';
export const FEE_SEED_KEY = 'fee';

export const DCA_PROGRAM_ID_BY_CLUSTER: { [key in Cluster]: PublicKey } = {
  testnet: new PublicKey('devBa82xDUtHW6hVF27uCFCf6hmV323aJg1Qf9bgwWf'),
  devnet: new PublicKey('devJJ5KxHjZeq4h5mKFNQqeNJtTMcjMfWr7pPZTpviK'),
  'mainnet-beta': new PublicKey('DCA265Vj8a9CEuX1eb1LWRnDT7uK6q1xMipnNyatn23M'),
};

// consider removing this
export const JUP_PROGRAM_ID_BY_CLUSTER = {
  'mainnet-beta': new PublicKey('JUP4Fb2cqiRUcaTHdrPC8h2gNsA2ETXiPDD33WcGuJB'),
};

export const DCA_TRACKER_BASE_URL = 'https://dca-api.jup.ag';

export enum Network {
  MAINNET = 'mainnet-beta',
  TESTNET = 'testnet',
  DEVNET = 'devnet',
}

export const FEE_BPS = 10; // consider removing this so that we have a single source of truth - the program

export enum DCAStatus {
  InProgress,
  Closed,
}
