import { jsx } from 'react/jsx-runtime';
import 'react';
import '@dynamic-labs/sdk-api';
import '../../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import { useViewContext } from '../../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../../../utils/constants/colors.js';
import '../../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/multi-wallet';
import { getProviderEnumForSocialSignInProvider } from '../../../../../utils/functions/getProviderEnumForSocialSignInProvider/getProviderEnumForSocialSignInProvider.js';
import '../../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../../context/ThemeContext/ThemeContext.js';
import 'react-dom';
import 'react-focus-lock';
import '../../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../../../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../../../../context/ErrorContext/ErrorContext.js';
import '../../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../../context/FooterAnimationContext/index.js';
import '../../../../../context/MockContext/MockContext.js';
import '../../../../../context/QrCodeContext/QrCodeContext.js';
import 'react-i18next';
import '../../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../../../../context/LoadingContext/LoadingContext.js';
import '../../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import '../../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import { useSocialAuth } from '../../../../../utils/hooks/useSocialAuth/useSocialAuth.js';
import '../../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import '../../../../../components/IconButton/IconButton.js';
import '../../../../../components/Alert/Alert.js';
import '../../../../../components/Popper/Popper/Popper.js';
import '../../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../../components/InlineWidget/InlineWidget.js';
import '../../../../../components/MenuList/Dropdown/Dropdown.js';
import { SocialSignIn } from '../../../../../components/SocialSignIn/SocialSignIn.js';
import { useSocialRedirectContext } from '../../../../../context/SocialRedirectContext/SocialRedirectContext.js';

const DynamicSocialSignIn = ({ defaultProvider, numOfItemsToDisplay, collapsedLayout, }) => {
    const { setShowAuthFlow } = useInternalDynamicContext();
    const { setSocialProvider, socialProvider, enabledSocialProviders } = useSocialRedirectContext();
    const { setView, goToInitialView } = useViewContext();
    const { isProcessing, connectSocialAccount } = useSocialAuth({
        authMode: 'signin',
        onError: () => {
            goToInitialView();
        },
        onSettled: () => {
            setSocialProvider(undefined);
        },
        sessionTimeout: 10000,
    });
    const onSelectProvider = (provider) => {
        const providerEnum = getProviderEnumForSocialSignInProvider(provider);
        setSocialProvider(provider);
        setView('social-redirect-view');
        setShowAuthFlow(true);
        connectSocialAccount(providerEnum);
    };
    return (jsx(SocialSignIn, { onSelectProvider: onSelectProvider, providers: enabledSocialProviders, selectedProvider: socialProvider, isLoading: isProcessing, testId: 'dynamic-social-sign-in', defaultProvider: defaultProvider, numOfItemsToDisplay: numOfItemsToDisplay, collapsedLayout: collapsedLayout }));
};

export { DynamicSocialSignIn };
