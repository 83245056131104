import { jsx, jsxs } from 'react/jsx-runtime';
import { ModalHeader } from '../ModalHeader/ModalHeader.js';
import { IconButton } from '../IconButton/IconButton.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import 'react';
import { ReactComponent as SvgClose } from '../../shared/assets/close.js';
import { ReactComponent as SvgCheck } from '../../shared/assets/check.js';
import { ReactComponent as SvgSignCircle } from '../../shared/assets/sign-circle.js';
import '@dynamic-labs/wallet-book';
import { shortenWalletAddress } from '../../shared/utils/functions/shortenWalletAddress/shortenWalletAddress.js';
import '@dynamic-labs/utils';
import '../../utils/constants/colors.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import { IconWithStatus } from '../IconWithStatus/IconWithStatus.js';
import { Typography } from '../Typography/Typography.js';
import { PoweredByDynamic } from '../PoweredByDynamic/PoweredByDynamic.js';
import { Button } from '../Button/Button.js';
import '@dynamic-labs/multi-wallet';
import '../../context/ThemeContext/ThemeContext.js';
import '../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../context/DynamicContext/DynamicContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import 'react-dom';
import '../ShadowDOM/ShadowDOM.js';
import 'react-focus-lock';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Alert/Alert.js';
import 'react-i18next';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'formik';
import 'yup';
import '../InlineWidget/InlineWidget.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../OverlayCard/OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import '../../context/MockContext/MockContext.js';
import '../../context/QrCodeContext/QrCodeContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../context/LoadingContext/LoadingContext.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import { TransactionRow } from '../TransactionConfirmationPageLayout/components/TransactionRow/TransactionRow.js';

const TransactionStatusLayout = ({ destinationAddress, amount, networkName, networkCurrency, NetworkIcon, onDone, displayPoweredByDynamicFooter = true, onClickClose, }) => {
    const closeButton = onClickClose && (jsx(IconButton, { type: 'button', onClick: onClickClose, "data-testid": 'close', children: jsx(SvgClose, {}) }));
    const networkIcon = (jsx("span", { className: 'transaction-status-layout__network-icon', children: NetworkIcon }));
    return (jsxs("div", { className: 'transaction-status-layout', children: [jsx(ModalHeader, { trailing: closeButton, alignContent: 'bottom', children: jsx(IconWithStatus, { containerClassName: 'transaction-status-layout__status-icon', iconSize: 50, Icon: SvgSignCircle, InnerIcon: SvgCheck }) }), jsxs("div", { className: 'transaction-status-layout__body', children: [jsx(Typography, { weight: 'medium', variant: 'title', color: 'primary', className: 'transaction-status-layout__title', children: "Transaction successfully sent" }), jsx("div", { className: 'transaction-status-layout__content', children: jsxs("div", { className: 'transaction-status-layout__rows', children: [jsx(TransactionRow, { label: 'Recipient', children: shortenWalletAddress(destinationAddress, 3, 3) }), jsxs(TransactionRow, { label: 'Network', children: [networkIcon, jsx("span", { className: 'transaction-status-layout__network-name', children: networkName })] }), jsxs(TransactionRow, { label: 'Total amount', children: [amount, jsx(Typography, { as: 'span', color: 'secondary', className: 'transaction-status-layout__network-currency', children: networkCurrency })] })] }) }), jsx("div", { className: 'transaction-status-layout__actions', children: jsx(Button, { buttonVariant: 'primary', buttonPadding: 'large', expanded: true, onClick: onDone, loading: false, children: "Continue" }) }), displayPoweredByDynamicFooter && (jsx(PoweredByDynamic, { classNameRoot: 'transaction-status-layout__footer' }))] })] }));
};

export { TransactionStatusLayout };
