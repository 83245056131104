"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGovernanceEntryGpaBuilder = exports.safeFetchAllGovernanceEntry = exports.fetchAllGovernanceEntry = exports.safeFetchGovernanceEntry = exports.fetchGovernanceEntry = exports.deserializeGovernanceEntry = exports.getGovernanceEntryAccountDataSerializer = void 0;
const umi_1 = require("@metaplex-foundation/umi");
const serializers_1 = require("@metaplex-foundation/umi/serializers");
const types_1 = require("../types");
function getGovernanceEntryAccountDataSerializer() {
    return (0, serializers_1.mapSerializer)((0, serializers_1.struct)([
        ['discriminator', (0, serializers_1.array)((0, serializers_1.u8)(), { size: 8 })],
        ['config', (0, serializers_1.publicKey)()],
        ['governanceProgram', (0, serializers_1.publicKey)()],
        ['govAccountType', (0, types_1.getDAOTypeSerializer)()],
        ['requestStatus', (0, types_1.getRequestStatusSerializer)()],
        ['rank', (0, serializers_1.u16)()],
        ['parentLinks', (0, serializers_1.option)((0, types_1.getParentLinksSerializer)())],
        ['childRealms', (0, serializers_1.option)((0, serializers_1.array)((0, serializers_1.publicKey)()))],
        ['name', (0, serializers_1.string)()],
        ['metadataUri', (0, serializers_1.string)()],
    ], { description: 'GovernanceEntryAccountData' }), (value) => (Object.assign(Object.assign({}, value), { discriminator: [107, 36, 237, 168, 199, 164, 138, 165] })));
}
exports.getGovernanceEntryAccountDataSerializer = getGovernanceEntryAccountDataSerializer;
function deserializeGovernanceEntry(rawAccount) {
    return (0, umi_1.deserializeAccount)(rawAccount, getGovernanceEntryAccountDataSerializer());
}
exports.deserializeGovernanceEntry = deserializeGovernanceEntry;
function fetchGovernanceEntry(context, publicKey, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const maybeAccount = yield context.rpc.getAccount((0, umi_1.publicKey)(publicKey, false), options);
        (0, umi_1.assertAccountExists)(maybeAccount, 'GovernanceEntry');
        return deserializeGovernanceEntry(maybeAccount);
    });
}
exports.fetchGovernanceEntry = fetchGovernanceEntry;
function safeFetchGovernanceEntry(context, publicKey, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const maybeAccount = yield context.rpc.getAccount((0, umi_1.publicKey)(publicKey, false), options);
        return maybeAccount.exists ? deserializeGovernanceEntry(maybeAccount) : null;
    });
}
exports.safeFetchGovernanceEntry = safeFetchGovernanceEntry;
function fetchAllGovernanceEntry(context, publicKeys, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const maybeAccounts = yield context.rpc.getAccounts(publicKeys.map((key) => (0, umi_1.publicKey)(key, false)), options);
        return maybeAccounts.map((maybeAccount) => {
            (0, umi_1.assertAccountExists)(maybeAccount, 'GovernanceEntry');
            return deserializeGovernanceEntry(maybeAccount);
        });
    });
}
exports.fetchAllGovernanceEntry = fetchAllGovernanceEntry;
function safeFetchAllGovernanceEntry(context, publicKeys, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const maybeAccounts = yield context.rpc.getAccounts(publicKeys.map((key) => (0, umi_1.publicKey)(key, false)), options);
        return maybeAccounts
            .filter((maybeAccount) => maybeAccount.exists)
            .map((maybeAccount) => deserializeGovernanceEntry(maybeAccount));
    });
}
exports.safeFetchAllGovernanceEntry = safeFetchAllGovernanceEntry;
function getGovernanceEntryGpaBuilder(context) {
    const programId = context.programs.getPublicKey('govBoarding', 'GovyJPza6EV6srUcmwA1vS3EmWGdLSkkDafRE54X1Dir');
    return (0, umi_1.gpaBuilder)(context, programId)
        .registerFields({
        discriminator: [0, (0, serializers_1.array)((0, serializers_1.u8)(), { size: 8 })],
        config: [8, (0, serializers_1.publicKey)()],
        governanceProgram: [40, (0, serializers_1.publicKey)()],
        govAccountType: [72, (0, types_1.getDAOTypeSerializer)()],
        requestStatus: [73, (0, types_1.getRequestStatusSerializer)()],
        rank: [74, (0, serializers_1.u16)()],
        parentLinks: [76, (0, serializers_1.option)((0, types_1.getParentLinksSerializer)())],
        childRealms: [null, (0, serializers_1.option)((0, serializers_1.array)((0, serializers_1.publicKey)()))],
        name: [null, (0, serializers_1.string)()],
        metadataUri: [null, (0, serializers_1.string)()],
    })
        .deserializeUsing((account) => deserializeGovernanceEntry(account))
        .whereField('discriminator', [107, 36, 237, 168, 199, 164, 138, 165]);
}
exports.getGovernanceEntryGpaBuilder = getGovernanceEntryGpaBuilder;
