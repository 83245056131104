import { PublicKey } from '@solana/web3.js';

export const MAIN_DOMAIN_PREFIX = 'main_domain';
// MAINNET
export const ORIGIN_TLD = 'ANS';
export const ANS_PROGRAM_ID = new PublicKey(
    'ALTNSZ46uaAUU7XUV6awvdorLGqAsPwa9shm7h4uP2FK',
);
export const TLD_HOUSE_PROGRAM_ID = new PublicKey(
    'TLDHkysf5pCnKsVA4gXpNvmy7psXLPEu4LAdDJthT9S',
);

export const NAME_HOUSE_PROGRAM_ID = new PublicKey(
    'NH3uX6FtVE2fNREAioP7hm5RaozotZxeL6khU1EHx51',
);

export const SPL_TOKEN_PROGRAM_ID = new PublicKey(
    'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
);
export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
    'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s',
);
export const NFT_RECORD_PREFIX = 'nft_record';
export const TLD_HOUSE_PREFIX = 'tld_house';
export const NAME_HOUSE_PREFIX = 'name_house';
export const MULTIPLE_ACCOUNT_INFO_MAX = 100;
