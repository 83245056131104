import { jsx } from 'react/jsx-runtime';
import 'react';
import { Typography } from '../../../../components/Typography/Typography.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgChevronLeft } from '../../../../shared/assets/chevron-left.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../../utils/constants/colors.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/multi-wallet';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../context/ThemeContext/ThemeContext.js';
import 'react-dom';
import 'react-focus-lock';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import { useFooterAnimationContext } from '../../../../context/FooterAnimationContext/index.js';
import { useAuthLayoutChecks } from '../../../../utils/hooks/useAuthLayoutChecks/useAuthLayoutChecks.js';
import { useDynamicLayoutData } from '../../../../utils/hooks/useDynamicLayoutData/useDynamicLayoutData.js';
import '../../../../context/QrCodeContext/QrCodeContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import 'react-i18next';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import '../../../../context/MockContext/MockContext.js';
import { ModalHeader } from '../../../../components/ModalHeader/ModalHeader.js';
import { IconButton } from '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';

const HelpHeader = () => {
    const { authMode, selectedWalletConnector } = useInternalDynamicContext();
    const { setIsFooterExpanded } = useFooterAnimationContext();
    const { view } = useViewContext();
    const { helpHeaderData } = useDynamicLayoutData({ authMode, view });
    const { displayBorderBelowHeader } = useAuthLayoutChecks(selectedWalletConnector);
    const backButton = (jsx(IconButton, { type: 'button', onClick: () => setIsFooterExpanded(false), "data-testid": 'back-button', className: 'header__icon', children: jsx(SvgChevronLeft, { className: 'back-button ' }) }));
    return (jsx("div", { className: 'help-header', children: jsx(ModalHeader, { trailing: backButton, displayLeading: false, displayBorder: displayBorderBelowHeader, children: jsx(Typography, { as: 'h1', variant: 'title', color: 'primary', "data-testid": 'dynamic-auth-help-heading', className: 'header__typography', children: helpHeaderData === null || helpHeaderData === void 0 ? void 0 : helpHeaderData.heading }) }) }));
};

export { HelpHeader };
