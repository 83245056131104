"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListStreamsRequest = void 0;
const deserialize_subscription_fn_1 = require("../../subscriptions/util/deserialize-subscription.fn");
const BaseRequest_class_1 = require("../BaseRequest.class");
class ListStreamsRequest extends BaseRequest_class_1.BaseRequest {
    method = "GET";
    url = "/v0/subscriptions/list";
    constructor() {
        super();
    }
    async _send(apiKey, baseUrl) {
        const response = (await super._send(apiKey, baseUrl));
        if (!response) {
            return [];
        }
        else {
            return response
                .map(deserialize_subscription_fn_1.deserializeSubscription)
                .filter((v) => !!v);
        }
    }
}
exports.ListStreamsRequest = ListStreamsRequest;
