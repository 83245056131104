import { __awaiter } from '../../../../_virtual/_tslib.js';
import { custom, fromHex } from 'viem';
import { unFormatTransaction } from '../unFormatTransaction/unFormatTransaction.js';

/**
 * Create a custom transport that will handle eth_accounts, personal_sign, and
 * eth_sendTransaction requests, and use the viem client created with the turnkey
 * account to handle these requests so the user is prompt to confirm and to use
 * the turnkey api to sign with passkeys.
 * This also prevents when the clients override the viem account in requests like
 * sendTransaction, signMessage, etc.
 */
const createTurnkeyTransport = ({ account, transport, getClient, }) => (props) => custom({
    request: (args) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { method, params } = args;
        const client = getClient();
        if (method === 'eth_accounts') {
            return [account.address];
        }
        if (method === 'personal_sign') {
            const [message] = params;
            return client.signMessage({
                message: fromHex(message, 'string'),
            });
        }
        if (method === 'eth_sendTransaction') {
            const [transaction] = params;
            return client.sendTransaction(unFormatTransaction(transaction));
        }
        if (method === 'eth_signTypedData_v4') {
            const [, message] = (_a = params) !== null && _a !== void 0 ? _a : [];
            return client.signTypedData(JSON.parse(message));
        }
        return transport(props).request(args);
    }),
})(props);

export { createTurnkeyTransport };
