"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefiProgramOverlapRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class DefiProgramOverlapRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/defi/program-stats/overlap";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.DefiProgramOverlapRequest = DefiProgramOverlapRequest;
