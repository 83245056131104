import { jsxs, jsx } from 'react/jsx-runtime';
import 'react';
import { Typography } from '../../../Typography/Typography.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import { getChainIcon } from '../../../../shared/utils/functions/chain/getChainIcon.js';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../../utils/constants/colors.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/iconic';
import '../../../../context/ViewContext/ViewContext.js';
import '../../../../shared/consts/index.js';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/multi-wallet';
import '../../../ShadowDOM/ShadowDOM.js';
import '../../../../context/ThemeContext/ThemeContext.js';
import { classNames } from '../../../../utils/functions/classNames/classNames.js';
import { Icon } from '../../../Icon/Icon.js';
import 'react-dom';
import 'react-focus-lock';
import '../../../Transition/ZoomTransition/ZoomTransition.js';
import '../../../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../Transition/OpacityTransition/OpacityTransition.js';
import '../../../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../context/MockContext/MockContext.js';
import '../../../../context/QrCodeContext/QrCodeContext.js';
import 'react-i18next';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import '../../../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import '../../../IconButton/IconButton.js';
import '../../../Alert/Alert.js';
import { useFetchChain } from '../../../../widgets/DynamicWidget/hooks/useFetchChain/useFetchChain.js';
import '../../../Popper/Popper/Popper.js';
import '../../../Popper/PopperContext/PopperContext.js';
import '../../../InlineWidget/InlineWidget.js';
import '../../../MenuList/Dropdown/Dropdown.js';
import { capitalize } from '../../../../shared/utils/functions/capitalize/capitalize.js';

const NonNetworkSwitchingSupportedControl = ({ walletConnector, className, showNetworkName = false, }) => {
    const [chain] = useFetchChain(walletConnector);
    const chainName = (chain === null || chain === void 0 ? void 0 : chain.name) || '';
    const ChainIcon = getChainIcon(chainName);
    return (jsxs("div", { "data-testid": 'NonNetworkSwitchingSupportedControl', className: classNames('non-network-switching-supported-control', className), children: [jsx(Icon, { size: 'small', children: (chain === null || chain === void 0 ? void 0 : chain.icon) ? (jsx("img", { src: chain.icon, alt: `${chain.name} icon` })) : (jsx(ChainIcon, {})) }), showNetworkName && (jsx(Typography, { className: classNames('non-network-switching-supported-control__network-name'), as: 'span', variant: 'body_small', children: capitalize(chainName) })), jsx("span", {})] }));
};

export { NonNetworkSwitchingSupportedControl };
