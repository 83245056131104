"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftCollectionListingStatsStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    blockId: { type: "NUMERIC" },
    blockTime: { type: "DATE" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
    helloMoonCollectionId: { type: "TEXT" },
    collectionName: { type: "TEXT" },
    floorPrice: { type: "NUMERIC" },
    numListings: { type: "NUMERIC" },
};
class NftCollectionListingStatsStream extends base_data_stream_class_1.BaseDataStream {
    id = "NftCollectionListingStats";
    static fieldMap = fieldMap;
}
exports.NftCollectionListingStatsStream = NftCollectionListingStatsStream;
