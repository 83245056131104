import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { useCallback } from 'react';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useInternalDynamicContext.js';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import { decodeJwt } from '../../../shared/utils/functions/decodeJwt/decodeJwt.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../shared/logger.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../constants/colors.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import '@dynamic-labs/multi-wallet';
import { getAuthToken } from '../../functions/getAuthToken/getAuthToken.js';
import '@dynamic-labs/sdk-api';
import { isTurnkeyEnabled } from '../../functions/isTurnkeyEnabled/isTurnkeyEnabled.js';
import { isEmbeddedWalletPresent } from '../../functions/isEmbeddedWalletPresent/isEmbeddedWalletPresent.js';
import { findEmbeddedWalletFromVerifiedCredentials } from '../../functions/findEmbeddedWalletFromVerifiedCredentials/findEmbeddedWalletFromVerifiedCredentials.js';
import { findTurnkeyWallet } from '../../functions/findTurnkeyWallet/findTurnkeyWallet.js';
import { getUserWalletsFromVerifiedCredentials } from '../../functions/getUserWalletsFromVerifiedCredentials/getUserWalletsFromVerifiedCredentials.js';

// Hook exposed to customers and used internally to trigger embedded wallet creation
const useEmbeddedWallet = () => {
    const { projectSettings, onboardingOnlyJwt, setPrimaryWalletId, wallets, internalSetShowAuthFlow, internalEvents, primaryWallet, } = useInternalDynamicContext();
    const { setView } = useViewContext();
    const userHasEmbeddedWallet = () => {
        var _a;
        const jwt = (_a = getAuthToken()) !== null && _a !== void 0 ? _a : onboardingOnlyJwt;
        if (!jwt) {
            return false;
        }
        return isEmbeddedWalletPresent(jwt);
    };
    const createEmbeddedWallet = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const jwt = (_a = getAuthToken()) !== null && _a !== void 0 ? _a : onboardingOnlyJwt;
        if (!jwt) {
            throw new Error('User is not logged in');
        }
        if (!isTurnkeyEnabled(projectSettings)) {
            throw new Error('Passkey embedded wallet is not enabled. Go to the dashboard and make sure to have both Turnkey/Passkey Embedded wallets and at least one EVM network enabled. Also, check if EthereumWalletConnectors is in the  DynamicContextProvider > settings > walletConnectors.');
        }
        const userWaletsCredentials = getUserWalletsFromVerifiedCredentials(jwt);
        // if user doesn't have a wallet yet, show the passkey view to create an embedded wallet
        if (!(userWaletsCredentials === null || userWaletsCredentials === void 0 ? void 0 : userWaletsCredentials.length)) {
            internalSetShowAuthFlow(true);
            setView('passkey-intro');
            return new Promise((resolve, reject) => {
                internalEvents.current.once('embeddedWalletCreated', (wallet) => resolve(wallet));
                internalEvents.current.once('embeddedWalletFailed', (error) => reject(error));
            });
        }
        internalSetShowAuthFlow(false);
        const embeddedWalletVerifiedCredential = findEmbeddedWalletFromVerifiedCredentials(jwt);
        const turnkeyWallet = findTurnkeyWallet(wallets);
        // if user logged in with MM for example, just continue the flow
        if (!embeddedWalletVerifiedCredential) {
            return Promise.resolve(primaryWallet);
        }
        const decodedJwt = decodeJwt(jwt);
        // if user already has embedded wallet, set it as primary wallet
        if (decodedJwt) {
            (_b = turnkeyWallet === null || turnkeyWallet === void 0 ? void 0 : turnkeyWallet.walletConnector) === null || _b === void 0 ? void 0 : _b.setVerifiedCredentials(decodedJwt.verifiedCredentials);
            setPrimaryWalletId(embeddedWalletVerifiedCredential.id);
        }
        const passkeyWallet = {
            address: (yield ((_c = turnkeyWallet === null || turnkeyWallet === void 0 ? void 0 : turnkeyWallet.walletConnector) === null || _c === void 0 ? void 0 : _c.fetchPublicAddress())) || '',
            chain: ((_d = turnkeyWallet === null || turnkeyWallet === void 0 ? void 0 : turnkeyWallet.walletConnector) === null || _d === void 0 ? void 0 : _d.connectedChain) || '',
            connected: true,
            connector: (turnkeyWallet === null || turnkeyWallet === void 0 ? void 0 : turnkeyWallet.walletConnector) || {},
            id: embeddedWalletVerifiedCredential.id,
        };
        return Promise.resolve(passkeyWallet);
    }), [
        internalEvents,
        internalSetShowAuthFlow,
        onboardingOnlyJwt,
        projectSettings,
        setPrimaryWalletId,
        setView,
        wallets,
    ]);
    return {
        createEmbeddedWallet,
        userHasEmbeddedWallet,
    };
};

export { useEmbeddedWallet };
