"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.streamMap = void 0;
const lp_creation_stream_1 = require("./lp-creation-stream");
const helio_event_stream_1 = require("./helio-event-stream");
const nft_collection_listing_stats_stream_1 = require("./nft-collection-listing-stats-stream");
const nft_primary_sale_with_collection_stream_1 = require("./nft-primary-sale-with-collection-stream");
const frakt_event_stream_1 = require("./frakt-event-stream");
const lp_balance_change_stream_1 = require("./lp-balance-change-stream");
const balance_change_stream_1 = require("./balance-change-stream");
const nft_primary_sale_without_collection_stream_1 = require("./nft-primary-sale-without-collection-stream");
const nft_market_action_stream_1 = require("./nft-market-action-stream");
const star_atlas_event_stream_1 = require("./star-atlas-event-stream");
const token_price_stream_1 = require("./token-price-stream");
const token_transfer_stream_1 = require("./token-transfer-stream");
const token_swap_stream_1 = require("./token-swap-stream");
const lp_deposit_withdrawal_stream_1 = require("./lp-deposit-withdrawal-stream");
exports.streamMap = {
    LpCreationStream: lp_creation_stream_1.LpCreationStream,
    HelioEventStream: helio_event_stream_1.HelioEventStream,
    NftCollectionListingStatsStream: nft_collection_listing_stats_stream_1.NftCollectionListingStatsStream,
    NftPrimarySaleWithCollectionStream: nft_primary_sale_with_collection_stream_1.NftPrimarySaleWithCollectionStream,
    FraktEventStream: frakt_event_stream_1.FraktEventStream,
    LpBalanceChangeStream: lp_balance_change_stream_1.LpBalanceChangeStream,
    BalanceChangeStream: balance_change_stream_1.BalanceChangeStream,
    NftPrimarySaleWithoutCollectionStream: nft_primary_sale_without_collection_stream_1.NftPrimarySaleWithoutCollectionStream,
    NftMarketActionStream: nft_market_action_stream_1.NftMarketActionStream,
    StarAtlasEventStream: star_atlas_event_stream_1.StarAtlasEventStream,
    TokenPriceStream: token_price_stream_1.TokenPriceStream,
    TokenTransferStream: token_transfer_stream_1.TokenTransferStream,
    TokenSwapStream: token_swap_stream_1.TokenSwapStream,
    LpDepositWithdrawalStream: lp_deposit_withdrawal_stream_1.LpDepositWithdrawalStream,
};
