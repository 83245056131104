import { __awaiter } from '../../../../_virtual/_tslib.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { CaptchaWaveIcon } from '@dynamic-labs/iconic';
import { isEmbeddedConnector, isBloctoConnector } from '@dynamic-labs/wallet-connector-core';
import 'react';
import { Typography } from '../../components/Typography/Typography.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import { useViewContext } from '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../utils/constants/colors.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/multi-wallet';
import { requiresTwoStepAuthentication } from '../../utils/functions/requiresTwoStepAuthentication/requiresTwoStepAuthentication.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../context/ThemeContext/ThemeContext.js';
import 'react-dom';
import 'react-focus-lock';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import { useVerifyWallet } from '../../utils/hooks/useVerifyWallet/useVerifyWallet.js';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import '../../context/MockContext/MockContext.js';
import '../../context/QrCodeContext/QrCodeContext.js';
import 'react-i18next';
import { PoweredByDynamic } from '../../components/PoweredByDynamic/PoweredByDynamic.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { useCaptchaContext } from '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import '../../components/IconButton/IconButton.js';
import '../../components/Alert/Alert.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import { Captcha } from '../../components/Captcha/Captcha.js';

const CaptchaVerifyUser = () => {
    const { consumeNonce, displaySiweStatement, environmentId, projectSettings, setIsSingleWalletAccount, selectedWalletConnector: walletConnector, walletUiUtils, } = useInternalDynamicContext();
    const { setView } = useViewContext();
    const { setCaptchaToken } = useCaptchaContext();
    const verifyWallet = useVerifyWallet({
        consumeNonce,
        displaySiweStatement,
        environmentId,
        projectSettings,
        setIsSingleWalletAccount,
    });
    if (!walletConnector) {
        return null;
    }
    const handleSuccess = (captchaToken) => __awaiter(void 0, void 0, void 0, function* () {
        const publicWalletAddress = yield walletConnector.fetchPublicAddress();
        if (!publicWalletAddress) {
            return;
        }
        if (!isEmbeddedConnector(walletConnector) ||
            isBloctoConnector(walletConnector)) {
            setView('pending-signature');
        }
        else {
            walletUiUtils.disabledConfirmationOnce();
        }
        if (requiresTwoStepAuthentication(walletConnector)) {
            setCaptchaToken(captchaToken);
            return;
        }
        yield verifyWallet({
            captchaToken,
            publicWalletAddress,
            walletConnector,
        });
    });
    return (jsxs("div", { className: 'captcha-verify-user__container', children: [jsx(CaptchaWaveIcon, { className: 'captcha-verify-user__icon' }), jsx(Typography, { weight: 'medium', variant: 'title', color: 'primary', className: 'captcha-verify-user__title', children: "Let\u2019s verify you\u2019re human" }), jsx(Typography, { variant: 'body_normal', weight: 'regular', color: 'secondary', className: 'captcha-verify-user__subtitle', children: "We need to quickly verify you\u2019re human before we proceed." }), jsx(Captcha, { handleSuccess: handleSuccess }), jsx(PoweredByDynamic, { classNameRoot: 'captcha-verify-user__dynamic-logo' })] }));
};

export { CaptchaVerifyUser };
