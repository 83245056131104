"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConfig = exports.getCreateConfigInstructionDataSerializer = void 0;
const umi_1 = require("@metaplex-foundation/umi");
const serializers_1 = require("@metaplex-foundation/umi/serializers");
const shared_1 = require("../shared");
function getCreateConfigInstructionDataSerializer() {
    return (0, serializers_1.mapSerializer)((0, serializers_1.struct)([['discriminator', (0, serializers_1.array)((0, serializers_1.u8)(), { size: 8 })]], { description: 'CreateConfigInstructionData' }), (value) => (Object.assign(Object.assign({}, value), { discriminator: [201, 207, 243, 114, 75, 111, 47, 189] })));
}
exports.getCreateConfigInstructionDataSerializer = getCreateConfigInstructionDataSerializer;
// Instruction.
function createConfig(context, input) {
    var _a, _b, _c, _d, _e, _f;
    // Program ID.
    const programId = context.programs.getPublicKey('govBoarding', 'GovyJPza6EV6srUcmwA1vS3EmWGdLSkkDafRE54X1Dir');
    // Accounts.
    const resolvedAccounts = {
        admin: {
            index: 0,
            isWritable: true,
            value: (_a = input.admin) !== null && _a !== void 0 ? _a : null,
        },
        config: {
            index: 1,
            isWritable: true,
            value: (_b = input.config) !== null && _b !== void 0 ? _b : null,
        },
        verifierRealm: {
            index: 2,
            isWritable: false,
            value: (_c = input.verifierRealm) !== null && _c !== void 0 ? _c : null,
        },
        rent: { index: 3, isWritable: false, value: (_d = input.rent) !== null && _d !== void 0 ? _d : null },
        systemProgram: {
            index: 4,
            isWritable: false,
            value: (_e = input.systemProgram) !== null && _e !== void 0 ? _e : null,
        },
        verifier: {
            index: 5,
            isWritable: false,
            value: (_f = input.verifier) !== null && _f !== void 0 ? _f : null,
        },
    };
    // Default values.
    if (!resolvedAccounts.rent.value) {
        resolvedAccounts.rent.value = (0, umi_1.publicKey)('SysvarRent111111111111111111111111111111111');
    }
    if (!resolvedAccounts.systemProgram.value) {
        resolvedAccounts.systemProgram.value = context.programs.getPublicKey('splSystem', '11111111111111111111111111111111');
        resolvedAccounts.systemProgram.isWritable = false;
    }
    // Accounts in order.
    const orderedAccounts = Object.values(resolvedAccounts).sort((a, b) => a.index - b.index);
    // Keys and Signers.
    const [keys, signers] = (0, shared_1.getAccountMetasAndSigners)(orderedAccounts, 'programId', programId);
    // Data.
    const data = getCreateConfigInstructionDataSerializer().serialize({});
    // Bytes Created On Chain.
    const bytesCreatedOnChain = 0;
    return (0, umi_1.transactionBuilder)([
        { instruction: { keys, programId, data }, signers, bytesCreatedOnChain },
    ]);
}
exports.createConfig = createConfig;
