"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParentLinksSerializer = void 0;
const serializers_1 = require("@metaplex-foundation/umi/serializers");
const _1 = require(".");
function getParentLinksSerializer() {
    return (0, serializers_1.struct)([['realms', (0, serializers_1.array)((0, _1.getParentLinkSerializer)())]], {
        description: 'ParentLinks',
    });
}
exports.getParentLinksSerializer = getParentLinksSerializer;
