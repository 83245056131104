import {
  Connection,
  PublicKey,
  MemcmpFilter,
  Keypair,
  TransactionInstruction,
  Transaction,
} from "@solana/web3.js";
import { NAME_OFFERS_ID } from "./bindings";
import { Offer, FixedPriceOffer } from "./state";

export const getOffersForName = async (
  connection: Connection,
  name: PublicKey
) => {
  const filters: MemcmpFilter[] = [
    {
      memcmp: {
        offset: 1 + 1,
        bytes: name.toBase58(),
      },
    },
    {
      memcmp: {
        offset: 0,
        bytes: "2",
      },
    },
  ];
  const result = await connection.getProgramAccounts(NAME_OFFERS_ID, {
    filters,
  });
  return result.map((r) => {
    return { pubkey: r.pubkey, offer: Offer.deserialize(r.account.data) };
  });
};

export const getOffersForOwner = async (
  connection: Connection,
  owner: PublicKey
) => {
  const filters: MemcmpFilter[] = [
    {
      memcmp: {
        offset: 1 + 1 + 32,
        bytes: owner.toBase58(),
      },
    },
    {
      memcmp: {
        offset: 0,
        bytes: "2",
      },
    },
  ];
  const result = await connection.getProgramAccounts(NAME_OFFERS_ID, {
    filters,
  });
  return result.map((r) => {
    return { pubkey: r.pubkey, offer: Offer.deserialize(r.account.data) };
  });
};

export const getFixedPriceOffersForName = async (
  connection: Connection,
  name: PublicKey
) => {
  const filters: MemcmpFilter[] = [
    {
      memcmp: {
        offset: 1 + 1,
        bytes: name.toBase58(),
      },
    },
    {
      memcmp: {
        offset: 0,
        bytes: "6",
      },
    },
  ];
  const result = await connection.getProgramAccounts(NAME_OFFERS_ID, {
    filters,
  });
  return result.map((r) => {
    return {
      pubkey: r.pubkey,
      offer: FixedPriceOffer.deserialize(r.account.data),
    };
  });
};

export const getFixedPriceOffersForOwner = async (
  connection: Connection,
  owner: PublicKey
) => {
  const filters: MemcmpFilter[] = [
    {
      memcmp: {
        offset: 1 + 1 + 32,
        bytes: owner.toBase58(),
      },
    },
    {
      memcmp: {
        offset: 0,
        bytes: "6",
      },
    },
  ];
  const result = await connection.getProgramAccounts(NAME_OFFERS_ID, {
    filters,
  });
  return result.map((r) => {
    return {
      pubkey: r.pubkey,
      offer: FixedPriceOffer.deserialize(r.account.data),
    };
  });
};

export const getAllFixedPrice = async (connection: Connection) => {
  const filters: MemcmpFilter[] = [
    {
      memcmp: {
        offset: 0,
        bytes: "6",
      },
    },
  ];
  const result = await connection.getProgramAccounts(NAME_OFFERS_ID, {
    filters,
  });
  return result.map((r) => {
    return {
      pubkey: r.pubkey,
      offer: FixedPriceOffer.deserialize(r.account.data),
    };
  });
};

export async function sleep(ms: number) {
  console.log("Sleeping for ", ms, " ms");
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

export const signAndSendTransactionInstructions = async (
  // sign and send transaction
  connection: Connection,
  signers: Array<Keypair>,
  feePayer: Keypair,
  txInstructions: Array<TransactionInstruction>
): Promise<string> => {
  const tx = new Transaction();
  tx.feePayer = feePayer.publicKey;
  signers.push(feePayer);
  tx.add(...txInstructions);
  return await connection.sendTransaction(tx, signers, {
    skipPreflight: false,
  });
};
