import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import 'react';
import { Typography } from '../../../../components/Typography/Typography.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgAdd } from '../../../../shared/assets/add.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../../utils/constants/colors.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/multi-wallet';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../context/ThemeContext/ThemeContext.js';
import 'react-dom';
import 'react-focus-lock';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../context/MockContext/MockContext.js';
import '../../../../context/QrCodeContext/QrCodeContext.js';
import 'react-i18next';
import { DynamicWidgetFooter } from '../../components/DynamicWidgetFooter/DynamicWidgetFooter.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../context/DynamicWidgetContext.js';
import { DynamicWidgetWallets } from '../../components/DynamicWidgetWallets/DynamicWidgetWallets.js';
import { SingleWalletButtons } from '../../components/SingleWalletButtons/SingleWalletButtons.js';
import 'yup';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import { Button } from '../../../../components/Button/Button.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import { useKYCFlag } from '../../../../utils/hooks/useKYCFlag/useKYCFlag.js';
import { useMultiWalletFlag } from '../../../../utils/hooks/useMultiWalletFlag/useMultiWalletFlag.js';
import { LogoutButton } from '../../../../components/LogoutButton/LogoutButton.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';

const WalletsView = () => {
    const { setShowAuthFlow, setSelectedWalletConnector, user, authMode } = useInternalDynamicContext();
    const isKYCEnabled = useKYCFlag();
    const isMultiWalletEnabled = useMultiWalletFlag();
    const { setView } = useViewContext();
    const addNewWallet = () => {
        setView('multi-wallet-wallet-list');
        setShowAuthFlow(true);
        setSelectedWalletConnector(null);
    };
    return (jsxs(Fragment, { children: [!isMultiWalletEnabled && !isKYCEnabled && (jsxs("div", { className: 'dynamic-widget-index-view__email-container', children: [jsx(Typography, { children: user === null || user === void 0 ? void 0 : user.email }), jsx(SingleWalletButtons, {})] })), isMultiWalletEnabled && (jsxs(Fragment, { children: [jsx(DynamicWidgetWallets, {}), jsx(DynamicWidgetFooter, { children: jsxs("div", { className: 'dynamic-widget-index-view__footer', children: [jsxs(Button, { buttonClassName: 'dynamic-widget-footer__button', buttonVariant: 'tertiary', buttonPadding: 'none', onClick: addNewWallet, typographyProps: {
                                        color: 'secondary',
                                        variant: 'button_tertiary',
                                    }, startSlot: jsx(SvgAdd, {}), children: [authMode === 'connect-only' ? 'Connect' : 'Link', " a new wallet"] }), jsx(LogoutButton, { isTextButton: true, buttonClassName: 'dynamic-widget-footer__button' })] }) })] }))] }));
};

export { WalletsView };
