"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftMarketActionStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    helloMoonCollectionId: { type: "TEXT" },
    collectionName: { type: "TEXT" },
    price: { type: "NUMERIC" },
    authority: { type: "COMPOSITE", dataType: "TEXT", fields: ["seller", "buyer"] },
    marketName: { type: "ENUM" },
    marketActionType: { type: "ENUM" },
    mint: { type: "TEXT" },
    programId: { type: "TEXT" },
    instructionName: { type: "TEXT" },
    blockTime: { type: "DATE" },
    blockId: { type: "NUMERIC" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
};
class NftMarketActionStream extends base_data_stream_class_1.BaseDataStream {
    id = "NftMarketAction";
    static fieldMap = fieldMap;
}
exports.NftMarketActionStream = NftMarketActionStream;
