"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CitrusLoanEventsRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class CitrusLoanEventsRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/citrus/loan-events";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.CitrusLoanEventsRequest = CitrusLoanEventsRequest;
