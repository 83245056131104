"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeSubscription = void 0;
const stream_map_const_1 = require("../streams/stream-map.const");
const json_logic_util_fn_1 = require("./json-logic-util.fn");
const deserializeSubscription = (subscription) => {
    if (subscription.ruleType === "parsedaccountfilter" ||
        subscription.ruleType === "unparsedtransaction" ||
        subscription.ruleType === "accountfilter") {
        return {
            subscriptionId: subscription.id,
            id: subscription.dataStream,
            filters: subscription.filter,
            authDetails: subscription.authDetails,
            dateCreated: subscription.dateCreated,
            dateLastUpdated: subscription.dateLastUpdated,
            name: subscription.name,
            ruleType: subscription.ruleType,
            target: {
                targetType: subscription.targetType,
                targetValue: subscription.targetValue
            },
            toSubscription: () => {
                return null;
            },
        };
    }
    else {
        const key = `${subscription.dataStream}Stream`;
        if (key in stream_map_const_1.streamMap === false)
            return null;
        const filter = subscription.filter
            ? json_logic_util_fn_1.jsonLogicUtil.deserialize(subscription.filter ?? [], stream_map_const_1.streamMap[key].fieldMap)
            : undefined;
        const dateCreated = subscription.dateCreated
            ? new Date(parseInt(subscription.dateCreated))
            : undefined;
        const dateLastUpdated = subscription.dateLastUpdated
            ? new Date(parseInt(subscription.dateLastUpdated))
            : undefined;
        return new stream_map_const_1.streamMap[key]({
            target: {
                targetType: subscription.targetType,
                targetValue: subscription.targetValue,
            },
            authDetails: subscription.authDetails,
            name: subscription.name,
            filters: filter,
            subscriptionId: subscription.id,
            ruleType: subscription.ruleType,
        }, {
            dateCreated,
            dateLastUpdated,
        });
    }
};
exports.deserializeSubscription = deserializeSubscription;
