"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceChangeStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    account: { type: "COMPOSITE", dataType: "TEXT", fields: ["account", "accountOwner"] },
    mint: { type: "TEXT" },
    amount: { type: "NUMERIC" },
    balance: { type: "COMPOSITE", dataType: "NUMERIC", fields: ["preBalance", "postBalance"] },
    decimals: { type: "NUMERIC" },
    blockTime: { type: "DATE" },
    blockId: { type: "NUMERIC" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
};
class BalanceChangeStream extends base_data_stream_class_1.BaseDataStream {
    id = "BalanceChange";
    static fieldMap = fieldMap;
}
exports.BalanceChangeStream = BalanceChangeStream;
