"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEntry = exports.getCreateEntryInstructionDataSerializer = void 0;
const umi_1 = require("@metaplex-foundation/umi");
const serializers_1 = require("@metaplex-foundation/umi/serializers");
const shared_1 = require("../shared");
const types_1 = require("../types");
function getCreateEntryInstructionDataSerializer() {
    return (0, serializers_1.mapSerializer)((0, serializers_1.struct)([
        ['discriminator', (0, serializers_1.array)((0, serializers_1.u8)(), { size: 8 })],
        ['name', (0, serializers_1.string)()],
        ['metadataUri', (0, serializers_1.string)()],
        ['govAccountType', (0, types_1.getDAOTypeSerializer)()],
        ['parents', (0, serializers_1.option)((0, serializers_1.array)((0, serializers_1.publicKey)()))],
    ], { description: 'CreateEntryInstructionData' }), (value) => (Object.assign(Object.assign({}, value), { discriminator: [248, 207, 142, 242, 66, 162, 150, 16] })));
}
exports.getCreateEntryInstructionDataSerializer = getCreateEntryInstructionDataSerializer;
// Instruction.
function createEntry(context, input) {
    var _a, _b, _c, _d, _e, _f;
    // Program ID.
    const programId = context.programs.getPublicKey('govBoarding', 'GovyJPza6EV6srUcmwA1vS3EmWGdLSkkDafRE54X1Dir');
    // Accounts.
    const resolvedAccounts = {
        payer: {
            index: 0,
            isWritable: true,
            value: (_a = input.payer) !== null && _a !== void 0 ? _a : null,
        },
        governanceEntry: {
            index: 1,
            isWritable: true,
            value: (_b = input.governanceEntry) !== null && _b !== void 0 ? _b : null,
        },
        config: {
            index: 2,
            isWritable: false,
            value: (_c = input.config) !== null && _c !== void 0 ? _c : null,
        },
        governanceProgram: {
            index: 3,
            isWritable: false,
            value: (_d = input.governanceProgram) !== null && _d !== void 0 ? _d : null,
        },
        rent: { index: 4, isWritable: false, value: (_e = input.rent) !== null && _e !== void 0 ? _e : null },
        systemProgram: {
            index: 5,
            isWritable: false,
            value: (_f = input.systemProgram) !== null && _f !== void 0 ? _f : null,
        },
    };
    // Arguments.
    const resolvedArgs = Object.assign({}, input);
    // Default values.
    if (!resolvedAccounts.payer.value) {
        resolvedAccounts.payer.value = context.payer;
    }
    if (!resolvedAccounts.rent.value) {
        resolvedAccounts.rent.value = (0, umi_1.publicKey)('SysvarRent111111111111111111111111111111111');
    }
    if (!resolvedAccounts.systemProgram.value) {
        resolvedAccounts.systemProgram.value = context.programs.getPublicKey('splSystem', '11111111111111111111111111111111');
        resolvedAccounts.systemProgram.isWritable = false;
    }
    // Accounts in order.
    const orderedAccounts = Object.values(resolvedAccounts).sort((a, b) => a.index - b.index);
    // Keys and Signers.
    const [keys, signers] = (0, shared_1.getAccountMetasAndSigners)(orderedAccounts, 'programId', programId);
    // Data.
    const data = getCreateEntryInstructionDataSerializer().serialize(resolvedArgs);
    // Bytes Created On Chain.
    const bytesCreatedOnChain = 0;
    return (0, umi_1.transactionBuilder)([
        { instruction: { keys, programId, data }, signers, bytesCreatedOnChain },
    ]);
}
exports.createEntry = createEntry;
