import { jsx, jsxs } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import 'react';
import { ReactComponent as SvgFooterKeyIcon } from '../../../../shared/assets/footer-key-icon.js';
import { ReactComponent as SvgUserAccount2 } from '../../../../shared/assets/user-account-2.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../../utils/constants/colors.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/multi-wallet';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../context/ThemeContext/ThemeContext.js';
import { Icon } from '../../../../components/Icon/Icon.js';
import 'react-dom';
import 'react-focus-lock';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../context/MockContext/MockContext.js';
import '../../../../context/QrCodeContext/QrCodeContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import { EnterTileAnimation } from '../../../../components/EnterTileAnimation/EnterTileAnimation.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';

const PasskeyInfoHelper = () => {
    const { t } = useTranslation();
    return (jsx("div", { className: 'passkey-info-help-content__container', children: jsx(EnterTileAnimation, { delay: '300ms', children: jsxs("div", { className: 'passkey-info-help-content__typography-wrapper', children: [jsxs("div", { className: 'passkey-info-help-content__details', children: [jsx(Icon, { size: 'medium', color: 'text-tertiary', children: jsx(SvgUserAccount2, {}) }), jsxs("div", { className: 'passkey-info-help-content__wrapper', children: [jsx(Typography, { variant: 'title', weight: 'medium', children: t('dyn_passkey_intro.helper.section_1.title') }), jsx(Typography, { as: 'p', variant: 'body_normal', weight: 'regular', color: 'secondary', className: 'passkey-info-help-content__typography--label', children: t('dyn_passkey_intro.helper.section_1.description') })] })] }), jsxs("div", { className: 'passkey-info-help-content__details', children: [jsx(Icon, { size: 'medium', color: 'text-tertiary', children: jsx(SvgFooterKeyIcon, {}) }), jsxs("div", { className: 'passkey-info-help-content__wrapper', children: [jsx(Typography, { variant: 'title', weight: 'medium', children: t('dyn_passkey_intro.helper.section_2.title') }), jsx(Typography, { as: 'p', variant: 'body_normal', weight: 'regular', color: 'secondary', className: 'passkey-info-help-content__typography--label', children: t('dyn_passkey_intro.helper.section_2.description') })] })] })] }) }) }));
};

export { PasskeyInfoHelper };
