export type Dca = {
  version: '0.1.0';
  name: 'dca';
  instructions: [
    {
      name: 'openDca';
      accounts: [
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'user';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'inputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'outputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'userAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'inAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'outAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'eventAuthority';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'program';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'applicationIdx';
          type: 'u64';
        },
        {
          name: 'inAmount';
          type: 'u64';
        },
        {
          name: 'inAmountPerCycle';
          type: 'u64';
        },
        {
          name: 'cycleFrequency';
          type: 'i64';
        },
        {
          name: 'minPrice';
          type: {
            option: 'u64';
          };
        },
        {
          name: 'maxPrice';
          type: {
            option: 'u64';
          };
        },
        {
          name: 'startAt';
          type: {
            option: 'i64';
          };
        },
        {
          name: 'closeWsolInAta';
          type: {
            option: 'bool';
          };
        },
      ];
    },
    {
      name: 'openDcaV2';
      accounts: [
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'user';
          isMut: false;
          isSigner: true;
        },
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'inputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'outputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'userAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'inAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'outAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'eventAuthority';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'program';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'applicationIdx';
          type: 'u64';
        },
        {
          name: 'inAmount';
          type: 'u64';
        },
        {
          name: 'inAmountPerCycle';
          type: 'u64';
        },
        {
          name: 'cycleFrequency';
          type: 'i64';
        },
        {
          name: 'minPrice';
          type: {
            option: 'u64';
          };
        },
        {
          name: 'maxPrice';
          type: {
            option: 'u64';
          };
        },
        {
          name: 'startAt';
          type: {
            option: 'i64';
          };
        },
      ];
    },
    {
      name: 'closeDca';
      accounts: [
        {
          name: 'user';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'inputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'outputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'inAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'outAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'userInAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'userOutAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'eventAuthority';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'program';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'withdraw';
      accounts: [
        {
          name: 'user';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'inputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'outputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'dcaAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'userInAta';
          isMut: true;
          isSigner: false;
          isOptional: true;
        },
        {
          name: 'userOutAta';
          isMut: true;
          isSigner: false;
          isOptional: true;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'eventAuthority';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'program';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'withdrawParams';
          type: {
            defined: 'WithdrawParams';
          };
        },
      ];
    },
    {
      name: 'deposit';
      accounts: [
        {
          name: 'user';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'inAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'userInAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'eventAuthority';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'program';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'depositIn';
          type: 'u64';
        },
      ];
    },
    {
      name: 'withdrawFees';
      accounts: [
        {
          name: 'admin';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'mint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'feeAuthority';
          isMut: false;
          isSigner: false;
          docs: ['CHECK'];
        },
        {
          name: 'programFeeAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'adminFeeAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'amount';
          type: 'u64';
        },
      ];
    },
    {
      name: 'initiateFlashFill';
      accounts: [
        {
          name: 'keeper';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'inputMint';
          isMut: false;
          isSigner: false;
          docs: ['The token to borrow'];
        },
        {
          name: 'keeperInAta';
          isMut: true;
          isSigner: false;
          docs: ['The account to send borrowed tokens to'];
        },
        {
          name: 'inAta';
          isMut: true;
          isSigner: false;
          docs: ['The account to borrow from'];
        },
        {
          name: 'outAta';
          isMut: false;
          isSigner: false;
          docs: ['The account to repay to'];
        },
        {
          name: 'instructionsSysvar';
          isMut: false;
          isSigner: false;
          docs: ['Solana Instructions Sysvar'];
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'fulfillFlashFill';
      accounts: [
        {
          name: 'keeper';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'inputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'outputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'keeperInAta';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'inAta';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'outAta';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'feeAuthority';
          isMut: false;
          isSigner: false;
          docs: ['CHECK'];
        },
        {
          name: 'feeAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'instructionsSysvar';
          isMut: false;
          isSigner: false;
          docs: ['Solana Instructions Sysvar'];
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'eventAuthority';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'program';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'repayAmount';
          type: 'u64';
        },
      ];
    },
    {
      name: 'transfer';
      accounts: [
        {
          name: 'keeper';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'user';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'outputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'dcaOutAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'userOutAta';
          isMut: true;
          isSigner: false;
          isOptional: true;
        },
        {
          name: 'intermediateAccount';
          isMut: true;
          isSigner: false;
          isOptional: true;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'eventAuthority';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'program';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'endAndClose';
      accounts: [
        {
          name: 'keeper';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'dca';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'inputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'outputMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'inAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'outAta';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'user';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'userOutAta';
          isMut: true;
          isSigner: false;
          isOptional: true;
        },
        {
          name: 'initUserOutAta';
          isMut: true;
          isSigner: false;
          isOptional: true;
        },
        {
          name: 'intermediateAccount';
          isMut: true;
          isSigner: false;
          isOptional: true;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'eventAuthority';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'program';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
  ];
  accounts: [
    {
      name: 'dca';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'user';
            type: 'publicKey';
          },
          {
            name: 'inputMint';
            type: 'publicKey';
          },
          {
            name: 'outputMint';
            type: 'publicKey';
          },
          {
            name: 'idx';
            type: 'u64';
          },
          {
            name: 'nextCycleAt';
            type: 'i64';
          },
          {
            name: 'inDeposited';
            type: 'u64';
          },
          {
            name: 'inWithdrawn';
            type: 'u64';
          },
          {
            name: 'outWithdrawn';
            type: 'u64';
          },
          {
            name: 'inUsed';
            type: 'u64';
          },
          {
            name: 'outReceived';
            type: 'u64';
          },
          {
            name: 'inAmountPerCycle';
            type: 'u64';
          },
          {
            name: 'cycleFrequency';
            type: 'i64';
          },
          {
            name: 'nextCycleAmountLeft';
            type: 'u64';
          },
          {
            name: 'inAccount';
            type: 'publicKey';
          },
          {
            name: 'outAccount';
            type: 'publicKey';
          },
          {
            name: 'minOutAmount';
            type: 'u64';
          },
          {
            name: 'maxOutAmount';
            type: 'u64';
          },
          {
            name: 'keeperInBalanceBeforeBorrow';
            type: 'u64';
          },
          {
            name: 'dcaOutBalanceBeforeSwap';
            type: 'u64';
          },
          {
            name: 'createdAt';
            type: 'i64';
          },
          {
            name: 'bump';
            type: 'u8';
          },
        ];
      };
    },
  ];
  types: [
    {
      name: 'WithdrawParams';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'withdrawAmount';
            type: 'u64';
          },
          {
            name: 'withdrawal';
            type: {
              defined: 'Withdrawal';
            };
          },
        ];
      };
    },
    {
      name: 'Withdrawal';
      type: {
        kind: 'enum';
        variants: [
          {
            name: 'In';
          },
          {
            name: 'Out';
          },
        ];
      };
    },
  ];
  events: [
    {
      name: 'CollectedFee';
      fields: [
        {
          name: 'userKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'dcaKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'mint';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'amount';
          type: 'u64';
          index: false;
        },
      ];
    },
    {
      name: 'Filled';
      fields: [
        {
          name: 'userKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'dcaKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'inputMint';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'outputMint';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'inAmount';
          type: 'u64';
          index: false;
        },
        {
          name: 'outAmount';
          type: 'u64';
          index: false;
        },
        {
          name: 'feeMint';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'fee';
          type: 'u64';
          index: false;
        },
      ];
    },
    {
      name: 'Opened';
      fields: [
        {
          name: 'userKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'dcaKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'inDeposited';
          type: 'u64';
          index: false;
        },
        {
          name: 'inputMint';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'outputMint';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'cycleFrequency';
          type: 'i64';
          index: false;
        },
        {
          name: 'inAmountPerCycle';
          type: 'u64';
          index: false;
        },
        {
          name: 'createdAt';
          type: 'i64';
          index: false;
        },
      ];
    },
    {
      name: 'Closed';
      fields: [
        {
          name: 'userKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'dcaKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'inDeposited';
          type: 'u64';
          index: false;
        },
        {
          name: 'inputMint';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'outputMint';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'cycleFrequency';
          type: 'i64';
          index: false;
        },
        {
          name: 'inAmountPerCycle';
          type: 'u64';
          index: false;
        },
        {
          name: 'createdAt';
          type: 'i64';
          index: false;
        },
        {
          name: 'totalInWithdrawn';
          type: 'u64';
          index: false;
        },
        {
          name: 'totalOutWithdrawn';
          type: 'u64';
          index: false;
        },
        {
          name: 'unfilledAmount';
          type: 'u64';
          index: false;
        },
        {
          name: 'userClosed';
          type: 'bool';
          index: false;
        },
      ];
    },
    {
      name: 'Withdraw';
      fields: [
        {
          name: 'dcaKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'inAmount';
          type: 'u64';
          index: false;
        },
        {
          name: 'outAmount';
          type: 'u64';
          index: false;
        },
        {
          name: 'userWithdraw';
          type: 'bool';
          index: false;
        },
      ];
    },
    {
      name: 'Deposit';
      fields: [
        {
          name: 'dcaKey';
          type: 'publicKey';
          index: false;
        },
        {
          name: 'amount';
          type: 'u64';
          index: false;
        },
      ];
    },
  ];
  errors: [
    {
      code: 6000;
      name: 'InvalidAmount';
      msg: 'Invalid deposit amount';
    },
    {
      code: 6001;
      name: 'InvalidCycleAmount';
      msg: 'Invalid deposit amount';
    },
    {
      code: 6002;
      name: 'InvalidPair';
      msg: 'Invalid pair';
    },
    {
      code: 6003;
      name: 'TooFrequent';
      msg: 'Too frequent DCA cycle';
    },
    {
      code: 6004;
      name: 'InvalidMinPrice';
      msg: 'Minimum price constraint must be greater than 0';
    },
    {
      code: 6005;
      name: 'InvalidMaxPrice';
      msg: 'Maximum price constraint must be greater than 0';
    },
    {
      code: 6006;
      name: 'InAmountInsufficient';
      msg: 'In amount needs to be more than in amount per cycle';
    },
    {
      code: 6007;
      name: 'Unauthorized';
      msg: 'Wrong user';
    },
    {
      code: 6008;
      name: 'NoInATA';
      msg: 'inAta not passed in';
    },
    {
      code: 6009;
      name: 'NoUserInATA';
      msg: 'userInAta not passed in';
    },
    {
      code: 6010;
      name: 'NoOutATA';
      msg: 'outAta not passed in';
    },
    {
      code: 6011;
      name: 'NoUserOutATA';
      msg: 'userOutAta not passed in';
    },
    {
      code: 6012;
      name: 'InsufficientBalanceInProgram';
      msg: 'Trying to withdraw more than available';
    },
    {
      code: 6013;
      name: 'InvalidDepositAmount';
      msg: 'Deposit should be more than 0';
    },
    {
      code: 6014;
      name: 'UserInsufficientBalance';
      msg: 'User has insufficient balance';
    },
    {
      code: 6015;
      name: 'UnauthorizedKeeper';
      msg: 'Unauthorized Keeper';
    },
    {
      code: 6016;
      name: 'UnrecognizedProgram';
      msg: 'Unrecognized Program';
    },
    {
      code: 6017;
      name: 'MathErrors';
      msg: 'Calculation errors';
    },
    {
      code: 6018;
      name: 'KeeperNotTimeToFill';
      msg: 'Not time to fill';
    },
    {
      code: 6019;
      name: 'OrderFillAmountWrong';
      msg: 'Order amount wrong';
    },
    {
      code: 6020;
      name: 'SwapOutAmountBelowMinimum';
      msg: 'Out amount below expectations';
    },
    {
      code: 6021;
      name: 'WrongAdmin';
      msg: 'Wrong admin';
    },
    {
      code: 6022;
      name: 'MathOverflow';
      msg: 'Overflow in arithmetic operation';
    },
    {
      code: 6023;
      name: 'AddressMismatch';
      msg: 'Address Mismatch';
    },
    {
      code: 6024;
      name: 'ProgramMismatch';
      msg: 'Program Mismatch';
    },
    {
      code: 6025;
      name: 'IncorrectRepaymentAmount';
      msg: 'Incorrect Repayment Amount';
    },
    {
      code: 6026;
      name: 'CannotBorrowBeforeRepay';
      msg: 'Cannot Borrow Before Repay';
    },
    {
      code: 6027;
      name: 'NoRepaymentInstructionFound';
      msg: 'No Repayment Found';
    },
    {
      code: 6028;
      name: 'MissingSwapInstructions';
      msg: 'Missing Swap Instruction';
    },
    {
      code: 6029;
      name: 'UnexpectedSwapProgram';
      msg: 'Expected Instruction to use Jupiter Swap Program';
    },
    {
      code: 6030;
      name: 'UnknownInstruction';
      msg: 'Unknown Instruction';
    },
    {
      code: 6031;
      name: 'MissingRepayInstructions';
      msg: 'Missing Repay Instruction';
    },
    {
      code: 6032;
      name: 'KeeperShortchanged';
      msg: 'Keeper Shortchanged';
    },
    {
      code: 6033;
      name: 'WrongSwapOutAccount';
      msg: 'Jup Swap to Wrong Out Account';
    },
    {
      code: 6034;
      name: 'WrongTransferAmount';
      msg: 'Transfer amount should be exactly account balance';
    },
    {
      code: 6035;
      name: 'InsufficientBalanceForRent';
      msg: 'Insufficient balance for rent';
    },
    {
      code: 6036;
      name: 'UnexpectedSolBalance';
      msg: 'Unexpected SOL amount in intermediate account';
    },
    {
      code: 6037;
      name: 'InsufficientWsolForTransfer';
      msg: 'Too little WSOL to perform transfer';
    },
    {
      code: 6038;
      name: 'MissedInstruction';
      msg: 'Did not call initiate_flash_fill';
    },
    {
      code: 6039;
      name: 'WrongProgram';
      msg: "Did not call this program's initiate_flash_fill";
    },
    {
      code: 6040;
      name: 'BalanceNotZero';
      msg: "Can't close account with balance";
    },
    {
      code: 6041;
      name: 'UnexpectedWSOLLeftover';
      msg: 'Should not have WSOL leftover in DCA out-token account';
    },
    {
      code: 6042;
      name: 'IntermediateAccountNotSet';
      msg: 'Should pass in a WSOL intermediate account when transferring SOL';
    },
    {
      code: 6043;
      name: 'UnexpectedSwapInstruction';
      msg: 'Did not call jup swap';
    },
  ];
};

export const IDL: Dca = {
  version: '0.1.0',
  name: 'dca',
  instructions: [
    {
      name: 'openDca',
      accounts: [
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'user',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'inputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'outputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'userAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'inAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'outAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'applicationIdx',
          type: 'u64',
        },
        {
          name: 'inAmount',
          type: 'u64',
        },
        {
          name: 'inAmountPerCycle',
          type: 'u64',
        },
        {
          name: 'cycleFrequency',
          type: 'i64',
        },
        {
          name: 'minPrice',
          type: {
            option: 'u64',
          },
        },
        {
          name: 'maxPrice',
          type: {
            option: 'u64',
          },
        },
        {
          name: 'startAt',
          type: {
            option: 'i64',
          },
        },
        {
          name: 'closeWsolInAta',
          type: {
            option: 'bool',
          },
        },
      ],
    },
    {
      name: 'openDcaV2',
      accounts: [
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'user',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'inputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'outputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'userAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'inAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'outAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'applicationIdx',
          type: 'u64',
        },
        {
          name: 'inAmount',
          type: 'u64',
        },
        {
          name: 'inAmountPerCycle',
          type: 'u64',
        },
        {
          name: 'cycleFrequency',
          type: 'i64',
        },
        {
          name: 'minPrice',
          type: {
            option: 'u64',
          },
        },
        {
          name: 'maxPrice',
          type: {
            option: 'u64',
          },
        },
        {
          name: 'startAt',
          type: {
            option: 'i64',
          },
        },
      ],
    },
    {
      name: 'closeDca',
      accounts: [
        {
          name: 'user',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'inputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'outputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'inAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'outAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'userInAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'userOutAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'withdraw',
      accounts: [
        {
          name: 'user',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'inputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'outputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'dcaAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'userInAta',
          isMut: true,
          isSigner: false,
          isOptional: true,
        },
        {
          name: 'userOutAta',
          isMut: true,
          isSigner: false,
          isOptional: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'withdrawParams',
          type: {
            defined: 'WithdrawParams',
          },
        },
      ],
    },
    {
      name: 'deposit',
      accounts: [
        {
          name: 'user',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'inAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'userInAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'depositIn',
          type: 'u64',
        },
      ],
    },
    {
      name: 'withdrawFees',
      accounts: [
        {
          name: 'admin',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'feeAuthority',
          isMut: false,
          isSigner: false,
          docs: ['CHECK'],
        },
        {
          name: 'programFeeAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'adminFeeAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'initiateFlashFill',
      accounts: [
        {
          name: 'keeper',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'inputMint',
          isMut: false,
          isSigner: false,
          docs: ['The token to borrow'],
        },
        {
          name: 'keeperInAta',
          isMut: true,
          isSigner: false,
          docs: ['The account to send borrowed tokens to'],
        },
        {
          name: 'inAta',
          isMut: true,
          isSigner: false,
          docs: ['The account to borrow from'],
        },
        {
          name: 'outAta',
          isMut: false,
          isSigner: false,
          docs: ['The account to repay to'],
        },
        {
          name: 'instructionsSysvar',
          isMut: false,
          isSigner: false,
          docs: ['Solana Instructions Sysvar'],
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'fulfillFlashFill',
      accounts: [
        {
          name: 'keeper',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'inputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'outputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'keeperInAta',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'inAta',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'outAta',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'feeAuthority',
          isMut: false,
          isSigner: false,
          docs: ['CHECK'],
        },
        {
          name: 'feeAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'instructionsSysvar',
          isMut: false,
          isSigner: false,
          docs: ['Solana Instructions Sysvar'],
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'repayAmount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'transfer',
      accounts: [
        {
          name: 'keeper',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'user',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'outputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'dcaOutAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'userOutAta',
          isMut: true,
          isSigner: false,
          isOptional: true,
        },
        {
          name: 'intermediateAccount',
          isMut: true,
          isSigner: false,
          isOptional: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'endAndClose',
      accounts: [
        {
          name: 'keeper',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'dca',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'inputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'outputMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'inAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'outAta',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'user',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'userOutAta',
          isMut: true,
          isSigner: false,
          isOptional: true,
        },
        {
          name: 'initUserOutAta',
          isMut: true,
          isSigner: false,
          isOptional: true,
        },
        {
          name: 'intermediateAccount',
          isMut: true,
          isSigner: false,
          isOptional: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'eventAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'program',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: 'dca',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'user',
            type: 'publicKey',
          },
          {
            name: 'inputMint',
            type: 'publicKey',
          },
          {
            name: 'outputMint',
            type: 'publicKey',
          },
          {
            name: 'idx',
            type: 'u64',
          },
          {
            name: 'nextCycleAt',
            type: 'i64',
          },
          {
            name: 'inDeposited',
            type: 'u64',
          },
          {
            name: 'inWithdrawn',
            type: 'u64',
          },
          {
            name: 'outWithdrawn',
            type: 'u64',
          },
          {
            name: 'inUsed',
            type: 'u64',
          },
          {
            name: 'outReceived',
            type: 'u64',
          },
          {
            name: 'inAmountPerCycle',
            type: 'u64',
          },
          {
            name: 'cycleFrequency',
            type: 'i64',
          },
          {
            name: 'nextCycleAmountLeft',
            type: 'u64',
          },
          {
            name: 'inAccount',
            type: 'publicKey',
          },
          {
            name: 'outAccount',
            type: 'publicKey',
          },
          {
            name: 'minOutAmount',
            type: 'u64',
          },
          {
            name: 'maxOutAmount',
            type: 'u64',
          },
          {
            name: 'keeperInBalanceBeforeBorrow',
            type: 'u64',
          },
          {
            name: 'dcaOutBalanceBeforeSwap',
            type: 'u64',
          },
          {
            name: 'createdAt',
            type: 'i64',
          },
          {
            name: 'bump',
            type: 'u8',
          },
        ],
      },
    },
  ],
  types: [
    {
      name: 'WithdrawParams',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'withdrawAmount',
            type: 'u64',
          },
          {
            name: 'withdrawal',
            type: {
              defined: 'Withdrawal',
            },
          },
        ],
      },
    },
    {
      name: 'Withdrawal',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'In',
          },
          {
            name: 'Out',
          },
        ],
      },
    },
  ],
  events: [
    {
      name: 'CollectedFee',
      fields: [
        {
          name: 'userKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'dcaKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'mint',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'Filled',
      fields: [
        {
          name: 'userKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'dcaKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'inputMint',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'outputMint',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'inAmount',
          type: 'u64',
          index: false,
        },
        {
          name: 'outAmount',
          type: 'u64',
          index: false,
        },
        {
          name: 'feeMint',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'fee',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'Opened',
      fields: [
        {
          name: 'userKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'dcaKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'inDeposited',
          type: 'u64',
          index: false,
        },
        {
          name: 'inputMint',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'outputMint',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'cycleFrequency',
          type: 'i64',
          index: false,
        },
        {
          name: 'inAmountPerCycle',
          type: 'u64',
          index: false,
        },
        {
          name: 'createdAt',
          type: 'i64',
          index: false,
        },
      ],
    },
    {
      name: 'Closed',
      fields: [
        {
          name: 'userKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'dcaKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'inDeposited',
          type: 'u64',
          index: false,
        },
        {
          name: 'inputMint',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'outputMint',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'cycleFrequency',
          type: 'i64',
          index: false,
        },
        {
          name: 'inAmountPerCycle',
          type: 'u64',
          index: false,
        },
        {
          name: 'createdAt',
          type: 'i64',
          index: false,
        },
        {
          name: 'totalInWithdrawn',
          type: 'u64',
          index: false,
        },
        {
          name: 'totalOutWithdrawn',
          type: 'u64',
          index: false,
        },
        {
          name: 'unfilledAmount',
          type: 'u64',
          index: false,
        },
        {
          name: 'userClosed',
          type: 'bool',
          index: false,
        },
      ],
    },
    {
      name: 'Withdraw',
      fields: [
        {
          name: 'dcaKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'inAmount',
          type: 'u64',
          index: false,
        },
        {
          name: 'outAmount',
          type: 'u64',
          index: false,
        },
        {
          name: 'userWithdraw',
          type: 'bool',
          index: false,
        },
      ],
    },
    {
      name: 'Deposit',
      fields: [
        {
          name: 'dcaKey',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'InvalidAmount',
      msg: 'Invalid deposit amount',
    },
    {
      code: 6001,
      name: 'InvalidCycleAmount',
      msg: 'Invalid deposit amount',
    },
    {
      code: 6002,
      name: 'InvalidPair',
      msg: 'Invalid pair',
    },
    {
      code: 6003,
      name: 'TooFrequent',
      msg: 'Too frequent DCA cycle',
    },
    {
      code: 6004,
      name: 'InvalidMinPrice',
      msg: 'Minimum price constraint must be greater than 0',
    },
    {
      code: 6005,
      name: 'InvalidMaxPrice',
      msg: 'Maximum price constraint must be greater than 0',
    },
    {
      code: 6006,
      name: 'InAmountInsufficient',
      msg: 'In amount needs to be more than in amount per cycle',
    },
    {
      code: 6007,
      name: 'Unauthorized',
      msg: 'Wrong user',
    },
    {
      code: 6008,
      name: 'NoInATA',
      msg: 'inAta not passed in',
    },
    {
      code: 6009,
      name: 'NoUserInATA',
      msg: 'userInAta not passed in',
    },
    {
      code: 6010,
      name: 'NoOutATA',
      msg: 'outAta not passed in',
    },
    {
      code: 6011,
      name: 'NoUserOutATA',
      msg: 'userOutAta not passed in',
    },
    {
      code: 6012,
      name: 'InsufficientBalanceInProgram',
      msg: 'Trying to withdraw more than available',
    },
    {
      code: 6013,
      name: 'InvalidDepositAmount',
      msg: 'Deposit should be more than 0',
    },
    {
      code: 6014,
      name: 'UserInsufficientBalance',
      msg: 'User has insufficient balance',
    },
    {
      code: 6015,
      name: 'UnauthorizedKeeper',
      msg: 'Unauthorized Keeper',
    },
    {
      code: 6016,
      name: 'UnrecognizedProgram',
      msg: 'Unrecognized Program',
    },
    {
      code: 6017,
      name: 'MathErrors',
      msg: 'Calculation errors',
    },
    {
      code: 6018,
      name: 'KeeperNotTimeToFill',
      msg: 'Not time to fill',
    },
    {
      code: 6019,
      name: 'OrderFillAmountWrong',
      msg: 'Order amount wrong',
    },
    {
      code: 6020,
      name: 'SwapOutAmountBelowMinimum',
      msg: 'Out amount below expectations',
    },
    {
      code: 6021,
      name: 'WrongAdmin',
      msg: 'Wrong admin',
    },
    {
      code: 6022,
      name: 'MathOverflow',
      msg: 'Overflow in arithmetic operation',
    },
    {
      code: 6023,
      name: 'AddressMismatch',
      msg: 'Address Mismatch',
    },
    {
      code: 6024,
      name: 'ProgramMismatch',
      msg: 'Program Mismatch',
    },
    {
      code: 6025,
      name: 'IncorrectRepaymentAmount',
      msg: 'Incorrect Repayment Amount',
    },
    {
      code: 6026,
      name: 'CannotBorrowBeforeRepay',
      msg: 'Cannot Borrow Before Repay',
    },
    {
      code: 6027,
      name: 'NoRepaymentInstructionFound',
      msg: 'No Repayment Found',
    },
    {
      code: 6028,
      name: 'MissingSwapInstructions',
      msg: 'Missing Swap Instruction',
    },
    {
      code: 6029,
      name: 'UnexpectedSwapProgram',
      msg: 'Expected Instruction to use Jupiter Swap Program',
    },
    {
      code: 6030,
      name: 'UnknownInstruction',
      msg: 'Unknown Instruction',
    },
    {
      code: 6031,
      name: 'MissingRepayInstructions',
      msg: 'Missing Repay Instruction',
    },
    {
      code: 6032,
      name: 'KeeperShortchanged',
      msg: 'Keeper Shortchanged',
    },
    {
      code: 6033,
      name: 'WrongSwapOutAccount',
      msg: 'Jup Swap to Wrong Out Account',
    },
    {
      code: 6034,
      name: 'WrongTransferAmount',
      msg: 'Transfer amount should be exactly account balance',
    },
    {
      code: 6035,
      name: 'InsufficientBalanceForRent',
      msg: 'Insufficient balance for rent',
    },
    {
      code: 6036,
      name: 'UnexpectedSolBalance',
      msg: 'Unexpected SOL amount in intermediate account',
    },
    {
      code: 6037,
      name: 'InsufficientWsolForTransfer',
      msg: 'Too little WSOL to perform transfer',
    },
    {
      code: 6038,
      name: 'MissedInstruction',
      msg: 'Did not call initiate_flash_fill',
    },
    {
      code: 6039,
      name: 'WrongProgram',
      msg: "Did not call this program's initiate_flash_fill",
    },
    {
      code: 6040,
      name: 'BalanceNotZero',
      msg: "Can't close account with balance",
    },
    {
      code: 6041,
      name: 'UnexpectedWSOLLeftover',
      msg: 'Should not have WSOL leftover in DCA out-token account',
    },
    {
      code: 6042,
      name: 'IntermediateAccountNotSet',
      msg: 'Should pass in a WSOL intermediate account when transferring SOL',
    },
    {
      code: 6043,
      name: 'UnexpectedSwapInstruction',
      msg: 'Did not call jup swap',
    },
  ],
};
