"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefiTokenNetNewPurchasesRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class DefiTokenNetNewPurchasesRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/token/daily_new_purchases";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.DefiTokenNetNewPurchasesRequest = DefiTokenNetNewPurchasesRequest;
