"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StarAtlasEventStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    blockId: { type: "NUMERIC" },
    blockTime: { type: "DATE" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
    instructionName: { type: "TEXT" },
    eventType: { type: "ENUM" },
    "escapeVelocityEvent.owner": { type: "TEXT" },
    "escapeVelocityEvent.game": { type: "TEXT" },
    "escapeVelocityEvent.fleetOwner": { type: "TEXT" },
    "escapeVelocityEvent.fleet": { type: "TEXT" },
    "escapeVelocityEvent.funder": { type: "TEXT" },
    "escapeVelocityEvent.userTokenAccount": { type: "TEXT" },
    "escapeVelocityEvent.fleetSigner": { type: "TEXT" },
    "escapeVelocityEvent.fleetLockAccount": { type: "TEXT" },
    "escapeVelocityEvent.lockMint": { type: "TEXT" },
    "escapeVelocityEvent.lockAmount": { type: "TEXT" },
    "escapeVelocityEvent.representation": { type: "TEXT" },
    "escapeVelocityEvent.defaultStats.warpSpeed": { type: "NUMERIC" },
    "escapeVelocityEvent.defaultStats.warpCooldown": { type: "NUMERIC" },
    "escapeVelocityEvent.defaultStats.warpRange": { type: "NUMERIC" },
    "escapeVelocityEvent.to": { type: "NUMERIC" },
    "escapeVelocityEvent.fleetAmount": { type: "NUMERIC" },
    "galacticMarketplaceEvent.updateAuthorityMaster": { type: "TEXT" },
    "galacticMarketplaceEvent.funder": { type: "TEXT" },
    "galacticMarketplaceEvent.marketVarsAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.feeExemptTarget": { type: "TEXT" },
    "galacticMarketplaceEvent.feeExemptAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.updateAuthorityAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.registeredCurrency": { type: "TEXT" },
    "galacticMarketplaceEvent.currencyMint": { type: "TEXT" },
    "galacticMarketplaceEvent.payer": { type: "TEXT" },
    "galacticMarketplaceEvent.user": { type: "TEXT" },
    "galacticMarketplaceEvent.openOrdersCounter": { type: "TEXT" },
    "galacticMarketplaceEvent.depositMint": { type: "TEXT" },
    "galacticMarketplaceEvent.signer": { type: "TEXT" },
    "galacticMarketplaceEvent.orderInitializer": { type: "TEXT" },
    "galacticMarketplaceEvent.initializerDepositTokenAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.orderVaultAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.orderVaultAuthority": { type: "TEXT" },
    "galacticMarketplaceEvent.orderAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.orderTaker": { type: "TEXT" },
    "galacticMarketplaceEvent.orderTakerDepositTokenAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.orderTakerReceiveTokenAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.assetMint": { type: "TEXT" },
    "galacticMarketplaceEvent.initializerReceiveTokenAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.saVault": { type: "TEXT" },
    "galacticMarketplaceEvent.atlasStaking": { type: "TEXT" },
    "galacticMarketplaceEvent.registeredStake": { type: "TEXT" },
    "galacticMarketplaceEvent.stakingAccount": { type: "TEXT" },
    "galacticMarketplaceEvent.receiveMint": { type: "TEXT" },
    "galacticMarketplaceEvent.saCurrencyVault": { type: "TEXT" },
    "galacticMarketplaceEvent.discount": { type: "NUMERIC" },
    "galacticMarketplaceEvent.stakeAmount": { type: "NUMERIC" },
    "galacticMarketplaceEvent.purchaseQuantity": { type: "NUMERIC" },
    "galacticMarketplaceEvent.expectedPrice": { type: "NUMERIC" },
    "galacticMarketplaceEvent.seller": { type: "TEXT" },
    "galacticMarketplaceEvent.price": { type: "NUMERIC" },
    "galacticMarketplaceEvent.originationQty": { type: "NUMERIC" },
    "galacticMarketplaceEvent.royalty": { type: "NUMERIC" },
    "galacticMarketplaceEvent.amount": { type: "NUMERIC" },
    "galacticMarketplaceEvent.sellAmount": { type: "NUMERIC" },
    "galacticMarketplaceEvent.sellMint": { type: "TEXT" },
    "galacticMarketplaceEvent.buyAmount": { type: "NUMERIC" },
    "galacticMarketplaceEvent.buyMint": { type: "TEXT" },
    "claimStakesEvent.playerAccount": { type: "TEXT" },
    "claimStakesEvent.claimStakingAccount": { type: "TEXT" },
    "claimStakesEvent.claimStakeVarsAccount": { type: "TEXT" },
    "claimStakesEvent.fuelTreasuryTokenAccount": { type: "TEXT" },
    "claimStakesEvent.armsTreasuryTokenAccount": { type: "TEXT" },
    "claimStakesEvent.foodTreasuryTokenAccount": { type: "TEXT" },
    "claimStakesEvent.toolkitTreasuryTokenAccount": { type: "TEXT" },
    "claimStakesEvent.playerFuelTokenAccount": { type: "TEXT" },
    "claimStakesEvent.playerArmsTokenAccount": { type: "TEXT" },
    "claimStakesEvent.playerFoodTokenAccount": { type: "TEXT" },
    "claimStakesEvent.playerToolkitTokenAccount": { type: "TEXT" },
    "claimStakesEvent.treasuryAuthorityAccount": { type: "TEXT" },
    "claimStakesEvent.claimStakeMint": { type: "TEXT" },
    "claimStakesEvent.funder": { type: "TEXT" },
    "claimStakesEvent.claimStakeVars": { type: "TEXT" },
    "claimStakesEvent.playerFactionAccount": { type: "TEXT" },
    "claimStakesEvent.escrowAuthority": { type: "TEXT" },
    "claimStakesEvent.claimStakeTokenAccountSource": { type: "TEXT" },
    "claimStakesEvent.claimStakeTokenAccountEscrow": { type: "TEXT" },
    "claimStakesEvent.updateAuthorityAccount": { type: "TEXT" },
    "claimStakesEvent.globalVarsAccount": { type: "TEXT" },
    "claimStakesEvent.fuelTreasuryAccount": { type: "TEXT" },
    "claimStakesEvent.armsTreasuryAccount": { type: "TEXT" },
    "claimStakesEvent.foodTreasuryAccount": { type: "TEXT" },
    "claimStakesEvent.toolkitTreasuryAccount": { type: "TEXT" },
    "claimStakesEvent.fuelMint": { type: "TEXT" },
    "claimStakesEvent.foodMint": { type: "TEXT" },
    "claimStakesEvent.armsMint": { type: "TEXT" },
    "claimStakesEvent.toolkitMint": { type: "TEXT" },
    "claimStakesEvent.fromTokenAccount": { type: "TEXT" },
    "claimStakesEvent.claimStakeEscrow": { type: "TEXT" },
    "claimStakesEvent.playerTokenAccount": { type: "TEXT" },
    "claimStakesEvent.claimStakeEscrowAccount": { type: "TEXT" },
    "claimStakesEvent.claimStakeQuantity": { type: "NUMERIC" },
    "claimStakesEvent.rewardRatePerSecond.fuel": { type: "NUMERIC" },
    "claimStakesEvent.rewardRatePerSecond.arms": { type: "NUMERIC" },
    "claimStakesEvent.rewardRatePerSecond.food": { type: "NUMERIC" },
    "claimStakesEvent.rewardRatePerSecond.toolkit": { type: "NUMERIC" },
    "claimStakesEvent.claimStakeMaxReserves.fuel": { type: "NUMERIC" },
    "claimStakesEvent.claimStakeMaxReserves.arms": { type: "NUMERIC" },
    "claimStakesEvent.claimStakeMaxReserves.food": { type: "NUMERIC" },
    "claimStakesEvent.claimStakeMaxReserves.toolkit": { type: "NUMERIC" },
    "claimStakesEvent.rewardRateValues.fuel": { type: "NUMERIC" },
    "claimStakesEvent.rewardRateValues.arms": { type: "NUMERIC" },
    "claimStakesEvent.rewardRateValues.food": { type: "NUMERIC" },
    "claimStakesEvent.rewardRateValues.toolkit": { type: "NUMERIC" },
    "claimStakesEvent.maxReserveValues.fuel": { type: "NUMERIC" },
    "claimStakesEvent.maxReserveValues.arms": { type: "NUMERIC" },
    "claimStakesEvent.maxReserveValues.food": { type: "NUMERIC" },
    "claimStakesEvent.maxReserveValues.toolkit": { type: "NUMERIC" },
    "claimStakesEvent.fuelAmount": { type: "NUMERIC" },
    "claimStakesEvent.armsAmount": { type: "NUMERIC" },
    "claimStakesEvent.foodAmount": { type: "NUMERIC" },
    "claimStakesEvent.toolkitAmount": { type: "NUMERIC" },
    "factionEnlistmentEvent.playerFactionAccount": { type: "TEXT" },
    "factionEnlistmentEvent.playerAccount": { type: "TEXT" },
    "factionEnlistmentEvent.factionId": { type: "TEXT" },
    "scoresEvent.playerAccount": { type: "TEXT" },
    "scoresEvent.shipStakingAccount": { type: "TEXT" },
    "scoresEvent.scoreVarsAccount": { type: "TEXT" },
    "scoresEvent.shipTokenAccountEscrow": { type: "TEXT" },
    "scoresEvent.fuelTokenAccountEscrow": { type: "TEXT" },
    "scoresEvent.foodTokenAccountEscrow": { type: "TEXT" },
    "scoresEvent.armsTokenAccountEscrow": { type: "TEXT" },
    "scoresEvent.escrowAuthority": { type: "TEXT" },
    "scoresEvent.shipMint": { type: "TEXT" },
    "scoresEvent.fuelMint": { type: "TEXT" },
    "scoresEvent.foodMint": { type: "TEXT" },
    "scoresEvent.armsMint": { type: "TEXT" },
    "scoresEvent.updateAuthorityAccount": { type: "TEXT" },
    "scoresEvent.scoreVarsShipAccount": { type: "TEXT" },
    "scoresEvent.playerAtlasTokenAccount": { type: "TEXT" },
    "scoresEvent.treasuryTokenAccount": { type: "TEXT" },
    "scoresEvent.treasuryAuthorityAccount": { type: "TEXT" },
    "scoresEvent.playerFactionAccount": { type: "TEXT" },
    "scoresEvent.shipTokenAccountSource": { type: "TEXT" },
    "scoresEvent.atlasMint": { type: "TEXT" },
    "scoresEvent.toolkitMint": { type: "TEXT" },
    "scoresEvent.tokenOwnerAccount": { type: "TEXT" },
    "scoresEvent.armsTokenAccountSource": { type: "TEXT" },
    "scoresEvent.foodTokenAccountSource": { type: "TEXT" },
    "scoresEvent.fuelTokenAccountSource": { type: "TEXT" },
    "scoresEvent.toolkitTokenAccountSource": { type: "TEXT" },
    "scoresEvent.armsTokenAccountReturn": { type: "TEXT" },
    "scoresEvent.foodTokenAccountReturn": { type: "TEXT" },
    "scoresEvent.fuelTokenAccountReturn": { type: "TEXT" },
    "scoresEvent.shipTokenAccountReturn": { type: "TEXT" },
    "scoresEvent.stakingBump": { type: "TEXT" },
    "scoresEvent.scorevarsBump": { type: "TEXT" },
    "scoresEvent.shipBump": { type: "TEXT" },
    "scoresEvent.fuelBump": { type: "TEXT" },
    "scoresEvent.foodBump": { type: "TEXT" },
    "scoresEvent.armsBump": { type: "TEXT" },
    "scoresEvent.escrowAuthBump": { type: "TEXT" },
    "scoresEvent.scorevarsShipBump": { type: "TEXT" },
    "scoresEvent.treasuryBump": { type: "TEXT" },
    "scoresEvent.treasuryAuthBump": { type: "TEXT" },
    "scoresEvent.escrowBump": { type: "TEXT" },
    "scoresEvent.shipQuantity": { type: "NUMERIC" },
    "scoresEvent.armsQuantity": { type: "NUMERIC" },
    "scoresEvent.foodQuantity": { type: "NUMERIC" },
    "scoresEvent.rewardRatePerSecond": { type: "NUMERIC" },
    "scoresEvent.fuelMaxReserve": { type: "NUMERIC" },
    "scoresEvent.foodMaxReserve": { type: "NUMERIC" },
    "scoresEvent.armsMaxReserve": { type: "NUMERIC" },
    "scoresEvent.toolkitMaxReserve": { type: "NUMERIC" },
    "scoresEvent.millisecondsToBurnOneFuel": { type: "NUMERIC" },
    "scoresEvent.millisecondsToBurnOneFood": { type: "NUMERIC" },
    "scoresEvent.millisecondsToBurnOneArms": { type: "NUMERIC" },
    "scoresEvent.millisecondsToBurnOneToolkit": { type: "NUMERIC" },
    "scoresEvent.newRewardRatePerSecond": { type: "NUMERIC" },
    "scoresEvent.atlasAmount": { type: "NUMERIC" },
    "scoresEvent.armsBurned": { type: "NUMERIC" },
    "scoresEvent.fuelBurned": { type: "NUMERIC" },
    "scoresEvent.foodBurned": { type: "NUMERIC" },
    "scoresEvent.toolkitBurned": { type: "NUMERIC" },
};
class StarAtlasEventStream extends base_data_stream_class_1.BaseDataStream {
    id = "StarAtlasEvent";
    static fieldMap = fieldMap;
}
exports.StarAtlasEventStream = StarAtlasEventStream;
