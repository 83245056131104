"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGovBoardingProgramId = exports.getGovBoardingProgram = exports.createGovBoardingProgram = exports.GOV_BOARDING_PROGRAM_ID = void 0;
const errors_1 = require("../errors");
exports.GOV_BOARDING_PROGRAM_ID = 'GovyJPza6EV6srUcmwA1vS3EmWGdLSkkDafRE54X1Dir';
function createGovBoardingProgram() {
    return {
        name: 'govBoarding',
        publicKey: exports.GOV_BOARDING_PROGRAM_ID,
        getErrorFromCode(code, cause) {
            return (0, errors_1.getGovBoardingErrorFromCode)(code, this, cause);
        },
        getErrorFromName(name, cause) {
            return (0, errors_1.getGovBoardingErrorFromName)(name, this, cause);
        },
        isOnCluster() {
            return true;
        },
    };
}
exports.createGovBoardingProgram = createGovBoardingProgram;
function getGovBoardingProgram(context, clusterFilter) {
    return context.programs.get('govBoarding', clusterFilter);
}
exports.getGovBoardingProgram = getGovBoardingProgram;
function getGovBoardingProgramId(context, clusterFilter) {
    return context.programs.getPublicKey('govBoarding', exports.GOV_BOARDING_PROGRAM_ID, clusterFilter);
}
exports.getGovBoardingProgramId = getGovBoardingProgramId;
