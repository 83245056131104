"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefiLiquidityPoolsMetadataRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class DefiLiquidityPoolsMetadataRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/defi/liquidity-pools/metadata";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.DefiLiquidityPoolsMetadataRequest = DefiLiquidityPoolsMetadataRequest;
