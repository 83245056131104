"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenBalancesByOwnerRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class TokenBalancesByOwnerRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/token/balances-by-owner";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.TokenBalancesByOwnerRequest = TokenBalancesByOwnerRequest;
