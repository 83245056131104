"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateStreamsRequest = void 0;
const deserialize_subscription_fn_1 = require("../../subscriptions/util/deserialize-subscription.fn");
const BaseRequest_class_1 = require("../BaseRequest.class");
class CreateStreamsRequest extends BaseRequest_class_1.BaseRequest {
    subscription;
    method = "POST";
    url = "/v0/subscriptions/create";
    constructor(subscription) {
        super({
            ...subscription.toSubscription(true),
        });
        this.subscription = subscription;
    }
    async _send(apiKey, baseUrl) {
        const response = (await super._send(apiKey, baseUrl));
        if (!response) {
            return null;
        }
        else {
            return (0, deserialize_subscription_fn_1.deserializeSubscription)(response);
        }
    }
}
exports.CreateStreamsRequest = CreateStreamsRequest;
