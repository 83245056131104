import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmailWalletConnector } from '@dynamic-labs/wallet-connector-core';
import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgArrowRightCircle } from '../../../../shared/assets/arrow-right-circle.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../../utils/constants/colors.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/multi-wallet';
import { isTurnkeyEnabled } from '../../../../utils/functions/isTurnkeyEnabled/isTurnkeyEnabled.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../context/ThemeContext/ThemeContext.js';
import { Input } from '../../../../components/Input/Input.js';
import 'react-dom';
import 'react-focus-lock';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import { getEmailProvider } from '../../../../data/api.js';
import '@dynamic-labs/rpc-providers';
import '../../../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import { useErrorContext } from '../../../../context/ErrorContext/ErrorContext.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../context/MockContext/MockContext.js';
import '../../../../context/QrCodeContext/QrCodeContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import { Button } from '../../../../components/Button/Button.js';
import { IconButton } from '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import { useEmailProvider } from '../../../../utils/hooks/useEmailProvider/useEmailProvider.js';

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const LoginEmailForm = ({ isLoading: isExternalLoading, onSubmit, onSubmitError, currentEmail, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { environmentId, wallets, projectSettings } = useInternalDynamicContext();
    const { setErrorMessage } = useErrorContext();
    const [emailInput, setEmailInput] = useState(currentEmail !== null && currentEmail !== void 0 ? currentEmail : '');
    const [invalidEmail, setInvalidEmail] = useState(false);
    const emailWalletConnector = wallets
        .map(({ walletConnector }) => walletConnector)
        .find(isEmailWalletConnector);
    const { handleEmailOnlyLoginSubmit, submitEmail, isEmailProviderLoading } = useEmailProvider({
        emailWalletConnector,
    });
    const isLoading = useMemo(() => isEmailProviderLoading || isExternalLoading, [isEmailProviderLoading, isExternalLoading]);
    const handleEmailOnChangeEvent = (event) => {
        const { value } = event.currentTarget;
        setEmailInput(value);
    };
    // will be true if blocto or magic email are enabled
    const isEmailWalletProviderEnabled = emailWalletConnector !== undefined;
    const emailInputSuffixIcon = (jsx(IconButton, { "data-testid": 'email_submit_button', type: 'submit', className: `icon-button${((_a = emailInput === null || emailInput === void 0 ? void 0 : emailInput.trim()) === null || _a === void 0 ? void 0 : _a.length) ? '--visible' : ''}`, disabled: isLoading, children: jsx(SvgArrowRightCircle, {}) }));
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (!EMAIL_REGEX.test(emailInput)) {
            setInvalidEmail(true);
            return;
        }
        try {
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
            if ((isEmailWalletProviderEnabled || isTurnkeyEnabled(projectSettings)) &&
                (projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.confirmEmailProviderForVerify)) {
                // see: https://linear.app/dynamic-labs/issue/DYN-2517
                // if confirmWalletTransfers setting is true, check api first to confirm emailProvider
                const { emailProvider } = yield getEmailProvider({
                    email: emailInput,
                    environmentId,
                });
                // For v0.19 we will support emailOnly and Dynamic as email providers
                // For v0.20/v1 we need to remove emailOnly and leave Dynamic as the only one email provider
                // Ticket: https://linear.app/dynamic-labs/issue/GVTY-213/remove-emailonly-as-a-provider-from-sdk
                if (emailProvider === ProviderEnum.EmailOnly ||
                    emailProvider === ProviderEnum.Dynamic) {
                    yield handleEmailOnlyLoginSubmit(emailInput);
                    return;
                }
            }
            yield submitEmail(emailInput);
        }
        catch (error) {
            if (error.code === 'invalid_email_address') {
                setInvalidEmail(true);
            }
            else {
                setErrorMessage(error.code);
            }
            logger.debug(error);
            onSubmitError === null || onSubmitError === void 0 ? void 0 : onSubmitError();
        }
    });
    // social sign in currently coupled to email... might be better to decouple
    return (jsxs("form", { className: 'login-with-email-form', onSubmit: (e) => handleSubmit(e), children: [jsx(Input, { id: 'email_field', value: emailInput, onChange: handleEmailOnChangeEvent, label: 'Enter your email', error: invalidEmail, message: invalidEmail && t('dyn_login.helper.email_form.invalid_email'), suffix: ((_b = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design.modal) === null || _b === void 0 ? void 0 : _b.emailSubmitButtonInsideInput)
                    ? emailInputSuffixIcon
                    : null, disabled: isLoading }), !((_c = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design.modal) === null || _c === void 0 ? void 0 : _c.emailSubmitButtonInsideInput) && (jsx(Button, { dataTestId: 'email_submit_button', buttonClassName: 'login-with-email-form__button', type: 'submit', loading: isLoading, disabled: isLoading, buttonVariant: 'brand-primary', buttonPadding: 'login-screen-height', expanded: true, typographyProps: {
                    color: 'inherit',
                }, children: "Continue" }))] }));
};

export { EMAIL_REGEX, LoginEmailForm };
