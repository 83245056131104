var dyn_account_exists = {
	connect: "Connect with {{socialOauth}}",
	description: "It looks like an account already exists using",
	title: "Account already exists",
	trail_message_email: ". Please log in with your email.",
	trail_message_social: "through {{socialOauth}}"
};
var dyn_active_wallet_info = {
	testnet_warning: "A testnet network has been selected. Please only use testnet funds or they will be lost."
};
var dyn_bridge = {
	summary_view: {
		connection_succeed: "Congratulations on connecting both your {{firstBlockchainName}} and {{secondBlockchainName}} wallets",
		title: "Welcome to {{appName}}"
	},
	welcome_view: {
		button: "Get started",
		description: "You’ll need to connect both your {{firstBlockchainName}} and {{secondBlockchainName}} wallets to get started",
		title: "Connect to {{appName}}"
	}
};
var dyn_chainalysis_blocked_wallet = {
	title: "Access denied"
};
var dyn_collect_user_data = {
	description: "We need a bit of information to get started",
	fields: {
		alias: {
			label: "Alias"
		},
		country: {
			label: "Country"
		},
		email: {
			label: "Email",
			validation: "Email is not valid"
		},
		first_name: {
			label: "First Name"
		},
		job_title: {
			label: "Job Title"
		},
		last_name: {
			label: "Last Name"
		},
		phone_number: {
			label: "Phone number",
			validation: "Phone number is not valid"
		},
		policies_consent: {
			label: "Policies Consent"
		},
		t_shirt_size: {
			label: "T-Shirt Size"
		},
		team: {
			label: "Team"
		},
		username: {
			label: "Username",
			validation: "Username is not valid"
		}
	},
	greeting: "Welcome to {{appName}}",
	log_out_button: "Log out",
	not_supported_network: {
		description: "Your wallet is not connected to a supported network. Please update before continuing.",
		error_message: "This network is not available, please update.",
		title: "Update Network"
	}
};
var dyn_connect_button = "Connect your wallet";
var dyn_email_confirmation = {
	description: "We’ve sent a verification email to",
	note: "Note: it might take a few seconds to proceed after clicking the link in your email",
	title: "Confirm your email"
};
var dyn_email_update = {
	current_email: "Your current email address is",
	label: "Email address",
	send_verification: "Send Verification Code",
	title: "Update email"
};
var dyn_email_verification = {
	code_not_received: "Did not receive your code yet?",
	code_sent: "Code sent",
	complete: "Verification complete, the email is now verified to this account.",
	description: "We’ve sent a verification code to",
	edit_email: "Edit email",
	log_out_button: "Log out",
	resend_code: "Re-send code",
	resend_code_in: "Re-send code in {{remainingSeconds}}",
	title: "Confirm your email"
};
var dyn_login = {
	connect_wallet: {
		title: "Connect"
	},
	helper: {
		all_wallet_list: "Get your first wallet",
		email_form: {
			invalid_email: "Invalid or incorrect email. Did you mistype it?"
		},
		pending_connect: {
			title: "Connecting a wallet"
		},
		pending_signature: {
			title: "Signing a wallet"
		},
		pending_signature_without_back_button: {
			title: "Signing a wallet"
		},
		qr_code: {
			title: "Connecting a wallet"
		},
		wallet_only: "Get your first wallet"
	},
	qr_code: {
		title: "Connect"
	},
	separators: {
		"default": "OR"
	},
	sign_wallet: {
		title: "Sign"
	},
	title: {
		all: "Log in or Sign up",
		all_wallet_list: "Select your wallet",
		wallet_only: "Select your wallet"
	},
	wallet_group: {
		title: "Select Chain"
	},
	wallet_list: {
		button_only: "Continue with a wallet"
	}
};
var dyn_network_not_supported = {
	button: "Switch Network",
	subtitle: "Your wallet is not connected to a supported network. Please update before continuing.",
	title: "Update Network",
	warning_message: "This network is not available, please update."
};
var dyn_network_not_supported_manual_switch = {
	subtitle_network_defined: "To continue, please update the network in your wallet to {{network}}",
	subtitle_no_network_defined: "Your wallet does not support switching networks from this app. Switch networks directly in your wallet.",
	title: "Update your Network",
	warning_message: "This network is not available, please update."
};
var dyn_no_access = {
	title: "You don't have access"
};
var dyn_otp_verification = {
	banner_text: "Sign in to access your email based wallet",
	code_sent: "We’ve sent a verification code to",
	confirm_code: "Confirm verification code",
	hang_tight: "Hang tight while we get things ready for you",
	verification_succeeded: "Verification code confirmed"
};
var dyn_passkey_intro = {
	button: "Set up a passkey",
	button_logout: "Log out",
	disabled: "Passkeys are not available on this device or browser. Please open on Chrome, Safari, or Brave to continue",
	helper: {
		section_1: {
			description: "Passkeys are a standard built by Apple, Google and others, and eliminates the use of passwords.",
			title: "Built by Apple and Google"
		},
		section_2: {
			description: "Passkeys are stored on your phone and are not shared with anyone.",
			title: "Secure and Private"
		},
		title: "What's Passkey",
		tooltip: "Need some help?"
	},
	subtitle: "Passkeys are stored natively to your device with a biometric and can only be accessed by you.",
	warning: "If you delete your passkey, you will be locked out of your wallet.",
	title: "Secure your wallet"
};
var dyn_sign_message = {
	cancel_button: "Cancel",
	sign_button: "Sign",
	title: "Requesting Signature"
};
var dyn_wallet_conect = {
	mobile: {
		wallet_list: {
			helper: "Get your first wallet",
			title: "WalletConnect wallets"
		}
	}
};
var dyn_wallet_link = {
	cannot_link: {
		cancel_button: "Cancel",
		description: "This wallet is the only wallet in your other account. You cannot transfer it, since then you will lose access to that account.",
		link_other_button: "Link a different wallet",
		title: "Cannot link this wallet to a new account"
	}
};
var dyn_wallet_list = {
	helper: "Get your first wallet",
	title: {
		connect: "Connect a new wallet",
		link: "Link a new wallet",
		select: "Select your wallet"
	},
	view_all: "View all wallets"
};
var dyn_wallet_transfer = {
	sign: {
		title: "Sign to confirm transfer"
	}
};
var dyn_widget = {
	connect: "Connect your wallet",
	empty_wallets: "{{action}} additional wallets to see them here.",
	other_wallets: "My other wallets"
};
var sdkTranslation = {
	dyn_account_exists: dyn_account_exists,
	dyn_active_wallet_info: dyn_active_wallet_info,
	dyn_bridge: dyn_bridge,
	dyn_chainalysis_blocked_wallet: dyn_chainalysis_blocked_wallet,
	dyn_collect_user_data: dyn_collect_user_data,
	dyn_connect_button: dyn_connect_button,
	dyn_email_confirmation: dyn_email_confirmation,
	dyn_email_update: dyn_email_update,
	dyn_email_verification: dyn_email_verification,
	dyn_login: dyn_login,
	dyn_network_not_supported: dyn_network_not_supported,
	dyn_network_not_supported_manual_switch: dyn_network_not_supported_manual_switch,
	dyn_no_access: dyn_no_access,
	dyn_otp_verification: dyn_otp_verification,
	dyn_passkey_intro: dyn_passkey_intro,
	dyn_sign_message: dyn_sign_message,
	dyn_wallet_conect: dyn_wallet_conect,
	dyn_wallet_link: dyn_wallet_link,
	dyn_wallet_list: dyn_wallet_list,
	dyn_wallet_transfer: dyn_wallet_transfer,
	dyn_widget: dyn_widget
};

export { sdkTranslation as default, dyn_account_exists, dyn_active_wallet_info, dyn_bridge, dyn_chainalysis_blocked_wallet, dyn_collect_user_data, dyn_connect_button, dyn_email_confirmation, dyn_email_update, dyn_email_verification, dyn_login, dyn_network_not_supported, dyn_network_not_supported_manual_switch, dyn_no_access, dyn_otp_verification, dyn_passkey_intro, dyn_sign_message, dyn_wallet_conect, dyn_wallet_link, dyn_wallet_list, dyn_wallet_transfer, dyn_widget };
