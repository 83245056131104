"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestClient = void 0;
class RestClient {
    apiKey;
    _baseUrl;
    constructor(apiKey, _baseUrl) {
        this.apiKey = apiKey;
        this._baseUrl = _baseUrl;
    }
    async send(sendable) {
        return sendable._send(this.apiKey, this._baseUrl);
    }
}
exports.RestClient = RestClient;
