"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonLogicUtil = exports.datastreamFilterFormatTypes = void 0;
const data_stream_field_map_util_fn_1 = require("./data-stream-field-map-util.fn");
const datastream_filters_const_1 = require("./datastream-filters.const");
const getValueForFieldType = (value, fieldType) => {
    if (fieldType === "DATE" && value) {
        return new Date(value).getTime() / 1000; // Time should be in seconds to work
    }
    return value;
};
const serializeFieldValue = (filter, key) => {
    if (filter.operator === "between") {
        return {
            ["<="]: [
                getValueForFieldType(filter.values[0], filter.type),
                { var: key },
                getValueForFieldType(filter.values[1], filter.type),
            ],
        };
    }
    return {
        [filter.operator]: [
            { var: key },
            getValueForFieldType(filter.values[0], filter.type),
        ],
    };
};
const capitalize = (value) => `${value[0].toUpperCase()}${value.substring(1)}`;
const filtersToJsonObject = (field, filter) => {
    if (datastream_filters_const_1.dataStreamFilters.is.boolean(filter) ||
        datastream_filters_const_1.dataStreamFilters.is.text(filter) ||
        datastream_filters_const_1.dataStreamFilters.is.enum(filter) ||
        datastream_filters_const_1.dataStreamFilters.is.numeric(filter) ||
        datastream_filters_const_1.dataStreamFilters.is.date(filter)) {
        return serializeFieldValue(filter, field);
    }
    else if (datastream_filters_const_1.dataStreamFilters.is.or(filter)) {
        return filter.filters.map((typeFilter) => {
            return serializeFieldValue(typeFilter, field);
        });
    }
    else if (datastream_filters_const_1.dataStreamFilters.is.composite(filter)) {
        if (filter.fields.length === 1) {
            return filtersToJsonObject(filter.fields[0], filter.filter);
        }
        return [
            {
                or: filter.fields.map((compositeField) => {
                    return filtersToJsonObject(compositeField, filter.filter);
                }),
            },
        ];
    }
    else if (datastream_filters_const_1.dataStreamFilters.is.and(filter)) {
    }
    return [];
};
const serializeFieldValues = (field, filter) => {
    if (datastream_filters_const_1.dataStreamFilters.is.and(filter)) {
        const output = [];
        for (let i = 0; i < filter.filters.length; i++) {
            const value = filtersToJsonObject(field, filter.filters[i]);
            output.push({
                or: Array.isArray(value) ? value : [value],
            });
        }
        return output;
    }
    const serializedValues = filtersToJsonObject(field, filter);
    return {
        or: Array.isArray(serializedValues) ? serializedValues : [serializedValues],
    };
};
const getOpValue = (input) => {
    const operator = Object.keys(input)[0];
    const values = input[operator];
    if (operator === "<=" && values.length === 3) {
        return {
            operator: "between",
            values: [values[0], values[2]],
            fieldName: values[1].var,
        };
    }
    return { operator, values: [values[1]], fieldName: values[0].var };
};
const parseSimpleValue = (field, operator, values) => {
    if (!field)
        return null;
    if (data_stream_field_map_util_fn_1.dataStreamFieldMapUtil.isSimple(field) === false) {
        return null;
    }
    if (field.type === "BOOLEAN")
        return datastream_filters_const_1.dataStreamFilters.boolean.equals(values[0]);
    if (field.type === "DATE") {
        if (operator === "==") {
            return datastream_filters_const_1.dataStreamFilters.date.equals(new Date(+values[0] * 1000));
        }
        return datastream_filters_const_1.dataStreamFilters.date.notEquals(new Date(+values[0] * 1000));
    }
    if (field.type === "TEXT") {
        if (operator === "==") {
            return datastream_filters_const_1.dataStreamFilters.text.equals(values[0]);
        }
        return datastream_filters_const_1.dataStreamFilters.text.notEquals(values[0]);
    }
    if (field.type === "ENUM") {
        if (operator === "==") {
            return datastream_filters_const_1.dataStreamFilters.enum.equals(values[0]);
        }
        return datastream_filters_const_1.dataStreamFilters.enum.notEquals(values[0]);
    }
    if (field.type === "NUMERIC") {
        if (operator === "==") {
            return datastream_filters_const_1.dataStreamFilters.numeric.equals(values[0]);
        }
        if (operator === "!=") {
            return datastream_filters_const_1.dataStreamFilters.numeric.notEquals(values[0]);
        }
        if (operator === "<") {
            return datastream_filters_const_1.dataStreamFilters.numeric.lessThan(values[0]);
        }
        if (operator === "<=") {
            return datastream_filters_const_1.dataStreamFilters.numeric.lessThanEquals(values[0]);
        }
        if (operator === ">") {
            return datastream_filters_const_1.dataStreamFilters.numeric.greaterThan(values[0]);
        }
        if (operator === ">=") {
            return datastream_filters_const_1.dataStreamFilters.numeric.greaterThanEquals(values[0]);
        }
        if (operator === "between") {
            return datastream_filters_const_1.dataStreamFilters.numeric.between(values[0], values[1]);
        }
    }
    return null;
};
const findCompositeFieldRootName = (compositeName, fieldMap) => {
    for (const prop in fieldMap) {
        const field = fieldMap[prop];
        if (field.type !== "COMPOSITE")
            continue;
        for (let i = 0; i < field.fields.length; i++) {
            if (field.fields[i] === compositeName)
                return prop;
        }
    }
    return null;
};
const getSingleFilterValue = (filters) => {
    return filters.length === 1
        ? filters[0]
        : {
            type: "OR",
            filters,
        };
};
const parseValue = (input, fieldMap) => {
    const ands = input.and;
    const output = {};
    if (!input.and)
        return output;
    ands.forEach((and) => {
        const filters = [];
        let orFieldName = null;
        let isComposite = false, compsiteFields = [];
        and.or.forEach((or, idx) => {
            if ("or" in or) {
                // This is a composite field
                isComposite = true;
                if (idx === 0) {
                    // setup composite information
                    or.or.forEach((compositeValue, compIdx) => {
                        const { fieldName } = getOpValue(compositeValue);
                        if (compIdx === 0) {
                            const rootFieldName = findCompositeFieldRootName(fieldName, fieldMap);
                            if (rootFieldName) {
                                orFieldName = rootFieldName;
                            }
                        }
                        compsiteFields.push(fieldName);
                    });
                }
                const value = or.or[0];
                const { operator, values } = getOpValue(value);
                const field = fieldMap[orFieldName];
                const filter = parseSimpleValue({ type: field.dataType }, operator, values);
                if (filter)
                    filters.push(filter);
                return;
            }
            const { operator, values, fieldName } = getOpValue(or);
            if (!orFieldName)
                orFieldName = fieldName;
            const field = fieldMap[fieldName];
            const filter = parseSimpleValue(field, operator, values);
            if (filter)
                filters.push(filter);
        });
        if (!filters.length || !orFieldName)
            return;
        if (orFieldName in output) {
            // This needs to be an AND field
            if (datastream_filters_const_1.dataStreamFilters.is.and(output[orFieldName]) === false) {
                const currentFilter = output[orFieldName];
                output[orFieldName] = {
                    type: "AND",
                    filters: [currentFilter],
                };
            }
            output[orFieldName].filters.push(isComposite
                ? datastream_filters_const_1.dataStreamFilters.composite(getSingleFilterValue(filters), compsiteFields)
                : getSingleFilterValue(filters));
            return;
        }
        output[orFieldName] = isComposite
            ? datastream_filters_const_1.dataStreamFilters.composite(getSingleFilterValue(filters), compsiteFields)
            : getSingleFilterValue(filters);
    });
    return output;
};
exports.datastreamFilterFormatTypes = {
    OR: "OR",
    AND: "AND",
    COMPOSITE: "COMPOSITE",
    ENUM: "ENUM",
    TEXT: "TEXT",
    NUMERIC: "NUMERIC",
    BOOLEAN: "BOOLEAN",
    DATE: "DATE",
};
const recursiveBuilderSerialization = (filterType, filterName, filterValue) => {
    switch (filterType) {
        case exports.datastreamFilterFormatTypes.NUMERIC: {
            const { operator, values, type } = filterValue;
            if (operator === "between") {
                const greaterThan = values[0];
                const lessThan = values[1];
                return {
                    operator: "between",
                    greaterThan,
                    lessThan,
                };
            }
            else {
                return {
                    operator: operator,
                    value: values[0],
                };
            }
        }
        case exports.datastreamFilterFormatTypes.TEXT: {
            const { operator, values, type } = filterValue;
            return {
                operator: operator,
                value: values[0],
            };
        }
        case exports.datastreamFilterFormatTypes.ENUM: {
            const { operator, values, type } = filterValue;
            return {
                operator: operator,
                value: values[0],
            };
        }
        case exports.datastreamFilterFormatTypes.BOOLEAN: {
            const { operator, values, type } = filterValue;
            return {
                operator: operator,
                value: values[0],
            };
        }
        case exports.datastreamFilterFormatTypes.DATE: {
            const { operator, values, type } = filterValue;
            if (operator === "between") {
                const greaterThan = values[0];
                const lessThan = values[1];
                return {
                    operator: "between",
                    greaterThan,
                    lessThan,
                };
            }
            else {
                return {
                    operator: operator,
                    value: values[0],
                };
            }
        }
        case exports.datastreamFilterFormatTypes.OR: {
            return filterValue.filters.map((filter) => {
                return recursiveBuilderSerialization(filter.type, filterName, filter);
            });
        }
        case exports.datastreamFilterFormatTypes.COMPOSITE: {
            const nestedCompositeFilterType = filterValue.filter.type;
            const nestedCompositeFilters = filterValue.filter;
            const nestedCompositeFilter = recursiveBuilderSerialization(nestedCompositeFilterType, filterName, nestedCompositeFilters);
            return {
                compositeValue: filterValue.fields,
                values: Array.isArray(nestedCompositeFilter) ? nestedCompositeFilter : [nestedCompositeFilter],
            };
        }
    }
};
const getRootType = (filterValue, filterType) => {
    if (filterValue && "compositeValue" in filterValue) {
        return exports.datastreamFilterFormatTypes.COMPOSITE;
    }
    else if (filterValue && filterValue.length > 1) {
        return exports.datastreamFilterFormatTypes.OR;
    }
    else {
        return filterType;
    }
};
const recursiveBuilderDeserialization = (filterName, filterValue, filterType) => {
    const realFilterType = getRootType(filterValue, filterType);
    switch (realFilterType) {
        case exports.datastreamFilterFormatTypes.TEXT: {
            const realFilterValue = Array.isArray(filterValue)
                ? filterValue[0]
                : filterValue;
            const { operator, value } = realFilterValue;
            return {
                type: exports.datastreamFilterFormatTypes.TEXT,
                operator: operator,
                values: [value],
            };
        }
        case exports.datastreamFilterFormatTypes.NUMERIC: {
            const realFilterValue = Array.isArray(filterValue)
                ? filterValue[0]
                : filterValue;
            const { operator, value, greaterThan, lessThan } = realFilterValue;
            if (operator === "between") {
                return {
                    type: exports.datastreamFilterFormatTypes.NUMERIC,
                    operator: "between",
                    values: [greaterThan, lessThan],
                };
            }
            else {
                return {
                    type: exports.datastreamFilterFormatTypes.NUMERIC,
                    operator: operator,
                    values: [value],
                };
            }
        }
        case exports.datastreamFilterFormatTypes.DATE: {
            const realFilterValue = Array.isArray(filterValue)
                ? filterValue[0]
                : filterValue;
            const { operator, value, greaterThan, lessThan } = realFilterValue;
            if (operator === "between") {
                return {
                    type: exports.datastreamFilterFormatTypes.DATE,
                    operator: "between",
                    values: [greaterThan, lessThan],
                };
            }
            else {
                return {
                    type: exports.datastreamFilterFormatTypes.DATE,
                    operator: operator,
                    values: [value],
                };
            }
        }
        case exports.datastreamFilterFormatTypes.BOOLEAN: {
            const realFilterValue = Array.isArray(filterValue)
                ? filterValue[0]
                : filterValue;
            const { operator, value } = realFilterValue;
            return {
                type: exports.datastreamFilterFormatTypes.BOOLEAN,
                operator: operator,
                values: [value],
            };
        }
        case exports.datastreamFilterFormatTypes.OR: {
            const orFilters = filterValue.map((filter) => {
                return recursiveBuilderDeserialization(filterName, filter, filterType);
            });
            return {
                type: realFilterType,
                filters: orFilters,
            };
        }
        case exports.datastreamFilterFormatTypes.COMPOSITE: {
            const { compositeValue, values } = filterValue;
            return {
                type: realFilterType,
                fields: compositeValue,
                filter: recursiveBuilderDeserialization(filterName, values, filterType),
            };
        }
    }
};
const allFilters = `{"==":[true,true]}`;
exports.jsonLogicUtil = {
    serialize: (filters) => {
        const formattedDatastreams = [{}];
        if (!filters) {
            return undefined;
        }
        for (const [filterName, filterValue] of Object.entries(filters)) {
            const filterType = filterValue.type;
            let formattedDatastream = recursiveBuilderSerialization(filterType, filterName, filterValue);
            if (!Array.isArray(formattedDatastream) &&
                filterType !== exports.datastreamFilterFormatTypes.COMPOSITE &&
                filterType !== exports.datastreamFilterFormatTypes.BOOLEAN) {
                formattedDatastream = [formattedDatastream];
            }
            for (const iterDatastream of formattedDatastreams) {
                if (!(filterName in iterDatastream)) {
                    iterDatastream[filterName] = formattedDatastream;
                }
            }
        }
        return formattedDatastreams;
    },
    deserialize: (input, fieldMap) => {
        if (!input ||
            input === JSON.parse(allFilters) ||
            JSON.stringify(input) === "{}")
            return {};
        const deserializedFilters = {};
        for (const rootFilter of input) {
            for (const [filterName, filterValue] of Object.entries(rootFilter)) {
                const deserializedFilter = recursiveBuilderDeserialization(filterName, filterValue, fieldMap[filterName].type === exports.datastreamFilterFormatTypes.COMPOSITE
                    ? // @ts-ignore
                        fieldMap[filterName].dataType
                    : fieldMap[filterName].type);
                deserializedFilters[filterName] = deserializedFilter;
            }
        }
        return deserializedFilters;
    },
};
