import { __awaiter } from '../../../../_virtual/_tslib.js';
import { jsx, Fragment } from 'react/jsx-runtime';
import { useEffect } from 'react';
import { useInternalDynamicContext } from '../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import { LAST_USED_WALLET } from '../../utils/constants/localStorage.js';
import '../../utils/constants/colors.js';
import { LocalStorage } from '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../config/ApiEndpoint.js';
import '@dynamic-labs/multi-wallet';
import '@dynamic-labs/rpc-providers';
import '../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import { isTurnkeyEnabled } from '../../utils/functions/isTurnkeyEnabled/isTurnkeyEnabled.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import 'react-dom';
import '../ShadowDOM/ShadowDOM.js';
import '../../context/ThemeContext/ThemeContext.js';
import 'react-focus-lock';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../IconButton/IconButton.js';
import '../Alert/Alert.js';
import 'react-i18next';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'formik';
import 'yup';
import '../InlineWidget/InlineWidget.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../OverlayCard/OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import '../../context/MockContext/MockContext.js';
import '../../context/QrCodeContext/QrCodeContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../context/LoadingContext/LoadingContext.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import { useEmbeddedWallet } from '../../utils/hooks/useEmbeddedWallet/useEmbeddedWallet.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';

const SyncPasskeyFlow = () => {
    const { projectSettings, isProjectSettingsLoading, authMode, user } = useInternalDynamicContext();
    const lastUsedWallet = LocalStorage.getFromLS(LAST_USED_WALLET);
    const { createEmbeddedWallet } = useEmbeddedWallet();
    useEffect(() => {
        var _a;
        const createWallet = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield createEmbeddedWallet();
            }
            catch (error) {
                return;
            }
        });
        if (!user ||
            isProjectSettingsLoading ||
            !projectSettings ||
            !isTurnkeyEnabled(projectSettings) ||
            !((_a = projectSettings.sdk) === null || _a === void 0 ? void 0 : _a.automaticEmbeddedWalletCreation) ||
            lastUsedWallet !== undefined //untill embedded wallet is generated it's undefined
        ) {
            return;
        }
        createWallet();
    }, [
        isProjectSettingsLoading,
        projectSettings,
        lastUsedWallet,
        authMode,
        user,
        createEmbeddedWallet,
    ]);
    return jsx(Fragment, {});
};

export { SyncPasskeyFlow };
