"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParentLinkSerializer = void 0;
const serializers_1 = require("@metaplex-foundation/umi/serializers");
const _1 = require(".");
function getParentLinkSerializer() {
    return (0, serializers_1.struct)([
        ['parentRealm', (0, serializers_1.publicKey)()],
        ['status', (0, _1.getRequestStatusSerializer)()],
    ], { description: 'ParentLink' });
}
exports.getParentLinkSerializer = getParentLinkSerializer;
