import { Transaction, Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { AnchorProvider, Program, BN, web3 } from '@coral-xyz/anchor';
import { Cluster } from '@solana/web3.js';
import { IDL as DCA_IDL } from './dca-idl';
import { DCAStatus, DCA_PROGRAM_ID_BY_CLUSTER, DCA_SEED_KEY, DCA_TRACKER_BASE_URL } from './constants';
import {
  createSyncNativeInstruction,
  getAssociatedTokenAddressSync,
  getMint,
  NATIVE_MINT,
  createCloseAccountInstruction,
} from '@solana/spl-token';
import {
  CreateDCAParams,
  getTokenBalance,
  CloseDCAParams,
  DepositParams,
  WithdrawParams,
  getLamports,
  DcaAccountBalance,
  WithdrawFeesParams,
  FillHistory,
  RawFillHistory,
  CreateDCAParamsV2,
} from '.';
import { getOrCreateATAInstruction } from '../lib/utils';

export class DCA {
  private program: Program<typeof DCA_IDL>;

  constructor(private readonly connection: Connection, cluster: Cluster = 'mainnet-beta') {
    const provider = new AnchorProvider(connection, {} as any, AnchorProvider.defaultOptions());

    this.program = new Program(DCA_IDL, DCA_PROGRAM_ID_BY_CLUSTER[cluster], provider);
  }

  public async getDcaPubKey(user: PublicKey, inToken: PublicKey, outToken: PublicKey, uid: BN) {
    // const uidBuffer = uid.toBuffer('le', 8);
    const uidBuffer = uid.toArrayLike(Buffer, 'le', 8);

    const [dcaPubKey] = await PublicKey.findProgramAddressSync(
      [Buffer.from(DCA_SEED_KEY), user.toBuffer(), inToken.toBuffer(), outToken.toBuffer(), uidBuffer],
      this.program.programId,
    );

    return dcaPubKey;
  }

  // this only works for dca accounts that are still on-chain
  public async fetchDCA(dcaPubKey: PublicKey) {
    const res = await this.program.account.dca.fetch(dcaPubKey);

    return {
      ...res,
      inBalance: res.inDeposited.sub(res.inUsed).sub(res.inWithdrawn),
      outBalance: res.outReceived.sub(res.outReceived),
    };
  }

  public async createDCA(params: CreateDCAParams): Promise<{ tx: Transaction; dcaPubKey: PublicKey }> {
    let {
      user,
      inAmount,
      inAmountPerCycle,
      cycleSecondsApart,
      inputMint,
      outputMint,
      minOutAmountPerCycle,
      maxOutAmountPerCycle,
      startAt,
      userInTokenAccount,
    } = params;

    if (inAmount < inAmountPerCycle) throw new Error('inAmount must be greater than inAmountPerCycle');
    if (BigInt(cycleSecondsApart.toString()) < BigInt(60))
      throw new Error('cycleSecondsApart must be greater than 59 seconds');
    if (
      minOutAmountPerCycle &&
      maxOutAmountPerCycle &&
      BigInt(minOutAmountPerCycle.toString()) > BigInt(maxOutAmountPerCycle.toString())
    )
      throw new Error('minOutAmountPerCycle must be less than maxOutAmountPerCycle');
    if (startAt && BigInt(startAt.toString()) < BigInt(cycleSecondsApart.toString()))
      throw new Error('startAt must be greater than cycleSecondsApart');

    user = new PublicKey(user);
    inputMint = new PublicKey(inputMint);
    outputMint = new PublicKey(outputMint);

    if (!userInTokenAccount) userInTokenAccount = getAssociatedTokenAddressSync(inputMint, user, true);

    if (inputMint.equals(NATIVE_MINT) && new BN((await getLamports(this.connection, user)).toString()) < inAmount)
      throw new Error('User does not have sufficient inputMint');

    if (
      !inputMint.equals(NATIVE_MINT) &&
      new BN((await getTokenBalance(this.connection, userInTokenAccount)).toString()) < inAmount
    )
      throw new Error('User does not have sufficient inputMint');

    return this._createDCA(
      user,
      userInTokenAccount,
      inAmount,
      inAmountPerCycle,
      cycleSecondsApart,
      inputMint,
      outputMint,
      minOutAmountPerCycle,
      maxOutAmountPerCycle,
      startAt,
    );
  }

  private async _createDCA(
    user: PublicKey,
    userInTokenAccount: PublicKey,
    inAmount: bigint | BN,
    inAmountPerCycle: bigint | BN,
    cycleSecondsApart: bigint | BN,
    inputMint: PublicKey,
    outputMint: PublicKey,
    minOutAmount: bigint | BN | null = null,
    maxOutAmount: bigint | BN | null = null,
    startAt: bigint | BN | null = null,
  ): Promise<{ tx: Transaction; dcaPubKey: PublicKey }> {
    const preInstructions: TransactionInstruction[] = [];
    let isOpeningWsolAtaAsUserInAta = false;

    // create ata then wrap SOL
    if (inputMint.equals(NATIVE_MINT)) {
      const { ataPubKey, ix } = await getOrCreateATAInstruction(this.connection, inputMint, user);

      const transferIx = web3.SystemProgram.transfer({
        fromPubkey: user,
        lamports: new BN(inAmount.toString()).toNumber(),
        toPubkey: ataPubKey,
      });
      const syncNativeIX = createSyncNativeInstruction(ataPubKey);

      if (ix) {
        isOpeningWsolAtaAsUserInAta = true;
        preInstructions.push(ix);
      }
      preInstructions.push(transferIx);
      preInstructions.push(syncNativeIX);
    }

    // create user out ATA
    if (!outputMint.equals(NATIVE_MINT)) {
      const { ataPubKey, ix } = await getOrCreateATAInstruction(this.connection, outputMint, user);
      if (ix) preInstructions.push(ix);
    }

    const uid = new BN(parseInt((Date.now() / 1000).toString()));
    const dcaPubKey = await this.getDcaPubKey(user, inputMint, outputMint, uid);

    const tx = await this.program.methods
      .openDca(
        uid,
        new BN(inAmount.toString()),
        new BN(inAmountPerCycle.toString()),
        new BN(cycleSecondsApart.toString()),
        minOutAmount ? new BN(minOutAmount.toString()) : new BN(0),
        maxOutAmount ? new BN(maxOutAmount.toString()) : new BN(0),
        startAt ? new BN(startAt.toString()) : new BN(0),
        isOpeningWsolAtaAsUserInAta,
      )
      .accounts({
        user: user,
        dca: dcaPubKey,
        inputMint: inputMint,
        outputMint: outputMint,
        userAta: userInTokenAccount,
        inAta: getAssociatedTokenAddressSync(inputMint, dcaPubKey, true),
        outAta: getAssociatedTokenAddressSync(outputMint, dcaPubKey, true),
      })
      .preInstructions(preInstructions)
      .transaction();

    return {
      tx,
      dcaPubKey,
    };
  }

  public async createDcaV2(params: CreateDCAParamsV2): Promise<{ tx: Transaction; dcaPubKey: PublicKey }> {
    let {
      payer,
      user,
      inAmount,
      inAmountPerCycle,
      cycleSecondsApart,
      inputMint,
      outputMint,
      minOutAmountPerCycle,
      maxOutAmountPerCycle,
      startAt,
      userInTokenAccount,
    } = params;

    if (inAmount < inAmountPerCycle) throw new Error('inAmount must be greater than inAmountPerCycle');
    if (BigInt(cycleSecondsApart.toString()) < BigInt(60))
      throw new Error('cycleSecondsApart must be greater than 59 seconds');
    if (
      minOutAmountPerCycle &&
      maxOutAmountPerCycle &&
      BigInt(minOutAmountPerCycle.toString()) > BigInt(maxOutAmountPerCycle.toString())
    )
      throw new Error('minOutAmountPerCycle must be less than maxOutAmountPerCycle');
    if (startAt && BigInt(startAt.toString()) < BigInt(cycleSecondsApart.toString()))
      throw new Error('startAt must be greater than cycleSecondsApart');

    payer = new PublicKey(payer);
    user = new PublicKey(user);
    inputMint = new PublicKey(inputMint);
    outputMint = new PublicKey(outputMint);

    if (!userInTokenAccount) userInTokenAccount = getAssociatedTokenAddressSync(inputMint, user, true);

    if (inputMint.equals(NATIVE_MINT) && new BN((await getLamports(this.connection, user)).toString()) < inAmount)
      throw new Error('User does not have sufficient inputMint');

    if (
      !inputMint.equals(NATIVE_MINT) &&
      new BN((await getTokenBalance(this.connection, userInTokenAccount)).toString()) < inAmount
    )
      throw new Error('User does not have sufficient inputMint');

    return this._createDCAV2(
      payer,
      user,
      userInTokenAccount,
      inAmount,
      inAmountPerCycle,
      cycleSecondsApart,
      inputMint,
      outputMint,
      minOutAmountPerCycle,
      maxOutAmountPerCycle,
      startAt,
    );
  }

  private async _createDCAV2(
    payer: PublicKey,
    user: PublicKey,
    userInTokenAccount: PublicKey,
    inAmount: bigint | BN,
    inAmountPerCycle: bigint | BN,
    cycleSecondsApart: bigint | BN,
    inputMint: PublicKey,
    outputMint: PublicKey,
    minOutAmount: bigint | BN | null = null,
    maxOutAmount: bigint | BN | null = null,
    startAt: bigint | BN | null = null,
  ): Promise<{ tx: Transaction; dcaPubKey: PublicKey }> {
    const preInstructions: TransactionInstruction[] = [];
    const postInstructions: TransactionInstruction[] = [];

    // create ata then wrap SOL
    if (inputMint.equals(NATIVE_MINT)) {
      const { ataPubKey, ix } = await getOrCreateATAInstruction(this.connection, inputMint, user);

      const transferIx = web3.SystemProgram.transfer({
        fromPubkey: user,
        lamports: new BN(inAmount.toString()).toNumber(),
        toPubkey: ataPubKey,
      });
      const syncNativeIX = createSyncNativeInstruction(ataPubKey);

      if (ix) {
        preInstructions.push(ix);
        postInstructions.push(createCloseAccountInstruction(ataPubKey, user, user));
      }
      preInstructions.push(transferIx);
      preInstructions.push(syncNativeIX);
    }

    // create user out ATA
    if (!outputMint.equals(NATIVE_MINT)) {
      const { ix } = await getOrCreateATAInstruction(this.connection, outputMint, user);
      if (ix) preInstructions.push(ix);
    }

    const uid = new BN(parseInt((Date.now() / 1000).toString()));
    const dcaPubKey = await this.getDcaPubKey(user, inputMint, outputMint, uid);

    const tx = await this.program.methods
      .openDcaV2(
        uid,
        new BN(inAmount.toString()),
        new BN(inAmountPerCycle.toString()),
        new BN(cycleSecondsApart.toString()),
        minOutAmount ? new BN(minOutAmount.toString()) : new BN(0),
        maxOutAmount ? new BN(maxOutAmount.toString()) : new BN(0),
        startAt ? new BN(startAt.toString()) : new BN(0),
      )
      .accounts({
        dca: dcaPubKey,
        user: user,
        payer: payer,
        inputMint: inputMint,
        outputMint: outputMint,
        userAta: userInTokenAccount,
        inAta: getAssociatedTokenAddressSync(inputMint, dcaPubKey, true),
        outAta: getAssociatedTokenAddressSync(outputMint, dcaPubKey, true),
      })
      .preInstructions(preInstructions)
      .postInstructions(postInstructions)
      .transaction();

    return {
      tx,
      dcaPubKey,
    };
  }

  public async closeDCA({ user, dca }: CloseDCAParams): Promise<{ tx: Transaction }> {
    user = new PublicKey(user);
    dca = new PublicKey(dca);

    const dcaAccount = await this.fetchDCA(dca);

    const inputMint = dcaAccount.inputMint as PublicKey;
    const outputMint = dcaAccount.outputMint as PublicKey;

    const userInAta = getAssociatedTokenAddressSync(inputMint, user, true);
    const userOutAta = getAssociatedTokenAddressSync(outputMint, user, true);
    const inAta = getAssociatedTokenAddressSync(inputMint, dca, true);
    const outAta = getAssociatedTokenAddressSync(outputMint, dca, true);

    return this._closeDCA(user, dca, inputMint, outputMint, userInAta, userOutAta, inAta, outAta);
  }

  private async _closeDCA(
    user: PublicKey,
    dca: PublicKey,
    inputMint: PublicKey,
    outputMint: PublicKey,
    userInAta: PublicKey,
    userOutAta: PublicKey,
    inAta: PublicKey,
    outAta: PublicKey,
  ): Promise<{ tx: Transaction }> {
    const postInstructions: TransactionInstruction[] = [];

    if (inputMint.equals(NATIVE_MINT)) {
      postInstructions.push(createCloseAccountInstruction(userInAta, user, user));
    }

    if (outputMint.equals(NATIVE_MINT)) {
      postInstructions.push(createCloseAccountInstruction(userOutAta, user, user));
    }

    const tx = await this.program.methods
      .closeDca()
      .accounts({
        user,
        dca,
        inputMint,
        outputMint,
        inAta,
        outAta,
        userInAta,
        userOutAta,
      })
      .postInstructions(postInstructions)
      .transaction();

    return { tx };
  }

  public async deposit(params: DepositParams) {
    let { user, dca, amount } = params;

    user = new PublicKey(user);
    dca = new PublicKey(dca);

    const dcaAccount = await this.fetchDCA(dca);

    const userInAta = getAssociatedTokenAddressSync(dcaAccount.inputMint as PublicKey, user, false);

    const inAta = getAssociatedTokenAddressSync(dcaAccount.inputMint as PublicKey, dca, true);

    amount = new BN(amount.toString());

    return this._deposit(user, dca, dcaAccount.inputMint as PublicKey, userInAta, inAta, amount);
  }

  private async _deposit(
    user: PublicKey,
    dca: PublicKey,
    inputMint: PublicKey,
    userInAta: PublicKey,
    inAta: PublicKey,
    amount: BN,
  ) {
    const preInstructions: TransactionInstruction[] = [];

    if (inputMint.equals(NATIVE_MINT)) {
      const { ataPubKey, ix } = await getOrCreateATAInstruction(this.connection, inputMint, user);

      const transferIx = web3.SystemProgram.transfer({
        fromPubkey: user,
        lamports: new BN(amount.toString()).toNumber(),
        toPubkey: ataPubKey,
      });
      const syncNativeIX = createSyncNativeInstruction(ataPubKey);

      if (ix) preInstructions.push(ix);
      preInstructions.push(transferIx);
      preInstructions.push(syncNativeIX);
    }

    const tx = await this.program.methods
      .deposit(amount)
      .accounts({
        user,
        dca,
        userInAta,
        inAta,
      })
      .preInstructions(preInstructions)
      .transaction();

    return { tx };
  }

  public async withdraw({
    user,
    dca,
    inputMint,
    outputMint,
    withdrawInAmount,
    withdrawOutAmount,
  }: WithdrawParams): Promise<{ tx: Transaction }> {
    user = new PublicKey(user);
    dca = new PublicKey(dca);

    if (!inputMint || !outputMint) throw new Error('Need to supply at least input mint or output mint or both');

    if (inputMint && !withdrawInAmount) throw new Error('withdrawInAmount not supplied');

    if (outputMint && !withdrawOutAmount) throw new Error('withdrawOutAmount not supplied');

    const dcaAccount = await this.fetchDCA(dca);

    if (withdrawInAmount && !new PublicKey(inputMint).equals(dcaAccount.inputMint))
      throw new Error('Wrong inputMint supplied');

    if (withdrawOutAmount && !new PublicKey(outputMint).equals(dcaAccount.outputMint))
      throw new Error('Wrong outputMint supplied');

    const inAta = getAssociatedTokenAddressSync(dcaAccount.inputMint, dca, true);

    const outAta = getAssociatedTokenAddressSync(dcaAccount.outputMint, dca, true);

    const userInAta = getAssociatedTokenAddressSync(dcaAccount.inputMint, user, false);

    const userOutAta = getAssociatedTokenAddressSync(dcaAccount.outputMint, user, false);

    withdrawInAmount = withdrawInAmount ? new BN(withdrawInAmount.toString()) : new BN(0);
    withdrawOutAmount = withdrawOutAmount ? new BN(withdrawOutAmount.toString()) : new BN(0);

    inputMint = dcaAccount.inputMint;
    outputMint = dcaAccount.outputMint;

    return this._withdraw(
      user,
      dca,
      inputMint,
      outputMint,
      inAta,
      outAta,
      userInAta,
      userOutAta,
      withdrawInAmount,
      withdrawOutAmount,
    );
  }

  private async _withdraw(
    user: PublicKey,
    dca: PublicKey,
    inputMint: PublicKey,
    outputMint: PublicKey,
    inAta: PublicKey,
    outAta: PublicKey,
    userInAta: PublicKey,
    userOutAta: PublicKey,
    withdrawInAmount: BN,
    withdrawOutAmount: BN,
  ): Promise<{ tx: Transaction }> {
    const withdrawIn = { in: {} };
    const withdrawOut = { out: {} };

    let tx: Transaction;
    const postInstructions: TransactionInstruction[] = [];

    if (inputMint && withdrawInAmount && inputMint.equals(NATIVE_MINT)) {
      if (!userInAta) {
        throw new Error('userInAta not supplied');
      }
      postInstructions.push(createCloseAccountInstruction(userInAta, user, user));
    }

    if (outputMint && withdrawOutAmount && outputMint.equals(NATIVE_MINT)) {
      if (!userOutAta) {
        throw new Error('userOutAta not supplied');
      }
      postInstructions.push(createCloseAccountInstruction(userOutAta, user, user));
    }

    if (withdrawInAmount.gt(new BN(0)) && withdrawOutAmount.gt(new BN(0))) {
      const withdrawInParams = {
        withdrawAmount: withdrawInAmount,
        withdrawal: withdrawIn,
      };

      const withdrawInIx = await this.program.methods
        .withdraw(withdrawInParams)
        .accounts({
          user,
          dca,
          inputMint,
          outputMint,
          dcaAta: inAta,
          userInAta,
          userOutAta,
        })
        .instruction();

      const withdrawOutParams = {
        withdrawAmount: withdrawOutAmount,
        withdrawal: withdrawOut,
      };

      tx = await this.program.methods
        .withdraw(withdrawOutParams)
        .accounts({
          user,
          dca,
          inputMint,
          outputMint,
          dcaAta: outAta,
          userInAta,
          userOutAta,
        })
        .preInstructions([withdrawInIx])
        .postInstructions(postInstructions)
        .transaction();
    } else if (withdrawInAmount.gt(new BN(0))) {
      const withdrawParams = {
        withdrawAmount: withdrawInAmount,
        withdrawal: withdrawIn,
      };

      tx = await this.program.methods
        .withdraw(withdrawParams)
        .accounts({
          user,
          dca,
          inputMint,
          outputMint,
          dcaAta: inAta,
          userInAta,
          userOutAta,
        })
        .postInstructions(postInstructions)
        .transaction();
    } else if (withdrawOutAmount.gt(new BN(0))) {
      const withdrawParams = {
        withdrawAmount: withdrawOutAmount,
        withdrawal: withdrawOut,
      };

      tx = await this.program.methods
        .withdraw(withdrawParams)
        .accounts({
          user,
          dca,
          inputMint,
          outputMint,
          dcaAta: outAta,
          userInAta,
          userOutAta,
        })
        .postInstructions(postInstructions)
        .transaction();
    } else {
      throw new Error('Unexpected error - no amount specified for withdrawal');
    }

    return { tx };
  }

  public async getAll() {
    return this.program.account.dca.all();
  }

  public async getCurrentByUser(user: PublicKey) {
    return this.program.account.dca.all([
      {
        memcmp: {
          offset: 8,
          bytes: user.toBase58(),
        },
      },
    ]);
  }

  public async getClosedByUser(user: PublicKey) {
    const resp = await fetch(DCA_TRACKER_BASE_URL + `/user/${user.toString()}/dca?status=1`);

    let allDcaAccounts = (await resp.json()).data.dcaAccounts as {
      createdAt: string;
      updatedAt: string;
      userKey: string;
      dcaKey: string;
      inputMint: string;
      outputMint: string;
      inDeposited: string;
      inAmountPerCycle: string;
      cycleFrequency: string;
      closeTxHash: string;
      openTxHash: string;
      status: DCAStatus;
      userClosed: boolean;
      inWithdrawn: string;
      outWithdrawn: string;
      unfilledAmount: string;
      fills: {
        confirmedAt: string;
        inputMint: string;
        outputMint: string;
        inAmount: string;
        outAmount: string;
        feeMint: string;
        fee: string;
        txId: string;
        dcaKey: string;
        userKey: string;
      }[];
    }[];

    return allDcaAccounts.map((acc) => {
      // let inFilled = new BN(0)
      // let outReceived = new BN(0)

      let massagedFills: FillHistory[] = [];

      acc.fills.forEach((fill) => {
        // inFilled = new BN(fill.inAmount).add(inFilled)
        // outReceived = new BN(fill.outAmount).add(outReceived)
        massagedFills.push({
          userKey: new PublicKey(fill.userKey),
          dcaKey: new PublicKey(fill.dcaKey),
          inputMint: new PublicKey(fill.inputMint),
          outputMint: new PublicKey(fill.inputMint),
          inAmount: fill.inAmount,
          outAmount: fill.outAmount,
          feeMint: new PublicKey(fill.feeMint),
          fee: fill.fee,
          txId: fill.txId,
          confirmedAt: new Date(Date.parse(fill.confirmedAt)),
        });
      });

      return {
        publicKey: new PublicKey(acc.dcaKey),
        account: {
          createdAt: new BN(Math.floor(Date.parse(acc.createdAt) / 1000)),
          updatedAt: new BN(Math.floor(Date.parse(acc.updatedAt) / 1000)),
          user: new PublicKey(acc.userKey),
          inputMint: new PublicKey(acc.inputMint),
          outputMint: new PublicKey(acc.outputMint),
          inDeposited: new BN(acc.inDeposited),
          inAmountPerCycle: new BN(acc.inAmountPerCycle),
          cycleFrequency: new BN(acc.cycleFrequency),
          inFilled: new BN(acc.inDeposited).sub(new BN(acc.inWithdrawn)),
          outReceived: new BN(acc.outWithdrawn),
          // inFilled,
          // outReceived,
          inWithdrawn: new BN(acc.inWithdrawn),
          outWithdrawn: new BN(acc.outWithdrawn),
          unfilledAmount: new BN(acc.unfilledAmount),
          closeTxHash: acc.closeTxHash,
          openTxHash: acc.openTxHash,
          status: acc.status,
          userClosed: acc.userClosed,
        },
        fills: massagedFills,
      };
    });
  }

  public async getBalancesByAccount(dcaPubKey: PublicKey): Promise<DcaAccountBalance> {
    const dca = await this.fetchDCA(dcaPubKey);
    const inToken = await getMint(this.connection, dca.inputMint);
    const inTokenAmount = await getTokenBalance(this.connection, dca.inAccount);

    const outToken = await getMint(this.connection, dca.outputMint);
    const outTokenAmount = await getTokenBalance(this.connection, dca.outAccount);

    return {
      in: {
        ...inToken,
        dcaInAta: dca.inAccount,
        dcaBalance: inTokenAmount,
      },
      out: {
        ...outToken,
        dcaOutAta: dca.outAccount,
        dcaBalance: outTokenAmount,
      },
      stats: {
        inDeposited: BigInt((dca.inDeposited as BN).toString()),
        inWithdrawn: BigInt((dca.inWithdrawn as BN).toString()),
        outWithdrawn: BigInt((dca.outWithdrawn as BN).toString()),
        inUsed: BigInt((dca.inUsed as BN).toString()),
        outReceived: BigInt((dca.outReceived as BN).toString()),
      },
    };
  }

  public async withdrawFees({ admin, mint, amount }: WithdrawFeesParams): Promise<{ tx: Transaction }> {
    admin = new PublicKey(admin);
    mint = new PublicKey(mint);
    amount = new BN(amount.toString());

    let [feeAuthority] = await PublicKey.findProgramAddressSync([Buffer.from('fee')], this.program.programId);

    const programFeeAta = getAssociatedTokenAddressSync(mint, feeAuthority, true);

    const adminFeeAta = getAssociatedTokenAddressSync(mint, admin, false);

    const tx = await this.program.methods
      .withdrawFees(new BN(amount.toString()))
      .accounts({
        admin,
        mint,
        feeAuthority,
        programFeeAta,
        adminFeeAta,
      })
      .transaction();

    return { tx };
  }

  public async getFillHistory(dcaPubKey: string | PublicKey): Promise<FillHistory[]> {
    try {
      dcaPubKey = dcaPubKey.toString();
      const resp = await fetch(DCA_TRACKER_BASE_URL + `/dca/${dcaPubKey}/fills`);

      const fillsRes: Array<RawFillHistory> = (await resp.json()).data.fills;

      return fillsRes.map((fill) => {
        return {
          userKey: new PublicKey(fill.userKey),
          dcaKey: new PublicKey(fill.dcaKey),
          inputMint: new PublicKey(fill.inputMint),
          outputMint: new PublicKey(fill.outputMint),
          inAmount: fill.inAmount,
          outAmount: fill.outAmount,
          feeMint: new PublicKey(fill.feeMint),
          fee: fill.fee,
          txId: fill.txId,
          confirmedAt: new Date(fill.confirmedAt * 1000),
        };
      });
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  public async getAvailableTokens(): Promise<PublicKey[]> {
    const resp = await fetch('https://cache.jup.ag/top-tokens');
    const res: String[] = await resp.json();

    return res.map((pubkey) => new PublicKey(pubkey));
  }
}
