"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVerifierTypeSerializer = exports.VerifierType = void 0;
const serializers_1 = require("@metaplex-foundation/umi/serializers");
var VerifierType;
(function (VerifierType) {
    VerifierType[VerifierType["VerifierRealm"] = 0] = "VerifierRealm";
    VerifierType[VerifierType["VerfierOverride"] = 1] = "VerfierOverride";
})(VerifierType || (exports.VerifierType = VerifierType = {}));
function getVerifierTypeSerializer() {
    return (0, serializers_1.scalarEnum)(VerifierType, {
        description: 'VerifierType',
    });
}
exports.getVerifierTypeSerializer = getVerifierTypeSerializer;
