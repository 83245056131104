"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefiProgramNetNewUsersDailyRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class DefiProgramNetNewUsersDailyRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/defi/program-stats/daily-new-users";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.DefiProgramNetNewUsersDailyRequest = DefiProgramNetNewUsersDailyRequest;
