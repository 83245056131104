"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateStreamsRequest = void 0;
const deserialize_subscription_fn_1 = require("../../subscriptions/util/deserialize-subscription.fn");
const BaseRequest_class_1 = require("../BaseRequest.class");
function removeByKey(obj, property) {
    if (property in obj)
        delete obj[property];
    return obj;
}
class UpdateStreamsRequest extends BaseRequest_class_1.BaseRequest {
    subscription;
    method = "PUT";
    url = "/v0/subscriptions/update";
    constructor(subscription) {
        super(removeByKey({
            ...subscription.toSubscription(true),
            subscriptionId: subscription.subscriptionId,
        }, "id"));
        this.subscription = subscription;
    }
    async _send(apiKey, baseUrl) {
        const response = (await super._send(apiKey, baseUrl));
        if (!response) {
            return null;
        }
        else {
            return (0, deserialize_subscription_fn_1.deserializeSubscription)(response);
        }
    }
}
exports.UpdateStreamsRequest = UpdateStreamsRequest;
