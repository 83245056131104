"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataStreamFieldMapUtil = void 0;
exports.dataStreamFieldMapUtil = {
    isComposite: (map) => map.type === "COMPOSITE",
    isSimple: (map) => map.type === "TEXT" ||
        map.type === "NUMERIC" ||
        map.type === "BOOLEAN" ||
        map.type === "DATE" ||
        map.type === "ENUM",
};
