"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LpCreationStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    blockId: { type: "NUMERIC" },
    blockTime: { type: "DATE" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
    programId: { type: "TEXT" },
    instructionName: { type: "TEXT" },
    programName: { type: "TEXT" },
    poolAddress: { type: "TEXT" },
    lpTokenMint: { type: "TEXT" },
    tokenMint: { type: "COMPOSITE", dataType: "TEXT", fields: ["mintTokenA", "mintTokenB"] },
    tokenAccount: { type: "COMPOSITE", dataType: "TEXT", fields: ["tokenAccountA", "tokenAccountB"] },
};
class LpCreationStream extends base_data_stream_class_1.BaseDataStream {
    id = "LpCreation";
    static fieldMap = fieldMap;
}
exports.LpCreationStream = LpCreationStream;
