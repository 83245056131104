"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGovBoardingConfigGpaBuilder = exports.safeFetchAllGovBoardingConfig = exports.fetchAllGovBoardingConfig = exports.safeFetchGovBoardingConfig = exports.fetchGovBoardingConfig = exports.deserializeGovBoardingConfig = exports.getGovBoardingConfigAccountDataSerializer = void 0;
const umi_1 = require("@metaplex-foundation/umi");
const serializers_1 = require("@metaplex-foundation/umi/serializers");
function getGovBoardingConfigAccountDataSerializer() {
    return (0, serializers_1.mapSerializer)((0, serializers_1.struct)([
        ['discriminator', (0, serializers_1.array)((0, serializers_1.u8)(), { size: 8 })],
        ['verifierRealm', (0, serializers_1.publicKey)()],
        ['admin', (0, serializers_1.publicKey)()],
        ['verifierOverride', (0, serializers_1.option)((0, serializers_1.publicKey)())],
    ], { description: 'GovBoardingConfigAccountData' }), (value) => (Object.assign(Object.assign({}, value), { discriminator: [131, 250, 238, 215, 122, 68, 90, 179] })));
}
exports.getGovBoardingConfigAccountDataSerializer = getGovBoardingConfigAccountDataSerializer;
function deserializeGovBoardingConfig(rawAccount) {
    return (0, umi_1.deserializeAccount)(rawAccount, getGovBoardingConfigAccountDataSerializer());
}
exports.deserializeGovBoardingConfig = deserializeGovBoardingConfig;
function fetchGovBoardingConfig(context, publicKey, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const maybeAccount = yield context.rpc.getAccount((0, umi_1.publicKey)(publicKey, false), options);
        (0, umi_1.assertAccountExists)(maybeAccount, 'GovBoardingConfig');
        return deserializeGovBoardingConfig(maybeAccount);
    });
}
exports.fetchGovBoardingConfig = fetchGovBoardingConfig;
function safeFetchGovBoardingConfig(context, publicKey, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const maybeAccount = yield context.rpc.getAccount((0, umi_1.publicKey)(publicKey, false), options);
        return maybeAccount.exists
            ? deserializeGovBoardingConfig(maybeAccount)
            : null;
    });
}
exports.safeFetchGovBoardingConfig = safeFetchGovBoardingConfig;
function fetchAllGovBoardingConfig(context, publicKeys, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const maybeAccounts = yield context.rpc.getAccounts(publicKeys.map((key) => (0, umi_1.publicKey)(key, false)), options);
        return maybeAccounts.map((maybeAccount) => {
            (0, umi_1.assertAccountExists)(maybeAccount, 'GovBoardingConfig');
            return deserializeGovBoardingConfig(maybeAccount);
        });
    });
}
exports.fetchAllGovBoardingConfig = fetchAllGovBoardingConfig;
function safeFetchAllGovBoardingConfig(context, publicKeys, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const maybeAccounts = yield context.rpc.getAccounts(publicKeys.map((key) => (0, umi_1.publicKey)(key, false)), options);
        return maybeAccounts
            .filter((maybeAccount) => maybeAccount.exists)
            .map((maybeAccount) => deserializeGovBoardingConfig(maybeAccount));
    });
}
exports.safeFetchAllGovBoardingConfig = safeFetchAllGovBoardingConfig;
function getGovBoardingConfigGpaBuilder(context) {
    const programId = context.programs.getPublicKey('govBoarding', 'GovyJPza6EV6srUcmwA1vS3EmWGdLSkkDafRE54X1Dir');
    return (0, umi_1.gpaBuilder)(context, programId)
        .registerFields({
        discriminator: [0, (0, serializers_1.array)((0, serializers_1.u8)(), { size: 8 })],
        verifierRealm: [8, (0, serializers_1.publicKey)()],
        admin: [40, (0, serializers_1.publicKey)()],
        verifierOverride: [72, (0, serializers_1.option)((0, serializers_1.publicKey)())],
    })
        .deserializeUsing((account) => deserializeGovBoardingConfig(account))
        .whereField('discriminator', [131, 250, 238, 215, 122, 68, 90, 179]);
}
exports.getGovBoardingConfigGpaBuilder = getGovBoardingConfigGpaBuilder;
