{
    "name": "@dynamic-labs/iconic",
    "version": "0.1.34",
    "description": "A library of icons used by Dynamic Lab's SDK, served via CDN",
    "main": "dist/src/index.js",
    "scripts": {
        "build": "rimraf dist build && tsc && ts-node ./scripts/build-sprite.ts",
        "test": "jest",
        "lint": "eslint .",
        "lint:fix": "eslint --fix",
        "format": "prettier --write './**/*.{js,jsx,ts,tsx,css,md,json}' --config ./.prettierrc"
    },
    "repository": {
        "type": "git",
        "url": "git+https://github.com/dynamic-labs/iconic.git"
    },
    "author": "Dynamic Labs, Inc.",
    "license": "MIT",
    "bugs": {
        "url": "https://github.com/dynamic-labs/iconic/issues"
    },
    "homepage": "https://github.com/dynamic-labs/iconic#readme",
    "devDependencies": {
        "@babel/preset-react": "^7.18.6",
        "@testing-library/jest-dom": "^5.16.5",
        "@testing-library/react": "^13.4.0",
        "@types/fs-extra": "^11.0.1",
        "@types/jest": "^29.4.0",
        "@types/node": "^18.13.0",
        "@types/react": "^18.0.27",
        "@types/svg-sprite": "^0.0.34",
        "@types/svgo": "^3.0.0",
        "@typescript-eslint/eslint-plugin": "^5.0.0",
        "eslint": "^8.0.1",
        "eslint-config-prettier": "^8.6.0",
        "eslint-config-standard-with-typescript": "^34.0.0",
        "eslint-plugin-import": "^2.25.2",
        "eslint-plugin-n": "^15.0.0",
        "eslint-plugin-prettier": "^4.2.1",
        "eslint-plugin-promise": "^6.0.0",
        "eslint-plugin-react": "^7.32.2",
        "fs-extra": "^11.1.0",
        "jest": "^29.4.2",
        "jest-environment-jsdom": "^29.4.2",
        "prettier": "^2.8.4",
        "react": "^18.0.0",
        "react-dom": "^18.0.0",
        "rimraf": "^4.1.2",
        "svg-sprite": "^2.0.2",
        "svgo": "^3.0.2",
        "ts-jest": "^29.0.5",
        "ts-node": "^10.9.1",
        "typescript": "*"
    },
    "peerDependencies": {
        "react": "^17.0.2 || ^18.0.0"
    },
    "exports": {
        ".": "./dist/src/index.js",
        "./package.json": "./package.json"
    },
    "files": [
        "dist",
        "README.md",
        "LICENSE"
    ],
    "dependencies": {}
}
