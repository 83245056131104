"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CitrusLoanSummaryRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class CitrusLoanSummaryRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/citrus/loan-summary";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.CitrusLoanSummaryRequest = CitrusLoanSummaryRequest;
