import { jsxs, jsx } from 'react/jsx-runtime';
import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from '../AnimatePresence/AnimatePresence.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import { SlideInUpTransition } from '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import { OpacityTransition } from '../Transition/OpacityTransition/OpacityTransition.js';
import '@dynamic-labs/utils';
import '@dynamic-labs/sdk-api';
import '../../config/ApiEndpoint.js';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import { usePreventPageScroll } from '../../shared/utils/hooks/usePreventPageScroll/usePreventPageScroll.js';
import '../../shared/consts/index.js';
import '@dynamic-labs/multi-wallet';
import '@dynamic-labs/rpc-providers';
import '../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../context/DynamicContext/DynamicContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import { ShadowDOM } from '../ShadowDOM/ShadowDOM.js';
import '../../context/ThemeContext/ThemeContext.js';
import 'react-focus-lock';
import '../IconButton/IconButton.js';
import '../Alert/Alert.js';
import 'react-i18next';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'formik';
import 'yup';
import '../InlineWidget/InlineWidget.js';
import '../MenuList/Dropdown/Dropdown.js';
import { overlayCardContext } from './OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import '../../context/MockContext/MockContext.js';
import '../../context/QrCodeContext/QrCodeContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../context/LoadingContext/LoadingContext.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';

const OverlayCard = ({ isOpen, children, onClickOverlay, style, className, }) => {
    const context = useContext(overlayCardContext);
    usePreventPageScroll(isOpen && !context.portal);
    const content = (jsxs("div", { children: [jsx(AnimatePresence, { animationComponent: jsx(OpacityTransition, {}), children: isOpen && (jsx("div", { onClick: onClickOverlay, className: 'overlay-card--overlay' })) }), jsx(AnimatePresence, { animationComponent: jsx(SlideInUpTransition, {}), children: isOpen && (jsx(AnimatePresence, { animationComponent: jsx(OpacityTransition, {}), children: isOpen && (jsx("div", { className: classNames(className, 'overlay-card--content'), style: style, children: children })) })) })] }));
    if (context === null || context === void 0 ? void 0 : context.portal)
        return createPortal(content, context.portal);
    return createPortal(jsx(ShadowDOM, { children: content }), document.body);
};

export { OverlayCard };
