"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WashtradingCollectionIndexV7Request = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class WashtradingCollectionIndexV7Request extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/nft/collection/washtrading";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.WashtradingCollectionIndexV7Request = WashtradingCollectionIndexV7Request;
