import { deserialize, Schema } from "borsh";
import BN from "bn.js";
import { Connection, PublicKey } from "@solana/web3.js";

/**
 * Account tags (used for deserialization on-chain)
 */
export enum Tag {
  Uninitialized = 0,
  ActiveOffer = 1,
  CancelledOffer = 2,
  AcceptedOffer = 3,
  FavouriteDomain = 4,
  FixedPriceOffer = 5,
  AcceptedFixedPriceOffer = 6,
  CancelledFixedPriceOffer = 7,
}

export class Offer {
  tag: Tag;
  nonce: number;
  nameAccount: PublicKey;
  owner: PublicKey;
  quoteMint: PublicKey;
  offerAmount: BN;
  escrow: PublicKey;

  static schema: Schema = new Map([
    [
      Offer,
      {
        kind: "struct",
        fields: [
          ["tag", "u8"],
          ["nonce", "u8"],
          ["nameAccount", [32]],
          ["owner", [32]],
          ["quoteMint", [32]],
          ["offerAmount", "u64"],
          ["escrow", [32]],
        ],
      },
    ],
  ]);

  constructor(obj: {
    tag: number;
    nonce: number;
    nameAccount: Uint8Array;
    owner: Uint8Array;
    quoteMint: Uint8Array;
    offerAmount: BN;
    escrow: Uint8Array;
  }) {
    this.tag = obj.tag as Tag;
    this.nonce = obj.nonce;
    this.nameAccount = new PublicKey(obj.nameAccount);
    this.owner = new PublicKey(obj.owner);
    this.quoteMint = new PublicKey(obj.quoteMint);
    this.offerAmount = obj.offerAmount;
    this.escrow = new PublicKey(obj.escrow);
  }

  /**
   * This function can be used to deserialize a Buffer into an Offer object
   * @param data The buffer to deserialize
   * @returns
   */
  static deserialize(data: Buffer) {
    return deserialize(this.schema, Offer, data);
  }

  /**
   * This function can be used to retrieve and deserialize an offer
   * @param connection The Solana RPC connection object
   * @param key The offer key
   * @returns
   */
  static async retrieve(connection: Connection, key: PublicKey) {
    const accountInfo = await connection.getAccountInfo(key);
    if (!accountInfo || !accountInfo.data) {
      throw new Error("Offer not found");
    }
    return this.deserialize(accountInfo.data);
  }

  /**
   * This function can be used to derive the PDA of an offer
   * @param programId The name offer program ID
   * @param owner The owner of the offer
   * @param quoteMint The quote mint of the offer
   * @param nameAccount The name account key
   * @returns
   */
  static async getKey(
    programId: PublicKey,
    owner: PublicKey,
    quoteMint: PublicKey,
    nameAccount: PublicKey
  ) {
    return await PublicKey.findProgramAddress(
      [
        Buffer.from("offer_account"),
        owner.toBuffer(),
        quoteMint.toBuffer(),
        nameAccount.toBuffer(),
      ],
      programId
    );
  }
}

export class FavouriteDomain {
  tag: Tag;
  nameAccount: PublicKey;

  static schema: Schema = new Map([
    [
      FavouriteDomain,
      {
        kind: "struct",
        fields: [
          ["tag", "u8"],
          ["nameAccount", [32]],
        ],
      },
    ],
  ]);

  constructor(obj: { tag: number; nameAccount: Uint8Array }) {
    this.tag = obj.tag as Tag;
    this.nameAccount = new PublicKey(obj.nameAccount);
  }

  /**
   * This function can be used to deserialize a Buffer into a FavouriteDomain object
   * @param data The buffer to deserialize
   * @returns
   */
  static deserialize(data: Buffer) {
    return deserialize(this.schema, FavouriteDomain, data);
  }

  /**
   * This function can be used to retrieve and deserialize a favorite domain
   * @param connection The Solana RPC connection object
   * @param key The favorite account key
   * @returns
   */
  static async retrieve(connection: Connection, key: PublicKey) {
    const accountInfo = await connection.getAccountInfo(key);
    if (!accountInfo || !accountInfo.data) {
      throw new Error("Favourite domain not found");
    }
    return this.deserialize(accountInfo.data);
  }

  /**
   * This function can be used to derive the key of a favorite domain
   * @param programId The name offer program ID
   * @param owner The owner to retrieve the favorite domain for
   * @returns
   */
  static async getKey(programId: PublicKey, owner: PublicKey) {
    return await PublicKey.findProgramAddress(
      [Buffer.from("favourite_domain"), owner.toBuffer()],
      programId
    );
  }
}

export class FixedPriceOffer {
  tag: Tag;
  nonce: number;
  nameAccount: PublicKey;
  owner: PublicKey;
  quoteMint: PublicKey;
  offerAmount: BN;
  tokenDestination: PublicKey;

  static schema: Schema = new Map([
    [
      FixedPriceOffer,
      {
        kind: "struct",
        fields: [
          ["tag", "u8"],
          ["nonce", "u8"],
          ["nameAccount", [32]],
          ["owner", [32]],
          ["quoteMint", [32]],
          ["offerAmount", "u64"],
          ["tokenDestination", [32]],
        ],
      },
    ],
  ]);

  constructor(obj: {
    tag: number;
    nonce: number;
    nameAccount: Uint8Array;
    owner: Uint8Array;
    quoteMint: Uint8Array;
    offerAmount: BN;
    tokenDestination: Uint8Array;
  }) {
    this.tag = obj.tag as Tag;
    this.nonce = obj.nonce;
    this.nameAccount = new PublicKey(obj.nameAccount);
    this.owner = new PublicKey(obj.owner);
    this.quoteMint = new PublicKey(obj.quoteMint);
    this.offerAmount = obj.offerAmount;
    this.tokenDestination = new PublicKey(obj.tokenDestination);
  }

  /**
   * This function can be used to deserialize a Buffer into a FixedPriceOffer object
   * @param data The buffer to deserialize
   * @returns
   */
  static deserialize(data: Buffer) {
    return deserialize(this.schema, FixedPriceOffer, data);
  }

  /**
   * This function can be used to retrieve and deserialize a fixed price offer
   * @param connection The Solana RPC connection object
   * @param key The key of the fixed price offer
   * @returns
   */
  static async retrieve(connection: Connection, key: PublicKey) {
    const accountInfo = await connection.getAccountInfo(key);
    if (!accountInfo || !accountInfo.data) {
      throw new Error("Fixed price offer not found");
    }
    return this.deserialize(accountInfo.data);
  }

  /**
   * This function can be used to derive the key of a fixed price offer
   * @param programId The name offer program ID
   * @param owner The owner of the fixed price
   * @param quoteMint The quote mint of the fixed price offer
   * @param nameAccount The name account of the fixed price offer
   * @returns
   */
  static async getKey(
    programId: PublicKey,
    owner: PublicKey,
    quoteMint: PublicKey,
    nameAccount: PublicKey
  ) {
    return await PublicKey.findProgramAddress(
      [
        Buffer.from("fixed_price_offer"),
        owner.toBuffer(),
        quoteMint.toBuffer(),
        nameAccount.toBuffer(),
      ],
      programId
    );
  }
}
