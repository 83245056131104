"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenTransferStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    mint: { type: "TEXT" },
    amount: { type: "NUMERIC" },
    authority: { type: "TEXT" },
    owner: { type: "COMPOSITE", dataType: "TEXT", fields: ["sourceOwner", "destinationOwner"] },
    account: { type: "COMPOSITE", dataType: "TEXT", fields: ["source", "destination"] },
    preBalances: { type: "COMPOSITE", dataType: "NUMERIC", fields: ["sourcePreBalance", "destinationPreBalance"] },
    postBalances: { type: "COMPOSITE", dataType: "NUMERIC", fields: ["sourcePostBalance", "destinationPostBalance"] },
    programId: { type: "TEXT" },
    type: { type: "TEXT" },
    blockTime: { type: "DATE" },
    blockId: { type: "NUMERIC" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionPosition: { type: "NUMERIC" },
    subInstructionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
    outerInstructionProgramId: { type: "TEXT" },
};
class TokenTransferStream extends base_data_stream_class_1.BaseDataStream {
    id = "TokenTransfer";
    static fieldMap = fieldMap;
}
exports.TokenTransferStream = TokenTransferStream;
