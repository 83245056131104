import { __awaiter } from '../../../../_virtual/_tslib.js';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicError, isIPhone, isIPhone8OrEarlier, isAndroid, getAndroidVersion } from '@dynamic-labs/utils';
import { useInternalDynamicContext } from '../../context/DynamicContext/useInternalDynamicContext.js';
import { Typography } from '../../components/Typography/Typography.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgFooterInfoIcon } from '../../shared/assets/footer-info-icon.js';
import { ReactComponent as SvgPasskeyIntroIcon } from '../../shared/assets/passkey-intro-icon.js';
import { ReactComponent as SvgAndroidFaceId } from '../../shared/assets/android-face-id.js';
import { ReactComponent as SvgAndroidTouchId } from '../../shared/assets/android-touch-id.js';
import { ReactComponent as SvgIphoneFaceId } from '../../shared/assets/iphone-face-id.js';
import { ReactComponent as SvgIphoneTouchId } from '../../shared/assets/iphone-touch-id.js';
import '@dynamic-labs/wallet-book';
import { LAST_USED_WALLET } from '../../utils/constants/localStorage.js';
import '../../utils/constants/colors.js';
import { LocalStorage } from '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import { storeAuthToken } from '../../utils/functions/storeAuthToken/index.js';
import '@dynamic-labs/multi-wallet';
import { createUserProfile } from '../../utils/functions/createUserProfile/createUserProfile.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../context/ThemeContext/ThemeContext.js';
import { Icon } from '../../components/Icon/Icon.js';
import 'react-dom';
import 'react-focus-lock';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../components/OverlayCard/OverlayCard.context.js';
import { useFooterAnimationContext } from '../../context/FooterAnimationContext/index.js';
import '../../context/MockContext/MockContext.js';
import '../../context/QrCodeContext/QrCodeContext.js';
import { Tooltip } from '../../components/Tooltip/Tooltip.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { usePromise } from '../../utils/hooks/usePromise/usePromise.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../context/LoadingContext/LoadingContext.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import { ErrorContainer } from '../../components/ErrorContainer/ErrorContainer.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import { Button } from '../../components/Button/Button.js';
import { ModalHeader } from '../../components/ModalHeader/ModalHeader.js';
import { IconButton } from '../../components/IconButton/IconButton.js';
import '../../components/Alert/Alert.js';
import { useMutation } from '../../utils/hooks/useMutation/useMutation.js';
import { TextButton } from '../../components/TextButton/TextButton.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import { isPasskeyEnabled } from '../../utils/functions/isPasskeyEnabled/isPasskeyEnabled.js';
import { createEmbeddedWalletWithPasskey } from './utils/createEmbeddedWalletWithPasskey/createEmbeddedWalletWithPasskey.js';
import { findPasskeyEmailWalletConnector } from './utils/findPasskeyEmailWalletConnector/findPasskeyEmailWalletConnector.js';

const PasskeyIntroView = ({ createEmbeddedWalletMutation = createEmbeddedWalletWithPasskey, }) => {
    const { user, wallets, setPrimaryWalletId, setUser, authToken, environmentId, handleLogOut, internalEvents, setShowAuthFlow, setCallback, } = useInternalDynamicContext();
    const { setIsFooterExpanded } = useFooterAnimationContext();
    const { t } = useTranslation();
    const { data: passkeyEnabled } = usePromise(isPasskeyEnabled);
    // if passkey is not yet setup, but user logged in with email in a passkey env
    // it should be null to force user to setup passkey even after the a refresh
    useEffect(() => LocalStorage.removeFromLS(LAST_USED_WALLET), []);
    const { mutate: createEmbeddedWalletWithPasskeyMutation, isLoading, error, } = useMutation(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { embeddedWalletVerifiedCredentialId, jwt, decodedJwt, walletConnector, } = yield createEmbeddedWalletMutation({
            authToken,
            environmentId,
            user,
            walletConnector: findPasskeyEmailWalletConnector(wallets),
        });
        const passkeyWallet = {
            address: (yield walletConnector.fetchPublicAddress()) || '',
            chain: walletConnector.connectedChain || '',
            connected: true,
            connector: walletConnector,
            id: embeddedWalletVerifiedCredentialId,
        };
        internalEvents.current.emit('embeddedWalletCreated', passkeyWallet);
        setPrimaryWalletId(embeddedWalletVerifiedCredentialId);
        storeAuthToken(jwt);
        setUser(createUserProfile(decodedJwt));
        setShowAuthFlow(false);
        const turnkeyVerifiedCredentials = (_a = decodedJwt === null || decodedJwt === void 0 ? void 0 : decodedJwt.verifiedCredentials) === null || _a === void 0 ? void 0 : _a.find(({ walletName }) => walletName === 'turnkey');
        setCallback('embeddedWalletCreated', {
            email: (turnkeyVerifiedCredentials === null || turnkeyVerifiedCredentials === void 0 ? void 0 : turnkeyVerifiedCredentials.email) || '',
            verifiedCredential: turnkeyVerifiedCredentials,
        });
    }), {
        onFailure: (err) => {
            logger.error('Failed to create embedded wallet', err);
            internalEvents.current.emit('embeddedWalletFailed', err);
        },
    });
    const errorText = useMemo(() => {
        if (!error) {
            return undefined;
        }
        if (error instanceof DynamicError) {
            return "Make sure you're logged in.";
        }
        return 'Something went wrong.';
    }, [error]);
    const infoIconButton = (jsx(Tooltip, { content: t('dyn_passkey_intro.helper.tooltip'), className: 'header__tooltip', children: jsx(IconButton, { type: 'button', "data-testid": 'info-button', onClick: () => setIsFooterExpanded((prev) => !prev), className: 'header__icon', children: jsx(SvgFooterInfoIcon, {}) }) }, 'info-button'));
    const renderIcon = () => {
        let IdIcon;
        if (isIPhone()) {
            if (isIPhone8OrEarlier()) {
                IdIcon = jsx(SvgIphoneTouchId, { "data-testid": 'iphone-8-icon' });
            }
            else {
                IdIcon = jsx(SvgIphoneFaceId, { "data-testid": 'iphone-icon' });
            }
        }
        if (isAndroid()) {
            const androidVersion = getAndroidVersion();
            if (androidVersion && androidVersion >= 9) {
                IdIcon = jsx(SvgAndroidTouchId, { "data-testid": 'android-9-icon' });
            }
            else {
                IdIcon = jsx(SvgAndroidFaceId, { "data-testid": 'android-icon' });
            }
        }
        if (!IdIcon) {
            return;
        }
        return jsx(Icon, { size: 'small', children: IdIcon });
    };
    return (jsxs("div", { className: 'passkey-intro-modal-wrapper', children: [jsx(ModalHeader, { leading: infoIconButton, children: jsx("div", { className: 'passkey-intro-view__header', children: jsx(SvgPasskeyIntroIcon, {}) }) }), jsx("div", { className: 'passkey-intro-view', children: jsxs("div", { className: 'passkey-intro-view__content', children: [jsx(Typography, { variant: 'title', color: 'primary', className: 'passkey-intro-view__title', children: t('dyn_passkey_intro.title') }), jsx(Typography, { variant: 'body_normal', weight: 'regular', color: 'secondary', className: 'passkey-intro-view__subtitle', children: t('dyn_passkey_intro.subtitle') }), jsx(Typography, { variant: 'body_normal', weight: 'regular', color: 'secondary', className: 'passkey-intro-view__subtitle--underline', children: t('dyn_passkey_intro.warning') }), passkeyEnabled === false && (jsx(ErrorContainer, { children: t('dyn_passkey_intro.disabled') })), errorText && jsx(ErrorContainer, { children: errorText }), jsxs("div", { className: 'passkey-intro-view__actions', children: [jsx(Button, { buttonPadding: 'large', onClick: () => createEmbeddedWalletWithPasskeyMutation(), dataTestId: 'setup-passkey-button', loading: isLoading, disabled: isLoading || !passkeyEnabled, children: jsxs("div", { className: 'passkey-intro-view__inline-button', children: [renderIcon(), jsx(Typography, { children: t('dyn_passkey_intro.button') })] }) }), jsx(TextButton, { "data-testid": 'setup-passkey-logout-button', className: 'passkey-intro-view__log-out', onClick: handleLogOut, children: t('dyn_passkey_intro.button_logout') })] })] }) })] }));
};

export { PasskeyIntroView };
