"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnparsedAccountStreamUpdateRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class UnparsedAccountStreamUpdateRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "PUT";
    url = "/v0/accounts/stream/update";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.UnparsedAccountStreamUpdateRequest = UnparsedAccountStreamUpdateRequest;
