"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletAllTimeStatsRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class WalletAllTimeStatsRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/nft/magiceden/wallet-all-time-stats";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.WalletAllTimeStatsRequest = WalletAllTimeStatsRequest;
