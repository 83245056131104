import { jsx } from 'react/jsx-runtime';
import 'react';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../utils/constants/colors.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/multi-wallet';
import { ShadowDOM } from '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../context/ThemeContext/ThemeContext.js';
import 'react-dom';
import 'react-focus-lock';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../context/MockContext/MockContext.js';
import '../../../context/QrCodeContext/QrCodeContext.js';
import 'react-i18next';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import { DynamicUserProfileLayout } from '../../../layout/DynamicUserProfileLayout/DynamicUserProfileLayout.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/Alert/Alert.js';
import 'formik';
import 'yup';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import '@dynamic-labs/types';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import { useIsLoggedIn } from '../../../utils/hooks/useIsLoggedIn/useIsLoggedIn.js';
import '../../../components/Popper/Popper/Popper.js';
import { PopperProvider } from '../../../components/Popper/PopperContext/PopperContext.js';
import { ModalCard } from '../../../components/ModalCard/ModalCard.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';

const DynamicEmbeddedUserProfile = ({ background = 'none', className, style }) => {
    const show = useIsLoggedIn();
    if (!show)
        return null;
    const content = (jsx(DynamicUserProfileLayout, { className: className, style: style, variant: 'modal' }));
    return (jsx(ShadowDOM, { className: 'embedded-widget', children: jsx(PopperProvider, { children: background === 'none' ? (content) : (jsx(ModalCard, { border: background === 'with-border', sharpBottomRadiusOnMobile: false, children: content })) }) }));
};

export { DynamicEmbeddedUserProfile };
