"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FraktBondsSummaryRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class FraktBondsSummaryRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/frakt/loan-summary/bonds";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.FraktBondsSummaryRequest = FraktBondsSummaryRequest;
