"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSwapStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    programId: { type: "TEXT" },
    programName: { type: "TEXT" },
    aggregator: { type: "TEXT" },
    mint: { type: "COMPOSITE", dataType: "TEXT", fields: ["srcMint", "dstMint"] },
    amount: { type: "COMPOSITE", dataType: "NUMERIC", fields: ["srcAmount", "dstAmount"] },
    tokenAccount: { type: "COMPOSITE", dataType: "TEXT", fields: ["srcTokenAccount", "dstTokenAccount"] },
    instructionName: { type: "TEXT" },
    blockTime: { type: "DATE" },
    blockId: { type: "NUMERIC" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
};
class TokenSwapStream extends base_data_stream_class_1.BaseDataStream {
    id = "TokenSwap";
    static fieldMap = fieldMap;
}
exports.TokenSwapStream = TokenSwapStream;
