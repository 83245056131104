"use strict";
/**
 * This code was AUTOGENERATED using the kinobi library.
 * Please DO NOT EDIT THIS FILE, instead use visitors
 * to add features, then rerun kinobi to update it.
 *
 * @see https://github.com/metaplex-foundation/kinobi
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRequestStatusSerializer = exports.RequestStatus = void 0;
const serializers_1 = require("@metaplex-foundation/umi/serializers");
var RequestStatus;
(function (RequestStatus) {
    RequestStatus[RequestStatus["Pending"] = 0] = "Pending";
    RequestStatus[RequestStatus["Approved"] = 1] = "Approved";
    RequestStatus[RequestStatus["Rejected"] = 2] = "Rejected";
    RequestStatus[RequestStatus["Disabled"] = 3] = "Disabled";
})(RequestStatus || (exports.RequestStatus = RequestStatus = {}));
function getRequestStatusSerializer() {
    return (0, serializers_1.scalarEnum)(RequestStatus, {
        description: 'RequestStatus',
    });
}
exports.getRequestStatusSerializer = getRequestStatusSerializer;
