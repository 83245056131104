"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteStreamsRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class DeleteStreamsRequest extends BaseRequest_class_1.BaseRequest {
    subscriptionId;
    method = "DELETE";
    url = "/v0/subscriptions/remove";
    constructor(subscriptionId) {
        super(undefined, undefined, undefined, subscriptionId);
        this.subscriptionId = subscriptionId;
    }
    async _send(apiKey, baseUrl) {
        const response = (await super.sendAsCustomUrlParam(apiKey, baseUrl));
    }
}
exports.DeleteStreamsRequest = DeleteStreamsRequest;
