import {
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Connection,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  makeOfferInstruction,
  cancelOfferInstruction,
  acceptOfferInstruction,
  registerFavouriteInstruction,
  makeFixedPriceInstruction,
  cancelFixedPriceInstruction,
  buyFixedPriceInstruction,
} from "./raw_instructions";
import BN from "bn.js";
import { Offer, FavouriteDomain, FixedPriceOffer } from "./state";
import {
  TOKEN_PROGRAM_ID,
  Token,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { NAME_PROGRAM_ID } from "@bonfida/spl-name-service";

/** Mainnet program ID */
export const NAME_OFFERS_ID = new PublicKey(
  "85iDfUvr3HJyLM2zcq5BXSiDvUWfw6cSE1FfNBo8Ap29"
);

/** Devnet program ID */
export const NAME_OFFERS_ID_DEVNET = new PublicKey(
  "zugu92jR3kqgFiNEJywq7gbbc9NbaLmHLiQhsZRwd6J"
);

/** Fee collecting address */
export const FEE_OWNER = new PublicKey(
  "GcWEQ9K78FV7LEHteFVciYApERk5YvQuFDQPk1yYJVXi"
);

/** Root TLD */
export const ROOT_DOMAIN = new PublicKey(
  "58PwtjSDuFHuUkYjH9BYnnQKHfwo9reZhC2zMJv9JPkx"
);

/**
 * This function can be used to create an usolicited offer
 * @param amount Raw amount of the offer
 * @param nameAccount The domain name on which the unsolicited offer is made
 * @param owner The owner of the usolicited offer
 * @param quoteMint The quote token mint of the usolicited offer
 * @param tokenSource The token account from which the tokens originate from
 * @param programId The name offer program ID
 * @returns
 */
export const makeOffer = async (
  amount: number,
  nameAccount: PublicKey,
  owner: PublicKey,
  quoteMint: PublicKey,
  tokenSource: PublicKey,
  programId: PublicKey
) => {
  const [offerKey] = await Offer.getKey(
    programId,
    owner,
    quoteMint,
    nameAccount
  );
  const [escrow] = await PublicKey.findProgramAddress(
    [offerKey.toBuffer()],
    programId
  );
  const ix = new makeOfferInstruction({
    amount: new BN(amount),
    nameAccount: nameAccount.toBuffer(),
  }).getInstruction(
    programId,
    owner,
    quoteMint,
    tokenSource,
    escrow,
    offerKey,
    SystemProgram.programId,
    SYSVAR_RENT_PUBKEY,
    TOKEN_PROGRAM_ID
  );

  return [ix];
};

/**
 * This function can be used to cancel an unsolicited offer
 * @param owner The owner of the unsolicited offer
 * @param tokenDestination The token destination account
 * @param offer The offer account
 * @param programId The name offer program ID
 * @returns
 */
export const cancelOffer = async (
  owner: PublicKey,
  tokenDestination: PublicKey,
  offer: PublicKey,
  programId: PublicKey
) => {
  const [escrow] = await PublicKey.findProgramAddress(
    [offer.toBuffer()],
    programId
  );
  const ix = new cancelOfferInstruction().getInstruction(
    programId,
    owner,
    tokenDestination,
    escrow,
    offer,
    TOKEN_PROGRAM_ID
  );
  return [ix];
};

/**
 * This function can be used to accept an unsolicited offer
 * @param connection The Solana RPC connection object
 * @param programId The name offer program ID
 * @param offerAccount The offer account
 * @param offerOwner The offer owner account
 * @param offerBeneficiary The offer owner beneficialiciary i.e the person accepting the offer
 * @param nameAccount The domain name on which the unsolicited offer is made
 * @param escrowTokenAccount The escrow token account
 * @param destinationTokenAccount The destination token account
 * @returns
 */
export const acceptOffer = async (
  connection: Connection,
  programId: PublicKey,
  offerAccount: PublicKey,
  offerOwner: PublicKey,
  offerBeneficiary: PublicKey,
  nameAccount: PublicKey,
  escrowTokenAccount: PublicKey,
  destinationTokenAccount: PublicKey
) => {
  const ix: TransactionInstruction[] = [];
  const offer = await Offer.retrieve(connection, offerAccount);
  const feeAta = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    offer.quoteMint,
    FEE_OWNER
  );
  if (!(await connection.getAccountInfo(feeAta))?.data) {
    const ix_create_ata = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      offer.quoteMint,
      feeAta,
      FEE_OWNER,
      offerOwner
    );
    ix.push(ix_create_ata);
  }
  const ix_accept = new acceptOfferInstruction().getInstruction(
    programId,
    offerAccount,
    offerBeneficiary,
    nameAccount,
    escrowTokenAccount,
    destinationTokenAccount,
    feeAta,
    TOKEN_PROGRAM_ID,
    NAME_PROGRAM_ID
  );
  ix.push(ix_accept);

  return ix;
};

/**
 * This function can be used to register a domain name as favorite
 * @param nameAccount The name account being registered as favorite
 * @param owner The owner of the name account
 * @param programId The name offer program ID
 * @returns
 */
export const registerFavourite = async (
  nameAccount: PublicKey,
  owner: PublicKey,
  programId: PublicKey
) => {
  const [favKey] = await FavouriteDomain.getKey(programId, owner);
  const ix = new registerFavouriteInstruction().getInstruction(
    programId,
    nameAccount,
    favKey,
    owner,
    SystemProgram.programId
  );
  return [ix];
};

/**
 * This function can be used to create a fixed price offer
 * @param connection The Solana RPC connection object
 * @param amount The amount of the fixed price offer
 * @param quoteMint The quote token mint of the fixed price offer
 * @param seller The seller i.e creator of the fixed price offer
 * @param nameAccount The name account being sold
 * @param programId The name offer program ID
 * @returns
 */
export const makeFixedPriceOffer = async (
  connection: Connection,
  amount: number,
  quoteMint: PublicKey,
  seller: PublicKey,
  nameAccount: PublicKey,
  programId: PublicKey
) => {
  const ix: TransactionInstruction[] = [];

  const tokenDestination = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    quoteMint,
    seller
  );

  if (!(await connection.getAccountInfo(tokenDestination))?.data) {
    const ix_create_ata = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      quoteMint,
      tokenDestination,
      seller,
      seller
    );
    ix.push(ix_create_ata);
  }

  const [fixedKey] = await FixedPriceOffer.getKey(
    programId,
    seller,
    quoteMint,
    nameAccount
  );
  const ix_offer = new makeFixedPriceInstruction({
    amount: new BN(amount),
    quoteMint: quoteMint.toBuffer(),
  }).getInstruction(
    programId,
    fixedKey,
    seller,
    nameAccount,
    tokenDestination,
    NAME_PROGRAM_ID,
    SystemProgram.programId
  );
  ix.push(ix_offer);

  return ix;
};

/**
 * This function can be used to cancel a fixed price offer
 * @param connection The Solana RPC connection object
 * @param fixedPriceOffer The fixed price offer key
 * @param programId The name offer program ID
 * @returns
 */
export const cancelFixedPriceOffer = async (
  connection: Connection,
  fixedPriceOffer: PublicKey,
  programId: PublicKey
) => {
  const offer = await FixedPriceOffer.retrieve(connection, fixedPriceOffer);
  const ix = new cancelFixedPriceInstruction().getInstruction(
    programId,
    fixedPriceOffer,
    offer.owner,
    offer.nameAccount,
    NAME_PROGRAM_ID,
    SystemProgram.programId
  );

  return [ix];
};

/**
 * This function can be used to buy a fixed a price offer
 * @param connection The Solana RPC connection object
 * @param fixedPriceOffer The fixed price offer being bought
 * @param buyer The buyer of the fixed price offer
 * @param tokenSource The token account used to buy the fixed price offer
 * @param programId The name offer program ID
 * @returns
 */
export const buyFixedPrice = async (
  connection: Connection,
  fixedPriceOffer: PublicKey,
  buyer: PublicKey,
  tokenSource: PublicKey,
  programId: PublicKey
) => {
  const ix: TransactionInstruction[] = [];
  const offer = await FixedPriceOffer.retrieve(connection, fixedPriceOffer);

  const feeAta = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    offer.quoteMint,
    FEE_OWNER
  );
  if (!(await connection.getAccountInfo(feeAta))?.data) {
    const ix_create_ata = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      offer.quoteMint,
      feeAta,
      FEE_OWNER,
      buyer
    );
    ix.push(ix_create_ata);
  }

  const ix_buy = new buyFixedPriceInstruction().getInstruction(
    programId,
    fixedPriceOffer,
    buyer,
    offer.nameAccount,
    offer.tokenDestination,
    tokenSource,
    feeAta,
    TOKEN_PROGRAM_ID,
    NAME_PROGRAM_ID
  );
  ix.push(ix_buy);

  return ix;
};
