"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParsedAccountStreamUpdateRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class ParsedAccountStreamUpdateRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "PUT";
    url = "/v0/accounts/parsed/update";
    constructor(body = {}) {
        super(body);
        this.body = body;
    }
}
exports.ParsedAccountStreamUpdateRequest = ParsedAccountStreamUpdateRequest;
