"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelioEventStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    blockId: { type: "NUMERIC" },
    blockTime: { type: "DATE" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    instructionOrdinal: { type: "NUMERIC" },
    instructionName: { type: "TEXT" },
    eventType: { type: "ENUM" },
    "helioNftEvent.owner": { type: "TEXT" },
    "helioNftEvent.ownerNftAccount": { type: "TEXT" },
    "helioNftEvent.escrowAccount": { type: "TEXT" },
    "helioNftEvent.escrowNftAccount": { type: "TEXT" },
    "helioNftEvent.escrowPda": { type: "TEXT" },
    "helioNftEvent.mint": { type: "TEXT" },
    "helioNftEvent.currency": { type: "TEXT" },
    "helioNftEvent.sender": { type: "TEXT" },
    "helioNftEvent.senderTokenAccount": { type: "TEXT" },
    "helioNftEvent.senderNftAccount": { type: "TEXT" },
    "helioNftEvent.recipient": { type: "TEXT" },
    "helioNftEvent.recipientTokenAccount": { type: "TEXT" },
    "helioNftEvent.helioFeeTokenAccount": { type: "TEXT" },
    "helioNftEvent.daoFeeTokenAccount": { type: "TEXT" },
    "helioNftEvent.helioFeeAccount": { type: "TEXT" },
    "helioNftEvent.daoFeeAccount": { type: "TEXT" },
    "helioNftEvent.unitPrice": { type: "NUMERIC" },
    "helioNftEvent.fee": { type: "NUMERIC" },
    "helioNftEvent.amount": { type: "NUMERIC" },
    "helioNftEvent.helioFeeAmount": { type: "NUMERIC" },
    "helioNftEvent.daoFeeAmount": { type: "NUMERIC" },
};
class HelioEventStream extends base_data_stream_class_1.BaseDataStream {
    id = "HelioEvent";
    static fieldMap = fieldMap;
}
exports.HelioEventStream = HelioEventStream;
