"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LpBalanceChangeStream = void 0;
const base_data_stream_class_1 = require("../models/base-data-stream.class");
const fieldMap = {
    blockId: { type: "NUMERIC" },
    blockTime: { type: "DATE" },
    transactionId: { type: "TEXT" },
    transactionPosition: { type: "NUMERIC" },
    programName: { type: "TEXT" },
    poolAddress: { type: "TEXT" },
    lpTokenMint: { type: "TEXT" },
    tokenMint: { type: "COMPOSITE", dataType: "TEXT", fields: ["mintTokenA", "mintTokenB"] },
    tokenAmount: { type: "COMPOSITE", dataType: "TEXT", fields: ["amountTokenA", "amountTokenB"] },
};
class LpBalanceChangeStream extends base_data_stream_class_1.BaseDataStream {
    id = "LpBalanceChange";
    static fieldMap = fieldMap;
}
exports.LpBalanceChangeStream = LpBalanceChangeStream;
