"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenPriceBatchedRequest = void 0;
const BaseRequest_class_1 = require("../BaseRequest.class");
class TokenPriceBatchedRequest extends BaseRequest_class_1.BaseRequest {
    body;
    method = "POST";
    url = "/v0/token/price/batched";
    constructor(body) {
        super(body);
        this.body = body;
    }
}
exports.TokenPriceBatchedRequest = TokenPriceBatchedRequest;
