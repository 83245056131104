{
    "name": "Solana Token List",
    "logoURI": "https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png",
    "keywords": [
        "solana",
        "spl"
    ],
    "tags": {
        "stablecoin": {
            "name": "stablecoin",
            "description": "Tokens that are fixed to an external asset, e.g. the US dollar"
        },
        "ethereum": {
            "name": "ethereum",
            "description": "Asset bridged from ethereum"
        },
        "lp-token": {
            "name": "lp-token",
            "description": "Asset representing liquidity provider token"
        },
        "wrapped-sollet": {
            "name": "wrapped-sollet",
            "description": "Asset wrapped using sollet bridge"
        },
        "wrapped": {
            "name": "wrapped",
            "description": "Asset wrapped using wormhole bridge"
        },
        "leveraged": {
            "name": "leveraged",
            "description": "Leveraged asset"
        },
        "bull": {
            "name": "bull",
            "description": "Leveraged Bull asset"
        },
        "bear": {
            "name": "bear",
            "description": "Leveraged Bear asset"
        },
        "nft": {
            "name": "nft",
            "description": "Non-fungible token"
        },
        "security-token": {
            "name": "security-token",
            "description": "Tokens that are used to gain access to an electronically restricted resource"
        },
        "utility-token": {
            "name": "utility-token",
            "description": "Tokens that are designed to be spent within a certain blockchain ecosystem e.g. most of the SPL-Tokens"
        },
        "tokenized-stock": {
            "name": "tokenized-stock",
            "description": "Tokenized stocks are tokenized derivatives that represent traditional securities, particularly shares in publicly firms traded on regulated exchanges"
        }
    },
    "timestamp": "2021-03-03T19:57:21+0000",
    "tokens": [
        {
            "chainId": 101,
            "address": "3SghkPdBSrpF9bzdAy5LwR4nGgFbqNcC6ZSq8vtZdj91",
            "symbol": "EV1",
            "name": "EveryOne Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3SghkPdBSrpF9bzdAy5LwR4nGgFbqNcC6ZSq8vtZdj91/logo.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "facebook": "https://facebook.com/everyonecoin",
                "twitter": "https://twitter.com/everyonecoin",
                "website": "https://everyonecoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "NGK3iHqqQkyRZUj4uhJDQqEyKKcZ7mdawWpqwMffM3s",
            "symbol": "YAKU",
            "name": "Yaku",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EK58dp4mxsKwwuySWQW826i3fwcvUK69jPph22VUcd2H/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CbNYA9n3927uXUukee2Hf4tm3xxkffJPPZvGazc2EAH1",
            "symbol": "agEUR",
            "name": "agEUR (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CbNYA9n3927uXUukee2Hf4tm3xxkffJPPZvGazc2EAH1/logo.png",
            "tags": [
                "ethereum",
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8",
                "assetContract": "https://etherscan.io/address/0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "ageur",
                "description": "Angle is the first decentralized, capital efficient and over-collateralized stablecoin protocol",
                "discord": "https://discord.gg/z3kCpTaKMh",
                "twitter": "https://twitter.com/AngleProtocol",
                "website": "https://www.angle.money"
            }
        },
        {
            "chainId": 101,
            "address": "31GpPxe1SW8pn7GXimM73paD8PZyCsmVSGTLkwUAJvZ8",
            "symbol": "ANGLE",
            "name": "ANGLE (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/31GpPxe1SW8pn7GXimM73paD8PZyCsmVSGTLkwUAJvZ8/logo.svg",
            "tags": [
                "ethereum",
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x31429d1856ad1377a8a0079410b297e1a9e214c2",
                "assetContract": "https://etherscan.io/address/0x31429d1856ad1377a8a0079410b297e1a9e214c2",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "angle-protocol",
                "description": "Angle is the first decentralized, capital efficient and over-collateralized stablecoin protocol",
                "discord": "https://discord.gg/z3kCpTaKMh",
                "twitter": "https://twitter.com/AngleProtocol",
                "website": "https://www.angle.money"
            }
        },
        {
            "chainId": 101,
            "address": "G6nZYEvhwFxxnp1KZr1v9igXtipuB5zL6oDGNMRZqF3q",
            "symbol": "BAD",
            "name": "EA Bad",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G6nZYEvhwFxxnp1KZr1v9igXtipuB5zL6oDGNMRZqF3q/EABadlogo.PNG",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EABadtoken"
            }
        },
        {
            "chainId": 101,
            "address": "Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A",
            "symbol": "MBS",
            "name": "MonkeyBucks",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "coingeckoId": "monkeyball",
                "description": "MonkeyLeague is the next-gen esports metaverse empowering players to create, play, compete, and earn.",
                "discord": "https://discord.gg/monkeyleague",
                "facebook": "https://www.facebook.com/TheMonkeyLeague",
                "instagram": "https://www.instagram.com/themonkeyleague/",
                "medium": "https://medium.com/@MonkeyLeague",
                "reddit": "https://www.reddit.com/r/MonkeyBallGame",
                "telegram": "https://t.me/MonkeyLeague_Official",
                "twitter": "https://twitter.com/TheMonkeyLeague",
                "website": "https://www.monkeyleague.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FCuoMii64H5Ee4eVWBjP18WTFS8iLUJmGi16Qti1xFQ2",
            "symbol": "EDB",
            "name": "Emerald Block",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SBPToken/token-list/main/assets/mainnet/FCuoMii64H5Ee4eVWBjP18WTFS8iLUJmGi16Qti1xFQ2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kzPBdWtbrK",
                "instagram": "https://instagram.com/solblockpunks",
                "twitter": "https://twitter.com/solblockpunks",
                "website": "https://solblockpunks.com"
            }
        },
        {
            "chainId": 101,
            "address": "HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo",
            "symbol": "CWAR",
            "name": "Cryowar Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/cryowar",
                "telegram": "https://t.me/cryowar",
                "twitter": "https://twitter.com/CryowarDevs",
                "website": "https://cryowar.com"
            }
        },
        {
            "chainId": 101,
            "address": "BqRtfrNpvRAW3KW319hvhPoTu76wKU2LTdXJyG9CyDze",
            "symbol": "ECHO",
            "name": "EchoDao",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/HnnxQND.png",
            "tags": [
                "utility-token",
                "DAO-fork"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/NtvVPs4WnY",
                "serumV3Usdc": "8bjQ8XvzrDxKxHhTccpLkqGLbBrCAAPuv6KHrgN95nDW",
                "twitter": "https://twitter.com/echodao_sol",
                "website": "https://echodao.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "HCXXtXPasqcF4BVsrPQPfHMQPUofoCbDbjsTUANFSHDR",
            "symbol": "MONKE",
            "name": "MONKE TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCXXtXPasqcF4BVsrPQPfHMQPUofoCbDbjsTUANFSHDR/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://monketoken.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "7gfwZiMD3B6hJWxejr4E3PdsfyVndfuGuk97LfDhJNNh",
            "symbol": "DIBU",
            "name": "DickButt",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/xRoBBeRT/DickButtLogo/DickButt.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/solanadickbutt",
                "twitter": "https://twitter.com/SolanaDickButt",
                "website": "https://solanadickbutt.com"
            }
        },
        {
            "chainId": 101,
            "address": "6JdcMdhqgCtcP4U9tieRqmKLhPLxRMLC67QfmdXAJBvZ",
            "symbol": "KITTY",
            "name": "KITTY SOLANA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JdcMdhqgCtcP4U9tieRqmKLhPLxRMLC67QfmdXAJBvZ/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "coingeckoId": "kitty-solana",
                "coinmarketcap": "https://coinmarketcap.com/currencies/kitty-solana/",
                "discord": "https://discord.gg/XaRfbtsscz",
                "serumV3Usdc": "8bjQ8XvzrDxKxHhTccpLkqGLbBrCAAPuv6KHrgN95nDW",
                "twitter": "https://twitter.com/kitty_solana",
                "website": "https://kittysolana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HDiA4quoMibAGeJQzvxajp3Z9cvnkNng99oVrnuNj6px",
            "symbol": "KSAMO",
            "name": "KING SAMO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDiA4quoMibAGeJQzvxajp3Z9cvnkNng99oVrnuNj6px/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/R8QhkNHn",
                "telegram": "https://t.me/TokenKingSamo",
                "twitter": "https://twitter.com/kingsamocoin",
                "website": "https://kingsamo.com"
            }
        },
        {
            "chainId": 101,
            "address": "73YQDsoPB3t5n5GqX53tKrwJK1n6HCZ935MEbo2gEYU5",
            "symbol": "KAJAME",
            "name": "Kajame",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/73YQDsoPB3t5n5GqX53tKrwJK1n6HCZ935MEbo2gEYU5/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "blog": "https://blog.me-idea.in.th",
                "website": "https://kajame.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "DJKX1cX2SPPaTdYBeuriUeQUUEpi2UGBGGPQthNMrgaa",
            "symbol": "MONKE",
            "name": "MONKE TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJKX1cX2SPPaTdYBeuriUeQUUEpi2UGBGGPQthNMrgaa/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://monketoken.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "6TgvYd7eApfcZ7K5Mur7MaUQ2xT7THB4cLHWuMkQdU5Z",
            "symbol": "OTR",
            "name": "Otter Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TgvYd7eApfcZ7K5Mur7MaUQ2xT7THB4cLHWuMkQdU5Z.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/chfgc9wxnw",
                "serumV3Usdc": "4k4WXdmrWjCG71E4pxMs6SQRRB5cypGNYatKb2iMnqN4",
                "twitter": "https://twitter.com/otter_finance",
                "website": "http://www.otterfinance.site"
            }
        },
        {
            "chainId": 101,
            "address": "382HfaEjcUNhwoGbYmL58DVX8GUvjrXiTPchCWKjchWA",
            "symbol": "SPLN",
            "name": "SHEEPLANA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/382HfaEjcUNhwoGbYmL58DVX8GUvjrXiTPchCWKjchWA.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zV9dyHfxGb",
                "twitter": "https://twitter.com/sheeplana_coin",
                "website": "https://www.sheeplana.online/"
            }
        },
        {
            "chainId": 101,
            "address": "EH49ziLeKhJtzUzdys5238pSKpvrgJvmi3EStrZ9QaY7",
            "symbol": "RAC",
            "name": "Rainc",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EH49ziLeKhJtzUzdys5238pSKpvrgJvmi3EStrZ9QaY7/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.io/rainc",
                "serumV3Usdc": "21eq7jsx59jPFo2gt4npreKu1voURzcjyPniBdgupuQC",
                "twitter": "https://twitter.com/raincproject",
                "website": "https://www.raincsol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2mDJPcvv7vigZo9ZPxhHLpKQSixCkbohVY35eX6NkN6m",
            "symbol": "TBK",
            "name": "TokenBook",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mDJPcvv7vigZo9ZPxhHLpKQSixCkbohVY35eX6NkN6m/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "serumV3Usdc": "DoL5SXaax9LwQM9JfqFBymiUfSxH9A9cwPugPuHvNTDM",
                "twitter": "https://twitter.com/TokenBook_tbk"
            }
        },
        {
            "chainId": 101,
            "address": "DcvJP16Cw5oqTbtHmpJ4JGXaqBvV5m6eMZj5rGsFLpwU",
            "symbol": "BOOGI",
            "name": "BABY OOGI",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcvJP16Cw5oqTbtHmpJ4JGXaqBvV5m6eMZj5rGsFLpwU/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/babyoogi_",
                "website": "https://babyoogi.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "48iGP5MUTZ8DCfDvZ9dpgKySP2iekQ3zPKZM8AhDjEmw",
            "symbol": "VIRAL",
            "name": "Viraverse",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/48iGP5MUTZ8DCfDvZ9dpgKySP2iekQ3zPKZM8AhDjEmw/logo.png",
            "tags": [
                "stake-pool-token",
                "utility-token",
                "security-token",
                "community-token",
                "viraverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/EwVdMYvEgV",
                "telegram": "https://t.me/viraverseio",
                "twitter": "https://twitter.com/viraverseio",
                "website": "https://viraverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "2WnVfjtW9QttRwqxn3RPnHBFHMR3cyA5Ca3zug41Q9Xb",
            "symbol": "HNI",
            "name": "Golden Techie Hannibal Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2WnVfjtW9QttRwqxn3RPnHBFHMR3cyA5Ca3zug41Q9Xb/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://crpanadasoft.com"
            }
        },
        {
            "chainId": 101,
            "address": "CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9",
            "symbol": "VINU",
            "name": "Viral Inu",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9/logo.png",
            "tags": [
                "stake-pool-token",
                "utility-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zpJ7zADRZ5",
                "telegram": "https://t.me/viralinu",
                "twitter": "https://twitter.com/ViralInu",
                "website": "https://viralinu.com"
            }
        },
        {
            "chainId": 101,
            "address": "6bLp99VoqKU1C3Qp6VTNvSoCoc78jMGxPkGSSopq8wHB",
            "symbol": "Paws",
            "name": "Solana Paws",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6bLp99VoqKU1C3Qp6VTNvSoCoc78jMGxPkGSSopq8wHB/logo.png",
            "tags": [
                "meme-token",
                "community",
                "doge",
                "Paws"
            ],
            "extensions": {
                "discord": "https://discord.gg/sVP35wfPhX",
                "twitter": "https://twitter.com/Sol_Paws",
                "website": "https://www.solanapaws.com"
            }
        },
        {
            "chainId": 101,
            "address": "GGupQCMnyEmHKcqFu72qCTm6yEYpVyhouY9dSAMEXLsC",
            "symbol": "DOGEC",
            "name": "Dogecoin Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGupQCMnyEmHKcqFu72qCTm6yEYpVyhouY9dSAMEXLsC/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "doge",
                "dogecoin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dogec_cash",
                "website": "https://www.dogecoincash.info"
            }
        },
        {
            "chainId": 101,
            "address": "2d9LcdAQCnxPHSca6frjQzYKapNzB7caSuLKpeWBctvT",
            "symbol": "Taboo",
            "name": "TABOO TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2d9LcdAQCnxPHSca6frjQzYKapNzB7caSuLKpeWBctvT/logo.png",
            "tags": [
                "community-token",
                "Taboo-token",
                "Taboo",
                "Videos"
            ]
        },
        {
            "chainId": 101,
            "address": "CrhUSH7FDwB37BYvPsVnVbsGVeE81biBzfkD4A4fyJMv",
            "symbol": "Vikings",
            "name": "Viking Legend",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CrhUSH7FDwB37BYvPsVnVbsGVeE81biBzfkD4A4fyJMv/logo.png",
            "tags": [
                "community-token",
                "viking-token",
                "floki",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "3BYQt5MtdUSDkGwPa7F5pxFNx6csyUK2zAqNgoAsQ96h",
            "symbol": "VIKINGxFLOKI",
            "name": "VIKINGxFLOKI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3BYQt5MtdUSDkGwPa7F5pxFNx6csyUK2zAqNgoAsQ96h/logo.png",
            "tags": [
                "community-token",
                "viking-token",
                "viking-floki",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "zWxLppNEHinqYbQffzp2T5yNXUzyQUsHZ39nxjTqk6F",
            "symbol": "METAS",
            "name": "Meta Syndrome",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zWxLppNEHinqYbQffzp2T5yNXUzyQUsHZ39nxjTqk6F/logo.png",
            "tags": [
                "community-token",
                "meta-token",
                "meta-mark",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "8sMa1Jfcpt2eSkKDtcd6rurX27gqxkrEvXn5jHt3suGB",
            "symbol": "DGMOON",
            "name": "DogeMoonxSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8sMa1Jfcpt2eSkKDtcd6rurX27gqxkrEvXn5jHt3suGB/logo.png",
            "tags": [
                "community-token",
                "doge-token",
                "doge",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "8g9kLFgtHF4kMVjGbpnPNUU8QbxMHpLZTKhAJyvwr9on",
            "symbol": "MCAT",
            "name": "Meta Cat",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8g9kLFgtHF4kMVjGbpnPNUU8QbxMHpLZTKhAJyvwr9on/logo.png",
            "tags": [
                "community-token",
                "cat-token",
                "cat",
                "nfts",
                "meta",
                "Facebook"
            ]
        },
        {
            "chainId": 101,
            "address": "H5TA9LexsmmvLM49zdEkbaPCcHJed8TTFtRqny81tEaK",
            "symbol": "xVideos",
            "name": "xVideo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H5TA9LexsmmvLM49zdEkbaPCcHJed8TTFtRqny81tEaK/logo.png",
            "tags": [
                "community-token",
                "platform",
                "videos",
                "payment"
            ]
        },
        {
            "chainId": 101,
            "address": "roCKojKezC7HhPxph5qb4UBasvmZJWgegCF57PvaV2f",
            "symbol": "ROCK",
            "name": "RockDeFi",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/roCKojKezC7HhPxph5qb4UBasvmZJWgegCF57PvaV2f/logo.png",
            "tags": [
                "stablecoin",
                "asset"
            ],
            "extensions": {
                "website": "https://rockdefi.app/"
            }
        },
        {
            "chainId": 101,
            "address": "FeGm2DB4EWHm2LS8ABnRatzARDRYFyUPkLsSJkJwBuSu",
            "symbol": "FKM",
            "name": "FLOKIMUSK (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGm2DB4EWHm2LS8ABnRatzARDRYFyUPkLsSJkJwBuSu/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc999f49bb48179d5df09402a4a7a4034bc039f81",
                "assetContract": "https://bscscan.com/address/0xc999f49bb48179d5df09402a4a7a4034bc039f81",
                "bridgeContract": "https://bscscan.com/address/0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7",
                "twitter": "https://twitter.com/flokimuskmeme",
                "website": "https://flokimuskweb.com"
            }
        },
        {
            "chainId": 101,
            "address": "usdrQqxAGgWsBRzzcckAi9ZAzHp19rFCNn87p4Q8Eir",
            "symbol": "USDR",
            "name": "RockDeFi Stablecoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/usdrQqxAGgWsBRzzcckAi9ZAzHp19rFCNn87p4Q8Eir/logo.png",
            "tags": [
                "stablecoin",
                "asset"
            ],
            "extensions": {
                "website": "https://rockdefi.app/"
            }
        },
        {
            "chainId": 101,
            "address": "9cU8yLEAidMNVGEq6QHPe2ktN7SV2qqvLABth8YiSwYx",
            "symbol": "PARM",
            "name": "Parm Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9cU8yLEAidMNVGEq6QHPe2ktN7SV2qqvLABth8YiSwYx/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theeggplantNFT",
                "website": "https://www.eggplantparty.com"
            }
        },
        {
            "chainId": 101,
            "address": "A9Nc6Yo9YGKsaeAb2nsQFSQpLcdotGqjEJmEQFzZeeqX",
            "symbol": "GM",
            "name": "Good Morning Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9Nc6Yo9YGKsaeAb2nsQFSQpLcdotGqjEJmEQFzZeeqX/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://goodmorning.money"
            }
        },
        {
            "chainId": 101,
            "address": "AMp8Jo18ZjK2tuQGfjKAkkWnVP4NWX5sav4NJH6pXF2D",
            "symbol": "ASTRA",
            "name": "AstraPad",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMp8Jo18ZjK2tuQGfjKAkkWnVP4NWX5sav4NJH6pXF2D/logo.png",
            "tags": [
                "Launchpad",
                "NFT"
            ],
            "extensions": {
                "coingeckoId": "astrapad",
                "telegram": "https://t.me/AstraPadANN",
                "twitter": "https://twitter.com/astrapadio",
                "website": "https://astrapad.io/"
            }
        },
        {
            "chainId": 101,
            "address": "45u9AsJtN2KkYpH6GCXtwuoDF7HwgMjTQ84xfH6SJYQy",
            "symbol": "PUT",
            "name": "Pedals Up Token",
            "decimals": 10,
            "logoURI": "https://cdn.jsdelivr.net/gh/devdutt6/PedalsUpToken/Pedals/pedals.png",
            "tags": [
                "personal",
                "development"
            ],
            "extensions": {
                "website": "https://pedalsup.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2kzNeq9Yc6rghrgSfat3cvBkmK9JiePaLv7B4r1YKGDX",
            "symbol": "FUTT",
            "name": "Futtbucks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2kzNeq9Yc6rghrgSfat3cvBkmK9JiePaLv7B4r1YKGDX/logo.png",
            "tags": [
                "community-token",
                "currency"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/futt.bucks/",
                "website": "https://futtbucks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DE3Tv7eWpXGanVQC9RW1P9RG6AHWtC8VgYS9hRRVcF93",
            "symbol": "FUTT",
            "name": "Futtbucks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2kzNeq9Yc6rghrgSfat3cvBkmK9JiePaLv7B4r1YKGDX/logo.png",
            "tags": [
                "community-token",
                "currency"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/futt.bucks/",
                "website": "https://futtbucks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6SKogZxCWY9jKsKPMT3ChJUhQxAEeB6NjVidXQK6TEdW",
            "symbol": "GDoge",
            "name": "Golden Doge Solana",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6SKogZxCWY9jKsKPMT3ChJUhQxAEeB6NjVidXQK6TEdW/Logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "doge",
                "dogecoin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GoldSolDoge",
                "website": "https://http://www.goldsoldoge.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FaiPGacTM7YBmacumbg4ZnDx7sKtGcG3LkcVoqfddEA7",
            "symbol": "BULL",
            "name": "theBULL Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FaiPGacTM7YBmacumbg4ZnDx7sKtGcG3LkcVoqfddEA7/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/B6sd88UVmD",
                "twitter": "https://twitter.com/theBULL_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "2XSuy8RSESbtYRBbVHxGWuoikn3B6iXKVKzN4i3owTCf",
            "symbol": "BSAMO",
            "name": "BUFF SAMO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2XSuy8RSESbtYRBbVHxGWuoikn3B6iXKVKzN4i3owTCf/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "buff-samo",
                "coinmarketcap": "https://coinmarketcap.com/currencies/buff-samo/",
                "discord": "https://discord.link/BuffSamo",
                "twitter": "https://twitter.com/buffsamo",
                "website": "http://buffsamo.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "DNmxHPgeVLSofyAriirHybKoNx1baM2ufiHKs1W7YyPc",
            "symbol": "RPN",
            "name": "RoyalPangolins",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DNmxHPgeVLSofyAriirHybKoNx1baM2ufiHKs1W7YyPc/logo.png",
            "extensions": {
                "discord": "https://discord.gg/XvjxsRzK",
                "twitter": "https://twitter.com/RoyalPangolins",
                "website": "https://royalpangolins.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FdnEZ71hjabwo6Eo6XHGyK4QrE1tVQtBoTGMmgWYAuDn",
            "symbol": "GDoge",
            "name": "Gold Doge Solana",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdnEZ71hjabwo6Eo6XHGyK4QrE1tVQtBoTGMmgWYAuDn/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "doge",
                "dogecoin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GoldSolDoge",
                "website": "https://http://www.goldsoldoge.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4eG64sB6SpvXve4WoRAN956UFKoETLP4JDyMU51TMdep",
            "symbol": "WZWT",
            "name": "WIZ WIT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4eG64sB6SpvXve4WoRAN956UFKoETLP4JDyMU51TMdep/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "description": "The world's 1st cheesesteak-based currency, brought to you by the City of Brotherly Love (Philly!!!).",
                "discord": "https://discord.gg/ptYZPtxZf4",
                "twitter": "https://twitter.com/phillytoken",
                "website": "https://phillytoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "Bjgh4YsLdicr8WArz9ftdSmpWNcQjsZ9KV3w9fkjiLG",
            "symbol": "SOLRC",
            "name": "SolRaca",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Solraca/token-list/main/assets/mainnet/Bjgh4YsLdicr8WArz9ftdSmpWNcQjsZ9KV3w9fkjiLG/solraca.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solracaofficial"
            }
        },
        {
            "chainId": 101,
            "address": "45HfvXJHY9msY2i4EmUpume1mSMLUvdaWsJRbctAobQM",
            "symbol": "INU",
            "name": "Monster Inu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/45HfvXJHY9msY2i4EmUpume1mSMLUvdaWsJRbctAobQM/logo.png",
            "tags": [
                "meme-token",
                "metaverse"
            ],
            "extensions": {
                "twitter": "https://twitter.com/inumonster",
                "website": "https://inu.monster"
            }
        },
        {
            "chainId": 101,
            "address": "FEYFyLCFLcBNfSuaf2eXNvyY5Jpii7zg9X48Br5vyenG",
            "symbol": "SUSDT",
            "name": "Stether",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FEYFyLCFLcBNfSuaf2eXNvyY5Jpii7zg9X48Br5vyenG/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://stether.io/"
            }
        },
        {
            "chainId": 102,
            "address": "8ZY7EkwN7LxifYvvrQDbpjqxkrjHUFMwWgq8fupNNvub",
            "symbol": "BIAD",
            "name": "daib test token",
            "decimals": 6,
            "logoURI": "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/02242929-14bc-4204-ac71-56d855a07078/512_daia.png?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIAT73L2G45O3KS52Y5%2F20211104%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Date=20211104T051518Z\u0026X-Amz-Expires=86400\u0026X-Amz-Signature=df1424dcb1be408faa45ef6af1f7769edf977da365822e00c34a2150167bfe58\u0026X-Amz-SignedHeaders=host\u0026response-content-disposition=filename%20%3D%22512_daia.png%22",
            "extensions": {
                "website": "https://daios.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4JEaBv49a4KdSrMduKZS3PcBCcPmPEmaY3uP7kXv6cj6",
            "symbol": "$ASS",
            "name": "Ass Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/daddydonke/gem-farm-ui/main/public/images/icon.png",
            "tags": [
                "MEMES-TOKEN",
                "NFTS",
                "DEX"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanadonkeBs",
                "website": "https://solanadonkey.business"
            }
        },
        {
            "chainId": 101,
            "address": "X71v8NH6dbLwPsn4TR1Tx38K4uWgGZ78mC599XSPJox",
            "symbol": "SNS",
            "name": "NSPACE Supporter",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/X71v8NH6dbLwPsn4TR1Tx38K4uWgGZ78mC599XSPJox/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NJjqzzXNKb",
                "twitter": "https://twitter.com/mynftspace_art",
                "website": "http://mynftspace.art/"
            }
        },
        {
            "chainId": 101,
            "address": "NpgsBSfavf5hmUeGQAbMz5pHDtXhn9ZFNRQypTr8Tfv",
            "symbol": "NSPACE",
            "name": "My NFT Space",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NpgsBSfavf5hmUeGQAbMz5pHDtXhn9ZFNRQypTr8Tfv/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NJjqzzXNKb",
                "twitter": "https://twitter.com/mynftspace_art",
                "website": "http://mynftspace.art/"
            }
        },
        {
            "chainId": 101,
            "address": "67Z7Pr4pX5iMczBox2bCgeU7Dy6SJRm2kZaMJoptstse",
            "symbol": "KOMO",
            "name": "Komondor",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/67Z7Pr4pX5iMczBox2bCgeU7Dy6SJRm2kZaMJoptstse/logo.svg",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "description": "KOMO is a community token, Dex, and Dapps on Solana.",
                "discord": "https://discord.gg/yMVPDuZKeq",
                "facebook": "https://web.facebook.com/komondor.so",
                "github": "https://github.com/komondorok",
                "instagram": "https://instagram.com/komondor.so",
                "medium": "https://komondorok.medium.com/",
                "telegram": "https://t.me/komondor_ann",
                "twitter": "https://twitter.com/komondor_so",
                "website": "https://komondor.so/"
            }
        },
        {
            "chainId": 101,
            "address": "p31qJ7LDLNRC57rU5GsXxFGBsnXheFXSsEn3avPoKDc",
            "symbol": "ART",
            "name": "Artchive-Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/p31qJ7LDLNRC57rU5GsXxFGBsnXheFXSsEn3avPoKDc/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "governance-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/artchive.nft/",
                "website": "https://artchivecoins.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD",
            "symbol": "DIO",
            "name": "Decimated",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD/logo.png",
            "tags": [
                "decimated",
                "DIO",
                "videogame",
                "utility-token",
                "virtual-currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/decimated_game",
                "website": "https://www.decimated.net"
            }
        },
        {
            "chainId": 101,
            "address": "7QTcx3LuVfPSZxVzFJcTmbpQCL35jkfoNSzx4qouX495",
            "symbol": "AKELA",
            "name": "AKELA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7QTcx3LuVfPSZxVzFJcTmbpQCL35jkfoNSzx4qouX495/logo.svg",
            "tags": [
                "Akela",
                "Meme"
            ],
            "extensions": {
                "discord": "https://discord.gg/vBHgckmR",
                "medium": "https://medium.com/@akelasolana",
                "twitter": "https://twitter.com/AkelaTOKEN",
                "website": "https://akelatoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "3EkHyexJLGCvSxzn5umbtd9N69GoT4p5pfdLTFqCNP9Y",
            "symbol": "HIPPO",
            "name": "Hippo Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EkHyexJLGCvSxzn5umbtd9N69GoT4p5pfdLTFqCNP9Y/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "hippo-coin",
                "discord": "https://discord.gg/sk3xN3sA8D",
                "serumV3Usdc": "HnLKrfMN64ccSbYCj4DxjgPYveSkFujYrnMr4cvPfL8",
                "twitter": "https://twitter.com/HipposCoin",
                "website": "https://hippofamilynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef",
            "symbol": "BLT",
            "name": "Blocto Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/blocto",
                "twitter": "https://twitter.com/BloctoApp",
                "website": "https://blocto.portto.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FoqP7aTaibT5npFKYKQQdyonL99vkW8YALNPwWepdvf5",
            "symbol": "BIP",
            "name": "BIP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoqP7aTaibT5npFKYKQQdyonL99vkW8YALNPwWepdvf5/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solanadbs",
                "serumV3Usdc": "9tr5uMYHgtJ5yG4SeqHA6kJUdzXrK6QCNGYgewQevuuS",
                "twitter": "https://twitter.com/solanadbs",
                "website": "https://www.thestarship.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "2R78szN1fZeQvSmeiUPe7Db7BFTPee5EVE2FduQx8Wtw",
            "symbol": "USDMD",
            "name": "MONDOCOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2R78szN1fZeQvSmeiUPe7Db7BFTPee5EVE2FduQx8Wtw/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/mondocoin_usdmd",
                "website": "https://mondocoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "BYLotMdQmq579hhP9xDcuCJky9kmMrbp77eoktmm7a5Y",
            "symbol": "CKG",
            "name": "CryptoKnight's Gold",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYLotMdQmq579hhP9xDcuCJky9kmMrbp77eoktmm7a5Y/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SYpnu7pTPS",
                "twitter": "https://twitter.com/CryptoKnightSOL",
                "website": "https://www.cryptoknights.me/"
            }
        },
        {
            "chainId": 101,
            "address": "F9tytWqLUAPXQTy6dejGtSgvJQZWYC71naD5bCi6caGX",
            "symbol": "INUGAMI",
            "name": "INUGAMI Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/INVESTOR888/content/main/F9tytWqLUAPXQTy6dejGtSgvJQZWYC71naD5bCi6caGX.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/inugamicoin",
                "website": "https://inugamicoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "B7RDhZ2iqE4FEwK5nfcZ9r2xhVL6rQJCo1dcjDXnF688",
            "symbol": "LAT",
            "name": "Latte",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B7RDhZ2iqE4FEwK5nfcZ9r2xhVL6rQJCo1dcjDXnF688/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/lattenft",
                "website": "https://www.lattetoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn",
            "symbol": "JSOL",
            "name": "JPOOL Solana Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn/logo.svg",
            "tags": [
                "stake-pool-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/qR4BA9QXVR",
                "telegram": "https://t.me/jpoolsolana",
                "twitter": "https://twitter.com/JPoolSolana",
                "website": "https://jpool.one/"
            }
        },
        {
            "chainId": 101,
            "address": "7JYZmXjHenJxgLUtBxgYsFfoABmWQFA1fW3tHQKUBThV",
            "symbol": "WEED",
            "name": "Solana Weed",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7JYZmXjHenJxgLUtBxgYsFfoABmWQFA1fW3tHQKUBThV/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3cshJ2gVz6",
                "telegram": "https://t.me/Solanaweed",
                "twitter": "https://twitter.com/SolanaWeed",
                "website": "https://solanaweed.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GaAzf7jwEKTouDXJExH9TKfvX3Ae7fLaGwNuEajq7KsE",
            "symbol": "BARK",
            "name": "Bark o Finance",
            "decimals": 1,
            "logoURI": "https://i.imgur.com/X90vi6d.png",
            "tags": [
                "meme-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Bark_Solana"
            }
        },
        {
            "chainId": 101,
            "address": "5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko",
            "symbol": "INU",
            "name": "Solana INU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko/logo.png",
            "tags": [
                "Meme-token"
            ],
            "extensions": {
                "coingeckoId": "solana-inu",
                "discord": "https://discord.gg/solanainu",
                "serumV3Usdc": "G3Bss3a2tif6eHNzWCh14g5k2H4rwBAmE42tbckUWG5T",
                "twitter": "https://twitter.com/solanainu",
                "website": "http://solanainu.org"
            }
        },
        {
            "chainId": 101,
            "address": "GJsBLZPMConURkFkewZskmJLFjnYVSENZtHjqV7GnohC",
            "symbol": "EMON",
            "name": "DORAEMON TOKEN FANS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJsBLZPMConURkFkewZskmJLFjnYVSENZtHjqV7GnohC/logo.png",
            "tags": [
                "MEMES-TOKEN",
                "NFTs",
                "DEX"
            ],
            "extensions": {
                "telegram": "https://t.me/emontoken",
                "twitter": "https://twitter.com/emontoken",
                "website": "https://doraemon.org"
            }
        },
        {
            "chainId": 101,
            "address": "7mNihWEjzWv9yCZc8capE4mS8v5Xvp5YH2yQhtZrQV5B",
            "symbol": "SBreakpoint",
            "name": "Solana Breakpoint",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tribeland/token-list/main/assets/mainnet/7mNihWEjzWv9yCZc8capE4mS8v5Xvp5YH2yQhtZrQV5B/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/8jSUfzjjDG"
            }
        },
        {
            "chainId": 101,
            "address": "6Km8PRUQxPmNX6EhmAuu3sFEnCP6uT2Yt42zPFR6VNnD",
            "symbol": "RUG",
            "name": "RugCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Km8PRUQxPmNX6EhmAuu3sFEnCP6uT2Yt42zPFR6VNnD/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "rug"
            ],
            "extensions": {
                "website": "https://rugcoin.rip"
            }
        },
        {
            "chainId": 101,
            "address": "JTTez7NDqtU4ZqZJmLLXt6K9f75izfTApQqmvMCn4jU",
            "symbol": "JTT",
            "name": "Japan Travel Token",
            "decimals": 0,
            "logoURI": "http://www.japantravel.me/jtticon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.japantravel.me"
            }
        },
        {
            "chainId": 101,
            "address": "7K1ad6gYMDbRssecDkGdGpaRueSezZpgD28uYsyaEA8f",
            "symbol": "SLNACK",
            "name": "SolNack",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7K1ad6gYMDbRssecDkGdGpaRueSezZpgD28uYsyaEA8f/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Solnack_NFT",
                "website": "https://www.solnack.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8hK6Vq53gwwYmvM2HuEeycGFn6ZDfynccHSuNJhWrTyd",
            "symbol": "1Coin",
            "name": "1Coin",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8hK6Vq53gwwYmvM2HuEeycGFn6ZDfynccHSuNJhWrTyd/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "github": "https://github.com/1coin1",
                "twitter": "https://twitter.com/1coincx",
                "website": "https://1coin.cx"
            }
        },
        {
            "chainId": 101,
            "address": "3SaUThdYFoUX2FYUi9ZPf2TKTu3UYKhNHhXb2Y6najRg",
            "symbol": "HIT",
            "name": "Hello Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3SaUThdYFoUX2FYUi9ZPf2TKTu3UYKhNHhXb2Y6najRg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Hello-Entertainment-103715111864185",
                "website": "https://app.gethello.net"
            }
        },
        {
            "chainId": 101,
            "address": "9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6",
            "symbol": "WIPE",
            "name": "WipeMyAss",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/rxrxrxrx/WipeMyAss/wipemyass.jpg",
            "tags": [
                "community-token",
                "meme-token",
                "doge",
                "dogecoin",
                "solcum",
                "monkey",
                "woof",
                "soldoge",
                "samo",
                "smb"
            ],
            "extensions": {
                "coingeckoId": "wipemyass",
                "serumV3Usdc": "3kuUc5eTZyi7qajuFfDMMUUkqreEkUKtxQbVCjdriKVz",
                "twitter": "https://twitter.com/WipeMyAssNFT",
                "website": "https://wipemyass.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FTkj421DxbS1wajE74J34BJ5a1o9ccA97PkK6mYq9hNQ",
            "symbol": "MINECRAFT",
            "name": "Synex Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTkj421DxbS1wajE74J34BJ5a1o9ccA97PkK6mYq9hNQ/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/N3BE44234A",
                "telegram": "https://t.me/synexcoin",
                "website": "https://synexcoin.dev"
            }
        },
        {
            "chainId": 101,
            "address": "EkDf4Nt89x4Usnxkj4sGHX7sWxkmmpiBzA4qdDkgEN6b",
            "symbol": "SOB",
            "name": "SolaLambo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EkDf4Nt89x4Usnxkj4sGHX7sWxkmmpiBzA4qdDkgEN6b/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sy2xymyc7J",
                "twitter": "https://twitter.com/SolaLambo",
                "website": "https://sob.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Hj4sTP4L4rvR9WBR6KyK99sxPptBQQczNWe4y15mxhRD",
            "symbol": "SNJ",
            "name": "Sola Ninja",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolaToken/token-list/ninja/assets/mainnet/Hj4sTP4L4rvR9WBR6KyK99sxPptBQQczNWe4y15mxhRD/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "serumV3Usdc": "FyVdsZs15c38b6vBjiYYfq9fPrcrZJjvMgjHtEB2T9Sa",
                "telegram": "https://t.me/solatokennet",
                "twitter": "https://twitter.com/EcoSolana",
                "website": "https://solatoken.net/"
            }
        },
        {
            "chainId": 101,
            "address": "9YdABeMBMjh5Pu8yPkJ9GETGWUT8KUK3B2RYBpjKNPsU",
            "symbol": "GTA",
            "name": "GTA LIVE",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9YdABeMBMjh5Pu8yPkJ9GETGWUT8KUK3B2RYBpjKNPsU/logo.svg",
            "tags": [
                "game-fi",
                "utility-token",
                "meta-verse"
            ],
            "extensions": {
                "discord": "https://discord.gta.live",
                "medium": "https://gtanft.medium.com/",
                "reddit": "https://reddit.com/user/gtanft",
                "telegram": "https://t.me/gtanft",
                "twitter": "https://twitter.com/gtanft",
                "website": "https://gta.live"
            }
        },
        {
            "chainId": 101,
            "address": "GqWbZDQaeJsiscgtGpDrJsNCxxeuHqJCGKs4oWBY1aYQ",
            "symbol": "GTA",
            "name": "GTA LIVE",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GqWbZDQaeJsiscgtGpDrJsNCxxeuHqJCGKs4oWBY1aYQ/logo.png",
            "tags": [
                "GameFi",
                "DeFi",
                "MetaVerse",
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gta.live",
                "telegram": "https://t.me/gta_fiverp",
                "website": "https://gta.live"
            }
        },
        {
            "chainId": 101,
            "address": "6H87YFkp5LHyN3KzDXa5r3QYce7WTcwYJi9SqwH3TXkQ",
            "symbol": "AINU",
            "name": "Akamaru Inu",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6H87YFkp5LHyN3KzDXa5r3QYce7WTcwYJi9SqwH3TXkQ/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/akamaruinusol",
                "website": "https://akamaruinusol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BZopZtZHqUY7ApiYTLjztQSgBoAsqQsJU3kFqHW27qEK",
            "symbol": "NICK",
            "name": "Nick Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZopZtZHqUY7ApiYTLjztQSgBoAsqQsJU3kFqHW27qEK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nickchua5"
            }
        },
        {
            "chainId": 101,
            "address": "6Vg88xUHUPU9MfddHpu2cgx6CdodReiU8eGLPJgyhyVZ",
            "symbol": "WLB",
            "name": "WHALEBE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Vg88xUHUPU9MfddHpu2cgx6CdodReiU8eGLPJgyhyVZ/logo.png",
            "tags": [
                "token-games",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Whalebe_",
                "website": "https://whalebe.art/"
            }
        },
        {
            "chainId": 101,
            "address": "9SLCSSkEYL9YbKtAvw39xNzMEV4a7oLisGXhSJt73UCu",
            "symbol": "KEVIN",
            "name": "Kevin Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9SLCSSkEYL9YbKtAvw39xNzMEV4a7oLisGXhSJt73UCu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kevarifin"
            }
        },
        {
            "chainId": 101,
            "address": "AeTzmX6QcL6tWKMRTKVW9ee8fPmCieKzgfmiU7ZaY4gu",
            "symbol": "SHIHT",
            "name": "Shih Tzu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AeTzmX6QcL6tWKMRTKVW9ee8fPmCieKzgfmiU7ZaY4gu/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ShihTzuSolana",
                "website": "https://shihtcoin.io"
            }
        },
        {
            "chainId": 101,
            "address": "AATiVPgFBTJejUJrmkwnwH8UTr69CtfodGVCwMvrCa2U",
            "symbol": "SOLM",
            "name": "Solmanians",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AATiVPgFBTJejUJrmkwnwH8UTr69CtfodGVCwMvrCa2U/logo.png",
            "tags": [
                "meme-token",
                "nft-token",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solmanians",
                "website": "https://solmanians.com"
            }
        },
        {
            "chainId": 101,
            "address": "ALMmmmbt5KNrPPUBFE4dAKUKSPWTop5s3kUGCdF69gmw",
            "symbol": "ALM",
            "name": "Almond",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALMmmmbt5KNrPPUBFE4dAKUKSPWTop5s3kUGCdF69gmw/logo.png",
            "extensions": {
                "coingeckoId": "almond",
                "coinmarketcap": "https://coinmarketcap.com/currencies/almond/",
                "discord": "https://discord.gg/MBWsChEdFw",
                "serumV3Usdc": "DNxn3qM61GZddidjrzc95398SCWhm5BUyt8Y8SdKYr8W",
                "twitter": "https://twitter.com/almond_so",
                "website": "https://almond.so/"
            }
        },
        {
            "chainId": 101,
            "address": "BSA5MfNRWc1CTJE1FD5ZErkNNLLWf9pqDyS3rCEYV1Re",
            "symbol": "UWT",
            "name": "Solana Underworld Token",
            "decimals": 0,
            "logoURI": "https://solsnatchers.com/images/head2.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolSnatchersNFT",
                "website": "https://solsnatchers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DGeHh4eoxGau3iH7PfdTJdRhZu4FWNgDFF1Czd3tNemT",
            "symbol": "UMURPHY",
            "name": "Uncle Murphy Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DGeHh4eoxGau3iH7PfdTJdRhZu4FWNgDFF1Czd3tNemT/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "telegram": "https://t.me/joinchat/1x2i0txLEOY2Yjgy",
                "twitter": "https://twitter.com/Driver29973042",
                "website": "https://unclemurphycoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ",
            "symbol": "NEKI",
            "name": "Maneki-neko",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ/logo.png",
            "tags": [
                "utility-token",
                "meme-token",
                "neki"
            ],
            "extensions": {
                "coingeckoId": "maneki-neko",
                "twitter": "https://twitter.com/ManekiNekoLabs"
            }
        },
        {
            "chainId": 101,
            "address": "D6yPmaM6SueQN4mteEQMiVFMbk6BSAShJAhuqyzVJ3fq",
            "symbol": "YARN",
            "name": "Yarn Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D6yPmaM6SueQN4mteEQMiVFMbk6BSAShJAhuqyzVJ3fq/logo.png",
            "tags": [
                "utility-token",
                "payment-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/yarncoin",
                "website": "https://yarncoin.io"
            }
        },
        {
            "chainId": 101,
            "address": "4BzxVoBQzwKoqm1dQc78r42Yby3EzAeZmMiYFdCjeu5Z",
            "symbol": "SWOLE",
            "name": "Swole Doge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4BzxVoBQzwKoqm1dQc78r42Yby3EzAeZmMiYFdCjeu5Z/logo.png",
            "extensions": {
                "coingeckoId": "swole-doge",
                "discord": "https://discord.gg/HfdHnhQkpB",
                "serumV3Usdc": "3SGeuz8EXsyFo4HHWXQsoo8r4r5RdZkt7TuuTZiVbKc8",
                "twitter": "https://twitter.com/swoledoge",
                "website": "https://swoledogecoin.org"
            }
        },
        {
            "chainId": 101,
            "address": "C3Rjiq8o2yiRWsmdSUSN276u5ah3UTqyWbZudLMG41pt",
            "symbol": "PITXX",
            "name": "Pi Token v2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C3Rjiq8o2yiRWsmdSUSN276u5ah3UTqyWbZudLMG41pt/logo.png",
            "extensions": {
                "website": "https://tokeninfo.yolasite.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CC1gRBjsu8c7sf79wVd2Ub46X1UntPd81T7tmw7sTVYp",
            "symbol": "BNN",
            "name": "Banana",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/tradeape/First_token_image/bnn.png"
        },
        {
            "chainId": 101,
            "address": "6oaVWSKRi4Pm1qVLf3fQFWFWygFhgswAL7Z466WE3cE8",
            "symbol": "IMUA",
            "name": "IMUA",
            "decimals": 9,
            "logoURI": "https://github.com/riversnout/imua/blob/main/rh.png",
            "extensions": {
                "website": "https://remnanthighway.org"
            }
        },
        {
            "chainId": 101,
            "address": "9Sbzj4DnRW8qFnfvJWwXxQMRkWKAwHLs9NgDuBFjkVgW",
            "symbol": "PITDT",
            "name": "Picasso Token Div Test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Sbzj4DnRW8qFnfvJWwXxQMRkWKAwHLs9NgDuBFjkVgW/logo.png"
        },
        {
            "chainId": 101,
            "address": "CN7qFa5iYkHz99PTctvT4xXUHnxwjQ5MHxCuTJtPN5uS",
            "symbol": "BOKU",
            "name": "Boryoku Dragonz",
            "decimals": 9,
            "logoURI": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/BokuBrew.png",
            "extensions": {
                "coingeckoId": "boryoku-dragonz",
                "coinmarketcap": "https://coinmarketcap.com/currencies/boku/",
                "serumV3Usdc": "Dvm8jjdAy8uyXn9WXjS2p1mcPeFTuYS6yW2eUL9SJE8p",
                "twitter": "https://twitter.com/BoryokuDragonz",
                "website": "https://boryokudragonz.io"
            }
        },
        {
            "chainId": 101,
            "address": "H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A",
            "symbol": "OOGI",
            "name": "OOGI",
            "decimals": 9,
            "logoURI": "https://oogi.com/icon.png",
            "extensions": {
                "coingeckoId": "oogi",
                "discord": "https://discord.gg/oogi",
                "serumV3Usdc": "ANUCohkG9gamUn6ofZEbnzGkjtyMexDhnjCwbLDmQ8Ub",
                "telegram": "https://t.me/oogicoin",
                "twitter": "https://twitter.com/oogicoin",
                "website": "https://oogi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GCxgQbbvJc4UyqGCsUAUa38npzZX27EMxZwckLuWeEkt",
            "symbol": "NUTS",
            "name": "NUTS",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/93886730/140664862-6dd80bff-be30-4c68-a978-fcb205011d61.png",
            "extensions": {
                "discord": "https://discord.gg/SSANFT",
                "twitter": "https://twitter.com/SSA_NFT",
                "website": "https://ssa.gg"
            }
        },
        {
            "chainId": 101,
            "address": "EFYKDdppK1FjixaxExpVhoTd8gtAmncbhQYruzWyG6Cx",
            "symbol": "SPKL",
            "name": "Spookeletons Token",
            "decimals": 9,
            "logoURI": "https://www.spookeletons.com/assets/spookeletons_token.png",
            "extensions": {
                "serumV3Usdc": "8nTQLcukiGQEQ1zguvmtLx95VJmZm5WxRsdBneaTGVmN",
                "website": "https://www.spookeletons.com"
            }
        },
        {
            "chainId": 101,
            "address": "31tCNEE6LiL9yW4Bu153Dq4vi2GuorXxCA9pW9aA6ecU",
            "symbol": "SPKL",
            "name": "Spookeletons Token",
            "decimals": 9,
            "logoURI": "https://www.spookeletons.com/assets/spookeletons_tokenv2.png",
            "extensions": {
                "serumV3Usdc": "6b51zj1C78Tn7R3nd9j4GvyShbMNxxufbU3mqPmbDRcz",
                "website": "https://www.spookeletons.com"
            }
        },
        {
            "chainId": 101,
            "address": "q4bpaRKw3fJB1AJBeeBaKv3TjYzWsmntLgnSB275YUb",
            "symbol": "TRTLS",
            "name": "Turtles Token",
            "decimals": 9,
            "logoURI": "https://www.turtles.com/turtles.png",
            "extensions": {
                "coingeckoId": "turtles-token",
                "serumV3Usdc": "2dKHkfJGKNxmtwdLcsqXFGcb8Xppw5RP6YVWEWjSfAHm",
                "website": "https://www.turtles.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FRtCrYT6oHEM7tdcfJJkDRMhqRWb9EjnobJSA2T95Put",
            "symbol": "MMaps",
            "name": "Map Metrics",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FRtCrYT6oHEM7tdcfJJkDRMhqRWb9EjnobJSA2T95Put/logo.png",
            "tags": [
                "utility-token",
                "Community",
                "NFT"
            ],
            "extensions": {
                "website": "https://mapmetrics.org/"
            }
        },
        {
            "chainId": 101,
            "address": "EFqYVEitSUpUTj2d9LSqun4eZ4BzouFuTPqQzU4hNtsS",
            "symbol": "MMaps",
            "name": "MapMetrics",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EFqYVEitSUpUTj2d9LSqun4eZ4BzouFuTPqQzU4hNtsS/logo.png",
            "tags": [
                "utility-token",
                "Community",
                "NFT"
            ],
            "extensions": {
                "website": "https://mapmetrics.org/"
            }
        },
        {
            "chainId": 101,
            "address": "3CaBxqxWsP5oqS84Pkja4wLxyZYsHzMivQbnfwFJQeL1",
            "symbol": "SOLFI",
            "name": "SOLFI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CaBxqxWsP5oqS84Pkja4wLxyZYsHzMivQbnfwFJQeL1/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solfina_io",
                "website": "https://solfina.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DdUm3gHS5nnB3XKnxEufnazb5ERa9vHjK6S3DgZi7QR5",
            "symbol": "BTSL",
            "name": "Bitsol Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DdUm3gHS5nnB3XKnxEufnazb5ERa9vHjK6S3DgZi7QR5/logo.png",
            "tags": [
                "DEX"
            ],
            "extensions": {
                "telegram": "https://t.me/bitsolfinance",
                "twitter": "https://twitter.com/bitsol_finance",
                "website": "https://bitsol.finance"
            }
        },
        {
            "chainId": 101,
            "address": "8f9s1sUmzUbVZMoMh6bufMueYH1u4BJSM57RCEvuVmFp",
            "symbol": "TRUE",
            "name": "TrueSight",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/pKTWrwP/true.jpg",
            "tags": [
                "utility-token",
                "capital-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Dzzc14S1D7cEFGJyMZMACuoQRHVUYFhVE74C5o8Fwau",
            "symbol": "BAB",
            "name": "Banana Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Dzzc14S1D7cEFGJyMZMACuoQRHVUYFhVE74C5o8Fwau/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://bananabucks.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GdRNeX9mbzCt4AnfiUFLbYZmxRuW7pGHfjbbAM59ZybR",
            "symbol": "SLFT",
            "name": "SOLife",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdRNeX9mbzCt4AnfiUFLbYZmxRuW7pGHfjbbAM59ZybR/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SOLifeNFT",
                "website": "https://solife.town"
            }
        },
        {
            "chainId": 101,
            "address": "AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ",
            "symbol": "SLX",
            "name": "Solex Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solexfin/token-list/main/assets/mainnet/AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ/logo.png",
            "tags": [
                "Exchange",
                "DApp"
            ],
            "extensions": {
                "coingeckoId": "solex-finance",
                "serumV3Usdc": "7xy4r55rLu2QYeSGwTGDCBKEYERDX98f6kQLn9Zre6S3",
                "twitter": "https://twitter.com/SolexFinance",
                "website": "https://solex.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "HppJbUYU4a9i3dXo1x1SS5ieaKEz4cAPWMg4eNQzabzg",
            "symbol": "BLOCKING",
            "name": "Blocking",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HppJbUYU4a9i3dXo1x1SS5ieaKEz4cAPWMg4eNQzabzg/logo.png",
            "tags": [
                "NFT",
                "Blocking"
            ],
            "extensions": {
                "website": "http://blocking.adchunha.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4HjScxnKaowf2SJzEKUCaHLJUe4qUBX15jCop6FQ4Ht1",
            "symbol": "Z",
            "name": "Zeus",
            "decimals": 5,
            "logoURI": "https://www.pnglib.com/wp-content/uploads/2021/02/letter-z_60235255a2bf7.png",
            "tags": [
                "Exchange",
                "DApp"
            ]
        },
        {
            "chainId": 101,
            "address": "Aojru8bfwZK6sgrx6exNazxASFZUjPpRY59byMrs6izt",
            "symbol": "OINK",
            "name": "OINK",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/dungnc/luckypigsNFT/main/SYMBOL.jpeg",
            "tags": [
                "meme",
                "social-token",
                "oink"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GiftedLabs",
                "website": "http://luckypignfts.com"
            }
        },
        {
            "chainId": 101,
            "address": "5sM9xxcBTM9rWza6nEgq2cShA87JjTBx1Cu82LjgmaEg",
            "symbol": "BMBO",
            "name": "Bamboo",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/rishkumaria/bamboo/main/bamboo.png",
            "extensions": {
                "coingeckoId": "bamboo-coin"
            }
        },
        {
            "chainId": 101,
            "address": "5F3beSzHFv1m3T2Sqp7dNQPYrZeetLZ8JYe6QPW6cqKA",
            "symbol": "ZEUS",
            "name": "ZEUS",
            "decimals": 8,
            "logoURI": "https://previews.123rf.com/images/arbuzu/arbuzu1501/arbuzu150100014/35131140-letter-z-logo-icon-design-template-elements.jpg",
            "tags": [
                "NFT",
                "ZEUS"
            ],
            "extensions": {
                "website": "http://test.com/"
            }
        },
        {
            "chainId": 101,
            "address": "C8QMoDwQADoW4MVkDZx7HgnebeugnNXWztrqpcCT2mFj",
            "symbol": "SHARK",
            "name": "Rogue Shark 129",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/L0px1n/token-list/main/assets/mainnet/shark.jpeg",
            "tags": [
                "NFT",
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "4oyPeSSUwfxExjBU76fTfAFHHrZ3HVwCHWqAUdpeFg6h",
            "symbol": "SHARK",
            "name": "Rogue Shark 129",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/L0px1n/token-list/main/assets/mainnet/shark.jpeg"
        },
        {
            "chainId": 101,
            "address": "9CuowWiTMFBeC5ntjdAeMv3W72vzwveCC9ATTjnjXjNx",
            "symbol": "KRCH",
            "name": "KrechetovNFT",
            "decimals": 0,
            "logoURI": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Toyota_GT86_%E2%80%93_Frontansicht%2C_17._September_2012%2C_D%C3%BCsseldorf.jpg/1200px-Toyota_GT86_%E2%80%93_Frontansicht%2C_17._September_2012%2C_D%C3%BCsseldorf.jpg",
            "tags": [
                "NFT",
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz",
            "symbol": "FUM",
            "name": "FUMoney",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FUMoney801/token-list/main/assets/mainnet/EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FUMindset",
                "website": "https://FUMoney.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9XtRZwKzDXEJ61A7qCqbPz8jXMYHGT3LwxqrEzB6fqxv",
            "symbol": "SOLUM",
            "name": "Solum",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XtRZwKzDXEJ61A7qCqbPz8jXMYHGT3LwxqrEzB6fqxv/logo.png",
            "extensions": {
                "coingeckoId": "solum",
                "telegram": "https://t.me/solumfinance",
                "twitter": "https://twitter.com/solumDefi",
                "website": "https://solum.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "31HcuZjFHCV8VcydRjXz9NFnte4RVRRuR5n9rECfy79W",
            "symbol": "BOOZ",
            "name": "boozcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jatoshi/token-list/main/BoozCoin%20Logo%202021-07-21.png",
            "extensions": {
                "telegram": "https://t.me/boozcoin",
                "twitter": "https://twitter.com/boozcoin",
                "website": "https://boozcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7Y2FprWHsBYCFkTpWiQ123BMPgWWquraaZHy6oFofcvs",
            "symbol": "JDBEAR",
            "name": "JareBear",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/7Y2FprWHsBYCFkTpWiQ123BMPgWWquraaZHy6oFofcvs/logo.png",
            "tags": [
                "leveraged",
                "bear",
                "nft"
            ],
            "extensions": {
                "website": "https://stacc.art/"
            }
        },
        {
            "chainId": 101,
            "address": "D3gRmoveMFa8e3ziw5XCwCByHKiSk76T4fi62GXNYXHi",
            "symbol": "JDBULL",
            "name": "JareBool",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/D3gRmoveMFa8e3ziw5XCwCByHKiSk76T4fi62GXNYXHi/logo.png",
            "tags": [
                "leveraged",
                "bull",
                "nft"
            ],
            "extensions": {
                "website": "https://stacc.art/"
            }
        },
        {
            "chainId": 101,
            "address": "8z55xQupEQcjAQTJy3BwZJX24pmtCJDo8MEe9Ub7a3Yv",
            "symbol": "SHAK",
            "name": "Shakudo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/8z55xQupEQcjAQTJy3BwZJX24pmtCJDo8MEe9Ub7a3Yv/logo.svg",
            "extensions": {
                "website": "https://shakudo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7ypyxaQoHcJwoo9wXsc3tqL6PN4UQuEoyyfg5xjDX1Dj",
            "symbol": "MMY",
            "name": "Mummy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/7ypyxaQoHcJwoo9wXsc3tqL6PN4UQuEoyyfg5xjDX1Dj/logo.png",
            "extensions": {
                "website": "https://mummy.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HavbxBPK1uY9kMNqKPkWDEQXWw6FYERrLxeMtWiXnwko",
            "symbol": "SXP",
            "name": "Stacc eXPerience Points",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/HavbxBPK1uY9kMNqKPkWDEQXWw6FYERrLxeMtWiXnwko/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/WUTFtgDhXd",
                "twitter": "https://twitter.com/STACCart",
                "website": "https://stacc.art/"
            }
        },
        {
            "chainId": 101,
            "address": "2A5esErqMaJXhrs1i6CtjbVxTbgsY9JbDedVsuVMQ6aY",
            "symbol": "SJP",
            "name": "Stacc Job Points",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/2A5esErqMaJXhrs1i6CtjbVxTbgsY9JbDedVsuVMQ6aY/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/WUTFtgDhXd",
                "twitter": "https://twitter.com/STACCart",
                "website": "https://stacc.art/"
            }
        },
        {
            "chainId": 101,
            "address": "84vmWvKxebcnZL5JPnqkzPFVBaL4y5uGCRRJW2a9P4SL",
            "symbol": "cvs",
            "name": "coinvs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CoinVs/brand-assets/master/logo/logo.png",
            "tags": [
                "coinversus",
                "cvs",
                "entertainment",
                "gaming",
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/F84VmCtByV",
                "facebook": "https://www.facebook.com/coinversusofficial",
                "github": "https://github.com/CoinVs",
                "instagram": "https://www.instagram.com/coinversus/",
                "reddit": "https://www.reddit.com/r/coinversus/",
                "telegram": "https://t.me/coinvs",
                "twitter": "https://twitter.com/CoinVersus",
                "website": "https://coinversus.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9KYMTqKY7f2cJKW2wYfNRpLb9zbB1tTyEbaTuzy4Gwwc",
            "symbol": "SGP",
            "name": "Stacc Gold Points",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/9KYMTqKY7f2cJKW2wYfNRpLb9zbB1tTyEbaTuzy4Gwwc/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/WUTFtgDhXd",
                "twitter": "https://twitter.com/STACCart",
                "website": "https://stacc.art/"
            }
        },
        {
            "chainId": 101,
            "address": "2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7",
            "symbol": "SLB",
            "name": "Solberg",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7/logo.svg",
            "extensions": {
                "coingeckoId": "solberg",
                "discord": "https://discord.gg/2w7J25Xgce",
                "instagram": "https://www.instagram.com/solbergtoken/",
                "serumV3Usdc": "4MUhHF5K9simSLXuaUKMaU8nMHSCRPzPMe7UtJRXi4HZ",
                "serumV3Usdt": "46jD4hpmvUAbhKaoAjdzFkY82VW1j9SMTEYFGcTor8Ww",
                "telegram": "https://t.me/solbergofficial",
                "twitter": "https://twitter.com/SolbergToken",
                "website": "https://www.solbergtoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HDLRMKW1FDz2q5Zg778CZx26UgrtnqpUDkNNJHhmVUFr",
            "symbol": "MILLI",
            "name": "MILLIONSY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/millionsy/token-list/main/assets/mainnet/HDLRMKW1FDz2q5Zg778CZx26UgrtnqpUDkNNJHhmVUFr/logo.png",
            "extensions": {
                "telegram": "https://t.me/MILLIONSYofficialchat",
                "twitter": "https://twitter.com/MILLIONSYio",
                "website": "https://www.millionsy.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7ic3cSqD6iiwsqxDyBbcs2qYfMcKY2HndLDrjhMKZ4cQ",
            "symbol": "$SOLY",
            "name": "Solana Yield",
            "decimals": 8,
            "logoURI": "https://github.com/saimaliabrish/Solana-yield-logo/blob/main/IMG_20210923_195019_436.png",
            "extensions": {
                "twitter": "https://twitter.com/AbrishSaim"
            }
        },
        {
            "chainId": 101,
            "address": "Dw3E5NJGyAZ5QmeB165cGf2gfc9ktyFu55wMyh3MaJov",
            "symbol": "TKC",
            "name": "Tugscom Coin",
            "decimals": 9,
            "logoURI": "https://tugscom.mn/tkc.png",
            "tags": [
                "TKC",
                "Tugscom"
            ],
            "extensions": {
                "website": "https://tugscom.mn/"
            }
        },
        {
            "chainId": 101,
            "address": "99pifp4v4qQNk3irTHpmAEEzgKfs3ahLE7iFKEqfyxPj",
            "symbol": "ZI",
            "name": "ZI (The Z Institute Token)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/99pifp4v4qQNk3irTHpmAEEzgKfs3ahLE7iFKEqfyxPj/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/the_z_institute",
                "website": "https://zinstitute.net/"
            }
        },
        {
            "chainId": 101,
            "address": "FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF",
            "symbol": "SOLA",
            "name": "Sola Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "sola-token",
                "serumV3Usdc": "4RZ27tjRnSwrtRqsJxDEgsERnDKFs7yx6Ra3HsJvkboy",
                "telegram": "https://t.me/solatokennet",
                "twitter": "https://twitter.com/EcoSolana",
                "website": "https://solatoken.net/"
            }
        },
        {
            "chainId": 101,
            "address": "So11111111111111111111111111111111111111112",
            "symbol": "SOL",
            "name": "Wrapped SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
            "extensions": {
                "coingeckoId": "solana",
                "serumV3Usdc": "9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT",
                "serumV3Usdt": "HWHvQhFmJB3NUcu1aihKmrKegfVxBEHzwVX6yZCKEsi1",
                "website": "https://solana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
            "symbol": "USDC",
            "name": "USD Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "serumV3Usdt": "77quYg4MGneUdjgXCunt9GgM1usmrxKY31twEy3WHwcS",
                "website": "https://www.centre.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SrZXMTPhY8TEJMXQEhHKbFdVY2oNSGR5YqMMGK6Zm7L",
            "symbol": "BDNT",
            "name": "Battle Drone Native Token",
            "decimals": 9,
            "logoURI": "https://battledronesnft.com/BDNT.png",
            "tags": [
                "utility-token",
                "BDNT"
            ],
            "extensions": {
                "discord": "https://discord.gg/jRz63Dsa3p",
                "twitter": "https://twitter.com/BattleDronesNFT",
                "website": "https://battledronesnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2inRoG4DuMRRzZxAt913CCdNZCu2eGsDD9kZTrsj2DAZ",
            "symbol": "TSLA",
            "name": "Tesla Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2inRoG4DuMRRzZxAt913CCdNZCu2eGsDD9kZTrsj2DAZ/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?TSLA"
            }
        },
        {
            "chainId": 101,
            "address": "8bpRdBGPt354VfABL5xugP3pmYZ2tQjzRcqjg2kmwfbF",
            "symbol": "AAPL",
            "name": "Apple Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8bpRdBGPt354VfABL5xugP3pmYZ2tQjzRcqjg2kmwfbF/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?AAPL"
            }
        },
        {
            "chainId": 103,
            "address": "DGGETjRbXeNyq2bpA7FLmWwqjFLtS8p5aYjzUwtAHtZd",
            "symbol": "Aiko",
            "name": "Aiko",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7YBn43cTvko7mfZ9YV1iyAUfHNuMp6aAy4rX7wehXcbU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Y72V9wn3Yw",
                "medium": "https://aikonft.medium.com/",
                "reddit": "https://www.reddit.com/r/AikoNFT/",
                "twitter": "https://twitter.com/aikonft",
                "website": "https://aiko.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7J2cEFFhMCsdUBWssnYfUG5z6zYJYCUaTNPTxH3k9aVY",
            "symbol": "AIKO",
            "name": "Aiko",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7J2cEFFhMCsdUBWssnYfUG5z6zYJYCUaTNPTxH3k9aVY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Y72V9wn3Yw",
                "reddit": "https://www.reddit.com/r/AikoNFT/",
                "twitter": "https://twitter.com/aikonft",
                "website": "https://aiko.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3vhcrQfEn8ashuBfE82F3MtEDFcBCEFfFw1ZgM3xj1s8",
            "symbol": "MSFT",
            "name": "Microsoft Corporation",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vhcrQfEn8ashuBfE82F3MtEDFcBCEFfFw1ZgM3xj1s8/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?MSFT"
            }
        },
        {
            "chainId": 101,
            "address": "HNXTQPd5FkGX7USMufrxkvUQkTGmNFxVYCnAhuR941mm",
            "symbol": "DOWIT",
            "name": "DOWIT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HNXTQPd5FkGX7USMufrxkvUQkTGmNFxVYCnAhuR941mm/logo.png",
            "extensions": {
                "website": "https://dowit-coin.com"
            }
        },
        {
            "chainId": 101,
            "address": "4R8DBzZEzjBQzPJe4qqaxXM97am7unGM1ZYfviS6oSFe",
            "symbol": "ANU",
            "name": "ANUBEAST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ANUBEAST/ANUBEAST/master/assets/logo.png",
            "tags": [
                "stake"
            ],
            "extensions": {
                "website": "https://github.com/ANUBEAST"
            }
        },
        {
            "chainId": 101,
            "address": "ASwYCbLedk85mRdPnkzrUXbbYbwe26m71af9rzrhC2Qz",
            "symbol": "MSTR",
            "name": "MicroStrategy Incorporated",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASwYCbLedk85mRdPnkzrUXbbYbwe26m71af9rzrhC2Qz/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?MSTR"
            }
        },
        {
            "chainId": 101,
            "address": "J25jdsEgTnAwB4nVq3dEQhwekbXCnVTGzFpVMPScXRgK",
            "symbol": "COIN",
            "name": "Coinbase Global Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J25jdsEgTnAwB4nVq3dEQhwekbXCnVTGzFpVMPScXRgK/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?COIN"
            }
        },
        {
            "chainId": 101,
            "address": "G2Cg4XoXdEJT5sfrSy9N6YCC3uuVV3AoTQSvMeSqT8ZV",
            "symbol": "ABC",
            "name": "AmerisourceBergen Corp",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G2Cg4XoXdEJT5sfrSy9N6YCC3uuVV3AoTQSvMeSqT8ZV/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?ABC"
            }
        },
        {
            "chainId": 101,
            "address": "FqqVanFZosh4M4zqxzWUmEnky6nVANjghiSLaGqUAYGi",
            "symbol": "ABNB",
            "name": "Airbnb",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FqqVanFZosh4M4zqxzWUmEnky6nVANjghiSLaGqUAYGi/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?ABNB"
            }
        },
        {
            "chainId": 101,
            "address": "FgcUo7Ymua8r5xxsn9puizkLGN5w4i3nnBmasXvkcWfJ",
            "symbol": "ACB",
            "name": "Aurora Cannabis Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgcUo7Ymua8r5xxsn9puizkLGN5w4i3nnBmasXvkcWfJ/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?ACB"
            }
        },
        {
            "chainId": 101,
            "address": "FenmUGWjsW5AohtHRbgLoPUZyWSK36Cd5a31XJWjnRur",
            "symbol": "AMC",
            "name": "AMC Entertainment Holdings",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FenmUGWjsW5AohtHRbgLoPUZyWSK36Cd5a31XJWjnRur/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?AMC"
            }
        },
        {
            "chainId": 101,
            "address": "7grgNP3tAJh7DRELmotHzC5Efth4e4SoBvgmFYTX9jPB",
            "symbol": "AMD",
            "name": "Advanced Micro Devices",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7grgNP3tAJh7DRELmotHzC5Efth4e4SoBvgmFYTX9jPB/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?AMD"
            }
        },
        {
            "chainId": 101,
            "address": "3bjpzTTK49eP8m1bYxw6HYAFGtzyWjvEyGYcFS4gbRAx",
            "symbol": "AMZN",
            "name": "Amazon",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3bjpzTTK49eP8m1bYxw6HYAFGtzyWjvEyGYcFS4gbRAx/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?AMZN"
            }
        },
        {
            "chainId": 101,
            "address": "4cr7NH1BD2PMV38JQp58UaHUxzqhxeSiF7b6q1GCS7Ae",
            "symbol": "APHA",
            "name": "Aphria Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4cr7NH1BD2PMV38JQp58UaHUxzqhxeSiF7b6q1GCS7Ae/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?APHA"
            }
        },
        {
            "chainId": 101,
            "address": "GPoBx2hycDs3t4Q8DeBme9RHb9nQpzH3a36iUoojHe16",
            "symbol": "ARKK",
            "name": "ARK Innovation ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GPoBx2hycDs3t4Q8DeBme9RHb9nQpzH3a36iUoojHe16/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?ARKK"
            }
        },
        {
            "chainId": 101,
            "address": "GgDDCnzZGQRUDy8jWqSqDDcPwAVg2YsKZfLPaTYBWdWt",
            "symbol": "BABA",
            "name": "Alibaba",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GgDDCnzZGQRUDy8jWqSqDDcPwAVg2YsKZfLPaTYBWdWt/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?BABA"
            }
        },
        {
            "chainId": 101,
            "address": "6jSgnmu8yg7kaZRWp5MtQqNrWTUDk7KWXhZhJPmsQ65y",
            "symbol": "BB",
            "name": "BlackBerry",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6jSgnmu8yg7kaZRWp5MtQqNrWTUDk7KWXhZhJPmsQ65y/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?BB"
            }
        },
        {
            "chainId": 101,
            "address": "5HHv6HAyBtaihyHEapCJvjE6iRbGLRmm3F5EZjz6EzHV",
            "symbol": "wID_v1",
            "name": "Everest ID (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5HHv6HAyBtaihyHEapCJvjE6iRbGLRmm3F5EZjz6EzHV/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xEBd9D99A3982d547C5Bb4DB7E3b1F9F14b67Eb83",
                "assetContract": "https://etherscan.io/address/0xEBd9D99A3982d547C5Bb4DB7E3b1F9F14b67Eb83",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "everid",
                "twitter": "https://twitter.com/EverestDotOrg",
                "website": "https://everest.org"
            }
        },
        {
            "chainId": 101,
            "address": "9Vovr1bqDbMQ8DyaizdC7n1YVvSia8r3PQ1RcPFqpQAs",
            "symbol": "BILI",
            "name": "Bilibili Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Vovr1bqDbMQ8DyaizdC7n1YVvSia8r3PQ1RcPFqpQAs/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?BILI"
            }
        },
        {
            "chainId": 101,
            "address": "j35qY1SbQ3k7b2WAR5cNETDKzDESxGnYbArsLNRUzg2",
            "symbol": "BITW",
            "name": "Bitwise 10 Crypto Index Fund",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/j35qY1SbQ3k7b2WAR5cNETDKzDESxGnYbArsLNRUzg2/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?BITW"
            }
        },
        {
            "chainId": 101,
            "address": "AykRYHVEERRoKGzfg2AMTqEFGmCGk9LNnGv2k5FgjKVB",
            "symbol": "BNTX",
            "name": "BioNTech",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AykRYHVEERRoKGzfg2AMTqEFGmCGk9LNnGv2k5FgjKVB/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?BNTX"
            }
        },
        {
            "chainId": 101,
            "address": "Dj76V3vdFGGE8444NWFACR5qmtJrrSop5RCBAGbC88nr",
            "symbol": "BRKA",
            "name": "Berkshire Hathaway Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dj76V3vdFGGE8444NWFACR5qmtJrrSop5RCBAGbC88nr/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?BRKA"
            }
        },
        {
            "chainId": 101,
            "address": "8TUg3Kpa4pNfaMvgyFdvwyiPBSnyTx7kK5EDfb42N6VK",
            "symbol": "BYND",
            "name": "Beyond Meat Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8TUg3Kpa4pNfaMvgyFdvwyiPBSnyTx7kK5EDfb42N6VK/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?BYND"
            }
        },
        {
            "chainId": 101,
            "address": "8FyEsMuDWAMMusMqVEstt2sDkMvcUKsTy1gF6oMfWZcG",
            "symbol": "CGC",
            "name": "Canopy Growth Corp",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FyEsMuDWAMMusMqVEstt2sDkMvcUKsTy1gF6oMfWZcG/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?CGC"
            }
        },
        {
            "chainId": 101,
            "address": "DUFVbhWf7FsUo3ouMnFbDjv4YYaRE1Sz9jvAmDsNTt1m",
            "symbol": "CRON",
            "name": "Chronos Group Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUFVbhWf7FsUo3ouMnFbDjv4YYaRE1Sz9jvAmDsNTt1m/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?CRON"
            }
        },
        {
            "chainId": 101,
            "address": "J9GVpBChXZ8EK7JuPsLSDV17BF9KLJweBQet3L6ZWvTC",
            "symbol": "EEM",
            "name": "iShares MSCI Emerging Markets ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J9GVpBChXZ8EK7JuPsLSDV17BF9KLJweBQet3L6ZWvTC/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?EEM"
            }
        },
        {
            "chainId": 101,
            "address": "6Xj2NzAW437UUomaxFiVyJQPGvvup6YLeXFQpp4kqNaD",
            "symbol": "EFA",
            "name": "iShares MSCI EAFE ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Xj2NzAW437UUomaxFiVyJQPGvvup6YLeXFQpp4kqNaD/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?EFA"
            }
        },
        {
            "chainId": 101,
            "address": "5YMFoVuoQzdivpm6W97UGKkHxq6aEhipuNkA8imPDoa1",
            "symbol": "ETHE",
            "name": "Grayscale Ethereum Trust",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5YMFoVuoQzdivpm6W97UGKkHxq6aEhipuNkA8imPDoa1/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?ETHE"
            }
        },
        {
            "chainId": 101,
            "address": "C9vMZBz1UCmYSCmMcZFw6N9AsYhXDAWnuhxd8ygCA1Ah",
            "symbol": "EWA",
            "name": "iShares MSCI Australia ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9vMZBz1UCmYSCmMcZFw6N9AsYhXDAWnuhxd8ygCA1Ah/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?EWA"
            }
        },
        {
            "chainId": 101,
            "address": "AcXn3WXPARC7r5JwrkPHSUmBGWyWx1vRydNHXXvgc8V6",
            "symbol": "EWJ",
            "name": "iShares MSCI Japan ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AcXn3WXPARC7r5JwrkPHSUmBGWyWx1vRydNHXXvgc8V6/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?EWJ"
            }
        },
        {
            "chainId": 101,
            "address": "8ihxfcxBZ7dZyfnpXJiGrgEZfrKWbZUk6LjfosLrQfR",
            "symbol": "EWY",
            "name": "iShares MSCI South Korea ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ihxfcxBZ7dZyfnpXJiGrgEZfrKWbZUk6LjfosLrQfR/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?EWY"
            }
        },
        {
            "chainId": 101,
            "address": "N5ykto2MU7CNcLX7sgWFe3M2Vpy7wq8gDt2sVNDe6aH",
            "symbol": "EWZ",
            "name": "iShares MSCI Brazil ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/N5ykto2MU7CNcLX7sgWFe3M2Vpy7wq8gDt2sVNDe6aH/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?EWZ"
            }
        },
        {
            "chainId": 101,
            "address": "3K9pfJzKiAm9upcyDWk5NBVdjxVtqXN8sVfQ4aR6qwb2",
            "symbol": "FB",
            "name": "Facebook",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3K9pfJzKiAm9upcyDWk5NBVdjxVtqXN8sVfQ4aR6qwb2/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?FB"
            }
        },
        {
            "chainId": 101,
            "address": "Ege7FzfrrBSusVQrRUuTiFVCSc8u2R9fRWh4qLjdNYfz",
            "symbol": "FXI",
            "name": "iShares China Large-Cap ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ege7FzfrrBSusVQrRUuTiFVCSc8u2R9fRWh4qLjdNYfz/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?FXI"
            }
        },
        {
            "chainId": 101,
            "address": "FiV4TtDtnjaf8m8vw2a7uc9hRoFvvu9Ft7GzxiMujn3t",
            "symbol": "GBTC",
            "name": "Grayscale Bitcoin Trust",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FiV4TtDtnjaf8m8vw2a7uc9hRoFvvu9Ft7GzxiMujn3t/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?GBTC"
            }
        },
        {
            "chainId": 101,
            "address": "7FYk6a91TiFWigBvCf8KbuEMyyfpqET5QHFkRtiD2XxF",
            "symbol": "GDX",
            "name": "VanEck Vectors Gold Miners Etf",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7FYk6a91TiFWigBvCf8KbuEMyyfpqET5QHFkRtiD2XxF/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?GDX"
            }
        },
        {
            "chainId": 101,
            "address": "EGhhk4sHgY1SBYsgkfgyGNhAKBXqn6QyKNx7W13evx9D",
            "symbol": "GDXJ",
            "name": "VanEck Vectors Junior Gold Miners Etf",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EGhhk4sHgY1SBYsgkfgyGNhAKBXqn6QyKNx7W13evx9D/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?GDXJ"
            }
        },
        {
            "chainId": 101,
            "address": "9HyU5EEyPvkxeuekNUwsHzmMCJoiw8FZBGWaNih2oux1",
            "symbol": "GLD",
            "name": "SPDR Gold Shares",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9HyU5EEyPvkxeuekNUwsHzmMCJoiw8FZBGWaNih2oux1/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?GLD"
            }
        },
        {
            "chainId": 101,
            "address": "EYLa7susWhzqDNKYe7qLhFHb3Y9kdNwThc6QSnc4TLWN",
            "symbol": "GLXY",
            "name": "Galaxy Digital Holdings",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EYLa7susWhzqDNKYe7qLhFHb3Y9kdNwThc6QSnc4TLWN/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?GLXY"
            }
        },
        {
            "chainId": 101,
            "address": "Ac2wmyujRxiGtb5msS7fKzGycaCF7K8NbVs5ortE6MFo",
            "symbol": "GME",
            "name": "GameStop",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ac2wmyujRxiGtb5msS7fKzGycaCF7K8NbVs5ortE6MFo/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?GME"
            }
        },
        {
            "chainId": 101,
            "address": "7uzWUPC6XsWkgFAuDjpZgPVH9p3WqeKTvTJqLM1RXX6w",
            "symbol": "GOOGL",
            "name": "Google",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uzWUPC6XsWkgFAuDjpZgPVH9p3WqeKTvTJqLM1RXX6w/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?GOOGL"
            }
        },
        {
            "chainId": 101,
            "address": "XJUMvw7KRLoLCYVD727jV9fjNUSDVcZaQUK6XpY6kGm",
            "symbol": "IF",
            "name": "Impossible Finance",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmcfMbNuvDV6ho3fueNPFTtgSruaUBmSdqP5D5ZduipN6S/logo.png",
            "tags": [
                "ethereum"
            ],
            "extensions": {
                "coingeckoId": "impossible-finance",
                "website": "https://impossible.finance"
            }
        },
        {
            "chainId": 101,
            "address": "CnLLrX9A8RhKpq8Z3CKko7sQMqN2AXj8AfFyxxoBBEFf",
            "symbol": "CH",
            "name": "COIN HUNTER",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CnLLrX9A8RhKpq8Z3CKko7sQMqN2AXj8AfFyxxoBBEFf/logo.png",
            "extensions": {
                "website": "https://bit.ly/Coin-Hunter"
            }
        },
        {
            "chainId": 101,
            "address": "CB3obConLVWpo8RtTANzBSURmJnAVgy5xznvQfWXDfpR",
            "symbol": "PURITY",
            "name": "PURITY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CB3obConLVWpo8RtTANzBSURmJnAVgy5xznvQfWXDfpR/logo.png",
            "extensions": {
                "website": "https://github.com/teampurity"
            }
        },
        {
            "chainId": 101,
            "address": "GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE",
            "symbol": "SOL100",
            "name": "SOL100",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE/logo.png",
            "extensions": {
                "website": "https://github.com/teampurity"
            }
        },
        {
            "chainId": 101,
            "address": "5E2742iZRZgZF94bfz39NgV9wjppe24YrQJVu6niLPMA",
            "symbol": "GPA",
            "name": "GALAXY PANDA",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5E2742iZRZgZF94bfz39NgV9wjppe24YrQJVu6niLPMA/logo.png",
            "extensions": {
                "website": "http://galaxypanda.space"
            }
        },
        {
            "chainId": 101,
            "address": "4oyFkXQhvvDFa8cvShR4zwhYJ1RfhRzQjVAx1wemmjs6",
            "symbol": "GRU",
            "name": "GURU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oyFkXQhvvDFa8cvShR4zwhYJ1RfhRzQjVAx1wemmjs6/logo.png",
            "extensions": {
                "website": "https://solanaguru.web.app"
            }
        },
        {
            "chainId": 101,
            "address": "8MpuLZGs52qdj4aGkgxrjjBbDQQd2dsKtbRpCpMb4g8o",
            "symbol": "TST",
            "name": "CHTST",
            "decimals": 9,
            "logoURI": ""
        },
        {
            "chainId": 101,
            "address": "4796pBun8ihiecy4unZuLWoSVRmK8sf7yQMbwkkvZDH3",
            "symbol": "BLOK",
            "name": "BLOCITIES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4796pBun8ihiecy4unZuLWoSVRmK8sf7yQMbwkkvZDH3/logo.png",
            "extensions": {
                "website": "https://blocities.finance"
            }
        },
        {
            "chainId": 101,
            "address": "C4kmKzQ8o6NAP8pToERJF6C7V4PjCVE3o2oSrp24f5GP",
            "symbol": "CERCr",
            "name": "Elemento6",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C4kmKzQ8o6NAP8pToERJF6C7V4PjCVE3o2oSrp24f5GP/logo.png",
            "extensions": {
                "website": "https://respectfuldevelopmentpanama.com"
            }
        },
        {
            "chainId": 101,
            "address": "6CuCUCYovcLxwaKuxWm8uTquVKGWaAydcFEU3NrtvxGZ",
            "symbol": "INTC",
            "name": "Intel Corp",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6CuCUCYovcLxwaKuxWm8uTquVKGWaAydcFEU3NrtvxGZ/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?INTC"
            }
        },
        {
            "chainId": 101,
            "address": "iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a",
            "symbol": "IVN",
            "name": "Investin Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a/logo.png",
            "tags": [
                "defi",
                "fund-management"
            ],
            "extensions": {
                "coingeckoId": "investin",
                "discord": "https://discord.com/invite/Yf54h9B",
                "medium": "https://medium.com/investin-pro",
                "serumV3Usdc": "AdmfUBJ64BTsjaZdtu1iQHAtxJ4Ge7Zw5bNMsrLGdZu7",
                "telegram": "https://t.me/Investin_pro1",
                "twitter": "https://twitter.com/Investin_pro",
                "website": "https://www.investin.pro/"
            }
        },
        {
            "chainId": 101,
            "address": "BTk1SU7EM3sv7u8iaeUjJahm5oeXhEFeC3GJ3KqjTkGL",
            "symbol": "RDP",
            "name": "Road Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTk1SU7EM3sv7u8iaeUjJahm5oeXhEFeC3GJ3KqjTkGL/logo.svg",
            "extensions": {
                "telegram": "https://t.me/roadprotocol",
                "twitter": "https://twitter.com/roadprotocol",
                "website": "https://roadprotocol.com"
            }
        },
        {
            "chainId": 101,
            "address": "7kWEmnn8sCDnB3gDyeNss8Jo8tgj7i3yHyKrYjbXV6Jd",
            "symbol": "WENA",
            "name": "WENATURE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kWEmnn8sCDnB3gDyeNss8Jo8tgj7i3yHyKrYjbXV6Jd/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/wenacurrency",
                "website": "https://wena.finance"
            }
        },
        {
            "chainId": 101,
            "address": "6H26K637YNAjZycRosvBR3ENKFGMsbr4xmoV7ca83GWf",
            "symbol": "JUST",
            "name": "Just Group PLC",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6H26K637YNAjZycRosvBR3ENKFGMsbr4xmoV7ca83GWf/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?JUST"
            }
        },
        {
            "chainId": 101,
            "address": "FFRtWiE8FT7HMf673r9cmpabHVQfa2QEf4rSRwNo4JM3",
            "symbol": "MRNA",
            "name": "Moderna",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFRtWiE8FT7HMf673r9cmpabHVQfa2QEf4rSRwNo4JM3/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?MRNA"
            }
        },
        {
            "chainId": 101,
            "address": "Hfbh3GU8AdYCw4stirFy2RPGtwQbbzToG2DgFozAymUb",
            "symbol": "NFLX",
            "name": "Netflix",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hfbh3GU8AdYCw4stirFy2RPGtwQbbzToG2DgFozAymUb/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?NFLX"
            }
        },
        {
            "chainId": 101,
            "address": "56Zwe8Crm4pXvmByCxmGDjYrLPxkenTrckdRM7WG3zQv",
            "symbol": "NIO",
            "name": "Nio",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56Zwe8Crm4pXvmByCxmGDjYrLPxkenTrckdRM7WG3zQv/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?NIO"
            }
        },
        {
            "chainId": 101,
            "address": "HP9WMRDV3KdUfJ7CNn5Wf8JzLczwxdnQYTHDAa9yCSnq",
            "symbol": "NOK",
            "name": "Nokia",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HP9WMRDV3KdUfJ7CNn5Wf8JzLczwxdnQYTHDAa9yCSnq/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?NOK"
            }
        },
        {
            "chainId": 101,
            "address": "GpM58T33eTrGEdHmeFnSVksJjJT6JVdTvim59ipTgTNh",
            "symbol": "NVDA",
            "name": "NVIDIA",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpM58T33eTrGEdHmeFnSVksJjJT6JVdTvim59ipTgTNh/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?NVDA"
            }
        },
        {
            "chainId": 101,
            "address": "CRCop5kHBDLTYJyG7z3u6yiVQi4FQHbyHdtb18Qh2Ta9",
            "symbol": "PENN",
            "name": "Penn National Gaming",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRCop5kHBDLTYJyG7z3u6yiVQi4FQHbyHdtb18Qh2Ta9/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?PENN"
            }
        },
        {
            "chainId": 101,
            "address": "97v2oXMQ2MMAkgUnoQk3rNhrZCRThorYhvz1poAe9stk",
            "symbol": "PFE",
            "name": "Pfizer",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97v2oXMQ2MMAkgUnoQk3rNhrZCRThorYhvz1poAe9stk/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?PFE"
            }
        },
        {
            "chainId": 101,
            "address": "AwutBmwmhehaMh18CxqFPPN311uCB1M2awp68A2bG41v",
            "symbol": "PYPL",
            "name": "PayPal",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AwutBmwmhehaMh18CxqFPPN311uCB1M2awp68A2bG41v/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?PYPL"
            }
        },
        {
            "chainId": 101,
            "address": "8Sa7BjogSJnkHyhtRTKNDDTDtASnWMcAsD4ySVNSFu27",
            "symbol": "SLV",
            "name": "iShares Silver Trust",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Sa7BjogSJnkHyhtRTKNDDTDtASnWMcAsD4ySVNSFu27/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?SLV"
            }
        },
        {
            "chainId": 101,
            "address": "CS4tNS523VCLiTsGnYEAd6GqfrZNLtA14C98DC6gE47g",
            "symbol": "SPY",
            "name": "SPDR S\u0026P 500 ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CS4tNS523VCLiTsGnYEAd6GqfrZNLtA14C98DC6gE47g/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?SPY"
            }
        },
        {
            "chainId": 101,
            "address": "BLyrWJuDyYnDaUMxqBMqkDYAeajnyode1ARh7TxtakEh",
            "symbol": "SQ",
            "name": "Square",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLyrWJuDyYnDaUMxqBMqkDYAeajnyode1ARh7TxtakEh/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?SQ"
            }
        },
        {
            "chainId": 101,
            "address": "HSDepE3xvbyRDx4M11LX7Hf9qgHSopfTXxAoeatCcwWF",
            "symbol": "SUN",
            "name": "Sunoco LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HSDepE3xvbyRDx4M11LX7Hf9qgHSopfTXxAoeatCcwWF/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?SUN"
            }
        },
        {
            "chainId": 101,
            "address": "LZufgu7ekMcWBUypPMBYia2ipnFzpxpZgRBFLhYswgR",
            "symbol": "TLRY",
            "name": "Tilray Inc",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LZufgu7ekMcWBUypPMBYia2ipnFzpxpZgRBFLhYswgR/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?TLRY"
            }
        },
        {
            "chainId": 101,
            "address": "2iCUKaCQpGvnaBimLprKWT8bNGF92e6LxWq4gjsteWfx",
            "symbol": "TSM",
            "name": "Taiwan Semiconductor Mfg",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2iCUKaCQpGvnaBimLprKWT8bNGF92e6LxWq4gjsteWfx/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?TSM"
            }
        },
        {
            "chainId": 101,
            "address": "H8Z1JgxqUpyHrerDVbXQ2HdjKuSTAWEZSeLTo4smWdF2",
            "symbol": "CPT",
            "name": "CryptoPotes Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H8Z1JgxqUpyHrerDVbXQ2HdjKuSTAWEZSeLTo4smWdF2/logo.svg",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/+EWjQtq9l3GBjYzc0"
            }
        },
        {
            "chainId": 101,
            "address": "BZMg4HyyHVUJkwh2yuv6duu4iQUaXRxT6sK1dT7FcaZf",
            "symbol": "TUR",
            "name": "iShares MSCI Turkey ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZMg4HyyHVUJkwh2yuv6duu4iQUaXRxT6sK1dT7FcaZf/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?TUR"
            }
        },
        {
            "chainId": 101,
            "address": "C2tNm8bMU5tz6KdXjHY5zewsN1Wv1TEbxK9XGTCgUZMJ",
            "symbol": "TWTR",
            "name": "Twitter",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C2tNm8bMU5tz6KdXjHY5zewsN1Wv1TEbxK9XGTCgUZMJ/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?TWTR"
            }
        },
        {
            "chainId": 101,
            "address": "4kmVbBDCzYam3S4e9XqKQkLCEz16gu3dTTo65KbhShuv",
            "symbol": "UBER",
            "name": "Uber",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4kmVbBDCzYam3S4e9XqKQkLCEz16gu3dTTo65KbhShuv/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?UBER"
            }
        },
        {
            "chainId": 101,
            "address": "J645gMdx9zSMM2VySLBrtv6Zv1HyEjPqQXVGRAPYqzvK",
            "symbol": "USO",
            "name": "United States Oil Fund",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J645gMdx9zSMM2VySLBrtv6Zv1HyEjPqQXVGRAPYqzvK/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?USO"
            }
        },
        {
            "chainId": 101,
            "address": "3LjkoC9FYEqRKNpy7xz3nxfnGVAt1SNS98rYwF2adQWB",
            "symbol": "VXX",
            "name": "iPath B S\u0026P 500 VIX S/T Futs ETN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3LjkoC9FYEqRKNpy7xz3nxfnGVAt1SNS98rYwF2adQWB/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?VXX"
            }
        },
        {
            "chainId": 101,
            "address": "BcALTCjD4HJJxBDUXi3nHUgqsJmXAQdBbQrcmtLtqZaf",
            "symbol": "ZM",
            "name": "Zoom",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BcALTCjD4HJJxBDUXi3nHUgqsJmXAQdBbQrcmtLtqZaf/logo.svg",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.digitalassets.ag/UnderlyingDetails?ZM"
            }
        },
        {
            "chainId": 101,
            "address": "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
            "symbol": "BTC",
            "name": "Wrapped Bitcoin (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "bitcoin",
                "serumV3Usdc": "A8YFbxQYFVqKZaoYJLLUVcQiWP7G2MeEgW5wsAQgMvFw",
                "serumV3Usdt": "C1EuT9VokAKLiW7i2ASnZUvxDoKuKkCpDDeNxAptuNe4"
            }
        },
        {
            "chainId": 101,
            "address": "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk",
            "symbol": "soETH",
            "name": "Wrapped Ethereum (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "ethereum",
                "serumV3Usdc": "4tSvZvnbyzHXLMTiFonMyxZoHmFqau1XArcRCVHLZ5gX",
                "serumV3Usdt": "7dLVkUfBVfCGkFhSXDCq1ukM9usathSgS716t643iFGF"
            }
        },
        {
            "chainId": 101,
            "address": "3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB",
            "symbol": "soYFI",
            "name": "Wrapped YFI (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "yearn-finance",
                "serumV3Usdc": "7qcCo8jqepnjjvB5swP4Afsr3keVBs6gNpBTNubd1Kr2",
                "serumV3Usdt": "3Xg9Q4VtZhD4bVYJbTfgGWFV5zjE3U7ztSHa938zizte"
            }
        },
        {
            "chainId": 101,
            "address": "CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG",
            "symbol": "soLINK",
            "name": "Wrapped Chainlink (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "chainlink",
                "serumV3Usdc": "3hwH1txjJVS8qv588tWrjHfRxdqNjBykM1kMcit484up",
                "serumV3Usdt": "3yEZ9ZpXSQapmKjLAGKZEzUNA1rcupJtsDp5mPBWmGZR"
            }
        },
        {
            "chainId": 101,
            "address": "Ga2AXHpfAF6mv2ekZwcsJFqu7wB4NV331qNH7fW9Nst8",
            "symbol": "XRP",
            "name": "Wrapped XRP (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ga2AXHpfAF6mv2ekZwcsJFqu7wB4NV331qNH7fW9Nst8/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "ripple"
            }
        },
        {
            "chainId": 101,
            "address": "BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4",
            "symbol": "soUSDT",
            "name": "Wrapped USDT (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png",
            "tags": [
                "stablecoin",
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy",
            "symbol": "soSUSHI",
            "name": "Wrapped SUSHI (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "sushi",
                "serumV3Usdc": "A1Q9iJDVVS8Wsswr9ajeZugmj64bQVCYLZQLra2TMBMo",
                "serumV3Usdt": "6DgQRTpJTnAYBSShngAVZZDq7j9ogRN1GfSQ3cq9tubW",
                "waterfallbot": "https://bit.ly/SUSHIwaterfall",
                "website": "https://www.sushi.com"
            }
        },
        {
            "chainId": 101,
            "address": "CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K",
            "symbol": "soALEPH",
            "name": "Wrapped ALEPH (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "aleph",
                "serumV3Usdc": "GcoKtAmTy5QyuijXSmJKBtFdt99e6Buza18Js7j9AJ6e",
                "serumV3Usdt": "Gyp1UGRgbrb6z8t7fpssxEKQgEmcJ4pVnWW3ds2p6ZPY"
            }
        },
        {
            "chainId": 101,
            "address": "SF3oTvfWzEP3DTwGSvUXRrGTvr75pdZNnBLAH9bzMuX",
            "symbol": "soSXP",
            "name": "Wrapped SXP (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SF3oTvfWzEP3DTwGSvUXRrGTvr75pdZNnBLAH9bzMuX/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "swipe",
                "serumV3Usdc": "4LUro5jaPaTurXK737QAxgJywdhABnFAMQkXX4ZyqqaZ",
                "serumV3Usdt": "8afKwzHR3wJE7W7Y5hvQkngXh6iTepSZuutRMMy96MjR"
            }
        },
        {
            "chainId": 101,
            "address": "BtZQfWqDGbk9Wf2rXEiWyQBdBY1etnUUn6zEphvVS7yN",
            "symbol": "soHGET",
            "name": "Wrapped Hedget (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtZQfWqDGbk9Wf2rXEiWyQBdBY1etnUUn6zEphvVS7yN/logo.svg",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "hedget",
                "serumV3Usdc": "88vztw7RTN6yJQchVvxrs6oXUDryvpv9iJaFa1EEmg87",
                "serumV3Usdt": "ErQXxiNfJgd4fqQ58PuEw5xY35TZG84tHT6FXf5s4UxY",
                "website": "https://www.hedget.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5Fu5UUgbjpUvdBveb3a1JTNirL8rXtiYeSMWvKjtUNQv",
            "symbol": "soCREAM",
            "name": "Wrapped Cream Finance (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Fu5UUgbjpUvdBveb3a1JTNirL8rXtiYeSMWvKjtUNQv/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "cream-2",
                "serumV3Usdc": "7nZP6feE94eAz9jmfakNJWPwEKaeezuKKC5D1vrnqyo2",
                "serumV3Usdt": "4ztJEvQyryoYagj2uieep3dyPwG2pyEwb2dKXTwmXe82"
            }
        },
        {
            "chainId": 101,
            "address": "873KLxCbz7s9Kc4ZzgYRtNmhfkQrhfyWGZJBmyCbC3ei",
            "symbol": "soUBXT",
            "name": "Wrapped Upbots (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/873KLxCbz7s9Kc4ZzgYRtNmhfkQrhfyWGZJBmyCbC3ei/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "coingeckoId": "upbots",
                "serumV3Usdc": "2wr3Ab29KNwGhtzr5HaPCyfU1qGJzTUAN4amCLZWaD1H",
                "serumV3Usdt": "F1T7b6pnR8Pge3qmfNUfW6ZipRDiGpMww6TKTrRU4NiL",
                "website": "https://upbots.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HqB7uswoVg4suaQiDP3wjxob1G5WdZ144zhdStwMCq7e",
            "symbol": "soHNT",
            "name": "Wrapped Helium (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HqB7uswoVg4suaQiDP3wjxob1G5WdZ144zhdStwMCq7e/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "helium",
                "serumV3Usdc": "CnUV42ZykoKUnMDdyefv5kP6nDSJf7jFd7WXAecC6LYr",
                "serumV3Usdt": "8FpuMGLtMZ7Wt9ZvyTGuTVwTwwzLYfS5NZWcHxbP1Wuh"
            }
        },
        {
            "chainId": 101,
            "address": "9S4t2NEAiJVMvPdRYKVrfJpBafPBLtvbvyS3DecojQHw",
            "symbol": "soFRONT",
            "name": "Wrapped FRONT (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9S4t2NEAiJVMvPdRYKVrfJpBafPBLtvbvyS3DecojQHw/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "frontier-token",
                "serumV3Usdc": "9Zx1CvxSVdroKMMWf2z8RwrnrLiQZ9VkQ7Ex3syQqdSH",
                "serumV3Usdt": "CGC4UgWwqA9PET6Tfx6o6dLv94EK2coVkPtxgNHuBtxj"
            }
        },
        {
            "chainId": 101,
            "address": "6WNVCuxCGJzNjmMZoKyhZJwvJ5tYpsLyAtagzYASqBoF",
            "symbol": "soAKRO",
            "name": "Wrapped AKRO (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6WNVCuxCGJzNjmMZoKyhZJwvJ5tYpsLyAtagzYASqBoF/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "akropolis",
                "serumV3Usdc": "5CZXTTgVZKSzgSA3AFMN5a2f3hmwmmJ6hU8BHTEJ3PX8",
                "serumV3Usdt": "HLvRdctRB48F9yLnu9E24LUTRt89D48Z35yi1HcxayDf"
            }
        },
        {
            "chainId": 101,
            "address": "DJafV9qemGp7mLMEn5wrfqaFwxsbLgUsGVS16zKRk9kc",
            "symbol": "soHXRO",
            "name": "Wrapped HXRO (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJafV9qemGp7mLMEn5wrfqaFwxsbLgUsGVS16zKRk9kc/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "hxro",
                "serumV3Usdc": "6Pn1cSiRos3qhBf54uBP9ZQg8x3JTardm1dL3n4p29tA",
                "serumV3Usdt": "4absuMsgemvdjfkgdLQq1zKEjw3dHBoCWkzKoctndyqd"
            }
        },
        {
            "chainId": 101,
            "address": "DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw",
            "symbol": "soUNI",
            "name": "Wrapped UNI (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "uniswap",
                "serumV3Usdc": "6JYHjaQBx6AtKSSsizDMwozAEDEZ5KBsSUzH7kRjGJon",
                "serumV3Usdt": "2SSnWNrc83otLpfRo792P6P3PESZpdr8cu2r8zCE6bMD"
            }
        },
        {
            "chainId": 101,
            "address": "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
            "symbol": "SRM",
            "name": "Serum",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png",
            "extensions": {
                "coingeckoId": "serum",
                "serumV3Usdc": "ByRys5tuUWDgL73G8JBAEfkdFf8JWBzPBDHsBVQ5vbQA",
                "serumV3Usdt": "AtNnsY1AyRERWJ8xCskfz38YdvruWVJQUVXgScC1iPb",
                "waterfallbot": "https://bit.ly/SRMwaterfall",
                "website": "https://projectserum.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3",
            "symbol": "soFTT",
            "name": "Wrapped FTT (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "assetContract": "https://etherscan.io/address/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "ftx-token",
                "serumV3Usdc": "2Pbh1CvRVku1TgewMfycemghf6sU9EyuFDcNXqvRmSxc",
                "serumV3Usdt": "Hr3wzG8mZXNHV7TuL6YqtgfVUesCqMxGYCEyP3otywZE",
                "waterfallbot": "https://bit.ly/FTTwaterfall"
            }
        },
        {
            "chainId": 101,
            "address": "MSRMcoVyrFxnSgo5uXwone5SKcGhT1KEJMFEkMEWf9L",
            "symbol": "MSRM",
            "name": "MegaSerum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MSRMcoVyrFxnSgo5uXwone5SKcGhT1KEJMFEkMEWf9L/logo.png",
            "extensions": {
                "coingeckoId": "megaserum",
                "serumV3Usdc": "4VKLSYdvrQ5ngQrt1d2VS8o4ewvb2MMUZLiejbnGPV33",
                "serumV3Usdt": "5nLJ22h1DUfeCfwbFxPYK8zbfbri7nA9bXoDcR8AcJjs",
                "website": "https://projectserum.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW",
            "symbol": "soUSDC",
            "name": "Wrapped USDC (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png",
            "tags": [
                "stablecoin",
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "GXMvfY2jpQctDqZ9RoU3oWPhufKiCcFEfchvYumtX7jd",
            "symbol": "soTOMO",
            "name": "Wrapped TOMO (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXMvfY2jpQctDqZ9RoU3oWPhufKiCcFEfchvYumtX7jd/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "tomochain",
                "serumV3Usdc": "8BdpjpSD5n3nk8DQLqPUyTZvVqFu6kcff5bzUX5dqDpy",
                "serumV3Usdt": "GnKPri4thaGipzTbp8hhSGSrHgG4F8MFiZVrbRn16iG2",
                "waterfallbot": "https://t.me/TOMOwaterfall"
            }
        },
        {
            "chainId": 101,
            "address": "EcqExpGNFBve2i1cMJUTR4bPXj4ZoqmDD2rTkeCcaTFX",
            "symbol": "soKARMA",
            "name": "Wrapped KARMA (Sollet)",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EcqExpGNFBve2i1cMJUTR4bPXj4ZoqmDD2rTkeCcaTFX/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "karma-dao"
            }
        },
        {
            "chainId": 101,
            "address": "EqWCKXfs3x47uVosDpTRgFniThL9Y8iCztJaapxbEaVX",
            "symbol": "soLUA",
            "name": "Wrapped LUA (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EqWCKXfs3x47uVosDpTRgFniThL9Y8iCztJaapxbEaVX/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "lua-token",
                "serumV3Usdc": "4xyWjQ74Eifq17vbue5Ut9xfFNfuVB116tZLEpiZuAn8",
                "serumV3Usdt": "35tV8UsHH8FnSAi3YFRrgCu4K9tb883wKnAXpnihot5r",
                "waterfallbot": "https://t.me/LUAwaterfall"
            }
        },
        {
            "chainId": 101,
            "address": "GeDS162t9yGJuLEHPWXXGrb1zwkzinCgRwnT8vHYjKza",
            "symbol": "soMATH",
            "name": "Wrapped MATH (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GeDS162t9yGJuLEHPWXXGrb1zwkzinCgRwnT8vHYjKza/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "math",
                "serumV3Usdc": "J7cPYBrXVy8Qeki2crZkZavcojf2sMRyQU7nx438Mf8t",
                "serumV3Usdt": "2WghiBkDL2yRhHdvm8CpprrkmfguuQGJTCDfPSudKBAZ"
            }
        },
        {
            "chainId": 101,
            "address": "GUohe4DJUA5FKPWo3joiPgsB7yzer7LpDmt1Vhzy3Zht",
            "symbol": "soKEEP",
            "name": "Wrapped KEEP (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUohe4DJUA5FKPWo3joiPgsB7yzer7LpDmt1Vhzy3Zht/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "keep-network",
                "serumV3Usdc": "3rgacody9SvM88QR83GHaNdEEx4Fe2V2ed5GJp2oeKDr",
                "serumV3Usdt": "HEGnaVL5i48ubPBqWAhodnZo8VsSLzEM3Gfc451DnFj9"
            }
        },
        {
            "chainId": 101,
            "address": "9F9fNTT6qwjsu4X4yWYKZpsbw5qT7o6yR2i57JF2jagy",
            "symbol": "soSWAG",
            "name": "Wrapped SWAG (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9F9fNTT6qwjsu4X4yWYKZpsbw5qT7o6yR2i57JF2jagy/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "swag-finance",
                "serumV3Usdt": "J2XSt77XWim5HwtUM8RUwQvmRXNZsbMKpp5GTKpHafvf"
            }
        },
        {
            "chainId": 101,
            "address": "DgHK9mfhMtUwwv54GChRrU54T2Em5cuszq2uMuen1ZVE",
            "symbol": "soCEL",
            "name": "Wrapped Celsius (Sollet)",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DgHK9mfhMtUwwv54GChRrU54T2Em5cuszq2uMuen1ZVE/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "celsius-degree-token",
                "serumV3Usdt": "cgani53cMZgYfRMgSrNekJTMaLmccRfspsfTbXWRg7u"
            }
        },
        {
            "chainId": 101,
            "address": "7ncCLJpP3MNww17LW8bRvx8odQQnubNtfNZBL5BgAEHW",
            "symbol": "soRSR",
            "name": "Wrapped Reserve Rights (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ncCLJpP3MNww17LW8bRvx8odQQnubNtfNZBL5BgAEHW/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "reserve-rights-token",
                "serumV3Usdt": "FcPet5fz9NLdbXwVM6kw2WTHzRAD7mT78UjwTpawd7hJ"
            }
        },
        {
            "chainId": 101,
            "address": "ChvvzHyRqCHnLVwMNz8amvQwgVLD8AELV7RgcFAxEhAf",
            "symbol": "DRIFT",
            "name": "DriftCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChvvzHyRqCHnLVwMNz8amvQwgVLD8AELV7RgcFAxEhAf/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/stKm5E9SuF",
                "telegram": "https://t.me/driftcoinorg",
                "twitter": "https://twitter.com/driftcoinorg",
                "website": "https://driftcoin.org"
            }
        },
        {
            "chainId": 101,
            "address": "GPF8ZZP6y1BkGAPLvmQCzkvVHvMUBko5e1pZzh8DD87Y",
            "symbol": "BECH",
            "name": "PrimeBech",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GPF8ZZP6y1BkGAPLvmQCzkvVHvMUBko5e1pZzh8DD87Y/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/doesitxz",
                "website": "https://www.primebech.app"
            }
        },
        {
            "chainId": 101,
            "address": "3yN3xNcXxbhkZYC6MXak1f7Ff29BZdGyc4GUQ1jbyt27",
            "symbol": "FRO",
            "name": "Fro",
            "decimals": 9,
            "logoURI": "https://www.frolicx0.de/public/assets/pngs/logo.png",
            "extensions": {
                "website": "https://www.frolic.live/"
            }
        },
        {
            "chainId": 101,
            "address": "5wihEYGca7X4gSe97C5mVcqNsfxBzhdTwpv72HKs25US",
            "symbol": "so1INCH",
            "name": "Wrapped 1INCH (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5wihEYGca7X4gSe97C5mVcqNsfxBzhdTwpv72HKs25US/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "1inch"
            }
        },
        {
            "chainId": 101,
            "address": "38i2NQxjp5rt5B3KogqrxmBxgrAwaB3W1f1GmiKqh9MS",
            "symbol": "soGRT",
            "name": "Wrapped GRT (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38i2NQxjp5rt5B3KogqrxmBxgrAwaB3W1f1GmiKqh9MS/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "the-graph"
            }
        },
        {
            "chainId": 101,
            "address": "Avz2fmevhhu87WYtWQCFj9UjKRjF9Z9QWwN2ih9yF95G",
            "symbol": "soCOMP",
            "name": "Wrapped Compound (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Avz2fmevhhu87WYtWQCFj9UjKRjF9Z9QWwN2ih9yF95G/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "compound-coin"
            }
        },
        {
            "chainId": 101,
            "address": "9wRD14AhdZ3qV8et3eBQVsrb3UoBZDUbJGyFckpTg8sj",
            "symbol": "soPAXG",
            "name": "Wrapped Paxos Gold (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9wRD14AhdZ3qV8et3eBQVsrb3UoBZDUbJGyFckpTg8sj/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "pax-gold"
            }
        },
        {
            "chainId": 101,
            "address": "AByXcTZwJHMtrKrvVsh9eFNB1pJaLDjCUR2ayvxBAAM2",
            "symbol": "soSTRONG",
            "name": "Wrapped Strong (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AByXcTZwJHMtrKrvVsh9eFNB1pJaLDjCUR2ayvxBAAM2/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "strong"
            }
        },
        {
            "chainId": 101,
            "address": "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
            "symbol": "FIDA",
            "name": "Bonfida",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp/logo.svg",
            "extensions": {
                "coingeckoId": "bonfida",
                "serumV3Usdc": "E14BKBhDWD4EuTkWj1ooZezesGxMW8LPCps4W5PuzZJo",
                "serumV3Usdt": "EbV7pPpEvheLizuYX3gUCvWM8iySbSRAhu2mQ5Vz2Mxf",
                "waterfallbot": "https://bit.ly/FIDAwaterfall",
                "website": "https://bonfida.com/"
            }
        },
        {
            "chainId": 101,
            "address": "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6",
            "symbol": "KIN",
            "name": "KIN",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6/logo.png",
            "extensions": {
                "coingeckoId": "kin",
                "serumV3Usdc": "Bn6NPyr6UzrFAwC4WmvPvDr2Vm8XSUnFykM2aQroedgn",
                "serumV3Usdt": "4nCFQr8sahhhL4XJ7kngGFBmpkmyf3xLzemuMhn6mWTm",
                "waterfallbot": "https://bit.ly/KINwaterfall"
            }
        },
        {
            "chainId": 101,
            "address": "MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb",
            "symbol": "MAPS",
            "name": "MAPS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb/logo.svg",
            "extensions": {
                "coingeckoId": "maps",
                "serumV3Usdc": "3A8XQRWXC7BjLpgLDDBhQJLT5yPCzS16cGYRKHkKxvYo",
                "serumV3Usdt": "7cknqHAuGpfVXPtFoJpFvUjJ8wkmyEfbFusmwMfNy3FE",
                "website": "https://maps.me/"
            }
        },
        {
            "chainId": 101,
            "address": "z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M",
            "symbol": "OXY",
            "name": "Oxygen Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M/logo.svg",
            "extensions": {
                "coingeckoId": "oxygen",
                "serumV3Usdc": "GZ3WBFsqntmERPwumFEYgrX2B7J7G11MzNZAy7Hje27X",
                "serumV3Usdt": "GKLev6UHeX1KSDCyo2bzyG6wqhByEzDBkmYTxEdmYJgB",
                "waterfallbot": "https://bit.ly/OXYwaterfall",
                "website": "https://www.oxygen.org/"
            }
        },
        {
            "chainId": 101,
            "address": "FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD",
            "symbol": "BRZ",
            "name": "BRZ",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD/logo.png",
            "extensions": {
                "coingeckoId": "brz",
                "website": "https://brztoken.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
            "symbol": "USDT",
            "name": "USDT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether",
                "serumV3Usdc": "77quYg4MGneUdjgXCunt9GgM1usmrxKY31twEy3WHwcS",
                "website": "https://tether.to/"
            }
        },
        {
            "chainId": 101,
            "address": "2oDxYGgTBmST4rc3yn1YtcSEck7ReDZ8wHWLqZAuNWXH",
            "symbol": "wxMARK_v1",
            "name": "Standard (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2oDxYGgTBmST4rc3yn1YtcSEck7ReDZ8wHWLqZAuNWXH/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x36b679bd64ed73dbfd88909cdcb892cb66bd4cbb",
                "assetContract": "https://etherscan.io/address/0x36b679bd64ed73dbfd88909cdcb892cb66bd4cbb",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "xmark",
                "website": "https://benchmarkprotocol.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
            "symbol": "RAY",
            "name": "Raydium",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png",
            "extensions": {
                "coingeckoId": "raydium",
                "serumV3Usdc": "2xiv8A5xrJ7RnGdxXB42uFEkYHJjszEhaJyKKt4WaLep",
                "serumV3Usdt": "teE55QrL4a4QSfydR9dnHF97jgCfptpuigbb53Lo95g",
                "waterfallbot": "https://bit.ly/RAYwaterfall",
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CzPDyvotTcxNqtPne32yUiEVQ6jk42HZi1Y3hUu7qf7f",
            "symbol": "RAY-WUSDT",
            "name": "Raydium Legacy LP Token V2 (RAY-WUSDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CzPDyvotTcxNqtPne32yUiEVQ6jk42HZi1Y3hUu7qf7f/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "134Cct3CSdRCbYgq5SkwmHgfwjJ7EM5cG9PzqffWqECx",
            "symbol": "RAY-SOL",
            "name": "Raydium Legacy LP Token V2 (RAY-SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/134Cct3CSdRCbYgq5SkwmHgfwjJ7EM5cG9PzqffWqECx/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EVDmwajM5U73PD34bYPugwiA4Eqqbrej4mLXXv15Z5qR",
            "symbol": "LINK-WUSDT",
            "name": "Raydium Legacy LP Token V2 (LINK-WUSDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EVDmwajM5U73PD34bYPugwiA4Eqqbrej4mLXXv15Z5qR/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "KY4XvwHy7JPzbWYAbk23jQvEb4qWJ8aCqYWREmk1Q7K",
            "symbol": "ETH-WUSDT",
            "name": "Raydium Legacy LP Token V2 (ETH-WUSDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KY4XvwHy7JPzbWYAbk23jQvEb4qWJ8aCqYWREmk1Q7K/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FgmBnsF5Qrnv8X9bomQfEtQTQjNNiBCWRKGpzPnE5BDg",
            "symbol": "RAY-USDC",
            "name": "Raydium Legacy LP Token V2 (RAY-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgmBnsF5Qrnv8X9bomQfEtQTQjNNiBCWRKGpzPnE5BDg/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5QXBMXuCL7zfAk39jEVVEvcrz1AvBGgT9wAhLLHLyyUJ",
            "symbol": "RAY-SRM",
            "name": "Raydium Legacy LP Token V2 (RAY-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5QXBMXuCL7zfAk39jEVVEvcrz1AvBGgT9wAhLLHLyyUJ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FdhKXYjCou2jQfgKWcNY7jb8F2DPLU1teTTTRfLBD2v1",
            "symbol": "RAY-WUSDT",
            "name": "Raydium Legacy LP Token V3 (RAY-WUSDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdhKXYjCou2jQfgKWcNY7jb8F2DPLU1teTTTRfLBD2v1/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BZFGfXMrjG2sS7QT2eiCDEevPFnkYYF7kzJpWfYxPbcx",
            "symbol": "RAY-USDC",
            "name": "Raydium Legacy LP Token V3 (RAY-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZFGfXMrjG2sS7QT2eiCDEevPFnkYYF7kzJpWfYxPbcx/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DSX5E21RE9FB9hM8Nh8xcXQfPK6SzRaJiywemHBSsfup",
            "symbol": "RAY-SRM",
            "name": "Raydium Legacy LP Token V3 (RAY-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DSX5E21RE9FB9hM8Nh8xcXQfPK6SzRaJiywemHBSsfup/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "F5PPQHGcznZ2FxD9JaxJMXaf7XkaFFJ6zzTBcW8osQjw",
            "symbol": "RAY-SOL",
            "name": "Raydium Legacy LP Token V3 (RAY-SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F5PPQHGcznZ2FxD9JaxJMXaf7XkaFFJ6zzTBcW8osQjw/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8Q6MKy5Yxb9vG1mWzppMtMb2nrhNuCRNUkJTeiE3fuwD",
            "symbol": "RAY-ETH",
            "name": "Raydium Legacy LP Token V3 (RAY-ETH)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Q6MKy5Yxb9vG1mWzppMtMb2nrhNuCRNUkJTeiE3fuwD/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DsBuznXRTmzvEdb36Dx3aVLVo1XmH7r1PRZUFugLPTFv",
            "symbol": "FIDA-RAY",
            "name": "Raydium LP Token V4 (FIDA-RAY)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DsBuznXRTmzvEdb36Dx3aVLVo1XmH7r1PRZUFugLPTFv/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FwaX9W7iThTZH5MFeasxdLpxTVxRcM7ZHieTCnYog8Yb",
            "symbol": "OXY-RAY",
            "name": "Raydium LP Token V4 (OXY-RAY)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FwaX9W7iThTZH5MFeasxdLpxTVxRcM7ZHieTCnYog8Yb/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CcKK8srfVdTSsFGV3VLBb2YDbzF4T4NM2C3UEjC39RLP",
            "symbol": "MAPS-RAY",
            "name": "Raydium LP Token V4 (MAPS-RAY)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CcKK8srfVdTSsFGV3VLBb2YDbzF4T4NM2C3UEjC39RLP/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CHT8sft3h3gpLYbCcZ9o27mT5s3Z6VifBVbUiDvprHPW",
            "symbol": "KIN-RAY",
            "name": "Raydium LP Token V4 (KIN-RAY)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHT8sft3h3gpLYbCcZ9o27mT5s3Z6VifBVbUiDvprHPW/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "C3sT1R3nsw4AVdepvLTLKr5Gvszr7jufyBWUCvy4TUvT",
            "symbol": "RAY-USDT",
            "name": "Raydium LP Token V4 (RAY-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C3sT1R3nsw4AVdepvLTLKr5Gvszr7jufyBWUCvy4TUvT/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8HoQnePLqPj4M7PUDzfw8e3Ymdwgc7NLGnaTUapubyvu",
            "symbol": "SOL-USDC",
            "name": "Raydium LP Token V4 (SOL-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8HoQnePLqPj4M7PUDzfw8e3Ymdwgc7NLGnaTUapubyvu/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "865j7iMmRRycSYUXzJ33ZcvLiX9JHvaLidasCyUyKaRE",
            "symbol": "YFI-USDC",
            "name": "Raydium LP Token V4 (YFI-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/865j7iMmRRycSYUXzJ33ZcvLiX9JHvaLidasCyUyKaRE/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9XnZd82j34KxNLgQfz29jGbYdxsYznTWRpvZE3SRE7JG",
            "symbol": "SRM-USDC",
            "name": "Raydium LP Token V4 (SRM-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XnZd82j34KxNLgQfz29jGbYdxsYznTWRpvZE3SRE7JG/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "75dCoKfUHLUuZ4qEh46ovsxfgWhB4icc3SintzWRedT9",
            "symbol": "FTT-USDC",
            "name": "Raydium LP Token V4 (FTT-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75dCoKfUHLUuZ4qEh46ovsxfgWhB4icc3SintzWRedT9/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2hMdRdVWZqetQsaHG8kQjdZinEMBz75vsoWTCob1ijXu",
            "symbol": "BTC-USDC",
            "name": "Raydium LP Token V4 (BTC-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2hMdRdVWZqetQsaHG8kQjdZinEMBz75vsoWTCob1ijXu/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2QVjeR9d2PbSf8em8NE8zWd8RYHjFtucDUdDgdbDD2h2",
            "symbol": "SUSHI-USDC",
            "name": "Raydium LP Token V4 (SUSHI-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2QVjeR9d2PbSf8em8NE8zWd8RYHjFtucDUdDgdbDD2h2/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CHyUpQFeW456zcr5XEh4RZiibH8Dzocs6Wbgz9aWpXnQ",
            "symbol": "TOMO-USDC",
            "name": "Raydium LP Token V4 (TOMO-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHyUpQFeW456zcr5XEh4RZiibH8Dzocs6Wbgz9aWpXnQ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BqjoYjqKrXtfBKXeaWeAT5sYCy7wsAYf3XjgDWsHSBRs",
            "symbol": "LINK-USDC",
            "name": "Raydium LP Token V4 (LINK-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BqjoYjqKrXtfBKXeaWeAT5sYCy7wsAYf3XjgDWsHSBRs/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "13PoKid6cZop4sj2GfoBeujnGfthUbTERdE5tpLCDLEY",
            "symbol": "ETH-USDC",
            "name": "Raydium LP Token V4 (ETH-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/13PoKid6cZop4sj2GfoBeujnGfthUbTERdE5tpLCDLEY/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2Vyyeuyd15Gp8aH6uKE72c4hxc8TVSLibxDP9vzspQWG",
            "symbol": "COPE-USDC",
            "name": "Raydium LP Token V4 (COPE-USDC)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Vyyeuyd15Gp8aH6uKE72c4hxc8TVSLibxDP9vzspQWG/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Epm4KfTj4DMrvqn6Bwg2Tr2N8vhQuNbuK8bESFp4k33K",
            "symbol": "SOL-USDT",
            "name": "Raydium LP Token V4 (SOL-USDT)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Epm4KfTj4DMrvqn6Bwg2Tr2N8vhQuNbuK8bESFp4k33K/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FA1i7fej1pAbQbnY8NbyYUsTrWcasTyipKreDgy1Mgku",
            "symbol": "YFI-USDT",
            "name": "Raydium LP Token V4 (YFI-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FA1i7fej1pAbQbnY8NbyYUsTrWcasTyipKreDgy1Mgku/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HYSAu42BFejBS77jZAZdNAWa3iVcbSRJSzp3wtqCbWwv",
            "symbol": "SRM-USDT",
            "name": "Raydium LP Token V4 (SRM-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYSAu42BFejBS77jZAZdNAWa3iVcbSRJSzp3wtqCbWwv/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2cTCiUnect5Lap2sk19xLby7aajNDYseFhC9Pigou11z",
            "symbol": "FTT-USDT",
            "name": "Raydium LP Token V4 (FTT-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cTCiUnect5Lap2sk19xLby7aajNDYseFhC9Pigou11z/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DgGuvR9GSHimopo3Gc7gfkbKamLKrdyzWkq5yqA6LqYS",
            "symbol": "BTC-USDT",
            "name": "Raydium LP Token V4 (BTC-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DgGuvR9GSHimopo3Gc7gfkbKamLKrdyzWkq5yqA6LqYS/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Ba26poEYDy6P2o95AJUsewXgZ8DM9BCsmnU9hmC9i4Ki",
            "symbol": "SUSHI-USDT",
            "name": "Raydium LP Token V4 (SUSHI-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ba26poEYDy6P2o95AJUsewXgZ8DM9BCsmnU9hmC9i4Ki/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D3iGro1vn6PWJXo9QAPj3dfta6dKkHHnmiiym2EfsAmi",
            "symbol": "TOMO-USDT",
            "name": "Raydium LP Token V4 (TOMO-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3iGro1vn6PWJXo9QAPj3dfta6dKkHHnmiiym2EfsAmi/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Dr12Sgt9gkY8WU5tRkgZf1TkVWJbvjYuPAhR3aDCwiiX",
            "symbol": "LINK-USDT",
            "name": "Raydium LP Token V4 (LINK-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dr12Sgt9gkY8WU5tRkgZf1TkVWJbvjYuPAhR3aDCwiiX/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "nPrB78ETY8661fUgohpuVusNCZnedYCgghzRJzxWnVb",
            "symbol": "ETH-USDT",
            "name": "Raydium LP Token V4 (ETH-USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nPrB78ETY8661fUgohpuVusNCZnedYCgghzRJzxWnVb/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EGJht91R7dKpCj8wzALkjmNdUUUcQgodqWCYweyKcRcV",
            "symbol": "YFI-SRM",
            "name": "Raydium LP Token V4 (YFI-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EGJht91R7dKpCj8wzALkjmNdUUUcQgodqWCYweyKcRcV/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AsDuPg9MgPtt3jfoyctUCUgsvwqAN6RZPftqoeiPDefM",
            "symbol": "FTT-SRM",
            "name": "Raydium LP Token V4 (FTT-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsDuPg9MgPtt3jfoyctUCUgsvwqAN6RZPftqoeiPDefM/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AGHQxXb3GSzeiLTcLtXMS2D5GGDZxsB2fZYZxSB5weqB",
            "symbol": "BTC-SRM",
            "name": "Raydium LP Token V4 (BTC-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGHQxXb3GSzeiLTcLtXMS2D5GGDZxsB2fZYZxSB5weqB/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3HYhUnUdV67j1vn8fu7ExuVGy5dJozHEyWvqEstDbWwE",
            "symbol": "SUSHI-SRM",
            "name": "Raydium LP Token V4 (SUSHI-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HYhUnUdV67j1vn8fu7ExuVGy5dJozHEyWvqEstDbWwE/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GgH9RnKrQpaMQeqmdbMvs5oo1A24hERQ9wuY2pSkeG7x",
            "symbol": "TOMO-SRM",
            "name": "Raydium LP Token V4 (TOMO-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GgH9RnKrQpaMQeqmdbMvs5oo1A24hERQ9wuY2pSkeG7x/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GXN6yJv12o18skTmJXaeFXZVY1iqR18CHsmCT8VVCmDD",
            "symbol": "LINK-SRM",
            "name": "Raydium LP Token V4 (LINK-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXN6yJv12o18skTmJXaeFXZVY1iqR18CHsmCT8VVCmDD/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9VoY3VERETuc2FoadMSYYizF26mJinY514ZpEzkHMtwG",
            "symbol": "ETH-SRM",
            "name": "Raydium LP Token V4 (ETH-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9VoY3VERETuc2FoadMSYYizF26mJinY514ZpEzkHMtwG/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AKJHspCwDhABucCxNLXUSfEzb7Ny62RqFtC9uNjJi4fq",
            "symbol": "SRM-SOL",
            "name": "Raydium LP Token V4 (SRM-SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKJHspCwDhABucCxNLXUSfEzb7Ny62RqFtC9uNjJi4fq/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2doeZGLJyACtaG9DCUyqMLtswesfje1hjNA11hMdj6YU",
            "symbol": "TULIP-USDC",
            "name": "Raydium LP Token V4 (TULIP-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2doeZGLJyACtaG9DCUyqMLtswesfje1hjNA11hMdj6YU/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AcstFzGGawvvdVhYV9bftr7fmBHbePUjhv53YK1W3dZo",
            "symbol": "LSD",
            "name": "LSD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AcstFzGGawvvdVhYV9bftr7fmBHbePUjhv53YK1W3dZo/logo.svg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "91fSFQsPzMLat9DHwLdQacW3i3EGnWds5tA5mt7yLiT9",
            "symbol": "Unlimited Energy",
            "name": "Unlimited Energy",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "29PEpZeuqWf9tS2gwCjpeXNdXLkaZSMR2s1ibkvGsfnP",
            "symbol": "Need for Speed",
            "name": "Need for Speed",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HsY8PNar8VExU335ZRYzg89fX7qa4upYu6vPMPFyCDdK",
            "symbol": "ADOR OPENS",
            "name": "ADOR OPENS",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EDP8TpLJ77M3KiDgFkZW4v4mhmKJHZi9gehYXenfFZuL",
            "symbol": "CMS - Rare",
            "name": "CMS - Rare",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BrUKFwAABkExb1xzYU4NkRWzjBihVQdZ3PBz4m5S8if3",
            "symbol": "Tesla",
            "name": "Tesla",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9CmQwpvVXRyixjiE3LrbSyyopPZohNDN1RZiTk8rnXsQ",
            "symbol": "DeceFi",
            "name": "DeceFi",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "F6ST1wWkx2PeH45sKmRxo1boyuzzWCfpnvyKL4BGeLxF",
            "symbol": "Power User",
            "name": "Power User",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "dZytJ7iPDcCu9mKe3srL7bpUeaR3zzkcVqbtqsmxtXZ",
            "symbol": "VIP Member",
            "name": "VIP Member",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8T4vXgwZUWwsbCDiptHFHjdfexvLG9UP8oy1psJWEQdS",
            "symbol": "Uni Christmas",
            "name": "Uni Christmas",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EjFGGJSyp9UDS8aqafET5LX49nsG326MeNezYzpiwgpQ",
            "symbol": "BNB",
            "name": "BNB",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FkmkTr4en8CXkfo9jAwEMov6PVNLpYMzWr3Udqf9so8Z",
            "symbol": "Seldom",
            "name": "Seldom",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2gn1PJdMAU92SU5inLSp4Xp16ZC5iLF6ScEi7UBvp8ZD",
            "symbol": "Satoshi Closeup",
            "name": "Satoshi Closeup",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7mhZHtPL4GFkquQR4Y6h34Q8hNkQvGc1FaNtyE43NvUR",
            "symbol": "Satoshi GB",
            "name": "Satoshi GB",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8RoKfLx5RCscbtVh8kYb81TF7ngFJ38RPomXtUREKsT2",
            "symbol": "Satoshi OG",
            "name": "Satoshi OG",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9rw5hyDngBQ3yDsCRHqgzGHERpU2zaLh1BXBUjree48J",
            "symbol": "Satoshi BTC",
            "name": "Satoshi BTC",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AiD7J6D5Hny5DJB1MrYBc2ePQqy2Yh4NoxWwYfR7PzxH",
            "symbol": "Satoshi GB",
            "name": "Satoshi GB",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4qzEcYvT6TuJME2EMZ5vjaLvQja6R4hKjarA73WQUwt6",
            "symbol": "APESZN_HOODIE",
            "name": "APESZN_HOODIE",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "APhyVWtzjdTVYhyta9ngSiCDk2pLi8eEZKsHGSbsmwv6",
            "symbol": "APESZN_TEE_SHIRT",
            "name": "APESZN_TEE_SHIRT",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "bxiA13fpU1utDmYuUvxvyMT8odew5FEm96MRv7ij3eb",
            "symbol": "Satoshi",
            "name": "Satoshi",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GoC24kpj6TkvjzspXrjSJC2CVb5zMWhLyRcHJh9yKjRF",
            "symbol": "Satoshi Closeup",
            "name": "Satoshi Closeup",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "oCUduD44ETuZ65bpWdPzPDSnAdreg1sJrugfwyFZVHV",
            "symbol": "Satoshi BTC",
            "name": "Satoshi BTC",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9Vvre2DxBB9onibwYDHeMsY1cj6BDKtEDccBPWRN215E",
            "symbol": "Satoshi Nakamoto",
            "name": "Satoshi Nakamoto",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7RpFk44cMTAUt9CcjEMWnZMypE9bYQsjBiSNLn5qBvhP",
            "symbol": "Charles Hoskinson",
            "name": "Charles Hoskinson",
            "decimals": 9,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GyRkPAxpd9XrMHcBF6fYHVRSZQvQBwAGKAGQeBPSKzMq",
            "symbol": "SBF",
            "name": "SBF",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AgdBQN2Sy2abiZ2KToWeUsQ9PHdCv95wt6kVWRf5zDkx",
            "symbol": "Bitcoin Tram",
            "name": "Bitcoin Tram",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7TRzvCqXN8KSXggbSyeEG2Z9YBBhEFmbtmv6FLbd4mmd",
            "symbol": "SRM tee-shirt",
            "name": "SRM tee-shirt",
            "decimals": 0,
            "logoURI": "",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://solible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "gksYzxitEf2HyE7Bb81vvHXNH5f3wa43jvXf4TcUZwb",
            "symbol": "PERK",
            "name": "PERK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gksYzxitEf2HyE7Bb81vvHXNH5f3wa43jvXf4TcUZwb/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/PerkExchange",
                "website": "https://perk.exchange/"
            }
        },
        {
            "chainId": 101,
            "address": "BDxWSxkMLW1nJ3VggamUKkEKrtCaVqzFxoDApM8HdBks",
            "symbol": "BTSG",
            "name": "BitSong",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDxWSxkMLW1nJ3VggamUKkEKrtCaVqzFxoDApM8HdBks/logo.png",
            "extensions": {
                "coingeckoId": "bitsong",
                "website": "https://bitsong.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5ddiFxh3J2tcZHfn8uhGRYqu16P3FUvBfh8WoZPUHKW5",
            "symbol": "EOSBEAR",
            "name": "EOSBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-eos-token",
                "serumV3Usdc": "2BQrJP599QVKRyHhyJ6oRrTPNUmPBgXxiBo2duvYdacy"
            }
        },
        {
            "chainId": 101,
            "address": "qxxF6S62hmZF5bo46mS7C2qbBa87qRossAM78VzsDqi",
            "symbol": "EOSBULL",
            "name": "EOSBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-eos-token"
            }
        },
        {
            "chainId": 101,
            "address": "2CDLbxeuqkLTLY3em6FFQgfBQV5LRnEsJJgcFCvWKNcS",
            "symbol": "BNBBEAR",
            "name": "BNBBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-bnb-token"
            }
        },
        {
            "chainId": 101,
            "address": "AfjHjdLibuXyvmz7PyTSc5KEcGBh43Kcu8Sr2tyDaJyt",
            "symbol": "BNBBULL",
            "name": "BNBBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-bnb-token"
            }
        },
        {
            "chainId": 101,
            "address": "8kA1WJKoLTxtACNPkvW6UNufsrpxUY57tXZ9KmG9123t",
            "symbol": "BSVBULL",
            "name": "BSVBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-bitcoin-sv-token"
            }
        },
        {
            "chainId": 101,
            "address": "2FGW8BVMu1EHsz2ZS9rZummDaq6o2DVrZZPw4KaAvDWh",
            "symbol": "BSVBEAR",
            "name": "BSVBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-bitcoin-sv-token"
            }
        },
        {
            "chainId": 101,
            "address": "8L9XGTMzcqS9p61zsR35t7qipwAXMYkD6disWoDFZiFT",
            "symbol": "LTCBEAR",
            "name": "LTCBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-litecoin-token"
            }
        },
        {
            "chainId": 101,
            "address": "863ZRjf1J8AaVuCqypAdm5ktVyGYDiBTvD1MNHKrwyjp",
            "symbol": "LTCBULL",
            "name": "LTCBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-litecoin-token"
            }
        },
        {
            "chainId": 101,
            "address": "GkSPaHdY2raetuYzsJYacHtrAtQUfWt64bpd1VzxJgSD",
            "symbol": "BULL",
            "name": "BULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-bitcoin-token"
            }
        },
        {
            "chainId": 101,
            "address": "45vwTZSDFBiqCMRdtK4xiLCHEov8LJRW8GwnofG8HYyH",
            "symbol": "BEAR",
            "name": "BEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-bitcoin-token"
            }
        },
        {
            "chainId": 101,
            "address": "2VTAVf1YCwamD3ALMdYHRMV5vPUCXdnatJH5f1khbmx6",
            "symbol": "BCHBEAR",
            "name": "BCHBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-bitcoin-cash-token"
            }
        },
        {
            "chainId": 101,
            "address": "22xoSp66BDt4x4Q5xqxjaSnirdEyharoBziSFChkLFLy",
            "symbol": "BCHBULL",
            "name": "BCHBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-bitcoin-cash-token"
            }
        },
        {
            "chainId": 101,
            "address": "CwChm6p9Q3yFrjzVeiLTTbsoJkooscof5SJYZc2CrNqG",
            "symbol": "ETHBULL",
            "name": "ETHBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-ethereum-token",
                "serumV3Usdt": "FuhKVt5YYCv7vXnADXtb7vqzYn82PJoap86q5wm8LX8Q"
            }
        },
        {
            "chainId": 101,
            "address": "Bvv9xLodFrvDFSno9Ud8SEh5zVtBDQQjnBty2SgMcJ2s",
            "symbol": "ETHBEAR",
            "name": "ETHBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-ethereum-token"
            }
        },
        {
            "chainId": 101,
            "address": "HRhaNssoyv5tKFRcbPg69ULEbcD8DPv99GdXLcdkgc1A",
            "symbol": "ALTBULL",
            "name": "ALTBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-altcoin-index-token"
            }
        },
        {
            "chainId": 101,
            "address": "9Mu1KmjBKTUWgpDoeTJ5oD7XFQmEiZxzspEd3TZGkavx",
            "symbol": "ALTBEAR",
            "name": "ALTBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-altcoin-index-token"
            }
        },
        {
            "chainId": 101,
            "address": "AYL1adismZ1U9pTuN33ahG4aYc5XTZQL4vKFx9ofsGWD",
            "symbol": "BULLSHIT",
            "name": "BULLSHIT",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-shitcoin-index-token"
            }
        },
        {
            "chainId": 101,
            "address": "5jqymuoXXVcUuJKrf1MWiHSqHyg2osMaJGVy69NsJWyP",
            "symbol": "BEARSHIT",
            "name": "BEARSHIT",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-shitcoin-index-token"
            }
        },
        {
            "chainId": 101,
            "address": "EL1aDTnLKjf4SaGpqtxJPyK94imSBr8fWDbcXjXQrsmj",
            "symbol": "MIDBULL",
            "name": "MIDBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-midcap-index-token",
                "serumV3Usdc": "8BBtLkoaEyavREriwGUudzAcihTH9SJLAPBbgb7QZe9y"
            }
        },
        {
            "chainId": 101,
            "address": "2EPvVjHusU3ozoucmdhhnqv3HQtBsQmjTnSa87K91HkC",
            "symbol": "MIDBEAR",
            "name": "MIDBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-midcap-index-token"
            }
        },
        {
            "chainId": 101,
            "address": "8TCfJTyeqNBZqyDMY4VwDY7kdCCY7pcbJJ58CnKHkMu2",
            "symbol": "LINKBEAR",
            "name": "LINKBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-chainlink-token"
            }
        },
        {
            "chainId": 101,
            "address": "EsUoZMbACNMppdqdmuLCFLet8VXxt2h47N9jHCKwyaPz",
            "symbol": "LINKBULL",
            "name": "LINKBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-chainlink-token"
            }
        },
        {
            "chainId": 101,
            "address": "262cQHT3soHwzuo2oVSy5kAfHcFZ1Jjn8C1GRLcQNKA3",
            "symbol": "XRPBULL",
            "name": "XRPBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-xrp-token"
            }
        },
        {
            "chainId": 101,
            "address": "5AX3ZyDN1rpamEzHpLfsJ5t6TyNECKSwPRfnzVHVuRFj",
            "symbol": "YUMZ",
            "name": "Food tasting Social Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/yu-ming-chen/yumzToken/logo.JPG",
            "tags": [
                "social-token",
                "yumz"
            ]
        },
        {
            "chainId": 101,
            "address": "8sxtSswmQ7Lcd2GjK6am37Z61wJZjA2SzE7Luf7yaKBB",
            "symbol": "XRPBEAR",
            "name": "XRPBEAR",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bear"
            ],
            "extensions": {
                "coingeckoId": "3x-short-xrp-token"
            }
        },
        {
            "chainId": 101,
            "address": "91z91RukFM16hyEUCXuwMQwp2BW3vanNG5Jh5yj6auiJ",
            "symbol": "BVOL",
            "name": "BVOL",
            "decimals": 6,
            "logoURI": "",
            "extensions": {
                "coingeckoId": "1x-long-btc-implied-volatility-token"
            }
        },
        {
            "chainId": 101,
            "address": "5TY71D29Cyuk9UrsSxLXw2quJBpS7xDDFuFu2K9W7Wf9",
            "symbol": "IBVOL",
            "name": "IBlive",
            "decimals": 6,
            "logoURI": "",
            "extensions": {
                "coingeckoId": "1x-short-btc-implied-volatility"
            }
        },
        {
            "chainId": 101,
            "address": "dK83wTVypEpa1pqiBbHY3MNuUnT3ADUZM4wk9VZXZEc",
            "symbol": "soAAVE",
            "name": "Wrapped Aave (Sollet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/dK83wTVypEpa1pqiBbHY3MNuUnT3ADUZM4wk9VZXZEc/logo.png",
            "extensions": {
                "coingeckoId": "aave",
                "serumV3Usdt": "6bxuB5N3bt3qW8UnPNLgMMzDq5sEH8pFmYJYGgzvE11V"
            }
        },
        {
            "chainId": 101,
            "address": "A6aY2ceogBz1VaXBxm1j2eJuNZMRqrWUAnKecrMH85zj",
            "symbol": "LQID",
            "name": "LQID",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A6aY2ceogBz1VaXBxm1j2eJuNZMRqrWUAnKecrMH85zj/logo.svg"
        },
        {
            "chainId": 101,
            "address": "7CnFGR9mZWyAtWxPcVuTewpyC3A3MDW4nLsu5NY6PDbd",
            "symbol": "SECO",
            "name": "SECO",
            "decimals": 6,
            "logoURI": "",
            "extensions": {
                "coingeckoId": "serum-ecosystem-token"
            }
        },
        {
            "chainId": 101,
            "address": "3GECTP7H4Tww3w8jEPJCJtXUtXxiZty31S9szs84CcwQ",
            "symbol": "HOLY",
            "name": "HOLY",
            "decimals": 6,
            "logoURI": "",
            "extensions": {
                "coingeckoId": "holy-trinity"
            }
        },
        {
            "chainId": 101,
            "address": "6ry4WBDvAwAnrYJVv6MCog4J8zx6S3cPgSqnTsDZ73AR",
            "symbol": "TRYB",
            "name": "TRYB",
            "decimals": 6,
            "logoURI": "",
            "extensions": {
                "coingeckoId": "bilira",
                "serumV3Usdt": "AADohBGxvf7bvixs2HKC3dG2RuU3xpZDwaTzYFJThM8U"
            }
        },
        {
            "chainId": 101,
            "address": "ASboaJPFtJeCS5eG4gL3Lg95xrTz2UZSLE9sdJtY93kE",
            "symbol": "DOGEBULL",
            "name": "DOGEBULL",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "leveraged",
                "bull"
            ],
            "extensions": {
                "coingeckoId": "3x-long-dogecoin-token"
            }
        },
        {
            "chainId": 101,
            "address": "Gnhy3boBT4MA8TTjGip5ND2uNsceh1Wgeaw1rYJo51ZY",
            "symbol": "MAPSPOOL",
            "name": "Bonfida Maps Pool",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gnhy3boBT4MA8TTjGip5ND2uNsceh1Wgeaw1rYJo51ZY/logo.svg",
            "extensions": {
                "website": "https://bonfida.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9iDWyYZ5VHBCxxmWZogoY3Z6FSbKsX4WFe37c728krdT",
            "symbol": "OXYPOOL",
            "name": "Bonfida Oxy Pool",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iDWyYZ5VHBCxxmWZogoY3Z6FSbKsX4WFe37c728krdT/logo.svg",
            "extensions": {
                "website": "https://bonfida.com/"
            }
        },
        {
            "chainId": 101,
            "address": "D68NB5JkzvyNCZAvi6EGtEcGvSoRNPanU9heYTAUFFRa",
            "symbol": "PERP",
            "name": "PERP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D68NB5JkzvyNCZAvi6EGtEcGvSoRNPanU9heYTAUFFRa/logo.png",
            "extensions": {
                "coingeckoId": "perpetual-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "4fiysjiegD8yoqHX75YXBvhBzmaUEQhyuAVRX8fGL3F1",
            "symbol": "RAYPOOL",
            "name": "Bonfida Ray Pool",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/93a1L7xaEV7vZGzNXCcb9ztZedbpKgUiTHYxmFKJwKvc/logo.png",
            "extensions": {
                "website": "https://bonfida.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf",
            "symbol": "wWETH_v1",
            "name": "Wrapped Ether (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
                "assetContract": "https://etherscan.io/address/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "ethereum"
            }
        },
        {
            "chainId": 101,
            "address": "FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T",
            "symbol": "wCAPS_v1",
            "name": "Wrapped Capsule Coin (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2",
                "assetContract": "https://etherscan.io/address/0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "coin-capsule",
                "website": "https://www.ternoa.com/en"
            }
        },
        {
            "chainId": 101,
            "address": "GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi",
            "symbol": "wFTT_v1",
            "name": "FTT (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
                "assetContract": "https://etherscan.io/address/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "ftx-token"
            }
        },
        {
            "chainId": 101,
            "address": "AbLwQCyU9S8ycJgu8wn6woRCHSYJmjMpJFcAHQ6vjq2P",
            "symbol": "wTUSD_v1",
            "name": "TrueUSD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AbLwQCyU9S8ycJgu8wn6woRCHSYJmjMpJFcAHQ6vjq2P/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0000000000085d4780B73119b644AE5ecd22b376",
                "assetContract": "https://etherscan.io/address/0x0000000000085d4780B73119b644AE5ecd22b376",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "true-usd"
            }
        },
        {
            "chainId": 101,
            "address": "3JfuyCg5891hCX1ZTbvt3pkiaww3XwgyqQH6E9eHtqKD",
            "symbol": "wLON_v1",
            "name": "Tokenlon (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JfuyCg5891hCX1ZTbvt3pkiaww3XwgyqQH6E9eHtqKD/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0000000000095413afC295d19EDeb1Ad7B71c952",
                "assetContract": "https://etherscan.io/address/0x0000000000095413afC295d19EDeb1Ad7B71c952",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "tokenlon"
            }
        },
        {
            "chainId": 101,
            "address": "6k7mrqiAqEWnABVN8FhfuNUrmrnaMh44nNWydNXctbpV",
            "symbol": "wALBT_v1",
            "name": "AllianceBlock Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6k7mrqiAqEWnABVN8FhfuNUrmrnaMh44nNWydNXctbpV/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x00a8b738E453fFd858a7edf03bcCfe20412f0Eb0",
                "assetContract": "https://etherscan.io/address/0x00a8b738E453fFd858a7edf03bcCfe20412f0Eb0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "allianceblock"
            }
        },
        {
            "chainId": 101,
            "address": "4b166BQEQunjg8oNTDcLeWU3nidQnVTL1Vni8ANU7Mvt",
            "symbol": "wSKL_v1",
            "name": "SKALE (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4b166BQEQunjg8oNTDcLeWU3nidQnVTL1Vni8ANU7Mvt/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7",
                "assetContract": "https://etherscan.io/address/0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "skale"
            }
        },
        {
            "chainId": 101,
            "address": "CcHhpEx9VcWx7UBJC8DJaR5h3wNdexsQtB1nEfekjSHn",
            "symbol": "wUFT_v1",
            "name": "UniLend Finance Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CcHhpEx9VcWx7UBJC8DJaR5h3wNdexsQtB1nEfekjSHn/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0202Be363B8a4820f3F4DE7FaF5224fF05943AB1",
                "assetContract": "https://etherscan.io/address/0x0202Be363B8a4820f3F4DE7FaF5224fF05943AB1",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "unlend-finance"
            }
        },
        {
            "chainId": 101,
            "address": "VPjCJkR1uZGT9k9q7PsLArS5sEQtWgij8eZC8tysCy7",
            "symbol": "wORN_v1",
            "name": "Orion Protocol (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VPjCJkR1uZGT9k9q7PsLArS5sEQtWgij8eZC8tysCy7/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a",
                "assetContract": "https://etherscan.io/address/0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "orion-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "CxzHZtzrm6bAz6iFCAGgCYCd3iQb5guUD7oQXKxdgk5c",
            "symbol": "wSRK_v1",
            "name": "SparkPoint (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CxzHZtzrm6bAz6iFCAGgCYCd3iQb5guUD7oQXKxdgk5c/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0488401c3F535193Fa8Df029d9fFe615A06E74E6",
                "assetContract": "https://etherscan.io/address/0x0488401c3F535193Fa8Df029d9fFe615A06E74E6",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "sparkpoint"
            }
        },
        {
            "chainId": 101,
            "address": "FqMZWvmii4NNzhLBKGzkvGj3e3XTxNVDNSKDJnt9fVQV",
            "symbol": "wUMA_v1",
            "name": "UMA Voting Token v1 (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FqMZWvmii4NNzhLBKGzkvGj3e3XTxNVDNSKDJnt9fVQV/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828",
                "assetContract": "https://etherscan.io/address/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "uma"
            }
        },
        {
            "chainId": 101,
            "address": "6GGNzF99kCG1ozQbP7M7EYW9zPbQGPMwTCCi2Dqx3qhU",
            "symbol": "wSkey_v1",
            "name": "SmartKey (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6GGNzF99kCG1ozQbP7M7EYW9zPbQGPMwTCCi2Dqx3qhU/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x06A01a4d579479Dd5D884EBf61A31727A3d8D442",
                "assetContract": "https://etherscan.io/address/0x06A01a4d579479Dd5D884EBf61A31727A3d8D442",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "smartkey"
            }
        },
        {
            "chainId": 101,
            "address": "Gc9rR2dUHfuYCJ8rU1Ye9fr8JoZZt9ZrfmXitQRLsxRW",
            "symbol": "wMIR_v1",
            "name": "Wrapped MIR Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gc9rR2dUHfuYCJ8rU1Ye9fr8JoZZt9ZrfmXitQRLsxRW/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x09a3EcAFa817268f77BE1283176B946C4ff2E608",
                "assetContract": "https://etherscan.io/address/0x09a3EcAFa817268f77BE1283176B946C4ff2E608",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "mirror-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "B8xDqdrHpYLNHQKQ4ARDKurxhkhn2gfZa8WRosCEzXnF",
            "symbol": "wGRO_v1",
            "name": "Growth (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B8xDqdrHpYLNHQKQ4ARDKurxhkhn2gfZa8WRosCEzXnF/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x09e64c2B61a5f1690Ee6fbeD9baf5D6990F8dFd0",
                "assetContract": "https://etherscan.io/address/0x09e64c2B61a5f1690Ee6fbeD9baf5D6990F8dFd0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "growth-defi"
            }
        },
        {
            "chainId": 101,
            "address": "GE1X8ef7fcsJ93THx4CvV7BQsdEyEAyk61s2L5YfSXiL",
            "symbol": "wSTAKE_v1",
            "name": "xDai (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GE1X8ef7fcsJ93THx4CvV7BQsdEyEAyk61s2L5YfSXiL/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0Ae055097C6d159879521C384F1D2123D1f195e6",
                "assetContract": "https://etherscan.io/address/0x0Ae055097C6d159879521C384F1D2123D1f195e6",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "xdai-stake"
            }
        },
        {
            "chainId": 101,
            "address": "7TK6QeyTsnTT6KsnK2tHHfh62mbjNuFWoyUc8vo3CmmU",
            "symbol": "wYFI_v1",
            "name": "yearn.finance (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TK6QeyTsnTT6KsnK2tHHfh62mbjNuFWoyUc8vo3CmmU/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
                "assetContract": "https://etherscan.io/address/0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "yearn-finance"
            }
        },
        {
            "chainId": 101,
            "address": "CTtKth9uW7froBA6xCd2MP7BXjGFESdT1SyxUmbHovSw",
            "symbol": "wBAT_v1",
            "name": "Basic Attention Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CTtKth9uW7froBA6xCd2MP7BXjGFESdT1SyxUmbHovSw/logo.png",
            "tags": [
                "wrapped",
                "wormhole-v1"
            ],
            "extensions": {
                "address": "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
                "assetContract": "https://etherscan.io/address/0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "basic-attention-token"
            }
        },
        {
            "chainId": 101,
            "address": "EPeUFDgHRxs9xxEPVaL6kfGQvCon7jmAWKVUHuux1Tpz",
            "symbol": "BAT",
            "name": "Basic Attention Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPeUFDgHRxs9xxEPVaL6kfGQvCon7jmAWKVUHuux1Tpz/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
                "assetContract": "https://etherscan.io/address/0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "basic-attention-token"
            }
        },
        {
            "chainId": 101,
            "address": "DrL2D4qCRCeNkQz3AJikLjBc3cS6fqqcQ3W7T9vbshCu",
            "symbol": "wMANA_v1",
            "name": "Decentraland MANA (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DrL2D4qCRCeNkQz3AJikLjBc3cS6fqqcQ3W7T9vbshCu/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0F5D2fB29fb7d3CFeE444a200298f468908cC942",
                "assetContract": "https://etherscan.io/address/0x0F5D2fB29fb7d3CFeE444a200298f468908cC942",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "decentraland"
            }
        },
        {
            "chainId": 101,
            "address": "3cJKTW69FQDDCud7AhKHXZg126b3t73a2qVcVBS1BWjL",
            "symbol": "wXIO_v1",
            "name": "XIO Network (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3cJKTW69FQDDCud7AhKHXZg126b3t73a2qVcVBS1BWjL/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0f7F961648aE6Db43C75663aC7E5414Eb79b5704",
                "assetContract": "https://etherscan.io/address/0x0f7F961648aE6Db43C75663aC7E5414Eb79b5704",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "xio"
            }
        },
        {
            "chainId": 101,
            "address": "CQivbzuRQLvZbqefKc5gLzhSzZzAaySAdMmTG7pFn41w",
            "symbol": "wLAYER_v1",
            "name": "Unilayer (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CQivbzuRQLvZbqefKc5gLzhSzZzAaySAdMmTG7pFn41w/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0fF6ffcFDa92c53F615a4A75D982f399C989366b",
                "assetContract": "https://etherscan.io/address/0x0fF6ffcFDa92c53F615a4A75D982f399C989366b",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "unilayer"
            }
        },
        {
            "chainId": 101,
            "address": "8F3kZd9XEpFgNZ4fZnEAC5CJZLewnkNE8QCjdvorGWuW",
            "symbol": "w1INCH_v1",
            "name": "1INCH Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8F3kZd9XEpFgNZ4fZnEAC5CJZLewnkNE8QCjdvorGWuW/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x111111111117dC0aa78b770fA6A738034120C302",
                "assetContract": "https://etherscan.io/address/0x111111111117dC0aa78b770fA6A738034120C302",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "1inch"
            }
        },
        {
            "chainId": 101,
            "address": "AjkPkq3nsyDe1yKcbyZT7N4aK4Evv9om9tzhQD3wsRC",
            "symbol": "1INCH",
            "name": "1INCH Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AjkPkq3nsyDe1yKcbyZT7N4aK4Evv9om9tzhQD3wsRC/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x111111111117dC0aa78b770fA6A738034120C302",
                "assetContract": "https://etherscan.io/address/0x111111111117dC0aa78b770fA6A738034120C302",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "1inch",
                "serumV3Usdc": "EQcNRGwogvYJDizG9Ek1qf6syi5UghkYDcUwgmycawYU",
                "serumV3Usdt": "B3UpqhaGZc9yXhELknAAXuAoKzCk4QAoqaiVUffgMQBH"
            }
        },
        {
            "chainId": 101,
            "address": "H3UMboX4tnjba1Xw1a2VhUtkdgnrbmPvmDm6jaouQDN9",
            "symbol": "wARMOR_v1",
            "name": "Armor (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H3UMboX4tnjba1Xw1a2VhUtkdgnrbmPvmDm6jaouQDN9/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1337DEF16F9B486fAEd0293eb623Dc8395dFE46a",
                "assetContract": "https://etherscan.io/address/0x1337DEF16F9B486fAEd0293eb623Dc8395dFE46a",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "armor"
            }
        },
        {
            "chainId": 101,
            "address": "Cw26Yz3rAN42mM5WpKriuGvbXnvRYmFA9sbBWH49KyqL",
            "symbol": "warNXM_v1",
            "name": "Armor NXM (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cw26Yz3rAN42mM5WpKriuGvbXnvRYmFA9sbBWH49KyqL/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1337DEF18C680aF1f9f45cBcab6309562975b1dD",
                "assetContract": "https://etherscan.io/address/0x1337DEF18C680aF1f9f45cBcab6309562975b1dD",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "armor-nxm"
            }
        },
        {
            "chainId": 101,
            "address": "3GVAecXsFP8xLFuAMMpg5NU4g5JK6h2NZWsQJ45wiw6b",
            "symbol": "wDPI_v1",
            "name": "DefiPulse Index (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GVAecXsFP8xLFuAMMpg5NU4g5JK6h2NZWsQJ45wiw6b/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b",
                "assetContract": "https://etherscan.io/address/0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "defipulse-index"
            }
        },
        {
            "chainId": 101,
            "address": "AC4BK5yoEKn5hw6WpH3iWu56pEwigQdR48CiiqJ3R1pd",
            "symbol": "wDHC_v1",
            "name": "DeltaHub Community (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AC4BK5yoEKn5hw6WpH3iWu56pEwigQdR48CiiqJ3R1pd/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x152687Bc4A7FCC89049cF119F9ac3e5aCF2eE7ef",
                "assetContract": "https://etherscan.io/address/0x152687Bc4A7FCC89049cF119F9ac3e5aCF2eE7ef",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "deltahub-community"
            }
        },
        {
            "chainId": 101,
            "address": "7bXgNP7SEwrqbnfLBPgKDRKSGjVe7cjbuioRP23upF5H",
            "symbol": "wKEX_v1",
            "name": "KIRA Network (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7bXgNP7SEwrqbnfLBPgKDRKSGjVe7cjbuioRP23upF5H/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x16980b3B4a3f9D89E33311B5aa8f80303E5ca4F8",
                "assetContract": "https://etherscan.io/address/0x16980b3B4a3f9D89E33311B5aa8f80303E5ca4F8",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "kira-network"
            }
        },
        {
            "chainId": 101,
            "address": "5uC8Gj96sK6UG44AYLpbX3DUjKtBUxBrhHcM8JDtyYum",
            "symbol": "wEWTB_v1",
            "name": "Energy Web Token Bridged (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5uC8Gj96sK6UG44AYLpbX3DUjKtBUxBrhHcM8JDtyYum/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x178c820f862B14f316509ec36b13123DA19A6054",
                "assetContract": "https://etherscan.io/address/0x178c820f862B14f316509ec36b13123DA19A6054",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "energy-web-token"
            }
        },
        {
            "chainId": 101,
            "address": "EzeRaHuh1Xu1nDUypv1VWXcGsNJ71ncCJ8HeWuyg8atJ",
            "symbol": "wCC10_v1",
            "name": "Cryptocurrency Top 10 Tokens Index (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzeRaHuh1Xu1nDUypv1VWXcGsNJ71ncCJ8HeWuyg8atJ/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x17aC188e09A7890a1844E5E65471fE8b0CcFadF3",
                "assetContract": "https://etherscan.io/address/0x17aC188e09A7890a1844E5E65471fE8b0CcFadF3",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "cryptocurrency-top-10-tokens-index"
            }
        },
        {
            "chainId": 101,
            "address": "9LzCMqDgTKYz9Drzqnpgee3SGa89up3a247ypMj2xrqM",
            "symbol": "AUDIO",
            "name": "Audius (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9LzCMqDgTKYz9Drzqnpgee3SGa89up3a247ypMj2xrqM/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998",
                "assetContract": "https://etherscan.io/address/0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "audius",
                "serumV3Usdc": "FxquLRmVMPXiS84FFSp8q5fbVExhLkX85yiXucyu7xSC"
            }
        },
        {
            "chainId": 101,
            "address": "9yPmJNUp1qFV6LafdYdegZ8sCgC4oy6Rgt9WsDJqv3EX",
            "symbol": "wREP_v1",
            "name": "Reputation (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9yPmJNUp1qFV6LafdYdegZ8sCgC4oy6Rgt9WsDJqv3EX/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1985365e9f78359a9B6AD760e32412f4a445E862",
                "assetContract": "https://etherscan.io/address/0x1985365e9f78359a9B6AD760e32412f4a445E862",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "CZxP1KtsfvMXZTGKR1fNwNChv8hGAfQrgVoENabN8zKU",
            "symbol": "wVSP_v1",
            "name": "VesperToken (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CZxP1KtsfvMXZTGKR1fNwNChv8hGAfQrgVoENabN8zKU/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1b40183EFB4Dd766f11bDa7A7c3AD8982e998421",
                "assetContract": "https://etherscan.io/address/0x1b40183EFB4Dd766f11bDa7A7c3AD8982e998421",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "vesper-finance"
            }
        },
        {
            "chainId": 101,
            "address": "8cGPyDGT1mgG1iWzNjPmCDKSK9veJhoBAguq7rp7CjTe",
            "symbol": "wKP3R_v1",
            "name": "Keep3rV1 (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8cGPyDGT1mgG1iWzNjPmCDKSK9veJhoBAguq7rp7CjTe/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44",
                "assetContract": "https://etherscan.io/address/0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "keep3rv1"
            }
        },
        {
            "chainId": 101,
            "address": "DGghbWvncPL41U8TmUtXcGMgLeQqkaA2yM7UfcabftR8",
            "symbol": "wLEAD_v1",
            "name": "Lead Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DGghbWvncPL41U8TmUtXcGMgLeQqkaA2yM7UfcabftR8/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1dD80016e3d4ae146Ee2EBB484e8edD92dacC4ce",
                "assetContract": "https://etherscan.io/address/0x1dD80016e3d4ae146Ee2EBB484e8edD92dacC4ce",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "lead-token"
            }
        },
        {
            "chainId": 101,
            "address": "3MVa4e32PaKmPxYUQ6n8vFkWtCma68Ld7e7fTktWDueQ",
            "symbol": "wUNI_v1",
            "name": "Uniswap (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3MVa4e32PaKmPxYUQ6n8vFkWtCma68Ld7e7fTktWDueQ/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
                "assetContract": "https://etherscan.io/address/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "uniswap"
            }
        },
        {
            "chainId": 101,
            "address": "qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL",
            "symbol": "wWBTC_v1",
            "name": "Wrapped BTC (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
                "assetContract": "https://etherscan.io/address/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "wrapped-bitcoin"
            }
        },
        {
            "chainId": 101,
            "address": "8My83RG8Xa1EhXdDKHWq8BWZN1zF3XUrWL3TXCLjVPFh",
            "symbol": "wUNN_v1",
            "name": "UNION Protocol Governance Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8My83RG8Xa1EhXdDKHWq8BWZN1zF3XUrWL3TXCLjVPFh/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x226f7b842E0F0120b7E194D05432b3fd14773a9D",
                "assetContract": "https://etherscan.io/address/0x226f7b842E0F0120b7E194D05432b3fd14773a9D",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "union-protocol-governance-token"
            }
        },
        {
            "chainId": 101,
            "address": "6jVuhLJ2mzyZ8DyUcrDj8Qr6Q9bqbJnq4fAnMeEduDM9",
            "symbol": "wSOCKS_v1",
            "name": "Unisocks Edition 0 (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6jVuhLJ2mzyZ8DyUcrDj8Qr6Q9bqbJnq4fAnMeEduDM9/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x23B608675a2B2fB1890d3ABBd85c5775c51691d5",
                "assetContract": "https://etherscan.io/address/0x23B608675a2B2fB1890d3ABBd85c5775c51691d5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "unisocks"
            }
        },
        {
            "chainId": 101,
            "address": "Az8PAQ7s6s5ZFgBiKKEizHt3SzDxXKZayDCtRZoC3452",
            "symbol": "wDEXT_v1",
            "name": "DEXTools (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Az8PAQ7s6s5ZFgBiKKEizHt3SzDxXKZayDCtRZoC3452/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x26CE25148832C04f3d7F26F32478a9fe55197166",
                "assetContract": "https://etherscan.io/address/0x26CE25148832C04f3d7F26F32478a9fe55197166",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "idextools"
            }
        },
        {
            "chainId": 101,
            "address": "ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv",
            "symbol": "wHEX_v1",
            "name": "HEX (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
                "assetContract": "https://etherscan.io/address/0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "hex"
            }
        },
        {
            "chainId": 101,
            "address": "9iwfHhE7BJKNo4Eb1wX3p4uyJjEN9RoGLt4BvMdzZoiN",
            "symbol": "wCREAM_v1",
            "name": "Cream (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iwfHhE7BJKNo4Eb1wX3p4uyJjEN9RoGLt4BvMdzZoiN/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x2ba592F78dB6436527729929AAf6c908497cB200",
                "assetContract": "https://etherscan.io/address/0x2ba592F78dB6436527729929AAf6c908497cB200",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "cream-2"
            }
        },
        {
            "chainId": 101,
            "address": "6wdcYNvUyHCerSiGbChkvGBF6Qzju1YP5qpXRQ4tqdZ3",
            "symbol": "wZEE_v1",
            "name": "ZeroSwapToken (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wdcYNvUyHCerSiGbChkvGBF6Qzju1YP5qpXRQ4tqdZ3/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x2eDf094dB69d6Dcd487f1B3dB9febE2eeC0dd4c5",
                "assetContract": "https://etherscan.io/address/0x2eDf094dB69d6Dcd487f1B3dB9febE2eeC0dd4c5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "zeroswap"
            }
        },
        {
            "chainId": 101,
            "address": "E8yz29LYVsmpMvbpqEsNUzTFU3mjNLLu4NmwXBdgBAJm",
            "symbol": "ZERO",
            "name": "Z-Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E8yz29LYVsmpMvbpqEsNUzTFU3mjNLLu4NmwXBdgBAJm/logo.png",
            "tags": [
                "ztechhub",
                "smartshift"
            ],
            "extensions": {
                "website": "https://www.smartshift.asia"
            }
        },
        {
            "chainId": 101,
            "address": "4xh8iC54UgaNpY4h34rxfZBSc9L2fBB8gWcYtDGHjxhN",
            "symbol": "wwANATHA_v1",
            "name": "Wrapped ANATHA (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4xh8iC54UgaNpY4h34rxfZBSc9L2fBB8gWcYtDGHjxhN/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x3383c5a8969Dc413bfdDc9656Eb80A1408E4bA20",
                "assetContract": "https://etherscan.io/address/0x3383c5a8969Dc413bfdDc9656Eb80A1408E4bA20",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "wrapped-anatha"
            }
        },
        {
            "chainId": 101,
            "address": "5Jq6S9HYqfG6TUMjjsKpnfis7utUAB69JiEGkkypdmgP",
            "symbol": "wRAMP_v1",
            "name": "RAMP DEFI (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Jq6S9HYqfG6TUMjjsKpnfis7utUAB69JiEGkkypdmgP/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x33D0568941C0C64ff7e0FB4fbA0B11BD37deEd9f",
                "assetContract": "https://etherscan.io/address/0x33D0568941C0C64ff7e0FB4fbA0B11BD37deEd9f",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "ramp"
            }
        },
        {
            "chainId": 101,
            "address": "6uMUH5ztnj6AKYvL71EZgcyyRxjyBC5LVkscA5LrBc3c",
            "symbol": "wPRQ_v1",
            "name": "Parsiq Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uMUH5ztnj6AKYvL71EZgcyyRxjyBC5LVkscA5LrBc3c/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x362bc847A3a9637d3af6624EeC853618a43ed7D2",
                "assetContract": "https://etherscan.io/address/0x362bc847A3a9637d3af6624EeC853618a43ed7D2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "parsiq"
            }
        },
        {
            "chainId": 101,
            "address": "42gecM46tdSiYZN2CK1ek5raCxnzQf1xfhoKAf3F7Y5k",
            "symbol": "wSLP_v1",
            "name": "Small Love Potion (Wormhole v1)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/42gecM46tdSiYZN2CK1ek5raCxnzQf1xfhoKAf3F7Y5k/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x37236CD05b34Cc79d3715AF2383E96dd7443dCF1",
                "assetContract": "https://etherscan.io/address/0x37236CD05b34Cc79d3715AF2383E96dd7443dCF1",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "smooth-love-potion"
            }
        },
        {
            "chainId": 101,
            "address": "F6M9DW1cWw7EtFK9m2ukvT9WEvtEbdZfTzZTtDeBcnAf",
            "symbol": "wSAND_v1",
            "name": "SAND (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F6M9DW1cWw7EtFK9m2ukvT9WEvtEbdZfTzZTtDeBcnAf/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x3845badAde8e6dFF049820680d1F14bD3903a5d0",
                "assetContract": "https://etherscan.io/address/0x3845badAde8e6dFF049820680d1F14bD3903a5d0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "the-sandbox"
            }
        },
        {
            "chainId": 101,
            "address": "G27M8w6G4hwatMNFi46DPAUR1YkxSmRNFKus7SgYLoDy",
            "symbol": "wCVP_v1",
            "name": "Concentrated Voting Power (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G27M8w6G4hwatMNFi46DPAUR1YkxSmRNFKus7SgYLoDy/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x38e4adB44ef08F22F5B5b76A8f0c2d0dCbE7DcA1",
                "assetContract": "https://etherscan.io/address/0x38e4adB44ef08F22F5B5b76A8f0c2d0dCbE7DcA1",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "concentrated-voting-power"
            }
        },
        {
            "chainId": 101,
            "address": "FjucGZpcdVXaWJH21pbrGQaKNszsGsJqbAXu4sJywKJa",
            "symbol": "wREN_v1",
            "name": "Republic Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FjucGZpcdVXaWJH21pbrGQaKNszsGsJqbAXu4sJywKJa/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x408e41876cCCDC0F92210600ef50372656052a38",
                "assetContract": "https://etherscan.io/address/0x408e41876cCCDC0F92210600ef50372656052a38",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "republic-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "5kvugu18snfGRu1PykMfRzYfUxJYs3smk1PWQcGo6Z8a",
            "symbol": "wXOR_v1",
            "name": "Sora (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5kvugu18snfGRu1PykMfRzYfUxJYs3smk1PWQcGo6Z8a/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x40FD72257597aA14C7231A7B1aaa29Fce868F677",
                "assetContract": "https://etherscan.io/address/0x40FD72257597aA14C7231A7B1aaa29Fce868F677",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "sora"
            }
        },
        {
            "chainId": 101,
            "address": "3EKQDmiXj8yLBFpZca4coxBpP8XJCzmjVgUdVydSmaaT",
            "symbol": "wFUN_v1",
            "name": "FunFair (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EKQDmiXj8yLBFpZca4coxBpP8XJCzmjVgUdVydSmaaT/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b",
                "assetContract": "https://etherscan.io/address/0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "funfair"
            }
        },
        {
            "chainId": 101,
            "address": "6J9soByB65WUamsEG8KSPdphBV1oCoGvr5QpaUaY3r19",
            "symbol": "wPICKLE_v1",
            "name": "PickleToken (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6J9soByB65WUamsEG8KSPdphBV1oCoGvr5QpaUaY3r19/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x429881672B9AE42b8EbA0E26cD9C73711b891Ca5",
                "assetContract": "https://etherscan.io/address/0x429881672B9AE42b8EbA0E26cD9C73711b891Ca5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "pickle-finance"
            }
        },
        {
            "chainId": 101,
            "address": "HEsqFznmAERPUmMWHtDWYAZRoFbNHZpuNuFrPio68Zp1",
            "symbol": "wPAXG_v1",
            "name": "Paxos Gold (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEsqFznmAERPUmMWHtDWYAZRoFbNHZpuNuFrPio68Zp1/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x45804880De22913dAFE09f4980848ECE6EcbAf78",
                "assetContract": "https://etherscan.io/address/0x45804880De22913dAFE09f4980848ECE6EcbAf78",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "pax-gold"
            }
        },
        {
            "chainId": 101,
            "address": "BrtLvpVCwVDH5Jpqjtiuhh8wKYA5b3NZCnsSftr61viv",
            "symbol": "wQNT_v1",
            "name": "Quant (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BrtLvpVCwVDH5Jpqjtiuhh8wKYA5b3NZCnsSftr61viv/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4a220E6096B25EADb88358cb44068A3248254675",
                "assetContract": "https://etherscan.io/address/0x4a220E6096B25EADb88358cb44068A3248254675",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "quant-network"
            }
        },
        {
            "chainId": 101,
            "address": "8DRgurhcQPJeCqQEpbeYGUmwAz2tETbyWUYLUU4Q7goM",
            "symbol": "wORAI_v1",
            "name": "Oraichain Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8DRgurhcQPJeCqQEpbeYGUmwAz2tETbyWUYLUU4Q7goM/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4c11249814f11b9346808179Cf06e71ac328c1b5",
                "assetContract": "https://etherscan.io/address/0x4c11249814f11b9346808179Cf06e71ac328c1b5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "oraichain-token"
            }
        },
        {
            "chainId": 101,
            "address": "4e5cqAsZ7wQqwLi7AApS9CgN8Yaho5TvkhvcLaGyiuzL",
            "symbol": "wTRU_v1",
            "name": "TrustToken (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4e5cqAsZ7wQqwLi7AApS9CgN8Yaho5TvkhvcLaGyiuzL/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784",
                "assetContract": "https://etherscan.io/address/0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "truefi"
            }
        },
        {
            "chainId": 101,
            "address": "HkhBUKSct2V93Z35apDmXthkRvH4yvMovLyv8s8idDgP",
            "symbol": "wMCB_v1",
            "name": "MCDEX Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HkhBUKSct2V93Z35apDmXthkRvH4yvMovLyv8s8idDgP/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42",
                "assetContract": "https://etherscan.io/address/0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "mcdex"
            }
        },
        {
            "chainId": 101,
            "address": "Eof7wbYsHZKaoyUGwM7Nfkoo6zQW4U7uWXqz2hoQzSkK",
            "symbol": "wNU_v1",
            "name": "NuCypher (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Eof7wbYsHZKaoyUGwM7Nfkoo6zQW4U7uWXqz2hoQzSkK/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4fE83213D56308330EC302a8BD641f1d0113A4Cc",
                "assetContract": "https://etherscan.io/address/0x4fE83213D56308330EC302a8BD641f1d0113A4Cc",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "nucypher"
            }
        },
        {
            "chainId": 101,
            "address": "5CmA1HTVZt5NRtwiUrqWrcnT5JRW5zHe6uQXfP7SDUNz",
            "symbol": "wRAZOR_v1",
            "name": "RAZOR (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5CmA1HTVZt5NRtwiUrqWrcnT5JRW5zHe6uQXfP7SDUNz/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x50DE6856358Cc35f3A9a57eAAA34BD4cB707d2cd",
                "assetContract": "https://etherscan.io/address/0x50DE6856358Cc35f3A9a57eAAA34BD4cB707d2cd",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "razor-network"
            }
        },
        {
            "chainId": 101,
            "address": "6msNYXzSVtjinqapq2xcvBb5NRq4YTPAi7wc5Jx8M8TS",
            "symbol": "wLINK_v1",
            "name": "ChainLink Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6msNYXzSVtjinqapq2xcvBb5NRq4YTPAi7wc5Jx8M8TS/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x514910771AF9Ca656af840dff83E8264EcF986CA",
                "assetContract": "https://etherscan.io/address/0x514910771AF9Ca656af840dff83E8264EcF986CA",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "chainlink"
            }
        },
        {
            "chainId": 101,
            "address": "BX2gcRRS12iqFzKCpvTt4krBBYNymR9JBDZBxzfFLnbF",
            "symbol": "weRSDL_v1",
            "name": "UnFederalReserveToken (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BX2gcRRS12iqFzKCpvTt4krBBYNymR9JBDZBxzfFLnbF/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x5218E472cFCFE0b64A064F055B43b4cdC9EfD3A6",
                "assetContract": "https://etherscan.io/address/0x5218E472cFCFE0b64A064F055B43b4cdC9EfD3A6",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "unfederalreserve"
            }
        },
        {
            "chainId": 101,
            "address": "CCGLdsokcybeF8NrCcu1RSQK8isNBjBA58kVEMTHTKjx",
            "symbol": "wsUSD_v1",
            "name": "Synth sUSD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CCGLdsokcybeF8NrCcu1RSQK8isNBjBA58kVEMTHTKjx/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51",
                "assetContract": "https://etherscan.io/address/0x57Ab1ec28D129707052df4dF418D58a2D46d5f51",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "nusd"
            }
        },
        {
            "chainId": 101,
            "address": "FP9ogG7hTdfcTJwn4prF9AVEcfcjLq1GtkqYM4oRn7eY",
            "symbol": "wHEGIC_v1",
            "name": "Hegic (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FP9ogG7hTdfcTJwn4prF9AVEcfcjLq1GtkqYM4oRn7eY/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x584bC13c7D411c00c01A62e8019472dE68768430",
                "assetContract": "https://etherscan.io/address/0x584bC13c7D411c00c01A62e8019472dE68768430",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "hegic"
            }
        },
        {
            "chainId": 101,
            "address": "DboP5vvYUVjmKSHKJ1YFHwmv41KtUscnYgzjmPgHwQVn",
            "symbol": "wXFI_v1",
            "name": "Xfinance (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DboP5vvYUVjmKSHKJ1YFHwmv41KtUscnYgzjmPgHwQVn/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x5BEfBB272290dD5b8521D4a938f6c4757742c430",
                "assetContract": "https://etherscan.io/address/0x5BEfBB272290dD5b8521D4a938f6c4757742c430",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "xfinance"
            }
        },
        {
            "chainId": 101,
            "address": "6c4U9yxGzVjejSJJXrdX8wtt532Et6MrBUZc2oK5j6w5",
            "symbol": "wDEXTF_v1",
            "name": "DEXTF Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6c4U9yxGzVjejSJJXrdX8wtt532Et6MrBUZc2oK5j6w5/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x5F64Ab1544D28732F0A24F4713c2C8ec0dA089f0",
                "assetContract": "https://etherscan.io/address/0x5F64Ab1544D28732F0A24F4713c2C8ec0dA089f0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "dextf"
            }
        },
        {
            "chainId": 101,
            "address": "JuXkRYNw54rujC7SPWcAM4ArLgA5x8nDQbS8xHAr6MA",
            "symbol": "wRLC_v1",
            "name": "iExec RLC (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JuXkRYNw54rujC7SPWcAM4ArLgA5x8nDQbS8xHAr6MA/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x607F4C5BB672230e8672085532f7e901544a7375",
                "assetContract": "https://etherscan.io/address/0x607F4C5BB672230e8672085532f7e901544a7375",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "iexec-rlc"
            }
        },
        {
            "chainId": 101,
            "address": "AqLKDJiGL4wXKPAfzNom3xEdQwgj2LTCE4k34gzvZsE6",
            "symbol": "wCFi_v1",
            "name": "CyberFi Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AqLKDJiGL4wXKPAfzNom3xEdQwgj2LTCE4k34gzvZsE6/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x63b4f3e3fa4e438698CE330e365E831F7cCD1eF4",
                "assetContract": "https://etherscan.io/address/0x63b4f3e3fa4e438698CE330e365E831F7cCD1eF4",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "cyberfi"
            }
        },
        {
            "chainId": 101,
            "address": "FLrjpCRrd4GffHu8MVYGvuLxYLuBGVaXsnCecw3Effci",
            "symbol": "wWISE_v1",
            "name": "Wise Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLrjpCRrd4GffHu8MVYGvuLxYLuBGVaXsnCecw3Effci/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6",
                "assetContract": "https://etherscan.io/address/0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "wise-token11"
            }
        },
        {
            "chainId": 101,
            "address": "GaMPhVyp1xd9xJuPskDEzQzp8mKfEjAmhny8NX7y7YKc",
            "symbol": "wGNO_v1",
            "name": "Gnosis Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GaMPhVyp1xd9xJuPskDEzQzp8mKfEjAmhny8NX7y7YKc/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6810e776880C02933D47DB1b9fc05908e5386b96",
                "assetContract": "https://etherscan.io/address/0x6810e776880C02933D47DB1b9fc05908e5386b96",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "gnosis"
            }
        },
        {
            "chainId": 101,
            "address": "CCAQZHBVWKDukT68PZ3LenDs7apibeSYeJ3jHE8NzBC5",
            "symbol": "wPOOLZ_v1",
            "name": "Poolz Finance (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CCAQZHBVWKDukT68PZ3LenDs7apibeSYeJ3jHE8NzBC5/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x69A95185ee2a045CDC4bCd1b1Df10710395e4e23",
                "assetContract": "https://etherscan.io/address/0x69A95185ee2a045CDC4bCd1b1Df10710395e4e23",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "poolz-finance"
            }
        },
        {
            "chainId": 101,
            "address": "FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1",
            "symbol": "wDAI_v1",
            "name": "Dai Stablecoin (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
                "assetContract": "https://etherscan.io/address/0x6B175474E89094C44Da98b954EedeAC495271d0F",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "dai"
            }
        },
        {
            "chainId": 101,
            "address": "HbMGwfGjGPchtaPwyrtJFy8APZN5w1hi63xnzmj1f23v",
            "symbol": "wSUSHI_v1",
            "name": "SushiSwap (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HbMGwfGjGPchtaPwyrtJFy8APZN5w1hi63xnzmj1f23v/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
                "assetContract": "https://etherscan.io/address/0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "sushi"
            }
        },
        {
            "chainId": 101,
            "address": "6Tmi8TZasqdxWB59uE5Zw9VLKecuCbsLSsPEqoMpmozA",
            "symbol": "wFYZ_v1",
            "name": "Fyooz (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Tmi8TZasqdxWB59uE5Zw9VLKecuCbsLSsPEqoMpmozA/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6BFf2fE249601ed0Db3a87424a2E923118BB0312",
                "assetContract": "https://etherscan.io/address/0x6BFf2fE249601ed0Db3a87424a2E923118BB0312",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "fyooz"
            }
        },
        {
            "chainId": 101,
            "address": "3sHinPxEPqhEGip2Wy45TFmgAA1Atg2mctMjY5RKJUjk",
            "symbol": "wQRX_v1",
            "name": "QuiverX (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3sHinPxEPqhEGip2Wy45TFmgAA1Atg2mctMjY5RKJUjk/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6e0daDE58D2d89eBBe7aFc384e3E4f15b70b14D8",
                "assetContract": "https://etherscan.io/address/0x6e0daDE58D2d89eBBe7aFc384e3E4f15b70b14D8",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "quiverx"
            }
        },
        {
            "chainId": 101,
            "address": "4ighgEijHcCoLu9AsvwVz2TnGFqAgzQtQMr6ch88Jrfe",
            "symbol": "wTRADE_v1",
            "name": "UniTrade (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ighgEijHcCoLu9AsvwVz2TnGFqAgzQtQMr6ch88Jrfe/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6F87D756DAf0503d08Eb8993686c7Fc01Dc44fB1",
                "assetContract": "https://etherscan.io/address/0x6F87D756DAf0503d08Eb8993686c7Fc01Dc44fB1",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "unitrade"
            }
        },
        {
            "chainId": 101,
            "address": "FTPnEQ3NfRRZ9tvmpDW6JFrvweBE5sanxnXSpJL1dvbB",
            "symbol": "wBIRD_v1",
            "name": "Bird.Money (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTPnEQ3NfRRZ9tvmpDW6JFrvweBE5sanxnXSpJL1dvbB/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x70401dFD142A16dC7031c56E862Fc88Cb9537Ce0",
                "assetContract": "https://etherscan.io/address/0x70401dFD142A16dC7031c56E862Fc88Cb9537Ce0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "bird-money"
            }
        },
        {
            "chainId": 101,
            "address": "QVDE6rhcGPSB3ex5T7vWBzvoSRUXULjuSGpVuKwu5XH",
            "symbol": "wAXN_v1",
            "name": "Axion (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/QVDE6rhcGPSB3ex5T7vWBzvoSRUXULjuSGpVuKwu5XH/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x71F85B2E46976bD21302B64329868fd15eb0D127",
                "assetContract": "https://etherscan.io/address/0x71F85B2E46976bD21302B64329868fd15eb0D127",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "axion"
            }
        },
        {
            "chainId": 101,
            "address": "J6AbGG62yo9UJ2T9r9GM7pnoRNui5DsZDnPbiNAPqbVd",
            "symbol": "wBMI_v1",
            "name": "Bridge Mutual (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J6AbGG62yo9UJ2T9r9GM7pnoRNui5DsZDnPbiNAPqbVd/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x725C263e32c72dDC3A19bEa12C5a0479a81eE688",
                "assetContract": "https://etherscan.io/address/0x725C263e32c72dDC3A19bEa12C5a0479a81eE688",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "bridge-mutual"
            }
        },
        {
            "chainId": 101,
            "address": "4wvHoaxxZxFeNrMTP8bLVRh1ziSBV7crN665WX4rRMqe",
            "symbol": "wDYT_v1",
            "name": "DoYourTip (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wvHoaxxZxFeNrMTP8bLVRh1ziSBV7crN665WX4rRMqe/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x740623d2c797b7D8D1EcB98e9b4Afcf99Ec31E14",
                "assetContract": "https://etherscan.io/address/0x740623d2c797b7D8D1EcB98e9b4Afcf99Ec31E14",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "dynamite"
            }
        },
        {
            "chainId": 101,
            "address": "Fe5fWjCLDMJoi4sTmfR2VW4BT1LwsbR1n6QAjzJQvhhf",
            "symbol": "wBBR_v1",
            "name": "BitberryToken (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fe5fWjCLDMJoi4sTmfR2VW4BT1LwsbR1n6QAjzJQvhhf/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7671904eed7f10808B664fc30BB8693FD7237abF",
                "assetContract": "https://etherscan.io/address/0x7671904eed7f10808B664fc30BB8693FD7237abF",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "bitberry-token"
            }
        },
        {
            "chainId": 101,
            "address": "5J9yhFRnQZx3RiqHzfQpAffX5UQz3k8vQCZH2g9Z9sDg",
            "symbol": "wWAXE_v1",
            "name": "WAX Economic Token (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5J9yhFRnQZx3RiqHzfQpAffX5UQz3k8vQCZH2g9Z9sDg/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7a2Bc711E19ba6aff6cE8246C546E8c4B4944DFD",
                "assetContract": "https://etherscan.io/address/0x7a2Bc711E19ba6aff6cE8246C546E8c4B4944DFD",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "waxe"
            }
        },
        {
            "chainId": 101,
            "address": "4DHywS5EjUTF5AYisPZiJbWcCV4gfpH98oKxpgyKRnnQ",
            "symbol": "wMATIC_v1",
            "name": "Matic Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4DHywS5EjUTF5AYisPZiJbWcCV4gfpH98oKxpgyKRnnQ/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
                "assetContract": "https://etherscan.io/address/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "matic-network"
            }
        },
        {
            "chainId": 101,
            "address": "yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM",
            "symbol": "yPRT",
            "name": "yPRT (Parrot Yield Token)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/gopartyparrot",
                "medium": "https://gopartyparrot.medium.com/",
                "telegram": "https://t.me/gopartyparrot",
                "twitter": "https://twitter.com/gopartyparrot",
                "website": "https://parrot.fi"
            }
        },
        {
            "chainId": 101,
            "address": "Au9E8ygQdTJQZXmNKPdtLEP8rGjC4qsGRhkJgjFNPAr8",
            "symbol": "wXRT_v1",
            "name": "Robonomics (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Au9E8ygQdTJQZXmNKPdtLEP8rGjC4qsGRhkJgjFNPAr8/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7dE91B204C1C737bcEe6F000AAA6569Cf7061cb7",
                "assetContract": "https://etherscan.io/address/0x7dE91B204C1C737bcEe6F000AAA6569Cf7061cb7",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "robonomics-network"
            }
        },
        {
            "chainId": 101,
            "address": "5DQZ14hLDxveMH7NyGmTmUTRGgVAVXADp3cP2UHeH6hM",
            "symbol": "wAAVE_v1",
            "name": "Aave Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5DQZ14hLDxveMH7NyGmTmUTRGgVAVXADp3cP2UHeH6hM/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
                "assetContract": "https://etherscan.io/address/0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave"
            }
        },
        {
            "chainId": 101,
            "address": "Arc2ZVKNCdDU4vB8Ubud5QayDtjo2oJF9xVrUPQ6TWxF",
            "symbol": "wLEND_v1",
            "name": "Lend (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Arc2ZVKNCdDU4vB8Ubud5QayDtjo2oJF9xVrUPQ6TWxF/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x80fB784B7eD66730e8b1DBd9820aFD29931aab03",
                "assetContract": "https://etherscan.io/address/0x80fB784B7eD66730e8b1DBd9820aFD29931aab03",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "ethlend"
            }
        },
        {
            "chainId": 101,
            "address": "2ctKUDkGBnVykt31AhMPhHvAQWJvoNGbLh7aRidjtAqv",
            "symbol": "wPOLS_v1",
            "name": "PolkastarterToken (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ctKUDkGBnVykt31AhMPhHvAQWJvoNGbLh7aRidjtAqv/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa",
                "assetContract": "https://etherscan.io/address/0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "polkastarter"
            }
        },
        {
            "chainId": 101,
            "address": "8FnkznYpHvKiaBkgatVoCrNiS5y5KW62JqgjnxVhDejC",
            "symbol": "wUBT_v1",
            "name": "Unibright (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FnkznYpHvKiaBkgatVoCrNiS5y5KW62JqgjnxVhDejC/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8400D94A5cb0fa0D041a3788e395285d61c9ee5e",
                "assetContract": "https://etherscan.io/address/0x8400D94A5cb0fa0D041a3788e395285d61c9ee5e",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "unibright"
            }
        },
        {
            "chainId": 101,
            "address": "4LLAYXVmT3U8Sew6k3tk66zk3btT91QRzQzxcNX8XhzV",
            "symbol": "wDIA_v1",
            "name": "DIA (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LLAYXVmT3U8Sew6k3tk66zk3btT91QRzQzxcNX8XhzV/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419",
                "assetContract": "https://etherscan.io/address/0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "dia-data"
            }
        },
        {
            "chainId": 101,
            "address": "8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU",
            "symbol": "wFRAX_v1",
            "name": "Frax (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x853d955aCEf822Db058eb8505911ED77F175b99e",
                "assetContract": "https://etherscan.io/address/0x853d955aCEf822Db058eb8505911ED77F175b99e",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "frax"
            }
        },
        {
            "chainId": 101,
            "address": "H3oVL2zJpHJaDoRfQmSrftv3fkGzvsiQgugCZmcRBykG",
            "symbol": "wKEEP_v1",
            "name": "KEEP Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H3oVL2zJpHJaDoRfQmSrftv3fkGzvsiQgugCZmcRBykG/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC",
                "assetContract": "https://etherscan.io/address/0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "keep-network"
            }
        },
        {
            "chainId": 101,
            "address": "64oqP1dFqqD8NEL4RPCpMyrHmpo31rj3nYxULVXvayfW",
            "symbol": "wRSR_v1",
            "name": "Reserve Rights (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64oqP1dFqqD8NEL4RPCpMyrHmpo31rj3nYxULVXvayfW/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8762db106B2c2A0bccB3A80d1Ed41273552616E8",
                "assetContract": "https://etherscan.io/address/0x8762db106B2c2A0bccB3A80d1Ed41273552616E8",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "reserve-rights-token"
            }
        },
        {
            "chainId": 101,
            "address": "5fv26ojhPHWNaikXcMf2TBu4JENjLQ2PWgWYeitttVwv",
            "symbol": "wPAID_v1",
            "name": "PAID Network (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fv26ojhPHWNaikXcMf2TBu4JENjLQ2PWgWYeitttVwv/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8c8687fC965593DFb2F0b4EAeFD55E9D8df348df",
                "assetContract": "https://etherscan.io/address/0x8c8687fC965593DFb2F0b4EAeFD55E9D8df348df",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "paid-network"
            }
        },
        {
            "chainId": 101,
            "address": "ACr98v3kv9qaGnR3p2BfsoSK9Q2ZmP6zUkm3qxv5ZJDd",
            "symbol": "wSXP_v1",
            "name": "Swipe (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ACr98v3kv9qaGnR3p2BfsoSK9Q2ZmP6zUkm3qxv5ZJDd/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8CE9137d39326AD0cD6491fb5CC0CbA0e089b6A9",
                "assetContract": "https://etherscan.io/address/0x8CE9137d39326AD0cD6491fb5CC0CbA0e089b6A9",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "swipe"
            }
        },
        {
            "chainId": 101,
            "address": "7gBuzBcJ7V48m8TiKJ1XWNDUerK2XfAbjxuRiKMb6S8Z",
            "symbol": "wREQ_v1",
            "name": "Request Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gBuzBcJ7V48m8TiKJ1XWNDUerK2XfAbjxuRiKMb6S8Z/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8f8221aFbB33998d8584A2B05749bA73c37a938a",
                "assetContract": "https://etherscan.io/address/0x8f8221aFbB33998d8584A2B05749bA73c37a938a",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "request-network"
            }
        },
        {
            "chainId": 101,
            "address": "CtDjsryLtwZCLj8TeniV7tWHbkaREfjKDWpvyQvsTyek",
            "symbol": "wWHALE_v1",
            "name": "WHALE (Wormhole v1)",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CtDjsryLtwZCLj8TeniV7tWHbkaREfjKDWpvyQvsTyek/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x9355372396e3F6daF13359B7b607a3374cc638e0",
                "assetContract": "https://etherscan.io/address/0x9355372396e3F6daF13359B7b607a3374cc638e0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "whale"
            }
        },
        {
            "chainId": 101,
            "address": "JDUgn6JUSwufqqthRdnZZKWv2vEdYvHxigF5Hk79yxRm",
            "symbol": "wPNK_v1",
            "name": "Pinakion (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JDUgn6JUSwufqqthRdnZZKWv2vEdYvHxigF5Hk79yxRm/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x93ED3FBe21207Ec2E8f2d3c3de6e058Cb73Bc04d",
                "assetContract": "https://etherscan.io/address/0x93ED3FBe21207Ec2E8f2d3c3de6e058Cb73Bc04d",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "kleros"
            }
        },
        {
            "chainId": 101,
            "address": "Gw7M5dqZJ6B6a8dYkDry6z9t9FuUA2xPUokjV2cortoq",
            "symbol": "KRW",
            "name": "Krown",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gw7M5dqZJ6B6a8dYkDry6z9t9FuUA2xPUokjV2cortoq/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0x1446f3cedf4d86a9399e49f7937766e6de2a3aab",
                "assetContract": "https://www.bscscan.com/address/0x1446f3cedf4d86a9399e49f7937766e6de2a3aab",
                "bridgeContract": "https://bscscan.com/address/0x0ac4a2f14927c7e038a3962b647dc7527d8a7229",
                "coingeckoId": "krown",
                "twitter": "https://twitter.com/kingdefi2",
                "website": "https://kingdefi.io"
            }
        },
        {
            "chainId": 101,
            "address": "p9tNnBf4PDA7WSSFj5EVZddai6WoEiNk5B5FMyeQLtu",
            "symbol": "TMI",
            "name": "TUMI",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/p9tNnBf4PDA7WSSFj5EVZddai6WoEiNk5B5FMyeQLtu/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x71845240485c5339b3192a820f5fa152c093d93a",
                "assetContract": "https://bscscan.com/address/0x71845240485c5339b3192a820f5fa152c093d93a",
                "bridgeContract": "https://bscscan.com/address/0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7",
                "telegram": "https://t.me/cointumi",
                "twitter": "https://twitter.com/tumicommunity",
                "website": "https://cointumi.com"
            }
        },
        {
            "chainId": 101,
            "address": "EJKqF4p7xVhXkcDNCrVQJE4osow76226bc6u3AtsGXaG",
            "symbol": "wAPY_v1",
            "name": "APY Governance Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EJKqF4p7xVhXkcDNCrVQJE4osow76226bc6u3AtsGXaG/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x95a4492F028aa1fd432Ea71146b433E7B4446611",
                "assetContract": "https://etherscan.io/address/0x95a4492F028aa1fd432Ea71146b433E7B4446611",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "apy-finance"
            }
        },
        {
            "chainId": 101,
            "address": "AF7Dv5Vzi1dT2fLnz4ysiRQ6FxGN1M6mrmHwgNpx7FVH",
            "symbol": "wOCEAN_v1",
            "name": "Ocean Protocol (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AF7Dv5Vzi1dT2fLnz4ysiRQ6FxGN1M6mrmHwgNpx7FVH/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x967da4048cD07aB37855c090aAF366e4ce1b9F48",
                "assetContract": "https://etherscan.io/address/0x967da4048cD07aB37855c090aAF366e4ce1b9F48",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "ocean-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "3UeKTABxz9XexDtyKq646rSQvx8GVpKNwfMoKKfxsTsF",
            "symbol": "wBBTC_v1",
            "name": "Binance Wrapped BTC (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3UeKTABxz9XexDtyKq646rSQvx8GVpKNwfMoKKfxsTsF/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x9BE89D2a4cd102D8Fecc6BF9dA793be995C22541",
                "assetContract": "https://etherscan.io/address/0x9BE89D2a4cd102D8Fecc6BF9dA793be995C22541",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "binance-wrapped-btc"
            }
        },
        {
            "chainId": 101,
            "address": "DsGbyCHbG4vSWBqAprR2eWuUAg8fXAgYkWL9psgvYZn5",
            "symbol": "wUNISTAKE_v1",
            "name": "Unistake (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DsGbyCHbG4vSWBqAprR2eWuUAg8fXAgYkWL9psgvYZn5/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x9Ed8e7C9604790F7Ec589F99b94361d8AAB64E5E",
                "assetContract": "https://etherscan.io/address/0x9Ed8e7C9604790F7Ec589F99b94361d8AAB64E5E",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "unistake"
            }
        },
        {
            "chainId": 101,
            "address": "GBvv3jn9u6pZqPd2GVnQ7BKJzLwQnEWe4ci9k359PN9Z",
            "symbol": "wMKR_v1",
            "name": "MakerDAO (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GBvv3jn9u6pZqPd2GVnQ7BKJzLwQnEWe4ci9k359PN9Z/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
                "assetContract": "https://etherscan.io/address/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "maker"
            }
        },
        {
            "chainId": 101,
            "address": "53ETjuzUNHG8c7rZ2hxQLQfN5R6tEYtdYwNQsa68xFUk",
            "symbol": "wFARM_v1",
            "name": "FARM Reward Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/53ETjuzUNHG8c7rZ2hxQLQfN5R6tEYtdYwNQsa68xFUk/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa0246c9032bC3A600820415aE600c6388619A14D",
                "assetContract": "https://etherscan.io/address/0xa0246c9032bC3A600820415aE600c6388619A14D",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "harvest-finance"
            }
        },
        {
            "chainId": 101,
            "address": "FVsXUnbhifqJ4LiXQEbpUtXVdB8T5ADLKqSs5t1oc54F",
            "symbol": "wUSDC_v1",
            "name": "USD Coin (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FVsXUnbhifqJ4LiXQEbpUtXVdB8T5ADLKqSs5t1oc54F/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                "assetContract": "https://etherscan.io/address/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "EjBpnWzWZeW1PKzfCszLdHgENZLZDoTNaEmz8BddpWJx",
            "symbol": "wANT_v1",
            "name": "Aragon Network Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjBpnWzWZeW1PKzfCszLdHgENZLZDoTNaEmz8BddpWJx/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa117000000f279D81A1D3cc75430fAA017FA5A2e",
                "assetContract": "https://etherscan.io/address/0xa117000000f279D81A1D3cc75430fAA017FA5A2e",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aragon"
            }
        },
        {
            "chainId": 101,
            "address": "Rs4LHZ4WogZCAkCzfsKJib5LLnYL6xcVAfTcLQiSjg2",
            "symbol": "wNPXS_v1",
            "name": "Pundi X Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Rs4LHZ4WogZCAkCzfsKJib5LLnYL6xcVAfTcLQiSjg2/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xA15C7Ebe1f07CaF6bFF097D8a589fb8AC49Ae5B3",
                "assetContract": "https://etherscan.io/address/0xA15C7Ebe1f07CaF6bFF097D8a589fb8AC49Ae5B3",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "pundi-x"
            }
        },
        {
            "chainId": 101,
            "address": "65ribugkb42AANKYrEeuruhhfXffyE4jY22FUxFbpW7C",
            "symbol": "wRFOX_v1",
            "name": "RFOX (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/65ribugkb42AANKYrEeuruhhfXffyE4jY22FUxFbpW7C/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa1d6Df714F91DeBF4e0802A542E13067f31b8262",
                "assetContract": "https://etherscan.io/address/0xa1d6Df714F91DeBF4e0802A542E13067f31b8262",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "redfox-labs-2"
            }
        },
        {
            "chainId": 101,
            "address": "T2mo6dnFiutu26KMuCMSjCLBB4ofWvQ3qBJGEMc3JSe",
            "symbol": "wMTA_v1",
            "name": "Meta (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T2mo6dnFiutu26KMuCMSjCLBB4ofWvQ3qBJGEMc3JSe/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2",
                "assetContract": "https://etherscan.io/address/0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "meta"
            }
        },
        {
            "chainId": 101,
            "address": "HC8SaUm9rhvVZE5ZwBWiUhFAnCuG8byd5FxKYdpFm5MR",
            "symbol": "wRBC_v1",
            "name": "Rubic (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HC8SaUm9rhvVZE5ZwBWiUhFAnCuG8byd5FxKYdpFm5MR/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3",
                "assetContract": "https://etherscan.io/address/0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "rubic"
            }
        },
        {
            "chainId": 101,
            "address": "9DdtKWoK8cBfLSLhHXHFZzzhxp4rdwHbFEAis8n5AsfQ",
            "symbol": "wNOIA_v1",
            "name": "NOIA Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9DdtKWoK8cBfLSLhHXHFZzzhxp4rdwHbFEAis8n5AsfQ/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa8c8CfB141A3bB59FEA1E2ea6B79b5ECBCD7b6ca",
                "assetContract": "https://etherscan.io/address/0xa8c8CfB141A3bB59FEA1E2ea6B79b5ECBCD7b6ca",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "noia-network"
            }
        },
        {
            "chainId": 101,
            "address": "DTQStP2z4DRqbNHRxtwThAujr9aPFPsv4y2kkXTVLVvb",
            "symbol": "wCEL_v1",
            "name": "Celsius (Wormhole v1)",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTQStP2z4DRqbNHRxtwThAujr9aPFPsv4y2kkXTVLVvb/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d",
                "assetContract": "https://etherscan.io/address/0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "celsius-degree-token"
            }
        },
        {
            "chainId": 101,
            "address": "59NPV18vAbTgwC9aeEGikrmX3EbZHMEMkZfvcsHBNFr9",
            "symbol": "wCWS_v1",
            "name": "Crowns (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/59NPV18vAbTgwC9aeEGikrmX3EbZHMEMkZfvcsHBNFr9/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xaC0104Cca91D167873B8601d2e71EB3D4D8c33e0",
                "assetContract": "https://etherscan.io/address/0xaC0104Cca91D167873B8601d2e71EB3D4D8c33e0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "crowns"
            }
        },
        {
            "chainId": 101,
            "address": "4811JP9i35zgAxSFZjGXQwew6xd1qSBE4xdMFik2J14Z",
            "symbol": "wROOM_v1",
            "name": "OptionRoom Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4811JP9i35zgAxSFZjGXQwew6xd1qSBE4xdMFik2J14Z/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xAd4f86a25bbc20FfB751f2FAC312A0B4d8F88c64",
                "assetContract": "https://etherscan.io/address/0xAd4f86a25bbc20FfB751f2FAC312A0B4d8F88c64",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "option-room"
            }
        },
        {
            "chainId": 101,
            "address": "2VAdvHWMpzMnDYYn64MgqLNpGQ19iCiusCet8JLMtxU5",
            "symbol": "wYOP_v1",
            "name": "YOP (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2VAdvHWMpzMnDYYn64MgqLNpGQ19iCiusCet8JLMtxU5/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xAE1eaAE3F627AAca434127644371b67B18444051",
                "assetContract": "https://etherscan.io/address/0xAE1eaAE3F627AAca434127644371b67B18444051",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "yield-optimization-platform"
            }
        },
        {
            "chainId": 101,
            "address": "AKiTcEWZarsnUbKkwQVRjJni5eqwiNeBQsJ3nrADacT4",
            "symbol": "wLGCY_v1",
            "name": "LGCY Network (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKiTcEWZarsnUbKkwQVRjJni5eqwiNeBQsJ3nrADacT4/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xaE697F994Fc5eBC000F8e22EbFfeE04612f98A0d",
                "assetContract": "https://etherscan.io/address/0xaE697F994Fc5eBC000F8e22EbFfeE04612f98A0d",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "lgcy-network"
            }
        },
        {
            "chainId": 101,
            "address": "4kPHTMfSD1k3SytAMKEVRWH5ip6WD5U52tC5q6TuXUNU",
            "symbol": "wRFuel_v1",
            "name": "Rio Fuel Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4kPHTMfSD1k3SytAMKEVRWH5ip6WD5U52tC5q6TuXUNU/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xaf9f549774ecEDbD0966C52f250aCc548D3F36E5",
                "assetContract": "https://etherscan.io/address/0xaf9f549774ecEDbD0966C52f250aCc548D3F36E5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "rio-defi"
            }
        },
        {
            "chainId": 101,
            "address": "E1w2uKRsVJeDf1Qqbk7DDKEDe7NCYwh8ySgqCaEZ4BTC",
            "symbol": "wMAHA_v1",
            "name": "MahaDAO (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1w2uKRsVJeDf1Qqbk7DDKEDe7NCYwh8ySgqCaEZ4BTC/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xB4d930279552397bbA2ee473229f89Ec245bc365",
                "assetContract": "https://etherscan.io/address/0xB4d930279552397bbA2ee473229f89Ec245bc365",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "mahadao"
            }
        },
        {
            "chainId": 101,
            "address": "4psmnTirimNyPEPEZtkQkdEPJagTXS3a7wsu1XN9MYK3",
            "symbol": "wRPL_v1",
            "name": "Rocket Pool (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4psmnTirimNyPEPEZtkQkdEPJagTXS3a7wsu1XN9MYK3/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xB4EFd85c19999D84251304bDA99E90B92300Bd93",
                "assetContract": "https://etherscan.io/address/0xB4EFd85c19999D84251304bDA99E90B92300Bd93",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "rocket-pool"
            }
        },
        {
            "chainId": 101,
            "address": "FrhQauNRm7ecom9FRprNcyz58agDe5ujAbAtA9NG6jtU",
            "symbol": "wNEXO_v1",
            "name": "Nexo (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FrhQauNRm7ecom9FRprNcyz58agDe5ujAbAtA9NG6jtU/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206",
                "assetContract": "https://etherscan.io/address/0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "nexo"
            }
        },
        {
            "chainId": 101,
            "address": "6G7X1B2f9F7KWcHxS66mn3ax6VPE2UMZud44RX3BzfVo",
            "symbol": "BEHZAT",
            "name": "Behzat Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6G7X1B2f9F7KWcHxS66mn3ax6VPE2UMZud44RX3BzfVo/logo.png",
            "tags": [
                "Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Tunay32718753"
            }
        },
        {
            "chainId": 101,
            "address": "AoU75vwpnWEVvfarxRALjzRc8vS9UdDhRMkwoDimt9ss",
            "symbol": "wSFI_v1",
            "name": "Spice (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AoU75vwpnWEVvfarxRALjzRc8vS9UdDhRMkwoDimt9ss/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xb753428af26E81097e7fD17f40c88aaA3E04902c",
                "assetContract": "https://etherscan.io/address/0xb753428af26E81097e7fD17f40c88aaA3E04902c",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "saffron-finance"
            }
        },
        {
            "chainId": 101,
            "address": "CRZuALvCYjPLB65WFLHh9JkmPWK5C81TXpy2aEEaCjr3",
            "symbol": "wSTBZ_v1",
            "name": "Stabilize Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRZuALvCYjPLB65WFLHh9JkmPWK5C81TXpy2aEEaCjr3/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xB987D48Ed8f2C468D52D6405624EADBa5e76d723",
                "assetContract": "https://etherscan.io/address/0xB987D48Ed8f2C468D52D6405624EADBa5e76d723",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "stabilize"
            }
        },
        {
            "chainId": 101,
            "address": "HPYXGSdAwyK5GwmuivL8gDdUVRChtgXq6SRat44k4Pat",
            "symbol": "wBAL_v1",
            "name": "Balancer (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HPYXGSdAwyK5GwmuivL8gDdUVRChtgXq6SRat44k4Pat/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xba100000625a3754423978a60c9317c58a424e3D",
                "assetContract": "https://etherscan.io/address/0xba100000625a3754423978a60c9317c58a424e3D",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "balancer"
            }
        },
        {
            "chainId": 101,
            "address": "AV7NgJV2BsgEukzUTrcUMz3LD37xLcLtygFig5WJ3kQN",
            "symbol": "wBAND_v1",
            "name": "BandToken (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AV7NgJV2BsgEukzUTrcUMz3LD37xLcLtygFig5WJ3kQN/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55",
                "assetContract": "https://etherscan.io/address/0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "band-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "4obZok5FFUcQXQoV39hhcqk9xSmo4WnP9wnrNCk1g5BC",
            "symbol": "wSWFL_v1",
            "name": "Swapfolio (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4obZok5FFUcQXQoV39hhcqk9xSmo4WnP9wnrNCk1g5BC/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xBa21Ef4c9f433Ede00badEFcC2754B8E74bd538A",
                "assetContract": "https://etherscan.io/address/0xBa21Ef4c9f433Ede00badEFcC2754B8E74bd538A",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "swapfolio"
            }
        },
        {
            "chainId": 101,
            "address": "HCP8hGKS6fUGfTA1tQxBKzbXuQk7yktzz71pY8LXVJyR",
            "symbol": "wLRC_v1",
            "name": "LoopringCoin V2 (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCP8hGKS6fUGfTA1tQxBKzbXuQk7yktzz71pY8LXVJyR/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD",
                "assetContract": "https://etherscan.io/address/0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "loopring"
            }
        },
        {
            "chainId": 101,
            "address": "7kpzQByqsfmZSX5Y71YtncBvuhFVFJBLUvJKqqNMfT8P",
            "symbol": "TSK",
            "name": "TaskDapp",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kpzQByqsfmZSX5Y71YtncBvuhFVFJBLUvJKqqNMfT8P/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/task_dapp",
                "website": "https://taskdapp.io"
            }
        },
        {
            "chainId": 101,
            "address": "9sNArcS6veh7DLEo7Y1ZSbBCYtkuPVE6S3HhVrcWR2Zw",
            "symbol": "wPERP_v1",
            "name": "Perpetual (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9sNArcS6veh7DLEo7Y1ZSbBCYtkuPVE6S3HhVrcWR2Zw/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xbC396689893D065F41bc2C6EcbeE5e0085233447",
                "assetContract": "https://etherscan.io/address/0xbC396689893D065F41bc2C6EcbeE5e0085233447",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "perpetual-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "3XnhArdJydrpbr9Nbj8wNUaozPL9WAo9YDyNWakhTm9X",
            "symbol": "wCOMP_v1",
            "name": "Compound (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3XnhArdJydrpbr9Nbj8wNUaozPL9WAo9YDyNWakhTm9X/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc00e94Cb662C3520282E6f5717214004A7f26888",
                "assetContract": "https://etherscan.io/address/0xc00e94Cb662C3520282E6f5717214004A7f26888",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "compound"
            }
        },
        {
            "chainId": 101,
            "address": "CPLNm9UMKfiJKiySQathV99yeSgTVjPDZx4ucFrbp2MD",
            "symbol": "wSNX_v1",
            "name": "Synthetix Network Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPLNm9UMKfiJKiySQathV99yeSgTVjPDZx4ucFrbp2MD/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
                "assetContract": "https://etherscan.io/address/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "synthetix-network-token"
            }
        },
        {
            "chainId": 101,
            "address": "D6eVKSfLdioqo2zG8LbQYFU2gf66FrjKA7afCYNo1GHt",
            "symbol": "wDUCK_v1",
            "name": "DLP Duck Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D6eVKSfLdioqo2zG8LbQYFU2gf66FrjKA7afCYNo1GHt/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xC0bA369c8Db6eB3924965e5c4FD0b4C1B91e305F",
                "assetContract": "https://etherscan.io/address/0xC0bA369c8Db6eB3924965e5c4FD0b4C1B91e305F",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "dlp-duck-token"
            }
        },
        {
            "chainId": 101,
            "address": "9PwPi3DAf9Dy4Y6qJmUzF6fX9CjNwScBidsYqJmcApF8",
            "symbol": "wCHAIN_v1",
            "name": "Chain Games (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9PwPi3DAf9Dy4Y6qJmUzF6fX9CjNwScBidsYqJmcApF8/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xC4C2614E694cF534D407Ee49F8E44D125E4681c4",
                "assetContract": "https://etherscan.io/address/0xC4C2614E694cF534D407Ee49F8E44D125E4681c4",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "chain-games"
            }
        },
        {
            "chainId": 101,
            "address": "BmxZ1pghpcoyT7aykj7D1o4AxWirTqvD7zD2tNngjirT",
            "symbol": "wGRT_v1",
            "name": "Graph Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BmxZ1pghpcoyT7aykj7D1o4AxWirTqvD7zD2tNngjirT/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc944E90C64B2c07662A292be6244BDf05Cda44a7",
                "assetContract": "https://etherscan.io/address/0xc944E90C64B2c07662A292be6244BDf05Cda44a7",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "the-graph"
            }
        },
        {
            "chainId": 101,
            "address": "FMr15arp651N6fR2WEL36pCMBnFecHcN6wDxne2Vf3SK",
            "symbol": "wROOT_v1",
            "name": "RootKit (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FMr15arp651N6fR2WEL36pCMBnFecHcN6wDxne2Vf3SK/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xCb5f72d37685C3D5aD0bB5F982443BC8FcdF570E",
                "assetContract": "https://etherscan.io/address/0xCb5f72d37685C3D5aD0bB5F982443BC8FcdF570E",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "rootkit"
            }
        },
        {
            "chainId": 101,
            "address": "E9X7rKAGfSh1gsHC6qh5MVLkDzRcT64KQbjzvHnc5zEq",
            "symbol": "wSWAP_v1",
            "name": "TrustSwap Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9X7rKAGfSh1gsHC6qh5MVLkDzRcT64KQbjzvHnc5zEq/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xCC4304A31d09258b0029eA7FE63d032f52e44EFe",
                "assetContract": "https://etherscan.io/address/0xCC4304A31d09258b0029eA7FE63d032f52e44EFe",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "trustswap"
            }
        },
        {
            "chainId": 101,
            "address": "5NEENV1mNvu7MfNNtKuGSDC8zoNStq1tuLkDXFtv6rZd",
            "symbol": "wTVK_v1",
            "name": "Terra Virtua Kolect (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5NEENV1mNvu7MfNNtKuGSDC8zoNStq1tuLkDXFtv6rZd/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xd084B83C305daFD76AE3E1b4E1F1fe2eCcCb3988",
                "assetContract": "https://etherscan.io/address/0xd084B83C305daFD76AE3E1b4E1F1fe2eCcCb3988",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "terra-virtua-kolect"
            }
        },
        {
            "chainId": 101,
            "address": "5ZXLGj7onpitgtREJNYb51DwDPddvqV1YLC8jn2sgz48",
            "symbol": "wOMG_v1",
            "name": "OMG Network (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5ZXLGj7onpitgtREJNYb51DwDPddvqV1YLC8jn2sgz48/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xd26114cd6EE289AccF82350c8d8487fedB8A0C07",
                "assetContract": "https://etherscan.io/address/0xd26114cd6EE289AccF82350c8d8487fedB8A0C07",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "omisego"
            }
        },
        {
            "chainId": 101,
            "address": "2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV",
            "symbol": "wLUNA_v1",
            "name": "Wrapped LUNA Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xd2877702675e6cEb975b4A1dFf9fb7BAF4C91ea9",
                "assetContract": "https://etherscan.io/address/0xd2877702675e6cEb975b4A1dFf9fb7BAF4C91ea9",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "wrapped-terra"
            }
        },
        {
            "chainId": 101,
            "address": "5Ro6JxJ4NjSTEppdX2iXUYgWkAEF1dcs9gqMX99E2vkL",
            "symbol": "wBONDLY_v1",
            "name": "Bondly Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Ro6JxJ4NjSTEppdX2iXUYgWkAEF1dcs9gqMX99E2vkL/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xD2dDa223b2617cB616c1580db421e4cFAe6a8a85",
                "assetContract": "https://etherscan.io/address/0xD2dDa223b2617cB616c1580db421e4cFAe6a8a85",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "bondly"
            }
        },
        {
            "chainId": 101,
            "address": "5jFzUEqWLnvGvKWb1Pji9nWVYy5vLG2saoXCyVNWEdEi",
            "symbol": "wDETS_v1",
            "name": "Dextrust (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5jFzUEqWLnvGvKWb1Pji9nWVYy5vLG2saoXCyVNWEdEi/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xd379700999F4805Ce80aa32DB46A94dF64561108",
                "assetContract": "https://etherscan.io/address/0xd379700999F4805Ce80aa32DB46A94dF64561108",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "dextrust"
            }
        },
        {
            "chainId": 101,
            "address": "BV5tm1uCRWQCQKNgQVFnkseqAjxpmbJkRCXvzFWBdgMp",
            "symbol": "wAMPL_v1",
            "name": "Ampleforth (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BV5tm1uCRWQCQKNgQVFnkseqAjxpmbJkRCXvzFWBdgMp/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xD46bA6D942050d489DBd938a2C909A5d5039A161",
                "assetContract": "https://etherscan.io/address/0xD46bA6D942050d489DBd938a2C909A5d5039A161",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "ampleforth"
            }
        },
        {
            "chainId": 101,
            "address": "2PSvGigDY4MVUmv51bBiARBMcHBtXcUBnx5V9BwWbbi2",
            "symbol": "wPOLK_v1",
            "name": "Polkamarkets (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2PSvGigDY4MVUmv51bBiARBMcHBtXcUBnx5V9BwWbbi2/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xD478161C952357F05f0292B56012Cd8457F1cfbF",
                "assetContract": "https://etherscan.io/address/0xD478161C952357F05f0292B56012Cd8457F1cfbF",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "polkamarkets"
            }
        },
        {
            "chainId": 101,
            "address": "ApmXkxXCASdxRf3Ln6Ni7oAZ7E6CX1CcJAD8A5qBdhSm",
            "symbol": "wCRV_v1",
            "name": "Curve DAO Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApmXkxXCASdxRf3Ln6Ni7oAZ7E6CX1CcJAD8A5qBdhSm/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xD533a949740bb3306d119CC777fa900bA034cd52",
                "assetContract": "https://etherscan.io/address/0xD533a949740bb3306d119CC777fa900bA034cd52",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "curve-dao-token"
            }
        },
        {
            "chainId": 101,
            "address": "DWECGzR56MruYJyo5g5QpoxZbFoydt3oWUkkDsVhxXzs",
            "symbol": "wMEME_v1",
            "name": "MEME (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DWECGzR56MruYJyo5g5QpoxZbFoydt3oWUkkDsVhxXzs/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xD5525D397898e5502075Ea5E830d8914f6F0affe",
                "assetContract": "https://etherscan.io/address/0xD5525D397898e5502075Ea5E830d8914f6F0affe",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "degenerator"
            }
        },
        {
            "chainId": 101,
            "address": "3Y2wTtM4kCX8uUSLrKJ8wpajCu1C9LaWWAd7b7Nb2BDw",
            "symbol": "wEXNT_v1",
            "name": "ExNetwork Community Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Y2wTtM4kCX8uUSLrKJ8wpajCu1C9LaWWAd7b7Nb2BDw/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xD6c67B93a7b248dF608a653d82a100556144c5DA",
                "assetContract": "https://etherscan.io/address/0xD6c67B93a7b248dF608a653d82a100556144c5DA",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "exnetwork-token"
            }
        },
        {
            "chainId": 101,
            "address": "9w97GdWUYYaamGwdKMKZgGzPduZJkiFizq4rz5CPXRv2",
            "symbol": "wUSDT_v1",
            "name": "Tether USD (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9w97GdWUYYaamGwdKMKZgGzPduZJkiFizq4rz5CPXRv2/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                "assetContract": "https://etherscan.io/address/0xdAC17F958D2ee523a2206206994597C13D831ec7",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "CqWSJtkMMY16q9QLnQxktM1byzVHGRr8b6LCPuZnEeiL",
            "symbol": "wYLD_v1",
            "name": "Yield (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CqWSJtkMMY16q9QLnQxktM1byzVHGRr8b6LCPuZnEeiL/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xDcB01cc464238396E213a6fDd933E36796eAfF9f",
                "assetContract": "https://etherscan.io/address/0xDcB01cc464238396E213a6fDd933E36796eAfF9f",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "yield"
            }
        },
        {
            "chainId": 101,
            "address": "26ZzQVGZruwcZPs2sqb8n9ojKt2cviUjHcMjstFtK6ow",
            "symbol": "wKNC_v1",
            "name": "Kyber Network Crystal (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/26ZzQVGZruwcZPs2sqb8n9ojKt2cviUjHcMjstFtK6ow/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xdd974D5C2e2928deA5F71b9825b8b646686BD200",
                "assetContract": "https://etherscan.io/address/0xdd974D5C2e2928deA5F71b9825b8b646686BD200",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "kyber-network"
            }
        },
        {
            "chainId": 101,
            "address": "HHoHTtntq2kiBPENyVM1DTP7pNrkBXX2Jye29PSyz3qf",
            "symbol": "wCOTI_v1",
            "name": "COTI Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HHoHTtntq2kiBPENyVM1DTP7pNrkBXX2Jye29PSyz3qf/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xDDB3422497E61e13543BeA06989C0789117555c5",
                "assetContract": "https://etherscan.io/address/0xDDB3422497E61e13543BeA06989C0789117555c5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "coti"
            }
        },
        {
            "chainId": 101,
            "address": "4sEpUsJ6uJZYi6A2da8EGjKPacRSqYJaPJffPnTqoWVv",
            "symbol": "wINJ_v1",
            "name": "Injective Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4sEpUsJ6uJZYi6A2da8EGjKPacRSqYJaPJffPnTqoWVv/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30",
                "assetContract": "https://etherscan.io/address/0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "injective-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "G2jrxYSoCSzmohxERa2JzSJMuRM4kiNvRA3DnCv7Lzcz",
            "symbol": "wZRX_v1",
            "name": "0x Protocol Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G2jrxYSoCSzmohxERa2JzSJMuRM4kiNvRA3DnCv7Lzcz/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xE41d2489571d322189246DaFA5ebDe1F4699F498",
                "assetContract": "https://etherscan.io/address/0xE41d2489571d322189246DaFA5ebDe1F4699F498",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "0x"
            }
        },
        {
            "chainId": 101,
            "address": "3bkBFHyof411hGBdcsiM1KSDdErw63Xoj3eLB8yNknB4",
            "symbol": "wSUPER_v1",
            "name": "SuperFarm (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3bkBFHyof411hGBdcsiM1KSDdErw63Xoj3eLB8yNknB4/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55",
                "assetContract": "https://etherscan.io/address/0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "superfarm"
            }
        },
        {
            "chainId": 101,
            "address": "7kkkoa1MB93ELm3vjvyC8GJ65G7eEgLhfaHU58riJUCx",
            "symbol": "waEth_v1",
            "name": "aEthereum (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kkkoa1MB93ELm3vjvyC8GJ65G7eEgLhfaHU58riJUCx/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xE95A203B1a91a908F9B9CE46459d101078c2c3cb",
                "assetContract": "https://etherscan.io/address/0xE95A203B1a91a908F9B9CE46459d101078c2c3cb",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "ankreth"
            }
        },
        {
            "chainId": 101,
            "address": "EK6iyvvqvQtsWYcySrZVHkXjCLX494r9PhnDWJaX1CPu",
            "symbol": "wrenBTC_v1",
            "name": "renBTC (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EK6iyvvqvQtsWYcySrZVHkXjCLX494r9PhnDWJaX1CPu/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D",
                "assetContract": "https://etherscan.io/address/0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "renbtc"
            }
        },
        {
            "chainId": 101,
            "address": "B2m4B527oLo5WFWLgy2MitP66azhEW2puaazUAuvNgqZ",
            "symbol": "wDMG_v1",
            "name": "DMM: Governance (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B2m4B527oLo5WFWLgy2MitP66azhEW2puaazUAuvNgqZ/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xEd91879919B71bB6905f23af0A68d231EcF87b14",
                "assetContract": "https://etherscan.io/address/0xEd91879919B71bB6905f23af0A68d231EcF87b14",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "dmm-governance"
            }
        },
        {
            "chainId": 101,
            "address": "H3iuZNRwaqPsnGUGU5YkDwTU3hQMkzC32hxDko8EtzZw",
            "symbol": "wHEZ_v1",
            "name": "Hermez Network Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H3iuZNRwaqPsnGUGU5YkDwTU3hQMkzC32hxDko8EtzZw/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xEEF9f339514298C6A857EfCfC1A762aF84438dEE",
                "assetContract": "https://etherscan.io/address/0xEEF9f339514298C6A857EfCfC1A762aF84438dEE",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "hermez-network-token"
            }
        },
        {
            "chainId": 101,
            "address": "DL7873Hud4eMdGScQFD7vrbC6fzWAMQ2LMuoZSn4zUry",
            "symbol": "wRLY_v1",
            "name": "Rally (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DL7873Hud4eMdGScQFD7vrbC6fzWAMQ2LMuoZSn4zUry/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b",
                "assetContract": "https://etherscan.io/address/0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "rally-2"
            }
        },
        {
            "chainId": 101,
            "address": "HPYP2WUVM8iRDG5XLzTBvcPqVcvY7eWj2Q2fEfwR51zX",
            "symbol": "SC",
            "name": "Swyft Coin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/FinancialBlock/SwyftToken/Swyft.png",
            "tags": [
                "utility-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "website": "http://swyftcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "FN3v94TgcDjae6r9TccXdrThZTy7Ya5b1PMFFdCNo9Fr",
            "symbol": "FAC",
            "name": "Financial Aid Coin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/FinancialBlock/SwyftToken/FAC.png",
            "tags": [
                "utility-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "website": "http://financialaidcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "8ArKbnnDiq8eRR8hZ1eULMjd2iMAD8AqwyVJRAX7mHQo",
            "symbol": "wFCL_v1",
            "name": "Fractal Protocol Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ArKbnnDiq8eRR8hZ1eULMjd2iMAD8AqwyVJRAX7mHQo/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xF4d861575ecC9493420A3f5a14F85B13f0b50EB3",
                "assetContract": "https://etherscan.io/address/0xF4d861575ecC9493420A3f5a14F85B13f0b50EB3",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "fractal"
            }
        },
        {
            "chainId": 101,
            "address": "ZWGxcTgJCNGQqZn6vFdknwj4AFFsYRZ4SDJuhRn3J1T",
            "symbol": "wAXS_v1",
            "name": "Axie Infinity (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZWGxcTgJCNGQqZn6vFdknwj4AFFsYRZ4SDJuhRn3J1T/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xF5D669627376EBd411E34b98F19C868c8ABA5ADA",
                "assetContract": "https://etherscan.io/address/0xF5D669627376EBd411E34b98F19C868c8ABA5ADA",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "axie-infinity"
            }
        },
        {
            "chainId": 101,
            "address": "PEjUEMHFRtfajio8YHKZdUruW1vTzGmz6F7NngjYuou",
            "symbol": "wENJ_v1",
            "name": "Enjin Coin (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PEjUEMHFRtfajio8YHKZdUruW1vTzGmz6F7NngjYuou/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c",
                "assetContract": "https://etherscan.io/address/0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "enjincoin"
            }
        },
        {
            "chainId": 101,
            "address": "2cW5deMKeR97C7csq1aMMWUa5RNWkpQFz8tumxk4ZV8w",
            "symbol": "wYLD_v1",
            "name": "Yield (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cW5deMKeR97C7csq1aMMWUa5RNWkpQFz8tumxk4ZV8w/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xF94b5C5651c888d928439aB6514B93944eEE6F48",
                "assetContract": "https://etherscan.io/address/0xF94b5C5651c888d928439aB6514B93944eEE6F48",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "yield-app"
            }
        },
        {
            "chainId": 101,
            "address": "FR5qPX4gbKHPyKMK7Cey6dHZ7wtqmqRogYPJo6bpd5Uw",
            "symbol": "wDDIM_v1",
            "name": "DuckDaoDime (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FR5qPX4gbKHPyKMK7Cey6dHZ7wtqmqRogYPJo6bpd5Uw/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xFbEEa1C75E4c4465CB2FCCc9c6d6afe984558E20",
                "assetContract": "https://etherscan.io/address/0xFbEEa1C75E4c4465CB2FCCc9c6d6afe984558E20",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "duckdaodime"
            }
        },
        {
            "chainId": 101,
            "address": "8HCWFQA2GsA6Nm2L5jidM3mus7NeeQ8wp1ri3XFF9WWH",
            "symbol": "wRARI_v1",
            "name": "Rarible (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8HCWFQA2GsA6Nm2L5jidM3mus7NeeQ8wp1ri3XFF9WWH/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF",
                "assetContract": "https://etherscan.io/address/0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "rarible"
            }
        },
        {
            "chainId": 101,
            "address": "Egrv6hURf5o68xJ1AGYeRv8RNj2nXJVuSoA5wwiSALcN",
            "symbol": "wAMP_v1",
            "name": "Amp (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Egrv6hURf5o68xJ1AGYeRv8RNj2nXJVuSoA5wwiSALcN/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xfF20817765cB7f73d4bde2e66e067E58D11095C2",
                "assetContract": "https://etherscan.io/address/0xfF20817765cB7f73d4bde2e66e067E58D11095C2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "amp-token"
            }
        },
        {
            "chainId": 101,
            "address": "GXMaB6jm5cdoQgb65YpkEu61eDYtod3PuVwYYXdZZJ9r",
            "symbol": "wFSW_v1",
            "name": "FalconSwap Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXMaB6jm5cdoQgb65YpkEu61eDYtod3PuVwYYXdZZJ9r/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xfffffffFf15AbF397dA76f1dcc1A1604F45126DB",
                "assetContract": "https://etherscan.io/address/0xfffffffFf15AbF397dA76f1dcc1A1604F45126DB",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "fsw-token"
            }
        },
        {
            "chainId": 101,
            "address": "AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX",
            "symbol": "wBUSD_v1",
            "name": "Binance USD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
                "assetContract": "https://etherscan.io/address/0x4Fabb145d64652a948d72533023f6E7A623C7C53",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "binance-usd"
            }
        },
        {
            "chainId": 101,
            "address": "2VmKuXMwdzouMndWcK7BK2951tBEtYVmGsdU4dXbjyaY",
            "symbol": "waDAI_v1",
            "name": "Aave Interest bearing DAI (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2VmKuXMwdzouMndWcK7BK2951tBEtYVmGsdU4dXbjyaY/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d",
                "assetContract": "https://etherscan.io/address/0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-dai-v1"
            }
        },
        {
            "chainId": 101,
            "address": "AXvWVviBmySSdghmuomYHqYB3AZn7NmAWrHYHKKPJxoL",
            "symbol": "waTUSD_v1",
            "name": "Aave Interest bearing TUSD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AXvWVviBmySSdghmuomYHqYB3AZn7NmAWrHYHKKPJxoL/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4DA9b813057D04BAef4e5800E36083717b4a0341",
                "assetContract": "https://etherscan.io/address/0x4DA9b813057D04BAef4e5800E36083717b4a0341",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-tusd-v1"
            }
        },
        {
            "chainId": 101,
            "address": "AkaisFPmasQYZUJsZLD9wPEo2KA7aCRqyRawX18ZRzGr",
            "symbol": "waUSDC_v1",
            "name": "Aave Interest bearing USDC (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkaisFPmasQYZUJsZLD9wPEo2KA7aCRqyRawX18ZRzGr/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x9bA00D6856a4eDF4665BcA2C2309936572473B7E",
                "assetContract": "https://etherscan.io/address/0x9bA00D6856a4eDF4665BcA2C2309936572473B7E",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-usdc-v1"
            }
        },
        {
            "chainId": 101,
            "address": "FZfQtWMoTQ51Z4jxvHfmFcqj4862u9GzmugBnZUuWqR5",
            "symbol": "waUSDT_v1",
            "name": "Aave Interest bearing USDT (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZfQtWMoTQ51Z4jxvHfmFcqj4862u9GzmugBnZUuWqR5/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x71fc860F7D3A592A4a98740e39dB31d25db65ae8",
                "assetContract": "https://etherscan.io/address/0x71fc860F7D3A592A4a98740e39dB31d25db65ae8",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-usdt-v1"
            }
        },
        {
            "chainId": 101,
            "address": "BMrbF8DZ9U5KGdJ4F2MJbH5d6KPi5FQVp7EqmLrhDe1f",
            "symbol": "waSUSD_v1",
            "name": "Aave Interest bearing SUSD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BMrbF8DZ9U5KGdJ4F2MJbH5d6KPi5FQVp7EqmLrhDe1f/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x625aE63000f46200499120B906716420bd059240",
                "assetContract": "https://etherscan.io/address/0x625aE63000f46200499120B906716420bd059240",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-susd-v1"
            }
        },
        {
            "chainId": 101,
            "address": "Fzx4N1xJPDZENAhrAaH79k2izT9CFbfnDEcpcWjiusdY",
            "symbol": "waLEND_v1",
            "name": "Aave Interest bearing LEND (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fzx4N1xJPDZENAhrAaH79k2izT9CFbfnDEcpcWjiusdY/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7D2D3688Df45Ce7C552E19c27e007673da9204B8",
                "assetContract": "https://etherscan.io/address/0x7D2D3688Df45Ce7C552E19c27e007673da9204B8",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "GCdDiVgZnkWCAnGktUsjhoho2CHab9JfrRy3Q5W51zvC",
            "symbol": "waBAT_v1",
            "name": "Aave Interest bearing BAT (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GCdDiVgZnkWCAnGktUsjhoho2CHab9JfrRy3Q5W51zvC/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xE1BA0FB44CCb0D11b80F92f4f8Ed94CA3fF51D00",
                "assetContract": "https://etherscan.io/address/0xE1BA0FB44CCb0D11b80F92f4f8Ed94CA3fF51D00",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-bat-v1"
            }
        },
        {
            "chainId": 101,
            "address": "FBrfFh7fb7xKfyBMJA32KufMjEkgSgY4AuzLXFKdJFRj",
            "symbol": "waETH_v1",
            "name": "Aave Interest bearing ETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBrfFh7fb7xKfyBMJA32KufMjEkgSgY4AuzLXFKdJFRj/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x3a3A65aAb0dd2A17E3F1947bA16138cd37d08c04",
                "assetContract": "https://etherscan.io/address/0x3a3A65aAb0dd2A17E3F1947bA16138cd37d08c04",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-eth-v1"
            }
        },
        {
            "chainId": 101,
            "address": "Adp88WrQDgExPTu26DdBnbN2ffWMkXLxwqzjTdfRQiJi",
            "symbol": "waLINK_v1",
            "name": "Aave Interest bearing LINK (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Adp88WrQDgExPTu26DdBnbN2ffWMkXLxwqzjTdfRQiJi/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xA64BD6C70Cb9051F6A9ba1F163Fdc07E0DfB5F84",
                "assetContract": "https://etherscan.io/address/0xA64BD6C70Cb9051F6A9ba1F163Fdc07E0DfB5F84",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-link-v1"
            }
        },
        {
            "chainId": 101,
            "address": "3p67dqghWn6reQcVCqNBkufrpU1gtA1ZRAYja6GMXySG",
            "symbol": "waKNC_v1",
            "name": "Aave Interest bearing KNC (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3p67dqghWn6reQcVCqNBkufrpU1gtA1ZRAYja6GMXySG/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x9D91BE44C06d373a8a226E1f3b146956083803eB",
                "assetContract": "https://etherscan.io/address/0x9D91BE44C06d373a8a226E1f3b146956083803eB",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-knc-v1"
            }
        },
        {
            "chainId": 101,
            "address": "A4qYX1xuewaBL9SeZnwA3We6MhG8TYcTceHAJpk7Etdt",
            "symbol": "waREP_v1",
            "name": "Aave Interest bearing REP (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A4qYX1xuewaBL9SeZnwA3We6MhG8TYcTceHAJpk7Etdt/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x71010A9D003445aC60C4e6A7017c1E89A477B438",
                "assetContract": "https://etherscan.io/address/0x71010A9D003445aC60C4e6A7017c1E89A477B438",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "3iTtcKUVa5ouzwNZFc3SasuAKkY2ZuMxLERRcWfxQVN3",
            "symbol": "waMKR_v1",
            "name": "Aave Interest bearing MKR (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3iTtcKUVa5ouzwNZFc3SasuAKkY2ZuMxLERRcWfxQVN3/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7deB5e830be29F91E298ba5FF1356BB7f8146998",
                "assetContract": "https://etherscan.io/address/0x7deB5e830be29F91E298ba5FF1356BB7f8146998",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-mkr-v1"
            }
        },
        {
            "chainId": 101,
            "address": "EMS6TrCU8uBMumZukRSShGS1yzHGqYd3S8hW2sYULX3T",
            "symbol": "waMANA_v1",
            "name": "Aave Interest bearing MANA (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EMS6TrCU8uBMumZukRSShGS1yzHGqYd3S8hW2sYULX3T/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6FCE4A401B6B80ACe52baAefE4421Bd188e76F6f",
                "assetContract": "https://etherscan.io/address/0x6FCE4A401B6B80ACe52baAefE4421Bd188e76F6f",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-mana-v1"
            }
        },
        {
            "chainId": 101,
            "address": "qhqzfH7AjeukUgqyPXncWHFXTBebFNu5QQUrzhJaLB4",
            "symbol": "waZRX_v1",
            "name": "Aave Interest bearing ZRX (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qhqzfH7AjeukUgqyPXncWHFXTBebFNu5QQUrzhJaLB4/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6Fb0855c404E09c47C3fBCA25f08d4E41f9F062f",
                "assetContract": "https://etherscan.io/address/0x6Fb0855c404E09c47C3fBCA25f08d4E41f9F062f",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-zrx-v1"
            }
        },
        {
            "chainId": 101,
            "address": "FeU2J26AfMqh2mh7Cf4Lw1HRueAvAkZYxGr8njFNMeQ2",
            "symbol": "waSNX_v1",
            "name": "Aave Interest bearing SNX (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeU2J26AfMqh2mh7Cf4Lw1HRueAvAkZYxGr8njFNMeQ2/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x328C4c80BC7aCa0834Db37e6600A6c49E12Da4DE",
                "assetContract": "https://etherscan.io/address/0x328C4c80BC7aCa0834Db37e6600A6c49E12Da4DE",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-snx-v1"
            }
        },
        {
            "chainId": 101,
            "address": "GveRVvWTUH1s26YxyjUnXh1J5mMdu5crC2K2uQy26KXi",
            "symbol": "waWBTC_v1",
            "name": "Aave Interest bearing WBTC (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GveRVvWTUH1s26YxyjUnXh1J5mMdu5crC2K2uQy26KXi/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xFC4B8ED459e00e5400be803A9BB3954234FD50e3",
                "assetContract": "https://etherscan.io/address/0xFC4B8ED459e00e5400be803A9BB3954234FD50e3",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-wbtc-v1"
            }
        },
        {
            "chainId": 101,
            "address": "F2WgoHLwV4pfxN4WrUs2q6KkmFCsNorGYQ82oaPNUFLP",
            "symbol": "waBUSD_v1",
            "name": "Aave Interest bearing Binance USD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F2WgoHLwV4pfxN4WrUs2q6KkmFCsNorGYQ82oaPNUFLP/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6Ee0f7BB50a54AB5253dA0667B0Dc2ee526C30a8",
                "assetContract": "https://etherscan.io/address/0x6Ee0f7BB50a54AB5253dA0667B0Dc2ee526C30a8",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-busd-v1"
            }
        },
        {
            "chainId": 101,
            "address": "3rNUQJgvfZ5eFsZvCkvdYcbd9ZzS6YmtwQsoUTFKmVd4",
            "symbol": "waENJ_v1",
            "name": "Aave Interest bearing ENJ (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3rNUQJgvfZ5eFsZvCkvdYcbd9ZzS6YmtwQsoUTFKmVd4/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x712DB54daA836B53Ef1EcBb9c6ba3b9Efb073F40",
                "assetContract": "https://etherscan.io/address/0x712DB54daA836B53Ef1EcBb9c6ba3b9Efb073F40",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-enj-v1"
            }
        },
        {
            "chainId": 101,
            "address": "BHh8nyDwdUG4uyyQYNqGXGLHPyb83R6Y2fqJrNVKtTsT",
            "symbol": "waREN_v1",
            "name": "Aave Interest bearing REN (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BHh8nyDwdUG4uyyQYNqGXGLHPyb83R6Y2fqJrNVKtTsT/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x69948cC03f478B95283F7dbf1CE764d0fc7EC54C",
                "assetContract": "https://etherscan.io/address/0x69948cC03f478B95283F7dbf1CE764d0fc7EC54C",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-ren-v1"
            }
        },
        {
            "chainId": 101,
            "address": "EE58FVYG1UoY6Givy3K3GSRde9sHMj6X1BnocHBtd3sz",
            "symbol": "waYFI_v1",
            "name": "Aave Interest bearing YFI (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EE58FVYG1UoY6Givy3K3GSRde9sHMj6X1BnocHBtd3sz/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x12e51E77DAAA58aA0E9247db7510Ea4B46F9bEAd",
                "assetContract": "https://etherscan.io/address/0x12e51E77DAAA58aA0E9247db7510Ea4B46F9bEAd",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "ayfi"
            }
        },
        {
            "chainId": 101,
            "address": "8aYsiHR6oVTAcFUzdXDhaPkgRbn4QYRCkdk3ATmAmY4p",
            "symbol": "waAAVE_v1",
            "name": "Aave Interest bearing Aave Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8aYsiHR6oVTAcFUzdXDhaPkgRbn4QYRCkdk3ATmAmY4p/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xba3D9687Cf50fE253cd2e1cFeEdE1d6787344Ed5",
                "assetContract": "https://etherscan.io/address/0xba3D9687Cf50fE253cd2e1cFeEdE1d6787344Ed5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "8kwCLkWbv4qTJPcbSV65tWdQmjURjBGRSv6VtC1JTiL8",
            "symbol": "waUNI_v1",
            "name": "Aave Interest bearing Uniswap (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kwCLkWbv4qTJPcbSV65tWdQmjURjBGRSv6VtC1JTiL8/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xB124541127A0A657f056D9Dd06188c4F1b0e5aab",
                "assetContract": "https://etherscan.io/address/0xB124541127A0A657f056D9Dd06188c4F1b0e5aab",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "9NDu1wdjZ7GiY7foAXhia9h1wQU45oTUzyMZKJ31V7JA",
            "symbol": "wstkAAVE_v1",
            "name": "Staked Aave (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9NDu1wdjZ7GiY7foAXhia9h1wQU45oTUzyMZKJ31V7JA/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4da27a545c0c5B758a6BA100e3a049001de870f5",
                "assetContract": "https://etherscan.io/address/0x4da27a545c0c5B758a6BA100e3a049001de870f5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "GNQ1Goajm3Za8uC1Eptt2yfsrbnkZh2eMJoqxg54sj3o",
            "symbol": "wUniDAIETH_v1",
            "name": "Uniswap DAI LP (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GNQ1Goajm3Za8uC1Eptt2yfsrbnkZh2eMJoqxg54sj3o/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x2a1530C4C41db0B0b2bB646CB5Eb1A67b7158667",
                "assetContract": "https://etherscan.io/address/0x2a1530C4C41db0B0b2bB646CB5Eb1A67b7158667",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "7NFin546WNvWkhtfftfY77z8C1TrxLbUcKmw5TpHGGtC",
            "symbol": "wUniUSDCETH_v1",
            "name": "Uniswap USDC LP (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7NFin546WNvWkhtfftfY77z8C1TrxLbUcKmw5TpHGGtC/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x97deC872013f6B5fB443861090ad931542878126",
                "assetContract": "https://etherscan.io/address/0x97deC872013f6B5fB443861090ad931542878126",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "7gersKTtU65ERNBNTZKjYgKf7HypR7PDMprcuhQJChaq",
            "symbol": "wUnisETHETH_v1",
            "name": "Uniswap sETH LP (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gersKTtU65ERNBNTZKjYgKf7HypR7PDMprcuhQJChaq/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xe9Cf7887b93150D4F2Da7dFc6D502B216438F244",
                "assetContract": "https://etherscan.io/address/0xe9Cf7887b93150D4F2Da7dFc6D502B216438F244",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "4aqNtSCr77eiEZJ9u9BhPErjEMju6FFdLeBKkE1pdxuK",
            "symbol": "wUniLENDETH_v1",
            "name": "Uniswap LEND LP (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4aqNtSCr77eiEZJ9u9BhPErjEMju6FFdLeBKkE1pdxuK/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xcaA7e4656f6A2B59f5f99c745F91AB26D1210DCe",
                "assetContract": "https://etherscan.io/address/0xcaA7e4656f6A2B59f5f99c745F91AB26D1210DCe",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "FDdoYCHwFghBSbnN6suvFR3VFw6kAzfhfGpkAQAGPLC3",
            "symbol": "wUniMKRETH_v1",
            "name": "Uniswap MKR LP (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDdoYCHwFghBSbnN6suvFR3VFw6kAzfhfGpkAQAGPLC3/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x2C4Bd064b998838076fa341A83d007FC2FA50957",
                "assetContract": "https://etherscan.io/address/0x2C4Bd064b998838076fa341A83d007FC2FA50957",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "FSSTfbb1vh1TRe8Ja64hC65QTc7pPUhwHh5uTAWj5haH",
            "symbol": "wUniLINKETH_v1",
            "name": "Uniswap LINK LP (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FSSTfbb1vh1TRe8Ja64hC65QTc7pPUhwHh5uTAWj5haH/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xF173214C720f58E03e194085B1DB28B50aCDeeaD",
                "assetContract": "https://etherscan.io/address/0xF173214C720f58E03e194085B1DB28B50aCDeeaD",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "Aci9xBGywrgBxQoFnL6LCoCYuX5k6AqaYhimgSZ1Fhrk",
            "symbol": "waUniETH_v1",
            "name": "Aave Interest bearing UniETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aci9xBGywrgBxQoFnL6LCoCYuX5k6AqaYhimgSZ1Fhrk/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6179078872605396Ee62960917128F9477a5DdbB",
                "assetContract": "https://etherscan.io/address/0x6179078872605396Ee62960917128F9477a5DdbB",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "GqHK99sW4ym6zy6Kdoh8f7sb2c3qhtB3WRqeyPbAYfmy",
            "symbol": "waUniDAI_v1",
            "name": "Aave Interest bearing UniDAI (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GqHK99sW4ym6zy6Kdoh8f7sb2c3qhtB3WRqeyPbAYfmy/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x048930eec73c91B44b0844aEACdEBADC2F2b6efb",
                "assetContract": "https://etherscan.io/address/0x048930eec73c91B44b0844aEACdEBADC2F2b6efb",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "4e4TpGVJMYiz5UBrAXuNmiVJ9yvc7ppJeAn8sXmbnmDi",
            "symbol": "waUniUSDC_v1",
            "name": "Aave Interest bearing UniUSDC (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4e4TpGVJMYiz5UBrAXuNmiVJ9yvc7ppJeAn8sXmbnmDi/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xe02b2Ad63eFF3Ac1D5827cBd7AB9DD3DaC4f4AD0",
                "assetContract": "https://etherscan.io/address/0xe02b2Ad63eFF3Ac1D5827cBd7AB9DD3DaC4f4AD0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "H36ykN443TZ6pC8oryicCYr5YB1em4fuSyezu5aoskNv",
            "symbol": "METARARITY",
            "name": "METARARITY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H36ykN443TZ6pC8oryicCYr5YB1em4fuSyezu5aoskNv/logo.png",
            "extensions": {
                "serumV3Usdc": "GL2M3XXPxA3BxpbMTy7j7ajTfRUreJNEvQhUnjU1r9fF",
                "twitter": "https://twitter.com/metararity",
                "website": "https://metararity.com"
            }
        },
        {
            "chainId": 101,
            "address": "49LoAnQQdo9171zfcWRUoQLYSScrxXobbuwt14xjvfVm",
            "symbol": "waUniUSDT_v1",
            "name": "Aave Interest bearing UniUSDT (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/49LoAnQQdo9171zfcWRUoQLYSScrxXobbuwt14xjvfVm/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xb977ee318010A5252774171494a1bCB98E7fab65",
                "assetContract": "https://etherscan.io/address/0xb977ee318010A5252774171494a1bCB98E7fab65",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "CvG3gtKYJtKRzEUgMeb42xnd8HDjESgLtyJqQ2kuLncp",
            "symbol": "waUniDAIETH_v1",
            "name": "Aave Interest bearing UniDAIETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CvG3gtKYJtKRzEUgMeb42xnd8HDjESgLtyJqQ2kuLncp/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xBbBb7F2aC04484F7F04A2C2C16f20479791BbB44",
                "assetContract": "https://etherscan.io/address/0xBbBb7F2aC04484F7F04A2C2C16f20479791BbB44",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "GSv5ECZaMfaceZK4WKKzA4tKVDkqtfBASECcmYFWcy4G",
            "symbol": "waUniUSDCETH_v1",
            "name": "Aave Interest bearing UniUSDCETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GSv5ECZaMfaceZK4WKKzA4tKVDkqtfBASECcmYFWcy4G/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1D0e53A0e524E3CC92C1f0f33Ae268FfF8D7E7a5",
                "assetContract": "https://etherscan.io/address/0x1D0e53A0e524E3CC92C1f0f33Ae268FfF8D7E7a5",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "7LUdsedi7qpTJGnFpZo6mWqVtKKpccr9XrQGxJ2xUDPT",
            "symbol": "waUniSETHETH_v1",
            "name": "Aave Interest bearing UniSETHETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7LUdsedi7qpTJGnFpZo6mWqVtKKpccr9XrQGxJ2xUDPT/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x84BBcaB430717ff832c3904fa6515f97fc63C76F",
                "assetContract": "https://etherscan.io/address/0x84BBcaB430717ff832c3904fa6515f97fc63C76F",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "Hc1zHQxg1k2JVwvuv3kqbCyZDEJYfDdNftBMab4EMUx9",
            "symbol": "waUniLENDETH_v1",
            "name": "Aave Interest bearing UniLENDETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hc1zHQxg1k2JVwvuv3kqbCyZDEJYfDdNftBMab4EMUx9/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc88ebbf7C523f38Ef3eB8A151273C0F0dA421e63",
                "assetContract": "https://etherscan.io/address/0xc88ebbf7C523f38Ef3eB8A151273C0F0dA421e63",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "9PejEmx6NKDHgf6jpgAWwZsibURKifBakjzDQdtCtAXT",
            "symbol": "waUniMKRETH_v1",
            "name": "Aave Interest bearing UniMKRETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9PejEmx6NKDHgf6jpgAWwZsibURKifBakjzDQdtCtAXT/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8c69f7A4C9B38F1b48005D216c398Efb2F1Ce3e4",
                "assetContract": "https://etherscan.io/address/0x8c69f7A4C9B38F1b48005D216c398Efb2F1Ce3e4",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "KcHygDp4o7ENsHjevYM4T3u6R7KHa5VyvkJ7kpmJcYo",
            "symbol": "waUniLINKETH_v1",
            "name": "Aave Interest bearing UniLINKETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KcHygDp4o7ENsHjevYM4T3u6R7KHa5VyvkJ7kpmJcYo/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x9548DB8b1cA9b6c757485e7861918b640390169c",
                "assetContract": "https://etherscan.io/address/0x9548DB8b1cA9b6c757485e7861918b640390169c",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "GNPAF84ZEtKYyfuY2fg8tZVwse7LpTSeyYPSyEKFqa2Y",
            "symbol": "waUSDT_v1",
            "name": "Aave interest bearing USDT (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GNPAF84ZEtKYyfuY2fg8tZVwse7LpTSeyYPSyEKFqa2Y/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x3Ed3B47Dd13EC9a98b44e6204A523E766B225811",
                "assetContract": "https://etherscan.io/address/0x3Ed3B47Dd13EC9a98b44e6204A523E766B225811",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-usdt"
            }
        },
        {
            "chainId": 101,
            "address": "3QTknQ3i27rDKm5hvBaScFLQ34xX9N7J7XfEFwy27qbZ",
            "symbol": "waWBTC_v1",
            "name": "Aave interest bearing WBTC (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3QTknQ3i27rDKm5hvBaScFLQ34xX9N7J7XfEFwy27qbZ/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x9ff58f4fFB29fA2266Ab25e75e2A8b3503311656",
                "assetContract": "https://etherscan.io/address/0x9ff58f4fFB29fA2266Ab25e75e2A8b3503311656",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-wbtc"
            }
        },
        {
            "chainId": 101,
            "address": "EbpkofeWyiQouGyxQAgXxEyGtjgq13NSucX3CNvucNpb",
            "symbol": "waWETH_v1",
            "name": "Aave interest bearing WETH (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EbpkofeWyiQouGyxQAgXxEyGtjgq13NSucX3CNvucNpb/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x030bA81f1c18d280636F32af80b9AAd02Cf0854e",
                "assetContract": "https://etherscan.io/address/0x030bA81f1c18d280636F32af80b9AAd02Cf0854e",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "67uaa3Z7SX7GC6dqSTjpJLnySLXZpCAK9MHMi3232Bfb",
            "symbol": "waYFI_v1",
            "name": "Aave interest bearing YFI (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/67uaa3Z7SX7GC6dqSTjpJLnySLXZpCAK9MHMi3232Bfb/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x5165d24277cD063F5ac44Efd447B27025e888f37",
                "assetContract": "https://etherscan.io/address/0x5165d24277cD063F5ac44Efd447B27025e888f37",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "9xS6et5uvQ64QsmaGMfzfXrwTsfYPjwEWuiPnBGFgfw",
            "symbol": "waZRX_v1",
            "name": "Aave interest bearing ZRX (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xS6et5uvQ64QsmaGMfzfXrwTsfYPjwEWuiPnBGFgfw/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xDf7FF54aAcAcbFf42dfe29DD6144A69b629f8C9e",
                "assetContract": "https://etherscan.io/address/0xDf7FF54aAcAcbFf42dfe29DD6144A69b629f8C9e",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-zrx"
            }
        },
        {
            "chainId": 101,
            "address": "2TZ8s2FwtWqJrWpdFsSf2uM2Fvjw474n6HhTdTEWoLor",
            "symbol": "waUNI_v1",
            "name": "Aave interest bearing UNI (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2TZ8s2FwtWqJrWpdFsSf2uM2Fvjw474n6HhTdTEWoLor/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xB9D7CB55f463405CDfBe4E90a6D2Df01C2B92BF1",
                "assetContract": "https://etherscan.io/address/0xB9D7CB55f463405CDfBe4E90a6D2Df01C2B92BF1",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "G1o2fHZXyPCeAEcY4o6as7SmVaUu65DRhcq1S4Cfap9T",
            "symbol": "waAAVE_v1",
            "name": "Aave interest bearing AAVE (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G1o2fHZXyPCeAEcY4o6as7SmVaUu65DRhcq1S4Cfap9T/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xFFC97d72E13E01096502Cb8Eb52dEe56f74DAD7B",
                "assetContract": "https://etherscan.io/address/0xFFC97d72E13E01096502Cb8Eb52dEe56f74DAD7B",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678"
            }
        },
        {
            "chainId": 101,
            "address": "8PeWkyvCDHpSgT5oiGFgZQtXSRBij7ZFLJTHAGBntRDH",
            "symbol": "waBAT_v1",
            "name": "Aave interest bearing BAT (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PeWkyvCDHpSgT5oiGFgZQtXSRBij7ZFLJTHAGBntRDH/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x05Ec93c0365baAeAbF7AefFb0972ea7ECdD39CF1",
                "assetContract": "https://etherscan.io/address/0x05Ec93c0365baAeAbF7AefFb0972ea7ECdD39CF1",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-bat"
            }
        },
        {
            "chainId": 101,
            "address": "67opsuaXQ3JRSJ1mmF7aPLSq6JaZcwAmXwcMzUN5PSMv",
            "symbol": "waBUSD_v1",
            "name": "Aave interest bearing BUSD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/67opsuaXQ3JRSJ1mmF7aPLSq6JaZcwAmXwcMzUN5PSMv/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xA361718326c15715591c299427c62086F69923D9",
                "assetContract": "https://etherscan.io/address/0xA361718326c15715591c299427c62086F69923D9",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-busd"
            }
        },
        {
            "chainId": 101,
            "address": "4JrrHRS56i9GZkSmGaCY3ZsxMo3JEqQviU64ki7ZJPak",
            "symbol": "waDAI_v1",
            "name": "Aave interest bearing DAI (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4JrrHRS56i9GZkSmGaCY3ZsxMo3JEqQviU64ki7ZJPak/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x028171bCA77440897B824Ca71D1c56caC55b68A3",
                "assetContract": "https://etherscan.io/address/0x028171bCA77440897B824Ca71D1c56caC55b68A3",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-dai"
            }
        },
        {
            "chainId": 101,
            "address": "3LmfKjsSU9hdxfZfcr873DMNR5nnrk8EvdueXg1dTSin",
            "symbol": "waENJ_v1",
            "name": "Aave interest bearing ENJ (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3LmfKjsSU9hdxfZfcr873DMNR5nnrk8EvdueXg1dTSin/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xaC6Df26a590F08dcC95D5a4705ae8abbc88509Ef",
                "assetContract": "https://etherscan.io/address/0xaC6Df26a590F08dcC95D5a4705ae8abbc88509Ef",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-enj"
            }
        },
        {
            "chainId": 101,
            "address": "7VD2Gosm34hB7kughTqu1N3sW92hq3XwKLTi1N1tdKrj",
            "symbol": "waKNC_v1",
            "name": "Aave interest bearing KNC (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7VD2Gosm34hB7kughTqu1N3sW92hq3XwKLTi1N1tdKrj/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x39C6b3e42d6A679d7D776778Fe880BC9487C2EDA",
                "assetContract": "https://etherscan.io/address/0x39C6b3e42d6A679d7D776778Fe880BC9487C2EDA",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-knc"
            }
        },
        {
            "chainId": 101,
            "address": "4erbVWFvdvS5P8ews7kUjqfpCQbA8vurnWyvRLsnZJgv",
            "symbol": "waLINK_v1",
            "name": "Aave interest bearing LINK (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4erbVWFvdvS5P8ews7kUjqfpCQbA8vurnWyvRLsnZJgv/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa06bC25B5805d5F8d82847D191Cb4Af5A3e873E0",
                "assetContract": "https://etherscan.io/address/0xa06bC25B5805d5F8d82847D191Cb4Af5A3e873E0",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-link"
            }
        },
        {
            "chainId": 101,
            "address": "AXJWqG4SpAEwkMjKYkarKwv6Qfz5rLU3cwt5KtrDAAYe",
            "symbol": "waMANA_v1",
            "name": "Aave interest bearing MANA (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AXJWqG4SpAEwkMjKYkarKwv6Qfz5rLU3cwt5KtrDAAYe/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa685a61171bb30d4072B338c80Cb7b2c865c873E",
                "assetContract": "https://etherscan.io/address/0xa685a61171bb30d4072B338c80Cb7b2c865c873E",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-mana"
            }
        },
        {
            "chainId": 101,
            "address": "4kJmfagJzQFuwto5RX6f1xScWYbEVBzEpdjmiqTCnzjJ",
            "symbol": "waMKR_v1",
            "name": "Aave interest bearing MKR (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4kJmfagJzQFuwto5RX6f1xScWYbEVBzEpdjmiqTCnzjJ/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc713e5E149D5D0715DcD1c156a020976e7E56B88",
                "assetContract": "https://etherscan.io/address/0xc713e5E149D5D0715DcD1c156a020976e7E56B88",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-mkr"
            }
        },
        {
            "chainId": 101,
            "address": "DN8jPo8YZTXhLMyDMKcnwFuKqY8wfn2UrpX8ct4rc8Bc",
            "symbol": "waREN_v1",
            "name": "Aave interest bearing REN (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DN8jPo8YZTXhLMyDMKcnwFuKqY8wfn2UrpX8ct4rc8Bc/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xCC12AbE4ff81c9378D670De1b57F8e0Dd228D77a",
                "assetContract": "https://etherscan.io/address/0xCC12AbE4ff81c9378D670De1b57F8e0Dd228D77a",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-ren"
            }
        },
        {
            "chainId": 101,
            "address": "HWbJZXJ7s1D1zi5P7yVgRUmZPXvYSFv6vsYU765Ti422",
            "symbol": "waSNX_v1",
            "name": "Aave interest bearing SNX (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWbJZXJ7s1D1zi5P7yVgRUmZPXvYSFv6vsYU765Ti422/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x35f6B052C598d933D69A4EEC4D04c73A191fE6c2",
                "assetContract": "https://etherscan.io/address/0x35f6B052C598d933D69A4EEC4D04c73A191fE6c2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-snx"
            }
        },
        {
            "chainId": 101,
            "address": "2LForywWWpHzmR5NjSEyF1kcw9ffyLuJX7V7hne2fHfY",
            "symbol": "waSUSD_v1",
            "name": "Aave interest bearing SUSD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2LForywWWpHzmR5NjSEyF1kcw9ffyLuJX7V7hne2fHfY/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6C5024Cd4F8A59110119C56f8933403A539555EB",
                "assetContract": "https://etherscan.io/address/0x6C5024Cd4F8A59110119C56f8933403A539555EB",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-susd"
            }
        },
        {
            "chainId": 101,
            "address": "Badj3S29a2u1auxmijwg5vGjhPLb1K6WLPoigtWjKPXp",
            "symbol": "waTUSD_v1",
            "name": "Aave interest bearing TUSD (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Badj3S29a2u1auxmijwg5vGjhPLb1K6WLPoigtWjKPXp/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x101cc05f4A51C0319f570d5E146a8C625198e636",
                "assetContract": "https://etherscan.io/address/0x101cc05f4A51C0319f570d5E146a8C625198e636",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-tusd"
            }
        },
        {
            "chainId": 101,
            "address": "BZCPpva12M9SqJgcpf8jtP9Si6rMANFoUR3i7nchha7M",
            "symbol": "waUSDC_v1",
            "name": "Aave interest bearing USDC (Wormhole v1)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZCPpva12M9SqJgcpf8jtP9Si6rMANFoUR3i7nchha7M/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xBcca60bB61934080951369a648Fb03DF4F96263C",
                "assetContract": "https://etherscan.io/address/0xBcca60bB61934080951369a648Fb03DF4F96263C",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "aave-usdc"
            }
        },
        {
            "chainId": 101,
            "address": "D3ajQoyBGJz3JCXCPsxHZJbLQKGt9UgxLavgurieGNcD",
            "symbol": "wSDT_v1",
            "name": "Stake DAO Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3ajQoyBGJz3JCXCPsxHZJbLQKGt9UgxLavgurieGNcD/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f",
                "assetContract": "https://etherscan.io/address/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "stake-dao"
            }
        },
        {
            "chainId": 101,
            "address": "4pk3pf9nJDN1im1kNwWJN1ThjE8pCYCTexXYGyFjqKVf",
            "symbol": "oDOP",
            "name": "Dominican Pesos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pk3pf9nJDN1im1kNwWJN1ThjE8pCYCTexXYGyFjqKVf/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://Odop.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5kjfp2qfRbqCXTQeUYgHNnTLf13eHoKjC5hHynW9DvQE",
            "symbol": "AAPE",
            "name": "AAPE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5kjfp2qfRbqCXTQeUYgHNnTLf13eHoKjC5hHynW9DvQE/logo.png",
            "extensions": {
                "website": "https://aape.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5LkvF71ZicV2HhbwYio6XMiFxNv3VUn62eBQ2nppG5D",
            "symbol": "CAPF",
            "name": "Capital Fusion",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/capitalfusion/token-list/main/assets/mainnet/5LkvF71ZicV2HhbwYio6XMiFxNv3VUn62eBQ2nppG5D/logo.png",
            "extensions": {
                "website": "https://capitalfusion.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE",
            "symbol": "XCOPE",
            "name": "XCOPE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE/logo.png",
            "tags": [
                "trading",
                "index",
                "Algos"
            ],
            "extensions": {
                "coingeckoId": "cope",
                "serumV3Usdc": "7MpMwArporUHEGW7quUpkPZp5L5cHPs9eKUfKCdaPHq2",
                "website": "https://www.unlimitedcope.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh",
            "symbol": "COPE",
            "name": "COPE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh/logo.png",
            "tags": [
                "trading",
                "index",
                "Algos"
            ],
            "extensions": {
                "coingeckoId": "cope",
                "serumV3Usdc": "6fc7v3PmjZG9Lk2XTot6BywGyYLkBQuzuFKd4FpCsPxk",
                "website": "https://www.unlimitedcope.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2prC8tcVsXwVJAinhxd2zeMeWMWaVyzPoQeLKyDZRFKd",
            "symbol": "MCAPS",
            "name": "Mango Market Caps",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2prC8tcVsXwVJAinhxd2zeMeWMWaVyzPoQeLKyDZRFKd/logo.png",
            "tags": [
                "mango"
            ],
            "extensions": {
                "coingeckoId": "mango-market-caps",
                "website": "https://initialcapoffering.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2reKm5Y9rmAWfaw5jraYz1BXwGLHMofGMs3iNoBLt4VC",
            "symbol": "DOCE",
            "name": "Doce Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2reKm5Y9rmAWfaw5jraYz1BXwGLHMofGMs3iNoBLt4VC/logo.png",
            "extensions": {
                "website": "https://swap.doce.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA",
            "symbol": "INO",
            "name": "NoGoalToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA/logo.png",
            "extensions": {
                "discord": "https://discord.gg/mHS3qbBaZk",
                "serumV3Usdc": "HyERWE8TEQmDX157oLEpwaTc59ECzmvjUgZhZ2RNtNdn",
                "website": "http://token.nogoal.click/"
            }
        },
        {
            "chainId": 101,
            "address": "8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo",
            "symbol": "ROPE",
            "name": "Rope Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo/logo.svg",
            "extensions": {
                "coingeckoId": "rope-token",
                "serumV3Usdc": "4Sg1g8U2ZuGnGYxAhc6MmX9MX7yZbrrraPkCQ9MdCPtF",
                "waterfallbot": "https://bit.ly/ROPEwaterfall",
                "website": "https://ropesolana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2XkWD6spByDUoR3VDEjPXz4kxFV8e1skwaRSBArRLG3a",
            "symbol": "DROIDF",
            "name": "DROID FINANCE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2XkWD6spByDUoR3VDEjPXz4kxFV8e1skwaRSBArRLG3a/logo.png",
            "extensions": {
                "website": "https://www.droid.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5dhkWqrq37F92jBmEyhQP1vbMkbVRz59V7288HH2wBC7",
            "symbol": "SLOCK",
            "name": "SOLLock",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5dhkWqrq37F92jBmEyhQP1vbMkbVRz59V7288HH2wBC7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/SOLLock",
                "telegram": "https://t.me/SOLLock",
                "telegramAnnouncements": "https://t.me/SOLLockAnn",
                "twitter": "https://twitter.com/SOLLockOfficial",
                "website": "https://sollock.org/"
            }
        },
        {
            "chainId": 101,
            "address": "ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs",
            "symbol": "MEDIA",
            "name": "Media Network",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "media-network",
                "serumV3Usdc": "FfiqqvJcVL7oCCu8WQUMHLUC2dnHQPAPjTdSzsERFWjb",
                "waterfallbot": "https://bit.ly/MEDIAwaterfall",
                "website": "https://media.network/"
            }
        },
        {
            "chainId": 101,
            "address": "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
            "symbol": "STEP",
            "name": "Step",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "step-finance",
                "serumV3Usdc": "97qCB4cAVSTthvJu3eNoEx6AY6DLuRDtCoPm5Tdyg77S",
                "twitter": "https://twitter.com/StepFinance_",
                "waterfallbot": "https://bit.ly/STEPwaterfall",
                "website": "https://step.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G",
            "symbol": "xSTEP",
            "name": "Staked Step",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/StepFinance_",
                "waterfallbot": "https://bit.ly/STEPwaterfall",
                "website": "https://step.finance/"
            }
        },
        {
            "chainId": 102,
            "address": "So11111111111111111111111111111111111111112",
            "symbol": "wSOL",
            "name": "Wrapped SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
            "extensions": {
                "coingeckoId": "solana",
                "website": "https://www.solana.com/"
            }
        },
        {
            "chainId": 102,
            "address": "CpMah17kQEL2wqyMKt3mZBdTnZbkbfx4nqmQMFDP5vwp",
            "symbol": "USDC",
            "name": "USD Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CpMah17kQEL2wqyMKt3mZBdTnZbkbfx4nqmQMFDP5vwp/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "website": "https://www.centre.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4UuGQgkD3rSeoXatXRWwRfRd21G87d5LiCfkVzNNv1Tt",
            "symbol": "XSB",
            "name": "Solareum",
            "decimals": 9,
            "logoURI": "https://solareum.app/icons/XSB-G.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solareum_wallet",
                "twitter": "https://twitter.com/solareum_wallet",
                "website": "https://solareum.app"
            }
        },
        {
            "chainId": 102,
            "address": "Gmk71cM7j2RMorRsQrsyysM4HsByQx5PuDGtDdqGLWCS",
            "symbol": "spSOL",
            "name": "Stake pool SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gmk71cM7j2RMorRsQrsyysM4HsByQx5PuDGtDdqGLWCS/logo.png",
            "tags": [
                "stake-pool"
            ],
            "extensions": {
                "website": "https://www.solana.com/"
            }
        },
        {
            "chainId": 102,
            "address": "2jQc2jDHVCewoWsQJK7JPLetP7UjqXvaFdno8rtrD8Kg",
            "symbol": "sHOG",
            "name": "sHOG",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY",
            "symbol": "COBAN",
            "name": "COBAN",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY/logo.png",
            "extensions": {
                "website": "https://coban.io"
            }
        },
        {
            "chainId": 102,
            "address": "ASpA3U8G2qHnyo6ag1jwtpZNj9E2MymbVDq6twi3ZvRN",
            "symbol": "USDT_ILT",
            "name": "USDT_ILT_Token_Test",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 103,
            "address": "3MoHgE6bJ2Ak1tEvTt5SVgSN2oXiwt6Gk5s6wbBxdmmN",
            "symbol": "USDT_ILT",
            "name": "USDT_ILT_Token_Test",
            "decimals": 6,
            "logoURI": "",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 103,
            "address": "zbLcPeHWQ7yQXT7fEYHeNBKGM3wdGhNYL9jryVpys5J",
            "symbol": "VDC",
            "name": "VDC Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zbLcPeHWQ7yQXT7fEYHeNBKGM3wdGhNYL9jryVpys5J/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://vdcs.io"
            }
        },
        {
            "chainId": 103,
            "address": "5Ct5qBYm2u7qB14iQcmLwnx5nmC6GJGjZee39scBcrSR",
            "symbol": "RBN",
            "name": "Robin Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Ct5qBYm2u7qB14iQcmLwnx5nmC6GJGjZee39scBcrSR/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://robin.com"
            }
        },
        {
            "chainId": 103,
            "address": "So11111111111111111111111111111111111111112",
            "symbol": "SOL",
            "name": "Wrapped SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
            "extensions": {
                "coingeckoId": "solana"
            }
        },
        {
            "chainId": 103,
            "address": "7Cab8z1Lz1bTC9bQNeY7VQoZw5a2YbZoxmvFSvPgcTEL",
            "symbol": "LGGD",
            "name": "LGG Dev Fan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Cab8z1Lz1bTC9bQNeY7VQoZw5a2YbZoxmvFSvPgcTEL/logo.png",
            "tags": [
                "LGG"
            ],
            "extensions": {
                "website": "https://lgg-hacks.art"
            }
        },
        {
            "chainId": 103,
            "address": "DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw",
            "symbol": "XYZ",
            "name": "XYZ Test",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw/logo.png"
        },
        {
            "chainId": 103,
            "address": "2rg5syU3DSwwWs778FQ6yczDKhS14NM3vP4hqnkJ2jsM",
            "symbol": "pSOL",
            "name": "SOL stake pool",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2rg5syU3DSwwWs778FQ6yczDKhS14NM3vP4hqnkJ2jsM/logo.png"
        },
        {
            "chainId": 103,
            "address": "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
            "symbol": "SRM",
            "name": "Serum",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png",
            "extensions": {
                "coingeckoId": "serum",
                "website": "https://projectserum.com/"
            }
        },
        {
            "chainId": 103,
            "address": "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
            "symbol": "STEP",
            "name": "Step",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "step-finance",
                "twitter": "https://twitter.com/StepFinance_",
                "waterfallbot": "https://bit.ly/STEPwaterfall",
                "website": "https://step.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G",
            "symbol": "xSTEP",
            "name": "Staked Step",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/StepFinance_",
                "waterfallbot": "https://bit.ly/STEPwaterfall",
                "website": "https://step.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "7STJWT74tAZzhbNNPRH8WuGDy9GZg27968EwALWuezrH",
            "symbol": "wSUSHI_v1",
            "name": "SushiSwap (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7STJWT74tAZzhbNNPRH8WuGDy9GZg27968EwALWuezrH/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
                "assetContract": "https://etherscan.io/address/0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "sushi",
                "website": "https://sushi.com"
            }
        },
        {
            "chainId": 103,
            "address": "3aMbgP7aGsP1sVcFKc6j65zu7UiziP57SMFzf6ptiCSX",
            "symbol": "sHOG",
            "name": "Devnet StableHog",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aMbgP7aGsP1sVcFKc6j65zu7UiziP57SMFzf6ptiCSX/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "3cXftQWJJEeoysZrhAEjpfCHe9tSKyhYG63xpbue8m3s",
            "symbol": "Kreechures",
            "name": "Kreechures",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3cXftQWJJEeoysZrhAEjpfCHe9tSKyhYG63xpbue8m3s/logo.svg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://www.kreechures.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4DrV8khCoPS3sWRj6t1bb2DzT9jD4mZp6nc7Jisuuv1b",
            "symbol": "SPD",
            "name": "Solpad",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4DrV8khCoPS3sWRj6t1bb2DzT9jD4mZp6nc7Jisuuv1b/logo.png",
            "extensions": {
                "website": "https://www.solpad.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7p7AMM6QoA8wPRKeqF87Pt51CRWmWvXPH5TBNMyDWhbH",
            "symbol": "Kreechures",
            "name": "Kreechures",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7p7AMM6QoA8wPRKeqF87Pt51CRWmWvXPH5TBNMyDWhbH/logo.svg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://www.kreechures.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1",
            "symbol": "QUEST",
            "name": "QUEST",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1/logo.png",
            "tags": [
                "DeFi",
                "GameFi",
                "NFT",
                "Metaverse"
            ],
            "extensions": {
                "description": "Questland metaverse token",
                "website": "https://questland.io"
            }
        },
        {
            "chainId": 101,
            "address": "97qAF7ZKEdPdQaUkhASGA59Jpa2Wi7QqVmnFdEuPqEDc",
            "symbol": "DIAMOND",
            "name": "LOVE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97qAF7ZKEdPdQaUkhASGA59Jpa2Wi7QqVmnFdEuPqEDc/logo.png",
            "extensions": {
                "telegram": "https://t.me/DiamondLoveX",
                "website": "https://diamondlove.io/"
            }
        },
        {
            "chainId": 101,
            "address": "xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW",
            "symbol": "SLIM",
            "name": "Solanium",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW/logo.png",
            "extensions": {
                "coingeckoId": "solanium",
                "telegram": "https://t.me/solanium_io",
                "twitter": "https://twitter.com/solanium_io",
                "website": "https://www.solanium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8GPUjUHFxfNhaSS8kUkix8txRRXszeUAsHTjUmHuygZT",
            "symbol": "NINJA NFT1",
            "name": "NINJA NFT1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yuzu-ninjaprotocol/ninjaprotocol/main/NINJA%20NFT%201.png",
            "extensions": {
                "website": "http://ninjaprotocol.io"
            }
        },
        {
            "chainId": 101,
            "address": "HcJCPYck2UsTMgiPfjn6CS1wrC5iBXtuqPSjt8Qy8Sou",
            "symbol": "GANGS",
            "name": "Gangs of Solana",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HcJCPYck2UsTMgiPfjn6CS1wrC5iBXtuqPSjt8Qy8Sou/logo.svg",
            "extensions": {
                "website": "https://gangsofsolana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2rEiLkpQ3mh4DGxv1zcSdW5r5HK2nehif5sCaF5Ss9E1",
            "symbol": "RECO",
            "name": "Reboot ECO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2rEiLkpQ3mh4DGxv1zcSdW5r5HK2nehif5sCaF5Ss9E1/logo.png",
            "extensions": {
                "website": "https://reboot.eco/"
            }
        },
        {
            "chainId": 101,
            "address": "BXhAKUxkGvFbAarA3K1SUYnqXRhEBC1bhUaCaxvzgyJ1",
            "symbol": "ISA",
            "name": "Interstellar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXhAKUxkGvFbAarA3K1SUYnqXRhEBC1bhUaCaxvzgyJ1/logo.png",
            "extensions": {
                "website": "https://interstellaralliance.gitbook.io/isa/"
            }
        },
        {
            "chainId": 101,
            "address": "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU",
            "symbol": "SAMO",
            "name": "Samoyed Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU/logo.png",
            "extensions": {
                "coingeckoId": "samoyedcoin",
                "serumV3Usdc": "FR3SPJmgfRSKKQ2ysUZBu7vJLpzTixXnjzb84bY3Diif",
                "website": "https://samoyedcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty",
            "symbol": "PANDA",
            "name": "Panda Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "panda-coin",
                "discord": "https://discord.gg/nPSXvdJpvz",
                "telegram": "https://t.me/pandacoin_announcements",
                "twitter": "https://twitter.com/pandacoin_fi",
                "website": "https://pandacrypto.org/"
            }
        },
        {
            "chainId": 101,
            "address": "5bJPS9PqzeH8JNzvGqw2nYi5VXyB7Cch6UYeUKkoHQeJ",
            "symbol": "CORGI",
            "name": "Corgi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5bJPS9PqzeH8JNzvGqw2nYi5VXyB7Cch6UYeUKkoHQeJ/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/xAXYmXQGYA",
                "website": "https://corgicoin.dev/"
            }
        },
        {
            "chainId": 101,
            "address": "61bW17b7WaWgA916RusHpzqTw9gZKf84sWN2efhtCrq",
            "symbol": "DODO",
            "name": "Dodo Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/61bW17b7WaWgA916RusHpzqTw9gZKf84sWN2efhtCrq/logo.png",
            "extensions": {
                "discord": "https://discord.gg/wVAxxw3rn4"
            }
        },
        {
            "chainId": 101,
            "address": "ByJ8a9NWk6G4Jg4iFyFNdrya1iVcusL1aL9aGXWXeoVG",
            "symbol": "POM",
            "name": "Pom Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ByJ8a9NWk6G4Jg4iFyFNdrya1iVcusL1aL9aGXWXeoVG/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/PomToken"
            }
        },
        {
            "chainId": 101,
            "address": "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
            "symbol": "ATLAS",
            "name": "Star Atlas",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "star-atlas",
                "description": "Star Atlas Token",
                "serumV3Usdc": "Di66GTLsV64JgCCYGVcY21RZ173BHkjJVgPyezNN7P1K",
                "waterfallbot": "https://bit.ly/ATLASwaterfall",
                "website": "https://staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
            "symbol": "POLIS",
            "name": "Star Atlas DAO",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "star-atlas-dao",
                "description": "Star Atlas DAO Token",
                "serumV3Usdc": "HxFLKUAmAMLz1jtT3hbvCMELwH5H9tpM2QugP8sKyfhW",
                "waterfallbot": "https://bit.ly/POLISwaterfall",
                "website": "https://staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "HAWy8kV3bD4gaN6yy6iK2619x2dyzLUBj1PfJiihTisE",
            "symbol": "DOI",
            "name": "Discovery of Iris",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAWy8kV3bD4gaN6yy6iK2619x2dyzLUBj1PfJiihTisE/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "The rogue planet, Iris, dense with invaluable materials, draws in and collides with seven child planets in a remote region of space, creating what is henceforth referred to as 'The Cataclysm'. When combined, these eight elements create a form of free energy. The collision creates a massively valuable debris field.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-1/discovery-of-iris.jpg",
                "serumV3Usdc": "AYXTVttPfhYmn3jryX5XbRjwPK2m9445mbN2iLyRD6nq",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "ATSPo9f9TJ3Atx8SuoTYdzSMh4ctQBzYzDiNukQDmoF7",
            "symbol": "HOSA",
            "name": "The Heart of Star Atlas",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATSPo9f9TJ3Atx8SuoTYdzSMh4ctQBzYzDiNukQDmoF7/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "At the core of Star Atlas lies a treasure trove of priceless data. After an unsuspecting deep space explorer discovers 'The Cataclysm', he scans its riches, creating what will once be known as the first intergalactic data block. He sells this invaluable information to all three rival factions, igniting a lethal spark that forever changes the course of history.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-2/the-heart-of-star-atlas.jpg",
                "serumV3Usdc": "5Erzgrw9pTjNWLeqHp2sChJq7smB7WXRQYw9wvkvA59t",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "36s6AFRXzE9KVdUyoJQ5y6mwxXw21LawYqqwNiQUMD8s",
            "symbol": "TCW",
            "name": "The Convergence War",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/36s6AFRXzE9KVdUyoJQ5y6mwxXw21LawYqqwNiQUMD8s/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "All three factions, thinking they were the sole owners of the cataclysmic data drop, converge to settle the area. A devastating war breaks out across the galaxy after their inability to settle the disputed territory.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-3/the-convergence-war.jpg",
                "serumV3Usdc": "DXPv2ZyMD6Y2mDenqYkAhkvGSjNahkuMkm4zv6DqB7RF",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "BgiTVxW9uLuHHoafTd2qjYB5xjCc5Y1EnUuYNfmTwhvp",
            "symbol": "LOST",
            "name": "Short Story of a Lost Astronaut",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BgiTVxW9uLuHHoafTd2qjYB5xjCc5Y1EnUuYNfmTwhvp/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "He thought it would be just another routine exploration mission. Get there, scan, save data blocks and return. But when a surprise radiation storm knocked out his spaceship and swept him up into its high-velocity current, the only thing that saved him from certain doom was his custom ion shield.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-4/short-story-of-a-lost-astronaut.jpg",
                "serumV3Usdc": "73d9N7BbWVKBG6A2xwwwEHcxzPB26YzbMnRjue3DPzqs",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "4G85c5aUsRTrRPqE5VjY7ebD9b2ktTF6NEVGiCddRBDX",
            "symbol": "LOVE",
            "name": "B ❤ P",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4G85c5aUsRTrRPqE5VjY7ebD9b2ktTF6NEVGiCddRBDX/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "Paizul, the charismatic and brilliant leader of the ONI consortium, vividly recalls the first time she saw her one true love. It was a warm summer day, full of raging ionic storms. Lightning was piercing the sky as Bekalu took off his helmet and locked eyes with her. 'What are the chances of nearly colliding with someone flying through these wastelands on a day like this', he smiled with his booming voice. 'Perhaps it's destiny,' she smiled back mysteriously. There was another strike of lightning, but this time the sky remained calm.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-5/love-story.jpg",
                "serumV3Usdc": "AM9sNDh48N2qhYSgpA58m9dHvrMoQongtyYu2u2XoYTc",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "7dr7jVyXf1KUnYq5FTpV2vCZjKRR4MV94jzerb8Fi16Q",
            "symbol": "MRDR",
            "name": "The Assassination of Paizul",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dr7jVyXf1KUnYq5FTpV2vCZjKRR4MV94jzerb8Fi16Q/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "Suffering one of the cruelest fates in the universe, the Sogmian race of aliens was driven to the brink of extinction. With only 10,000 members left, they put all hope of salvation in the hands of their leader Paizul. After she was assassinated in a gruesome public way, so much fear was struck in the hearts of survivors that they set out to build their 'Last Stand'.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-6/assassination-of-paizul.jpg",
                "serumV3Usdc": "BJiV2gCLwMvj2c1CbhnMjjy68RjqoMzYT8brDrpVyceA",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "G1bE9ge8Yoq43hv7QLcumxTFhHqFMdcL4y2d6ZdzMG4b",
            "symbol": "PFP",
            "name": "Paizul Funeral Procession",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G1bE9ge8Yoq43hv7QLcumxTFhHqFMdcL4y2d6ZdzMG4b/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "The sound of wailing echoes across the plains. The Sogmian procession solemnly marches in step past their ancestors' gravestones, still haunted by the fate of their leader. The sun begins to set as they bring Paizul's cryopod at the top of the Rock of Light. As a beam of light consumes the pod to upload it to eternal rest with the ancients, Bekalu falls to his knees with a wrathful howl. The crowd is rattled to the core, a foreboding of things to come.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-7/paizul-funeral-procession.jpg",
                "serumV3Usdc": "7JzaEAuVfjkrZyMwJgZF5aQkiEyVyCaTWA3N1fQK7Y6V",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "6bD8mr8DyuVqN5dXd1jnqmCL66b5KUV14jYY1HSmnxTE",
            "symbol": "AVE",
            "name": "Ahr Visits Earth",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6bD8mr8DyuVqN5dXd1jnqmCL66b5KUV14jYY1HSmnxTE/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "Humankind is visited by Ahr, a mysterious being of pure light. But not all is as it seems... For through the power of illusion, we are tricked into forming a space-based religion, plundering the planet and launching ourselves towards the stars, permanently leaving the Earth.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-8/ahr-visits-earth.jpg",
                "serumV3Usdc": "8yQzsbraXJFoPG5PdX73B8EVYFuPR9aC2axAqWearGKu",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "9vi6PTKBFHR2hXgyjoTZx6h7WXNkFAA5dCsZRSi4higK",
            "symbol": "ASF",
            "name": "Armstrong Forever",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vi6PTKBFHR2hXgyjoTZx6h7WXNkFAA5dCsZRSi4higK/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "When humans were racing to expand into outer space under Ahr's influence, the devastation they inflicted upon the planet was so great that it weakened the Earth's geomagnetic field. The reckless way the planet's orbit was populated by machines and debris led to distortions in the gravity field. All this culminated in a disastrous slingshot effect for the many satellites orbiting the blue dot, altering their trajectories to loosen the direct gravity pull of the planet and scatter into deep space. Some of these satellites contained valuable data that was lost forever.  In 2621, the Council of Peace put a bounty on these ancient artifacts to integrate them into Star Atlas, leading to a hunt for them across the galaxy. One of the most sought-after satellites in history records bears the name of Neil Armstrong, the first human to set foot on the Moon.  Initially launched into medium Earth orbit as a cornerstone of the global positioning system (GPS), the satellite had untold additional capabilities that made it more and more valuable as it drifted off into the void.",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-15/armstrong-forever.jpg",
                "serumV3Usdc": "8yQzsbraXJFoPG5PdX73B8EVYFuPR9aC2axAqWearGKu",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "Hfjgcs9ix17EwgXVVbKjo6NfMm2CXfr34cwty3xWARUm",
            "symbol": "TLS",
            "name": "The Last Stand",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hfjgcs9ix17EwgXVVbKjo6NfMm2CXfr34cwty3xWARUm/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "serumV3Usdc": "AVHndcEDUjP9Liz5dfcvAPAMffADXG6KMPn8sWB1XhFQ",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "8EXX5kG7qWTjgpNSGX7PnB6hJZ8xhXUcCafVJaBEJo32",
            "symbol": "SPT",
            "name": "The Signing of the Peace Treaty",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EXX5kG7qWTjgpNSGX7PnB6hJZ8xhXUcCafVJaBEJo32/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "serumV3Usdc": "FZ9xhZbkt9bKKVpWmFxRhEJyzgxqU5w5xu3mXcF6Eppe",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "62FWgS4XaMJrUrAYw7mHMRye4iY9hqgqnJLBiT8QyPJv",
            "symbol": "COFFEE",
            "name": "CoffeeMaker",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/cofeeswap/logo/coffeev2.png",
            "tags": [
                "nft",
                "swap",
                "nft-marketplace"
            ],
            "extensions": {
                "website": "https://coffeemaker.finance"
            }
        },
        {
            "chainId": 101,
            "address": "CAjoJeGCCRae9oDwHYXzkeUDonp3dZLWV5GKHysLwjnx",
            "symbol": "PBA",
            "name": "The Peacebringers Archive",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAjoJeGCCRae9oDwHYXzkeUDonp3dZLWV5GKHysLwjnx/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "serumV3Usdc": "4jN1R453Acv9egnr7Dry3x9Xe3jqh1tqz5RokniaeVhy",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "FPnwwNiL1tXqd4ZbGjFYsCw5qsQw91VN79SNcU4Bc732",
            "symbol": "UWB",
            "name": "Ustur Wod.bod",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FPnwwNiL1tXqd4ZbGjFYsCw5qsQw91VN79SNcU4Bc732/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "serumV3Usdc": "J99HsFQEWKR3UiFQpKTnF11iaNiR1enf2LxHfgsbVc59",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "DB76aiNQeLzHPwvFhzgwfpe6HGHCDTQ6snW6UD7AnHid",
            "symbol": "OMPH",
            "name": "Om Photoli",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DB76aiNQeLzHPwvFhzgwfpe6HGHCDTQ6snW6UD7AnHid/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "serumV3Usdc": "HdvXMScwAQQh9pEvLZjuaaeJcLTmixxYoMFefeqHFn2E",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "BrzwWsG845VttbTsacZMLKhyc2jAZU12MaPkTYrJHoqm",
            "symbol": "SATM",
            "name": "Star Atlas - The Movie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BrzwWsG845VttbTsacZMLKhyc2jAZU12MaPkTYrJHoqm/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "'The first to arrive at the universe's next frontier is the first to knock on the gates of prosperity.' - Ustur Armi.eldr",
                "imageUrl": "https://storage.googleapis.com/nft-assets/ReBirth/poster-14/star-at-atlas-movie.jpg",
                "serumV3Usdc": "KHw8L2eE6kpp8ziWPghBTtiAVCUvdSKMvGtT1e9AuJd",
                "website": "https://www.staratlas.com"
            }
        },
        {
            "chainId": 101,
            "address": "8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s",
            "symbol": "SDOGE",
            "name": "SolDoge",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s/logo.png",
            "extensions": {
                "coingeckoId": "soldoge",
                "discord": "https://bit.ly/SDOGEDiscord",
                "serumV3Usdc": "9aruV2p8cRWxybx6wMsJwPFqeN7eQVPR74RrxdM3DNdu",
                "twitter": "https://twitter.com/SolanaDoge",
                "website": "https://www.soldoge.io"
            }
        },
        {
            "chainId": 101,
            "address": "DQRNdQWz5NzbYgknGsZqSSXbdhQWvXSe8S56mrtNAs1b",
            "symbol": "ENTROPPP",
            "name": "ENTROPPP (Entropy for security)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DQRNdQWz5NzbYgknGsZqSSXbdhQWvXSe8S56mrtNAs1b/logo.png",
            "tags": [
                "cryptography",
                "blockchain-security",
                "randomness-entropy"
            ],
            "extensions": {
                "website": "https://www.entroppp.com"
            }
        },
        {
            "chainId": 101,
            "address": "8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz",
            "symbol": "YARD",
            "name": "SolYard Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz/logo.png",
            "extensions": {
                "website": "https://solyard.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "nope9HWCJcXVFkG49CDk7oYFtgGsUzsRvHdcJeL2aCL",
            "symbol": "NOPE",
            "name": "NOPE FINANCE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nope9HWCJcXVFkG49CDk7oYFtgGsUzsRvHdcJeL2aCL/logo.png",
            "extensions": {
                "website": "https://nopefinance.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We",
            "symbol": "STNK",
            "name": "Stonks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We/logo.png",
            "extensions": {
                "website": "https://stonkscoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "4368jNGeNq7Tt4Vzr98UWxL647PYu969VjzAsWGVaVH2",
            "symbol": "MEAL",
            "name": "HUNGRY",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4368jNGeNq7Tt4Vzr98UWxL647PYu969VjzAsWGVaVH2/logo.png",
            "extensions": {
                "website": "https://hungrycoin.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8GQsW3f7mdwfjqJon2myADcBsSsRjpXmxHYDG8q1pvV6",
            "symbol": "HOLD",
            "name": "Holdana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8GQsW3f7mdwfjqJon2myADcBsSsRjpXmxHYDG8q1pvV6/logo.png",
            "extensions": {
                "medium": "https://holdanatoken.medium.com/",
                "serumV3Usdc": "G2j5zKtfymPcWMq1YRoKrfUWy64SZ6ZxDVscHSyPQqmz",
                "twitter": "https://twitter.com/HoldanaOfficial"
            }
        },
        {
            "chainId": 101,
            "address": "64SqEfHtu4bZ6jr1mAxaWrLFdMngbKbru9AyaG2Dyk5T",
            "symbol": "wen-token",
            "name": "wen-token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64SqEfHtu4bZ6jr1mAxaWrLFdMngbKbru9AyaG2Dyk5T/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://pythians.pyth.network"
            }
        },
        {
            "chainId": 101,
            "address": "9axWWN2FY8njSSQReepkiSE56U2yAvPFGuaXRQNdkZaS",
            "symbol": "wen-token-2",
            "name": "wen-token-2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9axWWN2FY8njSSQReepkiSE56U2yAvPFGuaXRQNdkZaS/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://pythians.pyth.network"
            }
        },
        {
            "chainId": 101,
            "address": "4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y",
            "symbol": "SNY",
            "name": "Synthetify",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y/logo.png",
            "extensions": {
                "coingeckoId": "synthetify-token",
                "serumV3Usdc": "DPfj2jYwPaezkCmUNm5SSYfkrkz8WFqwGLcxDDUsN3gA",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4wTMJsh3q66PmAkmwEW47qVDevMZMVVWU3n1Yhqztwi6",
            "symbol": "wARCD_v1",
            "name": "Arcade Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wTMJsh3q66PmAkmwEW47qVDevMZMVVWU3n1Yhqztwi6/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xb581E3a7dB80fBAA821AB39342E9Cbfd2ce33c23",
                "assetContract": "https://etherscan.io/address/0xb581E3a7dB80fBAA821AB39342E9Cbfd2ce33c23",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "twitter": "https://twitter.com/ArcadeCityHall",
                "website": "https://arcade.city"
            }
        },
        {
            "chainId": 101,
            "address": "Hjc6Ku7VpMD8TqPUuimDXWvT3RWpnbm1viaUe3dUco3L",
            "symbol": "SHLT",
            "name": "Sehlat",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hjc6Ku7VpMD8TqPUuimDXWvT3RWpnbm1viaUe3dUco3L/logo.png",
            "tags": [
                "payment-token",
                "social-token"
            ],
            "extensions": {
                "medium": "https://medium.com/@all4free.crypto"
            }
        },
        {
            "chainId": 101,
            "address": "Amt5wUJREJQC5pX7Z48YSK812xmu4j3sQVupNhtsEuY8",
            "symbol": "FROG",
            "name": "FROG",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Amt5wUJREJQC5pX7Z48YSK812xmu4j3sQVupNhtsEuY8/logo.png",
            "extensions": {
                "serumV3Usdc": "2Si6XDdpv5zcvYna221eZZrsjsp5xeYoz9W1TVdMdbnt",
                "website": "https://www.froglana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DEAdry5qhNoSkF3mbFrTa6udGbMwUoLnQhvchCu26Ak1",
            "symbol": "JUEL",
            "name": "Juel Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEAdry5qhNoSkF3mbFrTa6udGbMwUoLnQhvchCu26Ak1/logo.png",
            "extensions": {
                "website": "http://juel.gg"
            }
        },
        {
            "chainId": 101,
            "address": "9Y8NT5HT9z2EsmCbYMgKXPRq3h3aa6tycEqfFiXjfZM7",
            "symbol": "CRT",
            "name": "CARROT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Y8NT5HT9z2EsmCbYMgKXPRq3h3aa6tycEqfFiXjfZM7/logo.png",
            "extensions": {
                "serumV3Usdc": "Aa8mN8bXAobmcuHDpbbZh55SoadUry6WdsYz2886Ymqf",
                "website": "https://farmerscarrot.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps",
            "symbol": "MOLA",
            "name": "MOONLANA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps/logo.png",
            "extensions": {
                "coingeckoId": "moonlana",
                "medium": "https://moonlana.medium.com/",
                "twitter": "https://twitter.com/xMoonLana",
                "website": "https://moonlana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3x7UeXDF4imKSKnizK9mYyx1M5bTNzpeALfPeB8S6XT9",
            "symbol": "SKEM",
            "name": "SKEM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3x7UeXDF4imKSKnizK9mYyx1M5bTNzpeALfPeB8S6XT9/logo.svg",
            "extensions": {
                "serumV3Usdc": "HkYJ3dX8CLSGyGZzfuqYiuoDjDmrDiu1vZhPtFJZa5Vt",
                "website": "https://skem.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN",
            "symbol": "SOLAPE",
            "name": "SolAPE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "solape-token",
                "serumV3Usdc": "4zffJaPyeXZ2wr4whHgP39QyTfurqZ2BEd4M5W6SEuon",
                "twitter": "https://twitter.com/SolApeFinance",
                "website": "https://solape.io"
            }
        },
        {
            "chainId": 101,
            "address": "92ibHPyfcwQnsscwkutSP7f9yWzz6GaVHu78gyQ37L1i",
            "symbol": "FOMO",
            "name": "FOMO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/92ibHPyfcwQnsscwkutSP7f9yWzz6GaVHu78gyQ37L1i/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/fomokitty",
                "website": "https://fomokitty.com"
            }
        },
        {
            "chainId": 101,
            "address": "Rs3Mrx9ZSNwsTM3gtJhEbeHQdTfzcvUWHg8T8Ena3pD",
            "symbol": "GLUB",
            "name": "GLUB Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Rs3Mrx9ZSNwsTM3gtJhEbeHQdTfzcvUWHg8T8Ena3pD/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/BWhaleClub",
                "website": "https://billionairewhaleclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE",
            "symbol": "WOOF",
            "name": "WOOF",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "coingeckoId": "woof-token",
                "serumV3Usdc": "CwK9brJ43MR4BJz2dwnDM7EXCNyHhGqCJDrAdsEts8n5",
                "website": "https://woofsolana.com"
            }
        },
        {
            "chainId": 101,
            "address": "MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K",
            "symbol": "MER",
            "name": "Mercurial",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K/logo.png",
            "extensions": {
                "coingeckoId": "mercurial",
                "serumV3Usdc": "G4LcexdCzzJUKZfqyVDQFzpkjhB1JoCNL8Kooxi9nJz5",
                "waterfallbot": "https://bit.ly/MERwaterfall",
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9MhNoxy1PbmEazjPo9kiZPCcG7BiFbhi3bWZXZgacfpp",
            "symbol": "ACMN",
            "name": "ACUMEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MhNoxy1PbmEazjPo9kiZPCcG7BiFbhi3bWZXZgacfpp/logo.png",
            "extensions": {
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "HRhCiCe8WLC4Jsy43Jkhq3poEWpjgXKD1U26XACReimt",
            "symbol": "zSOL",
            "name": "zSOL (ACUMEN)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRhCiCe8WLC4Jsy43Jkhq3poEWpjgXKD1U26XACReimt/logo.png",
            "extensions": {
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "2LBYxD4Jzipk1bEREW6vQk163cj27mUSxmHzW2ujXFNy",
            "symbol": "zUSDC",
            "name": "zUSDC (ACUMEN)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2LBYxD4Jzipk1bEREW6vQk163cj27mUSxmHzW2ujXFNy/logo.png",
            "extensions": {
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "DFTZmEopSWrj6YcsmQAAxypN7cHM3mnruEisJPQFJbs7",
            "symbol": "zBTC",
            "name": "zBTC (ACUMEN)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DFTZmEopSWrj6YcsmQAAxypN7cHM3mnruEisJPQFJbs7/logo.png",
            "extensions": {
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "A8pnvbKWmTjjnUMzmY6pDJRHy3QdQNdqJdL1VFYXX4oW",
            "symbol": "zETH",
            "name": "zETH (ACUMEN)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A8pnvbKWmTjjnUMzmY6pDJRHy3QdQNdqJdL1VFYXX4oW/logo.png",
            "extensions": {
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "9hZt5mP139TvzDBZHtruXxAyjYHiovKXfxW6XNYiofae",
            "symbol": "zSRM",
            "name": "zSRM (ACUMEN)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9hZt5mP139TvzDBZHtruXxAyjYHiovKXfxW6XNYiofae/logo.png",
            "extensions": {
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "BR31LZKtry5tyjVtZ49PFZoZjtE5SeS4rjVMuL9Xiyer",
            "symbol": "zSTEP",
            "name": "zSTEP (ACUMEN)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BR31LZKtry5tyjVtZ49PFZoZjtE5SeS4rjVMuL9Xiyer/logo.png",
            "extensions": {
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "7wZsSyzD4Ba8ZkPhRh62KshQc8TQYiB5KtdNknywE3k4",
            "symbol": "zRAY",
            "name": "zRAY (ACUMEN)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BR31LZKtry5tyjVtZ49PFZoZjtE5SeS4rjVMuL9Xiyer/logo.png",
            "extensions": {
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "EfLvzNsqmkoSneiML5t7uHCPEVRaWCpG4N2WsS39nWCU",
            "symbol": "MUDLEY",
            "name": "MUDLEY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EfLvzNsqmkoSneiML5t7uHCPEVRaWCpG4N2WsS39nWCU/logo.png",
            "extensions": {
                "website": "https://www.mudley.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GpYMp8eP3HADY8x1jLVfFVBVYqxFNxT5mFhZAZt9Poco",
            "symbol": "CAPE",
            "name": "Crazy Ape Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpYMp8eP3HADY8x1jLVfFVBVYqxFNxT5mFhZAZt9Poco/logo.png",
            "extensions": {
                "website": "https://www.crazyapecoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7ApYvMWwHJSgWz9BvMuNzqzUAqYbxByjzZu31t8FkYDy",
            "symbol": "SFairy",
            "name": "Fairy Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/debianos1/logo-token/main/fairyfinane%20.png",
            "extensions": {
                "twitter": "https://twitter.com/fairy_finance"
            }
        },
        {
            "chainId": 101,
            "address": "7Csho7qjseDjgX3hhBxfwP1W3LYARK3QH3PM2x55we14",
            "symbol": "LOTTO",
            "name": "Lotto",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Csho7qjseDjgX3hhBxfwP1W3LYARK3QH3PM2x55we14/logo.png",
            "extensions": {
                "address": "0xb0dfd28d3cf7a5897c694904ace292539242f858",
                "assetContract": "https://etherscan.io/address/0xb0dfd28d3cf7a5897c694904ace292539242f858",
                "coingeckoId": "lotto",
                "serumV3Usdc": "9MZKfgZzPgeidAukYpHtsLYm4eAdJFnR7nhPosWT8jiv",
                "telegram": "https://t.me/lottofinance",
                "website": "https://lotto.finance"
            }
        },
        {
            "chainId": 101,
            "address": "7uv3ZvZcQLd95bUp5WMioxG7tyAZVXFfr8JYkwhMYrnt",
            "symbol": "BOLE",
            "name": "Bole Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uv3ZvZcQLd95bUp5WMioxG7tyAZVXFfr8JYkwhMYrnt/logo.png",
            "extensions": {
                "coingeckoId": "bole-token",
                "serumV3Usdc": "9yGqsboBtvztDgGbGFEaBBT2G8dUMhxewXDQpy6T3eDm",
                "website": "https://tokenbole.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7Qbjc2DZ6K2t6NtQhQnJfsi9V2Aa2KSmKyWZZEdfTXsT",
            "symbol": "XTAG",
            "name": "XTAG",
            "decimals": 9,
            "logoURI": "https://pbs.twimg.com/profile_images/1422971633048834054/PqdED5l7.png",
            "extensions": {
                "medium": "https://medium.com/xhashtag",
                "twitter": "https://twitter.com/xhashtagio",
                "website": "https://www.xhashtag.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Bxp46xCB6CLjiqE99QaTcJAaY1hYF1o63DUUrXAS7QFu",
            "symbol": "mBRZ",
            "name": "SolMiner Bronze",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bxp46xCB6CLjiqE99QaTcJAaY1hYF1o63DUUrXAS7QFu/logo.png",
            "extensions": {
                "medium": "https://solminer.medium.com/",
                "twitter": "https://twitter.com/SolMinerproject",
                "website": "https://solminer.app"
            }
        },
        {
            "chainId": 101,
            "address": "GZNrMEdrt6Vg428JzvJYRGGPpVxgjUPsg6WLqKBvmNLw",
            "symbol": "mPLAT",
            "name": "SolMiner Platinum",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZNrMEdrt6Vg428JzvJYRGGPpVxgjUPsg6WLqKBvmNLw/logo.png",
            "extensions": {
                "medium": "https://solminer.medium.com/",
                "twitter": "https://twitter.com/SolMinerproject",
                "website": "https://solminer.app"
            }
        },
        {
            "chainId": 101,
            "address": "Er7a3ugS6kkAqj6sp3UmXEFAFrDdLMRQEkV9QH2fwRYA",
            "symbol": "mDIAM",
            "name": "SolMiner Diamond",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Er7a3ugS6kkAqj6sp3UmXEFAFrDdLMRQEkV9QH2fwRYA/logo.png",
            "extensions": {
                "medium": "https://solminer.medium.com/",
                "twitter": "https://twitter.com/SolMinerproject",
                "website": "https://solminer.app"
            }
        },
        {
            "chainId": 101,
            "address": "5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW",
            "symbol": "APYS",
            "name": "APYSwap",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "apyswap",
                "website": "https://apyswap.com"
            }
        },
        {
            "chainId": 101,
            "address": "ss1gxEUiufJyumsXfGbEwFe6maraPmc53fqbnjbum15",
            "symbol": "SS1",
            "name": "Naked Shorts",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ss1gxEUiufJyumsXfGbEwFe6maraPmc53fqbnjbum15/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://www.arweave.net/KBzRUmQNX6VKDH41N_uOETtJH21YtWXrOz270b8eqyo?ext=glb",
                "description": "After a gamma squeeze event he was left covered in theta. Due to the accident he lost his memories but gained the ability to refract light. He joins the tournament hoping to discover more about his past. His only clue is a damaged ID card with the word Malvin inscribed. Special: 'Now You See Me'",
                "imageUrl": "https://www.arweave.net/N-RGNyi1o1evhr7jTCXxHQlSndNPdnHWEzUTbTGMCl4",
                "twitter": "https://twitter.com/sol__survivor",
                "website": "https://www.sol-talk.com/sol-survivor"
            }
        },
        {
            "chainId": 101,
            "address": "GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp",
            "symbol": "SOLPAD",
            "name": "Solpad Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/solpad-finance",
                "telegram": "https://t.me/solpadfinance_chat",
                "telegramAnnouncements": "https://t.me/solpadfinance",
                "twitter": "https://twitter.com/FinanceSolpad",
                "website": "https://www.solpad.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "A3iozx9T9wgrtybnecQ9rv56y9RF8ThUrwRGWiF7hsmZ",
            "symbol": "$SHIVER",
            "name": "Shibaverse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3iozx9T9wgrtybnecQ9rv56y9RF8ThUrwRGWiF7hsmZ/logo.png",
            "extensions": {
                "coingeckoId": "shibaverse-token",
                "twitter": "https://twitter.com/theshibaverse",
                "website": "https://www.shibaverse.co"
            }
        },
        {
            "chainId": 101,
            "address": "FGMTuwmVVz9hUJzA8shYiEnM16wsYDoSmYoy13UZe1kk",
            "symbol": "SHIVER",
            "name": "Shibaverse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGMTuwmVVz9hUJzA8shYiEnM16wsYDoSmYoy13UZe1kk/logo.png",
            "extensions": {
                "coingeckoId": "shibaverse-token",
                "twitter": "https://twitter.com/theshibaverse",
                "website": "https://www.shibaverse.co"
            }
        },
        {
            "chainId": 101,
            "address": "ERPueLaiBW48uBhqX1CvCYBv2ApHN6ZFuME1MeQGTdAi",
            "symbol": "MIT",
            "name": "Muskimum Impact Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ERPueLaiBW48uBhqX1CvCYBv2ApHN6ZFuME1MeQGTdAi/logo.png",
            "tags": [
                "mit",
                "musk"
            ],
            "extensions": {
                "serumV3Usdc": "3mhrhTFrHtxe7uZhvzBhzneR3bD3hDyWcgEkR8EcvNZk",
                "twitter": "https://twitter.com/muskimum",
                "website": "https://muskimum.win/"
            }
        },
        {
            "chainId": 101,
            "address": "BsDrXiQaFd147Fxq1fQYbJQ77P6tmPkRJQJzkKvspDKo",
            "symbol": "SOLA",
            "name": "SolaPAD Token (deprecated)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BsDrXiQaFd147Fxq1fQYbJQ77P6tmPkRJQJzkKvspDKo/logo.png",
            "tags": [
                "SOLA",
                "LaunchPAD"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolaPAD",
                "website": "https://www.solapad.org/"
            }
        },
        {
            "chainId": 101,
            "address": "7fCzz6ZDHm4UWC9Se1RPLmiyeuQ6kStxpcAP696EuE1E",
            "symbol": "SHBL",
            "name": "Shoebill Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fCzz6ZDHm4UWC9Se1RPLmiyeuQ6kStxpcAP696EuE1E/logo.png",
            "extensions": {
                "website": "https://shoebillco.in/"
            }
        },
        {
            "chainId": 101,
            "address": "GnaFnTihwQFjrLeJNeVdBfEZATMdaUwZZ1RPxLwjbVwb",
            "symbol": "SHBL-USDC",
            "name": "Raydium Permissionless LP Token (SHBL-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GnaFnTihwQFjrLeJNeVdBfEZATMdaUwZZ1RPxLwjbVwb/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Djoz8btdR7p6xWHoVtPYF3zyN9LU5BBfMoDk4HczSDqc",
            "symbol": "AUSS",
            "name": "Ausshole",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Djoz8btdR7p6xWHoVtPYF3zyN9LU5BBfMoDk4HczSDqc/logo.svg",
            "extensions": {
                "serumV3Usdc": "bNbYoc2KawipbXj76BiXbUdf2NcGKWkdp4S9uDvWXB1",
                "twitter": "https://twitter.com/ausstoken",
                "website": "https://auss.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs",
            "symbol": "TULIP",
            "name": "Tulip",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tulip.png",
            "tags": [
                "tulip",
                "tulip-protocol",
                "vaults"
            ],
            "extensions": {
                "coingeckoId": "solfarm",
                "serumV3Usdc": "8GufnKq7YnXKhnB3WNhgy5PzU9uvHbaaRrZWQK6ixPxW",
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "5trVBqv1LvHxiSPMsHtEZuf8iN82wbpDcR5Zaw7sWC3s",
            "symbol": "soJPYC",
            "name": "JPY Coin(Sollet)(Deprecated)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5trVBqv1LvHxiSPMsHtEZuf8iN82wbpDcR5Zaw7sWC3s/logo.png",
            "tags": [
                "stablecoin",
                "ethereum",
                "wrapped-sollet"
            ],
            "extensions": {
                "website": "https://jpyc.jp/"
            }
        },
        {
            "chainId": 101,
            "address": "3QuAYThYKFXSmrTcSHsdd7sAxaFBobaCkLy2DBYJLMDs",
            "symbol": "TYNA",
            "name": "wTYNA",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3QuAYThYKFXSmrTcSHsdd7sAxaFBobaCkLy2DBYJLMDs/logo.png",
            "tags": [
                "ERC20",
                "ethereum"
            ],
            "extensions": {
                "address": "0x4ae54790c130B21E8CbaCAB011C6170e079e6eF5",
                "assetContract": "https://etherscan.io/address/0x4ae54790c130B21E8CbaCAB011C6170e079e6eF5",
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "twitter": "https://twitter.com/btc_AP",
                "website": "http://lendingbot.s3-website-us-east-1.amazonaws.com/whitepaper.html"
            }
        },
        {
            "chainId": 101,
            "address": "7zsKqN7Fg2s9VsqAq6XBoiShCVohpGshSUvoWBc6jKYh",
            "symbol": "soARDX",
            "name": "Wrapped ArdCoin (Sollet)",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zsKqN7Fg2s9VsqAq6XBoiShCVohpGshSUvoWBc6jKYh/logo.png",
            "tags": [
                "wrapped-sollet",
                "ethereum"
            ],
            "extensions": {
                "coingeckoId": "ardcoin",
                "website": "https://ardcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "7zphtJVjKyECvQkdfxJNPx83MNpPT6ZJyujQL8jyvKcC",
            "symbol": "SSHIB",
            "name": "SolShib",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zphtJVjKyECvQkdfxJNPx83MNpPT6ZJyujQL8jyvKcC/logo.png",
            "extensions": {
                "website": "https://solshib.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HoSWnZ6MZzqFruS1uoU69bU7megzHUv6MFPQ5nqC6Pj2",
            "symbol": "SGI",
            "name": "SolGift",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HoSWnZ6MZzqFruS1uoU69bU7megzHUv6MFPQ5nqC6Pj2/logo.png",
            "extensions": {
                "website": "https://solshib.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GpS9AavHtSUspaBnL1Tu26FWbUAdW8tm3MbacsNvwtGu",
            "symbol": "SOLT",
            "name": "Soltriever",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpS9AavHtSUspaBnL1Tu26FWbUAdW8tm3MbacsNvwtGu/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/_Soltriever",
                "website": "http://soltriever.info/"
            }
        },
        {
            "chainId": 101,
            "address": "2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG",
            "symbol": "KEKW",
            "name": "kekwcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG/logo.png",
            "extensions": {
                "description": "Kekwcoin is a creative community platform for content creators to monetize their artwork and get financial support from investors.",
                "discord": "https://discord.gg/kekw",
                "medium": "https://kekwcoin.medium.com/",
                "serumV3Usdc": "N99ngemA29qSKqdDW7kRiZHS7h2wEFpdgRvgE3N2jy6",
                "twitter": "https://twitter.com/kekwcoin",
                "website": "https://kekw.io/"
            }
        },
        {
            "chainId": 101,
            "address": "qs9Scx8YwNXS6zHYPCnDnyHQcRHg3QwXxpyCXs5tdM8",
            "symbol": "POCO",
            "name": "POWER COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qs9Scx8YwNXS6zHYPCnDnyHQcRHg3QwXxpyCXs5tdM8/logo.png",
            "tags": [
                "social-token",
                "poco"
            ]
        },
        {
            "chainId": 101,
            "address": "AndyTyTHiXSHT3DhKSehsg3BEdAWMHbw9xVeeDS3WZYh",
            "symbol": "ANDY",
            "name": "Andy token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AndyTyTHiXSHT3DhKSehsg3BEdAWMHbw9xVeeDS3WZYh/logo.png"
        },
        {
            "chainId": 101,
            "address": "7CVZWtuaA34gQZazbWwDhK8kFwPUubAiPaPnz6gAFjxF",
            "symbol": "DIGI",
            "name": "DIGI Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7CVZWtuaA34gQZazbWwDhK8kFwPUubAiPaPnz6gAFjxF/logo.png"
        },
        {
            "chainId": 101,
            "address": "VernWPaodzUcAXnZQAcCguQPbWJvUub1zuURzLvF128",
            "symbol": "VERNER",
            "name": "Verner Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VernWPaodzUcAXnZQAcCguQPbWJvUub1zuURzLvF128/logo.png"
        },
        {
            "chainId": 101,
            "address": "FxCvbCVAtNUEKSiKoF6xt2pWPfpXuYFWYbuQySaRnV5R",
            "symbol": "LOOP",
            "name": "LC Andy Social Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FxCvbCVAtNUEKSiKoF6xt2pWPfpXuYFWYbuQySaRnV5R/logo.png",
            "tags": [
                "social-token",
                "loop"
            ]
        },
        {
            "chainId": 101,
            "address": "4MRVPdN41888CwKSpY37K8HtN2FM9Bc3xTnhf9EDdcou",
            "symbol": "MGT",
            "name": "Mofogasy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4MRVPdN41888CwKSpY37K8HtN2FM9Bc3xTnhf9EDdcou/logo.png",
            "tags": [
                "social-token",
                "MGT"
            ]
        },
        {
            "chainId": 101,
            "address": "6DNkUoMa6vNo3CsxAw5XMJhjmdPbPBENHJ6w35eMXESo",
            "symbol": "MEME",
            "name": "MemeMarketplace",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6DNkUoMa6vNo3CsxAw5XMJhjmdPbPBENHJ6w35eMXESo/logo.png",
            "extensions": {
                "discord": "https://discord.gg/mememarketplace",
                "twitter": "https://twitter.com/MemeMarketNFT",
                "website": "https://www.mememarket.place"
            }
        },
        {
            "chainId": 101,
            "address": "pL5mVp1DByEFufunmymuBNFcSsyJftXguDMci7Jg1Du",
            "symbol": "MEW",
            "name": "MEWSOL",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/YudaAdiPratama/MEWSOL/MEWWALLET2.png",
            "tags": [
                "MEME-Token",
                "Fun-Token",
                "MEW"
            ]
        },
        {
            "chainId": 101,
            "address": "3iXydLpqi38CeGDuLFF1WRbPrrkNbUsgVf98cNSg6NaA",
            "symbol": "Spro",
            "name": "Sproken Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/kechricc/Sproken-Token-Logo/SprokenToken.png",
            "extensions": {
                "website": "https://www.sprokentoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "95GBBtPvFotNSLDuM4DYTukZhxptRbJjW7dMH45WMS2N",
            "symbol": "CCAT",
            "name": "Cute Baby Cat Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95GBBtPvFotNSLDuM4DYTukZhxptRbJjW7dMH45WMS2N/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/RhQTNhRk2X",
                "twitter": "https://twitter.com/CuteBabyCatCoin"
            }
        },
        {
            "chainId": 101,
            "address": "H5gczCNbrtso6BqGKihF97RaWaxpUEZnFuFUKK4YX3s2",
            "symbol": "BDE",
            "name": "Big Defi Energy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/bigchickendefi/bigchicken/main/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/Bigdefi",
                "website": "https://bigdefienergy.com"
            }
        },
        {
            "chainId": 101,
            "address": "cREsCN7KAyXcBG2xZc8qrfNHMRgC3MhTb4n3jBnNysv",
            "symbol": "DWT",
            "name": "DARK WEB TOKEN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cREsCN7KAyXcBG2xZc8qrfNHMRgC3MhTb4n3jBnNysv/logo.png",
            "tags": [
                "MEME"
            ],
            "extensions": {
                "serumV3Usdc": "526WW289h5wibg1Q55sK16CGoNip8H5d2AXVbaAGcUMb",
                "website": "https://www.darkwebtoken.live"
            }
        },
        {
            "chainId": 101,
            "address": "EdGAZ8JyFTFbmVedVTbaAEQRb6bxrvi3AW3kz8gABz2E",
            "symbol": "DOGA",
            "name": "Dogana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdGAZ8JyFTFbmVedVTbaAEQRb6bxrvi3AW3kz8gABz2E/logo.png",
            "extensions": {
                "serumV3Usdc": "H1Ywt7nSZkLDb2o3vpA5yupnBc9jr1pXtdjMm4Jgk1ay",
                "twitter": "https://twitter.com/DoganaOfficial"
            }
        },
        {
            "chainId": 101,
            "address": "3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc",
            "symbol": "CHEEMS",
            "name": "Cheems",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc/logo.png",
            "extensions": {
                "coingeckoId": "cheems",
                "serumV3Usdc": "5WVBCaUPZF4HP3io9Z56N71cPMJt8qh3c4ZwSjRDeuut",
                "twitter": "https://twitter.com/theCheemsToken",
                "website": "https://cheems.co/"
            }
        },
        {
            "chainId": 101,
            "address": "AWW5UQfMBnPsTaaxCK7cSEmkj1kbX2zUrqvgKXStjBKx",
            "symbol": "SBFC",
            "name": "SBF Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWW5UQfMBnPsTaaxCK7cSEmkj1kbX2zUrqvgKXStjBKx/logo.png",
            "tags": [
                "utility-token",
                "SBF",
                "sbfcoin",
                "SBFC"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sbfcoin",
                "website": "https://www.sbfcoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "FRbqQnbuLoMbUG4gtQMeULgCDHyY6YWF9NRUuLa98qmq",
            "symbol": "ECOP",
            "name": "EcoPoo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FRbqQnbuLoMbUG4gtQMeULgCDHyY6YWF9NRUuLa98qmq/logo.png",
            "tags": [
                "meme"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EcoPoo_Official"
            }
        },
        {
            "chainId": 101,
            "address": "5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB",
            "symbol": "CATO",
            "name": "CATO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB/logo.png",
            "tags": [
                "Meme-Token"
            ],
            "extensions": {
                "coingeckoId": "cato",
                "serumV3Usdc": "9fe1MWiKqUdwift3dEpxuRHWftG72rysCRHbxDy6i9xB",
                "telegram": "https://t.me/SolanaCATO",
                "twitter": "https://twitter.com/SolanaCATO",
                "website": "https://official.catodex.com"
            }
        },
        {
            "chainId": 101,
            "address": "J81fW7aza8wVUG1jjzhExsNMs3MrzwT5WrofgFqMjnSA",
            "symbol": "TOM",
            "name": "Tombili",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J81fW7aza8wVUG1jjzhExsNMs3MrzwT5WrofgFqMjnSA/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/cryptomindex",
                "website": "https://cryptomindex.com"
            }
        },
        {
            "chainId": 101,
            "address": "GunpHq4fn9gSSyGbPMYXTzs9nBS8RY88CX1so4V8kCiF",
            "symbol": "FABLE",
            "name": "Fable",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GunpHq4fn9gSSyGbPMYXTzs9nBS8RY88CX1so4V8kCiF/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/fable_finance",
                "website": "https://fable.finance"
            }
        },
        {
            "chainId": 101,
            "address": "6L5DzH3p1t1PrCrVkudasuUnWbK7Jq9tYwcwWQiV6yd7",
            "symbol": "LZD",
            "name": "Lizard",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6L5DzH3p1t1PrCrVkudasuUnWbK7Jq9tYwcwWQiV6yd7/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/lzd_sol",
                "website": "https://www.lzdsol.io"
            }
        },
        {
            "chainId": 101,
            "address": "EZqcdU8RLu9EChZgrY2BNVg8eovfdGyTiY2bd69EsPgQ",
            "symbol": "FELON",
            "name": "FuckElon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EZqcdU8RLu9EChZgrY2BNVg8eovfdGyTiY2bd69EsPgQ/logo.png",
            "extensions": {
                "telegram": "https://t.me/joinchat/cgUOCIRSTJ9hZmY1",
                "telegramAnnouncements": "https://t.me/fuckelonmusktoday",
                "twitter": "https://twitter.com/FuckElonMusk8",
                "website": "https://fuckelonmusk.godaddysites.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HBHMiauecxer5FCzPeXgE2A8ZCf7fQgxxwo4vfkFtC7s",
            "symbol": "SLNDN",
            "name": "Solanadon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBHMiauecxer5FCzPeXgE2A8ZCf7fQgxxwo4vfkFtC7s/logo.png",
            "extensions": {
                "telegramAnnouncements": "https://t.me/solanadonann",
                "twitter": "https://twitter.com/SolanadonCoin",
                "website": "https://solanadon.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GReBHpMgCadZRij4B111c94cqU9TktvJ45rWZRQ5b1A5",
            "symbol": "PINGU",
            "name": "Penguincoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GReBHpMgCadZRij4B111c94cqU9TktvJ45rWZRQ5b1A5/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/penguincoin1"
            }
        },
        {
            "chainId": 101,
            "address": "5WUab7TCvth43Au5vk6wKjchTzWFeyPEUSJE1MPJtTZE",
            "symbol": "KEKN1",
            "name": "KEKW In Solana Tripping",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WUab7TCvth43Au5vk6wKjchTzWFeyPEUSJE1MPJtTZE/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kekwcoin",
                "website": "https://www.kekw.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9KEe6o1jRTqFDFBo2AezsskcxBNwuq1rVeVat1Td8zbV",
            "symbol": "MPAD",
            "name": "MercuryPAD Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9KEe6o1jRTqFDFBo2AezsskcxBNwuq1rVeVat1Td8zbV/logo.png",
            "tags": [
                "MPAD",
                "LaunchPAD"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MercuryPad",
                "website": "https://mercurypad.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4KAFf8ZpNCn1SWLZFo5tbeZsKpVemsobbVZdERWxRvd2",
            "symbol": "SGT",
            "name": "Sangga Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4KAFf8ZpNCn1SWLZFo5tbeZsKpVemsobbVZdERWxRvd2/logo.png",
            "extensions": {
                "website": "https://sanggatalk.io"
            }
        },
        {
            "chainId": 101,
            "address": "Ae1aeYK9WrB2kP29jJU4aUUK7Y1vzsGNZFKoe4BG2h6P",
            "symbol": "OLDNINJA",
            "name": "OLDNINJA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ae1aeYK9WrB2kP29jJU4aUUK7Y1vzsGNZFKoe4BG2h6P/logo.png",
            "extensions": {
                "website": "https://www.ninjaprotocol.io/oldninja/"
            }
        },
        {
            "chainId": 101,
            "address": "FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ",
            "symbol": "NINJA",
            "name": "NINJA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ/logo.png",
            "extensions": {
                "coingeckoId": "ninja-protocol",
                "serumV3Usdc": "J4oPt5Q3FYxrznkXLkbosAWrJ4rZLqJpGqz7vZUL4eMM",
                "website": "https://www.ninjaprotocol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "E6UBhrtvP4gYHAEgoBi8kDU6DrPPmQxTAJvASo4ptNev",
            "symbol": "SOLDOG",
            "name": "SOLDOG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6UBhrtvP4gYHAEgoBi8kDU6DrPPmQxTAJvASo4ptNev/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solanadog",
                "website": "https://solanadog.io"
            }
        },
        {
            "chainId": 102,
            "address": "rz251Qbsa27sL8Y1H7h4qu71j6Q7ukNmskg5ZDhPCg3",
            "symbol": "HIRO",
            "name": "Hiro LaunchDAO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rz251Qbsa27sL8Y1H7h4qu71j6Q7ukNmskg5ZDhPCg3/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/HiroLaunchdao",
                "website": "https://hiro-finance.github.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9nusLQeFKiocswDt6NQsiErm1M43H2b8x6v5onhivqKv",
            "symbol": "LLAMA",
            "name": "SOLLAMA",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nusLQeFKiocswDt6NQsiErm1M43H2b8x6v5onhivqKv/logo.png",
            "extensions": {
                "serumV3Usdc": "6KKRzEedDY1dybdrfFzjDzTxCVuxdHgaiQFYUfoupigK",
                "twitter": "https://twitter.com/SollamaFinance",
                "website": "https://sollama.finance"
            }
        },
        {
            "chainId": 101,
            "address": "BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3",
            "symbol": "BOP",
            "name": "Boring Protocol",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/boringprotocol/brand-assets/main/boplogo.png",
            "tags": [
                "security-token",
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "boring-protocol",
                "serumV3Usdc": "7MmPwD1K56DthW14P1PnWZ4zPCbPWemGs3YggcT1KzsM",
                "twitter": "https://twitter.com/BoringProtocol",
                "website": "https://boringprotocol.io"
            }
        },
        {
            "chainId": 101,
            "address": "ER8Xa8YxJLC3CFJgdAxJs46Rdhb7B3MjgbPZsVg1aAFV",
            "symbol": "MOLAMON",
            "name": "MOLAMON",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ER8Xa8YxJLC3CFJgdAxJs46Rdhb7B3MjgbPZsVg1aAFV/logo.png",
            "extensions": {
                "description": "The first $MOLA NFT on Solana Blockchain.",
                "imageUrl": "https://gateway.pinata.cloud/ipfs/QmbdEesuzVUMzqaumrZNaWnwnz4WwDvqDyfrFneVDjqr2e/molamonbg.gif",
                "medium": "https://moonlana.medium.com/",
                "twitter": "https://twitter.com/xMoonLana",
                "website": "https://moonlana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4ezHExHThrwnnoqKcMNbUwcVYXzdkDerHFGfegnTqA2E",
            "symbol": "STUD",
            "name": "SolanaToolsUtilityDapp",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ezHExHThrwnnoqKcMNbUwcVYXzdkDerHFGfegnTqA2E/logo.png",
            "extensions": {
                "website": "https://www.solanatools.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AZtNYaEAHDBeK5AvdzquZWjc4y8cj5sKWH1keUJGMuPV",
            "symbol": "RESP",
            "name": "RESPECT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AZtNYaEAHDBeK5AvdzquZWjc4y8cj5sKWH1keUJGMuPV/logo.png",
            "extensions": {
                "website": "https://respect.cash"
            }
        },
        {
            "chainId": 101,
            "address": "5j6BmiZTfHssaWPT23EQYQci3w57VTw7QypKArQZbSZ9",
            "symbol": "CHAD",
            "name": "ChadTrader Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5j6BmiZTfHssaWPT23EQYQci3w57VTw7QypKArQZbSZ9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/chadtraderio",
                "website": "https://chadtrader.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z",
            "symbol": "DXL",
            "name": "Dexlab",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/dexlab-project/assets/master/dexlab/dexlab_symbol_logo.svg",
            "extensions": {
                "coingeckoId": "dexlab",
                "serumV3Usdc": "DYfigimKWc5VhavR4moPBibx9sMcWYVSjVdWvPztBPTa",
                "twitter": "https://twitter.com/dexlab_official",
                "website": "https://www.dexlab.space/"
            }
        },
        {
            "chainId": 101,
            "address": "6TCbtxs6eYfMKVF9ppTNvbUemW2YnpFig6z1jSqgM16e",
            "symbol": "STRANGE",
            "name": "STRANGE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TCbtxs6eYfMKVF9ppTNvbUemW2YnpFig6z1jSqgM16e/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://safepluto.tech"
            }
        },
        {
            "chainId": 101,
            "address": "BYNHheaKFX2WRGQTpMZNsM6vAyJXvkeMoMcixKfVKxY9",
            "symbol": "PLUTES",
            "name": "Plutonium",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYNHheaKFX2WRGQTpMZNsM6vAyJXvkeMoMcixKfVKxY9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://safepluto.tech"
            }
        },
        {
            "chainId": 101,
            "address": "8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA",
            "symbol": "GRAPE",
            "name": "Grape",
            "decimals": 6,
            "logoURI": "https://lh3.googleusercontent.com/y7Wsemw9UVBc9dtjtRfVilnS1cgpDt356PPAjne5NvMXIwWz9_x7WKMPH99teyv8vXDmpZinsJdgiFQ16_OAda1dNcsUxlpw9DyMkUk=s0",
            "extensions": {
                "coingeckoId": "grape-2",
                "website": "https://grapes.network"
            }
        },
        {
            "chainId": 101,
            "address": "6qAJ9W5XCb2JyrTRV8bcoXa6HmHNz9YikLMWK71dH1sY",
            "symbol": "SOLEM",
            "name": "SolemPad",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6qAJ9W5XCb2JyrTRV8bcoXa6HmHNz9YikLMWK71dH1sY/logo.png",
            "extensions": {
                "telegram": "https://t.me/solempad",
                "twitter": "https://twitter.com/solempad",
                "website": "https://www.solempad.net/"
            }
        },
        {
            "chainId": 101,
            "address": "7xzovRepzLvXbbpVZLYKzEBhCNgStEv1xpDqf1rMFFKX",
            "symbol": "KERMIT",
            "name": "Kermit",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xzovRepzLvXbbpVZLYKzEBhCNgStEv1xpDqf1rMFFKX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KermitFinance",
                "website": "https://www.kermitfinance.com"
            }
        },
        {
            "chainId": 101,
            "address": "3VhB8EAL8dZ457SiksLPpMUR1pyACpbNh5rTjQUEVCcH",
            "symbol": "TUTL",
            "name": "TurtleTraders",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VhB8EAL8dZ457SiksLPpMUR1pyACpbNh5rTjQUEVCcH/logo.png",
            "tags": [
                "social-token",
                "Turtles"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Turtle_Traders"
            }
        },
        {
            "chainId": 101,
            "address": "8tbAqS4dFNEeC6YGWpNnusc3JcxoFLMiiLPyHctgGYFe",
            "symbol": "PIPANA",
            "name": "Pipana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8tbAqS4dFNEeC6YGWpNnusc3JcxoFLMiiLPyHctgGYFe/logo.png",
            "extensions": {
                "serumV3Usdc": "EHCty8rwVcE1T8Ccd6Emrd1oB2yNeMXz6kcgmE1Qa6sG",
                "twitter": "https://twitter.com/itspipana",
                "website": "https://pip.monster"
            }
        },
        {
            "chainId": 101,
            "address": "8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx",
            "symbol": "CKC",
            "name": "ChikinCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/rsQQsAcAn7",
                "facebook": "https://www.facebook.com/Chikin-114453524487432",
                "github": "https://github.com/ChikinDeveloper",
                "instagram": "https://www.instagram.com/chikintuttut",
                "telegram": "https://t.me/+BG1HI84WGdwyMmU0",
                "tiktok": "https://www.tiktok.com/@chikintuttut",
                "twitter": "https://twitter.com/ChikinDev",
                "website": "https://chikin.run"
            }
        },
        {
            "chainId": 101,
            "address": "ATxXyewb1cXThrQFmwHUy4dtPTErfsuqkg7JcUXgLgqo",
            "symbol": "SPW",
            "name": "SpiderSwap",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATxXyewb1cXThrQFmwHUy4dtPTErfsuqkg7JcUXgLgqo/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/Spider_swap",
                "website": "https://www.spiderswap.org"
            }
        },
        {
            "chainId": 101,
            "address": "1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND",
            "symbol": "NRA",
            "name": "NORA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolNoraToken"
            }
        },
        {
            "chainId": 101,
            "address": "BrwgXmUtNd32dTKdP5teie68EmBnjGq8Wp3MukHehUBY",
            "symbol": "GSTONKS",
            "name": "Gamestonks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BrwgXmUtNd32dTKdP5teie68EmBnjGq8Wp3MukHehUBY/logo.png",
            "extensions": {
                "website": "https://www.game-stonks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8",
            "symbol": "MEOW",
            "name": "SOL-CATS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solcat777",
                "website": "https://www.solcats.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "8p758d6ZMkLUYQ949XZa6s1Mo31mhPpLcaaAPUBMeAmx",
            "symbol": "BOO",
            "name": "Gene Crucean",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8p758d6ZMkLUYQ949XZa6s1Mo31mhPpLcaaAPUBMeAmx/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.genecrucean.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Gro98oTmXxCVX8HKr3q2tMnP5ztoC77q6KehFDnAB983",
            "symbol": "SOLMO",
            "name": "SolMoon",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gro98oTmXxCVX8HKr3q2tMnP5ztoC77q6KehFDnAB983/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solmoonfinance",
                "website": "https://www.solmoonfinance.com"
            }
        },
        {
            "chainId": 101,
            "address": "2wBXHm4oxmed7ZoDkPL4DU8BuRfMYkubVu8T4N38vXdb",
            "symbol": "MSC",
            "name": "MasterCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wBXHm4oxmed7ZoDkPL4DU8BuRfMYkubVu8T4N38vXdb/logo.png",
            "extensions": {
                "discord": "https://t.co/CXZN9Ncd6Q?amp=1",
                "medium": "https://medium.com/@mastercoin-eu",
                "twitter": "https://twitter.com/MasterCoin_",
                "website": "https://mastercoin.site"
            }
        },
        {
            "chainId": 101,
            "address": "8b9mQo6ZU2rwZQgSFqGNQvXzrUSHDTRpKSKi9XXdGmqN",
            "symbol": "CHANGPENGUIN",
            "name": "CHANGPENGUIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8b9mQo6ZU2rwZQgSFqGNQvXzrUSHDTRpKSKi9XXdGmqN/logo.png",
            "extensions": {
                "website": "https://artbomb.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "3KnVxWhoYdc9UwDr5WMVkZp2LpF7gnojg7We7MUd6ixQ",
            "symbol": "WOLFE",
            "name": "Wolfecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KnVxWhoYdc9UwDr5WMVkZp2LpF7gnojg7We7MUd6ixQ/logo.png",
            "extensions": {
                "website": "https://www.wolfecoin.online/"
            }
        },
        {
            "chainId": 101,
            "address": "BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG",
            "symbol": "PGNT",
            "name": "PigeonSol Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/PigeonSol",
                "website": "https://pigeonsol.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg",
            "symbol": "APEX",
            "name": "APEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg/logo.png",
            "extensions": {
                "coingeckoId": "apexit-finance",
                "discord": "https://discord.gg/aASQy2dWsN",
                "telegram": "https://t.me/apexit_finance",
                "twitter": "https://twitter.com/apeXit_finance",
                "website": "https://apexit.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4NPzwMK2gfgQ6rTv8x4EE1ZvKW6MYyYTSrAZCx7zxyaX",
            "symbol": "KLB",
            "name": "Black Label",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4NPzwMK2gfgQ6rTv8x4EE1ZvKW6MYyYTSrAZCx7zxyaX/logo.svg",
            "extensions": {
                "coingeckoId": "black-label",
                "serumV3Usdc": "AVC5hkVjWqRzD9RXXwjcNiVAAR2rUvDGwhqoCd2TQNY8",
                "twitter": "https://twitter.com/klbtoken",
                "website": "https://klbtoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61",
            "symbol": "SOLD",
            "name": "Solanax",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61/logo.png",
            "extensions": {
                "telegram": "https://t.me/solanaxcommunity",
                "twitter": "https://twitter.com/Solanaxorg",
                "website": "https://solanax.org"
            }
        },
        {
            "chainId": 101,
            "address": "3RSafdgu7P2smSGHJvSGQ6kZVkcErZXfZTtynJYboyAu",
            "symbol": "SINE",
            "name": "SINE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3RSafdgu7P2smSGHJvSGQ6kZVkcErZXfZTtynJYboyAu/logo.svg",
            "tags": [
                "security-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolAiNetwork",
                "website": "https://solainetwork.com/"
            }
        },
        {
            "chainId": 101,
            "address": "SioTkQxHyAs98ouRiyi1YDv3gLMSrX3eNBg61GH7NrM",
            "symbol": "SIO",
            "name": "Simplio",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SioTkQxHyAs98ouRiyi1YDv3gLMSrX3eNBg61GH7NrM/logo.svg",
            "extensions": {
                "discord": "https://discord.com/invite/aKhjuwZmdP",
                "twitter": "https://twitter.com/simplioOfficial",
                "website": "https://simplio.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5Ne8szYZGGcXByFQA4zNBqJaWNdeTcJYAf2ZDLFfjVdA",
            "symbol": "TIGER",
            "name": "Tiger Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Ne8szYZGGcXByFQA4zNBqJaWNdeTcJYAf2ZDLFfjVdA/logo.png",
            "tags": [
                "meme-token",
                "non-nft",
                "nft",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
            "symbol": "ORCA",
            "name": "Orca",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png",
            "extensions": {
                "coingeckoId": "orca",
                "discord": "https://discord.com/invite/nSwGWn5KSG",
                "medium": "https://orca-so.medium.com",
                "serumV3Usdc": "8N1KkhaCYDpj3awD58d85n973EwkpeYnRp84y1kdZpMX",
                "telegram": "https://t.me/orca_so",
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "guppyrZyEX9iTPSu92pi8T71Zka7xd6PrsTJrXRW6u1",
            "symbol": "GUPPY",
            "name": "Orca Guppy Collectible",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/guppyrZyEX9iTPSu92pi8T71Zka7xd6PrsTJrXRW6u1/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "whaLeHav12EhGK19u6kKbLRwC9E1EATGnm6MWbBCcUW",
            "symbol": "WHALE",
            "name": "Orca Whale Collectible",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/whaLeHav12EhGK19u6kKbLRwC9E1EATGnm6MWbBCcUW/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "kLwhLkZRt6CadPHRBsgfhRCKXX426WMBnhoGozTduvk",
            "symbol": "KILLER-WHALE",
            "name": "Orca Killer Whale Collectible",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kLwhLkZRt6CadPHRBsgfhRCKXX426WMBnhoGozTduvk/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "star2pH7rVWscs743JGdCAL8Lc9nyJeqx7YQXkGUnWf",
            "symbol": "STARFISH",
            "name": "Orca Starfish Collectible",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/star2pH7rVWscs743JGdCAL8Lc9nyJeqx7YQXkGUnWf/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "cLownTTaiiQMoyMmFjfmSGowi8HyNhCtTLFcrNKnqX6",
            "symbol": "CLOWNFISH",
            "name": "Orca Clownfish Collectible",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cLownTTaiiQMoyMmFjfmSGowi8HyNhCtTLFcrNKnqX6/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "porpKs9ZZERXKkg55f1GRXCiXZK89Uz6VKS8Bv9qWqM",
            "symbol": "PORPOISE",
            "name": "Orca Porpoise Collectible",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/porpKs9ZZERXKkg55f1GRXCiXZK89Uz6VKS8Bv9qWqM/logo.svg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "ha11o7FUziqRqpWLSnHoAnNjpeMYg6S3sSd7hfbqLyk",
            "symbol": "HALLOWHALE",
            "name": "Orca HALLOWHALE Collectible",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ha11o7FUziqRqpWLSnHoAnNjpeMYg6S3sSd7hfbqLyk/logo.svg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "D9pXQfzq8MnhLGP8eo9BhuEmx2vSCaQg9ivkwNVHeCFc",
            "symbol": "LITTLENFTS",
            "name": "Jon Little NFTs",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D9pXQfzq8MnhLGP8eo9BhuEmx2vSCaQg9ivkwNVHeCFc/littlenfts4.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://jonlittle.myportfolio.com"
            }
        },
        {
            "chainId": 101,
            "address": "8kWk6CuCAfaxhWQZvQva6qkB1DkWNHq9LRKKN6n9joUG",
            "symbol": "pSOL/USDC",
            "name": "Orca Aquafarm Token (pSOL/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kWk6CuCAfaxhWQZvQva6qkB1DkWNHq9LRKKN6n9joUG/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7YFfqZGTxkj3Zeq3Et23kMznCaEYZ1WBZDt6CVrxwfqd",
            "symbol": "SOCN/USDC",
            "name": "Orca Aquafarm Token (SOCN/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7YFfqZGTxkj3Zeq3Et23kMznCaEYZ1WBZDt6CVrxwfqd/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "CNqmEKGjZUUARVFHcz4w9CvX5pR8Ae2c6imHDNqsbxgj",
            "symbol": "SOCN/SOL",
            "name": "Orca Aquafarm Token (SOCN/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CNqmEKGjZUUARVFHcz4w9CvX5pR8Ae2c6imHDNqsbxgj/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "Cum6sRPGpWYQHZapekDtMhbZ1BQ2QkYv9PAwQjypxMVo",
            "symbol": "SBR/USDC",
            "name": "Orca Aquafarm Token (SBR/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cum6sRPGpWYQHZapekDtMhbZ1BQ2QkYv9PAwQjypxMVo/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "3RTGL7gPF4V1ns1AeGFApT7cBEGVDfmJ77DqQi9AC6uG",
            "symbol": "mSOL/SOL",
            "name": "Orca Aquafarm Token (mSOL/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3RTGL7gPF4V1ns1AeGFApT7cBEGVDfmJ77DqQi9AC6uG/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "4aEi4A91hRbERJVDYxRWbbSrBrsxoM1Hm33KRoRzWMht",
            "symbol": "ORCA/PAI",
            "name": "Orca Aquafarm Token (ORCA/PAI)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4aEi4A91hRbERJVDYxRWbbSrBrsxoM1Hm33KRoRzWMht/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "3Duk5b6fLztPmS4ryV48FM1Q9WXUSMwz9jehAT4UtqpE",
            "symbol": "ORCA/mSOL",
            "name": "Orca Aquafarm Token (ORCA/mSOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Duk5b6fLztPmS4ryV48FM1Q9WXUSMwz9jehAT4UtqpE/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "ECFcUGwHHMaZynAQpqRHkYeTBnS5GnPWZywM8aggcs3A",
            "symbol": "SOL/USDC",
            "name": "Orca LP Token (SOL/USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ECFcUGwHHMaZynAQpqRHkYeTBnS5GnPWZywM8aggcs3A/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "3H5XKkE9uVvxsdrFeN4BLLGCmohiQN6aZJVVcJiXQ4WC",
            "symbol": "USDC/USDT",
            "name": "Orca LP Token (USDC/USDT)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3H5XKkE9uVvxsdrFeN4BLLGCmohiQN6aZJVVcJiXQ4WC/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "8qNqTaKKbdZuzQPWWXy5wNVkJh54ex8zvvnEnTFkrKMP",
            "symbol": "USDC/USDT-SRM",
            "name": "Orca LP Token (USDC/USDT-SRM)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8qNqTaKKbdZuzQPWWXy5wNVkJh54ex8zvvnEnTFkrKMP/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7TYb32qkwYosUQfUspU45cou7Bb3nefJocVMFX2mEGTT",
            "symbol": "ETH/USDC",
            "name": "Orca LP Token (ETH/USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TYb32qkwYosUQfUspU45cou7Bb3nefJocVMFX2mEGTT/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m",
            "symbol": "META",
            "name": "Metaplex",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m/logo.png",
            "extensions": {
                "github": "https://github.com/metaplex-foundation/metaplex",
                "twitter": "https://twitter.com/metaplex",
                "website": "https://metaplex.com"
            }
        },
        {
            "chainId": 101,
            "address": "BiJyWQr1Gpke3ouevgGCjtd9sSwSiUxdpnpGvJaoGQNL",
            "symbol": "SNG",
            "name": "SolNinja-go",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BiJyWQr1Gpke3ouevgGCjtd9sSwSiUxdpnpGvJaoGQNL/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/2NsduQUuxt",
                "twitter": "https://twitter.com/Solninja_go",
                "website": "https://www.solninja-go.art/"
            }
        },
        {
            "chainId": 102,
            "address": "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m",
            "symbol": "META",
            "name": "Metaplex",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m/logo.png",
            "extensions": {
                "github": "https://github.com/metaplex-foundation/metaplex",
                "twitter": "https://twitter.com/metaplex",
                "website": "https://metaplex.com"
            }
        },
        {
            "chainId": 103,
            "address": "9fvEiugrurd2kzSkqFjhGaUFiZUfP4UWbfprmFEm51M9",
            "symbol": "SHILL",
            "name": "Shill",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9fvEiugrurd2kzSkqFjhGaUFiZUfP4UWbfprmFEm51M9/logo.png",
            "tags": [
                "SPL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd",
            "symbol": "SHILL",
            "name": "Project SEED Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd/logo.png",
            "tags": [
                "projectseedtoken"
            ],
            "extensions": {
                "website": "https://projectseed.io"
            }
        },
        {
            "chainId": 101,
            "address": "Cgx1ZqFW7Mbg9wduJML1WYQ5XKGqY4Di76gYFj1oCPeJ",
            "symbol": "SHILL",
            "name": "Project SEED Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cgx1ZqFW7Mbg9wduJML1WYQ5XKGqY4Di76gYFj1oCPeJ/logo.png",
            "tags": [
                "projectseedtoken"
            ],
            "extensions": {
                "website": "https://projectseed.io"
            }
        },
        {
            "chainId": 101,
            "address": "6b1UGnijVcN6F5fcLnpF6UUkskPonoei4af38ybZrigV",
            "symbol": "SHILL",
            "name": "Project SEED Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6b1UGnijVcN6F5fcLnpF6UUkskPonoei4af38ybZrigV/logo.png",
            "tags": [
                "projectseedtoken"
            ],
            "extensions": {
                "website": "https://projectseed.io"
            }
        },
        {
            "chainId": 103,
            "address": "G3Cb13RiPcTtdKSfZEyhHCpXkgqyTr9BdVvdUbtERHUR",
            "symbol": "SHILL",
            "name": "Project SEED Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G3Cb13RiPcTtdKSfZEyhHCpXkgqyTr9BdVvdUbtERHUR/logo.png",
            "tags": [
                "projectseedtoken"
            ],
            "extensions": {
                "website": "https://projectseed.io"
            }
        },
        {
            "chainId": 103,
            "address": "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m",
            "symbol": "META",
            "name": "Metaplex",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m/logo.png",
            "extensions": {
                "github": "https://github.com/metaplex-foundation/metaplex",
                "twitter": "https://twitter.com/metaplex",
                "website": "https://metaplex.com"
            }
        },
        {
            "chainId": 101,
            "address": "EhBAmhkgEsMa8McFB5bpqZaRpZvGBBJ4jN59T5xToPdG",
            "symbol": "ETH/USDT-SRM",
            "name": "Orca LP Token (ETH/USDT-SRM)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EhBAmhkgEsMa8McFB5bpqZaRpZvGBBJ4jN59T5xToPdG/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "8pFwdcuXM7pvHdEGHLZbUR8nNsjj133iUXWG6CgdRHk2",
            "symbol": "BTC/ETH",
            "name": "Orca LP Token (BTC/ETH)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pFwdcuXM7pvHdEGHLZbUR8nNsjj133iUXWG6CgdRHk2/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7bb88DAnQY7LSoWEuqezCcbk4vutQbuRqgJMqpX8h6dL",
            "symbol": "ETH/SOL",
            "name": "Orca LP Token (ETH/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7bb88DAnQY7LSoWEuqezCcbk4vutQbuRqgJMqpX8h6dL/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "GWEmABT4rD3sGhyghv9rKbfdiaFe5uMHeJqr6hhu3XvA",
            "symbol": "RAY/SOL",
            "name": "Orca LP Token (RAY/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWEmABT4rD3sGhyghv9rKbfdiaFe5uMHeJqr6hhu3XvA/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "BmZNYGt7aApGTUUxAQUYsW64cMbb6P7uniokCWaptj4D",
            "symbol": "SOL/USDT",
            "name": "Orca LP Token (SOL/USDT)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BmZNYGt7aApGTUUxAQUYsW64cMbb6P7uniokCWaptj4D/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "E4cthfUFaDd4x5t1vbeBNBHm7isqhM8kapthPzPJz1M2",
            "symbol": "SOL/USDT-SRM",
            "name": "Orca LP Token (SOL/USDT-SRM)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E4cthfUFaDd4x5t1vbeBNBHm7isqhM8kapthPzPJz1M2/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "6ojPekCSQimAjDjaMApLvh3jF6wnZeNEVRVVoGNzEXvV",
            "symbol": "SOL/SRM",
            "name": "Orca LP Token (SOL/SRM)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ojPekCSQimAjDjaMApLvh3jF6wnZeNEVRVVoGNzEXvV/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "YJRknE9oPhUMtq1VvhjVzG5WnRsjQtLsWg3nbaAwCQ5",
            "symbol": "FTT/SOL",
            "name": "Orca LP Token (FTT/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YJRknE9oPhUMtq1VvhjVzG5WnRsjQtLsWg3nbaAwCQ5/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "C9PKvetJPrrPD53PR2aR8NYtVZzucCRkHYzcFXbZXEqu",
            "symbol": "KIN/SOL",
            "name": "Orca LP Token (KIN/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9PKvetJPrrPD53PR2aR8NYtVZzucCRkHYzcFXbZXEqu/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "6SfhBAmuaGf9p3WAxeHJYCWMABnYUMrdzNdK5Stvvj4k",
            "symbol": "ROPE/SOL",
            "name": "Orca LP Token (ROPE/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6SfhBAmuaGf9p3WAxeHJYCWMABnYUMrdzNdK5Stvvj4k/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "9r1n79TmerAgQJboUT8QvrChX3buZBfuSrBTtYM1cW4h",
            "symbol": "SOL/STEP",
            "name": "Orca LP Token (SOL/STEP)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9r1n79TmerAgQJboUT8QvrChX3buZBfuSrBTtYM1cW4h/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "ELLELFtgvWBgLkdY9EFx4Vb3SLNj4DJEhzZLWy1wCh4Y",
            "symbol": "OXY/SOL",
            "name": "Orca LP Token (OXY/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELLELFtgvWBgLkdY9EFx4Vb3SLNj4DJEhzZLWy1wCh4Y/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "BXM9ph4AuhCUzf94HQu5FnfeVThKj5oyrnb1krY1zax5",
            "symbol": "MER/SOL",
            "name": "Orca LP Token (MER/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXM9ph4AuhCUzf94HQu5FnfeVThKj5oyrnb1krY1zax5/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "FJ9Q9ojA7vdf5rFbcTc6dd7D3nLpwSxdtFSE8cwfuvqt",
            "symbol": "FIDA/SOL",
            "name": "Orca LP Token (FIDA/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FJ9Q9ojA7vdf5rFbcTc6dd7D3nLpwSxdtFSE8cwfuvqt/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "EHkfnhKLLTUqo1xMZLxhM9EusEgpN6RXPpZsGpUsewaa",
            "symbol": "MAPS/SOL",
            "name": "Orca LP Token (MAPS/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EHkfnhKLLTUqo1xMZLxhM9EusEgpN6RXPpZsGpUsewaa/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "9rguDaKqTrVjaDXafq6E7rKGn7NPHomkdb8RKpjKCDm2",
            "symbol": "SAMO/SOL",
            "name": "Orca LP Token (SAMO/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9rguDaKqTrVjaDXafq6E7rKGn7NPHomkdb8RKpjKCDm2/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "2697FyJ4vD9zwAVPr33fdVPDv54pyZZiBv9S2AoKMyQf",
            "symbol": "COPE/SOL",
            "name": "Orca LP Token (COPE/SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2697FyJ4vD9zwAVPr33fdVPDv54pyZZiBv9S2AoKMyQf/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "57vGdcMZLnbNr4TZ4hgrpGJZGR9vTPhu8L9bNKDrqxKT",
            "symbol": "LIQ/USDC",
            "name": "Orca Aquafarm Token (LIQ/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/57vGdcMZLnbNr4TZ4hgrpGJZGR9vTPhu8L9bNKDrqxKT/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "FFdjrSvNALfdgxANNpt3x85WpeVMdQSH5SEP2poM8fcK",
            "symbol": "SOL/USDC",
            "name": "Orca Aquafarm Token (SOL/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFdjrSvNALfdgxANNpt3x85WpeVMdQSH5SEP2poM8fcK/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "71vZ7Jvu8fTyFzpX399dmoSovoz24rVbipLrRn2wBNzW",
            "symbol": "SOL/USDT",
            "name": "Orca Aquafarm Token (SOL/USDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/71vZ7Jvu8fTyFzpX399dmoSovoz24rVbipLrRn2wBNzW/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "CGFTRh4jKLPbS9r4hZtbDfaRuC7qcA8rZpbLnVTzJBer",
            "symbol": "ETH/SOL",
            "name": "Orca Aquafarm Token (ETH/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CGFTRh4jKLPbS9r4hZtbDfaRuC7qcA8rZpbLnVTzJBer/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "HDP2AYFmvLz6sWpoSuNS62JjvW4HjMKp7doXucqpWN56",
            "symbol": "ETH/USDC",
            "name": "Orca Aquafarm Token (ETH/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDP2AYFmvLz6sWpoSuNS62JjvW4HjMKp7doXucqpWN56/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "AUkn5f4N4TqPA5BiWirTDHWnG3SePfmeDpDqrFmhSgKb",
            "symbol": "RAY/SOL",
            "name": "Orca Aquafarm Token (RAY/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AUkn5f4N4TqPA5BiWirTDHWnG3SePfmeDpDqrFmhSgKb/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "xpPyQwQ1HXHyEpvFGyTQRLY6rmj6jtAdEgLMV5uoz4m",
            "symbol": "ROPE/SOL",
            "name": "Orca Aquafarm Token (ROPE/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xpPyQwQ1HXHyEpvFGyTQRLY6rmj6jtAdEgLMV5uoz4m/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "GwrBA1F8rGummDCDd8NY9Eu1cLNuJqbT8WaGxgWpFwGL",
            "symbol": "STEP/SOL",
            "name": "Orca Aquafarm Token (STEP/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GwrBA1F8rGummDCDd8NY9Eu1cLNuJqbT8WaGxgWpFwGL/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "D659zwnbeTgquChbaWC3KDHrkYoqMuz1doGLHTFaqTtD",
            "symbol": "SRM/SOL",
            "name": "Orca Aquafarm Token (SRM/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D659zwnbeTgquChbaWC3KDHrkYoqMuz1doGLHTFaqTtD/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "9r9BcPwCon96P5Y6JSdRAog7Uknz9p9GrnuHm4VzuB9k",
            "symbol": "FTT/SOL",
            "name": "Orca Aquafarm Token (FTT/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9r9BcPwCon96P5Y6JSdRAog7Uknz9p9GrnuHm4VzuB9k/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7CT19h7n2YBKiCFCaxXqMM79jNM4cmUvjXhNMjJNRYa",
            "symbol": "COPE/SOL",
            "name": "Orca Aquafarm Token (COPE/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7CT19h7n2YBKiCFCaxXqMM79jNM4cmUvjXhNMjJNRYa/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "G48RkwsNYd3A4rBfuQhCswr9YUE63fFmZGyhgH95dq3S",
            "symbol": "OXY/SOL",
            "name": "Orca Aquafarm Token (OXY/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G48RkwsNYd3A4rBfuQhCswr9YUE63fFmZGyhgH95dq3S/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "GxmjQZvgwNCh3QSRNB8CPED81hzySem62PDDuMp4B379",
            "symbol": "BTC/SOL",
            "name": "Orca Aquafarm Token (BTC/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GxmjQZvgwNCh3QSRNB8CPED81hzySem62PDDuMp4B379/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "CrKVRnH6iGbFXxEnXMn3Emwv3Fe7VwxEqpA8zNbwsgkH",
            "symbol": "MER/SOL",
            "name": "Orca Aquafarm Token (MER/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CrKVRnH6iGbFXxEnXMn3Emwv3Fe7VwxEqpA8zNbwsgkH/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "4geGcEfgVjzJGZAaT8iTicPm1XLDPjdSpVhtA99sZ7jX",
            "symbol": "FIDA/SOL",
            "name": "Orca Aquafarm Token (FIDA/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4geGcEfgVjzJGZAaT8iTicPm1XLDPjdSpVhtA99sZ7jX/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7Dy84zJNHzEM9335BrtFjCuunt2VgxJ7KBT6PJarxKMq",
            "symbol": "MAPS/SOL",
            "name": "Orca Aquafarm Token (MAPS/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Dy84zJNHzEM9335BrtFjCuunt2VgxJ7KBT6PJarxKMq/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "GjpXgKwn4VW4J2pZdS3dovM58hiXWLJtopTfqG83zY2f",
            "symbol": "USDC/USDT[stable]",
            "name": "Orca Aquafarm Token (USDC/USDT[stable])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GjpXgKwn4VW4J2pZdS3dovM58hiXWLJtopTfqG83zY2f/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "B5waaKnsmtqFawPspUwcuy1cRjAC7u2LrHSwxPSxK4sZ",
            "symbol": "ORCA/SOL",
            "name": "Orca Aquafarm Token (ORCA/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B5waaKnsmtqFawPspUwcuy1cRjAC7u2LrHSwxPSxK4sZ/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "Gc7W5U66iuHQcC1cQyeX9hxkPF2QUVJPTf1NWbW8fNrt",
            "symbol": "ORCA/USDC",
            "name": "Orca Aquafarm Token (ORCA/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gc7W5U66iuHQcC1cQyeX9hxkPF2QUVJPTf1NWbW8fNrt/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7Ho3ht7krdFELBcPAsGXFfQMyG4PUvYSfpz4aNBRP3Ek",
            "symbol": "KIN/SOL",
            "name": "Orca Aquafarm Token (KIN/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Ho3ht7krdFELBcPAsGXFfQMyG4PUvYSfpz4aNBRP3Ek/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "CNf8gZtLahBWxKe3YwsqywLHMTewGqvq6pJ5ecg3cTYU",
            "symbol": "SAMO/SOL",
            "name": "Orca Aquafarm Token (SAMO/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CNf8gZtLahBWxKe3YwsqywLHMTewGqvq6pJ5ecg3cTYU/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "6Qw5Gzf1TkM3YRe7Dh6yMVMo2wnJxRiCUBP8abTTn9Yg",
            "symbol": "SNY/USDC",
            "name": "Orca Aquafarm Token (SNY/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Qw5Gzf1TkM3YRe7Dh6yMVMo2wnJxRiCUBP8abTTn9Yg/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "5r3vDsNTGXXb9cGQfqyNuYD2bjhRPymGJBfDmKosR9Ev",
            "symbol": "mSOL/USDC",
            "name": "Orca Aquafarm Token (mSOL/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5r3vDsNTGXXb9cGQfqyNuYD2bjhRPymGJBfDmKosR9Ev/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "66xCxkffQZKBZLiHV3PDcfR8ANJTfnDRxPCaBdv4wxB7",
            "symbol": "SLRS/USDC",
            "name": "Orca Aquafarm Token (SLRS/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/66xCxkffQZKBZLiHV3PDcfR8ANJTfnDRxPCaBdv4wxB7/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "4CGxvZdwiZgVMLXiTdJHTkJRUTpTSJCtmtCRbSkAxerE",
            "symbol": "PORT/USDC",
            "name": "Orca Aquafarm Token (PORT/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4CGxvZdwiZgVMLXiTdJHTkJRUTpTSJCtmtCRbSkAxerE/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7YyhptkxY81HPzFVfyCzA5UXxWdsNRD41ofLva3TuSpd",
            "symbol": "NINJA/SOL",
            "name": "Orca Aquafarm Token (NINJA/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "Db7mPGrZbswvFmJ7MgZsM6CFhnXHMnrUDqr2hrzmi7Re",
            "symbol": "NINJA/SOL",
            "name": "Orca Double Dip Token (NINJA/SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "HFmY1ggCsCky1zJ1sfdkNR4zb3u5n38YNRdf4vsGu17t",
            "symbol": "ATLAS/USDC",
            "name": "Orca Aquafarm Token (ATLAS/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HFmY1ggCsCky1zJ1sfdkNR4zb3u5n38YNRdf4vsGu17t/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "63JUKLnCAuNMPSPioEgbjjzp9Qk8qSEEM8eZqEtPqfLU",
            "symbol": "POLIS/USDC",
            "name": "Orca Aquafarm Token (POLIS/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/63JUKLnCAuNMPSPioEgbjjzp9Qk8qSEEM8eZqEtPqfLU/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "A7vvbqENJj8kED3ABjphe8TvwpasQYtoWGKpjpLArMxa",
            "symbol": "BOP/USDC",
            "name": "Orca Aquafarm Token (BOP/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A7vvbqENJj8kED3ABjphe8TvwpasQYtoWGKpjpLArMxa/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "9voVuTq1S9bFZkF2Jo44HoVG63w2xDRT8eBzB23YbQud",
            "symbol": "SAMO/USDC",
            "name": "Orca Aquafarm Token (SAMO/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9voVuTq1S9bFZkF2Jo44HoVG63w2xDRT8eBzB23YbQud/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "3K7aZhtwWJ2JS6GnbbgeDVnxd1q2hwhqasmgRsAMZ4yC",
            "symbol": "SLIM/USDC",
            "name": "Orca Aquafarm Token (SLIM/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3K7aZhtwWJ2JS6GnbbgeDVnxd1q2hwhqasmgRsAMZ4yC/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "Bfoi3RNnfdP5VeRGqvTA8MRN9ePGJoZgeKfe8WeBHUxE",
            "symbol": "wHAPI/USDC",
            "name": "Orca Aquafarm Token (wHAPI/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bfoi3RNnfdP5VeRGqvTA8MRN9ePGJoZgeKfe8WeBHUxE/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "9SDpBrfqNxjXcCzpKWM6yUKdfky975VJBD6xcu5cKf5s",
            "symbol": "COPE/USDC",
            "name": "Orca Aquafarm Token (COPE/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9SDpBrfqNxjXcCzpKWM6yUKdfky975VJBD6xcu5cKf5s/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "9HPn1oREyNA7CEK7B1xwmBmVH6qtQaSfLBXc1JyRsdUE",
            "symbol": "SUNNY/USDC",
            "name": "Orca Aquafarm Token (SUNNY/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9HPn1oREyNA7CEK7B1xwmBmVH6qtQaSfLBXc1JyRsdUE/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "97q89hnoKwqcynvwXcj83YqfqUBuCm4A8f2zHeV6bfZg",
            "symbol": "GRAPE/USDC",
            "name": "Orca Aquafarm Token (GRAPE/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97q89hnoKwqcynvwXcj83YqfqUBuCm4A8f2zHeV6bfZg/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "5uR5STASUmoGVHzqMeut98t26TfVkQqWU9f9dsv3NfJ6",
            "symbol": "ABR/USDC",
            "name": "Orca Aquafarm Token (ABR/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5uR5STASUmoGVHzqMeut98t26TfVkQqWU9f9dsv3NfJ6/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "6PGoaQdL9e463hdaFxHXsuPcjCHRK32CQ9PFKxvM7XY2",
            "symbol": "KURO/USDC",
            "name": "Orca Aquafarm Token (KURO/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6PGoaQdL9e463hdaFxHXsuPcjCHRK32CQ9PFKxvM7XY2/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "3pMYToENTB7jKrJiUPq19FCZCWE35Ph7bkRRMN6kxDXK",
            "symbol": "MEDIA/USDC",
            "name": "Orca Aquafarm Token (MEDIA/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3pMYToENTB7jKrJiUPq19FCZCWE35Ph7bkRRMN6kxDXK/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "2KYUwdRbVtaMUgHp1a6NuTomyCb33FxoZ4fkeVdwjaJA",
            "symbol": "TULIP/USDC",
            "name": "Orca Aquafarm Token (TULIP/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2KYUwdRbVtaMUgHp1a6NuTomyCb33FxoZ4fkeVdwjaJA/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "CxhcLZtbhfkwjAZ956SEkGxkAvMVQH3hfKTjKpgTV9Q5",
            "symbol": "MNGO/USDC",
            "name": "Orca Aquafarm Token (MNGO/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CxhcLZtbhfkwjAZ956SEkGxkAvMVQH3hfKTjKpgTV9Q5/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "3kT3oYuS1rCfhmqfgy6EKcbZdaJimaVEjoy25QiuEaoj",
            "symbol": "stSOL/wstETH",
            "name": "Orca Aquafarm Token (stSOL/wstETH[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3kT3oYuS1rCfhmqfgy6EKcbZdaJimaVEjoy25QiuEaoj/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "Ds4VGZhZzS2PMFzhzKeC3mwcQjdiCG21R76fTVbsSJyJ",
            "symbol": "SYP/USDC",
            "name": "Orca Aquafarm Token (SYP/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ds4VGZhZzS2PMFzhzKeC3mwcQjdiCG21R76fTVbsSJyJ/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "DQsbebdNDy8yQrwLTpieckhzi7Ewx9LoCPVf7G9KvY2U",
            "symbol": "stSOL/wLDO",
            "name": "Orca Aquafarm Token (stSOL/wLDO[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DQsbebdNDy8yQrwLTpieckhzi7Ewx9LoCPVf7G9KvY2U/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "FkHQBBZGh5GS4GcXpcVksKYUUkLTNn6Yk1PCMxucR2AK",
            "symbol": "whETH/SOL",
            "name": "Orca Aquafarm Token (whETH/SOL[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FkHQBBZGh5GS4GcXpcVksKYUUkLTNn6Yk1PCMxucR2AK/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "B11Xp26xU2gzjToJEuGswvr6Jtidfh4GRUyCWzWMNdQZ",
            "symbol": "whETH/USDC",
            "name": "Orca Aquafarm Token (whETH/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B11Xp26xU2gzjToJEuGswvr6Jtidfh4GRUyCWzWMNdQZ/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "2wPsMuzhEsC6GhV3qtFpmJF6atEgLGbnmQ8U43Y6fPxZ",
            "symbol": "MNDE/mSOL",
            "name": "Orca Aquafarm Token (MNDE/mSOL[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wPsMuzhEsC6GhV3qtFpmJF6atEgLGbnmQ8U43Y6fPxZ/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "8Wu5sJpERA1J5iWcT8aMpt9cTAfKDLPbLpGjNsJoPgLc",
            "symbol": "WAG/USDC",
            "name": "Orca Aquafarm Token (WAG/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Wu5sJpERA1J5iWcT8aMpt9cTAfKDLPbLpGjNsJoPgLc/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "Afvh7TWfcT1E9eEEWJk17fPjnqk36hreTJJK5g3s4fm8",
            "symbol": "mSOL/USDT",
            "name": "Orca Aquafarm Token (mSOL/USDT[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Afvh7TWfcT1E9eEEWJk17fPjnqk36hreTJJK5g3s4fm8/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "58nifjPjF3CutGz2xMxvAMk7R9YgbVEc8Cstj4rCcs8j",
            "symbol": "mSOL/whETH",
            "name": "Orca Aquafarm Token (mSOL/whETH[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58nifjPjF3CutGz2xMxvAMk7R9YgbVEc8Cstj4rCcs8j/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "DzpLz78wuwyFsQToin8iDv6YK6aBEymRqQq82swiFh7r",
            "symbol": "BTC/mSOL",
            "name": "Orca Aquafarm Token (BTC/mSOL[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DzpLz78wuwyFsQToin8iDv6YK6aBEymRqQq82swiFh7r/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "5rGtJDiJhD5Mx2fvdEYuLrCiWaMD9z3wpmJSxwGHmo4u",
            "symbol": "LIQ/USDC",
            "name": "Orca Double Dip Token (LIQ/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5rGtJDiJhD5Mx2fvdEYuLrCiWaMD9z3wpmJSxwGHmo4u/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "Gs1fM7EFS1rXkxhqs4mwu9uvSkupNzZgRbHGxG2NGRh7",
            "symbol": "STEP/SOL",
            "name": "Orca Double Dip Token (STEP/SOL[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gs1fM7EFS1rXkxhqs4mwu9uvSkupNzZgRbHGxG2NGRh7/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "F3rWkGAtdjWcU1rr16Wq4YPTgFdsyb1oS1xdy5tr9K1r",
            "symbol": "SLRS/USDC",
            "name": "Orca Double Dip Token (SLRS/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F3rWkGAtdjWcU1rr16Wq4YPTgFdsyb1oS1xdy5tr9K1r/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "Zm2dmUuuBicmvHxGAnAzaohZR2Y86gXEV2WMfo8AoCa",
            "symbol": "PORT/USDC",
            "name": "Orca Double Dip Token (PORT/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Zm2dmUuuBicmvHxGAnAzaohZR2Y86gXEV2WMfo8AoCa/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "AtcMEt9caZxpunQV99pxED2rhpQmaDykBreEqBsYU11v",
            "symbol": "COPE/USDC",
            "name": "Orca Double Dip Token (COPE/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AtcMEt9caZxpunQV99pxED2rhpQmaDykBreEqBsYU11v/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "CjGUbKiH1QmFFjMqhAbJn4DrbjgBWUhQHV4LuzrgpFqi",
            "symbol": "BOP/USDC",
            "name": "Orca Double Dip Token (BOP/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CjGUbKiH1QmFFjMqhAbJn4DrbjgBWUhQHV4LuzrgpFqi/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "EdfAy8jwnvU1z61UaFUjwoRPFgD3UkkPvnhEBZjzwhv8",
            "symbol": "SAMO/USDC",
            "name": "Orca Double Dip Token (SAMO/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdfAy8jwnvU1z61UaFUjwoRPFgD3UkkPvnhEBZjzwhv8/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "41VBoy8SGJzQnWGcxiBL4yM6H68FiPp74aMvsZGNGCbt",
            "symbol": "wHAPI/USDC",
            "name": "Orca Double Dip Token (wHAPI/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/41VBoy8SGJzQnWGcxiBL4yM6H68FiPp74aMvsZGNGCbt/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "HhDk3ySWkVbMZjgBsFSnLtAeudDCrfZ6DNSRgxh2oRUp",
            "symbol": "SLIM/USDC",
            "name": "Orca Double Dip Token (SLIM/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HhDk3ySWkVbMZjgBsFSnLtAeudDCrfZ6DNSRgxh2oRUp/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "894ptAFT7d3inPsWTniCGL2NZpJDiXGvFZFfuHXA1w8F",
            "symbol": "ATLAS/USDC",
            "name": "Orca Double Dip Token (ATLAS/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/894ptAFT7d3inPsWTniCGL2NZpJDiXGvFZFfuHXA1w8F/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "FE1QJzi5RA5aKnTfSV3DAMN3z4uHUzSR5Z4drs9S5vB",
            "symbol": "POLIS/USDC",
            "name": "Orca Double Dip Token (POLIS/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FE1QJzi5RA5aKnTfSV3DAMN3z4uHUzSR5Z4drs9S5vB/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7bp7psdaC3DVc86Hmdz5tAMEjgPjmCzgFEVALfqBwMmz",
            "symbol": "ABR/USDC",
            "name": "Orca Double Dip Token (ABR/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7bp7psdaC3DVc86Hmdz5tAMEjgPjmCzgFEVALfqBwMmz/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "88RCQs9VFvqPjsRe3PKNzBeMtzCS9oS1a1CJuAnGnLZJ",
            "symbol": "KURO/USDC",
            "name": "Orca Double Dip Token (KURO/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88RCQs9VFvqPjsRe3PKNzBeMtzCS9oS1a1CJuAnGnLZJ/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "9y3QYM5mcaB8tU7oXRzAQnzHVa75P8riDuPievLp64cY",
            "symbol": "mSOL/USDC",
            "name": "Orca Double Dip Token (mSOL/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9y3QYM5mcaB8tU7oXRzAQnzHVa75P8riDuPievLp64cY/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "876yhw4J4GHyynNJUtARYEnWGaejhrWC7Hy3DAm1pZxi",
            "symbol": "ORCA/mSOL",
            "name": "Orca Double Dip Token (ORCA/mSOL[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/876yhw4J4GHyynNJUtARYEnWGaejhrWC7Hy3DAm1pZxi/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "576ABEdvLG1iFU3bLC8AMJ3mo5LhfgPPhMtTeVAGG6u7",
            "symbol": "mSOL/SOL[stable]",
            "name": "Orca Double Dip Token (mSOL/SOL[stable][aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/576ABEdvLG1iFU3bLC8AMJ3mo5LhfgPPhMtTeVAGG6u7/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "5WXyG6zL1HmESPCSHHKBtqLuRPZCNgd9mTB25op87FkU",
            "symbol": "stSOL/wstETH",
            "name": "Orca Double Dip Token (stSOL/wstETH[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WXyG6zL1HmESPCSHHKBtqLuRPZCNgd9mTB25op87FkU/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "BpHfwFwJwkZKWY5xVMC3oifMvWRy42R4VE1vPeBzg2G1",
            "symbol": "SYP/USDC",
            "name": "Orca Double Dip Token (SYP/USDC[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BpHfwFwJwkZKWY5xVMC3oifMvWRy42R4VE1vPeBzg2G1/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "2FMpVEhvxiFxhfideFUMNxCoUZK3TfhezzajoHGTQKP2",
            "symbol": "MNDE/mSOL",
            "name": "Orca Double Dip Token (MNDE/mSOL[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FMpVEhvxiFxhfideFUMNxCoUZK3TfhezzajoHGTQKP2/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "7iKG16aukdXXw43MowbfrGqXhAoYe51iVR9u2Nf2dCEY",
            "symbol": "mSOL/USDT",
            "name": "Orca Double Dip Token (mSOL/USDT[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7iKG16aukdXXw43MowbfrGqXhAoYe51iVR9u2Nf2dCEY/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "3kFeVJUxhQS7PE7vV8pt9bhTCQrUDqeGf6AU4sjkLzVt",
            "symbol": "mSOL/whETH",
            "name": "Orca Double Dip Token (mSOL/whETH[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3kFeVJUxhQS7PE7vV8pt9bhTCQrUDqeGf6AU4sjkLzVt/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "6uA1ADUJbvwYJZpzUn9z9LuyKoRVngBKcQTKdXsSivA8",
            "symbol": "BTC/mSOL",
            "name": "Orca Double Dip Token (BTC/mSOL[aquafarm])",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uA1ADUJbvwYJZpzUn9z9LuyKoRVngBKcQTKdXsSivA8/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orca_so",
                "website": "https://www.orca.so"
            }
        },
        {
            "chainId": 101,
            "address": "E52bRrLGu1YFHBLNTWhdeGoYKyp1UYCTjB7XPoFgapYS",
            "symbol": "SAPN",
            "name": "Sapling Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E52bRrLGu1YFHBLNTWhdeGoYKyp1UYCTjB7XPoFgapYS/logo.png",
            "tags": [
                "sapling",
                "ecofriendly-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xvCFjyF5wS",
                "github": "https://github.com/SaplingNetwork",
                "instagram": "https://instagram.com/saplingnetwork",
                "telegram": "https://t.me/saplingnetwork",
                "twitter": "https://twitter.com/Sapling_Network",
                "website": "http://sapling.network"
            }
        },
        {
            "chainId": 101,
            "address": "HEhMLvpSdPviukafKwVN8BnBUTamirptsQ6Wxo5Cyv8s",
            "symbol": "FTR",
            "name": "Future",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEhMLvpSdPviukafKwVN8BnBUTamirptsQ6Wxo5Cyv8s/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/ftr_finance",
                "website": "https://future-ftr.io"
            }
        },
        {
            "chainId": 101,
            "address": "6oJ8Mp1VnKxN5MvGf9LfpeaRvTv8N1xFbvtdEbLLWUDT",
            "symbol": "ESC",
            "name": "ESCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6oJ8Mp1VnKxN5MvGf9LfpeaRvTv8N1xFbvtdEbLLWUDT/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/coin_esc",
                "website": "https://escoin.company/"
            }
        },
        {
            "chainId": 101,
            "address": "Da1jboBKU3rqXUqPL3L3BxJ8e67ogVgVKcqy4rWsS7LC",
            "symbol": "UBE",
            "name": "UBE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Da1jboBKU3rqXUqPL3L3BxJ8e67ogVgVKcqy4rWsS7LC/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/ube_token",
                "website": "https://www.ubetoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5",
            "symbol": "renBTC",
            "name": "renBTC",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5/logo.png",
            "extensions": {
                "coingeckoId": "renbtc",
                "serumV3Usdc": "74Ciu5yRzhe8TFTHvQuEVbFZJrbnCMRoohBK33NNiPtv",
                "website": "https://renproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "G1a6jxYz3m8DVyMqYnuV7s86wD4fvuXYneWSpLJkmsXj",
            "symbol": "renBCH",
            "name": "renBCH",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G1a6jxYz3m8DVyMqYnuV7s86wD4fvuXYneWSpLJkmsXj/logo.png",
            "extensions": {
                "coingeckoId": "renbch",
                "serumV3Usdc": "FS8EtiNZCH72pAK83YxqXaGAgk3KKFYphiTcYA2yRPis",
                "website": "https://renproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FKJvvVJ242tX7zFtzTmzqoA631LqHh4CdgcN8dcfFSju",
            "symbol": "renDGB",
            "name": "renDGB",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FKJvvVJ242tX7zFtzTmzqoA631LqHh4CdgcN8dcfFSju/logo.png",
            "extensions": {
                "website": "https://renproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ArUkYE2XDKzqy77PRRGjo4wREWwqk6RXTfM9NeqzPvjU",
            "symbol": "renDOGE",
            "name": "renDOGE",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArUkYE2XDKzqy77PRRGjo4wREWwqk6RXTfM9NeqzPvjU/logo.png",
            "extensions": {
                "coingeckoId": "rendoge",
                "serumV3Usdc": "5FpKCWYXgHWZ9CdDMHjwxAfqxJLdw2PRXuAmtECkzADk",
                "website": "https://renproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8wv2KAykQstNAj2oW6AHANGBiFKVFhvMiyyzzjhkmGvE",
            "symbol": "renLUNA",
            "name": "renLUNA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8wv2KAykQstNAj2oW6AHANGBiFKVFhvMiyyzzjhkmGvE/logo.png",
            "extensions": {
                "serumV3Usdc": "CxDhLbbM9uAA2AXfSPar5qmyfmC69NLj3vgJXYAsSVBT",
                "website": "https://renproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "E99CQ2gFMmbiyK2bwiaFNWUUmwz4r8k2CVEFxwuvQ7ue",
            "symbol": "renZEC",
            "name": "renZEC",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E99CQ2gFMmbiyK2bwiaFNWUUmwz4r8k2CVEFxwuvQ7ue/logo.png",
            "extensions": {
                "coingeckoId": "renzec",
                "serumV3Usdc": "2ahbUT5UryyRVxPnELtTmDLLneN26UjBQFgfMVvbWDTb",
                "website": "https://renproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GkXP719hnhLtizWHcQyGVYajuJqVsJJ6fyeUob9BPCFC",
            "symbol": "KROWZ",
            "name": "Mike Krow's Official Best Friend Super Kawaii Kasu Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkXP719hnhLtizWHcQyGVYajuJqVsJJ6fyeUob9BPCFC/logo.png",
            "tags": [
                "social-token",
                "krowz"
            ],
            "extensions": {
                "twitter": "https://twitter.com/space_asylum",
                "website": "https://mikekrow.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y",
            "symbol": "SAIL",
            "name": "SAIL",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "sail",
                "serumV3Usdc": "6hwK66FfUdyhncdQVxWFPRqY8y6usEvzekUaqtpKEKLr",
                "twitter": "https://twitter.com/SolanaSail",
                "website": "https://www.solanasail.com"
            }
        },
        {
            "chainId": 101,
            "address": "E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp",
            "symbol": "RIN",
            "name": "Aldrin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp/logo.png",
            "extensions": {
                "coingeckoId": "aldrin",
                "serumV3Usdc": "7gZNLDbWE73ueAoHuAeFoSu7JqmorwCLpNTBXHtYSFTa",
                "twitter": "https://twitter.com/Aldrin_Exchange",
                "website": "https://rin.aldrin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7LmGzEgnQZTxxeCThgxsv3xe4JQmiy9hxEGBPCF66KgH",
            "symbol": "SNEK",
            "name": "Snek Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7LmGzEgnQZTxxeCThgxsv3xe4JQmiy9hxEGBPCF66KgH/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/snekcoin"
            }
        },
        {
            "chainId": 101,
            "address": "ss26ybWnrhSYbGBjDT9bEwRiyAVUgiKCbgAfFkksj4R",
            "symbol": "SS2",
            "name": "POH",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ss26ybWnrhSYbGBjDT9bEwRiyAVUgiKCbgAfFkksj4R/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://vww4cphi4lv3ldd4dtidi4njkbilvngmvuaofo3rv2oa3ozepeea.arweave.net/ra3BPOji67WMfBzQNHGpUFC6tMytAOK7ca6cDbskeQg?ext=glb",
                "description": "Sensing a disturbance in the timeline, the tournament organizers send Poh back in time to the beginning of the tournament. He is tasked with finding the origin of the disturbance and restoring the original timeline. Special:'Out of Order'",
                "imageUrl": "https://www.arweave.net/fDxzEtzfu9IjFDh0ID-rOGaGw__F6-OD2ADoa23sayo?ext=gif",
                "twitter": "https://twitter.com/sol__survivor",
                "website": "https://www.sol-talk.com/sol-survivor"
            }
        },
        {
            "chainId": 101,
            "address": "6dGR9kAt499jzsojDHCvDArKxpTarNbhdSkiS7jeMAib",
            "symbol": "AKI",
            "name": "AKIHIGE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6dGR9kAt499jzsojDHCvDArKxpTarNbhdSkiS7jeMAib/logo.png",
            "tags": [
                "aki"
            ]
        },
        {
            "chainId": 101,
            "address": "SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f",
            "symbol": "SCY",
            "name": "Synchrony",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f/logo.png",
            "extensions": {
                "serumV3Usdc": "DR8V2wUCSFKCGjML6AZvUB2eYWDPVQ5xju3DeXGgpSaB",
                "twitter": "https://twitter.com/SynchronyFi",
                "website": "https://synchrony.fi"
            }
        },
        {
            "chainId": 101,
            "address": "BKMWPkPS8jXw59ezYwK2ueNTZRF4m8MYHDjh9HwUmkQ7",
            "symbol": "SDC",
            "name": "SandDollarClassic",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKMWPkPS8jXw59ezYwK2ueNTZRF4m8MYHDjh9HwUmkQ7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SandDollar_BS",
                "website": "https://sanddollar.bs"
            }
        },
        {
            "chainId": 101,
            "address": "Bx4ykEMurwPQBAFNvthGj73fMBVTvHa8e9cbAyaK4ZSh",
            "symbol": "TOX",
            "name": "trollbox",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bx4ykEMurwPQBAFNvthGj73fMBVTvHa8e9cbAyaK4ZSh/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/trollboxio",
                "website": "https://trollbox.io"
            }
        },
        {
            "chainId": 101,
            "address": "E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT",
            "symbol": "PPUG",
            "name": "PizzaPugCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/pizzapugcoin",
                "website": "https://www.pizzapugcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb",
            "symbol": "VCC",
            "name": "VentureCapital",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb/logo.svg",
            "tags": [
                "liquidator",
                "IDO",
                "incubator"
            ],
            "extensions": {
                "twitter": "https://twitter.com/vcc_finance",
                "website": "https://www.vcc.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx",
            "symbol": "OXS",
            "name": "Oxbull Sol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "oxbull-solana",
                "github": "https://github.com/OxBull",
                "medium": "https://medium.com/@oxbull",
                "telegramAnnouncements": "https://t.me/Oxbull_tech",
                "twitter": "https://twitter.com/OxBull5",
                "website": "https://www.oxbull.tech"
            }
        },
        {
            "chainId": 101,
            "address": "EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96",
            "symbol": "FAB",
            "name": "FABRIC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96/logo.png",
            "extensions": {
                "coingeckoId": "fabric",
                "serumV3Usdc": "Cud48DK2qoxsWNzQeTL5D8sAiHsGwG8Ev1VMNcYLayxt",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB",
            "symbol": "POTATO",
            "name": "POTATO",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB/logo.png",
            "extensions": {
                "serumV3Usdc": "6dn7tgTHe5rZEAscMWWY3xmPGVEKVkM9s7YRV11z399z",
                "website": "https://potatocoinspl.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FmJ1fo7wK5FF6rDvQxow5Gj7A2ctLmR5orCKLZ45Q3Cq",
            "symbol": "DGEN",
            "name": "Degen Banana",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FmJ1fo7wK5FF6rDvQxow5Gj7A2ctLmR5orCKLZ45Q3Cq/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/degenbanana",
                "website": "https://degen.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "FciGvHj9FjgSGgCBF1b9HY814FM9D28NijDd5SJrKvPo",
            "symbol": "TGT",
            "name": "Twirl Governance Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FciGvHj9FjgSGgCBF1b9HY814FM9D28NijDd5SJrKvPo/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/twirlfinance",
                "website": "https://twirlfinance.com/"
            }
        },
        {
            "chainId": 101,
            "address": "A9EEvcRcT7Q9XAa6NfqrqJChoc4XGDhd2mtc4xfniQkS",
            "symbol": "BILBY",
            "name": "Bilby Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9EEvcRcT7Q9XAa6NfqrqJChoc4XGDhd2mtc4xfniQkS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bilby.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "8NGgmXzBzhsXz46pTC3ioSBxeE3w2EXpc741N3EQ8E6r",
            "symbol": "JOKE",
            "name": "JOKESMEMES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NGgmXzBzhsXz46pTC3ioSBxeE3w2EXpc741N3EQ8E6r/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/Jokesmemes11",
                "website": "https://jokesmemes.finance"
            }
        },
        {
            "chainId": 101,
            "address": "Fp4gjLpTsPqBN6xDGpDHwtnuEofjyiZKxxZxzvJnjxV6",
            "symbol": "NAXAR",
            "name": "Naxar",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fp4gjLpTsPqBN6xDGpDHwtnuEofjyiZKxxZxzvJnjxV6/logo.png",
            "extensions": {
                "coingeckoId": "naxar",
                "instagram": "https://instagram.com/naxar_updates",
                "telegram": "https://t.me/naxar_official",
                "twitter": "https://twitter.com/_Naxar",
                "website": "https://naxar.net"
            }
        },
        {
            "chainId": 101,
            "address": "5jqTNKonR9ZZvbmX9JHwcPSEg6deTyNKR7PxQ9ZPdd2w",
            "symbol": "JBUS",
            "name": "Jebus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5jqTNKonR9ZZvbmX9JHwcPSEg6deTyNKR7PxQ9ZPdd2w/logo.png",
            "extensions": {
                "website": "https://jebus.live"
            }
        },
        {
            "chainId": 101,
            "address": "29UWGmi1MxJRi3izeritN8VvhZbUiX37KUVnGv46mzev",
            "symbol": "KLBx",
            "name": "Black Label X",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/29UWGmi1MxJRi3izeritN8VvhZbUiX37KUVnGv46mzev/logo.svg",
            "extensions": {
                "website": "https://klbtoken.com/x"
            }
        },
        {
            "chainId": 101,
            "address": "GACHAfpmbpk4FLfZcGkT2NUmaEqMygssAknhqnn8DVHP",
            "symbol": "GACHA",
            "name": "Gachapon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GACHAfpmbpk4FLfZcGkT2NUmaEqMygssAknhqnn8DVHP/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/GACHAPON7777"
            }
        },
        {
            "chainId": 101,
            "address": "9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m",
            "symbol": "STR",
            "name": "Solster",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solster_finance",
                "website": "https://solster.finance"
            }
        },
        {
            "chainId": 101,
            "address": "A2T2jDe2bxyEHkKtS8AtrTRmJ9VZRwyY8Kr7oQ8xNyfb",
            "symbol": "HAMS",
            "name": "Space Hamster",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2T2jDe2bxyEHkKtS8AtrTRmJ9VZRwyY8Kr7oQ8xNyfb/logo.png",
            "extensions": {
                "coingeckoId": "space-hamster",
                "coinmarketcap": "https://coinmarketcap.com/currencies/space-hamster/",
                "dexWebsite": "https://dex-solhamster.space/",
                "telegram": "https://t.me/SolHamster",
                "twitter": "https://twitter.com/sol_hamster",
                "website": "https://www.solhamster.space/"
            }
        },
        {
            "chainId": 101,
            "address": "EGN2774kzKyUnJs2Gv5poK6ymiMVkdyCQD2gGnJ84sDk",
            "symbol": "NEFT",
            "name": "Neftea Labs Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EGN2774kzKyUnJs2Gv5poK6ymiMVkdyCQD2gGnJ84sDk/logo.png",
            "tags": [
                "Neftea",
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.neftealabs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DK64rmGSZupv1dLYn57e3pUVgs9jL9EKLXDVZZPsMDz8",
            "symbol": "ABOMB",
            "name": "ArtBomb",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DK64rmGSZupv1dLYn57e3pUVgs9jL9EKLXDVZZPsMDz8/logo.png",
            "tags": [
                "utility-token",
                "artbomb"
            ],
            "extensions": {
                "website": "https://artbomb.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "AnyCsr1VCBZcwVAxbKPuHhKDP5DQQSnRxGAo4ycgRMi2",
            "symbol": "DAL",
            "name": "Dalmatiancoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AnyCsr1VCBZcwVAxbKPuHhKDP5DQQSnRxGAo4ycgRMi2/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/coindalmatian",
                "website": "https://dalmatiancoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "HiL1j5VMR9XtRnCA4mxaVoXr6PMHpbh8wUgfPsAP4CNF",
            "symbol": "SolNHD",
            "name": "SolNHD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HiL1j5VMR9XtRnCA4mxaVoXr6PMHpbh8wUgfPsAP4CNF/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/zororoaz01",
                "website": "https://www.solnhd.com"
            }
        },
        {
            "chainId": 101,
            "address": "qXu8Tj65H5XR8KHuaKKoyLCWj592KbTG3YWJwsuFrPS",
            "symbol": "STVA",
            "name": "SOLtiva",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qXu8Tj65H5XR8KHuaKKoyLCWj592KbTG3YWJwsuFrPS/logo.svg",
            "extensions": {
                "serumV3Usdc": "8srnqriKDYXQNSiNh3F5qhkEt8USwWcJyeR65TxavoAf",
                "website": "https://soltiva.co"
            }
        },
        {
            "chainId": 101,
            "address": "D3gHoiYT4RY5VSndne1fEnpM3kCNAyBhkp5xjNUqqPj9",
            "symbol": "PROEXIS",
            "name": "ProExis Prova de Existência Blockchain",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3gHoiYT4RY5VSndne1fEnpM3kCNAyBhkp5xjNUqqPj9/logo.png",
            "tags": [
                "utility-token",
                "proexis"
            ],
            "extensions": {
                "facebook": "https://facebook.com/provadeexistencia",
                "github": "https://github.com/provadeexistencia",
                "instagram": "https://instagram.com/provadeexistencia",
                "telegram": "https://t.me/provadeexistenciagrupo",
                "telegramAnnouncements": "https://t.me/provadeexistencia",
                "twitter": "https://twitter.com/provaexistencia",
                "website": "https://provadeexistencia.com.br"
            }
        },
        {
            "chainId": 101,
            "address": "5DWFxYBxjETuqFX3P2Z1uq8UbcCT1F4sABGiBZMnWKvR",
            "symbol": "PLDO",
            "name": "PLEIDO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5DWFxYBxjETuqFX3P2Z1uq8UbcCT1F4sABGiBZMnWKvR/logo.svg",
            "tags": [
                "pleido",
                "game-coin"
            ],
            "extensions": {
                "website": "https://pleido.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6uB5eEC8SzMbUdsPpe3eiNvHyvxdqUWnDEtpFQxkhNTP",
            "symbol": "MOLANIUM",
            "name": "MOLANIUM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uB5eEC8SzMbUdsPpe3eiNvHyvxdqUWnDEtpFQxkhNTP/logo.png",
            "extensions": {
                "imageUrl": "https://i.imgur.com/hOMe38E.png",
                "medium": "https://moonlana.medium.com/",
                "twitter": "https://twitter.com/xMoonLana",
                "website": "https://moonlana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ",
            "symbol": "GU",
            "name": "Kugle GU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "gu",
                "description": "GU token for the Kugle play to earn game.",
                "twitter": "https://twitter.com/Kuglesworld",
                "website": "https://kugle.org"
            }
        },
        {
            "chainId": 101,
            "address": "72fFy4SNGcHoEC1TTFTUkxNHriJqg3hBPsa2jSr2cZgb",
            "symbol": "BZX",
            "name": "BlizeX",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72fFy4SNGcHoEC1TTFTUkxNHriJqg3hBPsa2jSr2cZgb/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/blizex_en",
                "website": "https://www.blizex.co"
            }
        },
        {
            "chainId": 101,
            "address": "5fEo6ZbvpV6zdyzowtAwgMcWHZe1yJy9NxQM6gC19QW5",
            "symbol": "GREEN",
            "name": "Green DEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fEo6ZbvpV6zdyzowtAwgMcWHZe1yJy9NxQM6gC19QW5/logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/GreendexN",
                "website": "https://greendex.network/"
            }
        },
        {
            "chainId": 101,
            "address": "Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT",
            "symbol": "SOLC",
            "name": "Solcubator",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/Solcubator",
                "website": "http://solcubator.io"
            }
        },
        {
            "chainId": 101,
            "address": "ABxCiDz4jjKt1t7Syu5Tb37o8Wew9ADpwngZh6kpLbLX",
            "symbol": "XSOL",
            "name": "XSOL Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ABxCiDz4jjKt1t7Syu5Tb37o8Wew9ADpwngZh6kpLbLX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/0xSol_Network",
                "website": "https://0xsol.network"
            }
        },
        {
            "chainId": 101,
            "address": "DrcPRJPBiakQcWqon3gZms7sviAqdQS5zS5wvaG5v6wu",
            "symbol": "BLD",
            "name": "BladesToken",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DrcPRJPBiakQcWqon3gZms7sviAqdQS5zS5wvaG5v6wu/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/bladesfinance",
                "website": "https://blades.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "BTyJg5zMbaN2KMfn7LsKhpUsV675aCUSUMrgB1YGxBBP",
            "symbol": "GOOSEBERRY",
            "name": "Gooseberry",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTyJg5zMbaN2KMfn7LsKhpUsV675aCUSUMrgB1YGxBBP/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/gooseberrycoin",
                "website": "https://gooseberry.changr.ca"
            }
        },
        {
            "chainId": 101,
            "address": "8mgeCL7k2cB2KTN8NhD5biqvcbkfrmBogDpYoHmn5cKQ",
            "symbol": "DXB",
            "name": "DefiXBet Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5GG1LbgY4EEvPR51YQPNr65QKcZemrHWPooTqC5gRPBA/logo.png",
            "extensions": {
                "medium": "https://defixbet.medium.com/",
                "telegramAnnouncements": "https://t.me/DefiXBet",
                "twitter": "https://twitter.com/DefiXBet",
                "website": "https://DefiXBet.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf",
            "symbol": "LUNY",
            "name": "Luna Yield",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/Luna_Yield",
                "website": "https://www.lunayield.com"
            }
        },
        {
            "chainId": 101,
            "address": "AP58G14hoy4GGgZS4L8TzZgqXnk3hBvciFKW2Cb1RQ2J",
            "symbol": "YARDv1",
            "name": "SolYard Finance Beta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AP58G14hoy4GGgZS4L8TzZgqXnk3hBvciFKW2Cb1RQ2J/logo.png",
            "extensions": {
                "website": "https://solyard.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7",
            "symbol": "DINO",
            "name": "DINO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solanadino",
                "website": "https://www.solanadino.com"
            }
        },
        {
            "chainId": 101,
            "address": "4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj",
            "symbol": "LIQ",
            "name": "LIQ Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj/logo.png",
            "extensions": {
                "coingeckoId": "liq-protocol",
                "discord": "https://discord.gg/MkfjambeU7",
                "serumV3Usdc": "D7p7PebNjpkH6VNHJhmiDFNmpz9XE7UaTv9RouxJMrwb",
                "twitter": "https://twitter.com/liqsolana",
                "website": "https://liqsolana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz",
            "symbol": "CRP",
            "name": "CropperFinance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz/logo.png",
            "extensions": {
                "coingeckoId": "cropperfinance",
                "serumV3Usdc": "93mtNf4qzvytwp5sWrSC7JNUccPAomEE39ztErUq5V3F",
                "twitter": "https://twitter.com/cropperfinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "B3Ggjjj3QargPkFTAJiR6BaD8CWKFUaWRXGcDQ1nyeeD",
            "symbol": "PARTI",
            "name": "PARTI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B3Ggjjj3QargPkFTAJiR6BaD8CWKFUaWRXGcDQ1nyeeD/logo.png",
            "extensions": {
                "medium": "https://particlefinance.medium.com",
                "twitter": "https://twitter.com/ParticleFinance",
                "website": "https://parti.finance"
            }
        },
        {
            "chainId": 101,
            "address": "5igDhdTnXif5E5djBpRt4wUKo5gtf7VicHi8r5ada4Hj",
            "symbol": "NIA",
            "name": "NIALABS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5igDhdTnXif5E5djBpRt4wUKo5gtf7VicHi8r5ada4Hj/logo.png",
            "extensions": {
                "website": "https://www.nialabs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GQnN5M1M6oTjsziAwcRYd1P7pRBBQKURj5QeAjN1npnE",
            "symbol": "CORV",
            "name": "Project Corvus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GQnN5M1M6oTjsziAwcRYd1P7pRBBQKURj5QeAjN1npnE/logo.png",
            "extensions": {
                "website": "https://dixon.company/"
            }
        },
        {
            "chainId": 101,
            "address": "3FRQnT5djQMATCg6TNXBhi2bBkbTyGdywsLmLa8BbEKz",
            "symbol": "HLTH",
            "name": "HLTH",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FRQnT5djQMATCg6TNXBhi2bBkbTyGdywsLmLa8BbEKz/logo.png",
            "extensions": {
                "telegram": "https://t.me/HLTHnetwork",
                "twitter": "https://twitter.com/hlthnetwork",
                "website": "https://hlth.network/"
            }
        },
        {
            "chainId": 101,
            "address": "SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr",
            "symbol": "SLRS",
            "name": "Solrise Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr/logo.png",
            "extensions": {
                "coingeckoId": "solrise-finance",
                "discord": "https://discord.gg/xNbGgMUJfU",
                "medium": "https://blog.solrise.finance",
                "serumV3Usdc": "2Gx3UfV831BAh8uQv1FKSPKS9yajfeeD8GJ4ZNb2o2YP",
                "telegram": "https://t.me/solrisefinance",
                "twitter": "https://twitter.com/SolriseFinance",
                "website": "https://solrise.finance"
            }
        },
        {
            "chainId": 101,
            "address": "Hejznrp39zCfcmq4WpihfAeyhzhqeFtj4PURHFqMaHSS",
            "symbol": "SE",
            "name": "Snake Eyes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hejznrp39zCfcmq4WpihfAeyhzhqeFtj4PURHFqMaHSS/logo.png",
            "extensions": {
                "discord": "https://discord.gg/g94SubKn"
            }
        },
        {
            "chainId": 101,
            "address": "JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz",
            "symbol": "JET",
            "name": "Jet Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz/logo.png",
            "extensions": {
                "coingeckoId": "jet",
                "serumV3Usdc": "6pQMoHDC2o8eeFxyTKtfnsr8d48hKFWsRpLHAqVHH2ZP",
                "website": "https://jetprotocol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GWpD3eTfhJB5KDCcnE85dBQrjAk2CsrgDF9b52R9CrjV",
            "symbol": "LIQ-USDC",
            "name": "Raydium LP Token (LIQ-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKJHspCwDhABucCxNLXUSfEzb7Ny62RqFtC9uNjJi4fq/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D7U3BPHr5JBbFmPTaVNpmEKGBPFdQS3udijyte1QtuLk",
            "symbol": "STAR",
            "name": "SolStar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D7U3BPHr5JBbFmPTaVNpmEKGBPFdQS3udijyte1QtuLk/logo.png",
            "tags": [
                "community",
                "web3",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/j6B3q5Xk5N",
                "medium": "https://solstar.medium.com",
                "telegram": "https://t.me/SolStarFinance",
                "twitter": "https://twitter.com/SolStarFinance",
                "website": "https://solstar.finance"
            }
        },
        {
            "chainId": 101,
            "address": "GtQ48z7NNjs7sVyp3M7iuiDcTRjeWPd1fkdiWQNy1UR6",
            "symbol": "LIQ-SOL",
            "name": "Raydium LP Token (LIQ-SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKJHspCwDhABucCxNLXUSfEzb7Ny62RqFtC9uNjJi4fq/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DHojuFwy5Pb8HTUhyRGQ285s5KYgk8tGAjAcmjkEAGbY",
            "symbol": "RFK",
            "name": "Refrak",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHojuFwy5Pb8HTUhyRGQ285s5KYgk8tGAjAcmjkEAGbY/logo.png",
            "extensions": {
                "discord": "https://discord.gg/ZAWbnebFVK",
                "website": "https://refrak.io/"
            }
        },
        {
            "chainId": 101,
            "address": "JAhTGv1g19KzE2n58Jzhxpu5SSNioanAzj3wL7epiNUL",
            "symbol": "RFKP",
            "name": "Refrak Platinum",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAhTGv1g19KzE2n58Jzhxpu5SSNioanAzj3wL7epiNUL/logo.png",
            "extensions": {
                "discord": "https://discord.gg/ZAWbnebFVK",
                "website": "https://refrak.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7Jimij6hkEjjgmf3HamW44d2Cf5kj2gHnfCDDPGxWut",
            "symbol": "GIG",
            "name": "GIGCO",
            "decimals": 9,
            "logoURI": "https://gigco.com/images/new-gqo-logo.png",
            "extensions": {
                "twitter": "https://twitter.com/gigcoapp",
                "website": "https://gigco.com/?utm_source=tokenlist"
            }
        },
        {
            "chainId": 101,
            "address": "E5rk3nmgLUuKUiS94gg4bpWwWwyjCMtddsAXkTFLtHEy",
            "symbol": "WOO",
            "name": "Wootrade Network",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5rk3nmgLUuKUiS94gg4bpWwWwyjCMtddsAXkTFLtHEy/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/wootraderS",
                "website": "https://woo.network"
            }
        },
        {
            "chainId": 101,
            "address": "9s6dXtMgV5E6v3rHqBF2LejHcA2GWoZb7xNUkgXgsBqt",
            "symbol": "USDC-USDT-PAI",
            "name": "Mercurial LP Token (USDC-USDT-PAI)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9s6dXtMgV5E6v3rHqBF2LejHcA2GWoZb7xNUkgXgsBqt/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "8kRacWW5qZ34anyH8s9gu2gC4FpXtncqBDPpd2a6DnZE",
            "symbol": "MECA",
            "name": "Coinmeca",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kRacWW5qZ34anyH8s9gu2gC4FpXtncqBDPpd2a6DnZE/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/coinmeca",
                "medium": "https://coinmeca.medium.com/",
                "reddit": "https://reddit.com/r/coinmeca",
                "telegram": "https://t.me/coinmeca",
                "twitter": "https://twitter.com/coinmeca",
                "website": "https://coinmeca.net/"
            }
        },
        {
            "chainId": 101,
            "address": "6h6uy8yAfaAb5sPE2bvXQEB93LnUMEdcCRU2kfiErTct",
            "symbol": "ZMR",
            "name": "ZMIRROR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6h6uy8yAfaAb5sPE2bvXQEB93LnUMEdcCRU2kfiErTct/logo.JPG"
        },
        {
            "chainId": 101,
            "address": "sodaNXUbtjMvHe9c5Uw7o7VAcVpXPHAvtaRaiPVJQuE",
            "symbol": "SODA",
            "name": "cheesesoda token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sodaNXUbtjMvHe9c5Uw7o7VAcVpXPHAvtaRaiPVJQuE/logo.svg",
            "extensions": {
                "coingeckoId": "cheesesoda-token",
                "serumV3Usdc": "6KFs2wUzME8Z3AeWL4HfKkXbtik5zVvebdg5qCxqt4hB",
                "twitter": "https://twitter.com/cheesesodadex",
                "website": "https://token.cheesesoda.com"
            }
        },
        {
            "chainId": 101,
            "address": "sodaoT6Wh1nxHaarw4kDh7AkK4oZnERK1QgDUtHPR3H",
            "symbol": "SODAO",
            "name": "cheesesodaDAO",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sodaoT6Wh1nxHaarw4kDh7AkK4oZnERK1QgDUtHPR3H/logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/cheesesodadex",
                "website": "https://dao.cheesesoda.com"
            }
        },
        {
            "chainId": 101,
            "address": "49YUsDrThJosHSagCn1F59Uc9NRxbr9thVrZikUnQDXy",
            "symbol": "LIQ-RAY",
            "name": "Raydium LP Token (LIQ-RAY)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKJHspCwDhABucCxNLXUSfEzb7Ny62RqFtC9uNjJi4fq/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FGmeGqUqKzVX2ajkXaFSQxNcBRWnJg1vi5fugRJrDJ3k",
            "symbol": "FCS",
            "name": "FCS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGmeGqUqKzVX2ajkXaFSQxNcBRWnJg1vi5fugRJrDJ3k/logo.png",
            "extensions": {
                "website": "https://www.fcs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CjpDCj8zLSM37669qng5znYP25JuoDPCvLSLLd7pxAsr",
            "symbol": "Nordic Energy Token",
            "name": "NET",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CjpDCj8zLSM37669qng5znYP25JuoDPCvLSLLd7pxAsr/logo.png",
            "extensions": {
                "telegram": "https://t.me/nordicenergy",
                "twitter": "https://twitter.com/nordicenergy1",
                "website": "https://nordicenergy.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9eaAUFp7S38DKXxbjwzEG8oq1H1AipPkUuieUkVJ9krt",
            "symbol": "KDC",
            "name": "KDC (KURZ Digital Currency)",
            "decimals": 2,
            "logoURI": "https://kurzdigital.com/images/KDC_logo.png",
            "tags": [
                "stablecoin",
                "kdc"
            ],
            "extensions": {
                "website": "https://www.kurzdigital.com"
            }
        },
        {
            "chainId": 101,
            "address": "A1C9Shy732BThWvHAN936f33N7Wm1HbFvxb2zDSoBx8F",
            "symbol": "PKR2",
            "name": "PKR2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/C-e-r-b-e-r-u-s/token-list/main/assets/mainnet/A1C9Shy732BThWvHAN936f33N7Wm1HbFvxb2zDSoBx8F/pkr2-logo.png",
            "extensions": {
                "serumV3Usdt": "AUYZV5BbKePrAkMiWCMhc1EbZCPNHDrK7Jf8jYy8noF6",
                "twitter": "https://twitter.com/PKR2_Token",
                "website": "https://twitter.com/PKR2_Token"
            }
        },
        {
            "chainId": 101,
            "address": "35KgRun5UMT2Kjtjw4cNG1tXHcgBxuxji6Yp6ciz7yX7",
            "symbol": "VPE",
            "name": "VPOWER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/35KgRun5UMT2Kjtjw4cNG1tXHcgBxuxji6Yp6ciz7yX7/logo.png",
            "extensions": {
                "telegram": "https://t.me/vpowerswap_channel",
                "twitter": "https://twitter.com/vpowerswap",
                "website": "https://vpowerswap.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GSaiLQxREzaxUcE3v28HxBacoUQPZNtXx1eQsCFsX9Bg",
            "symbol": "XgSAIL",
            "name": "gSAIL DEPRECATED",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GSaiLQxREzaxUcE3v28HxBacoUQPZNtXx1eQsCFsX9Bg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaSail",
                "website": "https://www.solanasail.com"
            }
        },
        {
            "chainId": 101,
            "address": "ELyNEh5HC33sQLhGiQ5dimmwqiJCiqVJp3eQxpX3pKhQ",
            "symbol": "JCS",
            "name": "Jogys Crypto School Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELyNEh5HC33sQLhGiQ5dimmwqiJCiqVJp3eQxpX3pKhQ/logo.png",
            "extensions": {
                "instagram": "https://instagram.com/jogyscryptoschool?utm_medium=copy_link",
                "telegram": "https://t.me/JCS_JogysCryptoSchool",
                "website": "https://instagram.com/jogyscryptoschool?utm_medium=copy_link"
            }
        },
        {
            "chainId": 101,
            "address": "3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR",
            "symbol": "LIKE",
            "name": "Only1 (LIKE)",
            "decimals": 9,
            "logoURI": "https://only1.io/like-token.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "only1",
                "discord": "https://discord.gg/SrsKwTFA",
                "medium": "https://only1nft.medium.com/",
                "telegram": "https://t.me/only1nft",
                "twitter": "https://twitter.com/only1nft",
                "website": "https://only1.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm",
            "symbol": "wUST_v1",
            "name": "Wrapped UST (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa47c8bf37f92aBed4A126BDA807A7b7498661acD",
                "assetContract": "https://etherscan.io/address/0xa47c8bf37f92aBed4A126BDA807A7b7498661acD",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "terrausd",
                "website": "https://terra.money"
            }
        },
        {
            "chainId": 101,
            "address": "A7SXXA9wveT2quqqzh5m6Zf3ueCb9kBezQdpnYxHwzLt",
            "symbol": "ZINTI",
            "name": "Zia Inti",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A7SXXA9wveT2quqqzh5m6Zf3ueCb9kBezQdpnYxHwzLt/logo.png",
            "extensions": {
                "website": "https://www.ziainti.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3Ztt53vwGhQGoEp3n1RjSu4CFnGRfqzwo6L8KN8gmXfd",
            "symbol": "METAS",
            "name": "METASEER",
            "decimals": 9,
            "logoURI": "https://metaseer.io/img/home-one/logo256.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MSEERofficial",
                "website": "https://metaseer.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GEfZFBNAaTBxVj3T1sNRApSLfyYZYMYhfVZPhx7LFhXg",
            "symbol": "METAG",
            "name": "METASEER Governance Token",
            "decimals": 9,
            "logoURI": "https://metaseer.io/img/home-one/metag256.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MSEERofficial",
                "website": "https://metaseer.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EssczqGURZtsSuzEoH471KCRNDWfS4aQpEJVXWL3DvdK",
            "symbol": "VIVA",
            "name": "Viva coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EssczqGURZtsSuzEoH471KCRNDWfS4aQpEJVXWL3DvdK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/inkresearch",
                "website": "https://www.inkresearch.com"
            }
        },
        {
            "chainId": 101,
            "address": "7JnHPPJBBKSTJ7iEmsiGSBcPJgbcKw28uCRXtQgimncp",
            "symbol": "Orbs",
            "name": "Orbs",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7JnHPPJBBKSTJ7iEmsiGSBcPJgbcKw28uCRXtQgimncp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "orbs",
                "twitter": "https://twitter.com/orbs_network",
                "website": "https://www.orbs.com"
            }
        },
        {
            "chainId": 101,
            "address": "EWS2ATMt5fQk89NWLJYNRmGaNoji8MhFZkUB4DiWCCcz",
            "symbol": "SOLBERRY",
            "name": "SOLBERRY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EWS2ATMt5fQk89NWLJYNRmGaNoji8MhFZkUB4DiWCCcz/logo.png",
            "extensions": {
                "coingeckoId": "solberry",
                "serumV3Usdc": "HchZzqewgC4pfsJU9uxFsrP4sLDqLsDntYRzE5RgrBak",
                "twitter": "https://twitter.com/berrysol",
                "website": "https://www.solberry.tech"
            }
        },
        {
            "chainId": 101,
            "address": "FJJT7yUJM9X9SHpkVr4wLgyfJ3vtVLoReUqTsCPWzof2",
            "symbol": "KEKW-USDC",
            "name": "Raydium LP Token (KEKW-USDC)",
            "decimals": 9,
            "logoURI": "https://www.kekw.io/images/kekwusdc.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kekw",
                "medium": "https://kekwcoin.medium.com/",
                "twitter": "https://twitter.com/kekwcoin",
                "website": "https://kekw.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5Z6jnA9fDUDVjQyaTbYWwCTE47wMAuyvAQjg5angY12C",
            "symbol": "DNDZ",
            "name": "Dinarius Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Boukezzoula/Dinarius/master/dinariuslogo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "http://dinarius.net"
            }
        },
        {
            "chainId": 101,
            "address": "EqbY2zaTsJesaVviL5unHKjDsjoQZJhQAQz3iWQxAu1X",
            "symbol": "RnV",
            "name": "RADONTOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EqbY2zaTsJesaVviL5unHKjDsjoQZJhQAQz3iWQxAu1X/logo.png",
            "extensions": {
                "website": "https://www.radonvalue.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5pXLmRJyfrTDYMCp1xyiqRDcbb7vYjYiMYzhBza2ht62",
            "symbol": "CRYN",
            "name": "Crayon",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5pXLmRJyfrTDYMCp1xyiqRDcbb7vYjYiMYzhBza2ht62/crayon.png",
            "extensions": {
                "description": "Crayon is a meme token, Dex, and Dapps on Solana.",
                "serumV3Usdc": "CjBssusBjX4b2UBvMZhiZCQshW1afpQPA1Mv29Chn6vj",
                "twitter": "https://twitter.com/SolanaCrayon",
                "website": "https://solanacrayon.com"
            }
        },
        {
            "chainId": 101,
            "address": "6pSK3JkbfFcQvu6TuTsRnG61jKxdbaoRRkp1H6jhxXV3",
            "symbol": "CRYPT",
            "name": "Cryptor",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6pSK3JkbfFcQvu6TuTsRnG61jKxdbaoRRkp1H6jhxXV3/logo_light.png",
            "tags": [
                "streaming",
                "earnings"
            ],
            "extensions": {
                "description": "Cryptor let people invest in crypto throught Twitch.",
                "twitch": "https://twitch.tv/bt0r",
                "twitter": "https://twitter.com/biiitor"
            }
        },
        {
            "chainId": 101,
            "address": "z9WZXekbCtwoxyfAwEJn1euXybvqLzPVv3NDzJzkq7C",
            "symbol": "CRC",
            "name": "Care Coin Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/z9WZXekbCtwoxyfAwEJn1euXybvqLzPVv3NDzJzkq7C/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/carecointoken_",
                "website": "https://www.carecoin.site"
            }
        },
        {
            "chainId": 101,
            "address": "9aPjLUGR9e6w6xU2NEQNtP3jg3mq2mJjSUZoQS4RKz35",
            "symbol": "SOUL",
            "name": "Soulana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9aPjLUGR9e6w6xU2NEQNtP3jg3mq2mJjSUZoQS4RKz35/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/Soulanadefi"
            }
        },
        {
            "chainId": 101,
            "address": "5U93vfnWJ4NKDhm7k1X7J6D15nJim2odivn9MmfCWwPU",
            "symbol": "CHUM",
            "name": "Chum Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5U93vfnWJ4NKDhm7k1X7J6D15nJim2odivn9MmfCWwPU/logo.png",
            "tags": [
                "email-token",
                "email-coin"
            ],
            "extensions": {
                "website": "https://chumsmart.com"
            }
        },
        {
            "chainId": 101,
            "address": "26W4xxHbWJfrswaMNh14ag2s4PZTQuu2ypHGj6YEVXkT",
            "symbol": "DCASH",
            "name": "Diabolo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/26W4xxHbWJfrswaMNh14ag2s4PZTQuu2ypHGj6YEVXkT/dcash-logo.png",
            "extensions": {
                "website": "https://diabolo.io"
            }
        },
        {
            "chainId": 101,
            "address": "2pMNgs7Arn1oQBNSb65Aj55rY2zpWHV9JkuqK6ZoetCj",
            "symbol": "KBX",
            "name": "KUGLE-BOX",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/Seigneur-Machiavel/kugle.github.io/Tokens/KBX_200.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Kugle_",
                "website": "https://kugle.org"
            }
        },
        {
            "chainId": 101,
            "address": "8CWgMvZe7ntNLbky4T3JhSgtCYzeorgRiUY8xfXZztXx",
            "symbol": "IOTC",
            "name": "IoTcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8CWgMvZe7ntNLbky4T3JhSgtCYzeorgRiUY8xfXZztXx/logo.jpg",
            "extensions": {
                "facebook": "https://facebook.com/iotworlds",
                "instagram": "https://instagram.com/iotworlds",
                "linkedin": "https://www.linkedin.com/company/iotworlds",
                "twitter": "https://twitter.com/iotworlds",
                "website": "https://www.iotworlds.com"
            }
        },
        {
            "chainId": 101,
            "address": "FqJE1neoCJrRwxfC9mRL6FduuZ1gCX2FUbya5hi8EQgA",
            "symbol": "VLDC",
            "name": "Viloid Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FqJE1neoCJrRwxfC9mRL6FduuZ1gCX2FUbya5hi8EQgA/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "github": "https://github.com/viloidcoin",
                "website": "https://viloidcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9",
            "symbol": "C98",
            "name": "Coin98",
            "decimals": 6,
            "logoURI": "https://coin98.s3.ap-southeast-1.amazonaws.com/Coin/c98-512.svg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "coingeckoId": "coin98",
                "github": "https://github.com/coin98",
                "telegram": "https://t.me/coin98_finance",
                "twitter": "https://twitter.com/coin98_finance",
                "waterfallbot": "https://bit.ly/C98waterfall",
                "website": "https://coin98.com"
            }
        },
        {
            "chainId": 101,
            "address": "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
            "symbol": "SBR",
            "name": "Saber Protocol Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg",
            "extensions": {
                "coingeckoId": "saber",
                "discord": "https://chat.saber.so",
                "github": "https://github.com/saber-hq",
                "medium": "https://blog.saber.so",
                "serumV3Usdc": "HXBi8YBwbh4TXF6PjVw81m8Z3Cc4WBofvauj5SBFdgUs",
                "twitter": "https://twitter.com/saber_hq",
                "waterfallbot": "https://bit.ly/SBRwaterfall",
                "website": "https://saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "2juwHtqBUEaV26WM5sVvjFsjvCXfwP3ZPndmr5ywVwgZ",
            "symbol": "ADAM",
            "name": "adamho",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2juwHtqBUEaV26WM5sVvjFsjvCXfwP3ZPndmr5ywVwgZ/adamho_250x250.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/takwah"
            }
        },
        {
            "chainId": 101,
            "address": "FMJotGUW16AzexRD3vXJQ94AL71cwrhtFaCTGtK1QHXm",
            "symbol": "LRA",
            "name": "Lumos Rewards",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FMJotGUW16AzexRD3vXJQ94AL71cwrhtFaCTGtK1QHXm/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://lumos.exchange"
            }
        },
        {
            "chainId": 101,
            "address": "AWTE7toEwKdSRd7zh3q45SjKhmYVFp3zk4quWHsM92bj",
            "symbol": "ZAU",
            "name": "Zaucoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWTE7toEwKdSRd7zh3q45SjKhmYVFp3zk4quWHsM92bj/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ABFPEo4pUy1is4Atf33zZoYpG2nkB66W3fsTwAeCUSkA",
            "symbol": "SAM",
            "name": "Swiss and Makeup",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ABFPEo4pUy1is4Atf33zZoYpG2nkB66W3fsTwAeCUSkA/logo.png",
            "tags": [
                "fan-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/swissandmakeup/"
            }
        },
        {
            "chainId": 101,
            "address": "5ToouaoWhGCiaicANcewnaNKJssdZTxPATDhqJXARiJG",
            "symbol": "NUR",
            "name": "Nur Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5ToouaoWhGCiaicANcewnaNKJssdZTxPATDhqJXARiJG/logo.png",
            "tags": [
                "kazakhstan",
                "qazaqstan",
                "kz"
            ]
        },
        {
            "chainId": 101,
            "address": "8Jmvq2f2UMKwS16HeLXJu3Gr1BmZhmDmUXXuLRLcCKaj",
            "symbol": "MYOPA",
            "name": "Myopa",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Jmvq2f2UMKwS16HeLXJu3Gr1BmZhmDmUXXuLRLcCKaj/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/myopa",
                "facebook": "https://www.facebook.com/MyopaNFT/",
                "telegram": "https://t.me/myopanft",
                "twitter": "https://twitter.com/myopanft",
                "website": "https://myopa.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9ysRLs872GMvmAjjFZEFccnJBF3tYEVT1x7dFE1WPqTY",
            "symbol": "VRNT",
            "name": "Variant",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ysRLs872GMvmAjjFZEFccnJBF3tYEVT1x7dFE1WPqTY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.variantresearch.io"
            }
        },
        {
            "chainId": 101,
            "address": "8pBc4v9GAwCBNWPB5XKA93APexMGAS4qMr37vNke9Ref",
            "symbol": "wHBTC_v1",
            "name": "HBTC (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pBc4v9GAwCBNWPB5XKA93APexMGAS4qMr37vNke9Ref/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0316EB71485b0Ab14103307bf65a021042c6d380",
                "assetContract": "https://etherscan.io/address/0x0316EB71485b0Ab14103307bf65a021042c6d380",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "huobi-btc"
            }
        },
        {
            "chainId": 101,
            "address": "CjEm7iRHr5cwWTjtF7Xk58hnRiH4rz9NXboeeWjueFCc",
            "symbol": "DSPWN",
            "name": "Despawn",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CjEm7iRHr5cwWTjtF7Xk58hnRiH4rz9NXboeeWjueFCc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://despawn.io/dspwn"
            }
        },
        {
            "chainId": 101,
            "address": "Dg7d2va8PEKhPH1gfDoDUw21eRVbZPGRXrKEVafgEVgw",
            "symbol": "PVK",
            "name": "PlatinumO2",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dg7d2va8PEKhPH1gfDoDUw21eRVbZPGRXrKEVafgEVgw/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://plantinumo2.com/"
            }
        },
        {
            "chainId": 101,
            "address": "az4Nt1UtDp7Vo8nabW7SokKejpHUAju79JUaYDnXgkF",
            "symbol": "PNDR",
            "name": "PANDER",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/az4Nt1UtDp7Vo8nabW7SokKejpHUAju79JUaYDnXgkF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://pander.network"
            }
        },
        {
            "chainId": 101,
            "address": "BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX",
            "symbol": "wHUSD_v1",
            "name": "HUSD Stablecoin (Wormhole v1)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xdf574c24545e5ffecb9a659c229253d4111d87e1",
                "assetContract": "https://etherscan.io/address/0xdf574c24545e5ffecb9a659c229253d4111d87e1",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "husd",
                "website": "https://www.stcoins.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm",
            "symbol": "wHAPI",
            "name": "Wrapped HAPI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm/logo.png",
            "tags": [
                "wrapped",
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "hapi",
                "github": "https://github.com/HAPIprotocol/HAPI/",
                "medium": "https://medium.com/i-am-hapi",
                "telegram": "https://t.me/hapiHF",
                "twitter": "https://twitter.com/i_am_hapi_one",
                "website": "https://hapi.one"
            }
        },
        {
            "chainId": 101,
            "address": "Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC",
            "symbol": "LARIX",
            "name": "Larix",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC/logo.jpg",
            "extensions": {
                "coingeckoId": "larix",
                "discord": "https://discord.gg/hfnRFV9Ngt",
                "github": "https://github.com/ProjectLarix/Larix-Lending-Project-Rep",
                "medium": "http://projectlarix.medium.com",
                "telegram": "https://t.me/projectlarix",
                "twitter": "https://twitter.com/ProjectLarix",
                "website": "https://projectlarix.com"
            }
        },
        {
            "chainId": 101,
            "address": "BYvGwtPx6Nw4YUVVwqx7qh657EcdxBSfE8JcaPmWWa6E",
            "symbol": "TOSTI",
            "name": "Tosti Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYvGwtPx6Nw4YUVVwqx7qh657EcdxBSfE8JcaPmWWa6E/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://tosti.app"
            }
        },
        {
            "chainId": 101,
            "address": "EKEjv7VJTsKsfyZMNgPfoKkdk7pYNSgb3tg2h3zUe4PT",
            "symbol": "SIMP",
            "name": "Simp",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKEjv7VJTsKsfyZMNgPfoKkdk7pYNSgb3tg2h3zUe4PT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/5293AzqtHU",
                "website": "https://functional-spirit-e72.notion.site/Simp-090cf60910024a228d8b163dcaf23a84"
            }
        },
        {
            "chainId": 101,
            "address": "5ZsPxmhdh9jeDMCrWu6LvNvcvNtpbpwhQvrKkeMYZE7R",
            "symbol": "BECO",
            "name": "Beco Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5ZsPxmhdh9jeDMCrWu6LvNvcvNtpbpwhQvrKkeMYZE7R/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://beco.club/"
            }
        },
        {
            "chainId": 101,
            "address": "32uwQKZibFm5C9EjY6raGC1ZjAAQQWy1LvJxeriJEzEt",
            "symbol": "DGX",
            "name": "DGX",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/YBw0zVc/LOGO-new.png",
            "extensions": {
                "twitter": "https://twitter.com/dgxsolana",
                "website": "https://solanadgx.com/"
            }
        },
        {
            "chainId": 101,
            "address": "57h4LEnBooHrKbacYWGCFghmrTzYPVn8PwZkzTzRLvHa",
            "symbol": "USDC-USDT-UST",
            "name": "Mercurial LP Token (USDC-USDT-UST)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/57h4LEnBooHrKbacYWGCFghmrTzYPVn8PwZkzTzRLvHa/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9VgfFUFkGGrRePvpKLPkp9DR3crRepf6CJsYU3UmudtY",
            "symbol": "WEEB",
            "name": "Weeb Finance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9VgfFUFkGGrRePvpKLPkp9DR3crRepf6CJsYU3UmudtY/logo.png",
            "tags": [
                "utility-token",
                "anime"
            ],
            "extensions": {
                "discord": "https://discord.gg/fzZbyXAzaG",
                "medium": "https://medium.com/@WeebFinance",
                "telegram": "https://t.me/weeb_finance",
                "twitter": "https://twitter.com/WeebFinance",
                "website": "https://weeb.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf",
            "symbol": "SSU",
            "name": "SunnySideUp Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf/logo.png",
            "extensions": {
                "github": "https://github.com/sunnysideup72",
                "medium": "https://medium.com/official-sunnysideup",
                "twitter": "https://twitter.com/SSU_SunnySideUp",
                "website": "https://www.sunnysideup.finance"
            }
        },
        {
            "chainId": 101,
            "address": "8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS",
            "symbol": "sSOL",
            "name": "SunnySideUp staked SOL (sSOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS/logo.png",
            "extensions": {
                "github": "https://github.com/sunnysideup72",
                "medium": "https://medium.com/official-sunnysideup",
                "twitter": "https://twitter.com/SSU_SunnySideUp",
                "website": "https://www.sunnysideup.finance"
            }
        },
        {
            "chainId": 101,
            "address": "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
            "symbol": "mSOL",
            "name": "Marinade staked SOL (mSOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So/logo.png",
            "extensions": {
                "coingeckoId": "msol",
                "discord": "https://discord.gg/mGqZA5pjRN",
                "github": "https://github.com/marinade-finance",
                "medium": "https://medium.com/marinade-finance",
                "serumV3Usdc": "6oGsL2puUgySccKzn9XA9afqF217LfxP5ocq4B3LWsjy",
                "serumV3Usdt": "HxkQdUnrPdHwXP5T9kewEXs3ApgvbufuTfdw9v1nApFd",
                "twitter": "https://twitter.com/MarinadeFinance",
                "website": "https://marinade.finance"
            }
        },
        {
            "chainId": 101,
            "address": "LPmSozJJ8Jh69ut2WP3XmVohTjL4ipR18yiCzxrUmVj",
            "symbol": "mSOL-SOL-LP",
            "name": "Marinade LP token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LPmSozJJ8Jh69ut2WP3XmVohTjL4ipR18yiCzxrUmVj/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mGqZA5pjRN",
                "github": "https://github.com/marinade-finance",
                "medium": "https://medium.com/marinade-finance",
                "twitter": "https://twitter.com/MarinadeFinance",
                "website": "https://marinade.finance"
            }
        },
        {
            "chainId": 101,
            "address": "3k8BDobgihmk72jVmXYLE168bxxQUhqqyESW4dQVktqC",
            "symbol": "STEP-USDC",
            "name": "Raydium LP Token V4 (STEP-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3k8BDobgihmk72jVmXYLE168bxxQUhqqyESW4dQVktqC/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3UMYcByZNQVHHyyqVfXMKr8XWP64omYBFVvf7bD6wBiA",
            "symbol": "BET",
            "name": "SOLBET",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3UMYcByZNQVHHyyqVfXMKr8XWP64omYBFVvf7bD6wBiA/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "SOLBET seeks to facilitate P2P speculation and provide trustless on-chain escrow services for speculative ventures utilizing on-chain data, oracle services, and private data node operators to verify outcomes for all parties involved.",
                "discord": "https://solbet.org/discord",
                "serumV3Usdc": "GsWX1FgWP35jchi5R9uiNys2g6GftruEiHVpPS2b7Vq8",
                "telegram": "https://t.me/solbet_official",
                "twitter": "https://twitter.com/solbet_official",
                "website": "https://solbet.org/"
            }
        },
        {
            "chainId": 101,
            "address": "A5zanvgtioZGiJMdEyaKN4XQmJsp1p7uVxaq2696REvQ",
            "symbol": "MEDIA-USDC",
            "name": "Raydium LP Token V4 (MEDIA-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5zanvgtioZGiJMdEyaKN4XQmJsp1p7uVxaq2696REvQ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Cq4HyW5xia37tKejPF2XfZeXQoPYW6KfbPvxvw5eRoUE",
            "symbol": "ROPE-USDC",
            "name": "Raydium LP Token V4 (ROPE-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cq4HyW5xia37tKejPF2XfZeXQoPYW6KfbPvxvw5eRoUE/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3H9NxvaZoxMZZDZcbBDdWMKbrfNj7PCF5sbRwDr7SdDW",
            "symbol": "MER-USDC",
            "name": "Raydium LP Token V4 (MER-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3H9NxvaZoxMZZDZcbBDdWMKbrfNj7PCF5sbRwDr7SdDW/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Cz1kUvHw98imKkrqqu95GQB9h1frY8RikxPojMwWKGXf",
            "symbol": "COPE-USDC",
            "name": "Raydium LP Token V4 (COPE-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cz1kUvHw98imKkrqqu95GQB9h1frY8RikxPojMwWKGXf/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "iUDasAP2nXm5wvTukAHEKSdSXn8vQkRtaiShs9ceGB7",
            "symbol": "ALEPH-USDC",
            "name": "Raydium LP Token V4 (ALEPH-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iUDasAP2nXm5wvTukAHEKSdSXn8vQkRtaiShs9ceGB7/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7cu42ao8Jgrd5A3y3bNQsCxq5poyGZNmTydkGfJYQfzh",
            "symbol": "WOO-USDC",
            "name": "Raydium LP Token V4 (WOO-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7cu42ao8Jgrd5A3y3bNQsCxq5poyGZNmTydkGfJYQfzh/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "G8qcfeFqxwbCqpxv5LpLWxUCd1PyMB5nWb5e5YyxLMKg",
            "symbol": "SNY-USDC",
            "name": "Raydium LP Token V4 (SNY-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G8qcfeFqxwbCqpxv5LpLWxUCd1PyMB5nWb5e5YyxLMKg/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9nQPYJvysyfnXhQ6nkK5V7sZG26hmDgusfdNQijRk5LD",
            "symbol": "BOP-RAY",
            "name": "Raydium LP Token V4 (BOP-RAY)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nQPYJvysyfnXhQ6nkK5V7sZG26hmDgusfdNQijRk5LD/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2Xxbm1hdv5wPeen5ponDSMT3VqhGMTQ7mH9stNXm9shU",
            "symbol": "SLRS-USDC",
            "name": "Raydium LP Token V4 (SLRS-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Xxbm1hdv5wPeen5ponDSMT3VqhGMTQ7mH9stNXm9shU/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HwzkXyX8B45LsaHXwY8su92NoRBS5GQC32HzjQRDqPnr",
            "symbol": "SAMO-RAY",
            "name": "Raydium LP Token V4 (SAMO-RAY)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HwzkXyX8B45LsaHXwY8su92NoRBS5GQC32HzjQRDqPnr/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CTEpsih91ZLo5gunvryLpJ3pzMjmt5jbS6AnSQrzYw7V",
            "symbol": "renBTC-USDC",
            "name": "Raydium LP Token V4 (renBTC-USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CTEpsih91ZLo5gunvryLpJ3pzMjmt5jbS6AnSQrzYw7V/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Hb8KnZNKvRxu7pgMRWJgoMSMcepfvNiBFFDDrdf9o3wA",
            "symbol": "renDOGE-USDC",
            "name": "Raydium LP Token V4 (renDOGE-USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hb8KnZNKvRxu7pgMRWJgoMSMcepfvNiBFFDDrdf9o3wA/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FbC6K13MzHvN42bXrtGaWsvZY9fxrackRSZcBGfjPc7m",
            "symbol": "RAY-USDC",
            "name": "Raydium LP Token V4 (RAY-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FbC6K13MzHvN42bXrtGaWsvZY9fxrackRSZcBGfjPc7m/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7P5Thr9Egi2rvMmEuQkLn8x8e8Qro7u2U7yLD2tU2Hbe",
            "symbol": "RAY-SRM",
            "name": "Raydium LP Token V4 (RAY-SRM)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7P5Thr9Egi2rvMmEuQkLn8x8e8Qro7u2U7yLD2tU2Hbe/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "mjQH33MqZv5aKAbKHi8dG3g3qXeRQqq1GFcXceZkNSr",
            "symbol": "RAY-ETH",
            "name": "Raydium LP Token V4 (RAY-ETH)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mjQH33MqZv5aKAbKHi8dG3g3qXeRQqq1GFcXceZkNSr/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "89ZKE4aoyfLBe2RuV6jM3JGNhaV18Nxh8eNtjRcndBip",
            "symbol": "RAY-SOL",
            "name": "Raydium LP Token V4 (RAY-SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/89ZKE4aoyfLBe2RuV6jM3JGNhaV18Nxh8eNtjRcndBip/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Hmatmu1ktLbobSvim94mfpZmjL5iiyoM1zidtXJRAdLZ",
            "symbol": "PSOL",
            "name": "Parasol",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/parasol-finance/white-paper/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "parasol-finance",
                "discord": "https://discord.gg/WTwm2V45UZ",
                "github": "https://github.com/parasol-finance",
                "medium": "https://medium.com/@parasol.finance",
                "serumV3Usdc": "DukWnNjNS1qQYUNGYj5NJViEfQW793mHwxij3wi9dazM",
                "telegram": "https://t.me/parasolfinance",
                "twitter": "https://twitter.com/parasol_finance",
                "website": "https://parasol.finance"
            }
        },
        {
            "chainId": 101,
            "address": "4HFaSvfgskipvrzT1exoVKsUZ174JyExEsA8bDfsAdY5",
            "symbol": "DXL-USDC",
            "name": "Raydium LP Token V4 (DXL-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4HFaSvfgskipvrzT1exoVKsUZ174JyExEsA8bDfsAdY5/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4dydh8EGNEdTz6grqnGBxpduRg55eLnwNZXoNZJetadu",
            "symbol": "SWARM",
            "name": "MIM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dydh8EGNEdTz6grqnGBxpduRg55eLnwNZXoNZJetadu/logo.png",
            "extensions": {
                "coingeckoId": "mim",
                "discord": "https://discord.gg/8mHbKWczpB",
                "github": "https://github.com/kyonym/MIM",
                "telegram": "https://t.me/mimswarm",
                "twitter": "https://twitter.com/mimswarm",
                "website": "https://mim-swarm.com"
            }
        },
        {
            "chainId": 101,
            "address": "9SC3YkrWSWeroDUQnAuQ8fkziko2N6QydZPfVbDFjK8Z",
            "symbol": "PHC",
            "name": "Phosphine Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9SC3YkrWSWeroDUQnAuQ8fkziko2N6QydZPfVbDFjK8Z/logo.png",
            "tags": [
                "phosphine"
            ],
            "extensions": {
                "website": "https://phosphinecoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "cjZmbt8sJgaoyWYUttomAu5LJYU44ZrcKTbzTSEPDVw",
            "symbol": "LIKE-USDC",
            "name": "Raydium LP Token V4 (LIKE-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cjZmbt8sJgaoyWYUttomAu5LJYU44ZrcKTbzTSEPDVw/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y",
            "symbol": "PORT",
            "name": "Port Finance Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y/PORT.png",
            "extensions": {
                "coingeckoId": "port-finance",
                "discord": "https://discord.gg/nAMXAYhTb2",
                "github": "https://github.com/port-finance/",
                "medium": "https://medium.com/port-finance",
                "serumV3Usdc": "8x8jf7ikJwgP9UthadtiGFgfFuyyyYPHL3obJAuxFWko",
                "telegram": "https://t.me/port_finance",
                "twitter": "https://twitter.com/port_finance",
                "waterfallbot": "https://bit.ly/PORTwaterfall",
                "website": "https://port.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "C3vBJEuNvrUqJYQ5ki8TSrCndphJQ7wwiXEwvuy1AJkW",
            "symbol": "BONGO",
            "name": "Bongocoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/assets/mainnet/C3vBJEuNvrUqJYQ5ki8TSrCndphJQ7wwiXEwvuy1AJkW/logo.png",
            "extensions": {
                "website": "https://www.bongocoin.org"
            }
        },
        {
            "chainId": 101,
            "address": "6CssfnBjF4Vo56EithaLHLWDF95fLrt48QHsUfZwNnhv",
            "symbol": "JPYC",
            "name": "JPY Coin(Portal)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5trVBqv1LvHxiSPMsHtEZuf8iN82wbpDcR5Zaw7sWC3s/logo.png",
            "tags": [
                "stablecoin",
                "ethereum",
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "assetContract": "https://etherscan.io/address/0x2370f9d504c7a6e775bf6e14b3f12846b594cd53",
                "coingeckoId": "jpyc",
                "twitter": "https://twitter.com/jpy_coin",
                "website": "https://jpyc.jp/"
            }
        },
        {
            "chainId": 101,
            "address": "D1EjNd9c7MgepvQCS31x5TpdXpvtDwDNCLwLGEYg6hYo",
            "symbol": "AUTOS",
            "name": "Autostorm",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/autostorm-org/img/cb78b86a54f6f4f637e4e6cbe961e002966b4844/avatar.png",
            "tags": [
                "cars",
                "auto",
                "marketplace"
            ],
            "extensions": {
                "discord": "https://discord.gg/yWjkHgnPD3",
                "website": "https://www.autostorm.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BL6X5awy2TstWE6gJGZMLXwW1Wi3VsdCDWEzzK2cuzrw",
            "symbol": "ARIES",
            "name": "SOLARIES Financial Token",
            "decimals": 9,
            "logoURI": "https://github.com/ariesfinancial/Aries-Financial/raw/f2946ff1e295fef66e3cfa5e590daef7bb8559c2/logo-120-120%403x.png",
            "extensions": {
                "discord": "https://discord.gg/cRFc6kEu",
                "github": "https://github.com/ariesfinancial",
                "medium": "https://aries-financial.medium.com/",
                "telegram": "https://t.me/aries_financial_official",
                "twitter": "https://twitter.com/_AriesFinancial",
                "website": "https://solaries.network/"
            }
        },
        {
            "chainId": 101,
            "address": "GJQpf6Zjvokd3YK5EprXqZUah9jxkn8aG4pTeWL7Gkju",
            "symbol": "OKI",
            "name": "HDOKI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJQpf6Zjvokd3YK5EprXqZUah9jxkn8aG4pTeWL7Gkju/logo.png",
            "extensions": {
                "website": "https://hdoki.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4oCZJ97YH3cnqbdSqi8GZXpaTR9cdmF7idx3dZNNG7n8",
            "symbol": "DBYT",
            "name": "Debyt",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oCZJ97YH3cnqbdSqi8GZXpaTR9cdmF7idx3dZNNG7n8/logo.svg",
            "extensions": {
                "github": "https://github.com/debyt",
                "medium": "https://medium.com/@debyt",
                "telegram": "https://t.me/debytfinance",
                "twitter": "https://twitter.com/debytfinance",
                "website": "https://debyt.io/"
            }
        },
        {
            "chainId": 101,
            "address": "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
            "symbol": "MNGO",
            "name": "Mango",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png",
            "extensions": {
                "coingeckoId": "mango-markets",
                "discord": "https://discord.gg/67jySBhxrg",
                "serumV3Usdc": "3d4rzwpy9iGdCZvgxcu7B1YocYffVLsQXPXkBZKt2zLc",
                "twitter": "https://twitter.com/mangomarkets",
                "website": "https://mango.markets/"
            }
        },
        {
            "chainId": 101,
            "address": "9X4EK8E59VAVi6ChnNvvd39m6Yg9RtkBbAPq1mDVJT57",
            "symbol": "SLIM-SOL",
            "name": "Raydium LP Token V4 (SLIM-SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8BNNxGUinfDgwXodroVfGQde1RnwsA2DW34gc89YcBH9",
            "symbol": "RDZ",
            "name": "RADIOZONE26",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/Radiozone26/RDZtoken/RDZlogo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Rzone26",
                "twitter": "https://twitter.com/radio_zone26",
                "website": "https://radiozone26.com/"
            }
        },
        {
            "chainId": 101,
            "address": "F34jmbEEAEHCKqCLUXEEKyMWZLTAfFuF6mKQejySSZSN",
            "symbol": "MOGO",
            "name": "Mogotrovio",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F34jmbEEAEHCKqCLUXEEKyMWZLTAfFuF6mKQejySSZSN/symbol.png",
            "extensions": {
                "discord": "https://discord.gg/zwgxUqypy9",
                "website": "https://mogotrov.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7",
            "symbol": "UPS",
            "name": "UPS token (UPFI Network)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7/logo.png",
            "extensions": {
                "discord": "https://discord.gg/nHMDdyAggx",
                "facebook": "https://www.facebook.com/UPFInetwork",
                "medium": "https://upfinetwork.medium.com",
                "serumV3Usdc": "DByPstQRx18RU2A8DH6S9mT7bpT6xuLgD2TTFiZJTKZP",
                "telegram": "https://t.me/upfinetworkchannel",
                "twitter": "https://twitter.com/upfi_network",
                "website": "https://upfi.network/"
            }
        },
        {
            "chainId": 101,
            "address": "Gsai2KN28MTGcSZ1gKYFswUpFpS7EM9mvdR9c8f6iVXJ",
            "symbol": "gSAIL",
            "name": "SolanaSail Governance Token V2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanasail/token-list/main/assets/mainnet/Gsai2KN28MTGcSZ1gKYFswUpFpS7EM9mvdR9c8f6iVXJ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "solanasail-governance-token",
                "serumV3Usdc": "2zkPyHgQkKG6qJED6MTbjfCfUbZeT9VFwLm1Ld9nKxRp",
                "twitter": "https://twitter.com/SolanaSail",
                "website": "https://www.solanasail.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS",
            "symbol": "PAI",
            "name": "PAI (Parrot USD)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS/logo.svg",
            "tags": [
                "utility-token",
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "parrot-usd",
                "discord": "https://discord.gg/gopartyparrot",
                "medium": "https://gopartyparrot.medium.com/",
                "telegram": "https://t.me/gopartyparrot",
                "twitter": "https://twitter.com/gopartyparrot",
                "website": "https://parrot.fi"
            }
        },
        {
            "chainId": 101,
            "address": "PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44",
            "symbol": "PRT",
            "name": "PRT (Parrot Protocol)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "parrot-protocol",
                "discord": "https://discord.gg/gopartyparrot",
                "medium": "https://gopartyparrot.medium.com/",
                "telegram": "https://t.me/gopartyparrot",
                "twitter": "https://twitter.com/gopartyparrot",
                "website": "https://parrot.fi"
            }
        },
        {
            "chainId": 101,
            "address": "DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun",
            "symbol": "pBTC",
            "name": "pBTC (Parrot BTC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/gopartyparrot",
                "medium": "https://gopartyparrot.medium.com/",
                "telegram": "https://t.me/gopartyparrot",
                "twitter": "https://twitter.com/gopartyparrot",
                "website": "https://parrot.fi"
            }
        },
        {
            "chainId": 101,
            "address": "9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX",
            "symbol": "pSOL",
            "name": "pSOL (Parrot SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/gopartyparrot",
                "medium": "https://gopartyparrot.medium.com/",
                "telegram": "https://t.me/gopartyparrot",
                "twitter": "https://twitter.com/gopartyparrot",
                "website": "https://parrot.fi"
            }
        },
        {
            "chainId": 101,
            "address": "AsWigW5KeEKsiDAgdjxpWQEBMFk4BEgmmmHZMYwVfUVN",
            "symbol": "ZKL",
            "name": "zkrollup cross chain link",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsWigW5KeEKsiDAgdjxpWQEBMFk4BEgmmmHZMYwVfUVN/logo.png",
            "extensions": {
                "website": "https://zk.link"
            }
        },
        {
            "chainId": 101,
            "address": "EwHqbMUMX33JjWAhxSg9vsX3miWqncsgpnAbqn9nhJwZ",
            "symbol": "ULA",
            "name": "Solana Mobile App UlaPay Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EwHqbMUMX33JjWAhxSg9vsX3miWqncsgpnAbqn9nhJwZ/logo.png",
            "extensions": {
                "website": "http://kikenn.com/"
            }
        },
        {
            "chainId": 101,
            "address": "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag",
            "symbol": "SUNNY",
            "name": "Sunny Governance Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag/logo.svg",
            "extensions": {
                "coingeckoId": "sunny-aggregator",
                "discord": "https://chat.sunny.ag",
                "github": "https://github.com/SunnyAggregator",
                "medium": "https://medium.com/sunny-aggregator",
                "serumV3Usdc": "Aubv1QBFh4bwB2wbP1DaPW21YyQBLfgjg8L4PHTaPzRc",
                "twitter": "https://twitter.com/SunnyAggregator",
                "waterfallbot": "https://bit.ly/SUNNYwaterfall",
                "website": "https://sunny.ag/"
            }
        },
        {
            "chainId": 101,
            "address": "BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg",
            "symbol": "CYS",
            "name": "Cykura",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "cykura",
                "coinmarketcap": "https://coinmarketcap.com/currencies/cyclos/",
                "discord": "https://discord.gg/gyaK56UreX",
                "github": "https://github.com/cykura",
                "medium": "https://cykura.medium.com/",
                "serumV3Usdc": "6V6y6QFi17QZC9qNRpVp7SaPiHpCTp2skbRQkUyZZXPW",
                "solanium": "https://www.solanium.io/project/cyclos/",
                "telegram": "https://t.me/cykuraofficialchat",
                "twitter": "https://twitter.com/cykurafi",
                "website": "https://cykura.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FxjbQMfvQYMtZZK7WGEJwWfsDcdMuuaee8uPxDFFShWh",
            "symbol": "UPFI",
            "name": "UPFI stablecoin (UPFI Network)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FxjbQMfvQYMtZZK7WGEJwWfsDcdMuuaee8uPxDFFShWh/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/nHMDdyAggx",
                "facebook": "https://www.facebook.com/UPFInetwork",
                "medium": "https://upfinetwork.medium.com",
                "serumV3Usdc": "SyQ4KyF5Y1MPPkkf9LGNA6JpkVmis53HrpPvJ1ZUFwK",
                "telegram": "https://t.me/upfinetworkchannel",
                "twitter": "https://twitter.com/upfi_network",
                "website": "https://upfi.network/"
            }
        },
        {
            "chainId": 101,
            "address": "7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj",
            "symbol": "stSOL",
            "name": "Lido Staked SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj/logo.png",
            "extensions": {
                "coingeckoId": "lido-staked-sol",
                "coinmarketcap": "https://coinmarketcap.com/currencies/lido-for-solana/",
                "discord": "https://discord.gg/w9pXXgQPu8",
                "github": "https://github.com/ChorusOne/solido",
                "serumV3Usdc": "5F7LGsP1LPtaRV7vVKgxwNYX4Vf22xvuzyXjyar7jJqp",
                "twitter": "https://twitter.com/LidoFinance",
                "website": "https://solana.lido.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "H2mf9QNdU2Niq6QR7367Ua2trBsvscLyX5bz7R3Pw5sE",
            "symbol": "stETH",
            "name": "Lido Staked ETH",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H2mf9QNdU2Niq6QR7367Ua2trBsvscLyX5bz7R3Pw5sE/logo.png",
            "tags": [
                "stake",
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "lido-staked-ether",
                "discord": "https://discord.gg/WhhnWwsFXz",
                "github": "https://github.com/lidofinance",
                "telegram": "https://t.me/lidofinance",
                "twitter": "https://twitter.com/LidoFinance",
                "website": "https://lido.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo",
            "symbol": "wstETH",
            "name": "Lido Wrapped Staked ETH",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo/logo.png",
            "tags": [
                "stake",
                "wrapped"
            ],
            "extensions": {
                "discord": "https://discord.gg/WhhnWwsFXz",
                "github": "https://github.com/lidofinance",
                "telegram": "https://t.me/lidofinance",
                "twitter": "https://twitter.com/LidoFinance",
                "website": "https://lido.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "3ewm17jCxn8EkEpar45mnY6qk7wc93uPg5D41KMeHZhf",
            "symbol": "CSH",
            "name": "CSH Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ewm17jCxn8EkEpar45mnY6qk7wc93uPg5D41KMeHZhf/logo.png",
            "tags": [
                "utility-token",
                "shchoi"
            ]
        },
        {
            "chainId": 101,
            "address": "2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn",
            "symbol": "KURO",
            "name": "Kurobi",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "kurobi",
                "github": "https://github.com/KurobiHq/",
                "medium": "https://kurobi.medium.com/",
                "telegram": "https://t.me/kurobi_io",
                "twitter": "https://twitter.com/kurobi_io",
                "website": "https://kurobi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FiCiuX9DetEE89PgRAU1hmoptnem8b1fkpEq8PGYTYkd",
            "symbol": "MM",
            "name": "Million",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FiCiuX9DetEE89PgRAU1hmoptnem8b1fkpEq8PGYTYkd/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611",
                "assetContract": "https://etherscan.io/address/0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611",
                "bridgeContract": "https://etherscan.io/address/0xf92cd566ea4864356c5491c177a430c222d7e678",
                "coingeckoId": "million",
                "discord": "http://app.milliontoken.org/discord",
                "facebook": "https://facebook.com/groups/milliontoken",
                "instagram": "https://instagram.com/milliontokenofficial",
                "reddit": "https://www.reddit.com/r/milliontoken/",
                "telegram": "https://t.me/millionjacuzzibar",
                "twitter": "https://twitter.com/Million__Token",
                "website": "https://www.milliontoken.org/"
            }
        },
        {
            "chainId": 101,
            "address": "2PoF4gqWg97yjJk276yUYaGVkkASE7tqAU7H5faEBkeC",
            "symbol": "MM",
            "name": "Million",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2PoF4gqWg97yjJk276yUYaGVkkASE7tqAU7H5faEBkeC/logo.svg",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "coingeckoId": "million",
                "discord": "http://app.milliontoken.org/discord",
                "reddit": "https://www.reddit.com/r/milliontoken/",
                "twitter": "https://twitter.com/Million__Token",
                "website": "https://www.milliontoken.org/"
            }
        },
        {
            "chainId": 101,
            "address": "Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz",
            "symbol": "HOTTO",
            "name": "HottoShotto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "serumV3Usdc": "76d1Gv8649Fhn7HtZTxaPCMFA4fYxaQ3jbna7pGMGA6",
                "website": "https://hottoshotto.com"
            }
        },
        {
            "chainId": 101,
            "address": "FossiLkXJZ1rePN8jWBqHDZZ3F7ET8p1dRGhYKHbQcZR",
            "symbol": "Fossil",
            "name": "Scallop Fossil Decorations",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FossiLkXJZ1rePN8jWBqHDZZ3F7ET8p1dRGhYKHbQcZR/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/Scallop",
                "facebook": "https://www.facebook.com/Scallop.io",
                "instagram": "https://www.instagram.com/scallop.io",
                "medium": "https://scallopio.medium.com/",
                "telegram": "https://t.me/scallop_io",
                "twitter": "https://twitter.com/Scallop_io",
                "website": "https://www.scallop.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FM8yfVgaEHrpSzNZeZ1o4v5iLZuT9soNuqaWD72bJyqs",
            "symbol": "HOTTO-USDC",
            "name": "Raydium LP Token V4 (HOTTO-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FM8yfVgaEHrpSzNZeZ1o4v5iLZuT9soNuqaWD72bJyqs/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SeawdHf3NHG6gxCrezQxr5oJAHTLJd6JsQxxd144yaz",
            "symbol": "Seagrass",
            "name": "Scallop Seagrass Decorations",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SeawdHf3NHG6gxCrezQxr5oJAHTLJd6JsQxxd144yaz/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/Scallop",
                "facebook": "https://www.facebook.com/Scallop.io",
                "instagram": "https://www.instagram.com/scallop.io",
                "medium": "https://scallopio.medium.com/",
                "telegram": "https://t.me/scallop_io",
                "twitter": "https://twitter.com/Scallop_io",
                "website": "https://www.scallop.io/"
            }
        },
        {
            "chainId": 101,
            "address": "78CeyRBJSu4MFmaDi8Q8QZ3szB6Xwp93sVaMLYSy5SMZ",
            "symbol": "HOTTO-SOL",
            "name": "Raydium LP Token V4 (HOTTO-SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78CeyRBJSu4MFmaDi8Q8QZ3szB6Xwp93sVaMLYSy5SMZ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ScaLopYHz9eKtDdKs4yLswwq2RSUtNMZVdPynMcYcc9",
            "symbol": "SCA",
            "name": "Scallop",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ScaLopYHz9eKtDdKs4yLswwq2RSUtNMZVdPynMcYcc9/logo.png",
            "extensions": {
                "discord": "https://discord.gg/Scallop",
                "facebook": "https://www.facebook.com/Scallop.io",
                "instagram": "https://www.instagram.com/scallop.io",
                "medium": "https://scallopio.medium.com/",
                "telegram": "https://t.me/scallop_io",
                "twitter": "https://twitter.com/Scallop_io",
                "website": "https://www.scallop.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ",
            "symbol": "SYP",
            "name": "Sypool",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/b0e47e39f84cffb655f406eb569c57a88b5211cc/assets/mainnet/FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ/logo.png",
            "tags": [
                "platform"
            ],
            "extensions": {
                "coingeckoId": "sypool",
                "website": "https://www.sypool.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FGpMT3xLwk67hWsT7Lgp7WjovS3rejx9KBmCG1bBtB9U",
            "symbol": "ALTREC",
            "name": "ALTREC Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SmonkeyMonkey/token-list/main/assets/mainnet/FGpMT3xLwk67hWsT7Lgp7WjovS3rejx9KBmCG1bBtB9U/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2YxGppCJJY2KGoAwFdFASE6tnD4cENM7nThwUgdpXwjE",
            "symbol": "COD-sc1",
            "name": "Sceptre Token v1 (Sceptre-TOKEN)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2YxGppCJJY2KGoAwFdFASE6tnD4cENM7nThwUgdpXwjE/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://kokeshi.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "HbrmyoumgcK6sDFBi6EZQDi4i4ZgoN16eRB2JseKc7Hi",
            "symbol": "CRY",
            "name": "Crystal",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HbrmyoumgcK6sDFBi6EZQDi4i4ZgoN16eRB2JseKc7Hi/logo.png",
            "tags": [
                "crystal-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ghnnPvQNgS",
                "github": "https://github.com/sol-crystal",
                "medium": "https://solcrystal.medium.com/",
                "serumV3Usdc": "H3e7YziokpHJfFAMAy2PK6sNph72f38P1ELd5TUQaocv",
                "serumV3Usdt": "ESmbZckdRFv1F8aJ9CfcAsQ9JQchCVgXMEd2UimcujHU",
                "telegram": "https://t.me/sol_cry",
                "twitter": "https://twitter.com/Crystal80955369",
                "website": "http://solcry.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HRBrRXGCrPro6TtryKQkLXuZqg3LdBMN9ZWx2v66pT4L",
            "symbol": "WNAV",
            "name": "Wrapped Navcoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRBrRXGCrPro6TtryKQkLXuZqg3LdBMN9ZWx2v66pT4L/logo.png",
            "tags": [
                "ethereum"
            ],
            "extensions": {
                "website": "https://navcoin.org"
            }
        },
        {
            "chainId": 101,
            "address": "2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij",
            "symbol": "DINOEGG",
            "name": "DINOEGG",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solanadino",
                "website": "https://www.solanadino.com"
            }
        },
        {
            "chainId": 101,
            "address": "Fh4e5vX2euTBzyGK2FXN1P3A4VUoH73oPVuemfRWXK2Y",
            "symbol": "wFOX_v1",
            "name": "ShapeShift FOX Token (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fh4e5vX2euTBzyGK2FXN1P3A4VUoH73oPVuemfRWXK2Y/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc770eefad204b5180df6a14ee197d99d808ee52d",
                "assetContract": "https://etherscan.io/address/0xc770eefad204b5180df6a14ee197d99d808ee52d",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "shapeshift-fox-token",
                "github": "https://github.com/shapeshift",
                "twitter": "https://twitter.com/ShapeShift_io",
                "website": "https://shapeshift.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FY6XDSCubMhpkU9FAsUjB7jmN8YHYZGezHTWo9RHBSyX",
            "symbol": "ASH",
            "name": "Ashera",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FY6XDSCubMhpkU9FAsUjB7jmN8YHYZGezHTWo9RHBSyX/logo.png",
            "extensions": {
                "coingeckoId": "ashera",
                "coinmarketcap": "https://coinmarketcap.com/currencies/ashera/",
                "discord": "https://discord.gg/b3qYsNyBkz",
                "github": "https://github.com/asherasol",
                "medium": "https://solashera.medium.com/",
                "serumV3Usdc": "56ZFVzqMqtDmyry9bK7vi1szUV2nuQ4kT6CzFAB649wE",
                "twitter": "https://twitter.com/SolAshera",
                "website": "https://asherasol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "333iHoRM2Awhf9uVZtSyTfU8AekdGrgQePZsKMFPgKmS",
            "symbol": "ISOLA",
            "name": "Intersola",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/333iHoRM2Awhf9uVZtSyTfU8AekdGrgQePZsKMFPgKmS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Intersolaio/",
                "medium": "https://intersola.medium.com/",
                "serumV3Usdt": "42QVcMqoXmHT94zaBXm9KeU7pqDfBuAPHYN9ADW8weCF",
                "telegram": "https://t.me/intersola",
                "twitter": "https://twitter.com/intersola_io",
                "website": "https://intersola.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EYDEQW4xQzLqHcFwHTgGvpdjsa5EFn74KzuqLX5emjD2",
            "symbol": "BST",
            "name": "Balisari",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EYDEQW4xQzLqHcFwHTgGvpdjsa5EFn74KzuqLX5emjD2/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "coingeckoId": "balisari",
                "coinmarketcap": "https://coinmarketcap.com/currencies/balisari/",
                "discord": "https://discord.gg/6ndxB4vS56",
                "serumV3Usdc": "Hq2tZzrdNrMtDsdXeCf9CrN3aS3geJTEqtLRPyiYRZc",
                "telegram": "https://t.me/balisaritrans",
                "twitter": "https://twitter.com/balisaritrans",
                "website": "https://www.balisaritrans.site/"
            }
        },
        {
            "chainId": 101,
            "address": "8FXW4GSS9SNDVP5UhaWNsaZbxvRJXNrwvwvToXRnvuWL",
            "symbol": "KNB",
            "name": "KNB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FXW4GSS9SNDVP5UhaWNsaZbxvRJXNrwvwvToXRnvuWL/logo.png",
            "tags": [
                "knb-token"
            ],
            "extensions": {
                "website": "https://solatoken.net/"
            }
        },
        {
            "chainId": 101,
            "address": "Dypr2gWcVuqt3z6Uh31YD8Wm2V2ZCqWVBYEWhZNF9odk",
            "symbol": "SOLJAV",
            "name": "SOLJAV",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dypr2gWcVuqt3z6Uh31YD8Wm2V2ZCqWVBYEWhZNF9odk/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/n3VJpqftRU",
                "serumV3Usdc": "9e63hJZ1HUNDeBwmzJy9VsRT4kzqJ92Xk24htAwjTCeQ",
                "twitter": "https://twitter.com/soljav1",
                "website": "https://www.soljav.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "J3ts1ZEyQeUAbUyYHjZR6sE93YQTrfBzho8UKWnEa1j",
            "symbol": "ABION",
            "name": "aBion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J3ts1ZEyQeUAbUyYHjZR6sE93YQTrfBzho8UKWnEa1j/logo.png",
            "extensions": {
                "website": "https://www.abion.org/"
            }
        },
        {
            "chainId": 101,
            "address": "EN1VhM7BmuqAuUDGDDnzXZdefaFpvNHFCAwjXzp6gRhJ",
            "symbol": "BBS",
            "name": "BONER",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EN1VhM7BmuqAuUDGDDnzXZdefaFpvNHFCAwjXzp6gRhJ/logo.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BareBonesNFT",
                "website": "https://www.barebonesnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CnGUfvi9FxiRPuaBXpYmaXEwBjj5X6kwNJB2Cba5TiQp",
            "symbol": "SOLUP",
            "name": "SOLUP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CnGUfvi9FxiRPuaBXpYmaXEwBjj5X6kwNJB2Cba5TiQp/logo.png",
            "tags": [
                "SOLUP-TOKEN",
                "Sol-UP"
            ],
            "extensions": {
                "assetContract": "https://solscan.io/token/CnGUfvi9FxiRPuaBXpYmaXEwBjj5X6kwNJB2Cba5TiQp",
                "telegram": "https://t.me/solanavietnam",
                "website": "https://solup.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "95KN8q3qubEVjPf9trgyur2nHx8T5RCmztRbLuQ5E5i",
            "symbol": "SMRT",
            "name": "Solminter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95KN8q3qubEVjPf9trgyur2nHx8T5RCmztRbLuQ5E5i/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "solminter",
                "github": "https://github.com/solminter",
                "medium": "https://solminter.medium.com",
                "twitter": "https://twitter.com/solminter",
                "website": "https://solminter.com"
            }
        },
        {
            "chainId": 101,
            "address": "2ZrwW5Ng1fbZKghWxnjyfTjYXLdSwJpU5EQrXus4ogsE",
            "symbol": "TIX",
            "name": "Tix Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ZrwW5Ng1fbZKghWxnjyfTjYXLdSwJpU5EQrXus4ogsE/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/TixToken",
                "website": "https://tixtoken.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3xiDaQKLGrnWEVGpxFT5Y2DCBF1KoKdUnm9DmWdFnk45",
            "symbol": "PLGFT",
            "name": "Plongeurs de Fontaine Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3xiDaQKLGrnWEVGpxFT5Y2DCBF1KoKdUnm9DmWdFnk45/logo.png",
            "tags": [
                "esport"
            ]
        },
        {
            "chainId": 101,
            "address": "CKtm7ZMYdKmFSCGukzKjhsp4JFTFGk9uEMGF7XYEFKgK",
            "symbol": "ALP",
            "name": "CoinAlpha",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CKtm7ZMYdKmFSCGukzKjhsp4JFTFGk9uEMGF7XYEFKgK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://coinalpha.app/"
            }
        },
        {
            "chainId": 101,
            "address": "Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9",
            "symbol": "DATE",
            "name": "SolDate(DATE) Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9/DATE.svg",
            "tags": [
                "social-token",
                "dating-token",
                "metaverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/soldate",
                "medium": "https://soldate.medium.com",
                "telegram": "https://t.me/soldate_org",
                "twitter": "https://twitter.com/SolDate_org",
                "website": "https://soldate.org/"
            }
        },
        {
            "chainId": 101,
            "address": "SWANaZUGxF82KyVsbxeeNsMaVECtimze5VyCdywkvkH",
            "symbol": "SWAN",
            "name": "Swanlana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SwanLana/logo/main/SWANLANA_PNG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "swanlana",
                "discord": "http://discord.gg/swanlanafinance",
                "medium": "https://medium.com/@swanlana23",
                "serumV3Usdc": "8zPVSrun5AnePjXWjC4EgMdBaLdHtkSUtYbFNobzQHRN",
                "twitter": "https://twitter.com/swan_solana",
                "website": "https://www.swanlana.com"
            }
        },
        {
            "chainId": 101,
            "address": "G7uYedVqFy97mzjygebnmmaMUVxWHFhNZotY6Zzsprvf",
            "symbol": "CSTR",
            "name": "CoreStarter",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CoreStarter/token-logo/main/corestarter_logo.png",
            "extensions": {
                "github": "https://github.com/CoreStarter/",
                "linkedin": "https://www.linkedin.com/company/core-starter",
                "medium": "https://corestarter.medium.com",
                "telegram": "https://t.me/corestarter_chat",
                "twitter": "https://twitter.com/CoreStarter",
                "website": "https://corestarter.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DNhZkUaxHXYvpxZ7LNnHtss8sQgdAfd1ZYS1fB7LKWUZ",
            "symbol": "apUSDT",
            "name": "Wrapped USDT (Allbridge from Polygon)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca",
            "symbol": "apUSDC",
            "name": "Wrapped USDC (Allbridge from Polygon)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "De2bU64vsXKU9jq4bCjeDxNRGPn8nr3euaTK8jBYmD3J",
            "symbol": "renFIL",
            "name": "renFIL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/De2bU64vsXKU9jq4bCjeDxNRGPn8nr3euaTK8jBYmD3J/logo.png",
            "extensions": {
                "coingeckoId": "renfil",
                "website": "https://renproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6STzg1taqgJsFY6Z4xAmQVSErZ6e6EsbsvkQ6YJ3sXmj",
            "symbol": "SONC",
            "name": "Sonic",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6STzg1taqgJsFY6Z4xAmQVSErZ6e6EsbsvkQ6YJ3sXmj/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/JaySpark0x",
                "website": "https://www.sparkborsa.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k",
            "symbol": "SOLR",
            "name": "SolRazr",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k/SOLR.png",
            "extensions": {
                "discord": "https://discord.gg/HXa3qAYe",
                "github": "https://github.com/solrazr-app/",
                "medium": "https://medium.com/@SolRazr_App",
                "telegram": "https://t.me/solrazr_app",
                "twitter": "https://twitter.com/Solrazr_App",
                "website": "https://solrazr.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5xgRqfw4DqzjrriXEWduzo8iW8Uj1KzDsPt1pSLVQVJh",
            "symbol": "RNFTz",
            "name": "RNFTz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5xgRqfw4DqzjrriXEWduzo8iW8Uj1KzDsPt1pSLVQVJh/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RnftzS",
                "website": "https://rnftz.com"
            }
        },
        {
            "chainId": 101,
            "address": "8kFRCmQTKzvtVTVEVizjP8x3WamJpuQdZaPSGeqRJJnW",
            "symbol": "SKEM-USDC",
            "name": "Raydium LP Token (SKEM-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kFRCmQTKzvtVTVEVizjP8x3WamJpuQdZaPSGeqRJJnW/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "HKLBSZbkfeB8LoaLLrK7CDepPHLWQEoj1jbunT1T2wYg",
            "symbol": "SODA-USDC",
            "name": "Raydium LP Token (SODA-USDC)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HKLBSZbkfeB8LoaLLrK7CDepPHLWQEoj1jbunT1T2wYg/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "BK2YNwsExxnjSUgdAzdvLV2FrthcNGGWTxDBvfBULCjG",
            "symbol": "AUSS-USDC",
            "name": "Raydium LP Token (AUSS-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BK2YNwsExxnjSUgdAzdvLV2FrthcNGGWTxDBvfBULCjG/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "BTszujAA5kJJT7YCWVsAXwk4eJeuycithuTeAksQC1RC",
            "symbol": "KLB-USDC",
            "name": "Raydium LP Token (KLB-USDC)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTszujAA5kJJT7YCWVsAXwk4eJeuycithuTeAksQC1RC/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "DqRNwrvGUffB1j9tEYHcpw1DLMoc2QfwZ25nkBHkvRmr",
            "symbol": "SUPL",
            "name": "Suplar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DqRNwrvGUffB1j9tEYHcpw1DLMoc2QfwZ25nkBHkvRmr/token.png",
            "extensions": {
                "telegram": "https://t.me/suplar",
                "twitter": "https://twitter.com/suplarcom",
                "website": "https://suplar.com"
            }
        },
        {
            "chainId": 101,
            "address": "4KVuGB1iNhYqR99Hykv1ZLdHvx41zpBqqPFtHucYpQja",
            "symbol": "XEN",
            "name": "Xenren",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4KVuGB1iNhYqR99Hykv1ZLdHvx41zpBqqPFtHucYpQja/logo.png",
            "extensions": {
                "website": "https://xenren.co"
            }
        },
        {
            "chainId": 101,
            "address": "7b9rgZhiZHieCoPwxWd7ihbjtQ7Ljjy4McxvcA2TTgcK",
            "symbol": "PERA",
            "name": "Prithera token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7b9rgZhiZHieCoPwxWd7ihbjtQ7Ljjy4McxvcA2TTgcK/logo.png"
        },
        {
            "chainId": 101,
            "address": "8SvvzDMu5jqcBhfdYZM1zDjDG5YGYrsNmGsPzTm4bFYU",
            "symbol": "QIA",
            "name": "Qia Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8SvvzDMu5jqcBhfdYZM1zDjDG5YGYrsNmGsPzTm4bFYU/logo.png"
        },
        {
            "chainId": 101,
            "address": "3BUWWi7hb5dpnNdvi7s3hpLuDtzqEga6c2UT6c1tqKKP",
            "symbol": "COD",
            "name": "CODEMY",
            "decimals": 9,
            "logoURI": "http://codemyedu.com/resources/img/logo.png",
            "tags": [
                "CODEMY",
                "COD"
            ],
            "extensions": {
                "website": "http://codemyedu.com"
            }
        },
        {
            "chainId": 101,
            "address": "HGy1LwAfsmC61hvAtadW7FaPTzMG8iJQEJBVqJTjgd7u",
            "symbol": "NTE",
            "name": "Nocte",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HGy1LwAfsmC61hvAtadW7FaPTzMG8iJQEJBVqJTjgd7u/logo.png",
            "extensions": {
                "website": "https://nocte.app/"
            }
        },
        {
            "chainId": 101,
            "address": "H6nF5DxF9ERkNrfs2QgMbDvVAH7YmzHM2Q1ysL7Qpgt",
            "symbol": "FE",
            "name": "FUTURE ECOSYSTEM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/h1rdr3v2/logo/main/felogo.jpeg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AdARF36hBezSbqn7JAkGJtgGppMYdjtBjjXwRwBEp7JT",
            "symbol": "CAEN",
            "name": "Camel Aggregate Ecological Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanasail/token-list/main/assets/mainnet/AdARF36hBezSbqn7JAkGJtgGppMYdjtBjjXwRwBEp7JT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://caen.io"
            }
        },
        {
            "chainId": 101,
            "address": "25Vu6457o2gdZRGVVt5K8NbAvaP3esYaQNHbNDitVtw1",
            "symbol": "XVC",
            "name": "Xverse Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/25Vu6457o2gdZRGVVt5K8NbAvaP3esYaQNHbNDitVtw1/logo.png",
            "extensions": {
                "website": "https://xverse.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "5CZn24oQp8rZgdJvw3Ud8Mi5yTKBccMi1efogxxqBuK8",
            "symbol": "CUTIE",
            "name": "Cutie Patootie",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5CZn24oQp8rZgdJvw3Ud8Mi5yTKBccMi1efogxxqBuK8/logo.png",
            "tags": [
                "MEME"
            ],
            "extensions": {
                "discord": "https://discord.gg/2d3FvQUR",
                "github": "https://github.com/Cutie-Patootie-Token",
                "telegram": "https://t.me/joinchat/XFk1Boii0GxiNDc0",
                "twitter": "https://twitter.com/CutiePatotieSLN",
                "website": "https://www.cutiepatootie.tech"
            }
        },
        {
            "chainId": 101,
            "address": "Hp5CJjw9YxJeo8mAgkyUomzKGPUxEwyo6gGt6hj56aTw",
            "symbol": "SDM",
            "name": "Seldom",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hp5CJjw9YxJeo8mAgkyUomzKGPUxEwyo6gGt6hj56aTw/logo.png"
        },
        {
            "chainId": 101,
            "address": "CLLoeCMyKGH9yd6EVBUWFAbAfwq5VBFq4zidxZWKRaho",
            "symbol": "AUTM",
            "name": "Autumn",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLLoeCMyKGH9yd6EVBUWFAbAfwq5VBFq4zidxZWKRaho/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GZreQfnp3B1bmBZfxzJgShWbJgt6nyp13iyeHBB6Xh1n",
            "symbol": "LETTA",
            "name": "Soletta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj/logo.png"
        },
        {
            "chainId": 101,
            "address": "5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt",
            "symbol": "SPWN",
            "name": "Bitspawn Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "bitspawn",
                "discord": "https://discord.gg/EAtfCq9",
                "medium": "https://bitspawnprotocol.medium.com",
                "telegram": "https://t.me/bitspawnprotocol",
                "twitter": "https://twitter.com/bitspawngg",
                "website": "https://bitspawn.io"
            }
        },
        {
            "chainId": 101,
            "address": "SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd",
            "symbol": "SNS",
            "name": "SynesisOne",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Synesis-One/spl-token/main/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "synesis-one",
                "twitter": "https://twitter.com/synesis_one",
                "website": "https://www.synesis.one/"
            }
        },
        {
            "chainId": 101,
            "address": "6Wcs5FH471q1gqJHyRygm7DpNiHP1oYCKHX5zPEBD8ZZ",
            "symbol": "MILS",
            "name": "MillionSols",
            "decimals": 9,
            "logoURI": "https://arweave.net/uDmRPKqd7O9rXkv9y6cdc2sdCbVab5cgA62PWQYUYwI",
            "extensions": {
                "twitter": "https://twitter.com/MillionSols",
                "website": "https://millionsols.com"
            }
        },
        {
            "chainId": 101,
            "address": "GthwuoDnGTRgnvaZWixuqU5X3Nt18s9AzqNbGPxTonfK",
            "symbol": "JMKA",
            "name": "Jacob Makarsky Social Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GthwuoDnGTRgnvaZWixuqU5X3Nt18s9AzqNbGPxTonfK/logo.png",
            "tags": [
                "social-token",
                "jamaka"
            ],
            "extensions": {
                "website": "https://www.makarsky.dev/"
            }
        },
        {
            "chainId": 101,
            "address": "EaD8CViuq8RXPqAhZsxZudTj6fFMy6ktgHD42J34P6PD",
            "symbol": "KISM",
            "name": "KISAMA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kisamacrypto/SPL-Logo/main/kisama-crypto-coin-520x520_edit.png",
            "tags": [
                "social-token",
                "kisama",
                "Trading"
            ],
            "extensions": {
                "discord": "https://discord.gg/6xNDyc9",
                "website": "http://kisamacrypto.com"
            }
        },
        {
            "chainId": 101,
            "address": "6w5GEARUppTyeQee2grCUYjXi933Yswz5ZjYKt5nicY2",
            "symbol": "SOTY",
            "name": "Sol Infinity",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/kpvy2002/solinfinity/SOLINFINITY%20LOGO.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/z9st3dHRPf",
                "telegram": "https://t.me/Solinfinity_official",
                "twitter": "https://twitter.com/Solanainfinity"
            }
        },
        {
            "chainId": 101,
            "address": "5y1YcGVPFy8bEiCJi79kegF9igahmvDe5UrqswFvnpMJ",
            "symbol": "DSOL",
            "name": "DecentSol",
            "decimals": 4,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmfV1LNrqefadJQ7PzMvrTnio9GzsFLcbwRXAgVQad3ykt/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mkH52yU9xQ",
                "medium": "https://decentsol.medium.com",
                "twitter": "https://twitter.com/decentsol",
                "website": "https://home.decentsol/"
            }
        },
        {
            "chainId": 101,
            "address": "6foyeENL9GhqZEqPeaRK9YtP8HnFfej1JBwdn5rcsPDi",
            "symbol": "iM",
            "name": "iMentusCoin",
            "decimals": 0,
            "logoURI": "https://imentus.com/wp-content/uploads/2020/10/black_imentus_logo.png",
            "tags": [
                "dev-token"
            ],
            "extensions": {
                "website": "https://www.imentus.com"
            }
        },
        {
            "chainId": 101,
            "address": "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm",
            "symbol": "scnSOL",
            "name": "Socean staked SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm/logo.png",
            "tags": [
                "stake-pool"
            ],
            "extensions": {
                "coingeckoId": "socean-staked-sol",
                "discord": "https://discord.gg/k8ZcW27bq9",
                "medium": "https://medium.com/@soceanfinance",
                "serumV3Usdc": "D52sefGCWho2nd5UGxWd7wCftAzeNEMNYZkdEPGEdQTb",
                "twitter": "https://twitter.com/soceanfinance",
                "website": "https://socean.fi/"
            }
        },
        {
            "chainId": 103,
            "address": "FsrinjAhYaBKQieHhaJNGnepMS3RFHZJVjb1i26JhMdp",
            "symbol": "sBucks",
            "name": "SolBucks",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FxjbQMfvQYMtZZK7WGEJwWfsDcdMuuaee8uPxDFFShWh/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GHhDU9Y7HM37v6cQyaie1A3aZdfpCDp6ScJ5zZn2c3uk",
            "symbol": "SOL-pSOL",
            "name": "Mercurial LP Token (SOL-pSOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHhDU9Y7HM37v6cQyaie1A3aZdfpCDp6ScJ5zZn2c3uk/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "C64WgwmfCyuFeV1k8MP1gRMP6NPA1ve7QLivvCrVaJn",
            "symbol": "KRI",
            "name": "Kauri Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/Osawejustice/Kauri-Token/Kauri_logo32x32.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jeN7dhes9V",
                "instagram": "https://www.instagram.com/_museinc/",
                "telegram": "https://t.me/kauritoken",
                "twitter": "https://twitter.com/_museinc",
                "website": "https://www.museinc.studio"
            }
        },
        {
            "chainId": 101,
            "address": "H2EJUxt2KSPk7BWGZRfLMqh56wCmWygDJVTvjTJFHeym",
            "symbol": "ROLL",
            "name": "Let'sroll DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H2EJUxt2KSPk7BWGZRfLMqh56wCmWygDJVTvjTJFHeym/logo.png",
            "extensions": {
                "website": "ipfs://letsroll.dao"
            }
        },
        {
            "chainId": 101,
            "address": "6SuBPLC3vMTgfET5uoEhNoi5voYeBujVm7LS9kM3KX9s",
            "symbol": "FINN",
            "name": "FINNGRAM TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6SuBPLC3vMTgfET5uoEhNoi5voYeBujVm7LS9kM3KX9s/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.finngram.com"
            }
        },
        {
            "chainId": 101,
            "address": "BZrca9YNDtnshtsiD9GTvbMAXzZrSE6drxqNfxp5mpdc",
            "symbol": "KAKI",
            "name": "KAKI Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZrca9YNDtnshtsiD9GTvbMAXzZrSE6drxqNfxp5mpdc/logo.svg",
            "tags": [
                "dev-token"
            ],
            "extensions": {
                "website": "https://shunkakinoki.com"
            }
        },
        {
            "chainId": 101,
            "address": "6PwnEP2o5AnM29GDs2EiwfkQNuMoPiWokrLufSkJcVJR",
            "symbol": "Hose",
            "name": "Hose Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6PwnEP2o5AnM29GDs2EiwfkQNuMoPiWokrLufSkJcVJR/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://hose-coin.com"
            }
        },
        {
            "chainId": 101,
            "address": "4QV4wzDdy7S1EV6y2r9DkmaDsHeoKz6HUvFLVtAsu6dV",
            "symbol": "AGTE",
            "name": "Agronomist coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4QV4wzDdy7S1EV6y2r9DkmaDsHeoKz6HUvFLVtAsu6dV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "agronomist",
                "discord": "https://discord.gg/tR45QftB6K",
                "medium": "https://medium.com/@agronomist.tech",
                "serumV3Usdc": "Ci3wLTY3X9iuMxDGErSNwfWKcrhwPMugk8yWTGBvEzF",
                "twitter": "https://twitter.com/AgronomistTech",
                "website": "https://agronomist.tech"
            }
        },
        {
            "chainId": 101,
            "address": "5KB7WK1sB7WpoFXAiKoyhWCh44jHfTMtXDuvaSRQ4TR1",
            "symbol": "GIG",
            "name": "DecentGig Governance Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5KB7WK1sB7WpoFXAiKoyhWCh44jHfTMtXDuvaSRQ4TR1/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wKZhV5NtGA",
                "website": "https://github.com/solauto/decent-gig"
            }
        },
        {
            "chainId": 101,
            "address": "CJR5HtmXzpCD8Ro28zyZyLjz1wtrCsu7bEwC4f8ZjRCD",
            "symbol": "GEKZ",
            "name": "Gekz Arena",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJR5HtmXzpCD8Ro28zyZyLjz1wtrCsu7bEwC4f8ZjRCD/logo.png",
            "extensions": {
                "discord": "https://discord.gg/Mh29ZdQCGC",
                "twitter": "https://twitter.com/gekzarena",
                "website": "https://www.gekzarena.com/"
            }
        },
        {
            "chainId": 101,
            "address": "84QX2yE96Qmb984wGUcHLPT54a5bwjqo8zokCWPWez6d",
            "symbol": "INNO",
            "name": "INNOVATEK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/assets/mainnet/84QX2yE96Qmb984wGUcHLPT54a5bwjqo8zokCWPWez6d/logo.png",
            "extensions": {
                "website": "https://www.innovatek.us"
            }
        },
        {
            "chainId": 101,
            "address": "9JTriKH36nk7kQvK5V5TiVnuGQTqAJHRkX4kunGvZXfP",
            "symbol": "WHISP",
            "name": "Whispell",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9JTriKH36nk7kQvK5V5TiVnuGQTqAJHRkX4kunGvZXfP/logo.png",
            "extensions": {
                "website": "https://www.linkedin.com/in/williamwhispell/"
            }
        },
        {
            "chainId": 101,
            "address": "E28mvmaJa9LHLpJWiWsLd4eERL7w9j6uGAUwBWpH5UFd",
            "symbol": "SBULL",
            "name": "SolBull",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/kasshen/SolBull/SolBull_Logo.png",
            "tags": [
                "meme",
                "bullish"
            ]
        },
        {
            "chainId": 101,
            "address": "6JxHWpKwZjcnxjE9DZtaCEaoVNgpJzuBmrGQ6hmJ7DuM",
            "symbol": "PEPE",
            "name": "PEPE Coin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/matthew-github-123/pepetoken/frog.png",
            "extensions": {
                "twitter": "https://twitter.com/Pepe_Solana_SPL"
            }
        },
        {
            "chainId": 101,
            "address": "72FzkmpjqXQunY1UvrYDYhCj3mtPYExbWq7wFBSuxmHA",
            "symbol": "BOIT",
            "name": "BOIT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72FzkmpjqXQunY1UvrYDYhCj3mtPYExbWq7wFBSuxmHA/logo.png",
            "extensions": {
                "website": "https://boit.club"
            }
        },
        {
            "chainId": 101,
            "address": "4MawquNMLDQsyNmQwdwqa34YtWFiqSezNgFEbUvZgskM",
            "symbol": "USBL",
            "name": "Balanced Dollar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4MawquNMLDQsyNmQwdwqa34YtWFiqSezNgFEbUvZgskM/logo.png",
            "extensions": {
                "website": "https://softbalanced.com"
            }
        },
        {
            "chainId": 101,
            "address": "FTD9EisrsMt5TW5wSTMqyXLh2o7xTb6KNuTiXgHhw8Q8",
            "symbol": "PLAY",
            "name": "POLYPLAY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/assets/FTD9EisrsMt5TW5wSTMqyXLh2o7xTb6KNuTiXgHhw8Q8/logo.png",
            "extensions": {
                "coingeckoId": "polyplay",
                "discord": "https://discord.gg/TAgndUz2Fc",
                "twitter": "https://twitter.com/polyplaycoin",
                "website": "https://polyplay.net/"
            }
        },
        {
            "chainId": 101,
            "address": "CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn",
            "symbol": "SOLX",
            "name": "Soldex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn/logo.png",
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/soldex",
                "medium": "https://soldex.medium.com/",
                "serumV3Usdc": "97tCGtxWywJXkudCtderXZoQpTWXyWDQoLq13dqexScH",
                "twitter": "https://twitter.com/soldexai",
                "website": "https://soldex.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "J5gLhk6mmQ4PSoir1Ufh8JY2ytEHA93YupzYiTFVCgcL",
            "symbol": "FAROUT",
            "name": "Far-Out Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J5gLhk6mmQ4PSoir1Ufh8JY2ytEHA93YupzYiTFVCgcL/logo.png",
            "extensions": {
                "website": "https://gitlab.com/far-out/far-out-token"
            }
        },
        {
            "chainId": 101,
            "address": "Amig8TisuLpzun8XyGfC5HJHHGUQEscjLgoTWsCCKihg",
            "symbol": "tuUSDC",
            "name": "tuUSDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuUSDC.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "Am2kxXzFH84biqbswGWq2zieWqsX2ANnFDyiZr9Fh7zc",
            "symbol": "tuTULIP",
            "name": "tuTULIP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuTULIP.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "H4Q3hDbuMUw8Bu72Ph8oV2xMQ7BFNbekpfQZKS2xF7jW",
            "symbol": "tuSOL",
            "name": "tuSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuSOL.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "2yQJdxJy4tGeeXK2u8Lwdy9oY6Ks5shVH9gYtRH9zdDw",
            "symbol": "tuSNY",
            "name": "tuSNY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuSNY.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "GtFtWCcLYtWQT8NLRwEfUqc9sgVnq4SbuSnMCpwcutNk",
            "symbol": "tuSLRS",
            "name": "tuSLRS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuSLRS.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "8Lg7TowFuMQoGiTsLE6qV9x3czRgDmVy8f8Vv8KS4uW",
            "symbol": "tuRAY",
            "name": "tuRAY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuRAY.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "658FZo9B4HgKxsKsM7cUHN7jfNFgC7YftusWWYWc4piD",
            "symbol": "tuPOLIS",
            "name": "tuPOLIS",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuPOLIS.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "6fGTc455JK3bsiSrvyLkEymQasPDXdrw2jJR16UkPnT5",
            "symbol": "tuMEDIA",
            "name": "tuMEDIA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuMEDIA.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "DRu91PV94sb6kX6HwMGnGM8TuHrjycS4FmJNRWEgyw6n",
            "symbol": "tuLIKE",
            "name": "tuLIKE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuLIKE.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "C1gwRSpKFu4Vjrg9MhNfRjg65SV4CNLsb3C6d7kWFEyV",
            "symbol": "tuETH",
            "name": "tuETH",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuETH.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "8cm7UrBiDQ4C1ntQSCZfHSWKUizdW31ddTQGNY6Lym3B",
            "symbol": "tuCOPE",
            "name": "tuCOPE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuCOPE.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "9eGNc4BZCAgpTSEjbu7ACCLjpnZh1WSdts3y4nMik4e7",
            "symbol": "tuATLAS",
            "name": "tuATLAS",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuATLAS.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "FJtaAZd6tXNCFGTq7ifRHt9AWoVdads6gWNc4SXCPw1k",
            "symbol": "ALEPH",
            "name": "tuALEPH",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/main/tuALEPH.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp",
            "symbol": "ABR",
            "name": "Allbridge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/allbridge-io/media/main/token.svg",
            "extensions": {
                "coingeckoId": "allbridge",
                "medium": "https://allbridge.medium.com/",
                "telegram": "https://t.me/allbridge_announcements",
                "twitter": "https://twitter.com/Allbridge_io",
                "website": "https://allbridge.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6nuaX3ogrr2CaoAPjtaKHAoBNWok32BMcRozuf32s2QF",
            "symbol": "abBUSD",
            "name": "Wrapped BUSD (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "binance-usd"
            }
        },
        {
            "chainId": 101,
            "address": "AaAEw2VCw1XzgvKB8Rj2DyK2ZVau9fbt2bE8hZFWsMyE",
            "symbol": "aeWETH",
            "name": "Wrapped ETH (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "weth"
            }
        },
        {
            "chainId": 101,
            "address": "Bn113WT6rbdgwrm12UJtnmNqGqZjY4it2WoUQuQopFVn",
            "symbol": "aeUSDT",
            "name": "Wrapped USDT (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9",
            "symbol": "aeUSDC",
            "name": "Wrapped USDC (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "9w6LpS7RU1DKftiwH3NgShtXbkMM1ke9iNU4g3MBXSUs",
            "symbol": "aeDAI",
            "name": "Wrapped DAI (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "multi-collateral-dai"
            }
        },
        {
            "chainId": 101,
            "address": "EwxNF8g9UfmsJVcZFTpL9Hx5MCkoQFoJi6XNWzKf1j8e",
            "symbol": "acUSD",
            "name": "Wrapped CUSD (Allbridge from Celo)",
            "decimals": 9,
            "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7236.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "celo-dollar"
            }
        },
        {
            "chainId": 101,
            "address": "5h6ssFpeDeRbzsEHDbTQNH7nVGgsKrZydxdSTnLm6QdV",
            "symbol": "cSOL",
            "name": "Solend SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5h6ssFpeDeRbzsEHDbTQNH7nVGgsKrZydxdSTnLm6QdV/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk",
            "symbol": "cUSDC",
            "name": "Solend USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "CPDiKagfozERtJ33p7HHhEfJERjvfk1VAjMXAFLrvrKP",
            "symbol": "cETH",
            "name": "Solend ETH",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPDiKagfozERtJ33p7HHhEfJERjvfk1VAjMXAFLrvrKP/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "AppJPZka33cu4DyUenFe9Dc1ZmZ3oQju6mBn9k37bNAa",
            "symbol": "csoETH",
            "name": "Solend soETH",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AppJPZka33cu4DyUenFe9Dc1ZmZ3oQju6mBn9k37bNAa/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "Gqu3TFmJXfnfSX84kqbZ5u9JjSBVoesaHjfTsaPjRSnZ",
            "symbol": "cBTC",
            "name": "Solend BTC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gqu3TFmJXfnfSX84kqbZ5u9JjSBVoesaHjfTsaPjRSnZ/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "4CxGuD2NMr6zM8f18gr6kRhgd748pnmkAhkY1YJtkup1",
            "symbol": "cSRM",
            "name": "Solend SRM",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4CxGuD2NMr6zM8f18gr6kRhgd748pnmkAhkY1YJtkup1/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8",
            "symbol": "cUSDT",
            "name": "Solend USDT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "A38TjtcYrfutXT6nfRxhqwoGiXyzwJsGPmekoZYYmfgP",
            "symbol": "csoFTT",
            "name": "Solend soFTT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A38TjtcYrfutXT6nfRxhqwoGiXyzwJsGPmekoZYYmfgP/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "8bDyV3N7ctLKoaSVqUoEwUzw6msS2F65yyNPgAVUisKm",
            "symbol": "cFTT",
            "name": "Solend FTT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8bDyV3N7ctLKoaSVqUoEwUzw6msS2F65yyNPgAVUisKm/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "2d95ZC8L5XP6xCnaKx8D5U5eX6rKbboBBAwuBLxaFmmJ",
            "symbol": "cRAY",
            "name": "Solend RAY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2d95ZC8L5XP6xCnaKx8D5U5eX6rKbboBBAwuBLxaFmmJ/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "Bpm2aBL57uqVhgxutfRVrbtnjDpZLV8PZrRrytV1LgeT",
            "symbol": "cSBR",
            "name": "Solend SBR",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bpm2aBL57uqVhgxutfRVrbtnjDpZLV8PZrRrytV1LgeT/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "BsWLxf6hRJnyytKR52kKBiz7qU7BB3SH77mrBxNnYU1G",
            "symbol": "cMER",
            "name": "Solend MER",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BsWLxf6hRJnyytKR52kKBiz7qU7BB3SH77mrBxNnYU1G/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "5PHgMyZpEUCTeXQdb2ARm2KMZNu4rxzLXuhKwXtr8Xzc",
            "symbol": "STVA-USDC",
            "name": "Raydium LP Token (STVA-USDC)",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5PHgMyZpEUCTeXQdb2ARm2KMZNu4rxzLXuhKwXtr8Xzc/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "DxYAghKE5sCNFk5BDD8xzgxgdvRmoCzdYKawy7SfyZXq",
            "symbol": "PIGS",
            "name": "Diamond Pigs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DxYAghKE5sCNFk5BDD8xzgxgdvRmoCzdYKawy7SfyZXq/logo.png",
            "tags": [
                "IDO",
                "IDX",
                "utility-token"
            ],
            "extensions": {
                "website": "https://diamondpigs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p",
            "symbol": "LDO",
            "name": "Lido DAO Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
                "assetContract": "https://etherscan.io/address/0x5a98fcbea516cf06857215779fd812ca3bef1b32",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "lido-dao",
                "twitter": "https://twitter.com/LidoFinance",
                "website": "https://lido.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "48cR9mPuj33XowR4BX5nWtn6zqNP2rWjEAKURb6AcvkC",
            "symbol": "VLT",
            "name": "Vault Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/assets/48cR9mPuj33XowR4BX5nWtn6zqNP2rWjEAKURb6AcvkC/logo.png",
            "extensions": {
                "website": "https://vaultnft.org/"
            }
        },
        {
            "chainId": 101,
            "address": "AKAwZaP91svXuYTe2gD5JVmUZteDFrT4G92rMtrF1Wb4",
            "symbol": "WTTE",
            "name": "WATTTON Exchange",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKAwZaP91svXuYTe2gD5JVmUZteDFrT4G92rMtrF1Wb4/logo.png",
            "extensions": {
                "website": "https://wattton.org"
            }
        },
        {
            "chainId": 101,
            "address": "6GF5Gjptix8yCJeVjp6e8uYNAP5Y2Gnb1CqZ9ADEaLdu",
            "symbol": "TOPS",
            "name": "TOPSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6GF5Gjptix8yCJeVjp6e8uYNAP5Y2Gnb1CqZ9ADEaLdu/logo.png",
            "tags": [
                "utility-token",
                "commodity-token"
            ],
            "extensions": {
                "website": "https://topsol.finance"
            }
        },
        {
            "chainId": 101,
            "address": "Fh3As4AU6bSsj5HcFHFD1LigeXWdFCJicnaQ64h7RFn5",
            "symbol": "IPC",
            "name": "Imperial Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fh3As4AU6bSsj5HcFHFD1LigeXWdFCJicnaQ64h7RFn5/logo.png",
            "extensions": {
                "telegram": "https://t.me/imperialcoinOfficial",
                "twitter": "https://twitter.com/ImperialCoin_"
            }
        },
        {
            "chainId": 101,
            "address": "FG7x94jPcVbtt4pLXWhyr6sU3iWim8JJ2y215X5yowN5",
            "symbol": "FIS",
            "name": "StaFi Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FG7x94jPcVbtt4pLXWhyr6sU3iWim8JJ2y215X5yowN5/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/jB77etn",
                "medium": "https://stafi-protocol.medium.com",
                "telegram": "https://t.me/stafi_protocol",
                "twitter": "https://twitter.com/StaFi_Protocol",
                "website": "https://stafi.io"
            }
        },
        {
            "chainId": 101,
            "address": "7hUdUTkJLwdcmt3jSEeqx4ep91sm1XwBxMDaJae6bD5D",
            "symbol": "rSOL",
            "name": "StaFi rSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7hUdUTkJLwdcmt3jSEeqx4ep91sm1XwBxMDaJae6bD5D/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/jB77etn",
                "medium": "https://stafi-protocol.medium.com",
                "telegram": "https://t.me/stafi_protocol",
                "twitter": "https://twitter.com/StaFi_Protocol",
                "website": "https://stafi.io"
            }
        },
        {
            "chainId": 101,
            "address": "CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qAUDmuCaq",
            "symbol": "CPX",
            "name": "Circlepod Protocol Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qAUDmuCaq/logo.png",
            "extensions": {
                "discord": "https://discord.gg/4rTM9tRV8s",
                "twitter": "https://twitter.com/circlepodP",
                "website": "https://www.circlepod.app/"
            }
        },
        {
            "chainId": 101,
            "address": "GzN5Y1KoP6Yo6KYVYg7JfJ7Urs6oCrtLByHLeZ1ELAnx",
            "symbol": "ODC",
            "name": "OneDay Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GzN5Y1KoP6Yo6KYVYg7JfJ7Urs6oCrtLByHLeZ1ELAnx/logo.png",
            "extensions": {
                "website": "https://www.odccoin.com/",
                "whitepaper": "https://fbcfc5cd-4898-40bf-9870-db311c0095f0.filesusr.com/ugd/0a74e1_154571838e94457692909858d58f1f99.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "GEtb31uJbQ9ULCqGab7VooB4TDtgj1awLtapZtxhsB9Y",
            "symbol": "KSC",
            "name": "KISS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tribeland/token-list/main/assets/mainnet/GEtb31uJbQ9ULCqGab7VooB4TDtgj1awLtapZtxhsB9Y/logo.png",
            "extensions": {
                "website": "https://www.kisscrypto.net/",
                "whitepaper": "https://ae283fe9-fab9-4865-a65a-bcd5559ada09.filesusr.com/ugd/0a74e1_8d61680fa97d40568d9f7adba2eccfd7.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "6E8tJq85M64wqerfwBN6iYQGJPVcUFzgc8wKqc3tcKeD",
            "symbol": "YAT-PIR",
            "name": "Yet Another Token PIRIT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6E8tJq85M64wqerfwBN6iYQGJPVcUFzgc8wKqc3tcKeD/logo.png",
            "extensions": {
                "website": "http://n1pool.com/"
            }
        },
        {
            "chainId": 103,
            "address": "6E8tJq85M64wqerfwBN6iYQGJPVcUFzgc8wKqc3tcKeD",
            "symbol": "YAT-PIR",
            "name": "Yet Another Token PIRIT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6E8tJq85M64wqerfwBN6iYQGJPVcUFzgc8wKqc3tcKeD/logo.png",
            "extensions": {
                "website": "http://n1pool.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7duMWSNdYMof6WKZHs5X1wdmmxUa6cDGqqKShhMSGkgg",
            "symbol": "YAT-LAZ",
            "name": "Yet Another Token LAZURIT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7duMWSNdYMof6WKZHs5X1wdmmxUa6cDGqqKShhMSGkgg/logo.png",
            "extensions": {
                "website": "http://n1pool.com/"
            }
        },
        {
            "chainId": 103,
            "address": "7duMWSNdYMof6WKZHs5X1wdmmxUa6cDGqqKShhMSGkgg",
            "symbol": "YAT-LAZ",
            "name": "Yet Another Token LAZURIT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7duMWSNdYMof6WKZHs5X1wdmmxUa6cDGqqKShhMSGkgg/logo.png",
            "extensions": {
                "website": "http://n1pool.com/"
            }
        },
        {
            "chainId": 101,
            "address": "59XzU2M7YckoiNw7wUq54eeeg6Kw8gL5554eg2nToat",
            "symbol": "LDHTOIXG",
            "name": "LDHTOIXGCOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/59XzU2M7YckoiNw7wUq54eeeg6Kw8gL5554eg2nToat/logo.png",
            "extensions": {
                "website": "http://google.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FU93FVMNiphc8Jdh2jPHHQvZpwvL4obCELPBhkMnJLxh",
            "symbol": "RZZ",
            "name": "Razzmena compnay Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FU93FVMNiphc8Jdh2jPHHQvZpwvL4obCELPBhkMnJLxh/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://razzmena.com"
            }
        },
        {
            "chainId": 101,
            "address": "6xtyNYX6Rf4Kp3629X11m1jqUmkV89mf9xQakUtUQfHq",
            "symbol": "CHIH",
            "name": "CHIHUAHUA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ChihuahuaSol/Chihuahua/main/chihuahua-token.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "coingeckoId": "chihuahuasol",
                "discord": "https://discord.gg/cQMHepBqmc",
                "twitter": "https://twitter.com/ChihuahuaSol",
                "website": "https://chihuahuasol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9xkb4MSeD2WkJuio3EdGhEjNP5MuAp56scwKpiDNLtHc",
            "symbol": "JACKIE",
            "name": "Jackie Chan Fan Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xkb4MSeD2WkJuio3EdGhEjNP5MuAp56scwKpiDNLtHc/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/groups/jackiechanfanclubgroup",
                "telegram": "https://t.me/jackietoken",
                "twitter": "https://twitter.com/jackie_token",
                "website": "https://jackietoken.tk/"
            }
        },
        {
            "chainId": 101,
            "address": "8oMHsGMaeLLC77DdFYzernNS39oDT7cJ7Gq5o9ThcaFM",
            "symbol": "PLUS",
            "name": "PlusPlus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8oMHsGMaeLLC77DdFYzernNS39oDT7cJ7Gq5o9ThcaFM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://plusplus.is/"
            }
        },
        {
            "chainId": 101,
            "address": "DQP2edsDc4bApMaQ4pRim6AE18yCjHpohFLhnWbxc4um",
            "symbol": "wDAY",
            "name": "ChronoLogic DAY (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DQP2edsDc4bApMaQ4pRim6AE18yCjHpohFLhnWbxc4um/logo.png",
            "tags": [
                "ethereum",
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "coingeckoId": "chronologic",
                "medium": "https://blog.chronologic.network/",
                "telegram": "https://t.me/chronologicnetwork",
                "twitter": "https://twitter.com/ChronoLogicETH",
                "website": "https://chronologic.network/",
                "youtube": "http://www.youtube.com/c/ChronoLogic"
            }
        },
        {
            "chainId": 101,
            "address": "FAmdutSS9sTVoqTbw2JYrcns58ZfEozrgevgeZuZiyML",
            "symbol": "SOL-USDC",
            "name": "HydraSwap LP Token (SOL-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FAmdutSS9sTVoqTbw2JYrcns58ZfEozrgevgeZuZiyML/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HydraSwap_io",
                "website": "https://www.hydraswap.io"
            }
        },
        {
            "chainId": 101,
            "address": "5vVrn1ioAjAeCNSYhwA19CnPTSmcDuMPnB2wUFQ5hkeg",
            "symbol": "BTC-USDC",
            "name": "HydraSwap LP Token (BTC-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5vVrn1ioAjAeCNSYhwA19CnPTSmcDuMPnB2wUFQ5hkeg/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HydraSwap_io",
                "website": "https://www.hydraswap.io"
            }
        },
        {
            "chainId": 101,
            "address": "HRhugQTKnX5TK6dQUygwUr7rgCZmzJjk4CiAxZV3eaTk",
            "symbol": "ETH-USDC",
            "name": "HydraSwap LP Token (ETH-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRhugQTKnX5TK6dQUygwUr7rgCZmzJjk4CiAxZV3eaTk/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HydraSwap_io",
                "website": "https://www.hydraswap.io"
            }
        },
        {
            "chainId": 101,
            "address": "UgMdvGuY2HNMmCJQdY6aGty1yDxHBxm9Q9KJVefVjwJ",
            "symbol": "W technology",
            "name": "W",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/WBORSA/token-list/main/assets/mainnet/UgMdvGuY2HNMmCJQdY6aGty1yDxHBxm9Q9KJVefVjwJ/W%20Coin.png",
            "extensions": {
                "website": "https://app.w.systems/"
            }
        },
        {
            "chainId": 101,
            "address": "8EUyHq7ZVg7t9oFwYWtkiH1ybg5eXjKCGn7oc8FRXwDT",
            "symbol": "FLC",
            "name": "Feliz Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EUyHq7ZVg7t9oFwYWtkiH1ybg5eXjKCGn7oc8FRXwDT/logo.png",
            "extensions": {
                "website": "https://www.felizcoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "7R7rZ7SsLDXkYAfJyRCBScLuZwizeMWaTWrwFhSZU2Jq",
            "symbol": "WET",
            "name": "Weble Ecosystem Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7R7rZ7SsLDXkYAfJyRCBScLuZwizeMWaTWrwFhSZU2Jq/logo.png",
            "extensions": {
                "website": "https://wet.weble.ch/"
            }
        },
        {
            "chainId": 101,
            "address": "BFsCwfk8VsEbSfLkkgmoKsAPk2N6FMJjeTsuxfGa9VEf",
            "symbol": "aeFTT",
            "name": "Wrapped FTT (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3/logo.png",
            "extensions": {
                "coingeckoId": "ftx-token"
            }
        },
        {
            "chainId": 101,
            "address": "ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z",
            "symbol": "MDF",
            "name": "MatrixETF DAO Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "matrixetf",
                "telegram": "https://t.me/MatrixETF",
                "twitter": "https://twitter.com/MatrixETF",
                "website": "https://www.matrixetf.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "EfdM1aiUaoXHu3TdVAGYiyHKcvkZURjmxsfXWLa5LyTc",
            "symbol": "BRANE",
            "name": "Brane",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/elonsuk/BRANE-Token/master/Logo.png",
            "tags": [
                "BRANE",
                "BRANE-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E",
            "symbol": "WAG",
            "name": "Waggle Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E/logo.png",
            "extensions": {
                "medium": "https://medium.com/@wagglenetwork",
                "telegram": "https://t.me/waggle_network",
                "twitter": "https://twitter.com/wagglenetwork",
                "website": "https://waggle.network/"
            }
        },
        {
            "chainId": 101,
            "address": "DxWXDwbqNyXs4ABCRWAJU2Xi4xpYJLp3UxRhsu1jU6gs",
            "symbol": "LMS",
            "name": "LMS Stars",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/assets/DxWXDwbqNyXs4ABCRWAJU2Xi4xpYJLp3UxRhsu1jU6gs/logo.png",
            "tags": [
                "LMS"
            ],
            "extensions": {
                "website": "https://www.letmespeak.pro/"
            }
        },
        {
            "chainId": 101,
            "address": "uNrix3Q5g51MCEUrYBUEBDdQ96RQDQspQJJnnQ4T3Vc",
            "symbol": "SBNK",
            "name": "Solbank",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uNrix3Q5g51MCEUrYBUEBDdQ96RQDQspQJJnnQ4T3Vc/logo.png",
            "extensions": {
                "coingeckoId": "solblank",
                "twitter": "https://twitter.com/solbankapp",
                "website": "http://solbank.app/"
            }
        },
        {
            "chainId": 101,
            "address": "2KccNRqHQdnQ9WS8vLSo8uwVPpW7dojmMmZuDpwi6mKd",
            "symbol": "WESH",
            "name": "WeShare",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2KccNRqHQdnQ9WS8vLSo8uwVPpW7dojmMmZuDpwi6mKd/logo.png",
            "tags": [
                "tokenized-stock",
                "weshare",
                "social-token"
            ],
            "extensions": {
                "website": "https://www.we-share.online/"
            }
        },
        {
            "chainId": 101,
            "address": "EHrY9aueq55y7pWTcFJhCryNwJtAN14spL5UiG938RnV",
            "symbol": "KLAYG",
            "name": "Klay Games",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/KLAYGAMES/KlayGames/main/KlayLogo_64.png",
            "tags": [
                "game"
            ],
            "extensions": {
                "website": "https://klaygames.io/"
            }
        },
        {
            "chainId": 101,
            "address": "NJdK95TPKguYLUzhNPEumEbwC7cjciEQUzG4UrvhcJv",
            "symbol": "AINU",
            "name": "AvatarInu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NJdK95TPKguYLUzhNPEumEbwC7cjciEQUzG4UrvhcJv/logo.png",
            "tags": [
                "nft",
                "gaming"
            ],
            "extensions": {
                "website": "https://avatarinu.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2AVXRChvUAnyP3W3Psg4ZTY2w7KYbPy3vZ6FpS5c8ya6",
            "symbol": "BLACK",
            "name": "Black Freelancer",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2AVXRChvUAnyP3W3Psg4ZTY2w7KYbPy3vZ6FpS5c8ya6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.blackfreelancer.com",
                "instagram": "https://www.instagram.com/blackfreelancer",
                "twitter": "https://twitter.com/blackfreelancr",
                "website": "https://www.blackfreelancer.com"
            }
        },
        {
            "chainId": 101,
            "address": "GiKE9s8TMYdkWE28CzPDSYn42RK4AHZSxg7cthg1ntcn",
            "symbol": "SLTM",
            "name": "Soltomm F-token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GiKE9s8TMYdkWE28CzPDSYn42RK4AHZSxg7cthg1ntcn/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "medium": "https://soltomm.medium.com",
                "telegram": "https://t.me/soltomm",
                "twitter": "https://twitter.com/soltomm",
                "website": "https://www.soltomm.com"
            }
        },
        {
            "chainId": 101,
            "address": "8urbgQGLFvEScPKVbigbzcfU3BFHsZaVGZ7mhrMoaZcu",
            "symbol": "SWAB",
            "name": "Schwabendollar",
            "decimals": 2,
            "logoURI": "https://cdn.jsdelivr.net/gh/solana-labs/token-list@15773063f55394cf15353e7b4874c56dc66c97b6/assets/mainnet/8urbgQGLFvEScPKVbigbzcfU3BFHsZaVGZ7mhrMoaZcu/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/schwabendollar",
                "twitter": "https://twitter.com/schwabendollar",
                "website": "https://schwabendollar.de/"
            }
        },
        {
            "chainId": 101,
            "address": "BDNA1bZDCQYerXgjF9dcqeNcqBYKWQQN3z9QXypvQ9uV",
            "symbol": "BDNA1",
            "name": "Gold SolBull DNA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDNA1bZDCQYerXgjF9dcqeNcqBYKWQQN3z9QXypvQ9uV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wbkXkWQeex",
                "medium": "https://medium.com/@SolBulls",
                "twitter": "https://twitter.com/SolanaBullsNFT",
                "website": "https://solbulls.art"
            }
        },
        {
            "chainId": 101,
            "address": "BDNA2oi3W3TpMfbPMRoEzM55WdSajtyWnADkhsCW9p5f",
            "symbol": "BDNA2",
            "name": "White SolBull DNA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDNA2oi3W3TpMfbPMRoEzM55WdSajtyWnADkhsCW9p5f/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wbkXkWQeex",
                "medium": "https://medium.com/@SolBulls",
                "twitter": "https://twitter.com/SolanaBullsNFT",
                "website": "https://solbulls.art"
            }
        },
        {
            "chainId": 101,
            "address": "BDNA345whxSjPj1xBk7wobHfnv35qe7rJwX2zUnRMZMT",
            "symbol": "BDNA3",
            "name": "Grey SolBull DNA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDNA345whxSjPj1xBk7wobHfnv35qe7rJwX2zUnRMZMT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wbkXkWQeex",
                "medium": "https://medium.com/@SolBulls",
                "twitter": "https://twitter.com/SolanaBullsNFT",
                "website": "https://solbulls.art"
            }
        },
        {
            "chainId": 101,
            "address": "BDNA4xTPk6iVe2iuQe8931quH55XsZo3R97VwsgfUgK5",
            "symbol": "BDNA4",
            "name": "Brown SolBull DNA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDNA4xTPk6iVe2iuQe8931quH55XsZo3R97VwsgfUgK5/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wbkXkWQeex",
                "medium": "https://medium.com/@SolBulls",
                "twitter": "https://twitter.com/SolanaBullsNFT",
                "website": "https://solbulls.art"
            }
        },
        {
            "chainId": 101,
            "address": "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey",
            "symbol": "MNDE",
            "name": "Marinade",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey/logo.png",
            "extensions": {
                "coingeckoId": "marinade",
                "discord": "https://discord.gg/mGqZA5pjRN",
                "github": "https://github.com/marinade-finance",
                "medium": "https://medium.com/marinade-finance",
                "twitter": "https://twitter.com/MarinadeFinance",
                "website": "https://marinade.finance"
            }
        },
        {
            "chainId": 101,
            "address": "CzqJTjTp3R9opDHmzF5Gy1G2NKDHEyMufa4Keiju4PLu",
            "symbol": "wCCX",
            "name": "Wrapped Conceal",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/assets/CzqJTjTp3R9opDHmzF5Gy1G2NKDHEyMufa4Keiju4PLu/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "wrapped-conceal",
                "discord": "https://discord.conceal.network/",
                "twitter": "https://twitter.com/ConcealNetwork",
                "website": "https://conceal.network/"
            }
        },
        {
            "chainId": 101,
            "address": "FaYTnfmPK8uP4dvtECypG3ugCC3wQrG27pwkB1YkhXsG",
            "symbol": "DODG",
            "name": "DODG the Elephant Shrew",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FaYTnfmPK8uP4dvtECypG3ugCC3wQrG27pwkB1YkhXsG/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DODG_Sh"
            }
        },
        {
            "chainId": 101,
            "address": "soso1vCmdxwEZqU47M4NZ4MxZH19ppgqF1auG7dP3wz",
            "symbol": "SSL",
            "name": "sosol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/soso1vCmdxwEZqU47M4NZ4MxZH19ppgqF1auG7dP3wz/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sosol_app",
                "website": "https://sosol.app/"
            }
        },
        {
            "chainId": 101,
            "address": "zwUfHitWqsD722dAbFypW6vmsTi5pbFmGquRDdPKNUj",
            "symbol": "SEED",
            "name": "El Palito Coin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/grgkro/Schwabendollar@main/logo_elpalito.png",
            "extensions": {
                "facebook": "https://www.facebook.com/elpalitostuttgart",
                "instagram": "https://www.instagram.com/elpalito.de",
                "website": "https://www.elpalito.de/"
            }
        },
        {
            "chainId": 101,
            "address": "4hJ6sjwmsvvFag6TKL97yhWiBSDX9BABWoiXgb3EPXxB",
            "symbol": "GTON",
            "name": "Graviton",
            "decimals": 8,
            "logoURI": "https://v1.graviton.one/img/brand/download/graviton-logo/primary-logo-png.png",
            "extensions": {
                "blog": "https://medium.com/graviton-one",
                "coingeckoId": "graviton",
                "telegram": "https://t.me/graviton_community",
                "twitter": "https://twitter.com/OneGraviton",
                "website": "https://graviton.one"
            }
        },
        {
            "chainId": 101,
            "address": "4qv2AGjhzxiNz5iTUnTdRVYjjacmaEcVGgFcr1R6sRFe",
            "symbol": "NXMC",
            "name": "NextMindCoin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4qv2AGjhzxiNz5iTUnTdRVYjjacmaEcVGgFcr1R6sRFe/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/YXDNHMadC4",
                "github": "https://github.com/CEO-NextMindCoin",
                "telegram": "https://t.me/NextMindCoin_NXMC",
                "twitter": "https://twitter.com/nextmindcoin",
                "website": "https://www.nextmindcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X",
            "symbol": "DGLN",
            "name": "Dogelana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X/logo.png",
            "tags": [
                "dogecoin",
                "dogelana",
                "doge",
                "solana",
                "meme",
                "dgln"
            ],
            "extensions": {
                "blog": "https://dogelana.com/blog",
                "coingeckoId": "dogelana",
                "coinmarketcap": "https://coinmarketcap.com/currencies/dogelana/",
                "discord": "https://discord.gg/UNjbTguY5x",
                "facebook": "https://facebook.com/dogelana",
                "instagram": "https://www.instagram.com/realdogelana/",
                "reddit": "https://www.reddit.com/r/dogelana",
                "telegram": "https://t.me/realdogelana",
                "twitter": "https://twitter.com/realdogelana",
                "website": "https://www.dogelana.com",
                "youtube": "https://www.youtube.com/channel/UCkUS4exbXFaEVx5wBypMKaQ"
            }
        },
        {
            "chainId": 101,
            "address": "DTQbtBQUWC6cbw8TD83ma8SWy1oHux8stZsMrXWoyaLp",
            "symbol": "KPLR",
            "name": "KEPLER PAY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTQbtBQUWC6cbw8TD83ma8SWy1oHux8stZsMrXWoyaLp/logo.png",
            "tags": [
                "coin"
            ],
            "extensions": {
                "website": "https://wtmakers.com"
            }
        },
        {
            "chainId": 101,
            "address": "6KfDDXh4SFBEaUmL2JMYYQ9QETQL2PxowUucY1Vg3oe4",
            "symbol": "SUSD",
            "name": "Salvador USD",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/dsfjichi/susd/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "telegram": "https://t.me/salvadousd",
                "twitter": "https://twitter.com/SalvadorUsd"
            }
        },
        {
            "chainId": 101,
            "address": "8om7E9acx23C62yazUMbGaw5pmp48NmW7mnxGarn3XNi",
            "symbol": "GT",
            "name": "Good Terrier",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8om7E9acx23C62yazUMbGaw5pmp48NmW7mnxGarn3XNi/logo.png",
            "tags": [
                "Analytics",
                "Oracle",
                "Swap",
                "solana"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/YchEcgMgeC",
                "medium": "https://medium.com/goodterrier",
                "telegram": "https://t.me/goodterrier",
                "twitter": "https://twitter.com/Goodterrier",
                "website": "https://www.goodterrier.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9152xjhTyvDeVsRbbA82S5neQjwgkdqEyLTHqPtoRbnq",
            "symbol": "CATL",
            "name": "Cat Lover",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9152xjhTyvDeVsRbbA82S5neQjwgkdqEyLTHqPtoRbnq/logo.png",
            "tags": [
                "Community"
            ],
            "extensions": {
                "telegram": "https://t.me/catlovertoken",
                "website": "https://catlovertoken.tk/"
            }
        },
        {
            "chainId": 101,
            "address": "83LGLCm7QKpYZbX8q4W2kYWbtt8NJBwbVwEepzkVnJ9y",
            "symbol": "xUSD",
            "name": "Synthetic USD",
            "decimals": 6,
            "logoURI": "https://www.synthetify.io/icons/xusd.svg",
            "extensions": {
                "coingeckoId": "usd-coin",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HWxpSV3QAGzLQzGAtvhSYAEr7sTQugQygnni1gnUGh1D",
            "symbol": "xBTC",
            "name": "Synthetic BTC",
            "decimals": 10,
            "logoURI": "https://www.synthetify.io/icons/xbtc.svg",
            "extensions": {
                "coingeckoId": "bitcoin",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BdUJucPJyjkHxLMv6ipKNUhSeY3DWrVtgxAES1iSBAov",
            "symbol": "xSOL",
            "name": "Synthetic SOL",
            "decimals": 9,
            "logoURI": "https://www.synthetify.io/icons/xsol.svg",
            "extensions": {
                "coingeckoId": "solana",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Fr3W7NPVvdVbwMcHgA7Gx2wUxP43txdsn3iULJGFbKz9",
            "symbol": "xFTT",
            "name": "Synthetic FTT",
            "decimals": 8,
            "logoURI": "https://www.synthetify.io/icons/xftt.svg",
            "extensions": {
                "coingeckoId": "ftx-token",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8bqjz8DeSuim1sEAsQatjJN4zseyxSPdhHQcuuhL8PCK",
            "symbol": "xETH",
            "name": "Synthetic ETH",
            "decimals": 9,
            "logoURI": "https://www.synthetify.io/icons/xeth.svg",
            "extensions": {
                "coingeckoId": "ethereum",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "82Afat35Wr9v4fsZfSqGh8dnXFjxeaiQBfm5G9TK1BNj",
            "symbol": "xDOT",
            "name": "Synthetic DOT",
            "decimals": 6,
            "logoURI": "https://www.synthetify.io/icons/xdot.svg",
            "extensions": {
                "coingeckoId": "polkadot",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6MeoZEcUMhAB788YXTQN4x7K8MnwSt6RHWsLkuq9GJb2",
            "symbol": "xLUNA",
            "name": "Synthetic LUNA",
            "decimals": 6,
            "logoURI": "https://www.synthetify.io/icons/xluna.svg",
            "extensions": {
                "coingeckoId": "terra-luna",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HtxznfExBatdX28kMFDvmvU1rXVwiG3JSWcNPdFQ4PLh",
            "symbol": "xAVAX",
            "name": "Synthetic AVAX",
            "decimals": 6,
            "logoURI": "https://www.synthetify.io/icons/xavax.svg",
            "extensions": {
                "coingeckoId": "avalanche-2",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EAniGDVY2VKUtZxvpHnbazHfZgfo3bp61TxUGHzw3Cn7",
            "symbol": "007E",
            "name": "007 Exchange",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAniGDVY2VKUtZxvpHnbazHfZgfo3bp61TxUGHzw3Cn7/logo.png",
            "tags": [
                "Exchange"
            ],
            "extensions": {
                "telegram": "https://t.me/exchange_007",
                "twitter": "https://twitter.com/007Exchange",
                "website": "https://007exchange.tk/"
            }
        },
        {
            "chainId": 101,
            "address": "Hwv5ecNoHv5kNnQ2ungWN6PSq1pMq89McysSQtiELTTH",
            "symbol": "FBS",
            "name": "forbitspace",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hwv5ecNoHv5kNnQ2ungWN6PSq1pMq89McysSQtiELTTH/logo.png",
            "extensions": {
                "discord": "https://discord.gg/8tBuBPeZ",
                "github": "https://github.com/forbitspace",
                "medium": "https://medium.com/@forbitspace",
                "telegram": "https://t.me/forbitspace",
                "twitter": "https://twitter.com/forbitspace",
                "website": "https://forbitspace.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT",
            "symbol": "UXD",
            "name": "UXD Stablecoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT/uxd-icon-black.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "uxd-stablecoin",
                "discord": "https://discord.com/invite/BHfpYmjsBM",
                "medium": "https://uxdprotocol.medium.com/",
                "twitter": "https://twitter.com/UXDProtocol",
                "website": "https://uxd.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "DSmqf5Je3FEUmZntxBzG7c7EYJN3WK3feBV5Zgidukjj",
            "symbol": "USDC-wUSDC-wUSDT-wDAI",
            "name": "Mercurial LP Token (USDC-wUSDC-wUSDT-wDAI)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DSmqf5Je3FEUmZntxBzG7c7EYJN3WK3feBV5Zgidukjj/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM",
            "symbol": "USDCet",
            "name": "USD Coin (Portal from Ethereum)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                "assetContract": "https://etherscan.io/address/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M",
            "symbol": "USDCpo",
            "name": "USD Coin (Portal from Polygon)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
                "assetContract": "https://polygonscan.com/token/0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
                "bridgeContract": "https://polygonscan.com/address/0x5a58505a96d1dbf8df91cb21b54419fc36e93fde",
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "FLhkrAUE3kjwQwZPvAqDTAXULTgBUgjcAVtyzvwkcNrJ",
            "symbol": "MBB",
            "name": "Fraktionalized MBB 2793",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/0RPQq5Z_808sLjsjZ67__kFbQYdfQNaztLpBuDPKaEA?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/4q9s7Y3sAgbNi7AVwRSHJEnEWxugn8Fntk928rRsi6d9",
                "vaultPubkey": "4q9s7Y3sAgbNi7AVwRSHJEnEWxugn8Fntk928rRsi6d9"
            }
        },
        {
            "chainId": 101,
            "address": "Hk7P7ufaHe92Dx2Cmz6rSHT8RFj362kLYwMxJ9X5d7eF",
            "symbol": "MBB",
            "name": "Fraktionalized MBB 1007",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/piH41aGwPKOVs_idwQGU-wh278ZPNmJPpjchwt_gAEc?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/Ei5wPJtrKfLM9WD3Lj4Gmh31K2jsph4DtRAVH3oEJ2hi",
                "vaultPubkey": "Ei5wPJtrKfLM9WD3Lj4Gmh31K2jsph4DtRAVH3oEJ2hi"
            }
        },
        {
            "chainId": 101,
            "address": "Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1",
            "symbol": "USDTet",
            "name": "Tether USD (Portal from Ethereum)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
                "assetContract": "https://etherscan.io/address/0xdac17f958d2ee523a2206206994597c13d831ec7",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1",
            "symbol": "USDTpo",
            "name": "Tether USD (Portal from Polygon)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
                "assetContract": "https://polygonscan.com/token/0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
                "bridgeContract": "https://polygonscan.com/address/0x5a58505a96d1dbf8df91cb21b54419fc36e93fde",
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o",
            "symbol": "DAI",
            "name": "Dai Stablecoin (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
                "assetContract": "https://etherscan.io/address/0x6b175474e89094c44da98b954eedeac495271d0f",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "dai"
            }
        },
        {
            "chainId": 101,
            "address": "4Fo67MYQpVhZj9R7jQTd63FPAnWbPpaafAUxsMGX2geP",
            "symbol": "DAIpo",
            "name": "Dai Stablecoin (Portal from Polygon)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Fo67MYQpVhZj9R7jQTd63FPAnWbPpaafAUxsMGX2geP/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
                "assetContract": "https://polygonscan.com/token/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
                "bridgeContract": "https://polygonscan.com/address/0x5a58505a96d1dbf8df91cb21b54419fc36e93fde",
                "coingeckoId": "dai"
            }
        },
        {
            "chainId": 101,
            "address": "3U9pr23hLgoNWPVuKww3TfNutkqXXNQFMHm9cqZ3GfLT",
            "symbol": "DOJA",
            "name": "Doja Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/majesticio/doja-token/logo/dojapic-icon2.png",
            "tags": [
                "community",
                "sharing",
                "moonshot"
            ],
            "extensions": {
                "website": "https://dojatoken.contacthi.org"
            }
        },
        {
            "chainId": 101,
            "address": "72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ",
            "symbol": "HIMA",
            "name": "Himalayan Cat Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ/logo.png",
            "extensions": {
                "coingeckoId": "himalayan-cat-coin",
                "serumV3Usdc": "HCE4wQXApNyFBTK7gYa98QCYbshCz7EkH8axNz3ahvKc",
                "twitter": "https://twitter.com/himacoin",
                "website": "https://www.himalayancatcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GPz1MmYZG2NUi32nqyUjbVsVyG64mYV4EFUQaZDWwtWL",
            "symbol": "OOGA",
            "name": "ApeBucks",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GPz1MmYZG2NUi32nqyUjbVsVyG64mYV4EFUQaZDWwtWL/logo.svg"
        },
        {
            "chainId": 101,
            "address": "2DnEfD1hTYxmbkb5vHW3c67EXwu8gA845KYT6RCPDExp",
            "symbol": "WALLY",
            "name": "WALLY Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/wallysolana/WALLYIMAGE/main/WALLYlogos.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://allycareint.com"
            }
        },
        {
            "chainId": 101,
            "address": "4oV4HndNDY12bT7wEfEeQeVk9bVN5EEAc8jtG9ErJS2E",
            "symbol": "HIND",
            "name": "HINDCOIN",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oV4HndNDY12bT7wEfEeQeVk9bVN5EEAc8jtG9ErJS2E/HIND.png"
        },
        {
            "chainId": 101,
            "address": "ArzDDtxQaMdAJn2mkZ14cSA1MLqnaXhfCeXCVD8AEGeF",
            "symbol": "QBL",
            "name": "QIBLACOIN",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArzDDtxQaMdAJn2mkZ14cSA1MLqnaXhfCeXCVD8AEGeF/QBL.png"
        },
        {
            "chainId": 101,
            "address": "7Sg4VVktQo6kt6ePjeUcmiWEKptkW3U4WZVYv5L4HKbn",
            "symbol": "RBT",
            "name": "ROBTOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rabindrarajawat/Solana-blockchain/main/IMG_E8974.JPG"
        },
        {
            "chainId": 101,
            "address": "27FAF4JDSkV3MAWF4Tzc4rJsiNGyfGWP2wvoR4h73eBr",
            "symbol": "IV",
            "name": "IV WorldWide Entertainment Token",
            "decimals": 9,
            "logoURI": "https://github.com/bvdaakster/token-list/raw/9b15eb4f84e961016b01409d53979822ec1910db/assets/mainnet/27FAF4JDSkV3MAWF4Tzc4rJsiNGyfGWP2wvoR4h73eBr/token-logo.png",
            "extensions": {
                "website": "http://ivwwe.com"
            }
        },
        {
            "chainId": 101,
            "address": "ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj",
            "symbol": "FRKT",
            "name": "FRAKT Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "frakt-token",
                "coinmarketcap": "https://coinmarketcap.com/currencies/frakt-token/",
                "twitter": "https://twitter.com/FraktArt",
                "website": "https://frakt.art"
            }
        },
        {
            "chainId": 101,
            "address": "F5rdP7VxCDYy8xaAEksgLqUerCVty4BTe1CmoiCbmu7L",
            "symbol": "TPW",
            "name": "Fraktionalized Frakt-2795",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/1LMJ9lC00xVIbUv_XK4FuIQGwm87ua30iRskQslP9Go",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/5oDXsghEWBitmjGuy8w3MPnkGjZkNc5pjFv3wf9pg1QT",
                "vaultPubkey": "5oDXsghEWBitmjGuy8w3MPnkGjZkNc5pjFv3wf9pg1QT"
            }
        },
        {
            "chainId": 101,
            "address": "Gc2yWrkqBti7zeWVFD5JWHhj3ouWkAhw3YRg1btYJ5Vw",
            "symbol": "MMM",
            "name": "Fraktionalized Moment-492",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/YIxO-jtbg51k2auVaIg7L5RN4Ap0S1Vc-Jm_GrUcAgk?ext=jpeg",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/7D66e5MgEGN2BzYzogwtFNq6QNPqiFvKwD7Zx3fUytiY",
                "vaultPubkey": "7D66e5MgEGN2BzYzogwtFNq6QNPqiFvKwD7Zx3fUytiY"
            }
        },
        {
            "chainId": 101,
            "address": "2xQwcN3pQn7VvM4GnWMP8sBA2oPdDPtxnMNcLErZwTwh",
            "symbol": "SSF",
            "name": "Fraktionalized Silver Starfish",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/ZOt3SVdXPHbVJVr8H13wVo_G5gHBYxDipo6jaTA549w?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/6aVbY5fLCg5SqfLzZjp3Jo5BdTWBzS1ZuWfEiSuMTTFH",
                "vaultPubkey": "6aVbY5fLCg5SqfLzZjp3Jo5BdTWBzS1ZuWfEiSuMTTFH"
            }
        },
        {
            "chainId": 101,
            "address": "9jWgVR3Q3QjfmaXNiZ6jht2K43W7sqkn6tZFeoK9B48t",
            "symbol": "JRDN",
            "name": "Fraktionalized Triumphant",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/B1rrktxxGta7w34MkEuFoJQxDzKLPXlYtBcR_1TkQPQ?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/7uXCgfBQzL43hehzFhh8tBFTEggrcZJDfQRoRpgXNAeo",
                "vaultPubkey": "7uXCgfBQzL43hehzFhh8tBFTEggrcZJDfQRoRpgXNAeo"
            }
        },
        {
            "chainId": 101,
            "address": "DVPWKGLFHK73PwgKgTtW28iCZGewQdva2N5HeBLDorVJ",
            "symbol": "GOATS",
            "name": "GOATS",
            "decimals": 4,
            "logoURI": "https://www.solgoats.io/logo_full.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/eUwEbWw6ww",
                "serumV3Usdc": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
                "twitter": "https://twitter.com/TheGOATSociety_",
                "website": "https://www.solgoats.io"
            }
        },
        {
            "chainId": 101,
            "address": "DYegPLaJuNvicevUUoC77ek6Xfwi4s4Pabr8scLGopSU",
            "symbol": "SNG",
            "name": "Synergy",
            "decimals": 9,
            "logoURI": "https://synergyland.world/logo_G_100_100.png",
            "tags": [
                "synergy",
                "gaming",
                "nft"
            ],
            "extensions": {
                "website": "https://synergyland.world/"
            }
        },
        {
            "chainId": 101,
            "address": "3TMdBbnXKASdx9rBcZ5HQsyqCky7Gt2ea44gYB6Ro15A",
            "symbol": "SFOX",
            "name": "SOL FOX",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/Cryptodoggo97/Looptoken/logo.png",
            "tags": [
                "meme",
                "community",
                "gaming"
            ],
            "extensions": {
                "telegram": "https://t.me/Solfox_Official",
                "twitter": "https://twitter.com/SolFox_official",
                "website": "https://synergyland.world/"
            }
        },
        {
            "chainId": 101,
            "address": "BWe1ReuW5KjaUMZTdq3yPxUAkoBbvm6kaFG4cCvapX9p",
            "symbol": "IDRS",
            "name": "IDRS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BWe1ReuW5KjaUMZTdq3yPxUAkoBbvm6kaFG4cCvapX9p/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://rupiahdigital.com"
            }
        },
        {
            "chainId": 101,
            "address": "7fRXu5A3VSQHjhS2exrETCVK85jANRSh9utUuiJVoQc8",
            "symbol": "VER",
            "name": "Vera",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/umersurkhailas/spllogo/vbuck.png",
            "tags": [
                "vera-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CN2jeduMaHuehNZNGyyAfvo1DPmAK2zSiX1DHAwkBA8Y",
            "symbol": "VER",
            "name": "Vera",
            "decimals": 8,
            "logoURI": "https://dl.dropboxusercontent.com/s/1h1kbyfwhf9m8t3/vera-logo.png?dl=0",
            "tags": [
                "vera-token"
            ],
            "extensions": {
                "website": "http://veracurrency.com"
            }
        },
        {
            "chainId": 103,
            "address": "CN2jeduMaHuehNZNGyyAfvo1DPmAK2zSiX1DHAwkBA8Y",
            "symbol": "VER",
            "name": "Vera",
            "decimals": 8,
            "logoURI": "https://dl.dropboxusercontent.com/s/1h1kbyfwhf9m8t3/vera-logo.png?dl=0",
            "tags": [
                "vera-token"
            ],
            "extensions": {
                "website": "http://veracurrency.com"
            }
        },
        {
            "chainId": 101,
            "address": "1YDQ35V8g68FGvcT85haHwAXv1U7XMzuc4mZeEXfrjE",
            "symbol": "WGMI",
            "name": "WGMI NFT Collector's Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1YDQ35V8g68FGvcT85haHwAXv1U7XMzuc4mZeEXfrjE/logo.png",
            "tags": [
                "community",
                "NFT",
                "club",
                "wgmi",
                "wagmi"
            ],
            "extensions": {
                "discord": "https://discord.gg/yr4PpZNQXK",
                "twitter": "https://twitter.com/WGMIcc",
                "website": "https://wgmi.cc"
            }
        },
        {
            "chainId": 101,
            "address": "zp6ybEDFPzQqstgdw8eLfcV43JyfkTR4ty9CbmzQw6X",
            "symbol": "LARA",
            "name": "LARA Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zp6ybEDFPzQqstgdw8eLfcV43JyfkTR4ty9CbmzQw6X/logo.png",
            "tags": [
                "utility-token",
                "LARA"
            ]
        },
        {
            "chainId": 101,
            "address": "CAeZWe4RoK6fj6XHhLiynxZKV2tXqeS1kpTtP3JTWVXw",
            "symbol": "FBD",
            "name": "FBD Collectible Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAeZWe4RoK6fj6XHhLiynxZKV2tXqeS1kpTtP3JTWVXw/logo.png",
            "tags": [
                "rare",
                "value-token",
                "FBD",
                "FajCo"
            ]
        },
        {
            "chainId": 101,
            "address": "3USdfJ2KUKC1pS3itv2NuZUPYSgrMhdQPTCGXxpJkMSz",
            "symbol": "PNNX",
            "name": "Phennix Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3USdfJ2KUKC1pS3itv2NuZUPYSgrMhdQPTCGXxpJkMSz/logo.png",
            "tags": [
                "utility-token",
                "PNNX"
            ]
        },
        {
            "chainId": 101,
            "address": "FwEHs3kJEdMa2qZHv7SgzCiFXUQPEycEXksfBkwmS8gj",
            "symbol": "aaUSDT",
            "name": "Wrapped USDT (Allbridge from Avalanche)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr",
            "symbol": "aaUSDC",
            "name": "Wrapped USDC (Allbridge from Avalanche)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "EgQ3yNtVhdHz7g1ZhjfGbxhFKMPPaFkz8QHXM5RBZBgi",
            "symbol": "aaDAI",
            "name": "Wrapped DAI (Allbridge from Avalanche)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "multi-collateral-dai"
            }
        },
        {
            "chainId": 101,
            "address": "Fd8xyHHRjTvxfZrBirb6MaxSmrZYw99gRSqFUKdFwFvw",
            "symbol": "aaWBTC",
            "name": "Wrapped BTC (Allbridge from Avalanche)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png",
            "extensions": {
                "coingeckoId": "wrapped-bitcoin"
            }
        },
        {
            "chainId": 101,
            "address": "AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z",
            "symbol": "AVAX",
            "name": "AVAX (Allbridge from Avalanche)",
            "decimals": 9,
            "logoURI": "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png",
            "extensions": {
                "coingeckoId": "avalanche-2"
            }
        },
        {
            "chainId": 101,
            "address": "75L31F2cs2m9Y2MQtBowCTyzaaNyn2XeJ1ZMJ9YL4s96",
            "symbol": "FBDT",
            "name": "FBD Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75L31F2cs2m9Y2MQtBowCTyzaaNyn2XeJ1ZMJ9YL4s96/logo.png",
            "tags": [
                "rare",
                "collectible-token",
                "FBDT",
                "FBD"
            ]
        },
        {
            "chainId": 101,
            "address": "8ssTatx7m2x5zM24EbzvB9GFxFyUc9RCgpL5pXa2xWX8",
            "symbol": "STG",
            "name": "SOLARIS",
            "decimals": 2,
            "logoURI": "https://cdn.jsdelivr.net/gh/grgkro/token-list@6237aabe97cc75d24e472d5991f892dd696d8c66/assets/mainnet/8ssTatx7m2x5zM24EbzvB9GFxFyUc9RCgpL5pXa2xWX8/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/schwabendollar",
                "twitter": "https://twitter.com/schwabendollar",
                "website": "https://schwabendollar.de/"
            }
        },
        {
            "chainId": 101,
            "address": "BmLvq52WKMb5MYKLScay5V9C4Sh4E67zxvwLbU6i2vTR",
            "symbol": "HP",
            "name": "Honey Pot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BmLvq52WKMb5MYKLScay5V9C4Sh4E67zxvwLbU6i2vTR/logo.png",
            "tags": [
                "BugBear",
                "NFT"
            ],
            "extensions": {
                "description": "Bugbear favorite honey pot! Shiny gold honey, taste like money.",
                "imageUrl": "https://storage.googleapis.com/honeypotnft/honeypot.png",
                "serumV3Usdc": "EhABxYSBodQF7vz6D633cxCG7BSK1S6NrNNPVMSpKLct",
                "website": "https://www.thebugbear.com"
            }
        },
        {
            "chainId": 101,
            "address": "6X4jtyjKQmNx9zEPjzt1A3hcKEX7fi6BX3ruQ79sLa75",
            "symbol": "ATG",
            "name": "AbstergoAI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ATGxlabs/logo/main/ATGlogo.jpg",
            "tags": [
                "ATG",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AbstergoAI"
            }
        },
        {
            "chainId": 101,
            "address": "HzKX1FMvGaymSHxkwn9uvvSG4L3Ba9nvzMaGAdrPqXRQ",
            "symbol": "TRD",
            "name": "ToardoLandia",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/EdoardoBusetti/Solana_test@main/TRDlogo.jpg",
            "extensions": {
                "website": "https://toardolandia.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4xAPLtoJn7J7ALhLh7jz4unZRRDjCogNbnkJ2xhkYedo",
            "symbol": "ROFL",
            "name": "ROFLSTOMP TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4xAPLtoJn7J7ALhLh7jz4unZRRDjCogNbnkJ2xhkYedo/teamROFLtwitter.png",
            "tags": [
                "team-token",
                "roflstompers"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MW_ROFLSTOMPERS"
            }
        },
        {
            "chainId": 101,
            "address": "7bYdmHbEEGH9ZXmHdeo7fX9dxyZGGyh4w8oc13WU86CJ",
            "symbol": "PCRD",
            "name": "PubCred token",
            "decimals": 9,
            "logoURI": "https://cdn.pubcred.com/pc-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://pubcred.com"
            }
        },
        {
            "chainId": 101,
            "address": "nZhog7W722ieakdHxRp1pcHMZwvv1HUCW9Y1rPZHktE",
            "symbol": "NXT",
            "name": "NEXTSENSE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nZhog7W722ieakdHxRp1pcHMZwvv1HUCW9Y1rPZHktE/logo.png"
        },
        {
            "chainId": 101,
            "address": "HvdFyYDQLhz2KhVJew2bYo1cWaE7y8UY7E4yMVBL5uhw",
            "symbol": "GMAC",
            "name": "GMAC Unique Token",
            "decimals": 9,
            "logoURI": "https://www.slm.cloud/img/products.png",
            "tags": [
                "community",
                "utility-token",
                "club"
            ],
            "extensions": {
                "twitter": "https://twitter.com/gonzaloaraujoc",
                "website": "https://www.slm.cloud"
            }
        },
        {
            "chainId": 101,
            "address": "FriCEbw1V99GwrJRXPnSQ6su2TabHabNxiZ3VNsVFPPN",
            "symbol": "FRIES",
            "name": "Soltato FRIES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FriCEbw1V99GwrJRXPnSQ6su2TabHabNxiZ3VNsVFPPN/logo.png",
            "tags": [
                "nft",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Wkgh9Fvy84",
                "serumV3Usdc": "8DKD5dKmmwparxCkpV2BQFTxt164rfadK8kX3at6hWUB",
                "twitter": "https://twitter.com/Soltato_NFT",
                "website": "https://soltato.io/"
            }
        },
        {
            "chainId": 101,
            "address": "p1huPeR7J1o3DQdiV9yMpDiRc3grLXocrHiLpKifsBb",
            "symbol": "HIPP",
            "name": "HipsterCoin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/sunnybeach24/HIPSTER/hipp-logo.png",
            "tags": [
                "social-token",
                "innovation",
                "community",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.sunnybeach24.com"
            }
        },
        {
            "chainId": 101,
            "address": "AWz2TJfkvCX18RNaogJkx9DUAqGyPcwt6tMmh7PdaX58",
            "symbol": "DRM",
            "name": "DARUMA TOKEN",
            "decimals": 9,
            "logoURI": "https://unnxdjnntyhxaqlfmu4nyiw43cy6zvol2id3nz4a5ux4tnktbwiq.arweave.net/o1txpa2eD3BBZWU43CLc2LHs1cvSB7bngO0vybVTDZE"
        },
        {
            "chainId": 101,
            "address": "AKxR1NLTtPnsVcWwPSEGat1TC9da3Z2vX7sY4G7ZLj1r",
            "symbol": "PNT",
            "name": "PHANT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKxR1NLTtPnsVcWwPSEGat1TC9da3Z2vX7sY4G7ZLj1r/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "phant",
                "coinmarketcap": "https://coinmarketcap.com/currencies/phant/",
                "discord": "https://discord.gg/HsHwWGtuaz",
                "serumV3Usdc": "9GWz5exH52JAHhe4MyKmo96SRAJy96ZKhEpqrnbVzYqU",
                "twitter": "https://twitter.com/wecap_io",
                "website": "https://wecap.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9BYwEQ3aEDmnXaF8t4mPLCfRCiGzBBVPWcZAL6KYJxRx",
            "symbol": "APHD",
            "name": "Applehead",
            "decimals": 9,
            "logoURI": "https://applehead.finance/wp-content/uploads/2021/10/Applehead.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://applehead.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "54s1cUvcF5CuMhomJ65A3PFn6RASixP3J96taku6w6PP",
            "symbol": "SQD",
            "name": "Squid Game Invitation",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54s1cUvcF5CuMhomJ65A3PFn6RASixP3J96taku6w6PP/logo.png",
            "tags": [
                "tokenized-invitation"
            ]
        },
        {
            "chainId": 101,
            "address": "3vQ58RPSjGqpKRXpDnrMpKRry4ZeQcBPmhzkVZSZ2kGs",
            "symbol": "BHCC",
            "name": "The Beverly Hills Car Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vQ58RPSjGqpKRXpDnrMpKRry4ZeQcBPmhzkVZSZ2kGs/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/bevhillscarclub",
                "website": "https://beverlyhillscarclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HPcpwJ5arSHjJDYGmJQYCuHKDfWLqjdmRrb6bhadRkxG",
            "symbol": "ILB",
            "name": "ILearnBLock",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/marvy-codes/ILB-TOKEN/ilb.jpg",
            "tags": [
                "utility-token",
                "value-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "https://instagram.com/ilearnblock",
                "twitter": "https://twitter.com/ilearnblock"
            }
        },
        {
            "chainId": 103,
            "address": "8aN9XWUhD3Q7qY4p4uR1GrNbL8H3fF9tssk9VdvXD1Vp",
            "symbol": "KDSL",
            "name": "KOKILA DILSHAN SL",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/KOKILADILSHANSL/KDSLtoken/logo.png"
        },
        {
            "chainId": 103,
            "address": "5BQmj9wDdsVpwYuEKchWit2PafJkJ1tbacKogCLF4hJh",
            "symbol": "ENEC",
            "name": "ENEC",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/alexminden/logo/main/enec-logo/main_logo.png"
        },
        {
            "chainId": 101,
            "address": "5xnRrqoyoLBixNwjVet6Xb2ZTyBSXhENyUWj4sqzRGrv",
            "symbol": "QUID",
            "name": "Quid Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/quidtoken/main/quidlogo.png",
            "tags": [
                "social-token",
                "quid"
            ],
            "extensions": {
                "telegram": "https://t.me/quidtoken",
                "twitter": "https://twitter.com/quidtoken",
                "website": "https://www.quidtoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "yvbrxE6zjrA8SxxSpL7oojDBB5QDmF5CVqJWea8JcQE",
            "symbol": "CODI",
            "name": "CODI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yvbrxE6zjrA8SxxSpL7oojDBB5QDmF5CVqJWea8JcQE/logo.png",
            "tags": [
                "Infrastructure",
                "DeFi"
            ],
            "extensions": {
                "coingeckoId": "codi-finance",
                "discord": "https://discord.gg/npA4RDGv",
                "twitter": "https://twitter.com/codi_finance",
                "website": "https://codi.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "CLg4LWp4p3BfZf5VpE1kaEDv7a8bEf9ELLXAd7xGANmb",
            "symbol": "CRB",
            "name": "Carbon Farm",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/CarbonFarm/CRBtoken/logo.png",
            "tags": [
                "Farming",
                "NFT"
            ],
            "extensions": {
                "github": "https://github.com/CarbonFarm",
                "medium": "https://carbonfarm.medium.com/",
                "reddit": "https://www.reddit.com/user/CarbonFarm",
                "telegram": "https://t.me/carbonfarm",
                "twitter": "https://twitter.com/farmcarbonmore",
                "website": "https://www.farmcarbonmore.com/"
            }
        },
        {
            "chainId": 101,
            "address": "84kYHLFYpBL1bcXXV6VhLAWDVMphejeVdeWqYqsxARcW",
            "symbol": "HNF",
            "name": "HoneyFarmer",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/CarbonFarm/HNFtoken/logo.png",
            "tags": [
                "Farming",
                "NFT"
            ],
            "extensions": {
                "github": "https://github.com/CarbonFarm",
                "medium": "https://medium.com/@CarbonFarm",
                "reddit": "https://www.reddit.com/user/CarbonFarm",
                "telegram": "https://t.me/carbonfarm",
                "twitter": "https://twitter.com/farmcarbonmore",
                "website": "https://www.farmcarbonmore.com/"
            }
        },
        {
            "chainId": 101,
            "address": "28x5iW6faLFiLqY5mshtP6wggXChyshdFaLcuvj72HgS",
            "symbol": "DCW",
            "name": "DairyCow",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/CarbonFarm/DCWtoken/logo.png",
            "tags": [
                "Farming",
                "NFT"
            ],
            "extensions": {
                "github": "https://github.com/CarbonFarm",
                "medium": "https://medium.com/@CarbonFarm",
                "reddit": "https://www.reddit.com/user/CarbonFarm",
                "telegram": "https://t.me/carbonfarm",
                "twitter": "https://twitter.com/farmcarbonmore",
                "website": "https://www.farmcarbonmore.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GjTiVo5ajziFkK27YETD6jXo7femDkRak9yVpofZfqQ8",
            "symbol": "BRB",
            "name": "BarnBunny",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/CarbonFarm/BRBtoken/logo.png",
            "tags": [
                "Farming",
                "NFT"
            ],
            "extensions": {
                "github": "https://github.com/CarbonFarm",
                "medium": "https://medium.com/@CarbonFarm",
                "reddit": "https://www.reddit.com/user/CarbonFarm",
                "telegram": "https://t.me/carbonfarm",
                "twitter": "https://twitter.com/farmcarbonmore",
                "website": "https://www.farmcarbonmore.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G4fsgHkjDzVBSPUicgL5kzEjFSd1EAysP7fCdzidAyzo",
            "symbol": "Book",
            "name": "BookWorm Token",
            "decimals": 1,
            "logoURI": "https://pcarvalho.com/nftbookworm/logo.png",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction"
            ],
            "extensions": {
                "website": "http://nftbookworm.com/"
            }
        },
        {
            "chainId": 103,
            "address": "J9JkoZFdi31nJAcSniPMemfneJ7AL2iMYZkrEC9yvTDK",
            "symbol": "Book",
            "name": "BookWorm Test Token",
            "decimals": 1,
            "logoURI": "https://pcarvalho.com/nftbookworm/logo.png",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction"
            ],
            "extensions": {
                "website": "https://nftbookworm.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AErxrfertfwzFANXPpgpKkgBPY5kMZzRsafBU3a5mFw6",
            "symbol": "LSTR",
            "name": "Lstars",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AErxrfertfwzFANXPpgpKkgBPY5kMZzRsafBU3a5mFw6/logo.png"
        },
        {
            "chainId": 101,
            "address": "3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA",
            "symbol": "FRNT",
            "name": "Final Frontier",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://instagram.com/finalfrontiersa",
                "twitter": "https://twitter.com/FinalFrontierSA",
                "website": "https://finalfrontiersa.com/"
            }
        },
        {
            "chainId": 101,
            "address": "D1Kyn6tyKQPy3QZTvjKpavEPAWZXqQUH8q9Fc4ZWKHnN",
            "symbol": "$BOJACK",
            "name": "Bojack Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D1Kyn6tyKQPy3QZTvjKpavEPAWZXqQUH8q9Fc4ZWKHnN/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "meta-verse"
            ],
            "extensions": {
                "website": "https://bojackhorsecoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "9ubSPNJoTjbvo1aVArBBXHqoTXxdHKvNGiDnHWmskdv7",
            "symbol": "$ALPHA",
            "name": "Alpha NFT Token",
            "decimals": 0,
            "logoURI": "https://github.com/alpha-sol/alpha-frontend/blob/main/resources/logo.png",
            "tags": [
                "art",
                "art-token",
                "generative-art",
                "community",
                "community-token",
                "utility-token",
                "meta-verse",
                "dao",
                "governance"
            ],
            "extensions": {
                "website": "https://alphagen.gallery/"
            }
        },
        {
            "chainId": 101,
            "address": "Du8zr5ydoqcu5LQutEjr55RUjvmHLpPQKtncAMFhahsV",
            "symbol": "DCXa",
            "name": "Diverse Capital of Asiatic Exchanges",
            "decimals": 9,
            "logoURI": "https://dcxa.s3.amazonaws.com/static/icon.png",
            "tags": [
                "technology",
                "tech-token",
                "DeFi",
                "community",
                "community-token",
                "utility-token",
                "meta-verse",
                "dao",
                "governance"
            ],
            "extensions": {
                "website": "https://www.dcxa.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4q19vhpG6y4ZeMPLQiUNNaJStb8XivCFQy4m4mdnVnQZ",
            "symbol": "stSOL-SOL",
            "name": "Mercurial LP Token (stSOL-2Pool)",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4q19vhpG6y4ZeMPLQiUNNaJStb8XivCFQy4m4mdnVnQZ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP",
            "symbol": "AURY",
            "name": "Aurory",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "aurory",
                "description": "Aurory Token",
                "website": "https://aurory.io"
            }
        },
        {
            "chainId": 101,
            "address": "xAURp5XmAG7772mfkSy6vRAjGK9JofYjc3dmQDWdVDP",
            "symbol": "xAURY",
            "name": "Aurory",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xAURp5XmAG7772mfkSy6vRAjGK9JofYjc3dmQDWdVDP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Aurory Token",
                "website": "https://aurory.io/staking"
            }
        },
        {
            "chainId": 103,
            "address": "BXKro6nDX9y86rtGn6uh6K1rZUqENzsUHP6gAbdJj1NS",
            "symbol": "AKAYA",
            "name": "AKAYA_Bistro_NFT_Test",
            "decimals": 6,
            "logoURI": "https://musa.fyi/images/akaya.jpg",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "website": "https://www.instagram.com/akaya.bh/"
            }
        },
        {
            "chainId": 101,
            "address": "5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT",
            "symbol": "DOGO",
            "name": "Dogemon",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT/logo.png",
            "tags": [
                "GameFi",
                "NFT",
                "DogemonGo"
            ],
            "extensions": {
                "website": "https://dogemongo.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FNAqSGbG4mtPgqrSFbAVMSYXteuefe6BUnWRURUqSkeg",
            "symbol": "BAIS",
            "name": "BAIS Game",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNAqSGbG4mtPgqrSFbAVMSYXteuefe6BUnWRURUqSkeg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/BAISGame",
                "telegram": "https://t.me/BAISGame",
                "twitter": "https://twitter.com/BaisGame",
                "website": "https://baisgame.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8Ap9nTGPGJ1VYbMCE64f7yUTCptKk717Cns1ZfrqvdjE",
            "symbol": "HOL",
            "name": "HOLONA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Ap9nTGPGJ1VYbMCE64f7yUTCptKk717Cns1ZfrqvdjE/logo.png",
            "extensions": {
                "reddit": "https://www.reddit.com/u/HolonaNetwork",
                "telegram": "https://t.me/joinchat/UFpWIQwYE-kwYzA0",
                "twitter": "https://twitter.com/HolonaNetwork",
                "website": "https://holona.net",
                "youtube": "https://youtube.com/channel/UCSTEasbK8OSsvOk2NR6RmCQ"
            }
        },
        {
            "chainId": 101,
            "address": "BpK8nx5ygQaaFHnJyQ96mZePRvh74woxCNRT7CkjY81T",
            "symbol": "ME",
            "name": "ME Tokenized",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BpK8nx5ygQaaFHnJyQ96mZePRvh74woxCNRT7CkjY81T/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/metoken2"
            }
        },
        {
            "chainId": 101,
            "address": "6XYRoRykEKjfdv94jgt4b9bkLgQfoTYjGcCx1WXAh7mm",
            "symbol": "OBOL",
            "name": "OBOL Token",
            "decimals": 0,
            "logoURI": "https://github.com/altaga/Kharon-Protocol/blob/main/ObolLogo.png",
            "tags": [
                "IoT-token"
            ],
            "extensions": {
                "website": "https://kharonprotocol.com"
            }
        },
        {
            "chainId": 101,
            "address": "BJDDAfxaiZh9cW4UxSKpsK2BWJfe65TwTi72brKHzCm6",
            "symbol": "EDTH",
            "name": "Edith Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BJDDAfxaiZh9cW4UxSKpsK2BWJfe65TwTi72brKHzCm6/logo.png",
            "tags": [
                "technology",
                "DeFi",
                "community",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://edithtoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "94112Trifp3c5rycChpjDuwouSby7Yu7g8bbYsK4PV5Y",
            "symbol": "NVS",
            "name": "Novisium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94112Trifp3c5rycChpjDuwouSby7Yu7g8bbYsK4PV5Y/logo.png",
            "extensions": {
                "instagram": "https://www.instagram.com/novisium_",
                "telegram": "https://t.me/novisiumofficial",
                "twitter": "https://twitter.com/novisium_",
                "website": "https://novisium.com"
            }
        },
        {
            "chainId": 101,
            "address": "99M5AUeqcfiaY6gc5SCbCSToygpRV98PuGBJRiUzNTJ2",
            "symbol": "ADJ",
            "name": "Andrew Jackson Coin",
            "decimals": 9,
            "logoURI": "https://ircurrency.org/adj-coin-logo.png",
            "tags": [
                "adj",
                "inflation",
                "resistant",
                "currency",
                "DeFi",
                "crypto",
                "community"
            ],
            "extensions": {
                "discord": "https://discord.gg/4cxWg5Jmxt",
                "website": "https://ircurrency.org/"
            }
        },
        {
            "chainId": 101,
            "address": "E6H5zSHB1cqLW8V7ypkhdQv2bDrZTnTKSYxWfgL8UMTm",
            "symbol": "CAVIAR",
            "name": "Caviar Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/somethingsfishy/caviar/caviar.png",
            "tags": [
                "NFT",
                "inflation",
                "resistant",
                "utility-token",
                "community-token",
                "community"
            ],
            "extensions": {
                "discord": "https://discord.gg/zjtV3EjwUf",
                "twitter": "https://twitter.com/fishynftmob",
                "website": "https://somethingsfishy.art/"
            }
        },
        {
            "chainId": 101,
            "address": "BDy7QrC6JR987ZszfKCjTGwSSAcGEd9mRcDC2TSqNDkF",
            "symbol": "LSO",
            "name": "Les Sables d'Olonne Crypto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nguilbaud/token-list/main/assets/mainnet/BDy7QrC6JR987ZszfKCjTGwSSAcGEd9mRcDC2TSqNDkF/logo.png",
            "tags": [
                "tourism",
                "technology",
                "community",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.lessablesdolonne.fr/"
            }
        },
        {
            "chainId": 101,
            "address": "zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn",
            "symbol": "SOLPAY",
            "name": "SOLPAY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nguilbaud/token-list/main/assets/mainnet/zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn/logo.png",
            "tags": [
                "DeFi",
                "technology",
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solpayfinance",
                "twitter": "https://twitter.com/SolPayDeFi",
                "website": "https://solpay.finance"
            }
        },
        {
            "chainId": 101,
            "address": "HmRpcXedRLmWaRt5iFjsPUfoSTViZgHNSmU5RUmJFZu5",
            "symbol": "ENEC",
            "name": "ENEC",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/alexminden/logo/main/enec-logo/main_logo.png"
        },
        {
            "chainId": 101,
            "address": "4mkDXzvwMoP11MtKkgMfZW28xbdwY1gsMBz9N6pxdLbU",
            "symbol": "GGLD",
            "name": "GreenGoldCoin",
            "decimals": 4,
            "logoURI": "https://www.greengoldcoin.com/public/GreenGoldCoin-logo-128.png",
            "tags": [
                "utility-token",
                "payment-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/GreenGoldCoin/",
                "instagram": "https://www.instagram.com/explore/tags/greengoldcoin",
                "linkedin": "https://www.linkedin.com/company/40953073",
                "telegram": "https://t.me/greengoldcoinICO",
                "twitter": "https://twitter.com/GreenGoldCoins",
                "website": "https://www.greengoldcoin.com",
                "youtube": "https://www.youtube.com/channel/UCtUgroJeJEWx6VUuV_2ooPQ"
            }
        },
        {
            "chainId": 101,
            "address": "EE5L8cMU4itTsCSuor7NLK6RZx6JhsBe8GGV3oaAHm3P",
            "symbol": "AVDO",
            "name": "AvocadoCoin",
            "decimals": 8,
            "logoURI": "http://avocadocoin.com/public/AvocadoCoin-logo-128.png",
            "tags": [
                "utility-token",
                "payment-token",
                "hybrid-token",
                "agro-token",
                "IoT-token"
            ],
            "extensions": {
                "coingeckoId": "avocadocoin",
                "coinmarketcap": "https://coinmarketcap.com/currencies/avocadocoin/",
                "facebook": "https://www.facebook.com/avocadocoins/",
                "instagram": "https://www.instagram.com/avocadocoin",
                "telegram": "https://t.me/AvocadoCoinOfficial",
                "twitter": "https://twitter.com/avocadocoin",
                "website": "http://www.avocadocoin.com",
                "youtube": "https://www.youtube.com/channel/UCnMds055w0M3-_4GDjJsUKw"
            }
        },
        {
            "chainId": 103,
            "address": "FQxi8FaHaLtFzW1bZK3zuq2bWFxbJeeiUaUD5WY5oE17",
            "symbol": "LORDA",
            "name": "Lord Arena",
            "decimals": 9,
            "logoURI": "https://lordarena.com/public/filemanager/userfiles/logo-lordarena-png-min@2x.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "website": "https://lordarena.com"
            }
        },
        {
            "chainId": 103,
            "address": "EW1fpr5t8rge8wSaxFztPjmNMBeb4knaW6pcHtrQhZSt",
            "symbol": "SMILE",
            "name": "Smiles",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/oboxodo/solana-tokens/main/assets/devnet/EW1fpr5t8rge8wSaxFztPjmNMBeb4knaW6pcHtrQhZSt/smile-logo-256.png"
        },
        {
            "chainId": 101,
            "address": "G1NChRwNJG8BJAPfRCzq7t1aH5UTjdytCEGBDbQHCYcE",
            "symbol": "EPC",
            "name": "Elliptic",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G1NChRwNJG8BJAPfRCzq7t1aH5UTjdytCEGBDbQHCYcE/logo.png"
        },
        {
            "chainId": 101,
            "address": "5sBG2b32gk3jsd5azCK4Xs8jH9V6szz3vm9fi7v2cRrC",
            "symbol": "CPR",
            "name": "Crypto Republic",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5sBG2b32gk3jsd5azCK4Xs8jH9V6szz3vm9fi7v2cRrC/logo.png",
            "extensions": {
                "website": "https://cryptorepublictoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7HqhfUqig7kekN8FbJCtQ36VgdXKriZWQ62rTve9ZmQ",
            "symbol": "mSOL-SOL",
            "name": "Mercurial LP Token (mSOL-2Pool)",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7HqhfUqig7kekN8FbJCtQ36VgdXKriZWQ62rTve9ZmQ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "6xnQiCheV7U6wNZRjKPjan8qpE69yRzSPRb742KSHZYe",
            "symbol": "SPK",
            "name": "SPL Token",
            "decimals": 6,
            "logoURI": "https://dl.dropboxusercontent.com/s/j9e71gm4dwitxgp/token.png?dl=0",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "53K4DK7D5vY8wmi3dxNphekBETQHFhqpa2VPwR4DQjvj",
            "symbol": "SOLINU",
            "name": "SOLINU",
            "decimals": 9,
            "logoURI": ""
        },
        {
            "chainId": 101,
            "address": "AYb1hhPDFxGADUGKbCfe7qUvyHct3ucqvkmCS65y2HtA",
            "symbol": "AKT",
            "name": "Alexey Kulikov Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/pobegov/ak-token/logo.png"
        },
        {
            "chainId": 101,
            "address": "AWEqvJZhJzD8oJ3MacpbG3mvS4nTgCUBFSjxVuwqetXK",
            "symbol": "GPUT",
            "name": "GUTA Parallel Universe Token",
            "decimals": 9,
            "logoURI": "https://github.com/GAtuBAN/GPUT/blob/main/Gput.png",
            "tags": [
                "lgame-fi-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kemp5JZZVc"
            }
        },
        {
            "chainId": 101,
            "address": "3kHwk8N4c3j1Jtq28v5sFpvX6DBBcB6L4D6xqq6LhULh",
            "symbol": "GGG",
            "name": "Good Games Guild",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3kHwk8N4c3j1Jtq28v5sFpvX6DBBcB6L4D6xqq6LhULh/logo.png",
            "tags": [
                "GameFi",
                "NFT",
                "dao",
                "utility-token",
                "meta-verse",
                "governance"
            ],
            "extensions": {
                "discord": "https://discord.gg/x47U9qkR5K",
                "medium": "https://medium.com/@goodgamesguild/",
                "telegram": "https://t.me/GoodGamesGuildChat",
                "twitter": "https://twitter.com/goodgamesguild",
                "website": "https://goodgamesguild.com"
            }
        },
        {
            "chainId": 101,
            "address": "GmwiyYf1Biz9XjgofNBMdKSTgz134pmHWPhBanAFfDfa",
            "symbol": "ENT",
            "name": "Entropy",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GmwiyYf1Biz9XjgofNBMdKSTgz134pmHWPhBanAFfDfa/logo.png",
            "extensions": {
                "discord": "https://discord.gg/dVjvdgwvf4",
                "medium": "https://medium.com/@entropyofficial",
                "telegram": "https://t.me/entropy_official",
                "twitter": "https://twitter.com/entropy_dex",
                "website": "https://www.entropy.gold"
            }
        },
        {
            "chainId": 101,
            "address": "DTn6z1ikPcKa62KHeP7wFgSrq2NvCC1zEUqyJvTdso17",
            "symbol": "PHX",
            "name": "Phinx",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTn6z1ikPcKa62KHeP7wFgSrq2NvCC1zEUqyJvTdso17/logo.png"
        },
        {
            "chainId": 101,
            "address": "21cpwEpusR6gR65T3ymiJx16VS7M8VCqRTY4XjQbLBwh",
            "symbol": "FISH",
            "name": "CATPUNK TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/minh-git/docusaurus-2/main/catpunk.png",
            "tags": [
                "NFT",
                "dao",
                "utility-token",
                "meta-verse",
                "governance"
            ],
            "extensions": {
                "discord": "https://discord.gg/catpunk",
                "twitter": "https://twitter.com/ZodiLabOfficial",
                "website": "https://catpunk.net"
            }
        },
        {
            "chainId": 101,
            "address": "Ee9zTuGPX1YpAnpgPm5pi2juy8NrGQnky5RspvFh8JfE",
            "symbol": "ATT",
            "name": "AlterToken",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/brunofaczz/alter-logo/logo.png"
        },
        {
            "chainId": 101,
            "address": "2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd",
            "symbol": "MSI",
            "name": "Matrix Solana Index",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "matrix-solana-index",
                "telegram": "https://t.me/MatrixETF",
                "twitter": "https://twitter.com/MatrixETF",
                "website": "https://www.matrixetf.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "2fNw2cs23CV2X6JFA4CprbNbk3GcctFH8FcXSDPBkd7X",
            "symbol": "FISH",
            "name": "CATPUNK TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/minh-git/docusaurus-2/main/catpunk.png",
            "tags": [
                "NFT",
                "dao",
                "utility-token",
                "meta-verse",
                "governance"
            ],
            "extensions": {
                "discord": "https://discord.gg/catpunk",
                "twitter": "https://twitter.com/ZodiLabOfficial",
                "website": "https://catpunk.net"
            }
        },
        {
            "chainId": 101,
            "address": "3Cm1DDX9K9emkjjHoJJKXwwcDpREGxRzLyNgMSCy49zB",
            "symbol": "CHEESE",
            "name": "Cheese",
            "decimals": 9,
            "logoURI": "https://assets.solmice.com/file/solmice/cheese/images/757105.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolmiceNFT",
                "website": "https://solmice.com"
            }
        },
        {
            "chainId": 101,
            "address": "2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq",
            "symbol": "SAO",
            "name": "Sator",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq/Sator_Logo.png",
            "tags": [
                "NFT",
                "soical-token",
                "utility-token",
                "meta-verse"
            ],
            "extensions": {
                "coingeckoId": "sator",
                "github": "https://github.com/SatorNetwork",
                "medium": "https://satortoken.medium.com",
                "telegram": "https://t.me/SatorSAO",
                "twitter": "https://twitter.com/SatorSAO",
                "website": "https://sator.io"
            }
        },
        {
            "chainId": 101,
            "address": "5ENUvV3Ur3o3Fg6LVRfHL4sowidiVTMHHsEFqNJXRz6o",
            "symbol": "LIZARD",
            "name": "Lizard Token",
            "decimals": 4,
            "logoURI": "http://lizardtoken.xyz/gallery/lizard_logo-ts1634859660.jpg",
            "extensions": {
                "coingeckoId": "lizard-token",
                "discord": "https://discord.gg/zMvCkck2k2",
                "twitter": "https://twitter.com/Lizard_Token",
                "website": "http://www.lizardToken.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9yqPadcWQQ4BnuEbEZci1M5pTQYV8LX1HvckYwXoACdL",
            "symbol": "MYCTY",
            "name": "My Crypto City",
            "decimals": 6,
            "logoURI": "http://mycryptocity.net/wp-content/uploads/2021/10/cropped-HDLog2.png",
            "tags": [
                "utility-token",
                "game",
                "play2earn"
            ],
            "extensions": {
                "telegram": "https://t.me/mycryptocity",
                "twitter": "https://twitter.com/MyCrypto_City",
                "website": "https://mycryptocity.net/"
            }
        },
        {
            "chainId": 101,
            "address": "DhuzmUvC4YTRDJwFfPSynDCGaJcMotnqjdNcGeFFrQSD",
            "symbol": "PAD",
            "name": "Lilypad",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DhuzmUvC4YTRDJwFfPSynDCGaJcMotnqjdNcGeFFrQSD/logo.png",
            "extensions": {
                "discord": "https://discord.gg/DFdyP9saU6",
                "twitter": "https://twitter.com/HypnoFrogzNFT"
            }
        },
        {
            "chainId": 101,
            "address": "4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t",
            "symbol": "CAVE",
            "name": "Crypto Cavemen",
            "decimals": 6,
            "logoURI": "https://ftoblquxiunjey7bu4eevlz2u7kwg5s3yvou7adgge2jbihcu7qq.arweave.net/LNwVwpdFGpJj4acISq86p9VjdlvFXU-AZjE0kKDip-E/?ext=png",
            "tags": [
                "utility-token",
                "game",
                "play2earn"
            ],
            "extensions": {
                "coingeckoId": "cave",
                "discord": "https://discord.com/invite/cryptocavemen",
                "twitter": "https://twitter.com/TheCavemenClub",
                "website": "https://cryptocavemen.io"
            }
        },
        {
            "chainId": 101,
            "address": "GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD",
            "symbol": "GOFX",
            "name": "GooseFX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD/logo.png",
            "tags": [
                "NFT",
                "utility-token",
                "dao",
                "governance"
            ],
            "extensions": {
                "coingeckoId": "goosefx",
                "coinmarketcap": "https://coinmarketcap.com/currencies/goosefx/",
                "discord": "https://discord.gg/cDEPXpY26q",
                "serumV3Usdc": "2wgi2FabNsSDdb8dke9mHFB67QtMYjYa318HpSqyJLDD",
                "telegram": "https://t.me/goosefx",
                "twitter": "https://twitter.com/GooseFX1",
                "website": "https://goosefx.io"
            }
        },
        {
            "chainId": 101,
            "address": "EzfgjvkSwthhgHaceR3LnKXUoRkP6NUhfghdaHAj1tUv",
            "symbol": "FTT",
            "name": "FTX Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzfgjvkSwthhgHaceR3LnKXUoRkP6NUhfghdaHAj1tUv/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
                "assetContract": "https://etherscan.io/address/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "ftx-token",
                "serumV3Usdc": "2wteg25ch227n4Rh1CN4WNrDZXBpRBpWJ48mEC2K7f4r",
                "serumV3Usdt": "BoHojHESAv4McZx9gXd1bWTZMq25JYyGz4qL1m5C3nvk"
            }
        },
        {
            "chainId": 101,
            "address": "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i",
            "symbol": "UST",
            "name": "UST (Portal)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png",
            "tags": [
                "wrapped",
                "wormhole",
                "stablecoin"
            ],
            "extensions": {
                "address": "uusd",
                "bridgeContract": "https://finder.terra.money/columbus-5/address/terra10nmmwe8r3g99a9newtqa7a75xfgs2e8z87r2sf",
                "coingeckoId": "terrausd",
                "serumV3Usdc": "8WmckvEoVGZvtN8knjdzFGbWJ3Sr4BcWdyzSYuCrD4YK"
            }
        },
        {
            "chainId": 101,
            "address": "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
            "symbol": "ETH",
            "name": "Ether (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                "assetContract": "https://etherscan.io/address/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "ethereum",
                "serumV3Usdc": "8Gmi2HhZmwQPVdCwzS7CM66MGstMXPcTVHA7jF19cLZz",
                "serumV3Usdt": "ch7kmPrtoQUSEPBggcNAvLGiMQkJagVwd3gDYfd8m7Q"
            }
        },
        {
            "chainId": 101,
            "address": "xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG",
            "symbol": "SRMet",
            "name": "Serum (Portal from Ethereum)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x476c5e26a75bd202a9683ffd34359c0cc15be0ff",
                "assetContract": "https://etherscan.io/address/0x476c5e26a75bd202a9683ffd34359c0cc15be0ff",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "serum"
            }
        },
        {
            "chainId": 101,
            "address": "F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W",
            "symbol": "LUNA",
            "name": "LUNA (Portal)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "uluna",
                "bridgeContract": "https://finder.terra.money/columbus-5/address/terra10nmmwe8r3g99a9newtqa7a75xfgs2e8z87r2sf",
                "coingeckoId": "terra-luna",
                "serumV3Usdc": "HBTu8hNaoT3VyiSSzJYa8jwt9sDGKtJviSwFa11iXdmE"
            }
        },
        {
            "chainId": 101,
            "address": "KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE",
            "symbol": "AVAX",
            "name": "AVAX (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
                "assetContract": "https://snowtrace.io/token/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
                "bridgeContract": "https://snowtrace.io/address/0x0e082f06ff657d94310cb8ce8b0d9a04541d8052",
                "coingeckoId": "avalanche-2",
                "serumV3Usdc": "E8JQstcwjuqN5kdMyUJLNuaectymnhffkvfg1j286UCr"
            }
        },
        {
            "chainId": 101,
            "address": "7VQo3HFLNH5QqGtM8eC3XQbPkJUu7nS9LeGWjerRh5Sw",
            "symbol": "HUSD",
            "name": "HUSD (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7VQo3HFLNH5QqGtM8eC3XQbPkJUu7nS9LeGWjerRh5Sw/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xdf574c24545e5ffecb9a659c229253d4111d87e1",
                "assetContract": "https://etherscan.io/address/0xdf574c24545e5ffecb9a659c229253d4111d87e1",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "husd"
            }
        },
        {
            "chainId": 101,
            "address": "33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX",
            "symbol": "BUSDet",
            "name": "Binance USD (Portal from Ethereum)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4fabb145d64652a948d72533023f6e7a623c7c53",
                "assetContract": "https://etherscan.io/address/0x4fabb145d64652a948d72533023f6e7a623c7c53",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "binance-usd"
            }
        },
        {
            "chainId": 101,
            "address": "FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp",
            "symbol": "FRAX",
            "name": "Frax (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x853d955acef822db058eb8505911ed77f175b99e",
                "assetContract": "https://etherscan.io/address/0x853d955acef822db058eb8505911ed77f175b99e",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "frax"
            }
        },
        {
            "chainId": 101,
            "address": "7dVH61ChzgmN9BwG4PkzwRP8PbYwPJ7ZPNF2vamKT2H8",
            "symbol": "HBTC",
            "name": "Huobi BTC (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dVH61ChzgmN9BwG4PkzwRP8PbYwPJ7ZPNF2vamKT2H8/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0316eb71485b0ab14103307bf65a021042c6d380",
                "assetContract": "https://etherscan.io/address/0x0316eb71485b0ab14103307bf65a021042c6d380",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "huobi-btc"
            }
        },
        {
            "chainId": 101,
            "address": "43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F",
            "symbol": "USDK",
            "name": "USDK (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1c48f86ae57291f7686349f12601910bd8d470bb",
                "assetContract": "https://etherscan.io/address/0x1c48f86ae57291f7686349f12601910bd8d470bb",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "usdk"
            }
        },
        {
            "chainId": 101,
            "address": "ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj",
            "symbol": "SUSHI",
            "name": "SushiToken (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
                "assetContract": "https://etherscan.io/address/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "sushi",
                "serumV3Usdc": "3uWVMWu7cwMnYMAAdtsZNwaaqeeeZHARGZwcExnQiFay",
                "serumV3Usdt": "T3aC6qcPAJtX1gqkckfSxBPdPWziz5fLYRt5Dz3Nafq"
            }
        },
        {
            "chainId": 101,
            "address": "8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36",
            "symbol": "UNI",
            "name": "Uniswap (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
                "assetContract": "https://etherscan.io/address/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "uniswap",
                "serumV3Usdc": "B7b5rjQuqQCuGqmUBWmcCTqaL3Z1462mo4NArqty6QFR",
                "serumV3Usdt": "FrKM6kJtAjXknHPEpkrQtJSXZwUxV5dq26wDpc4YjQST"
            }
        },
        {
            "chainId": 101,
            "address": "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
            "symbol": "BNB",
            "name": "Binance Coin (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
                "assetContract": "https://bscscan.com/address/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
                "bridgeContract": "https://bscscan.com/address/0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7",
                "coingeckoId": "binance-coin",
                "serumV3Usdc": "4UPUurKveNEJgBqJzqHPyi8DhedvpYsMXi7d43CjAg2f",
                "serumV3Usdt": "FjbKNZME5yVSC1R3HJM99kB3yir3q3frS5MteMFD72sV"
            }
        },
        {
            "chainId": 101,
            "address": "2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX",
            "symbol": "LINK",
            "name": "ChainLink Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x514910771af9ca656af840dff83e8264ecf986ca",
                "assetContract": "https://etherscan.io/address/0x514910771af9ca656af840dff83e8264ecf986ca",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "chainlink",
                "serumV3Usdc": "FJMjxMCiDKn16TLhXUdEbVDH5wC6k9EHYJTcrH6NcbDE",
                "serumV3Usdt": "Gr2KmhK7Upr4uW56B1QQrJuhhgmot6zAHJeZALTMStiX"
            }
        },
        {
            "chainId": 101,
            "address": "C6oFsE8nXRDThzrMEQ5SxaNFGKoyyfWDDVPw37JKvPTe",
            "symbol": "PAXG",
            "name": "Paxos Gold (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C6oFsE8nXRDThzrMEQ5SxaNFGKoyyfWDDVPw37JKvPTe/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x45804880de22913dafe09f4980848ece6ecbaf78",
                "assetContract": "https://etherscan.io/address/0x45804880de22913dafe09f4980848ece6ecbaf78",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "pax-gold",
                "serumV3Usdc": "BeyB6W2iNsH9qSfb7icLTmSPDu8oUGkarMZed4Unrnsr",
                "serumV3Usdt": "9SQcpBFAs6ZiLAGUC9azYCN1kv89uTRmFR83sX1FTeh4"
            }
        },
        {
            "chainId": 101,
            "address": "HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK",
            "symbol": "HXRO",
            "name": "Hxro (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3",
                "assetContract": "https://etherscan.io/address/0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "hxro",
                "serumV3Usdc": "CBb5zXwNRB73WVjs2m21P5prcEZa6SWmej74Vzxh8dRm",
                "serumV3Usdt": "3BScwNxtMrEcQ5VTHyXHYQR98dTaxfyXGaLkuSjBY1dW"
            }
        },
        {
            "chainId": 101,
            "address": "3CyiEDRehaGufzkpXJitCP5tvh7cNhRqd9rPBxZrgK5z",
            "symbol": "SXP",
            "name": "Swipe (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CyiEDRehaGufzkpXJitCP5tvh7cNhRqd9rPBxZrgK5z/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9",
                "assetContract": "https://etherscan.io/address/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "swipe",
                "serumV3Usdc": "G5F84rfqmWqzZv5GBpSn8mMwW8zJ2B4Y1GpGupiwjHNM",
                "serumV3Usdt": "2FQbPW1ticJz2SMMbEXxbKWJKmw1wLc6ggSP2HyzdMen"
            }
        },
        {
            "chainId": 101,
            "address": "6LX8BhMQ4Sy2otmAWj7Y5sKd9YTVVUgfMsBzT6B9W7ct",
            "symbol": "FXS",
            "name": "Frax Share (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6LX8BhMQ4Sy2otmAWj7Y5sKd9YTVVUgfMsBzT6B9W7ct/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
                "assetContract": "https://etherscan.io/address/0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "frax-share"
            }
        },
        {
            "chainId": 101,
            "address": "nRtfwU9G82CSHhHGJNxFhtn7FLvWP2rqvQvje1WtL69",
            "symbol": "CEL",
            "name": "Celsius (Portal)",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nRtfwU9G82CSHhHGJNxFhtn7FLvWP2rqvQvje1WtL69/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d",
                "assetContract": "https://etherscan.io/address/0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "celsius-network-token",
                "serumV3Usdc": "79ESpYSb2hM14KTRXPZUwDkxUGC5irE2esd1vxdXfnZz",
                "serumV3Usdt": "J9ww1yufRNDDbUbDXmew2mW2ozkx7cme7dMvKjMQVHrL"
            }
        },
        {
            "chainId": 101,
            "address": "HihxL2iM6L6P1oqoSeiixdJ3PhPYNxvSKH9A2dDqLVDH",
            "symbol": "CREAM",
            "name": "Cream (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HihxL2iM6L6P1oqoSeiixdJ3PhPYNxvSKH9A2dDqLVDH/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x2ba592f78db6436527729929aaf6c908497cb200",
                "assetContract": "https://etherscan.io/address/0x2ba592f78db6436527729929aaf6c908497cb200",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "cream",
                "serumV3Usdc": "4pdQ2D4gehMhGu4z9jeQbEPUFbTxB5qcPr3zCynjJGyp",
                "serumV3Usdt": "6fspxMfBmYFTGFBDN5MU33A55i2MkGr7eSjBLPCAU6y9"
            }
        },
        {
            "chainId": 101,
            "address": "6rbtgebh89nx2vd9EbzcTMhQS8pKUG9ggoGEijuMFUbC",
            "symbol": "SHEIN",
            "name": "Sheincoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6rbtgebh89nx2vd9EbzcTMhQS8pKUG9ggoGEijuMFUbC/logo.png",
            "tags": [
                "utility-token",
                "game",
                "play2earn",
                "meta-verse",
                "shein"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SheinVerse",
                "website": "https://sheinverse.com"
            }
        },
        {
            "chainId": 101,
            "address": "CrUHen2BpEpXfMtU1KZFYEHNZwhxCMovACdaEC7Q5zkB",
            "symbol": "NFB",
            "name": "NFBet",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CrUHen2BpEpXfMtU1KZFYEHNZwhxCMovACdaEC7Q5zkB/logo.png",
            "tags": [
                "utility-token",
                "governance-token",
                "gaming",
                "NFTs"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nfbetofficial",
                "website": "https://nfbet.io"
            }
        },
        {
            "chainId": 101,
            "address": "ZXS6KKnXeT9xAc3qt2wpwmfKT5p3UspFVKCPP8bVs2Z",
            "symbol": "TMI",
            "name": "TUMI",
            "decimals": 4,
            "logoURI": "https://cointumi.com/token.png",
            "tags": [
                "utility-token",
                "governance-token",
                "gaming",
                "NFTs"
            ],
            "extensions": {
                "telegram": "https://t.me/cointumi",
                "website": "https://cointumi.com"
            }
        },
        {
            "chainId": 101,
            "address": "3k6zY8YUQsVPiXHh8Ncfw9BgMBcQzKq2B5TJfmSDbMZr",
            "symbol": "PEP",
            "name": "SolPepper",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/pepper-ts1635027628.jpg",
            "extensions": {
                "website": "https://solpepper.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "5hownqSTYjtGJi1u117siKxLXBEAMhkRZaVBM21rwh86",
            "symbol": "DOG",
            "name": "DogSol",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/dog-ts1635027588.png",
            "extensions": {
                "website": "https://dogsol.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "CmjegnBmHaEN2wbHTemVr1xwfgN61JZBgbtLkAa3WHM8",
            "symbol": "SILVER",
            "name": "SilverSol",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/silver-ts1635027635.png",
            "extensions": {
                "website": "https://silversol.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "DjDBpTJdatCrfR4XRWgKiQ8WY6K6RNuMsyTKAQ8rK9Rp",
            "symbol": "BRD",
            "name": "SolBird",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/bird-ts1635027934.png",
            "extensions": {
                "website": "https://solbird.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "BfbhLmrhtELjfFzrtcxpB1GoTpmiVK8qcpSYf7AM914h",
            "symbol": "BIRD",
            "name": "SolBird2",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/bird2-ts1635029482.png",
            "extensions": {
                "website": "https://solbird2.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "7pFo8CrTJuQFxRaTJT7k2TEQFGMijcGjQpcc4hFcmco1",
            "symbol": "CAT",
            "name": "SolCat",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/cat-ts1635027580.jpg",
            "extensions": {
                "website": "https://solcat.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "3Wup5AtKjDki1yX75WZuzGbqrNJTmLPvVPMwWEhBNKES",
            "symbol": "BLISS",
            "name": "SolFlower",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/flower-ts1635027605.jpg",
            "extensions": {
                "website": "https://solflower.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "A8fqp3MkJnDH9L5UzUdckfv2HPAzpqPbpFMRmnYGkZsj",
            "symbol": "DRGN",
            "name": "DragonSol",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/dragon-ts1635027595.jpg",
            "extensions": {
                "website": "https://dragonsol.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "7TXxsfjYt8gR1XZh9vZZNRxhA4t2VxtYbsy9JWHRjFhJ",
            "symbol": "MOON",
            "name": "MoonSol",
            "decimals": 4,
            "logoURI": "https://lizardtoken.xyz/gallery/moon-ts1635027621.jpg",
            "extensions": {
                "website": "https://moonsol.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "E1zxRweqCWzviAraKjNjqupuyYTzm1bukJgb8KiBN1sN",
            "symbol": "GOLD",
            "name": "SolGold",
            "decimals": 5,
            "logoURI": "https://lizardtoken.xyz/gallery/gold-ts1635027612.jpg",
            "extensions": {
                "website": "https://solgold.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "E5H5mHzUA8pRSL4X2ovv4sZMSorYk56EtCbQExQveRGJ",
            "symbol": "MPPLC",
            "name": "MoppelCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5H5mHzUA8pRSL4X2ovv4sZMSorYk56EtCbQExQveRGJ/logo.png",
            "tags": [
                "coin"
            ],
            "extensions": {
                "website": "https://sites.google.com/view/moppelcoin/startseite"
            }
        },
        {
            "chainId": 101,
            "address": "9xtsYLJjGhwKHxoZf1XU519bwSzwC4gf3XSVy9wZLpNz",
            "symbol": "STSNP",
            "name": "StarSnuppy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xtsYLJjGhwKHxoZf1XU519bwSzwC4gf3XSVy9wZLpNz/logo.png",
            "tags": [
                "coin"
            ],
            "extensions": {
                "website": "https://starsnuppy.wordpress.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BiJQPSEhHKQwMy5n9k66TWhDzXBjSNWTdTEfua164jXF",
            "symbol": "JTSN",
            "name": "Jetson",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/8zcfqM3/jetson.jpg",
            "tags": [
                "coin",
                "AI",
                "IoT",
                "Automation"
            ],
            "extensions": {
                "website": "https://www.jetson.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "9bQXrgpNYf9V2QgH6z8diP5e96c18REcDQXd87XCPkZw",
            "symbol": "BOLELD",
            "name": "BLD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/assets/9bQXrgpNYf9V2QgH6z8diP5e96c18REcDQXd87XCPkZw/logo.png",
            "extensions": {
                "website": "https://boleld.newboletoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "FLpjpb5hLKBeTneMPe2KVocDxoZCg1Xz7d9ekKZJ2e2n",
            "symbol": "LAMP",
            "name": "Lamp",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/assets/FLpjpb5hLKBeTneMPe2KVocDxoZCg1Xz7d9ekKZJ2e2n/logo.png",
            "tags": [
                "social-token",
                "lamp",
                "few"
            ],
            "extensions": {
                "website": "https://lamp.church"
            }
        },
        {
            "chainId": 101,
            "address": "3CKQgrcvwhvFqVXXxLTb1u262nh26SJ3uutkSCTtbZxH",
            "symbol": "TRBL",
            "name": "Tribeland Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tribeland/token-list/main/assets/mainnet/3CKQgrcvwhvFqVXXxLTb1u262nh26SJ3uutkSCTtbZxH/logo.png",
            "tags": [
                "game",
                "utility-token",
                "nft",
                "gaming",
                "metaverse"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tribe_land",
                "website": "https://tribeland.io"
            }
        },
        {
            "chainId": 101,
            "address": "67LPcnaWesD3LirJn4hyaTF5HVcEGivA2iHvTQgd23TU",
            "symbol": "OMG",
            "name": "OMEGA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/67LPcnaWesD3LirJn4hyaTF5HVcEGivA2iHvTQgd23TU/logo.png",
            "tags": [
                "OMEGA-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cf6rda8prASJemo9w4Q3NeBEM2pcYKK8aNZ5zLk4Dmzh",
            "symbol": "FUS",
            "name": "FUS Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cf6rda8prASJemo9w4Q3NeBEM2pcYKK8aNZ5zLk4Dmzh/logo.png",
            "tags": [
                "FUS-Token"
            ],
            "extensions": {
                "website": "http://www.thehae.biz"
            }
        },
        {
            "chainId": 101,
            "address": "4WSv4nmFA8JRKWkV84DHRFWbwaUhaGjNbXibitXQiYGS",
            "symbol": "AGC",
            "name": "Automatic Get Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4WSv4nmFA8JRKWkV84DHRFWbwaUhaGjNbXibitXQiYGS/logo.png",
            "tags": [
                "AGC-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "GRoESmA4DZjd7MMgvzaDaswZHow75ies6Xje5FoXytDP",
            "symbol": "VOO",
            "name": "VooVoo Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GRoESmA4DZjd7MMgvzaDaswZHow75ies6Xje5FoXytDP/logo.png",
            "tags": [
                "VOO-token"
            ],
            "extensions": {
                "website": "https://www.voovoo.io"
            }
        },
        {
            "chainId": 101,
            "address": "8oiPhiFrmXS93iC98M4ATev8emQ6XGtf8pz8sntbbqGt",
            "symbol": "OASIS",
            "name": "Oasis",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8oiPhiFrmXS93iC98M4ATev8emQ6XGtf8pz8sntbbqGt/logo.png",
            "tags": [
                "Utility-token",
                "game",
                "NFTs"
            ],
            "extensions": {
                "website": "https://wonderwall.finance"
            }
        },
        {
            "chainId": 101,
            "address": "HHXMCAQGw4SNfwJ3FqTJdFgt2M8GqggFk9cRm4jLYPDB",
            "symbol": "Ticket",
            "name": "Valknut Gold",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HHXMCAQGw4SNfwJ3FqTJdFgt2M8GqggFk9cRm4jLYPDB/logo.svg",
            "tags": [
                "utility-token",
                "gift",
                "game-coin",
                "gaming"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/JXKEDfYGGa",
                "twitter": "https://twitter.com/solvikingsnft",
                "website": "https://solvikings.com/"
            }
        },
        {
            "chainId": 101,
            "address": "KUPoVbJmipJb1M7xzQEND5w7u1BbmBytu9wZ2QPjQx4",
            "symbol": "CUBE",
            "name": "Cubecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUPoVbJmipJb1M7xzQEND5w7u1BbmBytu9wZ2QPjQx4/logo.png",
            "tags": [
                "meme",
                "community",
                "cube"
            ],
            "extensions": {
                "description": "Become the hodl champion.",
                "serumV3Usdc": "Gjxg4x8BturwnABaTeHvasgHPXvgLDVnu927ttu7BsAg",
                "website": "https://cubecoin.army"
            }
        },
        {
            "chainId": 101,
            "address": "3dmtKhD4bGXXdticPXyEeV3WF3mHcEucpSyJbUZum5cG",
            "symbol": "CSFCOIN",
            "name": "Cage Fight Series Coin",
            "decimals": 8,
            "logoURI": "https://file.avant-iconic.com/cfstoken.png",
            "extensions": {
                "description": "Official Cage Fight Series Coin",
                "website": "https://coin.cage-fight-series.com"
            }
        },
        {
            "chainId": 101,
            "address": "5fixLvM5hyZjX6JSE2wBojoJXoUQw1mE4vLVGYXkjuUU",
            "symbol": "BTCN",
            "name": "BatkaCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fixLvM5hyZjX6JSE2wBojoJXoUQw1mE4vLVGYXkjuUU/logo.svg"
        },
        {
            "chainId": 101,
            "address": "CpwH9nYz2CwsY7vVqxWbJxAWuvH7KnwP5B9JzoSHYdCA",
            "symbol": "SHELL",
            "name": "Tribeland Game Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tribeland/token-list/main/assets/mainnet/CpwH9nYz2CwsY7vVqxWbJxAWuvH7KnwP5B9JzoSHYdCA/logo.png",
            "tags": [
                "game",
                "utility-token",
                "nft",
                "gaming",
                "metaverse"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tribe_land",
                "website": "https://tribeland.io"
            }
        },
        {
            "chainId": 101,
            "address": "GyQK99iAgAoDvQBRAtFfw6j12gGncXbLHPacEENAVN1K",
            "symbol": "VST",
            "name": "VISTA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GyQK99iAgAoDvQBRAtFfw6j12gGncXbLHPacEENAVN1K/logo.png"
        },
        {
            "chainId": 101,
            "address": "3hNGkEXhVEbZWdb6Sd5nn8PQP2RBaKfcF8VYGaY4uqUZ",
            "symbol": "KSC",
            "name": "KISSCOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tribeland/token-list/main/assets/mainnet/3hNGkEXhVEbZWdb6Sd5nn8PQP2RBaKfcF8VYGaY4uqUZ/logo.png",
            "extensions": {
                "website": "https://www.kisscrypto.net/",
                "whitepaper": "https://ae283fe9-fab9-4865-a65a-bcd5559ada09.filesusr.com/ugd/0a74e1_8d61680fa97d40568d9f7adba2eccfd7.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "CGTXWnsZiJExZcCTaEKdXP5c7TL733bJo3ttqhtC1Gf1",
            "symbol": "TRASH",
            "name": "Trashy Pandas",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/22af25161ae56204dbb85a6cc9906875e4582681/assets/mainnet/CGTXWnsZiJExZcCTaEKdXP5c7TL733bJo3ttqhtC1Gf1/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/trashypandasNFT",
                "website": "https://www.trashypanda.com"
            }
        },
        {
            "chainId": 101,
            "address": "SAMUmmSvrE8yqtcG94oyP1Zu2P9t8PSRSV3vewsGtPM",
            "symbol": "SAMU",
            "name": "Samusky Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SAMUmmSvrE8yqtcG94oyP1Zu2P9t8PSRSV3vewsGtPM/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "samusky-token",
                "coinmarketcap": "https://coinmarketcap.com/currencies/samusky/",
                "discord": "https://discord.gg/samuskyio",
                "github": "https://github.com/samuskyio",
                "serumV3Usdc": "7PX2k66ecaUJHJweNbdCocR4kvPotvDn2FPu69svCsnu",
                "telegram": "https://t.me/samuskyio",
                "twitter": "https://twitter.com/samuskyio",
                "website": "https://samusky.io"
            }
        },
        {
            "chainId": 101,
            "address": "27NnGuWWsJ2aJvG85D27eiYBCcfc8LcFcvuW5yHM6gSz",
            "symbol": "BLC",
            "name": "bl Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/27NnGuWWsJ2aJvG85D27eiYBCcfc8LcFcvuW5yHM6gSz/logo.png",
            "tags": [
                "trash",
                "testing"
            ]
        },
        {
            "chainId": 101,
            "address": "2FkuyFr3N9RzvVahPqzXKfa8H9KhYpChwQZSeMKkkVPJ",
            "symbol": "SMBT",
            "name": "Monke Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FkuyFr3N9RzvVahPqzXKfa8H9KhYpChwQZSeMKkkVPJ/logo.png",
            "extensions": {
                "serumV3Usdc": "ENetSmU7WTBTcTXC7Q4WcYYw3WjgwmSFTaTECBtY1Wz9",
                "twitter": "https://twitter.com/TokenMonke",
                "website": "https://tokenmonke.github.io/SMBT/"
            }
        },
        {
            "chainId": 101,
            "address": "7hMNi9EVTTRyuUH7FybEcPLa7uG6amLWW8xAmV8qi47",
            "symbol": "DIRT",
            "name": "SolWorms",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/3f415a0fbe16c25e699842c8dfed327a0fbfc727/assets/mainnet/7hMNi9EVTTRyuUH7FybEcPLa7uG6amLWW8xAmV8qi47/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaWorms",
                "website": "https://www.solworms.com"
            }
        },
        {
            "chainId": 103,
            "address": "9vXqVi6UsD9JZwzfZoyQmVusCeUWPv1D1ZcWTpX5hxDu",
            "symbol": "GHT",
            "name": "Ghost Token",
            "decimals": 9,
            "logoURI": "https://bitexcer.com/llgo/7786848711618135046.svg",
            "tags": [
                "GHT"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/bitexcer/",
                "telegram": "https://t.me/bitexcer_info",
                "twitter": "https://twitter.com/bitexcer",
                "website": "https://bitexcer.com",
                "youtube": "https://www.youtube.com/channel/UCmRkuXFFDYe5ISENoCkO6AA"
            }
        },
        {
            "chainId": 101,
            "address": "7s5A6XukBCsM7S4EtHsNFzQfvztRdwZWGn1pQwQYfWBm",
            "symbol": "CERT",
            "name": "CERT",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/identicert/cryptoverse/main/img/tokens/cert.png",
            "tags": [
                "security-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/identicert",
                "website": "https://identicert.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ALbwwCnYj5Mf8S7k4QTSqezLbTMmpVdrv2SMYJTu4v9W",
            "symbol": "IDEN",
            "name": "IDEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/identicert/cryptoverse/main/img/tokens/iden.png",
            "tags": [
                "security-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/identicert",
                "website": "https://identicert.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8z1jFyg9heBFvKVvqMHJQ4UXQqomNpYZHWCsEJhQYaBd",
            "symbol": "VIP",
            "name": "Bored Rockstar Club",
            "decimals": 9,
            "logoURI": "https://github.com/solanadevv/token-list/blob/f73febc241c52ad0b977cd12fcf15d706b49a211/assets/mainnet/8z1jFyg9heBFvKVvqMHJQ4UXQqomNpYZHWCsEJhQYaBd/logo.png?raw=true",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RockstarClubNFT",
                "website": "https://www.boredrockstarclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "CrLXpyFeJQbhhkjWcrXGyXcY56KtWEPQmZQpjf853wFG",
            "symbol": "YUPPIE",
            "name": "Sol80s",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/8bf46f7c84f27a7d51fd233eaca5f064bbf87ba5/assets/mainnet/CrLXpyFeJQbhhkjWcrXGyXcY56KtWEPQmZQpjf853wFG/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolEighties",
                "website": "https://www.sol80s.com"
            }
        },
        {
            "chainId": 101,
            "address": "3UCH5emeLYr5zT41gzrKL9r7ACnQJETr9rJyXK6okuaL",
            "symbol": "FANTASY",
            "name": "SolFantasy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/fadf50a0b566216005de963d7afbf2c36047f087/assets/mainnet/3UCH5emeLYr5zT41gzrKL9r7ACnQJETr9rJyXK6okuaL/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolFantasyGirls",
                "website": "http://solfantasy.com"
            }
        },
        {
            "chainId": 101,
            "address": "8g2241fQ8fJamupx79UPqrFEVz851inAqYD7F8eDSSJG",
            "symbol": "TIKI",
            "name": "SolMaori",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/80a087f36770f46a9f28cc4e1eb78891d4cdd90a/assets/mainnet/8g2241fQ8fJamupx79UPqrFEVz851inAqYD7F8eDSSJG/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solmaori",
                "website": "https://www.solmaori.com"
            }
        },
        {
            "chainId": 101,
            "address": "EFKLgGMeGCLzGtJi6NUFSPE7y3ZaShyhhKNy4CubvFxx",
            "symbol": "TRUTH",
            "name": "TruthToken",
            "decimals": 9,
            "logoURI": "https://static.wixstatic.com/media/4ffb16_93f9f9561b054cf09ea60fd3d8c1072b~mv2.png",
            "tags": [
                "meme"
            ],
            "extensions": {
                "twitter": "https://twitter.com/truthtokenco",
                "website": "https://www.truthtoken.co"
            }
        },
        {
            "chainId": 101,
            "address": "GWRYczsoTksFqiwcrBRiXjihcL9g9udaAQAMeBSczf6h",
            "symbol": "SHBL LP token",
            "name": "SHBL-USDC Cropper LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz/logo.png",
            "tags": [
                "utility-token",
                "lp-token"
            ],
            "extensions": {
                "website": "https://cropper.finance/farms/"
            }
        },
        {
            "chainId": 101,
            "address": "8WR1cJ2RvcqFMNpsaBdUHamsibv8ywcCwmUAMg6ob1Xo",
            "symbol": "VIBES",
            "name": "Vibes",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8WR1cJ2RvcqFMNpsaBdUHamsibv8ywcCwmUAMg6ob1Xo/logo.png",
            "tags": [
                "utility-token",
                "game"
            ],
            "extensions": {
                "discord": "https://discord.gg/7Z4XbB7bDu",
                "twitter": "https://twitter.com/vibratinghamstr",
                "website": "https://www.vibratinghamster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HM9jjC8gThNDfFv3TRWqUdfJp5onWGDXhWirm5sUcFhj",
            "symbol": "NERD",
            "name": "SolNerds",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/a9a2654d9f5a41feeefab9e15337418a1eb7c08c/assets/mainnet/HM9jjC8gThNDfFv3TRWqUdfJp5onWGDXhWirm5sUcFhj/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolNerdsNFT",
                "website": "https://www.solnerds.com"
            }
        },
        {
            "chainId": 101,
            "address": "4cTDXDzieiTk2qibwtXty8UzvXGZXfd92dK3SF2EuKuV",
            "symbol": "DogeSOL",
            "name": "DogeSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/main/assets/mainnet/4cTDXDzieiTk2qibwtXty8UzvXGZXfd92dK3SF2EuKuV/logo.png",
            "tags": [
                "Doge"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Doge_Solana",
                "website": "https://dogesol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CuEi5x3nzHcCmiyG7CMPRiKNBhGKt9gyUtXkPK347eqa",
            "symbol": "BARK",
            "name": "BARK",
            "decimals": 6,
            "logoURI": "https://cdn.jsdelivr.net/gh/solanabark/solbark/logobark.png",
            "tags": [
                "Meme",
                "Meme-Token",
                "doge",
                "shib",
                "bark"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solbark"
            }
        },
        {
            "chainId": 101,
            "address": "9Exx2WQUZkRwbLB9RxSVThGdkuYdgCWW3v7GgDbFYR3c",
            "symbol": "LADS",
            "name": "LadsCoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Zderosia/token-list/main/assets/mainnet/9Exx2WQUZkRwbLB9RxSVThGdkuYdgCWW3v7GgDbFYR3c/LadCoinLogo.png",
            "tags": [
                "Pig",
                "Meme",
                "Lad"
            ]
        },
        {
            "chainId": 101,
            "address": "xAx6d1sjmBvpWkVZQEqgUvPmGBNndEXPxYpr3QVp61H",
            "symbol": "xABR",
            "name": "Allbridge Staking",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/allbridge-io/media/main/token.svg",
            "extensions": {
                "medium": "https://allbridge.medium.com/",
                "telegram": "https://t.me/allbridge_announcements",
                "twitter": "https://twitter.com/Allbridge_io",
                "website": "https://allbridge.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui",
            "symbol": "acUSDC",
            "name": "Wrapped USDC (Allbridge from Celo)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "Bo4ehCeRcRj2wp5tQpjfCJxYFn4KyRacfDzSa4Aj27tH",
            "symbol": "ahBTC",
            "name": "Wrapped BTC (Allbridge from HECO)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bo4ehCeRcRj2wp5tQpjfCJxYFn4KyRacfDzSa4Aj27tH/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin"
            }
        },
        {
            "chainId": 101,
            "address": "GfzU1fLASNV3r4NtEyrnwTyTakJkYzoivnaL3Snh45oj",
            "symbol": "ahUSDT",
            "name": "Wrapped USDT (Allbridge from HECO)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfzU1fLASNV3r4NtEyrnwTyTakJkYzoivnaL3Snh45oj/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "5pWsyiUEpKHvi86QmQPivfF1WND11DDwv7nS1wVMwsRf",
            "symbol": "Meleon",
            "name": "Solameleon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/03d369be7f3f9fc2f1c6cd37b71ada2d9b1708d2/assets/mainnet/5pWsyiUEpKHvi86QmQPivfF1WND11DDwv7nS1wVMwsRf/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Solameleon",
                "website": "https://www.solameleon.com"
            }
        },
        {
            "chainId": 101,
            "address": "M6U7nVSLym4CuJjjmXfFbDZp9yG4MiYBRBxWHfoaTxM",
            "symbol": "MORS",
            "name": "MoroSteel",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/M6U7nVSLym4CuJjjmXfFbDZp9yG4MiYBRBxWHfoaTxM/logo.png",
            "tags": [
                "DEX",
                "utility-token",
                "COMMERCE"
            ],
            "extensions": {
                "description": "Token used to trade international steel",
                "website": "https://morosteel.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CE6gowswLbhy5y9G2EDfvYSavAcdSaqX3wMta5gySG1H",
            "symbol": "BullDog",
            "name": "BullDog",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/assets/mainnet/CE6gowswLbhy5y9G2EDfvYSavAcdSaqX3wMta5gySG1H/logo.png",
            "tags": [
                "Meme-Token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BullDogSolana"
            }
        },
        {
            "chainId": 101,
            "address": "3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw",
            "symbol": "FLOOF",
            "name": "FLOOF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GreenFailure/Floof/main/OkyT9kpz_400x400.png",
            "extensions": {
                "coingeckoId": "floof",
                "discord": "https://discord.gg/Gr5Z8DZ67X",
                "serumV3Usdc": "BxcuT1p8FK9cFak4Uuf5nmoAZ7nQGu7FerCMESGqxF7b",
                "twitter": "https://twitter.com/FLOOF_SOLANA",
                "website": "https://www.floofsolana.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ap1gFH91RpuGQAm1y3AJU3GWbjXqTcUQ9b6gFZg5su3Z",
            "symbol": "PANTIE",
            "name": "Pantie",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ap1gFH91RpuGQAm1y3AJU3GWbjXqTcUQ9b6gFZg5su3Z/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://pantie.app/"
            }
        },
        {
            "chainId": 101,
            "address": "58yYYVT5FoVx2jtvD9xtX4JxE8jogtA5tjMkJudgERMS",
            "symbol": "DONKEY",
            "name": "Donkey Hee Haw",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58yYYVT5FoVx2jtvD9xtX4JxE8jogtA5tjMkJudgERMS/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/donkey_hee_haw"
            }
        },
        {
            "chainId": 101,
            "address": "Adqy2jwzqCo9M86AwoS1D1MtCc1gNoDbuVtsS35gf8aE",
            "symbol": "CLB",
            "name": "Columbus",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Adqy2jwzqCo9M86AwoS1D1MtCc1gNoDbuVtsS35gf8aE/logo.png",
            "tags": [
                "utility-token",
                "Validator",
                "Columbus-inc"
            ]
        },
        {
            "chainId": 101,
            "address": "Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH",
            "symbol": "BABY",
            "name": "Baby Samo Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "baby-samo-coin",
                "discord": "https://discord.gg/RhQTNhRk2X",
                "twitter": "https://twitter.com/BabySamoCoin",
                "website": "https://www.babysamocoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MSQTxnsq8t94gEqZ42a6mxuw11LBYWF4J5hy84GaECb",
            "symbol": "MSQ",
            "name": "Magic Square",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MSQTxnsq8t94gEqZ42a6mxuw11LBYWF4J5hy84GaECb/logo.svg",
            "extensions": {
                "discord": "https://discord.com/invite/u9SHXU9qNd",
                "medium": "https://medium.com/@magicsquare",
                "telegram": "https://t.me/Magicsquareio",
                "twitter": "https://twitter.com/MagicSquareio",
                "website": "https://magicsquare.io/",
                "youtube": "https://www.youtube.com/channel/UCJjHbHzZXe0T5gcn97baIbA"
            }
        },
        {
            "chainId": 101,
            "address": "AsVNhq2nnoUgMWciCvePRyHk7xAv6i4ruV6oRHFWBcwF",
            "symbol": "SHIBL",
            "name": "Shibalana Inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsVNhq2nnoUgMWciCvePRyHk7xAv6i4ruV6oRHFWBcwF/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "doge",
                "dogecoin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/shibalana_inu",
                "website": "https://trade.dexlab.space/#/market/Fc5co91LY2tiZAVuvCmVxDFZWA9SVBVLuxpBcYRQbH1T"
            }
        },
        {
            "chainId": 101,
            "address": "FmQN1sQDeD9DF7aQvmJA9zZ8hicJYxUzzCDSnV8tfUtY",
            "symbol": "NOOT",
            "name": "NOOT Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FmQN1sQDeD9DF7aQvmJA9zZ8hicJYxUzzCDSnV8tfUtY/noot.png",
            "tags": [
                "Meme-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "FucvfR9FF2xsRaGbzrhywNsfxsx2fjoJLEckUDJALG62",
            "symbol": "BADURUS",
            "name": "BaduroCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FucvfR9FF2xsRaGbzrhywNsfxsx2fjoJLEckUDJALG62/logo.png",
            "tags": [
                "gambling-token",
                "meme-token"
            ],
            "extensions": {
                "github": "https://github.com/BaduroCoin",
                "twitter": "https://twitter.com/badurocoin",
                "website": "https://badurocoin.pl"
            }
        },
        {
            "chainId": 101,
            "address": "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp",
            "symbol": "SLND",
            "name": "Solend",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp/logo.png",
            "tags": [
                "solend",
                "lending"
            ],
            "extensions": {
                "coingeckoId": "solend",
                "serumV3Usdc": "F9y9NM83kBMzBmMvNT18mkcFuNAPhNRhx7pnz9EDWwfv",
                "twitter": "https://twitter.com/solendprotocol",
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "6wFgUMohoSavTuEneDYcrb9qF3JsYVVXyB8jb3PaXCJ4",
            "symbol": "Kishu",
            "name": "Kishu Inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wFgUMohoSavTuEneDYcrb9qF3JsYVVXyB8jb3PaXCJ4/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "doge",
                "dogecoin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ShibamoonNFT",
                "website": "https://www.shibamoon.info/"
            }
        },
        {
            "chainId": 101,
            "address": "FZBNaVMz5n1EcKfno8Jgsa2go5GLUwBYVpGPvKAdzNth",
            "symbol": "PONQUE",
            "name": "Ponque Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZBNaVMz5n1EcKfno8Jgsa2go5GLUwBYVpGPvKAdzNth/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "capys"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solcapys",
                "website": "https://www.solcapys.club/"
            }
        },
        {
            "chainId": 101,
            "address": "cqNTpypmbwghrf1G9VGvSENcw7M7wGSQ7JS8UTQWXwb",
            "symbol": "SCUM",
            "name": "Solana CUM",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cqNTpypmbwghrf1G9VGvSENcw7M7wGSQ7JS8UTQWXwb/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaCum",
                "website": "https://www.solanacum.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8E5W9PMhnEvdvM2Q9XBLMJW7UsFiieXnRHPj8zhtB23h",
            "symbol": "APPLE",
            "name": "Apple Fruit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8E5W9PMhnEvdvM2Q9XBLMJW7UsFiieXnRHPj8zhtB23h/logo.png",
            "tags": [
                "community-token",
                "meme-coin",
                "fruit",
                "apple-coin"
            ],
            "extensions": {
                "discord": "https://discord.gg/NfGXqUTV",
                "twitter": "https://twitter.com/superfruitnft",
                "website": "https://superfruit.farm"
            }
        },
        {
            "chainId": 101,
            "address": "2qRHKgE9k7doshwy7ZfENuSHW256pDhcbyspDgU3Ek8C",
            "symbol": "SAMOL",
            "name": "Samolana NFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qRHKgE9k7doshwy7ZfENuSHW256pDhcbyspDgU3Ek8C/logo.png",
            "tags": [
                "meme",
                "community",
                "NFTs",
                "DEFI",
                "Dog"
            ],
            "extensions": {
                "twitter": "https://twitter.com/samolana3"
            }
        },
        {
            "chainId": 101,
            "address": "25DXQbnQicHzZ6sH4HgbhpEGUahxEPZUkPkFbU2Jr7D3",
            "symbol": "CLICK",
            "name": "Nice Click",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/25DXQbnQicHzZ6sH4HgbhpEGUahxEPZUkPkFbU2Jr7D3/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/proofofclick",
                "website": "https://www.proofofclick.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CDxwZo3ayxvTmxin7F6o9xg6SjdE4qWEDXV6MZFBevqw",
            "symbol": "SHIBS",
            "name": "ShibSol",
            "decimals": 9,
            "logoURI": "https://discordapp.com/channels/@me/903775821704073247/903777435231846420",
            "extensions": {
                "twitter": "https://twitter.com/ShibSol",
                "website": "https://www.solshib.net/"
            }
        },
        {
            "chainId": 101,
            "address": "3tufRsMkBu5rYUCsSQys3ZjBXxXLWeRgttAXX4a1CDdW",
            "symbol": "GAWD",
            "name": "Gawd Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/MaloSkylar/GAWDtoken/GAWDlogo.jpg",
            "tags": [
                "Social-Coin"
            ]
        },
        {
            "chainId": 101,
            "address": "HWnfNCDHWJqqXQBpF379ubyLPhk8sdvFAa2mBsn4T6Bz",
            "symbol": "SCODE",
            "name": "Scode Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWnfNCDHWJqqXQBpF379ubyLPhk8sdvFAa2mBsn4T6Bz/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/6NMRH4KNG5",
                "github": "https://github.com/scenechain",
                "medium": "https://medium.com/scenechain",
                "twitter": "https://twitter.com/scenechain",
                "website": "https://scenechain.org/"
            }
        },
        {
            "chainId": 101,
            "address": "G7uwQLyFLpeKWZePU3q5eCMuQYcu3tMoGZvu3JHdksyW",
            "symbol": "KUTTA",
            "name": "Sheru",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G7uwQLyFLpeKWZePU3q5eCMuQYcu3tMoGZvu3JHdksyW/logo.png"
        },
        {
            "chainId": 101,
            "address": "A9UhP1xfQHWUhSd54NgKPub2XB3ZuQMdPEvf9aMTHxGT",
            "symbol": "DEGN",
            "name": "Degen",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/flazewang/degencoinsolana/main/degen.png",
            "extensions": {
                "twitter": "https://twitter.com/degencoinsol"
            }
        },
        {
            "chainId": 101,
            "address": "H6JocWxg5g1Lcs4oPnBecmjQ4Y1bkZhGJHtjMunmjyrp",
            "symbol": "SPX",
            "name": "Sphinxel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H6JocWxg5g1Lcs4oPnBecmjQ4Y1bkZhGJHtjMunmjyrp/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "trading-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SphinxelSPX",
                "website": "https://sphinxel.com"
            }
        },
        {
            "chainId": 101,
            "address": "FsAXvJ5wrCoSh3cQvdkuceUsQUjLtRcqgoikR9jQ9FBW",
            "symbol": "DCN",
            "name": "D Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FsAXvJ5wrCoSh3cQvdkuceUsQUjLtRcqgoikR9jQ9FBW/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DatalusCorp",
                "website": "https://datalus.us"
            }
        },
        {
            "chainId": 101,
            "address": "6DXQSpYeUirraMuwnQKgXRK5QMvZUWL67WAuevntTn4Y",
            "symbol": "Finger",
            "name": "SolaFinger",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/35c428ac8c5fc843cc3d70caf552f7db66058491/assets/mainnet/6DXQSpYeUirraMuwnQKgXRK5QMvZUWL67WAuevntTn4Y/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solafinger",
                "website": "https://www.solafinger.com/"
            }
        },
        {
            "chainId": 101,
            "address": "PEGDAG5KpGAw66WBeGJcwub17eAyb9A4iFhBADsDJjF",
            "symbol": "SOLM",
            "name": "Solanim",
            "decimals": 8,
            "logoURI": "https://solanim.com/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "telegram": "https://t.me/teamsolanim",
                "twitter": "https://twitter.com/teamsolanim",
                "website": "https://solanim.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3Z5o6GGjkzPgBVk7aFPsTGFPqGDdAwXjfGteuQa1SE95",
            "symbol": "GHOST",
            "name": "Ghost Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Z5o6GGjkzPgBVk7aFPsTGFPqGDdAwXjfGteuQa1SE95/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solaghosts",
                "website": "https://www.solaghosts.io"
            }
        },
        {
            "chainId": 101,
            "address": "7CskY61wSZUZeSoMNHHX6br9kA9hL9v5EwAkS6mqsYNc",
            "symbol": "PLING",
            "name": "Dumpling",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7CskY61wSZUZeSoMNHHX6br9kA9hL9v5EwAkS6mqsYNc/logo.png",
            "extensions": {
                "website": "https://projectdumpling.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3jv3yRRX2WgBLeUe7p7AFxazudp913CK6BRk9aHCCUxz",
            "symbol": "MOGUL",
            "name": "Mogul coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3jv3yRRX2WgBLeUe7p7AFxazudp913CK6BRk9aHCCUxz/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MogulWars"
            }
        },
        {
            "chainId": 101,
            "address": "4evENxfLeUDk24nrqzMp4gkR3kPxCMeQuCeftjgd66BD",
            "symbol": "SBOOBS",
            "name": "Solana Boobs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4evENxfLeUDk24nrqzMp4gkR3kPxCMeQuCeftjgd66BD/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanaboobs",
                "website": "https://www.solanaboobs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "RdFHYW7mPJouuSpb5vEzUfbHeQedmQMuCoHN4VQkUDn",
            "symbol": "LUCHOW",
            "name": "LunaChow",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RdFHYW7mPJouuSpb5vEzUfbHeQedmQMuCoHN4VQkUDn/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa5ef74068d04ba0809b7379dd76af5ce34ab7c57",
                "assetContract": "https://etherscan.io/address/0xa5ef74068d04ba0809b7379dd76af5ce34ab7c57",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "lunachow",
                "discord": "https://discord.gg/FBz7dGkxgv",
                "facebook": "https://www.facebook.com/LunaChowOfficial",
                "instagram": "https://www.instagram.com/lunachow.io/",
                "reddit": "https://www.reddit.com/r/lunachow/",
                "telegram": "https://t.me/LuChow",
                "twitter": "https://twitter.com/LunaChoww",
                "website": "https://www.lunachow.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3mZMtzsr11srDX7jdpkacsxAo1Na5H4kqepxehjhTkLE",
            "symbol": "LUCHOW",
            "name": "LunaChow",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3mZMtzsr11srDX7jdpkacsxAo1Na5H4kqepxehjhTkLE/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa5ef74068d04ba0809b7379dd76af5ce34ab7c57",
                "assetContract": "https://etherscan.io/address/0xa5ef74068d04ba0809b7379dd76af5ce34ab7c57",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "lunachow",
                "discord": "https://discord.gg/FBz7dGkxgv",
                "facebook": "https://www.facebook.com/LunaChowOfficial",
                "instagram": "https://www.instagram.com/lunachow.io/",
                "reddit": "https://www.reddit.com/r/lunachow/",
                "telegram": "https://t.me/LuChow",
                "twitter": "https://twitter.com/LunaChoww",
                "website": "https://www.lunachow.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9k27FY1wmxKEyoMGqK4zJMT2Y8dvkiYRGM2ijjLLTrjq",
            "symbol": "UNIVERSE",
            "name": "universe",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9k27FY1wmxKEyoMGqK4zJMT2Y8dvkiYRGM2ijjLLTrjq/logo.png",
            "tags": [
                "Metaverse",
                "Universe"
            ]
        },
        {
            "chainId": 101,
            "address": "CA3XWNSEQNtBiiWQE9CQJp5G93eAZKZF7j6wx9tMTZR7",
            "symbol": "CHEEZ",
            "name": "Cheezburger",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CA3XWNSEQNtBiiWQE9CQJp5G93eAZKZF7j6wx9tMTZR7/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6kr8q1SXXNRLy3imzDsecWkcRtzJiXqiXx1N7LtpMPTe",
            "symbol": "BIT",
            "name": "Biconomy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kr8q1SXXNRLy3imzDsecWkcRtzJiXqiXx1N7LtpMPTe/logo.png",
            "tags": [
                "exchange-token"
            ],
            "extensions": {
                "bridgeContract": "https://bscscan.com/token/0xc864019047B864B6ab609a968ae2725DFaee808A",
                "coingeckoId": "biconomy-exchange-token",
                "website": "https://biconomy.com"
            }
        },
        {
            "chainId": 101,
            "address": "EFBGjiTEuvhwZGmEzDBJwrWnSDuALx94MERncXNsap3G",
            "symbol": "RBTP",
            "name": "Robotopian Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EFBGjiTEuvhwZGmEzDBJwrWnSDuALx94MERncXNsap3G/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://robotopia.io"
            }
        },
        {
            "chainId": 101,
            "address": "7tfCwa3CCNzhvLCkKPaBWvYxyjq157Wha1EDKZJAxBZ",
            "symbol": "RBTK",
            "name": "Robotopian Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tfCwa3CCNzhvLCkKPaBWvYxyjq157Wha1EDKZJAxBZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://robotopia.io"
            }
        },
        {
            "chainId": 101,
            "address": "Dm3qVmVLAEQPSHoHCzAuF1gpmT2k2SXe1Pw2FgtUVAaC",
            "symbol": "DNG",
            "name": "DNG Reasearch Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dm3qVmVLAEQPSHoHCzAuF1gpmT2k2SXe1Pw2FgtUVAaC/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://d-n-g.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HiQg2CX9BU24gsgDVXg5DR437z5ptYvnT3KY1D7nqfAw",
            "symbol": "ORD",
            "name": "Oridion",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/9thwall/Oridion/logo-v1.png",
            "tags": [
                "oridion",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/OridionToken",
                "website": "https://oridion.io"
            }
        },
        {
            "chainId": 101,
            "address": "3mXx1bNiB5bhgwznk4eeqM9eoy6DU3CeCkm1LPabeoEh",
            "symbol": "SAMOY",
            "name": "Samoy Meme Dog",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3mXx1bNiB5bhgwznk4eeqM9eoy6DU3CeCkm1LPabeoEh/logo.png",
            "tags": [
                "Meme-Token",
                "Community-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "5pGUEKhMLa7VCvGeP1acgGKPe2vpzQF5hhpyQAFsH2Cg",
            "symbol": "COSP",
            "name": "Cosplay Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5pGUEKhMLa7VCvGeP1acgGKPe2vpzQF5hhpyQAFsH2Cg/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CosplayCoin",
                "website": "https://cosplaycoin.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "B6nUf6nNex5Eh41xU6NY4qu9xNwyFyDFxKbRjdkPPenT",
            "symbol": "DIGART",
            "name": "Digital Artists Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B6nUf6nNex5Eh41xU6NY4qu9xNwyFyDFxKbRjdkPPenT/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/digartcoin",
                "website": "https://digartcoin.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9aj5LDmjjwBXk5ijfoyQej3X6waV1rnk7QN4KVqjX8wH",
            "symbol": "ARS",
            "name": "Artists Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9aj5LDmjjwBXk5ijfoyQej3X6waV1rnk7QN4KVqjX8wH/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/artistscoin",
                "twitter": "https://twitter.com/artistscoin",
                "website": "https://artistscoin.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa",
            "symbol": "SHIBA",
            "name": "Shibalana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa/logo.png",
            "extensions": {
                "coingeckoId": "shibalana",
                "discord": "https://discord.gg/UaK3ZMkkmH",
                "serumV3Usdc": "3M8uZhLZMxFUedsEgPzywZr9qbGTv3kKNMCEfAmg8iyK",
                "telegram": "https://t.me/shibalana",
                "twitter": "https://twitter.com/shibalana",
                "website": "https://shibalana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7TPWAzabCP26vuLGuAhBMJFSs5LQNVsw4uFyQUkSGJXj",
            "symbol": "PORN",
            "name": "Pornlana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Agaveis/pornlana/main/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "http://pornlana.com"
            }
        },
        {
            "chainId": 101,
            "address": "3xVf2hPbkE5TuZNUPLQXFgFLD4LpvCM45BodbPmnpSSV",
            "symbol": "sBull",
            "name": "SolBull - GoPromotedToken",
            "decimals": 9,
            "logoURI": "https://gopromoted.com/sbull200.png",
            "tags": [
                "Utility-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GoPromotedCom",
                "website": "http://gopromoted.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8gWEnKqB4qVQgC8yAorMxhiEKqsDcxZSVKFVbQ8g1fzB",
            "symbol": "PORN",
            "name": "Pornlana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Agaveis/pornlana/main/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "http://pornlana.com"
            }
        },
        {
            "chainId": 101,
            "address": "UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M",
            "symbol": "UXP",
            "name": "UXP Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M/uxp-icon-black.png",
            "extensions": {
                "coingeckoId": "uxd-protocol-token",
                "coinmarketcap": "https://coinmarketcap.com/currencies/uxd-protocol/",
                "discord": "https://discord.com/invite/BHfpYmjsBM",
                "medium": "https://uxdprotocol.medium.com/",
                "serumV3Usdc": "7KQpsp914VYnh62yV6AGfoG9hprfA14SgzEyqr6u9NY1",
                "twitter": "https://twitter.com/UXDProtocol",
                "website": "https://uxd.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "88govxpekHhHv4hF2bgi8UDveP9LnxofhdREmrxLffy8",
            "symbol": "USTK",
            "name": "Ultra Scarce Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88govxpekHhHv4hF2bgi8UDveP9LnxofhdREmrxLffy8/logo.png",
            "tags": [
                "utility-token",
                "wrapped"
            ]
        },
        {
            "chainId": 101,
            "address": "2uEb2S4JCxZSzMxRfEdmj6grtZkgEe6XrfTwgkpswuvS",
            "symbol": "VAL",
            "name": "Valknut",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2uEb2S4JCxZSzMxRfEdmj6grtZkgEe6XrfTwgkpswuvS/Valknut.png",
            "tags": [
                "utility-token",
                "game-coin",
                "gaming"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/JXKEDfYGGa",
                "twitter": "https://twitter.com/solvikingsnft",
                "website": "https://solvikings.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E6Hkw5o48QfNo6iUi1aepjEBzVq4ZjQLxh7xVtdTqoyB",
            "symbol": "DICK",
            "name": "Dickcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6Hkw5o48QfNo6iUi1aepjEBzVq4ZjQLxh7xVtdTqoyB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dickcoinmoon"
            }
        },
        {
            "chainId": 101,
            "address": "3uXMgtaMRBcyEtEChgiLMdHDjb5Azr17SQWwQo3ppEH8",
            "symbol": "WBRZ",
            "name": "Wrapped BRZ",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x420412E765BFa6d85aaaC94b4f7b708C89be2e2B/logo.png",
            "tags": [
                "exchange-token"
            ],
            "extensions": {
                "website": "https://www.brztoken.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AvB7Ffmt3H16bhq7ToXb839ynKzFgJxu2WDHsR1S9Yft",
            "symbol": "FLOKIS",
            "name": "FlokiSol",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AvB7Ffmt3H16bhq7ToXb839ynKzFgJxu2WDHsR1S9Yft/Flokisol.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FlokiSol",
                "website": "https://flokisol.com"
            }
        },
        {
            "chainId": 101,
            "address": "TrickCA8nD77Y6iHHEQBAaBFDjFa5zohSThmSVfz2X9",
            "symbol": "Trick",
            "name": "SolTricks token",
            "decimals": 9,
            "logoURI": "http://static.soltricks.io/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.soltricks.io",
                "twitter": "https://twitter.com/soltricks",
                "website": "https://soltricks.io"
            }
        },
        {
            "chainId": 101,
            "address": "rendopHqu4oE94AXjEEtporr1xW8Fsp4bZDccyMTYzU",
            "symbol": "RENDO",
            "name": "rendo.club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rendopHqu4oE94AXjEEtporr1xW8Fsp4bZDccyMTYzU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SvNZZu6",
                "twitter": "https://twitter.com/rendo",
                "website": "https://rendo.club"
            }
        },
        {
            "chainId": 101,
            "address": "F7mgxaYF1gg1hBtaVzENSG6ey3pn6J1mXhBRmHxDzBNg",
            "symbol": "SINU",
            "name": "Samo INU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F7mgxaYF1gg1hBtaVzENSG6ey3pn6J1mXhBRmHxDzBNg/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/samoinu",
                "twitter": "https://twitter.com/samo_inu",
                "website": "https://www.samoinu.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ac7GiHwC7vZU2y97GRh9rqCqqnKAAgopYrTAtKccHxUk",
            "symbol": "SINU",
            "name": "Samo INU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ac7GiHwC7vZU2y97GRh9rqCqqnKAAgopYrTAtKccHxUk/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "description": "Samo Inu is an hybrid meme coin with fun and social utilities",
                "discord": "https://discord.gg/samoinu",
                "twitter": "https://twitter.com/samo_inu",
                "website": "https://www.samoinu.com"
            }
        },
        {
            "chainId": 101,
            "address": "2LxZrcJJhzcAju1FBHuGvw929EVkX7R7Q8yA2cdp8q7b",
            "symbol": "BORK",
            "name": "BORK",
            "decimals": 3,
            "logoURI": "https://arweave.net/VtJL5kdepu6AyVHnHi4GImgyWxmcb2XMPN7jURW_yXQ",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fazKMkWSSA",
                "twitter": "https://twitter.com/borksolana",
                "website": "https://www.borksolana.club"
            }
        },
        {
            "chainId": 101,
            "address": "53dqN1unCex98QWzLZtk1ssJptEcRwZapTrv8pakcgNB",
            "symbol": "LGBR",
            "name": "LGBR - LETS GO BRANDON",
            "decimals": 9,
            "logoURI": "http://gopromoted.com/brandon.jpg",
            "tags": [
                "Meme-coin"
            ],
            "extensions": {
                "discord": "https://discord.gg/Qb7QRNAeH2",
                "twitter": "https://twitter.com/LGBRCoin",
                "whitepaper": "https://docs.google.com/document/d/1krRMK_b2DkvgV0AEl9rKN2LU4lh-QACWi9rXByfG8tQ/edit?usp=sharing"
            }
        },
        {
            "chainId": 101,
            "address": "4dzDhawz7bHfDDfBpbDrLeDu6T7vZEtmmyQtn9Df2PRa",
            "symbol": "PUMP",
            "name": "PUMP",
            "decimals": 3,
            "logoURI": "https://wtcaeyantzogvbrzbwcgqfesfnz7mekovkyybs3ae54fptigq5za.arweave.net/tMQCYA2eXGqGOQ2EaBSSK3P2EU6qsYDLYCd4V80Gh3I",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Tab4WFUSY2",
                "twitter": "https://twitter.com/S0l_Pump"
            }
        },
        {
            "chainId": 101,
            "address": "5uE8w9yoMMu88NV8wUaZMuxCiufBBoSiJbNDAEGmDx7x",
            "symbol": "SCIFI",
            "name": "SciFi-Verse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5uE8w9yoMMu88NV8wUaZMuxCiufBBoSiJbNDAEGmDx7x/logo.png",
            "tags": [
                "SciFi",
                "Game"
            ],
            "extensions": {
                "website": "https://www.scifiverse.net"
            }
        },
        {
            "chainId": 101,
            "address": "BTSPdFLQJ9R3JXAgjVx2JtLq4sNSjiVSGh4tQi4oRUi8",
            "symbol": "DYOR",
            "name": "DO YOUR OWN RESEARCH",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTSPdFLQJ9R3JXAgjVx2JtLq4sNSjiVSGh4tQi4oRUi8/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wXaGzqUuru",
                "twitter": "https://twitter.com/s0lnumbers",
                "website": "http://s0lnumbers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5i8C6n4VbELnTHtES83aqeh16uPiEyve4jHr2QN2WhSz",
            "symbol": "ZDRT",
            "name": "ZDRT Club Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5i8C6n4VbELnTHtES83aqeh16uPiEyve4jHr2QN2WhSz/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/VHpA4tQYXZ",
                "website": "https://zdrt.club"
            }
        },
        {
            "chainId": 101,
            "address": "4wfCtMp8KQ7r61V4qH2VtHxFjVjUdsWhgAmZgQi33UkT",
            "symbol": "ARDN",
            "name": "Ariadne Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wfCtMp8KQ7r61V4qH2VtHxFjVjUdsWhgAmZgQi33UkT/logo.svg",
            "tags": [
                "utility-token",
                "DeFi",
                "marketplace",
                "ariadne"
            ],
            "extensions": {
                "coingeckoId": "ariadne",
                "medium": "https://medium.com/ariadne-project",
                "telegram": "https://t.me/ariadne_finance",
                "twitter": "https://twitter.com/Ariadne_finance",
                "website": "https://ariadne.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "GcqEZcpnMYmxRhPp9sRh1wLLWTmFjTwp7CVQcuYX73sT",
            "symbol": "WHALE",
            "name": "Whale Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GcqEZcpnMYmxRhPp9sRh1wLLWTmFjTwp7CVQcuYX73sT/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "whale",
                "whale-token",
                "crypto-whale"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CryptoWhale",
                "website": "https://cryptowhale.org/"
            }
        },
        {
            "chainId": 101,
            "address": "5fhXkD8tXyDB9rmYZSNJ6LneLr2nMteMpCVxeDDEgXa3",
            "symbol": "ACE",
            "name": "ACE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fhXkD8tXyDB9rmYZSNJ6LneLr2nMteMpCVxeDDEgXa3/logo.png"
        },
        {
            "chainId": 101,
            "address": "CJ2K2J3HYU6ibR1JwLkUmD9RM8eytfxtMcLzYPqoQQKo",
            "symbol": "BONER",
            "name": "BONER",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJ2K2J3HYU6ibR1JwLkUmD9RM8eytfxtMcLzYPqoQQKo/logo.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BareBonesNFT",
                "website": "https://www.barebonesnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8o66EVAf4u2Hr21m2tuRrPtEXFPLr8G8aL1ETStP8fDu",
            "symbol": "VIBE",
            "name": "VIBE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8o66EVAf4u2Hr21m2tuRrPtEXFPLr8G8aL1ETStP8fDu/VIBE-logo.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/CQQSSSgTDb",
                "website": "https://twitter.com/vibetoken_"
            }
        },
        {
            "chainId": 101,
            "address": "9bPoFPCwGCVGDMC5gvzisPdjgKC6tRLRDhirJvcktgVh",
            "symbol": "SPIRIT",
            "name": "Spirit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Yuriy-Ihor/CyberGothicaAssets/main/Token/logo.png",
            "tags": [
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Cyber_Gothica"
            }
        },
        {
            "chainId": 101,
            "address": "Ax9MbdUbr7cPQhkipXnBh2QNDSzf245Sn4xKfQUDuJGD",
            "symbol": "EDGE",
            "name": "Lord Edge Elon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ax9MbdUbr7cPQhkipXnBh2QNDSzf245Sn4xKfQUDuJGD/logo.png",
            "tags": [
                "Meme-token",
                "DEFI",
                "Elon",
                "Community"
            ],
            "extensions": {
                "telegram": "https://t.me/lordedgesol",
                "twitter": "https://twitter.com/LordedgeSol",
                "website": "https://lordedgesol.com"
            }
        },
        {
            "chainId": 101,
            "address": "Fzs17QjYy7ZicGgBv2auDGA55TEV2PfSpK8cCax9m6fh",
            "symbol": "NBX",
            "name": "Nathan Bolin Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fzs17QjYy7ZicGgBv2auDGA55TEV2PfSpK8cCax9m6fh/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nathanbolin",
                "website": "http://nathanbolin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DYbRXaQcnj44SH9woxvyFdtcKkSoPoCEshRTQDZSjsBm",
            "symbol": "MARIJUANA",
            "name": "Marijuana Joint",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYbRXaQcnj44SH9woxvyFdtcKkSoPoCEshRTQDZSjsBm/logo.png",
            "tags": [
                "Meme",
                "Social"
            ]
        },
        {
            "chainId": 101,
            "address": "FiPpi1nhxws1cPkyy76AzmHkFMyB3NysdU8RruTXuzNf",
            "symbol": "KAJU",
            "name": "KAJU Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/kajucoin/assets/main/logo.jpg",
            "tags": [
                "Meme",
                "Social"
            ],
            "extensions": {
                "instagram": "http://instagram.com/kajucoin",
                "twitter": "https://twitter.com/kajucoin",
                "website": "http://www.kajucoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA",
            "symbol": "USDCbs",
            "name": "USD Coin (Portal from BSC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
                "assetContract": "https://bscscan.com/address/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
                "bridgeContract": "https://bscscan.com/address/0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7",
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond",
            "symbol": "aSOL",
            "name": "aSOL Aggregate Solana Stake Pool",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond/logo.svg",
            "extensions": {
                "coingeckoId": "solana",
                "description": "aSOL is the standard for transacting with staked SOL tokens.",
                "github": "https://github.com/aSolHQ",
                "twitter": "https://twitter.com/aSOLprotocol",
                "website": "https://asol.so"
            }
        },
        {
            "chainId": 101,
            "address": "Ew2xovnfCPoUwPeqtRJrk3ST8o6txNFPL2QxrcZrbspv",
            "symbol": "AUV",
            "name": "Atlantis Universe",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ew2xovnfCPoUwPeqtRJrk3ST8o6txNFPL2QxrcZrbspv/logo.png",
            "tags": [
                "games-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/atlantisvers"
            }
        },
        {
            "chainId": 101,
            "address": "8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv",
            "symbol": "USDTbs",
            "name": "Tether USD (Portal from BSC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x55d398326f99059ff775485246999027b3197955",
                "assetContract": "https://bscscan.com/address/0x55d398326f99059ff775485246999027b3197955",
                "bridgeContract": "https://bscscan.com/address/0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7",
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "BQTN97PwrQGkbNepQxjvcYfRPYbPNgd5PqoioYwBt4qX",
            "symbol": "ASGARD",
            "name": "Asgard Army DAO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQTN97PwrQGkbNepQxjvcYfRPYbPNgd5PqoioYwBt4qX/logo.png",
            "tags": [
                "DOA",
                "asgard-army",
                "nft"
            ],
            "extensions": {
                "website": "https://asgardarmy.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DogscQVvNVj7ndEnhWiCXPVPKKwNy9fJd4ATF7mVi5J",
            "symbol": "DSC",
            "name": "DoggyStyle Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Doggystylecoin/DSC/main/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "coinmarketcap": "https://coinmarketcap.com/currencies/doggystyle-coin/",
                "discord": "https://discord.gg/UACVDtQcCp",
                "serumV3Usdc": "36wb7iVPv5Rn5Q1ppqVmtJaxLdwgUYkr6U3SGa9iL3W1",
                "twitter": "https://twitter.com/dscoinsolana",
                "website": "https://dscsolana.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9i5WvFTCpt16Zv1Kk7mE5m9hqZ1zAoaVQwB76gcFhqLv",
            "symbol": "SENDIT",
            "name": "Sendit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9i5WvFTCpt16Zv1Kk7mE5m9hqZ1zAoaVQwB76gcFhqLv/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://get.stokedtosendit.com"
            }
        },
        {
            "chainId": 101,
            "address": "HNpdP2rL6FR6jM3bDxFX2Zo32D1YG2ZCztf9zzCrKMEX",
            "symbol": "SER",
            "name": "Secretum",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HNpdP2rL6FR6jM3bDxFX2Zo32D1YG2ZCztf9zzCrKMEX/logo.svg",
            "extensions": {
                "telegram": "https://t.me/joinchat/ZTXM0J9pOMozOGY0",
                "twitter": "https://twitter.com/appsecretum",
                "website": "https://secretum.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3NcCuwvTMnnf7TU2UEVhp6v2nzbLXQiDgzQySS6m8A7P",
            "symbol": "SQUIDGAME",
            "name": "Squid Game",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3NcCuwvTMnnf7TU2UEVhp6v2nzbLXQiDgzQySS6m8A7P/logo.png",
            "tags": [
                "Meme",
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "7S5QMfpwnai8nF8RmHnwfGDQwGa2TURDvkHXH17tMjdn",
            "symbol": "betaFANT",
            "name": "betaFANT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7S5QMfpwnai8nF8RmHnwfGDQwGa2TURDvkHXH17tMjdn/logo.png",
            "tags": [
                "dumby-token"
            ],
            "extensions": {
                "discord": "https://t.co/Vskz9PkBBC?amp=1",
                "twitter": "https://twitter.com/PhantasiaSports",
                "website": "https://phantasia.app/"
            }
        },
        {
            "chainId": 101,
            "address": "ArhMyF2N8XpaujYUxTTDt9EuaBCaGaccxfwaZmkm9XeF",
            "symbol": "DOGETH",
            "name": "Doge Thug",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArhMyF2N8XpaujYUxTTDt9EuaBCaGaccxfwaZmkm9XeF/logo.png",
            "tags": [
                "Meme",
                "Social",
                "Metaverse"
            ],
            "extensions": {
                "serumV3Usdc": "F1jBaKCMrxZpEgn79uduxpF2qHeqwiTQ3BwKdT6yqckd",
                "website": "https://dogethug.samoymeme.com"
            }
        },
        {
            "chainId": 101,
            "address": "D8Fc2HLd9L9V2mJnEUpnys6muJUawKYFnJWcUiaGKnyP",
            "symbol": "OOAH",
            "name": "OOAH Monkey",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D8Fc2HLd9L9V2mJnEUpnys6muJUawKYFnJWcUiaGKnyP/logo.png",
            "tags": [
                "Meme",
                "Social",
                "Metaverse"
            ],
            "extensions": {
                "serumV3Usdc": "J6ih6rFbwkx7bMRZYjaFzkAUcsLrCMxZ9CCEbtyEAJbS",
                "website": "https://ooah.samoymeme.com"
            }
        },
        {
            "chainId": 101,
            "address": "BjTUmZjNUUAPKHVdTs8yZsCmecW5isSK4AbuFihXoUwa",
            "symbol": "PUSSY",
            "name": "Pussy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjTUmZjNUUAPKHVdTs8yZsCmecW5isSK4AbuFihXoUwa/logo.png",
            "tags": [
                "Meme",
                "Social",
                "Metaverse"
            ],
            "extensions": {
                "serumV3Usdc": "ALgq4dHm4bypcsg1JRDc6VPurwDtUsdsbpYB8kDxyZ9o",
                "website": "https://pussy.samoymeme.com"
            }
        },
        {
            "chainId": 101,
            "address": "5xq71UHmPSZ5s68DkXL8wrBVsWCh4zXgcn4wTWkqFdxa",
            "symbol": "JESUS",
            "name": "JESUS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5xq71UHmPSZ5s68DkXL8wrBVsWCh4zXgcn4wTWkqFdxa/logo.png",
            "tags": [
                "Meme",
                "Social",
                "Community"
            ],
            "extensions": {
                "serumV3Usdc": "7h3Pda8GwRg83NifksuZNx9xBS6voe1DyxGrQb6JqfB7",
                "website": "https://jesus.samoymeme.com"
            }
        },
        {
            "chainId": 101,
            "address": "AJpDoSsLpPpkFdph1EJCEh4fxpLuPE7NAWhqG5vVBeqa",
            "symbol": "CRY",
            "name": "Crypunks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJpDoSsLpPpkFdph1EJCEh4fxpLuPE7NAWhqG5vVBeqa/logo.png",
            "tags": [
                "utility-token",
                "Metaverse",
                "Gamefi"
            ],
            "extensions": {
                "discord": "https://discord.gg/NDacA7b6AQ",
                "twitter": "https://twitter.com/crypunks",
                "website": "https://crypunks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8xAKtGcMFfjzcN1AuGufkpSjKQ6i9xmZeW1GHNzSNE1j",
            "symbol": "SOLGR",
            "name": "SOL GOLDEN RETRIEVER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8xAKtGcMFfjzcN1AuGufkpSjKQ6i9xmZeW1GHNzSNE1j/logo.png",
            "tags": [
                "Meme-token",
                "DEFI",
                "Dog",
                "Community"
            ],
            "extensions": {
                "discord": "https://discord.gg/2ZPNmRrNpW",
                "telegram": "https://t.me/SOLGR_Official",
                "twitter": "https://twitter.com/SOLGR_Official",
                "website": "https://sogrr.com"
            }
        },
        {
            "chainId": 101,
            "address": "9CZHvdrHt48GMMz7EbQ7iQM3ShrUW6vroipVj3WWrdHA",
            "symbol": "SOLGR",
            "name": "SOL GOLDEN RETRIEVER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8xAKtGcMFfjzcN1AuGufkpSjKQ6i9xmZeW1GHNzSNE1j/logo.png",
            "tags": [
                "Meme-token",
                "DEFI",
                "Dog",
                "Community"
            ],
            "extensions": {
                "discord": "https://discord.gg/2ZPNmRrNpW",
                "telegram": "https://t.me/SOLGR_Official",
                "twitter": "https://twitter.com/SOLGR_Official",
                "website": "https://solgrr.com"
            }
        },
        {
            "chainId": 101,
            "address": "EjCtfmGrsWePGJmE46gvB9r6oVRErgA2JA4Q5T3k8dUj",
            "symbol": "SOLGR",
            "name": "SOL GOLDEN RETRIEVER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjCtfmGrsWePGJmE46gvB9r6oVRErgA2JA4Q5T3k8dUj/logo.png",
            "tags": [
                "Meme-token",
                "DEFI",
                "Dog",
                "Community"
            ],
            "extensions": {
                "discord": "https://discord.gg/2ZPNmRrNpW",
                "telegram": "https://t.me/SOLGR_Official",
                "twitter": "https://twitter.com/SOLGR_Official",
                "website": "https://solgrr.com"
            }
        },
        {
            "chainId": 101,
            "address": "4ZEDNmqoLbzwJVAJZNhRgz31Da8DauDkpSfH9iU2vXA4",
            "symbol": "CATOMIAOU",
            "name": "Cato Miaouss",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ZEDNmqoLbzwJVAJZNhRgz31Da8DauDkpSfH9iU2vXA4/logo.png",
            "tags": [
                "Meme",
                "community"
            ],
            "extensions": {
                "serumV3Usdc": "FFJpw24jsFWgjcJKffjBc9FzAeoMpzedHBn9WhwwtcmD",
                "website": "https://catomiaouss.samoymeme.com"
            }
        },
        {
            "chainId": 101,
            "address": "AMNoi4727tzy7adu4wnx3cN2VQbQdG71DqaPoSm7isJ3",
            "symbol": "BITCH",
            "name": "Bitch Of Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMNoi4727tzy7adu4wnx3cN2VQbQdG71DqaPoSm7isJ3/logo.png",
            "tags": [
                "Meme",
                "community",
                "utility-token"
            ],
            "extensions": {
                "serumV3Usdc": "2N78M8HZj2R9rSHxVXx6QWSvFvvnmhoEbsNPfrBdtdGo",
                "website": "https://bitch.samoymeme.com"
            }
        },
        {
            "chainId": 101,
            "address": "HdjMPYYKaAgHr6Son56hGaSP3CEkDvD67bVzGuVgfz8S",
            "symbol": "SHIBETOSHI",
            "name": "Shibetoshi Nakamoto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HdjMPYYKaAgHr6Son56hGaSP3CEkDvD67bVzGuVgfz8S/logo.png",
            "tags": [
                "meme",
                "community",
                "NFTs",
                "DEFI",
                "Dog"
            ],
            "extensions": {
                "serumV3Usdc": "91WiupLKLjP8ENihdgiZ53j49aosNm1EYXdLbRD6GAY4",
                "twitter": "https://twitter.com/shibetoshi_naka",
                "website": "https://www.billym2k.net/"
            }
        },
        {
            "chainId": 101,
            "address": "4ZwWddrPzfgMxyEgQ7kzVrqoqX5D9BQJPwduQUBMmePs",
            "symbol": "APEM",
            "name": "APEMOON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ZwWddrPzfgMxyEgQ7kzVrqoqX5D9BQJPwduQUBMmePs/logo.png",
            "tags": [
                "Meme-token",
                "DEFI",
                "Community"
            ],
            "extensions": {
                "telegram": "https://t.me/ApeMoonSOL",
                "twitter": "https://twitter.com/ApemoonSOL",
                "website": "https://t.me/ApeMoonSOL"
            }
        },
        {
            "chainId": 101,
            "address": "QuYNbuTjnAUQ8YxtrmGfu8P1UAEvcG3CngFpXCo3Cts",
            "symbol": "APEM",
            "name": "APEMOON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/QuYNbuTjnAUQ8YxtrmGfu8P1UAEvcG3CngFpXCo3Cts/logo.png",
            "tags": [
                "Meme-token",
                "DEFI",
                "Community"
            ],
            "extensions": {
                "telegram": "https://t.me/ApeMoonSOL",
                "twitter": "https://twitter.com/ApemoonSOL",
                "website": "https://t.me/ApeMoonSOL"
            }
        },
        {
            "chainId": 101,
            "address": "BKGp1At3yLDK1NE2gfMuwv1QMAHBwnqgSdULsyzjUagA",
            "symbol": "KissMe",
            "name": "Kiss Me ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKGp1At3yLDK1NE2gfMuwv1QMAHBwnqgSdULsyzjUagA/logo.png",
            "tags": [
                "meme",
                "community",
                "utility-token"
            ],
            "extensions": {
                "serumV3Usdc": "91WiupLKLjP8ENihdgiZ53j49aosNm1EYXdLbRD6GAY4",
                "website": "https://kissme.samoymeme.com"
            }
        },
        {
            "chainId": 101,
            "address": "3in9a9yHtdjDFRjDyGTTpGUwJpT9zZBcyjQ8J7nqqNtq",
            "symbol": "DoggyStyle",
            "name": "Doggy Style",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3in9a9yHtdjDFRjDyGTTpGUwJpT9zZBcyjQ8J7nqqNtq/logo.png",
            "tags": [
                "Meme",
                "community",
                "utility-token"
            ],
            "extensions": {
                "serumV3Usdc": "yzKcpbgRmwE5ULL72C4JPUZYQ2S8ZHRFJnrXgQrKyvB",
                "website": "https://doggystyle.samoymeme.com"
            }
        },
        {
            "chainId": 101,
            "address": "osRA9qNxrtxF4kPAucsv9xHTu4YDrH6TqMMt9B2PsHa",
            "symbol": "SHIBETOSHI",
            "name": "Shibetoshi Nakamoto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/osRA9qNxrtxF4kPAucsv9xHTu4YDrH6TqMMt9B2PsHa/logo.png",
            "tags": [
                "meme",
                "community",
                "NFTs",
                "DEFI",
                "Dog"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BillyM2k",
                "website": "https://www.billym2k.net/"
            }
        },
        {
            "chainId": 101,
            "address": "FEdyfKQi9hoS5RtX7UMsof12UZvary8ahxHaLJUVaduX",
            "symbol": "YII",
            "name": "YIICoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FEdyfKQi9hoS5RtX7UMsof12UZvary8ahxHaLJUVaduX/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://dockcoin.me/"
            }
        },
        {
            "chainId": 101,
            "address": "GEdo2wNT5DDy7pQqApKrpc7MVnLmC3GJnb55iRmGieAi",
            "symbol": "DogeKing",
            "name": "Doge King",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEdo2wNT5DDy7pQqApKrpc7MVnLmC3GJnb55iRmGieAi/logo.png",
            "tags": [
                "meme-token",
                "DOGE",
                "NFT",
                "community"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DogeKing_SOL",
                "website": "https://solscan.io/token/GEdo2wNT5DDy7pQqApKrpc7MVnLmC3GJnb55iRmGieAi"
            }
        },
        {
            "chainId": 101,
            "address": "CPL7TvVnQXQ8aN2DytF53uskyYAVxgNx5z2waJrc3Cev",
            "symbol": "Cate",
            "name": "CateCoin SOL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPL7TvVnQXQ8aN2DytF53uskyYAVxgNx5z2waJrc3Cev/logo.png",
            "tags": [
                "meme-token",
                "CATE",
                "NFT",
                "community"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CatecoinSol",
                "website": "https://solscan.io/token/CPL7TvVnQXQ8aN2DytF53uskyYAVxgNx5z2waJrc3Cev"
            }
        },
        {
            "chainId": 101,
            "address": "BWm92csusaUNPWu8M2aC2UTcGQVJsrhH7JYtd47zN7FA",
            "symbol": "DOELON",
            "name": "Dogs Of Elon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BWm92csusaUNPWu8M2aC2UTcGQVJsrhH7JYtd47zN7FA/logo.png",
            "tags": [
                "meme-token",
                "DOGE",
                "NFT",
                "community"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ElonDogs",
                "website": "https://solscan.io/token/BWm92csusaUNPWu8M2aC2UTcGQVJsrhH7JYtd47zN7FA"
            }
        },
        {
            "chainId": 101,
            "address": "EpxkCmZT9MmGe2UfpH7zFEhpi8RknT4BwG2VyGJPG4Ps",
            "symbol": "CUMSTAR",
            "name": "CumStar SOL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EpxkCmZT9MmGe2UfpH7zFEhpi8RknT4BwG2VyGJPG4Ps/logo.png",
            "tags": [
                "Application",
                "Payment",
                "NFT",
                "community"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CumStarSol",
                "website": "https://solscan.io/token/EpxkCmZT9MmGe2UfpH7zFEhpi8RknT4BwG2VyGJPG4Ps"
            }
        },
        {
            "chainId": 101,
            "address": "F5f9hLQ6FNHwuU3dS8CUCRy9r2deJXYCinDL6RAxsPeX",
            "symbol": "BABYFLOKISOL",
            "name": "Baby Floki Doge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F5f9hLQ6FNHwuU3dS8CUCRy9r2deJXYCinDL6RAxsPeX/logo.png",
            "tags": [
                "meme-token",
                "DOGE",
                "NFT",
                "community"
            ],
            "extensions": {
                "website": "https://solscan.io/token/F5f9hLQ6FNHwuU3dS8CUCRy9r2deJXYCinDL6RAxsPeX"
            }
        },
        {
            "chainId": 101,
            "address": "39cG39AZ4cG7oGNMe4RhD3xAzjy1nkiNgk8W6WbDCgeR",
            "symbol": "XHamster",
            "name": "xHamster",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/39cG39AZ4cG7oGNMe4RhD3xAzjy1nkiNgk8W6WbDCgeR/logo.png",
            "tags": [
                "payment-protocol",
                "Payment",
                "Videos",
                "community",
                "live-stream"
            ],
            "extensions": {
                "twitter": "https://twitter.com/xhamster_sol",
                "website": "https://solscan.io/token/39cG39AZ4cG7oGNMe4RhD3xAzjy1nkiNgk8W6WbDCgeR"
            }
        },
        {
            "chainId": 101,
            "address": "Fkbimv9CBGZANAqRJZQ732xEZ5EA4GidjeNRKiYoDY5y",
            "symbol": "FLOKI",
            "name": "Floki Inu SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fkbimv9CBGZANAqRJZQ732xEZ5EA4GidjeNRKiYoDY5y/logo.png",
            "tags": [
                "meme-token",
                "DOGE",
                "NFT",
                "community",
                "FLOKI"
            ],
            "extensions": {
                "twitter": "https://twitter.com/floki_sol",
                "website": "https://solscan.io/token/Fkbimv9CBGZANAqRJZQ732xEZ5EA4GidjeNRKiYoDY5y"
            }
        },
        {
            "chainId": 101,
            "address": "3fFHsncY59ue2HPduo1KhbZRWYRd8iek5tj88sPXMgFk",
            "symbol": "FLOKI",
            "name": "Floki Viking",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3fFHsncY59ue2HPduo1KhbZRWYRd8iek5tj88sPXMgFk/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "doge",
                "Floki"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FlokiVikingSOL",
                "website": "https://www.flokiviking.net"
            }
        },
        {
            "chainId": 101,
            "address": "37mG5XYuwMSutQnvERDUZqxumes5hYp89X2gpBbedpZ2",
            "symbol": "ELON",
            "name": "Dogelon Mars",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/37mG5XYuwMSutQnvERDUZqxumes5hYp89X2gpBbedpZ2/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "doge",
                "MUSK"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DogeElonMarsSol",
                "website": "https://solscan.io/token/37mG5XYuwMSutQnvERDUZqxumes5hYp89X2gpBbedpZ2"
            }
        },
        {
            "chainId": 101,
            "address": "ApSAjELw31MrMMxWXPeSrsDaakmA66XE1gpJKJhhh5ix",
            "symbol": "FJB",
            "name": "FJB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApSAjELw31MrMMxWXPeSrsDaakmA66XE1gpJKJhhh5ix/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/official_fjb",
                "twitter": "https://twitter.com/official_fjb",
                "website": "http://OfficialFJB.com"
            }
        },
        {
            "chainId": 101,
            "address": "6cH34XtzNgCDwb7NFbiji1a1N8F3FgmXTrFxvzBZNVui",
            "symbol": "KINGSHIB",
            "name": "King Shiba",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cH34XtzNgCDwb7NFbiji1a1N8F3FgmXTrFxvzBZNVui/logo.png",
            "tags": [
                "meme",
                "community",
                "NFTs",
                "DEFI",
                "Dog"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kingshiba_sol",
                "website": "https://www.kingshibaofficial.com/"
            }
        },
        {
            "chainId": 101,
            "address": "B8NrYG3ZGbmDS6Xv5PUSdpJmXor9VvtxibvDRKNq3rnc",
            "symbol": "METASOL",
            "name": "META SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B8NrYG3ZGbmDS6Xv5PUSdpJmXor9VvtxibvDRKNq3rnc/logo.png",
            "tags": [
                "community",
                "NFTs",
                "DEFI",
                "Facebook"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetaSol2"
            }
        },
        {
            "chainId": 101,
            "address": "9HEGaeiuK1YNq4v69kVXqF1ssnpndpkVE9hbo8PSftGh",
            "symbol": "RUGP",
            "name": "Rug Pull Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9HEGaeiuK1YNq4v69kVXqF1ssnpndpkVE9hbo8PSftGh/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "description": "This is a coin on a mission to inform you of the dangers investing and prevent you from being scammed.",
                "twitter": "https://twitter.com/rug_puller",
                "website": "https://rug-pull-coin.com"
            }
        },
        {
            "chainId": 101,
            "address": "8cDqXAoivNdvwd1sy74rTfMeYMM4J1u1ey8WRFYk5RD",
            "symbol": "YEE",
            "name": "YEECoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8cDqXAoivNdvwd1sy74rTfMeYMM4J1u1ey8WRFYk5RD/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://solana-nft.io/"
            }
        },
        {
            "chainId": 103,
            "address": "9tjgbaSSEyPgRgTLVaTzzZR46xPq1jU6d7fB217czRdK",
            "symbol": "QAI",
            "name": "Quartic AI Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9tjgbaSSEyPgRgTLVaTzzZR46xPq1jU6d7fB217czRdK/logo.png",
            "tags": [
                "utility-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7ouSGk4PvjczBVKqXaV5TCuYpZgw8KhcJovvUfyUdsSv",
            "symbol": "MBLZ",
            "name": "MARBLZ Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ouSGk4PvjczBVKqXaV5TCuYpZgw8KhcJovvUfyUdsSv/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://marblz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2vspUf3wUsTPARa7EngGD3uoU6YgT9HRDhFpTdxa7rrW",
            "symbol": "CRYO",
            "name": "cryogen",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vspUf3wUsTPARa7EngGD3uoU6YgT9HRDhFpTdxa7rrW/logo.png",
            "tags": [
                "utility-token",
                "Metaverse",
                "Gamefi"
            ],
            "extensions": {
                "discord": "https://discord.gg/NDacA7b6AQ",
                "twitter": "https://twitter.com/crypunks",
                "website": "https://crypunks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3WLDzzYXvhAwyX4xLAVuvVHfh6Eoq8uieKC8HhmLAhDe",
            "symbol": "DANDO",
            "name": "Dancing Doge",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3WLDzzYXvhAwyX4xLAVuvVHfh6Eoq8uieKC8HhmLAhDe/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DandoToken",
                "website": "https://dancingdoge.co/"
            }
        },
        {
            "chainId": 101,
            "address": "9K4uNquZjVSBBN6fBsp62gtYLropyAxAbdZC7D9XErih",
            "symbol": "OPPA",
            "name": "OPPA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9K4uNquZjVSBBN6fBsp62gtYLropyAxAbdZC7D9XErih/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/XN6CbxD6m7",
                "twitter": "https://twitter.com/OppaNFT",
                "website": "https://nft.heyoppa.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5Rxq1GiVeuhhgcy3BEHLtjrHir1RKcNVpi5J6ZGMVDxj",
            "symbol": "BERS",
            "name": "Bers Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Rxq1GiVeuhhgcy3BEHLtjrHir1RKcNVpi5J6ZGMVDxj/logo.png",
            "tags": [
                "mongolian-token",
                "gamefi",
                "ads-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/berscoin",
                "website": "https://berscoin.com/",
                "youtube": "https://www.youtube.com/c/BersCoinBersTube"
            }
        },
        {
            "chainId": 101,
            "address": "ZEExktbqMM5ZMS569pCNbzky92KeEmiFeVwR3exfBNn",
            "symbol": "ZEE",
            "name": "ZEE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZEExktbqMM5ZMS569pCNbzky92KeEmiFeVwR3exfBNn/logo.png",
            "tags": [
                "zoints"
            ],
            "extensions": {
                "website": "https://zoints.com"
            }
        },
        {
            "chainId": 101,
            "address": "AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer",
            "symbol": "TICKET",
            "name": "The Ticket Finance",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer/logo.png",
            "tags": [
                "launchpad",
                "dex",
                "defi",
                "exchange",
                "utility-token"
            ],
            "extensions": {
                "serumV3Usdc": "HBfdGEMeQQpGogC3Li4uhRqZxGZAVLM9zimt31vdtSdA",
                "telegram": "https://t.me/theticketfinance",
                "twitter": "https://twitter.com/TheTicketSol",
                "website": "https://theticket.finance"
            }
        },
        {
            "chainId": 101,
            "address": "2vfgEPJStq761qrkyh8xedrj9zpew1GQ8CobjtQ4wtyM",
            "symbol": "BOO",
            "name": "BOO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vfgEPJStq761qrkyh8xedrj9zpew1GQ8CobjtQ4wtyM/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solaghosts",
                "website": "https://www.solaghosts.io"
            }
        },
        {
            "chainId": 101,
            "address": "CSQn7G3SmbBVFRMvNH5SJV5sd2HipWSCphfDVcXwY3K6",
            "symbol": "DANG",
            "name": "DANG",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CSQn7G3SmbBVFRMvNH5SJV5sd2HipWSCphfDVcXwY3K6/logo.png",
            "extensions": {
                "website": "https://dang.gg"
            }
        },
        {
            "chainId": 101,
            "address": "G5gqGPsrpkRYZPThJJpoVQRtgjo8zapPZ27iCSp2wPX",
            "symbol": "AGG",
            "name": "Aggie Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G5gqGPsrpkRYZPThJJpoVQRtgjo8zapPZ27iCSp2wPX/logo.png",
            "tags": [
                "aggie",
                "aggie-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AVKnbqNQgXDY8kbnno9eSGfwpVz5idimBnDKiz1vbWAh",
            "symbol": "PART",
            "name": "Particle",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVKnbqNQgXDY8kbnno9eSGfwpVz5idimBnDKiz1vbWAh/logo.png",
            "tags": [
                "DeFi",
                "NFT",
                "Gaming"
            ],
            "extensions": {
                "coingeckoId": "particle-technology",
                "twitter": "https://twitter.com/particle_techno",
                "website": "https://particle.technology/"
            }
        },
        {
            "chainId": 101,
            "address": "HYoGYzMcbYq3tAvpg15d8VFYVHw6jWEVuGgpNTrG8hps",
            "symbol": "DOGELON",
            "name": "DOGELON SOLANA",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYoGYzMcbYq3tAvpg15d8VFYVHw6jWEVuGgpNTrG8hps/logo.png",
            "tags": [
                "meme"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/A89B9rsB",
                "twitter": "https://twitter.com/DogelonSol",
                "website": "https://dogelonsol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2kMjMxSLLY3RP1Svg8THnoiAfnaScAemGUhVRF9bYcC7",
            "symbol": "BPCoin",
            "name": "BPCoin",
            "decimals": 9,
            "logoURI": "",
            "extensions": {
                "instagram": "https://instagram.com/bricksproperties",
                "website": "https://bricks.properties"
            }
        },
        {
            "chainId": 103,
            "address": "4p9KCkzJ26JDNsQY6FJHx8wn2N8UtTfA9KNduWEuLN9b",
            "symbol": "UO",
            "name": "UOWN Coin",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4p9KCkzJ26JDNsQY6FJHx8wn2N8UtTfA9KNduWEuLN9b/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://www.uown.co"
            }
        },
        {
            "chainId": 101,
            "address": "7zhbkbKpGaUsJW7AD4yyAfGGoy53Xx2H3Ai5BKcwGKHw",
            "symbol": "NOM",
            "name": "nom",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zhbkbKpGaUsJW7AD4yyAfGGoy53Xx2H3Ai5BKcwGKHw/logo.png",
            "tags": [
                "dao",
                "meme-token",
                "Defi",
                "Gamefi"
            ],
            "extensions": {
                "discord": "https://discord.gg/h7CZnKCb",
                "twitter": "https://twitter.com/TheNomDAO",
                "website": "https://www.nomdao.com"
            }
        },
        {
            "chainId": 103,
            "address": "RMRUKEmLrdjYSpd7gxQQ2y4VuFcM8jkanXaDNuMdaCZ",
            "symbol": "RM",
            "name": "Ringgit Malaysia",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RMRUKEmLrdjYSpd7gxQQ2y4VuFcM8jkanXaDNuMdaCZ/logo.png",
            "tags": [
                "stablecoin",
                "ringgit",
                "malaysia"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ringgitfinance",
                "website": "https://ringgit.finance"
            }
        },
        {
            "chainId": 101,
            "address": "Bp2vDyLQHE7nfx1e4h4E1mFEeMKk36PnvpXfxxPWm5dZ",
            "symbol": "SOLPANDS",
            "name": "SOLPANDS",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/m07n4vn/photo-2021-10-30-00-38-55.jpg",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SOLPANDS"
            }
        },
        {
            "chainId": 101,
            "address": "6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr",
            "symbol": "KITTY",
            "name": "Kitty Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr/logo.png",
            "tags": [
                "meme-token",
                "kitty",
                "cat"
            ],
            "extensions": {
                "coingeckoId": "kitty-coin-solana",
                "coinmarketcap": "https://coinmarketcap.com/currencies/kitty-coin-solana/",
                "discord": "https://discord.gg/kittycoinsolana",
                "serumV3Usdc": "FAHa34qbNbvtEBHgjuALk4WLJMwxJTtV6Z3V3p79XLWG",
                "serumV3Usdt": "H8VLs7VYaBg3rg1NvVNJKb1QWMf3kzieTPkgPmVffXTx",
                "telegram": "https://t.me/kittycoinsolana_off",
                "twitter": "https://twitter.com/KittyCoinSolana",
                "website": "https://kittycoinsolana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FZMcCAq9U65mymBjUxKXPUJehDJMLg54Ud5bTrmbaHYS",
            "symbol": "DCN",
            "name": "DoodleCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZMcCAq9U65mymBjUxKXPUJehDJMLg54Ud5bTrmbaHYS/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://doodlecoin.me/"
            }
        },
        {
            "chainId": 101,
            "address": "DCDUaGKLHcwEXdd2MiUYmW4PFtzCfCxncUZ5UZyGxdqh",
            "symbol": "KATZ",
            "name": "MeerkatCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DCDUaGKLHcwEXdd2MiUYmW4PFtzCfCxncUZ5UZyGxdqh/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ynkkE3raZf",
                "twitter": "https://twitter.com/mmccsolana",
                "website": "https://meerkatmillionaires.club"
            }
        },
        {
            "chainId": 101,
            "address": "9wPT3uJrH43TPPupYyaywXaaqBNLTxMDGoaAvnz4RMMR",
            "symbol": "DKC",
            "name": "DockCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9wPT3uJrH43TPPupYyaywXaaqBNLTxMDGoaAvnz4RMMR/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://dockcoin.me/"
            }
        },
        {
            "chainId": 101,
            "address": "Bo2kpetkHvtdjASpeRRiugzmdFhvbVsCMH6aq4mNd8TL",
            "symbol": "NETX",
            "name": "Syncline Health Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bo2kpetkHvtdjASpeRRiugzmdFhvbVsCMH6aq4mNd8TL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/joinchat/SKSLdG0sF53TFK6_",
                "twitter": "https://twitter.com/synclinenetwork",
                "website": "https://syncline.network"
            }
        },
        {
            "chainId": 101,
            "address": "6gNNYnBxXccu1PSzFDqhQdNACJNF7TEQc6kPQwZ8Zwv",
            "symbol": "INK",
            "name": "Octopus Ink",
            "decimals": 0,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmV6Au6eeGiS4F7qkWaueNDLyx2vV6fs3q67opyzVDKFz6",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/nSMc9EjvT4",
                "twitter": "https://twitter.com/socialoctopusio",
                "website": "https://socialoctopus.io"
            }
        },
        {
            "chainId": 101,
            "address": "Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3",
            "symbol": "MEND",
            "name": "Mend",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/mendappinc",
                "website": "https://mend.house"
            }
        },
        {
            "chainId": 101,
            "address": "J9yYvSXrtMV749XAbcFeMFpeY4AFwkFq9WpNDmmfV81Q",
            "symbol": "POCH",
            "name": "Poochu Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J9yYvSXrtMV749XAbcFeMFpeY4AFwkFq9WpNDmmfV81Q/logo.png",
            "tags": [
                "stake-pool-token",
                "utility-token",
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7YhfUG27m7ceDCBnB48dGy4mAJab2hqi6YKkp9Ho7ybv",
            "symbol": "BANANA",
            "name": "Banana Bucks",
            "decimals": 2,
            "logoURI": "https://i.ibb.co/DMQNjc0/bananabucks-thumbnail.jpg",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DVH2ggAc",
                "twitter": "https://twitter.com/solbananabucks"
            }
        },
        {
            "chainId": 101,
            "address": "snowRZ1wtHa7eiBhJVUqkyFn8P8zwkmL4UTXU7Zdgbu",
            "symbol": "SNOW",
            "name": "Snowflake",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/snowRZ1wtHa7eiBhJVUqkyFn8P8zwkmL4UTXU7Zdgbu/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/snowflake_sol",
                "website": "https://snowflake.so"
            }
        },
        {
            "chainId": 101,
            "address": "inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY",
            "symbol": "IN",
            "name": "Sol Invictus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY/logo-owl.png",
            "tags": [
                "decentralizedreserve",
                "utility-token",
                "DeFi",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "invictus",
                "discord": "https://discord.gg/invictusdao",
                "serumV3Usdc": "49vwM54DX3JPXpey2daePZPmimxA4CrkXLZ6E1fGxx2Z",
                "twitter": "https://twitter.com/InvictusDAO",
                "website": "https://invictusdao.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "7puG5H5Mc6QpvaXjAVLr6GnL5hhUMnpLcUm8G3mEsgHQ",
            "symbol": "LEONIDAS",
            "name": "Leonidas Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7puG5H5Mc6QpvaXjAVLr6GnL5hhUMnpLcUm8G3mEsgHQ/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "coingeckoId": "leonidas-token",
                "discord": "https://www.discord.com/invite/drN8FUruAu",
                "instagram": "https://www.instagram.com/leonidas_token",
                "medium": "https://leonidastoken.medium.com",
                "reddit": "https://www.reddit.com/r/leonidas_token",
                "serumV3Usdc": "DTEmm1nC7n8vb3KmVabT6dEEnSNeDXNu1jWN4u2DfD7Z",
                "telegram": "https://t.me/leonidas_token",
                "twitter": "https://twitter.com/leonidas_token",
                "website": "https://www.leonidastoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "8pMdj3AvCV4EbqRBCHMyDAVeMtBmP4wMhCDXqAtFcAqD",
            "symbol": "GROOT",
            "name": "Groot",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pMdj3AvCV4EbqRBCHMyDAVeMtBmP4wMhCDXqAtFcAqD/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://groot.sol/"
            }
        },
        {
            "chainId": 101,
            "address": "CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z",
            "symbol": "SHIB",
            "name": "SHIBA INU (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
                "assetContract": "https://etherscan.io/address/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "shiba-inu",
                "serumV3Usdc": "Er7Jp4PADPVHifykFwbVoHdkL1RtZSsx9zGJrPJTrCgW"
            }
        },
        {
            "chainId": 101,
            "address": "HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5",
            "symbol": "AXSet",
            "name": "Axie Infinity Shard (Portal from Ethereum)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
                "assetContract": "https://etherscan.io/address/0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "axie-infinity",
                "serumV3Usdc": "HZCheduA4nsSuQpVww1TiyKZpXSAitqaXxjBD2ymg22X"
            }
        },
        {
            "chainId": 101,
            "address": "4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU",
            "symbol": "DYDX",
            "name": "dYdX (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x92d6c1e31e14520e676a687f0a93788b716beff5",
                "assetContract": "https://etherscan.io/address/0x92d6c1e31e14520e676a687f0a93788b716beff5",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "dydx",
                "serumV3Usdc": "GNmTGd6iQvQApXgsyvHepDpCnvdRPiWzRr8kzFEMMNKN"
            }
        },
        {
            "chainId": 101,
            "address": "G5V7t3ZHTUGi6xGfk5nc42P1iRkNN3JhaFwFXkvyDmz5",
            "symbol": "tuBTC",
            "name": "tuBTC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuBTC.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "HRYfSMc1gYEvxo9zsh14jYHKxAcfJfoVakKtRtJEomb8",
            "symbol": "tuORCA",
            "name": "tuORCA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuORCA.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "CEqYVZCL7sHQ8gChh1yL3uajc2UDs6DXuYjPZyRox6MC",
            "symbol": "tuSAMO",
            "name": "tuSAMO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuSAMO.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "4QSK13NTKxTBExbMjHFsj3QfHBn4Hfp3DGLSba8GvFvh",
            "symbol": "tuSRM",
            "name": "tuSRM",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuSRM.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "8cn7JcYVjDZesLa3RTt3NXne4WcDw9PdUneQWuByehwW",
            "symbol": "tumSOL",
            "name": "tumSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tumSOL.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "85SyKDLXZPC1sqdFqasywQojutGhDCobbPHM6zczD1Li",
            "symbol": "tuwhETH",
            "name": "tuwhETH",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sol-farm/token-logos/dad49dff5a8cb4abc6c3ccae35dc845a2af46f98/tuwhETH.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TulipProtocol",
                "waterfallbot": "https://bit.ly/TULIPwaterfall",
                "website": "https://tulip.garden"
            }
        },
        {
            "chainId": 101,
            "address": "6nKUU36URHkewHg5GGGAgxs6szkE4VTioGUT5txQqJFU",
            "symbol": "ELON",
            "name": "Dogelon Mars (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6nKUU36URHkewHg5GGGAgxs6szkE4VTioGUT5txQqJFU/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3",
                "assetContract": "https://etherscan.io/address/0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "dogelon-mars"
            }
        },
        {
            "chainId": 101,
            "address": "9ARQsBfAn65q522cEqSJuse3cLhA31jgWDBGQHeiq7Mg",
            "symbol": "ALICE",
            "name": "My Neighbor Alice (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ARQsBfAn65q522cEqSJuse3cLhA31jgWDBGQHeiq7Mg/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xac51066d7bec65dc4589368da368b212745d63e8",
                "assetContract": "https://etherscan.io/address/0xac51066d7bec65dc4589368da368b212745d63e8",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "my-neighbor-alice"
            }
        },
        {
            "chainId": 101,
            "address": "EXExWvT6VyYxEjFzF5BrUxt5GZMPVZnd48y3iWrRefMq",
            "symbol": "ENJ",
            "name": "Enjin (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EXExWvT6VyYxEjFzF5BrUxt5GZMPVZnd48y3iWrRefMq/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
                "assetContract": "https://etherscan.io/address/0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "enjin-coin"
            }
        },
        {
            "chainId": 101,
            "address": "5TtSKAamFq88grN1QGrEaZ1AjjyciqnCya1aiMhAgFvG",
            "symbol": "CHZ",
            "name": "Chiliz (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5TtSKAamFq88grN1QGrEaZ1AjjyciqnCya1aiMhAgFvG/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x3506424f91fd33084466f402d5d97f05f8e3b4af",
                "assetContract": "https://etherscan.io/address/0x3506424f91fd33084466f402d5d97f05f8e3b4af",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "chiliz"
            }
        },
        {
            "chainId": 101,
            "address": "Afe9gSG8NcWicJtC58tUPGWG6pUcdK29d59BJuSAsePJ",
            "symbol": "OHM_v1",
            "name": "Olympus V1 (Portal)",
            "decimals": 8,
            "logoURI": "https://cloudflare-ipfs.com/ipfs/QmcDGyz7Ag6PJB7zHQt1uMnJkFjvawAGwWp2UbmoEAtpXp/",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5",
                "assetContract": "https://etherscan.io/address/0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585"
            }
        },
        {
            "chainId": 101,
            "address": "BCsFXYm81iqXyYmrLKgAp3AePcgLHnirb8FjTs6sjM7U",
            "symbol": "SPELL",
            "name": "Spell Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCsFXYm81iqXyYmrLKgAp3AePcgLHnirb8FjTs6sjM7U/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x090185f2135308bad17527004364ebcc2d37e5f6",
                "assetContract": "https://etherscan.io/address/0x090185f2135308bad17527004364ebcc2d37e5f6",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "spell-token"
            }
        },
        {
            "chainId": 101,
            "address": "DiJut4U3CU8b3bRgwfyqtJMJ4wjzJHaX6hudamjH46Km",
            "symbol": "ICE",
            "name": "IceToken (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiJut4U3CU8b3bRgwfyqtJMJ4wjzJHaX6hudamjH46Km/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xf16e81dce15b08f326220742020379b855b87df9",
                "assetContract": "https://etherscan.io/address/0xf16e81dce15b08f326220742020379b855b87df9",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "popsicle-finance"
            }
        },
        {
            "chainId": 101,
            "address": "64L6o4G2H7Ln1vN7AHZsUMW4pbFciHyuwn4wUdSbcFxh",
            "symbol": "KEEP",
            "name": "Keep Network (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64L6o4G2H7Ln1vN7AHZsUMW4pbFciHyuwn4wUdSbcFxh/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x85eee30c52b0b379b046fb0f85f4f3dc3009afec",
                "assetContract": "https://etherscan.io/address/0x85eee30c52b0b379b046fb0f85f4f3dc3009afec",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "keep-network"
            }
        },
        {
            "chainId": 101,
            "address": "3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh",
            "symbol": "WBTC",
            "name": "Wrapped BTC (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
                "assetContract": "https://etherscan.io/address/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "wrapped-bitcoin"
            }
        },
        {
            "chainId": 101,
            "address": "C7NNPWuZCNjZBfW5p6JvGsR8pUdsRpEdP1ZAhnoDwj7h",
            "symbol": "MATICet",
            "name": "Matic (Portal from Ethereum)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7NNPWuZCNjZBfW5p6JvGsR8pUdsRpEdP1ZAhnoDwj7h/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
                "assetContract": "https://etherscan.io/address/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "polygon"
            }
        },
        {
            "chainId": 101,
            "address": "Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG",
            "symbol": "MATICpo",
            "name": "Matic (Portal from Polygon)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
                "assetContract": "https://polygonscan.com/address/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
                "bridgeContract": "https://polygonscan.com/address/0x5a58505a96d1dbf8df91cb21b54419fc36e93fde",
                "coingeckoId": "matic-network",
                "serumV3Usdc": "5WRoQxE59966N2XfD2wYy1uhuyKeoVJ9NBMH6r6RNYEF"
            }
        },
        {
            "chainId": 101,
            "address": "5njTmK53Ss5jkiHHZvzabVzZj6ztu6WYWpAPYgbVnbjs",
            "symbol": "QUICK",
            "name": "Quickswap (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5njTmK53Ss5jkiHHZvzabVzZj6ztu6WYWpAPYgbVnbjs/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
                "assetContract": "https://polygonscan.com/address/0x831753dd7087cac61ab5644b308642cc1c33dc13",
                "bridgeContract": "https://polygonscan.com/address/0x5a58505a96d1dbf8df91cb21b54419fc36e93fde",
                "coingeckoId": "quickswap"
            }
        },
        {
            "chainId": 101,
            "address": "J8LKx7pr9Zxh9nMhhT7X3EBmj5RzuhFrHKyJAe2F2i9S",
            "symbol": "CAKE",
            "name": "PancakeSwap Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J8LKx7pr9Zxh9nMhhT7X3EBmj5RzuhFrHKyJAe2F2i9S/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                "assetContract": "https://bscscan.com/address/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                "bridgeContract": "https://bscscan.com/address/0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7",
                "coingeckoId": "pancakeswap"
            }
        },
        {
            "chainId": 101,
            "address": "5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2",
            "symbol": "BUSDbs",
            "name": "BUSD Token (Portal from BSC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                "assetContract": "https://bscscan.com/address/0xe9e7cea3dedca5984780bafc599bd69add087d56",
                "bridgeContract": "https://bscscan.com/address/0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7",
                "coingeckoId": "binance-usd"
            }
        },
        {
            "chainId": 101,
            "address": "2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf",
            "symbol": "USDT-USDC",
            "name": "Saber USDT-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/usdc_usdt"
            }
        },
        {
            "chainId": 101,
            "address": "PaiYwHYxr4SsEWox9YmyBNJmxVG7GdauirbBcYGB7cJ",
            "symbol": "PAI-USDC",
            "name": "Saber PAI-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PaiYwHYxr4SsEWox9YmyBNJmxVG7GdauirbBcYGB7cJ/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/usdc_pai"
            }
        },
        {
            "chainId": 101,
            "address": "SLPbsNrLHv8xG4cTc4R5Ci8kB9wUPs6yn6f7cKosoxs",
            "symbol": "BTC-renBTC",
            "name": "Saber BTC-renBTC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLPbsNrLHv8xG4cTc4R5Ci8kB9wUPs6yn6f7cKosoxs/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/btc"
            }
        },
        {
            "chainId": 101,
            "address": "pBTCmyG7FaZx4uk3Q2pT5jHKWmWDn84npdc7gZXpQ1x",
            "symbol": "pBTC-renBTC",
            "name": "Saber pBTC-renBTC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pBTCmyG7FaZx4uk3Q2pT5jHKWmWDn84npdc7gZXpQ1x/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/pbtc"
            }
        },
        {
            "chainId": 101,
            "address": "CLPKiHjoU5HwpPK5L6MBXHKqFsuzPr47dM1w4An3Lnvv",
            "symbol": "CASH-USDC",
            "name": "Saber CASH-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLPKiHjoU5HwpPK5L6MBXHKqFsuzPr47dM1w4An3Lnvv/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/cash"
            }
        },
        {
            "chainId": 101,
            "address": "XUSDfnsgc2QYXRdbPAbMWoXCbBCCspRSvoGJ8o7RV9n",
            "symbol": "xUSD-USDC",
            "name": "Saber xUSD-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/XUSDfnsgc2QYXRdbPAbMWoXCbBCCspRSvoGJ8o7RV9n/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-synthetify"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/xusd"
            }
        },
        {
            "chainId": 101,
            "address": "xSoLVBNztDTUW8Kou2GJinHoe54Siu9Sk3e2uoU9aUi",
            "symbol": "xSOL-SOL",
            "name": "Saber xSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xSoLVBNztDTUW8Kou2GJinHoe54Siu9Sk3e2uoU9aUi/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-synthetify"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/xsol"
            }
        },
        {
            "chainId": 101,
            "address": "xFTTLsMdN28XHtYTTTVWYz5zwXWBm5r1WTuZ7Cc7SyA",
            "symbol": "xFTT-wFTT",
            "name": "Saber xFTT-wFTT LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xFTTLsMdN28XHtYTTTVWYz5zwXWBm5r1WTuZ7Cc7SyA/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-synthetify"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/xftt"
            }
        },
        {
            "chainId": 101,
            "address": "xETH89889mVRwsw9tSUnULsdLUPryTpijagy2YXxWyY",
            "symbol": "xETH-whETH",
            "name": "Saber xETH-whETH LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xETH89889mVRwsw9tSUnULsdLUPryTpijagy2YXxWyY/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-synthetify"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/xeth"
            }
        },
        {
            "chainId": 101,
            "address": "xBTCPvRuEuRgz5DuuUd3ju3VP5XtR2Dsu1AxyW9JpXK",
            "symbol": "xBTC-renBTC",
            "name": "Saber xBTC-renBTC LP",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xBTCPvRuEuRgz5DuuUd3ju3VP5XtR2Dsu1AxyW9JpXK/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-synthetify"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/xbtc"
            }
        },
        {
            "chainId": 101,
            "address": "UST32f2JtPGocLzsL41B3VBBoJzTm1mK1j3rwyM3Wgc",
            "symbol": "wUSTV1-USDC",
            "name": "Saber wUST_V1-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST32f2JtPGocLzsL41B3VBBoJzTm1mK1j3rwyM3Wgc/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/ust"
            }
        },
        {
            "chainId": 101,
            "address": "Daimhb91DY4e3aVaa7YCW5GgwaMT9j1ALSi2GriBvDNh",
            "symbol": "wDAIV1-USDC",
            "name": "Saber wDAI_V1-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Daimhb91DY4e3aVaa7YCW5GgwaMT9j1ALSi2GriBvDNh/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/dai"
            }
        },
        {
            "chainId": 101,
            "address": "BUSDaZjarCrQJLeHpWi7aLaKptdR1S8DFpwdDuuZu9p3",
            "symbol": "wBUSDV1-USDC",
            "name": "Saber wBUSD_V1-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUSDaZjarCrQJLeHpWi7aLaKptdR1S8DFpwdDuuZu9p3/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/busd"
            }
        },
        {
            "chainId": 101,
            "address": "LUNkiLcb2wxcqULmJvMjuM6YQhpFBadG5KZBe7qBpSE",
            "symbol": "wLUNAV1-renLUNA",
            "name": "Saber wLUNA_V1-renLUNA LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUNkiLcb2wxcqULmJvMjuM6YQhpFBadG5KZBe7qBpSE/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/luna"
            }
        },
        {
            "chainId": 101,
            "address": "FRAXXvt2ucEsxYPK4nufDy5zKhb2xysieqRBE1dQTqnK",
            "symbol": "wFRAXV1-USDC",
            "name": "Saber wFRAX_V1-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FRAXXvt2ucEsxYPK4nufDy5zKhb2xysieqRBE1dQTqnK/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/frax"
            }
        },
        {
            "chainId": 101,
            "address": "HBTCNvkwjMsEtwe2PeXUuMcu8C4Hobw6HDP2m6vpWHGo",
            "symbol": "wHBTCV1-renBTC",
            "name": "Saber wHBTC_V1-renBTC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBTCNvkwjMsEtwe2PeXUuMcu8C4Hobw6HDP2m6vpWHGo/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/hbtc"
            }
        },
        {
            "chainId": 101,
            "address": "HUSDgP5YieANhAAHD42yivX9aFS1zbodTut2Dvvkj8QS",
            "symbol": "wHUSDV1-USDC",
            "name": "Saber wHUSD_V1-USDC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUSDgP5YieANhAAHD42yivX9aFS1zbodTut2Dvvkj8QS/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/husd"
            }
        },
        {
            "chainId": 101,
            "address": "uSdKg2Cs5bCtFSeNXs7aRVNzZJauX58eCkdsfssxTdW",
            "symbol": "wUSDKV1-USDC",
            "name": "Saber wUSDK_V1-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uSdKg2Cs5bCtFSeNXs7aRVNzZJauX58eCkdsfssxTdW/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/usdk"
            }
        },
        {
            "chainId": 101,
            "address": "FTXdV5wFFhceKjcd1JRrRQTT2uB7ruMerAqbj2rj1Mz7",
            "symbol": "wFTTV1-FTT",
            "name": "Saber wFTT_V1-FTT LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTXdV5wFFhceKjcd1JRrRQTT2uB7ruMerAqbj2rj1Mz7/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/ftt"
            }
        },
        {
            "chainId": 101,
            "address": "SRMKjSJpBHJ5gSVTrimci49SnXc1LVkBi9TGF9RNYdp",
            "symbol": "wSRMV1-SRM",
            "name": "Saber wSRM_V1-SRM LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMKjSJpBHJ5gSVTrimci49SnXc1LVkBi9TGF9RNYdp/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/srm"
            }
        },
        {
            "chainId": 101,
            "address": "BADGsQo6rTxKZuqkY1kSoqhriQwZW3ZVgyPjgDk9mvyo",
            "symbol": "wibBTCV1-BTC",
            "name": "Saber wibBTC_V1-BTC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BADGsQo6rTxKZuqkY1kSoqhriQwZW3ZVgyPjgDk9mvyo/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/ibbtc"
            }
        },
        {
            "chainId": 101,
            "address": "BRENm9SgYJZuCxM4ZJiH6CmZqEBn4MLpD9cnBZDnJgeT",
            "symbol": "wibBTCV1-renBTC",
            "name": "Saber wibBTC_V1-renBTC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRENm9SgYJZuCxM4ZJiH6CmZqEBn4MLpD9cnBZDnJgeT/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v1"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/ibbtc_ren"
            }
        },
        {
            "chainId": 101,
            "address": "BSCNZ4GLnpZYv4BLk5edymk4qty8a6ZpiMbfvtv9gAzL",
            "symbol": "weBUSD-USDC",
            "name": "Saber weBUSD-USDC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BSCNZ4GLnpZYv4BLk5edymk4qty8a6ZpiMbfvtv9gAzL/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/webusd"
            }
        },
        {
            "chainId": 101,
            "address": "USDCgfM1psLGhAbx99iPA72mTySvUcVq33qhCJpm65c",
            "symbol": "weUSDC-USDC",
            "name": "Saber weUSDC-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDCgfM1psLGhAbx99iPA72mTySvUcVq33qhCJpm65c/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/weusdc"
            }
        },
        {
            "chainId": 101,
            "address": "USDTJZL2vH92K5QeCvQTTzvMXUYAdvk3v46CwZyfsue",
            "symbol": "weUSDT-USDT",
            "name": "Saber weUSDT-USDT LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDTJZL2vH92K5QeCvQTTzvMXUYAdvk3v46CwZyfsue/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/weusdt"
            }
        },
        {
            "chainId": 101,
            "address": "USDKKmk1anWU1aEn6GJ6skL3ZvcB9CBAWVkmPGQEHtz",
            "symbol": "wUSDK-USDC",
            "name": "Saber wUSDK-USDC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDKKmk1anWU1aEn6GJ6skL3ZvcB9CBAWVkmPGQEHtz/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wusdk"
            }
        },
        {
            "chainId": 101,
            "address": "HUSzWddUQbavKn24cjozm65eps8rq9yhNn5edtTLWfdz",
            "symbol": "wHUSD-USDC",
            "name": "Saber wHUSD-USDC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUSzWddUQbavKn24cjozm65eps8rq9yhNn5edtTLWfdz/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/whusd"
            }
        },
        {
            "chainId": 101,
            "address": "DAihWEjhBc8LEmV1rEekTaiC2zqE5ex7nEFkmoe1Ppp3",
            "symbol": "wDAI-USDC",
            "name": "Saber wDAI-USDC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAihWEjhBc8LEmV1rEekTaiC2zqE5ex7nEFkmoe1Ppp3/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wdai"
            }
        },
        {
            "chainId": 101,
            "address": "FRXsjEv4jF3r72FgbCXu8uLbPoZGLmCmg3EN1S3cfC4x",
            "symbol": "wFRAX-USDC",
            "name": "Saber wFRAX-USDC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FRXsjEv4jF3r72FgbCXu8uLbPoZGLmCmg3EN1S3cfC4x/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wfrax"
            }
        },
        {
            "chainId": 101,
            "address": "WTHPuMavN9HBvgUafjrL65WqQytQHDwnTAmdFB9whXA",
            "symbol": "ETH-whETH",
            "name": "Saber ETH-whETH LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WTHPuMavN9HBvgUafjrL65WqQytQHDwnTAmdFB9whXA/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wheth"
            }
        },
        {
            "chainId": 101,
            "address": "FTXjwjwWqituSXEHnL5VF1mjDhZoAyJqvHiRPsRq3KWK",
            "symbol": "aeFTT-wFTT",
            "name": "Saber aeFTT-wFTT LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTXjwjwWqituSXEHnL5VF1mjDhZoAyJqvHiRPsRq3KWK/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wftt"
            }
        },
        {
            "chainId": 101,
            "address": "USTCmQpbUGj5iTsXdnTYHZupY1QpftDZhLokSVk6UWi",
            "symbol": "wUST-USDC",
            "name": "Saber wUST-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USTCmQpbUGj5iTsXdnTYHZupY1QpftDZhLokSVk6UWi/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wust"
            }
        },
        {
            "chainId": 101,
            "address": "LUN1p1dZwSBgTv1JSdn2apdUuLanHKtgNcnpDydVFTU",
            "symbol": "wLUNA-renLUNA",
            "name": "Saber wLUNA-renLUNA LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUN1p1dZwSBgTv1JSdn2apdUuLanHKtgNcnpDydVFTU/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wluna"
            }
        },
        {
            "chainId": 101,
            "address": "cUSDDDBZRhpDW7eyUUPMuw6u1SiMnzu6i7movwf5jxk",
            "symbol": "acUSD-USDC",
            "name": "Saber acUSD-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cUSDDDBZRhpDW7eyUUPMuw6u1SiMnzu6i7movwf5jxk/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/acusd"
            }
        },
        {
            "chainId": 101,
            "address": "GEcowHQW46CrEkfAdbcsdt4SV7taCetZF4sFBXN4USDC",
            "symbol": "USDC-acUSDC",
            "name": "Saber USDC-acUSDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEcowHQW46CrEkfAdbcsdt4SV7taCetZF4sFBXN4USDC/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/acusdc"
            }
        },
        {
            "chainId": 101,
            "address": "PLYJZgSkcV8UXTWhTyf2WLCMeBoZum1Y4rXgXkoYiNj",
            "symbol": "apUSDT-USDT",
            "name": "Saber apUSDT-USDT LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PLYJZgSkcV8UXTWhTyf2WLCMeBoZum1Y4rXgXkoYiNj/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/apusdt"
            }
        },
        {
            "chainId": 101,
            "address": "APUVVYA8Xf7T1PqLyDvNxLtwQ9rRDf3RUxfMttreVzHP",
            "symbol": "apUSDC-USDC",
            "name": "Saber apUSDC-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APUVVYA8Xf7T1PqLyDvNxLtwQ9rRDf3RUxfMttreVzHP/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/apusdc"
            }
        },
        {
            "chainId": 101,
            "address": "MAiP3Zmjhc6NYiCb2xK2893ifvTTDHciCS57Kga39pC",
            "symbol": "MAI-USDC",
            "name": "Saber MAI-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MAiP3Zmjhc6NYiCb2xK2893ifvTTDHciCS57Kga39pC/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/mai"
            }
        },
        {
            "chainId": 101,
            "address": "BUSDjE9NEQ15aRFTxKFAjUf5vzqBhEgTNbYevWcSB5qp",
            "symbol": "abBUSD-USDC",
            "name": "Saber abBUSD-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUSDjE9NEQ15aRFTxKFAjUf5vzqBhEgTNbYevWcSB5qp/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/abbusd"
            }
        },
        {
            "chainId": 101,
            "address": "AET3m1Mp2SLi7QX3tSypcZWyEtk1d8dUGcwhweDiZdaR",
            "symbol": "whETH-aeWETH",
            "name": "Saber whETH-aeWETH LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AET3m1Mp2SLi7QX3tSypcZWyEtk1d8dUGcwhweDiZdaR/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aeeth"
            }
        },
        {
            "chainId": 101,
            "address": "AECpyKJWfXVyWnk2d9md5dUj3RuzHRKfQra8MakjuVRz",
            "symbol": "aeUSDC-USDC",
            "name": "Saber aeUSDC-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AECpyKJWfXVyWnk2d9md5dUj3RuzHRKfQra8MakjuVRz/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aeusdc"
            }
        },
        {
            "chainId": 101,
            "address": "aeTwxcJhujVCq6rwbJri3s6ViYifsJUCFirMjLHgHZ7",
            "symbol": "aeUSDT-USDT",
            "name": "Saber aeUSDT-USDT LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aeTwxcJhujVCq6rwbJri3s6ViYifsJUCFirMjLHgHZ7/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aeusdt"
            }
        },
        {
            "chainId": 101,
            "address": "aeDebgky5BssqgLo426rXoQTmGrAn1JjEXp6aXFNLic",
            "symbol": "aeDAI-USDC",
            "name": "Saber aeDAI-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aeDebgky5BssqgLo426rXoQTmGrAn1JjEXp6aXFNLic/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aedai"
            }
        },
        {
            "chainId": 101,
            "address": "AVC7uVb6R9B34T8zWxQMEK8twvYk26U71gworsujxFNv",
            "symbol": "aaUSDC-USDC",
            "name": "Saber aaUSDC-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVC7uVb6R9B34T8zWxQMEK8twvYk26U71gworsujxFNv/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aausdc"
            }
        },
        {
            "chainId": 101,
            "address": "AVTrxHq5P57fYZTYjMuCRWFqsrLmom2gGThNtgEgK1ip",
            "symbol": "aaUSDT-USDT",
            "name": "Saber aaUSDT-USDT LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVTrxHq5P57fYZTYjMuCRWFqsrLmom2gGThNtgEgK1ip/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aausdt"
            }
        },
        {
            "chainId": 101,
            "address": "AVDuGckLavyLr5YifViaxnoveY6rwqDezHw5kiKiRQEC",
            "symbol": "aaDAI-USDC",
            "name": "Saber aaDAI-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVDuGckLavyLr5YifViaxnoveY6rwqDezHw5kiKiRQEC/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aadai"
            }
        },
        {
            "chainId": 101,
            "address": "AVBDpg1UYpDYQLbzEnRY76R3u82PYHtDuc3NBdFS2k39",
            "symbol": "aaWBTC-renBTC",
            "name": "Saber aaWBTC-renBTC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVBDpg1UYpDYQLbzEnRY76R3u82PYHtDuc3NBdFS2k39/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aawbtc"
            }
        },
        {
            "chainId": 101,
            "address": "HLPC9r4gbeP6KagT3qJLzFj7iWcYTJs245k9tuHFQGyR",
            "symbol": "ahUSDT-USDC",
            "name": "Saber ahUSDT-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HLPC9r4gbeP6KagT3qJLzFj7iWcYTJs245k9tuHFQGyR/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/ahusdt"
            }
        },
        {
            "chainId": 101,
            "address": "HLPPmd7NzTTNiqKR6rAZYgrH9VhU47kxftecQSk2oD6J",
            "symbol": "ahBTC-renBTC",
            "name": "Saber ahBTC-renBTC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HLPPmd7NzTTNiqKR6rAZYgrH9VhU47kxftecQSk2oD6J/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/ahwbtc"
            }
        },
        {
            "chainId": 101,
            "address": "PortuzxBGYMQXeNmM9Kc6AtHLBwqSrb6xWwZ4trQ1en",
            "symbol": "pUSDT-pUSDC",
            "name": "Saber pUSDT-pUSDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PortuzxBGYMQXeNmM9Kc6AtHLBwqSrb6xWwZ4trQ1en/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-port"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/port_2pool"
            }
        },
        {
            "chainId": 101,
            "address": "SoLEao8wTzSfqhuou8rcYsVoLjthVmiXuEjzdNPMnCz",
            "symbol": "mSOL-SOL",
            "name": "Saber mSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLEao8wTzSfqhuou8rcYsVoLjthVmiXuEjzdNPMnCz/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/msol_sol"
            }
        },
        {
            "chainId": 101,
            "address": "ALP89a89ASo1h5VosTSABtQBKLBgeoaWQexYQrRCMNfV",
            "symbol": "aSOL-SOL",
            "name": "Saber aSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALP89a89ASo1h5VosTSABtQBKLBgeoaWQexYQrRCMNfV/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/asol"
            }
        },
        {
            "chainId": 101,
            "address": "PSopTFPXzTRysj2H6W8oTvYBZmJHtRcVaQaDkckifAy",
            "symbol": "pSOL-prtSOL",
            "name": "Saber pSOL-prtSOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PSopTFPXzTRysj2H6W8oTvYBZmJHtRcVaQaDkckifAy/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/psol"
            }
        },
        {
            "chainId": 101,
            "address": "PrsVdKtXDDf6kJQu5Ff6YqmjfE4TZXtBgHM4bjuvRnR",
            "symbol": "prtSOL-SOL",
            "name": "Saber prtSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PrsVdKtXDDf6kJQu5Ff6YqmjfE4TZXtBgHM4bjuvRnR/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/prtsol"
            }
        },
        {
            "chainId": 101,
            "address": "stSjCmjQ96BiGhTk8gkU22j1739R8YBQVMq7KXWTqUV",
            "symbol": "stSOL-SOL",
            "name": "Saber stSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/stSjCmjQ96BiGhTk8gkU22j1739R8YBQVMq7KXWTqUV/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/stsol"
            }
        },
        {
            "chainId": 101,
            "address": "SoCJs5Qw1D3fjGbTqxxovK15FVnYVrwvTbYcBBrZmWj",
            "symbol": "scnSOL-SOL",
            "name": "Saber scnSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoCJs5Qw1D3fjGbTqxxovK15FVnYVrwvTbYcBBrZmWj/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/socean"
            }
        },
        {
            "chainId": 101,
            "address": "kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh",
            "symbol": "KKO",
            "name": "Kineko",
            "decimals": 9,
            "logoURI": "https://kineko.io/img/cat.jpg",
            "tags": [
                "DeFi",
                "Gaming",
                "Gambling"
            ]
        },
        {
            "chainId": 101,
            "address": "3oLpKntC8W9AxiFhafRGBeALGuKdimduUXVPo1GQNHuX",
            "symbol": "Otter",
            "name": "Ottercoin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/ottercoin11/ottercoin/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/gXymhAfceY",
                "twitter": "https://twitter.com/ottercoinn",
                "website": "https://ottercoin.info/"
            }
        },
        {
            "chainId": 101,
            "address": "9mxv3qiMZdcaBeFBrNqiP7WFZLJMFZDeQEnNpDzcWpa9",
            "symbol": "GAMER",
            "name": "GamerGains",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9mxv3qiMZdcaBeFBrNqiP7WFZLJMFZDeQEnNpDzcWpa9/logo.png",
            "tags": [
                "gaming",
                "utility-token"
            ],
            "extensions": {
                "website": "https://gamergains.com"
            }
        },
        {
            "chainId": 101,
            "address": "BUGuuhPsHpk8YZrL2GctsCtXGneL1gmT5zYb7eMHZDWf",
            "symbol": "BUG",
            "name": "Bug",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUGuuhPsHpk8YZrL2GctsCtXGneL1gmT5zYb7eMHZDWf/logo.png"
        },
        {
            "chainId": 101,
            "address": "3m7A2A8HHdqmiDrjAfaddj7Hxd88FrBHA1KSoqjoELtu",
            "symbol": "WAGMI",
            "name": "WAGMI",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3m7A2A8HHdqmiDrjAfaddj7Hxd88FrBHA1KSoqjoELtu/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "wagmi-on-solana",
                "serumV3Usdc": "eju5JDyaf29jYNfq7VrVAocVxGayDEHVHHiM7MYc331",
                "twitter": "https://twitter.com/wagmi_sol",
                "website": "https://wagmionsolana.com"
            }
        },
        {
            "chainId": 101,
            "address": "MYRXNKuLELno5qnfgB1jcMAMV5aPDG2Qk9SiWCKsMNb",
            "symbol": "MYR",
            "name": "Malaysian Ringgit",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MYRXNKuLELno5qnfgB1jcMAMV5aPDG2Qk9SiWCKsMNb/logo.png",
            "tags": [
                "stablecoin",
                "malaysian",
                "ringgit"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/ringgitfinance",
                "telegram": "https://t.me/ringgitfinance"
            }
        },
        {
            "chainId": 101,
            "address": "SLT3iSYKeBuCyxvnfij4RUhMfKxZCY3s12Z5pfkTXhV",
            "symbol": "SLT",
            "name": "Solit",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLT3iSYKeBuCyxvnfij4RUhMfKxZCY3s12Z5pfkTXhV/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "coingeckoId": "solit",
                "coinmarketcap": "https://coinmarketcap.com/currencies/solit/",
                "medium": "https://solit.medium.com",
                "serumV3Usdc": "DhmedYCzs57hspLjP3SKd3NRcsvDbuPSQdooSQof4vek",
                "twitter": "https://twitter.com/solitso",
                "website": "https://solit.so"
            }
        },
        {
            "chainId": 101,
            "address": "2HLHvyQbALDRZoFV3dQxcDh5P57r2SV24JEur9ebArzZ",
            "symbol": "BU",
            "name": "Bushel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jedbontjes/Ulogo/main/Ulogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "43UsEVeUuzHhM3vtB7a9c5Hy2mC27S24Exj24HsAqCYc",
            "symbol": "WILL",
            "name": "Will",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CyberGothica/WILL/main/logo.png",
            "tags": [
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.com/channels/885149106341830666",
                "twitter": "https://twitter.com/Cyber_Gothica"
            }
        },
        {
            "chainId": 101,
            "address": "9wb7mLFrfoPooHTqdPMinN5bTa4BSQaRuAwsjQDaEZot",
            "symbol": "SKD",
            "name": "SolKanda INU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9wb7mLFrfoPooHTqdPMinN5bTa4BSQaRuAwsjQDaEZot/logo.png",
            "tags": [
                "meme",
                "Community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/KwyTsTec7C",
                "twitter": "https://twitter.com/solkandainu",
                "website": "https://www.solkandainu.com"
            }
        },
        {
            "chainId": 101,
            "address": "FcbLEFSEGwdbg99jGRqzZr4yxw5yCPNAZ79DoMYJeHve",
            "symbol": "SSM",
            "name": "Safe Shiba Moon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FcbLEFSEGwdbg99jGRqzZr4yxw5yCPNAZ79DoMYJeHve/logo.png",
            "tags": [
                "Meme-token",
                "Gaming",
                "Gambling"
            ],
            "extensions": {
                "discord": "https://discord.gg/vvY56JgKuB",
                "telegram": "https://t.me/joinchat/2dcSL62oXSpmZjIx",
                "twitter": "https://twitter.com/SafeShibaMoon",
                "website": "https://www.safeshibamoon.com/"
            }
        },
        {
            "chainId": 101,
            "address": "76SLtYw4TU2ANeHi3xwZSqFmeYT2wtbhNUJ5kJh6nykh",
            "symbol": "groww",
            "name": "GrowwCoin",
            "decimals": 4,
            "logoURI": "https://growwcoin.com/public/groww-logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/growwcoin",
                "website": "https://growwcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "476ZdKh1xue32zNzFWvnyaDEncrBEdq99sDiZXSGyyJu",
            "symbol": "TOF",
            "name": "Toffee Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/476ZdKh1xue32zNzFWvnyaDEncrBEdq99sDiZXSGyyJu/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7osS84AkAG2TCrUvrE1wfKwfAqWTCrHnaCsrsyVJd5pY",
            "symbol": "THUG",
            "name": "Fraktionalized THUG 2856",
            "decimals": 3,
            "logoURI": "https://arweave.net/q4h6GvG6MQfhXXNJTbLILbZY1OIgLqkJNHzNLClHDiw",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/Hug8MsePSvyFcnKbkchnDdDQTJbWo2GZNchr3DE6vqrV",
                "vaultPubkey": "Hug8MsePSvyFcnKbkchnDdDQTJbWo2GZNchr3DE6vqrV"
            }
        },
        {
            "chainId": 101,
            "address": "4Te4KJgjtnZe4aE2zne8G4NPfrPjCwDmaiEx9rKnyDVZ",
            "symbol": "SCT",
            "name": "SolClout",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Te4KJgjtnZe4aE2zne8G4NPfrPjCwDmaiEx9rKnyDVZ/logo.png",
            "tags": [
                "social-defi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solclout",
                "website": "https://www.solclout.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CMUA9y6exE7WNF69Lcwc8CWQ3FygyjNTxpMtttxBwXTH",
            "symbol": "GAMMA",
            "name": "GAMMACoin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/samhiggs/gammatoken/gamma_logo.png",
            "tags": [
                "meme-token",
                "work-token",
                "gamma"
            ]
        },
        {
            "chainId": 101,
            "address": "HtMeEeMQzz5LhzR673dMPS551ck3uCckYNMJxdD6GXpr",
            "symbol": "RDCER",
            "name": "Resp Dev Cert Emissions Reduction",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/MBernierPanama/RDInitiative/main/RDCER-Github-1.png",
            "tags": [
                "rd-initiative",
                "emissions-reduction"
            ],
            "extensions": {
                "discord": "https://discord.gg/5Zx3Qdng4M"
            }
        },
        {
            "chainId": 101,
            "address": "5bNttaMQtpu49ivhgRLBAAddGyawpae3SRQ93JYxLp8q",
            "symbol": "RDIDAO",
            "name": "Resp Dev Invest DAO",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/MBernierPanama/RDInitiative/main/RDIDAO-Github-1.png",
            "tags": [
                "rd-initiative",
                "invest",
                "dao"
            ],
            "extensions": {
                "discord": "https://discord.gg/5Zx3Qdng4M"
            }
        },
        {
            "chainId": 101,
            "address": "GdCxbUymsA6WVv3RLt9zUYRsiUPyeRiE2H7zFJEtfvsn",
            "symbol": "RDPART",
            "name": "Resp Dev Participate",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/MBernierPanama/RDInitiative/main/RPART-Github-2.png",
            "tags": [
                "rd-initiative",
                "participate"
            ],
            "extensions": {
                "discord": "https://discord.gg/5Zx3Qdng4M"
            }
        },
        {
            "chainId": 101,
            "address": "Hh8Y53uUrcYchjLsYPfm35PE1iJyz5tdiXiJnxh2Nnwn",
            "symbol": "SHAB",
            "name": "Shabro",
            "decimals": 8,
            "logoURI": "https://shabronet.com/images/shabro_solana_token_icon.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://shabronet.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9qXxEVGagc9ccd6b135Z8ZLr4VAWUd7T5KcmMyjYKBdB",
            "symbol": "ONGR",
            "name": "OnigiriCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9qXxEVGagc9ccd6b135Z8ZLr4VAWUd7T5KcmMyjYKBdB/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/OnigiriCoin"
            }
        },
        {
            "chainId": 101,
            "address": "3LCSAo9Hf64cxtPbArLog3PKkwGkZFN7Ttz1zLdPWPTS",
            "symbol": "MORN",
            "name": "GoodMorning Token",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/8dYwfYw/MORN-Circle.png",
            "tags": [
                "meme-token",
                "community-token",
                "discord",
                "gm"
            ],
            "extensions": {
                "discord": "https://discord.gg/J8XnfSS8AV",
                "twitter": "https://twitter.com/token_gm",
                "website": "https://gmtoken.cc/"
            }
        },
        {
            "chainId": 101,
            "address": "3nWodcvMjowsrDa2TB3FG1gaCJ4d2DstDzo4roBdMWKY",
            "symbol": "ANA",
            "name": "Anahata Meta",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3nWodcvMjowsrDa2TB3FG1gaCJ4d2DstDzo4roBdMWKY/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AnahataMeta"
            }
        },
        {
            "chainId": 103,
            "address": "HxtN5ohiptXBaqjiJCkXqWaXjTTLo3vAxCMuVevvAf8q",
            "symbol": "CINS",
            "name": "Cinovos",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/1ZGfW5p/cinovos-token-11-2021.png",
            "tags": [
                "CINS",
                "Cinovos"
            ],
            "extensions": {
                "website": "https://cinovos.com"
            }
        },
        {
            "chainId": 101,
            "address": "RFn7mUjf24UFMBdDVmoggAii4gyHdRDDqmKzGVbkd8c",
            "symbol": "FOUR",
            "name": "The 4th Pillar Token (Portal)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RFn7mUjf24UFMBdDVmoggAii4gyHdRDDqmKzGVbkd8c/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4730fB1463A6F1F44AEB45F6c5c422427f37F4D0",
                "assetContract": "https://etherscan.io/address/0x4730fB1463A6F1F44AEB45F6c5c422427f37F4D0",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "the-4th-pillar"
            }
        },
        {
            "chainId": 103,
            "address": "97MxeDbRgc6vYP1Sty2XdPXks3QhMD97EVYJ9pP4XcR3",
            "symbol": "HGEN",
            "name": "HGEN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97MxeDbRgc6vYP1Sty2XdPXks3QhMD97EVYJ9pP4XcR3/logo.png"
        },
        {
            "chainId": 103,
            "address": "2U3Mf4umT4CpLhhdwpfmGiktyvhdrLrNNv4z4GgsXNMe",
            "symbol": "GENS",
            "name": "GENS",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2U3Mf4umT4CpLhhdwpfmGiktyvhdrLrNNv4z4GgsXNMe/logo.png"
        },
        {
            "chainId": 101,
            "address": "FKkAyqqbcrSQiizKmNEFz64VWVct4H5VgFfJ7JA9wYY2",
            "symbol": "HVT",
            "name": "HARVEST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FKkAyqqbcrSQiizKmNEFz64VWVct4H5VgFfJ7JA9wYY2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://farmlands.biz/"
            }
        },
        {
            "chainId": 101,
            "address": "A98UDy7z8MfmWnTQt6cKjje7UfqV3pTLf4yEbuwL2HrH",
            "symbol": "MODUL",
            "name": "Modul",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/parametrise/ParametriseLogo/main/Modul_Logomark_White_Pinky_Background.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/SyqUrzSGEC",
                "twitter": "https://twitter.com/Moduluc",
                "website": "https://moduluc.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6VyYxDbvZ1C27yjbDfPzwUwGsRyYYcDjhV5noG4WJv49",
            "symbol": "MUTT",
            "name": "MUTT",
            "decimals": 5,
            "logoURI": "https://arweave.net/YKl53d9y6oMzXfdCHugq49avMgRF3Y8etWRliDXZUWw",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanamutts",
                "website": "http://www.solanamutts.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6StzwSrFeQEkF2xwADqdoz63RXR8dftf9BZnk91o52rm",
            "symbol": "PEACH",
            "name": "PEACHO TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6StzwSrFeQEkF2xwADqdoz63RXR8dftf9BZnk91o52rm/peach.png",
            "tags": [
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AmVpjztzMEzup8opT4aDJQsn5wSyFqBGxBQu1xC2nRPA",
            "symbol": "WEEN",
            "name": "Weenee",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmcatVKBeBBPYUXWq1x7BUpmGbi7U8m1fhMGRxiKJQpfL1",
            "tags": [
                "meme-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lilweenees",
                "website": "https://weenee.me"
            }
        },
        {
            "chainId": 101,
            "address": "7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi",
            "symbol": "MANA",
            "name": "Decentraland (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
                "assetContract": "https://etherscan.io/address/0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "decentraland",
                "serumV3Usdc": "7GSn6KQRasgPQCHwCbuDjDCsyZ3cxVHKWFmBXzJUUW8P"
            }
        },
        {
            "chainId": 101,
            "address": "49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt",
            "symbol": "SAND",
            "name": "Sandbox (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x3845badade8e6dff049820680d1f14bd3903a5d0",
                "assetContract": "https://etherscan.io/address/0x3845badade8e6dff049820680d1f14bd3903a5d0",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "the-sandbox",
                "serumV3Usdc": "3FE2g3cadTJjN3C7gNRavwnv7Yh9Midq7h9KgTVUE7tR"
            }
        },
        {
            "chainId": 101,
            "address": "3hT6jycBqLLNA7vyYczq8Byo1oFpT6UEH6UK7aFu9UBf",
            "symbol": "LCD-SOL",
            "name": "LCD-SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3hT6jycBqLLNA7vyYczq8Byo1oFpT6UEH6UK7aFu9UBf/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "J1UmPj193heku4zCiSCPZ15VUma9iH1pyJP52j571dQU",
            "symbol": "JUMP",
            "name": "Jump",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J1UmPj193heku4zCiSCPZ15VUma9iH1pyJP52j571dQU/logo.png"
        },
        {
            "chainId": 101,
            "address": "3aAYh35n81F8HPG2QBdE48aYdzGFj2fsLccg91X4AcRc",
            "symbol": "CLASH",
            "name": "Clash Of Cars",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aAYh35n81F8HPG2QBdE48aYdzGFj2fsLccg91X4AcRc/logo.png",
            "tags": [
                "solana-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "clash-of-cars",
                "discord": "https://discord.gg/KP8pUYGc",
                "twitter": "https://twitter.com/ClashOfCars",
                "website": "https://clashofcars.io"
            }
        },
        {
            "chainId": 101,
            "address": "BuWRApgShkSthUiEzBFUo6iVVUqAd8oMucdCwLjh9Efq",
            "symbol": "MAYA",
            "name": "Maya",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BuWRApgShkSthUiEzBFUo6iVVUqAd8oMucdCwLjh9Efq/logo.png",
            "tags": [
                "solana-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://genezys.io"
            }
        },
        {
            "chainId": 101,
            "address": "5y8yKzbiKhZnNj1P68rZKvnB1jjWrmzffJ8zV7y1ms9s",
            "symbol": "JPORTA",
            "name": "JPORTA Coin",
            "decimals": 8,
            "logoURI": "https://user-images.githubusercontent.com/93989494/141846479-5fb57a0f-d290-433a-afce-2889d18749fb.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "49jpm8SpyTwaGaJfUa4AmU28hmW1HoKuqzXkgykysowU",
            "symbol": "PSY",
            "name": "PSY Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/charliebakerr/psy-token/main/psycoinlogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mvWUh8fGYp",
                "twitter": "https://twitter.com/shroomz_nft",
                "website": "https://shroomznft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "B8PczNDh5ydRMWdZmezBeGfgRqcG2Yw8e7BYEQcaSDif",
            "symbol": "LNRZ",
            "name": "Lunariz",
            "decimals": 8,
            "logoURI": "https://media.lunariz.com/LNRZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LunarizLNRZ",
                "website": "https://lunariz.com"
            }
        },
        {
            "chainId": 101,
            "address": "DktNJUJAWJyeLw3ykCkFNpGohE24SoEhevKBskRi6P1y",
            "symbol": "SOLBEAR",
            "name": "Solar Bear",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DktNJUJAWJyeLw3ykCkFNpGohE24SoEhevKBskRi6P1y/logo.png",
            "tags": [
                "utility-token",
                "meme-token",
                "dex"
            ],
            "extensions": {
                "discord": "https://discord.io/solarbear",
                "telegram": "https://t.me/solar_bear_official",
                "twitter": "https://twitter.com/solarbear_sol",
                "website": "https://solar-bear.com"
            }
        },
        {
            "chainId": 101,
            "address": "Foea9rMuUk58xn414yB4PfWcP2VweR2mr3SU3ZUmSEa7",
            "symbol": "TURNT",
            "name": "TURNT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Foea9rMuUk58xn414yB4PfWcP2VweR2mr3SU3ZUmSEa7/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/TurntUpTikis",
                "website": "https://www.turntuptikis.com/"
            }
        },
        {
            "chainId": 103,
            "address": "C6CTTSQALChg3k3VY5iBAhvFuqqvqbaehVdZXaqcyiz",
            "symbol": "DXDGM",
            "name": "DEXGM",
            "decimals": 0,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmQiNfkoXXHtnuWL1rmuRTfQ92RVypGsvjKs4WGsaT61yt",
            "tags": [
                "DXDGM"
            ]
        },
        {
            "chainId": 101,
            "address": "61BUcCw7xtnjPHCVgBcMe6Q5eF2cgwiSnpSvT8MfMbQw",
            "symbol": "KBP",
            "name": "KBPsystem",
            "decimals": 9,
            "logoURI": "https://koleenbp.com/img/portfolio/kbpsystem-blue.png",
            "tags": [
                "kbpsystem",
                "utility-token",
                "rewards"
            ]
        },
        {
            "chainId": 101,
            "address": "7q3AdgKuMeDRnjaMQs7ppXjaw4HUxjsdyMrrfiSZraiN",
            "symbol": "SOLV",
            "name": "SOLVIEW",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Solviewapp/solviewlogo/main/icon.png",
            "extensions": {
                "telegram": "https://t.me/solviewofficial",
                "twitter": "https://twitter.com/Solviewapp",
                "website": "https://solview.app/"
            }
        },
        {
            "chainId": 101,
            "address": "7aohFKctcbgaSzNTtfL1999hUy4tgDeUC7wLU1ozNmxc",
            "symbol": "CRAT",
            "name": "CRAT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7aohFKctcbgaSzNTtfL1999hUy4tgDeUC7wLU1ozNmxc/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/corporatslab",
                "serumV3Usdc": "8VD5vFACkFyR5G1Nz7xievERN4bjQE7YdLhiDKBMTFxV",
                "twitter": "https://twitter.com/RatataNFTs"
            }
        },
        {
            "chainId": 101,
            "address": "GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ",
            "symbol": "SVIZ",
            "name": "Space Vizsla",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ/VizslaMoonLogo100x100.png",
            "tags": [
                "meme-token",
                "community-token",
                "space-vizsla",
                "vizsla"
            ],
            "extensions": {
                "telegram": "https://t.me/VizslaToken",
                "website": "https://vizslatoken.org"
            }
        },
        {
            "chainId": 101,
            "address": "NA45Qgq1xn2EcrrKik7o9rVPMSgmDXK6kv8134Q8ADW",
            "symbol": "EWOOF",
            "name": "ElonWoof",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NA45Qgq1xn2EcrrKik7o9rVPMSgmDXK6kv8134Q8ADW/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/PxnfsBp8zX",
                "twitter": "https://twitter.com/elon_woof",
                "website": "https://www.elonwoof.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2HvCRmrArygEUj2ZoTChjoDTkcQ4PWCGHmxyYt3FieuN",
            "symbol": "ELEC",
            "name": "ELE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2HvCRmrArygEUj2ZoTChjoDTkcQ4PWCGHmxyYt3FieuN/logo.png"
        },
        {
            "chainId": 101,
            "address": "9ePJirBV8kDpTxMrr5J2hUFFCEY9Z2AVGawCLmfPfiU5",
            "symbol": "SVET",
            "name": "SVETLANA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ePJirBV8kDpTxMrr5J2hUFFCEY9Z2AVGawCLmfPfiU5/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2oR1D21bNczbpgH6jY4DEtMbGD49boZ7DPVVUXnTV1iL",
            "symbol": "SKUMA",
            "name": "SOLKUMAINU",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2oR1D21bNczbpgH6jY4DEtMbGD49boZ7DPVVUXnTV1iL/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PJ9BGz3Tmx",
                "twitter": "https://twitter.com/solkumainu",
                "website": "https://solkumainu.com"
            }
        },
        {
            "chainId": 101,
            "address": "ALQ6fjG5vSp9ou9PZHZES2cgu5E6QghL37gLNM2seZNh",
            "symbol": "DTPT",
            "name": "Degen Trash Panda Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALQ6fjG5vSp9ou9PZHZES2cgu5E6QghL37gLNM2seZNh/logo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DegenTrashPanda",
                "website": "https://degentrashpandas.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4oZyezadeP4KdskT3oDXWFR6Nsado4rGanaH6p6wNR3P",
            "symbol": "MIX",
            "name": "MIX",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oZyezadeP4KdskT3oDXWFR6Nsado4rGanaH6p6wNR3P/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://mixture.money/"
            }
        },
        {
            "chainId": 101,
            "address": "GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz",
            "symbol": "GENE",
            "name": "Genopets",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz/logo.png",
            "tags": [
                "genopets",
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "genopets",
                "discord": "https://discord.gg/genopets",
                "serumV3Usdc": "FwZ2GLyNNrFqXrmR8Sdkm9DQ61YnQmxS6oobeH3rrLUM",
                "twitter": "https://twitter.com/genopets",
                "website": "https://genopets.me"
            }
        },
        {
            "chainId": 101,
            "address": "75MxtZ7E6N4mapt2x5cYNHKpri6L6cb63e3YFfZBAAtd",
            "symbol": "SMN",
            "name": "Solmon",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75MxtZ7E6N4mapt2x5cYNHKpri6L6cb63e3YFfZBAAtd/logo.png",
            "tags": [
                "utility-token",
                "DeFi"
            ]
        },
        {
            "chainId": 101,
            "address": "AYXKguzBnvSLaGXYvfaWSiUT5gtoBX6wE1KkRb7s8sJV",
            "symbol": "MADZ",
            "name": "MADZ social token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/bwoodr01/cdn@main/src/wine.png",
            "tags": [
                "wine-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gs3LLGvDSb85i6Qnu6zKnW7FArxHiPXEjj2JJjbRqtHf",
            "symbol": "TIDEE",
            "name": "TITTY TOKEN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gs3LLGvDSb85i6Qnu6zKnW7FArxHiPXEjj2JJjbRqtHf/logo.png"
        },
        {
            "chainId": 101,
            "address": "AMzb4Tc7gDGHrsz1zUQzjtmQS2AXWuejveAKXKSpsoPU",
            "symbol": "GAMESHIB",
            "name": "GAME SHIB COIN",
            "decimals": 9,
            "logoURI": "https://gameshib.com/images/gameshib420.png",
            "tags": [
                "game",
                "gamefi",
                "social-token",
                "meme",
                "shib"
            ],
            "extensions": {
                "serumV3Usdc": "63PTn92SL43vSdNSbKRvPxhvNr2vzsoXT2aakz1JNpCb",
                "twitter": "https://twitter.com/Gameshibcoin",
                "website": "https://gameshib.com"
            }
        },
        {
            "chainId": 101,
            "address": "2ah3bN48SKfRJbV93zEwfWz3FoUh44RUhRxDWFGzeaGL",
            "symbol": "SAFE",
            "name": "Safecoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Fair-Exchange/safecoinwiki/master/Logos/SafeCoin/256.png",
            "tags": [
                "wrapped-wormhole2"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xdaf566020156297e2837fdfaa6fbba929a29461e",
                "coingeckoId": "safe-coin-2",
                "discord": "https://discord.gg/c6hWAkQ",
                "facebook": "https://www.facebook.com/safecoins/",
                "instagram": "https://www.instagram.com/safecoinproject/",
                "medium": "https://safecoin.medium.com/",
                "serumV3Usdc": "3zyMD67tUqSfuF8XTZjzBjqT6daEUfx2nk6YSVtmzDnD",
                "serumV3Usdt": "2sedHoXJosRteBjP4FEV4U9TJ6JhHaNDgmkzm2dNtSHe",
                "telegram": "https://t.me/SafeCoinEN",
                "twitter": "https://twitter.com/safecoins",
                "website": "https://safecoin.org"
            }
        },
        {
            "chainId": 101,
            "address": "97d6kj4itu6cqCNbxwmxjHxUD4QD1wicEi2jFw2Fm5vi",
            "symbol": "DEEZ",
            "name": "Deez Nuts",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97d6kj4itu6cqCNbxwmxjHxUD4QD1wicEi2jFw2Fm5vi/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "serumV3Usdc": "DcKJGNX3rAiEgEFKQiaYx9qydhy2z5vHdHTFjLa4KvGq",
                "twitter": "https://twitter.com/DeezSolana",
                "website": "https://deezsolana.com"
            }
        },
        {
            "chainId": 101,
            "address": "8qXGZR71AViyKMZxNesqumG5m6549t2aoQFpxoZa1x87",
            "symbol": "420TOKE",
            "name": "TOKES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jdrnthl/TOKES/main/logo.svg",
            "tags": [
                "weed",
                "420",
                "dank",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Wgco6reiMwazERpAm3JS1xD7JBHNJJQdNEE9MrUkwtJ",
            "symbol": "SP",
            "name": "Space Puppy",
            "decimals": 9,
            "logoURI": "https://spacepuppy.fun/logo_space_puppy.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token",
                "multi-chain"
            ],
            "extensions": {
                "telegram": "https://t.me/space_puppy",
                "twitter": "https://twitter.com/_spacepuppy",
                "website": "https://spacepuppy.fun"
            }
        },
        {
            "chainId": 101,
            "address": "AcgJKkqKgoeDQY82pSTFzaJE4dy82kXnoAkg4aNhN2a9",
            "symbol": "GMC",
            "name": "GM Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AcgJKkqKgoeDQY82pSTFzaJE4dy82kXnoAkg4aNhN2a9/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/JubHmwbg36",
                "twitter": "https://twitter.com/GMCoiners",
                "website": "https://twitter.com/GMCoiners"
            }
        },
        {
            "chainId": 101,
            "address": "APm3NKwCVAZ2nCtSgqZ2yG9c3iW3Gft4YKcZV5yUNfT7",
            "symbol": "BCGW",
            "name": "Blockchain Games Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Hideinseek/techdegree-project-1/main/images/2928898_th2.jpeg",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BlockchainGW",
                "website": "http://blockchaingames.world"
            }
        },
        {
            "chainId": 101,
            "address": "E4LzQYYFg4agn62od66JCq2NGq3o9h6TBnnurTgZhSJs",
            "symbol": "TRAM",
            "name": "Tramina",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/Eleluokuol/Tramina-/Tramina-Logo.png",
            "tags": [
                "Tramina",
                "TRAM"
            ]
        },
        {
            "chainId": 101,
            "address": "sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE",
            "symbol": "SONAR",
            "name": "Sonar Watch",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "sonarwatch",
                "discord": "https://discord.gg/sonarwatch",
                "twitter": "https://twitter.com/Sonarwatch",
                "website": "https://sonar.watch/"
            }
        },
        {
            "chainId": 101,
            "address": "yFuQR2juKrFm3vXDhv1eSDTuvppQqPeZjowinWMJFgt",
            "symbol": "ITAL",
            "name": "TALENTMICRO",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yFuQR2juKrFm3vXDhv1eSDTuvppQqPeZjowinWMJFgt/logo.png",
            "tags": [
                "utility-token",
                "ITAL",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jRz63Dsa3p",
                "facebook": "https://www.facebook.com/ital.talentmicro",
                "instagram": "https://www.instagram.com/TalentMicro_ITAL",
                "linkedin": "https://www.linkedin.com/company/ital-crypto",
                "medium": "https://medium.com/@TalentMicro_ITAL",
                "reddit": "https://www.reddit.com/user/ITAL_TalentMicro",
                "telegram": "https://t.me/TalentMicro_ITAL_bot",
                "twitter": "https://twitter.com/talentmicroital",
                "website": "https://www.ital.social"
            }
        },
        {
            "chainId": 101,
            "address": "8PH4JNMgdESsXdTbTEhseNgu1PwWWEQw5vAZHSZxaP23",
            "symbol": "XLE",
            "name": "Excelli Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PH4JNMgdESsXdTbTEhseNgu1PwWWEQw5vAZHSZxaP23/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://excellicoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "2KJwBUfD6oFSMJuKs8ppjULFZtquScYrDpxD38zvHHKP",
            "symbol": "PSDN",
            "name": "Poseidon Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2KJwBUfD6oFSMJuKs8ppjULFZtquScYrDpxD38zvHHKP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/token_poseidon"
            }
        },
        {
            "chainId": 101,
            "address": "Hkhpu6BU89R6cYZSBgmEfnfshn3NUvnHG6vRBREJFEv7",
            "symbol": "OGJ",
            "name": "Origin Jambo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hkhpu6BU89R6cYZSBgmEfnfshn3NUvnHG6vRBREJFEv7/logo.png",
            "extensions": {
                "discord": "https://www.discord.gg/jambomambo",
                "twitter": "https://twitter.com/JamboMamboLand",
                "website": "https://www.jambomambo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3Y1Nc9Cd4jDGeSQEZ8hpfS66LixZ4W3gKLdwf3ph7Bb4",
            "symbol": "JEW",
            "name": "JEW Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Y1Nc9Cd4jDGeSQEZ8hpfS66LixZ4W3gKLdwf3ph7Bb4/logo.png"
        },
        {
            "chainId": 101,
            "address": "Cs3ywW9tRrsbkGLqiYPaZ4wPXecEB1vNfnnAR6pbmfBm",
            "symbol": "DOGQ",
            "name": "Dog Queen",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Namblckchain/token-list/main/assets/mainnet/Cs3ywW9tRrsbkGLqiYPaZ4wPXecEB1vNfnnAR6pbmfBm/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5yUX1XpjLSTDyNBTQ3N3oYpu6RH4gckqnSS6Ecg79fAL",
            "symbol": "TDX",
            "name": "Tiddie Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/tiddietoken/Icon2/Screenshot_20211109-102934_WhatsApp.jpg",
            "tags": [
                "meme-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ag7ny2w9BE33hp41iSG2yCL8i3PyZbWV7yZZXdQekvsB",
            "symbol": "illkillyou",
            "name": "illkillyou",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ag7ny2w9BE33hp41iSG2yCL8i3PyZbWV7yZZXdQekvsB/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://twitter.com/rajgokal/status/1457750326212235273"
            }
        },
        {
            "chainId": 101,
            "address": "375hSNDx3yvHbZCPP7g3Cw85c5nWYLntvUZw7kkkJMRf",
            "symbol": "MNM",
            "name": "The Metaverse Museum",
            "decimals": 9,
            "logoURI": "https://themetaversemuseum.s3.eu-west-3.amazonaws.com/baselogo.png",
            "tags": [
                "meta-verse",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://themetaversemuseum.io"
            }
        },
        {
            "chainId": 101,
            "address": "2yhLAqkhVEoJ2BCQPWmGVSR2DK4UG2af532UDdcsyPzT",
            "symbol": "OMDT",
            "name": "Omnidex",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2yhLAqkhVEoJ2BCQPWmGVSR2DK4UG2af532UDdcsyPzT/logo.png",
            "tags": [
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "4oS3LJTPVr8UW9g2gJMEADotTF3omitQMxvXqgRTm8Mw",
            "symbol": "PAPA",
            "name": "SOLPAPA",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oS3LJTPVr8UW9g2gJMEADotTF3omitQMxvXqgRTm8Mw/logo.png",
            "tags": [
                "SOLPAPA",
                "NFTs",
                "TOKEN",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3oFeqagQB8Yh5UeJCnTiPW4sEo5K1Cdgp5NPuXoJYj3T",
            "symbol": "CORN",
            "name": "Solacorn Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3oFeqagQB8Yh5UeJCnTiPW4sEo5K1Cdgp5NPuXoJYj3T/logo.png",
            "tags": [
                "NFTs",
                "P2E"
            ],
            "extensions": {
                "website": "https://www.solacorn.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EMr7DzCHgA7Ako9uE2s82gXhyEw76GF4FkxnfWn9XEFP",
            "symbol": "SOW",
            "name": "SOLOW Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EMr7DzCHgA7Ako9uE2s82gXhyEw76GF4FkxnfWn9XEFP/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GXm9UzbAERvZsfsM8CB6sWrn74BJ6ZAfDoNdeNRCmy2E",
            "symbol": "MINGO",
            "name": "Mingo Token",
            "decimals": 9,
            "logoURI": "https://s3.amazonaws.com/flashyflamingos.com/token.png",
            "tags": [
                "meme-token",
                "nft"
            ],
            "extensions": {
                "website": "https://flashyflamingos.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq",
            "symbol": "FOXY",
            "name": "Famous Fox Federation",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/famousfoxes",
                "twitter": "https://twitter.com/famousfoxfed",
                "website": "https://famousfoxes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GrEDCQ4oVMyc5otXMd9BB5u3dhBiXiXdtM3vAxTjLZX3",
            "symbol": "wat",
            "name": "Fraktionalized EXCHANGE NOTIFICATION NFT",
            "decimals": 3,
            "logoURI": "https://cdn.exchange.art/comm_token/background_images/1.jpg",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/HbmF6oVqXNLzNUVgMy2bEDJTkMc5f6b4URFF2dC4bNS7",
                "vaultPubkey": "HbmF6oVqXNLzNUVgMy2bEDJTkMc5f6b4URFF2dC4bNS7"
            }
        },
        {
            "chainId": 101,
            "address": "ELe6SZqMy7a73AfWrBtto2XqRs49aBMCKaLPSH5759Dv",
            "symbol": "MRST",
            "name": "Fraktionalized Megalodon Rogue Shark Tooth",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/_oDYT91APrEJkQoajLAkSFs_bsrv3erlEPzX5Wiwlro?ext=gif",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/D6ErHk23Df2E1c1LqSqvzYhLzPBzKyh3xKrRDva2gxTj",
                "vaultPubkey": "D6ErHk23Df2E1c1LqSqvzYhLzPBzKyh3xKrRDva2gxTj"
            }
        },
        {
            "chainId": 101,
            "address": "YDMU6AZMmHB6dzcM3u34cgiHCMuM4z8bmMjTUv3drUA",
            "symbol": "FSY",
            "name": "Fraktionalized Synapses-914",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/WrsR1b43gWefNIQetPTjuwhwIiBeSsIW0YiiBuO4jHE?ext=gif",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/6ibb1MyYeTujF66GgbiQExSXSHMk3fV75iPF2vrr274U",
                "vaultPubkey": "6ibb1MyYeTujF66GgbiQExSXSHMk3fV75iPF2vrr274U"
            }
        },
        {
            "chainId": 101,
            "address": "2t25po1qdFfZcBzREPYTwfuojWUdT6dUYREov6k3CaXU",
            "symbol": "TWR",
            "name": "Fraktionalized Tower Community NFT",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/-WwoWhp965cBVUYwFWjGXvygQOsWjTEDYi-3VI0cWlo?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/3ovaiNKdfyDhq7yTjQ94LS1qvmmKJv4DQPXmWooFKUjR",
                "vaultPubkey": "3ovaiNKdfyDhq7yTjQ94LS1qvmmKJv4DQPXmWooFKUjR"
            }
        },
        {
            "chainId": 101,
            "address": "AcMqhvAD1qG2K1pesnXLix1zPQDMW54rBdaqUznmWhcS",
            "symbol": "PLS",
            "name": "Fraktionalized Synapses-1058",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/NGEfBIu3mAC7rE0y1qX2BsvvLxg4ZaPKkgN-BBiS0Zg?ext=gif",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/EzXyaBouLkRWqednXACYeNm6pDhLnKw3BaBEB2hJbLPB",
                "vaultPubkey": "EzXyaBouLkRWqednXACYeNm6pDhLnKw3BaBEB2hJbLPB"
            }
        },
        {
            "chainId": 101,
            "address": "U3iWnWUqdbn1SXAn6CrmuQoFCYBC3vRb2vmM7CMPEnj",
            "symbol": "Nom",
            "name": "Fraktionalized Frakt-1274",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/Ztw0zOyMrnvKNfcEBWxlpijGM2K0NYPoBXbhxg4bWQE",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/5AuSjp5d94882J3ctoeNjq8qRyzrvvznmUXKAAjJc9cT",
                "vaultPubkey": "5AuSjp5d94882J3ctoeNjq8qRyzrvvznmUXKAAjJc9cT"
            }
        },
        {
            "chainId": 101,
            "address": "GPb3fbFaXEy9b8WZexy3CogjrD322iDgLCx2Fe3tq6K5",
            "symbol": "RARE",
            "name": "Fraktionalized Moment-45",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/jsWoTIne-qV9ZjcCxwbrGXiuV3E_W3T9Ai7fw_44Pik?ext=jpeg",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/9C5aAzTJZeT9kBHENKd5CtchUk4Mv29ys8KaBTUfvrcF",
                "vaultPubkey": "9C5aAzTJZeT9kBHENKd5CtchUk4Mv29ys8KaBTUfvrcF"
            }
        },
        {
            "chainId": 101,
            "address": "5Cf7LFQ36L7caoowobjRvZUuXqE234vPbT8bLGphc25c",
            "symbol": "SLN",
            "name": "Fraktionalized Solanne: Up Only 1/1",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/HrAPpiyDALvoS9oj8b1_keTmuMKRlnjRuieyjN0RVLY?ext=jpg",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/2uwtAQNGkjhEaBjN5FavvrT3tbp6JZPuCwXr7qBBekb5",
                "vaultPubkey": "2uwtAQNGkjhEaBjN5FavvrT3tbp6JZPuCwXr7qBBekb5"
            }
        },
        {
            "chainId": 101,
            "address": "FSdUB3eBDy28H9z3izzV8KbB2SLUa1mRpe1R482CEsfR",
            "symbol": "tst",
            "name": "Fraktionalized Skyline FLP",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/7GLSB6nsDtbhFoOKZLbtgDoO0DTxPyxt3VJB1mHkmwM?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/C3VwkKSuL8KqxCmz4JV1Lx798isHjxcoNTwkVRGyV8Sm",
                "vaultPubkey": "C3VwkKSuL8KqxCmz4JV1Lx798isHjxcoNTwkVRGyV8Sm"
            }
        },
        {
            "chainId": 101,
            "address": "8D94XbVAjXRzRb8p1iz8dwPtuDKufeLX59WXEuGYLtPX",
            "symbol": "FLDG",
            "name": "FlokiDoge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8D94XbVAjXRzRb8p1iz8dwPtuDKufeLX59WXEuGYLtPX/flokidogelogo.png",
            "tags": [
                "utility-token",
                "meme-token",
                "doge",
                "Shib",
                "elon",
                "floki"
            ]
        },
        {
            "chainId": 101,
            "address": "BhNHLeVEJocDsQUMsjGSkzVeuGgrTFsucCXbNC2u22pM",
            "symbol": "POLLO",
            "name": "POLLO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhNHLeVEJocDsQUMsjGSkzVeuGgrTFsucCXbNC2u22pM/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/pollo_thumbs_up"
            }
        },
        {
            "chainId": 101,
            "address": "GmrHVLSvbt2HRwCY54SddihKvzseL7SAPndxrWYPnbUt",
            "symbol": "FEX",
            "name": "Fex",
            "decimals": 2,
            "logoURI": "https://www.linkpicture.com/q/fex.png"
        },
        {
            "chainId": 101,
            "address": "DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5",
            "symbol": "BITXBIT",
            "name": "BITXBIT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5/logo.png",
            "tags": [
                "social-token",
                "technology",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bitXbit_aus",
                "website": "https://bitxbit.com.au"
            }
        },
        {
            "chainId": 101,
            "address": "HqxyA7nVgtcoXhTrEcaWxzH2xuNeNDsie8nA8Unp1pZ2",
            "symbol": "ZULIA",
            "name": "ZULIA",
            "decimals": 9,
            "logoURI": "https://zuliacoin.com/wp-content/uploads/2021/09/zuliacoin.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "telegram": "https://t.me/zuliacoin",
                "twitter": "https://twitter.com/zuliacoin",
                "website": "https://zuliacoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "89r9FGEtk5a6PPmhQzrByjRd6kF8ZLGibjYNK59Rpc4V",
            "symbol": "JIAN",
            "name": "JIAN COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/89r9FGEtk5a6PPmhQzrByjRd6kF8ZLGibjYNK59Rpc4V/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "13mmS8pzL7EESnKdMu7ioCsVp7XBWGWKBMtW6BqR2e2K",
            "symbol": "RED",
            "name": "Token Red",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/13mmS8pzL7EESnKdMu7ioCsVp7XBWGWKBMtW6BqR2e2K/logo.png",
            "extensions": {
                "website": "https://redfishcommunications.net/"
            }
        },
        {
            "chainId": 101,
            "address": "5P8fWyGpwNhScv3hJCiSSBKWpo7sYR954AddefEvqMWM",
            "symbol": "MNL",
            "name": "MANUEL",
            "decimals": 9,
            "logoURI": "https://cdn.pixabay.com/photo/2017/01/26/13/00/mom-2010524_960_720.png"
        },
        {
            "chainId": 101,
            "address": "ArtbSHxfQUg7EUbP2iLjrBiMZvheS5GsmMcDRQKbDDzE",
            "symbol": "KRMA",
            "name": "KARMA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArtbSHxfQUg7EUbP2iLjrBiMZvheS5GsmMcDRQKbDDzE/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6KUXFshH",
                "twitter": "https://twitter.com/harikrismenon",
                "website": "https://getkarma.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "6sTdGdDSaHJ4SbieiD7oA3b1Sjbu9LZWE3h2mpFxjikw",
            "symbol": "DGAF",
            "name": "DGAF Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6sTdGdDSaHJ4SbieiD7oA3b1Sjbu9LZWE3h2mpFxjikw/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.earjuice.com/"
            }
        },
        {
            "chainId": 101,
            "address": "A6XKxTA1PiYjk3KTh9txMGCCc5CaNj56fRKnmpr6pYDN",
            "symbol": "LECT",
            "name": "Lecturer Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A6XKxTA1PiYjk3KTh9txMGCCc5CaNj56fRKnmpr6pYDN/logo.png",
            "extensions": {
                "website": "https://sol.lecturertoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "FQq2FsHvWgS84usEGa6rmr6WSeDNZjVd3KAD2W9rKFeq",
            "symbol": "CATC",
            "name": "CatsCoffee",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/gbc8181/Cats_Coffee/logo/pre_logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/CatsCoffeeCATC"
            }
        },
        {
            "chainId": 103,
            "address": "86x8r57bTMmaU7i5SqsY9gwiuxqddt8vPA7WXuR6Bhx1",
            "symbol": "HGC",
            "name": "Hatch Group Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/86x8r57bTMmaU7i5SqsY9gwiuxqddt8vPA7WXuR6Bhx1/logo.png",
            "extensions": {
                "website": "https://www.hatchgroup.co.uk/"
            }
        },
        {
            "chainId": 101,
            "address": "2ABVP6Ndy5KEqUhbFRpJuCqBQ6MdVkLgJTfh6ZbVqenj",
            "symbol": "AZUR",
            "name": "AZUR",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ABVP6Ndy5KEqUhbFRpJuCqBQ6MdVkLgJTfh6ZbVqenj/logo.png",
            "extensions": {
                "website": "https://azur-chain.com/"
            }
        },
        {
            "chainId": 101,
            "address": "57AS2y8g4MPN4R9uDRZQjr8c2ksa2PGrVJqJL5361BN5",
            "symbol": "NARCH",
            "name": "NARCHCoin",
            "decimals": 0,
            "logoURI": "https://cdn.dribbble.com/users/612987/screenshots/4309689/letter-n-logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BmvZaohxsWzsxeX3HX4145rtP6BcGbftUDKUz78d2xR2",
            "symbol": "SPIBETA",
            "name": "Spider Beta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BmvZaohxsWzsxeX3HX4145rtP6BcGbftUDKUz78d2xR2/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://www.spider.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AGrCEkCgXeTuD8GzHJmw2PEx4DKqmrnNiV6BYCsZK2Vg",
            "symbol": "GXG",
            "name": "Geometry Governance token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGrCEkCgXeTuD8GzHJmw2PEx4DKqmrnNiV6BYCsZK2Vg/logo.png",
            "tags": [
                "geometry",
                "geometryfi",
                "geobots",
                "governance"
            ],
            "extensions": {
                "discord": "https://www.discord.gg/geobots",
                "medium": "https://medium.com/geometryfi",
                "website": "https://geometry.fi"
            }
        },
        {
            "chainId": 101,
            "address": "ASTkbpe5ckW1mx6WDLE9zPScWsYjSh6h5zQ89UYoUQ7D",
            "symbol": "CHAM",
            "name": "SOLChamies",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASTkbpe5ckW1mx6WDLE9zPScWsYjSh6h5zQ89UYoUQ7D/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/GAN7MmCxAw",
                "twitter": "https://twitter.com/SOLChamies",
                "website": "https://solchamies.com/"
            }
        },
        {
            "chainId": 101,
            "address": "isnWzCRtawba3ySXBvKT6TFunfK8tdytKTFmS3xpySv",
            "symbol": "ASL-NFT",
            "name": "ASL Moto NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Acidastro/token-list/main/assets/mainnet/8ncGBhpwZcw3Hj9Cr8rmXw4R5YoLwwvzzDAaoBfiN8pn/logo.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "telegram": "https://t.me/Artur_buell",
                "website": "https://instalinks.ru/ArturBuell"
            }
        },
        {
            "chainId": 101,
            "address": "8XkS7ZDPR9zXcNcYR884tBScnQRyFcWRb7WcLtCR6zEZ",
            "symbol": "CNDR",
            "name": "CondorCoin",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8XkS7ZDPR9zXcNcYR884tBScnQRyFcWRb7WcLtCR6zEZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Condor-Coin-108233578306800",
                "instagram": "https://www.instagram.com/condorcoin/",
                "reddit": "https://www.reddit.com/user/condorcoin",
                "twitter": "https://twitter.com/condor_coin",
                "website": "https://www.condorcoin.co/"
            }
        },
        {
            "chainId": 101,
            "address": "4ZEC2PNED2i2H8fxjEzLrnKjUriWjWhaXCH76cTCC6Zd",
            "symbol": "ASL",
            "name": "ASL Moto Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Acidastro/token-list/main/assets/mainnet/8ncGBhpwZcw3Hj9Cr8rmXw4R5YoLwwvzzDAaoBfiN8pn/logo.png",
            "tags": [
                "utility-token",
                "Defi-Token"
            ],
            "extensions": {
                "telegram": "https://t.me/Artur_buell",
                "website": "https://instalinks.ru/ArturBuell"
            }
        },
        {
            "chainId": 101,
            "address": "CLhx8u9HXyMTew88dh2N9EyTqV2j6PwKSoTj6jivqaqH",
            "symbol": "GGMI",
            "name": "Gonna Make It Governance Token",
            "decimals": 9,
            "logoURI": "https://gonnamakeit.io/branding/ggmi-token.png",
            "tags": [
                "DeFi",
                "Gaming",
                "governance-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/rAnhpNqQmq",
                "twitter": "https://twitter.com/gonnamakeitapp",
                "website": "https://gonnamakeit.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8p1GqwyQMieGDJp2VcNY4j1bNEdWPnDUtVhaZrMhL6LF",
            "symbol": "GMI",
            "name": "Gonna Make It Utility Token",
            "decimals": 9,
            "logoURI": "https://gonnamakeit.io/branding/gmi-token.png",
            "tags": [
                "DeFi",
                "Gaming",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/rAnhpNqQmq",
                "twitter": "https://twitter.com/gonnamakeitapp",
                "website": "https://gonnamakeit.io/"
            }
        },
        {
            "chainId": 101,
            "address": "WLPyXq7WRfdWLiP4fvRfSisrfDzLiPmCeVTE6okKQWE",
            "symbol": "wpUSDC-USDC",
            "name": "Saber wpUSDC-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPyXq7WRfdWLiP4fvRfSisrfDzLiPmCeVTE6okKQWE/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wpusdc"
            }
        },
        {
            "chainId": 101,
            "address": "WLP59xUDvQMQdzC2SgPmZeRF1oj2RSvGZiQLksj4bwj",
            "symbol": "wpUSDT-USDT",
            "name": "Saber wpUSDT-USDT LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLP59xUDvQMQdzC2SgPmZeRF1oj2RSvGZiQLksj4bwj/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wpusdt"
            }
        },
        {
            "chainId": 101,
            "address": "GtHxqAqbaZB8eo8R8pGXUhWxs6X8WQWMWTUWKTgSFbHo",
            "symbol": "Potion",
            "name": "Potion",
            "decimals": 6,
            "logoURI": "https://i.imgur.com/zURNQAX.png",
            "tags": [
                "community-token",
                "DAO",
                "Staking"
            ],
            "extensions": {
                "discord": "https://discord.gg/fF9VEsxx",
                "twitter": "https://twitter.com/SolanaMMB",
                "website": "https://mutantmonkey.business/"
            }
        },
        {
            "chainId": 101,
            "address": "HrurWc98nGZ5nqZcGDUbr8mrmV6QseWGTPdTN5mwYPUH",
            "symbol": "UNK",
            "name": "Unknown Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HrurWc98nGZ5nqZcGDUbr8mrmV6QseWGTPdTN5mwYPUH/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://officialnameunknown.com/nfts"
            }
        },
        {
            "chainId": 101,
            "address": "4EKX35aZroQ9oGk8gf7deGHe77VXMxXcy7ahJRw7qwd1",
            "symbol": "GMIMO",
            "name": "Golden Access MIMO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4EKX35aZroQ9oGk8gf7deGHe77VXMxXcy7ahJRw7qwd1/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/rznuTnenPJ",
                "medium": "https://medium.com/@mimo_3408",
                "twitter": "https://twitter.com/MillionMonke",
                "website": "https://millionmonke.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GkSMSvjjcd8AXqbkzo4CiFXrsVB5JEhqLvd48HoVhJDA",
            "symbol": "SMIMO",
            "name": "Silver Access MIMO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkSMSvjjcd8AXqbkzo4CiFXrsVB5JEhqLvd48HoVhJDA/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/rznuTnenPJ",
                "medium": "https://medium.com/@mimo_3408",
                "twitter": "https://twitter.com/MillionMonke",
                "website": "https://millionmonke.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FidMgPpM9CBVLqYtpBUCBLfCsth5sbVKA5haetJdZ82R",
            "symbol": "FLOPPA",
            "name": "Floppa Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FidMgPpM9CBVLqYtpBUCBLfCsth5sbVKA5haetJdZ82R/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "telegram": "https://t.me/floppacoincommunity",
                "website": "https://www.floppamemecoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "gmdu3snwW28DmmxCseChp9owWLUhamH9eS3hWfHG8Vg",
            "symbol": "GMSOL",
            "name": "We Say gm",
            "decimals": 9,
            "logoURI": "https://github.com/icnivadmai/gm-logo/blob/master/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/3KYEhaHFN5",
                "twitter": "https://twitter.com/officialwesaygm",
                "website": "https://www.wesaygm.com/"
            }
        },
        {
            "chainId": 103,
            "address": "DCEXw37nxoFTRmbtbEW4gnBijrqHPSyBt2VirUQYoXfX",
            "symbol": "BERSX",
            "name": "Bers Coin dev",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DCEXw37nxoFTRmbtbEW4gnBijrqHPSyBt2VirUQYoXfX/logo.png",
            "tags": [
                "mongolian-token",
                "gamefi",
                "ads-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/2eACjdq9",
                "facebook": "https://www.facebook.com/berscoin",
                "website": "https://berscoin.com/",
                "youtube": "https://www.youtube.com/c/BersCoinBersTube"
            }
        },
        {
            "chainId": 101,
            "address": "F8qzs4rwAn2egKvQYrwQeY4R4MFQiRADv289gGF4LFti",
            "symbol": "PNCK",
            "name": "Poncik",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F8qzs4rwAn2egKvQYrwQeY4R4MFQiRADv289gGF4LFti/logo.png"
        },
        {
            "chainId": 101,
            "address": "BpZ93AYjkYgGtpMf15CVqFogtbeRmgyoevpwDcPq2vNv",
            "symbol": "Soltopia",
            "name": "RUG",
            "decimals": 9,
            "logoURI": "https://ipfs.io/ipfs/QmbpkaK4EZxe31HDMso4CK8nN54pstpHYewxDvRSZUSPjw",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q",
            "symbol": "KSOL",
            "name": "KITTYCOIN SOL",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/KittycoinSOL",
                "telegram": "https://t.me/KittycoinSOL",
                "twitter": "https://twitter.com/KittycoinSOL",
                "website": "https://kittycoinsol.com/",
                "youtube": "https://www.youtube.com/channel/UCRn6xC1Gmr6enUia-mg2rIA"
            }
        },
        {
            "chainId": 101,
            "address": "GWHaVysqHeeFgjM4eKDSAh5cbgG9VZNPNFSdJeNsfgKN",
            "symbol": "CNC",
            "name": "Chestnut Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWHaVysqHeeFgjM4eKDSAh5cbgG9VZNPNFSdJeNsfgKN/logo.png"
        },
        {
            "chainId": 103,
            "address": "4kcjCkyD87Rjosrpe9r5UhNUzC4dx7SjzTkMLW7Dgb3T",
            "symbol": "SHMAC",
            "name": "Shiba McEntire",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4kcjCkyD87Rjosrpe9r5UhNUzC4dx7SjzTkMLW7Dgb3T/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://shmactoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "J4MJ5VKsWoph5XtGF8aHEA5dMLQCwDFoAnRh1GtBdAtJ",
            "symbol": "AUVS",
            "name": "Atlantisvers",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J4MJ5VKsWoph5XtGF8aHEA5dMLQCwDFoAnRh1GtBdAtJ/logo.png",
            "tags": [
                "games-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/atlantisvers",
                "website": "https://atlantisvers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AG6RVirrcJYxQxLoHhxdLAtUAMunkvcFW2agks9CyUXt",
            "symbol": "GNXD",
            "name": "NeXt-DeFi Governance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AG6RVirrcJYxQxLoHhxdLAtUAMunkvcFW2agks9CyUXt/logo.png",
            "extensions": {
                "discord": "https://discord.gg/KH2YywfrgS",
                "medium": "https://medium.com/@NXDF",
                "reddit": "https://www.reddit.com/r/next_defi_protocol/",
                "telegram": "https://t.me/nxdfarmy",
                "twitter": "https://twitter.com/NXDF16",
                "website": "https://nxdf.io/",
                "youtube": "https://www.youtube.com/channel/UCMdPUPpCTVf-OHvMLCdzxOQ"
            }
        },
        {
            "chainId": 101,
            "address": "BsrrKFLVfEjyR6sjsbPBdu9H5aHjL4m4TeGqkKMecMfH",
            "symbol": "SOLAEGG",
            "name": "SolanaEgg token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BsrrKFLVfEjyR6sjsbPBdu9H5aHjL4m4TeGqkKMecMfH/logo.png"
        },
        {
            "chainId": 101,
            "address": "C4xWe67MMg5zJia7gZ8BmH2btvCfMeSMWRVWXCGvoAfG",
            "symbol": "PSCHARM",
            "name": "PreSale CHARM Token",
            "decimals": 9,
            "logoURI": "https://s3.amazonaws.com/charmtoken.net/images/Charm_logo_redmask.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "website": "https://charmtoken.net"
            }
        },
        {
            "chainId": 101,
            "address": "rvjXnhXCwaZD2b4mZA321khA959yAg1GT9YWTqr6jTN",
            "symbol": "THCT",
            "name": "Thai Baht Casino Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rvjXnhXCwaZD2b4mZA321khA959yAg1GT9YWTqr6jTN/logo_temp.png"
        },
        {
            "chainId": 101,
            "address": "CH9AbJkhRGGMg9MKsWhHGz36BP6Xpqe7ndrzPCtkwKME",
            "symbol": "USCT",
            "name": "US Dollars Casino Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CH9AbJkhRGGMg9MKsWhHGz36BP6Xpqe7ndrzPCtkwKME/USCT.png"
        },
        {
            "chainId": 101,
            "address": "4Su1xAHY9Lhc55pvMdeoUUWV5bojDog9TxBPgybqpoha",
            "symbol": "CNYCT",
            "name": "Chinese Yuan Casino Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Su1xAHY9Lhc55pvMdeoUUWV5bojDog9TxBPgybqpoha/CNYCT.png"
        },
        {
            "chainId": 101,
            "address": "EYgBLpkEpUTegYDu6vFZ1jf5i49NggUM9RB32Y1JPPav",
            "symbol": "UCT",
            "name": "Uni Casino Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EYgBLpkEpUTegYDu6vFZ1jf5i49NggUM9RB32Y1JPPav/UCT.png"
        },
        {
            "chainId": 101,
            "address": "3iBZV8gvUFxp333FFogUPVi6MP9dEZ74xUxVzEQvNPii",
            "symbol": "SHIBU",
            "name": "SHIBU Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/shibuio/token-list/main/assets/mainnet/3iBZV8gvUFxp333FFogUPVi6MP9dEZ74xUxVzEQvNPii/logo.png",
            "tags": [
                "meme-token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/shibu_io",
                "website": "https://shibu.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4qcHQruwW1NcSMxQ6v2eYKGxnGSDHdEZ9i7JvaL1ZADL",
            "symbol": "SLNK",
            "name": "Solanka Coin",
            "decimals": 9,
            "logoURI": "https://arweave.net/u0Nbp8wLcvtMsawdnddN4LH5rdxmQtKoul3TN7e2r0Q/solanka.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://woken-token.github.io/solanka-coin/"
            }
        },
        {
            "chainId": 101,
            "address": "9BiqBycZWkWH21vYqCbu2bL1PjZbR5GxWGA8LQkShbyt",
            "symbol": "MEW",
            "name": "Solcatcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9BiqBycZWkWH21vYqCbu2bL1PjZbR5GxWGA8LQkShbyt/logo.png",
            "tags": [
                "meme",
                "cat",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solcatcoin",
                "twitter": "https://twitter.com/SolCatCoin",
                "website": "https://www.solcatcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AoL4f5i5BaXcTAYKaqzy3mxVzDgAGncBaW9VNjfZZxwH",
            "symbol": "YOUNG2",
            "name": "YOUNG2 Token",
            "decimals": 9,
            "logoURI": "https://github.com/kskhbh2/token-list/blob/5a6d34fd2c0b7121e4d2fc6565986f627aeb0975/logo.png"
        },
        {
            "chainId": 101,
            "address": "GT6WPwDrM1L1aFMw9FzQZRW3hgMZp5v8qU1hiVpYeWWg",
            "symbol": "WBRZ-USDL",
            "name": "Lagrange WBRZ-USDL LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/cavidk/lp-token/main/lp-logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/lagrange-fi",
                "github": "https://github.com/Lagrange-fi",
                "twitter": "https://twitter.com/Lagrange_fi",
                "website": "https://lagrange.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "GLmaRDRmYd4u3YLfnj9eq1mrwxa1YfSweZYYZXZLTRdK",
            "symbol": "SOLAB",
            "name": "Solabrador",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GLmaRDRmYd4u3YLfnj9eq1mrwxa1YfSweZYYZXZLTRdK/solabradon250x250.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "coingeckoId": "solabrador",
                "discord": "https://discord.gg/8HJK4apjd7",
                "serumV3Usdc": "5m7CdEhV6Tq5KnSCy5Cg4w6ug5DFCcHrUJ8JMzKVDdav",
                "twitter": "https://twitter.com/Solabrador",
                "website": "https://solabrador.com"
            }
        },
        {
            "chainId": 101,
            "address": "9fzQfEM5aq1GLugzHMM6prq8tsURN2pxQMjARaWGd2py",
            "symbol": "ITSC",
            "name": "ITS Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9fzQfEM5aq1GLugzHMM6prq8tsURN2pxQMjARaWGd2py/logo.svg",
            "tags": [
                "utility-token",
                "Peruvian-token"
            ],
            "extensions": {
                "website": "https://www.itscash.me"
            }
        },
        {
            "chainId": 101,
            "address": "7sGYCckERRZ7cEpa8vxepoby953Z42jLbfyGg5HeSe3r",
            "symbol": "GIGA",
            "name": "Giga Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7sGYCckERRZ7cEpa8vxepoby953Z42jLbfyGg5HeSe3r/logo1.png",
            "tags": [
                "stake-pool-token",
                "utility-token",
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C1XaSueYWYCVrYqRcYLx5Qg9QngGqM3Ar6NecnCBxtta",
            "symbol": "BX",
            "name": "BitPhoenix X",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C1XaSueYWYCVrYqRcYLx5Qg9QngGqM3Ar6NecnCBxtta/logo.png",
            "tags": [
                "digital-coin"
            ],
            "extensions": {
                "website": "https://github.com/bitphoenixx"
            }
        },
        {
            "chainId": 101,
            "address": "EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa",
            "symbol": "PHY",
            "name": "Physis",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa/logo.png",
            "tags": [
                "utility-token",
                "metaverse",
                "multiverse",
                "wellness",
                "extended-reality",
                "augmented-reality",
                "virtual-reality",
                "mixed-reality",
                "physis"
            ],
            "extensions": {
                "coingeckoId": "physis",
                "discord": "https://discord.gg/jQ4fXyD6cz",
                "telegram": "https://t.me/PhysisVerse",
                "twitter": "https://twitter.com/PhysisVerse",
                "website": "https://phys.is/"
            }
        },
        {
            "chainId": 101,
            "address": "3Mvx4nQcUNL6EcxxPBhxaCffs8nDGEuLFh3TAwdbgBCZ",
            "symbol": "PHY-SOL",
            "name": "Physis LP Token (PHY-SOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa/logo.png",
            "tags": [
                "lp-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8WwG3MnTPhcibxnywUhLLBJkk4LAUFhBouDasiBE7hZy",
            "symbol": "PFDT",
            "name": "Platinum Fund Dev Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "medium": "https://medium.com/platinum-fund",
                "telegram": "https://t.me/platinumfund",
                "website": "https://platinum.fund"
            }
        },
        {
            "chainId": 101,
            "address": "JAcbPtpS9HsTAvhmQx7Nz95Kq7LRidX3ePXRowgQNHix",
            "symbol": "SMB",
            "name": "Fraktionalized SMB 1086",
            "decimals": 3,
            "logoURI": "https://arweave.net/gb8JNvIYFUscR5WCHsdYpFKRWcn8mb6T5b2Pn0cJNaE",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/8D1KmYWLdXZZiHqHbs8FS7Kw7RyF3jz2QbkhbwGGxhGj",
                "vaultPubkey": "8D1KmYWLdXZZiHqHbs8FS7Kw7RyF3jz2QbkhbwGGxhGj"
            }
        },
        {
            "chainId": 101,
            "address": "9dwrdifAVWZsyEPxi15D8LcLsdrvTbpabrGw5EVzc7pp",
            "symbol": "NinjaDoge",
            "name": "NinjaDoge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9dwrdifAVWZsyEPxi15D8LcLsdrvTbpabrGw5EVzc7pp/logo.png",
            "tags": [
                "Meme",
                "community",
                "utility-token",
                "shib",
                "nft"
            ],
            "extensions": {
                "serumV3Usdc": "Bm6GpVJsSfhmLEwJqQxGRLBvq4Gf5ERCsY8Xp1TZzPAk",
                "twitter": "https://twitter.com/sol_ninja_doge"
            }
        },
        {
            "chainId": 101,
            "address": "9VH6kTELjTFd1RunKZJsCvtzAVLTTsb44kQzWywixLbX",
            "symbol": "SHIBMOON",
            "name": "Shib Moon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9VH6kTELjTFd1RunKZJsCvtzAVLTTsb44kQzWywixLbX/logo.png",
            "tags": [
                "Meme",
                "community",
                "utility-token",
                "Shiba"
            ],
            "extensions": {
                "serumV3Usdc": "2B6CxvMS91eNyQ1FPyEqybvjFiZtKaBqHWKfXAKn1jij"
            }
        },
        {
            "chainId": 101,
            "address": "7s7PKr3qhuvZjngR1Zmsy53tFLLhZA4aoMnzeE8Z2H5Z",
            "symbol": "GALAXY",
            "name": "Galaxy",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7s7PKr3qhuvZjngR1Zmsy53tFLLhZA4aoMnzeE8Z2H5Z/logo.png",
            "tags": [
                "metaverse",
                "gaming",
                "utility-token",
                "nft",
                "DeFi"
            ],
            "extensions": {
                "serumV3Usdc": "2B6CxvMS91eNyQ1FPyEqybvjFiZtKaBqHWKfXAKn1jij"
            }
        },
        {
            "chainId": 101,
            "address": "9TcgiNyRYyTusbQKS7nXuWY9QajdBk4YQphoUCqesAFD",
            "symbol": "UNISOL",
            "name": "UNISOL",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TcgiNyRYyTusbQKS7nXuWY9QajdBk4YQphoUCqesAFD/logo.png",
            "tags": [
                "meme",
                "community",
                "utility-token"
            ],
            "extensions": {
                "serumV3Usdc": "8pQRoGRYnJaxQ33WyaXajMQP3XR4Kw6Tf1PeVHQewoJC"
            }
        },
        {
            "chainId": 101,
            "address": "FncRHFTSigcNzH66WP3Jh7kupaEHtGV48x8RyMm9cU6d",
            "symbol": "SNIPPLES",
            "name": "Solana Nipples",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FncRHFTSigcNzH66WP3Jh7kupaEHtGV48x8RyMm9cU6d/logo.png",
            "tags": [
                "meme",
                "community",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4mZhMJYyFxUfBmNqvc1jFwnYi6YauELx6puA5kcymEBb",
            "symbol": "GXO",
            "name": "Geometry Operational token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4mZhMJYyFxUfBmNqvc1jFwnYi6YauELx6puA5kcymEBb/logo.png",
            "tags": [
                "geometry",
                "geometryfi",
                "geobots",
                "governance"
            ],
            "extensions": {
                "discord": "https://www.discord.gg/geobots",
                "medium": "https://medium.com/geometryfi",
                "website": "https://geometry.fi"
            }
        },
        {
            "chainId": 101,
            "address": "zmtcoJNJ8LwGx2T592Sjur5gF3jGJNmawkWrAgTFLYF",
            "symbol": "MINT",
            "name": "Mintbox Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/de1043528e31f03aa093333e2a2de3c6c5da4f06/assets/mainnet/zmtcoJNJ8LwGx2T592Sjur5gF3jGJNmawkWrAgTFLYF/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "Mint",
                "NFTs"
            ]
        },
        {
            "chainId": 103,
            "address": "5aymQzpDXqduK1VgzoKFVsacYZ9qGsPdeAJqa3teUDGs",
            "symbol": "SolWormDev",
            "name": "Solana Worm Dev",
            "decimals": 0,
            "logoURI": "https://arweave.net/QgzEgUiWcPUOPIX8EyPGZpzbh1JysKHPS7K9_xRYDo0",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction",
                "Cupon",
                "Perks"
            ]
        },
        {
            "chainId": 103,
            "address": "7gcP2fx97KpdSEGLBdxtsd7YzjWS6dsA9BLTwQMrM8Pk",
            "symbol": "GoldWormDev",
            "name": "Gold Worm Dev",
            "decimals": 0,
            "logoURI": "https://arweave.net/7qE73qF7dHTEc5dKLndcL7cAxvR45fjoWDXdQdQlBBQ",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction",
                "Cupon",
                "Perks"
            ]
        },
        {
            "chainId": 103,
            "address": "Crm2bpr3ai5QKMNfaq11NkBnHeMGULeP5YDLpkLeJjnw",
            "symbol": "SilverWormDev",
            "name": "Silver Worm Dev",
            "decimals": 0,
            "logoURI": "https://arweave.net/NXblD18WvawSyQ2Qm3n5yqj7i_54xL_hJQpc18tvuCA",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction",
                "Cupon",
                "Perks"
            ]
        },
        {
            "chainId": 103,
            "address": "DPygBbLS3vyF95z6dz64M6DtLDVipBibUctW1bEy5qtw",
            "symbol": "CopperWormDev",
            "name": "Copper Worm Dev",
            "decimals": 0,
            "logoURI": "https://arweave.net/JOAw1EkrtukD3UohmvZTbaqPcTsOV6IaLjuzf6aQS5U",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction",
                "Cupon",
                "Perks"
            ]
        },
        {
            "chainId": 101,
            "address": "33cGFtKGiQPez1m6fHJBbS7nNQNvb5twYifCEL9spguN",
            "symbol": "SolWorm",
            "name": "Solana Worm",
            "decimals": 0,
            "logoURI": "https://arweave.net/QgzEgUiWcPUOPIX8EyPGZpzbh1JysKHPS7K9_xRYDo0",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction",
                "Cupon",
                "Perks"
            ]
        },
        {
            "chainId": 101,
            "address": "FJD3zv1F88DVEHcR8hDAWegHD9VEASYngPoGUEc9Ep64",
            "symbol": "GoldWorm",
            "name": "Gold Worm",
            "decimals": 0,
            "logoURI": "https://arweave.net/7qE73qF7dHTEc5dKLndcL7cAxvR45fjoWDXdQdQlBBQ",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction",
                "Cupon",
                "Perks"
            ]
        },
        {
            "chainId": 101,
            "address": "CcswZVLDUnVNY4UiXJ1SN3fm8nTN9rfkuD8nkXhCUsqz",
            "symbol": "SilverWorm",
            "name": "Silver Worm",
            "decimals": 0,
            "logoURI": "https://arweave.net/NXblD18WvawSyQ2Qm3n5yqj7i_54xL_hJQpc18tvuCA",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction",
                "Cupon",
                "Perks"
            ]
        },
        {
            "chainId": 101,
            "address": "GmjTSDnXj8DFRG5y869MFbjruNwWiJY7GB5oRKq8Jwj",
            "symbol": "CopperWorm",
            "name": "Copper Worm",
            "decimals": 0,
            "logoURI": "https://arweave.net/JOAw1EkrtukD3UohmvZTbaqPcTsOV6IaLjuzf6aQS5U",
            "tags": [
                "Book",
                "BookWorm",
                "NFT",
                "Publisher",
                "Auction",
                "Cupon",
                "Perks"
            ]
        },
        {
            "chainId": 101,
            "address": "E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6",
            "symbol": "CCC",
            "name": "Chairman Chow",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "doge-token",
                "doge"
            ],
            "extensions": {
                "discord": "https://discord.gg/8twDmsWega",
                "telegram": "https://t.me/joinchat/1XaURMrPqOBjNWVk",
                "twitter": "https://twitter.com/chairmanofdoge",
                "website": "https://www.chairmanchowtoken.com"
            }
        },
        {
            "chainId": 103,
            "address": "HBEjV98i1CoyN9nWBdyumomJN6M4EQgkpgHp4c8Ue5cW",
            "symbol": "Miin",
            "name": "Miin Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBEjV98i1CoyN9nWBdyumomJN6M4EQgkpgHp4c8Ue5cW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://miinasian.vn"
            }
        },
        {
            "chainId": 101,
            "address": "3cAzWmrfovbkb3pU9mktcEzCiAXrVWRf2yp3T8PdJ2Zt",
            "symbol": "DRINK",
            "name": "Solana Drink",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3cAzWmrfovbkb3pU9mktcEzCiAXrVWRf2yp3T8PdJ2Zt/logo.jpg",
            "tags": [
                "drink",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/solanadrink",
                "twitter": "https://twitter.com/solanadrink",
                "website": "https://solanadrink.com"
            }
        },
        {
            "chainId": 101,
            "address": "7zBWymxbZt7PVHQzfi3i85frc1YRiQc23K7bh3gos8ZC",
            "symbol": "VI",
            "name": "VI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zBWymxbZt7PVHQzfi3i85frc1YRiQc23K7bh3gos8ZC/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/eWVrppgCex",
                "telegram": "https://t.me/Vybit_app",
                "twitter": "https://twitter.com/vybit_app",
                "website": "https://vybit.app"
            }
        },
        {
            "chainId": 101,
            "address": "DiJWJ6hgV7Vm5JP6SU7xvo7nULR14UvrGoWmSu34fEvZ",
            "symbol": "SolBullDog",
            "name": "SolanaBullDog",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiJWJ6hgV7Vm5JP6SU7xvo7nULR14UvrGoWmSu34fEvZ/logo.png",
            "tags": [
                "meme-token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BullDogSolana",
                "website": "http://bulldogsolana.space/"
            }
        },
        {
            "chainId": 101,
            "address": "4tJZhSdGePuMEfZQ3h5LaHjTPsw1iWTRFTojnZcwsAU6",
            "symbol": "ELU",
            "name": "Elumia Crowns",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tJZhSdGePuMEfZQ3h5LaHjTPsw1iWTRFTojnZcwsAU6/logo.png",
            "tags": [
                "governance-token",
                "elumia",
                "LOE",
                "elumia-crowns"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PlayElumia"
            }
        },
        {
            "chainId": 101,
            "address": "GDsVXtyt2CBwieKSYMEsjjZXXvqz2G2VwudD7EvXzoEU",
            "symbol": "HIRAM",
            "name": "Hiram Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GDsVXtyt2CBwieKSYMEsjjZXXvqz2G2VwudD7EvXzoEU/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "coingeckoId": "hiram-coin",
                "website": "https://freemason-coin.io"
            }
        },
        {
            "chainId": 101,
            "address": "EzL6LLmv4vgfF7irkjG7ZxM92bTJ9f6nFopDXJTow7zj",
            "symbol": "CONDOMS",
            "name": "Solana Condoms",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzL6LLmv4vgfF7irkjG7ZxM92bTJ9f6nFopDXJTow7zj/logo.png",
            "tags": [
                "Meme",
                "community",
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solcondoms",
                "website": "http://solcondoms.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "7EGDKpVgb7W5HK6umEo3xQ2spthwnNa5JcKAPb41R2Ku",
            "symbol": "TBT",
            "name": "The Brain Trust",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7EGDKpVgb7W5HK6umEo3xQ2spthwnNa5JcKAPb41R2Ku/logo.png",
            "tags": [
                "dao-token"
            ],
            "extensions": {
                "website": "https://thebraintrustdao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CmSryDa4mnDYUicq7qSESsTKAdgBP26jSYcg8zavVoJd",
            "symbol": "GM",
            "name": "GM Solana",
            "decimals": 7,
            "logoURI": "https://cdn.jsdelivr.net/gh/brendanhernou/gm/GMSolana.jpg",
            "tags": [
                "social-token",
                "GM-Solana",
                "Goodmorning",
                "GM"
            ],
            "extensions": {
                "website": "https://gmsol.io"
            }
        },
        {
            "chainId": 101,
            "address": "7TmeYWYi29eV4u9Y2APupJBT7Ym28vdkgrw1QKHA3pW6",
            "symbol": "WAVE",
            "name": "WAVE Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/wretchedwaters/wavetoken/main/logo/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://wretchedwaters.io"
            }
        },
        {
            "chainId": 101,
            "address": "icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF",
            "symbol": "ICE",
            "name": "Fancy Diamond ICE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "NFTs"
            ],
            "extensions": {
                "discord": "https://discord.gg/fancydiamondsol/",
                "twitter": "https://twitter.com/fancydiamondsol",
                "website": "https://holdfancy.diamonds/"
            }
        },
        {
            "chainId": 101,
            "address": "Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi",
            "symbol": "JUNGLE",
            "name": "Jungle",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi/logo.png",
            "extensions": {
                "coingeckoId": "jungle",
                "discord": "https://discord.gg/7qjTkEkUuu",
                "twitter": "https://twitter.com/thugmonkez",
                "website": "https://thugmonkez.com/"
            }
        },
        {
            "chainId": 101,
            "address": "97yaD8NFLkMggzxj22KqrWkoYDSMjX7KD9FWY3eCEFh2",
            "symbol": "CORNE",
            "name": "CornDoge",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97yaD8NFLkMggzxj22KqrWkoYDSMjX7KD9FWY3eCEFh2/Corndoge.png"
        },
        {
            "chainId": 101,
            "address": "9ui27Q32YMQtCvbkohgsnhji1gPva43RLmBERfw79xX",
            "symbol": "SOLAR",
            "name": "Solarna Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ui27Q32YMQtCvbkohgsnhji1gPva43RLmBERfw79xX/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "dex",
                "cross-swap",
                "wallet-token"
            ],
            "extensions": {
                "website": "https://solarna.io"
            }
        },
        {
            "chainId": 101,
            "address": "6wiFZaYrisr78dEuCAxLEbJZ2HWoT5SnWNGXZ4ZzEJpy",
            "symbol": "SOLAROLD",
            "name": "Solarna Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wiFZaYrisr78dEuCAxLEbJZ2HWoT5SnWNGXZ4ZzEJpy/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "dex",
                "cross-swap",
                "wallet-token"
            ],
            "extensions": {
                "website": "https://google.com"
            }
        },
        {
            "chainId": 101,
            "address": "2wmKXX1xsxLfrvjEPrt2UHiqj8Gbzwxvffr9qmNjsw8g",
            "symbol": "Solar",
            "name": "Solar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolarDexOfficial/solar_token_icon/main/token-icon.jpeg",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "solar",
                "coinmarketcap": "https://coinmarketcap.com/currencies/solardex/",
                "serumV3Usdc": "BHfFJM36MirbBtLCcnZokwRvxUPxk7Ez6EAT6k44q6Go",
                "telegram": "https://t.me/solardexofficialchat",
                "twitter": "https://twitter.com/solar_dex",
                "website": "https://solardex.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb",
            "symbol": "REAL",
            "name": "Realy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb/logo.svg",
            "tags": [
                "Metaverse",
                "Governance-token"
            ],
            "extensions": {
                "serumV3Usdc": "AU8VGwd4NGRbcMz9LT6Fu2LP69LPAbWUJ6gEfEgeYM33",
                "telegram": "https://t.me/realyofficial",
                "twitter": "https://twitter.com/RealyOfficial",
                "website": "https://realy.pro/"
            }
        },
        {
            "chainId": 101,
            "address": "Evem1GAsUFeAVh6inpwTkdhFFHa9TCU9GtgqQfdKHYre",
            "symbol": "cc",
            "name": "Cat Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Evem1GAsUFeAVh6inpwTkdhFFHa9TCU9GtgqQfdKHYre/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EkSPpfdGCstzExF3eCsHFqjmyS71bguaZ1qJZCXXTCnv",
            "symbol": "SBY",
            "name": "SolBody",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EkSPpfdGCstzExF3eCsHFqjmyS71bguaZ1qJZCXXTCnv/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://solbody.io"
            }
        },
        {
            "chainId": 101,
            "address": "CLVZwHqS1CLdqGMSsFvRYVyGM7HUXAYXNkAfarn2nLRz",
            "symbol": "QTON",
            "name": "QUIZTOK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/quiztok/token-list/main/assets/mainnet/CLVZwHqS1CLdqGMSsFvRYVyGM7HUXAYXNkAfarn2nLRz/logo.png",
            "extensions": {
                "facebook": "https://www.facebook.com/quiztok.kr",
                "github": "https://github.com/quiztok",
                "instagram": "https://www.instagram.com/quiztok_kr/",
                "telegram": "https://t.me/QuiztokCS",
                "twitter": "https://twitter.com/quiztokkr",
                "website": "https://quiztok.com/",
                "youtube": "https://www.youtube.com/channel/UCtl9jTbXMa43F17zrnr1AeQ"
            }
        },
        {
            "chainId": 101,
            "address": "6o4f6tuvVQTa9PTrHN9pvUeXEPusN6RLgMam1Zc7tYbm",
            "symbol": "WEN",
            "name": "WEN Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6o4f6tuvVQTa9PTrHN9pvUeXEPusN6RLgMam1Zc7tYbm/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/notifbutwen"
            }
        },
        {
            "chainId": 101,
            "address": "AHdENcRMAo63LYQiMQN53e3KzXXChw5YPHsqrtRDTZza",
            "symbol": "DEVILz",
            "name": "DEVILz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AHdENcRMAo63LYQiMQN53e3KzXXChw5YPHsqrtRDTZza/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NFTDevilz",
                "website": "https://nftdevilz.com"
            }
        },
        {
            "chainId": 101,
            "address": "Au6EdrSDubCUc34awy9c6iQAg5GSos9pPBXyZQtyZewV",
            "symbol": "NXDF",
            "name": "NeXt-DeFi Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Au6EdrSDubCUc34awy9c6iQAg5GSos9pPBXyZQtyZewV/logo.png",
            "extensions": {
                "discord": "https://discord.gg/KH2YywfrgS",
                "medium": "https://medium.com/@NXDF",
                "reddit": "https://www.reddit.com/r/next_defi_protocol/",
                "telegram": "https://t.me/nxdfarmy",
                "twitter": "https://twitter.com/NXDF16",
                "website": "https://nxdf.io/",
                "youtube": "https://www.youtube.com/channel/UCMdPUPpCTVf-OHvMLCdzxOQ"
            }
        },
        {
            "chainId": 101,
            "address": "yUSDT1nYX9JHLqRCX1RqZyATiRKaSYiMLWLMatr3U2h",
            "symbol": "yUSDC",
            "name": "yUSDC (Parrot USDC Stability Pool Token)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yUSDT1nYX9JHLqRCX1RqZyATiRKaSYiMLWLMatr3U2h/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/gopartyparrot",
                "medium": "https://gopartyparrot.medium.com/",
                "telegram": "https://t.me/gopartyparrot",
                "twitter": "https://twitter.com/gopartyparrot",
                "website": "https://parrot.fi"
            }
        },
        {
            "chainId": 101,
            "address": "Learn5MyYtgdjiHfvW3w2z79mHws2TcfmrL2sA5grgz",
            "symbol": "LEARN",
            "name": "Learn Crypto",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Learn5MyYtgdjiHfvW3w2z79mHws2TcfmrL2sA5grgz/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/learncrypto",
                "telegram": "https://t.me/learncrypto5"
            }
        },
        {
            "chainId": 101,
            "address": "J948cnkxckQ1HS9xVeThCg61t89Y69oyMYCGHBH9WjFW",
            "symbol": "MIL",
            "name": "Foresight Utility Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J948cnkxckQ1HS9xVeThCg61t89Y69oyMYCGHBH9WjFW/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "website": "https://www.foresight.mobi/"
            }
        },
        {
            "chainId": 101,
            "address": "KaizUQ81Xku3PZpHDrWW1oDNLjTez6a2JiDznydXqNC",
            "symbol": "KAIZEN",
            "name": "Kaizen.Finance",
            "decimals": 9,
            "logoURI": "https://kaizen.finance/assets/images/kaizen-assets/logo-kaizen-symbol.png",
            "tags": [
                "kaizen",
                "kaizen-finance",
                "locked-token",
                "vesting-token",
                "bridge",
                "DEX"
            ],
            "extensions": {
                "description": "Kaizen is the first-ever cross-chain token lifecycle management platform built with the needs of both projects and investors alike.",
                "medium": "https://kaizenfinance.medium.com",
                "telegram": "https://t.me/KaizenFinance",
                "twitter": "https://twitter.com/kaizen_finance",
                "website": "https://kaizen.finance"
            }
        },
        {
            "chainId": 103,
            "address": "Ea9UXtBtVn1CcYjs5QWrRoyjsUE1YnMWFVoYui5kkA7o",
            "symbol": "ETE",
            "name": "Eternal Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ea9UXtBtVn1CcYjs5QWrRoyjsUE1YnMWFVoYui5kkA7o/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HaiTran60137177",
                "website": "https://www.s3corp.com.vn"
            }
        },
        {
            "chainId": 101,
            "address": "DbRA7Jp8p3tztoPWrDQeJqpKLKXJpotUzJoeiiCdxewz",
            "symbol": "GMP",
            "name": "Gamerpull",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DbRA7Jp8p3tztoPWrDQeJqpKLKXJpotUzJoeiiCdxewz/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Play video games with anyone anywhere in the world on any platform and earn using your Esport skills.",
                "serumV3Usdt": "FoUNnH6RTVFrL92PsvgLbwTZbFHK1PUe1rb2ZWfF3VGK",
                "twitter": "https://twitter.com/GamerPull",
                "website": "https://gamerpull.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DqxzPWQ2FKHn8pRoy9jCpA6M3GkEqYfieiAVwMYWVyXr",
            "symbol": "ROAR",
            "name": "Roar Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DqxzPWQ2FKHn8pRoy9jCpA6M3GkEqYfieiAVwMYWVyXr/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Earn ROAR by playing to soltigers game",
                "twitter": "https://twitter.com/SOL_Tigers",
                "website": "https://soltigers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt",
            "symbol": "APT",
            "name": "Apricot",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt/logo.svg",
            "tags": [
                "apricot",
                "lending",
                "x-farm"
            ],
            "extensions": {
                "coingeckoId": "apricot",
                "discord": "https://discord.gg/Aw6MEUue",
                "medium": "https://apricotfinance.medium.com/",
                "twitter": "https://twitter.com/ApricotFinance",
                "website": "https://apricot.one"
            }
        },
        {
            "chainId": 103,
            "address": "H6UWLcYpDoDUNXgnFxxvKtm4MhKENVVAuGukyMHxpyNJ",
            "symbol": "LDXI",
            "name": "LDXI Token",
            "decimals": 9,
            "logoURI": "https://github.com/Randi-Edi/token_logos/blob/main/ldx.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DH5KjPM53i7NMj69CEZ6FiF82ipbgz1U6QzNfQNY87Pr",
            "symbol": "SOLAB",
            "name": "SOLAB Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Protossnam/token-list/main/assets/mainnet/DH5KjPM53i7NMj69CEZ6FiF82ipbgz1U6QzNfQNY87Pr/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9EypAuptQxzpq5hNob6S4HT75UAbcbNsh6ysYmnic8vf",
            "symbol": "MDC",
            "name": "Medcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EypAuptQxzpq5hNob6S4HT75UAbcbNsh6ysYmnic8vf/logo.png",
            "tags": [
                "Community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/medcointech",
                "website": "https://medcoin.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "2ScZPtpLG4bb3SHfXEuTr9BuLdixDc1v3UTga2iiSH8n",
            "symbol": "CHONK",
            "name": "CHONKERS",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/megachonk/chonk/main/Chonkers.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ChonkersSolana"
            }
        },
        {
            "chainId": 103,
            "address": "9XPDVRQ3vfeccyvj6xh2uS5meuRKTXPueWcqGKgjo6pK",
            "symbol": "BTP",
            "name": "BLOCKOVATE TEST PROJECT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XPDVRQ3vfeccyvj6xh2uS5meuRKTXPueWcqGKgjo6pK/logo.png"
        },
        {
            "chainId": 101,
            "address": "AfXLBfMZd32pN6QauazHCd7diEWoBgw1GNUALDw3suVZ",
            "symbol": "FIRE",
            "name": "Solfire Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AfXLBfMZd32pN6QauazHCd7diEWoBgw1GNUALDw3suVZ/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solfirefinance",
                "website": "https://solfire.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q",
            "symbol": "THECA",
            "name": "Theca",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q/logo.png",
            "tags": [
                "utility-token",
                "Application"
            ],
            "extensions": {
                "address": "D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q",
                "coingeckoId": "theca",
                "coinmarketcap": "https://coinmarketcap.com/currencies/theca/",
                "description": "Theca has the mission to protect, spread and promote knowledge through blockchain technology, making the works of authors accessible to anyone. Theca: the first blockchain library",
                "discord": "https://discord.com/invite/YfbVenPbvq",
                "instagram": "https://www.instagram.com/theca_org",
                "medium": "https://theca.medium.com/",
                "serumV3Usdc": "6uH2rvVUySwnVFw7CMNV4HTzuxHTUNC7pK22pXcqgSey",
                "twitter": "https://twitter.com/Theca_org",
                "website": "https://theca.org"
            }
        },
        {
            "chainId": 101,
            "address": "3eLpKZBgu6pKG2TSpvTfTeeimT294yxV2AEiBKZdY2ai",
            "symbol": "SGG",
            "name": "Solx Gaming Guild",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3eLpKZBgu6pKG2TSpvTfTeeimT294yxV2AEiBKZdY2ai/logo.png",
            "tags": [
                "dao",
                "gaming",
                "gamefi",
                "utility-token"
            ],
            "extensions": {
                "description": "Solx Gaming Guild DAO",
                "twitter": "https://twitter.com/SolxGamingGuild"
            }
        },
        {
            "chainId": 101,
            "address": "DLvxinqHRjdJAXW3WJdvbGC5WgBr1v6RCXquLANM7DcV",
            "symbol": "SLPY",
            "name": "Solapoly Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DLvxinqHRjdJAXW3WJdvbGC5WgBr1v6RCXquLANM7DcV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solapolygame",
                "website": "https://www.solapoly.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3xXMjiMyu4hthrVWmsxvBrKtehBWFgSKRnGB9Je4mmdA",
            "symbol": "SINGULARITY",
            "name": "Singularity",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3xXMjiMyu4hthrVWmsxvBrKtehBWFgSKRnGB9Je4mmdA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.singularityonline.app",
                "description": "Singularity Online in-game currency Token",
                "twitter": "https://twitter.com/SingularityMeta",
                "website": "https://singularityonline.app",
                "whitepaper": "https://whitepaper.singularityonline.app"
            }
        },
        {
            "chainId": 101,
            "address": "8JSMcHAXLjmdcxXre3Cb6uccbMUSRvLw4NF3WNDP7UVD",
            "symbol": "SBALL",
            "name": "SOLANA KING BALL",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/DQ0HmQp/logos.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/WNzG2QKdgj",
                "twitter": "https://twitter.com/solanakingball"
            }
        },
        {
            "chainId": 101,
            "address": "6TrhkzBAokyonkodNY3hsxqMhf1kaj6Zjk7QKc3E6QZc",
            "symbol": "TRS",
            "name": "Trush",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TrhkzBAokyonkodNY3hsxqMhf1kaj6Zjk7QKc3E6QZc/logo.png",
            "tags": [
                "defi"
            ],
            "extensions": {
                "website": "https://www.trush.io"
            }
        },
        {
            "chainId": 101,
            "address": "81Y4XyV6TQj3XjQnJ1LNq9BifH6BwwuymPFjNd5Ab8i7",
            "symbol": "SADBOX",
            "name": "Sad Box Token",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/Zilula/moke-token/sadboxLogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HAZyBXwLQUxBPNbCeHdHYDFt3qjEQFEsJ2ojWE66zHRP",
            "symbol": "SOCKFR",
            "name": "SockFRACT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAZyBXwLQUxBPNbCeHdHYDFt3qjEQFEsJ2ojWE66zHRP/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/rznuTnenPJ",
                "medium": "https://medium.com/@mimo_3408",
                "twitter": "https://twitter.com/MillionMonke",
                "website": "https://millionmonke.com/"
            }
        },
        {
            "chainId": 101,
            "address": "62n7HVBjPpydoZEfipAZ2vasGnaC6jMB5cRoBsTdwxAd",
            "symbol": "SDXC",
            "name": "SOLIDEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/62n7HVBjPpydoZEfipAZ2vasGnaC6jMB5cRoBsTdwxAd/logo.png",
            "extensions": {
                "instagram": "https://instagram.com/solidex_finance",
                "telegram": "https://t.me/solidexfinance",
                "twitter": "https://twitter.com/solidexf",
                "website": "https://solidex.finance"
            }
        },
        {
            "chainId": 101,
            "address": "ChZp13iVvEYPSfhYnURo8cegaT8yUN8GYnUFfV1meB7L",
            "symbol": "paimon",
            "name": "paimon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChZp13iVvEYPSfhYnURo8cegaT8yUN8GYnUFfV1meB7L/logo.png",
            "tags": [
                "paimon-token",
                "paimon"
            ]
        },
        {
            "chainId": 101,
            "address": "29gwg5bs4x33L53wRfPoerReQfQgMDwftACQifGEtqk6",
            "symbol": "GOS",
            "name": "Gorilla Squad",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/29gwg5bs4x33L53wRfPoerReQfQgMDwftACQifGEtqk6/logo.png",
            "tags": [
                "NFT",
                "meme",
                "Community-token"
            ],
            "extensions": {
                "description": "The Gorilla Squad comprises 5,555 unique NFTs with in-born attributes and a sole mission to RULE.",
                "discord": "https://discord.gg/BaBYDhYJQn",
                "twitter": "https://twitter.com/gorrilla_squad",
                "website": "https://www.thegorillasquad.com"
            }
        },
        {
            "chainId": 101,
            "address": "F8qiDPBtLm3a78gdRQ5eioMiL3Swb8dyeUpULsUHEhDY",
            "symbol": "CREATIVE",
            "name": "Creative Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dmontaldo/static-content/main/creative-token.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EgunFXyGMyGkAy5mWhL1CcHT9RqDTn9vE1HWEbF7jMkC",
            "symbol": "HZB",
            "name": "Haizenberg",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EgunFXyGMyGkAy5mWhL1CcHT9RqDTn9vE1HWEbF7jMkC/logo.png",
            "extensions": {
                "telegram": "https://t.me/hzb_channel",
                "website": "https://hzbt.github.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BzNgVm6TAUzo81QJzXCEockZskrKgB96huDRae9pwJXZ",
            "symbol": "GAMEFI",
            "name": "GameFi Coin",
            "decimals": 9,
            "logoURI": "https://gameficoin.com/images/gamefilogo.png",
            "tags": [
                "gamefi",
                "meme",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/gameficoin",
                "website": "https://gameficoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "FXdxsZhNYGSBdne2LZ448SJ1QDXk8KaEzvKivCvc38h3",
            "symbol": "ACCT",
            "name": "Agrinix Carbon Credit Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/brunofaczz/acct-image/logo.jpeg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://acct.agrinix.com.br"
            }
        },
        {
            "chainId": 101,
            "address": "NTRNt4MmibcfkRHww3Y4WXRwFkXWxLvFXhBJ27YUbVN",
            "symbol": "NTRN",
            "name": "UNQ Neutrino",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NTRNt4MmibcfkRHww3Y4WXRwFkXWxLvFXhBJ27YUbVN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/unqnetwork",
                "twitter": "https://twitter.com/clubunq",
                "website": "https://unquniverse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DMTRqrUy2MVRTLZN7wv54sboHZDiiVsbeqXAiCUpDyNZ",
            "symbol": "DMTR",
            "name": "UNQ Dark Matter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMTRqrUy2MVRTLZN7wv54sboHZDiiVsbeqXAiCUpDyNZ/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/unqnetwork",
                "twitter": "https://twitter.com/clubunq",
                "website": "https://unquniverse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E4D4p3cSHZrSATTqvVMy5PsBJWZMKFE5fJYVJPHHo7B4",
            "symbol": "LOOTERS",
            "name": "Shadow Looters Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E4D4p3cSHZrSATTqvVMy5PsBJWZMKFE5fJYVJPHHo7B4/logo.png",
            "tags": [
                "Governance-token",
                "Game-Fi"
            ],
            "extensions": {
                "discord": "https://discord.gg/5E4RzUPYjs",
                "twitter": "https://twitter.com/shadowlooters"
            }
        },
        {
            "chainId": 101,
            "address": "5PnmhtnvJgPnBuRtgG8erR5AwAPkqkEF9MLieFnxLygX",
            "symbol": "PARTY",
            "name": "Party Giraffes",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/xRoBBeRT/PG-Logo/PGlogo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/partygiraffes",
                "twitter": "https://twitter.com/PartyGiraffes",
                "website": "https://partygiraffes.net"
            }
        },
        {
            "chainId": 101,
            "address": "6zQwyDe541Ys4Q85vkhvHMMRW7ypA4PyHCSF1doWZ4tw",
            "symbol": "FLOKIS",
            "name": "Floki Shiba Solana",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/aBLrIE0.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/QDfXeXhkqR",
                "twitter": "https://twitter.com/floki_solana",
                "website": "http://flokisol.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "DhfF81uahANRYQ4rn8VZKSGVmLE4k2nzccgfZMazmLJ2",
            "symbol": "TIPZ",
            "name": "Tipzcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DhfF81uahANRYQ4rn8VZKSGVmLE4k2nzccgfZMazmLJ2/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tipzcoin"
            }
        },
        {
            "chainId": 101,
            "address": "jKoDjNz1nVmBz2X5d5DvcKBon1VLh2TS9VjsoCJJ4AD",
            "symbol": "NIB",
            "name": "NiB Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/devbaze/looptoken/logo.png",
            "tags": [
                "social-token",
                "loop"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/EtRQ4gs73c"
            }
        },
        {
            "chainId": 101,
            "address": "9VVAUW3NjXWgsaD7eNjDmGeSxLP9v72neKKUvXSkijsK",
            "symbol": "SHIBANA",
            "name": "SHIBANA",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9VVAUW3NjXWgsaD7eNjDmGeSxLP9v72neKKUvXSkijsK/Shibana.png",
            "tags": [
                "community-token",
                "meme-token",
                "Defi"
            ],
            "extensions": {
                "discord": "https://discord.gg/shibana",
                "twitter": "https://twitter.com/ShibanaCoin",
                "website": "https://www.shibana.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF",
            "symbol": "1SOL",
            "name": "1sol.io Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF/logo.png",
            "tags": [
                "wrapped",
                "utility-token",
                "aggregator"
            ],
            "extensions": {
                "coinmarketcap": "https://coinmarketcap.com/currencies/1sol/",
                "description": "1Sol aggregates DEX(s), lending, yield-farming, and cross-chain trading.",
                "discord": "https://discord.gg/juvVBKnvkj",
                "github": "https://github.com/1sol-io",
                "medium": "https://medium.com/@1solProtocol",
                "telegram": "https://t.me/onesolcommunity",
                "twitter": "https://twitter.com/1solProtocol",
                "website": "https://app.1sol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Az4wTkL8AbFps6yJ7WgMg4kAS7UWxaHPxWzdxSCLbiA8",
            "symbol": "KNSR",
            "name": "KNSR Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Az4wTkL8AbFps6yJ7WgMg4kAS7UWxaHPxWzdxSCLbiA8/knsr.svg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.knsr.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EgnemVHLdXUkz6LFQRaQBihYFqWWMC8hStpZhrPkKyec",
            "symbol": "MGX",
            "name": "Magmox",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MagmoxMGXTeam/token-list/main/assets/mainnet/EgnemVHLdXUkz6LFQRaQBihYFqWWMC8hStpZhrPkKyec/logo.png",
            "tags": [
                "utility"
            ],
            "extensions": {
                "telegram": "https://t.me/joinMagmoxMGX",
                "twitter": "https://twitter.com/MagmoxMGX",
                "website": "https://magmox.com"
            }
        },
        {
            "chainId": 101,
            "address": "DmXfDUeyRJqnpvdjssGgUXwZrRFPXvu2DfMq4jfTTC9C",
            "symbol": "WEENS",
            "name": "Ween",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DmXfDUeyRJqnpvdjssGgUXwZrRFPXvu2DfMq4jfTTC9C/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "coingeckoId": "ween-token",
                "description": "Ween Token, a meme coin for people who love wiener dogs.",
                "discord": "https://discord.gg/longweenclub",
                "twitter": "https://twitter.com/weentoken",
                "website": "https://weentoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "HNtzvJgduuyNsFDnqH4GyHDqNQJShJMxzUcGnUW9xeWi",
            "symbol": "AGRO",
            "name": "Agroland",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/xan5h33/agroland/master/agLogo.png",
            "extensions": {
                "github": "https://github.com/xan5h33/agroland"
            }
        },
        {
            "chainId": 101,
            "address": "STMSXv13iwhDxH2MZa5bTr1pSsn7qH5VRKbuJvMzvkm",
            "symbol": "STM",
            "name": "Soltama",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/STMSXv13iwhDxH2MZa5bTr1pSsn7qH5VRKbuJvMzvkm/logo.png",
            "tags": [
                "meme",
                "Nft"
            ],
            "extensions": {
                "website": "http://www.soltama.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FcjViE1oVbXFza5ry9Q7WZ9XNd95zu1LqEiSUdeJMPTP",
            "symbol": "wbBUSD-4Pool",
            "name": "Mercurial LP Token (BUSD-4Pool)",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FcjViE1oVbXFza5ry9Q7WZ9XNd95zu1LqEiSUdeJMPTP/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "CZrt1PboqCm78iZBztRkHDkpzxLYrm2YeHYtE4voyeJh",
            "symbol": "BNTR",
            "name": "Crypto Banter",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CZrt1PboqCm78iZBztRkHDkpzxLYrm2YeHYtE4voyeJh/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "DeFi"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/cryptobanter",
                "instagram": "https://www.instagram.com/crypto_banter",
                "twitter": "https://twitter.com/crypto_banter",
                "youtube": "https://www.youtube.com/channel/UCN9Nj4tjXbVTLYWN0EKly_Q"
            }
        },
        {
            "chainId": 101,
            "address": "FzbRW9UWtpJxBJBuTRsTgpdJrQvDUVjddhiTuGVkhENz",
            "symbol": "SOLG",
            "name": "Solana Gold",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FzbRW9UWtpJxBJBuTRsTgpdJrQvDUVjddhiTuGVkhENz/logo.png",
            "tags": [
                "Community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solanagoldorg",
                "website": "https://solanagold.org/"
            }
        },
        {
            "chainId": 101,
            "address": "45ojchnvC3agGNvs86MWBq8N4miiTY6X8ECQzgQNDE4v",
            "symbol": "SWERVE",
            "name": "Swerve Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/swervesolana/swerve-assets/main/logo_400x400.jpg",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/swervesolana",
                "website": "https://www.swervesolana.com"
            }
        },
        {
            "chainId": 101,
            "address": "6vEscJrY775p2JtCGXYgaq2rXkvv2G2edeQoRRLfiQzW",
            "symbol": "SNFT",
            "name": "OpenSolNFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6vEscJrY775p2JtCGXYgaq2rXkvv2G2edeQoRRLfiQzW/logo.png",
            "tags": [
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DAZbw2FG5PCssV24SsP1E3m1whDn8paXZMwBdfVHRt5w",
            "symbol": "MCS",
            "name": "Million Coin Solana",
            "decimals": 9,
            "logoURI": "https://cdn.discordapp.com/attachments/908414498933735525/908414576838733854/Square-512.jpg",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MillionCoinSol"
            }
        },
        {
            "chainId": 101,
            "address": "EpnJLu5oc1UWG25jNvSzuezz1ENrxbDnLg1wQDC7Hfbw",
            "symbol": "TURD",
            "name": "Actual Shitcoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EpnJLu5oc1UWG25jNvSzuezz1ENrxbDnLg1wQDC7Hfbw/logo.png",
            "tags": [
                "MEME"
            ],
            "extensions": {
                "twitter": "https://twitter.com/actualshitcoin",
                "website": "https://actualshitcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "3BWKZdsnHusiQ9Z7mGqp6aJVBcukXKy1Y33cBeeXtWEM",
            "symbol": "GAEA",
            "name": "GAEA Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3BWKZdsnHusiQ9Z7mGqp6aJVBcukXKy1Y33cBeeXtWEM/logo.png"
        },
        {
            "chainId": 101,
            "address": "CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN",
            "symbol": "CAPY",
            "name": "Capybara",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN/logo.png",
            "tags": [
                "meme",
                "Nft"
            ],
            "extensions": {
                "website": "http://www.capybaraworld.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16",
            "symbol": "MIMO",
            "name": "Million Monke",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/rznuTnenPJ",
                "medium": "https://medium.com/@mimo_3408",
                "twitter": "https://twitter.com/MillionMonke",
                "website": "https://millionmonke.com/"
            }
        },
        {
            "chainId": 101,
            "address": "c8JyuF2fD84G6Vk4AmeVfTUoseNRmv2A9JpymAXi4B6",
            "symbol": "WENSIR",
            "name": "Wensircoin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/c8JyuF2fD84G6Vk4AmeVfTUoseNRmv2A9JpymAXi4B6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/wensircoin",
                "website": "https://www.wensirsolana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7yyTQNQdi45pRVCkDXB8yLRcPw9KFvd9P2Bw3u22HUj9",
            "symbol": "ATWORLD",
            "name": "ATWORLD",
            "decimals": 3,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/ATWORLD/ATWORLDtoken/ATWORLD-LOGO.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/ATWORLDCORP/",
                "telegram": "https://t.me/atworldtoken",
                "twitter": "https://twitter.com/atworldtoken",
                "website": "https://Atworld.io/"
            }
        },
        {
            "chainId": 101,
            "address": "B7xc2vPFDzWwD3ZaHPr2sACB49XFM2bqMQpqpoo87TjA",
            "symbol": "SRBs",
            "name": "Solana Robots",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B7xc2vPFDzWwD3ZaHPr2sACB49XFM2bqMQpqpoo87TjA/logo.png",
            "tags": [
                "community-token",
                "srbs"
            ]
        },
        {
            "chainId": 101,
            "address": "2946ofy854iifvXCQmHX2AJgxRBoQcchy1gfD26RtkHp",
            "symbol": "SHIBT",
            "name": "SHIBA LIGHT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2946ofy854iifvXCQmHX2AJgxRBoQcchy1gfD26RtkHp/logo.png",
            "tags": [
                "DEX",
                "MEME"
            ],
            "extensions": {
                "discord": "https://discord.gg/aWcp7uRe",
                "telegram": "https://t.me/shiba_light",
                "website": "https://shibalight.com"
            }
        },
        {
            "chainId": 101,
            "address": "4bLJGNHxUyUSBAaKaRt4J6bM7Pi7d7L8fF7u7vbonPMa",
            "symbol": "CLEF",
            "name": "CLEF COIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4bLJGNHxUyUSBAaKaRt4J6bM7Pi7d7L8fF7u7vbonPMa/logo.png"
        },
        {
            "chainId": 101,
            "address": "4fZ39Btvq4mB9Z1tWWtqfMS2cgyAUbkfPeGCGuiPpk29",
            "symbol": "CDC",
            "name": "Crypto Dispensary Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4fZ39Btvq4mB9Z1tWWtqfMS2cgyAUbkfPeGCGuiPpk29/logo.png",
            "extensions": {
                "website": "https://cryptodispensary.net"
            }
        },
        {
            "chainId": 101,
            "address": "CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v",
            "symbol": "AGX",
            "name": "AGX Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "address": "0x69CF1e63090Acf1e1E16ec0066055f8973fc9Ec8",
                "assetContract": "https://etherscan.io/address/0x69CF1e63090Acf1e1E16ec0066055f8973fc9Ec8",
                "website": "https://lode.one"
            }
        },
        {
            "chainId": 101,
            "address": "PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3",
            "symbol": "POLE",
            "name": "Pole Aggregator Governance Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/RASngHfHhS",
                "github": "https://github.com/pole-finance",
                "medium": "https://medium.com/pole-aggregator",
                "serumV3Usdc": "Ak1UDskYAjztX5YkxW8dmERjLwS9fYmk1b3G6fpWDxp6",
                "twitter": "https://twitter.com/Pole_Finance",
                "website": "https://pole.finance"
            }
        },
        {
            "chainId": 101,
            "address": "FvvQ6VPBkYB79ti5HmF1ea5Hbdkq7an3UsNmRa5TSfTW",
            "symbol": "K2J",
            "name": "K2J",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FvvQ6VPBkYB79ti5HmF1ea5Hbdkq7an3UsNmRa5TSfTW/logo.png"
        },
        {
            "chainId": 101,
            "address": "cNXe9Qi7xPYxyjw8D8BGs9MTPnCNwppg2bEmzdaaprp",
            "symbol": "GAMEZ",
            "name": "GAMERROOM",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cNXe9Qi7xPYxyjw8D8BGs9MTPnCNwppg2bEmzdaaprp/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/the_gamerroom",
                "website": "https://gamerroom.io"
            }
        },
        {
            "chainId": 101,
            "address": "46Sg2dkZ4gMRHt6QpuWdUiA7HbgeavyKeGiK7hDggeow",
            "symbol": "MELS",
            "name": "MeelasCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/46Sg2dkZ4gMRHt6QpuWdUiA7HbgeavyKeGiK7hDggeow/logo.png"
        },
        {
            "chainId": 101,
            "address": "DdNj56j1daQhtTKRzrFsrZqsh3EiMmeHcrv2h9ojNHqr",
            "symbol": "AYO",
            "name": "Ayoken",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DdNj56j1daQhtTKRzrFsrZqsh3EiMmeHcrv2h9ojNHqr/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ayokenlabs",
                "website": "https://www.ayoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "4hNvzLiFSVt9AL5cpUszVredNbmQFvvokDGexQxKuFV2",
            "symbol": "WLP",
            "name": "Web Link Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hNvzLiFSVt9AL5cpUszVredNbmQFvvokDGexQxKuFV2/logo.png",
            "tags": [
                "utility-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AjaDbQQmkZ1iWE6MR2xBuoNNRmVJhV8f3wwSrAeg9ggA",
            "symbol": "D20",
            "name": "d20 Gamer Nation",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AjaDbQQmkZ1iWE6MR2xBuoNNRmVJhV8f3wwSrAeg9ggA/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7G5ZjxYp7M",
                "website": "https://thegamernation.org"
            }
        },
        {
            "chainId": 101,
            "address": "Gemd25RpoWBa6HviAXxLyf1sbGT23CUxHjmwetzTEfL2",
            "symbol": "GEMS",
            "name": "Space Heads Gem",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gemd25RpoWBa6HviAXxLyf1sbGT23CUxHjmwetzTEfL2/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/spaceheads",
                "instagram": "https://www.instagram.com/space_heads_nft/",
                "twitter": "https://twitter.com/space_heads_nft",
                "website": "https://spaceheads.co"
            }
        },
        {
            "chainId": 101,
            "address": "5vm1hVMm9VpRR1HrdGJdcTHMzTsKkY8nVm3BUYoU4wUw",
            "symbol": "SHIMO",
            "name": "Shibi Samo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5vm1hVMm9VpRR1HrdGJdcTHMzTsKkY8nVm3BUYoU4wUw/logo.png",
            "tags": [
                "meme",
                "community",
                "NFTs",
                "DEFI",
                "Dog"
            ],
            "extensions": {
                "discord": "https://discord.gg/dCzfnNtZaH",
                "telegram": "https://t.me/joinchat/DX1mQFn9behmNjRl",
                "twitter": "https://twitter.com/ShibiSamo",
                "website": "https://shibi-samo.com"
            }
        },
        {
            "chainId": 101,
            "address": "86H9UVqUFqPjKiMPVhRLfWiTsJRWRc9yFGJACmboZCyi",
            "symbol": "FSKZ",
            "name": "FUTURE SKULLZ",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/86H9UVqUFqPjKiMPVhRLfWiTsJRWRc9yFGJACmboZCyi/logo.png"
        },
        {
            "chainId": 101,
            "address": "8W6QYqd35bH6BWt2zQVVcKzJj6MrXeGAsnEhcrsogypK",
            "symbol": "TIMBA",
            "name": "TIMBA DAO ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8W6QYqd35bH6BWt2zQVVcKzJj6MrXeGAsnEhcrsogypK/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Fv89T76F"
            }
        },
        {
            "chainId": 101,
            "address": "2FKuYE5D75e9Fjg3ymGBrFfVc8tVKac4SeyvZn5dGNUz",
            "symbol": "VITAL",
            "name": "VITAL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FKuYE5D75e9Fjg3ymGBrFfVc8tVKac4SeyvZn5dGNUz/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "vitall-markets",
                "discord": "https://discord.gg/Z3cFSSZKwU",
                "twitter": "https://twitter.com/vitallmarkets",
                "website": "https://www.vitallmarkets.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FLuFFY16jePbVTrJ68SY3YbwyZvne2w2ktAVkArpBufG",
            "symbol": "FLUFFY",
            "name": "Fluffy Heads Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLuFFY16jePbVTrJ68SY3YbwyZvne2w2ktAVkArpBufG/logo.png",
            "tags": [
                "social-token",
                "Fluffy"
            ],
            "extensions": {
                "discord": "https://discord.gg/msdttEjB7u",
                "twitter": "https://twitter.com/FluffyHeadsNFT",
                "website": "https://fluffyheads.club/"
            }
        },
        {
            "chainId": 101,
            "address": "seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs",
            "symbol": "SEEDED",
            "name": "Seeded Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs/logo.png",
            "tags": [
                "lending",
                "defi"
            ],
            "extensions": {
                "discord": "https://seeded.network/discord",
                "medium": "https://blog.seeded.network/",
                "reddit": "https://reddit.com/r/SeededNetwork",
                "twitter": "https://twitter.com/SeededNetwork",
                "website": "https://seeded.network"
            }
        },
        {
            "chainId": 101,
            "address": "DZkcS3dkDRj4KgxPKrJqAkruL8UznAgn5kFtL7bM9sP1",
            "symbol": "SH",
            "name": "SpaceHeads Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DZkcS3dkDRj4KgxPKrJqAkruL8UznAgn5kFtL7bM9sP1/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/spaceheads",
                "instagram": "https://www.instagram.com/space_heads_nft/",
                "twitter": "https://twitter.com/space_heads_nft",
                "website": "https://spaceheads.co"
            }
        },
        {
            "chainId": 101,
            "address": "AELJZ6bGcTRMzA4qHPZzqmfQ7eKDz47wNtzjDG5tqkRR",
            "symbol": "HEAD",
            "name": "Heads Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AELJZ6bGcTRMzA4qHPZzqmfQ7eKDz47wNtzjDG5tqkRR/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/spaceheads",
                "instagram": "https://www.instagram.com/space_heads_nft/",
                "twitter": "https://twitter.com/space_heads_nft",
                "website": "https://spaceheads.co"
            }
        },
        {
            "chainId": 101,
            "address": "4HecfhYXWiRhfJyjfRAufrxkRocBgwDzRmYAzCLS3VDA",
            "symbol": "HEADS",
            "name": "Space Heads Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4HecfhYXWiRhfJyjfRAufrxkRocBgwDzRmYAzCLS3VDA/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/spaceheads",
                "instagram": "https://www.instagram.com/space_heads_nft/",
                "twitter": "https://twitter.com/space_heads_nft",
                "website": "https://spaceheads.co"
            }
        },
        {
            "chainId": 101,
            "address": "3nXruwsZYPqf6A5NgoVMPTsqgN3ur2PU3DTzmNgnwiDa",
            "symbol": "ESA",
            "name": "Esculap",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3nXruwsZYPqf6A5NgoVMPTsqgN3ur2PU3DTzmNgnwiDa/logo.png",
            "tags": [
                "health",
                "eBank",
                "DLTAI",
                "predictions",
                "database"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Esculap-110660738044410",
                "instagram": "https://www.instagram.com/esculapesa/",
                "linkedin": "https://www.linkedin.com/company/esculapesa",
                "telegram": "https://t.me/esculapesa",
                "twitter": "https://twitter.com/EsculapESA",
                "website": "https://esculap.uk",
                "youtube": "https://youtube.com/channel/UCBlLNzv4H6EgL_th2sNmtWQ"
            }
        },
        {
            "chainId": 101,
            "address": "DUTLCXjguLEmzBXVQbjuyfqCD1Qa3f9TgaTAXXHK8ub3",
            "symbol": "LIBN",
            "name": "LIBANO",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUTLCXjguLEmzBXVQbjuyfqCD1Qa3f9TgaTAXXHK8ub3/logo.png",
            "tags": [
                "utility-token",
                "Startup-token"
            ],
            "extensions": {
                "telegram": "https://t.me/LibanoCoinCommunity",
                "twitter": "https://twitter.com/Libano_Official"
            }
        },
        {
            "chainId": 101,
            "address": "BFXZ7Mpw6zQGQYtQH1VUjtTrfKGc7uNjQWUize3Fdu2a",
            "symbol": "PKMN",
            "name": "PokeDollar",
            "decimals": 9,
            "logoURI": "https://pkmnsoltrainersbucket-devnet0.s3.amazonaws.com/pokeball_sq.gif",
            "tags": [
                "videogame",
                "utility-token",
                "gaming",
                "pkmn"
            ]
        },
        {
            "chainId": 101,
            "address": "EkazenTsXibMibBBTcaFkwuYdqVZrDj5tcUABR9jUNW",
            "symbol": "MFER",
            "name": "Monkey Fucker",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EkazenTsXibMibBBTcaFkwuYdqVZrDj5tcUABR9jUNW/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fTvkBYAM3M",
                "twitter": "https://twitter.com/kodamaNFTart",
                "website": "http://rational.af"
            }
        },
        {
            "chainId": 101,
            "address": "J4D6LT4YqaZBF4snQskq8sYbxjx5P9pXHyXKBcaGbq98",
            "symbol": "GREAT",
            "name": "Great Legendary",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J4D6LT4YqaZBF4snQskq8sYbxjx5P9pXHyXKBcaGbq98/logo.png",
            "tags": [
                "community-token",
                "game-token"
            ],
            "extensions": {
                "telegram": "https://t.me/greatlegendary",
                "twitter": "https://twitter.com/GreatLegendarys",
                "website": "https://greatlegendary.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2pNLzaGGYdL3u5qy5CybnP196CzuRf6crJ1VNpd4tmPu",
            "symbol": "SNFT",
            "name": "OpenSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2pNLzaGGYdL3u5qy5CybnP196CzuRf6crJ1VNpd4tmPu/logo.png",
            "tags": [
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT",
            "symbol": "CASH",
            "name": "Cashio Dollar",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT/icon.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "cashio-dollar",
                "discord": "https://discord.com/invite/GmkRRKJkuh",
                "medium": "https://medium.com/@cashioapp",
                "twitter": "https://twitter.com/CashioApp",
                "website": "https://cashio.app"
            }
        },
        {
            "chainId": 101,
            "address": "CowKesoLUaHSbAMaUxJUj7eodHHsaLsS65cy8NFyRDGP",
            "symbol": "COW",
            "name": "Cash Cow",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CowKesoLUaHSbAMaUxJUj7eodHHsaLsS65cy8NFyRDGP/icon.png",
            "tags": [
                "governance-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/GmkRRKJkuh",
                "medium": "https://medium.com/@cashioapp",
                "twitter": "https://twitter.com/CashioApp",
                "website": "https://cashio.app"
            }
        },
        {
            "chainId": 101,
            "address": "5QjajzuSfugQqqL8NSdkXdC9jDKHwf83yFHfWimhKXBa",
            "symbol": "IUS",
            "name": "IUS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5QjajzuSfugQqqL8NSdkXdC9jDKHwf83yFHfWimhKXBa/logo.png"
        },
        {
            "chainId": 101,
            "address": "AoPAKdi7u8BL8D6c64riBDzXdBbS8xCohWzmq8JZozfy",
            "symbol": "NLT",
            "name": "Neural Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AoPAKdi7u8BL8D6c64riBDzXdBbS8xCohWzmq8JZozfy/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://higherlevelfx.com"
            }
        },
        {
            "chainId": 101,
            "address": "8o2LqpeVcdpNB8qeGJcAFr4rQ4cc2wTpJPdgtsWZWdh7",
            "symbol": "LIGMA",
            "name": "whats ligma",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8o2LqpeVcdpNB8qeGJcAFr4rQ4cc2wTpJPdgtsWZWdh7/ligma_logo2.png",
            "tags": [
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://ligmadao.io"
            }
        },
        {
            "chainId": 101,
            "address": "GnzxEyULVPQYb5F5hxGc8dEGivctVrfr5mtsdp4z5xU2",
            "symbol": "DJN",
            "name": "Fenix Danjon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GnzxEyULVPQYb5F5hxGc8dEGivctVrfr5mtsdp4z5xU2/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FenixDanjon",
                "website": "https://fenixdanjon.com"
            }
        },
        {
            "chainId": 101,
            "address": "G45ASjWvWiWy6HcczTDhxe2JcSTNVr3hEdrKVo5X6PPU",
            "symbol": "LEONIDAS-USDC",
            "name": "Raydium LP Token (LEONIDAS-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G45ASjWvWiWy6HcczTDhxe2JcSTNVr3hEdrKVo5X6PPU/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "BEya3zocofJYvAN8oSsoNgxkXQN9HwL1ShU4tdHjRsqL",
            "symbol": "POOK",
            "name": "Pook",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEya3zocofJYvAN8oSsoNgxkXQN9HwL1ShU4tdHjRsqL/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "meme-token",
                "utility-token",
                "pook"
            ],
            "extensions": {
                "discord": "https://discord.gg/uxKXY5GEN3"
            }
        },
        {
            "chainId": 101,
            "address": "8H7xRwEB5x8QLWX29iLJmGtHdvn98AHh3kk4NjJx64rf",
            "symbol": "LEONIDAS-USDC",
            "name": "Cropper Finance LP Token (LEONIDAS-USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8H7xRwEB5x8QLWX29iLJmGtHdvn98AHh3kk4NjJx64rf/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "9XYbEGVjBK2BWvtvjoJBZtnoHtkkdGiw321tdN6eLa4A",
            "symbol": "SPORE",
            "name": "Flower View",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XYbEGVjBK2BWvtvjoJBZtnoHtkkdGiw321tdN6eLa4A/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6QxNGjZsLaKa35pKnxY2sa7GcUCnWSni9LPfw7KDLqYr",
            "symbol": "MOF",
            "name": "Molar Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6QxNGjZsLaKa35pKnxY2sa7GcUCnWSni9LPfw7KDLqYr/logo.png",
            "tags": [
                "utility-token",
                "aggregator",
                "DEX",
                "tool",
                "Exchange"
            ],
            "extensions": {
                "description": "We are chemist on the solana ecosystem, Dev ops at its peek. All your favorite tools in one place",
                "discord": "https://discord.gg/5euk87sWhH",
                "github": "https://github.com/Molarfinance",
                "twitter": "https://twitter.com/MolarFinance",
                "website": "https://molar.finance"
            }
        },
        {
            "chainId": 101,
            "address": "9QoLCRyjzPR6v8wkediV577GNQYmGEr96PkpUccCCLBY",
            "symbol": "SARG",
            "name": "SARG the Endangered",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9QoLCRyjzPR6v8wkediV577GNQYmGEr96PkpUccCCLBY/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CXVZgdAGahKyZaTegfJLq5TwjbJkJdSjM3xJHgnSfH26",
            "symbol": "FROGG",
            "name": "Frog Fork",
            "decimals": 6,
            "logoURI": "https://i.imgur.com/4S8wvMS.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/E69ZfD8nYV",
                "twitter": "https://twitter.com/frogsolana",
                "website": "http://frogsolana.org/"
            }
        },
        {
            "chainId": 103,
            "address": "2wShzRrTuEV4uKpVHcjEEG2yksZtaQWSbWjawKBkXvi2",
            "symbol": "fCryptoPunk-1021",
            "name": "Fractionalised CryptoPunk-1021",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/dCqJri9.png",
            "tags": [
                "bridgesplit-fraction"
            ],
            "extensions": {
                "vault": "https://devnet.bridgesplit.com/markets/2wShzRrTuEV4uKpVHcjEEG2yksZtaQWSbWjawKBkXvi2"
            }
        },
        {
            "chainId": 101,
            "address": "DVhia9KmzYCP1URf4s8DrPsE46A5PuT5TPyVRgr4c895",
            "symbol": "HEXAGON",
            "name": "Hexagon Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DVhia9KmzYCP1URf4s8DrPsE46A5PuT5TPyVRgr4c895/logo.png",
            "tags": [
                "DeFi",
                "DApp",
                "GameFi",
                "NFT"
            ],
            "extensions": {
                "serumV3Usdc": "BQBGTapjYAYa9bY46GCByW2tjQtbHHvEMwGCDYpN3kTR"
            }
        },
        {
            "chainId": 101,
            "address": "CFNstfcpNu8r8UsMKiKBg13f5iS7nytje41pS2Uc9f8q",
            "symbol": "LJM",
            "name": "Jaemyung",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CFNstfcpNu8r8UsMKiKBg13f5iS7nytje41pS2Uc9f8q/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HyxD4QcgeYW6H1acA2tyxFNQ1fTD37J4MZCMEeXCLQsK",
            "symbol": "DPAY",
            "name": "Doge Pay",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HyxD4QcgeYW6H1acA2tyxFNQ1fTD37J4MZCMEeXCLQsK/logo.png",
            "tags": [
                "utility-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4n2fdrTT2oVpYUzyHxURUwaZDKdXF2tsPLo7EKf6KU78",
            "symbol": "YSY",
            "name": "Seokyoul",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4n2fdrTT2oVpYUzyHxURUwaZDKdXF2tsPLo7EKf6KU78/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6HQscHoVdhAhABJFUU4vAgVicGzRwgARSTeob8r9brzv",
            "symbol": "SOLDN",
            "name": "Solana Diamond",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6HQscHoVdhAhABJFUU4vAgVicGzRwgARSTeob8r9brzv/logo.png",
            "tags": [
                "Community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DJ5BWLXXceGR7DqjqfNYihDWf15jPEjQD8ZhCpW7ocNT",
            "symbol": "PRDX",
            "name": "Paradox Token",
            "decimals": 9,
            "logoURI": "https://cobaltlend.com/wp-content/uploads/2021/11/581-5813108_transparent-triskelion-png-celtic-spiral-white-transparent-png.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/QtdzCR9ZQn",
                "instagram": "https://www.instagram.com/paradoxrealm13/",
                "twitter": "https://twitter.com/ParadoxRealms",
                "website": "https://paradoxrealm.com"
            }
        },
        {
            "chainId": 101,
            "address": "EQvh5nmHoD16dTTiZ7222EBSUQ563rh6o8kJ1W4Mwh7Z",
            "symbol": "APEX",
            "name": "Monster Apex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EQvh5nmHoD16dTTiZ7222EBSUQ563rh6o8kJ1W4Mwh7Z/logo.png",
            "tags": [
                "NFT-Gaming"
            ],
            "extensions": {
                "telegram": "https://t.me/monsterapex",
                "twitter": "https://twitter.com/monsterapex"
            }
        },
        {
            "chainId": 101,
            "address": "8WyEJS6QQeR7kRBCTBNptRDKeNcjM9P2F6yPQPkLB1H7",
            "symbol": "SOLP",
            "name": "Solana Play",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8WyEJS6QQeR7kRBCTBNptRDKeNcjM9P2F6yPQPkLB1H7/logo.png",
            "tags": [
                "Community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "74zkWmfyLSvu4mWEYRNEmcjrZkgPG3yhJDY1TX684VeK",
            "symbol": "WAR",
            "name": "War Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/74zkWmfyLSvu4mWEYRNEmcjrZkgPG3yhJDY1TX684VeK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WarCoin_"
            }
        },
        {
            "chainId": 103,
            "address": "HVVB2ZDw1ykyRYSwcMrDXsQ6sesGe7qyp5vB3zAuK4Hw",
            "symbol": "fBoku-420",
            "name": "Fractionalised Boku-420",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/O3h1zUq.png",
            "tags": [
                "bridgesplit-fraction"
            ],
            "extensions": {
                "vault": "https://devnet.bridgesplit.com/markets/HVVB2ZDw1ykyRYSwcMrDXsQ6sesGe7qyp5vB3zAuK4Hw"
            }
        },
        {
            "chainId": 101,
            "address": "3Ysmnbdddpxv9xK8FUKXexdhRzEA4yrCz8WaE6Za5sjV",
            "symbol": "EDX",
            "name": "edeXa Service Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Ysmnbdddpxv9xK8FUKXexdhRzEA4yrCz8WaE6Za5sjV/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://edexa.com/"
            }
        },
        {
            "chainId": 101,
            "address": "F2Rsx71Rg64a2gi5wSGxihAeLvPWt76kaNbcAjSMnULF",
            "symbol": "SOLCSH",
            "name": "Solana Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F2Rsx71Rg64a2gi5wSGxihAeLvPWt76kaNbcAjSMnULF/logo.png",
            "tags": [
                "Community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "3sJWs1uNphtHMVrqqP6foFxNTi1XLa6BZiyftq41y6kQ",
            "symbol": "SINGULARITY",
            "name": "Singularity",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3sJWs1uNphtHMVrqqP6foFxNTi1XLa6BZiyftq41y6kQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.singularityonline.app",
                "description": "Singularity Online in-game currency Token",
                "twitter": "https://twitter.com/SingularityMeta",
                "website": "https://singularityonline.app",
                "whitepaper": "https://whitepaper.singularityonline.app"
            }
        },
        {
            "chainId": 101,
            "address": "65aNqK5p43JhpFJLBiJPB1MmkW5uo2s6y5Dkj5KQdw3w",
            "symbol": "TUI",
            "name": "TradeUI",
            "decimals": 3,
            "logoURI": "https://pbs.twimg.com/profile_images/1260288995323502598/9aAFR0pr_400x400.jpg",
            "tags": [
                "social-token",
                "community-token",
                "reward-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/uSDVQRF",
                "facebook": "https://facebook.com/tradeui",
                "twitter": "https://twitter.com/tradeui",
                "website": "https://tradeui.com"
            }
        },
        {
            "chainId": 101,
            "address": "AbJ2e39BhfPpvpnrf5gupAL9W9yZW3zXxd8hsi2ZjQb",
            "symbol": "MTGVERSE",
            "name": "MetaGameVerse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AbJ2e39BhfPpvpnrf5gupAL9W9yZW3zXxd8hsi2ZjQb/logo.png",
            "tags": [
                "NFTs",
                "Gaming",
                "Ecosystem"
            ],
            "extensions": {
                "discord": "https://medium.com/@MetaGameVerse",
                "telegram": "https://t.me/MetaGameVerse",
                "twitter": "https://twitter.com/MetaGameVerse",
                "website": "https://metagameverse.info"
            }
        },
        {
            "chainId": 101,
            "address": "DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh",
            "symbol": "DFL",
            "name": "DeFi Land",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "defi-land",
                "coinmarketcap": "https://coinmarketcap.com/currencies/defi-land",
                "discord": "https://discord.com/invite/defiland",
                "medium": "https://defiland.medium.com/",
                "serumV3Usdc": "9UBuWgKN8ZYXcZWN67Spfp3Yp67DKBq1t31WLrVrPjTR",
                "telegram": "https://t.me/defiland_official",
                "twitter": "https://twitter.com/DeFi_Land",
                "website": "https://defiland.app/"
            }
        },
        {
            "chainId": 101,
            "address": "GDoR2uR265AFrY7LTi5Epp6T2wMTcVPmCo6Xmq51cw6P",
            "symbol": "PNGN",
            "name": "Penguin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GDoR2uR265AFrY7LTi5Epp6T2wMTcVPmCo6Xmq51cw6P/penguLogo.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/CQQSSSgTDb"
            }
        },
        {
            "chainId": 101,
            "address": "8vJdZKtvqd7WDtMZM2gi7WiFqyNxVGqyk71p7vzK6YUQ",
            "symbol": "YDDK",
            "name": "YDDK",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8vJdZKtvqd7WDtMZM2gi7WiFqyNxVGqyk71p7vzK6YUQ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D4skewS3sjd5ak8Noto6brxcUHQC42McJW5duWvmerHK",
            "symbol": "KW",
            "name": "Kilowatt",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4skewS3sjd5ak8Noto6brxcUHQC42McJW5duWvmerHK/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kilowattproject",
                "website": "https://kilo.solar"
            }
        },
        {
            "chainId": 101,
            "address": "4AHDENUSystAUR3VEgcUFLYAVL4BGNhLgq8uaAaKoQKq",
            "symbol": "SHRIMP",
            "name": "Shrimp Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4AHDENUSystAUR3VEgcUFLYAVL4BGNhLgq8uaAaKoQKq/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "flamingo-party",
                "shrimp-coin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Flamingos_nft",
                "website": "https://flamingoparty.io"
            }
        },
        {
            "chainId": 101,
            "address": "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW",
            "symbol": "STARS",
            "name": "StarLaunch",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW/logo.png",
            "extensions": {
                "coingeckoId": "starlaunch",
                "telegram": "https://t.me/StarLaunchOfficial",
                "twitter": "https://twitter.com/StarLaunchSOL",
                "website": "https://www.starlaunch.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GKyNQYSnQ3AoJp5HaYu9R4Qhza3NZjDKU7BedmNyFnPL",
            "symbol": "SOLMT",
            "name": "Solana Meta",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GKyNQYSnQ3AoJp5HaYu9R4Qhza3NZjDKU7BedmNyFnPL/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solanameta"
            }
        },
        {
            "chainId": 101,
            "address": "88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy",
            "symbol": "sUSDC-8",
            "name": "Saber Wrapped USD Coin (8 decimals)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy/icon.png",
            "tags": [
                "stablecoin",
                "saber-market-usd",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H",
            "symbol": "sBTC-9",
            "name": "Saber Wrapped Bitcoin (Sollet) (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H/icon.png",
            "tags": [
                "wrapped-sollet",
                "ethereum",
                "saber-market-btc",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "bitcoin",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV",
            "symbol": "sUSDT-9",
            "name": "Saber Wrapped USDT (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV/icon.png",
            "tags": [
                "stablecoin",
                "saber-market-usd",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "tether",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi",
            "symbol": "srenBTC-10",
            "name": "Saber Wrapped renBTC (10 decimals)",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi/icon.png",
            "tags": [
                "saber-market-btc",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "renbtc",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "C9xqJe3gMTUDKidZsZ6jJ7tL9zSLimDUKVpgUbLZnNbi",
            "symbol": "sCASH-9",
            "name": "Saber Wrapped CASH (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9xqJe3gMTUDKidZsZ6jJ7tL9zSLimDUKVpgUbLZnNbi/icon.png",
            "tags": [
                "stablecoin",
                "saber-market-usd",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "CLP2aB2bCXZEaoQjUNQdn64dCSzCVxKE3Kjgo3PcAYeY",
            "symbol": "PAI-CASH",
            "name": "Saber PAI-CASH LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLP2aB2bCXZEaoQjUNQdn64dCSzCVxKE3Kjgo3PcAYeY/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-cashio"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/pai_cash"
            }
        },
        {
            "chainId": 101,
            "address": "CLPLCvWFycur9CysMT3pmdkUXxPfBjXVkWyxTGntzoZ7",
            "symbol": "acUSD-CASH",
            "name": "Saber acUSD-CASH LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLPLCvWFycur9CysMT3pmdkUXxPfBjXVkWyxTGntzoZ7/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-cashio"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/acusd_cash"
            }
        },
        {
            "chainId": 101,
            "address": "CLPRkuzQFiYnXddGTTKLvqgjgh4Tm7q16sVvivWNRzo8",
            "symbol": "wUST-CASH",
            "name": "Saber wUST-CASH LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLPRkuzQFiYnXddGTTKLvqgjgh4Tm7q16sVvivWNRzo8/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-cashio"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/ust_cash"
            }
        },
        {
            "chainId": 101,
            "address": "FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5",
            "symbol": "srenBTC-9",
            "name": "Saber Wrapped renBTC (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5/icon.png",
            "tags": [
                "saber-market-btc",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "renbtc",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "FTT9GrHBVHvDeUTgLU8FxVJouGqg9uiWGmmjETdm32Sx",
            "symbol": "swFTT-9",
            "name": "Saber Wrapped FTT (Portal) (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9GrHBVHvDeUTgLU8FxVJouGqg9uiWGmmjETdm32Sx/icon.png",
            "tags": [
                "saber-market-ftt",
                "wormhole-v2",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "ftx-token",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt",
            "symbol": "sFTT-9",
            "name": "Saber Wrapped Wrapped FTT (Sollet) (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt/icon.png",
            "tags": [
                "wrapped-sollet",
                "ethereum",
                "saber-market-ftt",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "ftx-token",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1",
            "symbol": "sUSDC-9",
            "name": "Saber Wrapped USD Coin (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1/icon.png",
            "tags": [
                "stablecoin",
                "saber-market-usd",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma",
            "symbol": "swhETH-9",
            "name": "Saber Wrapped Ether (Portal) (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma/icon.png",
            "tags": [
                "saber-market-eth",
                "wormhole-v2",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "ethereum",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "KUANeD8EQvwpT1W7QZDtDqctLEh2FfSTy5pThE9CogT",
            "symbol": "srenLUNA-9",
            "name": "Saber Wrapped renLUNA (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUANeD8EQvwpT1W7QZDtDqctLEh2FfSTy5pThE9CogT/icon.png",
            "tags": [
                "saber-market-luna",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv",
            "symbol": "sBTC-8",
            "name": "Saber Wrapped Bitcoin (Sollet) (8 decimals)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv/icon.png",
            "tags": [
                "wrapped-sollet",
                "ethereum",
                "saber-market-btc",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "bitcoin",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj",
            "symbol": "sETH-8",
            "name": "Saber Wrapped Ethereum (Sollet) (8 decimals)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj/icon.png",
            "tags": [
                "wrapped-sollet",
                "ethereum",
                "saber-market-eth",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "ethereum",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "5ZkhmAq3biQ2deednocqrhs8WqbwyV9A1CxJ8PXQuGXk",
            "symbol": "ZER0",
            "name": "Zer0 Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5ZkhmAq3biQ2deednocqrhs8WqbwyV9A1CxJ8PXQuGXk/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.facebook.com/ZeroLin"
            }
        },
        {
            "chainId": 103,
            "address": "HMi8JHY3HXcSAbjYbW8Ab1Ztr47QtwNZBSZkvcbYZqhR",
            "symbol": "ECZ",
            "name": "Eczodex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMi8JHY3HXcSAbjYbW8Ab1Ztr47QtwNZBSZkvcbYZqhR/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "reddit": "https://www.reddit.com/r/Eczodex/",
                "twitter": "https://twitter.com/eczodex",
                "website": "https://eczodex.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FNrmswTa4f8WuYawZkZpVysUUny6bGRppmFJ8f2BMxun",
            "symbol": "POLKA",
            "name": "Polkasol",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNrmswTa4f8WuYawZkZpVysUUny6bGRppmFJ8f2BMxun/polkasol.png",
            "tags": [
                "Meme-token"
            ]
        },
        {
            "chainId": 103,
            "address": "41WNKuHHQXEqjiQjRm9kwupq1rcT84AWYPnki3xdQDg9",
            "symbol": "AGG",
            "name": "Aggie Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G5gqGPsrpkRYZPThJJpoVQRtgjo8zapPZ27iCSp2wPX/logo.png",
            "tags": [
                "aggie",
                "aggie-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HCQttNoEK41AG7Vk2ZBkqeD7Tpdohc4ECVebVj8nhxm1",
            "symbol": "PHR",
            "name": "PHR Block",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/bdeepakreddy/img-repo/d359d9f2d09689e9b57fc709337d6fda8b531455/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.phrblock.com"
            }
        },
        {
            "chainId": 101,
            "address": "HZgj7qvCUSiF5sPE4VDKUUF63ogKrzdnp9KXSePuCCsh",
            "symbol": "kHz",
            "name": "KOLONIE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZgj7qvCUSiF5sPE4VDKUUF63ogKrzdnp9KXSePuCCsh/logo.svg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8NAcUx21PWMS2MjXWfnWgBWfy1r1yfL4AeESgdteGaut",
            "symbol": "TBC",
            "name": "Trader Best Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NAcUx21PWMS2MjXWfnWgBWfy1r1yfL4AeESgdteGaut/logo.svg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Imwarmsmile"
            }
        },
        {
            "chainId": 101,
            "address": "72VTRtQ6dBfsRzqSNPN5Ws9Bk6GEZCcMWm94oWiiYRiv",
            "symbol": "EXGF",
            "name": "Doge Ex-Girlfriend",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72VTRtQ6dBfsRzqSNPN5Ws9Bk6GEZCcMWm94oWiiYRiv/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://www.dogeexgf.net"
            }
        },
        {
            "chainId": 101,
            "address": "2vRgBSJEVPXxayrhXoazQyCKSGFYQG3ZdfT2Gv5gZykL",
            "symbol": "SHROOMZ",
            "name": "Crypto Mushroomz",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vRgBSJEVPXxayrhXoazQyCKSGFYQG3ZdfT2Gv5gZykL/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "coingeckoId": "crypto-mushroomz",
                "coinmarketcap": "https://coinmarketcap.com/currencies/crypto-mushroomz/",
                "serumV3Usdc": "E9XAtU18PXeSMcz5gkAkZ6yfj1E5nzY21x576ZvEg9VA",
                "twitter": "https://twitter.com/cryptomushroomz",
                "website": "https://www.cryptomushroomz.io"
            }
        },
        {
            "chainId": 101,
            "address": "6uZ7MRGGf3FJhzk9TUk3QRMR2fz83WY9BEVBukRvMRVX",
            "symbol": "NANA",
            "name": "NANA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uZ7MRGGf3FJhzk9TUk3QRMR2fz83WY9BEVBukRvMRVX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "chimp-fight",
                "discord": "https://discord.gg/zJ9wQYzBsB",
                "medium": "https://medium.com/@chimpfight",
                "twitter": "https://twitter.com/chimpfightgame",
                "website": "https://chimpfight.io"
            }
        },
        {
            "chainId": 101,
            "address": "Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw",
            "symbol": "BURD",
            "name": "tudaBirds Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "tudabirds metaNest",
                "discord": "https://discord.gg/tudabirds",
                "facebook": "https://business.facebook.com/tudabirds",
                "instagram": "https://www.instagram.com/tudabirds",
                "medium": "https://tudabirds.medium.com",
                "telegram": "https://t.me/tudabirdsgroup",
                "website": "https://tudabirds.io"
            }
        },
        {
            "chainId": 101,
            "address": "6naWDMGNWwqffJnnXFLBCLaYu1y5U9Rohe5wwJPHvf1p",
            "symbol": "SCRAP",
            "name": "SCRAP",
            "decimals": 3,
            "logoURI": "https://art.pixilart.com/bd1b1275fdc0ac1.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/taiyorobotics",
                "website": "https://taiyorobotics.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9YAnyZ2cMUbQSwWAE76fohF58o4DHwaZFE9vgH5dVAJL",
            "symbol": "MILES",
            "name": "Degen Air Miles",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9YAnyZ2cMUbQSwWAE76fohF58o4DHwaZFE9vgH5dVAJL/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/degenair",
                "twitter": "https://twitter.com/DegenAirNFT",
                "website": "https://degenair.io"
            }
        },
        {
            "chainId": 101,
            "address": "pBNFtb4Dvox5jtr3aYmFaWyUBNnseDGNiyeQdsgGr19",
            "symbol": "KEY",
            "name": "Mysteria Game Key",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pBNFtb4Dvox5jtr3aYmFaWyUBNnseDGNiyeQdsgGr19/logo.png",
            "tags": [
                "game",
                "ticket",
                "fungible",
                "key",
                "puzzle"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/VRWxVCYrBb",
                "twitter": "https://twitter.com/play_mysteria",
                "website": "https://playmysteria.com"
            }
        },
        {
            "chainId": 101,
            "address": "939KAUTAyNdmpahj1vQmbS67D7auhyJnkMt4sv2tzBwU",
            "symbol": "SHIMOO",
            "name": "Shibi Samo Official",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/939KAUTAyNdmpahj1vQmbS67D7auhyJnkMt4sv2tzBwU/logo.png",
            "tags": [
                "meme",
                "community",
                "NFTs",
                "DEFI",
                "Dog"
            ],
            "extensions": {
                "discord": "https://discord.gg/GrPTmFqZ6m",
                "twitter": "https://twitter.com/ShibiSamo",
                "website": "https://shibi-samo.com"
            }
        },
        {
            "chainId": 101,
            "address": "GFABRXExXpA9SzXc8GkURg8kEqPoxKPTB9tp57miaLsK",
            "symbol": "NMS",
            "name": "New Myanmar Shekel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFABRXExXpA9SzXc8GkURg8kEqPoxKPTB9tp57miaLsK/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "website": "https://bitmmr.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8Sro4c3KZzTwMqXzdo3XwZUdDG8fuhuhX3uDUce5kxXX",
            "symbol": "Dara",
            "name": "Dara token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Sro4c3KZzTwMqXzdo3XwZUdDG8fuhuhX3uDUce5kxXX/dara.png",
            "tags": [
                "community",
                "NFTs",
                "Defi"
            ]
        },
        {
            "chainId": 101,
            "address": "EYXHfsSMwEfvdf4S1VjSCe7hphAuwnMJx6RvzxzMMeHr",
            "symbol": "BORN",
            "name": "Reborn",
            "decimals": 8,
            "logoURI": "https://github.com/Benjamunja/Unique/blob/571e466fda0a4ef6ef3463e96a9779ab556c6cab/Unique.jpg",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8nBNn2TcXnBFzc8TjoafWxLWw2BYHyNebZQ1w9PrWUs2",
            "symbol": "BLOCKS",
            "name": "BLOCKS",
            "decimals": 3,
            "logoURI": "https://i.imgur.com/Hex1oqB.png",
            "tags": [
                "community",
                "NFTs"
            ]
        },
        {
            "chainId": 101,
            "address": "Bqakvb2XEN7eqXG5eUumg86yCxb3gstNNGUHwhSa3bnS",
            "symbol": "SolS",
            "name": "S Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/soldogs/sols@main/Logo.png",
            "tags": [
                "meme-token",
                "S",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Sol_S_Official",
                "website": "https://www.solstoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5CAmbZa5RFdRpGCiDh6ADzVetupyNjnyMMeBfQgyPCzT",
            "symbol": "LCD-USDT",
            "name": "LCD-USDT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5CAmbZa5RFdRpGCiDh6ADzVetupyNjnyMMeBfQgyPCzT/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "7R78EGQjThEVchSUGuzs3KgptMg37FxzgKpaLcGYp7pi",
            "symbol": "LCD-USDC",
            "name": "LCD-USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7R78EGQjThEVchSUGuzs3KgptMg37FxzgKpaLcGYp7pi/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "B7dLZ8LVV7bR5xJEpRzmkYk7sx7pBkiqkf58ZHpPiNpw",
            "symbol": "LCD-LINK",
            "name": "LCD-LINK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B7dLZ8LVV7bR5xJEpRzmkYk7sx7pBkiqkf58ZHpPiNpw/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "CXbhgErBXhKiTJu95dK1aaoHukrg1Ghn8N2Spda7wSV",
            "symbol": "LCD-SAMO",
            "name": "LCD-SAMO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXbhgErBXhKiTJu95dK1aaoHukrg1Ghn8N2Spda7wSV/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "nvW2Fqyei7s1DhWqjiQU81eUxVE7iY4zKnicbL3yr61",
            "symbol": "RISK-SOL",
            "name": "RISK-SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nvW2Fqyei7s1DhWqjiQU81eUxVE7iY4zKnicbL3yr61/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "9xCabyenVjP7H2uQbMkLvvAdrEmQhpZDjDETuunjMqs",
            "symbol": "RISK-USDT",
            "name": "RISK-USDT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xCabyenVjP7H2uQbMkLvvAdrEmQhpZDjDETuunjMqs/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "GWDCXrc79q1xMroKisZmpSk5PXpgivD88pCg4mS63Ghf",
            "symbol": "RISK-USDC",
            "name": "RISK-USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWDCXrc79q1xMroKisZmpSk5PXpgivD88pCg4mS63Ghf/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "RuWFALHvbEY224W5ktzSVm4RP1FcV1wykJkfmuCQXkt",
            "symbol": "RISK-LINK",
            "name": "RISK-LINK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RuWFALHvbEY224W5ktzSVm4RP1FcV1wykJkfmuCQXkt/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "J7SNiV2ah5z9g1rGA9oWgET4XsbwuSCWjNNDBdboAmPp",
            "symbol": "RISK-SAMO",
            "name": "RISK-SAMO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J7SNiV2ah5z9g1rGA9oWgET4XsbwuSCWjNNDBdboAmPp/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/crazydiceapp",
                "twitter": "https://twitter.com/CrazyDiceApp",
                "website": "https://www.crazydice.app"
            }
        },
        {
            "chainId": 101,
            "address": "41TwwURtuv4k8TuFxp1vfFYP9noMbHXqtscse8xLM26V",
            "symbol": "LINU",
            "name": "LittleInu Token",
            "decimals": 9,
            "logoURI": "https://cdn.discordapp.com/attachments/904210282719621120/912640098913361930/unknown.png",
            "tags": [
                "meme-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/tazMH4qfd3",
                "twitter": "https://twitter.com/littleinusol"
            }
        },
        {
            "chainId": 101,
            "address": "8Wmonq7dhFJXuHqFCcVgWTmmUPCBC4C6J5xbB5HhGb6n",
            "symbol": "42",
            "name": "42",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/fortytwotoken/42token/forty-two.png",
            "tags": [
                "forty-two",
                "the-answer"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solana_42"
            }
        },
        {
            "chainId": 101,
            "address": "WETk2zhvTruGmVe1RFNHYt7netgpPWsGrezgB22vD3U",
            "symbol": "WETK",
            "name": "We-Tokenize",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WETk2zhvTruGmVe1RFNHYt7netgpPWsGrezgB22vD3U/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://we-tokenize.com"
            }
        },
        {
            "chainId": 103,
            "address": "91enn7UUM3rXqqMAmYgYRNuVQBRgTumTvV7kMCVyz5g",
            "symbol": "XYZ2",
            "name": "XYZ2 Test",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/91enn7UUM3rXqqMAmYgYRNuVQBRgTumTvV7kMCVyz5g/logo.png"
        },
        {
            "chainId": 101,
            "address": "ApvVgC5vc3yxrDJw933UZX7zB7CAy6JCeeZdqEjdsRsV",
            "symbol": "8PI",
            "name": "8teapi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApvVgC5vc3yxrDJw933UZX7zB7CAy6JCeeZdqEjdsRsV/logo.svg",
            "tags": [
                "personal"
            ],
            "extensions": {
                "twitter": "https://twitter.com/8teAPi",
                "website": "https://www.8teapi.com"
            }
        },
        {
            "chainId": 101,
            "address": "8koDKfrrjN5Yiu8JHybCDcSk5vHECesfw7a8kUQ9iqi4",
            "symbol": "QUEST-USDT",
            "name": "Raydium LP Token V4 (QUEST-USDT)",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EDudY1AYk9t72DtzhagzM9y6G8HBN7aY6eVu3XWG4WT9/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "7z3r8Q4Uh2MiHRS2ABQVms3ficvki5hz9YKXAcmeyEQx",
            "symbol": "BKC",
            "name": "Blakecoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7z3r8Q4Uh2MiHRS2ABQVms3ficvki5hz9YKXAcmeyEQx/BlakeToken.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://blakeim.com"
            }
        },
        {
            "chainId": 101,
            "address": "GBJtgKjLSFgaxPaD56eWSHdNZ1o3t5T4UZUi4QRTXebi",
            "symbol": "Hacklab",
            "name": "HACKLAB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HacklabAzerbaijan/hacklab_token/main/logo.jpg",
            "tags": [
                "DAO",
                "community-token",
                "governance"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/hacklabaz",
                "github": "https://github.com/HacklabAzerbaijan",
                "instagram": "https://www.instagram.com/hacklabaz/",
                "linkedin": "https://www.linkedin.com/company/hacklabaz/about/",
                "website": "https://hacklab.az/"
            }
        },
        {
            "chainId": 101,
            "address": "EN8gTAjVJtQJnGTXGc61iJKeE2uZ2pG1bh4i2syDCydF",
            "symbol": "CVS",
            "name": "Cannaverse",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EN8gTAjVJtQJnGTXGc61iJKeE2uZ2pG1bh4i2syDCydF/cannaverse.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/joinchat/tgIDibSI9Cg0NjM8"
            }
        },
        {
            "chainId": 101,
            "address": "GE6woJHj29zssJRKDgFZZevJdQvjCyXqACZkvbdXaLZ",
            "symbol": "fKZEN",
            "name": "Kaizen.Finance Locked",
            "decimals": 9,
            "logoURI": "https://cdn.kaizen.finance/assets/images/symbols/kzen-locked.png",
            "tags": [
                "kaizen",
                "kaizen-finance",
                "locked-token",
                "utility-token",
                "vesting-token"
            ],
            "extensions": {
                "description": "Locked KZEN token. First ever Token Lifecycle Management Platform.",
                "medium": "https://kaizenfinance.medium.com",
                "telegram": "https://t.me/KaizenFinance",
                "twitter": "https://twitter.com/kaizen_finance",
                "website": "https://kaizen.finance"
            }
        },
        {
            "chainId": 101,
            "address": "DcqWM1BdgfUFktSKw8XC6qLAo2Ki2dUFXc1YYe67c8kD",
            "symbol": "DRUGS",
            "name": "Cartel DRUGS",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcqWM1BdgfUFktSKw8XC6qLAo2Ki2dUFXc1YYe67c8kD/logo.png",
            "tags": [
                "meme-token",
                "utility-token",
                "stake-pool-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cartelofsolana",
                "serumV3Usdc": "6FSr3WhrLCdetbfr75JizgHsc3EDxmUxdKq6gTQwbefn",
                "twitter": "https://twitter.com/solcartel",
                "website": "https://www.solcartel.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ZVyeVWSFcinmH9XVHFjzZ9xtuqs4dFFboUPtAMZVyg7",
            "symbol": "SDG",
            "name": "samodoge",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/Samodoge/samodogelogo@master/samodoge.png",
            "tags": [
                "community-token",
                "meme-coin",
                "meme-token"
            ],
            "extensions": {
                "discord": "http://discord.link/SAMODOGE",
                "twitter": "https://twitter.com/samodoge",
                "website": "http://samodoge.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BXHUUQvD8YaEv4eModMzK9ds1f5g6Zx3iUY7MgTANjGf",
            "symbol": "Testtokentb",
            "name": "Testtokentb",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXHUUQvD8YaEv4eModMzK9ds1f5g6Zx3iUY7MgTANjGf/logo.png"
        },
        {
            "chainId": 101,
            "address": "FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj",
            "symbol": "TTT",
            "name": "TabTrader Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "tabtrader",
                "description": "TabTrader allows users to trade anywhere and anytime from their mobile device with all the main crypto exchanges accessible through a unified interface.",
                "discord": "https://discord.gg/pSMjEh7paU",
                "medium": "https://medium.com/@tabtraderbtc",
                "telegram": "https://t.me/tabtrader_token_en",
                "twitter": "https://twitter.com/tabtraderpro",
                "website": "https://tab-trader.com/",
                "youtube": "https://www.youtube.com/c/TabTrader"
            }
        },
        {
            "chainId": 101,
            "address": "Hb8uzkDbZh6hRe9LpeXYYhUxPEwwJc8LojjSPNKQWywq",
            "symbol": "NRD",
            "name": "Nirad",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hb8uzkDbZh6hRe9LpeXYYhUxPEwwJc8LojjSPNKQWywq/logo.png",
            "tags": [
                "currency",
                "social-token",
                "community-token",
                "trading-token",
                "reward-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://datchat.com/app1/"
            }
        },
        {
            "chainId": 101,
            "address": "wndrmt7dnQVapGC8Mff6jxN2qr54jJb1cdKsVDKH4oP",
            "symbol": "$WONDER",
            "name": "Wonder",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/wndrmt7dnQVapGC8Mff6jxN2qr54jJb1cdKsVDKH4oP/logo.svg"
        },
        {
            "chainId": 101,
            "address": "3jHi4W7nPecSBvXLXt7NzBiRCcfX9MbTct5JCeMAYdE6",
            "symbol": "PUG",
            "name": "Cryptic Pug",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3jHi4W7nPecSBvXLXt7NzBiRCcfX9MbTct5JCeMAYdE6/logo.png"
        },
        {
            "chainId": 101,
            "address": "AWukJTnvj1rUi9LmxezP7vseCVnESnPrYAsSipxuhUgh",
            "symbol": "NCOINS",
            "name": "NCoin",
            "decimals": 9,
            "logoURI": "http://content.ncoinchain.com/ncoinlogo.png",
            "tags": [
                "multi-chain",
                "payment-token"
            ],
            "extensions": {
                "website": "https://ncoinchain.com"
            }
        },
        {
            "chainId": 101,
            "address": "E77cpQ4VncGmcAXX16LHFFzNBEBb2U7Ar7LBmZNfCgwL",
            "symbol": "abUSDT",
            "name": "Wrapped USDT (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69",
            "symbol": "abUSDC",
            "name": "Wrapped USDC (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "3os2M3bX9qta154PRbU9rzaPUYAKAqVpaMMS8u2hoUQu",
            "symbol": "abBTCB",
            "name": "Wrapped BTC (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png",
            "extensions": {
                "coingeckoId": "wrapped-bitcoin"
            }
        },
        {
            "chainId": 101,
            "address": "EyrnrbE5ujd3HQG5PZd9MbECN9yaQrqc8pRwGtaLoyC",
            "symbol": "abETH",
            "name": "Wrapped ETH (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png",
            "extensions": {
                "coingeckoId": "weth"
            }
        },
        {
            "chainId": 101,
            "address": "GD8B6KhufThbCGmNodrTE5feVxU2kK5TRzwxqjahv12G",
            "symbol": "LQN",
            "name": "LIQNFT.com",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GD8B6KhufThbCGmNodrTE5feVxU2kK5TRzwxqjahv12G/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://liqnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3DHPqxdMXogNNnpqBMF8N4Zs4dn1WR31H7UjWq6FExwG",
            "symbol": "DAWG",
            "name": "DAWG",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/15795037/143055147-72513c02-be9b-4639-bb30-3c1c037cb030.png",
            "tags": [
                "doge-capital",
                "meme-token"
            ],
            "extensions": {
                "coingeckoId": "dawg",
                "discord": "https://discord.com/invite/DeM5VFF29v",
                "twitter": "https://twitter.com/thedogecapital",
                "website": "https://www.thedogecapital.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BaNtgHgwjNWPsepLe89KBQS9NbPoDCeAdLPGaYtreJoS",
            "symbol": "GOAT",
            "name": "Greatest Of All Time Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BaNtgHgwjNWPsepLe89KBQS9NbPoDCeAdLPGaYtreJoS/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://twitter.com/The_Goat_Token"
            }
        },
        {
            "chainId": 101,
            "address": "B2EMhAUhckRevpPDt11o7Gmjg59SSTqKQPsfus66k1hQ",
            "symbol": "BAZ",
            "name": "BAZ",
            "decimals": 0,
            "logoURI": "https://www.arthurr.co.uk/baz/logo.png",
            "extensions": {
                "website": "https://www.arthurr.co.uk/baz"
            }
        },
        {
            "chainId": 101,
            "address": "RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a",
            "symbol": "RLB",
            "name": "Rollbit Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "stake-pool-token",
                "lottery"
            ],
            "extensions": {
                "description": "Stake your RLB on rollbit.com to participate in a profit share distribution every 100 Bitcoin blocks",
                "discord": "https://discord.gg/Mwx3zqH",
                "facebook": "https://facebook.com/Rollbit",
                "instagram": "https://instagram.com/rollbitcom",
                "serumV3Usdc": "DFdcGFcAVWZ3UgVgpbBChFKen3URZdZ8dmju8GTXQgCE",
                "twitter": "https://twitter.com/rollbitcom",
                "website": "https://www.rollbit.com"
            }
        },
        {
            "chainId": 101,
            "address": "2gb84xxkfRdK6XJTuDePXVECkAXsFk1rLGZDuCH937K4",
            "symbol": "FAUS",
            "name": "Faust Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2gb84xxkfRdK6XJTuDePXVECkAXsFk1rLGZDuCH937K4/logo.png"
        },
        {
            "chainId": 101,
            "address": "8b2XtrXTQ3Qt3iYFhvpDKHLGXi3pZaobRsUyktD6gsJc",
            "symbol": "BOGEL",
            "name": "Bogel Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8b2XtrXTQ3Qt3iYFhvpDKHLGXi3pZaobRsUyktD6gsJc/logo.png"
        },
        {
            "chainId": 101,
            "address": "BjgmaM6xoRXv3tzAbgz1xiX3kVG2aqsUsV4PPhACXMCR",
            "symbol": "MEMEGO",
            "name": "Flamemego",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjgmaM6xoRXv3tzAbgz1xiX3kVG2aqsUsV4PPhACXMCR/logo.png",
            "tags": [
                "MEME-TOKEN",
                "NFTs",
                "DEX",
                "marketplace"
            ],
            "extensions": {
                "discord": "https://discord.gg/k7TJMX9G6D",
                "medium": "https://Flamemego.medium.com",
                "twitter": "https://twitter.com/Flamemego",
                "website": "https://flamemego.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SparkXuAqXDH1zhJGdkCRLtg6xHqJkNzRY1PCnLrV9v",
            "symbol": "SPARK",
            "name": "Spark",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/codeholic/token-list/main/assets/mainnet/SparkXuAqXDH1zhJGdkCRLtg6xHqJkNzRY1PCnLrV9v.svg",
            "extensions": {
                "website": "https://brazier.app/"
            }
        },
        {
            "chainId": 101,
            "address": "5MoDJmq3JqsNnottRdot3WHmhmD6h8VRodqsBKVu9LPk",
            "symbol": "APEC",
            "name": "APEC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5MoDJmq3JqsNnottRdot3WHmhmD6h8VRodqsBKVu9LPk/logo.png",
            "tags": [
                "DeFi",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/apeccoin",
                "website": "https://apecoin.money"
            }
        },
        {
            "chainId": 101,
            "address": "ALPX6x8FkkdQyn9YuoVZjPAapL4nUC7vjJ3AtwStmj9P",
            "symbol": "abUSDC-USDC",
            "name": "Saber abUSDC-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPX6x8FkkdQyn9YuoVZjPAapL4nUC7vjJ3AtwStmj9P/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/abusdc"
            }
        },
        {
            "chainId": 101,
            "address": "ALPaPPo6xmeGv3a63Pc4S8NJFAAuchhD7XnkkWJqzvXJ",
            "symbol": "abUSDT-USDT",
            "name": "Saber abUSDT-USDT LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPaPPo6xmeGv3a63Pc4S8NJFAAuchhD7XnkkWJqzvXJ/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/abusdt"
            }
        },
        {
            "chainId": 101,
            "address": "ALPb4SssuKFScyUFnTcXLtF3NAkwFuFpQFric5yo4Qpo",
            "symbol": "abBTCB-renBTC",
            "name": "Saber abBTCB-renBTC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPb4SssuKFScyUFnTcXLtF3NAkwFuFpQFric5yo4Qpo/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/abbtcb"
            }
        },
        {
            "chainId": 101,
            "address": "ALPi51sXwH9kNcQuneDwz7kPzJs3hJ5xV6SmnuyqodQP",
            "symbol": "abETH-weWETH",
            "name": "Saber abETH-weWETH LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPi51sXwH9kNcQuneDwz7kPzJs3hJ5xV6SmnuyqodQP/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/abeth"
            }
        },
        {
            "chainId": 101,
            "address": "WLPAEUgB95YrU7Vk1FVPeSP5C3e66bf63frHRgsyxHv",
            "symbol": "wbUSDT-USDT",
            "name": "Saber wbUSDT-USDT LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPAEUgB95YrU7Vk1FVPeSP5C3e66bf63frHRgsyxHv/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wbusdt"
            }
        },
        {
            "chainId": 101,
            "address": "WLPR8Sy6Fbnf2sZHVg8Z7uW1QnRThnjhb244kf4ddCS",
            "symbol": "wbBUSD-USDC",
            "name": "Saber wbBUSD-USDC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPR8Sy6Fbnf2sZHVg8Z7uW1QnRThnjhb244kf4ddCS/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wbusd"
            }
        },
        {
            "chainId": 101,
            "address": "WLPsu8oyn1muh5nimxvBsgo6hh6t5jAdUtZ1VmJkohs",
            "symbol": "wbUSDC-USDC",
            "name": "Saber wbUSDC-USDC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPsu8oyn1muh5nimxvBsgo6hh6t5jAdUtZ1VmJkohs/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wbusdc"
            }
        },
        {
            "chainId": 101,
            "address": "Hq9MuLDvUAWqC29JhqP2CUJP9879LfqNBHyRRREEXwtZ",
            "symbol": "FLOCK",
            "name": "Flock",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hq9MuLDvUAWqC29JhqP2CUJP9879LfqNBHyRRREEXwtZ/logo.png",
            "tags": [
                "utlity-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DapperDucks",
                "twitter": "https://twitter.com/Dapper_DucksNFT"
            }
        },
        {
            "chainId": 101,
            "address": "2xjPDhujjDAM51aEXkG4tCGyQMxvfgMwAhVUcP3bBESH",
            "symbol": "SLIK",
            "name": "SLIK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2xjPDhujjDAM51aEXkG4tCGyQMxvfgMwAhVUcP3bBESH/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/sliksafe",
                "twitter": "https://twitter.com/sliksafe",
                "website": "https://sliksafe.com"
            }
        },
        {
            "chainId": 101,
            "address": "2S3Ay27AY2bZLDfMhb8AGWT4Q1m8mu4PSKsUi77WFcQT",
            "symbol": "TUNG",
            "name": "Tungy Coin",
            "decimals": 14,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2S3Ay27AY2bZLDfMhb8AGWT4Q1m8mu4PSKsUi77WFcQT/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TungyCube",
                "website": "https://tungycoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "ELVRweR9SQECBiwXAJLQsFkXW9ME4hKwDD2nRg6QmoBV",
            "symbol": "fair3d.me",
            "name": "Rewarding Contributions to f3d",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/staccDOTsol/token-list/main/assets/mainnet/ELVRweR9SQECBiwXAJLQsFkXW9ME4hKwDD2nRg6QmoBV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mVZVuTbh4T",
                "twitter": "https://twitter.com/STACCart",
                "website": "https://fair3d.me"
            }
        },
        {
            "chainId": 101,
            "address": "EKSM2sjtptnvkqq79kwfAaSfVudNAtFYZSBdPe5jeRSt",
            "symbol": "SOLCH",
            "name": "SolanaCash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKSM2sjtptnvkqq79kwfAaSfVudNAtFYZSBdPe5jeRSt/logo.png",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanacash_io",
                "website": "https://solanacash.io"
            }
        },
        {
            "chainId": 101,
            "address": "3jasj72YfitGmo3jtHvmCQXFNqAoJoGxLiVnqaDeZdXf",
            "symbol": "AIEA",
            "name": "AI Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3jasj72YfitGmo3jtHvmCQXFNqAoJoGxLiVnqaDeZdXf/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://ai-token.io"
            }
        },
        {
            "chainId": 101,
            "address": "4RGQbaSi5YGySUmoiMZB6mFrmr7XzvokUF7oxneiMnDs",
            "symbol": "CML",
            "name": "CML IT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4RGQbaSi5YGySUmoiMZB6mFrmr7XzvokUF7oxneiMnDs/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B98YTvnDdVQ9NKT3pdbFwJn2TMTDitz1xzfxgA1zXCDC",
            "symbol": "wCOS_v1",
            "name": "COS Contentos (Wormhole v1)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B98YTvnDdVQ9NKT3pdbFwJn2TMTDitz1xzfxgA1zXCDC/logo.svg",
            "tags": [
                "wrapped",
                "wormhole",
                "utility-token",
                "ethereum"
            ],
            "extensions": {
                "address": "0x589891a198195061cb8ad1a75357a3b7dbadd7bc",
                "assetContract": "https://etherscan.io/address/0x589891a198195061cb8ad1a75357a3b7dbadd7bc",
                "bridgeContract": "https://etherscan.io/address/0xf92cD566Ea4864356C5491c177A430C222d7e678",
                "coingeckoId": "contentos",
                "medium": "https://medium.com/contentos-io",
                "twitter": "https://twitter.com/contentosio",
                "website": "https://www.contentos.io/"
            }
        },
        {
            "chainId": 101,
            "address": "yUSD1iVx5cgmRREB81pJW8byQTaY3HwsPzeMLCm26Ne",
            "symbol": "yUSDC",
            "name": "yUSDC (Parrot Stability Pool Token)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yUSD1iVx5cgmRREB81pJW8byQTaY3HwsPzeMLCm26Ne/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/gopartyparrot",
                "medium": "https://gopartyparrot.medium.com/",
                "telegram": "https://t.me/gopartyparrot",
                "twitter": "https://twitter.com/gopartyparrot",
                "website": "https://parrot.fi"
            }
        },
        {
            "chainId": 101,
            "address": "G1B6TwvfotwX4eVY7pcP6SDyZRGiTPwnKAL3bexi72WN",
            "symbol": "xKZEN",
            "name": "KZEN Locked",
            "decimals": 9,
            "logoURI": "https://cdn.kaizen.finance/assets/images/symbols/kzen-locked.png",
            "tags": [
                "kaizen",
                "kaizen-finance",
                "locked-token",
                "utility-token",
                "vesting-token"
            ],
            "extensions": {
                "description": "Locked KZEN token. First ever Token Lifecycle Management Platform.",
                "medium": "https://kaizenfinance.medium.com",
                "telegram": "https://t.me/KaizenFinance",
                "twitter": "https://twitter.com/kaizen_finance",
                "website": "https://kaizen.finance"
            }
        },
        {
            "chainId": 101,
            "address": "8NhthBCeEreii3he6J18tuaQk5MkopKNJt53ou4PDZpM",
            "symbol": "FRENS",
            "name": "Where is Nunu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NhthBCeEreii3he6J18tuaQk5MkopKNJt53ou4PDZpM/logo.png",
            "tags": [
                "utility-token",
                "NFTs",
                "Where-is-Nunu",
                "Infamous-Thugbirdz"
            ],
            "extensions": {
                "discord": "https://discord.gg/Hewj6Beatf",
                "website": "https://www.where-is-nunu.com"
            }
        },
        {
            "chainId": 101,
            "address": "FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58",
            "symbol": "pUSDC",
            "name": "Port Finance USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "8ezDtNNhX91t1NbSLe8xV2PcCEfoQjEm2qDVGjt3rjhg",
            "symbol": "pSOL",
            "name": "Port Finance SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ezDtNNhX91t1NbSLe8xV2PcCEfoQjEm2qDVGjt3rjhg/SOL.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "3RudPTAkfcq9Q9Jk8SVeCoecCBmdKMj6q5smsWzxqtqZ",
            "symbol": "pUSDT",
            "name": "Port Finance USDT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3RudPTAkfcq9Q9Jk8SVeCoecCBmdKMj6q5smsWzxqtqZ/USDT.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "Dt1Cuau5m5CSmun8hZstjEh9RszxAmejnq7ZaHNcuXfA",
            "symbol": "pmSOL",
            "name": "Port Finance mSOL",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dt1Cuau5m5CSmun8hZstjEh9RszxAmejnq7ZaHNcuXfA/mSOL.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "CiL3DpK53BRppcmxtMSDzfaNQmyPzPa9EZZhmS1LUkoM",
            "symbol": "START",
            "name": "Startupism",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/cdelalama/startupism/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Startupism is a community token for startup founders. It gamifies the entrepreneur's hero journey.",
                "twitter": "https://twitter.com/startupism_ai",
                "website": "https://www.startupism.ai"
            }
        },
        {
            "chainId": 101,
            "address": "HBiYxV9W4SAX4YS6JHHozGDDM62CdcMakiToed5rSZR",
            "symbol": "SHTM",
            "name": "SHIT MOON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBiYxV9W4SAX4YS6JHHozGDDM62CdcMakiToed5rSZR/logo.png"
        },
        {
            "chainId": 101,
            "address": "FiER3ViRr6xU8DNFJK9of2sioUEjg6xMhtJrA7dAmfEP",
            "symbol": "SRPD",
            "name": "Surprised",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FiER3ViRr6xU8DNFJK9of2sioUEjg6xMhtJrA7dAmfEP/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3St1AJT8p8GP3FKa8UjNekkzauesEuE2rmzqVyLcC3Wn",
            "symbol": "MB",
            "name": "Amoeba",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3St1AJT8p8GP3FKa8UjNekkzauesEuE2rmzqVyLcC3Wn/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AmoebaEcosystem"
            }
        },
        {
            "chainId": 101,
            "address": "EeFrrySDy3mp8vHyqMFrFyHREfqcdrXPzkAyVjpwbfMk",
            "symbol": "AMA",
            "name": "AMA token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EeFrrySDy3mp8vHyqMFrFyHREfqcdrXPzkAyVjpwbfMk/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/financeQ8"
            }
        },
        {
            "chainId": 101,
            "address": "YY85DMGbftvE4eKQauey4yAGWvUJ9LxbfFRQSnogF53",
            "symbol": "DTOK",
            "name": "Developer Utility Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YY85DMGbftvE4eKQauey4yAGWvUJ9LxbfFRQSnogF53/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Developer utility token is a community reward token for developers of cryptourios.io.",
                "website": "https://cryptourios.io/tokens/dtok"
            }
        },
        {
            "chainId": 101,
            "address": "3DTgQ3zv1oQ91NYYSAzdbM1HHxMwNmYQYy5CfE8pfnu9",
            "symbol": "CITE",
            "name": "Citation",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/citation-coin/citation-coin/main/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/citation_crypto",
                "website": "https://github.com/citation-coin/citation-coin"
            }
        },
        {
            "chainId": 101,
            "address": "EfYk923A6knsoSKRpi7uGozaevzAp4KcXe7C9qXxBbuv",
            "symbol": "MOGO",
            "name": "MONKE GOD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EfYk923A6knsoSKRpi7uGozaevzAp4KcXe7C9qXxBbuv/logo.png",
            "tags": [
                "meme-token",
                "monkey",
                "monkeygod",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/monkegodcoin",
                "website": "https://monkegodcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "kZEn3aDxEzcFADPe2VQ6WcJRbS1hVGjUcgCw4HiuYSU",
            "symbol": "KZEN",
            "name": "Kaizen Finance",
            "decimals": 9,
            "logoURI": "https://kaizen.finance/assets/images/kaizen-assets/logo-kaizen-symbol.png",
            "tags": [
                "kaizen",
                "kaizen-finance",
                "locked-token",
                "vesting-token",
                "bridge",
                "DEX"
            ],
            "extensions": {
                "description": "Kaizen is the first-ever cross-chain token lifecycle management platform built with the needs of both projects and investors alike.",
                "medium": "https://kaizenfinance.medium.com",
                "telegram": "https://t.me/KaizenFinance",
                "twitter": "https://twitter.com/kaizen_finance",
                "website": "https://kaizen.finance"
            }
        },
        {
            "chainId": 101,
            "address": "FADvkbzT3JY1gFqQRoaMyipfy6GvpVhbVfn3q3om7ZVG",
            "symbol": "QTC",
            "name": "Quantum",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FADvkbzT3JY1gFqQRoaMyipfy6GvpVhbVfn3q3om7ZVG/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/quantumQTC",
                "website": "http://www.quantum-coin.co"
            }
        },
        {
            "chainId": 101,
            "address": "JAzfmYG4WMDs8yeSLAR1rHrhS3QgknpQeMno9omsqeeo",
            "symbol": "MNR",
            "name": "Moon Raccoons token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAzfmYG4WMDs8yeSLAR1rHrhS3QgknpQeMno9omsqeeo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Major currency inside of Moon Raccoons Metaverse",
                "discord": "https://discord.gg/66FX52eZrk",
                "twitter": "https://twitter.com/moonraccoonsnft",
                "website": "https://moonraccoon.io"
            }
        },
        {
            "chainId": 101,
            "address": "95dZLhb9FkL7mP7mrBQcnLZdoHVzsyekNGSXmXnrL9S1",
            "symbol": "BLACKMOON",
            "name": "Blackmoon Finance",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95dZLhb9FkL7mP7mrBQcnLZdoHVzsyekNGSXmXnrL9S1/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DRh7AedYnCCpEwhPHSVox24g2zxqBK84MroyuXCknP8c",
            "symbol": "YOUC",
            "name": "yOUcash",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DRh7AedYnCCpEwhPHSVox24g2zxqBK84MroyuXCknP8c/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.youengine.com"
            }
        },
        {
            "chainId": 101,
            "address": "78PyZ96q9WpMBpxY89Ct9ovBBxuKYaUEzt9gGZsWXQQ2",
            "symbol": "SOD",
            "name": "SOLID",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solidsbs/asset/dc74c7250fb5b97e6c84c3fe1241dba47288b3eb/solid.png",
            "tags": [
                "currency",
                "social-token",
                "community-token",
                "trading-token",
                "reward-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solidsbs",
                "website": "https://solid.sbs/"
            }
        },
        {
            "chainId": 101,
            "address": "BDCyYhw4zvf8wx3e8rXcrcYnmsX5BxF7R6g5ooXqu3uL",
            "symbol": "SamoShib",
            "name": "Samo Shib",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDCyYhw4zvf8wx3e8rXcrcYnmsX5BxF7R6g5ooXqu3uL/logo.png",
            "tags": [
                "Meme-Token",
                "NFTs",
                "Meme-NFTs"
            ],
            "extensions": {
                "discord": "https://discord.gg/samoshib",
                "serumV3Usdc": "CBaYZDJktoYTrSc38Qd2dq3Kko9bzVKMx6i31nBRVSd4",
                "twitter": "https://twitter.com/samoshib",
                "website": "https://www.samoshib.com"
            }
        },
        {
            "chainId": 101,
            "address": "9eEwTum1ppuc5HnhwSR1HYFZ1F712eD8n6mwzT92HumG",
            "symbol": "TCTY",
            "name": "Traceability",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9eEwTum1ppuc5HnhwSR1HYFZ1F712eD8n6mwzT92HumG/icon.png",
            "tags": [
                "currency",
                "social-token",
                "community-token",
                "trading-token",
                "reward-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DRE26YQhpsifRS2pQptiVMpidVVvVNL8kbSbaXQyHmqi",
            "symbol": "TOAPP",
            "name": "Appreciation",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DRE26YQhpsifRS2pQptiVMpidVVvVNL8kbSbaXQyHmqi/logo.png"
        },
        {
            "chainId": 101,
            "address": "SLRCdL7ZiF8UUHCMqoeCCYhb4FDoHwDJG6MgEmVb9is",
            "symbol": "SLRC",
            "name": "Sollarion Crystal",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLRCdL7ZiF8UUHCMqoeCCYhb4FDoHwDJG6MgEmVb9is/logo.png",
            "tags": [
                "utility-token",
                "gaming",
                "metaverse",
                "nfts",
                "play-to-earn",
                "solana-ecosystem",
                "staking"
            ],
            "extensions": {
                "description": "SLRC tokens are built on the Solana blockchain. Users can use SLRC to participate in the game, buy cards on marketplace, bet with other players, win in matches and tournaments etc. Also staking rewards are paid in SLRC tokens.",
                "discord": "https://discord.com/invite/x2DcJ4uRd9",
                "instagram": "https://www.instagram.com/solanagalaxynft/",
                "twitter": "https://twitter.com/SolanaGalaxyNFT",
                "website": "https://sollarion.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9sQtcMxC7zwoVm9vsbZD6XkbZMik5882sA22oc6kb6bU",
            "symbol": "GAP",
            "name": "Solana Gap",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9sQtcMxC7zwoVm9vsbZD6XkbZMik5882sA22oc6kb6bU/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "stake-pool-token"
            ],
            "extensions": {
                "telegram": "https://t.me/SolanaGAP",
                "twitter": "https://twitter.com/SolanaGAP",
                "website": "https://solanagap.com"
            }
        },
        {
            "chainId": 101,
            "address": "4U7hSJxbgDoAcQqL2SZpB3hik225ZuG3L33VyrpZD8BA",
            "symbol": "ADA",
            "name": "CARDASOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4U7hSJxbgDoAcQqL2SZpB3hik225ZuG3L33VyrpZD8BA/logo.png",
            "tags": [
                "Meme-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tusA7HfUD4",
                "telegram": "https://t.me/cardasol",
                "twitter": "https://twitter.com/Carda_sol",
                "website": "https://cardasoltoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "Fj4js23EXVLoUQ26VPfmwVbt76XLn6souUGis71FvNmM",
            "symbol": "LEARN",
            "name": "Solearna",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fj4js23EXVLoUQ26VPfmwVbt76XLn6souUGis71FvNmM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/z3AZSHfcQ4",
                "reddit": "https://www.reddit.com/r/solearna",
                "telegram": "https://t.me/joinchat/3U4rp853CFMyNGY0",
                "twitter": "https://twitter.com/solearna",
                "website": "https://solearna.com/"
            }
        },
        {
            "chainId": 101,
            "address": "88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2",
            "symbol": "GRLC",
            "name": "Garlic",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2/logo.png",
            "tags": [
                "utility-token",
                "nfts"
            ],
            "extensions": {
                "description": "The Ivy Vampires Garlic Token",
                "discord": "https://discord.gg/X6rgFCSQ",
                "twitter": "https://twitter.com/IvyVampires",
                "website": "https://www.ivyvampires.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6FXNqrezcTvvySBTmANUxCes9D5apGv6Jbehi2ct1E8Q",
            "symbol": "THANKS",
            "name": "Thanks Token",
            "decimals": 4,
            "logoURI": "https://cdn.jsdelivr.net/gh/quinqu/thanks-token/logo/thanks.jpg",
            "extensions": {
                "description": "Give a Thanks (Token)",
                "twitter": "https://twitter.com/softbt"
            }
        },
        {
            "chainId": 101,
            "address": "3th4wtEfsK3HmGYn1uEdv9718YaebGMBW5PAPP26Te9x",
            "symbol": "EGEMS",
            "name": "Forgebot Electrogems",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3th4wtEfsK3HmGYn1uEdv9718YaebGMBW5PAPP26Te9x/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/nZmwCFbtC2",
                "twitter": "https://twitter.com/forgebots"
            }
        },
        {
            "chainId": 101,
            "address": "T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw",
            "symbol": "sUSDT-8",
            "name": "Saber Wrapped USDT (8 decimals)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw/icon.png",
            "tags": [
                "stablecoin",
                "saber-mkt-usd",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "tether",
                "serumV3Usdc": "77quYg4MGneUdjgXCunt9GgM1usmrxKY31twEy3WHwcS",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "HfsvmwJtXVkExnigHzvry42shBvNWk55ndmVHCxdXEXJ",
            "symbol": "SIVI",
            "name": "Siva Coin",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/t4xXJCA.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "We can help you turn your content into extra income and pursue more of what you love.",
                "website": "https://siva.vip/"
            }
        },
        {
            "chainId": 101,
            "address": "7MirouXpJ1J9wYT3jB9xSp8GKwjx9fJ2hHut5HxWxdLa",
            "symbol": "GKC",
            "name": "Ghost Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7MirouXpJ1J9wYT3jB9xSp8GKwjx9fJ2hHut5HxWxdLa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.ghostkitchenbrands.com/"
            }
        },
        {
            "chainId": 103,
            "address": "DchriVmCLnaDytihAWeAPVdGhHCf8dXM6UdPMGGT9rhx",
            "symbol": "SFVN",
            "name": "Sofven",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DchriVmCLnaDytihAWeAPVdGhHCf8dXM6UdPMGGT9rhx/logo.png",
            "extensions": {
                "website": "https://www.sofven.com"
            }
        },
        {
            "chainId": 101,
            "address": "69UCbruXAtuHm1cHEcv5zWeW3gE5mcKEsmmuRykgoqo2",
            "symbol": "FPVGC",
            "name": "First Principle Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/69UCbruXAtuHm1cHEcv5zWeW3gE5mcKEsmmuRykgoqo2/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "poDLpYm4N53sLjtaqJoqGaa4AH34uwq2GkXDWNiMztn",
            "symbol": "PODL",
            "name": "Poodle Inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/poDLpYm4N53sLjtaqJoqGaa4AH34uwq2GkXDWNiMztn/logo.png",
            "tags": [
                "meme-token",
                "nft",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ejWddvadHZ",
                "twitter": "https://twitter.com/SolPoodleInu",
                "website": "https://solpodl.com/"
            }
        },
        {
            "chainId": 102,
            "address": "9HAmU9sW1dVTZWd84qa5U83YdJLhemL21SheySznXJNM",
            "symbol": "wFINN",
            "name": "wFINN Coin (Wormhole V2)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9HAmU9sW1dVTZWd84qa5U83YdJLhemL21SheySznXJNM/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "address": "0x43b29D1b0941cB5a5bbe0ABd958eF03616437A49",
                "assetContract": "https://etherscan.io/address/0x43b29D1b0941cB5a5bbe0ABd958eF03616437A49",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585"
            }
        },
        {
            "chainId": 101,
            "address": "2DJZLCpTgSodW4DCEA7wJG29ypbnNRpZjSmmBRyRA4dt",
            "symbol": "BEAT",
            "name": "BEAT TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DJZLCpTgSodW4DCEA7wJG29ypbnNRpZjSmmBRyRA4dt/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hgvs5aM4SXy4YHsZZXUBcnvNXFmop6XCzAFGnkamQdRq",
            "symbol": "SKE",
            "name": "Solana King Empire",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hgvs5aM4SXy4YHsZZXUBcnvNXFmop6XCzAFGnkamQdRq/logo.png"
        },
        {
            "chainId": 101,
            "address": "DKCc1ja8WfxL1oki7zTBKoac74GHZXLfrcazBWbDJpwD",
            "symbol": "ZYB",
            "name": "Ziyob",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DKCc1ja8WfxL1oki7zTBKoac74GHZXLfrcazBWbDJpwD/ZiyobLogo.png",
            "tags": [
                "Ziyob-token",
                "Ziyob",
                "ZYB"
            ],
            "extensions": {
                "website": "https://www.ziyob.com"
            }
        },
        {
            "chainId": 101,
            "address": "21vatMcwZz53Eu2EUDCS9xoZUXdJ9ABMTQYNMKKkzoNW",
            "symbol": "CEX",
            "name": "Catena X",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/21vatMcwZz53Eu2EUDCS9xoZUXdJ9ABMTQYNMKKkzoNW/Logo.png",
            "tags": [
                "community",
                "DEFI",
                "Bridage"
            ],
            "extensions": {
                "coingeckoId": "catena-x",
                "telegram": "https://t.me/CatenaX",
                "twitter": "https://twitter.com/catena_x",
                "website": "https://catenax.org"
            }
        },
        {
            "chainId": 101,
            "address": "8uCVHmHrRH61PgDx9KgzpNanuv18QbmZnQbjZ82f2RfG",
            "symbol": "PPAY",
            "name": "PocketPay Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8uCVHmHrRH61PgDx9KgzpNanuv18QbmZnQbjZ82f2RfG/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/PocketPayFin",
                "website": "https://pocketpay.finance"
            }
        },
        {
            "chainId": 101,
            "address": "5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp",
            "symbol": "XTAG",
            "name": "xHashtag Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/xhashtagio",
                "website": "https://www.xhashtag.io/"
            }
        },
        {
            "chainId": 101,
            "address": "NEBUuriMe6MAhVo9Y9U6FwUg1ah1CEoL4iZfkDJ6BzD",
            "symbol": "NEBU",
            "name": "NEBU",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NEBUuriMe6MAhVo9Y9U6FwUg1ah1CEoL4iZfkDJ6BzD/logo.png"
        },
        {
            "chainId": 101,
            "address": "A94X2fRy3wydNShU4dRaDyap2UuoeWJGWyATtyp61WZf",
            "symbol": "TRYB",
            "name": "BiLira",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A94X2fRy3wydNShU4dRaDyap2UuoeWJGWyATtyp61WZf/logo.png",
            "tags": [
                "tryb",
                "bilira",
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "bilira",
                "github": "https://github.com/bilira-org",
                "instagram": "https://instagram.com/bilira_official",
                "telegram": "https://t.me/BiLira_Official",
                "website": "http://bilira.co"
            }
        },
        {
            "chainId": 101,
            "address": "BKLPnzg7HZfARBRxBNc7oiiYo7ALKH5Enmz2sTq6LkCM",
            "symbol": "Ycoins",
            "name": "Yute Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKLPnzg7HZfARBRxBNc7oiiYo7ALKH5Enmz2sTq6LkCM/logo.png"
        },
        {
            "chainId": 101,
            "address": "5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg",
            "symbol": "YORK",
            "name": "Yorkipoo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "telegram": "https://t.me/yorkipoocoin",
                "twitter": "https://twitter.com/yorkipoocoin",
                "website": "https://yorkipoocoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "AqLjXoMbZE9vMj3df1JQwJiLAb4WXh2K3K4JTpzGNKSo",
            "symbol": "MLD",
            "name": "Metaland",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/bdeepakreddy/img-repo/main/coin.png",
            "tags": [
                "nft",
                "nft-token",
                "nft-marketplace",
                "game-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MRvfvpGvXq",
                "website": "https://www.metaland.place"
            }
        },
        {
            "chainId": 101,
            "address": "3qtvTtcoAXaoTd8f1dmF8wzw17A1XPphuaDuGiBeVuTA",
            "symbol": "ANGELZ",
            "name": "ANGELZ Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3qtvTtcoAXaoTd8f1dmF8wzw17A1XPphuaDuGiBeVuTA/logo.png",
            "tags": [
                "socialtoken",
                "angelz"
            ],
            "extensions": {
                "github": "https://github.com/AngelzTokenTeam",
                "website": "http://angelztoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6Q3KU2dh4Nwkh8TpX7bF848cgUaXzaB9gzwbC6RLWbDy",
            "symbol": "INFY",
            "name": "NFT INFINITY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Q3KU2dh4Nwkh8TpX7bF848cgUaXzaB9gzwbC6RLWbDy/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/rGYPbXPvZT",
                "telegram": "https://t.me/joinchat/Rs7H8jFCyZZlOGMx",
                "twitter": "https://twitter.com/nftdinfinity",
                "website": "https://nftdinfinity.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CYbXZ7U1AeV8kjtJG3YqKMLaWtdMLa24JojrikZZdXAG",
            "symbol": "DIVX",
            "name": "Dividex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CYbXZ7U1AeV8kjtJG3YqKMLaWtdMLa24JojrikZZdXAG/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/joinchat/RSi9ys6xK1M4N2Zh",
                "website": "https://dividex.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5yzDXTMcxP7k4RRo1Wjg2USAiP4FrgNJqGPmWAVQKeLU",
            "symbol": "FINDLAY",
            "name": "Findlay Force",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5yzDXTMcxP7k4RRo1Wjg2USAiP4FrgNJqGPmWAVQKeLU/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "trading-token",
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/findlayhats/",
                "instagram": "https://www.instagram.com/findlayhats/",
                "twitter": "https://twitter.com/findlayhats",
                "website": "https://www.findlayhats.com/"
            }
        },
        {
            "chainId": 101,
            "address": "A2yu4dj4VCbXmV9Z58Vk6TEP5MwoHCZF22HpY9rjSTvx",
            "symbol": "winter",
            "name": "winter token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/Samodoge/solwinterlogo@master/solwinter.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolsWinter",
                "website": "https://solswinter.io/"
            }
        },
        {
            "chainId": 101,
            "address": "58S8vzz3JTi7brm1QhuddkwP9vp31uLXwtfTdb3vDczh",
            "symbol": "VIEW",
            "name": "VIEW",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58S8vzz3JTi7brm1QhuddkwP9vp31uLXwtfTdb3vDczh/logo.png",
            "tags": [
                "advertising-platform",
                "meme-token"
            ],
            "extensions": {
                "description": "The VIEW platform is a digital advertising platform that connects advertisers and advertising consumers based on the blockchain technology. ",
                "telegram": "https://t.me/VIEW_Official",
                "website": "https://viewplatform.net/index_en.html",
                "whitepaper": "https://viewplatform.net/wp/VIEW_WP_V1.0_EN.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L",
            "symbol": "AUX",
            "name": "AUX Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "address": "0xab60CcAC0d4157c2b03e5D883bDbe60317992f58",
                "assetContract": "https://etherscan.io/address/0xab60CcAC0d4157c2b03e5D883bDbe60317992f58",
                "website": "https://lode.one"
            }
        },
        {
            "chainId": 101,
            "address": "76f4Q85ZamR7LhFwjQmw8nLc7cg1WjGYTLFLTxFWtAJ2",
            "symbol": "OLDMAN",
            "name": "Dad Oldman token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/76f4Q85ZamR7LhFwjQmw8nLc7cg1WjGYTLFLTxFWtAJ2/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F1gQgd8hQCc68rfhZoMrRgEcN393cD2LaySCHFXS1Vii",
            "symbol": "RIDE",
            "name": "chain RIDE token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F1gQgd8hQCc68rfhZoMrRgEcN393cD2LaySCHFXS1Vii/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3fXCWpQaEHEsnHSYAqcxm3QLPGLxYiZzoJbqRY9wWxV2",
            "symbol": "DCCT",
            "name": "Docu Chain",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3fXCWpQaEHEsnHSYAqcxm3QLPGLxYiZzoJbqRY9wWxV2/logo.png",
            "tags": [
                "document-platform"
            ],
            "extensions": {
                "description": "The DocuChain project aims to establish a protocol and a platform that encompasses blockchainbased e-document/contract creation, sending, and authentication that have advanced from existing edocument/contract services in line with this trend.",
                "website": "https://docuchain.co/",
                "whitepaper": "https://docuchain.co/wp/DocuChain_WP_V1.0_EN.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR",
            "symbol": "LODE",
            "name": "LODE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR/logo.png",
            "tags": [
                "dividend"
            ],
            "extensions": {
                "address": "0x897f505d9637622219e4687eC1A71b4Acf204816",
                "assetContract": "https://etherscan.io/address/0x897f505d9637622219e4687eC1A71b4Acf204816",
                "website": "https://lode.one"
            }
        },
        {
            "chainId": 101,
            "address": "HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD",
            "symbol": "TWT",
            "name": "Trust Wallet (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4B0F1812e5Df2A09796481Ff14017e6005508003",
                "assetContract": "https://bscscan.com/address/0x4B0F1812e5Df2A09796481Ff14017e6005508003",
                "bridgeContract": "https://bscscan.com/address/0x4B0F1812e5Df2A09796481Ff14017e6005508003",
                "coingeckoId": "trust-wallet-token",
                "twitter": "https://twitter.com/trustwallet",
                "website": "https://trustwallet.com"
            }
        },
        {
            "chainId": 101,
            "address": "CAAFUFXRks6NLBtNPmedfEWycC51w7XyR9twj3vEqmMG",
            "symbol": "MAINE",
            "name": "Maine Coon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAAFUFXRks6NLBtNPmedfEWycC51w7XyR9twj3vEqmMG/logo.svg",
            "tags": [
                "meme",
                "meme-token",
                "roar",
                "meow",
                "cat"
            ],
            "extensions": {
                "website": "https://www.mainecooncrypto.com/"
            }
        },
        {
            "chainId": 101,
            "address": "SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M",
            "symbol": "SNTR",
            "name": "Sentre",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "sentre",
                "discord": "https://discord.gg/FyHJgQ7W",
                "medium": "https://sentre.medium.com",
                "telegram": "https://t.me/Sentre",
                "twitter": "https://twitter.com/SentreProtocol",
                "website": "https://sentre.io"
            }
        },
        {
            "chainId": 101,
            "address": "BAq9fjWbUSN5zED6dSscg8FiJfSRcEvawweuq5sKrjDB",
            "symbol": "GSL",
            "name": "Gamesol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAq9fjWbUSN5zED6dSscg8FiJfSRcEvawweuq5sKrjDB/logo.png",
            "tags": [
                "Game-Token",
                "Gamefi"
            ],
            "extensions": {
                "discord": "https://discord.io/gamesol_io",
                "twitter": "https://twitter.com/GameSol_io",
                "website": "https://gamesol.io"
            }
        },
        {
            "chainId": 101,
            "address": "w71tgPPw37F7sCxxq1bcT63D3dtV6bJ7MUD3q7fpLqg",
            "symbol": "SIM",
            "name": "Serious Internet Money",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/w71tgPPw37F7sCxxq1bcT63D3dtV6bJ7MUD3q7fpLqg/logo.png",
            "tags": [
                "utility-token",
                "dao",
                "nfts",
                "staking",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanapsb",
                "website": "https://solanapepeserious.business/"
            }
        },
        {
            "chainId": 101,
            "address": "92SCBrWw1N9fy2eXHbq8xME9nHuGx3Xs9ZWhRu1ZutHA",
            "symbol": "PRN",
            "name": "Piranha",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/92SCBrWw1N9fy2eXHbq8xME9nHuGx3Xs9ZWhRu1ZutHA/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/piranhasol",
                "website": "http://wwww.piranha.network"
            }
        },
        {
            "chainId": 101,
            "address": "AATv4cJz1ykW3XBDBwqCwbvGjg3SfWNJ47tSKhriTZuD",
            "symbol": "MGS",
            "name": "MoonGearSolid",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AATv4cJz1ykW3XBDBwqCwbvGjg3SfWNJ47tSKhriTZuD/logo.png",
            "tags": [
                "Utility-token",
                "Defi",
                "Fund-management"
            ],
            "extensions": {
                "discord": "https://discord.gg/4nrky27yu5",
                "twitter": "https://twitter.com/MoonGearSolid"
            }
        },
        {
            "chainId": 101,
            "address": "6dhTynDkYsVM7cbF7TKfC9DWB636TcEM935fq7JzL2ES",
            "symbol": "BONK",
            "name": "BONK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6dhTynDkYsVM7cbF7TKfC9DWB636TcEM935fq7JzL2ES/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/ShibaCouncil",
                "website": "https://shibacouncil.com"
            }
        },
        {
            "chainId": 101,
            "address": "56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g",
            "symbol": "CRYY",
            "name": "CRY Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "coingeckoId": "cry-coin",
                "discord": "https://discord.gg/kkY6dT4ARX",
                "twitter": "https://twitter.com/crycoinofficial",
                "website": "https://crycoin.io"
            }
        },
        {
            "chainId": 101,
            "address": "6NreacWdGLoWRc9m6Si6B5UZ8svaQthnKnZy6bssf3av",
            "symbol": "KOO",
            "name": "Koo coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6NreacWdGLoWRc9m6Si6B5UZ8svaQthnKnZy6bssf3av/logo.png"
        },
        {
            "chainId": 101,
            "address": "vqU8NVkkgpFtt3YECwuQRD3RhX7LYaqZKrotZbdiBJn",
            "symbol": "MOONRACE",
            "name": "Moonrace",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vqU8NVkkgpFtt3YECwuQRD3RhX7LYaqZKrotZbdiBJn/logo.png",
            "tags": [
                "meme-token",
                "metaverse",
                "gaming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/moonracecoin",
                "website": "https://moonrace.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "GyVHQisPRasinD6E2SGTGLUt7eE9ZStsLKSzZN9uNBpM",
            "symbol": "MLND",
            "name": "Metaland Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/bdeepakreddy/img-repo/main/token.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MRvfvpGvXq",
                "website": "https://www.metaland.place"
            }
        },
        {
            "chainId": 101,
            "address": "X1rN2wMDSoX3QacCgcumMGDQvk1ssr1LqTTwLsCRvf9",
            "symbol": "SEED",
            "name": "Stars Seed",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/X1rN2wMDSoX3QacCgcumMGDQvk1ssr1LqTTwLsCRvf9/logo.png",
            "tags": [
                "NFT",
                "STARS",
                "INFLUENCERS",
                "FAN-NFT",
                "STAKING"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ExchangeStars",
                "website": "https://stars.exchange"
            }
        },
        {
            "chainId": 103,
            "address": "2wnwtV87ujGZX3mhcRCHnfcJZufh2ffEQLvkNKAn63dE",
            "symbol": "URA",
            "name": "URA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wnwtV87ujGZX3mhcRCHnfcJZufh2ffEQLvkNKAn63dE/logo.png",
            "tags": [
                "dao",
                "gaming",
                "gamefi",
                "governance-token"
            ],
            "extensions": {
                "website": "https://uranusx.io"
            }
        },
        {
            "chainId": 103,
            "address": "6HusCnR1e3RKiLuzMCUzBZkaUTvjGYvT6EisRXGWPa5n",
            "symbol": "ROBO",
            "name": "ROBO",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3QEMVBPQm7PK86oms35izjca89fBbigDvs9wNNVH3Wi8/logo.png",
            "tags": [
                "dao",
                "gaming",
                "gamefi",
                "utility-token"
            ],
            "extensions": {
                "website": "https://roboworld.io"
            }
        },
        {
            "chainId": 101,
            "address": "9gVyinZd945tJddQBP7Lwo97kUpos7zNw6VkBA55VyPd",
            "symbol": "ISOL",
            "name": "Infinity Solana",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gVyinZd945tJddQBP7Lwo97kUpos7zNw6VkBA55VyPd/logo.png",
            "tags": [
                "stake-pool-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/uTPNNuSbG5",
                "twitter": "https://twitter.com/infinity_solana"
            }
        },
        {
            "chainId": 101,
            "address": "6TkXczqRJ71jRG5XNjaF1i2kW38PtCeLcG1LdttEVjun",
            "symbol": "DUDES",
            "name": "Dudes DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TkXczqRJ71jRG5XNjaF1i2kW38PtCeLcG1LdttEVjun/logo.png"
        },
        {
            "chainId": 101,
            "address": "DdYm4xFMroFwztupfN4mwVmCQTrVgQ8gXGzzscXzCnkF",
            "symbol": "CCT",
            "name": "CC Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dmontaldo/static-content/main/cct-token.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8T3pZV6rspPm98i5pFTKDxvuSF8R1YZMzDKhFuD5LY4s",
            "symbol": "TIP",
            "name": "Tip Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dmontaldo/static-content/main/tip-token.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A5jZTDzSxySC1YGGN85XjoyDbxxcjKBAWb5viX8KhivA",
            "symbol": "$EC",
            "name": "Elder Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5jZTDzSxySC1YGGN85XjoyDbxxcjKBAWb5viX8KhivA/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/0xCthulhu",
                "website": "https://0xcthulhu.io/"
            }
        },
        {
            "chainId": 101,
            "address": "JAAZw9LPm6xCyMHyR1F4FCDWwbCa5ac4MkwXm3rw3jpW",
            "symbol": "DEO",
            "name": "DEO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAAZw9LPm6xCyMHyR1F4FCDWwbCa5ac4MkwXm3rw3jpW/logo.png"
        },
        {
            "chainId": 101,
            "address": "AbZyorYfq9drppborCYCqdjt4g1apskgf1NTHZAdCVsR",
            "symbol": "CC",
            "name": "Clumsy Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AbZyorYfq9drppborCYCqdjt4g1apskgf1NTHZAdCVsR/logo.png",
            "tags": [
                "Community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F2bJk8vrGFfzXBfiWFJTJx8RW9BUpnUCVL1tY5RqMAg6",
            "symbol": "SUS",
            "name": "SUSOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F2bJk8vrGFfzXBfiWFJTJx8RW9BUpnUCVL1tY5RqMAg6/logo.png",
            "tags": [
                "fun-token",
                "not-serious-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Z3eaHY36Ed"
            }
        },
        {
            "chainId": 101,
            "address": "BuToUqdPzbhB16rtFHwReDPVUjaQ3YRBYNU6i3x2HYYb",
            "symbol": "BUTO",
            "name": "BUTOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BuToUqdPzbhB16rtFHwReDPVUjaQ3YRBYNU6i3x2HYYb/logo.jpg"
        },
        {
            "chainId": 103,
            "address": "9koJxhRXUX3zAbgWmFdvJkJjm8WfmV5MdcCRMapuuSvc",
            "symbol": "HMTC",
            "name": "Hamlett Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9koJxhRXUX3zAbgWmFdvJkJjm8WfmV5MdcCRMapuuSvc/logo.png",
            "tags": [
                "troll",
                "company"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Jul00t",
                "website": "http://hamlett-company.tk/"
            }
        },
        {
            "chainId": 101,
            "address": "3F78gqMGe12Z2RV77qevTcYP1z6jus5R5LAmbU2mDxRJ",
            "symbol": "PIU",
            "name": "PIU COIN",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/s9T8Zvj/mamu.jpg",
            "tags": [
                "mamma",
                "altcoin"
            ]
        },
        {
            "chainId": 101,
            "address": "FtRnQYHNmQNkX1T1ZuWd7HtFV2GrvJwTm4CwtXMvWRs8",
            "symbol": "SBBY",
            "name": "StakedBabylonDAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SZSVgnQDgKKxtCe3UA3x4T7tcSRNDaL3NmfdEqpuLzo/logo.jpg",
            "tags": [
                "Utility-token",
                "Defi",
                "StakedBabylonDAO"
            ],
            "extensions": {
                "twitter": "https://twitter.com/babylondao"
            }
        },
        {
            "chainId": 101,
            "address": "5SZSVgnQDgKKxtCe3UA3x4T7tcSRNDaL3NmfdEqpuLzo",
            "symbol": "BBY",
            "name": "BabylonDAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SZSVgnQDgKKxtCe3UA3x4T7tcSRNDaL3NmfdEqpuLzo/logo.jpg",
            "tags": [
                "Utility-token",
                "Defi",
                "BabylonDAO"
            ],
            "extensions": {
                "twitter": "https://twitter.com/babylondao"
            }
        },
        {
            "chainId": 101,
            "address": "9ovRpcbTPAGAsnfPDRHyu7Jy5qaXfmYpn8xNpaLkU1tX",
            "symbol": "JOV",
            "name": "Jove",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ovRpcbTPAGAsnfPDRHyu7Jy5qaXfmYpn8xNpaLkU1tX/logo.png",
            "tags": [
                "community-token",
                "game",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/pecpAJNUJP",
                "twitter": "https://twitter.com/JoveCryptoGames",
                "website": "https://jove.games/"
            }
        },
        {
            "chainId": 101,
            "address": "9SqXkCCpcRGrimjMfPdof9awceRHUEwy5qvmTgW779QD",
            "symbol": "FUEL",
            "name": "Fuel",
            "decimals": 2,
            "logoURI": "https://arweave.net/8shYrOoCPffqqQTwuGm2BXbzAllV0OtRCNZ_SMyvg7c?ext=png"
        },
        {
            "chainId": 101,
            "address": "3jcdrN3muKRJxCvuhHJ71ADDqsshFS1hQMj1Z5cGs6Gm",
            "symbol": "FRE",
            "name": "Frevation",
            "decimals": 2,
            "logoURI": "https://www.frevation.com/tokens/fre/logo.png"
        },
        {
            "chainId": 101,
            "address": "45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq",
            "symbol": "BOFB",
            "name": "BofB",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq/logo.svg",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "BankOfBitcoin",
                "bofb",
                "metaverse"
            ],
            "extensions": {
                "description": "BofB is where Bank meets Bitcoin. BofB's vision is to become a transitional platform that will help connect the old banking system with how everyone will bank in the future. Our mission is to help achieve the right to bank to everyone by allowing the entire world to be able to access our platform as long as they can meet the most simple requirement which is wanting to join our platform. KYC and AML will be mandatory but banking should be easy and for everyone in the world. Our main strategy will be to have the strongest presence in the Metaverse.",
                "discord": "https://discord.gg/7kcg2rxDxb",
                "instagram": "https://www.instagram.com/bofbdc",
                "telegram": "https://t.me/bofbdc",
                "twitter": "https://twitter.com/bofbdc",
                "website": "https://bofb.com"
            }
        },
        {
            "chainId": 101,
            "address": "JBKQgpTs11KR2sVqXRYXmWAbsE3ZuBzX3dGM2hjsiNcP",
            "symbol": "CARD",
            "name": "CARDEI Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JBKQgpTs11KR2sVqXRYXmWAbsE3ZuBzX3dGM2hjsiNcP/logo.png",
            "tags": [
                "utility-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9EwkJGjMenzW6F14ycgjvyojDZ3aK3sYvww27HKDVx3o",
            "symbol": "CHURU",
            "name": "CHURU",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EwkJGjMenzW6F14ycgjvyojDZ3aK3sYvww27HKDVx3o/logo.png",
            "tags": [
                "NFTs",
                "P2E"
            ],
            "extensions": {
                "website": "https://www.themeowcapital.com"
            }
        },
        {
            "chainId": 101,
            "address": "Eb4ZW4bPZybnsefY8B8h9z5sb8W7Q7HW9un5PL2N1dPb",
            "symbol": "LACH",
            "name": "Lachenal Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Eb4ZW4bPZybnsefY8B8h9z5sb8W7Q7HW9un5PL2N1dPb/logo.jpg",
            "tags": [
                "school-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AJnZPCRayvrPheXpEcJDCDU7pHvsRvVsxD8UfSEyMa4p",
            "symbol": "KEIK",
            "name": "KeithKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HafihnBU1fFn2Gi4GBh8gTcqw4BFDzkFmKLQf9ikGoyr",
            "symbol": "CHAKRA",
            "name": "CHAKRA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HafihnBU1fFn2Gi4GBh8gTcqw4BFDzkFmKLQf9ikGoyr/logo.png",
            "tags": [
                "NFTs",
                "utility-token",
                "currency-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GxkmWoupEMFg4w4aCxcsLgJsxvQybMQPf9PMetCkFJfy",
            "symbol": "ATL",
            "name": "Astral",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GxkmWoupEMFg4w4aCxcsLgJsxvQybMQPf9PMetCkFJfy/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "github": "https://github.com/AslakOffi"
            }
        },
        {
            "chainId": 101,
            "address": "5EvkywasgapiYDtoAdfvFoVnzQPN1bsap4RUyoLyY9fB",
            "symbol": "RWC",
            "name": "REPUBLIC WORLD",
            "decimals": 3,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/RWC/logo/RWC.png",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "facebook": "https://Facebook.com/republicworldss",
                "telegram": "https://t.me/Republicworldgame",
                "website": "http://Republicworld.io"
            }
        },
        {
            "chainId": 103,
            "address": "BmfgBHamrb6xRxkU33z6Hq4itEZ2K7KSkHdHGpeMYF9m",
            "symbol": "RAVEN",
            "name": "RAVEN Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BmfgBHamrb6xRxkU33z6Hq4itEZ2K7KSkHdHGpeMYF9m/logo.png"
        },
        {
            "chainId": 101,
            "address": "7re6D7DzKKhFzkqYznoyrc3Wdz1QWVEsou8W1u1G1g3p",
            "symbol": "ASS",
            "name": "Assaed coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7re6D7DzKKhFzkqYznoyrc3Wdz1QWVEsou8W1u1G1g3p/logo.png",
            "tags": [
                "assaed",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Ndirxnadix"
            }
        },
        {
            "chainId": 101,
            "address": "FTf8LQBKpiBjmbQdqXY84a3bzU9C9Q3hkzVd9nzb8kz6",
            "symbol": "FIFA",
            "name": "SOCCER WORLD",
            "decimals": 3,
            "logoURI": "https://github.com/vdbhatt3/Soccer/blob/main/docs/soccer.png",
            "tags": [
                "game-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8AFGosRN65dAsXdhXK7rGb82vuQ7BVn1iQQJuhEwaHNL",
            "symbol": "OMPT",
            "name": "OnlyMP3",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8AFGosRN65dAsXdhXK7rGb82vuQ7BVn1iQQJuhEwaHNL/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/_onlymp3",
                "website": "https://onlymp3.com"
            }
        },
        {
            "chainId": 101,
            "address": "64wFMuUZQ3KykKrf6Xi9pgpmASLfePiaAhXHqc9kHS4V",
            "symbol": "LYS",
            "name": "Lysnake",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64wFMuUZQ3KykKrf6Xi9pgpmASLfePiaAhXHqc9kHS4V/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LysnakeYT",
                "website": "https://lysnakeit.fr"
            }
        },
        {
            "chainId": 101,
            "address": "puppy8Lhckjh768j7vCPLr4244a64ZqioskcXxyb4rC",
            "symbol": "PUPPY",
            "name": "Puppy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/puppy8Lhckjh768j7vCPLr4244a64ZqioskcXxyb4rC/logo.png",
            "tags": [
                "meme",
                "Community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Vzp5BYr4FC",
                "serumV3Usdc": "GQQKuYXvdDV8qYMAaZDMuXYdhk1cokWhtSqULWnHZ6Gy",
                "website": "https://puppycoin.eu/"
            }
        },
        {
            "chainId": 101,
            "address": "88GEfTd3JKyQtRE3kYCup73MFe2gqHtZSCgqaUB5zDHV",
            "symbol": "aeWBTC",
            "name": "Wrapped BTC (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png",
            "extensions": {
                "coingeckoId": "wrapped-bitcoin"
            }
        },
        {
            "chainId": 101,
            "address": "2ASbApnFVSTp2RJvMLgLVfbDwJvu1FRXdhJWrGs89Lhj",
            "symbol": "aeMIM",
            "name": "Wrapped MIM (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ASbApnFVSTp2RJvMLgLVfbDwJvu1FRXdhJWrGs89Lhj/logo.png",
            "extensions": {
                "coingeckoId": "magic-internet-money"
            }
        },
        {
            "chainId": 101,
            "address": "BAexggGFsiLCKr17cSZF12wkHd8BkR1DBhzuSb78WTR2",
            "symbol": "aeFEI",
            "name": "Wrapped FEI (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAexggGFsiLCKr17cSZF12wkHd8BkR1DBhzuSb78WTR2/logo.png",
            "extensions": {
                "coingeckoId": "fei-usd"
            }
        },
        {
            "chainId": 101,
            "address": "FdvkkCbCgYht1xTR1W9MBJhEF1JEPVhHtW1NXBYRzZts",
            "symbol": "afBTC",
            "name": "Wrapped BTC (Allbridge from Fantom)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png",
            "extensions": {
                "coingeckoId": "wrapped-bitcoin"
            }
        },
        {
            "chainId": 101,
            "address": "BiryxNvVTABRs3pEE4fvVuu4d17aAYEsNmcPnJ8E8WeT",
            "symbol": "afETH",
            "name": "Wrapped ETH (Allbridge from Fantom)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png",
            "extensions": {
                "coingeckoId": "weth"
            }
        },
        {
            "chainId": 101,
            "address": "Grk6b4UMRWkgyq4Y6S1BnNRF4hRgtnMFp7Sorkv6Ez4u",
            "symbol": "afUSDC",
            "name": "Wrapped USDC (Allbridge from Fantom)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "HjUhUzi6fVkY1BndaSc4Dcg2mCzvnqzXjVJtXsj78ver",
            "symbol": "afDAI",
            "name": "Wrapped DAI (Allbridge from Fantom)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "dai"
            }
        },
        {
            "chainId": 101,
            "address": "6JbKyQenGQgnCx7ctmBC4F1ZYsnZdLpchf7F49ywLLUX",
            "symbol": "POG",
            "name": "POGGERS COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JbKyQenGQgnCx7ctmBC4F1ZYsnZdLpchf7F49ywLLUX/logo.jpg",
            "tags": [
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H3JwKryBPTaH3meBcW5qYZVGTv5gceCYMQwmbmWZUzX9",
            "symbol": "HIELON",
            "name": "Hi Elon Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H3JwKryBPTaH3meBcW5qYZVGTv5gceCYMQwmbmWZUzX9/logo.png"
        },
        {
            "chainId": 101,
            "address": "B6NyNs3k2DZm6XYNL5wyC8sWEkEL4S8eekonRaSjjD7B",
            "symbol": "TrumpcoinXSOL",
            "name": "TRUMPXSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B6NyNs3k2DZm6XYNL5wyC8sWEkEL4S8eekonRaSjjD7B/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/trump_sol",
                "website": "https://www.trumpcoinxsol.com"
            }
        },
        {
            "chainId": 101,
            "address": "8pxVzXsV19u9NStX9TTjafaCS6JDt7Es2wRJ9h319jFy",
            "symbol": "Luna",
            "name": "Lunaria",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pxVzXsV19u9NStX9TTjafaCS6JDt7Es2wRJ9h319jFy/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/3tsYkuw5G9"
            }
        },
        {
            "chainId": 101,
            "address": "BhGjzKt8tU8YD78WEL7Tag6e4DA5kRCZFBCuFsQMy9Tm",
            "symbol": "EGG",
            "name": "Egg Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhGjzKt8tU8YD78WEL7Tag6e4DA5kRCZFBCuFsQMy9Tm/logo.png"
        },
        {
            "chainId": 101,
            "address": "7efKNiTv3jsiqesUPaujCKoFM3d1Lj7qPybcrggQMCcj",
            "symbol": "SFBALL",
            "name": "Solana Football",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7efKNiTv3jsiqesUPaujCKoFM3d1Lj7qPybcrggQMCcj/logo.png"
        },
        {
            "chainId": 101,
            "address": "5i7VMjLqZGmnNZREzGFPqxT6c6f4k7oTasbfQMpZWgs8",
            "symbol": "HGM",
            "name": "Hagim",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5i7VMjLqZGmnNZREzGFPqxT6c6f4k7oTasbfQMpZWgs8/logo.png",
            "tags": [
                "community-token",
                "hagim-token",
                "hagim-floki",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "C9PXceoq8uho2RZDGdtxYmyGYXeJUABaUP4qn37wQCgB",
            "symbol": "poUSDC",
            "name": "Pole Aggregator USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9PXceoq8uho2RZDGdtxYmyGYXeJUABaUP4qn37wQCgB/USDC.svg",
            "tags": [
                "pole",
                "aggregator"
            ],
            "extensions": {
                "website": "https://pole.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "3oePHsi4fhoyuLAjqXEgBUPB1cs4bP9A8cZpc1dATS9c",
            "symbol": "CHEEZE",
            "name": "Cheeze",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3oePHsi4fhoyuLAjqXEgBUPB1cs4bP9A8cZpc1dATS9c/logo.png",
            "extensions": {
                "website": "https://www.ratbastard.io"
            }
        },
        {
            "chainId": 101,
            "address": "Ac7Q9QfNtwcjsDogkDh1rduRPJX21Tqe52t9NHXCfKRF",
            "symbol": "ENDR",
            "name": "Ender",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ac7Q9QfNtwcjsDogkDh1rduRPJX21Tqe52t9NHXCfKRF/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://discord.gg/4JzJfcMw8H"
            }
        },
        {
            "chainId": 101,
            "address": "stedjBnitxnJCRuWfsHP5x7pjXQhHsvbQMwq5ip8jQN",
            "symbol": "PANDA-WHALE",
            "name": "Panda Whale Collectible",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/stedjBnitxnJCRuWfsHP5x7pjXQhHsvbQMwq5ip8jQN/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/nPSXvdJpvz",
                "telegram": "https://t.me/pandacoin_announcements",
                "twitter": "https://twitter.com/pandacoin_fi",
                "website": "https://pandacrypto.org/"
            }
        },
        {
            "chainId": 101,
            "address": "9qTA3A113oG94ppSpiJTwWCyj44wyNcgPAs5i9d7QQne",
            "symbol": "$CULT",
            "name": "Culture Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9qTA3A113oG94ppSpiJTwWCyj44wyNcgPAs5i9d7QQne/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/vulturesnft",
                "website": "https://culturevultures.io"
            }
        },
        {
            "chainId": 101,
            "address": "Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD",
            "symbol": "$FROG",
            "name": "Frog",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/pepepunksnft",
                "website": "https://pepepunks.net"
            }
        },
        {
            "chainId": 101,
            "address": "FUHEdLxGtu2cpWuTX4u9Md8hYEb2WKScJXE2pTh9AUWt",
            "symbol": "$DMT",
            "name": "DMT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FUHEdLxGtu2cpWuTX4u9Md8hYEb2WKScJXE2pTh9AUWt/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/X8xgfTk3bT",
                "twitter": "https://twitter.com/SolMushies",
                "website": "https://solmushies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7H4Co5vUfRGuYCHFitwCr2iCvKpv7QiRA8hFfwa1y4x3",
            "symbol": "DOM",
            "name": "Dominus",
            "decimals": 17,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7H4Co5vUfRGuYCHFitwCr2iCvKpv7QiRA8hFfwa1y4x3/logo.png",
            "extensions": {
                "website": "https://www.dominus.finance"
            }
        },
        {
            "chainId": 101,
            "address": "Gz1xDkMQzBbGt5fVekmUBQdWaKSgbSfKBcPjnHRF6W21",
            "symbol": "TRICCI",
            "name": "tricci totodu30",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gz1xDkMQzBbGt5fVekmUBQdWaKSgbSfKBcPjnHRF6W21/logo.png",
            "tags": [
                "tricci-token"
            ],
            "extensions": {
                "twitch": "https://www.twitch.tv/lifep4k"
            }
        },
        {
            "chainId": 101,
            "address": "9Jog79BFVaD2eiR4evZvXDqvdphd62etqwqCPEM325uq",
            "symbol": "ALPS",
            "name": "Alpstake Ownership",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Jog79BFVaD2eiR4evZvXDqvdphd62etqwqCPEM325uq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/alpstake"
            }
        },
        {
            "chainId": 101,
            "address": "6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8",
            "symbol": "$KSH",
            "name": "Keeshond Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8/logo.png",
            "tags": [
                "Meme-Token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KeeshondC",
                "website": "https://keeshondcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE",
            "symbol": "PIXL",
            "name": "Pixels.so Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE/logo.png",
            "tags": [
                "utility-token",
                "NFT",
                "DeFi"
            ],
            "extensions": {
                "description": "Borrow Against Your NFTs - Deposit NFTs to Earn Yield.",
                "discord": "https://discord.gg/rMdRdhTQ",
                "telegram": "https://t.me/pixels_official",
                "twitter": "https://twitter.com/pixels_so",
                "website": "https://www.pixels.so"
            }
        },
        {
            "chainId": 101,
            "address": "G7eETAaUzmsBPKhokZyfbaT4tD9igdZSmfQGEYWem8Sw",
            "symbol": "EYE",
            "name": "NftEyez Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/NftEyez/assets/main/assets/eye-coin.png",
            "tags": [
                "nfteyez"
            ],
            "extensions": {
                "discord": "https://discord.gg/mfAvPq9v",
                "twitter": "https://twitter.com/eyeznft",
                "website": "https://nfteyez.global"
            }
        },
        {
            "chainId": 101,
            "address": "7xd71KP4HwQ4sM936xL8JQZCwE4amUko1AdCCf6Znjrt",
            "symbol": "FREN",
            "name": "SOL Frens",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xd71KP4HwQ4sM936xL8JQZCwE4amUko1AdCCf6Znjrt/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GqvM4xqeegtVgzY5mbEoq8Z9Wf8XpHuAxpg7gXN2dQ37",
            "symbol": "FLOW",
            "name": "Flower Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GqvM4xqeegtVgzY5mbEoq8Z9Wf8XpHuAxpg7gXN2dQ37/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "MetaVerse"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FlowerSolana",
                "website": "https://www.flowersolana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ADQauiPc85ciT33JTTpxkC5BiTt6zYukWfDYPvZE7nBD",
            "symbol": "DOGERACA",
            "name": "DogeRaCa",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADQauiPc85ciT33JTTpxkC5BiTt6zYukWfDYPvZE7nBD/logo.png",
            "tags": [
                "GAME",
                "MEME",
                "DEFI",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/doge_raca",
                "website": "https://www.dogeraca.org"
            }
        },
        {
            "chainId": 101,
            "address": "4KZd4rxAhxyzNmugAVnaedoe8KKNCJfSjPQQBhUaN7Bh",
            "symbol": "Orbital",
            "name": "Orbital",
            "decimals": 4,
            "logoURI": "https://github.com/orbital-market/Orbital-Market/blob/main/orbital-static.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2",
            "symbol": "CHICKS",
            "name": "SolChicks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2/logo.png",
            "tags": [
                "gaming",
                "nfts",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "solchicks-token",
                "description": "The Leading Play-to-Earn Fantasy Game on Solana",
                "discord": "https://discord.gg/solchicks",
                "telegram": "https://t.me/solchicksnft",
                "twitter": "https://twitter.com/SolChicksNFT",
                "website": "https://www.solchicks.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8j3hXRK5rdoZ2vSpGLRmXtWmW6iYaRUw5xVk4Kzmc9Hp",
            "symbol": "SHARDS",
            "name": "SolChicks Shards",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8j3hXRK5rdoZ2vSpGLRmXtWmW6iYaRUw5xVk4Kzmc9Hp/logo.png",
            "tags": [
                "gaming",
                "nfts",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "solchicks-shards",
                "description": "SHARDS is the utility token for SolChicks, the leading play-to-earn fantasy game on Solana",
                "discord": "https://discord.gg/solchicks",
                "telegram": "https://t.me/solchicksnft",
                "twitter": "https://twitter.com/SolChicksNFT",
                "website": "https://www.solchicks.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7rmV64vLfbrbS5rTFvojYWzCVEn8dnJ9RfSRx3nD6C5E",
            "symbol": "MARIO",
            "name": "Super Mario",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rmV64vLfbrbS5rTFvojYWzCVEn8dnJ9RfSRx3nD6C5E/logo.png",
            "tags": [
                "Community-token",
                "Meme",
                "NFT"
            ],
            "extensions": {
                "serumV3Usdc": "GN2TTgnWm7WyMEFZjbtzZL2d7NxhBk4QMZtDdLZUkcqv"
            }
        },
        {
            "chainId": 101,
            "address": "iobVLW6ePUrcX7Y4DzW69QDpQt2CkJ6c3SZmaLozibj",
            "symbol": "BTCNFT",
            "name": "Bitcoin NFT Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iobVLW6ePUrcX7Y4DzW69QDpQt2CkJ6c3SZmaLozibj/logo.png",
            "tags": [
                "nft-token",
                "btc-nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/btcnftcoin",
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ANHqBGhAHkkfGo1Jtu2BTbdhSFyh7N43sHv1riHtCNRt",
            "symbol": "CUCKOO",
            "name": "Cuckoo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CuckooDex/static/main/favicon-32x32.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/GtxjqTxnZG",
                "twitter": "https://twitter.com/cuckoodex",
                "website": "https://cuckoodex.com"
            }
        },
        {
            "chainId": 101,
            "address": "G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB",
            "symbol": "PUFF",
            "name": "PUFF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "puff",
                "discord": "https://discord.gg/stonedapecrew",
                "serumV3Usdc": "FjkwTi1nxCa1S2LtgDwCU8QjrbGuiqpJvYWu3SWUHdrV",
                "twitter": "https://twitter.com/stonedapecrew",
                "website": "https://www.stonedapecrew.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CjsinDkm95vqRTWsRv7A2nyf1br4oMWAZUZHA7xLD5gR",
            "symbol": "PFace",
            "name": "PrimaFace",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CjsinDkm95vqRTWsRv7A2nyf1br4oMWAZUZHA7xLD5gR/logo.png"
        },
        {
            "chainId": 101,
            "address": "CasshNb6PacBzSwbd5gw8uqoQEjcWxaQ9u9byFApShwT",
            "symbol": "Cassia",
            "name": "Cassia TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CasshNb6PacBzSwbd5gw8uqoQEjcWxaQ9u9byFApShwT/cassia_3.jpg",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL",
            "symbol": "BANA",
            "name": "Shibana",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL/Shibana.png",
            "tags": [
                "DeFi",
                "meme-token",
                "Meta",
                "solana",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "shibana",
                "discord": "https://discord.com/invite/X8tKR52E32",
                "twitter": "https://twitter.com/ShibanaCoin",
                "website": "https://www.shibana.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D4NXEPTGUcwntfyY3P1w8F7kpLbs2vn4G5LmwDF9ERPY",
            "symbol": "BNANA",
            "name": "Banana Solana Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4NXEPTGUcwntfyY3P1w8F7kpLbs2vn4G5LmwDF9ERPY/logo.png"
        },
        {
            "chainId": 101,
            "address": "v7bs339b8oqXgYGJu4mX9cB8tZrFVSozBjW6QZZKE3m",
            "symbol": "GON",
            "name": "MetaDrago Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/v7bs339b8oqXgYGJu4mX9cB8tZrFVSozBjW6QZZKE3m/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://t.co/ASaaDoQjIi?amp=1",
                "twitter": "https://twitter.com/MetaDragoNFT"
            }
        },
        {
            "chainId": 101,
            "address": "sinjBMHhAuvywW3o87uXHswuRXb3c7TfqgAdocedtDj",
            "symbol": "sIN",
            "name": "Wrapped Staked Sol Invictus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sinjBMHhAuvywW3o87uXHswuRXb3c7TfqgAdocedtDj/logo.png",
            "tags": [
                "decentralizedreserve",
                "utility-token",
                "DeFi",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/invictusdao",
                "twitter": "https://twitter.com/InvictusDAO",
                "website": "https://invictusdao.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU",
            "symbol": "RACEFI",
            "name": "RaceFi Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU/logo.png",
            "tags": [
                "RaceFi",
                "Metaverse",
                "GameFi",
                "solana",
                "NFT",
                "NFT-Gaming"
            ],
            "extensions": {
                "coingeckoId": "racefi",
                "description": "The fist AI/ML Play-to-Earn Racing Game on Solana",
                "discord": "https://discord.com/invite/rDX8uwd2sg",
                "telegram": "https://t.me/racefi_io",
                "website": "https://racefi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB",
            "symbol": "GST",
            "name": "GST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB/logo.png",
            "extensions": {
                "website": "https://stepn.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx",
            "symbol": "GMT",
            "name": "GMT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx/logo.png",
            "extensions": {
                "website": "https://stepn.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DogeLZECE9CthXasBLFxgeA2umEyt8CcV7Jsf6P5ZTFo",
            "symbol": "DLANA",
            "name": "Dogelana Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DogeLZECE9CthXasBLFxgeA2umEyt8CcV7Jsf6P5ZTFo/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dogelana",
                "github": "https://github.com/dogelanaio",
                "telegram": "https://t.me/dogelana",
                "twitter": "https://twitter.com/dogelana",
                "website": "https://dogelana.io"
            }
        },
        {
            "chainId": 101,
            "address": "Pika2wSYzve4njHBwcqzp2QZPr8w18hRwAkugf13BxK",
            "symbol": "PIKA",
            "name": "Pikachu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Pika2wSYzve4njHBwcqzp2QZPr8w18hRwAkugf13BxK/logo.png",
            "tags": [
                "community-token",
                "meme",
                "nft"
            ],
            "extensions": {
                "serumV3Usdc": "BTtbWNAVB3eYfPUBuztYpxwNQVWYKN7TKU5e8HDPXCd9"
            }
        },
        {
            "chainId": 101,
            "address": "FL4r6VCXhKWu5BUPjgzA1ygYEwbcrcChbxiYta5Gyj5v",
            "symbol": "BATTLE",
            "name": "Battle Drones",
            "decimals": 6,
            "logoURI": "https://battledronesnft.com/tokenPic.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jRz63Dsa3p",
                "twitter": "https://twitter.com/BattleDronesNFT",
                "website": "https://battledronesnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FxPEv1xy4Tcje2pcJA1Wr4Wgn9rxiJSxHA5qicHi3ivg",
            "symbol": "KATJ",
            "name": "Katja Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FxPEv1xy4Tcje2pcJA1Wr4Wgn9rxiJSxHA5qicHi3ivg/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7v5K9VFiqNTWnmkK4wofVfRzG7f7AGQ7WLXLmP91UibU",
            "symbol": "DOGExTESLA",
            "name": "DogeTesla",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7v5K9VFiqNTWnmkK4wofVfRzG7f7AGQ7WLXLmP91UibU/logo.png",
            "tags": [
                "doge-token",
                "Tesla",
                "Doge",
                "Musk"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dogetesla_",
                "website": "https://www.dogetesla.space"
            }
        },
        {
            "chainId": 101,
            "address": "CooQi1ti8NompmbiNENsm1qicdEMqZau9G4FZg5V4bzh",
            "symbol": "COO",
            "name": "Pigeon Resistance DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/pigeon-resistance/token-list/main/assets/mainnet/CooQi1ti8NompmbiNENsm1qicdEMqZau9G4FZg5V4bzh/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/pigeonresist",
                "website": "https://pigeonresistance.org"
            }
        },
        {
            "chainId": 101,
            "address": "55t1PfJngPgMS4c4HeSHPy54VWfkMEk7XBQhSkdz6Cm6",
            "symbol": "VOO",
            "name": "VooVoo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/55t1PfJngPgMS4c4HeSHPy54VWfkMEk7XBQhSkdz6Cm6/logo.png",
            "tags": [
                "VOO-token"
            ],
            "extensions": {
                "website": "https://www.voovoo.io"
            }
        },
        {
            "chainId": 101,
            "address": "JF7Cm3UH3BK1mKawg38e5Pfyv7XVL46QvwqbphFX2ij",
            "symbol": "CND",
            "name": "Conado",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JF7Cm3UH3BK1mKawg38e5Pfyv7XVL46QvwqbphFX2ij/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/conado"
            }
        },
        {
            "chainId": 101,
            "address": "BKSeassCi1Tx1CnmnWQp85JVSBSWBuiMaL3zFUrY1diM",
            "symbol": "WON",
            "name": "Wonders",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKSeassCi1Tx1CnmnWQp85JVSBSWBuiMaL3zFUrY1diM/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/vP7kqKY",
                "website": "https://wondergeek.fr/"
            }
        },
        {
            "chainId": 101,
            "address": "7h7SyWJGLXFDm8xn289rEbGnLZhQBcHDFayQ8tcqyLMw",
            "symbol": "WST",
            "name": "Wallistoken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JF7Cm3UH3BK1mKawg38e5Pfyv7XVL46QvwqbphFX2ij/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Sneazz__"
            }
        },
        {
            "chainId": 101,
            "address": "ENJm611c5aM8Hur9f6oqvLyjU6PjyAA63Pxs8EZ4RrNo",
            "symbol": "TresLeches",
            "name": "Tres Leches Cake",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENJm611c5aM8Hur9f6oqvLyjU6PjyAA63Pxs8EZ4RrNo/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ep29yETXFC",
                "twitter": "https://twitter.com/TresLechesToken",
                "website": "https://tresleches.finance"
            }
        },
        {
            "chainId": 101,
            "address": "D2m2WoHKXdahVwA6gUxqfuCgxbUWv1S9y2p4mMRqnuWi",
            "symbol": "LechesCake",
            "name": "Tres Leches Cake NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D2m2WoHKXdahVwA6gUxqfuCgxbUWv1S9y2p4mMRqnuWi/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ep29yETXFC",
                "twitter": "https://twitter.com/treslechestoken",
                "website": "https://tresleches.finance"
            }
        },
        {
            "chainId": 101,
            "address": "93JDWR3ygdx5g2z6zMvj7Ckkwx8viVDPdCDRFBSoyAr6",
            "symbol": "PRB",
            "name": "PROBE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/93JDWR3ygdx5g2z6zMvj7Ckkwx8viVDPdCDRFBSoyAr6/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7RXbNDhS",
                "telegram": "https://t.me/enzohallot",
                "twitter": "https://twitter.com/EnzoHallot",
                "website": "https://probe.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "BDVKYcMXKwkWcPkUtdLSMC98jeu8jB9vLywXQP7yrfnD",
            "symbol": "DB",
            "name": "Diamond Block",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDVKYcMXKwkWcPkUtdLSMC98jeu8jB9vLywXQP7yrfnD/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/HH8UuUHEqs",
                "instagram": "https://instagram.com/solblockpunks",
                "twitter": "https://twitter.com/solblockpunks",
                "website": "https://solblockpunks.com"
            }
        },
        {
            "chainId": 101,
            "address": "CVj6FV4HmhEsn7xQXCjj5iqbDQHB7hQ8AwZjB6P8UMDu",
            "symbol": "CoW",
            "name": "Culture of Women Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/cultureofwomen/CoW-token/CoW.jpeg",
            "tags": [
                "utility-token",
                "meme-token",
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/cultureofwomen",
                "twitter": "https://twitter.com/CultureOfWomen",
                "website": "https://cultureofwomen.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DWkd7xS3cDXJSMZqXHWN9oNgyCq49x7AdUqdadhTKW43",
            "symbol": "TETRA",
            "name": "Tetracoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DWkd7xS3cDXJSMZqXHWN9oNgyCq49x7AdUqdadhTKW43/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/5Wq4aBjx",
                "telegram": "https://t.me/tetracoinofficial"
            }
        },
        {
            "chainId": 101,
            "address": "HnZiKrSKYQkEfzjQs6qkvuGbBmrBP9YzjB1SMM7tiGZ1",
            "symbol": "SUCH",
            "name": "Such Shiba",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HnZiKrSKYQkEfzjQs6qkvuGbBmrBP9YzjB1SMM7tiGZ1/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SuchShibas"
            }
        },
        {
            "chainId": 101,
            "address": "8kQyyeurfv2upeMaZZgyyyPGev5rq8phgnUx8khA5T7D",
            "symbol": "DZU",
            "name": "Dzungaria DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kQyyeurfv2upeMaZZgyyyPGev5rq8phgnUx8khA5T7D/logo.png",
            "tags": [
                "decentralizedreserve",
                "utility-token",
                "DeFi",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SFKrDXGr",
                "twitter": "https://twitter.com/DzungariaDAO",
                "website": "https://www.dzungaria.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ",
            "symbol": "UNQ",
            "name": "UNQ",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "unq",
                "coinmarketcap": "https://coinmarketcap.com/currencies/unqclub",
                "discord": "https://discord.com/invite/unqnetwork",
                "twitter": "https://twitter.com/clubunq",
                "website": "https://unq.club/"
            }
        },
        {
            "chainId": 101,
            "address": "J9HSQ22QXCFiA5x2QMQVfeUVjhhx365rbF37CCH2LsR4",
            "symbol": "SMETA",
            "name": "SOLSPHERE METACOIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J9HSQ22QXCFiA5x2QMQVfeUVjhhx365rbF37CCH2LsR4/solspheremeta.png",
            "tags": [
                "metaverse",
                "virtual-real-estate",
                "utility-token",
                "community-token",
                "play-to-earn"
            ],
            "extensions": {
                "discord": "https://discord.gg/kMYkYVEGPu",
                "twitter": "https://twitter.com/SolSphereNFT",
                "website": "https://solsphere.io"
            }
        },
        {
            "chainId": 101,
            "address": "A4zyBooAFkpfy7osonRJMQ8a6zArGxN5fNXjXo1ZTZK2",
            "symbol": "NTCK",
            "name": "NetworkChuck Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/NetworkChuckCoin/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/networkchuck",
                "website": "https://networkchuck.com",
                "youtube": "https://www.youtube.com/channel/UC9x0AN7BWHpCDHSm9NiJFJQ"
            }
        },
        {
            "chainId": 101,
            "address": "BQ9VGULyxbEWyfNK2TmYctY8wbLQDQ9UPzwSY3WfxT14",
            "symbol": "RAGE",
            "name": "Solana Rage",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQ9VGULyxbEWyfNK2TmYctY8wbLQDQ9UPzwSY3WfxT14/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A9bQVYHePdN4LaW7BAXm1aL69SMGZ62LqLkeyPjP3rpN",
            "symbol": "TERPS",
            "name": "TERPS TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9bQVYHePdN4LaW7BAXm1aL69SMGZ62LqLkeyPjP3rpN/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/KryptoKronicNFT",
                "website": "https://kryptokronic.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9VTbmHuiQWK3rTnDLQewg3PbmBRXnuvrKW142Xsm8KWi",
            "symbol": "TRMT",
            "name": "TIERRA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9VTbmHuiQWK3rTnDLQewg3PbmBRXnuvrKW142Xsm8KWi/logo.png",
            "tags": [
                "TIERRA"
            ],
            "extensions": {
                "website": "http://tierrameta.io"
            }
        },
        {
            "chainId": 101,
            "address": "HHjoYwUp5aU6pnrvN4s2pwEErwXNZKhxKGYjRJMoBjLw",
            "symbol": "PIP",
            "name": "PIP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HHjoYwUp5aU6pnrvN4s2pwEErwXNZKhxKGYjRJMoBjLw/logo.svg",
            "tags": [
                "web3",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/P2AU78UB3e",
                "linkedin": "https://www.linkedin.com/company/getpip",
                "medium": "https://medium.com/@getpip",
                "reddit": "https://www.reddit.com/r/getpip",
                "telegram": "https://t.me/getpip",
                "twitter": "https://twitter.com/getpipcom",
                "website": "https://www.getpip.com",
                "youtube": "https://www.youtube.com/channel/UCB0bXqJZTq9li7_3ZOkEwdQ"
            }
        },
        {
            "chainId": 101,
            "address": "9Hf6j1UNsSTBAGh7Xng2ig27TYzJQn2MzR6aipUtw18y",
            "symbol": "EZS",
            "name": "EZsmarts",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/EZsmarts/Solana-repo/main/EZsmarts-Token.png"
        },
        {
            "chainId": 101,
            "address": "BFJ6rAVroCPaYbvgcT11ku4NG4KbfX3gWDGrbEUJ7r34",
            "symbol": "KYO",
            "name": "KYO",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/BLTZykc.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CJy5cjAMuczQpUkKq2dfNAKLttMKefZyzQVJnxsP5ti6",
            "symbol": "GRAVITY",
            "name": "GRAVITY",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJy5cjAMuczQpUkKq2dfNAKLttMKefZyzQVJnxsP5ti6/logo.jpg",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/gravityToken"
            }
        },
        {
            "chainId": 101,
            "address": "AtgKBiAwAmAbSNaZCSfvvCCuypsqbHgry2MmaE1PUh2Z",
            "symbol": "SOLsquid",
            "name": "SOLsquid",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AtgKBiAwAmAbSNaZCSfvvCCuypsqbHgry2MmaE1PUh2Z/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/2tgb7cft6u",
                "telegram": "https://t.me/SOLsquid",
                "twitter": "https://twitter.com/SOLsquidToken"
            }
        },
        {
            "chainId": 103,
            "address": "6W5L2kBemn9sLWNdpDqZkFU8CjHfRhHZuG4tpfexfWYp",
            "symbol": "CHB",
            "name": "Cheeseburger",
            "decimals": 9,
            "logoURI": "https://www.mcdonalds.com/is/image/content/dam/usa/nfl/nutrition/items/hero/desktop/t-mcdonalds-Double-Cheeseburger.jpg",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9JK1KNvGdWvZUX783nULvDZaZMVhdzohL2dKUUkjW2jZ",
            "symbol": "NINJA/USDC",
            "name": "ATRIX LP Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.atrix.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7K5AQHswmRMNCFQjT9y8DyQeDMGDvAEjVRob8sJdGrsN",
            "symbol": "ATCOIN",
            "name": "ATCOIN",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/ATWORLD/ATCOINtoken/ATCOIN-LOGO.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/atworldcoin",
                "twitter": "https://twitter.com/atworldcoin",
                "website": "http://atcoin.club"
            }
        },
        {
            "chainId": 101,
            "address": "79MEbJ299WbsWbE33q2GSRDJdQoVYzm8JXUMBfbASnJk",
            "symbol": "BDG",
            "name": "BidOn Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/79MEbJ299WbsWbE33q2GSRDJdQoVYzm8JXUMBfbASnJk/logo.png"
        },
        {
            "chainId": 101,
            "address": "BoaXkBim5WC4KPKdCCoQMoQDfypndQu1dRuicvtUxhE5",
            "symbol": "GREG",
            "name": "GREGORY TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoaXkBim5WC4KPKdCCoQMoQDfypndQu1dRuicvtUxhE5/logo.png"
        },
        {
            "chainId": 101,
            "address": "BXVR8wqs8ixPMHnuUq65buJQmimwG9WG5pNKKKBRd2S4",
            "symbol": "GUMA",
            "name": "GUM ARABIC",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/GUMA/logo/GUMA.jpg",
            "tags": [
                "Trading-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/gumarabictoken",
                "telegram": "https://t.me/gumarabictoken",
                "twitter": "https://twitter.com/gum_token",
                "website": "http://gumarabic.io"
            }
        },
        {
            "chainId": 101,
            "address": "86xL1LZhW7wj49Y6ttJ58ZeL9hDnpYUiCjVBPzHJSDiJ",
            "symbol": "WHALE",
            "name": "Panda WHALE Collectible",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/86xL1LZhW7wj49Y6ttJ58ZeL9hDnpYUiCjVBPzHJSDiJ/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/nPSXvdJpvz",
                "telegram": "https://t.me/pandacoin_announcements",
                "twitter": "https://twitter.com/pandacoin_fi",
                "website": "https://pandacrypto.org/"
            }
        },
        {
            "chainId": 101,
            "address": "BYvRESCxiFvzz2yTckNB6wi8yuYiobQU7NHMUbVE7Wu5",
            "symbol": "DOLPHIN",
            "name": "Panda DOLPHIN Collectible",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYvRESCxiFvzz2yTckNB6wi8yuYiobQU7NHMUbVE7Wu5/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/nPSXvdJpvz",
                "telegram": "https://t.me/pandacoin_announcements",
                "twitter": "https://twitter.com/pandacoin_fi",
                "website": "https://pandacrypto.org/"
            }
        },
        {
            "chainId": 101,
            "address": "HDXJnHUdi11qNb2aJ4xVaAvvhgnrRVDbZ9ogw3d81ZYc",
            "symbol": "EGEM",
            "name": "ForgeBots Electrogems",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDXJnHUdi11qNb2aJ4xVaAvvhgnrRVDbZ9ogw3d81ZYc/logo.png"
        },
        {
            "chainId": 101,
            "address": "5BXRYnEftT8aRnPBjPkz2nsXX6muFUUTanx78GN42qKQ",
            "symbol": "EXPO",
            "name": "EXPOSURE",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5BXRYnEftT8aRnPBjPkz2nsXX6muFUUTanx78GN42qKQ/logo.png",
            "tags": [
                "utility-token",
                "artsdigital-token"
            ],
            "extensions": {
                "website": "http://arts.digital"
            }
        },
        {
            "chainId": 101,
            "address": "NYANnvzqZeKmkNoHF1bcdqWfTvxLfjaDKTi2tksNapJ",
            "symbol": "NYAN",
            "name": "Nyan Cat",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NYANnvzqZeKmkNoHF1bcdqWfTvxLfjaDKTi2tksNapJ/logo.png",
            "tags": [
                "meme-token",
                "nft",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NfVj6T8qvh",
                "twitter": "https://twitter.com/SolNyanCat",
                "website": "https://solnyan.com/"
            }
        },
        {
            "chainId": 101,
            "address": "89MrW4Y3UxWqVAbJM2xfqy9uGNE7tMFT6uCtn6i2XH7x",
            "symbol": "SMOL",
            "name": "SMOL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/89MrW4Y3UxWqVAbJM2xfqy9uGNE7tMFT6uCtn6i2XH7x/logo.png",
            "tags": [
                "nft",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fqRa2jt9Q4",
                "twitter": "https://twitter.com/BabySolanaMBS",
                "website": "https://babysolanamonkey.business/"
            }
        },
        {
            "chainId": 101,
            "address": "CiDHbwzXqKC2gchuiwbkP36ktHSJV8QHAoJ5FTa8s6QS",
            "symbol": "MKC",
            "name": "Mickey Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CiDHbwzXqKC2gchuiwbkP36ktHSJV8QHAoJ5FTa8s6QS/logo.png",
            "tags": [
                "utility-token",
                "meme",
                "dex",
                "amm",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.io/mickeycoin",
                "telegram": "https://t.me/mickeycoin_official",
                "twitter": "https://twitter.com/mickeycoin_sol",
                "website": "https://mickeycoins.com"
            }
        },
        {
            "chainId": 101,
            "address": "4CuBdvKbwy1wo8o9sDs7mUQZTL1weKitpBA6X2D5a1nX",
            "symbol": "NSA",
            "name": "Nebula Stars Anthill",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/nonconforme/nebula_stars_anthill/nebula_logo_mini.png",
            "tags": [
                "social-token",
                "farming",
                "nebula"
            ],
            "extensions": {
                "telegram": "https://t.me/nebula_android_application",
                "website": "https://nebula.spl-token.com"
            }
        },
        {
            "chainId": 101,
            "address": "boba6XbsqCYTciFuzEmqAtBmdLVPzfj1Ui3wRBv8rQR",
            "symbol": "BOBA",
            "name": "Boba",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/boba6XbsqCYTciFuzEmqAtBmdLVPzfj1Ui3wRBv8rQR/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/YRtzm69Qzd",
                "medium": "https://boba-so.medium.com",
                "telegram": "https://t.me/boba_so",
                "twitter": "https://twitter.com/boba_solana",
                "website": "https://boba.so"
            }
        },
        {
            "chainId": 101,
            "address": "6TQYoqKGwtUYhPQUaG9SyzXJuwX38xPFVtHs3B1VRav4",
            "symbol": "FAUCI",
            "name": "Fauci Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TQYoqKGwtUYhPQUaG9SyzXJuwX38xPFVtHs3B1VRav4/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/QBTpckzaTx"
            }
        },
        {
            "chainId": 101,
            "address": "BTmj9HU4iQeDGG9k9v1a1tmFJCF1wyDaTJYidPPo2Nk",
            "symbol": "FLWR",
            "name": "Flower",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTmj9HU4iQeDGG9k9v1a1tmFJCF1wyDaTJYidPPo2Nk/logo.png",
            "extensions": {
                "discord": "https://discord.gg/solflowersnft",
                "twitter": "https://twitter.com/solflowers_io",
                "website": "https://www.solflowers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DmnQJhJ2jjFrotQsySdMzGc7hq9x8snb3rPT2qyShoC8",
            "symbol": "BEACH",
            "name": "BEACH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DmnQJhJ2jjFrotQsySdMzGc7hq9x8snb3rPT2qyShoC8/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/VAybaUNMsD",
                "twitter": "https://twitter.com/beachdaysio",
                "website": "https://beachdays.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ForaXiBD8K3a7C1PwxV1xqDHs5aV8y8nWRmHebafdkes",
            "symbol": "FORA",
            "name": "Fora",
            "decimals": 9,
            "logoURI": "https://www.fora.co/images/Fora+Logo+Solana/icon.png",
            "tags": [
                "fora",
                "utility-token"
            ],
            "extensions": {
                "description": "Co-founders hmu -\u003e ryan@kanelabs.com",
                "discord": "https://discord.gg/6erTzYChXh",
                "website": "https://fora.co"
            }
        },
        {
            "chainId": 101,
            "address": "GHAhTrgtYW7jRkojQQWPGkMbmG5jGK25QBu6FSj5tvZ5",
            "symbol": "GLTR",
            "name": "Glitter",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHAhTrgtYW7jRkojQQWPGkMbmG5jGK25QBu6FSj5tvZ5/logo.png",
            "tags": [
                "coin"
            ],
            "extensions": {
                "discord": "https://milliononmars.com/discord",
                "twitter": "https://twitter.com/MilliononMars",
                "website": "https://milliononmars.com"
            }
        },
        {
            "chainId": 101,
            "address": "6MpQesMjehBwJzgDRDsbUXqjHetf1LGE94H7FDzRVL9Y",
            "symbol": "ThugPenguin",
            "name": "Thug Penguin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6MpQesMjehBwJzgDRDsbUXqjHetf1LGE94H7FDzRVL9Y/logo.png",
            "tags": [
                "community-token",
                "meme",
                "thug"
            ],
            "extensions": {
                "serumV3Usdc": "HKYrwWJEhv7yTKFtraNtMWptirAPHsriMVDuUYiGgC4v",
                "twitter": "https://twitter.com/ThugTokens",
                "website": "https://thugtokens.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BzY2yoAPi3tD5xqVqEzrSPu5CSv9Vk7V2fsjJAQLqLv8",
            "symbol": "ThugMonkey",
            "name": "Thug Monkey",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BzY2yoAPi3tD5xqVqEzrSPu5CSv9Vk7V2fsjJAQLqLv8/logo.png",
            "tags": [
                "community-token",
                "meme",
                "thug"
            ],
            "extensions": {
                "serumV3Usdc": "AgUSpr5zSppSY3Gdz8j1HeKZuyntd3Q9KkjkT2KcYsj",
                "twitter": "https://twitter.com/ThugTokens",
                "website": "https://thugtokens.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Gh6jp5U3yfcJwkZ7RoY6Ak2tgd752dSqBSYwHnfK3gft",
            "symbol": "Gangsta",
            "name": "Gangsta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gh6jp5U3yfcJwkZ7RoY6Ak2tgd752dSqBSYwHnfK3gft/logo.png",
            "tags": [
                "community-token",
                "meme",
                "thug"
            ],
            "extensions": {
                "serumV3Usdc": "FJwGJFcu47vgGHEM6y124HxFuCeg9o5UAgA5skgWsSUv",
                "twitter": "https://twitter.com/ThugTokens",
                "website": "https://thugtokens.com/"
            }
        },
        {
            "chainId": 101,
            "address": "JBK72yMfskz6tkaDCSM6bmaTf6ub7zDZUgBNxTWRAx8p",
            "symbol": "ThugPandas",
            "name": "Thug Pandas",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JBK72yMfskz6tkaDCSM6bmaTf6ub7zDZUgBNxTWRAx8p/logo.png",
            "tags": [
                "community-token",
                "meme",
                "thug"
            ],
            "extensions": {
                "serumV3Usdc": "FiJzMSWGzG5kwumYeYZNuRm9ByPj8mF1cFMeks5kRMhg",
                "twitter": "https://twitter.com/ThugTokens",
                "website": "https://thugtokens.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8Au2WcQrgn1oTKfnnaFfb3iMjfdXYp7Y7CrHi8JaNSUZ",
            "symbol": "ThugShiba",
            "name": "Thug Shiba",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Au2WcQrgn1oTKfnnaFfb3iMjfdXYp7Y7CrHi8JaNSUZ/logo.png",
            "tags": [
                "community-token",
                "meme",
                "thug"
            ],
            "extensions": {
                "serumV3Usdc": "9UJ3AhPZjpBCupqj3Ch32MEB6RJgyYKAC8zvWLNpx5nn",
                "twitter": "https://twitter.com/ThugTokens",
                "website": "https://thugtokens.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7wKWd1kXz1wM5epW5hsu4Em3GAByGyDc3wr2AspMWjDG",
            "symbol": "$UFO",
            "name": "Solian UFO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7wKWd1kXz1wM5epW5hsu4Em3GAByGyDc3wr2AspMWjDG/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/jD4SvGwMb3",
                "twitter": "https://twitter.com/Solian_s",
                "website": "https://soliansnft.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "Aeiwkg1ep4YYQRomeZrr1J1hfbu7M36N3BwQ7AFJNCEz",
            "symbol": "AGB",
            "name": "Angry Bird",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aeiwkg1ep4YYQRomeZrr1J1hfbu7M36N3BwQ7AFJNCEz/logo.png",
            "tags": [
                "utility-token",
                "meme-token",
                "nfts",
                "meme-token",
                "community-token",
                "game-token",
                "metaverse-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AngryBirdLabs",
                "website": "https://angrybird.org"
            }
        },
        {
            "chainId": 101,
            "address": "7teDwSD3gCyWiBMFBvhojKWUcCE8rV6Qi39jfB8TZL3i",
            "symbol": "FUD",
            "name": "Forge Utah DAO Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7teDwSD3gCyWiBMFBvhojKWUcCE8rV6Qi39jfB8TZL3i/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ForgeUtah"
            }
        },
        {
            "chainId": 101,
            "address": "FvuVVPH5UYqe61UkEnNfPTWbgc5DBLGqW5pjz4iquwPr",
            "symbol": "SHKL",
            "name": "Shekel Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FvuVVPH5UYqe61UkEnNfPTWbgc5DBLGqW5pjz4iquwPr/logo.svg",
            "tags": [
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GiaaHW6H1gYM6ygzug2gSpGMAy7NAMCQGHsT4LgXZxwY",
            "symbol": "FACT",
            "name": "FACT Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GiaaHW6H1gYM6ygzug2gSpGMAy7NAMCQGHsT4LgXZxwY/logo.png",
            "tags": [
                "FACTToken"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FactToken"
            }
        },
        {
            "chainId": 101,
            "address": "9Tctj47WGuQ8cfnU5eJRRxaJhbnzLssFnb9Ujy79vwxQ",
            "symbol": "LBC",
            "name": "LobCoins",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Tctj47WGuQ8cfnU5eJRRxaJhbnzLssFnb9Ujy79vwxQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://github.com/ImmTheo"
            }
        },
        {
            "chainId": 101,
            "address": "Bqt9y5yQ68dThZNJuoYy5ficCxAvmbYycNR8BsNbiQPk",
            "symbol": "NAS",
            "name": "Not A Scam Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bqt9y5yQ68dThZNJuoYy5ficCxAvmbYycNR8BsNbiQPk/logo.png",
            "tags": [
                "notascamtoken"
            ]
        },
        {
            "chainId": 101,
            "address": "7WxzZAPQGeky9eAdgZr2jgznSHcNLaHfZpd9pHpPsfWp",
            "symbol": "CHB",
            "name": "CHEEBA INU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7WxzZAPQGeky9eAdgZr2jgznSHcNLaHfZpd9pHpPsfWp/logo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ceg14BA1ULy2UnBAHqiv1v29m9yzc5E5Ga3mfnz9qeVb",
            "symbol": "FLOP",
            "name": "FLOPPYX",
            "decimals": 20,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ceg14BA1ULy2UnBAHqiv1v29m9yzc5E5Ga3mfnz9qeVb/logo.png",
            "tags": [
                "floppyx"
            ]
        },
        {
            "chainId": 101,
            "address": "vXV3qR4WifEPiteogi9FDQveVthwrvEeRxpjyQdDHwq",
            "symbol": "BDHK",
            "name": "Bernard Hackwell",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bernardhackwell/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BYbhQgLbfrbQJ1ZvDbffVbCuQvM1xn8HhtNGNt7tBCpK",
            "symbol": "CITY",
            "name": "Manchester City Fan Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYbhQgLbfrbQJ1ZvDbffVbCuQvM1xn8HhtNGNt7tBCpK/logo.png",
            "tags": [
                "manchester",
                "city",
                "fan-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/UDBRKsEY",
                "telegram": "https://t.me/ManCityTokenCommunity",
                "twitter": "https://twitter.com/ManCityToken",
                "website": "https://citytoken.fund"
            }
        },
        {
            "chainId": 101,
            "address": "5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m",
            "symbol": "TPC",
            "name": "TOYPOODLE COIN",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9iCpAn9eazRTXsDawhBaPEnirRzBbtYzTn4feZArBEmn",
            "symbol": "BEE",
            "name": "SOLBEE",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iCpAn9eazRTXsDawhBaPEnirRzBbtYzTn4feZArBEmn/logo.png"
        },
        {
            "chainId": 101,
            "address": "6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC",
            "symbol": "RUN",
            "name": "Run Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC/RunGear.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "run",
                "discord": "https://discord.com/invite/V2f74X8Zrt",
                "telegram": "https://t.me/RunNode",
                "twitter": "https://twitter.com/runnode",
                "website": "https://runnode.com"
            }
        },
        {
            "chainId": 101,
            "address": "DVmRa87hDpCKHhWr8XEy1G4GMhB9QjaVUgjSspdihNcC",
            "symbol": "JGR",
            "name": "JAGUAR",
            "decimals": 8,
            "logoURI": "https://pbs.twimg.com/media/FDi18oAWQAIEdWX?format=jpg\u0026name=240x240",
            "tags": [
                "mem-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Samurai37685862"
            }
        },
        {
            "chainId": 101,
            "address": "DzyZr2caKBt6cTzeXwx3VMPa91kvQg8uejH4xts9LmtJ",
            "symbol": "$HIT",
            "name": "Shitcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DzyZr2caKBt6cTzeXwx3VMPa91kvQg8uejH4xts9LmtJ/logo.png",
            "tags": [
                "meme-token",
                "meme"
            ]
        },
        {
            "chainId": 101,
            "address": "EiJEQs1Hdo41RWvdFeRJi1umZLuvkgBYbipWS7Udpdxc",
            "symbol": "ACE",
            "name": "ACEVERSE",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EiJEQs1Hdo41RWvdFeRJi1umZLuvkgBYbipWS7Udpdxc/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://www.aceverse.net"
            }
        },
        {
            "chainId": 101,
            "address": "2KozeiQ6xnZsLRpjv9KfJdYbvzzLVttRQZ8HT7ibdsX9",
            "symbol": "107",
            "name": "107 Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2KozeiQ6xnZsLRpjv9KfJdYbvzzLVttRQZ8HT7ibdsX9/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiCDmDzHBGBiACK2nDzUpp9hyuFbSAgsqUttwdnAsVNg",
            "symbol": "TEST",
            "name": "SPL Test Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiCDmDzHBGBiACK2nDzUpp9hyuFbSAgsqUttwdnAsVNg/logo.png",
            "tags": [
                "test-token"
            ],
            "extensions": {
                "website": "https://www.test.bg/"
            }
        },
        {
            "chainId": 101,
            "address": "ALohVojeKow4qwNBP1vD11YjQ8cpLMJgCP55asRGBxDR",
            "symbol": "RDOG",
            "name": "RoulDog",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALohVojeKow4qwNBP1vD11YjQ8cpLMJgCP55asRGBxDR/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GjzLwTZvhdhswB1oyLZwH3tvL24Tv87UBmhNNnBgF42B",
            "symbol": "NUNI",
            "name": "Nuni Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GjzLwTZvhdhswB1oyLZwH3tvL24Tv87UBmhNNnBgF42B/logo.png"
        },
        {
            "chainId": 101,
            "address": "7EpBKD7A6F8dpms4ZAC9mkKSnzHeEwx4sHi82G65rMh2",
            "symbol": "SFT",
            "name": "SolFortune Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7EpBKD7A6F8dpms4ZAC9mkKSnzHeEwx4sHi82G65rMh2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/DMK6q2Gp5y",
                "twitter": "https://twitter.com/SolFortuneNFT",
                "website": "https://solfortune.com"
            }
        },
        {
            "chainId": 101,
            "address": "2Dk6yiK64X77sGehh9uqTEeBkd7xtLcspWRAjYZDUydu",
            "symbol": "1000X",
            "name": "1000X Exchange Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Dk6yiK64X77sGehh9uqTEeBkd7xtLcspWRAjYZDUydu/logo.png"
        },
        {
            "chainId": 101,
            "address": "BcZP34sFyzM28VP44V3vP98Lr1VT97CBewkPFmCx3BnG",
            "symbol": "AOD",
            "name": "Apes Of Dragons",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BcZP34sFyzM28VP44V3vP98Lr1VT97CBewkPFmCx3BnG/logo.png"
        },
        {
            "chainId": 101,
            "address": "7ahemqNsiXytAvppX1swLVNV8iCRar6zMm6xsoL39XG1",
            "symbol": "TOMY",
            "name": "TOMMY Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ahemqNsiXytAvppX1swLVNV8iCRar6zMm6xsoL39XG1/logo.png"
        },
        {
            "chainId": 103,
            "address": "CChTcums6omR9BpAZLZVuAfomYvwirLspgkp35nN3QH1",
            "symbol": "$WDR",
            "name": "Wonderland",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CChTcums6omR9BpAZLZVuAfomYvwirLspgkp35nN3QH1/logo.svg",
            "tags": [
                "Metaverse",
                "Game-Fi"
            ]
        },
        {
            "chainId": 101,
            "address": "ZvGkPneitqsPfVUK1wdeNPuKoNc8dZTX89MPqbTKFsK",
            "symbol": "ICY",
            "name": "ICY TOKEN",
            "decimals": 9,
            "logoURI": "https://peskyicecube.xyz/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://peskyicecube.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "AEkmazvpQEs6ZKs6yuv7akiJBbpmAfJquvaem4rsQ7oL",
            "symbol": "KUJIBET",
            "name": "KujiBet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEkmazvpQEs6ZKs6yuv7akiJBbpmAfJquvaem4rsQ7oL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Sports Betting Exchange.",
                "discord": "https://discord.gg/tcDZTPWX",
                "twitter": "https://twitter.com/kujibet",
                "website": "http://www.kujibet.com",
                "youtube": "https://www.youtube.com/channel/UCJqMBbJnEB8CqDT8B56kgEQ"
            }
        },
        {
            "chainId": 101,
            "address": "6YLq4dfqH1PcNYEQMWKA7NKm4WF7NE5ajRvaEFrdyyYG",
            "symbol": "REWD",
            "name": "Reward Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6YLq4dfqH1PcNYEQMWKA7NKm4WF7NE5ajRvaEFrdyyYG/logo.png"
        },
        {
            "chainId": 101,
            "address": "12Nxv2oMuGjdGG3617GWfbhbQkWvcLFC3M6nHFc7fUNQ",
            "symbol": "HALAL",
            "name": "Halal Coin",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/12Nxv2oMuGjdGG3617GWfbhbQkWvcLFC3M6nHFc7fUNQ/logo.jpg",
            "tags": [
                "meme-coin",
                "meme-token",
                "community-token",
                "fun-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/halal_coin/?utm_medium=copy_link",
                "website": "http://halalcoin.ezyro.com/"
            }
        },
        {
            "chainId": 101,
            "address": "87FK6uYhvxQyyvqdUDsQ6SNYbTzyrgwA4RXyZcNLfc36",
            "symbol": "MEDSAF",
            "name": "MEDSAF TOKEN",
            "decimals": 8,
            "logoURI": "https://dl.dropboxusercontent.com/s/gf6ici5n15krscy/medsaf.png?dl=0",
            "tags": [
                "medsaf-token",
                "medical-token"
            ],
            "extensions": {
                "website": "https://medsaf.com"
            }
        },
        {
            "chainId": 101,
            "address": "HnQv3NanZY9HbfLZx5Uu9x5Vrki6P4XkV4GwyaPofopp",
            "symbol": "GROWL",
            "name": "Growl Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HnQv3NanZY9HbfLZx5Uu9x5Vrki6P4XkV4GwyaPofopp/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Receive Growl By Holding your Leopard NFT",
                "twitter": "https://twitter.com/LeopardSNFT",
                "website": "https://leopardsyndicate.com/"
            }
        },
        {
            "chainId": 101,
            "address": "pskJRUNzJbVu4RaZSUJYfvaTNXmFdRCutegL6P2Y9tG",
            "symbol": "PSK",
            "name": "Pesky Penguins Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pskJRUNzJbVu4RaZSUJYfvaTNXmFdRCutegL6P2Y9tG/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "3XRsg7YRVcvZme9rHpATL55WPHrnGXvVL8E7ycNNFu4f",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "4nQqJkBx3Dnovc6ueEdkJfFr2zi2fc77834czmoymR1b",
            "symbol": "WAMO",
            "name": "WAMO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4nQqJkBx3Dnovc6ueEdkJfFr2zi2fc77834czmoymR1b/symbol.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "WAMO is the utility token for SolSamos.",
                "twitter": "https://twitter.com/solsamosnft"
            }
        },
        {
            "chainId": 101,
            "address": "6NWkhj6Xc9o3xhsd7iFpP4Uwv5mtEwwp7i5aTi4ngTSb",
            "symbol": "SEED",
            "name": "Carnivarous Seed Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6NWkhj6Xc9o3xhsd7iFpP4Uwv5mtEwwp7i5aTi4ngTSb/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/CarnivarousClub",
                "website": "https://carnivarousplant.club"
            }
        },
        {
            "chainId": 101,
            "address": "DRmkjPzGMmdKXt6HegGuyGVo2CB4P7fh52aYWhPC4dGk",
            "symbol": "GRAAL",
            "name": "Kaamelott",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DRmkjPzGMmdKXt6HegGuyGVo2CB4P7fh52aYWhPC4dGk/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "social-token",
                "kaamelott"
            ],
            "extensions": {
                "facebook": "https://facebook.com/KaamelottOff/",
                "instagram": "https://www.instagram.com/kaamelott.officiel",
                "twitter": "https://twitter.com/Kaamelott_tweet"
            }
        },
        {
            "chainId": 101,
            "address": "QygDPqqezpdeRs8Rfj5HqCbPc8xRXYg8tn5B5UsHvEc",
            "symbol": "Hack",
            "name": "Hacklab NFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HacklabAzerbaijan/Hack_NFT/main/logo.png",
            "tags": [
                "NFT",
                "community"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/hacklabaz",
                "github": "https://github.com/HacklabAzerbaijan",
                "instagram": "https://www.instagram.com/hacklabaz/",
                "linkedin": "https://www.linkedin.com/company/hacklabaz/about/",
                "website": "https://hacklab.az/"
            }
        },
        {
            "chainId": 101,
            "address": "AkJF8CkEjmV5vt3uhDD73dNWxfjTKKdCsqeg2XazWEca",
            "symbol": "AoD",
            "name": "Apes On Dragons",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkJF8CkEjmV5vt3uhDD73dNWxfjTKKdCsqeg2XazWEca/logo.png"
        },
        {
            "chainId": 101,
            "address": "8nF3i7zjAgcypnouABM7jPimXaVCX4yR2HV4EfKgrE5z",
            "symbol": "WPT",
            "name": "WEPOP TOKEN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8nF3i7zjAgcypnouABM7jPimXaVCX4yR2HV4EfKgrE5z/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "fun-token"
            ],
            "extensions": {
                "description": "The official Wepop.store token",
                "facebook": "https://www.facebook.com/Wepopdotstore",
                "instagram": "https://instagram.com/wepop.store",
                "twitter": "https://twitter.com/Wepop_store",
                "website": "https://wepop.store",
                "youtube": "https://www.youtube.com/channel/UCofafCOwPRLDuShEfnRswnA"
            }
        },
        {
            "chainId": 101,
            "address": "DhEqJyfGTySCCDw1iEoFzP9NdLoc7WJGNLDfSTrN7KCu",
            "symbol": "GURGA",
            "name": "Gurgamezcla Bull",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DhEqJyfGTySCCDw1iEoFzP9NdLoc7WJGNLDfSTrN7KCu/logo.svg",
            "tags": [
                "meme-token",
                "milano",
                "gurga",
                "gurgamezcla",
                "bull"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/gurgamezcla/",
                "website": "https://ggstudio.io"
            }
        },
        {
            "chainId": 101,
            "address": "DYQ3oKHyF8DVDr2gm3tLmFzuQ4HTfrE78NvmCh3WJ1XC",
            "symbol": "ZEN",
            "name": "ZEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYQ3oKHyF8DVDr2gm3tLmFzuQ4HTfrE78NvmCh3WJ1XC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://hashzen.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "4xT2BPEh3GY5bJveNTngSTqstH5SfH2zAQYAGjfZC8Lq",
            "symbol": "JDM",
            "name": "JapaneseDomesticMarket Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4xT2BPEh3GY5bJveNTngSTqstH5SfH2zAQYAGjfZC8Lq/logo.png",
            "tags": [
                "tokenomics",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7tE99RKS4RwQxEjvZfh4CQMoQMMTRdL5KQbugsfhNYPg",
            "symbol": "FLW",
            "name": "Flow Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tE99RKS4RwQxEjvZfh4CQMoQMMTRdL5KQbugsfhNYPg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "serumV3Usdc": "Cc2K7Ao4FtgoquT2ua7zvRuYnQMBSz2s83A5zTyR8h2z",
                "website": "https://moveflow.tech"
            }
        },
        {
            "chainId": 101,
            "address": "7PGnndsCpy477mry5cumk9pBGe5USP1Tw2DpipHpAWBA",
            "symbol": "WORE",
            "name": "Wood Ore",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7PGnndsCpy477mry5cumk9pBGe5USP1Tw2DpipHpAWBA/logo.png"
        },
        {
            "chainId": 101,
            "address": "Ao2yQZRaLxV46M1SAtHu9VtnMuZHepMmLHSfAZiyZtxy",
            "symbol": "BOOTY",
            "name": "Big Booty Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ao2yQZRaLxV46M1SAtHu9VtnMuZHepMmLHSfAZiyZtxy/logo.png",
            "tags": [
                "meme",
                "big",
                "booty",
                "coin",
                "adult"
            ],
            "extensions": {
                "website": "http://bigbootycoin.net/"
            }
        },
        {
            "chainId": 101,
            "address": "nzN5LqVJJmbuFMiWETDvVh519MwUp7qdb7QV9r5xA8S",
            "symbol": "BTT",
            "name": "Beleven Test Token",
            "decimals": 12,
            "logoURI": "https://www.google.com/imgres?imgurl=https%3A%2F%2Fmedia-exp1.licdn.com%2Fdms%2Fimage%2FC560BAQGT_8JiOehf2Q%2Fcompany-logo_200_200%2F0%2F1553000966716%3Fe%3D2159024400%26v%3Dbeta%26t%3DhL0TXFSFIAtPuEKiA_f6dweSEsOe0TDIbDuH04Kn88Q\u0026imgrefurl=https%3A%2F%2Fua.linkedin.com%2Fcompany%2Fgentledevs---%25D1%2580%25D0%25BE%25D0%25B7%25D1%2580%25D0%25BE%25D0%25B1%25D0%25BA%25D0%25B0-%25D0%25BC%25D0%25BE%25D0%25B1%25D1%2596%25D0%25BB%25D1%258C%25D0%25BD%25D0%25B8%25D1%2585-%25D0%25B4%25D0%25BE%25D0%25B4%25D0%25B0%25D1%2582%25D0%25BA%25D1%2596%25D0%25B2\u0026tbnid=FtHIxqUkOX5DTM\u0026vet=12ahUKEwig672M78X0AhVNCHcKHZ-lB1MQMygBegUIARCVAQ..i\u0026docid=Z-ph3rp8gPo9sM\u0026w=200\u0026h=200\u0026itg=1\u0026q=beleven\u0026ved=2ahUKEwig672M78X0AhVNCHcKHZ-lB1MQMygBegUIARCVAQ",
            "tags": [
                "meme-coin",
                "meme-token",
                "community-token",
                "fun-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EbmCtHX3quEWUJ2AtEyk9zGT7eMxs1Nq4H6nf746qf6a",
            "symbol": "CRD",
            "name": "Cards Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EbmCtHX3quEWUJ2AtEyk9zGT7eMxs1Nq4H6nf746qf6a/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://discord.gg/rAZyFEv4Fp"
            }
        },
        {
            "chainId": 101,
            "address": "EjKuHMKXXjbsc1MbVgXzv7GRWTgBaaTiAvQMKpJPAzTp",
            "symbol": "$PNDC",
            "name": "Pandar Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjKuHMKXXjbsc1MbVgXzv7GRWTgBaaTiAvQMKpJPAzTp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.thepandas.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HdCxSk9RNi2FofU1VSmcBAxYgJXbb5DHY3K613M66iPC",
            "symbol": "UPY",
            "name": "Upsilon",
            "decimals": 9,
            "logoURI": "https://imgur.com/f3K0KJG",
            "tags": [
                "utility-token",
                "gaming-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7SwZm1Xs6k1tMEXfrAzUtPjZsW2ex8MTJNuqaenWKKSN",
            "symbol": "MAGI",
            "name": "Crypto Pop Collectibles MAGI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7SwZm1Xs6k1tMEXfrAzUtPjZsW2ex8MTJNuqaenWKKSN/logo.png",
            "tags": [
                "utility-token",
                "NFTS",
                "Community-Token",
                "Game-Token",
                "Metaverse-Token"
            ],
            "extensions": {
                "description": "Crypto Pop Collectibles Gaming/Utility Token.",
                "discord": "https://discord.gg/Bv4k7npwM2",
                "website": "https://cryptopopcollectibles.com"
            }
        },
        {
            "chainId": 101,
            "address": "BHcuncUCUxsBw1yyENizoseEAH2Qrt4UxbcukPhQEGPQ",
            "symbol": "FCKU",
            "name": "FCKU",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/KG422cB.jpeg",
            "tags": [
                "utility-token",
                "NFTS",
                "Community-Token"
            ],
            "extensions": {
                "description": "Moonverse Launchpad Utility Token",
                "website": "https://rascalsnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "6wShYhqA2gs3HUAZ4MyaPDpKPBWFJUQQUGaCoy2k1Tgz",
            "symbol": "TUT",
            "name": "TUT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wShYhqA2gs3HUAZ4MyaPDpKPBWFJUQQUGaCoy2k1Tgz/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.turntuptikis.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3UcBMHnSTCXaxUbP6B96kHcED98DgEnNa9rGKzwXKMf4",
            "symbol": "PTR",
            "name": "PARTNER COIN",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/10572843/146142464-373cc98d-d147-4e68-84fc-f5e80bb67de6.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "partneroid",
                "description": "PTR is a community token for partnerships and collaborations. Visit OpenFren.com for more info.",
                "discord": "https://discord.gg/zjsScRb3nZ",
                "twitter": "https://twitter.com/openfren",
                "website": "https://openfren.com"
            }
        },
        {
            "chainId": 101,
            "address": "H18JF1LhJmNqqRCxc3JaWx4KZ4ACUYeARrr1XbGyzFiD",
            "symbol": "SunS",
            "name": "Sunflowers seeds",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H18JF1LhJmNqqRCxc3JaWx4KZ4ACUYeARrr1XbGyzFiD/logo.png",
            "tags": [
                "meme-token",
                "CATE",
                "NFT",
                "community"
            ],
            "extensions": {
                "website": "https://anasnejb.com"
            }
        },
        {
            "chainId": 103,
            "address": "FdGKBaPt6yg4VP2E2aicV2hTJY37cjnh4ouGKkTmL714",
            "symbol": "SNKC",
            "name": "SnakeChainCoin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/gowtham-k147/snkctoken/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "GzQ2JiAkyMDQuw5NGrhZzgcWCyAQdRGQSm9hQzCoLVKr",
            "symbol": "FITT",
            "name": "Fit Panda Test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GzQ2JiAkyMDQuw5NGrhZzgcWCyAQdRGQSm9hQzCoLVKr/logo.png",
            "tags": [
                "security-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AXUjVTpm2SWr2fjnQpJbLzy6dSGXPMTjPjWn4LxHjrKC",
            "symbol": "DR",
            "name": "doro Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AXUjVTpm2SWr2fjnQpJbLzy6dSGXPMTjPjWn4LxHjrKC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/meziane.allaoua19/",
                "telegram": "https://t.me/Allaouabusiness"
            }
        },
        {
            "chainId": 101,
            "address": "CKGZzeufghDK7Sekk4MnP34m1TuEkmhGU25rs6YGeHdP",
            "symbol": "XMAS",
            "name": "XMAS DAO",
            "decimals": 1,
            "logoURI": "https://i.postimg.cc/d1V9LHsz/L1032992.jpg",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://xmasdao.com"
            }
        },
        {
            "chainId": 101,
            "address": "8pfJr8MUHjS5tDVajmRCP7abEvEj3oYbYFR6f5jEhPH3",
            "symbol": "SLT",
            "name": "Soulé Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pfJr8MUHjS5tDVajmRCP7abEvEj3oYbYFR6f5jEhPH3/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FoygQKho2MmqyoYxSNx3KHe81kYPKFTCPdncwArUK4VP",
            "symbol": "KDX",
            "name": "Kodex",
            "decimals": 6,
            "logoURI": "https://kodingx.com/lib/logos/IconX-KodingX.png",
            "tags": [
                "DeFi",
                "DApp",
                "NFT",
                "community-token",
                "real-estate-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SyyHgAD9XD",
                "instagram": "https://www.instagram.com/xkoding/",
                "website": "https://www.kodingx.com"
            }
        },
        {
            "chainId": 101,
            "address": "SLRExi43rM1yJbwecLZ8AbBRdAcWaCExGLEu4uxPqmo",
            "symbol": "SLRE",
            "name": "Sollarion Energy",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLRExi43rM1yJbwecLZ8AbBRdAcWaCExGLEu4uxPqmo/logo.png",
            "tags": [
                "utility-token",
                "gaming",
                "metaverse",
                "nfts",
                "play-to-earn",
                "solana-ecosystem",
                "staking"
            ],
            "extensions": {
                "description": "SLRE tokens are built on the Solana blockchain. This token is important for in-game Forge.",
                "discord": "https://discord.com/invite/x2DcJ4uRd9",
                "instagram": "https://www.instagram.com/solanagalaxynft/",
                "twitter": "https://twitter.com/SolanaGalaxyNFT",
                "website": "https://sollarion.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4Fy8Abq9JTqg8bpTtgUpvxC2gocrbqRTUjTBwgHEjim1",
            "symbol": "STT",
            "name": "Start Tower Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Fy8Abq9JTqg8bpTtgUpvxC2gocrbqRTUjTBwgHEjim1/logo.png",
            "tags": [
                "starttower-token",
                "meta-verse"
            ],
            "extensions": {
                "twitter": "https://twitter.com/antobreizh",
                "website": "https://www.tiktok.com/@qwentosb?"
            }
        },
        {
            "chainId": 101,
            "address": "5SYkmkvQHPQ6EyqPjZYc4R4h5Az1avpArHL9vuPr99Bj",
            "symbol": "Squad",
            "name": "SolSquad",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SYkmkvQHPQ6EyqPjZYc4R4h5Az1avpArHL9vuPr99Bj/logo.png",
            "tags": [
                "DeFi",
                "community-token",
                "Meta",
                "nft",
                "metaverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/RNEPm5u9",
                "twitter": "https://twitter.com/solsquadmeta"
            }
        },
        {
            "chainId": 101,
            "address": "2qAWVzDrc49b4PD2wtMRMJN4d6cvgTtvANgsvoayvUid",
            "symbol": "NASTYA",
            "name": "Sweet Princess Nastya",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qAWVzDrc49b4PD2wtMRMJN4d6cvgTtvANgsvoayvUid/logo.jpg",
            "tags": [
                "friendship-token"
            ]
        },
        {
            "chainId": 101,
            "address": "X2y9KtLFWCRomutronoPoXDmcGrBoyYgMajTFH51EFG",
            "symbol": "RII",
            "name": "RII TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/X2y9KtLFWCRomutronoPoXDmcGrBoyYgMajTFH51EFG/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://www.terrestrialtrexclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EfCMgVD5tCvYH8AtYgJbDmB83X8dgxWTjuAtP7oui1Av",
            "symbol": "Mpet",
            "name": "Meta Pet Book",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EfCMgVD5tCvYH8AtYgJbDmB83X8dgxWTjuAtP7oui1Av/logo.png",
            "tags": [
                "Metaverse",
                "Pet",
                "NFTs",
                "NFT-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/9X4ZaNSmEX",
                "telegram": "https://t.me/+56ohaRsnGfcyNDdk",
                "twitter": "https://twitter.com/Metapetbook"
            }
        },
        {
            "chainId": 101,
            "address": "Cm3dsFkMtydExWib9pAxueaWhzMaZxJ5CWjMNT9tq2Eu",
            "symbol": "SCS",
            "name": "Super Cat Sol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cm3dsFkMtydExWib9pAxueaWhzMaZxJ5CWjMNT9tq2Eu/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SuperCatSol"
            }
        },
        {
            "chainId": 101,
            "address": "CCDju4Pf9gTZq2WkScnHnRX8oUqHn37wtp1gNBQPB5mP",
            "symbol": "BCY",
            "name": "BitcoinCity",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CCDju4Pf9gTZq2WkScnHnRX8oUqHn37wtp1gNBQPB5mP/logo.png",
            "tags": [
                "meme-token",
                "trading-token",
                "community-token",
                "bitcoincity"
            ]
        },
        {
            "chainId": 101,
            "address": "6r9ThcybVr3pbdyZHxUuABHNZJWtmcqZAYyK1HCs62fN",
            "symbol": "TEPS",
            "name": "Steps Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6r9ThcybVr3pbdyZHxUuABHNZJWtmcqZAYyK1HCs62fN/logo.png",
            "tags": [
                "walk-token",
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Fbw9wBafzQ",
                "twitter": "https://twitter.com/TheFrameboy",
                "website": "https://www.theframeboy.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2ADKYuqzwQwCKQtDb4fchL4Dxc8QZYcvrvTGGEGD6Ghh",
            "symbol": "YID",
            "name": "Yid Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ADKYuqzwQwCKQtDb4fchL4Dxc8QZYcvrvTGGEGD6Ghh/logo.png"
        },
        {
            "chainId": 101,
            "address": "6bBfw33QQiLL8qFadJCtXhY53t1q2cbmw8T4uFThK68p",
            "symbol": "SKC",
            "name": "Soul Skull",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6bBfw33QQiLL8qFadJCtXhY53t1q2cbmw8T4uFThK68p/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/UmbV22ZK88",
                "twitter": "https://twitter.com/skullcoolnft",
                "website": "https://skullcoolnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GWsZd8k85q2ie9SNycVSLeKkX7HLZfSsgx6Jdat9cjY1",
            "symbol": "PCN",
            "name": "Pollen Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWsZd8k85q2ie9SNycVSLeKkX7HLZfSsgx6Jdat9cjY1/logo.svg",
            "tags": [
                "utility-token",
                "gaming",
                "play-to-earn",
                "solana-ecosystem",
                "network"
            ],
            "extensions": {
                "description": "Pollen Coin is an SPL Token designed to help proliferate the Pollen Network"
            }
        },
        {
            "chainId": 101,
            "address": "trUmpZnz3uf4pHDwsVbdpEeMygiiVCpJq9ZgoWcaWBz",
            "symbol": "TRUMP",
            "name": "TrumpCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/trUmpZnz3uf4pHDwsVbdpEeMygiiVCpJq9ZgoWcaWBz/logo.png",
            "tags": [
                "meme-coin",
                "cowfefe"
            ]
        },
        {
            "chainId": 101,
            "address": "G6DqT7dogdEtqPq2usCy85GfGKpzcULHduAWEjuVN56E",
            "symbol": "THNKS",
            "name": "Thanks",
            "decimals": 4,
            "logoURI": "https://cdn.jsdelivr.net/gh/quinqu/thanks-token/logo/thanks.jpg",
            "extensions": {
                "description": "tytytytyty",
                "twitter": "https://twitter.com/softbt"
            }
        },
        {
            "chainId": 101,
            "address": "FZxUbyQ9oeFiSDaabw8KfAWACsDMQhbuxR9vm2Rh7Ewm",
            "symbol": "PHASE",
            "name": "PHASE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZxUbyQ9oeFiSDaabw8KfAWACsDMQhbuxR9vm2Rh7Ewm/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jVwHMvXfp9",
                "twitter": "https://twitter.com/phase_trade",
                "website": "https://phase.trade/"
            }
        },
        {
            "chainId": 101,
            "address": "9GSRxyiczjQvmeWA8mVoQS4bgrZzbpkG8hzQiZ9mhwuT",
            "symbol": "MAK",
            "name": "MAKOH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9GSRxyiczjQvmeWA8mVoQS4bgrZzbpkG8hzQiZ9mhwuT/logo.png",
            "tags": [
                "web3",
                "defi",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MakohToken"
            }
        },
        {
            "chainId": 101,
            "address": "C9QmRRaFdar7JBPD4hbKJnMBsHx2NMjf1oKS1CdZwZXa",
            "symbol": "KTSG",
            "name": "Kitty Sol Gang",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9QmRRaFdar7JBPD4hbKJnMBsHx2NMjf1oKS1CdZwZXa/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/pdVbz6yf",
                "twitter": "https://twitter.com/kittysolgang",
                "website": "https://kittysolgang.com"
            }
        },
        {
            "chainId": 101,
            "address": "6DTw9cra3RdWEJEnu7mCVE7rjxnXdV8qDa4StzuUcXcq",
            "symbol": "MAN",
            "name": "Maana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6DTw9cra3RdWEJEnu7mCVE7rjxnXdV8qDa4StzuUcXcq/logo.png",
            "tags": [
                "maana-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EGhBEcyJQBNopq7Ah83PXJNhSPYz4ELCTTNj3KWHC12r",
            "symbol": "SPRA",
            "name": "Sepira-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EGhBEcyJQBNopq7Ah83PXJNhSPYz4ELCTTNj3KWHC12r/logo.png",
            "tags": [
                "social-token",
                "sepira-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "CLimr85Hjy69xRQozkg5BiVHiqATYm2WMPhbqPPqY3Hs",
            "symbol": "CLIM",
            "name": "Climate",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLimr85Hjy69xRQozkg5BiVHiqATYm2WMPhbqPPqY3Hs/logo.png",
            "tags": [
                "climate-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Lib3nSXiss8HoXC9QQy97GkU1RX1n15XpeMhzLVjk7u",
            "symbol": "LIB",
            "name": "Freedom Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Lib3nSXiss8HoXC9QQy97GkU1RX1n15XpeMhzLVjk7u/logo.png",
            "tags": [
                "payment",
                "deflatory",
                "De-Fi",
                "capped",
                "Austrian",
                "LaizzesF"
            ]
        },
        {
            "chainId": 101,
            "address": "FKZh6fATukQHweUhCjL6i9vfQjQbfG1sWRzRmWx5U5Ee",
            "symbol": "DFHL",
            "name": "Delf-OHL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FKZh6fATukQHweUhCjL6i9vfQjQbfG1sWRzRmWx5U5Ee/logo.png",
            "tags": [
                "memme-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CoLaAnVnyzMo88o1iZs3sgKTct6BEdQPD7eYfZEz6KVG",
            "symbol": "COLA",
            "name": "CocaCola",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CoLaAnVnyzMo88o1iZs3sgKTct6BEdQPD7eYfZEz6KVG/logo.png",
            "tags": [
                "meme-coin",
                "utility-token",
                "thirst",
                "tooth-decay",
                "candida",
                "diabetes",
                "CVD",
                "NAFLD",
                "cancer"
            ]
        },
        {
            "chainId": 101,
            "address": "HzqyRGwBxRC9oVsAG4mPj9zL3s2GgcAi5h3QbATEp5fk",
            "symbol": "MICRO",
            "name": "MICRODOT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HzqyRGwBxRC9oVsAG4mPj9zL3s2GgcAi5h3QbATEp5fk/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RaversCrypto",
                "website": "https://www.crypto-ravers.com"
            }
        },
        {
            "chainId": 101,
            "address": "HxZ7hpjAfAxRqGNYGVxgHDqJm1RyjcQs6h6kYkD2UVEz",
            "symbol": "SCAM",
            "name": "ScamCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HxZ7hpjAfAxRqGNYGVxgHDqJm1RyjcQs6h6kYkD2UVEz/logo.png",
            "tags": [
                "meme-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/scamcoin43"
            }
        },
        {
            "chainId": 101,
            "address": "GW6NUnFwVdbmobcKdHoDKANMjZ5NojtgoPCfSWbQhcrQ",
            "symbol": "SLT",
            "name": "SteeLTv",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GW6NUnFwVdbmobcKdHoDKANMjZ5NojtgoPCfSWbQhcrQ/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://discord.gg/E4pVjeRehG"
            }
        },
        {
            "chainId": 101,
            "address": "5rFmh8C6Zj1VfL7ogB6PyVDnQkqsayvEPsEbbkUPX8f",
            "symbol": "SLCAT",
            "name": "Salad Cat Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5rFmh8C6Zj1VfL7ogB6PyVDnQkqsayvEPsEbbkUPX8f/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://saladcatcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "7tDaFkMdivNDyEhCFoXLk46eyjEPVJ58evdFqVojKTi1",
            "symbol": "UBER",
            "name": "TRANSPORT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tDaFkMdivNDyEhCFoXLk46eyjEPVJ58evdFqVojKTi1/logo.svg",
            "tags": [
                "transport-token",
                "delivery-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9RofDtXQWNtoEhBQjuVd9WucyW8ipUYbZ31CPsrqBGP1",
            "symbol": "PBZ",
            "name": "Pebblez",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/zibo123/Pebblez/pebblezlogo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/pebbleztoken"
            }
        },
        {
            "chainId": 101,
            "address": "BXrwYC5E8p3X2Zc2keWRrBJ3khpAQEiTBzKpSBE22Dy9",
            "symbol": "PTM",
            "name": "PotatoesMoon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXrwYC5E8p3X2Zc2keWRrBJ3khpAQEiTBzKpSBE22Dy9/logo.png",
            "tags": [
                "utility-token",
                "meme-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://www.youtube.com/c/lecarburodu63/"
            }
        },
        {
            "chainId": 101,
            "address": "NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk",
            "symbol": "BLOCK",
            "name": "Blockasset",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk/logo.png",
            "extensions": {
                "coingeckoId": "blockasset",
                "discord": "https://discord.com/invite/mynn9p6uNw",
                "telegram": "https://t.me/blockassetupdates",
                "twitter": "https://twitter.com/Blockassetco",
                "website": "https://blockasset.co"
            }
        },
        {
            "chainId": 101,
            "address": "H48ZPpo7CCLPTXMVZaftMnF6HCdFvzPehY9frzDHimTN",
            "symbol": "SHIBA-USDC",
            "name": "Shibalana LP Token (SHIBA-USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H48ZPpo7CCLPTXMVZaftMnF6HCdFvzPehY9frzDHimTN/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Wn6UYeQQGv",
                "telegram": "https://t.me/shibalana",
                "twitter": "https://twitter.com/shibalana",
                "website": "https://shibalana.com"
            }
        },
        {
            "chainId": 101,
            "address": "zmFoYNC3CuGY1VmgAcxXzfLMnSMVZpJF6RGJU5vDxvT",
            "symbol": "JUUJUU",
            "name": "JUUJUU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zmFoYNC3CuGY1VmgAcxXzfLMnSMVZpJF6RGJU5vDxvT/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.coolbabykaiju.com"
            }
        },
        {
            "chainId": 101,
            "address": "9vXwY4zS3Fs7Ej1gp6LtG32iRJbdKEKFnp35HnPew74o",
            "symbol": "PELF",
            "name": "PELF Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vXwY4zS3Fs7Ej1gp6LtG32iRJbdKEKFnp35HnPew74o/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://Pelfcoin.io"
            }
        },
        {
            "chainId": 101,
            "address": "GLxsZ4aMXNAq5XQ8wyp5cGPov3fajZVXeF6w8w4Pd58M",
            "symbol": "BWS",
            "name": "Bedrosova",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BedrosovaYulia/token-list/main/assets/mainnet/GLxsZ4aMXNAq5XQ8wyp5cGPov3fajZVXeF6w8w4Pd58M/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "1 hour of developer's work or consultation up to 1 hour",
                "website": "https://bedrosova.ru"
            }
        },
        {
            "chainId": 101,
            "address": "YodaXmvJfRMEecpYacvcvDEM3TCom6dVdFik4x8HyFe",
            "symbol": "YODA",
            "name": "YodaCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YodaXmvJfRMEecpYacvcvDEM3TCom6dVdFik4x8HyFe/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "3TaJVXPNLujB1ZCbLCxHJkwjTj8DfGc35GXtgmHpybs2",
            "symbol": "GENIAL",
            "name": "Genial4 Educational Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3TaJVXPNLujB1ZCbLCxHJkwjTj8DfGc35GXtgmHpybs2/logo.png",
            "tags": [
                "utility-token",
                "genial4"
            ],
            "extensions": {
                "website": "https://www.genial4.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6xcfmgzPgABAuAfGDhvvLLMfMDur4at7tU7j3NudUviK",
            "symbol": "FOSSIL",
            "name": "FOSSIL",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6xcfmgzPgABAuAfGDhvvLLMfMDur4at7tU7j3NudUviK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "fossil",
                "serumV3Usdc": "BHHvygk9aQ6P48uitEHk1eTGtXxX2Q9dh3W9U3VZhGWj",
                "twitter": "https://twitter.com/ShukutaiDinos",
                "website": "https://shukutaidinos.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GjYD3xQGJuk6xFzbkLx6ad3CiHbYJ6xwetwqNPMhvXmS",
            "symbol": "ELV",
            "name": "Elevator Beta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GjYD3xQGJuk6xFzbkLx6ad3CiHbYJ6xwetwqNPMhvXmS/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://www.elevator.biz"
            }
        },
        {
            "chainId": 101,
            "address": "4h41QKUkQPd2pCAFXNNgZUyGUxQ6E7fMexaZZHziCvhh",
            "symbol": "SUITE",
            "name": "The Suites Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4h41QKUkQPd2pCAFXNNgZUyGUxQ6E7fMexaZZHziCvhh/logo.png",
            "tags": [
                "NFT",
                "GAME"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheSuitesNFT",
                "website": "https://thesuites.app"
            }
        },
        {
            "chainId": 101,
            "address": "3pEGfQfY3mHmcy7euvTcYQy9bKw2UuNQwqgxUwwN6fFq",
            "symbol": "ZOODIACS",
            "name": "Zoodiac Tokens",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/DefiantDev/token-list/main/assets/mainnet/3pEGfQfY3mHmcy7euvTcYQy9bKw2UuNQwqgxUwwN6fFq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZoodiacsNFT",
                "website": "https://www.zoodiacs.io/"
            }
        },
        {
            "chainId": 103,
            "address": "AA1SM4BpEv9QRnWGeriD92HFegb3VXnKm3ENru7n2a4F",
            "symbol": "NEUM",
            "name": "Starlight coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AA1SM4BpEv9QRnWGeriD92HFegb3VXnKm3ENru7n2a4F/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://starlight.ooo"
            }
        },
        {
            "chainId": 101,
            "address": "CtC6uBe3QNNmBD8s9DLBnjT2gpN1uPNCb2PVPUDNTmHt",
            "symbol": "HILL",
            "name": "HillPhantom Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CtC6uBe3QNNmBD8s9DLBnjT2gpN1uPNCb2PVPUDNTmHt/logo.png"
        },
        {
            "chainId": 101,
            "address": "r8nuuzXCchjtqsmQZVZDPXXq928tuk7KVH479GsKVpy",
            "symbol": "DAOJONES",
            "name": "Fractionalized SMB-2367",
            "decimals": 2,
            "logoURI": "https://arweave.net/ncRp795w-ca_Xb5zkUBtBmxSQ_bcYA49E03NtMoHJMg",
            "tags": [
                "bridgesplit-fraction",
                "smb",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/fjrFpbweAP",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/markets/r8nuuzXCchjtqsmQZVZDPXXq928tuk7KVH479GsKVpy"
            }
        },
        {
            "chainId": 101,
            "address": "PEPSiFsZx6vvZvSqycBgCrqvGyhu31M1ytPc4gSsgYG",
            "symbol": "PEPSI",
            "name": "Pepsi tokens",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PEPSiFsZx6vvZvSqycBgCrqvGyhu31M1ytPc4gSsgYG/logo.png",
            "tags": [
                "thirst",
                "hunger",
                "obesity"
            ]
        },
        {
            "chainId": 101,
            "address": "J3ikF1EBSNCtcoUzLXr6HFLqhQTyyE3Pp3MwPth1joaf",
            "symbol": "BTCLAUNCH",
            "name": "BTCNFT LAUNCH DEC 5",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J3ikF1EBSNCtcoUzLXr6HFLqhQTyyE3Pp3MwPth1joaf/logo.png",
            "tags": [
                "btcnft",
                "btc",
                "nft"
            ],
            "extensions": {
                "website": "https://www.blockchain.com/btc/tx/f39100521ce727f109741e46c2789dbffffbda4422c4e99dd34549e06719cc10"
            }
        },
        {
            "chainId": 101,
            "address": "E48Ueg1o9avL5s7XBjfLViercSrNSJCvmbZMvnwN873",
            "symbol": "MCK",
            "name": "Mickey",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E48Ueg1o9avL5s7XBjfLViercSrNSJCvmbZMvnwN873/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.io/mickeycoin",
                "telegram": "https://t.me/mickeycoin_official",
                "twitter": "https://twitter.com/mickeycoin_sol",
                "website": "https://mickeycoins.com"
            }
        },
        {
            "chainId": 101,
            "address": "mETADas7y1WSAwkqM7Dar1Mq9LUJ8QSwzKmZccLTvTf",
            "symbol": "META",
            "name": "Metacoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mETADas7y1WSAwkqM7Dar1Mq9LUJ8QSwzKmZccLTvTf/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7HrUC7ucM7hr3QPJNhadXhuFtQhpY1sMppNJj1N9x1FY",
            "symbol": "KOALA",
            "name": "Koala Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7HrUC7ucM7hr3QPJNhadXhuFtQhpY1sMppNJj1N9x1FY/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/snft2022",
                "website": "https://solana-nft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "sHitufpUtFkrAGTNnhsoGaV7KhPNE4XHMp8PA5PuMwd",
            "symbol": "SHIT",
            "name": "Shitcoins",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sHitufpUtFkrAGTNnhsoGaV7KhPNE4XHMp8PA5PuMwd/logo.png",
            "tags": [
                "TheShit",
                "capped",
                "deflatory"
            ]
        },
        {
            "chainId": 101,
            "address": "kRitycGRGTXk4KM9ScNczrqgiVfz2DacrkgqwJmdGfm",
            "symbol": "KRIS",
            "name": "Krish Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kRitycGRGTXk4KM9ScNczrqgiVfz2DacrkgqwJmdGfm/logo.svg"
        },
        {
            "chainId": 101,
            "address": "CaShuGDxFr4NLRMcUR6vBxMMdjkc8W5ApYPyVdHDEZQM",
            "symbol": "CA$H",
            "name": "Money",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CaShuGDxFr4NLRMcUR6vBxMMdjkc8W5ApYPyVdHDEZQM/logo.png",
            "tags": [
                "payment",
                "capped",
                "deflatory",
                "De-fi"
            ]
        },
        {
            "chainId": 101,
            "address": "TwtRRGX2Lq3e5C71ix5gr1cn1CmnzmQGr4hrFMDbFFN",
            "symbol": "TWITR",
            "name": "Twitter-coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TwtRRGX2Lq3e5C71ix5gr1cn1CmnzmQGr4hrFMDbFFN/logo.png",
            "tags": [
                "Twitter-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "soUrSAYJo1Va5KGLhiqq2TbYwDMe8XXptZgyVLs5ud2",
            "symbol": "SOUND",
            "name": "SOUND tokens",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/soUrSAYJo1Va5KGLhiqq2TbYwDMe8XXptZgyVLs5ud2/logo.png",
            "tags": [
                "utility-token",
                "buy-music",
                "pay-performance",
                "De-Fi",
                "De-Mu"
            ]
        },
        {
            "chainId": 101,
            "address": "FMBfAxhiTwDmujiEGbexFtExHR9q7nqnRF1Rjd5UmhS7",
            "symbol": "BACON",
            "name": "BACON tokens",
            "decimals": 9,
            "logoURI": "https://zplphm7g437efyuli2psx6tmh4nf7ux6zf3iw65xnk5zjltaomca.arweave.net/y9bzs-bm_kLii0afK_psPxpf0v7Jdot7t2q7lK5gcwQ",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaBacon",
                "website": "https://swinegang.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CASHhd6REHunCFWUnwofYjHVEaYAmZT4qzeGxg73vVzL",
            "symbol": "Cash",
            "name": "Capital",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CASHhd6REHunCFWUnwofYjHVEaYAmZT4qzeGxg73vVzL/logo.png",
            "tags": [
                "payment",
                "capped",
                "deflatory",
                "De-Fi"
            ]
        },
        {
            "chainId": 101,
            "address": "4JdgGfKykyzZdKpCFaDSu6dKZ2ynAv6cqKX79banN4Ya",
            "symbol": "SLZR",
            "name": "Solzer",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4JdgGfKykyzZdKpCFaDSu6dKZ2ynAv6cqKX79banN4Ya/logo.svg",
            "tags": [
                "solzer",
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/AyQQ2N8dnD",
                "twitter": "https://twitter.com/SolzersOfficial",
                "website": "https://solzers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "maLen3sJGjSrWermkmjtD63jMwuDfD8kAZ6qtJ3JuYZ",
            "symbol": "MPT",
            "name": "Male Privelege Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/maLen3sJGjSrWermkmjtD63jMwuDfD8kAZ6qtJ3JuYZ/logo.png",
            "tags": [
                "underground-economy",
                "De-Fi",
                "anti-authoritarian"
            ]
        },
        {
            "chainId": 101,
            "address": "omiQMAY55K67aVCVQw9xhjV6swuXVbdTPTvQsWHRcun",
            "symbol": "OMI",
            "name": "Omikron",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/omiQMAY55K67aVCVQw9xhjV6swuXVbdTPTvQsWHRcun/logo.png",
            "tags": [
                "going-viral",
                "joy-of-dictators",
                "glee-of-politicians"
            ]
        },
        {
            "chainId": 101,
            "address": "FHK4riNxYRXTjCZxFK6a8V7tbvphVUfgtsEmySWP56L5",
            "symbol": "SHAI",
            "name": "ShaiCoin",
            "decimals": 9,
            "logoURI": "https://us.123rf.com/450wm/inkdrop/inkdrop1910/inkdrop191006626/132480841-police-de-texte-%C3%A0-effet-de-glitch-n%C3%A9on-d%C3%A9form%C3%A9e-de-la-lettre-s-rendu-3d.jpg?ver=6",
            "tags": [
                "utility-token",
                "Shai"
            ],
            "extensions": {
                "youtube": "https://www.youtube.com/channel/UCWOOi-1RPVdOxf-FY283FZA"
            }
        },
        {
            "chainId": 101,
            "address": "PuMpDwapgJU48tc1Nh5y4FBDZa8z5fuF9aUSa7wR3CP",
            "symbol": "PUMP",
            "name": "Pump it up",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PuMpDwapgJU48tc1Nh5y4FBDZa8z5fuF9aUSa7wR3CP/logo.png",
            "tags": [
                "pumping",
                "dumping"
            ]
        },
        {
            "chainId": 101,
            "address": "H3mRKonYi5pEi7eJfvQxwEYoHpV3qB2urZGtLfjk27NN",
            "symbol": "MLGO",
            "name": "MLGO tokens",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H3mRKonYi5pEi7eJfvQxwEYoHpV3qB2urZGtLfjk27NN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://mlgo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3vDfXEw3MZQgjYpLbjoZDYmgVci16CsC6ZDLgUzmcKR2",
            "symbol": "FLVR",
            "name": "Flokiverse",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/DaveBane/Flokiverse/Flokiverselogo.png",
            "tags": [
                "meme-token",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheFlokiverse",
                "website": "https://theflokiverse.com"
            }
        },
        {
            "chainId": 101,
            "address": "Dqo4Q2ku79MeyUXk8zcpiexhjMe1RgFQKrdpCN9VePJm",
            "symbol": "DBLN",
            "name": "Doublon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dqo4Q2ku79MeyUXk8zcpiexhjMe1RgFQKrdpCN9VePJm/logo.png",
            "tags": [
                "utility-token",
                "international-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/clement_aglr"
            }
        },
        {
            "chainId": 103,
            "address": "7SQq39bhiP45dwvmtEUpGqiddpycHjWQ6nzAM2sTJ9Bi",
            "symbol": "YOC",
            "name": "YOC token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7SQq39bhiP45dwvmtEUpGqiddpycHjWQ6nzAM2sTJ9Bi/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yWrsPP55pxTXWBnuReEd7B1gTFqLsfbhP3ZvnGCdrE4",
            "symbol": "BTDX",
            "name": "Bitadx Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yWrsPP55pxTXWBnuReEd7B1gTFqLsfbhP3ZvnGCdrE4/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/bitadx",
                "website": "https://www.bitadx.com"
            }
        },
        {
            "chainId": 101,
            "address": "JB9sPerGhfdwCDajmd8x4y2gkC4EtXVevoAoPwni39ik",
            "symbol": "ABP",
            "name": "ABP token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JB9sPerGhfdwCDajmd8x4y2gkC4EtXVevoAoPwni39ik/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9xEsFYFuJRPhLyZ6ysVHc8k7Z5LsCXhWHj5vm1DQd3Qp",
            "symbol": "GUNS",
            "name": "Cartel GUNS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xEsFYFuJRPhLyZ6ysVHc8k7Z5LsCXhWHj5vm1DQd3Qp/logo.png",
            "tags": [
                "meme-token",
                "utility-token",
                "stake-pool-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cartelofsolana",
                "twitter": "https://twitter.com/solcartel",
                "website": "https://www.solcartel.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FortJWoPT9EDfEdXUgoD9VdFzz5DARb6u5nfdLUZf8nK",
            "symbol": "FORT",
            "name": "Fortnite",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FortJWoPT9EDfEdXUgoD9VdFzz5DARb6u5nfdLUZf8nK/logo.png",
            "tags": [
                "game-token",
                "buy-sell",
                "get-paid"
            ]
        },
        {
            "chainId": 101,
            "address": "AdEnwi15gK4TsPncsyU8evgySt8tZ5vkugG29kz52GLS",
            "symbol": "SOLNUT",
            "name": "SOLANA NUT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AdEnwi15gK4TsPncsyU8evgySt8tZ5vkugG29kz52GLS/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Solana_Nut"
            }
        },
        {
            "chainId": 101,
            "address": "muSiCJtkftyEH5GicoF9D1Cc19qK5N7C9zEoeuUnAXn",
            "symbol": "MUSIC",
            "name": "Music",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/muSiCJtkftyEH5GicoF9D1Cc19qK5N7C9zEoeuUnAXn/logo.png",
            "tags": [
                "buy-sell-music",
                "pay-concerts",
                "get-paid",
                "support-musicians"
            ]
        },
        {
            "chainId": 101,
            "address": "314MsRJXmA4b4thSs8RReUvBiBwdZcTUL9GR8ndJLPgj",
            "symbol": "PI",
            "name": "3.14",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/314MsRJXmA4b4thSs8RReUvBiBwdZcTUL9GR8ndJLPgj/logo.png",
            "tags": [
                "Next-viral-token",
                "capped-supply"
            ]
        },
        {
            "chainId": 101,
            "address": "ELECstkptPdDmVcA7wzPifTqqhZBHqaVcPVevWZwqiep",
            "symbol": "ELEC",
            "name": "Election token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELECstkptPdDmVcA7wzPifTqqhZBHqaVcPVevWZwqiep/logo.png",
            "tags": [
                "utility-token",
                "national-elections",
                "local-elections",
                "safe-elections",
                "secure-elections",
                "blockchain-elections"
            ]
        },
        {
            "chainId": 101,
            "address": "8mYrpQbdcd4VrobcdcaqKLBHiKdaKEtMh5sgGbkXMird",
            "symbol": "LANDS",
            "name": "Lost Lands",
            "decimals": 4,
            "logoURI": "https://lostlands.club/lostlands_token.png",
            "tags": [
                "nft",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/c7Jk9a6GJK",
                "twitter": "https://twitter.com/LandsSol",
                "website": "https://lostlands.club"
            }
        },
        {
            "chainId": 101,
            "address": "C6F3XYB7Ny4uemJPkGUcaVfSWFn5jykG3RWH1CyhTTvr",
            "symbol": "RENY",
            "name": "RENY",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C6F3XYB7Ny4uemJPkGUcaVfSWFn5jykG3RWH1CyhTTvr/logo.png",
            "tags": [
                "community-token",
                "real-estate",
                "utility-token",
                "digital-nomad",
                "fractional-ownership"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RenyEstate",
                "website": "https://arealnomad.com"
            }
        },
        {
            "chainId": 101,
            "address": "mpczNCEv3ScNB5gGpBcdtda8MKw4BW55RbinrGCUNgK",
            "symbol": "MPC",
            "name": "Male Privilege Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mpczNCEv3ScNB5gGpBcdtda8MKw4BW55RbinrGCUNgK/logo.png",
            "tags": [
                "underground-economy",
                "De-Fi",
                "anti-authoritarian"
            ]
        },
        {
            "chainId": 101,
            "address": "k5Ybbtmnd1eAtBZoTqB9uzd24bVPz8Aip5EGVCJXQCM",
            "symbol": "sBTC",
            "name": "Solana Bitcoin",
            "decimals": 8,
            "logoURI": "https://i.ibb.co/SmxfhQT/sbtc.jpg",
            "tags": [
                "general"
            ]
        },
        {
            "chainId": 101,
            "address": "8cr7NKnPR1cTjfcQjiHUMyYdnrjyxXhDqXWpzwqGNZZk",
            "symbol": "SFL",
            "name": "SAFESOL",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8cr7NKnPR1cTjfcQjiHUMyYdnrjyxXhDqXWpzwqGNZZk/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/unhGGVXANW",
                "twitter": "https://twitter.com/safesolana"
            }
        },
        {
            "chainId": 101,
            "address": "4VJjP6PDuRJ6pXv73RCfihFsRatQDXsW1mzZyh6ADmiH",
            "symbol": "CANN",
            "name": "CannabisCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4VJjP6PDuRJ6pXv73RCfihFsRatQDXsW1mzZyh6ADmiH/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cannabiscoins_",
                "website": "https://www.cannabiscoin.space/"
            }
        },
        {
            "chainId": 101,
            "address": "DP37WVgLKyoTst3rcF3ZSfWsczoPtujRZ2kWmeBzXDdN",
            "symbol": "RDG",
            "name": "Craoutch",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DP37WVgLKyoTst3rcF3ZSfWsczoPtujRZ2kWmeBzXDdN/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/AyQQ2N8dnD",
                "twitter": "https://twitter.com/SolzersOfficial",
                "website": "https://solzers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Diss55mm6WhvBj5qHSsVbU6W9R4LyonMb3GLmj4bh3aF",
            "symbol": "DIS",
            "name": "Dissenter coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Diss55mm6WhvBj5qHSsVbU6W9R4LyonMb3GLmj4bh3aF/logo.png",
            "tags": [
                "self-thinking",
                "anti-authoritarian",
                "non-conformistic",
                "De-Fi",
                "capped"
            ]
        },
        {
            "chainId": 101,
            "address": "D18Vvg9PacxDK8FrGFijqrxc3QpF4dg5DDu4ff6SuAzP",
            "symbol": "GUARDS",
            "name": "GUARDS",
            "decimals": 4,
            "logoURI": "https://uw3arkapc6mahymsgieljx2isutp7c5lk5y7pufcucifobbwasdq.arweave.net/pbYIqA8XmAPhkjIItN9IlSb_i6tXcffQoqCQVwQ2BIc",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanaguards",
                "website": "http://guards.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FWFgveLWjyfUcmnquyMzQ7cyGRMUoWNfrQ31y3W2Ejbm",
            "symbol": "VST",
            "name": "Vesta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FWFgveLWjyfUcmnquyMzQ7cyGRMUoWNfrQ31y3W2Ejbm/logo.png",
            "tags": [
                "utility-token",
                "dex"
            ],
            "extensions": {
                "discord": "https://discord.io/vestadex",
                "telegram": "https://t.me/vesta_official",
                "twitter": "https://twitter.com/vesta_sol",
                "website": "https://vesta-dex.net"
            }
        },
        {
            "chainId": 101,
            "address": "32DSeHgkX64GcFNMjJXyRpG5eqoBVXJvdQpWyL1UgSrN",
            "symbol": "JJ",
            "name": "JuuJuuCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/32DSeHgkX64GcFNMjJXyRpG5eqoBVXJvdQpWyL1UgSrN/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://www.coolbabykaiju.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2Ete4fPkfRpgEzA8ypZUxVoiRAv8ZdrD9rZ6YA9b9m3j",
            "symbol": "$SSO",
            "name": "Solly Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Ete4fPkfRpgEzA8ypZUxVoiRAv8ZdrD9rZ6YA9b9m3j/logooo.png",
            "extensions": {
                "discord": "https://discord.gg/sollysolana",
                "twitter": "https://twitter.com/sollysolana"
            }
        },
        {
            "chainId": 101,
            "address": "RDRnT9rLYT2QRJpHwtKBAzJTQnPnnzJgCCqfeUP4Sf1",
            "symbol": "RDR",
            "name": "Red Dead Redemption",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RDRnT9rLYT2QRJpHwtKBAzJTQnPnnzJgCCqfeUP4Sf1/logo.png",
            "tags": [
                "game-coin",
                "pay",
                "get-paid"
            ]
        },
        {
            "chainId": 101,
            "address": "7ErrAxG4JHjrmohEu4gAjccdTBvc3ZxxLfxeBh1XkdNj",
            "symbol": "MICH",
            "name": "MichMich",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ErrAxG4JHjrmohEu4gAjccdTBvc3ZxxLfxeBh1XkdNj/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://github.com/Michenux/michmich-token"
            }
        },
        {
            "chainId": 101,
            "address": "J1inBU6NJahWE6NdSrR3cKr161ZmGdPsM7QTvHfNsD4J",
            "symbol": "CORP",
            "name": "Hound Tokens",
            "decimals": 9,
            "logoURI": "https://arweave.net/oVaTC19OqJlQvO8KR78ilL1XqTn29BSCisyvyHGrjSg?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/SaiboguC",
                "website": "https://www.saibogucorp.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda",
            "symbol": "N2H4",
            "name": "Hydrazine",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda/logo.png",
            "extensions": {
                "telegram": "https://t.me/StarLaunchOfficial",
                "twitter": "https://twitter.com/StarLaunchSOL",
                "website": "https://www.starlaunch.com/"
            }
        },
        {
            "chainId": 101,
            "address": "C3TJTA1NPMK1JsWzs7afYTTFnybVCXzwA6ZSe5ksx4Xw",
            "symbol": "ADR",
            "name": "AIR DROP",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C3TJTA1NPMK1JsWzs7afYTTFnybVCXzwA6ZSe5ksx4Xw/logo.png",
            "tags": [
                "air-drop-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "blog": "https://acomdir.com",
                "website": "https://jenka-nauta.com"
            }
        },
        {
            "chainId": 101,
            "address": "TR1PeRyej6G2KfX7bbYEVpdCQWaZrzkPGsoPU2cpZWx",
            "symbol": "TRIP",
            "name": "Trip Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TR1PeRyej6G2KfX7bbYEVpdCQWaZrzkPGsoPU2cpZWx/logo.png",
            "tags": [
                "DApp",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.tripprotocol.org/"
            }
        },
        {
            "chainId": 101,
            "address": "HgCCkxNtCJDPiHyi2STKbcNg74g7NrbTYep4JCFZzTBt",
            "symbol": "MMA",
            "name": "Metaverse Mining Alliance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HgCCkxNtCJDPiHyi2STKbcNg74g7NrbTYep4JCFZzTBt/logo_small.jpg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetaverseMining",
                "website": "https://mmagaming.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7WSpoEhi7zQB8t7Y5TqAdp83SMLExFh263cyJZR6BDKu",
            "symbol": "COE",
            "name": "VMW Hands on Team - Coin of Excellence",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/flashdumper/coe/master/logo.png",
            "tags": [
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://flashdumper.github.io"
            }
        },
        {
            "chainId": 101,
            "address": "TE1Eu2kGHetAeC6L89FhjDNBqdD4fGWmMVbengtr7Sj",
            "symbol": "TELE",
            "name": "Teleport",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TE1Eu2kGHetAeC6L89FhjDNBqdD4fGWmMVbengtr7Sj/logo.png",
            "tags": [
                "DApp",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.heyteleport.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5Virh6fFyNfrpZso5E7c7ANYyyJ45ADE4bq6AcQXTaRK",
            "symbol": "HELIOS",
            "name": "HELIOS PROJECT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Virh6fFyNfrpZso5E7c7ANYyyJ45ADE4bq6AcQXTaRK/logo.png",
            "tags": [
                "nft",
                "meta"
            ],
            "extensions": {
                "twitter": "https://twitter.com/helios_lab"
            }
        },
        {
            "chainId": 101,
            "address": "GYv7dD3cbsL8BHgp12wx34xtMwfS1C5j4MdsGQU5Gg4i",
            "symbol": "OIL",
            "name": "OIL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GYv7dD3cbsL8BHgp12wx34xtMwfS1C5j4MdsGQU5Gg4i/oil-barrel.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cyber_apes_nft"
            }
        },
        {
            "chainId": 101,
            "address": "EPae2UjdHnQiB6SaC8WJ2jMa7NJEMmuW9ZR4Z4yRMUhy",
            "symbol": "ANGRY",
            "name": "WhySoAngry",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/angryreindeers/tokenlogo/angry_coin.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/ZV4yhRUReP",
                "twitter": "https://twitter.com/Angry_Reindeers",
                "website": "https://www.angryreindeers.com/"
            }
        },
        {
            "chainId": 103,
            "address": "FURiApwZQoZmVR7xavw2ZdM14FHUVeeUbn9zo3ipLs52",
            "symbol": "FURIA",
            "name": "Furia Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FURiApwZQoZmVR7xavw2ZdM14FHUVeeUbn9zo3ipLs52/logo.png"
        },
        {
            "chainId": 101,
            "address": "sCsVEENGKrjh8S4sLr2pb8kn7mMbwasBBzhyT4w7DGv",
            "symbol": "SKATE",
            "name": "Skate Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sCsVEENGKrjh8S4sLr2pb8kn7mMbwasBBzhyT4w7DGv/logo.png",
            "extensions": {
                "discord": "https://discord.gg/QsJVtT3a8h",
                "twitter": "https://twitter.com/skatercatsoc",
                "website": "https://www.skatercatsociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6",
            "symbol": "GOD",
            "name": "GOD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6/logo.png",
            "extensions": {
                "discord": "https://discord.gg/4wvSFbkFqY",
                "twitter": "https://twitter.com/gods_sol",
                "website": "https://gods.so/"
            }
        },
        {
            "chainId": 101,
            "address": "HRyyRN2GY4yxrxCx5bekuEEKvFqztr42eVRY5UdkYWGf",
            "symbol": "MBM",
            "name": "Bonezz Inu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRyyRN2GY4yxrxCx5bekuEEKvFqztr42eVRY5UdkYWGf/logo.png"
        },
        {
            "chainId": 101,
            "address": "5DgD1XeBy5QU2C3uoD384LTA4jM3PuwEcqmbDKDh7XHs",
            "symbol": "IUSA",
            "name": "IUSA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5DgD1XeBy5QU2C3uoD384LTA4jM3PuwEcqmbDKDh7XHs/logo.png"
        },
        {
            "chainId": 101,
            "address": "43TzdKUmiMJGsnvcAz9qzSPb98V2gABcJDDagvK9ZcVF",
            "symbol": "CANNABIS",
            "name": "Cannabis Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43TzdKUmiMJGsnvcAz9qzSPb98V2gABcJDDagvK9ZcVF/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cannabiscoins_",
                "website": "https://www.cannabiscoin.space/"
            }
        },
        {
            "chainId": 101,
            "address": "6g1VPx4L1U69qkAcQyRh9b9UtsT7FboA4maeBb62fCY4",
            "symbol": "POT",
            "name": "PotCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6g1VPx4L1U69qkAcQyRh9b9UtsT7FboA4maeBb62fCY4/logo.png",
            "tags": [
                "WEED",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Potcoins_",
                "website": "https://www.potcoin.space/"
            }
        },
        {
            "chainId": 101,
            "address": "3wmg5g9hJC7oc6GPnoFGLDo1LnmDakvJw9WZL7HhPn4t",
            "symbol": "LUDU",
            "name": "LuduFi",
            "decimals": 9,
            "logoURI": "https://ludufi.io/ludufi.png",
            "tags": [
                "utility-token",
                "LUDU"
            ],
            "extensions": {
                "discord": "https://discord.gg/uZGdzzfGxd",
                "twitter": "https://twitter.com/LuduFi_",
                "website": "https://ludufi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DAt6azHcBFw4e5BDrVpnMcjmfgPsngaLspkbMbkFBWFN",
            "symbol": "THC",
            "name": "HempCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAt6azHcBFw4e5BDrVpnMcjmfgPsngaLspkbMbkFBWFN/logo.png",
            "tags": [
                "Marijuana",
                "Community-token",
                "Weed-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/hempcoinx",
                "website": "https://www.hempcoin.space/"
            }
        },
        {
            "chainId": 101,
            "address": "Cauqq1q5UrTqs3DEDme8oKagDfJgJsr1AqUmj3iq24N8",
            "symbol": "DOPE",
            "name": "DopeCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cauqq1q5UrTqs3DEDme8oKagDfJgJsr1AqUmj3iq24N8/logo.png",
            "tags": [
                "Cannabis",
                "Community-token",
                "WEEK"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thedopecoin",
                "website": "https://www.dopecoin.space/"
            }
        },
        {
            "chainId": 101,
            "address": "YtfMZ4jg2ubdz4GasY86iuGjHdo5rCPJnFqgSf8gxAz",
            "symbol": "CHB",
            "name": "Charactbit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6D4aGWykMymkFaeVzDS6MiH6QEujsHUze2pPGbJEyh7y/logo.png",
            "tags": [
                "MetaVerse",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/charactbit",
                "website": "https://www.charactbit.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9QBTKuSCDaJjtxYnYcVzoiKENMdJ5DRei5ZUCEeWyZnj",
            "symbol": "TRYB",
            "name": "BiLira (Portal)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9QBTKuSCDaJjtxYnYcVzoiKENMdJ5DRei5ZUCEeWyZnj/logo.png",
            "tags": [
                "stablecoin",
                "wormhole",
                "wrapped"
            ],
            "extensions": {
                "address": "0x2c537e5624e4af88a7ae4060c022609376c8d0eb",
                "assetContract": "https://etherscan.io/token/0x2c537e5624e4af88a7ae4060c022609376c8d0eb",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "github": "https://github.com/bilira-org",
                "instagram": "https://instagram.com/bilira_official",
                "telegram": "https://t.me/BiLira_Official",
                "website": "http://bilira.co"
            }
        },
        {
            "chainId": 101,
            "address": "6rzLw7uzkR76jXNMRR5pLAYjutsbET9avgBkpCUvojyW",
            "symbol": "BONGWEED",
            "name": "BongWeedCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6rzLw7uzkR76jXNMRR5pLAYjutsbET9avgBkpCUvojyW/logo.png",
            "tags": [
                "Cannabis",
                "Community-token",
                "BONGWEED"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bongweedcoins",
                "website": "https://www.bongweedcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FvHys7nD2kZMfk4DaNJwWTbQVdhFDkCP45tfsf5ZFgjX",
            "symbol": "SVRY",
            "name": "SVRY Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FvHys7nD2kZMfk4DaNJwWTbQVdhFDkCP45tfsf5ZFgjX/logo.png",
            "tags": [
                "utility-token",
                "family-token"
            ]
        },
        {
            "chainId": 103,
            "address": "ENhqgyaVeCWzR3AUhVTXDWmqhELFoigU9pdg6GfS9JAN",
            "symbol": "SDK",
            "name": "SDK-Dev",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENhqgyaVeCWzR3AUhVTXDWmqhELFoigU9pdg6GfS9JAN/logo.svg",
            "tags": [
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "ASHTTPcMddo7RsYHEyTv3nutMWvK8S4wgFUy3seAohja",
            "symbol": "$ASH",
            "name": "Mortuary Inc Ashes",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASHTTPcMddo7RsYHEyTv3nutMWvK8S4wgFUy3seAohja/logo.svg",
            "tags": [
                "NFT",
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DTdYZVhwne",
                "twitter": "https://twitter.com/MortuaryIncNFT",
                "website": "https://mortuary-inc.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EuHgNxKE99NNCZTv6djpbkRVTPMoqA1v93DTL2Thp1Hr",
            "symbol": "BIG",
            "name": "TBD Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EuHgNxKE99NNCZTv6djpbkRVTPMoqA1v93DTL2Thp1Hr/logo.png",
            "tags": [
                "Community-token"
            ],
            "extensions": {
                "description": "The BIG Deal is a blockchain based auction house for physical goods, digital assets, NFT's and more.",
                "github": "https://github.com/thebigdeals",
                "instagram": "https://www.instagram.com/thebigdeal_official",
                "linkedin": "https://www.linkedin.com/company/the-big-deals",
                "medium": "https://medium.com/@thebigdealofficial",
                "telegram": "https://t.me/+sWdEDZzdkSVlY2I1",
                "twitter": "https://twitter.com/thebigdeal_play",
                "website": "https://thebig.deals/"
            }
        },
        {
            "chainId": 101,
            "address": "JAienPrR8TgR4Bdo3UgxA9Kj1kB1JwuPkvcDpLfesruh",
            "symbol": "MMMT",
            "name": "mememetoken",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAienPrR8TgR4Bdo3UgxA9Kj1kB1JwuPkvcDpLfesruh/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mememenft"
            }
        },
        {
            "chainId": 101,
            "address": "DxcDecsWbXBy5S8phbRAMfNH8hJZWLwUkMug8JNT77d2",
            "symbol": "ATT",
            "name": "Attention Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DxcDecsWbXBy5S8phbRAMfNH8hJZWLwUkMug8JNT77d2/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DM8WStrzE7XHLr5EeuE8693VAqAx3MiTcAqfH6WcsSSC",
            "symbol": "KAKA",
            "name": "KAKA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DM8WStrzE7XHLr5EeuE8693VAqAx3MiTcAqfH6WcsSSC/logo.png",
            "tags": [
                "kaka-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6w6JEuFqkuCynQ4PnyE34BGBJCGC4diPTLgSy1grnjpJ",
            "symbol": "WSP",
            "name": "WinterSolePets",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6w6JEuFqkuCynQ4PnyE34BGBJCGC4diPTLgSy1grnjpJ/logo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "description": "NFT Holders They're Weekly / Monthly $ WSP Rewards Will Be Given Lifetime.",
                "discord": "https://discord.gg/3GS6DBSkbK",
                "medium": "https://medium.com/@WinterSolePets",
                "telegram": "https://t.me/WinterSolePets",
                "twitter": "https://twitter.com/WinterSolePets",
                "website": "https://www.wintersolepets.com"
            }
        },
        {
            "chainId": 101,
            "address": "BNDXucPySXoaHPqGP7tyn3xYVL9C7kkQoZqCLoT1fC5W",
            "symbol": "BNDX",
            "name": "Bondex",
            "decimals": 9,
            "logoURI": "https://arweave.net/uR-Niw7icxnt8N5E5rm0lGaI_g6nKxqSiO0tYtzAoTE",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "facebook": "https://m.facebook.com/Bondexcap/",
                "medium": "https://medium.com/@BondexCapital",
                "reddit": "https://www.reddit.com/u/Bondexcap/",
                "website": "https://www.bondex.app/"
            }
        },
        {
            "chainId": 101,
            "address": "CDFByd2FA8YTUM55bVZ4rEvKx814fEucHCMg92MxH4iW",
            "symbol": "BRSF",
            "name": "Brousouf",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CDFByd2FA8YTUM55bVZ4rEvKx814fEucHCMg92MxH4iW/logo.png",
            "tags": [
                "Gadz",
                "Community-token",
                "Brousouf",
                "Chalons"
            ]
        },
        {
            "chainId": 101,
            "address": "CANSqmUfQ9jfVXJP1ei7eeTw5odbdnHkFGq6GARq8ad8",
            "symbol": "$CANS",
            "name": "Homeless Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CANSqmUfQ9jfVXJP1ei7eeTw5odbdnHkFGq6GARq8ad8/logo.png",
            "tags": [
                "NFT-Token",
                "NFT-Project",
                "Meme-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/homeless_mouse",
                "website": "https://homelessmouse.io"
            }
        },
        {
            "chainId": 101,
            "address": "ARcAdExwxKEaTMKFjAWL17YespofK3a7poexuBvAPmrN",
            "symbol": "ARCADE",
            "name": "Arcade",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ARcAdExwxKEaTMKFjAWL17YespofK3a7poexuBvAPmrN/logo.png",
            "extensions": {
                "discord": "https://discord.gg/P98Zp5FYST",
                "twitter": "https://twitter.com/arcade2earn",
                "website": "https://www.arcade2earn.io/"
            }
        },
        {
            "chainId": 101,
            "address": "xARjQBdLR3q4vXh7DwbKVFg4fKJcfHk7aZaJTaByvhM",
            "symbol": "xARCADE",
            "name": "xArcade",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xARjQBdLR3q4vXh7DwbKVFg4fKJcfHk7aZaJTaByvhM/logo.png",
            "extensions": {
                "discord": "https://discord.gg/P98Zp5FYST",
                "twitter": "https://twitter.com/arcade2earn",
                "website": "https://www.arcade2earn.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AUTF8zA3uUMvaK18QAAwrfchzRHK9WdMnaJ2pu5GY68E",
            "symbol": "PNFT",
            "name": "PLUS NFT",
            "decimals": 2,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/PNFT/PNFT/PNTF.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Plussnft",
                "instagram": "https://www.instagram.com/plussnft",
                "twitter": "https://twitter.com/PlussNft",
                "website": "http://plusnft.io",
                "youtube": "https://www.youtube.com/channel/UCB25WKjPogsjtctYWZw93jw"
            }
        },
        {
            "chainId": 101,
            "address": "7eKVPDKxC6hd3hyrf8JczTme1g1PXXpoiD4qFZx83nrT",
            "symbol": "NINI",
            "name": "NIFTY NIBBAS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7eKVPDKxC6hd3hyrf8JczTme1g1PXXpoiD4qFZx83nrT/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4SzxkQvBSXdFjdRGEe14669qdkufp5eBSnia69uRaEFB",
            "symbol": "POPC",
            "name": "PopCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SzxkQvBSXdFjdRGEe14669qdkufp5eBSnia69uRaEFB/logo.png",
            "tags": [
                "MetaVerse",
                "nft-token",
                "token",
                "PopCoin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/OfficialPopCoin"
            }
        },
        {
            "chainId": 103,
            "address": "9B6Z1znWiJDhp6NwdVSidWizyGXRAXypfmmCtmQVgH8Y",
            "symbol": "FURIA",
            "name": "Furia Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9B6Z1znWiJDhp6NwdVSidWizyGXRAXypfmmCtmQVgH8Y/logo.png"
        },
        {
            "chainId": 101,
            "address": "4D2umdRkmjgsFj4Vf9foJGMkTjNQ41jXaGuAL3xb4dQj",
            "symbol": "FLX",
            "name": "Fluxx",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4D2umdRkmjgsFj4Vf9foJGMkTjNQ41jXaGuAL3xb4dQj/logo.svg",
            "tags": [
                "token"
            ],
            "extensions": {
                "website": "https://fluxx.webnode.sk/"
            }
        },
        {
            "chainId": 101,
            "address": "6c4L5nTH2sBKkfeuP3WhGp6Vq1tE4Suh4ezRp5KSu8Z7",
            "symbol": "FANI",
            "name": "FANITRADE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6c4L5nTH2sBKkfeuP3WhGp6Vq1tE4Suh4ezRp5KSu8Z7/logo.svg",
            "tags": [
                "fanitrade",
                "faniswap",
                "fanipool",
                "fanidex",
                "faniverse"
            ],
            "extensions": {
                "coingeckoId": "fanitrade",
                "coinmarketcap": "https://coinmarketcap.com/currencies/fanitrade",
                "instagram": "https://www.instagram.com/fanitrade/",
                "website": "https://fanitrade.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ENRkZoPMVKyxos91yji7dtqQ3SfYkLp2A1rNuaVE6G2p",
            "symbol": "ENR",
            "name": "Energy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENRkZoPMVKyxos91yji7dtqQ3SfYkLp2A1rNuaVE6G2p/logo.png",
            "tags": [
                "power-token",
                "wind",
                "solar",
                "nuclear",
                "coal",
                "electricity",
                "whatever-else"
            ]
        },
        {
            "chainId": 101,
            "address": "GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh",
            "symbol": "daoSOL",
            "name": "daoSOL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh/logo.png",
            "tags": [
                "stake-pool-token"
            ],
            "extensions": {
                "description": "daoSOL is the staking token issued by the MonkeDAO staking pool",
                "twitter": "https://twitter.com/MonkeDAO",
                "website": "https://monkedao.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D8AwdkJ6Kky97CPCxt8Em7otH6YER33zuNEDSQP8SEmz",
            "symbol": "NLI",
            "name": "nity lofi",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D8AwdkJ6Kky97CPCxt8Em7otH6YER33zuNEDSQP8SEmz/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/VCtbfspD",
                "instagram": "https://www.instagram.com/nity_lofi/",
                "website": "https://www.youtube.com/channel/UCP6vKUsfqpho8dV4vO4G93A"
            }
        },
        {
            "chainId": 101,
            "address": "UBhC118zKZRrVmkezncpf9wvXTLUpwwj5EUZkfNdhxs",
            "symbol": "WOLFIE",
            "name": "WOLFIE Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UBhC118zKZRrVmkezncpf9wvXTLUpwwj5EUZkfNdhxs/logo.png"
        },
        {
            "chainId": 101,
            "address": "61QabzGrvN3oYjSgkpoUXash6cucHkYgnnXBAtT5MdLS",
            "symbol": "HMG",
            "name": "Hamingja",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/61QabzGrvN3oYjSgkpoUXash6cucHkYgnnXBAtT5MdLS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HamingjaToken"
            }
        },
        {
            "chainId": 101,
            "address": "5idMFZTcT1xrhuf5vgeyF9cs5f43n6TiaDbZsrtC5zTv",
            "symbol": "SIU",
            "name": "SIU",
            "decimals": 9,
            "logoURI": "https://arweave.net/23nANpuvTnApbxoFgvXClZyeike1Jg88x8T6b2QXWYA?ext=png",
            "extensions": {
                "discord": "https://discord.gg/kuraininjas",
                "twitter": "https://twitter.com/KuraiNinjas"
            }
        },
        {
            "chainId": 101,
            "address": "7uuybREAsTt8epsnjrXbCUDcP6c9CKsnUEfDZr4MhEag",
            "symbol": "MOONVRS",
            "name": "Moonverse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uuybREAsTt8epsnjrXbCUDcP6c9CKsnUEfDZr4MhEag/logo.png",
            "tags": [
                "MetaVerse",
                "moon",
                "token",
                "moonverse"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/moonversetoken/",
                "twitter": "https://twitter.com/moonversetoken"
            }
        },
        {
            "chainId": 101,
            "address": "CYCLPDU3Pv4a12eKQd8iTXDJwb8Lc6AFp3mXrgcBhKFw",
            "symbol": "CYCL",
            "name": "Cyclic",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CYCLPDU3Pv4a12eKQd8iTXDJwb8Lc6AFp3mXrgcBhKFw/logo.png",
            "tags": [
                "market-cycle",
                "fashion-cycle",
                "day-cycle",
                "life-cycle",
                "all-is-cyclic"
            ]
        },
        {
            "chainId": 101,
            "address": "5YyVJfYwCbvuVVEWTvNLYWUkM7mkWNxFBhsb2YmisKrL",
            "symbol": "SLIME",
            "name": "Pixel Slime",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5YyVJfYwCbvuVVEWTvNLYWUkM7mkWNxFBhsb2YmisKrL/logo.png",
            "tags": [
                "Community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4tVnbp4KY8Ur6A9YQtZfs43r91LkFhdfAjot8wxw3uCF",
            "symbol": "PIXEL",
            "name": "NINJA-PIXEL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tVnbp4KY8Ur6A9YQtZfs43r91LkFhdfAjot8wxw3uCF/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NinjaProtocol",
                "twitter": "https://twitter.com/NinjaProtocol",
                "website": "https://ninjaprotocol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "rg3SH72WW9szTMVLUXamk4cNmLxRwdd5KM73vDMrtvK",
            "symbol": "ONI",
            "name": "ONI",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rg3SH72WW9szTMVLUXamk4cNmLxRwdd5KM73vDMrtvK/logo.png",
            "tags": [
                "GAMING",
                "NFT",
                "UTILITY"
            ],
            "extensions": {
                "discord": "https://discord.gg/NinjaProtocol",
                "twitter": "https://twitter.com/NinjaProtocol",
                "website": "https://ninjaprotocol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2bLBrov57Y78uZp9dcUBLRVZ49eSU843Bx3tccjrfSEN",
            "symbol": "DTM",
            "name": "DTMcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2bLBrov57Y78uZp9dcUBLRVZ49eSU843Bx3tccjrfSEN/logo.png",
            "tags": [
                "meme-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/YmMtHNm"
            }
        },
        {
            "chainId": 101,
            "address": "D3zwkVf2c1FNC7fW7tZixKjPLgaqhbm1cizKo332UDcm",
            "symbol": "KMDO",
            "name": "Komodo Dragonz",
            "decimals": 4,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/KMDO/logo/KMDO.png",
            "tags": [
                "Utility-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Komodo_Dragonz_",
                "website": "http://komododragonz.com"
            }
        },
        {
            "chainId": 101,
            "address": "38NBXKjUs5CM56E1nLfZXJgHLyob5pkYp1ERegqueQqy",
            "symbol": "SOLLESS",
            "name": "Solless Art Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38NBXKjUs5CM56E1nLfZXJgHLyob5pkYp1ERegqueQqy/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "SOLLESS"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sollessart"
            }
        },
        {
            "chainId": 101,
            "address": "EiNEYyUcPHpGt2btoMeuTrLtsAeayY74ECvPRYzcdPpo",
            "symbol": "ROYALE",
            "name": "Royale token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EiNEYyUcPHpGt2btoMeuTrLtsAeayY74ECvPRYzcdPpo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://miniroyale.io"
            }
        },
        {
            "chainId": 101,
            "address": "BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v",
            "symbol": "SBABYDOGE",
            "name": "SOL BABAY DOGE COIN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v/logo.png",
            "tags": [
                "MEME",
                "dogecoin",
                "meme-coin",
                "DOGE"
            ],
            "extensions": {
                "coingeckoId": "sol-baby-doge",
                "serumV3Usdc": "GCmfmqvNbY9LXWJ39fD8wNDn1HLj4sF2m9WAoNVVYaFt",
                "twitter": "https://twitter.com/sbabydogecoin",
                "website": "http://solbabydoge.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2J33fEqfphSio1DQ5kSxwMMNnRmVmK4SuHCVzSdBeCHM",
            "symbol": "NYANS",
            "name": "NYANS DOGES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nyansdoges/nyanstoken/main/nyans-token.png",
            "tags": [
                "NFT",
                "MEME"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NyansDoges",
                "website": "https://nyansdoges.com/"
            }
        },
        {
            "chainId": 103,
            "address": "3ZmQRcaKCmz9WF5L3noi6tZHhbY3ZmyujqyhTViWuffn",
            "symbol": "USDC-USDr",
            "name": "Raydium LP Token V4 (USDC-USDr)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "DJZUA9cQCtRiMH66tUJJNKR4RUmw7xZXhKnr7FGgiLKU",
            "symbol": "ETH-SOL",
            "name": "Raydium LP Token V4 (ETH-SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "8So1qJrk33QCrSaGGmPGxtzPbR3hkPqXjUJjgAbghRa1",
            "symbol": "ATLAS-RAY",
            "name": "Raydium LP Token V4 (ATLAS-RAY)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "3xAobruK6JiKnLDwjmBa3UqmqbiZK5YxQyvTYMNsiqFB",
            "symbol": "SAMO-RAY",
            "name": "Raydium LP Token V4 (SAMO-RAY)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5zQNcdfrexsVzaak53Shc5C3WQzbT8eKZU1be1nn9VZm",
            "symbol": "PP",
            "name": "PP Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5zQNcdfrexsVzaak53Shc5C3WQzbT8eKZU1be1nn9VZm/logo.png",
            "tags": [
                "community-token",
                "game-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/xHsjfEENFC",
                "twitter": "https://twitter.com/PossumPosseClub",
                "website": "https://www.possumposse.club/"
            }
        },
        {
            "chainId": 101,
            "address": "CtKP9sK6MHfQquhME6tyooc7LaztAN1pUBmHZT5RVQLR",
            "symbol": "ANT",
            "name": "Ant",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CtKP9sK6MHfQquhME6tyooc7LaztAN1pUBmHZT5RVQLR/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4tqCXeEtbGCzmav8QPiNeBZnh9p59HMbXYk8SW2z4Fj3",
            "symbol": "BNZO",
            "name": "BonzOhm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tqCXeEtbGCzmav8QPiNeBZnh9p59HMbXYk8SW2z4Fj3/logo.png",
            "tags": [
                "social-token",
                "nft",
                "lp-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/bonzos_nfts/",
                "twitter": "https://twitter.com/bonzos_nft",
                "website": "https://thebonzos.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "F1VM5fztZ62JyP2XjU2wwVvPF53RvnMdpSaG3YgcPiUV",
            "symbol": "DEVB",
            "name": "DEVB TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F1VM5fztZ62JyP2XjU2wwVvPF53RvnMdpSaG3YgcPiUV/logo.png",
            "tags": [
                "DEV"
            ],
            "extensions": {
                "website": "https://bigdickenergy.io/"
            }
        },
        {
            "chainId": 103,
            "address": "AEpC72EBDg5ZN8KXFZPB6bkTWqmYgGaDZ4m8ebyFLLrq",
            "symbol": "KPL",
            "name": "KAPPOLO TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEpC72EBDg5ZN8KXFZPB6bkTWqmYgGaDZ4m8ebyFLLrq/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kbEpp3ujqQnZsTmbWNvdoSN8BhqxguTB42RZyhnmKuk",
            "symbol": "HAROLD",
            "name": "Harold (Hide The Pain Harold)",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kbEpp3ujqQnZsTmbWNvdoSN8BhqxguTB42RZyhnmKuk/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9mK7QR9mAzdSysPEbNfmeb2EqKM5yrjy7hpfW3S96d13",
            "symbol": "TCV",
            "name": "Triple Cycle Void",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9mK7QR9mAzdSysPEbNfmeb2EqKM5yrjy7hpfW3S96d13/tcvtoken.jpg",
            "tags": [
                "tcv-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2bH4zPEedsVQufAwfzsXrydDbmNhvtu9DHEp8aN2f6kJ",
            "symbol": "LEX",
            "name": "Lexas",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2bH4zPEedsVQufAwfzsXrydDbmNhvtu9DHEp8aN2f6kJ/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/victor.dscp/",
                "twitter": "https://twitter.com/Lexas31789977"
            }
        },
        {
            "chainId": 101,
            "address": "CaTGDWtaY5hzDNja8FLNiAiQ3fZZaZ3wDjmeCPqYekam",
            "symbol": "Calindra",
            "name": "Calindra Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CaTGDWtaY5hzDNja8FLNiAiQ3fZZaZ3wDjmeCPqYekam/calindra.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AsQm9EwggKUe2s521mwAb92epQAZKnyiMUr8xuu23uCf",
            "symbol": "DMY",
            "name": "Dummyland",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsQm9EwggKUe2s521mwAb92epQAZKnyiMUr8xuu23uCf/logo.png",
            "tags": [
                "Governance-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/inviteWhApWVU8",
                "instagram": "https://www.instagram.com/dummylandai",
                "twitter": "https://twitter.com/dummylandai",
                "website": "https://dummyland.ai"
            }
        },
        {
            "chainId": 101,
            "address": "BDPkpgh2wF2G68o7GSaiodSQF65baW7pdYi76zAzWDA7",
            "symbol": "LNDAO",
            "name": "Lightning DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDPkpgh2wF2G68o7GSaiodSQF65baW7pdYi76zAzWDA7/logo.png",
            "tags": [
                "community-token",
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "81x8As53d4wuJDPAGfsJBkX8hZWXjrJDpgoBTeP5gZNu",
            "symbol": "AMN",
            "name": "Ameen",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/81x8As53d4wuJDPAGfsJBkX8hZWXjrJDpgoBTeP5gZNu/logo.png",
            "tags": [
                "Governance-token",
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/al__ameenjr",
                "twitter": "https://twitter.com/babaalameen7"
            }
        },
        {
            "chainId": 101,
            "address": "BGN9c9JJxMgmm7rUqeLanYwWwo2GbedjUFaXn7tAeuXK",
            "symbol": "Hono",
            "name": "Hono",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BGN9c9JJxMgmm7rUqeLanYwWwo2GbedjUFaXn7tAeuXK/hono.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DMkAp8aGEny6oEdWJNzwZPTL57biX6BwwGrzEF9bNRcS",
            "symbol": "LOOT",
            "name": "Loot Heroes",
            "decimals": 4,
            "logoURI": "https://lootheroes.io/lootcoin.png",
            "extensions": {
                "twitter": "https://twitter.com/lootheroesnft",
                "website": "https://lootheroes.io"
            }
        },
        {
            "chainId": 103,
            "address": "5qWoPqMYi7GQRbGNbEGKiQ4odG1VVmCPHKLQRhdeXqu5",
            "symbol": "LOOT",
            "name": "Loot Heroes",
            "decimals": 4,
            "logoURI": "https://lootheroes.io/lootcoin.png",
            "extensions": {
                "twitter": "https://twitter.com/lootheroesnft",
                "website": "https://lootheroes.io"
            }
        },
        {
            "chainId": 101,
            "address": "FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r",
            "symbol": "FANT",
            "name": "Phantasia",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r/logo.png",
            "tags": [
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "coingeckoId": "phantasia",
                "coinmarketcap": "https://coinmarketcap.com/currencies/phantasia/",
                "discord": "https://t.co/Vskz9PkBBC?amp=1",
                "github": "https://github.com/Phantasia-Sports",
                "medium": "https://medium.com/@phantasia",
                "twitter": "https://twitter.com/PhantasiaSports",
                "website": "https://phantasia.app/"
            }
        },
        {
            "chainId": 101,
            "address": "9Ksdsju1hgbfvTt8mR8h4NepRnEhjjqYjS9vALofwZAS",
            "symbol": "RBTC",
            "name": "Roboto",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Ksdsju1hgbfvTt8mR8h4NepRnEhjjqYjS9vALofwZAS/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/robotoio",
                "website": "http://www.roboto.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GG2Je65Rx55MreTBEFNfFFHPEXcmfGq2vuZVLDN2GXYk",
            "symbol": "ALTR",
            "name": "Altar Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GG2Je65Rx55MreTBEFNfFFHPEXcmfGq2vuZVLDN2GXYk/logo.png",
            "tags": [
                "Exchange",
                "DApp"
            ],
            "extensions": {
                "telegram": "https://t.me/altarfinanceCH",
                "website": "https://altar.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "54J1tQbQVVPvEcnDqCYNfMe2pEyWEWmix2cb3t1ENim8",
            "symbol": "BAMBOO",
            "name": "BAMBOO (Gorillaz Club)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54J1tQbQVVPvEcnDqCYNfMe2pEyWEWmix2cb3t1ENim8/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "nfts"
            ],
            "extensions": {
                "twitter": "https://twitter.com/gorillaz_nft",
                "website": "http://www.gorillaznft.com"
            }
        },
        {
            "chainId": 101,
            "address": "3aD7pEXJPVEXqAgksBxbRtFRGTWCXn9GuWCjKQxc3xsh",
            "symbol": "DEVT",
            "name": "DevTech45",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aD7pEXJPVEXqAgksBxbRtFRGTWCXn9GuWCjKQxc3xsh/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MneHecEKQJ",
                "twitter": "https://twitter.com/SirKingDev"
            }
        },
        {
            "chainId": 101,
            "address": "5VbDmpeTUgMZvybfaq43FmxH1Be5nkpy2BEDLZyvxuQD",
            "symbol": "FREE",
            "name": "Free Time Coin",
            "decimals": 8,
            "logoURI": "https://freetimecoin.com/images/freelogo.png",
            "tags": [
                "meme",
                "DApp"
            ],
            "extensions": {
                "twitter": "https://twitter.com/freetimecoin",
                "website": "https://freetimecoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6cUa7n3hXcDQNwKjqfVW8v6NG9B2HfU2h7orKF28b2x",
            "symbol": "RICH",
            "name": "The Rich DAO",
            "decimals": 8,
            "logoURI": "https://therichdao.com/images/richlogo.png",
            "tags": [
                "meme",
                "DApp"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheRichDAO",
                "website": "https://therichdao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CQKBCgPh8ievVBAZAuBC9K2LvDMbBFanqJwK9WcqYgXU",
            "symbol": "HGJ",
            "name": "Hamingja 2.0",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CQKBCgPh8ievVBAZAuBC9K2LvDMbBFanqJwK9WcqYgXU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HamingjaToken"
            }
        },
        {
            "chainId": 101,
            "address": "3WV4fTWGvtWNvQb8oVU4t99By8KztDLtExqHnkPfHAA9",
            "symbol": "PAW",
            "name": "CopyCats token",
            "decimals": 9,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/98c9b68b-1494-43d1-0c2c-31e5d0129a00/public",
            "extensions": {
                "discord": "http://discord.copycats.digital/",
                "twitter": "https://twitter.com/copycatssol",
                "website": "https://www.copycats.digital/"
            }
        },
        {
            "chainId": 101,
            "address": "FfmwaR8Vs7jc3i88sbNqnaf5XDqTozxSy13eK61bw2v6",
            "symbol": "PBLM",
            "name": "Pinke Blumen",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FfmwaR8Vs7jc3i88sbNqnaf5XDqTozxSy13eK61bw2v6/logo.png",
            "tags": [
                "meme-coin",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/itsfeellings"
            }
        },
        {
            "chainId": 101,
            "address": "HeQSLWpVaBTiiwX39gZ8Q1WcXxMpBgADv1spmvwkJB5F",
            "symbol": "SRAC",
            "name": "Solana Race",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HeQSLWpVaBTiiwX39gZ8Q1WcXxMpBgADv1spmvwkJB5F/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solana_race"
            }
        },
        {
            "chainId": 101,
            "address": "9eG37nGfZBPnRYUa9JM5qmgFZAzJTnWmN6EJRNpNPn9z",
            "symbol": "GRQ",
            "name": "Get Rich Quick token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/flashdumper/grq/master/grq.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://solscan.io/"
            }
        },
        {
            "chainId": 101,
            "address": "67kARmmNbhnwLbM61MKYaLC6oG31D3DrSXHFZGcg2Ehs",
            "symbol": "HOWDY",
            "name": "HOWDY DAO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/67kARmmNbhnwLbM61MKYaLC6oG31D3DrSXHFZGcg2Ehs/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/HowdyDao"
            }
        },
        {
            "chainId": 101,
            "address": "J8iu9djzKxxSY1s5inb7KDrka32iR3rF6rCDHGjafANf",
            "symbol": "OKAY",
            "name": "OKAY Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/thepbac/okaytoken/main/okay.png",
            "tags": [
                "eCommerce",
                "NFT",
                "community",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "website": "http://okayhouse.app"
            }
        },
        {
            "chainId": 101,
            "address": "AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1",
            "symbol": "BOT",
            "name": "Starbots Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1/logo.png",
            "tags": [
                "utility-token",
                "governance-token",
                "game-token"
            ],
            "extensions": {
                "coingeckoId": "starbots",
                "twitter": "https://twitter.com/Starbots_game",
                "website": "https://starbots.net"
            }
        },
        {
            "chainId": 101,
            "address": "HC9qZTgTYf12cFPaK3dK2HZJ9M47r2JenrsvQ1Ewnds8",
            "symbol": "C999",
            "name": "Cosmogol 999",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HC9qZTgTYf12cFPaK3dK2HZJ9M47r2JenrsvQ1Ewnds8/logo.svg",
            "tags": [
                "token"
            ],
            "extensions": {
                "github": "https://github.com/shadok-finance",
                "medium": "https://medium.com/@shadok.finance",
                "twitter": "https://twitter.com/shadokfinance",
                "website": "https://www.shadok.finance"
            }
        },
        {
            "chainId": 101,
            "address": "AxXoJZhSfeVUe3qgFZTt4NwQRJB61pBQAHTdWTN9PNms",
            "symbol": "KAYAC",
            "name": "Kayac",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AxXoJZhSfeVUe3qgFZTt4NwQRJB61pBQAHTdWTN9PNms/KAYAC_LOGO.png",
            "tags": [
                "utility-token",
                "governance-token",
                "game-token"
            ],
            "extensions": {
                "github": "https://github.com/GlyphBound",
                "twitter": "https://twitter.com/GlyphBound",
                "website": "https://www.glyph-bound.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6Dcr8QV9spfrrA6WNKnUrMrFWD8k7bcnj8wjpXaxufmR",
            "symbol": "PHD",
            "name": "PlantsHero Diamond",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Dcr8QV9spfrrA6WNKnUrMrFWD8k7bcnj8wjpXaxufmR/logo.png",
            "tags": [
                "utility-token",
                "Metaverse",
                "Gamefi"
            ],
            "extensions": {
                "website": "http://www.plantshero.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DqupStSzmSMvP44StQw7vpzrGWNa3qh7iN6HQsQWReNi",
            "symbol": "UMBR",
            "name": "Umbrella",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DqupStSzmSMvP44StQw7vpzrGWNa3qh7iN6HQsQWReNi/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EyvyHU1cbQufSYkoUe2nkSjQWBA36MaAZbvmtJMYoZDo",
            "symbol": "AVT",
            "name": "AVT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EyvyHU1cbQufSYkoUe2nkSjQWBA36MaAZbvmtJMYoZDo/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GRsn8fzybTYaiC8N4ZuYHZzcw5T6rN9FK1bpr6MQkKN7",
            "symbol": "SPLUNK",
            "name": "SPLUNK Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GRsn8fzybTYaiC8N4ZuYHZzcw5T6rN9FK1bpr6MQkKN7/logo.png",
            "tags": [
                "SPLUNKToken"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SplunkMeta"
            }
        },
        {
            "chainId": 101,
            "address": "9dS2MDzzt5Wiu3jLfFJ5z83iSjC2KirJg9vP536uHWDE",
            "symbol": "BMG",
            "name": "Battle Monster Game",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9dS2MDzzt5Wiu3jLfFJ5z83iSjC2KirJg9vP536uHWDE/Logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tr2ABHCAHQ",
                "twitter": "https://twitter.com/BattleMonster_",
                "website": "https://web.battlemonster.io/"
            }
        },
        {
            "chainId": 101,
            "address": "soL6gkvtJa7wtiqHXPZ2G6g2V9ZdT7GzFRJJPxTRS67",
            "symbol": "$SOLID",
            "name": "Solidarity Solutions",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/soL6gkvtJa7wtiqHXPZ2G6g2V9ZdT7GzFRJJPxTRS67/logo.png",
            "tags": [
                "DeFi",
                "DEX",
                "Farm",
                "Stake",
                "AMM",
                "ECO"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Solidarity2021",
                "website": "https://www.solidarity.solutions"
            }
        },
        {
            "chainId": 101,
            "address": "tsthEKwxAncDJJUUdLKNfS82Vm6Mk1M7hT9chKnq5C2",
            "symbol": "AIRBOX",
            "name": "AirBox Token",
            "decimals": 11,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tsthEKwxAncDJJUUdLKNfS82Vm6Mk1M7hT9chKnq5C2/logo.png",
            "tags": [
                "meme-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "blog": "https://jenka-nauta.com",
                "website": "http://www.airbox-token.acomdir.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1",
            "symbol": "CRWNY",
            "name": "Crowny token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1/logo.png",
            "extensions": {
                "coingeckoId": "crowny-token",
                "discord": "https://www.discord.gg/4JvMHrgNvv",
                "medium": "https://crowny.medium.com/",
                "serumV3Usdc": "H8GSFzSZmPNs4ANW9dPd5XTgrzWkta3CaT57TgWYs7SV",
                "telegram": "https://t.me/crownyofficial",
                "twitter": "https://twitter.com/crownyio",
                "website": "https://crowny.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2geYxMQ9o466tQ3JitUVR5Xmuk4a11KXs7ZmSX1hiSJp",
            "symbol": "McNoot",
            "name": "McNoot",
            "decimals": 9,
            "logoURI": "https://arweave.net/NyYZJt_ItdAhB3Jc4M4j8zrO_Ytz2RrSsuzqcp3YmZ8?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/littlenoots",
                "website": "https://www.littlenoots.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa",
            "symbol": "BASIS",
            "name": "basis",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "basis-markets",
                "description": "BASIS is basis.markets' fee-sharing token. Holders are able to stake their BASIS tokens to receive a proportional share of trading fee rewards generated by the basis.markets Decentralised Basis Liquidity Pool (DBLP). BASIS tokens also give access to a high-reward liquidity mining programme, as well as, discounted future deposits into the basis.markets DBLP.",
                "discord": "https://discord.gg/basismarkets",
                "medium": "https://basismarkets.medium.com/",
                "serumV3Usdc": "HsUNWR7ghHSumwDW3MNgs2HSh94yrDuZFVR1XpykA9or",
                "twitter": "https://twitter.com/basismarkets",
                "website": "https://basis.markets"
            }
        },
        {
            "chainId": 101,
            "address": "5bpYrWuERdLHJkgMoA3nrL98e3T3RV3sudgUPCWocirA",
            "symbol": "BASIS-USDC",
            "name": "Raydium Permissionless LP Token (BASIS-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5bpYrWuERdLHJkgMoA3nrL98e3T3RV3sudgUPCWocirA/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DjPt6xxMoZx1DyyWUHGs4mwqWWX48Fwf6ZJgqv2F9qwc",
            "symbol": "VOID",
            "name": "VOID Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DjPt6xxMoZx1DyyWUHGs4mwqWWX48Fwf6ZJgqv2F9qwc/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "coingeckoId": "void-games",
                "description": "VOID is a multiplayer online game with a strong focus on personalizing your character. Fight solo or in a small teams on epic on field battlegrounds.",
                "discord": "https://discord.gg/rzfe6k39aU",
                "facebook": "https://facebook.com/monkeyballgames",
                "instagram": "https://www.instagram.com/void_dot_games",
                "medium": "https://void-dot-games.medium.com",
                "telegram": "https://t.me/voidcommunitychat",
                "twitter": "https://twitter.com/void_dot_games",
                "website": "https://www.void.games"
            }
        },
        {
            "chainId": 101,
            "address": "8q8WUe4m13VjJq4Jta1NAapVSqMj1edhzDGF7z7UPEta",
            "symbol": "CLUB",
            "name": "Club Coin",
            "decimals": 0,
            "logoURI": "https://dmc-generator.s3.us-west-1.amazonaws.com/club_coin_ee389828e3.png",
            "tags": [
                "nft",
                "utility-token",
                "community"
            ],
            "extensions": {
                "discord": "https://discord.gg/YGyWWHWJyX",
                "twitter": "https://twitter.com/dogmonauts",
                "website": "https://moonclub.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "A97YQknPnoxPRRpnQdmxGkDzDzVAmN5Npo6R5oMWmrAG",
            "symbol": "TEST",
            "name": "TEST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F7mgxaYF1gg1hBtaVzENSG6ey3pn6J1mXhBRmHxDzBNg/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Z3iGPMSWWizHvyLsq1FcvoPempWK22UK6KoES6dHbSs",
            "symbol": "MAME",
            "name": "mameCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Z3iGPMSWWizHvyLsq1FcvoPempWK22UK6KoES6dHbSs/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/TcCZGu7HfA",
                "twitter": "https://twitter.com/mameCoin",
                "website": "https://mamecoin.id/"
            }
        },
        {
            "chainId": 101,
            "address": "Bn94VJuH9LtBfNsjWffGzfjfMewbQFL2B26493Evv6zz",
            "symbol": "SQRC",
            "name": "Squarecoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bn94VJuH9LtBfNsjWffGzfjfMewbQFL2B26493Evv6zz/logo.png",
            "tags": [
                "meme-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SquareheadzNFT",
                "website": "https://squareheadz.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "BA7Kht54DCCaNyoRG7Te2Ynbwxk3QJ9fnSCHLY8qJazF",
            "symbol": "DOJO",
            "name": "Dojo coin",
            "decimals": 6,
            "logoURI": "https://storage.googleapis.com/dojo-coin/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://storage.googleapis.com/dojo-coin/index.html"
            }
        },
        {
            "chainId": 101,
            "address": "GJQ1iDoPWWo7pXeNKhC9BLD3FHL2tgFEVGMHYGEfQZT",
            "symbol": "ALTAR",
            "name": "Altar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJQ1iDoPWWo7pXeNKhC9BLD3FHL2tgFEVGMHYGEfQZT/logo.png",
            "tags": [
                "Exchange",
                "DApp"
            ],
            "extensions": {
                "telegram": "https://t.me/altarfin",
                "twitter": "https://twitter.com/AltarFin",
                "website": "https://altar.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5shLzmEZo5rsqcEKs3z42xVdHSqrv9c8mqaoBbxBrcKi",
            "symbol": "PCRN",
            "name": "PopCorn Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5shLzmEZo5rsqcEKs3z42xVdHSqrv9c8mqaoBbxBrcKi/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HC3vmsaWD15wzw2V6EoXDtB2NL9Rruc7rjE65pPWubkK",
            "symbol": "ORBEM",
            "name": "Orbem",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HC3vmsaWD15wzw2V6EoXDtB2NL9Rruc7rjE65pPWubkK/logo.png",
            "tags": [
                "utility-token",
                "game-coin",
                "ORBEM",
                "Orbem-Coin",
                "Orbem-Token",
                "Duna-Games",
                "Zyon-Defenders"
            ],
            "extensions": {
                "discord": "https://discord.gg/f5GCcnZ7",
                "instagram": "https://www.instagram.com/zyondefenders/",
                "linkedin": "https://www.linkedin.com/company/duna-games",
                "reddit": "https://www.reddit.com/user/ZyonDefenders",
                "telegram": "https://t.me/joinchat/k4Rxha6DSzA2ODYx",
                "website": "http://zyondefenders.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2hdKbGbXiPHwyXdPyUCkAVuMEGfEu9eB1ampgYWBZpFy",
            "symbol": "DST",
            "name": "Dream Sanctuary Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2hdKbGbXiPHwyXdPyUCkAVuMEGfEu9eB1ampgYWBZpFy/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkRxg4Yrq92MAN5LCiQpH1DmsuZB3syUzWm2JobRFuP7",
            "symbol": "TALENTS",
            "name": "VURO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DkRxg4Yrq92MAN5LCiQpH1DmsuZB3syUzWm2JobRFuP7/logo.png",
            "tags": [
                "utility-token",
                "VURO"
            ],
            "extensions": {
                "description": "Workspaces in the metaverse",
                "instagram": "https://www.instagram.com/vuro.io/",
                "twitter": "https://twitter.com/Vuro_io",
                "website": "https://vuro.io"
            }
        },
        {
            "chainId": 101,
            "address": "74YedyBSKbjYzWMhwuBQz3mwsN6vuSSdAfzX9WLZQUtq",
            "symbol": "GNAR",
            "name": "GNAR",
            "decimals": 2,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/GNAR/logo/GNAR.png",
            "tags": [
                "Utility-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Komodo_Dragonz_",
                "website": "https://komododragonz.com"
            }
        },
        {
            "chainId": 101,
            "address": "EfiqH74NTt8U3PE5gDXWAwF8JHsYWsTzTeskH31D2Qon",
            "symbol": "CLB",
            "name": "CLUBB",
            "decimals": 2,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/CLB/logo/CLB.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Cllubbltd-103882295479000",
                "instagram": "https://www.instagram.com/cllubbltd",
                "twitter": "https://twitter.com/cllubbltd",
                "website": "http://cllubb.com/cclub"
            }
        },
        {
            "chainId": 101,
            "address": "ALP8mkba7FHrpn18hGMRURF1aRNS7P2y1SRzqE6ra3Zo",
            "symbol": "afUSDC-USDC",
            "name": "Saber afUSDC-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALP8mkba7FHrpn18hGMRURF1aRNS7P2y1SRzqE6ra3Zo/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/afusdc"
            }
        },
        {
            "chainId": 101,
            "address": "ALPDpWSYbwNkkuVB3wd1nZx7dZBLV7fEGvbDu9KJxLik",
            "symbol": "aeFEI-wtUST",
            "name": "Saber aeFEI-wtUST LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPDpWSYbwNkkuVB3wd1nZx7dZBLV7fEGvbDu9KJxLik/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/fei_ust"
            }
        },
        {
            "chainId": 101,
            "address": "ALPGFAuqQsWDB8NSKQ7rgQVhShj4LBCNC72ebdZrJs2e",
            "symbol": "afETH-weWETH",
            "name": "Saber afETH-weWETH LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPGFAuqQsWDB8NSKQ7rgQVhShj4LBCNC72ebdZrJs2e/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/afeth"
            }
        },
        {
            "chainId": 101,
            "address": "ALPaX3bS8zPKsVN6eS7Ln7dvEbDAfDtXsLz5pe2JRddq",
            "symbol": "aeFEI-CASH",
            "name": "Saber aeFEI-CASH LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPaX3bS8zPKsVN6eS7Ln7dvEbDAfDtXsLz5pe2JRddq/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/fei_cash"
            }
        },
        {
            "chainId": 101,
            "address": "ALPbh25PVwDDEhmJizhrtyhfgXNjh17RtbZc4i5ZAHdh",
            "symbol": "aeMIM-CASH",
            "name": "Saber aeMIM-CASH LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPbh25PVwDDEhmJizhrtyhfgXNjh17RtbZc4i5ZAHdh/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/mim_cash"
            }
        },
        {
            "chainId": 101,
            "address": "ALPfb7HJd4oenNBknjzCbVc2RooC5N1H6N391hbZ82ky",
            "symbol": "aeMIM-wtUST",
            "name": "Saber aeMIM-wtUST LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPfb7HJd4oenNBknjzCbVc2RooC5N1H6N391hbZ82ky/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/mim_ust"
            }
        },
        {
            "chainId": 101,
            "address": "ALPoQFUuKsoN6P4gLBiKXMSaYFP9YChniKwvKAFRPvn5",
            "symbol": "afBTC-renBTC",
            "name": "Saber afBTC-renBTC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPoQFUuKsoN6P4gLBiKXMSaYFP9YChniKwvKAFRPvn5/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/afbtc"
            }
        },
        {
            "chainId": 101,
            "address": "ALPqczrbEXS8k5JF69tEPyu6TTE8qJbiwrzADfjVfKov",
            "symbol": "afDAI-USDC",
            "name": "Saber afDAI-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPqczrbEXS8k5JF69tEPyu6TTE8qJbiwrzADfjVfKov/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/afdai"
            }
        },
        {
            "chainId": 101,
            "address": "UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW",
            "symbol": "swtUST-9",
            "name": "Saber Wrapped UST (Portal) (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW/icon.png",
            "tags": [
                "wrapped",
                "wormhole",
                "saber-mkt-usd",
                "wormhole-v2",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "address": "uusd",
                "bridgeContract": "https://finder.terra.money/columbus-5/address/terra10nmmwe8r3g99a9newtqa7a75xfgs2e8z87r2sf",
                "coingeckoId": "terra-usd",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "WLP7MDjSKGWxs2s6o2d8JFvvqLJD8KHZpcTAZf9ongE",
            "symbol": "weSRM-SRM",
            "name": "Saber weSRM-SRM LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLP7MDjSKGWxs2s6o2d8JFvvqLJD8KHZpcTAZf9ongE/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wsrm"
            }
        },
        {
            "chainId": 101,
            "address": "WLP9nwD2FUJyNeKWGEi8QnF1a5G3VC7zM9uCqE1W8tx",
            "symbol": "wibBTC-renBTC",
            "name": "Saber wibBTC-renBTC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLP9nwD2FUJyNeKWGEi8QnF1a5G3VC7zM9uCqE1W8tx/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/wibbtc"
            }
        },
        {
            "chainId": 101,
            "address": "WLP9zHZ3FcsPAcM891AQBn4ZWyS3wYgddiLPbSyzmDm",
            "symbol": "weHBTC-renBTC",
            "name": "Saber weHBTC-renBTC LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLP9zHZ3FcsPAcM891AQBn4ZWyS3wYgddiLPbSyzmDm/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/whbtc"
            }
        },
        {
            "chainId": 101,
            "address": "AvfTV1GztEciuYWsbJ2bDxxcPt1ZbyLe6tMPk7bmw7WM",
            "symbol": "MFTK",
            "name": "Macfor Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AvfTV1GztEciuYWsbJ2bDxxcPt1ZbyLe6tMPk7bmw7WM/Logo.png",
            "extensions": {
                "website": "https://macfor.com.br/"
            }
        },
        {
            "chainId": 101,
            "address": "4Y4z7at4WxCFs9TgVn5xJ6twMuyuRwSUyrUJoSea4GYP",
            "symbol": "MIN",
            "name": "MIN Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Y4z7at4WxCFs9TgVn5xJ6twMuyuRwSUyrUJoSea4GYP/logo.png",
            "extensions": {
                "website": "https://shopee.tw/minpick"
            }
        },
        {
            "chainId": 101,
            "address": "EUrd5Mxa3UsREEPk3Zi6NvoJxrAN1MjxLTm1Vz3v8hk8",
            "symbol": "DOWA",
            "name": "Destiny of War Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EUrd5Mxa3UsREEPk3Zi6NvoJxrAN1MjxLTm1Vz3v8hk8/logo.png",
            "extensions": {
                "website": "https://destinyofwar.com/"
            }
        },
        {
            "chainId": 101,
            "address": "D2DWXG6oDhywmYeFXQy3pCkLp7RKiwuoSZgjhNSqx14N",
            "symbol": "PIOLY",
            "name": "Pioly Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D2DWXG6oDhywmYeFXQy3pCkLp7RKiwuoSZgjhNSqx14N/logo.png",
            "extensions": {
                "website": "https://destinyofwar.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6cKnRJskSTonD6kZiWt2Fy3NB6ZND6CbgA3vHiZ1kHEU",
            "symbol": "ppUSDC",
            "name": "Port Finance 2022 March Principal USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg",
            "tags": [
                "port",
                "lending"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "B64haiHLQoWdrvcJqufRG5dEMms96rDpwuaTjYTihQEo",
            "symbol": "pyUSDC",
            "name": "Port Finance 2022 March Yield USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg",
            "tags": [
                "port",
                "lending"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "J7WYVzFNynk9D28eBCccw2EYkygygiLDCVCabV7CupWL",
            "symbol": "MONGOOSE",
            "name": "Mongoose Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J7WYVzFNynk9D28eBCccw2EYkygygiLDCVCabV7CupWL/logo.png",
            "tags": [
                "MEME",
                "Community-token",
                "Mongoose"
            ],
            "extensions": {
                "coingeckoId": "mongoosecoin",
                "twitter": "https://twitter.com/mongoosescoin",
                "website": "https://www.mongoosecoin.space/"
            }
        },
        {
            "chainId": 101,
            "address": "AHGXtqajWiph31bh5y3MfSK4Bo6JGyt3xpveEZ41ZLaX",
            "symbol": "ARAB",
            "name": "ARAB MONEY",
            "decimals": 9,
            "logoURI": "https://rawcdn.githack.com/arabpunkz/oillogo/8f141fb5d74220e3c8a5261508847b716a1b5b4b/arab.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/4GS8A4VQWR",
                "twitter": "https://twitter.com/arabpunkz",
                "website": "https://arabpunkz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CooL7VvP1BoSmnPDTXUnKQgRo1tMUo9mHfmwMWn4sm9F",
            "symbol": "$COOL",
            "name": "COOLTURA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CooL7VvP1BoSmnPDTXUnKQgRo1tMUo9mHfmwMWn4sm9F/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/danipass0s",
                "website": "https://cooltura.wtf"
            }
        },
        {
            "chainId": 101,
            "address": "kti1UbBFcCZbfccmGE57W9f6Byy5EY9YLLdsecs3UHa",
            "symbol": "BUTTER",
            "name": "Infamous Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kti1UbBFcCZbfccmGE57W9f6Byy5EY9YLLdsecs3UHa/logo.jpg",
            "tags": [
                "utility-token",
                "NFTs",
                "Infamous-Thugbirdz"
            ],
            "extensions": {
                "website": "https://www.infamous-thugbirdz.com"
            }
        },
        {
            "chainId": 101,
            "address": "3rj25VfGX89DXMBTjghcpszibzx88NidMbUy2oHHKgSs",
            "symbol": "BREAD",
            "name": "Bread",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kti1UbBFcCZbfccmGE57W9f6Byy5EY9YLLdsecs3UHa/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.infamous-thugbirdz.com"
            }
        },
        {
            "chainId": 101,
            "address": "Bq3N5VVipKZBqvQzz7tnzQGGU4kNdJaq3ZBgQncU7bLU",
            "symbol": "TURF",
            "name": "Turf",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kti1UbBFcCZbfccmGE57W9f6Byy5EY9YLLdsecs3UHa/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.infamous-thugbirdz.com"
            }
        },
        {
            "chainId": 101,
            "address": "FCnc2S8Rk517EpobQX94SyUr3YDqHDiamWFFzQGKTKhp",
            "symbol": "GVIBE",
            "name": "Good Vibe",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCnc2S8Rk517EpobQX94SyUr3YDqHDiamWFFzQGKTKhp/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5fibbme9FE1ttgaMHjKnt529va9Pf9oYEjJLU9F3G3s4",
            "symbol": "AMBR",
            "name": "AMBROSIA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fibbme9FE1ttgaMHjKnt529va9Pf9oYEjJLU9F3G3s4/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "Ambrosians",
                "AMBROSIA"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ambrosiansnft",
                "website": "https://ambrosians.io"
            }
        },
        {
            "chainId": 101,
            "address": "A7rqejP8LKN8syXMr4tvcKjs2iJ4WtZjXNs1e6qP3m9g",
            "symbol": "Zion",
            "name": "Zion",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/LousyNFTs/token-list/main/assets/mainnet/A7rqejP8LKN8syXMr4tvcKjs2iJ4WtZjXNs1e6qP3m9g/Ziontoken_2_84.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "zion",
                "discord": "http://discord.gg/catcartel",
                "twitter": "https://twitter.com/SolanaCatCartel",
                "website": "https://catcartel.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9kuK9j3HE33MAx3CDtbGQ9qg1cteQHNsRUV4i7JUh2bX",
            "symbol": "CHARM",
            "name": "Lucky Charm",
            "decimals": 8,
            "logoURI": "https://7rb7bdnwxw62kj4c7hle5pxatck5eycsl2tx4tlizgmlmjssdxna.arweave.net/_EPwjba9vaUngvnWTr7gmJXSYFJep35NaMmYtiZSHdo",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "CHARM is the token that allows the holder to influence the likelihood of a desirable outcome in random events in the metaverse",
                "discord": "https://discord.gg/YGTJU6qzqK",
                "twitter": "https://twitter.com/alleyesotprice",
                "website": "https://www.alleyesontheprice.com"
            }
        },
        {
            "chainId": 101,
            "address": "Q7y93vgxVdwgn2D9TZkJqfML67dTVWeyGsQdPyfBzoN",
            "symbol": "NICE",
            "name": "Nice Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Q7y93vgxVdwgn2D9TZkJqfML67dTVWeyGsQdPyfBzoN/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GD57bW3qQaqk8HkbWrFV3t4tSAeJsjngFRtYwbF1wZhZ",
            "symbol": "KLUB",
            "name": "Koala Klubhouse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GD57bW3qQaqk8HkbWrFV3t4tSAeJsjngFRtYwbF1wZhZ/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/koalaklubhouse",
                "website": "https://www.koalaklubhouse.art/"
            }
        },
        {
            "chainId": 101,
            "address": "Fv3ZG56M2cWvF8sy9VWzWyvtHPhugNc1BAzpyoAPvL7r",
            "symbol": "SOLNUT",
            "name": "Solana Nut",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fv3ZG56M2cWvF8sy9VWzWyvtHPhugNc1BAzpyoAPvL7r/logo.png",
            "tags": [
                "meme-token",
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/solananut",
                "medium": "https://medium.com/@nutsolana",
                "serumV3Usdc": "CUttKiPJyu3dsfNwzaEHusGqY36qNkuWcZrVGrnPe9KQ",
                "twitter": "https://twitter.com/Solana_Nut",
                "website": "https://solananut.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FiFxBfTSqcz6vWRbp5TKbyhgfkXauSfHiRwxKqMcbuNA",
            "symbol": "FIF",
            "name": "Fifsee",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FiFxBfTSqcz6vWRbp5TKbyhgfkXauSfHiRwxKqMcbuNA/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/fifseeofficial",
                "website": "https://www.fifsee.com"
            }
        },
        {
            "chainId": 101,
            "address": "2FkjWQNwKC2mW9cdyYs9umVU58cJAFSig7wcxShTowST",
            "symbol": "AUTH",
            "name": "Auth Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FkjWQNwKC2mW9cdyYs9umVU58cJAFSig7wcxShTowST/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D9mWdqrs7bpXEcTpocadL2kPWdAJQfMqWbSsxzT2L8qN",
            "symbol": "CERTIDOX",
            "name": "CertiDox Token",
            "decimals": 9,
            "logoURI": "https://certidox.com/assets/images/logo-light.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://certidox.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CeLAKJWAhNNixku9eLRB3CL9oB6eRE4z2mU6kGdgTgw",
            "symbol": "BROCK",
            "name": "Brock Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CeLAKJWAhNNixku9eLRB3CL9oB6eRE4z2mU6kGdgTgw/logo.png",
            "tags": [
                "meme-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "91AmTdeHp458JAkkNzVa2gjGnA7tqHAMyuqVG2TauRJU",
            "symbol": "RAG",
            "name": "RAGNAROK",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/91AmTdeHp458JAkkNzVa2gjGnA7tqHAMyuqVG2TauRJU/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "Game",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "B1nrnT8LvkxqJFw3A9tWoXCpbLUNKYkn8gW8qYZoTRaN",
            "symbol": "COBRA",
            "name": "Cobra Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B1nrnT8LvkxqJFw3A9tWoXCpbLUNKYkn8gW8qYZoTRaN/logo.png",
            "tags": [
                "COBRA",
                "Community-token",
                "MEME"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cobracoin_",
                "website": "https://www.cobracoin.info/"
            }
        },
        {
            "chainId": 101,
            "address": "BECBejLA8KGEzXKvfCwyuFFfeiyhBYxULF4MhVdyduQ5",
            "symbol": "HONEY",
            "name": "HoneyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LeonardHRonan/honeycoin/main/logo%202.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ea2t1QJFvpFueiju9qgtJX5dXjEt7PjQ2KNrWZA7bkSL",
            "symbol": "PNDC",
            "name": "PandaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ea2t1QJFvpFueiju9qgtJX5dXjEt7PjQ2KNrWZA7bkSL/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "je2RGLazSDwyG4fReN7endwmM472aotoETMuGscrUDF",
            "symbol": "NOP",
            "name": "Napkin Of Power Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/je2RGLazSDwyG4fReN7endwmM472aotoETMuGscrUDF/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CSwXHpHP4Gwg1qXSjR45zmNaVNxSkVf8qy3PCNR45XTL",
            "symbol": "AZMD",
            "name": "AZMD Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Magic-Deceiver/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9QXAu7FTf7hmswBQwKxvuqGgWH42FyQjqXJanUt6y4eC",
            "symbol": "MOUNT",
            "name": "MOUNT",
            "decimals": 9,
            "logoURI": "https://arweave.net/fy9pbbRbYRg-Y40B34ojIGrb3wxpadQgsiktan1LfKY?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/MetaMounts",
                "website": "https://metamounts.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GNAvTeFYjQ3BGgzAy1VThHLj9GxXCz8QmocfYpUbARca",
            "symbol": "CRDK",
            "name": "Conrad Duke",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crazyclimber73/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DEbfwv4einBrcPbQsymvHctbzcdZZE9y9sHMTTirTJmo",
            "symbol": "CKING",
            "name": "Crypto King Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CKSLLC/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GjCHuYuccKrjugBMmYYC17W42zNHNB7XeSxNCXZeetS4",
            "symbol": "GORD",
            "name": "Gordon Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GjCHuYuccKrjugBMmYYC17W42zNHNB7XeSxNCXZeetS4/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DTRZj3UpNdRZYDNybwo8QWiF1sRLta9gjWv4vhLMW62P",
            "symbol": "CM",
            "name": "CashMoney",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hhannis/digitalassets/main/cashmoney.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EmrbjdPbxvVjgU412w8FeLQSC354d3vPLcp1y3WNqq84",
            "symbol": "COCKS",
            "name": "GAMECOCKS COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stsomeware/gamecockcoin/main/gamecock.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FLB6MZMSNtYzL9dJfbfuZwP9hkTrGV3UGiGFuCj1SChn",
            "symbol": "GCTK",
            "name": "GruntCode Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gruntcode/crypto/main/gclogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Ut9K1ntBd8qu8kihDUtEfUPuiujg5eGdCyHHdgAYyPh",
            "symbol": "TXBY",
            "name": "TxByte Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/txbytecoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HdmqgX1u1TzeJFw62UGi3NAvbrBqBM39Vg7WRFDKexHo",
            "symbol": "BUSCH",
            "name": "Busch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheFortuitous/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DUpCdjkkKcaCvVeWNxxUajLLNmcoZ8qdohtq42RnR9rx",
            "symbol": "MRC",
            "name": "MerlinoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/timo42453189/crypto/main/logo.png",
            "tags": [
                "cat-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4WZQUN8Kt4UE1L7L5XhdffTDL1cbPKUzFakXyv59GP84",
            "symbol": "FAJW",
            "name": "Faj Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hgoudat/fajwahcoin/main/faj-color-square.png",
            "extensions": {
                "website": "https://www.fajwah.com"
            }
        },
        {
            "chainId": 101,
            "address": "5JaZgVn2LuKmRyBMhcfydq6L3dxmZhySeTE7rePYbH3k",
            "symbol": "RLAB",
            "name": "Research Lab Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LouisWhit/socialspreadinggames/main/virus-token.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wm4f7szXuU",
                "twitter": "https://twitter.com/VirologyNFT",
                "website": "https://www.virologynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6nY5u2KWywfY6ERXgBBr6YNfBATHi13HT1fge64vCAKo",
            "symbol": "HOHOHO",
            "name": "Santa Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6nY5u2KWywfY6ERXgBBr6YNfBATHi13HT1fge64vCAKo/santa.png",
            "tags": [
                "meme-token",
                "community-token",
                "bull",
                "nft",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/XE2PjtcyXs",
                "website": "https://santacoin.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9kauevxBjRrZef2PqwZVW4edrkNZ2KkPfmUx3R3QhRdy",
            "symbol": "AWCN",
            "name": "AwfulCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AwfulGamer1/crypto/main/40397979_10155824824890773_871328072698167296_n.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9NkjeLqSoWpQVZw12DHu9aTuyR4ssbgzDrjR2anKsZaF",
            "symbol": "LEG",
            "name": "Legion Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dorian-gabIer/legioncoin/main/3633.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cez3uhQiwAMMNsmKGd1YH5Xbf1cNb7pdmNDjZTmr6vpc",
            "symbol": "TOEKEN",
            "name": "Toeken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cez3uhQiwAMMNsmKGd1YH5Xbf1cNb7pdmNDjZTmr6vpc/logo.png",
            "extensions": {
                "website": "https://www.toem.art/"
            }
        },
        {
            "chainId": 101,
            "address": "5xjbrm9xXNNPScdgy9kz6burQ2dXoASkQP1cUQtjZrcb",
            "symbol": "TOKE",
            "name": "TOKE TOKE'N",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5xjbrm9xXNNPScdgy9kz6burQ2dXoASkQP1cUQtjZrcb/logo.png",
            "tags": [
                "toke-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6g6GDxiHh6y4DSnmUEMCU3Wk111RPTAoNgUwZT7R7x7E",
            "symbol": "BRT",
            "name": "Bear Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/teemuvayrynen/beartoken/main/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "QA4wEnnsYhneiss2grLFcnyjV5RjQ1mauADjPsjY1iX",
            "symbol": "KEIK",
            "name": "BearHat",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/indigoMeursault/cryoti/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CU2Qz8X4ZTvqja23RUQ8XNWwf8T7Et68LPcxQ5eaF5LT",
            "symbol": "GDGR",
            "name": "GudgerCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ngudger2021/crypto/main/gudger-coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EKpxcWttG5hcoRTZbo4owL4t3rGMMoPbv53cuu2Vv5do",
            "symbol": "GENE",
            "name": "The Gene Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/didyourobit/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CNVT48GsJeUBLLVVM9DG7SKaJJeBuskB2wCwrEKHHiE1",
            "symbol": "LOI",
            "name": "Loial",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/luisyamashiro/Loial/main/Loial.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5SnqZ1eUf9QtLya5PuV9ddsisRZrobSdNd1iRbg2p3sZ",
            "symbol": "FURY",
            "name": "FuryCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gondor5/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JBjj5FKogTJUjZUPhftYePkLzWToR2y7Gc1mNfS58AGK",
            "symbol": "GUMN",
            "name": "GuCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gibbs5126/crypto/main/Gumine.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7SAWTcarCZGvGATppHAxYucAQ43MkwBRVF2FS8DCaty1",
            "symbol": "SM3y",
            "name": "SM3y Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/smalley1992/crypto2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDsN12NXKpseHW6nC935C2pdVwZT5fegojH8t4i23HYn",
            "symbol": "C10",
            "name": "C10 Inc Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/parkerhemming2001/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DU7fpiWoJcJn1zAPqRKsXuWjFuWPcoP6osq6L7XSDSa2",
            "symbol": "DUCK",
            "name": "DuckCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrcpapa/duckcoin/main/duckcoin-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2VLVsnz9AGP5beSwsqWBfsqdkW9L67nFvA6xoM18PqP9",
            "symbol": "LUMA",
            "name": "LUMA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gabrielfigueroa/crypto/main/unnamed.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HR4fmpx558JsboAhJKgkGRVYdq2DeK3atGygw8Em9REq",
            "symbol": "WANE",
            "name": "Wane Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hamdouka93/Crypto/main/maxresdefault.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GsE1t2A9a7Bq7pGBAKFNqFTvwqkBtCD6B7QU8fLYkBd5",
            "symbol": "glowiebois",
            "name": "DEEP WEB HONEYPOT",
            "decimals": 0,
            "logoURI": "https://github.com/dylanbaker12/crypto/blob/main/th.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CcGwAW2xcDuWAtn8Q74xbsAYC5wdidVMtvaFRPi15BRM",
            "symbol": "MKST",
            "name": "MakaroSoft",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tmakaro/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "66sTs6BUychYMy5dy9yPXPz3wQVRkFrbi5uvLttmjVvH",
            "symbol": "wtf",
            "name": "WonTooFree",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PenTestTom/crypto/main/Logo-White.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FxdKLE4qaoUGGfGRFmtsWCmypLVmP5xv76KZZn8gbEH",
            "symbol": "LAZO",
            "name": "Lazo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/John7Lazo/lazocoin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EudR9SP68ycb4uApJChFCyzdmAjwgeaCYxXR6u9R5mCx",
            "symbol": "BWRX",
            "name": "BAYWORX",
            "decimals": 0,
            "logoURI": "https://github.com/bayworx/crypto/blob/main/bw_gear.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4YRtGRmbFZVzqetnJge9cPTA7PJpK51fyv8huwbJAzGt",
            "symbol": "PEP",
            "name": "Pepega Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thevoid69/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5abvCZ66fh2pt36V66YG5X2PoERSbS6qri69dnTuwrem",
            "symbol": "RICOIN",
            "name": "Rickoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RickyvdBerg/riccoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhwH3NSkyzGfasYpXA1N7v1KJJu6rFkeQ8EKuoh3Ab3C",
            "symbol": "LNFTxLIQ",
            "name": "LNFT SolPunk-9456",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AhwH3NSkyzGfasYpXA1N7v1KJJu6rFkeQ8EKuoh3Ab3C/logo.png",
            "tags": [
                "liqnft-owner-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/LIQ"
            }
        },
        {
            "chainId": 101,
            "address": "4tJRo9Dfd5q9ddoJztsu9sq7h3uhrPVEgxoqHgnjZ1Ck",
            "symbol": "RUNR",
            "name": "CryptoRunr",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cryptorunr/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JAzhmrgDUpGHvcBgfD1F1EsszUPnKuw1qfBtRDMQUxMk",
            "symbol": "TJ",
            "name": "Tarja",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jonathancywa/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DeGqHpa2M5icraaadStxgvQaV37rkQLKv2XSZQ2H1rXD",
            "symbol": "LEGI",
            "name": "Legion",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dorian-gabIer/legioncoin/main/3633.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "73vwXADoix7uPRfTLhPQX2Cabv5hM5W5MEZ6WZUj16r9",
            "symbol": "KARC",
            "name": "KarmaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/skidad75/karmaToken/main/karmaCoinLogo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CFhGVFKsBFmQzBrcXRFQBoYfe2Q38hb8LTfwGGD3Va1o",
            "symbol": "NOOD",
            "name": "SolNoodles",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CFhGVFKsBFmQzBrcXRFQBoYfe2Q38hb8LTfwGGD3Va1o/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "instagram": "https://instagram.com/solnoodles",
                "twitter": "https://twitter.com/solnoodles",
                "website": "https://www.solnoodles.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6AbPCZfkwLsQ8mVNXXUyv76zKd8qduigBur6pRezmNFf",
            "symbol": "KORA",
            "name": "Kora",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shaneholloman-org/crypto/main/logo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DVEco5eLPWqKQpEZq2XAFwyRaQuZ1KhVLw555Yky9E2U",
            "symbol": "PTXE",
            "name": "ProtoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/klercke/protocoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDKhvWcHmHQz6tF5iotDUTrZ2jNAqgjeKHT7YiXWSv6a",
            "symbol": "PDA",
            "name": "PandaToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZetaWaves/PandaToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8r2xj9f8ud4NVEkiLUk2dyzVxAckZbyidKRsTp41xAzv",
            "symbol": "CYCX",
            "name": "CYCLYCX Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/evantye/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ri2nbqn2nSzcjnqksVrG8cHpbKXgGN8RqGwPHC7LYKz",
            "symbol": "MFOX",
            "name": "MetaFox",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/metafox8/mfox/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6g9XX8V5eyQEpE5paRx3di56UXXpdJ1XSEtrKvp3Jk8S",
            "symbol": "FLPC",
            "name": "FlipCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shnabz/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6yvVwhfmy5nnAP2WEmQMQSpEhKUR2a6ekDcop6F6GcHd",
            "symbol": "CEMA",
            "name": "CEMA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LightOrithm/crypto/main/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/lightorithm",
                "website": "https://foxanchor.com/"
            }
        },
        {
            "chainId": 101,
            "address": "12xkr3HDV3r3rgCNa2YPBSsmpBAfpxLeu64UkzPMp5k4",
            "symbol": "DTBN",
            "name": "Databending Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AdamJSoftware/databending-coin/main/databending_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5itxjY1UM58Z2y77rkW5cRZt6qx8WGAtg6Q36NszDaXa",
            "symbol": "VRTX",
            "name": "VortX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MilesBellum/VortX-Token/main/logo/VortX_Token_logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/VortXToken",
                "website": "https://vortxtoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "DJwhXmhFHqUEntFPT5ZYvFm1Pt3ZYvbGxKQ2YAB1qNgE",
            "symbol": "PSBT",
            "name": "PassByte",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/digitalworld-crypto/PassByteToken/main/PassByte%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BRe8gKGMCfNvzdGEmV3JDWbn3hjqf1Vptsw5eeFrupjd",
            "symbol": "MANDA",
            "name": "Mandanapu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Akhandaaa/Snowman/main/Logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4pLFiemidbyyiGzP4C7jSpnLabCaZmJWTGeSnet7pwBW",
            "symbol": "TQSI",
            "name": "TemQueSerInvestigado",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tqsinvestigado/tqsi-coin/main/TQSI_logo.png",
            "tags": [
                "social-token",
                "investigation"
            ]
        },
        {
            "chainId": 101,
            "address": "U3aivtiLGRUgtek1s9YCdnancYr5VCTLJ6RoapYiNJN",
            "symbol": "SPKY",
            "name": "Spooky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DovahKemp/Crypto/main/d5nacc0-98fe73be-a868-428e-8ea1-d7d7fda6a093.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "udwm8g41w7Ua7BqtVCw3n5HM3Xa8pF1CeqVv2MSbt2D",
            "symbol": "ODIN",
            "name": "Odin Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aygran/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Y82cteBE5kUBrFURoYkMUMLNoydYxH6xXkqai9kPECa",
            "symbol": "BNCH",
            "name": "BenchCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoshSennett/BenchCoin/main/Benchcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Y77sFZfUKDgnxYGBZiBdc27avQBPYnd35tNqub8xjVx",
            "symbol": "PICO",
            "name": "PiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/2piMicrosox/crypto/main/2Pi.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5g8aQtjg6rw8Yr6kDSxtgr7e6CrBjxrUNJKLMtsCFvDk",
            "symbol": "BQNT",
            "name": "Boriken Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/KP4CA/crypto/main/bqnt.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GV8YdQA9GRHHWeqkibydNNxfhP4N6Vg3k551MN1RRZpb",
            "symbol": "LOSP",
            "name": "LOCOSPdotORG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LOCOSP/locosp-coin/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3k87Pv2ZfFrhTUpaf9xcXCN2vBKG4zBVuffvc3dT5PuL",
            "symbol": "MYMD",
            "name": "My Maid",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ldnetwork/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9rp6efeEcbqSRmJRYtN4rGbxtaz7qnhocFY7DvTjJnz9",
            "symbol": "POOP",
            "name": "Nincompoops Investments Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/patrick-nincompoops/nicoin/main/NI.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "http://nincompoopsinvestments.com"
            }
        },
        {
            "chainId": 101,
            "address": "HRTU4H7FticycnuHSJty2eCbva1JjCn194fEVCKQWgw7",
            "symbol": "KEIK",
            "name": "CuliacanCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Culiacancoin/Crypto/main/460-4601862_logo-puro-sinaloa-calidad-hd-png-download.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3EmsKNv1Hze2KsfzBPwvWPVoCDfV9NQXD5uQjnyh9kCu",
            "symbol": "PHC",
            "name": "PegCoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/PegHeads-Inc/PegCoin-Image/master/PegCoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/PegHeads-Inc",
                "twitter": "https://twitter.com/pegheads1",
                "website": "https://pegheads.ai"
            }
        },
        {
            "chainId": 101,
            "address": "GGpfnHaiQnnQ7wBbMB6MAipzaDLeSmCjDgf9GipyDsmu",
            "symbol": "AUM",
            "name": "AumKara",
            "decimals": 0,
            "logoURI": "https://github.com/ppbrahm/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8XcFaoWqS4V7TZD415EBYBn7ZV8kkLCxxDEEb6HwSEyc",
            "symbol": "PTC",
            "name": "Patel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RushBeats/PatelCoinLogo/main/patelCoinLogo-min.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GgBxABwcwpScmzqMb5tpS1JUVau7gf8gzK7WCdMqru3f",
            "symbol": "DMMM",
            "name": "Dent Man Mike",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dentmanmike/crypto/main/dentmanmike.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7rGsvajFsjHeq6PTUsdZzNBUpTu88y6i1YP7npkopBJk",
            "symbol": "AVLC",
            "name": "AntiviralCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AndrewAubury/cryptoimages/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FhGt8avUy8fKafd8rDAXWVfH19NUgZtyL8F7JojnVyBK",
            "symbol": "GNG",
            "name": "Ginger Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/z3rodown/gingertoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "87qcCHFHdyRkpKYWCnvVYUX6gqnGYCcsN29Zi8c5aAfo",
            "symbol": "DAZC",
            "name": "Dazcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faikwo/dazcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JmbT6JEib5gUKuM49YNs67u6Sc37z3AVq9XeXCUMuqe",
            "symbol": "HOLF",
            "name": "Heathen Ohana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Thorne79/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BLjf2hgEoZNtrKFqbnXYcHnnXB6YecDEh6npUvEBe4uT",
            "symbol": "918C",
            "name": "PAC918 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dfdubtv/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "metaverse",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PAC918coin",
                "website": "https://pac918.com/coin"
            }
        },
        {
            "chainId": 101,
            "address": "9QxjCX6G69kbjdVmwUaNtmhPmnMgeKaMNAdmC8eoMPgW",
            "symbol": "STCN",
            "name": "Spanishtown Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/oldschooldesignz/crypto/main/spanishtown.png",
            "tags": [
                "social-token",
                "community-token",
                "mardi-gras-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7uB6y88ECgZE6dkwXHJAmQzzKiNeKSH7PSaPBxux3sXw",
            "symbol": "CABC",
            "name": "Cable Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AshtonCable/cablecoin/main/cable-games-bg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hpfv5wUCASzmuBzbt24GbTHA2AL32KBZCeNntPmDeZTs",
            "symbol": "WYK",
            "name": "WaYaK Monke",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pyrohunter13/crypto/main/coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9nDPtHURxy4WByYnyjmish1Sehzpnq4qtLZpSXrb863N",
            "symbol": "DAO",
            "name": "DAO Coin",
            "decimals": 9,
            "logoURI": "https://therichdao.com/images/daologo.png",
            "tags": [
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AJt9AteA9QoFtnHc768WGykgWN4zcY8aSYB9LojHfLvn",
            "symbol": "GAME",
            "name": "Game Coin",
            "decimals": 8,
            "logoURI": "https://gameficoin.com/game/gamelogo.png",
            "tags": [
                "gamefi",
                "meme"
            ]
        },
        {
            "chainId": 101,
            "address": "AcYyc7e6kwre2FUWk2x26HFVYr1A6eg5uoW2XhcHpyCr",
            "symbol": "Web3",
            "name": "Web3 Coin",
            "decimals": 9,
            "logoURI": "https://freetimecoin.com/images/web3logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F9pjKSbaZBk7833Q96msrVrqvxN3AqbC9NEfPwPLkCoY",
            "symbol": "GSCA",
            "name": "Geko State Coin Alpha",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rath17023/GSC/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FXs5Sdz1d4hitKhmT58z2f134eCDashjKaEfJvEzfZ75",
            "symbol": "VIRUS",
            "name": "WVO Voting Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LouisWhit/socialspreadinggames/main/wvo-token.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wm4f7szXuU",
                "twitter": "https://twitter.com/VirologyNFT",
                "website": "https://www.virologynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BTeSJi1ek9Eq7M3DN9j59R7xyexiZ7Vih8ucv7F2uNqs",
            "symbol": "COCO",
            "name": "CoconutCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wgpowers98/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CYLCxdEcrn9sQGnGHb3JqPDEnMpcjVAGjaFE99kDnXsc",
            "symbol": "TXC",
            "name": "TruexCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/truexcoin/truexcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Z7bWSvcxVeUkroSypFW3Tsw7vPoJUcCxhTFNenLxNoR",
            "symbol": "MSP",
            "name": "MSP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Z7bWSvcxVeUkroSypFW3Tsw7vPoJUcCxhTFNenLxNoR/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9vGzJqzi15XKFzaFULcjcpzKpnYZcP7MwN64AE4F5KMx",
            "symbol": "DYC",
            "name": "Deany Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LightHardt/crypto/main/Studio_Project.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AVqtRUtYiLdJ2jEk3PYuJNpom3E4sRqfNWBoTEnwR7Ao",
            "symbol": "CGC",
            "name": "Charitable Giving Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/XxXDark-LordXxX/Charitable-Giving-Coin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DGBdLTdgbV1cTYQKjLWBVEZ64L1k6YikwTzfqHYYciNv",
            "symbol": "FAROS",
            "name": "Arte FAROS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Luiort/FAROS/main/logo.png?token=AHER75IJ66LAPA7763KI2RLBWRFDI",
            "tags": [
                "social-token",
                "Art-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GYjr7M9Pt2n7eFTw1auyxMsYin9AXGcGFT6JjRehkwhn",
            "symbol": "BANC",
            "name": "Banana Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alpine-token-gh/crypto/main/banana_pix.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fGWr9WRGvu",
                "twitter": "https://twitter.com/bananatokensol",
                "website": "https://justbanana.io"
            }
        },
        {
            "chainId": 101,
            "address": "AoxZQGRUcRaQJgtmVa8ydm3euZdvMfn8fCRJWFBqLc4Q",
            "symbol": "FRLX",
            "name": "FRALEX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AoxZQGRUcRaQJgtmVa8ydm3euZdvMfn8fCRJWFBqLc4Q/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FZVTWqHifiTAozipkCz5mtERSZQRPg9JV1GZFXXetRij",
            "symbol": "PTA",
            "name": "Prometa",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZVTWqHifiTAozipkCz5mtERSZQRPg9JV1GZFXXetRij/ProMeta.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7Tza6buS",
                "medium": "https://medium.com/@plusmetaofficial",
                "reddit": "https://www.reddit.com/user/Prometaoffcical",
                "twitter": "https://twitter.com/PrometaOfficial"
            }
        },
        {
            "chainId": 101,
            "address": "EPbF1VLfd2suCRCVcnK1Ly1wrvE4jRc6rSN4yrnS7hUk",
            "symbol": "HHC",
            "name": "Humphry and Halo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GoldenCrow217/crypto/main/H%26H%20(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "81RucdFcT4Hmw71Tq6A5kUsk7py7DwoWPKWvjfGkzhDC",
            "symbol": "BTY",
            "name": "Bity Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Titi923/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BWdQJ7mQS81MujywpixqtpPnCdLmiuiVANwLaMnRHDxs",
            "symbol": "SNEKY",
            "name": "Sneky",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/MrOddbird/crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EvWmXXuZA6J1RCeATbUYupFcjMtSanoYEadX8SZxAHeZ",
            "symbol": "GNEG",
            "name": "GNEG Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sausgulparen/logo/main/gneg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "69pC7U7hod1ZLggEj9KoftVBseEKq3TwCwAvffEP24dp",
            "symbol": "MXRS",
            "name": "MAXRIS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/m4xr1s/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HjC5UnFFVDyhtcCQQa6wDEQFcDfFZrpSAckABGf4Q1gx",
            "symbol": "POKIES",
            "name": "Pokies Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kumara-Upadhayayula/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8DSUDdZuCvUQ8FxASUUJBE2M8QmDVojTim9K99c9oPjm",
            "symbol": "IXDT",
            "name": "IXDT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ixdt/dotfiles/master/i.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "s3rrsS4VHhJVNehT95LyTbTr33bijpcXQy5s7ukEJ5q",
            "symbol": "SBC",
            "name": "Sam Bear Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/s3rrsS4VHhJVNehT95LyTbTr33bijpcXQy5s7ukEJ5q/logo.png",
            "tags": [
                "meme-coin",
                "meme-token",
                "community-token",
                "nft",
                "bearish",
                "monetary-experiment"
            ],
            "extensions": {
                "discord": "https://discord.gg/eRgSfy5ACA",
                "twitter": "https://twitter.com/SamBearCoin",
                "website": "https://sambearcoin.art"
            }
        },
        {
            "chainId": 101,
            "address": "H87svx8UzhFKnGPr412zbEPtfym4pbZKfdPnxy7af6Ux",
            "symbol": "MUD",
            "name": "Malik Draz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/malikdraz/malik-draz-solana-crypto/main/malik-draz-crypto-logo-solana.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/malikudraz",
                "website": "https://malikdraz.com",
                "youtube": "https://www.youtube.com/channel/UCCZNFtE6ghcRlpcwuzmZJIA"
            }
        },
        {
            "chainId": 101,
            "address": "48JBpRSKQApwkyzfUTwbGqMXXk4kmSYmT8tad6dPXN4E",
            "symbol": "PFC",
            "name": "Proficoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Iferencak/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A2ALonhy5FnjbhwY9BgVsrLhEodTFd8jMfJ2V3nZDNpy",
            "symbol": "PSC",
            "name": "The PS Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ps3460/crypto/main/logolg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A26a8gt8H28XSUwnhQGAyLwnKaeGAsWX6wxJUZ8fxtLf",
            "symbol": "DNK",
            "name": "Danko Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BratanFree/cryptodanko/main/danko-logo.jpg",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkXnDJfqp38YBYjJ2BmPvBKeaS2BHExzwhnYRyLEQ6sX",
            "symbol": "URA",
            "name": "URA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DkXnDJfqp38YBYjJ2BmPvBKeaS2BHExzwhnYRyLEQ6sX/logo.png",
            "tags": [
                "dao",
                "gaming",
                "gamefi",
                "governance-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NqmHKDZp8d",
                "facebook": "https://www.facebook.com/uranusx.official",
                "reddit": "https://www.reddit.com/r/uranusx_official",
                "telegram": "https://t.me/uranusx_global",
                "twitch": "https://www.twitch.tv/uranusx_official",
                "twitter": "https://twitter.com/uranusx_global",
                "website": "https://uranusx.io",
                "youtube": "https://www.youtube.com/channel/UCclA3Nu86rRSrzzcVWIUQdg"
            }
        },
        {
            "chainId": 101,
            "address": "6ifkw5MWM9pAL9x2dVkAUM6Bf962zHKRqnvjGz4UbibA",
            "symbol": "ROBO",
            "name": "ROBO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ifkw5MWM9pAL9x2dVkAUM6Bf962zHKRqnvjGz4UbibA/logo.png",
            "tags": [
                "dao",
                "gaming",
                "gamefi",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zpYNxg7NUy",
                "facebook": "https://www.facebook.com/roboworld.official",
                "reddit": "https://www.reddit.com/r/roboworld_global",
                "telegram": "https://t.me/Roboworldglobal",
                "twitch": "https://www.twitch.tv/uranusx_official",
                "twitter": "https://twitter.com/uranusx_rbw",
                "website": "https://roboworld.io",
                "youtube": "https://www.youtube.com/channel/UCclA3Nu86rRSrzzcVWIUQdg"
            }
        },
        {
            "chainId": 101,
            "address": "2fvPmqYk1NemSQ3NFUSu3zw3LgiZKxzFoz79oqkKdmaY",
            "symbol": "PXE",
            "name": "PixieCat",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DavidJKTofan/pixie-crypto-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7C7c4Ci84sZMySSYkwQ4YKMQtnNPhdTbbnSTsoDC4cjs",
            "symbol": "TOST",
            "name": "Toast Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/toztii/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DaaHNKna19EUgVyWS8MRBa8pvAfjvi3TTVWqVRucNgkH",
            "symbol": "PKLC",
            "name": "Pikls Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Metamorfolog/crypto/main/piklsCoinUpdate-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9h4xLqWTpuqcGB7nzD17vWTBxhZdjVWrumoPLsnKSoT1",
            "symbol": "CY1",
            "name": "CJ",
            "decimals": 0,
            "logoURI": "https://github.com/christos1996/Crypto/blob/main/pngtree-blue-bird-vector-or-color-illustration-png-image_5266726.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BAcKERxnjUoWNDPzv6SjE1piZEhZPcLgcgpcxYiCSKvy",
            "symbol": "SOLB",
            "name": "Solbacker",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAcKERxnjUoWNDPzv6SjE1piZEhZPcLgcgpcxYiCSKvy/logo.png",
            "extensions": {
                "telegram": "https://t.me/solbacker",
                "twitter": "https://twitter.com/solbacker_io",
                "website": "https://www.solbacker.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3WLPaFRqiUT79dNQFBVdK6HVtDfHVEywfNww8JPfQfXu",
            "symbol": "GL",
            "name": "GalaxLite Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/greysonleong/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CDLamTV7E1dgSQFw3YiiZnhNfSRKz9Wvhc3K3NzVKT7m",
            "symbol": "NIL",
            "name": "Nil Coin",
            "decimals": 0,
            "logoURI": "https://github.com/chayelheinsen/nilcoin/blob/80b91ee7d96d631e4d4e6bbeaf22b5cfa136cf47/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cyg784suVRPBekZ3EsAc2UjDfvbZk9iu9HphD9BkfEKC",
            "symbol": "SVTC",
            "name": "SvetrCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/petrsvetr123lol/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FZKnpMfrDtxB5j2xQFN1YHtxZgWEEpHAhi1HDGZDhubo",
            "symbol": "YWC",
            "name": "Yoseph Wijaya Cool Coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/nsnull0/YWC/main/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://yoseph.ws"
            }
        },
        {
            "chainId": 101,
            "address": "6kCBuk7CQieiRFGX6iw89pgF86mjLrUM2PRBemFLinNd",
            "symbol": "VULC",
            "name": "VULCOIN",
            "decimals": 0,
            "logoURI": "https://github.com/adambingley/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FPUo3gkLJKDw3QHoHQWwG9NEDZyDteDAGspNCNMkQtcW",
            "symbol": "SLAVUS",
            "name": "SLAVUS Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/devSLAVUS/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ReinhxoLwdYhndDaEr2rsMSBvWEmE9ycb2kRjpWi9eY",
            "symbol": "REIN",
            "name": "REIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ReinhxoLwdYhndDaEr2rsMSBvWEmE9ycb2kRjpWi9eY/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/aBjtec3zYs",
                "twitter": "https://twitter.com/RatataNFTs",
                "website": "https://corporatslab.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ata6mDfMdwLzGycj41aHJq4ahDvHYfSC1GQapCtjndoH",
            "symbol": "QLN",
            "name": "MYTHIC QILIN COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jack21m/logo/main/qilin.png",
            "tags": [
                "social-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/BSerFpJSEc",
                "twitter": "https://twitter.com/MythicQilin",
                "website": "http://www.mythicqilin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HNuNFMU6c5usjgHnXNc7HCAKFWjtVYvFVxMh1q2SLR3U",
            "symbol": "YKAY",
            "name": "YKAY Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/huh-chaff/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "87s213foYFiC3nUEoGTu5FEvipFfsuTtf1fCQAGpCPmT",
            "symbol": "DEW",
            "name": "DewCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Zroxus/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7b4GEPrvMz61ZG7p1UzEg8s6pw5isD1uTRLR8tDLP8zC",
            "symbol": "DNY",
            "name": "Donny Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/donegamattia/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4SwHidnf71JXXkyuS3saGvsUTmxtWFUbzAJ4XLsWEHTN",
            "symbol": "VDTC",
            "name": "ViktorioTV Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/viktoriotv/cryptologo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GEqYydMW3JZqapzrkJGTaScFbetV8riKAPui3SQcPZMo",
            "symbol": "EZCN",
            "name": "EZ-COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/elh0901/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A7qGcsFoqpMKPScU88RxL4WWtchX1exDyp5EifBr4m6h",
            "symbol": "KRNC",
            "name": "Karen Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SODIron/Crypto/main/logo.png",
            "tags": [
                "social-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "Fdxh6WL31JQhVvedDkQb2kPkVpk4ZMNj2f6x5ZrCkRbp",
            "symbol": "CONC",
            "name": "Conservative Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jhemley1/crypt/969897a6f58f874dd673a8360b1f0b3ec24063e9/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EfFfffeVwmt3iYYiBwmburzeVrAg315rCubNox5FaJeo",
            "symbol": "DGC",
            "name": "DigiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/digijustin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GHP2CEPaawZkkDqhq4i8HGJV1TAJ3uati5RFDa6zGmC6",
            "symbol": "MTMS",
            "name": "MTMS Network",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHP2CEPaawZkkDqhq4i8HGJV1TAJ3uati5RFDa6zGmC6/logo.png",
            "tags": [
                "utility-token",
                "mtms-token",
                "meta-meeting",
                "meet-to-earn"
            ],
            "extensions": {
                "description": "MTMS is a utility token, Dex, and metaverse meet-to-earn Dapps on Solana.",
                "facebook": "https://www.facebook.com/mtmslive",
                "instagram": "https://www.instagram.com/mtmsnetwork",
                "medium": "https://mtms-metameeting.medium.com",
                "telegram": "https://t.me/mtmsofficial",
                "twitter": "https://twitter.com/official_mtms",
                "website": "https://mtms.live"
            }
        },
        {
            "chainId": 101,
            "address": "Ayb55LvLMZc8i8XmbskEGCXE1QMXwCXYCmLpCNNhJEr9",
            "symbol": "RAMZ",
            "name": "Ramsay Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cramsay3/crypto/360a9f916f6332710065df9aefc2f1598f4f5aff/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FKMnUTbhjeoJU33CJo73oDAPFuDM2nkxFVvrhPp9jLYL",
            "symbol": "CLKK",
            "name": "Clikkit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SDCat99/clikkitimg/main/clikkit.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7u37yJKifkFC9nLP6oYbLSj9TCELn2D8sMHQoZYatVmE",
            "symbol": "JKA",
            "name": "JaydoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Muzz1409/MainLogo/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CcQoer5zWDxz1DpT1KQyqSEtpZKqCWFhwjJDXW9ULrDN",
            "symbol": "GORL",
            "name": "Gorlo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GorloGitHub/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4bRgrxSKhEg2yMmXoa21Sg6dnEWjNoPECEnYdfGhfTPj",
            "symbol": "MOLR",
            "name": "Molar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Igorgregorek/molar/main/molar.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9PHrDyomXcXrFqwWW4dsbJk66BvAxAYgQgw4emngH6tg",
            "symbol": "SGA",
            "name": "SGA Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sblacksg/sgacrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AR5z7CDpfqGgbhHQkKwDAiknkDSRJVjB1gFRLp3AA8CY",
            "symbol": "NEKOJOKE$$$",
            "name": "NEKOJOKECOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tengjuikchang1983/crypto/main/JokeNeko.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9rfXzv2AqNJzPBq9ppFfcny4DTqLzWMzLLLAY6CnLdvu",
            "symbol": "JOHN",
            "name": "John343",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/John34344/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ACgt6KadMNYYxu9uqVvmoPPwQokBSrVTWtXME7HhFLHJ",
            "symbol": "JLFC",
            "name": "JLF Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brackencyber/JLFCoin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2eq2uvkGR1LidQjxwib6Y4KdejJGaqS61foSSXoGQQhz",
            "symbol": "PKC",
            "name": "Pack Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/FreemoX/PKC-Pack-Coin/main/PKC-Pack-Coins-Icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Px2dwF1iod4SqKsn7sYL7p61GqHEiN8nqyutYACDxQF",
            "symbol": "OM",
            "name": "AUM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ppbrahm/aum/main/aumlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J8xx6gqQCP9yvQuZ1LyudeKaSTAXzu4fsGr5d8BRkyFW",
            "symbol": "PIPIK",
            "name": "PipikPeniaz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tobikbobik/crypto/main/pipikpeniaz.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9h6pmMs2eaQ95zzvHpYyssQoEcoUnHPLJs5sYVNikNxk",
            "symbol": "UVTSmart",
            "name": "UVT SmartBits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SMG-bits/SmartBits/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D1HA1d3tE1Pk5aQNhKGfAEFo6s364srRS2iZp6TPXB8L",
            "symbol": "AUBC",
            "name": "AubriCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/phuxtick/aubri-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5P8qGxw7GuhhoLs6T67mfwLdNMotmLXxBWkpbeD8cmK3",
            "symbol": "SirT",
            "name": "Sirius Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/siriusdave/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A7swkKhzDCgBkpp2h3UHTtHEVmDVRLFxrGx6HwbYmZEh",
            "symbol": "BKN",
            "name": "BruceKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pjfnsk/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qwfXXpVadqWCZDFf53jr43SkceSnVFt8TSb8zUQLWHF",
            "symbol": "STC",
            "name": "Shitoshi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/francip2190/shitoshi/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CfEMapS6FWUgaGXFtjCX123TL2FNQn9927x9iRE5ZzSE",
            "symbol": "ZONE",
            "name": "The Zone Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/liltaf/zone-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G9sTdFXB6ysWB95bD8FFgB4Ckc9d74ws9GsqxfgF6JPt",
            "symbol": "ASKO",
            "name": "Asset Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FilipSpace24/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7wkNjtQaDYrme1EsYrbbCQiZRz3pgjC2t5sdJoEMpxcH",
            "symbol": "R3N",
            "name": "R3DWA Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7wkNjtQaDYrme1EsYrbbCQiZRz3pgjC2t5sdJoEMpxcH/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "Pas cher."
            }
        },
        {
            "chainId": 101,
            "address": "8yjkMedQpR4x7fVK4BZjE1hPbEK3iPumAsqqUYncLhtM",
            "symbol": "KNFK",
            "name": "Kunefke Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brandonkunefke/KNFK/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5mDoMYBTvBPWWBGFCz9AWoFHBYQgskBksuizsxKBMcXX",
            "symbol": "SOF",
            "name": "Sophia",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/9b-hido/9B-Crypto-stuff/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HHNgXZSZF97YzFPBSSWm6muuKEnSjEDcSjZecLV3wU5c",
            "symbol": "SRRY",
            "name": "SoSorry Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sosorry-coin/crypto/main/lo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A1yQ9BtkeYpZje4Kbmq9aNvZ4H7Dqb2cWenoWS6gQ4np",
            "symbol": "DNMT",
            "name": "DinoMatic",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/dinomatic/icons/main/D.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H9TqnUZfdu4HcJXNtbk3BmRPoJ5m1TQgV9uibPnDPg8w",
            "symbol": "DLPC",
            "name": "De La Poer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OliverBeresford/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "pCKvG8uhzvBc7JEK6bBktwKixesUsbWjxdbLfcuSxh7",
            "symbol": "ASYC",
            "name": "AsynCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AkshatRastogi-1nC0re/asyncoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6EqdKhWfzBKKfugVRnnUgxp8EyhnMcBEWoPcaFGfq1VK",
            "symbol": "BYT",
            "name": "ByteToken",
            "decimals": 0,
            "logoURI": "https://github.com/HadiMorrow/Byte-Token/blob/main/byte-logo-png-transparent.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HBvYEZLfCPnKKuPPy8g7gFRkcjM9DLiPurEnt1CoP8Xe",
            "symbol": "SZYMIX",
            "name": "SzymixCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBvYEZLfCPnKKuPPy8g7gFRkcjM9DLiPurEnt1CoP8Xe/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C8N2dnob3qQbp1sqRhpaqyh9h4NfwMyKkdpjRNSsvA5e",
            "symbol": "SPC",
            "name": "Sahar Priva Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SaharPR/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H4PJ6A8VEAKZM6vZHs1Ud6ohRdWUD2AXmbY8EqzNKK7W",
            "symbol": "OXYG",
            "name": "Oxy Gem",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H4PJ6A8VEAKZM6vZHs1Ud6ohRdWUD2AXmbY8EqzNKK7W/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "OxySpace is the next-gen esports metaverse empowering players to create, play, compete, and earn.",
                "discord": "https://discord.gg/9rthWDuB3B",
                "instagram": "https://www.instagram.com/oxyspace.app",
                "twitter": "https://twitter.com/oxyspace_app",
                "website": "https://www.oxyspace.app"
            }
        },
        {
            "chainId": 101,
            "address": "5MmPuS9D1iQwhqpanamkYppRS9J2iZpD5rpum2mHFux8",
            "symbol": "MAEL",
            "name": "Maelstromdal Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/deanlucero/Crypto/main/YouTubeLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "94sjUKfvsd512K9YHKbhQkmpbVrHvvqu7w72Aedmn3mK",
            "symbol": "BDC",
            "name": "Billion Dollar Club Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheVeqaZ/Bllion-Dollar-Club/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhEVpqjwN7LmNSxESV1GGuJugiCvqrTu7FEaYEKw5Ntx",
            "symbol": "SOKA",
            "name": "Soka Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/imsoka/sokacoin/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RoCKnAqV8LmchWKBdpapKj9sPaM1F9kiNDxvLU8sN8d",
            "symbol": "RCK",
            "name": "Rock",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RoCKnAqV8LmchWKBdpapKj9sPaM1F9kiNDxvLU8sN8d/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DaPHpTFwTHJaDUURNyHipqaLvoZyrBcZtpzaCTV8hrwJ",
            "symbol": "PBM",
            "name": "Praised by Masche",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/praisedbymasche/sol/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7AjkC42JBNwbM6TUkG5wvzjmtSVus5idQUmTZ46Zx318",
            "symbol": "NP",
            "name": "Neptunium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MrRobot4444/crypto/main/NeptuniumLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6xUsqjDu9iboUJimq1cv19QU65vfXHWkkKFem7FYHwYm",
            "symbol": "ASH",
            "name": "Ash Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AshBeigian/crypto-/main/A.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "T4iPCH1Fo6fGdebxrTnwM79t82DhS4uJJ4xesykUThw",
            "symbol": "HAF",
            "name": "Hot Air Finance",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T4iPCH1Fo6fGdebxrTnwM79t82DhS4uJJ4xesykUThw/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/HotAirFinance",
                "website": "https://hotair.finance"
            }
        },
        {
            "chainId": 101,
            "address": "HNBC4G1jfiaYcp8X1oXW9riXRiQXprib8sPLtv85RVES",
            "symbol": "vHAF",
            "name": "Hot Air Finance Vote Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HNBC4G1jfiaYcp8X1oXW9riXRiQXprib8sPLtv85RVES/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/HotAirFinance",
                "website": "https://hotair.finance"
            }
        },
        {
            "chainId": 101,
            "address": "UCFj8biQNPGHWUgKVcSHzWtkKjizrgKEPLGuN3wazqn",
            "symbol": "RNG",
            "name": "RNG.Fail",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Wiliver/rng-fail/main/rng.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Z2JQym9r96"
            }
        },
        {
            "chainId": 101,
            "address": "8NvwnKWfiKgFD9FrPAtzPJfp7y8XZH2h4EZtYmGkrJoF",
            "symbol": "ONELA",
            "name": "OnelaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Romain-dev0/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ue6CAnzqYaEppcXA2AnVZKQKasQ6qjLStbbsDf4tCVC",
            "symbol": "swfbv",
            "name": "swfbvKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/swfbv/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9i7CwfBiJMsKUDGceCbj3JxSFr1rnKfjfPKoHg9uPs5P",
            "symbol": "MXBC",
            "name": "BradlCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mBradl/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bmkz7nwEMHMM2UPdsY7kdz97CXjxj6okr16djAgVbtgC",
            "symbol": "IKST",
            "name": "Imran Khan Social Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/socialcrypto/ikst/main/IKST_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3cVsanUhbo91Qcuzrzx7qZAutpXYcaMr64jfq5Cs17es",
            "symbol": "LNFTxBHD448",
            "name": "LNFT Solsteads-448",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3cVsanUhbo91Qcuzrzx7qZAutpXYcaMr64jfq5Cs17es/logo.png",
            "tags": [
                "liqnft-owner-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/BHD448"
            }
        },
        {
            "chainId": 101,
            "address": "7X3ZRf2aftbsqb5Mb9QP5TSsPAACmcKwq1Ex3WWPJUUF",
            "symbol": "BIND",
            "name": "BIND token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/davisi3/crypto/main/bind.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4YQ9NvzPg1pqs8tFZ2ggeq5qCGY8hUsJC97fLBcenav4",
            "symbol": "BLKTY",
            "name": "Blackie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ilc00/crypto/main/blackie.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ARcrxoAojZ2aQPFbwRANH4ne34Zd5HY3RsDfp98ZkY5",
            "symbol": "LETC",
            "name": "LTETT Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aolinger/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8YosQxNWqPtrKXcPYFsjWv4MvAC1tV6p1iUfzyTgUn9q",
            "symbol": "SHT",
            "name": "Soft High Tech",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8YosQxNWqPtrKXcPYFsjWv4MvAC1tV6p1iUfzyTgUn9q/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/softhightech",
                "website": "https://softhightech.com"
            }
        },
        {
            "chainId": 101,
            "address": "9QqiExUzS3BtujmunYHQKVP73mJstsDFgQHvkcKEpjFf",
            "symbol": "KUBE",
            "name": "Kube Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jankube-de/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BThiYmTDGgRm9rmXdRMLBxvouu7WStcegpBDwyy5jJgN",
            "symbol": "RACL",
            "name": "RACELAND Coin",
            "decimals": 0,
            "logoURI": "https://tech.automacaoraceland.pt/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "RACELAND S.A. Internal Coin by RACELAND Automação Lda",
                "website": "https://raceland.pt"
            }
        },
        {
            "chainId": 101,
            "address": "5prsLoqaQdQ1wkmzH3vMJVURzhcksdSL9q5Bq9uqz35w",
            "symbol": "RACN",
            "name": "Raccoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sullie-dev/coin-logo/main/Raccoin.png?token=AECYTNBEB6YCX74MTV7T3ETBXZH2Y",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FiGhTeZQLVUtkYuLWgSunbBngdPPWv3FrnfqetDZFUDv",
            "symbol": "FGHT",
            "name": "Fight",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FiGhTeZQLVUtkYuLWgSunbBngdPPWv3FrnfqetDZFUDv/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5sYXHJ9z92rjCTpvcmx2L51P3316qYCyMQxjEoNJLhtX",
            "symbol": "TCD",
            "name": "Tara Coin Dollar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BleeckPi/ctd/main/TCD.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4RQUF5AaTuXGaxJaoJpP7FFEUJALAv4YwHH585SxPTia",
            "symbol": "SAMY",
            "name": "SammyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thezolon/samycoin/main/samycoinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HeeW8waT8GTAG8jjNeXAYiDtwg7m5JxA3eptncSFhY5H",
            "symbol": "TTK",
            "name": "Tartalon Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thetartalon/crypto1/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BwhiDmLTL56AzuoetVBbKVr8UMhaVm3zvnQ82uHGouL7",
            "symbol": "Vodacoin",
            "name": "Vodacoin crypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zaid-aub/Vodacoin/main/Vodacoin.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HFRHgfT17WzTK8Bf1hkcn8neoiKb4DgRNzUARtJQ5MLg",
            "symbol": "IVAN",
            "name": "IVC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ivanasicl/crypto-IVAN/main/ivan.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AfARcLLqRHsZc4xPWHE9nXZAswZaW294Ff1xcYQbjkLq",
            "symbol": "KROOK",
            "name": "Krook Coin",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/1009138/143821720-d9c6f5fd-96d7-424f-9b1f-b185451cbb31.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/krookssolana",
                "serumV3Usdc": "aGyXAmV1QBESopcEgmr52qtaQ4DuEBg7WJARh1ojSPx",
                "twitter": "https://twitter.com/KrooksSol",
                "website": "https://thekrooks.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GJktnoNYzPbtf8Y6dECKUD7AsqhuseNr73tZj7boBSZQ",
            "symbol": "MAAD",
            "name": "Members Anonymous Association DAO Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJktnoNYzPbtf8Y6dECKUD7AsqhuseNr73tZj7boBSZQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "A1hjmtFWNwhsLr1gycvx4TonmwR1uLoHwCxi14Q4C1Pf",
            "symbol": "DRAGONWND",
            "name": "DragonWind",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gglazer/crypto_dragonwind/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2KLLYZs2Lgx15o2GC9La5uLciBCFdRAWJ6m2PEK3uUuz",
            "symbol": "DXN",
            "name": "Da X Nilsson Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danielxnilsson/cryptodxn/main/krypto_DXN_trans.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "gvK3ehKkvHwXGqiBVB6fMmYx333DMUfzp2tp6cW82Pf",
            "symbol": "MELL",
            "name": "MellCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mxlanie/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CDAQD65KwCEqLaZqzP4esJ9BCWFaazfpE6jvKL5ypLY2",
            "symbol": "BLKH",
            "name": "BlackHeart",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tblackheart/crypto-logo/main/icons8-black-heart-96.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BL3X2JsXfb6c6M3HmxctUsxoqy3QcbzZMak13cgMbYgD",
            "symbol": "BOOM",
            "name": "Boom",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/meezcal/boom/main/logo.jpg",
            "tags": [
                "social-token",
                "lebanon-token",
                "community-token"
            ],
            "extensions": {
                "description": "Boom is utility token that is created to help the lebanese community that was affected by Beirut explosion in August 4, 2020"
            }
        },
        {
            "chainId": 101,
            "address": "Accqi3Zt9mnqRnsZAkD2GxVwXr4S1eHzZbx3yMTAhg8g",
            "symbol": "DOC",
            "name": "DOC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Andrea00Azul/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HRjCPghjQSoWvTyMH3bANCpnJ5f1SwaqBotDYMFCPkV",
            "symbol": "SLVC",
            "name": "Slutville Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/linuxjoe1/slutville-coin/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9VXUw9z2CNpBhTrqfqvnzs495P67axywqydaQVcaHYAw",
            "symbol": "TAML",
            "name": "TamilCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gskonlines/mycrypto/main/Tamil.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4oHiGGKu8XTnzxZLM6uS8ksLUsKudSLR72xAAQA37m3e",
            "symbol": "ELDTN",
            "name": "EldinToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sleevegarlic/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H6bVzHciGHLqBN8AFCDPQKLDDhfH33YBJ21UWs4cCh3t",
            "symbol": "DOL",
            "name": "Dolar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mcflabby/crypto/main/dolar.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ENh1e7yRkUsp7gPJGK7w1PH3RiKjAJn4tTRWHHbjGqzc",
            "symbol": "DaDon",
            "name": "Da Don",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dprange/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "63vT4MTT4dKgdNBc7TacURrGGFM1XGEkrs2F1MeAy7Wz",
            "symbol": "IFUPT",
            "name": "IFU PT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/incognitfigure/ifu/main/ifutoken.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "Incognitfigure Unipessoal Lda investment token.",
                "website": "https://ifu.pt"
            }
        },
        {
            "chainId": 101,
            "address": "4dk1efRnie9zGLymwERRnB2z6X3YWeAFRdE1kfcHtps1",
            "symbol": "HUFF",
            "name": "Huffbuffer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/huffbuffer1/huffbuffer/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8TD2szF72D3qs47oX8BXzAG3nm4fcsn1yAmFssdbTAhr",
            "symbol": "ETER",
            "name": "Eternity",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8TD2szF72D3qs47oX8BXzAG3nm4fcsn1yAmFssdbTAhr/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "Eternity Is a token created for fun might turn into something cool we don't know yet"
            }
        },
        {
            "chainId": 101,
            "address": "8QxkX2amPFSbgpKdPEquQp3nxoQ51YXPxzJh7CkpAaSF",
            "symbol": "ZMC",
            "name": "ZM Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/emanhackwell/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://zsideimate.hu"
            }
        },
        {
            "chainId": 101,
            "address": "87Y85RDtZbgijbny2xLco9ModZNAtyrd2nL4xGWMvytQ",
            "symbol": "QAC",
            "name": "quacc",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crypto-quacc/quacc/main/quacc.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4623MTUYesNujtrwsziMRaHF5mzB3u1NxZCqYNQCavoL",
            "symbol": "Opinswap",
            "name": "OPIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4623MTUYesNujtrwsziMRaHF5mzB3u1NxZCqYNQCavoL/logo.png",
            "tags": [
                "Exchange-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6MCitDvVP2UmTPhc9vwo9dBmpagex5QtjJrtCvGNwnmJ",
            "symbol": "TSFU",
            "name": "Coin For Miguel",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6MCitDvVP2UmTPhc9vwo9dBmpagex5QtjJrtCvGNwnmJ/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Lynxer20"
            }
        },
        {
            "chainId": 101,
            "address": "ChwWnsw7jjWdrAjHQ62R9Yf5ivbbYZGAW5FjaKK4fG3Y",
            "symbol": "COPPER",
            "name": "Copper Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Deterix/copper/main/penny.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EycHkjJm83PFV8eH8TE8b52o1e3Mi5AULpkhyB3ckWzr",
            "symbol": "USCA",
            "name": "Uscado the relationship balancer coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/danielxnilsson/cryptodxn/main/uscados.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JArUA4zzX8ajLx7fLE6ZTBM1G1yc8xuCkPf18YsYg3Wk",
            "symbol": "GBFC",
            "name": "GuldBorg family Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pirat0303/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AXyYeUoEqPR5hKqjNdezFhUgGkLvfxyoqaAcvc3eeuab",
            "symbol": "NRTC",
            "name": "North Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maviharjit/NorthCoin/main/NorthCoinLogo%20500x500.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B114cLy9DF83jWKZbNNz8jdikx3PxrCctPx11pJ2BXrM",
            "symbol": "WENDY",
            "name": "Wcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/peterda84/crypto/main/w.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CiuBomi5eWLNLwSa2mr2w1S4jBmz7BAfiMu7WRZmcJB6",
            "symbol": "DGLE",
            "name": "Doogle Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Doogle32187/Doogle-ICON/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6TsFcWgr6QAzURndCAEhUFqY132Mfo29bTLfgCYMhDUC",
            "symbol": "FOFA",
            "name": "Four Fathers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jetlim1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ythGaUhHiAfcp1Ugxpq1JK2UVH6W7vz7dZW9qcYczRg",
            "symbol": "VATO",
            "name": "VatoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anthony0723/vatocoin/main/VCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6bbmQEK8674rjak3T9hBouXiZcNDkXF8anYA38LL48nr",
            "symbol": "OCHO",
            "name": "Ocho Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crossfitocho/coin/main/3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3KSGhbxAP1BkU7jeq1ga9Ws2wWGD3MwS78EhMWteVEzw",
            "symbol": "CHOW",
            "name": "ChiChiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bew1769/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C2uoUDDKArZU9orfEALacuSYthJM3xnhEVoayZtDyTK3",
            "symbol": "PAWZI",
            "name": "PawziGear",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/romorris/Pawzi/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6YbnPUaCoadCJqhjYfavVBRc7Lj6MYwvxeYvytdx9JR5",
            "symbol": "OCLS",
            "name": "LiftCoin",
            "decimals": 0,
            "logoURI": "https://github.com/aftoracloud/ocls-crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "d93Fvhq5Lrp6dhkJwGGTqAZeR2MhEEEJjzCoHzweMpk",
            "symbol": "FLAP",
            "name": "Flap Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/d93Fvhq5Lrp6dhkJwGGTqAZeR2MhEEEJjzCoHzweMpk/Flap.png"
        },
        {
            "chainId": 101,
            "address": "EUXizUbT7aZoCXqgtRrSVox9rN85mmJSEZ2crsuDvjZj",
            "symbol": "SHTK",
            "name": "ShipToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sunkenfrigate/Logo/main/Shippy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ax7EjwgRaerUacfCAptcMnkckmf8Wiee5T9KLqSzsF6",
            "symbol": "AZA",
            "name": "Kaliza",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ax7EjwgRaerUacfCAptcMnkckmf8Wiee5T9KLqSzsF6/logo.png",
            "tags": [
                "utility-token",
                "secutiry-token",
                "game-currency",
                "launchpad",
                "tokenization"
            ],
            "extensions": {
                "description": "AZA is a token of Kaliza’s platforms that offer simplified real-world investments.",
                "telegram": "https://t.me/kaliza_launchpad",
                "twitter": "https://twitter.com/kaliza_aza",
                "website": "https://www.kaliza.io"
            }
        },
        {
            "chainId": 101,
            "address": "GizKeyyHjGMTDwC2qqdKYi9LDbgQxdSX9ABKSZKLvEdQ",
            "symbol": "DOUCHA",
            "name": "DouchaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Judical/DouchaCoin/main/Doucha.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EcGxe9UeinRxn9NnTsQTCiwepGwVbz6gvuLHqjiJgHHd",
            "symbol": "GONC",
            "name": "Gonavi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crpo10/token/main/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4sydfVabeMZ8MRxZBzs48UzTebjYQQkxeFCeuRUoz47i",
            "symbol": "BLHZ",
            "name": "Bel Hazon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jhncrent/crypto/main/mybookxx.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BfM12YHvYxnrQQqBfdWz4ehBcjgmz4uaL43PV74p57XV",
            "symbol": "NEPL",
            "name": "NepalCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ePrabin/Nepal/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y",
            "symbol": "SHDW",
            "name": "Shadow Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "genesysgo-shadow",
                "coinmarketcap": "https://coinmarketcap.com/currencies/genesysgo-shadow/",
                "discord": "https://discord.gg/y86HPCkk",
                "serumV3Usdc": "CVJVpXU9xksCt2uSduVDrrqVw6fLZCAtNusuqLKc5DhW",
                "website": "https://www.shadowysupercoderdao.com"
            }
        },
        {
            "chainId": 101,
            "address": "3KTkQJEMJXP741EJBFcAS34Lx9t8GsBYaW2BUUWkeyDH",
            "symbol": "CC",
            "name": "Castle Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KTkQJEMJXP741EJBFcAS34Lx9t8GsBYaW2BUUWkeyDH/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/4jNRF9zfCV",
                "twitter": "https://twitter.com/Castle_Clique",
                "youtube": "https://www.youtube.com/c/CastleClique"
            }
        },
        {
            "chainId": 101,
            "address": "ofyujgvpGgNC4yafE9A32MbmdcLZWcSaqZdfjQEemrP",
            "symbol": "DHC",
            "name": "Die Hard Coin",
            "decimals": 0,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmY59QodV6YTdvQB1h2Za74xx4ziRkKmdMsRtyD6qUQ8yN",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7c5kQDeDMfW9dskciZqjz9wk5BsFkC5HWivpM9eqNDN8",
            "symbol": "DEO",
            "name": "Deowolf Coin",
            "decimals": 0,
            "logoURI": "https://github.com/JarvisUndefined/deocoin/blob/main/Wolf-with-blue-eyes1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GsTPRchUXTokPt59hx7gi7RrrmKAfLBdiozQxNBCgsZo",
            "symbol": "CHORE",
            "name": "Chore",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChoreCrypto/ChoreLogo/main/javaClass.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FWPfFhWcUZjaZaAkjJUteMuhSmePRbNr4NwJ6mLdoADn",
            "symbol": "FRT",
            "name": "FireRosted",
            "decimals": 9,
            "logoURI": "https://github.com/HadiMorrow/Byte-Token/blob/main/logo.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7gAhXVMvd3DCsajz2baKoVd1gouNrvUNs64CqMHoFqqy",
            "symbol": "UNKWN",
            "name": "Unknown Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/whoelsebutSB/token-list/main/assets/mainnet/7gAhXVMvd3DCsajz2baKoVd1gouNrvUNs64CqMHoFqqy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HS5vNPFkucPqMy9Q75poiMioq8eME5ekPpkQsuesRRTL",
            "symbol": "TLTK",
            "name": "Tillman Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TillmanTokens/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8EFGJUpWty9woWLWeDnbkEEQevVC87vn4GozkVtexDvm",
            "symbol": "CARR",
            "name": "CarrCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/carrcoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6jPAiSRGjEcDNJGHC85WFn94VNMkmoJhU8wdqoc8cWS2",
            "symbol": "FART",
            "name": "Fart",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dranderson21/Fart/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ZUiEH3UjarfBYwYDPBQtVJySAopmoxdqokw25EuWzRS",
            "symbol": "GDSC",
            "name": "Gray Data Social Credit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/technopharmer2000/crypto/main/GDSC.png?token=ALXPHJMMBXGLIKIPTVGRHTLBWVOCS",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4jgmjdQUWEZ4EAzi4TWSz3DnGLgruSA29wp1thZkzP5j",
            "symbol": "KKAI",
            "name": "Kalani Kai",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/engage-intellect/kkai/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GddE977h9ShBB1wCHmaTXfcCZZFMs1KA5Ag7YxajSf3r",
            "symbol": "DGDC",
            "name": "GavinCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cfultz/crypto/main/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "support-musicians"
            ]
        },
        {
            "chainId": 101,
            "address": "7ibGhXXWYMacnnHxx5tR6NytJc8cJUh7HvAWSozXr5UT",
            "symbol": "GABA",
            "name": "Gabbano",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/manutije/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4ziFfbYCNqVRcv4HwF36v7PCrg2mRA2qvqExoGrCTAPL",
            "symbol": "HLXN",
            "name": "Holoxon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TyronSmiteApril/crypto/main/holoxon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "HS5vNPFkucPqMy9Q75poiMioq8eME5ekPpkQsuesRRTL",
            "symbol": "TLTK",
            "name": "Tillman Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TillmanTokens/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "fppmFy1QetV3Ztt6EdBNqethbhFotJ6oAkwhQM93Qfe",
            "symbol": "LENGTH",
            "name": "Length Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/length967/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FK1ZUhj2sddnR2epLvK4cvrKmY22gv3dsio233iuAcq8",
            "symbol": "LILD",
            "name": "Lil D Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sdesopo42/Crypto_Lil_D_Token/main/Lil_D_Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AJ6EVZEePA5HAn58ZRbLCWX3MsK2BKrkT6bJYunuTU71",
            "symbol": "NIRV",
            "name": "Nirvana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ6EVZEePA5HAn58ZRbLCWX3MsK2BKrkT6bJYunuTU71/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "NIRV Is a token created for fun might turn into something cool we don't know yet"
            }
        },
        {
            "chainId": 101,
            "address": "A7Uvm8dvtMDNrYCKmHyox6BKYSkxU6DdYmTyfZA96nNW",
            "symbol": "COLE",
            "name": "Cole Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/egobyte/crypto_image/main/cole.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CXpU3zvNdQsgb6xVEcMYHLP3FQyFDv6Kck1f3fugibN7",
            "symbol": "PITC",
            "name": "Pit Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/narana2/PITC/main/PITC_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FoFMZpggKZ9Eq8gS582Z98w5mqXTuLQRmYw9c3gVEKW7",
            "symbol": "OSIP",
            "name": "OSIP Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/batangilog/osip/main/image.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "57zX7VtLqWMM3CJCbqGnyQr3kzN3sYMiYjoTujWf7g7y",
            "symbol": "SHAVE",
            "name": "Shavecoin",
            "decimals": 0,
            "logoURI": "https://github.com/awinterm13/token-list/raw/main/assets/mainnet/57zX7VtLqWMM3CJCbqGnyQr3kzN3sYMiYjoTujWf7g7y/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "97ner9bBhnmbg1yZXMh85WNYsYSLTqUb4RvyMttD57fh",
            "symbol": "FJBT",
            "name": "Fuck Joe Biden Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Texaglo/-fjb/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9pypdozsBG1KVABkQvHTzgEuAXfSj5cQour9cB73SLLg",
            "symbol": "$TBS",
            "name": "Texaglo Blockchain Service",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Texaglo/-TBS/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "hsX5YHyYMME4zaxfHCaxdPsGBZNW94Cir5GDfVh6Hc4",
            "symbol": "INMA",
            "name": "Inman Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/minman1979/Crptocurrency/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F6ZoD5zLzpFG7r35Gi4tMKbqxU8hMaQkK2VKaYpQQsEJ",
            "symbol": "BR86",
            "name": "BloodRose86 Cion",
            "decimals": 0,
            "logoURI": "https://github.com/BloodRose86/crypto/blob/main/bloodrose86.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "55cVxgRyn4WS6dTdzfNepn2374v46xRUdk31sH1eYi7R",
            "symbol": "CLR",
            "name": "Celerity",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/celerityd/celerity/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3aXrbPCeUG9g9M3PGujB9bMaMHiodsCa1qmyhb2QWRko",
            "symbol": "DJCF",
            "name": "DJ CandyFlip",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CandyCode-CC/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "7M2dxU1tavGpKX47baKCsspBjZ5Rcthcun8DDouSU49x",
            "symbol": "USDC-USDr",
            "name": "Raydium LP Token (USDC-USDr)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7M2dxU1tavGpKX47baKCsspBjZ5Rcthcun8DDouSU49x/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "8Tddz1epzDM5nvEaYc5uPtmgu3DCRabRxsnfXu6yUneD",
            "symbol": "ETH-SOL",
            "name": "Raydium LP Token (ETH-SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Tddz1epzDM5nvEaYc5uPtmgu3DCRabRxsnfXu6yUneD/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "4sGmJRR53TLUo6S1ovFChdEuEssjymPNuEaaDQacuKws",
            "symbol": "ATLAS-RAY",
            "name": "Raydium LP Token (ATLAS-RAY)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4sGmJRR53TLUo6S1ovFChdEuEssjymPNuEaaDQacuKws/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "AZFu6w1oj7t9QSgcBhpHdVmSp39QLuk6dMcqkqJ1cHXP",
            "symbol": "SAMO-RAY",
            "name": "Raydium LP Token (SAMO-RAY)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AZFu6w1oj7t9QSgcBhpHdVmSp39QLuk6dMcqkqJ1cHXP/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "6EJAff7wySn7TMeGLH8Nk7XCZ9uYdkrYiiM5qA4sKR8i",
            "symbol": "USDr",
            "name": "Ratio Finance Stable Coin USDr",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6EJAff7wySn7TMeGLH8Nk7XCZ9uYdkrYiiM5qA4sKR8i/logo.png",
            "tags": [
                "stable-coin"
            ],
            "extensions": {
                "website": "https://ratio.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9uVxrZA32nPdYqvwuzNLrgFMJemMq6dYwesy3LDfnZRq",
            "symbol": "CHILI",
            "name": "CHILI COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CY4N1DE/chilicoin/main/chili.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6xGpXJre3Lp4yr8y22QdQGzooKaa2PTgksjwGUpMJoJi",
            "symbol": "22MTA",
            "name": "META 22",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6xGpXJre3Lp4yr8y22QdQGzooKaa2PTgksjwGUpMJoJi/logo.png",
            "tags": [
                "utility-token",
                "GameFi",
                "DeFi",
                "MetaVerse",
                "stablecoin"
            ],
            "extensions": {
                "description": "Meta22 Token",
                "instagram": "https://www.instagram.com/meta_.22/",
                "waterfallbot": "https://bit.ly/Meta22ToTheMoon",
                "website": "https://meta22.io"
            }
        },
        {
            "chainId": 101,
            "address": "8xzn4uTZUDH4Etx9NPZeRsiXYoQDkahFEWrh7fF9uP3M",
            "symbol": "SMDG",
            "name": "SmudgeyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nebbby14/crypto/main/smudge.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "Av8oBwC39SJGWUH94wPJVzjSbaEReKZp8cYP6zUzvqQP",
            "symbol": "NLC",
            "name": "NewLifeCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/designerbjk/newlife/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "52NggxAXyBqp32P2fpdvcTrR1b3mcasBBkdjwzLUXjWN",
            "symbol": "EZC",
            "name": "EZ Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ToTt0G/EZC/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GZhRs3M3yzLAPCcRqgdHsZzLA6KU5ijWqgm1TzfP32P9",
            "symbol": "DMMY",
            "name": "D\u0026M Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/donovanm21/token-list/main/assets/mainnet/GZhRs3M3yzLAPCcRqgdHsZzLA6KU5ijWqgm1TzfP32P9/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Rock9rpDQsjm8Lme6GWp7Zn94efuQpGvB8x54mmMvad",
            "symbol": "ROCK",
            "name": "Rockstar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Rock9rpDQsjm8Lme6GWp7Zn94efuQpGvB8x54mmMvad/logo.png",
            "tags": [
                "utility-token",
                "fan-token",
                "concert-token",
                "musician-token",
                "merchandise-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8y1Srnf32DRcjHV9NaP4mTQ64ugjnuG2qxuuXyAa9Tq3",
            "symbol": "elRON",
            "name": "electronic RON",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ilc000/crypto/main/elRON.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8yGd68J1jn9BTpo2yy9EnUUUd9Hb7Ea7guDouDxBL7Hi",
            "symbol": "FIBU",
            "name": "Firmware Build",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/firmwarebuild/crypto/main/LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3jpmqo232z4MpkAPGAF4ZMYCMMmwKDPJRFw9x8xEcZ9v",
            "symbol": "CITC",
            "name": "CalligoIT Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nevenkordic/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DzyVnoAg8vv25eNVWvf95vqWJ6Jx4LuoTVRJxzSk5aQP",
            "symbol": "MANA",
            "name": "Mana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DzyVnoAg8vv25eNVWvf95vqWJ6Jx4LuoTVRJxzSk5aQP/mana-logo.png"
        },
        {
            "chainId": 101,
            "address": "AJ5potzXbvwpevuMWqx7SaQCtRCuWfqYNXr9hrrwLhig",
            "symbol": "IIM",
            "name": "IT IS MINE COIN",
            "decimals": 9,
            "logoURI": "https://github.com/coolsilver1984/itismine/blob/main/final_61b5f91a3d0e2d00b83779fd_645128.png"
        },
        {
            "chainId": 101,
            "address": "839WJzo5rausr5LkJUeDfZipjVHG4fsq67vyhyhVsJF3",
            "symbol": "LVEC",
            "name": "Love coin token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/commorad-zekri/crypto-love-coin/main/love-coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBnhiayTEVC1Qn6fn2iWJ1DvqVTYTjvJCy4UUYJwJBPW",
            "symbol": "LZTRN",
            "name": "LozzaTron",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LaurenceThornber/crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AcypHt7RkiQuGSy22jnDBibQhJZHnCnT6ybiJbuUA3AM",
            "symbol": "NRDL",
            "name": "Nimrodel",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NimrodelGR/Nimrodel/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F2sMWsxnKjtejFHchYtvMrmyMkFFR6Pg3n2SZQihemLN",
            "symbol": "CFJP",
            "name": "JP Scriven",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cfjps/crypto/main/logo.JPG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Xant3HaiLVaYqzSzZQwGHCzbtptBS3npVfuREkQHni2",
            "symbol": "kiwik",
            "name": "Kiwik Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yassix91/habandagaCoin/main/landing-kiwik.png",
            "tags": [
                "platform",
                "videos",
                "payment"
            ]
        },
        {
            "chainId": 101,
            "address": "7SccykJkXNyZqh7nBjDm3NjHNVtZ8BgLEBd36crgbLYZ",
            "symbol": "RSHS",
            "name": "RealServer_Ro",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Z33uSsss/crypto/main/logo%20real%20server-factura.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bp7aKzRUjusE887totYfwLNYXkiPEWKiAE863sTFRKh4",
            "symbol": "ATOM",
            "name": "Atomfire Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thenerdparadigm/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "444rGVugJ4zyt8hMsjMPNeJL8VZEomiVusuYfTfBigKd",
            "symbol": "BRC",
            "name": "BloodRose86 Coin",
            "decimals": 9,
            "logoURI": "https://github.com/BloodRose86/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GGz1x1Y9sFNPnWXbNwyUe9hqHZoVs4xzBebUmqhiEQzX",
            "symbol": "CROC",
            "name": "CROC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/neversl33p/logo/main/Logo%20coin.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaCBS"
            }
        },
        {
            "chainId": 101,
            "address": "9i234nZC5JSXVTJh5iUAaNXNhTejztzvMdB4A5dLTqCU",
            "symbol": "SNA",
            "name": "Sonata",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jolistic/Sonata/main/REDSNA.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cc5qmXcJ1FjtR9GqmaHG9VfVTH7Kg5b5G7m5qKCagCP2",
            "symbol": "DSN",
            "name": "Dubsteet Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Dubsteet/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8tDCkvnhZzzrLLML218xs2etYbqMugfG34CVPRdxa7AP",
            "symbol": "OXSH",
            "name": "Oxy Shard",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8tDCkvnhZzzrLLML218xs2etYbqMugfG34CVPRdxa7AP/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "OxySpace is a Play-to-Earn PvP and PvE game based on blockchain technology.",
                "discord": "https://discord.gg/9rthWDuB3B",
                "instagram": "https://www.instagram.com/oxyspace.app",
                "twitter": "https://twitter.com/oxyspace_app",
                "website": "https://www.oxyspace.app"
            }
        },
        {
            "chainId": 101,
            "address": "AQcvRhLY9ebLLXkoScWH5QQTvPg1xpnCdngMkqiXww74",
            "symbol": "AARD",
            "name": "Aardvark Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AQcvRhLY9ebLLXkoScWH5QQTvPg1xpnCdngMkqiXww74/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/OrycteropusA"
            }
        },
        {
            "chainId": 101,
            "address": "AJ38ch6VQ5Uxc1mZVJPDj34FRzTZqrNCxot9bRUak8Nu",
            "symbol": "BOKO",
            "name": "BorisKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bobtiji/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8A58At5hRv4V5DEBeN2nZ8AzRhqeB47bUpjL2fLJB1si",
            "symbol": "IMINENCE",
            "name": "Iminence Software",
            "decimals": 0,
            "logoURI": "https://www.iminence.com/public/img/cms/iminence/logo/256px.png",
            "tags": [
                "cms-token",
                "iminence-token",
                "software-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/cms.iminence/",
                "twitter": "https://twitter.com/cmsiminence",
                "website": "https://www.iminence.com"
            }
        },
        {
            "chainId": 101,
            "address": "93zxUZEMwo4tCS7xmoGbDaRhHUmb1xz4dm38g2kv23GV",
            "symbol": "TRD",
            "name": "Taradactal Token",
            "decimals": 0,
            "logoURI": "https://github.com/HadiMorrow/Taradactle/blob/main/logo.png?raw=true",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BNpbt5qqWX27WQXmw6DmrbtVzbpEfy8Tj5DM6uTQphJR",
            "symbol": "MARVIN",
            "name": "Elon's Marvin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BNpbt5qqWX27WQXmw6DmrbtVzbpEfy8Tj5DM6uTQphJR/logo.png",
            "tags": [
                "MARVIN",
                "Community-token",
                "Elon"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MarvinToken",
                "website": "https://www.elonsmarvin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "88mRh7c5RVgicAowFqHxpPHDY6yVDxf1H2uPS9TYw47K",
            "symbol": "MAGT",
            "name": "Mohammed Ayaan Ghugharia TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AyaanMAG/AyaanMAG-Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7HM8oZs8r5LkZKjCH1Te7aCT1ssTwWUE58rVg6ftaZTQ",
            "symbol": "NIRO",
            "name": "NiroCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NNirosan02/crypto/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BrQU6uJPLRi8BXb9FXJMwcvRceKgsPJmx8ZkbWaFt8e6",
            "symbol": "PB",
            "name": "Panda Bucks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mrdonpanda/pandabucks/main/pandabucks.png",
            "tags": [
                "Panda",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7UHXbSY6Wnta6EJakTMvYxmWFbqCrTvmGm6ez3Fbj1W9",
            "symbol": "SXCT",
            "name": "Saxocat",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/podycust/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HE743xcwoEPaFGWmfXXpvwqWZ4MkjGxw9t2JZADmMb4y",
            "symbol": "JVJX",
            "name": "JavaJixCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JavaJix/JavaJixTokenLogo/main/java_jix_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FUeyuZqhaJwwVnJraM1HfJENJEEVi4akuGKjrbV5oqqB",
            "symbol": "RAW",
            "name": "Raw Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/idomez/rawcoin/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5APccmJuY2hnmXqN8yjMA97k3obB6xH5Xah1eTiLa5eG",
            "symbol": "TUTT",
            "name": "The Unkown Tipping Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TUT-Token/sol_token/main/tut.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AknQ9t63Zr2xATRdoRqETfJBzRLTLzPaUS4pdu5ZrKfc",
            "symbol": "SCR",
            "name": "SolaCredits",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AknQ9t63Zr2xATRdoRqETfJBzRLTLzPaUS4pdu5ZrKfc/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "nft",
                "Gaming"
            ],
            "extensions": {
                "description": "Sola Space Academy is Space Training Exploration Challenge Game.",
                "discord": "https://discord.gg/9TtWpwxvfH",
                "telegram": "https://t.me/solacommand",
                "twitter": "https://twitter.com/solacommand",
                "website": "https://ssa.systemtechs.co.uk"
            }
        },
        {
            "chainId": 101,
            "address": "6p8FkfLtzUcYAndy9raW2zJ6W7D6bPeB6Ey8gUKLpw4X",
            "symbol": "BAER",
            "name": "Baer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bathsource/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "12jnEAyj5qkW9UPfQS7z7Yxz3LB9cHXdg65sZTfMj6Gg",
            "symbol": "ARTEL",
            "name": "ArtelCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Artel-Scaffolding-Ltd/cryptodev/main/logo/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GERkQKPenbJPoaLny5tfdbVzfDZpmkARFHdXN7KNLBSu",
            "symbol": "OXEN",
            "name": "OxyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wet33523fsdf/oxycoin/main/logo.png",
            "tags": [
                "social-token",
                "oxy-token"
            ]
        },
        {
            "chainId": 101,
            "address": "QbHcjuaK1iKXxd9iKbpaQv3w3wFSoUX9CDm3Kp5EQLi",
            "symbol": "DOFF",
            "name": "DoffiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dofferson/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9pXKosNGYSczhyb8e8k9sjc7SPCzvTc1g1bMkrhK5tvv",
            "symbol": "XECO",
            "name": "XEOS Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TurtleDevil1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ALk6U66xwzsWff79heA38ciVuN5uUQg4hVtMLewHcnpt",
            "symbol": "MKCN",
            "name": "Milky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Noah-Engineer/Milky-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2zCtgdca2wYH7Hu1hecR1hrsGEh5dHmNA8BcaUoNmda7",
            "symbol": "CSH",
            "name": "Cashano",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cashano/csh/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6mDnpYW73bp2TJeRghuDhKipVvNfDvPjQf8HjnyKTSGJ",
            "symbol": "KSCC",
            "name": "Kitter Skitter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/whooliepenguin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CH1UzhpTpq3C4Sxfa3qd3W6h7my464Y1Nmws7kTtezgm",
            "symbol": "WAC",
            "name": "WACoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Warriors-Army/WACoin/main/wac-coin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "A virtual economic system for my community.",
                "discord": "https://discord.gg/N49Gxsu",
                "twitter": "https://twitter.com/WarriorsArmyOff"
            }
        },
        {
            "chainId": 101,
            "address": "HvYpb3sRhusCXPw5wFGuv9vMhnAcpDTsoh3pKVgXQ3kH",
            "symbol": "DICN",
            "name": "Dissy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/marcopollacci/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EBZ1yiEQV64sD4hjGpase7xH8zyc9kBdnjS8hCVdx895",
            "symbol": "WDE",
            "name": "Auburn Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dpd696/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dpd696"
            }
        },
        {
            "chainId": 101,
            "address": "BawvMiq3zUvY1pBcHLkhQJv14NWP4rQLDFXw5Q64CCjn",
            "symbol": "DUDU",
            "name": "NUMANG TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haocheng1997/dudu_token/main/numang.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7sAh3TsoT2fFDm9ERKbHpco4oRyytanpcdXFfRCTbupR",
            "symbol": "TERP",
            "name": "Terp Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7sAh3TsoT2fFDm9ERKbHpco4oRyytanpcdXFfRCTbupR/tt.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EZrBfhaPN8JpA4ecpnQHSkj6szGBisnvpcn1kfdCh2ok",
            "symbol": "CAMP",
            "name": "Camp Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EZrBfhaPN8JpA4ecpnQHSkj6szGBisnvpcn1kfdCh2ok/logo.png"
        },
        {
            "chainId": 101,
            "address": "2waJmsGu1pd2a3VbAnQXnsaPRAAZLPcCuL3U4ot6QZkz",
            "symbol": "SOUV",
            "name": "Souvenir",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stasinuspierrus/crypto/main/logocrypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FxTMCsDCBaS9xAes1Q671wHdokWgv2n87iynLsv7W2Cq",
            "symbol": "HNNY",
            "name": "Honeybucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/honeybucks/crypto/main/honeybucks.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F7oER7ThE9ALwcTdqsb3JdVkPHNhge5t6TroEur5hB38",
            "symbol": "SCHAEF",
            "name": "SchaefCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/schaef1/crypto/main/SchaefCoinLogo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7jz9nNnLPkMf5N4aYCJP3fitvFw5moduhbw8sHNEFpmk",
            "symbol": "MSGC",
            "name": "Melcosoft Game Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/melcosoftclub/melcosoftcoin/main/logo2.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "A virtual economic system for my community.",
                "discord": "https://discord.gg/XzkVEKDg",
                "facebook": "https://www.facebook.com/melcosoftsu",
                "instagram": "https://www.instagram.com/melcosoftsu",
                "telegram": "https://t.me/melcosoft_official",
                "twitter": "https://twitter.com/melcosoft_su",
                "website": "https://melcosoft.games"
            }
        },
        {
            "chainId": 101,
            "address": "DNDYfdWL9dB5vyqLHncChJKs81VjnDJaqBwGMziFFkQt",
            "symbol": "SLDM",
            "name": "Slumdog Millionaire",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/smitpatel7/SlumDogMillionaire/main/logo.png",
            "tags": [
                "social-token",
                "NFT-Gaming",
                "Utility-Token",
                "Game-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "8B14CodaG7rQdYjGutpc3N74Qo4RHeiJnrFxSoYGTr8W",
            "symbol": "JCARE",
            "name": "JCAREcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DonovanVA/Crypto/main/JCARE.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "iaoa6DBpVv2bqtMjNFpXSbxtWdQTKsa3s9Xg897XNKg",
            "symbol": "KLYC",
            "name": "KellyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mvkellyjr/coinlogo/main/GoldenK.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H66uizqhr2Rye3xAWaCTeGXvhEWxasRdLGgKwctwkoEc",
            "symbol": "PORK",
            "name": "Pork Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/porkcoin4968/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FgJQJX9NoWqzBCgEFZuUuNXqrCh6tWzpeHwJqsKnSWhu",
            "symbol": "ISHA",
            "name": "isha coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/isha-sol/isha-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7YHXypSK2TKjBjzomxb29o6RJtV8q9C4Hyy979CZm5Th",
            "symbol": "HIAX",
            "name": "HiaceCoin",
            "decimals": 0,
            "logoURI": "https://github.com/VictorLaszio/HiaceCoin/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C7SRB4fH2evhkc6nFpM9oqN1ZpZ5NAnJLMBt6tegaFXA",
            "symbol": "NPTC",
            "name": "NeptuniumCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7SRB4fH2evhkc6nFpM9oqN1ZpZ5NAnJLMBt6tegaFXA/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BBQtieeXpnT5CztuhHBuVjuFP4XcpWTWf18FKKXAyBww",
            "symbol": "DJK",
            "name": "DJKouzaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/djkouza/djk-coin/main/DJK-Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A7ZGw8BLWEPiRKai4H1AA39fsM2hr2xwmGBcxZ2uUsD9",
            "symbol": "CROWN",
            "name": "Crown",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A7ZGw8BLWEPiRKai4H1AA39fsM2hr2xwmGBcxZ2uUsD9/logo.png",
            "tags": [
                "GAME",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/photofinishgame",
                "website": "https://photofinishhorseracing.com"
            }
        },
        {
            "chainId": 101,
            "address": "BdYkmFb3TNvZwWA8QXGKcsnFcPNFi1p1z9SaWbNgn6r2",
            "symbol": "JOMAR",
            "name": "Jomar Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jomartech/crypto/main/JomarLogo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D6APqJuEDZkT7mQz7C1ikBgypAc3tzYH86AnUuFxePWL",
            "symbol": "TCOIN",
            "name": "T-Coin",
            "decimals": 0,
            "logoURI": "https://github.com/Teddy0307/crypto/blob/main/T-Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2z9RV8Zr3cAtcXnkFXqP5dtwpsxAgAvJHCkYjETvmM5z",
            "symbol": "MEEP",
            "name": "MeepleCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2z9RV8Zr3cAtcXnkFXqP5dtwpsxAgAvJHCkYjETvmM5z/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Etkh3QdA1seEH3xaL37rJpwitLWXo5iYa8aiBiqcoET6",
            "symbol": "OFCN",
            "name": "Objectif Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ybraver/vue/c3a1c798c1ed37fc37fb36c471161c2656890372/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6qnRBUMk6Mr3xUCc5CBYWrdCb8Nr19U2HooLHcQ2ZxgQ",
            "symbol": "GBGL",
            "name": "Gobagoul Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Stcroix1414/gabaoulcoin/main/Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "12jExsp4wKGkVM64M97fis8R5jE67t5PXjZRzZcp2bcn",
            "symbol": "SYMP",
            "name": "SympCredit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomasflx/symp-tokens/main/credits/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6PfxF85LgsnzryHrEF2c8CYWyb3yZFtfvh1Mh9MmnbjM",
            "symbol": "BZML",
            "name": "BreezyModels Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/andreamarin23/crypto/main/My_logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wWThnqNrud",
                "instagram": "https://instagram.com/breezymodels/",
                "website": "https://www.breezymodels.ml/",
                "youtube": "https://www.youtube.com/c/BreezyChannel"
            }
        },
        {
            "chainId": 101,
            "address": "3iDK5HRmSf91fnkJ4x7UV2Rj53YyNdedtps6kddRnCtZ",
            "symbol": "FRC",
            "name": "Freya Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Jaeger790/crypto/main/freyacoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HHVz54Lh3Mx4mSZEtfd8spRcJdSaNKA8WhyxdniXrw7D",
            "symbol": "SDDS",
            "name": "Dexter Scooby Scrappy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gagar/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4X3JtV6USDr23Am4aSur9nDhfGfSR91TsmonCNw7VUkW",
            "symbol": "RKSL",
            "name": "RocketSloth",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RocketSloth/Crypto/main/Sloth_png.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RocketSlothCoin"
            }
        },
        {
            "chainId": 101,
            "address": "6GVGRXC5wf7NjnTQxaqEZErtdsHupke7Fiz5pUG5VkkM",
            "symbol": "HOOD",
            "name": "HoodRatsNFT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lito95/cheezV2/main/logo.png",
            "extensions": {
                "discord": "https://discord.gg/N3eVCCbmbB",
                "twitter": "https://twitter.com/HoodRatsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "8zbnRTFmyMKNpbUNJDr7rputhEjxFQ1wxwh2GJjnjP9",
            "symbol": "ORC",
            "name": "OSINT-Research coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OSINTResearch/OSINTResearch-coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/osint_research"
            }
        },
        {
            "chainId": 101,
            "address": "27UKQ4WVepzncEzW8ifofsaAmMCVyz5qdTJuJPEGrWxY",
            "symbol": "ELI5",
            "name": "CryptoKids",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/remedyblockchain/ELI-5/main/ELI5.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AzRY2yit9mHVaPzP2kH7h3K9EzWvY52eddUpGqaux7Y7",
            "symbol": "POPS",
            "name": "POP-SOLANA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Zenophire/POP-SOLANA/main/SMALL.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Xz9bhz8zAidDz6REu5CxDYdBdn3RQMXR2FPRxoWs5Sd",
            "symbol": "MACX",
            "name": "LeoMac",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/smacdonald23/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "Utility-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/feM342Y5"
            }
        },
        {
            "chainId": 101,
            "address": "GP85XWxGYtSAUsq5JKoN13hBTEqTBKPRANcohSGHd3Hx",
            "symbol": "MIAO",
            "name": "MIAO COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/qian262/Crypto/main/pngegg.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "metaverse-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "FwovKxHTJA8pLtSnkJWxFyTetTU1kKzg7o6gjRgFAAXf",
            "symbol": "HEDR",
            "name": "Heder Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HederGit/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AeC4LWpgMdYVekFwXn3yFA8PrsBG3QZFGYMvKFRJLdPb",
            "symbol": "VMTK",
            "name": "Vitor Moreira",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vhlmoreira/crypto/main/logo3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "27uumiju3EkRoPzWvwRub9eGBVwYJaBfFQyFmxCXr82H",
            "symbol": "RAMK",
            "name": "RAMKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/myattriot/Testing/main/RAMCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9FQnWG6GaUq3cTcQ8PPPjUdQB2QyTB522yUJhj9FEA6r",
            "symbol": "SLCN",
            "name": "SolarCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tejums007/solarcoin/main/solarcoin.PNG",
            "tags": [
                "solarcoin"
            ]
        },
        {
            "chainId": 101,
            "address": "9bFhizH8pNDxfNxFoA2Tdf3UwAV7XwGEbCC8penaExj6",
            "symbol": "CAIRN",
            "name": "CairnsCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mikecairns1/cairn/main/logo.png",
            "tags": [
                "Cairns-Coin"
            ]
        },
        {
            "chainId": 101,
            "address": "4HFqBfbTHdCSVED2E1FaMrkNgNikw6KNy7FnchSAohWP",
            "symbol": "DBRK",
            "name": "Dr BurkeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JamesPBurke/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hq9UCHNNTifPhbdJMSxXcE6ba6xALZ4uh5s6xVq3cJmQ",
            "symbol": "KBS",
            "name": "Kai Bell Soup",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WhirlyBell/KaiBell/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bz8U9LupMio1mk12CDZi2hhbpraBzkjpbTaBdMYt89xF",
            "symbol": "PHBC",
            "name": "PhoebeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/phoebecoin/crypto/main/phoebe.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DnEGjYuPq9UzZSBcqfHvpqHB1KKKyNgCEPsELFDXJNyC",
            "symbol": "YASB",
            "name": "Yassin Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/YassinAbdi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9kufJs6yVbtZrwCiNVLyjKPyjY3d3iVUVos3ujvgn9Wt",
            "symbol": "TFNE",
            "name": "Tranfinite",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gomez1112/crypto/main/Twitter%20Profile%20Picture-modified.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FA8KHovFPzhBDTs9LmP6kHbR21APLaPjpTjTFeKATDbC",
            "symbol": "SJC",
            "name": "Sjadey Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sjadelin/SjadeyCoin/main/SjadeySolutions_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C5AUZsFv35EuJEaGMoRyGJu9jzJ4VBxEvSia3Sry357h",
            "symbol": "SBTK",
            "name": "ShitboxToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bananaman2020/shitboxtoken/main/shitboxlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "84K5R4beJJMiLpnVF9uaRpKrYSuFG6k4mBecAkpKcky6",
            "symbol": "MJMJ007A",
            "name": "James Murray",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mjmj007a/crypto/main/1-mjmj007a-hires2_Estpa-sm.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HkNokfCXG33eu5vCcS49mq3jZcKZeQSQCyta964YxxYg",
            "symbol": "CRRT",
            "name": "Breezy Bunnies Carrot",
            "decimals": 0,
            "logoURI": "https://breezybunnies.com/images/3-p-500.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Breezybunniess",
                "website": "https://breezybunnies.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3oSUm4nYjtqTuDTg59cCwoFHbR6wYQsbM8xXuJ4K8vvG",
            "symbol": "ZTKN",
            "name": "Z Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZToken/LOGO/main/TOKEN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RqvX8H6QqEK556zatjaH772Rmu3wdx9ovdZqZMtkwe6",
            "symbol": "DRAGON",
            "name": "DragonCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/azcoigreach/dragon-tokens/main/dragon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJRESWVMtcAKAFoDzz3D7wnfxbhiuKJUZ81cVDukwusd",
            "symbol": "THDC",
            "name": "ThodoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/staurakis/thodoscoin/main/thodocoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9d99rTT5XMfDnb7BFG5j7ejJGU4RVghd1V2ysLh4CyKj",
            "symbol": "PC",
            "name": "PearCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/timpan77/cryptoc/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DcKqtwACf8tSncmyvSmTsXLizXin48TPWAdz9JEfgyms",
            "symbol": "NXC",
            "name": "NixCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/charbelll/nixcoin/main/nixcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4d9wEUBVUh8cYKoYkBkkwcUMsaRygim2gRJqSycALqw7",
            "symbol": "CNDMB",
            "name": "CanadianMoonBoyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/trevoroleary/CNDMB/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FnQ5HFHJjyJ2Ut5Ax3QMpG6LbU8rQuwK57CWzG45Vm2L",
            "symbol": "ivlt",
            "name": "I-Velt Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iveltcoin/token-list/9c11878fdedfe051d9341551db7caf03527652e8/assets/mainnet/FnQ5HFHJjyJ2Ut5Ax3QMpG6LbU8rQuwK57CWzG45Vm2L/site_logo.png",
            "tags": [
                "social-token",
                "ivelt"
            ]
        },
        {
            "chainId": 101,
            "address": "Bt3nRHbGf82y4ywqpBZ5FAHJ95uZJPwpmZMSZRpFxjXt",
            "symbol": "PRMT",
            "name": "Promort",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/doubled1105/promort/main/IMG_2026.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2dEmoDzXBgkQ5qfgHieiEpyytjBqB1j1cvR5CSZrnSB3",
            "symbol": "CNNC",
            "name": "CnnctingKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robermakram/Cryptocurrency/main/IMG_4092.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4ZV4yb127M2xULJKfMwprNCni4V8KCnTcMUcQ56iJD78",
            "symbol": "HZC",
            "name": "HorizonCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EmiN364/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7sDbbcYiDAY5gqz3mCRD16JpuudKPUkCEcmc5JE4Z74s",
            "symbol": "KEC",
            "name": "Kieran Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cloudysoda4/kieran-coin/main/coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "219T3V6e8EBegoNKFYMZ5VSvMw26JBwmgViG7nDNp4b9",
            "symbol": "POT",
            "name": "Potato",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bensat21/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CNuggUieEKApV1cfaKyvG86qu3Bg5fVtozbbLxVGz5ky",
            "symbol": "FRLUV",
            "name": "FreeLuv",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/greenel/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HaD4sYtdJQqAcXnpZkbv6DjTC4Qa2YyHeirwrKfP5TLr",
            "symbol": "SYMPCOIN",
            "name": "SympCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomasflx/symp-tokens/main/coins/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CJdZt6kta7i3Z1hg5g6PpsCVD1cDCn7zYWWkLpXcwvba",
            "symbol": "PUGC",
            "name": "PUG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/celerityd/pug/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yzLrXYxYw7VsFvcpPnyvWpedyCKkorMVETq31vKsNvQ",
            "symbol": "HAAH",
            "name": "Hamzah Alhumaidi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hamzahalhumaidi/crypto/main/images.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DL1WSv3tZpYr6pN489XXgP5GFvLyGtoDy6EW6dL6bFfL",
            "symbol": "0T",
            "name": "Jeweloophole",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Jeweloophole/0T/main/logo.png",
            "tags": [
                "detroit-mentality"
            ],
            "extensions": {
                "website": "https://jeweloophole.org"
            }
        },
        {
            "chainId": 101,
            "address": "A6k6EFVrW9MLEpWnwFZTqBcMKkB6VjyPH8RyVMddb7pJ",
            "symbol": "LMRV",
            "name": "LilMarv",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RayG96/marvcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6c2rt6aYpRKwddwwtE68BS2ok8y4U8VUZdThCjfcX38M",
            "symbol": "AAP",
            "name": "AstralApes",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/sungminkim01/token-list/main/assets/mainnet/6c2rt6aYpRKwddwwtE68BS2ok8y4U8VUZdThCjfcX38M/logo.png",
            "tags": [
                "AAP",
                "ASTRALAPES",
                "ASTRALBABYAPECLUB",
                "ASTRALHOUSE",
                "ASTRALJUNGLE",
                "ASTRALMARKET"
            ],
            "extensions": {
                "description": "AAP is a renowned NFT aggregator that offers a diverse range of NFT solutions. AAP enables organizations and start-ups to incorporate blockchain technology into their current operations. Users may use AAP to develop, own, and trade NFTs in a variety of industries, including entertainment, music, artwork, and esports. AAP is used together with play-to-earn mechanics, allowing players to earn tokens and NFTs while playing games.",
                "discord": "http://discord.gg/astralapes",
                "instagram": "https://www.instagram.com/astralapes/",
                "twitter": "https://twitter.com/ASTRALAPES",
                "website": "https://www.astralapes.com"
            }
        },
        {
            "chainId": 101,
            "address": "7JqTBx8rPmnHqzZjnzud6eyyqjuRVQeUmkZhPfx3rW5L",
            "symbol": "CUVY",
            "name": "Curvy Shroom Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Papinski/crypto/main/shroom.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31hz8bvkaJEAwhg1JVCbtNvuQ5dJKSZGMdsNQz86At99",
            "symbol": "COCN",
            "name": "CachorroCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LukeM94/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4LiYbWTHuKz9A4XVeUumjg1mwys5XUTyZzPWMtZYjeoQ",
            "symbol": "TKR",
            "name": "Tucker",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrshawntucker/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D3DrumetAR9yjcKLYYbxzGDtqovgqGSse5AYkdby5oVw",
            "symbol": "GG",
            "name": "Gruesome Goblin Token",
            "decimals": 6,
            "logoURI": "https://www.gruesomegoblins.com/assets/images/Token.png",
            "tags": [
                "GOBLINS",
                "NFT-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/gruesomegoblins",
                "website": "https://gruesomegoblins.com/"
            }
        },
        {
            "chainId": 101,
            "address": "A5pAmfS5PA7STgLh2wUpUy4LPGa7xqujMkLvZ9xwdPfv",
            "symbol": "STC",
            "name": "Scietech coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MCG10coder/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HeJ1yyA6v657o7AJgPF2GiW5yoinobc6CjMVudaTXr9R",
            "symbol": "MKT",
            "name": "Monkey Token",
            "decimals": 6,
            "logoURI": "https://i.ibb.co/MPn4qRD/icon.png"
        },
        {
            "chainId": 101,
            "address": "A3xL7crmipM83EqMDgRhHqzvn7RtR3724RH3d8Q5QJUP",
            "symbol": "TDTC",
            "name": "Dick Tickler Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dallasat/coin/main/tdtc.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7iBrwijJn8cHmvjNMYDKe7BiES3m9rBh7jtkZRBbfVpJ",
            "symbol": "TRTC",
            "name": "Traina Credits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dallasat/coin/main/dtlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ueHVLNy1mFrr9YucxVyvF287CR2Zs8v37TwAq1aqivw",
            "symbol": "TLWJR",
            "name": "TLWilliamsJr",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GinJudokax/TLWilliams/main/SOL_Token_Pic_1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6rAYvHh7QZQZJ8K5aS44k2C2XB5r7RcFSBV8piUWAwTg",
            "symbol": "DOBE",
            "name": "DobeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/northportio/crypto/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "6tFDVzLLy87Wk42dqQjhC6p731rrKpHNhEXjQnFqACFW",
            "symbol": "SPL",
            "name": "Space Lemons",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gamer4life1/crypto-pic/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2nePy8QQqjhPLWg3Kp4RRKGGcvvQ3hf9L7YWp6BjNN9c",
            "symbol": "RCDI",
            "name": "RyCanDoIt Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rycandoit/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3qvg2hSA4NHhe73Xv6rUuhFoGM77VBvZrmE4tWSHMQWe",
            "symbol": "PHPC",
            "name": "Philippine Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3qvg2hSA4NHhe73Xv6rUuhFoGM77VBvZrmE4tWSHMQWe/logo.jpg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://www.sagesoftcloud.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AcndTryRKudMeYQLmn8cxXRKCrHow8paJUndxS3YmBC8",
            "symbol": "APT3",
            "name": "APT3 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stsomeware/apt3/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.apt3.com"
            }
        },
        {
            "chainId": 101,
            "address": "G2CXQrHh8R8j5fRuLLjQKZi22tBmAr2dNMRLxetmFmpy",
            "symbol": "MKYC",
            "name": "Mikey Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ItsMikeyy/MikeyCoin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7uhJguD9nMQUE4Vka7EXqF2xeZbuQ5ehPt3cbTBomvXM",
            "symbol": "DRYL",
            "name": "Daryl Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uhJguD9nMQUE4Vka7EXqF2xeZbuQ5ehPt3cbTBomvXM/logo.png"
        },
        {
            "chainId": 101,
            "address": "2A88e7yXkNcgb8vwKvFbUhnQciCjE2Nd1QQmxt5CMdmn",
            "symbol": "SHCH",
            "name": "ShchichCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aksid83/shch/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3RtkQCZYd3ZDW4F8mAkZ9EsztJXTmQau3uDtXdD92KM1",
            "symbol": "NC",
            "name": "Naz Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/cyber-arknet/nazcoin/main/nazcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDdFjjyciMqE1mE4nwXMWKDYXUuzA1jbM7zPUF7CLYus",
            "symbol": "ELI",
            "name": "Eli Lapkin token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eli-lapkin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FCu4aywtY4FWVsHg3FacRDSMgF2WHXZdmFrCcXVYsuFZ",
            "symbol": "NINA",
            "name": "Nina Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thedamian/NinaCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HVrk6yPpVABm17GQf9ac28QGgpNa9jDUnJ8yTx5WT6am",
            "symbol": "EST",
            "name": "KE4EST COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ke4est/cypto/main/altcoinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BxVxptuPHfUbsebvsYgiHxAxCmLgT3zeYc8n1SAvPRdr",
            "symbol": "OCC",
            "name": "On9 Cat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/starholes/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Sv5TQPQtv3gA9D5ucp7XoG8TDGbo4UNBhACcg36G9iY",
            "symbol": "NOOB",
            "name": "NOOB COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Xstar97/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2jCtt2fy9Cq5MhdgxH7Rj1e1PHByziiuW9WQBqtb4uPW",
            "symbol": "HOTROD",
            "name": "Hotrod Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/markeo4/crypto/main/HR_Coin.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/bYY7CsgN7y",
                "twitter": "https://twitter.com/HottestRods",
                "website": "https://hottestrods.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7dQ5ojWAVPBUT5HqUUj8dXo5VsnpjBEc1axZUyoLmcex",
            "symbol": "SEDDY",
            "name": "Seddy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ymiseddy/logos/main/coin/seddycoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "tCchMAUtunqzwJ9aCm3co5YiUzRSceEmf2bw46hmAbw",
            "symbol": "TGNPRO",
            "name": "TGN Productions Inc",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tgnproductionsinc/TGNProToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8RmcC8ENzd4C6By6puSY6DKe8KBTdpG2w5xAxHGUaTMG",
            "symbol": "EKIT",
            "name": "Ekadrishta Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EkadrishtaITSolutionsLLP/crypto/main/logo.png",
            "tags": [
                "social-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BL23qc9ddViFVeQ3vn4cXRPdqd6C6K8Yb2CtrQ2etkMb",
            "symbol": "XNTK",
            "name": "Xengil Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Xengil/xntk/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DEbeG7mzbAhxmA6WMqNLhb62vrQtuq7mrxWdYk8GjCMn",
            "symbol": "LYTRM",
            "name": "Lytrium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/edmiecheil/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Em9L4m8VZUpRUiajnwmkDMXeJ7yvGfVNZraaGknnsB4A",
            "symbol": "WRO",
            "name": "WRO NETWORK",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Em9L4m8VZUpRUiajnwmkDMXeJ7yvGfVNZraaGknnsB4A/logo.png",
            "tags": [
                "social-token",
                "fun-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4PGTcVUMoyaLUvqDhcKGGR2moo6oPe4DmWdHZsdGJcB6",
            "symbol": "TTM",
            "name": "TO THE MOON",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GalacticApeSpaceClub/imagemiib/main/Webp.net-resizeimage.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9r6vbKHTY5ZGVhCZMgCDw1MQMeXrRtSgfErgNzXt1q9P",
            "symbol": "ASSS",
            "name": "Ape Strong",
            "decimals": 9,
            "logoURI": "https://iili.io/7EE4rx.jpg",
            "tags": [
                "Meme",
                "Ape"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ApeStrongSeries",
                "website": "http://www.apestrong.art/"
            }
        },
        {
            "chainId": 101,
            "address": "F1XTV55UN5UMu7nkofLZGLSnswM4qU6iDfRRs4JDcvfp",
            "symbol": "TOKO",
            "name": "TokoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hayaitoko/tokocoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7P8WeYZFfccFcvRmNyKFGWaReUYGDt9177vPKzs3tFyS",
            "symbol": "xESP",
            "name": "eSoup Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/esoup/token/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mcyork",
                "website": "https://crypto.esoup.net"
            }
        },
        {
            "chainId": 101,
            "address": "ABRQiF7QFBqWA472DP6FhfbbVjtw9qS2NBN6As6LGuYg",
            "symbol": "CMU",
            "name": "Cyberindo",
            "decimals": 9,
            "logoURI": "https://cyberindo.tech/logo.png",
            "tags": [
                "utility-token",
                "capital-token",
                "DApp",
                "Application",
                "payment-protocol",
                "Payment"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CyberindoTech",
                "website": "http://www.cyberindo.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "7UUWK4HFvkFvhd6U4DxCBi1yY7XWhQguabXK1MxtANKW",
            "symbol": "KSD",
            "name": "Kyoudai Synthetic Drug",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7UUWK4HFvkFvhd6U4DxCBi1yY7XWhQguabXK1MxtANKW/logo.png",
            "tags": [
                "nft",
                "p2e",
                "kyoudai",
                "academy"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KyoudaiAcademy",
                "website": "https://www.kyoudai.academy/"
            }
        },
        {
            "chainId": 101,
            "address": "uofopKqHsuuJo21z1tinDMaXN18C4uXJGLkfddFh9zL",
            "symbol": "THBP",
            "name": "Thai Baht Plus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cryptoflash007/crypto/main/THBPlus1.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "3TMHmSNcTuAE6t3gnpJmFMmNUH1Mm2n6VoAQnGdfgGp5",
            "symbol": "CCT",
            "name": "Cool Carbon Tonne",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3TMHmSNcTuAE6t3gnpJmFMmNUH1Mm2n6VoAQnGdfgGp5/logo.png"
        },
        {
            "chainId": 101,
            "address": "e6r9LKsNoenL8a6dzqLUqgQr5qkQTGuwHYtdHnhAABi",
            "symbol": "RC",
            "name": "Roach Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tzeshen/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7F1ERKVW2j4zNxLPp8etPUj2argvrSQuZBYAzvAJ1Mj5",
            "symbol": "SNARF",
            "name": "Snarf Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iamsnarf/SnarfCoin/main/SnarfCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J3bPYVNNmnWbZpBbdiWCbCmRoCUFCasr76567YjKZdMt",
            "symbol": "NVRX",
            "name": "Nevrix Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rmarcel-firestorm/nvrx/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E3jdc9DMbYQnyBvhBQzqspyugMmY4x6QVfY2iLzmJnqV",
            "symbol": "WDC",
            "name": "WidCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/willidesec/crypto-widex/master/crypto-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J1SwjFPmo9zNE6UV1FphcR7ohkaLecCYDeKTkWHiNgKM",
            "symbol": "SANDE",
            "name": "Sande-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ravask/crypto/main/sande-coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Mv7tMjYVUReWGp9w8dqVkk6MfKZPSGF5XfE6Ez7foJP",
            "symbol": "TWC",
            "name": "TECH WISE COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CraigEd/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9L8vpXqxusiTx3qZFJeiHA5RAynuM8NFHfx62YSkkavB",
            "symbol": "FENR",
            "name": "Fenrir Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SexyWerewolf/FenrirCoin/main/logo.png",
            "tags": [
                "Payment",
                "Application",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "github": "https://github.com/sexywerewolf"
            }
        },
        {
            "chainId": 101,
            "address": "2NZo5Dd1qJDTTP3HCMvTPeP3A2HjaZX1UBwv7kk8eNbe",
            "symbol": "BC",
            "name": "Borneo Chicos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/borneochicos/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "86W1fnAL11sBVyAZH8B7TxRKPsMHqHw1wdeybEdbtZCE",
            "symbol": "TECN",
            "name": "Temicoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Temico/temicoin/main/temicoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7VRjzC7XhbEs8ciYL9HSwgXRSwaMArGELx3FAWgJhKMf",
            "symbol": "SPRK",
            "name": "Sprocket",
            "decimals": 0,
            "logoURI": "https://github.com/SprocketHat/Crypto/blob/main/1639397622620.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9MdLY4syYwLWgcmD8yzPvxZNGgmnb9Adcb3rFP1Amqfg",
            "symbol": "FNCY",
            "name": "Fancy Action",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FancyActionToken/crypto/main/FNCY.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dng7TUrbfwc6re3qMW6UaSwccq6N3pbmkhtZNo7LWYtK",
            "symbol": "KINGS",
            "name": "Kings Coin",
            "decimals": 9,
            "logoURI": "https://github.com/robwesselskw1c/kingscoinlogo/blob/main/kings_coin_bald.png",
            "tags": [
                "meme-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BaG8Nw8Ct72teFUeWXHSdzVW2y5A5BKTr77hfFG3Sei6",
            "symbol": "CNORD",
            "name": "Crypto Nord",
            "decimals": 0,
            "logoURI": "https://stephanjess.de/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RealCryptoNord",
                "website": "https://crypto-nord.de/"
            }
        },
        {
            "chainId": 101,
            "address": "DL6XYgEXK6pHpBjKV8CDCCigTk3NQ4Qwj3WfahUvFTNW",
            "symbol": "LILJ",
            "name": "LiljaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DL6XYgEXK6pHpBjKV8CDCCigTk3NQ4Qwj3WfahUvFTNW/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7B3tU3tWEs5ApW6audcT9MUDttP6kiV2XLfrdoVBeyQt",
            "symbol": "CVC",
            "name": "Civic",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7B3tU3tWEs5ApW6audcT9MUDttP6kiV2XLfrdoVBeyQt/logo.png",
            "tags": [
                "ethereum",
                "wrapped",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MWmhXauJw8",
                "twitter": "https://twitter.com/civickey",
                "website": "https://www.civic.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CB1piXLFESJuzaRtsTT5Ty6UX9P4Lu17csRvC9vFmowM",
            "symbol": "BJTC",
            "name": "BajtyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bajty/BajtyCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BKbs3bmCi78myaeFv7Jzj68pSPtM3VwpQHWsNwqE52w8",
            "symbol": "MIMA",
            "name": "Mima Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicktolhurst/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Duch2MmgCar9UGt76smK5HcJ7anBRa31uNZZvNJt3b5S",
            "symbol": "LNFTxSMB3719",
            "name": "LNFT Solana Monkey Business-3719",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Duch2MmgCar9UGt76smK5HcJ7anBRa31uNZZvNJt3b5S/logo.png",
            "tags": [
                "liqnft-owner-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/SMB3719"
            }
        },
        {
            "chainId": 101,
            "address": "77oeFJMZeEsPD4ZfmaTJzwPXJTKpAN7uCX9GeVHzDdwj",
            "symbol": "WTHM",
            "name": "WERTHMANN TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rtxjp/crypto/main/moon-planet-nature-crescent-wallpaper.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2w7LH6PLxo8gk9ssR6P36rk4Zaq2qM7mWz5S5e7PNrNG",
            "symbol": "CHUB",
            "name": "Chubby Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/judeallanhill/crypto/main/12433.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HJmkRP6NwF9cMAis78atdBWG2TrGtzhH4M28T8VPB2T8",
            "symbol": "GDM",
            "name": "Gundam Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tetsuo-nl/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "388VX2FShukuv9sRYejMyWWMgbu3j5iAk1pRC2fAb1qD",
            "symbol": "LOCAL",
            "name": "Local Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/MPWAssociates/Local-Token/main/LocalTokenLogoGitHub.png",
            "tags": [
                "Community",
                "Shop-Small",
                "Local-Token",
                "P2E"
            ],
            "extensions": {
                "twitter": "https://twitter.com/local_token",
                "website": "https://www.ourlocaltokens.com"
            }
        },
        {
            "chainId": 101,
            "address": "CkQsGJByd8Eof4YhaVZEh7KYijRM9LcmhwqatRuN8TsG",
            "symbol": "PINATA",
            "name": "Open Pinata",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/aonsmith/crypto/main/logo.png",
            "tags": [
                "social-token",
                "metaverse",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7kUK6RDuMWNDpWapKsru7Qh4ou6CUUdZHCb7oBrTtt4H",
            "symbol": "DODO",
            "name": "DodoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/doryalo/dodocoin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E2EQNjDyUqMXKj8G6JgyBrT7QtxuA4puPuFYB75QkdE8",
            "symbol": "ISI",
            "name": "Isi Coin",
            "decimals": 0,
            "logoURI": "https://github.com/dzaja123/dummy-token/blob/main/logo.png",
            "tags": [
                "social-token",
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "CueKAJkMnVrRUHW29rAkhsQcj6c4qtmghUmpUZeTvsoQ",
            "symbol": "RAV",
            "name": "Rav Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RaviDhirani/rcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NuCLiDyTU5ETFvaxfZj76zBe3uUYJe4wvrtzkt2ZAnR",
            "symbol": "NUCL",
            "name": "Nuclear",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NuCLiDyTU5ETFvaxfZj76zBe3uUYJe4wvrtzkt2ZAnR/logo.png",
            "tags": [
                "The-Core",
                "of-everything",
                "capped",
                "deflatory"
            ]
        },
        {
            "chainId": 101,
            "address": "JCrX22B88PfXdrjMDEhgh85yC2EtUeyB6erPMpnvDS4s",
            "symbol": "GRIDX",
            "name": "Gridiron Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JCrX22B88PfXdrjMDEhgh85yC2EtUeyB6erPMpnvDS4s/logo.png",
            "extensions": {
                "website": "https://www.gridirondex.com"
            }
        },
        {
            "chainId": 103,
            "address": "Gg4g7oFT5be2mVk8rPyXYsmg5yhu6LTzK6aVP3ZvUjgy",
            "symbol": "GRIDX",
            "name": "Gridiron Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JCrX22B88PfXdrjMDEhgh85yC2EtUeyB6erPMpnvDS4s/logo.png",
            "extensions": {
                "website": "https://www.gridirondex.com"
            }
        },
        {
            "chainId": 101,
            "address": "4PKZftmFS776BSwnDhhwfsxgLxtFiHpoErNdz9rAdxhu",
            "symbol": "KUSH",
            "name": "Kush Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/c9obvi/kushCoin/main/kushCoinLogo.png",
            "tags": [
                "social-token",
                "community-token",
                "NFT",
                "game"
            ],
            "extensions": {
                "description": "Buds for bits, coins for Kush",
                "discord": "https://discord.gg/EQMkNZUgxt",
                "twitter": "https://twitter.com/WeeklySesh"
            }
        },
        {
            "chainId": 101,
            "address": "8H9cughzmpmtQKVg48d7UjAWB3kiR7v8sWrhRrZehypb",
            "symbol": "AUCO",
            "name": "Aur Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AUR-COIN/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4j5bhHvAyTzLih5WCRvsVpuyZa2KiXaiixporbEqb1Cy",
            "symbol": "LST",
            "name": "Last Supper Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/svenrobbie/cryptosol/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GfmUGCL2kY21tqpDoeVYnjP4c57wyQZzamyHjbqU4TKd",
            "symbol": "VENUS",
            "name": "Venus Protocol",
            "decimals": 9,
            "logoURI": "https://cdn.discordapp.com/attachments/904210282719621120/919989957827919932/256x256_2.png",
            "tags": [
                "trading-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/T39rQGunSK",
                "twitter": "https://twitter.com/venusprotosol",
                "website": "http://www.venusprotocolsol.live/"
            }
        },
        {
            "chainId": 101,
            "address": "B3X2intXe1biLN9kok5BZGUXQ2UrSYwaaz6duJ7dpcEt",
            "symbol": "MAFI",
            "name": "Mafi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fkanter602/Crypto/main/MAFI_LOGO_1.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HS3vkEDFe77SFyWYrj5yyvGjLvvr2uq7Aa2g4NwTiF2Z",
            "symbol": "TECZ",
            "name": "Temicoinz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Temico/temicocoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3pX59cis3ZXnX6ZExPoUQjpvJVspmj4YavtUmpTpkB33",
            "symbol": "Chicks",
            "name": "SolChick",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3pX59cis3ZXnX6ZExPoUQjpvJVspmj4YavtUmpTpkB33/logo.png",
            "tags": [
                "gaming",
                "nfts",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "The Leading Play-to-Earn Fantasy Game on Solana",
                "discord": "https://discord.gg/solchicks",
                "telegram": "https://t.me/solchicksnft",
                "twitter": "https://twitter.com/SolChicksNFT"
            }
        },
        {
            "chainId": 101,
            "address": "inuXXDC6Tw1Fu6qUAfRbELG5x5pSA4afTskf7MvwjhN",
            "symbol": "KINU",
            "name": "KIN INU",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/inuXXDC6Tw1Fu6qUAfRbELG5x5pSA4afTskf7MvwjhN/logo.svg",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://kininu.com"
            }
        },
        {
            "chainId": 101,
            "address": "AWqBvorwthhaV2CTmzzu7FehKAdce6iTczsKKcqEBggZ",
            "symbol": "HUAT",
            "name": "Huat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ericxgx/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "4j5bhHvAyTzLih5WCRvsVpuyZa2KiXaiixporbEqb1Cy",
            "symbol": "LST",
            "name": "Last Supper Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/svenrobbie/cryptosol/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "84kqep5DBSYXCev2AmTxVNeoTCSXW2vdPr7xpCif3DuE",
            "symbol": "IAN",
            "name": "Ian Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ianridsdill/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://ianridsdill.ca"
            }
        },
        {
            "chainId": 101,
            "address": "7fLNbYHSu6fDPvEMqww7VgJ76PBxnJjkgdXjCGv5fQCA",
            "symbol": "UTOC",
            "name": "Utopolis Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Utopolis/Blockchain_logo/main/utopolis_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4hBZG7N8DEp1WKHfCvcVd3CtEkvMkXnGNhNYzZiopCHw",
            "symbol": "ROMC",
            "name": "ROM Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Utopolis/Blockchain_logo/main/romestant.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Gw79PQcuJABAq8qZqN8oVAXMKzuV1FFgyRwrCMKtqLx",
            "symbol": "4RM",
            "name": "ReichMark",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HerrPenguin/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8EgH47vnsKHC72gp25DKMhNuBqUMSZBNoVLb4FXRh7ga",
            "symbol": "MOMO",
            "name": "MOMO Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/frazzor123/MOMOCOIN/main/MOMO_Coin_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HUFYuSidX6H4kGMJr1jFBiTS3DGhC6M6iiw7SvTnEzJM",
            "symbol": "SILLI",
            "name": "Silli Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sillitoken/silli/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC",
            "symbol": "$FORCE",
            "name": "Force",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solenforcers",
                "website": "https://solenforcers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "A9611k8CppA7zrwsQ72qzN3FrYv3j5jpK8FGqpqBZMLk",
            "symbol": "SECT8",
            "name": "Sector 8",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Darreans/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BhxPoUPkVASfT9y4ipJ6t4sN8uEuThz9voKaPrkv2sFQ",
            "symbol": "ANT",
            "name": "Ant Coin",
            "decimals": 9,
            "logoURI": "https://arweave.net/BchXu9WIA85Zxf1fntkKxDG7UzfsY_IFyVufswXWEMY/ants-logo-black.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/solants",
                "twitter": "https://twitter.com/solantsnft",
                "website": "https://solants.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ANsbGkBC8JbfcAgqLUEyfBMdbvmqPFunqmNqfuPm9HBi",
            "symbol": "ZGGY",
            "name": "ZiggyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ziggymoens/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/ziggymoens",
                "instagram": "https://www.instagram.com/ziggymoens",
                "linkedin": "https://www.linkedin.com/in/ziggymoens",
                "website": "https://ziggymoens.github.io"
            }
        },
        {
            "chainId": 101,
            "address": "cYmnZTPmyMgiffoVm1JpjhiUk3XNxExSvjgXK24ZpMs",
            "symbol": "SKUD",
            "name": "Skudder Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chriswerner007/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2bdHb59xYNtJNf1FuK8Tt96CsZyTTXFMNEDxTgXMCkdq",
            "symbol": "GOOB",
            "name": "GOOB",
            "decimals": 9,
            "logoURI": "https://arweave.net/h1AbbOh8hEcm7pXm9hi_Yk_WaYoihquY7ZWzAvWWIc4?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/goobienft",
                "website": "https://goobiesnft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4G6wwVS3YUL7sgG14eAXaazYjVb16aD8uzhvXsNzSrJw",
            "symbol": "SLOC",
            "name": "Slo coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robiweb74/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3p7pqeerwsuhL8FD2cdTHYqnVrU3w6X5pCap3pwPbs6T",
            "symbol": "POW",
            "name": "Pow coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/danielxnilsson/cryptodxn/main/pow_1_coin_s.png",
            "tags": [
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3SVhemQ5YHF6sDTZfjm98uTZimSZUVNh8pexcWibdg2s",
            "symbol": "DXB",
            "name": "Dubai Future Foundation",
            "decimals": 0,
            "logoURI": "https://github.com/samurtuza/DXB/blob/main/DXB.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5iAftA3uLN1LsaPWWs5ifuMDk5wjo8r8qRust3EJq3X2",
            "symbol": "ROZIC",
            "name": "Rozic Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aljaz-rozic/cyrpto/main/prenos.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "58Jbfajet1o3L5LzZopwkSHtGKo2PdKkZBTPnWLQpc3T",
            "symbol": "NIKKEL",
            "name": "Nikkel Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/NikkelCoin/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JdQi3VZFVF1Xbd1qqMGbrmyqDwKhPovdpswPtNEt5om",
            "symbol": "LID",
            "name": "Livia AI Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KayRohde/crypto-token/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "6VYF5jXq6rfq4QRgGMG6co7b1Ev1Lj7KSbHBxfQ9e1L3",
            "symbol": "wDingocoin",
            "name": "Wrapped Dingocoin",
            "decimals": 8,
            "logoURI": "https://wrap.dingocoin.org/wDingocoin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/y3J946HFQM",
                "facebook": "https://www.facebook.com/Dingocoin.org",
                "instagram": "https://www.instagram.com/dingocoin",
                "reddit": "https://www.reddit.com/r/dingocoin",
                "telegram": "https://t.me/DingoCoinTalk",
                "twitter": "https://twitter.com/dingocoincrypto",
                "website": "https://dingocoin.org"
            }
        },
        {
            "chainId": 101,
            "address": "7ZYjz3pLHUnqX2wrd7VfQPCdS3MLESVxRnYkfEFe8Dyn",
            "symbol": "LUGOO",
            "name": "GOOSEYS",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/Snaps19/gooseycoin/main/lucy(1).png",
            "tags": [
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4QHgnsUhyM4wZUhnHpMBcH37VBr81Ka3L8o1t34yQAfR",
            "symbol": "MOCO",
            "name": "Monarch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kmoerkens/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CXdiBsn4f67dSvgyRSWrxmTaf2JA77EH5YBFpAs7fzUF",
            "symbol": "MTRX",
            "name": "Araujo Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/overDraftIO/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ApgNFHXMsY9qM8yaaSVzqX7xtbKkGjgo64NLGuf2hQjW",
            "symbol": "VRSW",
            "name": "VeraSaw",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApgNFHXMsY9qM8yaaSVzqX7xtbKkGjgo64NLGuf2hQjW/logo.png",
            "extensions": {
                "discord": "https://discord.gg/w9e8yM7DtZ",
                "twitter": "https://twitter.com/VeraSaw",
                "website": "https://verasaw.io"
            }
        },
        {
            "chainId": 101,
            "address": "ETk89me8ru2rrAMx92Ufr2mGE2UDsXhXcuUEzSgMZqdk",
            "symbol": "DVRO",
            "name": "Vrolix",
            "decimals": 0,
            "logoURI": "https://github.com/DirkV69/Crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GXz5qhsjuu2ehynoEVrL2by7GouhJKfD8kW3mHUVdffQ",
            "symbol": "MARL",
            "name": "Marling Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rawhound/MC/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EnzoXw6dNuU1TgkuN2o4vjebHtZjuqX5mfNTUo2sYvM6",
            "symbol": "ENZO",
            "name": "Enzo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EnzoXw6dNuU1TgkuN2o4vjebHtZjuqX5mfNTUo2sYvM6/logo.png"
        },
        {
            "chainId": 101,
            "address": "5SgeEe9gQiuvXRrAeQLZdMhRfPeyFmAWLGLJcVVwyj6K",
            "symbol": "TRIU",
            "name": "Triumphia Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pushingpandas/triumphia/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kUVc7aa24mRhMR17a8XtshUvAe2kLGseKBuR7q1KAWU",
            "symbol": "GBC",
            "name": "Gasbert Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kUVc7aa24mRhMR17a8XtshUvAe2kLGseKBuR7q1KAWU/logo-gbc.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4qAGvkJfboj1K9KHMizpVTLUCCkMANM8CLGPCXwSCCRz",
            "symbol": "BABAB",
            "name": "Bananine Baberto",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/barb94/token-list/main/assets/mainnet/4qAGvkJfboj1K9KHMizpVTLUCCkMANM8CLGPCXwSCCRz/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H3r2shEWVKtsMTzdV9Wjhwj6GciJP3iUouQqnF8QQ6bY",
            "symbol": "TBL",
            "name": "Token Della Balotta",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Vanno97/crypto/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "BeF6YAAXse91gkDTKN9iwc2m8YLrH4HVT9TTaDPzrbcX",
            "symbol": "THSL",
            "name": "TreeHouseSol",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/edTreeHouse/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9nzAeR98tptPo2DWycNNVt4VotefttiHmaDc1qSr5pDN",
            "symbol": "FLC",
            "name": "Fortune legacy coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/abelgirmay/crypto/main/fortunelegacy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8KyChGxPRTfL6CFuSw8rMLpT7HttsSkCWnEyJydDcsYR",
            "symbol": "META",
            "name": "META",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/pblackhurst/Metacoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E2MoWRZsVWMrnqfZsMJvmCij2mGJUripxtokuS1coUi8",
            "symbol": "KOKO",
            "name": "KomaliKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/giltnerj0/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CSsSJSfJmaqocgqVHpHeoHDzCawVpCBZmFbmZ4AisoVH",
            "symbol": "QUACK",
            "name": "QuackCoin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/nfacha/token-list/main/assets/mainnet/CSsSJSfJmaqocgqVHpHeoHDzCawVpCBZmFbmZ4AisoVH/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2esX8PzjDqsfL2t7i6jfyLbP53gNN2Rp7cFPCP2842Hr",
            "symbol": "FANG",
            "name": "Fang Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DeveloperNeb101/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HnCVVhSgvJGFdk3yCe5qwaqr1qGZKnTjsTV2Hiq3uUGx",
            "symbol": "DEVR",
            "name": "DevRaeph Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DevRaeph/crypto/main/logo.png",
            "tags": [
                "streaming",
                "earnings"
            ],
            "extensions": {
                "description": "DevRaeph Coin is a special Token for Twitch viewers.",
                "twitch": "https://twitch.tv/devraeph",
                "website": "https://devraeph.com"
            }
        },
        {
            "chainId": 101,
            "address": "HfEd81FSGtMVdyt4QzNWDxV7Lbde7sB42bFG123wCLK3",
            "symbol": "PLUTO",
            "name": "Pluto Buckz",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Tacofromspace/Pluto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG",
            "symbol": "SANTA",
            "name": "Santaclaus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3vjNGAPF",
                "twitter": "https://twitter.com/SolSantaclaus",
                "website": "https://www.santaclaus-sol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "J5MNuEaYdDg52U1MLVUieJ2KXKdKXZLGzCuMW4wxtDKk",
            "symbol": "SYMPTHOMAS",
            "name": "Sympthomas",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomasflx/symp-tokens/main/sympthomas/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Da7LWn6guErBqAakf9zcQMGF79eGvBMNCQWMAGUmMK4i",
            "symbol": "JKN",
            "name": "femboy jack",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alf-sainsburys/crypto/main/Screenshot_20211213-231333_Gallery.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5eitJZGxFq7ufpd3UpUgjnXTbQr7hbs9BTZBPnVaBtss",
            "symbol": "MRCM",
            "name": "Marcum Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/XeleX101/crypto/main/MarcumCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FTbPBRWejaGcmnsRZfcbmA3vfcmPox4B25wRU9PFxVyv",
            "symbol": "HOGG",
            "name": "Hog Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ambreeze/hog-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8gdQTyjbE6qTjX82gdHV47e5FKQDZpKw8K3gVQ3p2NMi",
            "symbol": "ROME",
            "name": "ROMESTANT Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Utopolis/Blockchain_logo/main/romestant_fr.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9DHbizi6B513woHgN6eWXxczRGFopAahrV2cPpauwGDy",
            "symbol": "SUPRC",
            "name": "SuprC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/superfistdandy/crypt/main/suprC.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2QA5NrCMzeTqZVWTQXEc62yeHWB2vVfdVjcSYtewJ1GB",
            "symbol": "$AFRO",
            "name": "Afro",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Texaglo/afro/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CLkuUdjLwu4xS49rwTr1FpMHwvtcoFXUwh9anbYqMUe9",
            "symbol": "HUSAR",
            "name": "Sekler Husar Inu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pr04ak/alt1/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "telegram": "https://t.me/pr04ak"
            }
        },
        {
            "chainId": 101,
            "address": "CwkES31Z5SThDAzYK6izHEQ2XmqSSrHEay2M5P2qDtMk",
            "symbol": "GOPC",
            "name": "Gopal Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gmallapp/gopal-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gddp96Qygv4M1uJ9LJL9c9bu4XmY1khYLnVRv2HDQDKC",
            "symbol": "TISS",
            "name": "Time Is Standing Still Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theunisj/TISS/main/TimeIsStandingStillLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3wegcJmLCELNzbV26HPyCJstJ5gWAAjeY4pX9QEExLnr",
            "symbol": "MOB",
            "name": "Mobius",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mobius-token/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31fic4MCV5rfntTCRpoZCAYpqEt8ebH498pPzm1XhiFM",
            "symbol": "CRCO",
            "name": "Costa Rica Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bart-98/CR-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4jEYxe1KsqcR4TUfn4jmD333BRgbV1JcbkZ4aJ1FXb7t",
            "symbol": "FUNKLE",
            "name": "Garfunkle",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Zumux/funkle-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4BAPMaeBoqKQPpK3HGYAnAJ8BsUk4CNX9QWLCf4T5nho",
            "symbol": "BSNT",
            "name": "BSN Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dajohnso4374/crypto/main/darren.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E7DpePKcHa7wzfA1k7rzjkhmCa7PfT7p7DTyyJwTLqdn",
            "symbol": "Moxie",
            "name": "PrinceMoxie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ViperMiner/Crypto/main/Moxie%20Card.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Fdme9Lm55xjXxhCNUQRzaae68W4oiLcS29v2L8YsRRk",
            "symbol": "BSHRP",
            "name": "BSHARP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/namesakeradio/bsharp/main/bsharplogo3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD",
            "symbol": "MEAN",
            "name": "MEAN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD/logo.svg",
            "extensions": {
                "coingeckoId": "meanfi",
                "discord": "https://discord.meanfi.com/",
                "medium": "https://meandao.medium.com",
                "serumV3Usdc": "3WXrxhrj4PXYUwW4ozBjxdSxwEp9ELKf3vETxXTqdiQJ",
                "twitter": "https://twitter.com/meanfinance",
                "website": "https://www.meanfi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "49TV8AmixQUv3sqXZ16Q7BeTMcoDAFVPKTN8rZ3sdwXx",
            "symbol": "PNW",
            "name": "Evergreen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lucadenhez/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/lucadenhez",
                "website": "https://lucadenhez.com/evergreen"
            }
        },
        {
            "chainId": 101,
            "address": "8vnbD3zUR8H3c2bYUEDKyF5VFE1iSpbHKPfN59W3BWLJ",
            "symbol": "BURGER",
            "name": "BurgerCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MangoCoder360/burgercoin-image/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CUQGwSFxaMdRSt2nZpY6j8p39LMyzZNSsgyApjPmrKnu",
            "symbol": "TACO",
            "name": "TacohKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/greedydurian/Crypto/main/taco.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FyYUZ4C6Sz1eqAZTiVuP4BaVfaakcSrzXNDUWGoaN5yW",
            "symbol": "DLCN",
            "name": "DaleCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bigdale123/DaleCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BWsKWzLWUcLNPnaynFLrsHSyMEpgoMiwqL3jnNhEXgSU",
            "symbol": "CMNLCK",
            "name": "CommonLuck Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/One-Hundred-and-Eight/sol_tokens/main/cmnlck/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Yo5MmcAmyFYomUxju99BwUvT8Bi55iwm1idYGQJEQhY",
            "symbol": "WWW",
            "name": "WWW Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alilholt/crypto/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "846q7wB2kCgA5m8ksqXjPoMdrypM7WMWz5iLELR93ueM",
            "symbol": "SHE",
            "name": "SheeshCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ll3macorn/sheeshcoin/main/logo.png",
            "tags": [
                "SHEESH"
            ],
            "extensions": {
                "description": "SheeshCoin is quite poggers",
                "discord": "https://discord.gg/ntAgpWSbuc",
                "instagram": "https://www.instagram.com/baileyboylson",
                "reddit": "https://www.reddit.com/u/Ll3macorn",
                "website": "https://ll3mamc.com.au"
            }
        },
        {
            "chainId": 101,
            "address": "JDdmEMX1kyvmiTArqsH7JiG75JWhrheJXHCi357qt5QB",
            "symbol": "MMMM",
            "name": "Mobile Money",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/peteshouse/logo/main/Logo.png",
            "tags": [
                "social-token",
                "NFT",
                "Game",
                "fan-token"
            ]
        },
        {
            "chainId": 101,
            "address": "mh7AXXDW83uSRU3gPgZgygz7YPYYY4AnSukyGNZmjgL",
            "symbol": "KSHK",
            "name": "KishhKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nav2k19/crypto/main/KISHKOIN.jpg",
            "tags": [
                "social-token",
                "kish",
                "vibez"
            ]
        },
        {
            "chainId": 101,
            "address": "GENW9qVvxHMVmRTVokgHRPqSr3syV2AmKoehqrRWiYFu",
            "symbol": "DUSA",
            "name": "Medusa",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GENW9qVvxHMVmRTVokgHRPqSr3syV2AmKoehqrRWiYFu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/gorgongang",
                "twitter": "https://twitter.com/GorgonGangNft",
                "website": "https://www.gorgongang.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "4CWQ6KS2ZhNQtWxAytY8AGGt8qgfNEK1dvnHMMQhQBpE",
            "symbol": "KUNCI",
            "name": "Kunci Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4CWQ6KS2ZhNQtWxAytY8AGGt8qgfNEK1dvnHMMQhQBpE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://instagram.com/kuncicoin_id",
                "telegram": "https://t.me/kunciofficial",
                "twitter": "https://twitter.com/Kuncicoin_id",
                "website": "https://kuncicoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "67oHSptHjcGJdh3VECE3GFGynQcCtMGNRJyKPav55itP",
            "symbol": "CAWL",
            "name": "Cyber Ape WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CyberApeAge/PFPs/main/Cyber%20Ape%20Concept%2042.png",
            "tags": [
                "utility-token",
                "Ape",
                "NFT-Gaming",
                "play-to-earn",
                "p2e"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CyberApeAge"
            }
        },
        {
            "chainId": 101,
            "address": "BgzSLhARcWjWQMF8L2gjv65PCoq3JyMcPokhhzLSrqJ2",
            "symbol": "KC",
            "name": "Khtain Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bkcsplayer/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8dMrgk4joLzA3o69r87HeQ4ZyLyKNS2SZD1sVAJ6JWtB",
            "symbol": "FELTS",
            "name": "Felts Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Felts89/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FAbqiU27CNBoFwdWqryAF5KdHiMo4Q3GmamEEDKp6QfZ",
            "symbol": "108",
            "name": "One Hundred and Eight",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/One-Hundred-and-Eight/sol_tokens/main/108/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FhTJbTK91rDiYNZFpQUf9oyw7PTPneXPVTmTUktxYMq3",
            "symbol": "ZAK",
            "name": "Zaki",
            "decimals": 0,
            "logoURI": "https://github.com/hyedalian2/Zaki-Crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8pcs3hQk5iEfgNc2yykuJ9HBgVbXgAUmeagPrWQmkNFU",
            "symbol": "EHSAS",
            "name": "Ehsas Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/3lii06/ehsastoken/main/ehsas.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AoAm3Mqa57RasPhiiPXjssyAUv6unhxaNQ5xA4qgsMjo",
            "symbol": "MOO",
            "name": "Moo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NicolaeO/crypto/main/logo.png",
            "tags": [
                "social-token",
                "moo",
                "cow",
                "milk"
            ],
            "extensions": {
                "discord": "https://discord.gg/kJ2YYyrnHP",
                "telegram": "https://t.me/MooToken"
            }
        },
        {
            "chainId": 101,
            "address": "6sNhtA5dqqgyinJ1aavvLycD5CBqrTAbGm9Sf75Nzsy7",
            "symbol": "MABK",
            "name": "MamboKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kight555/crypto/main/1.png",
            "tags": [
                "social-token",
                "mambo",
                "catnip"
            ]
        },
        {
            "chainId": 101,
            "address": "GFpSv8R5AxiC2ZKC4yqf3jfVFKt1KVB5jk9x6YEQ7XSQ",
            "symbol": "AQS",
            "name": "AQSCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/n0tspam/AQSCOIN/main/logo.png",
            "tags": [
                "innovation-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3PNqq4kEqgRSkV5dYdcc6mtoaoXdaun9ytoCr4BgX5yA",
            "symbol": "CSC",
            "name": "Captain Shiba",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PNqq4kEqgRSkV5dYdcc6mtoaoXdaun9ytoCr4BgX5yA/logo.png",
            "tags": [
                "community",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/captainshibaz",
                "website": "https://captain-shiba.com"
            }
        },
        {
            "chainId": 101,
            "address": "38JE7RdaF5BL9xJnuo9qP6bW1kb3ddaaRSTV6bbBjREo",
            "symbol": "HOLO",
            "name": "HoloCoing",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BoundlessTechSolutions/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E3RN9omoTNdqKXGj988X8JuCXwNZ6ZHXbfpaZ1nVZbrA",
            "symbol": "APE",
            "name": "Cyber Ape",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/CyberApeAge/PFPs/main/Cyber%20Ape%20Concept%2043.png",
            "tags": [
                "utility-token",
                "gaming-token",
                "gaming",
                "play-to-earn",
                "p2e"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CyberApeAge"
            }
        },
        {
            "chainId": 101,
            "address": "BWeWGe646b92CUerrphZnFNRfqqAd33WdQSerS5hZ4eB",
            "symbol": "CA93",
            "name": "Cambrian Alumni 93",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/rellikeno/CA93_Coin/main/CA93_Coin.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "fan-token",
                "tech-token",
                "currency-token"
            ],
            "extensions": {
                "facebook": "https://m.facebook.com/PWCCA93/",
                "website": "https://www.cambrianalumni93.org",
                "youtube": "https://www.youtube.com/channel/UCPgDNcJSX2Ix629azkojIUA"
            }
        },
        {
            "chainId": 101,
            "address": "Bvq7LR4DrS1QbkD7AggtzuQotw2NWdy6UCVST6bH6vvr",
            "symbol": "YOU",
            "name": "YouBaby Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DevRaeph/crypto/main/YouBabyCoin/logo.png",
            "tags": [
                "social-token",
                "earnings"
            ],
            "extensions": {
                "website": "https://youbaby.at"
            }
        },
        {
            "chainId": 101,
            "address": "DcNJWM1YdAu79kT5fNKxjGDX2SQDMoJwskMj1AaXd92a",
            "symbol": "Korea-Won",
            "name": "Koreapunkz-Won",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcNJWM1YdAu79kT5fNKxjGDX2SQDMoJwskMj1AaXd92a/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HyrzGRfn9Tj1DMzh8QW9qtxhXbcaMfqveBJhrkNVsSVU",
            "symbol": "CRNZ",
            "name": "Cranzana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Karuzo98/crypto/main/Cranzana.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dz3a5NSiy2BRE1boBjAEz6VHNDsTVwdxhx2eZCvL5oWK",
            "symbol": "SQDC",
            "name": "Squid Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/abl10801/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "dapeM1DJj3xf2rC5o3Gcz1Cg3Rdu2ayZae9nGcsRRZT",
            "symbol": "DAPE",
            "name": "Degenerate Ape Academy Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/dapeM1DJj3xf2rC5o3Gcz1Cg3Rdu2ayZae9nGcsRRZT/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "CQQAHgfsu5uFTPzwewZ7QrLLSPk1K9MmqnuxiwmPDBzN",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "4jWGanaJh4GBpiEfoTzWuNsXFqSC5Rmq8ij195AV8cut",
            "symbol": "VPSGB",
            "name": "VPS GB Coin",
            "decimals": 0,
            "logoURI": "https://github.com/louij2/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://vpsgb.co.uk",
                "youtube": "https://www.youtube.com/channel/UCO15seGcv6e2CXWRfiHm6MQ"
            }
        },
        {
            "chainId": 101,
            "address": "CmQU5aan8Dng9xAuvYgpzroRkKWfeWaWD4X5mtErJ9E4",
            "symbol": "DIXI",
            "name": "DIXI Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DevRaeph/crypto/main/DixiCoin/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2eZ45YBgQEftoxEVyCTWEULLNkDxSVSMrwv7LXiZn4sk",
            "symbol": "STAR",
            "name": "SOLASTAR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2eZ45YBgQEftoxEVyCTWEULLNkDxSVSMrwv7LXiZn4sk/STAR.jpg",
            "tags": [
                "social-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jqm4gmtzbk",
                "telegram": "https://t.me/solanastar",
                "twitter": "https://twitter.com/STARsolana"
            }
        },
        {
            "chainId": 101,
            "address": "5pfUatZaaj332Pc1anrsX5AJaR2ogWrXyFAudqkFQc5e",
            "symbol": "FOR",
            "name": "FAVORITE",
            "decimals": 8,
            "logoURI": "https://pbs.twimg.com/media/FGhR6SMX0A4ed8o?format=jpg\u0026name=360x360",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Samurai37685862"
            }
        },
        {
            "chainId": 101,
            "address": "2FwYJP2YDBkxZYPMWaNJtNHxjCstMAY8SKBHMp3BGkWF",
            "symbol": "VOX",
            "name": "Voxxi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/frankwarwick/crypto/main/voxcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G3MRfuriArrzbCN148ftKJbTeMhxxrnM5zrQGJvnAEM5",
            "symbol": "KONG",
            "name": "Kong",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G3MRfuriArrzbCN148ftKJbTeMhxxrnM5zrQGJvnAEM5/shinylogo.png"
        },
        {
            "chainId": 101,
            "address": "Dmv1M32r9ZFwXiwt9QKkTJrPjCx5ozC2ot4niHB6562h",
            "symbol": "DRTN",
            "name": "DrexxTkn",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/tM6UvZP.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cdqnj1tFnQaVH4meAbTgQ6jJgHxYAJ1WwP9htrj7JxWQ",
            "symbol": "CYBER",
            "name": "Cyber Ape Age",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/CyberApeAge/PFPs/main/Cyber%20Ape%20Concept%2044.png",
            "tags": [
                "social-token",
                "ape",
                "NFT",
                "play-2-earn",
                "P2E"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CyberApeAge"
            }
        },
        {
            "chainId": 101,
            "address": "C57GUQaD4qJcUpQJWJuzz9zQ8ySQuEsfuksFhV2xyhxq",
            "symbol": "RZC",
            "name": "RZCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/rz1989s/RZCoin/main/Assets/Logo/Logo.png",
            "extensions": {
                "discord": "https://discord.gg/zs4W37BUSt",
                "twitter": "https://twitter.com/rhezasulaiman"
            }
        },
        {
            "chainId": 101,
            "address": "AVg5MNyVb3CgGx43vHk28zDPBdeasJc1LrrbwDeGjGa5",
            "symbol": "JOCO",
            "name": "JohnCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/john5071/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9aApttjwJAter9qh9NpNuaADfZRaUbtjGixRTN3zaiiQ",
            "symbol": "BIO",
            "name": "Biohazard",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BiohazzardXIII/crypto/main/BioCoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3XqdvAtmfBoJW7QSncwxpDeUnf4Uw9SykKeJoTQY3cwP",
            "symbol": "SOZC",
            "name": "SozioCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ImJstNickDev/SozioCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FfCyBkXo1VwkJw9xvjcQo521nBC4d5QTVt9juS25oqXu",
            "symbol": "T10NAT",
            "name": "T10NAT Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/n0rwegianblue/t10natcoin/main/t10nat-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BygDd5LURoqztD3xETc99WCxLUbTi6WYSht9XiBgZ4HW",
            "symbol": "WMP",
            "name": "Whalemap",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ssi91/crypto/main/logo.svg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "coingeckoId": "whalemap",
                "website": "https://whalemap.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4q1aDEgMtYmASAN4F3MHycAbqQhLZUBztXVyDqXiiXF5",
            "symbol": "HMLC",
            "name": "HEMEL Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cristhiank/hemelcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5ZXZEPxpC35nBxEiVhs257HMNeTd3htYk7FUvBsYFfcr",
            "symbol": "Armen",
            "name": "Armenia",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hamesdhacker/Tram/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3xzVH324eKe9XGyo2L72zyWbZiqA2kMu4NnFe26PhQCb",
            "symbol": "NFTX",
            "name": "NFT Universal Exchangable",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mixartemev/token-list/main/assets/mainnet/3xzVH324eKe9XGyo2L72zyWbZiqA2kMu4NnFe26PhQCb/logo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "telegram": "https://t.me/u_nft"
            }
        },
        {
            "chainId": 101,
            "address": "6Wt3E23jMiDfZJursDfitbCGK9SsRdVAKMMMz29Z5p54",
            "symbol": "FXWX",
            "name": "Fixiwixi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/unicornsteak/fixiwixi-coin/main/fixi_wixi-coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "98Q6u9iTu6x3kzT4feCZo8sRHNWKaHcTodgWNjTo27uF",
            "symbol": "VRXC",
            "name": "Vroxcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LukasVrox/PBcrypto/main/Vroxcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AzqYUWKG5kqXgNwCvnuKdF8nRNBsJvbLK6NpTzWJtxHr",
            "symbol": "YODA",
            "name": "YodaMaster",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kurtchan828/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BVPT9GdcY6mxfQQFG8yKK7XtawC9HcioUJKNFgVguKgf",
            "symbol": "KTM",
            "name": "Kristapaas_lv Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MordoTheHacker/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "93hB3wZSaz2h25hhUA9wDovVgaDLXKx8aR7hECahkMSp",
            "symbol": "SDOR",
            "name": "SDORCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shaneorourke/crypto_token/main/SDOR.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhQD9vmYfPwoDRsMdBwcRK1Zv7x1C5EntQKWMmuUwbSY",
            "symbol": "PBC",
            "name": "PandaBearCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ShayanBanerjee/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "78jgechALV3SaJaSzyKNPFDMUS9A9CXY1K3Vub6Badjy",
            "symbol": "CHKY",
            "name": "Chomsky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rickyspanish6/crypto-chomsky/main/logo.png",
            "tags": [
                "bruh"
            ]
        },
        {
            "chainId": 101,
            "address": "BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A",
            "symbol": "NOVA",
            "name": "NOVA FINANCE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A/logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/NovaFinance_"
            }
        },
        {
            "chainId": 101,
            "address": "9MefqocoPxZStLAYKPXSFVG11GNGnCy2LSHNQ2EyRjAw",
            "symbol": "TIKH",
            "name": "Tikhedewal Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sanketikaran/TikhedewalCrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4wPXWiJxKhaVHCe1WKcjDe8DvZS1WBe2QEPv2tih1aaC",
            "symbol": "BLC",
            "name": "BitLite Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Danieljr1995/crypto/main/bitlitelogo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://bitlite.live"
            }
        },
        {
            "chainId": 101,
            "address": "4psTdyTHQHDuvRdSR4UNgBd8QbcM7G3r1uiwhfNR6vfa",
            "symbol": "RWL",
            "name": "RAW LIGHT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4psTdyTHQHDuvRdSR4UNgBd8QbcM7G3r1uiwhfNR6vfa/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4AWPuaGUTqdyyV9T25RVZSEU2i9vyPw7YaNW5U64PmwR",
            "symbol": "BWGN",
            "name": "Blumenwagen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Blumenwagen/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HsueFrZbKRE7iFpML5PGisHj5BmsCuf5dYXYvpu8oXe3",
            "symbol": "ISBT",
            "name": "Interstellar Banking Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SecuritySkunk/cryptotoken/main/logo.png",
            "tags": [
                "game-coin"
            ],
            "extensions": {
                "discord": "https://discord.gg/WhefX8qf"
            }
        },
        {
            "chainId": 101,
            "address": "APuSEj1Skt97HnNA5HV4XKxZrTUJuHkqAyusq5TEDd7F",
            "symbol": "INC",
            "name": "Infina",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APuSEj1Skt97HnNA5HV4XKxZrTUJuHkqAyusq5TEDd7F/logo.png",
            "tags": [
                "utility-token",
                "meta-verse"
            ]
        },
        {
            "chainId": 101,
            "address": "7nvesyC4rCjxisG8jmZA3CXGD3oR94xGP7B3HguZUVk4",
            "symbol": "AZ",
            "name": "Alekss Zeltins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlekssZeltins/SolanaToken/main/smile-teeth-smile-oxxxymiron-wallpaper-preview.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BxCySPxFsR1zryF2ub7ouSXUGn952SB2mQ8dz45qvjtx",
            "symbol": "DEXC",
            "name": "Dex Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kmoerkens/crypto_dex/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BgnfsEaGq3wKrGDE4SWcWPjyf1BAmupNBtgCU7NjuGXU",
            "symbol": "XMAS",
            "name": "Merry-X",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robinsafari/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8RY64EeefQT83yAsMsy4hXGWJDLHL16Sy8FHCBG4sTLL",
            "symbol": "DBRY",
            "name": "DebroyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rkdebroy/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F5GuftC65xBqV3rbKN7xgJfZM8Xgma99E58gtgrrrDpR",
            "symbol": "USD",
            "name": "TETHER USD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Smaler1/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H1dMaosS57MArEM6Z9aPqGgrbMwCUZuJuc6VzPMWH8bm",
            "symbol": "YAK",
            "name": "YAKSA token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H1dMaosS57MArEM6Z9aPqGgrbMwCUZuJuc6VzPMWH8bm/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.yaksa.io/"
            }
        },
        {
            "chainId": 101,
            "address": "H6U5jkquLLK5u8FNVn4bTnmscSGm9fRNCGNe5xFBLDiM",
            "symbol": "Do",
            "name": "Do",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dodo124/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6ZdDMWym4VpY63PwsupC4AR5PrgbY2Xs5Et3RGf45LLv",
            "symbol": "WHOOP",
            "name": "Juggalo-Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Vio13ntJ/Logo/main/Logo1_50.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FatneQg39zhrG6XdwYb8fzM4VgybpgqjisJYESSBD7FV",
            "symbol": "MKD",
            "name": "Musk Doge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FatneQg39zhrG6XdwYb8fzM4VgybpgqjisJYESSBD7FV/logo.png",
            "tags": [
                "utility-token",
                "meme"
            ],
            "extensions": {
                "discord": "https://discord.io/muskdoge_sol",
                "telegram": "https://t.me/muskdoge_sol",
                "twitter": "https://twitter.com/muskdoge_sol",
                "website": "https://musk-doge.net"
            }
        },
        {
            "chainId": 101,
            "address": "9VQvshdNty8c69UQYCZDF8m96YZiJGGTVrNXDGfgKJeZ",
            "symbol": "TKNR",
            "name": "Tokenara",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gregbanna/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ETre2G9u2dBj4QDAiVikx8Dt3QRcVh2k6GcqLB34zTKp",
            "symbol": "LZR",
            "name": "LZR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ej78/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4WxMSTxij4uZVjz5rYhJS8wUUwNHYJxTAXCQjZLSqcmi",
            "symbol": "CoTo",
            "name": "CoupleToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Agent-3301/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3WLQr6NL9zh6DjraaoPnsqdwxakNgBKxSvNewkoGhmsZ",
            "symbol": "FTH",
            "name": "My Father",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rahitash/fthtoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "71SN1BdtEKPcfMFmC8xkv75RsdwNB1XsuWTp7ugGALsb",
            "symbol": "DRYZ",
            "name": "DryzzleKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danyou/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8z4ghJPp3ccvEtuXZbceGRfoX7AZHhcdwiapYzmsxmyC",
            "symbol": "TIGER",
            "name": "TIGER COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8z4ghJPp3ccvEtuXZbceGRfoX7AZHhcdwiapYzmsxmyC/logo.png",
            "tags": [
                "TIGER",
                "Community-token",
                "MEME"
            ],
            "extensions": {
                "coingeckoId": "tiger-coin",
                "twitter": "https://twitter.com/tigercoin_",
                "website": "https://www.tigercoin.space"
            }
        },
        {
            "chainId": 101,
            "address": "CvgBPD8H2P26GodbuxMryFppQdhqR7tuBGWjCWdhJssK",
            "symbol": "YODA",
            "name": "Baby Yoda",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CvgBPD8H2P26GodbuxMryFppQdhqR7tuBGWjCWdhJssK/logo.png",
            "tags": [
                "YODA",
                "Community-token",
                "MEME"
            ],
            "extensions": {
                "twitter": "https://twitter.com/babyyoda_token",
                "website": "https://www.babyoda.info"
            }
        },
        {
            "chainId": 101,
            "address": "C56RByoj5dmr7QToNNZXnJrPTSPQGbfJp29Nyrsjs9SE",
            "symbol": "AYA",
            "name": "AYACOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/torsteinknutson/crypto_sol/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ATK7foBcmr5HNRNwPkDtQtesTtvbivE6f8J2MWoH1AbW",
            "symbol": "PFC",
            "name": "Pflufdl Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FDHoho007/token-list/main/assets/mainnet/ATK7foBcmr5HNRNwPkDtQtesTtvbivE6f8J2MWoH1AbW/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ckz7u6p9L5DD1Qf162ChMoCNfJKphEBnMYdc8jmxxWG5",
            "symbol": "RSK",
            "name": "RosikoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rosiko/Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FV4NP8tweRyzwd5mo9V77ykV9jc9QthFs78Dan5C2Nf7",
            "symbol": "EMPRESS",
            "name": "Empress Coin",
            "decimals": 0,
            "logoURI": "https://github.com/salaamst/crypto/blob/main/empresslast2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8QzztHPYxAfV6rirUDrjMQCjfg8a2bi7VM3z25HNGEm1",
            "symbol": "KINS",
            "name": "Kinson",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mauriceatkinson/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2C6QKsbUe8d8NdSpVGh2QNDrEWZjatwqALZJyHCf9e2D",
            "symbol": "HVN",
            "name": "Hovno",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/filip-a-a-a/crypto/main/hovno.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6F2xFQE5weEKZQsfNva1hXVYRVfo8d2NGbeAZYm1fNax",
            "symbol": "MLEM",
            "name": "MLEM Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xatarox/MLEM/main/MLEM.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2sAfB9CDBGFdmvQRB4DAAv8shu2JS5rXoC6bUMezyJjZ",
            "symbol": "Shipu",
            "name": "SHIBA PURE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2sAfB9CDBGFdmvQRB4DAAv8shu2JS5rXoC6bUMezyJjZ/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.io/Shibapuretoken",
                "twitter": "https://twitter.com/Shibapuretoken"
            }
        },
        {
            "chainId": 101,
            "address": "EevgM1mYtXmYUDt6JauTQi9fuj5RvGJp2QY6WuMssgj8",
            "symbol": "NSX",
            "name": "Naseriax",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/naseriax/token-repo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DEpEgwSmk5EwaezB7tFaGFGtKkj1BoMn4PDV6HS572EZ",
            "symbol": "IWMO",
            "name": "I Want My Own",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iwmo/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G1ypuiZV7zJ6yi95B7YyVjsj91qS6hG9DRTNpchCXKQJ",
            "symbol": "GGD",
            "name": "GodGold",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/birlan/ggd/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "77SBw7BV35pQTrqCbrHz9UGswhWu7ZYBPYECuJU9JE5B",
            "symbol": "F",
            "name": "MR F",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ARO-coder/logo/main/2021-11-21_13-54-41_UTC_profile_pic.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DF5duTamJpaiL4Mg2xrgskqxq7PKZ5arMtKExsCLCzdP",
            "symbol": "HCk",
            "name": "Herculi Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ManojGurungPi/herculi-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "58nQHy4ACJksBm5FfprDrPAR5bu1Rkgm4bwCPs2H2pQp",
            "symbol": "Synapse",
            "name": "Synapse",
            "decimals": 9,
            "logoURI": "https://arweave.net/h5NOMoLgFKGBZGOkipic6XFRBdCi7PKWLf5qtm-urzg?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/plsandtybased"
            }
        },
        {
            "chainId": 101,
            "address": "39M2HJPA7jcbtv8vspMtgFhddUsqGje5s8YPG52zCtAR",
            "symbol": "NONE",
            "name": "NoneToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/N0ne0/CryptoToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FCWm6BzKMmFUzgPcrdPJNQbm5LqTkgfCNnyMimq8xHJ4",
            "symbol": "BNAN",
            "name": "BNAN Token",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmUxtp9gwPuUeGTA9fLGWLpJoEhCuWouxKeWnVP1cPmEsP",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/apefoodnft",
                "website": "https://apefood.club"
            }
        },
        {
            "chainId": 101,
            "address": "BiC36dQPvucFf3Xa7mEPxLGU63Xq7CJ7UBko5HaYEj7B",
            "symbol": "DOINT",
            "name": "Dwayne Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheDankness/crimpto/main/doint.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Qc1ZtQeR46aq6CEcf16XngA4dASqNHMrejfkmZPXy9z",
            "symbol": "WSGS",
            "name": "GameStonk",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Qc1ZtQeR46aq6CEcf16XngA4dASqNHMrejfkmZPXy9z/logo.png",
            "tags": [
                "GameStonk",
                "WallStreet",
                "WSGS"
            ],
            "extensions": {
                "twitter": "https://twitter.com/gamestonksol",
                "website": "https://www.gamestonk.fun"
            }
        },
        {
            "chainId": 101,
            "address": "8bQzEZJ3EkkYe7m9z12ewYVsMPBaxEVwPe4UnXRQUizi",
            "symbol": "FWB",
            "name": "FriendsWithBenefits",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8bQzEZJ3EkkYe7m9z12ewYVsMPBaxEVwPe4UnXRQUizi/logo.png",
            "tags": [
                "FWB",
                "Dating",
                "MobileApp"
            ],
            "extensions": {
                "twitter": "https://twitter.com/fwbtoken",
                "website": "https://www.fwb.mobi"
            }
        },
        {
            "chainId": 101,
            "address": "7SyL8WHkAhGqciL9qrLMRYrsBpiFCF9LEfCwvTWhqNRQ",
            "symbol": "LXC",
            "name": "LatroX Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MrLatroX/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Eth7YxeKCKEke37RQvXwBukr1HS6LpJV5Tu99m8Xq7M",
            "symbol": "WJF",
            "name": "We're Just Friends",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Eth7YxeKCKEke37RQvXwBukr1HS6LpJV5Tu99m8Xq7M/logo.png",
            "tags": [
                "MEME",
                "Friends",
                "community"
            ],
            "extensions": {
                "twitter": "https://twitter.com/werejustfriend_",
                "website": "https://www.werejustfriends.space"
            }
        },
        {
            "chainId": 101,
            "address": "7HGJwFyKC5wPLZ9ctgVSgjBARJ4dZrGCjMRQmzWwe277",
            "symbol": "Synapses",
            "name": "Synapses",
            "decimals": 9,
            "logoURI": "https://arweave.net/h5NOMoLgFKGBZGOkipic6XFRBdCi7PKWLf5qtm-urzg?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/plsandtybased"
            }
        },
        {
            "chainId": 103,
            "address": "8M6x4qnPPjrevm81h65fE2bQMNYU58NnTUi1BZQh1xUb",
            "symbol": "WIZZ",
            "name": "WizZ Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8bQzEZJ3EkkYe7m9z12ewYVsMPBaxEVwPe4UnXRQUizi/logo.png",
            "tags": [
                "social-token",
                "wizzcoin"
            ]
        },
        {
            "chainId": 101,
            "address": "AgnTcc2aWssiFxXDXW78QdS8GkPkYL9LzULxD7bMZ2Rg",
            "symbol": "SUS",
            "name": "SusCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/matteopassaro/crypto/main/sus.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FCovHdKLr4gzYkE5mVusbv3VvtCoB9AkQve8LsWX4rC8",
            "symbol": "KET",
            "name": "KE Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/francomalatacca/ke/development/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6jdXya2Bsh4aa1vvT6vYPMRw9MWUJRca5Yy4B7CZXbmB",
            "symbol": "Medusa",
            "name": "ProjectMedusa",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Qalander/medusatoken/main/Crypto_red.png",
            "tags": [
                "NFT-token",
                "Game"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/8Trp4b6W7S",
                "telegram": "https://t.me/project_medusa_NFT",
                "twitter": "https://twitter.com/medusa_token",
                "website": "https://www.projectmedusa.art/"
            }
        },
        {
            "chainId": 101,
            "address": "4o5FbTaPJXbyvso6puKuhM1JARuyK19G67amaGrrPt8c",
            "symbol": "CAMM",
            "name": "Camm Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/neilcamm/cc/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "356xypTDqfngosF1tf5gtK2mSBAsK8stMqNefHcqSBqX",
            "symbol": "FLOOC",
            "name": "Floofy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dbotero1979/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FBoxdQdkHBMFosU6qwz247FDVdorbvRruUUhkc7Xoyc6",
            "symbol": "ENNE",
            "name": "ENNE Token",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/ENNETOKEN/ENNE/main/ENNE%20TOKEN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ESW2pUBXadPr1GmWmhoumpyBgGpHfYKQVVxqMPrwtqQ1",
            "symbol": "FSLT",
            "name": "Falling Star Labs Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoseOlivarez/FSLT/main/chip.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5JvWhg6s9wLqeEwQydi3QhXF9x2WxywCTTR8n6MiJus3",
            "symbol": "STE",
            "name": "Ernst Stelter Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PeerStudios/crypto/main/ste.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://peerstudios.net/"
            }
        },
        {
            "chainId": 101,
            "address": "3SVXFzve2s5idL8BHp1LD1L2d6zaAv4s6g7ESx5Fwfpr",
            "symbol": "MARIER",
            "name": "Marier Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jonshadow12/twelvecrypto/main/12logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8jdeZHreUVmAxmdyS5bAvvW5XvJG9XBDiUAiuHGhMMVh",
            "symbol": "THRIVE",
            "name": "Thrive Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/axw4319/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EbzUDLcqe5BrqmSjFhQ9yEo5hfeAZrkf8sjbq2MRn5kC",
            "symbol": "HAWT",
            "name": "TrippyFox",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EbzUDLcqe5BrqmSjFhQ9yEo5hfeAZrkf8sjbq2MRn5kC/logo.png"
        },
        {
            "chainId": 101,
            "address": "Gn8qnQQ12sqCCxWb49DKYZgkryDCByBg6R9oREaJaami",
            "symbol": "DAYZ",
            "name": "Dayz-HK Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dayz-hk/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fe43isUjdq96t8Bh1o6UQPU1yKufjGZ3VLwWaH31wRxy",
            "symbol": "SUGBABY",
            "name": "Sugar-Baby Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Tmindz/crypto1logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3GKYR5DG7yzz9L6n2zeK8pXDAp75wcM77P1ikBpde4hD",
            "symbol": "PRBL",
            "name": "Problem Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/problem-token/problem-token/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/problem_token/",
                "twitter": "https://twitter.com/ProblemToken",
                "youtube": "https://www.youtube.com/channel/UC24s-Cdqu-xBKiaxSnhmNnA"
            }
        },
        {
            "chainId": 101,
            "address": "Eb9jRXpnYSXeJKudmd4qu1DhdryZQ1cRvWUbEGcKrYA8",
            "symbol": "HGLD",
            "name": "Heroes Gold",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ebmitcham/cryptologo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2AufhXLgWXmVjvYZw8jB2Sy4GmquysoSRLUhgVnWQyKm",
            "symbol": "SLFA",
            "name": "Sulefa Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/masterJedi21/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "USRhHFaeV36fsff6J9RRC3nNhuE3o5zvNUwbzYw9S3d",
            "symbol": "MYC",
            "name": "MinYanCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dams-rally/Crypto/main/icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9VSTThdFzN53eUqMdz5EmfXPjmTf2LHE3aSdX8DqpkyQ",
            "symbol": "ASI",
            "name": "ASICOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NikX23/Crypt/main/ac.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7hbS1Duxm5CiVaLGZkFaqMZGcrZka5p2zX5ruYxqQgvP",
            "symbol": "DOHA",
            "name": "Beautiful DOHA",
            "decimals": 0,
            "logoURI": "https://github.com/samurtuza1/DOHA/blob/main/Doha.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C523e6ZU4WTjpTmUC82FuPegFTdeYiR9Tv5ZhBAgtMEV",
            "symbol": "NSVD",
            "name": "Nicksylvanian Dollar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nickgabbard/solanacryptotokenlogo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gq5iXNG4vLi4iNMDLSmcJ13uxPf6V9bUzQ2QP7CFvzZs",
            "symbol": "BODC",
            "name": "Bank of Dad Coin",
            "decimals": 4,
            "logoURI": "https://github.com/newdr003/cryptostuff/blob/main/assets/images_200x200/75e2cfc3473b40bb8b7bfebb59069f5a.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HnQnC3jyZdCYiTKdswLaQMitC84o3yWfYP5G1BtkHJmw",
            "symbol": "NEX",
            "name": "NEXTOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/One-Hundred-and-Eight/sol_tokens/main/nex/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EcmKxi48X14Vrb9DHQRWRUAqYTBQLcC6eiZ4soPB3yPJ",
            "symbol": "CHILL",
            "name": "Just Chillin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Prime3vil/Just-Chillin-token-Solana-/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FzcmRR3wfGXLAcTkKkocmnQLjZKSScb3QhCB8jbUfj9D",
            "symbol": "AHDT",
            "name": "AHollaDolla Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LaptopHeaven/aholladolla/main/ahd_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6vW99phJaQVbvQVUviJT3hm8jumanQSTtFo1iht9AevD",
            "symbol": "ET",
            "name": "ello_Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/F12364/et/main/ello_token.png",
            "tags": [
                "social-token",
                "hope",
                "F12364"
            ]
        },
        {
            "chainId": 101,
            "address": "BjdhCgJcdvvprhY3pQfJgsLfKJCyPFAW6Mt1gB8hExGH",
            "symbol": "TALON",
            "name": "Sendai Tokens",
            "decimals": 6,
            "logoURI": "https://toc24s2sbu3nkr65vejwjh2bmer3x3vo4xbhzcacaxr55ant3o2q.arweave.net/m4WuS1INNtVH3akTZJ9BYSO77q7lwnyIAgXj3oGz27U/?ext=png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/BwvMzPgHpC",
                "twitter": "https://twitter.com/SendaiDojo"
            }
        },
        {
            "chainId": 101,
            "address": "E5x8hsbRVHvQXE8DcDZJhNzAttvTsymrFeb4CnkmCLLN",
            "symbol": "SOYO",
            "name": "SOL OYO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5x8hsbRVHvQXE8DcDZJhNzAttvTsymrFeb4CnkmCLLN/soyo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C8vpyWwYzQtAJH6otFkmZm94ydDCo19eGKXvmbXKAFop",
            "symbol": "L8S",
            "name": "OchosCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/agrestic1/OchosCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EHG9u6AYczWsd6NquX9AwrEZg7zt5mq6VmhHWcDDkyo6",
            "symbol": "DKNT",
            "name": "Just Darknet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Prime3vil/Just-Darknet-Token-Solana/main/logo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2aFsiuarZZPLPCYSwETvhZhwb6us7QxmB99RWc2qTCY6",
            "symbol": "LRN",
            "name": "Lauren Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LukeNash1/crypto/main/LaurenCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8PMGVDRYyUzP9ZzC93BQuiETpsRHjVuVuhBkdsJSLrUb",
            "symbol": "PORZ",
            "name": "Porzios",
            "decimals": 0,
            "logoURI": "https://github.com/aporzio1/crypto/raw/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhQJREpdHHpEaBbN6aMw9dZVHaLytCYkCVRZrZuz6tG5",
            "symbol": "TAMAL",
            "name": "tamalerhino Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AhQJREpdHHpEaBbN6aMw9dZVHaLytCYkCVRZrZuz6tG5/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tamalerhino"
            }
        },
        {
            "chainId": 101,
            "address": "Hdi94P9m8m6xn4fgQenSqsLg8MAVKumCXrcgzrXqJDvC",
            "symbol": "LABR",
            "name": "LabRcoin",
            "decimals": 0,
            "logoURI": "https://github.com/jtomacic/crypto/blob/main/Lab_R-Gold-Coin-Transparent.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "83LEryaa7MGudKvJNg9UrCrxgCFYVEGu8MzUaSnuDwWF",
            "symbol": "SHIMA",
            "name": "RinCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bakonpancakz/bakonpancakz/main/crypto/RinCoinLogo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Best Coin For Best Girl",
                "twitter": "https://twitter.com/bakonpancakz"
            }
        },
        {
            "chainId": 101,
            "address": "5SDM99Fp6J3suyLtEFEHPP5hexD7YnEDPeFrvZsMZUWv",
            "symbol": "PGCN",
            "name": "PigCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pigc0in/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Bx7uVFteBDd9jHTadUpaESoUaGjPCTWU61AyVmiG47K",
            "symbol": "JUJU",
            "name": "JujuCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DavidJKTofan/juju-crypto-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "34XTx66PbbvzpHhVqB5LdoLUY8Vo4KaZSeni5QKUa1C3",
            "symbol": "PIXEL",
            "name": "Pixel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/34XTx66PbbvzpHhVqB5LdoLUY8Vo4KaZSeni5QKUa1C3/logo.png",
            "tags": [
                "Community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BTkgSHMNvAZJsZ42fF38jMk6GhM55shF7PmD59YoDoQh",
            "symbol": "KMJ",
            "name": "Illuminati",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KhalilJouaneh/SOL_token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EtWJZTza5FAv1wsiRVQniozZvsVPB2C4wAbjYK9GJ2kf",
            "symbol": "KATM",
            "name": "Kat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DocWoodward/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E5ZGMWzA5KeSiVaPMbzfY9nELk2n9A5mPnitwnmUiwR7",
            "symbol": "BLESS",
            "name": "Blessings Always Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/securestrat/sol/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tamalerhino"
            }
        },
        {
            "chainId": 101,
            "address": "2yFHpcY6wRMHoAAFF2rZV7Lo25dTWe5E8wQ64Y4Ux3mk",
            "symbol": "SIDI",
            "name": "SIDI Network Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SIDI-github/SIDI-Network-Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kqTT8pcz6cQefzDmmT6MYVcZkvjUB7Yi4fb8ubRtyoh",
            "symbol": "DOTS",
            "name": "BLUEDOTS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gratsal/cryptobluedots/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CPTzy56dVrMFk9T5BxcdAV2yXpyyXbdVwELiZsU6DGoW",
            "symbol": "DTS",
            "name": "YWAM DTS COIn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/judeallanhill/crypto/main/unnamed%20(1).jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CZ9tL5UxpSftJMhBXys6vydWb9dZRxoNYF6uvumZWkS",
            "symbol": "HYPE",
            "name": "Hyperium",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CZ9tL5UxpSftJMhBXys6vydWb9dZRxoNYF6uvumZWkS/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "GameFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/frontiergame_io"
            }
        },
        {
            "chainId": 101,
            "address": "4FeZKwQ3kHK2JuSZ4QefmwE43j5NRL2zLFGxxLaC1d7J",
            "symbol": "PCLR",
            "name": "Pancholares",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cid8/pancholares/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BWcdAQSQZq2BW8jTP9QcTCieXpBddJEXTkZQVL4w7CWM",
            "symbol": "HAZ",
            "name": "Haris Pervaiz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/harispervaiz/crypto-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GezveZeQUvgqc2jY23weRgKwp1KMfawUx3587e6nGRSQ",
            "symbol": "FPGA",
            "name": "FPGALover",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Adrizcorp/SolFPGA/main/fpgaloverToken.png",
            "tags": [
                "nft-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://fpgalover.com"
            }
        },
        {
            "chainId": 101,
            "address": "HwvtHUqURttL79ryyPsCVgScLq9Yxm3VAZNrtE8w5fcF",
            "symbol": "JCCC",
            "name": "Christian Crypto Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thebibleunlocked/crypto/main/cryptologo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hk5yuhhgcuQMSCHuiciDRfk1Y39f1wR1dLAvMxC5Q69p",
            "symbol": "HANFU",
            "name": "Hanfu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/semmars/hanfucryto/main/logo6.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Yeb5am94p8qQBWJzWE5qafoSXUnEUCxAA9RSLYvQ1Ck",
            "symbol": "KIRB",
            "name": "KirbKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/obaker78/crypto/main/HarrisonKirby.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CC1S3Rxzxt9x4FMyXZ4KbULhuWfhEm6r36H9Cp96pCDB",
            "symbol": "PHOTO",
            "name": "PhotoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/the-last-minute/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2rzAPePfebVPGCMnikyh2xvFXK2JFdLe9PqTywo8Aabn",
            "symbol": "TYBR",
            "name": "Tiny Bear Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/inventiveraptor/crypto/main/tiny_bear.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AXRAgXYUgNai7FPY9RfgWa71UPtEPZP2kjBwH9KhGKBH",
            "symbol": "EMGT",
            "name": "Emerge Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EmergeToken/emerge-token/main/logo.png",
            "tags": [
                "utility-token",
                "emerge-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FHF1TDmPGKTLUi6mjrT7NDMrFXs4SCVvB7QWn3yswdao",
            "symbol": "ANAK",
            "name": "Anakoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/christianhjohnson/Anakoin/main/Anakoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FAigNii6yJEraz5LX9JQ9NUieUnk3ucY1eHKjXBGmd4d",
            "symbol": "CHKRA",
            "name": "CHKRA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FAigNii6yJEraz5LX9JQ9NUieUnk3ucY1eHKjXBGmd4d/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SoulKombat",
                "website": "https://soulkombat.com"
            }
        },
        {
            "chainId": 101,
            "address": "AamY54CmEp9CFLKCxy97x2zxhFvqSFbQuZRhLz1mbSjm",
            "symbol": "RENNT",
            "name": "RENT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/genties/rennt/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CLswAbXK6GKeLLZX6PXGGuqt8EyifLFC2j5iEgL3BySt",
            "symbol": "Cloudz",
            "name": "Cloudz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLswAbXK6GKeLLZX6PXGGuqt8EyifLFC2j5iEgL3BySt/logo.png",
            "extensions": {
                "discord": "https://discord.gg/solcloudz",
                "twitter": "https://twitter.com/SolCloudz",
                "website": "https://www.solcloudz.com"
            }
        },
        {
            "chainId": 101,
            "address": "3QE2K4QSHo1Y3rjjuAf9VEhCJxKRdpePD5jLP3NwKMmF",
            "symbol": "$DOPE",
            "name": "HellaDope",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/Texaglo/helladope/main/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7bEkdbEXe1tb9tHjMGk5p8Tzm4AuHpMLpptxALTdrGC3",
            "symbol": "EMMR",
            "name": "Emmer Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kjzamora/Emmer_Crypto_Logo/main/Crypto_Token_Logo_500x500.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3F6ujkso1NQtvpCDKocpNg8oynPW9TXawkPg5zQcyuAi",
            "symbol": "FNKL",
            "name": "Funkle",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Zumux/funkle-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CjpaJLNdGt4wmcPAT9GKBRVmGb6Q1TtdeJYmahmmrXSL",
            "symbol": "BAY",
            "name": "BayCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/UnlimitedKnowledge/BayCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C7MnWhwB4JyA21bo6wnomQA5jJtd31WoaiCSKbVTHq5n",
            "symbol": "$PDSGT",
            "name": "-PDS- Golden Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7MnWhwB4JyA21bo6wnomQA5jJtd31WoaiCSKbVTHq5n/PDS_TOKEN.png",
            "tags": [
                "Pirates",
                "Metaverse",
                "NFT",
                "Community-Token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/qVxYyZwCKS",
                "instagram": "https://www.instagram.com/piratesdelsol",
                "twitter": "https://twitter.com/PiratesDelSol",
                "website": "https://piratesdelsol.com"
            }
        },
        {
            "chainId": 101,
            "address": "2txmAT8zzyfU2BSur95UNq8UWvYrb3e38VVZPoi4fmk9",
            "symbol": "POLO",
            "name": "Polo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/poloW-NA/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBKFn5xH2y5b4jEdtUUW8rYJkuYoKEfGzSoQR2GYKP5V",
            "symbol": "SHAKEL",
            "name": "SHAKEL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/shanginn/shakel/master/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/shanginn",
                "website": "https://shanginn.ru/shakel"
            }
        },
        {
            "chainId": 101,
            "address": "BDHAyGwpxaVJ8eDZMHRZMGnu3evRh7XvWj8BaPYCY4QZ",
            "symbol": "BRO",
            "name": "Bro Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BroBoi78/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FLWRna1gxehQ9pSyZMzxfp4UhewvLPwuKfdUTgdZuMBY",
            "symbol": "FLWR",
            "name": "Flower Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Neurologist/brains-token/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/solflowersnft",
                "twitter": "https://twitter.com/solflowers_io",
                "website": "https://solflowers.io"
            }
        },
        {
            "chainId": 101,
            "address": "8JChbLTDbu9KwNsdyMKZtiYH4Q5hsXntYXrRgpELEgk3",
            "symbol": "UNCN",
            "name": "Unicoin",
            "decimals": 9,
            "logoURI": "https://github.com/newdr003/cryptostuff/blob/main/assets/images_200x200/unicoin.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FG9B9uNWVAhEvAroY7A85QdcoWUsJkKUCm6kkUrcJSVe",
            "symbol": "SSC",
            "name": "SusoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ShayanBanerjee/crypto/main/suso.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F7Up1Gpmf9DfNkDSc7oxgDTH74e5Hic9XeW6nQDR8ggH",
            "symbol": "DRAW",
            "name": "ReDrawCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/djaydraw/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BKcFbs39yCN49E8DL7PGZLoNSDgxbWZgvLBmM8LSP4eX",
            "symbol": "SHCC",
            "name": "COMET COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/STIGGY97/cryp/main/logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EonLDmjQShcfaZqrjpr68cWEKLUiGDe9LkGSwny5FgM5",
            "symbol": "DNIX",
            "name": "DOJO Nix",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Adrizcorp/dojonix/main/Dojo.png",
            "tags": [
                "NFT",
                "Health",
                "Social",
                "Community-Token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/kianayazdani_",
                "twitter": "https://twitter.com/KianaYazdani",
                "website": "https://kianayazdani.com"
            }
        },
        {
            "chainId": 101,
            "address": "CfEx76J76ojTeqCq92xETrn8xQRpVHnHCRbyVXd8iC9Y",
            "symbol": "SKIB",
            "name": "Skibba",
            "decimals": 0,
            "logoURI": "https://github.com/SkibbaToken/SkibbaToken/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5nDSZD14xD73X5PybvVwD9mZEZTywntCuwRH25xJJ4RL",
            "symbol": "IATK",
            "name": "IA Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/imananimator/IATK/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/urbsclimber/"
            }
        },
        {
            "chainId": 101,
            "address": "HNapoupAmeAMbWQdQQb879NxLj8qyZa3XLjXapjju2oq",
            "symbol": "MDONA",
            "name": "Mercadona Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Swanterra/proj1/main/Mdona1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3BQCm29nq5TincBd3bGrJGu5GJxyxoi43fMUsKfdmBTX",
            "symbol": "SHARKI",
            "name": "Sharki Inu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3BQCm29nq5TincBd3bGrJGu5GJxyxoi43fMUsKfdmBTX/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3nWNZqiTaKj1gkfuUdMeHY46CXwpqKHkEFBKzXtD3t77",
            "symbol": "URK",
            "name": "Urken Galleon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ebsmartin/Crypto_token_logo/main/logo.png?token=AQAF6VXMKXFD7RD65OYQRJLBYLKVS",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8QgqUFu3JShwHEQyM8P4eBciziaPSfVMGcbh2yfzAFzA",
            "symbol": "DANTE",
            "name": "DanteDollar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bakonpancakz/bakonpancakz/main/crypto/DanteDollarLogo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bakonpancakz",
                "youtube": "https://www.youtube.com/channel/UCN02ZFUIb1qzpAgZPHqJaBw"
            }
        },
        {
            "chainId": 101,
            "address": "6xsn4erobfxuYSfoTKKNUS342uprdot5aiD6mGPZH5X6",
            "symbol": "MONIKA",
            "name": "MonikaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bakonpancakz/bakonpancakz/main/crypto/MonikaCoinLogo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Only Monika",
                "twitter": "https://twitter.com/bakonpancakz"
            }
        },
        {
            "chainId": 101,
            "address": "3npEGBS1b52sSAMkWXo9eWraN9VBqqPjgWcJJRWoDxhi",
            "symbol": "MAI",
            "name": "MaiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bakonpancakz/bakonpancakz/main/crypto/MaiCoinLogo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "The show wasn't even about bunny girls",
                "twitter": "https://twitter.com/bakonpancakz"
            }
        },
        {
            "chainId": 101,
            "address": "BRET7yshXvwbesBpcWRpG98HFAKq4KaB3Vf2mQhKcA8K",
            "symbol": "DRKC",
            "name": "DarkCable Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DarkCable/dccrypto/main/dclogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bk3bRfj15DoZdhb6efr6QtXmoHhQJ9rAbK3wJKoxPdSa",
            "symbol": "DEWC",
            "name": "Dewey Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jpdewey1/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DxQHfEWnqjmD1pcnSm3RYxdM6uJJrLky7GZY1gdrJVAS",
            "symbol": "BRAD",
            "name": "BradCoin",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/pMt9YqZ.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M",
            "symbol": "SEI",
            "name": "Solanium Ecosystem Index",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/MatrixETF",
                "twitter": "https://twitter.com/MatrixETF",
                "website": "https://www.matrixetf.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "6mDtfcXkeRhy8Rt5ai6SmeDtpeaAuEvX5vCRcH4BUWL7",
            "symbol": "RITA",
            "name": "Rita The Mini Pinscher",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Adrizcorp/Rita/main/Pinscher_logo.png",
            "tags": [
                "NFT",
                "Pets",
                "Social",
                "Community-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Y5XYB3qjSpod8QmwEr5SCmNESf5F6oB5DBEXVxjQ6nH",
            "symbol": "SSTC",
            "name": "SST Coin",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/DAQPnn9.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CuStQeCcs9fmQAziyMETjnmqjMYC3EpY5pxijvi6Q7A8",
            "symbol": "CYB",
            "name": "Cyber Block",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dzaja123/cyber-block-token/main/logo.png",
            "tags": [
                "solana-token",
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "5DfhZugS25gPf84LF5u6LjRCzW1XCFuRd88PAujoeic5",
            "symbol": "MZOO",
            "name": "Millionaire ZOO",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/mzoo/main/assets/MZOOp.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/millionairecroc",
                "website": "https://www.millionairecrocodileclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Ap25HkKz66wLWYPid1DEer598YcJP4zrFuENiWf3PMsc",
            "symbol": "BIER",
            "name": "Biercoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xblackbytesx/biercoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FCRMFcfmZTY5qekzVYAmSRcxjSp4dMqSL8Gb3s2JkCAu",
            "symbol": "TRKR",
            "name": "TruckerCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZetaWaves/TruckerCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3k9STfrMqCdCB25TDKhy278KAu8AGrLQscbE9e9zssd2",
            "symbol": "STRKS",
            "name": "STRIKES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LazaBre/strikes/main/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://monketoken.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "Bny3hdj4iodageysz6VDnF2pPXw7aW31v5kVtcZHi8aM",
            "symbol": "GOPU",
            "name": "Gopals Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/gmallapp/gopal-coin/main/gopu-logo.png",
            "tags": [
                "GOPU",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D6jj3SiCwng8oDMZajPFPgw3KiQH5Qfw52Ch5Qpxvnhm",
            "symbol": "AMCN",
            "name": "Ameer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/amelli123/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EA1b7yP2BPELGu4M8NhiqccVDg5cBueMLq7LEvDsA4fo",
            "symbol": "LITC",
            "name": "LitCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/asidscript/LitCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7qaJM6UuC57iouPyeun5vqfJxkV9YiouDrgLMqTmFmFb",
            "symbol": "RAINR",
            "name": "RainerNFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/R4cc/mjuzes78fh3/main/rainer.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "address": "9MPyrhUqR9MTpDs9kBsNZCSwtexg9MJSntXvc2vBENZg",
                "website": "https://eke.at/"
            }
        },
        {
            "chainId": 101,
            "address": "HhWxJzGDCfU7DSzU9ScbpvrXsZW9VLPs8QfnhgYzxU2e",
            "symbol": "QUOINS",
            "name": "QUOINS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gavinfysh/quoins/main/logo.png",
            "tags": [
                "Metaverse",
                "NFT",
                "Community-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "DF1CXqAv9SoVjpG318CgyLkAorTDcHraUBwSqoAcervS",
            "symbol": "ROAR",
            "name": "Royal Bears",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DF1CXqAv9SoVjpG318CgyLkAorTDcHraUBwSqoAcervS/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://royalbears.art/"
            }
        },
        {
            "chainId": 101,
            "address": "FtuzPJYErhtZnqAo5Q34QEAtvr3YBdhoYHwE1fM9hFue",
            "symbol": "RSD",
            "name": "RAHUL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FtuzPJYErhtZnqAo5Q34QEAtvr3YBdhoYHwE1fM9hFue/logo.png"
        },
        {
            "chainId": 101,
            "address": "2ZLYEWypSrQhruqsTDqWNWuzFXe5G75dX9PoHQWtKZ31",
            "symbol": "PTN",
            "name": "Photon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ZLYEWypSrQhruqsTDqWNWuzFXe5G75dX9PoHQWtKZ31/logo.png",
            "tags": [
                "DeFi"
            ]
        },
        {
            "chainId": 101,
            "address": "FX1jtt1ebuEufXe4kV1otstnD1cz9J8rhB28U7hssWKY",
            "symbol": "LAMRO",
            "name": "Lamrium token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FX1jtt1ebuEufXe4kV1otstnD1cz9J8rhB28U7hssWKY/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LamroEmperor"
            }
        },
        {
            "chainId": 101,
            "address": "FAuXyL1eUw8yQQeMBNnx4WHf5Nd2PXBnRgcntt136ay8",
            "symbol": "ISH",
            "name": "ISH Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ishnat/ishtoken/main/natishcoin.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ivCB3vEfexeYsRtkXrvwFswjPgwnHS6qFCUCLJ3zyPD",
            "symbol": "NRC",
            "name": "NirCoin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/gavinfysh/quoins/main/logo.png",
            "tags": [
                "Metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "DgYmLNy8sdZ4qEsdCibwtpnxYywbmQrFWRpWaSmanu6V",
            "symbol": "MGTK",
            "name": "MGKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/giannolamichael/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8hUkXDpFYeynBXFCxwJQKSf6x7g5UvBvpXcjnrKpJcyt",
            "symbol": "NODY",
            "name": "Noddy Badge",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JeanPant/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9WNFtVYj1dUiF5nUM7zBz644hPbKgEDuxk6iHAohQfii",
            "symbol": "SPACE",
            "name": "Space Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NLM-fr/cryptocurrency/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GEFs1a5X6FpEuZKcxk7HSBZQqyjms7XmiAdLXv1wCJqu",
            "symbol": "GLG",
            "name": "Glenelg Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FrasSmith/glenelgcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C7vBpA5AzK4AqEkEzzruZJvzp8gg1mysgs23yCWvpcoG",
            "symbol": "MOECOIN",
            "name": "MoeCloud Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/moecloud/moecoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6MsHo9eB1cyvdNurs36KDQ7DBo5Yh7F8C5AB4hNxZX5B",
            "symbol": "Amc",
            "name": "Amazing Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/puyopuy/AmazingCoin/main/Amazing_logo_b.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3SReAPms587EPBi1VTGRdoXda6ueDpgxpHUqQ87m4S3g",
            "symbol": "MAYO",
            "name": "MayoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Melsubbink/mayocrypto/master/mayoLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7z1eQmEhhM9e1AVCBQc6BzMZWmCZRqHCLJtkDgHxzYnQ",
            "symbol": "LFGO",
            "name": "MEKKA FROGGO TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7z1eQmEhhM9e1AVCBQc6BzMZWmCZRqHCLJtkDgHxzYnQ/logo.png",
            "tags": [
                "community-token",
                "nft-token",
                "Meme-coin"
            ],
            "extensions": {
                "website": "https://mekkafroggo.com"
            }
        },
        {
            "chainId": 101,
            "address": "DpGbcBZMaWMhG31ZH8ESkXEy7CJAmV4ynq4fEcaZac3d",
            "symbol": "GEOT",
            "name": "GEOTDev",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GEOT-Dev/Crypto/main/logo.png",
            "tags": [
                "solana-token",
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "FmzLLbK99h3MU5DgWSApKCZh8AgdouPuAsFJUumYQrF3",
            "symbol": "CCT",
            "name": "Cool Carbon Tonne",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FmzLLbK99h3MU5DgWSApKCZh8AgdouPuAsFJUumYQrF3/logo.png"
        },
        {
            "chainId": 101,
            "address": "FHFsFMxBbV3ExHyph9B6ANEBe8RC9fNjFFCZWZkcXH3t",
            "symbol": "OXSC",
            "name": "Onyxs Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OnyxScoin/crypto/main/OnyxScoin-modified12neeee.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DQNdtA2KjZZZpC76BGh9QTtJdaZ8ir5wf7yNafEMzsD",
            "symbol": "AKG",
            "name": "ArkEnGel",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/st-hannibal/ark/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EtusNf4w3ccfjuMwkVR2tS1HjtGH5bUuQk7xfBTRKUdJ",
            "symbol": "NFTCO",
            "name": "NFTs Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EtusNf4w3ccfjuMwkVR2tS1HjtGH5bUuQk7xfBTRKUdJ/logo.png",
            "tags": [
                "nfts",
                "nfts",
                "nft-token",
                "social-token"
            ],
            "extensions": {
                "telegram": "https://t.me/nfts_coin",
                "twitter": "https://twitter.com/nfts_coin",
                "website": "https://nftsco.in"
            }
        },
        {
            "chainId": 101,
            "address": "81yyyURdDTrzVRRvMXec5gvLbCoeskASYWH27JCiW5A9",
            "symbol": "HRSS",
            "name": "Horses Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NormanCrypt/Horses-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dvw9PxT9W7aya1wUQeUGwgVXqL51ar1rJj87SY6yfK1y",
            "symbol": "CHOD",
            "name": "Tang Chode",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/OshKoskByPosh/TangChode/gh-pages/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "meme-coin",
                "solana-token",
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "FPivaNb8NnQfH2yF3fXEr1GSnRjMpoP51yNVctn1QG1U",
            "symbol": "FOX",
            "name": "Fox Fox Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/inghong06/logos/main/foxfox_token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8PZnzjEUJ1B1sMAU3xhzpfK8T4QzydDrnTZrWdzzcno",
            "symbol": "DAI",
            "name": "Dark Assassins Inc Coin",
            "decimals": 0,
            "logoURI": "https://darkassassinsinc.com/img/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6kTxevQU4Kpbs2wtjiRsqFiswqeyEiWiCDys5fNnNA9b",
            "symbol": "BAR",
            "name": "RugbyFisicas Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lem0z3n/BAR/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ErgUVcypn2SMYCvLVNkTjosYFLdpwGqW8eNksGXcnjy4",
            "symbol": "TREASURE",
            "name": "Treasure",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ErgUVcypn2SMYCvLVNkTjosYFLdpwGqW8eNksGXcnjy4/logo.png"
        },
        {
            "chainId": 101,
            "address": "GoiEZgEyGx25uSqNynsS5MGUCKHqtuJgg3hbdrDvc2Dq",
            "symbol": "DGTL",
            "name": "DGTLVCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DeenGierke/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8uPyFJP5W6hm3RLr7YK8qssrF8zYb7Q2dbVxUmXrbD5k",
            "symbol": "TCC",
            "name": "Tiny Check",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8uPyFJP5W6hm3RLr7YK8qssrF8zYb7Q2dbVxUmXrbD5k/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CNEZzNnxFHhczchBCh7cst9tDs4Qw3jwhT3hfqe3ZFvZ",
            "symbol": "MNTF",
            "name": "Mintoff Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/steampunkk3/Mintoff-Coin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CstznQoPXmePk3yCCGDHqKzhTsGe4KSQ1zVMPWEPhTcX",
            "symbol": "RST",
            "name": "Ricardo Santana Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rs-token/rst/main/RST-2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2oXgY5EfpewjWoMxG9eWqHZUhevjeHEwnPabCwHSiFSM",
            "symbol": "ADIC",
            "name": "ADDICTED",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mikelc1987/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5QW1HrbmwcYq36XS4VFEzjAezXBqqXBmBYDKgcdDbwu2",
            "symbol": "WvzG",
            "name": "Wilhelm von zur Gathen 1880",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/wvzg1880/coin/main/1880coin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://gathen1880.de"
            }
        },
        {
            "chainId": 101,
            "address": "9Fp1n143x8a2hUExpNRg1e2xQDNLiFHEUmZKfP1Yxxx6",
            "symbol": "BBSS",
            "name": "BitBarbers Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NossaCoin/BitBarbers/main/bb.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8xNkMsrj7ZexUhVY5wQCCwicLFnZLurjpKvLLpwhR1Rs",
            "symbol": "SASSY",
            "name": "Sassy Cat",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/veryDead/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GHjotLX6RCdoNWmM9BLTEX3qBa1zK49T61cyQ1vzTMYe",
            "symbol": "SMKC",
            "name": "SofeMkd Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sofe1204/token1/main/test.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8VfCXEQ2bB1K9PXs1tdqgNM7zsNoLii68HaZytQoCg4a",
            "symbol": "MSOL",
            "name": "MetaSOL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WebWeaveSimon/crypto/main/METASOL.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6J35JYuNeWsr7T3Dvynfv29EWYaTbu7Te4u9usbZ74rU",
            "symbol": "AMY",
            "name": "Princess Amy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/inghong06/logos/main/princess.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "291P3h5J6Zv31xiGhpps8EhidXmv6YUxceHy19uBT3Ue",
            "symbol": "SAMS",
            "name": "SAMAN'S COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/saman1717/test/main/logo.spl.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ajPmmLNtwFsHjeU289Y1v9MU6WwoBGVcAnRVyPcj5YY",
            "symbol": "DRW",
            "name": "Dragon War",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ajPmmLNtwFsHjeU289Y1v9MU6WwoBGVcAnRVyPcj5YY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/dragonwarann"
            }
        },
        {
            "chainId": 101,
            "address": "52qS8ZHUYjnpBf1Gg2hWB1kXdEWRwDmAbqtvgAdmxSxr",
            "symbol": "CSOL",
            "name": "CyberSolid",
            "decimals": 0,
            "logoURI": "https://github.com/Cyber-Solid/CryptoCoin/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CPQEAqdBduoY3Q2ZsfWKMR5a33wXgXiVWKvEXBZaBWVP",
            "symbol": "PFX",
            "name": "PROFIX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/csco32/crypto/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EPe5HaGZJgnYfSPgJmK4r3AWT3Ly2wMbxyPjuTNedc2J",
            "symbol": "STORM",
            "name": "Storm Bot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Keoki-Hapahaole/crypto/main/a1252a1221e6485c9e709a20d2278e27.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6qcdkHKgDWmgronGowmYHDBQkeBfEuer1U1dxFbkknM7",
            "symbol": "CPI",
            "name": "Capitan SQ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cristianfc/crypto/main/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7eK3SKjBqgxDFgGJHdsHBbKJd6Mm2tLgjJWcdkkicuD9",
            "symbol": "DIC",
            "name": "Dialing Innovations Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ron3075/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BtrQt4YugR4nEKEGAmutUY1GqT35RLoNYKZgi3YJFu6b",
            "symbol": "BNG",
            "name": "BingusCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PandaLizzy/binguscoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3JPyHnGXWDBbDyfiKYRAY12XXxqrpcm94tTWESZWTroT",
            "symbol": "MAHDIROSTAMI",
            "name": "mahdi rostami",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rostamimahdi1997/mysoltoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6DaFkcqxznP8EG5XnPFsvUy1sZDsYCR5Yw2jU4nomCDf",
            "symbol": "CASINO",
            "name": "CryptCasino",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/15Teds/crypto/9a72a55c5572f53df2471961f77fbcc955d9391a/logo.png",
            "tags": [
                "utility-token",
                "Casino",
                "Poker",
                "Blackjack",
                "Discord"
            ]
        },
        {
            "chainId": 101,
            "address": "3H2pm4JP7nCmsBDexFn1urEu2Kg5AcJVsMq7vBBPueDr",
            "symbol": "BRJC",
            "name": "BrijCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brijchavda/crypto/master/unnamed.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2TbxWoEgBvp7eigdpArmaj8XDAZcvCiuzJDUsScYyY2r",
            "symbol": "MAD",
            "name": "Mad",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/0ctobit/crypto/main/mad.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GMrE4hWjZTwvz8iDCGYuDv7TMUSjUG55RUjicKqKfaA",
            "symbol": "RLS",
            "name": "Relapse",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/REALSDEALS/relapse/main/relapselogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9z94xQBXxs2j3yQgVse5TWdR22DQPvSt3qRP9adpWqYG",
            "symbol": "CMET",
            "name": "CME TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/criptyan/logo/main/logo.png",
            "tags": [
                "learning-token",
                "training-token",
                "meet-to-earn",
                "study-to-earn"
            ],
            "extensions": {
                "description": "An incentive economic system.",
                "telegram": "https://t.me/cmetacoin",
                "twitter": "https://twitter.com/cmetacoin"
            }
        },
        {
            "chainId": 101,
            "address": "Hkcr4EnPuMnrhAYpwmcxAHYe36qQ4kd4NxSjFrPZkv9N",
            "symbol": "DMPA",
            "name": "Dempau Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DempauO/Crypto/main/Vegvisir.Logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4F4Qm94iF66Q1RdPmxsfirxKSf7hfUGJXUajX2YQDSVh",
            "symbol": "MRAY",
            "name": "Mantaray",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/callum-riordan/crypto/main/M.png",
            "tags": [
                "fan-token",
                "altcoin",
                "youtube",
                "mantaray"
            ]
        },
        {
            "chainId": 101,
            "address": "4n4Uswf5Ncww6zpqxfba8t6i9EqWs624Gk15APa6H7CW",
            "symbol": "PNUT",
            "name": "Potternet Utility Token",
            "decimals": 0,
            "logoURI": "https://github.com/rpotter6298/pnut/raw/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8iE8gXkGUb4eawkymH6sAchQVCgv5r2inwYw51iPQtRo",
            "symbol": "NRD",
            "name": "Nerd Coin +1",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/Jazzlygeyteman/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D9rXY171xguq2hVG2uqhWLj1GoojwiPXYEwrXtbbAvRt",
            "symbol": "FNDT",
            "name": "Foundation",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kaizen56/Willow-Foundation/main/FNDT4.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FWr6PNFQZwgdtiY7WE5jk4qSN3jsNoTzqYBdNJ5M4WyK",
            "symbol": "DRIFT",
            "name": "Drift Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FWr6PNFQZwgdtiY7WE5jk4qSN3jsNoTzqYBdNJ5M4WyK/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DAO_Drift"
            }
        },
        {
            "chainId": 101,
            "address": "6zi8GRr9vQ4TrzevkxwEJoYbKegshKNeYD5KhKfEckfa",
            "symbol": "FAST",
            "name": "Fast Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6zi8GRr9vQ4TrzevkxwEJoYbKegshKNeYD5KhKfEckfa/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/Faastaaaaa"
            }
        },
        {
            "chainId": 101,
            "address": "7ieh2n31v1iycuxkBrnYHP7w9jQZ4dx9hrtsu9gzJG7k",
            "symbol": "SQCN",
            "name": "Squirrel Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bmoose0001/crypto/main/coinpng.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7xLGTSXmqSbbfiwDNUW4kudjHEUBTHXZpfCYLEwUfgDF",
            "symbol": "FEES",
            "name": "FEES Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HawkOne/Crypto/main/logo.png",
            "tags": [
                "social-token",
                "fan-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "742eEPp13jqwGRDiTthtQx4BH6XU2K5aC7nkGi1uP9Dr",
            "symbol": "PHAKE",
            "name": "PHAKE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/742eEPp13jqwGRDiTthtQx4BH6XU2K5aC7nkGi1uP9Dr/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DAO_Drift"
            }
        },
        {
            "chainId": 101,
            "address": "HbXGVxjyJe84zApRNmCeoEKFhBg87yxxUFhPnp7d1Czo",
            "symbol": "ATOK",
            "name": "Alegator Token",
            "decimals": 0,
            "logoURI": "https://github.com/alegator605/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8udZmv2RrHpU8rPZhphUGhHpmyAqc9UzV4UihpThKvYh",
            "symbol": "SFI",
            "name": "SolanaFi",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8udZmv2RrHpU8rPZhphUGhHpmyAqc9UzV4UihpThKvYh/logo.png"
        },
        {
            "chainId": 101,
            "address": "9tpUsGFmj5VHTDPJg7yQmbZe9hWAq5m3fpedcdCdJaRm",
            "symbol": "QREZ",
            "name": "Qreezcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/qreez/img/main/logo2.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9X3oTJc6noafdRpuN5QEekNCppYX15GWxkeNo4udF1ui",
            "symbol": "GGCHAD",
            "name": "GIGA CHAD COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/l0r5/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9g2C91epN91cJC34gGJ1cV4AjaECLTk4qGsgPMQhmo1R",
            "symbol": "CJC",
            "name": "Computer Janitor Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/computerjanitor/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B1vp5NZkZRqyzkndbgnnL6BqXpGnoayQAsnbe6dzrr3F",
            "symbol": "NCLR",
            "name": "Nuclear Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/dmeat27/nclr/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2B49v3kCtwt2AtPMMbwDnB995sob7TTWjkYFFgjQQyMK",
            "symbol": "BEAN",
            "name": "Bean Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chris3309/crypto/main/beancoin.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7NPgzYMiZhf3YJYny7ojmhhbNkuBrnyAJCXeyxBSXo4T",
            "symbol": "TEATO",
            "name": "TeachToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kobak/TeachToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FcS3ikUmmWpUbuabcFG3VE1nmt7NPE9ZnRM3ssPAFGv3",
            "symbol": "COOF",
            "name": "Coof Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CptKanooka/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "48AEwauAHsJibyt3WqjQ6EoHnFBcnyHASfo7vB2eCXPS",
            "symbol": "DRAW",
            "name": "DragonWar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/48AEwauAHsJibyt3WqjQ6EoHnFBcnyHASfo7vB2eCXPS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Dragon War Token",
                "discord": "https://discord.gg/kFUmzU6nCH",
                "facebook": "https://fb.com/dragonwar.io",
                "medium": "https://medium.com/@dragonwar",
                "telegram": "https://t.me/dragonwarglobal",
                "twitter": "https://twitter.com/io_dragonwar",
                "website": "https://dragonwar.io",
                "youtube": "https://youtube.com/channel/UCzl9Gsl58YR9MyL2650tg8w"
            }
        },
        {
            "chainId": 101,
            "address": "6tXyNS8rBiM3DFMniZJwzeJL2BUQPQbnjcu6aN2hdw9N",
            "symbol": "SHTK",
            "name": "Sh-tKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/boxswapper/sh-tkoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ary45BZeVNKjTSTs1BWmJ9oYXAPaDoHWpZXMjx6E5GRJ",
            "symbol": "DOM",
            "name": "Dominium",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/30323789/146248908-3deb9c10-eb88-4dee-a5ab-313d8ea00b07.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://domtoken.net"
            }
        },
        {
            "chainId": 101,
            "address": "9q2vXTk4zLFMLFri3fyzsHNxMo7MmtsHajcBipaSNhr6",
            "symbol": "BLC",
            "name": "Blockmec Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9q2vXTk4zLFMLFri3fyzsHNxMo7MmtsHajcBipaSNhr6/logo.png",
            "tags": [
                "blockmec",
                "blc"
            ],
            "extensions": {
                "twitter": "https://twitter.com/blockmectechno1"
            }
        },
        {
            "chainId": 101,
            "address": "EudmQA7EZLicfxomqHKjj4yEtM1HfMRXb7eik1RByUAq",
            "symbol": "LOOTKEY",
            "name": "Loot Key",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EudmQA7EZLicfxomqHKjj4yEtM1HfMRXb7eik1RByUAq/logo.png"
        },
        {
            "chainId": 101,
            "address": "8F2AE5AythfwBt864XGExVBiTn7THD9iSWcUP61vjkM3",
            "symbol": "UNCR",
            "name": "UniCOINs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FearfulSquirrel/unicoin/main/Marta'sUniCOIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6156vEwBw11hGF6rkr3um5RPNWfBCYBFH7XcbEF47erH",
            "symbol": "STEPN",
            "name": "STEPN - Run To Earn",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6156vEwBw11hGF6rkr3um5RPNWfBCYBFH7XcbEF47erH/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Stepnofficial",
                "website": "https://stepn.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6kerT9GaHmCj4MkVzo4mqBaxmwYDBHY3DddGFaLVuNyx",
            "symbol": "MLAND",
            "name": "Mineland Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kerT9GaHmCj4MkVzo4mqBaxmwYDBHY3DddGFaLVuNyx/logo.png",
            "tags": [
                "social-token",
                "mineland",
                "ml",
                "mlt",
                "mland"
            ],
            "extensions": {
                "website": "https://mineland.net"
            }
        },
        {
            "chainId": 101,
            "address": "7RY638ykd3eU2uxrSe16gjPGQ2u6kyohEoCyFxwWHweN",
            "symbol": "MKITSOL",
            "name": "MkITSol COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mkitsol/crypto/main/Logo.png",
            "tags": [
                "social-token",
                "Community-Token",
                "Utility-Token",
                "fan-token",
                "tech-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dv2yLQoTpLx4arCZGXiVvp7wyYURsNeoQzUifd2gTKQD",
            "symbol": "XAMO",
            "name": "XAmore",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aminamor/logo/main/CryLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AtiZT72Vk8Qu9kTXE1H5UtjPDJZypBKRye6TfiFWda4B",
            "symbol": "POSTER",
            "name": "POSTER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scotchegg/poster-coin/main/icon-CIRCLE.png",
            "tags": [
                "currency-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J3gJvE7WvBZpMZv1krC8Y9DeoZiRqRnCe18mJbXqswgZ",
            "symbol": "MIM",
            "name": "My ONE token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J3gJvE7WvBZpMZv1krC8Y9DeoZiRqRnCe18mJbXqswgZ/logo.png",
            "tags": [
                "solana-token",
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "FXSrKsQ34jMmMtciuzhr3KSTG5UMZQfXBJKGqYYUTxg1",
            "symbol": "ORKA",
            "name": "ORKA Climate Solutions",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXSrKsQ34jMmMtciuzhr3KSTG5UMZQfXBJKGqYYUTxg1/orka-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://orkacoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "H2HQBn2LiFtTWeL2JUHccQsuCsQevSn9dDaL8Ln3wLRp",
            "symbol": "TUBN",
            "name": "Tuben Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RubenOussoren/solanatoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8aJJvSXsv2VERrTvpJKjBLE6LUhdnYWTWurmBmCiw7si",
            "symbol": "POVRY",
            "name": "CryptoPovery Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Stephen988/cryptopovery/23e0682c917b3be9a0ff5d454b2225f92f9e6e65/cryptopovery1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ByQ9MXaamfBD8DraD5BKgXPffboJwfahknj1cmomawAi",
            "symbol": "BOOBS",
            "name": "Booty Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ByQ9MXaamfBD8DraD5BKgXPffboJwfahknj1cmomawAi/BB.png",
            "tags": [
                "solana-token",
                "token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "98kWFBQPLsUYAXTwkuvDfSrWgqdHL9LeP4K7BHDGowot",
            "symbol": "TGP",
            "name": "Togepi",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/98kWFBQPLsUYAXTwkuvDfSrWgqdHL9LeP4K7BHDGowot/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.youtube.com/watch?v=ur26D817lpM"
            }
        },
        {
            "chainId": 101,
            "address": "42pr4AkwMzHRspr8VXmP9mfaV8QP21WptmWA3SjNfAxS",
            "symbol": "RUHE",
            "name": "RuheCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/n3gox/cripto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EuX6qS6skQs9jRJBn7a5nTcAPuveoN7sJJaQgLzPnkd4",
            "symbol": "PTNB",
            "name": "Photon Bond",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EuX6qS6skQs9jRJBn7a5nTcAPuveoN7sJJaQgLzPnkd4/logo.png",
            "tags": [
                "DeFi"
            ]
        },
        {
            "chainId": 101,
            "address": "3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1",
            "symbol": "BAIL",
            "name": "BAIL COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ApeLabsNFT/solpatrol/main/hammer.png",
            "tags": [
                "NFT",
                "Utility-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "8b66S9rYyD6yspL9VsXrj8tGq2N2eqRvXVuixWYMkGLC",
            "symbol": "STWIE",
            "name": "Stewie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JacobieKenobie12345/Stewie-Coin/main/Stewie.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hw8SwgELe4YvP2ZfoS3SsGa8eBxka5rRfPSxdFNWK5sV",
            "symbol": "PLVO",
            "name": "PoruvoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/poruvo/pulvo-crypto/main/gp_greenstroke600x600.png",
            "tags": [
                "social-token",
                "gon-pulvo",
                "poruvo",
                "chiphop",
                "musician-token",
                "support-musicians"
            ],
            "extensions": {
                "twitter": "https://twitter.com/poruvo",
                "website": "https://gonpulvo.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E3Rf9zBzQo7NpV8ZpDsqRqvtH64yAJGctmmVJQxmfGeg",
            "symbol": "ITQ",
            "name": "ITQoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nsnagg/DarkWinter/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DRvToWQbnPCht3tJJTuWzMfFyHvUtJ4587d6wXCHRC9",
            "symbol": "LUSA",
            "name": "Luisa-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/schrismyers/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "84A5n7GsQUQXpkbgWBctouRk8hLwwYuq6KfjSQN5oY6Q",
            "symbol": "420",
            "name": "Legalize420",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/martynasb11/crypto/main/kisspng-cryptocurrency-otcmkts-cann-bitcoin-market-capital-5afa4fdc833f27.1643313215263538845376.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GY4DvdAo8pXcJRUqNiRfrs5XyYGwkw7g4bvzgXq8yePc",
            "symbol": "BFCS",
            "name": "Best Friend Cosmo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GY4DvdAo8pXcJRUqNiRfrs5XyYGwkw7g4bvzgXq8yePc/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2gdSHBhpvEWvFdeKUdYLP2NFAopovfA8qu48Y4EqS7s2",
            "symbol": "PEPR",
            "name": "Pepper Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ron-github/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Z98tC8DK3Mp9UTCHVkKdRo5wS5AHL95L15NYRKSVFiV",
            "symbol": "ZERX",
            "name": "Zerxes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/thatwkidjohnny/Zerxes/main/zlogo.png",
            "tags": [
                "zerx",
                "zerxes"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZerxesCrypto"
            }
        },
        {
            "chainId": 101,
            "address": "3YuUgH2VWJPBsDJmCsczhvTFjp3rryXwHwLFsVXUuZQm",
            "symbol": "FAYRIN",
            "name": "Fayrbear Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jayala1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DEApiXXZVmK92qprbzKMXy787NdTeeyT6SueoEMKc8k5",
            "symbol": "BTI",
            "name": "Betradi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kaihacks/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "43ybenGduSsE2CgXtMmfgqA3xmK1xpYq8KWAsJpMimUY",
            "symbol": "LYNX",
            "name": "Lynxer",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43ybenGduSsE2CgXtMmfgqA3xmK1xpYq8KWAsJpMimUY/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Copyright Lynxer INC"
            }
        },
        {
            "chainId": 101,
            "address": "21TwLx8V8Js538eeT9ARJTa6CpdThgCwj2mkbjKRpv6J",
            "symbol": "H63",
            "name": "Hyper Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hyper63/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BqQLq22YpMx4tS6Rgrkp9yGiAyucfDuycKMGpszttxyy",
            "symbol": "SHAWK",
            "name": "Solana HAWK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Nasino1/logo-/main/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FdLycDiJH5se75GZyxEdEdo6c3z7WHkoHZmvbkibUUrS",
            "symbol": "SPRING",
            "name": "OFFSPRING",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdLycDiJH5se75GZyxEdEdo6c3z7WHkoHZmvbkibUUrS/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/4gRMm6mgHZ",
                "twitter": "https://twitter.com/bitcoinisking_",
                "website": "http://rational.af"
            }
        },
        {
            "chainId": 101,
            "address": "7VsUAYX7fvkGo2sfXCSmoQK8ceEarQYQDDeTBZRrgAw9",
            "symbol": "HOLYSHIT",
            "name": "HOLY SHIT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Crypto-Never-Sleeps/ShitToken/main/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xWCqbuMWFx",
                "website": "https://www.cryptoneversleeps.org"
            }
        },
        {
            "chainId": 101,
            "address": "7Q86RemQhwqAimrxXoEuRfuFFynPVzskeo43b35bCd8H",
            "symbol": "OTWG",
            "name": "Off The Wall Gaming",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BlameAcm3/otwg/main/otwg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7SRmAZg1PfViXW2CHkPYfoR7MTTbT9yy53Q16nZNaysL",
            "symbol": "WIENR",
            "name": "Wiener Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/doxiecrypto/crypto/main/wiener.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4yG54Gk9ZsdTXk2RNZBELTHYBpy8dK4jcePNzokzKJWS",
            "symbol": "GDX9902",
            "name": "The Dojo Master",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Adrizcorp/siu/main/derick.png",
            "tags": [
                "social-token",
                "GDX9902",
                "NFT",
                "Gundam",
                "SciFi-Modelling"
            ]
        },
        {
            "chainId": 101,
            "address": "77A8ycvZQfwYb3h2Rc4f9masYfug1wKVRRJUPeMA7b6o",
            "symbol": "SCHRY",
            "name": "SolChrysalis",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/77A8ycvZQfwYb3h2Rc4f9masYfug1wKVRRJUPeMA7b6o/logo.png",
            "tags": [
                "Games",
                "NFT"
            ],
            "extensions": {
                "telegram": "https://t.me/Sol_Chrysalis",
                "twitter": "https://twitter.com/solchrysalis",
                "website": "https://www.solchrysalis.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6jUnhKET45gpvLtxkZL75m5K6EmCNTpnCfjhPKXicekm",
            "symbol": "PMC",
            "name": "PirateCraft",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/johenkel/pmc-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2YjgjsYjKiAVLWzQj3Z7ZgunE9gWvfxTRXfTTrivxR1L",
            "symbol": "H2XLKD",
            "name": "H2 XLK Definitive Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/M0RPH10U5/H2XLKD-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Nh9SR2EQ2PVTWL5UzKtRTtbz2v6FjMrFDbd2oKknjtT",
            "symbol": "HEIN",
            "name": "Heinie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jeffreyphelan/crypto/main/Heine%20Cypto%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "L1edE4UVsmeqdyK5bWpxzhozxw3jgnfcuimVj1SnyCf",
            "symbol": "LiDo",
            "name": "LiDo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/L1edE4UVsmeqdyK5bWpxzhozxw3jgnfcuimVj1SnyCf/logo.png"
        },
        {
            "chainId": 101,
            "address": "Htqzmit2pAYcTzWKYnwxM7QeX8nEQXMj8SEjWUuixVcB",
            "symbol": "ATHENA",
            "name": "ATHENA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eliotos/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B3ac69eFLTt5FvkSUuBvZDm2vCbh98UNeADm58UGvBQR",
            "symbol": "GI",
            "name": "GI-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Anoopkrishna2493/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6XJj9vt6yZdCcmCc9yvrsi3Ahk7GExm738WjNTiNniZU",
            "symbol": "QQ",
            "name": "QQ Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jenochen/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7LwnCotpbzC5adQjFJQbkCRme7Ufiew2FbG4y6KcDN2u",
            "symbol": "FREON",
            "name": "Freon Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CptKanooka/Freon/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8H4eH19o9yYWCGFQLe37fQhn9Jkb5TUVw8bwW9cmHNfu",
            "symbol": "OWSOM",
            "name": "Owsom Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/akangaziz/token-list/a0a791f031bb802306916b44797bdc41acdba907/assets/mainnet/8H4eH19o9yYWCGFQLe37fQhn9Jkb5TUVw8bwW9cmHNfu/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/owsomtoken"
            }
        },
        {
            "chainId": 101,
            "address": "84tUoZWc7EKrU7r8jRW4kBjN1wLwkCxhTc9q3CKVR5Gt",
            "symbol": "PET",
            "name": "Pet Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/84tUoZWc7EKrU7r8jRW4kBjN1wLwkCxhTc9q3CKVR5Gt/logo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "website": "https://anotherapp.blogspot.com"
            }
        },
        {
            "chainId": 101,
            "address": "5WchjqsYPUkYH4gm9S8YoUDsMMFTejSsVkupRxNbCsJT",
            "symbol": "GRDL",
            "name": "GR3ND3L",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Grend3lPr1me/crypto/main/gr3nd3l.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6G1Vkyfm43ESu2h7xdcKUReCJSTMjrgL4PkHFR1Mh7zj",
            "symbol": "KBLH",
            "name": "Kabalah Online",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/h4ck3rtr4d3r/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DNimUPcmzh1VN9oCTHNLTMS1u9nR2rCjjoNTaut1qhue",
            "symbol": "BF",
            "name": "Brave Finance",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ben92316/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A2JPQDuPC3f2sv31zxgaXz2HPEEWVTmpPmBPJnMMHLaw",
            "symbol": "AMPL",
            "name": "AMPOLO Coin",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2JPQDuPC3f2sv31zxgaXz2HPEEWVTmpPmBPJnMMHLaw/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BYxq3JX2ZvXWXw5RWUDJgQcZcyRBjLuH1ggs9RHdbxWp",
            "symbol": "HODGE",
            "name": "HodgeHeg",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JonesZoid/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DG2ax7h7gjXfa8KywHoVsxfAqMkmxpQzg8N1PPdNVCmJ",
            "symbol": "BLND",
            "name": "BlundCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/blundcoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "92hFV3mqiCmbVJFwTf8b6uhN4o3GERfPRvLw2ypmjMBC",
            "symbol": "CLS",
            "name": "Caals Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pieter-JanCaals/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHVUoxNqv3D7EgktBxUsxFF2Wx83hVDmD2wBBpUaw3jn",
            "symbol": "CHP",
            "name": "Crypto Health Plus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHVUoxNqv3D7EgktBxUsxFF2Wx83hVDmD2wBBpUaw3jn/logo.png",
            "tags": [
                "health",
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Crypto-Health-Plus-226283872703673/",
                "twitter": "https://twitter.com/CryptoHealthPls",
                "website": "https://cryptohealthplus.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GH2rFP4BQAxjsYEceffJvwrh31aD2EfC5rpWsSuBvZ5v",
            "symbol": "DIG",
            "name": "Dingar Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/infinitydigitalLLC/dingar/main/Dingar%20Logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "71W5aWT3M21MHzirD94DvcfHtGPLu7K8nhgxKAo5QDNr",
            "symbol": "JAKE",
            "name": "JakeCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tsaunya/assets/main/logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "A1nQA6t11dUW3JfmzoRf4LAc69UNZMt7eXw1N7imDWjh",
            "symbol": "TZD",
            "name": "Toonztudio Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A1nQA6t11dUW3JfmzoRf4LAc69UNZMt7eXw1N7imDWjh/logo.png"
        },
        {
            "chainId": 101,
            "address": "HMtUX7HpQBa8AwfVHiKSQRraWbCjDqx6FhCbJAryt4uq",
            "symbol": "FANF",
            "name": "FANF team token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMtUX7HpQBa8AwfVHiKSQRraWbCjDqx6FhCbJAryt4uq/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dw31GE3Ba4WS9kyy64SHMXSx8GoK2ZfkBks13tGtvjT1",
            "symbol": "FRSH",
            "name": "Fresh Token by arcavacatalab",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/arcavacatalab/FreshToken/main/fresh.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://github.com/arcavacatalab"
            }
        },
        {
            "chainId": 101,
            "address": "GaCM5eHZEQswGDj4Jf4vKZUt7P8Dxdtq3V5friXVtMDF",
            "symbol": "FxK",
            "name": "SL0W4U",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SL0W4U/FXK/main/LOGO.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4k6cUJZura395AVNuUazifQwJBdrLD1jp73u9uNuGwGy",
            "symbol": "VINY",
            "name": "Vinya",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VinyaCoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "mH4741pwqFBAyyQ656mFE2GeojFn3txoY6sYBSw4J9S",
            "symbol": "LAMRO",
            "name": "LAMRIUM EMPEROR",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FX1jtt1ebuEufXe4kV1otstnD1cz9J8rhB28U7hssWKY/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LamriumEmperor"
            }
        },
        {
            "chainId": 101,
            "address": "3JSLdQFR87YUqohrvRNiYRuQfkZ8nqPdPH9hXhDfETVw",
            "symbol": "LCD",
            "name": "LCD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMtUX7HpQBa8AwfVHiKSQRraWbCjDqx6FhCbJAryt4uq/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3qXsHMCAWU2AkSgp4Gss1gDMk4zrFctwB2fYfsHsVmxf",
            "symbol": "STAM",
            "name": "Stammizcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Optisoftab/Stamcoin/main/stammizSlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7vKX5rx57VPE1ozJesFzojdPjGZ3M89894PT27i6seUF",
            "symbol": "PAC",
            "name": "PAC Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vKX5rx57VPE1ozJesFzojdPjGZ3M89894PT27i6seUF/logo.png",
            "extensions": {
                "discord": "https://discord.gg/QTeFmcnXhg",
                "twitter": "https://twitter.com/punkyapesclub",
                "website": "https://punkyapesclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "HoRavU7C96nCEsC77sVCiQpqCPdwG2DbByjBogmkpFBH",
            "symbol": "F",
            "name": "Mr_F",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faizu22/logo/main/f1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AHUuAcaNyc3QbS4gcdJRBHv1SfHEH9p7Cgfn1bPa4bAn",
            "symbol": "OERS",
            "name": "Oersie Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bartificial/oersietoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31VRJXz11dMDBkVUeLgjtLo35xH9U6VyxdXGF5aNAEcz",
            "symbol": "TRAIN",
            "name": "Thomas The Tank Engine",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/15Teds/tomcrypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yJCDaoanobYVkxAx4igSmf2xzmwheYiKjvJNgZwWhMw",
            "symbol": "SBT",
            "name": "SonicBee Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EdgaratSB/Crypto/main/SBT.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhH92P9YL57mJzgDqdc5NcTk8K1QazVHZF5TyvKnnk6V",
            "symbol": "KTJS",
            "name": "KTJ Solana",
            "decimals": 0,
            "logoURI": "https://github.com/juustkim/Crypto/blob/main/KTJSolana.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qfSePaCqvWkYnYUsYSm1VZYKtbzKYHh7gsnKcyrgspp",
            "symbol": "ETD",
            "name": "EntropyDex",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qfSePaCqvWkYnYUsYSm1VZYKtbzKYHh7gsnKcyrgspp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Entropy:The First Decentralized Perpetual Contracts and Derivatives On Solana.",
                "twitter": "https://twitter.com/entropydex",
                "website": "https://www.entropy.loan/"
            }
        },
        {
            "chainId": 101,
            "address": "5Zg5Uxu8XfNfcPwdLv6aZRjW4aLSrYZ3SZDeweuzeS4b",
            "symbol": "PINSOL",
            "name": "PinaSolana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tamkar1982/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6VDVXBNrUb5qa7T7E2g2cXJT6WRLzvNn28JQLCGXE8xN",
            "symbol": "BUDC",
            "name": "BuddyCoinz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Davidbenji/buddycoinz/main/buddycoinz.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FgKZcD2YLx3oddaSGSH7WH5eL1Zcu4xAj95jkZwMbQHi",
            "symbol": "KITH",
            "name": "Kaus In The House",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/levinKaus/KITH/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2gbZNPdCDqbRhaHvGGha2w1bmW8TimGaFxggAeESzoKb",
            "symbol": "RCKT",
            "name": "Rocket Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gabegon8910/crypto/main/RCTK.png",
            "tags": [
                "Utility-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GKKtUp6UTubkPTd6x9EG55rN7Svg3Ys76g24reDYFcFX",
            "symbol": "ACTN",
            "name": "ACTON Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Booostup/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CFf8WsY35fHvCwrFo4gfNBL9xSSPrXM7v41oCzzXECUz",
            "symbol": "SKCN",
            "name": "SkrekkCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MatsWeckhorst/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HowJ6tCJmQ1qtcNpd76mxX8KaukxHhzWYdvcJYPmFFKr",
            "symbol": "ALCE",
            "name": "AlchemyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alchymi/alchemy_coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DU9eSs4sHjGUfxmMdQu59TpMAjvxm6Rs8HbWnzJd5KXU",
            "symbol": "DEKA",
            "name": "Dekadus",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/JayPi89/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EwzpcfgyE3UZsNbpMkKjgQWiXYDmY3LFcvc7DKjtdvWk",
            "symbol": "apMATIC",
            "name": "Wrapped MATIC (Allbridge from Polygon)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4DHywS5EjUTF5AYisPZiJbWcCV4gfpH98oKxpgyKRnnQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "zdABz6DyNFsH62xAoh1RThk2DH2mDPeEiFnmWcJTHTP",
            "symbol": "apWETH",
            "name": "Wrapped WETH (Allbridge from Polygon)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png"
        },
        {
            "chainId": 101,
            "address": "AAuuhM6a9TJ6MsoKbSmBGbqBhDQkyuNUgDkH9E9j3Xzm",
            "symbol": "apWBTC",
            "name": "Wrapped WBTC (Allbridge from Polygon)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png"
        },
        {
            "chainId": 101,
            "address": "3X2UQQkHhbW1mHtS7BQgt7hUALw8pHS334FMpuoBADPt",
            "symbol": "apDAI",
            "name": "Wrapped DAI (Allbridge from Polygon)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "7eYsZw57mTK3y63R8xv3uehPm3bKTdoyAtAqHUxVxuyP",
            "symbol": "abUNI",
            "name": "Wrapped UNI (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw/logo.png"
        },
        {
            "chainId": 101,
            "address": "DoH3AKgMCShGxzpSgHeGyU3AosnHmJbYShgjZwNeFEsm",
            "symbol": "abLINK",
            "name": "Wrapped LINK (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG/logo.png"
        },
        {
            "chainId": 101,
            "address": "4nLJaxZbHMNXPpg6BSEruyU1vh4sAbRxSkjEkmJVJDKy",
            "symbol": "abBNB",
            "name": "Wrapped BNB (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4nLJaxZbHMNXPpg6BSEruyU1vh4sAbRxSkjEkmJVJDKy/logo.png"
        },
        {
            "chainId": 101,
            "address": "29r14BEftB1RnSdq2ygVnwmEThbhmwvaaXhkmv5GvyyG",
            "symbol": "abDAI",
            "name": "Wrapped DAI (Allbridge from BSC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "E7zDjtDVWRgsdgMDcbtzTMVMkcWcyF35Do6Qmis5nvBZ",
            "symbol": "aeUNI",
            "name": "Wrapped UNI (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw/logo.png"
        },
        {
            "chainId": 101,
            "address": "GFdVCMBimnf2Dmx2D4DtPhghTkLYh6JG4u5hKB89t9n1",
            "symbol": "aeLINK",
            "name": "Wrapped LINK (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG/logo.png"
        },
        {
            "chainId": 101,
            "address": "CChDnBH5UdEPGtYfwrFf7Bdmtvc5WnSKtN77oVsPkHBK",
            "symbol": "afLINK",
            "name": "Wrapped LINK (Allbridge from Fantom)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG/logo.png"
        },
        {
            "chainId": 101,
            "address": "A96PoNcxa9LMxcF9HhKAfA1p3M1dGbubPMWf19gHAkgJ",
            "symbol": "atUST",
            "name": "Wrapped UST (Allbridge from Terra)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "C58DBQ5gmU1Tt6vtqmKWBVMzNtxmPoRWg9mM1RspDtUZ",
            "symbol": "DEX",
            "name": "Dexus Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jonathanv05/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8TTV6aVDi8nFGra1tQQUxqKHL6bMX8HrJ13uTb531mfz",
            "symbol": "VeDMe",
            "name": "Vendome Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Wkryst/vendome/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9dgMFti1ubuybtBMESawfDv87TDUX9atnwRCT3YQFkJ4",
            "symbol": "ZETC",
            "name": "ZET Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zaramihnea/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3DWn26BxigsPjUAQCkfQUjFCNQVQratAqt8Yy8MNFXeU",
            "symbol": "JIMMY",
            "name": "Jimmy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/soccrates/crypto/main/jimmytoken.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8TytJGVEyYGPCmPbtMwaGNr57gn9qz7YSH9bLf6aBsn9",
            "symbol": "BAVS",
            "name": "BayernVerseSchoasch",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8TytJGVEyYGPCmPbtMwaGNr57gn9qz7YSH9bLf6aBsn9/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DfiPmMAX8AmZqvQAVGYEireCeJBoBC66VTipGs7mfh3v",
            "symbol": "MATS",
            "name": "MatsCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MatsWeckhorst/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JCLyRg1wd9uqigLTd7fFn7wdASXfT5CiN74Ka1zMrjhn",
            "symbol": "BEER",
            "name": "BeerCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/biercoin/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Az4BeYamV7awLrTWBTVeneLt12QNtXjtW14Jsproruz",
            "symbol": "MMC",
            "name": "Michael Martial Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/miketheamazin/crypto/main/IMG_5943.JPG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fv7msaPiJEaxym7LkY8PcJis6DPLwrbVTrCsmXKT3VG1",
            "symbol": "NKT",
            "name": "Nik Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nik110403/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6JEK47yGkULH4ezouZ8X4X4qEiyd3QZn7emaQhAXQuam",
            "symbol": "LEON",
            "name": "Chemeleon Worlds",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JEK47yGkULH4ezouZ8X4X4qEiyd3QZn7emaQhAXQuam/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/chemeleonworlds",
                "website": "https://chemeleon.world"
            }
        },
        {
            "chainId": 101,
            "address": "7g166TuBmnoHKvS2PEkZx6kREZtbfjUxCHGWjCqoDXZv",
            "symbol": "acEUR",
            "name": "Wrapped CEUR (Allbridge from Celo)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7g166TuBmnoHKvS2PEkZx6kREZtbfjUxCHGWjCqoDXZv/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "6wKJad8njToVD94SWYEsFUCT7bYYMAq69whJgznk7gax",
            "symbol": "SMRT",
            "name": "GeniusCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eugeniusss/crypto/main/brain.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Goe49dFiS8HYQj2epcwo7riCecbCTcU18Ws1ekcXpDYE",
            "symbol": "KUNT",
            "name": "Kunt Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dlopez079/kuntkoin/master/KuntKoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GvtLA6L6kX1H6dmwkr5GUojJKMtiL6nS9MP4ZSJe8JCT",
            "symbol": "SHBC",
            "name": "Shadowball Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haircutfish/crypto/main/smallerhat.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "83VBJQUUJt7VnsBTGYYdJvup6aJxz9ztGYoBEiwPYKbg",
            "symbol": "ROSIE",
            "name": "Rosie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Firebros1/crypto/main/Rosie-Cup-jpg-3-png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DPjQQZvc3ApL1ZzD4WcJnXV6NvuDQDgj6E6WW58cHWMf",
            "symbol": "THOR",
            "name": "Thor Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ajeema/thorcrypto/main/thor-token-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "74iB18CXkCtNwSn97L4amTYvpTrULj4Rmfeee2BXo8YZ",
            "symbol": "DIT",
            "name": "Dit Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ditsoftware/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cgo9SLxUJv4xLniwjoAtpWpCvesruHHaMosFkLfGQZ2e",
            "symbol": "WSH",
            "name": "Windshield Heroes Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kodepharm/wshcrypto/main/600_600px1new-01.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Av6n2pfJDAXfuU28226omzxD5yx3PpzqU5UjGYrXUy7b",
            "symbol": "ZOSIA",
            "name": "Zosia Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pavelosky/Zosia-Coin/main/zosia.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9vZ17jrKfMZj5pH37232mCR3t45EZiVHfauoAECueVWN",
            "symbol": "GZZC",
            "name": "Grizz Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LeonSatyr/crypto/main/Bear.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "86UGuBBNSy6QDgYggDVLixCEjrwHAShRu8Vu3Lno7FiP",
            "symbol": "LAMRO",
            "name": "LAMRIUM",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/86UGuBBNSy6QDgYggDVLixCEjrwHAShRu8Vu3Lno7FiP/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LamriumEmperor"
            }
        },
        {
            "chainId": 101,
            "address": "BwmQtUizFn5aoTidsAF5afq4Ty4UfWrXK3xxeXZMo6HF",
            "symbol": "MAXX",
            "name": "Maxx Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JervanSahd/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BcfM9pAiHx7si3rMGun2iHj3SiwCGv3BJA9EcpJKGgSd",
            "symbol": "FINN",
            "name": "Finn Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tsaunya/assets/main/finn.png",
            "tags": [
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://martens.wtf/"
            }
        },
        {
            "chainId": 101,
            "address": "AqnujVzrSrKEEpAxS6hPArZb24vMzrZ7ZsKmdL2rUQE5",
            "symbol": "GLIDO",
            "name": "Glider Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tryfon2l/ttcoin/main/glido.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cnf2SSLgWcQoKiULA4HjK9oD9ahFJzXg7fUdkuYFtDjn",
            "symbol": "VGVT",
            "name": "VigilantVeterans",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Vigilant6/VigilantVeteransToken/main/Token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ZF5irYUyh91Ugv5fzWZUaCGk7bN9DADKnHp8gtV52Nh",
            "symbol": "SOAT",
            "name": "Simple Online Accountability Token ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/White-Lycan/FIRM/main/download.png",
            "tags": [
                "social-token",
                "accountability",
                "better-internet"
            ]
        },
        {
            "chainId": 101,
            "address": "9tghX1jRyVxXRtVkYi4sqtxVt1UFDr7YNdZA5wM8R436",
            "symbol": "CONB",
            "name": "Consbeeracy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/800BOT/crypto/main/Capture.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6bdNd17gnxxgWRii3r573CBVMHJSG99TkJLcvPpg1x5N",
            "symbol": "MDP",
            "name": "MDP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/userDragos/MDP-crypto/main/MDPlogo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7VszmJBkHNLHEXkgrirRkxaC9w3A7RzgpYfKXDJn9h2h",
            "symbol": "DTLS",
            "name": "Datalys",
            "decimals": 0,
            "logoURI": "https://github.com/Datalys/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AsK8hmT5Rcr8iE7v5LnttjPXAjcjawM7qLJgMfruEpeg",
            "symbol": "TM",
            "name": "TechnoMonkee Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Vinpan1/Vinpan1/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ydfd9f9tqEKwGky4DUbKkAZkubwdzdWKYF245ZGZ3mC",
            "symbol": "PLK",
            "name": "Planktonium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/planktonio/planktonium/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9gA4uYdw33yEJZQ4q53X3scVzFY5T4Ub6SjCzMWo5P6L",
            "symbol": "WTFP",
            "name": "WTFP Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bearmun/WTFPCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5UZZVVfhVexzd7ULCarsmTQ5PQCSBdMBtzXfeham6DPy",
            "symbol": "YAKS",
            "name": "YakBux",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ruleryak/YakBux/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4VA2gtQ48gKexxLBabjeXQQKm9YScERD847RTz8R8pui",
            "symbol": "PLD",
            "name": "PALLADIUM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4VA2gtQ48gKexxLBabjeXQQKm9YScERD847RTz8R8pui/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "66LZ18mVs12MPQCnpQDZ4g7d8odACJEtKzcyS8eubYHK",
            "symbol": "BBB",
            "name": "Bitcoin Blockchain and Beyond",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MaceoCalico/BBB/main/BBB3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5j9T7GNXgHJUPLqnZ4TtRQy3AqW4Jcp4X8G2TXUfJ2QQ",
            "symbol": "RXDG",
            "name": "Rexollar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vladdu/rexollar/main/rexollar.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ansGKK89WQJobpBQjTzJKUr5cjfQXXmzj81ipLinix5",
            "symbol": "IMP",
            "name": "Império Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/idrinkpoison/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2mxAyT6KcaWzS2a3gJjE9zZL6VyXWY8Ut1PUtsF5Kp7e",
            "symbol": "POS",
            "name": "PabloOspinoSanchez",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ospinooo/solana-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C2wx351cbs8G7G3Xkg1Q8EwKJwTN1rP14ojs3ak1Y8uT",
            "symbol": "TAKE",
            "name": "TakeTheSound Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/isaac1972/takethesoundCrypto/main/logo.png",
            "tags": [
                "music-token",
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7uS8FP88CTrrtwNpzwcJsH7BfSGorn4L9wsfrb1knzKo",
            "symbol": "SKIDDY",
            "name": "SKIDDY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jaxeaxe/a/main/SKIDDY.png",
            "tags": [
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolBoxers",
                "website": "https://solboxers.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "2iZmEjsrdJnptKUxPe2xRv4WQzpNDrmdWJcqPMysBhdA",
            "symbol": "MLY",
            "name": "Masonly",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2iZmEjsrdJnptKUxPe2xRv4WQzpNDrmdWJcqPMysBhdA/logo.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "52nJbAzwCyyZUc7csLfa26sy4wdJGtSMh8rmfBKc2Zw8",
            "symbol": "PTBA",
            "name": "Pitbull Advanced Token ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/White-Lycan/FIRM/main/download.png",
            "tags": [
                "social-token",
                "accountability",
                "better-internet"
            ]
        },
        {
            "chainId": 101,
            "address": "6vmmDgU9YNCSUsnEpYekPW8GnG4zFYKH9g8KRQTnSdzo",
            "symbol": "BUL",
            "name": "BulBul",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xTaromarux/crypto/main/coin-image.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F3qjYusDAa4C67GYsfdBFzanGMykYydq2nMfn6E2D1VZ",
            "symbol": "MOMMY",
            "name": "Matt Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F3qjYusDAa4C67GYsfdBFzanGMykYydq2nMfn6E2D1VZ/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FZ4nBw9dhKRDfUGFoDN4fWpJ6Xm9B6xxBBAVUrmdYTVM",
            "symbol": "ERRD",
            "name": "404",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/3Banan3/404-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Jgat3yNtqvgeNqoeXud5nwXYtEV6XU8gTXEn56cd36V",
            "symbol": "LLEM",
            "name": "LaskinLockery Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/plaskin/CryptoLLEM/master/logo_1124.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7tz1ShZD1UbCojUcD4QnqFbwTp7GLNEWNnrXZf85hLDW",
            "symbol": "GGT",
            "name": "GonisGoldenTicket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GoniNataraja/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EURHdYFwnvZRKvggooS8Wgmp34L5L7T16kmN5971da71",
            "symbol": "GBYQ",
            "name": "GoobyQuest",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GoobyFRS/GoobyQuest-SolCoin/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "GoobyQuest - Matts Mini-Crypto Project",
                "website": "https://mattfaulkner.net/"
            }
        },
        {
            "chainId": 101,
            "address": "5oYsiw4YvejuKoUDhbR78ZSm6L7dD6UVcV4jER1RDhVs",
            "symbol": "TACO",
            "name": "Space Tacos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/withoutink/crypto-spacetacos/main/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Decentralized Tacos from space; In Tacos We Trust.",
                "website": "https://withoutink.com"
            }
        },
        {
            "chainId": 101,
            "address": "94jcj6rXADoQhMqDSmzBXFeWw94rTBqAcmwepCGRNaZ3",
            "symbol": "PSY",
            "name": "psychocoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Psychotic3440/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DQEQAysEtidrqTgqokbjymsLSSdxcRCiN4T6PN1CLY38",
            "symbol": "DDC",
            "name": "Droid Dude Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ArberKadriu/CryptoCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2LYZnwLkqzQGxVU6Ar7PquzAHF9cpQnFX2XsSgVqjXLY",
            "symbol": "GALX",
            "name": "Galaxi Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GalaxiToken/galaxi-token/main/logo.png",
            "tags": [
                "utility-token",
                "galaxi-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DZtptXkwMARkRJzwxcc41vcgrYfi1qiTZVm4584Zh7TD",
            "symbol": "ELLA",
            "name": "Ella Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/withoutink/crypto-ellacoin/main/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Ella Coin was minted to honor those that are fabulous.",
                "website": "https://withoutink.com"
            }
        },
        {
            "chainId": 101,
            "address": "E7nDqNmfu1uX6TBdyN8QQE9xkZAocnQTSSsqauvTUWxb",
            "symbol": "BNZ",
            "name": "BONES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sm0keytab00/bones/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "42jcPenNUYhNftUd9WdBtzjxpU7QckkYG8togt7d8YSB",
            "symbol": "CHOCG",
            "name": "Chocolate Gerbil",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dpartin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "77hLq2qDJyY4o4o7QybSPjhRyjoZkVDNpcNwDBCus1fu",
            "symbol": "LJDL",
            "name": "Liljedahlar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/devhyperlight/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9zajRKBP94JTnRhkiZ5eRjQiwGFsmF5MfetaGrPC2Ziz",
            "symbol": "Char",
            "name": "Charizard Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jacoding1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2JZNgjctcgACs7vMFLuB7Jj5B5rZnYFtApPtLPmLD82D",
            "symbol": "MUNIS",
            "name": "Munis",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/munistoken/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AihamqQrpdxqHscjAa8nPVXSyNtwzU2ghNXAAX1AptZw",
            "symbol": "AIHAM",
            "name": "Aiham Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AihamqQrpdxqHscjAa8nPVXSyNtwzU2ghNXAAX1AptZw/logo.png"
        },
        {
            "chainId": 101,
            "address": "BDJwEaEmVHzrWmRsY1A9jB5EqniuQgCFUyJwpA1i7VWr",
            "symbol": "MDVCN",
            "name": "Mandeville Connection",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Withatee/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7x6BiVDmP5yHBuPrZpZCZwgKJi91k7QQpvfP577ZbdFR",
            "symbol": "GDLN",
            "name": "Golden Lion",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/don0t/Goldenlion/main/lionhead-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4W64SgCQamoV2RVPTa1XNnT3nBXWr1JMWwfetLY1j6Pr",
            "symbol": "YEET",
            "name": "YEET Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AtticusCincinnatus/Crypto/main/yeetshaq.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FgLzsaSUKoZzBC4v9oATs9TyATaBKK4ses9gzD8ChRoB",
            "symbol": "BLAB",
            "name": "Barry Labs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BarryLabs/BarryLabs-Library/main/Placehodler.jpg",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6cQhcJ19jiVYb433Jia9cYR9k3YnMcwxUL5jNBjcDCTh",
            "symbol": "JBR",
            "name": "JBRNET",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jbonanza/logo.png/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5znaTXtBXAD81QqFYKzFR2XvwC7ThCszGbNbQSZPPcCi",
            "symbol": "TGNPRO",
            "name": "TGN Photography Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tgnphotography/TGNphotographycoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5SNC5EBD1xrQxxh9LsksKymbnXsAH7p8htF8CMyh93f",
            "symbol": "TUIT",
            "name": "Round TUIT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/withoutink/crypto-tuit/main/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "For anyone that gets around to it.",
                "website": "https://withoutink.com"
            }
        },
        {
            "chainId": 101,
            "address": "94LtmZUdKfJWJd9Q2eXhWVpdA92WTteoPJ6arw5HWFjf",
            "symbol": "HTOKEN",
            "name": "HTOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94LtmZUdKfJWJd9Q2eXhWVpdA92WTteoPJ6arw5HWFjf/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CiRMmmN5ytJk2BcVGsKiYwCFCePPRc1AM6KATYoNbA3T",
            "symbol": "MITH",
            "name": "MithCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brwest85/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AHVxRdfRec4bYbCDTiUnGn5tv8mzrFqmTcAZV6eoQGFx",
            "symbol": "ZFC",
            "name": "Zaff Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZaffyPuck/crypto/main/ZF_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Dunt9WfALctX9qAmsDPEzNjCj8wFfUfQsjsnFtajrSE",
            "symbol": "BHCA",
            "name": "Bohica Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gmitch46/crypto/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4BtMhZtx6275kEtR5xPYenhUCURj81Z3tpmvw4ecLBCq",
            "symbol": "SCTE",
            "name": "Scottie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DasJoules/ScottieCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FQ8sWTnYydqkGYd4yA4aopSVkDqsNyTPnSj5gK73nJT8",
            "symbol": "SPND",
            "name": "SPend Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sneh01/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EfiR9rM6QBg8iVh8WHHXnwD8XYviWpnaTZM8txzcwSFX",
            "symbol": "TAQA",
            "name": "Taqa Coin",
            "decimals": 0,
            "logoURI": "https://github.com/kawanda/crypto/blob/main/TaqaLogo-03.png",
            "tags": [
                "Energy",
                "social-token"
            ],
            "extensions": {
                "description": "The aim of this project is to drive the development in the Arabian Empty Quarter Desert and the North Africa Sahara Desert, using the latest technology in Photovoltaic Solar Panels and Energy storage and distribution.",
                "website": "https://www.taqacoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "De7MqeCzwbPQxGfGCChgKoHK7dtzH4Hzp4Gdh5qyF2os",
            "symbol": "DCANE",
            "name": "Don Carletto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/T0ny-Montana/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FkBRohZpqx2c7zxe5cDhNq3AoFo2nPJus3xtdz9CvQmQ",
            "symbol": "$MINERAL",
            "name": "Mineral Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FkBRohZpqx2c7zxe5cDhNq3AoFo2nPJus3xtdz9CvQmQ/Mineral.png",
            "tags": [
                "Energy",
                "social-token"
            ],
            "extensions": {
                "website": "https://afloat.eco"
            }
        },
        {
            "chainId": 101,
            "address": "smbdJcLBrtKPhjrWCpDv5ABdJwz2vYo3mm6ojmePL3t",
            "symbol": "SMBD",
            "name": "Solana Monkey Business Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/smbdJcLBrtKPhjrWCpDv5ABdJwz2vYo3mm6ojmePL3t/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "6KtErANtAw4oXGhF1LJhKk2f8xomCwo4bwLcntZYJBVt",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "G2mgq8ve2b6uVvdHtty2KtkDT67ztX7RH9e2Nhctumca",
            "symbol": "DICKS",
            "name": "NFT COCKS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Prime3vil/NFT-Cocks-Token/main/logo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "43cSR1rmsQGyAebfhYoLyuNBGVtRE2BKaYerBcDYzz4z",
            "symbol": "BJ",
            "name": "BJ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/soccrates/crypto/main/bj.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EkbaCuCgwwt19zrL5LKupCy3XaoFBFbB9zuRrWEXhR6N",
            "symbol": "DUK",
            "name": "DooksTesting",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EkbaCuCgwwt19zrL5LKupCy3XaoFBFbB9zuRrWEXhR6N/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "The goal is simple, to spread love. How we spread it.. well that's where our community comes in.",
                "website": "https://sighducks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9Wyt5txGT5Npd5McH1wTVRBeZiWXeQfrzqHCnMprRWD",
            "symbol": "SHARKY",
            "name": "SharkyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SharkyCoin/Sharky/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7r9iDa6YMkxPsz5BXSKQo2zGiGoGZbLGWUL84AUwBQMd",
            "symbol": "HZI",
            "name": "Heinzi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MrWaffelXD/heinzi/main/Heinzi.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/in/jonas-heinze-347879195/",
                "website": "https://heinzi.jonas-heinze.de"
            }
        },
        {
            "chainId": 101,
            "address": "793ApJzBMCdQrTiRS5eL3GokVzUr9U3YRLp62CrRJaCJ",
            "symbol": "DSL",
            "name": "DeSol Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/793ApJzBMCdQrTiRS5eL3GokVzUr9U3YRLp62CrRJaCJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "JAYDueSTMuhLYZEHZVXJrbSaYtgMfg78zGMpeiPRhxXA",
            "symbol": "ELXR",
            "name": "Elixir",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoMoon8/token-list/main/assets/mainnet/JAYDueSTMuhLYZEHZVXJrbSaYtgMfg78zGMpeiPRhxXA/symbol.png",
            "tags": [
                "general-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ty9oaBfP2LU5BwaHZng1oVCtmLwvn4qWu6RM25mAkcX",
            "symbol": "CAST",
            "name": "Caviar Store Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rubensilva09/cscoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4GEcMq4yZM3YvYNJSwkfhtwCZrEt8gtrJKQ3nCGLFD5z",
            "symbol": "SLOTH",
            "name": "Sloth Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4GEcMq4yZM3YvYNJSwkfhtwCZrEt8gtrJKQ3nCGLFD5z/logo.png",
            "tags": [
                "social-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7PWvDdwAieKUSfaDVhF8VD2RyLNk1Na12XoxN7Semegk",
            "symbol": "TERM",
            "name": "Termoenergetica Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mihnea2kx/crypto-termo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5hhA9PEToRcQtvSvFuvSQC15kkxHJoqG2yF2SSCs4znM",
            "symbol": "LITZ",
            "name": "Litz Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "59vMocrR3QWa6cLwQBiiYhFdHfDFRodLuUkYkNyyhBJ4",
            "symbol": "INRC",
            "name": "INR Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gsingh72/coin/main/inr-coin-inrc-logo.png",
            "tags": [
                "social-token",
                "general-token",
                "stable-coin",
                "stablecoin"
            ],
            "extensions": {
                "description": "Indian Rupee Coin, Stable Coin"
            }
        },
        {
            "chainId": 101,
            "address": "5vUBtmmHjSfpY1h24XhzEjRKjDyK5jNL9gT2BfM3wcnb",
            "symbol": "gFIDA",
            "name": "Bonfida Governance Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5vUBtmmHjSfpY1h24XhzEjRKjDyK5jNL9gT2BfM3wcnb/logo.svg",
            "tags": [
                "governance-token"
            ],
            "extensions": {
                "website": "https://bonfida.org/"
            }
        },
        {
            "chainId": 101,
            "address": "6wzzefNX8BhyQumboz4y12X1Ys3RwpLdsc7tkvDzMaNk",
            "symbol": "QUOKKA",
            "name": "Quokka Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wzzefNX8BhyQumboz4y12X1Ys3RwpLdsc7tkvDzMaNk/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/snft2022",
                "website": "https://solana-nft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D9VLqjZqjy5MNPSzSMnoFmUEjmTHmPEsvb3cfzAppiHU",
            "symbol": "GHFT",
            "name": "Gherghina Family Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mihnea2kx/crypto-family/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2GuQGvfvS7rNiYpysU4iDHbB9nF4aSjJ5c6TL3ALEgjv",
            "symbol": "MAP",
            "name": "Thirdmap",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanadevv/token-list/157bf21ad4a5ecdc6103f139c21490060225fd1a/assets/mainnet/2GuQGvfvS7rNiYpysU4iDHbB9nF4aSjJ5c6TL3ALEgjv/logo.svg",
            "tags": [
                "utility-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "CtGhNVXnG28qMAHHRbU5KcCPRmN2DeGKAtgb5e8spPk2",
            "symbol": "FOCK",
            "name": "Fockn Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CtGhNVXnG28qMAHHRbU5KcCPRmN2DeGKAtgb5e8spPk2/logo.png",
            "tags": [
                "social-token",
                "FOCK"
            ]
        },
        {
            "chainId": 101,
            "address": "GUAXo4yYqY335t9esybM4wDPcDeAc7m2mW2xQ2svXzy1",
            "symbol": "SOUTHPARK",
            "name": "South Park Memes",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUAXo4yYqY335t9esybM4wDPcDeAc7m2mW2xQ2svXzy1/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "southpark"
            ],
            "extensions": {
                "twitter": "https://twitter.com/southparknfts",
                "website": "https://www.southpark.space"
            }
        },
        {
            "chainId": 101,
            "address": "GCPHeGhYawX3hUEx2Zxezp3QyLvDCXuC1RuQJYtjh2bp",
            "symbol": "DUNK",
            "name": "SolJordan",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GCPHeGhYawX3hUEx2Zxezp3QyLvDCXuC1RuQJYtjh2bp/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolJordanLabs"
            }
        },
        {
            "chainId": 101,
            "address": "2aUguEhEae4SMTLKBYtf3GLzoSQaASwVnZMgE1vC4cPZ",
            "symbol": "FBC",
            "name": "Freebies Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pozitivamatt/crypto/main/cropped-freebiesfavico-NEW-192x192.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "29m33R3FKwdFTLTQUneh8GM2KQCpg3wYViTBwZ7ssJ7Z",
            "symbol": "SYD",
            "name": "Sydney",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ostersepp/coins/main/sydney.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "E2KVvQKqnymv6dpbML59TDQEuy7GbDKCagpGQuCDYNoJ",
            "symbol": "JJC",
            "name": "JJC",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E2KVvQKqnymv6dpbML59TDQEuy7GbDKCagpGQuCDYNoJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "HAErKxnzi2KvbpgDZ4m7D7Drv7nu1kNRtMSLEUSJLVfX",
            "symbol": "BELO",
            "name": "Beloane",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/beloane/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9niH1tb31znADrhJJTUVBjxN9sf43d2C9W6pMCoxWyat",
            "symbol": "JHX",
            "name": "Jimmy Hendrix",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/humanpong/avatars/master/jhx.jpeg",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AuJzg9V1XwCjFQvBFVzt1rtpxbCf766KSa4YyfHkBHz7",
            "symbol": "CVC",
            "name": "CoViD Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AdrianOverdijk/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EvB2m5VFGoAsVHdCwif1nKiuUwHt8zWdrrtWtJAYxpBt",
            "symbol": "NENE",
            "name": "Nene Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MatsWeckhorst/crypto/main/nenelogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RACE5fnTKB9obGtCusArTQ6hhdNXAtf3HarvJM17rxJ",
            "symbol": "RACE",
            "name": "RAce Game",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/NutsPokerTeam/token-list/main/assets/mainnet/RACE5fnTKB9obGtCusArTQ6hhdNXAtf3HarvJM17rxJ/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.racepoker.app/"
            }
        },
        {
            "chainId": 101,
            "address": "F3DDbJQHiDkmgUt33thXPRPp8NmQ3g2AuaqPiiuRGLjz",
            "symbol": "DG",
            "name": "DG Coin",
            "decimals": 9,
            "logoURI": "https://github.dev/dangilroy/token-list/blob/ec38aedc5e0c587f7b532717df492f2d1207c77b/assets/mainnet/F3DDbJQHiDkmgUt33thXPRPp8NmQ3g2AuaqPiiuRGLjz/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4a7GrANFXmUmheGpQAMcwaZnHr39zEwc6joUYHgDpPcF",
            "symbol": "VIRGO",
            "name": "Virgo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/virgo-coin/virgo-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FskUXF4aK3irwhueuv4psgqJE7PaZ3cM3gZb4rjTYPW8",
            "symbol": "SSND",
            "name": "SSND Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TacoNite99/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4pnTkobcmPYTZatmtmTJq3WoqNb3EfR8r8PJLnZYQctU",
            "symbol": "POO",
            "name": "Chihuahua Poo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/techroomjohn/chipoo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FB5aw2j2qPZQiVwZgXFMjZFQrFREpHfy1mXcLGLyHXTk",
            "symbol": "LRFT",
            "name": "LRFTech Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lrftech/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "i3WkGyyxhsorMmpxRbe7Z4c1GGHyXWJN6u3RC9YahHL",
            "symbol": "FLDY",
            "name": "Floody Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Glitchm/floodytoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BhD4xWQEyMCmKr88vGHjHoUwwo985QGkwWiQm22Uek9S",
            "symbol": "SHRTB",
            "name": "Schrute Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haircutfish/crypto/main/schrutebucks.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "79AHd9CjwuWYfMXJ4W6SHAVN4gehmHey4VhA1JQJAR8y",
            "symbol": "VALY",
            "name": "Valley View Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ds2600/crypto_valy/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5J1p27QBPS6pQK8mijYv7HfhhvZnCce72xEUMw2Zm7Jv",
            "symbol": "AXSORG",
            "name": "Axie Scholar Dot Org",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/geovanigoncalves/goncalves/master/Untitled.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8qNxN7oC2W1Bxq37Bk4uq2ryCLix8jeWty9ZdCMwUAvk",
            "symbol": "2KX",
            "name": "2kx Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mihnea2kx/crypto-2kx/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4fqGB44HMXtPbxKebXu2PbR2MoSv2f91fqEBkEHG4i5p",
            "symbol": "LORMU",
            "name": "LoreMure",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/loremure/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G6giteuUaL63u6jaBr8MNfNvtzUdyud16eUT68sce6AA",
            "symbol": "BVC",
            "name": "BeaverCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bobbybeaver/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DCp9Mef5CAbRTuSB4kf1wzemk87dRsdkjhypLBoQmJCK",
            "symbol": "SLB",
            "name": "SALIBACOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Krissal1234/Crypto/main/received_358990772277187_217x218.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DJQBoQPPXC2SMvF1t9Kb2YmFEEha7PBSQDgU2LwCRSz3",
            "symbol": "SOST",
            "name": "Solstallion Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/Osawejustice/solstallions@main/solstallion%20logo.jpeg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xRKGExcdJu",
                "telegram": "https://t.me/solstallion",
                "twitter": "https://twitter.com/solstallion",
                "website": "https://solstallion.com"
            }
        },
        {
            "chainId": 101,
            "address": "BUKPWSqsKcbQLwGBPLpihKTy56nqWJEetDAJMtk6tRzf",
            "symbol": "DEX",
            "name": "DEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sciencemonk/DEX/main/DEX.png",
            "tags": [
                "utility-token",
                "education-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DbnTECYvQm1ZKssruNYLQLs8qRu4rDvMmvQHoemznkmW",
            "symbol": "RKRK",
            "name": "RaivoKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/raivolalala/raivocoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkmdmfVCP8bvAYXGRz8ENjPM2aPkVE51CvBjne1qvxyk",
            "symbol": "BTZR",
            "name": "BitZera",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/grglzrv/bitzera/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "BitZera - Decentralized Cryptocurrency Token going to be used in the new generation of internet platforms.",
                "discord": "https://discord.gg/bWbCnQfvEW",
                "website": "https://bitzera.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7gGWV8z5sowGG8RvZvc4bbSDz1jnx5AucUkpueVLhsqx",
            "symbol": "LEKE",
            "name": "Lefche Kefche",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/paranoya1/chunch_coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FrREY3stxwMyuqWtS8c7u3j2n2353EVQW5rp1hRnycUc",
            "symbol": "ERRT",
            "name": "404 ET",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/3Banan3/tokenas/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8JjBJdV73zPPmZvkgC91ni8RsbXWTkhpuSdxeZgaw6hD",
            "symbol": "BABYTIGER",
            "name": "Baby Tiger",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8JjBJdV73zPPmZvkgC91ni8RsbXWTkhpuSdxeZgaw6hD/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "babytiger"
            ],
            "extensions": {
                "coingeckoId": "babytigergold",
                "twitter": "https://twitter.com/babytigergold",
                "website": "https://www.babytiger.gold"
            }
        },
        {
            "chainId": 101,
            "address": "FCuFJFTx6cvuK9CV2X8UciVyR7KP5HWBDuwB4WErWrAX",
            "symbol": "PXR",
            "name": "Precious",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCuFJFTx6cvuK9CV2X8UciVyR7KP5HWBDuwB4WErWrAX/logo.png",
            "tags": [
                "nft-token",
                "Precious"
            ]
        },
        {
            "chainId": 101,
            "address": "2eCU7FUA6q5UZTPvCLuw9bLq5T9vy5QJYaxSS9u3AABs",
            "symbol": "Lily",
            "name": "LilyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VladSabra/Crypto/main/lily22-min%20(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9uvPWLQuNZtJXbDu877YG3ZXZrgUdhREh2CJk8cWk1Bj",
            "symbol": "DUAL",
            "name": "Dual Origin Coin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9uvPWLQuNZtJXbDu877YG3ZXZrgUdhREh2CJk8cWk1Bj/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4BoeDsCbJ9jwoukFtczsXzfevXWZ58RiwXL2ykvVrLvy",
            "symbol": "BAMBOO",
            "name": "BAMBOO",
            "decimals": 9,
            "logoURI": "https://cdn.discordapp.com/attachments/507517222772539392/921458551334010960/2cbf72b5a5509d79f2945157f63f743b_1.jpg"
        },
        {
            "chainId": 101,
            "address": "EiJb6S5zo9a5Gfbu5PhteHCCxPRUMG7jVt5NiRdVfd1x",
            "symbol": "UES",
            "name": "Unreal Bridge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/UnrealBridge/Unreal-Bridge/main/logo.png",
            "tags": [
                "nft-token",
                "game-token",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "BtK64nGUZxxpeR2HcV51VrUW3LW9KhogfozLzS68HdYL",
            "symbol": "MSTK",
            "name": "msris108-token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/msris108/msris108.github.io/master/img/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "2doxC6be1paV35FDHwwHhSKgmghW4EqRzRbGLaZvuj85",
            "symbol": "O_N_I_O_N",
            "name": "ONIONTOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2doxC6be1paV35FDHwwHhSKgmghW4EqRzRbGLaZvuj85/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "e7khjBdGmTPNX6wZ4FjoCW8fzmyTGuX4CRgCuinwwTn",
            "symbol": "SKY",
            "name": "Sky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicholhai/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5n7qf5U9FFdirZzSYQqZh9L1YS91hESKSB4fJXj8XEu3",
            "symbol": "Raymond",
            "name": "OMR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/obimark/crypto/main/image.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GRN4AeoF6jut2ofGpgx8AMdPEuSAZDSXiftV8DULnMRK",
            "symbol": "MED",
            "name": "Medusa token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Qalander/medusatoken/main/red.png",
            "tags": [
                "NFT-token",
                "community-token",
                "Game"
            ],
            "extensions": {
                "description": "Medusa token - Decentralized Cryptocurrency Token going to be used along with Medusa, Perseus NFT's and blockchain game.",
                "discord": "https://discord.gg/bWbCnQfvEW",
                "telegram": "https://t.me/project_medusa_NFT",
                "twitter": "https://twitter.com/medusa_token",
                "website": "https://www.projectmedusa.art/"
            }
        },
        {
            "chainId": 101,
            "address": "3G2oiYqzx6r3SAY8zdioQ6v21HvrMJtwGoQ7SywS8AVY",
            "symbol": "PIXIE",
            "name": "PIXIEDUST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3G2oiYqzx6r3SAY8zdioQ6v21HvrMJtwGoQ7SywS8AVY/logo.png",
            "extensions": {
                "discord": "https://discord.gg/y8JCHKSxaQ",
                "twitter": "https://twitter.com/PixieDustToken",
                "website": "https://pixiedust.network"
            }
        },
        {
            "chainId": 101,
            "address": "N1CfKy2UEQmvQeQgELMXuHX9KRGQa6ayTrYUSqsL7TG",
            "symbol": "LMAO",
            "name": "Solana Alien Business",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Chiwax/assets/main/logo.png",
            "tags": [
                "NFT",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/HWVM99HhNb",
                "twitter": "https://twitter.com/SolanaAB"
            }
        },
        {
            "chainId": 101,
            "address": "JE1TvFnYcyCFqMDqCokDVX1mtyD9oeB8KGB68EEm3mG2",
            "symbol": "R1SK",
            "name": "R1skCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/i0r0me/crypto/main/logo.png",
            "tags": [
                "Multipurpose-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6YYzxoYNjjCPUyH6tb2tZJvMP3a2BWMrnocpeDDXS2Qu",
            "symbol": "DOJE",
            "name": "DeeOOgee",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/deeoogee/crypto/main/cryptologo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cb7Wf27erQQAyFcE33cQ3pgFtAqeXAiNe75Tev8mckY7",
            "symbol": "NIKO",
            "name": "NICOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iTzNikolovich/crypto/main/NICOIN-FINAL.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3VNoGbvXWXMWjdwM2zTNv731dx2pdxvRWRE9gEDWKWW9",
            "symbol": "KFC",
            "name": "Kentucky Fried Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/borisgrigorov/kfc/master/kfc.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D8f4ioijfrxec3exkJvt6GBZJmHcT38TVDamwzzCjEsx",
            "symbol": "MSPIR",
            "name": "MSProgramming",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/msprogramming/mspcrypto/cf2699102283d2b7fce30acaf044be61ca6fac59/001Logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3dsqqQcFmW4YUR6C7MQmy25czHNebQt91PWhk2NkDDg3",
            "symbol": "LST",
            "name": "LUSTKOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/itnowpb/lust/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3xDhNZt1aTEuwgeLrxmcqbkZBNyDgXoWVzJqUFVtcP2S",
            "symbol": "RND",
            "name": "Random Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dandanmygithub/crypto/main/random.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B1Yqn992SNem2yeEiSKsnWELCq3J8JcprZyf17E3Yfp2",
            "symbol": "DMAC",
            "name": "DillyMacko",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dmac500/Crypto-/main/pic.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EP6M2Wt6NNTDHrSS1mQAjvauWFBZGRuaQ3H65nxrv7x5",
            "symbol": "JEFF",
            "name": "Jeff Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thevaliantviking/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7T5yrjKEwi1zot4iu4bqzB5wKArHbZJDU99nWBh5T9hN",
            "symbol": "B1SH",
            "name": "B1tch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Fnineone/crypto/main/logo.png",
            "tags": [
                "social-token",
                "fantasy-football"
            ]
        },
        {
            "chainId": 103,
            "address": "7o9SZBfN4Pka4Kbff6ndyr4fWEQ7vktRs7tiZFMG9KTE",
            "symbol": "BLLB",
            "name": "Bilal Bayrak Test Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7o9SZBfN4Pka4Kbff6ndyr4fWEQ7vktRs7tiZFMG9KTE/logo.png",
            "tags": [
                "test-token"
            ],
            "extensions": {
                "website": "https://bilalbayrak.com"
            }
        },
        {
            "chainId": 101,
            "address": "8QdWAqc2pQwJPP4EvdJGUv7fUCZEYn5JvF24NcpRAR8H",
            "symbol": "VOS",
            "name": "VossenCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CubeQuence/solana/main/vos.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dv7kpiZDrdecYuhsu2oJpBTzxPBmu2w8wmBwwhKP6svD",
            "symbol": "BUTZI",
            "name": "Butzi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dv7kpiZDrdecYuhsu2oJpBTzxPBmu2w8wmBwwhKP6svD/logo.jpg",
            "tags": [
                "social-token",
                "BUTZI"
            ]
        },
        {
            "chainId": 101,
            "address": "ApToJQDQ9awk5jaUhJd6ZUQ8f5mbwVtkhmHSoJojkpxc",
            "symbol": "PAC",
            "name": "Pingüino Anarquista Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/martinvox/mvox-solana-token/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HtwpV19fNwDLzXCA4kbQzmoeVrHdJauw4yt6zAxptnuB",
            "symbol": "KETO",
            "name": "KetoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/K5rlx/Crypto-Keto-Coin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GhkR4r9KknG1VG6pQQckQ1HwgnjmnZHdZwY4kHcxyVwg",
            "symbol": "BEP",
            "name": "BEP Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BlakePerrin13/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7o1F8Rubumyz9hghzLWuLtmJVTHPL5nTPj3n51QzeTFL",
            "symbol": "EMAIL",
            "name": "Email 5",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7o1F8Rubumyz9hghzLWuLtmJVTHPL5nTPj3n51QzeTFL/logo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AUL7w7AwMEWRemqUP2Z14aEkPPAT8NDpnRoAPCUS2k5Q",
            "symbol": "IOU",
            "name": "IOweYou",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xMaxix/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7H5nr8n3xPbgUYXzx1ykZJoyppVcV4E4KN8mCaCrfvSd",
            "symbol": "SWA",
            "name": "Swami",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Glitch3086/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CeVHjx6EmDSGyet2TrRL3Wo4iSah2aQcstSmeoLLfrno",
            "symbol": "SIML",
            "name": "Simoleon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/alex-dp/simoleon/main/simoleon.svg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://livingfreely.on.fleek.co"
            }
        },
        {
            "chainId": 101,
            "address": "FQnWLBsBDPXP9AdAUyLkyhh8e29avRaSGS76w3ubnc6n",
            "symbol": "zsyc",
            "name": "zsycoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zsy450/zsycoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EF7mcTH7ZQXzHmdX3QEsumc8pdi2EzAcDrmM86ddUzNq",
            "symbol": "AIDS",
            "name": "EydanCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EthanLavi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D9ipP4vhuFYUKY8FXijXb76XLBhfjkj2GHWotQTc7CN",
            "symbol": "DOODOO",
            "name": "DOODOOKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GoofyDaytrader/doodoo/main/DOODOO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Y61XE4TV7J8B8r9N19drrVRftsEdqP89mGzkbUP8Shy",
            "symbol": "MRTZ",
            "name": "MoritzCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/moritzengineering/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H9k2cWgLfADao2FMoBCV3KNb4HCTeK7HiybDR7WTVEPK",
            "symbol": "KBC",
            "name": "Koal Bear Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BloodRose86/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GrCQkoCQDmh4XGjDohPvDiAndDH5uBpEXPWVGSoAqWa3",
            "symbol": "RIZ",
            "name": "RIZ coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OG-RizNaz/crypto/main/rizcoin_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "52jhieJCcQQPk3L81BxEuG6kyihAvcUvbwMKSVHJEK7r",
            "symbol": "ISAAC",
            "name": "Isaac Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Firebros1/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ALTXQKzksWFHrwBR2P2xVNWXCw7uNZCXrSaAo3NLrZCn",
            "symbol": "EDD",
            "name": "EDDARI Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EDDARI/MyCrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9shzY9jupopFajZbKP1sdhzwVwofZRejQgq7Kvw6jQKY",
            "symbol": "KAGD",
            "name": "KAGED KOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9shzY9jupopFajZbKP1sdhzwVwofZRejQgq7Kvw6jQKY/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://www.kagedmuscle.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EooEcetGTsGCq1EN9ZjHqQDybFFwPP9MZvy4JHqCVf3S",
            "symbol": "KMR",
            "name": "Comrade Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hamcreation/crypto/main/logo-main.png",
            "tags": [
                "social-token",
                "community-token",
                "reward-token",
                "utility-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "8piC4Rswbd4dkaMokYDVAVpurwCfu4PUWCoSdHUckjzR",
            "symbol": "MZC",
            "name": "Maunz Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/datamaunz/maunzCoin/main/MaunzCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5jjao6fQ9v7UcUZW4VjgroVE6k6bCj2HU2vUKrA73rS6",
            "symbol": "LIVE",
            "name": "LivePlus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rodolfo-vargas/live/main/L!.png",
            "tags": [
                "payment-protocol",
                "Payment",
                "Videos",
                "OTT",
                "utility-token",
                "Live-TV",
                "LivePlus",
                "Live-Plus",
                "live-stream"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Isjustlive",
                "website": "https://solscan.io/token/5jjao6fQ9v7UcUZW4VjgroVE6k6bCj2HU2vUKrA73rS6"
            }
        },
        {
            "chainId": 101,
            "address": "G6FsRAEAzvAZ2Z9fx68kuHWGyzmziJWwNao3gEmJ8Lmy",
            "symbol": "ORO",
            "name": "FamiLees",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/arain11/crp/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Wi7fWMzK3Ap9jGTTCtCavzBXZpZriVMo93udwo9ZrEr",
            "symbol": "CMCN",
            "name": "Comet Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FlamingComet/crypto/main/Comet.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B9Ej4ooSjae7xdgMhcUzCGtT6HcCL4cA8LSse3QqaibX",
            "symbol": "CGBC",
            "name": "CaGaBre Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/felipecadar/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BkQ4y8qNkZENFZojgVFtGig9WTFLk7nkHG1aYfUPUgt9",
            "symbol": "CPAY",
            "name": "ChorPay",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Tactictoes/Crypto/main/Chor%20Icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4VixcNJEegoSMBBZxsey8SFTreA6cWTKADdvRARcJQdu",
            "symbol": "RTTY",
            "name": "Retty Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bretterer/crypto/main/RTTY/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8sSLUDCbGABLgRWAqoWnAryG6vQaAbMhWdSmSshaBbp6",
            "symbol": "ILVPSSY",
            "name": "PussDSTRYER",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/egf-vv/crypto/main/logo%20crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7khbh7NsnKb5NGo6uFCTX4ec3viu2nhxJZ7ZK1fAmTFM",
            "symbol": "DRKO",
            "name": "DarkoKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ranxxaa/crypto/main/logoo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FryjpgnHCT3zS3jkBJ1Tc1Xrkq9CrtHin6Gxv7HbhQup",
            "symbol": "MTVB",
            "name": "Magic TV Box",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jemerocay/crypto/main/logo300x300bb.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EUYmNJg561X12YzYHAy8tSpYVo8SHgau48GrqS76vuRb",
            "symbol": "LWIZ",
            "name": "LwizCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tomkonikkara/lwiz-crypto/main/LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8bkuvExu7LPDY8H37SrUaqncLwKKmudjhSFWwD6wfpA9",
            "symbol": "NOSS",
            "name": "Noss Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ItsNoss/noss-token/main/transparent-128.png?token=ANSJD3YSMXV7IRIZRXTVVPTBY2HB2",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2LG52mHVTjHLZ1xoQjzPk2fvTnmePNWqL5Gx7zWrRV25",
            "symbol": "SALTS",
            "name": "SALTS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SycoSonic/Salts-Coin/e19694d9ae64ea08f9b0670042e06373719eb63a/SALTS.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4yNRp5Ap8TfG6oYPSPDHJjy5G3h3LjfWpdPZVBRb3KGT",
            "symbol": "TNC",
            "name": "TeamNote Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/tonytong830-apptask/token-list/main/assets/mainnet/4yNRp5Ap8TfG6oYPSPDHJjy5G3h3LjfWpdPZVBRb3KGT/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3cq3zUJd8fJ9GT7NEuB5yvUJy56LySKT3tTyaYKxJ6wu",
            "symbol": "RNBN",
            "name": "RONIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kevbot00/crypto/master/ronin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TaUdunJo5TcdJzt9kyB4cd6HTYUdFXJHCSL2yc3sbg4",
            "symbol": "TRUESIGHT",
            "name": "TrueSight Governance",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TaUdunJo5TcdJzt9kyB4cd6HTYUdFXJHCSL2yc3sbg4/logo.png",
            "tags": [
                "governance-token",
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "TrueSight.Me is a decentralized oracle for world sentiments. We believe making decisions under ignorance is to the root cause of human suffering. Our purpose is to build a better world based on fundamental truth. Our mission is to provide individuals with crowd-sourced actionable insights to make critical decisions in their daily lives. This has became imperative in our increasingly chaotic world.",
                "facebook": "https://www.facebook.com/TrueSightMe",
                "imageUrl": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TaUdunJo5TcdJzt9kyB4cd6HTYUdFXJHCSL2yc3sbg4/logo.png",
                "reddit": "https://www.reddit.com/r/truesightme/",
                "website": "https://truesight.me"
            }
        },
        {
            "chainId": 101,
            "address": "DK4rfyNuMN4VRnRJq4Ue6pRBvuCADePE9SGUYMVTNZ6k",
            "symbol": "NTFT",
            "name": "NineTailFox",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vaatsalya123/Ninetailfox/main/crypto_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HhDZibDm5QUfU1dWbvuEu4iSfqfWLtvu5hVJFi4bZfJ4",
            "symbol": "NOAH",
            "name": "NoahCoin",
            "decimals": 0,
            "logoURI": "https://github.com/nspahn/noahCoin/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5hUanQop7uL6V1S1U7tydn7SBuieRSW6wYsbXWea3Lsi",
            "symbol": "XP",
            "name": "Elite XP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5hUanQop7uL6V1S1U7tydn7SBuieRSW6wYsbXWea3Lsi/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Elite XP is a new skill-based wagering platform for gamers to play and earn crypto based on their own in-game performance. We’re bringing play-to-earn functionality to existing popular games like Fortnite, Dota 2, and more! Powered by Solana.",
                "discord": "https://discord.gg/zAKmq5N279",
                "imageUrl": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5hUanQop7uL6V1S1U7tydn7SBuieRSW6wYsbXWea3Lsi/logo.svg",
                "twitter": "https://twitter.com/XPcorp",
                "website": "https://elite.xpfantasy.com"
            }
        },
        {
            "chainId": 101,
            "address": "FkHzJkQudpz9sQf1s75dFhFZXnNvYpFeb41bjAirJmZa",
            "symbol": "LNT",
            "name": "LentCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lento47/LentCoin/main/LNT.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FLoD6AwcJCnbznnWfV6HkBHh5FYtr8wJYj3mBnwNdLLg",
            "symbol": "DOOK",
            "name": "Dook Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLoD6AwcJCnbznnWfV6HkBHh5FYtr8wJYj3mBnwNdLLg/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "The goal is simple, to spread love. How we spread it.. well that's where our community comes in.",
                "website": "https://sighducks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "aGyPX4YeSJcb4Y3GMDNnk8CBhQvq1G6fXmcUbHYJEjD",
            "symbol": "MYTH",
            "name": "ChainMyth",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aGyPX4YeSJcb4Y3GMDNnk8CBhQvq1G6fXmcUbHYJEjD/logo.png",
            "tags": [
                "NFT",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "5v5 FPS game with MOBA mechanics, featuring character NFTs! Tokenised 5K 3D characters!",
                "discord": "https://discord.gg/chainmyth",
                "twitter": "https://twitter.com/ChainMyth",
                "website": "http://chainmyth.io"
            }
        },
        {
            "chainId": 101,
            "address": "EiMNcA5UGw9jxCwaH3ncd1yYD8bEtNXCARWuXKTvCP2E",
            "symbol": "SPRKL",
            "name": "Sparkle",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/PapaBearDoes/SparkleCrypto/main/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "The Alphabet Cartel is a community of content creators that have banded together to provide a safe place for LGBTQIA+ members / allies and provide awareness / education on the issues that the Cartel experiences through games, discussion, and community.",
                "discord": "https://discord.gg/uxKaw7K4UQ",
                "twitter": "https://twitter.com/alphabetcartel"
            }
        },
        {
            "chainId": 101,
            "address": "4rA2iVsDroL1AEpK6wQjRr82YujboyFuiPrEUQ9Lt36H",
            "symbol": "QUEEN",
            "name": "Qoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/QueendomDAO/media/main/logo.svg",
            "tags": [
                "Multipurpose-token",
                "utility-token",
                "community-token",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "8NioZ3twxQnNA9UgxfeGHB8fXpEEVs2v6MgVEU1cNWkM",
            "symbol": "STWC",
            "name": "Stormwind Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dubde1/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "eG7L3PRqzNZLrrzoei93zHK2oDmq1dtyVwjFu83RBki",
            "symbol": "DREAM",
            "name": "Dreamland Currency",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dreamlandmonkeys/assets/main/assets/dream.png",
            "tags": [
                "metaverse-token",
                "community-token",
                "Dreamland-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AGRpSFvJiYLu726eyrEQTbT5KRyLtcfAiaDJBLVjp82Q",
            "symbol": "BRENDA",
            "name": "TJs Lover",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tjslover/crypto/main/brendaheart%402x.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Qyt6ULtyXkT8C83mcdi5RA6oJF7K3Kg9HroUz4kHgAL",
            "symbol": "PNDA",
            "name": "Panpan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PandaDevolper/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "67W6AdXTzKxGp1556GF45Wh7sENBxNmXpGFHKCk8mH87",
            "symbol": "BRLC",
            "name": "Brasil Real",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/silvionetto/crypto/main/tupinamba.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EzaS1S9ziDooyaqLeVU245eeia5dfNpZYNiJuxagA5iy",
            "symbol": "PIO",
            "name": "PEERSIO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/peersio/peersiocoin/main/PIO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8p7fEr1TC12osTi5EdnntYd8hjA6aSL1MfNr37xhiYCi",
            "symbol": "FRKS",
            "name": "Freakscoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/leocapuzzi/freakscoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SrkAxNG82cyXmCBDrqLqdZWmeYc7yxymexRKuEeSSYA",
            "symbol": "ISCO",
            "name": "Andoniar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Andoniar/insertcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6cvXk6byhVKKMmVR1hLMjYp4SXVt9RyFHvuVsGRzA3Sy",
            "symbol": "TNLI",
            "name": "Tonoli Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ricktonoli/cdn/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BQqo6Xwu7NzMvL813DkdWzcShWdbW6EiAnvr2HJVmdjg",
            "symbol": "LKEB",
            "name": "LukeBux",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lmcewen9/lukebux/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5pemBJT45oNvBYPvML1svLz6WXfEPHzsiEwHKCt85tSH",
            "symbol": "AVLN",
            "name": "AvianCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/z3r0-k3lv1n/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qycUc4D7p24BJz5SijoVmcx8o2kJirQqXGaK51THQso",
            "symbol": "AVES",
            "name": "A Very Efficient Server Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/garylin/token-images/main/aves.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6oiYnLCtsoyVua2KsJ9um1kdW28rMPPaQzZp95WrpcC5",
            "symbol": "RW",
            "name": "RACEWARS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AftershockNFTs/crypto/main/logo.png",
            "tags": [
                "NFT",
                "community-token",
                "gaming",
                "betting",
                "metaverse"
            ],
            "extensions": {
                "description": "In community purchases and bets within 'Aftershock NFT Collection' VR Racing Game",
                "discord": "https://discord.gg/S5kBZbCC2c",
                "facebook": "https://fb.me/AftershockNFTs",
                "telegram": "https://t.me/AftershockNFTs",
                "twitter": "https://twitter.com/AftershockNFTs",
                "website": "http://AftershockNFTs.com"
            }
        },
        {
            "chainId": 101,
            "address": "9RrcgceVyBPGD7cQnS9RFvThLmmwFLGCRNPhd9BdzM51",
            "symbol": "EGGY",
            "name": "eggyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eggyBones/eggyCoin/main/egg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "21kcPGWam5J8H4Ppa4C9NvbUswrA1SqQ5fWfXimAScay",
            "symbol": "EAST",
            "name": "EASTSIDE GmbH Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/project-play/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HfMVgG3fQr45JtrQD3jpVki6E5H5BSdjN8kCAvDEDKMQ",
            "symbol": "INA",
            "name": "Inanna",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/781d71f6cb76d2921c7f101fac3aa3010c627cdc/assets/mainnet/HfMVgG3fQr45JtrQD3jpVki6E5H5BSdjN8kCAvDEDKMQ/logo.png",
            "extensions": {
                "website": "https://www.inannafinance.com"
            }
        },
        {
            "chainId": 101,
            "address": "FzHYtu6S1EDr411frWwi1BjFjZC5dBe3cBrwBt7VEerN",
            "symbol": "FNC",
            "name": "Frannetwerk",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/frannetwerk/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "68yMQnizexkYdCA73PyEKg3Vs1KxWWMceDdp7LUhCTeh",
            "symbol": "LUCKY",
            "name": "Lucky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/garylin/token-images/main/lucky.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "81CoQVPb5ugBFicV1UekRjbGk8ZJkdKA2JJ4a1Rj5gb7",
            "symbol": "FTDAO",
            "name": "Fintech DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lovelangy/token-list/main/assets/mainnet/81CoQVPb5ugBFicV1UekRjbGk8ZJkdKA2JJ4a1Rj5gb7/logo.PNG",
            "tags": [
                "DAO"
            ]
        },
        {
            "chainId": 101,
            "address": "92bcERNtUmuaJ6mwLSxYHZYSph37jdKxRdoYNxpcYNPp",
            "symbol": "BTC-mSOL",
            "name": "Raydium LP Token V4 (BTC-mSOL)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/92bcERNtUmuaJ6mwLSxYHZYSph37jdKxRdoYNxpcYNPp/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5ijRoAHVgd5T5CNtK5KDRUBZ7Bffb69nktMj5n6ks6m4",
            "symbol": "SOL-mSOL",
            "name": "Raydium LP Token V4 (SOL-mSOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5ijRoAHVgd5T5CNtK5KDRUBZ7Bffb69nktMj5n6ks6m4/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FWc7yLYJFZ3hftcHfrFjG7CioFMpiwyy7ahFVpUxEZMh",
            "symbol": "ISSA",
            "name": "IssaAI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/marco2meyer/crypto/main/Coin%20logo%20small.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DhrqCm5oG8J6agWVCXzpKJh5UZk4XcWCJ4pyXm2szNXx",
            "symbol": "OLIMP",
            "name": "OLIMP NFT TOKEN",
            "decimals": 9,
            "logoURI": "https://i.postimg.cc/MTYxbd0K/1.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ArGrm5N6Sv9d79bosoH7jjteRDVLfX1J2HinPFoBFXz6",
            "symbol": "MSWS",
            "name": "Mighty Sol Wolves",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArGrm5N6Sv9d79bosoH7jjteRDVLfX1J2HinPFoBFXz6/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3NVEy7VgpRQ2CjdgJPBDDAb1Usvk51b42oC5wmbTHh9x",
            "symbol": "VSTC",
            "name": "Vilaythong Southavilay",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vstoto/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3S3a5vVR244mrDZWidChYk1wYM6L2Y1nG58EDP4Hdpbx",
            "symbol": "$PETE",
            "name": "PETE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3S3a5vVR244mrDZWidChYk1wYM6L2Y1nG58EDP4Hdpbx/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wt3PwDSETz",
                "twitter": "https://twitter.com/Pricklyplatoons",
                "website": "https://www.pricklypetesplatoon.army/"
            }
        },
        {
            "chainId": 101,
            "address": "9L518Y4ReHwEPDaTEVGF9pSdSRx4o1ieUkgxakLafw18",
            "symbol": "JFC",
            "name": "Solana JFC ",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/ziazilliqa/jpl-logo/main/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HPsCEGhe9tvrg7XGSqLsXPmwniv4GXP5DxTQy4739sRb",
            "symbol": "BEANS",
            "name": "Qahwa Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HPsCEGhe9tvrg7XGSqLsXPmwniv4GXP5DxTQy4739sRb/logo.png"
        },
        {
            "chainId": 101,
            "address": "3NDbsGrfzxhuybrWizhLC9QpnFDeHgfYzJ8wc5vGBkSm",
            "symbol": "CYBR",
            "name": "Cyber Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cyberpayload/crypto/main/CYBR-Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fx3LGp9Uvut5pB6oCa247SSefbd6MfVYZy9JPBvM3xCk",
            "symbol": "NORI",
            "name": "NORI Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maximakesthings/nori-coin/main/NORI.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHBFPh1MPcDP4gwezgFrANsxbFW5YKwSmpFEyAtnCbq9",
            "symbol": "TBF",
            "name": "Token Boyfriend",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/victorpng/tokenboyfriend/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "91bsBRfsS8acSvThHXUp1gRHUKaydfJQiU8uixdyqC47",
            "symbol": "SOPP",
            "name": "Sopp Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danthedev123/sopp/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CTpBUVdodTVc8W9m8mG3aUgVzGvEqKofsFhwD9rHreZu",
            "symbol": "RDC",
            "name": "RDC Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CTpBUVdodTVc8W9m8mG3aUgVzGvEqKofsFhwD9rHreZu/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://radu.ursache.ro"
            }
        },
        {
            "chainId": 101,
            "address": "2Yuv3BqAUsUeqYfrMRYL6LJZgf3HMKTkDrZM6mEf9R2j",
            "symbol": "MCN",
            "name": "M-Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Yuv3BqAUsUeqYfrMRYL6LJZgf3HMKTkDrZM6mEf9R2j/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://318music.com/mcoin"
            }
        },
        {
            "chainId": 101,
            "address": "5guuZW8cNNji4MRhf1T9bkJEiFEVbfKoh3tARe6xZzwt",
            "symbol": "CONR",
            "name": "Conner",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AnonymousChicken/solonatoken/main/dsBuffer.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5gak19rmAWusaesE8yqGRhB5thnsisMjYtU5x1RAV3cU",
            "symbol": "zia",
            "name": "zia zilliqa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eerip/zia-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7mKc2BHTogjgCNB6BAVA6rJc2mngF2ZJDu1J9iLrpoJZ",
            "symbol": "TPBX",
            "name": "TapBox",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TapBoxTech/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4aq9fPv1KqSURGat1bbrUtwFtQfN8xdWDUN1ezxubg5Q",
            "symbol": "EOGC",
            "name": "Eyes on Gaming Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/karldermarx/eogtoken/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FQnE6vtekmwXMY2DMQXgmtKH9CvUN8LoFBrW8U8CKUgH",
            "symbol": "KSY",
            "name": "Kelsey Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/blkrypt/KSY/main/black-Kelsey-removebg-preview.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EE2u4wHdyFZsSe3hdVhTSLCPjRsqG8Uc4SX8iSYEucQk",
            "symbol": "JCC",
            "name": "JerseyCityCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EE2u4wHdyFZsSe3hdVhTSLCPjRsqG8Uc4SX8iSYEucQk/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/JerseyCity",
                "website": "https://jerseycitynj.gov"
            }
        },
        {
            "chainId": 101,
            "address": "13cbpw6uN6wXVaQk6ngedAErceLLhyo1mgzoDpoUgCJE",
            "symbol": "PHISHINGSCAM",
            "name": "PHISHING SCAM TOKEN, PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/13cbpw6uN6wXVaQk6ngedAErceLLhyo1mgzoDpoUgCJE/logo.png"
        },
        {
            "chainId": 101,
            "address": "C2zLCAAKBJ94tDNh1kmDir7ddMPZTutKoLtaweddTbLC",
            "symbol": "BREEZE",
            "name": "Island Breeze",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Prime3vil/Island-Breeze-Token/main/logo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dg7p7c3AYyPyGvXcntPb1rd8CrbTY3TtfqDkAVtWEG2T",
            "symbol": "HEYC",
            "name": "Heytens Coin",
            "decimals": 0,
            "logoURI": "https://github.com/TuxSudoX/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FqqCbh6zuGJveheZeJwUj4nW1s4PiLFgjkEzCaKhoEkK",
            "symbol": "JRSY",
            "name": "JerseyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mjsantos00/sol-project/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "96SAxVXhnL2AVsL1bUGExuuqGojjgUWTAudoZQQPnK8N",
            "symbol": "SPELL",
            "name": "SPELL",
            "decimals": 9,
            "logoURI": "https://arweave.net/K1VORgjVa_ZugwgH6lr2YNCBhFT_rw-QyEiIYzOEuMc?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/solwitches",
                "website": "https://sol-witches.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6AJAV7o7SYLbmkTWhSAEGPzUKBHbuBtQb2i3ktPgCfRW",
            "symbol": "SHC",
            "name": "SherlCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Flowdawan/SherlCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EtgneRQETSDbjpAadZhoZ9uU6XjE8HNmvRgJAzmByQRW",
            "symbol": "SOCIAL",
            "name": "Social Coin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/OurSocialCoin/SocialCoin/main/logo-socialcoin-256x256.png",
            "tags": [
                "Community-token",
                "Social-token",
                "MetaVerse",
                "DeFi",
                "Payment"
            ],
            "extensions": {
                "reddit": "https://www.reddit.com/user/Social_Coin",
                "telegram": "https://t.me/SocialCoinNews",
                "twitter": "https://twitter.com/SocialCoin3",
                "website": "https://oursocialcoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "DDpPE1qBRLFfKYnmaGgnimSXNX3cYhRNBe5r8AC9tYRF",
            "symbol": "TIDAL",
            "name": "TIDAL Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicholhai/crypto-tidal/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "At5j3zhbEj8mfFsSy1MPbjVhrX2uNmRMPEDZiyFcETNX",
            "symbol": "BRWNDO",
            "name": "BRAWNDO",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/At5j3zhbEj8mfFsSy1MPbjVhrX2uNmRMPEDZiyFcETNX/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CvNLK13TUk8WymQgqy2VcLEtpcJYnyAGx1Mt9q3Syt7a",
            "symbol": "EMBLA",
            "name": "Embla token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kakkokakko/embla/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8eziEdHtKbc6FPPmuSpRCv1JbaKv7Hi5gLu5yLw49iCz",
            "symbol": "LOKI",
            "name": "LOKI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lokiandshrody/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2K18DgTTRqdGdwLHFgfZabH3FWyoMGeTeS1fdoWMsYBS",
            "symbol": "DRL",
            "name": "DUARTE Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LeoDuar/crypto/main/Logo-01.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Guuhj9TgQHV8A78UE4vKYBXN9QdQHHxUi4WxKU5QSAaL",
            "symbol": "KENTOKEN",
            "name": "Ken Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/hacksider/kentoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "qJFde4hmV2cARNyUxvhJN5QxJZU5KA3XSzNzjNUmC8r",
            "symbol": "SCTC",
            "name": "ScoutCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/frankie2times/crypto/main/555-5552141_easy-to-draw-boxer-dog-hd-png-download.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5aGhhs5umCgzyztWCQQ4KwCHu3g3FhMhTSWH1xuxDByr",
            "symbol": "Maff",
            "name": "Maff coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/conthegreat/crypto/main/maffcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EhP5wbt4Ew9N6vnr72KVw2QpARiVkgZHpfazx3ND9V6J",
            "symbol": "ucm",
            "name": "Cum Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andevcrpt/crypto/main/a90378c5210b4c7988a8d91dc6ef4396.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "21tkNLD7kMNXmbejHsPwpuVmMfdxM3qgmpvxvHc1P9i2",
            "symbol": "BGLEV",
            "name": "Bulgarian LEV Crypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hamcreation/crypto/main/BGLEV-logo-big.png",
            "tags": [
                "social-token",
                "community-token",
                "reward-token",
                "utility-token",
                "nft"
            ],
            "extensions": {
                "description": "Bulgarian LEV Crypto Currency (blockchain, independent from the official BGN currency). IPFS : bglev-crypto.coin",
                "website": "https://gateway.pinata.cloud/ipfs/QmVkteQriYAKt46CN7K6paqhQ3q2ECMs3ehGtPhW5jWcaQ/"
            }
        },
        {
            "chainId": 103,
            "address": "AGijTbeHy2FKngd42uzBNjb6dM27bvL1yt7YN8gu7eLB",
            "symbol": "OLVCD",
            "name": "Olives Code",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGijTbeHy2FKngd42uzBNjb6dM27bvL1yt7YN8gu7eLB/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "652wypzKa3vewqezFy8rai2GRsqJnGGsMn8VnsGjQPCN",
            "symbol": "ITAC",
            "name": "ItaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/masaki-itagaki/crypto-itacoin/main/itacoin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D7dNESwNwJwR4rGJ1PkdKDzGe4TSe5e3ZphB9Xq6JU6i",
            "symbol": "MANSA",
            "name": "MANSA MUSA TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jakin2021/crypto.mansa/main/logo.png",
            "tags": [
                "NFTs",
                "entertainment-token",
                "sports-token",
                "social-token",
                "networking-token",
                "music-token",
                "currency-token",
                "Multipurpose-token",
                "metaverse-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DyQSsdD6zEo6LeWYdQPdtNHTCzH1cL4py44BMBfwEkR6",
            "symbol": "ALY",
            "name": "AleksYarn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zaghloulegy/alekyarn-token/main/this.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2cQcEUZA3GAQftMYKjPfDfyjwr9wzcNphnFQXn2XXha8",
            "symbol": "MNZT",
            "name": "Meta NZToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jreMNZT/cryptoken/main/Mk1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ueBZoDVju9NwKL9uj1PPz8bT3TJJrDEqwrzRCCHJSAY",
            "symbol": "solMNZT",
            "name": "sol MNZT TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jreMNZT/cryptokeningham/main/doge.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H1kUP4Duno4pKJJ7bWjvvHo1fcAY6YYXy1WYXcsxVUeB",
            "symbol": "ALFA",
            "name": "Alpha Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GoatWick/Crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DSkUNkp3XGHyBELatc13KAqgyQxA83wn6N3tEGQ42s3q",
            "symbol": "IZZY",
            "name": "IZZYKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KFARSH/IZZY/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4UpRFwry9tgRnNBTxgRB7zDhGPbuoxgYmy4QAxvz7uBV",
            "symbol": "BCTK",
            "name": "Black Cat Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LordKlaus/mytoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GfkaneG3grLAYpqrjb2FQnbkRKWGRBL14S9uB33XR9Xj",
            "symbol": "QTS",
            "name": "Quantasec",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Terrahasher/crypto-logo/main/crypto%20logo%20quantasec.png",
            "tags": [
                "security-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FVJsaXLQfpHHfn22nzhmCzvbaiCZfXBgTM18yiZ7G9oR",
            "symbol": "SWMB",
            "name": "Swaimbux",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/doodmeister/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7e4ESDEv6bgXBAv1Qxvfq1gZNbU23f7WAtxcRXCYWgs6",
            "symbol": "HRZN",
            "name": "Meta Horizons",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mimam8/Crypto/main/Logo.jpg",
            "tags": [
                "NFT",
                "gaming",
                "Community-token",
                "Social-token",
                "MetaVerse",
                "Payment"
            ]
        },
        {
            "chainId": 101,
            "address": "13V5LqwdTUBZgJbyUe34nfqPYTwvB5gHigrKpDKyRB3",
            "symbol": "XPNS",
            "name": "XPNS Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AidenCrippa/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D6nfw6WVSRsoMx3SYYdEafHbuHkKaWnGQ6eczenKi7br",
            "symbol": "LNMX",
            "name": "LinuxFacil Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/linuxfacil-mx/solana-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3aA8QbAEyKDg2KJFXrStLZtjyYJzkVXYn8q5rLwosLUT",
            "symbol": "OEQMS",
            "name": "OmarCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OmarEQMS/OmarCoin/main/OmarCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JBjxHjFKMBvRa45PvYDaEVfecdYaV9pkwjnVjt2uSLxh",
            "symbol": "SBE",
            "name": "Sombe",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JBjxHjFKMBvRa45PvYDaEVfecdYaV9pkwjnVjt2uSLxh/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/Sombe_channel",
                "twitter": "https://twitter.com/Sombefoundation",
                "website": "https://sombe.org"
            }
        },
        {
            "chainId": 101,
            "address": "5j3xeUA37PRUu5yyXioyauteRRZYS1Xeit4HWEaxbMJ5",
            "symbol": "ARKNT",
            "name": "ARKNET",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cyber-arknet/arktoken/main/cropped-arknet-1.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/profile.php?id=100076065577875",
                "instagram": "https://www.instagram.com/arknetcyber/",
                "telegram": "https://t.me/+_HDz93MEOptkMGI0",
                "website": "https://arknet.pro"
            }
        },
        {
            "chainId": 101,
            "address": "J2hrSRQsXWpDRDUks3HkC3k5NK1oUdzA2naSNbkp4UoB",
            "symbol": "GDGC",
            "name": "GDG Cloud Santiago",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gdgcloudsantiago/gdgc/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6txogwrDcmKEj2mBz5KaSW82ytKPiPYGwcTcuHuEjATh",
            "symbol": "SNAX",
            "name": "Super Snax",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/karyoclasisfox/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CJitFpQgHVayFFQHh1e59nFVn5Lm27EtmfuAQQbeKQxk",
            "symbol": "BOB",
            "name": "Bob Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MajorScully/crypto/main/BobCoinIcon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TT4Fq7cZYM72So5px8jY9y2F9MER4YyMFCC1R6yDoVn",
            "symbol": "SGTBK",
            "name": "Burk Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jbburkhalter/crypto/main/Sgt.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A6JVLMAjR1aeCfz3X2z4vVQ9GafYWVT75tC5V5qefja2",
            "symbol": "BULB",
            "name": "BULB Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A6JVLMAjR1aeCfz3X2z4vVQ9GafYWVT75tC5V5qefja2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/9NMDkGdetE",
                "instagram": "https://www.instagram.com/bulbappio/",
                "medium": "https://bulbappio.medium.com/",
                "reddit": "https://www.reddit.com/r/bulbappio/",
                "twitter": "https://twitter.com/bulbappio",
                "website": "https://www.bulbapp.io/"
            }
        },
        {
            "chainId": 101,
            "address": "JqP79NdF3uqiaceeuYEsNufMy93bTM5zBtiRczjg4b7",
            "symbol": "LOW",
            "name": "Aqua Zoya",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Emrys1111/Crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2McdqcKvog5mv3VuEB8iGdCqCxVE9SQWrANXbVJMtWbq",
            "symbol": "SRSDS",
            "name": "Star Seeds",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/moulder29/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5sPXiVGaK2Uu2kbEgJ3U4ftpRouhg49ebF6zbsxwBMwC",
            "symbol": "BPC",
            "name": "BOUSPAM COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/bouspamcoin/crypto/main/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HvB8AK6NaVbLb6AxxCXuK8WrExFhMt3y8f2FbVGR18zW",
            "symbol": "AERO",
            "name": "Airplane",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TuznaSova150/Crypto/main/Airplane.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9me5u1aCBzV8VEgE8Rp1NrD9SrRDKYaDwYjgMjEuFjqo",
            "symbol": "MICN",
            "name": "MihirCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MihirSahu/MihirCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A9YU1GzfeN77Z59jaDP51i1ZdXyDUheCGEfLa6tR5coX",
            "symbol": "TBUX",
            "name": "TruckenBucks",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9YU1GzfeN77Z59jaDP51i1ZdXyDUheCGEfLa6tR5coX/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2uwnrFSF2czXtUgVZVXpAhdHuBCYcawkxdJaTFWXQPFa",
            "symbol": "SJN",
            "name": "SJN COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shenjianan97/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DVQJvpzxNS6sCPa1AAKKV24YkvQtveHKH9uEYDJ2NyPf",
            "symbol": "ARVX",
            "name": "Arvix Coin",
            "decimals": 0,
            "logoURI": "https://avatars.githubusercontent.com/u/15823883?s=400\u0026u=01e5237b6ed37d8b2e142c02688ed3c38c6d62ac\u0026v=4",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BebGokMwTrFp2wRV4Z5CftVq7pvgMbj176VND3vTVSKJ",
            "symbol": "GAPE",
            "name": "Gapes on Sol",
            "decimals": 9,
            "logoURI": "https://arweave.net/9XlB-rI0RiFtophrUQkw8Rj_H9Gqn3yK0j8NXy7zIyA?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/0x_apes",
                "website": "https://gapesonsol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Cx7gB3yZxhNab6nPwxRcdnEmbV2crkaR5ncuZrUsjaXa",
            "symbol": "PNTRc",
            "name": "Panter coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ladja73/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "36iKkbRbZcBXrKcpN75X9zrcvgpcGKmGZ8kgq1XnrxaF",
            "symbol": "CIR",
            "name": "Circuit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/UNTOCHABLE247/circuit/main/Circuit-Crypto-Logo.png",
            "tags": [
                "social-token",
                "community-token",
                "connection-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BkcfKKAFjFET3GW8Ph4WcN84SMwpD2wjNpJbDXrGvHq7",
            "symbol": "APES",
            "name": "APE Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/evilspray2/cyptomain/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9LmHXaboGRjE4VCxo8uUQFHxG97TfdPn97g48Fi87RvW",
            "symbol": "FFOX",
            "name": "Flimsy Fox Coin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/Flimsy-Fox/FFOX/main/Flimsy_Fox_Coin275x275.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EBKxGA78dXoUNvihNa5ufajkY7c8gJdPT5nvNBc17SQR",
            "symbol": "LUND",
            "name": "Lund Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/arkalon76/token-lund/main/assets/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CeEgpp7uQmw8S25s1iMLrdv28oC7SGYM5ynNQU8PGbyo",
            "symbol": "UNKN",
            "name": "UNKNWN SOCIAL TOKEN",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/unknwn1993/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FFQnGJkWtaSjpUTQVqEWPQwSPBC5EvTBWLR5nyE43rWn",
            "symbol": "OKDT",
            "name": "Oskadon't Team",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/derysendiry/logo/main/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2bynPcARe3D3FmvdCukCxhrvuAu5EaBwxNC4m5MA5dGF",
            "symbol": "MMST",
            "name": "MEDIAMARKT SOCIAL TOKEN",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/unknwn1993/MMTOKEN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DPh8nC4TxcJEyTUmTDtvkj4o7rkDg9o9v2x3Yd3DXbCL",
            "symbol": "WATER",
            "name": "Stingrays Water Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DPh8nC4TxcJEyTUmTDtvkj4o7rkDg9o9v2x3Yd3DXbCL/water.png"
        },
        {
            "chainId": 101,
            "address": "J27HeESs7iFotiCw7jZXx4mtM5iqEwXawTHnEbUGJEC3",
            "symbol": "CRNKYB",
            "name": "CrankyBird",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/citizendope/crankybird/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J5zREi94yfDQgkeZXvNANMRvQV6gdGd8UGcmBfnj1nSr",
            "symbol": "WOMBAT",
            "name": "Wombat Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J5zREi94yfDQgkeZXvNANMRvQV6gdGd8UGcmBfnj1nSr/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/snft2022",
                "website": "https://solana-nft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4K1MYRAoD6X87N6En3u5LVP2n3uHP3arZ6D5aV78nqo1",
            "symbol": "SHRIMPO",
            "name": "Shrimpo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/shrimpotoken/logo/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Zk9zWQ1VJn19snoJFg3qJqjCHT6VSExqYJvjXMCKVAT",
            "symbol": "MAITO",
            "name": "Maitoken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Zk9zWQ1VJn19snoJFg3qJqjCHT6VSExqYJvjXMCKVAT/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FLUX1wa2GmbtSB6ZGi2pTNbVCw3zEeKnaPCkPtFXxqXe",
            "symbol": "FLUX",
            "name": "Flux",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLUX1wa2GmbtSB6ZGi2pTNbVCw3zEeKnaPCkPtFXxqXe/logo.svg",
            "tags": [
                "web3",
                "decentralized-cloud",
                "masternodes",
                "mineable",
                "proof-of-work"
            ],
            "extensions": {
                "description": "Flux is the cryptocurrency powering the Flux Ecosystem, including decentralized computational network. Flux gives users both institutional and private control over their cloud infrastructure in a decentralized manner.",
                "discord": "https://discord.io/runonflux",
                "facebook": "https://www.facebook.com/groups/runonflux",
                "github": "https://github.com/runonflux",
                "instagram": "https://www.instagram.com/flux_runonflux",
                "linkedin": "https://www.linkedin.com/company/flux-official/mycompany",
                "medium": "https://fluxofficial.medium.com",
                "reddit": "https://www.reddit.com/r/RunOnFlux",
                "telegram": "https://t.me/zelhub",
                "twitch": "https://www.twitch.tv/runonflux",
                "twitter": "https://twitter.com/RunOnFlux",
                "website": "https://runonflux.io",
                "youtube": "https://www.youtube.com/channel/UCphbdfb1MXYgUPsdhQPcnGw"
            }
        },
        {
            "chainId": 101,
            "address": "GDenszZiAqmQGFochanKiYHgNcQ6hoi1xEkkgPrGvdvM",
            "symbol": "MCR",
            "name": "Metacar Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Jasil123/metacarlogo/main/mcr.png"
        },
        {
            "chainId": 101,
            "address": "3YmeCnMWgGM4tyU48ZdhRkw1Ni1hf8t9ykVWFtHmD1Vf",
            "symbol": "OSI",
            "name": "Osie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/oosiep/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BsJnmL4fKxVwmX6hGhQ2m22hAzx9EipM1NL4ei5zRvmP",
            "symbol": "RJBAGT",
            "name": "RJBitcoinAdvisoryGroup Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RJBAG/crypto/main/logotokentest.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4gcuaQbM9MFJDzKbUBH6oZQTDoCL6WczxAJkvJyGw2Am",
            "symbol": "BLUE",
            "name": "BlueCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bluetelecomsjames/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6h7hNagVUC9Gsd5TwKaNV3NS7GhQDM7Jp9faASyzpEdt",
            "symbol": "ANI",
            "name": "AniGattina",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6h7hNagVUC9Gsd5TwKaNV3NS7GhQDM7Jp9faASyzpEdt/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BwcoRTNgsvMP4yWQpCASBaaZ2FLesnEMAQAf944zSNyM",
            "symbol": "AH",
            "name": "AIRDROP HUNTER",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/derysendiry/air/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9UdAwhC6Qx5kStsS5p1C3uvvphj1Nh35MFtpKUswwjDR",
            "symbol": "TYC",
            "name": "The Yeet Coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/YeetTeamYT/The-Yeet-Token/main/unnamed.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EzyqGBM4itZN5FG5TC21y9w7WZuxS8zLNAjLZ9cKprHA",
            "symbol": "QRS",
            "name": "Quercus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zaghloulegy/Quercus-logo/main/LOGO.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FFBZ7r8Mvk1tSuWRdytmWSo5sJJCVwsAaMbN7m5bD6mR",
            "symbol": "BCC",
            "name": "BabyChaCha Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFBZ7r8Mvk1tSuWRdytmWSo5sJJCVwsAaMbN7m5bD6mR/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Yx5J1Y5eawe7DjiysJEp5xWMdx8ukAHLk7f9eCRXGnp",
            "symbol": "HELL",
            "name": "HellCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Yx5J1Y5eawe7DjiysJEp5xWMdx8ukAHLk7f9eCRXGnp/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DYjQEFzLKXdbJ15cTq7qfRtawhWJrJ3osbhkP1CG6egP",
            "symbol": "SCT",
            "name": "Solcitizens Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/leastgs/SCT/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "reward-token",
                "utility-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "gWBSgzhHqD1iLNk6MhuuuAvSZNtWzKJNSu8PSwuF2XP",
            "symbol": "PCN",
            "name": "Pecun",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/janlampert/crypto/main/pecun_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DwPS8FxXno1roKzqXNPHyphvTZJvFJP2xzAyevdFjZiz",
            "symbol": "TUMC",
            "name": "The Ultimate Meme Koin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/nodnoddk/token/main/948189841110.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GtTThgxVhM8bniQ3rgXcrNhuMJTtTmrdPRiYmMUzfKZh",
            "symbol": "LAB-R",
            "name": "Lab-R Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jtomacic/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HKMyGWVaSmdsYDXTcVYLEnkAN8RF975gRBjNPf2BdtK9",
            "symbol": "IGNFTHKM",
            "name": "Instagram NFT DANIELLEROBAY 1",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HKMyGWVaSmdsYDXTcVYLEnkAN8RF975gRBjNPf2BdtK9/logo.png",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/HKMyGWVaSmdsYDXTcVYLEnkAN8RF975gRBjNPf2BdtK9"
            }
        },
        {
            "chainId": 101,
            "address": "FXgz4aZtrUYdbqWA2boPzQE4J9hfHTGYB31e7sWf4LmL",
            "symbol": "IGNFTFXG",
            "name": "Instagram NFT BIGSTRAYWOLF 1",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXgz4aZtrUYdbqWA2boPzQE4J9hfHTGYB31e7sWf4LmL/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/FXgz4aZtrUYdbqWA2boPzQE4J9hfHTGYB31e7sWf4LmL"
            }
        },
        {
            "chainId": 101,
            "address": "5oZ4GKDJyCGbrjji1u1Q4UUEgfzC7szVv4S47KTKDVCX",
            "symbol": "IGNFT5OZ",
            "name": "Instagram NFT SPINZHOODRICH 1",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5oZ4GKDJyCGbrjji1u1Q4UUEgfzC7szVv4S47KTKDVCX/logo.png",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/5oZ4GKDJyCGbrjji1u1Q4UUEgfzC7szVv4S47KTKDVCX"
            }
        },
        {
            "chainId": 101,
            "address": "D3i6WzaYZB7k6DLj9tJPCTyFgj6rWG2ToFQEfGHFbhDv",
            "symbol": "FBNFTD3I",
            "name": "Facebook NFT VALIANT CREDIT REPAIR SERVICES 1",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3i6WzaYZB7k6DLj9tJPCTyFgj6rWG2ToFQEfGHFbhDv/logo.png",
            "tags": [
                "nft",
                "facebook"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/facebook/D3i6WzaYZB7k6DLj9tJPCTyFgj6rWG2ToFQEfGHFbhDv"
            }
        },
        {
            "chainId": 101,
            "address": "5Z52N5N4W9oc3a7kTNN3PBxPSFnRwNR7UfK8f6b5ktfE",
            "symbol": "CMN",
            "name": "Cloud Managed Networks Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicholhai/cmn/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "48BUK3fXHXyhzAMa47J2KMLS8GZ4ZbATTvQvnb96PUyD",
            "symbol": "WFC",
            "name": "WooF Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maxwolfen/crypto/main/woof.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AtaL8jRE9J9vAU1MZNb89WsnDewYiFxkK5kAsxx7BAGT",
            "symbol": "ZIPY",
            "name": "ZIPY COIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AtaL8jRE9J9vAU1MZNb89WsnDewYiFxkK5kAsxx7BAGT/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHEMvP3o9ejksqQFwUz5o2HgthTge1bReEW55vRiZbcM",
            "symbol": "CHEM",
            "name": "Chemistry",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHEMvP3o9ejksqQFwUz5o2HgthTge1bReEW55vRiZbcM/logo.png",
            "tags": [
                "chemistry-token",
                "the-chemical-trade",
                "capped",
                "deflatory"
            ]
        },
        {
            "chainId": 101,
            "address": "CPRe9qCgVfoSHgNGAzWrJSduv9TBdvaHSmyT5NGqEhjq",
            "symbol": "STFGHTR",
            "name": "Starfighter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bomfricketick/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DwZaJ7y9uJJESB3vtbPo6nkTPwCkxNu2WXNnVCBwCxcp",
            "symbol": "RBT",
            "name": "Reboot",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DwZaJ7y9uJJESB3vtbPo6nkTPwCkxNu2WXNnVCBwCxcp/RebootLogo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "description": "Reboot Token is a Community driven token, this token is not structured after a CEO for its future, instead it has a Community Driven development that provides the control for the development process of the token, every update will be voted on and decided by the community.",
                "reddit": "https://www.reddit.com/r/RebootToken/",
                "twitter": "https://twitter.com/RebootToken"
            }
        },
        {
            "chainId": 101,
            "address": "mCaKentpBgRwisk5zUtx6dfRYkCqMyTKDjjqSDp97mk",
            "symbol": "MCAKE",
            "name": "Meme Cake",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mCaKentpBgRwisk5zUtx6dfRYkCqMyTKDjjqSDp97mk/logo.png",
            "tags": [
                "utility-token",
                "memecake",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/WFkRssvNtW",
                "twitter": "https://twitter.com/realmemecake",
                "website": "https://memecake.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EowcKKxuSpJAr863Tgg5HGpA81A51PLHsLsZffDY1Fh3",
            "symbol": "POOP",
            "name": "POOP Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LeM0-Dev/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "poop"
            ]
        },
        {
            "chainId": 101,
            "address": "AStRo6zTp66EWgpH4SDMUF7F3eeA62sxkE9ukYbfQ1rZ",
            "symbol": "ASTRO",
            "name": "Astro",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AStRo6zTp66EWgpH4SDMUF7F3eeA62sxkE9ukYbfQ1rZ/logo.png",
            "tags": [
                "space-currency",
                "solar-system",
                "and-beyond"
            ]
        },
        {
            "chainId": 101,
            "address": "82dR2RF6DiYgxV4UAN6qrQQnKHtmyGrWfesmomhjNSe5",
            "symbol": "ESC",
            "name": "Escape",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/82dR2RF6DiYgxV4UAN6qrQQnKHtmyGrWfesmomhjNSe5/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "metaverse-token",
                "game-token",
                "nfts"
            ],
            "extensions": {
                "website": "https://escape.bilalbayrak.com"
            }
        },
        {
            "chainId": 101,
            "address": "7nwbJ3mPLDp3wGwgRUgnPXHWiy1ATT4XZHGJki6RajHt",
            "symbol": "SHPTCL",
            "name": "Shpetaclu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jpe17/crypto_shpetaclu/main/SHPETACLU_1_11zon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HxSf6BD7j7Xz4tQdeAGnnv84Tcuxfq47ebXvyQVUib7a",
            "symbol": "DECT",
            "name": "DEC Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mgarofano80/dect/main/dec-color.png",
            "tags": [
                "cybersecurity-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GD4uCKDGDDvUNTioSzgFqPzWiaQ7k1xaypYSoqzNAnDM",
            "symbol": "BOC",
            "name": "Burnout Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vitkhab/burnout-coin/main/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8thP1tHp4wU81umSVZiM8TcNSh5FVbD1NGDJFxmEUqNb",
            "symbol": "STZ",
            "name": "SolTropez",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8thP1tHp4wU81umSVZiM8TcNSh5FVbD1NGDJFxmEUqNb/logo.png",
            "tags": [
                "social-token",
                "SolTropez",
                "SaintTropez"
            ]
        },
        {
            "chainId": 101,
            "address": "BfjEU4wuNnfoidPfncBBeYSsNBLXGacDiduTv4gTchp8",
            "symbol": "N8BUX",
            "name": "natebucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bionicnate/crypto/main/n.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HdpzynkmhceixStgZSbeh8p9avpWGJCHRXfTTfyiXQPH",
            "symbol": "UBIK",
            "name": "UBIK",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HdpzynkmhceixStgZSbeh8p9avpWGJCHRXfTTfyiXQPH/logo.png",
            "tags": [
                "utility-token",
                "ubik-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/zJqKvMRQyZ",
                "telegram": "https://t.me/ubikcapital",
                "twitter": "https://twitter.com/ubikcapital",
                "website": "https://ubik.capital"
            }
        },
        {
            "chainId": 101,
            "address": "275Q9ort43hGq3AWf4DNTT2QyyB3Fn3pWK6RGL9ymm1c",
            "symbol": "MYT",
            "name": "MayoToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/coderus909/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7dXEQ4QJMdSD5CUJmMKd4HmPg72dMWmfAC3REicXYqUE",
            "symbol": "MIC",
            "name": "Mysteries in Colour",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicholhai/mysteriesincolour/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "mf7oAhmvRCSkhPsFzaBEZDwTp3wTPssdjTfUVgpxx4V",
            "symbol": "NETRA",
            "name": "Netra",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mf7oAhmvRCSkhPsFzaBEZDwTp3wTPssdjTfUVgpxx4V/netra-logo.jpg",
            "tags": [
                "NFT",
                "De-Mu",
                "music-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F52S4nbs95qVTzvK37q8skqu6svKvNvwY36NFCWwxEmj",
            "symbol": "KRMK",
            "name": "KremKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Coffeecan5577/KremCoin_Repo/main/KremCoin_Model.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "24ip6uLT9KmVdVd6DUydG6HMkmHM59Yxt851iCBWvyfQ",
            "symbol": "TREE",
            "name": "TreeCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ZettaTree/treecrypto/main/tree.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AwyP3rbLxexvpbNRp3Zm7EM7htLDqzHmysCxfMyUk47g",
            "symbol": "MIMM",
            "name": "Mimma Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mnarvaja/crypto/main/Screen%20Shot%202021-12-19%20at%201.57.19%20PM.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6ADxaYzyghezFcQVxarQ7wYvfiDvxwRRuvpLXbEiNxFm",
            "symbol": "Sgrios",
            "name": "Sgriosbh Scrivere",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DeMeoNaise/Sgrios/main/SGRIOS3.png",
            "tags": [
                "literary-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6PBEGe6YaKmDPw1Ebza823SuvQWQgGZ2NTANBgaKdxHq",
            "symbol": "GOLEM",
            "name": "Golem",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6PBEGe6YaKmDPw1Ebza823SuvQWQgGZ2NTANBgaKdxHq/logo.png",
            "tags": [
                "Community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BkT8smQpf2dw3V3BVoN4pMp6QEDNWKSqDDpLBj3WtNab",
            "symbol": "MXC",
            "name": "Max Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maximilianried/max-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6WaJfGoV2C5GsjjzkpsfPzkcadbXL1qVm3LGzS4fSBqs",
            "symbol": "CHAZ",
            "name": "Charlie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mr-sc0/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4sG8BMvgY3JwnX4EGUCPjWxpvB8hKASwNaM2z8wcQz8X",
            "symbol": "ICN",
            "name": "Icona",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/CryptoKnight360/Icona/main/icona_token.png",
            "tags": [
                "utility-token",
                "icona",
                "social-token"
            ],
            "extensions": {
                "website": "https://icona.eth.link"
            }
        },
        {
            "chainId": 101,
            "address": "CgHz25LxRxPxQuYYyyfPob8KT6Enqr2k7EA735jA9SNm",
            "symbol": "GC",
            "name": "Greedy Crocodiles Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/MrHibo/Token/main/Logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "H4aXNzXw3eZxfdKrAzGx9RERf3vbQMgMoRmGtsKtnPbg",
            "symbol": "PBRC",
            "name": "PobarCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/zlorgoncho1/pobarcoin/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SmartDevAfrica"
            }
        },
        {
            "chainId": 101,
            "address": "FW4wv9f6t112H5Fv4mRioKG4NshjwiXCfmYnVWKL5kpP",
            "symbol": "OPHI",
            "name": "Ophanim",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MartinSkerli/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GiDgqNhRnaKsbUW9KK7YiayobVAEZMnXN8X2NFEXQaJY",
            "symbol": "KWSQ",
            "name": "KwadSquad",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/x0ff001x0ff001/kwadsquadx0ff/main/kwadsquad.png",
            "tags": [
                "social-token",
                "KwadSquad"
            ]
        },
        {
            "chainId": 101,
            "address": "8m4kgdkqWooFTtJYvTzw3bZyaaoM4Bjp1Htwdwbqjasb",
            "symbol": "SKALA",
            "name": "Skala Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cgsnascar/crypto/main/taco-155812__340.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cnk1PySkhuS2NBUEbAe3fy8pBsfFzgTHDPmMCUxYq11J",
            "symbol": "JEFFZ",
            "name": "Jeffrey token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/minip91/jeff/main/logo-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Y7jWR3YMQZDmN6agqKjX4TMbXnQDYtSuVeyukcdi2KR",
            "symbol": "DFC",
            "name": "Deptford Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/deptfordio/coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EXFmJEbsc5CaG5Vz9S5bWP8qWELo3Vu3Bssi9pRFQHjQ",
            "symbol": "JOLY",
            "name": "JollyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/2420487/JollyCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4wkjHY2UnuDGPwwPDwVz6Sux7gW6zhkUT6fVvhsFJ2Ub",
            "symbol": "MATC",
            "name": "Metarts",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wkjHY2UnuDGPwwPDwVz6Sux7gW6zhkUT6fVvhsFJ2Ub/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9a7bTQWsGTCA7NE6hz3DSVxTBz9Ag7tdhE2e3Qrqt7E6",
            "symbol": "AC",
            "name": "Alpaca Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlpacaBi/AlpacaCoin/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "Alpaca Bi's Cryptocurrency"
            }
        },
        {
            "chainId": 101,
            "address": "DozWpi3Y3nzwFHmwzdsrzSxUQfN9NCH4BTbh5kJeGku3",
            "symbol": "WUN",
            "name": "WhaddupNow Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DozWpi3Y3nzwFHmwzdsrzSxUQfN9NCH4BTbh5kJeGku3/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "blog": "https://news.whaddupnow.com/",
                "website": "https://www.whaddupnow.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5d93mEXJP2LTNLtbysbayVdw86JtKGaSHKVb9jGkzDGB",
            "symbol": "ATHT",
            "name": "atheist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/guterboy67/crypto/main/athiest1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EuD5L5XSYKzyDC1YyYzmoWC8gmJhpEh2vMj4f8LeRW8r",
            "symbol": "NPC",
            "name": "NPC DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EuD5L5XSYKzyDC1YyYzmoWC8gmJhpEh2vMj4f8LeRW8r/logo.png",
            "tags": [
                "meme-token",
                "community-token",
                "fair-launch",
                "dao-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fDg529hq44",
                "telegram": "https://t.me/NPCDAO_official",
                "twitter": "https://twitter.com/NPCDAO_",
                "website": "https://npcdao.io"
            }
        },
        {
            "chainId": 101,
            "address": "EdW4dQkTZ3QRioGDKmFckJyELiPyh8VcwioAJWG7C4X5",
            "symbol": "SNDC",
            "name": "Special Needs and Disabilities Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/boostmagento/sol-sndc/main/logo.png",
            "tags": [
                "NFTs",
                "utility-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "39BGw1FfdTz8JmdwUp6m3HhXGZ15mJgjnMfrQHx2Ypx8",
            "symbol": "CAT",
            "name": "CloudAtlasTech Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/trekk/Cloud-Atlas-Tech-Coin/main/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7fnrumHA3xEYx95W14xbtN4RALCkTxCbuGYBkt7QvFyR",
            "symbol": "GBC",
            "name": "Gud Boi Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/bryarpatch36/GudBoiCoin/main/GudBoiCoin.png?token=AQESZD22TA4SIEVKN74X3FTBX7NU6",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2JSYv4xhjQBHtCr59q7Z6wVqA5miodCwEW5Hb7fb8vEk",
            "symbol": "KT",
            "name": "KenjiToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kenjisauce/Tokenkenji/main/tokenJI.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J8exTiSqWM7AMoXYdiEKnz3fk6FybL2voLEE7uaqfFGA",
            "symbol": "SHIB2",
            "name": "Shiba Inu 2.0",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shiba2-0/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "New projects coming!"
            }
        },
        {
            "chainId": 101,
            "address": "FMm7VB4wVBD13NKWZbK4QPiwjhKXyE3VxsHaepHZM9ve",
            "symbol": "TFT",
            "name": "TF Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dgon-jd/tf-crypto-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8jZUz6DHJMFHM7zoZ9UH2JBqAMKs3YdAXV812aTBk8U",
            "symbol": "NMT",
            "name": "NetworkingMaurijn Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Maurijn1455/NetworkingMaurijnCrypto/main/Logo-500x500-px2-1.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://networkingmaurijn.com"
            }
        },
        {
            "chainId": 101,
            "address": "J4tV8qjZyzwsYhGmPREDEyehCusPwa7uYm3UssQ6X4A8",
            "symbol": "GRUNT",
            "name": "GRUNT Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/derysendiry/orang/main/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/xsFxe2XV9z",
                "twitter": "https://twitter.com/goonygoblins",
                "website": "https://goonygoblins.com"
            }
        },
        {
            "chainId": 101,
            "address": "Dzsb9REsrxZ3cG6ucgvE1ATiWMawsRXeaDxDGZcL4gLx",
            "symbol": "AURM",
            "name": "Aurorium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dzsb9REsrxZ3cG6ucgvE1ATiWMawsRXeaDxDGZcL4gLx/logo.png",
            "tags": [
                "Game",
                "GameFi",
                "Metaverse"
            ],
            "extensions": {
                "description": "Questland Metaverse Game Token",
                "website": "https://questland.io"
            }
        },
        {
            "chainId": 101,
            "address": "9ivAYGAPBZNsTc1Egu8Fdu2R65mvSB2TSKGsfEwYuT6M",
            "symbol": "SMGC",
            "name": "SMG Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Timothy7555/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DK2ssmuPBrwBBGxcGiLe7uX32SFTQoWN7D8hVueymuV5",
            "symbol": "GEMZ",
            "name": "Gematria",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tomsclater/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7sFUYafvWaGKgDUyKviVKwLbCMxeZijaAiYPP4gB3fX",
            "symbol": "TEA",
            "name": "HonestTea",
            "decimals": 0,
            "logoURI": "https://github.com/CloudNomad/HonestTeaToken/blob/main/logo/TeaTokenBitPicMedium.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FSBAfYRaG2ZLch7fbBpNNtZbBRtT417Gb1WrUsHawNay",
            "symbol": "TRSH",
            "name": "TRASH COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TRSH-Coin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HWkPx2HBVSMCUPzHqf2HqCt4zUbGbauhZCBMzTYsZJ3Q",
            "symbol": "MWC",
            "name": "Mr Wonderful Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/trevoroleary/SolanaCoins/main/MrWonderfulCoin.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kevinolearytv",
                "website": "https://www.kevinoleary.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Tjs2thHW1uJXPeBh61iPjZCNeyAwsZFbm9zkbDB4JYZ",
            "symbol": "WXPT",
            "name": "Wireless Xperts Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ArberKadriu/WXP-Coin/main/WXP%20logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8fJJiHdwnqoVbR8ncALXcPQUwm8HWztDZPjKN4zWL5Wx",
            "symbol": "IGNFT8FJ",
            "name": "Instagram NFT SOLKITTYNFT 1",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8fJJiHdwnqoVbR8ncALXcPQUwm8HWztDZPjKN4zWL5Wx/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/8fJJiHdwnqoVbR8ncALXcPQUwm8HWztDZPjKN4zWL5Wx"
            }
        },
        {
            "chainId": 101,
            "address": "968saQ92NoC6zJN5eJfPmRQ52i7tt1uPVNKRHtmXU4gF",
            "symbol": "GASC",
            "name": "GasCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Gasgames/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GDspzEDR926EsGvb16Sa1xvgNtfVCEjke8A7MiKHkjXa",
            "symbol": "JLLY",
            "name": "JellyKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/antmananthony/crypto-/main/jellykoinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7CVKEVigLshD8hHtvJeyeg7WkBMDhviao2pombf6wBZ7",
            "symbol": "TPIF",
            "name": "TPIF Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ako5ta/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CZVZxuCWH7HhyBCzcNRDqFXRmee2S9rsNvWEectqmeHg",
            "symbol": "GRAPH",
            "name": "Grapheene Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CZVZxuCWH7HhyBCzcNRDqFXRmee2S9rsNvWEectqmeHg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://grapheene.com"
            }
        },
        {
            "chainId": 101,
            "address": "dFJCQkXjY4myq5x3doEz6cGVGrDN2RXTr9LqGm74FyW",
            "symbol": "DCZ",
            "name": "DigiCoinZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cubanitowolf/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FsmucuvSx62qKMiCHwRGyN9DcAKWdtsnhudsez45nev9",
            "symbol": "LZSB",
            "name": "Lazy Shiba",
            "decimals": 15,
            "logoURI": "https://raw.githubusercontent.com/Aniruddhchavda/lazyShiba/master/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2RejwZedYWpNY2GJGqjrbDbwwB63LtsSh3ioZmtUZiMD",
            "symbol": "Y13",
            "name": "YogsBUX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rayisken/Yogsbux/main/YOG%20LOGO.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ekjfRstw9JDNbXqXF4QeSzFmB55Ho4Y2aLNCjUiqADr",
            "symbol": "WISH",
            "name": "Genie Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/The0ne23/coin/main/geniewishtoken.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7vawu71vc4VeYtcfSGn5K9tGUgBhbRBtx1XHKksn9DiR",
            "symbol": "NIMA",
            "name": "Nimarena Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vawu71vc4VeYtcfSGn5K9tGUgBhbRBtx1XHKksn9DiR/logo.png",
            "tags": [
                "minable-token",
                "game-token",
                "metaverse-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2HTmf6SG39Jen3Gi3CThPQntDC1UfvjW4UHtoDE4B3wT",
            "symbol": "SOPH",
            "name": "SOPHIAP",
            "decimals": 0,
            "logoURI": "https://github.com/jplscnc/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CQbjDCxjuZGEBX4KVuX6dhiz8H7Dw1tp65k5o8zDNitB",
            "symbol": "FPVSWE",
            "name": "FPVSweden Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/x0ff101x0ff101/FPVSwedenToken/main/FPWSWE.png",
            "tags": [
                "social-token",
                "Facebook-Group"
            ],
            "extensions": {
                "website": "https://github.com/x0ff101x0ff101/FPVSwedenToken/blob/main/README.md"
            }
        },
        {
            "chainId": 101,
            "address": "2YbrpvGMaN9fQJZUKLGfzGsLUmQrEzYQtwa1GNCq5QeL",
            "symbol": "EMU",
            "name": "Emu Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Scoobydoohbruh/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EkzmEVHw8snd1B8LURvqQ4Pa59WF5eWRw7MyyFDqu8hx",
            "symbol": "FRFI",
            "name": "Freedom Finance",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/34078361/146739023-ce81f5b6-748c-4bce-9c37-230d79e94108.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zzdZx5aMfx",
                "twitter": "https://twitter.com/FreedomDAO_",
                "website": "https://freedom-dao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Fh7PGdaNgbWsHX7KpHaxqh2312fm3kYAiKgz4Jz8UcVA",
            "symbol": "SLRM",
            "name": "Solarium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fh7PGdaNgbWsHX7KpHaxqh2312fm3kYAiKgz4Jz8UcVA/logo.png",
            "tags": [
                "Game",
                "GameFi",
                "Metaverse"
            ],
            "extensions": {
                "description": "Questland Metaverse Game Token",
                "website": "https://questland.io"
            }
        },
        {
            "chainId": 101,
            "address": "2HTSv3kYtXRxLmSqqUQSXYvhkC4MaX7Kdm52RUKaEt6i",
            "symbol": "STRMSKR",
            "name": "Storm Seeker",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sinnx3/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9gmssDpdsg6e5hVSEXg97XBgB8kLDBuT2UAUGL8o2ynt",
            "symbol": "ZHBC",
            "name": "ZH Belch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZakBelchamber31/Crypto/main/ZHBC%20Logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DSAfgM5VG1g5xhHCUTsS39AHPPGiFkLhy4hV1oTQA2Kc",
            "symbol": "YRMUM",
            "name": "Your Mum Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZakBelchamber31/Crypto/main/Logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8mmtCb7gye6cyKS3GCV63pi9CKi8BdwR3zyUny3nXDQM",
            "symbol": "MOBY",
            "name": "Moby Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlexZeller/Moby-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HhswUjTDq9PNqnPfSP6hqYsfCk8HWRCy4gc7GKXjyUvC",
            "symbol": "SORO",
            "name": "SORORICO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/maximilianried/token-resources/main/sororico.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZwppVhZR7KEVnD5iRvtKNVVEJmjS7yHxkqGyawVn9Jt",
            "symbol": "MAX",
            "name": "Max Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/maximilianried/token-resources/main/max-token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DatzLBo5nbKHe5fQmQMRNYEUz3HQsrK1amaHYyFLVgg8",
            "symbol": "ZSK",
            "name": "ZSK Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Henzelix/lucky-number/master/logo-zsk.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/networkchuck",
                "website": "https://networkchuck.com",
                "youtube": "https://www.youtube.com/channel/UC9x0AN7BWHpCDHSm9NiJFJQ"
            }
        },
        {
            "chainId": 101,
            "address": "CxdbyB4y5T2WhrfT11y7N3K2jQAVDLHCFZMrvC8RKhfJ",
            "symbol": "BROST",
            "name": "BrunOst Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maxmalmberg/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ZP7ZAUUfM7mSkqdWNxUyeN1toMv8kE3QvdUkadQUrKZ",
            "symbol": "LOST",
            "name": "Slowly dying inside",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nMiyu/Lost/main/Lost.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bjw6uKN4RKpLJMBh84LfMRq9mTppFchQMCYiQra6crcV",
            "symbol": "HLJ",
            "name": "Ljevakovic Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hassman88/hassman88/main/hlj.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4",
            "symbol": "SOULO",
            "name": "SouloCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4/logo.png",
            "tags": [
                "utility-token",
                "NFT",
                "game-token",
                "GameFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/soulofox",
                "twitter": "https://twitter.com/soulofoxnft",
                "website": "https://app.soulofox.com"
            }
        },
        {
            "chainId": 101,
            "address": "Bjzj1YnTjENKAmY3pUUaMXSg4aoUHCJToWbz7MoaZsM8",
            "symbol": "RUGG",
            "name": "Real Ultramodern GPU Gold",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SetoriZen/RUGG/main/%23RUGG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7ioFQBJeUymYcA77hjBconsiDHLm8dZAKtbRcFinqdfX",
            "symbol": "FU",
            "name": "Fu Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/skunk-fu/sol-fu-coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://fucoin.me"
            }
        },
        {
            "chainId": 101,
            "address": "7NWewc3cxWWWwC1nEfRzbKxUpFjW2TapoCMTHXcpLUXF",
            "symbol": "HDTK",
            "name": "HeyDoll Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/huangxiaoming6688/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "217mKMvyGywmxyrurvo4Vt2jGhVXVhX81XaAgwK38cw7",
            "symbol": "KMKO",
            "name": "Kamiko Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ds2600/crypto_valy/kmko/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3a3M5JG4vdbw7L1vWEEWZqssBWZ4DksPiwy95Z6Z5JSr",
            "symbol": "GGN",
            "name": "Gargantuan",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gargantuanavatar/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FpXqUpigzx447tDv4iYX7M4aPZrMFTbiMdmuyjbEuUeH",
            "symbol": "OFS",
            "name": "OnChain FS",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/onchainfs/OFSMain/main/logo.png",
            "tags": [
                "financial-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A5UevXJdphkzXhRtTXj8JyoYYrWnkCLHVS986JHtRLyj",
            "symbol": "SOLID",
            "name": "Solid Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5UevXJdphkzXhRtTXj8JyoYYrWnkCLHVS986JHtRLyj/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "solid-protocol",
                "twitter": "https://twitter.com/ProtocolSolid",
                "website": "https://solidprotocol.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "3iV5uENJxg5LeuCvbiZy9ZAXBgp8ttqeuqyyqV1t67Ww",
            "symbol": "VUTS",
            "name": "VoutselasKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChristosVoutselas/VUTS/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NanxGmpuKwQvTrT8eKqmdVQgWJq9t1unCPhZb1mcWis",
            "symbol": "nanX",
            "name": "nanoX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NanxGmpuKwQvTrT8eKqmdVQgWJq9t1unCPhZb1mcWis/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3TwxDEZ2Ss",
                "github": "https://github.com/nanoX-finance",
                "medium": "https://medium.com/@nano.x",
                "reddit": "https://www.reddit.com/r/nanox_finance",
                "telegram": "https://t.me/nanoXspace",
                "twitter": "https://twitter.com/nanox_finance",
                "website": "https://www.nanx.finance",
                "youtube": "https://www.youtube.com/channel/UCSJrIzML8sTE3LsdIGKKuxg"
            }
        },
        {
            "chainId": 101,
            "address": "BntuckR32sWAziNtDQZg7RbQhHJZhLx5XpZtHkrpeEBx",
            "symbol": "SCTK",
            "name": "Solana City Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/leastgs/SCT/main/logo.png",
            "tags": [
                "utlity-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/c2Af3Ywq7a",
                "twitter": "https://twitter.com/SolcitizensNfts"
            }
        },
        {
            "chainId": 101,
            "address": "9DP4uBEYXYBpUEWdPJ2pGu43wVoAd1Xniok345LkwY49",
            "symbol": "GAPP",
            "name": "GappiesCoin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/gavinhartog/gappies/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4NUoCXBsCVUXPyQL3UmMU3dRUZ3WNQgY1USC7eAY8zSG",
            "symbol": "$CREDITS",
            "name": "Neon Game Credits",
            "decimals": 5,
            "logoURI": "https://rxvwsth6k2wkjbpgqixi4db3ekvlailhvm2xa4pcmqzl6dtctqwa.arweave.net/jetpTP5WrKSF5oIujgw7IqqwIWerNXBx4mQyvw5inCw",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://neon.game"
            }
        },
        {
            "chainId": 101,
            "address": "ADehsNVsuXwdQ5L6JT4YpZrmLumDQ3f1ar2VxBaR3TaL",
            "symbol": "RUM",
            "name": "RUM",
            "decimals": 6,
            "logoURI": "https://github.com/charliebakerr/psy-token/blob/f8d05bf0865582bc9d131cb77ba0e51f953f26ab/RUM.png"
        },
        {
            "chainId": 101,
            "address": "ECz3bSRcnziQ17oXa2ENxiGD76EdZy54kXLfUNWHSb5C",
            "symbol": "EPM",
            "name": "Easy Pocket Money",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/dcmgcm/EPM-Token/main/epm-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6QXq7LYfHEn8HMB6WpHvnnHE84Tpg6ciJQpe7Xn8sYkT",
            "symbol": "COLVO",
            "name": "Colibri Voice",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/davidmontoya60/Crypto-Colibri/main/Colibri.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5B7xf8nrKjyAqrJemjG6ArH2B4ZGN7UbjvW8AuL3dW9d",
            "symbol": "BFLP",
            "name": "Big Floppa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/volk-blip/crypto/main/BigFloppaLOGO-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FmpqmVcT4hSUfNhach31YUpf6M75bBYaC59JLMyCVNHH",
            "symbol": "GOOSE",
            "name": "Mongoose",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FmpqmVcT4hSUfNhach31YUpf6M75bBYaC59JLMyCVNHH/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BeqFvEaBWjKUt7ojbWVEytyDTLcDFzXSk25ndZiTDhi4",
            "symbol": "MATCHA",
            "name": "Matcha Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MayMeow/matcha-koin/main/matcha.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ApUXq6ipGZz3kSqSJJx4Kvp8xikwaT9yu5iAaQ9n7gft",
            "symbol": "MUSIC",
            "name": "Playmusic",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApUXq6ipGZz3kSqSJJx4Kvp8xikwaT9yu5iAaQ9n7gft/logo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Ewg5EvQzrQXnPDQ6Fe8QwWFSBYZCkwXDBkP5vwATZMM",
            "symbol": "BAHI.log",
            "name": "CGMB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sulman2244/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7NJwPgfE1YA1UP5k6mF2tYgLJg65SsRvPz9pxb2sKgLW",
            "symbol": "BABYXTAG",
            "name": "BABY XTAG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7NJwPgfE1YA1UP5k6mF2tYgLJg65SsRvPz9pxb2sKgLW/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/xhashtagio",
                "website": "https://www.xhashtag.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DPyPuX68Uop66MK9nKT1TsWMA2jayu2vKVoWK2RKPdjp",
            "symbol": "VBC",
            "name": "VBuck",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yarak73/crypto/main/vbuck.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fmw929Yn292BNvy4ZRQctbtv2n6KrynhjGHAvZmhEwM6",
            "symbol": "NATH",
            "name": "Natoha",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/STH57/cryptoNath/main/Natoha.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GqiHtXj18aaWs82UBz7D72x934EQLZNhpeUpfJwS3Scm",
            "symbol": "FLYZ",
            "name": "Cyber Flyz",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/93989494/146803309-9886040f-2e3c-46c0-bd57-69e15f088ac5.png"
        },
        {
            "chainId": 101,
            "address": "6iu6uaRvb78P68M3k3kPCh6XPw7h3r7SrPL7ggkBPVM3",
            "symbol": "ANGC",
            "name": "Angel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SirSimon04/angel-coin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HCHs1Kbp4Fn38nLrcnT1VLQ4CuLQPj6jZTGhG75maS9Y",
            "symbol": "PMC",
            "name": "PRIMARKCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Freemefromtheratrace/Primarkcoin/main/FSDFSF.png",
            "tags": [
                "social-token",
                "Utility-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "2zNzxqstigWAt5iUC51FtX8Ehixx4PgTZ3bXZx1x832r",
            "symbol": "GIRAF",
            "name": "Giraffe Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2zNzxqstigWAt5iUC51FtX8Ehixx4PgTZ3bXZx1x832r/logo.png",
            "tags": [
                "utility-token",
                "meme-coin",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiemGxqkm284CZVZcvk8wfybmRK4JWmprPtrUamuLndE",
            "symbol": "DIEM",
            "name": "GOFAR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiemGxqkm284CZVZcvk8wfybmRK4JWmprPtrUamuLndE/logo.png",
            "tags": [
                "utility-token",
                "travel-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thisiscarpedm",
                "website": "https://thisiscarpedm.com"
            }
        },
        {
            "chainId": 101,
            "address": "Fy2rYwPtbk3hHuzFFCtFaKT2NaRdhrovpytroecF95wL",
            "symbol": "PHLEK",
            "name": "Phlek",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/raivolalala/phlek/main/phlek.jpg",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "3pbhmoKkg9c5mokBqpPFdDMDczHL68X1VnxGm185KtBZ",
            "symbol": "PNK",
            "name": "Pinsky",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pinmymind/cryptotoken/main/pinsky.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7uNsGMMiLcdsZ7ipfNYPtwpcMrihHRedgBPAfstuGpTE",
            "symbol": "RBBK",
            "name": "RollingBallButcherKnives",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MasterofNunya/crypto/main/RBBK_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "36s9cAKFqea4gGFwAjG92Aoo3Zx5L3AbqBB519QeKsZU",
            "symbol": "ALF",
            "name": "AlfProtocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/36s9cAKFqea4gGFwAjG92Aoo3Zx5L3AbqBB519QeKsZU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/alfprotocol",
                "telegram": "https://t.me/alfprotocol",
                "twitter": "https://twitter.com/AlfProtocol",
                "website": "https://alfprotocol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4Wk4qLfLEXFTJqH9zn2LBqccorX2K2rjV9UwyujjUByW",
            "symbol": "DHAN",
            "name": "Dhancoin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/dhancoinofficial/token-list/main/assets/mainnet/4Wk4qLfLEXFTJqH9zn2LBqccorX2K2rjV9UwyujjUByW/logo.jpg",
            "tags": [
                "Social-token",
                "Indian-token",
                "Desi-coin"
            ],
            "extensions": {
                "description": "crypto asset for the indian metaverse"
            }
        },
        {
            "chainId": 103,
            "address": "4FTVzy4yrfbFy5ootGSU5GfhqUReg5GwGYdCSwwDwFoM",
            "symbol": "TSTO",
            "name": "Tsaki Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4FTVzy4yrfbFy5ootGSU5GfhqUReg5GwGYdCSwwDwFoM/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BuqmkJAkjsGNBe9sYa3mmbhTnf7CErE3xiEGZszxttb9",
            "symbol": "WEEL",
            "name": "Wheel",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wfmayer/wheel/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "EDPXuih9YfsnSYyiWss4okGGrPCmCLLNTC8hM3yw85Wt",
            "symbol": "ART",
            "name": "Art Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EDPXuih9YfsnSYyiWss4okGGrPCmCLLNTC8hM3yw85Wt/logo.png",
            "tags": [
                "utility-token",
                "art-token",
                "community-token",
                "meta-verse"
            ]
        },
        {
            "chainId": 101,
            "address": "ZRbe8C4rNPt5g9W9YovyuhpcEQzUcMV2bxy9moLSz7z",
            "symbol": "DEGO",
            "name": "DiegoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pcapazzi/DiegoCoin/main/diego.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "UDLEC7bkec8x6HonzFAAYotJew4EnMVvhqCxL5k6jjf",
            "symbol": "EKU",
            "name": "EKU Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UDLEC7bkec8x6HonzFAAYotJew4EnMVvhqCxL5k6jjf/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "meebAU3nZrU5PbUt3dVK6ExgbNWCUAkV7C3DaJKMZZ4",
            "symbol": "MEEB",
            "name": "Meeb Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/meebAU3nZrU5PbUt3dVK6ExgbNWCUAkV7C3DaJKMZZ4/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/amoebits",
                "twitter": "https://twitter.com/Amoebits",
                "website": "https://amoebits.io"
            }
        },
        {
            "chainId": 101,
            "address": "4DSym9K22MV1b9eAdA5udXAD2hWTpAHGhFNhgXAQRqph",
            "symbol": "HDC",
            "name": "HeyDoll Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/heavenframe2021/crypto-heydoll/main/LOGO%20HDTK.png",
            "tags": [
                "Social-token"
            ],
            "extensions": {
                "description": "crypto asset for the indian metaverse"
            }
        },
        {
            "chainId": 101,
            "address": "3cqGYMGgpi6DFWvPzNmsqmqS1WNeiPzgEPLKyNqJwJ3R",
            "symbol": "BERM",
            "name": "BermudaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DiamondBar9/BERM/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ANXwRA8fC8o1hsPESYSPtqCwaFduXZXzAWRToy9jG6h3",
            "symbol": "KEMP",
            "name": "KempKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dcden89/KempKoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9J3z3GDpzscKJ1evATZdE9qLDwWVqdh6yeYCwqE7cmGH",
            "symbol": "BASEC",
            "name": "Base Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/marcelbpcloud/basecoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ceHfJ8aFd5C7GrMkLQ9ht6cGuZECQddSXuGdowK6gdS",
            "symbol": "AOVT",
            "name": "Age of Vikings",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ceHfJ8aFd5C7GrMkLQ9ht6cGuZECQddSXuGdowK6gdS/logo.png",
            "tags": [
                "utility-token",
                "currency-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3RgXd92vgDor1Fz8DcFvMqXahz3rj4HQbnkuAyPLqsuT",
            "symbol": "MTN",
            "name": "Metanium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3RgXd92vgDor1Fz8DcFvMqXahz3rj4HQbnkuAyPLqsuT/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Metanium is a Token that was created with the aim of helping people in financial difficulty, we are committed to making donations to people in precarious situations."
            }
        },
        {
            "chainId": 101,
            "address": "3VjifLwGK9tNqDtnjDe6yF7C7V3BodPuQhk6cJyeQ33u",
            "symbol": "RLTY",
            "name": "Royalty Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BatmanSTRMC/crypto/main/rcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4CwuSUuswvPfgciVrD4GMs1gkxUGfQ9TnscGHY78ugYJ",
            "symbol": "SQC",
            "name": "Samcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SamQuailCoin/SQC-logo/main/samcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HfsvmwJtXVkExnigHzvry42shBvNWk55ndmVHCxdXEbJ",
            "symbol": "SIVI",
            "name": "Soba Coin",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/t4xXJCA.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "We can help you turn your content into extra income and pursue more of what you love.",
                "website": "https://siva.vip/"
            }
        },
        {
            "chainId": 101,
            "address": "9UEHkfF2G3DfBQVBz1DCMbQzVQwpcqnVvtrQHhHhNChR",
            "symbol": "SOLMEOW",
            "name": "Solana Meow Meow",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solmeowmeow/sol-meow-meow-coin/main/solmeowmeow.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "description": "Solana Meow Meow coin is dedicated to cats around the world!",
                "twitter": "https://twitter.com/solmeowmeow",
                "website": "https://github.com/solmeowmeow/sol-meow-meow-coin"
            }
        },
        {
            "chainId": 101,
            "address": "EKyEgieKeqssdhQVT3vuzz6uQhX6Q537TMuJgn9W6KVx",
            "symbol": "AMTP",
            "name": "AM Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/am-3p/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F1cuHRYZnALDAxJpqPfaZAJheeGuzeFfXk5csK58hh5d",
            "symbol": "DRMC",
            "name": "Drime Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Itz-Ethan/drmcrypto/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "description": "Drime Coin is a community token that makes investing in crypto fun and easy.",
                "twitter": "https://twitter.com/Ethan_Itz",
                "youtube": "https://www.youtube.com/channel/UCDg-Utvcsp5L05hB7C1_zgA"
            }
        },
        {
            "chainId": 101,
            "address": "DtPDh3wGGbs6vdVQV8wGfLuQzCsJUG21cqfmNk3f4ox5",
            "symbol": "NU3C",
            "name": "NIU3 COIN",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/heavenframe2021/crypto-niu3/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "crypto asset of Ken Liu"
            }
        },
        {
            "chainId": 101,
            "address": "8sZWFhn8nv5cpzLSCSrCcqwdgCKnf2syhHvJ9SQCEpfd",
            "symbol": "CCKN",
            "name": "CarusoCraft Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bcaruso22/crypto/main/cccoin_logo-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DeNXzrqbCxhi8xMXsnyrPGdWxrgSb2X82Aa2FL2yKmot",
            "symbol": "raff",
            "name": "RaffBit",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/nasher207/cripto-stuff/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9mnngHc1pAt5x6vHz4JRLexVWjGk9j7eJWs9QkJzh9RH",
            "symbol": "jm",
            "name": "JackCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/nasher207/cripto-stuff/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiEZQ5dYjLZnW8ABCna8KoZU8QCL1UMvwgF4BPCKey7t",
            "symbol": "EDHK",
            "name": "EDHKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shonen787/crypto/main/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6RfRLkMZjW4PhXGezHE43njrEYSpYKEBMRtja4XzyeNp",
            "symbol": "PINTO21",
            "name": "Pinto 21 Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/npinto21/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4N1nzo4rAFHGHvAm7ioE7dHZarxVNJpKemjSC45KXAxe",
            "symbol": "BELL",
            "name": "Michelle Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kerouacwannabe/crypto/main/bell-480-458437.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "description": "Michelle, ma belle, These are words that go together well, My Michelle"
            }
        },
        {
            "chainId": 101,
            "address": "J95KxdcBUgG7V4ecrtN4GLxZEz9GESbeLsEMyD8N6yX3",
            "symbol": "WHAT",
            "name": "WHAT Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J95KxdcBUgG7V4ecrtN4GLxZEz9GESbeLsEMyD8N6yX3/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DA4zFZot5fEcuKSNPbeYEhNQ6xP9jUbK9akXGAfG7FVq",
            "symbol": "BLCS",
            "name": "Black Lotus Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/obiwonjacoby/Black_Lotus/main/Black_Lotus_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "85d7BpwfBJNurB6t4S4vN1x4vMaDPjWNiMetWWco2vUe",
            "symbol": "VIPR",
            "name": "Viper",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/viperguy07/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75w8S3Du1Rd4KphUYiusWavqt1EUoeVKTphhrfgqPCeU",
            "symbol": "IGNFTWSD",
            "name": "Instagram NFT WSD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75w8S3Du1Rd4KphUYiusWavqt1EUoeVKTphhrfgqPCeU/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/75w8S3Du1Rd4KphUYiusWavqt1EUoeVKTphhrfgqPCeU"
            }
        },
        {
            "chainId": 101,
            "address": "HD2oWUXqnLfXqpR4giDndZisEeqVq7EZbCTBgjAEdDVH",
            "symbol": "IGNFTHDO",
            "name": "Instagram NFT HDO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HD2oWUXqnLfXqpR4giDndZisEeqVq7EZbCTBgjAEdDVH/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/HD2oWUXqnLfXqpR4giDndZisEeqVq7EZbCTBgjAEdDVH"
            }
        },
        {
            "chainId": 101,
            "address": "DzAr46UdBPeEamVRwUb6HR3cKJyzEunMia45cAQv5Pd",
            "symbol": "IGNFTDZA",
            "name": "Instagram NFT DZA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DzAr46UdBPeEamVRwUb6HR3cKJyzEunMia45cAQv5Pd/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/DzAr46UdBPeEamVRwUb6HR3cKJyzEunMia45cAQv5Pd"
            }
        },
        {
            "chainId": 101,
            "address": "CpSYD5rJE8coHfBQyWYAFpE1Rm5YvgGbnF46cvemBLPx",
            "symbol": "IGNFTCPS",
            "name": "Instagram NFT CPS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CpSYD5rJE8coHfBQyWYAFpE1Rm5YvgGbnF46cvemBLPx/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/CpSYD5rJE8coHfBQyWYAFpE1Rm5YvgGbnF46cvemBLPx"
            }
        },
        {
            "chainId": 101,
            "address": "HoU6QHcbNwUEn5emrSrZaM3HcwV6dZaA2vUJoUbAzQkP",
            "symbol": "IGNFTHOU",
            "name": "Instagram NFT HOU",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HoU6QHcbNwUEn5emrSrZaM3HcwV6dZaA2vUJoUbAzQkP/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/HoU6QHcbNwUEn5emrSrZaM3HcwV6dZaA2vUJoUbAzQkP"
            }
        },
        {
            "chainId": 101,
            "address": "3eEwnZksgg8mADgSyLzcMQQyVzt2ZCGyQxNet7wcyhBZ",
            "symbol": "IGNFTEEW",
            "name": "Instagram NFT EEW",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3eEwnZksgg8mADgSyLzcMQQyVzt2ZCGyQxNet7wcyhBZ/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/3eEwnZksgg8mADgSyLzcMQQyVzt2ZCGyQxNet7wcyhBZ"
            }
        },
        {
            "chainId": 101,
            "address": "E4P45hsc4WJ61EkJkoKg4HRps68WhnfBMqDWvNYBAVZ7",
            "symbol": "IGNFTEPH",
            "name": "Instagram NFT EPH",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E4P45hsc4WJ61EkJkoKg4HRps68WhnfBMqDWvNYBAVZ7/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/E4P45hsc4WJ61EkJkoKg4HRps68WhnfBMqDWvNYBAVZ7"
            }
        },
        {
            "chainId": 101,
            "address": "7WWYJ6xZLDDU8aVw5e8F3Xvp3QxRYJWMFhKLBUsMMx1z",
            "symbol": "IGNFTWWY",
            "name": "Instagram NFT WWY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7WWYJ6xZLDDU8aVw5e8F3Xvp3QxRYJWMFhKLBUsMMx1z/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/7WWYJ6xZLDDU8aVw5e8F3Xvp3QxRYJWMFhKLBUsMMx1z"
            }
        },
        {
            "chainId": 101,
            "address": "8UVQCYwBHGhHWeNZvYwCE8WcGmCpzA8AgxP2FaCUn3H4",
            "symbol": "IGNFTUVQ",
            "name": "Instagram NFT UVQ",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UVQCYwBHGhHWeNZvYwCE8WcGmCpzA8AgxP2FaCUn3H4/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/8UVQCYwBHGhHWeNZvYwCE8WcGmCpzA8AgxP2FaCUn3H4"
            }
        },
        {
            "chainId": 101,
            "address": "Akgos26wxMj1XDoY9uojm4KMsALT87QtgbZEB2GENH8X",
            "symbol": "IGNFTAKG",
            "name": "Instagram NFT AKG",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Akgos26wxMj1XDoY9uojm4KMsALT87QtgbZEB2GENH8X/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/Akgos26wxMj1XDoY9uojm4KMsALT87QtgbZEB2GENH8X"
            }
        },
        {
            "chainId": 101,
            "address": "78yaZuBb6qmFec4Jz59MMLtdDu5wBMzKsXaQAWXjpALF",
            "symbol": "IGNFTYAZ",
            "name": "Instagram NFT YAZ",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78yaZuBb6qmFec4Jz59MMLtdDu5wBMzKsXaQAWXjpALF/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/78yaZuBb6qmFec4Jz59MMLtdDu5wBMzKsXaQAWXjpALF"
            }
        },
        {
            "chainId": 101,
            "address": "4iPKfHxmYX29fr5nnXdNifA52GJKokWeRmQ67hApGySJ",
            "symbol": "IGNFTIPK",
            "name": "Instagram NFT IPK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4iPKfHxmYX29fr5nnXdNifA52GJKokWeRmQ67hApGySJ/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/4iPKfHxmYX29fr5nnXdNifA52GJKokWeRmQ67hApGySJ"
            }
        },
        {
            "chainId": 101,
            "address": "DT4omrwKUL4cWRjNoBSkbqAhxyexeJjXnXEGCHJFEPGT",
            "symbol": "IGNFTDTO",
            "name": "Instagram NFT SOLKITTYNFT 2",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DT4omrwKUL4cWRjNoBSkbqAhxyexeJjXnXEGCHJFEPGT/logo.png",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/DT4omrwKUL4cWRjNoBSkbqAhxyexeJjXnXEGCHJFEPGT"
            }
        },
        {
            "chainId": 101,
            "address": "7dnB3tTmjeS1rAdeVB8CBkcNTDZQeuBoSvg17woVrs6n",
            "symbol": "IGNFTDNB",
            "name": "Instagram NFT SOLKITTYNFT 3",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dnB3tTmjeS1rAdeVB8CBkcNTDZQeuBoSvg17woVrs6n/logo.png",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/7dnB3tTmjeS1rAdeVB8CBkcNTDZQeuBoSvg17woVrs6n"
            }
        },
        {
            "chainId": 101,
            "address": "46E32Wnki9A1Labyq8Y2kCE58NWoLVdJhnMh3Cg5pDj3",
            "symbol": "KGC",
            "name": "Knife Guys Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Tim-Zahler/KnifeGuysCoin/main/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "55EZVKbY9u9vdouob88QiKQ4g9nfYUoAu7aWQUiuQ8pc",
            "symbol": "ZKB",
            "name": "KolaZoin",
            "decimals": 0,
            "logoURI": "https://github.com/Aceboone011/cryptoZKB/blob/9232cad70e918c9f02b46f4d2b287af0dc6dffa3/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fse2oFDfbwT89CqtuoFaHCBnGTMFLartDYDjPLZyc7e",
            "symbol": "TRPY",
            "name": "Trippy Leaf",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fse2oFDfbwT89CqtuoFaHCBnGTMFLartDYDjPLZyc7e/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "general-token"
            ],
            "extensions": {
                "serumV3Usdc": "3N4UgbUHUkwMU5DKFYRvz3HASbFjs3q9JLAYVRdEQvbp",
                "twitter": "https://twitter.com/Real_TrippyLeaf",
                "website": "https://trippyleaf.com"
            }
        },
        {
            "chainId": 101,
            "address": "3fL38iNdhYHYhN94yX59DVDPqbcXaa7CmHjzbpUUaEG3",
            "symbol": "MKJF",
            "name": "MKJFamily Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alfaquad/crypto/main/mkjf-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7myzfdENcWPcbdcMrGcNHjpmnoMrpDp455qJ1imifsm5",
            "symbol": "CTCN",
            "name": "Curit Coin",
            "decimals": 0,
            "logoURI": "https://github.com/acurit1/crypto/blob/main/CuritCoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9WJiJAsTy54df2tYhF9eXrrC6L5E8hdV9qDVQ5dCVC7u",
            "symbol": "DYNO",
            "name": "Dynocoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lilshoota/token-list/main/assets/mainnet/9WJiJAsTy54df2tYhF9eXrrC6L5E8hdV9qDVQ5dCVC7u/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "description": "Dyno is a token I developed for fun. Maybe will be purposed eventually, but invest if you'd like. -Dev"
            }
        },
        {
            "chainId": 101,
            "address": "6xQFz3z8paL5Z2a3HscQQWhVPqhGo9ff4kYEsXC7Q2VG",
            "symbol": "FUN",
            "name": "The Fun Coupon",
            "decimals": 9,
            "logoURI": "https://github.dev/Keoki-TFC/token-list/blob/2f13b2bca784f12ac2e2525e66759b8b9a617af4/assets/mainnet/6xQFz3z8paL5Z2a3HscQQWhVPqhGo9ff4kYEsXC7Q2VG/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8cgJdvtxKYRJ4xmjGFUmF2jncsvmrjjvcnmFUQRB7Rrd",
            "symbol": "MSM",
            "name": "SAMCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/salik111/crypto/main/Drawing.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "76ijxiMkj4DX8q9QMtqpzTxFnT4KPmWv47sZf2kKoVwk",
            "symbol": "QF",
            "name": "QuietFire",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/76ijxiMkj4DX8q9QMtqpzTxFnT4KPmWv47sZf2kKoVwk/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/anderlaxe"
            }
        },
        {
            "chainId": 101,
            "address": "DySz4HGJnnHeRGzbRR3EBEfJwiZWgdWdCDKnRVuMxbKN",
            "symbol": "SVE",
            "name": "Eidelstedt Gorillas",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bumblebeeman85/Gorillas/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9f8tPib2mY7bjoXhxTmpJs3N2weK6tCwmgE9VrhgQF2w",
            "symbol": "TFFC",
            "name": "TaygaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Qctsu/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5SBKK5btXjeaS8w96eCCopwCHQd1g4hbckegtV4MkJhR",
            "symbol": "Wen",
            "name": "Wen Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SBKK5btXjeaS8w96eCCopwCHQd1g4hbckegtV4MkJhR/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/EMWVVXQnMy",
                "twitter": "https://twitter.com/notifbutwen",
                "website": "https://notifbutwen.io"
            }
        },
        {
            "chainId": 101,
            "address": "4FooynMvMtNhowDWP1X2AvTu8LkkTHWhZrsPuV9x2CJ2",
            "symbol": "ax0mg",
            "name": "ax0mg",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jeff14994/crypto-currency/main/logo.jpg",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4c3tzb1JZtgsaYrWTcRcjh8eoDfUPSUCQCH5VoampniQ",
            "symbol": "LALAC",
            "name": "LaLa Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/frederic-mamath/lala-coin/main/lala-snow.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9kALxjX6MvdaWDzWU5Th1LUJ1QT3iH8TXkuc98oV6R5v",
            "symbol": "HOOD",
            "name": "Hoodie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/therobertc/HoodieCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8KzT4VfvzULfbyAE8PS7qzD3zNv6v2Bb4sKMx2v4Qu8e",
            "symbol": "DTC",
            "name": "Dintcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dintapp/dintcoin/main/dintcoin-logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "general-token"
            ],
            "extensions": {
                "description": "Dintcoin is a decentralized global payments infrastructure that enables greater economic opportunity for everyone, everywhere.",
                "instagram": "https://instagram.com/dint",
                "twitter": "https://twitter.com/dint",
                "website": "https://dint.com"
            }
        },
        {
            "chainId": 101,
            "address": "2DKqMEPNGDuooM8ZydBQAJSPK695mNjC8KKUfJdzHe8A",
            "symbol": "FNR",
            "name": "Fenero",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jrpt201/crypto/main/fenero1.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "43oyjvvQkb6ypgdC28UGg2jrYtXwe7runGn64Vvdy79L",
            "symbol": "AGC",
            "name": "AGC Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43oyjvvQkb6ypgdC28UGg2jrYtXwe7runGn64Vvdy79L/logo.png",
            "tags": [
                "AGC-Token"
            ],
            "extensions": {
                "website": "https://www.agcmeta.com"
            }
        },
        {
            "chainId": 103,
            "address": "BcRr96qhSoaKFjGJDKtSWmHDvTrv7ziuq29dRjtUmHuk",
            "symbol": "NNI",
            "name": "Neonomad Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BcRr96qhSoaKFjGJDKtSWmHDvTrv7ziuq29dRjtUmHuk/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SoLEsRCGqG8zDKYyMt84xJdsNLJKx7vG5ZmCpKqt3rg",
            "symbol": "SOLE",
            "name": "Solare",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLEsRCGqG8zDKYyMt84xJdsNLJKx7vG5ZmCpKqt3rg/logo.png",
            "tags": [
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolareItalia",
                "website": "https://www.solare.digital"
            }
        },
        {
            "chainId": 101,
            "address": "8fd5eUPMNHuyKRshFbfmKRAm2gowJ75m8WjT7tLio6J3",
            "symbol": "DLN",
            "name": "Goekdln",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8fd5eUPMNHuyKRshFbfmKRAm2gowJ75m8WjT7tLio6J3/logo.svg",
            "tags": [
                "community-token",
                "meme-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "We are based on a Decentralized community token which aims to make peer-to-peer exchanges thanks to blockchain technology.",
                "facebook": "https://facebook.com/goekdln",
                "instagram": "https://www.instagram.com/goekdln",
                "medium": "https://medium.com/@goekdln",
                "reddit": "https://www.reddit.com/user/goekdln",
                "telegram": "https://t.me/goekdln",
                "twitter": "https://twitter.com/goekdln",
                "website": "https://www.goekdln.com"
            }
        },
        {
            "chainId": 101,
            "address": "Dh2WZdzcpoGuYgwbRUqt9feBwNiwkE6yg38zNSuV3Kmv",
            "symbol": "WAM",
            "name": "WAM Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dh2WZdzcpoGuYgwbRUqt9feBwNiwkE6yg38zNSuV3Kmv/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LHfna4RxdkPFqCqoQySPuKTxwGuyHNR2jUs3eRWWeL5",
            "symbol": "XXX",
            "name": "Wet Spot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DarkishHero/Crypto/main/Logo-min.png",
            "tags": [
                "social-token",
                "XXX"
            ]
        },
        {
            "chainId": 101,
            "address": "ApfnGbPxNJz48tC5GYnVL5nabb3yHGunfUwo5ufzpJKS",
            "symbol": "BRC",
            "name": "BrexCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DavidPackard/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LXTW9ngoMgUnW7dbUSqaS9HCwua6CJbkQ3bhJhh6RxV",
            "symbol": "LXT",
            "name": "Lamport Express Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LXTW9ngoMgUnW7dbUSqaS9HCwua6CJbkQ3bhJhh6RxV/logo.png",
            "extensions": {
                "serumV3Usdc": "CvSvtVVFywPm6mMA4ZkDT5iMZPSRZTca9LwFLcqqs2FU",
                "website": "https://lamportexpress.com"
            }
        },
        {
            "chainId": 101,
            "address": "FpPg6FiFJbwXESqW53ynhVWMy5wEZGevyi7mJWYmvwu3",
            "symbol": "HERO",
            "name": "Hero",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HERO-TOKEN/HERO/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BUsHHSHFVvqqJ29REdmvgpVqjDrvFwmynoF5z65gD9Z9",
            "symbol": "BCBB",
            "name": "Batcave",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bryansbatcave/crypto/main/batmanlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ANXqXpSkTEuCnR27YK2AoHNH2CCbiSaKYAKcDQVMi6ar",
            "symbol": "MTK",
            "name": "MetaToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANXqXpSkTEuCnR27YK2AoHNH2CCbiSaKYAKcDQVMi6ar/logo.png"
        },
        {
            "chainId": 101,
            "address": "2U9uvvhhA7z1PQ7wTsdUoXAJZxqaEJBb2B5iL9ydJSTd",
            "symbol": "SLTR",
            "name": "Saltire",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nottanudder/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SQRNmMb9mKjjkihQS7fCmAwo3gVs1FSQBVeDZzA7CP3",
            "symbol": "SQR",
            "name": "Magic Square Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SQRNmMb9mKjjkihQS7fCmAwo3gVs1FSQBVeDZzA7CP3/logo.svg",
            "extensions": {
                "discord": "https://discord.com/invite/u9SHXU9qNd",
                "medium": "https://medium.com/@magicsquare",
                "telegram": "https://t.me/Magicsquareio",
                "twitter": "https://twitter.com/MagicSquareio",
                "website": "https://magicsquare.io/",
                "youtube": "https://www.youtube.com/channel/UCJjHbHzZXe0T5gcn97baIbA"
            }
        },
        {
            "chainId": 101,
            "address": "HnWboZGwwEmzKtivLtYaFVKytrjuHtSzdZGQQjSTeryg",
            "symbol": "VIS",
            "name": "Visibility",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/fracchiomon/visibility/main/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "art-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HcFWXPerXxRh779QWPu3CycqsvKTwcQDqgU1kLBZWKj2",
            "symbol": "VOON",
            "name": "voonCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/esto87/hi/main/logo_voon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JEHHZr57hJ7By3dL74HB9G9R77ZrTvDr1P2vSjQCAewF",
            "symbol": "SWIM",
            "name": "SWIM",
            "decimals": 9,
            "logoURI": "https://arweave.net/x5q9Se-3YMgTRaZg8tfHRaQVnGnMbAw7Jd6F-I9fplE?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/SolAquariums"
            }
        },
        {
            "chainId": 101,
            "address": "EsZfrs1iWh9CXzbpGUfUkRfs35KkUWXJH2S6Y9D272M",
            "symbol": "LDT",
            "name": "Land Space Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsZfrs1iWh9CXzbpGUfUkRfs35KkUWXJH2S6Y9D272M/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GE119PjMNeRysQhLmdwLj4vgoRUHrYnu9p1VBnM35tiB",
            "symbol": "CAPS",
            "name": "NukedCaps",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Xcelery/CAPS/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GuZkRNyvp1ghmSK19Yje2z3CyxgfABcNqjjMT2RAFfvp",
            "symbol": "GLCK",
            "name": "GluckCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dg1371/crypt1/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x",
            "symbol": "PRISM",
            "name": "PRISM",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x/logo.svg",
            "tags": [
                "utility-token",
                "DeFi",
                "aggregator",
                "governance-token"
            ],
            "extensions": {
                "coingeckoId": "prism",
                "description": "Solana's DEX Aggregator",
                "discord": "https://discord.gg/prism-ag",
                "serumV3Usdc": "2MvXnxngd1gKp6gE8Q63wiPHSpveWcx8x4wf43VpyiA6",
                "twitter": "https://twitter.com/prism_ag",
                "website": "https://prism.ag"
            }
        },
        {
            "chainId": 101,
            "address": "GpGR2QqDf4Yxkuas3rYRHYdDWSmqk8auHiRdmMicQi2a",
            "symbol": "NASC",
            "name": "Nasc",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpGR2QqDf4Yxkuas3rYRHYdDWSmqk8auHiRdmMicQi2a/logo.png"
        },
        {
            "chainId": 101,
            "address": "6eNuHDcJrPL8RzSsHiEYJh6QonEPw7NuzoumaCvQYQxa",
            "symbol": "SNZY",
            "name": "SnazzyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/p4rk1ns/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GVvC9ejTXECjt9gCBDvVnrc3v2bGGUzAUbPiSKETjYjx",
            "symbol": "YLRS",
            "name": "YellowRose",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jms2004/crypto/main/LogoS.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8SCr4eoHCeZKA39hq1GwKuXNzm2MERT2aQEKpQpvc3jb",
            "symbol": "OXG",
            "name": "Oxgrod",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzHDi6bKVKLqJmNGmrvC4C4gaeaXdexG1zNKNdMnwb56/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Oxgrod"
            }
        },
        {
            "chainId": 101,
            "address": "FskkXb13qMTBUY5cKhcAjD2jGWBHXRS4RDuGpCGUBQi9",
            "symbol": "ORC",
            "name": "Orance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TheJacobHill/ProjectToken/main/ProfileTriangle.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9eRysBGyj2MAsdSGncQewXB5MUA24WNN2wczmkxHSTHL",
            "symbol": "AMB",
            "name": "AMB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xrus05/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DJDQhT1w7Z5bo4QBcVXPJuYeaSJsxhAxUWzYMPVfn7by",
            "symbol": "PLTT",
            "name": "Pallet Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Alster100/Logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A6NLLo5BJXSfQJdemPqtCukqr1b4rP9XmcPqYwv2651i",
            "symbol": "CLUBC",
            "name": "ClubCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nickridpath14/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dm9BVFm6qwuPwPkjN7y2fsTi1VaVcxcrJgenssE5K9SG",
            "symbol": "HRS",
            "name": "Volunteer Hours Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dm9BVFm6qwuPwPkjN7y2fsTi1VaVcxcrJgenssE5K9SG/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Volunteer token associated with OneChurch in Sanford, MI."
            }
        },
        {
            "chainId": 101,
            "address": "DYUB3MgtXaD5LT9eA1rgBCjFqJMvsBCNhjbg1ewGks7Z",
            "symbol": "REV",
            "name": "REVIVE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYUB3MgtXaD5LT9eA1rgBCjFqJMvsBCNhjbg1ewGks7Z/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "REVIVE token associated with OneChurch in Sanford, MI."
            }
        },
        {
            "chainId": 101,
            "address": "B9NomEhZNCCmcaTxYvvV1W9hVwHfHYjpLVjW2XRedCLK",
            "symbol": "PUGSESSED",
            "name": "Pugsessed",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sarendipity/pugsessed/main/pugsessed.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3PbZAQbm3NVF8xnstqgnETZP4oDZcGGNYq5CJsNXV6dm",
            "symbol": "TRL",
            "name": "TrollCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZrH-Source/crypto/main/troll.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4KwJZpjXKuAdv39eA7zgSA68ALxvJ9X5P2B1FwxQXAV4",
            "symbol": "POLAR",
            "name": "Polar Lisbon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/POLARTOKEN/cripto/main/POLARTOKENCOIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HKZuiCMDPxFvhyrT5MCXkR3bedBEYbZmYEwbi5RMBKn2",
            "symbol": "ARC",
            "name": "ARC Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jmartjax21/Crypto/main/ArcReactor6.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3nhqNrUV27goNPNh8Td3UdTdxbcAdJZQKJPCwSDdK1BH",
            "symbol": "TROLL",
            "name": "TROLL",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3nhqNrUV27goNPNh8Td3UdTdxbcAdJZQKJPCwSDdK1BH/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanatrollcamp",
                "website": "https://solanatrollcamp.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GLzvmdxas2DupbQWuxsSdVxnXwzopdVnoSPgU63fD6zf",
            "symbol": "KYP",
            "name": "KYP Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dope4ass/test/main/logo22.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2VdJqfGzW9JzBZSpDQLAYMBjwD4YoYtMAyBxg6S9AXL5",
            "symbol": "MOON",
            "name": "MOONSTONE",
            "decimals": 2,
            "logoURI": "https://i.ibb.co/W6fcMVj/MOON.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2vxNknizwbTvGUP7S6uYr7A7tEa4X14SHRoxFxTa1VqR",
            "symbol": "JENN",
            "name": "Jennifer Myers Crypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheRealJenniferMyers/crypto/main/logo.png",
            "tags": [
                "social-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "AsJkh4kbD2UszLxztWQ49AHj6NkK8UMYbpGmnDdpNCf6",
            "symbol": "NINE",
            "name": "Nin-En",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ninjajoe9/crypto/main/logo.png",
            "tags": [
                "social-token",
                "ninja",
                "gold"
            ]
        },
        {
            "chainId": 101,
            "address": "7N7D4FfJuHA1KMMkH6xKEvLehp6rvpdtrpANJgJ9mop7",
            "symbol": "PITCOIN",
            "name": "PitCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/psugasti/pitcoin/main/pitcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9PyPhLmY2XUSjoS1KzovKCcZANMyhdDkVbAHCMdHTM9Q",
            "symbol": "CHSTR",
            "name": "ChesterCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sirkirby/chester/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AZn7eXpTjF8Gf6ZsqLKA9oT9tB4z5HgurTaH99wFKBMU",
            "symbol": "SMBN",
            "name": "Symbien Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/War-Admiral-HR/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9p1yXeFftAopssNeUhTLpkvdsyjyroQFgzLNmUa4g6LD",
            "symbol": "BOING",
            "name": "BOIIING",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/as3k/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AmvYBdYBF9WFy6oprdnzjsJTgNraeAPP9v3cexBTzgb3",
            "symbol": "GDC",
            "name": "Gold Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/rickregien/gdc-logo/main/gold-coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanatrollcamp",
                "website": "https://solanatrollcamp.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BtPMb5Fm21XcS8fNPMZM9FsmZrZQZyWFzLbztvdB1kTE",
            "symbol": "MEKA",
            "name": "MekaMounts",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtPMb5Fm21XcS8fNPMZM9FsmZrZQZyWFzLbztvdB1kTE/meka.png",
            "tags": [
                "MekaMounts"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MEKAMOUNTS",
                "website": "https://www.mekamounts.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GuthHLjD3KyRNUveh2gS2jwViJYNK9dwcvkF9bgT4m1W",
            "symbol": "MLK",
            "name": "Moo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MooCoin74/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "instagram": "https://instagram.com/notforeating",
                "website": "https://notforeating.com.au"
            }
        },
        {
            "chainId": 101,
            "address": "8JaicM7TN4rnT5mo4X5SBUiQp5VUz9uddojugtAxrHss",
            "symbol": "HOCBE",
            "name": "HOC Brussels Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8JaicM7TN4rnT5mo4X5SBUiQp5VUz9uddojugtAxrHss/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5ujiTZh32mxBGjGcWVtUKUaRqXdomUyeMGZuWgP6hnBb",
            "symbol": "WALO",
            "name": "Walo Test",
            "decimals": 9,
            "logoURI": "https://github.com/younes4sami/logos/blob/main/mylogo.png"
        },
        {
            "chainId": 101,
            "address": "9XZaM4nBcVxu5uj1vMwyC8AX1psAaWiHgy3CXKG42cia",
            "symbol": "AFT",
            "name": "Together",
            "decimals": 0,
            "logoURI": "https://github.com/tabsoat/solana/blob/main/aftlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F6hejSCr6dTA4hsBE4iD18BtpPFPTkGJggLFKnXp1LjP",
            "symbol": "OLIRAD",
            "name": "OliRad 22 Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cryptoforironhead/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kb6rH87WJU5SCx1WDXzK3YXFrUN3Uq9DroCLKzgsKqs",
            "symbol": "ARS",
            "name": "ARS Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EuNetwork/Crypto/main/ARS_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2cZb2MHhhSJGBh8nfxtfYhF1R1V7CBiubiA2uDo5cFLz",
            "symbol": "ROXY",
            "name": "RoxyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pushygeoduck/roxycoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4t6hdasqbriik3svqbhBpZmAbYGiJUPds1bCB6rRbLzN",
            "symbol": "$STEALTH",
            "name": "STEALTH COIN",
            "decimals": 5,
            "logoURI": "https://user-images.githubusercontent.com/93989494/146991721-19c34433-72fe-460b-82e5-455e886fb27a.png"
        },
        {
            "chainId": 101,
            "address": "EgMuKRHL7EA3UFRG9kZmn6AdkbbkZSsz3jX9wAqnZo52",
            "symbol": "MEOWCR",
            "name": "Meow Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MayMeow/crypto/main/cat.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheMayMeow"
            }
        },
        {
            "chainId": 103,
            "address": "6DGhVNGpYng4pKWbmuZqVA8hqHXZ6ASdSoEgmHt2N55N",
            "symbol": "AGE",
            "name": "Medieval Age Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6DGhVNGpYng4pKWbmuZqVA8hqHXZ6ASdSoEgmHt2N55N/logo.png"
        },
        {
            "chainId": 101,
            "address": "8uCsnmP4aFkKTPGrvwaDUAHGTSVsGDkPxfjmWqfff6gS",
            "symbol": "NDC",
            "name": "QV5D Coin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/iorivn/ndc-logo/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Five Heads Monster Coin"
            }
        },
        {
            "chainId": 101,
            "address": "21ppv2RNR1AQ9buFw4ZDYiriCqhxaqNmiMDDjcsAtnbz",
            "symbol": "PANZ",
            "name": "Panzitta Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thxinfinity/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ep3Hb1aB4ppNyBy2Vrrqdo4omy1U9isQqkW9Vz81yFQk",
            "symbol": "NEBC",
            "name": "NebulaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JaydenH08/crypto/main/photo-1610296669228-602fa827fc1f.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9RxUYewqPKMesL53jcpMgdguy8rHnREXfLeRfFHA2WuM",
            "symbol": "SARAH",
            "name": "Sarah Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9RxUYewqPKMesL53jcpMgdguy8rHnREXfLeRfFHA2WuM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sarahtchoi"
            }
        },
        {
            "chainId": 101,
            "address": "DiMnHRPJuFj4Dex2cWMkuJeNMTshN9h9aSNt3R8UiFSg",
            "symbol": "AZT",
            "name": "Andrew Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiMnHRPJuFj4Dex2cWMkuJeNMTshN9h9aSNt3R8UiFSg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/andrewztan"
            }
        },
        {
            "chainId": 101,
            "address": "6aLjYGJQodEnnYh1etfezbx1jMkV8H3ERsLx5KiDoswH",
            "symbol": "CHAWIN",
            "name": "ChaWinnie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anrob/chawinnie/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B2gYuigZJNNQDTAnRiaiXVSetYT4uo1t4MXV2CdzdsGx",
            "symbol": "BTBOS",
            "name": "Btboss123",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/btboss123/BTboss-Crypto/main/LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9wXdxNiTFF6NBwmv864U9NPSY6RivgGMRkdZr269RLzr",
            "symbol": "UICT",
            "name": "UncleiT Coin",
            "decimals": 0,
            "logoURI": "https://github.com/imitone/ucit-coin/blob/main/logo.png?raw=true",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WM2UwRF7Sk",
                "website": "https://uncleitcoin.site"
            }
        },
        {
            "chainId": 101,
            "address": "ADrHVtkG7FGzrY8MpP44KC2aimhTsuJx3EjQxt85Wwab",
            "symbol": "IGNFTADR",
            "name": "Instagram NFT LIONLEGACY7 2",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADrHVtkG7FGzrY8MpP44KC2aimhTsuJx3EjQxt85Wwab/logo.png",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/ADrHVtkG7FGzrY8MpP44KC2aimhTsuJx3EjQxt85Wwab"
            }
        },
        {
            "chainId": 101,
            "address": "JCBDs3fmrYUVctDfv1o56wN8pr3CAhf4ERHgjyvTrC9C",
            "symbol": "IGNFTJCB",
            "name": "Instagram NFT LIONLEGACY7 4",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JCBDs3fmrYUVctDfv1o56wN8pr3CAhf4ERHgjyvTrC9C/logo.png",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/JCBDs3fmrYUVctDfv1o56wN8pr3CAhf4ERHgjyvTrC9C"
            }
        },
        {
            "chainId": 101,
            "address": "9mto3a7pbJpGL69h9xxSNLhr1zeQtUcsH87TYq9zT4nQ",
            "symbol": "BETS",
            "name": "BETS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9mto3a7pbJpGL69h9xxSNLhr1zeQtUcsH87TYq9zT4nQ/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solbets_io",
                "website": "https://www.solbets.io"
            }
        },
        {
            "chainId": 101,
            "address": "6vD17diFXjhVdEt3wo68FuHAb4nUuNG8FQSK9xM4MFDy",
            "symbol": "GYRC",
            "name": "GuyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/guyroyse/gyrc/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://guy.dev"
            }
        },
        {
            "chainId": 101,
            "address": "FjDomDGhHqxDSetH7KfMzkKMfks5pVKJZp9tA5ezNFbW",
            "symbol": "UNIC",
            "name": "UniCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JaydenH08/crypto2/main/download.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3T7bCxj1ndyCuAzf4GygLyaJ5EsLBCCaVYu18i12TJq3",
            "symbol": "IGNFTTBC",
            "name": "Instagram NFT LIONLEGACY7 3",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3T7bCxj1ndyCuAzf4GygLyaJ5EsLBCCaVYu18i12TJq3/logo.jpg",
            "tags": [
                "nft",
                "instagram"
            ],
            "extensions": {
                "website": "https://rocketsola.com/nft/instagram/3T7bCxj1ndyCuAzf4GygLyaJ5EsLBCCaVYu18i12TJq3"
            }
        },
        {
            "chainId": 101,
            "address": "3h3d9BGTVA8SQECgTdntR1DPAVVHTJMJW4ZJhGffcXme",
            "symbol": "JAKEN",
            "name": "Jaken Meme",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3h3d9BGTVA8SQECgTdntR1DPAVVHTJMJW4ZJhGffcXme/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://blog.botbie.io/author/jaken/"
            }
        },
        {
            "chainId": 101,
            "address": "ExXBzMJA2udpJbrtpJEQJYVSyNFJCzU717W1SNizW3pS",
            "symbol": "ARTA",
            "name": "ArtAgent",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JCraig66/ArtAgent/main/arta_logo_sm.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9uAHvTj3U6YZ6tWn5YMzomdnre3kpKZLFyD3nY7thvGR",
            "symbol": "BORI",
            "name": "Borikoin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Leopr1/cryptotoken/main/Taino-sun1-bold.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4SzcBzNhCxXGBRSdvyCyQjbuqAavFzvFEpDYRSKLqA1N",
            "symbol": "NELC",
            "name": "NelCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JohanJ21/cypto_1/main/NelcoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FBdRvc9CmHUf8ib2sV8PDv2oiFAmyxoftjid3Uv9e4kK",
            "symbol": "XIN",
            "name": "XIN Dragon Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBdRvc9CmHUf8ib2sV8PDv2oiFAmyxoftjid3Uv9e4kK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://xindragons.com"
            }
        },
        {
            "chainId": 101,
            "address": "CcsxvCYB8t8dMnqzR3BHi6aAXWmBWAxRi9b8F5hofqRW",
            "symbol": "GREGG",
            "name": "Gregg Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/im-old-gregg/crypt/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3TtdsuQQcuXLfPcUS745giYccyWedeSPCNtBcRfkZGuB",
            "symbol": "gala",
            "name": "gala",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Nasino1/ga/main/logo.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3EzuZHSJZnLvZWMpStNBmNJE9rruyz4u3STARBY4gPL7",
            "symbol": "OTO",
            "name": "Otometa Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EzuZHSJZnLvZWMpStNBmNJE9rruyz4u3STARBY4gPL7/logo.png",
            "tags": [
                "launchpad",
                "metaverse",
                "gaming",
                "nft",
                "collectibles",
                "play-to-earn",
                "community-token",
                "artists",
                "earn",
                "trade"
            ],
            "extensions": {
                "description": "A blockchain universe for the creative souls. Create, Sell, Earn and Play to the fullest of your creativity",
                "discord": "https://discord.gg/mrHVFNV4jq",
                "medium": "https://otometa.medium.com/",
                "reddit": "https://www.reddit.com/user/OtometaU",
                "twitter": "https://twitter.com/OtometaU",
                "website": "https://otometa.io"
            }
        },
        {
            "chainId": 101,
            "address": "EcFyPDjqpnyMvh1LhACtC6rrCZ41DMez7RZYocjhmUVS",
            "symbol": "NOCH",
            "name": "NodeBunch",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EcFyPDjqpnyMvh1LhACtC6rrCZ41DMez7RZYocjhmUVS/logo.png",
            "tags": [
                "dex",
                "farming",
                "swap",
                "pool",
                "nft",
                "trade",
                "staking",
                "nft-staking",
                "amm",
                "launchpad"
            ],
            "extensions": {
                "description": "Extend the boundaries of earning with nodebunch, a futuristic AMM Dex with the power of DEFi",
                "discord": "https://discord.gg/C4dC2grHAj",
                "medium": "https://nodebunch.medium.com/",
                "reddit": "https://www.reddit.com/user/nodebunch",
                "twitter": "https://twitter.com/BunchNode",
                "website": "https://nodebunch.finance"
            }
        },
        {
            "chainId": 101,
            "address": "5yLd5T4HXmeWTH8sTH9cPxkDJbQyHHVmv9EWwjWpBL4w",
            "symbol": "TGRC",
            "name": "TigerCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JaydenH08/crypto3/main/tiger-face-background_23-2148007463.jpg",
            "tags": [
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "HkNK7BL5pSUUzc6ns1mHW5JnzbSG4S9u2QdR3cUuyzSa",
            "symbol": "SPZ",
            "name": "Solprize",
            "decimals": 5,
            "logoURI": "https://www.solprize.io/static/images/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "description": "Let's get be rich and happy together!",
                "instagram": "https://www.instagram.com/solprize",
                "reddit": "https://www.reddit.com/r/solprize",
                "telegram": "https://t.me/solprize",
                "twitter": "https://twitter.com/solprize",
                "website": "https://www.solprize.io"
            }
        },
        {
            "chainId": 101,
            "address": "EAdQ1D1Q8Qj6uhif94dEgpf7XKC1LRdEM4BkumxkZbET",
            "symbol": "DTC",
            "name": "DTCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DannyBTW/crypto/main/DTCoin%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cvffc7pFnU3bDHfrwCM1NvWzxM8m9t5R82oJubU7TMQX",
            "symbol": "RODC",
            "name": "Rodders Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rodhop/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dm7cDFpovTay4pxFpUYv66ZZtRYdD1YjgvnseaaF6KHh",
            "symbol": "BLOOD",
            "name": "BLOOD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dm7cDFpovTay4pxFpUYv66ZZtRYdD1YjgvnseaaF6KHh/logo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "http://discord.gg/gyQ76h29TE",
                "twitter": "https://twitter.com/YakuzaRaptorz",
                "website": "https://www.yakuzaraptorz.com"
            }
        },
        {
            "chainId": 101,
            "address": "76wonxPzsZGAnes97cQBAshy3BwBeR7htCfksfjUJFXE",
            "symbol": "JONI",
            "name": "Joni Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/captain-joni/jonicoin/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EjSwAfwi4F6uYtoi2WuCSYSWPVUPJCdemmShZ9tdy65P",
            "symbol": "KZN",
            "name": "Kartazion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjSwAfwi4F6uYtoi2WuCSYSWPVUPJCdemmShZ9tdy65P/logo.png",
            "tags": [
                "kartazion",
                "kzn",
                "science",
                "quantum",
                "physics"
            ],
            "extensions": {
                "description": "Universal Theory of Physics and Quantum Mechanics - Research",
                "website": "https://www.kartazion.com"
            }
        },
        {
            "chainId": 101,
            "address": "FCw1uH4bWnCCjutTSAgyfP1n38tRP76LDDUm4ZWJp3Zf",
            "symbol": "BEIR",
            "name": "BEIR CHIP",
            "decimals": 6,
            "logoURI": "https://github.com/XtremeAppz/BEER-TOKEN/blob/main/logo.png",
            "tags": [
                "token",
                "meme-token",
                "social-token",
                "drink-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7c8WQE25zZbadjRWixiy2ARF9ZHrntDowZqYuhtCsPmJ",
            "symbol": "APC",
            "name": "Among Pirates Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7c8WQE25zZbadjRWixiy2ARF9ZHrntDowZqYuhtCsPmJ/logo.png",
            "tags": [
                "social-token",
                "gaming",
                "play-to-earn"
            ],
            "extensions": {
                "description": "Fun play to earn game among pirates",
                "instagram": "https://www.instagram.com/amongpirates",
                "website": "https://www.amongpirates.com"
            }
        },
        {
            "chainId": 101,
            "address": "5WLNynnq9pVio8Rj8XnqdZLYZSsaKzzAzS4aFvUK1tU2",
            "symbol": "SOLMERGE",
            "name": "Sol Merge AI",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WLNynnq9pVio8Rj8XnqdZLYZSsaKzzAzS4aFvUK1tU2/logo.png",
            "tags": [
                "solmergeaicom"
            ],
            "extensions": {
                "website": "https://solmergeai.com"
            }
        },
        {
            "chainId": 101,
            "address": "HwgZxR9bU1DhpGLhbGAgK2QNHKcjJDgs8pkqT2TdVxYV",
            "symbol": "DAD",
            "name": "DAD COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/davethecloud/crypto/main/D.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5r7vsbP24VaVzvZ3W2YnwYdGMmdpSsGFHA2mCitPxZev",
            "symbol": "VOLT",
            "name": "Voltage Solana",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5r7vsbP24VaVzvZ3W2YnwYdGMmdpSsGFHA2mCitPxZev/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/FFkmX5S9As",
                "twitter": "https://twitter.com/VoltageSolana"
            }
        },
        {
            "chainId": 101,
            "address": "2L8qUkyBzKyqkNqxqPzBhd8HXYyZPZgULuCDNheYdLZv",
            "symbol": "ALIEN",
            "name": "ALIEN JESUS COIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/alienjesusorg/alienjesusorg.github.io/master/images/favicon-alien.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://alienjesus.org/"
            }
        },
        {
            "chainId": 101,
            "address": "72tsMfXLasd8GFya63UZY7w8xDgDLdxJtCJ16trT14gn",
            "symbol": "HDPT",
            "name": "Headpat",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/zaravi/headpat/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DCGtYZmS6Swj1dPVjMbfa8Ke1ket486SbvGDY9CAZ2Xr",
            "symbol": "IVOS",
            "name": "Immutable Virtual Original Soul",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JVQuantum/ivos/main/ivOS%20logo.png",
            "tags": [
                "social-token",
                "gaming",
                "nft",
                "meta",
                "metaverse",
                "metaverse-token",
                "immutable",
                "virtual",
                "original",
                "soul"
            ],
            "extensions": {
                "website": "https://ivos.pt"
            }
        },
        {
            "chainId": 101,
            "address": "5PR8Fre4gU52dgv5W4nrJkSAW4xonDWD5d84HJF7nDhU",
            "symbol": "SLTK",
            "name": "Solulab Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Parth2412/solana-token/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "GTyzMMe7SvKeRa35GtXikyc8V198pckG7BEA3W3XnVrN",
            "symbol": "BEER",
            "name": "BEER CHIP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/XtremeAppz/BEER-TOKEN/main/logo.png",
            "tags": [
                "token",
                "meme-token",
                "social-token",
                "drink-token",
                "beer-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2e4rvazLT177uqXmDFkXJox9rZKk5LFqmqwpeaPaGkSk",
            "symbol": "ZAMO",
            "name": "zamo-o coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zamo-o/crypto/main/day-1-700x.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "EreDcRUuV8pBkpgoJfWmgzpWhwnrnHBNDQqSWzZk5dsa",
            "symbol": "CAT",
            "name": "CatCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cometbeetle/token-list/main/assets/mainnet/EreDcRUuV8pBkpgoJfWmgzpWhwnrnHBNDQqSWzZk5dsa/cat.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F8haj7XPnjiYLTmivGL3UvWZHjP9f1Lt96nxDzDQG3K3",
            "symbol": "SHUSHI",
            "name": "ShuShi Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F8haj7XPnjiYLTmivGL3UvWZHjP9f1Lt96nxDzDQG3K3/logo.png",
            "tags": [
                "solmergeaicom"
            ],
            "extensions": {
                "website": "https://houseoffoodporn.com"
            }
        },
        {
            "chainId": 101,
            "address": "77Qps1yYb3cZASEgizao3ZWW4MiXe2buTUzLFCbgtj2Z",
            "symbol": "HERGON",
            "name": "HERGON",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jota2314/Hergontoken/main/TAG_Hergon-removebg-preview.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BYqJ8nnsj1YqmdreEgxF2QgGV1GEdV5H85rJEhmN98id",
            "symbol": "WBC",
            "name": "Buffettcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYqJ8nnsj1YqmdreEgxF2QgGV1GEdV5H85rJEhmN98id/logo.png",
            "tags": [
                "utility-token",
                "travel-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BWqYfmB1p159ewVvih7r5rp2KzWuwD3Chxd4SWeYhJT9",
            "symbol": "CSE",
            "name": "CosmicSphere",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CosmicSphere/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7t8XBfFRUmEcUnXg2q6kk6EfaJAi4VyU43vysDhdtgJS",
            "symbol": "HIN",
            "name": "Hindle Family Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7t8XBfFRUmEcUnXg2q6kk6EfaJAi4VyU43vysDhdtgJS/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D98QvhmGn3ucfWnpjvtm3pSktVyrkCMcoABuTSwgzn9c",
            "symbol": "WURST",
            "name": "wurstcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mvmet/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BAMT7yASjR7RrP4XsK6azMG3GZxWiGRxf1vDETSUbd5Q",
            "symbol": "FRESH",
            "name": "FRESH808",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/breezy2472be/crypto2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3HNyLKZD6EDytCcCpS11TP4A8ChP1jMRZjbe16GLvFHh",
            "symbol": "KKC",
            "name": "KeksiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nicog2005/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8U4wn5EBr1bDSFHeSWLzn4Pia2aNNTGdjcc4uQttqVSB",
            "symbol": "BELLS",
            "name": "Bells",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8U4wn5EBr1bDSFHeSWLzn4Pia2aNNTGdjcc4uQttqVSB/bells.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://livingfreely.on.fleek.co"
            }
        },
        {
            "chainId": 101,
            "address": "CDg66cc81TNYXYDdDAe9MpE8n2htQgmNRs3gcFeiZ7xg",
            "symbol": "NKLE",
            "name": "WOODEN NICKLE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/XtremeAppz/BEER-TOKEN/main/logo.png",
            "tags": [
                "token",
                "meme-token",
                "social-token",
                "drink-token",
                "beer-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3fGm36NQqHNWz7pNtwaBa6q2a4NAnGGh4n6WHjLtfgHZ",
            "symbol": "RET",
            "name": "RET Everywhere",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3fGm36NQqHNWz7pNtwaBa6q2a4NAnGGh4n6WHjLtfgHZ/logo.png",
            "tags": [
                "social-token",
                "loop"
            ]
        },
        {
            "chainId": 101,
            "address": "B6E3Ekd37pDsCSAVnXfoZ1k9bWrKcizmxx3uWgVKGGmD",
            "symbol": "TSCT",
            "name": "The Social Cafe",
            "decimals": 0,
            "logoURI": "https://github.com/thesocialcafe/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5BRgm7MsvhsofftVczvTCsQqH1iaaYQ1xk2ZJ1JQ83nP",
            "symbol": "LPS",
            "name": "Lempira",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vhn0912/Lempira/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FNcmhoAsuzeoDwnckSEefuAW1jwGnFCbowVbv2DLMM74",
            "symbol": "AMKT",
            "name": "AMIKETTICOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNcmhoAsuzeoDwnckSEefuAW1jwGnFCbowVbv2DLMM74/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CwP7ggJPVvRGRjvkV4Q5bnFNTECd9GAhoGdjtsvhEHaC",
            "symbol": "CUTE",
            "name": "CUTE COIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CwP7ggJPVvRGRjvkV4Q5bnFNTECd9GAhoGdjtsvhEHaC/logo.png",
            "tags": [
                "solmergeaicom"
            ],
            "extensions": {
                "website": "https://cargocollective.com/gustifink/"
            }
        },
        {
            "chainId": 101,
            "address": "B6aJ3TGfme3SMnLSouHXqWXjVFqYyqj7czzhzr8WJFAi",
            "symbol": "RAD",
            "name": "RAD",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/NFTBrickLayer/zillaz-assets/main/RAD-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zillaz",
                "twitter": "https://twitter.com/ZillazNFT"
            }
        },
        {
            "chainId": 101,
            "address": "FtZpYentuYo1PRLEvx8qR6Htzv7uGVqotdR7RwfHw4kA",
            "symbol": "BYN",
            "name": "BAYAN COIN",
            "decimals": 0,
            "logoURI": "https://github.com/chadananda/bayancoin/blob/main/icon.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BDE7Rb89NQeoNPyokL5caRg116Khfz1nDbQ5xmpkDR9h",
            "symbol": "WEVE",
            "name": "weve coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ryanjweaver/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E8vEUoHrZu3g6o9p7t8iMDecjFPQz4rk3DRdZXZPfY5P",
            "symbol": "SGK",
            "name": "SugarKoin",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E8vEUoHrZu3g6o9p7t8iMDecjFPQz4rk3DRdZXZPfY5P/logo.png",
            "tags": [
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "2we9YEJC7B6rM7YVQZzxX6xigatntVmH5NLarvNUzzYk",
            "symbol": "NIC",
            "name": "NiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/techhotspot/NiCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FPBj9Nq1PmbqzhMwkJLsweDyutoqABnnA6vXD2mWLCQA",
            "symbol": "WEND",
            "name": "Wendpaaper",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PsychoMange/WendpaapCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7HTtfpKSs28sbbESNHMZ48HPgfhfMYoSPTKnqa15MVDR",
            "symbol": "NAD",
            "name": "NaDcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/grachenova/crypto/main/coin_pic.jpg",
            "tags": [
                "token"
            ]
        },
        {
            "chainId": 101,
            "address": "BgnLUkTwKMVngMvUjh5mEYPuz4idgpyib731tWaTCZxr",
            "symbol": "BNF",
            "name": "Beetle and Frog Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/as3k/crypto/main/images/bnf-coin-logo.jpg",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "APNp4mW8kofKc3vwK8oEdHBGksgcZnc3KrWTyCwExt7o",
            "symbol": "KTC",
            "name": "Kareten Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Wolmi59/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8un38Bn3aWb3hC5xVavGkiPed2qkHPVE4veSf144ST6y",
            "symbol": "RAT",
            "name": "RAT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8un38Bn3aWb3hC5xVavGkiPed2qkHPVE4veSf144ST6y/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "ACejrU5GujUeasmQfdJ51zVjZtntyXLpohihut6eHHWT",
            "symbol": "NFTCOIN",
            "name": "NFT COIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ACejrU5GujUeasmQfdJ51zVjZtntyXLpohihut6eHHWT/logo.png",
            "tags": [
                "solmergeaicom"
            ],
            "extensions": {
                "website": "https://twitter.com/btcnftcoin"
            }
        },
        {
            "chainId": 101,
            "address": "7kivceESa3YPmwKaUbkb2QpNPitHABAGfbcaEVFDAKR5",
            "symbol": "CRBNT",
            "name": "CarbonTAX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kivceESa3YPmwKaUbkb2QpNPitHABAGfbcaEVFDAKR5/logo.png",
            "tags": [
                "utility-token",
                "COMMERCE"
            ],
            "extensions": {
                "description": "Token used to trade international carbon tax",
                "website": "https://cryptometeo.fr/"
            }
        },
        {
            "chainId": 101,
            "address": "DnUoZhyXDrPEhFf2fffQFELQ3E5QPfDYuFgVLdrGyye5",
            "symbol": "PIG",
            "name": "PiggiesCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Iscalled/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "66tvzaQpB44BsZjHi6Kb7bSBLXUVekg4rB2mUzJ9afWf",
            "symbol": "DZO",
            "name": "Deazo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/66tvzaQpB44BsZjHi6Kb7bSBLXUVekg4rB2mUzJ9afWf/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CtLoGyUPkL8xKkmQ8GH5euM5Bp2JpBTtXvheA68zd2EM",
            "symbol": "WINKLE",
            "name": "WinklePinkle",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Perryhd/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FJPmrjXYCSffEcTSYnwnBMqZiUvTBUcpNMpCGsDqkA8B",
            "symbol": "BITNFT",
            "name": "BITNFT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FJPmrjXYCSffEcTSYnwnBMqZiUvTBUcpNMpCGsDqkA8B/logo.png",
            "tags": [
                "solmergeaicom"
            ],
            "extensions": {
                "website": "https://twitter.com/btcnftcoin"
            }
        },
        {
            "chainId": 101,
            "address": "3jmWzvq6CMsvbCtifJeQ3pt29kud4rXxub6Zt1YW8Zsg",
            "symbol": "BNFT",
            "name": "BNFT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3jmWzvq6CMsvbCtifJeQ3pt29kud4rXxub6Zt1YW8Zsg/logo.png",
            "tags": [
                "solmergeaicom"
            ],
            "extensions": {
                "website": "https://www.reddit.com/r/btcnftcoin/"
            }
        },
        {
            "chainId": 101,
            "address": "GhkxBbo6feL4Mxy1bykqnU8m9hpEGQnRfnGz8ZD6czwZ",
            "symbol": "GBM",
            "name": "Gabi Matatov Network",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gabimatatov17/gbmTokenOnSolana/main/GBM_Logo.png",
            "tags": [
                "GABI-Matatov-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7cw29me2D5U1FWJDkD4N8tBU5hgXymHWZ13hAmgYUmye",
            "symbol": "WHL",
            "name": "Whale Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WhaleCoinToken/WhaleCoin/main/WhaleCoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AK5QXWmFVCj7hNSNtDSBkHAZCQpwQr6Qqq6YoWNMftL6",
            "symbol": "Ponzi",
            "name": "PonziCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheCanadian445/crypto/main/CryptoCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75dRekBgDoATRHMtRNjae6d5QdWqjdxTJWEw7b29ECb4",
            "symbol": "AST",
            "name": "Arjun Singh Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HyperSlothAST/Coins/main/SolTiger1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FDKBUXKxCdNQnDrqP7DLe8Kri3hzFRxcXyoskoPa74rk",
            "symbol": "$YETI",
            "name": "YETI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/yet777/tokenlogo/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solyetis",
                "website": "https://solyetis.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5tAH9UVbthNvx7WRTMFmtwStwYEvRjmT7zVzgL8VXP5p",
            "symbol": "CODE",
            "name": "CodeCatch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/codecatchorg/codecatchcoin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://codecatch.net/discord",
                "website": "https://codecatch.net"
            }
        },
        {
            "chainId": 101,
            "address": "67jjUujr8psh8ACcry3M1QmpQU6krUBBt2rQpgbPxa4t",
            "symbol": "FSANE",
            "name": "F-Sane Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/uestadeus/cyrpto/main/logo.png",
            "tags": [
                "community-token",
                "drink-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "8q7a4FpN9Di6TtT8RDbyZbwz1qmiKwmhNALRstQCbN4K",
            "symbol": "AMKTC",
            "name": "AMIKETTI COIN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8q7a4FpN9Di6TtT8RDbyZbwz1qmiKwmhNALRstQCbN4K/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FRAC35fY5jBzDnERbL39K9awf6o7eAFvXhptUVZE8Grv",
            "symbol": "FRAC",
            "name": "Fractal",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FRAC35fY5jBzDnERbL39K9awf6o7eAFvXhptUVZE8Grv/logo.png",
            "tags": [
                "Fractal-coin",
                "fractally-divisible",
                "deflatory",
                "capped"
            ]
        },
        {
            "chainId": 101,
            "address": "FKL9NfHNDHLYpFjE8WhPyg9XDDcUtaweDCnJTLmb4TQy",
            "symbol": "JJC",
            "name": "JaramCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/josejaram/JaramCoin/main/JJLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EU71mB7Vsk8onvMdZ5wPtWGS2nRt1rEdfxzHW42PFPqb",
            "symbol": "BIOCHIP",
            "name": "Biochip token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EU71mB7Vsk8onvMdZ5wPtWGS2nRt1rEdfxzHW42PFPqb/logo.png",
            "tags": [
                "dna-microarray",
                "token",
                "biochip",
                "chip"
            ]
        },
        {
            "chainId": 101,
            "address": "7ZjyPmx4JNu6Wu5eXBBbtB63Z9Ga7Psxqax2WBr8fD2a",
            "symbol": "MGC",
            "name": "Midnight Gremlin Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Midnight-Gremlin/crypto/main/Midnight-Gremlin-Coin.png",
            "tags": [
                "musician-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EuKSYaM8Rgy9x4aaq4Mf9bUMcuBPLSLw5SyV2XR32wY7",
            "symbol": "POTR",
            "name": "PartyOtter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HyRo34/crypto/main/PartyOtter.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "95P1nEvDCoRQT79zdC13JzkyBUCgaxLAJoJw487Nzhcy",
            "symbol": "PTST",
            "name": "Palm Tree Square Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BigAsheHole/Crypto/main/PTST%20logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EwJPQzwjQnVH1hEb7jqUXqpEGf7dBvkahj9BGXXaM4we",
            "symbol": "SQKY",
            "name": "Squeaky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nottanudder/pammytoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AyD9X4EGFzfahi1oZLZQDfgRaTZEd2UYHNEZpnVJxz9z",
            "symbol": "VELO",
            "name": "Velosity",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/inventari/crypto/main/Velosity.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H7VBf2BrJGh5B66hfjHghrRG1YW4xxnL3ztEGgKTyWJ",
            "symbol": "MARK",
            "name": "Markley",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/inventari/crypto/main/Markley.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BFhbtoxLKrhG6ZU4R5S32S6DrPRjwxPQQgbpEZZe39ip",
            "symbol": "FAVR",
            "name": "Favor",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/inventari/crypto/main/Favor.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yLP4agSHVPv7oxDFQ38fuwJGQA4MV1kJJy4SuNbDEHj",
            "symbol": "y-wUSD-4Pool",
            "name": "Yield Mercurial LP Token (wUSD-4Pool)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yLP4agSHVPv7oxDFQ38fuwJGQA4MV1kJJy4SuNbDEHj/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "yM3pjpurEuod4TQ3m4KoV2Rj6Jjge4EGexX7NyiRL2P",
            "symbol": "y-UST-3Pool",
            "name": "Yield Mercurial LP Token (UST-3Pool)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yM3pjpurEuod4TQ3m4KoV2Rj6Jjge4EGexX7NyiRL2P/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "yM4piMddLe45SVccNqbRfZFPCQzNByXERjL3VKxFRWP",
            "symbol": "y-wbBUSD-4Pool",
            "name": "Yield Mercurial LP Token (wbBUSD-4Pool)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yM4piMddLe45SVccNqbRfZFPCQzNByXERjL3VKxFRWP/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "yMstKuGEW6v34MB4d3wBd4BTEsHhexLzZca6cCg2DPy",
            "symbol": "y-stSOL-2Pool",
            "name": "Yield Mercurial LP Token (stSOL-2Pool)",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yMstKuGEW6v34MB4d3wBd4BTEsHhexLzZca6cCg2DPy/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "yMmSMGXaLqyMc9Vo9LR6BhX7m789xrVdJmiUYUKk4aB",
            "symbol": "y-mSOL-2Pool",
            "name": "Yield Mercurial LP Token (mSOL-2Pool)",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yMmSMGXaLqyMc9Vo9LR6BhX7m789xrVdJmiUYUKk4aB/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "y2poavXfuAHWRizzK52vegqzzkHUo5CzooxFzDDX6vp",
            "symbol": "y-USDT-USDC",
            "name": "Yield Saber LP Token (USDT-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/y2poavXfuAHWRizzK52vegqzzkHUo5CzooxFzDDX6vp/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9Lhf1327tmJpbKiedjwJrBWp27T6snR2cJwSwjhBZjBW",
            "symbol": "DMS",
            "name": "DMScoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SalahMessaoud/dms/main/dms.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3cCtnQcc81oUfjufBL6D1yXNqt99TPt8n4HinW9h58wP",
            "symbol": "STARTREK",
            "name": "StarTrek Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3cCtnQcc81oUfjufBL6D1yXNqt99TPt8n4HinW9h58wP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/snft2022",
                "website": "https://solana-nft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FXKM9Ziz9kTTRbpUxxS2rUipSnJmeyNAkLDbabr5RTpb",
            "symbol": "QMC",
            "name": "QMC Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXKM9Ziz9kTTRbpUxxS2rUipSnJmeyNAkLDbabr5RTpb/logo.jpg",
            "tags": [
                "QMC-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "3XmvR9uW1NQhUpdZjFDqZSD8SrRvzThYnS2UETGgqzdG",
            "symbol": "RTF",
            "name": "Royal Tokens of Raf",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MrRafB/rtof/main/rtof.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cik9YZ43UQMG1W31N7SJ5KYUTTP6WDqwZMzzYfh8Ezqm",
            "symbol": "SOLFLO",
            "name": "Solana Flow",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/julz201/SOLFLO/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kZaHZV981fS4MiXm4QkLDsYXE5rqgcFw9ofPuSswvSV",
            "symbol": "MINAWI",
            "name": "minawi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/minawi69/minawi-token/main/logo.png.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "12WvCE1De1VjSRqSGQaity54T8Mvo22gvnxs1Lxc2Vmx",
            "symbol": "KBREW",
            "name": "Koasbrew Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dawa82/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "7Xn4mM868daxsGVJmaGrYxg8CZiuqBnDwUse66s5ALmr",
            "symbol": "SIMPDEV",
            "name": "SimpiansDEV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Xn4mM868daxsGVJmaGrYxg8CZiuqBnDwUse66s5ALmr/logo.png",
            "tags": [
                "nft",
                "utility-token",
                "gaming-token",
                "gaming",
                "play-to-earn",
                "breeding-token"
            ],
            "extensions": {
                "description": "Dynamic NFT breeding-trading-legacy making game",
                "reddit": "https://www.reddit.com/r/simpians/",
                "twitter": "https://twitter.com/SimpiansNFT",
                "website": "https://www.simpians.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8158KuiGXjjnMbzdAo2jZzRFAs8QwvVzo2v22aZstyTN",
            "symbol": "FLOWERS",
            "name": "live Flowers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AndrianBalanescu/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H6EqB6rSLEWsVfbhZw2hh7STU5Kd9RNUt24Jn1Vy8H66",
            "symbol": "STEVE",
            "name": "Steeeeve",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/onedoubleo/cyrpto/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4NGNdLiQ1KG8GgqZimKku4WCLdXbNw6UQJvqax3fE6CJ",
            "symbol": "WAV",
            "name": "Fractionalized WAVE-999",
            "decimals": 2,
            "logoURI": "https://www.arweave.net/qV0rFkGG-cTdtgRu7le-k8DstT8_-pzG2MWsceSlXjQ?ext=png",
            "tags": [
                "bridgesplit-fraction",
                "playground-wave",
                "wave-999",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/fjrFpbweAP",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/vault/4NGNdLiQ1KG8GgqZimKku4WCLdXbNw6UQJvqax3fE6CJ"
            }
        },
        {
            "chainId": 101,
            "address": "751v6p8xrGdX8pzdZtkgDpNirWmuoQuFnpAJhtXD8cPz",
            "symbol": "RaLeMA",
            "name": "RafaLeoManny",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rafinix/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4suNmcT5nPgG8rMMS9XwCdFH9e9ZDpZoCXiXUsCJTbsu",
            "symbol": "RLM",
            "name": "rafaleomanny",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rafinix/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkKgBEu1KqcKMU8JsVyvRKx8KxoRPDTtLtC8Eg1ocVsL",
            "symbol": "CRCN",
            "name": "Currie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vpourchot/crypto/main/currie_coin1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GcoAoh8ZAu2oVXm3Ac8Z9WvxGc6kcdDUE3PL19nvs5uJ",
            "symbol": "BULK",
            "name": "Bullcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/buffymuscot/crypto/main/logo.png",
            "tags": [
                "social-token",
                "bull",
                "NFT",
                "jari"
            ]
        },
        {
            "chainId": 103,
            "address": "9NAkASxt7EiYDhjskaTiHw9K2j2ac5mGB2WRwLRzKeUA",
            "symbol": "AGE",
            "name": "Medieval Age Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9NAkASxt7EiYDhjskaTiHw9K2j2ac5mGB2WRwLRzKeUA/logo.png"
        },
        {
            "chainId": 101,
            "address": "FCEhKMcpytVdmNXv5pMLX6mAxUkEN9yUGrJw6zsV4PHB",
            "symbol": "WLTHN",
            "name": "Whalethen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jimji1005/whalethen/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EKMQbbKzZgTwXbfhNqRHd2m6c1KtZcHf4bJnQHDru8Eh",
            "symbol": "EQXT",
            "name": "eqx Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ass77/buildspace-nft-client/main/public/eqx.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BbfVPdU5yuYyDQErmvbm2USqTQt9pH9j3k3tSx8ZxL9Q",
            "symbol": "SOLFISH",
            "name": "SOLFISH",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BbfVPdU5yuYyDQErmvbm2USqTQt9pH9j3k3tSx8ZxL9Q/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/jDr6sCM3jE",
                "twitter": "https://twitter.com/SharkSociety_",
                "website": "https://billionairesharksociety.com"
            }
        },
        {
            "chainId": 101,
            "address": "xZsp3D2JhBvgjdQzd5nobU26CALm916nYwZys3gRPux",
            "symbol": "SOLACE",
            "name": "Solace Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xZsp3D2JhBvgjdQzd5nobU26CALm916nYwZys3gRPux/logo.png",
            "tags": [
                "solmergeaicom"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solacetoken",
                "website": "https://www.solacetoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "J6aYvKGLbAJXyjWrwfoo3pUTgWVnmweExwL8iNskkaNm",
            "symbol": "VSWAY",
            "name": "VOTERSWAY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wevote-project/votersway/main/benny.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FFg7BMsmxiwVSrKXrKHrKEwaHTEYSUfdzBBCoJZfQsfo",
            "symbol": "GUA",
            "name": "Gua Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFg7BMsmxiwVSrKXrKHrKEwaHTEYSUfdzBBCoJZfQsfo/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/snft2022",
                "website": "https://solana-nft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EPSivq2E7s6PwqijJpAAqmj3vogxn6yV9ZgnxE3GxbSM",
            "symbol": "EPSI",
            "name": "Epsilon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPSivq2E7s6PwqijJpAAqmj3vogxn6yV9ZgnxE3GxbSM/logo.png",
            "tags": [
                "viral-token",
                "meme-token",
                "capped-supply",
                "defatory-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hqt7YjTJcZt1QwR7G6P3y8FQarzZSH1kxJ9sW1j4UCpk",
            "symbol": "SOLH",
            "name": "Sol-Hotels",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SOLHotel/Crypto/main/sol-by-melia-2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HbCRtAoxfYBVsYHSqHv256sEezRZF23dw8vqneEwHobp",
            "symbol": "CLDY",
            "name": "Cloudie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Master-Teddy/crypto.logo/main/crypto.logo.png",
            "tags": [
                "social-token",
                "viral-token",
                "meme-token",
                "capped-supply",
                "defatory-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBrv9C5isNXcYm5YxouHSw1VKiBZtmFmsgCPscoKhY8i",
            "symbol": "ALL",
            "name": "Allowance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jamroszk/crypto/main/All_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FNwzqPdAF4rXS8tny3RrdvQemUsZkShouceBK2MnFyPT",
            "symbol": "SHIPO",
            "name": "Shiba Poker Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shibasolana/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5DmWkHuc6YwWGSQmAS39BvWqKL7q5k3PfSobPQ6eYjSf",
            "symbol": "ALC",
            "name": "Alice Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KyleF0X/crypto/main/AliceCoinNew.png",
            "tags": [
                "social-token",
                "utility-token",
                "campaign-token",
                "national-elections",
                "future-primeminister"
            ]
        },
        {
            "chainId": 101,
            "address": "7Y8TLC4fdQqS4EnBLhhw9ivSZcn2Gs4sJjfk9tu7PChz",
            "symbol": "FOXC",
            "name": "Fox Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KyleF0X/crypto/main/FoxCoin.png",
            "tags": [
                "social-token",
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3TUw5cHyMAwzXU6JqarUwizYdzdfqxvAJBcWeFHcZyiK",
            "symbol": "GRNT",
            "name": "Grant Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KyleF0X/crypto/main/GrantCoinNew.png",
            "tags": [
                "social-token",
                "utility-token",
                "philanthropy-token",
                "change-the-world"
            ]
        },
        {
            "chainId": 101,
            "address": "GYX6vH8CerAGKxikCXGhX4uSFpwqrTP4dRqMZrm5Cn3r",
            "symbol": "TTS",
            "name": "The Travel Shop",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ollisailing/tts/main/tts.png",
            "tags": [
                "travel-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dys38dBBBCBzwfP5WuxNb2hcQdzJMMLZEFXeadtxEXCZ",
            "symbol": "MCCN",
            "name": "mccoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/drx36/mccoin/main/mccoin.png",
            "tags": [
                "mcdonalds"
            ]
        },
        {
            "chainId": 101,
            "address": "ggsgHDoX6tACq25XhQPUmbza8Fzwp9WdAzTU1voTwDi",
            "symbol": "GGSG",
            "name": "Galactic Geckos Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ggsgHDoX6tACq25XhQPUmbza8Fzwp9WdAzTU1voTwDi/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "2x6dmFevT3MGCZYPE2eagautjyQFVq4YwHsKow5bCVCs",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "8qeb2aBZtvTXc2oGTTzKSjqrKLVFQXn7WAjGhJzqsBqi",
            "symbol": "FWDLA",
            "name": "FlowDolla",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/keanuvanderbie/logo/main/download.png",
            "tags": [
                "flowdolla"
            ]
        },
        {
            "chainId": 101,
            "address": "85JRDbTen5BnkeFSzATnwYmGENfCgst1zc2HrTdKF2hy",
            "symbol": "THRC",
            "name": "3coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/smileyprogrammer/solana-cryptoc-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AtBB9xQCFfib9BzXeFPnZAvmuA6dJYsHn3brA9UrPXxr",
            "symbol": "QUINN",
            "name": "QuinnCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sbquinn/QuinnCoin/main/qclogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3rxQA46tE9a96WBgFxCntiyyYuprTma6e9hXuDNLBsvV",
            "symbol": "Mcb",
            "name": "MarrsCoin Beta",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "14f1nVCKADXMWXaRpuRzzhSwa9wYMAuuxiN9cETM3eue",
            "symbol": "RJOT",
            "name": "RJO Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rjorgan/cryptocurrency/main/RJOT.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9RnsxByFw8RsYHoidmoEL4mupr7VgRk5ozpKPCHDPaBS",
            "symbol": "VCTR",
            "name": "Vectorium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PaulJoych/vectorium/main/logo.png",
            "tags": [
                "social-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G1TiV92UmbRj8nYH7HoJXFmnfFJ8aTdyZYvpjnLcMLro",
            "symbol": "SOV",
            "name": "soviet-token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eberling/soviet/main/New%20Project.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4yHJZqqV5hPJHt59EwEQ1y2Mh1ESwJBpZcUyU5TMLgJR",
            "symbol": "WHP",
            "name": "WhiteHen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TrishkaV/WhiteHenProject/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7sqrNp7MxNwpXwB72Qp3CWfTF27D7SpV48MCwAteoYDb",
            "symbol": "UFCS",
            "name": "UF Computer Science Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/inventari/crypto/main/UFCS.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Token created for the department of Computer Science at University of Findlay for staff, students, and advisory board members",
                "website": "https://www.findlay.edu/sciences/computer-science/"
            }
        },
        {
            "chainId": 101,
            "address": "CZZxxnXY76xv5tebfufZXgH9DTsyYnW4fUV8cuCXUQux",
            "symbol": "CZZ",
            "name": "Chai Zuzim",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CZZxxnXY76xv5tebfufZXgH9DTsyYnW4fUV8cuCXUQux/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/chaizuzim",
                "website": "https://chaizuzim.com"
            }
        },
        {
            "chainId": 101,
            "address": "9Dzex6kbpTBSyPFaCu1QkkALp4tpegmxD1vMgHRQVR7S",
            "symbol": "MELCO",
            "name": "Melo Coin",
            "decimals": 0,
            "logoURI": "https://github.com/melodasi/crypto/blob/main/melocoinlogo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "uZZ8ptqyGMWBK1WBtpTSmctw5pUj3cgMZAEauk7Wgw2",
            "symbol": "NIAR",
            "name": "Arsanya",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Templight41/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F3DShX6CavzqRwVSmDfkoih8xGVeSy3RzzxvUxmszi3D",
            "symbol": "LBD",
            "name": "Liebde Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/liebde/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cnptf46Jh1RkjtV27SCd6J45XHVQWcnZ4WfWMAzLHePo",
            "symbol": "RKZC",
            "name": "Rekzi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cnptf46Jh1RkjtV27SCd6J45XHVQWcnZ4WfWMAzLHePo/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EMorf1mG8t3TeJfoE9bNNZkC4Hsif9hC9TU8yziHcPYc",
            "symbol": "SHAKUDO",
            "name": "ShakudoX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EMorf1mG8t3TeJfoE9bNNZkC4Hsif9hC9TU8yziHcPYc/logo.png",
            "extensions": {
                "website": "https://shakudo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AS86ZXVsSrVVh9ZJ4WFJmq1teSvayFfG7qzfc5e8UxvM",
            "symbol": "SCAM_053C",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AS86ZXVsSrVVh9ZJ4WFJmq1teSvayFfG7qzfc5e8UxvM/logo.png"
        },
        {
            "chainId": 101,
            "address": "9Cs1JW3uMyzLnCduK8ukDJWUWgVGHV5LiWjvQztxf6uH",
            "symbol": "SCAM_0BAE",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Cs1JW3uMyzLnCduK8ukDJWUWgVGHV5LiWjvQztxf6uH/logo.png"
        },
        {
            "chainId": 101,
            "address": "5LVTQYHeu5jx1oTbwLQF9QEdPqV9KPUgxm1772fArY1p",
            "symbol": "SCAM_01E2",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5LVTQYHeu5jx1oTbwLQF9QEdPqV9KPUgxm1772fArY1p/logo.png"
        },
        {
            "chainId": 101,
            "address": "8PYkbcenAWxniMCPKAAuQyxpvnw681nL9nJjwTHA6c7j",
            "symbol": "SCAM_6617",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PYkbcenAWxniMCPKAAuQyxpvnw681nL9nJjwTHA6c7j/logo.png"
        },
        {
            "chainId": 101,
            "address": "Aeg9tGNoa36G59WNZc3EAUN3t1mfFE5c4xFPAjGWweR2",
            "symbol": "SCAM_B8CF",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aeg9tGNoa36G59WNZc3EAUN3t1mfFE5c4xFPAjGWweR2/logo.png"
        },
        {
            "chainId": 101,
            "address": "4vzavYtxLVCKGguiCkKCJ9zbg8z2NoCTDfpaMcdv8yon",
            "symbol": "SCAM_D256",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4vzavYtxLVCKGguiCkKCJ9zbg8z2NoCTDfpaMcdv8yon/logo.png"
        },
        {
            "chainId": 101,
            "address": "HEqcDLPDBMNeZwmxqqSAq8xGQB28E5BTgNTDm3oxPvGa",
            "symbol": "SCAM_7BB4",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEqcDLPDBMNeZwmxqqSAq8xGQB28E5BTgNTDm3oxPvGa/logo.png"
        },
        {
            "chainId": 101,
            "address": "6L2y6QBsPq3fFfjSfRx2HS6fAoLETY77oWfuYWUSub67",
            "symbol": "SCAM_A296",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6L2y6QBsPq3fFfjSfRx2HS6fAoLETY77oWfuYWUSub67/logo.png"
        },
        {
            "chainId": 101,
            "address": "7ezQ4XB3yqQhvXfnWc8vRGvvfYuF419fJtSFLKrHTGJF",
            "symbol": "SCAM_9DCD",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ezQ4XB3yqQhvXfnWc8vRGvvfYuF419fJtSFLKrHTGJF/logo.png"
        },
        {
            "chainId": 101,
            "address": "Du9SG4NW2q5PDzDcGqVQaXWXLbAGqxKhHgCKUJrwth8n",
            "symbol": "SCAM_19FF",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Du9SG4NW2q5PDzDcGqVQaXWXLbAGqxKhHgCKUJrwth8n/logo.png"
        },
        {
            "chainId": 101,
            "address": "7Ne4bR2k1fUxGcH98KwdNYj1HYcHNGkm3EhMsP7p4DdV",
            "symbol": "SCAM_B1BE",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Ne4bR2k1fUxGcH98KwdNYj1HYcHNGkm3EhMsP7p4DdV/logo.png"
        },
        {
            "chainId": 101,
            "address": "EhEnDTgsDr2CSv7xBtgquwnrdScyWQEFgSP7Aycpoz9H",
            "symbol": "SCAM_D610",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EhEnDTgsDr2CSv7xBtgquwnrdScyWQEFgSP7Aycpoz9H/logo.png"
        },
        {
            "chainId": 101,
            "address": "7idro6sV7p4TDjqcDGaH9ofQgDy6yBKxCK65NtS7A9AP",
            "symbol": "SCAM_DCBD",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7idro6sV7p4TDjqcDGaH9ofQgDy6yBKxCK65NtS7A9AP/logo.png"
        },
        {
            "chainId": 101,
            "address": "Dne2srRF7Nv2sJHAEGgT2NfPCcgX1Qcmm3pqzyPvxJmr",
            "symbol": "SCAM_FFFF",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dne2srRF7Nv2sJHAEGgT2NfPCcgX1Qcmm3pqzyPvxJmr/logo.png"
        },
        {
            "chainId": 101,
            "address": "Hdu7gvU8s48wE9HKRnUkpnjFeWAebNHghCGehUMGJPib",
            "symbol": "SCAM_FB20",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hdu7gvU8s48wE9HKRnUkpnjFeWAebNHghCGehUMGJPib/logo.png"
        },
        {
            "chainId": 101,
            "address": "94KpMsJPfynoGwsuaKePnZgvn3X4D1FYC23p8D8JB3fr",
            "symbol": "SCAM_534B",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94KpMsJPfynoGwsuaKePnZgvn3X4D1FYC23p8D8JB3fr/logo.png"
        },
        {
            "chainId": 101,
            "address": "38ns993xRAXXxbun3PU634SbBsrkQewJjxztZsJLAaJr",
            "symbol": "SCAM_BD15",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38ns993xRAXXxbun3PU634SbBsrkQewJjxztZsJLAaJr/logo.png"
        },
        {
            "chainId": 101,
            "address": "3Qug1jMgAhLvo8A95BVAwtYXfKct3Bz3iw4aMFqGfs9p",
            "symbol": "SCAM_9BFF",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Qug1jMgAhLvo8A95BVAwtYXfKct3Bz3iw4aMFqGfs9p/logo.png"
        },
        {
            "chainId": 101,
            "address": "4nfhEh4K2UY6SWY7HkoSLZLerFJCUsyBiQqiEkxqXn6N",
            "symbol": "SCAM_1491",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4nfhEh4K2UY6SWY7HkoSLZLerFJCUsyBiQqiEkxqXn6N/logo.png"
        },
        {
            "chainId": 101,
            "address": "97abH276RFkjuCt5ZoqZJ58fpSoJpEN4oxyBTjXU1DjD",
            "symbol": "SCAM_5D91",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97abH276RFkjuCt5ZoqZJ58fpSoJpEN4oxyBTjXU1DjD/logo.png"
        },
        {
            "chainId": 101,
            "address": "F5ocHboe2BMjW7iUcinQnTtPV2JFbDhWj8dUvb9mEEY6",
            "symbol": "SCAM_42B1",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F5ocHboe2BMjW7iUcinQnTtPV2JFbDhWj8dUvb9mEEY6/logo.png"
        },
        {
            "chainId": 101,
            "address": "BqKAcnMm9tvF3RJvxA6H75XpM9d7VZuNZzYiLW1vaGMo",
            "symbol": "SCAM_3A41",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BqKAcnMm9tvF3RJvxA6H75XpM9d7VZuNZzYiLW1vaGMo/logo.png"
        },
        {
            "chainId": 101,
            "address": "J4ciBemqvv8zeWsrWz8sik694KEYEBotxyXZubcu2oQc",
            "symbol": "SCAM_0247",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J4ciBemqvv8zeWsrWz8sik694KEYEBotxyXZubcu2oQc/logo.png"
        },
        {
            "chainId": 101,
            "address": "DqWweYSkTEQLQ2eNoUgF9vAZEdar5jpyTSEaKrxviQw4",
            "symbol": "SCAM_2C3B",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DqWweYSkTEQLQ2eNoUgF9vAZEdar5jpyTSEaKrxviQw4/logo.png"
        },
        {
            "chainId": 101,
            "address": "7mJ33816m8RE3Pb34WsHRLCJnYiWsexzoJE4Ss5pWeuf",
            "symbol": "SCAM_0DF7",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7mJ33816m8RE3Pb34WsHRLCJnYiWsexzoJE4Ss5pWeuf/logo.png"
        },
        {
            "chainId": 101,
            "address": "Hn6Ev3AYAGt9HDNuKcekELrXu41cyovWzhJCYaZoApMN",
            "symbol": "SCAM_4777",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hn6Ev3AYAGt9HDNuKcekELrXu41cyovWzhJCYaZoApMN/logo.png"
        },
        {
            "chainId": 101,
            "address": "5UNCikpehY6AjK8Ytr5JAEbZZtqonwcucpj6LyyQkz24",
            "symbol": "SCAM_A900",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5UNCikpehY6AjK8Ytr5JAEbZZtqonwcucpj6LyyQkz24/logo.png"
        },
        {
            "chainId": 101,
            "address": "8NBhVydpYftG95YMMEcW7rGGMWEcxRRMof3jHyYPzFxw",
            "symbol": "SCAM_E9E1",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NBhVydpYftG95YMMEcW7rGGMWEcxRRMof3jHyYPzFxw/logo.png"
        },
        {
            "chainId": 101,
            "address": "2mJ63AfLKHn7eiqtQNgG2tGW78wBZbaHceouiyww8yEK",
            "symbol": "SCAM_4E5A",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mJ63AfLKHn7eiqtQNgG2tGW78wBZbaHceouiyww8yEK/logo.png"
        },
        {
            "chainId": 101,
            "address": "ARHsGjpku8r5U8m1N3sMbU4xj5kPVuHYBKSaUXocovWa",
            "symbol": "SCAM_2BED",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ARHsGjpku8r5U8m1N3sMbU4xj5kPVuHYBKSaUXocovWa/logo.png"
        },
        {
            "chainId": 101,
            "address": "DVtyWhNsgTh3dVh2JeEpRKwB85dtYeF8eGcvtEBYmFjW",
            "symbol": "SCAM_B362",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DVtyWhNsgTh3dVh2JeEpRKwB85dtYeF8eGcvtEBYmFjW/logo.png"
        },
        {
            "chainId": 101,
            "address": "62yapYKJW1UrRqGUUKRSrvHJf6pX6oqLUEgG7B9BqYWL",
            "symbol": "SCAM_05F3",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/62yapYKJW1UrRqGUUKRSrvHJf6pX6oqLUEgG7B9BqYWL/logo.png"
        },
        {
            "chainId": 101,
            "address": "4TFZPK4ynPE3WeP9jX4MGYWgZcS7g1BTtLqfffg4qZth",
            "symbol": "SCAM_7843",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4TFZPK4ynPE3WeP9jX4MGYWgZcS7g1BTtLqfffg4qZth/logo.png"
        },
        {
            "chainId": 101,
            "address": "2BbnhPHBQEx5d292D5fJjhfEMf9v9BC6zbgzxujZnEFP",
            "symbol": "SCAM_23B6",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2BbnhPHBQEx5d292D5fJjhfEMf9v9BC6zbgzxujZnEFP/logo.png"
        },
        {
            "chainId": 101,
            "address": "7puLTNvfApp9hYgZC3pSCqL64e5ULjezJN4a6fH86cBS",
            "symbol": "SCAM_47F8",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7puLTNvfApp9hYgZC3pSCqL64e5ULjezJN4a6fH86cBS/logo.png"
        },
        {
            "chainId": 101,
            "address": "68ghtDyk3YxAXVtMbvCd2J9j1hD3TLdVfG417zonzV6d",
            "symbol": "SCAM_6AFF",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/68ghtDyk3YxAXVtMbvCd2J9j1hD3TLdVfG417zonzV6d/logo.png"
        },
        {
            "chainId": 101,
            "address": "ARHaNjDLfkxrE9SQNHuuUihEt8SsN5yRxjTPuzpgPXH4",
            "symbol": "SCAM_18AD",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ARHaNjDLfkxrE9SQNHuuUihEt8SsN5yRxjTPuzpgPXH4/logo.png"
        },
        {
            "chainId": 101,
            "address": "DbgFXFkjA9hpej7fSSivxDRXjFQwimjgWGu5gSVy7yqq",
            "symbol": "SCAM_E4E0",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DbgFXFkjA9hpej7fSSivxDRXjFQwimjgWGu5gSVy7yqq/logo.png"
        },
        {
            "chainId": 101,
            "address": "HRbw1bM7pQX98DSVbHXUegDwv6kbMKrUjF9QSCv9tp8F",
            "symbol": "SCAM_4A2F",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRbw1bM7pQX98DSVbHXUegDwv6kbMKrUjF9QSCv9tp8F/logo.png"
        },
        {
            "chainId": 101,
            "address": "EnC3T8r4ghh2Ck6dh9Ry5eXVPFi6WvxBZV7ZCcK8HtKr",
            "symbol": "SCAM_FFCB",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EnC3T8r4ghh2Ck6dh9Ry5eXVPFi6WvxBZV7ZCcK8HtKr/logo.png"
        },
        {
            "chainId": 101,
            "address": "4cwKg4T5XM4heJ3XWFBg9ZXxbcnJb2ehqQqoCnewoPk4",
            "symbol": "SCAM_5480",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4cwKg4T5XM4heJ3XWFBg9ZXxbcnJb2ehqQqoCnewoPk4/logo.png"
        },
        {
            "chainId": 101,
            "address": "D2ge6U5WiVe9eAcrX85XETuymm4B4FPs4VJdSHb6M53C",
            "symbol": "SCAM_A4B4",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D2ge6U5WiVe9eAcrX85XETuymm4B4FPs4VJdSHb6M53C/logo.png"
        },
        {
            "chainId": 101,
            "address": "GrQaiQFxRSwdCpK9iWF35nJXmfP53UEB4jTSNRfEEpnv",
            "symbol": "SCAM_22B1",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GrQaiQFxRSwdCpK9iWF35nJXmfP53UEB4jTSNRfEEpnv/logo.png"
        },
        {
            "chainId": 101,
            "address": "9ZcZ2x94JkCEi863gQwterYkaNFCT266KmMbRV3iaBMC",
            "symbol": "SCAM_BE4E",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ZcZ2x94JkCEi863gQwterYkaNFCT266KmMbRV3iaBMC/logo.png"
        },
        {
            "chainId": 101,
            "address": "CatvdK2bzfREDnFEnydcodB4yaV39qkSiGphzxMwCmzE",
            "symbol": "SCAM_62AB",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CatvdK2bzfREDnFEnydcodB4yaV39qkSiGphzxMwCmzE/logo.png"
        },
        {
            "chainId": 101,
            "address": "D2gQhgJz7DM4gUBcuCe7HtCtJtMqvdSSX7LXV2j2hUVJ",
            "symbol": "SCAM_963D",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D2gQhgJz7DM4gUBcuCe7HtCtJtMqvdSSX7LXV2j2hUVJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "ErSwFxb8wJRzpyRr2WSUa9iD9ViS4wnvyTi9AWDWoAPE",
            "symbol": "SCAM_55B4",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ErSwFxb8wJRzpyRr2WSUa9iD9ViS4wnvyTi9AWDWoAPE/logo.png"
        },
        {
            "chainId": 101,
            "address": "4tVV3AaapvszL6LrWiHoBVYue9h6Hb9wmQ4DYnTYVWeT",
            "symbol": "SCAM_F6FB",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tVV3AaapvszL6LrWiHoBVYue9h6Hb9wmQ4DYnTYVWeT/logo.png"
        },
        {
            "chainId": 101,
            "address": "4mUpkwjw7Tj5YSwxZHKuE5hb8PLe5gqMvx2mDqAPojWd",
            "symbol": "SHIVA",
            "name": "Shiva Sharma",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shivasharma/crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GWgwUUrgai3BFeEJZp7bdsBSYiuDqNmHf9uRusWsf3Yi",
            "symbol": "SAFU",
            "name": "1SAFU",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWgwUUrgai3BFeEJZp7bdsBSYiuDqNmHf9uRusWsf3Yi/logo.png",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "coingeckoId": "1safu",
                "discord": "https://discord.gg/avdNF9s4CF",
                "serumV3Usdc": "H8sjxDJWVxC3kP5uZ3p9eZncc4Czn9pTWEA6bH2XX7rM",
                "telegram": "https://t.me/sol1safu",
                "twitter": "https://twitter.com/1safu_sol",
                "website": "https://www.1safu.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EvG67NNXyM3yHk13EpjV8JgzdLgAuydR8au1zKpiGmkt",
            "symbol": "DAKPLS",
            "name": "DakPlus BV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DakPlus/dakplus-token/main/DakPlusPNG.png",
            "tags": [
                "anchor-token",
                "BlockExchange"
            ]
        },
        {
            "chainId": 101,
            "address": "BbrhzVrirDHJhByTHCtHc2YQd9pNfkwKZtjjhNuF6RYS",
            "symbol": "SKYC",
            "name": "SkyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BbrhzVrirDHJhByTHCtHc2YQd9pNfkwKZtjjhNuF6RYS/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9oiC3NueSWBAuGPj4ophbdwW9STQFD9AtCgcwhrC4P6M",
            "symbol": "GRNLF",
            "name": "GreenLeaf",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Malkowiak14/logo/main/greenleafproject1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EPtUhHru9MNMvgHgdMxzgFY4DSL1gs5Aa47rcPt9mZHM",
            "symbol": "Db523",
            "name": "Db523 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Db523/Db523-Coin/main/hahfadfha%3Blh.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4iFrrgC6qZj6AQcMi61KfoGPejUztzENpDH4wSBpTT4W",
            "symbol": "BOHEM",
            "name": "Gallery Token",
            "decimals": 0,
            "logoURI": "https://www.arweave.net/5abqgGa3kuMhCdQ09OOtmw7EzQbk-Fsa_tBt000JAg8?ext=gif",
            "tags": [
                "utility-token",
                "mint-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BohemiaArtFair",
                "website": "https://bohemia.gallery"
            }
        },
        {
            "chainId": 101,
            "address": "5yipeWXSNQTySJ2sj2Qp21ZTm9HHWwpGqGJx3rBP6PD7",
            "symbol": "OLIDO",
            "name": "Olido",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/olidomix/crypto/main/logo.png",
            "tags": [
                "fan-token",
                "music-token"
            ],
            "extensions": {
                "youtube": "https://www.youtube.com/channel/UCK4gVVPR86YJVjrp2rKGmrg"
            }
        },
        {
            "chainId": 101,
            "address": "GY4hLpyh7ZvG5qW7y39LShLiKq2uX4o3zPWMpARaKwvj",
            "symbol": "CBGE",
            "name": "Cabbage Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AfterMA7H/crypto/main/Item_Cabbage.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ZHcr75m41a6quVZ6VHrRMeCpumUTzwuzEfhK65n8eop",
            "symbol": "Subt",
            "name": "Subtrack",
            "decimals": 6,
            "logoURI": "https://v-meter.com/images/sub.png",
            "tags": [
                "anchor-token",
                "BlockExchange"
            ]
        },
        {
            "chainId": 101,
            "address": "5j81MNxc3ru546HtUKq5b3qDg9qmqATZz89MYyKhdwhm",
            "symbol": "NPTC",
            "name": "Neptun Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/neptuncoin/token-list/main/assets/mainnet/5j81MNxc3ru546HtUKq5b3qDg9qmqATZz89MYyKhdwhm/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A9nPLZSP48mLYC89sqdBg6jmgnTH6Do1fRCN36SXCRqA",
            "symbol": "CAMPTOKEN",
            "name": "Camp",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9nPLZSP48mLYC89sqdBg6jmgnTH6Do1fRCN36SXCRqA/logo.png"
        },
        {
            "chainId": 101,
            "address": "JDX52hspqXahKheh1dnpZ3wqjWsezjSeiGZdQbYzy7P7",
            "symbol": "AIHK",
            "name": "IrabalaincHK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/irabalainch/irabalainchcrypto/main/LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5XQM5N6zFa8hwWQm3CAMzULytvG4rqnedwTrxA5p8nKH",
            "symbol": "RCOIN",
            "name": "Rosecoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fizztune/rosecoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "95qcUshGDmh7dPgKTUn8NwYNR3HxzZr7cJS4nT1XpLmU",
            "symbol": "BC",
            "name": "BCredit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomas0050/Bcredit/main/BCredit.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ErG1AJ6bgYGkWauJcJ7LMiH4vC6iXThmNwgfmYLW42TZ",
            "symbol": "SM",
            "name": "SMCOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/salik111/crypto/main/Drawing.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HjG8tgUvhrpVoxv184wkZCQxUaB1e6AneoQbwMoZQ1nG",
            "symbol": "LEAF",
            "name": "Leaf Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HjG8tgUvhrpVoxv184wkZCQxUaB1e6AneoQbwMoZQ1nG/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://perkypikas.com"
            }
        },
        {
            "chainId": 101,
            "address": "DcAuapsmRUfvfkxjHUfn9hCw4U7XN3MBt8cWrEwG13FP",
            "symbol": "CCCP",
            "name": "SovietCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eberling/soviet/main/New%20Project.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4mRFWeC8G49kpKPSVR49Xk9TDW8oXT8iw93QeaZryY8i",
            "symbol": "SMCH",
            "name": "Smooch",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nmaltais/smooch-coin/master/smooch-coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7KbJfvgSEjcaRHA3VSGnKQQs2bD48PuibLoSpNp4vDWq",
            "symbol": "HTKN",
            "name": "HoracioCoin",
            "decimals": 0,
            "logoURI": "https://github.com/hhtokenadmin/htoken/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cv2QrMYWmN9g6pcPmhAcZDTRDwqzsVQj43yTLnMHdBZa",
            "symbol": "TORG",
            "name": "TORGCrypto",
            "decimals": 0,
            "logoURI": "https://github.com/TORGCrypto/crypto/blob/main/TORG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FGx2QCpL5rJyADEQLj1NuishrhGa3aoxADkDkuQGD14M",
            "symbol": "KORR",
            "name": "KorrKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KorrSci/KorrKoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "72gHx36riVSVKoA7a6k1H93CA7yvaUVv5Yg54BSaSsWV",
            "symbol": "GLT",
            "name": "Green Life Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/OOSHAGGY/GREENLIFECOIN/main/edit%20png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hme4PHAPEXziq3cCYtHKkv4cKdiCE65wwRTaqVhx98sW",
            "symbol": "AKOW",
            "name": "KingArthurCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EyasZ/KingArthur/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EWvdioY8AY4btFj1EaK2wug9fc9WZaab52cpHFmoqMV3",
            "symbol": "NEUM",
            "name": "Starlight",
            "decimals": 9,
            "logoURI": "https://ipfs.io/ipfs/Qme78a6ciPwSia6znyZzNaoe1NC3e8Cmo7xbSTgVU1Nren",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://starlight.ooo"
            }
        },
        {
            "chainId": 101,
            "address": "3ZhSmk4X1TrjD1obBbN4yW4WiN68QpuD9f2zW1udm1Kt",
            "symbol": "MLC",
            "name": "Money Laundering Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/youngreezy7/crypto/main/IMG_206.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BsS6MEq7ZkdcggeAoiafi1XgNbqXDULi44FLLLJ6fGPv",
            "symbol": "MWRC",
            "name": "MoonWar Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/colinantonell/MWRC/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E8LYrZeJ1B84PVhyUS58BLgifWgXLvuot2LQsmCR9Wv4",
            "symbol": "LEAF",
            "name": "Leaf Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E8LYrZeJ1B84PVhyUS58BLgifWgXLvuot2LQsmCR9Wv4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://perkypikas.com"
            }
        },
        {
            "chainId": 101,
            "address": "AV8C9U2HKUf5kKN1u3Nyr9KTZ8Vc69FCmwFbp4xSFRaD",
            "symbol": "SIG",
            "name": "Sigmoid",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AV8C9U2HKUf5kKN1u3Nyr9KTZ8Vc69FCmwFbp4xSFRaD/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZBkNgmPFienXwWVPg5LRSf3nZTKEHc9BQrayWPjJfQ1",
            "symbol": "KRL",
            "name": "KoralCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/baylonp/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CsckyTZUQK3zWu94TD5i4Uk5SJji8DUQ5npCfF1XKhYa",
            "symbol": "THRD",
            "name": "ThreeD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DoogieMane/Crypto/main/3dcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CJtomtm2VeozXMsabvD7pB9wkKy5LC8hW3XopqxQDqBu",
            "symbol": "PRBLY",
            "name": "probably.legal",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJtomtm2VeozXMsabvD7pB9wkKy5LC8hW3XopqxQDqBu/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/TkJxyyee",
                "facebook": "https://www.facebook.com/ProbablyLegalToken/",
                "instagram": "https://www.instagram.com/probablylegaltoken/",
                "twitter": "https://twitter.com/probablylegal",
                "website": "https://probably.legal"
            }
        },
        {
            "chainId": 101,
            "address": "3aw2q4WSkHeC8sdFkaePafhUTaU1q3oVxP4rUzLT118U",
            "symbol": "VLAD",
            "name": "VladCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benalbrinck/cryptocurrency/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3WP53uLQiHGpsHZ4KwzPyAVuH9zWEfRWunEoNkWLSkA4",
            "symbol": "COLT",
            "name": "ColtCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/McSlippin/LOG/main/ColtCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "37KKubWwu7c7fqt9zowdNn2LLatJk84GGpsyaNdBHzoM",
            "symbol": "ROLR",
            "name": "Roller",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/beltramin/crypto/main/roller-skate.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MirrKKDC4ehQ2M2rvebJ36rvifBicUNYi8AA72N2sig",
            "symbol": "MIR",
            "name": "Mirror",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MirrKKDC4ehQ2M2rvebJ36rvifBicUNYi8AA72N2sig/logo.png",
            "tags": [
                "mirror-upon-mirror",
                "as-super-cycle",
                "capped-supply",
                "deflatory"
            ]
        },
        {
            "chainId": 101,
            "address": "3RDQ9dBYwb2LxNsEHM6spWYzkL4sgafgBBArtaN5gZ8A",
            "symbol": "NCHC",
            "name": "NachoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ellocomoli/crypto/main/moneda10.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "98Jhm2LKMDR1qqTMjqRFqqc9x3sNX3XUveiG5Q63R1dz",
            "symbol": "CHRS",
            "name": "ChrisCrypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/schrismyers/scm-crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "family-token",
                "friendship-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BknVBihY9Dh4ohBpVJ42kLZA5KAtwnScib5GarswevFv",
            "symbol": "sglo",
            "name": "Sunglo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sdenham25/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7NnDGqCUGEzNiZUcFEQGUPPpWrLp5nPSpUXfyd65BH5q",
            "symbol": "ZEM",
            "name": "ZEMMOUR 2022",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7NnDGqCUGEzNiZUcFEQGUPPpWrLp5nPSpUXfyd65BH5q/logo.png",
            "tags": [
                "social-token",
                "ZEMMOUR",
                "ERIC",
                "ERICZEMMOUR",
                "ZEMMOUR2022",
                "PRESIDENTIELLE",
                "FRANCE"
            ],
            "extensions": {
                "telegram": "https://t.me/ericzemmour2022",
                "twitter": "https://twitter.com/ZemmourEric",
                "website": "https://www.zemmour2022.fr/"
            }
        },
        {
            "chainId": 101,
            "address": "SPon2FaeBahJ4ZLugFvnn7vijuMqX3vYbKwZdYYtEaN",
            "symbol": "SPO",
            "name": "Sponsor",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPon2FaeBahJ4ZLugFvnn7vijuMqX3vYbKwZdYYtEaN/logo.png",
            "tags": [
                "sponsor-token",
                "invest-in-talent",
                "profit-from-talent",
                "receive-support",
                "capped-supply",
                "deflatory-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ELthwAhCKAkNGBqUY4AyaBfZna4yLEQJm3XqTkw4vJJq",
            "symbol": "EIRE",
            "name": "EireCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eoinw/EireCoin/main/EireCoin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6risWR78e4RJrCAcuRfV4uojs7XfqoWsKojELN2DBnFZ",
            "symbol": "CHKS",
            "name": "ChicKoins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6risWR78e4RJrCAcuRfV4uojs7XfqoWsKojELN2DBnFZ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DaMmU96hrW14Yq2Zwf8uP63xrUKSL1u646PH8zsean6e",
            "symbol": "FK",
            "name": "Fakir coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faki16/crypto/main/crypto.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4fs5LAN4kpfefkEtuLW8MGY9GD8A34Ey6AMCGkiNGfnH",
            "symbol": "CBAS",
            "name": "CBas Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/retro-token/CBas-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4KkBpohZHE7UXLWbzuub469Xd1TxXRtteRx9B2KygwoG",
            "symbol": "PRINA",
            "name": "PrIna BBY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/preda-bogdan/preda-bogdan/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "98wjP2Tg1UPticB6TwapY3d8vgajUXUuMSLYA79c7Lqr",
            "symbol": "SLTR",
            "name": "Solitaire",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/pokersolface/solitairetoken/main/solitairetoken.png",
            "extensions": {
                "twitter": "https://twitter.com/solitairetoken"
            }
        },
        {
            "chainId": 101,
            "address": "3vAs4D1WE6Na4tCgt4BApgFfENbm8WY7q4cSPD1yM4Cg",
            "symbol": "AAVE",
            "name": "Aave Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vAs4D1WE6Na4tCgt4BApgFfENbm8WY7q4cSPD1yM4Cg/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
                "assetContract": "https://etherscan.io/address/0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "aave",
                "serumV3Usdc": "8WZrmdpLckptiVKd2fPHPjewRVYQGQkjxi9vzRYG1sfs",
                "serumV3Usdt": "LghsMERQWQFK3zWMTrUkoyAJARQw2wSmcYZjexeN3zy"
            }
        },
        {
            "chainId": 101,
            "address": "12uHjozDVgyGWeLqQ8DMCRbig8amW5VmvZu3FdMMdcaG",
            "symbol": "AKRO",
            "name": "Akropolis (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/12uHjozDVgyGWeLqQ8DMCRbig8amW5VmvZu3FdMMdcaG/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7",
                "assetContract": "https://etherscan.io/address/0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "akropolis",
                "serumV3Usdc": "G3h8NZgJozk9crme2me6sKDJuSQ12mNCtvC9NbSWqGuk",
                "serumV3Usdt": "DvbiPxKzuXZPcmUcYDqBz1tvUrXYPsNrRAjSeuwHtmEA"
            }
        },
        {
            "chainId": 101,
            "address": "3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x",
            "symbol": "ALEPH",
            "name": "Aleph.im (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x27702a26126e0B3702af63Ee09aC4d1A084EF628",
                "assetContract": "https://etherscan.io/address/0x27702a26126e0B3702af63Ee09aC4d1A084EF628",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "aleph-im",
                "serumV3Usdc": "Fw4mvuE7KZmTjQPxP2sRpHwPDfRMWnKBupFZGyW9CAQH",
                "serumV3Usdt": "GZeHR8uCTVoHVDZFRVXTgm386DK1EKehy9yMS3BFChcL"
            }
        },
        {
            "chainId": 101,
            "address": "AwEauVaTMQRB71WeDnwf1DWSBxaMKjEPuxyLr1uixFom",
            "symbol": "COMP",
            "name": "Compound (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AwEauVaTMQRB71WeDnwf1DWSBxaMKjEPuxyLr1uixFom/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc00e94Cb662C3520282E6f5717214004A7f26888",
                "assetContract": "https://etherscan.io/address/0xc00e94Cb662C3520282E6f5717214004A7f26888",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "compound-governance-token",
                "serumV3Usdc": "CU5L8JC83hyYZdf1phzy6a7X58eTtPjs7mHL3QKCcLfh",
                "serumV3Usdt": "9gA6T3HRCMTVTULte5pJsXjMDUGRtygTKLbMny6eRcyM"
            }
        },
        {
            "chainId": 101,
            "address": "A9ik2NrpKRRG2snyTjofZQcTuav9yH3mNVHLsLiDQmYt",
            "symbol": "FRONT",
            "name": "Frontier Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9ik2NrpKRRG2snyTjofZQcTuav9yH3mNVHLsLiDQmYt/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xf8C3527CC04340b208C854E985240c02F7B7793f",
                "assetContract": "https://etherscan.io/address/0xf8C3527CC04340b208C854E985240c02F7B7793f",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "frontier",
                "serumV3Usdc": "B95oZN5HCLGmFAhbzReWBA9cuSGPFQAXeuhm2FfpdrML",
                "serumV3Usdt": "DZTYyy1L5Pr6DmTtYY5bEuU9g3LQ4XGvuYiN3zS25yG7"
            }
        },
        {
            "chainId": 101,
            "address": "HGsLG4PnZ28L8A4R5nPqKgZd86zUUdmfnkTRnuFJ5dAX",
            "symbol": "GRT",
            "name": "Graph Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HGsLG4PnZ28L8A4R5nPqKgZd86zUUdmfnkTRnuFJ5dAX/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc944E90C64B2c07662A292be6244BDf05Cda44a7",
                "assetContract": "https://etherscan.io/address/0xc944E90C64B2c07662A292be6244BDf05Cda44a7",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "the-graph",
                "serumV3Usdc": "4PD799gihM2SdM8g7PxfSWgQR8cWGNiuzmNzcL2RgpSu",
                "serumV3Usdt": "5bzmeSmiCzeyDQvaSsQhEega7e2jhH39cFCkT4eqSDSx"
            }
        },
        {
            "chainId": 101,
            "address": "2ueY1bLcPHfuFzEJq7yN1V2Wrpu8nkun9xG2TVCE1mhD",
            "symbol": "HGET",
            "name": "Hedget (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ueY1bLcPHfuFzEJq7yN1V2Wrpu8nkun9xG2TVCE1mhD/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x7968bc6a03017eA2de509AAA816F163Db0f35148",
                "assetContract": "https://etherscan.io/address/0x7968bc6a03017eA2de509AAA816F163Db0f35148",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "hedget",
                "serumV3Usdc": "27e1mB6UoPohbc3MmwMXu5QM7b2E3k5Mbhwv6JguwyXg",
                "serumV3Usdt": "BdRzTEKb7Qdu4tWts5zXjwcpQErZxEzvShKZ5QcthMag"
            }
        },
        {
            "chainId": 101,
            "address": "5Wc4U1ZoQRzF4tPdqKQzBwRSjYe8vEf3EvZMuXgtKUW6",
            "symbol": "LUA",
            "name": "LuaToken (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Wc4U1ZoQRzF4tPdqKQzBwRSjYe8vEf3EvZMuXgtKUW6/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xb1f66997a5760428d3a87d68b90bfe0ae64121cc",
                "assetContract": "https://etherscan.io/address/0xb1f66997a5760428d3a87d68b90bfe0ae64121cc",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "luaswap",
                "serumV3Usdc": "J9imTcEeahZqKuaoQaPcCeSGCMWL8qSACpK4B7bC8NN4",
                "serumV3Usdt": "BMJ3CvQZ57cNnuc3Lz5Pb6cW6Sr9kZGz3qz2bJQTE24A"
            }
        },
        {
            "chainId": 101,
            "address": "CaGa7pddFXS65Gznqwp42kBhkJQdceoFVT7AQYo8Jr8Q",
            "symbol": "MATH",
            "name": "MATH Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CaGa7pddFXS65Gznqwp42kBhkJQdceoFVT7AQYo8Jr8Q/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x08d967bb0134f2d07f7cfb6e246680c53927dd30",
                "assetContract": "https://etherscan.io/address/0x08d967bb0134f2d07f7cfb6e246680c53927dd30",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "math",
                "serumV3Usdc": "G8L1YLrktaG1t8YBMJs3CwV96nExvJJCSpw3DARPDjE2",
                "serumV3Usdt": "CkvNfATB7nky8zPLuwS9bgcFbVRkQdkd5zuKEovyo9rs"
            }
        },
        {
            "chainId": 101,
            "address": "9BsnSWDPfbusseZfnXyZ3un14CyPMZYvsKjWY3Y8Gbqn",
            "symbol": "PERP",
            "name": "Perpetual (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9BsnSWDPfbusseZfnXyZ3un14CyPMZYvsKjWY3Y8Gbqn/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xbC396689893D065F41bc2C6EcbeE5e0085233447",
                "assetContract": "https://etherscan.io/address/0xbC396689893D065F41bc2C6EcbeE5e0085233447",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "perpetual-protocol",
                "serumV3Usdc": "Ao8HgYFCT2BJHxSusZbpJCPhvFMFXZApqN2uy2trbQRa",
                "serumV3Usdt": "5EoZqJZrmKmq1yeRkYAerbJhcs92DZbCtW86EhPYCio2"
            }
        },
        {
            "chainId": 101,
            "address": "DkbE8U4gSRuGHcVMA1LwyZPYUjYbfEbjW8DMR3iSXBzr",
            "symbol": "RSR",
            "name": "Reserve Rights (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DkbE8U4gSRuGHcVMA1LwyZPYUjYbfEbjW8DMR3iSXBzr/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x8762db106B2c2A0bccB3A80d1Ed41273552616E8",
                "assetContract": "https://etherscan.io/address/0x8762db106B2c2A0bccB3A80d1Ed41273552616E8",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "reserve-rights-token",
                "serumV3Usdc": "GqgkxEswUwHBntmzb5GpUhKrVpJhzreSruZycuJwdNwB",
                "serumV3Usdt": "2j2or38X2FUbpkK4gkgvjDtqN3ibkKw3v5yn7o2gHqPc"
            }
        },
        {
            "chainId": 101,
            "address": "5hcdG6NjQwiNhVa9bcyaaDsCyA1muPQ6WRzQwHfgeeKo",
            "symbol": "SWAG",
            "name": "Swag Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5hcdG6NjQwiNhVa9bcyaaDsCyA1muPQ6WRzQwHfgeeKo/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x87eDfFDe3E14c7a66c9b9724747a1C5696b742e6",
                "assetContract": "https://etherscan.io/address/0x87eDfFDe3E14c7a66c9b9724747a1C5696b742e6",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "swag-finance",
                "serumV3Usdc": "wSkeLMv3ktJyLm51bvQWxY2saGKqGxbnUFimPxbgEvQ",
                "serumV3Usdt": "6URQ4zFWvPm1fhJCKKWorrh8X3mmTFiDDyXEUmSf8Rb2"
            }
        },
        {
            "chainId": 101,
            "address": "FTtXEUosNn6EKG2SQtfbGuYB4rBttreQQcoWn1YDsuTq",
            "symbol": "UBXT",
            "name": "UpBots (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTtXEUosNn6EKG2SQtfbGuYB4rBttreQQcoWn1YDsuTq/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x8564653879a18C560E7C0Ea0E084c516C62F5653",
                "assetContract": "https://etherscan.io/address/0x8564653879a18C560E7C0Ea0E084c516C62F5653",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "upbots",
                "serumV3Usdc": "Hh4p7tJpqkGW6xsHM2LiPPMpJg43fwn5TbmVmfrURdLY",
                "serumV3Usdt": "5xhjc3ZtAwnBK3qsaro28VChL7WrxY9N4SG6UZpYxpGc"
            }
        },
        {
            "chainId": 101,
            "address": "BXZX2JRJFjvKazM1ibeDFxgAngKExb74MRXzXKvgikxX",
            "symbol": "YFI",
            "name": "yearn.finance (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXZX2JRJFjvKazM1ibeDFxgAngKExb74MRXzXKvgikxX/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
                "assetContract": "https://etherscan.io/address/0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "yearn-finance",
                "serumV3Usdc": "BiJXGFc1c4gyPpv9HLRJoKbZewWQrTCHGuxYKjYMQJpC",
                "serumV3Usdt": "9sue9TZAeUhNtNAPPGb9dke7rkJeXktGD3u8ZC37GWnQ"
            }
        },
        {
            "chainId": 101,
            "address": "HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv",
            "symbol": "ATS",
            "name": "Atlas Dex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv/logo.png",
            "tags": [
                "utility-token",
                "dex",
                "swap"
            ],
            "extensions": {
                "coingeckoId": "atlas-dex",
                "coinmarketcap": "https://coinmarketcap.com/currencies/atlas-dex/",
                "medium": "https://atlasdex.medium.com/",
                "twitter": "https://twitter.com/atlas_dex",
                "website": "https://atlasdex.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7gjNiPun3AzEazTZoFEjZgcBMeuaXdpjHq2raZTmTrfs",
            "symbol": "CRV",
            "name": "Curve DAO Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gjNiPun3AzEazTZoFEjZgcBMeuaXdpjHq2raZTmTrfs/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xd533a949740bb3306d119cc777fa900ba034cd52",
                "assetContract": "https://etherscan.io/address/0xd533a949740bb3306d119cc777fa900ba034cd52",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "curve-dao-token"
            }
        },
        {
            "chainId": 101,
            "address": "HCTVFTzHL21a1dPzKxAUeWwqbE8QMUyvgChFDL4XYoi1",
            "symbol": "LRC",
            "name": "LoopringCoin V2 (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCTVFTzHL21a1dPzKxAUeWwqbE8QMUyvgChFDL4XYoi1/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
                "assetContract": "https://etherscan.io/address/0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "loopring"
            }
        },
        {
            "chainId": 101,
            "address": "HRQke5DKdDo3jV7wnomyiM8AA3EzkVnxMDdo2FQ5XUe1",
            "symbol": "MIMet",
            "name": "Magic Internet Money (Portal from Ethereum)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRQke5DKdDo3jV7wnomyiM8AA3EzkVnxMDdo2FQ5XUe1/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
                "assetContract": "https://etherscan.io/address/0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "magic-internet-money"
            }
        },
        {
            "chainId": 101,
            "address": "EzZp7LRN1xwu3QsB2RJRrWwEGjJGsuWzuMCeQDB3NSPK",
            "symbol": "YGG",
            "name": "Yield Guild Games Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzZp7LRN1xwu3QsB2RJRrWwEGjJGsuWzuMCeQDB3NSPK/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x25f8087ead173b73d6e8b84329989a8eea16cf73",
                "assetContract": "https://etherscan.io/address/0x25f8087ead173b73d6e8b84329989a8eea16cf73",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "yield-guild-games"
            }
        },
        {
            "chainId": 101,
            "address": "DPgNKZJAG2w1S6vfYHDBT62R4qrWWH5f45CnxtbQduZE",
            "symbol": "TRIBE",
            "name": "Tribe (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DPgNKZJAG2w1S6vfYHDBT62R4qrWWH5f45CnxtbQduZE/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
                "assetContract": "https://etherscan.io/address/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "tribe"
            }
        },
        {
            "chainId": 101,
            "address": "ASk8bss7PoxfFVJfXnSJepj9KupTX15QaRnhdjs6DdYe",
            "symbol": "RGT",
            "name": "Rari Governance Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASk8bss7PoxfFVJfXnSJepj9KupTX15QaRnhdjs6DdYe/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xd291e7a03283640fdc51b121ac401383a46cc623",
                "assetContract": "https://etherscan.io/address/0xd291e7a03283640fdc51b121ac401383a46cc623",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "rari-governance-token"
            }
        },
        {
            "chainId": 101,
            "address": "EHKQvJGu48ydKA4d3RivrkNyTJTkSdoS32UafxSX1yak",
            "symbol": "AMPL",
            "name": "Ampleforth (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EHKQvJGu48ydKA4d3RivrkNyTJTkSdoS32UafxSX1yak/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xd46ba6d942050d489dbd938a2c909a5d5039a161",
                "assetContract": "https://etherscan.io/address/0xd46ba6d942050d489dbd938a2c909a5d5039a161",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "ampleforth"
            }
        },
        {
            "chainId": 101,
            "address": "BLvmrccP4g1B6SpiVvmQrLUDya1nZ4B2D1nm9jzKF7sz",
            "symbol": "CVX",
            "name": "Convex Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLvmrccP4g1B6SpiVvmQrLUDya1nZ4B2D1nm9jzKF7sz/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
                "assetContract": "https://etherscan.io/address/0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "convex-finance"
            }
        },
        {
            "chainId": 101,
            "address": "GWdkYFnXnSJAsCBvmsqFLiPPe2tpvXynZcJdxf11Fu3U",
            "symbol": "UFO",
            "name": "UFO Gaming (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWdkYFnXnSJAsCBvmsqFLiPPe2tpvXynZcJdxf11Fu3U/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b",
                "assetContract": "https://etherscan.io/address/0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "ufo-gaming"
            }
        },
        {
            "chainId": 101,
            "address": "DvjMYMVeXgKxaixGKpzQThLoG98nc7HSU7eanzsdCboA",
            "symbol": "CRO",
            "name": "Crypto.com Coin (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DvjMYMVeXgKxaixGKpzQThLoG98nc7HSU7eanzsdCboA/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
                "assetContract": "https://etherscan.io/address/0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "crypto-com-coin"
            }
        },
        {
            "chainId": 101,
            "address": "4hpngEp1v3CXpeKB81Gw4sv7YvwUVRKvY3SGag9ND8Q4",
            "symbol": "SLP",
            "name": "Smooth Love Potion (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hpngEp1v3CXpeKB81Gw4sv7YvwUVRKvY3SGag9ND8Q4/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25",
                "assetContract": "https://etherscan.io/address/0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "smooth-love-potion"
            }
        },
        {
            "chainId": 101,
            "address": "EDVVEYW4fPJ6vKw5LZXRGUSPzxoHrv6eWvTqhCr8oShs",
            "symbol": "BNT",
            "name": "Bancor (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EDVVEYW4fPJ6vKw5LZXRGUSPzxoHrv6eWvTqhCr8oShs/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
                "assetContract": "https://etherscan.io/address/0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "bancor-network"
            }
        },
        {
            "chainId": 101,
            "address": "8UJbtpsEubDVkY53rk7d61hNYKkvouicczB2XmuwiG4g",
            "symbol": "ILV",
            "name": "Illuvium (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UJbtpsEubDVkY53rk7d61hNYKkvouicczB2XmuwiG4g/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x767fe9edc9e0df98e07454847909b5e959d7ca0e",
                "assetContract": "https://etherscan.io/address/0x767fe9edc9e0df98e07454847909b5e959d7ca0e",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "illuvium"
            }
        },
        {
            "chainId": 101,
            "address": "CriXdFS9iRAYbGEQiTcUqbWwG9RBmYt5B6LwTnoJ61Sm",
            "symbol": "JOE",
            "name": "JoeToken (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CriXdFS9iRAYbGEQiTcUqbWwG9RBmYt5B6LwTnoJ61Sm/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd",
                "assetContract": "https://snowtrace.io/address/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd",
                "bridgeContract": "https://snowtrace.io/address/0x0e082f06ff657d94310cb8ce8b0d9a04541d8052",
                "coingeckoId": "joe"
            }
        },
        {
            "chainId": 101,
            "address": "3a2VW9t5N6p4baMW3M6yLH1UJ9imMt7VsyUk6ouXPVLq",
            "symbol": "KP3R",
            "name": "Keep3rV1 (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3a2VW9t5N6p4baMW3M6yLH1UJ9imMt7VsyUk6ouXPVLq/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44",
                "assetContract": "https://etherscan.io/address/0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "keep3rv1"
            }
        },
        {
            "chainId": 101,
            "address": "3EQ6LqLkiFcoxTeGEsHMFpSLWNVPe9yT7XPX2HYSFyxX",
            "symbol": "TOKE",
            "name": "Tokemak (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EQ6LqLkiFcoxTeGEsHMFpSLWNVPe9yT7XPX2HYSFyxX/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x2e9d63788249371f1dfc918a52f8d799f4a38c94",
                "assetContract": "https://etherscan.io/address/0x2e9d63788249371f1dfc918a52f8d799f4a38c94",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "tokemak"
            }
        },
        {
            "chainId": 101,
            "address": "Aqs5ydqKXEK2cjotDXxHmk8N9PknqQ5q4ZED4ymY1eeh",
            "symbol": "NXM",
            "name": "Nexus Mutual (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aqs5ydqKXEK2cjotDXxHmk8N9PknqQ5q4ZED4ymY1eeh/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b",
                "assetContract": "https://etherscan.io/address/0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "nexus-mutual"
            }
        },
        {
            "chainId": 101,
            "address": "D559HwgjYGDYsXpmFUKxhFTEwutvS9sya1kXiyCVogCV",
            "symbol": "AMP",
            "name": "Amp (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D559HwgjYGDYsXpmFUKxhFTEwutvS9sya1kXiyCVogCV/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
                "assetContract": "https://etherscan.io/address/0xff20817765cb7f73d4bde2e66e067e58d11095c2",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "amp"
            }
        },
        {
            "chainId": 101,
            "address": "GJa1VeEYLTRoHbaeqcxfzHmjGCGtZGF3CUqxv9znZZAY",
            "symbol": "ZRX",
            "name": "ZRX (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJa1VeEYLTRoHbaeqcxfzHmjGCGtZGF3CUqxv9znZZAY/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xe41d2489571d322189246dafa5ebde1f4699f498",
                "assetContract": "https://etherscan.io/address/0xe41d2489571d322189246dafa5ebde1f4699f498",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "0x"
            }
        },
        {
            "chainId": 101,
            "address": "8gC27rQF4NEDYfyf5aS8ZmQJUum5gufowKGYRRba4ENN",
            "symbol": "FTMet",
            "name": "Fantom Token (Portal from Ethereum)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8gC27rQF4NEDYfyf5aS8ZmQJUum5gufowKGYRRba4ENN/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x4e15361fd6b4bb609fa63c81a2be19d873717870",
                "assetContract": "https://etherscan.io/address/0x4e15361fd6b4bb609fa63c81a2be19d873717870",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "fantom"
            }
        },
        {
            "chainId": 101,
            "address": "HUCyuyqESEUV4YWTKFvvB4JiQLqoovscTBpRXfGzW4Wx",
            "symbol": "RPL",
            "name": "Rocket Pool (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUCyuyqESEUV4YWTKFvvB4JiQLqoovscTBpRXfGzW4Wx/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xd33526068d116ce69f19a9ee46f0bd304f21a51f",
                "assetContract": "https://etherscan.io/address/0xd33526068d116ce69f19a9ee46f0bd304f21a51f",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "rocket-pool"
            }
        },
        {
            "chainId": 101,
            "address": "8cTNUtcV2ueC3royJ642uRnvTxorJAWLZc58gxAo7y56",
            "symbol": "SNX",
            "name": "Synthetix Network Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8cTNUtcV2ueC3royJ642uRnvTxorJAWLZc58gxAo7y56/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
                "assetContract": "https://etherscan.io/address/0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "synthetix-network-token"
            }
        },
        {
            "chainId": 101,
            "address": "AuGz22orMknxQHTVGwAu7e3dJikTJKgcjFwMNDikEKmF",
            "symbol": "GALA",
            "name": "Gala (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AuGz22orMknxQHTVGwAu7e3dJikTJKgcjFwMNDikEKmF/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x15d4c048f83bd7e37d49ea4c83a07267ec4203da",
                "assetContract": "https://etherscan.io/address/0x15d4c048f83bd7e37d49ea4c83a07267ec4203da",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "gala",
                "serumV3Usdc": "F7WJsoxTWQRmAwAyFe9APmuVv4HqmhchFtdbR9dvAUDm"
            }
        },
        {
            "chainId": 101,
            "address": "Gq2norJ1kBemBp3mPfkgAUMhMMmnFmY4zEyi26tRcxFB",
            "symbol": "ANKR",
            "name": "Ankr Network (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gq2norJ1kBemBp3mPfkgAUMhMMmnFmY4zEyi26tRcxFB/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x8290333cef9e6d528dd5618fb97a76f268f3edd4",
                "assetContract": "https://etherscan.io/address/0x8290333cef9e6d528dd5618fb97a76f268f3edd4",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "ankr-network"
            }
        },
        {
            "chainId": 101,
            "address": "CLQsDGoGibdNPnVCFp8BAsN2unvyvb41Jd5USYwAnzAg",
            "symbol": "ENS",
            "name": "Ethereum Name Service (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLQsDGoGibdNPnVCFp8BAsN2unvyvb41Jd5USYwAnzAg/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
                "assetContract": "https://etherscan.io/address/0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "ethereum-name-service"
            }
        },
        {
            "chainId": 101,
            "address": "ABAq2R9gSpDDGguQxBk4u13s4ZYW6zbwKVBx15mCMG8",
            "symbol": "GT",
            "name": "GateChainToken (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ABAq2R9gSpDDGguQxBk4u13s4ZYW6zbwKVBx15mCMG8/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0xe66747a101bff2dba3697199dcce5b743b454759",
                "assetContract": "https://etherscan.io/address/0xe66747a101bff2dba3697199dcce5b743b454759",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "gatetoken"
            }
        },
        {
            "chainId": 101,
            "address": "CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA",
            "symbol": "PEOPLE",
            "name": "ConstitutionDAO (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x7a58c0be72be218b41c608b7fe7c5bb630736c71",
                "assetContract": "https://etherscan.io/address/0x7a58c0be72be218b41c608b7fe7c5bb630736c71",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "constitutiondao",
                "serumV3Usdc": "GsWEL352sYgQC3uAVKgEQz2TtA1RA5cgNwUQahyzwJyz"
            }
        },
        {
            "chainId": 101,
            "address": "6Q5fvsJ6kgAFmisgDqqyaFd9FURYzHf8MCUbpAUaGZnE",
            "symbol": "SOS",
            "name": "SOS (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Q5fvsJ6kgAFmisgDqqyaFd9FURYzHf8MCUbpAUaGZnE/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x3b484b82567a09e2588a13d54d032153f0c0aee0",
                "assetContract": "https://etherscan.io/address/0x3b484b82567a09e2588a13d54d032153f0c0aee0",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "opendao"
            }
        },
        {
            "chainId": 101,
            "address": "FUGsN8H74WjRBBMfQWcf9Kk32gebA9VnNaGxqwcVvUW7",
            "symbol": "gOHM",
            "name": "Governance OHM (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FUGsN8H74WjRBBMfQWcf9Kk32gebA9VnNaGxqwcVvUW7/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ],
            "extensions": {
                "address": "0x0ab87046fbb341d058f17cbc4c1133f25a20a52f",
                "assetContract": "https://etherscan.io/address/0x0ab87046fbb341d058f17cbc4c1133f25a20a52f",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "governance-ohm"
            }
        },
        {
            "chainId": 101,
            "address": "6nhvTcjTTWXvy2QHyXN8RuhUdu7jS37tn4TjLNZFKdDq",
            "symbol": "MATE",
            "name": "Matecoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ellocomoli/matecoin/main/matecoin1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J4pnYWmCfmygTN6ufvfKtjoD3sYFK61Z63yNSqnGGaQG",
            "symbol": "KWT",
            "name": "Kiwi Token (KWT)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J4pnYWmCfmygTN6ufvfKtjoD3sYFK61Z63yNSqnGGaQG/logo.png"
        },
        {
            "chainId": 101,
            "address": "BMdeBCDq93fDKqhSgNVKv8CSWsnjH1j4tZ3zbz6tP7Er",
            "symbol": "UKGP",
            "name": "ukg-private-token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Tief-Flieger/Crypto/main/ukg-Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D7G1c6A2msKSsUyTyDcu5FpLvPKDsrDChJCVZ1iVQWSE",
            "symbol": "UnDD",
            "name": "Unusual Due Diligence",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D7G1c6A2msKSsUyTyDcu5FpLvPKDsrDChJCVZ1iVQWSE/logo.jpg",
            "tags": [
                "metaverse",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.youtube.com/c/UnusualDueDiligence"
            }
        },
        {
            "chainId": 101,
            "address": "FSypHUKULT3MfRbHZHDVxqyv7yCscrs9gw2w3cJVDWFW",
            "symbol": "QTP",
            "name": "QuestLoop",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FSypHUKULT3MfRbHZHDVxqyv7yCscrs9gw2w3cJVDWFW/logo.png",
            "tags": [
                "social-token",
                "rewards",
                "chores",
                "kudos"
            ]
        },
        {
            "chainId": 101,
            "address": "2ZKpDFT88tER8CHRZcfzXZCerRRJFPRCrMgVNwRoMP6k",
            "symbol": "SRO",
            "name": "SRO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Sreerajv799/cryoto_solana/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HYTcR5XJFe6H548R44SCgR5PBzpRXRFx8rxr7M6XQdfJ",
            "symbol": "6IX9INE",
            "name": "SnitchCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xX420Gucci69Xx/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3hWWVQnwcSW9BUW6jGBrpo2kii4b2DR8K4uQo4jhfxpJ",
            "symbol": "ETRT",
            "name": "EternalT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RaizelT/New/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3D1SjVC4z9WDaq1vdaJcHMNEjWwpEQrVY4eckm65nEJV",
            "symbol": "DDPC",
            "name": "Double Diamond Possum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/digitaldog888/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DNKj7KdzWtAGashhNw2EDmXpTzQxJ5R6qtUzuSVeJVhZ",
            "symbol": "ORBI",
            "name": "ORBI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/orbi11/token/main/orbi.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8NTbAqX4aQtWjGpR3vLZB5xMgwfiT9eyr1eVd99zaTFa",
            "symbol": "RAPP",
            "name": "RappToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dara-79/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ENkxkB6m9rKksYZsjmz3LsCdc79Tw2TS5agC4m2674cQ",
            "symbol": "BOND",
            "name": "Bond Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dc-bond/crypto/main/Bond-Token-Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ho9wbHLmYEUNgD6qLReTJnNmMN3G8iZ6D8fa77ktnB1E",
            "symbol": "MKM",
            "name": "Mikimouche Coin",
            "decimals": 0,
            "logoURI": "https://github.com/MichaelGomes98/MikimoucheCoin/blob/main/MoucheCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4wPV5AJiJ6L3fqokdnRAr8k3uiTawKvegUicwfqjMoaF",
            "symbol": "BRNC",
            "name": "Baron Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/erichjbaron/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "43q34gUCKfgBQcJSNq2M4s8uVGuGtfAFQXES1BzW5UBv",
            "symbol": "SHIWO",
            "name": "ShibWolf",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43q34gUCKfgBQcJSNq2M4s8uVGuGtfAFQXES1BzW5UBv/logo.png",
            "tags": [
                "Meme-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ShibwolfSOL"
            }
        },
        {
            "chainId": 101,
            "address": "76pWmCRuVqHKsPSUph5X7xQ6bZho3apXXEdSNvr11Yvx",
            "symbol": "CDZ",
            "name": "Clendeezy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/76pWmCRuVqHKsPSUph5X7xQ6bZho3apXXEdSNvr11Yvx/logo.png",
            "tags": [
                "meme-coin",
                "utility-token",
                "community-token",
                "education-token",
                "teacher",
                "finance",
                "education"
            ],
            "extensions": {
                "website": "https://www.smyrna.k12.de.us/shs"
            }
        },
        {
            "chainId": 101,
            "address": "Dj4aRJDqzCz4Ld6njv9ny6XGeNEx8rJGuhRVzpraXeV8",
            "symbol": "RARRI",
            "name": "Rarri Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ericode99/crypto/main/no%20risk%20no%20rarri%20(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FEFcxUnHkWcEakPP6QnDXrYQoLpyRWLpfRA22V4LFML",
            "symbol": "DOGR",
            "name": "Doggro coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Andreas-The-coder/crypto/main/oie_81DPzc4yJxZy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9EPXPP7woiTzq8nBFeJDBfDhy1snGVbgFkZkMpdfELiQ",
            "symbol": "ZAN",
            "name": "Zan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZanZubair96/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "34Nd9KXji4YiMBXjgCuCt6XKBPgWPG7qgqF6LnmA4TU1",
            "symbol": "SHMY",
            "name": "ShinyMoney",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/edwardlamar/shinymoney/main/shinymoney.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AV7oz8VVnZsov1JUBmzVqjUfLkA7vJ21127YcoBXbXzC",
            "symbol": "CANNA",
            "name": "CannaBit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AV7oz8VVnZsov1JUBmzVqjUfLkA7vJ21127YcoBXbXzC/logo.png",
            "tags": [
                "community-token",
                "cannabis-token"
            ],
            "extensions": {
                "website": "https://cannabit.community"
            }
        },
        {
            "chainId": 101,
            "address": "8pJw7fYdYf8QndqsNxBjWKC8K8hBFSfoBMgFjkWPNoiE",
            "symbol": "BKP",
            "name": "BeekeepingCoin",
            "decimals": 0,
            "logoURI": "https://github.com/Bees123/crypto/blob/main/logo_beekeeping.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JdedWzEV7arWRfU9zUomMeig38uEVSEjHVMFGzgtaB3",
            "symbol": "OMEGA",
            "name": "Black Omega Security",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/unkwntech/OmegaCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Ldprm1uy9QNq77JkrmEw5KYUy5P1fqpNPH6bkJJ5gB7",
            "symbol": "BONS",
            "name": "Bonsai Token",
            "decimals": 0,
            "logoURI": "https://github.dev/onebonsai/token-list/blob/3ee6c651d9486176bd24e6c3b17751c4e8f024b8/assets/mainnet/9Ldprm1uy9QNq77JkrmEw5KYUy5P1fqpNPH6bkJJ5gB7/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3MsjG23kP91Z9MJ4KccuofnpJogdGZQ2cuaGoWR62pRZ",
            "symbol": "BUCK",
            "name": "Bucks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3MsjG23kP91Z9MJ4KccuofnpJogdGZQ2cuaGoWR62pRZ/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ]
        },
        {
            "chainId": 101,
            "address": "4zM2o9CrZUWqe5kHCZFigwBDo3ncUhk6hWSkYQnWBLAG",
            "symbol": "HOC",
            "name": "House of Communication Brussels Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4zM2o9CrZUWqe5kHCZFigwBDo3ncUhk6hWSkYQnWBLAG/logo.png",
            "tags": [
                "coin"
            ],
            "extensions": {
                "website": "https://houseofcommunication.brussels/"
            }
        },
        {
            "chainId": 101,
            "address": "5FmsZLMdhU26REqY9oacvAYsJu5yDXZML9hCq35RBKJ3",
            "symbol": "FLOR",
            "name": "[OHC] Flor Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5FmsZLMdhU26REqY9oacvAYsJu5yDXZML9hCq35RBKJ3/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AQjEJZmVXgF24RkTBcxF4uxkdUyTEmWdDVovjZyzxqbW",
            "symbol": "NUYEN",
            "name": "Nuyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AQjEJZmVXgF24RkTBcxF4uxkdUyTEmWdDVovjZyzxqbW/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJFdcWFixN8R9oZvV21MTi7DFhYWkagRS4Lqpv4tNqJ4",
            "symbol": "CPHP",
            "name": "Citizen Philippines",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BJFdcWFixN8R9oZvV21MTi7DFhYWkagRS4Lqpv4tNqJ4/logo.png"
        },
        {
            "chainId": 101,
            "address": "2u11V36HWKb8j9uMab2nWD8z7LP4ZNDjuqrfUUW8BCkQ",
            "symbol": "HRB",
            "name": "Harish Badseshi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/harishrb/token-resources/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7adj9wALYZb8Yv2tjGUZd4e6cr9DZxtdYU62mH8Ymwae",
            "symbol": "OBM",
            "name": "Obamium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cliffbars/crypto/main/communityIcon_nk99ys4f2bs31.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Sfox9RVDwzFxnaSbpcNYWL5pZ4susku5VcVXjwShRJM",
            "symbol": "TAURUS",
            "name": "SOLTaurus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vrishab2112/SOLTAURUS/main/png_20211225_001730_0000.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cjm9WLytJNkzCEQ1hhv8zk5otZTxrL3dz8DKaMLbT9XB",
            "symbol": "WZRD",
            "name": "Wizard",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WZ4RD/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CvyUpzGFvTNUL9fgUKUkzrHGLYZdEbJTMbXx888vKWuQ",
            "symbol": "DIPI",
            "name": "Dippii Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dhernz/dippii-token/main/dippii-token-logo.png",
            "tags": [
                "social-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "EjRJQMZmG1RvjoyYovDNKonfpufzHH74G3ofs5b8UUyb",
            "symbol": "SIERRA",
            "name": "Sierra",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb/crypto/main/Crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BH4bFSsGKEpkEeR2ttPXx17K1z2qm6HZKKapKDgUtFNE",
            "symbol": "SEPH",
            "name": "Sephoria",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb/token/main/SEPH.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3RBYvg79eGAvzq3BeC7u4SDqtr82Yb4wDimbVPxmSQwL",
            "symbol": "MAHT",
            "name": "Mahalo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb/coin/main/Mahalo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "12BGWWdhL564TNpZKi8Bz4bkJYue7KysbrVw4fHYzPUz",
            "symbol": "JIJI",
            "name": "Jiji Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/joelbrendle/jijicoin/main/jijicoin.png",
            "tags": [
                "jiji",
                "coin",
                "token",
                "ghibli",
                "anime",
                "cool",
                "joel",
                "minecraft",
                "cats",
                "cat"
            ]
        },
        {
            "chainId": 103,
            "address": "7pLqSyNuUjGBbUwarna5coubMnwPQz1WuMz2viLxvq75",
            "symbol": "WEETW",
            "name": "Weee Twice",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7pLqSyNuUjGBbUwarna5coubMnwPQz1WuMz2viLxvq75/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://cavientdou.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8jndFj3Z3B1MgFHTR1hU2VbDjWMbCHES5Lzf3qgc1LVZ",
            "symbol": "BBNFT",
            "name": "BobaNFTea",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8jndFj3Z3B1MgFHTR1hU2VbDjWMbCHES5Lzf3qgc1LVZ/logo.png",
            "tags": [
                "Boba",
                "NFT",
                "utility-token",
                "utility",
                "rewards"
            ],
            "extensions": {
                "website": "https://www.BobaNFTea.com"
            }
        },
        {
            "chainId": 101,
            "address": "EWjNC6zeq1N2eDo1TqALhapUS5bhGv153REScHiDnDiY",
            "symbol": "N0SR",
            "name": "N0SR Amateur Radio",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FreedomSeeds/logo/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GJWTYpZBWYBwA5HFcUsSQUuh4ZMoGcS9CRcuoi3XWAQ8",
            "symbol": "BOBO",
            "name": "Bobo Coin",
            "decimals": 0,
            "logoURI": "https://github.com/mattssi1999/crypto/blob/main/bobo-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GhCKeJzf6T87Z11YBjgXJwjT8tNAmwKcE28sA9z7ebAV",
            "symbol": "LID",
            "name": "Lidion",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thelidioncrypto/Lidion-Crypto/main/df94ebf433e7cdd88a8ff3944628b7a1.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A8xdfTHDvFGib3q64c748KdETnWRTrQRnhzvK9uTocrf",
            "symbol": "BOLTS",
            "name": "Misunderstood Neighborhood",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A8xdfTHDvFGib3q64c748KdETnWRTrQRnhzvK9uTocrf/logo.png",
            "tags": [
                "Farming",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "EfJSYvrcjRBVBSs92EjKs1yxEkNVePRRYPvhJ6ywCkvb",
            "symbol": "SNSH",
            "name": "Snowshoe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ArmandGly/token/main/logo_snowshoe_token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2QgjeZig7YKCtgCwz7ZFDYZPFdtcbCpYGcMxLMHWiyfq",
            "symbol": "PENIS",
            "name": "Penis",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2QgjeZig7YKCtgCwz7ZFDYZPFdtcbCpYGcMxLMHWiyfq/logo.png"
        },
        {
            "chainId": 101,
            "address": "FbLYpn6ZxW7fiw9tSot3z8P6WYeZWjgvuNsidwecLr5g",
            "symbol": "CHEPS",
            "name": "Chepster",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jparedes90/crypto/main/FbLYpn6ZxW7fiw9tSot3z8P6WYeZWjgvuNsidwecLr5g/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9KVMZ6jZLmmsfNXHakXhqbv3ecbNmh7cgWh8XGgfF54e",
            "symbol": "RET",
            "name": "Retsa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jparedes90/crypto/main/9KVMZ6jZLmmsfNXHakXhqbv3ecbNmh7cgWh8XGgfF54e/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HhporioRx8hNAMw17m8rnHCN88wor4d3gvqYrHbT8NLd",
            "symbol": "YUKA",
            "name": "YukaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChrisCouweleers/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HJhg1zFy1SnxKD3yyzDkyzL3VMbCCdMStGAvcMJCQkri",
            "symbol": "BICN",
            "name": "BirdyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/StaticGuy0/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HjFmTSvsyEVvRQw1hvogRf2gWXTETjZJnvBq25u36vqV",
            "symbol": "SIKH",
            "name": "SikhCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SikhVQ/crypto/main/png-transparent-golden-temple-khanda-sikhism-ik-onkar-khanda-orange-logo-religious-symbol-thumbnail.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7gudXHNwesV7Sxposo5c2esj7MJ7M5HCaXAkMyYaZipd",
            "symbol": "ALP",
            "name": "AlpCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alperenerden/alp/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "VkqrWjbgVM6WG4TUYwcXMQoVwyS6qLojEyieeMyFiUY",
            "symbol": "KARL",
            "name": "Karl Koin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VkqrWjbgVM6WG4TUYwcXMQoVwyS6qLojEyieeMyFiUY/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6TYE9KyyEHJtMhznhXHetip55yWpCzBMzMMYAY4zTEfw",
            "symbol": "AIF",
            "name": "TridentToken",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TYE9KyyEHJtMhznhXHetip55yWpCzBMzMMYAY4zTEfw/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "The official cryptocurrency of the Alternative Investment Fund at the University of Virginia. Can be used for any bar tab payment at a Trinity Irish Pub near you!",
                "website": "https://www.aifatuva.com"
            }
        },
        {
            "chainId": 101,
            "address": "APCwmkZSbHXoqGeBb7otC51YZW1vrQJ9rUuTSPByxnM7",
            "symbol": "DGBX",
            "name": "DGemBox",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/petebdeblois/crypto_dgembox/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BzGfXGcZ68UP9YiChe4qeNa4b8fu81ZZyoZG8se4LPuD",
            "symbol": "LOLA",
            "name": "Lola Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheSauceBoss91/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E7hmHxRTmcsiKz2hC8BMHQnoV8pKhB3ndDsm8t3unx37",
            "symbol": "ACDC",
            "name": "Another Crypto Decentralized Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/toastofchaos/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D7M7hTPHRKXUMa8HnBUMEa52DHELvRSRthrTJt5rd48w",
            "symbol": "STAR",
            "name": "SuperStar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SuperStarShen/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "25j7MqHNxzLQmYxbhUbmu93PKUqezP2j43rgukjvoB5n",
            "symbol": "HUN",
            "name": "Hungary",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vt1/crypto/main/250px-Civil_Ensign_of_Hungary.svg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GRuPrvBJ95SGn84HfJNyuud7JuyNiLB3EsYYY5Hu9Wxd",
            "symbol": "AMT",
            "name": "Armenian Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Alexfromidaho/Arm/main/ArmToken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4jQvJa4zdKLQVULPDWQ2krjr7jUpaYr7x9tFHz57K3ek",
            "symbol": "PUNDIT",
            "name": "Pundit Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sync-Pundit/Sync_Pundit-Coin/main/head-cropped.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CxhhgErrdpsxGs11oegaqzkpQMprPHHKKBeVMcZnJKeg",
            "symbol": "DDD",
            "name": "Dok Dollars",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DoktorD1212/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AWjiz6kawv72iD8c5EXUSPAjMKfLP2PSUdiZEf5oKDVh",
            "symbol": "RGTK",
            "name": "RaggaToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Raggastan/RaggaTkn/main/raggatoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9uQjm4Yptt9ZxCVybQ4oQxSdMwV4UEtLtMKYrWH2f6kp",
            "symbol": "TYCN",
            "name": "TyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/samwarlock/tycoin/main/logo.png",
            "tags": [
                "social-token",
                "Community",
                "Community-Token",
                "charity"
            ]
        },
        {
            "chainId": 101,
            "address": "8D8Ft8KDQZh1vWpaTqQmUri5GMq2YaNHpiqGSTaffc5p",
            "symbol": "MEXL",
            "name": "Mexel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/samwarlock/mexelio/main/logo.png",
            "tags": [
                "social-token",
                "Community",
                "Community-Token",
                "charity"
            ]
        },
        {
            "chainId": 101,
            "address": "8vQWEAmjYoeTJxw9fe5ys3vvpevVMK5XyLivYP9eYt2j",
            "symbol": "PPCN",
            "name": "PartyPaul",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pjbarnett/PartyPaul/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DX6CsUeytH9Tz7FcGMiNQM9AAdDz6E7RbtjQobPRTpqM",
            "symbol": "MAVEL",
            "name": "Mavel-X",
            "decimals": 0,
            "logoURI": "https://github.dev/mavel-x/token-list/blob/67072395e7292c9e25a1f0cc9c8e3f8507b8b187/assets/mainnet/DX6CsUeytH9Tz7FcGMiNQM9AAdDz6E7RbtjQobPRTpqM/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DmmzNnnSibSwp3tm1izWzTbdmAVfLhEqHRT382mWvwWw",
            "symbol": "NoQt",
            "name": "NoQuarter Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brianridge/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BBuwn3G3eSr73GRez8Wc5zEEc1rqz8J4PxCksU4J38Q9",
            "symbol": "AMBAF",
            "name": "All My Brothers Are Freaks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robeard/Crypto/main/Marty2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5SeZXRASQraww1wSWEPDxC4bRLL3anZevp3hYpN4m9xF",
            "symbol": "MB",
            "name": "Monkey Boost",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/monkeyboost/crypto/main/monkey-boost.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9XVR3R8K2qVsyRRuP4Y6edLqmmmGAMHgcqcnoWR7MyCL",
            "symbol": "TGR",
            "name": "TGR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XVR3R8K2qVsyRRuP4Y6edLqmmmGAMHgcqcnoWR7MyCL/logo.png",
            "tags": [
                "fungible-art-token"
            ],
            "extensions": {
                "serumV3Usdc": "HRgYivDbGrJGGS4Uw8HxQnSKJRAb6EU1wncbsSGXP8M5",
                "website": "https://tgrtgr.github.io"
            }
        },
        {
            "chainId": 101,
            "address": "3EKEibJLx62pmJy4MW9QCghggyutRQwPmTxLx685vRX7",
            "symbol": "SOULUM",
            "name": "Soulanium",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EKEibJLx62pmJy4MW9QCghggyutRQwPmTxLx685vRX7/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/HKYbebXtSC",
                "facebook": "https://www.facebook.com/dungeoneersrest",
                "instagram": "https://www.instagram.com/dungeoneersrest"
            }
        },
        {
            "chainId": 101,
            "address": "GkLohmRxTA6CnjWx6CTPWqPS39xXCu3iu1HSy84aF2sc",
            "symbol": "SUS",
            "name": "AmongSus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AmongusSuspect/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FnFseXxpfQE5y2uCf3hmB9NutHN6QcLUvfxU2FvSxy4w",
            "symbol": "EDI",
            "name": "EdiKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/filiped1/crypto/main/edi.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2mHNWVwa9LZKQANaVLEzd2YcK9iHVS5e8JUWYTxgY9ms",
            "symbol": "CCUTE",
            "name": "Cute",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mHNWVwa9LZKQANaVLEzd2YcK9iHVS5e8JUWYTxgY9ms/logo.png",
            "tags": [
                "cute"
            ],
            "extensions": {
                "website": "https://cargocollective.com/gustifink/"
            }
        },
        {
            "chainId": 101,
            "address": "5FNqjw7HjJBLtfcA92AABPVNmRJzw3BhGZjrnjUptrc4",
            "symbol": "$ZOOT",
            "name": "Mutant Penguins Token",
            "decimals": 9,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/93c507bc-83ac-4905-15ca-f26ca12f1100/public",
            "extensions": {
                "discord": "http://discord.gg/FKPX3fjXRB",
                "twitter": "https://twitter.com/Mutant_Penguins",
                "website": "https://mutantpenguins.club/"
            }
        },
        {
            "chainId": 101,
            "address": "5vNsWR12b9YjYegh4CmFQ8j6UEftJ2x5kh1QYNWGRevb",
            "symbol": "CYBX",
            "name": "Cybecure",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cybermikeg/CYBX/main/cyx.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G1NiHnY27nf3VKJeGWrktedTUtTU9HpFbGEVwZvhDhx9",
            "symbol": "MWC",
            "name": "MetaWorld",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/dhabita/metaworld/main/mw.png",
            "tags": [
                "nft",
                "metaverse",
                "game"
            ]
        },
        {
            "chainId": 101,
            "address": "3Wb6Dppe1xmcLhfyJJExEsDtmiVGgdCkSuYGkJo5M4ac",
            "symbol": "CLBW",
            "name": "LabwaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/msahion/crypto/main/capcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DmsFwtKy5FQJ6Lm1PLeQM9qeeiLmBZogCbabePrH9FuE",
            "symbol": "SPREAD",
            "name": "SPREAD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spreadability/spread/main/logo.png",
            "tags": [
                "wrapped",
                "ethereum"
            ]
        },
        {
            "chainId": 101,
            "address": "5kJeu7Pupmrurbxh9btosGdJa9zGPMy4HFRtuSNW1XT4",
            "symbol": "DSGG",
            "name": "David n Seth 312",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cgtamburro/davnseth312/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkvPFsHmfSuai3iafbnV1PMDBJ6EA7mJW4Y8qnDSMvEe",
            "symbol": "MONK",
            "name": "Monkeyfluff",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Monkey5025/monkeyfluff/main/hunkcoinfixed.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "T7vteNxbPBQnnPyz2qqnbSWsfQMk9yQcmCPLRshwhgR",
            "symbol": "CPS",
            "name": "CryptoPsychoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T7vteNxbPBQnnPyz2qqnbSWsfQMk9yQcmCPLRshwhgR/CPS.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JCBXkqaNooApjhp7gdSi1vfGaPutYw8gaybnAYWYayqU",
            "symbol": "BURK",
            "name": "Burkenstock",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlekseyGreywolf/Burkenstock/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ATQxBie1H86xJat32xshZURXzW5oJXA6brcBnGveXnjV",
            "symbol": "DOGMOES",
            "name": "DogmoesCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kevinxyao/Crypto-dogmoes/main/dogmoes-smalla.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Eb5Gpxxk3Uev4VZ62EPBd5kF64Q9st2GLoz3ZKr37DjL",
            "symbol": "BOCO",
            "name": "Bo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bo-hack/cryptologo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8dm4suXKFr9CD84L5hQPvXfYzVShwY5ty9gdXME47ZkZ",
            "symbol": "CYBS",
            "name": "Cybersecurity",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kpizzo1/coin/main/logo.png",
            "tags": [
                "social-token",
                "cybersecurity-token"
            ]
        },
        {
            "chainId": 101,
            "address": "56TH45QefFZYBUggparQ4GN1Komo8vg7XfFZfAryJTa2",
            "symbol": "INFAPE",
            "name": "INFAPE",
            "decimals": 9,
            "logoURI": "https://arweave.net/zUWlZrU_4ob7zJ5m5Jv39QSZRvoCC9hF_T4NUXkFK-8?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/InfamousApesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "3knhFs1XAzrxv3q9i33qFtjtpSKQTGwovtthLWmpCrno",
            "symbol": "SCCA",
            "name": "Scouting Careers Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/scoutingcareers/crypto/main/logo-new-1.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "99XDYcC6E7UsnKpuv4n5Hpd9G8GmZGwaNoh7CXP4fQyc",
            "symbol": "TCHR",
            "name": "ToCharCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nathanru/crypto/main/token_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HFq8jesWLdqowPeUWrkFVYpFyMm5zDeZVY8pKEhtKEou",
            "symbol": "SHOT",
            "name": "On Target",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jimmjammjarr/flyptoes/main/logo.png",
            "tags": [
                "social-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "9mgCWxChSM5sWkRd9xA6ocNkJ48s5ySo5umQ7gfovHpW",
            "symbol": "SMIL",
            "name": "Smile",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9mgCWxChSM5sWkRd9xA6ocNkJ48s5ySo5umQ7gfovHpW/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4hAJzHWns8Y2JMK4cHRrcZFtPizQuvKqgbbQ6GF6V8D8",
            "symbol": "neINK",
            "name": "NeverEndingINK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NEStoreez/HunaKala/main/ink-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8VywduJzwDeXSgBr2LTwyyHsR5r9TFPG6GwwXdhbzVZH",
            "symbol": "BLDK",
            "name": "Buldak Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BULDAKCOIN/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DpY4DVuc6qZwJuyu4Z76fBy5hY3yXUtwSk21axW75Zxb",
            "symbol": "Shh",
            "name": "Shongololo",
            "decimals": 0,
            "logoURI": "https://github.com/thamsanqabengu/Shongololo/blob/main/Shongololo-Bhengu-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7p7pVamRxfLZR9gWQqLZvEbTLyBav2x33kzwZLQdrXzq",
            "symbol": "CGRFC",
            "name": "Cecils Get Rich Fast Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cecildesil4/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GV5BnbUvxYtScZWaGngPFNipgZKx3KpRmLaRXPT97ohR",
            "symbol": "WHYPHY",
            "name": "Sol City",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GV5BnbUvxYtScZWaGngPFNipgZKx3KpRmLaRXPT97ohR/logo.png",
            "tags": [
                "utility-token",
                "DeFi",
                "governance-token"
            ],
            "extensions": {
                "description": "Solana Art Metaverse",
                "discord": "https://discord.gg/Sa8EyYKyhU",
                "instagram": "https://instagram.com/69solstreet",
                "twitter": "https://twitter.com/69solstreet"
            }
        },
        {
            "chainId": 101,
            "address": "4PE5J2Yufinhdre67AxfeQQqvKwKTWQe5ZWUMhMK2Kum",
            "symbol": "HGV",
            "name": "HCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bernardhackwell/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5msC2VwMTPcu2oK1K19waeTeMW39rUTNx96ifvhqv9cD",
            "symbol": "GBP",
            "name": "Good Boy Pablo",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/toshalex/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiiiGzPx7xqpkiP6gT3T27fnqAoGPov6VqMPCkQAPLue",
            "symbol": "XINDT",
            "name": "Xin Dragons Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/MooningSols/assets/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "4p4e7iVvynmLuBgVKFxuxAdFVmGsjYcjqPrGR8SdKGqf",
            "symbol": "WV",
            "name": "WondraVision",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WondraVision/SolanaToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9cgWfYYvyskChwThYLyUBwPD1ZfwMMjTBWtCXYqXBGpa",
            "symbol": "BLT",
            "name": "Belette",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9cgWfYYvyskChwThYLyUBwPD1ZfwMMjTBWtCXYqXBGpa/logo.png",
            "tags": [
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "4F72CCUWeQFBCTWVG6Cr2yrwosHo3dziFEAzP13rGhbh",
            "symbol": "ADMC",
            "name": "aDam Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/bhartman21/aDamCoin/main/aDamCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9odg9Qf2jtScVkjYUH8rVtvZQYKrr1sMTRobRu3zQ9Ms",
            "symbol": "FP",
            "name": "FancyPants",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FancyPantsToken/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5FMqz4nZeKx5RzDjUSJykrwZS4BmqVMewqLkVRv3SQ8N",
            "symbol": "TANK",
            "name": "TechnoBoi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Toop-glitch/crypto/main/motherboard-icon-24.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AaxP96Js68xaPQea2Pt6We3aVoW27CEUkz9CPix3X4WQ",
            "symbol": "UMUM",
            "name": "urmom",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Toop-glitch/urmom/main/urmom%20(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AoCfbqCKQWuCNvriPTGnAvd69mvKzeP9RSXjbTR8yCk7",
            "symbol": "TOOP",
            "name": "Toop-Glitch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Toop-glitch/crypto/main/Glitch%20(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "226A6tzDEVoy4CLoMS89kDjfwmLyqbAYRwzcr2NPtbKB",
            "symbol": "CCMT",
            "name": "Clay Christmas Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/y2clay/crypto/main/Cmaslogosmall.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HFtWXb3wmc7mMwpcLVpH1QrhxrWdMjcyu6LnTaozHcBH",
            "symbol": "GFAL",
            "name": "GirlfriendAllowance",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HenrydeB/img-gfal/main/GFAL.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GjdvxEzd1rJgJyoTxWS9h9tk5bupw2ayoPXSwRYDA462",
            "symbol": "GCoin",
            "name": "GCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bill6086/crypto/main/YinYang.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ag3j2xydAzafmcYTPfpmLNdQ5Bh55uRDwyAGnAz5wpjr",
            "symbol": "OMC",
            "name": "Omicron",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ag3j2xydAzafmcYTPfpmLNdQ5Bh55uRDwyAGnAz5wpjr/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BKSrRNHhdJbsuYX4oHCsPa2GwKQD84Tb9a6817T6oCYm",
            "symbol": "KEKO",
            "name": "KonekoKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aiden280zx/crypto/main/icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3S1bmWCa6o8GDYPJFzRqTzdMpYnUM34LnikYMpoJ7AWd",
            "symbol": "DIDI",
            "name": "Didi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3S1bmWCa6o8GDYPJFzRqTzdMpYnUM34LnikYMpoJ7AWd/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9NxVRtwPU64LHj4gDD6kqs622eaZ8VHYG2fbLcWF6ode",
            "symbol": "Hacka",
            "name": "Hacka Coin",
            "decimals": 0,
            "logoURI": "https://xzqqawurhhkzdtqrwnsl.supabase.in/storage/v1/object/public/public/Hacka.png",
            "tags": [
                "hacka-coin",
                "community-coin"
            ],
            "extensions": {
                "website": "https://hackatalk.dev"
            }
        },
        {
            "chainId": 101,
            "address": "4A2us4kJfbMcBfJCmBiec8vMmahivRJQuLkreKEDrnsX",
            "symbol": "MAFIA",
            "name": "Solana Mafia Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4A2us4kJfbMcBfJCmBiec8vMmahivRJQuLkreKEDrnsX/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/jEYq8MK7vy",
                "twitter": "https://twitter.com/SolanaMob",
                "website": "https://www.solanamafia.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2DDyLzN1pxVddhkgZYJdJH6YFUbeSVPFZBMSxcLswwap",
            "symbol": "SOUP",
            "name": "GoodSoup",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DDyLzN1pxVddhkgZYJdJH6YFUbeSVPFZBMSxcLswwap/logo.png",
            "tags": [
                "community-token",
                "governance-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/goodsouptoken",
                "website": "https://goodsoup.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8J8e4bZhwCYRYNG9g9cj7Du91x7D2wrDmCJhRTpgbi2Y",
            "symbol": "WALA",
            "name": "Wallace Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LKcrash/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CphTaGMUnqyVk2ZwbashhXnimo6oMTRNk9Dv6NNCaxph",
            "symbol": "SANTA",
            "name": "SANTA FLOKI inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/programe95/cryp/main/logofloki.png",
            "tags": [
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "7rsd3MoJNSbrsgeLCND3QeCAdx7vMspGJhbCTVH1Sa6n",
            "symbol": "LEOTK",
            "name": "Leo Token",
            "decimals": 2,
            "logoURI": "https://github.com/neezer96/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BYK2CGi9JJRwaQVG6pxKXBR84cMPLbhxHYCHC98drKnN",
            "symbol": "CHF",
            "name": "Swiss CHF",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoSwissCHF/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DookudLUwXvbjbYFqNvJB6toPG9V67NTXhfbbCdc4u7T",
            "symbol": "DST",
            "name": "Dooku Society Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DookudLUwXvbjbYFqNvJB6toPG9V67NTXhfbbCdc4u7T/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://dookusociety.io"
            }
        },
        {
            "chainId": 101,
            "address": "23TXQ7EyhsPCTonPYoPpzFnskG1rRkgXrCmtiFFetH1n",
            "symbol": "MARTAN",
            "name": "Martan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/martanrauber/token/main/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Jdh31HKtk8dm4wxXR4y8ceQFsZN9jVQUiEa4s6693W2",
            "symbol": "BDRK12",
            "name": "PIEDROLARES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BarneyRubble12/crypto-brubbles/main/logo.png",
            "tags": [
                "handball",
                "cuchi12"
            ]
        },
        {
            "chainId": 101,
            "address": "CPreedQ24EcfMcNWuXrHSgqow6GBLXgqaQSddrUnk4u6",
            "symbol": "ALFA",
            "name": "ALFA Sport Studio",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Naximus/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hpi6UNPdTVtHvcJRzgxCcABEcBSoALK56N6KDatfggBZ",
            "symbol": "POUET",
            "name": "Pouet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hpi6UNPdTVtHvcJRzgxCcABEcBSoALK56N6KDatfggBZ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ZXc5yNWSVHc9hPY3Vb7jbZJV2xkqCUQvonekRog4oxZ",
            "symbol": "JOI",
            "name": "Joieria",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ZXc5yNWSVHc9hPY3Vb7jbZJV2xkqCUQvonekRog4oxZ/logo.png",
            "tags": [
                "Social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GSTgB6PZ1jUAbj3UoUfZjppxLbkJQZY1iyShQL1VgMpB",
            "symbol": "IDIOT",
            "name": "IdiotDAO Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GSTgB6PZ1jUAbj3UoUfZjppxLbkJQZY1iyShQL1VgMpB/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "394XeAW9P5FmjQioFZ6SZoT8j34KYHVJK77ZCvhAbpnR",
            "symbol": "KATAKLOP",
            "name": "Kataklop",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/394XeAW9P5FmjQioFZ6SZoT8j34KYHVJK77ZCvhAbpnR/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "cryptomony specializing in the equestrian world",
                "facebook": "https://www.facebook.com/kataklopnetwork",
                "website": "https://crypto.kataklop.com"
            }
        },
        {
            "chainId": 101,
            "address": "hXhvvXYY8ME777zr74AUX5w5yGjjxud4t1FLsdVFDyU",
            "symbol": "TWT",
            "name": "Techwell Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MitkoBachvarov/crypto/main/cryptologo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Diox5TrJd4JnHikpVkES749sVdZpCucVURcbeAPgZj11",
            "symbol": "ITT",
            "name": "Inter Tin Token",
            "decimals": 9,
            "logoURI": "https://github.com/intertin/solana/blob/main/1x1.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.intertin.com"
            }
        },
        {
            "chainId": 101,
            "address": "8x778KiyywKwez9ijikxfoMSrEhNHCfBhFmH5cbaPWj",
            "symbol": "IT",
            "name": "Inter Tin",
            "decimals": 0,
            "logoURI": "https://github.com/intertin/solana/blob/main/1x1.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "website": "https://www.intertin.com"
            }
        },
        {
            "chainId": 101,
            "address": "C6hj3fwvoY2AgRzW26AB47cfPNuWaBHGWsW8YdYp4vuC",
            "symbol": "MATK",
            "name": "MateoKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mateosat3/crypto/main/cat-cute.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ZXVxxX6zCf5Yqeq6iwdTuVwC6E8b7bs2q3D1Prg86sv",
            "symbol": "DAGE",
            "name": "Dark Age",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ZXVxxX6zCf5Yqeq6iwdTuVwC6E8b7bs2q3D1Prg86sv/logo.png",
            "tags": [
                "gamefi",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "QBeV89S6jJeXeJNURFTPTgCan9AW8dPBTUkbFoWHAaK",
            "symbol": "SLNM",
            "name": "Solanum",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/QBeV89S6jJeXeJNURFTPTgCan9AW8dPBTUkbFoWHAaK/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://solanum.app"
            }
        },
        {
            "chainId": 101,
            "address": "2x6kUR2FKiuwkXsG1E3w6sW6jgNcKpGKPcV5mX7cqvkt",
            "symbol": "RGK",
            "name": "RunGoodKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adampicker88/crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MJBzbqF6VSSxMkLq8dpb6KpLhRqGCiKmvQDVJUCnjP3",
            "symbol": "QKOR",
            "name": "Quarkore",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/agx93/1stoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Amori9cHbNiLDeec7WqXSUopheaxLvVxRzuGCNauPshB",
            "symbol": "AF",
            "name": "Amorfati",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Amori9cHbNiLDeec7WqXSUopheaxLvVxRzuGCNauPshB/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/amorifati_",
                "website": "https://amorifati.com"
            }
        },
        {
            "chainId": 101,
            "address": "7KsnxvGxV7kam2eFFnz2PhaSM9RZWrhS2BCKkmNieiCt",
            "symbol": "LAK",
            "name": "Cyberhakz Chip",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/navin-hariharan/about-me/main/favicon.png",
            "tags": [
                "cyberhakz-token",
                "cybersecurity-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6j7HmMqvYSYWTzh3mZN71ZJAjrvHJZU6JpB89YCaN7jE",
            "symbol": "JDPT",
            "name": "JD Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EkadrishtaITSolutionsLLP/jdtoken/main/logo.png",
            "tags": [
                "social-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7zhmxoDpZ2ShC15KYmhBzHmKsgwRAn454iRYRWPJHcVq",
            "symbol": "FIZZ",
            "name": "FizzCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FizzyWater9/fizzcoin/main/canvas.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5xr9CtnMbYiWA6UTB8Gy9XR4cMv3ARGho8Krrx6drgGG",
            "symbol": "VLE",
            "name": "Vleeboz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/APonline/VleeboCoin/main/logo.png",
            "tags": [
                "social-token",
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "BUFSVNvEy8uQkWj1syMQ6JC5nqV3YpjS9v1Q3vbaBqAf",
            "symbol": "ERPN",
            "name": "EARPAN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUFSVNvEy8uQkWj1syMQ6JC5nqV3YpjS9v1Q3vbaBqAf/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/irvansnid",
                "website": "https://coin.erpn.us"
            }
        },
        {
            "chainId": 101,
            "address": "9g7MFEqPLtoDe3vyJx1JsqCFyKogHXxf6dDkuRxUUd9f",
            "symbol": "PACE",
            "name": "SPACE MILK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9g7MFEqPLtoDe3vyJx1JsqCFyKogHXxf6dDkuRxUUd9f/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CU6tuHzFroqLozgMzrZ9pa5XvXwjRVCJG6qoREV9BLeg",
            "symbol": "MD",
            "name": "Madhs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Templight41/crypto/main/logo%20MD/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EhUMxef77Ya7sdcajcrMjM8tNA32pZFjRtfyTXTnj4P6",
            "symbol": "KTRK",
            "name": "KiterKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KiterCode/Kiter-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2iBENeS6FPNtBQrrYRTeM1nmQtcWChFu1M68DwFhc17M",
            "symbol": "QCI",
            "name": "Lill Qoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ghotato/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B45eExdHQmYbVsEYzqYgrMwLBGhz6KXQAAPdpQ2DCGFj",
            "symbol": "SZC",
            "name": "SageZeroCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B45eExdHQmYbVsEYzqYgrMwLBGhz6KXQAAPdpQ2DCGFj/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JBgKmQsBcpMGrUofgCwnE53FPSwoGKuBsj9F8sru3LKF",
            "symbol": "NUFI",
            "name": "Nu-Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/saosaik/cryptos/main/nufi.png",
            "tags": [
                "CEX-DEX",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nufi_official",
                "website": "https://nu.fi"
            }
        },
        {
            "chainId": 101,
            "address": "FWv9esV3HQKMwMG435bRgfnN3oSbQr3bzszkcCWPKDzy",
            "symbol": "BULLIT",
            "name": "BULLIT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JaySheepdog-N0SR/BLLT/main/BLLT-logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "AKnTTNEzNXB5q5ZoUTi2dX6XwTrCij136z7aoys3qdaL",
            "symbol": "BASM",
            "name": "Bwood Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKnTTNEzNXB5q5ZoUTi2dX6XwTrCij136z7aoys3qdaL/logo.png"
        },
        {
            "chainId": 101,
            "address": "8GuZf1aJtGVeqfWJCubiHtdJxqVfowAuwoQ8Ae2uruKT",
            "symbol": "DOOKU",
            "name": "Dooku World Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8GuZf1aJtGVeqfWJCubiHtdJxqVfowAuwoQ8Ae2uruKT/logo.png"
        },
        {
            "chainId": 101,
            "address": "2RBVJE3tzdrbD8aVzoKGrphycz3azhdip3u7V1JfE84X",
            "symbol": "WTC",
            "name": "Wolf and Crow",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/moquito64/Howl/master/src/assets/images/logo.png"
        },
        {
            "chainId": 101,
            "address": "FbqTq5JsMAiwDc2V6v84KuitCLNZpQgfnKGuV1bXtfHD",
            "symbol": "ATRIOC",
            "name": "Sigma Based Unit",
            "decimals": 0,
            "logoURI": "https://steeeeve.com/img/atrioc.png",
            "tags": [
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "GMtU1jRYAdR57t29TqGEm4qmeB22FeFVRNJ9mptwkPmf",
            "symbol": "LAFO",
            "name": "Lalafofo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ricurdhu/crypto/main/UniKlar.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2FgHJJBnaMDTeHnZZ8MPYDrxDFBy8yGG85NxSKZEANyB",
            "symbol": "AIHC",
            "name": "AIhsanC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ihsancelik/crypto/main/logo200x200.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://aihctoken.com/",
                "youtube": "https://www.youtube.com/channel/UCKfcCWNfCcy75l7aJRLnxRg"
            }
        },
        {
            "chainId": 101,
            "address": "8TQdiAzdZZEtkWUR8Zj1tqDYGPv9TR1XjPqrew39Vq9V",
            "symbol": "HYPE",
            "name": "HypeProxy",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/hypeproxy/hypeproxy/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/HYyGPe7",
                "github": "https://github.com/hypeproxy",
                "medium": "https://medium.com/@HypeProxy",
                "telegram": "https://t.me/hypeproxy_org",
                "twitter": "https://twitter.com/hypeproxyio",
                "website": "http://hypeproxy.org"
            }
        },
        {
            "chainId": 101,
            "address": "BkzgrJVNVUbCxLsRL4ULsrdk2MZaPkbZbpory6uczPCv",
            "symbol": "MYCRA",
            "name": "Mystery Crabs Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BkzgrJVNVUbCxLsRL4ULsrdk2MZaPkbZbpory6uczPCv/especial3.jpg",
            "tags": [
                "mysterycrabs",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/TyeVKNBx",
                "twitter": "https://twitter.com/MysteryCrabsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "ySumbV2nRTFDpiEyetwpz8Pr3JqUNBi7hK2s7jyyji3",
            "symbol": "TIKI",
            "name": "HashTiki",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ySumbV2nRTFDpiEyetwpz8Pr3JqUNBi7hK2s7jyyji3/logo.png",
            "tags": [
                "metaverse",
                "nft",
                "community-token"
            ],
            "extensions": {
                "description": "HashTiki is the first NFT portfolio that empowers the metaverse ecology, represents the inheritance of a totem culture, they are 256*256 pixel images, generated through a random combination algorithm, it consists of 8000 NFT totem collections.",
                "discord": "https://discord.com/invite/ZJ8hYjNt32",
                "medium": "https://medium.com/@hashtiki",
                "twitter": "https://twitter.com/hashtiki"
            }
        },
        {
            "chainId": 101,
            "address": "9FkY7k2N98enicYZyaJvt9BwGRDinkxakbCWh2yEUuhA",
            "symbol": "PRU",
            "name": "PRU Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pruking/PRU-Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HPC9iw1mao27CXWocVqHPjQgtFr9hZecNV7Q2B4KNm8",
            "symbol": "ASHC",
            "name": "Ashcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GoviCoin/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "Play-to-earn"
            ]
        },
        {
            "chainId": 101,
            "address": "8xhdLhtD5Kk4HAHxA67BboYNXPuNHRYvgv9Er1wqPFBD",
            "symbol": "EMIX",
            "name": "Emixcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Achraf0407/cryptotokensol/main/logo.png",
            "tags": [
                "social-token",
                "education-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3K6SeyAYC6E5n39DZ25Jp2EsWSgb4JBjSQYS86jziNMy",
            "symbol": "SHRKDAO",
            "name": "The Mark of Kamohoalii",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3K6SeyAYC6E5n39DZ25Jp2EsWSgb4JBjSQYS86jziNMy/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "governance-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/cDKerzDe9A",
                "twitter": "https://twitter.com/shkarysharks",
                "website": "https://www.shkarysharks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FGX2PiiMB56Cba4dp7ku7NpGPXNQB9mwyUQd55TAujfB",
            "symbol": "JERB",
            "name": "Jerboa",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Driverx2030/Solana-Token-Files/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AFk79udmPoxjc7vk99t7ou48EUv954HUe5tJWvNJBadG",
            "symbol": "DAFN",
            "name": "Digital Afghani",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/omidalikozay/DAFN3/main/DAFN.png",
            "tags": [
                "Payments"
            ]
        },
        {
            "chainId": 101,
            "address": "8Rs72gjdRNi61TCXq4YuJdxGJL2EyY3igmBXu9PYTCkd",
            "symbol": "TMSF",
            "name": "TMS Foundation",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bharatyuva/TMS-Udghatita/main/images/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DxNj4UJyEHcophxNiTMfdSBFqKxiiEg4BBnJJf3VUVe5",
            "symbol": "MGCN",
            "name": "MagiCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/leonelafonsoe/magicoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkbPcS3ik99FL2Rxw8PPyXTGjByyU2jyZQTbpDrCwaP8",
            "symbol": "SKAR",
            "name": "Skylar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SkylarToken/skylarlogo/main/logo.png",
            "tags": [
                "science-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EaaF9241uBoajKaSScbTzBfBuiJ78zuXJCSW3c9HQEtJ",
            "symbol": "EDUC",
            "name": "Education Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EaaF9241uBoajKaSScbTzBfBuiJ78zuXJCSW3c9HQEtJ/logo.png",
            "tags": [
                "utility-token",
                "education-token",
                "social-token",
                "community-token",
                "game-token",
                "gaming-token",
                "game-currency",
                "play2earn",
                "play-to-earn"
            ],
            "extensions": {
                "description": "The first coin that allows you and your children to play, learn and earn",
                "website": "https://education-coin.com"
            }
        },
        {
            "chainId": 101,
            "address": "2nZxXaVox3j3PjEp1xGjDc5RaJY2ggPakdxDvGxaPXEP",
            "symbol": "ISPT",
            "name": "Internet Service Provider Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Khikoman/crypto-ISPT/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "Internet Service Provider Token"
            }
        },
        {
            "chainId": 101,
            "address": "BMTjiZ15FqJ3pmGu91uBvhTWqXdh8FpLgFgoQX1BAsHV",
            "symbol": "ECOT",
            "name": "Eco Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Eco-bikes/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EXs1QTZ7dEpDe2GQa53GdJhSScWDDTgBkLCBT6FKevah",
            "symbol": "RDG",
            "name": "GUPTA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rishabdeogupta/crypto/main/logo.png",
            "extensions": {
                "instagram": "https://www.instagram.com/rishabdeogupta",
                "twitter": "https://twitter.com/rishabdeogupta"
            }
        },
        {
            "chainId": 101,
            "address": "DQF4npyBsvoG67STQ7MUGDcTDS49NfS7ngGg8cmLqTJT",
            "symbol": "DGNT",
            "name": "DogNut",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DQF4npyBsvoG67STQ7MUGDcTDS49NfS7ngGg8cmLqTJT/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CY3VrKMM8mEMQA1XC74LnWDYgR4JYkSrUV8KoDMcXydy",
            "symbol": "GIG",
            "name": "Glitter in the Grass",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Prime3vil/Glitter-in-the-Grass-Token/main/logo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Brs4S2ehJmxkWsqo6u673ZeNY8HUx2sqnpxAMC4uu2N4",
            "symbol": "RUNE",
            "name": "RuneCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/runestone74/runecoin/main/R%20grey%20flat%20logo.png",
            "tags": [
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "2N79onHLPU2R3s1WGM9n7L2ABCk18CouP83mnbLpBEux",
            "symbol": "WINC",
            "name": "Wubba Inc Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2N79onHLPU2R3s1WGM9n7L2ABCk18CouP83mnbLpBEux/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8dfxNdAhsmdGt6JE91Hx2y1u3UQdtNwYzaYdVQFugSM6",
            "symbol": "ZKC",
            "name": "ZaidK Coin",
            "decimals": 0,
            "logoURI": "https://github.com/ezyhackz/ZaidK/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A6UHLygu5yZ4t3WPi2A2R4BqP55ybxkXHKzYkzKTsyBf",
            "symbol": "SPZO",
            "name": "Spizzo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pspizzo/solana-spzo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FCxEBaPxMehE2Qc8QpEF2u1chq9jkBXbEFpJefeYX5fF",
            "symbol": "FSEED",
            "name": "Freedom Seeds",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JaySheepdog-N0SR/logo/main/FreedomSeed-logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3HaDnJ2PEt7v7RE8dPaSzSCgBL5dWvWtTkGaEGs5ap3N",
            "symbol": "ELONCASH",
            "name": "ElonCash",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HaDnJ2PEt7v7RE8dPaSzSCgBL5dWvWtTkGaEGs5ap3N/logo.png"
        },
        {
            "chainId": 101,
            "address": "Fek4Dy8QCzoSSn8bbznLZhdJHhsukSHQLL38wcwPXYak",
            "symbol": "ASTER",
            "name": "AsterCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Asterexpansion/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Eit8jtwJygU4pg3ESSXZWsWwP4KuMfGxkDUZhp7o2dYa",
            "symbol": "PEZOS",
            "name": "pezos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lecroquemonsieur/pezos/main/pezos-logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GggcHgGuJtckkQPCBif2fVe9D2FXhW19MGSZhcw5Tq1A",
            "symbol": "PHI",
            "name": "Philoid",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GggcHgGuJtckkQPCBif2fVe9D2FXhW19MGSZhcw5Tq1A/logo.png",
            "tags": [
                "study-Token",
                "education-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8dZkfTisESTx1cAdhDjEYTToKSDgwTUfuWZcMXkFn3uK",
            "symbol": "MENMA",
            "name": "Menma Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8dZkfTisESTx1cAdhDjEYTToKSDgwTUfuWZcMXkFn3uK/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sFot4wPtMp5k6ugtJXQGbGy4Sm41Yd6Q5mi7Pq9Zsk4",
            "symbol": "GTC",
            "name": "Gorito",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eketiger/goritoCoin/main/goritoCoinLogo.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BtnUizMTmDdKxP3hZhQhZMUXF9RZZaZJvcNDFLiRbZ5e",
            "symbol": "NRV",
            "name": "Nerv",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mr4x3/emoji-cheat-sheet.com/master/logo.jpeg",
            "tags": [
                "utility-token",
                "DeFi",
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Um17c1Bo2MEETUcbbFk8oaTXQFUpLcnSwBG2FNLb7AQ",
            "symbol": "NSD",
            "name": "Nasreddine Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/NasreddineMoe22/crypto/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "DvEChdcDKmKSp8uohR4Mbn2nHgf4kvvoc1a1UVY6dGye",
            "symbol": "6SK8",
            "name": "6Skates Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DvEChdcDKmKSp8uohR4Mbn2nHgf4kvvoc1a1UVY6dGye/logo.png",
            "tags": [
                "Reward-Token",
                "Social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6yfT5iyWzKHH8Bykj3cZ15mCMQkesR7FwnLUWUdxGV89",
            "symbol": "BPW",
            "name": "Wonder Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BPWCNRZ/picture/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FuGpEMivuKnTU5KwhCuq9rjfEVX2WekNeSNNqJdThpHk",
            "symbol": "AETHER",
            "name": "Aether",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Aether7io/AetherCryptoToken/main/Logos/Aether7ioTokenLogoTransparent.png",
            "tags": [
                "utility-token",
                "Application",
                "NFTS"
            ],
            "extensions": {
                "address": "FuGpEMivuKnTU5KwhCuq9rjfEVX2WekNeSNNqJdThpHk",
                "description": "Aether7.io is a cloud platform with applications that utilize blockchain technology to store B2B records and transactions",
                "facebook": "https://www.facebook.com/Aether7io",
                "website": "https://aether7.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7zKR4LWUQmq8E5uC5ZhqPtiV2cM3mR86Mvpw5y6rYayu",
            "symbol": "MHF",
            "name": "MHF Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FreedomSeeds/logo/main/MHF-logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4xGGBuyz7r7ps6Kb2Xt8Nh7B6hcGxTaC1pYvcxXJ83YG",
            "symbol": "TORO",
            "name": "TORO Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cryptodsyne/toro-token/main/toro.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "41QaQpUGPqLkDVae3cVxesZeZRh82G6ZpSaX86x5w8bk",
            "symbol": "AMDA",
            "name": "Amanda",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/41QaQpUGPqLkDVae3cVxesZeZRh82G6ZpSaX86x5w8bk/image.jpg",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DFhCgBHdr9VtJvMHzgqrK18vmbhZYtx2MaYfixMTzGPA",
            "symbol": "BITHQ",
            "name": "bitHQ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sutheegt/crypto/main/bithq.png?token=AKRLLC4HNANGYGGKUHXVULLBZIUSO",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Brooke2JJW9aPNQtedHF3pjPQKn9ULQkGLLHL5Pp838a",
            "symbol": "BROOKE",
            "name": "brookejlacey",
            "decimals": 9,
            "logoURI": "https://neusoz4twjxun7nsxjyb33ncazmeirheb7ouau6grvizlvbfykaa.arweave.net/aSknZ5Oyb0b9srpwHe2iBlhEROQP3UBTxo1RldQlwoA",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/brookejlacey.fb",
                "instagram": "https://instagram.com/brookejlacey",
                "twitter": "https://twitter.com/brookejlacey",
                "website": "https://brookejlacey.com"
            }
        },
        {
            "chainId": 101,
            "address": "FNAfUZ5NBk8a7JYLfnfChF9DkfEnc2q4mWcoEziemEsZ",
            "symbol": "DUBB",
            "name": "Dubb Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/systemdan5/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8v67An5A5JsJQABF2tGZqn5MYFyEZnxFfrVdnJxqudvs",
            "symbol": "DNC",
            "name": "DonutCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/coderkid11/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ao3UnASAD1e76HDz2PSUgop8mPT1QvYrNgJaoSenfbS8",
            "symbol": "BOOM",
            "name": "BOOM Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Srichand1021/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BjfYF1FN9aLtzZn8oNiLnuxgj9J8b5FECYuZvKWr9JD9",
            "symbol": "ghs",
            "name": "ghost",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjfYF1FN9aLtzZn8oNiLnuxgj9J8b5FECYuZvKWr9JD9/logo.png",
            "tags": [
                "community-token",
                "ghost",
                "stack",
                "comerce"
            ]
        },
        {
            "chainId": 101,
            "address": "665t3SYTfoVtaRPP7QRbBG3V7ePVtWVKQXYkSaUfxS7u",
            "symbol": "ORNC",
            "name": "Orion Coin",
            "decimals": 9,
            "logoURI": "https://github.com/neptuncoin/orion-coin/raw/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8JxhzRVb2BNdPD9tmobfeqjyEjnic3ZYxyS3NxKAfei2",
            "symbol": "OSKR",
            "name": "Oskar Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bremnertech/crypto_oskar/main/IMG_2237.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DBDjTWm6JfmQfypymuxBv9qUvDrqWKjBUWFp1AZB93UX",
            "symbol": "BTSI",
            "name": "Baertschis Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/k3ruakk/cryptoBTSI/main/BTSI_icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C3uCfCh7A5mp8nh5mb4BVtDdE3unMFx4iFrnNbRD3pP2",
            "symbol": "LMFR",
            "name": "LimoFahr Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C3uCfCh7A5mp8nh5mb4BVtDdE3unMFx4iFrnNbRD3pP2/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJ64zpEsCtTZcCXJ6P3V9J1yukWGQx9QkDqw46jjYqaV",
            "symbol": "WEK",
            "name": "We Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sermennsker/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AVTcgk5jNvgBcE8qATgGkNFmBLZNBENAGwaF84qjC3HX",
            "symbol": "DUMOC",
            "name": "DumboOcto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jeronaut/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ds644r6pUQZVt6wX4THmey2ejWSeqq2CDpiisiGcUKij",
            "symbol": "ZBC",
            "name": "Zibbi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mindmaster7/ZBC/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "wVkrVMERD4eVvTM9CtViLq85bZJFFWmM4UVwL2zuAYR",
            "symbol": "JBUX",
            "name": "JBUX",
            "decimals": 6,
            "logoURI": "https://tkuidcbjznavanyp45icotydzw7fhndgecqodr6tmne5oase7w7q.arweave.net/mqiBiCnLQVA3D-dQJ08Dzb5TtGYgoOHH02NJ1wJE_b8"
        },
        {
            "chainId": 101,
            "address": "H6vyVg538NbdvcY25UtnTG2L7fHhvBTL21zNxVR2PBbc",
            "symbol": "C2",
            "name": "C2 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/c2devlopement/c2crypto/main/c2-coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8mkr5nDzEYd6oezcGouM5S34QSn6L5osnHVqdzwB9qx4",
            "symbol": "D4CC",
            "name": "D4rk Cat Coin ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/traxxxor/d4rkcat/main/d4rkcat.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "584uDWBE14JqaUTVU4ExKB7KM2onpxvkK5hyUwXGCFBv",
            "symbol": "LLPC",
            "name": "Lil Pimpy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aminek888/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "YLTrJGCqZpwZZbCN2D3KTg4PuGBofto8ciqYdPoQEgd",
            "symbol": "YLTR",
            "name": "Yolo Otter Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YLTrJGCqZpwZZbCN2D3KTg4PuGBofto8ciqYdPoQEgd/logo.png"
        },
        {
            "chainId": 101,
            "address": "GPNLLeuL15G29XpbnC3oWwf1rgYAvMwhjGvomDQF11iz",
            "symbol": "Pure",
            "name": "PureParagon Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PureParagon/crypto/main/5c25b36beafa9880572d690de73545cf.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5fNj49KWgYv5qKrHPb8f5VnGWPUynAuVbWgeLMGLpD1h",
            "symbol": "SRNC",
            "name": "Seranco Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/somozadev/crpt/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "No2HnCo84QRR75Pv3kE6gMdwT1EjtoZ9SQir7Yq4phE",
            "symbol": "YUANBAO",
            "name": "Yuanbao Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/joelshu1/frenchclass/main/logo-yuanbao.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Gu8zWGNQFwFY6yB4SxRUJMhcUh1Xa1kPB81hFbbyWMT",
            "symbol": "BOULE",
            "name": "The Bake Shop Boule",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/joelshu1/frenchclass/main/logo-boule.png",
            "tags": [
                "bakery-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GbDTNwEQ1hkZtjXjoAeEJ4DUycMfKvxQ94GWFaNT63G8",
            "symbol": "BGVG",
            "name": "BlogVlogCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ajpaulrh/crypto/main/small2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6j28waP2NyoCBJrrVNHZuEzLDL25DXdNxMFsMNMxYht7",
            "symbol": "BRERO",
            "name": "Fraktionalized SMB 3394",
            "decimals": 3,
            "logoURI": "https://arweave.net/2OINffkTFvUwtPFfNKkNzQ7h8m-1UZsqQkr6bXKIH70",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/FsGYFGm3mUpUaP4tLAjH3oxnQPT6GYAeWq7mZpZkTGh8",
                "vaultPubkey": "FsGYFGm3mUpUaP4tLAjH3oxnQPT6GYAeWq7mZpZkTGh8"
            }
        },
        {
            "chainId": 101,
            "address": "GM4CTEsNsU5Kg22JNKkANTannrBU9Ah6SNa3BcyBA6Kj",
            "symbol": "BMINUS",
            "name": "Fraktionalized Solana White Paper",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/z6v5oNC5uL9fCPhn-BQEONgBdxXrFNhT3ZP5tF-ZesE?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/FGJJoASbC8g5Mx1JjUKKPDwCJYLnxEMyCmdd7KeJjbe9",
                "vaultPubkey": "FGJJoASbC8g5Mx1JjUKKPDwCJYLnxEMyCmdd7KeJjbe9"
            }
        },
        {
            "chainId": 101,
            "address": "3o9QgaRRjPKtjbpEtRHPNKUztyPqcdMV5rNwGZjYc8Pe",
            "symbol": "DRCO",
            "name": "Dracoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dracoin7/crypto/main/dracoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "988DvTYSr4h4bFfdegiw6pAjHH8wEe5QJ8ZbxczsqSrn",
            "symbol": "LGKC",
            "name": "LGK-BTS-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ludma/crypto/main/LGK-BTS-coins.png",
            "tags": [
                "social-token",
                "LGK",
                "BTS-CS"
            ]
        },
        {
            "chainId": 101,
            "address": "ASBqrsAiweKQTvZD1E9H6oPNneQra6jutzzfTkAcCUAw",
            "symbol": "KaPi",
            "name": "Kaboshon Points",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Getronixx/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FwFJMWrSEUViZkGDogdsBZ8HQ2XhjLDTmLd5xMe2AfHr",
            "symbol": "EMMA",
            "name": "Emmanuel Bruneau Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ImmoSearch/crypto/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CeRYEWdRSNbCqA8rC2s2eiANiqUpbGuhW3kD9uyvz8Kp",
            "symbol": "VLCT",
            "name": "Velocoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/conanjaywallace/crypto/main/Untitled%20design.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GGFNWQ2oEzYVPu1kGSjXZWfyTibnCGJfqZ7uPx8Jkj7B",
            "symbol": "SCTT",
            "name": "SOLANA CRYPT TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGFNWQ2oEzYVPu1kGSjXZWfyTibnCGJfqZ7uPx8Jkj7B/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cEEy8WweTy",
                "serumV3Usdc": "DRSZihtHpPkMqivKdwcdunGC3Z4aSfQFPiUCh3Nb4HPW",
                "twitter": "https://twitter.com/SolanaCrypt",
                "website": "https://solanacrypt.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7i9MWmz7ZQvstAFv43D3e4kmScXgnsN2YXd5NfkgDGd7",
            "symbol": "JBLZ",
            "name": "Dacoder Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jaeblaezdacoder/tyro-crypto/main/CSharp.png"
        },
        {
            "chainId": 101,
            "address": "2sxL3Ys8xyfKL1hcSiRP8jW6oAkNDHew9eMeKfvayRUb",
            "symbol": "PNET",
            "name": "Pendse Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EkadrishtaITSolutionsLLP/pendsetoken/main/logo.png",
            "tags": [
                "social-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9bj7eubfH6PzeSRC34msHevZLRmAPTv74mhay2V278V5",
            "symbol": "LSLA",
            "name": "Lusela",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/drewufool/Lusela/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J4pi4maoua63aUfpQR2L9wtUu73FeYayHeWxr4Dmk66h",
            "symbol": "HDZ",
            "name": "Headies",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J4pi4maoua63aUfpQR2L9wtUu73FeYayHeWxr4Dmk66h/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2imMJwQFXAF5mNRcptBz88zA327EVWNssPVF8dE4aTPN",
            "symbol": "MOOSE",
            "name": "Moose Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/joshmoose/moosecoin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RoacDJjaskP9mmDBGhbqE3wwZcbEmB5XCRBc12YzVw9",
            "symbol": "ROAC",
            "name": "Retroactive",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RoacDJjaskP9mmDBGhbqE3wwZcbEmB5XCRBc12YzVw9/Logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/g2swACkaWq",
                "telegram": "https://t.me/RetroactiveToken"
            }
        },
        {
            "chainId": 101,
            "address": "9n6ydqe6uczUuvydKvVRDTkcTPFJKrcgmTAT21qzjEWj",
            "symbol": "Wolfx",
            "name": "Wolfy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FXCrypto17/WolfGxng/main/Wolfy_768x839.png",
            "tags": [
                "social-token",
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "5LoM1CvGUgv2FAPgt6HLbWtPDvatuLBZVQURn7zqHMzM",
            "symbol": "NDCT",
            "name": "NandoCarter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jhosuave/nandocarter/main/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HZ3aLWMdriT5RepMWXYTFMQxqLNwTHk5WsnAPn2i6K5A",
            "symbol": "SSHIB",
            "name": "Solana Shiba Inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZ3aLWMdriT5RepMWXYTFMQxqLNwTHk5WsnAPn2i6K5A/logo.png",
            "tags": [
                "meme-coin"
            ],
            "extensions": {
                "instagram": "https://instagram.com/solana_shibainu",
                "telegram": "https://t.me/solana_shibainu",
                "twitter": "https://twitter.com/solana_shibainu",
                "website": "https://solanashibainu.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn",
            "symbol": "BOX",
            "name": "Solootbox DAO",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn/logo.png",
            "tags": [
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "address": "DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn",
                "description": "Solootbox is a rewards driven DAO structure built on the Solana blockchain.",
                "discord": "https://discord.com/invite/hM78bJHMqx",
                "twitter": "https://twitter.com/solootbox",
                "website": "https://www.solootbox.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "7YLmEQ3y3r976KKNpwk74pumdj6FmW7nXsDv8YGYfD64",
            "symbol": "PI",
            "name": "Pi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nekardms/crypto/main/PiLogo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhmwsTTEA9kp7D6syGPS6TGXtUmAnENKDLh3CmKZZpni",
            "symbol": "FRC",
            "name": "FriesCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FriesCoin/Crypto/main/logo.png",
            "tags": [
                "social-token",
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "5ptALUaFeYixtgX4eUMoouFPXqhZt6sLJJjPDq9hJJy7",
            "symbol": "PAP",
            "name": "PapaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Misterakos/Crypto/main/PapaCoin%20logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/PapalexiouPavlos",
                "instagram": "https://www.instagram.com/pavlospapalexiou/",
                "twitter": "https://twitter.com/PapalexiouP",
                "website": "https://papalexiou.me"
            }
        },
        {
            "chainId": 101,
            "address": "9rrHezomzprX9DKtoV17xcPDPRK6ktdfk9cD4KFe8sxY",
            "symbol": "CICD",
            "name": "CoIncident",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9rrHezomzprX9DKtoV17xcPDPRK6ktdfk9cD4KFe8sxY/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "h2M41fGRQ95TrNTY1kXFhgC6GBbksPeHqKgmR2BfxVz",
            "symbol": "NASI",
            "name": "NASI BUNGKUS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Nasino1/nasi/main/logo.png",
            "tags": [
                "kalebokan"
            ]
        },
        {
            "chainId": 101,
            "address": "612SCMgbrntMy7YTPzKRxL7cUQmjMmQytSNUSVwZySUM",
            "symbol": "BODIE",
            "name": "Bodie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pmcgovern/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HqUbEX8xNDsWYyrrA4JU9RRFfxbDSoKSx8jeiJMBa9ua",
            "symbol": "QUBE",
            "name": "Homeqube",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HqUbEX8xNDsWYyrrA4JU9RRFfxbDSoKSx8jeiJMBa9ua/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6RGkgyHiMwdd9p5PthJQD7K98v2nd9XzEeh8rFJzZwzG",
            "symbol": "HVACR",
            "name": "HeatingVentilationAirConditioningRefrigeration",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6HPGGdi3XoxwpW3GZyPgijUdJQiQRMCnPiyxFDyrpfW6",
            "symbol": "JAY",
            "name": "JayCoin",
            "decimals": 0,
            "logoURI": "https://github.com/jaycuts/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDMVdShFH3h6SH5c4mcff463uiUg7ahhgUK1koQZwTmv",
            "symbol": "JVK1",
            "name": "Javikito1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7oR9BN3aEq1dm6NCxdbSiQhGrDxcHRvmCRLGXHCUfrEi",
            "symbol": "JUJUB",
            "name": "JujubaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/davidveg/jujubacoin/main/logo.png",
            "tags": [
                "social-token",
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "CefqAKPjEBQ856XWJGABpPAf7idJYdfhyAoNWzFGvfLC",
            "symbol": "WEISS",
            "name": "Weiss token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/0xw01f/weiss-token/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "github": "https://github.com/0xw01f",
                "twitter": "https://twitter.com/w01f_src",
                "website": "https://w01f.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "5R1t9pwjEXVbvzhBCRT2ELiqMuLSf6r6vJ8RYTsXGXW1",
            "symbol": "OPSAM",
            "name": "Samatoshi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/OpSam/crypto-/main/octopusLand1%20300x300.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31WizpkSKyhptDcBw9fuSxMwd9Mkjac4rxM28pkcKjM5",
            "symbol": "KDMK",
            "name": "Kadima Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Blue182/Kadima-Koin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Agyt4PGy7sqq6yjEJddtCzYsvRuSJDefwTsMgobV67xo",
            "symbol": "ARCN",
            "name": "Augmented Relity Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shashtikar/arcoin-legacy/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BBKSy4KFdcZuWhEEcYTBLf9TGXZ321Yb44VPDdpYAnmY",
            "symbol": "NBE",
            "name": "Nube Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thebdsw320/crypto-sol/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AfA8C1cJNaHo2kSw6X353qCAzytmcXw5WWL5QkPmxd7h",
            "symbol": "SOCO",
            "name": "SoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bsepsey/socoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7PbCuPKJVrirRFydAgmTDBoxsysrdd7JjP2pBmrHvAf9",
            "symbol": "RMNI",
            "name": "Armani Mendrek",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/githubmaster308/crypto/main/Mani.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4BNcUV7Azh1xnWjppzq4exQTxvsL4XKsNbKF4sFtwtNc",
            "symbol": "KISSES",
            "name": "Kiss Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4BNcUV7Azh1xnWjppzq4exQTxvsL4XKsNbKF4sFtwtNc/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HvAedw7nTFNgLQjBkTn55LKpCgnTUzzYQ5bRU5M4QTXB",
            "symbol": "EURO",
            "name": "EURO token",
            "decimals": 2,
            "logoURI": "https://github.com/ArtTokenFund/solana/blob/main/blockPNG.png",
            "tags": [
                "DeFi-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CvkDiC6FziAnX5e8XufNDjFXCWNyRCSoCfhdEq2e5res",
            "symbol": "FluffyDegenerate",
            "name": "FluffyDegenerate",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lofobic/FluffyDegenerate/main/FluffyDegenerate.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5strq6dAS5LGURu2LWtwzzao2QVMtmzNKZwsDZfGYv6T",
            "symbol": "GAMBL",
            "name": "GAMBL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresamari/gabl-website/main/src/assets/GAMBL.png",
            "tags": [
                "gamble-token",
                "game-coin"
            ],
            "extensions": {
                "address": "5strq6dAS5LGURu2LWtwzzao2QVMtmzNKZwsDZfGYv6T",
                "description": "gambl.fun is a decentralized platform where you can use GAMBL coins to gamble on deterministic mini-games like roulette.",
                "discord": "https://discord.gg/DzCRqg29",
                "website": "https://gambl.fun/"
            }
        },
        {
            "chainId": 101,
            "address": "FPHLFQxs9VEKetUGNFaQu7LDK6DxckBQ199uxnQPNDzK",
            "symbol": "JY",
            "name": "Junkyard",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/4lxAnd3r/cryptostuff/main/JYLOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9PsfjNhfvPJEctsuGPFb74RENn4z7obna9zjGcRPuaVa",
            "symbol": "dooboo",
            "name": "dooboolab coin",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/27461460/147493821-1d95ae21-319a-410d-8a2c-4a69892080ec.png",
            "tags": [
                "dooboo-coin",
                "community-coin"
            ],
            "extensions": {
                "website": "https://dooboolab.com"
            }
        },
        {
            "chainId": 101,
            "address": "AShCRr7fqsMf7ieM5AkJqNY566HsYmtvpvK8oPUL4Bh8",
            "symbol": "MBC",
            "name": "MoneyBoy Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AShCRr7fqsMf7ieM5AkJqNY566HsYmtvpvK8oPUL4Bh8/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaMoneyBoys",
                "website": "https://www.solanamoneyboys.com/"
            }
        },
        {
            "chainId": 101,
            "address": "H7r1ZdGStLSNbfTur4b5JadQnBxs3Uxg2SQsMTUus3u7",
            "symbol": "AMBER",
            "name": "Amber Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/richardmotion/ambertoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Aufx78VHew6J1nyLiGZtAJozb9gEh7hWDE9XCib1k3eh",
            "symbol": "XEL",
            "name": "Sunflower Solar Energy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aufx78VHew6J1nyLiGZtAJozb9gEh7hWDE9XCib1k3eh/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://ntontel.com/"
            }
        },
        {
            "chainId": 101,
            "address": "784sQdraSP34ij3nokHomQ1EcB2aU3AzHgXG9gX8BRWe",
            "symbol": "DRAGON",
            "name": "Dragon Li",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LeChef/crypto/main/Screenshot_2.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "address": "784sQdraSP34ij3nokHomQ1EcB2aU3AzHgXG9gX8BRWe",
                "description": "Shiba's sister on the Solana network."
            }
        },
        {
            "chainId": 101,
            "address": "2zjmVX4KDWtNWQyJhbNXgpMmsAoXwftB7sGV4qvV18xa",
            "symbol": "EGO",
            "name": "Shear Ego Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jcards26/sol/main/new-new.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://shearego.com"
            }
        },
        {
            "chainId": 101,
            "address": "DY4bQMeGTg57b7EUjkpQk4JyGAAaRam5sggiHtmAvVWn",
            "symbol": "ARXCC",
            "name": "ArxaiosCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ckotselis/arxaioscoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5FuVDFV6VRhzsH5K26go93y5FVBTnDtyB2QZruP5uBk8",
            "symbol": "RDG",
            "name": "Rodger",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/erodger/crypto/main/logo_rdg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1",
            "symbol": "bSOL",
            "name": "BlazeStake Staked SOL (bSOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1/logo.png",
            "tags": [
                "utility-token",
                "stake-pool",
                "stake-pool-token"
            ],
            "extensions": {
                "github": "https://github.com/SolBlazeOrg",
                "twitter": "https://twitter.com/solblaze_org",
                "website": "https://stake.solblaze.org/"
            }
        },
        {
            "chainId": 101,
            "address": "CTDQnU7CUtQZis11khk8YADtYVuBt33KRoird8ADo76m",
            "symbol": "TECC",
            "name": "TechCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Semiprowhistle/crypto/main/9acab35e26de4bbebbc549a364a7a236.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2NURMkJEkLWUXF91kbhBETkZ5E2D674DF2Wi5X2ZTsTH",
            "symbol": "GCC",
            "name": "GitCompile Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gitcompile/assets/main/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://gitcompile.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5Re6i2v7iRsFyVqPxMX4gNHpgBxBaRnEBv2mWYp5i79d",
            "symbol": "TAYO",
            "name": "Tayo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/samwarlock/TayoCoin/main/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://mexel.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CrzX8DyyV9xLj2pTVB4iohdDRw7CXfNKeA9iksxHjvEz",
            "symbol": "TAN",
            "name": "Tanda Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CrzX8DyyV9xLj2pTVB4iohdDRw7CXfNKeA9iksxHjvEz/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://defitanda.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "C9mNSDBnHbpgRCwrtcpDQGNJJbNZPTLNKDe2d195uBSc",
            "symbol": "META$",
            "name": "MetaHedge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9mNSDBnHbpgRCwrtcpDQGNJJbNZPTLNKDe2d195uBSc/logo.png",
            "tags": [
                "metahedge"
            ],
            "extensions": {
                "website": "https://metahedge.site/"
            }
        },
        {
            "chainId": 101,
            "address": "8vNC6WZNQA55jv6dsVv7KYtyGUoTCdGDtsNZwVSJLv5L",
            "symbol": "IDK",
            "name": "IDONKNOW",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CoachAlpha/IDK/main/Nunito.png?token=AQLSQ24XAXTVK2JXXFQLFVTB2S5B6",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CCatQV1QfPCgLm6PwBMEHF2mMesAgMFwSqx64hvLGHvN",
            "symbol": "BORROW",
            "name": "BORROW",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/INBBELGIUM/BORROW/main/borrowPNG.png",
            "tags": [
                "BlockExchange"
            ]
        },
        {
            "chainId": 101,
            "address": "65xYYLAGvEH3JFiTAuNAvjyHEN1sYRVtVqbtpYWwQKkt",
            "symbol": "SKDB",
            "name": "Skanderbegcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/scanderbegcoin/crypto/main/logo_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GKG5EhKMCrwBfGBsT1iCbkRU4eajiXZPrKDJRttMy7M8",
            "symbol": "ANSAM",
            "name": "Ansam Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/k3ruakk/cryptoBTSI_ANSM/main/Logo_ANSM_C.pngrypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CB7QCaCyWW1cW6v2yRQEJMHQqeqSMGqyHgLiQYzPt9q1",
            "symbol": "BOSS",
            "name": "Bosschester Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jwood91/bosschester-coin/main/bosschester.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7mwv5foKostkWY2uNs7f5BikFyZRYHGtkzqrCWF1d19B",
            "symbol": "PWR",
            "name": "Power",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Zacnode/Z/main/coin.png",
            "tags": [
                "pwr-token",
                "defi-token",
                "community-token",
                "social-token",
                "security-token",
                "utility-token",
                "tokenized-stock",
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3px4SevZeazd16dM8tFKny2defzXJYPjpyXhbrg7ZnGg",
            "symbol": "GRAS",
            "name": "GRASSI Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GRASSI-CRYPTO/crypto/main/grassi_logo.PNG",
            "tags": [
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BBDTWqhhsACYGapK6uuSbFAgxzrdTmgNqG2dWTgL2pyE",
            "symbol": "BII",
            "name": "Shebii Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shebiisocial/shebiicrypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://shebii.com"
            }
        },
        {
            "chainId": 101,
            "address": "CRpfh3FNgeW6c2ennQihYiSFVTMzieeUH47t5SdkzgQ",
            "symbol": "CURC",
            "name": "CurrieCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LcCompany/CurrieCoin/main/Logo_CurrieCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9rA5Kr1CvnuwT5zgkfxMUBxEk2XMVtNGBmYrpVM8QSGp",
            "symbol": "WOLF",
            "name": "Wolf Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pspizzo/solana-wolf/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9opSeM2GqYG7Zpzvamr2wV1bYN7xjYMGY9ChbhDt1GE3",
            "symbol": "GIC",
            "name": "GiobaCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/nicogioino/giobacoin/main/gioba-coin-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ugYBhmHuF8sWjpD8NFrnCPAor4zWRkNwYS5qPQa9c2d",
            "symbol": "REIT",
            "name": "MetaREIT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/metareit/crypto/main/Logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HjJpn4jRa5uXLKHMqabUtCY7VbxZC3QhBTDqwvYypQ6i",
            "symbol": "RLX",
            "name": "RALI",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/seanjoneil7/Rali/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CzoZAELx9jhoxZkgqY6Rt2xbvEEzWs2FpM1tQGvKfGd1",
            "symbol": "T-SUGER",
            "name": "Token SUGER",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CzoZAELx9jhoxZkgqY6Rt2xbvEEzWs2FpM1tQGvKfGd1/cube-logo.png",
            "tags": [
                "asset"
            ]
        },
        {
            "chainId": 101,
            "address": "6gPe2ZHagsEjVJ5EoykKLsQSiqBUrRJJHogEy9WVREjV",
            "symbol": "PUKI",
            "name": "Puki Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AimeeKnight/puki-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Qkg5VLCQp1bkGQob6SFGKUZYX3wWz4pVhCtmM319SsB",
            "symbol": "HVACR",
            "name": "Heating Ventilation Air Conditioning Refrigeration",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MARRS3/Hvac/main/z676z7d%2B6763.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9k7cDLm7DXuNnunoruk25VwodPo8QM3647MRKdk9vbW5",
            "symbol": "SBCN",
            "name": "Smartbrain Coin Network",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9k7cDLm7DXuNnunoruk25VwodPo8QM3647MRKdk9vbW5/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fp7Tn42tBXe2vWbTLqBDcjzz4SaY68uaaD4453GEQC7o",
            "symbol": "Nurture",
            "name": "Nurture Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fp7Tn42tBXe2vWbTLqBDcjzz4SaY68uaaD4453GEQC7o/logo.svg",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://molabs.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "Dybs1hyBS16jNSBFpQ3hFJdLutw38CWcD3cD1qm75aL6",
            "symbol": "RAM",
            "name": "RAM Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robilokos/my_crypto_coin/main/ram-coin-logo.png",
            "tags": [
                "community-token",
                "ram-coin",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Digi7SnUD9ddiitEqkNfby1c4BfkgokFKWu9KScbizes",
            "symbol": "$DIGI",
            "name": "Digi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Digi7SnUD9ddiitEqkNfby1c4BfkgokFKWu9KScbizes/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DigiDinoNFT",
                "website": "http://pridelands.co.uk"
            }
        },
        {
            "chainId": 101,
            "address": "7QkpFkmjizUeFPL5n2zCdQXuktfqDriQJFSMg7FVpwEg",
            "symbol": "PTCN",
            "name": "Pota Coin",
            "decimals": 0,
            "logoURI": "https://github.com/mircolik/potacointoken/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2es95eT9Lq4akJcits6m9EuiuxfFG3RXtwmHuPqtrae8",
            "symbol": "MOOS",
            "name": "Moose Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bjs3/crypto/main/moose_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FqFjvxF78gLHDc1E6UCAHEo59xvNJxiugNdQPubG2BFk",
            "symbol": "ODN",
            "name": "Valhalla Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/odin-bb/crypto_logos/main/odin_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6b1FV6YcgQJUdLQHGbZSF2TVyZ63TkmLK8AZQNwvACma",
            "symbol": "SNBK",
            "name": "SonnyBuck Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/creatorSonny/sonnyBuck/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G2jD26aaBtHFB3MEn8SUd9StX9NYG3hu4chCM5AdnStq",
            "symbol": "PWR",
            "name": "Dino Power Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G2jD26aaBtHFB3MEn8SUd9StX9NYG3hu4chCM5AdnStq/logo.png"
        },
        {
            "chainId": 101,
            "address": "CFyomVLnGjVssjzjtPCG4wC258FQJXaQddrCnC8dRVqB",
            "symbol": "NTLC",
            "name": "Nightly",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "883kL8uXDpNdH9W15q2BcnQ5WcQ8dsga2x9SDs2yGNT5",
            "symbol": "ROYS",
            "name": "RoyalSnakes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/883kL8uXDpNdH9W15q2BcnQ5WcQ8dsga2x9SDs2yGNT5/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/royalsnake5",
                "website": "https://royalsnakes.club/"
            }
        },
        {
            "chainId": 101,
            "address": "HBxHiTHpmnps5ALo2jzZbVmUzPPpZsUT8wN8KtqkBU9h",
            "symbol": "NAMEK",
            "name": "Namek",
            "decimals": 9,
            "logoURI": "https://github.com/neptuncoin/namek/raw/main/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CwoGqZVdbhsVB9LyMgYRFoXTEixE8uZKdacXxECgXuQ3",
            "symbol": "ZEAL",
            "name": "ZEALLIONAIREZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FRESHKINGZ/crypto/main/ZEAL-TOKEN.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Xc1p7sxbVZnQZ5ZHVSthCo4n2WMSmB5we6s5xu2ztua",
            "symbol": "FabL",
            "name": "FABULOUS",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/fabienlopes/FABL-Crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "Metaverse",
                "NFTs"
            ],
            "extensions": {
                "description": "Solana Token by Fabulous Fab",
                "website": "https://fabienlopes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AZ2wwVXGgwf3CtEi1qAHoQo5o7ERDJfaMaKyxCM3igj5",
            "symbol": "AUD",
            "name": "STRAYA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dmnted/Straya/main/logo.png.png",
            "tags": [
                "Social-token",
                "Community-Token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Ug5TUBuzdvV1af2SQSqmzxfdC4frFy1oYuaPbgTYWJv",
            "symbol": "RDK",
            "name": "RIDIKIO COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bkornijenko/rdkc/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ENasDAoJpLs5wxpX1djqDUAp1PfwmmU8kCRKESHmV4Y5",
            "symbol": "JTX",
            "name": "JTX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENasDAoJpLs5wxpX1djqDUAp1PfwmmU8kCRKESHmV4Y5/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EhtDrCstJVsbKGLGVHXQFipJBQaV5721ykgBJUwECf7c",
            "symbol": "THGC",
            "name": "GandiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kunapoo88/crypto/main/newlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FKYuoLZSgZPAc4VuoDJBkXqq6sWDNGLLyxBdTxvXbs3a",
            "symbol": "GDST",
            "name": "Godeste",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/raggid00/crypto/main/gdst.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EgEybbXBmN9nH61mTa46BNT3H5ShcMTcUCtRQVd7uQ9e",
            "symbol": "chr",
            "name": "cryptoheroesrevolution",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BPWCNRZ/picture/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9bza9QfWA8M27CnUezbt2DXrMTcrSLjubqbPQBA7NFSq",
            "symbol": "EPA",
            "name": "E-Paisa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/omidalikozay/E-Paisa/main/logo.png",
            "tags": [
                "Payments"
            ]
        },
        {
            "chainId": 101,
            "address": "RDEwBzVqiEBRLwNt3ZNLfQf11N2rCZp2r6iYHkS3yD2",
            "symbol": "MTP",
            "name": "MetaPrimo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RDEwBzVqiEBRLwNt3ZNLfQf11N2rCZp2r6iYHkS3yD2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/Meta_Primo",
                "twitter": "https://twitter.com/Meta_Primoi",
                "website": "https://www.metaprimo.io"
            }
        },
        {
            "chainId": 101,
            "address": "3HovsMB1JnParzAgaUZLZRxTLAqLWqtc8z4Re8hB2ZUb",
            "symbol": "VOLK",
            "name": "VolKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/munkyz420/crypto/main/Untitled13_20211227205009~2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7rYHNNU1Quk56mzxXxGiaTWV6Hb1Dh1QNUyShVEdi2Qp",
            "symbol": "PTOC",
            "name": "Pluto Coin",
            "decimals": 9,
            "logoURI": "https://github.com/neptuncoin/pluto-coin/raw/main/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HG2FnifX1WPY6UvnkXnMx2kAt4E4Ssu6DZ2aiB5RmLHo",
            "symbol": "ISLAMI",
            "name": "ISLAMICOIN",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/ISLAMIBLOCKCHAIN/ISLAMICOIN/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "ISLAMICOIN is a cryptocurrency for the global Muslim community",
                "facebook": "https://facebook.com/islamicoin",
                "github": "https://github.com/islamiblockchain",
                "telegram": "https://t.me/islamicoin1",
                "twitter": "https://twitter.com/islamicoin",
                "website": "https://islamicoin.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AMHtKdUgDNDCc46tJpiDCCZNqLVMtvgEKHfnTqttkQ1N",
            "symbol": "NWT",
            "name": "Newmont Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Newmonttoken/Newmont-token/main/newmont.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ZQyX8fkcRQvVmzbgd8GX3TrSXiniJx8KkRrhTRTMZDh",
            "symbol": "EODC",
            "name": "EOD Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ZQyX8fkcRQvVmzbgd8GX3TrSXiniJx8KkRrhTRTMZDh/logo.png"
        },
        {
            "chainId": 101,
            "address": "FsxCN4C3dNQF1NtaT1CWf9mfEG2kSfPgUJU6wmAb3ffJ",
            "symbol": "TKOIN",
            "name": "TIME-KEEPER-KOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tkwsc/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "cet4t25o8KbxxphkR2jiS3vqE2HJiy4rZTsKRMYrrnj",
            "symbol": "WAGMIX",
            "name": "WagmiX",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cet4t25o8KbxxphkR2jiS3vqE2HJiy4rZTsKRMYrrnj/logo.png",
            "tags": [
                "defi",
                "community"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/hxPNxt2dG8",
                "medium": "https://wagmi-sol.medium.com",
                "twitter": "https://twitter.com/wagmi_sol",
                "website": "https://www.wagmionsolana.com"
            }
        },
        {
            "chainId": 101,
            "address": "Fp3xmEShsDMLTS7tDV1Wqz4JRLyf3gdpXCz198RFHdTz",
            "symbol": "XLS",
            "name": "Xelasi",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fp3xmEShsDMLTS7tDV1Wqz4JRLyf3gdpXCz198RFHdTz/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F4fgbBca87gDU2nsqcNjhKvVF6JKevsXkuyKhWoHRhQX",
            "symbol": "JK",
            "name": "Jaafar",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/Jaafarkrayem/Jaafar-Token/main/logo.PNG",
            "tags": [
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BjEn5yfq2uHFtxXqfatvjB5dzhmJaopvpNgxq66ouTSQ",
            "symbol": "BECOIN",
            "name": "Be Company",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bledrunn3r/bnut/main/b-logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/badoun",
                "website": "https://www.intengo.fr"
            }
        },
        {
            "chainId": 101,
            "address": "8mspsKgXTYmcQHXiRrt41R3jPkfHCsXXSStg56z7WhD6",
            "symbol": "WOOD",
            "name": "Wood",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rageousrsx/logos/main/w_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4GqXdRroc4tMneqcW6LLT4BvUdARMohFjVcokNfC3yp8",
            "symbol": "LYLN",
            "name": "Llewellyn Afallon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dewey420/crypto/main/LOFGOPNG1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dw6u2E7uBnogPiwN4G1Lxg6Pwf5HGbCPGfRpS46D7XHR",
            "symbol": "pkc",
            "name": "Peace Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/praladkhadka/sun1/main/amg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CQkTVkohEmyydNPNPH82c6aNPeXE72AatrhZcbntiAfg",
            "symbol": "WVIP",
            "name": "The WAGMI VIP Club",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CQkTVkohEmyydNPNPH82c6aNPeXE72AatrhZcbntiAfg/logo.png",
            "tags": [
                "utility-token",
                "Defi-Token"
            ],
            "extensions": {
                "website": "https://twitter.com/WAGMI_VIP_Club"
            }
        },
        {
            "chainId": 101,
            "address": "9zvpRCotU23T9Ykbq5VGGZV6vEcBioLpzUsEZGXjV1Pu",
            "symbol": "FRSGN",
            "name": "FirsGen Blessings",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb2/FirsGen/main/FirsGen.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HzphDb6FFqM3DWsKoRU8vEsg8hdL7muSdRzAEeE3AdLE",
            "symbol": "KRSB",
            "name": "Kers Brakers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb2/KersBrakers/main/KB.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GzpRsvnKXKz586kRLkjdppR4dUCFwHa2qaszKkPUQx6g",
            "symbol": "LOOT",
            "name": "LOOT",
            "decimals": 6,
            "logoURI": "https://arttest12312321.s3.us-west-1.amazonaws.com/pics/Bag_Open+(3).png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/copsgame",
                "twitter": "https://twitter.com/copsdotgame",
                "website": "https://cops.game/"
            }
        },
        {
            "chainId": 101,
            "address": "H7LGXowxxrgukYLUWAYwhnuXbXVugewTzE6dLu2ZnJti",
            "symbol": "DT",
            "name": "Derek Treonze",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/B33m0-1337/DT-Crypto/main/Derek.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BDQ1ALvrChL4CcjChwYp7C5XsXxbFkUj5hGdPM3AWkyf",
            "symbol": "PLC",
            "name": "Palastine Coin",
            "decimals": 0,
            "logoURI": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnKolKlwpw-Gp_UXWlgyFKWAOlf3LxYHODzw\u0026usqp=CAU",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F81wP9DYFnyk7JZtsHNks8S2QwG6vMwtmU13RwuU7bHP",
            "symbol": "SEAL",
            "name": "Seal Inu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/D3FUZED/sealinu/main/Social_Profile_Mirko.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3WQ53QmDCseyWZopw1EkyhWQKcsYYHr5RWsjLkqj8d2e",
            "symbol": "NKBC",
            "name": "NetworkKantreal B",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb3/NetworkKantrealB/main/Kantreal.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DP9fXAemWm3SGMZ5hE1qNqRBHaaNhEZ9o3aqHUEMBroN",
            "symbol": "CRUM",
            "name": "CookieGem",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/akashanova/CookieGem-Logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CKtNgCmqTiJi6Dv6MAM487u132goC9boQ2etRk7t2JVU",
            "symbol": "EXC",
            "name": "Exa Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/schweinder/exeter/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E3qVrUYYXEgt8HFhCks3Do2Ehxq6C9uqqTxLZwqxgcY7",
            "symbol": "AFTT",
            "name": "After Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/DrakeArcan/LOGO/main/AfterTokenLogo.png",
            "tags": [
                "Dex-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4qw5MNc9oLKS22hiFTK6TNBHCqegDK3qzhMDgawtwnUL",
            "symbol": "SSK",
            "name": "SolShark",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4qw5MNc9oLKS22hiFTK6TNBHCqegDK3qzhMDgawtwnUL/logo.png",
            "tags": [
                "utility-token",
                "meme-token",
                "dex"
            ],
            "extensions": {
                "discord": "https://discord.io/solshark_sol",
                "telegram": "https://t.me/solshark_sol",
                "twitter": "https://twitter.com/sol_shark_sol",
                "website": "https://sol-shark.com"
            }
        },
        {
            "chainId": 101,
            "address": "32trPxNGr47Xp8btb5de2p4jmwmTjeebDxsWEtnAC9R5",
            "symbol": "LBC",
            "name": "Liberty Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/elenaviolet/crypto1/main/Liberty_Coin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Nk6Nzg3AucaYtLoQvDLa8qVTVhVV7jJKYE56reWq5Xz",
            "symbol": "GCDOG",
            "name": "GeorgesCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/effset/crypto/main/georgescoinless200.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EXg16wru9VEEeTCWwEybTUDoUurvTTtQwapPsmitHsf4",
            "symbol": "GREY",
            "name": "The Greyson",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Chaosbeast9000/Crypto/main/grey-wolf.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HQJq5m1KxxNLEMfUqQNhxSbGaYGssf4QcHtE3UAVEjo5",
            "symbol": "PSYBER",
            "name": "psybertech's token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/psybertech/psyber_token/main/psyber_token_2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5U44iEAG39gysZdTV6gewnemuZHDJtEVdm5mmCMKrHoP",
            "symbol": "NTC",
            "name": "NetworkTrina",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb4/NetworkTrina/main/TN.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HUUZhbs3hKufU1fApwjEHmxobPaSh7gSrybeeZ2Sh2Hg",
            "symbol": "KJR",
            "name": "KJ Reward",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb4/KJReward/main/KJ.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G38t3TNTcKTpCVqgqu3WMsG6BALD5GnGRur2eVe2zmzf",
            "symbol": "TGC",
            "name": "Tray Gaming",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb4/TrayGaming/main/TW.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GjdyXC7cj97NZKBmypcS8ZH6GfL2Rjopj22BXbECfRpf",
            "symbol": "JFC",
            "name": "JFashion",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb4/JFashion/main/JW.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CUtaQC24jerou53mC5TQMfqa1XqATBNg1Sxnhjvf4JbQ",
            "symbol": "MAGA",
            "name": "MAGATOKEN",
            "decimals": 5,
            "logoURI": "https://github.com/hhhogannwo/MAGAToken/blob/main/logo.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9GyzdYVkfUH1s4MTB3Kv1FGgXTCtucsohukw5wfxmysg",
            "symbol": "CSUCSI",
            "name": "CSUCSI PUTYI TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/csucsiputyi/csucsi/main/csucsi_logo.png",
            "tags": [
                "soical-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BgeRyFWWGHeVouqfHfcXUxmvfkgekhrXYVqQWf63kpJB",
            "symbol": "BAPE",
            "name": "BAPE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BgeRyFWWGHeVouqfHfcXUxmvfkgekhrXYVqQWf63kpJB/logo.png",
            "extensions": {
                "coingeckoId": "bored-ape-social-club",
                "twitter": "https://twitter.com/BAPESocialClub",
                "website": "https://boredapesocial.club"
            }
        },
        {
            "chainId": 101,
            "address": "DfLX2gHyT75Y8Buh1tWt6MD3czKirJWKuTSwhPstPJd8",
            "symbol": "LFX2M",
            "name": "lfx2m",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lfx2m/crypto/main/lfx2m.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CFyomVLnGjVssjzjtPCG4wC258FQJXaQddrCnC8dRVqB",
            "symbol": "NTLC",
            "name": "Nightly",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Lfz9YhVb6nrU9jq2RPBtUL5VktGrrbDW1WBPcDzACu2",
            "symbol": "RAF",
            "name": "EXEMPLO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Rafinix/Crypto/main/Example.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BNTY5DaMP9CZhEtmQfMLHfUwwkXropHuCz4m96YqpqKm",
            "symbol": "BNTY",
            "name": "Bounty",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BNTY5DaMP9CZhEtmQfMLHfUwwkXropHuCz4m96YqpqKm/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bountyhunterspaceguild.com"
            }
        },
        {
            "chainId": 101,
            "address": "7gkM3c4zrHUwbv7BRsi8WiNck2nq2NmdsDguE4ipX46x",
            "symbol": "SLNY",
            "name": "Solenya Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IcyOtter/SolenyaCoin/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ynFsmZn1GpDreKCdn93ewqoUr5THpdhAo2MWcY4FmEG",
            "symbol": "DSPC",
            "name": "DSP Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/GenesisDSP/assets/main/DSPC.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://t.co/j1ljROSuoJ",
                "twitter": "https://twitter.com/cryptodsps"
            }
        },
        {
            "chainId": 101,
            "address": "3ez9nabkdvCjN4itVdyXSUX9dGgSvCTvqsBADa2Sxn9F",
            "symbol": "STC",
            "name": "Stouna Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/christian1409/stounacoin-1.0/main/coollogo_com-6144280.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ff5xHwgJDTowJ1x9r3EAoSznmVkWeSKNreRAAbJvzafF",
            "symbol": "HAMM",
            "name": "HammyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nledevil/hammycrypto/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7cjvfM6riwYjadQYKJ7BD6CrsUW5douFCDSNAQ275QKZ",
            "symbol": "ZEF",
            "name": "ZEFcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robazefa/ZEFcoin/main/ZEF_tongue6.png",
            "tags": [
                "social-token",
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "5h2opJngX4dYwZbsdjSqmvm9iaCBe8u9eGWfzud9d5Q4",
            "symbol": "VRinRL",
            "name": "VRinRL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xMaxix/crypto11/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "cwmkTPCxDkYpBjLQRNhcCKxuxnAQW6ahS7JQLeXrsXt",
            "symbol": "CWM",
            "name": "Catalina Whale Mixer Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cwmkTPCxDkYpBjLQRNhcCKxuxnAQW6ahS7JQLeXrsXt/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "E8CLGjBzJD7TPgjfcWm8dvpXhz5pvdjcPxhG7qi7Y5un",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "8Ea48QsKG4rcQtqGHxLYiJdVg96BUV8KU8VaVCCF6MTp",
            "symbol": "MEGABIKE",
            "name": "MEGABIKE",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/MegaBike-Simone/MegaBike/main/megabike-logo-PNG.png",
            "tags": [
                "MEGABIKE"
            ]
        },
        {
            "chainId": 101,
            "address": "7Af1biRuBcAQTEU3YkWaGGtQDFoxYmqLMyrBNQKnuogn",
            "symbol": "TRAC",
            "name": "Terea Coin",
            "decimals": 6,
            "logoURI": "https://github.com/neptuncoin/Terea-Coin/raw/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7YHfn7QR6uzK1o7ZtSXfLatzTix2En8ResVKDj83VXmP",
            "symbol": "HOTEL",
            "name": "HOTEL",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/NewHotel-Simone/NewHotel/main/hotelPNG-square.png",
            "tags": [
                "BlockExchange"
            ]
        },
        {
            "chainId": 101,
            "address": "HU8LWRUris9qQbFS78D9VTi3yu97XJpVaDoC7fLsGvPT",
            "symbol": "BOOTY",
            "name": "Dope Pirates Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dopepirate/Dopepirate/main/bootycoin.PNG",
            "tags": [
                "social-token",
                "meme-coin"
            ],
            "extensions": {
                "discord": "https://discord.gg/dopepirates",
                "twitter": "https://twitter.com/dopepirates",
                "website": "https://dopepirates.com"
            }
        },
        {
            "chainId": 101,
            "address": "Gz8GRWYB6X4BkTqA8AtoZGoHCTk6Dh4yedaA4B1LhCwV",
            "symbol": "SCC",
            "name": "SzymCiem Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/szymciem8/SzymCiem-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "47gavssnzGQLTUexBZyKqUh9b2iixymoJ9LpT9muGvPD",
            "symbol": "SHIBVS",
            "name": "Shiba verse",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sajjadafg2016/crypto/main/logo_shim.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7SZUnH7H9KptyJkUhJ5L4Kee5fFAbqVgCHvt7B6wg4Xc",
            "symbol": "SDO",
            "name": "TheSolanDAO",
            "decimals": 5,
            "logoURI": "https://thesolandao.com/logohero.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "coingeckoId": "thesolandao",
                "discord": "https://discord.gg/d8z7TAz5WB",
                "reddit": "https://www.reddit.com/r/TheSolanDAO",
                "twitter": "https://twitter.com/TheSolanDAO",
                "website": "https://thesolandao.com"
            }
        },
        {
            "chainId": 101,
            "address": "SuperbZyz7TsSdSoFAZ6RYHfAWe9NmjXBLVQpS8hqdx",
            "symbol": "SB",
            "name": "SuperBonds Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SuperbZyz7TsSdSoFAZ6RYHfAWe9NmjXBLVQpS8hqdx/logo.svg",
            "tags": [
                "defi-token"
            ],
            "extensions": {
                "website": "https://superbonds.finance"
            }
        },
        {
            "chainId": 101,
            "address": "F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B",
            "symbol": "AART",
            "name": "ALL ART",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/allartprotocol/token-list/main/assets/mainnet/F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/allart",
                "github": "https://github.com/allartprotocol",
                "medium": "https://allart.medium.com",
                "telegram": "https://t.me/allartprotocol",
                "twitter": "https://twitter.com/AllArtProtocol",
                "website": "https://all.art"
            }
        },
        {
            "chainId": 101,
            "address": "3CFNMMSQUeKRxTbVaaVhT3NxBgQ7rMuXauL7cbY8W9Sc",
            "symbol": "BARGE",
            "name": "BARGE BOND",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Barge-Simone/BARGE/main/bargePNG.png",
            "tags": [
                "BlockExchange"
            ]
        },
        {
            "chainId": 101,
            "address": "63hn7UtKkdePAjbbHsJTZ4qFHWiZBxmTsHRvpTVesAp5",
            "symbol": "PiTZ",
            "name": "PTZCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PTZCOIN/crypto/main/logo.png",
            "tags": [
                "social-token",
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "EoNyknzy8waz4MHP6Kue4MHQkDPzEYnCt5UEK9cBcG8c",
            "symbol": "SAL",
            "name": "Salvor",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EoNyknzy8waz4MHP6Kue4MHQkDPzEYnCt5UEK9cBcG8c/logo.png",
            "tags": [
                "gamefi",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6FPrmmvPvNnW6v3HDa51PoF31veMx1gqui3jJyo3JDB7",
            "symbol": "DES",
            "name": "The Destiny",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6FPrmmvPvNnW6v3HDa51PoF31veMx1gqui3jJyo3JDB7/logo.png",
            "tags": [
                "gamefi",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GM3pnrL7PcTpZ1F1k4JpEm76W2XF4oCWasoVHZH9Phfc",
            "symbol": "AZR",
            "name": "Azura",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GM3pnrL7PcTpZ1F1k4JpEm76W2XF4oCWasoVHZH9Phfc/logo.png",
            "tags": [
                "gamefi",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F3vTNbfYz6paDDTNn3suyK2wWBejED8PVY6uAP9jqCFh",
            "symbol": "PHAR",
            "name": "Pharawin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F3vTNbfYz6paDDTNn3suyK2wWBejED8PVY6uAP9jqCFh/logo.png",
            "tags": [
                "gamefi",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2D9iaJ5Rgb3R6DJ4v9Wd1CMEFSogBgEuReoEnGrgvquM",
            "symbol": "GUE",
            "name": "GUERRERO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2D9iaJ5Rgb3R6DJ4v9Wd1CMEFSogBgEuReoEnGrgvquM/logo.png",
            "tags": [
                "gamefi",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BFXrXHTgQbh4RGzVTfusuiZNADjAdSjWu37tWXJk2WY5",
            "symbol": "COFFEE",
            "name": "COFFEE",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Simone-Properties/COFFEE/main/coffeePNG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2zmWfuURnQQz5ymsvRhhF3FxVJrvpNCGDsKLE5fBnvAG",
            "symbol": "MOKI",
            "name": "MokiKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hinchingyuen/MokiKoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F46kqGKJRYg51PiyLsQhpFmaGCxL7Rz6VHh4TpmLebUQ",
            "symbol": "VINNIE",
            "name": "VinnieCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vmangelschots/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CPDMvZpN7TgZRbNfUWw6y5LG3zkiv8ZvHA4ZBHpgYSzm",
            "symbol": "CPDM",
            "name": "Carpe Diem",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPDMvZpN7TgZRbNfUWw6y5LG3zkiv8ZvHA4ZBHpgYSzm/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/CarpeDiemCPDM",
                "website": "http://cryptocarpediem.com"
            }
        },
        {
            "chainId": 101,
            "address": "ECsusvTb9KvVL3sedd5RsCn6aNDBxqyCSP5eEQGDEtnq",
            "symbol": "BASE",
            "name": "BSTK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SilverRuler/Solana_Practice/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75Brdir35vFLheHSoiEX3oWnTuHfAscYpGSBgfhRbmbk",
            "symbol": "BOOP",
            "name": "Boopieverse",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75Brdir35vFLheHSoiEX3oWnTuHfAscYpGSBgfhRbmbk/logo.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "description": "The official token powering the Boopieverse.",
                "discord": "https://discord.gg/u4es8yZrms",
                "twitter": "https://twitter.com/boopieverse",
                "website": "https://boopieverse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2f9GHFLy82GmYwrcTSVWr2fnPpTigmGdHRz5fpVisAXy",
            "symbol": "GNOM",
            "name": "Gnome Gnickels",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ThePicaresqueRed/crypto/main/GNOME_GNICKELS_75.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3jbVgUiyYybyou43zqx3qnPiPpbW64foe4Fxbxaer4Px",
            "symbol": "MANS",
            "name": "Mansour Family Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mansourSaleh/mansCoins/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mansour789",
                "website": "https://mansour1.sa/",
                "youtube": "https://www.youtube.com/channel/UC5JIaJ-RtJGp8wcOmn1ngNA"
            }
        },
        {
            "chainId": 101,
            "address": "8E38EebJE5sE3WGUhQquaoBUAEYBWzhyKtPUZqnNLS1Q",
            "symbol": "HLTI",
            "name": "Halitti Coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/odin-bb/crypto_logos/main/halitti_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CUPW2aLsxrCmhyqq2JgJwAYG41AfUwwCumpRf7PjKYFA",
            "symbol": "SHOP",
            "name": "SHOP Euro",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/SHOP-Simone/SHOP/main/shopPNG.png",
            "tags": [
                "Shopping"
            ]
        },
        {
            "chainId": 101,
            "address": "6qshnWEZth41WfL8Nhzar6yVz7intHcNmGQSFYxTg3DD",
            "symbol": "GEBO",
            "name": "Futhark Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Erulian/crypto/main/TKN_GEBO.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8niFXjTqhFsSuZX7Rtryi2cZtJuSCrxj63mX9t8mPrv4",
            "symbol": "EURO",
            "name": "EURO",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/EUROSHOPPING/EURO/main/shopPNG.png",
            "tags": [
                "Shopping"
            ]
        },
        {
            "chainId": 101,
            "address": "DHxMYFZ6hZYtX4LXLkSHRN2UHFFezE5X6GkRCQNW1un9",
            "symbol": "TQE",
            "name": "TRI NETWORK COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/angel999lucifer/tri-network-logo/main/LOGO.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6R1AG9dFMKdrysuYifUw3Q9kzaWUaMhCBnewoLJ2eGEL",
            "symbol": "KOS",
            "name": "Kingsolo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6R1AG9dFMKdrysuYifUw3Q9kzaWUaMhCBnewoLJ2eGEL/logo.png",
            "extensions": {
                "discord": "https://discord.gg/kingsolo",
                "twitter": "https://twitter.com/kingsolo_kos",
                "website": "http://kingsolo.io"
            }
        },
        {
            "chainId": 101,
            "address": "DCMupejk24xFG1qZdXf1y2DuoFQ19hJysshjxjL4urCg",
            "symbol": "BGODS",
            "name": "Battle of Gods Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BOG-Game/Token/main/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "description": "Battle of Gods is an unique and addictive NFT game with full tokenomics, where players Battle with Gods to become a God themselves! The biggest Mythoverse on the block-Chain and the 1st Board(game) 2 Block(chain).",
                "twitter": "https://twitter.com/BattleofGods_io"
            }
        },
        {
            "chainId": 101,
            "address": "5W1FqGQbbGvw2k6KWULaSnbefYUDjumKvvsMoQYjJ6Ho",
            "symbol": "WEAR",
            "name": "Anywear",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5W1FqGQbbGvw2k6KWULaSnbefYUDjumKvvsMoQYjJ6Ho/logo.png",
            "tags": [
                "metaverse",
                "oracle-token",
                "cross-platform",
                "nft-market",
                "cros-chain"
            ]
        },
        {
            "chainId": 101,
            "address": "AH4JyGYUTfXJt8F4FuoHQ6FgVNvGNkXE2g2uewNdTk7C",
            "symbol": "BITCOIN",
            "name": "BITCOIN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/BitcoinSimone/Bitcoin/main/borrowPNG.png",
            "tags": [
                "Shopping"
            ]
        },
        {
            "chainId": 101,
            "address": "CkMSudX33BeAP7kYRjXkwnixUbPvypSuxcYJbwEPhE1Y",
            "symbol": "FONSO",
            "name": "Fonso",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alfonsorua/fonso/main/fonso.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6QYe2Mea4b1cH9dk3C8g29e95Bah9R4CmTGdSpjajumr",
            "symbol": "HUDA",
            "name": "HugeHuda",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aradhya-B/huge/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J6Z6mDhXAxv3xxQVjPDky6hWoGSrmJ8fJ12KzpYYfisk",
            "symbol": "SKAL",
            "name": "SkalCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Skaldhor/SkalCoin/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "github": "https://github.com/Skaldhor/"
            }
        },
        {
            "chainId": 101,
            "address": "24WQvWoqJuTS5LoqeBJpa2smqg94V6iqQDWC5cPd8tve",
            "symbol": "JINDO",
            "name": "Solana Jindo Inu",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/soljindo/main/soljindoinulogo.png",
            "tags": [
                "social-token",
                "meme-coin"
            ],
            "extensions": {
                "telegram": "https://t.me/soljindo",
                "twitter": "https://twitter.com/soljindo",
                "website": "https://www.soljindo.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CxdritkAkNqMut7z7kzfcNwYKrH9LH5kyN36rBpVdiBv",
            "symbol": "ALC",
            "name": "AlPHA Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CoachAlpha/ALC/main/alc.png?token=AQLSQ242CJWZUT22OXZWNGDB2XLTM",
            "tags": [
                "Community",
                "AlPHA"
            ],
            "extensions": {
                "github": "https://github.com/CoachAlpha",
                "instagram": "https://www.instagram.com/am1r__8/",
                "twitter": "https://twitter.com/Unknown_830",
                "website": "https://alpha83.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "CmFbEaGxa2Z9mhfnZzwSQS5VqkoiFQTFQ2TxzfnAP338",
            "symbol": "PMS",
            "name": "Parekkara",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vivekparekkara/parekkara/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H69DNtbZZtk92hLLbMrHYqL8HiSHFVGcf6USHU39oRMW",
            "symbol": "GUANO",
            "name": "Guano",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H69DNtbZZtk92hLLbMrHYqL8HiSHFVGcf6USHU39oRMW/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "website": "https://newbat.city/"
            }
        },
        {
            "chainId": 101,
            "address": "G79qAryn3Urn4pyJyTSiX6XNz3Zk1epwJbsnLA5Yntz5",
            "symbol": "SIMONE",
            "name": "SolSimone",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/SolSimone/SOL/main/borrowPNG.png",
            "tags": [
                "Shopping"
            ]
        },
        {
            "chainId": 101,
            "address": "DLdAV7fxTQGfj82QpGMLw52EaxRAKGHz6Vp8xYPf2gPY",
            "symbol": "CDY",
            "name": "CodyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/m-volans/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6gtgcsCoGCaUa2w2r9vP4rSRshkySYPScBJrQDZ3VZoo",
            "symbol": "BAFF",
            "name": "BayernVerse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6gtgcsCoGCaUa2w2r9vP4rSRshkySYPScBJrQDZ3VZoo/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7TmmhvjfYzPt16xkNe6KK3u3CRqyRCbcEpiy4KaPD1UN",
            "symbol": "TBC",
            "name": "Tollie Bonker Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TollieBonker/Logo/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AxdUT9mVNYa5A8GVyDRTm8m87SrDJPd6RKR8HBvfB82M",
            "symbol": "YDF",
            "name": "YedekFih",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lordbelazzou/crypto/main/1w4aib.png",
            "tags": [
                "algerian-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BU33VVNSwUXcD8LXfoQxj4hgUNvKMMm5AEhRZs6EaiEJ",
            "symbol": "RR",
            "name": "RealizedRhapsody",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BU33VVNSwUXcD8LXfoQxj4hgUNvKMMm5AEhRZs6EaiEJ/logo.png",
            "tags": [
                "access-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://realizedrhapsody.com/"
            }
        },
        {
            "chainId": 103,
            "address": "ChfDuUG56zAELGyNad7g9ZXAx4vBkvXPntWTKWPtgaNT",
            "symbol": "CO2",
            "name": "CO2 Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/vitsanukomet/crypto/main/logo.png",
            "tags": [
                "co2-token",
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/vekin.th/",
                "website": "https://www.facebook.com/vekin.th/"
            }
        },
        {
            "chainId": 101,
            "address": "14r8dWfzmUUBpw59w5swNRb5F1YWqmUnSPgD6djUs1Jj",
            "symbol": "TREATS",
            "name": "Solana Puppy Pound Treats",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/14r8dWfzmUUBpw59w5swNRb5F1YWqmUnSPgD6djUs1Jj/logo.png",
            "tags": [
                "community-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "Hc7tVNLibu4YWHzy4VebGWpisTxnBykemMUbkBkk3yeL",
            "symbol": "PAPI",
            "name": "Papito",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mmauroness/cryptopapito/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3whnH8NaUKgcFN73tGB1RBxUznz6SoHye78fKw8Mrepq",
            "symbol": "TPHT",
            "name": "Top Hat",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/akrowczyk/tophat/main/logo_token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3vCtw56GedaqNhZQniJRoCFFZwnABrtcBqmK8RHVz1kt",
            "symbol": "VVC",
            "name": "ViaViaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aiviator/viaviacoin/main/logo.png",
            "tags": [
                "social-token",
                "matching"
            ]
        },
        {
            "chainId": 101,
            "address": "GUz4vBLFWyjURSHx5wUVHPphGSG3ztXiv1puyEZeUUA3",
            "symbol": "MOQSIS",
            "name": "Moqsis Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/scottjones4k/token-list/fa69bc55947c826fd8995a2c43470e7d9d771d9e/assets/mainnet/GUz4vBLFWyjURSHx5wUVHPphGSG3ztXiv1puyEZeUUA3/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GamK6bxGt1RRUAkFnQYgrJgXfNsaQqXv2ivcDxNUzJ5F",
            "symbol": "LUCA",
            "name": "Lucas Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MRGithubuser2020/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GX7scixLhnEQk3C1LEDKk5eeeUb2RUwyyKEWYRfAV8SA",
            "symbol": "DSB",
            "name": "Dogstreetbets",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Terrahasher/dogstreetbets/main/dogstreetbets.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7u6HvxGErHCqU9nr98Xsv8DDFfmuVyvMpknS3uC5kn9B",
            "symbol": "MVFC",
            "name": "Marvel Fans Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/juanlungo/cryptomvfc/main/marfanslogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5L87fjh5XZWERN4UGbK62TM1funxFvXSRUGmvbHBGqn1",
            "symbol": "ILUMA",
            "name": "ILUMA COIN",
            "decimals": 9,
            "logoURI": "https://github.com/neptuncoin/iluma/raw/main/logo.png",
            "tags": [
                "community-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "FnVPD3fRutXcibEHDYP1gv8kuYJx2SNCi2EizcAsR4wN",
            "symbol": "CMP",
            "name": "Camp Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FnVPD3fRutXcibEHDYP1gv8kuYJx2SNCi2EizcAsR4wN/logo.png"
        },
        {
            "chainId": 101,
            "address": "GAZ2qHKKvTgXpDyDyCVJ3Eh9hJkVLwjiwj4U6da4XTyk",
            "symbol": "2359",
            "name": "Midnightcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SirHackalots/2359/main/23.59.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL",
            "symbol": "SLC",
            "name": "Solice",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL/logo.png",
            "extensions": {
                "coingeckoId": "solice",
                "discord": "https://discord.gg/solice",
                "serumV3Usdc": "DvmDTjsdnN77q7SST7gngLydP1ASNNpUVi4cNfU95oCr",
                "telegram": "https://t.me/solice_io",
                "twitter": "https://twitter.com/solice_io",
                "website": "https://www.solice.io/"
            }
        },
        {
            "chainId": 101,
            "address": "gen2VK2sZstCfzsbN7rGiMCoe4WuMS9JwLiJNTn4cEy",
            "symbol": "SLUGGEN2",
            "name": "Generation 2 Slug",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolSlugs/assets/main/gen2redeem.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "description": "This token lets you mint a generation 2 Sol Slug",
                "twitter": "https://twitter.com/SolSlugsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "C15c651KE8HGUrbb2YZXYMEPj2iadkng7FgpHMFbHkUj",
            "symbol": "phantom-webdge",
            "name": "phw",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo/logo.png",
            "tags": [
                "solana",
                "ghost",
                "stack"
            ]
        },
        {
            "chainId": 101,
            "address": "DyT12z3tnUokpy18XdKXQNxsGmgUzAA67JUPpj6dBRtd",
            "symbol": "RGSC",
            "name": "Redmatter Game Studios Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/redmatter20/RGSCoin/main/RGS%20COIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8bosbbufBRQeF23tumTQWJVUwmX5PJ2h5vpmzzcs44pL",
            "symbol": "URN",
            "name": "URunner Coin",
            "decimals": 6,
            "logoURI": "https://cdn.jsdelivr.net/gh/URunner21/UrunnerApplication@main/IMG_0698.jpg",
            "tags": [
                "social-token",
                "urunner",
                "community"
            ],
            "extensions": {
                "twitter": "https://twitter.com/URunnerApp"
            }
        },
        {
            "chainId": 101,
            "address": "boomh1LQnwDnHtKxWTFgxcbdRjPypRSjdwxkAEJkFSH",
            "symbol": "BMA",
            "name": "Boom Army",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/boomh1LQnwDnHtKxWTFgxcbdRjPypRSjdwxkAEJkFSH/logo.svg",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BafovBHSnzFnHdkBHt1Nogkbzxrpn1o5ubACrc7H1Vfz",
            "symbol": "8OO8",
            "name": "8OO8 Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mannytatas/sol-8OO8-coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "8008"
            ]
        },
        {
            "chainId": 101,
            "address": "3PaXcR4gCNiYPSpibwnBDXmuv3nABA1Uq4J3R8MNWhq2",
            "symbol": "TNCC",
            "name": "TheNerdClan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheNerdClan/tnc-crypto/main/Logo%20TNC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "788Pkr27oUB9RwWsQL8nRNVeEPxqvvjnqKnmRKR9ig2M",
            "symbol": "YBC",
            "name": "YeahBitcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PhillipsCryptoNews/YeahbitCoin/main/yeahbitcoin-logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "description": "This token is to celbrate the entrepanural spirit",
                "instagram": "https://www.instagram.com/yeahbitcoin",
                "website": "https://www.yeahbit.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3gyTgBZsfyPqs5wcRYnE1uEWDMWd8NR6wuPrnfWEKpKD",
            "symbol": "BACA",
            "name": "BACA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CRK1918/Crypto-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cp3WZxco28xXebCE8ufua8L8djYxadChc7eZvEkmuajj",
            "symbol": "ONT",
            "name": "Ontiveros Coin (ONT Coin)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/seasideslug/crytpo/main/ONTCoinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "128U4NbjWptP3GTg1NdgDRdeGVZpiiqvTgdELffYngaR",
            "symbol": "FSD",
            "name": "FlokiShibaDoggy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brawal1/cryptoToken/main/logo.PNG",
            "tags": [
                "social-token",
                "community-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "AFK6iA9QWGBc1tgFW2YVn4oztAbwwWLyLW9UoTUcvb2F",
            "symbol": "KYC",
            "name": "KeunYang Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dattjc/Crypto/main/crop_image.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8hiGiRQs5ZzjN7XyPyLuxt4dC6hQFiPLNGBCQpaK8Kt7",
            "symbol": "BRUS",
            "name": "Brus Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bruselsker/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "838ByxAj4QFwA6mhs7gUzYw8tKjZ5NJSw3XQudAuGZZC",
            "symbol": "CGOLDR",
            "name": "CryptoGoldRush",
            "decimals": 6,
            "logoURI": "https://cryptogoldrush.xyz/cgoldr.png",
            "tags": [
                "social-token",
                "community-token",
                "lp-token",
                "media",
                "utility-token",
                "reward-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WavemStudios",
                "website": "https://cryptogoldrush.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "GFdixhJJFRkChQtCMWpHPVwN6tFou6EVNpJoTpBmPVKW",
            "symbol": "ARCH",
            "name": "Archer Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFdixhJJFRkChQtCMWpHPVwN6tFou6EVNpJoTpBmPVKW/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BTqAkWiCkkLwyp1ALWj34LcTTD4GcrcA3oTXYsYqLCNo",
            "symbol": "RMNT",
            "name": "Araminta Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DenizenK/crypto/main/Araminta-coin-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3NVoDmpdXK3nv1ySixhA1RdNjnVDepGMeRqbyFUY62wu",
            "symbol": "LNK",
            "name": "LinkCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/coreyman11/cryptologo/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "94jMUy411XNUw1CnkFr2514fq6KRc49W3kAmrjJiuZLx",
            "symbol": "SYXT",
            "name": "SolanyxToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94jMUy411XNUw1CnkFr2514fq6KRc49W3kAmrjJiuZLx/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "solanyx",
                "coinmarketcap": "https://coinmarketcap.com/currencies/solanyx/",
                "discord": "https://discord.gg/solanyx",
                "telegram": "https://t.me/solanyxofficial",
                "twitter": "https://twitter.com/solanyxnft",
                "website": "https://solanyx.com"
            }
        },
        {
            "chainId": 101,
            "address": "GvJqUai8yCMyxSSzs7mLmQLGueAfjcNszEnD2FmzYwez",
            "symbol": "ALGK",
            "name": "AlgoKinetics",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/eaparcedo/crypto/main/AlgoKinetics.png",
            "tags": [
                "utility-token",
                "community-token",
                "trading-token",
                "algo-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DGEU64ztwjPGznzGYRBPx4Fawrnq9kNtmCzzxxfaLdj2",
            "symbol": "SAM",
            "name": "Sam Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HyperCactus/samcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9nhtpC2ZyYWUxzLdgG37Bf1nrbf7tHwHvhfkgW4Bsh83",
            "symbol": "KBRC",
            "name": "KraeberKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KittyFunLand/KraeberCoin/main/Cat2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5UySFQujqcHQPKkHHfUwd61PnMyh9fT8DMbb8jPWacDY",
            "symbol": "ENEAS",
            "name": "EneasToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NotEneas/eneastoken/main/Eneas.png",
            "tags": [
                "cool-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3edUPhBRqprSVdEPheVsFYb34eYPq4xZrYjN6v9fqUrd",
            "symbol": "ELONINU",
            "name": "ElonInu",
            "decimals": 6,
            "logoURI": "https://i.ibb.co/xDpJjDs/eloninu.jpg",
            "tags": [
                "meme-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MKRhSdSb",
                "twitter": "https://twitter.com/eloninu",
                "website": "https://eloninu.org"
            }
        },
        {
            "chainId": 101,
            "address": "3GfPg6Et57DQrVRqebf4zjzGm1RZU9aLDGJMjuXMQjJW",
            "symbol": "MHGH",
            "name": "Rocket Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mustuh/token-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cbeis3cq8bwEseWRpHtx7K1GRjNUNWC27CVYffJyEArb",
            "symbol": "DWELL",
            "name": "DwellFi",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/dwellfi/docs/de8b0de338158f1bb075e1e2e37b44cc64017cd2/static/img/logo.svg",
            "tags": [
                "utility-token",
                "community-token",
                "real-estate",
                "DAO",
                "governance",
                "DeFi",
                "NFT",
                "metaverse"
            ],
            "extensions": {
                "description": "DWELL is a community governance token for DwellFi",
                "github": "https://github.com/dwellfi",
                "website": "https://dwell.fi"
            }
        },
        {
            "chainId": 101,
            "address": "7eMoDu4Z7VJPcndDGDgJ1b3H3bXLh5P2bd3AKx75NqkF",
            "symbol": "CITZ",
            "name": "CitizenCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ggteddy/crypto/main/citizenCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "332kAJq6n9gB4aCJTyJgg8sC32oKQ8S1d7kWcgbtP2Qy",
            "symbol": "MKYN",
            "name": "Maikycoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/M1gl0/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5hhjsjajtrRs5WayV6UcghQfiqZ13tjD38MnVDXghYti",
            "symbol": "SWEE",
            "name": "SweetTalk",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomasjsweet/SweetTalkCoin/main/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://thomasjsweet.com"
            }
        },
        {
            "chainId": 101,
            "address": "FiiCEB1xcWrZBFP1qCpakENvntcwy8SwU9cf4bp5fFjw",
            "symbol": "PJC",
            "name": "PJcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BenjaminK1981/crypto/main/Untitled-1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "jAi1KmJZrrDVSroQXXAqASpES4qLvZcW5p9s5a5ksG4",
            "symbol": "LNFTxDAPE9989",
            "name": "LNFT Degen Ape-9989",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/jAi1KmJZrrDVSroQXXAqASpES4qLvZcW5p9s5a5ksG4/logo.png",
            "tags": [
                "liqnft-owner-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/DAPE9989"
            }
        },
        {
            "chainId": 101,
            "address": "FYnX6G8kugZ9T7Kc67jNEskH5sHbxyd8mvUWQLiZupa6",
            "symbol": "EDJE",
            "name": "EDJE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYnX6G8kugZ9T7Kc67jNEskH5sHbxyd8mvUWQLiZupa6/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9PRC5AtsabfYYKQxjja3sdbsm6E1ALQUbou6nJRq9xxn",
            "symbol": "VIE",
            "name": "Virtual Internet Experience",
            "decimals": 4,
            "logoURI": "https://pbs.twimg.com/profile_images/1476383121734090755/elIl3dbA_400x400.jpg",
            "tags": [
                "metaverse-token"
            ],
            "extensions": {
                "telegram": "https://t.me/vieofficial",
                "twitter": "https://twitter.com/vie_metaverse",
                "website": "https://vie.digital"
            }
        },
        {
            "chainId": 101,
            "address": "C9Yy52f4tDaFHUFTCMPXAPq9w1kiZxhWHYTBWPdk2dDe",
            "symbol": "LATT",
            "name": "Latte Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xH3ADSH0Tx/crypto/main/logo.png?token=APG2SJHSRZSBSTIWUDYOXH3BZUNFS",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7KGoH1B2h9xdBk9dE5GnA2tRC3L8shgmUBGUmf3B2Ts4",
            "symbol": "TERO",
            "name": "Pterodactyl Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jprobasco/cryoto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CWGMg4WMTqJGLeNw6RH9qhzF8vysJgZeWqZpueeDqw3W",
            "symbol": "EXTRA",
            "name": "Extraterrestrial",
            "decimals": 3,
            "logoURI": "https://cdn.jsdelivr.net/gh/matheusz2/Extraterrestrial@main/logo.png",
            "tags": [
                "trading-token",
                "utility-token",
                "social-token",
                "community-token",
                "reward-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NewExtraterres",
                "website": "http://newextraterrestrial.com"
            }
        },
        {
            "chainId": 101,
            "address": "7FCy7ESVPSFuio3qYjWKCQU4btYaApXhNGMkcvjxqsxa",
            "symbol": "WISP",
            "name": "WISP token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MashmallowWoR/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cu2iuHD3vaiStixgEECDHvA2FaETtJzzt4jK3YmyNpBj",
            "symbol": "TOPY",
            "name": "TOPY Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cu2iuHD3vaiStixgEECDHvA2FaETtJzzt4jK3YmyNpBj/topycoinlogo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://topycoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CFEQJyNnPeGURdi46mW6zouQjFVAw72kB7KLoQq7JLdJ",
            "symbol": "A51C",
            "name": "Area 51",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AvenashKumar/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8EdHUfHdWytAcg8iFauRAckqZKRw4nbPf7Qkg4daQFCU",
            "symbol": "Zer",
            "name": "Zefir",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EdHUfHdWytAcg8iFauRAckqZKRw4nbPf7Qkg4daQFCU/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "telegram": "https://t.me/Hadiane_one_life"
            }
        },
        {
            "chainId": 101,
            "address": "4ws4ZmDmNQwG1boaDmKtmPzEHJ5UUEaeBKtHzeFDwYyo",
            "symbol": "JPEDU",
            "name": "JPEDU Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ws4ZmDmNQwG1boaDmKtmPzEHJ5UUEaeBKtHzeFDwYyo/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/snft2022",
                "website": "https://solana-nft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5cwbtUmBF4njEtq9xs7AGEi9Yxj9RZfuSWSvm58iqG8A",
            "symbol": "AATK",
            "name": "AIAL",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SilverRuler/Solana_Practice/main/aial_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ESrrTHaNKiC9saxaudX2j9iCM9r8qHRcLSrW5ypQ3W64",
            "symbol": "DALI",
            "name": "Fraktionalized Blockasset Legend Ali",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/2Y3f2SeoT7HKHTQ_nUPfsLPyc59mPvNYSAsjKZuWNOk?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/3oVLfsh7NdRKMEuX51whGgF594Pc4Tg2ZcWnMoAwZJum",
                "vaultPubkey": "3oVLfsh7NdRKMEuX51whGgF594Pc4Tg2ZcWnMoAwZJum"
            }
        },
        {
            "chainId": 101,
            "address": "zjB53Pcgj7wrNjXfXUxNmx23pSPZm5j1sR2kj5xVNEQ",
            "symbol": "MAC",
            "name": "Metaverse Athletic Crypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoshTchan/DMA-Logo/main/Screen%20Shot%202021-12-29%20at%2010.00.50%20PM.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EqPewmc54Ko5HZHkJwcVFHiCf81R4bMC518gN2WL844T",
            "symbol": "LEMN",
            "name": "Lemona",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EqPewmc54Ko5HZHkJwcVFHiCf81R4bMC518gN2WL844T/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/lemonacrypto"
            }
        },
        {
            "chainId": 101,
            "address": "9WpxYps83kGVcR4tpWqbVbAA9dWEZAsv9fDAnGc7n9za",
            "symbol": "MOLLY",
            "name": "Molly Klint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MollyKlinteback/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9YR9i44W24stoYUTTRAQVbreLTqUoaGyi3EY1StV9oqw",
            "symbol": "BM",
            "name": "BitMeme",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haidang128/bitmeme/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DTXYsSaMrkgbnmN3no1XPL5pty57Zobyqyh6PZpKzjid",
            "symbol": "zsl",
            "name": "ZSL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTXYsSaMrkgbnmN3no1XPL5pty57Zobyqyh6PZpKzjid/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EfbYD8f23BSbMgT3AvZpFeuzwsHvpt14MP8U4xTNsRZn",
            "symbol": "FRLP",
            "name": "FundRaiseLaunchpad",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EfbYD8f23BSbMgT3AvZpFeuzwsHvpt14MP8U4xTNsRZn/logo.png",
            "tags": [
                "launchpad-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/C3jrUAzm",
                "facebook": "https://www.facebook.com/Fundraise-Launchpad-106074095265419",
                "instagram": "https://www.instagram.com/fundraise_launchpad/",
                "reddit": "https://www.reddit.com/r/fundraise_launchpad/",
                "telegram": "https://t.me/fundraiselaunchpad",
                "twitter": "https://twitter.com/fundraiseLP",
                "website": "https://fundraiselaunchpad.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3mRzsmdRhaaGMUJX8fJSdanx8xJ2Q6RjEHd4aHi1b92X",
            "symbol": "SANT",
            "name": "SANTOSH Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3mRzsmdRhaaGMUJX8fJSdanx8xJ2Q6RjEHd4aHi1b92X/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6VBMAp9p7Nq1qA2FSMoCgchFvzBeiCEB5vHetTM5FAuB",
            "symbol": "ARAL",
            "name": "Aral Sea",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kalimbay/aral/main/aral.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6dSCqdomPGZCG3Vufoo1LLDGNZfa1v4ocjUKR1Tr1n66",
            "symbol": "BLCK",
            "name": "BLOCK SIGN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/testsystemca/blcksgn/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C5uQADv4uVw34PtJ4rxYZauCoMFMdEk8jqQCewqJjYjg",
            "symbol": "WAYK",
            "name": "WaifuKoKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitbitti/cripto2/main/logoc.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7t7nsgDAMRWj7TKYNB3YE4aUTdTYCstJfMopa3v647bx",
            "symbol": "BRSN",
            "name": "Bronson",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ExplosivePig98/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4XcHyw4vyPYvRQDKkMLEwXz7xH3tVMWzb6VBt6dEQJu4",
            "symbol": "KATAK",
            "name": "Kataklop Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4XcHyw4vyPYvRQDKkMLEwXz7xH3tVMWzb6VBt6dEQJu4/logoV4.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "Cryptomony specializing in the equestrian world",
                "facebook": "https://www.facebook.com/kataklopnetwork",
                "website": "https://crypto.kataklop.com"
            }
        },
        {
            "chainId": 101,
            "address": "8ceGXJzhzfMqfbczepoGZAgwu74qomwRn6rJzj9dvEMB",
            "symbol": "clz",
            "name": "clonzer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thegamer4003/crypto/main/pro.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EtEUg6MKhaKoxjiXgKpmP6cRywEJA9KuatofAZxN6Dog",
            "symbol": "KAIC",
            "name": "KaiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/razmipatel/kaicoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BH3BxwXgk1PGwM7wKXNaxVEFTATjHaoJqRx4QcETquK3",
            "symbol": "OLY",
            "name": "Solympic",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BH3BxwXgk1PGwM7wKXNaxVEFTATjHaoJqRx4QcETquK3/logo.png",
            "tags": [
                "sports-token",
                "social-token",
                "community-token",
                "oly"
            ],
            "extensions": {
                "description": "OLY is a virtual sports gaming cryptocurrency",
                "website": "https://github.com/denizTutuncu/SOLympic"
            }
        },
        {
            "chainId": 101,
            "address": "GCLRERxnQ4ZsMiyVbVSarP2zgEQvXet8y2nJccE7KL1F",
            "symbol": "CYST",
            "name": "Cybershady token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cybershady/cypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4pbWFHwDaci7RhJPNjtravxVUNdUiuFUwas2bzB62hBf",
            "symbol": "ZSOL",
            "name": "ZeNNSol",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitbitti/cripto2/main/logoc.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "APDLahB6PxznoWyHpnxj2P99Xnk4weHeYgCtjSxNGvoa",
            "symbol": "APDL",
            "name": "API Permanent Deposit Liquidity Pool",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APDLahB6PxznoWyHpnxj2P99Xnk4weHeYgCtjSxNGvoa/logo.png"
        },
        {
            "chainId": 101,
            "address": "EtFE9AA8PxAf3oLgW3HjgQDELo35PMLj3nqQ4367LewG",
            "symbol": "OZZY",
            "name": "Aussie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jim-fun/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "84X5KvsCN93XvP8AbRuPPHQiWH18Ktzwk5Eosns2DFha",
            "symbol": "LEEG",
            "name": "League Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NFTzTrader/crypto/main/NFT_0.2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8iU3TznihUmct2Ts1B6avJYSax4MrwHk7RxeKsVz5Q6y",
            "symbol": "CPTU",
            "name": "CryptoUisco",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alolore/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "73bdxWnRjmkwjKefy4wNxrgnxeCiKijx5F2an28rtogn",
            "symbol": "FAME",
            "name": "Famecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/73bdxWnRjmkwjKefy4wNxrgnxeCiKijx5F2an28rtogn/FAME.png"
        },
        {
            "chainId": 101,
            "address": "F92BeagxUnBa5ZRxFgQopHpVEd3rczMFxjuijWDWaDeL",
            "symbol": "marrs",
            "name": "MARRS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MARRS-1/m/main/sjrjhzfy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Lah9gQ6McD5bUaCAUZKhQjFHsVmCmTBCAgt1Gk99Ad7",
            "symbol": "PAP",
            "name": "WhiteList Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Lah9gQ6McD5bUaCAUZKhQjFHsVmCmTBCAgt1Gk99Ad7/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HrpVMYXim8endssf6jYXMnDqKAhpd2hWmi9nfWXjeEio",
            "symbol": "CYBC",
            "name": "Cybster Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cybster/crypto-cybc/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "altcoin"
            ]
        },
        {
            "chainId": 101,
            "address": "9tKEVUPrrWpa5vTy5jCBidQ54vC2y2715KyErNAn2E2S",
            "symbol": "JKPT",
            "name": "JUNKIEPACK Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/quintinmintiens/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dutt8PNkPfUpD3v54a78pfd56sYvR3a7UtTGzuNF77dp",
            "symbol": "DEV0",
            "name": "DevZero",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rmsdev0/coin-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MiHvRb55ySgxCh3i4ZDqp3xQeJ4wXgXFJXZqveCfjPU",
            "symbol": "FZC",
            "name": "FreezyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/arminjaenisch/freezycoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31F4zwMSLnBt8WBke7Dr7vGq1Miq2VmexmTUKVcrcMuV",
            "symbol": "CARIBB",
            "name": "CaribbCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/31F4zwMSLnBt8WBke7Dr7vGq1Miq2VmexmTUKVcrcMuV/logo.png",
            "tags": [
                "community-token",
                "caribbcoin",
                "utility-token"
            ],
            "extensions": {
                "website": "https://caribbcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "7azxPX5srz9mpUW6iK8N6pkLu4JfSVZv8LhnNs8QMETG",
            "symbol": "ManJ",
            "name": "ManualJ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jon457/ManJ/main/js.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3qwwM6hrhNQJcSSfaa97ib4jmX7sQFejQbBbTBgZVC1s",
            "symbol": "FINALB",
            "name": "FinalCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Finalb-Studios/FinalCoinLogo/main/CoinThumb.png",
            "tags": [
                "finalcoin"
            ],
            "extensions": {
                "website": "https://finalb.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9ajpwBFdUkfjaSouR3wjcPGFJwhDJ9fNHTQWcTMAyZeb",
            "symbol": "ZACC",
            "name": "ZAC-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jackborst/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GWqiHaYj2W6mtVEykaC3pceMGAN7pzFuVAErvGuFQpyY",
            "symbol": "SOWT",
            "name": "SowingToken",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWqiHaYj2W6mtVEykaC3pceMGAN7pzFuVAErvGuFQpyY/logo.png"
        },
        {
            "chainId": 101,
            "address": "Gv7LxA4em9CcVeLGuQgHASco2X32X7RvTP5o7W6nckNX",
            "symbol": "JHC",
            "name": "Johanna Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jhcoin2022/jhc/main/JH-Coin-v1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HKfs24UEDQpHS5hUyKYkHd9q7GY5UQ679q2bokeL2whu",
            "symbol": "TINY",
            "name": "TinyBits",
            "decimals": 6,
            "logoURI": "https://tinycolony.io/tinycolonytoken.png",
            "tags": [
                "NFT",
                "social-token",
                "utility-token",
                "meta-verse",
                "Game-Token",
                "Game-Fi"
            ],
            "extensions": {
                "coingeckoId": "tiny-colony",
                "description": "An expansive pixelated ecosystem on the Solana Blockchain.",
                "discord": "https://discord.gg/AT9caAbY",
                "github": "https://github.com/TinyColonyGame",
                "instagram": "https://www.instagram.com/tinycolonygame",
                "medium": "https://medium.com/@TinyColonyGame",
                "twitter": "https://twitter.com/TinyColonyGame",
                "website": "http://tinycolony.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4ZMwr188nAyRiJYsHLL5TBswXTU7aAmVio6SMP1mcdsJ",
            "symbol": "CRH",
            "name": "crypto revolution HEROES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ZMwr188nAyRiJYsHLL5TBswXTU7aAmVio6SMP1mcdsJ/logo.png",
            "tags": [
                "UTILITY",
                "NFT",
                "DEFI"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cryptocomicsnft"
            }
        },
        {
            "chainId": 101,
            "address": "2opQDJSzjpjeyHbvGt2rTCWqper36tTnJ3Evj6cRhFAx",
            "symbol": "SCI",
            "name": "Social Chaining Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robertseghedi/robertseghedi/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3bPDfM2QBf1a1is1kvjgzDGdwbVLczoa6dfuvGy7NXWT",
            "symbol": "DNSM",
            "name": "DNSmate Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cybster/crypto-dnsm/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "altcoin"
            ],
            "extensions": {
                "description": "DNSmate token",
                "website": "https://coin.dnsmate.net"
            }
        },
        {
            "chainId": 101,
            "address": "2RGej1BYyT1MZEwKdjV2FW5fNTAxp43Bpog4jk1zD97r",
            "symbol": "BNC",
            "name": "Bunsble",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bunsble/BunsbleCoin/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2MStv16MMiSTGu917stHLsC1ZTUW83tmrfVjUxfx2Ev1",
            "symbol": "NCLR",
            "name": "NCLR",
            "decimals": 9,
            "logoURI": "https://arweave.net/jiZBnPHQCAhdQc-9_9j3OXsw8rWtgIL2ryvR_5fz4p4?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/Metazillas",
                "website": "http://metazillas.io/"
            }
        },
        {
            "chainId": 103,
            "address": "EhSrrLdyVnWQ18LXvxAsVePmtLrADrss1UcZ1W98BNjJ",
            "symbol": "CHT",
            "name": "CRYPTOHYVE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EhSrrLdyVnWQ18LXvxAsVePmtLrADrss1UcZ1W98BNjJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "Dbaxp7pi3e5upbD7AnucAxqZWYiRHtPRsXH4uSDB4qj6",
            "symbol": "WASH",
            "name": "Carwash Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/phuxtick/wash-token/main/logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2hN7tqk7wF9z3J7w2k8Jee9hcaB6gT9Tz9HwhuV6B3vm",
            "symbol": "VVS",
            "name": "VVS Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2hN7tqk7wF9z3J7w2k8Jee9hcaB6gT9Tz9HwhuV6B3vm/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "altcoin"
            ],
            "extensions": {
                "description": "VVS is an invite- \u0026 mobile-only, engage-to-earn social media platform where content creators can drop exclusive experiences, merchandise, and digital collectibles in exchange for cryptocurrency.",
                "twitter": "https://twitter.com/earnVVS",
                "website": "https://www.vvs.io"
            }
        },
        {
            "chainId": 101,
            "address": "7odYbFhknk1FqHJnQFsFMwjzuM8zq3an6F4jRyoC39ik",
            "symbol": "DGS",
            "name": "DgS Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DgS4dd0n/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "392JVCPEw6YVebX1FArUgjV7uo6e3VYPqrH1R9cZxFse",
            "symbol": "WACCOE",
            "name": "WACCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iamtheitman/crypto/main/waccoin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3VVJiTq4B6EjAMQ4R6wot3MEVPEbRL7Fne9qHPTWfcDQ",
            "symbol": "EGGC",
            "name": "EGG Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ThePharaoOne/eggcoin/main/EggCoin.png",
            "tags": [
                "social-token",
                "game-currency",
                "community-token",
                "metaverse-currency"
            ]
        },
        {
            "chainId": 101,
            "address": "7fTbHcbPzTQqeRaazaFHqFB5ZevTckwB2jdq2SFRUfds",
            "symbol": "CAV",
            "name": "Cavcoin",
            "decimals": 0,
            "logoURI": "https://github.com/cavman76/cavcoin/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9sYn1ARn9wVPPsEy6fJHdjML8rE6Wm9QFixjRDYGwBMC",
            "symbol": "WaLe",
            "name": "Wallito",
            "decimals": 0,
            "logoURI": "https://github.com/memesparky/Wallito-Coin/blob/6ce49a410e014ac08b0dff1f9b2d2b5e6b43e9df/Wallito.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FJLWrhGxqz5oMgLyKUa3941R5bA1sgRWXcTxN3wt6QFu",
            "symbol": "RGL",
            "name": "RageLands",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RageLands/logo/main/logo.png",
            "tags": [
                "social-token",
                "ragelands"
            ]
        },
        {
            "chainId": 101,
            "address": "3dFdDhV9cu5SasNs2r1QY4tWSCGvgxEHomGgEW18QN6e",
            "symbol": "CRUMB",
            "name": "Crumbs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/phillipq/crypto-crumb/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ACNYcasCaM3NEch6gHFNYhhKtbKM5mPLRG8EuozLHi4j",
            "symbol": "HOPIUM",
            "name": "Hopium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HopiumCurrency/crypo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bb6qLeSMXW1p5WJmk29CNzPzsjkFz5UZ2GchcFtTdyEP",
            "symbol": "SVEN",
            "name": "Svenson Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SvenBu99/Crypto/main/Shield.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7zDSSPrtYtyAFtdSgbJ6jcH92BwMDZPBxcB1jPukwwe9",
            "symbol": "GRF",
            "name": "Gerald R Ford Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jon1217/Gerald-R-Ford/main/35dz33.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2FEfn3HNqKAAEWpMW46FgTSX1SbZxtLEeCJwzXSEjonS",
            "symbol": "RACC",
            "name": "Raccoon Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hasucha/crypto/main/raccoin.png",
            "tags": [
                "raccoons",
                "raccoon-platoon",
                "raccoin"
            ]
        },
        {
            "chainId": 101,
            "address": "EeB4EEHRuXtdZ8NvYNvSW18He9XvtnhnTF4TcBe92SCf",
            "symbol": "JfnP",
            "name": "JfinP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JfinP/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6ciiLZXXtCdEzxKPEpqDxeXM5ewKWi7yyJZ6MiMfK5Jt",
            "symbol": "SLMC",
            "name": "ShesLiam Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spiesserfreakx3/sm-tokenimage/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4rEYp4rKAHZwiKmaTi8dUNgSZnNtkMAMEjqRFvCeuaT7",
            "symbol": "VELORUM",
            "name": "Singularity DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4rEYp4rKAHZwiKmaTi8dUNgSZnNtkMAMEjqRFvCeuaT7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.singularityonline.app",
                "description": "Singularity Online DAO Token",
                "twitter": "https://twitter.com/SingularityMeta",
                "website": "https://singularityonline.app",
                "whitepaper": "https://whitepaper.singularityonline.app"
            }
        },
        {
            "chainId": 103,
            "address": "3485gPZHaNvhXC15HqfSUojefN2xPfg5mJCrnY6BNU6T",
            "symbol": "VELORUM",
            "name": "Singularity DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3485gPZHaNvhXC15HqfSUojefN2xPfg5mJCrnY6BNU6T/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.singularityonline.app",
                "description": "Singularity Online DAO Token",
                "twitter": "https://twitter.com/SingularityMeta",
                "website": "https://singularityonline.app",
                "whitepaper": "https://whitepaper.singularityonline.app"
            }
        },
        {
            "chainId": 101,
            "address": "5TQDDZhEBPCdGGX23mFBajwsBhxMdBkpatuhd5GgjybW",
            "symbol": "CGPT",
            "name": "the Cute Guineapig Token",
            "decimals": 0,
            "logoURI": "https://github.com/dgloverwork/cuteguineapig/raw/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BCaiBkEkDGoPddGsYH65iSLTZW7tr58QsmQ3Vfs6oCWv",
            "symbol": "HC",
            "name": "Hosting Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCaiBkEkDGoPddGsYH65iSLTZW7tr58QsmQ3Vfs6oCWv/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2YRi3W8JQLd55NHWepxxxaRP8vifG9T7iRLfSEEhW3jU",
            "symbol": "NFA",
            "name": "Niftarna",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/davidgenix/token/main/Niftarna%20Token.png",
            "tags": [
                "utility-token",
                "meme-token",
                "community-token",
                "Niftarna"
            ]
        },
        {
            "chainId": 101,
            "address": "Eoz6d1P9npgKRDSBLaf21L5zFJ7ccYAixkiN6NoBpdEM",
            "symbol": "TPG",
            "name": "TPG Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomasgregg/soltoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3cSPs9Zc5STxiDiD9VKfzikLZAaKYHvtnN5Ay32f6nri",
            "symbol": "WUBI",
            "name": "Wubicoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/porcul/Wubicoins/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EzdViPLRRqrBPQ8zshGeDE3BJCCB7rJu8H1169Byby7U",
            "symbol": "BIDA",
            "name": "Bid Auction",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bidauctiontoken/Bidauctiontoken-solana/main/logo.png",
            "tags": [
                "social-token",
                "metaverse",
                "gaming-token",
                "gamble-token",
                "auction",
                "multi-chain"
            ]
        },
        {
            "chainId": 101,
            "address": "B2wZun5H5eaf93UEgX9zxLZsGrejjqxhg2PdFAspyN2v",
            "symbol": "BODHI",
            "name": "Bodhi reward coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BjMrq/crypto-msl/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ghWaAQ2WkYh16ZWfYEEWwFn9a5KNzzJpPJsDqEqRbXX",
            "symbol": "TEDC",
            "name": "Ted Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TedEwanchyna/crypto/main/ted.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3WvkimSdxWxs4VLjsfP6o24KzP6dz4DDu7GemA9hhTis",
            "symbol": "MOSQ",
            "name": "SOLMOSQUITO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/9inelife/solmoz/main/log1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GV3MjuGin8aTG6Rhc1vR1QFYp8gq5vW39jnNPLrbzmPi",
            "symbol": "creatorpro",
            "name": "creatorPRO",
            "decimals": 6,
            "logoURI": "https://creatorpro.live/simbolo-creator.png",
            "tags": [
                "creator",
                "creators",
                "creator-token",
                "artist"
            ]
        },
        {
            "chainId": 101,
            "address": "H2rMQMa6kPpWwg2GraKyiiPwnayDBoBNg9WNVuafqXKc",
            "symbol": "creator",
            "name": "creator",
            "decimals": 6,
            "logoURI": "https://creatorpro.live/simbolo-creator.jpg",
            "tags": [
                "creator",
                "creators",
                "creator-token",
                "artist"
            ]
        },
        {
            "chainId": 101,
            "address": "ArGak2wW1RSyXfcAnn2jdoE1nCAExRF5ex8EBpFK62KZ",
            "symbol": "SLAK",
            "name": "Slakcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/R0ADY/slak/main/slakcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FzJSvLESU8uSiKvbvRj6qbBisXAvRrNRcCQMEN3qJqmZ",
            "symbol": "XDOCHE",
            "name": "Xenodoche Coin",
            "decimals": 0,
            "logoURI": "https://github.com/Xenodoche/Crypto/blob/main/XenodochE.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fsa1N5n3yF3aguZz2cpfPyJ5W1QKmqii5EqSGQSzRAQZ",
            "symbol": "GOLF",
            "name": "GOLF Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mcobbilo/crypto/main/golfer.png?",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HQHVTrx6BVYupT2FfdoSFK5HmDzQLh2a21aKopnQYLaX",
            "symbol": "LWCC",
            "name": "LIVINGWATER",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/markkeelan/crypto/main/Lwfs%20icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D1iisc5oAARTFonV2mQ3p7SEV7NdCVkKRgG4v5eF2k8k",
            "symbol": "KWL",
            "name": "Kiwi Launcher (KWL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D1iisc5oAARTFonV2mQ3p7SEV7NdCVkKRgG4v5eF2k8k/logo.png"
        },
        {
            "chainId": 101,
            "address": "2GTmJVSDNyhgvRfw4wP6yJGY3EhTpHm27M8cBMa4noNm",
            "symbol": "SKWSBN",
            "name": "SKYWEST SBN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thatdishguy/SBN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31Zd6HM1cAjLxWYaUmmnNp35JSvGujgqmwyKZwjdDvHG",
            "symbol": "BOBAH",
            "name": "Bobah Balls",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bobah-balls/crypto/main/logo.png",
            "tags": [
                "social-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BobahBalls"
            }
        },
        {
            "chainId": 101,
            "address": "4VhkFbAy6zL6yvaJsnArNkM5AabHgDj3yCojMj76HiXD",
            "symbol": "CINVI",
            "name": "CRYPTOINVITER",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Cryptoinviter/CINVI/main/Cinvi.png",
            "tags": [
                "social-token",
                "metaverse",
                "gaming-token",
                "gamble-token",
                "auction"
            ]
        },
        {
            "chainId": 103,
            "address": "AQxde8jgvxgkTcovbae1CvaRpfVsovB26wTrdgyVkAKA",
            "symbol": "BEAN",
            "name": "Token of TimeStore",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AQxde8jgvxgkTcovbae1CvaRpfVsovB26wTrdgyVkAKA/logo.svg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://timebitlaw.com"
            }
        },
        {
            "chainId": 103,
            "address": "6Mdq1QmqbWLejtSsvHVXc5HtTkztVTHgdb2EJUtCMRMq",
            "symbol": "XMT",
            "name": "X-mas Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Mdq1QmqbWLejtSsvHVXc5HtTkztVTHgdb2EJUtCMRMq/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6kR8XurNwjJZXGrTEBA8WtJtps7TAUjdX1oJhu1tyBVK",
            "symbol": "AGLA",
            "name": "ANGOLA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Partner-Angola/token-list/main/assets/mainnet/6kR8XurNwjJZXGrTEBA8WtJtps7TAUjdX1oJhu1tyBVK/logo.png",
            "extensions": {
                "facebook": "https://www.facebook.com/profile.php?id=100076475235846",
                "github": "https://github.com/Partner-Angola",
                "instagram": "https://www.instagram.com/angola_partner",
                "telegram": "https://t.me/AngolaCS",
                "twitter": "https://twitter.com/ANGOLApartner"
            }
        },
        {
            "chainId": 101,
            "address": "J2SLK9VNtVFGkhmuXT3Gsi43KP2Ftbmawmj7RsTFoRx6",
            "symbol": "1usd1",
            "name": "1USD1 COIN",
            "decimals": 4,
            "logoURI": "https://github.com/1usdguy1/jpegfiles/blob/main/1usd1.png",
            "tags": [
                "social-token",
                "utility-token",
                "meme-token",
                "dex"
            ]
        },
        {
            "chainId": 101,
            "address": "6hYoQQjd54a1qkmhDRSprgrbMKkRKiEH5Z44xw5DZzso",
            "symbol": "BOOZ",
            "name": "BoozCoin",
            "decimals": 9,
            "logoURI": "https://avatars.githubusercontent.com/u/95369650",
            "extensions": {
                "telegram": "https://t.me/boozcoin",
                "twitter": "https://twitter.com/boozcoin",
                "website": "https://boozcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "14Av6AozFck2wiRuqiK7zynKTPcKWmijDK3wVEXW5b4k",
            "symbol": "BOOGLY",
            "name": "Boogly",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wubsnubz/boogly/main/boogly.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "47WYC41xdUjs1ZghfppBRFGmC3ztzecHhdy51QyBXgLz",
            "symbol": "SUNNY",
            "name": "Sunny Weintraub Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/b-weintraub/sunny/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CkqFXm96v68ULpce3fQVEtHNagM2Ur1sZgM4oLSDNdZU",
            "symbol": "WECOUNT",
            "name": "WeCount Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkqFXm96v68ULpce3fQVEtHNagM2Ur1sZgM4oLSDNdZU/logo.png",
            "tags": [
                "wecount",
                "community-coin",
                "community",
                "social-token"
            ],
            "extensions": {
                "website": "https://wecount.dev"
            }
        },
        {
            "chainId": 101,
            "address": "68N2LZsUxJRWfNLeo2SMChnAtYwBd83sNarxLMLWUESV",
            "symbol": "PTRC",
            "name": "PetrakisCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NickEleftheriadis/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HqJyrnCuzYu14sam378r6ZAHE2pjXA4qQCia6VhPXr7o",
            "symbol": "ALHT",
            "name": "All Height",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nrbDev/crypto/main/c28a0aae32a67ab9bb38c3adb49eeaba.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PSoLjQL5Cd4mwC2p2qeSyHsTtSrifCSmNCUYZK75RFS",
            "symbol": "PSOL",
            "name": "PUMP SOL",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PSoLjQL5Cd4mwC2p2qeSyHsTtSrifCSmNCUYZK75RFS/logo.png",
            "extensions": {
                "website": "http://pumpsoltoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "9GSM9uXLTj7aHdwBtipDfaFtGYPgVnca5Bu6hnT5GHJG",
            "symbol": "GNB",
            "name": "GNBux",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jyanosu/GNBux/main/GNBux.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3nEUJDbbCPEh7ePCwxgEXq8fXXqStjrCrZmJ1rUyCPSB",
            "symbol": "SKL",
            "name": "SkylrFinance",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/skylrfinance1/skylr/main/assets/mainnet/3nEUJDbbCPEh7ePCwxgEXq8fXXqStjrCrZmJ1rUyCPSB/logo.png",
            "tags": [
                "community"
            ],
            "extensions": {
                "discord": "https://discord.gg/AHqRRmNg",
                "twitter": "https://twitter.com/SkylrFinance"
            }
        },
        {
            "chainId": 101,
            "address": "7yia2o7CrQDdAAvGRBtXp6L6mGqToEXjjUFm4VfioVjF",
            "symbol": "TrX",
            "name": "Travon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Travon-0/crypto/main/ll.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7AAtpqK78qbc7vx6BVWQ1D4PEjoccDbU293oGh74ovzN",
            "symbol": "FLB",
            "name": "Floki Baby",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7AAtpqK78qbc7vx6BVWQ1D4PEjoccDbU293oGh74ovzN/logo.png",
            "tags": [
                "utility-token",
                "meme"
            ],
            "extensions": {
                "discord": "https://discord.io/flokibaby_sol",
                "telegram": "https://t.me/flokibaby_sol",
                "twitter": "https://twitter.com/flokibaby_sol",
                "website": "https://flokibaby.net"
            }
        },
        {
            "chainId": 101,
            "address": "E4ZzSQqz3eFScU1VjcuPwiTMALHdNe7AT4CQt6MLQkqb",
            "symbol": "PNGN",
            "name": "Pinguin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/p1otek/pinguin/main/pingwin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "23f5TH1tFkfX6jPVQNy4VQ66Fo32WUc8zrZT1c14LzBM",
            "symbol": "HKV",
            "name": "HuskyVerse Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/23f5TH1tFkfX6jPVQNy4VQ66Fo32WUc8zrZT1c14LzBM/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/TTnKwcdngt",
                "twitter": "https://twitter.com/husky_verse",
                "website": "https://huskyverse.network"
            }
        },
        {
            "chainId": 101,
            "address": "AZDNFxGvp7ueSjJMuGGg4pHfDz9MEaHbH87UYSDeozHh",
            "symbol": "MSPC",
            "name": "Moose Sports Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CoachCurvCrypto/CCC/main/1%20time.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EcPcdvrnMPH9VNqdkUYezMCVXKMaiexnop1ScRG9LiNg",
            "symbol": "DVTC",
            "name": "Deivitcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/davirami88/davitcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31W7XnpNSwB4ioeXK2LoDvZLCdCyBn1NysXcgJvZ4C5o",
            "symbol": "TDC",
            "name": "The Danish Coin",
            "decimals": 0,
            "logoURI": "https://github.com/Alexander123st/The-Danish-Coin/blob/main/denmark.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6BX9nw9JhAUVDnBrJHqkXTnUcqpGkLRCe6vvXVGgsjNa",
            "symbol": "MGN",
            "name": "MUGEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kyle123c/MyCryptoToken/main/mugen.png?token=AGNMUIK532R65TGX5WNLL33B3BHVW",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E3HM6Vdpdomb11y1ZsBDz4ndjkByuDSMH4PEXZtZrGXc",
            "symbol": "KRON",
            "name": "Kronblad Net",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CrissZollo/crypto-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E6nKpgVHevNCVkHn7gqNKGbrsCxjnxagMYME5CYdyUto",
            "symbol": "EPIK",
            "name": "Epik City Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/EpikCityGit/EC/main/EC.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2kuTqXS3pxs6k3wVusRrMeP6YEjjDBvwTpqrvajsjg3T",
            "symbol": "SCCE",
            "name": "Cooney Coin",
            "decimals": 0,
            "logoURI": "https://github.com/looneyr8/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CcM2KGSi9XSkbg1mR1szTee2BDazbnW5Qy4prw9zJHFn",
            "symbol": "NERON",
            "name": "Neron",
            "decimals": 9,
            "logoURI": "https://github.com/neronproject/token/raw/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft",
                "swiss",
                "stake"
            ],
            "extensions": {
                "description": "Swiss decentralized Token",
                "twitter": "https://twitter.com/NeronProject",
                "website": "https://neron-project.com"
            }
        },
        {
            "chainId": 101,
            "address": "4drMeMofFD9d319PozEFeqL6KUNuvsV6qtRCPWvpiuVh",
            "symbol": "AMAROY",
            "name": "AMAROY holding coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bayramenes/Tokens/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HcXZtuzj4SvJEtB767cM2ywyhbd5Wxbobrc8h5kspc6S",
            "symbol": "MEZO",
            "name": "Mezo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mezoforthepeople/mezo/main/Logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7srfbys2PS5g7Z4a7VHNV1UquazToN5nhYFgu9Kje3cT",
            "symbol": "LUC",
            "name": "LUX Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mazsinger/luxcoin/main/luxcoint2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2XDAUBAoDL44ziNUVLGqYq3mA4QRYo46kaamH7r5Z1gZ",
            "symbol": "HAIR",
            "name": "Carecas Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nbcompras/crypto/main/carecas.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FJ95xcVK4U1YGjjjDDXpEhHpDrtjtGHK9AusqDavxQdy",
            "symbol": "CHOP",
            "name": "Choptimu5 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Choptimu5/crypto/main/ChopTokenLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HcLkdCCyjzHanERvMJe1u41pXMPdsJzSetHESJQbNRRR",
            "symbol": "TANK",
            "name": "The Aslan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/azlan2355/crypto/main/final-lion.png",
            "tags": [
                "social-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CgwSqBSeT6HLYmw6EnUp8W7ftsjC4MCShbq1gTwiae53",
            "symbol": "BNMTC",
            "name": "Benomat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Benomat/CryptoCurrency/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GfM5rmMJ2fQ55qHtB4whHeunqKMn1ZSnbKiVnanLwMVS",
            "symbol": "ASGHAR",
            "name": "Asghar Coin",
            "decimals": 0,
            "logoURI": "https://avatars.githubusercontent.com/u/53191495?s=400\u0026u=3e937145da66f98a3651c3d59a0aac4f6f69a893\u0026v=4",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3oHUwMYdNzsSF7aysvCdKc12ym7baoUVGE1SFum9k4Xm",
            "symbol": "CARN",
            "name": "Carnifex Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cjstoddard/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CuK5pT9F3TmZS672gNACDUv2BaY1d44aLfeXMyGgZUoF",
            "symbol": "ISAAK",
            "name": "Isaak Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Firebros1/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DK7HA62vZU4MSm7Z3cnoUnRzBcE562FdkYKzMmAS8sK1",
            "symbol": "BFRN",
            "name": "BitFiorino",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BitFiorino/BitFiorino/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GJXAvenss7VvmwUi3D8qHjAsdaKDMdWbZp5bPKTqBN7M",
            "symbol": "RICE",
            "name": "Fun",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JCF0/RICE/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7FdPzM6dYAvwpwLCH5cNs1ZsqVacPmkiE2qbCsCs8ofw",
            "symbol": "DLYS",
            "name": "Asterius",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Daetalytica/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "security-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/watson11B",
                "twitter": "https://twitter.com/nachteis",
                "website": "https://daetalytica.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3phF2ZYQwH5qHDNtHQ5wxAspka77ic23enA2tF9qaS4e",
            "symbol": "FF",
            "name": "FORBE FINANCE",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3phF2ZYQwH5qHDNtHQ5wxAspka77ic23enA2tF9qaS4e/logo.png",
            "tags": [
                "ethereum",
                "solana",
                "wormhole"
            ],
            "extensions": {
                "address": "3phF2ZYQwH5qHDNtHQ5wxAspka77ic23enA2tF9qaS4e",
                "discord": "https://discord.gg/U2N9b42C",
                "telegram": "https://t.me/ForbeFinance"
            }
        },
        {
            "chainId": 101,
            "address": "6Tf1yzhpZ731gRPJBpBk6KsWdEzx4sDXFn6LVSxwgzMW",
            "symbol": "FC",
            "name": "FamilyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IMAXMAXMAX/crypto/main/FamilyCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7xVANL1h6PnuV7zBCsL9CwKCBwUPjpLp75NtC3i1atmg",
            "symbol": "MEMET",
            "name": "Meme Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MemeTokenMan/TokenToei/main/pepe.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6eUmZ5NM1C1rgMErByGKhQa2pa8YeuYKEhL2wYNDBu5m",
            "symbol": "CHZBLK",
            "name": "CheeseBlock",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maxcheese3/Cheese-Block/main/CheeseBlock256.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Own your own shreds of the CheeseBlock today!",
                "discord": "https://discord.gg/N6wtXcmRnf"
            }
        },
        {
            "chainId": 101,
            "address": "6f179XqfQvxn112UHDCTL2VQy5tLb3bCp88XJFMPVJXU",
            "symbol": "SLYM",
            "name": "Slimey Bois",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6f179XqfQvxn112UHDCTL2VQy5tLb3bCp88XJFMPVJXU/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HbnwCozh2DKLzaNB1fEf1B4DyZm97g2iSzbjm8vYkJBp",
            "symbol": "NYES",
            "name": "James Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nyesjames/jamescoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "68jbAkL4V7e2zXKHxxSrMYiHbGakbmSCbKjGN7MApHJV",
            "symbol": "ZONIX",
            "name": "Zonix",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/68jbAkL4V7e2zXKHxxSrMYiHbGakbmSCbKjGN7MApHJV/logo.png"
        },
        {
            "chainId": 101,
            "address": "3kJ7goYo5Ezx9Vc2okRyVG8UF9RuAVLXHLKdpjpHuN4b",
            "symbol": "CSINI",
            "name": "Csini Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MoTheCo/Csini-coin/main/csini.png",
            "tags": [
                "social-token",
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "nooot44pqeM88dcU8XpbexrmHjK7PapV2qEVnQ9LJ14",
            "symbol": "PESKY",
            "name": "Pesky Coin",
            "decimals": 9,
            "logoURI": "https://arweave.net/58GmA531NCbAhLPsW5sOLSyzuS1lrveuzqr6nbHLzRc",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/peskypenguins",
                "website": "https://pesky-penguins.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9Ka5LepzQPK6BnhgCEEKPasBZgXdMUrn6NvHdw2B2fUu",
            "symbol": "KRK",
            "name": "Karkantas Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pkyria14/karkanto-token/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8y3LEAzvkcrdRegg93CxnxAHfk7UXB6hPs12EAxQfwJL",
            "symbol": "IRLV",
            "name": "Irrelevant",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Miliexy/Crypto/main/Logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "Just an Irrelevant token Project"
            }
        },
        {
            "chainId": 101,
            "address": "YiUu8KST9spXGeKVacjoxfS6gtbu8nKj9nbM2VGqC52",
            "symbol": "ALTK",
            "name": "The Al Tino Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/albertamoyaw/crypto/main/mycoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J9H1z1oY1zJKNDBf44MmtvwYW2HvU1GEESAGMvyC7izj",
            "symbol": "BRG",
            "name": "Burger",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/burgertoken/crypto/main/logo.png",
            "tags": [
                "NFTs",
                "utility-token",
                "currency-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SGTdtpAiPU1Fg9a3DqUN1852V24sfo92ePEHpyqkrSN",
            "symbol": "SGT",
            "name": "Solana Ghoest Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SGTdtpAiPU1Fg9a3DqUN1852V24sfo92ePEHpyqkrSN/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solanaghoests"
            }
        },
        {
            "chainId": 101,
            "address": "5pnwhysNPrzf7NjJzrhrWLMHo2d8zYSFLMKrVdYuAjAd",
            "symbol": "LSS",
            "name": "LaquiosSs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LaquiosDev/token/main/logo.jpg",
            "tags": [
                "utility-token",
                "social-token",
                "nft",
                "great"
            ],
            "extensions": {
                "description": "Swiss decentralized Token",
                "twitter": "https://twitter.com/Laquios1",
                "website": "https://www.laquiosss.ga"
            }
        },
        {
            "chainId": 101,
            "address": "2rghJ2ypY9CMFf8EMYF9GcgcwDPqV4tTcMQkEREWoRvQ",
            "symbol": "COL",
            "name": "Colonia Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Palm1che/assets/main/colonia%20token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "45BZJKCw4LKYRKJUZBwzsjiBEYWQ3qjsKpbYA66Q9LXP",
            "symbol": "VVTCK",
            "name": "VV Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DesSheehy/crypto/main/VvImage.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C9PDb9PFE33cTgfmipNPTpT8ee97nMYxHASwxhmXUqYo",
            "symbol": "FLEA",
            "name": "FleaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Isaac-T-Hill/crypto/main/FleaCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FQpcRZJb4tnBMcauWw6y4fUudJCYp4QTGUVSSxpmfTLw",
            "symbol": "TnTx",
            "name": "tedNET TnTx",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jrichard2022/tntx-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Wx1um8egtWAF8bQyJn28vcyY35e8j9j4d7GzJ6oww3R",
            "symbol": "Retr0D",
            "name": "Retr0 Dough",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Way2Retr0/crypto/main/Test1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H2Dcj1d2fwQmovK9f9n9E5rmjyi9dwz6HLX1eSFMDMGN",
            "symbol": "VTV",
            "name": "VITE-COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Vitelavita/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "23bzG86BQhzWZ2eYBZs7yGqL1bTYRupmZVc82z2Cz7CG",
            "symbol": "AMZY",
            "name": "Amaizy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lolpimp/cryptos/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H1LGfirZp73ssFJVWgumZ2GMpy5Bx2GFRuUkbuFNTxn2",
            "symbol": "SSHD",
            "name": "Super Shidd Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/infiniteDungeons/SuperShidCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4nGc9AjiBVKiZ8oNw12yEzZdJ7xwxNFQsqHf4gJJVZrF",
            "symbol": "GEICO",
            "name": "Fractionalized Gecko-2140",
            "decimals": 2,
            "logoURI": "https://oleoat3dkcqo3ydnw2m5fe2qcw5rhfhhf5ga7kaalnljynee57za.arweave.net/csjgT2NQoO3gbbaZ0pNQFbsTlOcvTA-oAFtWnDSE7_I",
            "tags": [
                "bridgesplit-fraction",
                "gecko",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/vault/4nGc9AjiBVKiZ8oNw12yEzZdJ7xwxNFQsqHf4gJJVZrF"
            }
        },
        {
            "chainId": 101,
            "address": "H89t4SG6mt8qD4pjZ6DjPPw59aNhuapCZRiyJJYrYFoM",
            "symbol": "MickS",
            "name": "Mick Savioz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/sm.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8DxBDBbJz1S7BR5A7a2Y57WMZ5VjZuy3gGRaeuFQ8au8",
            "symbol": "MickSavioz",
            "name": "Mickk Savioz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto2/main/smick.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "1SSBwC8hxB4GXzKKU4ENNpST7zgpTmucDe9NoAopWXX",
            "symbol": "ISS",
            "name": "Irish Setter Sol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1SSBwC8hxB4GXzKKU4ENNpST7zgpTmucDe9NoAopWXX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/f3vZN63gax",
                "github": "https://github.com/irishsettersol",
                "medium": "https://medium.com/@irishsettersol",
                "serumV3Usdc": "VEwPVH2AzoqQb35DP8gvY3B6hj5mwRzGhfee977b27Z",
                "twitter": "https://twitter.com/irishsettersol",
                "website": "https://irishsetter.io"
            }
        },
        {
            "chainId": 101,
            "address": "Fy7mCK5hyR1nRwjabvrNaYijD7Kx4WxawJRG9d5W1NDP",
            "symbol": "CAT",
            "name": "PufferCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/KZacharski/puffercoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2CBJuwoaD3pb6RnHgqoreRGuoZJJbYUAoafiskYDH4iF",
            "symbol": "BLUR",
            "name": "Blur",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/KZacharski/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4oAV7zaTgT5YHrjBvYy9SbcNLco9HSvZC1dtEY8vQFBt",
            "symbol": "PFRUSD",
            "name": "Puffercat USD",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/KZacharski/crypto/main/pfrusd.png",
            "tags": [
                "social-token",
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "2HesFCDdjq4UiX5xkbarmJe5rD7tCnAEjNUUY28QVBrm",
            "symbol": "TRC",
            "name": "Tora Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hirotoshimizu/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4TFJVNU7JtWdxGWUUdNbqteh8y2CtyxSg5UTFK69q3NM",
            "symbol": "AK",
            "name": "Animal Kingdom",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kcjrcrypto/animalkingdom/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8KsAYCoCmatysW2RpCqsrsNGo9S8WFFQYyCpTbZpQ2JN",
            "symbol": "APRT",
            "name": "Apparition Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/War-Admiral-HR/crypto/main/GhostPhotoLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GtcG4RDcojU5sJUQAAUh6zdSJBW8GLquNCMFpBwHyEnD",
            "symbol": "MNKY",
            "name": "Vitalik Buterin's - Monkey Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jay-silesh/crypto/main/logo.png?token=AAUEJWY5DS5KOUX7EMS4I53B2DQ6U",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "meme-token",
                "nft",
                "ethereum"
            ]
        },
        {
            "chainId": 101,
            "address": "5FoabfA5DF15FxMkmEW2qzDm3Q7PLEN1u2yRifhaQMkr",
            "symbol": "FITE",
            "name": "FightVerse",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/MightyAxStream/assets/main/FITE.png",
            "tags": [
                "social-token",
                "utility-token",
                "game-token",
                "NFT-Gaming",
                "governance-token"
            ],
            "extensions": {
                "website": "https://thefightverse.com"
            }
        },
        {
            "chainId": 101,
            "address": "EFPiWp3apg7mRMDmm7Mv9rrRmzbNkFFkkdpcTSaiEcU7",
            "symbol": "PACA",
            "name": "Alpacistan Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/enigmaticenigma/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fcx97kERRKkz5H3qzPB4pc3kRyTpKSaMUdxDnN4Bv9fK",
            "symbol": "LEV",
            "name": "Levski",
            "decimals": 9,
            "logoURI": "https://github.com/SimDim8907/crypto/blob/main/Levski.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ZRwLdDEVJHeVtkcDNBEYvzyGmj2y5p1DtbRhQKTizV6",
            "symbol": "DEAD",
            "name": "DeadCoin",
            "decimals": 0,
            "logoURI": "https://github.com/ceponatia/soltoken/blob/main/DeadCoin%20Square.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "DeadChannels",
                "website": "https://www.trapdrum.com"
            }
        },
        {
            "chainId": 101,
            "address": "AYcohjvG7vuX2KcA87MdG8Ppu4z5pwVEpJXe4ogm9YzP",
            "symbol": "CHCH",
            "name": "Chump Change",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AYcohjvG7vuX2KcA87MdG8Ppu4z5pwVEpJXe4ogm9YzP/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8XBCbEL8zPZzKia3EaTcHYdWLDnTt2thHJMCLP4mhFts",
            "symbol": "4TWRS",
            "name": "Four Towers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ignorantelkski/four-towers/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "12mNQPJ2FGytia5Mc3Tz5t7jXGkk46JY9u9PiReBVKLR",
            "symbol": "5RAN6",
            "name": "5ran6",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/5ran6/5ran6_token/main/logo.png.png",
            "tags": [
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "9N9YiaHuU6a99UNuSMi89UspY1gnd4AaAZxwetKTAUEn",
            "symbol": "P2H",
            "name": "Play2Help",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Play2Help/global/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Games-based charitable token",
                "website": "https://play2help.me"
            }
        },
        {
            "chainId": 101,
            "address": "5b6FCghR8TAtELnma4ADLk2Kx1KV1vcJ3jKdk7WJUf72",
            "symbol": "JR",
            "name": "JuRedstoner Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JuRedstoner/crypto/main/solana.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DtHEJQgYSjx3HoBqVtCzr4sojbjbSxFrRnKeAWv4qwjR",
            "symbol": "PLSM",
            "name": "PLASMA - Big Mouth Bastards",
            "decimals": 7,
            "logoURI": "http://www.choppeduniverse.com/wp-content/uploads/2021/12/PLSM-logox-01-01.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CvNxZwaEuCtQw72sNSxAWfsH2YdR4NP1qZ2YHFuJPtyE",
            "symbol": "3DS",
            "name": "Dominguez Digital Design",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dominguezcell661/3Ds-Token/main/3DsLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ktq18ZnynKTVV1ZQvbc28Jzj8WfbEP2WCC1im9e4fGK",
            "symbol": "PFRY",
            "name": "Ponies Fucking Rock Y",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NOVA-Heftig/PFRY-Crypto/main/20220101_022912_0000.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F8rFb2mX3V4wARW87pJfdbv2tZfPCbCFcwHypAbdCAkF",
            "symbol": "ZBEL",
            "name": "Pioneers Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F8rFb2mX3V4wARW87pJfdbv2tZfPCbCFcwHypAbdCAkF/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HyUqdk5LxGSFyrptQL9JiUgP66H25djVd3mCAedhfCR9",
            "symbol": "NIGE",
            "name": "Nigels Social Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HyUqdk5LxGSFyrptQL9JiUgP66H25djVd3mCAedhfCR9/logo.png",
            "tags": [
                "social-token",
                "loop"
            ]
        },
        {
            "chainId": 101,
            "address": "6mA5p31vvS4YMn7XK7hcLx88EC2rYq8a8vz76ofSoR3J",
            "symbol": "KND",
            "name": "Kindo",
            "decimals": 4,
            "logoURI": "https://user-images.githubusercontent.com/4694319/147854751-097c9287-365f-4926-b3dd-2db89ca49a9f.png",
            "tags": [
                "token"
            ],
            "extensions": {
                "website": "https://kindo.finance"
            }
        },
        {
            "chainId": 101,
            "address": "E6huQbLpbHAdXbBjXGti4eBnrjqPQzD9VrFKu6UKzqDu",
            "symbol": "DODI",
            "name": "Dodi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DodiMars/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7aCDkQbcoW97B9rYakmbktXHBeoB1L7whWep26P4teW",
            "symbol": "GONE",
            "name": "Gone Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GoneFromTheMachine/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Mkm88WrSH7KrMYRfVFfTYsYDpwmSdHYW5HDESaHSjvv",
            "symbol": "YCC",
            "name": "YouCode Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/el-ouakili/yc-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3odezrjhUwhNHQYjzzHyJ6hPzuto4BWUEA4iBfLUbr32",
            "symbol": "HSC",
            "name": "HOT SOUP COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GiuseppeGiglio3k/MyFirstTokenProject/main/logo.png",
            "tags": [
                "social-token",
                "community-coin"
            ],
            "extensions": {
                "description": "Giuseppe`s Hot Soup Coin",
                "twitter": "https://twitter.com/giuseppeagiglio"
            }
        },
        {
            "chainId": 101,
            "address": "2coPnLrai1teeZeZdcHwafjkD7hC6tyJavMWy23d7fbg",
            "symbol": "MCH",
            "name": "Millenial",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AnthonyRamses/crypto/main/logo_millenial.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MillenialTigers",
                "website": "https://millenialclubhouse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AqERDNX4eKDtMu4voLd6oLEsF7CRknACPye9UWWMLf7g",
            "symbol": "TECH",
            "name": "TechAdemi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ralle001/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BsSqr6gQNtYFaUHH23jW5FQVNVKP6Cd33BqM5FPrP8SL",
            "symbol": "KLQ",
            "name": "Klinq",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/geraldon1997/klinq/main/solana-klinq.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://klinq.io"
            }
        },
        {
            "chainId": 101,
            "address": "FAL6rDyKGyRrmMHHQD4wvreVDZatVg7rgoiixCSvsaLB",
            "symbol": "MRCB",
            "name": "MicroClub Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/massinissaboujelouah/Crypto/main/LOGO.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9YyBWN3uZf7j8rzEiAHBY7MxNHPvAwCuHUnTnGyMyd2b",
            "symbol": "MFTKN",
            "name": "My Future Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/myfuturetown/crypto/main/0001-removebg-preview.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BwGLzB8Upv2RxanfgkSVAYNjenuz26mQjHPH6c7ytr1u",
            "symbol": "OIL",
            "name": "SolBots",
            "decimals": 5,
            "logoURI": "https://i.imgur.com/imq3ICz.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolbotsDAO"
            }
        },
        {
            "chainId": 101,
            "address": "41j9uUKgyEJD5EsezyUJQ82ULtkBQ67xqWFu2pfG899d",
            "symbol": "SBH",
            "name": "ShoeBox Hub",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shoeboxhub/Crypto/main/1.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3jdvt3LFfoW28rhj7Z2J5r5The8ULWyfAfDXRVSRoDvh",
            "symbol": "ZAMC",
            "name": "Zamat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mattzamat/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "21Dn1UQQvioR6eJb9wznx3Ef56GyPVuGwVc9NGPpzNGa",
            "symbol": "TFA",
            "name": "Trifecta Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrcactusjones/trifecta-coin/main/60854458c4d1acdf4e1c2f79c4137142d85d78e379bdafbd69bd34c85f5819ad.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6ZzApb9xf1ZkRqCamvdqCNHhhL7B2g17D95D8ne2LvmD",
            "symbol": "KSTR",
            "name": "KaiStar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kaiozoshin/token/main/star.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hc2zBm4skodzmM2ZAWgXm8eu6wgDjNaWAA3ZQzc2oU23",
            "symbol": "umi",
            "name": "Umicom Asset-Backed Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/cf23012418749b944e9319370afbe0702c4dba94/assets/mainnet/Hc2zBm4skodzmM2ZAWgXm8eu6wgDjNaWAA3ZQzc2oU23/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "security-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EathPiCkVhpnEpr87ZSjo9qKoGuXVpCVFo1WWRzMLpYh",
            "symbol": "INO",
            "name": "Ignotus",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ignotuspy/ignotuspy/main/IGNOTUS.jpg",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EGZ54c4CWtdDpQ7SWfk69vrjhGjbJ2VRLrDGHKqjpwuc",
            "symbol": "GJT",
            "name": "Gunajaya Token",
            "decimals": 0,
            "logoURI": "https://github.com/gunawan89ok/beo2trex_token/blob/main/t-rex.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "AsTBgyjSpYiJuqzqLWzzgiMstdYsasfdPFwU5NyDqamx",
            "symbol": "BCAT",
            "name": "Big Cat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/johnc99688/bigcat/main/dog8-1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AZn4LQQfB2RCi5QY7J5gHDQ2FpY6uXmeGCEm5jMJLUvt",
            "symbol": "BPPL",
            "name": "Bipple",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/benjaminghys/Bipple/main/BIPPLE.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3NqEtv9CpqP6cgFd1bJCUG4Z9jsaGDGASsBbAreFFEwX",
            "symbol": "NSL",
            "name": "NASCOL",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3NqEtv9CpqP6cgFd1bJCUG4Z9jsaGDGASsBbAreFFEwX/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "NASCOL is a digital currency with an elastic supply mechanism",
                "website": "https://www.youtube.com/channel/UCRN1OelsbBSXb0MNYXzG1mQ"
            }
        },
        {
            "chainId": 101,
            "address": "57FYTy2H7DzhZX2H8oAyt5XJMird3ujKAFPGtR7qpojQ",
            "symbol": "CONA",
            "name": "ConaToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bernymelo3/crypto-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AwA59FnmqYNREGdCFB7GMtC6x94UGYjLV38PU4jpx7VQ",
            "symbol": "sqi",
            "name": "Sheng Chi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AwA59FnmqYNREGdCFB7GMtC6x94UGYjLV38PU4jpx7VQ/logo.png",
            "extensions": {
                "discord": "https://discord.gg/Us95EnvU7D"
            }
        },
        {
            "chainId": 101,
            "address": "2UZ3AnG5xx2vYsfTxDfzHJodfCzbtJ8GVvodAZFVVr8o",
            "symbol": "NAMIT",
            "name": "NamitCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/XnamitX/Crypto/main/Namit.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ks9ZkiUEperqVuxXmxx2HL7BdccCVWt5tB9gAHLhXRZ",
            "symbol": "SMM",
            "name": "Sumomo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ks9ZkiUEperqVuxXmxx2HL7BdccCVWt5tB9gAHLhXRZ/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Jx5r3LnRtVjUVYnGfedSPg2VsRzXSDyoDX4cLjd4RuQ",
            "symbol": "MUN",
            "name": "MuneraGames",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/muneragames/munera-public-docs/main/IconOnly.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "description": "MunerGames in-game and governance token.",
                "twitter": "https://twitter.com/MuneraGames",
                "website": "http://muneragames.com"
            }
        },
        {
            "chainId": 101,
            "address": "D2dBmc8RB6RQPAnxy4UXA6uyo3dPFNSnr37MftZjVQfs",
            "symbol": "MKN",
            "name": "MKwanyotaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/panexorca/crypto/main/mkn.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CSyoRfyxVkyHcXDAzqAQ5x5x2QM1odJv3Yr4CW4zeQ83",
            "symbol": "MTKFT",
            "name": "MountainKraft",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CarasanToscan/mtkft/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9q9ZYkkN4AgB1n4egxeZdkrvg6kMeFV3hK45dLqCf6H5",
            "symbol": "AGTM",
            "name": "Argentum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theisaaclloyd/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cp3Lk4CrZJWoLuFQ3z7dR2ser6jVH4TbLyiUr41YmXDR",
            "symbol": "OXRH",
            "name": "OxRoush Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OxRoushCoin/OxRoushCoin/main/OX.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Yt8pxxgNKz97rs3jW4E6BPe8LBtwtP7MT4TAcddPsCw",
            "symbol": "PK",
            "name": "Padoru Kindred",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fubukyx/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HEYjv6XKT92BM6Q13roWoXEaePxPcYe9mcU3RbsGSi5h",
            "symbol": "FRIEN",
            "name": "Friends",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/HellArnt/crypto/main/Friends.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3XGeHDD8HGTPS2RoAQqwy5d1Wrqh9pTtku6mQvvir3Xr",
            "symbol": "ASC",
            "name": "Aschonn Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aschonn/AndrewCrypto/main/My_Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7c4WDszqXtSnP8AkzRJQy9cSKDAkxDy5mDgSKpsVf6ce",
            "symbol": "SAGAN",
            "name": "Sagan",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kantrealb1/Sagan/main/Sagan.png",
            "tags": [
                "social-token",
                "family-token",
                "utility-token",
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "81fjtbZmEqmaRjxoqhK7VirgwFYDRAyv5fryAGqw5A3z",
            "symbol": "HUGE",
            "name": "DudeHuge",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DudeHuge1/crypto2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HyyTfDqTqQZL14x1AABj64TErufRcRGeX9RmQbmqyS8R",
            "symbol": "DHTC",
            "name": "Hscktoolcrypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hacktoolcrypto/token/main/token%20logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "Beemann1VaDTxXwKTRDM3ovrUYXQDyAq4od3yeFUGyBB",
            "symbol": "BEEMAN",
            "name": "BEEMAN Test Token",
            "decimals": 5,
            "logoURI": "https://avatars.githubusercontent.com/u/36491",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/beeman_nl"
            }
        },
        {
            "chainId": 101,
            "address": "3SVkD1JwJVoHujqLUA8PDjk7YzvqhEaPLjvtJW5B4VkJ",
            "symbol": "PCOW",
            "name": "Puppy Cow",
            "decimals": 0,
            "logoURI": "https://github.com/0xjpuff/puppycow/blob/main/puppycow2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C4ZUAZkaKRYTxbqqGz6kTgLhimxisBLYhWQvL16mTNdy",
            "symbol": "EHT",
            "name": "EggHead Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/ossiHQ/eggtoken/token_picture.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/etUarp4x8y",
                "twitter": "https://twitter.com/eggheadnft"
            }
        },
        {
            "chainId": 101,
            "address": "84TbR3kru8bRL4dtrZQkjn9csbxY59dWemv1v1nyt4ix",
            "symbol": "TRICK",
            "name": "TrickCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/TrickCoin/Trick/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E6s1cJi6Qs8EE14FNkF9pbNu1pwhLWGH2jMRZeStWsAk",
            "symbol": "DAYC",
            "name": "Dumb Ape Yacht Club Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6s1cJi6Qs8EE14FNkF9pbNu1pwhLWGH2jMRZeStWsAk/logo.png",
            "tags": [
                "game-token",
                "meme-token",
                "nft",
                "gaming"
            ],
            "extensions": {
                "description": "10,000 Seed phrases phished and stored on Solana.",
                "discord": "https://discord.gg/8eHfqeyx3T",
                "twitter": "https://twitter.com/DAYCNFT69",
                "website": "https://dayc.art"
            }
        },
        {
            "chainId": 101,
            "address": "DBpegaZtddc9ux5HcRaTz1JRzGpQAYVxuMNrmYfXeMYP",
            "symbol": "GSK",
            "name": "GSKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mustacheman6000/GSKoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9a1QRtcd2drso6NqP5SSDw33JY4FzKjvPJup5Q5KS1JK",
            "symbol": "JIN",
            "name": "Jin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jakelovescoding/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FGLe9dhZGUAKJrPeDyCTc5bAEzcf5eTg22uPBktNCCjb",
            "symbol": "BMKN",
            "name": "Boomer Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fibrou/boomerKoin/main/ouSauna.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F8jzEHiKCTure7oCQZBfzsB48guwk6REh7dSLbXamBvz",
            "symbol": "QBLB",
            "name": "CubaLibre Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/QubaLibre/CyrptoCubaLibre/main/cuba-libre-cocktail-can-be-used-as-icon-logo-vector-5024289.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "51Yi21CBG7sejLmV7StpSWDdxdLAAzE8G7vK3r4VbUZh",
            "symbol": "DOJAKOIN",
            "name": "Doja Koin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/51Yi21CBG7sejLmV7StpSWDdxdLAAzE8G7vK3r4VbUZh/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "Doja Koin is the future of gaming economies empowering players to transact across completely separate digital worlds.",
                "twitter": "https://twitter.com/DojaKoin"
            }
        },
        {
            "chainId": 101,
            "address": "AzNYUQvxghXPMt647GQgJeMbAcdm5Ptujv1pRLsvoRGD",
            "symbol": "BMK",
            "name": "Biermarke",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AzNYUQvxghXPMt647GQgJeMbAcdm5Ptujv1pRLsvoRGD/logo.png"
        },
        {
            "chainId": 101,
            "address": "GqVJTwFDLqiUzPtL5ZL7pF9i5xVCLixRHKqmg35QV9vK",
            "symbol": "BOW",
            "name": "BowCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GqVJTwFDLqiUzPtL5ZL7pF9i5xVCLixRHKqmg35QV9vK/logo.png",
            "tags": [
                "BlastOffWaters",
                "Bow",
                "BowCoin"
            ],
            "extensions": {
                "website": "https://blastoffwaters.wixsite.com/bowcoin"
            }
        },
        {
            "chainId": 101,
            "address": "E8j6isYBR5M4GZXa7PdwmmDLc2rZZTmf6fg624GhRZR3",
            "symbol": "KOBE",
            "name": "Kobe Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Giobertox/CryptoKobe/main/KobeLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DqvZpqvtLWTTHRndKWoVhyzhx2xoJu3oc331Javk3Yhy",
            "symbol": "CLCN",
            "name": "CloudCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iWritz/Test/main/logo%20(3).png",
            "tags": [
                "social-token",
                "breeding-token",
                "air-drop-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4no17WUTr7tbLgf8JFVf4X9oXAuoyyzdhaJDtjT9t5hs",
            "symbol": "ISL",
            "name": "Islanders",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/koudydev/crypto/main/icon.png",
            "tags": [
                "game-token",
                "gaming"
            ]
        },
        {
            "chainId": 101,
            "address": "Ee8pDo9eUaGpAcTm2bsqJryR86rJebGy3fYinNFZ7iqp",
            "symbol": "JAVV",
            "name": "JAVVDEV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zona8dev/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8we1VnnKCr769ZH7xiVFboMe3aLn6APYkGshxsm1QoNC",
            "symbol": "IRLC",
            "name": "IMREAL LIFE Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cr0atz/IMREAL.LIFE/main/Logo.png",
            "tags": [
                "social-token",
                "community-token",
                "air-drop-token"
            ],
            "extensions": {
                "description": "IMREAL.LIFE the nexus to the Metaverse, where real life meets Virtual Reality.",
                "instagram": "https://www.instagram.com/danny.imreal.life/",
                "twitter": "https://twitter.com/IMREALLIFE3",
                "website": "https://imreal.life",
                "youtube": "https://www.youtube.com/channel/UChQy6AwkeyPQurNXD9Vfw_w"
            }
        },
        {
            "chainId": 101,
            "address": "DFTu8VAKvHQnYEvFdRq9GT2VDjJMk1pqiYFjw97c5SMZ",
            "symbol": "DFT",
            "name": "DeFutu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DFTu8VAKvHQnYEvFdRq9GT2VDjJMk1pqiYFjw97c5SMZ/logo.png",
            "tags": [
                "DeFi"
            ]
        },
        {
            "chainId": 101,
            "address": "G933gsbeophPijATin5bB77etQwJ3W5ZFQHCij5Vh5ST",
            "symbol": "TKW",
            "name": "tkwonik",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G933gsbeophPijATin5bB77etQwJ3W5ZFQHCij5Vh5ST/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/uzytkownik667/"
            }
        },
        {
            "chainId": 101,
            "address": "2NkAsDPWtwzEvjLrKk69fiT4gQEPDRLVEGYFZQTxQodp",
            "symbol": "KUBER",
            "name": "KuberMoney",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KuberMoney/assets/main/kuber%20icon.png",
            "tags": [
                "utility-token",
                "DeFi"
            ]
        },
        {
            "chainId": 101,
            "address": "6eWSFau7EMNYn9zzDQKD5aUYTvB6DVdmBGBuMmbCqNTf",
            "symbol": "DU",
            "name": "DU Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dominican-CSSA/DU-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "73mCphqBmBRpWHXS7YVNGnBVDriHEAriFAZzYtGgLk58",
            "symbol": "PIP",
            "name": "Pip",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/knoxharper/pep/main/noun-seed-418863.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "43zpT8NboRXhvV2v6nB8Qt1gpfrvFYTTbruooN6xP5y8",
            "symbol": "ACRON",
            "name": "Acron Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/florin-liviu/flaviu/main/ACRONCRYPTO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3XL4fXxANNvcspBhaLHvPYbfjR9deKyMtT8HP71RwKwu",
            "symbol": "WETFISH",
            "name": "Wet Fish Aquarium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3XL4fXxANNvcspBhaLHvPYbfjR9deKyMtT8HP71RwKwu/logo.png"
        },
        {
            "chainId": 101,
            "address": "2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h",
            "symbol": "CRP/USDC",
            "name": "Cropper LP token (CRP/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "3YbUB2SfgNidBsYr5qEm8xFqyhE8V3k1TkjeFNUYPAjH",
            "symbol": "LIQ/CRP",
            "name": "Cropper LP token (LIQ/CRP)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "2vW8WkXdDWST6JSAerZTsqRCAJKFsAkGTkYZx1gNDcGD",
            "symbol": "ANGRYK",
            "name": "AngryKitty",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vW8WkXdDWST6JSAerZTsqRCAJKFsAkGTkYZx1gNDcGD/logo.png",
            "tags": [
                "meme-token",
                "game-token",
                "nft",
                "gaming"
            ],
            "extensions": {
                "description": "The funniest decentralized meme token for joy, profits and charity | NFT \u0026 Gaming",
                "website": "https://angrykittytoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3jZDCmmiVcBXv1saJaDag3ECnoBNLgr11LtFuQesgGXQ",
            "symbol": "FNF",
            "name": "Friends and Family",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JadenChoi94/crypto/main/tiger.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C9hyc9gbp39FtBPhSfUmeVQFjh3FT77T83f93yYGnPq3",
            "symbol": "BLUEY",
            "name": "Bluey",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/blueybagoon/crypto/main/RoseBluey.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3BjcHXvyzMsjmeqE2qFLx45K4XFx3JPiyRnjJiF5MAHt",
            "symbol": "fcBTC",
            "name": "Friktion BTC (Volt 01 Call)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3BjcHXvyzMsjmeqE2qFLx45K4XFx3JPiyRnjJiF5MAHt/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "4Hnh1UCC6HLzx9NaGKnTVHR2bANcRrhydumdHCnrT3i2",
            "symbol": "fcSOL",
            "name": "Friktion SOL (Volt 01 Call)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Hnh1UCC6HLzx9NaGKnTVHR2bANcRrhydumdHCnrT3i2/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "6UA3yn28XecAHLTwoCtjfzy3WcyQj1x13bxnH8urUiKt",
            "symbol": "fcmSOL",
            "name": "Friktion mSOL (Volt 01 Call)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6UA3yn28XecAHLTwoCtjfzy3WcyQj1x13bxnH8urUiKt/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "GjnoPUjQiEUYWuKAbMax2cM1Eony8Yutc133wuSun9hS",
            "symbol": "fcETH",
            "name": "Friktion ETH (Volt 01 Call)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GjnoPUjQiEUYWuKAbMax2cM1Eony8Yutc133wuSun9hS/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "7wDh4VCTPwx41kvbLE6fkFgMEjnqw7NpGJvQtNabCm2B",
            "symbol": "fcFTT",
            "name": "Friktion FTT (Volt 01 Call)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7wDh4VCTPwx41kvbLE6fkFgMEjnqw7NpGJvQtNabCm2B/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "5SLqZSywodLS8ih6U2AAioZrxpgR149hR8SApmCB7r5X",
            "symbol": "fcSRM",
            "name": "Friktion SRM (Volt 01 Call)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SLqZSywodLS8ih6U2AAioZrxpgR149hR8SApmCB7r5X/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "4sTuzTYfcE2NF7zy6Sy8XhVcNLa6JQSLrx3roy97n4sD",
            "symbol": "fcMNGO",
            "name": "Friktion MNGO (Volt 01 Call)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4sTuzTYfcE2NF7zy6Sy8XhVcNLa6JQSLrx3roy97n4sD/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "5VmdHqvRMbXivuC34w4Hux9zb1y9moiBEQmXDrTR1kV",
            "symbol": "fcscnSOL",
            "name": "Friktion scnSOL (Volt 01 Call)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5VmdHqvRMbXivuC34w4Hux9zb1y9moiBEQmXDrTR1kV/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "DPMCwE9z9jXaDVDti5aKhdgCWGgsvioz6ZvB9eZjH7UE",
            "symbol": "fcSBR",
            "name": "Friktion SBR (Volt 01 Call)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DPMCwE9z9jXaDVDti5aKhdgCWGgsvioz6ZvB9eZjH7UE/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "95sn4kgeJnnBfRCD8S2quu4HS9Y6vb7JDuXrarnmEjYE",
            "symbol": "fcLUNA",
            "name": "Friktion LUNA (Volt 01 Call)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95sn4kgeJnnBfRCD8S2quu4HS9Y6vb7JDuXrarnmEjYE/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "EBPM7fvPN8EuA65Uc7DT9eGyDUZ1sqMLM8Rb8y2YxBYU",
            "symbol": "fpSOL",
            "name": "Friktion SOL (Volt 02 Put)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EBPM7fvPN8EuA65Uc7DT9eGyDUZ1sqMLM8Rb8y2YxBYU/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-02",
                "volt-02-put"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "THjfJ7GUeW6aMU6dzYYFVs5LnKNvmPzgk2wbh3bWagC",
            "symbol": "fpBTC",
            "name": "Friktion BTC (Volt 02 Put)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/THjfJ7GUeW6aMU6dzYYFVs5LnKNvmPzgk2wbh3bWagC/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-02",
                "volt-02-put"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "CxHG1tPkeEHsAVmPF2UrjZK3W719J5DGFgMaLtUkBoeP",
            "symbol": "fpMNGO",
            "name": "Friktion MNGO (Volt 02 Put)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CxHG1tPkeEHsAVmPF2UrjZK3W719J5DGFgMaLtUkBoeP/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-02",
                "volt-02-put"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "SoAnGsHVqSyaN4MjWoPCcftC1V6oSeNxLJjF5TXrtuL",
            "symbol": "fcRAY",
            "name": "Friktion RAY (Volt 01 Call)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoAnGsHVqSyaN4MjWoPCcftC1V6oSeNxLJjF5TXrtuL/logo.png",
            "tags": [
                "friktion",
                "friktion-share",
                "volt-01",
                "volt-01-call"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/eSkK9X67Qj",
                "github": "https://github.com/Friktion-Labs",
                "medium": "https://friktionlabs.medium.com/",
                "twitter": "https://twitter.com/friktion_labs",
                "website": "https://friktion.fi"
            }
        },
        {
            "chainId": 101,
            "address": "At7RLMbA6ZUjj7riyvFq2j5NHQ19aJabCju2VxLDAqso",
            "symbol": "BGS",
            "name": "Battle of Guardians Share",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/At7RLMbA6ZUjj7riyvFq2j5NHQ19aJabCju2VxLDAqso/logo.png",
            "tags": [
                "GameFi",
                "NFT",
                "Utility-token",
                "Metaverse",
                "Governance"
            ],
            "extensions": {
                "medium": "https://medium.com/@battleofguardians",
                "telegram": "https://t.me/battleofguardianschat",
                "twitter": "https://twitter.com/BOG_Official_",
                "website": "https://battleofguardians.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4r5xzwBHZ6gmNQRfwXDwmmDK4T2DBmuVo4PNVqaSucud",
            "symbol": "PFRG",
            "name": "Project Forge",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4r5xzwBHZ6gmNQRfwXDwmmDK4T2DBmuVo4PNVqaSucud/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4NmpfHowmyUyZBfw4u8CeTtVUdwYSDXHH7yQnzZuRsZh",
            "symbol": "AAA",
            "name": "DEV TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/stakeAAA/token-list/main/assets/mainnet/4NmpfHowmyUyZBfw4u8CeTtVUdwYSDXHH7yQnzZuRsZh/logo.png",
            "tags": [
                "DEV",
                "DApp"
            ],
            "extensions": {
                "website": "https://www.soldev.app/"
            }
        },
        {
            "chainId": 101,
            "address": "DNJjw6FfDZpLEm3gp58yRsPQVHtikJ9DYjyRymaWxd47",
            "symbol": "MTVC",
            "name": "Metaverse Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tgraszl/Metaverse-Coin/main/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6terYqZ87xoDQMKfynYZRJ7jCDc6WVZ5RUkoJVFX3jLT",
            "symbol": "KARSTSHEPHERD",
            "name": "Karst Shepherd Dog",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6terYqZ87xoDQMKfynYZRJ7jCDc6WVZ5RUkoJVFX3jLT/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "website": "http://karstshepherddog.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5137Pz5jxBJ5UqABgSFshhEUv5uAz4WLqtg1Bi4WZk1s",
            "symbol": "SVBC",
            "name": "SVBCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Woslights/svbcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FenNHfcDqNKqWXsqNm4uKNvkDBYpuhLrRY5TKQT4D7A4",
            "symbol": "CBD",
            "name": "Carbonado",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/punikus/Carbonado-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ZywPUWBPusFYW9emmhcnjSjCYahEABGmptepbBwGUUQ",
            "symbol": "SIMC",
            "name": "SIM CRYPTO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SIMCrypto/crypto/main/Asset%201.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "github": "https://github.com/SIMCrypto"
            }
        },
        {
            "chainId": 101,
            "address": "FUd2R5Ha7uZ8KL6Tk9Bi6DJiuMrAkcRoUzN5EaAJhKZ5",
            "symbol": "WHZ",
            "name": "Wheelz Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MrWheels2/wheelzcoin/main/wheelzcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GGJTaYGXVqoiVnyweDAEA24NrMYgau9cCkhgDYbxybbE",
            "symbol": "INFY",
            "name": "Infinity Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mr-Bazoo/Infinity-Token/main/INFYlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6LrTDQBxNsFguSAqMRBzApFRg3B77MbTRMGaiCsKUPj8",
            "symbol": "CRAZY",
            "name": "CRAZY TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crazymeta/crazytokens/main/gitlogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BAGcAKTb5xooqS1pDkaNBJnD2WYaK7SuLkntyTi7Dbi7",
            "symbol": "BRAINY",
            "name": "Brainy Ape Gang",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brainyapegang/token-logo/30e21db93a7a14a8fc0ecb1cf5a7c6c2b51c3b26/brainyapegang/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/brainyapegang"
            }
        },
        {
            "chainId": 101,
            "address": "AuPHBMeTXSHQENQMkvVq1aiSuykvBUrRgvQwByBSzmsV",
            "symbol": "BRKS",
            "name": "Brookes Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lucas-brookes/mainnet/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DUcJxBoyB9MP72xT3w3Jc82ycGGuenUCHfT5hGvAL21c",
            "symbol": "CAST",
            "name": "Bettercast",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kingfisherfox/bettercast-coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "meta-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D5YJf7nqSp9JtQpMcZCthdEpMghK2gKmkLMLi2RhKSJt",
            "symbol": "$KAIDO",
            "name": "Kaidos Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D5YJf7nqSp9JtQpMcZCthdEpMghK2gKmkLMLi2RhKSJt/Kaido.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://kaidostempest.com"
            }
        },
        {
            "chainId": 101,
            "address": "PHnyhLEnsD9SiP9tk9kHHKiCxCTPFnymzPspDqAicMe",
            "symbol": "pHONEY",
            "name": "pre-IDO HONEY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PHnyhLEnsD9SiP9tk9kHHKiCxCTPFnymzPspDqAicMe/logo.svg",
            "tags": [
                "utility-token",
                "DeFi",
                "Governance"
            ],
            "extensions": {
                "discord": "https://discord.gg/honeydefi",
                "website": "https://honey.finance"
            }
        },
        {
            "chainId": 101,
            "address": "3LGLAKwV7aK6sr2fsRjr6XcXNPHyMeeqFDzJz6rNJnK7",
            "symbol": "DVL",
            "name": "DigiVelly",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/digitalyug/data/main/3.png",
            "tags": [
                "social-token",
                "community-token",
                "meta-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/digivelly",
                "website": "https://digivelly.com"
            }
        },
        {
            "chainId": 101,
            "address": "BH9mdKEdzjxHgNdUbjo4EsQMq629kbjoVgwLNZNMP74Y",
            "symbol": "NPT",
            "name": "PolarToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nicolas-Gatien/PolarToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CyNssnsGPUK2j4GmdAbahwL6U229YL9nsz2e1gyBf3by",
            "symbol": "KUNAL",
            "name": "KUNAL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CyNssnsGPUK2j4GmdAbahwL6U229YL9nsz2e1gyBf3by/kunal.png",
            "extensions": {
                "twitter": "https://twitter.com/kb24x7",
                "website": "https://kunalbagaria.com/"
            }
        },
        {
            "chainId": 101,
            "address": "WBifqxoQMuYZza6tHiMTaJbU3fAUpNnpzj92mcgyvYs",
            "symbol": "GWN",
            "name": "GOODWIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kevgoodwin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ycJ4cmmYdxsUra4hnqhAjKDKb1ez4YudbNdRqqTpnJE",
            "symbol": "GNKN",
            "name": "Green Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Greenjuicesystems22/CryptoGreen/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Xiq2eivd5dXqJvDgzu1pja9gVaF5UUxBK2C5pASk88D",
            "symbol": "RXP",
            "name": "R3G3xP Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ander1044/crypp2/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "meta-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4FxPQ8TT8gpsuMSCtSYyYfq5yWpsaScDJ6qmMfunGnHx",
            "symbol": "PEEP",
            "name": "PEEPCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/peppeschizzo/crypto/main/peep.png",
            "tags": [
                "social-token",
                "community-token",
                "meta-token",
                "utility-token",
                "DeFi",
                "Governance"
            ]
        },
        {
            "chainId": 101,
            "address": "674PmuiDtgKx3uKuJ1B16f9m5L84eFvNwj3xDMvHcbo7",
            "symbol": "$WOOD",
            "name": "Mindfolk Wood",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Alfred-builder/Alfred-builder/main/wood.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "mindfolk-wood",
                "discord": "https://discord.gg/mindfolk",
                "twitter": "https://twitter.com/mindfolkART",
                "website": "https://www.mindfolk.art/"
            }
        },
        {
            "chainId": 101,
            "address": "HqWzctSNnURY1D64mT7TPNf1GNnkPqssXipYQ5dhnQH1",
            "symbol": "GLDX",
            "name": "GoldiLox",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/knightscode94/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7mfnYz5BmZJsVZkQCDbtPBmk3dhQAYRJpdKjG2mTJQij",
            "symbol": "SIN",
            "name": "Sinner Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7mfnYz5BmZJsVZkQCDbtPBmk3dhQAYRJpdKjG2mTJQij/logo.png",
            "extensions": {
                "discord": "https://discord.gg/NqFkWNPv",
                "twitter": "https://twitter.com/SinnerCircle__",
                "website": "https://sinnercircle.io"
            }
        },
        {
            "chainId": 101,
            "address": "3BdbNjY9F823ZqAD3co5AijGY9pyowtPxr1DswB6EHVx",
            "symbol": "MPTN",
            "name": "Memphis-Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jon1217/jon/main/unnamed.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "43Kd1P5yC9kBA3w2JEMtUv7CjGexnkKHhhxrqksqEsDW",
            "symbol": "PSC",
            "name": "Psy Cat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Samar1221/token-list/main/assets/mainnet/43Kd1P5yC9kBA3w2JEMtUv7CjGexnkKHhhxrqksqEsDW/darckcat.png",
            "tags": [
                "NFT",
                "dao",
                "utility-token",
                "meta-verse",
                "game",
                "play2earn",
                "governance"
            ]
        },
        {
            "chainId": 101,
            "address": "3B5J4ZW3Yr55iirwP1JzJCrWcm1ACM3dEQAsjpy52rXE",
            "symbol": "LUV",
            "name": "Love Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/connectgca/cr/main/Pride.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "96tb6h21jQB31C6BZiPAKLJsTKyoiTqVJ6ufbnNbhMbZ",
            "symbol": "IRT",
            "name": "InJesterr Reward Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AllClassInvestments/InJesterr-Reward-Token/main/IRT.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8i4P3iuMkXMWycQU7oNVPJo4SnM5R2GXVqcCZvVQJ236",
            "symbol": "CNON",
            "name": "SKUNTA COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/teharchitect/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "71Hifmc5ihznRmbbzCDWfLeGbD8eubeYdDFsASGZfHmp",
            "symbol": "OC",
            "name": "Only Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Jonas-Fxl/crypto/main/coin-icon-png-6.jpg.png",
            "tags": [
                "social-token",
                "community-token",
                "meta-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HmZBR5PPsv1i23fHgFFNAYogG3jHhxKix9heM4Rx56t6",
            "symbol": "PEACE",
            "name": "Peacetache Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bozga-andrei/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2WWK14D4cdWuc9TtUPwbXMUZGyk1PrQYej1NVsPNsBeS",
            "symbol": "ORCS",
            "name": "The Orcs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TheOrcs/token-list/main/assets/mainnet/2WWK14D4cdWuc9TtUPwbXMUZGyk1PrQYej1NVsPNsBeS/logo.png",
            "tags": [
                "NFT",
                "dao",
                "utility-token",
                "meta-verse",
                "game",
                "play2earn",
                "governance"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/theorcs",
                "twitter": "https://twitter.com/TheOrcsNFT",
                "website": "https://theorcs.io"
            }
        },
        {
            "chainId": 101,
            "address": "C7i7JSHC4C3yaiVDREkx14yPSqso4LSFus2jnBD3nGX",
            "symbol": "LJG",
            "name": "LJG Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/LouisJGleason/LJGCoin/main/logo.jpg",
            "tags": [
                "LJG",
                "LJG-Coin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/louisgleason",
                "website": "https://www.louisgleason.com"
            }
        },
        {
            "chainId": 101,
            "address": "JDkv5fH7K2twbzrFKNGo6uFjWwtHjhRCALoQsHB66X3A",
            "symbol": "BOKT",
            "name": "bookcointoken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bookcointoken/crypto/main/BookLOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C7gENDPyayjE2iw6p55J5ELtcDYzQbe91JEhLjH2yxAR",
            "symbol": "SRD",
            "name": "SolRider",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7gENDPyayjE2iw6p55J5ELtcDYzQbe91JEhLjH2yxAR/logo.png",
            "tags": [
                "game",
                "play2earn",
                "community-token",
                "utility-token",
                "governance"
            ]
        },
        {
            "chainId": 101,
            "address": "8Cs8n1mVr4nvzYdpwth72nCpWyWsrviapJHFAnUnP8iH",
            "symbol": "FLAKE",
            "name": "Snowflakes",
            "decimals": 6,
            "logoURI": "https://cdn.discordapp.com/attachments/902244343174287391/907420388882014218/snowflake_1.png",
            "tags": [
                "community-token",
                "utility-token",
                "IcyBearz",
                "Snowflakes"
            ],
            "extensions": {
                "twitter": "https://twitter.com/icybearznft",
                "website": "https://icybearz.art"
            }
        },
        {
            "chainId": 101,
            "address": "E6GB3TZKzEngQyMyMJjX7Gy3UxAQJkBwnZcrzhnnDbZh",
            "symbol": "FETT",
            "name": "Fett Finance",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ffinance/crypto_fett_finance/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8dwADjpVptdHtyPxRTsyVZJgWSaLLPfNNWeYRY1ZXyjb",
            "symbol": "SWBO",
            "name": "SweetBoiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sweetboicoin/crypto/main/bret-coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2BoMWBCQhXx6XJyQG1T6SiPb2gMoFdbei4eGoKJLrHb4",
            "symbol": "TYL",
            "name": "ToYuLi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2BoMWBCQhXx6XJyQG1T6SiPb2gMoFdbei4eGoKJLrHb4/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://toyuli.shop"
            }
        },
        {
            "chainId": 101,
            "address": "3A33g3RFVQ5p3mi71ELYd12XxLTp4EbUN3K1crKNFgE8",
            "symbol": "Titan",
            "name": "Titan Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3A33g3RFVQ5p3mi71ELYd12XxLTp4EbUN3K1crKNFgE8/logo.png",
            "tags": [
                "social-token",
                "Titan"
            ]
        },
        {
            "chainId": 101,
            "address": "CP2eDMhsHBoBT28afkPEqSyQkt93TgxdBvG3mFUMA34V",
            "symbol": "AIR",
            "name": "AIRKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sachaCOOL/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3AEouQgf9NMVfdLRmfmjbBfxsYRN9K8aWbC1iu8KKNdU",
            "symbol": "ANC",
            "name": "Anura Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sufigueroa/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cz9rzVfaoPP2rYePDzAnCge7UsEjKBPi5ZXHJfUng4fc",
            "symbol": "CAS",
            "name": "Cyborg Apes Society",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cz9rzVfaoPP2rYePDzAnCge7UsEjKBPi5ZXHJfUng4fc/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/W8TrcN99",
                "twitter": "https://twitter.com/CyborgApesNFT",
                "website": "https://www.cyborg-apes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AgK9ehuBo4dUgcztxnGCpFFjZ4jz81Em4sQpxZfUmPdg",
            "symbol": "ELHC",
            "name": "elhcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hamzaelhoub/crypto/main/ELHC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B7mrssXBAkgKzodqKAX96t2jxvUmRxYfCVJbRwfXavJu",
            "symbol": "PALMICHE",
            "name": "Palmiche Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Palm1che/assets/main/Screenshot%202022-01-02%20at%2001.24.27.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "77Dz5V9MB97KNR1qhdvNg9gAUjE8xBCjvSPRNi9NZu4D",
            "symbol": "BICO",
            "name": "Big Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jas0nn1/dankcoin/main/monkeyLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7me1NGyaudu2HQSviF1haqczf7zekudKmZoEmkEkxCfY",
            "symbol": "BLC",
            "name": "Black Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/onahMo/crypt/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "619Bg4JtPfsZ68qQYHwejq97nn2PQMPziS8dTWeu9ARK",
            "symbol": "GNC",
            "name": "GnestaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ratman75/GnestaCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9fQcucBMK8XghiUj6J4WXWJWuytmrCQFfzA31CYhgWMC",
            "symbol": "OKTO",
            "name": "Okanagan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cmkgamer/okanaganToken/main/LIONHEADONLY.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J8UCMUQTTjthdRMHsYNoZTSfbVypD8JProEytWep4xMg",
            "symbol": "XXY",
            "name": "XXY Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/bubiblomer/solana-xxy-token/master/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fd9ZJHMCyaaFqTsbJMgPX3Cj3953zYW91Qfw8SNdxoaf",
            "symbol": "SBDA",
            "name": "Subida",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/awedjob/crypto/ce0ef5ce4840996fa8d8f133d98a269a04eba53f/Subida%20Logo03.svg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://affirmative.org/sbda/"
            }
        },
        {
            "chainId": 101,
            "address": "7xHc28rXRwSDq7GotGj6uKbZtYcLyg2cjQSPePBRXeRw",
            "symbol": "CF",
            "name": "Capital Fusion Pro",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Cyclopsticks007/CapitalFusion/main/CapitalFusion1.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2BoFNdaESb6NAMBMkycUS37FDc2VgxzdzSuxVvurcn1H",
            "symbol": "JACCI",
            "name": "Jacci Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/minman1979/spl-token-JACCI/main/logo.png",
            "tags": [
                "social-token",
                "model-token",
                "meme-token",
                "solo-token",
                "amature-token",
                "adult-token"
            ],
            "extensions": {
                "description": "Jacci Coin represents her passion for adult entertainment.",
                "website": "https://JacciOnline.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5GzDPyjczJgYs6ubZhogNxaMvQwHmLcFrGujZnxP9bhF",
            "symbol": "SXE",
            "name": "EDGE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xblackbytesx/edge/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5dVbqgiupbuteiny8u6WJXUGeZwWDEakfgjrBpgHdz28",
            "symbol": "Amour",
            "name": "Amour",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/amour.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9UJRqbjuT9BDw4pWQLd2LjVkiDXK7aKB7miLtNkmhVnu",
            "symbol": "Merci",
            "name": "Merci",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/merci.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7jfDomrA5DRHxk9fHU7RfyQD4iftEdXkBC4XhvvUEfnS",
            "symbol": "NAJ",
            "name": "NAJJI KOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nathanellevi223/najji/main/logo-1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AzED5FxiRrY1wd2Kt3epBTRZz2febfD8JGr2KzAvoUQZ",
            "symbol": "666",
            "name": "LuciCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sozly/LuciCoin/main/512BjjNrVPSL._AC_.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GFJUGdpei3JWTfQoHMzG1vN1JNbDQ1kfSh5TWDvkoPJQ",
            "symbol": "GOLFV",
            "name": "Golf Village Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Smith5172/GolfVillageLogo/main/GVlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EUP79aJSngmFW8nhv7eqU4Beg8mhUfYyh6x2TNNMRT5s",
            "symbol": "WECOIN",
            "name": "WECoin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/XiJinping19530615/CCP_Flag/main/Flag_of_the_People's_Republic_of_China.png",
            "tags": [
                "peoples-token",
                "CHN-token",
                "security-token",
                "PLA-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FdDEakNRY4k3orJuBXUcm9VkcXd8YXVPjuG5WuRN2tWH",
            "symbol": "POOS",
            "name": "Legend Of PooShi",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/ba3893b7f7822992a645bc130f78fa433baff2d3/assets/mainnet/FdDEakNRY4k3orJuBXUcm9VkcXd8YXVPjuG5WuRN2tWH/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "metaverse",
                "play-to-earn",
                "play-to-contribute"
            ],
            "extensions": {
                "discord": "https://discord.gg/WgxU6RSakP",
                "github": "https://github.com/LegendOfPooShi",
                "serumV3Usdc": "GPa4iZhDPJfGUj3RAdpuR1EZ4Xgmstn2CC7E9QVDukKC",
                "twitter": "https://twitter.com/legendofpooshi",
                "website": "https://LegendOfPooShi.com"
            }
        },
        {
            "chainId": 101,
            "address": "35gtGLFoJUaZ4ntd4xNJchw4dSgRBQDPzunbtx3cmSSt",
            "symbol": "BDSM",
            "name": "BDDSSM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/bsdm.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2hvey8VphFCoqXJk53uHmVfa9ebKqY4epBGVgSiYyfp2",
            "symbol": "Fuck",
            "name": "Fuck You",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/fuck.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5G48ogdSHQgFjKZB76RKhyk85zKD5w3ocFMUYeaBVLCd",
            "symbol": "NC4WD",
            "name": "North Country 4WD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NC4WD/Crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BX9FzBoM3nHFARJYAyyBPQZAqASJvu87fJmoes9tmv67",
            "symbol": "CRME",
            "name": "Crysmode",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MisterManku/crypto/main/crysmodelogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6btpYQf9aRuLs1CrE3UBuxyyatyTVQ1t6dPFJwfzBk2B",
            "symbol": "CORP",
            "name": "UniMetaCorp",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sergpelayo/UniMetaCorp/main/ISOTIPO.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JBFaqJ5JyCD1qw1gXzaiUyAJ6SNHRmnUBEgofAgcfXVf",
            "symbol": "PETAL",
            "name": "Lotus Petal",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ThiagoMeneghin/LotusPetal/main/LotusPetal.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Lotus Petal Coin is a token for the MTG Community."
            }
        },
        {
            "chainId": 101,
            "address": "1dHQHYsgcTbhm54kq9oHBuV2Wzx1Da4pQuBNZFjHyDv",
            "symbol": "T3X",
            "name": "Terminal3 Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1dHQHYsgcTbhm54kq9oHBuV2Wzx1Da4pQuBNZFjHyDv/logo.svg",
            "extensions": {
                "website": "https://terminal3.com"
            }
        },
        {
            "chainId": 101,
            "address": "9Qy6rKw5GtaEQibYAr9EDBjxL644XN9DXJBXcM9cLk7G",
            "symbol": "WALT",
            "name": "WalthallCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AWalthall/Crypto-logo/main/Logo_2.0.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A2278mqYGxHP26opZuLbbcUj9HWkdctbHHauDwqVsYz9",
            "symbol": "MCC",
            "name": "Milo Cat Coin",
            "decimals": 0,
            "logoURI": "https://github.com/bzlxhb/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8CBaQqTTrt2MQc3EsaZqtm87sivjwJgHX1FqXuHBDWWx",
            "symbol": "SQRT",
            "name": "Squirt Coin",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/QNC0j8T/favicon.png",
            "tags": [
                "social-token",
                "model-token",
                "meme-token",
                "solo-token",
                "adult-token"
            ],
            "extensions": {
                "description": "Squirt token is the utility token for uptimum pleasure.",
                "website": "https://pleasure-fi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HPeWNsBtYtfMeYS6Sqwb3uvDvuarekCnrjCq41XqMQdf",
            "symbol": "LITTE",
            "name": "LitteCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/EbenezerLitte/logo_assets/main/assets/litte_logo.png",
            "tags": [
                "general-token",
                "media-coin",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CiAkzbxkQCyY7hFtNeUHMbqiL8CXtbWaRnUJpJz5sBrE",
            "symbol": "PLUT",
            "name": "PlutusFi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CiAkzbxkQCyY7hFtNeUHMbqiL8CXtbWaRnUJpJz5sBrE/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "PlutusFi is a SocialFi application for Decentralized Social Trading",
                "instagram": "https://www.instagram.com/plutusfi",
                "telegram": "https://t.me/plutusfi",
                "twitter": "https://twitter.com/plutusfi",
                "website": "https://plutusfi.io"
            }
        },
        {
            "chainId": 101,
            "address": "Fnh2L7St6meFyNamoKa2axffnbLJgpJkASJMT5k51Dro",
            "symbol": "TSRCT",
            "name": "Tesseract",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/PapaBearDoes/TesseractCrypto/main/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "description": "Tesseract Comics and Games, Glenwood Springs, CO",
                "twitter": "https://twitter.com/tesseractcomics",
                "website": "http://tesseractcomicsandgames.com"
            }
        },
        {
            "chainId": 101,
            "address": "EVRDAsLpiJ8VYQU34vUfdcyVqhZBMDaEzb6RFKJ4NWqQ",
            "symbol": "CRUM",
            "name": "Bread Crumbz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sirEgghead/breadcrumbz/main/bread_crumbz.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6mCgDJDgVQfM5jdXPxwxXBmkBLafnqsBuyKPmTrwwb6h",
            "symbol": "MEIST",
            "name": "Meist Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sayedaman1/meist/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FAxygYQuLWUZNYh18b7ikn4oaeCJB63ATEga4fDxDvpX",
            "symbol": "LGB",
            "name": "Lets Go Brandon Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FreedomSeeds/logo/main/LGB-logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8HpZjkbjCTFNP4o1guxMuNC4LqbauAoyKZdTBv9cBnR6",
            "symbol": "EAST",
            "name": "Easter Inu",
            "decimals": 0,
            "logoURI": "https://storage.cloud.google.com/solana-crypto/easter-bunny.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4oKouQ3Q97Ns2EjdiGp9NFHvXnXBXZfKaYU22KLpxpjR",
            "symbol": "BBC",
            "name": "BB Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/madtc/BB_Coin/main/bb.PNG",
            "tags": [
                "social-token",
                "meme-token",
                "sol-token",
                "BB-Coin",
                "BBC"
            ]
        },
        {
            "chainId": 101,
            "address": "9xST7WVW9m92rojrv7tQk3NDeLmvzTiRzMErJvKBqWTM",
            "symbol": "INSC",
            "name": "Instant Coin",
            "decimals": 0,
            "logoURI": "https://github.com/spenserbuck/crypto/blob/main/InstantCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GfXwysjNzyEVFfE9Mf4y9oAkQkA3g4CFrcpkLA5Zhv6s",
            "symbol": "MANTRA",
            "name": "Om Mani Peme Hung",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfXwysjNzyEVFfE9Mf4y9oAkQkA3g4CFrcpkLA5Zhv6s/logo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bmq9F7F3MzDW55kTJGr4xiEF8Ssiz7Wvdk9E1Vf4rmyE",
            "symbol": "SCOUSE",
            "name": "Scouse Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bmq9F7F3MzDW55kTJGr4xiEF8Ssiz7Wvdk9E1Vf4rmyE/logo.svg",
            "tags": [
                "meme-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FhuY6rZoeQH3WKEXFs1NitsrvHf9axPvzKVcXHrNh94E",
            "symbol": "TREAT",
            "name": "doggos",
            "decimals": 0,
            "logoURI": "https://arweave.net/inoGzbyUQa7_9fQzDTSY5f5b4g4XUTGNa-oepqXo9qg",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/doggos",
                "twitter": "https://twitter.com/DoggosNFTs",
                "website": "https://doggos.dog/"
            }
        },
        {
            "chainId": 101,
            "address": "5tfvSnzrWQKB6yfGT2ATCzpNfsj4oui5fE8oZghejLev",
            "symbol": "AUTH",
            "name": "Authentic",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/AUTHTeam/crypto/main/Auth_Token.jpg",
            "tags": [
                "community-token",
                "meme-token",
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "metaverse",
                "play-to-earn",
                "play-to-contribute"
            ],
            "extensions": {
                "discord": "https://discord.gg/U8sFrVyzPA"
            }
        },
        {
            "chainId": 101,
            "address": "81RduLapa86mKSEhCjqSgCoeK69togqNACj7eQZdmEbJ",
            "symbol": "TAADE",
            "name": "TAADE Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TeeeJays/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BLbJBC1XJqQgRLTumMDnkVm1YeZLzfY8M2Yz5D57J2aK",
            "symbol": "CAP",
            "name": "DaddyEvans",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLbJBC1XJqQgRLTumMDnkVm1YeZLzfY8M2Yz5D57J2aK/logo.png"
        },
        {
            "chainId": 101,
            "address": "8sqAsqGA3YwzMDxgxwpugw2N7z3bE8WQaXshJeZkJC1w",
            "symbol": "ZADDY",
            "name": "Oh Zaddy",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8sqAsqGA3YwzMDxgxwpugw2N7z3bE8WQaXshJeZkJC1w/logo.svg",
            "tags": [
                "meme-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8StwRjjYtPQMXYDSEM4amjNfYaxXjUKfHBLUxWBgyypX",
            "symbol": "MSARI",
            "name": "Msarii Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/a1-msarii/Msarii/master/msarii.png",
            "tags": [
                "general-token",
                "media-coin",
                "community-token",
                "fund-management",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7nHkg14CwobcEj3FyL4E84dDoheES8BnpZhD51D8HXLC",
            "symbol": "BRANT",
            "name": "BRANT Coin",
            "decimals": 0,
            "logoURI": "https://github.com/brandon18lawson/CRYPTO/blob/main/147961747-91eababa-a853-4515-bd71-869a44642c31.jpg",
            "tags": [
                "Stake-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3T4zD5zD7wyZKY84tmoZCvscLugyF8RPw28G8cHSf9Hg",
            "symbol": "VAN",
            "name": "Vannecy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3T4zD5zD7wyZKY84tmoZCvscLugyF8RPw28G8cHSf9Hg/logo.png",
            "tags": [
                "general-token",
                "media-coin",
                "NFT",
                "Artiste",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3GrAKSRXAbnNaF2ht4racfB2pHpHD8QS1E5vHdMHJNZG",
            "symbol": "BIJLI",
            "name": "Bijli Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iayanpahwa/bijli-coin/main/assets/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HhYP6ff4uRJU19k7RcXHZ2FACCSpNaqVu3zsw5R6dRz4",
            "symbol": "WAYNE",
            "name": "Wayne Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WayneChattillon/token-list/488a6b70aba807ab8902477b6ec01fef578aaddc/assets/mainnet/wayne2.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dj9PmgbVBCpaqmwqrgq8LxFusHJr4QEmUuSRDRh3x4mS",
            "symbol": "CADEAU",
            "name": "CADEAU for YOU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GJJFoundation/cadeau/main/kadoPNG.png",
            "tags": [
                "cadeau-token",
                "gift-token",
                "solana",
                "gift"
            ]
        },
        {
            "chainId": 101,
            "address": "F24swYwis4GW7Th9pkU1GE1JfPDBAbb71FLc6mbfauV6",
            "symbol": "BATS",
            "name": "Bats Coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/Jonkolander/batscoin/main/Doge-with-a-Baseball-Bat.jpg",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FHDk1XZrp8puHS2CJb8ycSzgos1XoN16t8rKfVA51DoP",
            "symbol": "AKH",
            "name": "AKHOR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FHDk1XZrp8puHS2CJb8ycSzgos1XoN16t8rKfVA51DoP/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BELEkfxRkTdNexHtsJ8sk6RBx1ZuNcQmfpkKSNgq8S7N",
            "symbol": "BELE",
            "name": "Baby Elephant",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BELEkfxRkTdNexHtsJ8sk6RBx1ZuNcQmfpkKSNgq8S7N/logo.png",
            "tags": [
                "NFT",
                "general-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/JH8jAQ9THa",
                "twitter": "https://twitter.com/BabyElephantSol",
                "website": "https://www.babyelephantsol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "14ChupvJzBeS82ed9n8eymV1AR5U4Z52n2ZsDMjkiFRi",
            "symbol": "JBX",
            "name": "Juicebox Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JuiceboxCreative/token/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/juiceboxcreative",
                "instagram": "https://www.instagram.com/juiceboxcomau/",
                "twitter": "https://twitter.com/juiceboxcomau",
                "website": "https://www.juicebox.com.au"
            }
        },
        {
            "chainId": 101,
            "address": "EeXSXYzFKeaTq7SoKnM4xZ5exJCi6wUkQMS8edzrEPVJ",
            "symbol": "HEPG",
            "name": "HEPENG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andiesanchez/crypto/main/HepengToken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "84HaZttqci5gy7M3YJkd5fc9ftEoz3Ldm7Cm5WCfoWUe",
            "symbol": "HDT",
            "name": "HaDaT3ch",
            "decimals": 0,
            "logoURI": "https://github.com/hadat3ch/crypto/blob/main/logo.png",
            "tags": [
                "general-token",
                "social-token",
                "media-coin",
                "NFT",
                "gaming-token",
                "gambling-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6NPAkmE9u2ChUKUPYkVhdsEPdfdwocKKFdAmLWFqmHcC",
            "symbol": "GRF",
            "name": "Griffin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Griffin88b/crypto-GRF/main/Griffin.png",
            "tags": [
                "social-token",
                "solana",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2B6Veb5wYGAiSjrEHeCU3MTeb7arpfJVXXRpqoK8wjUT",
            "symbol": "SCSU",
            "name": "SCSU MakerBucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kevxred/cryptologo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dyk7Dq2JcpeRDacqiaDgoK9SS7Eqb4RYeKLnwGajS5eo",
            "symbol": "HIGHMINT",
            "name": "High Society Mint Pass",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dyk7Dq2JcpeRDacqiaDgoK9SS7Eqb4RYeKLnwGajS5eo/logo.png",
            "tags": [
                "NFT",
                "general-token",
                "community-token",
                "mint-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/a9Btmz4m4c",
                "twitter": "https://twitter.com/highsocietynfts",
                "website": "https://highsocietynft.io"
            }
        },
        {
            "chainId": 101,
            "address": "3jUSc5eVVsgmzfmhJTtoXzRWQjCvhJAnEPtmNmRDhPKX",
            "symbol": "SKCC",
            "name": "School Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LukaT11/crypto/main/Hnet.com-image.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "swimnKEr963p7EbCjsSnBCoYwytuZHPm3zbq6fKLHXb",
            "symbol": "SWIM",
            "name": "Swim Protocol Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/swim-io/token-list/main/assets/mainnet/swimnKEr963p7EbCjsSnBCoYwytuZHPm3zbq6fKLHXb/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "medium": "https://medium.com/@swimprotocol",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "HFhsaBch3nGYE4ehef7s2m9f1EMRZDzL7t7cNkW71L3F",
            "symbol": "SBG",
            "name": "Solana Bear Gang Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HFhsaBch3nGYE4ehef7s2m9f1EMRZDzL7t7cNkW71L3F/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Xn9WHM2RbN",
                "twitter": "https://twitter.com/solanabeargang",
                "website": "https://solanabeargang.com"
            }
        },
        {
            "chainId": 101,
            "address": "foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG",
            "symbol": "FOOOOOOD",
            "name": "AtlasFOOOOD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG/logo.jpg",
            "tags": [
                "util-token"
            ],
            "extensions": {
                "website": "https://shoebillco.in/"
            }
        },
        {
            "chainId": 101,
            "address": "tooLsNYLiVqzg8o4m3L2Uetbn62mvMWRqkog6PQeYKL",
            "symbol": "TOOOOOOOL",
            "name": "AtlasTOOOOOL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tooLsNYLiVqzg8o4m3L2Uetbn62mvMWRqkog6PQeYKL/logo.jpg",
            "tags": [
                "util-token"
            ],
            "extensions": {
                "website": "https://youtu.be/dQw4w9WgXcQ"
            }
        },
        {
            "chainId": 101,
            "address": "fJGfG6NFM2SGmvXhsNRaN4Ge3UAhqbbmjAkqbR7hn6B",
            "symbol": "USDL",
            "name": "USD Lagrange",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cavidk/logousdl/master/Lagrange_logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/lagrange-fi",
                "github": "https://github.com/Lagrange-fi",
                "twitter": "https://twitter.com/Lagrange_fi",
                "website": "https://lagrange.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "AH4KuSSs6D8yUPx7RTeYyGiBeQnnQC9ctncxZ4kvBD4R",
            "symbol": "FIFS",
            "name": "Solana FIFS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanafifs/solanafifs/main/logo.png",
            "tags": [
                "solana"
            ]
        },
        {
            "chainId": 101,
            "address": "9qWA2upqVgWmdsXEAyu5obEPUaE8sNBspU9pb2coqbcX",
            "symbol": "QDOG",
            "name": "Que Dog Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9qWA2upqVgWmdsXEAyu5obEPUaE8sNBspU9pb2coqbcX/tokenlogo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/hXq4t9RzA8",
                "twitter": "https://twitter.com/cryptoquedogs",
                "website": "https://cryptoquedogs.com"
            }
        },
        {
            "chainId": 101,
            "address": "5kMBto3Eu1sUKM6h9E5x4Lj6QBqaJcpr9V5GqP44EbU3",
            "symbol": "SSCSTK",
            "name": "SSC NFT Stake Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5kMBto3Eu1sUKM6h9E5x4Lj6QBqaJcpr9V5GqP44EbU3/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AknLt433zhYv2mpoHAMqCBBe4fTjWRWEamAkDu6yJJnU",
            "symbol": "CLEM",
            "name": "SUPERCLEMTOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AknLt433zhYv2mpoHAMqCBBe4fTjWRWEamAkDu6yJJnU/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "http://clementlefevre.com/"
            }
        },
        {
            "chainId": 101,
            "address": "APeybLoCHSYMPTnijUNsrMN1jNmJoYP6EAHRD6H91ZXX",
            "symbol": "SIGN",
            "name": "SIGN token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/calebkow/sign-solana/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/signdotnet/",
                "linkedin": "https://www.linkedin.com/company/signdotnet",
                "twitter": "https://twitter.com/SignDotNet",
                "website": "https://www.sign.net/",
                "youtube": "https://www.youtube.com/channel/UCnJ076pE1Cd2kdneAtveKkQ"
            }
        },
        {
            "chainId": 101,
            "address": "4LJ3u1972PSqD48aDiYZrhZ2QjaEaHgpChWth7mnMDtC",
            "symbol": "WJT",
            "name": "Winjit",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LJ3u1972PSqD48aDiYZrhZ2QjaEaHgpChWth7mnMDtC/logo.png",
            "tags": [
                "social-token",
                "solana",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A8mWck4HsvTWt5QkvAPsg3sK5g5b8Aoh1eCydUB5Q6Cc",
            "symbol": "BBUX",
            "name": "Bill Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/johnson1776/crypto/main/coin_photo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HUUvafwSaQ4XGyRuwSHfTRjTGMT4fdkZtbBgcXtBcLh7",
            "symbol": "SpongeBob",
            "name": "BOB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUUvafwSaQ4XGyRuwSHfTRjTGMT4fdkZtbBgcXtBcLh7/logo.png",
            "tags": [
                "MEME",
                "SpongeBob-token",
                "BOB-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/spongebob_sol",
                "website": "https://www.sponge-bob.space/"
            }
        },
        {
            "chainId": 101,
            "address": "C9t8kDQk2oPRC7awv1wfW9NCPZWY4EoXVZGyPnydWG9G",
            "symbol": "RNC",
            "name": "Royal Nugget Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/royalnuggetgaming/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Pmk4qTq3kbXZBchqUNVPtTyYkAJGjerms5E6PyJF7D5",
            "symbol": "POR",
            "name": "POR Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KrisztianZagonyiTis/crypto/main/logo.png",
            "tags": [
                "test-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AAEgVwnimraKGcQDqBjtu5qyunAMTWgxGMKpUFME5Zrw",
            "symbol": "DAHL",
            "name": "Dahlgren",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AAEgVwnimraKGcQDqBjtu5qyunAMTWgxGMKpUFME5Zrw/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ForWpWwegDcJSMxvR8EtAeaTFGbuJ7zgQV62NsE8vAw",
            "symbol": "BERK",
            "name": "Berk Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/berk-demirci-bimu/repos/main/com/repos/resources/img/logo.png",
            "tags": [
                "social-token",
                "util-token",
                "utility-token",
                "community-token",
                "individual-token"
            ],
            "extensions": {
                "github": "https://github.com/berk-demirci-bimu"
            }
        },
        {
            "chainId": 101,
            "address": "GUeUKVLusQ22eRJ42LDQSyvEWqAbudNtg34qu6Rhj7jZ",
            "symbol": "OMNS",
            "name": "OmnisCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUeUKVLusQ22eRJ42LDQSyvEWqAbudNtg34qu6Rhj7jZ/logo.png",
            "tags": [
                "omniscoin",
                "solana"
            ]
        },
        {
            "chainId": 101,
            "address": "2oQnVjGZ1Knmbd84aF6RV6Hw4Yexqa78DvGrQg9xzktP",
            "symbol": "CTH",
            "name": "Catch",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/rhinogroup/catch_logos/main/catch_logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.catchcoin.me/"
            }
        },
        {
            "chainId": 101,
            "address": "5ngVgVwMsWUKeQXYdrgq78qZU29sbnMr9bC3WngHo2Bd",
            "symbol": "CTHU",
            "name": "CatchUS",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/rhinogroup/catch_logos/main/catch_us_logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "stable-coin"
            ],
            "extensions": {
                "website": "https://www.catchcoin.me/"
            }
        },
        {
            "chainId": 101,
            "address": "6dqyQdTKApZiMMiAnJQhV6fkQVYWdoMw1BPBgtJ1hCCG",
            "symbol": "CTHE",
            "name": "CatchER",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/rhinogroup/catch_logos/main/catch_eu_logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "stable-coin"
            ],
            "extensions": {
                "website": "https://www.catchcoin.me/"
            }
        },
        {
            "chainId": 101,
            "address": "4QtpUp8RP7MwqgC5uyFEpkHQc11Dy4KARpRnzLtnGP26",
            "symbol": "CAMO",
            "name": "CaMo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/camopov/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RTs8J4B4gJSNjAmxnZw8abY6ACj5oVGVqnn1RJAj1Fb",
            "symbol": "RUSD",
            "name": "Real USD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RTs8J4B4gJSNjAmxnZw8abY6ACj5oVGVqnn1RJAj1Fb/logo.png",
            "tags": [
                "mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2EfoEpSDRwax4vC3sV7GQMw5fcdvvzf5owjtLE6fFWG8",
            "symbol": "DFI",
            "name": "Decentralized Finance Investment",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2EfoEpSDRwax4vC3sV7GQMw5fcdvvzf5owjtLE6fFWG8/logo.png",
            "tags": [
                "mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9YHRToRmnPmFx3kaHhMZR66JVNkeYDUC3EgJguGTrL7i",
            "symbol": "GGEZ",
            "name": "GamersHaven",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SylvySylveon/crypto/main/Crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CbDwU8JrTYv3GzU7msni8qtfFkAGpcyFAzuhuGq5SVqp",
            "symbol": "CHUG",
            "name": "CHUG Token",
            "decimals": 9,
            "logoURI": "https://solthirsty.io/images/chug.png",
            "extensions": {
                "discord": "https://discord.gg/solthirstyapes",
                "twitter": "https://twitter.com/solthirstyclub",
                "website": "https://solthirsty.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EBLbmoGYhRtw5Ds5A35h4SD6q17t97kW62Y2EJyriTBj",
            "symbol": "SOLCAC",
            "name": "SOLCACTUS",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/97104135/148100693-02cd5ba6-d197-4714-866c-e350b4e56db2.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H6MVWo1ypzht36Bc3NqhAtumY7f1Hq25nVBwW5xtJxUG",
            "symbol": "LAND",
            "name": "Landee Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsZfrs1iWh9CXzbpGUfUkRfs35KkUWXJH2S6Y9D272M/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5V2X6p32EZwFNLh48bU7HG14CE9RqsoMFXQNbCmZWLnJ",
            "symbol": "ALIEN",
            "name": "AlienCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/niospahiu/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "dDTRVvDVJfuELbuEmNqve32pkbc95raxBf2s2wAVjq5",
            "symbol": "CREDIT",
            "name": "Metaversol Credit",
            "decimals": 9,
            "logoURI": "https://metaversol.com/coinlogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Metaversol Credits are the official currency of The Expanse.",
                "discord": "https://discord.gg/metaversol",
                "twitter": "https://twitter.com/MxMetaversol",
                "website": "https://metaversol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "27BHoXY6jzeWZRJBawNNCsQRaquvN3kqtxady6nqq4jd",
            "symbol": "RSTYX",
            "name": "RiverStyx",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/flipsnoop/crypto/main/styx.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDveJwQE99MbJzNeozSk3Pp45CVmEDyVrGyMuckQg3R1",
            "symbol": "BMRC",
            "name": "BEAST MODE RC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/NCBEASTMODERC/NCBEASTMODERC/main/BMRC%20LOGO3.png",
            "tags": [
                "social-token",
                "community-token",
                "meta-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DDX3PR1RVeJgP1d5HWZVgUvPJqKpN3PLsqqXaXJfAjN8",
            "symbol": "CBC",
            "name": "cyberCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cyberfuturegit/cybercoin/main/logo.png",
            "tags": [
                "solana"
            ]
        },
        {
            "chainId": 101,
            "address": "5DENpiMn7EfJyJT98PaVVPUPJQYbDSCF76D9fX11G1Nw",
            "symbol": "NxTB",
            "name": "NxToken Beta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nexfortisme/asset-store/main/NxTokenBeta.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6jKtpdVu1QSPe88BprZB8rqTXmGuQNiX49mH3ePJZSqF",
            "symbol": "LND",
            "name": "Lend 2 Us Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lend2-us/resources/main/images/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/lend2-us",
                "twitter": "https://twitter.com/Lend2Us",
                "website": "https://lend2.us/"
            }
        },
        {
            "chainId": 101,
            "address": "4TT62MBAWgE1m9hJ7ABG7VvgGvnth3eXe6N3MB6xKSqt",
            "symbol": "ELL",
            "name": "Elliel",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4TT62MBAWgE1m9hJ7ABG7VvgGvnth3eXe6N3MB6xKSqt/logo-v1.png",
            "tags": [
                "ethereum",
                "artists-token",
                "awards-token",
                "charity-token",
                "community-token",
                "ott-platform",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://elliel.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5xEbwdxKaB8T2fNatZi8CGg99EjJoTFnnBssJXqEU6SZ",
            "symbol": "FBMP",
            "name": "ForgeBots Mint Pass",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/NWYJs2W/forgebots-mintpass.gif",
            "extensions": {
                "description": "Mint pass for ForgeBots",
                "imageUrl": "https://i.ibb.co/NWYJs2W/forgebots-mintpass.gif",
                "twitter": "https://twitter.com/forgebots",
                "website": "https://forgebots.io"
            }
        },
        {
            "chainId": 101,
            "address": "BoAiSzbhzDZb3kXVA3J1pCYNUEaKBKFrGZqvQWMVtB1v",
            "symbol": "BOM",
            "name": "BOM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Roastoth/BOM/main/1641341675907.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J2WWhNzkwgDGY66ztYEiQqNiryR4YCbECzssBKjVGsof",
            "symbol": "RIPL",
            "name": "RipleyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J2WWhNzkwgDGY66ztYEiQqNiryR4YCbECzssBKjVGsof/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GbUPueKeCvsQzVWtmwaAFSf94HALJX2PgSw54DH42ECC",
            "symbol": "QuBit",
            "name": "Quantum Bit",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/kazyon/Tokens_Images/main/Qubit/QuBit.png",
            "tags": [
                "utility-token",
                "community-token",
                "solana",
                "nft",
                "nft-marketplace"
            ],
            "extensions": {
                "description": "QuBit Tokens are the official tokens for the Network 3.0 Blockchain",
                "discord": "https://discord.com/invite/KVndwCqEHR",
                "facebook": "https://www.facebook.com/nettwork3.0/",
                "twitter": "https://twitter.com/TheNetwork_30"
            }
        },
        {
            "chainId": 101,
            "address": "Acj9A3JP6wYYGvryr1SqNMq3CkHAgXTVWi9xNCTnRA1c",
            "symbol": "CHF",
            "name": "Chief",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nicolas-castro97/Crypto/main/chiefLogorecor.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "z1KPitUQNxHYShTzZqzANR5ZfZeoBdYCad4xTVzbYKa",
            "symbol": "ELPH",
            "name": "Elephant Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/allsngit/crypto1/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Z8hzC4TxHAX7PXYUuZeXKrhmYZPsA5VfGZnCwhF6bWc",
            "symbol": "EDEDS",
            "name": "Eisermann",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Z8hzC4TxHAX7PXYUuZeXKrhmYZPsA5VfGZnCwhF6bWc/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FNtRDd73woYPPLD8B5fZeqe2HH5CLgGgu65wdMARyJWA",
            "symbol": "STUD",
            "name": "StudCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/studhause/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DB8bQYHuR1DEBVu8enu4Sg1PQz96R4BeCyWYs47rCm66",
            "symbol": "RECORD",
            "name": "RECORD Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DB8bQYHuR1DEBVu8enu4Sg1PQz96R4BeCyWYs47rCm66/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://itsthelabel.com"
            }
        },
        {
            "chainId": 101,
            "address": "6dPVeks868t8rGmY2WQP1e8GSzJkmb7NWri2qcsDrJr7",
            "symbol": "MINI",
            "name": "Mini Development",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ben-Donaldson/minidevtoken/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HfdVMLSmps9kTEJgWrmtNjp9zmJCBTWEZi84Jko2oe8d",
            "symbol": "TITS",
            "name": "Tig O Bitties",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tkits22/tits/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2HZoqoV6UscH6CXBtXuycY8qE85YzkLwZnJ8F9HpYGZ9",
            "symbol": "IVORY",
            "name": "Ivory Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2HZoqoV6UscH6CXBtXuycY8qE85YzkLwZnJ8F9HpYGZ9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/IvoryInvestors"
            }
        },
        {
            "chainId": 101,
            "address": "FTHpT4cXcBgwyR9okHAwMdVuoFjfy5tQiK5698g5vEGy",
            "symbol": "NTRL",
            "name": "Nocturnal",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/69129797/148165596-c429f2c6-ee19-4f30-a394-13b15503b7e9.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "description": "Token that fuels your all nighters during end sems."
            }
        },
        {
            "chainId": 101,
            "address": "7XLPdw5YEkLPff1MVeu2a3GeaLSDMG7N3wLNipKkU5FB",
            "symbol": "GK",
            "name": "GabeKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/free-napkins/jacoblogo/main/jacob-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9h4LRfpTAcwoDickGo3EMFxLs6q3Y1LxYtYVfM7nciiu",
            "symbol": "TBC",
            "name": "Tibcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Fatfoxmajor/Cryptothelast/main/euro-g5368e2465_640.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "Gw35BDbNkQtnMYezjSsFCZ1qZfgMbfMiHkwwB3UAz2XZ",
            "symbol": "MPI",
            "name": "Meta Paradise Island",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gw35BDbNkQtnMYezjSsFCZ1qZfgMbfMiHkwwB3UAz2XZ/logo.png"
        },
        {
            "chainId": 101,
            "address": "9JKrbg7CzSomL3rfwwB3SE2YHkexASf7H3qPY8DQxoSA",
            "symbol": "STP",
            "name": "Spiato",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Spiato/crypto/main/logo.png",
            "tags": [
                "change-the-world"
            ],
            "extensions": {
                "discord": "https://discord.gg/jfE4s5xrFR",
                "telegram": "https://t.me/spiatotoken",
                "website": "https://spiato.com"
            }
        },
        {
            "chainId": 101,
            "address": "3gPMG36CWmj4fyj9cWoY6inWxSPU8fVGwUBh2ecJEQkV",
            "symbol": "NYQ",
            "name": "Nyquist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anticausal/crypto/main/nyquist/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2FuUXTmUHMPz7ep8gdu5FKjj6LjjKRvXZeu6SBmRzRQ1",
            "symbol": "HRMC",
            "name": "Hrmmmm Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lukeeh11/hrmmmm-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "819qPK63wWAnSiM6ZPci4sEc6G5EzMk4yTxW97U7iodj",
            "symbol": "F0RD",
            "name": "F0rdCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4w9yH7LbGoQVV41bUzVNcrJ682vibsoLG7fWvkJHwrdS",
            "symbol": "ELFL",
            "name": "Elemental Fluid",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/ForgedMatrix/Crypto/main/final-elementalFluid-logo.png",
            "tags": [
                "game-token",
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5ymp5wZ4rCB2QJ4wmBLmN6ZbybYEPFsD9HdCLH19mkzm",
            "symbol": "AFC",
            "name": "Mehdi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ammari2/crypto2/main/lolo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9tYPNLSEXqK5uxJixk6z1SifTfDAJwQ5JkAyj5dtVQkh",
            "symbol": "SLR",
            "name": "Selerium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SeleriumCrypto/Crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AqTKiVzjX9EZG341Z9FiVAwFFLVx47mJEA9uNC8kBw4F",
            "symbol": "NOUT",
            "name": "NO Utility Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jurecki0/firstSOLToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8RCVnyYGPEpimZrFFBRPzaEsnCzEWZsZ2hPTHZCudrMJ",
            "symbol": "EMC",
            "name": "EmoriCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/emorilebo/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AXYEG84MRYcJoYhJh7Z9btYXdw4G4pdQGYaCubwMkksg",
            "symbol": "DXLSTAKE_V1",
            "name": "Dexlab Stake Season 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dexlab-project/assets/master/dexlab/dexlab_symbol_logo.svg",
            "tags": [
                "stake-pool-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dexlab_official",
                "website": "https://www.dexlab.space/"
            }
        },
        {
            "chainId": 103,
            "address": "Tcc6Xcjuru2jtGMB6bTg7EPMGJrZFXbmKiKHRmEgq9j",
            "symbol": "MNTK",
            "name": "My New Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Tcc6Xcjuru2jtGMB6bTg7EPMGJrZFXbmKiKHRmEgq9j/logo.png",
            "tags": [
                "social-my-new-token"
            ],
            "extensions": {
                "website": "https://google.com"
            }
        },
        {
            "chainId": 101,
            "address": "CWW1FBQ97EXhXBHqVzw82QawJR5bSaMS2KCwLx1jjwP2",
            "symbol": "BFT",
            "name": "Blue Fire Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Schindlermc/Bluefire-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CsiUQQqo6P1MSUS8P93VU2DK94w5Vr2GfDvvgPgq3fRx",
            "symbol": "INT",
            "name": "Interbiznw Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/interbiznw/crypto/main/interbiznw-coin-logo.png",
            "tags": [
                "utility-token",
                "MetaVerse",
                "social-token",
                "community-token",
                "payment-token",
                "multi-chain"
            ],
            "extensions": {
                "discord": "https://discord.gg/PEvDPV4zyU",
                "telegram": "https://t.me/interbiznwcoin",
                "website": "https://interbiznw.dev"
            }
        },
        {
            "chainId": 101,
            "address": "2NMSLU2cscTzrFK3htiPGop7T1jEiMQ5F82D5ugje3Jo",
            "symbol": "MOVS",
            "name": "Movs Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ivangugumartin/movstoken/main/movstoken.png",
            "tags": [
                "utility-token",
                "MetaVerse",
                "social-token",
                "community-token",
                "payment-token",
                "multi-chain"
            ],
            "extensions": {
                "discord": "https://discord.gg/PEvDPV4zyU",
                "telegram": "https://t.me/interbiznwcoin",
                "website": "https://interbiznw.dev"
            }
        },
        {
            "chainId": 101,
            "address": "Gs6vMVNsod4ptgVmse1cdKcX99CDqpiahDAXD9rke7rp",
            "symbol": "GZM",
            "name": "Gizmo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Wbez11/Crypto/main/Logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F2SoB3d83yA6xbSLawPg4ae1tPomAvWYP5LvKozicDH7",
            "symbol": "JMP",
            "name": "JMP Stonks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F2SoB3d83yA6xbSLawPg4ae1tPomAvWYP5LvKozicDH7/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Yes",
                "discord": "https://discord.gg/67jHRMXh5k"
            }
        },
        {
            "chainId": 101,
            "address": "Gt5MuV9PkVEAh34ybQ4LMtiuwNMiPkPLCXJkxdD5SFNu",
            "symbol": "HRM",
            "name": "Hiram",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/niakova/crypto/main/logo.png",
            "tags": [
                "Social-Token"
            ]
        },
        {
            "chainId": 103,
            "address": "8RcoapffJxVRoo523cMC8Q7mCZHT5xUWQfQLSjF5Xzox",
            "symbol": "FAB_d",
            "name": "FABRIC (devnet)",
            "decimals": 9,
            "logoURI": "https://dex.fsynth.io/logo.png",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "GqGn2LFgZYLhjikiSYW6Q4w26JXxnxBRuNMFmT3MjB6M",
            "symbol": "fUSD_d",
            "name": "Synthetic USD (devnet)",
            "decimals": 9,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/ec3521b6-b382-4efb-1d16-7b60c75b9100/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "Cc9PpPUswF4bHD8KJNijxKUKPzcZz1U9Km1STXEvQU5t",
            "symbol": "fURA_d",
            "name": "Synthetic Uranium (devnet)",
            "decimals": 9,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/e6570c13-2421-403c-74d6-eb5654472900/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "AS9argtvFaBWoW91LpfNMoJRk4ju8ugEVdWfMMmFzapx",
            "symbol": "fXAU_d",
            "name": "Synthetic Gold (devnet)",
            "decimals": 9,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/44d5355a-45e3-4c2d-decb-0cf723959900/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "84mR7LyzT9ZKgkFU9DnTrcFLEi8hyRtruXqtRM3LH1Fz",
            "symbol": "IM",
            "name": "ImmortalKing token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ayoubchawkiIT/crypto/main/3544116-middle.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "AgDGJHx2nsrnuouQ1p5f5wCy3bgBAppuuxR82ToUVGeg",
            "symbol": "VR1",
            "name": "VR One",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AzureVein/VR_One-Token/main/logo.png",
            "tags": [
                "utility-token",
                "company-token",
                "MetaVerse",
                "social-token",
                "community-token",
                "payment-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/VROneKoszalin",
                "github": "https://github.com/AzureVein",
                "website": "https://vr-one.pro/"
            }
        },
        {
            "chainId": 101,
            "address": "FGep99dB8Qo7GyqxrgtKm7DMeJF4SeHVHaz2fqJEP48V",
            "symbol": "FANDB",
            "name": "FoodAndBeverage",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGep99dB8Qo7GyqxrgtKm7DMeJF4SeHVHaz2fqJEP48V/logo.png",
            "tags": [
                "social-token",
                "MetaVerse"
            ]
        },
        {
            "chainId": 101,
            "address": "6c6AH38MGGanE4CrzvUUp7D2k39b4X1kYreLDcRmThtQ",
            "symbol": "CIC",
            "name": "CiocCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adriancioc/crypto/main/Untitled-1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3gUBwtQN7PG86D9Sn25ViM176hypSkHMe6bfgQiE1iqr",
            "symbol": "SIR",
            "name": "Senior Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/9z8/Seniorcoin-logo/main/SIR.png",
            "tags": [
                "social-token",
                "company-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "github": "https://github.com/9z8",
                "instagram": "https://www.instagram.com/qy3"
            }
        },
        {
            "chainId": 101,
            "address": "EqwvgBJmH8ADLaisdjLVk7oAtCKTqZy8RdKACmfCNNzJ",
            "symbol": "EVRN",
            "name": "EnviRon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hitshiv/Environ/main/HitshivCoin.png",
            "tags": [
                "utility-token",
                "MetaVerse",
                "environment",
                "community-token",
                "payment-token",
                "multi-chain"
            ]
        },
        {
            "chainId": 101,
            "address": "3kcPfzzEgD3o82RKio4B8LXoAMuZGXTv1gPiNF8miM3M",
            "symbol": "MDLJ",
            "name": "Medalje",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ejectzero/Medalje/main/logo.png",
            "tags": [
                "toekenning"
            ]
        },
        {
            "chainId": 101,
            "address": "5YFaApo5XYgJfyJ72dF5oBtv2d1VNay3ajuPxwbK2jX",
            "symbol": "FFT",
            "name": "FOREST FINANCE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ForestNFT/logo/main/LOGO.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6HKq7SoHESuDz5ZbjZqgQkhrTiDFTfQdJapavMv7DbFb",
            "symbol": "SBD",
            "name": "Solana Bird",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6HKq7SoHESuDz5ZbjZqgQkhrTiDFTfQdJapavMv7DbFb/logo.png",
            "tags": [
                "utility-token",
                "meme-token",
                "dex"
            ],
            "extensions": {
                "discord": "https://discord.io/solanabird_sol",
                "telegram": "https://t.me/solanabird_sol",
                "twitter": "https://twitter.com/solanabird_sol",
                "website": "https://solanabird.net"
            }
        },
        {
            "chainId": 101,
            "address": "8NRwX8xbxEiTzRkQVK96FysCWMFTzS6xv9u9zF4Ezpdj",
            "symbol": "STARX",
            "name": "STAR X",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ArtinMB/OlllO/main/WhatsApp%20Image%202022-01-03%20at%2017.36.24.jpeg",
            "tags": [
                "social-token",
                "currency-token",
                "community-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D2Ho4j5kg5aytwygxF9uNbyxHi4ZP4ywnvhX93kWsRiJ",
            "symbol": "SIMP",
            "name": "Simple Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Waking-Reality/Simple_Coin/main/Simple_Coin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9TufFVQ2Z1F2JucPodVfrocXQcM1RwTZ2CrBHysukXJ5",
            "symbol": "SBG",
            "name": "Solana Bear Gang Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TufFVQ2Z1F2JucPodVfrocXQcM1RwTZ2CrBHysukXJ5/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Xn9WHM2RbN",
                "twitter": "https://twitter.com/solanabeargang",
                "website": "https://solanabeargang.com"
            }
        },
        {
            "chainId": 101,
            "address": "EdzVwQnTsdT5tXcAAamWhAUGCDuB8haN4R9W4QLBKFGL",
            "symbol": "GMZA",
            "name": "GMZREA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mohudwan/gmzrea/main/assets/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://gmzrea.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5RhzHWv5mMi7a87pktvUiYRRkJHVqTiBTYz11FcY6xtV",
            "symbol": "FT123",
            "name": "Fatura 123 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Macbusinesss/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3SqaBCopmZTCBDD6MpbF1Ddg7vhs7yXrUb9MasSsLKXN",
            "symbol": "BELC",
            "name": "Bella Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/apprentice39/BellaCoin/main/Bella5.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hwupx4t36zS1EDyvBDo4mUhakKjvSpgPf6gejAndgjJT",
            "symbol": "BELL",
            "name": "BellIO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tedulus/bellio/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ek4DviTpz5HUUTkkh7uhQtENk8aTNwgaEPnEQTR5Pp8M",
            "symbol": "JWB",
            "name": "Brandtica",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bucephalus121/Logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "98WBFRufsB4BhoYE44oSgTXyvJZjkiewDTZHM8TTAoxo",
            "symbol": "ROCKS",
            "name": "MOONROCKS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/98WBFRufsB4BhoYE44oSgTXyvJZjkiewDTZHM8TTAoxo/logo.png",
            "tags": [
                "NFT",
                "utility-token",
                "meta-verse",
                "game",
                "play2earn",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DHBznR9yvF",
                "twitter": "https://twitter.com/MoonRocksCrypto",
                "website": "https://moonrockscrypto.com"
            }
        },
        {
            "chainId": 101,
            "address": "F2QDS2TaWnfEZVmWs5wRemdWUxtXErHkjDM1yEQF4LJV",
            "symbol": "BWS1WL",
            "name": "BitWhips S1 Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F2QDS2TaWnfEZVmWs5wRemdWUxtXErHkjDM1yEQF4LJV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bitwhips.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FjGPSLLUsZrwRPYhYh2gmHwDjXL8Q8JosdU2Fh63h5Hr",
            "symbol": "CSHELL",
            "name": "Captain Sea Shells",
            "decimals": 0,
            "logoURI": "https://cdn.hngr.co/shell.gif",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://gocaptain.com"
            }
        },
        {
            "chainId": 101,
            "address": "4nnHXEUhPcJub41HnDjKUGGZhjUhaxuuvN62KNqnWKs4",
            "symbol": "AWCT",
            "name": "Awesome CoinT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlexTownsend/crypto/main/grumba.png?token=ADBU4JUGILX4ROHHX4QZJEDB34I4I",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8X5xLPyniuuzLB68mmNVcYwQdbsxr42b9YU73T3SLhu5",
            "symbol": "SIMU",
            "name": "SIMU Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Memetoken123/SIMU/main/logo.png.JPG",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6uRJsnT3e1ZPNEuWLD46gyDhHEpppgH69Dx3JSFqJMua",
            "symbol": "HUSK",
            "name": "Hus-Key Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/huskeycoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5d4m4R3vFLCL265rsqxTyTKcEEuVAgqqRyJ12PxNyViR",
            "symbol": "BSPZ",
            "name": "Bspotz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Bspotz/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PiFfYeSabfSPhxnXUdy5GMoG84nbLG1Y4D9ZmHBGjpZ",
            "symbol": "PIF",
            "name": "Pay It Forward",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PiFfYeSabfSPhxnXUdy5GMoG84nbLG1Y4D9ZmHBGjpZ/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "7dHWMDQf9TDopoFdaJfg8kREq1u4hFFfLRMvEMrzV17C",
            "symbol": "PH",
            "name": "Peace Heaven",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Pralad120/amg/main/amg1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8WngA1uMyH3xxR8UzHmwCsYgBo5v2jENGauAP367tRa",
            "symbol": "IGT",
            "name": "IgorToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IgorKubisa/IgorToken/main/TOOT.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BVR7NkZQAGgYac5dpoe3NHKRCfHz3UdA555uagb1MtC4",
            "symbol": "RHC",
            "name": "RHINO Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BVR7NkZQAGgYac5dpoe3NHKRCfHz3UdA555uagb1MtC4/logo.svg",
            "tags": [
                "rhino",
                "rhinosoft",
                "rhinogames"
            ]
        },
        {
            "chainId": 101,
            "address": "AzW11g2JTErTQ8s8x9jvCYyq6YBbJtnkxAeMqueQ7G1S",
            "symbol": "GENZ",
            "name": "GEN Z TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TUTSS2021/cryto/main/Gen%20Z%20Token.png"
        },
        {
            "chainId": 101,
            "address": "HkxfF1sEPbsy1aFf1HUF6qjqLk2GewoQFY5xb2edgt5b",
            "symbol": "FF",
            "name": "FifteenFighters",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AntonioRuiz9191/crypto/main/logopulido.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "56ZMDv7VWEhEx2Q1YgH3RNEMnwNqTPPA8rk9KLi6CjX6",
            "symbol": "kcETH",
            "name": "Katana ETH Covered Call Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/56ZMDv7VWEhEx2Q1YgH3RNEMnwNqTPPA8rk9KLi6CjX6/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "J58rNqBQTQQJ7oMftec8bLWKVF6iujcH3Ck81f2a4zCu",
            "symbol": "kcSOL",
            "name": "Katana SOL Covered Call Vault Share",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/J58rNqBQTQQJ7oMftec8bLWKVF6iujcH3Ck81f2a4zCu/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "8cPHdUKcphHMxZcqk5ccNLuqJWt9iutwDWByA9ATqxhB",
            "symbol": "kcBTC",
            "name": "Katana BTC Covered Call Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/8cPHdUKcphHMxZcqk5ccNLuqJWt9iutwDWByA9ATqxhB/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "E2eevzN2TBWAL1LiALAfEYFLRBzSDLugLjiNuZg9ehwT",
            "symbol": "kcMSOL",
            "name": "Katana MSOL Covered Call Vault Share",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/E2eevzN2TBWAL1LiALAfEYFLRBzSDLugLjiNuZg9ehwT/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "3JU8iPVMrrhmct4RP7rTSBFwStv6oksEsSj4C6mkk2Yg",
            "symbol": "kcWLUNA",
            "name": "Katana WLUNA Covered Call Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/3JU8iPVMrrhmct4RP7rTSBFwStv6oksEsSj4C6mkk2Yg/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "JD7y57PY86Fz1JL2rtk1c8jGGsFtkiSU9yDTuy4k2gpU",
            "symbol": "kcMNGO",
            "name": "Katana MNGO Covered Call Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/JD7y57PY86Fz1JL2rtk1c8jGGsFtkiSU9yDTuy4k2gpU/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "CERhXTjfPT8AbERv7arCp6LnKr7jacvU82tDdkWQzzWo",
            "symbol": "kcFTT",
            "name": "Katana FTT Covered Call Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/CERhXTjfPT8AbERv7arCp6LnKr7jacvU82tDdkWQzzWo/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "FkTXB81tVJdcLnsnc72HeXGJq6NXaK2puqbFzd5WS6UU",
            "symbol": "kcSRM",
            "name": "Katana SRM Covered Call Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/FkTXB81tVJdcLnsnc72HeXGJq6NXaK2puqbFzd5WS6UU/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "2wRAEfYsBVfiirgWX7qGkbwnXvKvd8u1kd4D59QM4F8j",
            "symbol": "kcRAY",
            "name": "Katana RAY Covered Call Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/2wRAEfYsBVfiirgWX7qGkbwnXvKvd8u1kd4D59QM4F8j/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "Br4WZq7N5gFK3WX8Ls1U15ePnQ1DnSKwx5k62X51LeLB",
            "symbol": "kcSTSOL",
            "name": "Katana STSOL Covered Call Vault Share",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/Br4WZq7N5gFK3WX8Ls1U15ePnQ1DnSKwx5k62X51LeLB/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "Dcuxb4n4fjXmLZKJwMpB64F1r1THvwktLQM7oVwyKzDS",
            "symbol": "XART",
            "name": "XART Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DMW-11/crypto/main/logo.png",
            "tags": [
                "nft-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DwMX8BDhCT6siGkE489ZREovqeGWKzAH5LBa5MYmUPoL",
            "symbol": "ETZI",
            "name": "EtziCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cetzkorn/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FuC9mEgw9QwjteyCm3kkzGoSXcZAaN8o6rC6sXCaUkvm",
            "symbol": "TRST",
            "name": "Trinity Stone",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/paulstuartgibbons/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4c9WaR8GXXckgEjZpF6QS6ztzF16HDmNJgvVo91cT3EQ",
            "symbol": "$BUCK",
            "name": "Buck Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RobotBuck/token/main/Buck%20logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DQEkgMHfaAU4GwdLar3Q6Qj7mGqrc8mqddcTM2Cndf3V",
            "symbol": "KIRA",
            "name": "Poochie Pie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RobotBuck/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HGJ5LQYSaDufQyJZz2QeutKMKdsnatwNsy77zeWyZmFX",
            "symbol": "EIC",
            "name": "Edane Invest Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jlewko10/crypto/main/Crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CN7YUr2T1mr3zu1ccrYWKJcAUWFSVgFzBUg17p5zRM8B",
            "symbol": "OFF",
            "name": "OFF-COIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/yxslx/assets/main/unnamed.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4mFEgeuxhdQaG9BAfnoiUFdhPiyz72sPnMqr1rMEGm95",
            "symbol": "KZC",
            "name": "Kelvin Zhang Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kelvin-wenjie-zhang/kz-coin-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8s4ddk9tLr8CdrkbzU4zTWAMNCu2HfoS39wNNzLDCdUB",
            "symbol": "BBWP",
            "name": "BattleBots Watch Party Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spideyar/BBWP2021/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CCKDRAd4Xwjoovtf2s1duu3d4TPTmFRyh1hfrb3ZUGR2",
            "symbol": "MILK",
            "name": "Tiddy Juice Coin",
            "decimals": 9,
            "logoURI": "https://arweave.net/S71DNASA5TYlrK9giRy7QgqFNw1m4mxqY6OdtgY1E7k",
            "tags": [
                "meme-coin"
            ],
            "extensions": {
                "discord": "https://discord.gg/saPvZHEQm4",
                "twitter": "https://twitter.com/tiddyjuicecoin",
                "website": "https://tiddyjuice.com"
            }
        },
        {
            "chainId": 101,
            "address": "Cy4NRGdUaULYB1CsKXLoB1o2DZMVvBxvexc1JcSQjPoW",
            "symbol": "FLOOR",
            "name": "Floor Coin",
            "decimals": 3,
            "logoURI": "https://www.arweave.net/t-hilPreN5lQQjjR3RZC8RfmCCUMb35yNios7oQHAuo?ext=png",
            "tags": [
                "community-token",
                "meme-token",
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/5ZyJM8iRp3vDTvbGhJkoEZPCeBjcNmTRX6v8KvheNTtw",
                "vaultPubkey": "5ZyJM8iRp3vDTvbGhJkoEZPCeBjcNmTRX6v8KvheNTtw"
            }
        },
        {
            "chainId": 101,
            "address": "EDgaRzsFVHA2DbvWkf8BZeEAW7SxWZVCC5cpwyNH58vG",
            "symbol": "CVN76",
            "name": "Reagan CVN 76",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tkemet/cvn76/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5LnFS3LXtiTPNw6Y7Rak1sEW9bu4a239Xa4gHoeQfJD",
            "symbol": "BIRB",
            "name": "BirbCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DaQueeckinDuck/birbimage/main/birbcoin-logo.png",
            "tags": [
                "meme-token",
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "89fVzH7zgQoGpi8US3LGchvfc4tNP4vksYjW3dQ9Go8H",
            "symbol": "ANSD",
            "name": "AntoSid",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gotchhan/AntoSid/main/logo.png",
            "tags": [
                "change-the-world"
            ],
            "extensions": {
                "website": "https://github.com/gotchhan/AntoSid"
            }
        },
        {
            "chainId": 101,
            "address": "GXQvKtucVHsRWfFMmbBEvLGtxkMz2mSzm7H5NXTcbuzs",
            "symbol": "MINTY",
            "name": "MINTY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MINTYCOIN/MINTYCOIN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6xSvfXwcXy6Vbn4dtLkSYHT6K2dctJzfmUKNbq3VPpf5",
            "symbol": "CAPA",
            "name": "Capital A",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/9471710/capital-A-logo/main/capital-A-logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Udw2dVYLq9vjieo26znz98GrRb548aqfr35mNwsRwsF",
            "symbol": "COINS",
            "name": "Coins",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Udw2dVYLq9vjieo26znz98GrRb548aqfr35mNwsRwsF/logo.svg"
        },
        {
            "chainId": 101,
            "address": "7AVfEQTQGfY34ZGZTin8qbULDNh9eBcC19Ja5C6Y2yyn",
            "symbol": "PESO",
            "name": "Peso Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7AVfEQTQGfY34ZGZTin8qbULDNh9eBcC19Ja5C6Y2yyn/logo.png"
        },
        {
            "chainId": 101,
            "address": "AZYS2oh7rKaiAkabWMkCfHztKS3y9mNGnPaahHyqVtrK",
            "symbol": "PESOS",
            "name": "Pesos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AZYS2oh7rKaiAkabWMkCfHztKS3y9mNGnPaahHyqVtrK/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/pesos_coin",
                "website": "https://pesos.store/"
            }
        },
        {
            "chainId": 101,
            "address": "4jmMQoEFzEerAzsLvwkk289Zh8Fq2p8noZahcHob3tqW",
            "symbol": "SeeD",
            "name": "Semillero Online",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gtietzsch/Crypto/main/SeeD.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "tkKLfFxzDqw7riy2iHfjgjswaR4911dLwtdohXL4dWZ",
            "symbol": "BUX",
            "name": "Bill Bux",
            "decimals": 2,
            "logoURI": "https://github.com/johnson1776/crypto/blob/main/coin_photo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DLDvByK6eRvy3KbkBy6xQcqXAqkp8RhCLgEYPBS19ZnA",
            "symbol": "SLD",
            "name": "SOLDAO",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DLDvByK6eRvy3KbkBy6xQcqXAqkp8RhCLgEYPBS19ZnA/logo.png"
        },
        {
            "chainId": 101,
            "address": "Fh7m8mVDhj2zTdKAHosLLUanLhnFzwQCeKoj2KYaS5gc",
            "symbol": "SPCR",
            "name": "Superchor",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pshah260/superchorcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6wyuqYeRSZ8zC4a6ytz7ZnPkSgFijMiN6WZh3FnLY5fT",
            "symbol": "DT",
            "name": "Drip 0Ape Club Dao Gov Token",
            "decimals": 4,
            "logoURI": "https://media.discordapp.net/attachments/916936341995028530/928528112818479124/0.png",
            "tags": [
                "social-token",
                "dao",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FeyTwUrGtnqQEVARJY4pThxb7AtvtoLoHbNioPSKAk2U",
            "symbol": "UNOX",
            "name": "Unoxe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Oppentec/crypto/main/Unoxe.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BpMdHxq1odLwFsjupWBJdd3M5mXcZTK9DNmgQp2DRV2j",
            "symbol": "DGFR",
            "name": "Disc Golfer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/marshallnp/crypto/main/DGFR.jpg",
            "tags": [
                "transactional-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3MFTdLQ7HEEwQFSWqEaH2bek5Dd7tVqRBDPj5k5wuFGJ",
            "symbol": "CRONS",
            "name": "Cronstedt Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/carlcronstedt/crypto/main/ouroboros_sq2.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DByUkMG4sC17fNmCLGXzQfKxKbTGpuZsBy85ZyoTcydm",
            "symbol": "BFC",
            "name": "BrillFoodCoin",
            "decimals": 0,
            "logoURI": "https://gitlab.com/brilltek42-oss/bfc-assets/-/raw/master/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://bfc.brilltek.com.tw"
            }
        },
        {
            "chainId": 101,
            "address": "A7sNcaeuoL7e5Lv5o1SKTH9aQhe6eaQaaNFtYnSQHcNt",
            "symbol": "GOD",
            "name": "GodCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GodCoin7/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Evup5hpQjUYYCwjVKeMsoyBztq11hgBb75rK4ZXWpvvL",
            "symbol": "SRP",
            "name": "StarPunk",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hqphuoc129/SRP_logo/master/logo.svg"
        },
        {
            "chainId": 101,
            "address": "6uJTdV4FuVJ2YJ3Cv2ZdNXNBFsJsxgVJCYSQtFQYr9iA",
            "symbol": "YELAINC",
            "name": "YELA Incubator Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uJTdV4FuVJ2YJ3Cv2ZdNXNBFsJsxgVJCYSQtFQYr9iA/logo.png",
            "tags": [
                "incubator",
                "entrepreneur",
                "youth",
                "business"
            ]
        },
        {
            "chainId": 102,
            "address": "C4yTauQS75Dosut6LBLGLfJj7CnFURSVmQRNftf2tUuB",
            "symbol": "LTO",
            "name": "LOTO",
            "decimals": 9,
            "logoURI": "https://github.com/sergmai/tempo-jagger/raw/main/loto.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AEZ638gBqisSvmWbGYVuPB1b19vrD7dBtddqXa77M2x7",
            "symbol": "LIZ",
            "name": "Theranos Coin (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEZ638gBqisSvmWbGYVuPB1b19vrD7dBtddqXa77M2x7/logo.png",
            "tags": [
                "ethereum",
                "wrapped",
                "wormhole",
                "meme-token"
            ],
            "extensions": {
                "address": "0x102e941b77bcaa7e35d368cafe51ef8f79c8d1ef",
                "assetContract": "https://etherscan.io/address/0x102e941b77bcaa7e35d368cafe51ef8f79c8d1ef",
                "bridgeContract": "https://etherscan.io/address/0x3ee18b2214aff97000d974cf647e7c347e8fa585",
                "description": "Theranos Coin (LIZ) is a wrapped ERC-20 token immortalizing a story of revolutionary innovation and fantastic hypergrowth, stunning duplicity and scandalous fraud — the story of Elizabeth Holmes and Theranos.",
                "twitter": "https://twitter.com/drinksbydrew",
                "website": "https://www.theranosco.in"
            }
        },
        {
            "chainId": 101,
            "address": "AAXng5czWLNtTXHdWEn9Ef7kXMXEaraHj2JQKo7ZoLux",
            "symbol": "DGE",
            "name": "DarleyGo Essence",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DarleyGo/token-list/main/assets/mainnet/AAXng5czWLNtTXHdWEn9Ef7kXMXEaraHj2JQKo7ZoLux/icon.png",
            "tags": [
                "game-token",
                "game-currency",
                "Gaming"
            ],
            "extensions": {
                "website": "https://darleygo.io"
            }
        },
        {
            "chainId": 101,
            "address": "DsVPH4mAppxKrmdzcizGfPtLYEBAkQGK4eUch32wgaHY",
            "symbol": "GXE",
            "name": "Galaxy Essential",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DarleyGo/token-list/main/assets/mainnet/DsVPH4mAppxKrmdzcizGfPtLYEBAkQGK4eUch32wgaHY/icon.png",
            "tags": [
                "game-token",
                "game-currency",
                "Gaming"
            ],
            "extensions": {
                "website": "https://darleygo.io"
            }
        },
        {
            "chainId": 101,
            "address": "54bivnbkUA6CTw42m8oNaUqxxfDnCmH1NvGnQyeqeNaV",
            "symbol": "PLC",
            "name": "PlatinePHP Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54bivnbkUA6CTw42m8oNaUqxxfDnCmH1NvGnQyeqeNaV/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/platinephptoken",
                "website": "https://www.iacademy.cf"
            }
        },
        {
            "chainId": 101,
            "address": "GcogbzLCZnbo8SHLFLL1DsD5xBWKoDMvo1Tw4YxcUuaN",
            "symbol": "KSK",
            "name": "KssCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dkolesni-prog/ma_baker/main/photo_2022-01-06_12-00-31.png",
            "tags": [
                "beta"
            ]
        },
        {
            "chainId": 101,
            "address": "2vLKynPPYBbPNRzSZoQkrWjT7fvDjLJK5LqEpnEJZg8i",
            "symbol": "ATLS",
            "name": "Atlas Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AdityaKG-169/atlas-coin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/atlasdotgs",
                "website": "https://atlas.gs"
            }
        },
        {
            "chainId": 101,
            "address": "kATzfFsDBcLjknypjLXSPy6mrzFyNhwA5VmTZEurkgR",
            "symbol": "CKATZ",
            "name": "CyberKatz Space Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kATzfFsDBcLjknypjLXSPy6mrzFyNhwA5VmTZEurkgR/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/5m6cBdry6y",
                "twitter": "https://twitter.com/cyberkatzsol",
                "website": "https://cyberkatz.space"
            }
        },
        {
            "chainId": 101,
            "address": "J3iAbtzFedhsKotwPpD4u4hHaHVCuSPLRvjVWBHPZom",
            "symbol": "ANOM",
            "name": "Anomio",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anom-io/anomio/main/anomio.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5wG5c48NvMLoLuzYjZ9ESkTCGh7mEEaBDXgu7WzbizJA",
            "symbol": "MOSHI",
            "name": "Moshi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DuklinDonut/Crypto/main/doggy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C676Kc4yTcyw6pZKXvdZdCwAT1niNfZRcKaz61u7cMwG",
            "symbol": "PXCL",
            "name": "PlexComet",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/plexcomet/assets/main/only-p-logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/plex_comet"
            }
        },
        {
            "chainId": 101,
            "address": "CbratEtKyQCxFcEZ86HJXtT9nTK6gHpzC6BMHBkm9Did",
            "symbol": "CHC",
            "name": "Chief Coin",
            "decimals": 9,
            "logoURI": "https://github.com/PrommerRichard/crypto/blob/main/logo2.jpg?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ATZERmcPfopS9vGqw9kxqRj9Bmdi3Z268nHXkGsMa3Pf",
            "symbol": "FONE",
            "name": "Fone",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATZERmcPfopS9vGqw9kxqRj9Bmdi3Z268nHXkGsMa3Pf/fonelogo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.fone.dev/"
            }
        },
        {
            "chainId": 101,
            "address": "CdP49FGvVM1qFFq25VJA83dbaw4dzN4V8o9khjyFrH6d",
            "symbol": "GIFTY",
            "name": "GIFTY - Gift token for everyone",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/giftytoken/solanamerge/main/gifty.png",
            "tags": [
                "gifty-token",
                "gift-token",
                "gift",
                "solana",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FgysiEsRK2toZU1o56EaoyAfDqaKc9EeoFCSaRQyBkCu",
            "symbol": "FRD",
            "name": "Ferdosia",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FerdosiaEngine/FRD-Coin/main/favicon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HzQZrkhxN39E36qL7qa1tgpexTcwTresKCz2w9ff6f5r",
            "symbol": "PLCN",
            "name": "Pelican",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Medicante/pelican-coin/main/pell.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G8naNPQvUYHT4r93WjNJAjefPAyQk94FbkPkzucxycE1",
            "symbol": "HSMINT",
            "name": "High Society WL Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G8naNPQvUYHT4r93WjNJAjefPAyQk94FbkPkzucxycE1/logo.png",
            "tags": [
                "NFT",
                "general-token",
                "community-token",
                "mint-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/a9Btmz4m4c",
                "twitter": "https://twitter.com/highsocietynfts",
                "website": "https://highsocietynft.io"
            }
        },
        {
            "chainId": 101,
            "address": "6JTroKNLnJqheMhGwpoENfCDXRN1zosCnmpt2ZpHT3qE",
            "symbol": "ALURS",
            "name": "Alurser",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JTroKNLnJqheMhGwpoENfCDXRN1zosCnmpt2ZpHT3qE/logo.png",
            "tags": [
                "community-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "4nyQWESjEc2TvPWr4UgrfYvLPBRYnjs2LpkCvYWTxkwJ",
            "symbol": "MSHRC",
            "name": "Mush Room Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/marsch/mushroomcoin/main/mushroomcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AWWimrYk4qFJ2dVxxpbuKKjFp5PiPGKnbGY74pqXVVPt",
            "symbol": "KET",
            "name": "KETCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ketwebb/Sol_Tpken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6SBmEB9MuGBYdmCzsWXdqB1fT68Vdkn2prkRQrAEnASr",
            "symbol": "HST",
            "name": "Heaven Sevens Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6SBmEB9MuGBYdmCzsWXdqB1fT68Vdkn2prkRQrAEnASr/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/BndsQt7X9X",
                "twitter": "https://twitter.com/HeavensLotto"
            }
        },
        {
            "chainId": 101,
            "address": "FhGkz2uJRDkTKsFMeGPhreeM8E2yNmVmbyv6ny2zm8YD",
            "symbol": "MTVR",
            "name": "MetaVersana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FhGkz2uJRDkTKsFMeGPhreeM8E2yNmVmbyv6ny2zm8YD/logo.jpg",
            "tags": [
                "NFT",
                "dao",
                "utility-token",
                "meta-verse",
                "game",
                "play2earn",
                "governance"
            ],
            "extensions": {
                "discord": "https://discord.gg/invite/nBmznwNacT",
                "telegram": "https://t.me/MetaVersana",
                "twitter": "https://twitter.com/MetaVersana",
                "website": "http://metaversana.com"
            }
        },
        {
            "chainId": 101,
            "address": "2rPMyBMNkjxgDM3276oURfRnXVSb7Q3sK68mg5dtQebv",
            "symbol": "KAT",
            "name": "Katto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/amiralibabic/Katto/main/Kattologo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D2zS7ntXhQH8WURyDogqnWrrqLK4qmKaLqLLKU9XGRjS",
            "symbol": "KOEDOES",
            "name": "Reward-Task Tokens",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D2zS7ntXhQH8WURyDogqnWrrqLK4qmKaLqLLKU9XGRjS/logo.png"
        },
        {
            "chainId": 101,
            "address": "FQuHtR8bXDkHrx3x3To8JkT4fVHMMn9T9q5kk7tndYKv",
            "symbol": "THO",
            "name": "Tho token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomasthehacker/crypto/main/TTH.png",
            "tags": [
                "social-token",
                "community-token",
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "3NVZ9c5f8SSbR6kbhbhq8JQeJknVoiLJBtAXhF4bUUAM",
            "symbol": "UML",
            "name": "UML token",
            "decimals": 0,
            "logoURI": "https://github.com/johnson1776/crypto/blob/main/uml_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7bJvXMnUfVKAJPUgESEVE3U8bkUGTBSD72rZ8xUbbE9X",
            "symbol": "BLSF",
            "name": "Blocksafe Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexiuadrian/BlocksafeCoin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "Adm58Jgzb2qMLnMGMYyT91TM1ueLQvQDdMqAkte5F1Cq",
            "symbol": "BLOCK",
            "name": "Block",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SergeyDobrinyn/token/master/files/cube.png",
            "tags": [
                "social-token",
                "currency-token",
                "community-token",
                "trading-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/8nHcQFbPE3"
            }
        },
        {
            "chainId": 101,
            "address": "5ESQrFxPuciHdMTAP3dr2VdwrQ2wczgYq8rigteEGMsk",
            "symbol": "CHCHC",
            "name": "ChoChoCoin",
            "decimals": 0,
            "logoURI": "https://github.com/claxpum0n/crypto/blob/main/chochocoin_logo_img.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8PZzRtXTFUGmeWboeKbTuWYuALupGAS47dSKDmbxE5K3",
            "symbol": "PNGN",
            "name": "Penguin token",
            "decimals": 0,
            "logoURI": "https://github.com/p1otek/pinguin/blob/main/pingwin_solana.jpg",
            "tags": [
                "social-token",
                "nft-token",
                "community",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/p1otek"
            }
        },
        {
            "chainId": 101,
            "address": "G6x16VmcKduR9yeSWhzR94mnEDKWgt89PtGW5QncZXvR",
            "symbol": "WRBK",
            "name": "WarlockBucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WarlockOfWifi/crypto/main/WOW_FACE.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BekJYnrW64iEx8bSrk8LXpFN1UoAiAgmYMvyrgQCbeFM",
            "symbol": "BRD",
            "name": "Briskd token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BekJYnrW64iEx8bSrk8LXpFN1UoAiAgmYMvyrgQCbeFM/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/briskd.cc/"
            }
        },
        {
            "chainId": 103,
            "address": "kbWpPDaa7h8ggHpFHbgZy4p4Y26pe4uv1TrkR58GssU",
            "symbol": "BRIC",
            "name": "BridgemanCoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/rubru70/hello-world/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E9AsWRryDRKRo9AVgDx4idGuwUJLSGQB6u4UsZz9s3D6",
            "symbol": "SBSI",
            "name": "Sebsi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kevinianbrady/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E1s2muWwiLT2n3EQUL27hgviaPRRXWkpXD7ShpfgRvVz",
            "symbol": "CAC",
            "name": "Cosmic Ape Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1s2muWwiLT2n3EQUL27hgviaPRRXWkpXD7ShpfgRvVz/logo.png",
            "extensions": {
                "coingeckoId": "cosmic-ape-coin",
                "twitter": "https://twitter.com/cosmicapesnft",
                "website": "https://cosmicapes.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "GBDsHu7SMev6Q2DdFyU6YPkjRBiPVqWuFmsJyrsLLrUX",
            "symbol": "TOPIA",
            "name": "Topia Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/apetopiadev/logo/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "EuYA8VbAKgMfWuCRTQ4QGVBeBxgtaAwWTE7svhX4tvYN",
            "symbol": "HNEC",
            "name": "Henen Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rodricba/logochico/main/logook.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CfarmpcJk5aKW1vqaFwAeTYam7BZx45huLmZ6XtLm7kH",
            "symbol": "RFC",
            "name": "Rafac",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rafac-io/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "nft-token",
                "trading-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AgDGJHx2nsrnuouQ1p5f5wCy3bgBAppuuxR82ToUVGeg",
            "symbol": "VR1",
            "name": "VR One",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AzureVein/VR_One-Token/main/logo.png",
            "tags": [
                "utility-token",
                "company-token",
                "MetaVerse",
                "social-token",
                "community-token",
                "payment-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/VROneKoszalin",
                "github": "https://github.com/AzureVein",
                "website": "https://vr-one.pro/"
            }
        },
        {
            "chainId": 101,
            "address": "D7txa12HUtWk5dVxC2Ybg9QoE7ALZgDcUEKdrh43V8fB",
            "symbol": "GRLR",
            "name": "Growler Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D7txa12HUtWk5dVxC2Ybg9QoE7ALZgDcUEKdrh43V8fB/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Receive Growler By Holding your Leopard NFT",
                "twitter": "https://twitter.com/LeopardSNFT",
                "website": "https://leopardsyndicate.com/"
            }
        },
        {
            "chainId": 101,
            "address": "F31ccScAXyf39DzSpRYKdEGyZmPihqGEtKhmvLddYD11",
            "symbol": "RRCT",
            "name": "RR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rrgrow/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EPF1DDVYQFBYJHj66UVx3WztjvAp8V76FiTmCuuAqvyi",
            "symbol": "OLC",
            "name": "Oli Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/olicoin728/olicoin/main/oli.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "13zQLEjHaj8s4yuSH6yKBcCfKhNaGZiRHpNG2dCZCQsS",
            "symbol": "PNGS",
            "name": "Penguins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/p1otek/pinguin/main/pingwin_solana.jpg",
            "tags": [
                "social-token",
                "nft-token",
                "community",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/p1otek"
            }
        },
        {
            "chainId": 101,
            "address": "HgWt1oMYfnwYD4ttMBXnyzbZrmNiZheMjWHYzRC5diCL",
            "symbol": "DPT",
            "name": "Dioptase",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KatelynKunzmann/crypto/main/NFT_Dioptase.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3CvYgtGwHWRdsVcgWZHXXtiDfpSWWp83kefLCpnpCEq9",
            "symbol": "RZR",
            "name": "Rickemizer",
            "decimals": 9,
            "logoURI": "https://avatars.githubusercontent.com/u/8443515?s=96\u0026v=4",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CZtYQvMEQdtFFdF39PtMxGVMditE76AwbXqJYFEhYFvA",
            "symbol": "ACN",
            "name": "Acorn",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/VortexHowl/Acorn/main/30E9C0AF-8474-4B33-876E-609E2D5E189E.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6WvTvuxvaxpzpgmumiXuow6fSxELDb3NhecqUaegJWox",
            "symbol": "RMT",
            "name": "Red Moon Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AkshayPatel-360/RedMoon/main/RedMoon.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fjnvzm5iFULHrLGp6FNisrE8ZQvZbKR8d6VcsGkXgxxx",
            "symbol": "MOA",
            "name": "MoaLetters",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jacob-hanbeen-kim/moa-crypto/main/moa.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5CMusULTFeuRYkwgMnhSthNZ7KjRT55Xj9S56oCbDzBc",
            "symbol": "CRW",
            "name": "CREWPTO",
            "decimals": 0,
            "logoURI": "https://github.com/joerojasq/crypto/blob/main/PHOTO-2021-06-11-11-38-33.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9tbt9vQ3oHTtMG1E15fXnvSiGEJFHXx82oN4wwSJmLmt",
            "symbol": "MACT",
            "name": "MacToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/macdogxyz/mactoken/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DX2xEgbu96h3MVHdR6ygoV9gUaXy2z1Pj9mpCSh29TEd",
            "symbol": "SCCN",
            "name": "Shred Camp Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gamegenie1/cryptoLo/main/CoinLogo.png",
            "tags": [
                "utility-token",
                "company-token",
                "MetaVerse",
                "social-token",
                "community-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EmU2juRehuHHn3p2qwMbrPiupXdc3JrZdTD1aP5zyhrW",
            "symbol": "GRF",
            "name": "Graef Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/segraef/graef.io/main/logo.png",
            "tags": [
                "utility-token",
                "company-token",
                "meta-token",
                "social-token",
                "fabric-token"
            ],
            "extensions": {
                "github": "https://github.com/segraef",
                "website": "https://graef.io/"
            }
        },
        {
            "chainId": 101,
            "address": "J7BTo2oJoWhceyKqhNRotHT8YV1T5Zk5jzbBtz5tiD6W",
            "symbol": "MEMI",
            "name": "Metacraft Mineral",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J7BTo2oJoWhceyKqhNRotHT8YV1T5Zk5jzbBtz5tiD6W/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://stormgate.io"
            }
        },
        {
            "chainId": 101,
            "address": "2ybigq4qCufSSZTkn8bYZUD9nmbVoyx6XMFG8hYZo7FT",
            "symbol": "PEPC",
            "name": "Pepi Coin",
            "decimals": 0,
            "logoURI": "https://github.com/cheliviris/pepicoin/blob/main/PepiLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "812oQWKKDDyY6sFuPQjriMQqGRXtC563pBi7pPDWWSNh",
            "symbol": "$ABS",
            "name": "Stake Bot",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/812oQWKKDDyY6sFuPQjriMQqGRXtC563pBi7pPDWWSNh/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/RobotStake",
                "website": "https://stakebot.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "DpDFmEDVcy1AgZfCZqHq7DBQV6GjT4N1BNJF6kvgVeA5",
            "symbol": "CANZ",
            "name": "WebCanCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DpDFmEDVcy1AgZfCZqHq7DBQV6GjT4N1BNJF6kvgVeA5/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7LiQxpMKJzHqwx2dCErLF3v4sqjErgP1WJqRsfu3hK5",
            "symbol": "IMAG",
            "name": "Imaginary",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anticausal/crypto/main/imaginary/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "Evup5hpQjUYYCwjVKeMsoyBztq11hgBb75rK4ZXWpvvL",
            "symbol": "SRP",
            "name": "StarPunk",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hqphuoc129/SRP_logo/master/logo.svg"
        },
        {
            "chainId": 103,
            "address": "Evup5hpQjUYYCwjVKeMsoyBztq11hgBb75rK4ZXWpvvL",
            "symbol": "SRP",
            "name": "StarPunk",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hqphuoc129/SRP_logo/master/logo.svg"
        },
        {
            "chainId": 101,
            "address": "DaK2nYgbaJgGvGz5R1t9apm3FudTb4pmSJZoJ95CGyxg",
            "symbol": "HOWI",
            "name": "Howie Coin",
            "decimals": 0,
            "logoURI": "https://github.com/showarth/hcoin/blob/main/Howi.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FuXCCJrNgrAy5hV6c12o4KHg5pJ6K4MFcZZz5FRHrxt7",
            "symbol": "HEARTS",
            "name": "Hearts Art",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Hearts-Art/HEARTS/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://hearts.art/"
            }
        },
        {
            "chainId": 101,
            "address": "7jgwj5z8ZHBKVC2NRsVrvZTpTTgn99PcckUNPTiZFMGH",
            "symbol": "TEMT",
            "name": "TermiToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ItsEge1110/TermiToken/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "TermiToken is the one and only token to be paired with your own TheToxicTerminator NFT!",
                "discord": "https://bit.ly/thetoxicterminators",
                "instagram": "https://www.instagram.com/toxicterminators/",
                "twitter": "https://twitter.com/ToXiCtErMiNaTR",
                "website": "https://www.thetoxicterminators.com/"
            }
        },
        {
            "chainId": 103,
            "address": "DxGGS7cVsmihX8QUcLriWokXih6Kwo7PviVHWEYDhbua",
            "symbol": "FTK",
            "name": "Flame",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/Flame/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3LKZU3iQx9KM94S4uYRdYwAHTm6odDyzGQqTBNj7J27z",
            "symbol": "atLUNA",
            "name": "Wrapped Luna (Allbridge from Terra)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV/logo.png"
        },
        {
            "chainId": 101,
            "address": "6iFUWMJjaK2C2aRknmp5bYGqpMWUvfc6ySMY91HEJqNc",
            "symbol": "THMP",
            "name": "Thimp Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Thimp-com/uploads/main/logo.png",
            "tags": [
                "social-token",
                "util-token",
                "web3"
            ],
            "extensions": {
                "github": "https://github.com/Thimp-com"
            }
        },
        {
            "chainId": 101,
            "address": "2aFZVztpssp5WjZuucrYVB8gQbR83KH3htsgL8NH4K4s",
            "symbol": "WWW",
            "name": "Wassie World Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2aFZVztpssp5WjZuucrYVB8gQbR83KH3htsgL8NH4K4s/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://wassieworld.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "8NL1sfPUAJeea9hZ8m51Ps7DAtn4K1yJnELBZmdx3Nhv",
            "symbol": "GOIN",
            "name": "GO-IT School Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nikolai-sych89/goin/main/coin.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/goitschool",
                "instagram": "https://www.instagram.com/goitschool/",
                "website": "https://go-it.top/",
                "youtube": "https://www.youtube.com/channel/UCCtmmzdaQq56zRt2BjJIlpA"
            }
        },
        {
            "chainId": 101,
            "address": "GWdwqCFssJANE2a9R3aiD7KbvnHKgaK46d3x85paB58W",
            "symbol": "BEER",
            "name": "Beer Run",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tkemet/beerme/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9sdES9rkaTT98vmjpcrBjNg6XouDSKUL853PK6mT2cv4",
            "symbol": "DIKK",
            "name": "Dikkenek Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9sdES9rkaTT98vmjpcrBjNg6XouDSKUL853PK6mT2cv4/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DikkenekCoin"
            }
        },
        {
            "chainId": 101,
            "address": "FdV4xsPt95cj3TG4fgRwoJLYDqjNuhLHsqgsNhgtYoLV",
            "symbol": "BJ",
            "name": "Blowjob Token",
            "decimals": 9,
            "logoURI": "https://filedn.com/lWipyq8QQlwLMXQXJcXVB90/bj3.png",
            "extensions": {
                "website": "https://blowjobtoken.art/"
            }
        },
        {
            "chainId": 101,
            "address": "Ew42Lk7Lv1hiE2YouLWZypcf2DLDiobdbnFqzRmpy79L",
            "symbol": "TRMC",
            "name": "TermiCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ItsEge1110/TermiToken/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "TermiToken is the one and only token to be paired with your own TheToxicTerminator NFT!",
                "discord": "https://bit.ly/thetoxicterminators",
                "instagram": "https://www.instagram.com/toxicterminators/",
                "twitter": "https://twitter.com/ToXiCtErMiNaTR",
                "website": "https://www.thetoxicterminators.com/"
            }
        },
        {
            "chainId": 101,
            "address": "JC6EaHt8qYa6hnLG9ctniu5dZH4XniSRuVQsKnkvJHGB",
            "symbol": "PXC",
            "name": "Promenix Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/reverdemoi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CFkiqiAD1cJDLqGXHh1iLGPVMiwr1ajPW4nWPwsgGWit",
            "symbol": "BLCN",
            "name": "Balancana NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lovresqu/crptos/main/eggplant-2924511_640.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "One and only Balancana non fungible token.",
                "imageUrl": "https://raw.githubusercontent.com/Lovresqu/crptos/main/eggplant-2924511.png"
            }
        },
        {
            "chainId": 101,
            "address": "69Aa3N6iegen7AxqhvtnLCWyZ2WbwTjE41SyZ78iFRHv",
            "symbol": "GJSMS",
            "name": "Green Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Greenjuicesystems01/Greencrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2wanHD7yyhb6eeXZengZr6AKBwQCXBHFkwkpba6cTr1F",
            "symbol": "SPTACO",
            "name": "Spectrum One Taco Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ptolemaeusJS/solana-token-sptaco/main/133_The-Power-of-Tacos.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ft7YHe54mH3J5RM9D5oU6auzhCVt75AjtSvcRB3VTAHC",
            "symbol": "JRCN",
            "name": "JR Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jrcrayne/jrcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3MzNXz3jb6yUXAZXf8pPkMnjQo4vtkcuFgWH7k9ShWQQ",
            "symbol": "DM",
            "name": "DogeMask",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DogeMask/CRIPTO-MASK/main/Untitled.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HMYxhA2ZeSpFjitzP58K7MMJKbKkBuj4P5PHbsmo3rTF",
            "symbol": "TFBK",
            "name": "TFBK",
            "decimals": 9,
            "logoURI": "http://youngtech.fr/wp-content/uploads/2022/01/logofineblock.jpeg",
            "extensions": {
                "assetContract": "https://explorer.solana.com/address/HMYxhA2ZeSpFjitzP58K7MMJKbKkBuj4P5PHbsmo3rTF/largest",
                "description": "TFBK is A token to unlock the loyalty potential of businesses",
                "website": "https://fineblock.eu/"
            }
        },
        {
            "chainId": 101,
            "address": "5iipcUAdVoX6tyJBRinkhoen4jctGsv4HvdE23VA9QJ1",
            "symbol": "FFC",
            "name": "Fabulouscoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/traliotube/My-code-snippets/main/image-removebg-preview.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8TBDkT3Gu2HK8dwZ9YREg7yfGdq89rVJfPcpRDRWpF9C",
            "symbol": "NRA",
            "name": "Nracoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nraglobal/newcoin2/main/nra_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BYm7NHv2uyXR4fxaLphNGdie8csW9xbSofCsyZkkyYVF",
            "symbol": "BLPY",
            "name": "BULL_PYRAMID",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bipultiwari26/crypto/main/BULL%20PYRAMID.png",
            "tags": [
                "social-token",
                "community-token",
                "family-token",
                "friendship-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr",
            "symbol": "CMFI",
            "name": "Compendium Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "DeFi",
                "Exchange",
                "Application"
            ],
            "extensions": {
                "coingeckoId": "compendium-fi",
                "discord": "https://discord.gg/64r2xtqczs",
                "medium": "https://compendiumfi.medium.com/",
                "serumV3Usdc": "3Mf3bxVS2zLW3bbr9BNbqdiizaUwCGwoi3xhrAXfbFnW",
                "twitter": "https://twitter.com/CompendiumFi",
                "website": "https://compendium.finance/",
                "whitepaper": "https://compendium.finance/litepaper"
            }
        },
        {
            "chainId": 103,
            "address": "CnSfJEVhiysH7ZcB1AZ7ztUKmMxWvkAmGH1qn68Pq5NK",
            "symbol": "OLB",
            "name": "SOL BOOL",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CnSfJEVhiysH7ZcB1AZ7ztUKmMxWvkAmGH1qn68Pq5NK/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A9gSNY2yVTsi8sjYXDDUpUWMCBq9JvJG44ymA3GiKQWH",
            "symbol": "BLNK",
            "name": "Bliiink Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9gSNY2yVTsi8sjYXDDUpUWMCBq9JvJG44ymA3GiKQWH/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Bliiink is a blockchain community-driven music streaming platform connecting artists, influencers, and fans.",
                "instagram": "https://instagram.com/bliiinkmusic",
                "twitter": "https://twitter.com/bliiinkmusic",
                "website": "https://bliiink.co.uk"
            }
        },
        {
            "chainId": 103,
            "address": "2zSBuTeYKYyRSyAQ2XW1VkaiqtkAe12Nz6VkqFKZEnKe",
            "symbol": "PERKS",
            "name": "PERKS",
            "decimals": 8,
            "logoURI": "https://next.perkhero.com/token.png",
            "extensions": {
                "website": "https://www.perkhero.com"
            }
        },
        {
            "chainId": 101,
            "address": "2rAcQUyAZgxq5hNHVRFRiM4YTzPi9gTcPjjRH16DAtf5",
            "symbol": "SAV",
            "name": "Harsh Ghodkar",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/71517788/148593300-41bf340f-f20b-4f72-9f86-30587eaaa708.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AB94vBf7vyPENfUYEhRXxnWBfCr49mYunsXb72mrHh2v",
            "symbol": "CAM",
            "name": "Caramac",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Scott-Mackey/caramac/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBsctTyy8o7gqK5RSyj9eDJvN3cUH5bTGB2vpmc4g8dA",
            "symbol": "KOBRA",
            "name": "Kobra Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ATLTuck/KobraCoin/main/logo.png",
            "tags": [
                "utility-token",
                "company-token",
                "social-token",
                "community-token",
                "payment-token"
            ],
            "extensions": {
                "github": "https://github.com/ATLTuck"
            }
        },
        {
            "chainId": 101,
            "address": "FcJqJBBhfSHtX2tboNjLCpgQCXzJtqD4jkyEWmauFtLs",
            "symbol": "ZUL",
            "name": "Azul",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TheHelper2Azul/token-list/main/assets/mainnet/FcJqJBBhfSHtX2tboNjLCpgQCXzJtqD4jkyEWmauFtLs/logo.png",
            "tags": [
                "social-token",
                "stablecoin",
                "community-token",
                "lp-token",
                "metaverse-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/eremod"
            }
        },
        {
            "chainId": 101,
            "address": "AfGaVaWuGdKcbdzTTFvh1Haatw8DpaHQnouY3fRJEXyz",
            "symbol": "XEN",
            "name": "Xenium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheXeniumToken/Xenium/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9A6dgXm79ASFyG42tui86F4gQTC56Ydw3mrNL61xhdr",
            "symbol": "CRB",
            "name": "Cerebro",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9A6dgXm79ASFyG42tui86F4gQTC56Ydw3mrNL61xhdr/logo.png",
            "extensions": {
                "website": "https://cerebro.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "C2WpsuzfcxutC9Eabad2hptVu5AYLbApMDXG8pMTfiZV",
            "symbol": "HUZ",
            "name": "HUZI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lahsini/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6gAfvRhDCQ2hq2sBEuuDay2xJR9mTTApc4S4M58TPUGP",
            "symbol": "LILU",
            "name": "Lilu coin",
            "decimals": 0,
            "logoURI": "https://github.com/nkloga/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KrBBFTDLguJdmaicTqneYmHNbZrmeYBG1RMj5DXhTD2",
            "symbol": "BLOP",
            "name": "Royal Blop",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sastrup85/Crypto/main/Blop.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EWpafQPbyz72h1degKhLTozmPZ1LGtMgH3KpQKkuUD7v",
            "symbol": "S2COIN",
            "name": "SQUAD2 COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sc4rn4/crypto/main/coins2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cs6mFUDLT5CeHpodueDTZZppqHv9Mz1WWAcKTHU85ruk",
            "symbol": "EZNFT",
            "name": "EZ NFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ez-nft/token-list/main/assets/mainnet/Cs6mFUDLT5CeHpodueDTZZppqHv9Mz1WWAcKTHU85ruk/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "cXDYD5SkjTbJ3qeYr8n3pNXSSqa2Ht6UEeEzDhoxYzh",
            "symbol": "PCC",
            "name": "Pancake Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/godspeed110308/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2upsex3K1RLcsokoUA4CNcerV6aWTTja3LH5bS9626vR",
            "symbol": "SPIT",
            "name": "Spite Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bellasar/Spite/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7EnFmDwqvEh6iobuRhj3K7m4WQueeFZfZws8qeFMAzoh",
            "symbol": "TRST",
            "name": "TrustToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/barkour/open/main/BARKOUR%20copy.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9BmPGtFvmm57QgaUEdUhe6DE726XMKM1vh7DpYtV7jiE",
            "symbol": "EXP",
            "name": "The Expo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9BmPGtFvmm57QgaUEdUhe6DE726XMKM1vh7DpYtV7jiE/logos.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HysJKMMQ4G6oEffMhf55ZPRc28zwCmpmZr63vAfUrrBq",
            "symbol": "MORPH",
            "name": "MORPH",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HysJKMMQ4G6oEffMhf55ZPRc28zwCmpmZr63vAfUrrBq/logo.png"
        },
        {
            "chainId": 101,
            "address": "Ez2aLpUkF9mouaSedYvtaUwE29mpQBDWhVsRk6tD9HuH",
            "symbol": "CAPP",
            "name": "CapitalistPanda",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/igakubisa/capitalist-panda-/main/sneakycaptalist23.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AVERsCxn9wr9YZ4WVavPbjm13hrLTPAkdnu1QqK9ZL1y",
            "symbol": "AVER",
            "name": "Aver",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVERsCxn9wr9YZ4WVavPbjm13hrLTPAkdnu1QqK9ZL1y/aver.png",
            "extensions": {
                "discord": "https://discord.gg/KWZuB5E7AP",
                "medium": "https://averbet.medium.com/",
                "telegram": "https://t.me/OfficialAverBet",
                "twitter": "https://twitter.com/AverBet",
                "website": "https://aver.bet/"
            }
        },
        {
            "chainId": 101,
            "address": "2r59YbkX1Tv8mGSM7D56y2RHpWHTjVj6NSZ9WQu6621R",
            "symbol": "SAS",
            "name": "SheerazCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GhostImpact/crypto-Image/main/my-crypto-imge_optimized.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ELEhJznM6WZdR2CVnnkT3Yyj8NUGJpq186tr2MvBKKND",
            "symbol": "RCT",
            "name": "Real-Gold-Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/hhhogannwo/Real-Gold-Coin/main/money.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DJWYzyVsuzfprR5hkmW98nmXgQ8CK6rJWhxswj4CmQEb",
            "symbol": "$SQUEAK",
            "name": "SQUEAK Token",
            "decimals": 6,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/8a60559c-fe41-4a52-3210-6685125f1800/public",
            "extensions": {
                "discord": "https://discord.gg/uXveyZPhvH",
                "twitter": "https://twitter.com/FerretFaction",
                "website": "https://ferretfaction.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8FPa3VSqVTBqdZ6B4CbenKPNWiJB2U6gS5JKMNBZDTiQ",
            "symbol": "MIXI",
            "name": "Mixis Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FPa3VSqVTBqdZ6B4CbenKPNWiJB2U6gS5JKMNBZDTiQ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "22V59o2P9bRwTeMbbLtjrWrkZXy1bMJifuhGW16nA7sX",
            "symbol": "BCOIN",
            "name": "BartekCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/keirrr/crypto-bartekcoin/main/BartekCoin-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GdfXd3oDwT7eEPpxG136o1WnPgFaYcJbNjZQ4irbwFLo",
            "symbol": "PTCN",
            "name": "Pete Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kayyo321/Pete_Coin/main/peteLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5sqYqqRqs2uTN419mrxQzKmHru6BsChvH1WUHHuxTD1n",
            "symbol": "MARC",
            "name": "Marce Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/YOPDigital/marcecoin/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H8bFy62L3ZmUfA3Q4anvneF9nbGjQGce7QTsiXkpCk9y",
            "symbol": "SHARK",
            "name": "SharkyToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HustlinSharks/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "nft-token",
                "community-token",
                "play-2-earn"
            ]
        },
        {
            "chainId": 101,
            "address": "Dw5SHhyNha87c91Fkf5jDj8g5EDsdTQDxt3UBbuSGQPf",
            "symbol": "HYR",
            "name": "HyReli",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TjCicale556/Logo1/main/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F9ZsJAyX4SaqMbFqiixDk1RXRUkJ9d9zeC5tYjyn4v5z",
            "symbol": "IZU",
            "name": "IZUNO Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F9ZsJAyX4SaqMbFqiixDk1RXRUkJ9d9zeC5tYjyn4v5z/logo.png",
            "tags": [
                "NFT",
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi"
            ],
            "extensions": {
                "discord": "http://discord.gg/kam1",
                "medium": "https://medium.com/@kam1nft",
                "twitter": "https://twitter.com/KAM1NFT",
                "website": "https://kam1.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8oWxMbGkKQhrZHejQ9p1pobrc11Vu9wbUy8ByEC8EPt7",
            "symbol": "XAR",
            "name": "Xarible DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/juansebsol/xarible_logo/main/xarible_logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kPqxFYJUsq",
                "instagram": "https://instagram.com/xarible",
                "twitter": "https://twitter.com/xarible",
                "website": "http://www.xarible.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3cCHH2M9KE2jbvidCNJ5GaQCS5u9qTCAwnVyFFpVaKFK",
            "symbol": "SPIT",
            "name": "SpiteCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bellasar/Spite/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NSLWaDb57ah7L4YPjGrx1fpAsF3SmNYh2VeVjTxukz6",
            "symbol": "DKCHA",
            "name": "DIKCHAA NEBENG TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NSLWaDb57ah7L4YPjGrx1fpAsF3SmNYh2VeVjTxukz6/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dv3w4nZwpujrEW2WeQRco6ZicwzffPw12eRhHV3PUtm8",
            "symbol": "CV20",
            "name": "Introvert Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PwnedCrystal/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5SGv7LpujDvxiP7hGJMgpA5dt8tow3bQoasRnEa5r81D",
            "symbol": "HackA",
            "name": "HackA Token",
            "decimals": 2,
            "logoURI": "https://user-images.githubusercontent.com/27461460/148633297-9b4ee8e7-b1da-46a9-a156-7f13e5b808be.png",
            "tags": [
                "hacka-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://website.hackatalk.dev"
            }
        },
        {
            "chainId": 103,
            "address": "3jFvJtQnEvpXTZ3rkaP7gFWzxGSpN1hRg2FkHvKX8Qbo",
            "symbol": "XESQ",
            "name": "Xesque Dele",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3jFvJtQnEvpXTZ3rkaP7gFWzxGSpN1hRg2FkHvKX8Qbo/logo.png",
            "tags": [
                "NFT",
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi"
            ]
        },
        {
            "chainId": 101,
            "address": "57mDiJXNcrYDoLfwgtKXWSJgGDpP3HUEapTGofwBtM6K",
            "symbol": "SCRLA",
            "name": "Scrilla",
            "decimals": 0,
            "logoURI": "https://github.com/upsetk9/Crypto/blob/main/logo.png",
            "tags": [
                "NFT",
                "utility-token",
                "game-token",
                "game-currency"
            ]
        },
        {
            "chainId": 101,
            "address": "HLYUhd8cqWBGDgxaKS8aPN67WZX9nveUHuZqULNEA6zp",
            "symbol": "BOZO",
            "name": "BozoToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wahclan/BozoToken/main/logo_bozo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9neArAEpohuUcTZCcEfxGgRfVTgBqKqZWwx9QDMRcZTF",
            "symbol": "EDC",
            "name": "Edvin Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Vrakyax/crypto/main/logo.png",
            "tags": [
                "friendship-token",
                "family-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cr5CFLdtoKBLKa6LJrvAejn1HbyybuDdJjfeTjwbPjyV",
            "symbol": "EBA",
            "name": "Emmba",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsZfrs1iWh9CXzbpGUfUkRfs35KkUWXJH2S6Y9D272M/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4SpfLz9RrF55WKEUBHFcMssFexpmdhymhcDoPSpAEwvi",
            "symbol": "DOMOD",
            "name": "IDOMODI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SpfLz9RrF55WKEUBHFcMssFexpmdhymhcDoPSpAEwvi/logo.png"
        },
        {
            "chainId": 101,
            "address": "2dWWsdsYJ5fPJwm98963XmnknVjKH8cp2k7gGKJTfQC8",
            "symbol": "PGM",
            "name": "Pergama",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lucian2001/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "51dwnEnpJzQA4Y9b9ZDBeDCYuYBMszW3oNWhF9NmQ2jd",
            "symbol": "ZIRC",
            "name": "Zircoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Swimmoney/Crypto/main/2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5NepA4psgviSLWUmFo8VubuERGiRu89E1NS62mdNiFcV",
            "symbol": "EVA",
            "name": "EvaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MaskedPsycho/EVA-CRYPTO/main/eva_moneda.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Lb4YM1yuLg5kBfPe7ejJ9f1SWoS4DYFL2f8LonehBVE",
            "symbol": "HACKA",
            "name": "HackTalk Token",
            "decimals": 2,
            "logoURI": "https://user-images.githubusercontent.com/27461460/148633297-9b4ee8e7-b1da-46a9-a156-7f13e5b808be.png",
            "tags": [
                "hacka-token",
                "community-token",
                "hackatalk",
                "dooboolab"
            ],
            "extensions": {
                "website": "https://website.hackatalk.dev"
            }
        },
        {
            "chainId": 101,
            "address": "BjGJDwc4rbqL8XbZv84hfNWjXDVaw4MMekTuLcF79ecg",
            "symbol": "PHNC",
            "name": "Phoenix Homes Network Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TradeBuild/PhoenixHomesNetworkCoin/main/StreetLight.png",
            "tags": [
                "social-token",
                "NFT",
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CtRndGzvGwqPVVqkdquJvVnTpgg9oUwy1jAb6NMJM3Qu",
            "symbol": "EGEC",
            "name": "EgeCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ItsEge1110/EgeCoin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "description": "The one and only EgeCoin! Made by Ege Efes.",
                "instagram": "https://egeefes.com/instagram/",
                "website": "https://www.egeefes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5ANhsDeAivhohBfJM1mc5UnE9rwsJYAfaQhXDQ3geW3U",
            "symbol": "SUPER",
            "name": "SuperKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/supercjlee/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dh4wnMs2RKL3QAR4u2rTNC4n6MDSA57Hc2HRY5R9yMV1",
            "symbol": "LCC",
            "name": "LCC Elites Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Jxyden34/crypto/main/download%20(7).jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ALR2XbdBAdw7AKibMsTo71kvwUhAXsaxHJCb93dXMDPD",
            "symbol": "FERRY",
            "name": "The Lost Sheep",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BarryHaze1/token/main/token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AtEMmWJkSk1RWBwzXc3hGvzhJjsR95SprEqjiLxjWRnC",
            "symbol": "AtEM",
            "name": "AtEM COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AtEMmWJkSk1RWBwzXc3hGvzhJjsR95SprEqjiLxjWRnC/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/AtEMWORLD",
                "website": "https://atemcoin.art"
            }
        },
        {
            "chainId": 101,
            "address": "BhJjySvvXqRr25bmnae3sQBFUTJxvzSwct2JRash4AHa",
            "symbol": "WwC",
            "name": "Whitecoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rhorsford/token-list/main/assets/mainnet/BhJjySvvXqRr25bmnae3sQBFUTJxvzSwct2JRash4AHa/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AGi8C53fx6Wh6zMjjW9SmKv8atpWQaQW262GHPzbbP7z",
            "symbol": "MXSY",
            "name": "MaxisyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maxisycode/crypto/main/avarat_m.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "L9rviHfkbjUe2QyGhrfmgbMqDgMJqRjT3AVkrzKR5XD",
            "symbol": "CHAR",
            "name": "Sol Characters",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SolCharacters/Main/master/logo192.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "731jVgZtMo9RJSi9PfrmQT3Dq7vnW7AFVE9d1d8Xh7Qt",
            "symbol": "$SMGL",
            "name": "Smeagol token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mansziesel/token/main/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "description": "Smeagol token",
                "website": "https://smeagoltoken.mziesel.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G7ch9ue3G9zNN7odv8W3XhehRTNPMARCiJ6ka76915TK",
            "symbol": "CHIPPO",
            "name": "Chippo-Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/maikschoerling/crypto/main/Chippo-Coin_logo.jpg",
            "tags": [
                "friendship-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EToCnRYxgMkVeLddPEYecwUZs77E6U71YYwTdAzF58Q9",
            "symbol": "SOMAR",
            "name": "Somar Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/florin-liviu/somar/main/somar.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "S7Rp234e9thKFLL6FyfDNTvzG4VWwecHaDwyfkMtr4u",
            "symbol": "PR0V",
            "name": "Providers Groupe A1 shares",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Providers1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CgAJ7HJwntz738DngHUEFHFggHWhfYG6m6a4udHCJnds",
            "symbol": "DSNR",
            "name": "Designer Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CgAJ7HJwntz738DngHUEFHFggHWhfYG6m6a4udHCJnds/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://designerpunks.art"
            }
        },
        {
            "chainId": 101,
            "address": "W8hTcFbWTQR9gN1DdqYMnjqrAzVgRr9757C3DrgpLuf",
            "symbol": "FLD",
            "name": "Fieldcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sanderveld/coin/main/Fieldcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8nuRPzEdoJb3wBrnrvVtqEujKfBrfmEUBxaoBz94hqgR",
            "symbol": "WALLE",
            "name": "Walle Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Wallace-EXE/Solana/main/MakersMark.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HmwjiTu4rMNXoAsZeX91L6BN6q18era3P4ho9Qsv9KhG",
            "symbol": "ECO",
            "name": "Edanecoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/svansie/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H17Nfs2KPgMWjssb7ZwdT7zuocnLpvZvYbMeGwqK3Axd",
            "symbol": "GNR",
            "name": "Gnarcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GNARCOIN/Gnarcoin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HMxF3WbGSNL37LzD6ecPLWfZJNDwJss46XcRffBLLoAL",
            "symbol": "KA7I",
            "name": "Ka7ibrator Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ka7IO/solly/main/Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/Ka7ibrator/"
            }
        },
        {
            "chainId": 101,
            "address": "CkouhZpTakVUPAGkes1EkcoM5G9TTFMikKHQ2UFNFcvM",
            "symbol": "BBCN",
            "name": "BabaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MadhavMandala/crypto/main/Baba.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TRck3zHXCTyoAsiKPn1NyGb3i1mXFbB8JHheh7uFiVL",
            "symbol": "TRCK",
            "name": "BHSG Stake Tracker",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TRck3zHXCTyoAsiKPn1NyGb3i1mXFbB8JHheh7uFiVL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bountyhunterspaceguild.com"
            }
        },
        {
            "chainId": 101,
            "address": "23KNWdtDRhaX14qnzwk3AXiHzuxvwvs9BrQfL3GfKsUh",
            "symbol": "AIMW",
            "name": "AIM Wealth Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/23KNWdtDRhaX14qnzwk3AXiHzuxvwvs9BrQfL3GfKsUh/logo.jpg",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3tnvMcuxCEpQ2qkxJC5c1tNpcexaS9RuVk5kUrzqFjER",
            "symbol": "MWOW",
            "name": "Much Wow Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/venividivici24/muchwow/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B7S2ipLwnQzx8nDKiynhbhZJwwSbu5cjzUPJhHqPbpLy",
            "symbol": "JCC",
            "name": "Julius Caesar Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NinodeVeld/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4NDpSc97QF8jHdz37c98CtoubF5FK2rsZb8n4ZG5D4D7",
            "symbol": "ALX",
            "name": "Alex Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/florin-liviu/alx/main/alx.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZxH8wMX1fUVLw2gxCADSN19SuzPUkr1jSMNf3f1kfov",
            "symbol": "FL",
            "name": "Fuel Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZxH8wMX1fUVLw2gxCADSN19SuzPUkr1jSMNf3f1kfov/logo.png",
            "tags": [
                "gaming-token",
                "Betting",
                "Casino",
                "Gambling"
            ],
            "extensions": {
                "discord": "https://discord.gg/kongracers",
                "twitter": "https://twitter.com/KongRacers"
            }
        },
        {
            "chainId": 101,
            "address": "BEpMKK4eCKaLffmNy7hKr9hnRgPieYzV8DPBjXPkNhTQ",
            "symbol": "V8MECH",
            "name": "V8 Mech",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEpMKK4eCKaLffmNy7hKr9hnRgPieYzV8DPBjXPkNhTQ/logo.png",
            "extensions": {
                "website": "https://v8mech.com"
            }
        },
        {
            "chainId": 101,
            "address": "GZVS1bbPiiDqfuNymXEDbu9Am34uZx6MCzxBrp1P85K3",
            "symbol": "FRWK",
            "name": "FOR WORK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZVS1bbPiiDqfuNymXEDbu9Am34uZx6MCzxBrp1P85K3/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "81xHMuJEpmJcAoCSUT3YkkffWogCUQT5ymBDcrE99dTj",
            "symbol": "SBT",
            "name": "Spaarbot Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Spaarbot/sbt/main/SBT.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8VRoLHA21AgjY4XVFqrhSsMzzc5WQgxW9GSjwBh5EPCq",
            "symbol": "JPKC",
            "name": "Japko Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maxisycode/crypto/main/japkocoin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FQAoqS3fSyxZdNzssezgk7vBBFWweSRNFpCnM7pmFT3M",
            "symbol": "WORD",
            "name": "Babylon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EL-Magnific-o/Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AT6c9jrNBcnXDXzJvtCDq1e5Xtj36qQjezoJaxqpBpSh",
            "symbol": "KITC",
            "name": "Kitten Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GrumpyWolfpuppy/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "39JM7v8g7FRLphruZrQ38WPhYbGN53iNtkaFnzqvvBaL",
            "symbol": "KZM0N",
            "name": "KraZyMa50N",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KraZyMa50N/kzmn/main/logo.kzmon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJAeYtbByDcMBRvMSCR82KYJ1mSZBVqDRVE9FrtY6ViX",
            "symbol": "TEKI",
            "name": "TEKI",
            "decimals": 9,
            "logoURI": "https://arweave.net/9QUecXE-7AOmLcXLIJIjrTSy-JbTVltV92Y7VhCN8qM?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/TekikaNFT",
                "website": "https://www.tekika.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8drbiLyfY2e3nRX8nMxWrBmCB8HK6XcacMJ4tqtThM9n",
            "symbol": "IVNC",
            "name": "Irvin Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IrvinDelgado/cryptoLogo/main/CryptoLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CsuVrSifXUhkBa2dbb7c9FZesqAGg7LZTAZW5vVWJnDa",
            "symbol": "EROSA",
            "name": "Erosa Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ErosaCoin/crypto/main/ErosaCoin.png",
            "tags": [
                "utility-token",
                "company-token",
                "meta-token",
                "social-token",
                "nft-token"
            ],
            "extensions": {
                "github": "https://github.com/ErosaCoin",
                "website": "https://erosa.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9AjGdFLwFZWfPTdopep2BL14HbdcDJAKwNApDp8GDDpv",
            "symbol": "TNT",
            "name": "Top NFTs Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9AjGdFLwFZWfPTdopep2BL14HbdcDJAKwNApDp8GDDpv/logo.png",
            "tags": [
                "whitelist-ticket"
            ],
            "extensions": {
                "description": "Each ticket gives you access to buy one NFT on https://topnfts.cc/",
                "discord": "https://discord.gg/topnfts",
                "telegram": "https://t.me/topnftscc",
                "twitter": "https://twitter.com/topnfts_cc",
                "website": "https://topnfts.cc/"
            }
        },
        {
            "chainId": 101,
            "address": "5dpkidM6ofFspeqtncqpKvh3DGV3P8EybfNHJsiuD38b",
            "symbol": "LPMB",
            "name": "BLUUz Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LesBLUUs/BLUUz_Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "nft-token",
                "metaverse-token",
                "play-2-learn",
                "learn-2-earn",
                "growth-token"
            ],
            "extensions": {
                "website": "https://amiangles.com"
            }
        },
        {
            "chainId": 101,
            "address": "2AtnukeFFacsHXTTMwj2nA8tY2rVSJuAdEZYi6rjqnke",
            "symbol": "RPST",
            "name": "REPRESENT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AbhishekKale/sol/main/represent_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CNMvWLKc8r1wRrQ1Xws6v43x5ttTnaf2pccLkUqsfXVf",
            "symbol": "$REM",
            "name": "Guardian Remnants",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CNMvWLKc8r1wRrQ1Xws6v43x5ttTnaf2pccLkUqsfXVf/logo.png",
            "tags": [
                "utility-token",
                "company-token",
                "meta-token",
                "social-token",
                "nft-token"
            ],
            "extensions": {
                "description": "Guardian Remnants is the SPL Token you get when staking your Guardian NFTs",
                "discord": "https://discord.gg/guardiansnft",
                "twitter": "https://twitter.com/guardiansnft",
                "website": "https://guardiansproject.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DYqx6tLiF4r2J9QFw3uCRjLqWGj6K7TTPjaXmV6zVqmA",
            "symbol": "HZBZ",
            "name": "HazelBits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KzooPackFan/crypto/main/hazelbits_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5XYRirwUYbeeMNmiBcw1cZYmMKpwofAt77BxaqLaKWPo",
            "symbol": "EEZC",
            "name": "Samba Coin",
            "decimals": 0,
            "logoURI": "https://github.com/doyoubelieveit/sol/blob/main/token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AAixLvr77P8yKkb4d3wSzeCPGBjoAb1tAfTjG7MrxoKt",
            "symbol": "FTG",
            "name": "Reactium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AAixLvr77P8yKkb4d3wSzeCPGBjoAb1tAfTjG7MrxoKt/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "reactium"
            ],
            "extensions": {
                "description": "Reactium is a React application framework, and this is its community token.",
                "discord": "https://discord.gg/NwtHd4FZvf",
                "website": "https://reactium.io"
            }
        },
        {
            "chainId": 101,
            "address": "3hUt6kC4iMqVLCvDkq885YVCdqz2juwFu8pwL2VJDNUC",
            "symbol": "CRUSH",
            "name": "MetaCrush",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/santini23s/MetaCrush/main/logo.png",
            "tags": [
                "social-token",
                "metaverse-token",
                "Dating-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3mX43nH4dgvCLJZXSo1duzkSU6YcKXQGE56MUh3wUsko",
            "symbol": "GIAME",
            "name": "Giame Fajardo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gmfajardo/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7d2L3moARJhMP7uTrYuPW3d43hnSswGpwdGnXxia9kg4",
            "symbol": "GCWL",
            "name": "Galactikka Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7d2L3moARJhMP7uTrYuPW3d43hnSswGpwdGnXxia9kg4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "http://dsc.gg/galactikkaclub",
                "twitter": "https://twitter.com/galactikkaclub",
                "website": "https://galactikkaclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Eo8sDvxHMzKXvAJRoqHNFqL7LDmcP3ZGJRrrMo2Ku27P",
            "symbol": "ORi",
            "name": "Orisha Coin Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OrishaCoin/crypto/main/logoforCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2maDvG9nXGVstjdnsCZoSsNtjoda1SsZTLrHBVRgLR5F",
            "symbol": "CORE",
            "name": "CORE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2x4fH7kWNgSLpBjCuFBKKKiNHMcJb6fHs8ahQZUWJj1p/Core.png",
            "tags": [
                "utility"
            ]
        },
        {
            "chainId": 101,
            "address": "6maqSiQ6MBvrQuPASFJmAM1VqDrQVQ9PcvrtFYhgQbvr",
            "symbol": "ASKME",
            "name": "ASK ME TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sravannerella/askMeCrypto/main/ASKMe.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7DmySPgKTJGFqxyhVN8pivrJFWFKJQPRRvux3UXGZumz",
            "symbol": "SKOG",
            "name": "Skog Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SkogDev/skogtoken/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://skogdev.no/"
            }
        },
        {
            "chainId": 101,
            "address": "7G5KeJceruc7Kh6zYiwdXo7QF7kfHBie1anSfx3SA9MA",
            "symbol": "EDS",
            "name": "Ehecatl Dragon Souls",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Chirryrth/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DexAsMPG1GDrgHbmybp3G1gj84DYxbUnHYsf9g93PsDF",
            "symbol": "SEMBIT",
            "name": "sembit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/olinmama/crypto/main/sembit.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gm6szibJfB1ZzUxNYf85nXwFchugqTqNyE5fDwWfBc7K",
            "symbol": "ILU",
            "name": "ILoveU Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rjdrar/crypto/main/symbol-1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D81JUj1FwK1iNrXodm6fyQyHnNRaov4zknUjDHW4SYqe",
            "symbol": "AXC",
            "name": "AxCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GroovyDrew93/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8TNcijJ63HiA7aD2PswJcGDsxiJugkg9LvSFxmVFQ6y7",
            "symbol": "RALA",
            "name": "Strala",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stralatoken/strala/main/strala.png",
            "tags": [
                "utility"
            ]
        },
        {
            "chainId": 101,
            "address": "75cSjYPsTi5q1mLyc9wEnqpEJiVHWcy9jj8ce2JQqPvB",
            "symbol": "LRDCN",
            "name": "Lerd Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zurcas/LC_Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9PRwJDVYiMkHU2JatNzS3paZiGV3GHxLqvpRh46VgrN",
            "symbol": "STK",
            "name": "StatusKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/StathisKap/StatusKoin/main/SK.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2s8hVBfVKdsiUixaacxC2oEUtb8f7ejxyfRw98BTXA12",
            "symbol": "VALE",
            "name": "Vale Unleashed",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2s8hVBfVKdsiUixaacxC2oEUtb8f7ejxyfRw98BTXA12/logo.png",
            "tags": [
                "game",
                "utility-token",
                "gaming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/valeunleashed"
            }
        },
        {
            "chainId": 101,
            "address": "2mM18RXhqXJ7AYWtQ5iBrjyzsCfy14PvFfcde9Rwpw33",
            "symbol": "SLID",
            "name": "Sol Lizard",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WAHYU1236/logoorang/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaLizard"
            }
        },
        {
            "chainId": 101,
            "address": "DER1EFvpyDGE3GXwFX467VsxjR5HMwYQsBQdcSBRVZw1",
            "symbol": "HX",
            "name": "Heliosx",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Jguillen268/heliosx/main/logo-heliosx.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BDpgjGFi1xasBYEFPDxekLTnyU84j8hspMk6E8VBkP2V",
            "symbol": "PPC",
            "name": "Pinot Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDpgjGFi1xasBYEFPDxekLTnyU84j8hspMk6E8VBkP2V/logo.png"
        },
        {
            "chainId": 101,
            "address": "W1ZhQHoHnL5Rvt2eHZBzzZQ93Qpx1DpAmysvv7EmJo9",
            "symbol": "WIZ",
            "name": "Stakewiz",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/W1ZhQHoHnL5Rvt2eHZBzzZQ93Qpx1DpAmysvv7EmJo9/wiz-square.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Stakewiz is a Solana stats explorer and validator alerting tool that let's everyday stakers keep track of their validators.",
                "discord": "https://discord.gg/3JXdTavv6x",
                "twitter": "https://twitter.com/stakewiz",
                "website": "https://www.stakewiz.com"
            }
        },
        {
            "chainId": 101,
            "address": "9M6ceqS9nfEsrikzykCXVuEKZW9jbmGew7cfy8rFWK3M",
            "symbol": "HRT",
            "name": "Home Rewards",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IIorigindesignII/homerewards/main/logo_homerewards.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DJDL8BDftybBkddbvGuVuXsHY7PuTYZjSFgML1FB1miE",
            "symbol": "JSC",
            "name": "Jaroscoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Itzacartouche/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EKuVjHTHc9g8gvRZvjWLqgwD2bEsfxyaudmFfD9QGqMd",
            "symbol": "EMMATK",
            "name": "Emmanuel BRUNEAU NFTs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ImmoSearch/crypto/main/LogoNFT.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4BszBCzskD7T73movPVSm6gWScXZsqmisgVYo5HdCvmC",
            "symbol": "BB",
            "name": "BostonBionics Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bostonbionics/bostonbionics/03190f8dff48913d39ca486a60afd42fea990f6a/logo.jpg",
            "tags": [
                "company-token"
            ],
            "extensions": {
                "website": "https://www.bostonbionics.eu/"
            }
        },
        {
            "chainId": 101,
            "address": "nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7",
            "symbol": "NOS",
            "name": "Nosana",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7/logo.png",
            "extensions": {
                "coingeckoId": "nosana",
                "discord": "https://discord.gg/nosana",
                "github": "https://github.com/nosana-ci",
                "medium": "https://nosana.medium.com/",
                "telegram": "https://t.me/NosanaCI",
                "twitter": "https://twitter.com/nosana_ci",
                "website": "https://nosana.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AcRznQeMaFqotVikPYVzkH36yueawGCniMeeFmmmjPnW",
            "symbol": "FED",
            "name": "FedCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AcRznQeMaFqotVikPYVzkH36yueawGCniMeeFmmmjPnW/logo.png"
        },
        {
            "chainId": 101,
            "address": "4b3mWs4puEvyAmcWcPiZV1Expi9QwVt5jAhxoLnNzMw9",
            "symbol": "FITBOX",
            "name": "FITBOX Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/fitboxworld/solanacrypto/main/FBW.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "metaverse-token",
                "growth-token",
                "learn-2-earn",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://www.discord.com/invite/fitboxworld",
                "instagram": "https://www.instagram.com/fitboxworld",
                "twitter": "https://twitter.com/fitboxworld",
                "website": "https://www.fitboxworld.com"
            }
        },
        {
            "chainId": 101,
            "address": "7xfKgh8vtX2RrZn21wFTQSP9jsjh7Fqo8P4igYfmxxD3",
            "symbol": "ASDEX",
            "name": "AstraDEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ASTRADEX123/crypto/main/Logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "L7SA5ATrMm5kfLarfD4sJhVkQMhuXpcsprMicAZibk6",
            "symbol": "DAM",
            "name": "Chonky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexmcculley19/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5XzbcgnzjGByMuCh1hUZnzZvWJ6ekjqsnFs1mRtSyfuX",
            "symbol": "REICH",
            "name": "ReichCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ReichCorp/Reich/main/REICHCOIN-TRNSP.png",
            "tags": [
                "social-token",
                "utility-token",
                "Crypto-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "FuFrEUPoUTJCuTdnQ5sABFM3VaceFqJ7rigbYAG2YALu",
            "symbol": "HAC",
            "name": "Heroes Arena Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FuFrEUPoUTJCuTdnQ5sABFM3VaceFqJ7rigbYAG2YALu/logo.png",
            "tags": [
                "game",
                "utility-token",
                "nft",
                "gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/Qt7n5ha2fy"
            }
        },
        {
            "chainId": 101,
            "address": "8VbGtyibxmwax3u3adzcj6YY9TWzYaNi3PPgvtJ23DRP",
            "symbol": "ZTNHM",
            "name": "ZTNHM Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Zamakhchari10/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CtJtWd9cyM5dM6kpbaPj6QWv4ga9VhnRTAqjD3tjAkCi",
            "symbol": "GTRC",
            "name": "GTRCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/RustyGoatOC/crypto/main/Download.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HoGbvSustgSvZMyjbMKE3iNBbfrcATEYbbsaPwA9xxMp",
            "symbol": "$HOGLOT",
            "name": "Hog Lottery Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HoGbvSustgSvZMyjbMKE3iNBbfrcATEYbbsaPwA9xxMp/logo.png"
        },
        {
            "chainId": 101,
            "address": "8XRXCe1SasJcsU2EAmcW9yJhK1f2Z2avGCEhyNjPD2SD",
            "symbol": "DMTS",
            "name": "DMuertos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Alvindaley/dmuertoscoin/main/DMuertos200.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token",
                "nft-token"
            ],
            "extensions": {
                "description": "DMuertos NFT Token",
                "twitter": "https://twitter.com/DMuertosNFT",
                "website": "https://dmuertos.com"
            }
        },
        {
            "chainId": 101,
            "address": "GRYvuPE1zVZHZeJMu6q8UGmC55ej6H9qqymGUaQDcNuq",
            "symbol": "WRKT",
            "name": "Wracker Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nexfortisme/asset-store/main/icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CbU2bWHoy73HqCm9PQjGcniaxhFkQ65zWTJyUfNU5694",
            "symbol": "EURONIN",
            "name": "Euronin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CbU2bWHoy73HqCm9PQjGcniaxhFkQ65zWTJyUfNU5694/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/euronin.io/",
                "website": "https://www.euronin.io"
            }
        },
        {
            "chainId": 101,
            "address": "CAaTZn6zQYiiuBWGjDDxrbQPZXRbJsZ2NVeX2A8gTFMG",
            "symbol": "SPARKY",
            "name": "Sparky Papillon Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAaTZn6zQYiiuBWGjDDxrbQPZXRbJsZ2NVeX2A8gTFMG/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "85o7Co7jurn2Up4uBUhNamhEyp6FN7Tc6fm3XMa883CK",
            "symbol": "TMT",
            "name": "Tommy Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/detectivevoke/crypto/main/Black%231.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "Tommy Token"
            }
        },
        {
            "chainId": 101,
            "address": "51BpgNQaFHVZaXorub35SqXFZ8phvRSNpmTZBSwSdzc5",
            "symbol": "CFROG",
            "name": "Crypto Frog",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BartKmiecik/CryptoFrog/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5pAprHtXs9k4L3tYPVMGrUkMt24r9GN8Z7AkSbMooGNN",
            "symbol": "MLC",
            "name": "MallCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alinftminter/sol.mall/main/logo.pnj.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FhUtknp2SYWNJrbrpHDWr2nF6HpBVCUbehD7VWAy2Nt9",
            "symbol": "LDY",
            "name": "Lovely DanDy Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FhUtknp2SYWNJrbrpHDWr2nF6HpBVCUbehD7VWAy2Nt9/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://lovelydandy.ch"
            }
        },
        {
            "chainId": 101,
            "address": "G3Yp8RSzAKbkYyB8cX1RW1uRd8Vm7ZgfGvPxPiqnMXyU",
            "symbol": "HSKY",
            "name": "HSKY Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lukapiplica/cryptotoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2EKzQjEbEBqygpy7sqBk7E9Bj7NFsFJp9PDqqxTzVA2E",
            "symbol": "LILI",
            "name": "lilium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hamdielhamdi/token-list/main/assets/mainnet/2EKzQjEbEBqygpy7sqBk7E9Bj7NFsFJp9PDqqxTzVA2E/lilium.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D33w6Fc6m54oEx4h44BYXAWa2LGur1N7twafs9iBSVLj",
            "symbol": "GINKGO",
            "name": "GinkCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D33w6Fc6m54oEx4h44BYXAWa2LGur1N7twafs9iBSVLj/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8U4GvLbQqVbgAQMpkEQKNtF2B37KuxCXiuG6g2mxo6oC",
            "symbol": "LION",
            "name": "CryptoLions Coin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/jrwesty23/CryptoLions/main/CryptoLionToken.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "address": "8U4GvLbQqVbgAQMpkEQKNtF2B37KuxCXiuG6g2mxo6oC",
                "description": "CryptoLions Metaverse Coin",
                "discord": "https://discord.gg/aUYVJjVzdj",
                "facebook": "https://facebook.com/CryptoLIONsCanada",
                "medium": "https://medium.com/@CryptoLionsCan",
                "twitter": "https://twitter.com/CryptoLionsCan",
                "website": "https://cryptolions.ca"
            }
        },
        {
            "chainId": 101,
            "address": "78uaHEgTUELYBUbNX2FgijH64FudDJERGZHzrArSV3Le",
            "symbol": "PIDT",
            "name": "Pixel Drone Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78uaHEgTUELYBUbNX2FgijH64FudDJERGZHzrArSV3Le/logo.png",
            "tags": [
                "game",
                "nft",
                "mint",
                "mint-ticket",
                "ticket",
                "pixel",
                "pixelinvaderz"
            ],
            "extensions": {
                "discord": "https://discord.gg/ms4ZPsCVvr",
                "twitter": "https://twitter.com/pixelinvaderz",
                "website": "https://pixelinvaderz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Fm6Fb91iMW48S8cSJVVenMZDm1jS284r1a5z9264fvws",
            "symbol": "FRISCO",
            "name": "Frisco",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andyfife/crypto/main/frisco.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://milkywayfiasco.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2ReJfPzPVGAJQ9vc3Hk2GmWngwmJFLpPr3R3bjhzmKc5",
            "symbol": "SPEC",
            "name": "Speccoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hspecca92/crypto/main/scott.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9pkudFiG5qhiSQQ8XatPbxZj8zBQ5m4XzHsKHMAGkkCR",
            "symbol": "SERSH",
            "name": "Serenity Shield",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9pkudFiG5qhiSQQ8XatPbxZj8zBQ5m4XzHsKHMAGkkCR/logo.png",
            "tags": [
                "token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/serenityshield_",
                "website": "https://serenityshield.io"
            }
        },
        {
            "chainId": 101,
            "address": "B87x4tx7dKLxdupRcLxpNA6XafNHsHT5XkKAimj83BVu",
            "symbol": "PIP",
            "name": "Pippa Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Avran2611/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hg8Vzzodt1Hx6zM65g5v5p1bfW4NjP5UjqMJnqufa8MC",
            "symbol": "JoJo",
            "name": "JoJoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoJoModding/crypto/main/crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EYS73RNeyGmx9KR2me9ohZvzY1UhdUjLq3iqKH8vUGeH",
            "symbol": "JNC",
            "name": "Juni Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PTS3000/cripto2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GoLNtewUy5uRxQv8mdMgWQgNemvfuvMZMPWcsHnfkXQc",
            "symbol": "SHCT",
            "name": "Stajic Hoti Crypto Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/denizconnect/connectingcrypto/main/connecting.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CVhMRvUrskbG59EEpiCBRcYPrd9f9jWyPeBrU89rTBbR",
            "symbol": "CCNN",
            "name": "Call Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/towardsuffering/Call-Coin/main/Prototype_A_Call_Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AJFS2CD5GVB8ohiLY61NSzVAtMKSPYV67YCnXhyc9vXY",
            "symbol": "MHK",
            "name": "MYHHKEN",
            "decimals": 100,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsZfrs1iWh9CXzbpGUfUkRfs35KkUWXJH2S6Y9D272M/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BQmBMhxhAHuvdzPWTjv6Atj1HHLjsXrnwmWrsdYDtBD2",
            "symbol": "SURV",
            "name": "Under",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQmBMhxhAHuvdzPWTjv6Atj1HHLjsXrnwmWrsdYDtBD2/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "Undersurvival Token",
                "website": "https://linktr.ee/tacline_undersurvival"
            }
        },
        {
            "chainId": 101,
            "address": "4QBwTbXtvpL4ai9CHmmC7V88gAK2TKYhS5fTLGpVb7Fu",
            "symbol": "BMT",
            "name": "BumbierToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bumb1eris/BumbieruToken/main/pear2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A1nMCFUhHiV39FTaKZkzrQd6Q2UXZpVz9BeVvbddbpBV",
            "symbol": "LUNE",
            "name": "Lune",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/lune.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "FsSLBLTtCkRLwnEVzdNHiqasDWXkRfHAVqYHDX9gpzwU",
            "symbol": "SOLEIL",
            "name": "Soleil",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/soleil.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "4dmdcF11iZgj4qYSFV7Zd44jhws5YVApNZptBnsozNJx",
            "symbol": "MTMT",
            "name": "Moon Theory Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dmdcF11iZgj4qYSFV7Zd44jhws5YVApNZptBnsozNJx/logo.png"
        },
        {
            "chainId": 101,
            "address": "2BNi8eCPKataycG1qEVj9p3jAZs5t5Wq5Zfkgw9nX41f",
            "symbol": "GOOD2",
            "name": "GOODCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/good2bgood/good/main/goodlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ei8cio2iKfBErSCyHuGzvSqPQpDPd9qaxdE8nJYroX1B",
            "symbol": "DOPET",
            "name": "Dope Token",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/1009138/148701526-1836e263-2628-4264-a572-23b093324bbd.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/dope-cats",
                "twitter": "https://twitter.com/dope_cats",
                "website": "https://www.dopecats.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G4ctxjj7dV88HBnQ6Jx2bQeUGbgj9QpU2j4R2GNEWCMx",
            "symbol": "BBYTE",
            "name": "BinaryByte",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/binarybyte-net/BinaryByteToken/main/BBtokenlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EE6bUF6wpGCYmKbSTqmQParFa6imXAJiYUN5pA39r2Kz",
            "symbol": "DINAR",
            "name": "dinar coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/flstok/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2cUiM1QyKoTTMb5xssJ2dBx8jbGaKK8NPNnyVdLdtfCw",
            "symbol": "BBP",
            "name": "Belgium Beer Project",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cUiM1QyKoTTMb5xssJ2dBx8jbGaKK8NPNnyVdLdtfCw/logo.png",
            "tags": [
                "beer-token",
                "webshop-token",
                "belgium-token",
                "solana",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://belgiumbeerproject.be/"
            }
        },
        {
            "chainId": 101,
            "address": "J7cpg4uXDP4E9EYRLS5cZwJcy12sM86bPjawjNpohTHD",
            "symbol": "Good",
            "name": "SEMSoft",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/good2bgood/good/main/goodlogo.png",
            "tags": [
                "Social"
            ]
        },
        {
            "chainId": 101,
            "address": "514h2GS6K2vLx4Ru1D22Fj2oxEZ2MyGho2j8gjRbKHeE",
            "symbol": "OSNSO",
            "name": "osnso fun",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/514h2GS6K2vLx4Ru1D22Fj2oxEZ2MyGho2j8gjRbKHeE/logo.png",
            "tags": [
                "social-token",
                "osnso"
            ],
            "extensions": {
                "twitter": "https://twitter.com/goon_rush"
            }
        },
        {
            "chainId": 101,
            "address": "253TNECj84WknnpXPSdfb3uR3XxAviH6ZahKXYtVx9Yf",
            "symbol": "SOLRAE",
            "name": "SolRae",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KraZyMa50N/solrae/main/logo.sm.png",
            "tags": [
                "social-token",
                "nft",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8WDJHzLR94ZCiJdkeGHMUY3TdWuryWgTGgWM9XRCbUG4",
            "symbol": "EWS",
            "name": "Enterprise Web Service",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8WDJHzLR94ZCiJdkeGHMUY3TdWuryWgTGgWM9XRCbUG4/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/enterprisewsrvc",
                "website": "https://enterprisewebservice.com/",
                "youtube": "https://www.youtube.com/channel/UCiOimz60fahnbGEdTkN-fpQ"
            }
        },
        {
            "chainId": 101,
            "address": "Ctk8LtetjKQpTRoTfh1Pqgf2kxjdo6npuzRNgbsYgG1h",
            "symbol": "CST",
            "name": "Coin98 Stock Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ctk8LtetjKQpTRoTfh1Pqgf2kxjdo6npuzRNgbsYgG1h/logo.png",
            "tags": [
                "tokenized-stock",
                "utility-token",
                "Coin98"
            ],
            "extensions": {
                "telegram": "https://t.me/coin98"
            }
        },
        {
            "chainId": 101,
            "address": "6wbV1dKvPZZ68mKaF9v93Q2DtKXH71t7LdhNaD6z9Fbb",
            "symbol": "FFB",
            "name": "FreddyFazCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Peeperwidth/freddy/main/FreddyCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A1KWZzcUrsX9MAsL8XtZkuS4RX6fjZazc7K5UoX42M3M",
            "symbol": "ARTZ",
            "name": "Artozo",
            "decimals": 4,
            "logoURI": "https://artozo.mypinata.cloud/ipfs/QmdBoJK2HTRc7B71Xo36VqEXpME7JeexZPJkF1tamdQTTy",
            "tags": [
                "utility-token",
                "payment-token"
            ],
            "extensions": {
                "website": "https://artozo.com"
            }
        },
        {
            "chainId": 101,
            "address": "F14Cp89oAXMrNnaC4mKMNKHPWw2p2R4DRFAZEdJhUBkD",
            "symbol": "MOONBURN",
            "name": "MOONBURN",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/Joblo197/Crypto/main/Shroom%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HtGxBioB1v5vkfSJxkTh52rdYVLg72J1xdsurJS27ZJR",
            "symbol": "SOLINX",
            "name": "Solinx",
            "decimals": 0,
            "logoURI": "https://github.com/gdevsuper/sol-token-new/tree/main/assets/mainnet/HtGxBioB1v5vkfSJxkTh52rdYVLg72J1xdsurJS27ZJR/SolinxLogo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AtEMWORLD"
            }
        },
        {
            "chainId": 101,
            "address": "Cb2NVafBWfYwecmmJVGUetAVvJaiNahVMHbTEGMpanHi",
            "symbol": "PENNY",
            "name": "Penny",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cb2NVafBWfYwecmmJVGUetAVvJaiNahVMHbTEGMpanHi/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://itsthelabel.com"
            }
        },
        {
            "chainId": 101,
            "address": "CNTnf6FS8Vi3nPmoKUXxoU6MdoKwDgQnVA85kVxKs1CJ",
            "symbol": "PANAM",
            "name": "PhoenixPanam",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/phoenixpanam/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2QyXrDw26mq3Pw3C2Fswz22qPMVLSLg9BfqPy6PqmsMt",
            "symbol": "GREATLT",
            "name": "Great Legendary LT",
            "decimals": 6,
            "logoURI": "https://github.com/thanathos9558/token-logo/blob/main/logo.PNG",
            "tags": [
                "utility-token",
                "game-currency"
            ],
            "extensions": {
                "description": "GREAT LEGENDARY is a PLAY TO EARN game in which participants aspire to create an attractive community with a growing economy, focused on blockchain technology to play and earn rewards while playing.",
                "discord": "https://discord.gg/abQQGvUye2",
                "twitter": "https://twitter.com/GreatLegendarys",
                "website": "https://greatlegendary.com/"
            }
        },
        {
            "chainId": 101,
            "address": "D3Z4pdXyf2cXWURq3raLV18e7ZDm8BXCKGD42LuJLVaP",
            "symbol": "WDE",
            "name": "WDE Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MitchellSP/WDEcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CTejycdZJhT452dzi9mTfy2NgXwcpGarxtXLbh1D757Q",
            "symbol": "MKC",
            "name": "Monkey Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maindotmarcell/Monkey-Coin-Assets/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HS8aF3KJ959yAXxaGf68Ga1mARoQVGZTER4PyiSwmumq",
            "symbol": "DKT",
            "name": "DKToken",
            "decimals": 0,
            "logoURI": "https://github.com/gocho2021/crypto/blob/main/DKT%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B3HdvnP91cAZM6sVU1ZnZA1x388ZGQZyRH7hyHoxUuFh",
            "symbol": "DANNY",
            "name": "Danny",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B3HdvnP91cAZM6sVU1ZnZA1x388ZGQZyRH7hyHoxUuFh/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://itsthelabel.com"
            }
        },
        {
            "chainId": 101,
            "address": "BSyCt8JDqQjXTGvzyaiACyRcQ9fJdvrNbBwaF2QgTS3s",
            "symbol": "WCBT",
            "name": "War Council Battle Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thewarcouncil/wcbt-token/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AsDBSQte3gycEbzravDugoRQPxSWFXAm724itdJn3Gnc",
            "symbol": "BBWL",
            "name": "Brunch Bytes WhitedListed",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsDBSQte3gycEbzravDugoRQPxSWFXAm724itdJn3Gnc/logo.png"
        },
        {
            "chainId": 103,
            "address": "FPBXFrZ2HEKyc4yMeXJj5fpPbBa8DHzyRqx56sV1Lxno",
            "symbol": "INT",
            "name": "Inferno",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/Inferno/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8yQE3si8A36KJYHWy8yyFPyjKX63abXTToQLPuwsEbnm",
            "symbol": "COYN",
            "name": "Coyne Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/eckardtlo/crypto/main/NC.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DWnBMN8ec2Nk4koNGzWCAYee7gpWSXp3yTmwBsvRaxud",
            "symbol": "DEAN",
            "name": "Dean",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DWnBMN8ec2Nk4koNGzWCAYee7gpWSXp3yTmwBsvRaxud/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://itsthelabel.com"
            }
        },
        {
            "chainId": 101,
            "address": "8JDsyaAAwpTihZycq8FFSrXWHPbmiXpQXRZWaUnxBV76",
            "symbol": "WUCOIN",
            "name": "WUCOIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/wucoin0/wucoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3FYzcJvLeQubLuAgacV6sDu9Ye8Eg1vFYpCxD3ogp74M",
            "symbol": "CCG",
            "name": "Collectors Club Governance",
            "decimals": 9,
            "logoURI": "https://arweave.net/gBhj9k-4TGhwDwsnZxRQKMg_3ZQsvL1oeL0SUcrroPs",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/collectorsclub",
                "medium": "https://collectorsclub.medium.com",
                "twitter": "https://twitter.com/TheWatchersCC"
            }
        },
        {
            "chainId": 101,
            "address": "Czm5ySFYbcxsri15Hss2GYWa13CvbnUaeNm9ngJNkGvM",
            "symbol": "SLOT",
            "name": "Sloth Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maindotmarcell/Sloth-Token-Assets/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "883Umg7SBVNWfVE3EzUQFgo6UbEQGf9bj6qyDL4kJrY5",
            "symbol": "FNCN",
            "name": "Fan Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/eckardtl/crypto/main/fc.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HCBPNDD9iz683PszPBZzoy7xEub4GQ7rHBJxM57cLQpf",
            "symbol": "LNFTxTHUG0160",
            "name": "LNFT Thugbirdz-0160",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCBPNDD9iz683PszPBZzoy7xEub4GQ7rHBJxM57cLQpf/logo.png",
            "tags": [
                "liqnft-owner-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/THUG0160"
            }
        },
        {
            "chainId": 101,
            "address": "Dd7pji6EruuFPuAxuZG5LwZUdPSzYCTN6NsttrkHeedS",
            "symbol": "FLUFF",
            "name": "SolSamos",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solsamos/token-list/main/assets/mainnet/Dd7pji6EruuFPuAxuZG5LwZUdPSzYCTN6NsttrkHeedS/FluffIcon.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8JYfcavGunEGPTuLF4DHDeYjBt6gJmS9beQegPnTrhkH",
            "symbol": "NRG",
            "name": "eNeRGy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/unlimitedenergynrg/crypto/main/NRG-logo.png",
            "tags": [
                "game",
                "utility-token",
                "nft",
                "gaming"
            ]
        },
        {
            "chainId": 101,
            "address": "8ssNv1qPnN1UAfGzLjT9gDTPed2eqXsJgKq5wMB8ESan",
            "symbol": "$WHALES",
            "name": "INTERSTELLAR WHALES TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ssNv1qPnN1UAfGzLjT9gDTPed2eqXsJgKq5wMB8ESan/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "metaverse-token",
                "play-2-earn",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/xJjUYsWPN3",
                "twitter": "https://twitter.com/STELLAR_WHALES",
                "website": "https://interstellarwhalesnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "AZTgMrEH7jW2pp2F1qTCJ9gkbJK1MVa5Uy2q4cJpJot1",
            "symbol": "BMD",
            "name": "BumbierDollar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BumbieruInvestments/BumbierDollar/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "64h1SmKJiosJXkokXQwN3dRu92JBnSAGx416q9gdAYZt",
            "symbol": "SLG",
            "name": "Solido",
            "decimals": 9,
            "logoURI": "https://app.solido.games/images/logo5.png",
            "extensions": {
                "telegram": "https://t.me/solidogames",
                "twitter": "https://twitter.com/solidogames",
                "website": "https://solido.games/"
            }
        },
        {
            "chainId": 101,
            "address": "CLjXrTPioQDCLmfqUdyjiwCy7D7FHMs5edWQWXjesCWJ",
            "symbol": "MMT",
            "name": "Moon Money Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/agentspade/MoonMoneyToken/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "Utility-token",
                "payment-token",
                "NFT"
            ],
            "extensions": {
                "website": "http://moonmoneybank.com"
            }
        },
        {
            "chainId": 103,
            "address": "2NR6WaaCqmxEwoemqtf1c4sguLVmcaQVzfwhWdhKBoCc",
            "symbol": "DVTK",
            "name": "DevToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2NR6WaaCqmxEwoemqtf1c4sguLVmcaQVzfwhWdhKBoCc/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV",
            "symbol": "SVT",
            "name": "Solvent",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "solvent",
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "HuFKVQNyB177c9DiocQksYzBCtHMRUP5bBXZJzuLvYQm",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "HL9rCQ185mXnLp7qPJA5nL3iKjTtXk3pfkJ1DMvCQDxz",
            "symbol": "CNPI",
            "name": "CNPinvest",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/kenviet/CNPI/main/CNPI.png",
            "tags": [
                "MEMES-TOKEN",
                "NFTS",
                "DEX"
            ]
        },
        {
            "chainId": 101,
            "address": "GCzUTpii6QSMoAnqY2rKwXUS7EHWCYbA2X4CawNGnVt1",
            "symbol": "PEAR",
            "name": "Bumbieris",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BumbieruInvestor/Bumbieri/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5iFz597f2F5hjpA7kAuzB1ndJggs5LJYGg8wwN7nJGQj",
            "symbol": "JDX",
            "name": "Jaydoux",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dvxpower/jdx/main/Jdx-logos2.png"
        },
        {
            "chainId": 101,
            "address": "A7UfEJQ89JRhhwkYFsB8VxFj2LBx76k4GT2bywTfLNFu",
            "symbol": "YCB",
            "name": "Yottabits Crypto Binary",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A7UfEJQ89JRhhwkYFsB8VxFj2LBx76k4GT2bywTfLNFu/logo.png",
            "tags": [
                "social-crypto",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FExwLD8oa4JP15G639gQmm6cJPpBUXg73C11Y9Xn9SUb",
            "symbol": "LBT",
            "name": "Lucas BaTOKEN",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/lbpinheiro/lucasbatoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "413vAqjCPcjkGm5JEWNqXDti757PbtXjB2ju6ecMLbWf",
            "symbol": "PNIS",
            "name": "Elis Gay LMAO",
            "decimals": 0,
            "logoURI": "https://github.com/AR1Z0NAPN1SMAN/token-list/blob/09e2082a5d2f73b1e6d39aeb3487dce83b725477/assets/mainnet/413vAqjCPcjkGm5JEWNqXDti757PbtXjB2ju6ecMLbWf/cover8.jpg?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2hQLnJuLVpa7bRWBv3DKnp8HtbnDmbagWfdZLX7BsvDx",
            "symbol": "HEALTH",
            "name": "Swiss Health Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2hQLnJuLVpa7bRWBv3DKnp8HtbnDmbagWfdZLX7BsvDx/logo.png",
            "tags": [
                "Health"
            ],
            "extensions": {
                "website": "http://healthyswiss.ch"
            }
        },
        {
            "chainId": 101,
            "address": "LowWCjZx8Yksbuhb7izqEGmhwidS98RwwGcfAeTg7qU",
            "symbol": "LOWTOKEN",
            "name": "Low Token",
            "decimals": 0,
            "logoURI": "https://tj55zg4zqmheu27krx52xoeebbzbx4yzy4ef2kmgiaev5llsmdza.arweave.net/mnvcm5mDDkpr6o37q7iECHIb8xnHCF0phkAJXq1yYPI",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/low_sleazy",
                "twitter": "https://twitter.com/lowsleazy",
                "website": "https://lowsleazyakalowlife.bandzoogle.com/home"
            }
        },
        {
            "chainId": 101,
            "address": "DBGwvKn9tXBbW7WN3JP4X4tLBbP3Et3z3AV7Smu7YAf1",
            "symbol": "ANUC",
            "name": "Ashonk Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GajarKaHalva/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "tC3VFr8KHNETCRPkyx9Kwp6reKDsncYXXvUEj2pEmHr",
            "symbol": "JCE",
            "name": "Juice",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Joebenitus/Juice/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FHaFNXqLYP3tauBR8YG2zX9f5f6TiPjsW4RP2vkvoRM2",
            "symbol": "LBLY",
            "name": "Liberlandy",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FHaFNXqLYP3tauBR8YG2zX9f5f6TiPjsW4RP2vkvoRM2/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FRemSi7cqQPetjBV2SzBxhgsZoxgNYw3NuqF5Erf8u9u",
            "symbol": "VZ",
            "name": "VZion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FRemSi7cqQPetjBV2SzBxhgsZoxgNYw3NuqF5Erf8u9u/logo.png",
            "tags": [
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6wFD6foDuXV3xMLzHtJTxWpQAhnQ1XR27Zjofs5qWZPf",
            "symbol": "FLX",
            "name": "Flexcoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/flexboks/flexcoin/main/Flex-ikon-stor.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://flexboks.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ep2HcbGf6KsTfeDhJSjCbsWPcYziMmDnBpDFCYh5nC4m",
            "symbol": "PENG",
            "name": "Penguin Token",
            "decimals": 0,
            "logoURI": "https://github.com/Andrekreta/Solana_Token/blob/main/Penguin-Token.png",
            "tags": [
                "social-token",
                "community-token",
                "Utility-token",
                "payment-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "EjcSnvLtAbm6tXVfNKm3YNSfvPC3tdsTQw6jv3K5ZwHV",
            "symbol": "NILF",
            "name": "NILF Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/NFTMeOfficial/NIFL-logo/main/logo.png",
            "tags": [
                "utility-token",
                "MetaVerse",
                "community-token",
                "play-to-earn",
                "stake-to-earn",
                "multi-chain",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/nftme",
                "telegram": "https://t.me/nilfio",
                "twitter": "https://twitter.com/NILFToken",
                "website": "https://nilf.io"
            }
        },
        {
            "chainId": 101,
            "address": "2cKSkyng5MPYtuxbHnKTJVJ2nNH9hZ4ivovSK7xuBsGW",
            "symbol": "dCAPS",
            "name": "Decentralized Caps",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Nakejolen/bit-booze/main/assets/dcaps%20official.png",
            "tags": [
                "social-token",
                "community-token",
                "Utility-token",
                "payment-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/4wexHdsSND",
                "twitter": "https://twitter.com/bit_booze",
                "website": "https://bit-booze.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DZyQhqKbdjk4qERM9B6Tk3KMPibXADEejDBx21W8oCnC",
            "symbol": "KAMRA",
            "name": "Kamracoin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DZyQhqKbdjk4qERM9B6Tk3KMPibXADEejDBx21W8oCnC/logo.png"
        },
        {
            "chainId": 101,
            "address": "7xDo5775a2PgFuotkqukM1CtKdMx8rxBhgxrhvYt1d7W",
            "symbol": "GAD",
            "name": "GAD coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MaciejGad/gad_coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "585772wNa6DMRV3BwAFStS3A45DPTuUbhbwyAZG1kZBX",
            "symbol": "SHK",
            "name": "Shrek Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/serdafim24/shrek/main/main-qimg-8430e461c28bbfb39ef1806f0b4100f3.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3TrqXqA7Ao5AGpUtFzdt55jDyd7XYHv5rirLuEbsfGGc",
            "symbol": "ITG",
            "name": "Imperial Throne Gelt",
            "decimals": 0,
            "logoURI": "https://github.dev/ifranckie/token-list/blob/f498eab83d075a8b9623149bdc0f0625c38276e0/assets/mainnet/3TrqXqA7Ao5AGpUtFzdt55jDyd7XYHv5rirLuEbsfGGc/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6yEwx3WtwupFTpjh3t9esUbq4giTKb8YWHVF8u5sTibD",
            "symbol": "CCCT",
            "name": "Canopeé Carbon Credit Token",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/agrinix-token/logos/CCCT.jpg",
            "extensions": {
                "website": "https://canopee.com.br/"
            }
        },
        {
            "chainId": 101,
            "address": "CvzmN4HEMt2R9tsLyCV26yV2sT5tgD6nAHd7TNtWvHXq",
            "symbol": "WWV",
            "name": "Wild West Verse Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Wild-westverse/logo500/main/www_500.png",
            "tags": [
                "utility-token",
                "social-token",
                "play-2-earn"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/EgkyvyrNNT",
                "twitter": "https://twitter.com/wildwestverse",
                "website": "https://www.wildwestverse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Ch42xPsHVL21rPBnorysg6V9WgbtNWnSjG1fqnq9GYAp",
            "symbol": "NST",
            "name": "NFTStudioToken",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/Zlee12/NFTStudioHu/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "Utility-token",
                "payment-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "GtLLiBNXXqmM4pdD4Z3UDb5aZ3wtggkwZ3TsEkTkUi9f",
            "symbol": "SNOO",
            "name": "SNOO TOKEN",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GtLLiBNXXqmM4pdD4Z3UDb5aZ3wtggkwZ3TsEkTkUi9f/logo.png",
            "tags": [
                "token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/snootoken"
            }
        },
        {
            "chainId": 101,
            "address": "BfkeTseqgoxUn8gF1fGQC4GoqHMaCfzmQUgKF4nKDFhr",
            "symbol": "CLN",
            "name": "Central Loyalty Network",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BfkeTseqgoxUn8gF1fGQC4GoqHMaCfzmQUgKF4nKDFhr/logoo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Token_CLN",
                "website": "https://www.centralloyalty.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9RxfJAtr4T6s7uvj1zdjvwviKRwnDCJdKQqUZmX5FYiC",
            "symbol": "SXMAS",
            "name": "Sockmas",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9RxfJAtr4T6s7uvj1zdjvwviKRwnDCJdKQqUZmX5FYiC/logo.png",
            "tags": [
                "utility"
            ],
            "extensions": {
                "twitter": "https://twitter.com/socksonsolana",
                "website": "https://www.solsocks.exchange/"
            }
        },
        {
            "chainId": 101,
            "address": "9aXC5MphFpoiKtg9bvAKHV12vdKLfvzc1i1fZucFSE8m",
            "symbol": "LYFE",
            "name": "Lyfe Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/letto4135/solCrypto/main/cr.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9wBNzm54pPGq6T2WK6GecWvPgKcEYb6MYv225dgnuUu6",
            "symbol": "KMM",
            "name": "KMM Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/kum85/assets1/main/29B185B0-B5D0-4648-93B4-3D028C0B603D.jpeg",
            "extensions": {
                "website": "https://nouncurrency.wordpress.com/"
            }
        },
        {
            "chainId": 101,
            "address": "F6Kv4wJoK8pnNiHvbZPAvWqi5vm7jFAhJJ9DHQYtF498",
            "symbol": "SOLUTION",
            "name": "ECS",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/crypto/main/solution-logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6MREZaczrxNVph4RF4YFiDejxjcZVjZ1iNZW8NKcTVXa",
            "symbol": "KCV",
            "name": "Kelly Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kels48/crypto/main/LogoReal.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B2EYg3WaWeaTczfvWm2dZSXTGXGasC5Wb1wpG5VK2Q4L",
            "symbol": "CYS",
            "name": "CySecure Cyber Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CySecure/Token/main/CELogo.png",
            "tags": [
                "social-token",
                "community-token",
                "Utility-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DEV54dojzTED4ekUR4HwgZsWfTh9oouhuvhHUVszrMed",
            "symbol": "MWL",
            "name": "Munchiez WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEV54dojzTED4ekUR4HwgZsWfTh9oouhuvhHUVszrMed/logo.png"
        },
        {
            "chainId": 101,
            "address": "7GdDvrDJHabXcJ3JrJwtgKtXcT4nb4AujJXT3ZdSzSK6",
            "symbol": "WCC",
            "name": "Wookie Cookie Credits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ad6321/crypto/main/wookiecookie_logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBiUjXNvwWaMphZAcx2dTeNzCrTCmKhbSm1xydLQARMe",
            "symbol": "KRC",
            "name": "KayaCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/D3ss3rtTV/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "35W3RdZ3pKoqxcHkwSfHkRDRerdVUhHBkAK8MK1NY2ZJ",
            "symbol": "HPX",
            "name": "HappyX",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/astefanis/HappyX/main/HApexsmall.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HappyX_2022"
            }
        },
        {
            "chainId": 101,
            "address": "3HJZdqnCdUVW2Lq1oEHa3VbSgFNbSFXfcaZaqtpZLTyW",
            "symbol": "GMORPH",
            "name": "MORPH GOLD",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HJZdqnCdUVW2Lq1oEHa3VbSgFNbSFXfcaZaqtpZLTyW/logo.png"
        },
        {
            "chainId": 101,
            "address": "FZjiUmZaUCVxHBgHtcx9bc1qKpeai3hH2zHyYgTnWcps",
            "symbol": "ZOTTI",
            "name": "ZACHARIOTTI",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/SeagullsFucking/Zotti/main/FUCKADOOS.jpg"
        },
        {
            "chainId": 101,
            "address": "J561hAjQ41Fu7ykuRW1fMBrFZVYv1u8urC13QtZSR5eL",
            "symbol": "SQRL",
            "name": "SquirrelCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jdconner675/squirrel/main/squirrelcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "QQe5FMXHnF9cSx9PmYim7fveSsCSivqTut6BbYZGb1b",
            "symbol": "CHMP",
            "name": "Chump Buck",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/QQe5FMXHnF9cSx9PmYim7fveSsCSivqTut6BbYZGb1b/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6YdYuSYprCdTxGGyFfpmZ4YzsgJGVW7k2Wscs6Z7Gbqg",
            "symbol": "HYB",
            "name": "Hybreed",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/ElHamrouche/Hybreed/main/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "Gaming-token",
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8G9EzHcevdcxNYDwaC89N2FuLRRywCjfbpNt7P6H75A",
            "symbol": "ECS",
            "name": "EnvironmentalComfortSolutions",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/crypto/main/ecs-logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "29nBhWM2qRqSkttsX29pUwcNyDeznVbtN4HqkQjvYqxt",
            "symbol": "BOOO",
            "name": "BooBooCoin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/Jacob130000x/booboologo/main/logo2.png",
            "tags": [
                "farming",
                "payment-token",
                "NFT-Project"
            ]
        },
        {
            "chainId": 101,
            "address": "EEhosSQvC2yVDRXRGpkonGFF2WNjtUdzb48GV8TSmhfA",
            "symbol": "AMMO",
            "name": "Ammo",
            "decimals": 9,
            "logoURI": "https://arweave.net/rjP_BdMqFsXBWoInFYuVNDdqLzW1xo82egb74WRl3Hc",
            "tags": [
                "nft-token",
                "staking"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/cryptostraps",
                "twitter": "https://twitter.com/CryptoStraps",
                "website": "https://cryptostraps.io"
            }
        },
        {
            "chainId": 101,
            "address": "iMSoEKeFPsxXe493ouVREU6Aq7aamY2uADqBHtJFhza",
            "symbol": "ImSo",
            "name": "Intergalactic Monkey Space Odyssey",
            "decimals": 6,
            "logoURI": "https://pscwbp7c3jss2hzt34p5y2atycb2vopk2x43iapvbr367pezd2nq.arweave.net/fIVgv-LaZS0fM98f3GgTwIOquerV-bQB9Qx377yZHps",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Qsb5xaUK96",
                "twitter": "https://twitter.com/rationalnft",
                "website": "https://imsonft.art"
            }
        },
        {
            "chainId": 101,
            "address": "seedjTZWBtYzLi2eR5RidDKLaaZ5bkqk2ABVoUTMrKK",
            "symbol": "GSS",
            "name": "Genesis Seed",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/seedjTZWBtYzLi2eR5RidDKLaaZ5bkqk2ABVoUTMrKK/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Seed Society Genesis Collection",
                "discord": "https://discord.gg/tA8J3fM6pq",
                "twitter": "https://twitter.com/SeedSocietyNFT",
                "website": "https://seedsociety.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "HTYYDuePjjsm2dYwzWpYwkreMwS3KCigp3oZXhdw6fWn",
            "symbol": "IVCT",
            "name": "Invictus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kindja501/CoinIVCT/main/hallows.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5B4UWAFCVdivK6dqte7g5NzUhEnAcWWzEHfVAKiybVoY",
            "symbol": "MINE",
            "name": "FullMoonMiningCompany",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/crypto/main/mine-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2pLgW8DsTrSgMT7NeXoyKV3avWn5fbaYZyC1kjofgLXU",
            "symbol": "QRYES",
            "name": "QRYES Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rjdrar/my-solana-tokens/d4b3c396334b5c9bdaee49d36b8ae0ae4bff7867/qryes-icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BSsbGzUvWnsKoenarTd9vuiFmWcb7MFVKn2wuQuJCnFc",
            "symbol": "SATL",
            "name": "Selanthelas",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MasterDianite/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks",
            "symbol": "GARI",
            "name": "Gari",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/garitoken",
                "twitter": "https://twitter.com/garitoken",
                "website": "https://gari.network"
            }
        },
        {
            "chainId": 101,
            "address": "HFVzTS9Fp558HF9cAcGsTY6NR63naDE86RjcjAVM6VYZ",
            "symbol": "SGC",
            "name": "SushiGarden Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rkm1999/crypto/main/mobile-85f244bc61af3ebce6e6.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3iVT5eeMT67ywdr3jEQVjZnvwhR5gzQtESLhGcH6pN4u",
            "symbol": "KORN",
            "name": "Korn Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/crypto/main/korn-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GYyL45cnhDWEcr2iEs5sxWND5SLXa7VMFUmwp1uM92YV",
            "symbol": "WURK",
            "name": "Wurklo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GYyL45cnhDWEcr2iEs5sxWND5SLXa7VMFUmwp1uM92YV/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "employment-agency"
            ],
            "extensions": {
                "description": "Wurklo is improving the service industry by providing wurkers with equal power. We're putting the power of a large business in the palm of your hand, so you can wurk how you want!",
                "discord": "https://discord.gg/ZeYjq7m7vN",
                "facebook": "https://www.facebook.com/Wurklo",
                "instagram": "https://www.instagram.com/wurklo/",
                "medium": "https://wurklo.medium.com/",
                "reddit": "https://www.reddit.com/user/Wurklo",
                "telegram": "https://t.me/wurklo",
                "twitter": "https://twitter.com/Wurklo",
                "website": "https://www.wurklo.com"
            }
        },
        {
            "chainId": 101,
            "address": "8SNnEqCrwEtBh15M6KVqfPgX8zJGfvSioQFaKQ9oEnr4",
            "symbol": "TPDC",
            "name": "Topodoco",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rjdrar/my-solana-tokens/master/assets/mainnet/8SNnEqCrwEtBh15M6KVqfPgX8zJGfvSioQFaKQ9oEnr4/heart-128.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EYqLKRmMWwZNx2mcWGYmPrUspH2KEMS1Jvn4hvCpvFPR",
            "symbol": "ADT",
            "name": "Adapter Digital Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adapterdigital/adt/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GZLFnwCs9ruFj4iw6HWFySmaQHVaYoLZ6zHrfyq7CoNv",
            "symbol": "TRADEN",
            "name": "TRADEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZLFnwCs9ruFj4iw6HWFySmaQHVaYoLZ6zHrfyq7CoNv/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://traden.pro/"
            }
        },
        {
            "chainId": 101,
            "address": "3iFAZSDT54Z1YwCuMemJz2fb64odMbH5Ny9a2a9Anu6T",
            "symbol": "SLIP",
            "name": "SlipCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Solh4ckm1n/SLIP/main/logo%20S%20v3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Nvz4WeJBDszAgFFQg4soHyuQT9XH1gfNNc9d79TgLXh",
            "symbol": "BEANZ",
            "name": "Beans",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AMGrind/BNZ/main/BNZ_ICON.png",
            "tags": [
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AViCKt8bh3BQ6CnqKQq8DNPZLcyJktKggD9GTiwAntuo",
            "symbol": "ABEET",
            "name": "ABE Enterprise Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/albertamoyaw/crypto/main/abe_ent_logo.png",
            "tags": [
                "utility-token",
                "nft",
                "gaming-token",
                "auction",
                "stake"
            ]
        },
        {
            "chainId": 101,
            "address": "6cHHjtGDjTp16zwELGRmdMLeZQsv2m1nSWzQ7RFMPVVv",
            "symbol": "ABEFT",
            "name": "ABE Family Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/albertamoyaw/crypto/main/abe_fam_logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft",
                "auction",
                "stake"
            ]
        },
        {
            "chainId": 101,
            "address": "2jw1uFmc1hhfJH3EqGhaE2rfZMMC2YBpxkZcdUbPppMn",
            "symbol": "VIVAION",
            "name": "Vivaion Token",
            "decimals": 9,
            "logoURI": "https://arweave.net/Xz7ErS3TZYmBnoh3OPkIQM_O6TPotyEJb6H5WFOe_qc",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "metaverse-token"
            ],
            "extensions": {
                "coingeckoId": "vivaion",
                "discord": "https://discord.gg/vivaion/",
                "twitter": "https://twitter.com/vivaion",
                "website": "https://vivaion.com"
            }
        },
        {
            "chainId": 101,
            "address": "6mPxDQcbagBshYiUMVxywAZaQBpGd2yr5GYvjesjPQg9",
            "symbol": "DICKTOX",
            "name": "Dicktox Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/necrolingus/DICKTOX_TOKEN/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://dicktox.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BcRr96qhSoaKFjGJDKtSWmHDvTrv7ziuq29dRjtUmHuk",
            "symbol": "NNI",
            "name": "Neonomad Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BcRr96qhSoaKFjGJDKtSWmHDvTrv7ziuq29dRjtUmHuk/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Fj77EYcTNH",
                "twitter": "https://twitter.com/neonomadfinance",
                "website": "https://neonomad.finance"
            }
        },
        {
            "chainId": 101,
            "address": "vMETAjYD7KjC69E7zFCuw1g4PLfa6pcyztww9nSrgsQ",
            "symbol": "vMETA",
            "name": "vMETA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/meta-collective/tokens/main/assets/vMETA.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/B6sd88UVmD",
                "twitter": "https://twitter.com/_metaCollective",
                "website": "https://meta-collective.digital/"
            }
        },
        {
            "chainId": 101,
            "address": "cMETAGX549J6Z5wLWDVHKxEmBibh8YkWvFftCRyaw2a",
            "symbol": "cMETA",
            "name": "cMETA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/meta-collective/tokens/main/assets/cMETA.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/B6sd88UVmD",
                "twitter": "https://twitter.com/_metaCollective",
                "website": "https://meta-collective.digital/"
            }
        },
        {
            "chainId": 101,
            "address": "Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM",
            "symbol": "eSOL",
            "name": "EverSOL staked SOL (eSOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM/logo.png",
            "extensions": {
                "medium": "https://medium.com/everstake",
                "twitter": "https://twitter.com/everstake_pool",
                "website": "https://everstake.one"
            }
        },
        {
            "chainId": 101,
            "address": "3ahgNbqXRMS8YM8XCVzC3XrFcZ7y9ffJeqCNuQZXvFr1",
            "symbol": "MZN",
            "name": "Mutawazan",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/andbandu/mutawazan/main/mutawazan.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GXw4cMzffjshUqcTw7WtdsndVos32hRCEZcYgKsS2XTN",
            "symbol": "MTZY",
            "name": "Mitzy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MorrrisK/mitzy/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7WTbeUBKAPW61JBmziqGVKvsaJuQPnkg3rZcqBmieo1e",
            "symbol": "NOLO",
            "name": "No lo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rjdrar/my-solana-tokens/master/assets/mainnet/logo-1.png",
            "tags": [
                "social-token",
                "nft",
                "auction",
                "stake"
            ]
        },
        {
            "chainId": 101,
            "address": "B9MQoo9CwC5tqEvvhkqd3Amj6z5goQ7ANvFooJXrfWdB",
            "symbol": "IRC",
            "name": "Informatics Research Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/awangga/irctoken/main/logo.png",
            "tags": [
                "reward-token"
            ],
            "extensions": {
                "website": "https://www.irc.or.id"
            }
        },
        {
            "chainId": 101,
            "address": "GDmJJ6ReMBBxXkfGajAfDuwDArLejPoLUAgtWkrfU7tt",
            "symbol": "CNGUP",
            "name": "CHINGUP  TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rahathussain01/CNGUP/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5X1qmPGoj9Dbc9T7pncM8DMkLvamDoRSWf4FSu4kjajv",
            "symbol": "XPPI",
            "name": "XppiCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/xppi/xppicoin/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft",
                "stake"
            ],
            "extensions": {
                "discord": "https://discord.gg/HukFryQmr8",
                "twitter": "https://twitter.com/xppipr0"
            }
        },
        {
            "chainId": 101,
            "address": "Babr3RH9Cgwmn3GsiAQjv3d9fSPpF2k7vZGP9P8Vxfkp",
            "symbol": "MGNT",
            "name": "MagnataCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pcDamasceno/cryptoMagnata/main/Magnata.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3GK36MB6oudPbLaALKwwY1CDxKvsFuR2fdHLHknJ9KCb",
            "symbol": "MUDO",
            "name": "Mudo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Pcheckbox/token/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://mudocoin.io"
            }
        },
        {
            "chainId": 101,
            "address": "99Q3AfFWX3rdidoQCnAPPoZFjsaXr2AAk65RUgxiwfUi",
            "symbol": "GCC",
            "name": "Gatsby Club Currency",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/benzsteamNFT/gat/main/logotoken.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Gatsby Club members earn GCC Tokens to access exclusives advantages : VIP NFT's, Metaverse, P2E. ",
                "discord": "https://discord.com/invite/tvaFSXw4uV",
                "twitter": "https://twitter.com/gatsbyclubnft",
                "website": "https://www.gatsbyclub.net/"
            }
        },
        {
            "chainId": 101,
            "address": "9rpbyVWfW8LKutNaoyKGBzELSGhq2qvw1hcHLNmzFW6a",
            "symbol": "N4C",
            "name": "NFT 4 Cause DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/qrtp/token-list/main/assets/mainnet/9rpbyVWfW8LKutNaoyKGBzELSGhq2qvw1hcHLNmzFW6a/logo.png",
            "tags": [
                "social-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NFT4cause",
                "website": "https://www.nft4cause.app/"
            }
        },
        {
            "chainId": 101,
            "address": "2BAsgeW243NuG3qdx31uB5RNeJaXiSwaoA5P9aXTZN8g",
            "symbol": "QMT",
            "name": "Quantum Mob Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/kdubb1337/assets/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/quantum-mob/",
                "twitter": "https://twitter.com/quantummob",
                "website": "https://qmo.io"
            }
        },
        {
            "chainId": 101,
            "address": "36zKufCebdFBbrjak6DUyFwPZs1grv8hkdem4C9HTan3",
            "symbol": "STRO",
            "name": "AstroCoin",
            "decimals": 0,
            "logoURI": "https://github.com/tylergaryalbers/tokenstuff/blob/master/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BQzxq9g7Q71orJsKnmDgerdueMoxDrLUTrqAneRayxQk",
            "symbol": "MIKA",
            "name": "Mikadoh",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pinwheelHerman/crypto/main/Mikado.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5nni7VTkEDxZ8XHod9gbuypMv2vpyXw6SNRgwiuDXEQf",
            "symbol": "FLZ",
            "name": "Flooze",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cryptodgk/flouze/main/logo.png",
            "tags": [
                "social-token",
                "fun-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ReMvGxiJRK7x55qoFCG5tPhjFs3BypELZz1o5yFV933",
            "symbol": "MYT",
            "name": "MyIceT",
            "decimals": 0,
            "logoURI": "https://github.com/icebewide/Crypto/raw/main/ice",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C6weJjW3WzYCyL74u1HtSWXVmnF8nGGx53qUk6FA3NuF",
            "symbol": "GZL",
            "name": "Gazel",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ozalperen/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AirFPYZVvoaxzn33pwoaHhnRK6XPizhwCMeNqevUZbXC",
            "symbol": "AFT",
            "name": "AirFly Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/Diegomtz9/token-list/main/assets/mainnet/AirFPYZVvoaxzn33pwoaHhnRK6XPizhwCMeNqevUZbXC/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WKMFhNrxvS",
                "twitter": "https://twitter.com/airflyersnft",
                "website": "https://airflyersnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "Cu1mWsE3JeEzMz1AzHezDJ99a45KheQWxWpLPkHKtsxu",
            "symbol": "HCR",
            "name": "Hunt Crypto Rewards",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cu1mWsE3JeEzMz1AzHezDJ99a45KheQWxWpLPkHKtsxu/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CgqmgHx72HHXcd3ZT7221BX4KBjaCs6p15y6Aag73CeG",
            "symbol": "NERC",
            "name": "NeroCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anasfredmanjack/crypto/main/nerotoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HsUC69czHjEXe1gUYWk3M1ciCQnndC8nxU995JFxZEDZ",
            "symbol": "BLOQ",
            "name": "Bloqen Puebla 1",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HsUC69czHjEXe1gUYWk3M1ciCQnndC8nxU995JFxZEDZ/logo.png"
        },
        {
            "chainId": 101,
            "address": "7pL1P9kcd6VLBihySqgnc2SHHuFp2w8RwGy8zUdg1H4j",
            "symbol": "IBBP",
            "name": "Ipa Belgium Beer Project",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cUiM1QyKoTTMb5xssJ2dBx8jbGaKK8NPNnyVdLdtfCw/logo.png",
            "tags": [
                "beer-token",
                "webshop-token",
                "belgium-token",
                "solana",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://belgiumbeerproject.be/"
            }
        },
        {
            "chainId": 101,
            "address": "FSccmrtyj163LKzRDfNiqR364zdJXUpNfEPtsinES13f",
            "symbol": "BEZ",
            "name": "BezCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/enricobez/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GDKEjdmmuksKBckLvV7Yk68VjgLBEVasZF8WtSriHriv",
            "symbol": "WFX",
            "name": "WhaleFlex",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WhaleFlex/octopus/main/whaleflex_logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "47MXRyBzL2B8tutvCHNtahd35LdsTKhsVdneozf6mDwU",
            "symbol": "CASPER",
            "name": "Casper Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/therealghxst/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G57GeVQwToCUq9CznjEit97fePFF7gvB2hp2GnjGqGSw",
            "symbol": "MHT",
            "name": "Miami Hitches Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lenseov3/token/main/Logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHrdWn8btqYh3KKPs8S9Q9QqUD67hkYmk86QecLhh9E9",
            "symbol": "BETX",
            "name": "Solana Betting EXchange",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHrdWn8btqYh3KKPs8S9Q9QqUD67hkYmk86QecLhh9E9/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "virtual-currency"
            ],
            "extensions": {
                "description": "Solana Betting eXchange - bet with SOL, SAMO, SRM, RAY - fantast token leagues and more",
                "telegram": "https://t.me/solbetx"
            }
        },
        {
            "chainId": 101,
            "address": "9kHy1fxEtRbQPSKUSEBXqMkVnKT9Kc2x5B4YvRNCfa44",
            "symbol": "BINX",
            "name": "BinxCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JDevXV/binx/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CL4QTB7RVvipzTufQ1hrXGkLnZ5LxhGQybBUURo7ZUkU",
            "symbol": "SAMOCC",
            "name": "Samoyed Crosschain",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/sRTf7C6/723978-AB-B876-49-EA-9010-7-B01349-E945-C.jpg",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HcpgxKtiD5MDSu1VK1L4Y7evEedjkkr4yYBMBNgdqRPi",
            "symbol": "PIL",
            "name": "Pile",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mattpar03/crypto/main/PileLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3xNTg9uBP1cZox61SRqEMQwFNZxkwvYsbfVbLLJB1Gc7",
            "symbol": "POM",
            "name": "Pomsky Coin",
            "decimals": 0,
            "logoURI": "https://github.com/whitehathacks10/crypto/raw/main/image0.png",
            "tags": [
                "social-token",
                "commodity-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ACUMENkbnxQPAsN8XrNA11sY3NmXDNKVCqS82EiDqMYB",
            "symbol": "ACM",
            "name": "Acumen Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ACUMENkbnxQPAsN8XrNA11sY3NmXDNKVCqS82EiDqMYB/logo3.svg",
            "extensions": {
                "coingeckoId": "acumen",
                "discord": "https://discord.com/invite/UXDnngxRmn",
                "medium": "https://acumenofficial.medium.com/",
                "serumV3Usdc": "94CZWJMy7Jmc7gSM288WmFfse596H12KCKgwMARkyvhw",
                "twitter": "https://twitter.com/acumenofficial",
                "website": "https://acumen.network/"
            }
        },
        {
            "chainId": 101,
            "address": "KAshiP9YrrfrXenmtzX9crcePYzbSa6xjwZLAM8etmG",
            "symbol": "$KASHI",
            "name": "KASHI",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KAshiP9YrrfrXenmtzX9crcePYzbSa6xjwZLAM8etmG/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/kashidragons",
                "website": "https://kashidragons.com"
            }
        },
        {
            "chainId": 101,
            "address": "Gj9GCwBoVwR2wqaKHCkrAYQtTpyPSfwLU4WjsoJMqQ8m",
            "symbol": "ACESMINT",
            "name": "ACES Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gj9GCwBoVwR2wqaKHCkrAYQtTpyPSfwLU4WjsoJMqQ8m/logo.png",
            "extensions": {
                "discord": "https://discord.gg/5gehRmCNem",
                "twitter": "https://twitter.com/TheAcesNFT",
                "website": "https://theacesnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BQHdWCL8WTkxBsKKLvFxgThmCX9EK4zorTzpznabMrZY",
            "symbol": "ABWT",
            "name": "Alpha Blue Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQHdWCL8WTkxBsKKLvFxgThmCX9EK4zorTzpznabMrZY/logo.png",
            "tags": [
                "Zoolana"
            ],
            "extensions": {
                "website": "https://www.zoolana.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6XUWTnJEJxtK3zD6c6GdXZaHJxugJ7FnBoxtow9MRUoS",
            "symbol": "ELM",
            "name": "Elite World",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6XUWTnJEJxtK3zD6c6GdXZaHJxugJ7FnBoxtow9MRUoS/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/EliteWorldMetaverse",
                "telegram": "https://t.me/EliteWorldMetaverse",
                "twitter": "https://twitter.com/EliteWorldMeta"
            }
        },
        {
            "chainId": 101,
            "address": "ER7VS5oDqmPCALdxopj7583gzUC49cBuuNUxFrfc4uCd",
            "symbol": "BTR",
            "name": "BiTrust Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/kum85/assets/main/77D92247-EF90-42A4-A6CC-291701665E8E.jpeg",
            "extensions": {
                "website": "https://nouncurrency.wordpress.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EV1Fz9GycC5Yr19Ye6h6ct1m3NQMwmadrKiWHUDuXdnr",
            "symbol": "SCAPESWL",
            "name": "Scapes Whitelist",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EV1Fz9GycC5Yr19Ye6h6ct1m3NQMwmadrKiWHUDuXdnr/logo.png",
            "tags": [
                "scapes-whitelist"
            ],
            "extensions": {
                "discord": "https://discord.gg/scapes",
                "medium": "https://scapesnft.medium.com",
                "twitter": "https://twitter.com/scapesnft"
            }
        },
        {
            "chainId": 101,
            "address": "837zQBUJDs6JvMsVZkWdMaCTXsPkSfUyPEVEnuJZgMQt",
            "symbol": "FETTI",
            "name": "Fetti",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/837zQBUJDs6JvMsVZkWdMaCTXsPkSfUyPEVEnuJZgMQt/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://fetti.life/"
            }
        },
        {
            "chainId": 101,
            "address": "CYxGX9YZWdCZgnF4SobJNcE1APgisTh41u2pcqRU2Xov",
            "symbol": "KNTO",
            "name": "Kéntro",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/samueljpries/Crypto/main/k%C3%A9ntro.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HYM7xQ7Txze9H2oHpeR2MRqucu6jFcYJ7KU1yXVwbu4z",
            "symbol": "MyEggs",
            "name": "MyEggs",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/BrightTechnologies1/myeggs--logo@master/logo.png",
            "tags": [
                "social-token",
                "commodity-token",
                "Utility-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MyEggsNFT",
                "website": "https://myeggs.art"
            }
        },
        {
            "chainId": 101,
            "address": "wDyvn5CYbfaEvcRHVs9tsCrjC6dhhiYSfnLy6yJu3Ky",
            "symbol": "LFC",
            "name": "The Levy Family Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shinobialt/Crypto/main/logo-imresizer.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "S3SQfD6RheMXQ3EEYn1Z5sJsbtwfXdt7tSAVXPQFtYo",
            "symbol": "ROSE",
            "name": "ROSE (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/S3SQfD6RheMXQ3EEYn1Z5sJsbtwfXdt7tSAVXPQFtYo/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x21C718C22D52d0F3a789b752D4c2fD5908a8A733",
                "assetContract": "https://explorer.oasis.updev.si/address/0x21C718C22D52d0F3a789b752D4c2fD5908a8A733",
                "bridgeContract": "https://explorer.oasis.updev.si/address/0x5848C791e09901b40A9Ef749f2a6735b418d7564",
                "coingeckoId": "oasis-network",
                "serumV3Usdc": "EybAYkmRKCyD4w8AErTG1bqmnvT85LFuPQPMCc8J3yD"
            }
        },
        {
            "chainId": 101,
            "address": "ArdsPHY5LsCjvxSxZz8f3vTkv5qoYihmCCPMYvr6aQza",
            "symbol": "MPI",
            "name": "Meta Paradise Island",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArdsPHY5LsCjvxSxZz8f3vTkv5qoYihmCCPMYvr6aQza/Mpi-new.png",
            "tags": [
                "social-token",
                "solana",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "As5t6W78oDg78LuS7LWcNu1FfCtfSkRXiN9yVL77DDn8",
            "symbol": "PEECH",
            "name": "peach dot fun",
            "decimals": 9,
            "logoURI": "https://cdn.discordapp.com/attachments/903834329287897109/930672804523835422/Logo_Round.png",
            "tags": [
                "virtual-currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/_PeachFun",
                "website": "https://peach.fun/"
            }
        },
        {
            "chainId": 101,
            "address": "7wHHrkv2NY7jMBHSLvCiQjYTje1x9i7Hq6Ppp6bHL3X",
            "symbol": "GCON",
            "name": "The G COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kok1no/crypto/main/g.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2BsZeLpEusAd7i6qkmCmiHUpocMTRghoBHzMb4suMnoi",
            "symbol": "SBP",
            "name": "SBT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2BsZeLpEusAd7i6qkmCmiHUpocMTRghoBHzMb4suMnoi/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "SBP"
            ]
        },
        {
            "chainId": 101,
            "address": "8DWMpVjWCawgurBXWHjfDew1CjkxKGQevGMpGmhAFdTa",
            "symbol": "DUST",
            "name": "DUST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/codingbeast/testapp/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sobAUEhmPMrid5WtfXRCTRgASFFJbekKq8aLccC3DaL",
            "symbol": "SOB2",
            "name": "SolaLambo Version 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sobAUEhmPMrid5WtfXRCTRgASFFJbekKq8aLccC3DaL/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sy2xymyc7J",
                "twitter": "https://twitter.com/SolaLambo",
                "website": "https://sob.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "BdNwXKmq9NTxLXMc9M35wkcJ4dBE2m3yf5LhKHJQfmE5",
            "symbol": "KHLO",
            "name": "KahleoKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RoadRunner440/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CWEX6oPPN4HKa93BVWZsmhxUPPmwk1BMSkZrcnWAT8gq",
            "symbol": "VICE",
            "name": "Vicegerent",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Longjoe76/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AR5AsX5Wuwi89r1tkftMYXaGwsSzt8f2GcL5ngTsaaTc",
            "symbol": "SHAPES",
            "name": "Shapes WL token",
            "decimals": 0,
            "logoURI": "https://metadata.apoyield.com/logo.png",
            "tags": [
                "community-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/msB5gFcUZM",
                "twitter": "https://twitter.com/shapesofsolana",
                "website": "https://shapes.ltd/"
            }
        },
        {
            "chainId": 101,
            "address": "EimK4rPobJ6KosVq75pm3jSBB5waUiYATu4L38Up3hmZ",
            "symbol": "OCEANTOKEN",
            "name": "OCEANTOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EimK4rPobJ6KosVq75pm3jSBB5waUiYATu4L38Up3hmZ/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "address": "EimK4rPobJ6KosVq75pm3jSBB5waUiYATu4L38Up3hmZ",
                "description": "OCEANTOKEN is a tokenized donation asset that supports TEAMSEAS and SeaChain projects",
                "instagram": "https://www.instagram.com/oceantokens_founder//",
                "website": "https://www.oceantokens.site/"
            }
        },
        {
            "chainId": 101,
            "address": "CLoTdqgpqcvCQDipcGDkJxBPJVyZMP8v8e3iCFP5tFfc",
            "symbol": "MvrsWL",
            "name": "Metaversol WL Token",
            "decimals": 0,
            "logoURI": "https://metaversol.com/coinlogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Metaversol WL Tokens for Pass Mint",
                "discord": "https://discord.gg/metaversol",
                "twitter": "https://twitter.com/MxMetaversol",
                "website": "https://metaversol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AWxmmrmnLaQoAvmzKXmjFAsSuAKd6fGox964jvKi9MY",
            "symbol": "SPILU",
            "name": "Sofie Private",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/SPILU-logo.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://spilu.ilov.eu/"
            }
        },
        {
            "chainId": 101,
            "address": "9idUGSpZkFtqxYTrSd3YsgcaVxVwfSTrDSQ411nckbvb",
            "symbol": "ADAM",
            "name": "Adam Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adamliu84/ADAMCrypto/main/ADAM.png"
        },
        {
            "chainId": 101,
            "address": "DtVC9rcYBQozaqorEN6EKpRnSGxVQdds3bQkjN8yQWxg",
            "symbol": "DDCN",
            "name": "DD Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SkippyG/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2NczZKqiEtv1CCufwBCLVRCh17w8aHAhvyGTU486KMo3",
            "symbol": "ST",
            "name": "Solana Tiger",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2NczZKqiEtv1CCufwBCLVRCh17w8aHAhvyGTU486KMo3/logo.png",
            "tags": [
                "community-token",
                "meme"
            ],
            "extensions": {
                "discord": "https://discord.io/solanatiger_slt",
                "telegram": "https://t.me/solanatiger_slt",
                "twitter": "https://twitter.com/solanatiger_slt",
                "website": "https://solanatiger.com"
            }
        },
        {
            "chainId": 101,
            "address": "22WH22zjhczZU5Rc8jjteqNmM2hiv8VnJb8eha1B4eoE",
            "symbol": "DFOREST",
            "name": "Deer Forest",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheDeerNFT/logo/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "64pS5rHb5DZD7LE62Yj12HMb85CNNqPXPsescGLZbkFZ",
            "symbol": "CLM",
            "name": "CentreLineMining",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MBbison83/centrelinemining/main/Black%20and%20Green%20Gaming%20Badge%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4WGGn2GwRFaw9EDXeC8Gx7qYsqkNZbpnvaBeMsY1AoSK",
            "symbol": "MSM",
            "name": "MetaSystem",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alinftminter2/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "AG7y2rFmjhhzkiKJXwm6nTtXwhWYoqZ1LNFFTKsuAFXG",
            "symbol": "MCOIN",
            "name": "Moon Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/colinantonell/crypto/main/mcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6rCSJtHE6N7nzCmtA2tdSq1bcoEfeELUPV9k6REamt3",
            "symbol": "ILOVEU",
            "name": "ILoveU Platform",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/qr-400.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://ilov.eu/"
            }
        },
        {
            "chainId": 101,
            "address": "G93cdiAJMyZCzpn1uz8GHRxXHCh2NhcPmFeSAQRrVmxE",
            "symbol": "Flint",
            "name": "Flint",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/jamalalishov/Flinttoken/main/flintlogo.png",
            "tags": [
                "social-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "website": "https://caveman.games/"
            }
        },
        {
            "chainId": 101,
            "address": "7MVwEf2Tjfos9axjSJjYLgGECepAiqJsJjH4qvSfMVot",
            "symbol": "TIS",
            "name": "TIS token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/KrisztianZagonyiTis/TISlogo/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://theitsolutions.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6dBh32Su2MSPFRRxziqchMmGSPZanExndEnC4RrXw2Xx",
            "symbol": "WSDM",
            "name": "Wisdom Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/slimeyoctopus/WSDM_token/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Laughing Buddha Wisdom Token",
                "discord": "https://discord.gg/Hv5fScpArr",
                "twitter": "https://twitter.com/BuddhasNFT",
                "website": "https://laughingbuddhas.io"
            }
        },
        {
            "chainId": 101,
            "address": "7dYvmoEHzw1DKwkUFbnBewxGYce5mqRojgfQKK5dvpE5",
            "symbol": "IK",
            "name": "IstraKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/istracoin-dev/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GPhCi6EW4frdGHtxC6ddbxCpkEAmB8qec2GmVNYkr8yZ",
            "symbol": "Arcade",
            "name": "earn acade",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GPhCi6EW4frdGHtxC6ddbxCpkEAmB8qec2GmVNYkr8yZ/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "metaverse-token",
                "play-2-earn"
            ]
        },
        {
            "chainId": 101,
            "address": "Buec9o1MZ1nwtvFyLqnB1iAcGP7tcDmasCMiGiwFAaob",
            "symbol": "RLUN",
            "name": "Role Universe",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/WrIZBE5.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token",
                "gaming-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/HEWjtkgrcS",
                "telegram": "https://t.me/roleuniverse"
            }
        },
        {
            "chainId": 101,
            "address": "FNhGuZ25ZZvA8JzK8iVe7ovQV8GTbZkuYrHBivzJ11Sx",
            "symbol": "ASHR",
            "name": "Solasher Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/asherlagemi/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "pH5wWJc3KhdeVQSt86DU31pdcL9c8P88x2FQoKEJVHC",
            "symbol": "BXS",
            "name": "Bancambios AX",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pH5wWJc3KhdeVQSt86DU31pdcL9c8P88x2FQoKEJVHC/logo.jpg",
            "tags": [
                "impact-defi",
                "bancambios-token",
                "solana",
                "impact-dex",
                "defi-for-good",
                "multi-chain"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/BXcommunity",
                "facebook": "https://www.facebook.com/bancambios",
                "imageUrl": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pH5wWJc3KhdeVQSt86DU31pdcL9c8P88x2FQoKEJVHC/image.png",
                "instagram": "https://www.instagram.com/bancambiosx",
                "linkedin": "https://www.linkedin.com/company/bancambios",
                "medium": "https://medium.com/@bancambios",
                "telegram": "https://t.me/bancambiosx",
                "twitter": "https://twitter.com/bancambios",
                "website": "https://bancambios.exchange/"
            }
        },
        {
            "chainId": 101,
            "address": "5yDLN61f3aCu3b4uh7q4BvF9xG9xLe7o2GGVpMsd9uTR",
            "symbol": "DUNI",
            "name": "DUNNIE COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/platinumshore/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CkD9GDPqME11zrFt7Lk51jdQSuPmP1nRULUWbBYj2Ngq",
            "symbol": "B4C",
            "name": "b4c meme coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkD9GDPqME11zrFt7Lk51jdQSuPmP1nRULUWbBYj2Ngq/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cryptobac",
                "website": "https://cryptobac.me"
            }
        },
        {
            "chainId": 103,
            "address": "FWMTkCUmxa4xstJ6uP6wHHdtrC7sfCMoQNSg3z7rnbR5",
            "symbol": "UST",
            "name": "UST",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png",
            "tags": [
                "UST"
            ]
        },
        {
            "chainId": 103,
            "address": "EMZseDMf96q9PrUBDpMTJSTagbRXdbpNS6kVjcM96RxW",
            "symbol": "LUNA",
            "name": "LUNA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W/logo.png",
            "tags": [
                "LUNA"
            ]
        },
        {
            "chainId": 101,
            "address": "GQjMtTjjpHmUSc4xsmopBg2vRnhoyLtq2Q3SdbuzXaaC",
            "symbol": "LMM",
            "name": "Lion's Mane Media",
            "decimals": 0,
            "logoURI": "https://github.com/kevinianbrady/LMM_Token/blob/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BoEsKPwdGnkXmaav3D29tq3V2Y267JS4neQR8nrVVZSw",
            "symbol": "RTD",
            "name": "Retirement Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/k3nn8y/Coffee-Token/main/BoEsKPwdGnkXmaav3D29tq3V2Y267JS4neQR8nrVVZSw.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9m9eWSGozbJs56zbEzUw8ycnGQurZAcjE33dwXBttZDb",
            "symbol": "OGRE",
            "name": "Ogre Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/levoflax/crypto/main/ogre.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6ryi6dMrAtRdB44yLk9DDcoitJLn7LxtPygJAiYetHqM",
            "symbol": "$WARX",
            "name": "SYBER SPACE WARX",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ryi6dMrAtRdB44yLk9DDcoitJLn7LxtPygJAiYetHqM/logo.png"
        },
        {
            "chainId": 101,
            "address": "8DjZ7TzGjZhi115ZWzhk5T4ttfe4DihxyvgkSeF4n3BK",
            "symbol": "LIH",
            "name": "LIHYA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/metoceto66/crypto/main/pngegg.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3PC4o5eEEKYBZzbYChomH8H5unHaA9YhvDqHpiK41d64",
            "symbol": "creators",
            "name": "creators",
            "decimals": 6,
            "logoURI": "https://creatorpro.live/simbolo-creator.jpg",
            "tags": [
                "creator",
                "creators",
                "creator-token",
                "artist"
            ]
        },
        {
            "chainId": 101,
            "address": "3iH4LXRDMicfqb4TPR99QYzz1d6zyDvuuCWsQZxMH2bi",
            "symbol": "BPTS",
            "name": "Bounty Points",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/Qmf5gyuFsYg7gsHgaXxTebkMGCMKen7tz4Y6pqKLyfFycK?preview=1",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6LzFfRewQXPyUPo1vepAUE5hNC95Qu3AwkXZeRG9R4s6",
            "symbol": "SRNDM",
            "name": "-Serenadium-",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Serenadium/cryptoyeah/main/Serenadium%20Logo.jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SoLotgMEBV3epFVmbM89ERaAs5srvsEN3cghDVTwcmT",
            "symbol": "SOLO",
            "name": "SoLoteria Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLotgMEBV3epFVmbM89ERaAs5srvsEN3cghDVTwcmT/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SoLoteria",
                "twitter": "https://twitter.com/SoLoteriaNFT",
                "website": "https://soloterianft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Ey1Z2N1QPJSCdZfL9ENKaqrsH4c1pXHMeM7XQHh9DQqC",
            "symbol": "ONTOS",
            "name": "Ontos",
            "decimals": 0,
            "logoURI": "https://github.com/bartbrinkman/ontos-token/raw/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HvJxGSNz6f5ytZXCnKzH5kHVTwSG3qmgnd1FSeRzVrGH",
            "symbol": "AOB",
            "name": "Army of Balkan",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MViker/AoB/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CMGQeY9vUS4dh5VELg969BBWSmDfX3gnVjRddttpZErU",
            "symbol": "NNNC",
            "name": "NananaCoin",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/23483887/149228871-0e1f442f-2492-46a4-a6c9-5d6229c890e9.png",
            "tags": [
                "utility-token",
                "nanana"
            ]
        },
        {
            "chainId": 101,
            "address": "CnysaXBy5hDdz9ekaQu4DUqSapn7ZheSRFPUjATVGzBN",
            "symbol": "LGD",
            "name": "Legend",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9s9HnL3HgJegkh8hHBXEsRBU2M8Suu5tTdfXPNDzuKad",
            "symbol": "IPCC",
            "name": "IPCC Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9s9HnL3HgJegkh8hHBXEsRBU2M8Suu5tTdfXPNDzuKad/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CASHedBw9NfhsLBXq1WNVfueVznx255j8LLTScto3S6s",
            "symbol": "sCASH-8",
            "name": "Saber Wrapped Cashio Dollar (8 decimals)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CASHedBw9NfhsLBXq1WNVfueVznx255j8LLTScto3S6s/icon.png",
            "tags": [
                "stablecoin",
                "saber-mkt-usd",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "discord": "https://discord.com/invite/GmkRRKJkuh",
                "medium": "https://medium.com/@cashioapp",
                "twitter": "https://twitter.com/CashioApp",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "EU9aLffrTckFCs16da6CppHy63fAxMPF9ih1erQTuuRt",
            "symbol": "sagEUR-9",
            "name": "Saber Wrapped agEUR (Portal) (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EU9aLffrTckFCs16da6CppHy63fAxMPF9ih1erQTuuRt/icon.png",
            "tags": [
                "ethereum",
                "wrapped",
                "wormhole",
                "saber-mkt-eur",
                "wormhole-v2",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "address": "0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "ageur",
                "description": "Angle is the first decentralized, capital efficient and over-collateralized stablecoin protocol",
                "discord": "https://discord.gg/z3kCpTaKMh",
                "twitter": "https://twitter.com/AngleProtocol",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "Lirav2gsqs7jL1PFRUBp8uKACT8LYjDBV8c6nzchoer",
            "symbol": "TRYB-TRYB",
            "name": "Saber TRYB-TRYB LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Lirav2gsqs7jL1PFRUBp8uKACT8LYjDBV8c6nzchoer/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/bilira"
            }
        },
        {
            "chainId": 101,
            "address": "UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7",
            "symbol": "sUST-8",
            "name": "Saber Wrapped UST (Portal) (8 decimals)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7/icon.png",
            "tags": [
                "wrapped",
                "wormhole",
                "saber-mkt-usd",
                "wormhole-v2",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "address": "uusd",
                "bridgeContract": "https://finder.terra.money/columbus-5/address/terra10nmmwe8r3g99a9newtqa7a75xfgs2e8z87r2sf",
                "coingeckoId": "terra-usd",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "WLPGfDvnSsJg888FydDCRKkmKTwu4L3MHjfqBFj5LJD",
            "symbol": "FRAX-UST",
            "name": "Saber FRAX-UST LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPGfDvnSsJg888FydDCRKkmKTwu4L3MHjfqBFj5LJD/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/frax_ust"
            }
        },
        {
            "chainId": 101,
            "address": "WLPJD4jW6gnWKfwfL5jyJwcxEPHKLMbncNfQwMmkzKz",
            "symbol": "acEUR-agEUR",
            "name": "Saber acEUR-agEUR LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPJD4jW6gnWKfwfL5jyJwcxEPHKLMbncNfQwMmkzKz/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/aceur_ageur"
            }
        },
        {
            "chainId": 101,
            "address": "WLPv9tHDgkx3ekW8Kyp1TC222oYpr5BMZXTyBTLbk2n",
            "symbol": "FRAX-CASH",
            "name": "Saber FRAX-CASH LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPv9tHDgkx3ekW8Kyp1TC222oYpr5BMZXTyBTLbk2n/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-cashio"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/frax_cash"
            }
        },
        {
            "chainId": 101,
            "address": "monKYjV2bHTjbJVWCCcwhxE8C96sdTKR2HUNUHCjh4z",
            "symbol": "daoSOL-SOL",
            "name": "Saber daoSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/monKYjV2bHTjbJVWCCcwhxE8C96sdTKR2HUNUHCjh4z/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/monkedao"
            }
        },
        {
            "chainId": 101,
            "address": "son4WQ39xri8sqMqNQZAEtEEPTuUcCRHg7t1ZcYdkSw",
            "symbol": "JSOL-SOL",
            "name": "Saber JSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/son4WQ39xri8sqMqNQZAEtEEPTuUcCRHg7t1ZcYdkSw/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/jsol"
            }
        },
        {
            "chainId": 101,
            "address": "8hc4d6bZrqMNmJykhBRGuZCCiadYKCZhPEg7Zi9Zme1m",
            "symbol": "KAL",
            "name": "Kalmunity Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8hc4d6bZrqMNmJykhBRGuZCCiadYKCZhPEg7Zi9Zme1m/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5NewpkvmyAtHWayn5nx4ob5rhWBxZXriiYMtwLkqZkmR",
            "symbol": "RCC",
            "name": "Rabimbi Caravan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/diogoquaresma/crypto/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
            "symbol": "SBR",
            "name": "Saber Protocol Token (Devnet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg",
            "extensions": {
                "coingeckoId": "saber",
                "discord": "https://chat.saber.so",
                "github": "https://github.com/saber-hq",
                "medium": "https://blog.saber.so",
                "twitter": "https://twitter.com/saber_hq",
                "website": "https://saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "iouQcQBAiEXe6cKLS85zmZxUqaCqBdeHFpqKoSz615u",
            "symbol": "SBR",
            "name": "Saber IOU Token (Liquidity Mining Rewards)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg",
            "extensions": {
                "coingeckoId": "saber",
                "discord": "https://chat.saber.so",
                "github": "https://github.com/saber-hq",
                "medium": "https://blog.saber.so",
                "serumV3Usdc": "HXBi8YBwbh4TXF6PjVw81m8Z3Cc4WBofvauj5SBFdgUs",
                "twitter": "https://twitter.com/saber_hq",
                "website": "https://saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "Fa4QEWtRXdjDAZqk3D6RwCCWFYmsx5aozNzpdMH6WEyr",
            "symbol": "SIQUTA",
            "name": "Siquta",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fa4QEWtRXdjDAZqk3D6RwCCWFYmsx5aozNzpdMH6WEyr/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "github": "https://github.com/defihq",
                "website": "https://defihq.org/"
            }
        },
        {
            "chainId": 101,
            "address": "653BVmeqX21t8d7RqFRHSnuAjJWZPyhgJ6PdzUNPDcPF",
            "symbol": "MAKCN",
            "name": "MakerCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JamesDooley1/crypto/main/MakerCoin%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9vSpFqM7Tg1DdqX4jH5xJjF9VHRZHuSHWLFcDSvAqkyX",
            "symbol": "CRIS",
            "name": "Cris Coin",
            "decimals": 0,
            "logoURI": "https://github.com/gatekeeperuk/crypto-criscoin/blob/main/CClogo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DqHffZXd75rC3zrvpMveMowqerZcQwp5i1PA9orLJvgu",
            "symbol": "METT",
            "name": "Mettcoin",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/0qrbGfh/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "sHTmZs1eUp4pbZJbCdDMbBTwDtnPouGR2KT4ysA8s8y",
            "symbol": "SBICK",
            "name": "SBI CK Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/sgniceguy/staticfiles/main/SBI%20DM%20Logo%20Only.png"
        },
        {
            "chainId": 101,
            "address": "DUE8oA4YqJ3UysJWidiFybFKuszxw72kgjeKWrJLtquo",
            "symbol": "BPZ",
            "name": "BoppZ Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/chouli12/BPZ-Token/main/20hoihgoubrwpiwepoijwf%3Bn.jpg",
            "tags": [
                "utility-token",
                "nft-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BoppZNft",
                "website": "https://boppznft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "buckF8MnsB6QikWhBZe4jNLEbyrw4TyPP9XkLZ6QxkJ",
            "symbol": "RBUCKS",
            "name": "Rbucks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/buckF8MnsB6QikWhBZe4jNLEbyrw4TyPP9XkLZ6QxkJ/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/rbucks",
                "website": "https://rbucks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7Cw9BurQ3gwCNRaUoakZjpFzmmfDh9AH64MrawasktYS",
            "symbol": "TKSE",
            "name": "Token Seale",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kseale14/token-seale/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D3KdBta3p53RV5FoahnJM5tP45h6Fd3AyFYgXTJvGCaK",
            "symbol": "usdt",
            "name": "usdt",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3KdBta3p53RV5FoahnJM5tP45h6Fd3AyFYgXTJvGCaK/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether",
                "serumV3Usdc": "77quYg4MGneUdjgXCunt9GgM1usmrxKY31twEy3WHwcS",
                "website": "https://tether.to/"
            }
        },
        {
            "chainId": 101,
            "address": "4WhpEbHAxXFuvEiWgY6UdjKFiFddCABXpVeALS6a5MfS",
            "symbol": "FROGGIE",
            "name": "FROGGIE",
            "decimals": 9,
            "logoURI": "https://arweave.net/hoTwLS0aeJohF6GoFGQC7ExBHdpaqeIgg0wN711C4Pw?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/FrogClubb",
                "website": "https://frogclub.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "2VFkmFpyFm9DMKch4UdrdyY7WHLGrWgwJLAdKwHd5ekY",
            "symbol": "OODA",
            "name": "OODA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/OODA-X/ooda/main/OODA%20Coin%20Logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6UfZBKm9y9vS7cZ3wj4hXyiVeKNu27rWapu4bAUsPdcx",
            "symbol": "NEWT",
            "name": "New Transformers",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6UfZBKm9y9vS7cZ3wj4hXyiVeKNu27rWapu4bAUsPdcx/logo.png",
            "tags": [
                "payment-token",
                "utility-token",
                "nft",
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2V1AVjDVM2gZn72ZufG2HfFHDKXzS5XaCupNeKrdcruT",
            "symbol": "ATL",
            "name": "Akuma Serpent",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/assets/main/assets/trident.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wrdcRgdmA5",
                "twitter": "https://twitter.com/AkumaSerpents",
                "website": "https://www.akumaserpentsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "75sm2rQ8ZUwMjqSr8e68fFBMKfHpwDNBfZ9aaW2M9E5y",
            "symbol": "MBAG",
            "name": "Moon Bag",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/officialmoonbag/logo22/main/logo22.png",
            "tags": [
                "MEME-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BikKd7FNs7xdKFZjFUida6KD4uKcH4mTm4DN2HoKqL2D",
            "symbol": "INFH",
            "name": "Inflation Hedge",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kiranz38/SolTok/main/InfFlat.ico",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D6e7QftKXzFuxUZxoBccrcgqbgaSDQyWZFawvQgkbi22",
            "symbol": "PAN",
            "name": "PanCoin",
            "decimals": 0,
            "logoURI": "https://github.com/otiszeon/pancoin/blob/main/logo.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G4PTvGzukR8HeRRuZ1Covyt6H4uzD1yzuHKvWTFJyX6Y",
            "symbol": "KALT",
            "name": "Official Kalmunity Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G4PTvGzukR8HeRRuZ1Covyt6H4uzD1yzuHKvWTFJyX6Y/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FSrVG9VcTJuWr7tw9DV7hEg5NQjFgZ5g53KBG7uLi7NK",
            "symbol": "CIN",
            "name": "Cinder",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/hockaclu/cindericon/main/logo.png",
            "tags": [
                "payment-token",
                "social-token",
                "nft",
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GjzmNzFSyLVqvwe3cKWK7SghXQ32hHuEMAfYrPWjHU99",
            "symbol": "HOPE",
            "name": "H0PIUM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NigelSamhain/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H66XBfbJxQfdBRjoatYH2s8uD7gyaNWmVBbujHkj95tU",
            "symbol": "DWT",
            "name": "Dream World",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H66XBfbJxQfdBRjoatYH2s8uD7gyaNWmVBbujHkj95tU/logo.png",
            "tags": [
                "utility-token",
                "DWT"
            ]
        },
        {
            "chainId": 101,
            "address": "BqTiHsoU6J3tfSDa8J6LSiPfmGDAukUEhiPMpBmakT8y",
            "symbol": "MNY",
            "name": "Muniy",
            "decimals": 0,
            "logoURI": "https://github.com/londonprojects/muniycoin/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8rgbVTxkx8anUS9Ged9hs5EsHZoC2xMiucc9q54tyV5N",
            "symbol": "ENTH",
            "name": "Enthusiast DAO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Shivam1337/ETHUSIAST/main/ENTH.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ankhim7kPXxLKVbW1Tn7vH4mLTuvCAqHjhkKuvwWJ7b",
            "symbol": "ANKH",
            "name": "ANKH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ankhim7kPXxLKVbW1Tn7vH4mLTuvCAqHjhkKuvwWJ7b/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/SecretSphinxes",
                "website": "https://secretsphinx.io"
            }
        },
        {
            "chainId": 101,
            "address": "58dV5UScqXrroUg5wvwiWQWpnndUM482b1QBRDVycFAT",
            "symbol": "CRABS",
            "name": "Crabs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vsilent/crabs/main/crabs.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.crabs.cash/"
            }
        },
        {
            "chainId": 101,
            "address": "H51zcP4KqEZuR5ZnTSBtuNWaZbGGzL3kZDL6sJg4KpuS",
            "symbol": "OPTS",
            "name": "OPTIMUM",
            "decimals": 0,
            "logoURI": "https://q4r2u6z6.stackpathcdn.com/wp-content/uploads/cube_optimum.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ACABzWtc21if4KMvCKNDWNQK9h27SZezzuxMLLPT34JJ",
            "symbol": "WISE",
            "name": "WiseCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BLA4KM4MBA/wisecoin/main/wisecoin%20(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HUbEX1fVK8SvmKYkNM3DNonrrpxjNzmyEZSjhmjGyqix",
            "symbol": "HBX",
            "name": "HUBEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUbEX1fVK8SvmKYkNM3DNonrrpxjNzmyEZSjhmjGyqix/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/hubexnetwork",
                "website": "https://hubex.org"
            }
        },
        {
            "chainId": 101,
            "address": "mZfGzkZ61rjS4UWYwwhPnbJZxfaofUioTAPKvewQHEp",
            "symbol": "SOLGAME",
            "name": "SOLGAME",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mZfGzkZ61rjS4UWYwwhPnbJZxfaofUioTAPKvewQHEp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4tFosJWH3RbuXNxYpPDPL72aP3WVJM8VbrocZ1txkJ44",
            "symbol": "AOC",
            "name": "AlphaOmega Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/moin-afk/alphaomega/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BVnXMKfCzbdpqpYwuqgZURvFy2xh9aSu4LMhfihJG78b",
            "symbol": "UST",
            "name": "UST (devnet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png",
            "tags": [
                "UST"
            ]
        },
        {
            "chainId": 103,
            "address": "CjH3ukAUNpkJCbUK5KwoecHrFWJ9Zpn6p25AHj9gv7xb",
            "symbol": "LUNA",
            "name": "LUNA (devnet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W/logo.png",
            "tags": [
                "LUNA"
            ]
        },
        {
            "chainId": 101,
            "address": "Do9mNqryUMyuCLM2avYcimiMtbND7Gyce4z2MGp4VHQq",
            "symbol": "LZRD",
            "name": "Lizardz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Do9mNqryUMyuCLM2avYcimiMtbND7Gyce4z2MGp4VHQq/logo.png",
            "tags": [
                "utility-token",
                "NFTs",
                "Boryoku-Lizardz"
            ]
        },
        {
            "chainId": 101,
            "address": "5mcRqyofvBiFm2Dt9AG2DRvJq1BkM6D3VX93u2DWMhsJ",
            "symbol": "RP",
            "name": "Rogue Panda Token",
            "decimals": 4,
            "logoURI": "https://c5kh5ocamc7ov5pchbjdj5pikijb7toenkhpy345svfir6d7naoa.arweave.net/F1R-uEBgvur14jhSNPXoUhIfzcRqjvxvnZVKiPh_aBw",
            "tags": [
                "utility-token",
                "governance-token",
                "gaming",
                "NFTs"
            ],
            "extensions": {
                "twitter": "https://twitter.com/rogue_pandas",
                "website": "https://roguepandas.io/"
            }
        },
        {
            "chainId": 101,
            "address": "RichA8cRzwUT9RgsPtT1JBek9FqmnSuKuEnzJjMHQ2S",
            "symbol": "$RICHES",
            "name": "Rich Ostriches",
            "decimals": 11,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RichA8cRzwUT9RgsPtT1JBek9FqmnSuKuEnzJjMHQ2S/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/OstrichNFT",
                "website": "https://ostriches.io"
            }
        },
        {
            "chainId": 103,
            "address": "EyUnX5CPs1y1eQTiMHt4GKBVWDc96NyPGnrKL6xrSBmE",
            "symbol": "CHERRY",
            "name": "CHERRY (devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EyUnX5CPs1y1eQTiMHt4GKBVWDc96NyPGnrKL6xrSBmE/logo.png",
            "tags": [
                "CHERRY"
            ]
        },
        {
            "chainId": 101,
            "address": "6tbRiyDzRDDmhgrsLzAsm13bZ1vpESsT2zrx68mYS5vX",
            "symbol": "CRTS",
            "name": "Carrots",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thambusamy/crypto/main/carrot.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "UMpAaqxAVEkkXCnoMu2rbGiP8B3QfX4P9XqDtT9k5G7",
            "symbol": "BPS",
            "name": "Black Pearl Society Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UMpAaqxAVEkkXCnoMu2rbGiP8B3QfX4P9XqDtT9k5G7/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cptblackseazar"
            }
        },
        {
            "chainId": 101,
            "address": "GiXP9ZRsXwwDjtUdTB8UJ53Gq1KeHtVzKmwSbJh7URk3",
            "symbol": "GRMC",
            "name": "Gray Man Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anfesoft/grm/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SPD2Ha9rusWCvzKN1NNhbmsFBrQvPQTKGMWL1onmyKJ",
            "symbol": "SPD",
            "name": "South Park Degens Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPD2Ha9rusWCvzKN1NNhbmsFBrQvPQTKGMWL1onmyKJ/logo.png",
            "extensions": {
                "website": "https://twitter.com/southparkdegens"
            }
        },
        {
            "chainId": 101,
            "address": "rMMASpNW7YDfNMGjHqGtSjwcEuPygbTDCgdNNxnipNp",
            "symbol": "rMMA",
            "name": "redeemable MMA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rMMASpNW7YDfNMGjHqGtSjwcEuPygbTDCgdNNxnipNp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Cross-chain Gaming SuperGuild building the tools of the future. Redeemable token to be redeemed for $MMA upon closure of the upcoming IDO",
                "discord": "http://discord.gg/mmagaming",
                "medium": "https://medium.com/@MMAGaming",
                "twitter": "https://twitter.com/MetaverseMining",
                "website": "https://mmagaming.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Rq2M27fJBNYeLbJqjYwEmMqQAC2UWxdAA83MxgDBVGo",
            "symbol": "JOEY",
            "name": "Joey's Bits",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/JoeyTatu/crypto/20f86361ba6ccc3e1105d211393eaa2ea08d2456/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/TattedFaceJoey",
                "instagram": "https://www.instagram.com/TattedFaceJoey",
                "linkedin": "https://linkedin.com/in/JoeyTatu",
                "reddit": "https://www.reddit.com/user/TattedFaceJoey",
                "telegram": "https://t.me/TattedFaceJoey",
                "twitter": "https://twitter.com/TattedFaceJoey",
                "website": "https://linktr.ee/TattedFaceJoey"
            }
        },
        {
            "chainId": 101,
            "address": "Heho2JqtUx8jSAti49QshC6qxgWwwrzPWeQrRG4QsR35",
            "symbol": "JTC",
            "name": "JT Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Turner747/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AJHsQJQ3sJ9T1GKXrRz2ZJKGzRysQwF1xQxUmS6xmdqC",
            "symbol": "SLT",
            "name": "Solatube",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoSol1/cryptosol/main/kindpng_34058.png",
            "tags": [
                "multimedia-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HMyu1LU4tzBUVMwgficGQsR2DZqVR6SjqznzpWeVU5R7",
            "symbol": "PG7S",
            "name": "pg7scorp",
            "decimals": 0,
            "logoURI": "https://github.com/ArtiePeterson/crypto/blob/main/ArtieJesse__pg7s__logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Dujewcxn1qCd6rcj448SXQL9YYqTcqZCNQdCn3xJAKS",
            "symbol": "ARTE",
            "name": "ARTE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ArtPunks/ARTE-token/main/assets/token_512.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "arte",
                "description": "Utility token which is the fuel of the blockchain-art ARTE ecosystem.",
                "discord": "http://discord.gg/artpunks",
                "medium": "https://medium.com/@artpunknft",
                "serumV3Usdc": "4wqFtfechUSNW1nJ3WK5ogy478GTa66H99RrKnz45fa9",
                "twitter": "https://twitter.com/ArtPunksNFT",
                "website": "http://artpunks.fun/"
            }
        },
        {
            "chainId": 101,
            "address": "7ncPHXL5RMae3c6u59Mq2kgMFL5VHsR9m3Zy8dB91Fqe",
            "symbol": "CRYP",
            "name": "CryptoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/weissnichtmehr/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FT4nC3F1Nm2KwNyANcggnuiLSgeBkV7eNoZTmRZrimxJ",
            "symbol": "URKK",
            "name": "You rock coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lilypad219/crypto-2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BLBUQ3w8cDx5ykNNeTuaSvMJ3Sz322hHKbHQAaccdwNv",
            "symbol": "CHBIG",
            "name": "CHBIG",
            "decimals": 0,
            "logoURI": "https://github.com/Chbig/chbig-crypto/blob/c0a38352b1f2a8dc4f1525b937bd1ff066a8502b/c.png?raw=true",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Token distribué par Chbig pour sa communauté de Chbigonaute"
            }
        },
        {
            "chainId": 101,
            "address": "8wfzPPnT2GvRD5Hhf7YG8Ge8xENCGfcoKQXCDx4wkjVi",
            "symbol": "MT",
            "name": "Munch WhiteList Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8wfzPPnT2GvRD5Hhf7YG8Ge8xENCGfcoKQXCDx4wkjVi/logo.png"
        },
        {
            "chainId": 101,
            "address": "6YxDnUQLMaiMgHFu9yRb2G761FSVjhHjeKaW7qMSApxo",
            "symbol": "XNR",
            "name": "xynorium",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6YxDnUQLMaiMgHFu9yRb2G761FSVjhHjeKaW7qMSApxo/logo.png",
            "extensions": {
                "discord": "https://discord.gg/ZYJMefnGTr"
            }
        },
        {
            "chainId": 101,
            "address": "3J6hEEQvwspX4sFAhJybAA9ZTkTBFJ838FJEid2ST8bR",
            "symbol": "BNDI",
            "name": "Bondii",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jorgadan/bondii-crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Social token which is the fuel of the blockchain-market Bondii ecosystem.",
                "website": "https://bondii.net/"
            }
        },
        {
            "chainId": 101,
            "address": "ALmnNrE4xVdXvRQEJQFBCz7fALFT3vv2m57byB5goXb1",
            "symbol": "LDTMPG1",
            "name": "LDT Mint Pass G1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALmnNrE4xVdXvRQEJQFBCz7fALFT3vv2m57byB5goXb1/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "GBFnNpraMUqZXuwqvkFPh7eSwcW6nffGjRhUPNw4BJQt",
            "symbol": "SOC",
            "name": "Society Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dualitycsgo/societytoken/main/societylogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "938xXsPKhBAXDLUfWNYuZdsnj57oGF6fCz8P6yLom19f",
            "symbol": "NURP",
            "name": "NURP Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/938xXsPKhBAXDLUfWNYuZdsnj57oGF6fCz8P6yLom19f/logo.png",
            "extensions": {
                "discord": "https://discord.gg/4fBqbZF7cS",
                "twitter": "https://twitter.com/NovaNerdsNFT",
                "website": "https://novanerds.art"
            }
        },
        {
            "chainId": 101,
            "address": "4dC3xeuqp87kfGBpvviDHt4Fu5efqV7cJzWHmbPZ71zg",
            "symbol": "AINA",
            "name": "AiNation Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SaigatamaAiNA/AiNation-Coin/main/logo.png",
            "tags": [
                "social-token",
                "gaming-token",
                "NFT-Project",
                "multimedia-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Eknc379wsuM24aYvsSADP6ZYHjwJVHUm8V2w2Htoj5Zi",
            "symbol": "DLC",
            "name": "DONTLOOKCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Disease5556/Crypto/main/DONTLOOOkMINI.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G12t3QQMDm1Rd4msvgfpKuja52tdTu9oRXVwCfx9Y8jW",
            "symbol": "SPYX",
            "name": "Schnitzel",
            "decimals": 69,
            "logoURI": "https://raw.githubusercontent.com/Spyx34/sol_token/main/Token_pfp.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Fun Token for the Schnitzel Server",
                "discord": "https://discord.gg/uN8UezwmRd"
            }
        },
        {
            "chainId": 101,
            "address": "7KNgRC9LTPoXWUrUnYqQfJdUBFoegfGQzQsT3urYQocx",
            "symbol": "IAN",
            "name": "IAN Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ianicoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4gYtj4tPrHFxK4KSeDFf78RfL2Kr59PueHTgWT4qznvg",
            "symbol": "STNC",
            "name": "Satan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/templeofnight/Satan-Coin/main/Satan%20Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4XEvxRmF7T61MuA4yik4b8eVbsLChLk82qJQ12mFeAgZ",
            "symbol": "IRIS",
            "name": "IRIS Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4XEvxRmF7T61MuA4yik4b8eVbsLChLk82qJQ12mFeAgZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Iris_Impact"
            }
        },
        {
            "chainId": 101,
            "address": "DF6UGWcgLQEtHq1LXrUpLD1VhuZspa7u4aGQi4fCiotR",
            "symbol": "HWDC",
            "name": "Heidi Wet Dog Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mdmolone/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HpnQBMHCr6SVm16xp3uiFcKDwQsWykvU8SJPHqRBRD8p",
            "symbol": "FUBO",
            "name": "Fubo Libs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fubolibs/crypto/main/logo.jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8eKJx4oPC1ccV2wH4QHAfmq9nVoHHF8UwVKnPFiZQBDi",
            "symbol": "GTFO",
            "name": "GTFO Outfitters Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/gcstanley/GTFOCoin/main/GTFOCOIN.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "GTFO Outfitters Coin.",
                "instagram": "https://www.instagram.com/gtfocoolers/",
                "twitter": "https://twitter.com/gtfocoolers",
                "website": "https://www.gtfooutfitters.com"
            }
        },
        {
            "chainId": 101,
            "address": "9qDohSsZfimv2indBmyxcdwi26NN3fDznu6tycCjzr5z",
            "symbol": "FOTS",
            "name": "Fotsbeats",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9qDohSsZfimv2indBmyxcdwi26NN3fDznu6tycCjzr5z/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.fotsbeats.org"
            }
        },
        {
            "chainId": 101,
            "address": "3ACRcvMCrSDi7qtnCD8yh78CVrR1sTYmtcpMwcqaxMyc",
            "symbol": "VAMP",
            "name": "Vampire Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/templeofnight/VampireCoin/main/VampireCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PJf2Q1XfRnkmjGUPbAstV7erEvSkPwBFkudSfh7jNcE",
            "symbol": "3VIL",
            "name": "Prime3vil",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Prime3vil/Prime3vil-Token/main/logo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8oJ4nGEJmGCq8RikM3R8wdAN8d8ZxrhMYpbUPYzCMgFq",
            "symbol": "RPC",
            "name": "RED PANDA COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8oJ4nGEJmGCq8RikM3R8wdAN8d8ZxrhMYpbUPYzCMgFq/logo.png",
            "tags": [
                "meme-coin"
            ],
            "extensions": {
                "discord": "https://discord.gg/7av9SK7nZq",
                "twitter": "https://twitter.com/Redpandacoin",
                "website": "https://redpandacoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "DCTf1hWKWG5qsq66rH95JCm4kYgVM3QB9HGyiGSDnFU9",
            "symbol": "TSRB",
            "name": "Official TSRB Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JakovGlavac/TSRB-Coin/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://tsrb.shop"
            }
        },
        {
            "chainId": 101,
            "address": "GLdGUVd1r36cGNUAbNKXSPUp5jziJqend7ZURsqwmh1c",
            "symbol": "JINX",
            "name": "Jinx Mainecoon Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/drenieri/crypto/main/Jinx.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/drenieri"
            }
        },
        {
            "chainId": 101,
            "address": "G4UNF8zz3tFe3GELQXevugQrKQS2hMubs2fPZ4AEUKwH",
            "symbol": "CEH",
            "name": "CEH Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JKzData/CEH/main/CEHLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fu29AZUeQjBm7Qzi22BNpZGGzy3zbgue333V8gLMfVF",
            "symbol": "TESLER",
            "name": "TESLER",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/TeslerToken/token-list/main/assets/mainnet/Fu29AZUeQjBm7Qzi22BNpZGGzy3zbgue333V8gLMfVF/logo.png",
            "tags": [
                "TESLER",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.teslertoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "BTYw4bw5nZ3zd69ykg1An83ModKjcWJrPCUgDb4qSk9S",
            "symbol": "SOLARIS",
            "name": "SolarisCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/asherlagemi/crypto/main/logo.jpg",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "HD15N33gy4xYycqDA7Fgo4MqveVCvPaXUUCZAXizK1Pc",
            "symbol": "ENC",
            "name": "Encore Smile",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fung-jay/crypto/main/SmileUpv3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7JT45Qyrt9oQ2LtFugGjxKG2ye38R6tpD3TUfh5d1CgM",
            "symbol": "CHOMP",
            "name": "CHOMP",
            "decimals": 9,
            "logoURI": "https://arweave.net/74yVivx_qkvZzVM8a42tsEzPJ5rCQ1PRbofvKG3e7aY?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/ShkarySharks",
                "website": "https://shkarysharks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6KrfCrbzHx64PGfp8ZceRnNoV64D9asqbNZxkQraZRkT",
            "symbol": "CRGO",
            "name": "Cargo Currency",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/CargoCurrency/Cargo-Currency/main/cc.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FVVnRj8CXHStXv6LAbWc7y4xp7Cd2LwQVspnmw3uD3LX",
            "symbol": "VM13",
            "name": "VM13 Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anko13-vm/crypto/main/vm13koinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GToajNpiQeiLhcseDfBHqdPe8A86gs7VNtJ7S6H3rxDb",
            "symbol": "BCKP",
            "name": "Backprocess",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/flamacore/bckptoken/main/BCKP_Token_Logo_Final.png",
            "tags": [
                "social-token",
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "fc46FW2p3XZu19ak4mWqi8cXdKgexNZmgBnR5F4uyRv",
            "symbol": "NCTI",
            "name": "Nacati coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vincc09/crypto-nacati/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AoZN1Qr9nRZUNF19xAKKdTPhYyDRVNo6vQAgq473AzHB",
            "symbol": "TibaDM",
            "name": "TibaDM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mantalban/CryptoDM/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6fMoTH7Bad61GPzvqfq1XmRGFGv84MrvZfjDKGNuVwBz",
            "symbol": "OXSQ",
            "name": "Ox Squad Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/juandelia03/logo/main/icon400x400.jpg",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "FpxVovi3t3ACw2sDCCXMTy7X5E9ZaA5jLvUHroz48Dtf",
            "symbol": "BNTA",
            "name": "BNTA coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nmclaren/crypto-bnta/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FS2xCPvnrRRgXHsyQ3WcjRwUNyWDdY5R9TwTFXThVPoa",
            "symbol": "GAMPX",
            "name": "Grown Ass Man Points",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/drstreamy/manpoint/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ao9Grhjn2znhaxXTvTjH7XvktggbDqHqheEojRj6fQ7u",
            "symbol": "PIPC",
            "name": "Partners In Pleasure",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rstolwijk/crypto/main/logo-pip.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DzHhUCbv5dzUEJF5qrCt3MCqZgXBoavbJ1BisqB5nVSa",
            "symbol": "PATENT",
            "name": "PATENT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BEN1199/patent/3cf89952f1f82dbe864ec81683aac6e5eebe4e00/rsz_13patent.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "description": "The first NFT-based patent issued on Solana. The patent holds essential guidelines to reconstruct parts of human muscle and bone tissues in laboratory setting. The Dawn of the Decentralized Intellectual Property."
            }
        },
        {
            "chainId": 101,
            "address": "3uCj7dSTKHMEgSdn6tPm7pfVS2YpJLZCfRFNcbsn9Uko",
            "symbol": "FTCT",
            "name": "FatCat",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cc4020/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3pZgVRafhfoYwwKvu6UVtJ79Jxa2ooyuHFP7Ydt1t1e5",
            "symbol": "DPSTK",
            "name": "Doops Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/patdoops/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "7Yox5Rc2B1PnCM8LZXKaHey4M7RzDQe2W9TaUSAuLUuE",
            "symbol": "WPT",
            "name": "World Population Token",
            "decimals": 0,
            "logoURI": "https://github.com/Stibra1/crypto_ww/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AuY1RqejPvC5ftzvMRerBaR5ExfqFoq7pve3cmGZ8et1",
            "symbol": "SERPENT",
            "name": "Serpent",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/serpentnatura/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SerpentAcademy",
                "website": "https://serpent.academy"
            }
        },
        {
            "chainId": 101,
            "address": "PsyFiqqjiv41G7o5SMRzDJCu4psptThNR2GtfeGHfSq",
            "symbol": "PSY",
            "name": "PsyOptions",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/32071703/149460918-3694084f-2a37-4c95-93d3-b5aaf078d444.png",
            "extensions": {
                "coingeckoId": "psyoptions",
                "discord": "https://discord.gg/Ew3dq7dKwp",
                "serumV3Usdc": "CzuipkNnvG4JaTQPjgAseWLNhLZFYxMcYpd2G8hDLHco",
                "twitter": "https://twitter.com/psyoptions",
                "website": "https://psyoptions.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GoAwpsL4SjGMd7BAMq2QKbHvEjgPcbmAwAjg1wpT7UDv",
            "symbol": "NZWT",
            "name": "New Zoolana Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoAwpsL4SjGMd7BAMq2QKbHvEjgPcbmAwAjg1wpT7UDv/logo.png",
            "tags": [
                "Zoolana"
            ],
            "extensions": {
                "website": "https://www.zoolana.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5yVeTqWmb81b7rCDiLfbEAXK3Uc7QUd1UpMB7M6ukDd8",
            "symbol": "BKMR",
            "name": "BuckMaster Token",
            "decimals": 0,
            "logoURI": "https://github.com/mat1776/crypto/blob/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2TALdGYM8z3CPtQhSKsJWEYVpPWG1A3jUCha6Y7wtKbT",
            "symbol": "SHARE",
            "name": "Project Shares",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/col000r/Tokens/main/ProjectShares.png",
            "tags": [
                "general-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/projectshares",
                "website": "https://www.projectshares.org"
            }
        },
        {
            "chainId": 101,
            "address": "HGT2rtUUeWf2ZMVanN6Uipy1Qa7ZTWx2fogBc5WhsX2j",
            "symbol": "SHARD",
            "name": "Project Shards",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/col000r/Tokens/main/ProjectShards.png",
            "tags": [
                "general-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/projectshares",
                "website": "https://www.projectshares.org"
            }
        },
        {
            "chainId": 101,
            "address": "J7Whk3zvFdWHgnxeiccMiwqdMu8Uv7rVwYPaxjoRGvP",
            "symbol": "GSO",
            "name": "Get Shit On",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J7Whk3zvFdWHgnxeiccMiwqdMu8Uv7rVwYPaxjoRGvP/logo.png",
            "tags": [
                "gaming-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BasBheDvnr7HSd8PR6ZDrwXTHTH7e2jB3a7ZymbTHBU8",
            "symbol": "BGT",
            "name": "Baliarena Global Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Baliarena/baliarena/main/baliarena.png",
            "tags": [
                "hybrid-token",
                "metaverse-token"
            ],
            "extensions": {
                "website": "https://www.baliarena.com/"
            }
        },
        {
            "chainId": 101,
            "address": "H1HuyAvxPcbT6nGW8o4jENjZgDdNmq6DJSXwTbGHwNaQ",
            "symbol": "JUPITER",
            "name": "Jupiter",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/SergeyDobrinyn/token/master/files/jupiter.png",
            "tags": [
                "social-token",
                "currency-token",
                "community-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BpatisxZgzsxZy3ioh9Tr2QhuQBpaWHoJRWCyMzpCb6y",
            "symbol": "FASI",
            "name": "Fondue Sigi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adriansigrist/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9eDpvgE2X6ZdczWiFhkL6dRf2tHzqLwovnW72nrNgqst",
            "symbol": "KHC",
            "name": "kyleharmoncoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KyleBlakeHarmon/crypto/main/kyleharmoncoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ct7mbdwLmdFC6zgVRXFidvvgYbtGo2icsntNSSgzxoLs",
            "symbol": "SXS",
            "name": "SoldierXSolvivor Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ct7mbdwLmdFC6zgVRXFidvvgYbtGo2icsntNSSgzxoLs/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/solxsol",
                "twitter": "https://twitter.com/soldiersolvivor",
                "website": "https://soldierxsolvivor.com"
            }
        },
        {
            "chainId": 101,
            "address": "Dm5CqmNL7FgYFWU8pM1uMFPZaRezYXYbFHfoTkMjUoch",
            "symbol": "DRAGZ",
            "name": "DRAGZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/parjol13/drags-token/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "13R5VZw3wYHYL8fsZYecNBBimKU4rX6heg8s4Lng17S9",
            "symbol": "WXT",
            "name": "woxobit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/andbandu/woxobit/main/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6hYVVDjYXSZmc4E44QdN2r37gnahGxvbcjJgkrQs5svP",
            "symbol": "CHERRY",
            "name": "Cherry Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6hYVVDjYXSZmc4E44QdN2r37gnahGxvbcjJgkrQs5svP/logo.png",
            "tags": [
                "charity-token"
            ],
            "extensions": {
                "website": "https://cherry.charity/"
            }
        },
        {
            "chainId": 101,
            "address": "EvDK7FEwacWP9WgyAVXXXpJf5w7ycpZkuacV56dzGyrx",
            "symbol": "FAPE$",
            "name": "FAPE Token",
            "decimals": 9,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/7a51a4bf-1fb0-493c-6e9e-0624274cee00/public",
            "extensions": {
                "discord": "https://discord.gg/swWYcCMvdK",
                "twitter": "https://twitter.com/facelessapes444",
                "website": "https://www.facelessapes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "71wTsTQJS2BPhduyXmZZkSUwpVDGnSr12t7bVfJQ5pZR",
            "symbol": "PG",
            "name": "PARO GHUB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/acecard15/LOGO/main/logo.png",
            "tags": [
                "community-token",
                "solana",
                "philippines"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Paro-Paro-G-HUB-100917682527562",
                "telegram": "https://t.me/+t58ZtvzV7kYwMjg1",
                "website": "https://www.paro-ghub.site"
            }
        },
        {
            "chainId": 101,
            "address": "CZKnYioKuX2YzA2wnUMVXsSe3j259aaPsz7TfY2xnLmV",
            "symbol": "SNK",
            "name": "SolSnake",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CZKnYioKuX2YzA2wnUMVXsSe3j259aaPsz7TfY2xnLmV/logo.png",
            "tags": [
                "utility-token",
                "meme-token",
                "dex"
            ],
            "extensions": {
                "discord": "https://discord.io/solsnake_solana",
                "telegram": "https://t.me/solsnake_solana",
                "twitter": "https://twitter.com/solsnake_solana",
                "website": "https://solsnake.net"
            }
        },
        {
            "chainId": 101,
            "address": "8M5WyUy6nP225gYeukUPEbUsCAu19wREBQ765umhc3Qh",
            "symbol": "EZS",
            "name": "EZsmarts Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/EZsmarts/Solana-repo/main/EZsmarts_Token_icon.jpeg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/groups/ezsmarts",
                "instagram": "https://www.instagram.com/ezsmarts",
                "linkedin": "https://linkedin.com/company/ezsmarts",
                "telegram": "https://t.me/ezsmarts",
                "website": "https://www.ezsmarts.io"
            }
        },
        {
            "chainId": 101,
            "address": "4ZcdzZny4mVrCcF2hhyRhZ6Gp4p2xp6c1mJGK77hcpMn",
            "symbol": "RJDR",
            "name": "Richard del Rosal",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/rjdr-002.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/iqrwizard/",
                "website": "https://ilov.eu"
            }
        },
        {
            "chainId": 101,
            "address": "9k7VK2YpTfaAd6LqL6GdyAKu9oSoXy4CRLZHuwjk6ah4",
            "symbol": "NEOT",
            "name": "Neo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TeoN13/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "219cYoBAeWj2se9dnaAjQK9Xpy8ivX8835pAweZyMqVb",
            "symbol": "DMC",
            "name": "DMC Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ace0nBase/Logo-/main/LogoLogo1png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GmHjFJoH5WqkKRQY7ZadwnPm2SqnEwG5aL8EddXRkydx",
            "symbol": "NAZT",
            "name": "Naz Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nazmulababy/crypto/main/NazT-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2GPWmqQNmaAL8Vj48ETdjeuXd62snKMyZXGyGWjd3Ugw",
            "symbol": "RIG",
            "name": "Rigel",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Stibra2/rigel/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5rcRMZvSZrNkvwtakUoSU1aLTRkQMmdrZgG7M3vEo4kK",
            "symbol": "AA_WL",
            "name": "Alpha Alerts Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5rcRMZvSZrNkvwtakUoSU1aLTRkQMmdrZgG7M3vEo4kK/logo.png",
            "tags": [
                "utility"
            ],
            "extensions": {
                "website": "https://www.viewsolana.com/nft"
            }
        },
        {
            "chainId": 101,
            "address": "AxRQL3L4x4nDTbgWz877tUY64xhMwDXG5uY1XFbherY1",
            "symbol": "LGB",
            "name": "Let's Go Brandon (M1 Money Supply Limit)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fubolibs/crypto/main/brandon.jpg",
            "tags": [
                "social-token",
                "meme-token",
                "idiot-in-chief-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AJhAVUTqhCwCJm8omxJvJdPPLUzBjVofzeccsLv8chWY",
            "symbol": "SHTC",
            "name": "ShihTzuCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ecouter/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx",
            "symbol": "BUD",
            "name": "BunnyDucky Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BunnyDuckyHQ",
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "sBUDadt6KLaQErxT9E18fhYDMjBU7tcRZJh2cwtATcc",
            "symbol": "sBUD",
            "name": "BunnyDucky Stake Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sBUDadt6KLaQErxT9E18fhYDMjBU7tcRZJh2cwtATcc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BunnyDuckyHQ",
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "D9bPM4v6M3vn41Mg3Eh9ac2dwiYCM1GGvBLbDvyQdAeh",
            "symbol": "NBC",
            "name": "Nabecoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bernardhackwell/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5q1H7UDaGQj98Z3Kx3dnDdWuj8GwnYW9X74kJfFFTFYu",
            "symbol": "EKS",
            "name": "Eksclusive Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GKnt/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dg46mekhjFiKhCEG94wZyV1fsKLTyM98LeGfXPHGta7w",
            "symbol": "ERA",
            "name": "The Alliance of Eragard",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dg46mekhjFiKhCEG94wZyV1fsKLTyM98LeGfXPHGta7w/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Eragard Token",
                "discord": "https://discord.gg/kFUmzU6nCH",
                "facebook": "https://fb.com/dragonwar.io",
                "medium": "https://medium.com/@dragonwar",
                "telegram": "https://t.me/dragonwarglobal",
                "twitter": "https://twitter.com/io_dragonwar",
                "website": "https://dragonwar.io",
                "youtube": "https://youtube.com/channel/UCzl9Gsl58YR9MyL2650tg8w"
            }
        },
        {
            "chainId": 101,
            "address": "AcyTybdT75MhEauw1TJvRnpQjVKx6MMDyiU6FbSNKBec",
            "symbol": "SCT",
            "name": "Society Token v2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/societytokenv2/societytokenv2/main/societylogo%20(1).png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3cN2oUiv3998ieKX8FXRsw7eZ4cQDvKwP5cU8pfnbboF",
            "symbol": "BOBC",
            "name": "Bobbie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bobbie-coin/BOBC/main/BobbieC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qt7MkqDEd1dGnMmKsGJwPSMSf88F5CwgndBjBcrsQgD",
            "symbol": "OXO",
            "name": "OXO",
            "decimals": 0,
            "logoURI": "https://github.com/zoots-com/oxocoin/blob/main/oxo_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9tcnTYf4C6xAkCb1yFoWPrD8NyL1iUdUnLXBXHQntGeJ",
            "symbol": "PPRM",
            "name": "Pooperium",
            "decimals": 9,
            "logoURI": "https://www.clipartkey.com/mpngs/m/31-313637_sad-poop-emoji-png.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Rca23WVVN5Vnq4JdfgRq5ou8auD1n5ikVWzSd91s6eG",
            "symbol": "FUL",
            "name": "Fulbo Stars",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Rca23WVVN5Vnq4JdfgRq5ou8auD1n5ikVWzSd91s6eG/logo.png",
            "tags": [
                "social-token",
                "gaming-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3LtUEHq7m41i8h7GQDgrKU321daFsk71RVVyXzpU18s7",
            "symbol": "HKM",
            "name": "Haknem Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ari1204/token-list/main/assets/mainnet/3LtUEHq7m41i8h7GQDgrKU321daFsk71RVVyXzpU18s7/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://haknem.com"
            }
        },
        {
            "chainId": 101,
            "address": "4Qd7t3wG1ea5Zm7NSuEocMrc6NfA8GyGp3s3nv4kgvgj",
            "symbol": "ARK",
            "name": "Metavillage ARK Token",
            "decimals": 0,
            "logoURI": "https://metavillage.app/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Metavillage ARK Token",
                "discord": "https://discord.gg/metavillage",
                "twitter": "https://twitter.com/metavillageNFT",
                "website": "https://metavillage.app"
            }
        },
        {
            "chainId": 101,
            "address": "GyzW3CTMi8SdPQP3BMy3TqaZDAvffawNi9FULH8ksdmi",
            "symbol": "FEMBOY",
            "name": "Femboy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GyzW3CTMi8SdPQP3BMy3TqaZDAvffawNi9FULH8ksdmi/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "6zDXz7wkKYhs787ZwGGEWhx4PAvsUgorvvtvahPUeNHC",
            "symbol": "MMMM",
            "name": "Multiversal Metaversal Municipal Monetary Credit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/genx97/mmmmu/main/MMMM_logo.png",
            "tags": [
                "social-token",
                "multiverse-token",
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GeiwCzW1V4qS4Ye8zEGCcaHFBLY3T9hJir7D4db8Cc8e",
            "symbol": "ABUX",
            "name": "AlexaBucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Fuki17/crypto/main/AlexaBuxLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gtj6NZHgMZ7QHg8PDN6izEn4GjVoX8yFLv4ESNKsWb62",
            "symbol": "BT",
            "name": "Bitmon Presale Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gtj6NZHgMZ7QHg8PDN6izEn4GjVoX8yFLv4ESNKsWb62/BT-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/bitmon",
                "website": "https://twitter.com/BitmonWorld"
            }
        },
        {
            "chainId": 101,
            "address": "83XWg6JbMX16bvPz5y5e8g3Dqej2uowhkJouLecPcCME",
            "symbol": "RAW",
            "name": "RAW Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RAWBrand/RAWCoin/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "We craft Limited Edition Art that you can skate, surf or ride. Road | Air | Water"
            }
        },
        {
            "chainId": 101,
            "address": "4jZ14VWYWziTpKreD2Jqn8WC1AC4Y1Lc3MDLypCH1qFp",
            "symbol": "NEURO",
            "name": "NeuroFi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Neurologically/CryptoImage/main/Final_logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "neurodiverse",
                "autism"
            ]
        },
        {
            "chainId": 101,
            "address": "8GkFRfSBkJjk55BBiLyYhboYTGDXo5dkerp7N496Zfzo",
            "symbol": "VEL",
            "name": "VELYRIAN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/ivanTheHorribble/demo/main/Vale/assets/images/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "money",
                "velyria-state",
                "velyrian",
                "velyria"
            ]
        },
        {
            "chainId": 101,
            "address": "2wAUEu7NDQFbT5k2pQ3FHSvgeVtvbt5SehxFnbcsibkM",
            "symbol": "PARA",
            "name": "Parapente",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/parapente.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "76hX36CjA3JXHwWbK7EZMdNQ9Nz7WVL6yk3dTJNNuWHK",
            "symbol": "SNOWBOARD",
            "name": "SnowBoard",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micksavioz/crypto/main/snowboard.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "F6PqCrUpkwBwhzCs21xiA4WzYY4etQoa9vh22Nte3bAW",
            "symbol": "SMP",
            "name": "Solitaire Mint Pass",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F6PqCrUpkwBwhzCs21xiA4WzYY4etQoa9vh22Nte3bAW/logo512.png",
            "tags": [
                "whitelist-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "6FWzrWRBmUQCwRMBanxAWnDn1i2hRGAnSAzNUUowtojd",
            "symbol": "SHAMJI",
            "name": "Shamji Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/farhadshamji/crypto/main/logo.png",
            "tags": [
                "social-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2FKXJBtzCM2CE8MumEzzCKV5EPQ8uvFHTGtZg2ePtVAH",
            "symbol": "JIGX",
            "name": "Jigx Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FKXJBtzCM2CE8MumEzzCKV5EPQ8uvFHTGtZg2ePtVAH/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/jigx_official",
                "website": "https://jigx.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7zAqE4goQfe5pssmW68uxY7TueB5zrvz7KPNiyrR2TJ",
            "symbol": "SLUG",
            "name": "Slug Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/billybigpotatoes/crypto/main/slug.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4fRea2p9hpWEMddLraYAaMr7rcQHAAVkk2cQXxUN4yrh",
            "symbol": "CTZWL",
            "name": "Crypto Tradiez WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4fRea2p9hpWEMddLraYAaMr7rcQHAAVkk2cQXxUN4yrh/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E44QppzpSronbUVHADa7p7VQYFRA7UjeXnPAMzXij2zV",
            "symbol": "VRSX",
            "name": "VRDOLL",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E44QppzpSronbUVHADa7p7VQYFRA7UjeXnPAMzXij2zV/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9dR6KNm4EnhfE1ecpJoGUj9x7c9XJz2ZtrSMfkWYhqZT",
            "symbol": "KAFD",
            "name": "CatBnKatt",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kafdSol/Sol/main/kafd.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6RqXpuup98mFmWkYQFhdkTqvXCBf6e85c1kSBGtSKoR8",
            "symbol": "LNX",
            "name": "Linux",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SquareandCompass/crypto_2/main/logo2.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9gAxgixBFQYNpApuE6u2AsKALGpVpSDibapY5LdgRQMo",
            "symbol": "FHC",
            "name": "Fady Hanna Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fadygithub/crypto-project/main/Logo.png",
            "tags": [
                "social-token",
                "personal-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/hanna.fady",
                "linkedin": "https://www.linkedin.com/in/fady-hanna/"
            }
        },
        {
            "chainId": 101,
            "address": "FgGSSyWTof35tPwZi8CZ7uoH2tD6y1ezWcMiw38Cmoat",
            "symbol": "SCARAB",
            "name": "SCARAB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgGSSyWTof35tPwZi8CZ7uoH2tD6y1ezWcMiw38Cmoat/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/SecretSphinxes",
                "website": "https://secretsphinx.io"
            }
        },
        {
            "chainId": 101,
            "address": "AcQetM7ZgsBVPTUvR3TVY2gQeS3GV2KSiR4rLY5dboPM",
            "symbol": "KELL",
            "name": "Keller Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AcQetM7ZgsBVPTUvR3TVY2gQeS3GV2KSiR4rLY5dboPM/logo.png"
        },
        {
            "chainId": 101,
            "address": "3HLxrDXeukAbVG3x1YcvQ1LWAShSew2NrMVzS6ps217d",
            "symbol": "CCLC",
            "name": "Cybercrime Lab",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CybercrimeLabLLC/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BK5MG8QaWnAGnNZ9xpffQdTf5eUqQBbQWW5sqk4GCQMo",
            "symbol": "StMP",
            "name": "SolitaireMintPass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BK5MG8QaWnAGnNZ9xpffQdTf5eUqQBbQWW5sqk4GCQMo/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "WLdPvMhouG9sfJvLp6jFhYQoS6ic2Z1DEXujR7GEFwK",
            "symbol": "BRAINYMT",
            "name": "BAG Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brainyapegang/token-logo/468c72ce3b0f5714041ef712772199f8c2cf63ee/brainyapegang/mint-token-logo.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ptA8qAp7Gf",
                "twitter": "https://twitter.com/brainyapegang"
            }
        },
        {
            "chainId": 101,
            "address": "E4amqzv6WzGQfPqoNGVWDLdbFs3fXbuvowkYnZ5AE7Cc",
            "symbol": "SPUD",
            "name": "GrumpySpud",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/grumpyspud/spudtoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75XwHLqgAd2uLsXQEMpAe79EbWSnouwe5LXxEnMW1nCv",
            "symbol": "NOSE-WL",
            "name": "Nose Age NFT Whitelist Token",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/1735634/149608639-47e27565-c55a-4482-b712-e5ee2c1a2569.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/jgTh4tyyAJ",
                "twitter": "https://twitter.com/noseagenft"
            }
        },
        {
            "chainId": 101,
            "address": "CjzPUx7yLB92EFCKCVnqE9Wa7auTpSGrdzJEzmkAkj8Y",
            "symbol": "OSIS",
            "name": "Oasis Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TOSHlO/oasiscoin/main/logo.PNG",
            "tags": [
                "mint-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "3CgLTvZV8d1oM74ACnH1Te6BDsym3pYuX5YyYkusjGSL",
            "symbol": "BMXHUB",
            "name": "BMX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CgLTvZV8d1oM74ACnH1Te6BDsym3pYuX5YyYkusjGSL/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "14AB7dXTdiNAwqAtWC7NmSW9u74SkCXU1X6DSejxkFEg",
            "symbol": "LICKS",
            "name": "LICKS Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/dirtrunner2020/crypto/main/licks.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6M9uA6fMpYZ8pgAgXsa5ArJJFhiwMGH3gtMe1eM4sDWC",
            "symbol": "APCC",
            "name": "Appreciation Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AnataZealot/APCC/main/APCcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J5mdJUncYfZ9j8hYstp5aYNZscPFWfpYseMJ4E5ko75X",
            "symbol": "CCCN",
            "name": "Coin Coin Coin",
            "decimals": 0,
            "logoURI": "https://github.com/larry19power/J5mdJUncYfZ9j8hYstp5aYNZscPFWfpYseMJ4E5ko75X/blob/main/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BkGrcWxBuwZKxnMU8ck3dtAwi8jWoXVxrRguncxuFzQq",
            "symbol": "BBNF",
            "name": "BlockBoy 'n Friends",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WeAreMetaverse/BBNF/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "BlockBoy 'n Friends Universe"
            }
        },
        {
            "chainId": 101,
            "address": "7FSG3cy7SjR5mpjoRMbdj8ux5KYh3v8M7n1p7Sb3swE6",
            "symbol": "TRTN",
            "name": "Triton",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7FSG3cy7SjR5mpjoRMbdj8ux5KYh3v8M7n1p7Sb3swE6/logo.png",
            "tags": [
                "TRTN-token"
            ],
            "extensions": {
                "website": "https://tritonstation.io"
            }
        },
        {
            "chainId": 101,
            "address": "GGyTwdaJCZa5W9cK2bYLuVYXRw5ESFFRddbrLhqGtEax",
            "symbol": "BNTLY",
            "name": "Bentley",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crypto-coins-creator/crypto-bentley/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JDrctysFY7WWfzTp9qKiUXW926Nsgp8qGApzKhCWBbQr",
            "symbol": "MANIN",
            "name": "MANIN Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/yasbgts/crypto/main/mToken.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H2cTiHHi6cTfrwAudLPVqF1jipbHhNzFcyoUg5yNRKFY",
            "symbol": "ARNO",
            "name": "Arnaud FM",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/arno-002.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FiRrEmuX1rAhpS4ukzdx5txA4x7w3cQ4NbPm3mCxQEHZ",
            "symbol": "PRTV",
            "name": "PRTV Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FiRrEmuX1rAhpS4ukzdx5txA4x7w3cQ4NbPm3mCxQEHZ/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "PR"
            ],
            "extensions": {
                "discord": "https://discord.gg/ztqYqdVxhW",
                "medium": "https://medium.com/@PRTV",
                "twitter": "https://twitter.com/PRTV_SOL"
            }
        },
        {
            "chainId": 101,
            "address": "GyKoRHDEffu4C544h6L1jqNR7fJ8AkuAETMhC2Hj6FDR",
            "symbol": "KN",
            "name": "Key Nodes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/thephillipj/Key-Nodes/main/logo.png.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ALusFVmfyu2SV6CZurYSt8jj8iF1ZF9XAPNSXe4KciDE",
            "symbol": "THM",
            "name": "ThalerCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mtha26/thaler-coin/main/LOGO130KB.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GNCjk3FmPPgZTkbQRSxr6nCvLtYMbXKMnRxg8BgJs62e",
            "symbol": "CELO",
            "name": "CELO (Allbridge from Celo)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GNCjk3FmPPgZTkbQRSxr6nCvLtYMbXKMnRxg8BgJs62e/logo.png",
            "extensions": {
                "coingeckoId": "celo"
            }
        },
        {
            "chainId": 101,
            "address": "EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4",
            "symbol": "FTM",
            "name": "FTM (Allbridge from Fantom)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4/logo.png",
            "extensions": {
                "coingeckoId": "fantom"
            }
        },
        {
            "chainId": 101,
            "address": "9xjPG7Xbzqvatt5ZfbLx2J7WDHBY6uaEq8HkLPpDqC9j",
            "symbol": "REMN",
            "name": "Remnants Drop 3 Whitelist",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Fulgurus/remn/master/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Whitelist access to the Remnants drop 3 . Token is tradeable.",
                "discord": "https://discord.com/invite/remnantsnft",
                "twitter": "https://twitter.com/RemnantsNFT",
                "website": "https://www.theremnantsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7gkLeJq8xx5qtPCuLaas6qXoJdiFbLc6vg3M5RLMtXW2",
            "symbol": "CRONA",
            "name": "CRONA",
            "decimals": 0,
            "logoURI": "https://github.com/Kalinichenkovlad/crona/blob/80a6b8e1d270b4d9749014c6d1ea1fd035c7431f/logo_crona_token.png",
            "tags": [
                "mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Q8GPQfrMcDRknataFe46VYdsGYqwRTCuYxGqPSHGCQo",
            "symbol": "TAG",
            "name": "totag",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Q8GPQfrMcDRknataFe46VYdsGYqwRTCuYxGqPSHGCQo/logo.png",
            "tags": [
                "digireal-platform"
            ],
            "extensions": {
                "description": "TAG Token are used by the world's first digireal Platform, totag.io TAP it, get it!",
                "instagram": "https://www.instagram.com/totag.io",
                "medium": "https://totag.medium.com",
                "telegram": "https://t.me/totag_io",
                "twitter": "https://twitter.com/totag_io",
                "website": "https://totag.io"
            }
        },
        {
            "chainId": 101,
            "address": "64dTMpe6o3nxRm8jnnxwxpKqinH7ioEZoxB7gBe48nnT",
            "symbol": "WLSN",
            "name": "WL Squirrels Nation",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64dTMpe6o3nxRm8jnnxwxpKqinH7ioEZoxB7gBe48nnT/logo.png"
        },
        {
            "chainId": 101,
            "address": "6LcERGBPSv7nhoA6Gr8nvke1xcAmbarVsCMHVvQ3NCbt",
            "symbol": "$SLAY",
            "name": "SLAY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Don-73/-lay/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ETwvm6FreYGPpCQyV96xnujaonsA8zJdFKFjz2CKGm1",
            "symbol": "BOG",
            "name": "BandOfGoonies",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BrightShadowZ/BoG/main/BoG.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7bWNspkbFLNJbRWqkg9ocSPu7D3Jz2h7GCzHCiPVL8G2",
            "symbol": "MYC",
            "name": "MoYuCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/x1957/logo/master/myc_logo.png",
            "tags": [
                "mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2q39pHwFJUURyfypPaGgW2Y87pmAkj8iC8DV4z3DF19D",
            "symbol": "CXC",
            "name": "CommonXCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/immacrypt/LOGO/main/LOGO.png",
            "tags": [
                "community-token",
                "social-token",
                "raydium",
                "solana-blockchain",
                "defi-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CommonXCoin"
            }
        },
        {
            "chainId": 101,
            "address": "3V1iScAr8N7X2CXPXpLpgjeV3ThpJdwPJftmem6Ab3JH",
            "symbol": "VAMP",
            "name": "Vamp Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3V1iScAr8N7X2CXPXpLpgjeV3ThpJdwPJftmem6Ab3JH/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Vampo__"
            }
        },
        {
            "chainId": 101,
            "address": "BriQC6NkjrYRSXpoUqoW8cWJtESrtwUufJbAoLMkkCme",
            "symbol": "ECK",
            "name": "Eck Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BriQC6NkjrYRSXpoUqoW8cWJtESrtwUufJbAoLMkkCme/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TomEckMobile",
                "website": "https://ok-robot.co/"
            }
        },
        {
            "chainId": 101,
            "address": "APiz1MDLLZjhkhuckMDGEGthJU2hwSakpVYwGFgaHfFs",
            "symbol": "PWUC",
            "name": "PeriwinkleUnicoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/periwinkleunikoin/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8sJ1dLocbsYE76t9SMF85VBBDCNkmAcCAkvCWyuRTa8r",
            "symbol": "SOLADA",
            "name": "SoLADA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8sJ1dLocbsYE76t9SMF85VBBDCNkmAcCAkvCWyuRTa8r/ladacoin_large.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SoladaCoin"
            }
        },
        {
            "chainId": 101,
            "address": "5hpxDVc2uA3AYA528xkuwAUcRD755tc7X9Lnw8V632yD",
            "symbol": "$CAL",
            "name": "CalCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/waltercallesen/CalCoin/main/logo.png",
            "tags": [
                "meme-token",
                "utility-token",
                "social-token",
                "networking-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CalCoins"
            }
        },
        {
            "chainId": 101,
            "address": "GUJe4fZA3ky2v2MYCpJMZ27AiNCsXUcxcCc3Qt4qqm8Y",
            "symbol": "HART",
            "name": "Hart Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUJe4fZA3ky2v2MYCpJMZ27AiNCsXUcxcCc3Qt4qqm8Y/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Q8PtA1CcKUwx3hLYUwYcyqgxC6u8rrhydBxB9jrdUvt",
            "symbol": "SMGSY",
            "name": "Smargasy Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/smargasy/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/smargasy"
            }
        },
        {
            "chainId": 101,
            "address": "6Sz979oyueB1HHuJ7tSM2aQZnXsGqbLf27x4DMnZwMaW",
            "symbol": "RHINO",
            "name": "Royal Rhino Golf Club token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Sz979oyueB1HHuJ7tSM2aQZnXsGqbLf27x4DMnZwMaW/logo.png",
            "extensions": {
                "discord": "https://discord.gg/Vcjzw99A",
                "twitter": "https://twitter.com/Royal_rhino_gc",
                "website": "https://royalrhinogolfclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "DQnCJQfRv6nr9q5gMmuZ1HmvnLeYxun3DtZuDKeNna4X",
            "symbol": "RICO",
            "name": "Rico Sauvie",
            "decimals": 0,
            "logoURI": "https://github.com/crypto-coins-creator/crypto-rico/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8rYHMJHSAu4HXtW1xoonVaH1zzDSW5Na83QW7SGQPeV2",
            "symbol": "ZBRA",
            "name": "Zebra",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KanashiiNFT/zebra-logo/main/zebra.png",
            "tags": [
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AWmJqXroJCZTgQdeoHR3HDsM3xuztym93vqWXg7EdqvR",
            "symbol": "FMC",
            "name": "FreeMason Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/officialfreemasoncoin/logo/main/fmc%20logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8L5BRgmJ6WhZs3xKgg14YtrENHFUugvY3PUpT6XsLtX1",
            "symbol": "sfr",
            "name": "sinner zoofer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sinnerfilozofiya/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/sinnerfilozofiya"
            }
        },
        {
            "chainId": 101,
            "address": "CqNmReav6kiDU4giV3LJGc8Z3DgmbxNQfsN5kUSYKkF4",
            "symbol": "BDOO",
            "name": "BeeDoo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/opsydian/beedoo-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SFXb4HZkGAZNkD5apUugy2829NQVxqDhms8RKK2JJGR",
            "symbol": "SFX",
            "name": "SkinFlip Exchange Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SFXb4HZkGAZNkD5apUugy2829NQVxqDhms8RKK2JJGR/logo.png",
            "tags": [
                "nft",
                "gaming",
                "marketplace",
                "gamefi",
                "defi",
                "utility"
            ],
            "extensions": {
                "description": "A hybrid multichain marketplace and wallet platform for video game assets.",
                "discord": "https://discord.gg/skinflip",
                "instagram": "https://www.instagram.com/skin_flip",
                "twitter": "https://twitter.com/skin_flip",
                "website": "https://skinflip.net/"
            }
        },
        {
            "chainId": 101,
            "address": "64tUZwWhdAtcVBVS1T4m14nH86CZsoeQjwpJtv3A3Uwn",
            "symbol": "KFBR",
            "name": "I'm Gonna Shoot Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/imgonnashoot/crypto/main/ImGonnaShoot.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EZK41VTdm3j8iuDBigM6kyboBk2wqgAabtmt8CCiVKVt",
            "symbol": "OINKS",
            "name": "OINK COIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Stankrank/Stankrank/main/Oinks.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JNcg1YrQ6RQTR41MK6io1LFzHoS3xQzP9FMvQ1kYPBs",
            "symbol": "ZIVK",
            "name": "ZivaiyahKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZivaiyahKoin/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHci94eZt3KX2YZEJZppybMms2nW2wm5JWcdDtYLKujH",
            "symbol": "BROWL",
            "name": "Browl Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHci94eZt3KX2YZEJZppybMms2nW2wm5JWcdDtYLKujH/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BKXL2h567SJdobwamjMPb6qWM3SXwTm2wSNiCXRe8a47",
            "symbol": "HOM",
            "name": "NFT House Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/blackrex7/the-house/main/logo.png",
            "tags": [
                "social-token",
                "nft-marketplace",
                "NFT-Token",
                "NFT-Project",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "F7CCNYeNHeQrcawB1oJk1WSTGQnzD1pEbpKmBHjFLs1d",
            "symbol": "AUDRIC",
            "name": "AudriCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/audricmarshall/audric/main/master/logo.png",
            "tags": [
                "gaming",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6L7SrK64z9Cw2nVpEzzUFUJUX5uuQHK3zBzzYkHhvV7i",
            "symbol": "DOODAH",
            "name": "DooDah",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/afterconsideration/crypto/main/doodah_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AbY1k3ZAYDKXsBK3UUVPLSh74oZN7eMnY3NDocNUptrG",
            "symbol": "PMN",
            "name": "Peman Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pepengas/crypto/main/Peman%20token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CTty2YQKwkrTbdPVy2pVmEEkckWFwvrxBToBD8DRSTr8",
            "symbol": "UTBM",
            "name": "Blousards",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsZfrs1iWh9CXzbpGUfUkRfs35KkUWXJH2S6Y9D272M/logo.png",
            "tags": [
                "friendship-token",
                "family-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2rKmksbnyCtrJemQqYdBjiXP5R2ZGpP6uDmstpGvkaey",
            "symbol": "IBSH",
            "name": "Iba Shinu",
            "decimals": 8,
            "logoURI": "https://user-images.githubusercontent.com/81035681/144994154-2c2bcb2f-1976-4df3-8aaa-34a2cbf779a5.png",
            "extensions": {
                "telegram": "https://t.me/ibashinutoken",
                "twitter": "https://twitter.com/ibashinu_token"
            }
        },
        {
            "chainId": 101,
            "address": "AcjsLnq9r7adkCe1a1GPyqCErSe4GjGEukbqBP2WJdoo",
            "symbol": "HANDLER",
            "name": "Working Dog Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AcjsLnq9r7adkCe1a1GPyqCErSe4GjGEukbqBP2WJdoo/ico.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DFBxq9uPjYWMsuoL19ybaZ7eShZ1a6y42gtepEXNXfmd",
            "symbol": "PAM",
            "name": "Pamelija",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/unknownchapter/PamSol/main/pam.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4zz2esfV7hvyQEiYb8MKkE6cJiTPcg4X9psjcDeYdxG",
            "symbol": "NGTC",
            "name": "Nugget Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LordWestcott/nuggetcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FiV64orExiAJmzh6beVEr8BTpjC4WE6mqf6Tcry6tEhx",
            "symbol": "AHMZ",
            "name": "Ahmed Z Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ose15101/AHMZ/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AYKCSsAAeg7zL3QywPUnc2bPmtmvVTV5EnskmnLVRWCH",
            "symbol": "DBKS",
            "name": "Dollar Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TuckerBrady/DollarBucks/main/Dollar-Bucks-Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HTr5SoWycrUdHbbrRQvxe9qhVraP196p8At7LrfPbpmr",
            "symbol": "GLORY",
            "name": "GLORIOUS GECKOS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/GloriousXican/ASSETS/main/coin-sglory.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GeckosNFT",
                "website": "https://gloriousgeckos.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4RfFCaaAdqeLi16Ci6uQVZkR7EycHJTfBNAG85jGupVN",
            "symbol": "XDR",
            "name": "XOALDIR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ryanjames84/Xoaldir/main/xoaldir.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "zkie8GLAjoVvZkJcA752vhZcYfzfpiiMUfHsBvsQraY",
            "symbol": "PGT",
            "name": "Purple Goblin Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zkie8GLAjoVvZkJcA752vhZcYfzfpiiMUfHsBvsQraY/logo.png",
            "tags": [
                "social-token",
                "pgt"
            ]
        },
        {
            "chainId": 101,
            "address": "DVWbqNnW9btNcYJassHT8a9eon6Mhi7YbaZEdgBDNie7",
            "symbol": "CHGE",
            "name": "ChumpChange",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DVWbqNnW9btNcYJassHT8a9eon6Mhi7YbaZEdgBDNie7/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BMFsFjGKUAPVWX4Y9hfrnvKwPLCEpZAawSuBMfVPhVuG",
            "symbol": "MACH",
            "name": "Afterburner Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Dypto/CryptoOne/main/Logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9sjzeVEBMaT7jmD3hroYGg6bD6MCyHpUQ9Rps7ocbwbQ",
            "symbol": "MDARK",
            "name": "Martin Darko Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MartinDarko/MartinDarko-Token/main/MDarkoToken-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ESDPio4QvdJH8KCNZfyv4HVxmg4KQMYXZew17SAz6TKz",
            "symbol": "INDC",
            "name": "INDCLOUDS COIN",
            "decimals": 9,
            "logoURI": "https://github.com/Git-Indclouds/cloud-engine/blob/e416de0263be6d9293d3ae01c0bc9d1a2f2c3c3e/Assets/mainnet/logosizedd.png?raw=true",
            "tags": [
                "utility-token",
                "Hosting-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4F6diM2z8QcugE5boJe9fE3KwSRGP6zPpxr8Hqo4fRoZ",
            "symbol": "GRC",
            "name": "Golden Retriever Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TysonGold/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J2beWpqSSoFXzzotd9uWQe9xSHZkszAntjYD7xCdnu1K",
            "symbol": "TENU",
            "name": "Tesla Inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J2beWpqSSoFXzzotd9uWQe9xSHZkszAntjYD7xCdnu1K/logo.png",
            "tags": [
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.io/teslainu_coin",
                "telegram": "https://t.me/teslainu_coin",
                "twitter": "https://twitter.com/teslainu_coin",
                "website": "https://teslainu.net"
            }
        },
        {
            "chainId": 101,
            "address": "Fb4SGB3CFPWEkyo97ompWgxwHBG4rZHtVn4D3d4E3VDv",
            "symbol": "FLYK",
            "name": "Flying Koala",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fb4SGB3CFPWEkyo97ompWgxwHBG4rZHtVn4D3d4E3VDv/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3FWisHsh7HqaeFQkuaXGh5gTTMHjvnFvVhtQvxb2hviJ",
            "symbol": "BUB",
            "name": "Bubble",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/A-Krasniqi/bubble/main/Bubble-Logo.png",
            "tags": [
                "general"
            ]
        },
        {
            "chainId": 101,
            "address": "42kRyPwN634LVoNJNanS4k1BxUTbT6zvyEnSXg1eGyGz",
            "symbol": "CURSED",
            "name": "Cursed Mike Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/42kRyPwN634LVoNJNanS4k1BxUTbT6zvyEnSXg1eGyGz/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CbpRDGxDXHZHfEtiprCz2k9raAH12zgKgqJjiCaQh2t7",
            "symbol": "BOFH",
            "name": "BOFH Tokens",
            "decimals": 0,
            "logoURI": "https://github.com/itsthebofh/logos/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D5waTUjXsSRKhvJDsJVk8TQS8JxMKuRy49w9j8XH3QrA",
            "symbol": "FFC",
            "name": "Frew Family Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Frew250/Crypto/main/Logo3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SRYWvj5Xw1UoivpdfJN4hFZU1qbtceMvfM5nBc3PsRC",
            "symbol": "SUNNY",
            "name": "Sunny IOU Token (Quarry Rewards)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag/logo.svg",
            "extensions": {
                "coingeckoId": "sunny-aggregator",
                "discord": "https://chat.sunny.ag",
                "github": "https://github.com/SunnyAggregator",
                "medium": "https://medium.com/sunny-aggregator",
                "serumV3Usdc": "Aubv1QBFh4bwB2wbP1DaPW21YyQBLfgjg8L4PHTaPzRc",
                "twitter": "https://twitter.com/SunnyAggregator",
                "website": "https://sunny.ag/"
            }
        },
        {
            "chainId": 101,
            "address": "8H9qSSJnJ32LNkxU9t1BdHmLrWS6mG18XNrF4xUgDff5",
            "symbol": "JC",
            "name": "JaeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/itzjae123/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EN9eX36saBK16LRipK5rsSxv58XEmjtNtNA4g6D5QNf5",
            "symbol": "LIN",
            "name": "Lina Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ari1204/token-list/main/assets/mainnet/EN9eX36saBK16LRipK5rsSxv58XEmjtNtNA4g6D5QNf5/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DJuRxTJwQSFSh3YXT5mxm2ah4kADFMkPpBdDbBW95gZx",
            "symbol": "JSC",
            "name": "Jesucrypto Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/agustinrossi87/jesucryptologo/main/jesucryptologo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6dRx1xfsJeAkxSmb7NZemWZeBHxGCdd7XZg8SVSU1rwC",
            "symbol": "GIG",
            "name": "GIGNIOC",
            "decimals": 9,
            "logoURI": "https://gignioc.com/wp-content/uploads/2021/07/gignioc.png",
            "tags": [
                "currency-token"
            ],
            "extensions": {
                "website": "https://gignioc.com/"
            }
        },
        {
            "chainId": 101,
            "address": "97gfUrLLtLYJGQQVAheqoiAetr5SbNru8bWaioxNCVnJ",
            "symbol": "TPY",
            "name": "Toppy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andrewbayly/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2t3dWi4CKqBUdq3kGXzSLXuym6784x3Bkd7diJgm5P6s",
            "symbol": "MTRV",
            "name": "MetaRevo",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/MetaRevo/repository/main/metarevo-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RnYaAAPXp8QYcRs2Qnc36CNL9e2fybahup7UBjc118d",
            "symbol": "VNA",
            "name": "Vienna token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tenywa1/vienna-crypto/main/vienna-png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DeyztTGB1QxdfEYUvSs2t8aRF7UBYvGoNrkTtdPZ8vdC",
            "symbol": "DB",
            "name": "Devbhoomi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dbhari/crypto/main/DB.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "GVkDXg1nQ8HeP19hZSGY5nwTmwJUKTh4eNEiP6ZaAqGn",
            "symbol": "EKB",
            "name": "Emre Kemal Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/emrebalci7/NftToken/main/coin.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B7vGF8rBUuQGx4bx9Esmpb9zXhn2mXSfR2t7AHbqarpw",
            "symbol": "ROT",
            "name": "Reign of Terror",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B7vGF8rBUuQGx4bx9Esmpb9zXhn2mXSfR2t7AHbqarpw/logo.png",
            "tags": [
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "discord": "http://discord.gg/8AwGrpWBKA",
                "telegram": "https://t.me/reignofterrornews",
                "twitter": "https://twitter.com/RoTTheGame",
                "website": "https://www.reignofterror.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7vghsjzp2T8eHAGhSGji1qRKmWvuQsTcVKWMyEhdqVhv",
            "symbol": "WOR",
            "name": "Woorim",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ljubenvassilev/woorim-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "12NoHmWHmNtGLBuayCE9ybpT8PZe11wR5xfaAa5Gg4jg",
            "symbol": "MFK",
            "name": "M4K coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mantis919/logom4k/main/m4k.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "48KenSyCQME7B1JxNvSNfzH713kdMcw6bHCkLQ4rmDrN",
            "symbol": "VNTK",
            "name": "Vinoteka Donostia Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Intelsecpieter/Vinoteka-Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BgmTt9bbo3ecR5vpVNPEwKts3cAmnWN824Qb1wLqewWp",
            "symbol": "ROGER",
            "name": "Roger",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rogerthecat/rogercrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7nkuaNHwDashXjgKn3K3wBcvmWc5ga3MSxUo5vr8UPe8",
            "symbol": "TAI",
            "name": "Tai Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yeokianhwee/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4bsY5WUqmLy9qgkpZT9GW7medaZiZC61N4SzJ6XgnuBd",
            "symbol": "BRUSPAL",
            "name": "Bruspal Copper",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/bruspal/bruscoin/main/ressources/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://www.bruspal.net"
            }
        },
        {
            "chainId": 101,
            "address": "8V1QEHoWE41YvN99xRVpAJtUuXtPx1Bppi9TKYo26puP",
            "symbol": "OKDev",
            "name": "OKKAMI Dev Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ant-dev-panda/OKKAMI_dev_crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.okkami.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BbbnfPgU2XbuLEcP765VkLuU8dwwUrmDakDnwFXuod9i",
            "symbol": "BUDC",
            "name": "BUDCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rogerthecat/rogercrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CQR1VBAsxfeRA1fxT5brFiXcCecES4tj3MTV3mYY5wuX",
            "symbol": "KSHR",
            "name": "Kosher",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/earth04190419/token-list/main/assets/mainnet/CQR1VBAsxfeRA1fxT5brFiXcCecES4tj3MTV3mYY5wuX/logo.jpg",
            "tags": [
                "currency-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/BDe79UNaGq",
                "twitter": "https://twitter.com/kosher_token",
                "website": "https://koshertoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "GVTL9CwHurEhXE4WohoNV3KJKvnMNHivq6Ah9kgz8jiA",
            "symbol": "LZX",
            "name": "Lazy X",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lazyxmusic/crypto/main/lazyx.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BEpBgx5xDjg8qqgDRqhyrT32pZs51C5xMddzpykaNbuZ",
            "symbol": "WEB",
            "name": "Digital Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEpBgx5xDjg8qqgDRqhyrT32pZs51C5xMddzpykaNbuZ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HuManQDs2YtrRkQu4www48fFc6mz39gG6u2vUT2U9B9X",
            "symbol": "HUMAN",
            "name": "HUMAN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HuManQDs2YtrRkQu4www48fFc6mz39gG6u2vUT2U9B9X/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "human",
                "coinmarketcap": "https://coinmarketcap.com/currencies/human-coin/",
                "discord": "http://discord.gg/5m6cBdry6y",
                "twitter": "https://twitter.com/thecyberkatz",
                "website": "https://cyberkatz.space"
            }
        },
        {
            "chainId": 101,
            "address": "Eg143Ejqt2hNpjK58abz6cdFqfZQ3PMNq453vZh8V93T",
            "symbol": "WEB",
            "name": "CityWeb",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Eg143Ejqt2hNpjK58abz6cdFqfZQ3PMNq453vZh8V93T/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://cityweb.fr"
            }
        },
        {
            "chainId": 101,
            "address": "3wScesVuo8sAb8DwheMBq6amGCKuvWPD54Yg8aGQM4Eq",
            "symbol": "Punkz",
            "name": "Punkz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3wScesVuo8sAb8DwheMBq6amGCKuvWPD54Yg8aGQM4Eq/logo.jpg",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6RcMrg2DvLZv5cqZjfrccVm4a76iZ6HRyFcH5yW7LEiq",
            "symbol": "BNK",
            "name": "Biank Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MattiaBianchini/BiankCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "vmNr8REkFim7hpYBtLbxuJ6eBVHRj2qPNXeto9hrvVK",
            "symbol": "FORK",
            "name": "ForkCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kwask96/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6uQHNRdF9XQtR3azXXzfssk3eDDs3zFmHM5sXmfodDTw",
            "symbol": "ASMTW",
            "name": "ANONYMOUS SYS MTW0",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Annonymous-MTW/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DhVJF1GTbF1PKUBPVUT7N2LgtNR4VAaxRmP4sQcRCgyH",
            "symbol": "FUC",
            "name": "Fugler Universal Coin",
            "decimals": 9,
            "logoURI": "https://github.dev/solana-labs/token-list/blob/894565e050bc98a87f91b6d2855259be0ce09b56/assets/mainnet/DhVJF1GTbF1PKUBPVUT7N2LgtNR4VAaxRmP4sQcRCgyH/logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H7NbVyqLPHWC7Da49F4CLNTgfMfYq8dF9ktJW5wwH3Ck",
            "symbol": "RTRO",
            "name": "RetroBit",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H7NbVyqLPHWC7Da49F4CLNTgfMfYq8dF9ktJW5wwH3Ck/logo.png",
            "tags": [
                "utility-token",
                "retro-token",
                "retro-trades"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/qawXWshkQw",
                "twitter": "https://twitter.com/retro_trades",
                "website": "https://retrotrades.com/"
            }
        },
        {
            "chainId": 101,
            "address": "zrUEBKeJU9ekD2cExT6idR9jJw7Y41pfoz6ofm6D4M1",
            "symbol": "DAY",
            "name": "DEMOCRACY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zrUEBKeJU9ekD2cExT6idR9jJw7Y41pfoz6ofm6D4M1/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://youtube.com/channel/UCEmwk5liIg8lxIjGqh8uHaA"
            }
        },
        {
            "chainId": 101,
            "address": "3z6ihp33VVctahRfq2MVQ8Rgf3bEmasVkoQKSjb5MuuW",
            "symbol": "ADDO",
            "name": "ADDO family",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/in3bit/addo/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://addo-family.com"
            }
        },
        {
            "chainId": 101,
            "address": "BHamA7cqt2ZSTqzGagamALprsmFnzQRPJfgx65VcRyZU",
            "symbol": "OPEN",
            "name": "Opensource",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BHamA7cqt2ZSTqzGagamALprsmFnzQRPJfgx65VcRyZU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://youtube.com/channel/UCEmwk5liIg8lxIjGqh8uHaA"
            }
        },
        {
            "chainId": 101,
            "address": "4S8fELt7cfNsddncSxGzT5d8SfLeKAn76xj2Q2ZhTrdN",
            "symbol": "FANS",
            "name": "FANALYST",
            "decimals": 9,
            "logoURI": "https://arweave.net/yF5NB2MLwBge5pzgF9KPW6b7a6n9RznhQ-LKAJOpAaw",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/fanalystUS",
                "website": "https://fanalyst.co/"
            }
        },
        {
            "chainId": 101,
            "address": "B9ooQRoiBdcqRdp4E7KqWoxwsPQdeJ6Y2ZWyRUt2o7Rd",
            "symbol": "SHIBZ",
            "name": "Shibaz NFT Coins",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B9ooQRoiBdcqRdp4E7KqWoxwsPQdeJ6Y2ZWyRUt2o7Rd/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D6DU7eZBq3mnFB1EjvKbaw3xmTMmr4UgK2a7WwtSzSrJ",
            "symbol": "ACRE",
            "name": "ACRE NFT",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/AbdallaMalik/Acre/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "facebook": "https://facebook.com/gumarabictoken",
                "telegram": "https://t.me/gumarabictoken",
                "twitter": "https://twitter.com/gum_token"
            }
        },
        {
            "chainId": 101,
            "address": "EDcg7Vikia4yCikvydWrbc8EdvYiRkL9NW5k8hnKGfbD",
            "symbol": "LGND",
            "name": "LegendKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/soonerdm/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AyDGcnCpYLRCZygMCLjfioEZxgzH1FbySNLrEoJR27yX",
            "symbol": "JPR",
            "name": "JupiterCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MineGamer69/JupiterCoin/main/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3GxJQETXn3JRbxihndAteRvkZZrU9S7Fp91LC7LRcagj",
            "symbol": "WRC",
            "name": "Wulfz Reverse Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GxJQETXn3JRbxihndAteRvkZZrU9S7Fp91LC7LRcagj/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5UH5K1sMfG6MszrqGAsWym1Q1q87akGVW9HXjD4SXcgn",
            "symbol": "CLK",
            "name": "Click Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/click-icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://click.ilov.eu"
            }
        },
        {
            "chainId": 101,
            "address": "FJd4ZyXGHfKonF3tJKGs2ku2aMSAr4dcwwzGHUZtoH4w",
            "symbol": "SUBSI",
            "name": "Subsicoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FJd4ZyXGHfKonF3tJKGs2ku2aMSAr4dcwwzGHUZtoH4w/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2muXUaump8kdxheK4MtFNj1W2z6aVrB9mGK6W9eC7tJF",
            "symbol": "PENGU",
            "name": "PENGU COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CrazyLoafofBread/SolanaCryptoProject/main/PengToken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Z1xdUeUo92vc6XyFeyQrKv8ix1z1fKoTAENb6RRdmVH",
            "symbol": "SAPO",
            "name": "Samoyed Puppet Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Z1xdUeUo92vc6XyFeyQrKv8ix1z1fKoTAENb6RRdmVH/logo.jpg"
        },
        {
            "chainId": 103,
            "address": "f9wr17a5SaJgxN46pYzMVXKj9TkD8KmafhZZn6DpiB2",
            "symbol": "AKRG",
            "name": "Andrei Koriagin (f9wr)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/2F3szNSUNzRxHwx1arJahbs1XPa7sPUEp8i692mV2kwY/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/2F3szNSUNzRxHwx1arJahbs1XPa7sPUEp8i692mV2kwY?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "BQRoP3zmwzmk1TvYcNQq4nVP7voJDoX5vX8FWYmGVzqa",
            "symbol": "BCHB",
            "name": "Yuri (BQRo)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/HtnWUzGEAdi8c77T33wFbyZgw3s7ihr9bZMHD47q2u3j/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/HtnWUzGEAdi8c77T33wFbyZgw3s7ihr9bZMHD47q2u3j?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "c6qehzp5EdCr1Hve1Pj7uhdW3458gvNW9mqQ7qQWJc5",
            "symbol": "CDA",
            "name": "David Anderson (c6qe)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/5XYUV5ExAboTuKT3v54nCWRa9m6wddXMB6x4sW2X1vrx/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/5XYUV5ExAboTuKT3v54nCWRa9m6wddXMB6x4sW2X1vrx?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "HkjmVFQSLcv8jWVy7AadEWRptfxYaKGkLw6TRQdmVPor",
            "symbol": "EFEFOX",
            "name": "Estefania Foxy (Hkjm)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/8tJ9y6nUbcieE3fa6iSuwQSGkcpU5Bt4AhcBnKwPcBz8/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/8tJ9y6nUbcieE3fa6iSuwQSGkcpU5Bt4AhcBnKwPcBz8?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "4L3qjwT5pTXHULHUuWBPtqGrjAxRzUXVtbWQ4wHb5HRU",
            "symbol": "FFFFA",
            "name": "phantom-1641309842190 (4L3q)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/3kDjTHdA8xrUj6ZxEYNJ4sVBPAiD8FKrn2TNqgC4sP9g/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/3kDjTHdA8xrUj6ZxEYNJ4sVBPAiD8FKrn2TNqgC4sP9g?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "FZSyCookhhSdmrHGMSLJH6rdj6tzCRs1yW4iZamrXZCa",
            "symbol": "FNBRD",
            "name": "Onboarding 5 (FZSy)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/9g8zNL6gxGfAi8MjkecZAcz3PqeW2q4aH1bVbeLT2YQS/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/9g8zNL6gxGfAi8MjkecZAcz3PqeW2q4aH1bVbeLT2YQS?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "CJNiEgCqsbpjTfiGaEEmQk62PD1AmWzmSvRoyLMakPWk",
            "symbol": "HRY",
            "name": "Harry Bro (CJNi)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/4zZd9KUxa8Ndtj23UF7ffMAEbTyPhJLKJyFPhQ43S6Qu/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/4zZd9KUxa8Ndtj23UF7ffMAEbTyPhJLKJyFPhQ43S6Qu?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "Eh1kYoZhxdf5C6wwwAV7KHnDtNXpLm2fQka8RarnAkNV",
            "symbol": "JHX",
            "name": "Jimmy Hendrix (Eh1k)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/4UMDqYeUh6AZJ7qVyWptaStJB2ma9CVYKcU639x89YnJ/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/4UMDqYeUh6AZJ7qVyWptaStJB2ma9CVYKcU639x89YnJ?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "mthPPZnQijssvWuPzZwF9kLFYwrD3jGffDbJshKQruC",
            "symbol": "JRSW",
            "name": "Jim Raynor (mthP)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/33psYCJC3ESZzhzEpxm8vgAVcsP2BJ6Wp4fQZ19kTrdH/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/33psYCJC3ESZzhzEpxm8vgAVcsP2BJ6Wp4fQZ19kTrdH?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "AAheokyVcSbu9j84otF8txMHX53xbH2QD7Ws7waHTbZ",
            "symbol": "KGG",
            "name": "Master of Circles (AAhe)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/FKabzds8w3k7ZQRkPS6duTZJDhCiHiaR64Vq9AkCtsgP/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/FKabzds8w3k7ZQRkPS6duTZJDhCiHiaR64Vq9AkCtsgP?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "4Sf1tn3W23PGDbbEo3TsHDxLyd36wVDY812fNthX8HqX",
            "symbol": "KRG",
            "name": "Kirill (4Sf1)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/7su4iG1W2qkQiaLS1MTZJs1SQzhoAM5HuhRrnH4unXHN/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/7su4iG1W2qkQiaLS1MTZJs1SQzhoAM5HuhRrnH4unXHN?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "BgqDU57Y5xd4NHQZ6stRdsVxW6RcNUDW2zXbZDztNBnq",
            "symbol": "PAKRG",
            "name": "Phantom Andrei (BgqD)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/GF1gRpCRFFpbafMeSQaFXJk4BNvab9o7nZN4Hntdhzn5/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/GF1gRpCRFFpbafMeSQaFXJk4BNvab9o7nZN4Hntdhzn5?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "rHPjzFU3dJ4zuYMhKXkbF1kKdrDtrc3ys3jZHWBuVHF",
            "symbol": "PTWO",
            "name": "I am human (rHPj)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/Fzmkp2zp3kN4BqAkExhqBgngQKX7ix3vYxp8im9dPh6t/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/Fzmkp2zp3kN4BqAkExhqBgngQKX7ix3vYxp8im9dPh6t?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "9bZJdav86hkQSqFNnuPg3gQ6GcHLKaW2xFDAsZ7cjbHU",
            "symbol": "SMN",
            "name": "Simon Shchedrin (9bZJ)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/7piaSvW7udTHu9buGh1h4fQnYZ2aj4zLYMWM8de1XY8z/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/7piaSvW7udTHu9buGh1h4fQnYZ2aj4zLYMWM8de1XY8z?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "BsJ5yyh63pzAoV32Wr3SLkbPwntRwWVUWrHEKtYxdtJV",
            "symbol": "TESTT",
            "name": "Phantom Phantomovich (BsJ5)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/y3xTFb1EPZqrUp4EKzUuinJn1Fa5ywJAgG5xyiZLqQj/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/y3xTFb1EPZqrUp4EKzUuinJn1Fa5ywJAgG5xyiZLqQj?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "9BmtYXeTnmdWiyhkSpVQ4rt2T5YsAUepyJuoQpKbaJAB",
            "symbol": "TIRED",
            "name": "Phantom Phantomovich (9Bmt)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/89ZBi4ejzTs2oZS2YqnRRdYKxdzPxTLvy9XqfbzCrZmz/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/89ZBi4ejzTs2oZS2YqnRRdYKxdzPxTLvy9XqfbzCrZmz?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "E68is3KKzHGXr9aDijKPHPtYXpHTK4u5pq6auqPViNpa",
            "symbol": "TMAC",
            "name": "Timofey from macbook (E68i)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/F9xvUGCZa5oSxXDvzyDHp2r3ZqrBQMg8GYF5uDxBMbbB/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/F9xvUGCZa5oSxXDvzyDHp2r3ZqrBQMg8GYF5uDxBMbbB?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "ANqjKBxkJz2KSeCspw6zBRHA7LtA8SHgQHxnHWsCdH5T",
            "symbol": "TTT",
            "name": "Lord of the rings (ANqj)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/4fHZRvZ3U672GZH2dQz7wsGxTHdAZ6H2Rx52pxNtYxab/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/4fHZRvZ3U672GZH2dQz7wsGxTHdAZ6H2Rx52pxNtYxab?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "3boYXtxRvmEkBtLBSNP1sfporopRpV71HkqXp9fAaAf3",
            "symbol": "ZZZ",
            "name": "Eugene Trofimov (3boY)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/2MeA5pogprKa1Jyx3k1caTyZgHbBwsvb1w5mq6z4Ht3L/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/2MeA5pogprKa1Jyx3k1caTyZgHbBwsvb1w5mq6z4Ht3L?src=token-list"
            }
        },
        {
            "chainId": 103,
            "address": "D6zZNh4u7NLT3CWh8pv8XGpEr3yfjqiNs5myvyvEWPhm",
            "symbol": "ZZZZ",
            "name": "Wallet 6 (D6zZ)",
            "decimals": 4,
            "logoURI": "https://devbeta.human.ag/api/profile/2kZYqyAGZ8c9Ud1cdhoEqPipnmd8BS6pBZ8oEem3oPae/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://devbeta.human.ag/address/2kZYqyAGZ8c9Ud1cdhoEqPipnmd8BS6pBZ8oEem3oPae?src=token-list"
            }
        },
        {
            "chainId": 101,
            "address": "7Q4Tfdxn5Cj2rEeeewogaYr3yi94T8tAzDS4PBv48KXN",
            "symbol": "SPRO",
            "name": "Espresso Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cryptocode1/cryptotoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "977QUBGpynbm8s8v5WCRjCvE7QTbF9xU3yu74a7tB8cn",
            "symbol": "SQWD",
            "name": "SquidwardKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PGFsquidward/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BX9Qbqjuj7pg8ecNbvEDk1J4RYwjzACN2rdjsX4Sa6kL",
            "symbol": "FEZ",
            "name": "zemmour",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BX9Qbqjuj7pg8ecNbvEDk1J4RYwjzACN2rdjsX4Sa6kL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://youtu.be/JAiTOK16Xfk"
            }
        },
        {
            "chainId": 101,
            "address": "Em88wnxQo952FBQhJcBS1bRZR4PC7UVaPozQ9EC5PsHE",
            "symbol": "AFNF",
            "name": "All Family No Friends ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AFNF93/Crypto/main/AFNFLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3NPCYLdMWF4x4vDtgzmbCqqt6n8sc2r4skQTYUu4NWxY",
            "symbol": "MTR",
            "name": "Meteor",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gugunaga/meteor_token/main/assets/MTR_SYMB.png",
            "tags": [
                "nft",
                "game"
            ]
        },
        {
            "chainId": 101,
            "address": "98Hora73y7MALFLCfcBZpUPbdW8TmADJKBV5Foo4wicv",
            "symbol": "ROAR",
            "name": "ROAR Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/98Hora73y7MALFLCfcBZpUPbdW8TmADJKBV5Foo4wicv/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/cuYdr7F33g",
                "twitter": "https://twitter.com/CubsClubNFT",
                "website": "https://cubsclub.io"
            }
        },
        {
            "chainId": 101,
            "address": "3xsHAxDCADDi2Wo1VgAnrpGjBfQRpBrdWXakJKzLNn1W",
            "symbol": "OCCC",
            "name": "Cute Cat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Samthebest999/crypto-real/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6CJDKPcvWcMu5mGoBHMuBvZTHfkzne73bSXNnxwkX6tH",
            "symbol": "Des",
            "name": "Design",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/owens33/crypto/main/designn.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/design_tokensol"
            }
        },
        {
            "chainId": 103,
            "address": "GUBswusbccMEuLFeTECT3EyLRgo8Vn5di9FjpC5vCfuT",
            "symbol": "OGL",
            "name": "OGL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BHamA7cqt2ZSTqzGagamALprsmFnzQRPJfgx65VcRyZU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://youtube.com/channel/UCEmwk5liIg8lxIjGqh8uHaA"
            }
        },
        {
            "chainId": 101,
            "address": "5smauDYN5njuCSKiAAxz3qgPpCyYHFWqYzBpEq7F7gjS",
            "symbol": "GKC",
            "name": "GypsyKingCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GypsyKingCoin/cryptography/main/b563066513f0448baefd0254e69dcfc7.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2jKfiEJWC4wdyq6LnJUPtur2vjEH2VGQUoZThgCDry4J",
            "symbol": "MIG",
            "name": "Migrant",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2jKfiEJWC4wdyq6LnJUPtur2vjEH2VGQUoZThgCDry4J/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://agora-asile.ch"
            }
        },
        {
            "chainId": 101,
            "address": "8vMt2wuphVbehxhCiGsDZYn7Dw4sPFdw4jHq6JuYbZvy",
            "symbol": "ALE",
            "name": "Beer token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/roblound/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Wf4RCoeBsnjaWuSP8Z2uNKyBjjCVumLhAkpzDcTw175",
            "symbol": "KLA",
            "name": "Kalia",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nickrawlings2012/logo/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://josephikhalia.com/kalia"
            }
        },
        {
            "chainId": 101,
            "address": "AuniAuHUDDXasDZ3v6h4M2oJhZERXzSZFixqED2Etywy",
            "symbol": "MAGD",
            "name": "MagnumDong)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AuniAuHUDDXasDZ3v6h4M2oJhZERXzSZFixqED2Etywy/logo.png",
            "tags": [
                "MAGD",
                "magnum-dong"
            ]
        },
        {
            "chainId": 101,
            "address": "ETF3jwfXE5qSF9iDbwnzjUwZoi6YL6DvRRz2M8d6HDrV",
            "symbol": "TYLR",
            "name": "Tyler",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolPAce/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "52TaFzNdtQzCQw7msp3f2sFP5eiNZVs64KXTRsG9M35y",
            "symbol": "LAND",
            "name": "CommonLand",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Swaeyzi/CommonLand/main/CommonLand_22.01.13.png",
            "tags": [
                "financial-token",
                "real-estate"
            ]
        },
        {
            "chainId": 101,
            "address": "BmG6k6aNvTtZeGnywQqohtK3imPCK5CaNgs5WvddN3j1",
            "symbol": "KK",
            "name": "KidsKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dfergusoncle/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H7SuDJBGwzRJyoyjpkfkEUtwUS6ywk7LmL8dU3TuZUTB",
            "symbol": "SUS",
            "name": "Sus coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/devil182007/crypto/main/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BDdPxywjLGwecVxe7Ucge8QwYBFbev5TH3V8rTviUcGg",
            "symbol": "WAR",
            "name": "GameOfWar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/moonwalk103/crypto/main/war.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AWx5xbxedmeDkkgM37K2z7jSiLYVViDiqJGja8dMeMAj",
            "symbol": "BHFC",
            "name": "Buck Hill Falls Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dc215007/bhfc/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GQVAdB2mYSNG1amewdSmTheoFGggy99Hjv6fDjTZrnFo",
            "symbol": "VENOM",
            "name": "Subayai Snakes VENOM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/abrarmusa/files/master/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://subayaisnakes.com"
            }
        },
        {
            "chainId": 101,
            "address": "7Q7cGSYsitFxFavCf1GZ87rQUSfGS8sUxEwHXEM63YBC",
            "symbol": "IRC",
            "name": "IRC Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/irc-token/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/IRC_Coin",
                "website": "https://irc-chat.io"
            }
        },
        {
            "chainId": 101,
            "address": "Aq8jBSDyAqaHv8DYr8SbBRarJEQmtYhpGi3R7FsYZsug",
            "symbol": "CURSEDMIKE",
            "name": "Cursed Mikes Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aq8jBSDyAqaHv8DYr8SbBRarJEQmtYhpGi3R7FsYZsug/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cm3tGABuZYQuiKQVzuPEF2kUW141ZU1ejvqr4gSDtoXj",
            "symbol": "DNKE",
            "name": "Donkee Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EthanLavi/crypto2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HQnv1p1U3hHX4KHcALoDpE7qH2LHTErVqjattdr5kDTZ",
            "symbol": "SHUSHI$",
            "name": "ShuShi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HQnv1p1U3hHX4KHcALoDpE7qH2LHTErVqjattdr5kDTZ/logo.jpg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "http://houseoffoodporn.com"
            }
        },
        {
            "chainId": 101,
            "address": "96nnEKSfcNCUBjkN7hbDh2dXYRDyZ81JXGBdYAxsf3MP",
            "symbol": "HIEP",
            "name": "Hiep Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HiepToken/Hiep/main/20220116_031649_0000.png",
            "tags": [
                "social-token",
                "Vietnam",
                "USA"
            ]
        },
        {
            "chainId": 101,
            "address": "4PJAhtmtcwrrkxpxJcXbCCJU6jMVPqCHh3zBQZEhCrrB",
            "symbol": "PRSZ",
            "name": "PersonaZim",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zimlander/crypto/main/PSRZ.png",
            "tags": [
                "social-token",
                "community-token",
                "USA"
            ]
        },
        {
            "chainId": 101,
            "address": "8FaSGvqYWhBoqgSuVrKQSr2AquCSKyh7ufLEtrPAYJZF",
            "symbol": "ACID",
            "name": "Acid Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DeceptiveGmR/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A2xHq9wgpiEhzEKvRTXiKSspUFf51cyRhhAKcG9hXeF8",
            "symbol": "FTH",
            "name": "Feather",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2xHq9wgpiEhzEKvRTXiKSspUFf51cyRhhAKcG9hXeF8/logo.png",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "website": "https://nestarcade.io"
            }
        },
        {
            "chainId": 101,
            "address": "FKMtCmFwdovbGELve1Z3x2trid9HGkAzwDdcRHu9jqtc",
            "symbol": "ASH",
            "name": "AshishVZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ashishvz/ASH_Crypto_Data/main/Group%201.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "Indian-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5cC4ESz1a6zwQXREUZGgYyPZxmx7yEJJ3F9mBZe9qp9G",
            "symbol": "COMN",
            "name": "CommonCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cjwolff/comncoin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "payment-token",
                "financial-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7GhUW2mcC9kZYbDxbcBEhYzvDxwk2etaH6nfY9GLnHSH",
            "symbol": "UFOGs",
            "name": "UFOgs WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GhUW2mcC9kZYbDxbcBEhYzvDxwk2etaH6nfY9GLnHSH/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/UFOGs"
            }
        },
        {
            "chainId": 101,
            "address": "2YJH1Y5NbdwJGEUAMY6hoTycKWrRCP6kLKs62xiSKWHM",
            "symbol": "SGEM",
            "name": "Sol Gems",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2YJH1Y5NbdwJGEUAMY6hoTycKWrRCP6kLKs62xiSKWHM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://dazedducks.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ht1w3UzKmjZrDaEpyzNjs5YfZqiC9oPR1bHKxp8yCkuT",
            "symbol": "CSxA",
            "name": "Code Sydney test A",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/codesydney/assets/main/logo/test/hexagon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Er21kTfW9yHsL5xRGMPPPATuyDNzizMAk3pUtMyFAPrt",
            "symbol": "MP",
            "name": "MINT PASS",
            "decimals": 0,
            "logoURI": "https://chiiepdxwskdrdtt3mqrnde3xh7hib4go22qacb5myxdj34xwwqq.arweave.net/EdCCPHe0lDiOc9shFoybuf50B4Z2tQAIPWYuNO-XtaE",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/CtqSaXsY64",
                "twitter": "https://twitter.com/BohemiaArtFair",
                "website": "https://bohemia.gallery"
            }
        },
        {
            "chainId": 101,
            "address": "HovGjrBGTfna4dvg6exkMxXuexB3tUfEZKcut8AWowXj",
            "symbol": "FCON",
            "name": "Space Falcon",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/spacefalcon-io/crypto/main/logo.svg",
            "tags": [
                "game-token",
                "utility-token",
                "trading-token"
            ],
            "extensions": {
                "coingeckoId": "spacefalcon",
                "telegram": "https://t.me/spacefalconIO",
                "twitter": "https://twitter.com/SpaceFalconIO",
                "website": "https://spacefalcon.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8QN5VvpadszVqGkjzwUZs5saV85K8662hang7aWbpmfE",
            "symbol": "DEGN",
            "name": "Degenerate Ape Trader",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nvaider/solana/main/Untitled%20design.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5ZEf3d3cq2ExzV1X3srpM6Rfs4Hid1nvHJSvmWWsNcad",
            "symbol": "FANS",
            "name": "Fans I Love U",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/fans-token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FzkCv5ow9UPWh8HZ6VF8y6mrpxeCyyroSYgXo6WqLwBj",
            "symbol": "MEG",
            "name": "MEG Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FzkCv5ow9UPWh8HZ6VF8y6mrpxeCyyroSYgXo6WqLwBj/logo.png",
            "tags": [
                "nft",
                "game",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5WuCtNrZ6TnrrZFEojsMGubTJ3rfabdj9wYhMAMS4key",
            "symbol": "CNCL",
            "name": "Council Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WuCtNrZ6TnrrZFEojsMGubTJ3rfabdj9wYhMAMS4key/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D2FEb1hFrvyVP1XGsesSbnUCxuAiCqrVnHh8eM63ZwAa",
            "symbol": "VALENTINE",
            "name": "Valentine's Day",
            "decimals": 0,
            "logoURI": "https://krrdzdguglgnlkkisgwktrk3pvlst2vttfevzzcjddauwvnb63cq.arweave.net/VGI8jNQyzNWpSJGsqcVbfVcp6rOZSVzkSRjBS1Wh9sU",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://sm53k54naknzyhdbensb3r7lyfr33pmonxkes2rawxzhlr4srlkq.arweave.net/kzu1d40Cm5wcYSNkHcfrwWO9vY5t1ElqILXydceSitU",
                "description": "Valentine's Day is upon us and I'm far away from the one I'm in love with (Gh-Gh). On the Valentine's Day, my love will receive this token, containing this message; I'm in Love with you and I'm going to love you for as long as this token lives on this blockchain. Happy Valentine's Day",
                "imageUrl": "https://sm53k54naknzyhdbensb3r7lyfr33pmonxkes2rawxzhlr4srlkq.arweave.net/kzu1d40Cm5wcYSNkHcfrwWO9vY5t1ElqILXydceSitU",
                "instagram": "https://www.instagram.com/nftsolanagallery",
                "twitter": "https://twitter.com/nftgalleryshop"
            }
        },
        {
            "chainId": 101,
            "address": "B1kARrd8hS9LK683okxY8pwmEW212LbfrGSyup8USLC3",
            "symbol": "TREX",
            "name": "Tyrannosaurus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/trex-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AdUi6aRZMDCXGXcJDVGv2ttBnkkAhjkH7WgurJQCtJQc",
            "symbol": "KZM",
            "name": "KZMTV Social Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KZMTV/panda/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9EyZ6CECTHTZ5Tn2xJ4p1sciafwWsLAK7dBqAXGVqAj3",
            "symbol": "ARF",
            "name": "Arf Coin",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/93989494/148758013-4f2325b6-6b4b-40ce-a635-fbd777d65c67.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkYtowSvwrkp9G3PBKDwtfLbTxCJhuj92XcrnzxNhp3G",
            "symbol": "BLZ",
            "name": "BALLZ",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/93989494/149791451-1b1c77bc-bf0e-4942-90a5-a2793f365034.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E5G9DEbWiyDwgSh9tBLjhYU3JJpMBbPPnWLhShj2uLVx",
            "symbol": "OCTOWL",
            "name": "Octoverses Club WL",
            "decimals": 0,
            "logoURI": "https://static.wixstatic.com/media/ccf368_d2d56235725d4f21afa76e4e33229f0a~mv2.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/OctoversesClub",
                "website": "https://www.octoverses.com"
            }
        },
        {
            "chainId": 101,
            "address": "HmLehVYpehp22zQRs1fvtrgY1qR539LZGwXG3Qba3T76",
            "symbol": "RYDER",
            "name": "RYDER COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LouJoseph888/Crypto/main/ryderlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "57FSXkhL191HvuDeCgzczcx1RGWZaQJrmUESvicnXQN4",
            "symbol": "SKATECO",
            "name": "Skate Collective",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RAWBrand/SkateCollective/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "The Skate Collective Represents the Global Skateboarding Movement"
            }
        },
        {
            "chainId": 101,
            "address": "3Hi27r9kUtSmiPYtpRJNYCCGTGosnqC5Dhz812TrjpaY",
            "symbol": "DJIB",
            "name": "Djib",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Hi27r9kUtSmiPYtpRJNYCCGTGosnqC5Dhz812TrjpaY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Universal Data Storage Parachain",
                "discord": "https://discord.gg/PpZgKJkKpb",
                "github": "https://github.com/Djib-io",
                "imageUrl": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Hi27r9kUtSmiPYtpRJNYCCGTGosnqC5Dhz812TrjpaY/logo.png",
                "telegram": "https://t.me/DjibTech",
                "twitter": "https://twitter.com/Djibchain",
                "website": "https://djib.io"
            }
        },
        {
            "chainId": 101,
            "address": "2sbusqQv9kp6DoW23cCnRdRgHt4cDdB2Je8qddJtmKGT",
            "symbol": "XRIZ",
            "name": "XriZ Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChrisX33/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ChvNm6CWcUgjSMXwCjhC8eYLjZircvnsV9HT3iJkV9oa",
            "symbol": "OZ",
            "name": "Ozzy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChrisX33/crypto-ozzy/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G4iSJWW63sU8yRWtV4AkcQ5JJmEdbUHEG68aDyHbaqr3",
            "symbol": "LNFTxK2SOLAND",
            "name": "Key 2 SolanaLand",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G4iSJWW63sU8yRWtV4AkcQ5JJmEdbUHEG68aDyHbaqr3/logo.png",
            "tags": [
                "liqnft-wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/SOLAND"
            }
        },
        {
            "chainId": 101,
            "address": "3VDMeDMgXK4RFGtQ6Sr4PwPHG7T8aDeuUctKLEav317p",
            "symbol": "MILEI",
            "name": "Milei Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/milei-001.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://milei.ilov.eu"
            }
        },
        {
            "chainId": 103,
            "address": "8mAXWJXfz7v3zuBoPEvumadN4CTXciggGbGxsA2gEH78",
            "symbol": "SFT",
            "name": "Scorpion Fit Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8mAXWJXfz7v3zuBoPEvumadN4CTXciggGbGxsA2gEH78/logo.png",
            "tags": [
                "SFT"
            ]
        },
        {
            "chainId": 103,
            "address": "7nHUGFRz5En7obQUD39xfZgHNJdKq5GUfGe6oT4XVxDy",
            "symbol": "SMW",
            "name": "Scorpion Metaverse World",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7nHUGFRz5En7obQUD39xfZgHNJdKq5GUfGe6oT4XVxDy/logo.png",
            "tags": [
                "SMW"
            ]
        },
        {
            "chainId": 101,
            "address": "DTj795ZfgnJtvyARmzse7YimF1PjBVsJQFWL5RAk6hop",
            "symbol": "HAPE",
            "name": "HAPEBEAST Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/HelloCryptoHub/HAPEBEAST_coin/main/HAPE_Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ghhbwWSs4CCBY5hwDe37LG4GRyF1p1puZGFpqgZumzh",
            "symbol": "BIGM",
            "name": "Bigmos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BIGMOS/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "a45A2uc6XCPpRsqFbZEjUSf7eUqGCqDMbcw7UwWwqd9",
            "symbol": "LC",
            "name": "Ladinien Coins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LadineinCoins/Crypto/main/ladinien.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3aeoREZ34Nm1QkwJctwkKVJ5cB9NMdj6JpHHGTp7b5D7",
            "symbol": "ARK",
            "name": "Aryan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aryan20/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FLiPR1BvcJCvR94NqKrmmQ2tngLeYXPu4UXGA1i7cNR3",
            "symbol": "FLIPR",
            "name": "Flipper Therapy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLiPR1BvcJCvR94NqKrmmQ2tngLeYXPu4UXGA1i7cNR3/logo.png"
        },
        {
            "chainId": 101,
            "address": "BRg8CLYEStYAFQad3CVMCYy1cgeuvUnarAZLV8K8Hyfv",
            "symbol": "SHELL",
            "name": "MetaShells",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/metashells/main/metashellslogo.png",
            "tags": [
                "social-token",
                "metaverse"
            ],
            "extensions": {
                "telegram": "https://t.me/metashells",
                "twitter": "https://twitter.com/metashells",
                "website": "https://metashells.io"
            }
        },
        {
            "chainId": 101,
            "address": "2x7bhhL3GHDCFmQhTvnT4xYdyRXYvAL9UFjCyAv5e8gv",
            "symbol": "GHOLE",
            "name": "Glory Hole Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cosus/Crypto/main/g1142.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BGmbEEPGnWKesoVZRQX3Ti42VBDWTUzDmCkWF1jtwTDd",
            "symbol": "Val",
            "name": "Val",
            "decimals": 0,
            "logoURI": "https://arweave.net/VGI8jNQyzNWpSJGsqcVbfVcp6rOZSVzkSRjBS1Wh9sU",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://arweave.net/kzu1d40Cm5wcYSNkHcfrwWO9vY5t1ElqILXydceSitU",
                "description": "Valentine's Day is upon us and I'm far away from the one I'm in love with (Gh-Gh). On the Valentine's Day, my love will receive this token, containing this message; I'm in Love with you and I'm going to love you for as long as this token lives on this blockchain. Happy Valentine's Day",
                "imageUrl": "https://arweave.net/kzu1d40Cm5wcYSNkHcfrwWO9vY5t1ElqILXydceSitU"
            }
        },
        {
            "chainId": 101,
            "address": "HbzDzxmdcpQ7mik2q6Wp4KQ7haK4skuE8uziLd4Kyi7",
            "symbol": "POLY",
            "name": "Solana Poly",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/metalandplace/token_icons/main/solpoly.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6J2RZb63By",
                "website": "https://www.metaland.place"
            }
        },
        {
            "chainId": 101,
            "address": "TGRS4uJTH9osFV7Ku2XZURzVuKNA8g3EhSNiDjQqFsu",
            "symbol": "TGRS",
            "name": "TGRS COIN",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TGRS4uJTH9osFV7Ku2XZURzVuKNA8g3EhSNiDjQqFsu/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/TigersClub_NFT",
                "website": "https://tigerclubsol.com"
            }
        },
        {
            "chainId": 101,
            "address": "CH7XnCLxxr3fE8qa7Tt7jU7fXEo3hBjN7V3VeQBc855T",
            "symbol": "M6",
            "name": "Mission 6",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/powertronglobal/token-list/main/assets/mainnet/CH7XnCLxxr3fE8qa7Tt7jU7fXEo3hBjN7V3VeQBc855T/logo.png",
            "extensions": {
                "website": "https://mission6.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HPiNockqnxvZcQ3cyPzyboqv5vN6ypYaJNzB1k5m3hLH",
            "symbol": "WDIF",
            "name": "Whiskey Different",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DWPaks/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8irnqU1bs5K5kWuS94oanpy2aSxwYJMAmYYKFywkbH1J",
            "symbol": "AWD_WL",
            "name": "AWD Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8irnqU1bs5K5kWuS94oanpy2aSxwYJMAmYYKFywkbH1J/logo.png",
            "extensions": {
                "discord": "https://discord.gg/sFvnGB84Fq",
                "twitter": "https://twitter.com/a_wandering_dog",
                "website": "https://awanderingdog.art"
            }
        },
        {
            "chainId": 101,
            "address": "6RuHq7T4z4cCQ5MJ1VTwvDZg2n6mPCCriGgcAY6DREZL",
            "symbol": "GNGC",
            "name": "GangulyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ramanganguly/crypto/main/ganguly_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "56rYxFVCb4EfQNdRYrBtG8ENT9x3pRSHKuSCuAG9NfFs",
            "symbol": "SKREEE",
            "name": "SKREEE",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56rYxFVCb4EfQNdRYrBtG8ENT9x3pRSHKuSCuAG9NfFs/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/7uRF7Bfv",
                "twitter": "https://twitter.com/GodzillazNFT"
            }
        },
        {
            "chainId": 101,
            "address": "s1ams7bwzHCG2qQf2yMF5CWASV37YotDQsjRppVkX9n",
            "symbol": "SLAM",
            "name": "Slambert Coupons",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/s1ams7bwzHCG2qQf2yMF5CWASV37YotDQsjRppVkX9n/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sneakyslamberts",
                "website": "https://www.sneakyslamberts.com/"
            }
        },
        {
            "chainId": 101,
            "address": "54CPSwxTvPNtqoPc9Q5pmE9tC6nTM7FNAZvSUCe7sYG7",
            "symbol": "MEKAPRESALE",
            "name": "MekaPresale",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/rxrxrxrx/Meka-Presale/meka-presale.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MEKAMOUNT",
                "website": "https://mekamounts.io/"
            }
        },
        {
            "chainId": 101,
            "address": "APRrwB56R2VswHQPaANm3uMtYuPJGmZ4HBZWAePtz6F3",
            "symbol": "MSSC",
            "name": "M17SilverCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/manifest33/crypto/main/IMG_5871.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "27nqFZqb2iPBeVA7bbE4KPZrJgi3dJdKV9VzhCguSy6Y",
            "symbol": "POZZ",
            "name": "PozzCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dc215007/pozz1/main/logo1.png",
            "tags": [
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/pozzcoin",
                "website": "https://pozzco.in"
            }
        },
        {
            "chainId": 103,
            "address": "6C6EH14drHyiMVxeseqS2mUxcjEpSXQSV2vpikiMRjCE",
            "symbol": "AVIANI",
            "name": "AVIANI_Test",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6C6EH14drHyiMVxeseqS2mUxcjEpSXQSV2vpikiMRjCE/logo.png",
            "tags": [
                "test-token"
            ],
            "extensions": {
                "website": "https://www.aviani.com/"
            }
        },
        {
            "chainId": 101,
            "address": "rAnxenuKpiVq3zHmuykGQ2XE1buyMVEqr8DcbpmmQnr",
            "symbol": "KDZKOYN",
            "name": "KorudazKoyn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KorudazCrypto/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "nBesAPqZwC6Jcf8SD3iMjLPNTbJuBjG9C2KqEWMKGZy",
            "symbol": "Kult",
            "name": "KulturaArt",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nBesAPqZwC6Jcf8SD3iMjLPNTbJuBjG9C2KqEWMKGZy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HwtUnDXaNdJtMFBHw361KuqqHsufgLodbdJc2CqSywg9",
            "symbol": "MASON",
            "name": "FreeMason",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iusryn98y/free/adf7192fe1bd197624e136447b35f803e7a96c24/free.png",
            "tags": [
                "nft",
                "fractionalized-nft"
            ],
            "extensions": {
                "animationUrl": "https://arweave.net/wRu0cjCmUJCbV3CSJ6WtjnJM3DSGHIsEx9soWFobvkw",
                "description": "The real tragedy of life is when men are afraid of the light",
                "imageUrl": "https://arweave.net/Dpw_1MBQFEXJCefQZLhaBfyHm4aLEfD3GRhKzJzZOMc"
            }
        },
        {
            "chainId": 101,
            "address": "8rDACnycUMGFvndX74ZM9sxjEbR3gUpVHDjDbL4qW6Zf",
            "symbol": "TRTN",
            "name": "Shill City Triton",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8rDACnycUMGFvndX74ZM9sxjEbR3gUpVHDjDbL4qW6Zf/logo.png"
        },
        {
            "chainId": 101,
            "address": "B8on8NTurSRDZym6xCtaBoXjzbvMF4heVzYkbqG36Na5",
            "symbol": "NWL",
            "name": "Neo Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B8on8NTurSRDZym6xCtaBoXjzbvMF4heVzYkbqG36Na5/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhCXd1j49oSvMrVmAdu45zC75QxHQbjqLQsnHrjZoXjh",
            "symbol": "WOLFE",
            "name": "APEX WOLFE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jpburns82/token-list/088bb692748f95fd4df1dc55c432af1940613f8a/assets/mainnet/AhCXd1j49oSvMrVmAdu45zC75QxHQbjqLQsnHrjZoXjh/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://apexwolfe.com"
            }
        },
        {
            "chainId": 101,
            "address": "73aCdgDPtGvkq7iaUUp2FZxnnSrUsH7x2FBFhdDHk7rs",
            "symbol": "DSPL",
            "name": "DAZHUCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2xHq9wgpiEhzEKvRTXiKSspUFf51cyRhhAKcG9hXeF8/logo.png",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "website": "https://nestarcade.io"
            }
        },
        {
            "chainId": 101,
            "address": "AVnpmfTmvxK6NtvgF3X9c4khVDX9Jgf2CHG2yWSxXy7L",
            "symbol": "Nuke",
            "name": "NuclearCrypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mdmolone/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HtaG62kWs3pYhLTJFW5G9CE6a8AEy8zzY3rwqXQtgQc4",
            "symbol": "CRYON",
            "name": "Metacryonic token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Metacryonic/Cryon/main/CryonLogo.png",
            "tags": [
                "Game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BNm3YkFsVq4VJHscBXANKL4w3qJjF1pxPNd7fsXCSW6o",
            "symbol": "DELA",
            "name": "Dela",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nosoyeldela/CRYPTO/main/DELA%20COIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BHzj5oaBZTjUU9JzwmQBeLP3EttCMsTn4f4JXLd6ASrJ",
            "symbol": "MYSTIC",
            "name": "Mystic Token for MysticVerse",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EcrV3zpPhN1adDk91XTTVaCaZnbag2kqziTnwFfPvNNa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.mysticgirlsclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "DSkmKvFBPy9ZQvGzWJXcDujHrAbXEc2nFnXuYQoQYCyC",
            "symbol": "SHINU",
            "name": "Shiba Infinity Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DSkmKvFBPy9ZQvGzWJXcDujHrAbXEc2nFnXuYQoQYCyC/logo.png",
            "tags": [
                "utility-token",
                "Shiba-Infinity-token"
            ],
            "extensions": {
                "telegram": "https://t.me/shibainfinitygroup",
                "twitter": "https://twitter.com/shibainfinity_",
                "website": "https://shibainfinity.org"
            }
        },
        {
            "chainId": 101,
            "address": "GFMQn1KitWxEZeFAZ8jSfRg69TRAJGdwPoao89Jme5nR",
            "symbol": "CARD",
            "name": "Cardinalcoin",
            "decimals": 0,
            "logoURI": "https://github.com/KnoxEstes/CardinalLogo/blob/main/cardlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AxDiQTWTC1mY9UE5G6fXyUZU9ngof4vqnf1GhVHW6BSi",
            "symbol": "K",
            "name": "K COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AxDiQTWTC1mY9UE5G6fXyUZU9ngof4vqnf1GhVHW6BSi/logo.png"
        },
        {
            "chainId": 101,
            "address": "Cv1mECp8qVo2bFvdJRUAhiuParGhUGPPLuYLmBMU5sA8",
            "symbol": "SCOW",
            "name": "Smart Cow Token",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/KingsFourze/SmartCowToken@main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sf4iQbtGY7curML7LHdzZ1R7zs5Dxxm1QEHPdm7BqBB",
            "symbol": "LMBD",
            "name": "Lambda",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/imfazed/Test/main/Lambda.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ERJEUNsTwx",
                "twitter": "https://twitter.com/lambda_sol"
            }
        },
        {
            "chainId": 101,
            "address": "BYJ8ZRTZZAHuZsqAiz83eyCGDNuirbucUfNDj2Th6GFm",
            "symbol": "MESSI",
            "name": "Messi Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/messi-001.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://messi.ilov.eu/"
            }
        },
        {
            "chainId": 101,
            "address": "9jssxmtXAGVPG8YbvAkC4sFYoYhYV9JeEHA3ro4zsUmq",
            "symbol": "AOLT",
            "name": "Alpha Ohlinger Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dandykrions/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DzhFAZyUjZd3YnKHPfgN77oC9TcCf6BhCXMNQXKgMvD4",
            "symbol": "AGT",
            "name": "AnimeGirlsToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Twistedjay1988/CroAnimegirls/main/Screenshot_20220117-212021_Pinterest.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "93ubxUgXyzk4U245MEJrVv6fM5S25o72TUSUpmbg6e97",
            "symbol": "SINGULARITY",
            "name": "Singularity",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3sJWs1uNphtHMVrqqP6foFxNTi1XLa6BZiyftq41y6kQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.singularityonline.app",
                "description": "Singularity Online in-game currency Token",
                "twitter": "https://twitter.com/SingularityMeta",
                "website": "https://singularityonline.app",
                "whitepaper": "https://whitepaper.singularityonline.app"
            }
        },
        {
            "chainId": 102,
            "address": "J6epRUJv4ZL5Zp8J9EDM2XGTCqZ2FEknBwwerMG6TuPY",
            "symbol": "VELORUM",
            "name": "Singularity DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3485gPZHaNvhXC15HqfSUojefN2xPfg5mJCrnY6BNU6T/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.singularityonline.app",
                "description": "Singularity Online DAO Token",
                "twitter": "https://twitter.com/SingularityMeta",
                "website": "https://singularityonline.app",
                "whitepaper": "https://whitepaper.singularityonline.app"
            }
        },
        {
            "chainId": 101,
            "address": "8Hs4BfGZCZiJSs4ZWzTm4nYHtLHupYdStTcmvshHn5Mv",
            "symbol": "Au",
            "name": "GOLD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fadfsdf/gold/62daab88722607643c980fb13deb80e4a9cb296a/AU.png",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token"
            ],
            "extensions": {
                "animationUrl": "https://arweave.net/MLYqWT7VqoDsUeRB8rjBC-lwvqH5BER0fM1F-pHaoVM",
                "description": "The earliest recorded metal employed by humans",
                "imageUrl": "https://arweave.net/MLYqWT7VqoDsUeRB8rjBC-lwvqH5BER0fM1F-pHaoVM",
                "instagram": "https://www.instagram.com/nftsolanagallery",
                "twitter": "https://twitter.com/nftgalleryshop"
            }
        },
        {
            "chainId": 101,
            "address": "2vW4TWGJoQHqwspbw7TgP14bAZnuwW7DvXPEGd2295yT",
            "symbol": "SUCT",
            "name": "StandUpClubToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vW4TWGJoQHqwspbw7TgP14bAZnuwW7DvXPEGd2295yT/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://standupclubnft.ru/"
            }
        },
        {
            "chainId": 101,
            "address": "HDRkJTx6f2yiUBAuqCPE3JXgNx4WJm7RzydH5j6ctb4o",
            "symbol": "IMIX",
            "name": "IMIX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/imixtoken/crypto/main/logo.png",
            "tags": [
                "metaverse",
                "social"
            ],
            "extensions": {
                "description": "IMIX is a Solana token that can be used to pay for virtual lands and properties as well as in-world goods and services"
            }
        },
        {
            "chainId": 101,
            "address": "F6ZyzgtCU37NgFGTKYfdBy7BKwzxNZVBDkB68v9Qp1ch",
            "symbol": "Crazy",
            "name": "CrazyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ob1iviou5/CrazyCoin-Token/main/CrazyCoin%20Logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Crypto"
            ],
            "extensions": {
                "description": "CrazyCOin is a Solana Token that can be used to pay for CrazyBabyNFT sales and Tech Sales on the CrazyCoin Website",
                "facebook": "https://www.facebook.com/The-Crazy-Baby-NFT-111999381365589/",
                "instagram": "https://instagram.com/crazybabynft",
                "twitter": "https://twitter.com/crazybabynft",
                "website": "https://www.crazycoin.co.uk"
            }
        },
        {
            "chainId": 101,
            "address": "2SBJdmcd9H6A8ori4QfzmXdFyQbKCUbAq7ZGhKAn2nru",
            "symbol": "non",
            "name": "nondos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nondoscom/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P",
            "symbol": "GYC",
            "name": "GameYoo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gameyoo/materials/main/gyc.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "metaverse",
                "play-to-earn",
                "play-to-contribute"
            ],
            "extensions": {
                "github": "https://github.com/gameyoo",
                "twitter": "https://twitter.com/GameYooOfficial",
                "website": "https://gameyoo.io"
            }
        },
        {
            "chainId": 101,
            "address": "BtxmGUJHu8iqLu8rqECHhwDzbX3J4EHjA8NbtjiRXUoJ",
            "symbol": "TIGERW3",
            "name": "Web3Tiger",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtxmGUJHu8iqLu8rqECHhwDzbX3J4EHjA8NbtjiRXUoJ/logo.png",
            "tags": [
                "TIGER",
                "Meme-token",
                "Web3"
            ],
            "extensions": {
                "twitter": "https://twitter.com/web3tigers",
                "website": "https://www.web3tiger.org/"
            }
        },
        {
            "chainId": 101,
            "address": "5TdW2Tag4VxiRRa8w3aKYLzSgt4supPQwDvPpSpQBoBb",
            "symbol": "NBY",
            "name": "NEBLY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NEBLY1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN",
            "symbol": "$HONEY",
            "name": "HONEY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN/logo.svg",
            "tags": [
                "utility-token",
                "DeFi",
                "Governance"
            ],
            "extensions": {
                "discord": "https://discord.gg/honeydefi",
                "website": "https://honey.finance"
            }
        },
        {
            "chainId": 101,
            "address": "CFbdjaKonbBQTYG2GC6CmB7exofgDYGCDR8tp8KVGS7T",
            "symbol": "BORG",
            "name": "Cyborg Apes BORG",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CFbdjaKonbBQTYG2GC6CmB7exofgDYGCDR8tp8KVGS7T/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wunVdsjwZT",
                "twitter": "https://twitter.com/CyborgApesNFT",
                "website": "https://www.cyborg-apes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ThS1b5XmyRsfiXeBUnyo9LaYEsFJh44FdGucP8hx4UB",
            "symbol": "TherosWL",
            "name": "Yukan Theros WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ThS1b5XmyRsfiXeBUnyo9LaYEsFJh44FdGucP8hx4UB/logo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/YakuzaRaptorz",
                "website": "https://www.yakuzaraptorz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5upTHBTcup7vzCyhFL1dU3CoGRyc8CSBA9RZeZKhySs2",
            "symbol": "LEM",
            "name": "Lemon",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5upTHBTcup7vzCyhFL1dU3CoGRyc8CSBA9RZeZKhySs2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/lemonspl"
            }
        },
        {
            "chainId": 101,
            "address": "HsVxKa5TyLcqSEk1T2hBNMoikguEp19Wcuar9419q8CE",
            "symbol": "DARK",
            "name": "Darkness",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yekmil/DARK/main/darkness.jpg",
            "tags": [
                "Meme-token"
            ]
        },
        {
            "chainId": 103,
            "address": "5XXoLweEFQKheSnxj4d3AqLq3Nm8mr2DAvxCT8mRHUAd",
            "symbol": "VICKI",
            "name": "Vicki Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5XXoLweEFQKheSnxj4d3AqLq3Nm8mr2DAvxCT8mRHUAd/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FeHBcCk3HGESCYyrzMCvc6CrnpyhhLXVJkextDvgLNbj",
            "symbol": "NHLT",
            "name": "NorthLight",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeHBcCk3HGESCYyrzMCvc6CrnpyhhLXVJkextDvgLNbj/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "A completely new experience!",
                "website": "https://www.bfgs.net"
            }
        },
        {
            "chainId": 101,
            "address": "Fviqd6ttQnCHbrBsHNfdCgYarWa2wCjv214SKHbMM512",
            "symbol": "$RLC",
            "name": "RockCycleNFT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/mannupvt1/rockcyclenft/main/Rzp9lJSROl56K1ON17zTgtExw448SIPUeS6LOM1IYqlRpPdp.png",
            "tags": [
                "utility-token",
                "NFT",
                "Gaming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/rock_cycle_nft"
            }
        },
        {
            "chainId": 101,
            "address": "G6sDiZ4aeNAVnht1kKxorVEdjA67u4ExXVcX3K3xWChy",
            "symbol": "Pi",
            "name": "Pi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hjuhyiu/pi/f23510ccc93cdbf437f32b926d5ba7cf56f0f92c/unnamed.png",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token"
            ],
            "extensions": {
                "animationUrl": "https://arweave.net/mReUKbHGXoLXlxfp_DOjrUqvbOigFw2rmw-u3XdnrAU",
                "description": "Pi= 3.1415926535897932384626433",
                "imageUrl": "https://raw.githubusercontent.com/hjuhyiu/pi/b4822caa552a5ea0ab80e6eb016cf1902172c813/picirt.png",
                "instagram": "https://www.instagram.com/nftsolanagallery",
                "twitter": "https://twitter.com/nftgalleryshop"
            }
        },
        {
            "chainId": 101,
            "address": "FQ8Qp15y64J1JaggpmsYQoVff2pGJ758kypUt3napgGq",
            "symbol": "BEAST",
            "name": "The Beast Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/TheBeastCoin/assets/main/Inverted_Pentagram_200x200.png",
            "tags": [
                "soul",
                "soul-token",
                "soul-trade",
                "imunity"
            ]
        },
        {
            "chainId": 101,
            "address": "Ce4ZyHGeVtNtULo7u1cgD1Sc6q4GS5UZbSP3Yf4i6R5g",
            "symbol": "DIN",
            "name": "E-Dinar",
            "decimals": 9,
            "logoURI": "https://github.com/edinarorg/solana/blob/main/1x1.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://edinar.org"
            }
        },
        {
            "chainId": 101,
            "address": "4JJKbNqaoKmCXKdh4Nj7eqdPXGqBjZtae2jQqZDqDinM",
            "symbol": "SUI",
            "name": "SUI Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/colinchen4/SUICrypto/main/logo.png",
            "tags": [
                "NFT-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DbNYgaX2Q1mMkSQvu6c9PzMAvAkGQxc6KR3MMhePE6un",
            "symbol": "ARTB",
            "name": "Artbank Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DbNYgaX2Q1mMkSQvu6c9PzMAvAkGQxc6KR3MMhePE6un/logo.png",
            "extensions": {
                "website": "https://artbank.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "9sENJYFAJVqZ1kMo9R3rJ5LdhNkFgDAbYbPDdiCWVAbK",
            "symbol": "DMP",
            "name": "DMP coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9sENJYFAJVqZ1kMo9R3rJ5LdhNkFgDAbYbPDdiCWVAbK/logo.png",
            "tags": [
                "master-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8mEwLo9B32uGdq5sY4bGzbuXKVdWfXm6unzbwkDpXGDJ",
            "symbol": "FUC",
            "name": "FuCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8mEwLo9B32uGdq5sY4bGzbuXKVdWfXm6unzbwkDpXGDJ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 102,
            "address": "6CYxiyUo2pgMHkCWpWf4TU47f3Q1rMUWP1R4WtFmVLLA",
            "symbol": "PC",
            "name": "Pin Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6CYxiyUo2pgMHkCWpWf4TU47f3Q1rMUWP1R4WtFmVLLA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://pinclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7wSZ3mbpii2XPzedKht9sFQdocRk3GF3XgwNK9zzazq8",
            "symbol": "WIZC",
            "name": "WizardCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/paytenj10/crypto/main/PurpleWizard.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CkXDtsa1zrpw8ebvrnkp8ombWwx8iybLoNG8PQNF32uu",
            "symbol": "BSD",
            "name": "Based Department",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkXDtsa1zrpw8ebvrnkp8ombWwx8iybLoNG8PQNF32uu/download.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq",
            "symbol": "sRLY",
            "name": "Rally (Solana)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq/logo.png",
            "extensions": {
                "coingeckoId": "rally-solana",
                "website": "https://rly.network"
            }
        },
        {
            "chainId": 101,
            "address": "8Ggd9FGkA8q6YJaAMFL3PrBcFipKbU3HBtpUAGtd6EQP",
            "symbol": "SVEN",
            "name": "7Realms",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Ggd9FGkA8q6YJaAMFL3PrBcFipKbU3HBtpUAGtd6EQP/logo.png",
            "tags": [
                "game-token",
                "community-token",
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "website": "https://www.sevenrealms.io"
            }
        },
        {
            "chainId": 101,
            "address": "2zzC22UBgJGCYPdFyo7GDwz7YHq5SozJc1nnBqLU8oZb",
            "symbol": "1SP",
            "name": "1SPACE",
            "decimals": 9,
            "logoURI": "https://1space.me/images/1SP_logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/+jDm8ioFvec5iNzFk",
                "website": "https://1space.me/"
            }
        },
        {
            "chainId": 101,
            "address": "EpNByUUwEp6GDKWAhfuwZmm9jQN7PbvmQ4zgg57CDqRF",
            "symbol": "BassC",
            "name": "BassCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/noeljbass/basscoin/main/basscoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3YLfFYzpqcXnvWyErkFz8WRK4jvfNrC3eLqpXKCvzxa6",
            "symbol": "SPIT",
            "name": "SpitCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/larsBYN/TokenV1/main/Untitled%20design.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DANE1SmS2ahPkFNER6tfTPGLcJKK67z8zVjMj4PKoS79",
            "symbol": "DANE",
            "name": "Danerob Puppy Redeem Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DANE1SmS2ahPkFNER6tfTPGLcJKK67z8zVjMj4PKoS79/logo.png"
        },
        {
            "chainId": 101,
            "address": "CMa2oZ98xdsDFVdQpKKzgAJLJUq2HBiUCMi9y3u5S3uo",
            "symbol": "Nashorn",
            "name": "Nashorn Access",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CMa2oZ98xdsDFVdQpKKzgAJLJUq2HBiUCMi9y3u5S3uo/nash.png"
        },
        {
            "chainId": 101,
            "address": "GYHscURsqEm7HCnQK4UMfSNom9Vf3ihmwuV5suUGc2v9",
            "symbol": "RCA",
            "name": "RCA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rca2001/token/main/LOGO.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9RdHu9BZx4fjVjoptaSMYe3NGkt1UGjMTpt54f44tAWF",
            "symbol": "OliveBranch",
            "name": "PEACE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rwererfwe/peace/f8fb9422752cdb25ef7c12d58261530ed176fde9/PEACE.png",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token"
            ],
            "extensions": {
                "animationUrl": "https://arweave.net/v9E2asrABVKbj7c7iHvuycU9uNRCEeOn3oA4rGI6VuU",
                "description": "Human beings are members of a whole, in creation of one essence and soul",
                "imageUrl": "https://raw.githubusercontent.com/hjuhyiu/pi/f2c534f82e33e614a2befc9e70cae093b1ca86c6/peacecirt.png",
                "instagram": "https://www.instagram.com/nftsolanagallery",
                "twitter": "https://twitter.com/nftgalleryshop"
            }
        },
        {
            "chainId": 101,
            "address": "7uu5QFWfFaNHAYMtXqiShwg4oCqkMhFqUVZmkySj8LZD",
            "symbol": "NYLCN",
            "name": "Nylon Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shortcutgamez/crypto/main/logo.png",
            "tags": [
                "social-token",
                "nft",
                "collectible-token",
                "rare-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8HQAcKYzowK7R2rXhcx3zqTAJ2WtDsFhRvrYZQNck6jq",
            "symbol": "NSL",
            "name": "NftSOl",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/NFTSOL/assets/main/assets/nftsol500.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nftsoltoken",
                "website": "https://nftsol.club"
            }
        },
        {
            "chainId": 101,
            "address": "FZ3gSK53564jaFbwVsUYmmPXboLaMSW9xibRoreQVtYq",
            "symbol": "GECO",
            "name": "Gecco Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/curthulbert/crypto/main/logo-main.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ufxXyARKodhb9T9gEQKSTLE99vSADphjHGpxkivQcvN",
            "symbol": "QURAN",
            "name": "HOLY QURAN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xerterbt/quran/5c2f0b7456c62b53149a10c2746d68069a7f4ece/quran1.png",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "gift-token",
                "social-token"
            ],
            "extensions": {
                "animationUrl": "https://github.com/xerterbt/quran/raw/5c2f0b7456c62b53149a10c2746d68069a7f4ece/Holy_Quran_Full.pdf",
                "description": "Recite in the name of your Lord who created man from a clinging substance",
                "imageUrl": "https://arweave.net/d9XqIxPGi_cTvMihuSYGjABXe54gzvsTkeQHkrTB5Yg",
                "instagram": "https://www.instagram.com/nftsolanagallery",
                "twitter": "https://twitter.com/nftgalleryshop"
            }
        },
        {
            "chainId": 101,
            "address": "AXjJ1PSuDs79xokX5qx6jTi2pRY8TK665YRwFjwyfg5R",
            "symbol": "SGBR",
            "name": "SolGrabber",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/slimeyoctopus/SOLG_Token/main/logo.png",
            "tags": [
                "nft-token",
                "collectible-token",
                "rare-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/X6R4WgAW8n",
                "twitter": "https://twitter.com/SOLgrabbers",
                "website": "https://solgrabbers.io"
            }
        },
        {
            "chainId": 101,
            "address": "SLNTvrwEnq9tYevoyvXmRksRUAEVWCWPsyngJghVNqX",
            "symbol": "SLNT",
            "name": "Solanite",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLNTvrwEnq9tYevoyvXmRksRUAEVWCWPsyngJghVNqX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Solantasy Token",
                "website": "https://solantasy.com"
            }
        },
        {
            "chainId": 101,
            "address": "GrovT4mo1XXTQcGt96rrUMYtwAkmDY6AYY3axeTb7GRv",
            "symbol": "SPORK",
            "name": "Spraken Pork",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WendonScott/Spraken-Pork/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9UXbgaxC83SSvkQUEkN9ai1MoSyUivREsJSGj43Vqgap",
            "symbol": "JFK",
            "name": "John F Kennedy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ynbgjjgj/lady/8312f9cad00747cda2c8734d3512111a11c1e465/JFK.png",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "gift-token",
                "social-token"
            ],
            "extensions": {
                "animationUrl": "https://arweave.net/pOJ7pYdSUhvyDIXSXXXbJgkQGTPBsh4fQvyt6jHb7Uk",
                "description": "Change is the law of life",
                "imageUrl": "https://raw.githubusercontent.com/ynbgjjgj/lady/5d1f8d8382bb466d3ebbec97655d7dd60fbd298b/JFKcirt.png"
            }
        },
        {
            "chainId": 101,
            "address": "3dcSdwTsZxFdYGoWAbFPD75GNnAEkkuiV5BZmueejUXX",
            "symbol": "MSTO",
            "name": "MySoTo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mysoto/soltoken/main/mysoto_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9hFgPJBTz8rwsUy7fDoNAa3zFaWNhNRatNRnU4KFHabQ",
            "symbol": "RHNO",
            "name": "Rhino Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RagingRhino564/rhinoCoin/main/rhinoCoin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SLFYmFvVShjT3783aDbtKzwjMZ9tAcC6YJa7VN5ou1s",
            "symbol": "SLFY",
            "name": "Solify",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLFYmFvVShjT3783aDbtKzwjMZ9tAcC6YJa7VN5ou1s/logo.svg"
        },
        {
            "chainId": 101,
            "address": "8f3iD4Gz4RrXNggEaJtrV587s6RWFaSqeFXyF2Pd7Nhe",
            "symbol": "KMFJ",
            "name": "Kristi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LePedant/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://kristij.com"
            }
        },
        {
            "chainId": 101,
            "address": "DRDJyPK1JdgrEsvqsjeNey7NfWEVzhTs8xNjtq6tfTLN",
            "symbol": "CRONEP",
            "name": "Nepokoreni Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/p1nkhr/test/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dy66WHU3DqSKZeCMt3S7645oyP1D3mkJzB2CevjPmm1F",
            "symbol": "REX",
            "name": "Ryok Haka Rex coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dy66WHU3DqSKZeCMt3S7645oyP1D3mkJzB2CevjPmm1F/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/RyokHakaREX",
                "website": "https://www.ryokhakarex.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9gkgThnU59MixF7SzWuCxG7zqsWYazeUAKwmrCVfYMFu",
            "symbol": "FUNC",
            "name": "Fun Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gkgThnU59MixF7SzWuCxG7zqsWYazeUAKwmrCVfYMFu/logo.svg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4o67Pazc9fNqEfQM66xqWngw7WdAUzsccdpPmKsANDg1",
            "symbol": "LAVA",
            "name": "LAVA Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4o67Pazc9fNqEfQM66xqWngw7WdAUzsccdpPmKsANDg1/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Phenom Phoenixes Utility Token",
                "discord": "https://discord.gg/nkR4RcPSqJ",
                "twitter": "https://twitter.com/phenomphoenixes",
                "website": "https://phenomphoenixes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DJUVYb1zayUq2UpoQnk1VUU4qh7C6RJyz1rWqKa92FpW",
            "symbol": "12bar",
            "name": "12bar",
            "decimals": 0,
            "logoURI": "https://github.com/dbuster/12bar/blob/main/12barlogo200200.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/dbuster16/"
            }
        },
        {
            "chainId": 101,
            "address": "J9XkQyTdVpHWqxv9accWCZ8KS8QjkXLinGc9jenareJi",
            "symbol": "FULL",
            "name": "FullToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J9XkQyTdVpHWqxv9accWCZ8KS8QjkXLinGc9jenareJi/logo.png",
            "tags": [
                "utility-token",
                "carbon-offset",
                "energy-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "76DThuyLHagfkm2ssYw6Lv7k3MYHx6tXcwPBLU9tXm4D",
            "symbol": "BASIC",
            "name": "Basic Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/76DThuyLHagfkm2ssYw6Lv7k3MYHx6tXcwPBLU9tXm4D/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/basicapesociety",
                "website": "https://www.basicapesociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BfRHTQVGjLvGyENSyBUW1i1hifS4RAzxupFs6dhx4Uy2",
            "symbol": "FAK",
            "name": "Freaky Apez Klub",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sethtdev/FAKtoken/main/FAK_1.png",
            "tags": [
                "nft-token",
                "collectible-token",
                "rare-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/freakyapez"
            }
        },
        {
            "chainId": 101,
            "address": "AXgKEpV7tHUo8SEkb4pHvedc8TSw6xhcJ7sPUDfnVAQt",
            "symbol": "LUCK",
            "name": "Luck Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adriangl98/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9GnYb1ukBUKHobqpmNdzBE7VkYn7wWqianpKaYFPBChk",
            "symbol": "XAL",
            "name": "XAL Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9GnYb1ukBUKHobqpmNdzBE7VkYn7wWqianpKaYFPBChk/logo.png",
            "extensions": {
                "discord": "https://discord.gg/TeTk4GZfgh",
                "twitter": "https://twitter.com/xapeslab",
                "website": "https://xapeslab.com"
            }
        },
        {
            "chainId": 101,
            "address": "ByKDq6iMUgYFHAyQxm53FNgyYtaxnakA3ZRJQd4ZQSoG",
            "symbol": "PBT",
            "name": "PiggyBank Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PMEinc/tmlogo/main/logo.png?token=GHSAT0AAAAAABQJ32OH5VNMPZTFCXB6VOGYYPQ4M7Q"
        },
        {
            "chainId": 102,
            "address": "vkG3Qrz6vCWjzDt5yCLEKYMYZ8hFZ27dmKe92btaZE3",
            "symbol": "FYFY",
            "name": "FYFY Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vkG3Qrz6vCWjzDt5yCLEKYMYZ8hFZ27dmKe92btaZE3/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6jEMcEVU",
                "facebook": "https://www.facebook.com/fyfy.io",
                "github": "https://github.com/fyfylabs",
                "instagram": "https://www.instagram.com/fyfy.official",
                "linkedin": "https://www.linkedin.com/company/fyfy",
                "medium": "https://fyfy-io.medium.com",
                "twitter": "https://twitter.com/fyfyio",
                "website": "https://fyfy.io",
                "whitepaper": "https://fyfy-labs-ltd.gitbook.io/fyfy-documentation/"
            }
        },
        {
            "chainId": 101,
            "address": "RoKFpgGfAbMSrEBeapdNYuaohGwMeMeYfjs1Xyu8GtD",
            "symbol": "DISRPT",
            "name": "MoonRocks Whitelist",
            "decimals": 0,
            "logoURI": "https://arweave.net/57wgjEUQwNn9rwGMzCRYwlbv3QBmI_wueJ-riGzYHZs",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Mint your base moonrocks at https://get.basemoonrock.store",
                "discord": "https://discord.gg/WF5XWfJQ",
                "twitter": "https://twitter.com/MoonrocksMeta",
                "website": "https://moonrocksofficial.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SGMJ3vvrRHABjSosXyJiW82LvCSJdBjpT7w6KgBD3xT",
            "symbol": "KKJ",
            "name": "KuKuJiao",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jsky85/KKJ/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "14FcmwytuWNoVF62vNbV7cJuuGGu3pGR7a9P8BboWiS7",
            "symbol": "AIGA",
            "name": "AI Generative Artistry",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/grabrail/aiga/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C6qep3y7tCZUJYDXHiwuK46Gt6FsoxLi8qV1bTCRYaY1",
            "symbol": "LSTAR",
            "name": "Learning Star",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3yigvdWq6qobhAwQL6UiSide5W9W7zcJbuVvKa3T231/logo.png",
            "tags": [
                "play2earn",
                "learn2earn"
            ],
            "extensions": {
                "discord": "https://discord.gg/letmespeak",
                "instagram": "https://www.instagram.com/letmespeak_org",
                "telegram": "https://t.me/letmespeak_org",
                "twitter": "https://twitter.com/Letmespeak_org",
                "website": "https://www.letmespeak.org"
            }
        },
        {
            "chainId": 101,
            "address": "J5WamY8KrmfdaEPipMpdUxkxWjcMAhRU3pY5gJ9xL1v7",
            "symbol": "$CG",
            "name": "Code Giant",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/The-code-giant/crypto-logo/main/thecodegiantlogo.png",
            "tags": [
                "LearnToCode",
                "Blockchain",
                "Software",
                "WebDesign"
            ],
            "extensions": {
                "website": "https://www.thecodegiant.ca"
            }
        },
        {
            "chainId": 101,
            "address": "5mxRAJFyKfY5sCqcJngi7i3dBs1FkWsqimpaKj1vtjea",
            "symbol": "ANSRI",
            "name": "AnsariCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mohamedansari/ansari-coin/main/Ansari-coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZ1DeqbMzf3RKkAJdG2CuY27CgXqC35qca48dfUCf27T",
            "symbol": "COINK",
            "name": "Oink Coin",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/kakazgg/solswineslogo/SolSwines-OINK-Token_gold.png"
        },
        {
            "chainId": 101,
            "address": "7mrosadXD4c3fWo2MqCXiWYPtNmWdwcFZ9R9bEZyCQ6C",
            "symbol": "CAFFMACH",
            "name": "Caffeine Machine Coin",
            "decimals": 9,
            "logoURI": "https://github.com/sql2kguy/crypto/blob/main/logo-caffeine-machine-planet.230x230.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GytU95ZDVzsD5AF6QHzjWQwyVZsseRBe9iLt1KA8VsLb",
            "symbol": "ANSB",
            "name": "Ansibler",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theansibler/theansibler-token/main/ansibler-token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FCsftdadoqw8Sue37V66BgmRZjMg9YUr8vK3DtXj7cxV",
            "symbol": "ACC",
            "name": "Anudeep Crypto Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/anudeeptadikamalla/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BB33fYoeBVA2uv119be9tKvmXeuwtcx1W25N9KFNd2ca",
            "symbol": "GODZ",
            "name": "Godz Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BB33fYoeBVA2uv119be9tKvmXeuwtcx1W25N9KFNd2ca/logo.jpg",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/BGnCshGWgk",
                "twitter": "https://twitter.com/pocketgodz",
                "website": "https://www.pocketgodz.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "J1jeXxVPnViAusu9qCBdPf219YSGAXk5MSK94cecympa",
            "symbol": "DBZi",
            "name": "DBZi Game Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J1jeXxVPnViAusu9qCBdPf219YSGAXk5MSK94cecympa/logo.png",
            "tags": [
                "nft",
                "game",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ACN87zYFEaRqnvd9VmmDZejxzUL6xRJBUPwM3GzmdhcP",
            "symbol": "SPIKE",
            "name": "Spikes",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ReinhardvdBerg/crypto/main/Spikes-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6XWWxwh9mmy2LLdWfdqVUcdT2mVq2fng5dS13S7b8McZ",
            "symbol": "INUINU",
            "name": "Inu Inu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6XWWxwh9mmy2LLdWfdqVUcdT2mVq2fng5dS13S7b8McZ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EQZMHTLVbQmk9jUJc8mTGGczMwe7ZYA7o1iefnRhDWUj",
            "symbol": "ULBN",
            "name": "Uleborn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Freisherv/uleborn/main/uleborn.png",
            "tags": [
                "social-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "telegram": "https://t.me/uleborn"
            }
        },
        {
            "chainId": 101,
            "address": "CVsL7JFa2BHfckDcc4PTxX7ARCUGuFYav5zRDVK2JFRe",
            "symbol": "AHWL",
            "name": "Astral House Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CVsL7JFa2BHfckDcc4PTxX7ARCUGuFYav5zRDVK2JFRe/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "Welcome Our Amazing House!",
                "website": "https://www.astralapes.com"
            }
        },
        {
            "chainId": 101,
            "address": "ADZnqUz3xcGE5mVWPxLneCUKg1mTMaTdsNYp9t7nFwCg",
            "symbol": "AUTOR",
            "name": "Bridge media",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BMTVlt/AUTOR/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "copyright-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F1Ury8TwYWwqyEvqBME4smBc2TYszyZWp2Xd4ZCrntQ8",
            "symbol": "$SAKITA",
            "name": "Solakita Inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solakitainu/solakitainu-logo/main/logo.png",
            "extensions": {
                "website": "https://solakitainu.com"
            }
        },
        {
            "chainId": 101,
            "address": "3WpfuV4zYFXZmUzbkRKWtSQwbieVni5VotKKpj4NpYgx",
            "symbol": "IWT",
            "name": "iwaiter Tokens",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/mansourSaleh/iwaiter/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/iwaiterMENA",
                "website": "https://iwaiter.net"
            }
        },
        {
            "chainId": 101,
            "address": "CjwaoVnLG9xb2M9sSCp9g8r4jhV4FnMGufRXiUVnVND1",
            "symbol": "KAMA",
            "name": "Karma Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anthonybakx/crypto/main/Karma.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8AZE7sB9h76ERo6y2kopyg82njCqCXz55R1D8WidAekF",
            "symbol": "DBwT",
            "name": "Dead Bros Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/c792a3c9a4a9966c297afd9d81135b0481fa9d7a/assets/mainnet/8AZE7sB9h76ERo6y2kopyg82njCqCXz55R1D8WidAekF/logo.jpg",
            "tags": [
                "DeadBros",
                "DBT",
                "NFT",
                "Whitelist",
                "Zombie",
                "Undead"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeadBros666"
            }
        },
        {
            "chainId": 101,
            "address": "6zZJ6LbpJJtN7SRYujyS5RRxJzvRVxPfwwfunMkh8Vbp",
            "symbol": "NARA",
            "name": "Nara Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zectro/crypto/main/nara.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5n94vqEA5yNNnMvEunANoHnrDwKkcHcc6BAKNc8CZh21",
            "symbol": "ATT",
            "name": "ATT RentalCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eightbit6510/crypto/main/attcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BiFAEWzRg5smZuYbkExWCejYLMJThqd7t2HewvLcsmJT",
            "symbol": "CCY",
            "name": "Cryptic Currency",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CrypticToken/Crypto/main/Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sBUDzitkrHNZAR2gAoRorwSo6nqBEsHfRmdybHCsw8o",
            "symbol": "sBUD",
            "name": "BunnyDucky Staking Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sBUDzitkrHNZAR2gAoRorwSo6nqBEsHfRmdybHCsw8o/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BunnyDuckyHQ",
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EWL2aMkx1j7XcjdKniVMKmuK7Vds3CgMXJv28HohVBUx",
            "symbol": "$GOOD",
            "name": "Commit Good",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EWL2aMkx1j7XcjdKniVMKmuK7Vds3CgMXJv28HohVBUx/logo.png",
            "tags": [
                "NFT",
                "Philanthropic",
                "Charity",
                "Volunteer-Reward"
            ],
            "extensions": {
                "description": "Commit GOOD is an NFT philanthropic ecosystem built on the Solana Blockchain",
                "facebook": "https://www.facebook.com/CommitGood/",
                "twitter": "https://twitter.com/commitgdoffical",
                "website": "https://commitgood.com/"
            }
        },
        {
            "chainId": 103,
            "address": "EmT8DDai6qoiVrvUaFRLAu3pVoqChkYyWS8WexQVjt8m",
            "symbol": "ALBINO",
            "name": "ApesAgainst WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EmT8DDai6qoiVrvUaFRLAu3pVoqChkYyWS8WexQVjt8m/logo.png",
            "extensions": {
                "website": "http://apesagainst.io/"
            }
        },
        {
            "chainId": 101,
            "address": "48A1pXHvottXTf954CMhZyoG7MdFjngLJCDKJS6iJUth",
            "symbol": "$Yakuza",
            "name": "Yakuza Wars",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D77wfRvxzFgdAHd1C1gE5JuwuSq4QLSTxc3huB52WBXH/logo.png",
            "extensions": {
                "discord": "https://discord.gg/YUHDjmcssG",
                "twitter": "https://twitter.com/yakuzawarsnft",
                "website": "https://yakuzawars.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EcDWaqgfWre56VhCCjHbtqeVoPVWGtRkyWSA4H4GCXRD",
            "symbol": "BITC",
            "name": "BitondCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Elod044/BitondCoin/main/bitondcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Mtinr77fvdW3jBUkimTHmm4DsTUcm6YyhPRmijwrqa6",
            "symbol": "HML",
            "name": "HamalCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/florianHamal/coin/main/pic.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CYnW72dNBZcjv2rVH7QEChdynPWU6AwhWnLfCyVjAFBX",
            "symbol": "CMV",
            "name": "CINEMetAverse's",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Izsook/Cinemetaverses/main/Logo3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5fTwKZP2AK39LtFN9Ayppu6hdCVKfMGVm79F2EgHCtsi",
            "symbol": "WHEY",
            "name": "WHEY",
            "decimals": 6,
            "logoURI": "https://secureservercdn.net/160.153.138.53/cjm.06a.myftpupload.com/wp-content/uploads/2021/12/whey-coin-2048x2048.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "whey-token",
                "twitter": "https://twitter.com/shreddedapes",
                "website": "https://shreddedapes.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9w56YQTqukyTDckwvX1q3otDDqoHZKvZRfyxRs66714Q",
            "symbol": "PKLWFM",
            "name": "Pickle Law Firm Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SpyicyDev/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G4ZgnP3AuaLZBeKghrf8wGaLtkPiZEZZ7mnJ2rMYRKnR",
            "symbol": "SUDO",
            "name": "Sudo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/redneki/Sudo-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3yfKWgVECDBJMUbxEXEMbFpR3GfHK688v7yqaX6eHYVH",
            "symbol": "CPA",
            "name": "Cute Pixel Ape Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3yfKWgVECDBJMUbxEXEMbFpR3GfHK688v7yqaX6eHYVH/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/CutePixelApe",
                "website": "https://www.cutepixelape.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3EX6ZMvGJQHmPxp21YZooDd4fWa7FQM5ANPbvR56FBeo",
            "symbol": "SWMP",
            "name": "Swamp Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/papacroc/swamp/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4qMrzbUFmuVnNSb1Q1LyaW2hDzQJ2iJ9W3BT173tAa1a",
            "symbol": "KK",
            "name": "Krabby Kooks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ELmo1589/Logo/main/Logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/krabbykooks",
                "website": "https://krabbykooks.com/",
                "youtube": "https://www.youtube.com/c/KrabbyKooks/featured"
            }
        },
        {
            "chainId": 101,
            "address": "dnqqARmAuYFAuGwJDoCtqiG1r596UevadbWFLN53RUJ",
            "symbol": "MYLO",
            "name": "My Low Carbon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AllyByers/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TVPjsECdZdgHqdBAbo2WBve5Qnon5WhWE7pGrkRNYkr",
            "symbol": "BWRGO",
            "name": "BWRGO Chips",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/swampcoin/solana-bwrgo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "2tWC4JAdL4AxEFJySziYJfsAnW2MHKRo98vbAPiRDSk8",
            "symbol": "USDC",
            "name": "USD Coin (Saber Devnet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "website": "https://saber.so/"
            }
        },
        {
            "chainId": 103,
            "address": "EJwZgeZrdC8TXTQbQBoL6bfuAnFUUy1PVCMB4DYPzVaS",
            "symbol": "USDT",
            "name": "USDT (Saber Devnet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether",
                "website": "https://saber.so/"
            }
        },
        {
            "chainId": 101,
            "address": "DgHF6XLh1qgiyCQMadT9nqXub2XjzmTbbrUYeYXctyDV",
            "symbol": "PPF",
            "name": "PepperFire",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pent65/crypto/main/logo.png",
            "tags": [
                "multi-purpose"
            ]
        },
        {
            "chainId": 101,
            "address": "5k4WxhSFFVxMoKnmwfwbVpHSgr72WjTcXepebpkBEP7m",
            "symbol": "JJCS",
            "name": "Jijie Croissant",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5k4WxhSFFVxMoKnmwfwbVpHSgr72WjTcXepebpkBEP7m/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "82eWuyUHrn1Fr7w35T7hEwVXq24fZjkUQCsMbc2uQ5m8",
            "symbol": "BIS",
            "name": "CryptoWeed",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/82eWuyUHrn1Fr7w35T7hEwVXq24fZjkUQCsMbc2uQ5m8/logo.png",
            "tags": [
                "community-token",
                "cannabis-fun-club"
            ],
            "extensions": {
                "website": "https://cryptoweed.fun/"
            }
        },
        {
            "chainId": 101,
            "address": "JAAXk29ewkr8HFC8oCgPRtwdEi9HjqFJpwNp5oRiG6H2",
            "symbol": "CIMG",
            "name": "Cool Images",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAAXk29ewkr8HFC8oCgPRtwdEi9HjqFJpwNp5oRiG6H2/logo.png"
        },
        {
            "chainId": 101,
            "address": "AoHTk47aAaxpXHCVBzixXg7i6KjgeFufXkiNEqNvnPXD",
            "symbol": "$BRUSH",
            "name": "BrushToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AoHTk47aAaxpXHCVBzixXg7i6KjgeFufXkiNEqNvnPXD/logo.png",
            "tags": [
                "nft-token",
                "community-token",
                "game-token",
                "utility-token"
            ],
            "extensions": {
                "description": "NFT token ecosystem built on the Solana Blockchain",
                "discord": "https://discord.gg/JJpt9rseMn",
                "twitter": "https://twitter.com/brushtoken",
                "website": "https://brushtoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3wJJ5L9tDvtNQL5HLD5jpBNDsSqcph6jA2FEqusTPkXc",
            "symbol": "VeVe",
            "name": "VeVeSnipers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3wJJ5L9tDvtNQL5HLD5jpBNDsSqcph6jA2FEqusTPkXc/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "36gQwnZKLb5u2gbSKM4EMnZFp7KpGU74ZKA2wPBcNYdB",
            "symbol": "ZEB",
            "name": "Makhfi",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/ghazanfarperdakh/TokenIcon/main/279434_383480151741653_1719567806_o.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU",
            "symbol": "USDC",
            "name": "USD Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "website": "https://www.centre.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EsatfHAn6VTAerHtCRVKieGkGxzmcnBcPMfULy3o6s8W",
            "symbol": "PLYR",
            "name": "Playrz Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Playrz/PlayrzLogo/main/PlayrzLogo_NoBackground-removebg-preview.png",
            "tags": [
                "social-token",
                "play-2-earn",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Playrz1",
                "website": "https://www.playrz.co.uk"
            }
        },
        {
            "chainId": 101,
            "address": "6yoR64HCHLsQXoNVYq75fKNUzh6zZFbTYrEpUcRRuRm8",
            "symbol": "DAY0",
            "name": "DayZero",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OFFSETSYNT4X/Krypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/9dvJQxpRv8",
                "facebook": "https://www.facebook.com/STR8KILAFOREALA",
                "twitter": "https://twitter.com/OffsetSynt4x",
                "youtube": "https://www.youtube.com/c/OFFSETSYNT4X"
            }
        },
        {
            "chainId": 101,
            "address": "HrGoF1XHG13uqkEm3TvxZtAS6uh5z848zic3VMKdBtus",
            "symbol": "REEF",
            "name": "ReefToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/evenmorecowbell/Crypto/main/ReefTokenLogo-bluenewsquare.png"
        },
        {
            "chainId": 101,
            "address": "HdYCTHTSRidRJK8pvnCd6un1kFYAMai85xryhpjmNrz6",
            "symbol": "SHIP",
            "name": "SHIP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HdYCTHTSRidRJK8pvnCd6un1kFYAMai85xryhpjmNrz6/logo.png",
            "tags": [
                "NFT",
                "utility-token",
                "game",
                "play2earn",
                "meta-verse"
            ],
            "extensions": {
                "discord": "https://discord.gg/cryptoshipsco",
                "serumV3Usdc": "3tnNsMhZH5DVnY8bA95H1mQdSJpaA8spHBhsixnDsH2g",
                "twitter": "https://twitter.com/cryptoships_co",
                "website": "https://www.cryptoships.co/"
            }
        },
        {
            "chainId": 101,
            "address": "RokhzuyP2UGZQvBsEhrvjjiW9DkTn2obJiyPxNHBRa8",
            "symbol": "DSRUPT",
            "name": "MoonRocks Mint Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/57wgjEUQwNn9rwGMzCRYwlbv3QBmI_wueJ-riGzYHZs",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Mint your base moonrocks at https://get.basemoonrock.store",
                "discord": "https://discord.gg/WF5XWfJQ",
                "twitter": "https://twitter.com/MoonrocksMeta",
                "website": "https://moonrocksofficial.io"
            }
        },
        {
            "chainId": 101,
            "address": "DiWunPY8GfsFthdDAwiRRtCgKCB5AEcFx9edDpxZoTyo",
            "symbol": "GMORNN",
            "name": "gmornn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiWunPY8GfsFthdDAwiRRtCgKCB5AEcFx9edDpxZoTyo/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/g_mornn",
                "website": "https://twitter.com/g_mornn"
            }
        },
        {
            "chainId": 101,
            "address": "DMCUFm2ZAnSU7UgsdVq23gRogMU3MEBjPgQF1gK53rEn",
            "symbol": "UM",
            "name": "UncleMine",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMCUFm2ZAnSU7UgsdVq23gRogMU3MEBjPgQF1gK53rEn/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "Defi",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/ScuJCZtDHs",
                "medium": "https://medium.com/@UncleMine",
                "telegram": "https://t.me/unclemineEN",
                "twitter": "https://twitter.com/uncleminenews",
                "website": "https://unclemine.org",
                "whitepaper": "https://wiki.unclemine.org"
            }
        },
        {
            "chainId": 101,
            "address": "Cxwxa8g5y5kr9XEDyNeTtfvnFpQ3rGoUqiWsG6ywrjWU",
            "symbol": "PYDY",
            "name": "Poopydoody",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SpyicyDev/crypto/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D35toePAVBFxyMYxJcny8jiBT4u3jnhCYZkEV218W8R3",
            "symbol": "SHEP",
            "name": "ShepherdCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/debug-jay/crypto/main/13-8-CM-font-b-German-b-font-font-b-Shepherd-b-font-Alsation-Dog-Car.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7gDoUkqmkjX8BEVrkYQs6cawFB4ronky8AfkCEyHbzB2",
            "symbol": "PLASMA",
            "name": "Metaballix",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gDoUkqmkjX8BEVrkYQs6cawFB4ronky8AfkCEyHbzB2/logo.png",
            "tags": [
                "gamification-token",
                "metaverse-token",
                "nft-token",
                "metaverse-currency",
                "metaballs"
            ],
            "extensions": {
                "discord": "https://discord.gg/AG2Y93emkf",
                "twitter": "https://twitter.com/metaballix",
                "website": "https://www.metaballix.com"
            }
        },
        {
            "chainId": 101,
            "address": "FUHQncZtt1HxsvNyrXvm1scsadHHCYiuhUg7xx2AW8wA",
            "symbol": "SHD",
            "name": "SolHead",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FUHQncZtt1HxsvNyrXvm1scsadHHCYiuhUg7xx2AW8wA/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GKNr1Gwf7AMvEMEyMzBoEALVBvCpKJue9Lzn9HfrYYhg",
            "symbol": "SIXY",
            "name": "611Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dc215007/611/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FSkPuxZEy1RcxqfXMp6rosuueE6hh45agD5xKW16am74",
            "symbol": "FIASCO",
            "name": "Fiasco",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/fiasco68/crypto/main/fiasco.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Fiasco is as Fiasco does",
                "website": "https://milkywayfiasco.com"
            }
        },
        {
            "chainId": 101,
            "address": "2Hei1NUr23AyyG4rszQQysRkuQyvr2Z2EjHWVf8ZqjAS",
            "symbol": "OLIVEBRANCH",
            "name": "PEACE COIN ",
            "decimals": 0,
            "logoURI": "https://arweave.net/2X3Xh19ThGDQbQ6F9OULg5uO35N1dNI3rvxDFtCFWTc",
            "tags": [
                "NFT",
                "fractionalized-nft",
                "collectible-token"
            ],
            "extensions": {
                "description": "PEACE"
            }
        },
        {
            "chainId": 101,
            "address": "4AgfahtdgCbxcTRmkHhbZGZa63MrY9Qy8abW9jowEhEP",
            "symbol": "ICRN",
            "name": "ICR0WN5",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/iEDD13/crypto/main/LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3QJCTmFAfCj3LJU8ERhqHrdvt3A4X1whsWoX6L3dCv7C",
            "symbol": "DLP",
            "name": "Delepe",
            "decimals": 8,
            "logoURI": "https://cdn.discordapp.com/attachments/933563659198820443/933564515503714304/logo_delepe.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9zME6wTFbbwNxE1okwjLNG9eSqDRdkoZB86HP18y5F1s",
            "symbol": "MULLI",
            "name": "Mulligan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dc215007/mulligan/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BRoNkvRkKvJqbRPhA31DpZLyPkf8AnKBVf5dGd9VmWEQ",
            "symbol": "BRONK",
            "name": "BronkDAO",
            "decimals": 0,
            "logoURI": "https://arweave.net/eoS0O6eMHbHHAtqxR4y7wv-XQcaA6IOYo4erKILZKnU",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Brink_Brinker",
                "website": "https://pornhub.com/pornstar/haley-reed"
            }
        },
        {
            "chainId": 101,
            "address": "7KHPgzBmot6jD8CKW3KLT9SmVFDPQUzJeXDY3yCku1j7",
            "symbol": "GHS",
            "name": "Gold Hands SolGnome",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7KHPgzBmot6jD8CKW3KLT9SmVFDPQUzJeXDY3yCku1j7/logo.png"
        },
        {
            "chainId": 101,
            "address": "DPkphDcgGjJ3YUH6a1Q6vJHg1J9oWbW7Nd5itZKNLi8U",
            "symbol": "DHS",
            "name": "Diamond Hands SolGnome",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DPkphDcgGjJ3YUH6a1Q6vJHg1J9oWbW7Nd5itZKNLi8U/logo.png"
        },
        {
            "chainId": 101,
            "address": "SPtq8aKb6wnEVaMcB3J8Caec4mS2mzhATArpwcQ3hhm",
            "symbol": "TOMATO",
            "name": "Tomato I Love U",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ilov-eu/assets/main/tomato-logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://tomato.ilov.eu"
            }
        },
        {
            "chainId": 101,
            "address": "ADPEoQKG1xbiwYro4ecUfLQgXkkTVqQousxSCDsRKh9H",
            "symbol": "BREA",
            "name": "Baliarena",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Baliarena/baliarena/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://baliarena.com"
            }
        },
        {
            "chainId": 101,
            "address": "9N96sgWT7ryELfKiReJLHRsDF1JZ2vD2YrBfyL2LdHuZ",
            "symbol": "Huz",
            "name": "Huz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9N96sgWT7ryELfKiReJLHRsDF1JZ2vD2YrBfyL2LdHuZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://baliarena.com"
            }
        },
        {
            "chainId": 101,
            "address": "ET3q5Vzr1mTKWmUJKdxkUNf29RmmcvjybvT1dtQdHU9S",
            "symbol": "TAL",
            "name": "Talent",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/tal-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ei6KkjuVTBi1WePpt6CFQBfAxqrK8tXyTXq3GDHx3Tr4",
            "symbol": "WRK",
            "name": "Work",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/wrk-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J3CtkN2VjGkp1Y4phd89o54FpCSB2jgF41dzZd7q92Pu",
            "symbol": "1HRS",
            "name": "Hronos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/bsn999/crypto/main/logo.png",
            "tags": [
                "social-token",
                "trading-token"
            ],
            "extensions": {
                "website": "https://www.hronos.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HXERJEhyoTPkcseiyjVMyAFooPACJG72dfabqDB5dZJD",
            "symbol": "SWN",
            "name": "SolWon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HXERJEhyoTPkcseiyjVMyAFooPACJG72dfabqDB5dZJD/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FUNHP94B46a486h5RSv46aeWUkeM5goTUuM5mVh4L6Qr",
            "symbol": "Dino",
            "name": "Dino DAO",
            "decimals": 3,
            "logoURI": "https://hv4gxzchk24cqfezebn3ujjz6oy2kbtztv5vghn6kpbkjc3vg4rq.arweave.net/tVA2tvv9z8z5Si0aggHFXBmoNWBFupfn5Jjv7Faq8cs",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/Uzp4nRWuZozb36PbjepYJGM5Q44Bqiw1nYrDfQC1Hd1",
                "vaultPubkey": "Uzp4nRWuZozb36PbjepYJGM5Q44Bqiw1nYrDfQC1Hd1"
            }
        },
        {
            "chainId": 101,
            "address": "6b5cSDUCu2YiLJaeEX8JMTN3KF9UHywD7QTKvPuoB1eD",
            "symbol": "SCRGNT",
            "name": "Scraigounet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Lordnewe/scraigounet/main/logo.png",
            "tags": [
                "scraigounet-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FAViRHfwREAcuM476rJUfNvdB3EQ7mG519Lfnd5Xwabb",
            "symbol": "FAV",
            "name": "Faviont Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/faviont/marketplace/main/logo.jpg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Faviont is an NFT based marketplace for you to buy, sell, explorer and curated a Fashion including Digital Fashion \u0026 Metaverse Fashion on Solana Blockchain.",
                "discord": "https://discord.gg/T6mVYKeVYc",
                "twitter": "https://twitter.com/FaviontOfficial",
                "website": "https://faviont.io/"
            }
        },
        {
            "chainId": 102,
            "address": "GtpmHimJ3oyVPyZg7iZ4edxEE9ejTEikUAQLhoRPw1fR",
            "symbol": "BIGT",
            "name": "BiG Coin Test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GtpmHimJ3oyVPyZg7iZ4edxEE9ejTEikUAQLhoRPw1fR/logo.png",
            "tags": [
                "Community-token"
            ],
            "extensions": {
                "description": "The BiG Deal - is a decentralized blockchain-based platform that churns out amazing deals (BiG Deals) to its community members via auctioning and other engagement models. Each engagement has elements of luck, skill, gamification and is amazing fun.",
                "github": "https://github.com/thebigdeals",
                "instagram": "https://www.instagram.com/thebigdeal_official",
                "linkedin": "https://www.linkedin.com/company/the-big-deals",
                "medium": "https://medium.com/@thebigdealofficial",
                "telegram": "https://t.me/+sWdEDZzdkSVlY2I1",
                "twitter": "https://twitter.com/thebigdeal_play",
                "website": "https://thebig.deals/"
            }
        },
        {
            "chainId": 101,
            "address": "9QCTc8SEomtoYPBAZ5uU5G42buLBZKr8ic54jPMSZ8U8",
            "symbol": "LNFTxPP5445",
            "name": "LNFT Pudgy Penguin-5445",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9QCTc8SEomtoYPBAZ5uU5G42buLBZKr8ic54jPMSZ8U8/logo.png",
            "tags": [
                "liqnft-owner-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/PP5445"
            }
        },
        {
            "chainId": 101,
            "address": "26fxx6VCssNFQ5iTDmvNzMJW7t9MA7Nuk8L726fnU4BX",
            "symbol": "Rein",
            "name": "Rein",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/reindoodles/images/main/token.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fDaVy3TMjY",
                "twitter": "https://twitter.com/reindoodles",
                "website": "https://www.reindoodles.art"
            }
        },
        {
            "chainId": 101,
            "address": "2CUMPKb9jNn9EphYjknqgrbf1WBmUxNQkQWtatSep1cP",
            "symbol": "NTSH",
            "name": "Nootshi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/net47/ntsh/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CC3wKk19sfwBHNeQaEU8SkHu9K2YK2opzXZX85g83Vev",
            "symbol": "TOKLM",
            "name": "TOKLM Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/laurentmartinpro/toklm-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2nxv4ZQK1dnbP1kLZpEaswXPq6e51Bs5qmgKv8dkKKeR",
            "symbol": "CONACoin",
            "name": "Cona Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cd400/conacoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6w3nTYuLeAZ1VW2A8cKCNwakEEbQDhggELgijrAbHMiz",
            "symbol": "HMC",
            "name": "HummusCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hummuscoin/HummusCoin/main/Hummuscoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9vN4ETmRGzsh4XcohTB9LDb4hTRPEiijoHM5P17isTRG",
            "symbol": "MONSTY",
            "name": "MonstyNFTs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoleKarjole/crypto/main/Screenshot_3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6iULHaFtqpNdUpdqspD6A3t25LfvbxCoyZ42LgjAQ3ME",
            "symbol": "GM",
            "name": "Grey Matter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/serhiykravets/grey_matter/main/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B48MZkfDVRTwtoMHSZJQp5R2dE1Y2oz6bPbtR8L9JUmn",
            "symbol": "CEST",
            "name": "Canopée Environmental Services Token",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/agrinix-token/logos/CEST.png",
            "extensions": {
                "website": "https://canopee.com.br/"
            }
        },
        {
            "chainId": 101,
            "address": "9wopbEg2DgZveUcdLAB4iVp7uhcV6cVyYwpRxPeZfBWC",
            "symbol": "YUK",
            "name": "YUKON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/madkins69/crypto/main/yuklogo.png"
        },
        {
            "chainId": 101,
            "address": "CertotHfUtq85jnHPFjUWUgkQDWi4442Zjr3ikmknvah",
            "symbol": "HXDTest",
            "name": "HXD Test Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CertotHfUtq85jnHPFjUWUgkQDWi4442Zjr3ikmknvah/logo.png"
        },
        {
            "chainId": 101,
            "address": "ThorLXgsF3KZ9zUgx5kRG83Ai1tyvmUiFqnTGJry1cy",
            "symbol": "ThLight",
            "name": "Thor's Lightning Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/915400341992923246/933715407141998642/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LightningsThors"
            }
        },
        {
            "chainId": 101,
            "address": "9MmJ5qE7gNBNfPgPPAqNTUGKQ8ABXCaeJkfZvmojd5i5",
            "symbol": "OBSD",
            "name": "Bit Rocket",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/kooroshjo/logo.p/main/logo.jpg",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DMvWyuGPCWzAHCiQ1cq6NcGGyAC1Rne1RPiCaz4fDQMy",
            "symbol": "AP",
            "name": "Ape Power",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMvWyuGPCWzAHCiQ1cq6NcGGyAC1Rne1RPiCaz4fDQMy/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "gaming-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/degenapesociety",
                "twitter": "https://twitter.com/DegenApeSociety",
                "website": "https://degenapesociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5H9Kmyewa2caQMpwsfQYMgt6UEzobSBwJsFUE8ioZXjp",
            "symbol": "JNGL",
            "name": "JungleSoulsToken",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Raph990/Jungle/main/Token.jpeg"
        },
        {
            "chainId": 101,
            "address": "5cKFNtooCQSkLhdFukk8R3PTdT4Rvm9cJr8Et49TxchR",
            "symbol": "TSI",
            "name": "Tesla Shiba Inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5cKFNtooCQSkLhdFukk8R3PTdT4Rvm9cJr8Et49TxchR/logo.png",
            "tags": [
                "utility-token",
                "meme"
            ],
            "extensions": {
                "discord": "https://discord.io/tesla_shibainu",
                "telegram": "https://t.me/tesla_shibainu",
                "twitter": "https://twitter.com/tesla_shibainu",
                "website": "https://teslashibainu.dog"
            }
        },
        {
            "chainId": 101,
            "address": "4XSCR35F1z39PmumAMG6C1ZeBrezanmCJehjZdLqaGwX",
            "symbol": "UXS",
            "name": "United XS",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/UjjwalGupta49/UXS/main/UXS.png",
            "tags": [
                "social-token",
                "trading-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4HDPJgU4TfJroZJq2MmXJey7LPFBvr4iw11MfQVeQehp",
            "symbol": "BRCN",
            "name": "Beer Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/beercoin2022/pic/main/lobo%20beerc.jpg"
        },
        {
            "chainId": 101,
            "address": "GTuDe5yneFKaWSsPqqKgu413KTk8WyDnUZcZUGxuNYsT",
            "symbol": "DASCH",
            "name": "Dasch Coin",
            "decimals": 0,
            "logoURI": "https://github.com/kennethoqs/dasch/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9XZR8Y5ZbRAvbCMMzeoSPzj8haAaUBaa9XoFBDHJAy2Z",
            "symbol": "SVBL",
            "name": "Sievable",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XZR8Y5ZbRAvbCMMzeoSPzj8haAaUBaa9XoFBDHJAy2Z/logo.png",
            "tags": [
                "utility-token",
                "svbl",
                "sievable"
            ],
            "extensions": {
                "description": "Sievable is a universal filter-based search engine powered by machine learning.",
                "discord": "https://discord.gg/x9rmRNvdnS",
                "twitter": "https://twitter.com/Sievable",
                "website": "https://www.sievable.com"
            }
        },
        {
            "chainId": 101,
            "address": "3EH9uuM4T1Zyh27g2QBwA2f5mJyKbjtGc8e8Gurrg7v7",
            "symbol": "MUFI",
            "name": "Music Finance",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hedde22/crypto2/main/large2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6",
            "symbol": "HBB",
            "name": "Hubble Protocol Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6/logo.svg",
            "tags": [
                "stake-pool-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "coingeckoId": "hubble",
                "coinmarketcap": "https://coinmarketcap.com/currencies/hubble-protocol/",
                "discord": "https://discord.gg/d44A8WvK",
                "twitter": "https://twitter.com/hubbleprotocol",
                "website": "https://hubbleprotocol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6uZmhU7YfKzKaV9kkv73oXgjiDKnnKriB1gQYPxjce3H",
            "symbol": "DEER",
            "name": "Bruces Deer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Floridanative60/crytologo/main/deer.PNG",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "7Xcoao3X8Jfn3PupeahgNAwYuuAFZ3PLyjxjWQ1a7HGY",
            "symbol": "RCT",
            "name": "RB Dev Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Xcoao3X8Jfn3PupeahgNAwYuuAFZ3PLyjxjWQ1a7HGY/logo.png",
            "tags": [
                "RCT"
            ],
            "extensions": {
                "website": "https://robocock.io"
            }
        },
        {
            "chainId": 101,
            "address": "HX7SXjc2D1FDY9pxk2AUaePWp54hkUnyyCLpHYe9CV78",
            "symbol": "BETX",
            "name": "BetDEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HX7SXjc2D1FDY9pxk2AUaePWp54hkUnyyCLpHYe9CV78/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/BetDEXLabs"
            }
        },
        {
            "chainId": 101,
            "address": "GU63hof6cScEZTMdmgZjKzVwAEsUjTQwE6bNCLVA5Yb6",
            "symbol": "SSHTLT",
            "name": "Solaniverse Shuttle Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GU63hof6cScEZTMdmgZjKzVwAEsUjTQwE6bNCLVA5Yb6/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/AWnP7ZkaYk",
                "twitter": "https://twitter.com/solaniverse",
                "website": "https://solaniverse.online"
            }
        },
        {
            "chainId": 101,
            "address": "8nG72hGMBSBMnrAxgNN2V2nt4HoZWEAF7VcdpdUchMVU",
            "symbol": "BRICE",
            "name": "Brice",
            "decimals": 7,
            "logoURI": "https://cdn.jsdelivr.net/gh/checkbrice/BriceToken/Brice%20logo.png",
            "extensions": {
                "twitter": "https://twitter.com/BriceToken"
            }
        },
        {
            "chainId": 101,
            "address": "BjTAx2xS8Z5CyoKGJNm1hWnZciuBSmo2o5WWFouVBAQC",
            "symbol": "URSU",
            "name": "UrsuCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/UrsuCoin/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "46QuZ6aBbevAFzQsA1zxwHnZaT8FDcZKorBT3muQ4oqh",
            "symbol": "SHUSHIS",
            "name": "ShuShi Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/46QuZ6aBbevAFzQsA1zxwHnZaT8FDcZKorBT3muQ4oqh/logo.png",
            "tags": [
                "Gr3yH00d13"
            ],
            "extensions": {
                "website": "https://houseoffoodporn.com"
            }
        },
        {
            "chainId": 101,
            "address": "Hz9qtPG8FmtasM9BzxLvQ784UCG4i5s2TemL2VuuPyr4",
            "symbol": "RHC",
            "name": "RhigeR Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jakobdk02/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BnMsMNnurwNigkPCt45RPRQPqoKJaZbMiiN3E2Md7KJi",
            "symbol": "RBC",
            "name": "Royal Blood Token",
            "decimals": 9,
            "logoURI": "https://github.com/Remedies84/crypto/blob/main/Royal%20Blood%20Token%20Logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ChtWQCsxVL2x8HLqjPkoypL3JTUeFkbyMwcvma1B2Jzv",
            "symbol": "XAI",
            "name": "XanthAkita Inu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xanthakita/XanthAkita-Inu-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "42oYDbvU7wogVhaGjL2FgEyCJpoVvooeMpPb9RtAXWkM",
            "symbol": "LDTLDMP",
            "name": "LDT Limited Drop Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/42oYDbvU7wogVhaGjL2FgEyCJpoVvooeMpPb9RtAXWkM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "55qjPkZhCnhE6zNwaoxvfHh8bTbpGadmXi6RpR8svLCg",
            "symbol": "CTUBE",
            "name": "Carbon Tube Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/carbontube/ctube/80eda6cdc5f4af2fdd5cdb43622850a9edbe0bd5/logo.png",
            "tags": [
                "carbon",
                "carbon-tube",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/carbontube/ctube",
                "reddit": "https://www.reddit.com/u/carbontube_io",
                "twitter": "https://twitter.com/Carbontube_io",
                "website": "https://carbontube.io"
            }
        },
        {
            "chainId": 101,
            "address": "DAuB5FLvmAveFdtsUoyHJcX3e3sJouy7gtcygh2XUwMM",
            "symbol": "ILACN",
            "name": "ILA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shortcutgamez-alt/crypto/main/logo.png",
            "tags": [
                "social-token",
                "nft",
                "collectible-token",
                "rare-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4vG8fmpqRHUuYDttLc4ADRN4jM7kTB12u4TB3Uokgs96",
            "symbol": "CUMM",
            "name": "Can You Make Money",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZeXEVO/crypto/main/Semen-Male-Comic-Happy-Sperm-Cartoon-Green-311801.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "4KZ2KMyar3NK8uXHE8G5vXskD5Zr4PgE5g9XztCJEpFT",
            "symbol": "MOM",
            "name": "TEST_TOKEN_METADATA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/humanpong/avatars/master/jhx.jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4vWjyzaG7M9AouRfKneCC412spLhP1vtx1SHE2hy9m8b",
            "symbol": "N4CC",
            "name": "NFT 4 Cause Council",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/qrtp/token-list/main/assets/mainnet/4vWjyzaG7M9AouRfKneCC412spLhP1vtx1SHE2hy9m8b/logo.png",
            "tags": [
                "social-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NFT4cause",
                "website": "https://www.nft4cause.app/"
            }
        },
        {
            "chainId": 101,
            "address": "7xZgeMkxKocj8wBBKdEFTaF25j4kUHVJbUZjvRqkdrsE",
            "symbol": "TIT",
            "name": "Tiki Tix",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xZgeMkxKocj8wBBKdEFTaF25j4kUHVJbUZjvRqkdrsE/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "84t1H7MDJokUY6X2M9oMTudHB6LABzUPMhhnPegxs8QC",
            "symbol": "HRFT",
            "name": "Highlands Ranch Falcon Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/84t1H7MDJokUY6X2M9oMTudHB6LABzUPMhhnPegxs8QC/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BXoCoeqSCC16X7L9dSykw5FQFD9peYbQqywyHuxMFomN",
            "symbol": "CCOIN",
            "name": "Course Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aGurysh/courseCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DsNADnfdnbCyuxE2mWxVu3GmAAiqVfaw9xgMMfFGRG5r",
            "symbol": "MAGIC",
            "name": "ShinLim",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dc215007/shinlim/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fbz6tgGFMgeDZdJeGdJ3iC19SjjY8hQPDh51ymXZWYzZ",
            "symbol": "GES",
            "name": "Generic Efficient Salary",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/noweffekt/ges/main/solana-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "An4iSjm5RtWMAeUGrbbkZ7EJNCaQutmkoSKjXNMKZSyE",
            "symbol": "ARTC",
            "name": "Artchive Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/An4iSjm5RtWMAeUGrbbkZ7EJNCaQutmkoSKjXNMKZSyE/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "governance-token"
            ],
            "extensions": {
                "description": "The Artchive premier token to be used with the Artchive NFT.",
                "discord": "https://discord.com/invite/S6NZ4FeqtY",
                "facebook": "https://www.facebook.com/ARTchive.nft",
                "instagram": "https://www.instagram.com/artchive.nft",
                "twitter": "https://twitter.com/ARTchive_nft",
                "website": "https://artchivecoins.com"
            }
        },
        {
            "chainId": 101,
            "address": "2k6nV8pvUVUb2GEBu6jewp3V4j16SnbEwAUBADJBGFXp",
            "symbol": "LUMB",
            "name": "LUMB COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MrKnots/New-Logo/main/LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9YZ7cegX2nXdEC162mWa5xec4UTgwX8P61XYvsDMvt74",
            "symbol": "KP",
            "name": "KP Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kpgroup1979/cryptobetong/main/kpgroup.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "97oBkXkZQRJd9kxptQiYoDQCmGtVMjb3NBg5xN66kWKS",
            "symbol": "PMWL",
            "name": "Pixelmon WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97oBkXkZQRJd9kxptQiYoDQCmGtVMjb3NBg5xN66kWKS/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/pixelmonnft",
                "twitter": "https://twitter.com/PixelmonTM",
                "website": "https://www.pixelmonnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AGksrUShiRfRAeGNwdMSXr19RRifeZcNzA9567EhUYB8",
            "symbol": "COG",
            "name": "COG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NoxianHobo/cog/main/cog-icon-final.png",
            "tags": [
                "social-token",
                "NFT-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "GKid2eL5bswPDvV4udcYpmuhBpE76a8AezniQV1HZ8CS",
            "symbol": "RAWR",
            "name": "Rawr Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GKid2eL5bswPDvV4udcYpmuhBpE76a8AezniQV1HZ8CS/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "A crowdfunding token for potential startups.",
                "website": "https://cybertekk.com"
            }
        },
        {
            "chainId": 101,
            "address": "47iWsWXdiR6QR2mcNsBy3BskoqmKVaweYSt1LrrzGDbz",
            "symbol": "ORI",
            "name": "ORION",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Qualitytoken/OrionToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EPcAkxWPTJd8sShhoWemtro3jWCgBdYFT7VyRX6bscn3",
            "symbol": "BRAVE",
            "name": "SolFaces Arena Brave Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPcAkxWPTJd8sShhoWemtro3jWCgBdYFT7VyRX6bscn3/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2zYvwePFJwrUWxQd6QUxpxJcE8ZFazYYWCjBsdcQBwwb",
            "symbol": "JfK",
            "name": "JOHN F KENNEDY",
            "decimals": 0,
            "logoURI": "https://arweave.net/HbeCTMPGnk7Vtl1oZ7Sf2nM6bpkv6-XMtRMrePuPDJc",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection"
            ],
            "extensions": {
                "description": "Change is the law of life",
                "twitter": "https://twitter.com/nftgalleryshop",
                "website": "https://www.nftsolanagallery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6CYxiyUo2pgMHkCWpWf4TU47f3Q1rMUWP1R4WtFmVLLA",
            "symbol": "PINS",
            "name": "Pin Club Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6CYxiyUo2pgMHkCWpWf4TU47f3Q1rMUWP1R4WtFmVLLA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/pinclub",
                "twitter": "https://twitter.com/PinClubFamily",
                "website": "https://pinclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "86erHPHmYKXxkQSKVuRUazUrG62tZg1FdYr5oJPaS4tQ",
            "symbol": "BLSHMNT",
            "name": "FBCMINT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NFTBrickLayer/assets/main/FBC-logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/xbVtrqjZcc",
                "twitter": "https://twitter.com/Fearless_Bulls"
            }
        },
        {
            "chainId": 101,
            "address": "14k9uii4GKXVcD3XrAYiYF15ZKuzziVydKgttDxoyGPM",
            "symbol": "CREATE",
            "name": "Create Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/14k9uii4GKXVcD3XrAYiYF15ZKuzziVydKgttDxoyGPM/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fellowshipdao",
                "twitter": "https://twitter.com/FellowshipDAO",
                "website": "https://fellowshipdao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "85nfo4Na3q8nXSzHM4bLgRsn2CmbeLMsLYGKmQs46Wzm",
            "symbol": "FSWL",
            "name": "Fellowship Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/85nfo4Na3q8nXSzHM4bLgRsn2CmbeLMsLYGKmQs46Wzm/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fellowshipdao",
                "twitter": "https://twitter.com/FellowshipDAO",
                "website": "https://fellowshipdao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HQAttcHCUJNUUTFfJzp7ng14LBz29A4GCUMedkvg77Uf",
            "symbol": "MGC",
            "name": "Mi Gente Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/botapierna/crypto/main/MGC_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5fTYZKkawRZswGZnzKeKzcnhYd2WNC8N5JdTf2pTYbax",
            "symbol": "BTTL",
            "name": "Battle Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Battleram/BattleTokenBTTL/main/BattleTokenPNG.png",
            "tags": [
                "DAO-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "BxpgexfjfrQrM59ZRGB4ZLVs7sEuHi6ry6QWZ273sD3P",
            "symbol": "SKC",
            "name": "SweetKoffi Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Zec0de/SweetKoffi_crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J4gd9c6trVYkrUESngq362FrReWtpyyzbUYWAvqmtjBv",
            "symbol": "CSH",
            "name": "Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/csh-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GbXAsJjLgtqnwi8RMhZnmCcwY95puKWyMr8WWn95ywKG",
            "symbol": "NOT",
            "name": "Nothing",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/not-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HV4YBnsPCvQK76jQvMp4rkKf8oFEyQoeGVNYFX9Mqf8r",
            "symbol": "GRAT",
            "name": "Gratitude",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/grat-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7CKmxmZcgzKjfDZPvKo7WxWRKQv3NfiK7utzyZUrtMHR",
            "symbol": "DMCN",
            "name": "DUNCION",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/duncmcclunc/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ErTvb8p9Vfh7Pp87DSY6LNE3KGmgu4ZiHW3Qv6qpDNbe",
            "symbol": "IPAS",
            "name": "Internet Protocol as NFT token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/IPasNFT/assets/main/ipasnft-logo-2735x2735.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Internet Protocol as NFT. We are bringing the ability of IP ownership as NFT.",
                "discord": "https://discord.gg/WsaXKQQNVR",
                "telegram": "https://t.me/ipasnft",
                "twitter": "https://twitter.com/ipasnft",
                "website": "https://www.ipasnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "9UFreZXDqxJR95nMJfe3KTSPPuXtTd9WTZa1UVM7p4Bn",
            "symbol": "SURAH",
            "name": "THE HOLY QURAN",
            "decimals": 0,
            "logoURI": "https://arweave.net/phkjn6ROYiXFoj21ORPARPjGd-81vR9CAMkbms5I0-Y",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection"
            ],
            "extensions": {
                "description": "This is the Book about which there is no doubt, a guidance for those conscious of Allah",
                "twitter": "https://twitter.com/nftgalleryshop",
                "website": "https://www.nftsolanagallery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "1USD6bWynacpTnSy2xHpSNPEDh59TYGx2VztNVzy9pZ",
            "symbol": "1USD",
            "name": "OneUSD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1USD6bWynacpTnSy2xHpSNPEDh59TYGx2VztNVzy9pZ/logo.svg",
            "tags": [
                "stablecoin",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/oneringfinance",
                "github": "https://github.com/OneRingUSD",
                "medium": "https://medium.com/oneringfinance",
                "telegram": "https://t.me/OneRing_Finance",
                "twitter": "https://twitter.com/onering_finance",
                "website": "https://www.onering.finance"
            }
        },
        {
            "chainId": 101,
            "address": "A5t8hZNLcB9eja4bi2LhfYvbw4Upa9rpdCXsKskNxBXA",
            "symbol": "LINCOLN",
            "name": "ABRAHAM LINCOLN",
            "decimals": 0,
            "logoURI": "https://arweave.net/g0tw2-WNXctJXRowkpJEgsuGMFfJdA7uym3kMIFdZjA",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection"
            ],
            "extensions": {
                "description": "Those who deny freedom to others, deserve it not for themselves",
                "twitter": "https://twitter.com/nftgalleryshop",
                "website": "https://www.nftsolanagallery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8iey5bqBcqejJWcS7Two1ZffeJcxx28rfBMeSAqCF9nf",
            "symbol": "LIT",
            "name": "LIT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LIT-token/uploads/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "web3"
            ],
            "extensions": {
                "discord": "https://discord.gg/slacc",
                "twitter": "https://twitter.com/SlaccSol",
                "website": "https://slacc.io"
            }
        },
        {
            "chainId": 101,
            "address": "HivQLtgvg8rgovmBv6UC54dmDV3QSFjEbZxDQRAEMdBS",
            "symbol": "LVXY",
            "name": "LIVAXY COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/leafyus/crypto/main/LivaxyLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "41beW9K9CRDVjUN7yCZKRWkiDPxjkAkhZ7Q6ny4vZ7Ny",
            "symbol": "DSOL",
            "name": "Defi Solution",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/41beW9K9CRDVjUN7yCZKRWkiDPxjkAkhZ7Q6ny4vZ7Ny/logo.png",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/defi_solution"
            }
        },
        {
            "chainId": 101,
            "address": "A5t8hZNLcB9eja4bi2LhfYvbw4Upa9rpdCXsKskNxBXZ",
            "symbol": "LION1",
            "name": "LION1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5t8hZNLcB9eja4bi2LhfYvbw4Upa9rpdCXsKskNxBXZ/logo.svg"
        },
        {
            "chainId": 101,
            "address": "A5t8hZNLcB9eja4bi2LhfYvbw4Upa9rpdCXsKskNxBXX",
            "symbol": "LION2",
            "name": "LION2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5t8hZNLcB9eja4bi2LhfYvbw4Upa9rpdCXsKskNxBXX/logo.svg"
        },
        {
            "chainId": 101,
            "address": "27w7fcX3Ak13Bb6aSJrvxp8SFfearoVxqbtowypTd9it",
            "symbol": "RETIBI",
            "name": "RetibiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aymenretibi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6eKoh5DvnGYoeQoLnQ6H7TXo1A4VWrKUQoKsQrcxDcz9",
            "symbol": "scepter",
            "name": "Majesty",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/PeepGainz/crypto/main/Majesty.png",
            "tags": [
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "y1YpyeeYVseiTMsRL2jJc7NYd2FcWn5PKfiWRF1S4g3",
            "symbol": "BBW",
            "name": "Brunch Bytes Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/y1YpyeeYVseiTMsRL2jJc7NYd2FcWn5PKfiWRF1S4g3/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/j7rPyNb6T6",
                "twitter": "https://twitter.com/brunchbytesNFT",
                "website": "https://www.brunchbytes.com"
            }
        },
        {
            "chainId": 101,
            "address": "6yX3oaYCcAor2ooFk8vFuwef6tAtwqeBiiQmV1zCHVaw",
            "symbol": "SWANY",
            "name": "SWANY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/davidaswan/swany/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "bPKvK1c8pT5u3Pz7ou9pBA3YMCmpZgweuixqPzFhZDb",
            "symbol": "YLM",
            "name": "Yarden",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bPKvK1c8pT5u3Pz7ou9pBA3YMCmpZgweuixqPzFhZDb/logo.png",
            "tags": [
                "nft",
                "game"
            ]
        },
        {
            "chainId": 101,
            "address": "63STzHqymgmUcZzFiGPVU11XiAf5tn3N8UuVtyYBSDjQ",
            "symbol": "gulu",
            "name": "gulu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/63STzHqymgmUcZzFiGPVU11XiAf5tn3N8UuVtyYBSDjQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "AyPkunNZ7g8acsXUZ3ruB17EKSf8Q2JJPsWu4AWBT3yB",
            "symbol": "G",
            "name": "Gulp",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AyPkunNZ7g8acsXUZ3ruB17EKSf8Q2JJPsWu4AWBT3yB/logo.png"
        },
        {
            "chainId": 101,
            "address": "BC1QPt3SMAni1mNDbeXLGmUL1ie6n2L6GX943UACwu3R",
            "symbol": "WL",
            "name": "WLGulp",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BC1QPt3SMAni1mNDbeXLGmUL1ie6n2L6GX943UACwu3R/logo.png"
        },
        {
            "chainId": 101,
            "address": "A4K1NiurCDitDMLBiuDV6oVMftoHJzUMeX5pQcFW7k9S",
            "symbol": "BND",
            "name": "Bender",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/a4373694e907dc8749bdd7a07731257db8f5a39b/assets/mainnet/Bender.JPG",
            "tags": [
                "meme-token",
                "game-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9B7Wpo7LXhgDZ4QT4e1bpjBXC9g6h52rH9UwLb3UAyeR",
            "symbol": "$",
            "name": "GULP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/Gulp/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "32YDp4MSNso5J1usYcuUGLPRXrHstzVnFnoyiFBfHdJQ",
            "symbol": "GEST",
            "name": "Gesticoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dussam/gesticom/main/gesticoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6x7SAmGfMANGHG4CsLPcYZ9UX2QhAS6f8WGMovB3P7RE",
            "symbol": "RBIT",
            "name": "RichBit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6x7SAmGfMANGHG4CsLPcYZ9UX2QhAS6f8WGMovB3P7RE/logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J5zncv7PeN3Km2BTC6hcRrZevGQX7avM9EErZtFrdTrh",
            "symbol": "SOLLINX",
            "name": "Sollinx",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Jones9238/sollinx/main/sollinxLogo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AtEMWORLD"
            }
        },
        {
            "chainId": 101,
            "address": "3WiHeUJksPE2nhHVVwPfE9F4Eepv2QtmgNPoTc4PL1X8",
            "symbol": "PSFI",
            "name": "Pug Shot Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3WiHeUJksPE2nhHVVwPfE9F4Eepv2QtmgNPoTc4PL1X8/logo.png",
            "tags": [
                "meme",
                "utility"
            ],
            "extensions": {
                "discord": "https://discord.gg/mF7mexhaE2",
                "twitter": "https://twitter.com/pugshotfinance",
                "website": "https://www.pugshotfinance.com/"
            }
        },
        {
            "chainId": 102,
            "address": "DotdtxnoYiTELUjGnjXorv5Xy2kngLRiaydYrBzUxHNL",
            "symbol": "TBIG",
            "name": "BiG Coin (Testnet)",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DotdtxnoYiTELUjGnjXorv5Xy2kngLRiaydYrBzUxHNL/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "description": "The BiG Deal - is a decentralized blockchain-based platform that churns out amazing deals (BiG Deals) to its community members via auctioning and other engagement models. Each engagement has elements of luck, skill, gamification and is amazing fun.",
                "github": "https://github.com/thebigdeals",
                "instagram": "https://www.instagram.com/thebigdeal_official",
                "linkedin": "https://www.linkedin.com/company/the-big-deals",
                "medium": "https://medium.com/@thebigdealofficial",
                "telegram": "https://t.me/+sWdEDZzdkSVlY2I1",
                "twitter": "https://twitter.com/thebigdeal_play",
                "website": "https://thebig.deals/"
            }
        },
        {
            "chainId": 101,
            "address": "4ZoMoYLFjEmZjTUG4ZvKya5AEYXRGZETDFKR28Zd3wEw",
            "symbol": "BLDTPE",
            "name": "BoldType",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/franckmandon/BoldType/main/LOGO-BOLDTYPE.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/9gdreAgW",
                "twitter": "https://twitter.com/BoldTypeArt",
                "website": "https://www.boldtype.art/"
            }
        },
        {
            "chainId": 101,
            "address": "FoxHUbcgZGU4ozFG8iH9tpivGPaHkxusvxSSuWTDNPEZ",
            "symbol": "SOLALAND",
            "name": "SOLALAND",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoxHUbcgZGU4ozFG8iH9tpivGPaHkxusvxSSuWTDNPEZ/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/GybWAj3bSF",
                "serumV3Usdc": "5eFBFqATo93NZd1FGVFv59ewDPEqeGoEgFipB2kGmJVP",
                "twitter": "https://twitter.com/solalandhq",
                "website": "https://www.solalandhq.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E2GaLU27RU5MLeQcrGmvkQeZhSZhA62YiaXk31j3d7wH",
            "symbol": "BSTC",
            "name": "BeastCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/toastedflames/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6RfidFC3diqqF3fYGFTtTV9CsxxA2gsVUFN3RJ5gCyT1",
            "symbol": "PUPS",
            "name": "PupsPleasure",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thypups/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EbTHasvCpSU1GYTFE5hNEzNNUbiui9CV9DWHWKP9CvgQ",
            "symbol": "FSH",
            "name": "Fish Token",
            "decimals": 0,
            "logoURI": "https://media.discordapp.net/attachments/926535226820276264/934150203496337428/fish_token.png?width=675\u0026height=675",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MoanaWhalesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "2S3hhwCnDkFN2zHYc7SBzhLuHUevMwmgUCzq3ShDsUb5",
            "symbol": "CWB",
            "name": "Chiwa Bones",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2S3hhwCnDkFN2zHYc7SBzhLuHUevMwmgUCzq3ShDsUb5/logo.png"
        },
        {
            "chainId": 101,
            "address": "SLTSRS7irCC1LgeCdnKZDaaUXRDHB4TQRm1dd5GNLPS",
            "symbol": "SLTS",
            "name": "Soltus Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLTSRS7irCC1LgeCdnKZDaaUXRDHB4TQRm1dd5GNLPS/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/eUPwQuvEX9",
                "twitter": "https://twitter.com/SoltusNFT",
                "website": "https://soltus.io/"
            }
        },
        {
            "chainId": 101,
            "address": "JDdU9XAry5FNKFKxrrFbFiK6yAHKoWqbYSTJdUQfrjua",
            "symbol": "PSY",
            "name": "PSILOCYBE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/stardouce/annunaki/main/thumbnail_IMG_4221%20(1).png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nacho_dacal"
            }
        },
        {
            "chainId": 101,
            "address": "DEFjrmzpXuaPg5cqNi4PzPCdrdqjngc8cF6KqesLAUrP",
            "symbol": "CHNG",
            "name": "ChangeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jimerson001/crypto/main/changelogo.png",
            "tags": [
                "social-token",
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "BYbfgn491deiSTApQnXmV4DpkiDHUXJYYoc11U6fB1By",
            "symbol": "DOOB",
            "name": "Doobie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xxmafiaxxx/doobietokens/main/doobiecoin.png",
            "extensions": {
                "description": " The doobie transaction coin",
                "website": "https://doobie.me/"
            }
        },
        {
            "chainId": 101,
            "address": "823dnFBD7dUCbBr9tvD6oT3JHo286dx93opVLgqKT21u",
            "symbol": "SMCK",
            "name": "Smacked Exprience Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xxmafiaxxx/doobietokens/main/smackedlogo.png",
            "extensions": {
                "description": "The Smacked Experience coin",
                "website": "https://www.smackeddobbie.com"
            }
        },
        {
            "chainId": 101,
            "address": "GUM5TSSLThjHD9brZLQtFwHP2ci9GBLmak1maUcGbLPm",
            "symbol": "TRT",
            "name": "Tortillas",
            "decimals": 0,
            "logoURI": "https://github.dev/martindebo/token-list/blob/fb32057248d78926ebb9afe5234987f34a88b15d/assets/mainnet/GUM5TSSLThjHD9brZLQtFwHP2ci9GBLmak1maUcGbLPm/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ef8U4a9MJUPPmaUeHMvcnZoLsVw3QwTeEyZgWKvn7Mnj",
            "symbol": "CM4K",
            "name": "ChasingMidnight Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Chasing-Midnight-Dev/CMToken/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/ChasingMidnight_/"
            }
        },
        {
            "chainId": 101,
            "address": "rPMfHAnourZnyjo99raz9n9SfezmwCQCuiyweMse5sM",
            "symbol": "ILYL",
            "name": "Lily Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/clnKnight/lilycoin/main/lilycoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8XYyLiTfVkDWbAdRHkkzqg5TtswyGgKQjzPg9wrQRupA",
            "symbol": "WHDT",
            "name": "Weedheads Discount Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8XYyLiTfVkDWbAdRHkkzqg5TtswyGgKQjzPg9wrQRupA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Weedheads Discount Token",
                "twitter": "https://twitter.com/weedheadsNFT",
                "website": "https://weedheads.art"
            }
        },
        {
            "chainId": 101,
            "address": "472qrvfcCdNhWKnAMU8fkujnRfs6M1vqV92aetS1Fzg5",
            "symbol": "MTVK",
            "name": "Metaversita Kids",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/metaversita/cryptokids/main/metaversitakidslogo.png",
            "tags": [
                "social-token",
                "educational-token",
                "kids-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6qdpLDezQgJJGbNqNPwN8WMDgyUWtrw7vkTKnxpHe18U",
            "symbol": "KMT",
            "name": "Kemet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kemetcoin/kemet/main/kemet.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BsfjJdm3oBmJsLx59M9hoCD9PUncbJuKRymMEYwqgS86",
            "symbol": "EER",
            "name": "ElEuro",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rlealg7/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F6mDXoirNX8gwcM5uM88BboaHWbVdkkfxt5b9nS7Wiot",
            "symbol": "DARKNESS",
            "name": "BLACKHOLE",
            "decimals": 0,
            "logoURI": "https://arweave.net/ig2baWinYJXYT2sD6oi5QDL-mxd11xB3giJwInT7mIg",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection"
            ],
            "extensions": {
                "description": "Black holes are where God divided by zero",
                "twitter": "https://twitter.com/nftgalleryshop",
                "website": "https://www.nftsolanagallery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FANGZa14uU2oLHczgyDqE9sYQtbD3VLzdWntQwLujWs6",
            "symbol": "FANGZ",
            "name": "Fangz WL Token - The First Clan",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FANGZa14uU2oLHczgyDqE9sYQtbD3VLzdWntQwLujWs6/logo.png"
        },
        {
            "chainId": 101,
            "address": "DH8H67JVbmiYnE6HiCFXe1JMckdLrGtopUYTNKdqyKcY",
            "symbol": "ORB",
            "name": "Ouroboros",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/kshabahang/crypto/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "2Tnc6ngSfJS4QNXzDU5daR2G7JH5uox2C6Z6B3y1SZ6i",
            "symbol": "AHMT",
            "name": "Astral House Mint Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Tnc6ngSfJS4QNXzDU5daR2G7JH5uox2C6Z6B3y1SZ6i/logo.png",
            "tags": [
                "whitelist-ticket"
            ],
            "extensions": {
                "description": "Welcome Our Amazing House!",
                "website": "https://www.astralapes.com"
            }
        },
        {
            "chainId": 101,
            "address": "5cPc4dx8D61JrbHqVZ8Ywsf2L6FeBMRmgstg1mWc65Ti",
            "symbol": "RAINBOW",
            "name": "LGBTQ COMMUNITY",
            "decimals": 0,
            "logoURI": "https://arweave.net/zK7Epeb7WOnqzE6ZZtCxTO8x4rMZq9u0Bn3tcueAtRM",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection"
            ],
            "extensions": {
                "description": "Hope will never be silent",
                "twitter": "https://twitter.com/nftgalleryshop",
                "website": "https://www.nftsolanagallery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "F9BKWDRMopfWxf5rbU1FA1LPADahy9U3Z9ByvM4MX3p6",
            "symbol": "FTRLS",
            "name": "Future Travelers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maddurup/futuretravelers/main/futuretravelers_logo.png",
            "tags": [
                "social-token",
                "collectible-token",
                "digital-collection",
                "educational-token",
                "kids-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ErKdodyZV6heNvjmthdycjDoEdXris7Ej2xgMCA5UxPP",
            "symbol": "DIANA",
            "name": "PRINCESS DIANA",
            "decimals": 0,
            "logoURI": "https://arweave.net/9NoKsOxpDXt4zbMQ5lncbz07JcCONN7lMUhz-A8IVD8",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection"
            ],
            "extensions": {
                "description": "I would like to be a queen in people hearts, but I dont see myself being queen of this country",
                "twitter": "https://twitter.com/nftgalleryshop",
                "website": "https://www.nftsolanagallery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FGtAoCT3Lpy7eecLSQhQ9oFnb9rRJtL5bjpmbbDaQwg7",
            "symbol": "VCC",
            "name": "Vic Chaos Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/waxjock/Vic-Chaos-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8tM8eC6JVKvck2qB35ungkJfRrM4nyessv3ZK7USQRWh",
            "symbol": "DQ",
            "name": "DQ Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/daanishaqureshi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5TPoLh9drzQsgvPqVZ3hhjG2LV4mBCy7uC2JY7fcZwDR",
            "symbol": "FTO",
            "name": "Fake Taxi Official",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FakeTaxiCoinOfficial/FakeTaxiCoinOfficialLogo/main/fake-taxi-crypto-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AG1wkgfXJNkS2xWsbDePVsVDsdiZofcm2JpYiMfvPnHg",
            "symbol": "TUSK",
            "name": "TUSK (WATW)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AG1wkgfXJNkS2xWsbDePVsVDsdiZofcm2JpYiMfvPnHg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Utility token for use by the We Are The Walrus community",
                "discord": "https://discord.gg/wearethewalrus",
                "instagram": "https://instagram.com/watwalrus",
                "twitter": "https://twitter.com/WATwalrus",
                "website": "https://wearethewalrus.io",
                "youtube": "https://www.youtube.com/channel/UCT3g3uBSCnqnT3KfJ85221w"
            }
        },
        {
            "chainId": 101,
            "address": "8qX7w2eQWG3iLFzAe7gWRrjf7LGiodq8e4NoY7ALk14y",
            "symbol": "KNRS",
            "name": "Kinrys",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rainfallthejoker/Logo/main/Kinrys.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3dU3xJjjPCJHvJwmqhmK4nqETfPpEGKRaWSquffAvZ5C",
            "symbol": "88A",
            "name": "Arcade '88 Token",
            "decimals": 0,
            "logoURI": "https://www.arcade88nft.com/permanent/token-a88.png",
            "tags": [
                "social-token",
                "NFT",
                "utility-token",
                "meta-verse",
                "game"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/fMaSFjtfcT",
                "twitter": "https://twitter.com/arcade88nft",
                "website": "https://www.arcade88nft.com"
            }
        },
        {
            "chainId": 101,
            "address": "aPwnnqozXpdnQ3M5spxcoWRBxF7WWTfADwuLYaeUyUh",
            "symbol": "SWK",
            "name": "Sarawak",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kevjacob/sarawak/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5P1WVkzP41CCnFSxRdskRXCbVWfkr5c2pNm8CpixBCem",
            "symbol": "LIXI",
            "name": "Lixi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/phannyjohn/crypto/main/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G2WBYifnQMX4dB3eUpFLpSZV8UzBztUHsLzdo3ow2swf",
            "symbol": "BSC",
            "name": "BoseCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CNADHUSHAN/COIN/main/Bose.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FuJSEGsyheXAhjptNpLrcdSDNSb94b6wQ5qWLMBYnWqD",
            "symbol": "BGP",
            "name": "Bedri Coin Patron",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sonfero/crypto/main/BGP_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A1wBAV4hJbiaY9LgEZ4g98WNFah66wAtH7h5b3PXCxku",
            "symbol": "LAB",
            "name": "Labrador coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/The-Tech-Dog1/crypto-img/main/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HFWz47uq1mrzMGuCmMmZDrCqrQpmQcAkTVhBxZDFY7RS",
            "symbol": "KND",
            "name": "Kendi Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/ilzamafif/logo/KDN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DAhyNwD9bsMkcgXHzCvVCVimkpeRwc6bv9i47UuuQAo3",
            "symbol": "AUT",
            "name": "Airdrop Utility Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAhyNwD9bsMkcgXHzCvVCVimkpeRwc6bv9i47UuuQAo3/logo.png"
        },
        {
            "chainId": 101,
            "address": "2BJ3bfEcxtSqgrKVYE25emYqA51m5tmQfXcZsGxUAyNL",
            "symbol": "AAO",
            "name": "Against All Odds",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/andbandu/spl-tokens-logo/main/logo.png",
            "tags": [
                "technology"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/aao_blocktech/",
                "website": "https://aaoblocktech.com"
            }
        },
        {
            "chainId": 101,
            "address": "3Qv5SAkB3pNMNicFf3RQqPr6dWpCh3hywZxkTRNbuBZa",
            "symbol": "BT50M",
            "name": "BTM UTILITY",
            "decimals": 6,
            "logoURI": "https://cdn.jsdelivr.net/gh/guru1animo/BT5/BTM_REG_TRANS.png",
            "tags": [
                "UTILITY-TOKEN"
            ]
        },
        {
            "chainId": 101,
            "address": "BT5gcf7stQ1M7veC7cFUpZHiLa5577GhGkkvCsoY4jiC",
            "symbol": "BT5",
            "name": "BT5 WHITELIST",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/guru1animo/BT5/BT5_TRANSPARENT_LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8wPQwg1v5BK5KNmmV2pjKBLarftfR24iQrNeZb7vQAbo",
            "symbol": "KRABS",
            "name": "KrabsKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SleepyKeebler/KKLogo/main/KKLogoFinal.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EKe7rakfHZGhjPc7xtJ5RbjdPrydjxKrsoWZoEGatUvD",
            "symbol": "SFWL",
            "name": "Sol Force Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKe7rakfHZGhjPc7xtJ5RbjdPrydjxKrsoWZoEGatUvD/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FxDawrtC2VStAKcw8SrgTQjtyE7T2yYH4SsVLhDJm9Sa",
            "symbol": "UET",
            "name": "Uetos Token",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/7600092/150626036-007c284d-0921-4c79-93fd-3e156f29cc98.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ai9ToH4zWxHv9YUgAXd7stxMQkC97Qg2Vj73V38W4N29",
            "symbol": "mTOR",
            "name": "mTOR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Stephanhero/Hero/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RAWRgsFuMD8U38ATP6uDg2A3c1HSXAyrnK13mrXqv6T",
            "symbol": "$RAWR",
            "name": "RAWR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RAWRgsFuMD8U38ATP6uDg2A3c1HSXAyrnK13mrXqv6T/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/TigerLegacyNFT",
                "website": "https://www.tigerlegacynft.com"
            }
        },
        {
            "chainId": 101,
            "address": "F33Pi2HYRFi2HvyvDezbZj7NmskBU9FWmTj4ZgAp5qE",
            "symbol": "KASH",
            "name": "KKoppa Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kopparam/solana-token-assets/main/kkoppa.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5pGWuTbSbapu9qxpFTcNLQx4ydj9tJsw6okN6mErWw6q",
            "symbol": "DT",
            "name": "Dev Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/The-Bifrost/BADC-Assets/main/devToken.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Z2vY77ejH6",
                "twitter": "https://twitter.com/BoredDevClub"
            }
        },
        {
            "chainId": 101,
            "address": "6fDeCCoZLWRZporJtRBZ8toMs7BBn2wWQapdaKsTigpF",
            "symbol": "HHWL",
            "name": "Hockey Heroes Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6fDeCCoZLWRZporJtRBZ8toMs7BBn2wWQapdaKsTigpF/logo.png",
            "tags": [
                "social-token",
                "nft",
                "whitelist-token",
                "utility-token",
                "HockeyHeroes",
                "HHWL"
            ],
            "extensions": {
                "discord": "https://discord.gg/tfcYjW59gH",
                "instagram": "https://www.instagram.com/hockeyheroes.io/",
                "twitter": "https://twitter.com/hockeyheroes_io",
                "website": "https://hockeyheroes.io"
            }
        },
        {
            "chainId": 101,
            "address": "7tmJntLEkQ8NUCMyXz8Yti77pqX7aB3NHLLL7i6q121G",
            "symbol": "KAI",
            "name": "Kai Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/UDUT/Logo/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GkJdRCiKg9oJK4ZJjsQAUQiTtFT7BzRtMCPms4neShVP",
            "symbol": "DOODLEBUNNYWL",
            "name": "Doodle Bunny Whitelist",
            "decimals": 0,
            "logoURI": "https://nova-host.nyc3.cdn.digitaloceanspaces.com/doodlebunny/doodlebunny-icon.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://doodlebunny.io/"
            }
        },
        {
            "chainId": 101,
            "address": "invYVY53mcmBtf2RbVudoqKDyAgZGofkLYodvnQwQep",
            "symbol": "INV",
            "name": "Invoke",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/invYVY53mcmBtf2RbVudoqKDyAgZGofkLYodvnQwQep/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/invokers",
                "twitter": "https://twitter.com/invokersnft",
                "website": "https://www.invokersnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "DpiEAFAYhNG5sVg2x54yPrjcPnWcFwG6oaU41ryabzSV",
            "symbol": "BUS",
            "name": "Orobus Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/venture64/orobus/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EX1Zh43eHStRRaN6CMxAvK5yRNodrsXFzzsgW417vaBM",
            "symbol": "MRFAN",
            "name": "FanCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shortcutgamezalt/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9J2MudjVzuYF6EAnQBswTpTxo6qqhVJ56PqDLo6LXrLq",
            "symbol": "Amp",
            "name": "AmpK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stefasav/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bhh5WwdL9HPCgEZxLYWNKnwjtP8kCUMa8bEhz9KFTxSP",
            "symbol": "AWC-D",
            "name": "Awake Coin - Dev",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bhh5WwdL9HPCgEZxLYWNKnwjtP8kCUMa8bEhz9KFTxSP/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EyxaYzqCBB5zxRFH4fvW1JtXupeaz6YKcWaJq2AVeeVT",
            "symbol": "Merov",
            "name": "Merov Fan Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EyxaYzqCBB5zxRFH4fvW1JtXupeaz6YKcWaJq2AVeeVT/Merov.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GLfMsvQiKxvAhf8h5vM3EUurjdvvgfXSYnEWe6UydoiR",
            "symbol": "MADOG",
            "name": "Mad Dog",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/upsetk9/Crypto-MadDog/main/logo.png",
            "tags": [
                "Utility-Token",
                "Crypto-Token",
                "Game-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "GBMJGHgYK3XmhSJMGkeqvN97sUTpc7dpEHJENMn7SAjV",
            "symbol": "3D",
            "name": "3DIMENSION",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GBMJGHgYK3XmhSJMGkeqvN97sUTpc7dpEHJENMn7SAjV/logo.png",
            "tags": [
                "Imprimante3D-token",
                "3D-token",
                "meta-verse",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2XmVJ6XfJzwdY69QzbBq7Do9tkzumcTFdg6BPyBd9uc2",
            "symbol": "TITOR",
            "name": "TITOR KEY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2XmVJ6XfJzwdY69QzbBq7Do9tkzumcTFdg6BPyBd9uc2/insignia.png",
            "tags": [
                "social-token",
                "NFT",
                "08-11-2022"
            ]
        },
        {
            "chainId": 103,
            "address": "moN9cUBW8K2zCQi6xsBjx5QqNG3rprjSUtdHgTHRTRK",
            "symbol": "ZIZ",
            "name": "ZIZZY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/moN9cUBW8K2zCQi6xsBjx5QqNG3rprjSUtdHgTHRTRK/logo.png",
            "tags": [
                "utility-token",
                "Startup-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Knjw5dG7UszrbdftqoXwiMvSLkT999eNWNKzwdRR96i",
            "symbol": "DNGN",
            "name": "DungeonCoin",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/MrThomp308/crypto/main/logo.png",
            "tags": [
                "social-token",
                "game"
            ],
            "extensions": {
                "description": "What are ya buyin'?"
            }
        },
        {
            "chainId": 101,
            "address": "GhJTPNR8yiwA84imWaQytWK7mDf884JBKSkxehTnY7RN",
            "symbol": "SDW",
            "name": "Shadows",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/celesticalrain/shadow/logo.jpeg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/abitofashadow",
                "twitter": "https://twitter.com/abitofashadow",
                "website": "https://www.abitofashadow.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E4yBLdJX8bJLejwn8raT1msMnFP2UKyuj3EWx9X92gAy",
            "symbol": "SPICY",
            "name": "Spicy Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/manihck/spicy/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ciAKvQShrEdMf6d4GctueSTnakiF8V4QjrYdRrZQngY",
            "symbol": "IMP",
            "name": "Iguana Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ciAKvQShrEdMf6d4GctueSTnakiF8V4QjrYdRrZQngY/IguanaPass.png",
            "tags": [
                "utility"
            ]
        },
        {
            "chainId": 101,
            "address": "7DrFiGCsimt4c8fMy1yzvkBuwaicG6MemXM9Nfcj2xsC",
            "symbol": "BBRY",
            "name": "Blueberry Social",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlueberrySocial/blueberry/main/blueberry.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/blueberrycoin"
            }
        },
        {
            "chainId": 101,
            "address": "FQhv1hNXqKymA1C1irdgHgLHhUBpJRLz8a22ZKLBQqaX",
            "symbol": "SKUG",
            "name": "SuK Social Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ckoys/crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HXiCWdAouMTC1rngKWTPWUpRftPut9fZtbWcGyazKmqq",
            "symbol": "WWGT",
            "name": "We Want Green Token",
            "decimals": 9,
            "logoURI": "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-6/271996191_137942348645759_6859222909034109708_n.jpg?_nc_cat=111\u0026ccb=1-5\u0026_nc_sid=09cbfe\u0026_nc_ohc=k2HG4ZGkHsMAX96YtDP\u0026_nc_ht=scontent-iad3-1.xx\u0026oh=00_AT_KCZncS8TJAgade6-_NtFkZzJeXWe4VtIX_KaRY9Po3Q\u0026oe=61F21F1E"
        },
        {
            "chainId": 101,
            "address": "F2CcdH4uXVL6vwutkFMtHWFaj87dYSh9WMWNqzsMmTUG",
            "symbol": "ENC",
            "name": "crypt",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/encryptedsolana/crypt/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2UHrXB1HPt3Law7PghjTR7nLwZwHxSfYwcxXEi6ZkEid",
            "symbol": "SNOW",
            "name": "SNOW",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2UHrXB1HPt3Law7PghjTR7nLwZwHxSfYwcxXEi6ZkEid/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "86mtV2nWTrheDGKjPnyomczehBLsLZrGjSMkTu4VWGqi",
            "symbol": "SWPT",
            "name": "softWEB Production Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Moerbius/SWPT/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4bgZ8g4cb4suy6NvCskZRrLRoTESzriVBZwuQBJirGVn",
            "symbol": "FWT",
            "name": "FACE WALLET TOKEN",
            "decimals": 8,
            "logoURI": "https://solana-bucket.fra1.digitaloceanspaces.com/4bgZ8g4cb4suy6NvCskZRrLRoTESzriVBZwuQBJirGVn.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://www.facewallet.com"
            }
        },
        {
            "chainId": 101,
            "address": "HniRa4Ho4GqCGpfK74fdBWJX5EQqDy5cwChJPds4E9pD",
            "symbol": "KAT",
            "name": "Kron Token",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/36153766/150657557-f93d2539-ab4f-4fb5-924a-ae5bb8979b7c.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "3uqsNQk5ZmvakBviu5VaWB5SogtaJK8WwnpZ7B1XXafi",
            "symbol": "KTD",
            "name": "Kanye the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/Ye.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BmpBH8Lks2eX8Mqmn9azpCjVHKFGDLg8PYUKMJReNVtA",
            "symbol": "WSPY",
            "name": "Wrapped SPY ETF",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/gitcompile/assets/main/logo.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "website": "https://www.gitcompile.com"
            }
        },
        {
            "chainId": 101,
            "address": "E8eKZucB5DH8QaRAo36STQG2MnLSDkXroYeNYB6TaJAi",
            "symbol": "MERF",
            "name": "Merth Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jdmerth/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "53ArqdYtoNPGLiuNKPc3GxjAtnjun2VJXrcRT3azr4mX",
            "symbol": "TTD",
            "name": "Travis the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/travis.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GPneEd9G1xunrPPicdRGfh8qmx7HPbVjqB7FfKHTC5CC",
            "symbol": "BILL",
            "name": "One Billion Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Cyborg360/Bill/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AB48g9PuY9CxLhrFVpTKG7hTR49rWAdbqxL6mQ7pQUTs",
            "symbol": "HERRERA",
            "name": "Herrera Family Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ElPiUpilas/crypto/main/HERRERA.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7h27ASEDcKfhSFqGCsS3VEhw33Ea7k9dBTSDWqpg72a7",
            "symbol": "TaCO",
            "name": "Tacoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/byzantinezer0/crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9orxGYrDdQzuNQdUGfHTVS2xWyGC6snFDf13eezaZCbv",
            "symbol": "SHELL",
            "name": "Shill City Shell",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9orxGYrDdQzuNQdUGfHTVS2xWyGC6snFDf13eezaZCbv/logo.png"
        },
        {
            "chainId": 103,
            "address": "3LN9JTYGoxXZMuKvEke1ygcWGLvFksjfQJ35zBAZoKFA",
            "symbol": "SHMRD",
            "name": "Shmurda Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/marcus-kielman/SHMRD-Logo/main/Shmurda_Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31Uicx93Wes8tXKH5hzaUXYAGUY9pFbHyAiatoNEaoaj",
            "symbol": "JAXX",
            "name": "Ruby Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/josephantoine85/rubytoken/main/Jaxyn%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DooD5TvzkhRJoKdQ6AMTUC2DmXYW27jouyALWfN7g5x3",
            "symbol": "D00D00",
            "name": "DOODOO BANK",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/guru1animo/doodoo/doodoo_trans.png",
            "tags": [
                "UTILITY-TOKEN"
            ]
        },
        {
            "chainId": 101,
            "address": "CrwZumj357tPeBZeQFDtVeoJHFWQ75G17SXkwUQWwqY7",
            "symbol": "BUCH",
            "name": "Kombucha Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ca1ebd/kombucha/master/buch-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A4MgR6ANAh79AE1csJjXiRV2vYDqP8wgSUUp28HpDEyo",
            "symbol": "GIS",
            "name": "GeoBit",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/benjaminschleider/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToeF3rovM5R7wMomNc8CZ2Pw78VyioUtrTrJs88MFh9",
            "symbol": "SM",
            "name": "SolMaps Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToeF3rovM5R7wMomNc8CZ2Pw78VyioUtrTrJs88MFh9/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/gaBQAD2kHW",
                "twitter": "https://twitter.com/SolMapsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "58GVUcJtzaWHdnnJpyfP4YjfXhJpQuNtVpWDeU1H3ND9",
            "symbol": "FLLW",
            "name": "Fellowship Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/A-Ebel/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GdCb8jMB6mhsoiQsSoSbt7YDQzxjx4whdPvzM131jFCC",
            "symbol": "IBC",
            "name": "IsaacBurgerCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdCb8jMB6mhsoiQsSoSbt7YDQzxjx4whdPvzM131jFCC/logo.png"
        },
        {
            "chainId": 101,
            "address": "HTdyVb5LsFCJjua8wypkp6eZb9KiG1oT1bnTn8WweouR",
            "symbol": "BAM",
            "name": "BAM Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HTdyVb5LsFCJjua8wypkp6eZb9KiG1oT1bnTn8WweouR/logo.png",
            "tags": [
                "game-coin",
                "game-fi-token",
                "community-token",
                "game-fi",
                "games-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6KwhtEzhZRLvpXFT1fYJ2f7gtUHLXYDFkKfp5Lza6nEq",
            "symbol": "nFTS",
            "name": "Not For That Stuff",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/uneektyreek/crypto/main/NFT_LOGO_1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hg3WNUcCqDaTUBGrJKihSoG8C6rckV4LHEGXQFCeEdwz",
            "symbol": "SYLV",
            "name": "Sylvestor Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JimmyDangerGit/crypto/main/Sylvestor.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToYSMpnt1VQ49sKFzyyL9zYfR9YR1VV1HyesynCii8i",
            "symbol": "TOYS",
            "name": "Mekatoys",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Mekatoys/Logo/main/%24TOYS.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Mekatoys",
                "twitter": "https://twitter.com/mekatoysnft",
                "website": "https://www.mekatoys.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AEAXBnLErByhViskwaZWjzd8jVFj3S2ypNdURPHdYohx",
            "symbol": "SOUL",
            "name": "Soulless Citizen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AidanLau/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AGJT11SGhzckdJMVvELJWgYTNuczS4hAK3m1R1aCEj8A",
            "symbol": "BIZZ",
            "name": "Bizznaz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Bizz.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "bizznaz"
            ]
        },
        {
            "chainId": 101,
            "address": "GJQ2X6f7DZDVDp8LvduoPjb3VTp5sbosDwjzkiFRDuJR",
            "symbol": "HEMP",
            "name": "Weed",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Weed.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "marijuana",
                "weed",
                "420",
                "Cannabis"
            ]
        },
        {
            "chainId": 101,
            "address": "44YFuQRhwT2qTeZtG8YtLD4LVgZbwhmT4uWtetgA5jPx",
            "symbol": "TGCN",
            "name": "The Good Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aberezovsky/TheGoodCoin/main/TGC_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Z6tPs57qvFgu9Nppbx3TbzKZyiyazt6j4N8tDqmvuu2",
            "symbol": "SMR",
            "name": "Sol Monke Rejects Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Z6tPs57qvFgu9Nppbx3TbzKZyiyazt6j4N8tDqmvuu2/whitelistmonke.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/MonkeRejects",
                "twitter": "https://twitter.com/MonkeRejects",
                "website": "https://solmonkerejects.com"
            }
        },
        {
            "chainId": 101,
            "address": "3QvErzht4mtqGRzU3iDUYNdAKq8w2A2yGZWk6t9HS1DE",
            "symbol": "STMK",
            "name": "Star Monke Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/milorue/token-assets/master/starmonke.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/MonkeRejects",
                "twitter": "https://twitter.com/MonkeRejects",
                "website": "https://solmonkerejects.com"
            }
        },
        {
            "chainId": 101,
            "address": "BwaXCdG2hsW2PTieBc14Ys92yKVGWSQSATY9juwkBEZo",
            "symbol": "NGNC",
            "name": "Nairacoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spaco67/nairacoin/main/Screenshot_2022-01-15%20Logo%20Maker%20Used%20By%202%203%20Million%20Startups(4).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EBJNRwAJGeiinx15QW3wYXkqDcr1tPnH8Cx7CgsQHUV8",
            "symbol": "CLNX",
            "name": "Linux Centos)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SquareandCompass/images/main/EBJNRwAJGeiinx15QW3wYXkqDcr1tPnH8Cx7CgsQHUV8.png",
            "tags": [
                "wrapped",
                "ethereum",
                "stablecoin",
                "development",
                "social-token",
                "utility-token",
                "wrapped-sollet",
                "community-token",
                "Non-fungible-token"
            ],
            "extensions": {
                "description": "Tokens that are designed to be spent within a certain blockchain ecosystem e.g. most of the SPL-Tokens, bridged from etherium,non-fungable tokens,asset wrapped tokens and for linux os community tokens.",
                "telegram": "https://t.me/linuxtokens",
                "twitter": "https://twitter.com/linuxtokens"
            }
        },
        {
            "chainId": 101,
            "address": "Bm5BpgMitt7AHiqBRrgxP2jzkz5g6WfgjS6CmpJ9hzC6",
            "symbol": "REM",
            "name": "Remy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cavacas/rembrandt-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F94cGudbrJN3cfZH5EZ59zb1W29dtjYNuG8UkyNy98ur",
            "symbol": "SNWL",
            "name": "The Sneaky Devils White List Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/531224495676063764/934592775024959548/WLTOKENIMAGE.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3qLZnBYKVv5vgsrTfofvgQN5Skn8cFoTfcBFsbx6WPbH",
            "symbol": "GIZMO",
            "name": "GizmoCoin",
            "decimals": 0,
            "logoURI": "https://github.com/jrhampton/crypto/blob/main/gizmo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GxGKMQmEL3PyU6cXgDYcoTYkRYx8aEwutU4GHhRMtADS",
            "symbol": "WCGC",
            "name": "Darryl Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Darryl-Shi/cryptologo/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9dWPVXMGyNAssJXEVXs8CwRTCrb1bTxw2Dd6Y3fbkoCQ",
            "symbol": "DMON",
            "name": "Demon Time Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/abrarmusa/files/master/dmon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/AD3wmAsT9g",
                "twitter": "https://twitter.com/DemonTimeNFT",
                "website": "https://demontime.co"
            }
        },
        {
            "chainId": 101,
            "address": "DsMXpQQo71K7iVmvDANz18NSWf1i2PCHqtmaksxLa228",
            "symbol": "SVC",
            "name": "Vikecoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Henrymarks1/vikecoin/main/Group_1.png",
            "tags": [
                "utility-token",
                "SAMOHI"
            ]
        },
        {
            "chainId": 101,
            "address": "DexkjiHEdyA5wLJeKVNztnms1BFKKiEKFJQo2YmcUoEd",
            "symbol": "CWSEED",
            "name": "Cactus Seed Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DexkjiHEdyA5wLJeKVNztnms1BFKKiEKFJQo2YmcUoEd/logo.png",
            "tags": [
                "CactusWorldNFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CactusWorldNFT"
            }
        },
        {
            "chainId": 101,
            "address": "GRg8MeUDZe9j7ZoAZRduMhpySfX3eb3crPdJ2s25hxn4",
            "symbol": "LEOLO",
            "name": "Leolo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/proker84/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLWbeAUsTfvTvQf2iAVmagGxbzNMMS6pzXNiYGdnrj7",
            "symbol": "PAWS",
            "name": "Grampaw",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLWbeAUsTfvTvQf2iAVmagGxbzNMMS6pzXNiYGdnrj7/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/FtyxbfmSMv",
                "twitter": "https://twitter.com/GrampawNFT",
                "website": "https://grampaw.art"
            }
        },
        {
            "chainId": 101,
            "address": "F4kwDkWDrybHpYUabQMntrM7Ejbo2aYNZqrWsHFvNrbv",
            "symbol": "MPWL",
            "name": "MP Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F4kwDkWDrybHpYUabQMntrM7Ejbo2aYNZqrWsHFvNrbv/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "CQqo3KXd3HPLxvkTojCN6a4yhUKnHyVeY36iFKgv7Hug",
            "symbol": "One",
            "name": "OneCoin Joke",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/onecoin2022/Onecoin/main/one.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BrdJfqFi5UWKmKnwKp83Je7tygA3YEntLZZjeNY7qKgL",
            "symbol": "TCB",
            "name": "TecBeat Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SamuelMichaAssmann/TecBeat-Coin/master/tecbeat_blue.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GsBJWMp5UDJqBiaAvgQRoC5RyJhm125jZVo3oFV6zoa5",
            "symbol": "DTAC",
            "name": "Dark Terminal Access Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GsBJWMp5UDJqBiaAvgQRoC5RyJhm125jZVo3oFV6zoa5/dtac-logo.png",
            "tags": [
                "social-token",
                "NFT",
                "dark-terminal",
                "DTAC",
                "hacking",
                "game",
                "P2E",
                "play-to-earn"
            ],
            "extensions": {
                "discord": "https://discord.gg/8BTMHYXdVj",
                "twitter": "https://twitter.com/DarkTerminalSol",
                "website": "https://darkterminal.io"
            }
        },
        {
            "chainId": 101,
            "address": "8SsTzpQHNijvMPb5jv1ho9kTR2tqTrxHffXMc2jc9qhu",
            "symbol": "DIGOU",
            "name": "Digoucoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8SsTzpQHNijvMPb5jv1ho9kTR2tqTrxHffXMc2jc9qhu/logo.png",
            "tags": [
                "social-token",
                "digouraye-token",
                "leroux"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Ezeit_"
            }
        },
        {
            "chainId": 101,
            "address": "8BryHGGaNCCR7MWa9JoX7mRU3ZyChHMabNFVNw5Dy52x",
            "symbol": "PLNX",
            "name": "Linux Parot)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SquareandCompass/images/main/8BryHGGaNCCR7MWa9JoX7mRU3ZyChHMabNFVNw5Dy52x.png",
            "tags": [
                "wrapped",
                "ethereum",
                "stablecoin",
                "development",
                "social-token",
                "utility-token",
                "wrapped-sollet",
                "community-token",
                "Non-fungible-token"
            ],
            "extensions": {
                "description": "Tokens that are designed to be spent within a certain blockchain ecosystem e.g. most of the SPL-Tokens, bridged from etherium,non-fungable tokens,asset wrapped tokens and for linux os community tokens.",
                "telegram": "https://t.me/linuxtokens",
                "twitter": "https://twitter.com/linuxtokens"
            }
        },
        {
            "chainId": 101,
            "address": "4GiJwhhdfR2h84bHnjikVM1e2qbpwVUiatFrc6YaZ2Qt",
            "symbol": "FRENz",
            "name": "Non-Fungi Frens Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/NFTzTrader/FRENz/main/FRENzlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GGDi8o8suLZ5GxGesejhjKuA91hcGsh1rtaH38JigGr7",
            "symbol": "JTNM",
            "name": "Jotonama",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JonTron23/Jotonama/main/icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DAPhv2RQyVHTaPXXUnjJNyDyEiikiJ7wumhYwT4tCrdX",
            "symbol": "Altay",
            "name": "Altay Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AltayIbrhm/Crypto/main/logo%20(2).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "33j6cnQUmef2tSbSvxn2U4uSBdKYSbRvzmJcPAvDEHoW",
            "symbol": "LOCO",
            "name": "ANYBODIES Fashion Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33j6cnQUmef2tSbSvxn2U4uSBdKYSbRvzmJcPAvDEHoW/loco.png",
            "tags": [
                "utility-token",
                "fashion",
                "wte",
                "wear-to-earn"
            ],
            "extensions": {
                "discord": "https://discord.gg/anybodies",
                "twitter": "https://twitter.com/anybodiesNFT",
                "website": "https://anybodies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "75A5D1VncynKoNDHmj8t2dHKM1RY66n3ouRU4uQzeVdZ",
            "symbol": "BBT",
            "name": "Baobab Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bananut/Baobab-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ykRbDPDjbgPSULbqpnj66H5uC2jKBVcCcqNrYsHCSHa",
            "symbol": "SPAETI",
            "name": "SPAETI Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ArgoXNFT/spaeti_coin/main/SPAETI_COIN.png",
            "tags": [
                "social-token",
                "utility-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "GCBQDCUdAJLytVpdXFpG8snME7aPG8wFiBnruDuCvr52",
            "symbol": "ALN",
            "name": "Allan Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GCBQDCUdAJLytVpdXFpG8snME7aPG8wFiBnruDuCvr52/logo.png",
            "tags": [
                "Testtoken"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/allanv.26/"
            }
        },
        {
            "chainId": 101,
            "address": "GHn4S6s2PQAWs41MoQ4Zi3AZgdyUgV5JJvw3MWCKZCKR",
            "symbol": "TJ",
            "name": "TOM AND JERRY",
            "decimals": 0,
            "logoURI": "https://arweave.net/vUE9CepRJXgxSrP9BLsR48oDldqfg277ia7L4y1ELPM",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection"
            ],
            "extensions": {
                "description": "I am your Tom and you are my Jerry...Let's get ready to fight"
            }
        },
        {
            "chainId": 101,
            "address": "6pHBFoc1nM9Jm6qQC8oFHZdq1wQD2vsjSJJkAzSTgKAt",
            "symbol": "$VCTN",
            "name": "Viccy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ViccyCoin/viccycoin-logo/main/viccycoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EfwqTnpLrrRfLyTy6tNt95HjbDwJTxgqHx6x1vEBrMKK",
            "symbol": "SEX",
            "name": "Solexx",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cripplemetimbrs/SPL-Tolken/main/Snapshot.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BoEFB6sNwUZCFbKNt279rAJrfzsy16YhX2X4wUKuecZe",
            "symbol": "PEK",
            "name": "PEK",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoEFB6sNwUZCFbKNt279rAJrfzsy16YhX2X4wUKuecZe/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/edFdkcG",
                "twitter": "https://twitter.com/Likuello"
            }
        },
        {
            "chainId": 101,
            "address": "3HJDjYmKVnYSeX1ziFRC5jHD8TdEZeTQtuGeJE3Td58e",
            "symbol": "OSAI",
            "name": "OneScreenAI Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/onescreenai/osai-token/main/onescreen_logo_box.png",
            "extensions": {
                "website": "https://www.onescreen.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "2xbWx7eSoxvtKq1fjikPx9kbLi3VX8DkUec7iwj3LEyW",
            "symbol": "IS",
            "name": "Inustart",
            "decimals": 9,
            "logoURI": "https://inustart.com/logo.png",
            "tags": [
                "IDO",
                "ICO",
                "Protocol",
                "Presale",
                "Platform"
            ],
            "extensions": {
                "telegram": "https://t.me/shibainusolana",
                "twitter": "https://twitter.com/inu_solana",
                "website": "https://inustart.com"
            }
        },
        {
            "chainId": 101,
            "address": "8mxTuo1SrCvGqyvCMyzuRmtReXsxbsRu446nsjDtCA4h",
            "symbol": "Bldr",
            "name": "Buildr",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/paxanon/buildr-logo/main/buildr/logo.png",
            "tags": [
                "Buildr-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4g2CUtwyvLZhxha38CpgGFiHMnSeQdKsLvQBLUtUf54S",
            "symbol": "GUNT",
            "name": "Gunterverse",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/Gunterverse/-Gunt-Token@main/tokenlogo.png",
            "tags": [
                "utility-token",
                "Metaverse"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/pYsvQNWyxR",
                "telegram": "https://t.me/gunterverse",
                "twitter": "https://twitter.com/gunterverse",
                "website": "https://gunterverse.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3x4DKbtWYkw9SigBp7LbeT96MLw68wpWVC4ZUtCp1Gvz",
            "symbol": "COF",
            "name": "Codefin Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3x4DKbtWYkw9SigBp7LbeT96MLw68wpWVC4ZUtCp1Gvz/logo.png",
            "tags": [
                "utility-token",
                "incentive-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9pPoKimqEa5PSnnNU9GK5kC3ExQV4LxbbHER949yCU7F",
            "symbol": "KYFX",
            "name": "KyroGliFX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/KyroGliFX/Crypto/main/Logo.png",
            "tags": [
                "community-token",
                "Gaming-token",
                "Mu-Kingdom",
                "nfts",
                "Mu-Eternal",
                "PlaytoEarn",
                "Facebook"
            ]
        },
        {
            "chainId": 101,
            "address": "2e6SpNYYkYuqwYSnriuPfjyLC2shZ1LGn63F1xgE4Ros",
            "symbol": "LSC",
            "name": "Lucas Currency",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/96Lucas96/Lucas-Currency/main/Lucas_Currency.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8X8iBFCBD7wfo8YeGckoQyGN7pN6qtwivxKFqdfJsRie",
            "symbol": "MTVT",
            "name": "Metaversita",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/metaversita/metaversitatoken/main/metaversitacoinlogo.png",
            "tags": [
                "social-token",
                "educational-token",
                "exchange-token",
                "utility-token",
                "metaverse",
                "NFT",
                "Play2earn"
            ]
        },
        {
            "chainId": 101,
            "address": "4VqPtrkc3v78VFTyKm6V99HeTg5ixBqMhzMwP2rDVZgL",
            "symbol": "AXOL",
            "name": "AXOLOTL-CLUB",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/david-nunes/axololt_logo@main/buff.png",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection"
            ],
            "extensions": {
                "description": "For our loyal White-listers. Axolotl Club -- 3,333 Axolotls building a world on Solana."
            }
        },
        {
            "chainId": 101,
            "address": "53bKoYpdk83bVyR2tu9U7FguBAwoP7EQGfzBQwnmkRtG",
            "symbol": "FX",
            "name": "FaconX Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cooltech90/crypto-faconx/main/faconx-coin.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "instagram": "https://instagram.com/faconx_",
                "linkedin": "https://linkedin.com/company/faconx",
                "twitter": "https://twitter.com/faconxofficial",
                "website": "https://faconx.com"
            }
        },
        {
            "chainId": 101,
            "address": "6CQuweCcPRWAaQNvnkvwWjrbxRe6MuVh5eFFVk8DVyM7",
            "symbol": "RUMT",
            "name": "Rum Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SeaRovers/rum_icon/main/Rum_Token.png",
            "extensions": {
                "twitter": "https://twitter.com/SeaRoversNFT",
                "website": "https://searoversnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2knGXmo5piJGC4oPfnsaPnKis4KgepmeiC8osFxQfTse",
            "symbol": "RHLNX",
            "name": "Linux Red Hat)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SquareandCompass/images/main/2knGXmo5piJGC4oPfnsaPnKis4KgepmeiC8osFxQfTse.png",
            "tags": [
                "wrapped",
                "ethereum",
                "stablecoin",
                "development",
                "social-token",
                "utility-token",
                "wrapped-sollet",
                "community-token",
                "Non-fungible-token"
            ],
            "extensions": {
                "description": "Tokens that are designed to be spent within a certain blockchain ecosystem e.g. most of the SPL-Tokens, bridged from etherium,non-fungable tokens,asset wrapped tokens and for linux os community tokens.",
                "telegram": "https://t.me/linuxtokens",
                "twitter": "https://twitter.com/linuxtokens"
            }
        },
        {
            "chainId": 101,
            "address": "37NPZJBQVSTXcyorSYoR537jQGmQ4svSpfBCJdMjUrV9",
            "symbol": "DLNX",
            "name": "Linux Debian)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SquareandCompass/images/main/37NPZJBQVSTXcyorSYoR537jQGmQ4svSpfBCJdMjUrV9.png",
            "tags": [
                "wrapped",
                "ethereum",
                "stablecoin",
                "development",
                "social-token",
                "utility-token",
                "wrapped-sollet",
                "community-token",
                "Non-fungible-token"
            ],
            "extensions": {
                "description": "Tokens that are designed to be spent within a certain blockchain ecosystem e.g. most of the SPL-Tokens, bridged from etherium,non-fungable tokens,asset wrapped tokens and for linux os community tokens.",
                "telegram": "https://t.me/linuxtokens",
                "twitter": "https://twitter.com/linuxtokens"
            }
        },
        {
            "chainId": 101,
            "address": "A2ngwBq2jFLziAGXS5aQgbBGrZvG5weGGJWeBaaVhfxu",
            "symbol": "FLNX",
            "name": "Linux FX)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SquareandCompass/images/main/A2ngwBq2jFLziAGXS5aQgbBGrZvG5weGGJWeBaaVhfxu.png",
            "tags": [
                "wrapped",
                "ethereum",
                "stablecoin",
                "development",
                "social-token",
                "utility-token",
                "wrapped-sollet",
                "community-token",
                "Non-fungible-token"
            ],
            "extensions": {
                "description": "Tokens that are designed to be spent within a certain blockchain ecosystem e.g. most of the SPL-Tokens, bridged from etherium,non-fungable tokens,asset wrapped tokens and for linux os community tokens.",
                "telegram": "https://t.me/linuxtokens",
                "twitter": "https://twitter.com/linuxtokens"
            }
        },
        {
            "chainId": 101,
            "address": "AQ542ndgurN8Dnef8JVQ6G3SAjQJjxAshS2n2fKXz6Tx",
            "symbol": "OLNX",
            "name": "Linux Oracle)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SquareandCompass/images/main/AQ542ndgurN8Dnef8JVQ6G3SAjQJjxAshS2n2fKXz6Tx.png",
            "tags": [
                "wrapped",
                "ethereum",
                "stablecoin",
                "development",
                "social-token",
                "utility-token",
                "wrapped-sollet",
                "community-token",
                "Non-fungible-token"
            ],
            "extensions": {
                "description": "Tokens that are designed to be spent within a certain blockchain ecosystem e.g. most of the SPL-Tokens, bridged from etherium,non-fungable tokens,asset wrapped tokens and for linux os community tokens.",
                "telegram": "https://t.me/linuxtokens",
                "twitter": "https://twitter.com/linuxtokens"
            }
        },
        {
            "chainId": 101,
            "address": "Bo4ZraLUGdtpf65cuLoJaMQVoUBThmaqtEnnHXHsajh7",
            "symbol": "DADPT",
            "name": "DadPoint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DadMasterFlex/DadPoint/main/Logopit_1642966961778.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6RUV3rfx3q4n7UEJcARs9gCibB3TD5pgrxha1LmQwptV",
            "symbol": "WLZVC",
            "name": "Zombieverse Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cvzwk5dvHu67n2b4BDMNQ7VTQ4G2XRQ6hN3uDBPmD9vt/logo.png",
            "tags": [
                "WriteToEarn",
                "Zombieverse",
                "Whitelist",
                "MintPass"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Fu5zxU6Y8X",
                "instagram": "https://www.instagram.com/zombieversecomics/",
                "twitter": "https://twitter.com/ZombVerseNFT",
                "website": "https://www.zombieversecomics.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7Cs28oipyufQqvGNVpiJLT8CoUsWKw4gHxpaCGcLBQn8",
            "symbol": "CONC",
            "name": "CONCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pr0con/CONCOIN/main/eight-ball.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E8bT8g4so2zPnEoragg1wLCz29VXddqjf1acoG5YwTJA",
            "symbol": "BIBLE",
            "name": "THE HOLY BIBLE",
            "decimals": 0,
            "logoURI": "https://arweave.net/99YuLHYGxRGxLHLsX_z8WuzXRAPHZ0YAY_3B3MqesqE",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "bible-on-blockchain"
            ],
            "extensions": {
                "animationUrl": "https://arweave.net/V6BCeZcME8rVPXkCVSzad0lJKvWf7Az8gV4cwE-3AhE",
                "description": "World's first Bible launched on Solana's blockchain. Own a copy of the Bible on blockchain and keep it alive forever"
            }
        },
        {
            "chainId": 101,
            "address": "4rii94eJ9oh2rPNQY4y11gz4g7CtTfH9pULqFoCHFeCC",
            "symbol": "REO",
            "name": "REO",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/REO-TOKEN/REO/a5ad68159e7b6c29ccfa5c460a075b0f7aa02382/REO_TOKEN_LOGO.png",
            "tags": [
                "energy-token",
                "community-token",
                "recycling-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/reo_token",
                "twitter": "https://twitter.com/reotoken",
                "website": "https://reotoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4bFiA3N5VUFRYXr66MPozDRYfvBJzoyAmMNYqYMuFZJw",
            "symbol": "WTL",
            "name": "WHTLST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4bFiA3N5VUFRYXr66MPozDRYfvBJzoyAmMNYqYMuFZJw/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3jtXs6MeP2jes5WWcBPgD5W4iV9ieVnrfHW7tCCpY36j",
            "symbol": "BUBS",
            "name": "Bubbers",
            "decimals": 0,
            "logoURI": "https://github.com/Bubbers-boi/Crypto-Token/blob/main/bubberToken-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6zeVZMot1At2fBBSta4yfyHVX1FCS6ZHBFZzRY25A9wJ",
            "symbol": "RKTC",
            "name": "Rocket Chips",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rocket-chips/crypto/main/goldrocketchip_55.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhiH3QkPCZY3VJZjgwheTUTTvyHCDedYRumbg6Jdnw5R",
            "symbol": "NIGHT",
            "name": "Nightlife Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AhiH3QkPCZY3VJZjgwheTUTTvyHCDedYRumbg6Jdnw5R/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token",
                "governance-token"
            ],
            "extensions": {
                "description": "The NightLife premier token to be used with the Nightlife NFT platform.",
                "website": "http://www.nightlifecoins.com"
            }
        },
        {
            "chainId": 101,
            "address": "9krdcPB9gzGZk3QxqJi6AEAdyVt1RiDmQKin3im29DCJ",
            "symbol": "CHILL",
            "name": "Chilled Apes Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9krdcPB9gzGZk3QxqJi6AEAdyVt1RiDmQKin3im29DCJ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ChilledApes",
                "website": "https://www.chilledapes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "D9WeU6xNgvcitUBD2X7Uuv3SQnDmXihWvieqwicU7YFf",
            "symbol": "SOLM",
            "name": "SOLAMA",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/viniciuskloppel/solama-token/SOLAMA-TOKEN.png",
            "tags": [
                "Solama",
                "Token",
                "Degen"
            ]
        },
        {
            "chainId": 101,
            "address": "USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX",
            "symbol": "USDH",
            "name": "USDH Hubble Stablecoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX/usdh.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usdh",
                "discord": "https://discord.gg/d44A8WvK",
                "twitter": "https://twitter.com/hubbleprotocol",
                "website": "https://hubbleprotocol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3AvE8DJ9ErbxeRfx8dfL5if3iFpTi11FugarWzqmybwn",
            "symbol": "LADY",
            "name": "LADY VALENTINE",
            "decimals": 0,
            "logoURI": "https://arweave.net/TdW4_c2Q7nIqkofKqULS94zVSEyd1B9V5GHs06s3JPA",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "Lady-Valentine"
            ],
            "extensions": {
                "description": "You are the miracle of my life, my Lady Valentine",
                "website": "https://www.nftsolanagallery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8WUriNxrgMJMKWrbrKiP8DkKUgoduR2fswBgAcJo9Uag",
            "symbol": "YET",
            "name": "SolaYeti",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8WUriNxrgMJMKWrbrKiP8DkKUgoduR2fswBgAcJo9Uag/Icon.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "website": "https://solayeti.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G63oH5BhHFe4sGLuUmGge9K1p6Fonc5UkP4oyRqMdEaP",
            "symbol": "CLC",
            "name": "Clutch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nordstrm/Crypto/main/LOGOCOIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J4zNZR8Qzo6tuNm82oUKvq3FPTpKXEQxPh3EK3xDpmEZ",
            "symbol": "DUKE",
            "name": "DukePe",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/smmdsa/dukep/main/dukep.jpeg",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7N4c1HY6iYFo6Z5eAQUzBK3TpAJMRa5nNBvL5Mg6Zpb8",
            "symbol": "OHHH",
            "name": "Distract",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/SMHCrypto/Crypto/main/Distract.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "XK6KzWPZJ4u8gYWmZrqdb3yYj8HocPLxctU5T7ptKTk",
            "symbol": "TMLELM",
            "name": "Tamil Eelam",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/v2sxn/crypto/main/5E585811-7181-454F-AE04-AE40A1A28BFA.JPG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EGiWZhNk3vUNJr35MbL2tY5YD6D81VVZghR2LgEFyXZh",
            "symbol": "BIT",
            "name": "BIT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gtj6NZHgMZ7QHg8PDN6izEn4GjVoX8yFLv4ESNKsWb62/BT-logo.png",
            "tags": [
                "utility-token",
                "Game-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/bitmon",
                "twitter": "https://twitter.com/BitmonWorld",
                "website": "https://bitmon.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DzrMBR2XTedpZShMUjkgfTvc9Vm4qd6JqaRFraAokWYN",
            "symbol": "BWT",
            "name": "Balloonsville Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DzrMBR2XTedpZShMUjkgfTvc9Vm4qd6JqaRFraAokWYN/Logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "This token gives you the accessibility to mint 1 guaranteed Balloonsville NFT via the whitelisted presale.",
                "twitter": "https://twitter.com/blloonsville",
                "website": "https://balloonsville.io/"
            }
        },
        {
            "chainId": 101,
            "address": "asoxKG6KdRq8nqQ9ADJsYmBNyFYFwB9zp9TXPfgkVhn",
            "symbol": "ASO",
            "name": "artmuseo",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/asoxKG6KdRq8nqQ9ADJsYmBNyFYFwB9zp9TXPfgkVhn/logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/artmuseo",
                "website": "https://artmuseo.com"
            }
        },
        {
            "chainId": 101,
            "address": "9djyMuBtdUSo5xW7exfjwsuob5WknqJgf2muvGHuLxee",
            "symbol": "PTN-USDC",
            "name": "PTN-USDC LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9djyMuBtdUSo5xW7exfjwsuob5WknqJgf2muvGHuLxee/logo.png",
            "tags": [
                "lp-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Xh9ZFcDkKLqW28WgsFmEJ8mxqjzEKEArqftakC3gMUU",
            "symbol": "METOO",
            "name": "ME TOO COMMUNITY",
            "decimals": 0,
            "logoURI": "https://arweave.net/-MYQEJnrWu0t1MGd5qjWOzFU_zWqBvtOO3V8BKWJtXs",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "Me-too"
            ],
            "extensions": {
                "description": "Uprising is the only way out of gross inequality"
            }
        },
        {
            "chainId": 101,
            "address": "jWq7FELvM46QY7QPC1DDdjx1fmjgfga5v3YkYDpiavb",
            "symbol": "SWLA",
            "name": "SwalaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jholmes1209/CryptoShark/main/SWLACOIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Vke7fkMukNpBB12mvgbwzwmsx6USpd5UMt5uQjhGQJ3",
            "symbol": "NJOS",
            "name": "JOSLIN COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Josser02/Josser02/main/crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CesPoby8NEUXy2tzx5feb2Fp7jHnimyDcfHStDt1pLDb",
            "symbol": "MARIO",
            "name": "SUPER MARIO",
            "decimals": 0,
            "logoURI": "https://arweave.net/XbS4N_rj0rv7vyWtWZxgHMylIfpxqxZ0QQkPlusPO3U",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "super-mario"
            ],
            "extensions": {
                "description": "Life is a game, kid! It all depends on how you play!"
            }
        },
        {
            "chainId": 101,
            "address": "Fc5AWmJtCx7ktmyr8UneM9LrDTtazG7SqixYFBhFdf9X",
            "symbol": "SFGT",
            "name": "Second Foundation Graphics Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SegFun01/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EKH1TkPAnbGsDuGAy6yUJdqUcHRjCbbEjgp5KBVDVj8e",
            "symbol": "BUTTERS",
            "name": "BUTTERS STOTCH",
            "decimals": 0,
            "logoURI": "https://arweave.net/A7GWqGxhsi4U0MgVrBbaNBOO3GVXEqeFfvWastqxRGg",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "butters-stotch"
            ],
            "extensions": {
                "description": "Lu-lu-lu, I've got some apples",
                "twitter": "https://twitter.com/nftgalleryshop"
            }
        },
        {
            "chainId": 101,
            "address": "BcY62xPtPgVLo7vLpxkZPmYM9sGZZRAjo2hmJYSXZJ2u",
            "symbol": "IMPI",
            "name": "Impi Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BcY62xPtPgVLo7vLpxkZPmYM9sGZZRAjo2hmJYSXZJ2u/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft",
                "meme-token",
                "community-token",
                "trading-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/YDY3brHm",
                "facebook": "https://www.facebook.com/impikisogloo",
                "instagram": "https://www.instagram.com/impi_token",
                "telegram": "https://t.me/traderstoolboxtm",
                "twitter": "https://twitter.com/impitoken",
                "website": "https://impi-nft.traderstoolbox.uk"
            }
        },
        {
            "chainId": 101,
            "address": "EGHRD8VSdRVo9akHSxJT7f6S1tdskNSwQ39HEN4k17yg",
            "symbol": "KIS",
            "name": "Kisogloo Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EGHRD8VSdRVo9akHSxJT7f6S1tdskNSwQ39HEN4k17yg/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft",
                "meme-token",
                "community-token",
                "trading-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/JrErsKuc",
                "facebook": "https://www.facebook.com/latinexplosivepage",
                "instagram": "https://www.instagram.com/jason.kisogloo",
                "twitter": "https://twitter.com/jasonkisogloo",
                "website": "https://linktr.ee/LordJason"
            }
        },
        {
            "chainId": 101,
            "address": "JDcR8BL5F4vmERKMQ8oyqAME8BpktgJM9BmCkiber3Qa",
            "symbol": "XDI",
            "name": "Xplosive Dance Inc Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JDcR8BL5F4vmERKMQ8oyqAME8BpktgJM9BmCkiber3Qa/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft",
                "meme-token",
                "community-token",
                "trading-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/FEQTy75U",
                "facebook": "https://www.facebook.com/xplosivedanceincpage",
                "instagram": "https://www.instagram.com/xplosivedanceinc",
                "twitter": "https://twitter.com/xplosivedance",
                "website": "https://www.xplosivedanceinc.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8VmgCo3wzX32bCYm1oc14bVTcAXLhcTSBBLkF9BRs172",
            "symbol": "TTTM",
            "name": "Traders Toolbox Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8VmgCo3wzX32bCYm1oc14bVTcAXLhcTSBBLkF9BRs172/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft",
                "meme-token",
                "community-token",
                "trading-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C2hBncba",
                "facebook": "https://www.facebook.com/traderstoolboxMT5",
                "instagram": "https://www.instagram.com/traderstoolboxmt5",
                "telegram": "https://t.me/traderstoolboxtm",
                "twitter": "https://twitter.com/traders_toolbox",
                "website": "https://traderstoolbox.uk/"
            }
        },
        {
            "chainId": 101,
            "address": "FYfbMps5wjKfxWmQivyp3Ws79SRrxpgqEvfdEQzxnDhr",
            "symbol": "DDT",
            "name": "Dancer Dates Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYfbMps5wjKfxWmQivyp3Ws79SRrxpgqEvfdEQzxnDhr/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/dancerdatespage",
                "instagram": "https://www.instagram.com/dancerdates",
                "twitter": "https://twitter.com/dancerdates",
                "website": "https://www.dancerdates.net/"
            }
        },
        {
            "chainId": 101,
            "address": "BQ89saYHbWrtURbgaWJpsXXctRQXX1DeC7FsYmejiUNF",
            "symbol": "LIFE",
            "name": "Life Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQ89saYHbWrtURbgaWJpsXXctRQXX1DeC7FsYmejiUNF/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/splashoflifetm",
                "instagram": "https://www.instagram.com/splashof.life",
                "twitter": "https://twitter.com/splashoflifetm",
                "website": "https://www.splashof.life/"
            }
        },
        {
            "chainId": 101,
            "address": "Ab274upSKBwBLUWnE2BHZrhcu8ErpuSbJNe38YEVBbom",
            "symbol": "JCHR",
            "name": "J_CHAR_ Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/j-devx/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F2NV9QNwQVTb16uRn2691bECUUFFATdyV3gTGcRPSNf1",
            "symbol": "PINKPANTY",
            "name": "Katie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/katieandlaura/solana/main/katiecoinpink.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "93QRj9CQyMKjFSiC4UoQ2opt8A2JAUSD99eNuMgG1bg5",
            "symbol": "LVN",
            "name": "Lovin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/lvn-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3EyBARkMEPiCTkmGtKshgcyrk4K5owbaxSj3v9K8CJp9",
            "symbol": "CNFD",
            "name": "Confidence",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/cnfd-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "96ydjV6qPSZvrF5vVFYDh66md8XzY6kmbtCY66cYdLQX",
            "symbol": "MOT",
            "name": "Motivation",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/mot-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8z9gGpU5nUxvDpRDBMX7WfP2nTgLLA61gK8Yo1mUdN3W",
            "symbol": "SOM",
            "name": "Something",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/som-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3SeAK1jJ2TfBSg8wGVjy3bLeYPiKgTrK84sLKrMRcfGY",
            "symbol": "GNS",
            "name": "Good News",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/gns-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A3G1w2mBTFhWXvVXin72UR8Q3MqJ4PqkSX3bVgq92ZGQ",
            "symbol": "LCK",
            "name": "Luck",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/lck-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3TkFJvaZKSTQUFSFqDym8Eoq7oTqPPus66StZV8nLJsn",
            "symbol": "RELX",
            "name": "Relaxation",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/relx-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J67EndxpfUkTh1uxh9vtzn4CBQFS7L6YjznpFK7Gv8XA",
            "symbol": "FOOD",
            "name": "Food",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/food-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GRXC2wfayPY3X5zHvfe6DNwYcg2HTqGDaGQoNLZ5nQL5",
            "symbol": "PROM",
            "name": "Promise",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/prom-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5NXxnQJxJqCwc4u7qZKK2uq1SKWRVkvXgSo49h7wnaSp",
            "symbol": "TRVL",
            "name": "Travel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/trvl-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3A4DLGNZN4W2KxMYXhswjSsvNDQJ2sfbn5aTBP8sc1n2",
            "symbol": "DREM",
            "name": "Dream",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/drem-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6MtBCmYesdsyUGyJ2Xe6sDYjTMNWdAEMWdnzioN2enov",
            "symbol": "ANSR",
            "name": "Answer",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ansr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "VcfTkfUYBeFeTQ7NGbdWxcy1whTTM8RG1m85p24RQ1P",
            "symbol": "FRND",
            "name": "Friend",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/frnd-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CszE2EabvyV3aLSKC9vcqabqAu4cVTMNjbAGMiK8qRmh",
            "symbol": "THX",
            "name": "Thank",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/thx-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5dovqGWg1bA7A44q48Rho3cUgt5JBUj9yFdKo6RggQN9",
            "symbol": "SLTN",
            "name": "Solution",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/sltn-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FymoFdxDxoByBDjHSsBcKaaBpcKPLZJG1wnddEhnP6sX",
            "symbol": "IDEA",
            "name": "Idea",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/idea-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5W5YhE2XAyUdt5o96gDTDbwe3LfsezmmrevXsUXGCJyo",
            "symbol": "XCITE",
            "name": "Excitement",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/xcite-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ein7MYuyEsMC2e9SPk3TnBPxjT96F62wD7wFWPd7f6cp",
            "symbol": "HELP",
            "name": "Help",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/help-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DEdQULP5oXC11GD3XXEYz4sbrjs7kUN2RdoMoswiuGQo",
            "symbol": "LIF",
            "name": "Life",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/lif-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2TwVRWEtBEb5hqGE6n4xCLojsYbdxrGkdjBKABGbCkDq",
            "symbol": "CCNZ",
            "name": "Creative Coinz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ccnz-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EoqJXTxCc67i18B7nwWs59RBkTe8rStTreRhCCq1eVTH",
            "symbol": "DAU",
            "name": "dauqu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dauqu/dauqu/main/dauqu.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HmLbfdwGdbdtKaVC43G8oYJM8c5VLm7KwRZUKxFLx5v",
            "symbol": "PJCT",
            "name": "Project",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/projectjewels/projectcrypto/main/Unknown.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HASXzXvKKoZE1rSMn3dr78uATgLEZoJQ7NHMKfaivDrm",
            "symbol": "MEOWT",
            "name": "MEOW Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LinuxMeow/meow-token/master/meowtoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E7vVVWcdUFxGJ494C2W1AnbcYLzf5GJTWJzetCjzA7A7",
            "symbol": "DAGO-WL",
            "name": "DagoDAO Whitelist Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/juXE_DoTwZng2BK9QN6XdypWq5RscNKoxcKFUEQQ2Ho",
            "tags": [
                "whitelist-token",
                "nfts"
            ],
            "extensions": {
                "website": "https://www.kekaisarandago.com/"
            }
        },
        {
            "chainId": 101,
            "address": "goonVPLC3DARhntpoE56nrsybwMnP76St5JoywzCmMw",
            "symbol": "GOON",
            "name": "Gooney Toons Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/goonVPLC3DARhntpoE56nrsybwMnP76St5JoywzCmMw/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "B8L7mjHL3xm6iYo8n7r7SigvFYqg6pwQdJdeGSuPQTfD",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "AX5A1pVHMq12uFFhAPmsyLXw4KDiPRULxrejjeoX9CSp",
            "symbol": "DBWT",
            "name": "Dead Bros WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sungminkim01/token-list/main/assets/mainnet/AX5A1pVHMq12uFFhAPmsyLXw4KDiPRULxrejjeoX9CSp/logo.jpg",
            "tags": [
                "DeadBros",
                "DBT",
                "NFT",
                "Whitelist",
                "Zombie",
                "Undead"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeadBros666"
            }
        },
        {
            "chainId": 101,
            "address": "J8MmCBGGC2X5YAzjE4Md778d3zAAxmcJBrX8FqMmeqkh",
            "symbol": "WOCH",
            "name": "Whoochie Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/xxmafiaxxx/doobietokens/main/whoochie.png",
            "extensions": {
                "description": "The Whoochie Token",
                "twitter": "https://twitter.com/woochiewobbler",
                "website": "https://onlyfans.com/missusblu"
            }
        },
        {
            "chainId": 101,
            "address": "8htWTajB8ZeDfSuC6r3YLottcyfSE3cQqXsrd4V8CZMQ",
            "symbol": "Bldr360",
            "name": "Buildr 360",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/paxanon/buildr-logo/main/buildr360/logo.png",
            "tags": [
                "buildr360-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BsjdZPmJn4ywzPYMeeWPMf7ZzfDUnC4iKbsRmo1Ub4Cp",
            "symbol": "NRQ",
            "name": "Norqoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cryptodog912/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2pyuMiUqRrBSXvg8wRxNkuhaV4iJa4i8aGp5t6FLHGQh",
            "symbol": "HKSM",
            "name": "HKSM Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pvella/HKSM-Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8qhJSbyy3akGKoPdnX6TRiWZn2b8ysF5XbcUmzxDPZrv",
            "symbol": "LXG",
            "name": "LINUXGOLD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/linuxgold78/logo/main/linux_PNG42%20(1).png",
            "tags": [
                "linux-token",
                "gold-token",
                "info-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GzMYkPvEccCxn7bvZh8Jhz87GhrASKhVadn9LHE4zVDy",
            "symbol": "CAPRA",
            "name": "CAPRA Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GzMYkPvEccCxn7bvZh8Jhz87GhrASKhVadn9LHE4zVDy/logo.png",
            "tags": [
                "whitelist-token",
                "governance-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://spacegoatsonacid.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9ndWJZRvFN36rJPd6Xe4HzWjFrEKUG4RFVFyyaCttuaX",
            "symbol": "DASH",
            "name": "Dashsnipe WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ndWJZRvFN36rJPd6Xe4HzWjFrEKUG4RFVFyyaCttuaX/logo.jpg",
            "tags": [
                "whitelist-token",
                "governance-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://twitter.com/DashsnipeSOL"
            }
        },
        {
            "chainId": 102,
            "address": "e33pRtp3gj8q47yKYbUPYWCh6CibNSq5Q5VqrNv2Umo",
            "symbol": "MALL",
            "name": "MetaMalls (Testnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/c-spider/Solana-TestToken/main/icon.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "telegram": "https://t.me/aiLikeSpider",
                "website": "https://metamalls.io"
            }
        },
        {
            "chainId": 101,
            "address": "DrhQmuUWbAmszSVagzUcJfQhLMgL3gWtineYbQ6drzRM",
            "symbol": "DON",
            "name": "Don Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/game09488/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2xZzszs2FeTffckT5WhvHXzuXbM94bvp31vC7BRdXWhE",
            "symbol": "aeTRYB",
            "name": "Wrapped TRYB (Allbridge from Ethereum)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2xZzszs2FeTffckT5WhvHXzuXbM94bvp31vC7BRdXWhE/logo.png"
        },
        {
            "chainId": 101,
            "address": "5NfKc4tVAWn94qg35SU5XcZAT7dt7n4YLLwKB1kWmEf8",
            "symbol": "SMC",
            "name": "SmolCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bugzum/Crypto/main/ree.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8dPFrSN4oVnMFZoAYnoTvufz6cFerJAw32aiWCtBvXWy",
            "symbol": "SMT",
            "name": "Smart Money Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/darek4you/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "hapiymAn1QKih3vHmqRQyUWLSCbMjRukuRobAQ66Qj6",
            "symbol": "HAPI",
            "name": "HAPI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/hapiymAn1QKih3vHmqRQyUWLSCbMjRukuRobAQ66Qj6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "hapi",
                "github": "https://github.com/HAPIprotocol/HAPI/",
                "medium": "https://medium.com/i-am-hapi",
                "telegram": "https://t.me/hapiHF",
                "twitter": "https://twitter.com/i_am_hapi_one",
                "website": "https://hapi.one"
            }
        },
        {
            "chainId": 101,
            "address": "GUHRLLpnD6eCh7PFf6xH55ZX4c3T7WA5psjww5h6uneH",
            "symbol": "SMELL",
            "name": "SmellingFart",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/al3x2508/SmellingFart/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.link/SmellingFart",
                "twitter": "https://twitter.com/SmellingFart",
                "youtube": "https://www.youtube.com/channel/UCjwpAfoBXQdE4CW3LzYcwmQ"
            }
        },
        {
            "chainId": 101,
            "address": "H4Nsy2sUHBXVhTRSjn2Jvy3cfZ64YJUa2HxjfJUgZHNg",
            "symbol": "DNB",
            "name": "dNb Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dani-Brasoveanu/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://bigbrothers.ro"
            }
        },
        {
            "chainId": 101,
            "address": "61xuVm4bAG9SjR7ADE5eR1oAYyQffFeRJi26F4EKfe7R",
            "symbol": "CHC",
            "name": "Choose Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lthull/choose-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6wvzrS9LdKTCF5vb3VMA96c8s5syDR16nWD3d5MDjBQr",
            "symbol": "NETOFF",
            "name": "netOFF Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mef123/netoff-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CxV98Qj1mtS1Et9arBmbPC1wamtxe8kU9qGEfd16BqDt",
            "symbol": "YTT",
            "name": "YattaCoinCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gerpdev/yatta-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DfcZGTPbPsrauFXzRnnCjc3B8u2ZDQCSFvKRKDDdAvt4",
            "symbol": "STAN",
            "name": "STAN MARSH",
            "decimals": 0,
            "logoURI": "https://arweave.net/U1XpUwL3Awpbq9EKpEHd1bzAP1t80LE7ul164jW-MtQ",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "stan-marsh"
            ],
            "extensions": {
                "description": "I think it is the nicest hat I've ever known"
            }
        },
        {
            "chainId": 101,
            "address": "5jK8Vdu4pB6Fv7WNXfzgoCZnyBUa58KHNjik6K4ovMbL",
            "symbol": "KCOIN",
            "name": "KAVALAN COIN",
            "decimals": 0,
            "logoURI": "https://github.com/yang9515181/Crypto/blob/main/logo_Kavalan-2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7cEfTi1cwRUTZeqqZAYhLKFLktMJv81YtfFRxaUitpvd",
            "symbol": "OLV",
            "name": "Olvency",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Olvency/Olvency/main/lovency-circle-2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BhWj9CvMqWJxoCcYV9P66u7aPB4aZ9WTWEDfEAt62hJ9",
            "symbol": "BDC",
            "name": "Bangla Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/shuvoGit50/Bangla-Coin-BDC-/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7xFkazheBr5DXAPnsCxX1TnyuDYhXjHrvf9aEgdJnY8X",
            "symbol": "WOSFT",
            "name": "WOS Free Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/deadzer0/wos-logo/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "9By8YbnSZc2xNhSy6SE2mDpb9X9vF7qArp1jshawvd3d",
            "symbol": "ZNT",
            "name": "ZerberusNetworkToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Luapix/ZerberusNetwork/main/bbe13b80b4804217801a654a158980b7.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F52RcGHrdGiSfKSXmoWd5YBAYsmkftmBWhbjSD5DDq6o",
            "symbol": "STONKIS",
            "name": "Stonkis coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/saltvatten/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9WR2z4LSgWtr7nyfzmGaH5T8dBqJtCJbea62m6ynQ5zL",
            "symbol": "NILAISH",
            "name": "Nilan Aish Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9WR2z4LSgWtr7nyfzmGaH5T8dBqJtCJbea62m6ynQ5zL/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bk7BtQVbzh3aqHS8XntmA6xXZZYwBemqscYbWLD4VbXU",
            "symbol": "GHSTX",
            "name": "GHOST OPTIONS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bk7BtQVbzh3aqHS8XntmA6xXZZYwBemqscYbWLD4VbXU/logo.png",
            "extensions": {
                "website": "https://ghostoptions.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CwpXvAZDNK8n6FVqHxdATvgvSLBfwkm5AJqdDTCT4L38",
            "symbol": "SM",
            "name": "Solana Miners WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CwpXvAZDNK8n6FVqHxdATvgvSLBfwkm5AJqdDTCT4L38/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/JDTqAGFnNd",
                "twitter": "https://twitter.com/SolanaMiners",
                "website": "https://solanaminers.com"
            }
        },
        {
            "chainId": 101,
            "address": "FwwxaPRAEBx7Bp98goCYknGK4NLmZSJfQR16vJzJVrr7",
            "symbol": "DBT",
            "name": "DatBug",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/azaek/DatBugToken/7679d6daa5272585b57352a084b85dd05d151799/DBT.png"
        },
        {
            "chainId": 101,
            "address": "iV4DSeDNjWasKVywvu8Mp4mMc2Te7uioGEWrfTnAQxF",
            "symbol": "SOULS",
            "name": "HornsvsHalos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hornsvshalos/token/main/soulstoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DumWh3LbSTP7iE9oRSLeptfjK3NoJLkWXTgHGDqybunZ",
            "symbol": "CRAP",
            "name": "Crap",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/crap-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DpbsGSHBZQrKkvxkFU8snmx6KzFK7RRUCiRksN7ojEqx",
            "symbol": "SYMPATHY",
            "name": "Sympathy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/sympathy-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HNMjpQYNdY3xjFt91X2Rg4sbNi3WKySJsPMJHq2yKNiQ",
            "symbol": "CHOICE",
            "name": "Choice",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/choice-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3P7TBkAZjRQdEQgLj2y8cSCPpjhsmyvWHNgc4G9VYn2n",
            "symbol": "ATTN",
            "name": "Attention",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/attn-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "65ih6bwSeUeLdDyB8BggwVwrRaMofvLvthubv4Spu9MC",
            "symbol": "GRNDGT",
            "name": "GRANGD's Global Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/joelshu1/frenchclass/main/logo-yuanbao.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Q2z5MAAHxmquedtmnFJ5zXf8FDrpq2DH4bBodHYuG3w",
            "symbol": "VTC",
            "name": "VerdeCoin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Q2z5MAAHxmquedtmnFJ5zXf8FDrpq2DH4bBodHYuG3w/logo.png"
        },
        {
            "chainId": 101,
            "address": "FowCNsVNypPgQqSGu8ZQ4GLxMMsZTsciSFYbSURHcBdz",
            "symbol": "ATOS",
            "name": "Atom Solana Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Mickyfly/assets/f54a2c841882bce3cb4839166d47ef087bae8f22/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FgGo7MsjQDxaTcWSMRasm6Dj2pi9S9wTu2YAdSdBwfwi",
            "symbol": "RED",
            "name": "RedCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thebigred1/crypto/main/redcoinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Du9uRpTT7HuHuKvFZXiS2LEboEVLdhxL5ScLYPU88K9Q",
            "symbol": "FCoin",
            "name": "Firecup Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Du9uRpTT7HuHuKvFZXiS2LEboEVLdhxL5ScLYPU88K9Q/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CVoDGyg8cUebjpHUb7N7r32sg2gawXRG7JNQNqJFTAGR",
            "symbol": "LAZY",
            "name": "Lazy",
            "decimals": 9,
            "logoURI": "https://arweave.net/-eAPfd93Q3-JeZ5dLyr5PfXo0TgC5n0ufI-LuFwxpcQ",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LazyHeroesNFT",
                "website": "https://lazyheroes.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DF4tgv1gDDdciLZe3HvhuVm8LL9ZqvZxiZ5mUzMg6eSs",
            "symbol": "$DOGEVR",
            "name": "Doge Very Rocket",
            "decimals": 9,
            "logoURI": "https://m.media-amazon.com/images/I/41TMEvEbCGS._AC_UL320_.jpg"
        },
        {
            "chainId": 101,
            "address": "Ghj7ib4VJC592ybwpMK75vaX3fRu1SqtkXYMd2pNywtK",
            "symbol": "PRMD",
            "name": "Primordials Token",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/84945202/152918221-72a0d9a4-374b-4b30-b686-e371502b0978.png",
            "tags": [
                "social-token",
                "gaming-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zFDBEKSq",
                "medium": "https://medium.com/@primordialsgame",
                "serumV3Usdc": "9wmsybKvBWKatj7FxkYhauna6z4mcMDXQrjNvdy2kdgE",
                "twitter": "https://twitter.com/Primordialsgame",
                "website": "https://primordialsgame.com/"
            }
        },
        {
            "chainId": 101,
            "address": "778ourFLRMCceitp8QXowkrcu4sDhn141Bhm1FuWeoCy",
            "symbol": "KROM",
            "name": "Krom Powered",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lminor/blockbusters/main/krom/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3nGtw9cDwi8Nj81p2t53KxCQ8jjAdRScEX1yPSkwmcY6",
            "symbol": "RAY",
            "name": "THE SUN",
            "decimals": 0,
            "logoURI": "https://arweave.net/7mhSk9OMb57Yuuc0pGbBY1rfcnw5Ft3SmaFtuoR5GLo",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "the-sun"
            ],
            "extensions": {
                "description": "The Sun, the hearth of affection and life, pours burning love on the delighted earth",
                "imageUrl": "https://arweave.net/7mhSk9OMb57Yuuc0pGbBY1rfcnw5Ft3SmaFtuoR5GLo",
                "twitter": "https://twitter.com/nftgalleryshop",
                "website": "https://www.nftsolanagallery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DJLhSzxoVm1NhNvd38kwpdP896Jwmr2jSw6aFKqahyiA",
            "symbol": "KDN",
            "name": "Kid Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TechyKid/KDN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6FKVXC2BGCQ18sSyPRp5UDGfPi4LXWLCJxDFkfQjFk5n",
            "symbol": "GAC",
            "name": "Grand Ape Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6FKVXC2BGCQ18sSyPRp5UDGfPi4LXWLCJxDFkfQjFk5n/GAC.png",
            "tags": [
                "social-token",
                "Utility-token",
                "NFTs"
            ],
            "extensions": {
                "discord": "https://discord.gg/zZedFkuybR"
            }
        },
        {
            "chainId": 101,
            "address": "B2ZNLe3zPzo6suMQNkFDdHy3PhsoUExCuxGx4bzZrzeP",
            "symbol": "SENSHI",
            "name": "Senshi WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tund1/images/main/Death-Back5%20(1).png?token=GHSAT0AAAAAABQRVZZUR3K3OD7BM7IEY4W2YPPIV2A"
        },
        {
            "chainId": 101,
            "address": "CLd797uarSBWHmD7rFiypQQNsTZZMmRjgxTbEV15k4g6",
            "symbol": "HYPR",
            "name": "HYPRCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kmpunk/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ADNUG4Y8LW9jTticE9VaPs8xfSVXg1j8UKC8VzcEKXHe",
            "symbol": "THS",
            "name": "Thesis",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/DekreedStudios/varkalai/main/thesis.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Varkalai Token",
                "website": "https://www.varkalai.com"
            }
        },
        {
            "chainId": 101,
            "address": "FhFUCR3ftJojyymv99iPiqoENAgFvHJYrQCAZDMZU71P",
            "symbol": "EWIR",
            "name": "eWire Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/clearviewsys/ewire-coin/main/assets/logo.png?token=GHSAT0AAAAAABQUJF6NFEUYBWDNSPXF4P4KYPYTZWA",
            "tags": [
                "social-token",
                "security-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BkPs1XvVkFbwuYhNKCaNubH86dK5o57CuQz4GfunJhHw",
            "symbol": "WUSDT",
            "name": "Wrapped USDT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/andbandu/wrapped-usdt/main/logo.png",
            "tags": [
                "Payment-token"
            ],
            "extensions": {
                "website": "https://wrappedtether.to"
            }
        },
        {
            "chainId": 101,
            "address": "76JQoQBum4A9KD7b6CKRJ2eWMRkjFNVEzA4mJ9VxVv9K",
            "symbol": "SENWL",
            "name": "Senshi Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tund1/images/main/Death-Back5%20(1).png",
            "extensions": {
                "discord": "https://discord.gg/E7yWZrzvXj",
                "twitter": "https://twitter.com/SenshiNFT",
                "website": "https://senshisamurai.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5hwowy6cu7XxkA2NNXtA3YxeFP5hZeXp2qt9nfhSEaAL",
            "symbol": "GROUND",
            "name": "CommonGround",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/CommonGroundHoldings/CommonGround/main/Logo.png",
            "tags": [
                "financial-token",
                "real-estate"
            ],
            "extensions": {
                "description": "CommonGround is democratizing physical land ownership by providing fractional shares in a portfolio of real land parcels around the world in a tokenized form.  Land is the purest and oldest store of value. CommonGround is the first and easiest way to buy, sell and trade pure land assets through tokenized liquidity.",
                "telegram": "https://t.me/+4GgFHbWiUWVhNzJh",
                "twitter": "https://twitter.com/CommonGroundSOV"
            }
        },
        {
            "chainId": 101,
            "address": "AfXqwtEAMNxiPGfWLVzc2Tda2fYU68G1zMV1kjsbCdia",
            "symbol": "BTCSEA",
            "name": "Bitcoin NFT Sea",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AfXqwtEAMNxiPGfWLVzc2Tda2fYU68G1zMV1kjsbCdia/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Ajnx5aVELiDbir3du6c9pbuJPJNmpqoEJBhND2oswmKz",
            "symbol": "KMC",
            "name": "KMC FINANCE",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/mum33n/kmc/main/logo.eac9db95.jpg",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cGaTmkCf",
                "twitter": "https://twitter.com/KMC_FINANCE",
                "website": "https://kmc.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "77pjWry8wE767r3sS9xfvRNt87gKmcgVu71Nk8seX2UK",
            "symbol": "SPCE",
            "name": "Space",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/spce-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D6H5nvUMMTtUaKBdaQHQmmFJ1epZWRM8YjqmRfdVp8Yy",
            "symbol": "LBRA",
            "name": "LIBRA Experience",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/asadat81/crypto/main/LibraBlackLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EoMgMnWvkkqAFKRRktf4XRYuCvXykH6hW3DYv793HrG1",
            "symbol": "WETH",
            "name": "Wrapped Ethereum Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EoMgMnWvkkqAFKRRktf4XRYuCvXykH6hW3DYv793HrG1/logo.png"
        },
        {
            "chainId": 101,
            "address": "DTWaeJx4Q3dbZdLZshU8Lr8k3ewfQss9rhjd2zBaiwSV",
            "symbol": "DTD",
            "name": "Drake the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/Drake.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LdEPJM6vTgBUjzvDaTMEr3jZ2PKsQuMkbDFD3xuvmJd",
            "symbol": "TWTD",
            "name": "The Weeknd the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/weeknd.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ai56GYeXjMjuoSV9WEktKPiT1cKM644MaGTrf6qXSogW",
            "symbol": "DBTD",
            "name": "DaBaby the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/dababy.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ak42CFiNNZZJtfNUwoH7MwQcNkFtmfMWi1F7RWhshPz",
            "symbol": "PGTD",
            "name": "Polo G the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/Polo%20G.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EiEayfxQnBVw8qVgtX4FVNyZMA1J2eCMg7QuDrjUSpcj",
            "symbol": "22TD",
            "name": "22Gz the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/22gz.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Y3QxSkUCk2mf2ZCXqKzhiXc2hQbed2ec1Tjpp1o5GZ3",
            "symbol": "42TD",
            "name": "42 Dugg the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/42dugg.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bq7npCEUPLrpFaYxeCB9SEuZ9z8xEwEFJ75wVW3BppYC",
            "symbol": "JBTD",
            "name": "Justin the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/Justin.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6vRax67T1oaRgQEG12E1GWAcbMUYZhjFgQCGCUA3Mr5N",
            "symbol": "ATD",
            "name": "Ariana the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/ariana.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3i8Z2eXAUzsovJUapJhBWYjRWDSkS6a6aRNJxxFtL6go",
            "symbol": "ARTD",
            "name": "ASAP Rocky the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/asap.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6b5CMmrMfycoBPakTb1D9ZF4QHdQYVFLfjrMdKpm9ycE",
            "symbol": "BKTD",
            "name": "Baby Keem the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/babykeem.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "96tk9QyWua8Aykd5K5kc85wr58ZC7J9KxvCMMT7UpBdK",
            "symbol": "BTD",
            "name": "Beyonce the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/beyonce.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Wo2tB9zMLy7zRFxN4rURRocMg5NBtnKcV5dDTWt8bPd",
            "symbol": "BETD",
            "name": "Billie Eilish the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/billie.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9kiNXWvgA3mT9EomcNbMWkdCTTn2JNSWSBJiSvXJpQkE",
            "symbol": "BPTD",
            "name": "Black Pink the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/blackpink.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4tQdSnBsQ2cLnohbBi4T7SqR3UtDoQkE7eTGTFnz4wqK",
            "symbol": "BFTD",
            "name": "Brent Faiyaz the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/brentfaiyaz.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D7nEWnYFN6xeJPHn31PS8tL3oZJ1cx2CJii2o1F7R6uA",
            "symbol": "BTTD",
            "name": "Bryson Tiller the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/brysontiller.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4fknN6hnL5GtzSmfM9mYycphVB58HPxkxmBAAeTAwuCD",
            "symbol": "BTSTD",
            "name": "BTS the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/bts.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F5JpUAqTbCE3JovCJLnpUmz3aKBmi4ZebbLxw4SAdMop",
            "symbol": "CBTD",
            "name": "Cardi B the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/cardib.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Kcv947kwhDJjHntdG5Bx13yStuMaLCZ9NUryDyptFrK",
            "symbol": "PCTD",
            "name": "Playboi Carti the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/carti.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fn1Y7HvU7UQTDEjiAGac7pPGmow5aZwfZFPnqXRd3oQ8",
            "symbol": "CBTD2",
            "name": "Chris Brown the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/chrisbrown.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Ht7dyr9oLBApfFxsYaHxSccpqNmBNFFZb4kuhP54g9f",
            "symbol": "DJTD",
            "name": "Doja the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/doja.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HdVG3bxxyStRf8qawt67z9JrQ9jMDjQySHCohamYbuCG",
            "symbol": "DLTD",
            "name": "Dua Lipa the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/dualipa.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C1KjuTtpi6wTeMDTU1EShb7wYk7Rt7gmuA8a8huQ8foH",
            "symbol": "FFTD",
            "name": "Fivio Foreign the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/fivio.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "51SPUdQvnPtaAH3HW6x1XFx7wdPAdGX2PATvH1f7hCx",
            "symbol": "GVTD",
            "name": "Giveon the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/giveon.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HbRMsfSaoSdpK6YJSHg4WidyrgigbcG6aEKCaE4j7rLN",
            "symbol": "GUTD",
            "name": "Gunna the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/gunna.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FsX2G6cgtHyvKsGLQfyVzYmVZquQAyxCf9vaw2RtMoxX",
            "symbol": "IDTD",
            "name": "Iann Dior the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/ianndior.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5L6uiM3uvcGc1xC6HYBmzPJ8V7F98STL6mWLfJhLsZBB",
            "symbol": "JHTD",
            "name": "Jack Harlow the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/jackharlow.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DR4fbXfiY1Sh7obps2WBAne7iLmCqWfcSMq1C1iJhyMw",
            "symbol": "JCTD",
            "name": "J Cole the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/jcole.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Vc531wSxFcCTTMFyBW1sgJzamHZUBLYBJMpufcQy5Rj",
            "symbol": "KLTD",
            "name": "Kendrick Lamar the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/kendrick.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cs6mUEfAbd8NtCCGqy2JkzzpjcATMYP9E3kmDeLeT5UE",
            "symbol": "KLTD2",
            "name": "Kid Laroi the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/kidlaroi.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7X8ia4jHmvgSHZDvdDtVRUZR3g5f5J1YVZyvkLfg2c8c",
            "symbol": "LBTD",
            "name": "Lil Baby the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/lilbaby.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8izhNY8Ex3wA4rEP4VWTX4XhpWag7c7x9DCdVpb5mdCK",
            "symbol": "LNXTD",
            "name": "Lil Nas X the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/lilnasx.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7qrjNSNqXQFWYLtM2x5o8RUv9eVwQo3B9pFyVmuEMZ1Y",
            "symbol": "LUVTD",
            "name": "Lil Uzi Vert the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/liluzi.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BUDQJmx6eq7fw4jBsoYgPv33Q4bcoFXN4onP89rAEPsz",
            "symbol": "LWTD",
            "name": "Lil Wayne the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/lilwayne.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "87KwpBxNnHNNiT2656XsSCrkCwk5DGzqeiJN9wFKgeYc",
            "symbol": "MMTD",
            "name": "Meek Mill the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/meekmill.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cxuimbvjj7R7GpzTKjimi7FSanJw6KffmfCmCdpTcxhi",
            "symbol": "MTSTD",
            "name": "Megan the Stallion the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/megan.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AaHN2BhgxmqjSGWxAJLKfT8kxA5P2ye9MxZjveRrUj63",
            "symbol": "MFTD",
            "name": "Montell Fish the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/montellfish.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CZ9CymSKQh9c3J4kTgHt5e5CHhtXXw7BoBV6K1ugU56m",
            "symbol": "NMTD",
            "name": "Nicki Minaj the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/nickiminaj.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "iBdafMoCKZ8Zc1r329QtrEUe3n6ykq3UEUtn8GVCtx9",
            "symbol": "NRTD",
            "name": "Normani the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/normani.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8zZsaGWfMPnWQtxh9Qwo9YdNoEHS6PtHxmdisKYS8QHh",
            "symbol": "NYBTD",
            "name": "NBA Youngboy the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/nyb.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7dXcQeKRfPHyEy7bwcNPGA8CyoGtdEUzui61NhgNyPUd",
            "symbol": "ORTD",
            "name": "Olivia Rodrigo the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/olivia.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiP31pzu8gaBDCthqVi7ec2kJr4EDU5N8GDv8ymCuGBH",
            "symbol": "RHTD",
            "name": "Rihanna the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/rihanna.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ANnik3298mNcS8ex7dpyh3rYi3NF7Wv4f8sEen8VJWQF",
            "symbol": "TSTD",
            "name": "Taylor Swift the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/taylor.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bga8vuZyVzK5PnLitJM1yiUDfBjEtpRaxDqdGdxDZEYZ",
            "symbol": "YDTD",
            "name": "Young Dolph the Doyen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haroonmoh/doyentokenimages/main/youngdolph.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BgBUxRsEgXur2iyhhJnWwbBMiSNiMWz1Ka5TTgVRt2Ft",
            "symbol": "BUFF",
            "name": "Buffaloe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/buff5953/crypto/main/Screen%20Shot%202022-01-24%20at%2011.49.34%20PM.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6R98EWBJALzZxtN4iUQwCifuXK9jB7CeX4wRC7d6RBbK",
            "symbol": "TSD",
            "name": "TrueSight DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TaUdunJo5TcdJzt9kyB4cd6HTYUdFXJHCSL2yc3sbg4/logo.png",
            "tags": [
                "governance-token",
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "TSD is a governance and utility token for our universal credibility protocol. We believe making decisions under ignorance is to the root cause of human suffering. Our purpose is to build a better world based on fundamental truth. Our mission is fight information pollution by providing individuals with actionable insights to make critical decisions. This has became imperative in our increasingly chaotic world.",
                "discord": "https://discord.gg/gEfypKdCEW",
                "facebook": "https://www.facebook.com/TrueSightMe",
                "imageUrl": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TaUdunJo5TcdJzt9kyB4cd6HTYUdFXJHCSL2yc3sbg4/logo.png",
                "reddit": "https://www.reddit.com/r/truesightme/",
                "website": "https://truesight.me"
            }
        },
        {
            "chainId": 101,
            "address": "EcTLhBgyBSRgAPH8FPpRQXajZarzW2tFRqeban1WoWiE",
            "symbol": "YACHT",
            "name": "YachtCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Citizen72/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9XAmWFSJA1WGWJXnaMgGHEErqeS2N1hf9N3Lwpfjehiq",
            "symbol": "HPC",
            "name": "HoodiePrint Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HP-hoodieprint/crypto/main/logo.jpg",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CaEAvWF1EFNrgddjhtzC1vtiUf1CRmap3Bw8J2usjaya",
            "symbol": "Shakti",
            "name": "Shakti Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shakti105/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7vTJvSEJY8Ww4zDmphb2rWy6qatd2wduAfQX9HMZQnqJ",
            "symbol": "NIHC",
            "name": "Nihal Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/realnihal/crypto/main/token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BdtBaw3u9bBiBLjF9unbPDhXTWaWqz6ngSDXo16yXcT9",
            "symbol": "CHERRY",
            "name": "Cherry",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BdtBaw3u9bBiBLjF9unbPDhXTWaWqz6ngSDXo16yXcT9/logo.png",
            "tags": [
                "charity-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.naver.com/prologue/PrologueList.nhn?blogId=cherry_official",
                "description": "Cherry is a donation token that is used to beautify the world through donations.",
                "facebook": "https://www.facebook.com/cherry.e4net/",
                "instagram": "https://www.instagram.com/cherry.e4net/",
                "website": "https://cherry.charity/"
            }
        },
        {
            "chainId": 103,
            "address": "BdtBaw3u9bBiBLjF9unbPDhXTWaWqz6ngSDXo16yXcT9",
            "symbol": "CHERRY",
            "name": "Cherry",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BdtBaw3u9bBiBLjF9unbPDhXTWaWqz6ngSDXo16yXcT9/logo.png",
            "tags": [
                "charity-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.naver.com/prologue/PrologueList.nhn?blogId=cherry_official",
                "description": "Cherry is a donation token that is used to beautify the world through donations.",
                "facebook": "https://www.facebook.com/cherry.e4net/",
                "instagram": "https://www.instagram.com/cherry.e4net/",
                "website": "https://cherry.charity/"
            }
        },
        {
            "chainId": 101,
            "address": "9CpTqKJp297cjUSpfd15akDnRhHD5hVertuKSZNL3u8x",
            "symbol": "WOSG",
            "name": "WOLF GANG TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9CpTqKJp297cjUSpfd15akDnRhHD5hVertuKSZNL3u8x/logo.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/vhjPC4xzSE",
                "instagram": "https://www.instagram.com/wolfofsol/",
                "twitter": "https://twitter.com/WolfOfSol",
                "website": "https://www.wolfofsol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9AT5QZhKgzfikxLPTTtvEtkSFJjm8td6poRGzLVZecS2",
            "symbol": "SOO",
            "name": "SooKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LennyKato/SOOK/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5oXYc3xX9CEdCL3wV1HjXgfVQkaMDpEYgj7B7TKifPWc",
            "symbol": "LZ",
            "name": "The Lazy Token",
            "decimals": 9,
            "logoURI": "https://arweave.net/-eAPfd93Q3-JeZ5dLyr5PfXo0TgC5n0ufI-LuFwxpcQ",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LazyHeroesNFT",
                "website": "https://lazyheroes.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AUWaJQkaLGFGFBe5xoEjUqr13vGgPDUFjFTvEfP5VoMG",
            "symbol": "KTY",
            "name": "Katy",
            "decimals": 2,
            "logoURI": "https://solana-bucket.fra1.digitaloceanspaces.com/AUWaJQkaLGFGFBe5xoEjUqr13vGgPDUFjFTvEfP5VoMG.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FKygZZn2xVUKBrseYJH5hnmwJGVhMgoanMooA7nPU17K",
            "symbol": "NGCT",
            "name": "NairaCoin Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/samwarlock/nairatoken/main/icon.PNG",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5oc5fSjG1xfz6UtXvAyJTW6Ai7q6tbLWddTmw9DH2Qej",
            "symbol": "SRCT",
            "name": "Solana Rabbits Club Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5oc5fSjG1xfz6UtXvAyJTW6Ai7q6tbLWddTmw9DH2Qej/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GoLdAj6PZFhKp9QmtFgqvSZfAMPnZjQVxwVi4YbGxmq2",
            "symbol": "AotMGold",
            "name": "AotM - Gold WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoLdAj6PZFhKp9QmtFgqvSZfAMPnZjQVxwVi4YbGxmq2/gold.png"
        },
        {
            "chainId": 101,
            "address": "sLvrnnFy6M8iPTQ9Gy4Zx251QkewH4YzcpFLh3ezVyJ",
            "symbol": "AotMSilver",
            "name": "AotM - Silver WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sLvrnnFy6M8iPTQ9Gy4Zx251QkewH4YzcpFLh3ezVyJ/silver.png"
        },
        {
            "chainId": 101,
            "address": "brnzEEdWQ5pBd2Qfec4Tquw5xL1tLkL85qFuBsMGjfm",
            "symbol": "AotMBronze",
            "name": "AotM - Bronze WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/brnzEEdWQ5pBd2Qfec4Tquw5xL1tLkL85qFuBsMGjfm/bronze.png"
        },
        {
            "chainId": 101,
            "address": "4Z9UMELGy3tU4pD4PvnQCiTVGPDpNK6e25XYSWYH9MrG",
            "symbol": "ALBINO",
            "name": "ApesAgainst WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Z9UMELGy3tU4pD4PvnQCiTVGPDpNK6e25XYSWYH9MrG/logo.png",
            "extensions": {
                "website": "https://apesagainst.io/"
            }
        },
        {
            "chainId": 101,
            "address": "zwDxiFD5CJ8nKUtzzKWJ9YAp8Y5NbHkSCMjBesxv7j5",
            "symbol": "GOD",
            "name": "Godly Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoGodd/crypto/main/CryptoGod.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ufvPBDBMTT3tj5xzxxKsTqjHArWYPpLMv715KbWtNDi",
            "symbol": "DILAC",
            "name": "DiLa Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ImBillyon/crypto/main/DiLaTv_NEW_LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C28joqNtMTFHBNbPFZyJx62WH1uW8hWhhAUJRyH5XGfb",
            "symbol": "JTC",
            "name": "Justtravelcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BestGitUser1/crypto/main/bessereslogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "76aYNHbDfHemxSS7vmh6eJGfjodK8m7srCxiYCrKxzY1",
            "symbol": "ROLL",
            "name": "HRHC Token",
            "decimals": 6,
            "logoURI": "https://hrhc.io/images/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/rJjFqcJf",
                "twitter": "https://twitter.com/HighRollerHC",
                "website": "https://hrhc.io"
            }
        },
        {
            "chainId": 101,
            "address": "vAR6K3QdoC9TFkcgda9v2etCVYSYWXcap2xn8p8x8qW",
            "symbol": "AOTWL",
            "name": "Apes on Tigers WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vAR6K3QdoC9TFkcgda9v2etCVYSYWXcap2xn8p8x8qW/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/ApesonTigers"
            }
        },
        {
            "chainId": 101,
            "address": "4DQzkwRCpvTZxdodNw86wLwxAzu5y2EFB9KEwxboEPCG",
            "symbol": "DBC",
            "name": "DBCrypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/darioe54/dbcrypto/main/logo.png",
            "tags": [
                "idc"
            ]
        },
        {
            "chainId": 101,
            "address": "8x4nE4MNzw3zhpiAB4MBWhXz4iGxNt9Q6Mm3dTdPSRyC",
            "symbol": "BIOB",
            "name": "Bionic Beaver",
            "decimals": 9,
            "logoURI": "https://github.com/starcodez/bionicbeavertoken/raw/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7jyT964jZSEMkPy5itfEh8Jt447zmfo7C1Dsz7oPoMy5",
            "symbol": "KWC",
            "name": "Keep Walking Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hhstanchev/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5pSJDkzXm8iqBGmT2mB1wYuxtzhjuxqdtKRLUx2rfU9H",
            "symbol": "AJC",
            "name": "AJC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JYOOrepository/ajcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4RQzhjy4nDRzLrN71TxQ5KwoYURds5yjt5Fbin1cJ2xk",
            "symbol": "LVCM",
            "name": "Crescent Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Zabb116/CrescentCoin/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AA6LkBM7m5wmhrrTopNfEeMrLupsRDZVQzFSfL2rr5iQ",
            "symbol": "DRIP",
            "name": "0xDRIP Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AA6LkBM7m5wmhrrTopNfEeMrLupsRDZVQzFSfL2rr5iQ/logo.png",
            "tags": [
                "NFT",
                "utility-token",
                "community-token",
                "altcoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/0xdrip",
                "twitter": "https://twitter.com/0x_DRIP"
            }
        },
        {
            "chainId": 101,
            "address": "G3qD5uCnhv9mGjvRLduCDGXRvrH6fi8nJn2Wk9ijXhGZ",
            "symbol": "PAIN",
            "name": "LordPain",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rahulchy960/crypto-/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FbUzTvXeHxPXKpZQHWMvoVXXW6aEhitfoakRttUBBzVx",
            "symbol": "BBX",
            "name": "BlockBux",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WeAreMetaverse/BBX/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "metaverse",
                "culture-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "9861az8m8LiuEeSgsmUaNVzVcq6XGDG6YCj3Jq7MRKHq",
            "symbol": "ASSET",
            "name": "ASSET",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9861az8m8LiuEeSgsmUaNVzVcq6XGDG6YCj3Jq7MRKHq/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/mynn9p6uNw",
                "telegram": "https://t.me/blockassetupdates",
                "twitter": "https://twitter.com/Blockassetco",
                "website": "https://blockasset.co"
            }
        },
        {
            "chainId": 101,
            "address": "5J6C29Rz2m3MfRk4CEwnVTAAiAoii8Vbrvecc6aKRJYv",
            "symbol": "PIKA",
            "name": "Pika Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5J6C29Rz2m3MfRk4CEwnVTAAiAoii8Vbrvecc6aKRJYv/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://perkypikas.com"
            }
        },
        {
            "chainId": 103,
            "address": "CG9mVnKksTV6C1NNGH8Qnz2GZKJN4KAKsMrrN1qdwEhL",
            "symbol": "LHMINT",
            "name": "Loot Heroes' Mint Token",
            "decimals": 0,
            "logoURI": "https://lootheroes.io/lhminttoken.png",
            "extensions": {
                "twitter": "https://twitter.com/lootheroesnft",
                "website": "https://lootheroes.io"
            }
        },
        {
            "chainId": 101,
            "address": "BJP255e79kNzeBkDPJx8Dkgep32hwF56e1UCWKdBCvie",
            "symbol": "H3LL",
            "name": "HELLC0IN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rangersmyth74/hellfire/main/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/yR8YbDWeMf"
            }
        },
        {
            "chainId": 101,
            "address": "AB7nnTXuSzje7HxjPsZJmq5HAFA64dCQiorT7R5P7kvS",
            "symbol": "SPI",
            "name": "Spinner",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/willkilla9/sol/main/logo.png",
            "tags": [
                "NFT",
                "community-token"
            ],
            "extensions": {
                "instagram": "https://instagram.com/nftspinner",
                "twitter": "https://twitter.com/nftspinner",
                "website": "https://nftspinner.io"
            }
        },
        {
            "chainId": 101,
            "address": "ExxPZ3m5VGre7oy9HLDBmAmzRjtG8nUzptsxB8uTYBRF",
            "symbol": "ALB11",
            "name": "ALBILEVEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TherealXmeD/Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "22ViCwd3WGBCjAWBV6tuAvMN22GYJ5X1fpkNNaWfTKUq",
            "symbol": "QCKH",
            "name": "Quickhaven",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mizzamir/Quickhaven-Conquest/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9h8hPsY6YhWK1JQbUJ35xXAkcRTE11YvcPxHKwn24di1",
            "symbol": "MFN",
            "name": "Mindfulness",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/migueloliveros/crypto/main/MFN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HcD6eTvEAGy2E5ZmDm2zwywXWUkaJvjwarLf7rPuG8Mm",
            "symbol": "BREATH",
            "name": "GEORGE FLOYD",
            "decimals": 0,
            "logoURI": "https://arweave.net/39Ctk1TBG-EA5nNuaOG59uy3WBN9MM_S_FgpRkEiSv0",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "george-floyd",
                "black-lives-matter"
            ],
            "extensions": {
                "description": "On May 25, 2020, George Floyd, a 46-year-old black man, was murdered in the U.S city of Minneapolis by Derek Chauvin, a 44-year-old white police officer.Floyd's last words were; Please, the knee in my neck, I can't breathe",
                "imageUrl": "https://arweave.net/9lbB1r5kCNrqRMn3SPtt-_m6fy3MRG0fmlyLt0_NO2U"
            }
        },
        {
            "chainId": 101,
            "address": "BeCPRbP5AxZ9sc46FvPeJVcNpKLKwuMnc3tsJXnMYBTs",
            "symbol": "WYOS",
            "name": "WyoSol",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BeCPRbP5AxZ9sc46FvPeJVcNpKLKwuMnc3tsJXnMYBTs/logo.png",
            "tags": [
                "solana-blockchain",
                "wyoming"
            ]
        },
        {
            "chainId": 101,
            "address": "6z8GUKgcPBMg9P2pZkLtCw3nLnoXm2pyAavcRWEnKRM7",
            "symbol": "TSTTOKEN",
            "name": "TSTTOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6z8GUKgcPBMg9P2pZkLtCw3nLnoXm2pyAavcRWEnKRM7/logo.png"
        },
        {
            "chainId": 101,
            "address": "9z7ZEwCFc4fSYbrZ1JTAPdwLH7pd3h94RhyHFS6SP4c2",
            "symbol": "TGS",
            "name": "The Global Special",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9z7ZEwCFc4fSYbrZ1JTAPdwLH7pd3h94RhyHFS6SP4c2/logo.png",
            "extensions": {
                "website": "http://tgstgs.com",
                "whitepaper": "http://tgstgs.com/_template/s_dooogn_com/web_skin/web_skin_tgstgs/data/The_Global_Special_WP_EN.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "7rkZKH3gF41dj7xNER7Bny2uTFZF3V4hNfM8c6MJVd2V",
            "symbol": "LUGB",
            "name": "LucasGrowsBuck",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MattM7/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CpFE715P5DnDoJj9FbCRcuyHHeTXNdRnvzNkHvq1o23U",
            "symbol": "DARC",
            "name": "DARC Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Konstellation/DARC_token/main/256darctoken.png",
            "tags": [
                "utility-token",
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "darcmatter-coin",
                "description": "Konstellation is a decentralized cross-chain capital markets protocol built on Cosmos. It is aimed to efficiently connect funds and the various components of the asset management industry with investors. Konstellation intends to be the financial services industry hub for Cosmos and other blockchain ecosystems through strategic inter-blockchain communications. The network is powered by DARC tokens, which are required for the Konstellation network’s governance and transactions.",
                "facebook": "https://www.facebook.com/Konstellation.tech/",
                "imageUrl": "https://raw.githubusercontent.com/Konstellation/DARC_token/main/256darctoken.png",
                "linkedin": "https://www.linkedin.com/company/konstellation-tech/",
                "medium": "https://konstellation.medium.com/",
                "telegram": "https://t.me/Konstellation",
                "website": "https://konstellation.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "Fi5NUmnZeKDPS4YDfy6DNL3Y6Pp9drtmzrjyAkDHN1nX",
            "symbol": "PPC",
            "name": "Pherret Private Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fi5NUmnZeKDPS4YDfy6DNL3Y6Pp9drtmzrjyAkDHN1nX/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/ClubPherret",
                "website": "https://pherretclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "BVUd7RNHcLbAt6p98XyY39XEGrjrnWuikbMsdPjddjuH",
            "symbol": "EMPT",
            "name": "Emily Pillow Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kotoraku/crypto/main/emtLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHUWRaNN62HRSkxDh6woJjnzZfQNCm49kd91MQfKYkjC",
            "symbol": "CHRIST",
            "name": "JESUS CHRIST",
            "decimals": 0,
            "logoURI": "https://arweave.net/7QpRGUZ7KDMvzJ3ZtRbbXus714OErFSlLTN0FbSTY2Y",
            "tags": [
                "nft",
                "fractionalized-nft",
                "collectible-token",
                "digital-collection",
                "jesus-christ",
                "bible-on-blockchain"
            ],
            "extensions": {
                "description": "This Non-Fungible Token is made to keep the word of jesus alive and accessible to everyone around the world. EACH NFT is associated with a full copy of the New Testament archived on blockchain - https://arweave.net/Ov5sBcSgKOL0AASs4TyOnwz6ReIJ3MyIX9j90zvfOd8",
                "imageUrl": "https://arweave.net/gD0M3bryfWOUudcTeat924dKTf45nt1PrQwNWOT_xq8"
            }
        },
        {
            "chainId": 101,
            "address": "4MeqAe5QhfHzJGtXiatdUjr5mUrA2NFdeC3xLDQu9WkJ",
            "symbol": "TMCT",
            "name": "Middle Class",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheMiddleClassToken/crypto/main/12345%20token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3jts9kgX29ANZGKHK4Hsj4EyLjQJzLeU7MFg5HNnXJ2A",
            "symbol": "SUPA",
            "name": "SUPADROP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3jts9kgX29ANZGKHK4Hsj4EyLjQJzLeU7MFg5HNnXJ2A/logo.svg"
        },
        {
            "chainId": 101,
            "address": "HNgs8kX46NDveA4HU3n8RLNuKBzUkX13cEHE86rN4v6E",
            "symbol": "GTMO",
            "name": "GetMo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ctorgy/crypto/main/yuh.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CU8sKLJRcp7v7hP75t1bmZmdFGCQNdEoX6ZnSjm7i3X",
            "symbol": "UMUC",
            "name": "UMU Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nmill22/UMUCoin/main/UMUCoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9HybgFrEwawhAEECY52djqf7MHd5BtpJAVn923Nf5Qd2",
            "symbol": "LSCG",
            "name": "Los Chingones",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheGamerCoder121/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HV8D4F4XYiTTn7P9TJ9SaPHqht8qKQxPUAXDRFSdayNw",
            "symbol": "PIOUS",
            "name": "Pious Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PiousCoins/PiousCrypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8NP4GmmPrFFbBC7Hrimjw9cLSx5Vw7i3bGo5RFpt8o8R",
            "symbol": "GMC",
            "name": "Grimeace",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/nktshrd1234/Grimeacecoin/main/8RCI9aEe_400x400.png"
        },
        {
            "chainId": 101,
            "address": "FEiVPB67mabyoMsNhvQuww2YCid7Lwo6c6aKJBfQ7oet",
            "symbol": "BDHK",
            "name": "POTRESS CREW",
            "decimals": 9,
            "logoURI": "https://github.com/gGeorgiev1/crypto/blob/main/Potres-logo-2019.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBWuZDJad2ueB3br5YHkpwksfAxiKXrwRAqdjasqsyYj",
            "symbol": "GNOM",
            "name": "GnomCoin",
            "decimals": 6,
            "logoURI": "https://github.com/onuraladi/assetsgnom/blob/main/gnom.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/CFgTvwDEHf",
                "twitter": "https://twitter.com/GnarlyGnomies",
                "website": "https://gnomiezgen2.fun/"
            }
        },
        {
            "chainId": 103,
            "address": "6z8GUKgcPBMg9P2pZkLtCw3nLnoXm2pyAavcRWEnKRM7",
            "symbol": "TSTTOKEN",
            "name": "TSTTOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6z8GUKgcPBMg9P2pZkLtCw3nLnoXm2pyAavcRWEnKRM7/logo.png"
        },
        {
            "chainId": 101,
            "address": "EJtTcFxg7kQmBkHzp64kpGU5f6J7taoTB2jVpkCDMzUA",
            "symbol": "NNC",
            "name": "Nicenerds Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/squatchydev9000/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7YGyq8FLVWxAi1rF8DpynaSB7BsJs23wYMACuTzR2TdP",
            "symbol": "STK",
            "name": "Sortek",
            "decimals": 0,
            "logoURI": "https://github.com/sskelton21/SortekLogo/blob/main/IMG_0687.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4xZUVXUpcKNQXsnCoTSWXrc8PGjtjqxQHF3EcJDXXcjw",
            "symbol": "STGC",
            "name": "Spotthegeek Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spotthegeek/crypto-stgc/main/stgc-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qGCk3HEH8uEcHkQMNfoFjX1MnxDqjCUSqtB4C9KZ2BD",
            "symbol": "SOLAK",
            "name": "Solavik",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Aviksaikat/Solavic-token-SOLAK-/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E5xCoBy9pCNjtZHGjD57j498YidLh41sihnu6rSghJkr",
            "symbol": "TTCL",
            "name": "TTCL Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/tongtongchain/token-list/main/assets/mainnet/E5xCoBy9pCNjtZHGjD57j498YidLh41sihnu6rSghJkr/ttcllogo.png",
            "tags": [
                "tongtongcoin",
                "tongtongchain",
                "dex",
                "finance"
            ]
        },
        {
            "chainId": 101,
            "address": "Toamuce7zPGWhf8dYgMG92QDtCRxXWqGrvmFfZPiEjE",
            "symbol": "CSMT",
            "name": "CS Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jonathanemorales/logo/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "5pnEnSsky1L2xrd4Nqj4oiKgsDLqPHstnkVP4XwKicmX",
            "symbol": "Zues",
            "name": "Carlos Verbelen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Atrahasis369/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EKDaZbPnJRc1ujbFFiQEZoBikXLyx1TFBBRnED98KxC1",
            "symbol": "CRG",
            "name": "CraigCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/McGowanC/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7e7RaP8so1xme93Lb45smVCaXT6yyEh6Sp4QFn3ecbjv",
            "symbol": "MIT",
            "name": "MITcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thompsonkylaw/crypto/main/MITcoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EP5vvzVMKRygpzHouyKuEXRKrJAeYXs7rgr98LoUAbBC",
            "symbol": "YAOI",
            "name": "Yaoi Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RicktaScale/crypto/main/ukeLogo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6vG3dVdreJDXATexCDxJcT7yFyjhZiafzBWTZx6BL965",
            "symbol": "SRC0",
            "name": "SRC Demo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanaraffleclub/src-token/main/0001%20demo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "To1az33ZSY6GUueytiXUysBputPeU6zQdz6zc9HsCLE",
            "symbol": "LCWL",
            "name": "LoadedCrates WLs1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/setaf007/lc-token-cli/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "Yoku8bNBWX6riJpsztLWjmyxAwCEs8ytKxoQAEsZNkA",
            "symbol": "YOKU",
            "name": "Yoku Folks",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Yoku8bNBWX6riJpsztLWjmyxAwCEs8ytKxoQAEsZNkA/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/yokufolk"
            }
        },
        {
            "chainId": 101,
            "address": "35PTcAHcQYrRS5QW8fgHx163rWV9ffXU9Y66Hio853P3",
            "symbol": "VMNT",
            "name": "Syndicate Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/VMNT/main/assets/VMNT.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BXN6pAK7BM7hXQJqyLPeGF8q5aohy92gV3G62BKRBjZX",
            "symbol": "CC",
            "name": "CamCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ctorgy/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3XBgEzdisbD9ypcRFE1UNS5AeSHbzS2qXb9Ta6aM1pFZ",
            "symbol": "SOOP",
            "name": "Sooper",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LennySOOP/Token/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5irqyvD7Cc6vAVvUhUYn7WXVJtdJ1YSEknjPBob2TMM4",
            "symbol": "SUPAD",
            "name": "SUPADROPD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3jts9kgX29ANZGKHK4Hsj4EyLjQJzLeU7MFg5HNnXJ2A/logo.svg"
        },
        {
            "chainId": 101,
            "address": "Enz9Gcr32fZJ7ZaZ2NpQL9o3eNG2aiUKtrJ1iuX2Agqp",
            "symbol": "CVAL",
            "name": "Carnival Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rylaizy/token-img/main/logo_coin.png"
        },
        {
            "chainId": 101,
            "address": "CaV7NzXykiMwUfDk7JQFYE4VK6RtethJin8my2Rh7Qfc",
            "symbol": "LOC",
            "name": "Lion Rocker",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lionrocker/crypto/main/LionRockerlogo-B.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "D8wmkqGKpJvai2kScsFvK1n6uZMDicHKW6Zk6S53MRqU",
            "symbol": "FLRP",
            "name": "Moons",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/StarboyB/cryptologos/main/moons.png",
            "tags": [
                "utility-token",
                "metaverse",
                "NFT-Gaming",
                "game-token",
                "game-currency",
                "Gaming"
            ],
            "extensions": {
                "description": "FlatlineRP is a Stylized GTA V roleplay server where players can play in a community based city with their own virtual characters.",
                "discord": "https://www.discord.io/flatlinerp",
                "facebook": "https://www.facebook.com/Flatline-RP-102075495648442",
                "instagram": "https://www.instagram.com/flatlinerp/",
                "reddit": "https://www.reddit.com/r/flatline_rp/",
                "twitter": "https://twitter.com/flatlinerp"
            }
        },
        {
            "chainId": 101,
            "address": "4AWoo3NXpdepcxjBSMya4YQmK28fFzYebQTBEC3aB69m",
            "symbol": "MUNJAL",
            "name": "Munjal Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/munjalchetan/logo-solana/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Pti79GJaprhqqkhSWxdyhE6MHKTLTEKfcyfMXiZFp5M",
            "symbol": "GRAFO",
            "name": "Graphic Design",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SegFun01/EIC/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AHM5dtyMJZzTs8ZscL17VWtKRdvt9YKC1sM75cCDUcJe",
            "symbol": "BDHK",
            "name": "POTRESS CREW FREERIDERS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/geshecaMTB/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7dSy7gPTJcV1YECvpE8Zu8eJkY4ENCVwvjnsPtXwMHce",
            "symbol": "ADVENTURE",
            "name": "Adventure",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/adventure-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HZnDaTEyKnZozKR1JFro3bZFuqJ4tEqJxoxCBTNoHDMu",
            "symbol": "A",
            "name": "A",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/a-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "o1Ec6bs7kF3ps9SaChwzAR74GHgmPpHmdEAyXFCBS9w",
            "symbol": "HEAL",
            "name": "Health",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/heal-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "885utG7mvmNbSADdFNoMteB1RMvvHrkiRBLbdr6ShyGi",
            "symbol": "BOOB",
            "name": "Boob",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/boob-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "421tFhs4tL4ohU3wf2cmi51kcpfXD1ZQKK73buLXbi3b",
            "symbol": "SLEEP",
            "name": "Sleep",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/sleep-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NaFJTgvemQFfTTGAq2PR1uBny3NENWMur5k6eBsG5ii",
            "symbol": "NAGA",
            "name": "Naga Kingdom",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NaFJTgvemQFfTTGAq2PR1uBny3NENWMur5k6eBsG5ii/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "io-game",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "The most entertaining .io game on Solana",
                "discord": "https://discord.gg/nagakingdom",
                "facebook": "https://www.facebook.com/NagaKingdomGame",
                "medium": "https://medium.com/@NagaKingdom",
                "reddit": "https://www.reddit.com/r/nagakingdom",
                "telegram": "https://t.me/nagakingdom",
                "twitter": "https://twitter.com/NagaKingdom",
                "website": "https://naga.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "Ma4dse7fmzXLQYymNsDDjq6VgRXtEFTJw1CvmRrBoKN",
            "symbol": "MAGA",
            "name": "Magic Eggs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ma4dse7fmzXLQYymNsDDjq6VgRXtEFTJw1CvmRrBoKN/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "io-game",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "The most entertaining .io game on Solana",
                "discord": "https://discord.gg/nagakingdom",
                "facebook": "https://www.facebook.com/NagaKingdomGame",
                "medium": "https://medium.com/@NagaKingdom",
                "reddit": "https://www.reddit.com/r/nagakingdom",
                "telegram": "https://t.me/nagakingdom",
                "twitter": "https://twitter.com/NagaKingdom",
                "website": "https://naga.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "8yHB4Y8caxRvpKhpDSbvFGMca2ZH2nzqtwEs86b6fryy",
            "symbol": "PCI",
            "name": "Poly Capital Investment",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PolyPusCrypto/crypto/main/logo.png",
            "tags": [
                "investing-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cd8nLV1oTVsnnk6iJZ5dASTCJQZF4B52SgaE6NJKt6aB",
            "symbol": "MFT",
            "name": "MetaFruits",
            "decimals": 0,
            "logoURI": "https://github.com/MetaFruitsCoin/crypto/blob/main/Metafruits_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CShm4EA7oi15pYYiP2XpCdsqMKZFqqZpQ645CgL3AdLV",
            "symbol": "CHE",
            "name": "cheProsts",
            "decimals": 0,
            "logoURI": "https://github.com/bogdanvoicu72/logoche/blob/main/logo-che-good.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CRn4h1ocNiu3kJAkgGievWvMPzDRXftqimiTVrkTpPxt",
            "symbol": "BGPA",
            "name": "Bedia Coin Patron",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sonfero/crypto/main/BGPA_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "xsvtzXdo6tMD59k6NnYmRTi4ZduEoUSvSb6Keny73sr",
            "symbol": "xSVT",
            "name": "Staked Solvent",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xsvtzXdo6tMD59k6NnYmRTi4ZduEoUSvSb6Keny73sr/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "iEWFfqPmrLKcYh8kTigpNRVpqKMCqFq7AxpLDPyRSZN",
            "symbol": "AOSMT",
            "name": "Apes on Space Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zenturing/token-list/42c9ce3f689571dcb1136ec13183c0f2e06b1c23/assets/mainnet/iEWFfqPmrLKcYh8kTigpNRVpqKMCqFq7AxpLDPyRSZN/aos.png",
            "tags": [
                "mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4QUGN7JBDHbxZdV9EAwQCcfEHLAoybv572y8mLSozi9G",
            "symbol": "STO",
            "name": "Consentium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/consentium-finance/assets/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/consentium-finance",
                "telegram": "https://t.me/consentium_fi",
                "twitter": "https://twitter.com/consentium_fi",
                "website": "https://consentium.fi"
            }
        },
        {
            "chainId": 101,
            "address": "HyYztE5BiLWoiWca1YFeTaoGfHJ1eWdnCca1B3oaJP1J",
            "symbol": "DFS",
            "name": "Doggy Fun Space",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/abrahamsmit/assets/1c455cbc3f6b7137335db048c4bf0b288b7fc0c4/logo.png",
            "tags": [
                "meme-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHre8EVZ37hpwdiR1Dmnm5LN3VGeQZbD6DMbDDUpZ8xD",
            "symbol": "PCFL",
            "name": "Peacefulness",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/pcfl-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4p2H86arHQLQ2pzVCksXBpUBtn4iWnkcH6kd4Xva9SFn",
            "symbol": "COMPASSION",
            "name": "Compassion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/compassion-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CQ9M3t3pYaf34iZ5V4suUPP7R6YEvdykowdxQzEmibce",
            "symbol": "TRUST",
            "name": "Trust",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/trust-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7aAQDS1p6cKDe5W7MJwHAaDQTec2FETbWj87P4jK6xik",
            "symbol": "LCVIP",
            "name": "Lost Cat FUD Club VIP List",
            "decimals": 0,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmQkFU34BMBVXU5ZTxfXKGgjuyW9PGLhiDjjf2iVTgNz2S",
            "tags": [
                "nft-presale"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lostcatfc",
                "website": "https://lostcatfc.com"
            }
        },
        {
            "chainId": 101,
            "address": "CB543LMSL99Z6yMz6o1YWdp9CF8HMkfXPoKHvchjoc8",
            "symbol": "WTB",
            "name": "webeMutt",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CB543LMSL99Z6yMz6o1YWdp9CF8HMkfXPoKHvchjoc8/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://webeteer.com"
            }
        },
        {
            "chainId": 101,
            "address": "ELFvPMFCTeHy2gGfnxhHjeRBBCfKZs7zaFjaF7vDZPrT",
            "symbol": "JMT",
            "name": "Just Monitoring",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Prime3vil/Just-Monitoring-Token/main/logo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "imbaVQ5BUwJ1yo3RJYVorHE7aXh4UFWAicb6yX6XBEA",
            "symbol": "IMBA",
            "name": "The Lion Cats",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nf0x/the-lion-cats-logo/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/thelioncats",
                "twitter": "https://twitter.com/TheLionCatsNFT",
                "website": "https://thelioncats.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GUvq7jCQq7arzs3aQHNHoFkAxQwPY8gsn2D5pfsJ7vPN",
            "symbol": "CorsairCoin",
            "name": "CRCNTKN",
            "decimals": 0,
            "logoURI": "https://terrestrial.cards/CorsairCoinToken.png",
            "tags": [
                "social-token",
                "investing-token",
                "game-token",
                "io-game",
                "GameFi",
                "Gaming"
            ]
        },
        {
            "chainId": 101,
            "address": "DAumagnEA3co6SG6NH7Fsh9CgvppLP98viE43ECNPv9m",
            "symbol": "JDC",
            "name": "Just Duct Cleaning",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Prime3vil/Just-Duct-Cleaning-Token/main/logo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9UyfZHMQy87TbRexvdKi3kvJdCerWfENEz1x3jyf5D6Z",
            "symbol": "DUMB",
            "name": "Dumb Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dumbtokenorg/dumbtoken/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://dumb.digital/discord",
                "website": "https://dumb.digital"
            }
        },
        {
            "chainId": 101,
            "address": "PartumKFrDhAkoGaQw3o1eLs1MXmpBjKfU5eMpzwyxW",
            "symbol": "PAR",
            "name": "Partum",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PartumKFrDhAkoGaQw3o1eLs1MXmpBjKfU5eMpzwyxW/logo.png",
            "tags": [
                "utility-token",
                "nfts",
                "governance-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ynCfkSMfwK",
                "facebook": "https://www.facebook.com/PartumNFT/",
                "github": "https://github.com/PartumNFT/",
                "instagram": "https://www.instagram.com/partumnft/",
                "telegramAnnouncements": "https://t.me/PartumRiseOfAGladiator",
                "twitter": "https://twitter.com/PartumNFT",
                "website": "https://riseofagladiator.com/",
                "whitepaper": "https://whitepaper.riseofagladiator.com/"
            }
        },
        {
            "chainId": 101,
            "address": "51rGXakBCcBoti5TSjKZ5kYtaiFfu8wnxjfEgPZRsdMD",
            "symbol": "FFSH",
            "name": "Fire FISH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Breacker/token-list/main/assets/mainnet/51rGXakBCcBoti5TSjKZ5kYtaiFfu8wnxjfEgPZRsdMD/Fire_FISH.png",
            "tags": [
                "meme",
                "Discret_project",
                "fish_token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Fire-FISH-103087682288324"
            }
        },
        {
            "chainId": 101,
            "address": "FF41MLzeQnqr7zQzgWVev9VoSLvVYC2UxvYeKiqKCfrv",
            "symbol": "WAGG",
            "name": "WaggCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jw4gg/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B97yZiSWsRGsbYGa1tCPZzL3K6ggM36pQjzCHg1STsym",
            "symbol": "XMB",
            "name": "XMBCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/XMBCreator/crypto/main/xmbcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CRmFZnjVrKgNESqxKLpBmuuakZzYqWKR2cwTQCDkvQbS",
            "symbol": "LENS",
            "name": "Lens Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fpetru104/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BDNRJZ6MA3YRhHcewYMjRDEc7oWQCxHknXU98wwTsSxu",
            "symbol": "OOO",
            "name": "Particles",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/mCP5vxP.jpg",
            "tags": [
                "particles",
                "one-of-ones",
                "bridgesplit-fraction",
                "curated-index"
            ]
        },
        {
            "chainId": 101,
            "address": "95ZWS7g7qTTbgkvuxuj54NK5hJ4pRz94CCgURAhJanEy",
            "symbol": "SEC",
            "name": "Security",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/sec-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "38UngbbBnGnxB2ZaYJw5yRD8axh9XNjWPWuU6UjrCjjJ",
            "symbol": "CLASS",
            "name": "Class",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/class-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7cL2XirnaDyeb5aDNZSsxQdV3cmUDyRrw1JfqHLMf1fJ",
            "symbol": "PSHNP",
            "name": "Pushin P",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/simpgawd/token/main/letter_p_PNG37.png",
            "tags": [
                "meme",
                "Discret_project",
                "fish_token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Fire-FISH-103087682288324"
            }
        },
        {
            "chainId": 101,
            "address": "2gbUoS6obaqer6waiYpJQL8QQFnoqx9k8iaJexVprKwQ",
            "symbol": "LUVV",
            "name": "Luvv Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/coachjacob1111/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8aE9jJVosvUxNMev7GHg8zCXEtd4sSVC7ZCc4sjrc68e",
            "symbol": "VCC",
            "name": "Vice City Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8aE9jJVosvUxNMev7GHg8zCXEtd4sSVC7ZCc4sjrc68e/logo.png"
        },
        {
            "chainId": 101,
            "address": "9xo19RejvwWRPAfGDJ93EoNGxEwmUtZNoMfcSjfKqouq",
            "symbol": "KnC",
            "name": "KunaiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alfredodirksen130/Crypto/main/Final.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu",
            "symbol": "NFD",
            "name": "Feisty Doge NFT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu/logo.svg",
            "tags": [
                "ethereum",
                "wrapped",
                "wormhole",
                "fractionalized-nft"
            ],
            "extensions": {
                "address": "0xdfdb7f72c1f195c5951a234e8db9806eb0635346",
                "assetContract": "https://etherscan.io/address/0xdfdb7f72c1f195c5951a234e8db9806eb0635346",
                "bridgeContract": "https://etherscan.io/address/0x3ee18B2214AFF97000D974cf647E7C347E8fa585",
                "coingeckoId": "feisty-doge-nft",
                "description": "The first fractionalized doge NFT, backed by one of the most culturally significant digital assets in all of crypto",
                "discord": "https://discord.gg/JNKbZur9gd",
                "twitter": "https://twitter.com/feistydogenfd",
                "website": "https://www.feistydoge.art/"
            }
        },
        {
            "chainId": 101,
            "address": "CX3h9eBD9fjaS1TNF51BgsdCNeS8daxbk4c9rF5GHtjj",
            "symbol": "SPDLT",
            "name": "Speedalot Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sirspeedalot/soltokenREAL/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FQuUpEgGHMwCLk7gT9B3mSSZQB5Bc3xG8QAdREwxxsmn",
            "symbol": "GGC",
            "name": "GangGangCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WombatOverlord/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "STRMPHakTLMzvWoyWiQW4HxLa3eqUVhLWMbVX9GTc11",
            "symbol": "STRM",
            "name": "Streamflow",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/STRMPHakTLMzvWoyWiQW4HxLa3eqUVhLWMbVX9GTc11/logo.png",
            "extensions": {
                "discord": "https://discord.gg/SDZpryKsgd",
                "medium": "https://streamflow.medium.com",
                "telegram": "https://t.me/streamflow_fi",
                "twitter": "https://twitter.com/streamflow_fi",
                "website": "https://streamflow.finance"
            }
        },
        {
            "chainId": 101,
            "address": "DP7rDueWUTYAjeP9cs7o4XfBBL9WZYCDbeA6Y9UUaG99",
            "symbol": "YAMA",
            "name": "YAMA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/casmith109/yama/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9dACzqUP3V8FSpn818MLhvu3j7KgzjSTmstGEH53ezLm",
            "symbol": "SUCO",
            "name": "SUCOIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9dACzqUP3V8FSpn818MLhvu3j7KgzjSTmstGEH53ezLm/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "Play-to-earn",
                "Metaverse"
            ],
            "extensions": {
                "description": "SUCOIN is a unique tradable crypto token which serves as perfect medium for easy exchange, purchase, and sale of virtual assets in a rapidly growing digital Sol Unicorns ecosystem. It can be earned by staking NFTs and through rewards by performing various tasks in an upcoming metaverse game.",
                "discord": "https://discord.gg/solunicorns",
                "instagram": "https://www.instagram.com/solunicorns3d/",
                "telegram": "https://t.me/solunicorns",
                "twitter": "https://twitter.com/solunicorns3d",
                "website": "https://solunicorns.io"
            }
        },
        {
            "chainId": 101,
            "address": "6Fw9B7EAyET1ohFsutoU72mUpENvCMBCbHJQRdpJ8jcY",
            "symbol": "GPC",
            "name": "GaplaCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Fw9B7EAyET1ohFsutoU72mUpENvCMBCbHJQRdpJ8jcY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://my.gaplagov.org/"
            }
        },
        {
            "chainId": 101,
            "address": "6LtdceheUFnTm7AKPLuLopvn2UCwpy722qFFi7soE2oK",
            "symbol": "CSPA",
            "name": "Cat Spirit Animal",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stephenjiang12714/Solana-Token/main/Cat%20Spirit%20Animal.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "87hhyGRqUET8ptS4KLFucCSZyQeUNpEY9TgLA32VZJRs",
            "symbol": "MELO",
            "name": "MILANO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kambobo/crypto/main/milanooo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DdLynXMq4hURnMMNNVt2CRi1WbnbV2NmuEb8skhWZUAh",
            "symbol": "DRYGN",
            "name": "Draygon Guarantee Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/draygontoken/main/assets/guaranteemint.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sYQeqp87wBhQyZMo2TfCVNrFiX4HrLAWUkr2rmqbRoN",
            "symbol": "DRYGNWL",
            "name": "Draygon WL FCFS Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/draygontoken/main/assets/wlfcfs.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CGBxTQNXMmmCXAPm4dC9MQJ1q8JDDxApwFwW188SdtBu",
            "symbol": "TiP",
            "name": "CREATiP",
            "decimals": 2,
            "logoURI": "https://arweave.net/bZGwLPTI6aay84W9TF3R190dezSGf8jpC7VBTXljd2g",
            "tags": [
                "community-token",
                "fund-your-creator",
                "tip-token"
            ],
            "extensions": {
                "description": "CREATiP is a decentralaized funding platform, designed to support independent creators worldwide",
                "serumV3Usdc": "4kAzx9Raaw4ZThyN7PTVnc82YP2gLu6xNmH4jHWWSR7N",
                "website": "https://www.creatip.online/"
            }
        },
        {
            "chainId": 103,
            "address": "8GTpf5LVVY8WZZfm9EnPzS2NrnJFqhzJGALDf71dpwFy",
            "symbol": "WORKS",
            "name": "WorksToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8GTpf5LVVY8WZZfm9EnPzS2NrnJFqhzJGALDf71dpwFy/logo.png"
        },
        {
            "chainId": 101,
            "address": "E7FusqnjCWvTK2TQhqkwHPYEyg3zah5mSzz4DHHhk7RA",
            "symbol": "SWL",
            "name": "Solitarian WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E7FusqnjCWvTK2TQhqkwHPYEyg3zah5mSzz4DHHhk7RA/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6pT6udhxrj3WbwZNVcV88r49eAQDzGB2KvGqxLVdi6U8",
            "symbol": "RHE",
            "name": "RICHE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RicheCorp/CryptoToken/main/Logo22.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkFiLryp7uj4SqB6HvXGRMGguZEC1p3yXTuo7TtrofqF",
            "symbol": "TABC",
            "name": "True Aid Bangladesh Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tmtns/TABC/main/TrueAidBangladesh%20Coin%20logo.png",
            "tags": [
                "social-token",
                "bangladesh"
            ],
            "extensions": {
                "description": "True Aid Bangladesh Coin",
                "twitter": "https://twitter.com/TrueAidBD"
            }
        },
        {
            "chainId": 101,
            "address": "4fPdQ19Y3UxnvWyAXAdhCpsseSjQ3RmyPqG6d2HqPxtg",
            "symbol": "MOR",
            "name": "More",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/mor-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9hgs7innQNcqD7TVvRt74i4r3vX7zhs7gm3V121CfZsx",
            "symbol": "LES",
            "name": "Less",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/les-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AYbqRoZz7J5q6vKbF8WgiphrszpCNdQW2t8gtGkqGzeb",
            "symbol": "CHRM",
            "name": "Charm",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/chrm-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4nYLgH7QhJNWVMXbcjyECD8ThhyXT98zDCcvPmJPjjeM",
            "symbol": "POOF",
            "name": "Magic",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/poof-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AHRuDpG1yHcyJN6XeJ8s2izzGM9eVcvkz8cVWcfy1sm1",
            "symbol": "LGNC",
            "name": "Elegance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/lgnc-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AYFy612QPJ3C5GjTd142XnNFTve8HmDTy9Ps9x4g1X2Y",
            "symbol": "VLTN",
            "name": "Valentine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/vltn-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GAxouwwpiB1BYi6uBhpowBhGhxvcyDmy8iQFCCSCivS7",
            "symbol": "GFT",
            "name": "Gift",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/gft-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BKPJsy2uSiJTimAKwpTiKGMr1JxH2b9ZPQbJNSmE9NR8",
            "symbol": "AWD",
            "name": "Award",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/awd-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BNjUKCGNdjVcD69d8FN3UyYbJZTSLYhce1RAkk1zbePA",
            "symbol": "MLOR",
            "name": "Mazalore",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Madara25M/Crypto/main/logo.png",
            "tags": [
                "ultility-token",
                "game-token",
                "community-token",
                "metaverse-token",
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "fjWssw19SJvBzkRk8r5RRik6ZTkMTgg4YSpYp3MKEDw",
            "symbol": "SEX",
            "name": "Sex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Sex.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "sex",
                "xxx"
            ]
        },
        {
            "chainId": 101,
            "address": "7TX7GAJEZ2DG46cRRvZLgXhmAow4ojDEcg3SPCQCqFiG",
            "symbol": "SHADOWFAX",
            "name": "Shadowfax",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shadowfax92/token-list/fc7dc0b8a7a575af838cfce9a72003756bf7efde/assets/mainnet/7TX7GAJEZ2DG46cRRvZLgXhmAow4ojDEcg3SPCQCqFiG/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Shadowfax",
                "website": "https://shadowfax.app"
            }
        },
        {
            "chainId": 101,
            "address": "6btA3WkxvPsw7H8R1LF5c7W9BDrHubBE6FL8nprMFRT3",
            "symbol": "RKTK",
            "name": "Rick Rick Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RicktaScale89/crypto/main/rickLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJUH9GJLaMSLV1E7B3SQLCy9eCfyr6zsrwGcpS2MkqR1",
            "symbol": "swimUSD",
            "name": "Swim Hexapool LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BJUH9GJLaMSLV1E7B3SQLCy9eCfyr6zsrwGcpS2MkqR1/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "medium": "https://medium.com/@swimprotocol",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "ErbJzzRAEM8cVRMXHTftVDFbUVM7TiFWSfaZx4zQRJhS",
            "symbol": "NFTABSWL",
            "name": "NFTABS Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jenvillaganas/crypto/main/assets/ErbJzzRAEM8cVRMXHTftVDFbUVM7TiFWSfaZx4zQRJhS/logo.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/nftabs",
                "twitter": "https://twitter.com/nonfungibletabs",
                "website": "https://www.nftabs.io"
            }
        },
        {
            "chainId": 101,
            "address": "765R1rpPGVZKKJZPevTp5b2dTAHJZNX4feTiHDEqj7JV",
            "symbol": "$wheat",
            "name": "BCBY wheat",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/765R1rpPGVZKKJZPevTp5b2dTAHJZNX4feTiHDEqj7JV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.bcbarnyard.info/"
            }
        },
        {
            "chainId": 101,
            "address": "QLtZHvXyk2cUTT4DPKazTjMZ5DLJhQj8zjCSJWqNH4q",
            "symbol": "ZFRC",
            "name": "ZefranCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GabrielFRANCEZ/crypto-ZFRN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5J2LCgFwz74sC9cwxYMjZDXSL7VDnXU9rUA8NE7pdkhy",
            "symbol": "VIN",
            "name": "VIN Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/vineelsai26/crypto/main/5J2LCgFwz74sC9cwxYMjZDXSL7VDnXU9rUA8NE7pdkhy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9D3dzDFYSpaqgVRCUcnWYjj3apJCAPBY4QEB1Wk78BkL",
            "symbol": "AMT",
            "name": "Amethyst",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/Amethyst/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9xYuVh6h2WPNorGiMP6AfYfewVbvm4fgGDj4bywKRoss",
            "symbol": "TKS",
            "name": "TOKIO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xYuVh6h2WPNorGiMP6AfYfewVbvm4fgGDj4bywKRoss/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Kmb13yPMEfeg8DMPJAsxcuGNJZG7KLK2uL5vPamLMPj",
            "symbol": "Cypher",
            "name": "CYPH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Kmb13yPMEfeg8DMPJAsxcuGNJZG7KLK2uL5vPamLMPj/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9cjgHXpgMhYHQ4oU2gKiWzVtKsj4xtv7zf8LN8qZrTzC",
            "symbol": "LIO",
            "name": "Lio Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/erik-la/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9C7ipMbr5b2YhgGzFy1cxUotnCHcPhbp6wdQWD4EeggA",
            "symbol": "HIST",
            "name": "Historians WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9C7ipMbr5b2YhgGzFy1cxUotnCHcPhbp6wdQWD4EeggA/logo.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "description": "WL Mint Token for Boopieveres: Historians",
                "discord": "https://discord.gg/boopie",
                "twitter": "https://twitter.com/boopieverse",
                "website": "https://boopieverse.com/boopie-historians"
            }
        },
        {
            "chainId": 101,
            "address": "FtzDsgh8enWNBr1RaLngS7FoCVg8y2eM8dtHpp6L3E82",
            "symbol": "YOL",
            "name": "WAYSON",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yol61/cyrpto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BUMxQPidV5oAyU2qbGHn7n5efG5GT3zCBRZ626wQqhAV",
            "symbol": "BUM",
            "name": "Space Bums Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUMxQPidV5oAyU2qbGHn7n5efG5GT3zCBRZ626wQqhAV/bum-token.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "game-token",
                "meme-token",
                "metaverse-token",
                "nft-token",
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/Qe59g3Vun2",
                "twitter": "https://twitter.com/space_bums",
                "website": "https://spacebums.io"
            }
        },
        {
            "chainId": 101,
            "address": "GASxcJbxFkhQA7WAhvCodwB9GxZRujpPUwpyp7KqbZog",
            "symbol": "BUMGAS",
            "name": "Space Bums Fuel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GASxcJbxFkhQA7WAhvCodwB9GxZRujpPUwpyp7KqbZog/bum-gas-token.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "game-token",
                "meme-token",
                "metaverse-token",
                "nft-token",
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/Qe59g3Vun2",
                "twitter": "https://twitter.com/space_bums",
                "website": "https://spacebums.io"
            }
        },
        {
            "chainId": 101,
            "address": "HARDxYqxDCdj8sPr5fFtuCtAw1GHUWXKkLsKnSzJAPfv",
            "symbol": "HARDBUM",
            "name": "Space Bums Metal",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HARDxYqxDCdj8sPr5fFtuCtAw1GHUWXKkLsKnSzJAPfv/hard-bum-token.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "game-token",
                "meme-token",
                "metaverse-token",
                "nft-token",
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/Qe59g3Vun2",
                "twitter": "https://twitter.com/space_bums",
                "website": "https://spacebums.io"
            }
        },
        {
            "chainId": 101,
            "address": "AV4uKFZXqkqe15CaksCPQsndeVjydAyBYvv2qLvWjMhy",
            "symbol": "KGG",
            "name": "Knowledge Gamers Guild",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AV4uKFZXqkqe15CaksCPQsndeVjydAyBYvv2qLvWjMhy/logo.png"
        },
        {
            "chainId": 101,
            "address": "5Trek8ULqkerReUEKJ6h9SAwQtzrq4qcvfM9gUdgHubs",
            "symbol": "CAR",
            "name": "Car",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Car.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "cars",
                "car"
            ]
        },
        {
            "chainId": 101,
            "address": "AjWiUP3kFrShTTrQejvxxBaToB3GZdtvsmxxTvCz6xFM",
            "symbol": "CARS",
            "name": "Cars",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Cars.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "cars"
            ]
        },
        {
            "chainId": 101,
            "address": "6yNgVg6G9JPZWtDMCtHSQb1g26q2VRP8LBG93dUXouGa",
            "symbol": "JWC",
            "name": "JWcraft",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheRealSmartiesG/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EaW33x381UER8ea72aaETaZRP3TQuPheAqoEnazgvkVS",
            "symbol": "BEST",
            "name": "BestOfSol",
            "decimals": 2,
            "logoURI": "https://arweave.net/QGMtPDCZB3X2S-2m8x_DZi3q6BMIGxvYdyUhrmRSM10",
            "tags": [
                "bestofsol",
                "curatorsol",
                "bridgesplit-fraction",
                "curated-index"
            ]
        },
        {
            "chainId": 101,
            "address": "GP4NH4i11nPktMsZPPPA2QDnfmdTUqEDzGzGTdY3WFHF",
            "symbol": "MFC",
            "name": "MetaFruit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MetaFruit-Token/crypto/main/logo.png",
            "tags": [
                "social-token",
                "metafruit",
                "utility-token",
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "6dbgnU1Ua3Tf6L4CdxGzq1gxPdH2iB23jVfhQXFUBLCN",
            "symbol": "$USELESS",
            "name": "USELESS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/setoKaibalol/pictures/main/pic/NGMI.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "jPdS9RQGtS2MsieiiwNoZmGjP4REZmvy1PxEatBjTSk",
            "symbol": "ERC",
            "name": "ERC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GeorgeGento/ERC/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EuVQy8s21rBgp6yWPAu1qJBksB3WLdtGjLv1qPA6Pk9W",
            "symbol": "WOSV",
            "name": "WOS VIP CLUB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EuVQy8s21rBgp6yWPAu1qJBksB3WLdtGjLv1qPA6Pk9W/logo.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/vhjPC4xzSE",
                "instagram": "https://www.instagram.com/wolfofsol/",
                "twitter": "https://twitter.com/WolfOfSol",
                "website": "https://www.wolfofsol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4m2Gi4BwwGqAtGh2zr6xNZTSSJ5hEGVdNedJ7D3B4UQN",
            "symbol": "RZT",
            "name": "Rizzotti Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rizzottididier/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8NyHGgEyf5djAFeMhTukwLYEBKEWkrzJ742QmtNLiUmq",
            "symbol": "CHRLI",
            "name": "CharlieCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CharlesKlug/Crypto-Image-3/main/CharlieCoin5.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "5Ns2m2oBWyj7Kz4VgGgenHZA5dUTJ224AbtjUjaDB651",
            "symbol": "WOOD",
            "name": "WOOD - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/5Ns2m2oBWyj7Kz4VgGgenHZA5dUTJ224AbtjUjaDB651/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 102,
            "address": "2NzAWwLj8GcW3wT9LHSdsyeMTMPCdNp23EAg2YwrLjCz",
            "symbol": "STONE",
            "name": "STONE - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/2NzAWwLj8GcW3wT9LHSdsyeMTMPCdNp23EAg2YwrLjCz/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 102,
            "address": "ExKEMV2Z9dSHdRg6AkcFMXLPUqzta7zcyK2UQk3a4soy",
            "symbol": "IRON",
            "name": "IRON - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/ExKEMV2Z9dSHdRg6AkcFMXLPUqzta7zcyK2UQk3a4soy/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2BG5Db61mF6MLpT5YEuJZSfeMkqBaMRGHygzriT5cPam",
            "symbol": "BBP",
            "name": "Blueberry Pi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Luis-Gehrmann/blueberry_pi/main/donut-g466161830_1280.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HAfTjdSjZiquZiAkmsYBmcFR5NM7cP8HtMqjQRk8eVTX",
            "symbol": "ICON",
            "name": "ICON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAfTjdSjZiquZiAkmsYBmcFR5NM7cP8HtMqjQRk8eVTX/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/IconicApeClub",
                "website": "https://iconicapeclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "DBamvBGp7KNkp7MXyUEzN6cG71X6XxQamjBnQz6zUTTF",
            "symbol": "TOBY",
            "name": "TobyCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DanielKDevine/CryptoToby/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SGWxXHYJuQmx2123zbUj3uA3Qi9Ehf56Jr69hbAJGsc",
            "symbol": "SGW",
            "name": "Solana Ghoests Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SGWxXHYJuQmx2123zbUj3uA3Qi9Ehf56Jr69hbAJGsc/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/solanaghoests"
            }
        },
        {
            "chainId": 101,
            "address": "CpyfVs4mkJyqRrkrbo2W7htRunnh41WSNiBSBU8HNwTG",
            "symbol": "WEX",
            "name": "WexCoin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/coreycullen00/WexCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "WLKyvhnYTrgHKawvjMdQQCzh3Q1rDqDV5bqiCJKLwqP",
            "symbol": "KART",
            "name": "Kart WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLKyvhnYTrgHKawvjMdQQCzh3Q1rDqDV5bqiCJKLwqP/logo.png"
        },
        {
            "chainId": 101,
            "address": "AiUQabNKfJtpnyk7u3FbaDzqLFy4Ay3gAF784aPELJyc",
            "symbol": "Llamas",
            "name": "LlamasEmpire",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AiUQabNKfJtpnyk7u3FbaDzqLFy4Ay3gAF784aPELJyc/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "74P3Dt5FY1Mg1ypmaAeGcrxMpTxERmkJhQsmcYG38Uj8",
            "symbol": "Crocs",
            "name": "ChosenCroc",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/74P3Dt5FY1Mg1ypmaAeGcrxMpTxERmkJhQsmcYG38Uj8/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "85LBfFboXNmmNzkEAUhfytTEPD31qiddtjepPPywyix4",
            "symbol": "ADSL2EF",
            "name": "Adsl2ef Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/85LBfFboXNmmNzkEAUhfytTEPD31qiddtjepPPywyix4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/adsl.formation.tg",
                "instagram": "https://www.instagram.com/adsl2ef",
                "website": "https://www.adsl2ef.com/",
                "youtube": "https://www.youtube.com/channel/UCJnZdPgPwI2rd8_-dfGWOng"
            }
        },
        {
            "chainId": 101,
            "address": "FFBNNQZ6ynKXEdqUi9PnLxmkgzJc7Qf43KRcBTzbJVu5",
            "symbol": "PATTY",
            "name": "PATTY TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFBNNQZ6ynKXEdqUi9PnLxmkgzJc7Qf43KRcBTzbJVu5/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4u3LdFiY2JoSc7znP9ZrgKkmTAtVB9Qo254A2nH2nXGU",
            "symbol": "VCT",
            "name": "Vibing Cat",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fpetruololo/cat2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj",
            "symbol": "POT",
            "name": "Positron",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "governance-token"
            ],
            "extensions": {
                "description": "POT is a utility token for Ubik LoFi metaverse."
            }
        },
        {
            "chainId": 101,
            "address": "AsiP4tPh6tWfgKMjTibhA5H6ge3EnHSyh3iPPDEFSqSu",
            "symbol": "NET",
            "name": "Negatron",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsiP4tPh6tWfgKMjTibhA5H6ge3EnHSyh3iPPDEFSqSu/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "governance-token"
            ],
            "extensions": {
                "description": "NET is a governance and utility token for Ubik LoFi metaverse."
            }
        },
        {
            "chainId": 101,
            "address": "HyuRGvanUr6kxs7MHNA9c3CzxwJtBLe1BxDZPcB7Akkq",
            "symbol": "CKIV",
            "name": "CharlieKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ceklug/CharlieCoin2/main/CharlieCoin5.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CEUQNkt3tXqakiXurUbCMVVq9RRaPFsaw8fUKfTNgpup",
            "symbol": "BUDS",
            "name": "BUDS - PotHeads",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/PotHeadsNFT/marketing-assets/main/9YjkcXTNKn3f2NmgO67w8P2kFC_LfCNvPdyG3OaSy24%207.57.15%20AM.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Pot_Heads_NFT",
                "website": "https://potheads-nft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CPiJMJPcYemoo8dW1LfrVE39JyS3CmHkr2gwewjzihxd",
            "symbol": "THC",
            "name": "The Holy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TinyHarvester/crypto/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "Game-Token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CnscHgCcmzFEntdZoAjvLmZWN4cZL1Fy2pKF4NUJiFeb",
            "symbol": "STNE",
            "name": "Stone Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlanBeez/Stone_Coin/main/Logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BVtZbAcUNYg8SD4UL9YWvibRtHK11Gu3pERjR2X2dhPE",
            "symbol": "TEK",
            "name": "TEKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lakshgandikota/tekonsolana/main/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "T1AJW2EJLkDeEFEurHCJH9vBde23tVKD4pgDHEFCGUK",
            "symbol": "AOSM",
            "name": "AOS Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zenturing/token-list/aos_t/assets/mainnet/T1AJW2EJLkDeEFEurHCJH9vBde23tVKD4pgDHEFCGUK/aos.png",
            "tags": [
                "mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9tdCBZd9ZtpAS4KjBhfx4VsA9svC7ZQD8UR7FXHyLPaP",
            "symbol": "TBMP",
            "name": "ToadBoys Mint Pass",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9tdCBZd9ZtpAS4KjBhfx4VsA9svC7ZQD8UR7FXHyLPaP/toad_logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.toadboys.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Ek1wxugWrFimo8NExqzYaGDnikEibuUrkEaWEs3z2aTU",
            "symbol": "DAVE",
            "name": "DAVE COIN",
            "decimals": 6,
            "logoURI": "https://github.com/DAVECOIN1/Crypto/blob/main/DAVECOINLOGO.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6XCZB7vqgmcNazYJRgQaQqYrig49SbeE18oBb6CpLRwc",
            "symbol": "DRUNK",
            "name": "CryptoDistillation",
            "decimals": 0,
            "logoURI": "https://github.com/rynoroot/crypto/blob/main/DrunkPug.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "56eg63pjkbYB3XYBWu23scue61W6emtNEuYNEBtsCWDy",
            "symbol": "ARK",
            "name": "arkayde-monkey",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yoavshmariahu/token-list/main/assets/mainnet/56eg63pjkbYB3XYBWu23scue61W6emtNEuYNEBtsCWDy/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://www.arkayde.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HT5cCnN9kugN7V3D3CLiskkREfKxBcJnAPdoxCz4Ciju",
            "symbol": "NWFP",
            "name": "New World Federation Pound",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/newworldfederation/nwfp/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://nwf.earth/"
            }
        },
        {
            "chainId": 101,
            "address": "5HEu2bEscWLvYUwSof3wAzLciqUXLLzaa7e6WWPfZzxh",
            "symbol": "VOID",
            "name": "VoidCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/fpetruololo/void/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qKKBY3P6n2e2kFQdMmkvBvDKc5wRbtjTCbaNy2TGHeU",
            "symbol": "ZIGI",
            "name": "ZigiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DaviZigiJones/ZigiCoin/main/ZigiCoin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DavidZigiJones"
            }
        },
        {
            "chainId": 101,
            "address": "CNgARsLNnudL5UqbqkwA2mQ1aah2U9kfHFyoppZTeTKx",
            "symbol": "MINTTUSKER",
            "name": "Mint Discount Team Tuskers",
            "decimals": 0,
            "logoURI": "https://www.teamtuskers.com/wp-content/uploads/2021/12/tuskers-logo-circle-01.png",
            "tags": [
                "discount-token"
            ],
            "extensions": {
                "website": "https://www.teamtuskers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3CXVYitUaSMxifZKyiBRDT1TMcGrL1nJ2WfwdgnnvrKS",
            "symbol": "STC",
            "name": "Shortcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CXVYitUaSMxifZKyiBRDT1TMcGrL1nJ2WfwdgnnvrKS/logo.png",
            "extensions": {
                "website": "http://shortgun.in/shortcoin/"
            }
        },
        {
            "chainId": 101,
            "address": "8Ntf39cB3qXKC1wZ1pXDPpaR2gWGqMXfsffsRqC3DZNa",
            "symbol": "GSTR",
            "name": "G Street Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MEENcoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CPDkFwaWuGF56taYCJrGdqrbMcV28s2EuZhs2vPMDU95",
            "symbol": "TREES",
            "name": "TREES Token",
            "decimals": 9,
            "logoURI": "https://github.com/RaymondBillyRay/TREES/blob/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "Game-Token",
                "community-token",
                "Colorado",
                "Metaverse",
                "climate-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9EWr2TsGKwAQSL2R7sQex6TACxXu6wUQJktEsogtexCN",
            "symbol": "JST",
            "name": "Justice",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/jst-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2CnnqGkniLnYG2idYCr7gGWyvvnwEKAaqX3mKiSGELLh",
            "symbol": "HAPX",
            "name": "Happiness",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/hapx-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3zD9zassLocyH6Tdj2NYXw6ym1jtgaM3QDkmeJNwNjer",
            "symbol": "ULTRON",
            "name": "Ultron",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3zD9zassLocyH6Tdj2NYXw6ym1jtgaM3QDkmeJNwNjer/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Ultron is a utility token for the Ultron Apes community",
                "discord": "https://discord.gg/ultronapes",
                "twitter": "https://twitter.com/ultronapes"
            }
        },
        {
            "chainId": 101,
            "address": "Foxy7Df6VEc1dUCr1ExZfRAqqHaifNFDd8ccvAs59DNr",
            "symbol": "FOXY",
            "name": "Foxy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/foxytoken/foxytoken/main/foxy.png",
            "extensions": {
                "discord": "https://discord.gg/9Yph9njFNF",
                "github": "https://github.com/foxytoken",
                "telegram": "https://t.me/foxyarmy",
                "twitter": "https://twitter.com/joinfoxyarmy",
                "website": "https://foxy.army"
            }
        },
        {
            "chainId": 101,
            "address": "FnmSHBjt45RAWhcKCTPgbmJbNcBGu1KXXFbEYd5HxGoc",
            "symbol": "CWC",
            "name": "cwCoin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Copyrightsworld/cwcoin/main/cwcoin-logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5P1ZqF9UspfEA3foGywTbjm5UxCjLLu7hfaPh5gSCgDM",
            "symbol": "THESIS",
            "name": "Thesis Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DekreedStudios/varkalai/main/thesis.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Varkalai Official Token",
                "website": "https://www.varkalai.com"
            }
        },
        {
            "chainId": 101,
            "address": "GAERrdnan7rsVyqcFxHFWoPcNdMEfwinHCGGGSyhzkQu",
            "symbol": "VRK",
            "name": "Varkalai Founder",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DekreedStudios/varkalai/main/varkalai-founder.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Varkalai Official DAO Token",
                "website": "https://www.varkalai.com"
            }
        },
        {
            "chainId": 101,
            "address": "CHzPHnY84VZdgzEnpPJZBTCXiSSsiMEah1qeLGQgD2q8",
            "symbol": "HAPPY",
            "name": "HAPPY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHzPHnY84VZdgzEnpPJZBTCXiSSsiMEah1qeLGQgD2q8/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "vYDNTpXUjidPNewzHxcCC7NRT2i97J82LeFkZyEZ3HM",
            "symbol": "$DNWL",
            "name": "DNMM WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vYDNTpXUjidPNewzHxcCC7NRT2i97J82LeFkZyEZ3HM/logo.png",
            "extensions": {
                "discord": "https://discord.gg/DegenNation69",
                "twitter": "https://twitter.com/DegenNation69",
                "website": "https://degen-nation69.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4SVyZ9e8EJFLiHeoys7Bsm4EH8bkfCRMcL4TzEEfsyXE",
            "symbol": "MASK",
            "name": "Please don't tell Mask token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SVyZ9e8EJFLiHeoys7Bsm4EH8bkfCRMcL4TzEEfsyXE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.pleasedonttell.art/"
            }
        },
        {
            "chainId": 101,
            "address": "7yg1kQGz89FzmjbdUppFZCSWAQsjn7zYGJ4o8wtb7gju",
            "symbol": "ENFT",
            "name": "ENOFEL",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7yg1kQGz89FzmjbdUppFZCSWAQsjn7zYGJ4o8wtb7gju/logo.png",
            "tags": [
                "community-token",
                "nfts",
                "ENOFEL"
            ],
            "extensions": {
                "website": "http://enofel.com/"
            }
        },
        {
            "chainId": 103,
            "address": "EMTaakqZJn2AfXjWX5Y6avjssBgsqDVijQebL7frmEh5",
            "symbol": "RUNI",
            "name": "Runi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EMTaakqZJn2AfXjWX5Y6avjssBgsqDVijQebL7frmEh5/logo.png"
        },
        {
            "chainId": 103,
            "address": "7Wh4ALdTGJkSwCPrP9vFfywMxjDjgFPF4JmyJPLp7kSJ",
            "symbol": "WORKS",
            "name": "WORKS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Wh4ALdTGJkSwCPrP9vFfywMxjDjgFPF4JmyJPLp7kSJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "Ddc3Rd6dbXysGcFHB2jyEXtpSZ2KFGsJ1wNiG76GUuk3",
            "symbol": "BHN",
            "name": "Bhuna",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hotdogtown/logopng/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BHvUQ1igzBbeoZBjfuYw5Eax25nMH2C6qDrGyLH5kt48",
            "symbol": "SNPC",
            "name": "SnipeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/steve12345585/snipecoin/main/SNIPECOIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hero6s7zJXsw9hfCXLVR5stLqgCok3E7CCkpQEoLAk2g",
            "symbol": "HERO",
            "name": "Solhero Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hero6s7zJXsw9hfCXLVR5stLqgCok3E7CCkpQEoLAk2g/logo.png",
            "tags": [
                "utility-token",
                "game-currency",
                "GameFi"
            ],
            "extensions": {
                "discord": "https://discord.io/solhero-finance",
                "telegram": "https://t.me/SolheroFinance",
                "twitter": "https://twitter.com/SolheroFi",
                "website": "https://solhero.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "CuxuCrT6FCAc5SUoGDoMVuf7UCLwAvzUmseq4a9VBNqw",
            "symbol": "DRT",
            "name": "Digital Ranger Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CuxuCrT6FCAc5SUoGDoMVuf7UCLwAvzUmseq4a9VBNqw/logo.png"
        },
        {
            "chainId": 101,
            "address": "7gjTvVbg1R62yo2iAwusg1zSH5Zp1FhP1zF7hFNrrHPT",
            "symbol": "BOK",
            "name": "BokBok",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gjTvVbg1R62yo2iAwusg1zSH5Zp1FhP1zF7hFNrrHPT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Mechabots live to fight, and they fight to earn BokBoks in MechaFightClub, the biggest fight club in the metaverse! BokBok!",
                "discord": "https://discord.gg/mechafightclub",
                "instagram": "https://www.instagram.com/mechafightclub/",
                "linkedin": "https://www.linkedin.com/company/irreverentlabs/",
                "twitter": "https://twitter.com/MechaFightClub",
                "website": "https://mechafightclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "38FmKLADxGDhdjJqSm6o6ahkdabZDBwkxXcu4qSyruZ4",
            "symbol": "ROBOT",
            "name": "ROBOT COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38FmKLADxGDhdjJqSm6o6ahkdabZDBwkxXcu4qSyruZ4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://developrobots.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HF1Y7w9dzFaodMrZYATEKTgySUeLek8SUhW36Gye4x8Q",
            "symbol": "LOCO",
            "name": "ElonCoin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/eloncoin2022/logo/main/logo.jpg",
            "tags": [
                "social-token",
                "meme-token",
                "payment",
                "solana-ecosystem",
                "media",
                "web3"
            ]
        },
        {
            "chainId": 101,
            "address": "GZwi7xwAkdqXXntybrCJ4e16faQjG57K17WuDg2copFj",
            "symbol": "PZAH",
            "name": "ThePzahParty",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ThePzahParty/The-Pzah-Party/main/PIXELZAH1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GRfw4aEVY12C7vFjs1u1GQ1eLEsaEiUjrfMNfmjrD1W",
            "symbol": "$GODz",
            "name": "GODz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GRfw4aEVY12C7vFjs1u1GQ1eLEsaEiUjrfMNfmjrD1W/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C4WG3YZCbN",
                "twitter": "https://twitter.com/nft_godz",
                "website": "https://godznft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HM9mt39fiUqeYnnV1FzHeW421Uq61t3t6Qyp7PzWFfHN",
            "symbol": "GODzWL",
            "name": "GODz WL Seed Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HM9mt39fiUqeYnnV1FzHeW421Uq61t3t6Qyp7PzWFfHN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C4WG3YZCbN",
                "twitter": "https://twitter.com/nft_godz",
                "website": "https://godznft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "32Eh585dMTSAPHF28DEG8i8vNcS9iwoVRdaJkBucfqG1",
            "symbol": "GIN",
            "name": "Green Impact NFT Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/32Eh585dMTSAPHF28DEG8i8vNcS9iwoVRdaJkBucfqG1/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ondaUvsoXMcakKuPUyaJ4kyzpzasiLA7CP5oycuU66z",
            "symbol": "ONDA",
            "name": "ONDA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ondaverse/assets/main/xx.png",
            "extensions": {
                "discord": "https://discord.gg/fkeGTBt5KH",
                "twitter": "https://twitter.com/Onda_lab",
                "website": "https://ondalab.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DeNjdkaXz7wnzEirgSTz1Yyzp2qSb84qYM9pRQzoKW1o",
            "symbol": "DAUS",
            "name": "Dauswap",
            "decimals": 9,
            "logoURI": "https://dauswap.com/dauswap.png",
            "tags": [
                "exchange",
                "social",
                "nft",
                "swap",
                "finance",
                "gift",
                "decentralized",
                "eCommerce",
                "education"
            ],
            "extensions": {
                "website": "https://dauswap.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BjFceVzhHSADeV9udJnS73sxdGFQ2VcFdi1meXhS1EzF",
            "symbol": "ChimpWL",
            "name": "Chimpanions WL Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/935006072266162177/936269290955485214/unknown.png",
            "extensions": {
                "discord": "https://discord.gg/chimpanions",
                "twitter": "https://twitter.com/thechimpanions",
                "website": "https://ondalab.io/"
            }
        },
        {
            "chainId": 103,
            "address": "5PKB6ySL244r2CnRSDPBqe3yLYW72kY8DsWc82MufAtg",
            "symbol": "SNKC",
            "name": "SnakeChain Coin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/gowtham-k147/snkctoken/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "69HKWsA5AuX1hc5MSyAF9N9iZP59BpNsUSPCB3XPKRrZ",
            "symbol": "WMBO",
            "name": "Wumbo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WEVR33/Crypto/main/IMG_7043.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "Fx8JwEA9B7YxJrKpjoMt6Cxxnukqj4TQPTkniGcB4WsA",
            "symbol": "LNR",
            "name": "Lunar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/Lunar/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9cyEStsrZF7LzqLzbNcuUeuat1NM4eHrBVApvkPBCQk4",
            "symbol": "ATA",
            "name": "ATA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puppetmonkester/img-repo/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/_atadia_",
                "website": "https://www.atadia.io/"
            }
        },
        {
            "chainId": 101,
            "address": "J42yFLJgCSU1GcUoiCTXwdmEGgZp7tEmAWbzDkgCu93w",
            "symbol": "RADIO",
            "name": "Radio Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J42yFLJgCSU1GcUoiCTXwdmEGgZp7tEmAWbzDkgCu93w/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "description": "Radio is the official token of Radio Lazy Club",
                "discord": "https://discord.com/invite/5jxtVqaG",
                "twitter": "https://twitter.com/radiolazyclub1",
                "website": "https://www.radiolazyclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "KARTdF5K68Q2nGppizG3DeCzp7AhHy6YXf2uTQjBSQx",
            "symbol": "VROOM",
            "name": "Mushroom Racers Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KARTdF5K68Q2nGppizG3DeCzp7AhHy6YXf2uTQjBSQx/logo.png"
        },
        {
            "chainId": 101,
            "address": "MUSH7gnYdrr9PHrfSq8VyfehwUX9v9tqGqLNdKYRUrv",
            "symbol": "MUSH",
            "name": "Mushroom Racers WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MUSH7gnYdrr9PHrfSq8VyfehwUX9v9tqGqLNdKYRUrv/logo.png"
        },
        {
            "chainId": 101,
            "address": "Eo5YeDSgnH1qa4tL5iWhyk7iKdohWWpv9iDtym3Kgb7t",
            "symbol": "cPANZR",
            "name": "PANZR Credits Token",
            "decimals": 5,
            "logoURI": "https://www.panzerdogs.io/img/cPANZR.gif",
            "tags": [
                "utility-token",
                "game-token",
                "staking"
            ],
            "extensions": {
                "discord": "https://discord.gg/dSbEq6fBA2",
                "twitter": "https://twitter.com/panzerdogs",
                "website": "https://www.panzerdogs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "H1X2quMc8ZesCE4afDQhWAmFssENprmmz9a6SXvHWPoN",
            "symbol": "$AMMO",
            "name": "Solarmy AMMO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/UniversolSoldier/UniversolSoldier/main/ammo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solarmy",
                "twitter": "https://twitter.com/thesolarmy",
                "website": "https://solarmy.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8m3x8wZNacWZyonJNqfZmHjQFG8sC8YqV68q3A9JFRfE",
            "symbol": "SIPA",
            "name": "SIPA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/arushagroup/SIPA/main/logo.png",
            "tags": [
                "foreign-exchange",
                "payment-token"
            ],
            "extensions": {
                "description": "Integrated foreign exchange payment system.",
                "facebook": "https://facebook.com/sipa_social",
                "instagram": "https://www.instagram.com/sipa_social/",
                "telegram": "https://t.me/sipa_social",
                "twitter": "https://twitter.com/sipa_social"
            }
        },
        {
            "chainId": 101,
            "address": "AtS8bVho4K3kcufDCiqtE4GoC3WhSpLAM4KsEou5oh1j",
            "symbol": "PENNY",
            "name": "PENNYWISE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AtS8bVho4K3kcufDCiqtE4GoC3WhSpLAM4KsEou5oh1j/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DUSTcnwRpZjhds1tLY2NpcvVTmKL6JJERD9T274LcqCr",
            "symbol": "DUST",
            "name": "Dust Protocol",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUSTcnwRpZjhds1tLY2NpcvVTmKL6JJERD9T274LcqCr/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/degods",
                "twitter": "https://twitter.com/degodsnft",
                "website": "https://www.dustprotocol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AM1bCNMMPA4kVYHkYakBYSWeyMEsvjknjwhfmMxJunBc",
            "symbol": "KAI",
            "name": "KAITO",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AM1bCNMMPA4kVYHkYakBYSWeyMEsvjknjwhfmMxJunBc/logo.png",
            "extensions": {
                "discord": "https://discord.gg/wQcTyYMQRk"
            }
        },
        {
            "chainId": 101,
            "address": "12HddxSoYCL5SYekxabqVVxaBtcJc2Wi3eEwZrLoQ1nx",
            "symbol": "LVL",
            "name": "levlups",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/levlups/levlupsToken/main/logo.png",
            "tags": [
                "social-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/egwUcdfqrv",
                "twitter": "https://twitter.com/levlups",
                "website": "https://galacticau.fun/",
                "youtube": "https://www.youtube.com/channel/UCmBbOgb66HChuHNDXCEEELQ"
            }
        },
        {
            "chainId": 101,
            "address": "JCigygFgEEKoRpixRFQibhHLyvSXrQM5AJjkE7xQdxDA",
            "symbol": "DAF",
            "name": "DA Futuristics Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/deepak-aditya/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToQaYfKWvHtHpmPsCo4awxavoEQJjguBZzGiZBM2vYT",
            "symbol": "PBT",
            "name": "'Plan B' Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToQaYfKWvHtHpmPsCo4awxavoEQJjguBZzGiZBM2vYT/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4o8JKVCpaV3Rp2GtXcJNaa7DSomeuVks8UJS4nNcNUuS",
            "symbol": "CIRCLE",
            "name": "Mr Circle",
            "decimals": 4,
            "logoURI": "https://human.ag/api/profile/7H1HKjLxJbYnbK78AtDEXoXWPB464afXcskoMnk8GHu6/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://human.ag/coin/CIRCLE"
            }
        },
        {
            "chainId": 101,
            "address": "PSMndFwnJpMRo9c6aWU2V4ev3hq6SYgE2AwpJ7CuweB",
            "symbol": "PSM",
            "name": "Phantom Simon",
            "decimals": 4,
            "logoURI": "https://human.ag/api/profile/FUQ3VUB5pvUnwCBZXLNr8iVAjrKvStvgTA5ewkMwHSPG/picture?src=token-list",
            "tags": [
                "utility-token",
                "social-token",
                "human"
            ],
            "extensions": {
                "website": "https://human.ag/coin/PSM"
            }
        },
        {
            "chainId": 101,
            "address": "E638GBkp3CA82bTu6J5DptGiAELLu61vvfW1bPDsxCq1",
            "symbol": "TKNI",
            "name": "Tooknie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pongoe/tooknie/main/logo-small.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GD2T4gzUu6JyvejqaTXAzLPtkLa6T1JvczsnAeCJyrFf",
            "symbol": "MAQE",
            "name": "MAQE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/amnajpotipak/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FBx3B3RrLTdeNhUPinZZGXQrBibb2N3rx9N2YwkuSjD9",
            "symbol": "GAFA",
            "name": "Gafa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GafaCoin/gafa/main/gafacoin.png",
            "tags": [
                "Gaming",
                "metaverse",
                "lands"
            ]
        },
        {
            "chainId": 101,
            "address": "6G6v5rEh1hHzySQLQQyZisf773kMbNPk5Ay5QM6kriUa",
            "symbol": "ADSL",
            "name": "ADSL-2EF coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6G6v5rEh1hHzySQLQQyZisf773kMbNPk5Ay5QM6kriUa/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/adsl.formation.tg",
                "instagram": "https://www.instagram.com/adsl2ef",
                "website": "https://www.adsl2ef.com/",
                "youtube": "https://www.youtube.com/channel/UCJnZdPgPwI2rd8_-dfGWOng"
            }
        },
        {
            "chainId": 101,
            "address": "3gb3iUAgTCtXq7cFiDZTi2GFPgQdg1zFVUubYAtLJfWU",
            "symbol": "LUX",
            "name": "LUX Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3gb3iUAgTCtXq7cFiDZTi2GFPgQdg1zFVUubYAtLJfWU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://wearelux.space/"
            }
        },
        {
            "chainId": 101,
            "address": "9yzc7kudPMJGGDjsmaCkHUPtZtjBfUCWKVgUeC2ytMKC",
            "symbol": "US",
            "name": "Update Settings Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9yzc7kudPMJGGDjsmaCkHUPtZtjBfUCWKVgUeC2ytMKC/logo.svg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/update_settings/",
                "twitter": "https://twitter.com/update_settings",
                "website": "https://updatesettings.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GFWPMQNMdoqvBYV4yFhBgbQSDikaRin8pfrdxPocXvNU",
            "symbol": "LHGT",
            "name": "LightCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/fpetruololo/light/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FrantisekPetru"
            }
        },
        {
            "chainId": 101,
            "address": "Ghh9TRwCMjjGXFexdjqscqrcfwHsETRcxgvFi4puMftx",
            "symbol": "WIN",
            "name": "Gamble",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Gamble.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "gamble",
                "gambling"
            ]
        },
        {
            "chainId": 101,
            "address": "BxYiGctREpknXPhFszGt7xzL6kgF6B3QgrEnybnvSLao",
            "symbol": "HTTP",
            "name": "Internet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Internet.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "internet",
                "www"
            ]
        },
        {
            "chainId": 101,
            "address": "DDvVKCK9Aw61BQUbiL5m7Co8eeaPZGhHeynaocfk1d5X",
            "symbol": "VACA",
            "name": "Vacation",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Vacation.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "vacation",
                "rental"
            ]
        },
        {
            "chainId": 101,
            "address": "GHKCb1b2YgLDhEYUXGyHLCVJyi3MjVqbERZgESvPoH8a",
            "symbol": "NG",
            "name": "Nex Gen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rakeshreddy7979/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FRt8DDNFaE6wH9CcJ9eEQxv8B2NM9v3jkdq7FHRobksH",
            "symbol": "QNT",
            "name": "Quantanium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/QuantaniumCrypto/Quantanium/main/logo.png",
            "tags": [
                "foreign-exchange",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "77jUqHLiMJDH2oTrcVTafMCUCNpv3M5A1BixjwqRwiku",
            "symbol": "MTC",
            "name": "Mountain Top Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Cryptlofi/MTC/main/MTaB.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "282Weh31UZ1yw99dYV8JqxPLtFBNbN1UfZhjBiKNk9Zb",
            "symbol": "UGA",
            "name": "BAPC Native Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7aENbEssDsZZ7T1HP7LLgoHJF8q4JDKfxArkmdLReUZc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/XwQ5AtvsBU",
                "twitter": "https://twitter.com/BAPC_Solana",
                "website": "https://bapepixel.club/"
            }
        },
        {
            "chainId": 101,
            "address": "7rGXsGSHdSyN4vPtxfw7ZzopzoUp223fY2t34KVcUv2c",
            "symbol": "VNFT",
            "name": "Viking NFT",
            "decimals": 0,
            "logoURI": "https://github.com/Mrhydrate/crypto/blob/main/Clogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8KVJypeFhCG2CPAsmxPWtByFmKGSgfwAXkP5NafQCD3B",
            "symbol": "SDP",
            "name": "San Diego Padres Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/SanDiegoPadresToken/SDP_Token/main/SDPadre.png",
            "tags": [
                "social-token",
                "sports-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SDP_Token"
            }
        },
        {
            "chainId": 101,
            "address": "BK2GUqUMryV2GVo3TwBZmPxWREx9fcHsT881DJGVLQrf",
            "symbol": "R",
            "name": "Romeao coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Romeao/crypto/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "meme-token",
                "community-token",
                "payment-token",
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H7tkCCb4BsAr14Dz7BWqnf69J8cBjnLZsj8QzaaNm7vA",
            "symbol": "ORLC",
            "name": "Orel Crypto Gulden",
            "decimals": 9,
            "logoURI": "https://orelcrypto.ru/images/logo.png",
            "tags": [
                "city-token",
                "social-token",
                "local-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/%D0%9E%D1%80%D0%BB%D0%BE%D0%B2%D1%81%D0%BA%D0%B8%D0%B9-%D0%B3%D1%83%D0%BB%D1%8C%D0%B4%D0%B5%D0%BD-108073031603453/about",
                "telegram": "https://t.me/otelcrypto",
                "website": "https://orelcrypto.ru/"
            }
        },
        {
            "chainId": 101,
            "address": "FFSYStZVbrsHZVLDvkXWxqRbygPuCTJVMRTSqXmDd8M9",
            "symbol": "FFS",
            "name": "Future Finance Systems Token - FFSYST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/codefuturance/ffsyst/main/logo.png",
            "tags": [
                "governance-token",
                "utility-token",
                "exchange-token",
                "DAO",
                "DeFi",
                "Fexobit"
            ],
            "extensions": {
                "description": "FFS is a governance and utility token for an ethical financial protocol, supporting non-derivative, trade-based financial instruments.",
                "facebook": "https://www.facebook.com/fexobit",
                "imageUrl": "https://raw.githubusercontent.com/codefuturance/ffsyst/main/logo.png",
                "reddit": "https://www.reddit.com/u/fexobit",
                "twitter": "https://twitter.com/fexobit",
                "website": "https://www.fexobit.com"
            }
        },
        {
            "chainId": 101,
            "address": "3WPMJe4od94V12zJoY1D7s3b7HdRb17eNryUZd1nJ6ey",
            "symbol": "CP",
            "name": "Copen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DrNapNap/Copen-Token/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Kqk68qjE8zVNhtjeKioSHY1EhSdyym33TDX6PhbrBn5",
            "symbol": "FAM",
            "name": "Family Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solodevo/family/main/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolSavages",
                "website": "https://www.solsavages.com"
            }
        },
        {
            "chainId": 101,
            "address": "6thgyjUZ5Vfsy1CpDPGuyJkJxMVyzUY4mNvE797S3Eey",
            "symbol": "PUGs",
            "name": "Pugs Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crinkylips/Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2JqUDmTAGdebvbvjWXgSVq1bj1mDhjhX2q531AmPB9Vk",
            "symbol": "SMR",
            "name": "Solana Monke Rejects Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/milorue/token-assets/master/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/MonkeRejects",
                "twitter": "https://twitter.com/MonkeRejects",
                "website": "https://solmonkerejects.com"
            }
        },
        {
            "chainId": 101,
            "address": "9mWRABuz2x6koTPCWiCPM49WUbcrNqGTHBV9T9k7y1o7",
            "symbol": "MAI",
            "name": "MAI Stablecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/0xlaozi/qidao/main/images/mimatic-red.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/mQq55j65xJ",
                "twitter": "https://twitter.com/QiDaoProtocol",
                "website": "https://mai.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4vBXowxbnpKWQJQ4SNiQHiCcyimk5qvFqono1VtbNj25",
            "symbol": "INF",
            "name": "InfectedCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/infectedw/infectoin/main/wp3014779-radioactive-wallpapers-modified.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4JWTvCNMLXZx69W2ri9ecFxuXLnPsNAWyv38Q7fMxcwE",
            "symbol": "CBDOIL",
            "name": "CBD Oil",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/CBD.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "cbd",
                "cannabis"
            ]
        },
        {
            "chainId": 101,
            "address": "Bf79kggD3C5T3NMQQBshqsKjLhxNrzZufYdtX6EjLsmQ",
            "symbol": "GIVE",
            "name": "Give",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Give.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Give",
                "cannabis"
            ]
        },
        {
            "chainId": 101,
            "address": "7tKzHt87Np924w5Gpi2CwFqpaP5jwtzcuKXRFExvHm5N",
            "symbol": "CASH",
            "name": "Loans",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Loan.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "loans",
                "cash"
            ]
        },
        {
            "chainId": 101,
            "address": "BdYJ116zSMCcpYDQsoKeLHF7ivoyy6eTodS8hRfa9nuf",
            "symbol": "PETS",
            "name": "Pets",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Pets.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Pets",
                "dog",
                "cat",
                "pet"
            ]
        },
        {
            "chainId": 101,
            "address": "7hdrzjRXA8NP6sZExxSfPQjTQhre6mxF39bhUa9ccre9",
            "symbol": "AI",
            "name": "AI COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7hdrzjRXA8NP6sZExxSfPQjTQhre6mxF39bhUa9ccre9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://itradeai.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3o6PheMkJP68da6JF9Fmgr1wEF8r43JLREdvrnWacyA3",
            "symbol": "TAR",
            "name": "Tarras Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bakri993/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EqibTGnqC88AvQAyK3jaDY2rbM13mysARCXJ587q4SFA",
            "symbol": "HLTC",
            "name": "HaleTown Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/j0hnboy75067/Cryptocoin/main/Haletowncoinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6MCvPS1LdWqZbiNkcqCxYHeS8wY29AyxGMFwaNtTC8GK",
            "symbol": "NRVK",
            "name": "Nirvik Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nirvikBasnet/nrvk-logo/main/NRVK-LOGO.png",
            "tags": [
                "social-token",
                "Nepal",
                "nft",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "DHwKYcFF3nZvgHDm2TpHaiSRKXnAZRWw1CvDkgNSQNuo",
            "symbol": "GMWL",
            "name": "GenMakers Whitelist Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHwKYcFF3nZvgHDm2TpHaiSRKXnAZRWw1CvDkgNSQNuo/logo.png",
            "tags": [
                "social-token",
                "nft-token"
            ],
            "extensions": {
                "website": "https://www.genmakers.com"
            }
        },
        {
            "chainId": 101,
            "address": "24fKHtN7ySkhcvZjg9aVirhbkuSVufsm3AdMkwF2rVu8",
            "symbol": "BDW",
            "name": "BoizDemWe Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/baileyfiscus/boizdemwe-crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TYxUwkB1Yxw7xyB6uezLNhj5cmgGFvgBw3wRRnfTj5Z",
            "symbol": "METAPX",
            "name": "Meta Pixel",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/metapixelsolana/meta_pixel/main/logo.png",
            "tags": [
                "utility-token",
                "meta-verse",
                "NFT",
                "soical-token",
                "game",
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/MetaPixelUniverse/",
                "website": "https://metapixel.money/"
            }
        },
        {
            "chainId": 101,
            "address": "7bFfvNZqJr2KFDjdP58MVud7kM9AMbXvoFcfAmgBHtfx",
            "symbol": "MTSC",
            "name": "Sami Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MTSami/sami_coin/main/Sami_Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CUabWw8Q3o8AN8MxA9WFG5MjwDU9Gq29aLxbr3z5kGi2",
            "symbol": "TKH",
            "name": "TkhKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tkhadir/tkhcrypto/main/tkh-token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6bTcyMbXcRuvBgukkUDNK8xxLrviAGVmDs8vTcs4Rodj",
            "symbol": "GTOK",
            "name": "GTOK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yoavshmariahu/token-list/main/assets/mainnet/6bTcyMbXcRuvBgukkUDNK8xxLrviAGVmDs8vTcs4Rodj/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.arkayde.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AEVtX9tDibQaYB5S5YPsce1917fVZgtqJx8pvkW4hM8g",
            "symbol": "TIX",
            "name": "TIX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yoavshmariahu/token-list/main/assets/mainnet/AEVtX9tDibQaYB5S5YPsce1917fVZgtqJx8pvkW4hM8g/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.arkayde.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GUdX84edZH14HfnDuD3AYjKxQdm52ztJnXpzoHdTwMSt",
            "symbol": "GEM",
            "name": "Gemstone",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/23665754/150893304-f9391d19-a332-45d6-be74-fdab73cbd422.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.gemtoken.online"
            }
        },
        {
            "chainId": 101,
            "address": "6cMZs2PRafVnMHkXiDu5SLvomqLHCS11bxwi9KBJTtzZ",
            "symbol": "STOMP",
            "name": "STOMP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cMZs2PRafVnMHkXiDu5SLvomqLHCS11bxwi9KBJTtzZ/logo.jpg",
            "extensions": {
                "instagram": "https://www.instagram.com/dopedinopc/",
                "twitter": "https://twitter.com/DopeDinoPC"
            }
        },
        {
            "chainId": 101,
            "address": "SFVNDppzq2ME2YvEnuRcnC3mNListsPpQqKTLbAMe86",
            "symbol": "SFVN",
            "name": "Sofven",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SFVNDppzq2ME2YvEnuRcnC3mNListsPpQqKTLbAMe86/logo.png",
            "extensions": {
                "website": "https://www.sofven.com"
            }
        },
        {
            "chainId": 101,
            "address": "4axW5EJCMJp5AwMAQA8ZiAULG6Hj6FEwqFnug9zd17ZJ",
            "symbol": "Boog",
            "name": "VBoog",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VFinona/crypto/main/boog.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "imbaePRPNVxBhTLdSWpdjYbXiPWc9spNTz4xKVkZfBJ",
            "symbol": "IMBA",
            "name": "The Lion Cats Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nf0x/the-lion-cats-logo/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/thelioncats",
                "twitter": "https://twitter.com/TheLionCatsNFT",
                "website": "https://thelioncats.com/"
            }
        },
        {
            "chainId": 101,
            "address": "55sgz1DPXFfzFVNd9irzGJ2Xgn5B7iiEM651Yz67e5jB",
            "symbol": "HOUSE",
            "name": "High Roller",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/55sgz1DPXFfzFVNd9irzGJ2Xgn5B7iiEM651Yz67e5jB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://nftscratchoff.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GuuiDrRLAfacFFcjfe5WkvtsA3hZ4PUipVibR5yzk6Te",
            "symbol": "KOSM",
            "name": "KosmiKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AbeerRao/crypto-token/main/logo.png?token=GHSAT0AAAAAABPUKCTG3XIU4VEGU5RERUJSYP6ANEA",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2xaPstY4XqJ2gUA1mpph3XmvmPZGuTuJ658AeqX3gJ6F",
            "symbol": "QUP",
            "name": "QUP Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ske5074/qup-coin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GSFMXUz1ZSdVke47kKxiC7cdxUmxpjZD3NustUT5Nf8R",
            "symbol": "CHEER",
            "name": "Spirit Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rynoroot/Cheer-Coin/main/SpiritToken.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PAWoJfAqpJ6jVjgt9KGvA45c8X6W1i9TmicTSBZ3xaq",
            "symbol": "PAWS",
            "name": "Grampaw Treats",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PAWoJfAqpJ6jVjgt9KGvA45c8X6W1i9TmicTSBZ3xaq/logo.png",
            "tags": [
                "whitelist-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/FtyxbfmSMv",
                "twitter": "https://twitter.com/GrampawNFT",
                "website": "https://grampaw.art"
            }
        },
        {
            "chainId": 101,
            "address": "5qjVXkTL5mG9TLqWyYjW4ZQ7usD941Ri5n2WeWCK3XP",
            "symbol": "CHEST",
            "name": "Chest",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SeaRovers/icon/84acea6e31d759265ed96e9ecd9b5a36b725e971/Doub.png",
            "extensions": {
                "twitter": "https://twitter.com/SeaRoversNFT",
                "website": "https://searoversnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "44xgPw81essPayagreDhbR81KuCk9if2kHyAFDchSGjv",
            "symbol": "MKCT",
            "name": "MilkCraft Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/milk015/Crypto-token-fun/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7rMHRy955QCSqzpDPDYczeRxxd21wVPdx2WPBQ6YPEwM",
            "symbol": "MG",
            "name": "Merc Gamora",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rakeshreddy79799/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8bLoJVemmSqBAi6wuGmRcygrYjhD8yf8Wc2cKPXwC4Vg",
            "symbol": "BANK",
            "name": "Bank",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Bank.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Bank"
            ]
        },
        {
            "chainId": 101,
            "address": "65jgTrU2ES6vXZacUTifntsgew7a5kydJYreyB4WMxSu",
            "symbol": "CA",
            "name": "California",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/California.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "California"
            ]
        },
        {
            "chainId": 101,
            "address": "2C17XBoSToZupV55df4nmNqhAQMCUy7b63aRg3FB6zaB",
            "symbol": "SMART",
            "name": "College",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/College.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "College"
            ]
        },
        {
            "chainId": 101,
            "address": "GwaaY77dAxjgNLR3PF1Dh11oq3nAvJ6pFaATmv5s8m9H",
            "symbol": "GAS",
            "name": "Gas",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/GAS.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Gas"
            ]
        },
        {
            "chainId": 101,
            "address": "HfNP2gAWcXxHQCPH8bRMtE5wPHodxcdAx2swKqaZpV2D",
            "symbol": "INS",
            "name": "Insurance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Insurance.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Insurance"
            ]
        },
        {
            "chainId": 101,
            "address": "CmiqaFLzE6AAbjvBNcKWaCrwiZu2UGN2rewLRHw1LX1",
            "symbol": "KID",
            "name": "Kid",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Kid.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Kid"
            ]
        },
        {
            "chainId": 101,
            "address": "45ja9VNcsQjEvCBTDR5zF84ymrJP5JbDhPwQ8knP8ciZ",
            "symbol": "KIDS",
            "name": "Kids",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Kids.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Kids"
            ]
        },
        {
            "chainId": 101,
            "address": "CUG4DWPXQv3dL7Bod5kbdqY2Gza4wtW3LupQaJGtXPe2",
            "symbol": "MEDI",
            "name": "Medical",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Medical.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Medical"
            ]
        },
        {
            "chainId": 101,
            "address": "HMN9kWpebvvoym4kpocMMxsHZTkcmPetui2Qyx15Z7V2",
            "symbol": "MORT",
            "name": "Mortgage",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Mortgage.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Mortgage"
            ]
        },
        {
            "chainId": 101,
            "address": "2L47qxKaZN9ZbZjjC35Mius4drvJ8np5FSkXrU3Hzw1w",
            "symbol": "PNMA",
            "name": "Panama",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Panama.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Panama"
            ]
        },
        {
            "chainId": 101,
            "address": "96LtWTyyBJsyc85ooKFHujFQQyTgwRpZcee7Tacichdq",
            "symbol": "SELL",
            "name": "Real Estate",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/RealEstate.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "RealEstate"
            ]
        },
        {
            "chainId": 101,
            "address": "53Cp2PMxyZnCyPZXoJqYug2ycUPehhPS8r2G1v2QusHs",
            "symbol": "TX",
            "name": "Texas",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Texas.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Texas"
            ]
        },
        {
            "chainId": 101,
            "address": "3Dp7LtyFanuAxdCPjgq1kD7vHQ9pbp3YbDARpFyKUqtp",
            "symbol": "USA",
            "name": "USA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/USA.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "USA"
            ]
        },
        {
            "chainId": 101,
            "address": "HhaXWNUNmDMReaCDkXaoKKEJyZHiLHgEFcyoZmCHHXZ1",
            "symbol": "MBX",
            "name": "MetaBlocks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WeAreMetaverse/MetaBlocks/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "metaverse-blocks",
                "Community-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "2XsFvbkZYX8Z2Y8C4kQJXQ1RwQVpqXAZ28SqqxdrGdgK",
            "symbol": "WAD",
            "name": "Watchad Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2XsFvbkZYX8Z2Y8C4kQJXQ1RwQVpqXAZ28SqqxdrGdgK/logo.png",
            "tags": [
                "payment-token"
            ],
            "extensions": {
                "website": "https://www.watchad.org/"
            }
        },
        {
            "chainId": 101,
            "address": "9P1Z58FjsW5mn9kkdYkSN58ePakQJY9NK2GNDPunhqzB",
            "symbol": "JAMI",
            "name": "JAMIE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/poshier/crypto/main/jamie.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "73rd6Ekp1bTYzV3oBAUeL4vMDAnHTdiXhCS5pbnh9quj",
            "symbol": "QTX",
            "name": "Quantex Coin (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/cryptiswap-admin/token-list/main/assets/mainnet/73rd6Ekp1bTYzV3oBAUeL4vMDAnHTdiXhCS5pbnh9quj/logo.png",
            "tags": [
                "exchange-token",
                "utility-token",
                "DeFi",
                "Wrapped",
                "Wormhole"
            ],
            "extensions": {
                "blog": "https://blog.myquantex.com",
                "description": "QTX is the native exchange token on the Quantex (formerly Cryptiswap), which is a global crypto exchange featuring an instant swap platform \u0026 an advanced trading platform. Customers choose between taking custody of their own funds on the non-custodial swap platform, or allow us to take custody on the trading platform.",
                "reddit": "https://www.reddit.com/user/CryptiSwap",
                "twitter": "https://twitter.com/Quantex_Global",
                "website": "https://myquantex.com",
                "whitepaper": "https://1drv.ms/w/s!Al2HWrZhIdn3hL0AW2D-LkLo4X9-eA?e=NKN7CT"
            }
        },
        {
            "chainId": 101,
            "address": "EX84m74hxaHQDwasohSQt3NwMxfiU7Tzr65HY91X8BAi",
            "symbol": "RetroWolvesWL",
            "name": "RWOLFWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EX84m74hxaHQDwasohSQt3NwMxfiU7Tzr65HY91X8BAi/Logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RetroWolvesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "9Pa4FS6ZmZa8ajgUY9F8SJsdVUaD9F1R8q6qeFKf1yC1",
            "symbol": "SKY",
            "name": "Skyrocket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/skyrocket55/skyrocket-token/main/skyrocket.png",
            "tags": [
                "social-token",
                "commodity-token",
                "gaming-token",
                "payment-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DjwnsaxiggAUZHDT82ELepNSjJfmVDNGtSubTF9VJKk7",
            "symbol": "XBX",
            "name": "xBlocks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WeAreMetaverse/XBlocks/main/xlogo.png",
            "tags": [
                "social-token",
                "utility-token",
                "metaverse-blocks",
                "Community-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "7jZzdF79oWYN5UBezaZAexrys74wRkZr7ksr5QJ5Zim3",
            "symbol": "BLT",
            "name": "BLIGHT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7jZzdF79oWYN5UBezaZAexrys74wRkZr7ksr5QJ5Zim3/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/catalysts",
                "twitter": "https://twitter.com/CatalystsNFT",
                "website": "https://catalysts.world/"
            }
        },
        {
            "chainId": 101,
            "address": "CCzGS5cm84rhMqSxy5cxCoV6QphdDFKjqQYf1dbFSRL7",
            "symbol": "RCT",
            "name": "Roccetta Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/r0rsch4ch96/Roccetta_Coin/4fc83aefc099a000379b4e9d5af8c65d19c9ddda/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/ale_brd_"
            }
        },
        {
            "chainId": 101,
            "address": "BCuMMwWwYEe83fVAe96bYNChiMLXYXYx3T126MzGYnv1",
            "symbol": "MNTYTKN",
            "name": "Minty Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MintyAutomation/Minty-Logo/main/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/RfShDcAuwU",
                "twitter": "https://twitter.com/MintyAutomation"
            }
        },
        {
            "chainId": 101,
            "address": "41K8ttkLGK6cGLGe8iQ7RgMqYdXc2fkY7vBquviaz4Ag",
            "symbol": "MyEgg",
            "name": "MyEgg",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/BrightTechnologies1/myeggs--logo@master/logo.png",
            "tags": [
                "social-token",
                "commodity-token",
                "Utility-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MyEggsNFT",
                "website": "https://myeggs.art"
            }
        },
        {
            "chainId": 101,
            "address": "FZiaBRWwhJEdEb6sLv9Eep1Sef1HzdsTCYwTJfRK4kJx",
            "symbol": "DUST",
            "name": "Moondust",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JaeckelArtist/Lovestruck/5d362906ade17ff4d5b78750fa5b68a664418fb3/DUST.jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NtB93PXAeibmt96UEynaiAtpLKbPA5xAQgwMXXCzNiW",
            "symbol": "CLG",
            "name": "CloggCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RunFastMcChunky1/CloggCoin/main/Clogg%20Coin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6PXSWh8oGzReC5w7KGX7n4wQQ8ZBxAzHMCBtXBwQZXLq",
            "symbol": "ROMI",
            "name": "RomCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6PXSWh8oGzReC5w7KGX7n4wQQ8ZBxAzHMCBtXBwQZXLq/logo.jpg",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FE7ZpFUeHrWdcxga7yKV2WnGvGNRKv63EDzkLzMbgyRT",
            "symbol": "LUVE",
            "name": "Luve Factory",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/LuveFactory/assets/main/LUVE-520x520.png",
            "tags": [
                "nft-token",
                "finance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBGDvixzTq5FwNQN5jQvJHLsDv8cJJ7SUWuQPdDG5yi2",
            "symbol": "SCLA",
            "name": "ScottLaiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ScottLL/Crypto/main/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "W3UAFTu4DmzR5B5ku9LXB78e4YPAPPYE2msqZ8Po4sT",
            "symbol": "DABS",
            "name": "Dab Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MrMatrixMaster/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5tFbKtYB1pisDApKZVz7eMr7d5BYJxb6NRwN618pJZ6M",
            "symbol": "TEXAS",
            "name": "LoneStar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BitRacer/LoneStar/main/LoneStar.png",
            "tags": [
                "social-token",
                "commodity-token",
                "gaming-token",
                "payment-token",
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mZJ8XZFjTs",
                "twitter": "https://twitter.com/LoneStarToken"
            }
        },
        {
            "chainId": 101,
            "address": "9aeoLdUngRCRKpWaJR8gBi2oL7UzQ44EiuryG32LGWT9",
            "symbol": "FEL",
            "name": "Felektro Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Felektro/crypto/main/logoFinal.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4k4q4JRuD4FoMSAXocuDyz1L9JDPK7BDWYszEHutQ1kZ",
            "symbol": "TTR",
            "name": "TITOR GATE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k4q4JRuD4FoMSAXocuDyz1L9JDPK7BDWYszEHutQ1kZ/insignia36.png",
            "tags": [
                "social-token",
                "NFT",
                "09-16-2022"
            ]
        },
        {
            "chainId": 101,
            "address": "A4Fpxz1RZGmscTxbF2Hhwywi1mGPVNaucc5gVwuM5Q5b",
            "symbol": "KITCHEN",
            "name": "Kitchen Token",
            "decimals": 9,
            "logoURI": "https://seth.kitchen/images/kitchentokentrans.png",
            "tags": [
                "social-token",
                "metaverse",
                "game-token",
                "payment-token"
            ],
            "extensions": {
                "website": "https://seth.kitchen"
            }
        },
        {
            "chainId": 101,
            "address": "5RCdLcdRvenFVcApfARpppCJ2boko2Vwm4aBLLQMkjsG",
            "symbol": "HOOF",
            "name": "HOOF project",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5RCdLcdRvenFVcApfARpppCJ2boko2Vwm4aBLLQMkjsG/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "71p8QK8qhujs2PcLiYM55tcdGLXZ1G2SKCLVL955MuJV",
            "symbol": "APLOSA",
            "name": "Appaloosa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/akash2169/crypto/main/appaloosa-horse-window-sticker-decal-2-modified.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BpDxe3NXRGbdrWKYxfz9Dmy4N2kzMAN1q66qqiAXxKPJ",
            "symbol": "IEJB",
            "name": "IctorVay EchoJay",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChuckleeKlown/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D7U5xsvLBsBzZj2mmkoa7Pa1kKDiKGQHWuWknEgB9y4h",
            "symbol": "CHEERS",
            "name": "Cheers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sanys17/crypto/main/crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9f1E3bskXPb1oXrkefF7mLDtFVqojkrSnoEUYZKQR6wv",
            "symbol": "ASTR",
            "name": "Aster Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlanBeez/AsterCoin/main/Aster_Logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "reward-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/aster.contemporary.jewellery/",
                "instagram": "https://www.instagram.com/aster.contemporary.jewellery/",
                "website": "https://asterjewellery.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9sSA9Fi9bXS934mKHBHmxKcuLoZBML8w4F5fPUaNCkyZ",
            "symbol": "IGCN",
            "name": "Igicoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9sSA9Fi9bXS934mKHBHmxKcuLoZBML8w4F5fPUaNCkyZ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CWUUV3ym4Uphw4CVgkpNxrR7FsttF7h7mLggEUJ1J1aV",
            "symbol": "GIF",
            "name": "Giraffe",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWUUV3ym4Uphw4CVgkpNxrR7FsttF7h7mLggEUJ1J1aV/logo.jpg",
            "tags": [
                "social-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "telegram": "https://t.me/+a2Cw4O8SrotiNDRk"
            }
        },
        {
            "chainId": 103,
            "address": "37JC3gPPfXJme7JNtgxyWQ6J76iZQu9V86E6uZG1Se9k",
            "symbol": "EGGS",
            "name": "Egg Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/37JC3gPPfXJme7JNtgxyWQ6J76iZQu9V86E6uZG1Se9k/logo.png"
        },
        {
            "chainId": 101,
            "address": "EF6Pjrjf28VKsTiYmmNosbRcH91NyQRnFoBqAaUWqqXq",
            "symbol": "FYST",
            "name": "FUNKY SANTAS T",
            "decimals": 0,
            "logoURI": "https://wk767iv23kqj675aavuyesscxa3rq4ipwglwtehqmuhowrolfhhq.arweave.net/sr_vorraoJ9_oAVpgkpCuDcYcQ-xl2mQ8GUO60XLKc8",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/pr3yWdGZYx",
                "instagram": "https://www.instagram.com/funkysantasnft/",
                "twitter": "https://twitter.com/FunkySantas",
                "website": "https://funkysantas.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ATYRrGJqUzJtvPwUcxpBxFN5d2UKcwc8X9dsKdERySzD",
            "symbol": "IBBT",
            "name": "Internal BB Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ibgouvea/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BA89u4vfmpRiCwuFjWiyWXGb67LQawbvdKMFqoiB5iF5",
            "symbol": "FUAT",
            "name": "FuatCoin",
            "decimals": 0,
            "logoURI": "https://github.com/ByteLock/ImageHost/blob/main/fuat-coin.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8VCBkwY5WU7L5dWyrJAtF1XWbGxaYT1PTPEMeMhPoGQH",
            "symbol": "42",
            "name": "42-School Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlanBeez/42-School_Coin/3eb2b8c6485b2a64abc2393ad47921c4898b0fd3/42_Logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "reward-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/42born2code/",
                "github": "https://github.com/42School",
                "instagram": "https://www.instagram.com/42.born2code/",
                "twitter": "https://twitter.com/42born2code",
                "website": "https://42.fr/en/homepage/"
            }
        },
        {
            "chainId": 101,
            "address": "AVNA86e6yJKD1QfdYhQuzqsSanpqUtMP1mX9KXRXehJ8",
            "symbol": "SWTR",
            "name": "Swell Travel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ScottSwell/token/main/swell_logo_token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ew162TdjnvYPhjhZAwcg1zMtn7MGz4tNs9kayyFfhhf",
            "symbol": "KOFI",
            "name": "Coffee Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/sebulino/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://sebastianalscher.de/coffee-coin/"
            }
        },
        {
            "chainId": 101,
            "address": "GJ6v9KhX8knyjSAdy9e6SnHwYX3ajuyAdNy4Uvc6AgBS",
            "symbol": "WAT",
            "name": "Watson Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CelticRogue/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9U4B5oQMXBkVcUvYp9c9CTRLAgFcbF3NeopuaxvVCW7y",
            "symbol": "MYB",
            "name": "Mindyourbiz",
            "decimals": 6,
            "logoURI": "https://github.com/Mindyourbiz9000/MYB/blob/main/MYB.jpg"
        },
        {
            "chainId": 101,
            "address": "CmAgr6XtAZsR1BGrxTrbVKXsVvvC4Y69GXR2zP8XxK3X",
            "symbol": "CHALK",
            "name": "CHALK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CmAgr6XtAZsR1BGrxTrbVKXsVvvC4Y69GXR2zP8XxK3X/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/ChalkBoredNFT",
                "website": "https://chalkbored.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "D1MQUD2QKg4HaMbasBq4gk8LWjaogBiGV2vdLkrodBkx",
            "symbol": "FWL",
            "name": "Fellowship WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D1MQUD2QKg4HaMbasBq4gk8LWjaogBiGV2vdLkrodBkx/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fellowshipdao",
                "twitter": "https://twitter.com/FellowshipDAO",
                "website": "https://fellowshipdao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8SGPkY7qCiYpodQLHNjuG2doBkbhDpTqwaLFnDJvS3Bk",
            "symbol": "GLC",
            "name": "GoodLuckCharm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Jbigga/verbose-octo-pancake/main/good-luck.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BsaTqsqD9JKM7mRzZ7U2MCLrXtfg2CuwbrFYVoTrYvHx",
            "symbol": "LBC",
            "name": "LaBella Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nicolalalalala/LaBellaCoin/main/StarLogo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2sZyMV6LdAD8v5rEVDWyYKFXWXY1PkVid9smCtsF4qog",
            "symbol": "PMP",
            "name": "PimpCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PimpCoinboys/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BV2AJmBUFnEXqAjXxhds5st6hoHnCfbCLzqnGY87ciWQ",
            "symbol": "SSP",
            "name": "Secret Syren Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BV2AJmBUFnEXqAjXxhds5st6hoHnCfbCLzqnGY87ciWQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "mofLoWHKWess7F63We227zfDcXD3YkgoR79BWX7DDSA",
            "symbol": "MOFBOT",
            "name": "MOF BOT token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mofLoWHKWess7F63We227zfDcXD3YkgoR79BWX7DDSA/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5ZaBjSwzhSr5vG35CDENqmkfvrHSnTwhm1dFLeBYmVxS",
            "symbol": "JAYDN",
            "name": "Jaydn Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jaydnchiert/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3z6Rry7DNjvcqbHZJgKv7gpa1XpMDuvxN66pLDfaLh8p",
            "symbol": "SQF",
            "name": "Squarefish",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ryanoff/squarefish-token/main/icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "56eg63pjkbYB3XYBWu23scue61W6emtNEuYNEBtsCWDy",
            "symbol": "ARK",
            "name": "arkayde-monkey",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yoavshmariahu/token-list/main/assets/mainnet/56eg63pjkbYB3XYBWu23scue61W6emtNEuYNEBtsCWDy/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://www.arkayde.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2pLrCRnbYBGbhANbUvkFXDYuuRNZNkesbvc8WSMQ9unX",
            "symbol": "DEVX",
            "name": "developer experience",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/35096265/151653614-7271949c-5d16-4b0e-b06f-af5b5983fb8d.png",
            "tags": [
                "utility-token",
                "Community-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "61m2xv1m6zTEAS86VfjFmNKG1ZGemNu19hzMmgstowLZ",
            "symbol": "SAMI",
            "name": "SAMI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tund1/images/main/SAMI.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/E7yWZrzvXj",
                "twitter": "https://twitter.com/SenshiNFT",
                "website": "https://senshisamurai.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5oegZC9FYh5dtvRGm8qVF8mBm6Haw83PCHmUjW5pF2h3",
            "symbol": "DOK",
            "name": "Dokdo Coin",
            "decimals": 5,
            "logoURI": "https://github.com/johnnyconto/Symbol/blob/main/symbol.png",
            "tags": [
                "charity-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4PDQFjLhWowgfCVJY4qmXtX3VDGirgCCs6vcByx63HMm",
            "symbol": "JUSTO",
            "name": "Justo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/justinvallely/justo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yUT3Dqe2Nz46iy9urmqEdntQSBywvSC1KoiktkEycvN",
            "symbol": "y-UST-USDC",
            "name": "Yield Saber LP Token (UST-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yUT3Dqe2Nz46iy9urmqEdntQSBywvSC1KoiktkEycvN/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3Ymo9BYodmFXkQLxQwCLWdp9PYBgn8Zu6sVkP4ZNi1u9",
            "symbol": "BMXGH",
            "name": "alaskabmx",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alaskagh/ALASKAGH/main/pngwing.com.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4n91Cp8ufhotnvvNj7zMZemYhCdfaJhSAQifGMGrDXNR",
            "symbol": "SCMK",
            "name": "Citizens Mint Key",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4n91Cp8ufhotnvvNj7zMZemYhCdfaJhSAQifGMGrDXNR/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wS932Vndrc",
                "twitter": "https://twitter.com/SolsteadsNFT",
                "website": "https://citizens.solsteads.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6hUBekzJqUybbKuBWKirjQuSvWTAb3LxxuAQVNVVnXJi",
            "symbol": "BRIAN",
            "name": "Brian Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brianmahlstedt/bmahlstedt.com/master/frontend/public/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2XR2o7RXgrtDU2E4QspqirqZRXHRkVTZSaXhCR2WUjc8",
            "symbol": "NBPL",
            "name": "NewberrySolToken",
            "decimals": 0,
            "logoURI": "https://github.com/iNewberry/soltoken/blob/main/newberry_white_225_250.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9AkMBNwwFXsMugHitdXdWTavJHuhtjU8d4n9fzXE2RAr",
            "symbol": "PERC",
            "name": "Crypto Nine 3D",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Monzingo89/CryptoNine3DCoin/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/UwBmvmjnqJ",
                "twitter": "https://twitter.com/CryptoNine3D",
                "website": "https://cryptonine3d.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8J7yrjW4JsZYiLUMWxyHu5V1bStvFQ7yD3jHrkTk88wk",
            "symbol": "DINO",
            "name": "Dino Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/vinceeverton/everton/main/daring%20dinos%20%23111131.jpg"
        },
        {
            "chainId": 101,
            "address": "gem1g5yUhMHr2hLcPVQJHK96XZGvZgvgrXLyoDaavZy",
            "symbol": "TyTokn",
            "name": "Token of Bone",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem1g5yUhMHr2hLcPVQJHK96XZGvZgvgrXLyoDaavZy/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem2bWYeGnwFLqif2LVejMos5HFMGpvCVT4oNDWAD22",
            "symbol": "TyTokn",
            "name": "Token of Amethyst",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem2bWYeGnwFLqif2LVejMos5HFMGpvCVT4oNDWAD22/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem3tNL3oLdJiuZ4WucMXbFqrgoUiSjqcxEqjFP4ex2",
            "symbol": "TyTokn",
            "name": "Token of Ruby",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem3tNL3oLdJiuZ4WucMXbFqrgoUiSjqcxEqjFP4ex2/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem4KsX8iPGmRcydTX7CjiSWWV2Ewp8Bk2d2xkjQErd",
            "symbol": "TyTokn",
            "name": "Token of Yellow Diamond",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem4KsX8iPGmRcydTX7CjiSWWV2Ewp8Bk2d2xkjQErd/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem5PzSyAVNRStfDxmeEtQ3hwJuxBFTtkhod7ky4yoE",
            "symbol": "TyTokn",
            "name": "Token of Citrine",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem5PzSyAVNRStfDxmeEtQ3hwJuxBFTtkhod7ky4yoE/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem6MmeKGHZswKKsj5nj7cVuRdUvgQmz15RvH1mGoM5",
            "symbol": "TyTokn",
            "name": "Token of Aquamarine",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem6MmeKGHZswKKsj5nj7cVuRdUvgQmz15RvH1mGoM5/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem7SRHvDPDNEiUgzCgkjsSpTTuWZwSvCTNjSocDBVh",
            "symbol": "TyTokn",
            "name": "Token of Emerald",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem7SRHvDPDNEiUgzCgkjsSpTTuWZwSvCTNjSocDBVh/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem8crKAucbrYSv6LoPQC7nsk8gD9Xegrf2cXQaM6Vv",
            "symbol": "TyTokn",
            "name": "Token of Sapphire",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem8crKAucbrYSv6LoPQC7nsk8gD9Xegrf2cXQaM6Vv/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem9c2awvvwqmmc2JzfvDi9NffTEupzGUbnNHbVVbtU",
            "symbol": "TyTokn",
            "name": "Token of Peridot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem9c2awvvwqmmc2JzfvDi9NffTEupzGUbnNHbVVbtU/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem1oHFv3SkPSC5E2jkaSLgMeK35MnE9JGPYabHWfwn",
            "symbol": "TyTokn",
            "name": "Token of Pink Tourmaline",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem1oHFv3SkPSC5E2jkaSLgMeK35MnE9JGPYabHWfwn/logo.png"
        },
        {
            "chainId": 101,
            "address": "gem117wcyhYdU4247o3nmCQ3cPZL4DpJWBkTgX8ZvZN",
            "symbol": "TyTokn",
            "name": "Token of Diamond",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gem117wcyhYdU4247o3nmCQ3cPZL4DpJWBkTgX8ZvZN/logo.png"
        },
        {
            "chainId": 101,
            "address": "Ct8kkYmeNZqofEcv3VibzoZPm75kiabs3VAdwdHFy4B4",
            "symbol": "DNK",
            "name": "Danaka",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/honeybkk/crypto/main/coin.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Y46b1AZFgoAbPq9oddoaDSahCaZWTh7AmdvgPZa9nN7",
            "symbol": "TASH",
            "name": "Magara Tash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/viol3/magara-solana/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "6vVD6QjoFjghKhXQtSc2yTSQaGTBxcUGnWfjnzbeeENK",
            "symbol": "CHIPS",
            "name": "Universe Chips",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6vVD6QjoFjghKhXQtSc2yTSQaGTBxcUGnWfjnzbeeENK/logo.png",
            "tags": [
                "utiliy-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cxmgnKtZBF",
                "twitter": "https://twitter.com/u_niverseNFT",
                "website": "https://twitter.com/u_niverseNFT"
            }
        },
        {
            "chainId": 101,
            "address": "5Wddj4FevPALWn655YTC5wGktWM7vViQTzLJLr2dhGFF",
            "symbol": "UPASS",
            "name": "Universe Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Wddj4FevPALWn655YTC5wGktWM7vViQTzLJLr2dhGFF/logo.png",
            "tags": [
                "utiliy-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cxmgnKtZBF",
                "twitter": "https://twitter.com/u_niverseNFT",
                "website": "https://twitter.com/u_niverseNFT"
            }
        },
        {
            "chainId": 101,
            "address": "6MxMHdu9xfnMC8ruHv3x4TdxR8W3S9J2hKBWBMyxtkTY",
            "symbol": "CUBR",
            "name": "Cubric",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/crypterix/cubr-token/main/logo.png",
            "tags": [
                "metaverse",
                "game-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ju4tKmMrJUX981gWg6icbB7DWMFAmo8MFwKHZReZYpp",
            "symbol": "FPA",
            "name": "OFS-FPA-COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wedd2626/roncrypto/main/OFSICON.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CTQBjyrX8pYyqbNa8vAhQfnRXfu9cUxnvrxj5PvbzTmf",
            "symbol": "BACH",
            "name": "BACH Token",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/badd1dbe8c2d1e38c4f77b77f1d5fd5c60d3cccb/assets/mainnet/CTQBjyrX8pYyqbNa8vAhQfnRXfu9cUxnvrxj5PvbzTmf/bach-token-logo-Est.2022.png",
            "tags": [
                "music-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://musik88.com/BACH"
            }
        },
        {
            "chainId": 101,
            "address": "9LwVPhjXZ2vc4yGmZu9buifRJvf6enWowzFRouAwAedj",
            "symbol": "AQLA",
            "name": "Aqualibre Project",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Aqualibre/AQLAlogo/main/AQLA.png",
            "tags": [
                "utility-token",
                "offset-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LibreAqua",
                "website": "https://www.aqualibreproject.com"
            }
        },
        {
            "chainId": 101,
            "address": "3mybJECUrNVJoqf8K84AzoLQHzpjBRtn1uTnY8gbiDrv",
            "symbol": "BUNI",
            "name": "Happy Bunnies Gold Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HappyBunniesNFT/Happy-Bunnies/main/Happy-Bunnies-Gold-Token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "96TaRFYTJceZSm5Y5m4wkdRjRS8NTUf8DzrcVGqL85F8",
            "symbol": "HBWT",
            "name": "Happy Bunnies Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HappyBunniesNFT/Happy-Bunnies/main/Happy-Bunnies-Silver-Token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "37yAJGaaqP5dPwTyVdTCWKr7YeiH9U8maCtGNEDky3si",
            "symbol": "ALIEN",
            "name": "Alien Universe Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/alien-universe/assets/main/logo.png",
            "tags": [
                "game-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8o6wCFnz4H3tNQf3P3jwc5Q38QksZmGi3WyiKsEDWpWF",
            "symbol": "ROTC",
            "name": "Rotar Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/potatos12333/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B5g4fUcVox9q6dowqAxm2EXNLuz45jiW2iFZcmvP2KvK",
            "symbol": "PQT",
            "name": "Pixel Quest Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B5g4fUcVox9q6dowqAxm2EXNLuz45jiW2iFZcmvP2KvK/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "The Solana Pixel Quest token",
                "twitter": "https://twitter.com/SolPixelQuest",
                "website": "https://solanapixelquest.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "ErPsVLjNj8sFWFui5JnaavLh4ZtpLq2rffJR1f1GNQAq",
            "symbol": "MFT",
            "name": "MetaFootwear Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SilverBM/logo/main/logo.png.png",
            "tags": [
                "NFT-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "2y7wUCJdtqTbjnKBNEB3DpAYCuwA1atCtdfkGt9VR4sM",
            "symbol": "$PSYn",
            "name": "PSYn",
            "decimals": 5,
            "logoURI": "https://github.com/charliebakerr/psy-token/blob/main/photo_2022-01-30%2012.23.01.jpeg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://psynetwork.io"
            }
        },
        {
            "chainId": 101,
            "address": "AXSJtZsAw4SCrJwt9vAVeNycKDKTUrEQ6rxzoimNUVoo",
            "symbol": "HPC",
            "name": "HpaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MasterC0derr/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8AY9mo8fTDdeuhCC3fAxV313gVKxko8SGWuPFuPFyrAF",
            "symbol": "MEIO",
            "name": "Meio Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/evzonic/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EXAzCuUPFRgq2vG5naJYJVW6Z68j45RfequsmYfwPfQy",
            "symbol": "FORS",
            "name": "Fors Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Thunberg087/ForsCoin/master/ForsCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D4F6Qkn6DYpCQFnuFmABSuKRPzdMGTvqVU4NovYJo8JD",
            "symbol": "DEFI",
            "name": "Crypto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/CryptoCoin.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Crypto",
                "DEFI"
            ]
        },
        {
            "chainId": 101,
            "address": "4mJ6N65rD9w6sFPQ17UDWot2H64UtzR31biVLaKpZT6J",
            "symbol": "ALIEN",
            "name": "AlienUniverse Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/alien-universe/assets/main/logo.png",
            "tags": [
                "game-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AlienUniversNFT",
                "website": "https://alienuniverse.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4AhAphjiE9M81KyHBJNHCHrYTzScm2wrxLSu5zCYKvWq",
            "symbol": "HUMAN-SOL",
            "name": "HUMAN-SOL LP Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4AhAphjiE9M81KyHBJNHCHrYTzScm2wrxLSu5zCYKvWq/humanlp.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "coingeckoId": "human",
                "coinmarketcap": "https://coinmarketcap.com/currencies/human-coin/",
                "discord": "http://discord.gg/5m6cBdry6y",
                "twitter": "https://twitter.com/thecyberkatz",
                "website": "https://cyberkatz.space"
            }
        },
        {
            "chainId": 101,
            "address": "GPiMHSiMsX94dGXYGaA4CkMNF74KXbqJk9y31tqMUhE8",
            "symbol": "Cultivate",
            "name": "Compassionate Care Cultivators Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theworkathomeacademy/cccultivate/main/cccultivate_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CooPeTCb9FBRUgVsptj8r13Nai1o3Sgd8rMhWi9Fcbs3",
            "symbol": "COOP",
            "name": "CoopBits",
            "decimals": 6,
            "logoURI": "https://arweave.net/-HbRJjyYpfVLILnWOhut7PtIt6UR5jjW44HLLBbDlEg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "2BZ8NMmnwbaxseLwQVmwb1N4vjYhF3KXi5iqnGmbDdWV",
            "symbol": "CAROL",
            "name": "CarolCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/reihtw/carolcoin/main/carolcoin_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Esq5cPGK4ifsm8sHLvnWJUEhupKXyyg8SqoE4B79wos",
            "symbol": "MNM",
            "name": "Mark \u0026 Myia C0in",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/darkjumper5/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "rk1n9BraH65rd8bgFW8YEVmhq4dmvAcETFrYdUdjLfa",
            "symbol": "Baap",
            "name": "Universe Baap",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/manassikri/token-list/ded03f5e31a5b45865821586c006ce2c822dbe63/assets/mainnet/rk1n9BraH65rd8bgFW8YEVmhq4dmvAcETFrYdUdjLfa/baap_icon.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C63abTB86tqri3LtuCyyK7ti1fC1RamovBUqYs94Fqcj",
            "symbol": "JULI",
            "name": "Juliette",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/poush/ipiyush.com/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GBjETEdSvrtuxQx3rywQTR9FJm6VJugJJ9Gxi9wrMiKg",
            "symbol": "OSSE",
            "name": "Ossecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GBjETEdSvrtuxQx3rywQTR9FJm6VJugJJ9Gxi9wrMiKg/logo.png"
        },
        {
            "chainId": 101,
            "address": "2Zz3HKQdHM1p4cUE714wkBs7VegVAfJH48Ww7AEV2oKu",
            "symbol": "CHCK",
            "name": "Checkoin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/outstandaedu/outstanda/main/logo.png",
            "tags": [
                "education-token",
                "education"
            ],
            "extensions": {
                "twitter": "https://twitter.com/outstandaedu",
                "website": "http://www.checkoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "4BfXSiVYZ2RT4dCcfTSjyhBWks2KyZoJL5gTUBpG4wQa",
            "symbol": "MSKM",
            "name": "MaskMan Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/realradical/crypto-sol/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Uebzt5ngRCx44JSakZWjPmBJjmvYkfDtYotCZFRyKLb",
            "symbol": "INKW",
            "name": "Inkwe Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrdawgza/inkwecoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "nU3vPxb6gVYZNuxXW3DuB77KViFh3iV8AXMcmpq7xjx",
            "symbol": "Khls",
            "name": "Khalsa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sukhbal/crypto/main/Khanda2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HXr5yRmXkA2qYQzkKXJ2yZS5f9QYDjPvTPT9PmApEY4J",
            "symbol": "SLIME",
            "name": "Slime Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HXr5yRmXkA2qYQzkKXJ2yZS5f9QYDjPvTPT9PmApEY4J/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.weareslimes.com"
            }
        },
        {
            "chainId": 101,
            "address": "2knG14icDCxtvCW5NC43CjgkgKxmnuPGv94GUj7bGp3s",
            "symbol": "AHOL",
            "name": "Alcohol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ahol-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DAwtdBT9MvZsRjQnajKka8R6SApTXU3smpHNjWrQizxq",
            "symbol": "OWE",
            "name": "IOU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/owe-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GXkh6qkKB16oJqLXgi8i7Q4bEESBYQYsZejZPb68KTRd",
            "symbol": "TNG",
            "name": "Thing",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/tng-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "29LTkpS6y3Uz1UtpSCCf44N586gsfmdF2mr5tCM1vBb2",
            "symbol": "WINE",
            "name": "Wine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/wine-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6zcdFNBup26LDU8s1VyeRTTeQgWCGhfv7CMnLnUT8jYH",
            "symbol": "INSIGHT",
            "name": "Insight",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/insight-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dhx1gTCqVdpZV99EkoroxQfQ1r3eQyBxJFBNXuvviNJC",
            "symbol": "AUTO",
            "name": "Automation",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/auto-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5JQm4bPcHZMYVX4kwRgfbJbGWwSNf7LcHoC2JQCGTaMF",
            "symbol": "EXPR",
            "name": "Experience",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/expr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8BxfVMgCxZG8Yx13AgtoewwLX7x5HHQaM7cLFJ2zKr3n",
            "symbol": "FOCUS",
            "name": "Focus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/focus-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DNvXGyCwk7MeUKPLEvLL8zThYapPUfqkY4GyGsLDZMdM",
            "symbol": "IOT",
            "name": "IOT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/iot-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BYHKZqqUVgFj82jKMmAcnuK3SPmQRvnjiSa3xtVzej4J",
            "symbol": "CHRG",
            "name": "Charge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/chrg-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G9sFdhjgpvpRzCNjbi5KHbSJiCLXQjb72sSnDNA2252B",
            "symbol": "STF",
            "name": "Stuff",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/stf-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2JuAFubeEdpjGhRzLqXTxHKYDrCt1ZAswSk1Yo6xf8A3",
            "symbol": "DRNK",
            "name": "Beer",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/drnk-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2CocrLUBKadDChAR7weK2vo6GE72zgWgBV3xXRcrHgG1",
            "symbol": "O",
            "name": "Orgasm",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/o-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9mzeQQGLvsrFCWw8VmN42sNioEUF8Y8isxWmQ7sATVzY",
            "symbol": "FTNS",
            "name": "Fitness",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ftns-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5DGt9RyzKKEBhpTndsdKSepcanHthQ3V88zimZAUrXTE",
            "symbol": "NATR",
            "name": "Nature",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/natr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Rsjs7DJrYBqcLWSBNtbQTGzdv9qQyUPcEXb6mjAhKgp",
            "symbol": "ADVC",
            "name": "Advice",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/advc-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AxjXRvhCGregr4hJGz3MizYk7a7Dk68hpyZ4iSgpVABi",
            "symbol": "DRCN",
            "name": "Direction",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/drcn-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2DUPqJ4p2ebFbayM6npoTab8AZP2tvkVLgVff5tnBUBn",
            "symbol": "WSKY",
            "name": "Whiskey",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/wsky-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BhEfh2YiRtWySpMukfayF6jDzVaxQSY7pjNVEqing99c",
            "symbol": "QUIET",
            "name": "Quiet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/quiet-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AMBsnZoeiWhsfk2RATCXSbG5KyGkqN9nNGwH9Db3RWR6",
            "symbol": "TIME",
            "name": "Time",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/time-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EMAqnp7coDmGRMtBGBv2NBtuu28ZUopzo9uH5ngBdS4T",
            "symbol": "INFO",
            "name": "Info",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/info-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6uybw8dfojtb71HNYvJkj2r7Ue1oGSGwNnHFXDJtGhCZ",
            "symbol": "RIOT",
            "name": "Riot C01N",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uybw8dfojtb71HNYvJkj2r7Ue1oGSGwNnHFXDJtGhCZ/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Riot C01N - first Riot Token/Coin",
                "twitter": "https://twitter.com/MrRoryRiot"
            }
        },
        {
            "chainId": 101,
            "address": "7n9ovGfYCwMwWS5PvZ6jJe9NgSTDhGCR9SSRfBaB9iJm",
            "symbol": "VNTS",
            "name": "Vintus",
            "decimals": 0,
            "logoURI": "https://github.com/VintusGH/crypto2/blob/main/vintus_official2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CPR34yNtEecfCNU86DhmAGMcTo2KNMuEpCDJL81hcPgr",
            "symbol": "CP",
            "name": "CornPop Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPR34yNtEecfCNU86DhmAGMcTo2KNMuEpCDJL81hcPgr/logo.png",
            "tags": [
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dopetweetsbrah"
            }
        },
        {
            "chainId": 101,
            "address": "3qX21t9nLt4AJ1NqdiVAikRdM6QfjJuPp3pz9cZyBM2a",
            "symbol": "SNM",
            "name": "SayNoMore",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Lange1990/SNM-Coin/main/snm-logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Crypto",
                "DEFI"
            ]
        },
        {
            "chainId": 101,
            "address": "8bxHnjDrhW4488XMwZih6aYEiHRCSySNy3DMfn43dbiC",
            "symbol": "MVZY",
            "name": "Mavzayo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mavrag/logos/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CXdmsYdhqFR6o8YC81mV9SVhhuooSeRFy2B7mi9eFnvW",
            "symbol": "RATTY",
            "name": "Rattie Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jonwretlind/rattie-token/main/token-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E74tNWRjKvYnULLoVdsniCfTvh6znSci1V9fkyyhNU3e",
            "symbol": "RMA",
            "name": "RMA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rom2282/RMA-coin/main/coin_m.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gxc4XkzAK6ykL15NR9c8SrwWSJCwPb3fqHsZeJKaB4xv",
            "symbol": "FCHC",
            "name": "HansaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dsinnig/hansaCoin/main/hansaCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ATZaUphhN7mrcwMdoY1hTRprwV1rxGPuWr1YkGNP8Axr",
            "symbol": "ALFAC",
            "name": "AlfaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alfatokens/crypto/main/alfa.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KDXvqikvXxc49SzLaLRa4uK7LbB9rrqDASvL4xJajqN",
            "symbol": "KDX",
            "name": "KDX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KDXvqikvXxc49SzLaLRa4uK7LbB9rrqDASvL4xJajqN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://kdx.cl/"
            }
        },
        {
            "chainId": 101,
            "address": "5gXAJQJ8ms9pKBaJtceoQ1yh8fjZLdvSicFCQKxqXaGK",
            "symbol": "IRPR",
            "name": "iRepairCLT Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ialzouby/iRepairCLT-Coin/main/2002.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EcSXCrLsB6nZ3ccr2VR6mrXXsXsCAWtG2a9cWMKn4CJt",
            "symbol": "ARTIFAKT",
            "name": "ARTIFAKT Mint Pass",
            "decimals": 0,
            "logoURI": "https://arweave.net/1lU3cQ15_5Wq3khZO_d_Jr9_xIlLhDTh_vbJrMtug0k",
            "extensions": {
                "description": "Mint Pass for ARTIFAKTS",
                "imageUrl": "https://arweave.net/1lU3cQ15_5Wq3khZO_d_Jr9_xIlLhDTh_vbJrMtug0k",
                "twitter": "https://twitter.com/ARTIFAKTS_sol",
                "website": "https://www.artifakts.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "6xUECSMxSjPGPMzauuCjJGkYgyLYC5UJWpv5en8twhPN",
            "symbol": "SKOL",
            "name": "SKOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6xUECSMxSjPGPMzauuCjJGkYgyLYC5UJWpv5en8twhPN/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/SolSkullsNFT",
                "website": "https://skolsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Hdrim63jd7Woa1u5MvME5GeaYysXCuKqdzQs3qFbDJNk",
            "symbol": "GROW",
            "name": "Growth",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/grow-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3smZZpSgFMFVkECTWMjwVTK89WzpJy8M77VDrzeAZ8Qi",
            "symbol": "PLA",
            "name": "Play",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/pla-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FFtpF7GuXB5Pjmug3wWwx9HpvXzCm5QmYEUjXUyxtVUB",
            "symbol": "CHANGE",
            "name": "Change",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/change-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MonYu4GQb1dpoMs4DG1FpJt5F9nXtUy6JRyvANZFxZu",
            "symbol": "MONY",
            "name": "Mooney Token",
            "decimals": 6,
            "logoURI": "https://arweave.net/b-zptDkkRt3xz9dNflpUxRMJMqFq40tPVS5wjuicDd4",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/moondusa",
                "telegram": "https://t.me/MoondusaTheNFT",
                "twitter": "https://twitter.com/Moondusa",
                "website": "https://moondusanft.io"
            }
        },
        {
            "chainId": 101,
            "address": "HG2UzJS8rbgsd16tZorT1mNiPJBjE7Xq4ifqsfZNK6h6",
            "symbol": "UXD-3Pool",
            "name": "Mercurial LP Token (UXD-3Pool)",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HG2UzJS8rbgsd16tZorT1mNiPJBjE7Xq4ifqsfZNK6h6/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "BezE74cco4xB2pw8bjo1aUgPPDrvs3iD2N3mWSxNPQ3a",
            "symbol": "GEPG",
            "name": "Grow East Point Germination",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cpatt2015/Crypto/main/Germ.png",
            "extensions": {
                "twitter": "https://twitter.com/SolSkullsNFT",
                "website": "https://skolsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6n9VoqhzkNZzZxfoFhCvFNFVYPetzxD9qBvoUPE3Z9XF",
            "symbol": "SML",
            "name": "Smiley",
            "decimals": 0,
            "logoURI": "https://github.com/kekhongdau01/crypto/blob/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "87qRcHFYN4w6VWcSiiWNFCH62EhNjUpb5sHP3ww5kPn5",
            "symbol": "NED",
            "name": "Ned Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nimanezhadi/web3/main/logo.png",
            "extensions": {
                "website": "https://nimanezhadi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "vCKbdx7ShGcyRvTiex76SLj3YqcgR3oBwQeDhEa6YSw",
            "symbol": "SHL",
            "name": "sarhanilabs",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vCKbdx7ShGcyRvTiex76SLj3YqcgR3oBwQeDhEa6YSw/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sarhanilabs"
            }
        },
        {
            "chainId": 101,
            "address": "RAVA1GsKtUXyciThQMxssm15tB9hF8NFQjZM6kincqD",
            "symbol": "RAVA",
            "name": "RAVAverse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RAVA1GsKtUXyciThQMxssm15tB9hF8NFQjZM6kincqD/logo.png",
            "tags": [
                "Governance-token",
                "Gaming",
                "NFT",
                "Exchange",
                "Metaverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/Sr9epbgEW4",
                "telegram": "https://t.me/Ravaverse",
                "twitter": "https://twitter.com/Ravaverse"
            }
        },
        {
            "chainId": 101,
            "address": "3xbqJgCsf2PjKQsjACGUvH2Eo8ZyGxW2hWGY9TZW2q8D",
            "symbol": "ALPHA",
            "name": "Alpha",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3xbqJgCsf2PjKQsjACGUvH2Eo8ZyGxW2hWGY9TZW2q8D/logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5iTsXxzEm2msviveLGCXJpQYDqaCJHJfUvKDUMQiS2QF",
            "symbol": "911",
            "name": "911Academy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5iTsXxzEm2msviveLGCXJpQYDqaCJHJfUvKDUMQiS2QF/logo.png",
            "tags": [
                "social-token",
                "util-token"
            ],
            "extensions": {
                "website": "https://policeacademynft.com"
            }
        },
        {
            "chainId": 101,
            "address": "GdK4LNeHCMnBgWJewgeJ11usnd59UHQ7avUmVmFomUz3",
            "symbol": "SolanaIdols",
            "name": "Solana Idols Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdK4LNeHCMnBgWJewgeJ11usnd59UHQ7avUmVmFomUz3/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token",
                "Anime",
                "NFT",
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/k2mAEUKhcs",
                "twitter": "https://twitter.com/SolanaIdols",
                "website": "https://www.solanaidols.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HX8kZQDV5oqyM1md8Z6nxEhfPdM4GFfPEYdyhehM8vBY",
            "symbol": "ISCC",
            "name": "Iskald Cola",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IskaldBrus/8FAF47FE52A4DAAC2CEEE7E46EBC7/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8gCUGpSFcruuAGGCy1rMnhr3CSna95thNte6uCAbdWgv",
            "symbol": "CRPSV",
            "name": "Corpusville Membership Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/osy93/token-list/8fc5460c667d817645f26e7b8a732ef3a25138d8/assets/mainnet/8gCUGpSFcruuAGGCy1rMnhr3CSna95thNte6uCAbdWgv/logo.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "description": "Token to Mint Corpusville Membership Pass",
                "discord": "http://discord.gg/3fH3cVVFfg",
                "twitter": "https://twitter.com/CorpusvilleNFT",
                "website": "https://corpusville.com"
            }
        },
        {
            "chainId": 101,
            "address": "6YeTi7npbcyMZRqjVwY7zxW53iE39rMAzLErn3mTftAc",
            "symbol": "Eros",
            "name": "Eros Project",
            "decimals": 9,
            "logoURI": "https://github.com/starcodez/erosproject/raw/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "NFT",
                "nfts"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ErosToken",
                "website": "https://eros-project.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2xkgFzA7DSRodWAFXfgSyAgKJh9Js2TnPS2DcGGSks9e",
            "symbol": "hash",
            "name": "Hash Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/harsh16kh/solana_quests/main/hash.jpg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5F8AtyhSt325X27eGAZTQmfyqQwfs7J2yAWc32WN1goP",
            "symbol": "ANH",
            "name": "Anahera",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5F8AtyhSt325X27eGAZTQmfyqQwfs7J2yAWc32WN1goP/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ENA76HKkDfDbAaeosGvfFXB6NPFY8L8V2KkBAwnT6JXF",
            "symbol": "rayTICKET-USDC",
            "name": "rayTICKET-USDC LP TOken",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENA76HKkDfDbAaeosGvfFXB6NPFY8L8V2KkBAwnT6JXF/logo.jpg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "telegram": "https://t.me/theticketfinance",
                "twitter": "https://twitter.com/TheTicketSol",
                "website": "https://theticket.finance"
            }
        },
        {
            "chainId": 101,
            "address": "82xYSLKQ5xBca6rkQSG3Vjt7T6bGhbiwkUeYwk6NSt4",
            "symbol": "SORTED",
            "name": "Sorted",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/minopop/sorted/master/sortedicon.png",
            "tags": [
                "social-token",
                "utility-token",
                "community",
                "Crypto",
                "DEFI"
            ],
            "extensions": {
                "description": "Sorted is Australia’s first all-in-one home services marketplace – allowing you to connect everything you need for your home, all in one place.",
                "website": "https://www.sortedservices.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8trdTr5Qz964dpCMZzWwmz9XeN1JDUGPxmVeYZHEhEsp",
            "symbol": "GBLE",
            "name": "G-able Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Chiewchi/crypto/main/gable.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J21YDEoGj4FwDsHUHRYky5eHYGpgBN5BEq9kfNefxcqt",
            "symbol": "BONES",
            "name": "GoldboneMusic",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/KatrellTurner/crypto/main/BONES%20LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7cuxRG3fkYzegvUzQarhG3UdVDbu7RoLi3ua4YXDTfeo",
            "symbol": "TIGC",
            "name": "tiger year coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zihichuang/tigercoin/main/2022-tigc-w.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TXdGde6R2WFF8WWbaEFiPQsa7vxY7Scqu1L4e3djScw",
            "symbol": "FAPEIS$",
            "name": "FAPEIS Token WL",
            "decimals": 0,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/9c6903db-114f-4d46-db76-b3fc6f88f700/public",
            "extensions": {
                "discord": "https://discord.gg/swWYcCMvdK",
                "twitter": "https://twitter.com/facelessapes444",
                "website": "https://facelessapeis.com"
            }
        },
        {
            "chainId": 101,
            "address": "DRT5F5jJN9NWrz3gURDrGWCvs8GR41KddYQ78vdxchGM",
            "symbol": "SK8TL",
            "name": "SK8-T-L",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/TariqMBooker/assets/main/download2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9JxDDwnCYAmEznRLeMYrjT8GiGBFM7VzRgsqkiLqjhas",
            "symbol": "YOLO",
            "name": "YoloCoin",
            "decimals": 9,
            "logoURI": "https://github.com/YoloBoyz446/Yolo-Coin",
            "tags": [
                "Party-Asset",
                "Yolo"
            ]
        },
        {
            "chainId": 101,
            "address": "H3rZJzvMWrLp6u5ED2FCSeYeAxih9tJEQTxae8obisjT",
            "symbol": "MASS",
            "name": "SolPlanets MASS Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/vts666/masslogo/main/masslogo.png",
            "extensions": {
                "discord": "http://discord.gg/RNAgPeZXnV",
                "twitter": "https://twitter.com/solplanets_nft",
                "website": "https://solplanets.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9YoiEstQbAgQscqN4xeSsWk1S5yENwRHP74kgvAMGVjQ",
            "symbol": "ROUX",
            "name": "Flying Carpet KE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9YoiEstQbAgQscqN4xeSsWk1S5yENwRHP74kgvAMGVjQ/FC.KE.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A8KtBhjWn5P4JLrsNvW1ANPuQnqe3aZyvo5QcECrQPd9",
            "symbol": "DROP",
            "name": "DROP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A8KtBhjWn5P4JLrsNvW1ANPuQnqe3aZyvo5QcECrQPd9/logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/supadropnft",
                "website": "https://www.supadrop.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7q3P7FmKW9ChvzNe81yfeuNGn8XsvwwfVNJS7S5WzXap",
            "symbol": "FRISCOS",
            "name": "Friscocoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fiasco67/friscocoin/main/logo.svg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://crypto.milkywayfiasco.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9T3Rjp9KajQARj4Fje91HJ7HTMB4fQfyAPaUzaM7M9yP",
            "symbol": "PUSH",
            "name": "P",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BrandonClem/Crypto/main/P.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PUSHpresident"
            }
        },
        {
            "chainId": 101,
            "address": "4W1KbtB5tqZBkKaSqZpEc8387GJ8v2xbcMgXub6SVVRX",
            "symbol": "ECT",
            "name": "ECLIPTIC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4W1KbtB5tqZBkKaSqZpEc8387GJ8v2xbcMgXub6SVVRX/logo.png",
            "tags": [
                "social-token",
                "community",
                "Crypto"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/ecliptic.crypto",
                "twitter": "https://twitter.com/eclipticcrypto"
            }
        },
        {
            "chainId": 101,
            "address": "6AMG3z14ia54ThCCAB84fduRAJ2DxwAdMyj5LRrtmsQB",
            "symbol": "CHI",
            "name": "Freemason Chisel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chiseltoken/Crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/STU3w2XtYN",
                "facebook": "https://www.facebook.com/chiseltoken",
                "instagram": "https://www.instagram.com/chiseltoken/",
                "reddit": "https://www.reddit.com/r/chiseltoken/",
                "twitter": "https://twitter.com/chiseltoken"
            }
        },
        {
            "chainId": 101,
            "address": "8ixdtoh5uLvYsScHDfkEKHU1WZhqEYM3YcCva3qn1Kpu",
            "symbol": "HD",
            "name": "HD Crypto Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/k3nn8y/Coffee-Token/main/8ixdtoh5uLvYsScHDfkEKHU1WZhqEYM3YcCva3qn1Kpu.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C14M2mnzdQf5v4vqN9PGnWCasS3oRi2YpAneUmW7XBV4",
            "symbol": "MKFI",
            "name": "MEIKAFI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/natenation8977/cryptonate/main/meikafi.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZAyVG8WQPXt98qqopmFVEMH7dn5k4yDzVZG9XuXcgF3",
            "symbol": "STZR",
            "name": "SolSeltzer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolSeltzer/Crypto/main/logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A63H4R1nNSZMxFjn9D3AejKL6KXfuY5H75T8zqwj7ryU",
            "symbol": "GAMBLE",
            "name": "Casino",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Casino.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Casino",
                "Gambling",
                "Betting"
            ]
        },
        {
            "chainId": 101,
            "address": "6kfKCfN6FKuGEEPrLXR5nYavM8MVj59VymNN1CpAfSw3",
            "symbol": "HOOT",
            "name": "Owlcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/owlcoin-sol/owlcoin/main/300px.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ATRfazNAYY",
                "twitter": "https://twitter.com/0wlcoin",
                "website": "http://owlhoot.club/"
            }
        },
        {
            "chainId": 101,
            "address": "61KT9S6byGpq5FmYDKkg1N36Cj4KXDtH1gjsuJTYd24L",
            "symbol": "CIO",
            "name": "Opincur",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/road12runner/opincur/main/opincur_logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://opincur.com"
            }
        },
        {
            "chainId": 101,
            "address": "7Vv2bCbK3geqiVgGcTnDM29qyEWpnJzTUbS5LDWpniuH",
            "symbol": "CHAIN",
            "name": "Blockchain",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Blockchain.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Blockchain"
            ]
        },
        {
            "chainId": 101,
            "address": "Taki7fi3Zicv7Du1xNAWLaf6mRK7ikdn77HeGzgwvo4",
            "symbol": "TAKI",
            "name": "Taki",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Taki7fi3Zicv7Du1xNAWLaf6mRK7ikdn77HeGzgwvo4/logo.png",
            "extensions": {
                "website": "https://gettaki.com"
            }
        },
        {
            "chainId": 101,
            "address": "CkRtyP2dNZeCTXrZPc1kJngKYQVq5E2YJtPUQs9UxUYE",
            "symbol": "COASE",
            "name": "CoaseCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MarkovExp/CoaseCoin/main/coaselogo.png",
            "tags": [
                "utility-token",
                "meta-verse",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "http://exp.trading/coase"
            }
        },
        {
            "chainId": 101,
            "address": "HiukEL37kKv9fhTYsq8Re8osTSJmeCjEK2AjaTUE7mdF",
            "symbol": "GCCR",
            "name": "Gulf Cooperation Council Riyal",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/theashin197/logo/main/gccr%20Small.png"
        },
        {
            "chainId": 101,
            "address": "FA49FHHB1DUCL9WPykw9yf58voRk92WwYNDjMa5Gq7tc",
            "symbol": "HYPE",
            "name": "ByteHype",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FA49FHHB1DUCL9WPykw9yf58voRk92WwYNDjMa5Gq7tc/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://ByteHype.io"
            }
        },
        {
            "chainId": 101,
            "address": "CEXwCLooS5Bf2qrzf18QoePYESD1JrzErGSdeaBd6Uwj",
            "symbol": "SKET",
            "name": "Sketch Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/kekhongdau01/crypto1/main/istockphoto-535498384-612x612.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AuxoBFAsVZ6Ez5TKK8cmnQ8cktCpYmE1bjp98M4Zeuju",
            "symbol": "AK$",
            "name": "AlienKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Melsatan/Crypto/main/aliencoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
            "symbol": "DUST",
            "name": "DUST Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/dedao",
                "twitter": "https://twitter.com/degodsnft",
                "website": "https://docs.dustprotocol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3bBzthYLDgF58nE5khLJ88Ub7MAehsZPAD5V1uxS17Tg",
            "symbol": "VIK",
            "name": "Vikriyah",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vikriyah/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SLBwAxvWSfYqh2ZmPpNQ3Lo2Qxu8nn5D5mjbpNap5Yr",
            "symbol": "SLB",
            "name": "Benfica Fan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLBwAxvWSfYqh2ZmPpNQ3Lo2Qxu8nn5D5mjbpNap5Yr/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BenficaToken",
                "website": "https://www.slbenfica.pt"
            }
        },
        {
            "chainId": 101,
            "address": "SCP22eJcC3eSXxG51BQqfxsf3S2nQnGjXpnT3KT72Tu",
            "symbol": "SCP",
            "name": "Sporting CP Fan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SCP22eJcC3eSXxG51BQqfxsf3S2nQnGjXpnT3KT72Tu/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SportingToken",
                "website": "https://www.sporting.pt"
            }
        },
        {
            "chainId": 101,
            "address": "62Unc9Dm8buA2iY35tTUCJ4ERoUp5Q7K8EWFiRTDfBp2",
            "symbol": "OOMintTicket",
            "name": "OO Golden Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/matlirman/token-list/main/assets/mainnet/62Unc9Dm8buA2iY35tTUCJ4ERoUp5Q7K8EWFiRTDfBp2/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FCPtL4RqWGLjstUxWpniCkHHNYuYFnVY7po2hoWgFEhT",
            "symbol": "FCP",
            "name": "FC Porto Fan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCPtL4RqWGLjstUxWpniCkHHNYuYFnVY7po2hoWgFEhT/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PortoToken",
                "website": "https://www.fcporto.pt"
            }
        },
        {
            "chainId": 101,
            "address": "To6BjpcUwXXP2XGUe8KeFFXjJos8wayUmHuM9ePkGWj",
            "symbol": "IHRV",
            "name": "IHrTeam NFT Valentine",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/valentinenft/token/main/IHR-ihrteam.png"
        },
        {
            "chainId": 101,
            "address": "H5euuuZXAuFak2NVTMu53fckdkHFWuJzXXb3TfKTrLWK",
            "symbol": "DEV",
            "name": "DevCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/thedevdojo/devcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "aWXP3vpT9MhFWDoMEYG5ssYG72dZN5Cb8cLWDLLThpB",
            "symbol": "CIGO",
            "name": "Crypto IGO",
            "decimals": 9,
            "logoURI": "https://github.com/starcodez/cryptoigo/raw/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CryptoIGO1",
                "website": "https://crypto-igo.com"
            }
        },
        {
            "chainId": 101,
            "address": "7SNxNjT82XpoBmkHJnEMyCxssrbTrvt3F386N7RknXBS",
            "symbol": "GRR",
            "name": "GorillaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/K-code-k/ctypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8DxYVnEoLyuTs6Mgz4XbwXckZvwHryMxyLFGKqvY3C3c",
            "symbol": "VSEN",
            "name": "Vibudha Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LordQuantum/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7mhmh7qNVWkUUcdyoY5r17NwDHz9Lq5hxwPFX59hycq1",
            "symbol": "GEPS",
            "name": "Grow East Point Seeding",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cpatt2015/Crypto/main/Seeding.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fknt5RuY2Zd2GwKbHCzRTYCQBL8Ry9ugNHCcicvVKmQB",
            "symbol": "WSBI",
            "name": "WasabiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/luke911able/Crypto/main/WasabiCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2dsWvsHBE5biqZtNDYDUfxGVWKC7xSfwTArwic8MJAWy",
            "symbol": "BLCWL",
            "name": "Bluechip Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NFTBrickLayer/assets/main/BLC-logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MR4LmLsWNyWZXzo3jHq1abv1ZwPwjGewrPoHsNSHVJk",
            "symbol": "BCBY",
            "name": "BCBY: Land WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SupCa/token-list/main/assets/mainnet/MR4LmLsWNyWZXzo3jHq1abv1ZwPwjGewrPoHsNSHVJk/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/bcby",
                "twitter": "https://twitter.com/BarnyardNft",
                "website": "https://www.bcbarnyard.info/"
            }
        },
        {
            "chainId": 101,
            "address": "9N1hNVjr74nDMp6RZbGR8bwjbbrHpVZnu8B3cZiqaPoX",
            "symbol": "CABRAL",
            "name": "Amilcar Cabral",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mikusher/cabral/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mikusher",
                "website": "https://www.mikusher.com"
            }
        },
        {
            "chainId": 101,
            "address": "2sc6jWgvjMdFpofZMB6Vsrsih58mp1jJoQss4chLSgxQ",
            "symbol": "ACER",
            "name": "Acer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Maho2021/Crypto/main/Logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8t2JQrLWDN8HJ5jTNKzVYgJasmxpaqQW6yo6dx9M3BTK",
            "symbol": "$MYTH",
            "name": "Myth",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8t2JQrLWDN8HJ5jTNKzVYgJasmxpaqQW6yo6dx9M3BTK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C4WG3YZCbN",
                "twitter": "https://twitter.com/nft_godz",
                "website": "https://godznft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5LjLH9C6HBGh5qWBFDyhpreK9RSru2PAGmzAGVZBDbzM",
            "symbol": "PEERS",
            "name": "Peers Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/aprilkeely12/logo-image/main/final_big_test_coin-removebg-preview.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G766VkCHqBZDTYYvu7ntnfjZbqRKtjrTgsLWcxUiHxjx",
            "symbol": "ZAH",
            "name": "ZAH",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZAH-Token/Adding-ZAH-Token/main/PIXELMONEY.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9pV7YvmrvW74WTBPpK7WK8FYyuLGo5aLBL2ewSxMGutz",
            "symbol": "LONCH",
            "name": "Lonch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lonchus/lonchcoin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lonchnow",
                "website": "https://lonch.io"
            }
        },
        {
            "chainId": 101,
            "address": "5BEpcYXeJcMfwPmiZjQmpWKb4DXJfEKiQCLtQ3qEjQGD",
            "symbol": "LCUBE",
            "name": "LCube Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lcubestudios/token/main/Lcube-logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lcubestudios",
                "website": "https://lcubestudios.io"
            }
        },
        {
            "chainId": 101,
            "address": "Ajz6Gc9H4efEfHXCpzNJwcg1dacUn6numfFoUHJcAUT1",
            "symbol": "MTTA",
            "name": "Crazy Mitta Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/VerheijenWebdevelopment/CrazyMittaCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "APEbcwKE1yriVQVtmkgFKdyr7pn7Bj8q1KDgc1nMdXaA",
            "symbol": "RAMT",
            "name": "Rich Apes Mint Token",
            "decimals": 0,
            "logoURI": "https://imgur.com/4mlZg5G.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheRichApes",
                "website": "https://therichapes.com"
            }
        },
        {
            "chainId": 101,
            "address": "6eaYoP2rXxGfPbRxnQPrsp9Hh26FaUBsbBXeYdyzQon7",
            "symbol": "FALC",
            "name": "FALCOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6eaYoP2rXxGfPbRxnQPrsp9Hh26FaUBsbBXeYdyzQon7/logo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FAJL_sol",
                "website": "https://furiousapeleague.com"
            }
        },
        {
            "chainId": 101,
            "address": "Dgfbbkzs8eg48HoQGjuzPzqUDyoZhsXs47cYB7MB335o",
            "symbol": "SORP",
            "name": "Solana Orietnal Rug",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolSeltzer/Solana-Oriental-Rug/main/Untitled-3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ASVHLM6aY3dcfXfQrdcxfUMPdZkjWQfcVx7fB8SbYo89",
            "symbol": "BTMC",
            "name": "Bit Mania Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bit-illa/pfiles/main/BitMania-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3J58Ax7KPxLKr5BDoYFVnVjmugyQFi1hq4z4F5ji8yLH",
            "symbol": "Rasta",
            "name": "Rasta Rabbits",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/proninkirill/Rastar/main/rastar.png"
        },
        {
            "chainId": 101,
            "address": "7iV5gpBxv5kerSmu6qwPym2eHKiBrURS4RL3sHaq7vUq",
            "symbol": "DPN",
            "name": "Dolphin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/zia999/logo1/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolDolphinn"
            }
        },
        {
            "chainId": 102,
            "address": "7FzVSAJVxpgC7WkXdypLfdAhBfc1ZmkEoW49cxpAaw8T",
            "symbol": "FAT",
            "name": "FYFY Airdrop Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7FzVSAJVxpgC7WkXdypLfdAhBfc1ZmkEoW49cxpAaw8T/logo.png",
            "tags": [
                "airdrop-token",
                "reward-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6jEMcEVU",
                "facebook": "https://www.facebook.com/fyfy.io",
                "github": "https://github.com/fyfylabs",
                "instagram": "https://www.instagram.com/fyfy.official",
                "linkedin": "https://www.linkedin.com/company/fyfy",
                "medium": "https://fyfy-io.medium.com",
                "twitter": "https://twitter.com/fyfyio",
                "website": "https://fyfy.io",
                "whitepaper": "https://fyfy-labs-ltd.gitbook.io/fyfy-documentation/"
            }
        },
        {
            "chainId": 102,
            "address": "7rdCoFnW6kvzr3fEinFUw51CJKQBsuLQSQ2boBiqqPkC",
            "symbol": "FGT",
            "name": "FYFY Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rdCoFnW6kvzr3fEinFUw51CJKQBsuLQSQ2boBiqqPkC/logo.png",
            "tags": [
                "governance-token",
                "utility-token",
                "liguidity-pool-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6jEMcEVU",
                "facebook": "https://www.facebook.com/fyfy.io",
                "github": "https://github.com/fyfylabs",
                "instagram": "https://www.instagram.com/fyfy.official",
                "linkedin": "https://www.linkedin.com/company/fyfy",
                "medium": "https://fyfy-io.medium.com",
                "twitter": "https://twitter.com/fyfyio",
                "website": "https://fyfy.io",
                "whitepaper": "https://fyfy-labs-ltd.gitbook.io/fyfy-documentation/"
            }
        },
        {
            "chainId": 101,
            "address": "UUGAAWxhG2TXnyZHA4jDUaSJQQi2nf6UymtsBspRihz",
            "symbol": "UUGAA",
            "name": "UUGAA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gorillazmaster/UUGAA/main/logo.png",
            "tags": [
                "social-token",
                "game-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/fHbsnvSraj",
                "twitter": "https://twitter.com/GorillazReborn",
                "website": "https://gorillazreborn.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9usSKpVhb4PXthq5RuLc87JvFaCTobiMTNsCqWVwcPz1",
            "symbol": "FNT",
            "name": "FYFY NFT Token",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9usSKpVhb4PXthq5RuLc87JvFaCTobiMTNsCqWVwcPz1/logo.png",
            "tags": [
                "nft",
                "social-token",
                "marketplace"
            ],
            "extensions": {
                "discord": "https://discord.gg/6jEMcEVU",
                "facebook": "https://www.facebook.com/fyfy.io",
                "github": "https://github.com/fyfylabs",
                "instagram": "https://www.instagram.com/fyfy.official",
                "linkedin": "https://www.linkedin.com/company/fyfy",
                "medium": "https://fyfy-io.medium.com",
                "twitter": "https://twitter.com/fyfyio",
                "website": "https://fyfy.io",
                "whitepaper": "https://fyfy-labs-ltd.gitbook.io/fyfy-documentation/"
            }
        },
        {
            "chainId": 101,
            "address": "DY6VRL9LNYfAMQhhjfEjoHK3hE9esmapvrHsVzxYNMmJ",
            "symbol": "VRTY",
            "name": "VRTY Coin",
            "decimals": 0,
            "logoURI": "https://vrtymedia.blob.core.windows.net/mprd/l/7AdWWIrHn6ZkpftdN7JIUo",
            "tags": [
                "social-token",
                "utility-token",
                "reward-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Q3nSH2htJ1jZBbBmMpFDFuZAGK3QwNUN3uHDosQiW9n",
            "symbol": "mickies",
            "name": "Mickey Rose",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrninjastyle/mickeyrosecoin/main/heartlogoo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FKQX91BgH633Ww72Jpeq2g11MC3MTP97k4Nd2bk2W2Vn",
            "symbol": "rinTICKET-USDC",
            "name": "Aldrin TICKET-USDC LP Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FKQX91BgH633Ww72Jpeq2g11MC3MTP97k4Nd2bk2W2Vn/logo.jpg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "telegram": "https://t.me/theticketfinance",
                "twitter": "https://twitter.com/TheTicketSol",
                "website": "https://theticket.finance"
            }
        },
        {
            "chainId": 101,
            "address": "717xKUm4FVLeHb5F2PDYNAsbzhi8wo4ZpNFyaHr2KLK2",
            "symbol": "MNTYC",
            "name": "Minty Crypto Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/717xKUm4FVLeHb5F2PDYNAsbzhi8wo4ZpNFyaHr2KLK2/github300-300.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AfnXwfaZiLPz7aEFwDFoy3TgXSngfzCo9WrpDg4HXCFJ",
            "symbol": "MILK1",
            "name": "MILKTOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/hynfort/hynimg/main/D8vJNIscy8y5xr7jxiRsH3rB9g17NW9byRaTgVTLWuo.png",
            "tags": [
                "nft",
                "milk-test",
                "marketplace"
            ],
            "extensions": {
                "discord": "https://discord.gg/test",
                "twitter": "https://twitter.com/test"
            }
        },
        {
            "chainId": 101,
            "address": "rBsH9ME52axhqSjAVXY3t1xcCrmntVNvP3X16pRjVdM",
            "symbol": "rBASIS",
            "name": "rBasis",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rBsH9ME52axhqSjAVXY3t1xcCrmntVNvP3X16pRjVdM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Redeemable BASIS token.",
                "discord": "https://discord.gg/basismarkets",
                "medium": "https://basismarkets.medium.com/",
                "twitter": "https://twitter.com/basismarkets",
                "website": "https://staking.basis.markets"
            }
        },
        {
            "chainId": 101,
            "address": "DD69a6abqhsjFsg4TAX98YFggUsuFCzLVBNRpqoudycK",
            "symbol": "GCHARM",
            "name": "G-CHARM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DD69a6abqhsjFsg4TAX98YFggUsuFCzLVBNRpqoudycK/Logo.png",
            "tags": [
                "general-token",
                "gaming-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GlyphBound",
                "website": "https://www.kayac.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2Z21GPCZSyrieTN3tYtPHUmuLWBffW9L86wqyZBj84Fv",
            "symbol": "BROCCOLI",
            "name": "BROCCOLI",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Z21GPCZSyrieTN3tYtPHUmuLWBffW9L86wqyZBj84Fv/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cybervillainz"
            }
        },
        {
            "chainId": 101,
            "address": "AJPR96dTbGBH8jxzX85Ux9D1HyNe9NfNhKABm9t7gG6Z",
            "symbol": "PSM",
            "name": "Pasame Shares",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jdaniel96/PasameSharesLogo/main/LogoPasame.png",
            "tags": [
                "Pasame-shares"
            ]
        },
        {
            "chainId": 101,
            "address": "AWLDWua47fVu5f8uxiCCLJPXBJAdsyRM11fESt8jRceo",
            "symbol": "Arcryptian",
            "name": "Arcryptian WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWLDWua47fVu5f8uxiCCLJPXBJAdsyRM11fESt8jRceo/logo.png"
        },
        {
            "chainId": 101,
            "address": "FRrNp3cT1TPFVesRYi3gChz8j1hQepnEo6PDkxWcbp4D",
            "symbol": "DOCO",
            "name": "DoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/docoin2022/crypto/main/docoin_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DAEhUmgrWxSjw3YgCsgkRXg5A2w3wN2juGdsgLV7Ughn",
            "symbol": "LIV",
            "name": "OLIVIACOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mistrnomad/crypto/main/logo.fw.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiVtPXzfKQcBy9nyHxfTxXQjLG6XjCF5inExCGSQ8zVa",
            "symbol": "DIV",
            "name": "DIVERGENTI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiVtPXzfKQcBy9nyHxfTxXQjLG6XjCF5inExCGSQ8zVa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://divergenti.cl/"
            }
        },
        {
            "chainId": 101,
            "address": "DwgiPBuMVcV84hGUtSzSiiPy5RYwA1PjDnXa2cnhskaw",
            "symbol": "PAZO",
            "name": "Harpazo",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/baymonex/token/main/Harpazo_logo.png",
            "tags": [
                "gaming-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "ToTYKUcdnb2pt16b2TzPRoKJirPx4zQQjcawDQVTLUX",
            "symbol": "SPW",
            "name": "SPW Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToTYKUcdnb2pt16b2TzPRoKJirPx4zQQjcawDQVTLUX/logo.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ssj",
                "twitter": "https://twitter.com/SolanaJourney",
                "website": "https://solpetwar.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HCW4kbsPuZiRSSnuyFf896WHtsVvnVkDWtbBi46vkEaM",
            "symbol": "UNIM",
            "name": "UniMint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/unimintco/token-logos/main/Unimint.png",
            "tags": [
                "general-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EfGAveovMu59SpE5gNzoB4NtXmFiTw3EyQjikDBrvznb",
            "symbol": "KISS",
            "name": "KISSES and ROSES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sonfero/crypto/main/KISS_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "webCtmAsLtNszwVRdi1AzQrGLRLhHQy4boxxxjT4ypZ",
            "symbol": "SIMI",
            "name": "DR SIMI COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JorgeLoredo-1852/DrSimi/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "8tyHzSLPonrknXft8UfVRVWyVSFo12Cq9xoB12rCfWFk",
            "symbol": "OIRA",
            "name": "oikeaRahe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Atzuni/cryptoRahe/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "38G9b9JjjkYsGHV5QYD35oeAVvnAjVoBpSD31u1RDeXu",
            "symbol": "HYPE",
            "name": "HYPER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HYPERWARRI0R/hypercoin/main/logo.png",
            "tags": [
                "gaming-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "GACtwBgE4T6SrwDk9Va8PShVR7Q9ZYfrJShygz8D2dJr",
            "symbol": "MSA",
            "name": "Muscida",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rutcraft2/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fr9Ze1rnhC6djPuRQEW1aSsVCLR1iRqWweg5gbgFjECm",
            "symbol": "SCHOOL",
            "name": "School Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/YoungMonksNFT/YoungMonksToken/main/School.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/youngmonksnft",
                "website": "https://youngmonksnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5bwwZ41kzLWAZy1MqKttc5GW2vrE42hZ49EbJg6dpxSV",
            "symbol": "MINTYWL",
            "name": "Minty Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MintyAutomation/minty-logo/main/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MintyAutomation"
            }
        },
        {
            "chainId": 101,
            "address": "GkPeYwnggoxmUx7bSRKL8UmgUHhkENAdGVgWVpT5T6nY",
            "symbol": "HOGS",
            "name": "Hoglympics",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkPeYwnggoxmUx7bSRKL8UmgUHhkENAdGVgWVpT5T6nY/logo.png",
            "tags": [
                "gaming-token",
                "NFT"
            ],
            "extensions": {
                "description": "The Hoglympics is a next-gen crypto metaverse project incorporating esports with blockchain, NFTs, and DeFi to produce immersive, P2E Olympic Games.",
                "discord": "https://discord.com/invite/6kVjb99aqx",
                "telegram": "https://t.me/hoglympics",
                "twitter": "https://twitter.com/hoglympics",
                "website": "https://hoglympics.com"
            }
        },
        {
            "chainId": 101,
            "address": "4Y75QsYX8fayufQD2ottjgc1jAbaFpoA4Z8QcSFk63wi",
            "symbol": "HOWL",
            "name": "HOWL",
            "decimals": 9,
            "logoURI": "https://arweave.net/9WBYgIcpJNldRxozBXSmZ7Mm-Wt8ug2y0A6TXy2XZd4?ext=png",
            "extensions": {
                "twitter": "https://twitter.com/HonshuWolves"
            }
        },
        {
            "chainId": 101,
            "address": "CboZhKpk2VCFKXhHWqNWregP5t5vxTcpcgw5agQaCFdQ",
            "symbol": "$DSOULS",
            "name": "DragonSouls",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Soulsdragon/SoulsIcon-v2/main/%24DSOUL.png",
            "tags": [
                "general-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9e6nnqbsTjWx3ss6a3x7Q9ZvpupLNYLb8cTbVmm6UD2K",
            "symbol": "SHUT",
            "name": "Shuttlecoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mikkel76543/crypto9/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DKrjaRTyhRozuKS1euhsmdLNc2K1fTYrrRXBjFdeec1o",
            "symbol": "KINY",
            "name": "KinnyCoin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/kinnycoin/kinnycoin/main/logo.jpg",
            "tags": [
                "media",
                "social-token",
                "payments",
                "solana-ecosystem",
                "web3"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kinnycoin"
            }
        },
        {
            "chainId": 101,
            "address": "9AtZcfJn1xVHukAnw3LPmveDYiDjMK4vBcQ4ajkf8U9q",
            "symbol": "MCOP",
            "name": "Metacop Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9AtZcfJn1xVHukAnw3LPmveDYiDjMK4vBcQ4ajkf8U9q/logo.png",
            "tags": [
                "nft",
                "nft-token",
                "utility-token",
                "dao-token",
                "governance-token",
                "liquidity-pool-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fucn68GYbH",
                "github": "https://github.com/whiterabbit-hub",
                "twitter": "https://twitter.com/cops_nft",
                "website": "https://metacopsnft.io"
            }
        },
        {
            "chainId": 101,
            "address": "CnHURDBf35hWkyXjHrVUQBiBx1kXKsVggk24wUKzNufL",
            "symbol": "PWOO",
            "name": "Pirates of the Woo DAO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CnHURDBf35hWkyXjHrVUQBiBx1kXKsVggk24wUKzNufL/logo.png"
        },
        {
            "chainId": 101,
            "address": "GW799gGPTbARkg4WpuMx5Qe8fW1H3ocQfjKGo5z73ETP",
            "symbol": "PAPER",
            "name": "Printer",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GW799gGPTbARkg4WpuMx5Qe8fW1H3ocQfjKGo5z73ETP/logo.png",
            "tags": [
                "community-token",
                "NFT"
            ],
            "extensions": {
                "description": "Passive Income is everyones goal!"
            }
        },
        {
            "chainId": 101,
            "address": "77zKXdvwqC3H9v84GPpQfnJ3EsRbjWESLe9bh5z7oQct",
            "symbol": "FRUIT",
            "name": "fruit Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/77zKXdvwqC3H9v84GPpQfnJ3EsRbjWESLe9bh5z7oQct/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHZVKrkCRXVDp8U8Tn9KEgvSPoKTuLW5L7NwA7jUinve",
            "symbol": "SISC",
            "name": "StepInsight Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomasstas/sis-token/main/sis-round-icon.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://stepinsight.com.au"
            }
        },
        {
            "chainId": 101,
            "address": "5TPYH5sMvFMitzsjzM9xrxq5igpVuGugVu8CLzCrFdFW",
            "symbol": "Ras",
            "name": "Ras",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/proninkirill/Rastar/main/rastar.png"
        },
        {
            "chainId": 101,
            "address": "By6s91ubScp1b3SnboQvZrHkGvek3VR6CgUfk53PpZBH",
            "symbol": "TDCS",
            "name": "Tu Dices",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ayiking/TuDicesCrypto/main/TuDiceLogo-min.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ccq1heMs7LN173FU5rpzv1Ajhf2Bqbm4fr2Eg6ZoYsnS",
            "symbol": "ROK",
            "name": "Rosko",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Roxirious/ROK-Crypto/main/ROK_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9vyYNbfFKDQ4uraNuLRWuAzyTPe9myjEzEYV71j7Jr7f",
            "symbol": "JNX",
            "name": "Captain JiNX Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CaptainJiNX/ctjinx-coins/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "github": "https://github.com/CaptainJiNX",
                "twitter": "https://twitter.com/captain_jinx"
            }
        },
        {
            "chainId": 101,
            "address": "9vCQncWkt5iUP5oYFQF6o4SD4zhiScd4SdrYNp2Rid61",
            "symbol": "ROB",
            "name": "Rob",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vCQncWkt5iUP5oYFQF6o4SD4zhiScd4SdrYNp2Rid61/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AZwhZChzhdfbnEZJ2gLuda4p2ib19GpN5k28tevyk8KL",
            "symbol": "KNKO",
            "name": "Koneko Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AZwhZChzhdfbnEZJ2gLuda4p2ib19GpN5k28tevyk8KL/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/5bDQmVRUgS",
                "twitter": "https://twitter.com/KonekoCoin",
                "website": "https://konekocoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "8yQuj5v4s72UqZi3sYZL5rAD4NPV4ueUwBKzChBDWMVf",
            "symbol": "MRX",
            "name": "Maars",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maarstoken/crypto/main/maars-800x800.png",
            "tags": [
                "social-token",
                "community-token",
                "nft-token",
                "gaming-token",
                "web3-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PaPa6D4Rys4Lcj1d5csabmDv3QdUE2T1QQ6sWNgoeTa",
            "symbol": "PAPPA",
            "name": "Psychic Warriors of Pappataz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PaPa6D4Rys4Lcj1d5csabmDv3QdUE2T1QQ6sWNgoeTa/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zJWZyRjcMJ",
                "twitter": "https://twitter.com/PappatazWarrior",
                "website": "https://www.pappataz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CCoin6VDphET1YsAgTGsXwThEUWetGNo4WiTPhGgR6US",
            "symbol": "CatchCoin",
            "name": "CatchCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CCoin6VDphET1YsAgTGsXwThEUWetGNo4WiTPhGgR6US/icon.png",
            "tags": [
                "CatchCoin",
                "game-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/catchking",
                "twitter": "https://twitter.com/catch_king",
                "website": "https://www.catchking.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8KiHGb2hvWGJoW2Ges8ZcdebWLtXKPjbF2VAjDYLrjxg",
            "symbol": "MORG",
            "name": "MorganMillionsRYAN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ryanhurt99/cryptopic/main/Slide1.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6d5Sz3eEXXTvqGXye3XjFzhPT6ozzBoMDYdJd9sLCQSq",
            "symbol": "KRK",
            "name": "KRK FINANCE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6d5Sz3eEXXTvqGXye3XjFzhPT6ozzBoMDYdJd9sLCQSq/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jpWc7A6dcf",
                "twitter": "https://twitter.com/krkfinance",
                "website": "https://krk.finance"
            }
        },
        {
            "chainId": 101,
            "address": "8zVkHZVb9tsJFQrj75FTa23qFGv5payVz9uaGmGwytD9",
            "symbol": "FLAC",
            "name": "FLATAM COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/33b083e2fd4a906d227b4051b53ccfd07c160037/assets/mainnet/8zVkHZVb9tsJFQrj75FTa23qFGv5payVz9uaGmGwytD9/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CvRjLqr3fwtQEe3RETTQCqX2CJAvMzKf613yC6DfyBDM",
            "symbol": "PRTS",
            "name": "PARTS",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CvRjLqr3fwtQEe3RETTQCqX2CJAvMzKf613yC6DfyBDM/logo.png",
            "tags": [
                "community-token",
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HZJCKAqZhdKx1vK3nSEQyN59YKypJ5p6m5U2WbJzdvvJ",
            "symbol": "KSPR",
            "name": "Ghost Hallmon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZJCKAqZhdKx1vK3nSEQyN59YKypJ5p6m5U2WbJzdvvJ/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://ghosthallmon.com"
            }
        },
        {
            "chainId": 101,
            "address": "ZrmxFkEed9ofNDn3upBittTUqiFgQiQYiZVRvrcNqt5",
            "symbol": "DOTORI",
            "name": "Dotori Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dotoritoken/dotori/master/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4eYYeaFqjscucKTUBHfgQe5zf8xF8YXqTA55EfXFYxB6",
            "symbol": "AF",
            "name": "Amoeba Friends",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4eYYeaFqjscucKTUBHfgQe5zf8xF8YXqTA55EfXFYxB6/logo.png",
            "extensions": {
                "discord": "https://discord.gg/amoebafriends",
                "twitter": "https://twitter.com/AmoebaFriends",
                "website": "https://amoebafriends.com/"
            }
        },
        {
            "chainId": 101,
            "address": "C2APzN3zNXsW3Mfufp2EYpwGPwGU1MXjrFo7A2csiAza",
            "symbol": "DSi",
            "name": "Digital Silicon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hinchingyuen/Digital-Silicon/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gn4C5DBFotntR2FQQoYNjzfZNiu4TB2A3rvtdiLwDiJ9",
            "symbol": "YUM",
            "name": "Candy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Candy.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Candy"
            ]
        },
        {
            "chainId": 101,
            "address": "DMEF1wi1nWquM4QaKZYwDX5qcRY67mD8zCn5DwsWM9V2",
            "symbol": "TEAM",
            "name": "Sports",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Sports.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Sports"
            ]
        },
        {
            "chainId": 103,
            "address": "8zVkHZVb9tsJFQrj75FTa23qFGv5payVz9uaGmGwytD9",
            "symbol": "FLAC",
            "name": "FLATAM COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8zVkHZVb9tsJFQrj75FTa23qFGv5payVz9uaGmGwytD9/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "g5wiWvADNDYvC2csLE4PDJDQqiRndmjSCedd3LVWrUa",
            "symbol": "FAM",
            "name": "Family Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tpadilla/CryptoL/main/FamilyCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75W3RAgbH4HNRPFxhus7Xhsvrcujv3j5xTk56M5v18p2",
            "symbol": "STRK",
            "name": "StrikeForce",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/spacepriest/StrikeForce/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3DswPbTaZ3S8AvQdGSGKbksMsLCVcK72jo1z16ysyxBx",
            "symbol": "PSTD",
            "name": "PIZZA SHARK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tobytuuby/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8P4SaLw2hqrqPzAEX5MhhnQ4xMAMDbnV9YB1q4WmVKDK",
            "symbol": "MVDC",
            "name": "MVP Test TOken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rahulsrs/crypto/main/logo.png",
            "tags": [
                "social-token",
                "test-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2WRrEvBHdyR95BYwtGgRt5GzcL4JTS6PDtkP8v7bLzi2",
            "symbol": "SFIM",
            "name": "StrikeForce Initiate Medal",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spacepriest/StrikeForce/main/medal/strikeforce%20initiate/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MywLpWzJ9AxM1psuDNJmSTiLhYnuGtChUXc4WEfGvAB",
            "symbol": "MONYWL",
            "name": "WL Moondusa",
            "decimals": 0,
            "logoURI": "https://arweave.net/HZlX45BxqQqJ5KHwpMsz9Kg0EsjzjmNOGjWxqUzMyGs",
            "tags": [
                "whitelist-ticket"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/moondusa",
                "telegram": "https://t.me/MoondusaTheNFT",
                "twitter": "https://twitter.com/Moondusa",
                "website": "https://moondusanft.io"
            }
        },
        {
            "chainId": 101,
            "address": "4s4EAQY68kJGvuUrcGqyj2mt2YTxkbxLUniJfE9KxSoJ",
            "symbol": "OVCN",
            "name": "Ovecon Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/mstation/ovecon_token/main/ms-icon-310x310.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "http://ovecon.com"
            }
        },
        {
            "chainId": 101,
            "address": "AdU884KcTWz9yR6MJ4V2ZHUxDFhMoSuCr6VfRgvh4rxM",
            "symbol": "APSR",
            "name": "Apsara Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AdU884KcTWz9yR6MJ4V2ZHUxDFhMoSuCr6VfRgvh4rxM/APSR.jpg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A18dvmCWZLQQZrTZhjea6XsNqBmNhrcmiyi1cUMmFAth",
            "symbol": "FIN",
            "name": "FINUP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/taboglevi/Finup/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "security-token",
                "payment-token"
            ],
            "extensions": {
                "description": "Finup is online investment platform",
                "facebook": "https://www.facebook.com/24finup/",
                "instagram": "https://www.instagram.com/finup24/",
                "linkedin": "https://www.linkedin.com/company/finup24/",
                "telegram": "https://t.me/finup_24",
                "website": "https://www.finup24.com"
            }
        },
        {
            "chainId": 101,
            "address": "4quErP8SY5HFiAvmV5g5atATnSbhgj9z2c3yW74ea5wA",
            "symbol": "FAT",
            "name": "Fatura Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faturame/crypto/main/Fatura_LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FpM1DKUxX9Z2rZwT9iwBWTniTpxmdLQ3RYTaGLVLej3Z",
            "symbol": "DON",
            "name": "DON coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Raunakj90/DONcrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dp118fyErWC8mrexAVwweDqBc5MtV7FprQffKSmFvzkY",
            "symbol": "FST",
            "name": "FUNKY SANTAS TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dp118fyErWC8mrexAVwweDqBc5MtV7FprQffKSmFvzkY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/pr3yWdGZYx",
                "instagram": "https://www.instagram.com/funkysantasnft/",
                "twitter": "https://twitter.com/FunkySantas",
                "website": "https://funkysantas.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Y8Je5kBiTj2kN4T8gFMwSfcQLDfUVdYaUWeR2LvkRsA",
            "symbol": "BURN",
            "name": "Absolut Burning Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Y8Je5kBiTj2kN4T8gFMwSfcQLDfUVdYaUWeR2LvkRsA/logo.jpg",
            "tags": [
                "memes-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Benji_in_Crypto"
            }
        },
        {
            "chainId": 101,
            "address": "AF3BcDnqaJhdpKQ9jskZjohnTYRwqrv8H6Yyv4zUvA5g",
            "symbol": "HORDE",
            "name": "HORDE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ORCNFT/horde/main/logo.png",
            "tags": [
                "social-token",
                "Orc-Racing-Club"
            ]
        },
        {
            "chainId": 101,
            "address": "aosppr4qSxirqUx9QW8bMXZQbqYCJ5dPgJTBjhLTHNr",
            "symbol": "AOS",
            "name": "Apes On Space Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aosppr4qSxirqUx9QW8bMXZQbqYCJ5dPgJTBjhLTHNr/token48_48.png",
            "tags": [
                "nft-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cmUJSVqK",
                "twitter": "https://twitter.com/aos_NFT",
                "website": "https://apeson.space"
            }
        },
        {
            "chainId": 101,
            "address": "93RhkFuXYDsJcm1EEgGEd9XVndR1rAsUQ4GDZbD5maGd",
            "symbol": "BND",
            "name": "BannerDesk",
            "decimals": 6,
            "logoURI": "https://www.bannerdesk.com/logos/logo256.png",
            "tags": [
                "utility-token",
                "payment-token"
            ],
            "extensions": {
                "website": "https://www.bannerdesk.com"
            }
        },
        {
            "chainId": 101,
            "address": "AuJ451gN7buxyJ33iMsCZoUyUnxx1KUkQuHVGWBJRrqt",
            "symbol": "IDO",
            "name": "Wedding",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Wedding.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Weddings",
                "Wedding"
            ]
        },
        {
            "chainId": 101,
            "address": "7DU6krT61iVHs2pGyJiG2DDVtfxiCFH7kHTKUPShao2R",
            "symbol": "XOXO",
            "name": "HUGS and KISSES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sonfero/crypto/main/HUGS_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2nMMKu8Z9E1WkaeU2GkyY7txPGPgHk8aWVDKwcb4HeSw",
            "symbol": "SOOCY",
            "name": "SOOCY Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/soocyme/coin/main/Logo_Final.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://soocy.me/"
            }
        },
        {
            "chainId": 101,
            "address": "WLLnZwAKJcN7aMXUFhfejSPj56D48e6DiNuMa6Qud8C",
            "symbol": "TPACWL",
            "name": "The Pension Apes Club WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLLnZwAKJcN7aMXUFhfejSPj56D48e6DiNuMa6Qud8C/logo.png"
        },
        {
            "chainId": 101,
            "address": "CRjfXCXkGr3x8awncuj54XuPhgtFyFc7zasYigLdiZuX",
            "symbol": "DDCTK",
            "name": "DopeDolphinCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mbc241/DopeDolphinToken/main/dolphin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4MUy23aeZTPZJvU4dePb4QnWEd85jgvMKm82y6vWTX6g",
            "symbol": "DDSOL",
            "name": "DAO GSOL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Brazil11/cryptoPNG/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DKu822yyAYFXn5XZo7J1aUNPaGxLNhrLEvGK6qw1ex8L",
            "symbol": "XENOMINT",
            "name": "Xenobots Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DKu822yyAYFXn5XZo7J1aUNPaGxLNhrLEvGK6qw1ex8L/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xenobotsnft",
                "twitter": "https://twitter.com/XenobotsNFT",
                "website": "https://thexenobotsproject.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7xDuMPzWoHL9xDXtM141y38sBFmjDKEuQfh2BuHQ7Mjh",
            "symbol": "JD00G",
            "name": "JD00G",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicarr/JD00G/e1e9f48d25c0df4593e29497766e445e6c468489/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Krw1NNq1KzofMe8a5qVqkmvuERveVXHh1bqNqmBXs9f",
            "symbol": "OUTC",
            "name": "Outcast Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoelAakko/OUTC/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "9buJYEsVDkSi1BeAUQZaA34ATvU8WqLFvqGFLCY6RHGy",
            "symbol": "DD",
            "name": "DUDE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9buJYEsVDkSi1BeAUQZaA34ATvU8WqLFvqGFLCY6RHGy/dd.svg"
        },
        {
            "chainId": 101,
            "address": "2qiwtngAgHsrz6biuGgqEictAtbHxjZkqoGH4RLBWgJ5",
            "symbol": "NAC",
            "name": "Nadi Coin",
            "decimals": 6,
            "logoURI": "https://github.com/Effendi-lab/NadiCrypto/blob/main/NAC_logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/+x9YbzrNSda5iMGVk",
                "website": "https://github.com/Effendi-lab"
            }
        },
        {
            "chainId": 101,
            "address": "f4PugEJmvcf4VEVG4PzCZcfmjJzRwYDzgMtKZ5BhBc9",
            "symbol": "DSOUL",
            "name": "Dragon Souls",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Soulsdragon/icon/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "6cCQc1BZk6Y8ZrKRf8odLQn7Zb5cTnTFFme5UZgEPZJe",
            "symbol": "BALI",
            "name": "babyLI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cCQc1BZk6Y8ZrKRf8odLQn7Zb5cTnTFFme5UZgEPZJe/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Blockchain"
            ],
            "extensions": {
                "telegram": "https://t.me/babylitoken",
                "website": "https://babyLI.at"
            }
        },
        {
            "chainId": 101,
            "address": "8CQHGtSjgyVGeXARWSwei89GJ8bumWRPhCAFY2XYqJnL",
            "symbol": "USDS",
            "name": "USD Stable",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/unimintco/token-logos/main/USDS/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "32NkWgx6KaLpTEYb9ape4XNWff1n6Bt9rsmwepiJzxop",
            "symbol": "GIGALAND",
            "name": "Gigaland",
            "decimals": 9,
            "logoURI": "https://github.com/starcodez/gigaland/raw/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Blockchain"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GigalandWallet",
                "website": "https://gigaland.art"
            }
        },
        {
            "chainId": 101,
            "address": "3TVUHQgnmW1xqS5fTuDoNDay95DTRRbizbo962UN1KzX",
            "symbol": "CFP",
            "name": "ColdFusion Protocol",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DanteLogan/cfp-token/main/newlogo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EDKGGSFR34h1QcQg42uo5V9iQn7VkNy8ETziXzNg6Fkd",
            "symbol": "SFM1",
            "name": "Cobro StrikeForce Initiate Medal",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spacepriest/StrikeForce/main/medal/strikeforce%20initiate/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "Awarded for successfully completing the StrikeForce Initiation Training",
                "imageUrl": "https://raw.githubusercontent.com/spacepriest/StrikeForce/main/medal/strikeforce%20initiate/logo.png",
                "website": "https://strikeforce-cobra.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6tLPcYzdq4RizeFq7AK4mfR4uSqXncFse3uAiAQ72VGr",
            "symbol": "REIN",
            "name": "Reinbow",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/reinbowapp/token/main/logoclear.png",
            "tags": [
                "social-token",
                "social-media",
                "social-media-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4nxUY1jjtKZR27XcqJCW36kvAZi1iaceBTmgMK1i95gB",
            "symbol": "TOAST",
            "name": "Toast Crumbs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4nxUY1jjtKZR27XcqJCW36kvAZi1iaceBTmgMK1i95gB/logo.png"
        },
        {
            "chainId": 101,
            "address": "ATC6C1AL4X51FXNFbPG5pxfjSgDQCNECtfsnyMUnk9X1",
            "symbol": "NVGD",
            "name": "NEOVANGUARD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATC6C1AL4X51FXNFbPG5pxfjSgDQCNECtfsnyMUnk9X1/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://neovanguard.com"
            }
        },
        {
            "chainId": 101,
            "address": "HLccaGrQyyfiDddCGmE5h3sysNE7gYFbcDb4KS68fvM9",
            "symbol": "FUBAR",
            "name": "Fubar Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gruntlabs/fubar-coin/main/assets/FUBAR_icon.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "The most F!@#'d up beyond all recognition crypto",
                "website": "https://coin.fubar.icu/"
            }
        },
        {
            "chainId": 101,
            "address": "GtBwdtJvjyh3f7auZpABP7gswsiimw8D7geWZc8rzH9Q",
            "symbol": "GEEXO",
            "name": "GEEXO Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GtBwdtJvjyh3f7auZpABP7gswsiimw8D7geWZc8rzH9Q/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/NAvfaa3vJg",
                "instagram": "https://www.instagram.com/geexolotls/",
                "twitter": "https://twitter.com/geexolotls",
                "website": "https://www.geexolotls.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AEZNG2XxvjBumWsuKmZDUcF7qcA54d1WDssGcYzyDcww",
            "symbol": "PAUS",
            "name": "PAUS crypto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jorge-teixeira/token-list/708b475844860fc9c4de27518cdaca0810cf03c9/assets/mainnet/AEZNG2XxvjBumWsuKmZDUcF7qcA54d1WDssGcYzyDcww/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "255RFt4MZ4nXQ8qQzKzpSvSipnqcUMPQEJKzvy4fHk8x",
            "symbol": "PPL",
            "name": "PPL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/emavalerio441/token/main/pp-token-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CRdyak46KB6tFw7cACHqufKkg9QNmrQvhwrJiKJRd1gs",
            "symbol": "SICO",
            "name": "SI COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/johnnysp90/crypto/main/Crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3XVr2zjhnd7gNeroKXqaTBa7pLLvZ6jBzDfzLWTMehua",
            "symbol": "CONE",
            "name": "Cone Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3XVr2zjhnd7gNeroKXqaTBa7pLLvZ6jBzDfzLWTMehua/logo.png"
        },
        {
            "chainId": 101,
            "address": "LiVirTXDeLzv7d8xAz6oWvGzjYPQ3kuSmxd8FPPtpye",
            "symbol": "LIVI",
            "name": "Livi Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LiVirTXDeLzv7d8xAz6oWvGzjYPQ3kuSmxd8FPPtpye/logo.png"
        },
        {
            "chainId": 101,
            "address": "Raa82Hb2GwCgb2uL2UsWc4C6UBMA282ondDCzaiYJJh",
            "symbol": "GOYELLA",
            "name": "GoyellaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PipSqueak7/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JBonCvaUNnxA6etyp2pivccTCjyGdEetZDzksrfTNzeQ",
            "symbol": "BBIT",
            "name": "BABYBOI Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/breslaiv/token-list/main/assets/mainnet/JBonCvaUNnxA6etyp2pivccTCjyGdEetZDzksrfTNzeQ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7eJCLyW5KkvzdzkVXs1ukA1WfFjCcocXjVit64tYcown",
            "symbol": "SLITE",
            "name": "SolanaLite",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaLite/SolanaLite-Brand-Kit/main/SolanaLite%20(SLITE)%20Logo%20Solana%20Blockchain.svg",
            "tags": [
                "SolanaLite"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/GbQAgQKxdN",
                "reddit": "https://www.reddit.com/r/SolanaLite",
                "telegram": "https://t.me/SolanaLite",
                "twitter": "https://twitter.com/SolanaLite",
                "website": "https://www.solanalite.io"
            }
        },
        {
            "chainId": 101,
            "address": "Ee8tkCzVse1ch95AfavmHE8yJamJrCg78SoVhSzYJhbu",
            "symbol": "PZL",
            "name": "Puzzle Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlanBeez/Puzzle-Token/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5HF5GxkFT8QcurPAygeTejS7Mikut7JuxLpwmP7uTXX7",
            "symbol": "rayTICKET-SOL",
            "name": "rayTICKET-SOL LP Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5HF5GxkFT8QcurPAygeTejS7Mikut7JuxLpwmP7uTXX7/logo.jpg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "telegram": "https://t.me/theticketfinance",
                "twitter": "https://twitter.com/TheTicketSol",
                "website": "https://theticket.finance"
            }
        },
        {
            "chainId": 101,
            "address": "2sf6MtQgAwthVrXZmTUKH1myuP8z1N7vXT87t34bw3Gj",
            "symbol": "MNSTR",
            "name": "Monsterz Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2sf6MtQgAwthVrXZmTUKH1myuP8z1N7vXT87t34bw3Gj/logo.png",
            "tags": [
                "NFT",
                "art-token",
                "artists-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/monsterzdao",
                "twitter": "https://twitter.com/MonsterzDao",
                "website": "https://monsterzdao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Gv5dNeSwtkLfD3sX1KVo13LWcv6u3mWSSKtRcGWS6XaP",
            "symbol": "FLTL",
            "name": "Flametail Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pyromorphite/crypto/main/Logo.png",
            "tags": [
                "social-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3mxhqjhdQD6fveJqfeXuxpiVhH4aVzwSqmbouRMCbuWW",
            "symbol": "SSWL",
            "name": "Sp00ky Sk0Ls Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3mxhqjhdQD6fveJqfeXuxpiVhH4aVzwSqmbouRMCbuWW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://spookyskols.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7osDiPy41gwDddxH7Lh6Fdq3LHss97wYqfE5M6bXsNyi",
            "symbol": "SSGA",
            "name": "Sp00ky Sk0Ls Giveaway",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7osDiPy41gwDddxH7Lh6Fdq3LHss97wYqfE5M6bXsNyi/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://spookyskols.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AHAZhu8c1yk6wzgYxVgwyZCromhWu2358qc3jDMavHMV",
            "symbol": "DSMKT",
            "name": "Dusty Market Coin",
            "decimals": 0,
            "logoURI": "https://github.com/cenisa/cryptologo/blob/main/dustylogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EmBDSpQb3hJ6dNYShJy9wxu1g7xjFovA943wYLzQLyNy",
            "symbol": "TWRA",
            "name": "TWRA Deer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/floridanative1956/crypto/main/TWRA1.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2pJt6MSNJisSwaoqHC1yHbZsU42reJLpGowt7M6Yf3fX",
            "symbol": "ALCP",
            "name": "Alice Pizza Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlanBeez/Alice-Pizza-Coin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "game-token",
                "pizza-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/alice_pizza",
                "website": "https://www.alicepizza.it/"
            }
        },
        {
            "chainId": 101,
            "address": "Fa8GFAcQmoipwkacVFPiPh3MvN9EWRksGNdHBk2eLppU",
            "symbol": "KFT",
            "name": "Krofft Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Christian33333/crypto/main/crypto-logo-6.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8wCTFEhu3Yihx2BB5i2tt5v8JwBzi1JQsvwS82QEbCKg",
            "symbol": "SECTACOIN",
            "name": "SECTACOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrhili/SECTACOIN/main/secta.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8wwQf6gjqxdamBj2MQ64Rat3kRJ7VxGkuLMETzdTfGhm",
            "symbol": "CHIP",
            "name": "Poker",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Poker.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Poker",
                "Gamble"
            ]
        },
        {
            "chainId": 101,
            "address": "JCKkaqMLstBgJffRLhbRvPnv8Wkdm4pgfCnBZq64CuRL",
            "symbol": "BOGD",
            "name": "Bog Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mibadvanced/BogCoin/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B2PKbRKPT5HFv71vzu5z86NqCTPmAB6n8xQkBQRADe5z",
            "symbol": "AL1",
            "name": "Aluz One",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B2PKbRKPT5HFv71vzu5z86NqCTPmAB6n8xQkBQRADe5z/logo.png"
        },
        {
            "chainId": 101,
            "address": "7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu",
            "symbol": "EDO",
            "name": "EDO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/E7yWZrzvXj",
                "twitter": "https://twitter.com/SenshiNFT",
                "website": "https://senshisamurai.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EmPxJRmDU9y3kh1XcrzDXYRUpHh875eSWKEA529eCGDN",
            "symbol": "DKZ",
            "name": "DarkZed Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/darzed/DarkZed-coin/main/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/darvaszoltan",
                "website": "https://darvaszoltan.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4F4NLLPjeLo7KLCEynQNHvQos4DyuTSbgAPmPULFsyrQ",
            "symbol": "MJ",
            "name": "Megajoule",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4F4NLLPjeLo7KLCEynQNHvQos4DyuTSbgAPmPULFsyrQ/logo.png",
            "tags": [
                "utility-token",
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "9X298b5Ypa64iCstzn7cQmND2kdgEFftUKTQSANmpbnw",
            "symbol": "BWS2WL",
            "name": "BitWhips S2 Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9X298b5Ypa64iCstzn7cQmND2kdgEFftUKTQSANmpbnw/logo2.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bitwhips.io/"
            }
        },
        {
            "chainId": 101,
            "address": "H4uy24qcfPjp3aUfFKub9ZPUqCa1isZSn11NWTbGu6ys",
            "symbol": "SANZ",
            "name": "SANZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jablinjables/cryptosol/main/SanaNewLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B4ZVvb3H6nHXrxWXVC4dKt5Wv2QAwWM9CmhFVVPxCYEh",
            "symbol": "JETC",
            "name": "Jetcoin",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/25322469/152279588-8f92996a-2b48-4b75-9354-099a056879dd.png",
            "tags": [
                "social-token",
                "utility-token",
                "community"
            ],
            "extensions": {
                "facebook": "https://facebook.com/jetdevs",
                "linkedin": "https://linkedin.com/company/jet-devs",
                "website": "https://jetdevs.com"
            }
        },
        {
            "chainId": 101,
            "address": "2vCyKnkPjBrnRVRQBMiunJLUpFMPjp6mwD8jLb5KKYPZ",
            "symbol": "NWZ",
            "name": "Nftwinz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vCyKnkPjBrnRVRQBMiunJLUpFMPjp6mwD8jLb5KKYPZ/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://crypto.nftwinz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EXQgN2S5baFSqBR9XijnGvtunemdhhj2f9p4K9NW4Y9F",
            "symbol": "CRE8R",
            "name": "CRE8RCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iamcre8r/cre8rtoken/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7CeDq7g178wgQeDPQea5Mvh3i1ACy9AMjrLBqogw8yG3",
            "symbol": "CHECKRED",
            "name": "CHECKRED Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7CeDq7g178wgQeDPQea5Mvh3i1ACy9AMjrLBqogw8yG3/logo.png",
            "tags": [
                "utily-token"
            ],
            "extensions": {
                "website": "https://checkred.com"
            }
        },
        {
            "chainId": 101,
            "address": "Sona6KvuQjFxy7y8CPN45qXGLh8QHTerxaGMqPBo55B",
            "symbol": "SONA",
            "name": "SONA",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Sona6KvuQjFxy7y8CPN45qXGLh8QHTerxaGMqPBo55B/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/aapoonofficial",
                "website": "https://aapoon.com"
            }
        },
        {
            "chainId": 101,
            "address": "D7psBLw3LeUbb1bDZKkV8yRE7VhxC3A4HwfZkcGqGQpS",
            "symbol": "XKR",
            "name": "Kori",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D7psBLw3LeUbb1bDZKkV8yRE7VhxC3A4HwfZkcGqGQpS/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Kori Coin",
                "twitter": "https://twitter.com/KoriPay"
            }
        },
        {
            "chainId": 101,
            "address": "3Hh4mWgN2F2PBzmqeWxZmYYjA6Jxir5RNV5gwspRECev",
            "symbol": "MULA",
            "name": "Mula Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MULACOIN/mulalogo/main/mulalogo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA",
            "symbol": "$WNZ",
            "name": "Winerz",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "payment-token",
                "game-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/winerz.official",
                "instagram": "https://www.instagram.com/winerz.official/",
                "medium": "https://medium.com/@winerz",
                "twitter": "https://twitter.com/Winerz_official",
                "website": "https://winerz.com/",
                "whitepaper": "https://ico.winerz.com/assets/docs/Winerz-Token-Economics.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "2DuUuHBZzvnJEByVt4qerUfEoHStNj8fFoJxwVpWDy1a",
            "symbol": "GMASK",
            "name": "Gas Mask Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DuUuHBZzvnJEByVt4qerUfEoHStNj8fFoJxwVpWDy1a/logo.jpg",
            "tags": [
                "utility-token",
                "NFT-token",
                "Metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "DDtaBrpMAGG1phmHd79Cxd3AjA89ZtsbA9Pz6GSp8hQz",
            "symbol": "SEDS",
            "name": "SEDS KANCHANABURI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SEDS-kanchanaburi/Crypto/main/logo_png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Mix4UnSBefX8ym3mHndLyTgvqUq2kt7QsYQ4pCL4jr3",
            "symbol": "MIX",
            "name": "MATRIX",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Mix4UnSBefX8ym3mHndLyTgvqUq2kt7QsYQ4pCL4jr3/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/matrixmix",
                "website": "https://matrixx.site"
            }
        },
        {
            "chainId": 101,
            "address": "ALEXVSS7YCQUMiJfTCCmQcHBu1ApcLwe1FN4irMXK5ig",
            "symbol": "ALEXV",
            "name": "AlexV Special Series 7",
            "decimals": 9,
            "logoURI": "https://github.com/alexvaldezlectures/alexvaldezlectures/raw/3126e37677e5a0857f5be58483b0331da9a5e006/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ALEXVSS7",
                "website": "https://alexvss7.medium.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MGM57zGF6ghF9Aax7FfoPHfxuoLKuEGM6twJALyCFqx",
            "symbol": "MGM",
            "name": "Maho Genies",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MGM57zGF6ghF9Aax7FfoPHfxuoLKuEGM6twJALyCFqx/logo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mahogenies",
                "website": "https://www.mahogenies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "KAT2oYwjN2uVj9gubM9VutCFMoX1Wq9eLiwJJEpBEX3",
            "symbol": "9LIVES",
            "name": "9LIVES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KAT2oYwjN2uVj9gubM9VutCFMoX1Wq9eLiwJJEpBEX3/9lives.png",
            "tags": [
                "utility-token",
                "community"
            ],
            "extensions": {
                "discord": "http://discord.gg/cyberkatz",
                "twitter": "https://twitter.com/thecyberkatz",
                "website": "https://cyberkatz.space"
            }
        },
        {
            "chainId": 101,
            "address": "GUSRLRYJfyX5Dy5jMZhSv1SysYazoiUZheBhzpZwaqGk",
            "symbol": "FISH",
            "name": "Fish Coin",
            "decimals": 5,
            "logoURI": "https://cdn.jsdelivr.net/gh/gowtham-k147/fishtoken/logo.jpg",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7yHF9pyjs2V14kat6W7guwSvTrFpkdG8jWKyMwY8MuKZ",
            "symbol": "DEWEY",
            "name": "Dewey Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7yHF9pyjs2V14kat6W7guwSvTrFpkdG8jWKyMwY8MuKZ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6sy3PgbjxNtTJuujA8Ppwxz7JLQDdpt5m1y3iuv9gnG6",
            "symbol": "GCC-PRESALE",
            "name": "Galactic Chimp Club Pre-Sale",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spore-engineering/GIMP/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/zutk4yKJ5J",
                "twitter": "https://twitter.com/galacticchimps",
                "website": "https://galacticchimps.com"
            }
        },
        {
            "chainId": 101,
            "address": "8jLKpoyen9zngmzMXsSXH9GijN7dk3NBM4wvEqraecTM",
            "symbol": "WWT",
            "name": "Wizard World Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/AngelOfWindFon/testgits/wwtokensquare.png",
            "tags": [
                "utility-token",
                "community"
            ],
            "extensions": {
                "discord": "http://discord.gg/wizardworld",
                "twitter": "https://twitter.com/WizardWorldTH",
                "website": "https://wizardworld.online"
            }
        },
        {
            "chainId": 101,
            "address": "t4WVhM5uz9Ux2v4QQCxP3Rx7Yw1o1iB5HdY8ruUYYwk",
            "symbol": "SEFU",
            "name": "SEFU",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/drumbun/sefu/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GkDX7TmmEeKB3puMYtK9P9JEEozBB2gCKnBwePvFtJxs",
            "symbol": "CMFT",
            "name": "Comfort",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/cmft-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "kDpB4LcBvjSG4BFHEsgWoUK9v6wVmQT7ZheqBRQtL39",
            "symbol": "CELEBRATE",
            "name": "Celebration",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/celebrate-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AxzUoqjHN8X9GrW7ap28HDmWk4m98PK42Qt81vUmmwwL",
            "symbol": "TWRK",
            "name": "Teamwork",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/twrk-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9MN8frXDaNqvSW8Q6gNAsXJxVrJ9ZVTS96RtgoJfmmFq",
            "symbol": "PRTY",
            "name": "Party",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/prty-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6uhPsJZSkRbhStteALQVtRQoxya1ygZSBG2tEhLsv6jS",
            "symbol": "W",
            "name": "Win",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/w-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5gX3JoSm6BRWCoiLHUYEZP938uKuXYTjayT9VHpR7Eq5",
            "symbol": "CHANCE",
            "name": "Chance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/chance-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ePCNJtsTZqsQ2WZmhbS3UpxdxYwdPX61e6imYmSi4Ef",
            "symbol": "Q",
            "name": "Quality",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/q-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DsxQTYvzN5HAAu4wU3zrRNtWdSim9m26qUdqSWecuYUG",
            "symbol": "GAM",
            "name": "Game",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/gam-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FNsr9VZTJh1Wj5wqDvFNrBu37nDgQ4nnrnt1cHNsvcvc",
            "symbol": "TOY",
            "name": "Toy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/toy-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7tivzLp6w1kTS2pZFf175F4GrZw1U3X1G3wuWneE4FEP",
            "symbol": "EDU",
            "name": "Education",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/edu-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ugSUeRJzQNqVNvGMs9x7znEq77eaFz6JeY4hC4cvqEg",
            "symbol": "CHOC",
            "name": "Chocolate",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/choc-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AMHxYLuRoe6z1dwUtCK3Qd1GqsgXfGyHy3DFmukKqkeN",
            "symbol": "DNUT",
            "name": "Donut",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/dnut-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "wcpSdkgbU7C5RG5tZ6uR4igPzXDrfmbcHm49Q3khWCn",
            "symbol": "MAZE",
            "name": "Amazement",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/maze-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AMHrqDPAkij1tj3sBBMScmb67mEg1BS63yrfcSUjS48B",
            "symbol": "SQUEEZE",
            "name": "Hugs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/squeeze-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B1k73ppGnKNrs2t18pDYwE2xmw2CWNFX6AFnnCdJV9yj",
            "symbol": "FAMILY",
            "name": "Family",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/family-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BBafuR4yCVj2fRfGQjQ18C71DhNB6MVGhYaSVw8dxCmU",
            "symbol": "SONG",
            "name": "Tune",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/song-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7xKwS4saJJ1RstUYUF6iBW5LcqGtjN6WwSD3Ypn11Nve",
            "symbol": "GEEK",
            "name": "Technology",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/geek-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "56kdsPBQi3fWgmMtMWe3c21uDZaeuyEyKFfQUUK2Xjf9",
            "symbol": "IIOT",
            "name": "IIoT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/iiot-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DuNQutK9pTyqYsENuZKQ2gmeo6eRT83J84muyKankZvB",
            "symbol": "CANDY",
            "name": "Sweet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/candy-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G3ap56qwNvA6BBffsXkF1SsWg4VWrC4AR2fyWnikEKR5",
            "symbol": "ATEL",
            "name": "AI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/atel-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DQXAncyjFiELmGuXuH7t8t2CsyEoJCddyZzmCuQTKBzr",
            "symbol": "PUCKER",
            "name": "Kisses",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/pucker-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FXsW1VcB2E4Gwqz47JaFLLTtYYvZ5tb5PA2JcNtX1LQ",
            "symbol": "HIILI",
            "name": "Hiili Coin",
            "decimals": 9,
            "logoURI": "https://github.com/HiiliCoin/HiiliCoin/blob/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GTs5Eg57FPxymuakS4kUVXfMkFVcQZAjBg3QURQfTMae",
            "symbol": "GSPARK",
            "name": "GlowingSpark Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GTs5Eg57FPxymuakS4kUVXfMkFVcQZAjBg3QURQfTMae/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Yc2ab2FXjmiXgXzQaQDKHMMXmzRs1RgYDcech9pfg8R",
            "symbol": "UAHC",
            "name": "UAH Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Yc2ab2FXjmiXgXzQaQDKHMMXmzRs1RgYDcech9pfg8R/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "CJpxdKneyDfxJsw5qioLPQX8iqhbMvxafuz4Jh1gyiA2",
            "symbol": "LFC",
            "name": "Ledger Figure Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AsahiTashi05/crypto/main/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Ledger Figure Token was created with the aim of helping people in financial difficulty, we are committed to making donations to people in precarious situations.",
                "website": "https://ledgerfigurecoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4goT5SLzfoeFx3YwbPF3g6j8whtqnwDJ7BdKC6YVDeKc",
            "symbol": "CHI",
            "name": "Laohu Chi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "888 Tigers ROARing around the #Solana ecosystem on a mission to save their Kingdom from Evil",
                "website": "https://www.laohukingdom.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CTkhysavqhi9wFCZbFKew9AburtGVrjRkpE8NEHk7YF2",
            "symbol": "MTN",
            "name": "MTN Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TuckerBrady/MTNoutlaw/master/images/mtn-coin-logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "The official currency of the MTN Outlaws.",
                "discord": "https://discord.gg/mnuca9F6",
                "facebook": "https://www.facebook.com/mtnoutlaw",
                "instagram": "https://www.instagram.com/mtnoutlaw",
                "twitter": "https://twitter.com/MTNoutlaws",
                "website": "https://www.mtnoutlaw.com"
            }
        },
        {
            "chainId": 101,
            "address": "7yUFhJy4R2b3RLGqbB9JAHuN3kFw4DZyHLagSyRGEc71",
            "symbol": "DY",
            "name": "DYNAMIC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7yUFhJy4R2b3RLGqbB9JAHuN3kFw4DZyHLagSyRGEc71/logo.jpg",
            "extensions": {
                "website": "https://www.dynamic-warriors.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CooPDdGLnUbj8sGgFb8ZDtr2y9tTezJRJ76nj1TwP9B3",
            "symbol": "CoopD",
            "name": "CoopDollar",
            "decimals": 0,
            "logoURI": "https://arweave.net/sJNlqRf7G9wegjc_EwTFknN-hjj2WFqYAf1pJ5CJmMk",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "H1QvHLhmk4rL36FBphnFUaQszf6RHGU8RLptPSuPcQwX",
            "symbol": "BUGS",
            "name": "BUGS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H1QvHLhmk4rL36FBphnFUaQszf6RHGU8RLptPSuPcQwX/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FroggosNFT",
                "website": "http://froggosnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "27r7EqbY3g8TKAhneAWaFeiyGjg6P8XUgmA7goZnyWG3",
            "symbol": "WRZTR",
            "name": "Thug Roozter Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/0xthug/logo/main/thug-roozter-wl-token.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://twitter.com/thugroozter"
            }
        },
        {
            "chainId": 101,
            "address": "C6RdoUNJf91xAH3n9385u3hJysgKBzMfPRBKcLq2NMEs",
            "symbol": "RZTR",
            "name": "Thug Roozter Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/0xthug/logo/main/thug-roozter-token.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thugroozter",
                "website": "https://thugroozter.com"
            }
        },
        {
            "chainId": 101,
            "address": "GH9urVNhVzEvQFAz4NGWrjc3raGMKSmU96GV7s3QwSfq",
            "symbol": "SD",
            "name": "DoughDAO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GH9urVNhVzEvQFAz4NGWrjc3raGMKSmU96GV7s3QwSfq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "DoughDAO Token",
                "discord": "https://discord.gg/soldough",
                "twitter": "https://twitter.com/SolDough",
                "website": "https://soldough.com"
            }
        },
        {
            "chainId": 101,
            "address": "2zsvXn4BJykA6zrmDr8LCnEmF7oC4uGxvYMbE47wct85",
            "symbol": "SHIBZWL",
            "name": "Shibaz NFT MINT PASS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2zsvXn4BJykA6zrmDr8LCnEmF7oC4uGxvYMbE47wct85/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DqEbjduwWLmLFTuXDmJZ51P4HLBYE2PQ2AKPuQLRWcw9",
            "symbol": "BLLOON",
            "name": "BALLOONCOIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/tylerj147/balloonsvillee/main/balloon.png"
        },
        {
            "chainId": 101,
            "address": "DydAumm7jWxhYqfGguLFCCPAgJxkLENGortKGPMeBcnH",
            "symbol": "SKRYL",
            "name": "SkrylniCoin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DydAumm7jWxhYqfGguLFCCPAgJxkLENGortKGPMeBcnH/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HVMZXKX8EAUdEa1hNbAzUzeFqbcm1w9AKFeVoEKkADF6",
            "symbol": "SPATCH",
            "name": "SpatchCockKings",
            "decimals": 0,
            "logoURI": "https://github.com/Stickmanlamont/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4hF98FggXoEcrZmE8JGdMGSXrRCRid3j99rutyMKh1oF",
            "symbol": "GDT",
            "name": "Gangster Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/gangsterducks/gangstertoken/main/logogangstertoken.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/gangsterducks",
                "website": "https://gangsterducks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8i4r3uJTw7zfJp2BYjM6PVLCWaTLK4CgakaBFwpcEXZp",
            "symbol": "FGC",
            "name": "FUGGA COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Keshav-py/Fugga_Coin/main/png1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "35HAnnLpne8Kppd7JtpDJ9dWwgrW8f8yUmHwymjGZth4",
            "symbol": "P",
            "name": "P-Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/FloorPricePusher/P-Token/main/P-%20Coin%20Symbol.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2oF21EoZdpJHXP1VeroAFbd2tCBtprQzwJvNRRr5iKnq",
            "symbol": "DUKLOCK",
            "name": "DUKLOCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LeM0-Dev/crypto/main/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.alecau.com/",
                "youtube": "https://www.youtube.com/c/Duklock"
            }
        },
        {
            "chainId": 101,
            "address": "FbdnyBXYePogUMT6JbnpPBxk7gJpkMy4HjdCYynxYdjb",
            "symbol": "NSOLA",
            "name": "Negativesolana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Negativesolana/logo/main/imageedit_2_2485355732.png",
            "tags": [
                "negative",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/negative_solana",
                "website": "http://negativesolana.42web.io/"
            }
        },
        {
            "chainId": 101,
            "address": "29wTrVK2kyBCE3AKvQzEAzKAZo8bndthuM3b7nmr2G7y",
            "symbol": "ZMMD",
            "name": "Zoo Magic Maker DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DavidTick/Crypto/main/Token.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9yNG97Asj59bkz1UggyxKBrM2bLqCgZugydCwHDrqkQJ",
            "symbol": "STck",
            "name": "StockbridgeCoin",
            "decimals": 0,
            "logoURI": "https://github.com/wstockbridge/StockbridgeCoin/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AWD7Rkh72ENAZUeHGP5mnubFPk8AeSN1ogosExdF4G2X",
            "symbol": "LIME",
            "name": "Tequila",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainWizard/Crypto/main/Tequila.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community",
                "Tequila"
            ]
        },
        {
            "chainId": 101,
            "address": "9pF8ARAEZUBwAJEHbviWnpNz9Ewho8y4ggCT452nhBuQ",
            "symbol": "FLWRS",
            "name": "Flowers",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/flwrs-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9fKV886v4MmnWBUnDWoxsyeYFqyG6WTRzTYfbcMXia7E",
            "symbol": "CRSH",
            "name": "Crush",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/crsh-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EvsUWXMWnErtaBJPpqVMhhX3uWrJq51yMPwdnGaiBJUf",
            "symbol": "NOVT",
            "name": "Innovation",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/novt-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cy839k8eJf3pzXUhrJ89kexxcEDEzexCfD2P1wB3LtAa",
            "symbol": "PUP",
            "name": "Dog",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/pup-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FZJYgGhQGJggRuSBjkhYx44o2CLuAR1WbihBq6TdCMj9",
            "symbol": "KILO",
            "name": "KILO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lpbcmain/kilo/main/kilotoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2LkFmYFS9jXirHEBUwZFR1LTZRnsLHoeyS3QjYpXW4cC",
            "symbol": "TKAE",
            "name": "Tokkea",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tokkeass/cryptos/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "nft",
                "meme-token",
                "community-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4BzQc4659DGHLNye2kFFYFh2SsPvaiH3yEVcVBiUdjXb",
            "symbol": "STRW",
            "name": "StirWave",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SunCrypto/stirwave/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FxKngPeyV2mM4oZPiF5EQ7xPBBAj5WtrzpBcf7n8HHaH",
            "symbol": "FXKNG",
            "name": "Fox King Whitelist",
            "decimals": 0,
            "logoURI": "https://arweave.net/V0fJBa_DsUCxCBta_8WPxWAtK8h30qvPxLCnD7cj2Hs",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/W8fra6bABu",
                "twitter": "https://twitter.com/rationalasf",
                "website": "https://linktr.ee/rationalasfuck",
                "youtube": "https://www.youtube.com/c/RationalAF"
            }
        },
        {
            "chainId": 101,
            "address": "3ZYsJZ7wb4hNEkfaDnR4JKT6Rok4hEfbxjGqDA3cRxGS",
            "symbol": "TTCS",
            "name": "TTCS Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/tongtongchain/token-list/main/assets/mainnet/3ZYsJZ7wb4hNEkfaDnR4JKT6Rok4hEfbxjGqDA3cRxGS/TTCS.png",
            "tags": [
                "tongtongcoin",
                "tongtongchain",
                "dex",
                "finance"
            ]
        },
        {
            "chainId": 101,
            "address": "CaNiNGW7fasuC6Z3zGGuVt1j9WYX94KG7UN1SMY7gQqP",
            "symbol": "CANIN",
            "name": "DFLDAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cultureclap/cultureclap/main/CANIN.logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "address": "CaNiNGW7fasuC6Z3zGGuVt1j9WYX94KG7UN1SMY7gQqP",
                "assetContract": "https://solscan.io/token/CaNiNGW7fasuC6Z3zGGuVt1j9WYX94KG7UN1SMY7gQqP",
                "description": "CANIN is the initial currency for DreamFreely Labs, as orchestrated by Culture Clap.",
                "discord": "https://discord.gg/bwMRRbs",
                "twitter": "https://twitter.com/CultureClap",
                "website": "https://www.cultureclap.com"
            }
        },
        {
            "chainId": 101,
            "address": "DkNihsQs1hqEwf9TgKP8FmGv7dmMQ7hnKjS2ZSmMZZBE",
            "symbol": "QTCON-SOL",
            "name": "QUIZTOK",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DkNihsQs1hqEwf9TgKP8FmGv7dmMQ7hnKjS2ZSmMZZBE/logo.png",
            "extensions": {
                "coingeckoId": "quiztok",
                "facebook": "https://www.facebook.com/quiztok.kr",
                "github": "https://github.com/quiztok",
                "instagram": "https://www.instagram.com/quiztok_kr/",
                "telegram": "https://t.me/QuiztokCS",
                "twitter": "https://twitter.com/quiztokkr",
                "website": "https://quiztok.com/",
                "youtube": "https://www.youtube.com/channel/UCtl9jTbXMa43F17zrnr1AeQ"
            }
        },
        {
            "chainId": 101,
            "address": "RVMgn3H2x5EjZsQ5Z561Kvx6tRDxkkFsXEtH1hMuJ7k",
            "symbol": "RVM",
            "name": "RAVIUM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RVMgn3H2x5EjZsQ5Z561Kvx6tRDxkkFsXEtH1hMuJ7k/logo.png",
            "tags": [
                "Utility-token",
                "Gaming",
                "NFT",
                "Metaverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/Sr9epbgEW4",
                "telegram": "https://t.me/Ravaverse",
                "twitter": "https://twitter.com/Ravaverse"
            }
        },
        {
            "chainId": 101,
            "address": "6k6R1nRupoVjvJJ6QGnJysBqyEyvgUAhWsx8QJujcPuF",
            "symbol": "ISGT",
            "name": "ISG Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6k6R1nRupoVjvJJ6QGnJysBqyEyvgUAhWsx8QJujcPuF/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fm8zMkQmry3CzKDhSSx1xZm7QEv5JKU9okaEkmuD53CV",
            "symbol": "LNR",
            "name": "Lunar Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/926535226820276264/938814249009029150/Untitled_Artwork.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "34q5p3pbzg7a3KHugynsaMDYNHqobb12A1Q9XzEfuJgb",
            "symbol": "FIRST",
            "name": "1",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/first-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3M9SwGNxejzTUyKUwc9iFF4ionmBRguciVVoLCb2waBS",
            "symbol": "SECOND",
            "name": "2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/second-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4eYiTBBxWxM9G1acw1jjXwLY6JziccKEGNPhQw2Dtrhd",
            "symbol": "THIRD",
            "name": "3",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/third-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4T2ytRMHfLtmSeFfzErEQ53x4MhVhFSGaY33dV2AXgLw",
            "symbol": "PARTICIPANT",
            "name": "Participant",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/participant-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5CKTpsfkiLtgvS7pUUg6jjjiF6yagUuDgzVwRHEyFYVa",
            "symbol": "B",
            "name": "B",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/b-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5FtKWmzt856FmSqiCwpBGpwv2gjAfFoa4N5Zc8fN3sah",
            "symbol": "C",
            "name": "C",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/c-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DRx4PGJSofkSKy8hHefMpkbQTyAMoX9aw9bcrdBteVzJ",
            "symbol": "EGLP",
            "name": "Eagle Paws",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/410playa/crypto/main/eaglepaws.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CtEYJ44W7PctR1Bc7tHJxfEX4vvzc8aAxZYzGwGWscT9",
            "symbol": "EVO",
            "name": "Ernest Voyagers Orb",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/swnrvpg/Orb-02-main-transparent.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "A collectors token, used in the Ernest Metaverse",
                "discord": "https://discord.com/invite/ernestindisguise",
                "twitter": "https://twitter.com/ErnestDisguise",
                "website": "https://ernestindisguise.com/"
            }
        },
        {
            "chainId": 101,
            "address": "rRdJNw8cPdJ7UVoBeFyaQ3vrauCzXxN3ecBwmLPYf3C",
            "symbol": "RENTA",
            "name": "Renta",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin8855/crypto/main/logo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "website": "https://renta.world/"
            }
        },
        {
            "chainId": 101,
            "address": "6B5KXnS9pYr7Sb9ksa6YTng7mLkv7sz8nfG7a55fh2d1",
            "symbol": "ALGOS",
            "name": "Algo Stone",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pubathed0g/AlgoStone/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8JnNWJ46yfdq8sKgT1Lk4G7VWkAA8Rhh7LhqgJ6WY41G",
            "symbol": "SOLI",
            "name": "Solana Ecosystem Index",
            "decimals": 6,
            "logoURI": "https://cdn.lima.amun.com/solana/8jnnwj46yfdq8skgt1lk4g7vwkaa8rhh7lhqgj6wy41g.png",
            "tags": [
                "index-token",
                "index",
                "defi-index",
                "defi",
                "solana-ecosystem"
            ],
            "extensions": {
                "description": "SOLI is an index of the Solana ecosystem. It delivers easy, low-cost, diversified exposure to top Solana-native projects. By using staked SOL (mSOL), the index also captures Solana's 6% staking yield. SOLI rebalances monthly to catch trends in the ecosystem.",
                "discord": "https://discord.gg/amun",
                "medium": "https://medium.com/amun-tokens",
                "telegram": "https://t.me/AmunTokens",
                "twitter": "https://twitter.com/amun",
                "website": "https://amun.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3dUP24FewyxEpT6qY1f6pMhPsZ1yqJEw2XWqStSxqCCV",
            "symbol": "9LIVES-SOL",
            "name": "9LIVES-SOL LP Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3dUP24FewyxEpT6qY1f6pMhPsZ1yqJEw2XWqStSxqCCV/9livesLP.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/cyberkatz",
                "twitter": "https://twitter.com/thecyberkatz",
                "website": "https://cyberkatz.space"
            }
        },
        {
            "chainId": 101,
            "address": "2GpfALCv9rNhkyWTwCNwtC3BNa5PSQ7Jf1xSmnnkJQWv",
            "symbol": "ARVLA",
            "name": "The Arvella Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/CISSPInstructor/crypto/main/arvella_logo.jpg",
            "tags": [
                "social-token",
                "utility-token",
                "NFT-token",
                "Metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "FGseEvVtXnbx5M9M2w4s67wKArVL4CUM48xMaoGxtaGi",
            "symbol": "CTWL",
            "name": "Cyber Tribe Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGseEvVtXnbx5M9M2w4s67wKArVL4CUM48xMaoGxtaGi/logo.png",
            "tags": [
                "community"
            ],
            "extensions": {
                "discord": "http://discord.gg/cybertribe",
                "twitter": "https://twitter.com/CyberTribeNFT",
                "website": "https://cybertribe.gg"
            }
        },
        {
            "chainId": 101,
            "address": "9CUy37Vsg2DsjkLmWx2AXix7oSogTRjDn2JBMLPQ4mfY",
            "symbol": "LCT",
            "name": "LoutreCoin Tuto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9CUy37Vsg2DsjkLmWx2AXix7oSogTRjDn2JBMLPQ4mfY/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/laloutre",
                "website": "https://laloutre.gitbook.io/"
            }
        },
        {
            "chainId": 103,
            "address": "8FDETjLAtqcnvq8KyPuKSRBPxZQ9yEx9Qs3QFxzDqcE2",
            "symbol": "G",
            "name": "TEST G Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FDETjLAtqcnvq8KyPuKSRBPxZQ9yEx9Qs3QFxzDqcE2/logo.png"
        },
        {
            "chainId": 101,
            "address": "Hio6C8chHfSAjxokuyB7KiYQwP6GduekrPh5pXwEmL36",
            "symbol": "CODP",
            "name": "CodPoints Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/elishaakyaw/cod/main/p.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fwy8aK7m1TF9cr3DhAEaBfqG6W7jX4vfCK2UuQE3qi21",
            "symbol": "KICA",
            "name": "KICA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fwy8aK7m1TF9cr3DhAEaBfqG6W7jX4vfCK2UuQE3qi21/logo.png",
            "tags": [
                "carbon-offset",
                "climate"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EarthKica"
            }
        },
        {
            "chainId": 101,
            "address": "D6xt2imesfZ1zL57o72BBzjaDs91Q72kgoSGC6qpXY9T",
            "symbol": "FUDAI",
            "name": "FUDAI Token",
            "decimals": 6,
            "logoURI": "https://fortunenekos.io/images/fudai.png",
            "extensions": {
                "discord": "https://discord.gg/fortunenekos",
                "twitter": "https://twitter.com/fortunenekosnft",
                "website": "https://fortunenekos.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8gsccGREpFttkH813odWMfHGtDRu8MjjyKvTN782WFaP",
            "symbol": "$",
            "name": "gulp",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/farhan067/Gulp/main/gulpLogo.png"
        },
        {
            "chainId": 101,
            "address": "HVVQoWkpEGiZ1ASk8KMdmJTCD16ap1cxYqnupVPcPme7",
            "symbol": "SKA",
            "name": "Dedska",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ShogDev/Dedska/main/Dedska2.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/+x9YbzrNSda5iMGVk",
                "website": "https://github.com/ShogDev"
            }
        },
        {
            "chainId": 101,
            "address": "EasTB1F65QLPwMMpyESgCVy4wrkfbCwLzG6Epf1Lq4Rv",
            "symbol": "GDXC",
            "name": "GDX Token",
            "decimals": 2,
            "logoURI": "https://gdxc.greydotx.network/gdxc.png",
            "tags": [
                "community",
                "utility-token"
            ],
            "extensions": {
                "website": "https://gdxc.greydotx.network"
            }
        },
        {
            "chainId": 101,
            "address": "FTvYdHBYKoAfqZzFWPH14zLQAyrm1xbvVKE69Xo6Wpps",
            "symbol": "BRCN",
            "name": "Brain Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dskupinski8/crypto/main/logo.png",
            "tags": [
                "community"
            ],
            "extensions": {
                "website": "https://bit.ly/3unsVny"
            }
        },
        {
            "chainId": 101,
            "address": "DBnyE7AKca1avuBtfh9jnHrm6Qe1gt2q5LMt5umxR8wN",
            "symbol": "LZ",
            "name": "Leanza Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/leanza/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/GmDSMR8E",
                "twitter": "https://twitter.com/leanzadario",
                "website": "https://www.leanza.nl/"
            }
        },
        {
            "chainId": 101,
            "address": "7UqRuj5w6SUmXwWvDPiefr9rdS4XCDM86dKHv2Bps4Sq",
            "symbol": "BRIZ",
            "name": "Bristol Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7UqRuj5w6SUmXwWvDPiefr9rdS4XCDM86dKHv2Bps4Sq/BristolCoinLogo.jpg"
        },
        {
            "chainId": 101,
            "address": "Gdt362HgV2pVdZk1hm8JuQxJGbCuJfzFWP6GCxcMj81H",
            "symbol": "LOS",
            "name": "ThaiSol",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lingnooy/crypto/main/thaisol-los.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DLUB7cPdvuwV1gCYHHzVweHEav11FxkMCTX6TwS1iee8",
            "symbol": "FPV",
            "name": "FPV Pilot Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gadominas/fpvcoin/main/fpv-token.png",
            "tags": [
                "community",
                "fpv",
                "drone"
            ]
        },
        {
            "chainId": 101,
            "address": "2FZjqh8BQTqB9aT2usEXw2hsykZGaTfgQ32oEuGd35Tu",
            "symbol": "SATO",
            "name": "Satoseries",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/satowind/satoseries-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HiNb1ABjQG4xeuv1Bee6j5wRhiyagcygDAQkJU9ULbRM",
            "symbol": "CHEEBZ",
            "name": "Shiba Cheeba Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/eazucker/token-list/main/assets/mainnet/HiNb1ABjQG4xeuv1Bee6j5wRhiyagcygDAQkJU9ULbRM/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Billz_Gluckman"
            }
        },
        {
            "chainId": 101,
            "address": "E7Ykw7mxzoRVrfmzoE951ASQLhj24PDJaWN3vDu6LNpq",
            "symbol": "santi",
            "name": "santi coin V2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E7Ykw7mxzoRVrfmzoE951ASQLhj24PDJaWN3vDu6LNpq/logo.png"
        },
        {
            "chainId": 101,
            "address": "4XJXbVFXu8MrHGKXDGnnUmC2TNuuXoE3R5711uJbf6Tn",
            "symbol": "SVG",
            "name": "Sauvage",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dudgrayman/crypto/main/lps234px.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2ikET9vxPYEf28XwpqRewFKizij1f1KqgyLqet8TMUsa",
            "symbol": "MZB",
            "name": "MnZorBashm",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/RahelHamarash/mazeCoin/main/MnZorBashm.png",
            "tags": [
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "5XiE2JApnDwGc24PSY7y7stD4JxStkYPAH5tFVKAcrow",
            "symbol": "ORIA",
            "name": "Memoria",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5XiE2JApnDwGc24PSY7y7stD4JxStkYPAH5tFVKAcrow/Logo.png",
            "tags": [
                "Gaming-token",
                "NFT-token",
                "Metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "FmVCMUr2HygkdfKyJK7EMyFxKyME27vsMeByreV4rdPg",
            "symbol": "STDT-S",
            "name": "Steadd S Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/SteaddCoin/STDT-S/main/logo.png",
            "tags": [
                "Invesimet",
                "Currency"
            ],
            "extensions": {
                "description": "A project for people that want a reliable Invesimet alternative",
                "discord": "https://discord.com/channels/@steaddproject",
                "facebook": "https://www.facebook.com/Steadd-Project-101204732361826",
                "instagram": "https://www.instagram.com/steaddcoin/",
                "linkedin": "https://www.linkedin.com/in/steadd-coin-692b0a228/",
                "reddit": "https://www.reddit.com/user/steaddcoin",
                "telegram": "https://t.me/monkeyball_official",
                "twitter": "https://twitter.com/SteaddC",
                "website": "https://steadd.com/",
                "whitepaper": "https://steadd.com/assets/pdfs/whitepaperWeb.pdf",
                "youtube": "https://www.youtube.com/channel/UCKhU0YnQHs2-Hovjs2cLWmw"
            }
        },
        {
            "chainId": 101,
            "address": "51AA7ktYcb8yb98Tfrhs6TaDjr7cMtJVo6sEMNe87mNs",
            "symbol": "$GLUE",
            "name": "Glue Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/51AA7ktYcb8yb98Tfrhs6TaDjr7cMtJVo6sEMNe87mNs/logo.png"
        },
        {
            "chainId": 101,
            "address": "21AdqMAKHuvbFVs4qvrSWz47fDnCPjZ1tc36kD6yoixz",
            "symbol": "MLTX",
            "name": "MylaToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stoneiness/crypto/main/mylatokenlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7pDCeQ7PmB98GKuNeobrhF7mnVteLEWTxWjikZxVg7h6",
            "symbol": "RYO",
            "name": "Ryo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7pDCeQ7PmB98GKuNeobrhF7mnVteLEWTxWjikZxVg7h6/logo.png",
            "tags": [
                "utlity-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/n8yF8Qq7vM",
                "twitter": "https://twitter.com/Jetpack_Samurai"
            }
        },
        {
            "chainId": 101,
            "address": "3pv2LWtt2vSkysGBCt1QKp4VYaYqkJPqB8cWSXxHMxDB",
            "symbol": "CYPA",
            "name": "Candy Panda",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3pv2LWtt2vSkysGBCt1QKp4VYaYqkJPqB8cWSXxHMxDB/logo.png",
            "tags": [
                "game-token",
                "nft-token",
                "utility-token",
                "trading-token"
            ],
            "extensions": {
                "telegram": "https://t.me/MiguelA_crypto",
                "twitter": "https://twitter.com/MiguelA_crypto"
            }
        },
        {
            "chainId": 101,
            "address": "CDAoigSD1gAU1R5nYA16ycMsp3hBjqXFM4za9GzedwpU",
            "symbol": "CoopDAO",
            "name": "CoopDAO",
            "decimals": 6,
            "logoURI": "https://arweave.net/sJNlqRf7G9wegjc_EwTFknN-hjj2WFqYAf1pJ5CJmMk",
            "tags": [
                "governance-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m",
            "symbol": "FUJI",
            "name": "FUJI",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/MarkSackerberg/token-list/main/assets/mainnet/fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://fujilions.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CY7meunShZiLEdD9RgXYoMampEj6DZbD87EMLNKrBzAe",
            "symbol": "CFT",
            "name": "ColdFusion Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DanteLogan/cfp-token/main/newlogo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "NFT-Token"
            ]
        },
        {
            "chainId": 103,
            "address": "KAFjxURNxSrayrceJx4W4oijSohZ5Rp25Jut2FdWtaZ",
            "symbol": "CAFE",
            "name": "Cafe",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KAFjxURNxSrayrceJx4W4oijSohZ5Rp25Jut2FdWtaZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "CAFE Token",
                "discord": "https://discord.gg/r4vNRUTXY9",
                "twitter": "https://twitter.com/TheBuilderDAO",
                "website": "https://learn.figment.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D8tpoPhS3GVAY5g5ZxDpUVoRj6SHhbSWzEw2z5sptAsf",
            "symbol": "PCF",
            "name": "Pecker Face",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jankillerslatki/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GLNJrLLLUNeZcbUmQJuTsCAweniSgrjV4jVhPwWxM7Sy",
            "symbol": "PUIG",
            "name": "DianneCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dashort/shortcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4gEDaARBim1yBrx3GKYsJyFaH8AWB8acMds1hRLgMYUJ",
            "symbol": "HTC",
            "name": "HaleTownCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/j0hnboy75067/crypto2/main/Haletowncoinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5t1Jbazv95uUSQDCQ7idrjYfd6wqaieGhikcQRM8S6SA",
            "symbol": "HRC",
            "name": "Hog Rider Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LeonBidner/cryptologo1/main/CoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HuzR2FWsAJFtMFWu5XfiTaxPZBAqKcv8sNMjqZXcf572",
            "symbol": "FBT",
            "name": "Fullbull Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fullbull96/fullbull/main/fbt_token.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBSTwQzAXeGiNvM5aDdkMD6wkSyuVXqsu8u3ZQmQLFa1",
            "symbol": "CUBIST",
            "name": "Cubist Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBSTwQzAXeGiNvM5aDdkMD6wkSyuVXqsu8u3ZQmQLFa1/CC.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cubistcollective",
                "twitter": "https://twitter.com/CubistNFT",
                "website": "https://cubistcollective.com/"
            }
        },
        {
            "chainId": 101,
            "address": "97MukwGXTtvEumoedekChs8WEZp3NHWoRABm7RUaTqfj",
            "symbol": "BATK",
            "name": "BATK Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FlorianMTRC/Token/master/logo.png",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Lij1agaWiK8sXAon5AGLwYT8a6bRfo8i6PtQfFjFQcD",
            "symbol": "NOAT",
            "name": "No Attraction",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/joshtasman/cSolana/main/noattractionNOAT.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HsGvAPFxsEGjc5Tee7eHRZrfvWb4ddvLo7DhA2tKNM3H",
            "symbol": "XFR",
            "name": "XFR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bogdwynny/XFR-TOKEN/main/0XH9Dgw.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TokeZFFFFmeWFEEGiXy9DYszxKz5yYT2WqBSc4uVrgw",
            "symbol": "TOK",
            "name": "Tok Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/melekperest/token-list-1/main/assets/mainnet/TokeZFFFFmeWFEEGiXy9DYszxKz5yYT2WqBSc4uVrgw/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "This is TOK Coin"
            }
        },
        {
            "chainId": 101,
            "address": "Aaiw5kZjZcKXRLezY8PonRMfTZ84jgovh51WYSVrSHXG",
            "symbol": "SOLV2",
            "name": "Solana V2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aaiw5kZjZcKXRLezY8PonRMfTZ84jgovh51WYSVrSHXG/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GraTisEXuByrh5GyBckbuC1xgz1FSXBF5pTbyMKK8xfp",
            "symbol": "PAYCHECK",
            "name": "PAYCHECK",
            "decimals": 0,
            "logoURI": "https://github.com/MarkSackerberg/token-list/blob/main/assets/mainnet/GraTisEXuByrh5GyBckbuC1xgz1FSXBF5pTbyMKK8xfp/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MarkSackerberg"
            }
        },
        {
            "chainId": 101,
            "address": "FwKfC9833sWPEmq7T46dbJpnL5pcjGLPxBTuZYm5uD9X",
            "symbol": "CYOST",
            "name": "CYOST Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Corefax/crypto/main/cyostlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHYiXPvPRrvN5eCGHkpfPExdBy4GerGewyuoK2BRyDiC",
            "symbol": "NOOFT",
            "name": "Nooft Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/noofttoken/nooft/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "nooft",
                "description": "NOOFT Project develops a Search Engine \u0026 Analytics platform for NFTs ",
                "facebook": "https://www.facebook.com/nooftproject",
                "twitter": "https://twitter.com/NooftProject",
                "website": "https://nooft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "J6ijHrBaje6XNCaPH4KwWYPHeG5LkcAqGdGQGZeVJ9xX",
            "symbol": "CIBRA",
            "name": "Cibra Monterrey Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J6ijHrBaje6XNCaPH4KwWYPHeG5LkcAqGdGQGZeVJ9xX/logo.png"
        },
        {
            "chainId": 101,
            "address": "75XracgnqjPeuexHKWQU3bBcXMZG6XLDF867tKB1T9e6",
            "symbol": "ISL",
            "name": "The Islanders",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/koudydev/crypto/main/islIcon.png",
            "tags": [
                "game-token",
                "gaming"
            ],
            "extensions": {
                "description": "The Islanders Game coin",
                "discord": "https://discord.com/channels/937754422270885889/937754422270885892",
                "facebook": "https://www.facebook.com/ISLGame",
                "instagram": "https://www.instagram.com/theislandersgame/",
                "twitter": "https://twitter.com/ISLGame",
                "website": "https://theislandersgame.com/"
            }
        },
        {
            "chainId": 101,
            "address": "65SjJYuWxK6Lihg2KXKu6Byxjz7488eUMLjXxSqzeZU9",
            "symbol": "DiZ",
            "name": "DiZSol Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IK2050/Crypto/main/DiZ.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BKtfw5T85QcrDsT5u4Jeks8jQauaaKUfyqJjzAti2uAf",
            "symbol": "GMRM",
            "name": "GameRoom Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKtfw5T85QcrDsT5u4Jeks8jQauaaKUfyqJjzAti2uAf/Logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cvvh8nsKZet59nsDDo3orMa3rZnPWQhpgrMCVcRDRgip",
            "symbol": "tsUSDC",
            "name": "tsUSDC",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/8619106/152620671-249ada62-ced7-4959-bc92-f16837ca83b1.png",
            "tags": [
                "solfarm",
                "tulip",
                "lending",
                "strategy"
            ]
        },
        {
            "chainId": 101,
            "address": "78ZnfsncDVyhE2HVPe5LscUrgKsJpwP3wJDHRF2TuC1v",
            "symbol": "SLNT",
            "name": "SLNT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78ZnfsncDVyhE2HVPe5LscUrgKsJpwP3wJDHRF2TuC1v/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheSolitarians"
            }
        },
        {
            "chainId": 101,
            "address": "F2Wvo3sDsTN4okhcSCh4ttAieJmesJiD2HhHcfrgdNRU",
            "symbol": "PXLC",
            "name": "Pixel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pixel-Coin/cypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GuwfcBtgvxpzyb11ucAbjH5hwALPQNqQ4By5AF12BBLu",
            "symbol": "CWC",
            "name": "Crypto Wealth Capital",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicholas104/assets/main/CWC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E1vVoJmfr3Jyvwd8iFB7F1u9uwAeFNqkNxfzybHNyHuD",
            "symbol": "Met4",
            "name": "Meta 4",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1vVoJmfr3Jyvwd8iFB7F1u9uwAeFNqkNxfzybHNyHuD/logo.png",
            "tags": [
                "utility-token",
                "metaverse"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Meta4labs1",
                "website": "https://meta4labs.org"
            }
        },
        {
            "chainId": 101,
            "address": "GUrngDJstwofUvg6mbKLy886XEqk3bG2uEtmgdHbWn9o",
            "symbol": "BBSC",
            "name": "Booth Boys StableCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Vo1dedParad0x/BoothBoys/main/BBS1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJmWW8tqHosfAGobPMxjnuWrkjUoH88ZPQozxcm2pWcm",
            "symbol": "N8Z",
            "name": "N8Z Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/n8zcoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3zUfBAhvLY3JER8CiWTdFnKfdQyqNW5cgaBZBZp1fzSF",
            "symbol": "GMW",
            "name": "Giants Metawar",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/GIANTSMETAWAR/Assets/main/logo.png",
            "tags": [
                "game-token",
                "gaming"
            ],
            "extensions": {
                "description": "The Giants Metawar Game coin",
                "twitter": "https://twitter.com/giantsmetawar"
            }
        },
        {
            "chainId": 101,
            "address": "6yHNeyd7a14VD2PBedaXqFacoVAJfD5mAAYbvwBnvC7t",
            "symbol": "MTM",
            "name": "MTM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6yHNeyd7a14VD2PBedaXqFacoVAJfD5mAAYbvwBnvC7t/logo.png",
            "tags": [
                "Utility-token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metamounts",
                "website": "https://metamounts.io/"
            }
        },
        {
            "chainId": 101,
            "address": "V221RXxi1u2Utk64vmddRXHKAUawG2u5qiZ2KvK9rm9",
            "symbol": "V22WT",
            "name": "Valley22 white token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Justin-hw-Tseng/assets/main/logo.png",
            "tags": [
                "member-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DCmg9yjadWKoVY1kJgni3emACVojdZ7K1fxch2whUoZM",
            "symbol": "DSMB",
            "name": "Doodled SMB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/doodledsmb/doodled-smb/master/img/doodled-smb-pfp.png",
            "tags": [
                "NFT",
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6tY672aRW5MLU5EetBEsYLMHe9BhssRMcthsix3byVSV",
            "symbol": "SGT/USDC",
            "name": "Cropper LP token (SGT/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "CLA2WB7Sm7Pc5f6FKcsdS6YewHXqAxYT4dDRqepYyPxL",
            "symbol": "SHROOMZ/USDC",
            "name": "Cropper LP token (SHROOMZ/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5HVZhTGSebGoLS2Auaarv5zeY92vEgxbxhSdbkA54cLL",
            "symbol": "GIF/USDC",
            "name": "Cropper LP token (GIF/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "FbKZLsPuEeLHW49KCsXSw1ATWs4HvgZHUVpo5YKj2p1F",
            "symbol": "SD/SOL",
            "name": "Cropper LP token (SD/SOL)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "A7Fzax3TigXLinwQa2hRydsuiJo14d73i2Gob7WDvNTa",
            "symbol": "HIPPO/USDC",
            "name": "Cropper LP token (HIPPO/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "HYmgeRXiNmje6Cc2zP221jX18bdaj8PvvHm2JSqhgBeY",
            "symbol": "SVT/USDC",
            "name": "Cropper LP token (SVT/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4YUWAnFjsrAQawUgnxG1v3boKRLpssZx294LoDki67K6",
            "symbol": "SOB/USDC",
            "name": "Cropper LP token (SOB/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "GnZmzcApAfDfZZ1VyuaqKCT5JgepGbqe5kP189jeGMPk",
            "symbol": "DXL/USDC",
            "name": "Cropper LP token (DXL/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "8X81vuXa8yYS1k242e7CpVgaShk5cxwx8gwgc2RDS8Jh",
            "symbol": "PSOL/USDC",
            "name": "Cropper LP token (PSOL/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "EKGM73Femgd7Nm6u7pMP6U7C5aZAQrp1txLjS4kFYUtG",
            "symbol": "CONDOMS/USDC",
            "name": "Cropper LP token (CONDOMS/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "8Ncps4M6Le7U3cxxvAwsE7LfVmLArBdFpwg3byx2YXKz",
            "symbol": "CHICKS/USDC",
            "name": "Cropper LP token (CHICKS/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AorFnse73z1SXWCQpbfdWJMeHGL6HYJfXjAQFmFRLkhu",
            "symbol": "sBTC/USDC",
            "name": "Cropper LP token (sBTC/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "EnuUKoFnc9ewnLCv6oRcMbqc6hCLKE5Y95WZeyT36mcZ",
            "symbol": "XVC/USDC",
            "name": "Cropper LP token (XVC/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "8ydpYCUcgLKLKbzAwHTfGj5fZR7yjXPBi4C81mFjnCZy",
            "symbol": "GUMA/USDT",
            "name": "Cropper LP token (GUMA/USDT)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "FwgSkLJjfpmVNGJDidmzmU5ainm8k5ZA1f6y6st9Fywp",
            "symbol": "GRUNT/USDC",
            "name": "Cropper LP token (GRUNT/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "ABFNhdJ1Te2VK7XTK3cLxkMhN2yyJG3CbhqrvgVQ5Uxq",
            "symbol": "BOX/USDC",
            "name": "Cropper LP token (BOX/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "DeNBqXqbmD7sagf2z7zGbwxFpkuwKMczRVgshZVh46Nf",
            "symbol": "sBull/USDC",
            "name": "Cropper LP token (sBull/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "CYptGYMQbjbm9RX6zD1frHShFdkG3z9mpr2Njcg5exZv",
            "symbol": "CRP/ETH",
            "name": "Cropper LP token (CRP/ETH)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AqUDC2yd1wWyzZa4ERjhjChuwg7SmRxuwfdiSRi6ZHqN",
            "symbol": "SLIM/CRP",
            "name": "Cropper LP token (SLIM/CRP)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4GspeaC15u8Zd6aDwhYpYZYrzMcAF1jroDH2Y8T4gmg9",
            "symbol": "WIPE/USDC",
            "name": "Cropper LP token (WIPE/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AVDrUiPiC74NjEK78C6U5aPwRjHjnTgCDCS9dZtio7uZ",
            "symbol": "LIZARD/USDC",
            "name": "Cropper LP token (LIZARD/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "2osnrtveVu9eueAabM8GAnzndZTe8jsfPv58NU2jF8Ax",
            "symbol": "COBAN/CRP",
            "name": "Cropper LP token (COBAN/CRP)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "APTaiNJxUtAZMnhoZCVXdxR5kf7ExYWuET3sfnub59z2",
            "symbol": "SOL/USDC",
            "name": "Cropper LP token (SOL/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "BpF7815mKZ4UGvsLoGfYbkDidiGb4ikd5J7fn7s3cB9M",
            "symbol": "WBRZ/USDC",
            "name": "Cropper LP token (WBRZ/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Hdv4pBjB54LLzihUcC6CMTN8uwH63ACq1itkESXHkiSh",
            "symbol": "DEGN/USDC",
            "name": "Cropper LP token (DEGN/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5AHYsFrBTHZdHGgC8zh4UM1JnwEBeEd9Pp5awqxwgjH2",
            "symbol": "LEONIDAS/USDC",
            "name": "Cropper LP token (LEONIDAS/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "3DN98FXELwME1sr6pz9on6pW3vrB8rX3kqkZBHuf3ppy",
            "symbol": "OOGI/CRP",
            "name": "Cropper LP token (OOGI/CRP)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4FyRYzSfduFzBJPkF8ikQ3FDkSg5H7TrGRfhitzNSNeN",
            "symbol": "PART/USDC",
            "name": "Cropper LP token (PART/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7FpihYivmiL5AhcCUgKuxxKSj4GCsr8tWsr2gfRekW45",
            "symbol": "HAMS/USDC",
            "name": "Cropper LP token (HAMS/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9dBKkgCaNxXxe95zPHvotYJcuf9UAQDNko1hQ6M6H7AV",
            "symbol": "SWOLE/USDC",
            "name": "Cropper LP token (SWOLE/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "A4UuGvfHD51UiXrTFvC7irxh4NqSyfYZite25WDSQW6k",
            "symbol": "SCIFI/USDC",
            "name": "Cropper LP token (SCIFI/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "691CkaRvYyPJSRFgomyBymiVDfuw8c7VcceNC2kecRgx",
            "symbol": "SQUIDGAME/USDC",
            "name": "Cropper LP token (SQUIDGAME/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "G6KxgxFZB6qWstFA3oU3ByN4EfQmy4H2d52P7hyh5LaS",
            "symbol": "OOGI/USDC",
            "name": "Cropper LP token (OOGI/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "E7QXvYyoe9D5jVKrzkyuhhXvPtZE8tugZAjLacvujKBc",
            "symbol": "LIQ/USDC",
            "name": "Cropper LP token (LIQ/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "CCMzC7xHHJ6XVEK3Ee5PenNQecbD48KywkrUwZFYYTsX",
            "symbol": "CRP/BTC",
            "name": "Cropper LP token (CRP/BTC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "389WSabPTk1hZ6hC5uG3MEKsindmwCuNhdcHNdhCwXrG",
            "symbol": "WOOF/USDC",
            "name": "Cropper LP token (WOOF/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9TrnN1nRvPetRJgwtdbRrf7MX5eeCDbUyXk2aKw83mQK",
            "symbol": "FUM/USDC",
            "name": "Cropper LP token (FUM/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "rQ3TuSc95MJ44SvuqGiFtHEhL1gCXS2CafhaZXaziAd",
            "symbol": "UNIVERSE/USDC",
            "name": "Cropper LP token (UNIVERSE/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "CqZ8r4PshEUXH87wbNLbeHXkZY1NZm1AoF5x1VwAN1Ce",
            "symbol": "LGBR/USDC",
            "name": "Cropper LP token (LGBR/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "jb388Fw59QgHTYDUwK47AHsV8iLiS1tEyFk1kyXk5So",
            "symbol": "BST/USDC",
            "name": "Cropper LP token (BST/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4ig1SUo6e39QsWzzyvsDEXCDrx9yMyjFg2UUgfpsMwkc",
            "symbol": "FLOOF/USDC",
            "name": "Cropper LP token (FLOOF/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5nMqTPw8ABz8R1jj9ATHNrAJSZD6ZBCBheKKVnX9oLai",
            "symbol": "SHBL/SOL",
            "name": "Cropper LP token (SHBL/SOL)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7LaVBQKnQQ63govY6dYziiPxJ3KVN9TiS577JTFoFVgv",
            "symbol": "MARIO/USDC",
            "name": "Cropper LP token (MARIO/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7XECrQw5N9avacB5H72shuUiiMf3P3ykubcu8oPKiGb7",
            "symbol": "SLT/USDC",
            "name": "Cropper LP token (SLT/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9PNEDBczsEJL9bVdvF9PzgCNCyNKt2gwHMo3XUCxsKHZ",
            "symbol": "LINU/USDC",
            "name": "Cropper LP token (LINU/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "ATNhobTTsidXWAAEp1HNedSAwfCEBSsL8kVaV6Bco1vx",
            "symbol": "HIMA/USDC",
            "name": "Cropper LP token (HIMA/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "H4byHXBhL6nqJc6egqcdeAMpjxG7233Ksg3yQQ4hXwq1",
            "symbol": "WAG/USDC",
            "name": "Cropper LP token (WAG/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "DCvHY1Do3gdCttENXxZbg5KeKF76LpYSjwCWp4WjbM6r",
            "symbol": "GAMESHIB/USDC",
            "name": "Cropper LP token (GAMESHIB/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "DbgDEG9L9wEYsnDtpzrm8Kyw5RkiU8EGexJFUCSL6McV",
            "symbol": "CYS/USDC",
            "name": "Cropper LP token (CYS/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7ccPQfef52gPPueiaGKVE2qFBrS2DbAEPjrdpHvsvY8J",
            "symbol": "SRM/CRP",
            "name": "Cropper LP token (SRM/CRP)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Hh7a2wEboNx4NiGh3DWbGGUiChdTJ5bc8Sb4dU5YNznb",
            "symbol": "LSP",
            "name": "Lightspeed",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/nerdgod21/Lightspeed/main/Zbaz8Fx-2.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZLCHcUKgkbqzx4SzcxGgGdDuUneyAma2mEWvNvXTYCA",
            "symbol": "ZILCH",
            "name": "The Nothing",
            "decimals": 6,
            "logoURI": "https://arweave.net/Vn_m92f54lR932GL3sZDcsp17j5TqtJMfrlVJApQMxo",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "This token is about nothing"
            }
        },
        {
            "chainId": 101,
            "address": "42Y3CgJQLnHjdScYMu8VS4TbeZMUNVdBMKYbf7hz7aum",
            "symbol": "GMFC",
            "name": "Gamify Club",
            "decimals": 6,
            "logoURI": "https://pbs.twimg.com/profile_images/1444964749897175046/wdz26CzM_400x400.jpg",
            "tags": [
                "gamefi",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3H3AeG7BRmCmCJuQ21Am24SYcgFBkgpX6miSSDM7YmW7",
            "symbol": "METAIN",
            "name": "Meta Infinity",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/MetaInfinity1/assets/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "8Sc16a55YzSKpPTUN4VJEfcKU5aXSk22WyCEsr1MfdCf",
            "symbol": "TREE",
            "name": "Tree Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/926535226820276264/939484372397080606/tree_token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "aosvsUetSY7h7hSYXPR3oCoVMpo9GeL3Gtz2aqnua7p",
            "symbol": "AOST",
            "name": "AOS Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aosvsUetSY7h7hSYXPR3oCoVMpo9GeL3Gtz2aqnua7p/token48_48.png",
            "tags": [
                "nft-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cmUJSVqK",
                "twitter": "https://twitter.com/aos_NFT",
                "website": "https://apeson.space"
            }
        },
        {
            "chainId": 101,
            "address": "CyYQrscVAmhzfg2rSC8HAEQvSgkTKq9hBurop3CMoULm",
            "symbol": "FORCA",
            "name": "Forca",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/forcapunks/Forca/main/forca.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "GVcJpThQvSXNDyVZ4yvXGL5YXT7mtoHwyGDnQbq88SHY",
            "symbol": "WGA",
            "name": "GoldAnimation whitelisting Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVcJpThQvSXNDyVZ4yvXGL5YXT7mtoHwyGDnQbq88SHY/gold-logo.png",
            "tags": [
                "whitelist-token",
                "GoldAnimation"
            ]
        },
        {
            "chainId": 101,
            "address": "G9cPkaqupvJu4qm4JEcTVnTnChyhEuLNNBJbEdqe6h7R",
            "symbol": "WLTH",
            "name": "Wealthy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/unimintco/token-logos/main/WLTH/logo-larger.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GG3gVKEb9K48aSYkYvMmBLU9HaCUkLKxeC4c8oZuJG2W",
            "symbol": "SCP",
            "name": "SCP FanClub",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SCPUk/SportingToken/main/SCP.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DTdAVHK68Gm6v1TQz9kaV4xGmAgZCZ8inqjUrvcPzxXh",
            "symbol": "IMO",
            "name": "Imoogi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTdAVHK68Gm6v1TQz9kaV4xGmAgZCZ8inqjUrvcPzxXh/logo.png",
            "tags": [
                "utility-token",
                "security-token",
                "game-currency",
                "DeFi",
                "launchpad",
                "tokenization"
            ],
            "extensions": {
                "description": "IMO is a token of Imoogi Kingdom platforms that offer simplified real-world investments on Metaverse Game.",
                "telegram": "https://t.me/imoogi_kingdom",
                "twitter": "https://twitter.com/imoogi_kingdom",
                "website": "https://imoogi.finance"
            }
        },
        {
            "chainId": 101,
            "address": "F3BR3EeyuNNANLDyW9R359KXKDtXVBrL4yQSBjeC5Riz",
            "symbol": "Comb",
            "name": "Comb Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/afrossss/crypto/main/comb-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6ZXACEZYMaWr1D9U8eBXqDsdzBfVH4NGhWun7bhRB8L5",
            "symbol": "TUSKER",
            "name": "Mister Tusker",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sadun001/mistertusker/main/logo.png",
            "tags": [
                "NFT-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9CRG3HKLrbqmmGhPtgodyCdt2iUmSmBtbzuUHMhyn9G6",
            "symbol": "JNK",
            "name": "JANOSKOIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9CRG3HKLrbqmmGhPtgodyCdt2iUmSmBtbzuUHMhyn9G6/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/_JNSKR"
            }
        },
        {
            "chainId": 101,
            "address": "CUKkELwvhgGQqezNE3vBwcrKqAXRyqW92WUpD9tEXBf4",
            "symbol": "PRGRM",
            "name": "Pro Program Token",
            "decimals": 0,
            "logoURI": "https://github.com/Pro-Program/crypto/blob/main/proprogram.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EZphDuwLyp7kh36Xe19vnfh6F8jC9fGUWNrTULnGd4E4",
            "symbol": "LLNG",
            "name": "Little Nugget",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LouisDeSimone/lilnugget/main/Untitleddesign.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5pwyQZnX8GkabzowWtFHNeKER1J1omrAdJSYqjtffaQZ",
            "symbol": "LOOP",
            "name": "Solana Loop",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/vQX1smT/logo-loop.png",
            "tags": [
                "DeFi",
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaLoops"
            }
        },
        {
            "chainId": 101,
            "address": "FsPwar1GMAdvdURobNedMyrkdY6tUum63xfRukgkCe5Q",
            "symbol": "TTO",
            "name": "Totofsen Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Tof37/Totofsen-crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "totCSh2TfEPTRovc4rMGz1ezukAdPxYWzh9jnXppeqY",
            "symbol": "GMT",
            "name": "Greenman Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/totCSh2TfEPTRovc4rMGz1ezukAdPxYWzh9jnXppeqY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheGreenmanShow",
                "website": "https://greenmanhouseparty.io"
            }
        },
        {
            "chainId": 101,
            "address": "5B7vJfjKYTzxMphzZ7GQYzdnpLhRRudcKRmiDoUezMc2",
            "symbol": "BARC",
            "name": "Barcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Barcoin-io/Barcoin/main/No%20Slogan/Transparent.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BkbqZY7Bhg4TV1fjL9zBLs1xLUpjXj16mmPT6MAMfpQH",
            "symbol": "$POT",
            "name": "Simians Potion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BkbqZY7Bhg4TV1fjL9zBLs1xLUpjXj16mmPT6MAMfpQH/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cmUJSVqK",
                "twitter": "https://twitter.com/SimiansNFT",
                "website": "https://simiansuniverse.com"
            }
        },
        {
            "chainId": 101,
            "address": "5zL1cRf34b35Bwx8GAdqquvyBe2K3JXLhCEPQN7DGah",
            "symbol": "SB",
            "name": "Skreetbird Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5zL1cRf34b35Bwx8GAdqquvyBe2K3JXLhCEPQN7DGah/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "Skreetbird Token",
                "discord": "https://discord.com/invite/9FYjjHsBWh",
                "instagram": "https://www.instagram.com/skreetbirds_nft",
                "twitter": "https://twitter.com/SkreetBirds_NFT",
                "website": "https://skreetbirds.io"
            }
        },
        {
            "chainId": 101,
            "address": "6kgN1e2DBDekXeaED2QLrhXpAEuxFK24hfC9ZQ2JS3Sg",
            "symbol": "ABI",
            "name": "AbiCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gere-lajos/AbiCoin/main/abicoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BEDCa1roohJon5y2GrH84R9mV6XqdgSurrCcP6wHLfDS",
            "symbol": "SOY",
            "name": "Soy Free",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ErranMorad/soyfree_crypto/main/soya.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8F7PWq2XnQCdXqbPgBvXfsjPRC33a5NWr2j5sFPwbMrU",
            "symbol": "Tiger",
            "name": "White Tiger",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shanghaitigersceo/shanghai-tiger/master/img/white-tiger-token.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DTokw7gqbgWENWptx59gnXsyz3EDiio73K9WasY5qvX4",
            "symbol": "BETYAR",
            "name": "Betyár Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTokw7gqbgWENWptx59gnXsyz3EDiio73K9WasY5qvX4/logo.png"
        },
        {
            "chainId": 101,
            "address": "9ZLBKPCzkvDv85hojKofsogsESkJMN164QCVUtxvBxEQ",
            "symbol": "SLDR",
            "name": "Solderland",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ZLBKPCzkvDv85hojKofsogsESkJMN164QCVUtxvBxEQ/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/solderland",
                "website": "https://solderland.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HWABAcTnRbFZV5T2J3rA8twXbPPVQaTbNnzM74YzcYUq",
            "symbol": "SPEV",
            "name": "SpeveCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SpeveCoin/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9XuFGK8Yq2t97Ae8tDXDtkheEXN1ZDZq9pSPXDdXoBKN",
            "symbol": "LTR",
            "name": "Lontar",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XuFGK8Yq2t97Ae8tDXDtkheEXN1ZDZq9pSPXDdXoBKN/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token",
                "launchpad"
            ],
            "extensions": {
                "website": "https://lontar.io"
            }
        },
        {
            "chainId": 101,
            "address": "4KFkWBNu4Ts3DgGRQHBsSEzVE2txQYZ38wS2nJM43M2w",
            "symbol": "FTL",
            "name": "FasterthanLite",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mikemilli818/Fasterthanlight/main/OFFEO-offeo-video-15.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GCcf1qi8zpZ96eqmT9BbPxkS2jytX4K8xzNB2kyYi1nb",
            "symbol": "MCZ",
            "name": "MCZ Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/mcz73/mcztest/main/glaube-an-dich.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EC5XTvPE8YV3rkpdRTLP7ATDURaLj9GLJ1mbGCQnVHYW",
            "symbol": "BLDK",
            "name": "Blue Duck",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LouisDeSimone/BlueDuck/main/blueDuck.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "kLChrqoqRECAp4VVX9i7KqL2sfcHHwX3UbXLcgk8r6q",
            "symbol": "CUB3",
            "name": "CUB3 Demo Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kLChrqoqRECAp4VVX9i7KqL2sfcHHwX3UbXLcgk8r6q/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SouN3GpzerkB9tkmybjktAm17Pt4ynrdgU64B4mv8Ye",
            "symbol": "Souls",
            "name": "Souls Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SouN3GpzerkB9tkmybjktAm17Pt4ynrdgU64B4mv8Ye/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.hornsvshalosnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "426zJLfE2sV6UkQsYJXQrLkJTZo995ZVZodq1LTMfo7Y",
            "symbol": "NEWTON",
            "name": "NeftWorld",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/neftworld/assets/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E2XR53KDCGffnvsEADiRvTshh6SQCokepX3CKnffV2LL",
            "symbol": "YC",
            "name": "YagyaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/YagyaMittal-CDC/MySolToken-YagyaCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4C833zd4QW62PSEAooeaeEeKQg3hbocUJ9wimxLSXUcU",
            "symbol": "CC",
            "name": "Cloud Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cloud-corp/cloucroin-resources/main/CloudCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3LDDeRpEW45HAyUUiyCysDKsYRQanXnEXd9Yjfr5mxZ5",
            "symbol": "ODSY",
            "name": "Odyssey Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/soutrikdeylive/odyssey_logo_solchain/main/new_logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DTokw7gqbgWENWptx59gnXsyz3EDiio73K9WasY5qvX4",
            "symbol": "BETYAR",
            "name": "Betyár Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTokw7gqbgWENWptx59gnXsyz3EDiio73K9WasY5qvX4/logo.png"
        },
        {
            "chainId": 101,
            "address": "7AWYMvHBxHifjxMAMcGz3SKncXKwSNHPzmei9ZoYTg93",
            "symbol": "BOON",
            "name": "BoonCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BoonBaboon/boon-coin/main/small-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "iRoCxFdj5ZpaGZv5zfbme6CkMtRYc6rLTWbxzuxdMGQ",
            "symbol": "IROC",
            "name": "SolRockers",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iRoCxFdj5ZpaGZv5zfbme6CkMtRYc6rLTWbxzuxdMGQ/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "SolRockers Battle of the Bands is a next-gen PVE/PVP play to earn rhythm game built on the Solana blockchain.",
                "discord": "https://discord.gg/solrockers",
                "facebook": "https://facebook.com//solrockersnft",
                "instagram": "https://instagram.com/solrockersnft",
                "medium": "https://medium.com/@solrockersnft",
                "telegram": "https://t.me/solrockersnft",
                "twitter": "https://twitter.com/solrockersnft",
                "website": "https://solrockers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "E359HKTV192s4kpg4QXTmj7eQ6fzvsL2KbU9QJGDrM3e",
            "symbol": "MBALL",
            "name": "Metaballs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E359HKTV192s4kpg4QXTmj7eQ6fzvsL2KbU9QJGDrM3e/logo.png",
            "tags": [
                "metaballix",
                "metaballs",
                "whitelist-token",
                "nft-token",
                "metaverse-currency",
                "metaball-nft",
                "solana-nft",
                "nft-collection"
            ],
            "extensions": {
                "discord": "https://discord.gg/AG2Y93emkf",
                "twitter": "https://twitter.com/metaballix",
                "website": "https://www.metaballix.com"
            }
        },
        {
            "chainId": 101,
            "address": "a4dLZ6Zki1AFWJTfMYC5vGP4YwreNS5uPJG9ZsoMzq9",
            "symbol": "STH",
            "name": "SethCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/seth123321123321/crypto/main/sethcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "P7DHtdeoHF6dKTPaguymDoZTmHETBnULWBnoSUYrqkp",
            "symbol": "PRPh",
            "name": "PRPH Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/P7DHtdeoHF6dKTPaguymDoZTmHETBnULWBnoSUYrqkp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AuBFooi7wKZNr4kD25sW2YDHGyyc9uRDKBggC21i9WQe",
            "symbol": "OSMT",
            "name": "Osammot Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Osammot-Coin/main/Logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "game-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8eFhb4EeCBfb6FPrU9rDRW8LZVeva8zAz3yvtVxxmdqM",
            "symbol": "HIK",
            "name": "Ponhiki",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/omadi2/token/main/HouseofPonhikiLogo.png",
            "tags": [
                "utility-token",
                "capital-token",
                "DApp",
                "Application",
                "payment-protocol",
                "Payment"
            ],
            "extensions": {
                "twitter": "https://twitter.com/realwatertv",
                "youtube": "https://www.youtube.com/channel/UCf-kciaFvGJ2_pB0WK6hfvQ"
            }
        },
        {
            "chainId": 101,
            "address": "8fRZVFFBkCVc2NSSxHBufsCWCbXhPt478fbgAjW1cRLX",
            "symbol": "FV",
            "name": "Future Value",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FV85/Crypto/main/FVlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "12ZKf2tcsYtgUiB6wtVHmVZGAckDzkyYRy1iwcieJQdQ",
            "symbol": "SLSM",
            "name": "Solisium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solisium/solisium/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4aJiwFd8bscKH6tBfvK9j3vFD4YnKSQGJfhttYismubf",
            "symbol": "NERTIA",
            "name": "Nertia",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kadeaus/CC/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "NFT-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "Et3k45YA6ZMur4GcjuYJiiZSdnr78Jt2AhMbWs4cs3B",
            "symbol": "GNOM",
            "name": "GnomToken",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/CoinGnom/main/gnomcoin.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/CFgTvwDEHf",
                "twitter": "https://twitter.com/GnarlyGnomies",
                "website": "https://gnomiezgen2.fun/"
            }
        },
        {
            "chainId": 101,
            "address": "H7KGAGLpfKnPUcLUR521R13gKys5vVnjTrD5tyGgKP1D",
            "symbol": "KKOIN",
            "name": "KristenKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/klauducci/klauducci/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6U9zAUz4gGANpwMuXKFYzfWZp6VVd3BWromTKabMLFL5",
            "symbol": "HUMA",
            "name": "Huma Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HumaCoin/icon/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E6HdfEAvaY8Fz9avWHvP9iJLWhVrdvmRjvXNc2v3vzb7",
            "symbol": "GNANA",
            "name": "Golden Banana",
            "decimals": 9,
            "logoURI": "https://github.com/2beyondWRLD/token-list/blob/main/assets/mainnet/E6HdfEAvaY8Fz9avWHvP9iJLWhVrdvmRjvXNc2v3vzb7/logo.png?raw=true",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/awFhms4VYx",
                "twitter": "https://twitter.com/riseofsolapes"
            }
        },
        {
            "chainId": 101,
            "address": "AGqKX7F4mqJ8x2mUQVangJb5pWQJApaKoUfe5gXM53CV",
            "symbol": "USDCav",
            "name": "USDCav (Portal from Avalanche)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGqKX7F4mqJ8x2mUQVangJb5pWQJApaKoUfe5gXM53CV/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
                "assetContract": "https://snowtrace.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
                "bridgeContract": "https://snowtrace.io/address/0x0e082f06ff657d94310cb8ce8b0d9a04541d8052",
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "B2wfeYz5VtBnQVrX4M8F6FeDrprVrzKPws5qg1in8bzR",
            "symbol": "USDTav",
            "name": "USDTav (Portal from Avalanche)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B2wfeYz5VtBnQVrX4M8F6FeDrprVrzKPws5qg1in8bzR/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0xc7198437980c041c805a1edcba50c1ce5db95118",
                "assetContract": "https://snowtrace.io/address/0xc7198437980c041c805a1edcba50c1ce5db95118",
                "bridgeContract": "https://snowtrace.io/address/0x0e082f06ff657d94310cb8ce8b0d9a04541d8052",
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 101,
            "address": "CrDKsTDUursFNeTu5neSg4x8MGM6UkDbmmXYfe7xk4Qa",
            "symbol": "SPEVE",
            "name": "Speve Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SpeveCoin/crypto/main/Logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EP628RFby7V2Cx4MYbQthQeP4PaW4rVV6kRAxAj6f6C1",
            "symbol": "OpenP",
            "name": "Open Practica",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EP628RFby7V2Cx4MYbQthQeP4PaW4rVV6kRAxAj6f6C1/Logo.jpg",
            "tags": [
                "learning-token",
                "training-token",
                "meet-to-earn",
                "study-to-earn"
            ]
        },
        {
            "chainId": 101,
            "address": "CbQk9JHBZLsYECbDnvDeKDDHx5WiRXUqs9QMDfvuk1oZ",
            "symbol": "BMC",
            "name": "Blue Marble Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/uljisoft/assets/main/icons/blueGem.png",
            "tags": [
                "utility-token",
                "game"
            ]
        },
        {
            "chainId": 101,
            "address": "FmJeYBupgRuHiZrjUyVu1vLmj4fudrDaMQPx9A15SoZt",
            "symbol": "BQCN",
            "name": "BonaireCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BonaireCoin/CNCN/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GL9bUD7qqLDSEvRUZjvyBs7zMVh589sNydizviLz9PW",
            "symbol": "EHSC",
            "name": "EHS Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/evanset/EHSC/main/EHSC.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8zKYrMbJJVCSypbmxmtknjtf1GtmmYdmJSoBmpz6aH4z",
            "symbol": "CMPT",
            "name": "Campetta Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mcampetta/Campetta/main/Campetta_Logo.png",
            "tags": [
                "social-token",
                "learning-token",
                "training-token",
                "family-token"
            ],
            "extensions": {
                "description": "Token for the Campetta family, to be dispensed as a reward for successful completion of games and tasks.",
                "github": "https://github.com/mcampetta"
            }
        },
        {
            "chainId": 101,
            "address": "8Dv7BrugUUSXbcVRJr39fJV31m5AmbxunLADFWS26Suk",
            "symbol": "RHLM",
            "name": "Refined Land Metaverse Homes",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Dv7BrugUUSXbcVRJr39fJV31m5AmbxunLADFWS26Suk/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "CBV12y1pehFbhdnDpUfgPe88SbUZ5G2s1kLA449Yu3Ad",
            "symbol": "GUARD",
            "name": "VANGUARD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBV12y1pehFbhdnDpUfgPe88SbUZ5G2s1kLA449Yu3Ad/logo.png",
            "tags": [
                "VANGUARD"
            ],
            "extensions": {
                "discord": "https://discord.gg/thevanguards",
                "twitter": "https://twitter.com/Vanguards_SOL",
                "website": "https://vanguards.world/"
            }
        },
        {
            "chainId": 101,
            "address": "EK1rBdnucX4yf8JDCFQEC7rTejXEUqsjazDxHZaHSKT7",
            "symbol": "DRAY",
            "name": "Draygon Investment",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/dray/main/assets/draylogo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "description": "Draygon Invesment Co is Invesment DAO NFT project. ",
                "discord": "http://discord.gg/draygonco",
                "twitter": "https://twitter.com/DraygonCo",
                "website": "https://draygoninvestment.co/"
            }
        },
        {
            "chainId": 101,
            "address": "BwyQD7Kvn5ciu7D6B1S382vhfXmsneVZnuiH7LfjtFA6",
            "symbol": "BMP",
            "name": "Bohemian Mint Pass",
            "decimals": 0,
            "logoURI": "https://chiiepdxwskdrdtt3mqrnde3xh7hib4go22qacb5myxdj34xwwqq.arweave.net/EdCCPHe0lDiOc9shFoybuf50B4Z2tQAIPWYuNO-XtaE",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BohemiaArtFair"
            }
        },
        {
            "chainId": 101,
            "address": "FZGvzidSPbJXozPdNhFDCnxkwpD54GNvpHxGPGLf9EBQ",
            "symbol": "LIGHT",
            "name": "Celestial Light Token",
            "decimals": 6,
            "logoURI": "https://github.com/kozhi-dreams/Light-token-logo/raw/main/Light-token-logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community"
            ],
            "extensions": {
                "website": "https://celestialhoosters.com"
            }
        },
        {
            "chainId": 101,
            "address": "7sgs35BJHUQAsVBFat2f3M8dRggTZAPcstc3mRHoEmq1",
            "symbol": "COSMIC",
            "name": "Cosmic Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7sgs35BJHUQAsVBFat2f3M8dRggTZAPcstc3mRHoEmq1/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AQ1ZVHTKZBoWbd8qf3CUzymnLTFvGngU84zNjV11EFHs",
            "symbol": "MCHP",
            "name": "Mint Chip Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AQ1ZVHTKZBoWbd8qf3CUzymnLTFvGngU84zNjV11EFHs/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MintChipToken"
            }
        },
        {
            "chainId": 101,
            "address": "H9qEvSM7FCJUXSG13yss8vT7f82fHySypLqBMrhrfytb",
            "symbol": "SB",
            "name": "Santa Barberia Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Santa-Barberia-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HighHmwDW4o5QXGXt4n3HsimjLEKnTG4V1DjBwbhyP1i",
            "symbol": "SOCIETY",
            "name": "High Society Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HighHmwDW4o5QXGXt4n3HsimjLEKnTG4V1DjBwbhyP1i/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/highsociety",
                "twitter": "https://twitter.com/highsocietynfts",
                "website": "https://highsocietynft.io/high-society-protocol"
            }
        },
        {
            "chainId": 101,
            "address": "TosnWGnUeRM7D6TfVarLNAkFYsKoCUHKnjjd6Bim4jn",
            "symbol": "KF",
            "name": "Kurama Foxes",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TosnWGnUeRM7D6TfVarLNAkFYsKoCUHKnjjd6Bim4jn/logo.png"
        },
        {
            "chainId": 101,
            "address": "DN1NuymJZwZWTrLQy4nvS3SC41tJLuPxFy1wNMPemztE",
            "symbol": "P-MERC",
            "name": "Pre-Sale Mercanti NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DN1NuymJZwZWTrLQy4nvS3SC41tJLuPxFy1wNMPemztE/logo.png",
            "tags": [
                "whitelist-token",
                "mercanti",
                "pre-sale"
            ]
        },
        {
            "chainId": 101,
            "address": "Bc24bCVJLC1ugySyRoH9MNExW8VWxN1MZ3XJLsNgzjCe",
            "symbol": "DUALITY",
            "name": "Duality Dogs",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/g0dpain/crypto/main/DDC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BUzZpkLc97TGJH2oTzixkbRA5KFMUyWaon7iQAqgiQYP",
            "symbol": "twn",
            "name": "twin coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/smitte10/crypto2/main/twncoin2png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "KAFE5ivWfDPP3dek2m36xvdU2NearVsnU5ryfCSAdAW",
            "symbol": "KAFE",
            "name": "Kafe",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KAFE5ivWfDPP3dek2m36xvdU2NearVsnU5ryfCSAdAW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "KAFE Token",
                "discord": "https://discord.gg/r4vNRUTXY9",
                "twitter": "https://twitter.com/TheBuilderDAO",
                "website": "https://learn.figment.io/"
            }
        },
        {
            "chainId": 103,
            "address": "BDR3oUcZLRQtufDahJskbsxwTvfWt9jiZkJPVr4kUQg2",
            "symbol": "BDR",
            "name": "Builder",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDR3oUcZLRQtufDahJskbsxwTvfWt9jiZkJPVr4kUQg2/logo.png",
            "tags": [
                "community-token",
                "governance-token"
            ],
            "extensions": {
                "description": "BDR Token",
                "discord": "https://discord.gg/r4vNRUTXY9",
                "twitter": "https://twitter.com/TheBuilderDAO",
                "website": "https://learn.figment.io/"
            }
        },
        {
            "chainId": 101,
            "address": "22poAsb2M8gfh7oYEGTBiFFheSzf5tQTsrLKTA2jtbLT",
            "symbol": "$GBT",
            "name": "GoodBoyToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JakeElder/token-list/main/assets/mainnet/22poAsb2M8gfh7oYEGTBiFFheSzf5tQTsrLKTA2jtbLT/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "GpkU9GZXznnPwoCttnXFLTq4P9UVzocCUJT8Jsn3MzcC",
            "symbol": "DGG",
            "name": "Digital Gorilla Genesis",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpkU9GZXznnPwoCttnXFLTq4P9UVzocCUJT8Jsn3MzcC/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Yw6TQnVGD8",
                "twitter": "https://twitter.com/DigiGorillaNFT"
            }
        },
        {
            "chainId": 101,
            "address": "36pVTMos1K66AHSk8N3R27dPCzvMMziUpU8bHBHn2Qky",
            "symbol": "RHLM",
            "name": "Refined Land Metaverse HOMES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/36pVTMos1K66AHSk8N3R27dPCzvMMziUpU8bHBHn2Qky/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "5SdcK6tKKemj1fmq447mxpkFd3H33K96ZmACY9TbbZdR",
            "symbol": "LZRD",
            "name": "Lizard Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SdcK6tKKemj1fmq447mxpkFd3H33K96ZmACY9TbbZdR/logo.png",
            "tags": [
                "utility-token",
                "community"
            ],
            "extensions": {
                "discord": "https://discord.gg/x8XmhEJxbg",
                "twitter": "https://twitter.com/MasonicLizards",
                "website": "https://www.masoniclizards.com/"
            }
        },
        {
            "chainId": 101,
            "address": "epchejN2prm44RwMfWetBkbMr4wnEXHmMN9nmKyx2TQ",
            "symbol": "EPOCH",
            "name": "Playground Epoch Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/epchejN2prm44RwMfWetBkbMr4wnEXHmMN9nmKyx2TQ/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "2QxNtz8xeSZeY5kmFsGUbsqwqE5zQXdMfeHYUac1KvuT",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "7k5WRFxyHveTDVJiNj69r8pboregzRSTdoRvmBaETe3w",
            "symbol": "4x4NFC",
            "name": "RANGER",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7k5WRFxyHveTDVJiNj69r8pboregzRSTdoRvmBaETe3w/logo.png",
            "tags": [
                "4x4-Fan-Club",
                "4x4FC",
                "utility-token",
                "virtual-currency"
            ],
            "extensions": {
                "website": "https://gateway.pinata.cloud/ipfs/Qmb8pC2899CwCT4bywgP2Tjs61aP5csa9nHcmLfQzhHJWH"
            }
        },
        {
            "chainId": 101,
            "address": "8aAgMxie1DizqcYhxoSz2CMPMaDCpfe7ty3kLK9nWigN",
            "symbol": "Exg",
            "name": "Exchg",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8aAgMxie1DizqcYhxoSz2CMPMaDCpfe7ty3kLK9nWigN/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "qs37APvkooUwjD2R6kKjdULUERFDp3hvJqykUq6g3b9",
            "symbol": "KRKO",
            "name": "Kersh Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nkorte1/crypto/5541c8935777e71eefb60fa53000a5452c302fb3/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2TsiN3BxBYUqGfckjRdXLC47FEtihddcLub45kc5sEVK",
            "symbol": "KOT",
            "name": "Kotlet Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/teohz/crypto/master/kotlet.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HTFSPU2KwKh7nhBPUraTjd8jbhU2VA6Vm5XcdTSrHmfu",
            "symbol": "RBIT",
            "name": "Ribbit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HTFSPU2KwKh7nhBPUraTjd8jbhU2VA6Vm5XcdTSrHmfu/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CcVSZpJQcViLSA6q7oVnFeLpvTwznhhKKfkNFw7TF2Ew",
            "symbol": "SNDMN",
            "name": "Sandman",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/sndmn-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CF9uE7Z4FfJz71zkVpJMeCSME2D5mBgbSy2gtWeaWyTH",
            "symbol": "WAGON",
            "name": "Wagon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/wagon-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3rUgGMf8xBiJ9mcd3rS772iuU96nuE7JwGcxZGkeGDti",
            "symbol": "YUMMY",
            "name": "Treat",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/yummy-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HnU8xRe94VeRo2gGSKvo8PeFfzEioDXhK9mb7N6YGTXD",
            "symbol": "HAHA",
            "name": "Trick",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/haha-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5pWwUABvbh7v6ofwxJLanexojbgPP9YEo2fS1haJQF8M",
            "symbol": "D",
            "name": "D",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/d-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7LFXbvJbWBEBXzW4TqBd2jRSvyXx7q8W8ZTqnfQ6bmRa",
            "symbol": "EFF",
            "name": "F",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/eff-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7TzPNjubR4JjmEskAj7HuRETqJkiYbbbFHuZAoo42Sx3",
            "symbol": "HVAC",
            "name": "HVAC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/hvac-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Kb1iFskhn2qUtBXjtq2kyDd1MWZXjRSDhQW5haw6uJu",
            "symbol": "PRRR",
            "name": "Cat",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/prrr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "37FZ5LNTdMTD7Sy3HVHsbbmPnauUmKPdsxuJyx4wQVzd",
            "symbol": "COINAGE",
            "name": "Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/coinage-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToqN59DwBwzxfXD6v7EFUAbNLXpZHdzRfAJc1hqpP5V",
            "symbol": "CNFT",
            "name": "Cool TokenNFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Yash094/Testing-App/main/logo512.png",
            "tags": [
                "cools"
            ]
        },
        {
            "chainId": 101,
            "address": "WEbtb1LUhR8h44nvze1fLNeWXXSM4M9dtWxXT3r61ft",
            "symbol": "MRCS",
            "name": "Merces Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WEbtb1LUhR8h44nvze1fLNeWXXSM4M9dtWxXT3r61ft/logo.png",
            "tags": [
                "community-token",
                "story-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/mercestoken",
                "twitter": "https://twitter.com/mercestoken",
                "website": "https://mercestoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "8jNL59bysGpwcBLcdUY3mHUe3i5tKX8mimeQZDBqYXzs",
            "symbol": "iTHING",
            "name": "IoT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ithing-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "AqETemBd52MRXi1e26rV7yasVtP4F6wWGUvdUcBkE5TB",
            "symbol": "LIT",
            "name": "Lion Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AqETemBd52MRXi1e26rV7yasVtP4F6wWGUvdUcBkE5TB/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C5quBbSnDjLpdVuCQnJF38Uw3arfmHUNCPGPDR2L8cyh",
            "symbol": "Janus",
            "name": "Janus Finance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/0e419de885631f7331e395e0acf5a1cac6d6ea4d/assets/mainnet/C5quBbSnDjLpdVuCQnJF38Uw3arfmHUNCPGPDR2L8cyh/janis.png",
            "tags": [
                "DeFi",
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FinanceJanus"
            }
        },
        {
            "chainId": 101,
            "address": "8sxPUxRSRQD6vdvNLC65VMdLrjkbyyEtRfw4aMasvjUR",
            "symbol": "Umi",
            "name": "Umi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8sxPUxRSRQD6vdvNLC65VMdLrjkbyyEtRfw4aMasvjUR/umi.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6FfKvzcHxo2no9MwLs8FHXDxBhQj1Sg7Q4H3WnyhmHDw",
            "symbol": "Hikari",
            "name": "Hikari",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6FfKvzcHxo2no9MwLs8FHXDxBhQj1Sg7Q4H3WnyhmHDw/hikari.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AbD4ZqLj1xXwRWnnEGfuLK5KRfynSuNXRHLkQ9ntNcjg",
            "symbol": "Kaze",
            "name": "Kaze",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AbD4ZqLj1xXwRWnnEGfuLK5KRfynSuNXRHLkQ9ntNcjg/kaze.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7xGviwPk9Fj2YFLLoZdXZzj64TwQe9ysVS7JawHKaePD",
            "symbol": "Konoha",
            "name": "Konoha",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xGviwPk9Fj2YFLLoZdXZzj64TwQe9ysVS7JawHKaePD/konoha.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E1XbcaMheKrvRbaM8rMVnJkHJ9EYpHZCX6xctsDQ73Pp",
            "symbol": "Zio",
            "name": "Zio",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1XbcaMheKrvRbaM8rMVnJkHJ9EYpHZCX6xctsDQ73Pp/zio.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GbGg3uoYBjbY7PKbKsFi1vHE36PRVk8ycu5k5Ay9ojuN",
            "symbol": "CHRIS",
            "name": "Chris Token",
            "decimals": 9,
            "logoURI": "https://github.com/chris400p/crypto/blob/main/chrisb.png",
            "tags": [
                "community-token",
                "story-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DwmaUsVcYy9NoynnhwH1YfXKPwotjTKQgVW9bwZW4z7E",
            "symbol": "MEEN",
            "name": "MEENcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MEENcoin/MEEN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "24iJjSNbD3nZdjBi3UPcgwxDLZhAGd1GQozSy8JRpvaW",
            "symbol": "ISHA",
            "name": "Isashii Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/MIpito/isashii/main/logo.png",
            "tags": [
                "natural",
                "technology",
                "economy",
                "mining",
                "ecology",
                "electricity"
            ]
        },
        {
            "chainId": 101,
            "address": "5Emw7mifMM86nfHhyMC12p8pAW5DvWTFJeFtsP7DBHn2",
            "symbol": "WATR",
            "name": "Metajuana",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/XAGBack/MCSTOKEN/main/WATR.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gq5xdBxA39rRN5GsnJtodAnkqvFDQ4YxkEG3N7rS8XHw",
            "symbol": "KTR",
            "name": "Kotaru",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gq5xdBxA39rRN5GsnJtodAnkqvFDQ4YxkEG3N7rS8XHw/logo.png",
            "tags": [
                "NFT",
                "GAMEFI",
                "Staking"
            ],
            "extensions": {
                "description": "Kotaru is an SPL token of Kotaru Sharks, an NFT collection of sharks living on the Solana blockchain that love to play poker and vibe with friends.",
                "discord": "http://discord.gg/kotarosharks",
                "twitter": "https://twitter.com/KotaroSharks"
            }
        },
        {
            "chainId": 101,
            "address": "6pyFwtq8wZPumC2owaACuo4XS8qSWnKbWaw5rW4omuLh",
            "symbol": "DOODLE",
            "name": "Doodle Loot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6pyFwtq8wZPumC2owaACuo4XS8qSWnKbWaw5rW4omuLh/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CwLtCWJn5ppiTn9iheykBVTVD92JouBCjjL4bHt1xP7w",
            "symbol": "SHINI",
            "name": "Shinigami Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hackvice/Crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J2qkpFsE1P8E12hyqEBcGrMumLQEnzkfTaADW49PXZy1",
            "symbol": "CBDB",
            "name": "CBDB WL Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/DGcHG6Cqx6pm58JIlUsERiSbAXyobwnML3d5DZfF1ig",
            "tags": [
                "whitelist-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ChibiDBs",
                "website": "https://www.chibidbs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BrYZZjDTGzZXLsL3ABCYNa5ArQd48kJbhTyhbTp2fgq",
            "symbol": "CRF",
            "name": "CryptoFinn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FinnInBinn/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bpx6rPot4m7nhxEEnagokTbRqnaMeZXPnKsTEurNcgQR",
            "symbol": "DOOX",
            "name": "DOOX Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rce171/crypto/main/doox_coin_logo.png",
            "tags": [
                "tech-token",
                "technology",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7DpRoxt4gzQnbtBavWi6VyBY75fBPuoRNsBmr7KxeBDm",
            "symbol": "MHL",
            "name": "Mahalo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7DpRoxt4gzQnbtBavWi6VyBY75fBPuoRNsBmr7KxeBDm/logo.png",
            "tags": [
                "social-token",
                "tech-token"
            ],
            "extensions": {
                "website": "https://github.com/blagoon"
            }
        },
        {
            "chainId": 101,
            "address": "8G1SG7q8VyqCrjH7VjG9fouDDmHYJaYBMzBomdcUZ1qX",
            "symbol": "CACTI",
            "name": "CACTI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8G1SG7q8VyqCrjH7VjG9fouDDmHYJaYBMzBomdcUZ1qX/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/stranded_cactus"
            }
        },
        {
            "chainId": 101,
            "address": "73cnggNzqCd6PSdFVjJ2ohzvGYyrJhygKfj2Sjqi9zhx",
            "symbol": "AK",
            "name": "King Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/King4715/Crypto/main/King.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6sZdGff2HusNB4M4yqQxmfZicdwSMzjTF8axXeW4nQ2N",
            "symbol": "CATZ",
            "name": "Cat Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6sZdGff2HusNB4M4yqQxmfZicdwSMzjTF8axXeW4nQ2N/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://www.catsonmats.com"
            }
        },
        {
            "chainId": 101,
            "address": "5KmzqxNmxEz7mdhckVNb36BKztVWNdggeMfa71H9RfX4",
            "symbol": "Solnex",
            "name": "Solnex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5KmzqxNmxEz7mdhckVNb36BKztVWNdggeMfa71H9RfX4/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "8vrcRzHfVCnW4EkGdoYFYzwYhN7GdUYjED2nL7BbcGQx",
            "symbol": "JLFC",
            "name": "Joseph Fermin Inc",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/JLFC2644/JFLC/main/JLFC.png",
            "tags": [
                "community-token",
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/josephFermin0",
                "website": "http://josephfermin.com//"
            }
        },
        {
            "chainId": 101,
            "address": "CuZt4XCzqpPNLSqNeocNhZDBkDTgUwGFiURmkfuWiASw",
            "symbol": "HEYRST",
            "name": "Ankkurikeula",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Heyrst/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6KC8AU1Tm39sVvmwBkXBzW82XtHaRgSZqufQET9YipHY",
            "symbol": "NEAR",
            "name": "Near Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6KC8AU1Tm39sVvmwBkXBzW82XtHaRgSZqufQET9YipHY/logo.png",
            "extensions": {
                "facebook": "https://www.facebook.com/NearcastHQ",
                "instagram": "https://www.instagram.com/NearcastHQ/",
                "linkedin": "https://www.linkedin.com/company/nearcast",
                "twitter": "https://twitter.com/NearcastHQ",
                "website": "https://www.nearcoin.com/",
                "youtube": "https://www.youtube.com/channel/UCqvbKHoV0xaReGts8oedmnQ"
            }
        },
        {
            "chainId": 102,
            "address": "HEixDWPG1cTMdCDimN67oGc5SjbbVMMSDGJSX3G2Fyez",
            "symbol": "WORKS",
            "name": "WorksToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEixDWPG1cTMdCDimN67oGc5SjbbVMMSDGJSX3G2Fyez/logo.png"
        },
        {
            "chainId": 101,
            "address": "3hWBSqyHrJMDkSuAQtBYHwgKmMbJ666we5xegfDmMzGd",
            "symbol": "CURSE",
            "name": "Cursed Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3hWBSqyHrJMDkSuAQtBYHwgKmMbJ666we5xegfDmMzGd/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToSPoMNzvaZquVYDVdapSYcyM7rJcHykpjFPFz7BYSR",
            "symbol": "ColorShape",
            "name": "ColSha token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToSPoMNzvaZquVYDVdapSYcyM7rJcHykpjFPFz7BYSR/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/berczi17"
            }
        },
        {
            "chainId": 101,
            "address": "4XSvxSJBmsWzw63QMYQRf5WxfC4b2hLNNeoYp9Fbugkb",
            "symbol": "CSxB",
            "name": "Code Sydney test B",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/codesydney/assets/main/logo/test/hexagon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EiyHq2uL3AYCq7VREs69RbbMC6Ccj5vfYGpqFptb2Zie",
            "symbol": "NZT",
            "name": "NineZulu Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EiyHq2uL3AYCq7VREs69RbbMC6Ccj5vfYGpqFptb2Zie/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ed5nxXh2TT1Ry7MuX4Hi5YuCAnjmXEcpJ9LhdmDbCFQS",
            "symbol": "FUM",
            "name": "FUM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ed5nxXh2TT1Ry7MuX4Hi5YuCAnjmXEcpJ9LhdmDbCFQS/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolSquatchNFT",
                "website": "https://solsquatch.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CeRbm5araoFjmW7UGc5D85vHjD1gTUBMHuVynyqLLHa7",
            "symbol": "BFND",
            "name": "Boyfriend",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/bfnd-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CbvgsXZfdfSbPn7crh34oYAvLfBbTZDSLcrxbgZsiVBk",
            "symbol": "GFND",
            "name": "Girlfriend",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/gfnd-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZScomZJmrJs5DUjyPUH24Sz2srPFdqTcbEzk4WknLhW",
            "symbol": "BFF",
            "name": "Best Friend",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/bff-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CnLsjg3xZ8rtNg41DA9cXUTtF6VWL78UKr2RygjqcGo5",
            "symbol": "HUS",
            "name": "Husband",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/hus-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Coq3LbB52jzCxk5W8SJTyK3SB83sYTKEjs2JmHaoSGxS",
            "symbol": "WIF",
            "name": "Wife",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/wif-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HXgUrXgfVEmjpt1oaNjogj2XxL5QzbrgN1Sqjyv7o184",
            "symbol": "HDO",
            "name": "Honey-Do",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/hdo-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DMHjU5u3X9ZLRsoxufWa53WjfT84TXKH15ePANskj6UL",
            "symbol": "DTNT",
            "name": "Date",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/dtnt-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qNQBY77aeTWK141weqvSpeQ84DdHM3AvujffpWVSQXS",
            "symbol": "LOL",
            "name": "Laugh",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/lol-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "58D4h2DhKq3256NEK5geK4S6DzYDXxm9nNAhW5FAJ5gq",
            "symbol": "DINE",
            "name": "Dinner",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/dine-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "69eLQE1AvjBpjskJrDSVbkNKY4Z6J61A9BjGfxqEuH6x",
            "symbol": "TAIN",
            "name": "Entertainment",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/tain-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9vbTWQTmdKZed35iC14Q1XiQp7syUWwfwQZLXVrjnkxt",
            "symbol": "BUY",
            "name": "Shop",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/buy-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "mxhhKrBKjb8jzJYYoe6pYGMA4Qgr7ZD4wirHM3cHLdm",
            "symbol": "NCM",
            "name": "Income",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ncm-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ocPTPpMQZVMuTNxraMNyqxsAzHoxagA1RrP68HZvyHm",
            "symbol": "XPS",
            "name": "Expense",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/xps-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "T2uNWp4uzFkVHei6qkqVywvzxR2Rde9Zgt8pfC4G8nJ",
            "symbol": "SET",
            "name": "Wealth",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/set-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GooetU7meKL6HbnGj6ZiNAGC41nHX8a8P8jyyGWppnpY",
            "symbol": "FRDM",
            "name": "Freedom",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/frdm-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4UZzWmagD4QKRnmp94cM5BXQdVbspDXmKR57efcwkpWX",
            "symbol": "HT",
            "name": "Heat",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ht-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4V3g5mrjZeExqLMpJfmtHXmJsaxGpCW2hn6uj4FG54XL",
            "symbol": "CL",
            "name": "Cool",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/cl-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "46KYqqwbiuMipGidYos9LaMZNNteobscuqxP9jo3Taes",
            "symbol": "BRIGHT",
            "name": "Light",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/bright-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "96f3LWFcQo8amNTekr1wAUMRWQ99bV53D6xn2nbmgU2w",
            "symbol": "SNCK",
            "name": "Snack",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/snck-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DE3qpVWZuomEkXgBWD9r1u4EtkVDAFvQzrsxg6DnTzF1",
            "symbol": "DIET",
            "name": "Diet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/diet-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5cdoDqerd9AbmouZQrFnRVMSimo7G4kVXTKQf98WiKkv",
            "symbol": "Gummi",
            "name": "Gummi Bear Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5cdoDqerd9AbmouZQrFnRVMSimo7G4kVXTKQf98WiKkv/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GummiBears_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "86BrHAcZijW11wXTP4MqWYJoLZc8HL1egp8XuG8LfDCt",
            "symbol": "JOSE",
            "name": "JoseCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/JoseCoin/Crypto/main/JoseCoin.png",
            "tags": [
                "jose-knows-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4uoMXiEP1thMEsFjT9kQ8HyM1wYgx7CN6gV8waeEvqMy",
            "symbol": "UBU",
            "name": "UBU Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/UBUcares/UBUcrypto/main/UBUcares-crypto-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2S6vF5KfLUCBoBahC3QkWzTG4XSnJ7RbpM1hf3dmiEUp",
            "symbol": "Zim",
            "name": "Zim",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/danwdgc/Zim/main/logo.png",
            "tags": [
                "currency-token",
                "financial-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8KA12axBKQK5FG3SqTR1WU7ybvw5M3YDQEAHmk8eop8P",
            "symbol": "MMJ",
            "name": "MeetingMaryjane",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Neojunkerr/crypto/main/MMJ.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G2apZqTaccBmBipn9NLTXo4MJ6XbJ4z2EZmDGrtrZfam",
            "symbol": "ROOTER",
            "name": "Rooter Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G2apZqTaccBmBipn9NLTXo4MJ6XbJ4z2EZmDGrtrZfam/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "website": "https://www.rooter.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "H1CHW4k7e8PMrhT4DuaEQSaXMvhtkLtuuBFY24JfbzZ3",
            "symbol": "SNC",
            "name": "SnowCoin",
            "decimals": 0,
            "logoURI": "https://github.com/ri3m4nn/crypto/blob/main/Polish_20220207_030852210.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3vXYrqQkKpebReDPUaD1CseA3cWiqfxgPuKTe5aGL17U",
            "symbol": "SOUL",
            "name": "Lost Souls",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/9635375b050718ed272108309818f1d4347e0ca2/assets/mainnet/soul.png",
            "extensions": {
                "twitter": "https://twitter.com/solanalostsouls",
                "website": "https://discord.com/invite/efkrna3jbs"
            }
        },
        {
            "chainId": 101,
            "address": "5d7twcViTUiQVLdbKBYSv9Je1DeKkTsTyfEhx6vVvLpL",
            "symbol": "MUSK",
            "name": "Musk Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haydenzeller/MUSK/main/musk.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "tABbYiZsg2msMsPx9wZeJVJpBdCBdGBKDMTuy3XnH2V",
            "symbol": "TABBY",
            "name": "TABBY",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tABbYiZsg2msMsPx9wZeJVJpBdCBdGBKDMTuy3XnH2V/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/tabbycoinmeow",
                "website": "https://tabbylaunch.io"
            }
        },
        {
            "chainId": 101,
            "address": "5qjK1py1gFa1XSEpBjgXZtUE2JKYxNLNm3MvQqMAuCN3",
            "symbol": "SOVG",
            "name": "Sovana - Guard",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5qjK1py1gFa1XSEpBjgXZtUE2JKYxNLNm3MvQqMAuCN3/logo.png",
            "extensions": {
                "website": "https://www.sovana.world"
            }
        },
        {
            "chainId": 101,
            "address": "Gqu3e6x4iN5rGJwAEeCwQFsuuYP5dpvmB9ise2sufrcs",
            "symbol": "SOVO",
            "name": "Sovana - Oracle",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gqu3e6x4iN5rGJwAEeCwQFsuuYP5dpvmB9ise2sufrcs/logo.png",
            "extensions": {
                "website": "https://www.sovana.world"
            }
        },
        {
            "chainId": 101,
            "address": "D63gfFT3YE7GXUShznPXEVPJwoKhdWw2psHmrxjBTt1M",
            "symbol": "SOVD",
            "name": "Sovana - Degen",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D63gfFT3YE7GXUShznPXEVPJwoKhdWw2psHmrxjBTt1M/logo.png",
            "extensions": {
                "website": "https://www.sovana.world"
            }
        },
        {
            "chainId": 102,
            "address": "CmGD1rbJoEDE2vtGfeJx9Uje8ijYJSiSQV76WwmxHpXy",
            "symbol": "WORKS",
            "name": "WORKS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CmGD1rbJoEDE2vtGfeJx9Uje8ijYJSiSQV76WwmxHpXy/logo.png"
        },
        {
            "chainId": 101,
            "address": "G4Kigjc2hAs4UaeaAsUvWF4Pcsjuv8VQqJLtZpoF37sB",
            "symbol": "MRLN",
            "name": "Merlin Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lewixlabs/merlin-solana-token/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://github.com/lewixlabs/merlin-solana-token"
            }
        },
        {
            "chainId": 101,
            "address": "NQV11Y41dpwJjXA7BwUVNJh3uf9fFChLgdn97dqPCNp",
            "symbol": "VENE",
            "name": "VenehKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hartoken/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9hNfQggmq5QaWtf2HjjSvSSM18iCW4qF4kPUu4kZkMJS",
            "symbol": "NMTM",
            "name": "Naughty_Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/zung1/logo/main/logo.png",
            "tags": [
                "Test-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Jum2noBrYYT8Mt6VFJ9kPcGc4mXS7vDJzuAUAkBgmXP",
            "symbol": "ARTJ",
            "name": "ArtCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/unimintco/token-logos/main/ARTJ/logo.png",
            "tags": [
                "corporate"
            ],
            "extensions": {
                "facebook": "https://facebook.com/artjoker.ua",
                "instagram": "https://instagram.com/artjoker_official",
                "linkedin": "https://linkedin.com/company/artjoker-software",
                "telegram": "https://t.me/hello_artjoker",
                "website": "https://artjoker.net",
                "youtube": "https://www.youtube.com/user/artjokerua"
            }
        },
        {
            "chainId": 101,
            "address": "DgCZDUPm5PapUMZ3F9jbB62zDqzgpuBKgdANz34u7KL8",
            "symbol": "GOSHT",
            "name": "Gosht Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GoshNFT/uploads/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/goshnft",
                "instagram": "https://www.instagram.com/goshnft/",
                "linkedin": "https://www.linkedin.com/company/goshnft",
                "telegram": "https://t.me/GoshNFT",
                "twitter": "https://twitter.com/GoshNft",
                "website": "https://www.goshnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2CyctHvyb8ioWM35doYQHaGk6UpYX5jo24oL3mgGKwe6",
            "symbol": "WBT",
            "name": "Webbeat",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2CyctHvyb8ioWM35doYQHaGk6UpYX5jo24oL3mgGKwe6/logo.png"
        },
        {
            "chainId": 101,
            "address": "Cf31XKvBYx287TL8C1XBmDuLPDL8BKzozwkFC58RTqC6",
            "symbol": "NEU",
            "name": "Neuron",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cf31XKvBYx287TL8C1XBmDuLPDL8BKzozwkFC58RTqC6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaBrains",
                "website": "https://solbrains.com"
            }
        },
        {
            "chainId": 101,
            "address": "CnzCjrBQNzcwG1XEfBtP6kfvsbkLmXmSsHxR6UwgfXYQ",
            "symbol": "$INV",
            "name": "Invokers Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CnzCjrBQNzcwG1XEfBtP6kfvsbkLmXmSsHxR6UwgfXYQ/logo-iv.png",
            "tags": [
                "social-token",
                "nft",
                "game"
            ]
        },
        {
            "chainId": 101,
            "address": "MoscKLpTs9EV4ADTCGYATt1cHLdmybZ7215gKTdWAq7",
            "symbol": "MOSC",
            "name": "Moscovium",
            "decimals": 9,
            "logoURI": "https://arweave.net/i1bb-mYGWpJyDivUpnyExbYfvefgH-NcsLhsCMGeAjc",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Metazillas",
                "website": "http://metazillas.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2mXvNjEjY4QaQaWEAcaxojCHP7m8iujD97hZ3KEx13k6",
            "symbol": "OGN",
            "name": "Okegani",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/slaveofcode/okegani-assets/master/images/ogn.png",
            "tags": [
                "social-token",
                "community-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "HDzAraAsi2WaxmyAby7W4xQHx6Z5VgfweAcPxRHwnefE",
            "symbol": "MDL",
            "name": "MDL Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mihbo75/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6crJB6HHyAdiuMYjW9W8p6i4QSfBmRPTw1xbrG8zXfHy",
            "symbol": "FRC",
            "name": "Frederikk Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LorenzoMann/crypto/main/FC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CjxPDfH3EX2gp3qypLbwmePyehwpsvU3Qc7GmxP3LQQG",
            "symbol": "HOOTS",
            "name": "SolOwl Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CjxPDfH3EX2gp3qypLbwmePyehwpsvU3Qc7GmxP3LQQG/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://t.co/iOUr4IKfcm",
                "twitter": "https://twitter.com/owlsofsol",
                "website": "https://solowl.io"
            }
        },
        {
            "chainId": 101,
            "address": "4HU3YPmYuejnEXhG24dUmFuVwvtkYqebdanqqubRsiFP",
            "symbol": "BEAC",
            "name": "Beatcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/asdrubalchirinos/beatcoin/main/Beatcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "HjChwVRT1WjbdDx6ikyKp4qXWLowXfneQZ6MXJd5Zw6p",
            "symbol": "PoSB",
            "name": "PoSB Doubloon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HjChwVRT1WjbdDx6ikyKp4qXWLowXfneQZ6MXJd5Zw6p/logo.png",
            "tags": [
                "posb-token",
                "doubloon",
                "test"
            ]
        },
        {
            "chainId": 101,
            "address": "GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs",
            "symbol": "JFI",
            "name": "Jungle DeFi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs/logo.png",
            "tags": [
                "governance-token"
            ],
            "extensions": {
                "description": "The Jungle Finance governance token.",
                "discord": "https://discord.gg/2DWjx5NywE",
                "medium": "https://medium.com/@JungleDeFi",
                "twitter": "https://twitter.com/JungleDeFi",
                "website": "https://jungledefi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "TosKhWRKkaf3Bc6c5j3BYRx8EQn8w17xVRx1HAvv2zV",
            "symbol": "PD",
            "name": "Pixel Drugs Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solodevo/pixeldrugs/main/token.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Hd97U9z3eg",
                "twitter": "https://twitter.com/PixelDrugs",
                "website": "https://www.pixeldrugs.com"
            }
        },
        {
            "chainId": 101,
            "address": "D3Cu5urZJhkKyNZQQq2ne6xSfzbXLU4RrywVErMA2vf8",
            "symbol": "cSLND",
            "name": "Solend SLND",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3Cu5urZJhkKyNZQQq2ne6xSfzbXLU4RrywVErMA2vf8/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "6XrbsKScacEwpEW5DVNko9t5vW3cim9wktAeT9mmiYHS",
            "symbol": "cUSDT-USDC",
            "name": "Solend USDT-USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6XrbsKScacEwpEW5DVNko9t5vW3cim9wktAeT9mmiYHS/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "4icXEpFVMrcqob6fnd3jZ6KjKrc6cqre6do1f8kKAC1u",
            "symbol": "cmSOL-SOL",
            "name": "Solend mSOL-SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4icXEpFVMrcqob6fnd3jZ6KjKrc6cqre6do1f8kKAC1u/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "QQ6WK86aUCBvNPkGeYBKikk15sUg6aMUEi5PTL6eB4i",
            "symbol": "cstSOL",
            "name": "Solend stSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/QQ6WK86aUCBvNPkGeYBKikk15sUg6aMUEi5PTL6eB4i/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "AFq1sSdevxfqWGcmcz7XpPbfjHevcJY7baZf9RkyrzoR",
            "symbol": "cscnSOL",
            "name": "Solend scnSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AFq1sSdevxfqWGcmcz7XpPbfjHevcJY7baZf9RkyrzoR/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "3JFC4cB56Er45nWVe29Bhnn5GnwQzSmHVf6eUq9ac91h",
            "symbol": "cmSOL",
            "name": "Solend mSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JFC4cB56Er45nWVe29Bhnn5GnwQzSmHVf6eUq9ac91h/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "E9LAZYxBVhJr9Cdfi9Tn4GSiJHDWSZDsew5tfgJja6Cu",
            "symbol": "cORCA",
            "name": "Solend ORCA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9LAZYxBVhJr9Cdfi9Tn4GSiJHDWSZDsew5tfgJja6Cu/logo.png",
            "tags": [
                "solend",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://solend.fi"
            }
        },
        {
            "chainId": 101,
            "address": "AZci9R148CU6hfnnE8ffm2K5mkxagbnTAZNQF5fLbvHb",
            "symbol": "CUSE",
            "name": "CuseTheJuice",
            "decimals": 9,
            "logoURI": "https://github.com/CuseTheJuice/token-list/blob/main/assets/mainnet/AZci9R148CU6hfnnE8ffm2K5mkxagbnTAZNQF5fLbvHb/logo.png?raw=true",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xHpRcRKG",
                "twitter": "https://twitter.com/CuseTheJuice"
            }
        },
        {
            "chainId": 101,
            "address": "AR1AwFBUTQ2QNrKaY1vAMmHqqwQWGfX3bzxSaqJ76uPd",
            "symbol": "CLIP",
            "name": "Clip Finance",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/M19crq9/gallery-1.png",
            "tags": [
                "Lending-Protocol",
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ClipFinance",
                "website": "https://www.clip.finance"
            }
        },
        {
            "chainId": 101,
            "address": "6jeSdTbLzrgSpnATG38zQJLfEVwbrP8WXxzzgmsVpRFW",
            "symbol": "PRMD-USDC",
            "name": "PRMD-USDC LP",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/84945202/152856372-8691eef7-6eb4-40b3-a307-0e033104fa9f.png",
            "tags": [
                "PRMD",
                "primordials-coin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Primordialsgame",
                "website": "https://primordialsgame.com/"
            }
        },
        {
            "chainId": 101,
            "address": "B9LtfDZWWRrihYu8jDN57thcqqi7xfWAvj8yq4o2YJxw",
            "symbol": "UPB",
            "name": "Upbring Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SOLreaper88/Upbring-Logo/main/UpbringLogo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "e66ioN3eaRDSnKfHZrB72Nr6eBUZxxq1ZDHxnHdnFmo",
            "symbol": "BLDRA",
            "name": "Balandra",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AKDEVFD/btit/main/logo.png",
            "tags": [
                "social-token",
                "nft",
                "game"
            ]
        },
        {
            "chainId": 101,
            "address": "3UmGKDt2wvwaU3C5L711ikNMtM2fwrB5qpyCm1DDPC71",
            "symbol": "YADA",
            "name": "DAVSHALONA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yanivberg/tiktoken/main/yada.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AnQdNTJKgU5TY9YYnFMBaL6Hfc7uUGZzSkZVduPVCfMx",
            "symbol": "BAOP",
            "name": "PromiseCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/justcallmetk/cryptotokenexperiment/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "educational-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8yiekaUUidqA8bQ5QuWGNgrSDCnZVf5te6ZykGeY8roa",
            "symbol": "MANIAK",
            "name": "Maniak NFT",
            "decimals": 9,
            "logoURI": "https://github.com/OmiCodez/maniaktoken/raw/main/logo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ManiakNFT1",
                "website": "https://maniak-nft.space"
            }
        },
        {
            "chainId": 101,
            "address": "xinvJyYM2DK7gZrntebb23sJfZWYhbCArsVaWZtepjN",
            "symbol": "XINV",
            "name": "XInvoke",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xinvJyYM2DK7gZrntebb23sJfZWYhbCArsVaWZtepjN/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8CuXwnLm71jRLRNJ4Lv92JWCiE9SSmwKSyG6Xg812kVY",
            "symbol": "FKA",
            "name": "FRANKA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/FRANKA-COIN/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HBSPEVBvSJbRXmeyf7gYSZbPeLjZQTBeNotjsSrtqHTe",
            "symbol": "HBWT",
            "name": "Happy Bunnies Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBSPEVBvSJbRXmeyf7gYSZbPeLjZQTBeNotjsSrtqHTe/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HzPdErnvgJCUVHYJ78o4HvpdErcGCkd75fBQYabbp6uR",
            "symbol": "GBL",
            "name": "GLOBAL COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Marianngg/crypto/main/GLOBALcripto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EVA7k8PYK3VkSXvV29uUjS3VXzfUN89Vc8etkW4quxKc",
            "symbol": "TIPS",
            "name": "Mystic Money",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CMHayden/crypto/master/MysticLogo.png?token=GHSAT0AAAAAABPUDPL3K4KVVCNUVAPF6YMOYQKYTGQ",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2RUXbnyJHkVMuKXW1shAjKVPxgSmi1LQRuYcHUe7NeUW",
            "symbol": "ENFENDI",
            "name": "ENFENDI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hoops773/enfendi/main/IMG_8759.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AVPutJiUTzcDEpekrZ1G3CDr1K9b51VbmhbtaKpjJeJh",
            "symbol": "AKHI",
            "name": "AKHI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Akhi-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "social-token",
                "meme-token",
                "akhi-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GWLT3Dh8MvXPD2iCCMCvxrbmVp2zNmyFBpyDpTZBq4Fi",
            "symbol": "MGWL",
            "name": "Maho Genies Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWLT3Dh8MvXPD2iCCMCvxrbmVp2zNmyFBpyDpTZBq4Fi/logo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mahogenies",
                "website": "https://www.mahogenies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3n2bNUxAm9S3y6sP6ovsbC21x7anWs4YP8czAkzVBnej",
            "symbol": "LYIT",
            "name": "LYIToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/JoeTheGilbert/crypto/main/logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C3jJ1FMaKXa5r7u36zuoqnqmcriscNAgTh94CwAJqaZV",
            "symbol": "MOL",
            "name": "Molecula",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C3jJ1FMaKXa5r7u36zuoqnqmcriscNAgTh94CwAJqaZV/logo.svg",
            "extensions": {
                "website": "https://moleculanum.com/"
            }
        },
        {
            "chainId": 101,
            "address": "47QLe2y3Ao7r8A2K75XGBjXotrnvUp79arYvf5zNzzmV",
            "symbol": "BLUB",
            "name": "Bluebeary",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/an7ana5/crypto/main/BlooBearySmall.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BH6pgMckUTvpyh9iK6ocW5iqv9g4k8iL8zXwuMphsfeS",
            "symbol": "RAC",
            "name": "Rage Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BH6pgMckUTvpyh9iK6ocW5iqv9g4k8iL8zXwuMphsfeS/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HHW3Bjf3PnufTZnnm1LV8e8cfcrs3k8QvTBJ9uuDKyo8",
            "symbol": "HHWL",
            "name": "Hamsa Hands Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HHW3Bjf3PnufTZnnm1LV8e8cfcrs3k8QvTBJ9uuDKyo8/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7nKjEQVc5tNG3KU1fkhBFqpFy4e6z4QY74HDATWcBbez",
            "symbol": "BROC",
            "name": "BROCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/echo5even/crypto/main/bro.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ArGtDgKVbu2hKX9HHUzgknuKgx2PFHCg2HfPL96XvpBk",
            "symbol": "SHAD",
            "name": "Shad Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shad01111000/crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DvqzmxRatXsdWLeZ6zQnkQdXNGnNqE6n227TkHbRSCTF",
            "symbol": "HOMY",
            "name": "HOMELESSARMY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Homelessarmy/crypto/main/logo.png",
            "tags": [
                "social-token",
                "trading-token",
                "nft-token",
                "community-token"
            ],
            "extensions": {
                "description": "HomelessArmy (HOMY) is Eco-System Perspective for Homeless",
                "telegram": "https://t.me/homelessarmy",
                "twitter": "https://twitter.com/homelessarmy_",
                "website": "https://homelessarmy.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FQgPLqhrErJQ85wmxRggvUnqmvVoMzWYzGQSxQ8MYxSL",
            "symbol": "SLRZ",
            "name": "SolRockets",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/PadureanRazvan/crypto/main/SolRockets.png",
            "tags": [
                "gaming-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FNMLmBPkhh7nBFyGHsdrmCuvgEf6ygpaVqFejimHEx9V",
            "symbol": "vBLSH",
            "name": "vBLSH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNMLmBPkhh7nBFyGHsdrmCuvgEf6ygpaVqFejimHEx9V/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Fearless_Bulls",
                "website": "https://fearlessbulls.club/"
            }
        },
        {
            "chainId": 101,
            "address": "FH9zPGDCw4TfhJCJmYd7EnJ9E5wtjHTynM6m128Eh5LA",
            "symbol": "ELK",
            "name": "Wapiti Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gruntlabs/wapiti/2380612a92f617f6bf869490b94fae94c98ef9a9/assets/ELK_Icon.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/2AAEaYmQaV",
                "reddit": "https://www.reddit.com/r/WapitiToken/",
                "website": "https://token.wapiti.app/"
            }
        },
        {
            "chainId": 101,
            "address": "Gmn6oi8jSbncob1UZYVQeKqc5JY1w471ADgDDmqG8uHN",
            "symbol": "DORAw",
            "name": "Doragon Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pixelateddoragon/brand/main/wl-token.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://twitter.com/PixelDoragon"
            }
        },
        {
            "chainId": 101,
            "address": "DoP9jJrLVLUrG6NaAjJoHKTnhQq59AFXRzmjc677UCSm",
            "symbol": "CAPE",
            "name": "Citizen Apes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/citizenape/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DqsgNL9KnjSNbCvzuKdfT18N5VQDzhL1EuNC3CTFHrGy",
            "symbol": "HLX",
            "name": "Helix",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KiranPothana/Helix/main/Helix.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E4DwhpJWCGSLY1K6LjWjB94pMnLQszhS9yRz5LkuLGL3",
            "symbol": "DUCK",
            "name": "Duck Patrol",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E4DwhpJWCGSLY1K6LjWjB94pMnLQszhS9yRz5LkuLGL3/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LsinpBtQH68hzHqrvWw4PYbH7wMoAobQAzcvxVHwTLv",
            "symbol": "lsIN",
            "name": "Locked Staked Sol Invictus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LsinpBtQH68hzHqrvWw4PYbH7wMoAobQAzcvxVHwTLv/logo.png",
            "tags": [
                "decentralizedreserve",
                "utility-token",
                "DeFi",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/invictusdao",
                "twitter": "https://twitter.com/InvictusDAO",
                "website": "https://invictusdao.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "Bqr6xVZ9mTMuiTgvZC4tkMC7kH1WdP4r5CZjy2fV3GQe",
            "symbol": "JTS",
            "name": "Josh Tasman Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/joshtasman/cSolana/main/JTS_logo_small.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B4pZJFQm9VDdscJzoR4TTuj8YwexY4kYMfmRCxbZwbcV",
            "symbol": "HERD",
            "name": "Herd Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TheChillElephantsClub/assets/main/HERD_COIN_LOGO.png",
            "tags": [
                "social-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/qMb6Evqe2b",
                "twitter": "https://twitter.com/ChillElephants",
                "website": "https://thechillelephantsnft.site/"
            }
        },
        {
            "chainId": 101,
            "address": "WLvnutUvmM44YrqHN7y7ow5EJWW9GGmeijLubQbyy3J",
            "symbol": "DCOMPWL",
            "name": "Degen Competitions Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLvnutUvmM44YrqHN7y7ow5EJWW9GGmeijLubQbyy3J/icon.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DegenComp"
            }
        },
        {
            "chainId": 103,
            "address": "Ga8zd84edy8Jn7t8hjKU1sGNUfb3eeqpfeAYC9hKDpgM",
            "symbol": "EMD",
            "name": "Emerald",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/Emerald/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3tkmSMhACRrFFUSVfq8C43mHXjyCWkfShe6gEtZi292t",
            "symbol": "CUPS",
            "name": "Coffee",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haydenzeller/CoffeeProtocol/main/img/Coffee.png",
            "tags": [
                "social-token",
                "payment-protocol"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HaydenDevs"
            }
        },
        {
            "chainId": 101,
            "address": "77Aw7fW4dMQLV1tzJdtepQQsrZddMPwjskWTqv7qdv17",
            "symbol": "NAU",
            "name": "NaughtyFingers",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/naughtyfingers/naughtyfingers/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NaughtyFingerss",
                "website": "https://naughtyfingers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3",
            "symbol": "prtSOL",
            "name": "prtSOL (Parrot Staked SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/gopartyparrot",
                "medium": "https://gopartyparrot.medium.com/",
                "telegram": "https://t.me/gopartyparrot",
                "twitter": "https://twitter.com/gopartyparrot",
                "website": "https://parrot.fi"
            }
        },
        {
            "chainId": 101,
            "address": "68eWsAzjiskQVcQ6qj9Tn6AK3ss532asTckFT5c3i5Yj",
            "symbol": "JFC",
            "name": "Jelly Fish Crypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/68eWsAzjiskQVcQ6qj9Tn6AK3ss532asTckFT5c3i5Yj/logo.png",
            "tags": [
                "community-token",
                "videogame"
            ],
            "extensions": {
                "website": "https://jellyfishcrypto.com"
            }
        },
        {
            "chainId": 101,
            "address": "GZ1BzNsCkV7kd1cYuWHQRHmVSTLqGm71uJiPWep9nh7r",
            "symbol": "pMOON",
            "name": "Moonland WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZ1BzNsCkV7kd1cYuWHQRHmVSTLqGm71uJiPWep9nh7r/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wxTc2t68J8",
                "twitter": "https://twitter.com/MoonlandVerse",
                "website": "https://www.moonland.world"
            }
        },
        {
            "chainId": 101,
            "address": "4ckMuvhiqeLAmVh9cCP8M8M3sNomv9MAzTZxAD4ZNARJ",
            "symbol": "$SPWX",
            "name": "SPWX",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ckMuvhiqeLAmVh9cCP8M8M3sNomv9MAzTZxAD4ZNARJ/logo.png",
            "tags": [
                "MANAGEMENT"
            ]
        },
        {
            "chainId": 101,
            "address": "6A1uWJRgUVmJYSVYxACXjsvxcy3EzzPdMxHt12j9J1dx",
            "symbol": "BENJI",
            "name": "BENJI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stefanylaforest/benji/main/BENJI.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Kts7TQFVrBseq9GokZ71o2gBiAmQNQc1ykdVZtH1JhS",
            "symbol": "TON",
            "name": "The Open Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/unimintco/token-logos/main/TON/logo.png",
            "tags": [
                "general-token",
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "the-open-network",
                "description": "TON is a third-generation proof-of-stake blockchain designed in 2018 by the Durov brothers, the founders of Telegram Messenger. Later, it was handed over to our open TON Community, which has been supporting and developing it ever since.",
                "github": "https://github.com/newton-blockchain",
                "telegram": "https://t.me/tonblockchain",
                "website": "https://ton.org"
            }
        },
        {
            "chainId": 101,
            "address": "8U72UUryrh6xtHyAsHbyEvESj8X5ARQtNAu3XY7ZtkJd",
            "symbol": "ZENY",
            "name": "ZenCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/badeafrost/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3jJiWeL1i1p5z2Vrev3Ybcb13eBjSuTYEpY9uUPgwRDf",
            "symbol": "RSCU",
            "name": "Rescue Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3jJiWeL1i1p5z2Vrev3Ybcb13eBjSuTYEpY9uUPgwRDf/RSCU_logo.png",
            "extensions": {
                "discord": "https://discord.gg/Mkrz63aMFr",
                "twitter": "https://twitter.com/Rescue_DAO",
                "website": "https://rescuedao.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Fb1RdmtEdhyYWATEGMrLC27DmYdszpGfzg4aGoduzkb6",
            "symbol": "CASTLE",
            "name": "Beverweerd Castle",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fb1RdmtEdhyYWATEGMrLC27DmYdszpGfzg4aGoduzkb6/castle1PNG.png",
            "tags": [
                "Beverweerd",
                "asset-token"
            ],
            "extensions": {
                "website": "https://www.blocksto.ne/castle"
            }
        },
        {
            "chainId": 101,
            "address": "7GsajQSLyh4vsgsRLZVXgptr3pPdxidkYpzeKZ2YYkhg",
            "symbol": "MTY",
            "name": "MTYGROUP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/krazyesko/cryptojpg/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZsvnTcJgUncYcPx6gZZeeEN79SNr2f57ys9sLDDVAK7",
            "symbol": "$OBL",
            "name": "OBL (Oblivion City Token)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZsvnTcJgUncYcPx6gZZeeEN79SNr2f57ys9sLDDVAK7/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "game",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/PCxN8rNXCx",
                "twitter": "https://twitter.com/OblivionCityNFT",
                "website": "https://oblivion.city"
            }
        },
        {
            "chainId": 101,
            "address": "21jZ1ESEFYh9SBwjT5gqE3jHv3JnaGhgvypLTR96QXW9",
            "symbol": "sUSD",
            "name": "Solcasino USD",
            "decimals": 6,
            "logoURI": "https://files.solcasino.io/susd.png",
            "tags": [
                "stablecoin",
                "web3",
                "lottery",
                "casino",
                "bet"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Solcasinoio",
                "website": "https://www.solcasino.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HHsUHFuSuXYoJiEkfqxFpVArhUfkxs7d6peNWQMuVLQw",
            "symbol": "HHWL",
            "name": "Hamsa Hands WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HHW3Bjf3PnufTZnnm1LV8e8cfcrs3k8QvTBJ9uuDKyo8/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2NwuBrDSvbBvH8WVKyhVJvyakPSuLH8P2cceApgVQZWv",
            "symbol": "FAU",
            "name": "FAU-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Adrian2541/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FZxanxcLw2d5a7bBRcQsRpC5KD5LZWpRLMMo4YAvu4eW",
            "symbol": "GRWLR",
            "name": "Growler",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D7txa12HUtWk5dVxC2Ybg9QoE7ALZgDcUEKdrh43V8fB/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LeopardSNFT",
                "website": "https://leopardsyndicate.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4EPoEU6Yjpv3fiZ9awuUv8rCQzBFRjPhMmaM4L1uQVHm",
            "symbol": "KAPH",
            "name": "karima_phoenix",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KarimaPhoenix/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHNQXwHSKsNu9NPBiWb86mWDQTRj27diPJu55BC73KW2",
            "symbol": "DOHWL",
            "name": "Demons on Holiday Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DNKENNFT/logo/main/logo.png",
            "tags": [
                "whitelist-token",
                "NFT",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/nTq4dgbSpc",
                "instagram": "https://www.instagram.com/demonsonholidaynft/",
                "twitter": "https://twitter.com/DemonsOnholiday",
                "website": "https://demonsonholiday.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E1CRrNYTykhsJLUEkDEzJJexzCaoJ18fVSfvrHqRYecw",
            "symbol": "DALM",
            "name": "Dalmatian Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1CRrNYTykhsJLUEkDEzJJexzCaoJ18fVSfvrHqRYecw/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "website": "https://www.dalmatianfoundation.org"
            }
        },
        {
            "chainId": 101,
            "address": "5Cw2VrDPpDpujNK79uXUxSy2pXfc9XKQxbzKx65VpYEn",
            "symbol": "BKN",
            "name": "Bôkaynou",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Cw2VrDPpDpujNK79uXUxSy2pXfc9XKQxbzKx65VpYEn/logo.png",
            "tags": [
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3JkhC6yTBgupgicVwtUDTNsiUGxj2WsupemdrSFgApfY",
            "symbol": "MANDR",
            "name": "MathewAndRedsell",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bushido-lee/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7kWBhuqxf6tX1GUgAQAh9fgEHqBoizuQX7vvHTfhysiL",
            "symbol": "LMINT",
            "name": "Laohu Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/laohu.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Gen0 Laohu Kingdom Mint Token",
                "website": "https://www.laohukingdom.com/"
            }
        },
        {
            "chainId": 101,
            "address": "32iV3tk9bDgf2nBHVMbD2HgZnv4vccDZhrDrK2eUkmJd",
            "symbol": "MAGIK",
            "name": "Magik Finance",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/6J5p50N/logo-magik-1.png",
            "tags": [
                "Lending-Protocol",
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Magik_Finance",
                "website": "https://magikfinance.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "HaZjxfrB7pirwCaw73uz7n3Gn1q5NaFwNjXpEMrTspto",
            "symbol": "CITK",
            "name": "Cinnamon Meme Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/emb8767/assets/main/cinnamon.png",
            "tags": [
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "aaCcokDefDnFFRkVrpxFicubsAaMSHpnXiXNCuPab4z",
            "symbol": "GRBL",
            "name": "Greeble Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/joshtasman/cSolana/main/grbl_logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9WPM6pYALpcRzLs4qYafvm8chfRYLrtVBQe3aDTDBTuz",
            "symbol": "GMBC",
            "name": "Gamers Bet Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JohnCodePie/GamersBetCoin/main/GamersBetCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DjcCbwFLCW16MMEtiH5Bk3DZ1Ggy2oYdB1FQy7hRK8dj",
            "symbol": "TLSC",
            "name": "The Living Stone Church coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Shad01111000/crypto/main/TLSC_logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/livingstonefam/",
                "website": "https://thelivingstone.church/"
            }
        },
        {
            "chainId": 101,
            "address": "7SNynmqBGZsrxtUCz8wCF3LoH7KJbA4KPv6N5dnbU9yM",
            "symbol": "$SMLY",
            "name": "SmileyToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TSKsmiley/Smiley.sol/master/logo.png",
            "tags": [
                "social-token",
                "dev-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLDjeuGksQC5wiha7zpmq1RaHhmHiE7gvE4Qkxnyyda",
            "symbol": "DOHWLT",
            "name": "Demons on Holiday Whitelist Token S1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DNKENNFT/logo/main/logo.png",
            "tags": [
                "whitelist-token",
                "NFT",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/nTq4dgbSpc",
                "instagram": "https://www.instagram.com/demonsonholidaynft/",
                "twitter": "https://twitter.com/DemonsOnholiday",
                "website": "https://demonsonholiday.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2KhhdkFcLe8ZW5e7r93KbkAeDnfxHcfYH4GmXjCuEVwC",
            "symbol": "321",
            "name": "troisdeuxun coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2KhhdkFcLe8ZW5e7r93KbkAeDnfxHcfYH4GmXjCuEVwC/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://troisdeuxun.ch/"
            }
        },
        {
            "chainId": 101,
            "address": "7yejJw7D5khrhETFZjahaD3LfKpi1XDSLYSCb5jYVkuN",
            "symbol": "CCT",
            "name": "Coin98 Community Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/abbas-bachari/logo/main/src/cct.png",
            "tags": [
                "CCT",
                "Coin98",
                "Community",
                "Token"
            ],
            "extensions": {
                "description": "This token is used to reward active members of the @COIN98 Telegram channel.",
                "telegram": "https://t.me/coin98"
            }
        },
        {
            "chainId": 101,
            "address": "GCEm8HRdz61TSUHch21GbdyKtU3jUt9bjyeixv1M7HWu",
            "symbol": "YTCK",
            "name": "YuticK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yutick/crypto/main/ytck.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5XUSf9cjuX3NmMs3GNY4ASf8nhD12PcZ31vJiTWJNMTR",
            "symbol": "CE5C",
            "name": "CE-5 GALACTIC COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/StarLord36/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F9ShtW8SG3rypRSUKFogwPPMUPN38EJ77KkxkUQ3degr",
            "symbol": "DRIVE",
            "name": "DRIVE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Vo1dedParad0x/Drive/main/Drive1.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WoLFWyFspu68aHQeKRbgYPma6H16cHPXErJK8o3sczb",
            "symbol": "DIRE",
            "name": "DireWolf",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WoLFWyFspu68aHQeKRbgYPma6H16cHPXErJK8o3sczb/Direlogo.png",
            "tags": [
                "community-token",
                "utility-token",
                "stake-pool-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/direwolfsol"
            }
        },
        {
            "chainId": 101,
            "address": "3NpdRoGtohjoJD5cwXJxU3g3y4UsY1GVcMohGfS32LoQ",
            "symbol": "TKC",
            "name": "KnightCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JopdeRidder-hub/KnightCoin/main/0-5415_fortnite-black-knight-png-transparent-png%20(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8xvbRKnmRWbsS8hpsADvaXS7EtjSFoyWGsteqQwuWwyp",
            "symbol": "TLAWL",
            "name": "The Lost Aliens WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8xvbRKnmRWbsS8hpsADvaXS7EtjSFoyWGsteqQwuWwyp/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sou1ELxm3XpLWpnjP81KaoigPPCwbNUFAZ4dhqifq13",
            "symbol": "SS",
            "name": "Souls",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sou1ELxm3XpLWpnjP81KaoigPPCwbNUFAZ4dhqifq13/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.hornsvshalosnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CCWhF5ST5AH6VZ9qQKbMvKZD9DrX2SkhTNG6Nr67m5jA",
            "symbol": "WAKT",
            "name": "Whack Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Faceless87/whacktoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FZTfdpD9DzgqMDjgNrmShDsyd5MYkwQ4k1jLq1ecrmnd",
            "symbol": "RZC",
            "name": "Razzlecoin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZTfdpD9DzgqMDjgNrmShDsyd5MYkwQ4k1jLq1ecrmnd/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.razzlecoin.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "9mXZ54YnJJRmUN2MaMEtWCfFyoncP4ZhKz7U9DZ4JY2X",
            "symbol": "SPACEGOLD",
            "name": "SPACEGOLD",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/cosmic-condos/SPACEGOLD/main/logo-icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CosmicCondos",
                "website": "https://cosmiccondos.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Gic1MQRVSqpY4pT8to55CtMammzpthQwDgij7wZEvyo2",
            "symbol": "ITCOIN",
            "name": "IT SOL COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gic1MQRVSqpY4pT8to55CtMammzpthQwDgij7wZEvyo2/Coinlogo.png",
            "tags": [
                "software-developer",
                "coin"
            ],
            "extensions": {
                "website": "https://www.blocksto.ne/it-sol"
            }
        },
        {
            "chainId": 101,
            "address": "33EWTxBQdQdLnubsKyZ1Gyf97F7SSYBdi65YkUehgm58",
            "symbol": "IDEA",
            "name": "IDEA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin9955/crypto/main/logo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "website": "https://ideacoin.info/"
            }
        },
        {
            "chainId": 101,
            "address": "pbVqUjNWhL6YNt8gtMJCD5Ntevnh4Wy59CEp3jZAU69",
            "symbol": "OoD",
            "name": "Orb of Divinity",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pbVqUjNWhL6YNt8gtMJCD5Ntevnh4Wy59CEp3jZAU69/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/catalysts",
                "twitter": "https://twitter.com/CatalystsNFT",
                "website": "https://catalysts.world/"
            }
        },
        {
            "chainId": 101,
            "address": "Grti5hpNF5kQt7kV2YvEaWVJ2CwPy2KapXUXvZzbCTgJ",
            "symbol": "OoP",
            "name": "Orb of Protection",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Grti5hpNF5kQt7kV2YvEaWVJ2CwPy2KapXUXvZzbCTgJ/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/catalysts",
                "twitter": "https://twitter.com/CatalystsNFT",
                "website": "https://catalysts.world/"
            }
        },
        {
            "chainId": 101,
            "address": "EXi2m6TtDpFU63penR2d4DGtirw58GPB3Zkj9Bco2jqM",
            "symbol": "OoC",
            "name": "Orb of Chaos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EXi2m6TtDpFU63penR2d4DGtirw58GPB3Zkj9Bco2jqM/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/catalysts",
                "twitter": "https://twitter.com/CatalystsNFT",
                "website": "https://catalysts.world/"
            }
        },
        {
            "chainId": 101,
            "address": "Eth5Tw3ZveSv8t8scEJ9VGx99wVJTkQCrAgDAjKY6pNr",
            "symbol": "OoW",
            "name": "Orb of Wither",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Eth5Tw3ZveSv8t8scEJ9VGx99wVJTkQCrAgDAjKY6pNr/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/catalysts",
                "twitter": "https://twitter.com/CatalystsNFT",
                "website": "https://catalysts.world/"
            }
        },
        {
            "chainId": 101,
            "address": "7foFetNr9ktWZp95hppg19v6wMSJhoWAjCdceLk89EBy",
            "symbol": "OoA",
            "name": "Orb of Ascension",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7foFetNr9ktWZp95hppg19v6wMSJhoWAjCdceLk89EBy/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/catalysts",
                "twitter": "https://twitter.com/CatalystsNFT",
                "website": "https://catalysts.world/"
            }
        },
        {
            "chainId": 101,
            "address": "8uhGf3XCJkBQoRxBycsEFWao3TPPajcTEbdXeJk1mFS7",
            "symbol": "RIL",
            "name": "Rilum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/abbas-bachari/logo/main/src/RIL.png",
            "tags": [
                "wallet",
                "payment",
                "Token"
            ],
            "extensions": {
                "description": "Rilum is SPL telegram wallet for buy and sell products and currencies.",
                "telegram": "https://t.me/Rilum"
            }
        },
        {
            "chainId": 101,
            "address": "691FMUwMY8paaEJjow4BNBnKe3rdS3KH1bnu6hsFudZG",
            "symbol": "LEIB",
            "name": "LeibCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/leibmunz/leibt/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "web3-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F6rgHNX17nF5KMC4AWw7Nj9CdBcQZtUz3deunyyRC962",
            "symbol": "METAP",
            "name": "Meta Parlor Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MetaParlor/MetaParlorToken/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4TEHiDjsFsW6SvqafW2jhsSDuPbU4ESXT1GujtodUrDc",
            "symbol": "DAN",
            "name": "Dan Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/joshtasman/cSolana/main/dan_logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Daw6ysXkpYQ3v6ZgwbDwukdsRsZD4HEmQwafUPbApA2",
            "symbol": "NMC",
            "name": "New Media Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Scrump/newmediacoin/main/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://newmediacoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "A3T8bCAVd91xgzuU56Zz9hUEhVriPeVcgDyjrnzaiV1H",
            "symbol": "SCHL",
            "name": "Schulmoney",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FeliksIsABoss/Cryptocurrency/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "45eBLJUCQf1acXdBG8daBfUudy8T7V5gDTBpiE1iezsN",
            "symbol": "SOLP",
            "name": "SOL Playground Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/45eBLJUCQf1acXdBG8daBfUudy8T7V5gDTBpiE1iezsN/logo.png",
            "tags": [
                "utility-token",
                "blockchain-developer",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solplayground",
                "website": "https://solana-playground.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ErVUYVyKw41FKh6fjYZSVzjCKZonBfz21qsLtxz3pUba",
            "symbol": "CMPR",
            "name": "Camper",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/cmpr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5K1mKvSdpGtAeuz2bbgCzRD77qcwsrNKANjfwbfQUCwM",
            "symbol": "LAW",
            "name": "Law",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/law-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ETRs7r7yuBAnoJgbf68YnX9gnWVAvpRpipxD5EwkU8Xn",
            "symbol": "ZET",
            "name": "Zettle",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/zet-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6vXX83QA2vSR1LMVbBKCoezqQyW8yma25WFujQwhc1gn",
            "symbol": "BAL",
            "name": "Balance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/bal-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HHjz2EB6XZCNwPtncutxKgJdzwnTB5ejDhmYuXoFVx97",
            "symbol": "BUTT",
            "name": "Butt",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/butt-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4YvQifoQTgACgogadp6qy4YYUUFt9HF1EX4a8oZdiLJu",
            "symbol": "iTECH",
            "name": "IT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/itech-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5oSS1JcvUgYgFsrEhWQkN4hxJ6oUJXmSiKDK7n66aUKn",
            "symbol": "S0S",
            "name": "Sam",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/s0s-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31jGurR1grhqPw5wphpqf6SNQMJCAPpEz2pvSdMnH1UX",
            "symbol": "DMND",
            "name": "Diamond",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/dmnd-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Q8RRoJ8bWAPuTH4qjJ8An6qYX3Yigzod5WUWMWTdUXp",
            "symbol": "OST",
            "name": "Ostrich",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ost-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FJAbrPsfZuap6txnJpvpVLMtfDqec51JxMELagyQaxDX",
            "symbol": "SASS",
            "name": "Sass",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/sass-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JJSRBDYPtreV1mBfBgsmSpsVbXit1hQSBpspNGSQENw",
            "symbol": "LOR",
            "name": "Lore",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/lor-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9TAcqfx1NZ2y5VJDg2q8sGYMrKa5J626DTghhG6i1pjW",
            "symbol": "WDY",
            "name": "Woody",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/wdy-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HxtAGwgWb7AGEnJPLQkygsQkcnvfMgAUL8XvN8encgwf",
            "symbol": "DAISY",
            "name": "Daisy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/daisy-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sL6CBFRUD2xHUbzvsYCvrVguJrWpV8XFvKP7hso3C1b",
            "symbol": "RAYS",
            "name": "Sunshine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/rays-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ah1yv8NEKjDirynjwopjLxZh7KhhW59Z6tqsFAjj6GyZ",
            "symbol": "PLY",
            "name": "Pliability",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ply-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AUWiGXvZyrgB9KB5DLTNeyu3wENJU99basenhKZeaPZn",
            "symbol": "SHINE",
            "name": "Moonshine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/shine-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5cAcAGQVxJmJCKAHTnDTCwXPsGbiDf8St9WwVQTLiSBu",
            "symbol": "UNCRN",
            "name": "Unicorn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/goingfortheether/crypto/main/logo.png",
            "tags": [
                "social-token",
                "whitelist-token",
                "NFT",
                "community-token",
                "game"
            ]
        },
        {
            "chainId": 102,
            "address": "7KFASybd48bRqcBEUXoAWHwwbwJqYoyLMSjZwMkUigiR",
            "symbol": "APEs",
            "name": "APEs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7KFASybd48bRqcBEUXoAWHwwbwJqYoyLMSjZwMkUigiR/logo.png"
        },
        {
            "chainId": 101,
            "address": "4hFExpecK2WyGGxpEVSzwYw7SUmrCSgyoAmFg88cyzVZ",
            "symbol": "PRM",
            "name": "Prime",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/maava89/crypto/main/prime.png",
            "tags": [
                "social-token",
                "web3-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV",
            "symbol": "NESTA",
            "name": "Nest Arcade",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV/logo.png",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "website": "https://nestarcade.io"
            }
        },
        {
            "chainId": 101,
            "address": "GF1Y8ZqtJ37ek6YzjmU3p14SX4qCHK7B7ozXgiLcmbS4",
            "symbol": "GAMO",
            "name": "GAMO",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GF1Y8ZqtJ37ek6YzjmU3p14SX4qCHK7B7ozXgiLcmbS4/logo.png",
            "tags": [
                "MANAGEMENT"
            ]
        },
        {
            "chainId": 101,
            "address": "Ca4CppSk1dSyneHw6Kn1x588yny4DeXGe7mAVZ7eyyuG",
            "symbol": "TICKET",
            "name": "Crypneyland Tickets",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ca4CppSk1dSyneHw6Kn1x588yny4DeXGe7mAVZ7eyyuG/logo.png",
            "tags": [
                "Currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/crypneyland"
            }
        },
        {
            "chainId": 101,
            "address": "C8pcsGXL21Hq8XCPMA2RAwfLAMn2UVKX1FqNeFgwgSLK",
            "symbol": "TGIF",
            "name": "TGIF",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/skmotochan/crypto/main/tgif-icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Hke4tmbBsXSpj269VkwMXo8sTr6oFhCUsiJepUxatHz",
            "symbol": "SHIBBURN",
            "name": "ShibaBurn",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tradermq/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "game"
            ]
        },
        {
            "chainId": 101,
            "address": "HKCHNuB9t18zKgjSvDdfADbXyxCFyHU1Gac74WnRdAPt",
            "symbol": "ITSNM",
            "name": "No Money",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nomoneyface/nomoney/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "49PGdswRzWT3yUP13r6oysMv1B1WeQ4w87H4M8zxWrTN",
            "symbol": "LNA",
            "name": "Lania",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lania22/crypto/main/logo.png",
            "tags": [
                "Defi",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "LbtGm5toYeEk1U5K63UvNQKKhPJoVW11w6BHzwckqZr",
            "symbol": "XCPT",
            "name": "Acceptance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/xcpt-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5oCf3mGGykAoMKf9CK7NvD1St2obf5Cnr5CLEpT33JxG",
            "symbol": "Y",
            "name": "Yes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/y-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7yQ3eujpoHEZEcLE4YRHA3En4nMK3k2bPUWiXXxRdh9e",
            "symbol": "N",
            "name": "No",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/n-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "QN2HkkBaWHfYSU5bybyups9z1UHu8Eu7QeeyMbjD2JA",
            "symbol": "pBTC",
            "name": "Port Finance BTC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/QN2HkkBaWHfYSU5bybyups9z1UHu8Eu7QeeyMbjD2JA/BTC.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "6UgGnLA3Lfe8NBLAESctsUXWdP3zjMFzSLEZxS3tiaKh",
            "symbol": "pMER",
            "name": "Port Finance Mercurial",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6UgGnLA3Lfe8NBLAESctsUXWdP3zjMFzSLEZxS3tiaKh/MER.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "FhraFicS7fGxHn8jfzuZ6TeTpCu8PAnQNZiT2tqM5xvv",
            "symbol": "pSBR",
            "name": "Port Finance Saber",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FhraFicS7fGxHn8jfzuZ6TeTpCu8PAnQNZiT2tqM5xvv/SBR.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "77TBgKmTNtMdGrt1ewNRb56F2Xw6fNLZZj33JZ3oGwXh",
            "symbol": "pSRM",
            "name": "Port Finance Serum",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/77TBgKmTNtMdGrt1ewNRb56F2Xw6fNLZZj33JZ3oGwXh/SRM.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "GLLZY8xRU1dvV117DwEki2hVdfzQwB1D3xeBcQ7JEvCf",
            "symbol": "DOO",
            "name": "Twinkycoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Deliriant3/token/main/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75tQsrumoU3xb98J1wZgPJXK3ZsrDxomaK4WEzrR5kEr",
            "symbol": "CSYD",
            "name": "CodeSydney",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/codesydney/assets/main/logo/csyd/hexagon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EzpSBrAdTU1AYdY6UWghFJRiq2WF39CUy3Tncrh82XiB",
            "symbol": "FAKE",
            "name": "Fake Crypto Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faketrillionaire/fakecryptocoin/main/fake_crypto_coin.png"
        },
        {
            "chainId": 101,
            "address": "85dqKwPUVgEgH1LRTCDYc4Pym2hiQFHU7vfQRJQx6pAg",
            "symbol": "HCHC",
            "name": "Highland Cow Herd Coin",
            "decimals": 0,
            "logoURI": "https://github.com/Frew250/Crypto/blob/main/HCH.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AoTKABCgrfjMXDo7Rtbcgcphi3XCJRjJ74Vpyc3ENMor",
            "symbol": "TUSD",
            "name": "TOP USD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AoTKABCgrfjMXDo7Rtbcgcphi3XCJRjJ74Vpyc3ENMor/logo.png",
            "tags": [
                "stablecoin",
                "payment"
            ],
            "extensions": {
                "description": "Digital coin for making payments on https://topnfts.cc/"
            }
        },
        {
            "chainId": 101,
            "address": "BCMqKuey7zLuxfErEo1at1ui4BjC69752MCy2WU1mhmo",
            "symbol": "SHEEP",
            "name": "SheepCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Andreas711/crypto/main/sheeptoken.png",
            "tags": [
                "social-token",
                "meme-token",
                "private-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6BnVgighj8VKqCS2ymUmcvLUguHrPHjZfDZ9oZqqFp7j",
            "symbol": "Momobear",
            "name": "Momobear Taro Pot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wei1769/nft_storage/main/art/momoyaTaroPot.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/momobeardao",
                "twitter": "https://twitter.com/momobearya"
            }
        },
        {
            "chainId": 101,
            "address": "G49gd8dLmqedK3DNdgUkd2chVMZieRgqZVMnnrsnsF5V",
            "symbol": "P-MERC",
            "name": "Pre-Sale Mercanti NFT 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G49gd8dLmqedK3DNdgUkd2chVMZieRgqZVMnnrsnsF5V/logo.png",
            "tags": [
                "whitelist-token",
                "mercanti",
                "pre-sale"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tradewithmarco"
            }
        },
        {
            "chainId": 101,
            "address": "JBveYSPQ5sGSPBMi8UdSYcFH25pCEQiU6PymVFqkzm3b",
            "symbol": "P-MERC",
            "name": "Pre-Sale Mercanti NFT 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JBveYSPQ5sGSPBMi8UdSYcFH25pCEQiU6PymVFqkzm3b/logo.png",
            "tags": [
                "whitelist-token",
                "mercanti",
                "pre-sale"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tradewithmarco"
            }
        },
        {
            "chainId": 101,
            "address": "CdQseFmnPh2JBiz5747dJ6oYXK9NKnbdFRfiXTcZuaXT",
            "symbol": "$PACES",
            "name": "PACES Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CdQseFmnPh2JBiz5747dJ6oYXK9NKnbdFRfiXTcZuaXT/logo.png",
            "extensions": {
                "discord": "https://discord.gg/5gehRmCNem",
                "twitter": "https://twitter.com/TheAcesNFT",
                "website": "https://theacesnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5kdUFnjEERxhCzTDQrmxoh5nSkp4xkDSJaUGquNkar5a",
            "symbol": "LILIT",
            "name": "Lilith Project",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5kdUFnjEERxhCzTDQrmxoh5nSkp4xkDSJaUGquNkar5a/logo.png",
            "tags": [
                "meme"
            ]
        },
        {
            "chainId": 101,
            "address": "AvxN7UPdmJpqB9guCGtrC77svYzmupouL3d7v6J4xEPw",
            "symbol": "OYT",
            "name": "OYT Apparels",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rulhaq/OYT/main/logo.png",
            "tags": [
                "social-token",
                "apparel-token",
                "ecommerce-token",
                "fashion-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GWYzdeiAr1MhAckNn2Sn8D3XhUHSCMcmf3YoAGZAM7C7",
            "symbol": "MILL",
            "name": "Millitoken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWYzdeiAr1MhAckNn2Sn8D3XhUHSCMcmf3YoAGZAM7C7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/aW7MRd5zYA",
                "twitter": "https://twitter.com/MillennialsNFT",
                "website": "https://themillennials.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D56dMWuvAEGq4WmbWxNKBJREkXYVfER98Tb5RNCmF84o",
            "symbol": "DOGE",
            "name": "Dogecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/unimintco/token-logos/main/DOGE/logo.png",
            "tags": [
                "meme-token",
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "dogecoin",
                "description": "Dogecoin is an open source peer-to-peer digital currency, favored by Shiba Inus worldwide.",
                "discord": "https://discord.com/invite/dogecoin",
                "facebook": "https://facebook.com/OfficialDogecoin",
                "github": "https://github.com/dogecoin/dogecoin",
                "reddit": "https://reddit.com/r/dogecoin",
                "twitter": "https://twitter.com/dogecoin",
                "website": "https://dogecoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3b2G8Fe8vawLMfRHvKh1cxtxuRWQyeRknr7xVzPVTcYn",
            "symbol": "MIND",
            "name": "MindMining",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3b2G8Fe8vawLMfRHvKh1cxtxuRWQyeRknr7xVzPVTcYn/logo.svg",
            "extensions": {
                "website": "https://mindmining.org/"
            }
        },
        {
            "chainId": 101,
            "address": "2DH1PxfM8d8bkrQM3WFX1HySohcv1rvMLU9KmycmzLp1",
            "symbol": "GRILLAZ",
            "name": "GRILLAZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DH1PxfM8d8bkrQM3WFX1HySohcv1rvMLU9KmycmzLp1/logo.png",
            "extensions": {
                "website": "https://mekagorillaz.live/"
            }
        },
        {
            "chainId": 101,
            "address": "549BXihQSZGrog7mJpBLjaBcetUoovh72wVmHJf5icmV",
            "symbol": "DORA",
            "name": "Doragon Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/pixelateddoragon/brand/main/token.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PixelDoragon",
                "website": "https://pixelateddoragon.com"
            }
        },
        {
            "chainId": 101,
            "address": "AQGncY1hA22JATnj8aEuCAaPQsoe9wwmn4QBKzvV96yS",
            "symbol": "BACK",
            "name": "Backistan BACK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/backdao/backdao/f64c256ffb811018d88e18c804a60a6127f991d3/SPL-image.png",
            "tags": [
                "social-token",
                "community-token",
                "nft-backed-currency",
                "nft-basket"
            ],
            "extensions": {
                "twitter": "https://twitter.com/backDAO",
                "vault": "https://www.fraktion.art/vault/DLRCcdmBJvoR6nHTpKRKvRBE9n8TN1443rN3VrtiXzqp",
                "vaultPubkey": "DLRCcdmBJvoR6nHTpKRKvRBE9n8TN1443rN3VrtiXzqp",
                "website": "https://back2back.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HtKKipqmgxMQJUob9XCgZBG6zSCZx4nA433ZfDuLyUTg",
            "symbol": "ALGO",
            "name": "Algorand",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/unimintco/token-logos/main/ALGO/logo.png",
            "tags": [
                "utility-token",
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "algorand",
                "description": "Algorand is a scalable, secure and decentralized digital currency and transactions platform.",
                "discord": "https://discord.com/invite/QBYp7Y2",
                "github": "https://github.com/algorand",
                "reddit": "https://reddit.com/r/AlgorandOfficial",
                "telegram": "https://t.me/algorand",
                "twitter": "https://twitter.com/algorand",
                "website": "https://www.algorand.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5v2kfUPs51kMbHh57E81rPMRvFD5KGPU83Ft7dvDtwvP",
            "symbol": "KN",
            "name": "Kuna",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/donDominik0/cryptokuna/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3sMKt96ayAftnsGrjsymTzxanHPWGZiGgdfcZQ7Pfe8K",
            "symbol": "P-MERC",
            "name": "Mercanti Pre-Sale NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3sMKt96ayAftnsGrjsymTzxanHPWGZiGgdfcZQ7Pfe8K/logo.png",
            "tags": [
                "whitelist-token",
                "mercanti",
                "pre-sale"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tradewithmarco"
            }
        },
        {
            "chainId": 101,
            "address": "AH1KE4h9nnKfqYRDynDsB3nFwT8ohXvw9rX81uaxrhAg",
            "symbol": "MERC",
            "name": "Mercanti NFT Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AH1KE4h9nnKfqYRDynDsB3nFwT8ohXvw9rX81uaxrhAg/logo.png",
            "tags": [
                "whitelist-token",
                "mercanti",
                "sale"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tradewithmarco"
            }
        },
        {
            "chainId": 101,
            "address": "9p8d6WABqK9dDTS2rCcyAbGjZbS4AtXCuqpdMMfcGXat",
            "symbol": "BRLS",
            "name": "Brails Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brailsford/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhDt1FnEt759Tmxwa61E9FQhFnerPwMDTTt7CJjvog7L",
            "symbol": "EC",
            "name": "EliteCoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AhDt1FnEt759Tmxwa61E9FQhFnerPwMDTTt7CJjvog7L/logo.png",
            "extensions": {
                "discord": "https://discord.gg/MetaApeEC",
                "instagram": "https://instagram.com/MetaApeEC",
                "twitch": "https://www.twitch.tv/MetaApeEC",
                "twitter": "https://twitter.com/MetaApeEC",
                "website": "https://metaapeeliteclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GssKDr54mrME81AUnmSBKBc3Bcb4F3M8ugsYVQDE2r9F",
            "symbol": "POGR",
            "name": "PoggersCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ConfusedCatBongo/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HUGZgz5stMv2SuSDCV6WifPea1MsuBWNd4nVfea71oiT",
            "symbol": "CBG",
            "name": "BeneCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EigeneDateien/CBG/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DFzykeGoEa359d7RS99bFwG95i6ojKcEMibqNtF7JC2c",
            "symbol": "EINC",
            "name": "EinCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/einmanjr/Crypto/main/DCBB9804-C1D5-4903-8461-8CE1CA66A187_1_201_a.jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fxkabr3Qjc7HnraxgvtHxf7HCrrzqLzvhFUJuonFcoW3",
            "symbol": "ETL",
            "name": "eTolar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/admin9955/eTolar/main/logo.png",
            "extensions": {
                "website": "https://etolar.org/"
            }
        },
        {
            "chainId": 101,
            "address": "GVuwAompUahjSWLKS5NZDu1yPnRRsJV5mZa3wvgzzmuH",
            "symbol": "SMW73",
            "name": "Local 73 COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVuwAompUahjSWLKS5NZDu1yPnRRsJV5mZa3wvgzzmuH/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3DEbcjbcCXwzhx75FHTWs3TiyLLXcwhvnbyAxciDojRY",
            "symbol": "BAAG",
            "name": "Banded Agate Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rightywastaken/logo/main/logo5.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLG8Q4Woh6Usb4AVtC2vi7AMU2GVtcCrjp3SEpskUDz",
            "symbol": "SxDWL",
            "name": "SXD Whitelist token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SaintxDmon/WL-sxd/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8sxavS8ky125Axf7h1Zi2nEb2MvVZc6WY8pjPnY9aoKR",
            "symbol": "SEC",
            "name": "SECCO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/SECCO-COIN/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "secco-token",
                "art-token"
            ]
        },
        {
            "chainId": 102,
            "address": "3VkCNsok1V8Y65utG7LchxURHh7nAhFR7ScVyTLLG1jJ",
            "symbol": "HGEN",
            "name": "HGEN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VkCNsok1V8Y65utG7LchxURHh7nAhFR7ScVyTLLG1jJ/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "governance-token"
            ]
        },
        {
            "chainId": 102,
            "address": "7d3U17g4WEZkVGjRVVQchrgEaoFAuuui2xmEGCzmtUGt",
            "symbol": "GENS",
            "name": "GENS",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7d3U17g4WEZkVGjRVVQchrgEaoFAuuui2xmEGCzmtUGt/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "4xU44oSF32sFXzTG2PRNhsxJidLegyGTGV8Fmu5grLFy",
            "symbol": "BRIDGE",
            "name": "Bridge Network",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/LhQSzyh/unknown-3.png",
            "tags": [
                "Bridge",
                "Cross-Chain",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bridgenetwork0x",
                "website": "https://www.bridgenetwork.com"
            }
        },
        {
            "chainId": 101,
            "address": "8fF8hajCaotRDZ39HRJhQCAUA6aFMabvz6eV8518pDkB",
            "symbol": "GREMM",
            "name": "GremlinCoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Bucksmatt/Gremlin-coin/main/Gremlin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "14AnHZYk1CvtTCq5jvYMX7Fx7pnWDmgQJvADxP9Q4jYN",
            "symbol": "KZOO",
            "name": "Kawaii Zoo",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/97711508/152678665-9e923dbd-8190-40a8-a910-634881fe3530.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6SF9wrmXxB",
                "twitter": "https://twitter.com/Kawaii__Zoo",
                "website": "https://kawaii-zoo.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GuJWiZBox3tZZwiV9NCJsRUaULv8tmNcK9UWNV25msND",
            "symbol": "FROGZ",
            "name": "FROGZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GuJWiZBox3tZZwiV9NCJsRUaULv8tmNcK9UWNV25msND/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HokkaidoFrogz",
                "website": "https://www.hokkaidofrogz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Dc5LzwzYLtSMhquxvXSAuhiDqjjbPLrBF1ZajNQyFuXM",
            "symbol": "WTLBC",
            "name": "TLBC Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dc5LzwzYLtSMhquxvXSAuhiDqjjbPLrBF1ZajNQyFuXM/logo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/theloftbusinessclub",
                "instagram": "https://www.instagram.com/the_lofts_business_club/",
                "twitter": "https://twitter.com/lofts_club",
                "website": "https://loftsclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8TeyhNzVnJkYMQg6byGGwuszV2C3miNashj8HNUzgRU7",
            "symbol": "GMGWH",
            "name": "gm Groundhog Whitelist",
            "decimals": 0,
            "logoURI": "https://github.com/imentus-rahul/whitelist-token-registry/blob/main/logo.png",
            "tags": [
                "whitelist-token",
                "presale-token"
            ],
            "extensions": {
                "description": "gmgwh is a whitelist token for preminting the gmg nft collection"
            }
        },
        {
            "chainId": 101,
            "address": "KuTHacd5KQF9F7akJY3LSUhuACdPhfRLyCm8AWTkYrE",
            "symbol": "KWT",
            "name": "Kuroda Whitelist Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tessareskuroda/kurodatoken/master/logo.png",
            "tags": [
                "kuroda-tokens",
                "whitelist"
            ],
            "extensions": {
                "description": "KWT is a whitelist token for preminting the Kuroda genisys collection",
                "website": "https://kurodaclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7hsXTesZFNdjkD4v78n18GqLKx3gZzxA1G8n8DH186FH",
            "symbol": "AMORE",
            "name": "Amore",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin9955/amore/main/logo.png",
            "extensions": {
                "website": "https://amore.bar/"
            }
        },
        {
            "chainId": 101,
            "address": "SPCxcJbCHdsPNd9PMpMYoXwNN2Dvj4GHMAk23bdCgC7",
            "symbol": "SPC",
            "name": "Sea Peach",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPCxcJbCHdsPNd9PMpMYoXwNN2Dvj4GHMAk23bdCgC7/logo.png",
            "extensions": {
                "website": "https://seapeach.pro"
            }
        },
        {
            "chainId": 101,
            "address": "7kBQ5dnNg1StV58LzPF9B1BXWioSEPmEnuAPEHJ8qu5G",
            "symbol": "MATIC",
            "name": "Polygon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kBQ5dnNg1StV58LzPF9B1BXWioSEPmEnuAPEHJ8qu5G/logo.png",
            "tags": [
                "utility-token",
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "polygon",
                "description": "Polygon believes in Web3 for all. Polygon is a decentralised Ethereum scaling platform that enables developers to build scalable user-friendly dApps with low transaction fees without ever sacrificing on security.",
                "discord": "https://discord.com/invite/polygon",
                "facebook": "https://facebook.com/0xPolygon.Technology",
                "github": "https://github.com/maticnetwork",
                "instagram": "https://instagram.com/0xpolygon",
                "linkedin": "https://linkedin.com/company/0xpolygon",
                "reddit": "https://reddit.com/r/0xPolygon",
                "telegram": "https://t.me/maticnetwork",
                "twitter": "https://twitter.com/0xPolygon",
                "website": "https://polygon.technology/",
                "youtube": "https://youtube.com/c/PolygonTV"
            }
        },
        {
            "chainId": 101,
            "address": "9DSDuJxgQouaYBLComUfGTGyaGLjVKktTYuojUs7o3r3",
            "symbol": "VOID",
            "name": "THE VOID GENESIS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9DSDuJxgQouaYBLComUfGTGyaGLjVKktTYuojUs7o3r3/genesis.jpg",
            "tags": [
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "NFT"
            ],
            "extensions": {
                "description": "This one is for the Fans! Holding the Black Orb with the Golden V comes with amazing Benefits: future Airdrops, access to NFT Pre-Sales, exclusive in-game Characters and many more.",
                "discord": "https://discord.gg/rzfe6k39aU",
                "facebook": "https://facebook.com/monkeyballgames",
                "instagram": "https://www.instagram.com/void_dot_games",
                "medium": "https://void-dot-games.medium.com",
                "telegram": "https://t.me/voidcommunitychat",
                "twitter": "https://twitter.com/void_dot_games",
                "website": "https://www.void.games"
            }
        },
        {
            "chainId": 101,
            "address": "Be8zNPTmyMnVqj2z7ZNSL1oYjWw6fLC5YGhmipqzbBpC",
            "symbol": "ZFE",
            "name": "ZeroFees Exchange",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin8808/zfe/main/logo.png",
            "extensions": {
                "website": "https://zerofees.exchange/"
            }
        },
        {
            "chainId": 101,
            "address": "A3g1zcXKxE3sZP9chg79zWKc62pRbtpJcTtosujBHgVN",
            "symbol": "NFLX",
            "name": "Netflix Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Netflix-Coin/030295bdec4cf208294a54e155c06fac2cc964db/logo.svg",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "netflix-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/netflix"
            }
        },
        {
            "chainId": 101,
            "address": "CmYzaiBpAxLFkXAfePgUJ4D99hvtZKpMvbbftYotjWgk",
            "symbol": "NYAO",
            "name": "NYAO COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kln037/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Azw7nHFCUrY3i2RpRLxJja1mooiZkAai3ipsmQTeMqNQ",
            "symbol": "JUNK",
            "name": "Zankoku JUNK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/XAGBack/junktoken/main/Junk.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CDYJ5se32rbcLCo4nSS7EsMJ7FJyn7xHyz7LP9mynyHC",
            "symbol": "HFWL",
            "name": "HAWTFOX WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/UMGD/images/main/firefoxxx.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HqXHEpvDAM9WntedjzxSx5MUpDRtxGyTZXGTUohSgLtb",
            "symbol": "BUG",
            "name": "BugCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BugzumDevAlt/CoinLogo/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "Crypto-Token",
                "crypto"
            ],
            "extensions": {
                "youtube": "https://www.youtube.com/channel/UCi5K-ZcuNbBW6svtT1gvlwA/"
            }
        },
        {
            "chainId": 101,
            "address": "9ph2ZAwzigVRrrXvHoGxMU7YuW59zMDtNX6WJjh927tF",
            "symbol": "CUSELP",
            "name": "CuseTheJuice Liquidity Pool",
            "decimals": 9,
            "logoURI": "https://github.com/CuseTheJuice/token-list/blob/main/assets/mainnet/9ph2ZAwzigVRrrXvHoGxMU7YuW59zMDtNX6WJjh927tF/logo.png?raw=true",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xHpRcRKG",
                "twitter": "https://twitter.com/CuseTheJuice",
                "website": "https://linktr.ee/CuseTheJuice"
            }
        },
        {
            "chainId": 101,
            "address": "8t3fB5pmvgYpYnxqvVSbJCf5rEtgNNcsGs3exgWqdvoT",
            "symbol": "SOLBASE",
            "name": "SolBase",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8t3fB5pmvgYpYnxqvVSbJCf5rEtgNNcsGs3exgWqdvoT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Easily build apps on Solana.",
                "website": "https://solbase.io"
            }
        },
        {
            "chainId": 101,
            "address": "WLiHGZSFg3E17ZBymfQG3NzhUjgQPKeLzTdYW3PhchQ",
            "symbol": "WLPRT",
            "name": "WLPrototype",
            "decimals": 0,
            "logoURI": "https://bafybeie4ijaerva5fjqa4ojdy356jzf4xhhggybpwxf2ekn5tyg7xiedzu.ipfs.infura-ipfs.io/",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Whitelist token for PrototypeNFT.",
                "discord": "https://discord.com/invite/prototypenft",
                "twitter": "https://twitter.com/prototype5656",
                "website": "https://prototype-nft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GLrfLkhCBv7w3LPW8YZJxXcNnXnzic8nQ7nTefPRaSMR",
            "symbol": "GLPRT",
            "name": "GLPrototype",
            "decimals": 0,
            "logoURI": "https://bafybeieax25iipx7k67ul3l5eeuzoisx5gohsxddrltuftuogktnrpbzgy.ipfs.infura-ipfs.io/",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Goldlist token for PrototypeNFT.",
                "discord": "https://discord.com/invite/prototypenft",
                "twitter": "https://twitter.com/prototype5656",
                "website": "https://prototype-nft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8ZWRyq1gQMJjt1kgmRRT7zNKCsLz8HCuGmt13jf7iUPo",
            "symbol": "DRAP",
            "name": "Drap Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Drap-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "drap-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HjPHNMugJsC9tQHp2zMhQ7EB6YjsH4DMsR81hM1PjmmM",
            "symbol": "BCFT",
            "name": "BoreasCF Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/besnikbelegu/crypto/main/logo.png",
            "tags": [
                "social-token",
                "fitness-token",
                "family-token"
            ],
            "extensions": {
                "description": "BoreasCF Token is a token that is given to our friends when they do something good.",
                "discord": "https://discord.gg/PyGry28k5Z",
                "instagram": "https://www.instagram.com/besnikbelegu",
                "linkedin": "https://www.linkedin.com/in/besnikbelegu"
            }
        },
        {
            "chainId": 101,
            "address": "GYwYv1NcPuQozaqedrfivksbPfGEpuhwiWJg33rChQrq",
            "symbol": "GLD",
            "name": "Global Dollar",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin8808/GLD/main/logo.png",
            "extensions": {
                "website": "https://globaldollar.world/"
            }
        },
        {
            "chainId": 101,
            "address": "NYNKnLDu1WRacDTmqvMburqc31reUWQSSmoh4MxE3FB",
            "symbol": "NYN",
            "name": "NYN Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NYNKnLDu1WRacDTmqvMburqc31reUWQSSmoh4MxE3FB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coinmarketcap": "https://coinmarketcap.com/currencies/nyan-heroes/",
                "discord": "https://discord.gg/nyanheroes",
                "medium": "https://nyanheroes.medium.com/",
                "telegram": "https://t.me/nyanheroes",
                "twitter": "https://twitter.com/nyanheroes",
                "website": "https://nyanheroes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "42869t2uZcntyvNhJyVU7X83AKxcWqeikvGsxsJwG3NF",
            "symbol": "HMN",
            "name": "Hasif Malaysia Node Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hasifMahazir/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZAzphjkZwSYnPgdaiaUqYpZajhkz9hC5aYkRyzEVc4G",
            "symbol": "DPPD",
            "name": "Duck Patrol WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZAzphjkZwSYnPgdaiaUqYpZajhkz9hC5aYkRyzEVc4G/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DuckPatrolNFT"
            }
        },
        {
            "chainId": 101,
            "address": "Ab28EPE28GFubPM47DN5xxp8Edm5fpeN24FkdWnRGND9",
            "symbol": "VEGE",
            "name": "VeggieCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/transaide/vege/main/logo.png",
            "tags": [
                "utility-token",
                "governance-token",
                "game-token",
                "NFT"
            ],
            "extensions": {
                "description": "Use VeggieCoin to participate in the Vegetarians ecosystem; NFT's, Gaming, Governance and more! Earn VeggieCoin by owning a Vegetarian NFT. Eat your Veggies!",
                "discord": "https://discord.gg/QddPWdg4vg",
                "twitter": "https://twitter.com/VegetariansNFT"
            }
        },
        {
            "chainId": 101,
            "address": "GrhiwuCNR953krtxPu4F7YGqYhhfQH2QDfaE1ktNurrT",
            "symbol": "SKLT",
            "name": "Eskeleton",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GrhiwuCNR953krtxPu4F7YGqYhhfQH2QDfaE1ktNurrT/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/miguela_crypto"
            }
        },
        {
            "chainId": 101,
            "address": "G4nr5KV4Yd7JJi94GsRjPUukVGghaUJxgz4qVgXyLg7K",
            "symbol": "HAPE",
            "name": "Hungry Apes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G4nr5KV4Yd7JJi94GsRjPUukVGghaUJxgz4qVgXyLg7K/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Dh4GsSrJ",
                "twitter": "https://twitter.com/HungryApesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "H8M6nvzsKt6L7DLt2pGd7yXLLfJNaYvciS3XbvQecEu6",
            "symbol": "NEO",
            "name": "Urban Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/SecioreanuStefanita/crypto/main/UAC_logo.png",
            "tags": [
                "social-token",
                "community-token",
                "mobility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/neobiIity",
                "website": "https://www.rideurbanair.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4E1G6fPbbWqCwipnNNbNtiUfHn8yXBZESjtqU7ZQPacy",
            "symbol": "KOALAS",
            "name": "Saving Australian Koalas Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4E1G6fPbbWqCwipnNNbNtiUfHn8yXBZESjtqU7ZQPacy/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "wildlife",
                "koalas-token",
                "Crypto"
            ],
            "extensions": {
                "website": "https://www.savingaustraliankoalas.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EW5ZzkiUr1y8snnYmtd4RoK2twnseYXc9YtEVvfFYeVx",
            "symbol": "PAM",
            "name": "Pamela Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Pamela-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "pam-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/pamela_rf",
                "website": "https://pamelareif.com"
            }
        },
        {
            "chainId": 101,
            "address": "F5SjYkNBNF29iKsLf5r665n58qRsw4PjEwtVTLBZzGh",
            "symbol": "NUO",
            "name": "Nuobility",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F5SjYkNBNF29iKsLf5r665n58qRsw4PjEwtVTLBZzGh/logo.png"
        },
        {
            "chainId": 101,
            "address": "FronkXnfBgzfhsEV2bjwoJ5VgYhpEVCSN3pzpJkvZGUf",
            "symbol": "FRONK",
            "name": "Fronk",
            "decimals": 9,
            "logoURI": "https://arweave.net/g_pwKEk6RAc2ZQ_-DYIrvwchekBRHLvoxGKEDE4S6hc",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/friendlyfrogs",
                "twitter": "https://twitter.com/FriendlyFrogSC",
                "website": "https://ffsc.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2iDc5dHpVKq5WsNEdWFoaMiAjtKz7zrRKi8WHyRW7z8x",
            "symbol": "NOYZ",
            "name": "Noyz Narcos Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Noyz-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "noyz-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/noyzofficial",
                "website": "https://www.propagandaclothing.it"
            }
        },
        {
            "chainId": 101,
            "address": "CJze5X3G3V6nqqrfeALTpb1HbkKvspjiUGR12rVchL3T",
            "symbol": "SOC",
            "name": "Solcrystol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJze5X3G3V6nqqrfeALTpb1HbkKvspjiUGR12rVchL3T/SOC.png",
            "tags": [
                "social-token",
                "Metaverses",
                "community-token",
                "game"
            ]
        },
        {
            "chainId": 101,
            "address": "CwPp4q7C23oXNh12Z8ZGx1vTL7gnqT4iWtLjg7Ta2vBF",
            "symbol": "PPPWL",
            "name": "Peppy Platypus Posse Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CwPp4q7C23oXNh12Z8ZGx1vTL7gnqT4iWtLjg7Ta2vBF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://peppyplatypus.com/"
            }
        },
        {
            "chainId": 101,
            "address": "63cL4hHbkRStqMvhAudXkPsydRASp3yr7iPQemqmAcD2",
            "symbol": "ART",
            "name": "Aiko ART",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/63cL4hHbkRStqMvhAudXkPsydRASp3yr7iPQemqmAcD2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Y72V9wn3Yw",
                "reddit": "https://www.reddit.com/r/AikoNFT/",
                "twitter": "https://twitter.com/aikonft",
                "website": "https://aiko.io/"
            }
        },
        {
            "chainId": 103,
            "address": "AZ1mS4yHVYLTXb2Z8AQmec2o18i15REEYGWtnRcuWohu",
            "symbol": "ART",
            "name": "ART",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/63cL4hHbkRStqMvhAudXkPsydRASp3yr7iPQemqmAcD2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Y72V9wn3Yw",
                "reddit": "https://www.reddit.com/r/AikoNFT/",
                "twitter": "https://twitter.com/aikonft",
                "website": "https://aiko.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BS9HQKdgY6DUq6HCgNz8xpDyoERJD4GnwGRHebFasjZS",
            "symbol": "RRAM",
            "name": "RRAM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BS9HQKdgY6DUq6HCgNz8xpDyoERJD4GnwGRHebFasjZS/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/rowdyramsnft"
            }
        },
        {
            "chainId": 101,
            "address": "GQ9Ym6HrE3C5NvugEMLwBXTyWjXrwoHZD6Nvv5Laosvu",
            "symbol": "LAUR",
            "name": "Achille Lauro Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Achille-Lauro-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "lauro-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/frasidilauro"
            }
        },
        {
            "chainId": 101,
            "address": "51Cd5WF7cW6CWqkYn1WZHikqkjMGeyKRjsEW2ULrVvSa",
            "symbol": "GEM",
            "name": "Gemitaiz Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Gemitaiz-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "gemitaiz-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thetruegemitaiz",
                "website": "https://tantarobastore.com"
            }
        },
        {
            "chainId": 101,
            "address": "GXxff8vm2MB6FvA79tmGHs3VNUEwevvpdxwiaqRfaZkr",
            "symbol": "BEL",
            "name": "Belial",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DozaLabz/crypto/main/BelialCoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8c8J3bHqWRkJFhGZ9XLinYjgD69fRsxfQMinZtGbnGr5",
            "symbol": "DFSC",
            "name": "Dogfin Studios Coin",
            "decimals": 0,
            "logoURI": "https://github.com/guardianjon/crypto/blob/main/dogfinstudios-logo200x200.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CSf5iuscN8A5FKdMyq7FSai1DxzTUhMvKSitp5tzMUQD",
            "symbol": "HOTC",
            "name": "Hot Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Spidle/crypto/main/HOTLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8TjgDMv2Esb7YRKu1ESZv5vtgD1WqFKmzhPBgsLqwEGG",
            "symbol": "NARWHAL",
            "name": "Narwhal Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8TjgDMv2Esb7YRKu1ESZv5vtgD1WqFKmzhPBgsLqwEGG/logo.png",
            "tags": [
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "serumV3Usdc": "3qXkLj4vAT4B2dMJQgtKSrNxLfrSQawMZtAzKVEsMyYT",
                "twitter": "https://twitter.com/narwhalcoin",
                "website": "https://www.narwhalcoin.net"
            }
        },
        {
            "chainId": 101,
            "address": "8mTe216SBwdfdXLPxrNCo1fxXTt7NVTUF9PqCwv4Wuzz",
            "symbol": "BLVE",
            "name": "Blave Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/arkashka/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3echLX8Qyz4hB694BFsSNWj6ytdStVQKVKFiFCJ1McpQ",
            "symbol": "TOME",
            "name": "TOME",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/AlderToken/main/tome.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/qRcCATnZ",
                "twitter": "https://twitter.com/AlderMages",
                "website": "https://aldermages.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9HqmwYxGnZctqasFu4MoFmC2EpkgezNvrm3TPS25M6xM",
            "symbol": "ST8",
            "name": "StaticToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/staticgroup/statictoken/main/logo.png?token=GHSAT0AAAAAABRMVGH2HVN65YFKZUT4JHHOYQNVVBA",
            "tags": [
                "tech-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DXDfk4zV",
                "reddit": "https://www.reddit.com/r/statictoken",
                "twitter": "https://twitter.com/staticcomputers"
            }
        },
        {
            "chainId": 101,
            "address": "DQVQEAaE8zx2e2ea6sWvW3MAoxXv9R1t5yN85kGaU5Xk",
            "symbol": "DEMU",
            "name": "Decent Muzic Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/geccosol/demu/main/token.png",
            "tags": [
                "music-token",
                "listner-token",
                "utility-token",
                "demu-token"
            ],
            "extensions": {
                "description": "Decent Muzic Token is a token that is for both the listner and the musician.",
                "facebook": "https://www.facebook.com/decentmuzic",
                "website": "https://decentmuzic.com"
            }
        },
        {
            "chainId": 101,
            "address": "3NfqXM9i7NvxxRD23FJoF6sX1q8jRLRJU2b8BLsUrUDu",
            "symbol": "JRGC",
            "name": "JACKED",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ThomasClous/CryptoUpdate/main/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "8fi6wnM4A1eRfur7fC7TD5DG1cmi4UxcW3rshpB9wEwm",
            "symbol": "SNAKE",
            "name": "SnakeChain",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/SnakeRush/SnakeRush/master/SnakeChainLogo.png",
            "tags": [
                "utility-token",
                "gaming",
                "metaverse",
                "play-to-earn",
                "solana-ecosystem",
                "user-experience",
                "multiplayer"
            ],
            "extensions": {
                "description": "SnakeChain (SNAKE) are earned by playing SnakeRush, and minting your coins at the SnakeBank. They can be used to play more and exchange in any market where available.",
                "instagram": "https://www.instagram.com/snakerushgame/",
                "twitter": "https://twitter.com/snakerushgame",
                "website": "https://www.snakechain.org"
            }
        },
        {
            "chainId": 101,
            "address": "C7P3cWGAhEY9A4BsBXiQNZ7ycavPUcuQcaH99ppQdfwe",
            "symbol": "HNYC",
            "name": "Honey Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7P3cWGAhEY9A4BsBXiQNZ7ycavPUcuQcaH99ppQdfwe/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/+a5XKRkBSz3xkOTQ9"
            }
        },
        {
            "chainId": 101,
            "address": "5XoCeZjdZUsQoe9ahtjdH3EEPed3bQiKo96erRi556X5",
            "symbol": "USDE",
            "name": "USD Equivalent",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin8808/USDE/main/logo.png",
            "extensions": {
                "website": "https://usde.world/"
            }
        },
        {
            "chainId": 101,
            "address": "JApyjFvMard5g1WmgSGC6vF61ZsofSEHHQMYk6hNVUXk",
            "symbol": "SXD",
            "name": "SXD Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SaintxDmon/WL-sxd/main/logo.jpg",
            "tags": [
                "social-token",
                "mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "wLYsBuL6HH5Pxzh5sxdzHfQHmWTJMRMyP8WCUvnoKte",
            "symbol": "VWT",
            "name": "Vivendell Whitelist Token",
            "decimals": 0,
            "logoURI": "https://www.vivendell.com/images/icon-whitelist.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/vivendell",
                "website": "https://www.vivendell.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6sPqMNWhQPK8X23F6mQAabhKFfJ943fHPDZMaBYuJHgZ",
            "symbol": "GDLKD",
            "name": "GDLK Dev Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6sPqMNWhQPK8X23F6mQAabhKFfJ943fHPDZMaBYuJHgZ/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "WLTDpT8E9TY6jCQQhdamjs7SieUza68JpmCzaayWY6D",
            "symbol": "SPWL",
            "name": "SOLive Pass Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLTDpT8E9TY6jCQQhdamjs7SieUza68JpmCzaayWY6D/logo.png"
        },
        {
            "chainId": 102,
            "address": "8C96hGmwzfHznPPiviPxExuKc3MkZ3sNNZcKB9qXUDLg",
            "symbol": "BCAT",
            "name": "Bouncing Cat Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8C96hGmwzfHznPPiviPxExuKc3MkZ3sNNZcKB9qXUDLg/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "bubis9dcbs6dVrzWkybcLsFPc8wuw8q7vNEnG3eoRPJ",
            "symbol": "BuBi",
            "name": "BuBi Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bubis9dcbs6dVrzWkybcLsFPc8wuw8q7vNEnG3eoRPJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "ED2o7vhsJH67NUkYpLMZL5Y97SLRfJzTDb27ECy1ZJDe",
            "symbol": "TREC",
            "name": "Treeco",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ED2o7vhsJH67NUkYpLMZL5Y97SLRfJzTDb27ECy1ZJDe/logo.png",
            "tags": [
                "environment",
                "technology"
            ]
        },
        {
            "chainId": 101,
            "address": "6o2Aqq1VtovuwGYBegAvka3RXM3rxM5gTYUuGkXq4GEV",
            "symbol": "REYK",
            "name": "Reyka",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/web3ckster/reika/main/assets/logo.png",
            "tags": [
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "cT9TDUAxi6aw8uG3hfoU6eczXwTHenkQ2pxwRmx1uop",
            "symbol": "ECAT",
            "name": "E-Cat Energy",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin8808/ecat/main/logo.png",
            "extensions": {
                "website": "https://e-cat.energy/"
            }
        },
        {
            "chainId": 101,
            "address": "EKPr2TWypHoCV2zSPfYsfkt8mK8RjxRNNb9B6HH7CBci",
            "symbol": "CRS",
            "name": "Crypto Squirre",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ramoncrs/assets/main/logo.jpg",
            "tags": [
                "social-token",
                "meme-token",
                "defi-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D1KcDpJGxDj57qcnhGNucEDikjarZPJ6gjraj8b7hJL",
            "symbol": "VICE",
            "name": "Vice Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D1KcDpJGxDj57qcnhGNucEDikjarZPJ6gjraj8b7hJL/logo.png",
            "tags": [
                "vice",
                "sports"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Vicehood"
            }
        },
        {
            "chainId": 101,
            "address": "JAotw1dfgFRt3wUgkHrEXaHmMxXUQGMfZF176TNnMLFE",
            "symbol": "TLR",
            "name": "Tolar",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin8855/tlr/main/logo.png",
            "extensions": {
                "website": "https://tolar.exchange/"
            }
        },
        {
            "chainId": 101,
            "address": "FviScByi7Pbg1myqqHjfT6xxMVYUaF8wP3DGxgEov8tM",
            "symbol": "XIU",
            "name": "Xiuder Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Xiuder-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "game-token",
                "xiuder-token"
            ],
            "extensions": {
                "twitch": "https://www.twitch.tv/xiuder_",
                "twitter": "https://twitter.com/Xiuder_",
                "website": "https://www.smart-gfx.com/merch-xiuder",
                "youtube": "https://www.youtube.com/c/Xiuder"
            }
        },
        {
            "chainId": 101,
            "address": "CbpaPcL86iPJC8okGJbbH852tUFJrr18agBgz6j9uu4g",
            "symbol": "IZCO",
            "name": "IZGI Technologies",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/hammerbox/crypto/main/logo.png",
            "tags": [
                "NFTs",
                "utility-token",
                "currency-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "StiMWryqUXqFfQUBX51bbxhMJqS9RqJqsAvrsuh2aYi",
            "symbol": "SER",
            "name": "SER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StiMWryqUXqFfQUBX51bbxhMJqS9RqJqsAvrsuh2aYi/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/IRS",
                "twitter": "https://twitter.com/irsnft",
                "website": "https://sers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4tfNrjsyMR35Jbvua7RM2n2zsrvWBfPsuqovGxcL2DHB",
            "symbol": "DOT",
            "name": "Polkadot",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tfNrjsyMR35Jbvua7RM2n2zsrvWBfPsuqovGxcL2DHB/logo.png",
            "tags": [
                "utility-token",
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "Polkadot makes it easier than ever to create and connect decentralized applications, services, and institutions.",
                "discord": "https://discord.com/invite/wGUDt2p",
                "github": "https://github.com/paritytech/polkadot",
                "reddit": "https://reddit.com/r/dot",
                "telegram": "https://t.me/PolkadotOfficial",
                "twitter": "https://twitter.com/Polkadot",
                "website": "https://polkadot.network/",
                "youtube": "https://www.youtube.com/channel/UCB7PbjuZLEba_znc7mEGNgw"
            }
        },
        {
            "chainId": 101,
            "address": "ADn8cY1hhzFiB7bSFzR1oxLJdqc7Dhmj8UXhpdkNVtGj",
            "symbol": "KIKS",
            "name": "Kristijan Svihran",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kiks2401/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Vicehood"
            }
        },
        {
            "chainId": 101,
            "address": "Bhjc5ezhrKnZrLbMGahuY5rBYJ3ViKNFMsa7kNBnpacw",
            "symbol": "ENUM",
            "name": "Enum Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chrismengle/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5RnsebX6ZamLv8YAXDNhgv513SNFbUto9Yz1a9LC4tBZ",
            "symbol": "DSOUL-WL",
            "name": "Dragon Souls Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Soulsdragon/icon/main/token-wl.png",
            "extensions": {
                "website": "https://dragonsoulsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4p3fqvcKR4kvyKC7mmDFebsJg7D9ywzxskgXahG2AMo5",
            "symbol": "CHRV",
            "name": "ChorvaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/denvee32/chorvacrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8EnhbBFkxykmzGvJwZ55MgRRiLEVykn5iUNY9QmGy81A",
            "symbol": "STR",
            "name": "Stratos Energy",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/admin5505/str/main/logo.png",
            "extensions": {
                "website": "https://stratos.energy/"
            }
        },
        {
            "chainId": 101,
            "address": "GoQjhy3tBcXRWdNfvyh6MPhiQAkNdrAyCJdqWJ3WuUpW",
            "symbol": "LTC",
            "name": "Litecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoQjhy3tBcXRWdNfvyh6MPhiQAkNdrAyCJdqWJ3WuUpW/logo.png",
            "tags": [
                "utility-token",
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "litecoin",
                "description": "The cryptocurrency for payments",
                "github": "https://github.com/litecoin-project",
                "reddit": "https://www.reddit.com/r/litecoin",
                "telegram": "https://t.me/Litecoin",
                "twitter": "https://twitter.com/LitecoinProject",
                "website": "https://litecoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "AuNVtaGYtjeBL44nrSMHJMJsyZdbebrm5qQdrswqkZUD",
            "symbol": "VECO",
            "name": "VenloCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DromedarisCoi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GmEvUrfkmWzJSqua5UkK3KZMUWjLieDK6iatBd7QzEAN",
            "symbol": "Leo",
            "name": "Solleo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GmEvUrfkmWzJSqua5UkK3KZMUWjLieDK6iatBd7QzEAN/logo.png"
        },
        {
            "chainId": 101,
            "address": "BsnpN8xvDpY7uKo4gnk5B3Z6pHnyL8RXgmMTQFpEETLa",
            "symbol": "NRC",
            "name": "Nearcoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BsnpN8xvDpY7uKo4gnk5B3Z6pHnyL8RXgmMTQFpEETLa/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "currency-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/NearcastHQ",
                "instagram": "https://www.instagram.com/NearcastHQ/",
                "linkedin": "https://www.linkedin.com/company/nearcast",
                "twitter": "https://twitter.com/NearcastHQ",
                "website": "https://www.nearcoin.com/",
                "youtube": "https://www.youtube.com/channel/UCqvbKHoV0xaReGts8oedmnQ"
            }
        },
        {
            "chainId": 101,
            "address": "Kir4NUgYeLoHN7aBjNTfiyn3vHwZVKiyhBqN5RYBqnA",
            "symbol": "KIRIN",
            "name": "Kirin Kingdom",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/CtjdKgQ/unknown.png",
            "tags": [
                "NFT",
                "Staking",
                "Passive-income",
                "Breeding"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Kirin_Kingdom",
                "website": "https://kirin-kingdom.com"
            }
        },
        {
            "chainId": 101,
            "address": "7XmXhKmngJJvK3k1dSbCNDB9pfMyGtgm8QUcczUY1c4R",
            "symbol": "GAL",
            "name": "Galapagos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/galapagos-coin/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "NFTs",
                "Metaverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/bVvZWvFRAT",
                "telegram": "https://t.me/GalapagosNFTs",
                "twitter": "https://twitter.com/GalapagosNFTs",
                "website": "https://GalapagosNFT.com"
            }
        },
        {
            "chainId": 101,
            "address": "38vopEvoU5cdKX33noN793669JweG2KA5afW18BrLBFZ",
            "symbol": "GJJ-LDV",
            "name": "Lady with an Ermine",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38vopEvoU5cdKX33noN793669JweG2KA5afW18BrLBFZ/hermelijn.png",
            "tags": [
                "Geert-Jan-Jansen",
                "in-the-style-of",
                "Leonardo-da-Vinci",
                "NFT"
            ],
            "extensions": {
                "website": "https://blocksto.ne/leonardo-da-vinci/"
            }
        },
        {
            "chainId": 101,
            "address": "ViCeSQqiKWD3LGZv6W9tjLLwgXed3iPZpSLhurYZkXY",
            "symbol": "VICE",
            "name": "Vice",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ViCeSQqiKWD3LGZv6W9tjLLwgXed3iPZpSLhurYZkXY/logo.png",
            "tags": [
                "vice",
                "sports"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Vicehood"
            }
        },
        {
            "chainId": 101,
            "address": "9wB5JMdWYGtzygwo8GygrJFg6PY6QhtWK5HdK3PwJraV",
            "symbol": "CRTK",
            "name": "CRYTOK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mamoamo/cryotok/main/crytok_logo.png",
            "tags": [
                "promo-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3zjBUUJEvJHAUB6K4hX9j5HVcfJxXmC3iSREjsvDqT4C",
            "symbol": "CRPN",
            "name": "cryptonators",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ibby255/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GVsdtSe3E2fQoP3TzNT2M1VUchJ7sBmDBuvBZmGDGvmB",
            "symbol": "PEEL",
            "name": "PEEL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVsdtSe3E2fQoP3TzNT2M1VUchJ7sBmDBuvBZmGDGvmB/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolKongz",
                "website": "https://www.solkongz.net/"
            }
        },
        {
            "chainId": 101,
            "address": "4vXidyArpT8fyQTmfXfMNHitj5ay1Fjcnbw9fJLL2zBa",
            "symbol": "HONSHU",
            "name": "HONSHU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4vXidyArpT8fyQTmfXfMNHitj5ay1Fjcnbw9fJLL2zBa/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HonshuWolves"
            }
        },
        {
            "chainId": 101,
            "address": "29dvN2ABzyyKFJs7ZiqPEoFuv5cRKhtkCxjfeaDVEjwe",
            "symbol": "TERPS",
            "name": "TERPS TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/29dvN2ABzyyKFJs7ZiqPEoFuv5cRKhtkCxjfeaDVEjwe/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/KryptoKronicNFT",
                "website": "https://kryptokronic.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ucJLDcy5K7aHm8LVAwpnjWxMAn1MPZ3Wdq5hg9PXjsH",
            "symbol": "FLOYD",
            "name": "Floyd Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlexMGalvez/George-Floyd-Awareness-Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HuWgMSopqLv2uriuvsAMZnSE8DyQCXyfHD6C2NJUHFPX",
            "symbol": "PARTWL",
            "name": "Particles Pre-sale",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/xVznBiJ.jpg",
            "tags": [
                "particles-nft"
            ],
            "extensions": {
                "description": "Pre-sale Date Feb 11 at 9pm UTC",
                "discord": "https://dsc.gg/particles"
            }
        },
        {
            "chainId": 101,
            "address": "BPgZHN1YTZ5YGi1AkNSjAA3bKz1mi3VuvaHuWg5eCsQh",
            "symbol": "SNDR",
            "name": "Sinder Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ddhoch/cryptoSNDR/main/SinderCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DvamBgpr7JxvcCdpUud7UvESjjZC2Sp1jqautepgtqFf",
            "symbol": "FRIX",
            "name": "Frickin Frix",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thevaliantviking/FrickingFrix/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EnirFMFgqKebUHSsKt1Z9CKZCddUMS3DZ9iL3uLsbeJo",
            "symbol": "$0LEG",
            "name": "Olegario Arbelaez",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/drivengroup/0legario.github.io/main/images/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "35v9KktjoyRWbACbtVcEFjGbmhAN2QFb4hJvB2MnSyTd",
            "symbol": "PWL",
            "name": "Particles Presale",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/xVznBiJ.jpg",
            "tags": [
                "particles-nft",
                "feb11-9pm-utc"
            ],
            "extensions": {
                "discord": "https://dsc.gg/particles"
            }
        },
        {
            "chainId": 101,
            "address": "9qEKV8LZuZWiqBPLh7zFszBvBBE85q7Rgo6iDjurDAqZ",
            "symbol": "TESLON",
            "name": "Teslon Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vaibhavgupta28/teslon/main/TESLON.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Abym7kC68rnC5BRk1gsuTX19Yv2RGysQVDBoAUkr7NwK",
            "symbol": "MCOIN",
            "name": "Mcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nn9uy3n0326/m-coin/main/Mcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B3YqABfC8T8MTdPeDEof469zJiUWjy4CwM94dMaeMpxG",
            "symbol": "$PoSB",
            "name": "PoSB Doubloon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B3YqABfC8T8MTdPeDEof469zJiUWjy4CwM94dMaeMpxG/logo.png",
            "tags": [
                "posb-token",
                "doubloon"
            ],
            "extensions": {
                "description": "Pirates of Sol Bay Crew Token",
                "discord": "https://discord.gg/wEXT6vFKBM",
                "twitter": "https://twitter.com/PiratesOfSolBay",
                "website": "https://www.piratesofsolbay.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6o9N9o2axcm3kCcvjSmMHhR9MatuKdVTBsZsH3T5cFRb",
            "symbol": "DOUG",
            "name": "Doug Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AnEpicTurtle/DougCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bwq2FJjmtkiTEFGRGpQE3QyeUFBcbZBLKMWy8fmcD8T9",
            "symbol": "GDLKa",
            "name": "GDLK Access Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bwq2FJjmtkiTEFGRGpQE3QyeUFBcbZBLKMWy8fmcD8T9/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "Fy6cyRqB4SATf4kRAgXEL9Cze9gz468Mk1fNL5mY3msU",
            "symbol": "$Steak",
            "name": "Steak token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fy6cyRqB4SATf4kRAgXEL9Cze9gz468Mk1fNL5mY3msU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ekF24e5JEe",
                "instagram": "https://instagram.com/royallionclub",
                "telegram": "https://t.me/royallionclub",
                "twitter": "https://twitter.com/royallionclub",
                "website": "https://royallionpartyclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "7okxAUuyxQLsgkoSBNkhSGQoUkYdXMaL8KWuxNDLpkoZ",
            "symbol": "MSEK",
            "name": "Metasek",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7okxAUuyxQLsgkoSBNkhSGQoUkYdXMaL8KWuxNDLpkoZ/logo.png",
            "extensions": {
                "medium": "https://metasek.medium.com",
                "telegram": "https://t.me/metasek",
                "twitter": "https://twitter.com/MetaSek_",
                "website": "https://www.metasek.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4Nj9HtQ7W18Wuyka3bkm7sQngnJB6Rj8oLyjLs6cKZH6",
            "symbol": "ATLX",
            "name": "Atleron",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/atleron/atleron/main/atlxlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G2PQTxEooYBG6TcrJ97tFrBzyYoFiRQwwTwiAkTycpwd",
            "symbol": "BUM",
            "name": "Beach Bum Billionaire",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bumbeach/crypto/main/logo.png",
            "tags": [
                "Defi-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5ScrqeNncPHfiCtiHfg6KVtxJoYp4sWdp3bnWtddr1gY",
            "symbol": "RPTR",
            "name": "RaptorKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xAlphaRaptor/New-crypto/main/Token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fr44SZqRCxUQ3GcK9yrHcEW8aQqKYW9wmsavdGYWfdHd",
            "symbol": "JLSP",
            "name": "JAMIE PEANUT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BleuBacon/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "netGLdgjzwsr3zfX1YH59yMGNjX4nK8VtZGJvq7G35Z",
            "symbol": "NETG",
            "name": "Net Gala",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/netGLdgjzwsr3zfX1YH59yMGNjX4nK8VtZGJvq7G35Z/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NetGala",
                "twitter": "https://twitter.com/NetGalaNFT",
                "website": "https://netga.la/"
            }
        },
        {
            "chainId": 103,
            "address": "4hSYCK4fbjzDUKxQPJUjAqa9rpdd8r5QjVrsxVP83dCx",
            "symbol": "PET",
            "name": "SEED PET TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hSYCK4fbjzDUKxQPJUjAqa9rpdd8r5QjVrsxVP83dCx/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "EhdRK8hKjeNr6R3WStmh7LeiHDtZNdFLru6Mva1t8DZ1",
            "symbol": "ZED",
            "name": "SEED Weapon Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EhdRK8hKjeNr6R3WStmh7LeiHDtZNdFLru6Mva1t8DZ1/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiZZVBNDEpCDLJceFAqwR1DrtGtyHk9ymCHfzxYJP2KX",
            "symbol": "WDC",
            "name": "White Devil Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wdevil404/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "frtnaScfGPuo56uyPGmij1QTc64SBdjnXC3RXmcVmxw",
            "symbol": "FRTN",
            "name": "Fortune",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cesar128/token-list/3b60aecbb7e2565151a9dc3c2e4dab1a96acb520/assets/mainnet/frtnaScfGPuo56uyPGmij1QTc64SBdjnXC3RXmcVmxw/logo.png",
            "extensions": {
                "coingeckoId": "fortune-finance",
                "discord": "https://discord.gg/fortunefinance",
                "twitter": "https://twitter.com/fortunefinance_",
                "website": "https://fortune.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Fy3ZMyNFPiA4xZfpiFCrWYRwXSfmRHbvoBvxRVYBnPWN",
            "symbol": "DDC",
            "name": "Doodle Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fy3ZMyNFPiA4xZfpiFCrWYRwXSfmRHbvoBvxRVYBnPWN/logo.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6P6tND7xPMnGh1Xcc4YrD61SWs5zqe4KqW35jGQywhEu",
            "symbol": "TSW",
            "name": "TokenStore World",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/admin5505/tsw/main/logo.png",
            "extensions": {
                "website": "https://tokenstore.world/"
            }
        },
        {
            "chainId": 101,
            "address": "67QGaWsVKCPU3XeWNomsmVcE34Y1TM5gRF2PH5pMvFL7",
            "symbol": "ZYHON",
            "name": "Zyhon Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/M0rphe0us/ZhyonCoin/main/zhyonlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "m4Js9gR4SqX8QL66TU31NT3zCKSotbB2PiVv9wA7BuJ",
            "symbol": "M4JS",
            "name": "Memes4JS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/m4Js9gR4SqX8QL66TU31NT3zCKSotbB2PiVv9wA7BuJ/logo.png",
            "extensions": {
                "telegram": "https://t.me/memes4js"
            }
        },
        {
            "chainId": 101,
            "address": "63txudD2TqtVTyke1os4iUhzbcU34ykMvnhWVDcyRgxL",
            "symbol": "BLA",
            "name": "BLADE",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/63txudD2TqtVTyke1os4iUhzbcU34ykMvnhWVDcyRgxL/logo.png",
            "tags": [
                "EarnToPlay"
            ]
        },
        {
            "chainId": 101,
            "address": "43ynN6Y2mG7k3cU9EQveo5KJmzQKenzx2VGu6yVc2yfz",
            "symbol": "SGOD",
            "name": "SolanaGold",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sgolld/crypto/main/logo.png",
            "tags": [
                "Defi",
                "NFT",
                "GAME"
            ]
        },
        {
            "chainId": 101,
            "address": "9qCSRCRg8nKk6g78bvNNhqm3wfrDAgKfyvnfCcCmoU6t",
            "symbol": "KRMA",
            "name": "Kuruma",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexhunterzs7/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hys3b2ED2Wc5pZnZx6Yd7Fcc6zNtPCVk8NcQaKzNx2Md",
            "symbol": "LATIN",
            "name": "Latinos",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hys3b2ED2Wc5pZnZx6Yd7Fcc6zNtPCVk8NcQaKzNx2Md/logo.png",
            "tags": [
                "NFT",
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DfTFVgwyvN",
                "instagram": "https://instagram.com/latinostoken",
                "twitter": "https://twitter.com/latinostoken",
                "website": "https://medium.com/@latinostoken/latinos-al-poder-27315aed41d3"
            }
        },
        {
            "chainId": 101,
            "address": "htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD",
            "symbol": "HTO",
            "name": "HTO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD/logo.png",
            "tags": [
                "utility-token",
                "governance-token",
                "metaverse"
            ],
            "extensions": {
                "twitter": "https://twitter.com/heavenland_io",
                "website": "https://heavenland.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9vezmSLnbUc2aeuLEubVWHdqwhTbnEhCuesRa3kR5SpK",
            "symbol": "BEER",
            "name": "BEER Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vezmSLnbUc2aeuLEubVWHdqwhTbnEhCuesRa3kR5SpK/logo.png",
            "tags": [
                "meme-token",
                "utility-token",
                "fun-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Tn2CzcuD",
                "twitter": "https://twitter.com/thebeertoken",
                "website": "https://beertoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E8erdK9QjBZEMmkXisFRFrWA3kes7kRLhWcaT1VE7tWg",
            "symbol": "CLAND",
            "name": "Zeeland Homes",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E8erdK9QjBZEMmkXisFRFrWA3kes7kRLhWcaT1VE7tWg/Coinlogo.png",
            "tags": [
                "De-Fi",
                "real-estate",
                "community-token"
            ],
            "extensions": {
                "website": "https://blocksto.ne/zeeland-homes/"
            }
        },
        {
            "chainId": 101,
            "address": "52GzcLDMfBveMRnWXKX7U3Pa5Lf7QLkWWvsJRDjWDBSk",
            "symbol": "NGNC",
            "name": "NGN Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/52GzcLDMfBveMRnWXKX7U3Pa5Lf7QLkWWvsJRDjWDBSk/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://www.linkio.africa/"
            }
        },
        {
            "chainId": 103,
            "address": "BnT724Zd3WTFnYmpTMe1a4NqkWCJSjxf13iGyKEXHRiS",
            "symbol": "TFT",
            "name": "Techforce Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BnT724Zd3WTFnYmpTMe1a4NqkWCJSjxf13iGyKEXHRiS/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://techforceglobal.com/"
            }
        },
        {
            "chainId": 101,
            "address": "39oHe7Aw7XNpc23KKzJJZrPYNBnheobuGhJZoqJUknTW",
            "symbol": "HIQ",
            "name": "HiQoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mpnox/HiQoins/main/HiQoin.png",
            "tags": [
                "social-token",
                "HiQ"
            ],
            "extensions": {
                "website": "https://hiq.se/"
            }
        },
        {
            "chainId": 101,
            "address": "96NEqUTsdQZ3aQvs3ci67pAuGptRN3spgHTeC4PXxHRP",
            "symbol": "CCDAO",
            "name": "Cyber City DAO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/96NEqUTsdQZ3aQvs3ci67pAuGptRN3spgHTeC4PXxHRP/logo.png",
            "extensions": {
                "discord": "https://discord.gg/cybercitydao",
                "twitter": "https://twitter.com/CyberCityDAO",
                "website": "https://cybercitydao.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GMkKEutqt8Rr4pa2Fh9XZ4ZiQ54DoA64qxmKJXNX7s65",
            "symbol": "BARTER",
            "name": "Barter Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GMkKEutqt8Rr4pa2Fh9XZ4ZiQ54DoA64qxmKJXNX7s65/Coinlogo.png",
            "tags": [
                "community-token",
                "Barter-trade"
            ],
            "extensions": {
                "website": "https://blocksto.ne/barter/"
            }
        },
        {
            "chainId": 101,
            "address": "GXnRgq7q9uunrTmaCGL98Riugv88dv7uKiBqoxzmKwXE",
            "symbol": "BRG",
            "name": "Baby Rapper Gank",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXnRgq7q9uunrTmaCGL98Riugv88dv7uKiBqoxzmKwXE/logo.png"
        },
        {
            "chainId": 101,
            "address": "485JRT9ejhCmZK6rQj9kNBQK6DfzRerRefRWPxkmbtA3",
            "symbol": "TRASH",
            "name": "Trash Token",
            "decimals": 9,
            "logoURI": "https://raw.githack.com/solbumfights/tokenlogo/main/trash.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GZdhs47e6BkHyisqbGe8FKZNHbQVqm1bq9uYciSCkvdH",
            "symbol": "JANJAAP-WALLET",
            "name": "Wallet of Jan Jaap Geusebroek",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZdhs47e6BkHyisqbGe8FKZNHbQVqm1bq9uYciSCkvdH/jjPNG.png",
            "tags": [
                "Solana-wallet",
                "JanJaap-Geusebroek",
                "Blockstone-Founder"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/in/janjaap-geusebroek/",
                "website": "https://blocksto.ne/"
            }
        },
        {
            "chainId": 101,
            "address": "T1NYHYDyTYqft3UHvYKq3SzTBhSPWVcouVA1bp2fDE9",
            "symbol": "TINY",
            "name": "TINY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/K3rqQVxDdX",
                "twitter": "https://twitter.com/TinyDogzNFT",
                "website": "https://tinydogz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "WLt4YzchWBFCa62cottuJniCGUpVQxy9uEmtwws6iiK",
            "symbol": "MVBWL",
            "name": "MinerVerse Brews Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLt4YzchWBFCa62cottuJniCGUpVQxy9uEmtwws6iiK/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FXTaYTiqvXChz1ASom1ngEqt7LTjr5hfdwHoRkfjJfkd",
            "symbol": "MDR",
            "name": "Madori",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXTaYTiqvXChz1ASom1ngEqt7LTjr5hfdwHoRkfjJfkd/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.madori.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "ARApgmD9DyUo72TcfDpRomb13YZkqZKoLroRdeez8UeQ",
            "symbol": "ARTEM",
            "name": "ARTEM Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ARApgmD9DyUo72TcfDpRomb13YZkqZKoLroRdeez8UeQ/logo.png",
            "tags": [
                "ARTEM",
                "ART",
                "NFT"
            ],
            "extensions": {
                "description": "ARTEM is a fungible cross-chain digital token created by Simonida Pavicevic and Elio D'Anna (founders of The House of Fine Art, Kreation, House of Luxury, and Artcels) made especially for the fine art, digital art, and luxury collectables marketplace and global artist communities.",
                "medium": "https://medium.com/@ArtemCoin",
                "telegram": "https://t.me/ARTEMCoinOfficial",
                "twitter": "https://twitter.com/Artem_Coin",
                "website": "https://artemcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "6z484cGkS5Nxg1XvZR2wkivCHYXvTwMp5intiA59kWCP",
            "symbol": "LSTN",
            "name": "Listen Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/izetrom/token/master/logo.png"
        },
        {
            "chainId": 101,
            "address": "4exqZikhVp8XEToXekwYLw5YqXwzH7wUmwF76pHvubN2",
            "symbol": "GBL",
            "name": "GLOBAL INV CORP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4exqZikhVp8XEToXekwYLw5YqXwzH7wUmwF76pHvubN2/GINV.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/GLOBALINVC",
                "twitter": "https://twitter.com/globalinvcorp",
                "website": "https://en.globalinvcorp.com/ginvtoken"
            }
        },
        {
            "chainId": 101,
            "address": "D4niwCVzNKtt7dGw5X6TAJrLNBLvv8fZveh3bnNK7dcu",
            "symbol": "WPWD",
            "name": "Prison Worlds Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/prisonworlds/brands/main/whitelist.png",
            "tags": [
                "whitelist-token",
                "NFT"
            ],
            "extensions": {
                "website": "https://twitter.com/Prison_Worlds"
            }
        },
        {
            "chainId": 101,
            "address": "7KyxUX8H4bMi5PS5NERzEDTcLnM5JYt7wqEcG22ZLrAf",
            "symbol": "PWD",
            "name": "Prison Worlds",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/prisonworlds/brands/main/token.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Prison_Worlds",
                "website": "https://prisonworlds.com/"
            }
        },
        {
            "chainId": 101,
            "address": "31MbbgAyf2WJEzaG9Ck8WRvs2sq9UuDw7gr7cQ1PH3Xs",
            "symbol": "SUN",
            "name": "Sun Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/936507261264724008/940702342586728538/THE_SUN.png"
        },
        {
            "chainId": 103,
            "address": "GGkyk1KNs6kbnEgYwqYaPtuSwTXVhXPYBqododhGfw3T",
            "symbol": "UNITOK",
            "name": "Bugsy Token",
            "decimals": 6,
            "logoURI": "https://bugsy.world/logo.png",
            "tags": [
                "DeFi",
                "Gaming",
                "Gambling"
            ]
        },
        {
            "chainId": 101,
            "address": "BnV3XcZUbNsuonNKqkQrZSvCN8tVYTJtDgfUx6DJ9riy",
            "symbol": "NOIA",
            "name": "NOIA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BnV3XcZUbNsuonNKqkQrZSvCN8tVYTJtDgfUx6DJ9riy/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/eunoianfts",
                "website": "https://eunoianfts.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DPuGqV7jq9PEbcRU7bWzuaJx5bGiaVj4cNWhWjTdWAKi",
            "symbol": "DOPIES",
            "name": "DOPE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DPuGqV7jq9PEbcRU7bWzuaJx5bGiaVj4cNWhWjTdWAKi/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DopeApes"
            }
        },
        {
            "chainId": 101,
            "address": "4onzDs1X6ubktirorHB8iYbve3K4bBtkGpYehqzGm9So",
            "symbol": "BIJU",
            "name": "BIJU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4onzDs1X6ubktirorHB8iYbve3K4bBtkGpYehqzGm9So/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KuramaFoxes",
                "website": "https://kuramafoxes.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3EBLgqhs6CPff7DZMmQRoVFAy7Y9CpTZeKyEw8eryZj2",
            "symbol": "BUZZ",
            "name": "BUZZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EBLgqhs6CPff7DZMmQRoVFAy7Y9CpTZeKyEw8eryZj2/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/buzzapegang",
                "website": "https://buzz-ape.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DHbGBhZc1yLLgpPqAzr7KGs47oCMfbg2q6Fmg5NCSM1C",
            "symbol": "BAMB",
            "name": "BAMB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHbGBhZc1yLLgpPqAzr7KGs47oCMfbg2q6Fmg5NCSM1C/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/patrol_panda",
                "website": "https://panda-patrol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DhYTJPmUa5kQZfLgHb1soubgaK4VLZMxb8CTNY1vZ93S",
            "symbol": "MEK",
            "name": "MEK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DhYTJPmUa5kQZfLgHb1soubgaK4VLZMxb8CTNY1vZ93S/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MekaGorillaz"
            }
        },
        {
            "chainId": 101,
            "address": "8YQiVihv9DRqpcE6W1NF7wjGzUvheV15Cn577wVDsL39",
            "symbol": "SHAEBA",
            "name": "Shaeba Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nazmulababy/crypto/main/shaeba-coin-logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5mhEaWHh81FF2aJoTV7GuTgt4fiBvj3HGzoJ26eFka1a",
            "symbol": "FERC",
            "name": "FerretCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mpnox/HiQoins/main/FERC.png",
            "tags": [
                "social-token",
                "fun-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TocKJBU6yproKbFJCDcM8KHFzuWLzVEi5LXLMG6EoDw",
            "symbol": "SLTT",
            "name": "Solotto WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TocKJBU6yproKbFJCDcM8KHFzuWLzVEi5LXLMG6EoDw/logo.png"
        },
        {
            "chainId": 101,
            "address": "Y71XaLmJPvuPY4h4LnTZfFgSR6vP3qCxGLpCx25JTMA",
            "symbol": "STEAK",
            "name": "STEAK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Y71XaLmJPvuPY4h4LnTZfFgSR6vP3qCxGLpCx25JTMA/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RoyalLionParty",
                "website": "https://royallionpartyclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2zRiTfY2mmkUcDtj7Rp8pZuB15D5fupxoTaNEDewomTj",
            "symbol": "HEYESWL",
            "name": "Hollow Eyes WL",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/viniciuskloppel/hollow-eyes-token-image/hollow-eyes.jpg",
            "tags": [
                "Whitelist",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "C6n8Cb7kVjbXGPLybfgHNM783mQS1Jhs9RCx1ewiYXw",
            "symbol": "ZHC",
            "name": "ZashcheCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ft-1408/ZashcheCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "XenomnZ7kLQxfENcKKpfC8tov3GoZiW4XrDmPc8HRxd",
            "symbol": "XENO",
            "name": "Xenobots Fuel",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/KbkmW7D/unknown-5-Copie.png",
            "tags": [
                "NFT",
                "Staking",
                "fusion",
                "Passive-income",
                "Breeding"
            ],
            "extensions": {
                "twitter": "https://twitter.com/XenobotsNFT",
                "website": "https://thexenobotsproject.com"
            }
        },
        {
            "chainId": 101,
            "address": "8aMSnqggXTFEn4PSfB5gpzJja8ZuCLMjNV2VadPomhxx",
            "symbol": "YOG",
            "name": "Yoga Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VibhorOne8/YOGA/main/logo.png.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ANevJMDLF8Z2tJawwtmqqTENU38k6hHqgMyHgffMLdR7",
            "symbol": "GUIN",
            "name": "GuinCoin",
            "decimals": 0,
            "logoURI": "https://github.com/Ai4503/Guin/blob/main/GuinCoin.png",
            "tags": [
                "social-token",
                "NFT",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "dUjHL6zHaZtQjL6UKy1NBLZSbD4CCH3TAW4mDafLCzU",
            "symbol": "CONW",
            "name": "Construction World",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/conworldtoken/conwimage/main/CONWI.png",
            "tags": [
                "metaverse-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Constructionwrl",
                "website": "https://conworld.org/"
            }
        },
        {
            "chainId": 101,
            "address": "CSDTxoTLT1aniyoJSZ8uvHMe9GoLno1jpTSGNAbYfiwr",
            "symbol": "CSDT",
            "name": "CryptoShards Discount Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CSDTxoTLT1aniyoJSZ8uvHMe9GoLno1jpTSGNAbYfiwr/logo.png",
            "tags": [
                "discount-token",
                "sweepstakes"
            ],
            "extensions": {
                "discord": "https://discord.gg/VJUtHVJWyv",
                "twitter": "https://twitter.com/CryptoShardsNFT",
                "website": "https://cryptoshards.art/"
            }
        },
        {
            "chainId": 101,
            "address": "68RRPuZQrrw3whXHm9LSyC4y8iLrjkTm5Brc2tUMLNPw",
            "symbol": "CLAN",
            "name": "Clan Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/%24CLAN_Token.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "EKmeuVtJRMsXek53ohh33BG3QLxBAiCh4SqGCvS2Eq4W",
            "symbol": "CREATURE",
            "name": "Creature Scroll",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/CREATURE_Token.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "6wJYjYRtEMVsGXKzTuhLmWt6hfHX8qCa6VXn4E4nGoyj",
            "symbol": "GORE",
            "name": "GORE COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/gore-coin.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "DnYLfTsnLMYVdDhnZuzekdGf8AMQ3crDR2qRfpHRe47i",
            "symbol": "FIN",
            "name": "FIN COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/malibu-coin.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "32CHtMAuGaCAZx8Rgp54jSFG3ihbpN5brSvRAWpwEHPv",
            "symbol": "DAB",
            "name": "DAB COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/basc-coin.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "bATSLdvufuY3vQYv18HAEN3sJTLPodtiq2FtUismJd1",
            "symbol": "bATS",
            "name": "Bonded ATS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv/logo.png",
            "tags": [
                "utility-token",
                "dex",
                "swap"
            ],
            "extensions": {
                "coingeckoId": "atlas-dex",
                "coinmarketcap": "https://coinmarketcap.com/currencies/atlas-dex/",
                "medium": "https://atlasdex.medium.com/",
                "twitter": "https://twitter.com/atlas_dex",
                "website": "https://atlasdex.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "6cWXLiJQSaMX1446s6yfU9cj44xA7cRVjAy8pzuya5dz",
            "symbol": "DODA",
            "name": "SAAD token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alinet84/DODA/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G9DvVA9XhoTd4GnaBbM4oNWGreM1y75qd12E39PLRjdE",
            "symbol": "GMNFT",
            "name": "GreenMan",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G9DvVA9XhoTd4GnaBbM4oNWGreM1y75qd12E39PLRjdE/test1.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "website": "http://MyMetaHedge.com"
            }
        },
        {
            "chainId": 101,
            "address": "GUdpDLrmJYiXCPmw7U65jz9acHy1z9b1CKnYJxf8kZns",
            "symbol": "GRUMPY",
            "name": "GRUMPY TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUdpDLrmJYiXCPmw7U65jz9acHy1z9b1CKnYJxf8kZns/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/grumpysolnft",
                "website": "https://grumpysolnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "BtndwmZJ6QSJpb2dQFm9VyuaQPfKGFB9NrLXeY7rHvT8",
            "symbol": "CUSEGOVERNANCE",
            "name": "CuseTheJuice Governance Token",
            "decimals": 9,
            "logoURI": "https://github.com/CuseTheJuice/token-list/blob/main/assets/mainnet/BtndwmZJ6QSJpb2dQFm9VyuaQPfKGFB9NrLXeY7rHvT8/logo.png?raw=true",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xHpRcRKG",
                "twitter": "https://twitter.com/CuseTheJuice",
                "website": "https://linktr.ee/CuseTheJuice"
            }
        },
        {
            "chainId": 101,
            "address": "ToTxDbCvSKrTozuTLyTpiUMPMuyNtSohiXxcxbGv5wL",
            "symbol": "HODLWhales",
            "name": "HODL Whales White List Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanahodlwhales/whitelist/main/whitelistToken.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaWhalesNFT",
                "website": "https://solanahodlwhales.io"
            }
        },
        {
            "chainId": 101,
            "address": "HRVSkf3Vp5CLCA3a53qQawyPj8LXWNTVcF9zkLxHLnYt",
            "symbol": "HMTT",
            "name": "HMATT Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/HMATT-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utlity-token",
                "game-token",
                "hmatt-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/hmattofficial",
                "twitter": "https://twitter.com/_hmatt",
                "website": "https://hmatt.forstar.shop/",
                "youtube": "https://www.youtube.com/channel/UC89WhLz7cetBparM1hrIrFQ"
            }
        },
        {
            "chainId": 101,
            "address": "2dafTgJTtwDUx6RRYEv7Bxjp7nr1G6cyTP6cWQqXGjQ3",
            "symbol": "GOI",
            "name": "Gems Of The Internet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MrRobotop/gemsoftheineternet/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4t4Jq2LPFbePVe11HJJQByWhZgQWDkmRNhYtTo6rZa63",
            "symbol": "CREDITCARD",
            "name": "CREDIT CARD SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4t4Jq2LPFbePVe11HJJQByWhZgQWDkmRNhYtTo6rZa63/Coinlogo.png",
            "tags": [
                "credit-card",
                "solana"
            ],
            "extensions": {
                "website": "https://blocksto.ne/credit-card/"
            }
        },
        {
            "chainId": 101,
            "address": "7WSbzRYnisqGcYxG8yeCwViUbs3zGjpABAs7EAkMbj2y",
            "symbol": "CSC",
            "name": "Cipherspace Credits",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Cipherspace-Credits/CSC/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "gaming",
                "NFT"
            ],
            "extensions": {
                "description": "Credits to be used in Cipherspace",
                "discord": "https://discord.gg/3UJRJfeMmk",
                "twitter": "https://twitter.com/Cipherspace_Cr",
                "website": "https://www.cipherspace.credit"
            }
        },
        {
            "chainId": 101,
            "address": "mHzSiPEFjydrQoDb2e5JimTVR7kpz9PLMWYCaaSqM3U",
            "symbol": "DXTR",
            "name": "DaxterCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/apexslug/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6c1QMK2LCCojQ59uaormMLvDj3K8Vpfk7Br4fMAqgpqE",
            "symbol": "DPBLS",
            "name": "DeploraBills",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6c1QMK2LCCojQ59uaormMLvDj3K8Vpfk7Br4fMAqgpqE/logo.png",
            "tags": [
                "deplorable",
                "free-speech",
                "deplorabills"
            ],
            "extensions": {
                "website": "https://deplorabills.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2L1CKRS2NzJPGB22m2CpMtFcEvujk4ehDHWJW8nTpxdA",
            "symbol": "UZB",
            "name": "Uzbek Coin",
            "decimals": 0,
            "logoURI": "https://github.com/sanjarbek1807/Uzbekcrypto/blob/main/msg453275494-436158.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3xpb7ZioKc88PWJhrYMZioBp8SWP9DYejuwVBy4uCBj5",
            "symbol": "TJCN",
            "name": "TechJosieCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/techjosie/crypto/main/mainlogoICON.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H1fZRaLJdiUMkPzuWQTDhG6ToYP7BNDsEMcDoJj7PPA",
            "symbol": "GGWL",
            "name": "GLOOMY GECKO WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H1fZRaLJdiUMkPzuWQTDhG6ToYP7BNDsEMcDoJj7PPA/logo.png",
            "tags": [
                "NFT",
                "mint-token",
                "whitelist"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GloomyGeckos",
                "website": "https://www.gloomygeckonft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BXDAzbZVi6USE3GLcTqc5d78vbU7T6KeDJwKgBzPNF5r",
            "symbol": "GGNFT",
            "name": "GreenGuy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXDAzbZVi6USE3GLcTqc5d78vbU7T6KeDJwKgBzPNF5r/test1.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "website": "http://MyMetaHedge.com"
            }
        },
        {
            "chainId": 101,
            "address": "E9q7G44vMQSKTfxXGUppKyRzWpZAAp5rQoFBcjehyTNY",
            "symbol": "AAKA",
            "name": "Aaka",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ayan4157/crypto-aaka/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7CYJuwZmvhzQptaruSoH34xEPyZVKxk5dzq3vHsHYQeS",
            "symbol": "FROG",
            "name": "FROG Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FRoGricH18/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7jSfgVyxrCW5D9C7S5XHu32y8aeQgGFgjh6vzrGVW6oV",
            "symbol": "3MUT",
            "name": "Freedom Cash",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/34078361/153697539-c5dd51c4-73fd-43bc-bb5e-f6ac1acebfd6.png",
            "tags": [
                "stablecoin",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zzdZx5aMfx",
                "twitter": "https://twitter.com/FreedomDAO_",
                "website": "https://freedom-dao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Gh1jKzmxf95cT5PQabNbfJskkQU8kQ5UugfpbHSnPq9z",
            "symbol": "NRC",
            "name": "Neon Rocket Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/neontechanoah/neon_rocket_coin/main/asset/NRC_Logo_100px.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NeonRocketCoin"
            }
        },
        {
            "chainId": 101,
            "address": "DWXDRm1TbZBxy4Gtyg746Ms2moW7HtNT6j8dYtKs8u8y",
            "symbol": "CUSEGOVERNANCELP",
            "name": "CuseTheJuice Governance Token Liquidity Pool",
            "decimals": 9,
            "logoURI": "https://github.com/CuseTheJuice/token-list/blob/main/assets/mainnet/DWXDRm1TbZBxy4Gtyg746Ms2moW7HtNT6j8dYtKs8u8y/logo.png?raw=true",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xHpRcRKG",
                "twitter": "https://twitter.com/CuseTheJuice",
                "website": "https://linktr.ee/CuseTheJuice"
            }
        },
        {
            "chainId": 101,
            "address": "YuGiLp6mrZwQXVDr8xHMDiadp2og3QTKKyUqM3Fr2Yu",
            "symbol": "YUGI",
            "name": "Millenium",
            "decimals": 0,
            "logoURI": "https://github.com/KKQanT/token-list/blob/main/assets/mainnet/YuGiLp6mrZwQXVDr8xHMDiadp2og3QTKKyUqM3Fr2Yu/logo.png",
            "tags": [
                "testing-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HWhcvxobFohwVaDD1DGh2nKnenB7dytCpxi5EVL2DJst",
            "symbol": "PUTIN",
            "name": "Putin Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWhcvxobFohwVaDD1DGh2nKnenB7dytCpxi5EVL2DJst/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AzRN9BgQFVbcvVn34pvi5h1GqBUg7zXtV6RZNfeobfoX",
            "symbol": "TRBE",
            "name": "Tribe Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TribeLeaderNFT/TribeTokenImage/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ELADrKrvyv7mtQ5DbCvPDGogn4fcApH3jDeTy2qpfhsA",
            "symbol": "VIDI",
            "name": "VIDI",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELADrKrvyv7mtQ5DbCvPDGogn4fcApH3jDeTy2qpfhsA/logo.png",
            "extensions": {
                "discord": "http://discord.gg/vidiart",
                "medium": "https://medium.com/@vidiart",
                "serumV3Usdc": "Crxw1bdPQ549BfZs6iwZScmN2zZmuCA7wPvMqWWGihUM",
                "twitter": "https://twitter.com/Vidi_Art",
                "website": "https://vidi.art/"
            }
        },
        {
            "chainId": 101,
            "address": "29GVG3FUGaUxkZSvFQyYy23UWWk77vVRvMZRZysY2YXB",
            "symbol": "PLEB",
            "name": "Bittywood BittyBucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Luckyshotthegreat/Crypto-Bitty/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EwFQ8DHyc3Z2k7HWyABh4ZNBnjr9aGfvFLmdWe5ehepu",
            "symbol": "KRM",
            "name": "Keramos financial",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EwFQ8DHyc3Z2k7HWyABh4ZNBnjr9aGfvFLmdWe5ehepu/logo.png",
            "tags": [
                "financial-token",
                "financial",
                "ai-cryptocurrency",
                "solana"
            ],
            "extensions": {
                "description": "AI-POWERED CRYPTOCURRENCY FINANCIAL SERVICES",
                "website": "https://www.keramos.tech"
            }
        },
        {
            "chainId": 101,
            "address": "2mUsVwk8Hy3cxa7AchVUA2kVwre4S3Sj5JpnafVqyQTE",
            "symbol": "CLAIM",
            "name": "CLAIM Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mUsVwk8Hy3cxa7AchVUA2kVwre4S3Sj5JpnafVqyQTE/Coinlogo.png",
            "tags": [
                "claims",
                "invest"
            ],
            "extensions": {
                "website": "https://blocksto.ne/claim-coin/"
            }
        },
        {
            "chainId": 101,
            "address": "36BCpE3T2M5mCAaudBKcR21oT4zcas88wwqBeHb1KKUC",
            "symbol": "BLAK",
            "name": "Blackbeard",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/BlackBeardToken/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B3YvXxW7hrtPrg8z2ctwS8iTaJpqaWZKgLU2GyJbB5iB",
            "symbol": "ShroomApes",
            "name": "ShroomApes Token WL",
            "decimals": 0,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/fd5a4f97-2ac0-4712-a721-e5ea6c5e4700/public",
            "extensions": {
                "discord": "https://discord.gg/D8X8wmfUaJ",
                "twitter": "https://twitter.com/ApesShroom",
                "website": "https://shroomapes.com"
            }
        },
        {
            "chainId": 101,
            "address": "7tX8bqmNyWMi9X32mFvxR2ZJh4hTjohd5Lc5WmCLxX8e",
            "symbol": "MMC",
            "name": "Millionaire Mafia Club WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/agammc/MMCTOKEN/main/MMClogo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "description": "Credits to Millionaire Mafia Club",
                "twitter": "https://twitter.com/nftmmc"
            }
        },
        {
            "chainId": 101,
            "address": "4GJZyEZhqsHpKbWUtMGSsLYCpgDB3izrEcU61UJxBfMY",
            "symbol": "KUA",
            "name": "KOC\u0026U Awards",
            "decimals": 4,
            "logoURI": "https://i.ibb.co/CQRTSHk/KUA-Logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WCGXaSoSWgwBwpFzCq42eFpLFemLTCNPrAYEQt2eVmm",
            "symbol": "CRYPTO",
            "name": "Cryptocurrency Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WCGXaSoSWgwBwpFzCq42eFpLFemLTCNPrAYEQt2eVmm/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/cryptocoinio",
                "website": "http://cryptocurrencycoin.io"
            }
        },
        {
            "chainId": 101,
            "address": "2DKaAP95JRUh5enBc81DTWCrY1enovZjZGFA6BLF8myC",
            "symbol": "MILK",
            "name": "Baby Rapper Gank Milk Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DKaAP95JRUh5enBc81DTWCrY1enovZjZGFA6BLF8myC/MILKLOGO.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/babyrappergank"
            }
        },
        {
            "chainId": 101,
            "address": "BCUJboQTMHY38YFpgY1ZjnnjVfRpFoZZuEw49w8Ab6p7",
            "symbol": "GJJ",
            "name": "Wallet Geert Jan Jansen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCUJboQTMHY38YFpgY1ZjnnjVfRpFoZZuEw49w8Ab6p7/gjjPNG.png",
            "tags": [
                "Masterpainter"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/geertjanjansenmeesterschilder/",
                "website": "https://geertjanjansen.nl/contact/"
            }
        },
        {
            "chainId": 101,
            "address": "GNoatrXVjt7fGV7ysjBuarLaGMg5xLYwNegu18Kn7ycJ",
            "symbol": "2SET",
            "name": "2SET Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/2SET-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "2set-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/twosetviolin",
                "website": "https://www.twosetviolin.com/",
                "youtube": "https://www.youtube.com/c/twosetviolin"
            }
        },
        {
            "chainId": 101,
            "address": "2WUdQzKMFK1BvNu55TP1jH7LaDwfrzj2zPxAdTcVMC3p",
            "symbol": "FAVR",
            "name": "Favor Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Panda393/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4nwWXS6UD8yUtJiYUvGcBxnTP8cWrCVGixiMryjrQXmT",
            "symbol": "IT",
            "name": "IT SOL Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4nwWXS6UD8yUtJiYUvGcBxnTP8cWrCVGixiMryjrQXmT/Coinlogo.png",
            "tags": [
                "software-developers",
                "guarantee-payments"
            ],
            "extensions": {
                "website": "https://blocksto.ne/itcoin/"
            }
        },
        {
            "chainId": 101,
            "address": "FqwseU5ScQrvDH6hCVkbNTXn2B9ZyxfxcTBuDoyHNPRT",
            "symbol": "$DNGL",
            "name": "Degen Nation Green List",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/setoKaibalol/pictures/main/pic/DN_sol_gradient_black_bg_600x600.png",
            "tags": [
                "Utility-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DegenNation69",
                "twitter": "https://twitter.com/DegenNation69",
                "website": "https://degen-nation69.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DH9L2E49cBKC9BEbt4q262WDp6E7FnH7QBkyES7AQbSx",
            "symbol": "CLASS",
            "name": "CLASS",
            "decimals": 3,
            "logoURI": "https://github.com/Boogdy/Boogdy/blob/main/C%20(1).jpg?raw=true",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Classoids"
            }
        },
        {
            "chainId": 101,
            "address": "7ePMLZDsyV6y3BhiSwiewzsTvbh5XkUDiEXVtvB2wGP4",
            "symbol": "VM",
            "name": "Versus Mode",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ePMLZDsyV6y3BhiSwiewzsTvbh5XkUDiEXVtvB2wGP4/logo.png",
            "tags": [
                "nft-token",
                "card-game"
            ],
            "extensions": {
                "website": "https://www.versusmode.net/"
            }
        },
        {
            "chainId": 101,
            "address": "6FLsSkF4AqQeDuqEmGPyBZvVebc4WWWntnZP2QZyBzG8",
            "symbol": "JJJJC",
            "name": "JJC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6FLsSkF4AqQeDuqEmGPyBZvVebc4WWWntnZP2QZyBzG8/logo.png",
            "tags": [
                "social-token",
                "nft-token",
                "game-token",
                "jjcverse"
            ]
        },
        {
            "chainId": 101,
            "address": "GDQBFKyQKJhMTM53NbF64FaVECv7aooffUJU1MXiixo1",
            "symbol": "MWLT",
            "name": "Mutant WL",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/HFaour/token-list/main/assets/mainnet/GDQBFKyQKJhMTM53NbF64FaVECv7aooffUJU1MXiixo1/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/catcartel",
                "twitter": "https://twitter.com/SolanaCatCartel",
                "website": "https://catcartel.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4Mekk6DCgmFZpDCz4FGDPq1T1W43YPUakiRsoY89uKXM",
            "symbol": "BSIR",
            "name": "BlocSocIITR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Mekk6DCgmFZpDCz4FGDPq1T1W43YPUakiRsoY89uKXM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://blocsoc.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "8fpus58kYH9rxuG9hodgcZLcsw4Uj4L9G1ZvGFqX7fyF",
            "symbol": "QD",
            "name": "QDukan",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/qdukan/QD/main/logo.png",
            "tags": [
                "accessories-token",
                "ecommerce-token",
                "fashion-token",
                "shopping-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Qdukan1",
                "website": "https://qdukan.com"
            }
        },
        {
            "chainId": 101,
            "address": "6xreK49Nq2VEvcQNpddKWqWwQXBDMgZoNqfz2NiG75Wp",
            "symbol": "BONG",
            "name": "MCS Farmer's Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DefiTokens/assets/main/pass.png",
            "extensions": {
                "twitter": "https://twitter.com/metajuananft",
                "website": "https://metajuanacs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2pQSbiyNgj7oVG3oZAeWG1HjmLZW3XwerHFdaptvJErA",
            "symbol": "DUB",
            "name": "Dubloon",
            "decimals": 12,
            "logoURI": "https://storage.googleapis.com/random-stuff-do-not-delete/crypto-coins/dubloon.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5wHSMHVicDbR9NwnCZfvZqLwHTv2iS2mQxp3kdsJGTzD",
            "symbol": "REHA",
            "name": "Rehab",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/XRPAviator/Rehab/main/Photo_1644669256494.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6LaJKhZts2cJJPdo9v7XR4kHiLk9ERh16VLKjBUS8DxD",
            "symbol": "$LILY",
            "name": "Lilypad Token",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/FrogPartyNFT/Lilypad/main/logo.png",
            "tags": [
                "game",
                "coin",
                "game-coin",
                "game-currency",
                "game-token",
                "videogame",
                "frogparty",
                "frog"
            ],
            "extensions": {
                "website": "https://frog.party/"
            }
        },
        {
            "chainId": 101,
            "address": "CA3zFzBXyVt4tDfAvKWMDxtWogtoGYao5ANvg7Feo8u5",
            "symbol": "WEED",
            "name": "Weed Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CA3zFzBXyVt4tDfAvKWMDxtWogtoGYao5ANvg7Feo8u5/pot.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://weedcoin.store"
            }
        },
        {
            "chainId": 101,
            "address": "8ZepSXp47WFyDK21QbvMiiKVWRHnGrAegiwDr71PfGi3",
            "symbol": "$ROBO",
            "name": "ROBO Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CMHayden/crypto/master/robocoin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cN4qECwt68",
                "twitter": "https://twitter.com/robotmafiaclub"
            }
        },
        {
            "chainId": 101,
            "address": "FrNEoFBDfz62eZQoucquKTHA87XCuPL5GyatQafXbxK1",
            "symbol": "HGY",
            "name": "High Hungry",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/Shateizi/highhungry/main/random_apes_1.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HighHungry_Sol"
            }
        },
        {
            "chainId": 101,
            "address": "karwVyyQYvdZzR6MvCm5QbbmYxzJ7DqQNa44gD8Rpi8",
            "symbol": "KV",
            "name": "Karma V22",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Justin-hw-Tseng/assets/main/karma.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "r82YqkLkNBWexTLBfzCMUVmCvCVcy5vnv73wB3orXyN",
            "symbol": "BLOCKSTONE",
            "name": "BlockStone wallet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/r82YqkLkNBWexTLBfzCMUVmCvCVcy5vnv73wB3orXyN/blockstonePNG.png",
            "tags": [
                "Solana-wallet",
                "Blockstone"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Blockstone-103327348899538/",
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "twitter": "https://twitter.com/TheBlockstone",
                "website": "https://blocksto.ne/solana-wallet/"
            }
        },
        {
            "chainId": 101,
            "address": "B3J1npmtEst1zQtbgaUTNgyjV4BHiVLydbcXSmuz3onw",
            "symbol": "$PACHA",
            "name": "Pacha DAO Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/bridgitbrowser/bridge-assets/master/tokens/pacha.svg",
            "tags": [
                "p2e",
                "dao",
                "coin",
                "game-token",
                "animation",
                "nft"
            ],
            "extensions": {
                "website": "https://pachaverse.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HHXKU5sTRCsrJqGk19XCkX8gHXk4XsfGSBrBZBr2N1QE",
            "symbol": "LMHF",
            "name": "Lemonhead Felons",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/leojack12/files/main/2.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/LemonHeadFelons"
            }
        },
        {
            "chainId": 101,
            "address": "5GcstXTDa6EvauGhSPFi6TokfJhNT1nQTqVGvVDGtV5",
            "symbol": "SHZ",
            "name": "SHOOZCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shoozpc/shoozcoin/main/SHOOZCOIN.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ABxMcyrcR17mzeksKkGbLVq24qXJtHM8zTEfxCo6JpfG",
            "symbol": "CUTIE",
            "name": "Guarantee SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ABxMcyrcR17mzeksKkGbLVq24qXJtHM8zTEfxCo6JpfG/Coinlogo.png",
            "tags": [
                "guarantee-token",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/cutee-sol/"
            }
        },
        {
            "chainId": 101,
            "address": "EjTVkGzAR9kYLb6guB3G1mophcqJbJ8BdNZ32GdkKhuA",
            "symbol": "RELO",
            "name": "Rello Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NicholasGLambert/Rello/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hv5mZYNXfnapjB4f1opJVipu7wNVv7RWfDPsRrAWXkWo",
            "symbol": "DPS",
            "name": "Dhanush P Sukruth",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dsukruth/dps_token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ck7DzPFmKVUDJ7VUKFpesgPATYDcD25sK2KPjP61T98r",
            "symbol": "QT-AMSTERDAM",
            "name": "Guarantee Amsterdam Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ck7DzPFmKVUDJ7VUKFpesgPATYDcD25sK2KPjP61T98r/amsterdamcoin.png",
            "tags": [
                "SOL-guarantee",
                "Amsterdam-Coin"
            ],
            "extensions": {
                "website": "https://blocksto.ne/guarantee-amsterdam-coin/"
            }
        },
        {
            "chainId": 101,
            "address": "5ic9vUza5tqrmMX3d2P3zZ78yDbxy2AzYiGA7SUYUQtK",
            "symbol": "FSHL",
            "name": "FishelCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nfishel/fishelcoincrypto/main/fishelcoinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AtPaih7G75f7LKQzc1CNCPv6crc9T5iwCaan629gLTRq",
            "symbol": "DMK",
            "name": "DimorneKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JDBeast200/crypo/main/token_img.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GmBwNscpLsjctXS4yEt43b1sBzeboUJythhAXw23deg1",
            "symbol": "SNIFF",
            "name": "SNIFF",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/1735634/153721371-69bd5db6-1b6e-433d-a682-5cae6a00e517.png",
            "tags": [
                "social-token",
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.gg/jgTh4tyyAJ",
                "twitter": "https://twitter.com/noseagenft"
            }
        },
        {
            "chainId": 101,
            "address": "Dj5vr8fVTkt2DQMMoDL5cnva7fdBAgrPe2pcGkECCQ6t",
            "symbol": "MAYA",
            "name": "MAYA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iwillbemicrosoftceo/profile-repo/main/om.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2KGYYcwXfxzZ8QsfHwett92Dwz6mpptjtHmLW8WQU2NM",
            "symbol": "NFTBTC",
            "name": "BTCNFT Trademark",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2KGYYcwXfxzZ8QsfHwett92Dwz6mpptjtHmLW8WQU2NM/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "1Kz2ZBJpMDeH9bJxqH7oGMZAn8PZ35ZeMSm7RVY12J1",
            "symbol": "AYQC",
            "name": "Andrelana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andreyaniv/cryptolana/main/crypto.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://andreyaniv.com/"
            }
        },
        {
            "chainId": 101,
            "address": "TX2FnsJkWvAyjSRoEZsCkDu4ViwZDEYMehiT6U6PXKj",
            "symbol": "TIEXO",
            "name": "Tiexo",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/GvTPdwc/unknown-6.png",
            "tags": [
                "Marketplace",
                "NFT",
                "Cross-Chain"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tiexohq",
                "website": "https://pages.tiexo.com"
            }
        },
        {
            "chainId": 101,
            "address": "FaTPs4RdGnSpEcyNEDwnVbHxM3Mu2PUmv2UftKNSNuHU",
            "symbol": "FRP",
            "name": "Freppi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Freppi-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "freppi-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/francescocastagnae/",
                "twitter": "https://twitter.com/castagnafrance2",
                "website": "https://linktr.ee/Francesco03"
            }
        },
        {
            "chainId": 101,
            "address": "9DYLi7scxtyTGUbQEwNSuSoiVUvnxUHrY6mYRGdTu87C",
            "symbol": "ACIDBOMBWL",
            "name": "Acid Bombs Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9DYLi7scxtyTGUbQEwNSuSoiVUvnxUHrY6mYRGdTu87C/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4PunrmGdVN5ZCora83RSRSsFYJBTervxHe7KCxFKYQV9",
            "symbol": "YIPS",
            "name": "Yipperson",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tokermaker/yippers/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Yi69Ac2D1Qa5WGXn7pGw7h4tE5jC74V3TANhiqQaX9b",
            "symbol": "MTDF",
            "name": "Matilda Ferragni Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/MatildaFerragni-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BANNrQ9CAjnT4ar5rtA3J5Yv67j1Y8CXLmSg1t6atYQq",
            "symbol": "MRVL",
            "name": "Marvel Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Marvel-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "game-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/marvel",
                "website": "https://www.marvel.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BATz51cYUfdw8S5HD6ambiJLzDhogQq2Ne3NQu8RREk2",
            "symbol": "KHB",
            "name": "Khabi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Khabi-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "khabi-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KhabyLame",
                "website": "https://khabyshop.com"
            }
        },
        {
            "chainId": 101,
            "address": "eSk6wPv1ZCEaqRshKNjYvwH9jyYdFR2pJWZyTeEBm2G",
            "symbol": "$NFTBTC",
            "name": "BTCNFT Trademark Ticker",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/eSk6wPv1ZCEaqRshKNjYvwH9jyYdFR2pJWZyTeEBm2G/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E9aUfnpyoWwq9GMXD2QsT98v8fyZCaXwoyn3tmHY724w",
            "symbol": "$BTCNFT",
            "name": "BTCNFT Trademark USDC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9aUfnpyoWwq9GMXD2QsT98v8fyZCaXwoyn3tmHY724w/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GF8xQkj8EsdB19grGhRYzTFV766NtNBakz6GZg6a9Msr",
            "symbol": "DUCK",
            "name": "Duck Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FriendlyDuckNFT/nft-project/main/ducktokennew.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gfc5CxvWtoAnHgHD4uJPeHwa69CwnGzDcU2jhNBmwJFu",
            "symbol": "PTC",
            "name": "PatronumCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Cryptomaker1212/PatronumCoin/main/patronumlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9AKfTcjQ5yVKbrySSgU1GKtRJrMCz5RY9DtA5wdnDMcE",
            "symbol": "AMSTERDAMCOIN",
            "name": "Amsterdam Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9AKfTcjQ5yVKbrySSgU1GKtRJrMCz5RY9DtA5wdnDMcE/amsterdamcoin.png",
            "tags": [
                "Blockstone",
                "Amsterdam-Coin"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Blockstone-103327348899538/",
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "twitter": "https://twitter.com/coinamsterdam",
                "website": "https://blocksto.ne/amsterdam-coin/"
            }
        },
        {
            "chainId": 101,
            "address": "Emn9MBFDuHTTa5Vrde8ComqeCAeFZPuNEA3Tm7XTMfUg",
            "symbol": "BISCUITS",
            "name": "BISCUITS",
            "decimals": 3,
            "logoURI": "https://user-images.githubusercontent.com/95661911/153719099-b2300769-20d8-46bb-8f4c-c9d4d4f335c7.jpeg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bbsolana",
                "website": "https://www.bulldogbillionaires.club/"
            }
        },
        {
            "chainId": 101,
            "address": "6rgrgN6hugDqP9xivu8gEYDGoXmSuDzMxe7QcNRonAYc",
            "symbol": "THGZ",
            "name": "Thugbirdz Loyalty Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6rgrgN6hugDqP9xivu8gEYDGoXmSuDzMxe7QcNRonAYc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/soldit_"
            }
        },
        {
            "chainId": 103,
            "address": "BMbc3JA435uuigvEtwDvcicXJcozXsLDziVuB7JktMgb",
            "symbol": "Test",
            "name": "Test listing",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BMbc3JA435uuigvEtwDvcicXJcozXsLDziVuB7JktMgb/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://aiko.io/"
            }
        },
        {
            "chainId": 101,
            "address": "G9BetcZDyXS2kwhAsxrbirg8ujkE1GePxaTXefZMsVz8",
            "symbol": "VEIN",
            "name": "CRYPTO VEIN",
            "decimals": 6,
            "logoURI": "https://github.com/VEINTOKEN/images/blob/ccad4a6caea3ab9b373dbe283b5da3b56436be5d/CRYPTO_VEIN_LOGO_SIMPLE.png",
            "tags": [
                "general",
                "mining"
            ],
            "extensions": {
                "website": "https://www.cryptovein.net/"
            }
        },
        {
            "chainId": 101,
            "address": "Cgp9Hb59rzv8WYm6bkkq6q55KJ47Eqgo9R1S9dCPcjJw",
            "symbol": "MM",
            "name": "MadMan Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Madman-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "madman-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/orangemadman",
                "website": "https://tantarobastore.com/madman"
            }
        },
        {
            "chainId": 101,
            "address": "9mCmBexpg4v21NVJbZXPwMLTPDvdAz91d52yzZgFVr62",
            "symbol": "STN",
            "name": "Stone Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Stone-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "game-token",
                "meme-token",
                "stone-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6tpi16FnQq9Jtra6FDiYS1j6bXiz4bnk5FCXuT7v21sj",
            "symbol": "SNDR2",
            "name": "Sinder Coin 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sinder-exe/Crypto/main/SINDERCOIN2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hji6T3VMDqAv21KzX1RfPPvBYn3C7VcnN4YZoCUd3Yhd",
            "symbol": "MOVE",
            "name": "MoveCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vipurkumar/move89-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3PjhmWT4ZbTkaWNPfQ6zwyDVhhuxM28T66UfZDCvt65F",
            "symbol": "ET",
            "name": "Everest Token",
            "decimals": 0,
            "logoURI": "https://github.com/EverestToken/EverestCrypto/blob/main/Everest.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HJLTaDvwcyHvLbvme81E2BtyxsURfazo34anUwTZDc3W",
            "symbol": "MORTGAGE",
            "name": "Mortgage Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJLTaDvwcyHvLbvme81E2BtyxsURfazo34anUwTZDc3W/Coinlogo.png",
            "tags": [
                "mortgage-coin",
                "DeFi"
            ],
            "extensions": {
                "website": "https://blocksto.ne/mortgage-coin/"
            }
        },
        {
            "chainId": 101,
            "address": "A6RaMVULGMZhKyFjiqchvonNBYbGEz3hAYvJZb2Zh5v7",
            "symbol": "TIME",
            "name": "TIME Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A6RaMVULGMZhKyFjiqchvonNBYbGEz3hAYvJZb2Zh5v7/watch.png",
            "tags": [
                "Investment",
                "Time"
            ],
            "extensions": {
                "website": "https://blocksto.ne/time-coin/"
            }
        },
        {
            "chainId": 101,
            "address": "DYXYVkCk4Xk7RpWdsnqZEkt2S1UBYHsSbj5ZWTWAHvHM",
            "symbol": "SOSA",
            "name": "Social Sauna Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYXYVkCk4Xk7RpWdsnqZEkt2S1UBYHsSbj5ZWTWAHvHM/logo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SocialSaunaClub"
            }
        },
        {
            "chainId": 101,
            "address": "6VAqGYWWiPbBMsKY2LpdE21ECNeoMooobTdtSsw7LGUX",
            "symbol": "SMBX20",
            "name": "SmashCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6VAqGYWWiPbBMsKY2LpdE21ECNeoMooobTdtSsw7LGUX/logo3.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://smashbox20.com"
            }
        },
        {
            "chainId": 101,
            "address": "GVzo3Asp4LgJ4wdFLBg2fv4XZXai5cGniZNyb32KeTkb",
            "symbol": "WNK",
            "name": "WINKIE KOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Fishbone5150/Solana_Crypto/main/Wink_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "59d9er76s7xUzTxwJx797crEBDC5vsKZdemQvJ5Z4HGJ",
            "symbol": "VELS",
            "name": "The VELS Home",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ljssoares/Mycripto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J1T2yjgZLWWrZEQ5J4Mmo2NrXmZJGN8XrQcsi6T7hzRn",
            "symbol": "MANIJABZ",
            "name": "ImaniJabaliKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jambazi1/MANIJABZ/main/MANIJABZ.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F9cQnniHJWEx7uinpjR5J3enVHcCW5kQPcKh2MBmegDH",
            "symbol": "GROK",
            "name": "Grok Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mbarnott/GLogo/main/grok.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HmKMP6mEfbdLDKSL7bpV41QTe4ECNJWNCXbpYUavAauh",
            "symbol": "OUT",
            "name": "OUTDOORSMAN COIN",
            "decimals": 9,
            "logoURI": "https://github.dev/mfhunt/token-list-1/blob/fe4bb38263edce5801507623aa77ca8aa7f4857c/assets/mainnet/HmKMP6mEfbdLDKSL7bpV41QTe4ECNJWNCXbpYUavAauh/logo.png",
            "tags": [
                "Utility-Token",
                "Community-Token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/uBUDut4T",
                "website": "https://solscan.io/token/HmKMP6mEfbdLDKSL7bpV41QTe4ECNJWNCXbpYUavAauh"
            }
        },
        {
            "chainId": 101,
            "address": "AXgZjDfLxYqr8Sxix5vztYie6PVwKB5miYNnR5YR6ANX",
            "symbol": "PMCOIN",
            "name": "Perky Mint Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AXgZjDfLxYqr8Sxix5vztYie6PVwKB5miYNnR5YR6ANX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://perkypikas.com"
            }
        },
        {
            "chainId": 101,
            "address": "5xNfxFAesUqRQnG4Y4VGBdBR3jCaxEHGztoxQxruN8Hb",
            "symbol": "TRIBU",
            "name": "Tribu Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5xNfxFAesUqRQnG4Y4VGBdBR3jCaxEHGztoxQxruN8Hb/tribu.png",
            "tags": [
                "tribu-coin",
                "community-token"
            ],
            "extensions": {
                "website": "https://tribuapp.tech"
            }
        },
        {
            "chainId": 101,
            "address": "66aj3Zrpoaze3CXnfp4D2D345TYTS7HeCexLP8xznAtG",
            "symbol": "TFNFT",
            "name": "TheFrenchies",
            "decimals": 6,
            "logoURI": "https://github.com/The-Frenchies/assets/blob/main/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "RoLLn5qBN4juQ1D2KFpJyAcC7Deo3cYotXi4qDooHLU",
            "symbol": "ROL",
            "name": "ROL",
            "decimals": 6,
            "logoURI": "https://assets.blockstars.gg/static/ROL.svg",
            "tags": [
                "utility-token",
                "game-token"
            ],
            "extensions": {
                "description": "$ROL is the core gameplay token used in Blockstars, Solana's first management simulation game.",
                "discord": "https://discord.gg/blockstars",
                "facebook": "https://www.facebook.com/blockstarsgame",
                "instagram": "https://instagram.com/blockstarsgame",
                "medium": "https://blog.blockstars.gg",
                "reddit": "https://www.reddit.com/r/blockstarsgame",
                "twitter": "https://twitter.com/blockstarsgame",
                "website": "https://blockstars.gg"
            }
        },
        {
            "chainId": 101,
            "address": "RoCK9ocDq7Ga8s2VGAgJxNjAgByYd2dyps5WCwVLDMm",
            "symbol": "ROC",
            "name": "ROC",
            "decimals": 6,
            "logoURI": "https://assets.blockstars.gg/static/ROC.svg",
            "tags": [
                "utility-token",
                "game-token"
            ],
            "extensions": {
                "description": "$ROC is the governance token used in Blockstars, Solana's first management simulation game.",
                "discord": "https://discord.gg/blockstars",
                "facebook": "https://www.facebook.com/blockstarsgame",
                "instagram": "https://instagram.com/blockstarsgame",
                "medium": "https://blog.blockstars.gg",
                "reddit": "https://www.reddit.com/r/blockstarsgame",
                "twitter": "https://twitter.com/blockstarsgame",
                "website": "https://blockstars.gg"
            }
        },
        {
            "chainId": 101,
            "address": "YNnTDhbhseADo4q8QX8Kfy6FWDrCV3PLVG4wcuDWh4G",
            "symbol": "BOLT",
            "name": "BOLT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YNnTDhbhseADo4q8QX8Kfy6FWDrCV3PLVG4wcuDWh4G/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/skullbots",
                "twitter": "https://twitter.com/SKULLBOTS",
                "website": "https://www.skullbots.io/"
            }
        },
        {
            "chainId": 101,
            "address": "72NBPHtsUkLvk4KpPcDCiDnaHPoP8XvBGZsjcsSSkyi9",
            "symbol": "ARF",
            "name": "Sealz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72NBPHtsUkLvk4KpPcDCiDnaHPoP8XvBGZsjcsSSkyi9/logo.png",
            "extensions": {
                "website": "https://sealzdao.com"
            }
        },
        {
            "chainId": 101,
            "address": "7BjGUzsCJBXuT21QS4tkEdWf4DyFbJz4wAZRbkoCVizm",
            "symbol": "XSGD",
            "name": "Wormhole wrapped XSGD(eth)",
            "decimals": 8,
            "logoURI": "https://i.imgur.com/iXKK4YQ.png",
            "extensions": {
                "serumV3Usdc": "9mJLgcpgwQtSSFixeUAbqw6JGiP8Px1CpbMzhuG6NCdk",
                "website": "https://straitsx.com"
            }
        },
        {
            "chainId": 101,
            "address": "54vCmCdKk3hkT76CJKmhJq7G1FWL3CTuGNkAa2sRVzAv",
            "symbol": "$REG",
            "name": "REG Tokens",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54vCmCdKk3hkT76CJKmhJq7G1FWL3CTuGNkAa2sRVzAv/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "EuijzUe5SDQjg4Gpia8Jt8EsVdGmR8sA16FCH9SpmeHW",
            "symbol": "SCKC",
            "name": "Sucker Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EuijzUe5SDQjg4Gpia8Jt8EsVdGmR8sA16FCH9SpmeHW/logo.png",
            "tags": [
                "Sucker-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "GDePUpH7aFkxYx6ufPseSJEdLphxsSF58cFVqoUxv9Nt",
            "symbol": "BRM",
            "name": "BRG Milk Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GDePUpH7aFkxYx6ufPseSJEdLphxsSF58cFVqoUxv9Nt/brmlogo.png"
        },
        {
            "chainId": 101,
            "address": "Ak6ZqnuToBysp4Em8S5VH4TL7BBKL8cyGNHUz1vY1Vry",
            "symbol": "$Hedgie",
            "name": "The SOL Hedgehog",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danny8252/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6kqr5FDAEPAPfHZJDSrZV5qaZm9xWbYNbVVois3Qd5fs",
            "symbol": "AIVN",
            "name": "AIVEN COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DeVReV27/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HqR91GXLmMjWYWJCBnBLUd6ahnddQJR2zSb3bqw5B4Hw",
            "symbol": "COMPANY",
            "name": "Blockstone Company Fund",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HqR91GXLmMjWYWJCBnBLUd6ahnddQJR2zSb3bqw5B4Hw/blockstonePNG.png",
            "tags": [
                "Fund",
                "Companies",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/blockstone-company-fund/"
            }
        },
        {
            "chainId": 101,
            "address": "AVAayYjzz8FuGGwu1g37F5im1T5foYgjnmC87cdAtFhk",
            "symbol": "FUND",
            "name": "SOLFUND",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVAayYjzz8FuGGwu1g37F5im1T5foYgjnmC87cdAtFhk/Coinlogo.png",
            "tags": [
                "Fund",
                "Investments",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/solfund/"
            }
        },
        {
            "chainId": 101,
            "address": "Dn7UyZBD99Jw2NuDXmij5Ao34LmxNhLaPDAzayasaCjB",
            "symbol": "ART",
            "name": "ART Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dn7UyZBD99Jw2NuDXmij5Ao34LmxNhLaPDAzayasaCjB/Coinlogo.png",
            "tags": [
                "Art",
                "Investments",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/art-coin/"
            }
        },
        {
            "chainId": 101,
            "address": "7KUe1wcih2dnDi9q5QrrWAFzJLNKL19tpcNqBy5EbpCh",
            "symbol": "SULK",
            "name": "SulKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/boijoely/sulc/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FcepxdeiBJHzRGSd3Xs6V2WZyRa1HkunonLfK8rSAAAj",
            "symbol": "CCIRCLE",
            "name": "Champions Circle",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cv1s1on/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Pnvt8qDiMP96x5GHAQPYizgJzgfLJ2TPyTrhYfks6ZK",
            "symbol": "CWE",
            "name": "Cleanworld Energy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WayTo1Million/CWE/main/CW-Energy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hm1Q9s9gakYp3c6Jh3YqxGaxHJcUc7tCQH9jw6C2Mu5e",
            "symbol": "CREDIT",
            "name": "EURO CREDIT",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hm1Q9s9gakYp3c6Jh3YqxGaxHJcUc7tCQH9jw6C2Mu5e/Coinlogo.png",
            "tags": [
                "EURO",
                "guaranteed",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/euro-credit/"
            }
        },
        {
            "chainId": 101,
            "address": "EbmZ9fE3XxMUr1vEcjvrRqLJv3p9Zjs5fXeZBUGFgC7y",
            "symbol": "BOTX",
            "name": "botXcoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EbmZ9fE3XxMUr1vEcjvrRqLJv3p9Zjs5fXeZBUGFgC7y/logo.png",
            "tags": [
                "wrapped",
                "ethereum",
                "wormhole-v2"
            ],
            "extensions": {
                "coingeckoId": "botxcoin",
                "coinmarketcap": "https://coinmarketcap.com/currencies/botxcoin/",
                "instagram": "https://www.instagram.com/botxofficial/",
                "website": "https://www.botx.network"
            }
        },
        {
            "chainId": 101,
            "address": "GdeT55oxg67xcVB3CjkAZveDQo1rfz6XQD7icv8N1JKs",
            "symbol": "SOMT",
            "name": "Spirits of the Mountain Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/donaldlee725/som-pfp/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "6EwSsFYZFjfab1gMcinyqvZn5N19ByWaTWjRSUdkxemN",
            "symbol": "CREDIT",
            "name": "CREDIT SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6EwSsFYZFjfab1gMcinyqvZn5N19ByWaTWjRSUdkxemN/Coinlogo.png",
            "tags": [
                "Credit",
                "Guarantee",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/credit-sol/"
            }
        },
        {
            "chainId": 101,
            "address": "MAoMixaSaG7TkXNKQLaqfcK2VKxigePnEU7Vm89ezN5",
            "symbol": "MAO",
            "name": "MAO Tokendong",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KKQanT/token-list/main/assets/mainnet/MAoMixaSaG7TkXNKQLaqfcK2VKxigePnEU7Vm89ezN5/logo.png",
            "tags": [
                "test-run-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KKQanTT"
            }
        },
        {
            "chainId": 101,
            "address": "25CNQuwBrHNkCHUMbLUjfUcfGBrepGB6gw53LNsTGGZ9",
            "symbol": "STB",
            "name": "SolTee Balls",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/25CNQuwBrHNkCHUMbLUjfUcfGBrepGB6gw53LNsTGGZ9/logo.png"
        },
        {
            "chainId": 101,
            "address": "46ssKdgiiYZLy8d8m7gbKtpgo1q2D1jea9b2fniEzoWF",
            "symbol": "LABY",
            "name": "LabyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dqnns/LabyCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bwfe7DwmEDvjEBZGbQnDU8CrwZsuvYaed1VuQ8KDTGsS",
            "symbol": "XENO",
            "name": "The Xenobots Project",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bwfe7DwmEDvjEBZGbQnDU8CrwZsuvYaed1VuQ8KDTGsS/logo.png",
            "tags": [
                "XENO",
                "nft",
                "gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/xenobotsnft",
                "twitter": "https://twitter.com/XenobotsNFT",
                "website": "https://thexenobotsproject.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7GNyprqrpvJHNwzWSj4KVkqw9cKyV5aR3ehM2HeE5pw",
            "symbol": "Miku",
            "name": "Mikuko Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GNyprqrpvJHNwzWSj4KVkqw9cKyV5aR3ehM2HeE5pw/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/kirinkingdom",
                "twitter": "https://twitter.com/Kirin_Kingdom"
            }
        },
        {
            "chainId": 101,
            "address": "GD2QbNQPqr36RvXV8bWrhE18mdMLXiYY5438PH3wv6Yz",
            "symbol": "CWE",
            "name": "CleanworldEnergy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WayTo1Million/CWE/main/CW-Energy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Da1B9dCFi8im1KL9Gf3Uwmnq67SjgLdxtmZ8wpMVTwb3",
            "symbol": "VSN",
            "name": "Vesna",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pavelbrn/LinkData/main/IMGs/VesnaIconLogoPNG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5DKNgVCJyq5VeKQgqKoTh17ARFUCeFQVmrSFXH3mTpYS",
            "symbol": "SLACC",
            "name": "SLACC Whitelist",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SlaccWhitelist/uploads/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/slacc",
                "twitter": "https://twitter.com/SlaccSol",
                "website": "https://slacc.io"
            }
        },
        {
            "chainId": 101,
            "address": "CsbF4RjoWzKgMnQQRBqn5RgruLJt9DbgjBBsVTBEmtZg",
            "symbol": "TIX",
            "name": "Tickets",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/T3Toxic/Tickets/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GALLEZFD9GsKv7yqbqwavwuv2hqq34xqAsbPJUL8EsRC",
            "symbol": "Galleon",
            "name": "Galleon Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GALLEZFD9GsKv7yqbqwavwuv2hqq34xqAsbPJUL8EsRC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://mint.solanalysis.com/wiz"
            }
        },
        {
            "chainId": 101,
            "address": "Cnz4KS5VEogp9SCkZfXMYhBX6GUaeGBxZw5Lks3BMPY9",
            "symbol": "BANZ",
            "name": "Banz Bucks",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cnz4KS5VEogp9SCkZfXMYhBX6GUaeGBxZw5Lks3BMPY9/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Banz Carewear for Kids. Sensitive care. Sensible choice.",
                "facebook": "https://www.facebook.com/BANZCarewear",
                "instagram": "https://www.instagram.com/banzaustralia/",
                "twitter": "https://twitter.com/banzcarewear",
                "website": "https://www.banzworld.com"
            }
        },
        {
            "chainId": 101,
            "address": "GTgfkNU1jbSaHFLHdijzJ75s6TjLRZt5FsA1J7aQgJHS",
            "symbol": "DGCWL",
            "name": "Demigod Cats WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GTgfkNU1jbSaHFLHdijzJ75s6TjLRZt5FsA1J7aQgJHS/logo.png",
            "tags": [
                "dgc-wl-token"
            ],
            "extensions": {
                "website": "https://demigodcats.com"
            }
        },
        {
            "chainId": 101,
            "address": "7tHKMb727bjpjWS6xVU59aj8KCcsoBjiUVsZbgkrkGvv",
            "symbol": "NCR",
            "name": "Necro Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Necro-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "necro-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/necro_is_god",
                "website": "https://www.necrohiphop.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2dDpeiquywrXutiFJUduYTEkF9oorXFBdcUDWZ5HpxE7",
            "symbol": "ILLB",
            "name": "Ill Bill Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Ill-Bill-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "game-token",
                "meme-token",
                "ilbill-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/illb1ll",
                "website": "https://store.shopbenchmark.net/collections/ill-bill"
            }
        },
        {
            "chainId": 101,
            "address": "GCn5mmHAdas3QjW38GmXVL8BnEDjuytRRhQLxBGqghjr",
            "symbol": "SPDR",
            "name": "SpiderMan Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/SpiderMan-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "spiderman-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/spiderman",
                "website": "https://www.marvel.com/characters/spider-man-peter-parker"
            }
        },
        {
            "chainId": 101,
            "address": "CL1khybavkZhyKY1zQvMYGRqyxGxjATT5VxGg7wGENDn",
            "symbol": "ERTH",
            "name": "Earth Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MrEmeraldSlime/crypto/main/palette.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CtgocDbemGswwGezYT37aj3hax5VDVCzWcbMNgsHExB6",
            "symbol": "SDT",
            "name": "STARDUST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ScarceProject/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/polypursue",
                "website": "https://polypursue.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5WypLazEE66ARzDjAQrndirRoneWgTFDtKzoCvfHu5N8",
            "symbol": "DRAH",
            "name": "Drachma",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bouloutas/crypto/main/Drachma.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DsAc94BXEs9H4BKSE4CrApEBZ3faLDnGwjV7NYKgxiWg",
            "symbol": "SILN",
            "name": "Silent Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/joseraeiro/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BnJp2dd5zEQJKMd48Ac84Evd8ocPa2KpCfVmCG9PrFwi",
            "symbol": "SFD",
            "name": "SolFounder token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BnJp2dd5zEQJKMd48Ac84Evd8ocPa2KpCfVmCG9PrFwi/logo.png",
            "tags": [
                "social-token",
                "loop"
            ]
        },
        {
            "chainId": 101,
            "address": "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
            "symbol": "T1NY",
            "name": "Tiny Bonez",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/K3rqQVxDdX",
                "twitter": "https://twitter.com/TinyDogzNFT",
                "website": "https://www.tinydogz.com"
            }
        },
        {
            "chainId": 101,
            "address": "3x7qXFaXtgjzwuvPwHrjE7W6JYj2XvjNqi28eWvJec2D",
            "symbol": "LVT",
            "name": "Leviat Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3x7qXFaXtgjzwuvPwHrjE7W6JYj2XvjNqi28eWvJec2D/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://leviatlegal.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AuGVGA7zu8AnMnedB28gnxJXkNvzPZS6u2GMDtbRJ5HM",
            "symbol": "DUMPS",
            "name": "Dumps Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DumpsandPumps/dumpstoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6naP1Ajk5qpnxtK43aPEpJLJvawZ8TuVgnoBnCiLqa4C",
            "symbol": "TOOLS",
            "name": "Tools Token",
            "decimals": 3,
            "logoURI": "https://arweave.net/qeTFDPRfbxC1bTYczjMSYMOunAUbDN5CE4KBf2cY9bA",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolRobos",
                "website": "https://solrobos.com/"
            }
        },
        {
            "chainId": 101,
            "address": "yxTU2PghnfPgcLiUSamfiKWufLtRyQAEUsqoG45kMjm",
            "symbol": "GLIDE",
            "name": "Glide",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tsbsoltn/soltn/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://glidecoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9NcHuQFt8AyW4ryPMwDsufZxeL4kPHDAaMPwwZRgXKP3",
            "symbol": "MCLAU",
            "name": "MCLAU Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mclau79/logo/main/crypto2.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DVXXemgYhnsXkoXnXCMPoEesSys2AgE6qvT2xzzLWAAP",
            "symbol": "TOTO",
            "name": "TotoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tomcastigl/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9XczSh86n8XKkDuL4yK93afRHgSifui9j9sFPHQLfFUT",
            "symbol": "EGGZ",
            "name": "Dapper Ducks Eggs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XczSh86n8XKkDuL4yK93afRHgSifui9j9sFPHQLfFUT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "$EGGZ are the utility token for the Dapper Ducks Universe use to purchase a multitude of items from The Pond.",
                "discord": "https://discord.gg/DapperDucks",
                "website": "https://www.thedapperducks.com"
            }
        },
        {
            "chainId": 101,
            "address": "9R6a6XQjY8U7j8ae1Gd5GVcrrjtPDRSbgeEYmmHVdVpQ",
            "symbol": "BBTC",
            "name": "BuBasTeamCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AntMar23/BubasTeamCoin/main/BBTC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "14oGis8Lv2y8FEX8MdmUz9g48hpLY7cEKR4BRYxHbUAQ",
            "symbol": "$BNN",
            "name": "Bananas",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/14oGis8Lv2y8FEX8MdmUz9g48hpLY7cEKR4BRYxHbUAQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "ANtz84NtpCmMtr2smcXw1YUKtPGhgUrvLdb5cF2pN64D",
            "symbol": "BAT",
            "name": "Batman Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Batman-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "batman-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dcbatman",
                "website": "https://www.thebatman.com"
            }
        },
        {
            "chainId": 101,
            "address": "9kU82wzvUn4uLXxRW2rd2MwqJcZRrBEtotatx81t9GJd",
            "symbol": "CTE",
            "name": "Cabbage To Earth Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SGB1986/Cabbage/main/273467447_654316445879810_8753904468743291567_n.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DxEmFstA2MDKsSpDngDG1AbJpAc4gPXXcXMZ81tKCBoj",
            "symbol": "DrP",
            "name": "DoctorP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/iddoc2022/crypto/main/DrP.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6WP1cizTmefsGBhKu2Fyb2XLS7G29h1ktNp6NWD628FB",
            "symbol": "INTRODUCTION",
            "name": "Gezondheid Adviseur",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6WP1cizTmefsGBhKu2Fyb2XLS7G29h1ktNp6NWD628FB/gezond.png",
            "tags": [
                "gezondheid-adviseur",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/gezondheid-adviseur/"
            }
        },
        {
            "chainId": 101,
            "address": "WNhMkZGcWM9WbzkLqWGJb6DdfCcCW2JwEHbPRPjTHfq",
            "symbol": "COMPANY",
            "name": "Zeeland Homes BV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WNhMkZGcWM9WbzkLqWGJb6DdfCcCW2JwEHbPRPjTHfq/blockstonePNG.png",
            "tags": [
                "Company",
                "Zeeland-Homes-BV",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/zeeland-homes/"
            }
        },
        {
            "chainId": 101,
            "address": "3tp5LKhVc6ZaQ9aXNA4ufxNMnoz7aWK7NRRn4cxThY3e",
            "symbol": "SlyAF",
            "name": "SlyFox Governance Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ProCoreNFT/token-list/main/assets/mainnet/3tp5LKhVc6ZaQ9aXNA4ufxNMnoz7aWK7NRRn4cxThY3e/logo.png",
            "tags": [
                "Utility-Token",
                "Community-Token",
                "social-token",
                "Governance-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/g9QaNkk6HW",
                "website": "https://www.sly-fox.io"
            }
        },
        {
            "chainId": 101,
            "address": "F3cnJDxJyVoZVB3RY6bGvHo3vPnzeupCjKK3jTBRigd",
            "symbol": "GDC",
            "name": "GourdeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/carleintech/GourdeCoin/main/GourdeCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H5iiBHeCGKkzucX5PwEn2cf2uWHyVnAg3aq5RRsWF5P8",
            "symbol": "TIKI",
            "name": "Tiki Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H5iiBHeCGKkzucX5PwEn2cf2uWHyVnAg3aq5RRsWF5P8/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "$TIKI is utility token that powers Tiki DAO, a sub community of The Chimpions.",
                "discord": "https://discord.gg/thechimpions",
                "twitter": "https://twitter.com/TikiDAO_"
            }
        },
        {
            "chainId": 101,
            "address": "wyM6zDsEjuNrKmLHQtxfXADgwwDxbyxUKG2zd3ch7GN",
            "symbol": "SHARES",
            "name": "Zeeland Homes shares",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/wyM6zDsEjuNrKmLHQtxfXADgwwDxbyxUKG2zd3ch7GN/blockstonePNG.png",
            "tags": [
                "Zeeland-Homes-BV",
                "shares"
            ],
            "extensions": {
                "website": "https://explorer.solana.com/address/WNhMkZGcWM9WbzkLqWGJb6DdfCcCW2JwEHbPRPjTHfq"
            }
        },
        {
            "chainId": 101,
            "address": "GP9zY2D8CgMreoUdYQjyn7Fo7XCq9ubVnX3u4ot1wpgt",
            "symbol": "DPD",
            "name": "DPD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GP9zY2D8CgMreoUdYQjyn7Fo7XCq9ubVnX3u4ot1wpgt/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DuckPatrolNFT",
                "website": "https://duckpatrol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "H1bwyQiWQL7wWkcVKbp7NMQtXH2RaxABf1WgswYuKvVk",
            "symbol": "DTT",
            "name": "Dust Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TheReaperQc/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DxCEtxi7dkTnGhypntokEuAAHdSn7n1oqtUMMYAAgfhb",
            "symbol": "HJAL",
            "name": "HJALTUN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DxCEtxi7dkTnGhypntokEuAAHdSn7n1oqtUMMYAAgfhb/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "HJAL is the token supporting Hjaltun's initiatives and its community. Hjaltun is one of the most innovative luxury stationery brands in Europe",
                "instagram": "https://www.instagram.com/hjaltunlondon",
                "twitter": "https://twitter.com/HjaltunLondon",
                "website": "https://hjaltun.com"
            }
        },
        {
            "chainId": 101,
            "address": "CyC1zVPGm9ARZYyxiv26Q5vm4vLQBv9n358gW4PB6oCW",
            "symbol": "NEFT",
            "name": "NeftWorld Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/neftworld/assets/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JAfdyspue7J3GxWg9Gc1UWDJBqvMFDpfS2KMKWNW6dGh",
            "symbol": "NASA",
            "name": "Nasa Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/NASA-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "nasa-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nasa"
            }
        },
        {
            "chainId": 101,
            "address": "6imwhega2b1AcDhbuY1mgQnJdoGdKoqfmX4tUTr6F8t9",
            "symbol": "NIKE",
            "name": "Nike Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Nike-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "nike-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nike",
                "website": "https://nike.com"
            }
        },
        {
            "chainId": 101,
            "address": "2HyFkH3hvpj5PCGwYAaBVXGkgD1s2bCnkiGBv2ksVm4E",
            "symbol": "IG",
            "name": "Instagram Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Instagram-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "instagram-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/instagram",
                "website": "https://about.instagram.com"
            }
        },
        {
            "chainId": 101,
            "address": "92G96KqHG898Mr3nnW9ZCgnRkQComxq3RNPXyvRWrzcb",
            "symbol": "BSCH",
            "name": "Bosich",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EigeneDateien/BSCH/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8V6EsUxArHkt37LphQ4iPVtn4zie5xomeLPAqYbysryy",
            "symbol": "BLOCKSTONE",
            "name": "Lawyers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8V6EsUxArHkt37LphQ4iPVtn4zie5xomeLPAqYbysryy/blockstonePNG.png",
            "tags": [
                "Blockstone-lawyers",
                "Solana"
            ],
            "extensions": {
                "website": "https://blocksto.ne/lawyers/"
            }
        },
        {
            "chainId": 101,
            "address": "4SksTiX5Qh33FaoKp9AhjzpVH68dEobL18GGb44M6DJ6",
            "symbol": "BLOCKSTONE",
            "name": "Lawyer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SksTiX5Qh33FaoKp9AhjzpVH68dEobL18GGb44M6DJ6/blockstonePNG.png",
            "tags": [
                "Blockstone-lawyers",
                "Solana"
            ],
            "extensions": {
                "website": "https://blocksto.ne/lawyers/"
            }
        },
        {
            "chainId": 101,
            "address": "2GsktBGZtWC5tU2wHH5RaBvPZhVXRWrGC5Qe5sEKnBzs",
            "symbol": "BEEF",
            "name": "CobacCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Abbyigor/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FAHES5y9chrtEvAFt6Ma1RJJgnfXjF3Toy2vThxLZHUN",
            "symbol": "OCA",
            "name": "CluckCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/12inchT/Crypto/main/PunkyLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D3TbFDiwkLrMKvkEADaN43BV2otE8oMQU6G1n9UdD9m2",
            "symbol": "TAUSS",
            "name": "Tauss Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/scsezgin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5GofNCpdDpz2wNEaSYYB8rf6m4CaNRvfHLAxd3PKmnt9",
            "symbol": "BULLY",
            "name": "Bully",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/viaadler/crypto/main/logo.png",
            "tags": [
                "meme-token",
                "utility-token",
                "stake-pool-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/bullytoken",
                "twitter": "https://twitter.com/bullytokennnnn"
            }
        },
        {
            "chainId": 101,
            "address": "GSBCr3LLkfMu99iJhiwERFdWzuFArqoV93og13pWc9mX",
            "symbol": "LXCD",
            "name": "LEX CLOUD DATA",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/lexthoms411/Lex-cloud-Data/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "LXCD is utility token used by members of Lex Cloud Data at www.homenetlink.com. This is still a work in progress"
            }
        },
        {
            "chainId": 101,
            "address": "AmK2B4obE93TmqMgHCmmnYUXm3gbtEiu3EMxPttVcwk6",
            "symbol": "WLTLA",
            "name": "TLA Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AmK2B4obE93TmqMgHCmmnYUXm3gbtEiu3EMxPttVcwk6/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GqVKfEuAyiehrSTCg5fcsNpAcsFxUNb1AYCnRwn88NPL",
            "symbol": "TLA",
            "name": "TLA Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GqVKfEuAyiehrSTCg5fcsNpAcsFxUNb1AYCnRwn88NPL/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CcAdUqAzCiAQYAGkD4jveD31uKm9g6csQUFiRHuAbwNu",
            "symbol": "RKONGZ",
            "name": "Reversed Kongz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/klaydevelop/reversed-kongz-metadata/main/logo/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "49mNzFzMh2i7kApyeQiVdLYjt5jasc9Umjj2An49EtV9",
            "symbol": "PLAGUE",
            "name": "Plague Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/49mNzFzMh2i7kApyeQiVdLYjt5jasc9Umjj2An49EtV9/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D3eyBjfgJMPHZyYDRtbf1cSxeLiNwKumwHzQK3h3TRRq",
            "symbol": "BONE",
            "name": "BONE",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/95661911/153796714-8061210f-ebef-4db9-a646-ab8dc6b57e0f.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/bulldogbillionaires",
                "twitter": "https://twitter.com/bbsolana",
                "website": "https://www.bulldogbillionaires.club/"
            }
        },
        {
            "chainId": 101,
            "address": "5C9gnZEjaDVpaQjJcRSf54pJv3g1JVACo32jBRvNxpm4",
            "symbol": "ANGELS",
            "name": "Solana Angels",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5C9gnZEjaDVpaQjJcRSf54pJv3g1JVACo32jBRvNxpm4/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AngelsOnSolana"
            }
        },
        {
            "chainId": 101,
            "address": "4xDPH7DVtDXA2eU6wp9BjhryfXEdxBuhe4hnEc9yz1pJ",
            "symbol": "BOLT",
            "name": "Bolt Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4xDPH7DVtDXA2eU6wp9BjhryfXEdxBuhe4hnEc9yz1pJ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/skullbots",
                "twitter": "https://twitter.com/SKULLBOTS",
                "website": "https://www.skullbots.io/"
            }
        },
        {
            "chainId": 101,
            "address": "LbrZyD19NVCKQo2C7M6Nq2pfck1spMwE5FUxThcwDWt",
            "symbol": "HNZ",
            "name": "HONEYZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LbrZyD19NVCKQo2C7M6Nq2pfck1spMwE5FUxThcwDWt/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/saDmFdp6ar",
                "twitter": "https://twitter.com/LazyBearZNFT",
                "website": "https://lazybearz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3MAy18swqBKioU1GRThqixeVi6K6YEK4fBeZiK66fLs6",
            "symbol": "TTKN",
            "name": "TonyToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bone-meal/tonytokens/main/tony.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i",
            "symbol": "MALL",
            "name": "MetaMall",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://metamalls.io"
            }
        },
        {
            "chainId": 101,
            "address": "8bPoL3n7BemSatpnTZPq24anychqcB8uw6omdDPYQAvd",
            "symbol": "TSTRN",
            "name": "Test Run Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chatkilla/CRYPTO/main/O.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "32gaR4rn9JyzoDVwMzZ5j3NgcHc5RQhMSJby55FFKnq3",
            "symbol": "RIBBET",
            "name": "RIBBET",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/32gaR4rn9JyzoDVwMzZ5j3NgcHc5RQhMSJby55FFKnq3/logo.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metafrogbizclub",
                "website": "https://www.metafrogbusinessclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BaSkmM2e6dY8aC4oe8Rh4B7L4bNG4tjtKSinVfjfoCRK",
            "symbol": "BOTOX",
            "name": "BOTOX Wellnes and Healthcare",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/rockyrocksrocky/BOTOX/main/Artboard%201%20copy%2027small.png",
            "tags": [
                "Healthcare"
            ]
        },
        {
            "chainId": 101,
            "address": "FWRP3RHMYHNL5jQa1dpecYdrT8PkpJr8fsq2WsFvDUyr",
            "symbol": "DVRV",
            "name": "DevRev Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DevReV11/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "stKrFPSwdR36jvsxLWsFVVKXABXaWUSFFFxNri4gPmH",
            "symbol": "SPGJR",
            "name": "Offspring Junior",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdLycDiJH5se75GZyxEdEdo6c3z7WHkoHZmvbkibUUrS/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DpxMKwKMrM9mBExbttc9SrCAqy9Nx9MAX3Ha8kWkHtBr",
            "symbol": "TFNFT",
            "name": "The Frenchies",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DpxMKwKMrM9mBExbttc9SrCAqy9Nx9MAX3Ha8kWkHtBr/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "5yw793FZPCaPcuUN4F61VJh2ehsFX87zvHbCA4oRebfn",
            "symbol": "RICE",
            "name": "RICE",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/kakasonz112/robocock-token/main/assets/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/robococknft",
                "twitter": "https://twitter.com/RoboCockNFT",
                "website": "https://robocock.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5ZDkTmkX7z1VEufQHrkY3M4k1w1H8iEc7MvyePNSotZ8",
            "symbol": "TCFT",
            "name": "CFT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5ZDkTmkX7z1VEufQHrkY3M4k1w1H8iEc7MvyePNSotZ8/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.codesfortomorrow.com"
            }
        },
        {
            "chainId": 101,
            "address": "BYDCPWeACSG9XaDDPqqxSC1pLnKjTwxXcSva3UYs8zJP",
            "symbol": "GG",
            "name": "Simo GG Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Simo-Gigi-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "8XGRbcc3mXQ9Pe2bhjoRRuDypA5JcyMpBNtmQoyTeeJd",
            "symbol": "KHB",
            "name": "khaby Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Khaby-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "khaby-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/khabylame",
                "website": "https://khabyshop.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7gsvY4yjAVrP7fwBBMjyVCJNBRMRHgotjFfqrNPDKJec",
            "symbol": "MC",
            "name": "Magic Carrot",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gsvY4yjAVrP7fwBBMjyVCJNBRMRHgotjFfqrNPDKJec/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/solderland",
                "website": "https://solderland.com/"
            }
        },
        {
            "chainId": 103,
            "address": "6rJSjCEVxovip8GBUw6P7tsQprzFPET3uTohCXXQqkBh",
            "symbol": "wtUST-USDT",
            "name": "wtUST-USDT LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6rJSjCEVxovip8GBUw6P7tsQprzFPET3uTohCXXQqkBh/logo.png",
            "tags": [
                "ratio-token"
            ],
            "extensions": {
                "website": "https://dev.ratio.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "BkpqyoDe5mwN6DiH1MYSJ1G4AbhPuiZsycjjYfQcWK9P",
            "symbol": "USDC-USDT",
            "name": "USDC-USDT LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BkpqyoDe5mwN6DiH1MYSJ1G4AbhPuiZsycjjYfQcWK9P/logo.png",
            "tags": [
                "ratio-token"
            ],
            "extensions": {
                "website": "https://dev.ratio.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "ASnVcQxNRosGw8crN8E4ScnDncBfnR1eJPyzucKsM4Vm",
            "symbol": "wUST-USDC-USDT",
            "name": "wUST_v1-USDC-USDT LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASnVcQxNRosGw8crN8E4ScnDncBfnR1eJPyzucKsM4Vm/logo.png",
            "tags": [
                "ratio-token"
            ],
            "extensions": {
                "website": "https://dev.ratio.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "2gsojBCyZUgqXEj5vR41sKat3JyG11nXSsgzMErY9EVL",
            "symbol": "USDC-CASH",
            "name": "USDC-CASH LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2gsojBCyZUgqXEj5vR41sKat3JyG11nXSsgzMErY9EVL/logo.png",
            "tags": [
                "ratio-token"
            ],
            "extensions": {
                "website": "https://dev.ratio.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "38tKCEuuLfUzCoZZFgsiGTq9wi8Dq3YykrSDvKjx7caE",
            "symbol": "SGEM",
            "name": "GemsLab",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38tKCEuuLfUzCoZZFgsiGTq9wi8Dq3YykrSDvKjx7caE/sgem.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/gemslab_space",
                "website": "https://gemslab.space"
            }
        },
        {
            "chainId": 101,
            "address": "BNpHCaRDPepoUPyXrTMvjTkrcfnwFmuRaXHgqbdjauyE",
            "symbol": "CUX",
            "name": "BestBluiAlways",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hemanthgade/CuxCrypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9QPeHFjjyw4jy9GDzs3RvWxWJuwpKBj6RzQpEnwBuAnR",
            "symbol": "BITS",
            "name": "Bits",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9QPeHFjjyw4jy9GDzs3RvWxWJuwpKBj6RzQpEnwBuAnR/logo.png",
            "tags": [
                "social-token",
                "gaming-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/XbzMd9uXR4",
                "twitter": "https://twitter.com/ParleySolana",
                "website": "https://gameplex.live/"
            }
        },
        {
            "chainId": 101,
            "address": "FNqmjdUXyXGjAnKj9Kwi61qp6nVBXCFAm46cU4pP14aN",
            "symbol": "MMACWL",
            "name": "MMAC Whitelist token",
            "decimals": 0,
            "logoURI": "https://arweave.net/aU0UWqYpoQx9o3tWxqAdNdvZUa1_THlUDSUwDQuACyA",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://www.miniapenft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2NRKkmiqktNt6c3wdFEdzQMZDQ8KpbR4HU7MFk1Jaw7x",
            "symbol": "$BOIS",
            "name": "Bois Coin",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/679118561649623070/942573815513354300/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitch": "https://twitch.tv/gckluke"
            }
        },
        {
            "chainId": 101,
            "address": "EZZogRzGFPLZYQBoetqeQpsCqFvYYE4DyRsF1rxJwey1",
            "symbol": "VNM",
            "name": "Venom",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EZZogRzGFPLZYQBoetqeQpsCqFvYYE4DyRsF1rxJwey1/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/y42pXAFFVH",
                "instagram": "https://www.instagram.com/SlimeySnakeClub",
                "twitter": "https://twitter.com/SlimeySnakeClub",
                "website": "https://slimeysnake.club"
            }
        },
        {
            "chainId": 101,
            "address": "CSdwvtbfVw9VD3qjMC8EbWWyximyHUdL2enj836pvvFK",
            "symbol": "GEPV",
            "name": "Grow East Point Vegging",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cpatt2015/Crypto/main/vegging.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2bMdoyyxDeFx6H6cN2gvUStVP2NLqgU1Z2zpa3dFcxNt",
            "symbol": "TREX",
            "name": "Sexual Tyrannosaur Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SGB1986/TREX/main/Polish_20210218_030212109.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MetaDxGDwtNZwsbwrfERzBwhRdUhRVVjGU7HYuCTk8r",
            "symbol": "MetaD",
            "name": "Meta D Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MetaDxGDwtNZwsbwrfERzBwhRdUhRVVjGU7HYuCTk8r/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "The Meta D token is a social token used to promote digital technologies in modern BAS",
                "website": "https://www.johnsoncontrols.com/building-automation-and-controls/building-management/building-automation-systems-bas"
            }
        },
        {
            "chainId": 101,
            "address": "SRwhxeZEKPR66i6RyTqMLQHhAxBpfNdFkXcEd1q4dUz",
            "symbol": "SRPRESALE",
            "name": "SolRockers PreSale",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRwhxeZEKPR66i6RyTqMLQHhAxBpfNdFkXcEd1q4dUz/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "SolRockers Battle of the Bands is a next-gen PVE/PVP play to earn rhythm game built on the Solana blockchain.",
                "discord": "https://discord.gg/solrockers",
                "facebook": "https://facebook.com//solrockersnft",
                "instagram": "https://instagram.com/solrockersnft",
                "medium": "https://medium.com/@solrockersnft",
                "telegram": "https://t.me/solrockersnft",
                "twitter": "https://twitter.com/solrockersnft",
                "website": "https://solrockers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8o4FYKQMarEG9JP5hzbWF16pDjNahVyubz25bYDZCfMq",
            "symbol": "SAFESOUL",
            "name": "Safe Soul",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8o4FYKQMarEG9JP5hzbWF16pDjNahVyubz25bYDZCfMq/safesoul.png",
            "tags": [
                "community-token",
                "meme-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SafeSoulMeme",
                "website": "https://safesoul.info/"
            }
        },
        {
            "chainId": 101,
            "address": "4cSZkVz2S9qZqng58zd3gcMKmSZ864GAzcR8ezH1SHhw",
            "symbol": "COT",
            "name": "Colibri Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4cSZkVz2S9qZqng58zd3gcMKmSZ864GAzcR8ezH1SHhw/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/colibritoken"
            }
        },
        {
            "chainId": 101,
            "address": "3cUGVa1wVg9XiCSi4dhwjLR8A5T8FYnEyhNPBPkMbYDq",
            "symbol": "OOWL",
            "name": "Otaku Origins WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/matlirman/token-list/main/assets/mainnet/3CXVYitUaSMxifZKyiBRDT1TMcGrL1nJ2WfwdgnnvrKS/logo.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "website": "https://otakuorigins.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BWnE6wM3DYyVCMHxsiSxKfNwrJy4G6kcKpSYESGxZWKf",
            "symbol": "SWAG",
            "name": "Swag Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/heybevis01/crypto/main/swagcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AsU8cuPxynNeDoGetZ2HscoNkVmhz169f87tAWNb1LNN",
            "symbol": "TECH",
            "name": "Technology Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Technology-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.1dsmedia.com"
            }
        },
        {
            "chainId": 101,
            "address": "5an1UMFKA5gXGyhKJoqGawEiLao9Z3ymn1SBoXuNmFP",
            "symbol": "LING",
            "name": "LingLing Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Lingling-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "lingling-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Jnew8Gxbt6cWXA4s3HRqET228SLcHYHdDqAEbSchMnL",
            "symbol": "BASED",
            "name": "Based Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Scrump/basedcoin/main/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://thebasedcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "8bbQugNuT8hoUnaJ7xja5hCWgQsVgHK1hc74oXREC1Hq",
            "symbol": "A$",
            "name": "ACOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alro1991/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HhoEAbSPui6EncKntxdToz8cPPLtacgshfKXUjzRYBUr",
            "symbol": "ARX",
            "name": "Arx Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HhoEAbSPui6EncKntxdToz8cPPLtacgshfKXUjzRYBUr/logo.png",
            "tags": [
                "Arxolotl"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ARxolotl"
            }
        },
        {
            "chainId": 101,
            "address": "3HYVcsFMDWSyvoUQthCTJX2MW96ciVMkFEnoHhheNqL7",
            "symbol": "ODNR",
            "name": "ODNR Deer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RealKevin1960/Crypto/main/ohioDNR3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "hone3CJTYjczb5nJh45KCNMkjrKMt7SCnHkWGWsVfVu",
            "symbol": "HONE",
            "name": "Yokoito Crew Hone",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/hone3CJTYjczb5nJh45KCNMkjrKMt7SCnHkWGWsVfVu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kcYuqWu9Qr",
                "twitter": "https://twitter.com/yokoitocrew",
                "website": "https://yokoitocrew.org/"
            }
        },
        {
            "chainId": 101,
            "address": "56bFarytGNPXnA34WbghfHoHQJ7NqKSKyTSnfmACdYtk",
            "symbol": "KCRWN",
            "name": "Kingdom Crown",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56bFarytGNPXnA34WbghfHoHQJ7NqKSKyTSnfmACdYtk/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.kingdomofdwarves.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Ge59QW4WJMgi4esSWr6fy8nndhLhp9u9CtF8fBqcZjv5",
            "symbol": "USPC",
            "name": "United States Patriot Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Artenen/USPCoin/main/Patriot-Coin-Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DmR3GfYASk4nvLtm3SJrXZChS7cq7opvMnfvrXwik1z4",
            "symbol": "BUIDL",
            "name": "BUIDL Token",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmbqSmG9As6kvyzFZUQNAB3jGvzKaxYNz8uggJjR8F1LGe",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FxpkAfCjjYzRn5fL5JSYBmzAG73m9EgJpyv4FojMficF",
            "symbol": "USSR",
            "name": "La cosa nostra project",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/kartashowRoman/src/main/asset.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3rL1r2wp7B63fwFzMQuYCQUZgWWKXS576UNnpBZZxRqt",
            "symbol": "ATPK",
            "name": "AmeriKen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ekledzik/atpx/main/icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "YaUTyaugMnz5qxoJmL8s8kQYiVRHa7PBRLoU9tw4Nxg",
            "symbol": "EXO",
            "name": "EXOTIC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ShichibukaiMatt/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FjmhkS8tD9LzqU7nNNZKEjbZjQJFi8GuK3zSj7xBkTRE",
            "symbol": "BLOCKSTONE",
            "name": "FUND",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FjmhkS8tD9LzqU7nNNZKEjbZjQJFi8GuK3zSj7xBkTRE/blockstonePNG.png",
            "tags": [
                "DeFi",
                "Blockstone-fund"
            ],
            "extensions": {
                "website": "https://blocksto.ne/fund/"
            }
        },
        {
            "chainId": 101,
            "address": "GQ26R2DVjWPYZTeUCBZV7Hs9V1DBsVhCNunvP2MWLQgP",
            "symbol": "LOBO",
            "name": "Lobo Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GQ26R2DVjWPYZTeUCBZV7Hs9V1DBsVhCNunvP2MWLQgP/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lostaliensnft"
            }
        },
        {
            "chainId": 101,
            "address": "FB3UPHEXLGzf2CrnUENJ1iVdV7Jf8MN2Kzk9xTaojwNk",
            "symbol": "MMRB",
            "name": "Karatishan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SGB1986/mmrb/main/273881030_5268999106472407_2126765086870700326_n.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2YjFA4xNN3qaxpnVAciF7VvnzuF4GzbiNziu2uxRY9Wm",
            "symbol": "BLOCKSTONE",
            "name": "Ahome",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2YjFA4xNN3qaxpnVAciF7VvnzuF4GzbiNziu2uxRY9Wm/blockstonePNG.png",
            "tags": [
                "Real-estate",
                "Blockstone-fund"
            ],
            "extensions": {
                "website": "https://blocksto.ne/ahome/"
            }
        },
        {
            "chainId": 101,
            "address": "BiNGum7bxnvXVjQchN8g1mYzt1U1jzMB3Ym7PiWKU1h8",
            "symbol": "BLOCKSTONE",
            "name": "BARGE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BiNGum7bxnvXVjQchN8g1mYzt1U1jzMB3Ym7PiWKU1h8/blockstonePNG.png",
            "tags": [
                "DeFi",
                "Blockstone-fund"
            ],
            "extensions": {
                "website": "https://blocksto.ne/barge/"
            }
        },
        {
            "chainId": 101,
            "address": "DpP9baPNx9gaVDR9ez4Dc6Ty4UnCbtChrfw5WAyc7ei6",
            "symbol": "NCCoin",
            "name": "Night Club Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Night-Club/Coins/main/nigtclub.png",
            "tags": [
                "game",
                "coin",
                "game-coin",
                "game-currency",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/_Night_Club_",
                "website": "https://www.NightClub.nu"
            }
        },
        {
            "chainId": 101,
            "address": "ExZMffxDyrchC17bj4QLLHcAtnWwWSW5T8YJxi8ACBPv",
            "symbol": "BANG",
            "name": "Bang Energy Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Bang-Energy-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bangenergy",
                "website": "https://bangenergy.com"
            }
        },
        {
            "chainId": 101,
            "address": "AXaLS9CR2Xc16w7PuZFckqGcYmMuNdxyuDpDjYQVq8Z1",
            "symbol": "OZY",
            "name": "Ozymandias Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Ozymandias-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "Ozymandias-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7bJMr1AtyCewq5QKRPJHVKTQZVKoSRN6ekiuJTe93cdN",
            "symbol": "LANA",
            "name": "LANA",
            "decimals": 6,
            "logoURI": "https://i.imgur.com/KVmQyyK.png",
            "extensions": {
                "medium": "https://moonlana.medium.com/",
                "serumV3Usdc": "AcnAbXbc5YqEyYUksPbGuHR21SGFNDUdRm3xEvWHFN1Y",
                "twitter": "https://twitter.com/xMoonLana",
                "website": "https://moonlana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EJXx9KWMbUB8dNEaGLNJBkoMdrEgjfUAQ7ChzgkGFbxS",
            "symbol": "BLOCKSTONE",
            "name": "Art Fund",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EJXx9KWMbUB8dNEaGLNJBkoMdrEgjfUAQ7ChzgkGFbxS/blockstonePNG.png",
            "tags": [
                "Art",
                "AAA-Blockstone-token"
            ],
            "extensions": {
                "website": "https://blocksto.ne/art-fund/"
            }
        },
        {
            "chainId": 101,
            "address": "kai6Dt6612rNn9MaW1eXHAWngwfoqYKFEMyniuWmoWv",
            "symbol": "KAIJU",
            "name": "KaijuVerse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kai6Dt6612rNn9MaW1eXHAWngwfoqYKFEMyniuWmoWv/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "NFT"
            ],
            "extensions": {
                "description": "1313 Kaijus Wreaking Havoc On Solana",
                "discord": "https://discord.gg/kaijuverse",
                "twitter": "https://twitter.com/KaijuVerseNFT",
                "website": "https://kaijuversenft.com"
            }
        },
        {
            "chainId": 101,
            "address": "Hi4WKXqmeoNVz8Nf7pxCCQrdFfxdLDLGFudHXmV95oU9",
            "symbol": "ACRE",
            "name": "ACRE",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/2f7q7UN.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ractopia",
                "website": "https://www.ractopia.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5RyFNDFE6tadfNU7ftSSyXB1PCBQVbLf9EdY1WecHt2b",
            "symbol": "YAPS",
            "name": "Yapperson",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tokermaker/yippers/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DFdgWSHxSmhv8rvMkzaiZR7x5m83LFY9Pe2NP2vVEDDX",
            "symbol": "CGS",
            "name": "CryptoGas",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rcorales/cryptogas/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "83XVHzMWXfoMJt9aBuS4mWAUk5FPYkkMZWYMe7mQPc7o",
            "symbol": "OMEN",
            "name": "Omens of Olympus WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/83XVHzMWXfoMJt9aBuS4mWAUk5FPYkkMZWYMe7mQPc7o/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Whitelist token for a single Omen of Olympus.",
                "discord": "https://discord.gg/omensofolympus",
                "twitter": "https://twitter.com/omensofolympus",
                "website": "https://omensofolympus.com"
            }
        },
        {
            "chainId": 101,
            "address": "9Db4WyPxBGAj3V62Y2KGo757PYhq6QYxG2BZPkU8hDzV",
            "symbol": "MVT",
            "name": "Megaverse",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/MegaverseProject/Assets/main/MVT%20Logo.png",
            "tags": [
                "ethereum",
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "description": "Megaverse is a cross platform gaming project built on solana, where NFTs and GameFi meets the metaverse, enhancing P2E blockchain technology.",
                "discord": "https://discord.com/invite/2kveFZwnBU",
                "twitter": "https://twitter.com/MegaverseProj",
                "website": "https://www.megaverseproject.io"
            }
        },
        {
            "chainId": 101,
            "address": "EXDv3VAeWUYgkuoBPuZvfq4k5xdEJSfyzSNKoc9rkceF",
            "symbol": "CMCN",
            "name": "CamToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/greenapple1234/Crypto/main/C.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "399N5NddQ2rpRyUHku1UAmZzqUdanDhxe1kN9MnUBhQW",
            "symbol": "FWF",
            "name": "Framework Fortune Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/frameworkfortune/FWF-Graphics/main/logo.png.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FramewkFortune",
                "website": "https://www.frameworkfortune.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7TFkZszsBijKKdxhLn2PkTJsbBv8a9CfsB61LsSFQmnZ",
            "symbol": "PHORK",
            "name": "Phork Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TFkZszsBijKKdxhLn2PkTJsbBv8a9CfsB61LsSFQmnZ/logo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7JgZmhsfxVyV2Xgmgb34dx8kB3EDUSnBt9rSGdiaiiaD",
            "symbol": "BFCN",
            "name": "Billyfrog Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benb80/crypto/main/frogcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8qEvmjgVub3fEaxaEr6YkyPnWv5ZT2XQkFQTXaYSvJzN",
            "symbol": "JOWL",
            "name": "Juvot Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/juvenileotters/juvotlogo/main/juvot.png",
            "tags": [
                "stake-pool-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sCSPajmTZu",
                "twitter": "https://twitter.com/JuvenileOtters"
            }
        },
        {
            "chainId": 101,
            "address": "9v8HmhZi7y4cwbe7uHKkRP2eGMaf5m2u8FuzuNqo9JwZ",
            "symbol": "EKAM",
            "name": "Ekam Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ekamtoor/crypto/main/Ekam.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TopKewsMBtY6kM7SDJtWaW9SfmowqqPH2peEpsdMf3D",
            "symbol": "WKT",
            "name": "Witch Kingdom Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TopKewsMBtY6kM7SDJtWaW9SfmowqqPH2peEpsdMf3D/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5vfLU3vxRQj6v474imwFvoeWxqo4gKmXmRpQ5N1Qgpcy",
            "symbol": "SLACCMINT",
            "name": "SLACC Mint Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SlaccMint/uploads/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/slacc",
                "twitter": "https://twitter.com/SlaccSol",
                "website": "https://slacc.io"
            }
        },
        {
            "chainId": 101,
            "address": "8uvbcdvzVV8TMtmY5DGd7PrJT2bCKgWYYtQJ9iKnU8m8",
            "symbol": "SPC",
            "name": "Space Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/spacecash-solana/spacecash/main/spacecash.png"
        },
        {
            "chainId": 101,
            "address": "BgjhevUKQDZ28usZMEairXPctoFA3nqKM8A1j5YhHv7w",
            "symbol": "ANGRY",
            "name": "ANGRY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/matthewrx/token/main/angry_token_logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/angryroyale",
                "website": "https://angrybattleroyale.com/"
            }
        },
        {
            "chainId": 101,
            "address": "h4XNYWyefydaNiEZmECeutJtxQbyopZUSeWPFDJZtaL",
            "symbol": "PURR",
            "name": "Alleycats Collective Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/h4XNYWyefydaNiEZmECeutJtxQbyopZUSeWPFDJZtaL/purr_coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/v6wJMQhArX",
                "twitter": "https://twitter.com/accsolana"
            }
        },
        {
            "chainId": 101,
            "address": "DbiLGsmv5FbgzipdpvEMFS1Zv7cHpVFZj7QEYvzRVAyq",
            "symbol": "COLORCUBES",
            "name": "Random Color Cubes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DbiLGsmv5FbgzipdpvEMFS1Zv7cHpVFZj7QEYvzRVAyq/logo.png",
            "tags": [
                "random-color-cubes",
                "one-of-a-kind"
            ],
            "extensions": {
                "website": "http://randomcolorcubes.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "pBGDfRAWPo15JaTXQVQ8dTYKgaLkG8ywzbgrueGMu7L",
            "symbol": "LFC",
            "name": "LongFlexCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/longflex/crypto-logo/main/cryptologo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CvPpTR27jiLS6zqKxRyxnyjgjmeUA3xrfWokhMZs5EaE",
            "symbol": "DUBS",
            "name": "DUB Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bagheadfan/dub/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AwdLhnYKHsA53KYVXFhsRAfnrPbAgPMjUKPoMQ2L6VM",
            "symbol": "GBLN",
            "name": "Goblin Metaverse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AwdLhnYKHsA53KYVXFhsRAfnrPbAgPMjUKPoMQ2L6VM/logo.png",
            "tags": [
                "utility-token",
                "governance-token",
                "metaverse",
                "community-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/goblinarmy",
                "instagram": "https://instagram.com/goblinmetaverse",
                "twitter": "https://twitter.com/GoblinArmyNFT",
                "website": "https://goblinmetaverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "3E3mksyXdcAtCY2zmoniGxgowhWPjn3B93MVbqbgsyLE",
            "symbol": "BRHM",
            "name": "BRAMHA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vivekdnaik/bramha/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6VgdRyC2ft9MTKzhWBpxy1aemyucdjKtu7a4cxDui29y",
            "symbol": "Yabu12344",
            "name": "TestYabu12344",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WayTo1Million/test/main/Yabu12344.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6kcXW5DZjF31jNZKpJ6XLYwqVsn7HdkoVLSJUnogma9U",
            "symbol": "MWLT2",
            "name": "Mutant WL2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HFaour/token-list/main/assets/mainnet/6kcXW5DZjF31jNZKpJ6XLYwqVsn7HdkoVLSJUnogma9U/new.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/catcartel",
                "twitter": "https://twitter.com/SolanaCatCartel",
                "website": "https://catcartel.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2xcDAxtrSJ9hv2qaS4dDWGTv78NHuu8FWV6j6ZdwQJmW",
            "symbol": "LAD",
            "name": "LAD",
            "decimals": 4,
            "logoURI": "https://user-images.githubusercontent.com/95661911/154075037-3f7946d4-8b73-42be-9ee9-08c21095d99e.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "Play-to-earn",
                "Metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "GaSt1Lqv7oept4bfBipgPnY7T5f1rG6tu8sgpWYxVtFT",
            "symbol": "GJJ",
            "name": "Vrouw met waaier",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GaSt1Lqv7oept4bfBipgPnY7T5f1rG6tu8sgpWYxVtFT/waaier.png",
            "tags": [
                "Geert-Jan-Jansen",
                "stijl-van-Klimt"
            ],
            "extensions": {
                "website": "https://blocksto.ne/nft-gustav-klimt-by-geert-jan-jansen/"
            }
        },
        {
            "chainId": 101,
            "address": "WLPkasQaeBjePs6uED7MQsQiES41aANWfqqAxc5Y8CP",
            "symbol": "PPWL",
            "name": "Plague WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PlaguePixel/icon/main/WL%20Plague.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7JHZiCNojnGedEV9E7nyvJBuaqnGhX8U4iw2o3K6kia",
            "symbol": "XLO",
            "name": "XLO Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Link125/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GHjgZH9oVEoDyyRGXGRmALdhAVh3Bz9fhvdaVea2BiK2",
            "symbol": "LBH",
            "name": "LOSBRANLOSHERMANOS Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/izetrom/token/master/logo.png"
        },
        {
            "chainId": 101,
            "address": "GD3rXfcnCf8CNpFcqwG67cXa3ree9KrfGBZMV5gjutpu",
            "symbol": "KOIN",
            "name": "Koin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Itschotsch/koin/main/Koin.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "549vG4KMhxe3cMVMJ7YRPNC2nj9HTBv8CZhBw85wdJgj",
            "symbol": "UBMS",
            "name": "Unplugged BMS Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/unplugged-bms/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6GK4nhV3h2RDKmjY4u43N3HJWNs6nYSB1qSBnsj6a2px",
            "symbol": "SFC-BATTERY",
            "name": "Solana Faction Cards Battery Pack",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/faction-cards/assets/main/images/coins/SFC-BATTERY/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Solana Faction Cards enables staking, governance and cross-chain multiverse.",
                "discord": "https://discord.com/invite/Wkjp3DhJuZ",
                "github": "https://github.com/faction-cards",
                "serumV3Usdc": "7uHeXXknyws9iazNGh5JZjfqFFCKeihAYcgzSW3AfqkN",
                "twitter": "https://twitter.com/faction_cards",
                "website": "https://faction.cards"
            }
        },
        {
            "chainId": 101,
            "address": "AoYS2EzUPNKWSm4BH4bvzL16hA76XSmCkxgftxojVgLA",
            "symbol": "SFC-PRESALE",
            "name": "Solana Faction Cards Pre-Sale Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faction-cards/assets/main/images/coins/SFC-PRESALE/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Solana Faction Cards enables staking, governance and cross-chain multiverse.",
                "discord": "https://discord.com/invite/Wkjp3DhJuZ",
                "github": "https://github.com/faction-cards",
                "twitter": "https://twitter.com/faction_cards",
                "website": "https://faction.cards"
            }
        },
        {
            "chainId": 101,
            "address": "9yL9k6WivrPkwjbVQ8e4PiSM9SqzJfB8H1GKif7ZkXLJ",
            "symbol": "FLOCKA",
            "name": "Flocka Coin",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh//averyschwarz/myrepo/FlockasLogo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DycVsKpUdxEkWgN6YhPGNJTpNdT2BoTwbVLkfYWZe4cz",
            "symbol": "WAVE",
            "name": "The WAVE Forumla",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/karlmalik/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "34KUJBBZ1Xbud6NYcbyX25pw7TpkeUG2PJS8Paq3fcsM",
            "symbol": "BENZ",
            "name": "benz",
            "decimals": 8,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmR5FQVrGg7ySX3BXxPsMzPrBR2j2RWnDKVavReKbKV79W",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3qFnHUwFnEiheKXJwKwSNnZdFK5bsCmSEUUzPtc8q1hT",
            "symbol": "BB",
            "name": "Bagbucks",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/EthanProvostMcCallie/Bagbucks/main/unknown.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9E1pBgmP2UeNyCyQuxTPaBAmxb4nU62BQRDuwZeRQJCF",
            "symbol": "UAHS",
            "name": "UAH Stable",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9E1pBgmP2UeNyCyQuxTPaBAmxb4nU62BQRDuwZeRQJCF/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "8qkzk7WQHj7M6poVbQtbgwyhM9qLTE2zXaJNaycsngF3",
            "symbol": "ETTM",
            "name": "Entertainment Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nn9uy3n0326/cypto-logo/main/ETTM1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EZq9JtPQDRJ54Vyp1T8rnbwXU9CDBcjJeZE6jLCjbn1D",
            "symbol": "BUILD",
            "name": "BUILD Token",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmbqSmG9As6kvyzFZUQNAB3jGvzKaxYNz8uggJjR8F1LGe",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BuzXJtMchXztGFHMpVtPCBrmRYHMS83TQ7sVCqXJKgvL",
            "symbol": "DBOC",
            "name": "Donnie Barko",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JMtechdev/crypto_token_test/main/donnie-barko.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TobhYvEGedQnGWvr1Hge75mepe55xZ9vpKde885HsRV",
            "symbol": "CSCWL",
            "name": "CSC WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TobhYvEGedQnGWvr1Hge75mepe55xZ9vpKde885HsRV/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToDFFVGdRGnxBXpiDX3sjouiQCUpgyKQcSzoHskDX27",
            "symbol": "SE",
            "name": "Solana Express WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/krikkkk/setokenlogo/main/rsz_1logo.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HnbycWsUBp4sVS8D8NrBxDiMxPT3wu9ndoNCyTEiG5TP",
            "symbol": "LTR",
            "name": "Letter",
            "decimals": 4,
            "logoURI": "https://github.com/letter/brand/raw/main/Normal.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/letterbanking",
                "website": "https://letter.co"
            }
        },
        {
            "chainId": 101,
            "address": "H6Jz41jUjNV7kNDBQuvJqNnpEYhGF6wJ5DR8bMLZDqvR",
            "symbol": "PALM",
            "name": "Palm Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Alsabbagh16/PALM/main/2%20colors%20512px.png",
            "extensions": {
                "twitter": "https://twitter.com/palmtok"
            }
        },
        {
            "chainId": 101,
            "address": "DkGCSjkUHKDPM1hjcMM8dGNDPnf5nrQU8fvsqvQkVixx",
            "symbol": "BRZL",
            "name": "Brezel",
            "decimals": 6,
            "logoURI": "https://brezel.io/img/brezel.svg"
        },
        {
            "chainId": 101,
            "address": "53jjZtfmvFDKpWaHj1Z9u53d45eAVk29xrwG2s89r57E",
            "symbol": "EGL",
            "name": "Egl Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/14-8811-1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DbV44ix6fnBsR7Uf7uinmHqkMcEG81PjxP2ofCbBrUPa",
            "symbol": "SWAMP",
            "name": "The Swamp Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/papacroc/swamptokenofficial/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C6Kc3aLCTugfPcH1twxGAdiyWf9DsVjHdyABjXugufhL",
            "symbol": "PTS",
            "name": "Sol-e Tech Points",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C6Kc3aLCTugfPcH1twxGAdiyWf9DsVjHdyABjXugufhL/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/sole_nft"
            }
        },
        {
            "chainId": 101,
            "address": "D3jtAcY9u9EGRiJ7j9FA6Wa6pJNZzmDe29iKrXtWUkJh",
            "symbol": "NTRON",
            "name": "Neotron",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gvossos/crypto/main/neotron-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDnX2LC1bnP88VefMh3rZxdAEFvK1jEPVovb2CBQsbbs",
            "symbol": "PEW",
            "name": "Moose and cub currency system",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/fkDWTtg/us.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/n26UwdVy",
                "twitter": "https://twitter.com/solgats",
                "website": "https://solgats.io"
            }
        },
        {
            "chainId": 101,
            "address": "7TMc9ogd3woQHVNe6y49PyNcqZNtQkrjjNwLtcrKD1Nm",
            "symbol": "BBQ",
            "name": "BBQ Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gzparker/BBQCoin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.americanbbq.org"
            }
        },
        {
            "chainId": 101,
            "address": "Neofzr2yA4zKv7zs9DgANA332nD3tAsDBUuk7s2ab2n",
            "symbol": "NEO",
            "name": "NEO Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/UnlockDeFi/NEONEXUS/master/assets/tokens/neo/neo.svg",
            "tags": [
                "governance-token",
                "neonexus"
            ],
            "extensions": {
                "discord": "https://discord.gg/y76VcXQrZq",
                "twitter": "https://twitter.com/neonexus_world",
                "website": "https://neonexus.io"
            }
        },
        {
            "chainId": 101,
            "address": "8YNpPhbPabeZqHTdZsh5miXNqj4WFGPL1DwZbmb7AaxK",
            "symbol": "RGT",
            "name": "Royal Goats",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8YNpPhbPabeZqHTdZsh5miXNqj4WFGPL1DwZbmb7AaxK/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MaB6hjhfzt",
                "twitter": "https://twitter.com/RoyalGoatsNFT",
                "website": "https://www.royalgoats.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5S2uwGaiCYX4tXq9wfSH44SbXTQUF2n2sanJJ2o1djXr",
            "symbol": "KROBA",
            "name": "KROBA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RonxBrush/KROBA-TOKEN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Vjitrf9ZF7mmPKiyocSigH8HqWWSCAPYXAGPdzjvZGg",
            "symbol": "MFWL",
            "name": "Motor Flippers WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Vjitrf9ZF7mmPKiyocSigH8HqWWSCAPYXAGPdzjvZGg/motor-flippers.png",
            "tags": [
                "whitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "ENGUzXs1xoYxHm3NtvZL26NLwAWr2zkswnTNevfEUYqp",
            "symbol": "KLNW",
            "name": "KALA NETWORK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENGUzXs1xoYxHm3NtvZL26NLwAWr2zkswnTNevfEUYqp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/kala_network/",
                "telegram": "https://t.me/kala_network",
                "twitter": "https://twitter.com/kala_network",
                "website": "https://kala.network/"
            }
        },
        {
            "chainId": 101,
            "address": "8iKYLJseSsybWXEzVXSrZ8weSfYEwu7uFrWDbdsWgXYV",
            "symbol": "TNKR",
            "name": "TinkerKoin",
            "decimals": 0,
            "logoURI": "https://github.com/smian1/tinkerKoin/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4ja4BvHok5ANPDM5Z922Yb1XUx77T96JYFyWbFczhydP",
            "symbol": "YGL",
            "name": "Yagolnitzer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ja4BvHok5ANPDM5Z922Yb1XUx77T96JYFyWbFczhydP/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HVtfDhSpbuUQwXxEL6RmVEfEeWbpmfUt6Tbh8iRrosZP",
            "symbol": "OtakuOriginsWL",
            "name": "Otaku Origins WL 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/matlirman/token-list/main/assets/mainnet/HVtfDhSpbuUQwXxEL6RmVEfEeWbpmfUt6Tbh8iRrosZP/logo.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "website": "https://otakuorigins.io"
            }
        },
        {
            "chainId": 101,
            "address": "3LjPgBRjMXXdbhaK9t3qDS8yPHRnNeLfiRLNFqW9Awxz",
            "symbol": "$BNFT",
            "name": "BTCNFT Trademark BNFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3LjPgBRjMXXdbhaK9t3qDS8yPHRnNeLfiRLNFqW9Awxz/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "J5ikTGiq3SZm8HbVCC2srYRKsmSaoUYiPDcivP5W3uwg",
            "symbol": "BTNFT",
            "name": "BTCNFT Trademark BTNFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J5ikTGiq3SZm8HbVCC2srYRKsmSaoUYiPDcivP5W3uwg/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3t3UYQKWu3c2c3jUSnQ2PkRxD1PTkdrkWrBFhvmrr1ST",
            "symbol": "$BTNFT",
            "name": "BTCNFT Trademark BTNFT 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3t3UYQKWu3c2c3jUSnQ2PkRxD1PTkdrkWrBFhvmrr1ST/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2Bup23Wjs9Y8x51HbFNa2ChEAPTVz3SiEtSBdtCPKgne",
            "symbol": "BTCNF",
            "name": "BTCNFT Trademark BTCNF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Bup23Wjs9Y8x51HbFNa2ChEAPTVz3SiEtSBdtCPKgne/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EnagYUpV6BUhyAqkCggw3dbyqTo4aqctKMPK2FdSXNAq",
            "symbol": "$BTCNF",
            "name": "BTCNFT Trademark BTCNF 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EnagYUpV6BUhyAqkCggw3dbyqTo4aqctKMPK2FdSXNAq/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BTu5SVrUyuaZUywEi7yY7W8XtSDFyV42Joy3321aps2H",
            "symbol": "$BTCN",
            "name": "BTCNFT Trademark BTCN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTu5SVrUyuaZUywEi7yY7W8XtSDFyV42Joy3321aps2H/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8EnATyTT58dpqTbVEETzjrPvjHhZwUHugXhPbDQuDBuJ",
            "symbol": "NFTBT",
            "name": "BTCNFT Trademark NFTBT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EnATyTT58dpqTbVEETzjrPvjHhZwUHugXhPbDQuDBuJ/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7TYKDZ2TTkRmXbRyCqQvPCPCwPALv8me2xnH87U8vxr6",
            "symbol": "$NFTBT",
            "name": "BTCNFT Trademark NFTBT 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TYKDZ2TTkRmXbRyCqQvPCPCwPALv8me2xnH87U8vxr6/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8Z61yvRm9ADG3PScFP5UtCqZ1oJpy7iUDGDyddP1ZD9i",
            "symbol": "NFTB",
            "name": "BTCNFT Trademark NFTB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Z61yvRm9ADG3PScFP5UtCqZ1oJpy7iUDGDyddP1ZD9i/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9gWzac1i2wUfKrQbrY8RrwEqQU5QuUn7brr6w17gxeQ3",
            "symbol": "$NFTB",
            "name": "BTCNFT Trademark NFTB 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gWzac1i2wUfKrQbrY8RrwEqQU5QuUn7brr6w17gxeQ3/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DeviL3EJ6hU4abi7WqQKv3KfXRCqDhcn4GxkCMAWCAFf",
            "symbol": "SOULZ",
            "name": "Devil Games SOULZ",
            "decimals": 13,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DeviL3EJ6hU4abi7WqQKv3KfXRCqDhcn4GxkCMAWCAFf/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DevilsGameNFT",
                "website": "https://devilsgame.net"
            }
        },
        {
            "chainId": 101,
            "address": "NexuswHziJRTQ6WbmFaxLSMDZo3w7FYpMFViwAcEW3p",
            "symbol": "NEXUS",
            "name": "NEXUS Utility Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/UnlockDeFi/NEONEXUS/master/assets/tokens/nexus/nexus.svg",
            "tags": [
                "utility-token",
                "neonexus"
            ],
            "extensions": {
                "discord": "https://discord.gg/y76VcXQrZq",
                "twitter": "https://twitter.com/neonexus_world",
                "website": "https://neonexus.io"
            }
        },
        {
            "chainId": 101,
            "address": "DviJfSdN55meHWdyvxKTP4eFhbV9pFsrkxq6jkqEipk9",
            "symbol": "TGS",
            "name": "Tech Guy Station",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DviJfSdN55meHWdyvxKTP4eFhbV9pFsrkxq6jkqEipk9/logo.png",
            "tags": [
                "whitelist",
                "tech-guy-station"
            ]
        },
        {
            "chainId": 103,
            "address": "Hqk42zkSoxhKGZtnj8HpuxYroysRmMkEDUvC9TvQbzNu",
            "symbol": "AIR",
            "name": "Air",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/Air/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CMeBHBuvWby47zKN8iUyW8mam7tCqet5aLAoshva3fYQ",
            "symbol": "FTC",
            "name": "FourTwentyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/draganfourtwenty/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G1jPNVrjF6beKE88pDBmMZM72xsphw8x8n94xcPTG18R",
            "symbol": "CBETS",
            "name": "CSEBets",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CSEBets/crypto/main/csebetslogo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GdB5KVvYbmRR3qrxG1sjRMpBLKHb2rg1hzcRR3a3wMmv",
            "symbol": "CTMS",
            "name": "Cryptomos Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cryptomos000com/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3RXTenk2bvx5cTYY8JrN94EFi3AkYCz9tzJzE7oRhSgf",
            "symbol": "CHICK",
            "name": "TheChickenz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MarioProjects/chick-coin/main/coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8xCKSf2ba7HP2EwahXmeYqABcFTt6eyYkJZM9hcBZLdf",
            "symbol": "$BITNFT",
            "name": "BTCNFT Trademark BITNFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8xCKSf2ba7HP2EwahXmeYqABcFTt6eyYkJZM9hcBZLdf/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "D7nr4D5MSLGnyYLoKKe5GfujkcfLoHk6ksDi6FtrfxVs",
            "symbol": "BITNF",
            "name": "BTCNFT Trademark BITNF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D7nr4D5MSLGnyYLoKKe5GfujkcfLoHk6ksDi6FtrfxVs/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "D5GNycJ6kbppKcxsN3FV8ve7crTzAKioaFCh9hEEbVfc",
            "symbol": "$BITNF",
            "name": "BTCNFT Trademark BITNF 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D5GNycJ6kbppKcxsN3FV8ve7crTzAKioaFCh9hEEbVfc/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "F8hWTmvFrXFK8V6HRS5CEvrHa5rMLbahTBsfnLqr2FRc",
            "symbol": "BITN",
            "name": "BTCNFT Trademark BITN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F8hWTmvFrXFK8V6HRS5CEvrHa5rMLbahTBsfnLqr2FRc/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "527RUQgWm9wjuZqENJ1RTbNmsJ44vLQnZcxMoKKAtXet",
            "symbol": "$BITN",
            "name": "BTCNFT Trademark BITN 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/527RUQgWm9wjuZqENJ1RTbNmsJ44vLQnZcxMoKKAtXet/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5vXyUx7fGqpo1NJo2GNqaJuyFWQVU96nr8E2r7467Dhb",
            "symbol": "BCNFT",
            "name": "BTCNFT Trademark BCNFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5vXyUx7fGqpo1NJo2GNqaJuyFWQVU96nr8E2r7467Dhb/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7gevHSfhLtBj5tZ5iw8113J8Aft1A6aNzy2jKqkPJ2UR",
            "symbol": "$BCNFT",
            "name": "BTCNFT Trademark BCNFT 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gevHSfhLtBj5tZ5iw8113J8Aft1A6aNzy2jKqkPJ2UR/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FLZGQMEH3eUHhDrwtnDLmw48XqSovLsucgDY5mWuCctG",
            "symbol": "BCNF",
            "name": "BTCNFT Trademark BCNF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLZGQMEH3eUHhDrwtnDLmw48XqSovLsucgDY5mWuCctG/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9sBpeAnECieAgstSG2hqrKYMF66LzFAajBGpj2hbbPMy",
            "symbol": "$BCNF",
            "name": "BTCNFT Trademark BCNF 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9sBpeAnECieAgstSG2hqrKYMF66LzFAajBGpj2hbbPMy/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "55mkfwuUyuN6VScNMay4g3KSN2xVsbVuJ9mqb9puARzp",
            "symbol": "BCN",
            "name": "BTCNFT Trademark BCN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/55mkfwuUyuN6VScNMay4g3KSN2xVsbVuJ9mqb9puARzp/logo.png",
            "tags": [
                "btcnftcoin-com",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftcoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3GfdtDnQC6mjkdr9cEaSr9mjS2VnSYoVypQiT2PPRBch",
            "symbol": "ORO",
            "name": "Shizen Orochi - ORO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GfdtDnQC6mjkdr9cEaSr9mjS2VnSYoVypQiT2PPRBch/logo.png",
            "extensions": {
                "website": "https://www.shizenorochi.com"
            }
        },
        {
            "chainId": 101,
            "address": "FrFwnzVRLWics6WEixMupRC5GiPg7YaQuY4m5DWKCqEy",
            "symbol": "BEEF",
            "name": "Beefcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FrFwnzVRLWics6WEixMupRC5GiPg7YaQuY4m5DWKCqEy/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token",
                "meme-token",
                "food-token",
                "nft-token"
            ],
            "extensions": {
                "website": "https://www.beefcoin.info/"
            }
        },
        {
            "chainId": 101,
            "address": "6dGAtKBFYNB34ABHDHQV5jc3PXGD36eaxxgVMRakwgeY",
            "symbol": "TRNT",
            "name": "Trunte Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CosmosKrypto/trunte/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dmd4sH3U35Uy3rLGnkx4zVa7EnJAherqSbm9K7aHbfpc",
            "symbol": "SOLERA",
            "name": "Solera",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dmd4sH3U35Uy3rLGnkx4zVa7EnJAherqSbm9K7aHbfpc/icon.png",
            "extensions": {
                "website": "https://www.savagestotsys.com"
            }
        },
        {
            "chainId": 101,
            "address": "CS5tMuXYGR1cd2XnVQjoZ42Z1jWoW1Bu8ySdRBT99PDc",
            "symbol": "GEMS",
            "name": "SAINTxDMON - GEMS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CS5tMuXYGR1cd2XnVQjoZ42Z1jWoW1Bu8ySdRBT99PDc/logo.png",
            "extensions": {
                "website": "https://saintxdmon.io"
            }
        },
        {
            "chainId": 101,
            "address": "Bsms72AVaPVPByPUky9Jmh1UCFDwMYBZNMs9Mx55EipG",
            "symbol": "FOF",
            "name": "Future of France",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FutureFrance/fof-/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BCZZL8hK41QvuR2pg5WHp7JmQtPUsE7m3vNCm3LfQoti",
            "symbol": "KKC",
            "name": "Kkcina",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bumir258/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Egnk28ph1b8s8DQJHnunSin6BbGoLptufb1po5vbmsPn",
            "symbol": "ROVE",
            "name": "Rove Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Egnk28ph1b8s8DQJHnunSin6BbGoLptufb1po5vbmsPn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://rove.live"
            }
        },
        {
            "chainId": 101,
            "address": "9BZffa5zs4K41GRDm7a8EfM4y1rw6SoXV6eysruDCjPT",
            "symbol": "STELI",
            "name": "Stelinium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SteliosMappouras/Stelinium/main/stelinium1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7VnDcdeDG2PdysDHSeceackLupXNrUo9nmhzKt7kqAMQ",
            "symbol": "NTR",
            "name": "Nitro Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Nitro-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "nitro-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/robertdenitro",
                "twitter": "https://twitter.com/nitroakawilson",
                "youtube": "https://www.youtube.com/channel/UCh-RZKbzquLB8oMsNiFZo5A"
            }
        },
        {
            "chainId": 101,
            "address": "C9kdkSkppUh9zAfGZVbyTKR3bR5G972XpyVpBfYVXpVh",
            "symbol": "GJJ",
            "name": "Gietwerk gouden lijst",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9kdkSkppUh9zAfGZVbyTKR3bR5G972XpyVpBfYVXpVh/goudenlijst.png",
            "tags": [
                "Geert-Jan-Jansen",
                "Gietwerken"
            ],
            "extensions": {
                "website": "https://blocksto.ne/nft-geert-jan-jansen-gietwerk-gouden-lijst/"
            }
        },
        {
            "chainId": 101,
            "address": "FFDyss8ZNKDoptctFbem76j5idS5yqWVc3kLsNhgFSpV",
            "symbol": "BAC",
            "name": "BakkaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ludvig03/Bakkacoin_logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FZa3VAVuk2u2CbzHrLChxc8qDm9oGG9vaPvyeQ1FgKgx",
            "symbol": "SKT",
            "name": "SanKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/badulakepuntocom/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75PDZoxj9K9o97eg71SBNpvJWSKmeq3HtSUoHZAWMbBk",
            "symbol": "GKoz",
            "name": "G Koinz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GKoinz/logo/main/logoo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token",
                "community"
            ]
        },
        {
            "chainId": 101,
            "address": "CfzXjG5VCQqZ7H7hxnoZZpA1MdcGSThcm6aaipU3M46K",
            "symbol": "DR",
            "name": "SOUL Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/GokhanCerk/asset/main/Daku.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dakureapers",
                "website": "https://dakureapers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE",
            "symbol": "BONES",
            "name": "BONES Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/souldogs",
                "instagram": "https://www.instagram.com/souldogscity/",
                "medium": "https://medium.com/@souldogscity",
                "twitter": "https://twitter.com/SoulDogsNFT",
                "website": "https://souldogs.city/"
            }
        },
        {
            "chainId": 101,
            "address": "5nBVVMJrG8NXNTgUV8NbydLGxF1G6HATAzYRXBfuinLL",
            "symbol": "CUPCAKE",
            "name": "CupcakeSwap",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Njoni/CupcakeSwapLogo-1/main/CupcakeSwap-png-24-1.png"
        },
        {
            "chainId": 101,
            "address": "BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7",
            "symbol": "FBZ",
            "name": "FakeBiz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7/logo.png",
            "tags": [
                "social-token",
                "util-token",
                "web3"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/timjdillon",
                "twitter": "https://twitter.com/FakeBiz_FBZ",
                "website": "https://www.fakebiz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7q69NKLpaD9uA6DrFcatKKmLY1Aop1jRe39wSF5MK4AV",
            "symbol": "MCCL",
            "name": "McCoolCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spj1932/cryp/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Pre5GMkB2MYsdTg2QLzM4XD3xawQ9UQiKzp9iuoGVcA",
            "symbol": "NFTW",
            "name": "NFTraders WhiteList",
            "decimals": 0,
            "logoURI": "https://github.dev/solana-labs/token-list/blob/89a0071ebd9424b3600cb44719eb73edda6d38ca/assets/mainnet/Pre5GMkB2MYsdTg2QLzM4XD3xawQ9UQiKzp9iuoGVcA/logo.png"
        },
        {
            "chainId": 101,
            "address": "4nDRYZAhu1frRzeRx4rh3pbbcWgFWx5oSPvtnGDRp4DM",
            "symbol": "FLSH",
            "name": "Flash Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexisonsmith/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B8wCsjSv3TDZcaLuhPZNDvpk2vuBtRgpgmTuvoDAJZZ7",
            "symbol": "CHIMP",
            "name": "Chimp",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tsbsoltn/soltn/main/chimp/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "meme-token"
            ],
            "extensions": {
                "description": "The #1 Decentralized meme token in Solana ecosystem.",
                "telegram": "https://t.me/TokenChimp",
                "twitter": "https://twitter.com/tokenchimp",
                "website": "https://chimpd.com"
            }
        },
        {
            "chainId": 101,
            "address": "GZV6zHR9Byi1iBqQobiUW51WPcu5xYHXUXt8JyCS9W92",
            "symbol": "VOID",
            "name": "TheVoidCoin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/dev-red07/solanavoid/void.png",
            "tags": [
                "social-token",
                "web3"
            ]
        },
        {
            "chainId": 101,
            "address": "J4ieoMxEng99xHVD3KMvyZ2zYz1ZmkwqmQhRSeqjoMqV",
            "symbol": "SBS",
            "name": "soulbondsoul",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/soulbondsoul/SBS-soulbondsoul-CRYPTO/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7mNpq2YV3hcQ9PqaqmxheTjyDZEVRuYHkK2TAoth66u7",
            "symbol": "TSTAKE",
            "name": "Test Stake",
            "decimals": 9,
            "logoURI": "https://media.discordapp.net/attachments/922295207062564885/938054794394894396/turquiose.png",
            "extensions": {
                "twitter": "https://twitter.com/undeadsols",
                "website": "https://undeadsols.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5aHpMNhALJsfBkogF63SqMxxYvfzBmcbAdjjTc5e8t3v",
            "symbol": "FSTC",
            "name": "FastCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lgsalles/fastcoin/main/token-logo.png",
            "tags": [
                "stablecoin",
                "asset"
            ]
        },
        {
            "chainId": 101,
            "address": "E6oCGvmSYW7qhy7oeDfiNZLX6hEmPCVxBC8AknwAj82B",
            "symbol": "PLAYA",
            "name": "Playground",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/ZkjtZNt.jpg",
            "tags": [
                "bridgesplit-fraction",
                "playground-dao",
                "curated-index",
                "fnft",
                "curated-index"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/pool/E6oCGvmSYW7qhy7oeDfiNZLX6hEmPCVxBC8AknwAj82B"
            }
        },
        {
            "chainId": 101,
            "address": "22CgxvP9YdhmoC7Pnhp7PQDMr9LvDefVHEvWWW7Rtvuv",
            "symbol": "EVILM",
            "name": "Evil Laohu Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/emint.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Evil Laohu Mint",
                "website": "https://www.laohukingdom.com/"
            }
        },
        {
            "chainId": 101,
            "address": "F173iuJpRaxWdHWc9hFbzrGZykCDyQqqcD11YQ1aiqX7",
            "symbol": "AST",
            "name": "Sudereum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aomaf/astlogo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Bihqw9aQETYVH7kitDtk4PwWN5XMCCZJTRDJGUwDkNp",
            "symbol": "HOZ",
            "name": "HOZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Bihqw9aQETYVH7kitDtk4PwWN5XMCCZJTRDJGUwDkNp/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HoundsOfZeusNFT",
                "website": "https://www.houndsofzeus.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CdcRwbFuj3YNJYdfUqh3hnxFz1fuF6he1Wgz7JvZMHda",
            "symbol": "HONEYBEAR",
            "name": "HONEYBEAR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CdcRwbFuj3YNJYdfUqh3hnxFz1fuF6he1Wgz7JvZMHda/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LazyBearsNFT",
                "website": "http://lazybearsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8Bmwd23NKHoGGaZbWDvngbG2GQ2vstso1WX3283RSQkC",
            "symbol": "BLING",
            "name": "Billionaire Bling Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gzparker/BlingCoin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3E6naW6kBaMetuKZD7a17AtPVDVzQr2gnTGdcGn7ud17",
            "symbol": "SORTI",
            "name": "Sortitio",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SortitioToken/uploads/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/sortitioworld/",
                "instagram": "https://www.instagram.com/sortitioworld/",
                "telegram": "https://t.me/sortitio",
                "twitter": "https://twitter.com/sortitioworld",
                "website": "https://sortitio.com/",
                "youtube": "https://www.youtube.com/channel/UCxpZ8j5HEC7Pjy5bM8GAiog"
            }
        },
        {
            "chainId": 101,
            "address": "6FTNWZF5bDNrepD32sA6LSBbPZA13ktjujxTYXtZ9Bde",
            "symbol": "PNPL",
            "name": "Pineapple Express Coin",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/PineappleExpressCoin/token/main/logo.png",
            "tags": [
                "social-token",
                "pineapple-token",
                "pineapple-express",
                "web3"
            ],
            "extensions": {
                "website": "https://pineappleinc.com/coin/"
            }
        },
        {
            "chainId": 101,
            "address": "6cio8bo7ab6jENJPxaxQMqBgniPtdHGkLtvmrDk9KS8u",
            "symbol": "OMENS",
            "name": "Omens of Olympus WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cio8bo7ab6jENJPxaxQMqBgniPtdHGkLtvmrDk9KS8u/logo-gold.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Exchange this with Charon for an Omen of Olympus.",
                "discord": "https://discord.gg/omensofolympus",
                "twitter": "https://twitter.com/omensofolympus",
                "website": "https://omensofolympus.com"
            }
        },
        {
            "chainId": 101,
            "address": "CRNcN3kpTNiJ9Bb1jfR4MzAavP9tU2vsBJFRHcHPtSzm",
            "symbol": "GMSC",
            "name": "GMSC Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRNcN3kpTNiJ9Bb1jfR4MzAavP9tU2vsBJFRHcHPtSzm/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/ehRVxFxmwq",
                "twitter": "https://twitter.com/GMSCNFT"
            }
        },
        {
            "chainId": 101,
            "address": "B6WQE14ZvajXFyVFPi1Eik92dKNMu3g3UsDnMc7xNH22",
            "symbol": "JBCNB",
            "name": "JoeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/StecksMate/crypto/main/joecoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6RzDb8kG2EVnhxuGUQte1bt51JuoEhuZxpnsDbUF2fwg",
            "symbol": "BPWL",
            "name": "Blash Pass Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6RzDb8kG2EVnhxuGUQte1bt51JuoEhuZxpnsDbUF2fwg/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kmpNEkYMrD",
                "twitter": "https://twitter.com/BlashPass",
                "website": "https://www.blashpass.com"
            }
        },
        {
            "chainId": 101,
            "address": "GAoUf49B5wYDeXtXagS1kJXf8VV9baZqhkte8mw7dZMx",
            "symbol": "ARCHPARCH",
            "name": "Archie Parchy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rapid12367/Crypto/main/Parchy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BpGRsBqgSNbGHb73cPL4SGqJTyr2zXomjFu3tX9LP9Vo",
            "symbol": "CARROT",
            "name": "CARROT TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cmgonsalves/token/main/carrot-logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/begrudgeddao",
                "website": "http://begrudgedbunnies.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7SEsxCsiNiYqCpYG16wx4c9u2YGLZphnEFTAU9ENAizD",
            "symbol": "RING",
            "name": "RING",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7SEsxCsiNiYqCpYG16wx4c9u2YGLZphnEFTAU9ENAizD/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheBullHerdNFT",
                "website": "https://thebullherd.com/ring"
            }
        },
        {
            "chainId": 101,
            "address": "5k4is2LEY9qBNi4s4zf5hFuSkEooMTCKKrVRvvn4RF99",
            "symbol": "DUST",
            "name": "StarDust",
            "decimals": 8,
            "logoURI": "https://user-images.githubusercontent.com/99850934/154388020-11a96575-d433-4e98-b6e8-dc4e9bd348cd.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/StarDust-Pay-102818109003525",
                "instagram": "https://www.instagram.com/stardustpay",
                "linkedin": "https://www.linkedin.com/company/82468083",
                "twitter": "https://twitter.com/stardustpay",
                "website": "http://stardustpay.com"
            }
        },
        {
            "chainId": 101,
            "address": "HMiZ2LEidcb1wDm8KsYJMk8udxVTchn39wyCJwA9dbAn",
            "symbol": "YOBRO",
            "name": "You Only Become Rich Once",
            "decimals": 0,
            "logoURI": "https://github.com/crptpj/yobro/blob/main/Untitled.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G7MR39wexBZcKfQvPwPqbZRNEUMH3UojpBhvobNyyhAF",
            "symbol": "BFIC",
            "name": "BigFishCoin",
            "decimals": 0,
            "logoURI": "https://github.com/GioNakamoto/CryptoFish/blob/main/FCPngsasaas2321321321.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FDrvNJHhLPMCrRe3oPzkXi3ug3S5dQ84hYAGUTjyjrqQ",
            "symbol": "MILEZ",
            "name": "MHAC MILEZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDrvNJHhLPMCrRe3oPzkXi3ug3S5dQ84hYAGUTjyjrqQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/milehighapeclub",
                "website": "https://www.milehighapeclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GwevK6tX7zwpVsardwtCG2SZEMMnSiFQuQfeLcsNuaBR",
            "symbol": "PUF",
            "name": "PoofyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bogdanix99/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2yA5b7MQRFyVr3GtdTDav6zV2BnSBiN5zD5uqms1BqaW",
            "symbol": "METAPRO",
            "name": "META PRO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ctomark/token-list-metapro/main/assets/mainnet/2yA5b7MQRFyVr3GtdTDav6zV2BnSBiN5zD5uqms1BqaW/logo.png",
            "extensions": {
                "website": "https://www.metaprotoken.com/"
            }
        },
        {
            "chainId": 103,
            "address": "AKzti1oAdft1XqtoJWoqvP5cqUKYWCNCECstELbgLu2x",
            "symbol": "SPH",
            "name": "Sapphire",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/Sapphire/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "hKnVPhEEWBbge4vuNZxFe8dVstpsXVUv6wJDwfTLvhW",
            "symbol": "SKWL1",
            "name": "SolKongz WL 1",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/hKnVPhEEWBbge4vuNZxFe8dVstpsXVUv6wJDwfTLvhW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolKongz",
                "website": "https://www.solkongz.net/"
            }
        },
        {
            "chainId": 101,
            "address": "B2GcHcXR4Na3ry38dSKXpybacaW4xYmt4TZvCoXHmqki",
            "symbol": "SKW2L",
            "name": "SolKongz WL 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B2GcHcXR4Na3ry38dSKXpybacaW4xYmt4TZvCoXHmqki/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolKongz",
                "website": "https://www.solkongz.net/"
            }
        },
        {
            "chainId": 101,
            "address": "EXChb5DMN7aPHgwXGVRgyxeTbfRuSXU73qL7EVfu4iDg",
            "symbol": "MV",
            "name": "MetaVerse",
            "decimals": 8,
            "logoURI": "https://user-images.githubusercontent.com/99852730/154405909-7002a30e-c2a4-4bbd-a4a8-91d6c903090e.jpg",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CcbVj95AVQZttpPUGrDRzdb59tmdV1jEN4UrQhZE2tcG",
            "symbol": "OSIN",
            "name": "OSINT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/therealaleph/OSIN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6yLLVgoJFyQh6xsKjeFEPL1AKPymJEratCsEXuDHEpq4",
            "symbol": "FVJ",
            "name": "Favij Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Favij-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "favij-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FavijTV"
            }
        },
        {
            "chainId": 101,
            "address": "GKsGCWJ5Z1cwL8hr92DDqkfy3aaZ77A9Zy3cyQragt6a",
            "symbol": "TSHW",
            "name": "theShow Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/theShow-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "theShow-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theshowisyou"
            }
        },
        {
            "chainId": 101,
            "address": "B5qmUyPzehsTkK5NsJYD7jrsNwGuXivZ6CAZQJ1gJrvh",
            "symbol": "MMNN",
            "name": "Moonie Moon",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/seng1218/token/main/moon-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hj92ziQbpRuAX5BAm73m8zjd43YY1nCNa3kGjP5g4hyR",
            "symbol": "SHIBZ",
            "name": "SHIBZ Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hj92ziQbpRuAX5BAm73m8zjd43YY1nCNa3kGjP5g4hyR/logo.png",
            "tags": [
                "meme-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "EP2aYBDD4WvdhnwWLUMyqU69g1ePtEjgYK6qyEAFCHTx",
            "symbol": "KRILL",
            "name": "KRILL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanahodlwhales/whitelist/main/Krill_towen.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaWhalesNFT",
                "website": "https://solanahodlwhales.io"
            }
        },
        {
            "chainId": 101,
            "address": "5XtsBTUaYbBVZjYQBYQcky1gRCbhj6bf9riVVLSdLJ1h",
            "symbol": "PURS",
            "name": "PURS Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/konradre/purslogo/main/purslogo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "web3-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7akcGwiDMFmMY8NTtGPzH9QPXat2wQ7CMmbDjzGu5fYg",
            "symbol": "PURR",
            "name": "PURR Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7akcGwiDMFmMY8NTtGPzH9QPXat2wQ7CMmbDjzGu5fYg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://demigodcats.com"
            }
        },
        {
            "chainId": 101,
            "address": "3uNAevHamuZKKQdtdLzmHNvqD8r14tXUUXx5PN48UbYC",
            "symbol": "NLTK",
            "name": "Nasi Lemak",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/avaelon/nltk/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4q6ag4sYBdQfAWRt7miq3sbbuTRBQRFHcZAc8oL5ETpS",
            "symbol": "UNICOF",
            "name": "UnitedKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/letungbach/crypto-/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2pVkjwPJHXopCknbdsHgXQnhptEWWWkfiw6pDcnNnBPC",
            "symbol": "TOPSS",
            "name": "The original payment system solution",
            "decimals": 6,
            "logoURI": "https://topss.io/assets/images/favicon_dark.jpg",
            "extensions": {
                "twitter": "https://twitter.com/TOPSSTOPSS1",
                "website": "https://topss.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EkRFmiruaziaUAafqYm5vXwEXH9dQBSyHT7RLczjnmQS",
            "symbol": "ERNE",
            "name": "ERIC NERON",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pattron/first-crypto/main/t%C3%A9l%C3%A9chargement.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4PJ8yD2hPXVGxnYcmjBoDyDHKAMEAKjGgAfvEqmZUaAw",
            "symbol": "WL1",
            "name": "Whitelist token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Deghf666g/gggg/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CykTLNbzwmb3c4D3aWteLPAzR9Zga52gm3RJLDebz4Cz",
            "symbol": "GRAV3",
            "name": "Grav3Coin",
            "decimals": 0,
            "logoURI": "https://github.com/boijoely/grav3/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AEAxXsKeg8xdoSDMyKtFM1TbbGjXbRpBP3eZGDNEaLGQ",
            "symbol": "CARP-A",
            "name": "CARP-A",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEAxXsKeg8xdoSDMyKtFM1TbbGjXbRpBP3eZGDNEaLGQ/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3LFdGFgEpqXNcuLNXKgpcK7fKq5jUYdCfDmcFDVyUqCN",
            "symbol": "DAIK",
            "name": "Daiki Token",
            "decimals": 1,
            "logoURI": "https://cdn.discordapp.com/attachments/926535226820276264/943860197351387146/daikitoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToiHvgfcWD6CcHryjAFNaHZfZ1jQkKuEiELVmjqfWH9",
            "symbol": "PandG",
            "name": "Purple and Green token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToiHvgfcWD6CcHryjAFNaHZfZ1jQkKuEiELVmjqfWH9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kzPBdWtbrK",
                "twitter": "https://twitter.com/Ghost_Beat_",
                "website": "https://www.ghost-beat.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HgM7hCyoibJQggtrKAJiaNwpG2usxriFLiVskTgNNEDT",
            "symbol": "COMPANY",
            "name": "Wallet Dimidium BV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HgM7hCyoibJQggtrKAJiaNwpG2usxriFLiVskTgNNEDT/dimidium.png",
            "tags": [
                "Dimidium",
                "Wallet"
            ],
            "extensions": {
                "website": "https://dimidium.eu/contact-us/"
            }
        },
        {
            "chainId": 101,
            "address": "B87r1e6PsztnS5fHFCHhQP86dtd9ASfKWCUytLBpudLi",
            "symbol": "ITI",
            "name": "Innovation Technology Information-Meta Learn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WayTo1Million/ITI/main/ITI.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G3dCVUwdVZpYhoUbwzv9DbUoCq1kp72HoXwH1GqyxXxe",
            "symbol": "TSTPPL",
            "name": "TestPPL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/T3tsu0/testDeploy1/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "http://peoplewilldieof.fun/"
            }
        },
        {
            "chainId": 101,
            "address": "CFT3tLPjaTvGnRMqFyBwz7dja6EL47qxzHaFQJEoMQ12",
            "symbol": "PLZ",
            "name": "The Playaz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CFT3tLPjaTvGnRMqFyBwz7dja6EL47qxzHaFQJEoMQ12/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/JDQgrWMRJV",
                "twitter": "https://twitter.com/playaznft",
                "website": "https://playaznft.club/"
            }
        },
        {
            "chainId": 101,
            "address": "WhorAZ8rXmD1iAh2hGTJrSdwnY9w75GJpeugBd8w5gR",
            "symbol": "HORNII",
            "name": "HorniIDAO WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WhorAZ8rXmD1iAh2hGTJrSdwnY9w75GJpeugBd8w5gR/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "52WyZe1pfobyq6v1t7KAKZWePcq9Aj2Aa5kJHuF2KHDM",
            "symbol": "SHEEP",
            "name": "SolWolf Game Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/8sunyuan/token-list/main/assets/mainnet/52WyZe1pfobyq6v1t7KAKZWePcq9Aj2Aa5kJHuF2KHDM/wolflogo.jpg"
        },
        {
            "chainId": 101,
            "address": "CVzNDckT4riUsVj5zfVCLA5ABZdXFqtW4KiZTRviYRGJ",
            "symbol": "NWT",
            "name": "Nether World Tales Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Nether-World-Tales/token/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nwtales",
                "website": "https://netherworldtales.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5TLuCMNFLFie6vuTEpiUNHEPjTANYvbooUzdc4Pe1pb6",
            "symbol": "JRDN",
            "name": "Jordan Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Jordan-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "meme-token",
                "Jordan-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/jumpman23"
            }
        },
        {
            "chainId": 101,
            "address": "7rnHtsEv2pbYevWkbYZVhFujHsJu16UK9RDurUptbMap",
            "symbol": "MSR",
            "name": "MetaSnooker",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rnHtsEv2pbYevWkbYZVhFujHsJu16UK9RDurUptbMap/logo.svg",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/meta.snooker/",
                "medium": "https://metasnooker.medium.com/",
                "telegram": "https://t.me/MetaSnooker",
                "twitter": "https://twitter.com/Snooker_Meta",
                "website": "https://metasnooker.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2pqRgpTLSJRgqBaBAQBBAqfLsdjqWic5bi6S9UJazyaT",
            "symbol": "FEAR",
            "name": "The Fearless",
            "decimals": 3,
            "logoURI": "https://arweave.net/QTbFKiBGSYIJJna0QafYgAxNyAtuhwssEJliiJa0eyE?ext=png",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/5yTUs6NXd9S9fsny9r5PHZvTtcXN3zLj7SDxLrn2z1Kr",
                "vaultPubkey": "5yTUs6NXd9S9fsny9r5PHZvTtcXN3zLj7SDxLrn2z1Kr"
            }
        },
        {
            "chainId": 101,
            "address": "Z41dTzcv4S7CoykWpnj1GYDk7N5yreunYruGrSuFvj8",
            "symbol": "GIO",
            "name": "Andrew Andy Goram Giovanni van Bronkhorst Boyd",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SGB1986/GIO/main/273874760_693098681684592_4257136878581332139_n.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6yjKYzdDuyf2qjoTY5n5V5s6hjFcbVfnod9ze94Frv3g",
            "symbol": "GTHWL",
            "name": "Gentie Home WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/matthewrx/wl/main/tokenwl.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B5xan1Zvc8gv2x8qsrG4VgzcCt2vWkLXxQjf5aoYHv17",
            "symbol": "THN",
            "name": "Throne",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B5xan1Zvc8gv2x8qsrG4VgzcCt2vWkLXxQjf5aoYHv17/logo.png",
            "tags": [
                "Metaverse",
                "NFT"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/thronenft/",
                "telegram": "https://t.me/thronecommunity",
                "twitter": "https://twitter.com/thronenft",
                "website": "https://www.thr.one"
            }
        },
        {
            "chainId": 101,
            "address": "FkPw5G7Ffek5URaYjyFQucQeS9XwA7EWqcoYFTtD9XZz",
            "symbol": "CAY",
            "name": "CAYMAN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FkPw5G7Ffek5URaYjyFQucQeS9XwA7EWqcoYFTtD9XZz/cay520x520.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2D4fAKkwAXGdMFo57SFw2YvL2WwPu2Egg3J7d1ovC4rH",
            "symbol": "SOXS",
            "name": "Socsky",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2D4fAKkwAXGdMFo57SFw2YvL2WwPu2Egg3J7d1ovC4rH/soxs520x520.png",
            "tags": [
                "NFTs-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gorizmp5uZz2VC8dg6L58Hhf9L1onCfBH8SoDBHnyJ2C",
            "symbol": "GMACHA",
            "name": "GorillaeMACHA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gorizmp5uZz2VC8dg6L58Hhf9L1onCfBH8SoDBHnyJ2C/logo.png",
            "tags": [
                "NFT",
                "mint-token",
                "whitelist"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GorillaeNFT",
                "website": "https://gorillaenft.io"
            }
        },
        {
            "chainId": 101,
            "address": "GiT3osEFEqahWoUmoHiE2GLoVTTjAEsSYawipuNqQzpv",
            "symbol": "SMD",
            "name": "SolMaxDex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GiT3osEFEqahWoUmoHiE2GLoVTTjAEsSYawipuNqQzpv/logo.png",
            "tags": [
                "DEX"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolMaxDex"
            }
        },
        {
            "chainId": 101,
            "address": "2cW8Yosn4tSYJYjfUkcpKnYBSMYDqXfJmQXVu4RJzBTw",
            "symbol": "WAS",
            "name": "Wasder",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cW8Yosn4tSYJYjfUkcpKnYBSMYDqXfJmQXVu4RJzBTw/logo.png",
            "tags": [
                "gaming",
                "metaverse",
                "microverse",
                "gamefi"
            ],
            "extensions": {
                "address": "0x0c572544a4ee47904d54aaa6a970af96b6f00e1b",
                "assetContract": "https://etherscan.io/address/0x0c572544a4ee47904d54aaa6a970af96b6f00e1b",
                "bridgeContract": "https://etherscan.io/address/0x3ee18b2214aff97000d974cf647e7c347e8fa585",
                "coingeckoId": "wasder",
                "twitter": "https://twitter.com/WasderGG",
                "website": "https://www.wasder.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe",
            "symbol": "MMA",
            "name": "mma",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "WE PLAY, YOU EARN, By helping gamers generate an income for themselves, they create an income for you.",
                "discord": "https://discord.gg/mmagaming",
                "medium": "https://medium.com/@MMAGaming",
                "twitter": "https://twitter.com/mmagaming_io",
                "website": "https://mmagaming.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5oPqqnah6ci5FNU6M24fRtuRQ56eH8hKrLzBw185D13i",
            "symbol": "SHNY",
            "name": "SHINY",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5oPqqnah6ci5FNU6M24fRtuRQ56eH8hKrLzBw185D13i/logo.png",
            "tags": [
                "social-token",
                "community-coin",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EEcTHtygK1agDKMKrXgaznbjUNPM67KvnmsqKmPkCTvn",
            "symbol": "LNFTxMOON1",
            "name": "Cube WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EEcTHtygK1agDKMKrXgaznbjUNPM67KvnmsqKmPkCTvn/logo.png",
            "tags": [
                "liqnft-wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/collection/MOON"
            }
        },
        {
            "chainId": 101,
            "address": "FUVpXU15YFGh8PyBgqQfj59NmwiRMDe53uqm6sreaj2D",
            "symbol": "LNFTxMOON2",
            "name": "Nautilus WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FUVpXU15YFGh8PyBgqQfj59NmwiRMDe53uqm6sreaj2D/logo.png",
            "tags": [
                "liqnft-wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/collection/MOON"
            }
        },
        {
            "chainId": 101,
            "address": "AUNHT5DSvHqgaQCeB7bs9XQMjyx2gfVv8nzmUf5jX3Et",
            "symbol": "LNFTxMOON345",
            "name": "Moon WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AUNHT5DSvHqgaQCeB7bs9XQMjyx2gfVv8nzmUf5jX3Et/logo.png",
            "tags": [
                "liqnft-wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/gallery/collection/MOON"
            }
        },
        {
            "chainId": 101,
            "address": "DqSUNuXdASnqQ5MR5mRytNZW6NkF9akEA14hEFqmcr94",
            "symbol": "OKTA",
            "name": "Octanity",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Oktavian25/Octanity/main/unknown.png",
            "tags": [
                "nft",
                "stake",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PJ683jbxAjjtSh8HWuqq79Kd5HGaSQeNpYXZt6tWSD5",
            "symbol": "TROS",
            "name": "The Republic of Satoshi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TROSATOSHI/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "83R8854RXX3h4fow7bgJ7w1MarVDpnXGKbjLMDyetu54",
            "symbol": "GPET",
            "name": "Galaxy Pets",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/83R8854RXX3h4fow7bgJ7w1MarVDpnXGKbjLMDyetu54/logo.png",
            "tags": [
                "metaverse",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/glxypets",
                "website": "https://www.galaxypets.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "CMTiRQKXFbK7eW8GM4svmjVJ8DEBbwjubrQgqkybmC2P",
            "symbol": "CMT",
            "name": "CryptoMural",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CMTiRQKXFbK7eW8GM4svmjVJ8DEBbwjubrQgqkybmC2P/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://cryptomural.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7j1G47gSNhavCy5r4UsvDU2Qtw4U5M7JVWzZEXYSGcma",
            "symbol": "RVRB",
            "name": "Reverb",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Andy2988c/logo/main/Reverb.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6skDrUSXtrGWCMgaUbGzDkSTw2TtHwfhcFre397mcedP",
            "symbol": "YAR",
            "name": "Yara",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Keizerstad/Yara/main/Yara-Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLjDmpFkBe2eUunAit8h4pohYNQ243xYS1bv4wHz9Hi",
            "symbol": "BBCA",
            "name": "Big Brain Clique Alpha Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLjDmpFkBe2eUunAit8h4pohYNQ243xYS1bv4wHz9Hi/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E8763URMT8FSo2Hg9WrQZfVbUKMrK8EVTNt3jXzr2dzY",
            "symbol": "LIFT",
            "name": "Lucifer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/diontr101/Lucifer/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "prwjbiQ3EQf8WqTqheZwr4Fa7gHfnFYYgPJJuC3CB7B",
            "symbol": "PL-FE-WL",
            "name": "ProLeague FE Whitelist Token",
            "decimals": 0,
            "logoURI": "https://proleague.gg/wp-content/uploads/2020/12/cropped-favicon-512px.png",
            "extensions": {
                "website": "https://proleague-mint.prolaunch.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "6ahac3aYrkq5kvUdDaJquBXbcR1xH58GkTBchcgHWkh7",
            "symbol": "AWOO",
            "name": "Retro Wolves Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/rxrxrxrx/RetroWolves/logo.png",
            "tags": [
                "retro-coin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RetroWolvesNFT",
                "website": "https://discord.gg/Dm4tQEwERh"
            }
        },
        {
            "chainId": 101,
            "address": "3adAUq2Z7sNfcmxSvgjXmxcgKF8bsQfUL76UpLMj8CqG",
            "symbol": "PEPE$",
            "name": "Pepe Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/virdagreat147/Pepe-army-Logo/main/Pepe%20Coin%20Logo%20small.png",
            "tags": [
                "currency",
                "NFT",
                "staking",
                "access-token",
                "gamble",
                "utility"
            ],
            "extensions": {
                "discord": "https://discord.gg/ySkaUa2rpy",
                "twitter": "https://twitter.com/pepearmynft"
            }
        },
        {
            "chainId": 101,
            "address": "FfpMkqyZS1EpvdjamVUY14Em988wEqBEZxMvKLZ2WE1m",
            "symbol": "CF",
            "name": "CLERGY666",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/98871398/153300652-6905b72c-1cfb-4972-9b92-f9a00d81626d.png",
            "tags": [
                "social-token",
                "utility-token",
                "clergy",
                "666",
                "clowst"
            ],
            "extensions": {
                "description": "Clowst, the ghost clown is set to build a new civilization by collecting his monstrous friends we've left behind and build a new clergy. CLERGY666.",
                "discord": "https://discord.gg/CWmjPqxfuZ",
                "instagram": "https://www.instagram.com/clowst666",
                "twitter": "https://twitter.com/clowst666",
                "website": "https://www.clowst666.io"
            }
        },
        {
            "chainId": 101,
            "address": "8s1FdbTFy6RozVcrtydnJ8KkAd5XdpXqcLmAk3BixybK",
            "symbol": "BIGK",
            "name": "BiggieKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bkmdchw7/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GA1a332k3RjL1MsYyqBsRyK1n9ssvEEXTk4DTAybva11",
            "symbol": "KAUHL",
            "name": "PanditCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Gitlycool/Crtyo/main/diy-silk-screen-print-design-stencil-om-mantra-symbol-hindu-buddhist2.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FXSLj3g5u9SEm62bErBs8895YgrfJPyn5q36V4KpZQFc",
            "symbol": "$BURGER",
            "name": "BRBB - Burger Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXSLj3g5u9SEm62bErBs8895YgrfJPyn5q36V4KpZQFc/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zKfTy88rvx",
                "twitter": "https://twitter.com/BrokenRobotNFT"
            }
        },
        {
            "chainId": 101,
            "address": "BiHmfj5eEj2DTFbfKStr2fqKXTqNkFWxHBqv8C4ofKAA",
            "symbol": "STKD",
            "name": "Stoked Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jstokesok/stoked/main/logo-stkd.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5sxyEXsCjZcuz4R7SpHxgb6Cx7SV2sLBA4Fm7EKwCDZw",
            "symbol": "LTS",
            "name": "Logiq Test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/andreiprivate/token-list/main/assets/mainnet/5sxyEXsCjZcuz4R7SpHxgb6Cx7SV2sLBA4Fm7EKwCDZw/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "88YUXaaUhybTxYmupRNiSz2TfuU7Zpk37DLwAr6ZyuwN",
            "symbol": "HCC",
            "name": "Hell City Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/n123al/HCC/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C5EefTmWXHJWFkN3Dh7QyFUnBG3UXSu8h6qVs6xtaLxy",
            "symbol": "SDUST",
            "name": "Solien DAO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C5EefTmWXHJWFkN3Dh7QyFUnBG3UXSu8h6qVs6xtaLxy/daologo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/ZVQNNtbw3u",
                "twitter": "https://twitter.com/soliensnft",
                "website": "https://soliens.super.site/"
            }
        },
        {
            "chainId": 101,
            "address": "C4KYZ9EPYU2EYvTVkt4R5NG5AwCpCb6VG4QZd7LVMfua",
            "symbol": "JEEV",
            "name": "CLTSM Social Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C4KYZ9EPYU2EYvTVkt4R5NG5AwCpCb6VG4QZd7LVMfua/logo.png",
            "tags": [
                "social-token",
                "cltsm"
            ]
        },
        {
            "chainId": 101,
            "address": "EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az",
            "symbol": "WLKN",
            "name": "Walken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az/logo.svg",
            "tags": [
                "walken",
                "utility-token"
            ],
            "extensions": {
                "website": "https://walken.io"
            }
        },
        {
            "chainId": 101,
            "address": "AWGrr6HmmhJwnMdDLoxxDMpKo2KN99Bx47Rbhfo3NM3V",
            "symbol": "BWLKN",
            "name": "BETA Wlkn",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWGrr6HmmhJwnMdDLoxxDMpKo2KN99Bx47Rbhfo3NM3V/logo.svg",
            "tags": [
                "walken",
                "utility-token"
            ],
            "extensions": {
                "website": "https://walken.io"
            }
        },
        {
            "chainId": 101,
            "address": "HAndCoG9GWS4TPaBFhbbtVMDevpUkvRti6TweV7d4q1L",
            "symbol": "PoHS",
            "name": "Proof-of-Handshake",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KKQanT/token-list/main/assets/mainnet/HAndCoG9GWS4TPaBFhbbtVMDevpUkvRti6TweV7d4q1L/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.atadia.io/"
            }
        },
        {
            "chainId": 101,
            "address": "reZNfhu7z32WzecdjjswvZQMFrkXyUX4mbMh4NZwPNt",
            "symbol": "xATS",
            "name": "Staked Atlas Dex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv/logo.png",
            "tags": [
                "utility-token",
                "dex",
                "swap"
            ],
            "extensions": {
                "medium": "https://atlasdex.medium.com/",
                "twitter": "https://twitter.com/atlas_dex",
                "website": "https://atlasdex.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AihWtSwR46XmrYycYJkvyzPeCDbBGL4YJB9ob3WWcNUd",
            "symbol": "XFER",
            "name": "Transfer Coins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Web3portfolio/token-list/main/assets/mainnet/AihWtSwR46XmrYycYJkvyzPeCDbBGL4YJB9ob3WWcNUd/logo.PNG",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.web3portfolio.sale/"
            }
        },
        {
            "chainId": 101,
            "address": "C6sSRHuvnHph3wLefvwUY3bq1edVbATWbT46PWDovEd",
            "symbol": "PAM",
            "name": "Pampkin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pampkin128/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLBSARMCJkVktbnjcB9n4WcVyBEkphgSnfZegPergL6",
            "symbol": "WLB",
            "name": "TTT WLB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C5EefTmWXHJWFkN3Dh7QyFUnBG3UXSu8h6qVs6xtaLxy/daologo.png",
            "tags": [
                "whitelist-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "4KGbtg9AqnstxDfJBMjiZiPZFUAWQrz573gnz5zg7JSE",
            "symbol": "OOT",
            "name": "OneofThem",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/global01/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J9VccnH3iS4qBnCCJqnrLAUk8inBhqNj82E2QyFzUD25",
            "symbol": "CHER",
            "name": "Cheriola",
            "decimals": 9,
            "logoURI": "https://arweave.net/OLGbQ8YmXyjGX1XF6Mt861mkLI8kOZ9U1xnHZlwdqJ4",
            "tags": [
                "General"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cheriolatoken"
            }
        },
        {
            "chainId": 101,
            "address": "derekokMW1a3MLm6guMBKaWUFQLSMJ3C5MoEkZZeBrt",
            "symbol": "BBDD",
            "name": "BuBiDD Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/derekokMW1a3MLm6guMBKaWUFQLSMJ3C5MoEkZZeBrt/logo.png"
        },
        {
            "chainId": 101,
            "address": "CompzLC6kig1XRMsErkvB69JnxYfyAW9pZ8EyaytW43o",
            "symbol": "COMPANION",
            "name": "Companion",
            "decimals": 9,
            "logoURI": "https://yt3.ggpht.com/6xyHbMau92t-6MDTYGEEqCtzquvCrIlRK_TkfYk_dmq0WM824Gtzo6dP9tp3mwEWRlEnb8_ahzY=s88-c-k-c0x00ffffff-no-rj",
            "tags": [
                "Defi",
                "utility-token",
                "educational"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Companion_sol",
                "website": "https://app.companion.to"
            }
        },
        {
            "chainId": 101,
            "address": "4JEBYVA3Yny9E46CjoK3V9TKayqbkCGcvwkmYXYu1Uoe",
            "symbol": "NICO",
            "name": "NICO Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ralli-5/crypto/main/NICO.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FgX5vS4ueGUuSSZ48tJcYd9cNm8nYexheC3YS9FR9aTJ",
            "symbol": "GNGR",
            "name": "GingerKoin Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shines69/GingerKoin---Token/main/Ginger-Crypto%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6mbESoTpqy3z6BuTY4CVLMJJ1cYuS4s29R5vdUAP5EU",
            "symbol": "ARBX",
            "name": "Arqo Beta",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6mbESoTpqy3z6BuTY4CVLMJJ1cYuS4s29R5vdUAP5EU/logo.PNG",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://arqox.com"
            }
        },
        {
            "chainId": 103,
            "address": "FZE52MWasDcwNeQfBL6PUHjvYgQMthvHNX5e7xUDN56T",
            "symbol": "USDC-CASH",
            "name": "USDC-CASH Saber LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZE52MWasDcwNeQfBL6PUHjvYgQMthvHNX5e7xUDN56T/logo.png",
            "tags": [
                "ratio-token"
            ],
            "extensions": {
                "website": "https://dev.ratio.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "7gJWEW3vGDgUNbg3agG9DSSkb271tpk82K4ixAGXeuoh",
            "symbol": "USDC-PAI",
            "name": "USDC-PAI Saber LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7gJWEW3vGDgUNbg3agG9DSSkb271tpk82K4ixAGXeuoh/logo.png",
            "tags": [
                "ratio-token"
            ],
            "extensions": {
                "website": "https://dev.ratio.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "HXb1AM83cRUbGegTivuSanvLP1W8A4pyTGMveNWR1pyg",
            "symbol": "USDC-USDT",
            "name": "USDC-USDT Saber LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HXb1AM83cRUbGegTivuSanvLP1W8A4pyTGMveNWR1pyg/logo.png",
            "tags": [
                "ratio-token"
            ],
            "extensions": {
                "website": "https://dev.ratio.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "9RBrjJLKK7xm5275iNHPDdtMEN3nZFhPDiUkZGmkTUrd",
            "symbol": "USDT-CASH",
            "name": "USDT-CASH Saber LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9RBrjJLKK7xm5275iNHPDdtMEN3nZFhPDiUkZGmkTUrd/logo.png",
            "tags": [
                "ratio-token"
            ],
            "extensions": {
                "website": "https://dev.ratio.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "2SzPyNtr9taHniunNJVgunTkXQqpJjaTuKTB9ztTchh3",
            "symbol": "NPACA",
            "name": "Nessalpaca",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/n0rwegianblue/npaca/main/nessalpaca.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLcpHjuqSZQjxmPhnF5fEkfC1MKqZ5voHNops9EGCS7",
            "symbol": "HMWL",
            "name": "Haughty Monkee WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLcpHjuqSZQjxmPhnF5fEkfC1MKqZ5voHNops9EGCS7/logo.png"
        },
        {
            "chainId": 101,
            "address": "4pzWuLXG8uwXjTCbverinCzeGf7ktAnZ2eL1eAJf8LV3",
            "symbol": "LBRE",
            "name": "Librecoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ghostlywhitetail/crypt0/main/LBRE.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ChTE6TCqoY16dvqPjgK6Ji7zHcKricB2DHLoqWzx5v9A",
            "symbol": "MTP",
            "name": "Metapoo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/metapoo/token-list/main/assets/mainnet/ChTE6TCqoY16dvqPjgK6Ji7zHcKricB2DHLoqWzx5v9A/logo.png",
            "tags": [
                "NFT",
                "fair-launch",
                "farming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Metapoo_Solana",
                "website": "https://app.metapoo.cash"
            }
        },
        {
            "chainId": 101,
            "address": "UXRj3sUsJsQ6mkDz8xmXQxnY7DHoyZzX1UtEZriqbmC",
            "symbol": "CHEERS",
            "name": "Secret Kongz Cheers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXRj3sUsJsQ6mkDz8xmXQxnY7DHoyZzX1UtEZriqbmC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SecretKongzSC",
                "website": "https://secretkongz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "H6jvgU491zP4uXZEb16DtX9U7ZdbrD2K2FLbbTq9uiVY",
            "symbol": "TMF",
            "name": "Truly Marvelous Falcons",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H6jvgU491zP4uXZEb16DtX9U7ZdbrD2K2FLbbTq9uiVY/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "MarvelousFalcons is a UAE-based p2e NFT game."
            }
        },
        {
            "chainId": 103,
            "address": "4XQxzNWwgqU1YDaAvUSjJHZKy4mJZUfcKG7pKurvXLLf",
            "symbol": "HUNT",
            "name": "Treasure Hunt",
            "decimals": 9,
            "logoURI": "https://github.com/vulcanrowley/Solana/blob/main/Logo.png"
        },
        {
            "chainId": 101,
            "address": "GvRPncmFzJ7pAb3y2PjnKR6zuNkZSxdqS2cgZ6R9TeBd",
            "symbol": "BPWM",
            "name": "Bizarre Platypus Mint Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GvRPncmFzJ7pAb3y2PjnKR6zuNkZSxdqS2cgZ6R9TeBd/logo.png",
            "tags": [
                "utility-token",
                "mint-token",
                "whitelist"
            ],
            "extensions": {
                "description": "The Coin for Bizarre Platypus NFT for Whitelist.",
                "twitter": "https://twitter.com/bizarreplatypus",
                "website": "https://bizarreplatypus.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GMSC6kHzxHmw43KKYJMH91HZVZBSuRNTDYb3Et4sxvWZ",
            "symbol": "GMSC",
            "name": "GM Social Club Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GMSC6kHzxHmw43KKYJMH91HZVZBSuRNTDYb3Et4sxvWZ/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/ehRVxFxmwq",
                "twitter": "https://twitter.com/GMSCNFT"
            }
        },
        {
            "chainId": 101,
            "address": "3zgjd8uWEjZS2tjNnrbB5PATzWXCYmuLckk9B46eQqN8",
            "symbol": "PLANET",
            "name": "Ninth Planet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3zgjd8uWEjZS2tjNnrbB5PATzWXCYmuLckk9B46eQqN8/logo.png",
            "tags": [
                "nft-token",
                "game-token",
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "planet",
                "discord": "https://discord.com/invite/9SRMNRTWc6",
                "twitter": "https://twitter.com/NinthPlanetNFT",
                "website": "https://ninthplanet.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GpQLC7KnNgAvEpamfWi1AWFdXECZ1eQetvFYTuETLZC7",
            "symbol": "SPM",
            "name": "SchimmelPeter Monster",
            "decimals": 9,
            "logoURI": "https://www.schimmelpeter.de/spm_solana.png",
            "extensions": {
                "facebook": "https://facebook.com/SchimmelPeterGmbH",
                "instagram": "https://www.instagram.com/schimmelpetergmbh",
                "website": "https://www.schimmelpeter.de/"
            }
        },
        {
            "chainId": 101,
            "address": "B7s5eT2SG6RBroG9cxVb18J5mEUp71UTeTRYLzCPra36",
            "symbol": "RISE",
            "name": "RISE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B7s5eT2SG6RBroG9cxVb18J5mEUp71UTeTRYLzCPra36/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/qNtp75xZuN",
                "twitter": "https://twitter.com/ECORISEDAO",
                "website": "https://ecorise.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9beVwyZMYRNg2UQaHa8153bouEPDufcGNqL7VfGWn9dc",
            "symbol": "GAC",
            "name": "GAC PROJECT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/GAC-Coin/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "gac-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/gac.project"
            }
        },
        {
            "chainId": 101,
            "address": "ToTuLunrMF2eQtvj7p6UtU7Jc38mbZZ8do21fg61Qg6",
            "symbol": "MRTS",
            "name": "MERITS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToTuLunrMF2eQtvj7p6UtU7Jc38mbZZ8do21fg61Qg6/logo.png"
        },
        {
            "chainId": 101,
            "address": "FFhRNx1RVn5UnujcEved9aRaQtEUazgmQMbmdFHJXe5K",
            "symbol": "PLMR",
            "name": "Palmer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rydder92/crypto/main/coinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BW2CtuLxPzvVcV1DEomHfmL8zWiDD4fvk11F2SxqEaUB",
            "symbol": "SSFM",
            "name": "Sol Stone Free Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BW2CtuLxPzvVcV1DEomHfmL8zWiDD4fvk11F2SxqEaUB/logo.jpg",
            "extensions": {
                "website": "https://solstone.contrastive.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "FDxEJx156tbp7ukF4B89QisMg3EpMtFqAZ94TQk2ZwRV",
            "symbol": "BT-WL",
            "name": "Blue Terra Whitelist Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDxEJx156tbp7ukF4B89QisMg3EpMtFqAZ94TQk2ZwRV/logo.png",
            "extensions": {
                "website": "https://blueterra.land/"
            }
        },
        {
            "chainId": 101,
            "address": "HMWLJtkb5U1evEJvXassg2MHaStY6aeE9CmVvr2o6pVE",
            "symbol": "HMKS",
            "name": "HMKs Whitelist Token ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMWLJtkb5U1evEJvXassg2MHaStY6aeE9CmVvr2o6pVE/logo.png"
        },
        {
            "chainId": 101,
            "address": "7iy4ybiXSvcGVN83gcCVjtfZasu8B9CRV5fULMmbLb7k",
            "symbol": "SPYBCK",
            "name": "Spy Bucks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7iy4ybiXSvcGVN83gcCVjtfZasu8B9CRV5fULMmbLb7k/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://doxologypress.org/spybucks"
            }
        },
        {
            "chainId": 101,
            "address": "2Tp4hCJ24aRnsLShz9U96VtTSDHuaKL7eD7vj8Stvxhn",
            "symbol": "HENDX",
            "name": "Hendrix Token",
            "decimals": 6,
            "logoURI": "https://github.com/thejoshuahendrix/hennyscript/blob/main/IMG_20180803_100044.jpg?raw=true",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "website": "https://hennyscript.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "Am2QTz1KrLs2VP8BU4vUjRxTynxBEfNMGsAdNB5Sy8Np",
            "symbol": "WCOIN",
            "name": "WatchCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Am2QTz1KrLs2VP8BU4vUjRxTynxBEfNMGsAdNB5Sy8Np/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5R8Ai4pQuzteR1Y5HxpscQH4Es2JNWcQZbMmu9RpZwoR",
            "symbol": "NCT",
            "name": "Encanto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jdaniel96/EncantoLogo/main/EncantoLogo.png",
            "tags": [
                "Encanto"
            ]
        },
        {
            "chainId": 101,
            "address": "FVTBPs5HwM6McfZv7xJjfxGwb3A4zPMJ9JGuphKrADfx",
            "symbol": "BPBI",
            "name": "Bipbi Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FVTBPs5HwM6McfZv7xJjfxGwb3A4zPMJ9JGuphKrADfx/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DGZqPd5VmgcfrvSe93o2oMMGP5toK3zzoBLuSUjNzqCd",
            "symbol": "TICR",
            "name": "Tyr International Crisis Research Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JFischer03/crypto2/main/TICRLOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G6xzfFFnsUcX62kThT3ecaBsor2YBeYAsqHANBjNjXE1",
            "symbol": "GUILD",
            "name": "GuildCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/thedevdojo/guild-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLT8odEsvbXvdmZ7be6vfXpXgYL8BNR83wiuT5nLz9E",
            "symbol": "TMDCWL",
            "name": "The Monkey Design Club Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLT8odEsvbXvdmZ7be6vfXpXgYL8BNR83wiuT5nLz9E/logo.png",
            "tags": [
                "whitelist-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "Df7cTkD6BwAjRRogfLFiodePfwFZ4btxwkhTmHG6inEg",
            "symbol": "JRZYcoin",
            "name": "JRZYcoin",
            "decimals": 9,
            "logoURI": "https://jrzycoin.com/images/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "payment-token",
                "game-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/jrzycoin",
                "instagram": "https://www.instagram.com/jrzycoin",
                "twitter": "https://twitter.com/jrzycoin",
                "website": "https://jrzycoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "AGwrWpN9dthHxvwfhtrUPJrdT1yQJizwd1KsE2gLaAkr",
            "symbol": "METAI",
            "name": "Meta Infra",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sachinbhoi29/metainfralogo/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "payment-token",
                "game-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/metainfra/",
                "twitter": "https://twitter.com/meta_infracoin",
                "website": "https://metainfracoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EZkW8HcjwkSSdGdjW4R2pkkLrc58W5mBbditxdj7XG2N",
            "symbol": "CHAN",
            "name": "CHAN Token",
            "decimals": 9,
            "logoURI": "https://solchan.io/chan-token-logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Mjv4JFw8pQ",
                "twitter": "https://twitter.com/Solchan_NFT",
                "website": "https://solchan.io"
            }
        },
        {
            "chainId": 101,
            "address": "DJC5ouG5JwrWzdwrEUhrGwB3Sd7oEqCbUJpxJy2v9RVf",
            "symbol": "B24",
            "name": "Liberator",
            "decimals": 0,
            "logoURI": "https://github.com/mdtdnb/Liberator/blob/main/Lib.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Pb6o2pJBvdqYJwXpsJnn3JfT3V1XgEq7zQBYH4djbf4",
            "symbol": "GRV3",
            "name": "Grav3Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/boijoely/grav3/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4LwFLmS7MUBxoqRPDgmMSDjyA63gbZ1e317JmAqiuzbS",
            "symbol": "N2NK",
            "name": "N2Network",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nn9uy3n0326/cypto-logo/main/n2networkcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5AUZ4YM9LieuSiRV9QkSGQ9McvCbs8Dma8YpzhphDD93",
            "symbol": "DGZ",
            "name": "IditaCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Bitibles/token-logos/master/DGZ/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://iditarod.com"
            }
        },
        {
            "chainId": 101,
            "address": "BqWTiSenPeBrxSZZhRhuBpTq4kZrnJso9wmby9CSQTh3",
            "symbol": "AHZGLD",
            "name": "AHZUGOLD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ahzu-Direct-Tech/crypto/main/Ahzu-Sol-Token.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "1E7ipicsn5zAi5P9rYXwYToJV1EVXfDHuYndQ1ctzDR",
            "symbol": "RDD",
            "name": "R1D2 Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/maplion/token-logos/master/RDD/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5yULE5Fq8Zfeg74sYqkieuMD9bdzdZz8Mcb1T6gYzneA",
            "symbol": "Chimaek",
            "name": "Chimaek",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Chimaek-token/logo/main/chimaek_logo.png",
            "tags": [
                "community-token",
                "K-Pop",
                "K-Drama"
            ],
            "extensions": {
                "description": "Community token for those who love k-pop, k-dramas, Chicken \u0026 Beer, etc...",
                "discord": "https://discord.gg/Q7PgF2S3W5",
                "twitter": "https://twitter.com/Chimaek_Token",
                "website": "https://chi-maek.com"
            }
        },
        {
            "chainId": 101,
            "address": "E5TT39UcytqDkHy4xq24JjjWhQrWjhT8yf4XeCfz1v2F",
            "symbol": "FLPZ",
            "name": "Flappyzcoin",
            "decimals": 4,
            "logoURI": "https://solnetwebapistorage.blob.core.windows.net/images/flappyzcoin.png",
            "tags": [
                "utility-token",
                "community-token",
                "game-token",
                "gaming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FlappyzApp",
                "website": "https://www.flappyz.io"
            }
        },
        {
            "chainId": 101,
            "address": "ssdmMTQJ2e8G5EbPabaQ9X7p1ZwwHSw3n8fQnuJ55b1",
            "symbol": "SS-COUPON",
            "name": "Sol Stone Coupon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ssdmMTQJ2e8G5EbPabaQ9X7p1ZwwHSw3n8fQnuJ55b1/logo.jpg",
            "extensions": {
                "website": "https://solstone.contrastive.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "7RivgLYo6Shp1p4GWUGf5hVV6ZnuK5i7F1xThNev4em6",
            "symbol": "SAD",
            "name": "Sad Boi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tobaccoblonde/sadboicoin/main/sadbois.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZy8LAqypYdQLYyyDnDqhebK1FjEfrNqQYt6motchaCo",
            "symbol": "LOKI",
            "name": "Lokesh Sathiyamoorthi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lokesh-Sathiyamoorthi/My_Cryptocurrency/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHKL3aXvhhK4dDu12wWfc5kQgdpqyJJwbS4PqrPL5Fg",
            "symbol": "HANI",
            "name": "Hanikey Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hanikey01/hani/main/HANI.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3E4YuX7XdEVCF54KtimWVBhBpeN5P2dehGnCff65q559",
            "symbol": "STRAYAPE-WL",
            "name": "Stray Ape WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3E4YuX7XdEVCF54KtimWVBhBpeN5P2dehGnCff65q559/Logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/strayapes",
                "twitter": "https://twitter.com/StrayApesNFT",
                "website": "https://strayapesnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "GGQmwvoYRUbRs1p8azv7goikFJAJThjm7oW4tDTYCsdR",
            "symbol": "KONGWL1",
            "name": "Sol Kongz Remint 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGQmwvoYRUbRs1p8azv7goikFJAJThjm7oW4tDTYCsdR/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolKongz",
                "website": "https://www.solkongz.net/"
            }
        },
        {
            "chainId": 101,
            "address": "YLB6H9s99Y9HMARGM5zYpBv59fr1qtooZY1Qo7vpf4n",
            "symbol": "KONGWL2",
            "name": "Sol Kongz Remint 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YLB6H9s99Y9HMARGM5zYpBv59fr1qtooZY1Qo7vpf4n/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolKongz",
                "website": "https://www.solkongz.net/"
            }
        },
        {
            "chainId": 101,
            "address": "ayVMWBtmzPM48HAP1f5BSyvVWnqJWs4y2TEDbKNUGMo",
            "symbol": "$NOW",
            "name": "SNOW Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ayVMWBtmzPM48HAP1f5BSyvVWnqJWs4y2TEDbKNUGMo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PolariansNFT",
                "website": "http://polarians.io/"
            }
        },
        {
            "chainId": 101,
            "address": "X2m83B2T6y92qcq1am2z3FKXCzzmKNVxUGXxFWa7x8c",
            "symbol": "MRB",
            "name": "MetaRoyalBank",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JORJE-LOCO/logo.png/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DjtQv62kUfsZC7n7ejfeRwkjWmhaLnCDwNuDpQLLFNKH",
            "symbol": "LONG",
            "name": "Longcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mutulong/crypto-test/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D9hidBDDauvAYWY9jkNt6YfPxtcC7HgWm5sNNHAobC3A",
            "symbol": "NAC-WL",
            "name": "NAC WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D9hidBDDauvAYWY9jkNt6YfPxtcC7HgWm5sNNHAobC3A/logo.jpg",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EptKq9tJwRjhg4MLZc9TMdyngNnLqpSkc5cDbeX7PX1f",
            "symbol": "QTOC",
            "name": "Quito Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MSAdair/Quito_Coin/main/condor3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Fn19ad6suzpBHbfRNXGRbrUT1jTwEcJMR4R21vPL7e3",
            "symbol": "SATAHACK",
            "name": "Hacklab Pori",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Satakunnan-Tietojenkasittely-yhdistys/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/porihacklab",
                "website": "https://www.satkynet.fi/tag/satahack/"
            }
        },
        {
            "chainId": 101,
            "address": "HYUo89nCzFqyhefsimzBTnX9puAbQ9vMZqdvYrbsrinr",
            "symbol": "AD2",
            "name": "Asian Dragon",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/asiandragoncoin/official-logo/main/AD2-SOLANA/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/hHgvv9JB8e",
                "facebook": "https://www.facebook.com/asiandragoncoin",
                "twitter": "https://twitter.com/CoinAsian",
                "website": "https://www.asiandragoncoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "Dv3LwwDe6QgVCuZ2nYvj29MZsNgDpwMFLhQiVPpjAFqk",
            "symbol": "BT",
            "name": "Bell Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dv3LwwDe6QgVCuZ2nYvj29MZsNgDpwMFLhQiVPpjAFqk/logo.png"
        },
        {
            "chainId": 101,
            "address": "6kPEua21Vhs41cPkEFieQ8cdyL9ugYicSzgAxu21kfDx",
            "symbol": "$monkeland",
            "name": "Monkeland Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kPEua21Vhs41cPkEFieQ8cdyL9ugYicSzgAxu21kfDx/logo.png",
            "extensions": {
                "website": "https://apesagainst.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2dbSAv86RN3qh2tcfUPkh8iaYNYu917xjxwKbb2oVdG9",
            "symbol": "WAW",
            "name": "wafuWafu Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Viata0/Cryto/main/mango.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "78rgT17v4G3XNoyo7tDWoV6Bwr1Jq6tdCtuK4nUcj5oZ",
            "symbol": "GG",
            "name": "GILBERT'S GARDENS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78rgT17v4G3XNoyo7tDWoV6Bwr1Jq6tdCtuK4nUcj5oZ/gg520x520.png",
            "tags": [
                "reward-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hjibp1cn2bSk1dkTdpbxez3YAiBGTLjzc8xZ8LbCCUHS",
            "symbol": "FRANCIUM-CP",
            "name": "Francium Contribution Point",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hjibp1cn2bSk1dkTdpbxez3YAiBGTLjzc8xZ8LbCCUHS/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "description": "Community token for those who contribute to Francium",
                "discord": "https://discord.gg/francium",
                "twitter": "https://twitter.com/Francium_Defi",
                "website": "https://francium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CARoTGvYPajELZsoLQSovLY8fZmBkrrUoyJVJN3zGwQT",
            "symbol": "$CAROT",
            "name": "CAROT COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CARoTGvYPajELZsoLQSovLY8fZmBkrrUoyJVJN3zGwQT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/bobbyrabbits",
                "instagram": "https://www.instagram.com/bobbyrabbits/",
                "twitter": "https://twitter.com/bobbyrabbits",
                "website": "https://bobbyrabbits.com"
            }
        },
        {
            "chainId": 101,
            "address": "WL9XzGRaUwrZmPQD7ank5KM2z9U87WcWQEYo6Xk4bnZ",
            "symbol": "TMDCWL",
            "name": "TMDC Whitelist Token (TMDCWL)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLT8odEsvbXvdmZ7be6vfXpXgYL8BNR83wiuT5nLz9E/logo.png",
            "tags": [
                "whitelist-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "wpcxhh4VTi16TkYMZx7ApM6ZKiejkZd7kW7PengKNLi",
            "symbol": "ARWL",
            "name": "Alien Rappers Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/wpcxhh4VTi16TkYMZx7ApM6ZKiejkZd7kW7PengKNLi/logo.png",
            "tags": [
                "utility-token",
                "mint-token",
                "whitelist"
            ],
            "extensions": {
                "description": "The Coin for Alien Rappers NFT for Whitelist.",
                "discord": "https://discord.com/invite/alienrappersclub",
                "instagram": "https://www.instagram.com/alienrappersclub/",
                "twitter": "https://twitter.com/AlienRappers",
                "website": "https://alienrappers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6Q3WxTssbpM7yQjAxZ9XKfJnoLXAa5xw6Rcd6FVpRkc",
            "symbol": "CNPC",
            "name": "Cannapuff Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rthomas98/cannacoin/main/cannapuff-coin.png",
            "tags": [
                "cannabis-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AG4NpZGm8WXHTcxC9sdhWQMFGRxgsrG4sPaAHHcwFxvH",
            "symbol": "SPWL",
            "name": "Solapoly NFT Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AG4NpZGm8WXHTcxC9sdhWQMFGRxgsrG4sPaAHHcwFxvH/logo.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solapolygame",
                "website": "https://www.solapoly.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G2zmqsR1V1HdccEFG1EZp6XidbArqDYUf9o5QZeHwkjt",
            "symbol": "VGC",
            "name": "VICION GROUP COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gabgarluc/viciongroup/main/VicionGroupCoin.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4n5mFx5kQAeZpi786B44yLWMfCNUNZ8us6UeRbuZoyc6",
            "symbol": "RcCHIP",
            "name": "RC CHIP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kakasonz112/robocock-token/main/assets/whitelist_token.png",
            "tags": [
                "SPL-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/robococknft",
                "twitter": "https://twitter.com/RoboCockNFT",
                "website": "https://robocock.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5io93Fx24MDPMybCUYxjLmfK1WaW33bWRqfvBnNgX6Ug",
            "symbol": "RNDIEWL",
            "name": "The Roundies Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5io93Fx24MDPMybCUYxjLmfK1WaW33bWRqfvBnNgX6Ug/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.theroundies.co"
            }
        },
        {
            "chainId": 101,
            "address": "6zZ3Eu3VeHcqCPJhDTGoJrufV6xrSVBCBTp8izgXNJHF",
            "symbol": "BLACK1",
            "name": "BLACK ONE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danncti/crypto--black-one/master/logo.png",
            "tags": [
                "currency",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HpPJW5kyYaHomubNnF7coBNZSGrpMkZeBke3aj62i23n",
            "symbol": "OME",
            "name": "OneMeta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/OneMetaLand/crypto/main/ome_logo.png",
            "tags": [
                "social-token",
                "community-token",
                "currency-token",
                "utility-token",
                "Metaverse-Token"
            ],
            "extensions": {
                "website": "http://onemeta.land"
            }
        },
        {
            "chainId": 101,
            "address": "EQLUZF1Cx2NgBSqNTBbBQ6CRgrUQ6NUYzwiRDV5GQ6no",
            "symbol": "PIGFLY",
            "name": "PIGS FLY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dtmaiale/crypto/main/pig.png",
            "tags": [
                "currency",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CPvPSJbnZE1VHXtHHpEEM6qMSMgqph5cGycinu1pNczX",
            "symbol": "GNET",
            "name": "GameNET Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Gnetds/Crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "currency-token",
                "utility-token",
                "Metaverse-Token"
            ],
            "extensions": {
                "website": "http://gamenet.mx"
            }
        },
        {
            "chainId": 101,
            "address": "3wSBMG1CpUDFAQDfUcEkTCbhMZhbznWwzzcWThPNEwJj",
            "symbol": "PxLo",
            "name": "PeaceLove Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PeloKoin/Pelo/main/PXL.png",
            "tags": [
                "Faith-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6dqaoHQNsmbTd11JGqDKhyoNQ36rnRTRmWtxaAqA9fBV",
            "symbol": "OOAA",
            "name": "OOAA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6dqaoHQNsmbTd11JGqDKhyoNQ36rnRTRmWtxaAqA9fBV/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2kFqn5SH9Ecpv9mQo2WduUoZhARetELy3WNM4iUacjDT",
            "symbol": "TOXIC",
            "name": "TOKENX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2kFqn5SH9Ecpv9mQo2WduUoZhARetELy3WNM4iUacjDT/logo.png",
            "tags": [
                "tokenx"
            ]
        },
        {
            "chainId": 101,
            "address": "3Py5nZKoNuotXBcYTiDrLVQeWJzgryHWyiED4JRckEEs",
            "symbol": "MICROCOCK",
            "name": "MICROCOCK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kakasonz112/robocock-token/main/assets/wl_logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/robococknft",
                "twitter": "https://twitter.com/RoboCockNFT",
                "website": "https://robocock.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GkSjBhh6G5BZ6zwp22Re33j2i6wbzZUANSoA7rbru8cu",
            "symbol": "MIGHTY",
            "name": "BamBam Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FreddyMart/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DcYfKgKsGtZ6YKXLRBDSn6qxc8Us4VYZDsqsU8EiDEeD",
            "symbol": "YNWL",
            "name": "Yukan Neko Whitelist Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/926535226820276264/944843273275584552/Untitled_Artwork.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/YukanNekoNFT"
            }
        },
        {
            "chainId": 101,
            "address": "H5K7BAvYMtkA6yw2ceQ9uhVNcSg821oYoiYZGiKWkWDT",
            "symbol": "OXY",
            "name": "Oxy Family WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H5K7BAvYMtkA6yw2ceQ9uhVNcSg821oYoiYZGiKWkWDT/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "21myCu8fLtojSwjNvZnQWFCB3ZjL2bKM6z5axgp4KnAU",
            "symbol": "PDX",
            "name": "PDX Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PDX-Coin/PDX-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "currency-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.pdxcoin.info"
            }
        },
        {
            "chainId": 101,
            "address": "BMgrcLbpmRxTyj6b3FsCrpJB2XRftKewm5YBBu7tK11w",
            "symbol": "SCR",
            "name": "SCAR",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BMgrcLbpmRxTyj6b3FsCrpJB2XRftKewm5YBBu7tK11w/logo.png",
            "tags": [
                "investments",
                "reward"
            ],
            "extensions": {
                "twitter": "https://twitter.com/scarcoins",
                "website": "http://www.scarco.in/"
            }
        },
        {
            "chainId": 101,
            "address": "H45osYSkBiWiDr75JzzGjXzfHdwQAQ1rK1Ymb3k6srbn",
            "symbol": "FRDKN",
            "name": "fredfiftythreeKoin-Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/shines69/Fred53Koin-Token-2/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E7XfsVVwVJMounVHJuW6U2uHK3mHc3eNQrW5nmjHoZYw",
            "symbol": "$NEXT",
            "name": "Next Chapter Community Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E7XfsVVwVJMounVHJuW6U2uHK3mHc3eNQrW5nmjHoZYw/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://nextchapter.to/"
            }
        },
        {
            "chainId": 101,
            "address": "FYmiFBiUTkmFoY7duWgGE21VHxyUazZN15HJLaHBKEKq",
            "symbol": "MarsWL",
            "name": "MARS WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYmiFBiUTkmFoY7duWgGE21VHxyUazZN15HJLaHBKEKq/logo.png",
            "tags": [
                "currency",
                "game-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/channels/932827372464513085/943416696964780132",
                "website": "https://www.martianarmy.space/"
            }
        },
        {
            "chainId": 101,
            "address": "ssr1CpV54AngxBAeeTpPNgmAACf3VpdTQJtCdgMRFSC",
            "symbol": "BRCRS",
            "name": "Brickers WL Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ssr1CpV54AngxBAeeTpPNgmAACf3VpdTQJtCdgMRFSC/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://www.brickers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7LFgbAxGkVsFNWsDrFTU2itczmaSeAdbCjWxcco7nKB3",
            "symbol": "MTV",
            "name": "Mysterious Vegan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7LFgbAxGkVsFNWsDrFTU2itczmaSeAdbCjWxcco7nKB3/logo.png",
            "tags": [
                "Mysterious-Vegan",
                "Wallet",
                "MV-TOKEN",
                "utility-token"
            ],
            "extensions": {
                "website": "https://mysteriousvegan.com"
            }
        },
        {
            "chainId": 101,
            "address": "4KTss3DuyjwuvsZF9RAGr2huYDsktHPwmznZRaQWm7kc",
            "symbol": "CLNS",
            "name": "Clenos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hotmaildhiraj/clenos/main/clenos.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BvJuCsGXoN1UcuUrsNNjJ76MxWq1PSQmbvvermKEfzZ6",
            "symbol": "KNTRN",
            "name": "KentronKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChristosVoutselas/KentronKoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Code7hV6DaK5Werof8c7vPwBxLvhmEWVUbU2AfhBZArB",
            "symbol": "CODE",
            "name": "Code",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Code7hV6DaK5Werof8c7vPwBxLvhmEWVUbU2AfhBZArB/logo.png",
            "tags": [
                "defi",
                "currency",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/pSCsYD5x",
                "telegram": "https://t.me/codenjobsgroup",
                "twitter": "https://twitter.com/codenjobs",
                "website": "https://www.codenjobs.com"
            }
        },
        {
            "chainId": 101,
            "address": "FJauoY5QAHqdQwkpyqSeUu75ZNQUJieEtqC5oqkFkwTD",
            "symbol": "YSol",
            "name": "YSol Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Y-Token/Ytoken-Logo/main/Ysol%20logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4TYSPUxq4U8q4rokZ7M3mo6RQRRYZDexhvZ6bm8Kh4GW",
            "symbol": "MYLO",
            "name": "Mylo Angels",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iiizzzyyy/mylo-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg",
            "symbol": "XGLI",
            "name": "Glitter Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg/logo.svg",
            "tags": [
                "community-token",
                "social-token",
                "utility-token",
                "security-token",
                "governance-token"
            ],
            "extensions": {
                "coingeckoId": "glitter-finance",
                "discord": "https://discord.gg/P3mZkjsQMM",
                "linkedin": "https://www.linkedin.com/company/glitter-finance",
                "medium": "https://medium.com/@Glitter-Finance",
                "telegram": "https://t.me/GlitterFinanceGlobal",
                "twitter": "https://twitter.com/GlitterFinance",
                "website": "https://glitter.finance"
            }
        },
        {
            "chainId": 101,
            "address": "6jh8X7EAdUhTN5LhPNwPZFNLmDt21Z3n1WdKTCS1vHCn",
            "symbol": "BRAWN",
            "name": "Brawny Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mikesquad/token-list/main/assets/mainnet/6jh8X7EAdUhTN5LhPNwPZFNLmDt21Z3n1WdKTCS1vHCn/CoinLogo2.png",
            "tags": [
                "social-token",
                "meme-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JetNnLZpbECCfyeZSvJdgg5gf37QfCkPrp2X8XfmNWn",
            "symbol": "JET",
            "name": "BHSG Jetpack Claim Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JetNnLZpbECCfyeZSvJdgg5gf37QfCkPrp2X8XfmNWn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bountyhunterspaceguild.com"
            }
        },
        {
            "chainId": 101,
            "address": "9y5nCppbmKvdErJ6GwVVzqEcn3zSwEpsdakNSmQqKJ9y",
            "symbol": "MONY",
            "name": "Monies",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brokenstein/monies/main/Monies.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLTzkAbFCUonfz9h4eF6nUcWncYckAjd94urxvnEFd8",
            "symbol": "TW",
            "name": "Toon Worlds",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micku92/token-list/main/assets/mainnet/WLTzkAbFCUonfz9h4eF6nUcWncYckAjd94urxvnEFd8/logo.png",
            "tags": [
                "SPL-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Vz59hBBK",
                "twitter": "https://twitter.com/ToonWorlds_NFT",
                "website": "https://toonworldsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "H3pWoh5Te12nHYVSQm1vQC6aAn2EbADj8zit23jP2jX3",
            "symbol": "FAC",
            "name": "FAC FUD Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MundoFacu/stuff/038660d9336d647a9d4ede0e3107de9c9481724d/FAC_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CoCrfJfccDLXxdd4HSVynLGKavvBPPHjJEGphwXZTFyn",
            "symbol": "OG",
            "name": "CoC OG token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CoCrfJfccDLXxdd4HSVynLGKavvBPPHjJEGphwXZTFyn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://catsoncrack.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "CoCrDWPkZwDtNuoVticQWhrdUVoBKEgJN1c6pYihB2vD",
            "symbol": "PBL",
            "name": "CoC public token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CoCrDWPkZwDtNuoVticQWhrdUVoBKEgJN1c6pYihB2vD/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://catsoncrack.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "5ahu5wPznx1VFp696Fcw3y1sLeFL7evy8UvYwWmWxTbQ",
            "symbol": "PINE",
            "name": "PineCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/eheaivilin/images/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "84eyzKVHsE3gbPU9Wknkr4AGxdrSkfQfvmCLc5bH9XWD",
            "symbol": "HUNT",
            "name": "Treasure Hunt",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/84eyzKVHsE3gbPU9Wknkr4AGxdrSkfQfvmCLc5bH9XWD/logo.png"
        },
        {
            "chainId": 101,
            "address": "FQv1XRDxPGKJqG5jj7qhQ1PzhW4xnZNPf2yRyVH4BaLZ",
            "symbol": "ANS",
            "name": "Ananas Coin",
            "decimals": 0,
            "logoURI": "https://github.com/ananas-coin/Ananas-Coin/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HfBA9FHCvndVj7DVCfSvAHRZuR5JnT2EKwnsoL6jTwXG",
            "symbol": "JBB",
            "name": "BeckCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HfBA9FHCvndVj7DVCfSvAHRZuR5JnT2EKwnsoL6jTwXG/logo.png",
            "extensions": {
                "linkedin": "https://linkedin.com/in/jeanbaptistebeck",
                "twitter": "https://twitter.com/_jbbeck"
            }
        },
        {
            "chainId": 101,
            "address": "HExEqx48oPsvoDcS88vDyj7X9JW3tiy5kzLTHan9iYJE",
            "symbol": "EST",
            "name": "ESTRA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/vedranlo/estra/main/estra_logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "travel-token"
            ],
            "extensions": {
                "website": "https://estra.world"
            }
        },
        {
            "chainId": 101,
            "address": "unVasmPouDQNVvyvoGRyHMnNafhqLX23dUpbxrJnZvV",
            "symbol": "UNVAL-WL",
            "name": "Outlet Ticket - unValuables",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/unVasmPouDQNVvyvoGRyHMnNafhqLX23dUpbxrJnZvV/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sneakyslamberts",
                "website": "https://www.sneakyslamberts.com/"
            }
        },
        {
            "chainId": 101,
            "address": "de1QJkP1qDCk5JYCCXCeq27bQQUdCaiv7xVKFrhPSzF",
            "symbol": "DELFI",
            "name": "DeltaFi Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/de1QJkP1qDCk5JYCCXCeq27bQQUdCaiv7xVKFrhPSzF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/deltafi",
                "github": "https://github.com/delta-fi",
                "medium": "https://medium.com/deltafi",
                "telegram": "https://t.me/deltafi_labs",
                "twitter": "https://twitter.com/deltafi_ai",
                "whitepaper": "https://deltafi-s3.s3.us-east-2.amazonaws.com/DeltaFi_whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "WLDu1fuZ2dswJAizQg5BrN26oAKeCUiJ42x7uaep5WV",
            "symbol": "WLD",
            "name": "Whitelist Drogo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLDu1fuZ2dswJAizQg5BrN26oAKeCUiJ42x7uaep5WV/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/VFJVFXGxR8",
                "twitter": "https://twitter.com/Komododragonnft",
                "website": "https://solkomodo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "XkQoahtKbVC15hTczRFLRfZPtM1AFFjWs3uFE9sxDb4",
            "symbol": "ITA",
            "name": "ItalyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nemitoken/ItalyCoin/main/ita.png",
            "tags": [
                "management"
            ]
        },
        {
            "chainId": 101,
            "address": "4SSTwFJzRFNBLZBrNcieWYxyJ19bPSPZVZBy2eJKFURS",
            "symbol": "HUNT",
            "name": "Real Treasure Hunt ",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SSTwFJzRFNBLZBrNcieWYxyJ19bPSPZVZBy2eJKFURS/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DPJr1ErxEuHF2hT73DsJ4aVAv49SRsjpYiV8hRGTYQXa",
            "symbol": "FWOGGY",
            "name": "FwoggyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PineconeChamp/FwogyCoin/main/FwogyCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Qksbc7NWAXigX5r37LDehRVy92yYV28PJ1nkcCj58wo",
            "symbol": "XMT",
            "name": "Xovalien Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Edis27/XovaliensMintToken/main/coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FJKYciBS5CbskT7BdPnUmMJQz1rWQZocxw7F4UdP9XuF",
            "symbol": "PNC",
            "name": "PioneerCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/KingxRamos51/PioneerCoins53k/main/pioneerpetecoins53k.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJv2UCtM48qKqmkcTegLg696vXwhBoqfkNjueys4TN8X",
            "symbol": "DC",
            "name": "Dreamcity",
            "decimals": 0,
            "logoURI": "https://dreamcitynft.com/apple-touch-icon.png"
        },
        {
            "chainId": 101,
            "address": "4n6bk2d8yxY3NeuimCHhRr1ywP24o7Hcg8dqZWK9mZT2",
            "symbol": "KANISEK",
            "name": "KaniCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adifinek/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FqYPZmwUTCpGgNvLanhe6P7pcQr2mnKMLVjpWmZbV8B9",
            "symbol": "PINT",
            "name": "PINTO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wedd26/ryanpinto/main/RYAN3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3wx61Zfz7j5gtvTQCKistr2hsWNYMNKfJKqKfyAdK4fG",
            "symbol": "LBCN",
            "name": "LiberCoins",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/LiberCoins/Token-LiberCoin/main/libercoins.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AumBLHWYFrPsJn1yPj12XmTq19v4W8573zQ3Ja9zNm5v",
            "symbol": "PRAY",
            "name": "Prayer Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/loktar00/prayertoken/master/praytoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GLapb5CUwQ5pbDy9RLuEctFEjZuoNKsRvc95MGpKimCf",
            "symbol": "SLGT",
            "name": "Sundays Lab Golden Token",
            "decimals": 9,
            "logoURI": "https://arweave.net/pHrzP-Q4FhYr-nZRLnNLl0v1fUTbwnsIhxAERvoNf7Y",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sundayslab"
            }
        },
        {
            "chainId": 101,
            "address": "GmY2Rp9t5S4yD5jhgJrc47VSAa6hQiikkYi3sr9HLNZr",
            "symbol": "BLEEP",
            "name": "BLEEP",
            "decimals": 0,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmYjaWwcDx8gaf5rnkzGYLy8qhjjvuuvQXK8RttvXFWUWd?preview=1",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3HLeyffHyFKsLPJgoSL1Jq1u6tzuhgU2tAgbDBu9uFyb",
            "symbol": "NINJA/USDC",
            "name": "Raydium Fusion Pool Token (NINJA/USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HLeyffHyFKsLPJgoSL1Jq1u6tzuhgU2tAgbDBu9uFyb/ninja-lp.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://ninjaprotocol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5PUL5pfqnHqa3mb88txLhGSRadvYboBFQGEbEjCBkHQ8",
            "symbol": "MANA",
            "name": "MANA Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hotel-Six/Mana-Token/main/Logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DoKnmLSCe81mkvga7Vxn82F8NtvBdW3Qas4Rk22Wd3UG",
            "symbol": "DAWL",
            "name": "Dokan Apes WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NFTBrickLayer/assets/main/dokan-logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/YcthEaHXBs",
                "twitter": "https://twitter.com/DokanApesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "9jk4bVurWNPnVqPAN6jqP8UEvtzAxHtZ5ysSd3Npz9Mh",
            "symbol": "JCKY",
            "name": "Jocky Koin",
            "decimals": 0,
            "logoURI": "https://github.com/czcr/cozicoins/blob/main/JLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AHp6mR4Jm6UoogsY1aNYM1JgfpCvDhUvWBAQDnmVcxDw",
            "symbol": "LABNT",
            "name": "LA BONITA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LuckyLuis888/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GkppDJvtDJfE2SENMg9i6EvSTTgvmrcmnNJrNXSoZcbJ",
            "symbol": "UTS",
            "name": "UTSCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/utscoin/utscoin/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "8RhBF3P2TZn58f9ossvuqMC48DR2LJre1aMLazyMX79d",
            "symbol": "LDTMG1",
            "name": "LDT MG1 Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RhBF3P2TZn58f9ossvuqMC48DR2LJre1aMLazyMX79d/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "CDvWh6Dt7zfbEgQz5CHyxrrFFnJkzGHPoFkeYRzG6CNH",
            "symbol": "GRVT",
            "name": "Graviton Studio",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dgrig1/GravitonCrypto/main/Graviton.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "AQZRVC5xEg1PhTrstwTENkkJUEzJLDxN5nd57RbRtqC6",
            "symbol": "WLKN",
            "name": "Walken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az/logo.svg",
            "tags": [
                "walken",
                "utility-token"
            ],
            "extensions": {
                "website": "https://walken.io"
            }
        },
        {
            "chainId": 101,
            "address": "DPTchYSEMEDSjDyk81xyBeWaon7oLtFtkQ2FptPGyCAe",
            "symbol": "TWL",
            "name": "TigerBeing WL",
            "decimals": 0,
            "logoURI": "https://arweave.net/dtrMgdRPaWXCmfKHRB87aSvYDwGt3GAB3HC1cYWa7rk",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/jXfANfzkFb",
                "instagram": "http://instagram.com/tigerbeing_io",
                "twitter": "https://twitter.com/tigerbeing_io",
                "website": "https://tigerbeing.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GwYERALLKMycyksMAYVNvuv58bN5Z4yZfTNhPsRMm9aX",
            "symbol": "PHB",
            "name": "PornHub Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/PornHub-Coin/main/Logo.png.png",
            "extensions": {
                "twitter": "https://twitter.com/pornhub",
                "website": "https://pornhub.com"
            }
        },
        {
            "chainId": 101,
            "address": "GNVwLCbUhpHxqYgtjbq1fa5KViYtdhsN7Cw11cKhPNe9",
            "symbol": "WTF",
            "name": "WelcomeToFavelas Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/WelcomeToFavelas-Coin/main/Logo.png"
        },
        {
            "chainId": 101,
            "address": "6Mt8jADDUPckFf3hQfsxrrZGyov7sdfLAiD9oeLVcBkk",
            "symbol": "DTH",
            "name": "DTH WLTicket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Mt8jADDUPckFf3hQfsxrrZGyov7sdfLAiD9oeLVcBkk/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/soldiamondheads",
                "website": "https://diamondtownheads.io"
            }
        },
        {
            "chainId": 101,
            "address": "FBiVu8g69ghfnrU58ouVqh3dfZAiqGLWNUop7gXwv4Ce",
            "symbol": "BLOCKSTONE",
            "name": "Trust Wallet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBiVu8g69ghfnrU58ouVqh3dfZAiqGLWNUop7gXwv4Ce/trustwalletcards.png",
            "tags": [
                "trust-wallet",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://blocksto.ne/trust-wallet/"
            }
        },
        {
            "chainId": 101,
            "address": "9zwqWdo8hyQS3Zjx87AtviD9ooD38XWKtd5FTaLAK9Js",
            "symbol": "PIT",
            "name": "The Pit Bosses",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/pitbuli.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Currency for the Pit Casino",
                "website": "http://www.pitbosses.vip/"
            }
        },
        {
            "chainId": 101,
            "address": "C76wG8mcoJEg8zSXn35ZP4r5wczXGhSjzBhSXva9y7g",
            "symbol": "RF",
            "name": "Robot Factory",
            "decimals": 2,
            "logoURI": "https://github.com/iam314/RF/blob/main/RF-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bfz47crqn1GbobhcXPN8TNCz7sibLCQsrimeoJAewq3W",
            "symbol": "BGSC",
            "name": "Bingo Star Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/changman/bingostarcoin/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "7vgM43XjNMgdWecddTcpwYNmk8tK4EdfGvyThisb99FR",
            "symbol": "SOL-GUARANTEE",
            "name": "Golden SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vgM43XjNMgdWecddTcpwYNmk8tK4EdfGvyThisb99FR/gold.png",
            "tags": [
                "guarantee-payment",
                "Golden-SOL"
            ],
            "extensions": {
                "website": "https://blocksto.ne/golden-sol/"
            }
        },
        {
            "chainId": 101,
            "address": "2Bgp5rK6XjfFZKo4Tm7TfErg8iDmHWJspXhnD4tWaVez",
            "symbol": "TYO",
            "name": "Tokyo Coin",
            "decimals": 0,
            "logoURI": "https://github.com/t0ky0nerd/crypto/blob/main/TokyoCoinIcon.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fqg9BrFR6d2WD1FmpBfNxotM6woqeizFK7m5URpDSxuz",
            "symbol": "Corrupt",
            "name": "Corrupt Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fqg9BrFR6d2WD1FmpBfNxotM6woqeizFK7m5URpDSxuz/Logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/CorruptedSkulls"
            }
        },
        {
            "chainId": 101,
            "address": "AtrBRuv63i83AwzTrnuh9HTeN5K3UGUveRTniiwrdyYb",
            "symbol": "CZCR",
            "name": "Cozi Coin",
            "decimals": 0,
            "logoURI": "https://github.dev/andy-dey/token-list/blob/3ff94f8f0def5d0fc4acb4568c65a71b4aac612d/assets/mainnet/AtrBRuv63i83AwzTrnuh9HTeN5K3UGUveRTniiwrdyYb/CC-Logo.png"
        },
        {
            "chainId": 101,
            "address": "LUaAb8dKBd3oye8PdSZtCmr3wQJbcXop4P3bCm434xc",
            "symbol": "LUT",
            "name": "Launchlabs Unlimited",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUaAb8dKBd3oye8PdSZtCmr3wQJbcXop4P3bCm434xc/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "website": "https://launchlabs.app/"
            }
        },
        {
            "chainId": 101,
            "address": "LHTZ1E5tcZRK786zwrpcUsGivU6vemQaKBeXuiVD6tP",
            "symbol": "LHT",
            "name": "Launchlabs Holder Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LHTZ1E5tcZRK786zwrpcUsGivU6vemQaKBeXuiVD6tP/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "website": "https://launchlabs.app/"
            }
        },
        {
            "chainId": 101,
            "address": "ECRJG22BfVD5wmGZeCNy6f74zdmzGR5DQFczqMypkpfe",
            "symbol": "EURO-GUARANTEE",
            "name": "Golden EURO",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ECRJG22BfVD5wmGZeCNy6f74zdmzGR5DQFczqMypkpfe/gold.png",
            "tags": [
                "Golden-euro",
                "Guarantee"
            ],
            "extensions": {
                "website": "https://blocksto.ne/golden-euro/"
            }
        },
        {
            "chainId": 101,
            "address": "7LCo9Kv3rP6F2uHtSbh1ze6K1eDV47S8phMwr8mA9Dne",
            "symbol": "GTHK",
            "name": "GhostTown",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CdcRwbFuj3YNJYdfUqh3hnxFz1fuF6he1Wgz7JvZMHda/logo.png",
            "tags": [
                "game-token",
                "social-token"
            ],
            "extensions": {
                "description": "Token for the P2E game",
                "website": "https://ghosttown.fun/"
            }
        },
        {
            "chainId": 101,
            "address": "ByvbXb847KX2MpYraz7daZyS7yv8k2ZyckdTTgDscNuf",
            "symbol": "GUARANTEE",
            "name": "BLOCKED SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ByvbXb847KX2MpYraz7daZyS7yv8k2ZyckdTTgDscNuf/gold.png",
            "tags": [
                "Blocked-SOL",
                "Guarantee"
            ],
            "extensions": {
                "website": "https://blocksto.ne/blocked-sol/"
            }
        },
        {
            "chainId": 101,
            "address": "3Kxcywq58u49CnsF3n1nQt7viZ3ALXxfduc8jfmFPNai",
            "symbol": "CYBERS",
            "name": "CyberStorm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CyberStorm-Game/CyberStorm-Crypto/main/CyberStorm.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HuMShjViKhcfihmHkgvctcFAyeyxAk8hK5K58zWpuRKf",
            "symbol": "DREAM",
            "name": "DREAM TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HuMShjViKhcfihmHkgvctcFAyeyxAk8hK5K58zWpuRKf/logo.png",
            "tags": [
                "Dreamapes"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DreamapesNFT",
                "website": "https://dreamapes.intersect.art/"
            }
        },
        {
            "chainId": 101,
            "address": "DYu4rwdqSRmeastBxQVrsRYzM6bZMGUMSB6cDfWNarw9",
            "symbol": "POS",
            "name": "Worthless",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/tommyl1258/crypto/main/cyptoImage.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "64mrJr7bZa85ZtNjDWySUAnA66Dd7jNJNqu7MugsKKyz",
            "symbol": "VZX",
            "name": "VerZioX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64mrJr7bZa85ZtNjDWySUAnA66Dd7jNJNqu7MugsKKyz/logo.png",
            "tags": [
                "gaming-token",
                "metaverse"
            ],
            "extensions": {
                "description": "VerZioX is a FPP TPP Blockchain Metaverse That Allows Players To Earn With Distinctive Virtual Adventures.",
                "github": "https://github.com/VerZioX",
                "medium": "https://verzio-x.medium.com/",
                "telegram": "https://t.me/VerZioX",
                "telegramAnnouncements": "https://t.me/VerZioX_Announcements",
                "twitter": "https://twitter.com/VerZio_X",
                "youtube": "https://www.youtube.com/channel/UCxKQl1vt59EwvGRYuuJz30w"
            }
        },
        {
            "chainId": 101,
            "address": "6GyUtgM5ux2fa5kjnieQKgk1R1cwkLQ3SZ6c8MwiYLSs",
            "symbol": "SS",
            "name": "Smith Strong",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mattsmith4/crypto/main/smithstronglogo.jpg",
            "tags": [
                "social-token",
                "smith-strong"
            ]
        },
        {
            "chainId": 101,
            "address": "B469ASyzvGkUPCoi3n7cKnXkDiYbhR3Kg7hb1MJjZYfk",
            "symbol": "CoGC",
            "name": "CashOutCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CashOutGang1337/CashOutGangCoin/main/free-money-bag-icon-6384-thumb-removebg-preview.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FGr4QMQBWju7tA373pLdH8q8kRpA4uSzwE4f63NGryqH",
            "symbol": "PLEBCOIN",
            "name": "PlebCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGr4QMQBWju7tA373pLdH8q8kRpA4uSzwE4f63NGryqH/PlebCoin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "A coin that Plebs can spend"
            }
        },
        {
            "chainId": 101,
            "address": "5M1gA4nCkK6vHQvh2oGhMwFSCE3TgJNTZ1rhZR1a3o5o",
            "symbol": "KAWL",
            "name": "Kawaii Animal Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5M1gA4nCkK6vHQvh2oGhMwFSCE3TgJNTZ1rhZR1a3o5o/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BLSTrhpP73RnVocgRu3te1Z5bBy6kL78Cpg2WuACqFkS",
            "symbol": "BLAST",
            "name": "BHSG Blaster Claim Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLSTrhpP73RnVocgRu3te1Z5bBy6kL78Cpg2WuACqFkS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bountyhunterspaceguild.com"
            }
        },
        {
            "chainId": 101,
            "address": "9FvJe9t8E771DJuQmJQcqygsUHgYUnXMEbEoYqiNj9ky",
            "symbol": "ZEBRA",
            "name": "Zebra Barcelona Design",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9FvJe9t8E771DJuQmJQcqygsUHgYUnXMEbEoYqiNj9ky/logo.png"
        },
        {
            "chainId": 101,
            "address": "3QxMtHFY6x35Rmuh7SPSiP6EPNpFT1AEPz268KF3JH1V",
            "symbol": "GHC",
            "name": "GigaHub",
            "decimals": 9,
            "logoURI": "https://arweave.net/uHznKxrFiAG1QC2LgRhGf08ySUHLmRT3Yne4yAndXmg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/GigaHubOfficial",
                "twitter": "https://twitter.com/GigaHubOfficial",
                "website": "https://www.gigahub.gg/",
                "youtube": "https://www.youtube.com/gigahub"
            }
        },
        {
            "chainId": 101,
            "address": "FBbYSQqfLGdJwiUyyhH3BnXaXzZdGLLaoAaihdqBhAoK",
            "symbol": "KRENIUM",
            "name": "Krenium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/playsoulstone/logos/main/krenium.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "Currency for Soulstone",
                "discord": "https://discord.gg/playsoulstone",
                "twitter": "https://twitter.com/playsoulstone",
                "website": "https://playsoulstone.com/"
            }
        },
        {
            "chainId": 101,
            "address": "QVHEgfYuDB6hrFQwzTQZ3RsBxGX3yz2b7L1sa5S1xeV",
            "symbol": "SOULWL",
            "name": "Soulstone WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/playsoulstone/logos/main/wltoken.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Soulstone WL Token for Pass Mint",
                "discord": "https://discord.gg/playsoulstone",
                "twitter": "https://twitter.com/playsoulstone",
                "website": "https://playsoulstone.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AeFr98GrcpiK4jr6gNg5V4nBQsBbo7bxUg6LrCTgRhY5",
            "symbol": "AERE",
            "name": "AlexCrypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/A1234760/logos/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FvkVzKWPWMJRuP3XjSk2aKSmf5z7W9gWAtB2HAXoZaRi",
            "symbol": "LBRT",
            "name": "AlbertCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lbrtcoin/lbrt/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3aq3eikS1x7oMYWFNYXiQyhQ5KfL7khBmoaBC3Vist4X",
            "symbol": "OWY",
            "name": "Ownify",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aq3eikS1x7oMYWFNYXiQyhQ5KfL7khBmoaBC3Vist4X/logo.png"
        },
        {
            "chainId": 101,
            "address": "Fp1ZyU4NTDvrPfBUtKFPMmSCS3Yp4KvPsTX2RscMCD5g",
            "symbol": "DDZ",
            "name": "Doryudragonz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fp1ZyU4NTDvrPfBUtKFPMmSCS3Yp4KvPsTX2RscMCD5g/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MMTXhAEoj23QQ22gYYTLfLzRhRTW58NNG5aTBXExjeE",
            "symbol": "MMWL",
            "name": "Miner Moose Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MMTXhAEoj23QQ22gYYTLfLzRhRTW58NNG5aTBXExjeE/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "kdmxBtSBBodcNXLvBXcYxYREpYaMCYLKcRiRFcjuc1v",
            "symbol": "KDM",
            "name": "Knightdom coin",
            "decimals": 9,
            "logoURI": "https://www.knightdomservers.com/logo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Knightdom",
                "discord": "https://discord.gg/hfaSQ4FY3k",
                "twitter": "https://twitter.com/Knightdomsol1",
                "website": "https://knightdom-evolved.com"
            }
        },
        {
            "chainId": 101,
            "address": "8ZGDbxp6iRYEsLajghuALAq25sEaXcaVepH9zQXGHinN",
            "symbol": "GALI",
            "name": "GalipeauCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ajgalipeau/galipeau-coin/main/galipeau_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DrogoV6nuMsCGfhqcVMCVxGZASajgXoxN7ytUcRCQgQs",
            "symbol": "DRG",
            "name": "Drogo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DrogoV6nuMsCGfhqcVMCVxGZASajgXoxN7ytUcRCQgQs/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/VFJVFXGxR8",
                "twitter": "https://twitter.com/Komododragonnft",
                "website": "https://solkomodo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8d22HrxhgditkRAtjhcQLKB7gwT94L3NuKQukEuRU5ax",
            "symbol": "NTC",
            "name": "NTC crypto MP20",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8d22HrxhgditkRAtjhcQLKB7gwT94L3NuKQukEuRU5ax/logo.png",
            "tags": [
                "security-token"
            ],
            "extensions": {
                "website": "https://thenationaltradecenter.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AEDrQREPVWLFnymPCrxDrgv4mP58CjBXDsc9YRaYbnfx",
            "symbol": "FMXC",
            "name": "Family Exchange Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEDrQREPVWLFnymPCrxDrgv4mP58CjBXDsc9YRaYbnfx/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "26KSs4cds9P3p2K5q6j8xGD2yzB1Wa2pzms7AHSMhG3s",
            "symbol": "ECO",
            "name": "EcoToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/26KSs4cds9P3p2K5q6j8xGD2yzB1Wa2pzms7AHSMhG3s/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MfBAZ6eQcW",
                "twitter": "https://twitter.com/THEecoToken",
                "website": "http://eco-token.io"
            }
        },
        {
            "chainId": 101,
            "address": "9PYyJoGv5WVht6X7i4znDnPjRKinRhFdiA27yFRRxXqB",
            "symbol": "BD",
            "name": "BlueDiamonds",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9PYyJoGv5WVht6X7i4znDnPjRKinRhFdiA27yFRRxXqB/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Native token of the BlueDiamonds marketplace",
                "twitter": "https://twitter.com/BlueDiamondsNFT",
                "website": "https://www.bluediamonds.app/"
            }
        },
        {
            "chainId": 101,
            "address": "TjvBEgrhTeCgoSV2TY9QkpFHv3VVcirHSuoT9Zya8uM",
            "symbol": "THE",
            "name": "The",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/the-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HhyziMR2vkGCVddCMM2AUw3D2pjXCoo7qrNkuQKoYBF5",
            "symbol": "WHY",
            "name": "Y",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/why-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FZ4kX4dDwhF8HSaczN8TkvcRNUZrtt4uyMDwCiHQye34",
            "symbol": "ZE",
            "name": "Z",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ze-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8DYP1ciF5mXCWFsbFfq7mPL15fXbFHcUUE7rnAY7Adbh",
            "symbol": "M",
            "name": "M",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/m-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3qnhg34K5A38NtQXrj3yxQhY3Gc1pMKSd32hAHkKNbKi",
            "symbol": "WIS",
            "name": "Wisdom",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/wis-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "77P22CtARq4rPGBW7suWJnWDhqJPHN85ZP6x9rXmK2n2",
            "symbol": "J",
            "name": "J",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/j-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GLVapw9LW3ekMfFFNwgt1GepnCAJUJATVadUZMi5Vm8d",
            "symbol": "GIDDI",
            "name": "Giddiness",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/giddi-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FbDc92eS9HzcMhWZb3nEtMH9nMLDxAnWnBG2fACENPCR",
            "symbol": "LIK",
            "name": "Like",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/lik-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DdRGrcVXQ2t5ma2eDhmvKrXcCYWyTop2TxtrXCrT1c3e",
            "symbol": "AND",
            "name": "And",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/and-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C4fxCuEcvvN1AL19tVtxdGc9GkChho1TB3zSF1QwsATQ",
            "symbol": "KRHN",
            "name": "Krahn",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/krhn-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7e1mJRetJSAj3nZDmjZFKoBGoRHSbbVogTsWSzTVdf5",
            "symbol": "KA",
            "name": "K",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ka-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "37c3ecpSo9zXu8SaUWKRLPEGAfR24gwxcEMMjpD9rPrj",
            "symbol": "REST",
            "name": "Rest",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/rest-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9NQGZmX19HkLFSvzT6X1BVmrSX1WtwxWbxHTfyDAdcty",
            "symbol": "JAM",
            "name": "Jam",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/jam-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6poRXXpCjk5UfLw4tz7vVHiK7MHHxQrjaUStmy65YtFq",
            "symbol": "MON",
            "name": "Monday",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/mon-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CfM5L3bx9Qp5KWoyjBZ67p2d6RFbzgUTrm865KKC9yPZ",
            "symbol": "APRV",
            "name": "Approval",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/aprv-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FNuzzAmiz8Mqspggzyt3fmm3CtjMw1175P2Bubfys8p1",
            "symbol": "X",
            "name": "X",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/x-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "59iYkEjvKmHUyNDBjVyVLV9PQViAeMi2rjxRuw4ZuUhv",
            "symbol": "S",
            "name": "S",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/s-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6E2wrGGFudaapNmmxE5BoQc3pNr3xJwe2zoNPw5VkjpB",
            "symbol": "FRI",
            "name": "Friday",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/fri-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "jWWi8vp5q8hcNdxQpqbJNMJ2aDpz5t8SoB1dkzYS7CL",
            "symbol": "BD$",
            "name": "Businessdogs Token",
            "decimals": 6,
            "logoURI": "https://i.redd.it/pap3i9ua8g421.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Businessdogs Token is used in Businessdogs P2E game on Solana",
                "twitter": "https://twitter.com/BusinessdogsP2E",
                "website": "https://businessdogs.eu/"
            }
        },
        {
            "chainId": 101,
            "address": "4fTHuf78kxMJQXXZbuMknYpLLsDAWPxtghPMJ4NiGh1e",
            "symbol": "LEOJ",
            "name": "Leocoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/leoj9099/cripto/8f26001f0c5169d5546d81c241f3880c856336ce/leeocoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EJuWBuoFjnrjKcgcgmegimSqS6RCyAh99EwMFfT2oo5",
            "symbol": "AMZ3",
            "name": "Amazon Tree Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EJuWBuoFjnrjKcgcgmegimSqS6RCyAh99EwMFfT2oo5/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BqT6xtDKXewmNFhf7E2bT6uzJvdwXJupgSurrnj2zt2z",
            "symbol": "KBT",
            "name": "KARBAR TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Karbar97/crypto/main/logo-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C4ug39iNuqRPzN6SWvbmwi8frSfbVdhmXLagJUic8akZ",
            "symbol": "SOLP",
            "name": "SolPensions",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solpensions/token-list/main/assets/mainnet/C4ug39iNuqRPzN6SWvbmwi8frSfbVdhmXLagJUic8akZ/logo.png"
        },
        {
            "chainId": 101,
            "address": "8ezfbtjtkHZPCqiLiSUbAr1eyVfRreNGwWFfXJRuhAu1",
            "symbol": "LBTC",
            "name": "LibertCoin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ezfbtjtkHZPCqiLiSUbAr1eyVfRreNGwWFfXJRuhAu1/logo.png",
            "tags": [
                "security-token"
            ]
        },
        {
            "chainId": 101,
            "address": "44wwZtdrnm2PsEYAQWUwFVQQMjTSkNouLU2o2jXJYxid",
            "symbol": "WG59",
            "name": "Wiseguy59",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ksyzman/wg59/main/3-2.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3HVG8MLMAsu1Rd7gEkQ2K7HrsjpzV6Em3hp5Ug1V3ds1",
            "symbol": "FRP",
            "name": "Filthy Rich Pups Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Filthy-Rich-Pups-NFT/assets/main/assets/frp_token.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3EW2p6Dy6a3zNTfcetFFopzvv253xQzMHstBJv7wCgSt",
            "symbol": "ARPT",
            "name": "Alien Rappers Prize Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EW2p6Dy6a3zNTfcetFFopzvv253xQzMHstBJv7wCgSt/logo.png",
            "tags": [
                "utility-token",
                "mint-token",
                "whitelist"
            ],
            "extensions": {
                "description": "The Coin for Alien Rappers NFT free mint.",
                "discord": "https://discord.com/invite/alienrappersclub",
                "instagram": "https://www.instagram.com/alienrappersclub/",
                "twitter": "https://twitter.com/AlienRappers",
                "website": "https://alienrappers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "A8fP2qLT1Min6pwP52LgV8j2UmuskwDUgSmVUGGyQ6o7",
            "symbol": "LILV",
            "name": "Lily Love",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kcam9908/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bxui1LezmVUhS5P7oo8YcLx11E1EgLk4CUbkFDTSXKTm",
            "symbol": "$BTCSEA",
            "name": "BTCSEA Trademark 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bxui1LezmVUhS5P7oo8YcLx11E1EgLk4CUbkFDTSXKTm/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4uUzZtoVvpdbq4yKSTcEZbu3LxohNhnoYBBksfFtqUcu",
            "symbol": "SEABTC",
            "name": "BTCSEA Trademark SEABTC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4uUzZtoVvpdbq4yKSTcEZbu3LxohNhnoYBBksfFtqUcu/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7XjqFj9B7NuTmnVqMtWb1fFg62bMTVAosnHuvavYotgs",
            "symbol": "$SEABTC",
            "name": "BTCSEA Trademark SEABTC 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7XjqFj9B7NuTmnVqMtWb1fFg62bMTVAosnHuvavYotgs/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8rnKM5ZHYtNRym2GKSnA7rgm9fUddDY4RZQtKus9h5Vc",
            "symbol": "BTSEA",
            "name": "BTCSEA Trademark BTSEA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8rnKM5ZHYtNRym2GKSnA7rgm9fUddDY4RZQtKus9h5Vc/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FBUfMhucixFJEFUh4CrDH5h5RwzCZP6Cs5CD8XZcuioC",
            "symbol": "$BTSEA",
            "name": "BTCSEA Trademark BTSEA 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBUfMhucixFJEFUh4CrDH5h5RwzCZP6Cs5CD8XZcuioC/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "38GtEzk73ic2YNX9eaoZk7CTmMF2jFcAjptRLoYkeFQf",
            "symbol": "BSEA",
            "name": "BTCSEA Trademark BSEA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38GtEzk73ic2YNX9eaoZk7CTmMF2jFcAjptRLoYkeFQf/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BBfGFC4Wu5wsPqeWxYZoA3M22ChcnVCvoTYCfHT7EYBK",
            "symbol": "$BSEA",
            "name": "BTCSEA Trademark BSEA 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BBfGFC4Wu5wsPqeWxYZoA3M22ChcnVCvoTYCfHT7EYBK/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6wu9hyd6gEvAzfEE8r6gf3NMRPrZYQQ7tfgccbZRmWxX",
            "symbol": "BITSEA",
            "name": "BTCSEA Trademark BITSEA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wu9hyd6gEvAzfEE8r6gf3NMRPrZYQQ7tfgccbZRmWxX/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7GphmB52nxymz5aoUKk6MascqpoCoJLyPKCH9Dfbti4h",
            "symbol": "$BITSEA",
            "name": "BTCSEA Trademark BITSEA 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GphmB52nxymz5aoUKk6MascqpoCoJLyPKCH9Dfbti4h/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "G2PYNu3XS3XQndSof5DffP4QPCzcJ35SAB6a3WuYEgvL",
            "symbol": "BCSEA",
            "name": "BTCSEA Trademark BCSEA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G2PYNu3XS3XQndSof5DffP4QPCzcJ35SAB6a3WuYEgvL/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AhE4DhAUVtqHEFhMp8oRdRgcyCg6Qk3G45QbaQ4vyshB",
            "symbol": "HOLLO",
            "name": "Hollo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lddcoding/crypto/main/download.jpg?token=GHSAT0AAAAAABR2FOB7KI2KXGMO6PBLGSEWYQUIKAQ",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "24GFfEuzkowwZTZubpATywEhNYBqvPyAwmgRjBesEM4q",
            "symbol": "BEACON",
            "name": "Sol Visitors Beacon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gserafini/solvisitors/main/coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Beacon is a utility token used in the Sol Visitors universe.",
                "discord": "https://discord.gg/TF7zW5q9Ur",
                "instagram": "https://www.instagram.com/solvisitors/",
                "twitter": "https://twitter.com/SolVisitors",
                "website": "https://solvisitors.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G6F2CBnm4zWb4QieAZkgw83Y19bMv3sBGMQjxt3Z62Qs",
            "symbol": "$BCSEA",
            "name": "BTCSEA Trademark BCSEA 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G6F2CBnm4zWb4QieAZkgw83Y19bMv3sBGMQjxt3Z62Qs/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "En5yRm9njDZBVZn1mHdRrWv9UgxrHmF53MwgaF28eEe6",
            "symbol": "SEABT",
            "name": "BTCSEA Trademark SEABT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/En5yRm9njDZBVZn1mHdRrWv9UgxrHmF53MwgaF28eEe6/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5wH8pmmyfFXJ95gWHC7MZpbJ4WjigKM2g2hiny6ckkCo",
            "symbol": "$SEABT",
            "name": "BTCSEA Trademark SEABT 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5wH8pmmyfFXJ95gWHC7MZpbJ4WjigKM2g2hiny6ckkCo/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4g2V1EznZfv87Hip8XYGmtT59CKZEvwJUnde8HXAGL9z",
            "symbol": "SEAB",
            "name": "BTCSEA Trademark SEAB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4g2V1EznZfv87Hip8XYGmtT59CKZEvwJUnde8HXAGL9z/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "mgJgz62i7d1iXK8ugT6a8XewrWo4s3QxcZt8pnzFtpr",
            "symbol": "$SEAB",
            "name": "BTCSEA Trademark SEAB 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mgJgz62i7d1iXK8ugT6a8XewrWo4s3QxcZt8pnzFtpr/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HMJKrgLEsQKy4usVDWQRBABReBf9e9YLyxEk3TJEi6XJ",
            "symbol": "SEABC",
            "name": "BTCSEA Trademark SEABC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMJKrgLEsQKy4usVDWQRBABReBf9e9YLyxEk3TJEi6XJ/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BEq1jCvCUNETAEhhz2txCVKzRXzKPwCK1GmbnoSxbCNq",
            "symbol": "$SEABC",
            "name": "BTCSEA Trademark SEABC 2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEq1jCvCUNETAEhhz2txCVKzRXzKPwCK1GmbnoSxbCNq/logo.png",
            "tags": [
                "btcnftsea-io",
                "trademark"
            ],
            "extensions": {
                "website": "https://btcnftsea.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BezfkxAoSXhTpdx2nuU5CoPVmVgmjNKAL4YThpY92VGw",
            "symbol": "AHA",
            "name": "SunnySideUp Event Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BezfkxAoSXhTpdx2nuU5CoPVmVgmjNKAL4YThpY92VGw/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HMnKqbxrfeBy4HV7yVrETefRoTwxKTe9uwCnTQCyqjxf",
            "symbol": "SAT",
            "name": "Samosa Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hacking-life/samosa-solana-crypto-token-data/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FoeJwGaHvudz1bB64Y5XJbrGSx1CTZV7SmPesn2zGDdk",
            "symbol": "JOR",
            "name": "Jorbtoken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LouisDeSimone/fiverrImagestokens/main/jorbtoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2jnRj78ouLDPMoerggzojuz8HHYHH7h9rq6G8SbVpcq7",
            "symbol": "TOPIA",
            "name": "Topia",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2jnRj78ouLDPMoerggzojuz8HHYHH7h9rq6G8SbVpcq7/logo.png",
            "tags": [
                "topia"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TopiaCryptoCoin",
                "website": "http://topiacrypto.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3JBbgfLJdp5NYjpQ5CdKYqCA9LCwimF7AMZsUyPoQ4Hk",
            "symbol": "TOOT",
            "name": "Toot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JBbgfLJdp5NYjpQ5CdKYqCA9LCwimF7AMZsUyPoQ4Hk/tootcoin.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6wrpsKQ7bHWRqg9paHfcWjepxVCajWvHwWNozoaQ4QqR",
            "symbol": "BMBA",
            "name": "Bomba Anarchica Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Bomba-Anarchica-Coin/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "meme-token",
                "bombaAnarchica-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8C3CwsUB1ePoWrgxJ2wd76D9byGWviYuK1gcfSTNUbnV",
            "symbol": "CRP/SOL",
            "name": "Cropper LP token (CRP/SOL)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7M1E2H2nYZRnFApXmqJiX2hXYBnXh4PEFaUvcnrTgQ3J",
            "symbol": "ARAUC",
            "name": "Araucaria",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ffurtuoso/tree/main/token/araucaria2.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EmaXkycuar2Ve2uXx6o1Gq5PVM3uPDuxaotW892VHxr4",
            "symbol": "GDJ",
            "name": "Good Job Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/biogwen/goodjob/main/Done.png",
            "tags": [
                "Community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FKckG972VE5yGEsHLwL9MfGEXTaYTvEn9KZ4Qf22dTgc",
            "symbol": "SOI",
            "name": "SOI Finance",
            "decimals": 9,
            "logoURI": "https://soi.ink/soi-token-logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://soi.ink"
            }
        },
        {
            "chainId": 103,
            "address": "2Q1WAAgnpEox5Y4b6Y8YyXVwFNhDdGot467XfvdBJaPf",
            "symbol": "tUSDC",
            "name": "Test USD Coin (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/USDC.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_",
                "website": "https://www.lp.finance"
            }
        },
        {
            "chainId": 103,
            "address": "Hv96pk4HkhGcbNxkBvb7evTU88KzedvgVy2oddBB1ySB",
            "symbol": "tBTC",
            "name": "Test Bitcoin (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/47faa0410931bbdee2164773bffa906060c45c82/BTC.svg",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_",
                "website": "https://www.lp.finance"
            }
        },
        {
            "chainId": 101,
            "address": "7TEcMnoVJtZgpWCdnLu8X15bTtnt4J2zZyBxfV7i2uw7",
            "symbol": "xDOGE",
            "name": "Synthetic DOGE",
            "decimals": 9,
            "logoURI": "https://www.synthetify.io/icons/xdoge.svg",
            "extensions": {
                "coingeckoId": "dogecoin",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "PkwrevuR6k3QHAdPUHWWhdqMmjgnHUvdSun9GJmsRvk",
            "symbol": "EDIN",
            "name": "Edinar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrreith/edinar/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3jb1kbPZjhFx9hdGE9CPs838B5UZDmrirbhw6aKMJmEA",
            "symbol": "HNYDRP",
            "name": "Honeydrop",
            "decimals": 0,
            "logoURI": "https://github.com/kipland-m/Honeydrop/blob/master/logo.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GKbCjKB7jLjUiSHPReXA6c7VkSRRt4iMeGqTqrSBMkPA",
            "symbol": "FROST",
            "name": "Frost",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nooballday8/tatocrypto/main/Frost.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GurTvmWDmaZmUTqMFsctZaKGVPocbDXyrT6UJiT5YR9X",
            "symbol": "STY",
            "name": "STRAY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GurTvmWDmaZmUTqMFsctZaKGVPocbDXyrT6UJiT5YR9X/Logo.png",
            "tags": [
                "gaming-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/strayapes",
                "twitter": "https://twitter.com/StrayApesNFT",
                "website": "https://strayapesnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "6VnsFohk6CQbaXoPTiQr5bHruEnuCqg94iwmnwrUj72M",
            "symbol": "IMMORTAL",
            "name": "IMMORTAL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DemonKingdomNFT/DemonKingdomN/main/DemonKingdomPFP.jpg",
            "tags": [
                "Community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/demonkingdomnft",
                "website": "https://demonkingdomnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "WLmzvH6aA31vA8hZ1bptVemSoiBc8fm6J4LRXzHZmCF",
            "symbol": "matE",
            "name": "MateysDAO 2/22 Whitelist token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLmzvH6aA31vA8hZ1bptVemSoiBc8fm6J4LRXzHZmCF/icon.png",
            "tags": [
                "social-token",
                "whitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "3mnScz1CPoHdtRxsakdT8LH7b4VC3AUBeUh1GyGZUqq1",
            "symbol": "NXA",
            "name": "NXA Bra",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cryptopher17/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SYDetWjb2SZ4G2PsEkdk1xX9Hmu4WV3Dn2CyGG2sKq9",
            "symbol": "ANYM",
            "name": "anymous3",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anymouse20/crypto/main/logo_size_invert.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F2YCY4WQqZY6qAiSfmwM9bjdnVhRsZ4oYA5fk6vVNLQv",
            "symbol": "CHELA",
            "name": "Chela Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/metadanx/chela/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5AHzHDUiamGAwiVs8pXJuJmigqJWopwtcp2voieL1oWP",
            "symbol": "CRPT",
            "name": "Corrupted Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/thefalseshad0w/crpt-token-/main/5AHzHDUiamGAwiVs8pXJuJmigqJWopwtcp2voieL1oWP/crpt.png",
            "tags": [
                "Community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Babychimps_nft",
                "website": "https://babychimps.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "DarFue7o1rT1vGwSjkJ9MpYKVMtyAQNUfR4dyUDvCRk9",
            "symbol": "VCOIN",
            "name": "Viking's coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/iLaD08/crypto/main/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "2MpDzukPY4UEhrPVQFXWXKw5UMJZvhYjmsEqeNLkTxzQ",
            "symbol": "MT",
            "name": "M-test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2MpDzukPY4UEhrPVQFXWXKw5UMJZvhYjmsEqeNLkTxzQ/MT.png"
        },
        {
            "chainId": 101,
            "address": "4qr4rD2w47LC2aYj1Dd1sjmEcf4r9VXLB3jvZYvu225i",
            "symbol": "A1C",
            "name": "Fenix Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Jacobb0319/cryptologo/main/A1CLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CkTT6bUw9DWtnrkhJmUPTgWB9TvVy4sHgX3mmFNw1v1C",
            "symbol": "NNTICKET",
            "name": "NEO-NINJA MINT TICKET",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkTT6bUw9DWtnrkhJmUPTgWB9TvVy4sHgX3mmFNw1v1C/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "website": "https://neo.ninjaprotocol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6HzndwRRT1Eamf8pFZxikuFPHcRkp6457fUcxFACZK7H",
            "symbol": "YDWK",
            "name": "YardCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/d-smith/notes/master/solana/shovels.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HmXgJwUzWfuvCZzUkbzVKYGggjjbq6qy1meKpPitj7Xu",
            "symbol": "GLORY",
            "name": "GLORIOUS TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/GloriousXican/ASSETS/main/coin-sglory.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GeckosNFT",
                "website": "https://gloriousgeckos.com/"
            }
        },
        {
            "chainId": 103,
            "address": "CWWjhM7bKQQGTJNbPGTfMYBdM8WkKgrePufgUKXzHqne",
            "symbol": "GAGO",
            "name": "GaGoats NFT Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWWjhM7bKQQGTJNbPGTfMYBdM8WkKgrePufgUKXzHqne/logo.png",
            "tags": [
                "gagoats-nft",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GaGoatsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "etCPUaMghWobVwxug5Vgxb6r3A7DEBdLKUywrnDrJeZ",
            "symbol": "JFICALL",
            "name": "May 1 JFI Call",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/etCPUaMghWobVwxug5Vgxb6r3A7DEBdLKUywrnDrJeZ/logo.png",
            "tags": [
                "option-contract"
            ],
            "extensions": {
                "description": "Psyoptions call option on the Jungle Finance token.",
                "website": "https://psyoptions.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SKu11EypaFU3gvr8VSAbi13zEC2CPvqbz9s83N3tWHM",
            "symbol": "SKULL",
            "name": "Skeleton Crew",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SKu11EypaFU3gvr8VSAbi13zEC2CPvqbz9s83N3tWHM/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/skeletoncrewrip",
                "twitter": "https://twitter.com/skeletoncrewrip",
                "website": "https://skeletoncrew.rip/"
            }
        },
        {
            "chainId": 101,
            "address": "Cfafd52FfHRA5FRkTXmMNyHZfhNkbaHpZ12ggmeTVEMw",
            "symbol": "cFAF",
            "name": "Friends and Family Council Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cfafd52FfHRA5FRkTXmMNyHZfhNkbaHpZ12ggmeTVEMw/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://find-and-update.company-information.service.gov.uk/company/13753949"
            }
        },
        {
            "chainId": 101,
            "address": "FAFDfoUkaxoMqiNur9F1iigdBNrXFf4uNmS5XrhMewvf",
            "symbol": "FAF",
            "name": "Friends and Family Community Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FAFDfoUkaxoMqiNur9F1iigdBNrXFf4uNmS5XrhMewvf/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://find-and-update.company-information.service.gov.uk/company/13753949"
            }
        },
        {
            "chainId": 101,
            "address": "rQKXfgBtTZTpywvZQdPGspUDvx2navMx2gmm3CXNsEN",
            "symbol": "POLY",
            "name": "Apolycapse Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Polycapse/assets/main/Poly%20log.png",
            "tags": [
                "gaming-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3NtrDUC9",
                "telegram": "https://t.me/apolycapsegame",
                "twitter": "https://twitter.com/ApolycapseGame",
                "website": "https://apolycapse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Bcuzen8nWgPQH2wCKiZbB3R8HS5CCEN3hxVwTVPEATPg",
            "symbol": "ARMYWL",
            "name": "Bears Army Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bcuzen8nWgPQH2wCKiZbB3R8HS5CCEN3hxVwTVPEATPg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/beararmy_"
            }
        },
        {
            "chainId": 101,
            "address": "AwrdDfbE7CP69kpiEctdtE2YJvr42kKw3rXiCYgFJp85",
            "symbol": "HOBO",
            "name": "Drifting Hobo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DriftingHobo/Crypto/main/DriftingHoboCoTokenImage.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8TZa4rcPSY5yFw3wLHDpVL6ASjrVbuBmQV5p18tmUcfW",
            "symbol": "FUNCO",
            "name": "Family Fun Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anymouse20/crypto/main/FUNC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DjSiNBBFraAmd2mUHn6ScQaJgovNB6HxPUqSpherE77D",
            "symbol": "UPAY",
            "name": "UniPay",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/UniPayToken/coinlogo/main/pixil-frame-0%20(1).png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BzwERW2s5brUYtt3jvobVQn64uxXYRrz81Yx6vYpJorE",
            "symbol": "$SPOT",
            "name": "SPOT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ViralCC/Spot/main/spot.jpg",
            "tags": [
                "community-token",
                "dog-token",
                "dog",
                "nfts",
                "meta"
            ]
        },
        {
            "chainId": 101,
            "address": "3195WFU2ZdULUv4NJbVR3DKEimutMApZnCeJ8fo1CUyx",
            "symbol": "MC",
            "name": "Metaverze Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3195WFU2ZdULUv4NJbVR3DKEimutMApZnCeJ8fo1CUyx/logo.png",
            "tags": [
                "social-token",
                "MC"
            ]
        },
        {
            "chainId": 101,
            "address": "4aertukCfmJpy7bf7rEx8JXvFi8ed8jNt3z9rLUjRjoV",
            "symbol": "FULBO",
            "name": "FulboStars",
            "decimals": 9,
            "logoURI": "https://fulbostars.com/logotoken.png",
            "tags": [
                "social-token",
                "gaming-token",
                "nft-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ax6SeCjhCR8rnAPSR5VrT5K47XPQi4moRAF2oV2Fzknt",
            "symbol": "JOCKY",
            "name": "Aloo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ax6SeCjhCR8rnAPSR5VrT5K47XPQi4moRAF2oV2Fzknt/JLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HQAxChVaeiESe9JN3LiRfLh1DM4g5MtGrbQTu3MwjjGa",
            "symbol": "ZWL",
            "name": "Zezam Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HQAxChVaeiESe9JN3LiRfLh1DM4g5MtGrbQTu3MwjjGa/zezam.png",
            "tags": [
                "whitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "ES6xz8FR8a5fP31ePeeKGR7HcgtzjQAQdHfGmcR3Uer7",
            "symbol": "ROAR",
            "name": "ROAR",
            "decimals": 9,
            "logoURI": "https://arweave.net/WPbvoC2Wx2obrPtJtMYLpenZqz31KJClHTSxGpcqesw",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LostLionsNFT"
            }
        },
        {
            "chainId": 103,
            "address": "BTyZwy5VVWBrgnppQR2wzmZeBwyct7EkTbuGt4L4epe8",
            "symbol": "SOBE",
            "name": "SoBe",
            "decimals": 9,
            "logoURI": "https://github.com/MattyMonk/sobe-swap/blob/master/sobe%20swap%20logo.png",
            "tags": [
                "whitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "8VLj4dYPEQSsQBcbopjBiwdStUUpVUkcLmUa5qWHE7AW",
            "symbol": "SPCO",
            "name": "SPACCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spaccoin1/SPACCOIN/main/spaccoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FvBZo7dEFasSB9vottYnuLvLy3br5pkbC1NXDdCVa4wQ",
            "symbol": "MHV",
            "name": "MetaHeaven Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FvBZo7dEFasSB9vottYnuLvLy3br5pkbC1NXDdCVa4wQ/logo.png",
            "tags": [
                "community-token",
                "metaheaven",
                "NFT",
                "metagod",
                "metaheaven-coin",
                "crypto"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaheaven_",
                "website": "https://metaheaven.land/"
            }
        },
        {
            "chainId": 101,
            "address": "J47E3RbXNsUmFzYryWgjnVdTKqeTARW55NwYRVYZmQxm",
            "symbol": "DHCO",
            "name": "Drifting Hobo Co Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DriftingHobo/Newcrypto/main/DHCOLogo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/DriftingHoboCo/",
                "instagram": "https://www.instagram.com/driftinghoboco/",
                "twitter": "https://twitter.com/DriftingHoboCo",
                "website": "https://www.thedriftinghobo.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EeHA7rkU2LauYCG8P6HJMTJq4qG38EokMfa6hDRDmdZt",
            "symbol": "$POOOOPY",
            "name": "POOOPY ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EeHA7rkU2LauYCG8P6HJMTJq4qG38EokMfa6hDRDmdZt/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://google.com"
            }
        },
        {
            "chainId": 101,
            "address": "EeLpRX4pgS4zPTQQfoHqqhBvoLzL83wPExmQoG5e1hnb",
            "symbol": "CRAFT",
            "name": "CRAFT COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/craft-token.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "F83hqnjNA2g6RZBPUovAA7n3U2CVnmxzacBcv9WJgirD",
            "symbol": "GIBT",
            "name": "gib basc tigur",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolPatrol/SolPatrol/main/dab-island.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "6JiPyJdjh8HdA4G3qHVD7oxrUUdaqBSR833DD46nFDyF",
            "symbol": "$BRAP",
            "name": "BRAP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JiPyJdjh8HdA4G3qHVD7oxrUUdaqBSR833DD46nFDyF/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "zZ9b792xCst7Sqd1MwJhtr1CXtdXosNGzBqYKFdYxiF",
            "symbol": "COZI",
            "name": "CoziCorners",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zZ9b792xCst7Sqd1MwJhtr1CXtdXosNGzBqYKFdYxiF/CoziCorners.png",
            "tags": [
                "CoziCorners"
            ]
        },
        {
            "chainId": 101,
            "address": "GRe7S2RHJBSiQXaATZEHtT275j4tPPfnPsEZzZJMK8YN",
            "symbol": "HZC",
            "name": "HazelCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ahazel94/HazelCoin/main/HazelTokenLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J4zm9yxF7PtevLzjkreF2hebTEiazJUrW2ee1UNTqG8E",
            "symbol": "FPC",
            "name": "Footprint Coin",
            "decimals": 2,
            "logoURI": "https://fpc-static.s3.amazonaws.com/logo/1024-main.png",
            "tags": [
                "Carbon-Footprint",
                "Electric-Vehicle",
                "greentech"
            ],
            "extensions": {
                "description": "We distribute Footprint Coin as a reward for driving electric vehicles.",
                "facebook": "https://www.facebook.com/Footprintcoin-101162415827069",
                "twitter": "https://twitter.com/footprintcoin",
                "website": "http://www.footprintcoin.io//"
            }
        },
        {
            "chainId": 101,
            "address": "5Z8JqL2kaGYT7cPaxSoCPT18pKmRudxVNHV3ny6qyNXW",
            "symbol": "PRIM",
            "name": "Primate Academy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Phnmz/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6frkvZf72wiz3uqRWhBqLftNU4PS6XXYCoNrW9P4CFdK",
            "symbol": "QUACK",
            "name": "QUACK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GP9zY2D8CgMreoUdYQjyn7Fo7XCq9ubVnX3u4ot1wpgt/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DuckPatrolNFT",
                "website": "https://duckpatrol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7BtSRTCk3BZkjmWvoLtP8igJTRcbACWrYHaNCv7ASRdt",
            "symbol": "CHUD",
            "name": "Chud Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rolandstudios/CHUD/main/logo.png",
            "tags": [
                "gaming-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/roland_studios",
                "website": "https://rolandstudios.com"
            }
        },
        {
            "chainId": 101,
            "address": "KDQXUUqSCAYhMNxsaQLQNwt37AnZqfeo9SK9uiWeTsz",
            "symbol": "AGE",
            "name": "MetaAge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MetaAge/MetaAge/main/logo.png",
            "tags": [
                "Metaverse-Token",
                "gaming-token",
                "play-2-earn"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaagee"
            }
        },
        {
            "chainId": 101,
            "address": "2NQr8337ZRhcCcor6BLbgYKQvxrP22mhHXdMncbC1w5P",
            "symbol": "IDNT",
            "name": "Identigo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rodnaf34/crypto/main/Logo_Blue_Green.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DXrpamQ5BPPDrrUTEVuwJdCT5Ut7eFPb79131xoYQHcs",
            "symbol": "RSC",
            "name": "RScoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NKMirzoyan/rscoin/main/logo.PNG",
            "tags": [
                "social-token",
                "gaming-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Rsus663WdHRFEfRZ6rPzEsvZCdiFRcukgjUkYLPTayL",
            "symbol": "APEXAPES$",
            "name": "APEXAPES WL Token",
            "decimals": 0,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/5e989673-a536-44d6-835e-89087e256500/public",
            "extensions": {
                "discord": "https://discord.gg/apexapes",
                "twitter": "https://twitter.com/apex_apes",
                "website": "https://apexapes.io"
            }
        },
        {
            "chainId": 101,
            "address": "8CjTkVDkbAJi5KjAyEh6La2XJ9QyRJr3umRh5LzpBHse",
            "symbol": "GUARANTEE",
            "name": "Blocked EuroSol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8CjTkVDkbAJi5KjAyEh6La2XJ9QyRJr3umRh5LzpBHse/gold.png",
            "tags": [
                "Golden-sol",
                "Euro-guarantee"
            ],
            "extensions": {
                "website": "https://blocksto.ne/blocked-sol-for-euro/"
            }
        },
        {
            "chainId": 101,
            "address": "8Jdjg3xVNAAFdiDbFpWF2FX5dYwHyw5j3Myvgmzrf7z4",
            "symbol": "MDOA",
            "name": "MDOA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Jdjg3xVNAAFdiDbFpWF2FX5dYwHyw5j3Myvgmzrf7z4/logo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mutantDogsACAD",
                "website": "https://mutantdogs.academy/"
            }
        },
        {
            "chainId": 101,
            "address": "ADj2YoHjZvv9HhAD32orJEzhYUsEBKbgTLD8c6FPUz4T",
            "symbol": "NIGGR",
            "name": "RacisToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADj2YoHjZvv9HhAD32orJEzhYUsEBKbgTLD8c6FPUz4T/logo.png",
            "tags": [
                "freespeech-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RacisToken",
                "website": "https://niggr.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "AYv1MnM75ayDkRBxBoAMhdXULKfUAQN3JWxkDGS4gT1Q",
            "symbol": "NUTTY",
            "name": "Nutty Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AYv1MnM75ayDkRBxBoAMhdXULKfUAQN3JWxkDGS4gT1Q/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nuttynarwhals"
            }
        },
        {
            "chainId": 101,
            "address": "9VZtUphSYbx7q9R4XfCnAkmkQHN7tW3x8KFRmqzinDPW",
            "symbol": "TNP",
            "name": "The NFT Pass",
            "decimals": 6,
            "logoURI": "https://cdn.discordapp.com/attachments/943583586051846185/945767522853073019/token-icon-TNP.png",
            "tags": [
                "utility-token",
                "governance-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/F9BpgY5syv"
            }
        },
        {
            "chainId": 101,
            "address": "AuyLHdrJtrv9PBhRSwvApj8ViuCT3VzBhkVLA4HzPn48",
            "symbol": "PST",
            "name": "Pelajar Social Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/pelajarsocial/locales-lib/main/logo.jpg",
            "tags": [
                "utility-token",
                "learning-token"
            ],
            "extensions": {
                "instagram": "https://instagram.com/pelajarsocial"
            }
        },
        {
            "chainId": 101,
            "address": "BLoBgdr2W7YEgzPioUbThYQwSFsaU7nPtFAgCQByM1gC",
            "symbol": "BLOBB",
            "name": "Blobbolution WL Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/754741118566137929/946037034269671444/Twitter_Discord_Profile_Picture_small.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/2zx2EHTXBM",
                "twitter": "https://twitter.com/Blobbolution",
                "website": "https://blobbolution.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GGe5nmdfJChxieXG9QUr94yLzgbAgV2ezrwbAgRjU3Hm",
            "symbol": "$OGPILL",
            "name": "OGPill",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLhpayHdNqcMKjuRCq9eEWPMV9LFN7gTqFAHSdU4Sxn/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLhpayHdNqcMKjuRCq9eEWPMV9LFN7gTqFAHSdU4Sxn",
            "symbol": "$PILL",
            "name": "Pill",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLhpayHdNqcMKjuRCq9eEWPMV9LFN7gTqFAHSdU4Sxn/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AGM9wUVHy9qbzkRULF9pgHwuFUmZYD3oys9ooKEAUuRQ",
            "symbol": "SB",
            "name": "SOBO",
            "decimals": 6,
            "logoURI": "https://github.com/usman989899/Asset/blob/main/FHo7KkRUYAEmy8Q.jpg"
        },
        {
            "chainId": 101,
            "address": "8GT6u7FETgcr287xhWRA96GJnn65e3dadJRXf9gEH2V1",
            "symbol": "KBEER",
            "name": "KBeer coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aagusti1974/kbeer/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P",
            "symbol": "GYC",
            "name": "GameYoo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gameyoo/materials/main/gyc.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "metaverse",
                "play-to-earn",
                "play-to-contribute"
            ],
            "extensions": {
                "github": "https://github.com/gameyoo",
                "twitter": "https://twitter.com/GameYooOfficial",
                "website": "https://gameyoo.io"
            }
        },
        {
            "chainId": 101,
            "address": "HNFvGsZ9buzVN4GibJfhjUfae2pP7vTAbrTwKqvib3aW",
            "symbol": "S4S",
            "name": "S4Scoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/S4Scoin/S4Scoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SBbDNNbqGBtxuHDr9kjzxewwABYxmZq9NSSjyk25W3K",
            "symbol": "BREE",
            "name": "BREECOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/notmafiosa/crypto/main/IMG_3189.jpg",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "2mHdf1SxL39mmFLPHWKAwH1DQDhMNqfjA5v6qrV9MBCW",
            "symbol": "MYR",
            "name": "Make You Rich",
            "decimals": 6,
            "logoURI": "https://github.com/Soumya-Shekhar/Crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B1N2zdRZn6FMCPsGjsB4cpmiygCoCcMedwjntrfvBPJ2",
            "symbol": "MEGC",
            "name": "MEGC-Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B1N2zdRZn6FMCPsGjsB4cpmiygCoCcMedwjntrfvBPJ2/MEGCTicket.png",
            "tags": [
                "whitelist",
                "megc",
                "invite",
                "monkey"
            ],
            "extensions": {
                "discord": "https://discord.gg/tkqe7RX4bb",
                "twitter": "https://twitter.com/MonkeyGolfClub",
                "website": "https://www.monkeygolfclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9ceUNLrZa1D4zGrj7NaT2gh7FnBUmeBLf1MtXbqSRN3c",
            "symbol": "VIVE",
            "name": "Vivian's New Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/saibaneer/solana-token/master/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "7mT2XbK3iqQrhzDFoy87r61ewkrDWYLHrm94iMiTf8xJ",
            "symbol": "BOOTY",
            "name": "Booty Farm",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7mT2XbK3iqQrhzDFoy87r61ewkrDWYLHrm94iMiTf8xJ/booty-logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "NSFW",
                "yield-farming",
                "web3"
            ],
            "extensions": {
                "discord": "https://discord.gg/aqKXxuVY7x",
                "medium": "https://medium.com/@bootyfarmxxx",
                "serumV3Usdc": "EHv2AXZuCapqseZCqRwzeJcVDgctzcuGu4JwRZenEv5g",
                "twitter": "https://twitter.com/BootyFarmLive"
            }
        },
        {
            "chainId": 101,
            "address": "BwPTX854gNfV4pyig33BVedPh2wAya5h3XvfC1Lc2wBh",
            "symbol": "Kito",
            "name": "Kitetoken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jseras07/token/main/kitetok.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6dsacPDwBFppyBeybCqfEJ2zqkiFfwDPFtoRPsK9Zw5",
            "symbol": "MULKT",
            "name": "Mulk Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6dsacPDwBFppyBeybCqfEJ2zqkiFfwDPFtoRPsK9Zw5/logo.png"
        },
        {
            "chainId": 101,
            "address": "5PT5nXyWVnBH887PAGiCk95fEjgjRhkY5BjhgKNcTDdJ",
            "symbol": "CALEE",
            "name": "Camarena Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5PT5nXyWVnBH887PAGiCk95fEjgjRhkY5BjhgKNcTDdJ/logo.png",
            "tags": [
                "reasearch-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cutm64TvBg4iWyao4tCZvNp8urMWhtTPhWxsbdwykuDn",
            "symbol": "EUCALYPTUS",
            "name": "Eucalyptus",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/KoalaHeroes/Cloud/bf5e2d5e61c386055e5fd28b97f2c77a55541c0a/ELogo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://koalaheroes.com"
            }
        },
        {
            "chainId": 101,
            "address": "EQTyTPidQAoGJBRYS4mLpS8ej5sZrdt5TziRgwDkoVXX",
            "symbol": "USD-T",
            "name": "USD stablecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EQTyTPidQAoGJBRYS4mLpS8ej5sZrdt5TziRgwDkoVXX/logo.png"
        },
        {
            "chainId": 101,
            "address": "ESCrFrmy96EFyZQCEV1YPpCAqrbwGpy62yWU6cGXgtb4",
            "symbol": "CRM",
            "name": "CRM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/iiizzzyyy/crm/main/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7QKhKEnUwx6icJotjh9qtC61EK9WpiXNrmMTPQw8AgSj",
            "symbol": "Face",
            "name": "Face",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7QKhKEnUwx6icJotjh9qtC61EK9WpiXNrmMTPQw8AgSj/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/longfacepunkz"
            }
        },
        {
            "chainId": 101,
            "address": "GWLFW6c6WtAyJZFSqbxaSr351H9s6Dskd44yQUtonWWq",
            "symbol": "AFGT",
            "name": "AF Gold Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWLFW6c6WtAyJZFSqbxaSr351H9s6Dskd44yQUtonWWq/logo.png"
        },
        {
            "chainId": 101,
            "address": "SWLmRezJbWb8SJKkHHAbGDfXneCWprXxc8iDNXBhM5q",
            "symbol": "AFST",
            "name": "AF Silver Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SWLmRezJbWb8SJKkHHAbGDfXneCWprXxc8iDNXBhM5q/logo.png"
        },
        {
            "chainId": 101,
            "address": "3nTt9pj9Sn4cVrGB75kTCdUba6DQLVv2S3j4KUMY8RzE",
            "symbol": "ST",
            "name": "Scythe Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3nTt9pj9Sn4cVrGB75kTCdUba6DQLVv2S3j4KUMY8RzE/logo.png"
        },
        {
            "chainId": 101,
            "address": "SexyzTVcKsB7m9CHDt2FgkJbhQK58pm7M73RynPWhCz",
            "symbol": "EXY",
            "name": "Sexy Kiwi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SexyzTVcKsB7m9CHDt2FgkJbhQK58pm7M73RynPWhCz/goldkiwi.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/bzW9VAyHjW",
                "twitter": "https://twitter.com/KiwiDAO",
                "website": "https://www.sexybirdz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "RichAYwyL4p9UC9RgfzsLW6K6kG3iERo1Nce4kR2WQW",
            "symbol": "RICHES",
            "name": "Rich Coin",
            "decimals": 11,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RichAYwyL4p9UC9RgfzsLW6K6kG3iERo1Nce4kR2WQW/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/OstrichesNFT",
                "website": "https://ostriches.io"
            }
        },
        {
            "chainId": 101,
            "address": "DLC4h3LeDq6qUKiaxqMfB9GQdFqg5ULkApAHT7LgiR6Y",
            "symbol": "ZROG",
            "name": "Zero Gravity",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zerodev1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sEEdWDz8CNhcgU3fPF4sgjjjNQ2jiS4boMXqix6pMGG",
            "symbol": "Germ",
            "name": "Germination Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sEEdWDz8CNhcgU3fPF4sgjjjNQ2jiS4boMXqix6pMGG/logo.png",
            "tags": [
                "Community-token",
                "Germination",
                "NFT",
                "Social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHMpZJw2ZsUryFX587vcTGvybDyKHaXVqvm379641fkh",
            "symbol": "CHMPZ",
            "name": "CHIMPZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHMpZJw2ZsUryFX587vcTGvybDyKHaXVqvm379641fkh/logo.png",
            "tags": [
                "Stake",
                "CHIMPZ",
                "NFT",
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5J3LY8zMS3CVdLrn9QXVorstZGUbWZ4BrtM12U4mRPhR",
            "symbol": "RJ7",
            "name": "RJ Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RJ079/Crypto/main/RJ.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9xEgqA4b2NsHwN1bTi1fEDjX8J1waQNA7nHfPWnRpfbu",
            "symbol": "DARKELVWL",
            "name": "DARKELV Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xEgqA4b2NsHwN1bTi1fEDjX8J1waQNA7nHfPWnRpfbu/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5uVwCRDLPRX4xDkLKZbr8VEiGcqB7jav8DT4i6Pak116",
            "symbol": "CLT",
            "name": "Culture",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NicholasGLambert/logo/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P",
            "symbol": "GYC",
            "name": "GameYoo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/gameyoo/materials/main/gyc.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "metaverse",
                "play-to-earn",
                "play-to-contribute"
            ],
            "extensions": {
                "github": "https://github.com/gameyoo",
                "twitter": "https://twitter.com/GameYooOfficial",
                "website": "https://gameyoo.io"
            }
        },
        {
            "chainId": 101,
            "address": "AsnXmPL8redwti7bFRXMWpfuBgHn1Yve6YK3vEvnjTnz",
            "symbol": "FOOFY",
            "name": "FOOFY COIN ",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsnXmPL8redwti7bFRXMWpfuBgHn1Yve6YK3vEvnjTnz/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://google.com"
            }
        },
        {
            "chainId": 101,
            "address": "AgrNk2aCFnLKixAugMDbq7A887ivQSFbNncvRhCSV1Mn",
            "symbol": "$SHIZU",
            "name": "Shizu Hippos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AgrNk2aCFnLKixAugMDbq7A887ivQSFbNncvRhCSV1Mn/logo.png",
            "tags": [
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ARCtXLM9CEDfhCt1XeTXgKNqhSaqbeMKdJmhBdXNyF4g",
            "symbol": "$ARC",
            "name": "ARC Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ARCtXLM9CEDfhCt1XeTXgKNqhSaqbeMKdJmhBdXNyF4g/logo.png"
        },
        {
            "chainId": 101,
            "address": "FB5kmTzvhn95n5HYL9MkGL3JbFDwUD9kMoHCFU4Zjsay",
            "symbol": "PEBL",
            "name": "Rocks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FB5kmTzvhn95n5HYL9MkGL3JbFDwUD9kMoHCFU4Zjsay/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/fyhostelli/",
                "twitter": "https://twitter.com/FyhostElli",
                "website": "https://www.fyhost.art/"
            }
        },
        {
            "chainId": 101,
            "address": "DGU9zPheJjohsjqvVxhRi54YPhzTKQZr1WPX8CjP7NfA",
            "symbol": "WBY",
            "name": "Webyo",
            "decimals": 5,
            "logoURI": "https://blogger.googleusercontent.com/img/a/AVvXsEghBmM9xKjXwPgFDFT2E5TB7lsjKp9n5_DoLqAYuJWGIzU1fmVfXYkc-wekE_3G7_l_T5JzDhIcwHbaCqByekojJzLAI4Ytii3qh7xl_0JKgTmroIBrdTLXQbN4ZuZuf68yhevNNNhuU-2nHHGZ0hJdF2hbwl3iSrQdzjLIf1BAqE28GVL-9yir-UTH=s1280",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "meme-token",
                "bombaAnarchica-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6gMucBt3aFdABoxJj2Deew1PntPTQjYhbauuuScCFXW5",
            "symbol": "GABX",
            "name": "GABXCoin",
            "decimals": 0,
            "logoURI": "https://github.com/GabToken/Crypto/blob/main/LOGO.PNG",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sMMAMLinw5aeQ1WzGp1RuJsufrEKKttMudt2e3SGgKE",
            "symbol": "sMMA",
            "name": "staked MMA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sMMAMLinw5aeQ1WzGp1RuJsufrEKKttMudt2e3SGgKE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Cross-chain Gaming SuperGuild building the tools of the future. Redeemable token to be redeemed for $MMA upon closure of the upcoming IDO",
                "discord": "http://discord.gg/mmagaming",
                "medium": "https://medium.com/@MMAGaming",
                "twitter": "https://twitter.com/MetaverseMining",
                "website": "https://mmagaming.io/"
            }
        },
        {
            "chainId": 101,
            "address": "srMMACAiS4raGVmRDSuD7FqGM3B7tLZBxNVR3uZJRDC",
            "symbol": "srMMA",
            "name": "staked redeemable MMA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/srMMACAiS4raGVmRDSuD7FqGM3B7tLZBxNVR3uZJRDC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Cross-chain Gaming SuperGuild building the tools of the future. Redeemable token to be redeemed for $MMA upon closure of the upcoming IDO",
                "discord": "http://discord.gg/mmagaming",
                "medium": "https://medium.com/@MMAGaming",
                "twitter": "https://twitter.com/MetaverseMining",
                "website": "https://mmagaming.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9JtoHi7ZfTyzDPj43k688yGfMyzp4j9gpsnWswAKnnKD",
            "symbol": "NCST",
            "name": "SPLOIT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SploitToken/Sploit-Token/main/ncst.png",
            "tags": [
                "nft-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "844bzknocdtT5AMhqhoAGNDQnN9f2Yq8JP8J2bvgzU91",
            "symbol": "HAND",
            "name": "Hand Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/samfultonjr/scratches/main/logo500.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HhmqnYpvHa44EE4TfcAghoZrYKHwV1tGaHMNZAH4RAvr",
            "symbol": "EMEOW",
            "name": "EMeow Cat Coin ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jaccba/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AiyQDVjnwg1phQRjCyyxyHdHvTBYhF8eXtmuBPRQrqRB",
            "symbol": "SSS",
            "name": "SKULLS",
            "decimals": 6,
            "logoURI": "https://github.com/Apxrture/SSS/blob/main/pixil-frame-0%20(1).png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4LWnLjNL9Q5SaVnJkhhmueKGkvgfb4aXQAgKsVve3yL2",
            "symbol": "OXYF",
            "name": "OXYCON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LWnLjNL9Q5SaVnJkhhmueKGkvgfb4aXQAgKsVve3yL2/eyes.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bc9vUedRBx6Mu1fYk9rhC6ug2BnyDJSLj1Ji12rjbLcn",
            "symbol": "LEV",
            "name": "LEV4E",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lev4e/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "45FKxaoC28XvpWnYe1GRkgfnxWEYSoQ11juGxsCLLpX4",
            "symbol": "GLSBBQ",
            "name": "Glass Barbie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/asterixx00/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "2HCeYJWocEzTS5GJiCCznR4s5ca6aWRuLafNCpVNmakc",
            "symbol": "TMK",
            "name": "TMkIIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2HCeYJWocEzTS5GJiCCznR4s5ca6aWRuLafNCpVNmakc/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GVvSwwQDpqWTQu739vqatKyyCXan7G1fibU9d5cXvXeX",
            "symbol": "PQL",
            "name": "Piqsol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVvSwwQDpqWTQu739vqatKyyCXan7G1fibU9d5cXvXeX/piqsol.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "nft-market"
            ],
            "extensions": {
                "description": "Fractional Solana NFT Marketplace",
                "discord": "https://discord.gg/TmKBmyrGHY",
                "facebook": "https://www.facebook.com/piqsol",
                "instagram": "https://www.instagram.com/piqsol.art",
                "medium": "https://medium.com/@social_91523",
                "reddit": "https://www.reddit.com/user/Piqsol_NFT",
                "telegram": "https://t.me/Piqsol",
                "twitter": "https://twitter.com/Piqsol",
                "website": "https://piqsol.com"
            }
        },
        {
            "chainId": 101,
            "address": "DxN75FUqcazmzyW6gMbHXC2VGhwRhMe1UmwPYbXqKJRb",
            "symbol": "FRST",
            "name": "FIRST",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LWnLjNL9Q5SaVnJkhhmueKGkvgfb4aXQAgKsVve3yL2/eyes.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AA6kSr673UmmAT8C8dHZGq3FTURYtRoYbyY5hWB1mPfH",
            "symbol": "SCND",
            "name": "SECOND",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LWnLjNL9Q5SaVnJkhhmueKGkvgfb4aXQAgKsVve3yL2/eyes.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "84SAoSWPAXdaSkcoqsGcPVZ9vB2LrugY1N7AEgVopB3Z",
            "symbol": "THRD",
            "name": "THIRD",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LWnLjNL9Q5SaVnJkhhmueKGkvgfb4aXQAgKsVve3yL2/eyes.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Aj4mwrbwTeq6hoKSRZ6ZF7Agcy3zCu3Q9tesa3KHqWct",
            "symbol": "$STOMP",
            "name": "STOMPP",
            "decimals": 9,
            "logoURI": "https://imgur.com/icCaYVN.png",
            "extensions": {
                "instagram": "https://www.instagram.com/dopedinopc/",
                "twitter": "https://twitter.com/DopeDinoPC"
            }
        },
        {
            "chainId": 101,
            "address": "6V39Vsa5epWyMYncpB6Yb3bjpYpoXURt2SLDCQ1UwdWU",
            "symbol": "XSQR",
            "name": "XBOX SIQER",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/cemdastan/xboxsiqer/main/xbox.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DJTE14SD24SKmPv6pLt84173srwbBgt7YGyfHZ5oVhwp",
            "symbol": "DJWL",
            "name": "Dino Jump Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJTE14SD24SKmPv6pLt84173srwbBgt7YGyfHZ5oVhwp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E5is2XpFQBn5HwoxZRRKszhJyHd4ofNPfGWSUwoHLRNE",
            "symbol": "SWNE",
            "name": "SolSwines Whitelist",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/kakazgg/solswineslogo/SolSwines-WL_swiny.png",
            "tags": [
                "nft",
                "game",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5TLBtAsJyGrxWyFhgyYr9R9KEkec9zrMQjfCKegdfax8",
            "symbol": "BPWT",
            "name": "Bizarre Platypus Token Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5TLBtAsJyGrxWyFhgyYr9R9KEkec9zrMQjfCKegdfax8/logo.png",
            "tags": [
                "utility-token",
                "mint-token",
                "whitelist"
            ],
            "extensions": {
                "description": "The Coin for Bizarre Platypus NFT for Whitelist.",
                "twitter": "https://twitter.com/bizarreplatypus",
                "website": "https://bizarreplatypus.io/"
            }
        },
        {
            "chainId": 101,
            "address": "WLTFhXCQPrGXq22PmbbdFojY9M19NPePorrW4NLaH1g",
            "symbol": "RMWL",
            "name": "R\u0026M Whitelist token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLTFhXCQPrGXq22PmbbdFojY9M19NPePorrW4NLaH1g/logo.png"
        },
        {
            "chainId": 101,
            "address": "76QxaXxTnhkmNWRcDiqPU6HnNXqJMB4LgBuWXGBoRvG7",
            "symbol": "TRCK",
            "name": "Trucken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/efsoleth/crypto/main/GoTruckCA__1_-removebg-preview.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7VpRFwcWWg7EyuqVhFK5ZX1tjW45BsDUhQ6Q3Z9SfKBH",
            "symbol": "MATTER",
            "name": "Star Light Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Matthewfusco/starlightnft/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "BfECixANAExjiUYBYtQSMr7L3ik2pKizVpJUKaPJpTCH",
            "symbol": "HAAS",
            "name": "HAASCOIN",
            "decimals": 0,
            "logoURI": "https://github.com/tenrandomdigits/HAASCOIN/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "jmsApix74A2RUJyw5XRCCL61MiCRP538yypQmemnCZd",
            "symbol": "souls0",
            "name": "souls for test",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/jmsApix74A2RUJyw5XRCCL61MiCRP538yypQmemnCZd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://aiko.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GGASkW5PZWvNbHr4F8BVXYPbMWLdfhP96QmVbQpoEbX9",
            "symbol": "$PILLOG",
            "name": "PillSyndromeOG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLhpayHdNqcMKjuRCq9eEWPMV9LFN7gTqFAHSdU4Sxn/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLiu98oqYLrM1rkbew41QSPrqVteVn3h2wS4rNurv9v",
            "symbol": "$WLPILL",
            "name": "PillSyndromeWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLhpayHdNqcMKjuRCq9eEWPMV9LFN7gTqFAHSdU4Sxn/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6XfDGAhCbVmDGUYiAfCUPtfjQq1jgTvpVdyaSnxLoxt",
            "symbol": "CC",
            "name": "ConeyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CIPC1826/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6XfGDAhCbVmDGUYiAfCUPtfjQq1jgT4vpVdyaSnxLoxt",
            "symbol": "CC",
            "name": "CONEYCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CIPC1826/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FzEf2xS5M5TFej5nzoZmEoRRmHtKZMAniBa96XJYZ2UR",
            "symbol": "My3",
            "name": "My3Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Maithreya1994/crypto/main/My3-logos_transparent.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gj1E2PKCgnd1s5m8oAELGRSmqcDXc94ayWw7ncveRK9M",
            "symbol": "BSUT",
            "name": "Black Spade Utility Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kmperkins85/BST/main/C5B956A9-289F-4084-BB0A-B050EB02BB61.PNG",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C9Gz2xLRviTg5FFD15F1TiTs5hjJe63n2GrqL94911M3",
            "symbol": "BRNB",
            "name": "Barnabium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Isembart/barnabium/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Forgp6AYPyCpMmd6o7PENRjwHjw2mcDeNB1Qx15DekLc",
            "symbol": "ISLDA",
            "name": "Fish",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Forgp6AYPyCpMmd6o7PENRjwHjw2mcDeNB1Qx15DekLc/logo.png",
            "tags": [
                "game-token",
                "staking",
                "gamble"
            ],
            "extensions": {
                "description": "Islanda is moving away from the copy and paste profile picture art and is expanding out to places where only few go...",
                "discord": "https://discord.gg/7n9sFPFe9b",
                "twitter": "https://twitter.com/IslandaNFT",
                "website": "https://moomoons.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GWTbCdwCSAR15dAGbSUxiFDAm2MXicSABXhhceobbCeA",
            "symbol": "PX",
            "name": "PX Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RolandPixeldust/PXCoin/main/logo.png",
            "tags": [
                "nfts",
                "community-token",
                "game-token",
                "metaverse-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/VQvzSHtNbR",
                "twitter": "https://twitter.com/PixeldustNFT",
                "website": "https://nft.pixeldust.tv/"
            }
        },
        {
            "chainId": 101,
            "address": "Hoh887H72GFaPqG8yBqTA8GWeVbWsnbCxDyAZyRHXMvc",
            "symbol": "HVAC",
            "name": "Ohms2law",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ohms2law/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3fwNEvk4WZnB6RCJwgp9HazXqX8sKD8qcNx2ckx5Rct2",
            "symbol": "FSTK",
            "name": "Fastik Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/frozenBranch/fastik/main/fastik.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hrs8H2FfWLxUa7kFV53vXPE2RGx5uZuUPCrKSUNZ99yU",
            "symbol": "$CROPS",
            "name": "BCBY Crops",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hrs8H2FfWLxUa7kFV53vXPE2RGx5uZuUPCrKSUNZ99yU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.bcbarnyard.info/"
            }
        },
        {
            "chainId": 101,
            "address": "84CGueN92MKdNRhpgciRmWZhnD8xv65k6K6zFX5e4Lwq",
            "symbol": "DHMT",
            "name": "Diamond Hands Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/84CGueN92MKdNRhpgciRmWZhnD8xv65k6K6zFX5e4Lwq/logo.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/cprodiamond/",
                "twitter": "https://twitter.com/cprodiamond",
                "website": "https://www.cprodiamond.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2Ky3WvFzcQQP8cHNAxfGgeNmEvXS1He66t9wEgaTbBMt",
            "symbol": "GLTH",
            "name": "Goliath",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/austinyk1/crypto/main/unknown.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "3fX2LgjZkoAsxGJLx75pLdWqP9kY5QJtqmhDzVTJwqvJ",
            "symbol": "HTLN",
            "name": "Hotline Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3fX2LgjZkoAsxGJLx75pLdWqP9kY5QJtqmhDzVTJwqvJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "Cbi6U8C8mC1ENvskYBqyYzmy1Q5wB5bk9YzUcF6ZVomQ",
            "symbol": "PURR",
            "name": "Alleycats Collective Tokens",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/h4XNYWyefydaNiEZmECeutJtxQbyopZUSeWPFDJZtaL/purr_coin.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "G4YMiaKnkb3iaduV4Jfh3rrGdNH6heKHTDS39rE6UXAq",
            "symbol": "WREKt",
            "name": "CryptoWrek Testcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G4YMiaKnkb3iaduV4Jfh3rrGdNH6heKHTDS39rE6UXAq/logo.png",
            "tags": [
                "utility-token",
                "wrekt"
            ],
            "extensions": {
                "discord": "https://discord.gg/saMpFsDAbZ",
                "twitter": "https://twitter.com/cryptowrek",
                "website": "https://synthverse.art/"
            }
        },
        {
            "chainId": 101,
            "address": "7Pe63gqDvC62U5YiLnL92zDkcQwFb2Gn51WYmKfrc3eY",
            "symbol": "LLAMA",
            "name": "LlamaCoin",
            "decimals": 10,
            "logoURI": "https://solgats.io/wp-content/uploads/2022/02/llamacoin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solgats",
                "website": "https://solgats.io"
            }
        },
        {
            "chainId": 101,
            "address": "3bCJzDhVXDTf8Tph4xcLw8iogUK6WgHGzk6RLgW21UTN",
            "symbol": "URJK",
            "name": "Urjcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kueurj/urjkue-logo/main/circle-U-ou1.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DdFDHUTNUVAfmwUj7hkdSkuee14TkHMAacoCFijAudX",
            "symbol": "LVM",
            "name": "Mises Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ki2tech/crypto/main/Misescoinlogo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Pyc8DkCgPohR1o3ExMx84fLxU8ti1eQnySUwJMh5E6d",
            "symbol": "GUNWL",
            "name": "INVALID - CryptoStrapsWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Pyc8DkCgPohR1o3ExMx84fLxU8ti1eQnySUwJMh5E6d/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4QHLVFxuqnxv2YhD8cNFfMNvws3yBNeCrWAGJ4pywVNQ",
            "symbol": "CZR",
            "name": "cruz3r",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nsxcruz3r/crypto/main/czrLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "fishXewD9Muc6xenzn3JevquHq3A5WVERMLLUrZMTfh",
            "symbol": "FI$H",
            "name": "FISH Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/fishXewD9Muc6xenzn3JevquHq3A5WVERMLLUrZMTfh/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "FI$H token allows SlumCat NFT holders to redeem goodies",
                "discord": "https://discord.gg/slumcatnft",
                "instagram": "https://www.instagram.com/slumcatnft/",
                "twitter": "https://twitter.com/slumcatnft",
                "website": "https://slumcat.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GYigzHB8PvDKC6q9hSzJCd1EeWkNDn5BoNSYirnNf97K",
            "symbol": "APPL",
            "name": "Apple Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GYigzHB8PvDKC6q9hSzJCd1EeWkNDn5BoNSYirnNf97K/appl.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/shinigaminft"
            }
        },
        {
            "chainId": 101,
            "address": "JSmhhX58q5Jxk7xBGh2sLgLsfiEhqCEdYRN7Rb6ov8h",
            "symbol": "CHUMP",
            "name": "CHUMPS Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/loosebucks/token/main/Untitled%204%20(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4i2yu5iFvxyPDgbRPbn449gz4As9D9q1BaUS6nsuQf7B",
            "symbol": "PILA",
            "name": "Pila",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Allenbrd/Pila/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BaZXh456atM5Fh7uWcbKeTPGXbMCacoxwXhbrM8eefNm",
            "symbol": "MISO",
            "name": "MISO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/koi-shogun/miso/main/miso%20pixel.jpeg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5W5vGGnpfU6PykJuexUPbbgcs2mJQ7ywq8s7GWEqb2c9",
            "symbol": "CHF",
            "name": "CheF'N",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Chriscoins/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F2scmWCGyhefxcdzSYAVdZjBVwocri7DJDAhBiVgNcTR",
            "symbol": "SMTH",
            "name": "SmoothCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mabo31/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "4BE8tjtSWcCrXCdkx4wyzX84XNGkGrNzoxovcftkt7qK",
            "symbol": "WILLC",
            "name": "WillCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chunghooman/crypto1/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BEf5BZxd5w2paiX6GnyBDBHUihfNMRUy8Dpek8X4rjBy",
            "symbol": "$TOXIN",
            "name": "TOXIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CMHayden/crypto/master/toxin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/galactic_snakes"
            }
        },
        {
            "chainId": 101,
            "address": "FtYtDBJxMcCUdoJqrhxmDpXQiExknSR3WAsRU6B41FrB",
            "symbol": "STKL",
            "name": "Shoot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/A6BH/crypto/main/Logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7qSqFjxZCAwrGe9QyR4755f1CJowcZRNoP519TVfJ5z2",
            "symbol": "CDC",
            "name": "cDragonCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benjaminiyou/crypto/main/cDragonLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GLBvhzpnQApaDAJ5g5Cgw5713JsZkm2DWKFDgjzpTrft",
            "symbol": "PREZSMINT",
            "name": "PixelPrezs MINT Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GLBvhzpnQApaDAJ5g5Cgw5713JsZkm2DWKFDgjzpTrft/logo.png",
            "extensions": {
                "discord": "https://discord.gg/J7YwjVEzgc",
                "twitter": "https://twitter.com/PixelPrezs",
                "website": "https://pixelprezs.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "NfZUsY5ZqdJFxcKjYxA3KgibKEP5wTUDNdCXJ43dtVo",
            "symbol": "MENACE",
            "name": "MENACE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NfZUsY5ZqdJFxcKjYxA3KgibKEP5wTUDNdCXJ43dtVo/menacelogo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BabyMenaceNft",
                "website": "https://www.babymenace.com/"
            }
        },
        {
            "chainId": 101,
            "address": "dR9WzcdHTXrW57eM9kkVmkP7CpZGmTQq834JyTRxGjw",
            "symbol": "MOOSE",
            "name": "MooseCoin",
            "decimals": 10,
            "logoURI": "https://solgats.io/wp-content/uploads/2022/02/moosecoin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solgats",
                "website": "https://solgats.io"
            }
        },
        {
            "chainId": 101,
            "address": "2tXb5LRDnPo2WxfvrvMgCXP1vtGxn9ErpBAW1w4D7483",
            "symbol": "PITM",
            "name": "PitBossesMint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/pitbuli.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "description": "The Pit Bosses Mint token",
                "website": "http://www.pitbosses.vip/"
            }
        },
        {
            "chainId": 101,
            "address": "GEWm7pTN5jH1LND1LgiWkrTDCusQbC8JbL55vmxeq7Ne",
            "symbol": "GOATZ",
            "name": "GOATZ",
            "decimals": 2,
            "logoURI": "https://solaboy.github.io/images/goatz.png",
            "tags": [
                "nft",
                "crypto",
                "rewards"
            ],
            "extensions": {
                "telegram": "https://t.me/goatz_world",
                "website": "https://www.goatz.io"
            }
        },
        {
            "chainId": 101,
            "address": "AvYRFjk4imoGSAYxcwfm4dM6qQigR6DZJt3vDatdgPeP",
            "symbol": "PEW",
            "name": "Pew",
            "decimals": 10,
            "logoURI": "https://solgats.io/wp-content/uploads/2022/02/pew-1.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solgats",
                "website": "https://solgats.io"
            }
        },
        {
            "chainId": 101,
            "address": "DirhEoLS5cgHc2DnDtvFhNusp4dWr3WNK6i2ssect1Zi",
            "symbol": "CLAW",
            "name": "CryptoClaw",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DirhEoLS5cgHc2DnDtvFhNusp4dWr3WNK6i2ssect1Zi/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/J7YwjVEzgc",
                "twitter": "https://twitter.com/PixelPrezs",
                "website": "https://pixelprezs.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "DgeYFKF199fxQQhYf3xiEZoYLoTANN8vq3kU7jSrM3eS",
            "symbol": "ES",
            "name": "Eliga Services",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bernardvdv/nft/main/cropped-eliga-services-favicon-32x32.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "28d4sqhGuvbV63DpEptTkj26umw3DyLy6NB8u6vdGTuh",
            "symbol": "TNT",
            "name": "TheNFT Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Onni3000/crypto/main/token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6sV2WL6pYjN1fHGhjtQQ4QxpCjtpvH7HrMjng3B6ARCy",
            "symbol": "BARGE",
            "name": "Barge Profit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6sV2WL6pYjN1fHGhjtQQ4QxpCjtpvH7HrMjng3B6ARCy/captain.png",
            "tags": [
                "Barge-profit",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://www.blocksto.ne/barge-profit/"
            }
        },
        {
            "chainId": 101,
            "address": "J968KD1xJx3X29Zg1yGWRa8EMDHWthHFZMkY7egr6ARp",
            "symbol": "BARGE",
            "name": "Barge Bond",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J968KD1xJx3X29Zg1yGWRa8EMDHWthHFZMkY7egr6ARp/captain.png",
            "tags": [
                "Barge-bond",
                "Blockstone"
            ],
            "extensions": {
                "website": "https://www.blocksto.ne/barge-bond"
            }
        },
        {
            "chainId": 101,
            "address": "CMcSwpe5WarHDkE64Yxasz4poxNz3jjpum45xYrcxtpi",
            "symbol": "NXDM",
            "name": "NXTDMNSN INDUSTRIES Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CMcSwpe5WarHDkE64Yxasz4poxNz3jjpum45xYrcxtpi/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "NXDM is a physical NFT verification utility token and product design token.",
                "discord": "https://discord.gg/gaZMrd3Qjb",
                "twitter": "https://twitter.com/NXT_DMNSN_IND"
            }
        },
        {
            "chainId": 101,
            "address": "bZiNzQpzMEvhCGG722aKR2LrPaHA9AfAK2EpAwsy7yv",
            "symbol": "SSS",
            "name": "Social Squids",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bZiNzQpzMEvhCGG722aKR2LrPaHA9AfAK2EpAwsy7yv/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/SoSquidSociety"
            }
        },
        {
            "chainId": 101,
            "address": "J8bFycPrVKhXnf6nysvUz8Wc2Q6BRvd41JSQ1RTTxxAx",
            "symbol": "KING",
            "name": "Coin Flip Kingz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J8bFycPrVKhXnf6nysvUz8Wc2Q6BRvd41JSQ1RTTxxAx/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ]
        },
        {
            "chainId": 101,
            "address": "12Yqttjac9py7cnEUMHaXWfuPPcJ9Nym8ifaxNgvT6HY",
            "symbol": "MTGTH",
            "name": "Matagoth Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/12Yqttjac9py7cnEUMHaXWfuPPcJ9Nym8ifaxNgvT6HY/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SPLw2X9175YhXUAaQuf8cvZ9mX8hpYF5X4B1esHPcNS",
            "symbol": "TNK",
            "name": "Tanuki WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPLw2X9175YhXUAaQuf8cvZ9mX8hpYF5X4B1esHPcNS/logo.png"
        },
        {
            "chainId": 101,
            "address": "4tUUpzPsSCzSasdRueA3J2phAvLD9cqRbzFCCNQAJpt4",
            "symbol": "KAI",
            "name": "KAI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tUUpzPsSCzSasdRueA3J2phAvLD9cqRbzFCCNQAJpt4/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "staking"
            ],
            "extensions": {
                "description": "KAI is the native token within the kaimetaverse - It supports several utility cases in which users are sent KAI via rewards as well as enabling DeFi features within it's ecosystem",
                "discord": "https://discord.gg/kaimetaverse",
                "twitter": "https://twitter.com/kaimetaverse",
                "website": "https://kaimetaverse.cloud"
            }
        },
        {
            "chainId": 101,
            "address": "8N48SUqtnrUyJtzU8iLpmUPMg3mm5su5uRh5tStVqHnD",
            "symbol": "PTCOIN",
            "name": "PATACOIN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8N48SUqtnrUyJtzU8iLpmUPMg3mm5su5uRh5tStVqHnD/logo.png",
            "tags": [
                "Community-Token",
                "Utility-Token",
                "Social-Token",
                "DAO-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/axbaquero"
            }
        },
        {
            "chainId": 101,
            "address": "F3YadVupQT3rxBpXxZJCdmzhpyb5NEPsCr18DHKXvsmb",
            "symbol": "SKULLWL",
            "name": "Skull Invasion WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F3YadVupQT3rxBpXxZJCdmzhpyb5NEPsCr18DHKXvsmb/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "description": "SKULLWL is the whitelist invite token for the Skull Invasion NFT collection.",
                "discord": "https://discord.gg/UxX7FFV577",
                "twitter": "https://twitter.com/Skullinvasionn",
                "website": "https://www.skullinvasionnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "7XhyezvAxuEV43hm7iLfYyCR4DuweEsi2oGN84TxBNNc",
            "symbol": "FRAYDEY",
            "name": "FRAYDEY COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cyberspyde/fraydey-coin/main/logo.png",
            "tags": [
                "Social-Token",
                "Community-Token"
            ],
            "extensions": {
                "description": "Fraydey Token is used for specific applications only, as rewards and/or advertisement balance for Fraydey app",
                "telegram": "https://t.me/fraydeyuz",
                "website": "https://fraydey.uz",
                "youtube": "https://www.youtube.com/channel/UCgB3d4-Oa6Q6OdeM0QTd72A"
            }
        },
        {
            "chainId": 101,
            "address": "HMWEz1fMBSaCEFVqM7ZFvpNKm4kbPpEYamkt27eQX51U",
            "symbol": "LNFTxSLSKIN1",
            "name": "SL Bath WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMWEz1fMBSaCEFVqM7ZFvpNKm4kbPpEYamkt27eQX51U/logo.png",
            "tags": [
                "liqnft-wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/solanaland"
            }
        },
        {
            "chainId": 101,
            "address": "9WhjFakVRAfRvZ8X5m5LFHD3Gvh7JkibmZjAs5BxHzFZ",
            "symbol": "JUGC",
            "name": "Juggernaut Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9WhjFakVRAfRvZ8X5m5LFHD3Gvh7JkibmZjAs5BxHzFZ/J2logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/TFD7bmujJR",
                "twitter": "https://twitter.com/Sol_Juggernauts",
                "website": "https://soljuggernauts.000webhostapp.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5MqqCDLDf1EoBE5KRhQDRTP6XZyn9ToVeuZ2RzDemJCk",
            "symbol": "RBWC",
            "name": "Reborn Baby Wukong Coin",
            "decimals": 6,
            "logoURI": "https://github.com/gilffinder/RBW-Coin/blob/main/coin%20pic.png?raw=true",
            "tags": [
                "NFT-token",
                "Farming-token",
                "staking"
            ],
            "extensions": {
                "description": " Reborn Wukongs Taking Over the Solana Chain",
                "discord": "https://discord.gg/uYM3Hzz4Gd",
                "twitter": "https://twitter.com/rebornbabykongz",
                "website": "https://www.rebornbabywukongs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "WLTdgwdNi91RGojhM6Wk5rXNKsVgLA5cZbgL7XGTETX",
            "symbol": "WLSSS",
            "name": "SSS WhiteList Token",
            "decimals": 0,
            "logoURI": "https://github.com/CryptoPique/token-list/tree/main/assets/mainnet/WLTdgwdNi91RGojhM6Wk5rXNKsVgLA5cZbgL7XGTETX/logowhite.png"
        },
        {
            "chainId": 101,
            "address": "HhXYhtFqfjxe5iBuBQ3RedmmAbgFrjW2DBzPak14ejQq",
            "symbol": "KAMP",
            "name": "Kawaii Animals Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HhXYhtFqfjxe5iBuBQ3RedmmAbgFrjW2DBzPak14ejQq/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7TQTpG1qBvE9ui7J9yQWKFAYpQahkaKPKqGTsqSm1wUv",
            "symbol": "GV",
            "name": "Good Vibes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TQTpG1qBvE9ui7J9yQWKFAYpQahkaKPKqGTsqSm1wUv/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/The_Sol_Army",
                "website": "http://www.thesolarmy.com"
            }
        },
        {
            "chainId": 101,
            "address": "AstPCW5xTv5EG22qPE81CeKVZ6y9jv8SooivuCYsCMSu",
            "symbol": "ASRO",
            "name": "AstroToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AstPCW5xTv5EG22qPE81CeKVZ6y9jv8SooivuCYsCMSu/logo.png"
        },
        {
            "chainId": 101,
            "address": "ETKgPZnDiFzHWGTpC2t3g7d7B2XR3iih6iXjV2e2jBSt",
            "symbol": "Tiik",
            "name": "Tiiks",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ETKgPZnDiFzHWGTpC2t3g7d7B2XR3iih6iXjV2e2jBSt/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Stiiks_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "HtquMwfDXagEkYYC9NJVKmv1sUrp4PTHLo4piDNf3sMm",
            "symbol": "FXFUND",
            "name": "EffektFuture Fund",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/pyrexxx/effektfuturefund/main/eff_logo3.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fb1FMcXHEW8kjJFEKDWkzuEXL2MazjjiSHi1sWkjrvcH",
            "symbol": "D4F",
            "name": "Dancer4Freedom",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fb1FMcXHEW8kjJFEKDWkzuEXL2MazjjiSHi1sWkjrvcH/logo.png",
            "tags": [
                "Solana",
                "Videogame",
                "Play2Earn",
                "Utility-Token"
            ],
            "extensions": {
                "address": "Fb1FMcXHEW8kjJFEKDWkzuEXL2MazjjiSHi1sWkjrvcH",
                "assetContract": "https://solscan.io/token/Fb1FMcXHEW8kjJFEKDWkzuEXL2MazjjiSHi1sWkjrvcH",
                "description": "D4F is a utility token, currency for the purchase of digital assets, NFT, within the video game Dancer4Freedom",
                "discord": "https://discord.gg/qZ3eqFy6",
                "twitter": "https://twitter.com/Dancer4Freedom",
                "website": "https://www.dancer4freedom.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FD9HCKVyyfC4LUAmeo68EuEhDs68sxrspiHmTt21CGpm",
            "symbol": "CSFTHC",
            "name": "TuffyHacksCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/890popbox/tuffyhackscoin/main/tuffyhackscoinsg.png",
            "tags": [
                "meme-token",
                "project-token",
                "hackathon-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CzXF8oUJSsB9ADKV99WAi2TgytqAyKvQw6EihwiL9em4",
            "symbol": "DRGNZ",
            "name": "Boryoku Genesis Dragonz Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/AzJI7FQ.png",
            "tags": [
                "bridgesplit-fraction",
                "boryoku-genesis",
                "fraction-index",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/CzXF8oUJSsB9ADKV99WAi2TgytqAyKvQw6EihwiL9em4"
            }
        },
        {
            "chainId": 101,
            "address": "8vncC6LS9j14ubYDamLRK6t1JGXe8DsWuvJreDwUmkQa",
            "symbol": "Cloudy",
            "name": "CloudyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iWritzBoy/ImageBoy/main/CloudyCoin.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "59Y8Bxa64n1sgb54s7XjNitt7iwdJ2gGGRHySmXUuUBV",
            "symbol": "JKLP",
            "name": "Jackalopes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/59Y8Bxa64n1sgb54s7XjNitt7iwdJ2gGGRHySmXUuUBV/logo.png",
            "tags": [
                "nft-token",
                "Jackalopes"
            ],
            "extensions": {
                "discord": "https://discord.com/channels/945732232503513109/945732237117235231",
                "instagram": "https://www.instagram.com/jackalopenfts/",
                "twitter": "https://twitter.com/JackalopeNFTs",
                "website": "https://jackalopes.info/"
            }
        },
        {
            "chainId": 101,
            "address": "8eMz8nphkDvH3mET2cWWtkva1h4VgxmVtbKZNa11g3Ko",
            "symbol": "VIMA",
            "name": "ViMaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/freehandslife/ViMaCrypto/main/ViMaScaled450.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7HRrtvKKn2vEYJuFaD9ijwmkdrdQmEe13KmBoYstqD85",
            "symbol": "PHOC",
            "name": "PhotonCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Unitech357/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "67jSkrp4SEhk4k5E6oXjprX9SXnRb1x2M3obke7BemL1",
            "symbol": "$MARS",
            "name": "MARS Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/67jSkrp4SEhk4k5E6oXjprX9SXnRb1x2M3obke7BemL1/logo.png",
            "tags": [
                "currency",
                "game-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/channels/932827372464513085/943416696964780132",
                "website": "https://www.martianarmy.space"
            }
        },
        {
            "chainId": 101,
            "address": "FUHqX4aEQCofiHFcLoscQ9Y16zuB9mLuqMTwzLGWZbin",
            "symbol": "f3k",
            "name": "f3token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/bettersree/beeterknitf3/main/knite84053671_padded_logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/talkwith_money",
                "website": "https://www.knitef3.com/home/white-paper"
            }
        },
        {
            "chainId": 101,
            "address": "F32SqCgfvxi2z4EWirzQ3DATpQo3dK75LoRUvZBjeeSe",
            "symbol": "WC",
            "name": "W-Club Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yanivberg/WCTOKEN/main/WClogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CSTVeXB1oBAfRz3JsJmHz37ViJ2uRRv7qHnWRPnF2fQG",
            "symbol": "LNFTxSLSKIN3",
            "name": "SL Garden WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CSTVeXB1oBAfRz3JsJmHz37ViJ2uRRv7qHnWRPnF2fQG/logo.png",
            "tags": [
                "liqnft-wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/solanaland"
            }
        },
        {
            "chainId": 101,
            "address": "TohuyVV81dVVJnLKJGArgWQGBXhCcRDSvyYFv9bSNn8",
            "symbol": "FOX40",
            "name": "Fox40 token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TohuyVV81dVVJnLKJGArgWQGBXhCcRDSvyYFv9bSNn8/logo.png"
        },
        {
            "chainId": 101,
            "address": "LUSTdLASZy86pR6V5VjMpXxW9oVtCQt8q3fJ9iHZtPY",
            "symbol": "LUST",
            "name": "Succuverse",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/dynaris/lust/main/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/succuverse",
                "twitter": "https://twitter.com/succuverse",
                "website": "https://succuverse.com"
            }
        },
        {
            "chainId": 101,
            "address": "ENGY6nJtgro1jQQmwQhbWm6niTdut92nAiWsYJ6fiMaR",
            "symbol": "$ENERGY",
            "name": "ENERGY",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENGY6nJtgro1jQQmwQhbWm6niTdut92nAiWsYJ6fiMaR/logo.png",
            "extensions": {
                "website": "https://botslifenft.com"
            }
        },
        {
            "chainId": 101,
            "address": "8fhixNskWPGsQprN28pCwDqx11p1VTJGVQ6a7NBv9nwe",
            "symbol": "TNKWL",
            "name": "Tanukisan WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8fhixNskWPGsQprN28pCwDqx11p1VTJGVQ6a7NBv9nwe/logo.png"
        },
        {
            "chainId": 101,
            "address": "FmjycwWoV2iVjE5EinWTdMTdQgrV3tANECnEdqeCtqAC",
            "symbol": "6RIMS",
            "name": "6rims WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FmjycwWoV2iVjE5EinWTdMTdQgrV3tANECnEdqeCtqAC/logo.png",
            "extensions": {
                "website": "https://6rims.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6oQnWHy3XsQKLi8CNpq4UVFY1SA7sGirEm6zmbge2ZU4",
            "symbol": "BeNFT",
            "name": "BeNFT Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/BeNFT2022/images/main/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/benft",
                "website": "https://www.benft.solutions"
            }
        },
        {
            "chainId": 101,
            "address": "6P3U9HzGUitrCxSXX4PzEU8uc1QqBsZuZNULyKUGgfvN",
            "symbol": "G666",
            "name": "VladCoinG66",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Jordann26PC/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GiktSMDhz9U7Nxs7fH5LSAVna3quv6giFdJrJ4WD3uHx",
            "symbol": "MK",
            "name": "Markka",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GiktSMDhz9U7Nxs7fH5LSAVna3quv6giFdJrJ4WD3uHx/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BdnaZ5yDCjXWXXt37s8fFUJ3afpTEvvoo8c77CHSDopC",
            "symbol": "OPDC",
            "name": "Open Data Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/frozenBranch/fastik/main/opendatalogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4CEmorYMVkPad5XrS7p4gqhwwUT4jgBTzcF2d6LTEZ6R",
            "symbol": "WDY",
            "name": "Weedy's",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Olieboll/crypto/main/Weedy's.png",
            "tags": [
                "nft-token",
                "solana"
            ]
        },
        {
            "chainId": 101,
            "address": "8s1sdXotPyj2SyaCwdaobbbkdBjm9VUTv7Lmt4BGBqEn",
            "symbol": "WOB",
            "name": "WOLOB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mateimmm/wolob/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5MihDafd4Vcbu1zE5CKK4y5d486tDHCjJbN3hYXLkViH",
            "symbol": "WWINR",
            "name": "WWINR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/aktech297/WWINR/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G2uWAcSQJRJigA81e7hx9zoMrN4YryEJg7JHJdxuG6sr",
            "symbol": "MTZ",
            "name": "Metacraze",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/metacraze/assets/main/inverse.png",
            "tags": [
                "metaverse-token",
                "staking-token",
                "nft-token",
                "staking-token"
            ],
            "extensions": {
                "description": "Metaverse talent contests and wealth generation with blockchain validation",
                "telegram": "https://t.me/Metacraze_Official",
                "twitter": "https://twitter.com/Metacraze_io",
                "website": "https://metacraze.io"
            }
        },
        {
            "chainId": 101,
            "address": "G5Qo7JbfLdDWyd8uWC6UXZWmBg7kBMtMGLTxSgV2VPvr",
            "symbol": "PXC",
            "name": "PIXEL COIN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Medamine168/boring_pixels/main/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MeDarkX1",
                "website": "https://boring-pixels.netlify.app"
            }
        },
        {
            "chainId": 101,
            "address": "2RXSV7qPvPoPz7djG5fTT7teb61EJ1VRiC6ysHWm8F6c",
            "symbol": "$MTSN",
            "name": "Metasolanium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2RXSV7qPvPoPz7djG5fTT7teb61EJ1VRiC6ysHWm8F6c/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheMetanoids"
            }
        },
        {
            "chainId": 101,
            "address": "3nH3vsMyAWPxwRdbwT2tcmXGiYm6eYGf1Mi8bTXGt8CF",
            "symbol": "$AKR",
            "name": "AKR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3nH3vsMyAWPxwRdbwT2tcmXGiYm6eYGf1Mi8bTXGt8CF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ProjectAkira_"
            }
        },
        {
            "chainId": 101,
            "address": "4bgRUBC4gPoTs38TytDwujhcdn7reRoKynecvK7fJ5VW",
            "symbol": "$NUT",
            "name": "NUT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4bgRUBC4gPoTs38TytDwujhcdn7reRoKynecvK7fJ5VW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RCollectorsClub"
            }
        },
        {
            "chainId": 101,
            "address": "Exz2u9EhSXzGDef4v8bfXVjcUbsFm4kMKoXxn58fDUSa",
            "symbol": "$MCREW",
            "name": "MONKE CREW",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Exz2u9EhSXzGDef4v8bfXVjcUbsFm4kMKoXxn58fDUSa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MonkeCrew"
            }
        },
        {
            "chainId": 101,
            "address": "3FzFyEk3NRvA2EKtSW2FSrGp67R8cnDoEWTZQ36AiUxE",
            "symbol": "DPC",
            "name": "Dozy Panda Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FzFyEk3NRvA2EKtSW2FSrGp67R8cnDoEWTZQ36AiUxE/logo.png",
            "tags": [
                "game-token",
                "staking",
                "gamble"
            ],
            "extensions": {
                "description": "A Unique Collection Of 3333 Dozy Pandas Sleeping On The Solana Blockchain.",
                "discord": "https://discord.gg/Q22K998ra9",
                "twitter": "https://twitter.com/DozyPandaClub",
                "website": "http://dozypandaclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "TokeZFFFFmeWFEEGiXy9DYszxKz5yYT2WqBSc4uVrge",
            "symbol": "MTSPL",
            "name": "My Test SPL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mean-training/spl_token/main/images-1.png"
        },
        {
            "chainId": 101,
            "address": "6hwvPWpszUc1KeLgRmR1CcmT3uSkGqeaXXR45HpHYECH",
            "symbol": "GTT",
            "name": "Golden Ticket Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DEM-Lion/GoldenTicketToken/64141cb7d7af46295c9ec9525a179506948f25b3/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C2JftZnC852McYwDePmm3ZQTrpPuXT1gA3Cm9oW4NRuA",
            "symbol": "LTRSE",
            "name": "LOTROSE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lotrose1/crypto/main/LOTROSE.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3VKidPiw5NrYBC92dXJhy67U5e2VH5Ukbe7rcDQcd1VX",
            "symbol": "Pc",
            "name": "Pentester Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alex14324/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8WTN3gDKgk2xfYoBZFjyBhek9DaMkqVMeSU8EcfUhHNU",
            "symbol": "DGENMF",
            "name": "Degen MFer Floor Index",
            "decimals": 2,
            "logoURI": "https://testlaunchmynft.mypinata.cloud/ipfs/QmRHkP5XEJphdcSkqEsE8qEWB2tVBsUYxyhKZakcbQLzhK/54.png",
            "tags": [
                "bridgesplit-fraction",
                "degen-mfers",
                "fraction-index",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/8WTN3gDKgk2xfYoBZFjyBhek9DaMkqVMeSU8EcfUhHNU"
            }
        },
        {
            "chainId": 101,
            "address": "6QpsdCum7B8HhonzsrK1XCsJmrsB6kr3A8mzB1nomZb2",
            "symbol": "ALKA",
            "name": "Alka Sweephy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/abdullahalkakandilli/alkasweephy/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EWkFXyfTKYbjGFmmYJ8mtogMmwsZJrfZSJmLGHPSLZrg",
            "symbol": "TOONIE",
            "name": "Toonie Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/VortexHowl/ToonieToken/main/ToonieLogo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/VortexHowl"
            }
        },
        {
            "chainId": 101,
            "address": "2yqrNM6D2SoqHpJpHYGBAhk8rkbMR8z7TrED19W6JaHh",
            "symbol": "MSWL",
            "name": "MetaSuite-Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HarshitChari/Image-classifier/master/pics/WLCoin.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetaSuiteNFT",
                "website": "https://metasuitenft.com"
            }
        },
        {
            "chainId": 101,
            "address": "DcjXyCeXRuVcRoR1LEsL33qLbTL374mCeE1dLbcdyv2B",
            "symbol": "WEZ",
            "name": "Wezesha",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcjXyCeXRuVcRoR1LEsL33qLbTL374mCeE1dLbcdyv2B/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WezeshaCoin",
                "website": "https://www.wezesha.co"
            }
        },
        {
            "chainId": 101,
            "address": "8w2PWW9XddU28eqAS3ig9E7m8hqWWGKELhUqqyJSUugz",
            "symbol": "WIFE",
            "name": "NFT Wives WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8w2PWW9XddU28eqAS3ig9E7m8hqWWGKELhUqqyJSUugz/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thenftwives",
                "website": "https://www.thenftwives.com"
            }
        },
        {
            "chainId": 103,
            "address": "Ddoy8we5iFxPomFnLVXHxXY7ZAdQsCnFxnW8G2rxYTQF",
            "symbol": "AW",
            "name": "AW Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Edli-AW422/aw-solana-token/main/aw-logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "3KTvv2xvLxeVwgLYed5JVpnwpLzmqKjcxeZv1n29bYLp",
            "symbol": "BONG",
            "name": "Bongo Bear Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KTvv2xvLxeVwgLYed5JVpnwpLzmqKjcxeZv1n29bYLp/logo.png"
        },
        {
            "chainId": 101,
            "address": "MGTt6Cj6Brg3mHmDSe6NnJhmAvwqoibNBt7xrj92RNu",
            "symbol": "MGWL",
            "name": "Majin Genies Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MGTt6Cj6Brg3mHmDSe6NnJhmAvwqoibNBt7xrj92RNu/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MURjLjujBn6A8Q1fDx96b9f3ftdoqS2FVoLKhpvN6jn",
            "symbol": "WHISK",
            "name": "Murphiez Whiskey",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MURjLjujBn6A8Q1fDx96b9f3ftdoqS2FVoLKhpvN6jn/whisk.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/murphiezclub"
            }
        },
        {
            "chainId": 103,
            "address": "DBKKTjyzJ6C5p9byNKfUtGMUp7TVeihZ2KxJL4taaXrn",
            "symbol": "BULLDOG",
            "name": "Bulldog Billionaires Whitelist Token",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/99852311/155863295-f74e3dfe-d49d-46f7-b77b-3b4a0b0a5ba5.png",
            "extensions": {
                "website": "http://bulldogbillionaires.com"
            }
        },
        {
            "chainId": 101,
            "address": "2at5mSqehrvw6qZF97JrAaw5FRGVRnDJvJhygHenXHbE",
            "symbol": "MLCN",
            "name": "MolleCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MMarleen/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "51VFUq6gpFqNDjtjcABsdVPRRyJKf1vxwGQK3wd9hEEs",
            "symbol": "GRCO",
            "name": "Gray Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/beemr4me/Logos/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GGytdhmAWwiAkxkwn3N4TbgHoTrcbSnmE5zXJCfEYjwX",
            "symbol": "RBWT",
            "name": "RebornBabyWukongToken",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/gilfs/cc/main/rbwtt.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AFbcRd1aFYSdqBUKbV6bAEfka3Kx2cbiBFBJsRhLoeMa",
            "symbol": "DROC",
            "name": "DROC TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/droc789/DROC-TOKEN/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Avff6rVko736LDnMXQtbs1ckMM9bS17CmmzZMoUBtNEH",
            "symbol": "MTT",
            "name": "Meta Trading Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/metatrader9/metatrader9/main/unnamedmtt.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BLggR1UrrMTP2UMiQNLkvqr8VyXcE3LD6VtEC8t7XgTi",
            "symbol": "JBLR",
            "name": "Jacob's ladder",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bluedenn/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GiLAFSEGwJB3pmMkpAAznS9YBSPe82GtWugzwkBNvJ5v",
            "symbol": "STYX",
            "name": "STYX",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GiLAFSEGwJB3pmMkpAAznS9YBSPe82GtWugzwkBNvJ5v/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "STYX dug from the deepest depths of the Underworld",
                "website": "https://omensofolympus.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CwfKdiuKoyMaLb9fPf6QfK5VSzPSCmBXzS4P4QNvrZa4",
            "symbol": "KISH",
            "name": "Kish Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CwfKdiuKoyMaLb9fPf6QfK5VSzPSCmBXzS4P4QNvrZa4/logo.png",
            "tags": [
                "monkish",
                "dekt",
                "utility-token"
            ],
            "extensions": {
                "description": "Key to the Monkish Kingdom",
                "discord": "https://discord.gg/monkish",
                "twitter": "https://twitter.com/MonkishRebel",
                "website": "https://dekt.life"
            }
        },
        {
            "chainId": 103,
            "address": "AHRVZP1DkfRX5vffwKvsUJgbTTFmsXW8cZu43VwGdfuh",
            "symbol": "KISH",
            "name": "Kish Coin Devnet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CwfKdiuKoyMaLb9fPf6QfK5VSzPSCmBXzS4P4QNvrZa4/logo.png",
            "tags": [
                "monkish",
                "dekt",
                "utility-token"
            ],
            "extensions": {
                "description": "Key to the Monkish Kingdom",
                "discord": "https://discord.gg/monkish",
                "twitter": "https://twitter.com/MonkishRebel",
                "website": "https://dekt.life/"
            }
        },
        {
            "chainId": 101,
            "address": "8weZegtxtcRbdrEXZVvwYHVeRtPQQc1kqWexkhwjgnfd",
            "symbol": "NXB",
            "name": "Nix Bonds",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8weZegtxtcRbdrEXZVvwYHVeRtPQQc1kqWexkhwjgnfd/logo.png",
            "tags": [
                "nix-bonds"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/bondsnxb/"
            }
        },
        {
            "chainId": 101,
            "address": "viTh4TyQqxAdLBbnLJ7K1uRJrz7riyMCEfAjRcXQV3V",
            "symbol": "VITH",
            "name": "Vithril",
            "decimals": 9,
            "logoURI": "https://www.vivendell.com/images/icon-vithril.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/KR28XfjtYQ",
                "twitter": "https://twitter.com/vivendell",
                "website": "https://www.vivendell.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4shVDxvcyFvGznMh3fWD95W72KczvR9x8A1rKhJ48wnX",
            "symbol": "MRAM",
            "name": "Matty Ram",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mattesser/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4D7mdfqiQ7G8XMcTnk5gRBxE4m7V7HPaRbGqouxjKfyq",
            "symbol": "THMD",
            "name": "THERMOMED",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/cemdastan/thermomed/main/thermomed-logo.png",
            "tags": [
                "business-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Yt9TgPeqNoWJDinGSr1qjz2ou5H3A7Fo6Ks2Edqmmb7",
            "symbol": "RENE",
            "name": "RENEKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RENEcat/crypto/main/RENECOIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9VhWwUGcgws9ZeBSKdDSmw1CwbYxZS1qER8KkmJX2iRV",
            "symbol": "BBR",
            "name": "Bagsyb",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bagsybrawlers/Crypto/main/Bagsyb-logos.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HAevQUdCf52P4XzKTozt75aurAn8ZfHeveVQrtDjG79D",
            "symbol": "PANCAKE",
            "name": "PancakeCoin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/InfernalGG/PANCAKE-assets/master/PANCAKE-COIN.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7pJ3ov6LZTgc4pxkWg8vopGEvYvjjYmfiZUjygMhQxKh",
            "symbol": "CHAOS",
            "name": "CHAOS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7pJ3ov6LZTgc4pxkWg8vopGEvYvjjYmfiZUjygMhQxKh/chaoslogo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BabyMenaceNft",
                "website": "https://www.babymenace.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8ZxnzfnonFDuB1WBm6P3ESdrPZccn6KnAqiEdkBxMupb",
            "symbol": "THC",
            "name": "THE HOPE COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/thehopecoin/thehopecoin/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8pwSGfFeo2MiwPxNFUf4jEkyFDxaaByxRm5MkLNUvw2K",
            "symbol": "NEKO",
            "name": "Yukan Neko Coin",
            "decimals": 2,
            "logoURI": "https://cdn.discordapp.com/attachments/926535226820276264/947426770334924810/Untitled_Artwork.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2YdQtVb48TFFegZQqJTnFBdtLT7bZsMioAAXuuBnwDc7",
            "symbol": "NR",
            "name": "NeuRon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PopusoiIonut/NeuRon/main/Logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G2WKCVbT9oEZN3yQgeTfUz4CVe5zQDWQaEtckFnxnB4r",
            "symbol": "KAP",
            "name": "Kawaii Animals Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G2WKCVbT9oEZN3yQgeTfUz4CVe5zQDWQaEtckFnxnB4r/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EHinbFyVA4VckqJ54wxFXHWLxCCYCKxy62oVtRDTCoRD",
            "symbol": "$SKULL",
            "name": "Skull Invasion",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EHinbFyVA4VckqJ54wxFXHWLxCCYCKxy62oVtRDTCoRD/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "$SKULL is the whitelist community token for the Skull Invasion NFT collection.",
                "discord": "https://discord.gg/UxX7FFV577",
                "twitter": "https://twitter.com/Skullinvasionn",
                "website": "https://www.skullinvasionnft.com"
            }
        },
        {
            "chainId": 103,
            "address": "Cjx3nM3AzhECVCkaGPp5GXcEG4JQiGaNR9iYWxTuyFp5",
            "symbol": "PURR",
            "name": "PURR Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Edli-AW422/aw-solana-token/main/aw-logo.png"
        },
        {
            "chainId": 103,
            "address": "ByEWiEXkwMXzHTT5FkuWzcHY3MzkYT814jFxxN1QLh3g",
            "symbol": "UREP",
            "name": "UNIVERS REPUBLIK",
            "decimals": 9,
            "logoURI": "https://github.dev/POPE001/token-list/blob/a59c01f6fdf9ef7a4b9603f72e8d811a0ee8a830/assets/mainnet/ByEWiEXkwMXzHTT5FkuWzcHY3MzkYT814jFxxN1QLh3g/unilever.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "83Mme81p8fbNGaiRmNJMTx9wn5o6RezHq5ztvN1pYpdf",
            "symbol": "DOME",
            "name": "DOME",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/83Mme81p8fbNGaiRmNJMTx9wn5o6RezHq5ztvN1pYpdf/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3onFNex9u7DdhLoeYVskfamBBdLWRJkwy1RaP4VjwfQQ",
            "symbol": "RBT",
            "name": "ROYAL EBANK TOKEN",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3onFNex9u7DdhLoeYVskfamBBdLWRJkwy1RaP4VjwfQQ/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FGtc75KTsueEXeRKRaQXPqUybH6zhszn3cth8PkTFxr5",
            "symbol": "LMY",
            "name": "LilMissy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lokie861/LYM_logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9t6F4UJXr9B4xzoSmgC9LwYHb87maYeM58PsoXgNjyVW",
            "symbol": "SMBC",
            "name": "SambuCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexserra/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AqMikDiApiwrHEwusrqaWZ1uBH182WUXsHwp4CS1UMaD",
            "symbol": "YOAD",
            "name": "YOADcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yoadib/crypto/main/%D7%9E%D7%99%D7%96%D7%9D%20%D7%97%D7%93%D7%A9%20(1).jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2XGcH9UUvn7vk8B4xxZaRBqf38Qyrz5W9GyifUwx72FD",
            "symbol": "SUNL",
            "name": "SunLight",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/SunLight-SUNL/assets/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8dPtoBrsL8YAcTs3nmibwy5QTNk6p3SoPZGtP5QCTbhM",
            "symbol": "MTPR",
            "name": "METAPHOR COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/metaphor-token/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3nJcD3BNVcLhJoxXwXiGEkQ3qoeoRfHZRUK63K54t3Qy",
            "symbol": "$BeNFT",
            "name": "BeNFT Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/BeNFT2022/images/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "$BeNFT revolutionizes employee recognition programs by introcing NFTs",
                "telegram": "https://t.me/+Q6nm372MCTtjNDhh",
                "twitter": "https://twitter.com/Thebenfts",
                "website": "https://www.benft.solutions"
            }
        },
        {
            "chainId": 101,
            "address": "AyDDpSg7Q6icGx7RAntSDAuwxdMkEsRWkL4QkeqoQF1H",
            "symbol": "BTWL",
            "name": "Official Blue Terra WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AyDDpSg7Q6icGx7RAntSDAuwxdMkEsRWkL4QkeqoQF1H/logo.png",
            "extensions": {
                "website": "https://blueterra.land/"
            }
        },
        {
            "chainId": 101,
            "address": "7qFv2mV6Kc7AmGkvaEo5kS2Zy7Z6ofCGdhRk6v3oDQMG",
            "symbol": "$HAT",
            "name": "Happy Akedo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hikaric-io/hikari/main/HappiAkedoToken-256.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DsCP3hKzcpNgpuce9GYqshiq4UNhHHRhr7e75itr8se2",
            "symbol": "LIFE",
            "name": "LifeLosAngelesco",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lifelosangelescompany/crypto/main/lifelosangeleslogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FRr6TJLRzGiuCSscmqj7CSr35BQRaJeBewNDu2yYtDPh",
            "symbol": "ASTRAL",
            "name": "Astra Galactic",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/astragalactic/assets/main/logo-Astro%20Galactic-token.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9MkwWhJvRJwaXu9ffQjRgNyHUYWvp42KtchxyGQwkkfP",
            "symbol": "CREDO",
            "name": "Astra Galactic DAO",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/astragalactic/assets/main/logo-Astra%20Galactic-credo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5a7VDPje5YJrVF5XMUCM7CxPu7FGVifiTEsxpyFc3rSP",
            "symbol": "FIN",
            "name": "FINCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5a7VDPje5YJrVF5XMUCM7CxPu7FGVifiTEsxpyFc3rSP/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DQDYyw71uRM5QBZdXK2wEDkqqRAox2d5jBdVrLxeXaA8",
            "symbol": "SHRD",
            "name": "Brawl Shards",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/strongestbrawler/cryptowork/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BSL5GUsceBaKTTijp5ppszeV2xHHzaihSDdL1kQtNnjU",
            "symbol": "SKLZ",
            "name": "Lost Skollz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BSL5GUsceBaKTTijp5ppszeV2xHHzaihSDdL1kQtNnjU/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5X6AuKY8QF2xzYUEYYCxf9t9FXhuG76hHJNAB8qUbKqz",
            "symbol": "UPGRADE",
            "name": "UPGRADE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5X6AuKY8QF2xzYUEYYCxf9t9FXhuG76hHJNAB8qUbKqz/logo.png",
            "tags": [
                "utility-token",
                "stake-pool",
                "stake-pool-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7eDhcd5zo6Dg3LAdezLvGMYE9xHWTzr2ayKzAhQp8DHy",
            "symbol": "$",
            "name": "Rmi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/richmonkeyisland/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "FXTifEbpv8sMA7TuQb3jm2EvKHMGLyhpBDvAHrzt7V6d",
            "symbol": "GoatZ",
            "name": "GoatZ",
            "decimals": 2,
            "logoURI": "https://solaboy.github.io/images/goatz.png",
            "tags": [
                "nft",
                "crypto",
                "rewards"
            ],
            "extensions": {
                "telegram": "https://t.me/goatz_world",
                "website": "https://www.goatz.io"
            }
        },
        {
            "chainId": 101,
            "address": "5WckfuLPnEamS8VwCJoRUyT6J8C6PE6Ru5kTz6J8ft9f",
            "symbol": "DSPY",
            "name": "DISPLAY COIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/displayclub/displaytoken/main/assets/IMP%20TOKEN%20DSPY.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HwK5VcHmByX7u3oSM8TrVeECaQPVLFVKr2CtvygvBUMC",
            "symbol": "iVIDI",
            "name": "iVIDI",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HwK5VcHmByX7u3oSM8TrVeECaQPVLFVKr2CtvygvBUMC/logo.png",
            "extensions": {
                "discord": "http://discord.gg/vidiart",
                "medium": "https://medium.com/@vidiart",
                "twitter": "https://twitter.com/Vidi_Art",
                "website": "https://vidi.art/"
            }
        },
        {
            "chainId": 101,
            "address": "88FwzFbRopWuTmZQKhVLTgr7XhEHrpmHQVtjatJtP1j8",
            "symbol": "TSNKC",
            "name": "TSINACOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kareklas123/kareklaicon/main/unknown.png",
            "tags": [
                "social-token",
                "karekla-token"
            ]
        },
        {
            "chainId": 103,
            "address": "5xRyUaUVDguVC5uCfZsTv9fHKLQrX5meZshBpnuGEPzY",
            "symbol": "TAGE",
            "name": "Test Age",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5xRyUaUVDguVC5uCfZsTv9fHKLQrX5meZshBpnuGEPzY/logo.png",
            "tags": [
                "future-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ZG2JQcQiuNmRq8VtJKDedAX4NLHwuwLWkLoi75vTK4g",
            "symbol": "PASS",
            "name": "Passport Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PassportToken/pass/main/logo.PNG",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31pKQMfQCdAvH8RuWf2k4UGczZdGRQzaznKGSBEqvvZF",
            "symbol": "MTT",
            "name": "Ming Tung Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MTBHK/MingTungBank/main/MTBLOGOV2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4RBJRwEAnNEe664LgSECkkfeT4fc6ZjtCMgL7rtUs8g1",
            "symbol": "CYRUS",
            "name": "Bizarre Platypus Cyrus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4RBJRwEAnNEe664LgSECkkfeT4fc6ZjtCMgL7rtUs8g1/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Bizarre Platypus Token.",
                "twitter": "https://twitter.com/bizarreplatypus",
                "website": "https://bizarreplatypus.io"
            }
        },
        {
            "chainId": 101,
            "address": "7KG5WNqNbUdXY5MBX7TUVZMTSD5cGoYxwYwry96GD1sM",
            "symbol": "WORKS",
            "name": "WORKS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7KG5WNqNbUdXY5MBX7TUVZMTSD5cGoYxwYwry96GD1sM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/officialgigworks",
                "instagram": "https://www.instagram.com/officialgigworks/",
                "linkedin": "https://www.linkedin.com/company/officialgigworks",
                "twitter": "https://twitter.com/gigwrks",
                "website": "https://www.works.so"
            }
        },
        {
            "chainId": 101,
            "address": "HBteHRLoj7ANfbfxKPhHMvWLMfUg1xz69uhi6C3hfcfo",
            "symbol": "SCWL",
            "name": "Space Civilians Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Diamond-Hands-Studio/token-images/main/Whitelist.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "77QtZwSAsV9jP6CaS7GoETpboRZ6Gm1gkebxHEybBmvN",
            "symbol": "PASS",
            "name": "Space Civilians Alpha Squad Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Diamond-Hands-Studio/token-images/main/AlphaSquad.png",
            "tags": [
                "community-token",
                "Golden-ticket",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "E8VwNHRvPR1DBNfTpJdh2dysTDGfsAippyviP7owgY6h",
            "symbol": "DONATE",
            "name": "Space Civilian Space Whales",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Diamond-Hands-Studio/token-images/main/SpaceWhale.png",
            "tags": [
                "social-token",
                "community-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "6JKdix5WeqhB87hAZxgTfCfR27gcD28PnE8roLaw1T1v",
            "symbol": "CRED",
            "name": "Standard Galactic Credit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Diamond-Hands-Studio/token-images/main/StandardGalacticCredit.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8wpsWoW6bNsnXPpNgVwXRoghHhwBnUWP2DNCRYqBtn3W",
            "symbol": "LUCY",
            "name": "LUCY",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8wpsWoW6bNsnXPpNgVwXRoghHhwBnUWP2DNCRYqBtn3W/logo.svg",
            "tags": [
                "community",
                "staking"
            ],
            "extensions": {
                "discord": "https://discord.gg/dJRVh7nXYb",
                "twitter": "https://twitter.com/419NFT",
                "website": "https://419.so"
            }
        },
        {
            "chainId": 101,
            "address": "GSfjUZ5pN1Ab7RpYsvUX2UiNXxg4zVssGtpCaf8B36hr",
            "symbol": "SUTH",
            "name": "The Succoth Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/thesuccoth/The-Succoth-coin/main/succoth.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHWLyMqU4w6nprba4FE6g6E5muUEgS5BmHhaMb3FaBjU",
            "symbol": "CHWL",
            "name": "Meta Chimp Whitelist Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/pIWAkKG0Zd14USGL1sc3LCF7x18qDpytvvCv7JGJx78",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/W8fra6bABu",
                "twitter": "https://twitter.com/rationalasf",
                "website": "https://linktr.ee/rationalasfuck",
                "youtube": "https://www.youtube.com/c/RationalAF"
            }
        },
        {
            "chainId": 101,
            "address": "5eU1Wy8QhYkYtLBoUDGEqDjT3vH5RVdKsLCp995KG6VJ",
            "symbol": "coc",
            "name": "colourcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MaximilianPrestage/crypto-/main/Colorful_Background_3_RF_RMPL-01.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "u8am3pjJ6poZrpwgerGyAmPYHfg7AmQyR8YNaG39onS",
            "symbol": "$FOX",
            "name": "Fox Dynasty",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/u8am3pjJ6poZrpwgerGyAmPYHfg7AmQyR8YNaG39onS/logo.png",
            "tags": [
                "community",
                "staking"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FoxDynastyNFT",
                "website": "https://www.foxdynastynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EmzYLb3fwhjNp726Na5zLdhgrZjyC8GrfNGyopq6731w",
            "symbol": "TYLER",
            "name": "Stock Tyler Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/5a6012d507438ca35419bc35cfa941e8/2e865450",
            "tags": [
                "bridgesplit-fraction",
                "stock-tylers",
                "fraction-index",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/EmzYLb3fwhjNp726Na5zLdhgrZjyC8GrfNGyopq6731w"
            }
        },
        {
            "chainId": 101,
            "address": "J3uigCZEWVwFgGsSnKXFsEsH4AqJ7hLQ7PuN3WGSwfdk",
            "symbol": "$NEXT",
            "name": "Next Chapter Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J3uigCZEWVwFgGsSnKXFsEsH4AqJ7hLQ7PuN3WGSwfdk/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://nextchapter.to/"
            }
        },
        {
            "chainId": 101,
            "address": "foG7mADNFZoy2nQt4XWc3Jrhrdu65KZekKWLBvpZyk2",
            "symbol": "BRE4D",
            "name": "Dapper Ducks Bread",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/foG7mADNFZoy2nQt4XWc3Jrhrdu65KZekKWLBvpZyk2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dapper_ducksnft",
                "website": "https://thedapperducks.com"
            }
        },
        {
            "chainId": 101,
            "address": "FWRVAvoQQ6DWXypAnZojQTwfwvEiBV2jyGFyx9uE2o8x",
            "symbol": "$BONES",
            "name": "Dapper Ducks BONES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FWRVAvoQQ6DWXypAnZojQTwfwvEiBV2jyGFyx9uE2o8x/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dapper_ducksnft",
                "website": "https://thedapperducks.com"
            }
        },
        {
            "chainId": 101,
            "address": "4J4XAtCWWVrb4FBM4JySPWX3YWix2bTpZNtAAHH4UEba",
            "symbol": "SCHOLA",
            "name": "Schola",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4J4XAtCWWVrb4FBM4JySPWX3YWix2bTpZNtAAHH4UEba/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/ScholaEducation",
                "website": "https://www.schola.education"
            }
        },
        {
            "chainId": 101,
            "address": "CRKzmPtn1jW8SbQjTiEok4tzrEGqgFcTjY6G1943d23C",
            "symbol": "EUSP",
            "name": "EuroSpeed",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RubenYordano/EuroSpeed/main/274885725_528552265260738_524344753350750781_n.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EVR1MYMZ929kwEFt9rNgHYkAsjCY8oKFN7svddZXavTB",
            "symbol": "GEM",
            "name": "Gem Block",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EVR1MYMZ929kwEFt9rNgHYkAsjCY8oKFN7svddZXavTB/logo.png",
            "extensions": {
                "website": "https://gemblock.co",
                "whitepaper": "https://gemblock.co/wp/GEM_Whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "BHsev9KAEjheM5We6Zew7uYavPcUpXrNr35NBWMJmqVj",
            "symbol": "$TDS",
            "name": "The Deadly Skulls",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BHsev9KAEjheM5We6Zew7uYavPcUpXrNr35NBWMJmqVj/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DeadlyTds"
            }
        },
        {
            "chainId": 101,
            "address": "B21QKFnx6PKPvr56VWwCuMG9EovsLWG6tuhHpmCawtEa",
            "symbol": "$GIZMO",
            "name": "Gizmo Rebels Token",
            "decimals": 9,
            "logoURI": "https://eager-dubinsky-9aa533.netlify.app/token/gizmo_token_logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Utility token of the Gizmo Rebels NFT collection.",
                "discord": "https://discord.gg/4JAwZNP58x",
                "twitter": "https://twitter.com/GizmoRebels",
                "website": "https://gizmorebels.io/",
                "whitepaper": "https://gizmorebels.notion.site/gizmorebels/Gizmo-Rebels-Litepaper-bd8f0ede26cf4603888bd90c54529ffb"
            }
        },
        {
            "chainId": 101,
            "address": "5KLMunWFYRRWxHtwk7gE6C4LzmUgnF6LNuj4XPfxCumd",
            "symbol": "RONIN",
            "name": "Ronin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RoninFisher/Ronin/main/EagleCircle.png",
            "tags": [
                "social-tokens"
            ]
        },
        {
            "chainId": 101,
            "address": "CN4bGnSdNDD3A6kLTBKffF4ocN3AMg8ZQCrzoRyEZ4Yw",
            "symbol": "EDST",
            "name": "Ehecatl Dragon Souls Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Chirryrth/EDST/main/Dragon_Soul.png",
            "tags": [
                "social-tokens"
            ]
        },
        {
            "chainId": 101,
            "address": "Komoh63FFuxxMHKuAtpEpL2fYJLooicXKyqDqmPa54n",
            "symbol": "KOMO",
            "name": "Komoverse",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Komoh63FFuxxMHKuAtpEpL2fYJLooicXKyqDqmPa54n/logo.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "98MkjJ2nDgSGeYf56DYCzymGpMNTV7iQceHGcfBZ6PdT",
            "symbol": "Blockstone",
            "name": "Crypto Beach",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/98MkjJ2nDgSGeYf56DYCzymGpMNTV7iQceHGcfBZ6PdT/beach.png",
            "tags": [
                "Crypto-Beach",
                "Holdiday-homes"
            ],
            "extensions": {
                "website": "https://blocksto.ne/crypto-beach/"
            }
        },
        {
            "chainId": 101,
            "address": "J8ZXjuor6nWYnvsYXKRHPZmEFepZt96dMjeKMfD7qZA2",
            "symbol": "$ITKN",
            "name": "Hassanat (Islam Token)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J8ZXjuor6nWYnvsYXKRHPZmEFepZt96dMjeKMfD7qZA2/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Hassanat ($ITKN) is the token of Islam, utility token for Muslims who can open the gates of paradise by doing good deeds.",
                "discord": "https://discord.gg/qEfCZuJHsu",
                "twitter": "https://twitter.com/hassanat_itkn"
            }
        },
        {
            "chainId": 101,
            "address": "4agZJaNSecPG8vueyn8cqK8x7sPoU2NeD4kKScRxP7WC",
            "symbol": "LIBER",
            "name": "Liberum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Liberumx/crypto/main/Logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9zeGo62tsX4HG4RepuhczBgBNaBbDuQ2tyaKjqDgaSor",
            "symbol": "JV",
            "name": "Vibe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JakesterMt/SolToken/main/J.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr",
            "symbol": "USDC-Dev",
            "name": "USD Coin Dev",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "blog": "https://medium.com/credix/building-an-spl-token-faucet-on-solana-2b8a07d364ff",
                "coingeckoId": "usd-coin"
            }
        },
        {
            "chainId": 101,
            "address": "BbsPqYi2Zto5wcJrPz1KiuvnnBMmVbbTo1xVtvLgczqg",
            "symbol": "PRLY",
            "name": "Parlay",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BbsPqYi2Zto5wcJrPz1KiuvnnBMmVbbTo1xVtvLgczqg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/parlaynft",
                "website": "https://theparlaynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G2q99gPeUhTdMMtGuMvfq7xn3SpVzUL5d7VPcVXEzFct",
            "symbol": "KUMPEL",
            "name": "Kumpel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/S1adem4n/crypto/main/E7284313-B475-4543-B2E9-03CA46F541A2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BVH95rvjK4mnBiCTioYg7rCnVqxfpHkFvYREJ6A2q4Ka",
            "symbol": "JABS",
            "name": "Jab Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adiquet/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AEcFA1cq4Mtz568HL8sd7tQ9EyTYFNV6EHw6KrqYT785",
            "symbol": "bSHARD",
            "name": "Brawl Shard",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/strongestbrawler/brawlshard/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "This is the utility token for BitBrawl.",
                "twitter": "https://twitter.com/bitbrawlio"
            }
        },
        {
            "chainId": 101,
            "address": "F9ZDLuTiRJwrmeP5zystepNofya4CvDpWpoVkbZbZwi1",
            "symbol": "MIKI",
            "name": "MindKind",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F9ZDLuTiRJwrmeP5zystepNofya4CvDpWpoVkbZbZwi1/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AVKwBcNTBzLa3t24W37yTQKQumaK7E8vrmZHiYDYfX4t",
            "symbol": "JSCTC",
            "name": "Jaguar Society Coin",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/AVKwBcNTBzLa3t24W37yTQKQumaK7E8vrmZHiYDYfX4t/logo.jpg?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3tYsZEAAfpUBWQt6TShpo6JSuVjPrpx6GruR4BHFHV7J",
            "symbol": "$FROTH",
            "name": "FROTH",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3tYsZEAAfpUBWQt6TShpo6JSuVjPrpx6GruR4BHFHV7J/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DuxDz1K3npjMYT6ZoH628pK3zJYjUzDMGT3ZQHKz1VYb",
            "symbol": "SST",
            "name": "Solana Storm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DuxDz1K3npjMYT6ZoH628pK3zJYjUzDMGT3ZQHKz1VYb/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://solanastorm.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7FvaS3FZ3RThvFeZspkzszF9hj5Zp6SMrxjkoz74NfX",
            "symbol": "SKUL",
            "name": "SKUL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/76cacdce94f997540d1ecfbad7cb526272cc5c49/assets/mainnet/7FvaS3FZ3RThvFeZspkzszF9hj5Zp6SMrxjkoz74NfX/skull.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BPkDkV63heb1gxwah1cZ7weoUfaEQapR9qjd27aiJZdc",
            "symbol": "$SCALE",
            "name": "Cryptilian Scale Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BPkDkV63heb1gxwah1cZ7weoUfaEQapR9qjd27aiJZdc/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cryptilians_nft",
                "website": "http://cryptilians.io"
            }
        },
        {
            "chainId": 101,
            "address": "6dJTbfwiJTD3jHpB2hS8PjDXxdJXQGTHmYNsAfJhwrfq",
            "symbol": "DEHC",
            "name": "DCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mountaineer2600/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "invSTFnhB1779dyku9vKSmGPxeBNKhdf7ZfGL1vTH3u",
            "symbol": "IV",
            "name": "Invoker",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/invSTFnhB1779dyku9vKSmGPxeBNKhdf7ZfGL1vTH3u/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/invokers",
                "twitter": "https://twitter.com/invokersnft"
            }
        },
        {
            "chainId": 101,
            "address": "J2hGHwbkpj2SVo6Bs4X2Houy7n6oauydhbh9D6HpKBU4",
            "symbol": "JFI-USDC",
            "name": "Jungle DeFi Raydium LP Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J2hGHwbkpj2SVo6Bs4X2Houy7n6oauydhbh9D6HpKBU4/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "The Jungle Finance Raydium LP token.",
                "discord": "https://discord.gg/2DWjx5NywE",
                "medium": "https://medium.com/@JungleDeFi",
                "twitter": "https://twitter.com/JungleDeFi",
                "website": "https://jungledefi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "51D28d7vte67dPtcvX6bnw3V67VVPAUf6HpBhVfhx6cV",
            "symbol": "FERGIE",
            "name": "Ferguson",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SuperFergie/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CWy42AVkx8jj39Jnb6A2ChupCNcsoqdsiRCYHh3XuREj",
            "symbol": "NXTK",
            "name": "NxtTek",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/phaktor/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HMKSrb4Nb894wEmwYveACs3y7wHy7TKzf3kqPeiHjaqa",
            "symbol": "HMKS",
            "name": "Haughty Monkee Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMKSrb4Nb894wEmwYveACs3y7wHy7TKzf3kqPeiHjaqa/logo.png"
        },
        {
            "chainId": 101,
            "address": "ABaeZ4AJ91Qu2DDZH9yvJD6KrPGkFAUZ7uDycTZwCotH",
            "symbol": "PISS",
            "name": "PissCoin",
            "decimals": 0,
            "logoURI": "https://github.com/JarSittel/PissCoin/blob/master/PissCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "stmnJnBt9kNdjYWrcQSj6ZdLeLvzvrXd8hSKCHHhJ5U",
            "symbol": "sFANT",
            "name": "Phantasia Staking Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/stmnJnBt9kNdjYWrcQSj6ZdLeLvzvrXd8hSKCHHhJ5U/logo.png",
            "tags": [
                "stake-pool-token",
                "staking-token"
            ],
            "extensions": {
                "discord": "https://t.co/Vskz9PkBBC?amp=1",
                "github": "https://github.com/Phantasia-Sports",
                "medium": "https://medium.com/@phantasia",
                "twitter": "https://twitter.com/PhantasiaSports",
                "website": "https://phantasia.app/"
            }
        },
        {
            "chainId": 101,
            "address": "6sKbBU1ECSokaBeh7jKvhBu2i1ZyDhDUQz8d2FoRpdrC",
            "symbol": "PreRacers$",
            "name": "Pre-Racers WL token",
            "decimals": 0,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/8a63a599-2b2b-4261-b12e-19f5cfe24b00/public",
            "extensions": {
                "discord": "https://discord.gg/YhjYHSJDu5",
                "twitter": "https://twitter.com/Spidey_Eden",
                "website": "https://spideyeden.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2EKoef9nDjJGtLBtXuWCTMyRuB4iidYSThCmoVvQyK3B",
            "symbol": "KNIP",
            "name": "Kat Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kombatkat/crypto/main/kombatkat.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5bBDXm92dDqqnpmiLPE7ZMFYdLhyhNMLwzotPSar3sLY",
            "symbol": "WKN",
            "name": "Walkoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FardMan69420/crypto/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToiQNrFfF7eP9DdtDPhzTLZJvAPDT5yvQqBDEU9FkUn",
            "symbol": "DAREDUWL",
            "name": "DAREdu Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bradbalogh/DAREdu-assets/main/DAREDUWL.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sbsJFhGh",
                "twitter": "https://twitter.com/DAREdu_NFT",
                "website": "https://daredu.io/"
            }
        },
        {
            "chainId": 103,
            "address": "FovRJ61rWzbasfX46Zv8HQgzEz7t8VWXezMpRckjU4BM",
            "symbol": "CMP",
            "name": "Camp Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FovRJ61rWzbasfX46Zv8HQgzEz7t8VWXezMpRckjU4BM/logo.png"
        },
        {
            "chainId": 101,
            "address": "kasaU22JExTfVikDPKqV4yd2W46nAbJcAkz8AK1kxGF",
            "symbol": "KASA",
            "name": "Kasa",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kasaU22JExTfVikDPKqV4yd2W46nAbJcAkz8AK1kxGF/logo.png",
            "tags": [
                "utility-token",
                "tokenized-stock",
                "solana",
                "kasa",
                "real-estate"
            ],
            "extensions": {
                "description": "Bridging the worlds of crypto and real-estate",
                "twitter": "https://twitter.com/thekasaproject",
                "website": "https://kasa.finance"
            }
        },
        {
            "chainId": 101,
            "address": "BZhA4kJHMCEzDxreHKSVEogLWt5hBHfq1A9Aoa64Kb9d",
            "symbol": "HCP",
            "name": "Hyper Complex Project",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/HyperComplexProject/HCPtoken/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "2JfA8r8eSAxkjXP9LxGoj8MTe1vCiSCxwesY8ZPyiNoS",
            "symbol": "$Shady",
            "name": "Shady",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Ken-tech-max/ShadyTigsToken/main/photo_2022-02-28_06-40-46.jpg",
            "tags": [
                "rewards"
            ],
            "extensions": {
                "description": "Metaverse talent contests and wealth generation with blockchain validation",
                "website": "https://shadytigs.com"
            }
        },
        {
            "chainId": 101,
            "address": "Hd65ZzpmXaXgmjRy7QrVPmSqiWzZDRFkLCTBfoBM6XAz",
            "symbol": "FIG",
            "name": "FIG Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hd65ZzpmXaXgmjRy7QrVPmSqiWzZDRFkLCTBfoBM6XAz/logo.png",
            "tags": [
                "fig-token",
                "game-token",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/figs71969430",
                "website": "http://projectfigtoken.com/"
            }
        },
        {
            "chainId": 103,
            "address": "AAd8oQcBCk2TBvXXzQ77DheoNTNPJSYUxhH6yYGrwLWV",
            "symbol": "tETH",
            "name": "Test Ethereum (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://github.com/LP-Finance-Inc/token-image/blob/main/ETH.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "4fBcqkaR9CpaYkXQGqP6bkkfbSDiuGLD6DDoi6LiuowX",
            "symbol": "tAVAX",
            "name": "Test Avalanche (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/AVAX.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "BRaoLCWn5Y2oEN97j4kmxt7zz1ezjFQjDp2iAC4J8pMc",
            "symbol": "tLUNA",
            "name": "Test Terra (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/wLUNA.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "EJ94TwhddyUAra7i3qttQ64Q1wExJYb8GmACbHbAnvKF",
            "symbol": "tmSOL",
            "name": "Test Marinade Staked SOL (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/msol.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "3bsvftcGX66jB39fLJ2a9ipPgVGWsxEopgJxBfdjar3D",
            "symbol": "tRAY",
            "name": "Test Raydium (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/RAY.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "86opZkLhi6V7B5oVgBT1yGvXdKzi6MfTs65M3gHeha4p",
            "symbol": "tFTT",
            "name": "Test FTX Token (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/FTT.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "Bwxtq3dx3PiH9t5wKuDPwVNpYoUXSiZyEGkbD7iNGMe",
            "symbol": "tLINK",
            "name": "Test Chainlink (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/LINK.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "HY6uvCfBQhKANRxBcYLBK7aUva8mT7mLP2SjrLLmipza",
            "symbol": "tUSDT",
            "name": "Test Tether (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/usdt.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "DeYee8csjXSngSSc9PhgyvkQYMPDyUSEXirs9pLVAGst",
            "symbol": "tSRM",
            "name": "Test Serum (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/srm.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "Bi775t6MHNDn4hoG3kZr9QDjMUEP6WeD8SNqyAuekQsM",
            "symbol": "tUST",
            "name": "Test TerraUSD (LP Finance Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/ust.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "CCFfxDcVY6iCd4EiocQNymZRhZapuGrxVP4TK1PJrVqh",
            "symbol": "lpSOL",
            "name": "LP Finance Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/lpSOL-min.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "C6DHbFE8eFmiiZPcY1mTPaG928q6cXuE9vD2NHuDL5TH",
            "symbol": "lpUSD",
            "name": "LP Finance USD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/lpUSD.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 101,
            "address": "4xiH8pUEe5u9vs2rNKqbVATePjFEL5er34Qm5spxQxWH",
            "symbol": "KNC",
            "name": "KCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kameania/crypto/main/KNC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLZAGA9jnK7o5nTgkDTDngSsSuiyegb1p7Ch5DDoQEe",
            "symbol": "ATWL",
            "name": "OG Atadian WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KKQanT/token-list/main/assets/mainnet/WLZAGA9jnK7o5nTgkDTDngSsSuiyegb1p7Ch5DDoQEe/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://www.atadia.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4pzpksQjzXcnWXciTY8Q1rmsWsywaaEZxP8YzJdYGzHU",
            "symbol": "LSDC",
            "name": "LSDC (419LABS)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pzpksQjzXcnWXciTY8Q1rmsWsywaaEZxP8YzJdYGzHU/logo.svg",
            "tags": [
                "community",
                "staking"
            ],
            "extensions": {
                "discord": "https://discord.gg/dJRVh7nXYb",
                "twitter": "https://twitter.com/419NFT",
                "website": "https://419.so"
            }
        },
        {
            "chainId": 101,
            "address": "9uGQwUDHErypQa317fherN2tmTbGh9L9My16t2eEVomS",
            "symbol": "WPSC",
            "name": "WPScreators",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wpscreatros/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FGEDRBaMtigF13ReDs562F3T3CkVvBmorzED3zBufCpC",
            "symbol": "CENS",
            "name": "Century Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGEDRBaMtigF13ReDs562F3T3CkVvBmorzED3zBufCpC/logo.png",
            "tags": [
                "gamefi",
                "metaverse",
                "create-to-earn",
                "play-to-earn",
                "parallel-world"
            ],
            "extensions": {
                "telegram": "https://t.me/censworldgroup",
                "twitter": "https://twitter.com/CensWorldnft",
                "website": "https://century.luxe"
            }
        },
        {
            "chainId": 101,
            "address": "5VQnKaTu522jRQyaawDNBKZjBa5SZoeetyDXEwocYxXN",
            "symbol": "KING",
            "name": "King's token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5VQnKaTu522jRQyaawDNBKZjBa5SZoeetyDXEwocYxXN/logo.png",
            "tags": [
                "meme-coin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kings_dao"
            }
        },
        {
            "chainId": 101,
            "address": "AnFP69EqWTZqYEuYiTu5ThhHb87mNSFvtGjyctJmhJxT",
            "symbol": "Blockstone",
            "name": "SolQ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AnFP69EqWTZqYEuYiTu5ThhHb87mNSFvtGjyctJmhJxT/Blockstone.png",
            "tags": [
                "SolQ",
                "Guaranteed-SOL"
            ],
            "extensions": {
                "website": "https://blocksto.ne/solq/"
            }
        },
        {
            "chainId": 101,
            "address": "CrSofpzMjwgwTJVjgRG6ctfjNrntSuZTD5fqSqRTAWbd",
            "symbol": "Blockstone",
            "name": "SolMax",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CrSofpzMjwgwTJVjgRG6ctfjNrntSuZTD5fqSqRTAWbd/Blockstone.png",
            "tags": [
                "SolMax",
                "Guarantee"
            ],
            "extensions": {
                "website": "https://blocksto.ne/solmax/"
            }
        },
        {
            "chainId": 101,
            "address": "J4qwFUNTtCtyW8fjAHtgiUXBLgNeJcuRoeXiLc18bdJL",
            "symbol": "ORI",
            "name": "Orinoco Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/orinocof/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BAHCdK2PNwQ15jgUqB9L3Gn78XdeNkXj4gT1ixxVaqyF",
            "symbol": "SEED",
            "name": "SEED Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/charliebakerr/psy-token/398ad3c190c33e21ef2187113ebabc70dba95d1d/HFSeedCoin.png",
            "extensions": {
                "description": "Powering the HighFlyerZ economy and beyond",
                "discord": "https://discord.gg/3AGKpS9cfR",
                "twitter": "https://twitter.com/highflyerzNFT",
                "website": "https://highflyerz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2kGvWxSUn8carzriNzyaotLLVrojzYTV6DMkjizJwN8b",
            "symbol": "KSA",
            "name": "The Kingdom of Saudi Arabia",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/nawrasco/Crypto/main/2.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GT2EtnDCN5sUWCf12P3hjA4QiNjeztLHf8FL1J1PBypa",
            "symbol": "UKR",
            "name": "Protection of the Ukrainian people",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/nawrasco/Crypto/main/UKR.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3J2KopdK7UwKK83socwHCgFoUPpp2AmyAob6XCnbH4VB",
            "symbol": "Blockstone",
            "name": "EuroQ",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3J2KopdK7UwKK83socwHCgFoUPpp2AmyAob6XCnbH4VB/Blockstone.png",
            "tags": [
                "EuroQ",
                "Guaranteed-euro"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "website": "https://blocksto.ne/euroq/"
            }
        },
        {
            "chainId": 101,
            "address": "MMWjJmjppSH8wmoRzi9bZZavRXzWqkG4YYJEreo4zrU",
            "symbol": "MMWLT",
            "name": "MinerMice Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MMWjJmjppSH8wmoRzi9bZZavRXzWqkG4YYJEreo4zrU/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "aUNZ2RVAfrPy5Q3aGUp3skJJJ938fn6GW8sG6pQCHrS",
            "symbol": "TBBC",
            "name": "Billi Brothers",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aUNZ2RVAfrPy5Q3aGUp3skJJJ938fn6GW8sG6pQCHrS/logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2EXNrLeEH91HXK1hah4jT51153jE7hFSDKaYah7HBK6J",
            "symbol": "MKI",
            "name": "MineKoin",
            "decimals": 0,
            "logoURI": "https://github.dev/nexnux/token-list/blob/18ead90d889940cff3e7b2a233bf7800f292bdf9/assets/mainnet/2EXNrLeEH91HXK1hah4jT51153jE7hFSDKaYah7HBK6J/minecoin.png",
            "tags": [
                "minecraft-coin",
                "gaming-token",
                "mineable",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KoinsMine",
                "website": "https://minekoin.net/"
            }
        },
        {
            "chainId": 101,
            "address": "Ao94rg8D6oK2TAq3nm8YEQxfS73vZ2GWYw2AKaUihDEY",
            "symbol": "$CRECK",
            "name": "CRECK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ao94rg8D6oK2TAq3nm8YEQxfS73vZ2GWYw2AKaUihDEY/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fg3NLKzwfnhtiEjnbn86wcZiGckuL5bzf61JGSqFi4ot",
            "symbol": "VVV",
            "name": "Vladimir VS Volodymyr",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/VladVSVolod/Assets/main/VladVSVolod.png",
            "tags": [
                "Vlad-VS-Volod",
                "Charity",
                "NFT",
                "P2E"
            ],
            "extensions": {
                "website": "https://www.vladimir-volodymyr.com/"
            }
        },
        {
            "chainId": 101,
            "address": "H3eTgBUXAdcssxY7Cz496PEg3scxakYsxC4Q4JTTf5SF",
            "symbol": "TED",
            "name": "Ted prueba",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H3eTgBUXAdcssxY7Cz496PEg3scxakYsxC4Q4JTTf5SF/logo.png"
        },
        {
            "chainId": 101,
            "address": "4K8wPaVSqDwTeMvTrN9twHZhdnvXTYoqBuw4F8aFkFVg",
            "symbol": "WORTH",
            "name": "Worthy",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4K8wPaVSqDwTeMvTrN9twHZhdnvXTYoqBuw4F8aFkFVg/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "http://instagram.com/worthynfts",
                "telegram": "https://t.me/worthynfts",
                "twitter": "https://twitter.com/worthynfts"
            }
        },
        {
            "chainId": 101,
            "address": "2y2NXKk6vAa3oCmrTuQqnt2CXvcUcqYjcqLqEuHYhQ3U",
            "symbol": "KIND",
            "name": "Kind Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2y2NXKk6vAa3oCmrTuQqnt2CXvcUcqYjcqLqEuHYhQ3U/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "http://instagram.com/worthynfts",
                "telegram": "https://t.me/worthynfts",
                "twitter": "https://twitter.com/worthynfts"
            }
        },
        {
            "chainId": 101,
            "address": "4NJ1L4LHSbJpk4h4rHQnJNKZbRSYticS8sQVPbGHsj33",
            "symbol": "BNCE",
            "name": "BOUNCER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4NJ1L4LHSbJpk4h4rHQnJNKZbRSYticS8sQVPbGHsj33/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/parlay",
                "twitter": "https://twitter.com/parlaynft",
                "website": "https://theparlaynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4gG8ndgwWt776JFjUgrgas6dYTrK7URox9s5FJysLhzv",
            "symbol": "YNI",
            "name": "YANI",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4gG8ndgwWt776JFjUgrgas6dYTrK7URox9s5FJysLhzv/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "yansi",
                "airdrop-token"
            ],
            "extensions": {
                "description": "Yani coin ment for everyone !!!",
                "instagram": "https://instagram.com/yansi.in",
                "website": "https://yansi.in"
            }
        },
        {
            "chainId": 101,
            "address": "5izdXUfc9km1oXaZj1PomjB8E8CvqoeHxCBxbPGebdbW",
            "symbol": "UF",
            "name": "Unidad Fomento Digital Chile",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5izdXUfc9km1oXaZj1PomjB8E8CvqoeHxCBxbPGebdbW/logo.png",
            "tags": [
                "utility-token",
                "tokenized-stock",
                "solana",
                "uf-chile",
                "unidad-fomento"
            ],
            "extensions": {
                "description": "Unidad de Fomento Digital Chile"
            }
        },
        {
            "chainId": 101,
            "address": "52spCVSg8zP5GjD4HG8YQa7KP2nDQ9zqxo6e37m67iLA",
            "symbol": "THC",
            "name": "Treehouse Club",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solanatreehouseclub/token/main/FMiJoG6VgAI0xUc.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://t.co/RS56JPQAz3",
                "twitter": "https://twitter.com/TreehouseNFT"
            }
        },
        {
            "chainId": 101,
            "address": "HWXWUXUNuBd6euKDxsL3FrCZ6P9RwmVmbXHKSd4MgxoA",
            "symbol": "BARMY",
            "name": "BARMY",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWXWUXUNuBd6euKDxsL3FrCZ6P9RwmVmbXHKSd4MgxoA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bearsarmy.com/"
            }
        },
        {
            "chainId": 101,
            "address": "H4Gu5KgyZhHFjDHJ6dqS8pLiACbumkMwpS9ywRqGGZqL",
            "symbol": "JP",
            "name": "JP Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/algr0n/JPTOKEN/main/JP.png",
            "tags": [
                "social-token",
                "MEME",
                "MEME-TOKEN",
                "Jordan-Peterson"
            ]
        },
        {
            "chainId": 101,
            "address": "DarkRc495tLsH1ULeXtP2tShUG4iFMDw6dv55oG6AwjZ",
            "symbol": "DET",
            "name": "Dark Energy",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/thefalseshad0w/crpt-token-/main/DarkRc495tLsH1ULeXtP2tShUG4iFMDw6dv55oG6AwjZ/dark.png",
            "tags": [
                "Community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://babychimps.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "4FKZsej5FonJWj24xhgG13wV4FSMQqTDwABBQaoQNMZF",
            "symbol": "stSOL-mSOL",
            "name": "Saber stSOL-mSOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4FKZsej5FonJWj24xhgG13wV4FSMQqTDwABBQaoQNMZF/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/stsol_msol"
            }
        },
        {
            "chainId": 101,
            "address": "ALPBPdBQf8ibRm3PRCycDC8bdFZQhFgjUwZMtnxA3rAA",
            "symbol": "atUST-CASH",
            "name": "Saber atUST-CASH LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALPBPdBQf8ibRm3PRCycDC8bdFZQhFgjUwZMtnxA3rAA/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-cashio"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/atust_cash"
            }
        },
        {
            "chainId": 101,
            "address": "ESoLEkfqBkqti137xAmnEHXB4omZpGXUdSPpfBWe9sau",
            "symbol": "eSOL-SOL",
            "name": "Saber eSOL-SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ESoLEkfqBkqti137xAmnEHXB4omZpGXUdSPpfBWe9sau/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/everstake_sol"
            }
        },
        {
            "chainId": 101,
            "address": "FP9C3p1r19tVyUDLgN98wcu4Rdzsu5hAcqFwY3MzbLpE",
            "symbol": "scnSOL-mSOL",
            "name": "Saber scnSOL-mSOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FP9C3p1r19tVyUDLgN98wcu4Rdzsu5hAcqFwY3MzbLpE/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/scn_msol"
            }
        },
        {
            "chainId": 101,
            "address": "FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr",
            "symbol": "ssoFTT-8",
            "name": "Saber Wrapped Wrapped FTT (Sollet) (8 decimals)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr/icon.png",
            "tags": [
                "wrapped-sollet",
                "ethereum",
                "saber-mkt-ftt",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
                "coingeckoId": "ftx-token",
                "serumV3Usdc": "2Pbh1CvRVku1TgewMfycemghf6sU9EyuFDcNXqvRmSxc",
                "serumV3Usdt": "Hr3wzG8mZXNHV7TuL6YqtgfVUesCqMxGYCEyP3otywZE",
                "waterfallbot": "https://bit.ly/FTTwaterfall",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "HUBBGekfLpdZhZcqjLeecLVz39o1ysDkicZpgMgZgPFS",
            "symbol": "USDH-USDC",
            "name": "Saber USDH-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUBBGekfLpdZhZcqjLeecLVz39o1ysDkicZpgMgZgPFS/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/usdh"
            }
        },
        {
            "chainId": 101,
            "address": "HUBBMrYrYpxkPbBbULdsvSrcoa6Qv526AGuosrhF8V59",
            "symbol": "USDH-CASH",
            "name": "Saber USDH-CASH LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUBBMrYrYpxkPbBbULdsvSrcoa6Qv526AGuosrhF8V59/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-cashio"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/usdh_cash"
            }
        },
        {
            "chainId": 101,
            "address": "KWAMdUrCdQ2j1t9S1HD29Z4RxXymXkwSh2c94598amY",
            "symbol": "atLUNA-LUNA",
            "name": "Saber atLUNA-LUNA LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KWAMdUrCdQ2j1t9S1HD29Z4RxXymXkwSh2c94598amY/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-allbridge",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/atust_wust"
            }
        },
        {
            "chainId": 101,
            "address": "LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB",
            "symbol": "sLUNA-9",
            "name": "Saber Wrapped LUNA (Portal) (9 decimals)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB/icon.png",
            "tags": [
                "wrapped",
                "wormhole",
                "saber-mkt-luna",
                "wormhole-v2",
                "saber-dec-wrapped"
            ],
            "extensions": {
                "address": "uluna",
                "bridgeContract": "https://finder.terra.money/columbus-5/address/terra10nmmwe8r3g99a9newtqa7a75xfgs2e8z87r2sf",
                "coingeckoId": "terra-luna",
                "serumV3Usdc": "HBTu8hNaoT3VyiSSzJYa8jwt9sDGKtJviSwFa11iXdmE",
                "website": "https://app.saber.so"
            }
        },
        {
            "chainId": 101,
            "address": "LUNbjQA8GAwotiHPiq9cmdVkEfYgZFGhBFnHhicjZtP",
            "symbol": "xLUNA-LUNA",
            "name": "Saber xLUNA-LUNA LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUNbjQA8GAwotiHPiq9cmdVkEfYgZFGhBFnHhicjZtP/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-synthetify"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/xluna"
            }
        },
        {
            "chainId": 101,
            "address": "SSoxcNry3qU1wN5Vj6zMJKTSqJPyPLks5KNfCJCmUPa",
            "symbol": "cSOL-cmSOL",
            "name": "Saber cSOL-cmSOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SSoxcNry3qU1wN5Vj6zMJKTSqJPyPLks5KNfCJCmUPa/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-solend"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/solend_sol_msol"
            }
        },
        {
            "chainId": 101,
            "address": "SUSeGZEV69Xy7rQfhDffyTysHgEP3nJUDMxEZJSvJr1",
            "symbol": "cUSDC-cUSDT",
            "name": "Saber cUSDC-cUSDT LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUSeGZEV69Xy7rQfhDffyTysHgEP3nJUDMxEZJSvJr1/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-solend"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/solend_usdc_usdt"
            }
        },
        {
            "chainId": 101,
            "address": "SoLWs9Av7ss1jHXqVLDVCBiDaAYuT4o5uRNMMuBFhB9",
            "symbol": "mSOL-JSOL",
            "name": "Saber mSOL-JSOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLWs9Av7ss1jHXqVLDVCBiDaAYuT4o5uRNMMuBFhB9/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/msol_jsol"
            }
        },
        {
            "chainId": 101,
            "address": "SoLaUA1vvCaidtQLYwt4yszBv1LtbYJDjEbwC4bzxca",
            "symbol": "scnSOL-JSOL",
            "name": "Saber scnSOL-JSOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLaUA1vvCaidtQLYwt4yszBv1LtbYJDjEbwC4bzxca/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/scn_jsol"
            }
        },
        {
            "chainId": 101,
            "address": "USTKgR66nvdkCc4bUKsirzEnyFoPtRyZzT2xsqB5t7c",
            "symbol": "solUST-UST",
            "name": "Saber solUST-UST LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USTKgR66nvdkCc4bUKsirzEnyFoPtRyZzT2xsqB5t7c/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/solust"
            }
        },
        {
            "chainId": 101,
            "address": "USTRHDiTkhjE1oDydHqNkPJNDeBKTTbz9crTmiYc2w9",
            "symbol": "solUST-USDH",
            "name": "Saber solUST-USDH LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USTRHDiTkhjE1oDydHqNkPJNDeBKTTbz9crTmiYc2w9/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/solust_usdh"
            }
        },
        {
            "chainId": 101,
            "address": "UXDgmqLd1roNYkC4TmJzok61qcM9oKs5foDADiFoCiJ",
            "symbol": "UXD-USDC",
            "name": "Saber UXD-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXDgmqLd1roNYkC4TmJzok61qcM9oKs5foDADiFoCiJ/icon.png",
            "tags": [
                "saber-stableswap-lp"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/uxd"
            }
        },
        {
            "chainId": 101,
            "address": "UXDjCH2xGyyLWa92stSUDftWPKGFFPEvqvk28gQA8bW",
            "symbol": "UXD-CASH",
            "name": "Saber UXD-CASH LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXDjCH2xGyyLWa92stSUDftWPKGFFPEvqvk28gQA8bW/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-cashio"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/uxd_cash"
            }
        },
        {
            "chainId": 101,
            "address": "WLPmZgnajNcCzYv68gQDsix4NNjTxGTPmKRMtiXMuFg",
            "symbol": "soFTT-FTT",
            "name": "Saber soFTT-FTT LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLPmZgnajNcCzYv68gQDsix4NNjTxGTPmKRMtiXMuFg/icon.png",
            "tags": [
                "saber-stableswap-lp",
                "saber-lp-wormhole-v2"
            ],
            "extensions": {
                "website": "https://app.saber.so/#/pools/sollet_ftt"
            }
        },
        {
            "chainId": 101,
            "address": "5azmHy2UZfpYhtxPgzPfGEh9E3i9vmQe945wQKiR1WMz",
            "symbol": "RSOULS",
            "name": "SolReapers Souls",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/beamseree/SolReapers/main/assets/Soul.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sol_reapers"
            }
        },
        {
            "chainId": 101,
            "address": "DeM5g1xR3Hj3djs1v8BMuDAGfAxzkb2KdAG1M26kNMQ5",
            "symbol": "POTUS",
            "name": "Presidential",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/asapBern/Cryypto/main/Presidential%20Logo%20-%20Copy.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2sbn1jfZUzj8VJutfmsPxtwevZpBDiBWsXJj2owjX3vn",
            "symbol": "VOTE",
            "name": "Vote",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/vote-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2U7AMRZBMFqNeCpDRnSzVeyvDTTKunssxHUj1twD4oSE",
            "symbol": "BDAY",
            "name": "Birthday",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/bday-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2urksvP5mopRd1kxmbR93tUWadqq3eSoZjjoFzZYU1Pp",
            "symbol": "DONATE",
            "name": "Donation",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/donate-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3qYpt9J7XYDDbH882W9uT9wTZiT3i4kKFfR3u87xot47",
            "symbol": "TIGE",
            "name": "Prestige",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/tige-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4tu2y9ymeNFyQSFJbRmgZ411yiXv1oye9Kkvt29RstLY",
            "symbol": "CHTR",
            "name": "Character",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/chtr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "55WUsHhhCPNrUDCzakT1m1h8XhdT2pNNbHwd2EVG3qRX",
            "symbol": "CTRL",
            "name": "Control",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ctrl-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "76esUJEVL47TkVj4NFAptPyvx6MNPiVYEVy3EyZ5pk9J",
            "symbol": "SKIL",
            "name": "Skill",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/skil-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7j6XzCsyiGBm9UNAHeU3BRtehEHeRYHds7mgW44amdxK",
            "symbol": "DATA",
            "name": "Data",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/data-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7kCXRxhESYfc2cAf5ZQ3NcAfeVPiFCk1aheULz8tvtc1",
            "symbol": "CLU",
            "name": "CLUE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/clu-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "86vhTivj5ubLUe9ehUpH78EFATmXfbYxSd3iexqGLb7w",
            "symbol": "BAND",
            "name": "Band",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/band-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Lk76j7TkpqRtD4P4VyZWsbVEXb5pMwR8ATzqSDfRkuq",
            "symbol": "STRY",
            "name": "Story",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/stry-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A39EVs7VWp9Ei7xgk5TA8FqKtH4pNogD75xCc4qJ5gJQ",
            "symbol": "PVCY",
            "name": "Privacy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/pvcy-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhV8mqAENrbvh6WY5n27uiBWwhcG5CaaYHmBRhuTuSNc",
            "symbol": "VOIC",
            "name": "Voice",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/voic-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJSYRWwxzFJZTfMyUt8eDuV16bkagTLpdbHA6rDCFkmj",
            "symbol": "BEARD",
            "name": "Beard",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/beard-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bswfb9zBz2WPbfm4zNPqkxCKfmM9A5Y4TLDtyq5Zq9YJ",
            "symbol": "TEC",
            "name": "Technician",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/tec-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CMWRswKHCyd4dsUXjfc4VVr533niNo5QhGjdN795ETaT",
            "symbol": "SRVC",
            "name": "Service",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/srvc-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EbeLsKdwKPHJm5FhBTSE7a6tC7w9Y4sDFMACqQFxaXSP",
            "symbol": "SUPPORT",
            "name": "Support",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/support-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EBKeCBPNh6A76o2VrrrqACsKMXLwGgpJXp7QjnYkZ7QJ",
            "symbol": "STOK",
            "name": "Stock",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/stok-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EHjN5vK18PepUwuX7pm3HTRQPUrMM9FmfEigTzj5ydQz",
            "symbol": "STAF",
            "name": "Staff",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/staf-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EkYLxLUfZ5gQ1rFc9yHTrrdL3v6Lt9kr1UrdXxnGLiF5",
            "symbol": "ASSOC",
            "name": "Associate",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/assoc-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EU3AeXjCZgnsKHqXzfrSNMxHjF62aUGPXmT64PdbLU2B",
            "symbol": "SUP",
            "name": "Supervisor",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/sup-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FDmQXuuLVSWMWGZSC4v3r563rkX5y678RTH5jK8tb3JL",
            "symbol": "MGR",
            "name": "Manager",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/mgr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FKd5geCZS6eNRegp91nWVq2SME5Sxv1S6e93q1ukRPfE",
            "symbol": "DIR",
            "name": "Director",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/dir-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FPZ93ZC5GJkLZXPrjGP26dRX4yo9uq5R3UXYimKJ82Ym",
            "symbol": "OFCR",
            "name": "Officer",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/ofcr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G1i5oFXWpjYsu5JeAPEvAQysN5RiE9PtBNYoT8aUdbQ9",
            "symbol": "TRES",
            "name": "Treasurer",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/tres-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GF26aQDP13R1Ha3SXv5bXh9Wm4Kvq4duVH6xUnzyEHjX",
            "symbol": "SECR",
            "name": "Secretary",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/secr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GkLmtLYTe3Kc8hBW9hH6kXh9uijJyRJeFgTrLcnyFqKX",
            "symbol": "VP",
            "name": "Vice President",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/vp-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GyGZZS2ZgPdx5pshRCfxGiCJPU9yM26749e6QZhQps7n",
            "symbol": "PRES",
            "name": "President",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/pres-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HeX8qsnwPyPNnCmKstissYDm3J6cDUb6ScrAD1Ls5sEL",
            "symbol": "EXEC",
            "name": "Executive",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/exec-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "647wdbz4VJQ9WXTFNHaNsJrd6gSSHQYeYtvdiLVCSiS",
            "symbol": "LEAD",
            "name": "Leadership",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/lead-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "29FWnmKQTpBfZX5M2TZnxrgLVHBEdLWgkcuke9XM8RWr",
            "symbol": "CDUB",
            "name": "Crypto Dubloon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/officialmigueltorres/Crypto/main/Dubloon-logo-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "APbULJeHRq1w7bzXcTJ4CXQQcuYg9u7rYQnc8J4k5Cdh",
            "symbol": "EXP",
            "name": "EXP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APbULJeHRq1w7bzXcTJ4CXQQcuYg9u7rYQnc8J4k5Cdh/EXP-logo.png",
            "tags": [
                "utility-token",
                "Game-token"
            ],
            "extensions": {
                "website": "https://bitmon.io"
            }
        },
        {
            "chainId": 101,
            "address": "HdsHqjH4fvaSfBuByoM6ijT8hTibdnfXHytJVMvUwoJu",
            "symbol": "$Ball",
            "name": "Ball",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HdsHqjH4fvaSfBuByoM6ijT8hTibdnfXHytJVMvUwoJu/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ape_nba",
                "website": "https://apenba.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7rxkKVPwD7ddXuEryeC7mwHyBscz3eqxmgeYhaeySRnF",
            "symbol": "GOOP",
            "name": "Goop Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rxkKVPwD7ddXuEryeC7mwHyBscz3eqxmgeYhaeySRnF/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheLickIn13Keys",
                "website": "https://goop.house/"
            }
        },
        {
            "chainId": 101,
            "address": "NoRDquauNev8h7syCd5zxgNZaYZBqcy2j8YYCDBEa3r",
            "symbol": "TyTokn",
            "name": "Token of Glory",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NoRDquauNev8h7syCd5zxgNZaYZBqcy2j8YYCDBEa3r/logo.png",
            "extensions": {
                "website": "https://www.tylermcnair.com/nordic-glory-nft"
            }
        },
        {
            "chainId": 101,
            "address": "41FRcQJzjupZe8MNVcNfYoCCJLtr31aUHVSBwH2zvALd",
            "symbol": "QVIP",
            "name": "Qi Coin VIP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/41FRcQJzjupZe8MNVcNfYoCCJLtr31aUHVSBwH2zvALd/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/hitbalm",
                "website": "https://hitbalm.com/"
            }
        },
        {
            "chainId": 101,
            "address": "66edZnAPEJSxnAK4SckuupssXpbu5doV57FUcghaqPsY",
            "symbol": "PRGC",
            "name": "ProtoReality Games Token",
            "decimals": 9,
            "logoURI": "https://www.protorealitygames.com/images/PRGC_Token.png",
            "tags": [
                "utility-token",
                "game-token",
                "gaming"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/7pjQxtUc5F",
                "telegram": "https://t.me/PRG_and_DO119",
                "twitter": "https://twitter.com/ProtoRealityGmz",
                "website": "https://www.protorealitygames.com",
                "youtube": "https://www.youtube.com/channel/UCfSBSgEnTOZfSCOkY5pGqLg"
            }
        },
        {
            "chainId": 101,
            "address": "2ekksitUHfghpkwfnEBcD385YBXwGL75tPwcS1i3b3Vw",
            "symbol": "DEGA",
            "name": "DEGA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ekksitUHfghpkwfnEBcD385YBXwGL75tPwcS1i3b3Vw/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "BYHvqH47A1UrUDZnPhE94X9LLg2ChrxwmkXfabbxy43A",
            "symbol": "AKQJ",
            "name": "Solitaires Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYHvqH47A1UrUDZnPhE94X9LLg2ChrxwmkXfabbxy43A/logo512.png",
            "tags": [
                "whitelist-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "Wi7m4DQFLwz226a3RJeTkPeY6C9rov4hsUvnFxNf2WC",
            "symbol": "GMGWL",
            "name": "GMG Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Wi7m4DQFLwz226a3RJeTkPeY6C9rov4hsUvnFxNf2WC/logo.jpg",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FezCBjaR6ZRnwpYuZ8dL2h7kqPWXnwBHcBACzV2B8kRT",
            "symbol": "BYTE",
            "name": "Byte Buddies",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Kennyaldez/assets/main/assets/token%20logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "wrBTCqVjkpqktbqN3CeGVSzQ9PFiPonHN98uwEpwMsy",
            "symbol": "wrBTC",
            "name": "Wrapped BTC (Player 2)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/wrBTCqVjkpqktbqN3CeGVSzQ9PFiPonHN98uwEpwMsy/logo_wrBTC.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/player2",
                "github": "https://github.com/player2world",
                "reddit": "https://www.reddit.com/r/player2world",
                "twitter": "https://twitter.com/player2world"
            }
        },
        {
            "chainId": 101,
            "address": "45Pt8dSXr59TbB7ZgBXJpycYGkkwdqHYkTcWkpqtNttE",
            "symbol": "RUB",
            "name": "Massage",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/rub-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6DkU5mszGEVWta5G25536m6uH2kQGVkwQGo2JswWkWLG",
            "symbol": "GMBL",
            "name": "Poker Chip",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/gmbl-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6vt26AmQr4jciA8S8RP9CJAwxquapdzwPHxkCCFpEENs",
            "symbol": "XPRT",
            "name": "Expertise",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/xprt-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HnYu5Tfqgcp7uvYH8degPpX5eicNjL7JEMVFq3nj7tP6",
            "symbol": "PLDG",
            "name": "Pledge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/pldg-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3B7BHTB3AyZfb2teKirnEZCeUmnvJ5asRkfLJpYoSGq6",
            "symbol": "KNO",
            "name": "Knowledge",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/kno-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BALLimbbj8tUo76Ksv6ESyNNNR2SKtcfDPeCuLiwpUeE",
            "symbol": "MTBL",
            "name": "Metaball",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BALLimbbj8tUo76Ksv6ESyNNNR2SKtcfDPeCuLiwpUeE/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Rbj9ezcxnC",
                "twitter": "https://twitter.com/MetaballNFT",
                "website": "https://www.metaballnft.io"
            }
        },
        {
            "chainId": 101,
            "address": "EYZMoSHaVmULt3AnujorGweJ6NPqzDaUbLrTiCwZeJYu",
            "symbol": "BOTTLE",
            "name": "Message Bottle",
            "decimals": 0,
            "logoURI": "https://dd6jvny7gn5elomvxk6uqibfvsdtpfiid7rxvparqrqaq4kz.arweave.net/GPyatx8zekW5lbq9SCAlrI_c3lQgf43q8EYRgCHFZ_U?ext=png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BohemiaArtFair"
            }
        },
        {
            "chainId": 101,
            "address": "Gj8tg2wwUYqk6MWd21jGgzcSiAumMHaBEfB3VSo5FKoF",
            "symbol": "SKB",
            "name": "Skarbucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/d0nt-by/crypto/main/Body5.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CbBcr2RnpycCceMMCDRmNXcsqYxDLW4TqfGN6CD3CP9d",
            "symbol": "DGKT",
            "name": "Digital Knights Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DigitalKnightsGA/crypto/main/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8E46KTad6wrB1TdFnozAjX8BLvL6pnFaADqGL3KFMtX",
            "symbol": "HFT",
            "name": "Feet Tokens",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/Karfearsw/HeatFeet-Token/main/hft.jpg",
            "tags": [
                "minecraft-coin",
                "gaming-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://Heatfeet401.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2EqzJJtqTDeVLmV6wPHyvRLcBYEbqa4PowZjM34mzYay",
            "symbol": "SOLYuan",
            "name": "SOLYuan universe",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2EqzJJtqTDeVLmV6wPHyvRLcBYEbqa4PowZjM34mzYay/logo.jpg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7CUJNc1jHfT9J391frL6CiLhwJUiFBs5SErhdBJ3KAQQ",
            "symbol": "WSL",
            "name": "webeSail",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7CUJNc1jHfT9J391frL6CiLhwJUiFBs5SErhdBJ3KAQQ/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://webeteer.com"
            }
        },
        {
            "chainId": 101,
            "address": "CJ8icYUpMiPNEnxtfqUnDsHEtJA6tqvag9LDNRzzbZRF",
            "symbol": "CAOS",
            "name": "Cacao",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/buymesalmon3/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AuHBUtTsD2uU5MTSZFwZ9d63Ug78pZ3JxCH7cRNcRxcK",
            "symbol": "ZELT",
            "name": "ZEL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kmperkins85/ZEL/main/ZEL_TOKEN_LOGO_3.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "senWz5Nzt73xvDdk1Qbh3BLL1995xppr52dL8ti4gEo",
            "symbol": "SEFMT",
            "name": "Scalp Empire FMT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/senWz5Nzt73xvDdk1Qbh3BLL1995xppr52dL8ti4gEo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ScalpEmpireNFT",
                "website": "https://www.scalp-empire.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AZTJ1BCAZC6MVUnt8NCmK1aBjHVtnbzdpqaHCZBaXv5d",
            "symbol": "ESK",
            "name": "Eskudo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fontes81/eskudo/main/eskudo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 102,
            "address": "7tyjWFuQR2n751LQbb9rvKaDiGQMd4ce3XAVd8K62gwA",
            "symbol": "DJIB",
            "name": "Djib",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tyjWFuQR2n751LQbb9rvKaDiGQMd4ce3XAVd8K62gwA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Universal Data Storage Parachain",
                "discord": "https://discord.gg/PpZgKJkKpb",
                "github": "https://github.com/Djib-io",
                "imageUrl": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tyjWFuQR2n751LQbb9rvKaDiGQMd4ce3XAVd8K62gwA/logo.png",
                "telegram": "https://t.me/DjibTech",
                "twitter": "https://twitter.com/Djibchain",
                "website": "https://djib.io"
            }
        },
        {
            "chainId": 101,
            "address": "5Wsi3RdR7fRYu5KUNrSUHNZuFg7wPhPdJxjMNqu4vme4",
            "symbol": "RASCAL",
            "name": "Rascal Land Token",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/RascalLand/token/main/logo.png",
            "tags": [
                "social-token",
                "rascal-land",
                "rascal-token",
                "rascal-land-token"
            ],
            "extensions": {
                "website": "https://rascalland.com/"
            }
        },
        {
            "chainId": 103,
            "address": "6R5BbsUof8ipV3evjJEpkZghMUA4ZvX8dndtsLbZpPa8",
            "symbol": "SAIS",
            "name": "SAI Airdrop Token S",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/staratlas-italia/frontend/main/public/images/apple-touch-icon.png",
            "tags": [
                "airdrop-token"
            ],
            "extensions": {
                "discord": "https://dsc.gg/staratlasitalia",
                "github": "https://github.com/staratlas-italia",
                "medium": "https://staratlasitalia.medium.com/",
                "twitter": "https://twitter.com/staratlasitalia",
                "website": "https://staratlasitalia.com",
                "whitepaper": "https://staratlasitalia.com/whitepaper/"
            }
        },
        {
            "chainId": 103,
            "address": "8AfufJACJG2VbyxMMN3EEX5aVGuXnYLWPXDf5WuXoogz",
            "symbol": "SAIM",
            "name": "SAI Airdrop Token M",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/staratlas-italia/frontend/main/public/images/apple-touch-icon.png",
            "tags": [
                "airdrop-token"
            ],
            "extensions": {
                "discord": "https://dsc.gg/staratlasitalia",
                "github": "https://github.com/staratlas-italia",
                "medium": "https://staratlasitalia.medium.com/",
                "twitter": "https://twitter.com/staratlasitalia",
                "website": "https://staratlasitalia.com",
                "whitepaper": "https://staratlasitalia.com/whitepaper/"
            }
        },
        {
            "chainId": 103,
            "address": "2y6uJ4awE7pqqvk1VPe4Yak66jPcmcfMRiZdy9JzHcW9",
            "symbol": "SAIL",
            "name": "SAI Airdrop Token L",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/staratlas-italia/frontend/main/public/images/apple-touch-icon.png",
            "tags": [
                "airdrop-token"
            ],
            "extensions": {
                "discord": "https://dsc.gg/staratlasitalia",
                "github": "https://github.com/staratlas-italia",
                "medium": "https://staratlasitalia.medium.com/",
                "twitter": "https://twitter.com/staratlasitalia",
                "website": "https://staratlasitalia.com",
                "whitepaper": "https://staratlasitalia.com/whitepaper/"
            }
        },
        {
            "chainId": 101,
            "address": "9nT91mYnAjdJYmgDBqa4jY4p6RpHXJKNj7Yz7KyqmZjv",
            "symbol": "$CREDZ",
            "name": "Neon Game CREDZ",
            "decimals": 0,
            "logoURI": "https://rxvwsth6k2wkjbpgqixi4db3ekvlailhvm2xa4pcmqzl6dtctqwa.arweave.net/jetpTP5WrKSF5oIujgw7IqqwIWerNXBx4mQyvw5inCw",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://neon.game"
            }
        },
        {
            "chainId": 101,
            "address": "CXNPQApndJ3Wo7bfu43GdA3y1fXuvYGLtodnbvUAJPSY",
            "symbol": "rakshpma",
            "name": "rakshpma",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXNPQApndJ3Wo7bfu43GdA3y1fXuvYGLtodnbvUAJPSY/logo.png",
            "tags": [
                "rakshpma-token"
            ]
        },
        {
            "chainId": 101,
            "address": "wrmAVPTbvznVe5LDbL7uRXZCppeyq3LNqacyKswtQqJ",
            "symbol": "WORM",
            "name": "WORM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SnootyRavens/logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6isPCSZf1ZQdwHMTuRMG9q4DZPKsjFog5o3HM4XCuCc9",
            "symbol": "vBOW",
            "name": "vBOW",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6isPCSZf1ZQdwHMTuRMG9q4DZPKsjFog5o3HM4XCuCc9/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/bobosofwar",
                "website": "https://bobosofwar.com/"
            }
        },
        {
            "chainId": 103,
            "address": "B4fAv9ru7oHX2HuQzTq8XDkwas3xwnokYa4kgzEQEpcm",
            "symbol": "DJIB",
            "name": "Djib",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B4fAv9ru7oHX2HuQzTq8XDkwas3xwnokYa4kgzEQEpcm/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Universal Data Storage Parachain",
                "discord": "https://discord.gg/PpZgKJkKpb",
                "github": "https://github.com/Djib-io",
                "imageUrl": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B4fAv9ru7oHX2HuQzTq8XDkwas3xwnokYa4kgzEQEpcm/logo.png",
                "telegram": "https://t.me/DjibTech",
                "twitter": "https://twitter.com/Djibchain",
                "website": "https://djib.io"
            }
        },
        {
            "chainId": 101,
            "address": "Betaza9WsCg2L2PBmqd8QnWBFkMwcxQjGK1rssY6HGWY",
            "symbol": "V1CE",
            "name": "Vice Club Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CyberDes1gner/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J2BWMK44sjgM2dApxMX4Xpst4jCJBBUvaBXj6AbXGdad",
            "symbol": "THC",
            "name": "Solana Treehouse Club",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solanatreehouseclub/token/main/FMiJoG6VgAI0xUc.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://t.co/RS56JPQAz3",
                "twitter": "https://twitter.com/TreehouseNFT"
            }
        },
        {
            "chainId": 101,
            "address": "Fq6fRLQ8LrwxtTER6om1wZsuzVjpGZrtL8Bjy3vzSJre",
            "symbol": "FOISY",
            "name": "Foisy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MichaelFoisy12/crypto/main/logo.png.JPG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6zPZniWEfM1KcuhEUAQCzrfgpkzmD8LD2zoyxo4biUG",
            "symbol": "OG",
            "name": "OG WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/virdagreat147/Pepe-army-Logo/main/purple-heart.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/goblinarmy",
                "twitter": "https://twitter.com/GoblinArmyNFT",
                "website": "https://goblinmetaverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "64ExnkDhpVwKzEjuqnkGgWVEtWZvWTG7JRyqQgzTWtFV",
            "symbol": "BFS",
            "name": "blockfilesystem",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64ExnkDhpVwKzEjuqnkGgWVEtWZvWTG7JRyqQgzTWtFV/logo.png",
            "tags": [
                "governance-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "description": "Create as many NFTs as you want from pictures, music, json file and more. Use BFS-Acces features to retrieve data and login to your accounts with NFTs.",
                "discord": "https://discord.gg/3cUwfJW8C4",
                "medium": "https://medium.com/@blockfilesystem",
                "telegram": "https://t.me/BlockFileSystem",
                "website": "https://www.blockfilesystem.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CedGzCs94g69Qjpe4KWWfhwQc5ViGx4oDB6K7daQdL28",
            "symbol": "rakeshsh",
            "name": "rakeshsh",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CedGzCs94g69Qjpe4KWWfhwQc5ViGx4oDB6K7daQdL28/logo.png",
            "tags": [
                "rakshpma-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4TzFbsSpDKfZCyaSHP92ktyEUiCZ5hGbGwNhWFbzs6rM",
            "symbol": "JC",
            "name": "JoyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/djTiTanZ/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3pVGSD3L4n35kcC6m6Z8rmG5j4q7Uq8NvEVPmc9CwrxZ",
            "symbol": "CRM",
            "name": "Crazy Monkeys Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crazymonkeysclub/CRM-LOGO/main/CRM_TOKEN_LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7WaSkvYLkqnsqzzsQC58J4w9tMusGNUHHHaUgGXsfMkK",
            "symbol": "24x7",
            "name": "24x7",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/24x7-Development/logo/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/9yUjFtcFqP"
            }
        },
        {
            "chainId": 101,
            "address": "BHT9o8W1FuG2wBRxbKVQoAyANo1pZPVkrpNWTdGaYwVP",
            "symbol": "PXL",
            "name": "PXL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BHT9o8W1FuG2wBRxbKVQoAyANo1pZPVkrpNWTdGaYwVP/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SenseiTrapLord",
                "website": "https://pixel.cryptotraplordzclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "RACEKYC7jTn98bEyG8xWFyMJZj9XDXHPfYRsCMoaM33",
            "symbol": "RACER",
            "name": "Racer",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RACEKYC7jTn98bEyG8xWFyMJZj9XDXHPfYRsCMoaM33/logo.png",
            "tags": [
                "currency",
                "Utility-Token",
                "NFT-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DraceNft"
            }
        },
        {
            "chainId": 101,
            "address": "JEBexgRE4xdNHeNAv7c3GCSC7wg3DUwXJybxUNEgiUzD",
            "symbol": "FLWT",
            "name": "Flowin Wallet LP Token",
            "decimals": 0,
            "logoURI": "https://somosflow.in/static/media/flowLine.25a310d8.png",
            "tags": [
                "permission-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7vTiyNxmJeWPQjWEChCHhrSZdewidiDWFdF4qy91ZthC",
            "symbol": "PTNS",
            "name": "Photon Share",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vTiyNxmJeWPQjWEChCHhrSZdewidiDWFdF4qy91ZthC/logo.png",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "website": "https://photondao.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9xvPQbGmUWwhhaRmersVKGvFCYTHQfkiuWgiuzv68YB4",
            "symbol": "GPC",
            "name": "Guinea Pigs Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9xvPQbGmUWwhhaRmersVKGvFCYTHQfkiuWgiuzv68YB4/gpctokenlogo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/8ZtD23V8Wf",
                "twitter": "https://twitter.com/GPCNFT"
            }
        },
        {
            "chainId": 101,
            "address": "24z5PixwQnuxFFXaokSEWZ7A97XhcC91HJuLzs9xShZ4",
            "symbol": "SLR",
            "name": "S0LAR",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/0verdr1v3/idkkkkk/main/test%20tt.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "J2X4VXBpwhR3rYvwHy6bDpMUiqro6Kjx4RZnMuRspRqJ",
            "symbol": "GOOM",
            "name": "Goomie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ki2tech/monty/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6L1A1VAPYURVpgacPawEpDJCRM5xxhL65LB1Qn45tjrp",
            "symbol": "KNT",
            "name": "Kelvin Network Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Kelv3200/knt/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3FFpD5G3Bt8V4hKnfhvxYxhxYYijsN9FN8oi727YZfNV",
            "symbol": "HOOFCB",
            "name": "HOOFCoin Community Builder ",
            "decimals": 6,
            "logoURI": "https://static.wixstatic.com/media/ade91d_bc0f989a468c48b091cccccf327bf745~mv2.png/v1/fill/w_242,h_242,al_c,q_85,usm_0.66_1.00_0.01/HOOF%20(33).webp",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.hoofit.io/theneighborhood"
            }
        },
        {
            "chainId": 101,
            "address": "BT3eJdbY48s8dhBqJ2YoiGxYNg69aT7RYncjQ6CyBxW5",
            "symbol": "MTX",
            "name": "MeteorX",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/gugunaga/meteor_token/main/assets/MeteorX.png",
            "tags": [
                "nft",
                "utility-token"
            ],
            "extensions": {
                "description": "MeteorX is utility token for Dinolana NFT. It is created for investment, gameplay, staking and trading purposes.",
                "discord": "https://discord.com/invite/J283ggNcAT",
                "twitter": "https://twitter.com/dinolana_sol",
                "website": "https://dinolana.world/"
            }
        },
        {
            "chainId": 101,
            "address": "2w8c62wDQXzTrtyzLzUBYLKQTrZSRus8c9ULoF339Jhq",
            "symbol": "V1CE",
            "name": "The Vice Club Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CyberDes1gner/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "68Dyv8edop8MeeAoBA4std17heYETVtmQpSRa5Bk5e3D",
            "symbol": "INT",
            "name": "Integer",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/chinchillajared/integer/main/int.png",
            "tags": [
                "social-token",
                "int",
                "integer"
            ]
        },
        {
            "chainId": 101,
            "address": "CB6T9KSCtLqPnuVA37g43tCWyic7q2MJLe1Cgi8PadtT",
            "symbol": "BULLION",
            "name": "Bullionaires",
            "decimals": 6,
            "logoURI": "https://github.com/K3AMZ/crypto/blob/main/5E34C72F-00EA-4EF1-AB5C-97301E139DA2_4_5005_c.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MHC4pzYWfctfF23WRYXfPTJZEoLp1bCt3KjpCjDcnek",
            "symbol": "MGHC",
            "name": "MG High Council Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MHC4pzYWfctfF23WRYXfPTJZEoLp1bCt3KjpCjDcnek/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/mahogenies",
                "website": "https://www.mahogenies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "MRCbdHyTNxHco5pUa2vTqUrJZb5TQf3foLaJTLc8WCX",
            "symbol": "MGR",
            "name": "MG Researcher Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MRCbdHyTNxHco5pUa2vTqUrJZb5TQf3foLaJTLc8WCX/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/mahogenies",
                "website": "https://www.mahogenies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3kiFobgbSxVtmbp5N2JwKkKpnjamwGRe12kgof8G4CBE",
            "symbol": "KRACK",
            "name": "CZoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Graemo27/crypto/main/czoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AQnSh5nv9dzm2AfPWLvASo9AA7TTbnnHFd9HboNane5n",
            "symbol": "$PIGG",
            "name": "Piggish Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ingdavidavila/cryptologo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MCL9utLXFvUMdWLou4jk13BBgTu2XRGM983eZ24RsTP",
            "symbol": "MC",
            "name": "Monkey Crown",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/namu42/ImageRepo/main/MCL9utLXFvUMdWLou4jk13BBgTu2XRGM983eZ24RsTP/logo.png",
            "tags": [
                "TMDC",
                "THEMONKEYDESIGNCLUB",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "EAYneKaMNLsf52xerf5Bq9Q7PDuLttieXj9jupbvgsEK",
            "symbol": "ROMP",
            "name": "RowdyRex Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAYneKaMNLsf52xerf5Bq9Q7PDuLttieXj9jupbvgsEK/logo.png",
            "tags": [
                "rowdyrex",
                "utlity-token"
            ],
            "extensions": {
                "website": "https://rowdyrex.com"
            }
        },
        {
            "chainId": 101,
            "address": "2xUnwVS4xMb8ZkWn1nvkrgbrDX5BC7dfETfQ5GwXStvv",
            "symbol": "Kalit",
            "name": "Tilak",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kalifather/Crypto/main/panda.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AmxkHjjbHVeBRM6CA4sTXVY8Zy8PybHosMFM9gnUpmnh",
            "symbol": "METG",
            "name": "Metg",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BiaDN/metgglogo/main/64.png",
            "tags": [
                "metg-token"
            ]
        },
        {
            "chainId": 101,
            "address": "osaRUfE6ccwNMWfdY8X29auM8W4LtyDzaEU4EAHKBhR",
            "symbol": "OSARU",
            "name": "OSARU Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/osaRUfE6ccwNMWfdY8X29auM8W4LtyDzaEU4EAHKBhR/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "OSARU is a utility token."
            }
        },
        {
            "chainId": 101,
            "address": "9pagADTDoxvV4nSNAcJkZ6tHPi9WW45vXeQQspULwmN4",
            "symbol": "$MINT",
            "name": "Dapper Ducks Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9pagADTDoxvV4nSNAcJkZ6tHPi9WW45vXeQQspULwmN4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Mint Token for DD Universe"
            }
        },
        {
            "chainId": 103,
            "address": "3Lb4NaecWe91oBw3WLWxKMQBvb4LsyHFnmbCt3LySHL5",
            "symbol": "ERT",
            "name": "Earth",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hariienesh1901/Earth/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CipcAcgVCPc7duEi633s7nbGvgb1PdJiTs136vrFfEjE",
            "symbol": "JUSOL",
            "name": "Juiced SOL Carton Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CipcAcgVCPc7duEi633s7nbGvgb1PdJiTs136vrFfEjE/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "Juiced SOL Carton Token",
                "discord": "https://discord.gg/ZXBRGXccR2",
                "instagram": "https://www.instagram.com/juicedfi",
                "linkedin": "https://www.linkedin.com/company/juicedfi",
                "medium": "https://www.medium.com/@juicedfinance",
                "twitter": "https://twitter.com/JuicedProtocol",
                "website": "https://www.juiced.fi",
                "whitepaper": "https://juiced.fi/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "2fRCnvb2Gcgz8MvDxK4R1EV8Ax2qN8MkdeDPWALL45Zy",
            "symbol": "JUBTC",
            "name": "Juiced BTC Carton Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2fRCnvb2Gcgz8MvDxK4R1EV8Ax2qN8MkdeDPWALL45Zy/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "Juiced BTC Carton Token",
                "discord": "https://discord.gg/ZXBRGXccR2",
                "instagram": "https://www.instagram.com/juicedfi",
                "linkedin": "https://www.linkedin.com/company/juicedfi",
                "medium": "https://www.medium.com/@juicedfinance",
                "twitter": "https://twitter.com/JuicedProtocol",
                "website": "https://www.juiced.fi",
                "whitepaper": "https://juiced.fi/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "BP3H2mTnkT4yPSR1z8RTf2VTQ192FXvvfXPsGRQBGcR6",
            "symbol": "Soul",
            "name": "SolNode",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolNodeAdmin/Crypto/main/lollipop.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Soul is a utility token."
            }
        },
        {
            "chainId": 103,
            "address": "C9Gbj8yfWF82D5op3JWw3eW5147fzQ7jUnBisZXerzUE",
            "symbol": "ALPHAX",
            "name": "Alphax",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9Gbj8yfWF82D5op3JWw3eW5147fzQ7jUnBisZXerzUE/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "Your3JebQKoJHUPWNJ2LJLT6rHkLbPEq8FVGzFcs23q",
            "symbol": "YOUR",
            "name": "Your",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Your3JebQKoJHUPWNJ2LJLT6rHkLbPEq8FVGzFcs23q/logo.svg",
            "extensions": {
                "discord": "https://discord.gg/8deTbfAsdW",
                "github": "https://github.com/your-network",
                "medium": "https://medium.com/@yourofficial",
                "telegram": "https://t.me/your_community",
                "twitter": "https://twitter.com/yourtokens",
                "website": "https://www.yournetwork.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4utgA7JVGbpazbZcTA4H1pN3ZCTmRxmGyuphe1BKs66S",
            "symbol": "Fleece",
            "name": "Fleece",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dispnft/token-list/main/assets/mainnet/4utgA7JVGbpazbZcTA4H1pN3ZCTmRxmGyuphe1BKs66S/logo.png",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "description": "A Game Token"
            }
        },
        {
            "chainId": 101,
            "address": "8ywCnyNNZDPMdc7nWPdMn1qASxaMJh6MuRPV4GMCUDLW",
            "symbol": "COGS",
            "name": "Cogi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mateo-cogeanu/cogs/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GEMz5TfaV54E6wmGJ1wtArysP99U5pftMExgeCZ6hwoo",
            "symbol": "GEMZ",
            "name": "LDAC Gemz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEMz5TfaV54E6wmGJ1wtArysP99U5pftMExgeCZ6hwoo/logo.png",
            "tags": [
                "utility-token",
                "game-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/yhbUPsZs7r",
                "twitter": "https://twitter.com/lildegenapeclub",
                "website": "https://www.lildegenapeclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "J1TN1Q7AXxvfg9VYVJg2CeHFfWqtuUUqDL6vpRRMNT4M",
            "symbol": "AAA",
            "name": "Alien Art Academy WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LUXiTyV4h7rXpgvAYSAVPa2TPB3cjABvdenkWTGQEZa",
            "symbol": "LUXWL",
            "name": "Luxnode WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUXiTyV4h7rXpgvAYSAVPa2TPB3cjABvdenkWTGQEZa/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LuxnodeNFT"
            }
        },
        {
            "chainId": 101,
            "address": "EKDrve7QKF2iz6tr9J6Zy5LmNN8biT2uQZ3rg9zoE6Cz",
            "symbol": "KOMX",
            "name": "Kompas X - Stakeholder Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mtorpdev/cryptoX/main/KompasX-Silver-Coin.png",
            "tags": [
                "investment-token"
            ],
            "extensions": {
                "description": "A token for investment in Kompas X"
            }
        },
        {
            "chainId": 101,
            "address": "3CFHkKtuep49nYndy37LXoQGmLgrKjuD9Q13jEpw6mMH",
            "symbol": "RECO",
            "name": "REE COIN",
            "decimals": 0,
            "logoURI": "https://github.com/seankyusunlee/ReeCrypto/blob/main/RcoinLogo1.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8852hjJiX4JMF7C45KyrypDTJiE7zG9GP3S3o7sweL8e",
            "symbol": "VDK",
            "name": "Vodka",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/BlackTrickst3r/VodkaToken/main/VTokenFinal.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WY5BZWQbYu",
                "twitter": "https://twitter.com/RussianPunkz",
                "website": "https://russianpunkz.net/"
            }
        },
        {
            "chainId": 102,
            "address": "CE2GVFioduYefyV2xzbihpH7WZRyZ8om2i8xYWhP2hmT",
            "symbol": "KEN",
            "name": "KEN COIN",
            "decimals": 0,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmSP9dLN9fvTvoBAf4ao3nSRz2bVhXYUct6r663GHFUpU3",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "description": "Ken's Test Token"
            }
        },
        {
            "chainId": 101,
            "address": "7K4WWxp4onH8N7Abw5WKSfpHUmyFiK27K8z65eXwhuV7",
            "symbol": "IDI",
            "name": "GO FUCK YOURSELF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7K4WWxp4onH8N7Abw5WKSfpHUmyFiK27K8z65eXwhuV7/logo.png",
            "tags": [
                "social-token",
                "war-token",
                "ukraine"
            ],
            "extensions": {
                "description": "Send these tokens to all Russian invaders",
                "facebook": "https://www.facebook.com/UkraineUA.MFA",
                "reddit": "https://www.reddit.com/r/ukraine/",
                "telegram": "https://t.me/mintsyfra",
                "twitter": "https://twitter.com/Ukraine"
            }
        },
        {
            "chainId": 101,
            "address": "HjLtBjuh1vMd3N1Hsi2V6akvoziTMrbpnoWnkyXpb9tA",
            "symbol": "GUNWL2",
            "name": "v2CryptoStrapsWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HjLtBjuh1vMd3N1Hsi2V6akvoziTMrbpnoWnkyXpb9tA/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4ncSzoxBDMRPFy8Mx7QsGRtGNYey4uQc8Ydfg9ErRz3X",
            "symbol": "YLKT",
            "name": "YOLK TOKEN",
            "decimals": 8,
            "logoURI": "https://user-images.githubusercontent.com/98852228/156415259-09e7d785-8ee4-4398-a906-f8f211a59d15.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "The Official Token For The Egg Heads Club"
            }
        },
        {
            "chainId": 101,
            "address": "B4eowLak7eJPo3XStE35EqEN7QU4L66PBJ2Jzgf1PMgB",
            "symbol": "FUEGO",
            "name": "FuegoAmericano",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/CarlosGyael/FuegoAmericano/main/FuegoToken.png",
            "tags": [
                "Currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/QmxAAzxg",
                "instagram": "https://www.instagram.com/fuegoamericano/",
                "telegram": "https://t.me/fuegoamericano",
                "twitter": "https://twitter.com/FuegoAmericano",
                "website": "https://thefuegoamericanollc.com/"
            }
        },
        {
            "chainId": 103,
            "address": "CE2GVFioduYefyV2xzbihpH7WZRyZ8om2i8xYWhP2hmT",
            "symbol": "KEN",
            "name": "KEN COIN",
            "decimals": 0,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmSP9dLN9fvTvoBAf4ao3nSRz2bVhXYUct6r663GHFUpU3",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "description": "Ken's Test Token on DevNet"
            }
        },
        {
            "chainId": 101,
            "address": "AAoJ5eYd61QsUBRBxjCcAdsQZpQYxrob2wS4Hzoaeoas",
            "symbol": "SNAP",
            "name": "SNAPSHOTS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ZvsK4/snapshotslogo/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "FBXoa1cW6h4QYaM15dXjmDQXqjsg3ZqqsZr1qAJNESQS",
            "symbol": "KBWL",
            "name": "Kurai Bats WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBXoa1cW6h4QYaM15dXjmDQXqjsg3ZqqsZr1qAJNESQS/logo.png",
            "tags": [
                "White-List",
                "Utility-Token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "GGWL1v6aRnjRFAxi7j2ArQBDRxb1maZz4XGqMsxkdYbt",
            "symbol": "GGWL1",
            "name": "Glitched Gorilla WL Token 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGWL1v6aRnjRFAxi7j2ArQBDRxb1maZz4XGqMsxkdYbt/GGToken.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WtpzxsBTGF",
                "twitter": "https://twitter.com/MH_Multiverse",
                "website": "https://milehighmultiverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "7Q9YbR4jPPaDsWsEngubW2z9PGfmWK7xn7AeewMm3qbT",
            "symbol": "DLITE",
            "name": "Dino Lite",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Q9YbR4jPPaDsWsEngubW2z9PGfmWK7xn7AeewMm3qbT/Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dinolabs",
                "twitter": "https://twitter.com/DinoLabsNFT",
                "website": "https://dinolabs.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "judTED8D9sPVX87UUajnDh2bwdG9MDFUVPzJdGnFYkS",
            "symbol": "JU",
            "name": "JU",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/judTED8D9sPVX87UUajnDh2bwdG9MDFUVPzJdGnFYkS/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "staking",
                "staking-reward",
                "Gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/jujudevils",
                "instagram": "https://www.instagram.com/jujudevils",
                "medium": "https://medium.com/@jujudevils",
                "twitter": "https://twitter.com/jujudevilsarmy",
                "website": "https://jujudevils.com"
            }
        },
        {
            "chainId": 101,
            "address": "EDubeD9UZYFvbGLq8w9JF5o9AaT5WzY9W6CjcrtYvDaw",
            "symbol": "ClaBo",
            "name": "Cla_Bo",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/collectivtwo/logo/main/alteredlogo.png",
            "extensions": {
                "twitter": "https://twitter.com/ClaToken"
            }
        },
        {
            "chainId": 101,
            "address": "EKrEmPnaNzgGixE61ERsTNn5oMoMqACJf3GniFpgGPbp",
            "symbol": "SKELLY",
            "name": "Skelly Gang Utillity Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKrEmPnaNzgGixE61ERsTNn5oMoMqACJf3GniFpgGPbp/SkellyCoin.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "The skelly gang utility token earned through staking your skelly gang NFTs.",
                "discord": "https://discord.gg/3T3jVtac",
                "twitter": "https://twitter.com/SkellyGangNFT",
                "website": "https://skellygang.com"
            }
        },
        {
            "chainId": 101,
            "address": "14HfP7zWiNdUPiE6CvyUPC9YWmSc7UsmaqiWcpkcBVFg",
            "symbol": "DVI",
            "name": "Deep Value Investments",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/DeepValueInvestments/DVI-Token/main/Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Deep Value Investments (DVI) is developing a multi-asset digital marketplace built from the ground up for Cryptocurrency, NFTs, Equities, Fixed Income, Commodities, and FOREX. DVI is a first-to-market solution delivering a singular platform designed to encompass all cryptocurrency and investment needs for retail investors, institutional prime services (SaaS, TAMP, Operations, etc.), and RIA SaaS offerings."
            }
        },
        {
            "chainId": 101,
            "address": "5XFJAEyKtGUruRnjwzKZzDVwjzyyBtLw5TUhyZKyqUbk",
            "symbol": "PNH",
            "name": "Putin Khuylo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5XFJAEyKtGUruRnjwzKZzDVwjzyyBtLw5TUhyZKyqUbk/logo.png",
            "tags": [
                "social-token",
                "war-token",
                "ukraine"
            ],
            "extensions": {
                "description": "Send these tokens to all Russian invaders",
                "facebook": "https://www.facebook.com/UkraineUA.MFA",
                "reddit": "https://www.reddit.com/r/ukraine/",
                "telegram": "https://t.me/mintsyfra",
                "twitter": "https://twitter.com/Ukraine"
            }
        },
        {
            "chainId": 101,
            "address": "8kWgPei6KkkcnoDZGtXGXxAvUkNmug7qbeNnm2zx3xgp",
            "symbol": "METAL",
            "name": "Metaversol Loyalty Token",
            "decimals": 0,
            "logoURI": "https://metaversol.com/coinlogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6fxKAgb4CYUacmcQueCcjUDrV9R3B2HMSwjxgmk5BaAa",
            "symbol": "SOLCOU",
            "name": "SOLautos Coupon",
            "decimals": 0,
            "logoURI": "https://solautos.io/images/SolLogo-768x484.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GtMieQ5MpEqKWEJEWjp4HbGxDnYCRAps3W8tQ55YVRyj",
            "symbol": "SAWL",
            "name": "SOLautos Whitelist",
            "decimals": 0,
            "logoURI": "https://solautos.io/images/SolLogo-768x484.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AWWL88eXNREJaT1KFvdTWmGnVSSXqxjbYhFUjJ978eFn",
            "symbol": "AWWL",
            "name": "Awful Waffles Presale Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWWL88eXNREJaT1KFvdTWmGnVSSXqxjbYhFUjJ978eFn/awwl.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/w3hMaaPvtG",
                "twitter": "https://twitter.com/awfulwafflesnft",
                "website": "https://awfulwaffles.io"
            }
        },
        {
            "chainId": 101,
            "address": "aEvgNv5DVznzHrmJaqDHVuExH2nbLCGbyfhsNfgCYBS",
            "symbol": "EXT",
            "name": "ErosXT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aEvgNv5DVznzHrmJaqDHVuExH2nbLCGbyfhsNfgCYBS/logo.png",
            "tags": [
                "utility-token",
                "game-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NH6jx7JsKE",
                "github": "https://github.com/eroticissima",
                "twitter": "https://twitter.com/eroticissima"
            }
        },
        {
            "chainId": 101,
            "address": "4Juifs2fUxN1h2FM8S9HZRsqyQ2qkLXcE9V9xTvKs2GA",
            "symbol": "KIBB",
            "name": "Kibble",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Jonathanriddle/Kibble/main/Lily%201.jpg",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "Rescue-Dogs"
            ]
        },
        {
            "chainId": 101,
            "address": "BTCA1FcBrsmLJLQVNUHwVkmC2PywfK2Qsw3Lb1kFMqGz",
            "symbol": "BTCA1",
            "name": "Gold Bitcoin Accumulator",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTCA1FcBrsmLJLQVNUHwVkmC2PywfK2Qsw3Lb1kFMqGz/logo_BTCA1.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/player2",
                "github": "https://github.com/player2world",
                "reddit": "https://www.reddit.com/r/player2world",
                "twitter": "https://twitter.com/player2world"
            }
        },
        {
            "chainId": 101,
            "address": "BTCA2PAfYbqPyeRjMPQdUx6c9yTxQhQL6StdfQjmxmjh",
            "symbol": "BTCA2",
            "name": "Silver Bitcoin Accumulator",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTCA2PAfYbqPyeRjMPQdUx6c9yTxQhQL6StdfQjmxmjh/logo_BTCA2.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/player2",
                "github": "https://github.com/player2world",
                "reddit": "https://www.reddit.com/r/player2world",
                "twitter": "https://twitter.com/player2world"
            }
        },
        {
            "chainId": 101,
            "address": "BTCA3ZWcWJjW6qcijPSCePZ7WPCgrYm8KJJNVZJzqHd6",
            "symbol": "BTCA3",
            "name": "Bronze Bitcoin Accumulator",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTCA3ZWcWJjW6qcijPSCePZ7WPCgrYm8KJJNVZJzqHd6/logo_BTCA3.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/player2",
                "github": "https://github.com/player2world",
                "reddit": "https://www.reddit.com/r/player2world",
                "twitter": "https://twitter.com/player2world"
            }
        },
        {
            "chainId": 101,
            "address": "FuMuxd5bcgk8E1xdxawApC8EVEnN5WTTXAhkmHW5pFM9",
            "symbol": "MMMC",
            "name": "Money Made Magnificent Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ahzu-Direct-Tech/mmmc/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F6X6hs6whSrTPTuvgdtEfG2B1LvsWWQ4qSDyii3ntuQ7",
            "symbol": "JJT",
            "name": "Jo Jo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CREpto90/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Avc1X8iAWLVrsnRtDK6aTyPDichkqe8YAn6ePGTuPAKH",
            "symbol": "BREAD",
            "name": "BREAD Token",
            "decimals": 9,
            "logoURI": "https://arweave.net/czwVBNRqXrCthS1R-XH_AoJWPqwDj7DiCxv5tNnyxrQ",
            "tags": [
                "DunkinDucksToken"
            ]
        },
        {
            "chainId": 101,
            "address": "4WRkRD4Pom8bFyhxyoCJtykaXoXZh2WX2KP9KGx6qy7a",
            "symbol": "RARECOIN",
            "name": "RareCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Player172/RareCoin-Logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FCJ3e3aEXVpoeua6m6oLyB5J2TCgn1Ldc2WbHudC4S75",
            "symbol": "$TN1",
            "name": "TRUST NO 1 TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/TRUST-NO-1-TOKEN/main/logo.png",
            "tags": [
                "NFT",
                "SOLANA"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/8zzXHaEZF2",
                "instagram": "https://www.instagram.com/trustno1_nft",
                "twitter": "https://twitter.com/trustno1_nft",
                "website": "https://trustno1.onuniverse.com"
            }
        },
        {
            "chainId": 101,
            "address": "apnggFw6CdVzxjdVC3KbfT6qVYfNi4VgQBuW7hVM9us",
            "symbol": "APN",
            "name": "APN",
            "decimals": 9,
            "logoURI": "https://static.apn.gg/token/apn-signet.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/D2ktsbMd6d",
                "twitter": "https://twitter.com/apngg_",
                "website": "https://apn.gg"
            }
        },
        {
            "chainId": 101,
            "address": "AdAPHuuPvidvcXEoYC4MqwHX2zQHRrHrggQwfgDCTHQQ",
            "symbol": "PAELLA",
            "name": "Paella Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ESR20/Crypto/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "AMzmwvDRKdt5AQ3m1m28tWjzBxmQNe1PsmHnYitVZwzp",
            "symbol": "JUNKz",
            "name": "JUNK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/XAGBack/JUNKToken1/main/JunkToken.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CtvD16P3aziZZaamHEyLHVnFN7UpXD9rcziShg2JAuQJ",
            "symbol": "CHIQUITA",
            "name": "CHIQUITA Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/HonkugaToken/main/honkuga.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.com/3DxXbqHeBH",
                "twitter": "https://twitter.com/HonkugaApes"
            }
        },
        {
            "chainId": 101,
            "address": "ETsqjC4pUFPBAbjNxHExc1soRodVhCv3sFE3PT19t8S3",
            "symbol": "KZN",
            "name": "KZN Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/kuzuryutoken/main/kuzuryu.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/APBqNFXNJB",
                "twitter": "https://twitter.com/KuzuryuNinja"
            }
        },
        {
            "chainId": 101,
            "address": "DBtvzWi1UVyojuwDkKc1a4s56jLquJdZzwgunMYbq3xc",
            "symbol": "alen",
            "name": "Alinea",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sumpter32/Cryto/main/alinea.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7MJctMEzbs4VCN7Phu5d2rdg2qAN8cFDCMSR5pXnEAJu",
            "symbol": "MINDSET",
            "name": "MindSet",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/thesummitoftheworld/solana.mindset_token/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "49uThnJWEoBjqYZaVXd6L9KKBR288Z1oXoLH7SUFyBJT",
            "symbol": "UNMT",
            "name": "UNMUTEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/49uThnJWEoBjqYZaVXd6L9KKBR288Z1oXoLH7SUFyBJT/logo.png",
            "tags": [
                "UNMUTEX"
            ]
        },
        {
            "chainId": 101,
            "address": "Frag4ZMMivXrM4YAufTA8z5NpsrTNcXFNSDmz1FhrY43",
            "symbol": "FRAG",
            "name": "TA Fragments",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tavernadventures/token-logo/8eb01cf46ad02ab584f9190db5a1d432b677a98f/img/frag.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tavernadventure"
            }
        },
        {
            "chainId": 101,
            "address": "5JYKZTxKqAaT6ADyCyD2zdyihg4xB6TQ2DSAKh9DKn1m",
            "symbol": "BCEI",
            "name": "Blockchain expo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5JYKZTxKqAaT6ADyCyD2zdyihg4xB6TQ2DSAKh9DKn1m/logo.png",
            "tags": [
                "BCEI"
            ],
            "extensions": {
                "website": "http://www.blockchainexpoindia.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FHeU7e7Tyw5bNcCNiM1jNVoT5UUims7zRi3o76Kdz4f6",
            "symbol": "VEGAS",
            "name": "Vegas Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FHeU7e7Tyw5bNcCNiM1jNVoT5UUims7zRi3o76Kdz4f6/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GPHNiEkx7bWW1ZQWddqqevJ7X5fpGX1LcTwoAdC1NHKg",
            "symbol": "DVL",
            "name": "Divina Lux",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ahzu-Direct-Tech/mmmc/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE",
            "symbol": "YAW",
            "name": "Yawww",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE/yaw.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/YawwwNFT",
                "website": "https://www.yawww.io"
            }
        },
        {
            "chainId": 103,
            "address": "EEGG1MLcWwBQVNwmhoNxMdTjVuuRX24o7Gym2MzQmRST",
            "symbol": "READ",
            "name": "ReadON Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EEGG1MLcWwBQVNwmhoNxMdTjVuuRX24o7Gym2MzQmRST/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://readon.me"
            }
        },
        {
            "chainId": 101,
            "address": "v22HTG593HPztGA9gKqEw1KRJVFUECk61Xg2LnugJ9M",
            "symbol": "VLW",
            "name": "Valley22 Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Justin-hw-Tseng/assets/main/logo.png",
            "tags": [
                "social-token",
                "member-token"
            ]
        },
        {
            "chainId": 101,
            "address": "og8BnQWDNP1fbn7cGuXkmybmBUxfMpEg4EJK4HT6h6m",
            "symbol": "MBCPT",
            "name": "MBC Premium Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/og8BnQWDNP1fbn7cGuXkmybmBUxfMpEg4EJK4HT6h6m/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLTqMPniYji1c991Qy867DyLWNpP61mjn1VLw22JnLr",
            "symbol": "MBCWT",
            "name": "MBC Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLTqMPniYji1c991Qy867DyLWNpP61mjn1VLw22JnLr/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7FntsntzGjK9PzPBbHLDJAFcKQVU14d2SbQZhgMUf2KA",
            "symbol": "TOCO",
            "name": "TownCoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7FntsntzGjK9PzPBbHLDJAFcKQVU14d2SbQZhgMUf2KA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://solanatown.com"
            }
        },
        {
            "chainId": 101,
            "address": "DMC8y7kpeBYfkbM3MmLREKeSGnw1sdWSv68aDUfH97Bu",
            "symbol": "DMC",
            "name": "Degen Moon Council Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMC8y7kpeBYfkbM3MmLREKeSGnw1sdWSv68aDUfH97Bu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.degenmooncouncil.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "RRf92wqDcax85HH6YbRpPJV7Gj1qVAeEqiNouY4mRWw",
            "symbol": "RR-FREE",
            "name": "Rave Review Free Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RRf92wqDcax85HH6YbRpPJV7Gj1qVAeEqiNouY4mRWw/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LFGqoEMMPUDkeWHRHeZucsiAtgudgDw9DaLjvxXMEmn",
            "symbol": "LFGOLD",
            "name": "24K Gorilla Club Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LFGqoEMMPUDkeWHRHeZucsiAtgudgDw9DaLjvxXMEmn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.24kgorillaclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EZjzDwkNYWhpprD1thHaGZvJDh8U5WJV86JX9erS6ovD",
            "symbol": "XSD",
            "name": "Sealand Dollar",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EZjzDwkNYWhpprD1thHaGZvJDh8U5WJV86JX9erS6ovD/logo.png"
        },
        {
            "chainId": 101,
            "address": "3nP1SUoA9XWL5qDam7BKWd8QnLtRHDtfZuSmwgfTnBe2",
            "symbol": "TGRT",
            "name": "Research Token",
            "decimals": 0,
            "logoURI": "https://metaversol.com/research.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Research token used to power and perform research in the Test Guys Lab",
                "discord": "https://discord.gg/metaversol",
                "twitter": "https://twitter.com/MxMetaversol",
                "website": "https://testguys.fail/"
            }
        },
        {
            "chainId": 101,
            "address": "RRpPtGKTBRuawbvqGqAPPtu81Gmhhg4LZww4BDarXZv",
            "symbol": "RR-PRE",
            "name": "Rave Review Pre Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RRpPtGKTBRuawbvqGqAPPtu81Gmhhg4LZww4BDarXZv/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4P4jjC6KjwVTCxhpfj4pQGBC1ohUaNin8QaWwPyge9ML",
            "symbol": "SHEEP",
            "name": "Solana Cyclopes",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/cyclopes.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5YU1m4qUFrTnedrM29BDmBD8cmAkrVSqiRPKbfhPvFRt",
            "symbol": "VERU",
            "name": "Daveru",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ktabong/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EmXeQPjrZf99SnGfd8oSFr6VeVWJtU7FcxJmzfr1cMzX",
            "symbol": "TTc",
            "name": "TTcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xTTLegend/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3paMrtwA8pEspcKYHNrWNjxG36oL9BTTG7JKNwYUud4i",
            "symbol": "DV",
            "name": "Divina-Lux",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ahzu-Direct-Tech/mmmc/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7ZoFUd5CkcU4fQnhvpAYWSPV7wkHtxKm2Q9R5W733CKd",
            "symbol": "FLG",
            "name": "FLY GUYS MINT PASS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ZoFUd5CkcU4fQnhvpAYWSPV7wkHtxKm2Q9R5W733CKd/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.flyguys.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "HTy2Go2xA5BtGrnAyoVUPHsLyUKnLXkqucuAYYvQoLS6",
            "symbol": "Byte",
            "name": "Byte Buddies Chip",
            "decimals": 9,
            "logoURI": "https://media.discordapp.net/attachments/949172481690861598/949267442562113586/token_logo_Cropped.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/qRw9yN4qFr",
                "twitter": "https://twitter.com/bytebuddiesnft"
            }
        },
        {
            "chainId": 101,
            "address": "rwLH899ZJpjdWb2si3213HMHSvH4yMJdCj6Ne3HrkN4",
            "symbol": "ATRWL",
            "name": "OG Atadian RWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KKQanT/token-list/main/assets/mainnet/rwLH899ZJpjdWb2si3213HMHSvH4yMJdCj6Ne3HrkN4/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://www.atadia.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9dEi5k9tT8RNKuYhGbskKBoPxK8mwGAufqCDXGbw6EHp",
            "symbol": "GNC",
            "name": "Green coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kostasbekis/green-coin/main/GCN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F1utinCFGALViGVnFmACcmCRooV4PLTcUCc5gL2HExu8",
            "symbol": "HAY",
            "name": "Hay Bale",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F1utinCFGALViGVnFmACcmCRooV4PLTcUCc5gL2HExu8/logo.png"
        },
        {
            "chainId": 101,
            "address": "6BxLMs9ggwKUU7c6VUJ9WWH5o7q8UyvZbXMNFg7ivgMq",
            "symbol": "FDST",
            "name": "FoodStamp",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FoodStampCoins/Stamps/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "THCBB791xLFhRhxPHMDVN9m5KDeBBjwh6dfBbnTuPe9",
            "symbol": "THC",
            "name": "Club 420 Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/THCBB791xLFhRhxPHMDVN9m5KDeBBjwh6dfBbnTuPe9/Logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "nft-staking"
            ],
            "extensions": {
                "discord": "https://discord.gg/PzJpxNkHNf",
                "twitter": "https://twitter.com/Club420_NFT",
                "website": "https://club420nft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5JNEb6GzBY1VrfXCugU5bBQ5mEo9G7g3ySnE6cMzZSGC",
            "symbol": "$BUD",
            "name": "BUD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5JNEb6GzBY1VrfXCugU5bBQ5mEo9G7g3ySnE6cMzZSGC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/krypto-kronikz",
                "instagram": "https://www.instagram.com/kryptokronikz",
                "twitter": "https://twitter.com/KryptoKronikz",
                "website": "https://www.kryptokronikz.co.uk/"
            }
        },
        {
            "chainId": 101,
            "address": "9Cn5bRH8KaCpk91zZyLQDF6AFkp6ycZyP7TDMDWps1uc",
            "symbol": "MEDIBLOCK",
            "name": "MedicalBlockchain",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WHug0/crypto/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "646YeDRsCEnRNYoDa5hLogGroBcCrtc3wwEUwQEAv73X",
            "symbol": "SLICE",
            "name": "Benevolent Order of The Slice",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rajyyan/Slice/main/logo.png",
            "tags": [
                "membership-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9Cn5bRH8KaCpk91zZyLQDF6AFkp6ycZyP7TDMDWps1uc",
            "symbol": "MEDIBLOCK",
            "name": "MedicalBlockchain",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WHug0/crypto/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Q1qB6pJCksvBZFwwMssD4P6yt67o6SPJhnE6KCjzmJr",
            "symbol": "UFC",
            "name": "UFuture Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danielmahech/crypto1/main/poi-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fdju8iLbXXiM9QFz7xSZudtwUQuKHC1ecEQDNTeHPFBk",
            "symbol": "SPCE",
            "name": "Space Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/azvaliev/token-icons/master/3dQIR2cTzqBPcbvf1d2a.jpg",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6EYX7x2SMbvnUEK18XXzdSeVxeH1yMdh7yBsnSvKkjCD",
            "symbol": "FUTR",
            "name": "Future Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/azvaliev/token-icons/master/IYC9z9eDvBthkfOIVPMI.jpg",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GJUQB41W77dHbK1JU7oEP7UBP4gmj8uPSMfao8UGn2hu",
            "symbol": "MAEC",
            "name": "Meta Ape Elite Club WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJUQB41W77dHbK1JU7oEP7UBP4gmj8uPSMfao8UGn2hu/logo.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/2nxeZFf7Jw",
                "twitter": "https://twitter.com/MetaApeEC",
                "website": "https://metaapeeliteclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DJTMxBZDpjFv27o4QzTSkSBb2TZPFTsAnXaAWPrJept6",
            "symbol": "DJWLT",
            "name": "Dino Jump WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJTE14SD24SKmPv6pLt84173srwbBgt7YGyfHZ5oVhwp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GWLtBqEcnSsPWS8Sj79BJETGoEAGbCZKRy9LQbsCveLJ",
            "symbol": "GPWL",
            "name": "GatePass Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWLtBqEcnSsPWS8Sj79BJETGoEAGbCZKRy9LQbsCveLJ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "obtF8YwHn2g7uhmtziqVn4wvD2r99e3DPcQo4rFaN1G",
            "symbol": "OCXWL",
            "name": "Oblivion City X Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/obtF8YwHn2g7uhmtziqVn4wvD2r99e3DPcQo4rFaN1G/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CfNkADyvwg66yLtctbuDo381s6VqVC8583ur7uvooE43",
            "symbol": "HB",
            "name": "Honey Badges Presale Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CfNkADyvwg66yLtctbuDo381s6VqVC8583ur7uvooE43/logo.png",
            "tags": [
                "presale-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HoneyBadgesNFT",
                "website": "https://www.honeybadges.xyz/"
            }
        },
        {
            "chainId": 103,
            "address": "4XZhhTtMQ2SXmBSKucQ28PtseRtwBGpc4AVej37kuTTv",
            "symbol": "fDOGE_d",
            "name": "Synthetic Doge (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/12cd1cf4-f68a-4b32-355e-b40053725300/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "BWc6VuazzHxJ8yRrWocjLZfKjfEkMEr8grYMhT8jLQWK",
            "symbol": "fEUR_d",
            "name": "Synthetic Euro (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/1ed84129-6754-4668-7e4b-2cf143f5a100/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "FK12Vfus9VH4kasYe7d7R6syEcuTbS8JCJtk9ZmesT5q",
            "symbol": "fGBP_d",
            "name": "Synthetic GB Pound (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/b00332ce-5694-4d33-09fc-d523b63bbe00/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "BwhbNiZRwTKB7rz6n95SUQcMwkFYbb9cx2h6FPDHstwU",
            "symbol": "fBTC_d",
            "name": "Synthetic Bitcoin (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/ec84365d-c9a9-45b6-afeb-4ca5ca831600/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "A6QvgEnaFf2ftq35M5kztaxRufpeiryWuPXEXM71AjmA",
            "symbol": "fADA_d",
            "name": "Synthetic Cardano (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/a6d47d98-ea35-47ec-cf43-eb95ac94d500/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "J9nzLNpbc8vfvyV45RDAgjH2LkYH1KnegGfDXKmKbdMT",
            "symbol": "fAVAX_d",
            "name": "Synthetic Avax (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/aa894435-4e95-4af0-e4ff-5faefbc65b00/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "4qr9SiYyi4E3k6DT9ycD6CQh8m5E82gNkoUWNvTxZefj",
            "symbol": "fBCH_d",
            "name": "Synthetic Bitcoin Cash (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/17e71390-758e-4355-bae1-5b0fb6afed00/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "FgsHQ2TdFokDuEVGYdhkrwoxD1Y8bg5HcTRCmD7KEpgb",
            "symbol": "fFTM_d",
            "name": "Synthetic Fantom (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/875a80a0-4ae6-4d76-bc8b-3f2eee6b5b00/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "FE9KLJTUyd2jSnpKRmnxoJB2CQhTFLTmhc8D5Szdpxue",
            "symbol": "fLUNA_d",
            "name": "Synthetic Luna (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/8bf381db-f096-4d6f-3397-e9224a012c00/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "r7KZ6SgMCPeA6MU7bjtxgV1xageYcstYzmw4Ed2UQZz",
            "symbol": "fNEAR_d",
            "name": "Synthetic Near (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/86dbb0ed-4918-4495-ded2-b8ed1ee2d000/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 103,
            "address": "8Pb8DVqFpHZjs9gr3C6EaALRaLihsMaFjNFYnHcuBYAN",
            "symbol": "fRUB_d",
            "name": "Synthetic Ruble (devnet)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/2fc3c060-058b-40f7-d0c4-f0cda2028a00/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4MG83Q5NfZp7tecbaURs3zS5LbXjAXhwTHffrv49xLGX",
            "symbol": "MsT",
            "name": "Ms Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/MeherTrident/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8",
            "symbol": "WAVE",
            "name": "Lost At Sea WAVE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "nft-staking"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lostatseanft",
                "website": "https://lostatseanft.com/"
            }
        },
        {
            "chainId": 103,
            "address": "zYLhfZpn6zLF1nGkq9A6kr2ufLeEnUEJ25um4zFijDr",
            "symbol": "GENIE",
            "name": "Genieverse",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Genieverse/crypto/main/logo.png",
            "tags": [
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5tbRhXYgsNKZG9E2Z4PJfcbQpoxabpVYmaqRY1XmTkJL",
            "symbol": "VLTK",
            "name": "VeLeTok",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/veletech/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DSqR1btEch7YGmpFXhwuboRV5QNuqrrSCnPTLjfnrdCS",
            "symbol": "ANU",
            "name": "Anime-U",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DSqR1btEch7YGmpFXhwuboRV5QNuqrrSCnPTLjfnrdCS/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/EliteWorldMetaverse",
                "telegram": "https://t.me/EliteWorldMetaverse",
                "twitter": "https://twitter.com/EliteWorldMeta"
            }
        },
        {
            "chainId": 101,
            "address": "3aTpYgEDSUgX2VLSPm2z2CE5zrhtWZ9jtXsCs7MXsP5z",
            "symbol": "RKT",
            "name": "Rocket",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/rkt-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6S4fgP6UpWwuDg1S4FyV3BSoxoECm9nZuGLLxwaSQVDu",
            "symbol": "MIN",
            "name": "Minute",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/min-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7xYwLhYFDPNNHRkWBwZgUZrrLdzUuxZYkbBjyBzTCZFB",
            "symbol": "HR",
            "name": "Hour",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/hr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8a5GgBPUnZNv546q8DBVhz7zSRC35scHR44mKRiq8Hs8",
            "symbol": "DAY",
            "name": "Day",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/day-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9xSqFFHH8kq928NuvLNBLntd9LfCW9NwhZ94wcD4dedp",
            "symbol": "WK",
            "name": "Week",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/wk-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JC5TC9CJNvf9DQiCCTP236obnipzRhVo5LH5QYrXiYFm",
            "symbol": "MO",
            "name": "Month",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/mo-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "tvsmkrUKwW952YZHqDPMj9at39XY1nDXi6bF5JcAmaa",
            "symbol": "YR",
            "name": "Year",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FullMoonMiningCo/logos/main/yr-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G7nv4MSWXatvdcx7t4oPsg9sDCtxg8bbVcDA454wQkgh",
            "symbol": "CHI",
            "name": "Projekt Paradise",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G7nv4MSWXatvdcx7t4oPsg9sDCtxg8bbVcDA454wQkgh/token.png",
            "tags": [
                "community-token",
                "utility-token",
                "nft-staking",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "86hKMv2gmGJ1xKKDRHvjEE7sSbGpRfgaBgNrWHAo4uuN",
            "symbol": "GROWL",
            "name": "GROWL",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/h3ll0x/sabretoothsyndicate/main/tiger.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6jXNdXMrg8GUiSsgLc2DmkeoEC1NMGkDej4cYJ1zmvvj",
            "symbol": "MGWL",
            "name": "Mad Ghosts WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6jXNdXMrg8GUiSsgLc2DmkeoEC1NMGkDej4cYJ1zmvvj/logo.png"
        },
        {
            "chainId": 101,
            "address": "9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp",
            "symbol": "JELLY",
            "name": "Jelly",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wzEfUjmpFw",
                "twitter": "https://twitter.com/JellyBabiesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "2WozVZ5yp5T3cabdXbARyjYW82obC4JvyNbgj1JwM11V",
            "symbol": "OGSOLAR",
            "name": "Off Grid Solar Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/morwic/solarcoin/6c758de71aa851f172ef4fcdcb96829662557311/SolarCoin-Logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cc5vmGeN5V"
            }
        },
        {
            "chainId": 101,
            "address": "Fkfi6USVjwkY47qaSK4i4WQTKtE6tFLRrkMc8C5VPzhv",
            "symbol": "SDS",
            "name": "Seven Deadly Sins Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fkfi6USVjwkY47qaSK4i4WQTKtE6tFLRrkMc8C5VPzhv/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cx7Rswv6MNyaBk354BohVvBP6mCPFo7FDjTwCYDXkajG",
            "symbol": "STAR",
            "name": "Starry Insiders",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cx7Rswv6MNyaBk354BohVvBP6mCPFo7FDjTwCYDXkajG/logo.png",
            "tags": [
                "gambling",
                "nft"
            ],
            "extensions": {
                "description": "The official Starry Insiders token.",
                "twitter": "https://twitter.com/StarryInsiders",
                "website": "https://www.starryinsiders.com"
            }
        },
        {
            "chainId": 101,
            "address": "8iSagwHZNj4Hx4CMeoZwLLMVbWt4mUT6qk42TxiHkRtn",
            "symbol": "DGNA",
            "name": "DegenAlley",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8iSagwHZNj4Hx4CMeoZwLLMVbWt4mUT6qk42TxiHkRtn/DGNA.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://realms.today/dao/6iBBtiZDb14Y4xRRcJ6uchaRXoFG2PxiQggPooYTPZuS"
            }
        },
        {
            "chainId": 101,
            "address": "6YAXGyWb3hhLVQQ3vqg9ZYewXk4Cknnr1raTfDwbf8XG",
            "symbol": "MEKKA",
            "name": "MEKKA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6YAXGyWb3hhLVQQ3vqg9ZYewXk4Cknnr1raTfDwbf8XG/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ngFmDBmz8B",
                "twitter": "https://twitter.com/mekkafroggo",
                "website": "https://www.mekkafroggo.com"
            }
        },
        {
            "chainId": 101,
            "address": "DNLzdgaE3LUWoBxHasY9j8skCv8sBA9PBcD2DtYTjtaL",
            "symbol": "HHC",
            "name": "Horseshit High Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/alaydin/HHC_Test/master/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/tNvMvzjMxGhttps://realms.today/dao/6iBBtiZDb14Y4xRRcJ6uchaRXoFG2PxiQggPooYTPZuS"
            }
        },
        {
            "chainId": 103,
            "address": "DNLzdgaE3LUWoBxHasY9j8skCv8sBA9PBcD2DtYTjtaL",
            "symbol": "HHC",
            "name": "Horseshit High Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/alaydin/HHC_Test/master/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/tNvMvzjMxGhttps://realms.today/dao/6iBBtiZDb14Y4xRRcJ6uchaRXoFG2PxiQggPooYTPZuS"
            }
        },
        {
            "chainId": 101,
            "address": "Bfsrs1qLiuooamkSuG9tcF66SXXFefWbur86RkHDuxQS",
            "symbol": "CHING",
            "name": "CHA CHING",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bfsrs1qLiuooamkSuG9tcF66SXXFefWbur86RkHDuxQS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/parlay",
                "twitter": "https://twitter.com/parlaynft",
                "website": "https://theparlaynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "tr1HUaLpPmvaj1PAAXJokJ7PLjEGoSfuULhRvVvAPBS",
            "symbol": "SAIS",
            "name": "SAI Airdrop Token S",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tr1HUaLpPmvaj1PAAXJokJ7PLjEGoSfuULhRvVvAPBS/logo.png",
            "tags": [
                "airdrop-token"
            ],
            "extensions": {
                "discord": "https://dsc.gg/staratlasitalia",
                "github": "https://github.com/staratlas-italia",
                "medium": "https://staratlasitalia.medium.com/",
                "twitter": "https://twitter.com/staratlasitalia",
                "website": "https://staratlasitalia.com",
                "whitepaper": "https://staratlasitalia.com/whitepaper/"
            }
        },
        {
            "chainId": 101,
            "address": "tr2cweq4j6F8LrXk6vWWmamsxzkSFxyStCS3v1z2j75",
            "symbol": "SAIM",
            "name": "SAI Airdrop Token M",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tr2cweq4j6F8LrXk6vWWmamsxzkSFxyStCS3v1z2j75/logo.png",
            "tags": [
                "airdrop-token"
            ],
            "extensions": {
                "discord": "https://dsc.gg/staratlasitalia",
                "github": "https://github.com/staratlas-italia",
                "medium": "https://staratlasitalia.medium.com/",
                "twitter": "https://twitter.com/staratlasitalia",
                "website": "https://staratlasitalia.com",
                "whitepaper": "https://staratlasitalia.com/whitepaper/"
            }
        },
        {
            "chainId": 101,
            "address": "tr3Z8EqLMeNf2gHSpCsu9uP2o5DzoQ8QNFmueKjHQ95",
            "symbol": "SAIL",
            "name": "SAI Airdrop Token L",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tr3Z8EqLMeNf2gHSpCsu9uP2o5DzoQ8QNFmueKjHQ95/logo.png",
            "tags": [
                "airdrop-token"
            ],
            "extensions": {
                "discord": "https://dsc.gg/staratlasitalia",
                "github": "https://github.com/staratlas-italia",
                "medium": "https://staratlasitalia.medium.com/",
                "twitter": "https://twitter.com/staratlasitalia",
                "website": "https://staratlasitalia.com",
                "whitepaper": "https://staratlasitalia.com/whitepaper/"
            }
        },
        {
            "chainId": 101,
            "address": "7s6NLX42eURZfpyuKkVLrr9ED9hJE8718cyXFsYKqq5g",
            "symbol": "GEAR",
            "name": "Gear Token",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/7s6NLX42eURZfpyuKkVLrr9ED9hJE8718cyXFsYKqq5g/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "Hog8VTQ8fuaMKqbj426cZ9skfs6Dw9b6AW9h5qMb3akb",
            "symbol": "DTOKN",
            "name": "DEWATOKN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dewacorp/dewatokn/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/parlay",
                "twitter": "https://twitter.com/parlaynft",
                "website": "https://theparlaynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7VeFS95G5WApQgaAgZUejwRgV8pbWDDCSiL3kQKkyFrd",
            "symbol": "VAL",
            "name": "Valhall Crowns",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7VeFS95G5WApQgaAgZUejwRgV8pbWDDCSiL3kQKkyFrd/logo.png",
            "tags": [
                "metaverse-token",
                "community-token",
                "social-token",
                "nft-staking"
            ],
            "extensions": {
                "website": "https://valhall.site/"
            }
        },
        {
            "chainId": 101,
            "address": "TSTeJQaJ68jmS9vvooq9iDCmQETggC8ibb3ofeDkQUa",
            "symbol": "TST",
            "name": "The Syndicate Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TSTeJQaJ68jmS9vvooq9iDCmQETggC8ibb3ofeDkQUa/logo.png"
        },
        {
            "chainId": 101,
            "address": "DBsDkTdAxQEiSDeqfE48eMmc1vGXWjcum7hjRAf6zxza",
            "symbol": "DBS",
            "name": "DBS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DBsDkTdAxQEiSDeqfE48eMmc1vGXWjcum7hjRAf6zxza/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "cvF1vU4WXL1px9qqTcCCRhtBvyrLp2P7jPeh9qZCic9",
            "symbol": "CP",
            "name": "Creative Points",
            "decimals": 0,
            "logoURI": "https://github.com/Boxib/CP/blob/main/ic-removebg-preview.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "6zTqGvQRaxQo9mmz3mgv2MiEYcMpxhGVkp8CimKgS1M7",
            "symbol": "JOEY",
            "name": "Joey",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6zTqGvQRaxQo9mmz3mgv2MiEYcMpxhGVkp8CimKgS1M7/logo1.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://joeydiaz.net"
            }
        },
        {
            "chainId": 101,
            "address": "EpLKTiSVy9R5cFpwgp9FjHY6M2B3ZKoKuVgMSW59ts3d",
            "symbol": "CHZE",
            "name": "Cheeze Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/moxlongg/crypto/main/logo.png",
            "tags": [
                "social-token",
                "very-addictive"
            ]
        },
        {
            "chainId": 101,
            "address": "3rH1toffQAELHo5vyRKdwEFxhPTZA7ocfRdJK2c8txoJ",
            "symbol": "FamSOL",
            "name": "FamilySOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solodevo/FamilySOL/main/LOGOFamilySOL.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/tSXnjNJEhT",
                "twitter": "https://twitter.com/FamilySolNft",
                "website": "https://familysolnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4mD1WxMTjtSQZ2oEPF56Nve5tBF9h82fCTHCzkQ5fhrq",
            "symbol": "KIDS",
            "name": "Kids Matter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KidsMatterNFT/crypto/main/KidsMatter_Final1_NoBG-min.jpg",
            "tags": [
                "social-token",
                "charity-token",
                "nft-staking",
                "nft-marketplace"
            ]
        },
        {
            "chainId": 101,
            "address": "7UqGHhzUcjss4bxKDxjy5GPHTRPn2HyCYcAfhx8hpe2K",
            "symbol": "PSCYB",
            "name": "PsilocyBits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7UqGHhzUcjss4bxKDxjy5GPHTRPn2HyCYcAfhx8hpe2K/logo.png",
            "tags": [
                "psilocybin"
            ],
            "extensions": {
                "website": "http://psilocybits.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2N3F89tt3EttGDu7FUkYsDzUCtU7pLeYHygSV8HDhAb6",
            "symbol": "WOC",
            "name": "World Cup",
            "decimals": 4,
            "logoURI": "https://ipfs.io/ipfs/QmSK8KN5DAXWabX38PJZxrtKExXTjiHNEqCvPJXWzJM8Ju",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ERF6RUUm4XLhypKSgT22YLBcsS9YN6HjudnWDv6fSSQS",
            "symbol": "CBOYS",
            "name": "CBOYS WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/91.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EsyMThKB6pKiXDLiV1EbkH2TsYzNUGndC7e6T4Dmi1n8",
            "symbol": "MDR",
            "name": "Meta Drago DAO WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsyMThKB6pKiXDLiV1EbkH2TsYzNUGndC7e6T4Dmi1n8/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/yvw3Nezb",
                "twitter": "https://twitter.com/MetaDragoNFT",
                "website": "https://metadrago.art/"
            }
        },
        {
            "chainId": 101,
            "address": "5Nq1GvUtRbxRZ6LA4VLtTNJND2qgotrbQQMsA8qtuDUj",
            "symbol": "AVEF",
            "name": "AVEFENIX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Nq1GvUtRbxRZ6LA4VLtTNJND2qgotrbQQMsA8qtuDUj/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "GFwcGGZCKJEeLgDZQPT7TqjwWjTgwok9sC8SaJpUSwxz",
            "symbol": "GK",
            "name": "G Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andonfree/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SouLNByBQBBgM1hh4jmFHcCd6UJXxHMV8ngbu5f7Trj",
            "symbol": "souls",
            "name": "Soul Shards",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SouLNByBQBBgM1hh4jmFHcCd6UJXxHMV8ngbu5f7Trj/logo.png",
            "tags": [
                "solbound",
                "nft",
                "utility-token"
            ],
            "extensions": {
                "description": "Solbounds Currency Token",
                "discord": "https://discord.com/invite/zQ5ycytFgu",
                "twitter": "https://twitter.com/SolboundNFT",
                "website": "https://solbound.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Cj4BCm8j7eBfbeKAn7cqaSKahYbxBEtp7aLW7uNPuULf",
            "symbol": "DOGGC",
            "name": "DOGGETTY COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cj4BCm8j7eBfbeKAn7cqaSKahYbxBEtp7aLW7uNPuULf/logo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/VjGzYvU7n8",
                "twitter": "https://twitter.com/doggettynft"
            }
        },
        {
            "chainId": 101,
            "address": "DLmSkzTJ6TBfRoFbeVLkUwcakn2XFBuv7XNiFWKXdCjL",
            "symbol": "HONEY",
            "name": "Honey Chain",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HoneyChain/Assets/main/HoneyLogo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thehoneychain"
            }
        },
        {
            "chainId": 101,
            "address": "4kSiw6xdLYKjaKiQM4rQ6AkqqbFBcatzh7AAyMXwZqp9",
            "symbol": "COKE",
            "name": "Cocaine",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4kSiw6xdLYKjaKiQM4rQ6AkqqbFBcatzh7AAyMXwZqp9/logo.png"
        },
        {
            "chainId": 101,
            "address": "TopZ8khSHQiApXA1nEZncHtjCxxexSP7RxSNnRUqxQJ",
            "symbol": "SHIN",
            "name": "Shin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TopZ8khSHQiApXA1nEZncHtjCxxexSP7RxSNnRUqxQJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "9oCf3dx1PoSP1tnhNS6LBQXzixU1vkzNHvFwY1oFCD8M",
            "symbol": "$FLY",
            "name": "FLY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9oCf3dx1PoSP1tnhNS6LBQXzixU1vkzNHvFwY1oFCD8M/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/flyguys888"
            }
        },
        {
            "chainId": 101,
            "address": "9Ln4ifMMDATtQB4HHSt1iBbfKUh4mcepcXpNuiKC29nt",
            "symbol": "SOLOT",
            "name": "Solot Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/solot/main/solo.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solotmachinehd"
            }
        },
        {
            "chainId": 101,
            "address": "EARYFkgahvveTJsm64kbWWREDLYHpETwZypo3RTJZWyQ",
            "symbol": "SOLC",
            "name": "SolCountries Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/solc/main/solct.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolCountries"
            }
        },
        {
            "chainId": 101,
            "address": "4Y5fZd9ouaNY7mDFsrmNkuJxfDQLvLZUY8MeRnxuWSdT",
            "symbol": "GREEDY",
            "name": "Kitsune Kingdom Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/kitsune/main/kitsune.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kitsunekngdm"
            }
        },
        {
            "chainId": 101,
            "address": "C6drMh42qtqW8at6HmyXM6p45GrRNgMCYpWTFoxx5BEC",
            "symbol": "BTH",
            "name": "Beyond The Harmony",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jonathanlippe/solanatoken/main/bthlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "285TEuebA7adK8Pcbuwubc5wAVN4cpvuZGDthJzH4pZp",
            "symbol": "ETHER",
            "name": "Ether",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/285TEuebA7adK8Pcbuwubc5wAVN4cpvuZGDthJzH4pZp/logo.png"
        },
        {
            "chainId": 101,
            "address": "BLAAD2QLUgRSbQ9AB9jqAoHh55cGVcSBaCH9JGBh2zDX",
            "symbol": "BLOOD",
            "name": "DRACULA GAME BLOOD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLAAD2QLUgRSbQ9AB9jqAoHh55cGVcSBaCH9JGBh2zDX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/invite/Frj3KDPBpq",
                "twitter": "https://twitter.com/draculagame_nft",
                "website": "https://www.dracula.game"
            }
        },
        {
            "chainId": 101,
            "address": "divWEfz76RDbL5kre8AsbaQtMFH8W38o8kTK5BBFNmP",
            "symbol": "DVW",
            "name": "divWallet",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/divWEfz76RDbL5kre8AsbaQtMFH8W38o8kTK5BBFNmP/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EMp3TqZHTgPJBMLa54qT6p8hNjimYscCEc8HPmQZJi35",
            "symbol": "ByteWL",
            "name": "Byte Buddies WL",
            "decimals": 0,
            "logoURI": "https://media.discordapp.net/attachments/949172481690861598/949597987414351912/WL_token_logo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bytebuddiesnft"
            }
        },
        {
            "chainId": 101,
            "address": "WisehRG5uPTHFJHrTmfm7dBMRvLZ6Lm9mivgYB2Pd3A",
            "symbol": "WISECAT",
            "name": "Wisecat Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WisehRG5uPTHFJHrTmfm7dBMRvLZ6Lm9mivgYB2Pd3A/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wisecats",
                "instagram": "https://www.instagram.com/wisecatsnft/",
                "twitter": "https://twitter.com/WiseCatsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "TPxx46psN8pYKBVUm1g5xis2KZYp5kQEPDjUdjsob9S",
            "symbol": "TPwl",
            "name": "Traveling Pigeons WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TPxx46psN8pYKBVUm1g5xis2KZYp5kQEPDjUdjsob9S/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EgYW3GbGgHBHcGpm2UVFHh75qMTEWMRjsWeBax3rQbLq",
            "symbol": "WTR",
            "name": "Water",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EgYW3GbGgHBHcGpm2UVFHh75qMTEWMRjsWeBax3rQbLq/logo.png"
        },
        {
            "chainId": 101,
            "address": "2MtPZqwNKTNsBoFCwm4ZTWk3ySz4LSd82ucDGeTk7VNu",
            "symbol": "IVRY",
            "name": "Portals Ivory Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/102af36cc30fec0b05ebb45406f20971/126d0320",
            "tags": [
                "bridgesplit-fraction",
                "portals-ivory",
                "fraction-index",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/2MtPZqwNKTNsBoFCwm4ZTWk3ySz4LSd82ucDGeTk7VNu"
            }
        },
        {
            "chainId": 101,
            "address": "GZL4yjPohDShW4RofJ6dEWu2Fv7qEa5mBT7Dpje5hqe7",
            "symbol": "SAC",
            "name": "Stoned Ape Crew Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/sxmJbDr.jpg",
            "tags": [
                "bridgesplit-fraction",
                "stonedd-ape-crew",
                "fraction-index",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/GZL4yjPohDShW4RofJ6dEWu2Fv7qEa5mBT7Dpje5hqe7"
            }
        },
        {
            "chainId": 101,
            "address": "EQuxvgDwuCyCb9HqAmctQ6Pd9uD2EV3YFHbPdq7CE1dq",
            "symbol": "SILV",
            "name": "Silver",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EQuxvgDwuCyCb9HqAmctQ6Pd9uD2EV3YFHbPdq7CE1dq/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "EFeeUYk9h8uVKARRza9j2QARJ9KfRCD6qC2vwqPmXBaV",
            "symbol": "WMU",
            "name": "Weedmenu",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EFeeUYk9h8uVKARRza9j2QARJ9KfRCD6qC2vwqPmXBaV/logo.png"
        },
        {
            "chainId": 101,
            "address": "94WM3HZrxKhRfrXRZQ5xswdiLdjQaugwoBe7pJ3azoCA",
            "symbol": "DRUG",
            "name": "Drugs",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94WM3HZrxKhRfrXRZQ5xswdiLdjQaugwoBe7pJ3azoCA/logo.png"
        },
        {
            "chainId": 101,
            "address": "todqxobCHmNPgu2boQy7VUFado4RARbemgh8i9AwWdn",
            "symbol": "GATWL",
            "name": "Gangsta Gator WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/todqxobCHmNPgu2boQy7VUFado4RARbemgh8i9AwWdn/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://www.gangstagators.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AcN9sRB6YzKJpfcYbpDzkc2YjfixMYfiZCfJHqMaFyis",
            "symbol": "PCE",
            "name": "Peace Coin Earth",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/P3ac3C01n/peacecoin/main/logo.png",
            "tags": [
                "social-token",
                "Health",
                "Healthcare",
                "education",
                "Support-people",
                "Support-refugees",
                "Support"
            ],
            "extensions": {
                "description": "The 31% of the total coins will be used to provide essential needs of war affected and underpriviledged people."
            }
        },
        {
            "chainId": 101,
            "address": "8eagBiM6u6b9hyk3L4yuX5aKCJTY6Q38N8YdzqYVWWmn",
            "symbol": "ICEY",
            "name": "Diamonds",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8eagBiM6u6b9hyk3L4yuX5aKCJTY6Q38N8YdzqYVWWmn/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "MeTaRpcZ6XDvFrmPsNFqhehqV7MgY6obHrsxJLG372E",
            "symbol": "MAECWL",
            "name": "MAEC WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MeTaRpcZ6XDvFrmPsNFqhehqV7MgY6obHrsxJLG372E/logo.png",
            "tags": [
                "Whitelist"
            ],
            "extensions": {
                "discord": "https://discord.gg/MetaApeEC",
                "twitter": "https://twitter.com/MetaApeEC",
                "website": "https://metaapeeliteclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CEKW3UCXwVb7SPjASeNhzHAenyzYGrNwxGMNzXHRz8k6",
            "symbol": "HOT",
            "name": "Hotels",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CEKW3UCXwVb7SPjASeNhzHAenyzYGrNwxGMNzXHRz8k6/logo.png"
        },
        {
            "chainId": 101,
            "address": "C2WwPdPrQAnEqoSeNTprrbbJiW5KY2EmeWFmdvBm1Fi6",
            "symbol": "FLT",
            "name": "Flights",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C2WwPdPrQAnEqoSeNTprrbbJiW5KY2EmeWFmdvBm1Fi6/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "GcjjhxjtK8t8DMqf9Zm1CZ48d5SohHm2k2aFfsTZ5ssp",
            "symbol": "COM",
            "name": "Comedy",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GcjjhxjtK8t8DMqf9Zm1CZ48d5SohHm2k2aFfsTZ5ssp/logo.png"
        },
        {
            "chainId": 101,
            "address": "BFK8DKyraJdJCStwtFK3JAhC4N2q6UgfDKzKoeNskQYT",
            "symbol": "JACK",
            "name": "Blackjack",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BFK8DKyraJdJCStwtFK3JAhC4N2q6UgfDKzKoeNskQYT/logo.png"
        },
        {
            "chainId": 101,
            "address": "AhRP4bxXcycEChFe8z4exKUoGSvgHukXripPhbH2e5Qe",
            "symbol": "GTR",
            "name": "Guitar",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AhRP4bxXcycEChFe8z4exKUoGSvgHukXripPhbH2e5Qe/logo.png"
        },
        {
            "chainId": 101,
            "address": "eTgYW2UEke2tyj6PTWZF2L6LMHztbB4ZMc8NGY7HVWf",
            "symbol": "COOKIES",
            "name": "Cookies",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/eTgYW2UEke2tyj6PTWZF2L6LMHztbB4ZMc8NGY7HVWf/logo.png"
        },
        {
            "chainId": 101,
            "address": "84kG7LY99uiJ69wprwmxksPDjwTjPY5PJiqhaQEBvatG",
            "symbol": "LYCH",
            "name": "Lychee Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fungtoken/crypto/main/Lychee.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9N32Tr2iXFo6GNxRRSFTtrVYcriEnABAwPE7rFatFBA1",
            "symbol": "CGWL",
            "name": "Cowgirl WhiteList Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9N32Tr2iXFo6GNxRRSFTtrVYcriEnABAwPE7rFatFBA1/Cowgirl_WL.png",
            "tags": [
                "NFT",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/wildwestverse",
                "website": "https://www.wildwestverse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "B4GtD1qaV1vKANHqkAekb2EPx82qQbLqyDM7qkCKPsjC",
            "symbol": "UMGMC",
            "name": "umang mc coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/hxyro/logo/main/UMG.png",
            "tags": [
                "NFT",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/hxyro0"
            }
        },
        {
            "chainId": 101,
            "address": "7S9apq5H8CAgdKP6hpNR7HL2KmgFX4PKCU1DFhzTqYof",
            "symbol": "PVPV",
            "name": "Papa Charlie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thoms021/crypto/main/logo3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4WpMh3kj2uQzUBmLxR6zwwGeWdHAdeGGBdRfeqA8qM3v",
            "symbol": "SLPT",
            "name": "Silpa",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4WpMh3kj2uQzUBmLxR6zwwGeWdHAdeGGBdRfeqA8qM3v/logo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Silpa-Platform"
            }
        },
        {
            "chainId": 101,
            "address": "HXkMCeVEAkC8QBDJJpy8d8HEeUp2K6EVcLaKyU48cymN",
            "symbol": "UWP",
            "name": "Underworldpics",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HXkMCeVEAkC8QBDJJpy8d8HEeUp2K6EVcLaKyU48cymN/logo.png",
            "extensions": {
                "website": "https://underworldpics.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FsXYfMuqaFZNJdBunHQzVKLCQHjCE4KWDtDm8iE388Zq",
            "symbol": "GRN",
            "name": "Greene Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Brucemostert/Coin/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.greenepets.com"
            }
        },
        {
            "chainId": 101,
            "address": "5mpHNiNrf5wWJoQNzUGrVe9DE4VpnGyCXzzMaNAx7SHy",
            "symbol": "LDTLS",
            "name": "LDT Legendary Sensei Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5mpHNiNrf5wWJoQNzUGrVe9DE4VpnGyCXzzMaNAx7SHy/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "DfEAzEifrhMHw9pLiW2echTE4XvVEFkXavqLKRuApc9t",
            "symbol": "AGK",
            "name": "AgriKoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/umarbrowser/agrikoin/main/logo.png",
            "tags": [
                "farming",
                "nft",
                "community-token"
            ],
            "extensions": {
                "description": "Agrikoin Currency Token",
                "website": "https://agrikoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "PLGoBo4XyjrfCR76WRcdT4G7bFtjGEtCdhViTWk9HA9",
            "symbol": "PLG",
            "name": "ProLeague",
            "decimals": 9,
            "logoURI": "https://sybvdlumjgmthsovg2jw7n5u54keits532jcbuopkc4dyowpgu.arweave.net/lgNRroxJmTPJ1Ta-Tb7e07xRETl3ekiDRz1C4PDrPNc",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "ProLeague is a web3 social blockchain gaming/esports platform fostering the growth of the next generation of Esport professionals by offering an engaging platform that uses blockchain, NFTs, and our unique cryptocurrency to reward and incentivize competitive play.",
                "twitter": "https://twitter.com/proleaguegg",
                "website": "https://proleague.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "7gPNQ28N9p1BZnLc49ipj4wQRRSMtuuqSMy3aNzJ6Pr",
            "symbol": "CBRC",
            "name": "Crypto Brain",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bizottodbt/crypto-brain-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLAyTCh9AqPFYPNiYpHYo8nAPMqgbV4MTduLcg5kHdh",
            "symbol": "LBA",
            "name": "Le Bel Anus Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLAyTCh9AqPFYPNiYpHYo8nAPMqgbV4MTduLcg5kHdh/logo.png"
        },
        {
            "chainId": 101,
            "address": "3azR7QE61RFnGw1PDrnCNAABFGR54xskLEuCEuyTvtT6",
            "symbol": "PWL",
            "name": "Planet WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3azR7QE61RFnGw1PDrnCNAABFGR54xskLEuCEuyTvtT6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Planet WL Token",
                "website": "https://planet-mint.vercel.app/"
            }
        },
        {
            "chainId": 101,
            "address": "G7yoaGtYGKUWq18cKUX3bpt6Gpr5fpZLVhMXctW77VE8",
            "symbol": "KDMC",
            "name": "Kingdom Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ecduzit/kdmc/main/kdmclogo16.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.kingdomconnectionglobal.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EvvR84wMJVjsoNTAU9tdxNGhm7peGYXGdJSLCE3pwegR",
            "symbol": "TDKP",
            "name": "TaterCoin DKP",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EvvR84wMJVjsoNTAU9tdxNGhm7peGYXGdJSLCE3pwegR/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "57MRgnqY9U2HeHoJNF63Z6f8GAgzBjyB9rNecqK9G3sV",
            "symbol": "FLEUX",
            "name": "Fleux",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/57MRgnqY9U2HeHoJNF63Z6f8GAgzBjyB9rNecqK9G3sV/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "stake-pool-token",
                "NFTs",
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/RTSEFBVqZB",
                "instagram": "https://www.instagram.com/fleuxlabs/",
                "twitter": "https://twitter.com/fleuxlabs"
            }
        },
        {
            "chainId": 101,
            "address": "5ZExppytfT5qnHnc4MMMwVaeaBtbAUavuUK6EU3jyPBo",
            "symbol": "MORB",
            "name": "Metal Orb",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/M-Jawad-Ch/MetalOrbs/Projects/icon.png",
            "tags": [
                "utility-token",
                "community-coin",
                "NFTs"
            ],
            "extensions": {
                "discord": "https://discord.gg/sm6Ts6bF",
                "twitter": "https://twitter.com/Fdarklord5"
            }
        },
        {
            "chainId": 101,
            "address": "EnmQX2aeyagdjv5EBsCAKnUwyV1iqmkEPWhqbnNzXJHU",
            "symbol": "PING",
            "name": "Pingo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zigacmer/crypto/main/finalni%201000x1000.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "stake-pool-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2TocBpcDms9xWB9oGBo1Pek3bZDhbBtFRNUkSYbQ9N5P",
            "symbol": "TRS",
            "name": "TruSwap",
            "decimals": 0,
            "logoURI": "https://github.com/Msaqib0786/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H8iLU94PvXEBk3fAFKveJ8RgNxPejYQetYipWPNs6WXk",
            "symbol": "MEG",
            "name": "Megan Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/joshtasman/cSolana/main/meg_logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9ihuaebHjvKwpWHXcnQoYjvxA4qHX1fy2SuYyCZ7Rokh",
            "symbol": "RAYS",
            "name": "SUNSHINE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mastervc/Crypto-/main/87-878487_cute-sunshine-clipart-19-cute-sun-clip-art.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2oDVQrNmBrJR71t2wJjq5f7Vz6ohnJheHoLMJEHcEW4J",
            "symbol": "FPUNK",
            "name": "Fab Punk Index",
            "decimals": 2,
            "logoURI": "https://www.arweave.net/-6iToYivBOpCvcDdFMN1MHLFLlQHKolBIm2dtQvyLbE?ext=png",
            "tags": [
                "bridgesplit-fraction",
                "fab-punks",
                "fraction-index",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/2oDVQrNmBrJR71t2wJjq5f7Vz6ohnJheHoLMJEHcEW4J"
            }
        },
        {
            "chainId": 101,
            "address": "Trc1fBjSu3N9jQYhRJxMKeVp5teAfwchJ5ru5DwBKUX",
            "symbol": "TRCWL",
            "name": "The Rigers Club WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Trc1fBjSu3N9jQYhRJxMKeVp5teAfwchJ5ru5DwBKUX/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/waGjtdd9bD",
                "twitter": "https://twitter.com/RigersNFT",
                "website": "https://rigersclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "6onZgUwKaw1xhiMa3oDAF2ZW5kfwDUqggxWxLEUEeiPx",
            "symbol": "EVAN",
            "name": "Evan Coin",
            "decimals": 0,
            "logoURI": "https://github.com/kaprebox/crypto/blob/main/evancoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ssSTgHfmnnVjHiLK69gFMc3kBF5VYwqkcospoTv9uSj",
            "symbol": "SSST",
            "name": "SSS Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ssSTgHfmnnVjHiLK69gFMc3kBF5VYwqkcospoTv9uSj/logo.png",
            "extensions": {
                "description": "At Solana Secret Sociaty we build secretly to release Amazingly trying to create an extremely positive use case for the whole NFT industry!",
                "discord": "https://discord.gg/SxDPXK5v45",
                "twitter": "https://twitter.com/sssnfts",
                "website": "https://solanasecretsociety.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "Astramzi5GnmM6h657PgtEPXMi43qTXSfZfJrPb1Ytb3",
            "symbol": "AST",
            "name": "Astrals WL Pass",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Astramzi5GnmM6h657PgtEPXMi43qTXSfZfJrPb1Ytb3/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/astrals_nft"
            }
        },
        {
            "chainId": 101,
            "address": "52FoYpdjoZRVvgSZvSWegxpYjBVCaoxZ9AzWAtZ3BN7N",
            "symbol": "ROU",
            "name": "Roulette",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/52FoYpdjoZRVvgSZvSWegxpYjBVCaoxZ9AzWAtZ3BN7N/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "B3DMB7GaKSfm8PiMFTfUtrr9jaxPSMmkpUtBuu8EQXqr",
            "symbol": "APL",
            "name": "Apple",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B3DMB7GaKSfm8PiMFTfUtrr9jaxPSMmkpUtBuu8EQXqr/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "D1pgyotequejHAJaUeRouj9UiMn3waqSt1kUUtXCBDWa",
            "symbol": "SOLBTC",
            "name": "SOL Bitcoin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D1pgyotequejHAJaUeRouj9UiMn3waqSt1kUUtXCBDWa/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "ToEY2bUgDzbMfABvuUQ41AvinTQeb6qwJZkfXCLVKGS",
            "symbol": "NA",
            "name": "NinjaApe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/itachi201/logo/main/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "GVST2hmmp1xexQcF9nEUQoYvwhqfaYQNrjR36aNeB1MY",
            "symbol": "VINAY",
            "name": "Vinay Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVST2hmmp1xexQcF9nEUQoYvwhqfaYQNrjR36aNeB1MY/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "GUZBixfPh8ksp3oHme778CdXtnETfNZizSFWhfWnaTqA",
            "symbol": "HER",
            "name": "Heroin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUZBixfPh8ksp3oHme778CdXtnETfNZizSFWhfWnaTqA/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "1Kt8V3zUr9yxHtaoBvfxMNBkiopSDTZ2d5GZpAMdsJN",
            "symbol": "PLT",
            "name": "Platinum",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1Kt8V3zUr9yxHtaoBvfxMNBkiopSDTZ2d5GZpAMdsJN/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "ALNdAqpmo7jTPFmF888abDzcSoKFnsgdFaJabGNgrZ9S",
            "symbol": "505",
            "name": "UFO 505",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALNdAqpmo7jTPFmF888abDzcSoKFnsgdFaJabGNgrZ9S/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/VEVJJTjxKD",
                "instagram": "https://instagram.com/invalienspecies",
                "twitter": "https://twitter.com/invalienspecies"
            }
        },
        {
            "chainId": 101,
            "address": "3fG4VCyMDBwz8zRz6V2J55hAiwCqt86JLoYJSNfqpeVg",
            "symbol": "piece8",
            "name": "Pieces of 8",
            "decimals": 0,
            "logoURI": "https://github.com/liu-ren/crypto/blob/main/pieceof8.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "62onfgZVfLYquXBG2CtfNcNsB2iczSLDb9QLHusNC1xh",
            "symbol": "YE",
            "name": "Sol Ye Dropout Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Fotsbeats/ye/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EE2BWEPx1ZznRkZ5CUnWLcwZwiYW16K6VqoawTfYMgbq",
            "symbol": "GOLD",
            "name": "Gold",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EE2BWEPx1ZznRkZ5CUnWLcwZwiYW16K6VqoawTfYMgbq/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "C1h9o2ojnKNTwvmgCTVuyvYRMRbDRn9FuTzaMVKkAo54",
            "symbol": "PWCC",
            "name": "Priority Waste Crypto Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gagar/prioritycoin/main/prioritycoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hxk1ns5V8Lq41wzLjvq8pvNEhGh3FcCTWbawbj5SL4jj",
            "symbol": "HEMOB",
            "name": "Heatmob",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hxk1ns5V8Lq41wzLjvq8pvNEhGh3FcCTWbawbj5SL4jj/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://heatmob.net"
            }
        },
        {
            "chainId": 101,
            "address": "97uDv3zZqJtT46HnzMaEyFzhzfUPTdhe2i2D1298jtb2",
            "symbol": "SCORPIONS",
            "name": "SRP ",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/Dherie/logo/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Scorpionseu"
            }
        },
        {
            "chainId": 101,
            "address": "4Pb9eXNQCmx6WKG8knniCqmNS6qJiqCcB3A6CFjcCkWB",
            "symbol": "DCATMT",
            "name": "DCat Metatoken",
            "decimals": 9,
            "logoURI": "https://github.com/dcatmusicnft/assets/blob/main/dcatmtlogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C5REfPvPMbwLAEyfB9M8MK3Kj5x9PPHp8KhJzykzQuUx",
            "symbol": "lV",
            "name": "lnvoker",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xLoopCreativeAndyx/-/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EBQ6gWBQNxA2zB4twR5GWP6CkeAhqZZZeDgeP7BTtdM3",
            "symbol": "PERP",
            "name": "PerpeTraders",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EBQ6gWBQNxA2zB4twR5GWP6CkeAhqZZZeDgeP7BTtdM3/logo.jpg",
            "tags": [
                "stablecoin",
                "security-token",
                "utility-token",
                "tokenized-stock"
            ]
        },
        {
            "chainId": 101,
            "address": "Dc8mEshVhb6e4dxi1yWm2gBDxUDVfYBKrYMGR1hz8Ku7",
            "symbol": "NEUT",
            "name": "Neutrino",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dc8mEshVhb6e4dxi1yWm2gBDxUDVfYBKrYMGR1hz8Ku7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WcBbCJPNYr",
                "twitter": "https://twitter.com/matrbuilders",
                "website": "https://www.matr.builders"
            }
        },
        {
            "chainId": 101,
            "address": "AVB4JJMg8dG4igf5cpx5wvCak5XqqE359PzbZo4B9yKz",
            "symbol": "BLAK",
            "name": "BLAK Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/blakcoin/logo/main/image1.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theblakcoin"
            }
        },
        {
            "chainId": 101,
            "address": "8PvtB3kEnoNMLcEBBsXdQqy3N2RYnWgJ4GzhButPytTL",
            "symbol": "DAC",
            "name": "DAC - Token for Defiant Ape Casino",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/DefiantApeClub/DefiantApeToken/main/Untitled-7.png",
            "tags": [
                "NFT",
                "Gambling"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DefiantApeClub",
                "website": "http://defiantapeclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FNRDrrinmYQ4TMEbAppmLFp92bGi6FfNLBCp5Xkc2PSw",
            "symbol": "FNRD",
            "name": "Finrend Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNRDrrinmYQ4TMEbAppmLFp92bGi6FfNLBCp5Xkc2PSw/logo.png",
            "tags": [
                "defi",
                "fund-management",
                "nft",
                "finance",
                "exchange"
            ],
            "extensions": {
                "discord": "https://discord.gg/rTVXFxhY",
                "medium": "https://medium.com/@finrend",
                "telegram": "https://t.me/Finrend",
                "twitter": "https://twitter.com/finrend"
            }
        },
        {
            "chainId": 101,
            "address": "2Kc91qK5tA1df2P9BWrjNrJfdQCbCx95iUY8H27aNuWa",
            "symbol": "FFF",
            "name": "Famous Fox Federation Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Kc91qK5tA1df2P9BWrjNrJfdQCbCx95iUY8H27aNuWa/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "5WDPUdgNUUiQ83TXubV8q1CdSnNg8WvAH7YDXwTvdct7",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "yAC4RaXvFZpNhnXZVgiiYj4cgC1G5SbgX5jzdaEXgyA",
            "symbol": "y-apUSDC-USDC",
            "name": "Yield Saber LP Token (apUSDC-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yAC4RaXvFZpNhnXZVgiiYj4cgC1G5SbgX5jzdaEXgyA/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GUtWHTy9N5Av4LTB5PJPn4ZTfxCB2tGiK7DJpS7y8F8S",
            "symbol": "AUR",
            "name": "Aurory Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUtWHTy9N5Av4LTB5PJPn4ZTfxCB2tGiK7DJpS7y8F8S/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "Hd5uEsj4fnEYsdPdY8ReptKoXGkUZYM9ZC5GHZcHkCBY",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "C3z52NDqHYt1W33Mve25e3rRE9itoZeSjQgPxgXwFotH",
            "symbol": "HFT",
            "name": "Hall Family Token",
            "decimals": 2,
            "logoURI": "https://github.com/Toastiam/hft/blob/main/HFT.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GknXZXR3Y84wgmDUxtsoR9FBHEZovqXFuDK2jczi1ydz",
            "symbol": "GENO",
            "name": "Genopets Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GknXZXR3Y84wgmDUxtsoR9FBHEZovqXFuDK2jczi1ydz/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "8AjdxXSvZ5Cu7FGsoMa6Y8hu1ZS1iCy3gaoNggNCmqML",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 103,
            "address": "FMkEN7WnMou9PejHf72N4Pveh7A83iR4PfruwD9BAm2v",
            "symbol": "MOU9",
            "name": "Mou9",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FMkEN7WnMou9PejHf72N4Pveh7A83iR4PfruwD9BAm2v/logo.png"
        },
        {
            "chainId": 101,
            "address": "AgBQSKgZPJPmsMz8qkHbyZdEU4JrRpoNHYh2kxE5TcD1",
            "symbol": "BV",
            "name": "Balloonsville Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AgBQSKgZPJPmsMz8qkHbyZdEU4JrRpoNHYh2kxE5TcD1/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "5tVUY9id5G6tPBu5C6hFZ9f6As3A4eopf7QTApt5owAy",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "DcUhmQsxyGKnqaKzn5b8K3QB2LRN2uqzxLeqgbtuC4ry",
            "symbol": "ECOGA",
            "name": "Echocore Genesis Access",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcUhmQsxyGKnqaKzn5b8K3QB2LRN2uqzxLeqgbtuC4ry/logo.png",
            "tags": [
                "NFT",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/echocore_io",
                "website": "https://echocore.io"
            }
        },
        {
            "chainId": 101,
            "address": "5aGsu5hASnsnQVXV58fN8Jw9P8BVyfDnH2eYatmFLGoQ",
            "symbol": "LIFL",
            "name": "Lifinity Flares Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5aGsu5hASnsnQVXV58fN8Jw9P8BVyfDnH2eYatmFLGoQ/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "AYbybZJN533vgPdZrJga6Zw7WtZCnUofNLiMjneWUP6V",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 103,
            "address": "9AjFLx5CRee9kGxmN54LkHz9opZpESerP27C6sGhZsdH",
            "symbol": "snake",
            "name": "SnakeChain_Devnet",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/SnakeRush/SnakeRush/master/SnakeChainLogo.png",
            "tags": [
                "utility-token",
                "gaming",
                "metaverse",
                "play-to-earn",
                "solana-ecosystem",
                "user-experience",
                "multiplayer",
                "fun",
                "awesome",
                "reading-this-desu-ka"
            ],
            "extensions": {
                "description": "SnakeChain_Devnet (snake) are the DevNet tokens for SnakeRush's SnakeChain currency. These are used on the beta/devnet game. They are earned by playing SnakeRush, and minting your coins at the SnakeBank. They can be used to play more and exchange in any market where available.",
                "instagram": "https://www.instagram.com/snakerushgame/",
                "twitter": "https://twitter.com/snakerushgame",
                "website": "https://www.snakechain.org"
            }
        },
        {
            "chainId": 101,
            "address": "DpcmtJniwGRPqU6A8shdcV812uddwoxDCMfXUz2SkLVJ",
            "symbol": "DGOD",
            "name": "DeGods Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DpcmtJniwGRPqU6A8shdcV812uddwoxDCMfXUz2SkLVJ/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "FFmE1patBuqcjRZ1TMrokrefnpMqzmLZMecLGhvnWb3b",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "SeN67Ce1f19MES76SDDiXFBTURNFQuQKFQ2rWPPHuZY",
            "symbol": "SENSEI",
            "name": "Radja Sensei",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SeN67Ce1f19MES76SDDiXFBTURNFQuQKFQ2rWPPHuZY/logo.jpg",
            "tags": [
                "airdrop-token",
                "social-token",
                "swap-token",
                "reward-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GGATzpTfEwkq8BDotzBFTUSeKu36XRtsFhncd83UDwEu",
            "symbol": "GGAT",
            "name": "Gangsta Gators",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGATzpTfEwkq8BDotzBFTUSeKu36XRtsFhncd83UDwEu/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.gangstagators.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DonHdDmzsx9wWtnettbw2MxC5zhcHBX4DsPwFPZvDSnu",
            "symbol": "DUIT",
            "name": "Duit Digital",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DonHdDmzsx9wWtnettbw2MxC5zhcHBX4DsPwFPZvDSnu/duit520x520.png",
            "tags": [
                "social-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "9vzSi7RDSgk6aTm8Fp6xDU5HgBvmHVGFqd1YjFhzuuPd",
            "symbol": "VPT",
            "name": "ViewPoint",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TheViewPoint/crypto/main/ViewPointLOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GGWL2pjJKrUcH47a23DLGXUUoLT8HJVhdcjVhfi2p1tT",
            "symbol": "GGWL2",
            "name": "Glitched Gorilla WL Token 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGWL1v6aRnjRFAxi7j2ArQBDRxb1maZz4XGqMsxkdYbt/GGToken.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WtpzxsBTGF",
                "twitter": "https://twitter.com/MH_Multiverse",
                "website": "https://milehighmultiverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "GxuwbQeuVFzkjjYA2j6hvihLyE8tYZbE3zYFjciZquXB",
            "symbol": "FHG",
            "name": "Faith Heroic Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/adademonn/image/main/index.png",
            "tags": [
                "fhg-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CjgFeULq2ZJCnH9XpTaR8eEcSVxfuVqCk7nBgxdDVmpe",
            "symbol": "KSOC",
            "name": "KSherwoodOps Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KSherwoodOps/KSherwoodOps_Coin/main/ksherwood_logo_01_v03_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8RSyhCxFKYVnr6PGTgKC9o86AjbQjdmLRZjYJjhdTdYH",
            "symbol": "FAITH",
            "name": "Faith",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RSyhCxFKYVnr6PGTgKC9o86AjbQjdmLRZjYJjhdTdYH/logo.png"
        },
        {
            "chainId": 101,
            "address": "Fw57g8sgRnKBu3wDcGu9wYge17EDPaQdTdMvbRYuXYXr",
            "symbol": "AANDELEN",
            "name": "Belgian Company BV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fw57g8sgRnKBu3wDcGu9wYge17EDPaQdTdMvbRYuXYXr/Blockstone.png",
            "tags": [
                "Example-company",
                "Amsterdam-Lawyers"
            ],
            "extensions": {
                "website": "https://amsterdam-lawyers.nl/belgian-company-bv/"
            }
        },
        {
            "chainId": 101,
            "address": "JAa3gQySiTi8tH3dpkvgztJWHQC1vGXr5m6SQ9LEM55T",
            "symbol": "solUST",
            "name": "solUST",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAa3gQySiTi8tH3dpkvgztJWHQC1vGXr5m6SQ9LEM55T/solust.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://soluna.money/"
            }
        },
        {
            "chainId": 101,
            "address": "ExistEr1h19DiEPPzaDpwx3DnjQbrVbXpaxKDYBSNoWj",
            "symbol": "Exist",
            "name": "Exist",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ExistEr1h19DiEPPzaDpwx3DnjQbrVbXpaxKDYBSNoWj/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/ExistCoin",
                "website": "https://existcoin.io"
            }
        },
        {
            "chainId": 101,
            "address": "FFBnqafsjrvvxxf5n3Tzba8V7vWPb8wr5DPEog1VAwff",
            "symbol": "THUGZ",
            "name": "Thugbirdz Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFBnqafsjrvvxxf5n3Tzba8V7vWPb8wr5DPEog1VAwff/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "5Rcqv6qXRFFnK3dzYu96sKqf6wtC8hEA1ivRdMwFHEH2",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "F7hd3fj1nFrSDmDrYkANSFkFagnfYSjHUG2i2NxE3Jaz",
            "symbol": "VLGKEY",
            "name": "Village Building Site Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F7hd3fj1nFrSDmDrYkANSFkFagnfYSjHUG2i2NxE3Jaz/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8jwFgR1QUNxjHmyhmpuDXmsyq3T2gSCqPkLntVar5ZwB",
            "symbol": "$DINOLABS",
            "name": "DinoLabs Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8jwFgR1QUNxjHmyhmpuDXmsyq3T2gSCqPkLntVar5ZwB/logo.jpg",
            "extensions": {
                "discord": "https://discord.gg/dinolabs",
                "twitter": "https://twitter.com/DinoLabsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "FQkm6bACFuJpGDmnkvYoq2Luhcc65oam2dg1tXfnKWAY",
            "symbol": "PLWAV",
            "name": "Playground Waves Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FQkm6bACFuJpGDmnkvYoq2Luhcc65oam2dg1tXfnKWAY/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "Ccgq4UV9sFtXp9yMGXwJRcJz9UsgQFEjF3Fmui2Z6Xhm",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "GatoRJrXZPeRFBoCwirUHGTGVkMXNvsLLx6TUhMAD14T",
            "symbol": "GAS",
            "name": "Gator Squad WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GatoRJrXZPeRFBoCwirUHGTGVkMXNvsLLx6TUhMAD14T/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GatorSquadNFT"
            }
        },
        {
            "chainId": 101,
            "address": "4UnuMZTAUf7Xn1VLg93AfvkLTVk8v5YCvRvRanwHL3QF",
            "symbol": "ANDI",
            "name": "Anditon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Anditon/crypto/main/solanacoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BLoCKVzvv9jRSm6rLQhXNauQ3p34asRWocJyWdrkkWUF",
            "symbol": "BLS",
            "name": "Official Blocksmith Labs WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLoCKVzvv9jRSm6rLQhXNauQ3p34asRWocJyWdrkkWUF/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BlocksmithLabs",
                "website": "https://mercury.blocksmithlabs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9aRLE1rSJJmtALJXBQX8iH63sJ5hyEhy2G28NH1SKFjk",
            "symbol": "HITKN",
            "name": "Hassanat",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/MohamedITKN/Hassanat/main/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Hassanat ($HITKN) is the token of Islam, utility token for Muslims who can open the gates of paradise by doing good deeds.",
                "discord": "https://discord.gg/qEfCZuJHsu",
                "twitter": "https://twitter.com/hassanat_itkn"
            }
        },
        {
            "chainId": 101,
            "address": "BLrzBnx4qYLK8A93NdYHFq8Ucye9sySRhNjn1NuMa9tR",
            "symbol": "CHUMM",
            "name": "CHUMM Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/btempel05/assets/main/chum.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DSGD5HFS57ThL6sE9rHk8zgnNiRrWsDkiLsuMTHaRyv5",
            "symbol": "CARGO",
            "name": "CargoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CargoBlitz/cargocrypto/main/images.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BPfgTWYrsBZJAxrjLxp9cMbtKTswsL5wwW3SHqPCnFh5",
            "symbol": "SCWL",
            "name": "SolChippie WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BPfgTWYrsBZJAxrjLxp9cMbtKTswsL5wwW3SHqPCnFh5/logo.png",
            "tags": [
                "NFT",
                "SOLCHIPPIE"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solchippie",
                "website": "https://solchippie-stacking.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HyuZntYTzeE3jtYozsLcyj84iZJgqxsmnMCGhQRAoTdc",
            "symbol": "IQD",
            "name": "Iraqi Dinar Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/opals99/IQD-SOL-Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G3Azoh6ff4MH443ZZ78ArUiut1gbq9w445PmuPnnzQKv",
            "symbol": "$SHADOW",
            "name": "AotM Shadow",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CMHayden/crypto/master/token-shadown.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaverseangels"
            }
        },
        {
            "chainId": 101,
            "address": "F3dfTzx7kxtYE7zioFwRofAhU2xB1JLa3pvUxChQpwu1",
            "symbol": "XPLOIR",
            "name": "Xploir",
            "decimals": 2,
            "logoURI": "https://gitlab.com/b4557/xploir/-/raw/main/assets/xploir_logo_876.png",
            "tags": [
                "crowdfunding",
                "xploir",
                "xploir-index",
                "crypto-development"
            ],
            "extensions": {
                "twitter": "https://twitter.com/xploir"
            }
        },
        {
            "chainId": 101,
            "address": "F9WYDeS9kAjFoXo5raGGpzTE8z7Xnyc9GbsQKuM3986p",
            "symbol": "PTNC",
            "name": "Petenic Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F9WYDeS9kAjFoXo5raGGpzTE8z7Xnyc9GbsQKuM3986p/logo.png"
        },
        {
            "chainId": 101,
            "address": "3MNjcDDJSmvNqvwWZ2RqSo8dA863jGrDNohvCCMkodZJ",
            "symbol": "TRIPPY",
            "name": "TRIPPY token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/d3ebe4df93d7ee04dd3b132579181a5fef90631e/assets/mainnet/3MNjcDDJSmvNqvwWZ2RqSo8dA863jGrDNohvCCMkodZJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "H53UQZCGQnji5QgjJtVNZXBTgxPqNfv9DHjBFT9WC4yE",
            "symbol": "PBBwl",
            "name": "Punk Bear Bando WL",
            "decimals": 0,
            "logoURI": "https://github.com/h3ll0x/pbbwlpic/blob/main/wltoken.png",
            "tags": [
                "community-token",
                "wl-token",
                "whitelist",
                "pbb"
            ]
        },
        {
            "chainId": 101,
            "address": "BhWivnU5MNnTtawhKeZ77ytUF4CxjAYszp2Ypafyshmu",
            "symbol": "IJOT",
            "name": "Tojiba Ijot",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/tojibaceo/tojibaceo/main/ijot2.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tojibaceo"
            }
        },
        {
            "chainId": 101,
            "address": "2thvTG87oytuwp6Egp1jqMAKTyQ5B1aWk46pLsa7Zo2P",
            "symbol": "SXA",
            "name": "SXA Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ArthurPaivaT/token-list/main/assets/mainnet/2thvTG87oytuwp6Egp1jqMAKTyQ5B1aWk46pLsa7Zo2P/logo.png"
        },
        {
            "chainId": 101,
            "address": "PfdcXaR413iHy2uLWvNwkcw8Yp7dxLNp3yfzdba2Nog",
            "symbol": "OGPENFRENS",
            "name": "Pen Frens: OG Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/penfrens/token-list/main/assets/mainnet/PfdcXaR413iHy2uLWvNwkcw8Yp7dxLNp3yfzdba2Nog/logo.png",
            "tags": [
                "community-token",
                "og-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/Dd4tDYG5aj",
                "twitter": "https://twitter.com/pen_frens",
                "website": "https://penfrens.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3mDpFyUquEgoDGJBgAG4b5U8ZsUXEUgSuw1VBBzMaQNs",
            "symbol": "CMDRD",
            "name": "Commander Dante",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kraihn/commander-dante/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DF3EtJzAxySb8WHsD8PFPxZsvzNb3ickn159CB7D21Tw",
            "symbol": "GTWL",
            "name": "Golden Tiger Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AnthonyRamses/crypto/main/wl_token.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6bFz8REykQeYh1JKAdPEUCTCEUfEw32i9a3bzvtacRhy",
            "symbol": "BAZOU",
            "name": "Bazou Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/quoije/bazou_coin/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Bazou Coin ($BAZOU) is the official token for the French-Canadian podcast Le journal d'un podcast.",
                "facebook": "https://www.facebook.com/ljdpodcast",
                "website": "https://ljdp.ca"
            }
        },
        {
            "chainId": 101,
            "address": "FJvkpSpR4y73Hd6yNDvd11ie3kBkHAtNCv922gcYYM3w",
            "symbol": "ODKY",
            "name": "OddKey",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FJvkpSpR4y73Hd6yNDvd11ie3kBkHAtNCv922gcYYM3w/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://oddkey.com"
            }
        },
        {
            "chainId": 101,
            "address": "WoSgyFWVmmEdvfY9Bp5Zrhqu6uas7mUjkiZpEUoBeQH",
            "symbol": "WOSMINT",
            "name": "World of Solana Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WoSgyFWVmmEdvfY9Bp5Zrhqu6uas7mUjkiZpEUoBeQH/logo.png",
            "tags": [
                "wl-token",
                "whitelist"
            ],
            "extensions": {
                "discord": "https://discord.gg/worldofsolana",
                "twitter": "https://twitter.com/worldofsolana",
                "website": "https://www.worldofsolana.io/"
            }
        },
        {
            "chainId": 101,
            "address": "75r7zg51bz7ESrQ9KuDLn3KGX7NKSZgB9UfKdfopHR6m",
            "symbol": "SMINE",
            "name": "Solana Mine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75r7zg51bz7ESrQ9KuDLn3KGX7NKSZgB9UfKdfopHR6m/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/eV9nycnFr8",
                "twitter": "https://twitter.com/solanamine",
                "website": "https://solanamine.io"
            }
        },
        {
            "chainId": 101,
            "address": "PFjQE5mYPTCCDuHz8buxFppyQoL8q32wfTmZbBF5BWL",
            "symbol": "WLPENFRENS",
            "name": "Pen Frens: WL Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/penfrens/token-list/main/assets/mainnet/PFjQE5mYPTCCDuHz8buxFppyQoL8q32wfTmZbBF5BWL/logo.png",
            "tags": [
                "community-token",
                "wl-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/Dd4tDYG5aj",
                "twitter": "https://twitter.com/pen_frens",
                "website": "https://penfrens.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7SuFYqL2hZYutNJkZxA3s6W2MvvfyYQw5ntxU9v6969R",
            "symbol": "SET",
            "name": "Service Exchange Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Toastiam/set/cd2479187f23e8ccc1b1f9c8a1f21b54d562aef9/logo.jpg",
            "tags": [
                "community-exchange",
                "local",
                "community",
                "traiding-services"
            ]
        },
        {
            "chainId": 101,
            "address": "RKwwb3SLUnipcDJaXsaVJPn8dzrcyNNdUwpAUDcRZAT",
            "symbol": "KVWL",
            "name": "Keroverse WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RKwwb3SLUnipcDJaXsaVJPn8dzrcyNNdUwpAUDcRZAT/logo.png",
            "tags": [
                "wl-token",
                "whitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "9CzmA137fzLtdyfaBY63Sa85uY8ZvBiX3MrAJypzsDGR",
            "symbol": "BLUFF",
            "name": "Bluffcoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9CzmA137fzLtdyfaBY63Sa85uY8ZvBiX3MrAJypzsDGR/logo.png"
        },
        {
            "chainId": 101,
            "address": "HgY1nxnPHxrdFMSgVeBtYjw6K31X5VhNsLDuecPD3qwK",
            "symbol": "PTNC",
            "name": "Petenic",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HgY1nxnPHxrdFMSgVeBtYjw6K31X5VhNsLDuecPD3qwK/logo.png"
        },
        {
            "chainId": 101,
            "address": "2ooXiXagYELm9MrFwWT6r5wGPRejmcnbGAD3k8qHCM4X",
            "symbol": "FBTL",
            "name": "FBT Litecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ooXiXagYELm9MrFwWT6r5wGPRejmcnbGAD3k8qHCM4X/logo.png",
            "tags": [
                "IoT-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CyqsrrQXAK6u2VNfRJxN6eL8GUuULZhHMKrDrNmtwuuS",
            "symbol": "HEBTC",
            "name": "Heavy Early Bitcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/HEBTC/main/HEBTC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4i8cpfhjcxQ3kt8sBhr9YvsaE5crmYGscBqYdKmw2EuL",
            "symbol": "L5",
            "name": "L5",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4i8cpfhjcxQ3kt8sBhr9YvsaE5crmYGscBqYdKmw2EuL/logo.png",
            "tags": [
                "L5"
            ]
        },
        {
            "chainId": 101,
            "address": "EyWco8dE87bp9KfTYHayvcrZ2Zi92bbRGs28ZfUGEb7N",
            "symbol": "AMZ",
            "name": "AMAZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EyWco8dE87bp9KfTYHayvcrZ2Zi92bbRGs28ZfUGEb7N/logo.png",
            "tags": [
                "AMAZ"
            ]
        },
        {
            "chainId": 103,
            "address": "2jrVcfazHUiyKTmKQaEyfbEq1ZDga4v1pUHCWkiXCErA",
            "symbol": "ICSOL",
            "name": "Ivan Solana",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2jrVcfazHUiyKTmKQaEyfbEq1ZDga4v1pUHCWkiXCErA/logo.png"
        },
        {
            "chainId": 101,
            "address": "EenMwVUMnqhQjrSBcccCgMXJQVQDQZ3rVFd53hv8gExY",
            "symbol": "POES",
            "name": "People Of Every Society",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jody-wicomb/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "8m96fMrqk5ZZ4UnaA8YBfU6wLPdzmzFRdYsGTdpJS8EF",
            "symbol": "YBUTCHERS",
            "name": "YBUTCHERS token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8m96fMrqk5ZZ4UnaA8YBfU6wLPdzmzFRdYsGTdpJS8EF/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "sotka91WnAeWUbmyBgGbHjhYFML1AX1Nk3xYdvQTMth",
            "symbol": "KNIVES",
            "name": "KNIVES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sotka91WnAeWUbmyBgGbHjhYFML1AX1Nk3xYdvQTMth/logo.png",
            "tags": [
                "KNIVES"
            ]
        },
        {
            "chainId": 101,
            "address": "3MfUyK8FbwbEhPsGqi9pJirKLgQevH27AvH2mhbUGxtj",
            "symbol": "PoSBWind",
            "name": "PoSB-WindToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3MfUyK8FbwbEhPsGqi9pJirKLgQevH27AvH2mhbUGxtj/logo.png",
            "tags": [
                "posb-token",
                "whitelist"
            ],
            "extensions": {
                "description": "Pirates of Sol Bay Crew Token",
                "discord": "https://discord.gg/wEXT6vFKBM",
                "twitter": "https://twitter.com/PiratesOfSolBay",
                "website": "https://www.piratesofsolbay.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FT84xCFrgRbP39Yo49BiDWRii8ytb1f3rHZtQiDkC7sH",
            "symbol": "DN",
            "name": "Digital Nirvana",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FT84xCFrgRbP39Yo49BiDWRii8ytb1f3rHZtQiDkC7sH/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3uytqzAsQnTVZxDEz9u7yvgidvQCteHzWsoVUVFps1XR",
            "symbol": "AANDELHOUDER",
            "name": "Kaat Riche te Brasschaat",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3uytqzAsQnTVZxDEz9u7yvgidvQCteHzWsoVUVFps1XR/Kaat.png",
            "tags": [
                "aandelen",
                "aandeelhouders"
            ],
            "extensions": {
                "website": "https://amsterdam-lawyers.nl/belgian-company-bv/"
            }
        },
        {
            "chainId": 103,
            "address": "4v3o1iGc7kSuiCHJsmkqkEfP2pq7rAS9Dx4qR6vw1fke",
            "symbol": "YFINANCIAL",
            "name": "YFINANCIAL token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4v3o1iGc7kSuiCHJsmkqkEfP2pq7rAS9Dx4qR6vw1fke/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HVkFqcMHevVPb4XKrf4XowjEaVVsBoqJ2U1EG59Dfk5j",
            "symbol": "VISION",
            "name": "VisionGame",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HVkFqcMHevVPb4XKrf4XowjEaVVsBoqJ2U1EG59Dfk5j/logo.png",
            "tags": [
                "game-publishing"
            ],
            "extensions": {
                "description": "Ultimate Gaming Experience Amplified by Blockchain",
                "medium": "https://medium.com/@visiongame",
                "solanium": "https://www.solanium.io/project/visiongame/",
                "telegram": "https://t.me/visiongame_official",
                "telegramAnnouncements": "https://t.me/visiongame_ann",
                "twitter": "https://twitter.com/visiongame_",
                "website": "https://visiongame.io/"
            }
        },
        {
            "chainId": 101,
            "address": "MCDsTXbZe87sWezWJ3B8Sw63PUjq3BXzRmY7zptoYr2",
            "symbol": "TMDC",
            "name": "The Monkey Design Club",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/namu42/ImageRepo/main/MCL9utLXFvUMdWLou4jk13BBgTu2XRGM983eZ24RsTP/logo.png",
            "tags": [
                "TMDC",
                "THEMONKEYDESIGNCLUB",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "AUXXw8iRvSb57upRB74xVrUiPWpfoxzKgRs9j2fEzsVC",
            "symbol": "AANDEELHOUDER",
            "name": "Dirk Willems te Antwerpen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AUXXw8iRvSb57upRB74xVrUiPWpfoxzKgRs9j2fEzsVC/dirk.png",
            "tags": [
                "aandelen",
                "aandeelhouders"
            ],
            "extensions": {
                "website": "https://amsterdam-lawyers.nl/belgian-company-bv/"
            }
        },
        {
            "chainId": 101,
            "address": "DH6NkYYTuLWUu5gj1Q4JdgNV6Nu6Sji9VWgrMMPP6gy7",
            "symbol": "AANDEELHOUDER",
            "name": "Ann Willems te Antwerpen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DH6NkYYTuLWUu5gj1Q4JdgNV6Nu6Sji9VWgrMMPP6gy7/ann.png",
            "tags": [
                "aandelen",
                "aandeelhouders"
            ],
            "extensions": {
                "website": "https://amsterdam-lawyers.nl/belgian-company-bv/"
            }
        },
        {
            "chainId": 101,
            "address": "CRSzWoeyfR8sJxB2d6LLEre92Uc59TCPX2gZidp4t3eE",
            "symbol": "AGVZ",
            "name": "Agave Zwolf",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GrupoZwolf/cryptozw/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7KxC287omw6QrKQfKNUQs2kmgrTtakVcKriUAHpkur9m",
            "symbol": "CRISTO",
            "name": "Cristomoneda",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cristomoneda/cristomoneda.png/main/cristomonedalogo.png",
            "tags": [
                "community-token",
                "wl-token",
                "utility-token",
                "christian",
                "church",
                "jesus",
                "christ",
                "cristo",
                "cristomoneda",
                "iglesia"
            ]
        },
        {
            "chainId": 101,
            "address": "AnjuLVbYvrhZZSU9Eiqj4d7TdYEmHJRuqdes8Cn7PfS9",
            "symbol": "CMARC",
            "name": "CodeMarcs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/codemarc/codemarks/master/assets/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "4ek283kahxjzEs13sM3qDTUCPD15iFQ1DzVfAMQYpXPJ",
            "symbol": "RDT",
            "name": "Rep Devnet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlexMGN/assets/develop/assets/Repti_icon.png"
        },
        {
            "chainId": 101,
            "address": "GcYico8V5ycBi9VM2z1VQ2abvCSp6JggRDkmMEqKTUHe",
            "symbol": "WRNH",
            "name": "Wrench",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/chiefsoft/wrench/main/wrench_token.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ogtp2SQvRn5vs4YfgQ8BQ5h2rbfjPZzux4r99TZ62yy",
            "symbol": "SHOG",
            "name": "Solana Hills OG Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ogtp2SQvRn5vs4YfgQ8BQ5h2rbfjPZzux4r99TZ62yy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WL12AGjU2zs5myyrUzBmSDF5cMG9xMAcZ8kMYnre2ns",
            "symbol": "SHWL1",
            "name": "Solana Hills Whitelist-1 Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL12AGjU2zs5myyrUzBmSDF5cMG9xMAcZ8kMYnre2ns/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WL2jnbxfHqQ6SoHrfA4QXEx9vw8gNyJwSTfgtvvuoKD",
            "symbol": "SHWL2",
            "name": "Solana Hills Whitelist-2 Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL2jnbxfHqQ6SoHrfA4QXEx9vw8gNyJwSTfgtvvuoKD/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "T3Z3238ujJZsaJE3jh31Df5YPnd6wJbHzQ7XdfoMVnt",
            "symbol": "BHWL",
            "name": "Bongheads White List Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kumia115/nft-landing-page/main/images/x-icon/android-chrome-512x512.png",
            "extensions": {
                "twitter": "https://twitter.com/BongHeadsNFT",
                "website": "https://bongheads.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BueMgcdgzFRKdUMLgKX3DTR8s7BxpHrYm7R1L8LPF9oa",
            "symbol": "BE",
            "name": "Brave Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/audreydeyniera/aset/main/logo.jpeg",
            "tags": [
                "staking"
            ],
            "extensions": {
                "discord": "https://discord.gg/2xmTnvb5",
                "twitter": "https://twitter.com/BraveApeDerug"
            }
        },
        {
            "chainId": 103,
            "address": "AecwgXCfcCZYa15BXGjnpg5jaAWXneCbWEXJmotf4Q5Q",
            "symbol": "ODKYDEV",
            "name": "OddKey (devnet)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AecwgXCfcCZYa15BXGjnpg5jaAWXneCbWEXJmotf4Q5Q/logo.png",
            "tags": [
                "utility-token",
                "devnet"
            ],
            "extensions": {
                "website": "https://oddkey.com"
            }
        },
        {
            "chainId": 101,
            "address": "BpaoPZUKR3gP4SMVJja3itp3LRAxTMwovZmKBbtzWdyB",
            "symbol": "MINTUS",
            "name": "MintUS Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BpaoPZUKR3gP4SMVJja3itp3LRAxTMwovZmKBbtzWdyB/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/onlymintus"
            }
        },
        {
            "chainId": 101,
            "address": "BYtxPHFNdcTk9HZChrRtwWN73PKMW3RKdpeC2pZfhkrq",
            "symbol": "73PM",
            "name": "73Prime",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ahplummer/73primelogo/main/73Prime-yellow-simple-256.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "488DGeMxRHmofoEtfrPE6geMT7EzwP9HMn76cDq5ZKwt",
            "symbol": "LONGAN",
            "name": "Longan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fungtoken/Longan/main/Longan.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BVCwkyqS5Xu1taHLtZVEBmw4NEfkxcYe2ZEJH41DZD7c",
            "symbol": "OJC",
            "name": "Ojciec Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lineekk/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WhiLmuEcmdAfhvJ1zhitX4z96Zb1EGUBkKbTWYrrUd6",
            "symbol": "HSHN",
            "name": "Hidden Shinobi WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WhiLmuEcmdAfhvJ1zhitX4z96Zb1EGUBkKbTWYrrUd6/logo.png"
        },
        {
            "chainId": 101,
            "address": "3FRE6YQXnEZKbsKBUW4EkvsqkVpoQDs4W2gEUsQRxfDp",
            "symbol": "ZWD",
            "name": "ZIMBABWE DOLLAR TOKEN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/opals99/ZWD-SOL/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DmqnqcT2fqzzWup6o4CyTiErCBzsrV9M19zxZyeFYo6Z",
            "symbol": "STACHE",
            "name": "Mustache Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rajyyan/mustache/main/stachelogo.png",
            "tags": [
                "value-token",
                "collectible-token"
            ],
            "extensions": {
                "website": "http://mustachetoken.space"
            }
        },
        {
            "chainId": 101,
            "address": "H2mUUthn7jzr1rShRYcsvbidQD3UFfyQ6vMGceUDn5Ew",
            "symbol": "PEARL",
            "name": "Pearl Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fungtoken/pearl/main/Pearls.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7CSuSUufuNSaV6vqmNnftJgPWT23RFQjgoJfWqFPAB3U",
            "symbol": "QMBY",
            "name": "Quimby",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rorymcpedal/cryptocoin/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Wto5r1vzhhgXvRsYWW4PkpS68JW6NURGCDtVBGCVAKc",
            "symbol": "SOCK",
            "name": "Socks Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dashgotsocks/crypto/main/logo.png?token=GHSAT0AAAAAABSJXBBV6U6P3BOSIIJV3GYCYRQ7AVA",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Eqekt4QF8zy9X6MZn5JUa6YYAz5MMgo7ZV5ZyX7YtjJW",
            "symbol": "SMU",
            "name": "Solana Monkey University Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Tagzie/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "YFmGQB86pWYmNo1VN2kK2enMf47tQiBiXakU4MgikRh",
            "symbol": "REG",
            "name": "RegCoin",
            "decimals": 0,
            "logoURI": "https://github.com/Reg1337/RegCoin/raw/main/ae1eeb837b7640bea60f6f213718ac99.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DVWBLVL9DqMSkgb8WfuWW23dfe88h4hRK1r7hpmvhNuE",
            "symbol": "FUNNY",
            "name": "MetaLaughs",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DVWBLVL9DqMSkgb8WfuWW23dfe88h4hRK1r7hpmvhNuE/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "instagram": "http://instagram.com/metalaughs_io",
                "telegram": "https://t.me/metalaughs",
                "twitter": "https://twitter.com/metalaughs"
            }
        },
        {
            "chainId": 101,
            "address": "ToBNsrnbNuDzowzt1y3zLUMEndMTFqTFngM22mojHX3",
            "symbol": "SKILL",
            "name": "SKILL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/NinjaApe/image/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G4ASTCiHKeM2JNbefruTbwPTQ8z7SnmPUVC1xAUcaS7a",
            "symbol": "SOLGAT",
            "name": "Solgats WL Token",
            "decimals": 0,
            "logoURI": "https://solgats.io/images/solgats.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solgats",
                "website": "https://solgats.io"
            }
        },
        {
            "chainId": 101,
            "address": "BiBNvJTnA4HZraqfex7mqLzkac5Nv9RuZY73PHnitfgD",
            "symbol": "BABY",
            "name": "BABY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BiBNvJTnA4HZraqfex7mqLzkac5Nv9RuZY73PHnitfgD/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CSj94sJsnWf9TGKkV7gD2SqVr9y38SqaYmi7qekAyKai",
            "symbol": "BULL",
            "name": "Bull Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/h3ll0x/bullimage/main/bulltoken.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DriP77HQDnSyT478K4wrYTjKFdvbaCyQnEKpAkq87GtR",
            "symbol": "DRIP",
            "name": "Drippies WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DriP77HQDnSyT478K4wrYTjKFdvbaCyQnEKpAkq87GtR/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/drippieverse",
                "website": "https://www.drippies.space"
            }
        },
        {
            "chainId": 101,
            "address": "5aS5Uuo5dt8Dut7FZfpaPhUjoih14GiEs1P4yhYGqA69",
            "symbol": "DDToken",
            "name": "Double D Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tcryptocoins/spltoken/main/dd-token-v4.svg",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7xBLAJQ9TDnBXZ6cEcLiq7XtwneiCU5b94b569ryBEqP",
            "symbol": "BENZ",
            "name": "BENZ Token",
            "decimals": 0,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmR5FQVrGg7ySX3BXxPsMzPrBR2j2RWnDKVavReKbKV79W",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DriPBT5PbN2vuYXQgz1jbar5Pi2adzo2d447UrV8fvTc",
            "symbol": "DRP",
            "name": "Drippies SOL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DriPBT5PbN2vuYXQgz1jbar5Pi2adzo2d447UrV8fvTc/logo.png",
            "tags": [
                "whitelist-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "DuLnzJo4emVer2j9Gjy8ce5w6ASzWUeSbCYemvEg8cJZ",
            "symbol": "TSS",
            "name": "TSS COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CoachAlpha/TSS-COIN/main/tss_coin.png",
            "tags": [
                "minecraft",
                "tss",
                "server"
            ],
            "extensions": {
                "discord": "https://discord.gg/HtksU8ukZD"
            }
        },
        {
            "chainId": 101,
            "address": "pizZaxdr2KyMtdgWBXGU32Ti88H3bCr98z6gQZx8VYY",
            "symbol": "PIZZA",
            "name": "PIZZA",
            "decimals": 12,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pizZaxdr2KyMtdgWBXGU32Ti88H3bCr98z6gQZx8VYY/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/TurtleZ_sol"
            }
        },
        {
            "chainId": 101,
            "address": "5diZKwPDsfcqCs8kYEU4AM1cCufZRTK7b77djF5LmXyo",
            "symbol": "$MARSH",
            "name": "Marsh",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5diZKwPDsfcqCs8kYEU4AM1cCufZRTK7b77djF5LmXyo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.marshiesnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ASoKEceyYDNRvhZmdoELBZKNc4VhKwsSHkx9v3yR1gTD",
            "symbol": "CINE1",
            "name": "Cineoncerts NFT 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cineconcerts/NFT_Token_1/main/Cine_Solana_Token_Logo.png",
            "tags": [
                "cineconcerts"
            ]
        },
        {
            "chainId": 101,
            "address": "CatsMsgyV6ecnvztHkceEAXmhy9oYhAS4tT3Yyi6xHAf",
            "symbol": "PEBLO",
            "name": "Cats On Crack WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CatsMsgyV6ecnvztHkceEAXmhy9oYhAS4tT3Yyi6xHAf/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/catsoncrack_2",
                "website": "https://www.catsoncrack.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "nYDqQVEaQxLYLh8B8oAFXziMT1bcGrAVigZPL1s3dKc",
            "symbol": "PREY",
            "name": "PREY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nYDqQVEaQxLYLh8B8oAFXziMT1bcGrAVigZPL1s3dKc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ApexPredatorSOL",
                "website": "https://home.apexpredator.army/"
            }
        },
        {
            "chainId": 101,
            "address": "9U8Bn6zAf6Wyp1YHdXtLyfbN7yMvdvW1qQY475iZ5ftZ",
            "symbol": "GIGS",
            "name": "GigaDAO",
            "decimals": 4,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmUz2npdTWpFWBYiRCBN4zXMZFBRL9w5yNBNaSCXMTJ9T9",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CAtsAzbpAf4sFt5Qh5kguJqb1UJavWENnHmkCv8T9hvU",
            "symbol": "CAT",
            "name": "Official Cats On Crack WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAtsAzbpAf4sFt5Qh5kguJqb1UJavWENnHmkCv8T9hvU/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/catsoncrack_2",
                "website": "https://www.catsoncrack.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "DKHzgFWPRoGqzsbnk6bmaVw8t32tujJsqCMiMJ5PpmR4",
            "symbol": "AVAG",
            "name": "Avago",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/tecfay/token/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DZMFqjobcvXyaaggDt1MxiLZyhqki67NuZRfiMJwczNq",
            "symbol": "$BA",
            "name": "Banana Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DZMFqjobcvXyaaggDt1MxiLZyhqki67NuZRfiMJwczNq/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9NGd8kUraGJPGEpN2Tk61JuU7oJ4h2ererDuw7WDHcTP",
            "symbol": "M11N",
            "name": "MI11ENNIUM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wiseblue32/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7u2AsUouSsgvWgJrzYej9iu8sABgYgJseEuFp6228TLk",
            "symbol": "WERSA",
            "name": "Twersa",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7u2AsUouSsgvWgJrzYej9iu8sABgYgJseEuFp6228TLk/logo.png",
            "tags": [
                "Twersa"
            ]
        },
        {
            "chainId": 101,
            "address": "BRTsHXpCdVr8uaMBE5D9SsCS1d5o98ZSnT2Z9EaGryrM",
            "symbol": "LMNS",
            "name": "Luminus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRTsHXpCdVr8uaMBE5D9SsCS1d5o98ZSnT2Z9EaGryrM/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DaMaterLuminus",
                "website": "https://damater.web.app/"
            }
        },
        {
            "chainId": 101,
            "address": "4ajycEvJN5BScuECdaDsYuqS88XPShDmxCToZBrZT2hY",
            "symbol": "DaMTR",
            "name": "DaMater",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ajycEvJN5BScuECdaDsYuqS88XPShDmxCToZBrZT2hY/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DaMaterLuminus",
                "website": "https://damater.web.app/"
            }
        },
        {
            "chainId": 101,
            "address": "guYHCq2ZhdXNrDodWoiyCrJYm7yjLTXxETWbGiG32VU",
            "symbol": "RAFS",
            "name": "RA Fitness Science",
            "decimals": 0,
            "logoURI": "https://github.com/GTLB13/RA-TOKEN/blob/main/RA.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dm99YNXbJXzf2tDkNQMNwKY19Jop3C6fXQawLHBFbHi2",
            "symbol": "$HIME",
            "name": "HIME",
            "decimals": 9,
            "logoURI": "https://api.fgraph.io/storage/images/logos/himeiconv2.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "7ktA2FhAp93mKgX6gPRdFpD4Kb7bAUJsMGvEmRVhF8RG",
            "symbol": "YSBET",
            "name": "YSBET token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ktA2FhAp93mKgX6gPRdFpD4Kb7bAUJsMGvEmRVhF8RG/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z",
            "symbol": "NEAR",
            "name": "NEAR (Allbridge from Near)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z/logo.png",
            "extensions": {
                "coingeckoId": "near"
            }
        },
        {
            "chainId": 101,
            "address": "6CgrvodLPX2pfHGj4FDVLWgUAxGFSXJTDgZvfFBifD8p",
            "symbol": "xHXD",
            "name": "xHXD Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6CgrvodLPX2pfHGj4FDVLWgUAxGFSXJTDgZvfFBifD8p/logo.png"
        },
        {
            "chainId": 103,
            "address": "BR7JoJSBTdYDxTxnxsgkn47LVT7oAftBE4Jk11tZNww7",
            "symbol": "YMAKERS",
            "name": "YMAKERS token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BR7JoJSBTdYDxTxnxsgkn47LVT7oAftBE4Jk11tZNww7/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Ci2uQFhWvPuEupoBpmh7EJG8eTepDYrfoPgu7MKCddi",
            "symbol": "RSC",
            "name": "RESOURCE PROTOCOL",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Ci2uQFhWvPuEupoBpmh7EJG8eTepDYrfoPgu7MKCddi/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CVUeU96k6Lc2z4Qe3R6n4xkhcxmZKFHxmU4HzoTN3cF",
            "symbol": "DOOG",
            "name": "Doogles Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CVUeU96k6Lc2z4Qe3R6n4xkhcxmZKFHxmU4HzoTN3cF/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DscTwD3ozNrW6xhbwxqBMur36vvUkLTmDrJBdaCJvaTW",
            "symbol": "LCD",
            "name": "Lazy Crazy Ducks Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DscTwD3ozNrW6xhbwxqBMur36vvUkLTmDrJBdaCJvaTW/logo.png",
            "tags": [
                "Whitelist-token",
                "LCD"
            ]
        },
        {
            "chainId": 101,
            "address": "9oM4Bg2E8y8LkGbaJhd27tcbto4KpjvXHubG2LEoRbQL",
            "symbol": "PB",
            "name": "CoC public sale token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9oM4Bg2E8y8LkGbaJhd27tcbto4KpjvXHubG2LEoRbQL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://catsoncrack.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "DF4LAwfNPMdX5a9mBAFxA1amBZKJ9HtTQPQqDCDcvvMg",
            "symbol": "F6T",
            "name": "F6 Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DF4LAwfNPMdX5a9mBAFxA1amBZKJ9HtTQPQqDCDcvvMg/logo.png"
        },
        {
            "chainId": 101,
            "address": "BqgeGmnGN8oRfLmZVFStUnX9KpVjYiaPFm6TcVZUowWy",
            "symbol": "TSTCN",
            "name": "TestCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BqgeGmnGN8oRfLmZVFStUnX9KpVjYiaPFm6TcVZUowWy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HS2DanDUPKEnkzXDywtQKAqWKbWte3ahvwMhcfBJjGai",
            "symbol": "WWOLF",
            "name": "WWOLF Token",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/HS2DanDUPKEnkzXDywtQKAqWKbWte3ahvwMhcfBJjGai/logo.png?raw=true"
        },
        {
            "chainId": 103,
            "address": "Fnbmb9Zcu2rzvXuj6rEnFLnVUtDm5ryofAFDvXWpniSJ",
            "symbol": "YSBULLS",
            "name": "YSBULLS token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fnbmb9Zcu2rzvXuj6rEnFLnVUtDm5ryofAFDvXWpniSJ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ax6SfYCx6iZLarSkUeJibeF4dEWyA5nNWiEE21ov6imU",
            "symbol": "J28",
            "name": "j28905",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/j28905/cryptoBros/main/crypto_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3qdTxpYhfCeoJMG4obhBZ24fB4SRuBMqGwAN35YzpRCR",
            "symbol": "wsPNG",
            "name": "wsPENG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/peddyiscrazy/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "88nvLwiD7wDgKoT1LvLa4qRkUUo2unZi1qtjtu7R7Kiu",
            "symbol": "OLIV",
            "name": "Oliv test token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88nvLwiD7wDgKoT1LvLa4qRkUUo2unZi1qtjtu7R7Kiu/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DxfEB8qDoyCgoGr3qZyU5iK2hKuRGjaEooHs3eyb9P8k",
            "symbol": "02",
            "name": "Outland Odyssey Token",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DxfEB8qDoyCgoGr3qZyU5iK2hKuRGjaEooHs3eyb9P8k/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EABadtoken"
            }
        },
        {
            "chainId": 101,
            "address": "4Q82kdinwF7MsTgiXuvkF8SMwchkrhcWc4PMytAcYr23",
            "symbol": "HRSW",
            "name": "Hashrate Servers Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Q82kdinwF7MsTgiXuvkF8SMwchkrhcWc4PMytAcYr23/logo.png",
            "extensions": {
                "discord": "https://discord.gg/hashrateservers",
                "twitter": "https://twitter.com/HashrateServers"
            }
        },
        {
            "chainId": 101,
            "address": "BUduUSJDmTpEUs3di9NMPf38zRz1HMHqEtWAebpYwyVz",
            "symbol": "HSWL",
            "name": "Hashrate Servers Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUduUSJDmTpEUs3di9NMPf38zRz1HMHqEtWAebpYwyVz/logo.png",
            "extensions": {
                "discord": "https://discord.gg/hashrateservers",
                "twitter": "https://twitter.com/HashrateServers"
            }
        },
        {
            "chainId": 103,
            "address": "54kUGptgonbnr6YjzQcgduScBcPkNTKRffraSxsVHc4J",
            "symbol": "YOCs",
            "name": "YOCs token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54kUGptgonbnr6YjzQcgduScBcPkNTKRffraSxsVHc4J/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PLAyKbtrwQWgWkpsEaMHPMeDLDourWEWVrx824kQN8P",
            "symbol": "SolPlay",
            "name": "SolPlay",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PLAyKbtrwQWgWkpsEaMHPMeDLDourWEWVrx824kQN8P/logo.png",
            "tags": [
                "Gaming"
            ],
            "extensions": {
                "website": "https://solplay.de"
            }
        },
        {
            "chainId": 101,
            "address": "Eb3xrjvR3Gu1MybG7YoR7QZPDQQsSnw5kmZgej9xTW5R",
            "symbol": "TROPHY",
            "name": "TROPHY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GRacersClub/crypto/main/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Z9zQmpFGQ7e5tHFyqtxmD4EJJ3Cj5F5sFrnJA2Lwn8X",
            "symbol": "PINO",
            "name": "PinoCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CargoBlitz/cryptopino/main/Logopit_1646826988688.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "rfhZiDve7ZC25suwzVdZYr2J1DvuEm15gXZaBgeCf9X",
            "symbol": "DUSD",
            "name": "DOME USD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DrMagazi/token-list/main/assets/mainnet/rfhZiDve7ZC25suwzVdZYr2J1DvuEm15gXZaBgeCf9X/logo.jpg",
            "tags": [
                "stablecoin",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DsTJzQaef6hX7STtZy6ThQhej3XNPMcLKHpPVUENYa35",
            "symbol": "HOC",
            "name": "HouseOfCards",
            "decimals": 6,
            "logoURI": "https://i.ibb.co/rHrVgdT/logo.png"
        },
        {
            "chainId": 101,
            "address": "Dudp8epBnyrqqj3ZV8qjhyxtnjUAhRGLB9qCtJqFV9EV",
            "symbol": "PACA",
            "name": "PACA",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/Dudp8epBnyrqqj3ZV8qjhyxtnjUAhRGLB9qCtJqFV9EV/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "He6G3aKAykVMRczNVf8F6LYquaocWSjCQ5vwn1P3X5qM",
            "symbol": "TPY",
            "name": "Trust Pay",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TrustPayCoin/Crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8TACnwvkQTBNkBux81R6WunjGkUPCkaUK7ae9c7Lu4fA",
            "symbol": "RPCC",
            "name": "Red Pill Coaching Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/F03nX/crypto-RPC/main/np_drugs_1863865_ED1C25.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FvbzTav9EZY9LLoCcrW665urLsRJ87asVFZMsTA8hQwd",
            "symbol": "GOD",
            "name": "God's Obol",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/matthewrx/godtokenlogo/main/logo.png",
            "tags": [
                "Gaming"
            ],
            "extensions": {
                "discord": "http://discord.gg/solgods",
                "twitter": "https://twitter.com/SOLgods_",
                "website": "https://thefracture.art/"
            }
        },
        {
            "chainId": 101,
            "address": "2rBgtDbKoqHg2PsdnHjreBJJwiNHwvvMfpjAF4kvyDcB",
            "symbol": "J9BC",
            "name": "J9 Casino",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2rBgtDbKoqHg2PsdnHjreBJJwiNHwvvMfpjAF4kvyDcB/j9.jpg",
            "tags": [
                "Gamble",
                "Stake-Token",
                "Cross-Chain"
            ],
            "extensions": {
                "twitter": "https://twitter.com/j9_global",
                "website": "https://j9.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4RyUEWXJemmMpoWpr9xjYuzMLDbcM6DuLkojibFaufDy",
            "symbol": "LINEK",
            "name": "Linek",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lineekk/crypto/main/Linek/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FDo4TPENcKdHtWMV56PSEjJmFfWUSRotLgW1EbPHreCB",
            "symbol": "bEVRY",
            "name": "EVRY (Binance chain)",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDo4TPENcKdHtWMV56PSEjJmFfWUSRotLgW1EbPHreCB/evry.png",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EvryNetwork",
                "website": "https://evrynet.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6TSyg6kXgz2rtSbZQcnhoGV3VqNsRBbSVxWrg5cwkSw7",
            "symbol": "shCP",
            "name": "Shapz Compute Power",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TSyg6kXgz2rtSbZQcnhoGV3VqNsRBbSVxWrg5cwkSw7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://shapz.org"
            }
        },
        {
            "chainId": 101,
            "address": "5kANAUeHsoambmdV317Nhs8puVxfukyChr9j9TZ8ZeQq",
            "symbol": "DoSC",
            "name": "Dreams of SolCandy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5kANAUeHsoambmdV317Nhs8puVxfukyChr9j9TZ8ZeQq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://dreamsofsolcandy.com/"
            }
        },
        {
            "chainId": 103,
            "address": "9S1wCdvYgrCus7jXyLxaZP3ovSJrYDvocAEhPq8WgxXC",
            "symbol": "RTDT",
            "name": "Repti TT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9S1wCdvYgrCus7jXyLxaZP3ovSJrYDvocAEhPq8WgxXC/Repti_icon.png"
        },
        {
            "chainId": 101,
            "address": "BEuhuvH3FQYCoMqBHknyXpSjcMYN3fzZbZtf11KyWKqy",
            "symbol": "HIKI",
            "name": "Hikicoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEuhuvH3FQYCoMqBHknyXpSjcMYN3fzZbZtf11KyWKqy/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6MXoebdRr4qZmeCdzEc5Dtk8XmX3mBUrzgtEYwCKqxzk",
            "symbol": "OxH",
            "name": "OriginX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/OriginXHeroes/Token/main/assets/%24OriginX.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9jyC8HjUBUgEWvZ6XUNbtUtiMthDVJkfhgp9aUatfWq3",
            "symbol": "THAUM",
            "name": "CYPHERZ THAUM TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9jyC8HjUBUgEWvZ6XUNbtUtiMthDVJkfhgp9aUatfWq3/logo.png",
            "tags": [
                "DeFi",
                "NFT",
                "community-token",
                "Metaverse"
            ],
            "extensions": {
                "description": "Thaum is the in-game and DeFi token of the Cypherz NFT Pryzmverse. Check our Medium Page for Tokenomics",
                "twitter": "https://twitter.com/CypherzNFT",
                "website": "https://CypherzNFT.io"
            }
        },
        {
            "chainId": 101,
            "address": "FC4p7fFtfCSYfx6zvCnmnLtKF1Kmfsn5qvHwk9LXxSV8",
            "symbol": "BUBI",
            "name": "bubi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/X-xhesild/crypto/main/BUBI.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HxocLoGoaaz2V4nS8gpV93us2PbPkNRcTpDrmruk1Fxd",
            "symbol": "BTXS",
            "name": "BTXSOL",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HxocLoGoaaz2V4nS8gpV93us2PbPkNRcTpDrmruk1Fxd/logo.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "website": "https://sol.btx.world/"
            }
        },
        {
            "chainId": 101,
            "address": "EmoRBYmLPDTUYyrpm5LTMfRgjJYz7LUFM685PH6jLTUE",
            "symbol": "EMORB",
            "name": "Emorb",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EmoRBYmLPDTUYyrpm5LTMfRgjJYz7LUFM685PH6jLTUE/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/emorb",
                "twitter": "https://twitter.com/EmorbNFT",
                "website": "https://www.emorb.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3UXCdNbWBvSt5GfrZPXBFmFJYeX26wJQWBKMd3J5jVR8",
            "symbol": "FJZ",
            "name": "Francisco Jose Zangerolame",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/fzangerolame/crypto/main/zangerolame.png",
            "extensions": {
                "twitter": "https://twitter.com/FjzUsa",
                "website": "https://zangerolame.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BJJohVbGmLbM8EEzT1CH7N6fg2AjEAAgb5qrRnNYdD1j",
            "symbol": "TBILL",
            "name": "NFTokers Treasury Bill Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MoonCandy69/crypto5/main/tokerlogo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nftokers"
            }
        },
        {
            "chainId": 101,
            "address": "4eW4TtBfyCs6AwfN4ZRKSjL8eGaukr9VoBaLBzphxJLK",
            "symbol": "CORA",
            "name": "Cotorra Coin",
            "decimals": 9,
            "logoURI": "https://lacotorraradio.s3.us-east-2.amazonaws.com/CotorraCoin.png",
            "tags": [
                "social-media",
                "multimedia-token",
                "multiverse-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WqmXQ8bq7n",
                "twitter": "https://twitter.com/cotorraradio1",
                "website": "https://lacotorraradio.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8DSstVvEPDg3uwsPCXrFkdxJQARhaN5GoXeMCAX8AiK5",
            "symbol": "BAWK",
            "name": "BawkBucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Player172/BawkBucks/main/bawkbuckslogo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ZDLvGwEaGtpkPZEXmjJrPwahMYuAcs3YHdhTWEWbyo2",
            "symbol": "$BTXS",
            "name": "BTXSOL MIRROR 1",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ZDLvGwEaGtpkPZEXmjJrPwahMYuAcs3YHdhTWEWbyo2/logo.png",
            "tags": [
                "mirror-token"
            ],
            "extensions": {
                "website": "https://sol.btx.world/"
            }
        },
        {
            "chainId": 101,
            "address": "73dCbT3M8UiTd1PQwunTxc2UWnMeLLWmNT3KkbvHyLGP",
            "symbol": "BTXSOL",
            "name": "BTXSOL MIRROR 2",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/73dCbT3M8UiTd1PQwunTxc2UWnMeLLWmNT3KkbvHyLGP/logo.png",
            "tags": [
                "mirror-token"
            ],
            "extensions": {
                "website": "https://sol.btx.world/"
            }
        },
        {
            "chainId": 101,
            "address": "8uD8yx7pXCvX1J1AmdN7jLZh7rAGUE4zNtwT7UtY4rvm",
            "symbol": "CSST",
            "name": "CS Stake Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8uD8yx7pXCvX1J1AmdN7jLZh7rAGUE4zNtwT7UtY4rvm/logo.jpg",
            "tags": [
                "stake-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GFzwhDUKNoQPLAwP3p5xChw9GDnwZ5ZNMhjbWKw9KB7p",
            "symbol": "CREEPY",
            "name": "CREEPY SOULS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/cd9e50d252cc84da29ecc459b547e32083e84134/assets/mainnet/GFzwhDUKNoQPLAwP3p5xChw9GDnwZ5ZNMhjbWKw9KB7p/logo.png",
            "tags": [
                "stake-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fc1DRrjQFNJ4Ym5GaC4B6dUvVPRGzdgffEWYmc2PktRv",
            "symbol": "RESCUE",
            "name": "Rescue DAO Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sinnocent26/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "A decentralized coin created to fund local solidarity projects all over the world.",
                "discord": "https://discord.gg/UpRKQpTQ",
                "twitter": "https://twitter.com/Rescue_DAO",
                "website": "https://rescuedao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EHwLnuVJLLejkJMSE1iJgj5uDCBPDgb59zmvMWFt12H4",
            "symbol": "$GANG",
            "name": "Infected Gang",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/InfectedGangNFT/assets/main/infected%201.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "website": "https://infectedgang.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Ha8pzMaX6shu6N3pCehkGZ1nNuryUn24qh14xr4cgvYR",
            "symbol": "SAMO",
            "name": "Samo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/samuel-yeebiyo/solana-img/main/logo.png",
            "tags": [
                "stake-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hx6Ns9RwaK4SXFVtjrErKc5afMpS9FRtjYtsZnVaiyTX",
            "symbol": "$BTXSOL",
            "name": "BTXSOL MIRROR 3",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hx6Ns9RwaK4SXFVtjrErKc5afMpS9FRtjYtsZnVaiyTX/logo.png",
            "tags": [
                "mirror-token"
            ],
            "extensions": {
                "website": "https://sol.btx.world/"
            }
        },
        {
            "chainId": 101,
            "address": "J5QR7ewBbtGnzmSjL2ARcZ2fEg5TjmEV2653qFtMQ9qe",
            "symbol": "BEAN",
            "name": "BeanBucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thatpokemontrainer/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BoyzuwdnAfPRHE4hZEeKVYLMWUVjkucu55FgmZ59Y41W",
            "symbol": "BOYZ",
            "name": "Official Hidden Boyz TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoyzuwdnAfPRHE4hZEeKVYLMWUVjkucu55FgmZ59Y41W/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/hiddenboyznft"
            }
        },
        {
            "chainId": 101,
            "address": "H4FamHEaSg27xhxsDEtTbg9zp2EcanwgzECiD9ChnKbP",
            "symbol": "OWC",
            "name": "Orions Wick Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Avoices/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3y9CzNkzS51JzUTMKQCZaRvksNH9qXUc24Pgx35BJ8K5",
            "symbol": "CINEVIP1",
            "name": "Cineconcerts VIP 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cineconcerts/cine_NFT_tokens/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://cineconcerts.com"
            }
        },
        {
            "chainId": 101,
            "address": "5XTEN4ZQJ5BpLLz7ismC7PbDyugpXqcUsZcMHtTs3aW2",
            "symbol": "KCC",
            "name": "Kids Crypto Currency",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Toastiam/crypto/main/KCC/logo.png",
            "tags": [
                "allowance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GA9qsjRTgLHdu1xdMKZm274qLgbYYEe3PqfjMvPqw2c4",
            "symbol": "AZC",
            "name": "Azalea Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Azalea-Ray/AZC/main/azc_logo.png",
            "tags": [
                "invest-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DFRRxGLsdBNeLBpUmD1iLWbYjvi5kJp7ya8XgzNZoXJK",
            "symbol": "TRLC",
            "name": "Trilactic Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheyCallMeAlpha/token-logo/main/tokenlogo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5BY9wMqpvmTJrzUHt9BAmaQwave833DyHZh33rCXFEQ2",
            "symbol": "GLXY",
            "name": "ASTRALS GLXY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5BY9wMqpvmTJrzUHt9BAmaQwave833DyHZh33rCXFEQ2/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/astralsnft",
                "twitter": "https://twitter.com/Astrals_NFT",
                "website": "https://astrals.io"
            }
        },
        {
            "chainId": 101,
            "address": "2aLMD9KMQpZe1XLaZL5znEnRzprmb8Jjd2hws3GrSxeK",
            "symbol": "MIRL",
            "name": "MIRL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2aLMD9KMQpZe1XLaZL5znEnRzprmb8Jjd2hws3GrSxeK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ve4UA2Xc",
                "twitter": "https://twitter.com/mirlnft",
                "website": "https://mirl.club/"
            }
        },
        {
            "chainId": 101,
            "address": "4qn6FjsBPpVrGcPmvTgfwpCEQv7VxEAZX6WqmekWt5S7",
            "symbol": "sYAW",
            "name": "Staked Yawww",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4qn6FjsBPpVrGcPmvTgfwpCEQv7VxEAZX6WqmekWt5S7/syaw.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/YawwwNFT",
                "website": "https://www.yawww.io"
            }
        },
        {
            "chainId": 101,
            "address": "6oYoyFahkKxyvjsdt8JtRr4AQHvvwHEJthwqxQjei2gi",
            "symbol": "eEVRY",
            "name": "EVRY (Ethereum)",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6oYoyFahkKxyvjsdt8JtRr4AQHvvwHEJthwqxQjei2gi/evry.png",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "coingeckoId": "evrynet",
                "twitter": "https://twitter.com/EvryNetwork",
                "website": "https://evrynet.io/"
            }
        },
        {
            "chainId": 101,
            "address": "WitD7ka2noYdpqxdiUx9NHCpQLE7NVqroLVejThrSFw",
            "symbol": "LCD",
            "name": "Lazy Crazy Ducks Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WitD7ka2noYdpqxdiUx9NHCpQLE7NVqroLVejThrSFw/logo.png",
            "tags": [
                "Whitelist-token",
                "LCD"
            ]
        },
        {
            "chainId": 101,
            "address": "8tc8SFPUjJcuwZC3JPZMvTCnad9gsCZE94y2EE6i7gjk",
            "symbol": "GAMECOIN",
            "name": "SOL Game Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8tc8SFPUjJcuwZC3JPZMvTCnad9gsCZE94y2EE6i7gjk/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solplayground",
                "website": "https://solana-playground.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7ScYHk4VDgSRnQngAUtQk4Eyf7fGat8P4wXq6e2dkzLj",
            "symbol": "$ALL",
            "name": "ALL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ScYHk4VDgSRnQngAUtQk4Eyf7fGat8P4wXq6e2dkzLj/logo.png",
            "tags": [
                "utility-token",
                "nft",
                "commodity-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/stonedapecrew",
                "twitter": "https://twitter.com/stonedapecrew",
                "website": "https://www.allblue.dev/"
            }
        },
        {
            "chainId": 103,
            "address": "2GF7CMzttUbpEsPYReVHgMgbSPMdARGjhCWb9UcwcbU3",
            "symbol": "UREP",
            "name": "UNIVERSAL REPVBLIK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2GF7CMzttUbpEsPYReVHgMgbSPMdARGjhCWb9UcwcbU3/logo.png",
            "tags": [
                "utility-token",
                "investment-token",
                "stake-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/universalrepvblik/",
                "website": "https://www.universalrepvblik.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EEQFpa3sKwt7QD6MiqjoknrpHGE2qH6iPtiLmchzXdhL",
            "symbol": "DUCKPOOP",
            "name": "CraftyPoop",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/crtyencz/CraftyPoop/main/CraftyPoop.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FT2jC2W3kNxY1xDArtBzhPyAygFrB6HE8YH9Jbxdgpsy",
            "symbol": "SSCR",
            "name": "Skyscraper",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NarrativeDev/token/main/LOGO.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Fv4EAtwj8N",
                "twitter": "https://twitter.com/Skyscraper_NFT",
                "website": "https://skyscrapernft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FW7Ubg7FU36kB4tmK651ZKqu98gwyYuyM6bDXKLun77y",
            "symbol": "GLT",
            "name": "GreenLTEST",
            "decimals": 9,
            "logoURI": "https://github.com/gintarastamosiunas/Assets/blob/main/gliht_logo.svg",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/glight_network",
                "website": "https://glight.network/"
            }
        },
        {
            "chainId": 101,
            "address": "4mjbG7eFXriY6enhFfuFT4QAEFfuoY5e9KGC4eAp8Zv6",
            "symbol": "BOOG",
            "name": "Boogle Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Yamer9772/boogles/main/Logo.jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ad7QdUa5H38LgRWmnJxsFqpixGGKd1jQRdoc1ZUconhK",
            "symbol": "HMBL",
            "name": "Humble Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/humblelabs/token-list/main/assets/mainnet/Ad7QdUa5H38LgRWmnJxsFqpixGGKd1jQRdoc1ZUconhK/logo.png",
            "tags": [
                "investment-token",
                "stake-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "m6XGr58ATHSS7BvThRCDkqnsAeXLhpammhCrDo1amxq",
            "symbol": "BM",
            "name": "BM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/m6XGr58ATHSS7BvThRCDkqnsAeXLhpammhCrDo1amxq/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "exchange-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dmkq1LGAQKgaEogemxaWxHcNCLj9mUfkjkHoVLTCw7WN",
            "symbol": "BNW",
            "name": "Banana Wallet Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dmkq1LGAQKgaEogemxaWxHcNCLj9mUfkjkHoVLTCw7WN/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MsvhksRh",
                "twitter": "https://twitter.com/banana_wallet",
                "website": "https://bananawallet.io"
            }
        },
        {
            "chainId": 101,
            "address": "Hv55zwSKWfGBBY8bHMJ1fRxAbHHzuVGirJrpRKABV4mV",
            "symbol": "SC",
            "name": "Scrap City",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hv55zwSKWfGBBY8bHMJ1fRxAbHHzuVGirJrpRKABV4mV/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/knzdu7Pt24",
                "twitter": "https://twitter.com/ScrapCityTNW",
                "website": "https://scrapcity.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SUga2NsMBxwPDJd8ccjUw98LTCgTVkLNNBoRppm9DKE",
            "symbol": "SUG",
            "name": "Sugar Realm NFT WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUga2NsMBxwPDJd8ccjUw98LTCgTVkLNNBoRppm9DKE/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SugarRealmNFT",
                "website": "https://sugarrealm.io/"
            }
        },
        {
            "chainId": 101,
            "address": "WLhJmBK713bYZUpe9Hvx6CdCDMFRLLGDTJtaME7ywfP",
            "symbol": "SHFW",
            "name": "Female HODL Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanahodlwhales/whitelist/main/FemaleWhiteList.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaWhalesNFT",
                "website": "https://solanahodlwhales.io"
            }
        },
        {
            "chainId": 101,
            "address": "ECA5eN5A7tgynkcvapWHgBvfR28Ma1JtcPFLQzA6Z7yr",
            "symbol": "KAMA",
            "name": "Kamaaina Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/webmasterhawaii/kamacoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8gVsZAWiJg7aRvFTKrBMXDW2XVpUMrpjAUyobTRK1fwD",
            "symbol": "GOLD",
            "name": "Lords of SOL Gold",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8gVsZAWiJg7aRvFTKrBMXDW2XVpUMrpjAUyobTRK1fwD/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6sjHkM8nTT",
                "twitter": "https://twitter.com/LordsofSOL",
                "website": "https://lordsofsol.io"
            }
        },
        {
            "chainId": 101,
            "address": "DAHStbBEHcp7ooofA1TV3oayoRGM9i1v6TJSnoa2ZAWh",
            "symbol": "DAHS",
            "name": "Monkeh Moneh",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAHStbBEHcp7ooofA1TV3oayoRGM9i1v6TJSnoa2ZAWh/logo.svg",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DAHS",
                "twitter": "https://twitter.com/DAHSNFT",
                "website": "https://www.dahsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DHN2f5fexiVmUpiunxjszQakHyQohAjJC4CX3GQkRqwz",
            "symbol": "CKWL",
            "name": "Coinflip Kingz WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHN2f5fexiVmUpiunxjszQakHyQohAjJC4CX3GQkRqwz/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5sDTUwgsRDdycAHyWPEaXv4nSkjnLjSvJNhuLTnBmegM",
            "symbol": "MLCWL",
            "name": "MLC Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5sDTUwgsRDdycAHyWPEaXv4nSkjnLjSvJNhuLTnBmegM/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "StnYdE8KD26pJHRqMNHY6m6Fy6DZAiw4MEB3G5ydkvQ",
            "symbol": "stnywl",
            "name": "Stoney Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StnYdE8KD26pJHRqMNHY6m6Fy6DZAiw4MEB3G5ydkvQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "GDTVxsG41afjiJngZgFYHJkrG4PkTB9pVx7NuVsm2RcX",
            "symbol": "Club",
            "name": "Club",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/GDTVxsG41afjiJngZgFYHJkrG4PkTB9pVx7NuVsm2RcX/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "F6jdxXGBzrYxK9GtLNorhDL4ku7JgqXSpB26r5WWHNZw",
            "symbol": "HOLLOW",
            "name": "HOLLOW Token",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/F6jdxXGBzrYxK9GtLNorhDL4ku7JgqXSpB26r5WWHNZw/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "FKMH3U5uJK8s3zm66ij4urtLGUhX27WSpBLXdbeKgEqg",
            "symbol": "JWC",
            "name": "JetWanker Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Cawdroch/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ASBUxbkq4nKj8iExKyLVrHzDTZQpSBuaH8kiX3AYEQNj",
            "symbol": "APSU",
            "name": "APSU Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASBUxbkq4nKj8iExKyLVrHzDTZQpSBuaH8kiX3AYEQNj/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "Apsu Token",
                "telegram": "https://t.me/apsutoken",
                "twitter": "https://twitter.com/AnuApsu",
                "website": "https://apsu.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3sVenj4Dr2V37n3C1PfsEcsd3NXdQeCdvkJCpvTJ7W5b",
            "symbol": "SOLSALON$",
            "name": "SOLSALON WL Token",
            "decimals": 0,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/14122e8d-2a88-4d4e-e7c7-4d2039a3bd00/public",
            "extensions": {
                "discord": "https://discord.gg/uH9NBxbW",
                "twitter": "https://twitter.com/NftsSalon"
            }
        },
        {
            "chainId": 101,
            "address": "E3Lt4wqQhkz3iQ2VFa8bWhzAgiz6hfARJYFfMkLgmd8g",
            "symbol": "AVFX",
            "name": "AVE FENIX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E3Lt4wqQhkz3iQ2VFa8bWhzAgiz6hfARJYFfMkLgmd8g/logo.jpg",
            "extensions": {
                "website": "https://avefenixtools.wixsite.com/cryptotools"
            }
        },
        {
            "chainId": 101,
            "address": "HkahsdMz8okct7oQLXSVEdbax6MxexhDYqVBDtk1mKfp",
            "symbol": "STAK",
            "name": "Star Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HkahsdMz8okct7oQLXSVEdbax6MxexhDYqVBDtk1mKfp/starkoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7mQ7A7YPzRfkQnTwgwCMJknQ38EBBuppf1UEHYhBXMYB",
            "symbol": "SDSV",
            "name": "Seven Deadly Sins VIP Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7mQ7A7YPzRfkQnTwgwCMJknQ38EBBuppf1UEHYhBXMYB/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Use this coin to Mint a Sin NFT for a 50% discount on March 23rd, 2022",
                "discord": "https://discord.gg/XMKu9QEEHW",
                "twitter": "https://twitter.com/SevenDeadly5ins",
                "website": "https://sevendeadlysins.io/"
            }
        },
        {
            "chainId": 103,
            "address": "9b9F4MaqdawKWmzrGC8HdSdiCZA6Pq4kecdFL9gJiYL2",
            "symbol": "UREP_TESTNET",
            "name": "UNIVERSAL REPVBLIK TESTNET",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9b9F4MaqdawKWmzrGC8HdSdiCZA6Pq4kecdFL9gJiYL2/logo.png",
            "tags": [
                "social-token",
                "stake-pool-token",
                "investment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J7dR9dQZDAmhfkTBfaywqA92sqbvBjtcwdP6bCMZys5G",
            "symbol": "SWC",
            "name": "SwedCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/phonitekosova/crypto/main/Swedcoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8E4QTUFyNGEdXnktB67o9XTkPN7viDVUDNH9JKyGHnpb",
            "symbol": "DSY",
            "name": "DaisyChain",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/davidjr103/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4uRn7vxRPWYP4HuAa4UNXwEPLRL8oQ71YByMhr6yBnL4",
            "symbol": "WAVES",
            "name": "Playground Waves Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/TMyD8MN.jpg",
            "tags": [
                "bridgesplit-fraction",
                "playground-waves",
                "fraction-index",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/4uRn7vxRPWYP4HuAa4UNXwEPLRL8oQ71YByMhr6yBnL4"
            }
        },
        {
            "chainId": 101,
            "address": "CuSdFHQN8y42Qsyp6urwwGtddALgcS5wjEaEBa8tWCBP",
            "symbol": "CoopUSD",
            "name": "CoopUSD",
            "decimals": 0,
            "logoURI": "https://wcjwlkix7mn5yhucg4cmcmlettmghd3fqwuyah6wsj4qrgmm.arweave.net/sJNlqRf7G9wegjc_EwTFknN-hjj2WFqYAf1pJ5CJmMk",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "HiLLs2w3omgMqY37Yp58DLxkQJHoa1j1nzUAqTuL8kcU",
            "symbol": "SOLHILL",
            "name": "Solana Hills OG Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HiLLs2w3omgMqY37Yp58DLxkQJHoa1j1nzUAqTuL8kcU/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/SolanaHills",
                "twitter": "https://twitter.com/SolanaHills",
                "website": "https://solanahills.io"
            }
        },
        {
            "chainId": 101,
            "address": "FQ3U3wQYePjumgoeMVXLqJ4Jjeh2oBr8JBSnYDHMfU4J",
            "symbol": "FORTUNE",
            "name": "Fortune Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FQ3U3wQYePjumgoeMVXLqJ4Jjeh2oBr8JBSnYDHMfU4J/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/kUnPPnquwc",
                "twitter": "https://twitter.com/fortune_tigers",
                "website": "https://fortune-tigers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9vqbgswPyyhoBhMyGLry2CacE8pKgMPwmw6JDnrBhTwB",
            "symbol": "AGF",
            "name": "AgriFarm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gs3bopar/Crypto/main/AgiFarm.png",
            "tags": [
                "social-token",
                "community-token",
                "Agriculture",
                "Farming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/agri___farm"
            }
        },
        {
            "chainId": 101,
            "address": "9wirbd9zKaRT9dzBCAXRsEcgFc3sw64Qcd3zE3RBtacD",
            "symbol": "BBCK",
            "name": "Brock Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Matthew-Mattias/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2AAjFN46hMZB8YxDaaNNJH8eJPVS4Ej9c8nM8TkZHXfo",
            "symbol": "SLKRS",
            "name": "Slickers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IscrapIT/Crypto/main/Slicker.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75rBrSHUpyWNM48bx8SeU8GPHDYJ8sZyXB4AKZBs6vTi",
            "symbol": "METR",
            "name": "Meterra",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75rBrSHUpyWNM48bx8SeU8GPHDYJ8sZyXB4AKZBs6vTi/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/meterra-coin"
            }
        },
        {
            "chainId": 101,
            "address": "BxjGvx9Fkk3agp2vqzGzTXhPG7cwmqj6VoUqGhiDFgZK",
            "symbol": "CRYPTO",
            "name": "Cryptocurrency",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BxjGvx9Fkk3agp2vqzGzTXhPG7cwmqj6VoUqGhiDFgZK/logo.png"
        },
        {
            "chainId": 102,
            "address": "Gnqk5SFJjBUt976hSe69LVGYUtvMpGuJvYHHtPkmKJpt",
            "symbol": "IVSOL",
            "name": "Ivan Testnet Solana",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gnqk5SFJjBUt976hSe69LVGYUtvMpGuJvYHHtPkmKJpt/logo.png"
        },
        {
            "chainId": 101,
            "address": "81gZgxGMPGGvR74SLPiUDgzynwHmxv8V3hKrLmbX8Ej1",
            "symbol": "AYDFIVRR",
            "name": "AyodejiFiverr",
            "decimals": 2,
            "logoURI": "https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/fc44a5f0a37163bb6c44676cee391c33-778066491587995814402/JPEG_20200427_145650_538741297499951133.jpg",
            "tags": [
                "Show",
                "Social"
            ]
        },
        {
            "chainId": 101,
            "address": "tpt8Q3PBPbkt8kypMH8P48E5SU13ttRAKTyhYPB8q4X",
            "symbol": "TPT",
            "name": "Trashpile Presale Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tpt8Q3PBPbkt8kypMH8P48E5SU13ttRAKTyhYPB8q4X/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://trashpiles.io"
            }
        },
        {
            "chainId": 101,
            "address": "CCDj1SnaEEi5QNBGiBhs2K8yns4NCE9TX5vGg2UN42Ww",
            "symbol": "DIAC",
            "name": "Dia Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/xXMrZombie20Xx/DIAC/main/DIAC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DCg5GuAyxRwtM2VcSAJbgHesi1XqSqV1FAtV6T3VatcR",
            "symbol": "HKDD",
            "name": "DEFINIS",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/Definis-HKDD/Smart-Contract-/main/logo.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "HDRfVAY8Q54YnxhQPpc1ngHVEswU8k39cegCTZXnY5LE",
            "symbol": "BERRY",
            "name": "SBG Berry",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDRfVAY8Q54YnxhQPpc1ngHVEswU8k39cegCTZXnY5LE/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "In Solana Bear Gang you can earn $BERRY by sending your bears on Missions and more!",
                "discord": "https://discord.gg/Xn9WHM2RbN",
                "twitter": "https://twitter.com/solanabeargang",
                "website": "https://solanabeargang.com"
            }
        },
        {
            "chainId": 101,
            "address": "A1FmYQ3kXePPxpsnPPYFB8s25m7eWwSQmUzMwHMe9uUx",
            "symbol": "TCTY",
            "name": "The City Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ColstonKevin/Crypto/main/detroit-city-skyline-5096060_640.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "63qHkL13gWTisXjdi1MK1iVJh5Ax241znBNkikS9AAuo",
            "symbol": "AKN",
            "name": "Animal KingNFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/63qHkL13gWTisXjdi1MK1iVJh5Ax241znBNkikS9AAuo/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CGtYHv8WgfRBytCkmbFPuTsYTEQe57Gi6hGb4PGKDxXa",
            "symbol": "COF",
            "name": "Cof Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CGtYHv8WgfRBytCkmbFPuTsYTEQe57Gi6hGb4PGKDxXa/logo.png"
        },
        {
            "chainId": 101,
            "address": "SLNAAQ8VT6DRDc3W9UPDjFyRt7u4mzh8Z4WYMDjJc35",
            "symbol": "SLNA",
            "name": "Soluna Governance Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLNAAQ8VT6DRDc3W9UPDjFyRt7u4mzh8Z4WYMDjJc35/slna.svg",
            "extensions": {
                "website": "https://soluna.money/"
            }
        },
        {
            "chainId": 101,
            "address": "rMDeu774jQqT4fnDm9qcCRWFCm6jvZ7YRfwzqNsSj5j",
            "symbol": "MIO",
            "name": "Miomi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rMDeu774jQqT4fnDm9qcCRWFCm6jvZ7YRfwzqNsSj5j/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "beanZG7tYRxHyTTm9pFSNqStL7AZFo2FirFkH1dLZzc",
            "symbol": "BEAN",
            "name": "Magic bean",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/beanZG7tYRxHyTTm9pFSNqStL7AZFo2FirFkH1dLZzc/logo.jpg",
            "tags": [
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MagicBeanCoin",
                "website": "https://magicbean.social/"
            }
        },
        {
            "chainId": 101,
            "address": "6AnQyR4fypvEWdArxg6hSpbvLBVwDiMGeYBeb9tHpR2p",
            "symbol": "SRF",
            "name": "Solar's Refunding Firm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Reg1337/RegCoin/main/Webp.net-compress-image.jpg",
            "tags": [
                "Refunding",
                "RefundGod",
                "Community",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SW3Bzm2wEJ",
                "telegram": "https://t.me/SolarRefundingFirm"
            }
        },
        {
            "chainId": 101,
            "address": "GnyQey471sZqakFBR61oGmPyZzJB4JaSCk6H1CQzSf3X",
            "symbol": "COTA",
            "name": "Children of the Almighties",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ChildrenoftheAlmighties/cotatoken/main/assets/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "stake-pool-token"
            ],
            "extensions": {
                "description": "Children of the Almighties is the Ultimate Gamers Community on Solana",
                "discord": "https://discord.gg/BNJfkQGNYM",
                "twitter": "https://twitter.com/CotA_solana",
                "website": "https://childrenofthealmighties.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3baWGQ4b6sXwv2daBy3NNTQpwYh9mBXXe71abPx9Mgnw",
            "symbol": "CODE",
            "name": "the codingmiracle token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/codingmiracle/Solana-Token/main/token/CODE/code-logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/codingmiracle",
                "twitter": "https://twitter.com/codingmiracle",
                "website": "https://codingmiracle.github.io"
            }
        },
        {
            "chainId": 101,
            "address": "6ywd9eSkajgWf91tSyZxERjNZkFTHY8C37dGH7kkEDWX",
            "symbol": "RYDE",
            "name": "Fairyde Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ywd9eSkajgWf91tSyZxERjNZkFTHY8C37dGH7kkEDWX/logo.png",
            "tags": [
                "ride-hailing-token",
                "RYDE"
            ]
        },
        {
            "chainId": 101,
            "address": "JDBfYTBh4FqdrbawJnjHGLhijhwm3HBvjbcUtL9UnyPR",
            "symbol": "NBLK",
            "name": "NeonBlocks Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JDBfYTBh4FqdrbawJnjHGLhijhwm3HBvjbcUtL9UnyPR/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/XEV3azMgsB",
                "twitter": "https://twitter.com/NeonBlocksNFT"
            }
        },
        {
            "chainId": 101,
            "address": "9RdGrj2x88fCDTpQDiWzvzgYMApoakSCV3eJWR8JLjCK",
            "symbol": "BBWL",
            "name": "Begrudged WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cmgonsalves/wl-token/main/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/9ygPYaw9xN",
                "twitter": "https://twitter.com/begrudgeddao",
                "website": "https://www.begrudgedbunnies.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GQQ2JR5GtShE67U7v6CMMHCr4EMfUTJLfvPRo3EpedG8",
            "symbol": "BRT",
            "name": "COLLECTORS CURRENCY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/opals99/BRT/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5uo9kB3MrosZPaVAQuiKA9d5CvHe29zS9AsShqynGfuq",
            "symbol": "CRAFTYSTATS",
            "name": "CraftyStatistic",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crtyencz/CraftyStatistic/main/CraftyStatistic.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KoRnU6Tr6MMMEqdUPbDeaQsZTMcBjQoM8vaZQ2maS8k",
            "symbol": "KORN",
            "name": "Kornwolf Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KoRnU6Tr6MMMEqdUPbDeaQsZTMcBjQoM8vaZQ2maS8k/logo.png",
            "tags": [
                "utility-token",
                "staking-token"
            ],
            "extensions": {
                "description": "Kornwolf is a social crypto trading platform on Telegram. Users can stake KORN to reduce fees, unlock extra features and participate in revenue sharing.",
                "facebook": "https://facebook.com/KornwolfBot",
                "telegram": "https://t.me/KornwolfCommunity",
                "twitter": "https://twitter.com/KornwolfBot",
                "website": "https://kornwolf.com"
            }
        },
        {
            "chainId": 101,
            "address": "Dihi9zPutTATSsYGZdtsq35ECaf4minQ1ZxxsoegCtT1",
            "symbol": "Rbit",
            "name": "Rbit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dihi9zPutTATSsYGZdtsq35ECaf4minQ1ZxxsoegCtT1/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/miojounft"
            }
        },
        {
            "chainId": 101,
            "address": "DUGxgWxo8csexcUJyGogybMjgnstzfiwQHRkCovGbfvm",
            "symbol": "DUG",
            "name": "UncommonGood",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUGxgWxo8csexcUJyGogybMjgnstzfiwQHRkCovGbfvm/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/douncommongood",
                "website": "https://douncommongood.com"
            }
        },
        {
            "chainId": 101,
            "address": "GiF3aStC3TwyaxoHE7UKMPxhCbCg92ar8JXDS8nfLT6U",
            "symbol": "VSRF",
            "name": "VS Rewards",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kookej/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4QvgYkBd4XbkHrXXvYW1gPFvy3dMZZSWfC8ctsSp6T5X",
            "symbol": "MC",
            "name": "MuscleCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4QvgYkBd4XbkHrXXvYW1gPFvy3dMZZSWfC8ctsSp6T5X/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://musclecoin.is/"
            }
        },
        {
            "chainId": 101,
            "address": "2JKkkZYHxXvsX6BZac58SrheWN62Jq5Ua3E42NjRmkuL",
            "symbol": "JYCN",
            "name": "JoeyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/joemc3/joecoin/main/joeyCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TENJmPxVHvGyoN6nNaW2dF3VmJjcgb5jVTSaAaUu6Aa",
            "symbol": "TNJ",
            "name": "TENJIN WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TENJmPxVHvGyoN6nNaW2dF3VmJjcgb5jVTSaAaUu6Aa/logo.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tenjin3dnft",
                "website": "https://www.projecttenjin.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8pikaozvoGfhN7XC2aXqZE73B4ahdS9ffpSQUH3hSokG",
            "symbol": "Tipso",
            "name": "Tipso-Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pikaozvoGfhN7XC2aXqZE73B4ahdS9ffpSQUH3hSokG/tipso_logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D6B9iXwtaFXXdB2J4k9L2CaY661MdLUyG9u2R2Smokes",
            "symbol": "SMOKES",
            "name": "Pen Frens: SMOKES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/penfrens/token-list/main/assets/mainnet/D6B9iXwtaFXXdB2J4k9L2CaY661MdLUyG9u2R2Smokes/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "trade-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Dd4tDYG5aj",
                "twitter": "https://twitter.com/pen_frens",
                "website": "https://penfrens.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AKdoFM6nBH2pTYqbd1hhe1KSnXN3WRaYjEkGKRTu1y2Z",
            "symbol": "FTM",
            "name": "Future Trading Meta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Aindari/crypto-token-/main/FTM.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FRsUQpriCFXtCH8nHb65Ke14m9rMiqTbt74Ahfp4CB3i",
            "symbol": "DIGIDOT",
            "name": "Rewards Dot Digital",
            "decimals": 2,
            "logoURI": "https://github.com/btcubi/SOLANALOGO/blob/main/logo4solanasm.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/hello_rewards",
                "website": "https://rewards.digital/"
            }
        },
        {
            "chainId": 101,
            "address": "7AK7bdmA2GZioA4Z6WdKms4oBa9xmScYzdRRjPnV6iPM",
            "symbol": "PRTK",
            "name": "Priceless Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PriceFB/PixelBabes-website/main/assets/images/Empty-Gold-Coin-PNG-Image.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NeoSLQatRUQkLkiKxLwyg2Ef5T72HHX3eesn7Ve1MHk",
            "symbol": "NEOS",
            "name": "NEOS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NeoSLQatRUQkLkiKxLwyg2Ef5T72HHX3eesn7Ve1MHk/logo.png",
            "tags": [
                "community-token",
                "utility-coin",
                "NFTs"
            ]
        },
        {
            "chainId": 101,
            "address": "H5SkWTvDpxon9Egd9JeVeeYFj97QDGHAmMPmjLpTFvNx",
            "symbol": "PRJH",
            "name": "ProjectH",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Slikzy21/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "78mxvM1TiRZwjfQjJWwv37tAvXdnKKLTCsf55rVed9WR",
            "symbol": "WTT",
            "name": "World Trading Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/jjnorris/worldtradingtoken/main/WorldTradingToken.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CyNoV67G6bmwJS42CpAVkrK6dT4pCjTuZAymL4fSL1fE",
            "symbol": "CYN",
            "name": "Official Cynova WL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CyNoV67G6bmwJS42CpAVkrK6dT4pCjTuZAymL4fSL1fE/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/The_Cynova"
            }
        },
        {
            "chainId": 101,
            "address": "DfVYtfj9nMPVFDaSM5vaqFAxucfNA3vgsn4MwyrwWUJZ",
            "symbol": "AKNWL",
            "name": "Animal KingNFTWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DfVYtfj9nMPVFDaSM5vaqFAxucfNA3vgsn4MwyrwWUJZ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2bzXp1XzCSW8C3xTPcuKjEBwy98BENTb8i9ZCppgY4Az",
            "symbol": "ZGWL",
            "name": "Zpheres Genesis Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2bzXp1XzCSW8C3xTPcuKjEBwy98BENTb8i9ZCppgY4Az/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7t5XxREp55",
                "twitter": "https://twitter.com/Zarnyx_NFT",
                "website": "https://zarnyx.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4ktGVhz9DhUiCj2p4ZYE5foHTtMATtG47PyNqeGHx7ev",
            "symbol": "HORO",
            "name": "Horo-Ikimono",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ktGVhz9DhUiCj2p4ZYE5foHTtMATtG47PyNqeGHx7ev/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/HoroikiNFT",
                "website": "https://www.horoikimono.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8WPqBwThv7dtFt3BTgsfDJjM8kPphrhTDHGz3iiqePiB",
            "symbol": "PARA",
            "name": "Parabolic",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TylerDurden69420/Parabolically-Token/main/%24PARA%20TOKEN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "44JT3kt1XRyuD8RfGRMaPnBY3tUS4ZK5FgQH2sQVXtFG",
            "symbol": "MRTY",
            "name": "Marty Plunkett",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shanghaibokchoy/Plunkettlogo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7yqrmfWprDy1xejzAF9LGmRg8iGP3CiZJHfcA8qvV4QH",
            "symbol": "HONKS",
            "name": "HONKS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7yqrmfWprDy1xejzAF9LGmRg8iGP3CiZJHfcA8qvV4QH/logo.png"
        },
        {
            "chainId": 101,
            "address": "EzSPBGJkZzGbcRYDMTUcfiH9ecocmzLXjdmXX8X4h2iC",
            "symbol": "LTZ",
            "name": "Lumatozer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lumatozer/ltz-coin-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "kinghrELFNQzWhGaiALF9SCZLf4J7daPQdjvzE146z4",
            "symbol": "KINGS",
            "name": "Congo Kings",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/congokings/king_logo/main/token_logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J7HoxrqyPM5nu2RBUQfM5TKM5vxDmXp76fqt7T1t5pSF",
            "symbol": "DOLPHIN",
            "name": "GET DOLPHIN WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J7HoxrqyPM5nu2RBUQfM5TKM5vxDmXp76fqt7T1t5pSF/logo.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://getdolphin.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FanJWA4yEVUJj1r83tR7XybxmDGF6bNH8M81ag9aeUbF",
            "symbol": "BMT",
            "name": "BMT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FanJWA4yEVUJj1r83tR7XybxmDGF6bNH8M81ag9aeUbF/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "exchange-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ALL5YrKTfDNCUqoXiWDSHE1ne763eAcvEUMU8VCWdn2W",
            "symbol": "HPC",
            "name": "Harry Potter Coin",
            "decimals": 0,
            "logoURI": "https://github.com/nishadmahmud/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gso9VgvACphPJerk32vTmsvCPN6H79pKqdUMZVCGfRnf",
            "symbol": "SOLARISWL",
            "name": "Solaris Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gso9VgvACphPJerk32vTmsvCPN6H79pKqdUMZVCGfRnf/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solaris_nft",
                "website": "https://solaris.so/"
            }
        },
        {
            "chainId": 101,
            "address": "H7U13y4ZWxG7444tNwcoqcoA3n5ZJrCe5LN6cBG5MigT",
            "symbol": "ADATOMO",
            "name": "Ada-Tom-O",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H7U13y4ZWxG7444tNwcoqcoA3n5ZJrCe5LN6cBG5MigT/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "FCSUDXzfqc393wVcv4tWBU4LgRhJeDi8YA6BGTs3qVPP",
            "symbol": "tlpSOL",
            "name": "LP Finance Solana (Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/lpSOL-min.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "AL9fyDTSmJavYxjftxBHxkLtwv9FcsUJfVvEheW6vfdq",
            "symbol": "tlpUSD",
            "name": "LP Finance USD (Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/lpUSD.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 101,
            "address": "E6eCEE3KqjRD5UxcBYQTdV8Z535hyaBuFin9Udm6s6bz",
            "symbol": "AIR",
            "name": "Balloonsville AIR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6eCEE3KqjRD5UxcBYQTdV8Z535hyaBuFin9Udm6s6bz/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/balloonsville",
                "twitter": "https://twitter.com/balloonsville_2",
                "website": "https://stake.balloonsville.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "kQFsSGvn4AM5KqkJq4cLHDx1DXyNx7hL4HRJvxrjeNh",
            "symbol": "TWSW",
            "name": "Two Side Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoDevHouse/token-list/main/assets/mainnet/kQFsSGvn4AM5KqkJq4cLHDx1DXyNx7hL4HRJvxrjeNh/logo.jpg",
            "tags": [
                "whitelist-token",
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Each token gives you access to buy one NFT on https://two-side.io",
                "discord": "https://discord.gg/twoside",
                "twitter": "https://twitter.com/TwoSide_NFT",
                "website": "https://two-side.io"
            }
        },
        {
            "chainId": 101,
            "address": "2vf8ZFgKsFskg6KDi62HYAVq4fiMa4ubnV4SHUKHeyrH",
            "symbol": "MC",
            "name": "Moschino Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vf8ZFgKsFskg6KDi62HYAVq4fiMa4ubnV4SHUKHeyrH/MoschinoCoin.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DMrgJoVP6DYyiaL74bCLSbeBxJGVKNoBtyQbuaLnKeDJ",
            "symbol": "MOD",
            "name": "Modium",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Donb76e/Donb76e/main/modo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FS9JmN2AmqsNELUVDKyC2P1cPDk79mhqZZRRryM6hJcF",
            "symbol": "CKE",
            "name": "CAKE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrcl007/cake-token/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "Eq6ipM2YvENyuFQmxy1zMAqfdm2dHsd45rDoKteMSRgR",
            "symbol": "SolPhant",
            "name": "SolPhant WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Eq6ipM2YvENyuFQmxy1zMAqfdm2dHsd45rDoKteMSRgR/logo.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/HsHwWGtuaz",
                "twitter": "https://twitter.com/wecap_io",
                "website": "https://wecap.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CYGGn3Pp6BnXJXxvz3Ym4sKHJPgJXVq9UsumyjMJCsnE",
            "symbol": "SCHK",
            "name": "SchultzKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thebartster/schultzcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5cMLFikqJ6DpFzjD7sQf9CoqTGQVB4ELfPN78NBfBofv",
            "symbol": "$ONLY",
            "name": "ONLY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/setoKaibalol/pictures/main/pic/Only_HODLers_PFP.png",
            "tags": [
                "Meme-Token",
                "Commerce"
            ],
            "extensions": {
                "twitter": "https://twitter.com/OnlyHODLers"
            }
        },
        {
            "chainId": 101,
            "address": "GnrXhsbf2TtHaRr5Fa2UUQEsXzcAX9HnJqrQPUUR6yAx",
            "symbol": "REWARDS",
            "name": "MCPREWARDS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GnrXhsbf2TtHaRr5Fa2UUQEsXzcAX9HnJqrQPUUR6yAx/logo.png",
            "tags": [
                "NFT",
                "reward-token",
                "casino",
                "game",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/AuwdjCWfVb",
                "twitter": "https://twitter.com/casino_pandas",
                "website": "http://millionairecasino.games"
            }
        },
        {
            "chainId": 101,
            "address": "7BXSXbcUxN4jHcnaxh2aA1trCpr9xbcb2q3qywQCurKq",
            "symbol": "FAIS",
            "name": "Financial Artificial Intelligence Systems",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7BXSXbcUxN4jHcnaxh2aA1trCpr9xbcb2q3qywQCurKq/FAIS.png",
            "tags": [
                "utility-token",
                "security-token"
            ],
            "extensions": {
                "description": "Financial Artificial Intelligence Systems delivers a user-friendly AI-supported platform for financial and management accounting.",
                "linkedin": "https://www.linkedin.com/company/faisystems/",
                "telegram": "https://t.me/fais_discuss",
                "telegramAnnouncements": "https://t.me/fais_announce",
                "twitter": "https://twitter.com/fai_systems",
                "website": "https://www.faisystems.com",
                "whitepaper": "https://base.faisystems.com/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "BoChkHxTHhpXFWTAvztbqhPA7qF91Fndp6FygVtKtMa5",
            "symbol": "BOCH",
            "name": "BOCHI BONES TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoChkHxTHhpXFWTAvztbqhPA7qF91Fndp6FygVtKtMa5/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BochiBones"
            }
        },
        {
            "chainId": 101,
            "address": "EaxUnKBSP18HMvxRyjMogXd3HYzi8JjGzJ4tdWcC2LtY",
            "symbol": "WAG",
            "name": "Swagcoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Swagcoin-Foundation/swagcoin-token/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NUGkd72SGr"
            }
        },
        {
            "chainId": 101,
            "address": "8pdW1cHbX182TcMTb5hrEPhTimTdDm8ACe7a3TBkkiDG",
            "symbol": "ACRO",
            "name": "Atomic Rhino",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Atomiceatscode/cryptoo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6tAmokk5fqrjm4ho2JerziBsiV3hYzgJZnG6sFXZNXZs",
            "symbol": "GREPCC",
            "name": "Grepper Contributor Coin",
            "decimals": 2,
            "logoURI": "https://www.codegrepper.com/images/grepper_coin.png",
            "tags": [
                "grepper",
                "grepper-coin",
                "grepper-contributor",
                "grepcc",
                "community-token",
                "developer"
            ],
            "extensions": {
                "description": "Grepper Contributor Coin is designed to reward contributors that help the Grepper Developer Community.",
                "github": "https://github.com/CodeGrepper/grepper_contributor_coin_contract",
                "twitter": "https://twitter.com/codegrepper",
                "website": "https://www.codegrepper.com"
            }
        },
        {
            "chainId": 101,
            "address": "PRDXoZD3WCjgr6xzvTfWo3qQJmmVsR616cX2NM1wnaX",
            "symbol": "$PRDX",
            "name": "Paradox World",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRDXoZD3WCjgr6xzvTfWo3qQJmmVsR616cX2NM1wnaX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://www.paradoxworld.io"
            }
        },
        {
            "chainId": 101,
            "address": "ARg9wfeLN4qZTxgYTYeuGtGFMmYdk5zFhBuSnTfXXUvb",
            "symbol": "CHI",
            "name": "Project Paradise - CHI Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/benthebape/projekt-paradise-chi-token/main/Moneda_1.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://projektparadise.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EAMZkD4RwKfKCbAczUxDhRm5a5WJMcjnJsnJy8WWEZVt",
            "symbol": "FEDC",
            "name": "fed coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAMZkD4RwKfKCbAczUxDhRm5a5WJMcjnJsnJy8WWEZVt/fedcoin.png",
            "extensions": {
                "github": "https://github.com/triptych-labs",
                "twitter": "https://twitter.com/triptychlabs_io",
                "website": "https://triptychlabs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7z6ejELsEWQMUsuCjp9J93WYyhj7UtPFY5GuiTQwfo4a",
            "symbol": "TNJN",
            "name": "TENJIN Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TENJmPxVHvGyoN6nNaW2dF3VmJjcgb5jVTSaAaUu6Aa/logo.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/tenjin3dnft",
                "website": "https://www.projecttenjin.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6odZreTZYmgyqe4jJwE1A66CbbUGJpstwYNfMw4FJbQp",
            "symbol": "May",
            "name": "May Koin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/steelhitman/Crypto-Token-May/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9JqZDg3awhhwZPh83QE2bSkqfJdRuJeUEKPpKWYUDst9",
            "symbol": "HUF",
            "name": "Hungarian Forint",
            "decimals": 9,
            "logoURI": "https://github.com/BugzumDevAlt/FTlogo/blob/main/ftlogo.png",
            "tags": [
                "stablecoin",
                "cryptocurrency"
            ]
        },
        {
            "chainId": 101,
            "address": "33MJjPS6H7P2nwD648uiTiUu8v5fJ1zKyjnVqeWZ344F",
            "symbol": "WVE",
            "name": "everwave",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33MJjPS6H7P2nwD648uiTiUu8v5fJ1zKyjnVqeWZ344F/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "B1ock8ufjvuEPo4eDhnTHtY1uzk2TLg9zpoLnmMpa3Ht",
            "symbol": "BLOCK",
            "name": "BlockParty BLOCK",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B1ock8ufjvuEPo4eDhnTHtY1uzk2TLg9zpoLnmMpa3Ht/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solblockpartynft",
                "twitter": "https://twitter.com/solblockparty",
                "website": "https://www.solblockparty.com"
            }
        },
        {
            "chainId": 101,
            "address": "F3XTi3qaJMEiozmmwL1VFMWHoViMeRAMemydQeSvVpvA",
            "symbol": "BUST",
            "name": "Bus Token",
            "decimals": 0,
            "logoURI": "https://github.com/opulukcu/kryp/blob/main/logo.png",
            "tags": [
                "social-token",
                "stablecoin",
                "cryptocurrency",
                "bus-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7V8aTxLBGZwU1sswUeaimS1Xe3c2dJkFMafxJLokLN3C",
            "symbol": "NUBE",
            "name": "NubeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gansaikhanshur/NubeCoin/main/images/NubeCoin_2022.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GCbxbnesg5MGUcmZW6WuAbCqmixqq4P3vw4FNvDKBeMT",
            "symbol": "VARO",
            "name": "Varo Mexico",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DigitalVaro/Varo/main/LogoVaro.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GgSTrc4B4qGjx4JVSeRDopG5xy1qSf54gFYhW4bm6a2x",
            "symbol": "$JUNK",
            "name": "Junkyard Scraps Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GgSTrc4B4qGjx4JVSeRDopG5xy1qSf54gFYhW4bm6a2x/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GDNHmkp8K2yVFskSgjCx9ETv6iqdJYPMgRDrjzqCACqJ",
            "symbol": "iLQD",
            "name": "identical LQD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GDNHmkp8K2yVFskSgjCx9ETv6iqdJYPMgRDrjzqCACqJ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3iepSA15cpm3sbci4fMVeMsWxwXgE9w7kF69Y7KnPJKZ",
            "symbol": "$ZILLA",
            "name": "Zilla Family Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3iepSA15cpm3sbci4fMVeMsWxwXgE9w7kF69Y7KnPJKZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://www.babyzillaclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "esiYxKfzKhoYUmK5mJWG3rvXhPD9BcmfaSSKJwKk1KD",
            "symbol": "4lt",
            "name": "4ltcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/4ltBread/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3tRxdAT4eSRxemnUWBP17YN778SwN354EiZ79Ci7wrZA",
            "symbol": "NOS",
            "name": "NOS Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3tRxdAT4eSRxemnUWBP17YN778SwN354EiZ79Ci7wrZA/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C6zQtWK5hoEhFgzn8hDmn1MwrYs8QF6ZWopeDTyQub4c",
            "symbol": "CSR",
            "name": "Crypto Street Racing Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C6zQtWK5hoEhFgzn8hDmn1MwrYs8QF6ZWopeDTyQub4c/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7iFfsz6qLZezfMUJGc4Laq8N2H5KipFFk6CvEE8ayQiE",
            "symbol": "TIA",
            "name": "Tokina",
            "decimals": 9,
            "logoURI": "https://tokina.io/logo512.png",
            "tags": [
                "exchange-token",
                "payment-token"
            ],
            "extensions": {
                "website": "https://tokina.io"
            }
        },
        {
            "chainId": 101,
            "address": "CHiriKKbYYgxh69MbTk5f3z42jZgqLXAfQX3q9qkXb6E",
            "symbol": "CHIP",
            "name": "Chip",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thechip911/crypto_mach_1/main/logo.png",
            "tags": [
                "utility-token",
                "DeFi",
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FDqw6afUwC8bXhRDcwyDGUh7MwHPkKahJjbm5ZHEd7BP",
            "symbol": "HXDT",
            "name": "HXD-TEST",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDqw6afUwC8bXhRDcwyDGUh7MwHPkKahJjbm5ZHEd7BP/logo.png"
        },
        {
            "chainId": 101,
            "address": "MAHGKVNJzmGKUA2oHc4QaQtkfdoeKuHgrVGpjdxHhss",
            "symbol": "MAHO",
            "name": "MAHO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MAHGKVNJzmGKUA2oHc4QaQtkfdoeKuHgrVGpjdxHhss/logo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mahogenies",
                "website": "https://www.mahogenies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3B7pVd7FX9TPFgtmTSsacoQTcweAnbuSicswoBDrfpTc",
            "symbol": "HYDRAS",
            "name": "Hydrasound",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danielboots/hydra-crypto/main/logo.png",
            "tags": [
                "music-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ao6oTCHGkLNpPCgyrSHHuZSrxx7AkaEaxoKeYR8ES4hq",
            "symbol": "DragonMonke$",
            "name": "WL Dragon Monke",
            "decimals": 0,
            "logoURI": "https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/4a2e7ca4-1259-4e8e-d819-933f059ca000/public",
            "extensions": {
                "discord": "https://discord.gg/scz79P4W5w",
                "twitter": "https://twitter.com/DragonMonkes",
                "website": "https://dragonmonke.club"
            }
        },
        {
            "chainId": 101,
            "address": "7fXf6Zdk76gtxgbcNohiv4YhuYD1ak6gNMXPMFtZyUm6",
            "symbol": "XAl",
            "name": "Xallo",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Donb76e/xallo/main/xallo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "APhcurxPQdEfrxLYSRTQgigv1bVFV4xTSsQgwRap9Xfk",
            "symbol": "RSTM",
            "name": "Rostam Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/farhadkarimi/rostam/main/logo.png",
            "tags": [
                "social-token",
                "payment-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AgxCnAs8sP1q3zdFvJCWMipbHkCwB8u4vKXmesUtbRfn",
            "symbol": "EBC",
            "name": "EarnBot Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/steelhitman/Crypto-Token-EBCoin/main/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "reward-token"
            ],
            "extensions": {
                "description": "EarnBot is a new and easy way to earn money. Visit our website (https://earn-bot.herokuapp.com)or join our discord (https://discord.gg/NHnUw4P9Pq) to know more."
            }
        },
        {
            "chainId": 101,
            "address": "7HF4P4pPuDNGDgdGpzXKapnDYC7c4gCWkhMPJMynB5DD",
            "symbol": "SSZ",
            "name": "SolSeltzers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7HF4P4pPuDNGDgdGpzXKapnDYC7c4gCWkhMPJMynB5DD/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiW3CUXCrskvxtqTMVFEcnbULWzZ6BjGWPJLc1uM5nhf",
            "symbol": "LOOT",
            "name": "Loot Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoeyTheCoder/LootCoin/master/lootlogo.png",
            "tags": [
                "social-token",
                "game-currency"
            ]
        },
        {
            "chainId": 101,
            "address": "8w5KKbMATmjWpMq4vJEwDedHEhUdwGWWthBEnunAw5EM",
            "symbol": "ZGWLV2",
            "name": "Zpheres Genesis Whitelist Token V2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8w5KKbMATmjWpMq4vJEwDedHEhUdwGWWthBEnunAw5EM/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7t5XxREp55",
                "twitter": "https://twitter.com/Zarnyx_NFT",
                "website": "https://zarnyx.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FoWWWzVBdj6VqYfBw7qQZmZtQqCwNxetLbxbBGYb2r54",
            "symbol": "ECOGRL",
            "name": "Echocore Genesis Reserve List Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoWWWzVBdj6VqYfBw7qQZmZtQqCwNxetLbxbBGYb2r54/logo.png",
            "tags": [
                "NFT",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/echocore_io",
                "website": "https://echocore.io"
            }
        },
        {
            "chainId": 101,
            "address": "4JdFQvbd9YQ7TMmvzEohHKpUCKD7wPGMphLhSFgH5gsX",
            "symbol": "FILM",
            "name": "Filmira",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/stoyanvelinov/filmira-token/main/logo.png",
            "tags": [
                "exchange-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZRAB3ibkeezgjnrJdvPnadZ2hJWbRKcBe3SEPR8FQiv",
            "symbol": "MD",
            "name": "MD20M Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MD20M/MD20M_coin/main/tonka.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2WkEFHecypXrpjSEJqccftBAmG7xSPCnQbKEeGpkUyia",
            "symbol": "APRZEN",
            "name": "ApertureZEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ApertureZEN/ApertureZEN_Token/main/ApertureSTAR.jpg",
            "tags": [
                "Utility-token",
                "NFT",
                "reward-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2uEhaxw5JYFv2aMACsTK1zxVz3GqEXd3nKi71CRQCjsH",
            "symbol": "THTn",
            "name": "Thoughtable Network",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/THTNet/THTn/main/thtn.png",
            "tags": [
                "social-token",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "DtKcjV7SXHEtNSYPJRQ74sJG5fZwRfYz3bEG2t95NqcK",
            "symbol": "IAMIN",
            "name": "Amastan Innovation",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DtKcjV7SXHEtNSYPJRQ74sJG5fZwRfYz3bEG2t95NqcK/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token",
                "security-token"
            ],
            "extensions": {
                "medium": "https://amastan-innovation.medium.com/",
                "twitter": "https://twitter.com/AmastanInnov",
                "website": "https://amastan-innovation.com"
            }
        },
        {
            "chainId": 101,
            "address": "ESzkdUN8jvJxMqBjWADUomRxHn2ECxLFeeEvrcteLMnY",
            "symbol": "pstSOL",
            "name": "Port Finance Lido Staked SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ESzkdUN8jvJxMqBjWADUomRxHn2ECxLFeeEvrcteLMnY/pstSOL.svg",
            "tags": [
                "port",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "6Hdoq4uHvyQ7Wvj6P4RsNvDZhDF5Px6Gz3YTwDSYj1bv",
            "symbol": "USDBTC",
            "name": "USDbitcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanausdbtc/solanusdbtc/main/download1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bs5rtnAennmZhxsmYw4zWexzX4Zjd966xsA4R4HCYnaa",
            "symbol": "SSKM",
            "name": "Shurikoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bs5rtnAennmZhxsmYw4zWexzX4Zjd966xsA4R4HCYnaa/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/shurikoin"
            }
        },
        {
            "chainId": 101,
            "address": "DinoSAHtJFKyuYoKF8tLnojLRSSfYh3tWrsbztQMPxYi",
            "symbol": "DINO",
            "name": "DinoLabs WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DinoSAHtJFKyuYoKF8tLnojLRSSfYh3tWrsbztQMPxYi/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DinoLabsNFT",
                "website": "https://dinolabs.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "4GuvJDpvvGgzkUi6cASJd5qLGEPbMCLeD5bz2yvHfRMi",
            "symbol": "SAKT",
            "name": "Sakura Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/3Luxor/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3wtZxWZM3udb8ApXKfYdxkkYqAkgF1c4DPCLNE5S2oAq",
            "symbol": "SIMS",
            "name": "Simone Verso",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/L34NDRO-S/CRYPTO/main/simone_verso.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2nQy1opG8E9xzFMgPUdo5ChmoA2zXHcywquhftABexf6",
            "symbol": "ON9",
            "name": "on9ine coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andrewangtaylor/on9/main/800x800.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "o8fd6VTHYEsuw8kduumAZ5pYSrGV9ygafuQYeCW1VSm",
            "symbol": "ANONYMOUS",
            "name": "Anonymous Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ultimateminds/anon/main/Anonimg.png",
            "tags": [
                "social-token",
                "collectibles",
                "change-the-world"
            ]
        },
        {
            "chainId": 101,
            "address": "8phoATmrxgACo6BGKznLwX9JwRLkhtowyuVhhQKxj1QL",
            "symbol": "VPFD",
            "name": "Fiend Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VPFiends/tokens/main/VPFiendsIcontrans300x300.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "gJtYNevehYkg9VeEaWWKztWeFt4WXdQFihMXrUMeKd4",
            "symbol": "ODYSSEUS",
            "name": "Odysseus Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gJtYNevehYkg9VeEaWWKztWeFt4WXdQFihMXrUMeKd4/logo.png",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/OdysseusCoin",
                "website": "https://odysseuscoin.net/"
            }
        },
        {
            "chainId": 101,
            "address": "EKhDvdnC3SPqTuKR5fpkyb4qMNpXJokXvG4SgzaucwNy",
            "symbol": "NMYWL",
            "name": "ENEMY WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKhDvdnC3SPqTuKR5fpkyb4qMNpXJokXvG4SgzaucwNy/logo.png",
            "tags": [
                "Enemy-token",
                "WHITELIST",
                "NFT",
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF",
            "symbol": "ZBC",
            "name": "ZEBEC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF/logo.png",
            "tags": [
                "utility-token",
                "veni",
                "vidi",
                "vici"
            ],
            "extensions": {
                "coingeckoId": "zebec-protocol",
                "description": "Zebec is a continuous Settlement Protocol that will transform payroll, cash flow, and token vesting by allowing users to send payments and distributions every second.",
                "discord": "https://discord.gg/gYCe7h8p",
                "telegram": "https://t.me/zebececosystem",
                "twitter": "https://twitter.com/Zebec_HQ",
                "website": "https://zebec.io"
            }
        },
        {
            "chainId": 101,
            "address": "H1cM6JMcL8Wq9MhcGYun5U6T6tVXfZbGUjiX7dUaGbjp",
            "symbol": "9CPM",
            "name": "9C Pasti Menang",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andhra21231/andhra21231/main/logo.png",
            "tags": [
                "NFT",
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CT8zpJM4sVo7Bnm45Mr5rvpUfM2n1LqbMdzHRfyPW3uQ",
            "symbol": "METG",
            "name": "MetaGam",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/namtan10t1/solana-metg/main/logo.png",
            "tags": [
                "metg-solana"
            ]
        },
        {
            "chainId": 101,
            "address": "6g1KdqGYUb9TY5CyzHbyksW5ctGVS4xVewNEobFqD53g",
            "symbol": "FLO",
            "name": "FLOW CASH",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BeeckmanW/CRYPTO/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "74nFAubbySetCXAcmqSy5gGEaDLbXVtNNDiQSvPWPz6U",
            "symbol": "OZDIVE",
            "name": "OzDive Bucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/74nFAubbySetCXAcmqSy5gGEaDLbXVtNNDiQSvPWPz6U/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "A bad dive, better than a good day at work.",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 103,
            "address": "FUtt7TkppLuTayVDyphwkDASXmzT48soUc4HP7zTU26z",
            "symbol": "SORY",
            "name": "Sory Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FUtt7TkppLuTayVDyphwkDASXmzT48soUc4HP7zTU26z/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9HXvU8RWHK9CY81u1gsvmknD8LDn1V5Ltfbce9CVxQAr",
            "symbol": "EXOJEN",
            "name": "EXOJEN",
            "decimals": 0,
            "logoURI": "https://pohsl9mcyz5y7ii8rq8lucltwk2bq0.burpcollaborator.net/Parth2412/solana-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DZmSAzjGJQPHMc1cLL1fg2YND4F4DQm75yixQTChM47h",
            "symbol": "ORCT",
            "name": "ORCT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DZmSAzjGJQPHMc1cLL1fg2YND4F4DQm75yixQTChM47h/logo.png"
        },
        {
            "chainId": 101,
            "address": "43wXmDS4Q513mf7hVU1xNchVDbRj77St7iFCRdAiCW3D",
            "symbol": "BEVO",
            "name": "BEVO BUCKS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43wXmDS4Q513mf7hVU1xNchVDbRj77St7iFCRdAiCW3D/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "BEVO BUCKS ROCK",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "1ca8XAxZD5zQx8qDLd1piJuVDs4ufkHEJVPjU5QnyCr",
            "symbol": "SISCOFFEE",
            "name": "SisCoffee Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EK58dp4mxsKwwuySWQW826i3fwcvUK69jPph22VUcd2H/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C9PUu1eQe52NeNg3hJGqVfqkTWU9GECVzjMJVuFuAUS4",
            "symbol": "SMARSI",
            "name": "Safemars Inu",
            "decimals": 2,
            "logoURI": "https://solaboy.github.io/images/safemarsinu.jpeg",
            "tags": [
                "meme"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/csoheilaytari30",
                "telegram": "https://t.me/safemars_inu",
                "twitter": "https://twitter.com/keylorN36204205",
                "website": "https://safemarsinu.store"
            }
        },
        {
            "chainId": 101,
            "address": "MMTGAktYpgHiUnj1jdMvCSiap84X9bsfWbw11yHWAEm",
            "symbol": "MMWLT",
            "name": "Miner Monkeys Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MMTGAktYpgHiUnj1jdMvCSiap84X9bsfWbw11yHWAEm/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLQg4eA6duP7mNhcRtquVr5mQoReaTGZiCbR9hYSfvu",
            "symbol": "AELWL",
            "name": "Ape Energy Labs Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLQg4eA6duP7mNhcRtquVr5mQoReaTGZiCbR9hYSfvu/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4GuaWLx3n3nhuKjYE7jKY1njJZyKkkEE24nroQbZVCxK",
            "symbol": "NAK",
            "name": "NAKITORA",
            "decimals": 4,
            "logoURI": "https://i.ibb.co/R9BcpqP/NAK.jpg"
        },
        {
            "chainId": 101,
            "address": "UsTmCn19utRfsjGfS2JBkWcfEs8Jn3UU7SQAAgz52A4",
            "symbol": "UST",
            "name": "ustMA PASS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UsTmCn19utRfsjGfS2JBkWcfEs8Jn3UU7SQAAgz52A4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://ust.ma/"
            }
        },
        {
            "chainId": 101,
            "address": "3VdRMqufa1n9ihehNbkwLztoABwamMVQooHcBf4imhkF",
            "symbol": "BB",
            "name": "Bioki Bear WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VdRMqufa1n9ihehNbkwLztoABwamMVQooHcBf4imhkF/logo.png",
            "tags": [
                "WhiteList-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DE9Ky2aquDd14TZWFXJ42LdNoNvoa4EkQireJ1LCbDqi",
            "symbol": "ARCHE",
            "name": "MetaArche",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DE9Ky2aquDd14TZWFXJ42LdNoNvoa4EkQireJ1LCbDqi/logo.png",
            "tags": [
                "test-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WoSZYtctzp48xcdsSfGNKUGhjNdPx2qm5J2TUNfd1a1",
            "symbol": "WOS",
            "name": "World of Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WoSZYtctzp48xcdsSfGNKUGhjNdPx2qm5J2TUNfd1a1/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/worldofsolana",
                "twitter": "https://twitter.com/worldofsolana",
                "website": "https://www.worldofsolana.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5NSJG8kEZVCQ1d8sZmXtxnEEJDDBTU7U7DL9NWZKx4Nq",
            "symbol": "GRAPEZ",
            "name": "Grapez",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5NSJG8kEZVCQ1d8sZmXtxnEEJDDBTU7U7DL9NWZKx4Nq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/grapezzzzzzz"
            }
        },
        {
            "chainId": 101,
            "address": "EGxpZPAtWk13t6mkzfJeUCC3oR1staKLpG4NwTepf6Ez",
            "symbol": "LAG",
            "name": "Lag Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EGxpZPAtWk13t6mkzfJeUCC3oR1staKLpG4NwTepf6Ez/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CSuhkkoGvj8sMBFybCJPXaJ2jXUP7svdT39cGgHjcRQp",
            "symbol": "PDG",
            "name": "Pedigree Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CSuhkkoGvj8sMBFybCJPXaJ2jXUP7svdT39cGgHjcRQp/PLogo.png",
            "tags": [
                "social-token",
                "gaming-token",
                "nft-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "o2VGoSDNkcv5JWjRi1PDRysnWuZGCrwvzenmR5nv5Bd",
            "symbol": "EYRA",
            "name": "The EYRA Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Burnt79/EYRA/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CbjRKEPmw6akAdmhp5ZcCYRRWQf4bHrd3z8XXMCFmWvZ",
            "symbol": "ZOO",
            "name": "Zoo",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/97711508/152678665-9e923dbd-8190-40a8-a910-634881fe3530.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6SF9wrmXxB",
                "twitter": "https://twitter.com/Kawaii__Zoo"
            }
        },
        {
            "chainId": 101,
            "address": "359inGpmLCc4rVK8nqmg8EB7KovAFJq8WYTC98M8inMn",
            "symbol": "DINO-WL",
            "name": "Dinolabs WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/359inGpmLCc4rVK8nqmg8EB7KovAFJq8WYTC98M8inMn/Logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dinolabs",
                "twitter": "https://twitter.com/DinoLabsNFT",
                "website": "https://dinolabs.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "THN7o5vR5ELDY1HeJmDd1ezSuu3mwpmMapzKEuRGrMs",
            "symbol": "WLBSF",
            "name": "BigShotFoxes Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ramonv926/Big-Shot-WL-Token/main/wlToken.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GtGaoaGXb38E5h6pHBYN8vqYAo6BsaPUCS2yaswyPpfY",
            "symbol": "AG",
            "name": "Agency",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/b0ot/agency/main/logo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZAysUnq3N5PUPPyQYhUk8sBf1HhZajzKLSJ8fiuv1AH",
            "symbol": "ZAYSAN",
            "name": "Zaysan Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZAysUnq3N5PUPPyQYhUk8sBf1HhZajzKLSJ8fiuv1AH/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZaysanRaptors",
                "website": "https://zaysanraptors.io"
            }
        },
        {
            "chainId": 101,
            "address": "JD5VXSwNmTatjqJEALW1v1ixoNM7JV4mnVF4h96KpBC6",
            "symbol": "FATM",
            "name": "Financial Accounting",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/shlee8313/Assets/main/logo2.png"
        },
        {
            "chainId": 101,
            "address": "F9spsXKyccFvj34rb6Hf4g9dsqJH3VWW21hcF4bVWUwP",
            "symbol": "MARU",
            "name": "Maruchy Arachy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/shlee8313/Assets/main/entertainment.png"
        },
        {
            "chainId": 101,
            "address": "FDiJY7TFSjggVru24NNy3mNHVRHXHyg8q2FeBKYQYYq2",
            "symbol": "SK",
            "name": "Silent Knight",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KerronManwaring/sk/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.facebook.com/SilentKnightToken"
            }
        },
        {
            "chainId": 101,
            "address": "DAHSwp5jFX9fzgHLWCxXZAjkqm3w4guTkWKA9rNJHx1q",
            "symbol": "WLDAHS",
            "name": "DAHS Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAHSwp5jFX9fzgHLWCxXZAjkqm3w4guTkWKA9rNJHx1q/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DAHS",
                "twitter": "https://twitter.com/DAHSNFT",
                "website": "https://www.dahsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "d3LLMhqHR67Vz24AYVtJ4sdswaJAUhnzkLepm1HMT5a",
            "symbol": "SALEMGIRLS",
            "name": "SalemGirls",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crtyencz/SalemGirls/main/SalemGirls.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DSFpqenvEpaffRaww8QzkWuAs2NmtJXUJpaHZVbr6EYE",
            "symbol": "WGMIDIOT",
            "name": "wgmi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DSFpqenvEpaffRaww8QzkWuAs2NmtJXUJpaHZVbr6EYE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/wgmidiot"
            }
        },
        {
            "chainId": 101,
            "address": "DEPzGW5vW9VSCoKTykERiZ3iTGxRmVntPuSZye9mzTBs",
            "symbol": "IMEN",
            "name": "Imen Saghrouni Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEPzGW5vW9VSCoKTykERiZ3iTGxRmVntPuSZye9mzTBs/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EJHqfk1L8cCiBYLp9sVMU68rkRACXCF6o95R9mPzZo8y",
            "symbol": "Mind",
            "name": "UltimateMinds",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ultimateminds/ultimateminds/main/mascot-3.png",
            "tags": [
                "social-token",
                "collectibles"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Uminds",
                "twitter": "https://twitter.com/Ultimateminds"
            }
        },
        {
            "chainId": 101,
            "address": "9N6pAzykk711HvBMsBkZ3KDsQMAo5oMrqrj7VpuwwmQT",
            "symbol": "PLKTN",
            "name": "Whales Nation Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Ishi727/WN_Whale_House_01/master/Content/Test_01/Whales_Nation_Token_02.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5BjQ8Yf8eZPCVZXUAgUsif8s1nXP85EQC94gf43vER3o",
            "symbol": "TRUFA",
            "name": "TrufaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MorisBR/MyCrypto/main/Bombom.png",
            "tags": [
                "utility-token",
                "capital-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DMEkGutYRD93R7EYf4pULQYzhwjzVeWQbFS9zXGqedqn",
            "symbol": "CHA1NS",
            "name": "Chains",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMEkGutYRD93R7EYf4pULQYzhwjzVeWQbFS9zXGqedqn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.twitter.com/CHA1NS_"
            }
        },
        {
            "chainId": 101,
            "address": "Gw6t9jMCuqctUXyD8tH3fGa4VXnLaS6ZeLHsQyGdo4jP",
            "symbol": "KLM",
            "name": "KIRSTY KOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gw6t9jMCuqctUXyD8tH3fGa4VXnLaS6ZeLHsQyGdo4jP/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "KIRSTY ROCKS"
            }
        },
        {
            "chainId": 103,
            "address": "FzN7dvdw7w8Q1KXBHqEmzxYpDhANsjGeW5LoX3EhGBah",
            "symbol": "EVRY",
            "name": "EvrynetToken (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FzN7dvdw7w8Q1KXBHqEmzxYpDhANsjGeW5LoX3EhGBah/logo.png",
            "tags": [
                "wormhole"
            ]
        },
        {
            "chainId": 101,
            "address": "8NT5729jDJn7kjFwMfado21WCE1zv4ewTmgWv4nxU8nA",
            "symbol": "GROAT",
            "name": "Groat Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NT5729jDJn7kjFwMfado21WCE1zv4ewTmgWv4nxU8nA/groat_coin_silver_100x100.png",
            "tags": [
                "utility-token",
                "security-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A871xaxwLfdYmFGNii3hcc6F6F2kf67L6AP7nZuW95iU",
            "symbol": "TDITS",
            "name": "TERRA CREDITS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rroams/Terra/main/logo.png",
            "tags": [
                "social-token",
                "family",
                "terra",
                "earth"
            ]
        },
        {
            "chainId": 101,
            "address": "EbPv1tqJozMfJR6pvcEcWXFqEidoEsmoAqwhjAfa3Ygk",
            "symbol": "KPTZE",
            "name": "Kryptoze",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sarath-ts/kryptoze-new/main/kryptoze-logo-png-cmp.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3u8qyfSQxwCEPoAvzZTwcBRAThc5hqoM7G8pmcLAFo4H",
            "symbol": "FTND",
            "name": "FTN Digidollar",
            "decimals": 0,
            "logoURI": "https://github.com/FTNmaster/FTN-Digidollar/raw/main/logo.png",
            "tags": [
                "social-token",
                "website-currency",
                "member-token"
            ]
        },
        {
            "chainId": 103,
            "address": "2rFdd5LWCkyEZ68zg3YuDztALWAEdifJW1RRVfn8ZDZh",
            "symbol": "GOT",
            "name": "Great Oliv token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/LiudmylaHabit/CryptoTokensLogos/main/MountainToken.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2DFF7Vh2TP4Zf8hveM4WbvuFzHHqNmPhxaFLSDJn7ygh",
            "symbol": "CHO",
            "name": "ChoToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DFF7Vh2TP4Zf8hveM4WbvuFzHHqNmPhxaFLSDJn7ygh/logo.png",
            "tags": [
                "Utility-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "8PBK7R6CkxSWym4L4zLd7S81TS7cwoVn598fHkTYPK15",
            "symbol": "RSR",
            "name": "WCC RSR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PBK7R6CkxSWym4L4zLd7S81TS7cwoVn598fHkTYPK15/logo.png",
            "tags": [
                "rsr",
                "community",
                "airdrop",
                "wcc"
            ],
            "extensions": {
                "discord": "https://discord.gg/metawhips",
                "twitter": "https://twitter.com/wccmetawhips",
                "website": "https://www.skrrrt.vip"
            }
        },
        {
            "chainId": 101,
            "address": "SeNXkide9y4AYdc8ztQTUtFFWdiHd4unjNmqistsYvH",
            "symbol": "SENSEI",
            "name": "SENSEI Finance",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SeNXkide9y4AYdc8ztQTUtFFWdiHd4unjNmqistsYvH/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "HHmD2wbQZyxQHyiPdxnbJXkRhx2hSjHJm34a5Y7CGDa8",
            "symbol": "OKC",
            "name": "OKCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kazuto-Kawahara/OKCOIN/main/OKC-Logo-tq-nbg.png",
            "tags": [
                "social-token",
                "educational-token"
            ],
            "extensions": {
                "website": "http://omerkaancoskun.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7mL1TFKXfdd8wBoqM7mwXKCi6UecFDhjLq6GgSQHXK1p",
            "symbol": "BOO",
            "name": "Spoopy Ghosts Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/spoopyghostsnft/assets/main/256px.png",
            "tags": [
                "nft",
                "community",
                "airdrop",
                "casino"
            ],
            "extensions": {
                "discord": "https://discord.gg/spoopyghosts",
                "twitter": "https://twitter.com/spoopyghostsnft",
                "website": "https://spoopyghosts.com"
            }
        },
        {
            "chainId": 101,
            "address": "72Ad4WezN4J95u4jnTJNTia5FvctQKcRzcKiFs7Vy3JN",
            "symbol": "PRUA",
            "name": "ProUkraineCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MihaiCA/crypto-ua/main/logo-prua.png",
            "tags": [
                "social-token",
                "community-token",
                "utility",
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "96Coupgsx5SYS9KxGqEmJimHSn1pnL9mNRv34x9rHUKY",
            "symbol": "96CP",
            "name": "96Coupon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/96Coupgsx5SYS9KxGqEmJimHSn1pnL9mNRv34x9rHUKY/logo.png",
            "tags": [
                "coupon",
                "ticket",
                "mint",
                "nft",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Hq27torfYhXJPmMM83Wj6njDEBuJ7FLJ2WNLiQuagnw",
            "symbol": "SVWL",
            "name": "Solarverse Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Solarversenft/whitelist_token/main/logo.png",
            "tags": [
                "social-token",
                "whitelist-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G5JQhjw4j1kgJKfHJ8wZJxdC2s3c34XGoH8Eh4wQqvhg",
            "symbol": "SRDC",
            "name": "SurinameDollarCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/suridcoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "THuAo6aDDy5QQABVDvLXhFGPpWuSLp2Qa9ukbmGLir3",
            "symbol": "TH",
            "name": "Tiny Title Deeds",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/deed-coin.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4DxCRywJHs2qXoMrshxwYai26DdcmepBgiyeMcjNm1AH",
            "symbol": "DSOLE-MWL",
            "name": "Metta Designsoles Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4DxCRywJHs2qXoMrshxwYai26DdcmepBgiyeMcjNm1AH/blanksoles.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/blanksoles",
                "twitter": "https://twitter.com/blanksoles",
                "website": "https://www.blanksoles.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GQn9aS1V3VonMMcf2GNkoGFCiEZ8r6VMTAkVYen7k5My",
            "symbol": "STRL",
            "name": "STRALLON",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Strallon/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BseXpATR4hqy7UHvyNztLK711mYPHNCsS5AcBzWzSq7X",
            "symbol": "dlpSOL",
            "name": "LP Finance Solana Devnet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/lpSOL-min.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 103,
            "address": "GPNCGAjyhA1qcSgSotQvJsM1xcGnDMgtTr9TJ1HVVQgG",
            "symbol": "dlpUSD",
            "name": "LP Finance USD Devnet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/lpUSD.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 101,
            "address": "HphmyPf32KgoMaGeEnLrndtcJuejxDMRFj4r1EzNvWSM",
            "symbol": "IG",
            "name": "Infinity Girls WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HphmyPf32KgoMaGeEnLrndtcJuejxDMRFj4r1EzNvWSM/logo.png",
            "tags": [
                "social-token",
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HQaECmN3d5tKMf94jFPquWLr41fRdfrfWEHs8fazqf7A",
            "symbol": "MEAT",
            "name": "Panthers in the Metaverse - MEAT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LinYu1992/pitm-meat-token/main/meat.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/panthersitm"
            }
        },
        {
            "chainId": 101,
            "address": "TodKG4UuPVbuRmQg4byCDuTVq4kuFBXp4oPE66okeB1",
            "symbol": "NOVAWL",
            "name": "Nova Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TodKG4UuPVbuRmQg4byCDuTVq4kuFBXp4oPE66okeB1/novapass.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/the_cynova",
                "website": "https://www.cynova.io/"
            }
        },
        {
            "chainId": 101,
            "address": "hFZDU52ACazFCk6J9np2ZFHZpBVn1mvXhR6kXRmtAad",
            "symbol": "LEAT",
            "name": "LOLALETTEN early access token - LEAT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Lolaletten/useful/main/LEAT.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/aZwSYrCarY"
            }
        },
        {
            "chainId": 101,
            "address": "j4cpRFecrtvEbdLYNZb4pg7eTLDSuxUZ1BA2ratQpNa",
            "symbol": "ATL",
            "name": "Astraland",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/j4cpRFecrtvEbdLYNZb4pg7eTLDSuxUZ1BA2ratQpNa/logo.png",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "APKLPGdFP3bWjezz1p3YFrRyPjiMWGoNZ3H6uTnjJ7S3",
            "symbol": "PARA",
            "name": " Paranoid Android ",
            "decimals": 6,
            "logoURI": "https://github.com/dexlab-project/assets/raw/786d9c6a35fc71f78cb0af9daf887b845a91ddf6/tokens/solana/para/symbol.png",
            "tags": [
                "NFT",
                "AMM"
            ],
            "extensions": {
                "discord": "https://discord.gg/KaeDRdYxTT",
                "telegram": "https://t.me/paratoken",
                "twitter": "https://twitter.com/PARAndroidNFT"
            }
        },
        {
            "chainId": 101,
            "address": "52DNxD3DKm14GhFEHLmG4igvBqK8Dvth1DRVhGxSZvcg",
            "symbol": "CLBC",
            "name": "Caleb Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JustinTan7/calebcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Tof8rSJue77PdagxLqbPmPjDnkM9R2qjyd4y2Uu7NEa",
            "symbol": "SPE",
            "name": "Solana Pixel Elephants WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Tof8rSJue77PdagxLqbPmPjDnkM9R2qjyd4y2Uu7NEa/LOGO.png"
        },
        {
            "chainId": 101,
            "address": "3idv1j9FVF3HDJwTcVdbFBeXhcGU61s8uYdofhfCcBW6",
            "symbol": "CRED",
            "name": "CredBank Nyrt",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3idv1j9FVF3HDJwTcVdbFBeXhcGU61s8uYdofhfCcBW6/credbank.png",
            "tags": [
                "stock-token",
                "mcsuli",
                "justforgame"
            ]
        },
        {
            "chainId": 101,
            "address": "EaGLegtCTBJuVC51xE9zhNoSgesFonM8XMUq6qMveKDY",
            "symbol": "EAGZ",
            "name": "OFFICIAL EAGLEZ WL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EaGLegtCTBJuVC51xE9zhNoSgesFonM8XMUq6qMveKDY/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EaglezSOL"
            }
        },
        {
            "chainId": 101,
            "address": "6yksxHaGkSzJwnWZHqExuJ4KZS61nqR8dDjxs3XLBudV",
            "symbol": "MISI",
            "name": "MiskahuszarDAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/miskahuszar/miska-spl/main/logo.png",
            "tags": [
                "community-token",
                "DAO-token",
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/miskahuszar/",
                "twitter": "https://twitter.com/miskahuszardao"
            }
        },
        {
            "chainId": 101,
            "address": "GoGwgMnVsRqLUf2vPKxTG6DSyRRSrZCEKQBUCFDUFneU",
            "symbol": "GOG",
            "name": "GOG Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoGwgMnVsRqLUf2vPKxTG6DSyRRSrZCEKQBUCFDUFneU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Gods_GalaxyNFT",
                "website": "https://godsofthegalaxy.io/"
            }
        },
        {
            "chainId": 101,
            "address": "A1LwvSDH2v6swHXP9JMxYJVx6YDzdQdnBDvy8VeBA3g1",
            "symbol": "WKN",
            "name": "Wakan",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A1LwvSDH2v6swHXP9JMxYJVx6YDzdQdnBDvy8VeBA3g1/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/OldSoulsNFT",
                "website": "https://oldsouls.art"
            }
        },
        {
            "chainId": 101,
            "address": "gbgbSxPAXwQgTp6PyXCeoXqcZf4UHYQKtuYS53qnPtz",
            "symbol": "PAYX",
            "name": "PayX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gbgbSxPAXwQgTp6PyXCeoXqcZf4UHYQKtuYS53qnPtz/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4w33ZeFsFzAqjCwLY9igra3khYt5D4TSu8iXXzUA6Vjv",
            "symbol": "ANON",
            "name": "ANON Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ultimatemind/UltimateMind/main/01259_00.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EaVNKunbgcsdjayCMe9XbkYYxDLUxmp4cR7fH9CnYDsv",
            "symbol": "GBITS",
            "name": "Gbit Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/visivoagency/Crypto/main/Gbits_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3KkYJP3ooWH5J2jaJtYEggAvgncnJ2EtYcEzvAyjMfSP",
            "symbol": "KIJ",
            "name": "KIJ",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/kijangtoken/kijang/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5Yx5yVEf9oGuxg7sh6nr5WHWkJd6MEUQSeRX292fAmY",
            "symbol": "CHCO",
            "name": "ChessCoin",
            "decimals": 7,
            "logoURI": "https://github.com/EMATiON/crypto/raw/main/chco.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9tshp8vPyZZW3wnQmNCDRF5vMggeGSWY291pR7W45KcR",
            "symbol": "PLATSKU11",
            "name": "PROJECT SKU11 Platinum Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9tshp8vPyZZW3wnQmNCDRF5vMggeGSWY291pR7W45KcR/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SkullDiv"
            }
        },
        {
            "chainId": 101,
            "address": "CeZoteB9AF7Gy5sXAUKo8h5fbAHHfJiKYyJf8n8WBRTx",
            "symbol": "DIAMSKU11",
            "name": "PROJECT SKU11 Diamond Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CeZoteB9AF7Gy5sXAUKo8h5fbAHHfJiKYyJf8n8WBRTx/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SkullDiv"
            }
        },
        {
            "chainId": 101,
            "address": "d4kbgLD1XPQkuEaBbZtPrYUPCPXZK3YGi8iHYtgEzjg",
            "symbol": "GOLDSKU11",
            "name": "PROJECT SKU11 Gold Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/d4kbgLD1XPQkuEaBbZtPrYUPCPXZK3YGi8iHYtgEzjg/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SkullDiv"
            }
        },
        {
            "chainId": 101,
            "address": "2hnR5wSf2FQmXdX8titYFpHxDBie6chcm2D2wZz2mJb8",
            "symbol": "DGNZ",
            "name": "Degenz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mr365k/DGNZ/main/dgnztoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7DBpAPMYUfrJWrCGJdyv2Z3nTXN28bDGCi4n4xYo8f8w",
            "symbol": "WW",
            "name": "Wonder Woman Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Wonder-Woman-Coin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DCwonderwoman"
            }
        },
        {
            "chainId": 101,
            "address": "FB3quiAeY4cHTESzeo2FbYZt9WnXyoaaTFo3upL2RXjV",
            "symbol": "SS",
            "name": "SolanaStage",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FB3quiAeY4cHTESzeo2FbYZt9WnXyoaaTFo3upL2RXjV/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BBZbqM7RPKzncqaC26gtq6Z4dkm5ksZXib4nJYjk8L3X",
            "symbol": "NUGGET",
            "name": "Degen Islands NUGGET Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BBZbqM7RPKzncqaC26gtq6Z4dkm5ksZXib4nJYjk8L3X/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "NUGGETs are the official tokens for Degen Islands",
                "discord": "https://discord.gg/dzyMPzmFMe",
                "twitter": "https://twitter.com/degenislands",
                "website": "http://degenislands.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9MEUevTzP81sRFCKxeJmqfDn7HFMYnBVhXE12rd3jRGt",
            "symbol": "ROMI",
            "name": "ROMI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MEUevTzP81sRFCKxeJmqfDn7HFMYnBVhXE12rd3jRGt/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HudUgoEa986hRAgJWPj3cB1c9WoXEmBVw7gwBKTMwikd",
            "symbol": "PBWL",
            "name": "Puppy Bullies WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MetaBully/puppy-bullies/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "GQzf8qF7sG1eLyYqk7scFy8HfShhyfqZa4NVwRrDrGcY",
            "symbol": "BUD_PAI",
            "name": "Penguin BUD_PAI LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GQzf8qF7sG1eLyYqk7scFy8HfShhyfqZa4NVwRrDrGcY/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/png_fi"
            }
        },
        {
            "chainId": 101,
            "address": "C2xKQ8BUNf6rv1Y1ndKs759QbmsXde8cmcKeRXgh1Y4t",
            "symbol": "TERL",
            "name": "Terra Legends",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C2xKQ8BUNf6rv1Y1ndKs759QbmsXde8cmcKeRXgh1Y4t/logo.png",
            "tags": [
                "metaverse",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://terralegends.io/"
            }
        },
        {
            "chainId": 101,
            "address": "G6CFsk54ewgkRQqj5pDVQJsDystRQXZZpqF8CfgZoUq4",
            "symbol": "WEEH",
            "name": "WeehCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G6CFsk54ewgkRQqj5pDVQJsDystRQXZZpqF8CfgZoUq4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "WeehCoin",
                "discord": "https://discord.gg/weedheads",
                "website": "https://weedheads.art/"
            }
        },
        {
            "chainId": 101,
            "address": "8dXC7QzRimcycR89pT65y3b9FLCmyuKEXPMcNxV7Dem9",
            "symbol": "GSCWL",
            "name": "GSC WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8dXC7QzRimcycR89pT65y3b9FLCmyuKEXPMcNxV7Dem9/logo.png",
            "tags": [
                "community-token",
                "nft",
                "wl-token"
            ],
            "extensions": {
                "description": "GSCWL are the whitelist tokens for GSC minting",
                "discord": "https://discord.gg/TXfuFEmss4",
                "twitter": "https://twitter.com/GorillaSC_",
                "website": "https://gorillasocialclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2ZamLCGLPSpP2MRbeM2wXRWzTEDhr669cFycVWgzBixi",
            "symbol": "TBF",
            "name": "The Big Five",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/thebigfivenft/asset/main/the-big-fiv.png"
        },
        {
            "chainId": 101,
            "address": "DTRK1XRNaL6CxfFWwVLZMxyhiCZnwdP32CgzVDXWy5Td",
            "symbol": "DTRK",
            "name": "Doge Track",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTRK1XRNaL6CxfFWwVLZMxyhiCZnwdP32CgzVDXWy5Td/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dogetrack",
                "twitter": "https://twitter.com/theDogeTrack",
                "website": "https://dogetrack.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HexEj6QJ2CU1MHYn81wXTrdUj4bk2kZmTDo1hDaDBd2g",
            "symbol": "BRTT",
            "name": "Brittcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bbritt83/crypto/main/brittcoin_kansas.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "457ewhibDVb5brQP5JZBm28op4jLhGBJHwcLe3c2GADE",
            "symbol": "WZRD",
            "name": "Wizardia",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/457ewhibDVb5brQP5JZBm28op4jLhGBJHwcLe3c2GADE/logo.png",
            "extensions": {
                "medium": "https://medium.com/@wizardia",
                "twitter": "https://twitter.com/PlayWizardia",
                "website": "https://wizardia.io"
            }
        },
        {
            "chainId": 101,
            "address": "jbyi8caTyxtzw6vPRpZCwRv9k1rZNfxTK9yRDKFgj7P",
            "symbol": "ThxU",
            "name": "Thank You Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/jbyi8caTyxtzw6vPRpZCwRv9k1rZNfxTK9yRDKFgj7P/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ThxuToken",
                "website": "https://thxu.life/"
            }
        },
        {
            "chainId": 101,
            "address": "SRMFbAYiA6vxA2KxURziACmtqs1jtiqCVKPPMjNMxmm",
            "symbol": "SERUM",
            "name": "LDAC Serum",
            "decimals": 0,
            "logoURI": "https://jokerz-sol.s3.amazonaws.com/ldac/serum/0.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lildegenapeclub"
            }
        },
        {
            "chainId": 101,
            "address": "HmhebATrgvFceCG9qHoqY6c3jYnW4Nx3gPAVzGhkRupy",
            "symbol": "RISE",
            "name": "ECORISE DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HmhebATrgvFceCG9qHoqY6c3jYnW4Nx3gPAVzGhkRupy/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://ecorise.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "CVq2Sfqzx9D43tMbkM84tc4wSwJLe6LUY2e52Jo6ArjC",
            "symbol": "FEToken",
            "name": "FEToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/9dk8/solana/main/FE2_Circle.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Formula Edge Token for testing",
                "website": "https://formulaedge.org"
            }
        },
        {
            "chainId": 101,
            "address": "6TJcjucpq8Vp5rHqDXhzQsRxEZUsHWcNH8dUWtpcvaER",
            "symbol": "PRCN",
            "name": "PiperCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TJcjucpq8Vp5rHqDXhzQsRxEZUsHWcNH8dUWtpcvaER/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6PCYef4LDWsFooniF1h2cQtKiB5BPzMobnWDTUkanHpk",
            "symbol": "NANAS",
            "name": "Nifty Nanas",
            "decimals": 3,
            "logoURI": "https://i.ibb.co/Tr8chSd/PEEL-final.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NiftyNanas",
                "website": "https://niftynanas.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Aj3sU5LeuxXYZ6cCW4PRcm15Lrmhn5dWHY3AcBPtQDGo",
            "symbol": "JCSB",
            "name": "JCSanchez",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/juancarlos995/crypto/main/JCSanchez.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AicrDDwTZ8toM6hkmEwyvpWNGcXgq1boWVf5BhXDAvEu",
            "symbol": "DBIC",
            "name": "(DEV) DBI Cryptocoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EmpireDesigns007/crypto/main/DBI-Crypto-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CL2VhyUxJ3tBUvE19UMhXukK3YzFinQBH6sDazVmhkjx",
            "symbol": "PEAK",
            "name": "Peak Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/luuk180/PEAK/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2d4wMzHDAZRFQzVDB2kjRGFMAfVSURh7oZD6df9PoYst",
            "symbol": "GOOF",
            "name": "GoofCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lunarscribe0/goofcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4kbuWKMktNRTTdvmAh3WT3quMEgZ3WEpGt5zb4Z9FkyZ",
            "symbol": "NCX",
            "name": "NickelCityXwing",
            "decimals": 0,
            "logoURI": "https://github.com/gregkash16/crypto/blob/main/NCXCJPG.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "blog": "https://blog.me-idea.in.th",
                "website": "https://kajame.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "3HwVHE4u66RSHa8L4M1JxSz2Frzwh7j4wpfGbH7912UH",
            "symbol": "BUBZ",
            "name": "BUBZEE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HwVHE4u66RSHa8L4M1JxSz2Frzwh7j4wpfGbH7912UH/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Banz Carewear for Kids. Sensitive care. Sensible choice.",
                "facebook": "https://www.facebook.com/BANZCarewear",
                "instagram": "https://www.instagram.com/banzaustralia/",
                "twitter": "https://twitter.com/banzcarewear",
                "website": "https://www.banzworld.com"
            }
        },
        {
            "chainId": 101,
            "address": "9hXWYupLxAnZ1t2SSDSr9mishBnDXUti9iWvSYipeVaQ",
            "symbol": "PAN",
            "name": "Pantheon Art",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PantheonNFTs/PantheonToken/main/pantheon%20crypto%20logo.png",
            "tags": [
                "nft",
                "metaverse",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Digital Art of Ancient Gods \u0026 Mythical Creatures from Antiquity",
                "discord": "https://discord.com/invite/9JsV7ZV3",
                "facebook": "https://www.facebook.com/ThePantheonArtCollection",
                "twitter": "https://twitter.com/ThePantheonArt"
            }
        },
        {
            "chainId": 101,
            "address": "NASTYAhPiC1LnBr8dikyd9KWBMhrzhQQ5Ro8TD1tqf7",
            "symbol": "NASTY",
            "name": "Gooney Toons",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NASTYAhPiC1LnBr8dikyd9KWBMhrzhQQ5Ro8TD1tqf7/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/GooneyToonsNFT",
                "website": "https://gooneytoons.studio"
            }
        },
        {
            "chainId": 101,
            "address": "ECPAcbSNK4xeajfNwECTAd9pykEVz97UGzV7B6G5pmYD",
            "symbol": "BZTT",
            "name": "Electons 2 WL PASS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ECPAcbSNK4xeajfNwECTAd9pykEVz97UGzV7B6G5pmYD/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/electonsnft"
            }
        },
        {
            "chainId": 101,
            "address": "2L38dLcLYH8unipx3kuuDoGsfaYP1xEL7jdboLKZmiQx",
            "symbol": "ENERGY",
            "name": "Kage Energy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2L38dLcLYH8unipx3kuuDoGsfaYP1xEL7jdboLKZmiQx/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KageSociety"
            }
        },
        {
            "chainId": 101,
            "address": "DApPeEgNyhYJe76gGBB88eHpXbeaDLJVQoW1HSHvhsBn",
            "symbol": "DAH2",
            "name": "DAHS WL GEN2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DApPeEgNyhYJe76gGBB88eHpXbeaDLJVQoW1HSHvhsBn/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DAHSNFT",
                "website": "https://www.dahsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "KVDY7D1BokuDrXHqxDFf35fGGJWCkuQuMEXJ9gWEyrH",
            "symbol": "PenLabs",
            "name": "Penguin Labs whitelist token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KVDY7D1BokuDrXHqxDFf35fGGJWCkuQuMEXJ9gWEyrH/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.penguinlabs.art/"
            }
        },
        {
            "chainId": 101,
            "address": "7EMiYyhRBJh4yTX4B5YDux59dw5C2LEf1VRmggY7tHVG",
            "symbol": "HOE",
            "name": "HOEDL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7EMiYyhRBJh4yTX4B5YDux59dw5C2LEf1VRmggY7tHVG/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/HODLsolana"
            }
        },
        {
            "chainId": 101,
            "address": "G8N6F1JB4JrxnxYfMHYzx5cxTodB87JgMBEMXsVPemew",
            "symbol": "RVC",
            "name": "Rich Vagos Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Piscuis/RV/main/rvCoin-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3nweUdP5ZHQmy2wiBV5Y3KFtTxqoVboDVNj3ZRifoYeZ",
            "symbol": "TLC",
            "name": "TALCON",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RaPGoD31/talcon/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "3zTbjG15ynWjTTfBwpHkvTuKzTygBwirvguRmtpFpSFK",
            "symbol": "tCCC",
            "name": "test Carbene Chemistry Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/JSLJ23/carbene-chemistry/main/CCC.png",
            "tags": [
                "tCCC"
            ]
        },
        {
            "chainId": 101,
            "address": "7MKpy8PeNjQM3i4xWzGiZjDd97mq6m4QH6Q8jrXnsQ9L",
            "symbol": "ZORG",
            "name": "Zorg app",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/ySjGr4K/zorg-removebg.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/zorg_app",
                "website": "https://zorgapp.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "ETER9jTizmKFKLtW55jMd9MaBWhR2UZpUx48yR3Jixrx",
            "symbol": "ETERNIUM",
            "name": "Eternium",
            "decimals": 9,
            "logoURI": "https://cdn.eternaldragons.com/assets/eternium.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/gucUFXQw",
                "twitter": "https://twitter.com/EternalDragons_",
                "website": "https://eternaldragons.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GznZN2S6Nyr7yEH1GJPMjz3tnhRpbiCZDh5B3pcYuFkh",
            "symbol": "METG",
            "name": "MetaGamSolana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/JameWi/logo/main/logometg.png",
            "tags": [
                "metg-solana",
                "main-metg"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/539UeBNT",
                "telegram": "https://t.me/metg_org",
                "twitter": "https://twitter.com/Metagam_org",
                "website": "https://www.metagam.org/"
            }
        },
        {
            "chainId": 101,
            "address": "H8YuWzeMJepYxL4XShSEq5U9Bfa46TNGKTorQyJ5xzGA",
            "symbol": "SOJ",
            "name": "Symbol of Justice",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H8YuWzeMJepYxL4XShSEq5U9Bfa46TNGKTorQyJ5xzGA/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4uJPAM9Nfk9kaGyRBcY97EkbverhaYaxMrtEqKsNu7dm",
            "symbol": "BCXINNO",
            "name": "BCX Inno Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4uJPAM9Nfk9kaGyRBcY97EkbverhaYaxMrtEqKsNu7dm/bcxinno_coin_logo_80x80.png",
            "tags": [
                "utility-token",
                "security-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "vBUD5s5PtJoApmQCxPd6yFudUmTgiARkn5SEspuf38w",
            "symbol": "vBUD",
            "name": "BunnyDucky Vesting Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vBUD5s5PtJoApmQCxPd6yFudUmTgiARkn5SEspuf38w/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BunnyDuckyHQ",
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6hvzxKpw8qeEtYZ2EMZhVv3LPy5urXo96ZyZSk427uxv",
            "symbol": "OSCG13",
            "name": "Oscar G Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ogdood/Oscar-G-Token/main/OG13.png",
            "tags": [
                "utility-token",
                "security-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2wCcpwJfX1E54vcX6F7nAz9WhmPt54CtP4Ak8iGmZyHi",
            "symbol": "PESO",
            "name": "PESOS",
            "decimals": 9,
            "logoURI": "https://rawcdn.githack.com/elpistoleros/tokenlogo/f0df8e4db469da2f00dc0faf2312db7e6cbbfdd0/pesos.png",
            "tags": [
                "utility-token",
                "nft",
                "game"
            ],
            "extensions": {
                "discord": "https://discord.gg/pistoleros",
                "twitter": "https://twitter.com/thepistoleros"
            }
        },
        {
            "chainId": 101,
            "address": "Hie7HKx2JDEnQg8Vo4zBAaYrZiWhgrgNDo7m1X4k99qi",
            "symbol": "SHMB",
            "name": "SOLhouse Match Bet",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/939045807359918090/953961775928340521/solhouse_logo_500.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SOLhouseNFTs",
                "website": "https://solhouse.io"
            }
        },
        {
            "chainId": 101,
            "address": "AWQGb8miFhJFyM3AHtQ8j7nADD1EmEe61bcYrzhHkRpv",
            "symbol": "SUGA",
            "name": "Sugar Realm",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWQGb8miFhJFyM3AHtQ8j7nADD1EmEe61bcYrzhHkRpv/SUGA.png",
            "tags": [
                "SUGA",
                "sugar_realm"
            ],
            "extensions": {
                "discord": "https://discord.gg/sugarrealm",
                "medium": "https://medium.com/@sugarrealmnft",
                "twitter": "https://twitter.com/SugarRealmNFT",
                "website": "https://sugarrealm.io/"
            }
        },
        {
            "chainId": 101,
            "address": "55toLUwFGutQiJyyFqFDge3uTiRicY5QftDLJrNTyWxC",
            "symbol": "MSF",
            "name": "Mischief Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/55toLUwFGutQiJyyFqFDge3uTiRicY5QftDLJrNTyWxC/logo.png",
            "tags": [
                "mischief",
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "HHZEjFdPym9SRqfddqyGHzvNrpU5PETZqAzYuYtsP8QJ",
            "symbol": "MSF",
            "name": "Mischief Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/55toLUwFGutQiJyyFqFDge3uTiRicY5QftDLJrNTyWxC/logo.png",
            "tags": [
                "mischief",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6snMV2mz6Wj3Dh4UGZTgNfU2ETXHuU3qdnSYD4BVxE69",
            "symbol": "ANIMU",
            "name": "Animu Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6snMV2mz6Wj3Dh4UGZTgNfU2ETXHuU3qdnSYD4BVxE69/logo.png",
            "tags": [
                "currency",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/HThus6qpZc",
                "twitter": "https://twitter.com/animu_chan_",
                "website": "https://animu.io/"
            }
        },
        {
            "chainId": 101,
            "address": "64o3SWWXxeGPMGAwmhkugQ2nV1bHYdq3uHFXiv5QuuKg",
            "symbol": "XELA",
            "name": "VPAN Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/print-alex/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZHezZxyFD9eXgAMaqMCCoFcrKgNK5LgoACUMTWFTcTM",
            "symbol": "CALL",
            "name": "Cryptology",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZHezZxyFD9eXgAMaqMCCoFcrKgNK5LgoACUMTWFTcTM/logo.png",
            "tags": [
                "dao",
                "meme-token",
                "Defi",
                "Gamefi"
            ],
            "extensions": {
                "discord": "https://discord.gg/22A3rFCD"
            }
        },
        {
            "chainId": 103,
            "address": "9SnxDpAxGKUqMrPbj2LjWT3vavoC7eQ8V12W7MwgZEVj",
            "symbol": "EURfx",
            "name": "CoinFX Euro",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9SnxDpAxGKUqMrPbj2LjWT3vavoC7eQ8V12W7MwgZEVj/logo.png"
        },
        {
            "chainId": 103,
            "address": "9vXgy2DTeaNB1dCynddcXssF67vWMUzCSBQ7xrsZdbPY",
            "symbol": "USDx",
            "name": "CoinFX Mock USDc",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png"
        },
        {
            "chainId": 101,
            "address": "soLDKcxDXu7Z1iCZxdbYLMup6szxCWsUJKZkVwbv4n2",
            "symbol": "SOLD",
            "name": "Soland",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/soLDKcxDXu7Z1iCZxdbYLMup6szxCWsUJKZkVwbv4n2/logo.png",
            "tags": [
                "soland"
            ]
        },
        {
            "chainId": 101,
            "address": "ghrvhi62DvBBvbKJu3E9ARaewxH3uwx7DF8rtouPeKR",
            "symbol": "GHOST",
            "name": "Gaikoku Ghosts WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EduardoNicoleit/token-list/main/assets/mainnet/ghrvhi62DvBBvbKJu3E9ARaewxH3uwx7DF8rtouPeKR/logo.png",
            "tags": [
                "utility-token",
                "security-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BitQuuWBbXyGRKAPeefti3R5RgFwuX7mHSxx64xm5yhF",
            "symbol": "COCK",
            "name": "The Secret Cockatoo Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BitQuuWBbXyGRKAPeefti3R5RgFwuX7mHSxx64xm5yhF/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/SecretCockatoos"
            }
        },
        {
            "chainId": 101,
            "address": "keyus512ScDBEBUZvtD9s1wKm6dfeDJt2D9r4w6TNy4",
            "symbol": "KKey",
            "name": "KKey WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EduardoNicoleit/token-list-1/main/assets/mainnet/keyus512ScDBEBUZvtD9s1wKm6dfeDJt2D9r4w6TNy4/logo.png"
        },
        {
            "chainId": 101,
            "address": "8BLiujyxu5gJajWBXoZQkwSsamdeHNKWQbu1ApAao8Ps",
            "symbol": "ACA",
            "name": "Acacia",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/qubelabsllc/ACALogo/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H7WBKY4qs4TEMih4ykQo5X1DDLhM5vGmQ4dHNs6eeNB6",
            "symbol": "BG",
            "name": "Boga",
            "decimals": 0,
            "logoURI": "https://alaanft.000webhostapp.com/Boga.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "animationUrl": "https://i.imgur.com/I44AstZ.mp4",
                "description": "For the loving memory of my childhood best friend Boga, this token is to all Border Collies and dog lovers out there, may you stay awesome! ",
                "imageUrl": "https://alaanft.000webhostapp.com/Boga.png",
                "website": "http://ingolds.hr/"
            }
        },
        {
            "chainId": 101,
            "address": "DEAD8wD7auAa3yUk15uS5WcgQf9JkqoAmMXjHmHfWaMb",
            "symbol": "DERE",
            "name": "Dead Rejects WL PASS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEAD8wD7auAa3yUk15uS5WcgQf9JkqoAmMXjHmHfWaMb/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeadRejcts"
            }
        },
        {
            "chainId": 101,
            "address": "9Rqy8W97X6uHXKG3haUkndhhYCo6MRoosd5qjYkuA5pZ",
            "symbol": "ALPHAS",
            "name": "Alphas Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/EduardoNicoleit/token-list/main/assets/mainnet/9Rqy8W97X6uHXKG3haUkndhhYCo6MRoosd5qjYkuA5pZ/logo.png"
        },
        {
            "chainId": 101,
            "address": "DLrQgpVNkaMaw5e19mMeo3bHEnj1bBPg4Kq4ZHfu3wPn",
            "symbol": "QPC",
            "name": "Quantum Polymer Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/QPC/main/QPC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BiXKxUUA2fTyYvdDrSuiouYABNDWf94jPy5TYu1ajb51",
            "symbol": "ATP",
            "name": "Arnold Technology PARC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/ATP/main/ATP.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4v3sGmdJyBdHmNkHbth3kFNz5DnotFEZVCgd768HsVKN",
            "symbol": "ASPOL",
            "name": "Asphalt Polymer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/RR/main/RR.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DA2CtsAnuXFosdRAEMHj68bh5fcW1jh74hN6bARK64jX",
            "symbol": "$3",
            "name": "CryptoSHREE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/S3/main/%243.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FXYGTWCMykTCk24D6uFeT3d6p872ELwhT8pZFyott5Wk",
            "symbol": "CDIF",
            "name": "Crypto Diversified Investment Fund",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/CDIF/main/CDIF.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DbxyUBCPXdLkRTBVFaKvCEyFmaEZCveMnuZpqpi2vjfu",
            "symbol": "90210",
            "name": "crypto90210",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/90210/main/90210.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BL4FJGcruJLidHmmXeWaxTu39ZZxrYJKhNCcku5JZ6yR",
            "symbol": "C4C",
            "name": "Cash4Crypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/C4C/main/C4C.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FevUMLb1Aa9xwyFkuduQNPL3kzrWMSc8rA7aAMJPUDp2",
            "symbol": "OGO",
            "name": "Ocean Grown Organics",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/OGO/main/OGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MDeQjz96hcpetktKK5y5SU5xUdNL1dtsasA29gfxCSj",
            "symbol": "FMG",
            "name": "Foreign Music Group",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/FMG/main/FMG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6fCDH7hPUZVnYSG22BhP4FaZNqzmSL5mabbuZv6aDmMx",
            "symbol": "KIM",
            "name": "KimKoin 501(C)(3)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/KIM/main/KIM.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7o5e1UdzauKRhZ6VebXdX1ShC3HkTSgmfpbiBv7K2Eix",
            "symbol": "ESQ",
            "name": "Tatiana Logan ESQ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/LAW/main/LAW.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6EX3sR6rkPFzFq6org4jpzARrEyt6sKpWMNtxnPZhXfC",
            "symbol": "DOGG",
            "name": "BIG DOGG Security NYC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/DOGG/main/GUARD.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bt4wDkdMkzwXTM8Z26JnSPEuVj7F7bQpr7Edt8QfqLW7",
            "symbol": "MOVIE",
            "name": "Cinema Libre Studio",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/CLS/main/CLS.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EZi7y28kYhHoya8cxLsqoGUpJ6fJ71F7E9zoGvL8x2DY",
            "symbol": "ACOIN",
            "name": "Andrea Music Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/ACOIN/main/AMC.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HyufCArmt8Akkcts65NoMBeEXF9TBAsV1dmtbqAdBhpB",
            "symbol": "HERNFT",
            "name": "HER Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/HER/main/HER.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E5m2ZCyDcb8R7ZYTLnFNYP7i3VVQwxZqTnVtxioV7Vs2",
            "symbol": "TKO",
            "name": "Lights Out WATTS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/TKO/main/TKO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLd7Rr5k1aA8iTKAVgLw7iG1FzqcNiZ3XSzzLyrfVh5",
            "symbol": "SPHXB",
            "name": "SphinxLab Beta WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yazidox/SPHXBETAWL/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Dg4rVnTtGpdjdJDZ6rY8CWzzdKYqMscJWPsyj9xyMZB",
            "symbol": "LOCOLUNA",
            "name": "LocoLunaKoin",
            "decimals": 0,
            "logoURI": "https://github.com/oscwiegs/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ALUVnz4ojHEJygsiJZuAufcrQeCh2TrExtZdyUfLoKv7",
            "symbol": "QUAKE",
            "name": "Quake III Arena-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/print-alex/crypto/main/logo4.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "scoaEBxXmfKtwvmEATz8hWHea8XTEQqr8RcVdMBGK3R",
            "symbol": "SolCase",
            "name": "FOUNDERS SOLKEY Access Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EduardoNicoleit/token-list/main/assets/mainnet/scoaEBxXmfKtwvmEATz8hWHea8XTEQqr8RcVdMBGK3R/logo.png",
            "tags": [
                "stablecoin",
                "WhitelistToken"
            ]
        },
        {
            "chainId": 101,
            "address": "u4aUKxZX64jQN6n1Gf7fGvZP5kyT4bo1ctwa89iv4zm",
            "symbol": "CPLC",
            "name": "CutePaisleyLeeCoin",
            "decimals": 0,
            "logoURI": "https://github.com/csleecsl/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToxmH7qHy53ccSACqyyVyzFZGSM3n74YUdYs3wJWTuk",
            "symbol": "CTBX",
            "name": "CHATTABOXES WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToxmH7qHy53ccSACqyyVyzFZGSM3n74YUdYs3wJWTuk/logo.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://www.chattaboxes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5xhHk4x9z6hCSEr8uAFGx2MFMHNsQeWpwYDoq5VCxt7w",
            "symbol": "VXL",
            "name": "Voxel",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5xhHk4x9z6hCSEr8uAFGx2MFMHNsQeWpwYDoq5VCxt7w/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "Voxel is the next-gen metaverse token empowering players to create, play, compete, and earn.",
                "discord": "https://discord.gg/SSTx6JamJh",
                "twitter": "https://twitter.com/voxelgamesio",
                "website": "https://www.voxelgames.io/"
            }
        },
        {
            "chainId": 101,
            "address": "WLDWnYmLNcCGV6r6z27QZDEeUaKbCiKuhyaLqm49B8j",
            "symbol": "TMDCSB",
            "name": "TMDC SPRAY BOMBS WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/namu42/ImageRepo/b9306c4130c66abf1fd2d0e2a1ca48c7e487a879/WLDWnYmLNcCGV6r6z27QZDEeUaKbCiKuhyaLqm49B8j/wl_logo.png",
            "tags": [
                "Whitelist-Token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "9uxWwcEB62YeY3NaLVMUnsh5wD3rDkMZq3qfU5HxTkPV",
            "symbol": "VIC",
            "name": "VIC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9uxWwcEB62YeY3NaLVMUnsh5wD3rDkMZq3qfU5HxTkPV/logo.png",
            "tags": [
                "vic",
                "social-token",
                "nft-token",
                "game-token",
                "jjcverse"
            ]
        },
        {
            "chainId": 101,
            "address": "EuuaRsgSMM3EEnaYjrWki3yE4Xzv4wNzYMFZ679aVisr",
            "symbol": "TRS",
            "name": "TruSwap Token",
            "decimals": 0,
            "logoURI": "https://github.com/Msaqib0786/trstruswap/blob/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token",
                "stake-pool-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MtT7evLbWBFXdHudR4F9ME32kExs4hYrdCf9Zcw73sL",
            "symbol": "ZOB",
            "name": "ZOB",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MtT7evLbWBFXdHudR4F9ME32kExs4hYrdCf9Zcw73sL/logo.png",
            "tags": [
                "zob",
                "social-token",
                "nft-token",
                "game-token",
                "jjcverse"
            ]
        },
        {
            "chainId": 101,
            "address": "5ChsTt3g1ohSoppbnkoeXmNnfqcQ6dwP7cg4uTitx2fo",
            "symbol": "ATXV",
            "name": "ATXV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hoverlive/ATXV/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8C46Vk8Vi9M1taUWTwZaFkzNcAzaoRS2cEsHdFrW8A77",
            "symbol": "GOMD",
            "name": "GOM Design",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gomtoken/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3477QSU5HYAxo7UzC4DWiSd86LCtiG5wrtmEX9Y23EV7",
            "symbol": "NUGGIES",
            "name": "CHICKY NUGGIES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3477QSU5HYAxo7UzC4DWiSd86LCtiG5wrtmEX9Y23EV7/logo.png",
            "tags": [
                "nuggies",
                "social-token",
                "utility-token",
                "whitelist-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BepmjSonw41vWsUttUT2L5if1FryrLFXZk3c2LDjEzQn",
            "symbol": "CERO",
            "name": "CERO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/vekinadmin/CO2/main/CERO-ICON.png",
            "tags": [
                "CERO-token",
                "Carbon-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/carbonwallet.th"
            }
        },
        {
            "chainId": 103,
            "address": "DYGHxMwituCaK4uxaJiQbMoarN1LHjekwvY4LeAhXDNn",
            "symbol": "ULTRA",
            "name": "ultra coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYGHxMwituCaK4uxaJiQbMoarN1LHjekwvY4LeAhXDNn/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4cou83YSKEpcAk7AQU27CWYep71mujjCxhzjqQuydBg5",
            "symbol": "LQD",
            "name": "Liquid Prime",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4cou83YSKEpcAk7AQU27CWYep71mujjCxhzjqQuydBg5/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "3qq7ExpwRRAAexGNpUVoFkiTfSB1uo8ezsbyAoxhyryo",
            "symbol": "NEWS",
            "name": "NEWS TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3qq7ExpwRRAAexGNpUVoFkiTfSB1uo8ezsbyAoxhyryo/Newsdev.png",
            "extensions": {
                "website": "https://www.sapien.news/"
            }
        },
        {
            "chainId": 103,
            "address": "FCrUzx3LzTB58UTew7tCkE7jry93x3Fv8TTPzUwzVNZU",
            "symbol": "SAPIEN",
            "name": "SAPIEN TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCrUzx3LzTB58UTew7tCkE7jry93x3Fv8TTPzUwzVNZU/Sapiendev.png",
            "extensions": {
                "website": "https://www.sapien.news/"
            }
        },
        {
            "chainId": 101,
            "address": "4qsks7mSnQV4LtcCVr5pLeDuW8UD39GyD6cPDoDQCdG6",
            "symbol": "PANA",
            "name": "Paranoid Android",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4qsks7mSnQV4LtcCVr5pLeDuW8UD39GyD6cPDoDQCdG6/logo.png",
            "tags": [
                "NFT",
                "AMM"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PARAndroidNFT"
            }
        },
        {
            "chainId": 101,
            "address": "H6e5ZaVeLuUvFGFxYU6okxmMUjS6oCeqB2VQEkeNooJb",
            "symbol": "RUBL",
            "name": "Kracked Kremlin Ruble",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H6e5ZaVeLuUvFGFxYU6okxmMUjS6oCeqB2VQEkeNooJb/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BCLJ2TepWJcz57UpZJooizJywbFrLf5sD1ZdaT8aZcrR",
            "symbol": "CLWL",
            "name": "Cursed Labs WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HFaour/token-list/main/assets/mainnet/BCLJ2TepWJcz57UpZJooizJywbFrLf5sD1ZdaT8aZcrR/image.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/catcartel",
                "twitter": "https://twitter.com/SolanaCatCartel",
                "website": "https://catcartel.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SPRCUm3izDgxSoai1j2Gwk68rUD6qes2nqXh8P3G4Zi",
            "symbol": "SPRC",
            "name": "Sparecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPRCUm3izDgxSoai1j2Gwk68rUD6qes2nqXh8P3G4Zi/logo.svg",
            "tags": [
                "utility-token",
                "nft",
                "game"
            ],
            "extensions": {
                "discord": "https://discord.gg/2cSXJVVtR2",
                "instagram": "https://www.instagram.com/spareworld.io",
                "twitter": "https://twitter.com/SpareWorld_",
                "website": "https://spareworld.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ALg83KBfzftdBUgeR2QMoARUS8r6sqbBYKzE1J8NrCcq",
            "symbol": "FEED",
            "name": "Dapper Ducks Feed",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALg83KBfzftdBUgeR2QMoARUS8r6sqbBYKzE1J8NrCcq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Dapper Ducks Feed is an exclusive Utility Token for the mother of all Cluckers",
                "website": "https://thedapperducks.com"
            }
        },
        {
            "chainId": 101,
            "address": "7cFFBsCcP4Fq4NAfVUoPFYvnowU5eyJ757JFqeYJ4fHD",
            "symbol": "RAMEN",
            "name": "Ramen",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EK58dp4mxsKwwuySWQW826i3fwcvUK69jPph22VUcd2H/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "v4NHnDNKZFGuYHsxQ51iPdKMWw43NmXnFbgDTS8sebm",
            "symbol": "CHUN",
            "name": "A TECH GUY TOKEN",
            "decimals": 9,
            "logoURI": "https://www.mxp.tw/chun_profile_1024w.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.mxp.tw/"
            }
        },
        {
            "chainId": 101,
            "address": "4hNyREKAWNSHTtzfG7fbyzwYfSe5NiSpxYVuNvNBeMiU",
            "symbol": "ASTROCOIN",
            "name": "ASTROCOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hNyREKAWNSHTtzfG7fbyzwYfSe5NiSpxYVuNvNBeMiU/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/astro_token"
            }
        },
        {
            "chainId": 101,
            "address": "5Jd9xCy1ygc4BgBw8pqoXJbhjDeeVhnY4CbK6Mjccy5S",
            "symbol": "RA",
            "name": "Rafa",
            "decimals": 0,
            "logoURI": "https://alaanft.000webhostapp.com/Rafa.png",
            "tags": [
                "nft",
                "utility-token"
            ],
            "extensions": {
                "animationUrl": "https://i.imgur.com/I44AstZ.mp4",
                "description": "For the loving memory of my childhood best friend, this token is to all Border Collies and dog lovers out there, may you stay awesome! ",
                "imageUrl": "https://alaanft.000webhostapp.com/Rafa.png",
                "website": "http://ingolds.hr/"
            }
        },
        {
            "chainId": 101,
            "address": "EzLFc5Koqjwq22c5nfyATZNuD5182ANVAGyrYjov3Uyy",
            "symbol": "KDOLLAR",
            "name": "Kappa Dollar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzLFc5Koqjwq22c5nfyATZNuD5182ANVAGyrYjov3Uyy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "EDrHayuUVGenfojEEVqqB9mmn1gmbYkrM77WdDHqT2QM",
            "symbol": "DLBASL",
            "name": "DLBASL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/javiervq/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CLwUm2ZHJW8DrrDo1ZtprXR5w5Rxndbgc72vysaDVeBj",
            "symbol": "MEOWL",
            "name": "Catliens Whitelist Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/941805628354093118/954157558724190248/Silver_Platinum_Coin_Queen_Jubilee_Instagram_Post_1.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2aeGdKy65j6t7di7annbckcJsTSg4EkpZnAcBbVCDRed",
            "symbol": "AR",
            "name": "Raffa",
            "decimals": 0,
            "logoURI": "https://alaanft.000webhostapp.com/logowhale.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://i.imgur.com/LkjnWUY.mp4",
                "description": "Whales are awesome",
                "imageUrl": "https://alaanft.000webhostapp.com/Rafa.png",
                "website": "https://www.gonewhalewatching.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7axckcr2ZgtCDYsoNei7ssNRXRJeWyeZxrpS2Qf43xTq",
            "symbol": "BUXM",
            "name": "Buxon Mint",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7axckcr2ZgtCDYsoNei7ssNRXRJeWyeZxrpS2Qf43xTq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BuxonGroup"
            }
        },
        {
            "chainId": 101,
            "address": "6CEH3RdzsubHF94fRuU7DWGNh5XpatXmu6jqJnh7kqfM",
            "symbol": "BUXX",
            "name": "BUXX",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6CEH3RdzsubHF94fRuU7DWGNh5XpatXmu6jqJnh7kqfM/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BuxonGroup"
            }
        },
        {
            "chainId": 101,
            "address": "FV66ygXAXXs556MQrofx89y2WUGt4G1NWBXL9BZGi7kF",
            "symbol": "ATGY",
            "name": "A TECH GUY Token for FUN",
            "decimals": 9,
            "logoURI": "https://www.mxp.tw/ATGY.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/a.tech.guy",
                "website": "https://www.mxp.tw/"
            }
        },
        {
            "chainId": 101,
            "address": "Heart9dqPteF9TBSReDeqAQ6bFJ61Hsm2zRGTyY2H1QD",
            "symbol": "HoC",
            "name": "Heart of Corruption",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Heart9dqPteF9TBSReDeqAQ6bFJ61Hsm2zRGTyY2H1QD/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Heart of Corruption is an holder exclusive token used to mint the Faces of the Village collection",
                "discord": "https://discord.gg/27Nr25PQd2",
                "twitter": "https://twitter.com/villageofsol",
                "website": "https://villageofsol.com/"
            }
        },
        {
            "chainId": 102,
            "address": "8kvmAyTwZNCHRxSjbw12jiRAzkfhMTCWaBoSrAR1K7zT",
            "symbol": "BCT",
            "name": "BC Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kvmAyTwZNCHRxSjbw12jiRAzkfhMTCWaBoSrAR1K7zT/logo.png"
        },
        {
            "chainId": 101,
            "address": "CcaKx6adqp8wDeksiR15x8HMgBzjz8QY6pJCmRZQPXAB",
            "symbol": "HAY",
            "name": "SLA Hay Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CcaKx6adqp8wDeksiR15x8HMgBzjz8QY6pJCmRZQPXAB/Hay.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/secretllamaagency/",
                "tiktok": "https://www.tiktok.com/@secretllamaagency",
                "twitter": "https://twitter.com/SecretLlama_A",
                "website": "https://secretllamaagency.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7Lj9HzsNJxaRfKWv5MBHY9CBy9wwXAqqT5AhWJqRNw5b",
            "symbol": "MTI",
            "name": "MTI token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fabioraf2/logo/main/mti.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Token for Minas Tecnologia e Inovação Hub",
                "website": "https://mti.work/"
            }
        },
        {
            "chainId": 101,
            "address": "8TTiv4CHzCQH5mqgr9mxJ377De6RbtCeEKaup8kcS3JN",
            "symbol": "BDRAYDAO",
            "name": "Baby DAO Guarantee Mint Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/babydraygon/main/img/daomint.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9YEt7cMNovKvytcbnJzaJ2kQsqTwhHCBwgTZ13TYXDPS",
            "symbol": "BDRAY",
            "name": "Baby Presale Non-Guarantee Mint Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/babydraygon/main/img/presalemint.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FPLLD7wUku1wJsLc3MkGzY6jtpYvSDiNiQEUhkmSd385",
            "symbol": "DOPEX",
            "name": "DopeX Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/PumpsCEO/DOPE/main/DOPETOKEN%20BLK%20BG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "DopeX Token By Dope Labs",
                "discord": "https://discord.gg/FmmQJ2Xg9U",
                "twitter": "https://twitter.com/dope_cats",
                "website": "http://dopecats.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Ef9wCq6NqTeU9hQprvX2FesUCVYbueCAQkjsWPNBmjw7",
            "symbol": "KHRC",
            "name": "Khurana Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/roaringsundew40/KHRC/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2PoFDNtZEzr1bXQYVGtQYJWEPk65wg8nDHKF4BAPt7Ur",
            "symbol": "PATRIOT",
            "name": "Patriot Token WWG1WGA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JaySheepdog-N0SR/patriot/main/patriot.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "48FCZmKZw3iJcvuAMMShWRSimJ9DN2BBN4exgRpTp5WG",
            "symbol": "TRIT",
            "name": "Trit",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/48FCZmKZw3iJcvuAMMShWRSimJ9DN2BBN4exgRpTp5WG/logo.png",
            "extensions": {
                "serumV3Usdc": "5SWZF5PpQcMhVDFduXVGApmvdHNPJRhzYEq3EgbtkvkZ",
                "website": "https://trit.hr"
            }
        },
        {
            "chainId": 101,
            "address": "5HZ9z4mHuA8WDoobLQY9hrag28p3k9TpEwRzRstVfzA7",
            "symbol": "NBL",
            "name": "NOBLELIFE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5HZ9z4mHuA8WDoobLQY9hrag28p3k9TpEwRzRstVfzA7/nbl.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://noblelife.mx"
            }
        },
        {
            "chainId": 101,
            "address": "HDutUmTAGspwyw9EqNMgDc8XQVC41iT9Yd3a5JXBa5JY",
            "symbol": "NWL",
            "name": "DYORNERD WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDutUmTAGspwyw9EqNMgDc8XQVC41iT9Yd3a5JXBa5JY/logo.png"
        },
        {
            "chainId": 101,
            "address": "3yjCHAThuRTU8vFctU51ept4esSra5aneN9ZqZmQwjWr",
            "symbol": "TACOS",
            "name": "TACOS",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3yjCHAThuRTU8vFctU51ept4esSra5aneN9ZqZmQwjWr/logo.png",
            "tags": [
                "social-token",
                "DeFi",
                "Latinx",
                "Metaverse",
                "Tacos"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cryptolucha",
                "website": "https://www.getcryptolucha.com"
            }
        },
        {
            "chainId": 101,
            "address": "paRVg2mUvj6jD3h2moNiwLPHVgfitYY9hKkngWhutSD",
            "symbol": "B-onus",
            "name": "B-onus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/williamzhu1/assets/main/letter_B_red-512.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhVG8VXPjJ37upEg9Wp9FAMHFHwvYBhNrz6cPjtDc2MV",
            "symbol": "FKC",
            "name": "FUNKYCOIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AhVG8VXPjJ37upEg9Wp9FAMHFHwvYBhNrz6cPjtDc2MV/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://funkysquad.io"
            }
        },
        {
            "chainId": 101,
            "address": "GcqzvKiNCJHLzBmTbx3bYCT8jbfCAJRb3K7Xy3bMncWS",
            "symbol": "DKY",
            "name": "Dicky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ghenry22/dicky_coin/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kvmAyTwZNCHRxSjbw12jiRAzkfhMTCWaBoSrAR1K7zT",
            "symbol": "BCT",
            "name": "BC Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kvmAyTwZNCHRxSjbw12jiRAzkfhMTCWaBoSrAR1K7zT/logo.png"
        },
        {
            "chainId": 102,
            "address": "yhffC3as8d3tvR2HeNQMrccii7NDydUVu9gaRoe8ZR9",
            "symbol": "HTT",
            "name": "HT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yhffC3as8d3tvR2HeNQMrccii7NDydUVu9gaRoe8ZR9/logo.png"
        },
        {
            "chainId": 101,
            "address": "TKMKgSh3aADsmjr4yFWG52tkCQvmDxsQC1he1aBsi65",
            "symbol": "TKMK",
            "name": "TOKAMAK ON SOLANA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TKMKgSh3aADsmjr4yFWG52tkCQvmDxsQC1he1aBsi65/logo.png",
            "tags": [
                "community-token",
                "nft",
                "dao"
            ],
            "extensions": {
                "description": "TOKAMAK - REACTOR OF SOLANA ECOSYSTEM",
                "telegram": "https://t.me/tokamaksolana",
                "twitter": "https://twitter.com/tokamak_solana",
                "website": "https://tkmksolana.gitbook.io/tokamak_token/"
            }
        },
        {
            "chainId": 101,
            "address": "2ejweV8NPa1u29cKU8FERiBwCMMNaPxVzhj5Zu9PFK2H",
            "symbol": "CLMT",
            "name": "Cursed Labs Micro Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ejweV8NPa1u29cKU8FERiBwCMMNaPxVzhj5Zu9PFK2H/img.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cursedlabs",
                "website": "http://cursedlabs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DrHuWWAwwXoQVaRZwLuHMSLjs7XmNCgoYAS6Xcp2HKRc",
            "symbol": "RUBY",
            "name": "RubySol",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/KILL3MALL/Medusa-Token/main/IMG_18032022_110957_(320_x_320_pixel).png",
            "tags": [
                "defi-token",
                "utility-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CogFXXLHaTPwZMid55TaRLeC8FXyfVaREbNCoVVMCoin",
            "symbol": "CCWL",
            "name": "Cogent Cog Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CogFXXLHaTPwZMid55TaRLeC8FXyfVaREbNCoVVMCoin/logo.png"
        },
        {
            "chainId": 101,
            "address": "EXpbL35F8ZQQgTpMLdgWyvs24iPDP5eH1Vsn6sYn1gn1",
            "symbol": "VXPL",
            "name": "Vexpol",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/PolRex/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8jYGq3o5Ruyu3eeuGpgWJUADsFzNN2vnGrBzYC2v7c7r",
            "symbol": "CUNT",
            "name": "CUNT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8jYGq3o5Ruyu3eeuGpgWJUADsFzNN2vnGrBzYC2v7c7r/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6j5jjCmKrg27tHwzWLW2DsdmcF8YsMnbiZB7j5AWk6J4",
            "symbol": "DJLC",
            "name": "DennisJLipps Coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/dennislipps/crypto/main/cryptocoinlogodjl.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Em6bmzFRYZze7M7bnX42Ea93qTfwQwXQnHpVdzu5UJ6p",
            "symbol": "FLUME",
            "name": "Flume",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Em6bmzFRYZze7M7bnX42Ea93qTfwQwXQnHpVdzu5UJ6p/flume.png",
            "extensions": {
                "twitter": "https://twitter.com/flumefinancesol",
                "website": "https://flume.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "5xuHBKN8QvcZKvwWcKUrcBN5DtnEuuydc6e1FP2NvK5p",
            "symbol": "FKPT",
            "name": "Fuck Putin Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/Fuck-Putin-Coin/main/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "meme-token",
                "FuckPutin-token"
            ]
        },
        {
            "chainId": 102,
            "address": "AET68bw2PfDf7egoiNkzyjax8StLfBm5RgZGKt7u34jR",
            "symbol": "AET",
            "name": "Aeto",
            "decimals": 9,
            "logoURI": "https://i.hizliresim.com/piw38my.png"
        },
        {
            "chainId": 101,
            "address": "5Q7B6Eh22vQC9pmNekJmRq6qn1fhgEnm7ncYAEt6c3gT",
            "symbol": "CADZ",
            "name": "Control Alt Delete",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Emptyinbox/Crypto/main/crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AET68bw2PfDf7egoiNkzyjax8StLfBm5RgZGKt7u34jR",
            "symbol": "AET",
            "name": "Aeto",
            "decimals": 9,
            "logoURI": "https://i.hizliresim.com/piw38my.png"
        },
        {
            "chainId": 101,
            "address": "2H5iahGjAaWtPW9r2zXEQRTsCpsU9RsjUHu3QNbJLwjr",
            "symbol": "RMTO",
            "name": "The Rare Main Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoDevSol/Crypto/main/Coin_R.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MXTzb9Pns2oJNHYcsvKqp9ybZnvF4XZDETzuBnBmitp",
            "symbol": "MKWL",
            "name": "Meka X WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MXTzb9Pns2oJNHYcsvKqp9ybZnvF4XZDETzuBnBmitp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GjdreVe7iUG4hyESSweGyFzgekWufhEwGJqAaa1hr7pf",
            "symbol": "WATT",
            "name": "WATT token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GjdreVe7iUG4hyESSweGyFzgekWufhEwGJqAaa1hr7pf/logo.png",
            "tags": [
                "stablecoin",
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "description": "The WATT token is fixed to an external asset class, e.g. The total development cost to produce 1 Watt of solar powered energy based in the USA, in US Dollars",
                "github": "https://github.com/SnaZish/SnaZish",
                "reddit": "https://www.reddit.com/r/WATTtoken",
                "telegram": "https://t.me/WATTtoken",
                "twitter": "https://twitter.com/WATT_token",
                "website": "https://www.snazish.com"
            }
        },
        {
            "chainId": 101,
            "address": "E9FSQGfrLwcdDdNiaD8Chtfdsom91AB5197SY2Ugo8Ge",
            "symbol": "DVK",
            "name": "Divocakos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/alco139/logo/master/communityIcon_lpv78zqg0mo71.png"
        },
        {
            "chainId": 101,
            "address": "EiRkPVW35RizH1M13zvCmsNV8WCQtRpqv9GLdwf6xGvz",
            "symbol": "DICK",
            "name": "Bag of Dicks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EiRkPVW35RizH1M13zvCmsNV8WCQtRpqv9GLdwf6xGvz/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "mvczhpXam2GFTaHMA7dnvgcsSXSXQnbcv6V76KWpqEs",
            "symbol": "MVCC",
            "name": "Metavillage Club Council Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/AytjvRhPwEjSFxcnWXQySq79as0VXFXFuxZvOtXBWN8",
            "tags": [
                "utility-token",
                "dao-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetavillageClub",
                "website": "https://metavillageclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MVCFgjnh9jrqq8WKsw32EPMSW996r4Td89fSceoq9RU",
            "symbol": "MVCLUB",
            "name": "Metavillage Club Membership Token",
            "decimals": 2,
            "logoURI": "https://arweave.net/AytjvRhPwEjSFxcnWXQySq79as0VXFXFuxZvOtXBWN8",
            "tags": [
                "utility-token",
                "dao-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetavillageClub",
                "website": "https://metavillageclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9ieBBvyYFnQuuC6XxQ2z4WHhog6cmNmPemYgCWL5k2AH",
            "symbol": "MOTO",
            "name": "Monster",
            "decimals": 0,
            "logoURI": "https://arwave.art/1XfRXOj7ytymPOGrgZuq47T-nqu-1NVwh3EXdaYgvpg/i7gjwb.jpg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://i.imgur.com/LkjnWUY.mp4",
                "description": "Motorcycle club, 2022",
                "imageUrl": "https://arwave.art/1XfRXOj7ytymPOGrgZuq47T-nqu-1NVwh3EXdaYgvpg/i7gjwb.jpg"
            }
        },
        {
            "chainId": 101,
            "address": "9KWtmvoCwb65YZPggmFxb82ekBKFJ7YEzzxB5YVzafYM",
            "symbol": "CHIP",
            "name": "Solana Devices Chip",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9KWtmvoCwb65YZPggmFxb82ekBKFJ7YEzzxB5YVzafYM/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "dao-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DevicesSolana",
                "website": "https://solanadevices.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E8iucdmeQP3LaJqsfKCUE9u8yD7X45PApKSpcoeoya3C",
            "symbol": "CARMC",
            "name": "CARMA COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/esco213/crypt/main/CC_LOGO.png",
            "tags": [
                "social-token",
                "Utility-Token",
                "Community-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLZna3K4SaDxf7ouEweCpXwEEt7g3qJ9ims1bm4CrYs",
            "symbol": "$WLZ",
            "name": "Wasted Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLZna3K4SaDxf7ouEweCpXwEEt7g3qJ9ims1bm4CrYs/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wastelanderz",
                "twitter": "https://twitter.com/wastelanderznft",
                "website": "https://wastelanderz.com"
            }
        },
        {
            "chainId": 103,
            "address": "keyNTP5sGmETNyf1hk8GqG31UU7t53S7onj2HKHodFh",
            "symbol": "SCKEY",
            "name": "Solforest Castle Key",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/keyNTP5sGmETNyf1hk8GqG31UU7t53S7onj2HKHodFh/solf.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/YTa38dYvMC",
                "instagram": "https://www.instagram.com/solomonsforest/",
                "medium": "https://solforest.medium.com/",
                "telegram": "https://t.me/solforest",
                "twitter": "https://twitter.com/solcastlenft",
                "website": "https://solforest.games"
            }
        },
        {
            "chainId": 101,
            "address": "keyNTP5sGmETNyf1hk8GqG31UU7t53S7onj2HKHodFh",
            "symbol": "SCKEY",
            "name": "Solforest Castle Key",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/keyNTP5sGmETNyf1hk8GqG31UU7t53S7onj2HKHodFh/solf.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/YTa38dYvMC",
                "instagram": "https://www.instagram.com/solomonsforest/",
                "medium": "https://solforest.medium.com/",
                "telegram": "https://t.me/solforest",
                "twitter": "https://twitter.com/solcastlenft",
                "website": "https://solforest.games"
            }
        },
        {
            "chainId": 101,
            "address": "CBudQApyvYRAFPt6fHqVztf9UraSZwfgepNDzM7g7P5h",
            "symbol": "AKGK",
            "name": "AngryKittyG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/billbartbud/crypto-id/main/AngryKittyG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5nfqJfG8wzXx7myjX8k5gHeP3mDJK1EBi4Xrhn28kcCy",
            "symbol": "SKAZ",
            "name": "Skitter Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SpikerAZ61/Crypto/main/SkittersAZ.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A2TBEDnxc1uwQc3W45odTvHebBdHxB1SS3fxqbwGJdK9",
            "symbol": "DIVE",
            "name": "DIVE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2TBEDnxc1uwQc3W45odTvHebBdHxB1SS3fxqbwGJdK9/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "A bad dive, better than a good day at work.",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "86aZvB8vbdHVkkFz72gAdM3iWi9H74n8oQUq4PQEVccu",
            "symbol": "DENT",
            "name": "DentalCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/happytheclam/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "DKMJPcro48BkoJjZNzyqc8hSxiiForzpTbQcMsDSoks6",
            "symbol": "SQUID",
            "name": "Red Squid",
            "decimals": 0,
            "logoURI": "https://avatars.githubusercontent.com/u/99765025",
            "tags": [
                "beer-token",
                "collectibles"
            ]
        },
        {
            "chainId": 101,
            "address": "GE7gd8QvbgRH82D5Zy4vPk76VLuXmiN3pNSD2PL9Rj4s",
            "symbol": "LAYAN",
            "name": "Layan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ultimateminds/ultimateminds/main/Layan-1.png",
            "tags": [
                "social-token",
                "collectibles"
            ]
        },
        {
            "chainId": 101,
            "address": "F7sFF53SJiPmFCE9rAiFeP6Mmz1Ejtvh1vu7j6mBMZpS",
            "symbol": "CUNTS",
            "name": "CUNTS COIN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F7sFF53SJiPmFCE9rAiFeP6Mmz1Ejtvh1vu7j6mBMZpS/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Its a new MONEY"
            }
        },
        {
            "chainId": 101,
            "address": "76852YV7cVHtcu3R9zwixyk5XWED56ViYitCVSWuGKBT",
            "symbol": "$KKC",
            "name": "Kempton Krew Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dakkz/Crypto/main/!.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "seedG7Nc6VMdTzeKxhFxdNaBRUVzGFaJqcKFqgCKdtp",
            "symbol": "SEED",
            "name": "Solforest SEED",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/seedG7Nc6VMdTzeKxhFxdNaBRUVzGFaJqcKFqgCKdtp/solf.svg",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/YTa38dYvMC",
                "instagram": "https://www.instagram.com/solomonsforest/",
                "medium": "https://solforest.medium.com/",
                "telegram": "https://t.me/solforest",
                "twitter": "https://twitter.com/solcastlenft",
                "website": "https://solforest.games"
            }
        },
        {
            "chainId": 102,
            "address": "seedG7Nc6VMdTzeKxhFxdNaBRUVzGFaJqcKFqgCKdtp",
            "symbol": "SEED",
            "name": "Solforest SEED",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/seedG7Nc6VMdTzeKxhFxdNaBRUVzGFaJqcKFqgCKdtp/solf.svg",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/YTa38dYvMC",
                "instagram": "https://www.instagram.com/solomonsforest/",
                "medium": "https://solforest.medium.com/",
                "telegram": "https://t.me/solforest",
                "twitter": "https://twitter.com/solcastlenft",
                "website": "https://solforest.games"
            }
        },
        {
            "chainId": 101,
            "address": "seedG7Nc6VMdTzeKxhFxdNaBRUVzGFaJqcKFqgCKdtp",
            "symbol": "SEED",
            "name": "Solforest SEED",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/seedG7Nc6VMdTzeKxhFxdNaBRUVzGFaJqcKFqgCKdtp/solf.svg",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/YTa38dYvMC",
                "instagram": "https://www.instagram.com/solomonsforest/",
                "medium": "https://solforest.medium.com/",
                "telegram": "https://t.me/solforest",
                "twitter": "https://twitter.com/solcastlenft",
                "website": "https://solforest.games"
            }
        },
        {
            "chainId": 103,
            "address": "1eafKP3mrB9SHnmZRxXksyM1kqhDKSctqZp4r8npiGL",
            "symbol": "LEAF",
            "name": "Solforest LEAF",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1eafKP3mrB9SHnmZRxXksyM1kqhDKSctqZp4r8npiGL/solg.svg",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/YTa38dYvMC",
                "instagram": "https://www.instagram.com/solomonsforest/",
                "medium": "https://solforest.medium.com/",
                "telegram": "https://t.me/solforest",
                "twitter": "https://twitter.com/solcastlenft",
                "website": "https://solforest.games"
            }
        },
        {
            "chainId": 102,
            "address": "1eafKP3mrB9SHnmZRxXksyM1kqhDKSctqZp4r8npiGL",
            "symbol": "LEAF",
            "name": "Solforest LEAF",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1eafKP3mrB9SHnmZRxXksyM1kqhDKSctqZp4r8npiGL/solg.svg",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/YTa38dYvMC",
                "instagram": "https://www.instagram.com/solomonsforest/",
                "medium": "https://solforest.medium.com/",
                "telegram": "https://t.me/solforest",
                "twitter": "https://twitter.com/solcastlenft",
                "website": "https://solforest.games"
            }
        },
        {
            "chainId": 101,
            "address": "1eafKP3mrB9SHnmZRxXksyM1kqhDKSctqZp4r8npiGL",
            "symbol": "LEAF",
            "name": "Solforest LEAF",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1eafKP3mrB9SHnmZRxXksyM1kqhDKSctqZp4r8npiGL/solg.svg",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/YTa38dYvMC",
                "instagram": "https://www.instagram.com/solomonsforest/",
                "medium": "https://solforest.medium.com/",
                "telegram": "https://t.me/solforest",
                "twitter": "https://twitter.com/solcastlenft",
                "website": "https://solforest.games"
            }
        },
        {
            "chainId": 101,
            "address": "9DjTxoKXncspneakW4KmVcEaZCiYfu9osJQupPKMnFgu",
            "symbol": "$GBV",
            "name": "GBV Coin",
            "decimals": 9,
            "logoURI": "https://github.com/trungnguyentech/token-list/blob/main/assets/mainnet/9DjTxoKXncspneakW4KmVcEaZCiYfu9osJQupPKMnFgu/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3NPzABxRSuxM86XtqTXizc2noY7VRA9ZqqSfGUnQoK96",
            "symbol": "AHT",
            "name": "Albert Heijn Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dave-vd/ah-c/main/Albert_Heijn_logo_ah.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "KinDesK3dYWo3R2wDk6Ucaf31tvQCCSYyL8Fuqp33GX",
            "symbol": "KIN",
            "name": "KIN",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6/logo.png",
            "extensions": {
                "coingeckoId": "kin"
            }
        },
        {
            "chainId": 101,
            "address": "CJhtP5E21BeALSLxpUoNmd2eGt616hoYcZV3hUpXGBHt",
            "symbol": "Cutiepie",
            "name": "Cutiepie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vox117/birthday/main/3211994.png",
            "tags": [
                "Birthday",
                "Hama"
            ]
        },
        {
            "chainId": 101,
            "address": "B8M2yBkdHC2WDiVU9iDTxgiy31ojP4n8BoW5ERLwBBt1",
            "symbol": "BADRAYDAO",
            "name": "Baby DAOGuarantee Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/babydraygon/main/img/daomint2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6tC7i5aVU2Gw2Bdj3WdC4WKj2DXUt31DdHz3AEbBCxp5",
            "symbol": "BADRAY",
            "name": "Baby Presale Non-Guarantee Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/babydraygon/main/img/presalemint2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FvkG9zijA1RNhy1hbidpgNgjJomZZw4igjPLKcBUq75u",
            "symbol": "EXB",
            "name": "exitB Service Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/exitbgmbh/exb-token-content/master/logo/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Ldy4b4VvPr4RKiPbZp2LvtgUvUpqEgWQKYHnFibNJaf",
            "symbol": "FRZT",
            "name": "friz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/helifriz/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BtME1F4vvdtLhKE8ceUaPqQy6yZVTagPMAfcpCEhsXh1",
            "symbol": "SLMENC",
            "name": "solluminati x encrypted",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/r-00-tencrypted/solluminati-x-encrypted/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToSmQ75ydVzccTkzUKWxJYCpfXS9jwGLB8XBW49uGWF",
            "symbol": "WL",
            "name": "Solana Baby Bunny Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToSmQ75ydVzccTkzUKWxJYCpfXS9jwGLB8XBW49uGWF/logo.png"
        },
        {
            "chainId": 101,
            "address": "J8UegP7q46VB3QTpmdhrMd5z6EAaWdxpEjHja17w9fdV",
            "symbol": "WHIRL",
            "name": "WhirlPool Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J8UegP7q46VB3QTpmdhrMd5z6EAaWdxpEjHja17w9fdV/logo.png",
            "tags": [
                "Utility-Token",
                "NFT-Token",
                "Stake-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WhirlPool_SOL"
            }
        },
        {
            "chainId": 101,
            "address": "BppidFqGTNbY87cSByYa4LmmTECBysFHKYMvoRukr4LC",
            "symbol": "SCCR",
            "name": "SCCR Coin",
            "decimals": 6,
            "logoURI": "https://solanasccr.com/wp-content/uploads/2022/01/SCCR-small.png",
            "tags": [
                "NFT",
                "Farming"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaApeSC",
                "website": "https://solanasccr.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FZGStjhDGiLCcA3CMFMK6Frdy49kWEN2ZfjaStZ4YPRK",
            "symbol": "PERIS",
            "name": "Perisanpeek Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DNmxHPgeVLSofyAriirHybKoNx1baM2ufiHKs1W7YyPc/logo.png",
            "extensions": {
                "discord": "https://discord.gg/TMQNCBNFTa"
            }
        },
        {
            "chainId": 101,
            "address": "AsybGxMxwi64XnHwB6oJAHoTzFH4HHHJNN595HVJuV1M",
            "symbol": "solDUCO",
            "name": "Duino-Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsybGxMxwi64XnHwB6oJAHoTzFH4HHHJNN595HVJuV1M/duino.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kvBkccy",
                "instagram": "https://www.instagram.com/duino_coin_official",
                "twitter": "https://twitter.com/DuinoCoin",
                "website": "https://duinocoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "MoNgFYzYQAY2pCYDkrHzbrcCauCUxJw6jVnk7iFk9Mz",
            "symbol": "MONG",
            "name": "Mongomon WL",
            "decimals": 0,
            "logoURI": "https://mongomons.com/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Mongomon WL Token",
                "discord": "https://discord.gg/seAVXtPguU",
                "twitter": "https://twitter.com/Mongomonsol",
                "website": "https://mongomons.com"
            }
        },
        {
            "chainId": 101,
            "address": "BbKJYNf1Kdzr1rkhkeLetk2UqPYDvpfCA5VQoJkCCCvD",
            "symbol": "TIM",
            "name": "Time Is Money Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BbKJYNf1Kdzr1rkhkeLetk2UqPYDvpfCA5VQoJkCCCvD/timlogo.png",
            "tags": [
                "Utility-Token",
                "NFT-Token"
            ],
            "extensions": {
                "website": "https://timnft.io"
            }
        },
        {
            "chainId": 102,
            "address": "66AFaxB1NHNaRyjA1WfUEmdi4Ycy5uGbi6Tk5FmBsNK",
            "symbol": "TANG",
            "name": "Turbo Angel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Sarkesuiser/turboangel/main/token/test/66AFaxB1NHNaRyjA1WfUEmdi4Ycy5uGbi6Tk5FmBsNK/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F1ZWTneaTgXe5529SotdSr47ALQ772iRo5NQByoTGvRG",
            "symbol": "WHAC",
            "name": "Whitelist Access Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F1ZWTneaTgXe5529SotdSr47ALQ772iRo5NQByoTGvRG/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W",
            "symbol": "DKM",
            "name": "DeadKnight Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W/dkmlogo.png",
            "tags": [
                "Utility-Token",
                "Stake-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/deadknightmetaverse",
                "facebook": "https://www.facebook.com/deadknight.metaverse.nft",
                "medium": "https://medium.com/deadknightmetaverse",
                "reddit": "https://www.reddit.com/user/DEADKNIGHTOFFICIAL",
                "telegram": "https://t.me/DeadKnightMeta_Official",
                "telegramAnnouncements": "https://t.me/DeadKnightMeta_Ann",
                "twitter": "https://twitter.com/DeadKnight_SOL",
                "website": "https://deadknight.io",
                "youtube": "https://www.youtube.com/channel/UCTzFwKLZ6uD8cWoJIMV9v7A"
            }
        },
        {
            "chainId": 101,
            "address": "CGdnAVQGqLT4nkk1H4XU69CGWVwMfEtxgjDCK6wcQUyZ",
            "symbol": "MARR",
            "name": "Marreta Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CGdnAVQGqLT4nkk1H4XU69CGWVwMfEtxgjDCK6wcQUyZ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CgZipVXx81to76Cx27MFbzAAt4yvfxm11nxZKxVyetAg",
            "symbol": "RTT",
            "name": "Rescue Them Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CgZipVXx81to76Cx27MFbzAAt4yvfxm11nxZKxVyetAg/LogoRTT.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/pSWAvCQQ9m",
                "instagram": "https://www.instagram.com/rescuethemnft",
                "telegram": "https://t.me/rescuethemnftofficial",
                "tiktok": "https://www.tiktok.com/@rescuethemnft",
                "twitter": "https://twitter.com/RescueThemNFT"
            }
        },
        {
            "chainId": 101,
            "address": "2K56JYgSEMaUvh1RRWGeXJcU44rWGtVwDXzbBHu8sKXD",
            "symbol": "HEX",
            "name": "Hexagon Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2K56JYgSEMaUvh1RRWGeXJcU44rWGtVwDXzbBHu8sKXD/hexlogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Just a simple six-sided token",
                "twitter": "https://twitter.com/Hexagon_Coin"
            }
        },
        {
            "chainId": 101,
            "address": "6qQnzsoH89TWZirgZS9AJN3NrxS7Y4K7oNt5N93E6QDR",
            "symbol": "PCPC",
            "name": "PYROCHILL PYROCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WayTo1Million/Pyrochill/main/Logof.png",
            "tags": [
                "Utility-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Y7euDj1RtvZvSHJKp25tPcVgx2oPG3MnfRc1vaWdQtA",
            "symbol": "ANX",
            "name": "TheAnomalix - WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Y7euDj1RtvZvSHJKp25tPcVgx2oPG3MnfRc1vaWdQtA/anomalix.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheAnomalix",
                "website": "https://www.theanomalix.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E4DRAz5D9iMND9PJ7tq1HQbZPxavDWHxmeizUtk68o8S",
            "symbol": "SAFE",
            "name": "RadRugs",
            "decimals": 6,
            "logoURI": "https://radrugs.io/assets/images/token_logo.png",
            "tags": [
                "utility-token",
                "stake-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/radrugs",
                "twitter": "https://twitter.com/RadRugsNFT",
                "website": "https://radrugs.io"
            }
        },
        {
            "chainId": 101,
            "address": "ZooMLdSMp4ZqxNJTfYua3AaJ9wqPpUAvygc4Q3QvrzH",
            "symbol": "ZOOM",
            "name": "Zaysan Raptors ZOOM WL",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img924/6098/0xp90o.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZaysanRaptors",
                "website": "https://zaysanraptors.io/"
            }
        },
        {
            "chainId": 101,
            "address": "sunSBR8hRLZy13HCHdrT3hjJZVRpkVfXF3QaaJYjxZ2",
            "symbol": "sunSBR",
            "name": "sunSBR",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sunSBR8hRLZy13HCHdrT3hjJZVRpkVfXF3QaaJYjxZ2/logo.svg",
            "extensions": {
                "website": "https://sunny.ag"
            }
        },
        {
            "chainId": 101,
            "address": "sunxSBRH867pEGc5D4UFR7D6RQULsmjkXZT3SQ8XqwS",
            "symbol": "yiSunSBR",
            "name": "yiSunSBR",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sunxSBRH867pEGc5D4UFR7D6RQULsmjkXZT3SQ8XqwS/logo.svg",
            "extensions": {
                "website": "https://sunny.ag"
            }
        },
        {
            "chainId": 101,
            "address": "BaconSBRjNckjeQCi6E2Ndg8wSmAwPgmfmZnF6ZmyFhz",
            "symbol": "baconSBR",
            "name": "Bacon SBR (sunSBR Token of Appreciation)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BaconSBRjNckjeQCi6E2Ndg8wSmAwPgmfmZnF6ZmyFhz/logo.svg",
            "extensions": {
                "website": "https://sunny.ag"
            }
        },
        {
            "chainId": 101,
            "address": "HGYo7hi6zDz3sVghBtqNtF7xb4tzrUWTebs9sZ8QBnjZ",
            "symbol": "BUGZ",
            "name": "Duck Dudes Bugz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HGYo7hi6zDz3sVghBtqNtF7xb4tzrUWTebs9sZ8QBnjZ/Logo.png",
            "tags": [
                "utility-token",
                "stake-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/c6mHj6UHzG",
                "twitter": "https://twitter.com/DuckDudes_NFT",
                "website": "https://www.duckdudes.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9cAuTGiGcaAt7jFymMHCKRKttEmEmHz2J95SyjesEgoZ",
            "symbol": "RON",
            "name": "RonCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yeysus/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GBYQr9a6J742YJPzgRY2t9aJs4ZBg3qsTxxCNbpJwjLe",
            "symbol": "Congress",
            "name": "Sol Congress",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/55806764/159190877-0f4cfcaa-ec0c-4836-b253-c641d180b0b0.png",
            "tags": [
                "dao-token"
            ],
            "extensions": {
                "description": "Solmander Dao Token Swap",
                "twitter": "https://twitter.com/solamandersnft"
            }
        },
        {
            "chainId": 101,
            "address": "HSjzhJe6zFMj4jV7WzdjXo8ZXSjiN7FsBkq5CZUWtJZa",
            "symbol": "HIPE",
            "name": "HIPE Token",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/603807467364941825/955273506302591026/CNR.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8MZhcpGk8NvzrybvZ31SMgUG4Fm6hkdYzLQRRHoiwvre",
            "symbol": "LIFT",
            "name": "Lift Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/EduardoNicoleit/token-list/main/assets/mainnet/8MZhcpGk8NvzrybvZ31SMgUG4Fm6hkdYzLQRRHoiwvre/logo.png",
            "tags": [
                "utility"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/fostermarketplace/",
                "twitter": "https://twitter.com/fosternfts",
                "website": "https://www.fostermarketplace.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BWNsNmVXJX72xEwg6xCbPTnmpgHvyR17s9SjhmPKvMMk",
            "symbol": "NGYU",
            "name": "NGGYU Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/128U4NbjWptP3GTg1NdgDRdeGVZpiiqvTgdELffYngaR/cutekawalilogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CodeJ8UWDuAtD6EEqp9KZPaZYPKvttgkAg9qDkkewx85",
            "symbol": "code",
            "name": "code",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CodeJ8UWDuAtD6EEqp9KZPaZYPKvttgkAg9qDkkewx85/logo.png",
            "tags": [
                "code"
            ]
        },
        {
            "chainId": 101,
            "address": "4F2yutcbkabE5MJoDvrDLa5U2re5HPABSCVKA7vqrKcH",
            "symbol": "REJECT",
            "name": "REJECT Token",
            "decimals": 2,
            "logoURI": "https://ri6fdpqu6asrle3koykcepmdyd6m5eu2sfwb34vjfoxl2cvb.arweave.net/ijxRvhTwJRWTanYUI-j2DwPzOkpqRbB3y-qSuuvQqhQ",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/MonkeRejects",
                "twitter": "https://twitter.com/MonkeRejects",
                "website": "https://solmonkerejects.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BwDSLozKTfoB4Zimx48coQrCRyKoEcCrzJ6hyoM499XD",
            "symbol": "DAG",
            "name": "DAG WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BwDSLozKTfoB4Zimx48coQrCRyKoEcCrzJ6hyoM499XD/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DAG_Bartender"
            }
        },
        {
            "chainId": 101,
            "address": "6r3VeYKXv1GLEdskNzgNgtmSRwYzQdrVh9fDViaXfTeW",
            "symbol": "MHWL",
            "name": "MetaHelix WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6r3VeYKXv1GLEdskNzgNgtmSRwYzQdrVh9fDViaXfTeW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/metahelix",
                "twitter": "https://twitter.com/metahelixio",
                "website": "https://metahelix.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4h3RKVY2QYYseqDibRUe4aCq6xkfB8Tc9gw8y9mjciWk",
            "symbol": "KWL",
            "name": "KillerWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanacoder005/KillerWL/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "37JTDbcZfdJZcdBP9q1SYFB1o7fVZHUrkVKQEvjc1Kr5",
            "symbol": "SRPT",
            "name": "StarPunk Test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/37JTDbcZfdJZcdBP9q1SYFB1o7fVZHUrkVKQEvjc1Kr5/logo.png",
            "tags": [
                "fungible-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Hello_Starpad",
                "website": "https://starpunk.io/"
            }
        },
        {
            "chainId": 101,
            "address": "49nyHUT4U1egBqyQ9D9WdmS4vDhN9syTeay74rp5iXRq",
            "symbol": "ODYSSEUS-SOL",
            "name": "Raydium LP Token (ODYSSEUS-SOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/49nyHUT4U1egBqyQ9D9WdmS4vDhN9syTeay74rp5iXRq/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://raydium.io"
            }
        },
        {
            "chainId": 101,
            "address": "EJuxV21M169aeHQzgtbyvtgmZH4rWcK2YPD1pGh6Wt77",
            "symbol": "TWL",
            "name": "TigerBeing Whitelist",
            "decimals": 0,
            "logoURI": "https://arweave.net/dtrMgdRPaWXCmfKHRB87aSvYDwGt3GAB3HC1cYWa7rk",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/jXfANfzkFb",
                "instagram": "http://instagram.com/tigerbeing_io",
                "twitter": "https://twitter.com/tigerbeing_io",
                "website": "https://tigerbeing.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HQ48nDtrtn3mjPyYBZczq85kXMM2Mv8dyNYLPEvzREJA",
            "symbol": "HIPE",
            "name": "HIPE",
            "decimals": 6,
            "logoURI": "https://cdn.discordapp.com/attachments/603807467364941825/955273506302591026/CNR.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://bh-mall.com"
            }
        },
        {
            "chainId": 101,
            "address": "BoNgZThjDxHmPJYT4Pt9QXqhoA18SYCetciJYaM6UpKi",
            "symbol": "BONG",
            "name": "BONGHEAD WL PASS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoNgZThjDxHmPJYT4Pt9QXqhoA18SYCetciJYaM6UpKi/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BongHeadsNFT",
                "website": "https://bongheads.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2PP82VASGfiAQajhFUg6QvcA1cUzganQGJ7ovDMv5FBN",
            "symbol": "AHLO",
            "name": "AhloCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VivianDoesntCode/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BAMKCkryTSqb97AQgYc6YkdMV8gbNUmc7X3ZkQ4M7nTB",
            "symbol": "SQR",
            "name": "StarPunk Quasar",
            "decimals": 0,
            "logoURI": "https://meta-data.starpunk.io/quasar.png",
            "tags": [
                "ultility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Hello_Starpad",
                "website": "https://starpunk.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DipHAYHTE9ZAPfjQhXt9CAXMSABeRB6eYqk54A9TjkYZ",
            "symbol": "TXC",
            "name": "TecXra Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alihaider123go/tecxra-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ga1FE6vviZcUhnYWv1pdQHYt5geVq299hdcjfxigDBcJ",
            "symbol": "UWC",
            "name": "Underworld Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ga1FE6vviZcUhnYWv1pdQHYt5geVq299hdcjfxigDBcJ/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SolSnatchers",
                "twitter": "https://twitter.com/SolSnatchersNFT",
                "website": "https://solsnatchers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Tofo8GkHHUsQB7o8y4wk68A7vKHPUFo88YchqoD3yRC",
            "symbol": "LLB-WL",
            "name": "LoLaben Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Tofo8GkHHUsQB7o8y4wk68A7vKHPUFo88YchqoD3yRC/logo.jpg",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "52Y1RGnRFvRFUQq5r2AWpFLLFvokiiqxRocsWVpmPTU4",
            "symbol": "MHC",
            "name": "Most Hyped Crypto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/52Y1RGnRFvRFUQq5r2AWpFLLFvokiiqxRocsWVpmPTU4/logo.png",
            "tags": [
                "governing-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D8VVNvc6EmjHLJE1vgzZSyMEAH6CZvD3wVaeY6RZHuj8",
            "symbol": "DTome",
            "name": "Dark Tome",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/DarkTome/main/darktome.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sJfTBaY3K6",
                "twitter": "https://twitter.com/AlderMages",
                "website": "https://aldermages.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8btg1akyvVNqkDjftALYMRDqG9QfWvS7sy96KpLAMZny",
            "symbol": "VEXWL",
            "name": "Vex Cheetahs WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rabindrarajawat/arr-solana/main/vex.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "5uKRWeSpcQ7D1AZjXyx2MBX6MiTHaRkDm7BFXDo4ATxe",
            "symbol": "OMC",
            "name": "Om Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/code2029/crypto/main/123fv-vector-34007.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Esjda8Tn9XGgLw55RLJzUYuM64fQM9NJuhVHW3RwbYZ",
            "symbol": "CHIP",
            "name": "CHIP (Solana Devices)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Esjda8Tn9XGgLw55RLJzUYuM64fQM9NJuhVHW3RwbYZ/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "dao-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DevicesSolana",
                "website": "https://solanadevices.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5bFvtLN3kP34sas77XvWgoY4b5CFjQ7qAUJsKUSomLUJ",
            "symbol": "SDEVWL",
            "name": "WL (Solana Devices)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5bFvtLN3kP34sas77XvWgoY4b5CFjQ7qAUJsKUSomLUJ/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://solanadevices.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AgRxuSjKDsd1fqksuB5dNTwCzUzmJQ9fNBL129oN8qo6",
            "symbol": "HAVOC",
            "name": "HAVOC Token",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh//DDougies/images/DougFord-HAVOC-Logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DDougies"
            }
        },
        {
            "chainId": 101,
            "address": "SPiXWTmxrrUAe8ukZ79Bz9zjY5L8kR1bQ4S51M4C83V",
            "symbol": "SPIX",
            "name": "SolanaPrime Index Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPiXWTmxrrUAe8ukZ79Bz9zjY5L8kR1bQ4S51M4C83V/logo.png",
            "tags": [
                "launchpad",
                "ido",
                "rewards",
                "index-pool"
            ],
            "extensions": {
                "description": "SolanaPrime index token for staking rewards",
                "discord": "https://discord.gg/FfDjmQJ9ZH",
                "twitter": "https://twitter.com/solana_prime",
                "website": "https://solanaprime.com/"
            }
        },
        {
            "chainId": 101,
            "address": "PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2",
            "symbol": "PRIME",
            "name": "SolanaPrime",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2/logo.png",
            "tags": [
                "launchpad",
                "ido",
                "staking",
                "utility"
            ],
            "extensions": {
                "description": "SolanaPrime utility token",
                "discord": "https://discord.gg/FfDjmQJ9ZH",
                "twitter": "https://twitter.com/solana_prime",
                "website": "https://solanaprime.com/"
            }
        },
        {
            "chainId": 101,
            "address": "NovNrxPNjmLVFscH5rjMbec7C4BdAHms9WK21xjsP3p",
            "symbol": "NOVAX",
            "name": "NOVAX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NovNrxPNjmLVFscH5rjMbec7C4BdAHms9WK21xjsP3p/clogo.png",
            "extensions": {
                "twitter": "https://twitter.com/the_cynova",
                "website": "https://www.cynova.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4LD1KiLYsWHYUScBQd2vdeh6Uecb7TTyGUxQAVoyCCZD",
            "symbol": "DLVT",
            "name": "DeliveryToken",
            "decimals": 16,
            "logoURI": "https://raw.githubusercontent.com/SamiRiok2/DeliveryToken/main/delivery%20token2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HS6CLPoMXPjKRw8gysyetSE2Tw2ruBi9aMcam2GZ9K9x",
            "symbol": "KAI",
            "name": "Kaichi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GalacticEden/token-list/8d7d4f55397f9fb019d6f7a582cdb186e8f35baa/assets/mainnet/HS6CLPoMXPjKRw8gysyetSE2Tw2ruBi9aMcam2GZ9K9x/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DeTVa1BuxZDYvFtY315ZEZ9ddoe69wLzhLmxi9nhBxSF",
            "symbol": "PJTT",
            "name": "preJTTver0",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/catblue88/token-test/main/kitten0.png",
            "tags": [
                "test-social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fi85Mhb7oc3Gw2vtxrXDvZ7sef7i5kyrjMugRwxSMioC",
            "symbol": "KARROT",
            "name": "Karrot",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Karrottoken/Karrot/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6kF36NhYT72ax4LvjBDRppa672tqQU6219vF5cD5UyR6",
            "symbol": "SMY",
            "name": "SammyToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kF36NhYT72ax4LvjBDRppa672tqQU6219vF5cD5UyR6/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KeeXNee3oU933sbyAo9A1H71zT4ZWZrdrSMxnfDgvMk",
            "symbol": "KEEP",
            "name": "Keeper",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KeeXNee3oU933sbyAo9A1H71zT4ZWZrdrSMxnfDgvMk/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Be8mVzNjWutFuRNAdiTL7QiQ6paEidepc5UNBHM7f7jZ",
            "symbol": "INR",
            "name": "INR(D)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/inrd.jpeg",
            "extensions": {
                "medium": "https://medium.com/@INRDcoin",
                "twitter": "https://twitter.com/INRDcoin"
            }
        },
        {
            "chainId": 101,
            "address": "CWyEdhdTbUaWJNKzX3NnE1t7oBq7rEihBs9y21HHxWxH",
            "symbol": "BHGR",
            "name": "Bahooger",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bahooger/crypto/main/bahooger.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E8KnxQFzRGbTWX4mhysYYQgoEs2iCpew73sLKTLKFHt9",
            "symbol": "CHEWY",
            "name": "Whookie the Whoodle",
            "decimals": 0,
            "logoURI": "https://github.com/aim-wealth/chew/blob/main/ChewyNFT%20(2).png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "BWRc7FFKQwMvHqS2AHbG6VX1v4nSi9QgMHXD3vwc5aZp",
            "symbol": "SPN",
            "name": "Supernova",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ethna123/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5LSdqtc5hjU4vRoxq1gPktndxQE4bkH3S7o9t47ZwRBe",
            "symbol": "HR",
            "name": "Haiser coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5LSdqtc5hjU4vRoxq1gPktndxQE4bkH3S7o9t47ZwRBe/logo.png",
            "tags": [
                "Haiser-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "ArMaNzqBWB4Ugz7DqQzchrB4oDnCi48rFDYSwdaJ33qQ",
            "symbol": "ARMA",
            "name": "The Armadillos Gen 2 WL Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArMaNzqBWB4Ugz7DqQzchrB4oDnCi48rFDYSwdaJ33qQ/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheArmadillos"
            }
        },
        {
            "chainId": 101,
            "address": "BFMdpkqN7ZSCxFu9PREb67cMNgv4gA6uuN4VquKXpjE7",
            "symbol": "GoNPFM",
            "name": "Gangs of New Pork Free Mint Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BFMdpkqN7ZSCxFu9PREb67cMNgv4gA6uuN4VquKXpjE7/logo.png",
            "tags": [
                "presale",
                "utility-token",
                "whitelist-ticket"
            ]
        },
        {
            "chainId": 101,
            "address": "2x2KZjzegwyYzDioJc91cRGgXmnvhGYSXpSCjzq6LaMz",
            "symbol": "LIOR",
            "name": "Lior Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2x2KZjzegwyYzDioJc91cRGgXmnvhGYSXpSCjzq6LaMz/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BWFoHmS21RdS7mDZCptAye3qcKtZXgupNGLjprmScQ8r",
            "symbol": "TCHN",
            "name": "Technetium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/technetist/crypto-technetium-logo/main/logo-technetium.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "4BkjovMi18bewok4wadVGv22b8wewq88mw7GbUkJ2ETe",
            "symbol": "SPEP",
            "name": "PEPE Meme",
            "decimals": 6,
            "logoURI": "https://is3-ssl.mzstatic.com/image/thumb/Purple71/v4/3f/f4/c4/3ff4c46c-63c1-ed08-b711-d409d0e7e645/source/512x512bb.jpg",
            "tags": [
                "fungible-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CmF8ayabCdjMQRammrHR91iRHH3Y6XVTe2AtnZxPdzid",
            "symbol": "GSWL",
            "name": "Gangsta Swans WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rabindrarajawat/arr-solana/main/gangstaswans.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "EXGqHqvKBs4Z1mCwhiGE7kT2TXGFirAjvQzPSQP8nvuw",
            "symbol": "STAR",
            "name": "Star Chain",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EXGqHqvKBs4Z1mCwhiGE7kT2TXGFirAjvQzPSQP8nvuw/logo.png"
        },
        {
            "chainId": 101,
            "address": "9eUyxnMoPCM89z98TRJvkpFkLBtZbwphydLYHLwRNkJH",
            "symbol": "RCR",
            "name": "Recursion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9eUyxnMoPCM89z98TRJvkpFkLBtZbwphydLYHLwRNkJH/logo.svg"
        },
        {
            "chainId": 101,
            "address": "AZjFodHTbd8TQhhFujJAXLmaQpYwNEhYMTATbmwHQxi4",
            "symbol": "JOLI",
            "name": "Joli Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoliWoli/crypto/main/businesstux.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GuRdDYCNuykG28e77aFVD7gvwdeRqziBfQYdCdRqSVVS",
            "symbol": "HNYG",
            "name": "Honey Genesis Bee Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GuRdDYCNuykG28e77aFVD7gvwdeRqziBfQYdCdRqSVVS/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "8s6vNyWzCynmXV8tpKfsRZFy2qRYwFA9uR31nA5d61mz",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "ENNuzbdv7MiT9fjg7ApC2Fyf2F67PWww5Trm6A7W1BSn",
            "symbol": "QUACK",
            "name": "QUACKOIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENNuzbdv7MiT9fjg7ApC2Fyf2F67PWww5Trm6A7W1BSn/logo.png",
            "tags": [
                "community",
                "token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FFloateesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "JCBSxd9TAecuG6JN68kSFgvUoLLWqpXaJH8VqFXrvjtj",
            "symbol": "JCBS",
            "name": "Jacobs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JCBSxd9TAecuG6JN68kSFgvUoLLWqpXaJH8VqFXrvjtj/logo.png",
            "extensions": {
                "website": "https://jcbscoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "DQBaMSFBz4BF7r5TYEr5db25kKKhzKNWo8HSppX7tLZ3",
            "symbol": "FOO",
            "name": "Foogle",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DQBaMSFBz4BF7r5TYEr5db25kKKhzKNWo8HSppX7tLZ3/logo.png",
            "tags": [
                "utility-token",
                "meta-verse",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "66FLirmJtD13VMwCQywKZfa1TG97Zj182966zh63XERf",
            "symbol": "sFFC",
            "name": "staked FoneFanClub",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danny8252/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV",
            "symbol": "SLCL",
            "name": "Solcial token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "nfts"
            ],
            "extensions": {
                "blog": "http://blog.solcial.io/",
                "coinmarketcap": "https://coinmarketcap.com/currencies/solcial/",
                "description": "The first uncensorable, permissionless and truly open social network",
                "discord": "https://discord.gg/solcial",
                "telegram": "https://t.me/solcial",
                "twitter": "https://twitter.com/solcialofficial",
                "website": "https://solcial.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FZiMRKG25BUgZQjgzizy775duZLKiEoPhsyYQaktvEed",
            "symbol": "METAWL",
            "name": "Metawares WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZiMRKG25BUgZQjgzizy775duZLKiEoPhsyYQaktvEed/logo.png",
            "tags": [
                "whitelist",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "7zmJ9hiA54py8HTgKtSjv9fVbX6HxwSqdGvMKSuzodWC",
            "symbol": "METAPASS",
            "name": "Metawares Redemption Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zmJ9hiA54py8HTgKtSjv9fVbX6HxwSqdGvMKSuzodWC/logo.png",
            "tags": [
                "whitelist",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "AyJiCcYWA4b7u61FkY4RTawtbTd91r22cE1Wc6gAL5LS",
            "symbol": "NUWA",
            "name": "NUWA Rex Token",
            "decimals": 3,
            "logoURI": "https://media.discordapp.net/attachments/926535226820276264/954723796252753971/coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HEyd4WBCBKeCWMsFx9rjDjzt72rsMzQ36CoP8GkCDj4T",
            "symbol": "FFC",
            "name": "FoneFanClub",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danny8252/ffc/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DGjrbCznR8gviTJEswA5f6KZPU2JNHJseHqz16eW7nQq",
            "symbol": "MUJ",
            "name": "Mujib Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iamrasheeq/MujibCoinCrypto/main/mujibCoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7KKccqpnBYJUMBjuh6QY4h1sZvzp5XAgXnSvGEbci5W",
            "symbol": "DDWL",
            "name": "DDougies WL Token",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh//DDougies/images/DougFord-DD-Coin-Logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DDougies"
            }
        },
        {
            "chainId": 101,
            "address": "BbdgdtrkdABiATqKUeFEx6Qmcwz6TTx3EjzDcNUC1bhU",
            "symbol": "MONGUILOD",
            "name": "Sir Monguilod Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mikim83/sir-monguilod-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H85VKejRaDRGUoiKhpNyjC7RF2vtuztN51ztLGq6rgtt",
            "symbol": "GC",
            "name": "GameChangers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H85VKejRaDRGUoiKhpNyjC7RF2vtuztN51ztLGq6rgtt/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLHhNcW169NyPwUooRBh1jkXf5C2hHY2WjNY4ASfPEC",
            "symbol": "LIMWL",
            "name": "Lost in Metaverse WL1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/r4h01/LostInMetaverse/main/assets/LIM.png"
        },
        {
            "chainId": 101,
            "address": "4ANPmEsoZ24EjrvXP7Z7vfjqEgBm7G8Ex5ccZ23t9xiA",
            "symbol": "JHRC",
            "name": "JacobsCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jacobhreed/crypto/main/J-logos_transparent_ccexpress.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "86stxk9NfjwHyfHaMTRS6DftzZoq33Usf1KbWZS42XBs",
            "symbol": "BUBBA",
            "name": "Bubba Chimp Crew TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Randomkushh/assets/master/tokens/solana/bubba/bubba%20token.png",
            "extensions": {
                "twitter": "https://twitter.com/BubbaChimps_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "FCP14kyGeg9BYfBUZFtcEAt5sLe7BfyjLvBoqnL2WcQV",
            "symbol": "DAFM",
            "name": "Doomed Free Mint",
            "decimals": 0,
            "logoURI": "https://mint.thearmadillos.io/da-freemint-token.jpg",
            "tags": [
                "whitelist",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "PSPdfYZVDrrYaWh5F7XDdQPeMPsLpisnE5VD4E3JGtp",
            "symbol": "DWLT",
            "name": "Doomed WL Token",
            "decimals": 0,
            "logoURI": "https://mint.thearmadillos.io/da-presale-token.jpg",
            "tags": [
                "whitelist",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "PPTfK8sQwH5485Fvjg5edAFG3aoQdEhxaTe9kthhTy6",
            "symbol": "PPWLT",
            "name": "Pixxel Parrots Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PPTfK8sQwH5485Fvjg5edAFG3aoQdEhxaTe9kthhTy6/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4SsBoLmKGWdVWfuJ5DU1TmM2BxxWBx44eMDuh6nhYgHf",
            "symbol": "$SHILLS",
            "name": "Solana Hills",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CMHayden/crypto/master/solanahills.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solanahills"
            }
        },
        {
            "chainId": 101,
            "address": "8TWt7HSKJXwiD5C9k1CqTQFT6ieNFdJvFAwWf46EcHa3",
            "symbol": "JAWN",
            "name": "JawnCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8TWt7HSKJXwiD5C9k1CqTQFT6ieNFdJvFAwWf46EcHa3/jawncoin.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GdVYQYnYV8zNFuQcMMEb1RQFBR14mdJUVVWHVTzU3AHq",
            "symbol": "ZOKER",
            "name": "Zoker",
            "decimals": 9,
            "logoURI": "https://media.zoker.com/media/logo.png",
            "tags": [
                "game-token",
                "meta-verse",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZokerGame",
                "website": "https://zoker.com"
            }
        },
        {
            "chainId": 101,
            "address": "ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J",
            "symbol": "RATIO",
            "name": "Ratio Governance Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J/logo.svg",
            "extensions": {
                "telegram": "https://t.me/ratiofinance",
                "twitter": "https://twitter.com/RatioFinance",
                "website": "https://ratio.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Bt8vnAZYaTBcoF4i6K8ANuuKwLbzbPUF1c7g6an6KKJt",
            "symbol": "ICY",
            "name": "Icury token",
            "decimals": 0,
            "logoURI": "https://github.com/MoneshROR/icury_token/blob/main/logo.png",
            "tags": [
                "icury-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4o3j9LaMoxrxvk1BB8QaRPT62RHguUUZ1bmHJ1M2msVk",
            "symbol": "CHWY",
            "name": "Chewbacca the Whoodle",
            "decimals": 0,
            "logoURI": "https://github.com/aim-wealth/public/blob/main/Chewbacca%20the%20Whoodle.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "SMKfVDwViTiwuQkwaZdwUkvq2MXyxF75UXaYkFPfgPG",
            "symbol": "SMK",
            "name": "SMK",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img923/9223/l8sa9k.png",
            "extensions": {
                "twitter": "https://twitter.com/SamuraiMkeys"
            }
        },
        {
            "chainId": 101,
            "address": "8yFXMR3smwiz9RgTnQswwFPVbYkLLKv58RpsBDYw351r",
            "symbol": "DDWT",
            "name": "Degen DAO WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8yFXMR3smwiz9RgTnQswwFPVbYkLLKv58RpsBDYw351r/logo.png",
            "tags": [
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GmEwrWwya2Q7jtHcTzCWRcWDtF74ceqJbTBwDgb6zvKG",
            "symbol": "CLWL",
            "name": "Curse Lab Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanacoder005/Cursed-Lab-Token/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MAGf4MnUUkkAUUdiYbNFcDnE4EBGHJYLk9foJ2ae7BV",
            "symbol": "MAGAI",
            "name": "Magnum Token",
            "decimals": 0,
            "logoURI": "https://github.com/tomekregulski/magnum-token-logo/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HVWUENskPh5bYq4Fdpv4hTwkbVbS4XAbCJ49Gev3WYEX",
            "symbol": "HVWWL",
            "name": "High Voltage Worlds Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HVWUENskPh5bYq4Fdpv4hTwkbVbS4XAbCJ49Gev3WYEX/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/hvworldsnft",
                "twitter": "https://twitter.com/hvWorldsNFT_",
                "website": "https://www.highvoltageworlds.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CGczF9uYdSVXmSr9swMafhF1ktHsi6ygcgTHWL71XNZ9",
            "symbol": "yiSolUST",
            "name": "Staked solUST",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CGczF9uYdSVXmSr9swMafhF1ktHsi6ygcgTHWL71XNZ9/icon.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://soluna.money/"
            }
        },
        {
            "chainId": 101,
            "address": "BGLa4XwmsRcLDBKEtUgxAzKTkHYm4qgxEtkGwJKT7gEF",
            "symbol": "MHAWL",
            "name": "MetaHelix Alpha WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BGLa4XwmsRcLDBKEtUgxAzKTkHYm4qgxEtkGwJKT7gEF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/metahelix",
                "twitter": "https://twitter.com/metahelixio",
                "website": "https://metahelix.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GpkezdzsMoerjxCviax6rSPN6suTpSTg9eFzaRBGwLtf",
            "symbol": "DISCO",
            "name": "Disco Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpkezdzsMoerjxCviax6rSPN6suTpSTg9eFzaRBGwLtf/logo.jpg",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DISCO_Protocol",
                "website": "https://disco.foundation/"
            }
        },
        {
            "chainId": 103,
            "address": "GpkezdzsMoerjxCviax6rSPN6suTpSTg9eFzaRBGwLtf",
            "symbol": "DISCO",
            "name": "Disco Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpkezdzsMoerjxCviax6rSPN6suTpSTg9eFzaRBGwLtf/logo.jpg",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DISCO_Protocol",
                "website": "https://disco.foundation/"
            }
        },
        {
            "chainId": 101,
            "address": "AiRWExaAjZyyt7jCs9wEGzdNL68pEpa66BUXF6zVP3JT",
            "symbol": "ARCC",
            "name": "Acrona Credits",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/AiRWExaAjZyyt7jCs9wEGzdNL68pEpa66BUXF6zVP3JT/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "FwT5PEo4BTPLMPex88EakwdXceJWe3ZSF5XhxZjHwhXe",
            "symbol": "cDusk",
            "name": "Cosmik Dusk",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cosmik-dust/cosmik-dust/main/cosmikdust.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HAps7qmQmfZE5beoLUXtqvSGURP5AXsiiU6fKccn7swm",
            "symbol": "GPKC",
            "name": "GlobalKeyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/incharta/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BhWwL5K6k98xvy2vndXLVvq6vRsnCq9RSM6sCHNPSGMe",
            "symbol": "JUNI",
            "name": "Juni's Personal Tokens",
            "decimals": 0,
            "logoURI": "https://res.cloudinary.com/dbsyktgpl/image/upload/c_limit,h_1000,q_100/aXBmczovL1FtZFdWZkpyVXQ2SjI1WWZicFVKdVdzQllGMVdMNldESHFMajViejNWbUV5eGs%3D",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nubstick27"
            }
        },
        {
            "chainId": 101,
            "address": "oRCSKFhkpeeCPEziCXLBn9SR8qtM1WjvAJeRPKBbPWv",
            "symbol": "ORCS",
            "name": "The Orcs WL PASS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TheOrcs/token-list/main/assets/mainnet/2WWK14D4cdWuc9TtUPwbXMUZGyk1PrQYej1NVsPNsBeS/logo.png",
            "tags": [
                "NFT",
                "whitlist-pass"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/theorcs",
                "twitter": "https://twitter.com/TheOrcsNFT",
                "website": "https://theorcs.io"
            }
        },
        {
            "chainId": 101,
            "address": "BavXjyWCy85c6T7WVsDfibkbNmg2SkwdZy8JhFcKdYvk",
            "symbol": "BBWL",
            "name": "Baked Bots WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solanacoder005/Baked-Bots-WL/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AKBHskt7LUfvzysUS8hZQDypjW4Zz3C4zaZzK7paX4vb",
            "symbol": "BRGY",
            "name": "BERGY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bergy700/crypto/main/images.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4U3f8vfoCe5ifbQ5j5CkSnUruhM2vxxcEbAN6H1sLi6r",
            "symbol": "RCB",
            "name": "RCB Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ryebreadseeds/RCBpic/main/RCB%20Good.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "57at8PHwvg8AWZAZAvxUUShEFyhPgwd7LfGF5oTzG85t",
            "symbol": "SCF",
            "name": "SCF4B",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/57at8PHwvg8AWZAZAvxUUShEFyhPgwd7LfGF5oTzG85t/logo.png",
            "extensions": {
                "telegram": "https://t.me/SCFOfficial",
                "twitter": "https://twitter.com/SCF4b",
                "whitepaper": "https://scf4b.com/wp/SCF4B_WP_EN.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "4kCxL4vRtbXNVqojpdgkd6yGQwhkP2noRhBqakLwpVAd",
            "symbol": "TG",
            "name": "TrueGold",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/masnun-siam/crypto/main/truegold.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "Bt8vnAZYaTBcoF4i6K8ANuuKwLbzbPUF1c7g6an6KKJt",
            "symbol": "ICY",
            "name": "Icury token",
            "decimals": 0,
            "logoURI": "https://github.com/MoneshROR/icury_token/blob/main/logo.png",
            "tags": [
                "icury-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gqv2cFatnYbPAuuTYMaiB9AXpD8BY9tqoMoFKgZKND4D",
            "symbol": "BANAC",
            "name": "BaNaNaa Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/will3479/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GoQDVRK1vso9gSGJ2D8hfeGGYnqjarEVqCoFv888guG6",
            "symbol": "SLOTH",
            "name": "Sloth Souls",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoQDVRK1vso9gSGJ2D8hfeGGYnqjarEVqCoFv888guG6/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "Sloth souls are an extremely important thing when it comes to Sloth Evolution...",
                "discord": "https://discord.gg/xN7fTBwrjc",
                "medium": "https://medium.com/@theslowpatrol",
                "twitter": "https://twitter.com/TheSlowPatrol",
                "website": "https://www.theslowpatrol.com/"
            }
        },
        {
            "chainId": 103,
            "address": "GoQDVRK1vso9gSGJ2D8hfeGGYnqjarEVqCoFv888guG6",
            "symbol": "SLOTH",
            "name": "Sloth Souls",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoQDVRK1vso9gSGJ2D8hfeGGYnqjarEVqCoFv888guG6/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "Sloth souls are an extremely important thing when it comes to Sloth Evolution...",
                "discord": "https://discord.gg/xN7fTBwrjc",
                "medium": "https://medium.com/@theslowpatrol",
                "twitter": "https://twitter.com/TheSlowPatrol",
                "website": "https://www.theslowpatrol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "RAriRG5GhRhTJRsvoGAwNAhcY3mpLguC8p1d9kQVKT6",
            "symbol": "RARI",
            "name": "Official RariKeys WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RAriRG5GhRhTJRsvoGAwNAhcY3mpLguC8p1d9kQVKT6/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolRarity_"
            }
        },
        {
            "chainId": 101,
            "address": "BKDzpDfsWo8zMPh477pgMgffB1nxwkTXpstD9GRV9jBU",
            "symbol": "XPAC",
            "name": "Xpace",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKDzpDfsWo8zMPh477pgMgffB1nxwkTXpstD9GRV9jBU/logo.png",
            "tags": [
                "community-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "FSeME2TS4xmMScwDy4j53cApTEi9eHr9MRJSXmR29mov",
            "symbol": "AWC",
            "name": "AwakeCoin",
            "decimals": 9,
            "logoURI": "https://storage.googleapis.com/random-stuff-do-not-delete/crypto-coins/awake.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Awake is a content creators website"
            }
        },
        {
            "chainId": 101,
            "address": "GxbSCU2go1MsLPM9ZJFSWuvsYCN4ZBRtCFSmcy5VehmX",
            "symbol": "MLS",
            "name": "Moonland Stardust",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GxbSCU2go1MsLPM9ZJFSWuvsYCN4ZBRtCFSmcy5VehmX/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/moonland",
                "twitter": "https://twitter.com/MoonlandVerse",
                "website": "https://www.moonland.world"
            }
        },
        {
            "chainId": 101,
            "address": "FeEdorXhQh5jsr89XtD1s6txNkmXTPu9hhNpaYvPiQGJ",
            "symbol": "ZUKI",
            "name": "Zuki Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SnowballedNoot/SPLAkatzuki/main/tokenlogo520.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "CJiyMgq41YYH9McFQANw69KWyj74H8NjgVauaS1mfs39",
            "symbol": "DEST",
            "name": "Destiny",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Anonymous-Pentester/logo/main/logo1.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/destnetwork",
                "website": "https://destcoin.org/"
            }
        },
        {
            "chainId": 102,
            "address": "C7PCjVH11ViAhxvFDqXUuRq5afkijVDgVEaeJYuihaRk",
            "symbol": "ICY",
            "name": "Icury token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MoneshROR/icury_token/main/logo.png",
            "tags": [
                "icury-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2MsuJCKJMa1e77c2TfhwsT9oaqM43FNCW7twYpdweizp",
            "symbol": "CERO",
            "name": "CERO Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2MsuJCKJMa1e77c2TfhwsT9oaqM43FNCW7twYpdweizp/logo.png",
            "tags": [
                "cero-token",
                "carbon-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/carbonwallet.th"
            }
        },
        {
            "chainId": 101,
            "address": "C2LZUMY8tf6HJEZ3EzzWW6Fc7aJtcomf1VC2jBNQcKoE",
            "symbol": "DNT",
            "name": "Deez Nuts Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C2LZUMY8tf6HJEZ3EzzWW6Fc7aJtcomf1VC2jBNQcKoE/logo.png",
            "tags": [
                "utility-token",
                "security-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CrwkrbSDnjbsc34iY3gwjUKXZHwM71LCsC2Kn76fsvG5",
            "symbol": "TOG",
            "name": "TokenOfGratitude",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kurrios/tokenOfGratitudeIMG/main/TOG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GENR71rjoC56vFf8RzfMTutEEHcQV8mr5kSsUzs7kHbc",
            "symbol": "SPACEROBOTS",
            "name": "Generous Robots Mission",
            "decimals": 0,
            "logoURI": "https://jokerz-sol.s3.amazonaws.com/gen-robots/space-robots/token/0.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/generousrobots"
            }
        },
        {
            "chainId": 101,
            "address": "TMbabNY9UwnGsYTYus7tGHUcDKkVhZ2gFbF9ucqjXqc",
            "symbol": "SOLNFT",
            "name": "SLOTHS NFT WHITELIST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TMbabNY9UwnGsYTYus7tGHUcDKkVhZ2gFbF9ucqjXqc/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EkTmcVRhGU9Z2YPCNYW4KUuRUiCUVc1r4vVnTCmWwFW",
            "symbol": "FFCt",
            "name": "FoneFanClub token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danny8252/ffct/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bots8GcuhxrZ1XBGHx3qvzMEq3xfBZHSCxHX5EukGuis",
            "symbol": "BBW",
            "name": "Baked Bots WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bots8GcuhxrZ1XBGHx3qvzMEq3xfBZHSCxHX5EukGuis/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bakedbots420",
                "website": "https://bakedbots420.com"
            }
        },
        {
            "chainId": 101,
            "address": "ELXRYrf8wd4DcyXDU9QPnMdD8jn2twg7o7qEtf5z2GBW",
            "symbol": "ELIXIR",
            "name": "ELIXIR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zaptors/image/main/elixir.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "M1LEuEmGhZfiiCcrVfutWuiYzzodDnatyFw63Xmg2g9",
            "symbol": "MILE",
            "name": "MILE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/M1LEuEmGhZfiiCcrVfutWuiYzzodDnatyFw63Xmg2g9/MileHighMultiverseToken.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WtpzxsBTGF",
                "twitter": "https://twitter.com/MH_Multiverse",
                "website": "https://milehighmultiverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "t1CCCUJmHX4czeSjmu6TLyrvRznSmcnA9QySQXaMn5D",
            "symbol": "t1CCC",
            "name": "test1 Carbene Chemisty Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/t1CCCUJmHX4czeSjmu6TLyrvRznSmcnA9QySQXaMn5D/t1CCC.png",
            "tags": [
                "t1CCC",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "34XxQ23XCitmpUuDnLeS49H9R7Q6NV3zGSGMjqy43xhJ",
            "symbol": "FOREST",
            "name": "FOREST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ForestFrenz/forest/main/frenzforest.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3QyJgynKp4aiMt4RJy5HrYSSBzuanU3ZwQ7RRdAni1rQ",
            "symbol": "RichWL",
            "name": "Rich Arab WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3QyJgynKp4aiMt4RJy5HrYSSBzuanU3ZwQ7RRdAni1rQ/logo.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/pSUhqNKFeg",
                "twitter": "https://twitter.com/RichArabDAO"
            }
        },
        {
            "chainId": 101,
            "address": "6f6ZEB6GApv7QFot9n8t1i579AZkNhKcJ7JhQvZkhEc8",
            "symbol": "FreeML",
            "name": "Monkey Legacy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6f6ZEB6GApv7QFot9n8t1i579AZkNhKcJ7JhQvZkhEc8/logo.png",
            "tags": [
                "free-mint"
            ],
            "extensions": {
                "discord": "https://discord.com/ACDealerNFT",
                "twitter": "https://twitter.com/TigerLegacyNFT"
            }
        },
        {
            "chainId": 101,
            "address": "L3Uy1CbJMre4NQaXmaNvaQDwjw7iwVoy2h5yLSj5TiJ",
            "symbol": "LUMT",
            "name": "Launchlabs Unlimited Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUaAb8dKBd3oye8PdSZtCmr3wQJbcXop4P3bCm434xc/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "website": "https://launchlabs.app/"
            }
        },
        {
            "chainId": 101,
            "address": "L3RtBNTumKfH9Pe5zUpK9zhYZXVazw15YrDjGwistJ6",
            "symbol": "LRMT",
            "name": "Launchlabs Regular Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LHTZ1E5tcZRK786zwrpcUsGivU6vemQaKBeXuiVD6tP/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "website": "https://launchlabs.app/"
            }
        },
        {
            "chainId": 101,
            "address": "TWL3qkjRXomK4fFViXA86csuYAfphTQiKCfxXTYjB7K",
            "symbol": "TOAWL",
            "name": "Towers Of Astraa Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TWL3qkjRXomK4fFViXA86csuYAfphTQiKCfxXTYjB7K/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FFTwhd4pEZoGCbocV1PMqkb2MCebskz8MoKiNugyCpAd",
            "symbol": "FUNK",
            "name": "Multidimensional Funky Mirian",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFTwhd4pEZoGCbocV1PMqkb2MCebskz8MoKiNugyCpAd/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AW9bgLubnd4jjmfsidHwSJnDutWhZBcj3yYcY4weXvSP",
            "symbol": "BIGCANDY",
            "name": "BIG CANDY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ramonv926/-BigCandy/main/bigcandy.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HVb5epReoJrfgoSNwBLCgN2WLLuUQBgstYY7cNJKBc6r",
            "symbol": "DLBASL",
            "name": "DEL BUENO ASL TECH SUPERMARKETS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/javiervq/DLBASL/main/logo3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "91AkDgMBMpGGBv1Uqt5K1gC2HSdoR6qfzksKF5sykxVb",
            "symbol": "ZEBRAWL",
            "name": "Zebra Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/91AkDgMBMpGGBv1Uqt5K1gC2HSdoR6qfzksKF5sykxVb/logo.png"
        },
        {
            "chainId": 101,
            "address": "6a1LUJuDe8G6XKQbpiXsDjHVJpq1hbvQ5axf7rXzBzpg",
            "symbol": "NCTR",
            "name": "Nectar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MonarchButterflySquad/NFTMonarchsGeneral/main/6a1LUJuDe8G6XKQbpiXsDjHVJpq1hbvQ5axf7rXzBzpg/nectardrop.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "NFT Monarch in-game currency and utility token.",
                "discord": "https://discord.gg/nftmonarchs",
                "twitter": "https://twitter.com/NFTMonarchs",
                "website": "https://www.nftmonarchs.com"
            }
        },
        {
            "chainId": 101,
            "address": "J1fuSfLo46UTQbVsySmaCed5YoU8KzpTg9GjZhBQEJAf",
            "symbol": "JPWLT",
            "name": "Jungle Pass WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J1fuSfLo46UTQbVsySmaCed5YoU8KzpTg9GjZhBQEJAf/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GLrFNhFr9kmAi2maE4nPAd3AEc5BPakgDz78ogQKYxEF",
            "symbol": "SLN",
            "name": "SOLANITE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GLrFNhFr9kmAi2maE4nPAd3AEc5BPakgDz78ogQKYxEF/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "5P14yGZH2kZegsaxv3XDauoq8ZUFxKBbaLXFMG6cj5iP",
            "symbol": "SoF",
            "name": "Solji on Fire WL TOken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5P14yGZH2kZegsaxv3XDauoq8ZUFxKBbaLXFMG6cj5iP/logo.png",
            "tags": [
                "Whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GuLCixNS3St1PgBRYesQUX1M7oANL3APK2LFEv1a7dWA",
            "symbol": "WZRD",
            "name": "Wizardia Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GuLCixNS3St1PgBRYesQUX1M7oANL3APK2LFEv1a7dWA/logo.png",
            "extensions": {
                "medium": "https://medium.com/@wizardia",
                "twitter": "https://twitter.com/PlayWizardia",
                "website": "https://wizardia.io"
            }
        },
        {
            "chainId": 101,
            "address": "3dLsoauTtH9BkZxPvfgaNQvyiLoqkiXCszM8LHDaf28e",
            "symbol": "FLOPP",
            "name": "Floppa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fli671/crypto1/main/Gregory_Caracal.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BaKEdMuqQduNkc3enqMfsbQaDnYmWChobiBFciZsQwui",
            "symbol": "BABO",
            "name": "Baked Bots Official WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BaKEdMuqQduNkc3enqMfsbQaDnYmWChobiBFciZsQwui/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/bakedbots_420",
                "twitter": "https://twitter.com/bakedbots420",
                "website": "https://bakedbots420.com/"
            }
        },
        {
            "chainId": 101,
            "address": "yRYbFDLRAaaQRMJeeuqxKHyAFLKXUTVWx8FR1jsA8yV",
            "symbol": "VIY",
            "name": "Variety",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dorp43/VarietyToken/main/VarietyLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5bp6Ke3rtTnUJST9n3Ejf5GwysjxC1yA7FzxKoT7AcqE",
            "symbol": "LVIP",
            "name": "VIP Access Solana Zoo Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5bp6Ke3rtTnUJST9n3Ejf5GwysjxC1yA7FzxKoT7AcqE/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/gBphrYNF8B",
                "instagram": "https://www.instagram.com/solanazoo/",
                "twitter": "https://twitter.com/SolanaZoo_NFT",
                "website": "https://www.zoosolana.com/",
                "whitepaper": "https://www.zoosolana.com/_files/ugd/ac6553_9b6777afc91e4c239f4a57a5f0b9bbc1.pdf?index=true"
            }
        },
        {
            "chainId": 101,
            "address": "5hTcHUaPwMaTE5ApWVtvkjTZ6fKSBsKowJuvsKgPCV2P",
            "symbol": "MT1",
            "name": "MT1 token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5hTcHUaPwMaTE5ApWVtvkjTZ6fKSBsKowJuvsKgPCV2P/MT1LOGO.png"
        },
        {
            "chainId": 101,
            "address": "EH8wCubDZ8nFvy8xv9T3j5EGZ9AaJXvNAxjpqXiSvqEX",
            "symbol": "MHCSWAP",
            "name": "Most Hyped Crypto Swap Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EH8wCubDZ8nFvy8xv9T3j5EGZ9AaJXvNAxjpqXiSvqEX/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3pErr2gHeNic4PLrTuSPEbax3BHMpsTnGHPNPiZP2hw2",
            "symbol": "MHCNFT",
            "name": "Most Hyped Crypto NFTS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3pErr2gHeNic4PLrTuSPEbax3BHMpsTnGHPNPiZP2hw2/logo.png",
            "tags": [
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2t8QV7KmZU7kCNtKfBa3YYb7pJortKB7tYqLdAxBoVtw",
            "symbol": "RSDAO",
            "name": "Royal Society Dao",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MarkyyMarkNFT/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "S8x4KYumVSJ2qadD2wY6yKQkLxSEbFuMWYKFNNavdHP",
            "symbol": "DEGS",
            "name": "DEGA Glory Shard",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/S8x4KYumVSJ2qadD2wY6yKQkLxSEbFuMWYKFNNavdHP/logo.png",
            "tags": [
                "game-currency",
                "game-token",
                "De-Glory"
            ]
        },
        {
            "chainId": 102,
            "address": "DQ2bmRqXY8wgVgqzNY73YTTmDq1NAwHhEfgpVMxhxgzc",
            "symbol": "Ay",
            "name": "Aayush",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Moneshsoni/crypto_token/main/logo.png",
            "tags": [
                "icury-token"
            ]
        },
        {
            "chainId": 101,
            "address": "92ueLLpG9sFQkaGd8qkr7k4VmdRh7tUfKzcBmXYux5sm",
            "symbol": "WORLD",
            "name": "Better World Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zjeffreys/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "vba5JtMaGeKt2eN5v5mRDsB5pH32ehWNv4Sf9tviyMp",
            "symbol": "VBAWL",
            "name": "VBAGAME OFFICIAL ML TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/yoyo-ca/token-list/main/assets/mainnet/vba5JtMaGeKt2eN5v5mRDsB5pH32ehWNv4Sf9tviyMp/vba.png",
            "extensions": {
                "twitter": "https://twitter.com/vbagame",
                "website": "https://www.vbagame.com/"
            }
        },
        {
            "chainId": 101,
            "address": "hivekAB8ofmqxLpCMFXnoTYzAK4UVGfXJwJXQfEjosU",
            "symbol": "PHWL",
            "name": "PROJECT HIVE OFFICIAL WL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/yoyo-ca/token-list/main/assets/mainnet/hivekAB8ofmqxLpCMFXnoTYzAK4UVGfXJwJXQfEjosU/hive.png",
            "extensions": {
                "twitter": "https://twitter.com/project_hive_io",
                "website": "https://project-hive.io"
            }
        },
        {
            "chainId": 101,
            "address": "HyUD3Vkfp4zsVXVbAfSrpSZqvEuMysF1zLSFyCcHybkt",
            "symbol": "WARI-WL",
            "name": "Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HyUD3Vkfp4zsVXVbAfSrpSZqvEuMysF1zLSFyCcHybkt/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xQRTBcxb",
                "twitter": "https://twitter.com/OpportunitiesIO"
            }
        },
        {
            "chainId": 101,
            "address": "HsZHzbqSe3wm5SHmTyD7y9TQJyyRt27GQDjkqXvLahb9",
            "symbol": "OBWL",
            "name": "Occult Balls Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HsZHzbqSe3wm5SHmTyD7y9TQJyyRt27GQDjkqXvLahb9/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2savLpc2scbPdpnrK33KExXfpLMrMvJr1rmzDJ3o7Bf6",
            "symbol": "SNOW",
            "name": "SNOW TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2savLpc2scbPdpnrK33KExXfpLMrMvJr1rmzDJ3o7Bf6/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hn4gYjpTvfo5KpJ9Dfm3oBEMuYduMmaSp3JZyA13CwHi",
            "symbol": "VPWL",
            "name": "VP Bunny Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hn4gYjpTvfo5KpJ9Dfm3oBEMuYduMmaSp3JZyA13CwHi/vplogo.png",
            "extensions": {
                "twitter": "https://twitter.com/lasvegas_vp",
                "website": "https://nft.lasvegasvp.com"
            }
        },
        {
            "chainId": 101,
            "address": "ToF3JR3FVKggXEVCVrYTkz85MAopjNGT6cE7hxJ9a9A",
            "symbol": "AXO",
            "name": "Axolotl WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/charlesvargas8234/crypto/main/Newlogo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4stkBpKm22xAJ9pwAyPiJPutzTLYFC52QEVV66KWkPuR",
            "symbol": "OTK",
            "name": "OTAKU",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sibisen/CRYPTO/main/LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AzZMJEE1u5cM2fVtPFp5K4jyL5988i72WiwQhLCXQTr2",
            "symbol": "MHCNWS",
            "name": "Most Hyped Crypto News Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AzZMJEE1u5cM2fVtPFp5K4jyL5988i72WiwQhLCXQTr2/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "54VdWW4LYc4reEUPkyJ3Aif7D8Equ5WFWo9BUCqdnbmc",
            "symbol": "1xbit",
            "name": "1xbit token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54VdWW4LYc4reEUPkyJ3Aif7D8Equ5WFWo9BUCqdnbmc/logo.png",
            "tags": [
                "utility-token",
                "gambling-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/1xbit",
                "telegram": "https://t.me/sportsbook_1xBit",
                "twitter": "https://twitter.com/1x_bit"
            }
        },
        {
            "chainId": 101,
            "address": "9qcLbAanoAo461yf19epr6GXGyAwHQn8asKGUaoRRB47",
            "symbol": "KPM",
            "name": "Kingdom Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/pass.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "description": "Kingdom Pass Mint",
                "website": "https://www.kingdompass.io/"
            }
        },
        {
            "chainId": 101,
            "address": "zoomAe6zBJsgB23puRkVxgPAC8WETUb6AApaBUM7y1M",
            "symbol": "ZOOM",
            "name": "Zaptors WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zoomAe6zBJsgB23puRkVxgPAC8WETUb6AApaBUM7y1M/Raptor-WL.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB",
            "symbol": "WOOP",
            "name": "WOOP",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "coingeckoId": "woop",
                "discord": "https://discord.gg/bohemia",
                "serumV3Usdc": "DQY2Sjm3bEbrXhyGgHsXqLHU2EybBPVPLDTCrEHq9KpL",
                "twitter": "https://twitter.com/BohemiaArtFair"
            }
        },
        {
            "chainId": 101,
            "address": "CiAS2EUR6qDmUEHtLoJtXQ4XoLWLVuxsPX3F74smXnez",
            "symbol": "CIAS",
            "name": "Coin Invasive Alien Species",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CiAS2EUR6qDmUEHtLoJtXQ4XoLWLVuxsPX3F74smXnez/logo.png",
            "tags": [
                "utility-token",
                "metaverse",
                "nfts",
                "solana-ecosystem",
                "staking"
            ],
            "extensions": {
                "discord": "http://discord.gg/VEVJJTjxKD",
                "instagram": "http://instagram.com/invalienspecies",
                "twitter": "https://twitter.com/invalienspecies"
            }
        },
        {
            "chainId": 101,
            "address": "8KBZyCpvZJ26nAp6A44zZiVevgsbkF3wakTAUa1yVBpL",
            "symbol": "ZWL",
            "name": "Zamka WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ra1yuga/ZamkaWLtoken/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "G6Y7oujw17anSbn5maHj5qUxBYzvTt5frrBLm6fHfAon",
            "symbol": "WOLF",
            "name": "Wolf house",
            "decimals": 9,
            "logoURI": "https://github.com/WolfHousee/Asset/blob/main/Pngtreecartoon_wolf_head_2943333.png",
            "tags": [
                "utility-token",
                "metaverse",
                "nfts",
                "solana-ecosystem",
                "staking",
                "DeFi-token",
                "web3-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/F6jJmX5b"
            }
        },
        {
            "chainId": 101,
            "address": "5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N",
            "symbol": "ENRX",
            "name": "Enrex",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N/logo.png",
            "tags": [
                "carbon-offsetting",
                "renewable-energy",
                "green-commodities",
                "defi"
            ],
            "extensions": {
                "description": "Solution that allows to have exposure to crypto in an environmentally friendly way. One-stop-shop to calculate your crypto CO2 emissions and offset it",
                "discord": "https://discord.com/invite/enrex",
                "facebook": "https://www.facebook.com/enrex.io",
                "github": "https://github.com/Enrex-io",
                "instagram": "https://www.instagram.com/enrex.io",
                "linkedin": "https://www.linkedin.com/company/enrex",
                "medium": "https://medium.com/@enrex",
                "telegram": "https://t.me/enrex_community",
                "telegramAnnouncements": "https://t.me/enrex_io",
                "twitter": "https://twitter.com/enrex_crypto",
                "website": "https://www.enrex.io",
                "whitepaper": "https://wp.enrex.io",
                "youtube": "https://www.youtube.com/channel/UClY1GH83dVoTM_uZs4PstIg"
            }
        },
        {
            "chainId": 101,
            "address": "2UgW5e86z3wHYRT4jnXRLgJheSKM8yCz3sn9rMpbWdCy",
            "symbol": "DUCKS",
            "name": "Ducks Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2UgW5e86z3wHYRT4jnXRLgJheSKM8yCz3sn9rMpbWdCy/logo.jpg",
            "tags": [
                "nft-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLTHduNcwf9K2XF7MtTfAHqLNcE3o3HvxEh6kQXmAdh",
            "symbol": "WLTH",
            "name": "WEALTH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLTHduNcwf9K2XF7MtTfAHqLNcE3o3HvxEh6kQXmAdh/logo.png",
            "tags": [
                "WLTH"
            ]
        },
        {
            "chainId": 101,
            "address": "4eimkrkYx1Er6bbvbVp8xY4uMZcHViWZ7EdNCroXMeCT",
            "symbol": "TKOA",
            "name": "TKOACOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/kingalpacas2005/cryptooo/main/TH_25094221_25187221_001.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9mLQpAvyDeX1S4aYL7yskNQRxSqsNLXyzqPDSQVBd3WN",
            "symbol": "JCT",
            "name": "JOB COOPERATION TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9mLQpAvyDeX1S4aYL7yskNQRxSqsNLXyzqPDSQVBd3WN/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://job-cooperation.cz/"
            }
        },
        {
            "chainId": 101,
            "address": "CwEe4fmKXgJCRcqrByaeueTu6MSk5Tir4R3KkEcgLRez",
            "symbol": "AAT",
            "name": "ARVO ASSET TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CwEe4fmKXgJCRcqrByaeueTu6MSk5Tir4R3KkEcgLRez/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://aa-management.cz/"
            }
        },
        {
            "chainId": 101,
            "address": "4nt2s5FpwHFZw3Hcaq72BqWDQmFnuYugCG67CFsnGAhW",
            "symbol": "PMT",
            "name": "Prvni Moravsky TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4nt2s5FpwHFZw3Hcaq72BqWDQmFnuYugCG67CFsnGAhW/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://moravska-obchodni.cz/"
            }
        },
        {
            "chainId": 101,
            "address": "3oRbrJ7HTgErq6nSkhuaCRXsmDRh92kPHp4r3DaGXZyz",
            "symbol": "MT2",
            "name": "MT2 token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3oRbrJ7HTgErq6nSkhuaCRXsmDRh92kPHp4r3DaGXZyz/logo1.png"
        },
        {
            "chainId": 101,
            "address": "3dxDbp6zEcWLeEMcxriLqnVbp82qdpzGjo1ke7GAYEkM",
            "symbol": "MUNSON",
            "name": "Munson Token",
            "decimals": 0,
            "logoURI": "https://live.staticflickr.com/52/158791296_15d9660138_b.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "93jRu2Rv8bh9RrnwLHdnFaky91QuG9CQVGiysbifbaWD",
            "symbol": "SKLT",
            "name": "Skleton Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/93jRu2Rv8bh9RrnwLHdnFaky91QuG9CQVGiysbifbaWD/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Skleton_Tony",
                "website": "https://skletontony.com"
            }
        },
        {
            "chainId": 101,
            "address": "AJXtZx9GFVc6sQaqbChg7zEDK3NC1iByjxxJsctyZsTU",
            "symbol": "CARYPTO",
            "name": "Carypto Carbon Token ",
            "decimals": 0,
            "logoURI": "https://github.com/StefanPapp/carypto/blob/main/carypto.png",
            "tags": [
                "carbon-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9uKdGskJYMzaYfR7eQEjG1oBeW91nRGyuNcCEXN1DP11",
            "symbol": "OBAMIUM",
            "name": "Obamium Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ObamiumCoin/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/obamiumcoin"
            }
        },
        {
            "chainId": 101,
            "address": "8zqeAcLQKv9qqovonj9exEei4mUNKhnFUAfAHQhuj7Ea",
            "symbol": "FUNZ",
            "name": "FunGuyz",
            "decimals": 0,
            "logoURI": "https://arweave.net/LSrYJsws79XdkrVfCkIINQmRUs5mtvnQJ5uj3xWZY60",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/funguyz",
                "twitter": "https://twitter.com/FunGuyzNFT",
                "website": "https://www.funguyz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EDudX4WivJX7vt7Enx9WHzJoeu2FiTsGx1ujQKrnRXN1",
            "symbol": "SNT",
            "name": "Sudarshan Nation Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SuperMonstor/Sudarshan-Nation-Token/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sudarshansk0",
                "website": "https://www.sudarshansk.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GDWdE6uktfrfPT5TnF4cCdJ4eneYe8ZDw1iDJ3Faju22",
            "symbol": "SPHXOG",
            "name": "Sphinx Lab OG",
            "decimals": 0,
            "logoURI": "https://github.com/yazidox/SPHXBETAWL/blob/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://sphinx-lab.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SWLXCGbDk7PkwU9b4GzRgQMy1HgPHwcfe2vTWCXRqSy",
            "symbol": "SPHXWL",
            "name": "Sphinx Lab WL",
            "decimals": 0,
            "logoURI": "https://github.com/yazidox/SPHXBETAWL/blob/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://sphinx-lab.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8c23rzPueFYTj8Fh876M1Zw91W3bK3qEjSEH4iXJthTN",
            "symbol": "HAT",
            "name": "Human Attention Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/socialvibe/human_attention_token/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "jZo4Efd5sqdx8Thbnq2N5sW9buZXYSg7eYbusgsdfv9",
            "symbol": "LGC",
            "name": "LERN Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OdiGames/crypto/main/LogoLern.png",
            "tags": [
                "utility-token",
                "metaverse",
                "solana-ecosystem",
                "DeFi-token",
                "web3-token"
            ],
            "extensions": {
                "website": "https://lernglobal.co.uk/"
            }
        },
        {
            "chainId": 101,
            "address": "8nrNJB2w4ZuoiZz6QzgURSZsARuMGPspK4wz8MoBrM1H",
            "symbol": "PHT",
            "name": "PhantomToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/joca11/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AgWL46DMnUL4j66c6qLRhBce3PXtiAnd889fXCbyR5pb",
            "symbol": "AGWL",
            "name": "Ahegao Gang WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AgWL46DMnUL4j66c6qLRhBce3PXtiAnd889fXCbyR5pb/logo.png"
        },
        {
            "chainId": 101,
            "address": "BHiqsyoXypYme4eQAb6zv5RquMhikM2dGM7xtzrmyACo",
            "symbol": "RAZN",
            "name": "Raisinz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/qudo-code/assets/master/solana/tokens/RAZN/RAZN.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/Raisinzzzz"
            }
        },
        {
            "chainId": 101,
            "address": "CSsWu6rdrJUjuTozXgk3uWNPyqsE3hW54mAeNYjDxYPv",
            "symbol": "SLH",
            "name": "SweetLandHub",
            "decimals": 2,
            "logoURI": "https://sweetlandhub.com/SLH.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://sweetlandhub.com"
            }
        },
        {
            "chainId": 101,
            "address": "4LWf2HT44JMw6w7mckHmXKyHAHL6KkgcJVG2X48uaqCU",
            "symbol": "WLBAHC",
            "name": "WL Bored Ape History Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LWf2HT44JMw6w7mckHmXKyHAHL6KkgcJVG2X48uaqCU/logo.png"
        },
        {
            "chainId": 101,
            "address": "FDqVe2wP8WPJ2aWR1uUXWJsyMeB72mNkvDRjQPbmuyHE",
            "symbol": "MYRIEN",
            "name": "Myths of Myrien",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDqVe2wP8WPJ2aWR1uUXWJsyMeB72mNkvDRjQPbmuyHE/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.mythsofmyrien.com"
            }
        },
        {
            "chainId": 101,
            "address": "GPyj8JXL9NDaG7iR1gfKSeJP1H98h7JGraDNqv8ASK9i",
            "symbol": "GUNC",
            "name": "Gunia Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dennis9819/crypto/main/guniaserv.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "zayS9RM57gJbFQioWA4EW6NiZ45abGSzsxLan7A1gjh",
            "symbol": "ZAYS",
            "name": "Zaysan Raptors WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zayS9RM57gJbFQioWA4EW6NiZ45abGSzsxLan7A1gjh/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZaysanRaptors",
                "website": "https://zaysanraptors.io/"
            }
        },
        {
            "chainId": 101,
            "address": "26nPfD9S6Eiqm6f27ob74uQ5u6BDmMWFKbRoMPZKgNjF",
            "symbol": "MMC",
            "name": "Millionaire Mafia Club Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nftmmc/MMCTOKEN/main/logowl.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "description": "Credits to Millionaire Mafia Club",
                "discord": "https://www.discord.gg/millionairemafiaclub",
                "twitter": "https://twitter.com/nftmmc"
            }
        },
        {
            "chainId": 101,
            "address": "EAWTaWzSPGwyatZ3NQgyyDgoVWpwtPk7s4JMvm1Lv3sK",
            "symbol": "DERP",
            "name": "Derpy Pets Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAWTaWzSPGwyatZ3NQgyyDgoVWpwtPk7s4JMvm1Lv3sK/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9yM42HMJnN69rhMGr8nCYSRtFxjWTWm5Z6GeucyLBEHg",
            "symbol": "RAMENF",
            "name": "Ramen Feast Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9yM42HMJnN69rhMGr8nCYSRtFxjWTWm5Z6GeucyLBEHg/logo.png",
            "tags": [
                "utility-token",
                "nfts"
            ],
            "extensions": {
                "discord": "https://www.discord.gg/kamakuranft",
                "twitter": "https://twitter.com/kamakura_nft"
            }
        },
        {
            "chainId": 101,
            "address": "8NngPwTJD9FW6LNxEf8CPL7d8Fx3iisjiCVM7ngHra9G",
            "symbol": "SPIRIT-WL",
            "name": "Spirits of Pappataz WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NngPwTJD9FW6LNxEf8CPL7d8Fx3iisjiCVM7ngHra9G/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zJWZyRjcMJ",
                "twitter": "https://twitter.com/PappatazWarrior",
                "website": "https://www.pappataz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MM7s2bggZvq2DBFyBVKBBHb9DYAo3A2WGkP6L5cPzxC",
            "symbol": "NEST",
            "name": "Nest Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Beario/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6DzRG51nRXkD5JmTj7P8ELTwBgExMGUoa9cyvi9vEPUK",
            "symbol": "SPPWL",
            "name": "SPP Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/virdagreat147/SPP-Dog/main/kj.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DLvMHnA6ehWDPobqDMpFcsTSiwpFu2y5D8hEtxsd7zUT",
            "symbol": "SNKN",
            "name": "SnakeKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jakeenea51/SnakeKoin-Logo/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AvTYakqgKLAUJucjymMpVmgDdeT4PfDSW4wpqynNCyLk",
            "symbol": "YakuAv",
            "name": "Yaku Avatar WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AvTYakqgKLAUJucjymMpVmgDdeT4PfDSW4wpqynNCyLk/logo.png"
        },
        {
            "chainId": 101,
            "address": "CS9bXG9HE6LoJz1SMpjnaH86XLNEitLubUS3ap3q7y1N",
            "symbol": "PUFFS",
            "name": "Puffs",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/qudo-code/assets/master/solana/tokens/PUFFS/PUFFS.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/solpuffss"
            }
        },
        {
            "chainId": 101,
            "address": "3D8mQf27DQbo1GWJJPsXwFas5u4cewKzCNMH5VpUcgFX",
            "symbol": "3DACWL",
            "name": "3D Apes Club WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3D8mQf27DQbo1GWJJPsXwFas5u4cewKzCNMH5VpUcgFX/logo.png"
        },
        {
            "chainId": 101,
            "address": "SC3o3s72Ecpr8Dovt6zzakUZ4ewesAFz8Hyj119hLER",
            "symbol": "SKCLWL",
            "name": "Skull Club Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SC3o3s72Ecpr8Dovt6zzakUZ4ewesAFz8Hyj119hLER/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://skullclubnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5b6oa5VhzJMeC9eFiFPpJtAwnKvzEPHAnUTajRyKiMDf",
            "symbol": "APCM",
            "name": "Whitelist APC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5b6oa5VhzJMeC9eFiFPpJtAwnKvzEPHAnUTajRyKiMDf/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F6wb8jEnjJrZVfMDj7z1NYPiYhWsdEBShrBQkfNq9FZz",
            "symbol": "SIMT",
            "name": "Simba Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bukieldios/Simba/main/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Simba_Token"
            }
        },
        {
            "chainId": 101,
            "address": "H8p6D7kGAh8MnNTW17C2chRfbpQro4E23xJqziA14H8n",
            "symbol": "AVY",
            "name": "AVORY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ryebreadseeds/AvoryPic/main/Avory%20Background.jpg"
        },
        {
            "chainId": 103,
            "address": "D17PtjrzxNANtGDacNMRNwxtBU59phYCQXLMJWL5K1DW",
            "symbol": "WGBTC",
            "name": "GreenBitcoin",
            "decimals": 8,
            "logoURI": "https://ipfs.safewatch.care/ipns/Qmcojiocs93jWj9yVqCcqZTBNr8yjiTcb55pfrZPPNuBGv/assets/gbitcoin.svg",
            "tags": [
                "FNFT",
                "community-token",
                "color-coin"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "website": "https://duckduckgo.com/?q=greener+bitcoin+on+solana+!g"
            }
        },
        {
            "chainId": 101,
            "address": "4bAsJ44rDiFi31SqNV6tyrgYBUcqkdhmx65LLgdta3gQ",
            "symbol": "EKG",
            "name": "EKG NFT HRS 2022",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4bAsJ44rDiFi31SqNV6tyrgYBUcqkdhmx65LLgdta3gQ/logo.png",
            "tags": [
                "EKG",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BishoyHannaMD"
            }
        },
        {
            "chainId": 101,
            "address": "9P45hmWU9TMmHzjX2gxmvYxuucrVYbkVt77k561y5sYE",
            "symbol": "GIFT",
            "name": "Giftcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/techgiftcoin/spl-token-giftcoin/main/giftcoin-logo.png",
            "tags": [
                "social-token",
                "gift",
                "gift-token",
                "reward"
            ]
        },
        {
            "chainId": 101,
            "address": "8AN7mWuw6M81w3gDcxvvQR2Kr5RGKJjzh7ZKAPPmrcJG",
            "symbol": "SAKC",
            "name": "Sakura Collective",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8AN7mWuw6M81w3gDcxvvQR2Kr5RGKJjzh7ZKAPPmrcJG/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sakcnft"
            }
        },
        {
            "chainId": 101,
            "address": "EartHq4cviNGBaqS6dq1b1mENRVS6jV5tj1k3WMChykV",
            "symbol": "EARTH",
            "name": "Refined Crystal Earth",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitcoinblack482/logo/main/white.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FrHe1NUX2hRgnpZCTcxDEh8LU9uYEGU1uWhyYjvXLdFe",
            "symbol": "$BOING",
            "name": "BOING Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FrHe1NUX2hRgnpZCTcxDEh8LU9uYEGU1uWhyYjvXLdFe/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolWabbits",
                "website": "https://wabbits.co/"
            }
        },
        {
            "chainId": 101,
            "address": "2Dm1zu8ERJGBs3NLXt8s8Vor3YHwJye5E2pYhLiMHU4L",
            "symbol": "REAP",
            "name": "REAP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Dm1zu8ERJGBs3NLXt8s8Vor3YHwJye5E2pYhLiMHU4L/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dakureapersnft"
            }
        },
        {
            "chainId": 101,
            "address": "rvxo8t7TKeSmAgpdqK1CY9ddZi3NyowRCh1m2d7KrUc",
            "symbol": "SMRAI",
            "name": "SMRAI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rvxo8t7TKeSmAgpdqK1CY9ddZi3NyowRCh1m2d7KrUc/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SamuraiWarriorz"
            }
        },
        {
            "chainId": 101,
            "address": "EKuYvkDkNxkvGgpnmDJtFyp7bpaeKffMPp5DoTSJpHjs",
            "symbol": "XNU",
            "name": "XEANU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKuYvkDkNxkvGgpnmDJtFyp7bpaeKffMPp5DoTSJpHjs/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/XeanusMonkeysN",
                "website": "https://xeanusmonkeys.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8XUTstViEpLfhxaA88A6oWKraHm8V444bnSq6hm79vYh",
            "symbol": "RUGZ",
            "name": "RUGZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8XUTstViEpLfhxaA88A6oWKraHm8V444bnSq6hm79vYh/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ProbRug"
            }
        },
        {
            "chainId": 101,
            "address": "32Fwn7VL1CsDoZv4H33tZhkK7eudofG7oijNtfQpEuww",
            "symbol": "NURS",
            "name": "NurseDeck",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ev0lvE212/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Community engagement based social points for nursing professionals and nursing students",
                "facebook": "https://www.facebook.com/nursedeck",
                "instagram": "https://www.instagram.com/nursedeck",
                "linkedin": "https://www.linkedin.com/company/nursedeck",
                "twitter": "https://twitter.com/thenursedeck",
                "website": "https://nursedeck.com",
                "youtube": "https://www.youtube.com/channel/UCe6DHqdCDczRjQSs7R_2G3g"
            }
        },
        {
            "chainId": 101,
            "address": "Dk1HXZkE5tUn8EvNeT8GoB1JqcYioFp5LCPxGq8tTa7s",
            "symbol": "HAIR",
            "name": "HAIR",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dk1HXZkE5tUn8EvNeT8GoB1JqcYioFp5LCPxGq8tTa7s/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/soldiamondheads",
                "website": "https://diamondtownheads.io"
            }
        },
        {
            "chainId": 101,
            "address": "9DxD8kewV6qyLEgAv2bPHsfuJUxJumu9wkbj4JmkNzJu",
            "symbol": "VACT",
            "name": "Very Angry Chicken Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/boyanM/Other/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bg6GueVWbvP3S4xLGQRSW94Vcy1vp6xW3zFYykqFxoeu",
            "symbol": "BLVCK",
            "name": "BLVCK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BLVCKDEVIL/crypto/main/images.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SWLXP14ev2gFc3TTJaHP5o8SMq1Zu5isREYKE4UTdo3",
            "symbol": "SWLX",
            "name": "Sphinx Lab WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SWLXP14ev2gFc3TTJaHP5o8SMq1Zu5isREYKE4UTdo3/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LabSphinx",
                "website": "https://sphinx-lab.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8RxfJRQkwXbbLWK5piPmh9jnVZ2TFndpyiYhscWEEsUY",
            "symbol": "ZSWPZ",
            "name": "ZSWPZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RxfJRQkwXbbLWK5piPmh9jnVZ2TFndpyiYhscWEEsUY/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://swapoo.ai/"
            }
        },
        {
            "chainId": 101,
            "address": "BLkedSoxJsytxWFqrRZj5vJZ8w3sva3f7Wmm1iYxsAZw",
            "symbol": "BLDWL",
            "name": "Bots Life Deviant Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLkedSoxJsytxWFqrRZj5vJZ8w3sva3f7Wmm1iYxsAZw/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://botslifenft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "J9NBUALQvMAJsNb97QGWwHdRVWvL5Mc3923jVGFDf89D",
            "symbol": "WLZEBRA",
            "name": "Whitelist Zebra",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J9NBUALQvMAJsNb97QGWwHdRVWvL5Mc3923jVGFDf89D/logo.png"
        },
        {
            "chainId": 101,
            "address": "To5os14QBUk2As5emuUmWbSvRpW9kUjT3W28B66WMah",
            "symbol": "HSC",
            "name": "Hippy Solana Crew Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/To5os14QBUk2As5emuUmWbSvRpW9kUjT3W28B66WMah/logo.png"
        },
        {
            "chainId": 101,
            "address": "4xC1g2bG5rfjvAcB88UJGaNfoYGnPeVNQV2deEfSf34X",
            "symbol": "IBSBL",
            "name": "IbisBull Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IbisBull/cryptovault/main/IBSBLLogo.jpg",
            "tags": [
                "social-token",
                "utility-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Exr1Q3qzVFoeDuBK45gU9jzVj1uJBhQNs8fnUMwCev5y",
            "symbol": "GGWP",
            "name": "Global Games World Passion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Exr1Q3qzVFoeDuBK45gU9jzVj1uJBhQNs8fnUMwCev5y/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hw2JDWPm35DheBXoovHWJhVsqQJBVoEqZUayEpFVctqk",
            "symbol": "SUVNR",
            "name": "Metas",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hw2JDWPm35DheBXoovHWJhVsqQJBVoEqZUayEpFVctqk/logo.png",
            "tags": [
                "meta",
                "souvenir"
            ],
            "extensions": {
                "website": "http://metasouvenirs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "XeomDrjzsFuJLSSTDzrxbSkfYJHLeLVgmLUgA4cWArx",
            "symbol": "OOUM",
            "name": "OOU Meta V1 Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/XeomDrjzsFuJLSSTDzrxbSkfYJHLeLVgmLUgA4cWArx/oou.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "m7AYiyDGVzV4bGuV3R6iT3Psmj9GaU5s4WLqtL3VL9H",
            "symbol": "TEX",
            "name": "TEX Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/MorisBR/MyCrypto/main/TEXLogo3D.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://tex.com.br/"
            }
        },
        {
            "chainId": 101,
            "address": "WarsFNc1gd5P9eLKhszjopCZNvvHyB4kmUbavP3eA1i",
            "symbol": "WARS",
            "name": "GMG Hog Wars",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WarsFNc1gd5P9eLKhszjopCZNvvHyB4kmUbavP3eA1i/logo.jpg",
            "tags": [
                "apparel-token",
                "ecommerce-token",
                "fashion-token"
            ],
            "extensions": {
                "description": "WARS is a token for the gm Groundhogs Hog Wars event",
                "twitter": "https://twitter.com/gmGroundhogs",
                "website": "https://remilabs.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "cPQm9UM1ekMeAgRcYGLfNcBC65SLA8o2BVTaBUA7kTg",
            "symbol": "API",
            "name": "API Coin",
            "decimals": 9,
            "logoURI": "https://theblockchainapi-website.s3.amazonaws.com/logo512.png",
            "tags": [
                "community-token",
                "platform"
            ],
            "extensions": {
                "twitter": "https://twitter.com/_BlockX_",
                "website": "https://blockchainapi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HkCrU2Vk5kGvvPUUR2dirjgAx3TbyCPYXRZbefwzCyCp",
            "symbol": "LFI",
            "name": "Luffy Inu",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HkCrU2Vk5kGvvPUUR2dirjgAx3TbyCPYXRZbefwzCyCp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.io/luffyinu_sol",
                "twitter": "https://twitter.com/luffyinu_sol",
                "website": "https://luffyinu.live"
            }
        },
        {
            "chainId": 101,
            "address": "B1cLmU92KCAJ6yYAGa3aHUgnKfKRG5G7wqqbuPYqLihs",
            "symbol": "VOLTAIC",
            "name": "Voltaic",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/VoltaicLLC/VoltaicToken/main/Voltaic%20Symbol%20800x800.png",
            "tags": [
                "energy-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8R5pSiFD1YaABPf8EpkFPYcVgW1domzJEMRgFUwmFeKd",
            "symbol": "SEZ",
            "name": "Seokazer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MirProg/crypto/main/anchy-art-scale-6_00x-gigapixel_800x800.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3DYAkudDzEjoio5rSdGw8F5E79aqanAoWzsiXrShdbXY",
            "symbol": "YakuX",
            "name": "3D Yaku avatarX WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3DYAkudDzEjoio5rSdGw8F5E79aqanAoWzsiXrShdbXY/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/YakuCorp",
                "website": "https://www.yakushima.io"
            }
        },
        {
            "chainId": 101,
            "address": "ABZQ8Mx9WPDvkK5B9fWVTVSiL6E8KXjbk8FjimBBA5wm",
            "symbol": "MIMIC",
            "name": "Mimic",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Valygore/Mimic/main/Mimic%20Logo%20Black%20-%20White%20Background.png",
            "tags": [
                "utility-token",
                "social-token",
                "game-token"
            ],
            "extensions": {
                "description": "Mimic Project Token",
                "twitter": "https://twitter.com/MimicToken"
            }
        },
        {
            "chainId": 101,
            "address": "4hehqMkv8oNXCjicnzZJ6s84Ew7JXGadJk9uzFxWg2QR",
            "symbol": "AKARI",
            "name": "Akari Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hehqMkv8oNXCjicnzZJ6s84Ew7JXGadJk9uzFxWg2QR/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5zryMjwaGbfLNnpCyeohVyjXAFap4RGEdHhaAzVMqB8t",
            "symbol": "AKVT",
            "name": "Akari Vault Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5zryMjwaGbfLNnpCyeohVyjXAFap4RGEdHhaAzVMqB8t/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Nej2Nvgvfjm8uRtsJgQe4UeA1ycoSn6dDLruSKxNrz8",
            "symbol": "SNORT",
            "name": "Snortcoin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Nej2Nvgvfjm8uRtsJgQe4UeA1ycoSn6dDLruSKxNrz8/SNORTcoin.png",
            "tags": [
                "social-token",
                "share-with-a-friend"
            ],
            "extensions": {
                "description": "Share a SNORT with a friend",
                "website": "https://snort.zedazi.com"
            }
        },
        {
            "chainId": 101,
            "address": "AQn8JG2DFQ2kNisrue6THwksYWravFTmU1boUnSVjBKU",
            "symbol": "GOLFBET",
            "name": "Golf BET Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MorisBR/MyCrypto/main/GolfLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EYV1w4tZDqC7TkBKTmLwx2r5y8no2W6EFnNFmedsugWa",
            "symbol": "ODKYV2",
            "name": "OddKey V2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EYV1w4tZDqC7TkBKTmLwx2r5y8no2W6EFnNFmedsugWa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://oddkey.com"
            }
        },
        {
            "chainId": 101,
            "address": "FExy4ZgUdvJeAcb7E2kciNEfz8wzvr4uVUufvX6jMHX7",
            "symbol": "LIQ-RAY-BATTERY-USDC",
            "name": "BATTERY USDC Raydium Liquidity Pair",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/faction-cards/assets/main/images/coins/SFC-BATTERY-USDC-POOL/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Solana Faction Cards enables staking, governance and cross-chain multiverse.",
                "discord": "https://discord.com/invite/Wkjp3DhJuZ",
                "github": "https://github.com/faction-cards",
                "twitter": "https://twitter.com/faction_cards",
                "website": "https://faction.cards"
            }
        },
        {
            "chainId": 101,
            "address": "8Q6hdoeaCsEA6N26SMwfQd3D1nAskkbjzdzsCWcen1fV",
            "symbol": "AEK",
            "name": "Amberlyn Electric Kitten",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/De-veloper/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4dGfhcjNAQojhTXqaWnn4VmgqBwaRFyXHcbS299yrfdM",
            "symbol": "TDEV",
            "name": "trevdev",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/trevor256/trevdev-token/main/trevdev.svg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D5BYCgj9ATrgtPBvac1eXBF7T87Bfi85JdtvCnTKZZb9",
            "symbol": "DD44",
            "name": "Domestic Disorder",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/paddysc/crypto/main/DD44.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TombLgeWCentYMpgGZHxqUG6KiPcACXWfeVAuwrVC11",
            "symbol": "THS",
            "name": "TombStoned High Society WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TombLgeWCentYMpgGZHxqUG6KiPcACXWfeVAuwrVC11/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TombStonedHS",
                "website": "https://www.tombstoned.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "CELeSTbECuuPkTtSZ9pSUw6Z3CPxm1ggbrhmdhVpxzwD",
            "symbol": "CLZ",
            "name": "CELESTIALZ WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CELeSTbECuuPkTtSZ9pSUw6Z3CPxm1ggbrhmdhVpxzwD/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CELESTIALZ777",
                "website": "https://www.celestialznft.io"
            }
        },
        {
            "chainId": 101,
            "address": "BoFxKXdyiEYJReWGZAT4tavuAo3D1BmDyXK5VFSXd4EF",
            "symbol": "BOFx",
            "name": "BitOptionsFx",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoFxKXdyiEYJReWGZAT4tavuAo3D1BmDyXK5VFSXd4EF/logo.png",
            "tags": [
                "BitOptionsFx"
            ]
        },
        {
            "chainId": 101,
            "address": "AgqrB5sJxPC1zq57FEExn4Hm1FCyCBQuiivHYebvvBqU",
            "symbol": "BOSE",
            "name": "BoseBucks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fearthemetal/crypto/main/BoseBuck500cropped.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3wZ9hVw26xRkBcDjgNfUGaQgFsRhqVaZh19ZU2uyg6eo",
            "symbol": "LVWL",
            "name": "Lazy Villains WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KingpinFisk/Lazy-Villains-WL-Token/main/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "discord": "http://discord.gg/thelazyverse",
                "twitter": "https://twitter.com/thelazyverse",
                "website": "https://www.thelazyverse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CCJvffKRwhEEZZUzCwXPgjwZ24EpKJq3WTQt1ntaqZtR",
            "symbol": "MSI",
            "name": "Musii Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CCJvffKRwhEEZZUzCwXPgjwZ24EpKJq3WTQt1ntaqZtR/logo.png",
            "tags": [
                "music",
                "music-token",
                "social-token",
                "community-token",
                "play-to-earn"
            ],
            "extensions": {
                "description": "The musical social media on blockchain, a commnunity of a music enthusiasts! A place to exchange musial experience and to connect people through music.",
                "discord": "https://discord.gg/pF4cEccH",
                "instagram": "https://instagram.com/musii.app",
                "linkedin": "https://br.linkedin.com/company/musii-app",
                "telegram": "https://t.me/channelappmusii",
                "telegramAnnouncements": "https://t.me/appmusii",
                "twitter": "https://twitter.com/musiiapp",
                "website": "http://musii.app/",
                "whitepaper": "https://musii-app.gitbook.io/musii-connecting-people-through-music",
                "youtube": "https://www.youtube.com/channel/UCxD2lOQWGDVkgKXWCXriyQw"
            }
        },
        {
            "chainId": 101,
            "address": "HGE4xSAfuRZgpYZnt6GZ1vyqbdFafRESLE1AQFGEbBk9",
            "symbol": "XDN",
            "name": "XeDeen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/j2deen/crypto-xdn/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7BPCwgL97UMWcSuyUmDdNTzGnDvruyfGKTmUaSbLzohP",
            "symbol": "CHEF",
            "name": "ChefCoin",
            "decimals": 0,
            "logoURI": "https://metakitchen.io/static/media/mk.66f4827037442397afe6.jpeg",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "Inspired by our favorite chef competitions, our P2E cooking game will bring the mK NFT collections into the metaverse. Building a community of foodies on Solana.",
                "discord": "https://discord.gg/metakitchen",
                "instagram": "https://www.instagram.com/metakitchennft/",
                "medium": "https://medium.com/@metaKitchen",
                "twitter": "https://twitter.com/meta_Kitchen",
                "website": "https://metakitchen.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HjuNyD8Gt4sTGfFfa98h87p8UgsvJBzWq7EChhy3UcWj",
            "symbol": "ML",
            "name": "Mathieu Lefebvre Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Matlefebvre1234/MathieuLefebvreToken/main/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://www.mathieulefebvre.dev"
            }
        },
        {
            "chainId": 101,
            "address": "GjKfZatPZijAJJEPMQ9rXJX7foTb2943xAxQgiPfjZoy",
            "symbol": "FC",
            "name": "fish coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nonfungiblestuff/cryptofish/main/fish_crypto_crop.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8G9zfZHLPCrrowGWj5RV5WYq2uKgAQSwsAe7NWZS8JVt",
            "symbol": "CUNY",
            "name": "CunnyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/QTAnimeWaifu/crypto/main/CunnyCoinFinal.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/2DFDAbigail"
            }
        },
        {
            "chainId": 101,
            "address": "9MVgPE22LCr91QWuZvJrnPXggMi9GsEqt9coWEW3BAbe",
            "symbol": "MSDT",
            "name": "MS Delta Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MS-Delta-Token/crypto/main/Delta%20Token-logos%20png.png",
            "tags": [
                "community-token",
                "meme-token",
                "whitelist-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/G4rQUZ75gv",
                "instagram": "https://www.instagram.com/msdeltatoken/",
                "twitter": "https://twitter.com/MSDeltaToken"
            }
        },
        {
            "chainId": 101,
            "address": "HZBxUY5Hu9HNm6HSXrPQ77ABf9ZLqzUigCbTQiarBR2v",
            "symbol": "BTCUBI",
            "name": "Bitcoin UBI",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZBxUY5Hu9HNm6HSXrPQ77ABf9ZLqzUigCbTQiarBR2v/robot-logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/hello_btcubi",
                "website": "https://bitcoinubi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "27oGLMCDP57DE77rfVfe35oJaAnimFasJs3HyuaDUuuU",
            "symbol": "BKSP",
            "name": "Blackspot coin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/BahamaGov/assets/main/gold_PNG11020.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SSSCzT3yBad6GsSAcftqbbdcwBnLaBtXU6gYgDUMUAT",
            "symbol": "SSS",
            "name": "SolSkies WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SSSCzT3yBad6GsSAcftqbbdcwBnLaBtXU6gYgDUMUAT/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/SolSkiesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "JYxEEzuxzZhUybcbBSV126jaqzEvqPT1TC5NVWaYgWy",
            "symbol": "JY",
            "name": "JOY153",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JYxEEzuxzZhUybcbBSV126jaqzEvqPT1TC5NVWaYgWy/logo.png"
        },
        {
            "chainId": 101,
            "address": "3DCMhZ6aDVGjMYYhehYrvCEPxmvuvCLfqhCmMQRYeDy4",
            "symbol": "BUSSY",
            "name": "BussyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/QTAnimeWaifu/crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AstolfoFindom"
            }
        },
        {
            "chainId": 101,
            "address": "ToibiuwmaQVvbTdJ7Bor837TvXeKs7LG8CGhsqHLG46",
            "symbol": "TF-Pre",
            "name": "TwoFace-Pre",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToibiuwmaQVvbTdJ7Bor837TvXeKs7LG8CGhsqHLG46/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/yX4pMPsz",
                "twitter": "https://twitter.com/TwoFace_SOL",
                "website": "https://www.twofaceportraits.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DPwMbbfgAYoUzvwnY7S9ZTwcmTdKiL3GufPbU3sSn7Zt",
            "symbol": "OLVC",
            "name": "Olivcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rodrix1/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DqPwxiqS9ooPZftazpNUU8hM4wPdhZ6W94Pcyig1SQFL",
            "symbol": "JPNS",
            "name": "Japanese",
            "decimals": 3,
            "logoURI": "https://japanesewave.netlify.app/static/media/face.8bf18402cd3f3784c76e.png",
            "extensions": {
                "discord": "https://t.co/B41RzZZJGf",
                "twitter": "https://twitter.com/Japanese_Wave",
                "website": "https://japanesewave.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "VCNnsZ8ZSHExJzhavmfg8aPZ93WxmRhELMLq8K3HwYy",
            "symbol": "VCN",
            "name": "Vercinia",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VCNnsZ8ZSHExJzhavmfg8aPZ93WxmRhELMLq8K3HwYy/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/VerciniaNFT"
            }
        },
        {
            "chainId": 101,
            "address": "HMaAwHa6AxDSvV6w3NGsSP7FNGx3MtGUErBGji7mRARZ",
            "symbol": "ZENERGY",
            "name": "ZENERGY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/donaldlee725/som-pfp/main/zenergy.png",
            "tags": [
                "GOAT"
            ],
            "extensions": {
                "animationUrl": "https://raw.githubusercontent.com/donaldlee725/som-pfp/main/zenergy.gif",
                "description": "Spiritual Goats Passive Income Token",
                "twitter": "https://twitter.com/spiritualgoats",
                "website": "https://www.spiritualgoats.com/"
            }
        },
        {
            "chainId": 101,
            "address": "USDV47taduJQSPePwGsFR9GPbYRbmRt8BCx2fRRYJEt",
            "symbol": "USDV",
            "name": "Virtual USD",
            "decimals": 4,
            "logoURI": "https://media.atellix.net/token/usdv.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "description": "Virtual USD® Stablecoin from Atellix",
                "discord": "https://discord.gg/qC9azB6BeP",
                "twitter": "https://twitter.com/atellix",
                "website": "https://atellix.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EdRTiG6J4cZEtrYtwVwVBQQG2enm6PCQTGbuy5o1rPrz",
            "symbol": "SXM",
            "name": "SpaceXmove",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/SXM.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G5EsRrog1WAgxUVJdLzS137wU1TjPtcDqMd1LHfvUCXB",
            "symbol": "KHN",
            "name": "KushCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexkush35/crypto/main/kush.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "82bfDckzpVhcUsrytjWRS1jw5akjgJ4mMFqGLA2cD9pe",
            "symbol": "MCN",
            "name": "MarCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OkramCivejdan/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F6GSD1MjUAyS4KbnoMsUBsXuyimPH61e2Y8ytQWAAwFH",
            "symbol": "QMR",
            "name": "Qamar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ibbe13/crypto/main/Untitled-1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ewv5Y641TPYoFLBdb8XkWCukHbvW49ACzXMhcKtAy4xD",
            "symbol": "ddSOL",
            "name": "ddSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ewv5Y641TPYoFLBdb8XkWCukHbvW49ACzXMhcKtAy4xD/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://dazedducks.com"
            }
        },
        {
            "chainId": 101,
            "address": "Fuj1gZHrGu7x3W5uab7CrSXuy9cZ3qqc1me5LjGykt4d",
            "symbol": "ApesPlanet",
            "name": "Apes Planet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/affankhan43/crypto/master/apes-logo.png",
            "tags": [
                "nft",
                "metaverse",
                "P2e",
                "Gaming"
            ],
            "extensions": {
                "description": "Apes Planet is a civilization where 7,777 Ape Warriors live mastering the art of war",
                "discord": "https://discord.com/invite/apesplanet",
                "twitter": "https://twitter.com/ApesPlanetNFT"
            }
        },
        {
            "chainId": 101,
            "address": "3F2nPGTipnAM8BRs6hNmwYvyXTUyFRe5We4MqbGfSkUm",
            "symbol": "3MSP",
            "name": "3MSP DSC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/autonux/3MSP-Solana-Token/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/3MSP",
                "website": "http://www.3msp.net/"
            }
        },
        {
            "chainId": 101,
            "address": "AD5PZKiJNyCxaixUqp6qRxLHy5t6nAoqU3bE1bYoiHAJ",
            "symbol": "NABI",
            "name": "NABI wl",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AD5PZKiJNyCxaixUqp6qRxLHy5t6nAoqU3bE1bYoiHAJ/token.jpg",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DAM8b7iGuMCo5oaAP7BRh41NMBC6Ni7t4fg37zBRq6Q4",
            "symbol": "DAM",
            "name": "DAN AND MITCHY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAM8b7iGuMCo5oaAP7BRh41NMBC6Ni7t4fg37zBRq6Q4/logo.png"
        },
        {
            "chainId": 101,
            "address": "ZeCy2psga81yrLTZFF77vr9gXCuTg3CicfycRv591AW",
            "symbol": "LCT",
            "name": "Lucky Cat Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/wishnudh/webpro/master/goldicon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/luckycatSCTY",
                "website": "https://www.luckycatsociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MoNKaiYiiXeUGHHmCL9ABaDVLstSDMz3qH5at93puvy",
            "symbol": "MONKAI",
            "name": "MONKAI TOKEN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Neurologist/assets/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Monkai are the first Multi-chain Generative GIF NFTs on ETH \u0026 SOL, bringing new DeFi stake + farm tokenomics to the Blockchain",
                "discord": "https://discord.gg/monkai",
                "twitter": "https://twitter.com/monkainft",
                "website": "https://monkainft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "41QDYHN8UKDt4iJiBLgYDDZ22aF6pBFgX7kto2djERNf",
            "symbol": "DOTBUCKX",
            "name": "DotBuckx",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Player172/DotBuckx/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7rrJLRar2WjZwRoF3iJKHKnA7d7d9YJT1X9HAJnwUH3Z",
            "symbol": "WIZE",
            "name": "Project Wisdom",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rrJLRar2WjZwRoF3iJKHKnA7d7d9YJT1X9HAJnwUH3Z/logo.png"
        },
        {
            "chainId": 101,
            "address": "7FEN3FFaWcjiYcajh71qqfAW4eJX8Xr3b7hwHcwTFae8",
            "symbol": "APK",
            "name": "APK Cool",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/apraveenkumar1197/apk_coin/main/crypto_logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "UfoY2nXnXWyYAqcbupnNkfkRoGkNqYkdCHY6CyR56MZ",
            "symbol": "JVNT",
            "name": "Jovinite",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/0xJovin/solana/main/95069323-removebg-preview.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToHR6aQ4RTDEVhcXbVTarQvajGJD72GyPLSHt9iApLJ",
            "symbol": "SLSWL",
            "name": "Solaptops WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToHR6aQ4RTDEVhcXbVTarQvajGJD72GyPLSHt9iApLJ/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/YJfG8tcSmu",
                "twitter": "https://twitter.com/solaptops",
                "website": "https://www.solaptops.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "5P3giWpPBrVKL8QP8roKM7NsLdi3ie1Nc2b5r9mGtvwb",
            "symbol": "STR",
            "name": "Stream Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5P3giWpPBrVKL8QP8roKM7NsLdi3ie1Nc2b5r9mGtvwb/logo.jpg",
            "tags": [
                "community-token",
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dao_stream",
                "website": "https://streamdao.org/"
            }
        },
        {
            "chainId": 101,
            "address": "VVKNtVuJJ1fYPAh2tRbZWVssiRVY6Z8hari5DbR9omy",
            "symbol": "VPASS",
            "name": "Verdant Pass",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/hgQmnRj/icon-fix.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3MZsyU7GqgXyg4R6fDM6bGCqAqMCcPbiBGeyNvXCCt6v",
            "symbol": "SPEAK",
            "name": "SPEAK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolidityDevs/Solana-Peak/main/solopeak.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8bkxAiCsAsM3iPaEhqCjhjKU3XNuGAqgAAuCG6o8eW24",
            "symbol": "BUXO",
            "name": "Buxon Development Group",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8bkxAiCsAsM3iPaEhqCjhjKU3XNuGAqgAAuCG6o8eW24/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BuxonGroup"
            }
        },
        {
            "chainId": 101,
            "address": "GpdFKHRW23verFBHWRMAmvmwQmkwfyUiJFAALwcrUt8y",
            "symbol": "CC",
            "name": "Child Coins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChildCoins/crypto/main/child_coins_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CoyECRaedmKEpwySLTKsZJhpSQFAWpUfszaMTHVZSKPi",
            "symbol": "GUG",
            "name": "GugCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vihaan-s1ngla/customToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GTFBBcnRCT6Pa7rxDQHyYW4KMMebvkhB17dKBRkcHw78",
            "symbol": "AML",
            "name": "AmalThea Protocol",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GTFBBcnRCT6Pa7rxDQHyYW4KMMebvkhB17dKBRkcHw78/logo.jpg",
            "tags": [
                "Game-Token",
                "AML"
            ]
        },
        {
            "chainId": 101,
            "address": "52ndEqJE9nrTkUWoADWHzPgKsFD1RvJdNgL9x6JnL1zo",
            "symbol": "MPC",
            "name": "M P Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/He1senberg11/MPCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AASZr8AE3AK6nuSv7vJSdMgAUhKhsbg4iuRb1M81ETMN",
            "symbol": "PSG",
            "name": "ProSport Guru Token",
            "decimals": 0,
            "logoURI": "https://github.com/faugan2/psg-new-token/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "2GRf3hnKg3Cpbpt2PSzYeaKcoQXLnG87Yei6DWbKX2xC",
            "symbol": "USDT",
            "name": "xldUSDT (XLD Devnet)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/XLDFinance",
                "website": "https://xld.finance/"
            }
        },
        {
            "chainId": 103,
            "address": "4HK5XDLp6EkduexYYjwDErMTWBmrSbGYPYSLiho9U1Xu",
            "symbol": "FURR",
            "name": "FurrSols Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/furrsols/assets/main/FURR_GIF.gif",
            "tags": [
                "governance-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Furr_Sols",
                "website": "https://furrsols.io/"
            }
        },
        {
            "chainId": 103,
            "address": "FfnsNNWAx7xKTKFAjzD7m7uSi5n6MF1w8zj75J4ErFXS",
            "symbol": "USDT",
            "name": "xldUSDT (Devnet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 103,
            "address": "8d1CoyqxTbvhcwdN2eBmqQChrmodHyVsLE96GnZmr2W6",
            "symbol": "SLP",
            "name": "xldSLP (Devnet)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hpngEp1v3CXpeKB81Gw4sv7YvwUVRKvY3SGag9ND8Q4/logo.png",
            "tags": [
                "wrapped"
            ]
        },
        {
            "chainId": 103,
            "address": "CWGqLA6zaRvJvVgz7NimdvHe8BmkscjmAa9fiYgqdiXJ",
            "symbol": "DAI",
            "name": "xldDai (Devnet)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o/logo.png",
            "tags": [
                "wrapped"
            ]
        },
        {
            "chainId": 101,
            "address": "CThP76CAbtqBZEgqHWf5W9eSkDj7JGaBoz6dVbnWA7hE",
            "symbol": "BFFC100$b",
            "name": "Bank of Fone Fan Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danny8252/BFFC100-b/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9mvbLC3pB2wdTHxiiiQqQre6bHr9TQ6DXVkfe3ckcYYG",
            "symbol": "TURBO",
            "name": "Turbo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9mvbLC3pB2wdTHxiiiQqQre6bHr9TQ6DXVkfe3ckcYYG/logo.png",
            "tags": [
                "automotive-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TurbochargedNFT"
            }
        },
        {
            "chainId": 101,
            "address": "5oNoVpYsFteFdiGovkLFXebrSnGDqX3MgqJNAXwsuPM9",
            "symbol": "MDUK",
            "name": "My Ducky Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/an7ana5-2/My-Ducky-Coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "86PgpRgnhojD5b4ExVSNZ8QFc3WjcnTxChr35cuJU2yx",
            "symbol": "TCOIN",
            "name": "TJ COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tjgill1/cryptopng/main/Baby.tux-800x800.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7FqS7Zc1SzT79kCNtbDzsm85vZfBRR53FaUN2r4Z1dAC",
            "symbol": "SMTGEM",
            "name": "SmartGem",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7FqS7Zc1SzT79kCNtbDzsm85vZfBRR53FaUN2r4Z1dAC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://smartgems.org"
            }
        },
        {
            "chainId": 101,
            "address": "6NdLV4H1BUwKSFK51S6XhVUGVXNseZtmGi1vgqs27HHN",
            "symbol": "B0YZ",
            "name": "The Boyz Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theboyzyeg/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "22KcF82BzNg14X2eWMKLYPEykQz3mZPLe7fun3R3vq72",
            "symbol": "Rabbit",
            "name": "RTO-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/edmontana77/crypto2/main/RTO.png",
            "tags": [
                "social-token",
                "nft",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "7Cn6jmWSDZguFDizQURcLVGJf6Ct7i2RSo5d11A7Egix",
            "symbol": "GoNPWL",
            "name": "Gangs of New Pork White List Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Cn6jmWSDZguFDizQURcLVGJf6Ct7i2RSo5d11A7Egix/logo.png",
            "tags": [
                "utility-token",
                "whitelist-ticket"
            ]
        },
        {
            "chainId": 101,
            "address": "AefuNcVikKudanCHGRB5KkjdUoen9QBL4fFX6q1kGsRe",
            "symbol": "GEEXOWL",
            "name": "GEEXO Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AefuNcVikKudanCHGRB5KkjdUoen9QBL4fFX6q1kGsRe/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/exfYm6uJay",
                "instagram": "https://www.instagram.com/geexolotls/",
                "twitter": "https://twitter.com/geexolotls",
                "website": "https://www.geexolotls.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HBfHbAuCFCP34rSkPMXEt5UuLf8HH3SbuvH7DPA6aX8Q",
            "symbol": "CITRUS",
            "name": "CITRUS Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBfHbAuCFCP34rSkPMXEt5UuLf8HH3SbuvH7DPA6aX8Q/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://certifiedcitric.com"
            }
        },
        {
            "chainId": 101,
            "address": "8iWMXSUViPHYZDEpXiXZFiMo956VDCKMij1VUiE3VbDF",
            "symbol": "JBKA",
            "name": "Ben Dover",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PavleSavic90/KriptoSlika/main/SamoZdravoBajoMoj.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Nz4gAXydZcWHPN1EBxM1Rqzoaz7btyRVFhLN7PkfoJR",
            "symbol": "DILLO",
            "name": "DILLO",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/JaxSolana/dillo/Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheArmadillos",
                "website": "https://angrydillos.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GENRi6N2JvqRxLhcE89EK2ZPpiRQBg6D68Z25kK4RgcE",
            "symbol": "GENR",
            "name": "Generous Robots Dutch Auction Presale Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img924/7092/CTMJJy.jpg",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/generous_robots",
                "twitter": "https://twitter.com/GenerousRobots",
                "website": "https://generousrobots.com/"
            }
        },
        {
            "chainId": 101,
            "address": "WLkccWEEH6k6x7CZpaszQSwqK9VjQwabMV4wkaW3bxo",
            "symbol": "GLDWL",
            "name": "Gold Alpha Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLkccWEEH6k6x7CZpaszQSwqK9VjQwabMV4wkaW3bxo/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/thegoldalpha",
                "twitter": "https://twitter.com/TheGoldAlpha",
                "website": "https://thegoldalpha.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6xnUqAfxvDTZepHNWK1NsxXkjhFdTvuaGVXh3z29wRhx",
            "symbol": "BONEZ",
            "name": "Bonez",
            "decimals": 6,
            "logoURI": "https://i.ibb.co/Wk2BhWS/lastskull.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dq8zr1M6vbSLM9rvVpqTN6Lb82m3wEpxPrTZp7fXZ3wQ",
            "symbol": "CCWLT",
            "name": "CERTIFIED CITRIC WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dq8zr1M6vbSLM9rvVpqTN6Lb82m3wEpxPrTZp7fXZ3wQ/wl-logo.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "website": "http://certifiedcitric.com"
            }
        },
        {
            "chainId": 101,
            "address": "E2TXx1wFHNEGBtTfNgw6mVCmV9gRH45jyYNQwHJUH9oc",
            "symbol": "QCUT",
            "name": "QCU Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jcp-qcu/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LoTrcDXVtmu7CPaxnsVifJD8wwcEr4fhxKykrAQDKWE",
            "symbol": "LOT",
            "name": "Lottery",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LoTrcDXVtmu7CPaxnsVifJD8wwcEr4fhxKykrAQDKWE/logo.png",
            "tags": [
                "utility-token",
                "lottery",
                "casino"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lotme_official",
                "website": "https://lotme.in"
            }
        },
        {
            "chainId": 103,
            "address": "JD4oidcWARRJindyzyJxfAVYsv83wAsqyPdKS3Bphhej",
            "symbol": "DCTX",
            "name": "DCOSTA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JD4oidcWARRJindyzyJxfAVYsv83wAsqyPdKS3Bphhej/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "EDzBtG9AmwLoVBcDAn7StDJPb5jyfrDKqCYXiUFhBjke",
            "symbol": "moso",
            "name": "moso Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Moneshsoni/moso_token_crypto/main/moso.png",
            "tags": [
                "moso-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4hiMPLMDGkCRgKnDsXwhVZPZhESgp34ZZqymVHuBu1z2",
            "symbol": "MOTS",
            "name": "MOTS Game",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hiMPLMDGkCRgKnDsXwhVZPZhESgp34ZZqymVHuBu1z2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "MOTS Game is a P2E and NFT mining game developed on the Solana network.",
                "telegram": "https://t.me/motsgame",
                "telegramAnnouncements": "https://t.me/motsgameannouncement",
                "twitter": "https://twitter.com/motsgame",
                "website": "https://motsgame.io",
                "whitepaper": "https://whitepaper.motsgame.io"
            }
        },
        {
            "chainId": 101,
            "address": "FfKd5rGQXMtJaEKFTYLobQBVp4czgCF216DVDPXsCzky",
            "symbol": "FFST",
            "name": "FluxFusionstreams",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NItewolf07/crypto/main/FluxFusion_Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yUXcJCopBT5yiWy3gBQjNNotMP7rzkDScpCoA7z48cU",
            "symbol": "y-UXD-USDC",
            "name": "Yield Saber LP Token (UXD-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yUXcJCopBT5yiWy3gBQjNNotMP7rzkDScpCoA7z48cU/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G7JY5Ui9YghpXCjCAh9bEf3QgZ2HKKmys8JggNmhzH6k",
            "symbol": "$GODzWL",
            "name": "GODz WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G7JY5Ui9YghpXCjCAh9bEf3QgZ2HKKmys8JggNmhzH6k/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C4WG3YZCbN",
                "twitter": "https://twitter.com/nft_godz",
                "website": "https://godznft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EJt3iszkR6GKiV1YSyefB2GaHsXniyVAjAtS9mrxyTZR",
            "symbol": "MCCN",
            "name": "Collider Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EJt3iszkR6GKiV1YSyefB2GaHsXniyVAjAtS9mrxyTZR/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://collidercoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "G8KevG5RY3KgikX6iDHKaNSP7R2ERyDF9JFaTS1z1nms",
            "symbol": "acUSDC",
            "name": "Accrue USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G8KevG5RY3KgikX6iDHKaNSP7R2ERyDF9JFaTS1z1nms/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/accrue_finance",
                "website": "https://accrue.finance"
            }
        },
        {
            "chainId": 101,
            "address": "8RiWas3o1R6Dt1AVd3g4FUT6Ga4vGKW6BachqK86Pgaq",
            "symbol": "acSOL",
            "name": "Accrue SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RiWas3o1R6Dt1AVd3g4FUT6Ga4vGKW6BachqK86Pgaq/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/accrue_finance",
                "website": "https://accrue.finance"
            }
        },
        {
            "chainId": 101,
            "address": "21ytFGhW4qzCgNp3hf3yzb55R5sXzR4vYrPYm38v7KEe",
            "symbol": "GNMZ",
            "name": "Gnomez",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GNOMEZY/crypto/main/logo.jpg",
            "tags": [
                "social-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BtwngniRNdE9yUGV2WCxUmmH4HiYituAusR7giJDv566",
            "symbol": "MSOL",
            "name": "METASOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/METASOL2022/logomsol/main/Logo_msol.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2aztzADwa8aJHBnyna4yC6m8L433vAbT8fF3CScLhAEm",
            "symbol": "CTOKEN",
            "name": "CyberToken",
            "decimals": 6,
            "logoURI": "https://cdn.discordapp.com/attachments/953073433024012328/953216442545877042/logo-white.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/CyberFPS",
                "twitter": "https://twitter.com/CyberFPSGame",
                "website": "https://cybertrees.io"
            }
        },
        {
            "chainId": 101,
            "address": "3USr6wkoCiEXPGmTvTctAa9FrHuMRjBHhsynEBaYH6Ks",
            "symbol": "CHEERSK",
            "name": "CheersKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Davide-Mario/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5hTVhKaFRvdzkGtByoiW46aYmuS1o1t4kCJfTwTMTkdf",
            "symbol": "CLER",
            "name": "CLER",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/taviflorutza/CRYPTO/main/CLER.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CjQYX4NkZyk29yHvbquFieY9Cw9jMjXGxyci8Nb7RMc3",
            "symbol": "FVR",
            "name": "Favor You Owe Me One",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robertnanu/Favor/main/Favor.png?token=GHSAT0AAAAAABS26XWFBHTDD64BZPVR4FJQYSK4VLA",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GdGhA8PNnfTyCnL8dMSeZffwneXggurPfA7vnRjZMw1r",
            "symbol": "PNXKLR",
            "name": "PhoenixKiller",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gurrajman/Token/main/phoenix.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CVatR2DSHovFfFvJqeiuhgbjsHD9NpN9iAaqbVEZRxdW",
            "symbol": "SOLAND",
            "name": "Soland Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CVatR2DSHovFfFvJqeiuhgbjsHD9NpN9iAaqbVEZRxdW/logo520x520.png",
            "tags": [
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EnigWcs9jBQvBFnQaHvwGihpzVEELtGC25yxpKeJ68ZV",
            "symbol": "ENIGMA",
            "name": "Enigma Ventures GEN 2 Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img922/2193/GEK6pA.jpg",
            "tags": [
                "community-token",
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2",
            "symbol": "USDr",
            "name": "Ratio stable Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2/logo.png",
            "extensions": {
                "telegram": "https://t.me/ratiofinance",
                "twitter": "https://twitter.com/RatioFinance",
                "website": "https://ratio.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "HthhdbsiGKeswH7xzh1iyLfM5eLBVFgJyW9PL8CKdgqY",
            "symbol": "MIBOUN",
            "name": "FA7L BENZARTI TAHAN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HthhdbsiGKeswH7xzh1iyLfM5eLBVFgJyW9PL8CKdgqY/Bayrem.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HPGcyNmas47AgHrVsyH5A1JnRUxtJvNfLYEBtQ8WjWWL",
            "symbol": "HPG_WL",
            "name": "Hot Potato Gang Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HPGcyNmas47AgHrVsyH5A1JnRUxtJvNfLYEBtQ8WjWWL/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "Hot Potato Gang Whitelist Token - Token burns after mint",
                "discord": "https://discord.gg/4NJHDCmFA3",
                "twitter": "https://twitter.com/hotpotatogang",
                "website": "https://hotpotatogang.com"
            }
        },
        {
            "chainId": 101,
            "address": "AAHdKByKPccoWKYsV5kXXmmuBaS2gzSh7Bg5H3ByXjzD",
            "symbol": "$OKSA",
            "name": "Test coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AAHdKByKPccoWKYsV5kXXmmuBaS2gzSh7Bg5H3ByXjzD/logo.png",
            "tags": [
                "test"
            ]
        },
        {
            "chainId": 101,
            "address": "KoMRKGrap9sQFgeWrM3gNu8jacq2RyhGva6GZQ49xHj",
            "symbol": "Komradz",
            "name": "Komradz WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KoMRKGrap9sQFgeWrM3gNu8jacq2RyhGva6GZQ49xHj/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7pDvbQZ9ANogmc36xpjemR1T9ngkxSSvSr1WmuX6cnd",
            "symbol": "PECAN",
            "name": "PECAN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7pDvbQZ9ANogmc36xpjemR1T9ngkxSSvSr1WmuX6cnd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://ssa.gg/"
            }
        },
        {
            "chainId": 101,
            "address": "6pQnfdrZVm7mASQoRnDfAAi98ngS1eFNij2koW4kUZFS",
            "symbol": "DICP",
            "name": "DIC punch",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6pQnfdrZVm7mASQoRnDfAAi98ngS1eFNij2koW4kUZFS/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/thegimmicks",
                "website": "https://www.therealgimmicks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9bCWGwpMK4JGK2mMsPxq62tHFfe82RbwFbnQ7Bgf2PiW",
            "symbol": "COA",
            "name": "Coal for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9bCWGwpMK4JGK2mMsPxq62tHFfe82RbwFbnQ7Bgf2PiW/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "A3M9mjaqpeiDxLYn5UmfmpBZMxQKSVCA6F9QMKijdyGZ",
            "symbol": "COP",
            "name": "Copper for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3M9mjaqpeiDxLYn5UmfmpBZMxQKSVCA6F9QMKijdyGZ/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "8APGrtjuEMDYFf6dPv7ZkhxLKCHgdXhH9dqDi7UhWxJ7",
            "symbol": "SIL",
            "name": "Silver for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8APGrtjuEMDYFf6dPv7ZkhxLKCHgdXhH9dqDi7UhWxJ7/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "FGZtrEJFE3rSEVdVjJyorE5RrMHvuLHaGUa4kH5WwMnR",
            "symbol": "GOL",
            "name": "Gold for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGZtrEJFE3rSEVdVjJyorE5RrMHvuLHaGUa4kH5WwMnR/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "Gq3v8BadgWv66pLnyU6yuk8EkVKz6a9rtrYCejN6F2r5",
            "symbol": "DIA",
            "name": "Diamond for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gq3v8BadgWv66pLnyU6yuk8EkVKz6a9rtrYCejN6F2r5/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "8nRPoRxueGMx3ZoD2kws6dr6iHcYffRNWUMC7LBQgHjD",
            "symbol": "COL",
            "name": "Coltan for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8nRPoRxueGMx3ZoD2kws6dr6iHcYffRNWUMC7LBQgHjD/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "BRWmzjdY5dHgXsWRa7a3kuM22NEyDk6eAX8d7yx4bR7L",
            "symbol": "PAI",
            "name": "Painite for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRWmzjdY5dHgXsWRa7a3kuM22NEyDk6eAX8d7yx4bR7L/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "2LUeVhXSQs4nUtiv6BY3pN1wF4y1XsNTaqXJ4LDwc3Hy",
            "symbol": "BAO",
            "name": "Black Opal for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2LUeVhXSQs4nUtiv6BY3pN1wF4y1XsNTaqXJ4LDwc3Hy/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "297ddHYikegC7NFPZyn7R2fV6GXrkHLDX3dKo5KBdA3Z",
            "symbol": "RED",
            "name": "Red Diamond for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/297ddHYikegC7NFPZyn7R2fV6GXrkHLDX3dKo5KBdA3Z/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "2fLfRiX7cCWk9SZfmZbbjnJF6HE5qENjopPmnhMovfhd",
            "symbol": "BLO",
            "name": "Blue Opsidian for MOTS Game",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2fLfRiX7cCWk9SZfmZbbjnJF6HE5qENjopPmnhMovfhd/logo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "pToHR9ATnztDaF6CqK3CqQV6MVDkCfAFwrjstktatXS",
            "symbol": "pTICKETS",
            "name": "pTickets",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pToHR9ATnztDaF6CqK3CqQV6MVDkCfAFwrjstktatXS/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3HGKM3rj9XmVfyGmQR4D2md6vRDoVKwcicWXDnb7cGtZ",
            "symbol": "CRZCN",
            "name": "CruiseCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crzcoindev/CruiseCoin/main/shiplogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D5Q4JrSS1zdH5k5Z8hVUV3gVyhQTx5x1v9dqsNChAdma",
            "symbol": "FRAME",
            "name": "NEOFRAME",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/rxrxrxrx/neoframe/neoframe.png",
            "tags": [
                "neoframe",
                "print",
                "frame"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Neo_Frame_NFT",
                "website": "https://neoframe.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D34QaWVkFZn3Z4rgCbzjaT9NviVLmvGEBgxHpP5f3bdR",
            "symbol": "acBTC",
            "name": "Accrue BTC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D34QaWVkFZn3Z4rgCbzjaT9NviVLmvGEBgxHpP5f3bdR/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/accrue_finance",
                "website": "https://accrue.finance"
            }
        },
        {
            "chainId": 101,
            "address": "6v4qfXy1yWpVEbmK4Juz3iMDdo6EPg5gJm1rtDb2jqVa",
            "symbol": "RIZE",
            "name": "RIZE NETWORK TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/abovewallstreet/RIZE-NETWORK/main/assets/RIZE%20NETWORK%20LOGO-blk-500x500.png",
            "tags": [
                "utility-token",
                "NFT-platform",
                "DAO"
            ],
            "extensions": {
                "discord": "https://discord.gg/c9537zpxrt",
                "telegram": "https://t.me/rizenetwork",
                "twitter": "https://twitter.com/rizetoken",
                "website": "https://rizenetwork.com"
            }
        },
        {
            "chainId": 101,
            "address": "AVkxtqhLSjkP4XjSchmpA9xTrXWvLsVkpiqC9VdUqyQX",
            "symbol": "DNCS",
            "name": "Dees Coins",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/794737676875071500/958158985482096711/LOGO.png?size=4096",
            "tags": [
                "community-token",
                "whitelist-token",
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CWintzgasUVMK5YVbnwrNjMEV3Uen6rYYqbXNdXxKqnQ",
            "symbol": "CWint",
            "name": "CatchKing Winter Coupon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWintzgasUVMK5YVbnwrNjMEV3Uen6rYYqbXNdXxKqnQ/icon.png",
            "tags": [
                "winter-coupon",
                "game-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/catchking",
                "twitter": "https://twitter.com/catch_king",
                "website": "https://www.catchking.io/"
            }
        },
        {
            "chainId": 101,
            "address": "rafWuTLdrLuQs1CpAkVsZnerGPto6B7gHU7B12fNa37",
            "symbol": "RAFDSTK",
            "name": "RAF Dev Stakereward",
            "decimals": 6,
            "logoURI": "https://arweave.net/grzrJ41ji5LtJ0P6iQtOOD5mA49IpXpq6mI9Uao7I8Y",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/W8fra6bABu",
                "twitter": "https://twitter.com/rationalasf",
                "website": "https://linktr.ee/rationalasfuck",
                "youtube": "https://www.youtube.com/c/RationalAF"
            }
        },
        {
            "chainId": 101,
            "address": "rafbXPy26CD2oavXQQcpLafRVdp3TUamFiPQj3pkGEu",
            "symbol": "RAFDMNT",
            "name": "RAF Dev Mint",
            "decimals": 0,
            "logoURI": "https://arweave.net/4luPz027xADW_ysqoe9qcy6ooVDgA1hB20bqhktmQYk",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/W8fra6bABu",
                "twitter": "https://twitter.com/rationalasf",
                "website": "https://linktr.ee/rationalasfuck",
                "youtube": "https://www.youtube.com/c/RationalAF"
            }
        },
        {
            "chainId": 101,
            "address": "5KwJTX1iHzYNKjWDqz8d3zMnqAAaVkZhsT3qqPWGSqF6",
            "symbol": "MOBF",
            "name": "Bouyant Fairies (A Mad Owls collection)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5KwJTX1iHzYNKjWDqz8d3zMnqAAaVkZhsT3qqPWGSqF6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/4zv3vhHTUn",
                "twitter": "https://twitter.com/MadOwlsNFT",
                "website": "https://madowls.art/"
            }
        },
        {
            "chainId": 101,
            "address": "GCHK2hkKoQBfoiyChty5kddQNGqodwKwxdr6MYaYVmSn",
            "symbol": "GLOW",
            "name": "Glow Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GCHK2hkKoQBfoiyChty5kddQNGqodwKwxdr6MYaYVmSn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AkariLabs",
                "website": "https://akarilabs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Em3mRfTT8ZXp1U8eGZKCbrHW6ciBJn5UqaWfHemXnbCd",
            "symbol": "GVAULT",
            "name": "Glow Vault Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Em3mRfTT8ZXp1U8eGZKCbrHW6ciBJn5UqaWfHemXnbCd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AkariLabs",
                "website": "https://akarilabs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "WMagFCZ3JWiFF9vmbvkNxNK52hX74Jhzk6GzcyZCdcD",
            "symbol": "$WMAGIC",
            "name": "Witch Kingdom Magic Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WMagFCZ3JWiFF9vmbvkNxNK52hX74Jhzk6GzcyZCdcD/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.witchkingdom.com/"
            }
        },
        {
            "chainId": 101,
            "address": "51JGqKym9sz2UsMFksgqg9uynWrVXev2RtdexQfH4SLi",
            "symbol": "WLIST",
            "name": "WhitelistAIO WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Heisenberg-sol/name/main/WhitelistAIO_WL.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6U6MyKr7pb"
            }
        },
        {
            "chainId": 101,
            "address": "9gmRW9iXfq8VdRjekgMX5tf3m572wofcdyXwrqfo4eZ4",
            "symbol": "GRDY",
            "name": "Greedy",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gmRW9iXfq8VdRjekgMX5tf3m572wofcdyXwrqfo4eZ4/GREEDYCOIN-min.png"
        },
        {
            "chainId": 101,
            "address": "HBrjj2814WZrB7zrTmkvxUiETG1QqDWCmM5CQAtHL2kF",
            "symbol": "LAMC",
            "name": "LamarcaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/testbughub/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7QxPk2TmM8WXASKKDdduTiYJzzHKEyeLTQh4UfQVBxpN",
            "symbol": "ELC",
            "name": "Eloncoin1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ElonCoin0101/ElonCoin/main/ElonCoin-logos.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PYQXgjSLLSXJqdfNspjqdaJHLAAS9dxSkpxs3srbWkH",
            "symbol": "CRAB",
            "name": "Crabcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/undert0wn/crypto/main/crab.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4hUEY2tvFzKhFUTYnF36fEygktrUmnY1pqaefcVoyf1w",
            "symbol": "KSC",
            "name": "Khasla Raj Network",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tjgill1/Khalsa-img/main/khasla.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6wa8ydqbVdbLapvfeXSfdV8gR2ZSTGfeJCqsD3NZ5zpq",
            "symbol": "Dj",
            "name": "Dj Coin",
            "decimals": 9,
            "logoURI": "https://github.com/Fulkatomas/DjCoin/blob/main/logo.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Dj-Coin-103643742309595",
                "twitter": "https://twitter.com/Dj1Coin"
            }
        },
        {
            "chainId": 101,
            "address": "CEN5hFZKA1vY9g9BQYF9oejXhe9zqJ13ckxmo4CpHWa1",
            "symbol": "CentWL",
            "name": "Centaurians WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CEN5hFZKA1vY9g9BQYF9oejXhe9zqJ13ckxmo4CpHWa1/CentauriansWLToken.svg",
            "tags": [
                "utility-token",
                "social-token",
                "governance-token"
            ],
            "extensions": {
                "description": "Multi-Utility Community Driven Project with 3,000 Centaurians on the Solana blockchain. In the desolate era of humankind's devolution, remaining survivors embark on a final mission to seek out salvation. Their adventure is cut short from otherworldly forces, will they have what it takes to survive?",
                "discord": "http://discord.gg/5mCACy37FQ",
                "twitter": "https://twitter.com/The_Centaurians",
                "website": "https://centaurians.live"
            }
        },
        {
            "chainId": 101,
            "address": "CFJUeSeYQtHgNyzrcmdDFkHmuxh8MPY9kov4GxVsqrhR",
            "symbol": "SEMBII",
            "name": "Sembii Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tuvshinorg/Sembii/master/token-logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.sembii.com/"
            }
        },
        {
            "chainId": 101,
            "address": "REeDoodZrTzXrfH4kyqzznBqwLot1x566St7KPar18g",
            "symbol": "REEDS",
            "name": "Reeds",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HippoAlpha/reedslogo/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dNKW3qw7Rw",
                "twitter": "https://twitter.com/RenegadeHippos",
                "website": "https://www.renegadehippos.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5AsCTEEDBH9qpfCP2nVV891cbXHJfXyiQgnKJyUFSzjB",
            "symbol": "TBTO",
            "name": "Tree Block Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5AsCTEEDBH9qpfCP2nVV891cbXHJfXyiQgnKJyUFSzjB/logo1.png"
        },
        {
            "chainId": 101,
            "address": "EdsV136bYNoY2Z1G9bMERphMM1a1wQF5W6iDKMYgQH7V",
            "symbol": "LR",
            "name": "landreit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdsV136bYNoY2Z1G9bMERphMM1a1wQF5W6iDKMYgQH7V/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H7oqks8Xi4c4xfKMYzDKKYbAiTrmCGUAi4mja11MjUzv",
            "symbol": "OBIT",
            "name": "off bit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bndsknzlr/off_bit/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "97pMYg5WZXm9cXWBJWUFcGQ7sb64RAkNhDDYqqPq5KyN",
            "symbol": "RAISIN",
            "name": "RAISIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97pMYg5WZXm9cXWBJWUFcGQ7sb64RAkNhDDYqqPq5KyN/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://grimsyndicate.com"
            }
        },
        {
            "chainId": 101,
            "address": "5h2vV75XStgNtGwWJ9JEwtUaEPwhTiDTHfQYqHMeK7Nh",
            "symbol": "WEN",
            "name": "Wenergy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DeanoDragon/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BPeXQCaDyL4kU4EU2pTMrBsTKm3ViUkEseWyj9csXMzU",
            "symbol": "BRIBE",
            "name": "SPP Bribe Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/virdagreat147/bribe/main/briber.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5FJeUZ6Tn8F6ErCL3NinmpvSqjeFPq6ffvgCYug4r1kL",
            "symbol": "POKER",
            "name": "Poker Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5FJeUZ6Tn8F6ErCL3NinmpvSqjeFPq6ffvgCYug4r1kL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SCpokerclub",
                "website": "http://solcitypoker.com"
            }
        },
        {
            "chainId": 101,
            "address": "AcK8dJTKvxh9hUhTMLdbsczUTrjfUNHSJ9znTaYDgLVT",
            "symbol": "SOLCITYPH2",
            "name": "SolCity Poker Club Phase 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AcK8dJTKvxh9hUhTMLdbsczUTrjfUNHSJ9znTaYDgLVT/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CajpcNHraSHMzQevzan2m4Cxwn1u2NHbTupEfQ236uyM",
            "symbol": "FRFL",
            "name": "FreeFuel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FreeFuelcoin/logo/main/F%20(800%20%C3%97%20800%20px).png",
            "tags": [
                "utility-token",
                "community-token",
                "FreeFuel"
            ],
            "extensions": {
                "discord": "https://discord.com/channels/958429684788518942/958429684788518944",
                "twitter": "https://twitter.com/FreeFuelCoin"
            }
        },
        {
            "chainId": 101,
            "address": "FvaExVNHCACXXeTDj6hpZFLCyhYekT8zxpfPXiMtba51",
            "symbol": "SWNT",
            "name": "Swiss National Token",
            "decimals": 9,
            "logoURI": "https://github.com/OmiCodez/SWNT/raw/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2KKhXL6Ajkhedp9rRNHXen1CzhZnYfonkBno7iVcHpsD",
            "symbol": "CLOSEDLHMINT",
            "name": "Loot Heroes' Mint Token Closed WL",
            "decimals": 0,
            "logoURI": "https://lootheroes.io/lhminttokencwl.png",
            "extensions": {
                "twitter": "https://twitter.com/lootheroesnft",
                "website": "https://lootheroes.io"
            }
        },
        {
            "chainId": 101,
            "address": "46G5mPufPTxteTBqr98yjpePtTrSYdF1jjvR71Ju1mM1",
            "symbol": "OPENLHMINT",
            "name": "Loot Heroes' Mint Token Open WL",
            "decimals": 0,
            "logoURI": "https://lootheroes.io/lhminttokenowl.png",
            "extensions": {
                "twitter": "https://twitter.com/lootheroesnft",
                "website": "https://lootheroes.io"
            }
        },
        {
            "chainId": 101,
            "address": "CFowe8TTZgwawPdRggJtAb2GjfCaPDg5qnUY5HP3UJWZ",
            "symbol": "LHBRONZECHEST",
            "name": "Loot Heroes' Bronze Chest",
            "decimals": 0,
            "logoURI": "https://lootheroes.io/lhbronzechest.png",
            "extensions": {
                "twitter": "https://twitter.com/lootheroesnft",
                "website": "https://lootheroes.io"
            }
        },
        {
            "chainId": 101,
            "address": "6AierQY71omEf7gbUiwvQJC2Q3vYBgQ3UZojwGtjqb9S",
            "symbol": "LHSILVERCHEST",
            "name": "Loot Heroes' Silver Chest",
            "decimals": 0,
            "logoURI": "https://lootheroes.io/lhsilverchest.png",
            "extensions": {
                "twitter": "https://twitter.com/lootheroesnft",
                "website": "https://lootheroes.io"
            }
        },
        {
            "chainId": 101,
            "address": "A4W75bfTm8YLfR2BwtunZKWxeSzxbKuiMCZgTdGYLvVG",
            "symbol": "LHGOLDCHEST",
            "name": "Loot Heroes' Gold Chest",
            "decimals": 0,
            "logoURI": "https://lootheroes.io/lhgoldchest.png",
            "extensions": {
                "twitter": "https://twitter.com/lootheroesnft",
                "website": "https://lootheroes.io"
            }
        },
        {
            "chainId": 101,
            "address": "MekadxAsHWBzhJi2FBvadaKzhAdPpsK6MCjmp7579QN",
            "symbol": "MKX",
            "name": "Meka X Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MekadxAsHWBzhJi2FBvadaKzhAdPpsK6MCjmp7579QN/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4bwMPPipiPafcxxtSBPMUgCkv8hF4xAyWsUzxr3DLYPZ",
            "symbol": "DISK",
            "name": "FloppyLabs token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/floppy-labs/disk-logo/master/disk_logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "waodAGjWGKKG4bKNdiXpx8sVSCWgwcJh1vt88XLPdib",
            "symbol": "AODWL",
            "name": "Age of DeFi Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/waodAGjWGKKG4bKNdiXpx8sVSCWgwcJh1vt88XLPdib/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://ageofdefi.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "3YAQawvYxNLRAdgjt71g8hct72j1R7dVUvR5ghtVjkCn",
            "symbol": "SALVAR",
            "name": "Salvar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SalvaP2E/SALVAR/main/Salva.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ECQkERLRgPGW34P5jjLvEGy449qGjq75BinqynBNyCqp",
            "symbol": "ECOPNT",
            "name": "ECOpoint",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/juancano57/logos_vortex/main/logos/ECQkERLRgPGW34P5jjLvEGy449qGjq75BinqynBNyCqp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EconomY_Solutio",
                "website": "https://humanenergysolutions.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8kub6BkBGf3Xd1GhtYvZZoh8KS88gauMSrSBy6iuDw8A",
            "symbol": "POKER",
            "name": "SolCity Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kub6BkBGf3Xd1GhtYvZZoh8KS88gauMSrSBy6iuDw8A/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SCpokerclub",
                "website": "http://solcitypoker.com"
            }
        },
        {
            "chainId": 101,
            "address": "Enigfq6hqjVHMZSLBb5NrXaN59k14abZGgtd9CBTpxso",
            "symbol": "ENIGMA",
            "name": "Enigma Ventures GEN 2 WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Enigfq6hqjVHMZSLBb5NrXaN59k14abZGgtd9CBTpxso/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GoDZeDqM8Q7vutygP5MpKYngccUncSYNZch93PPieqbk",
            "symbol": "GODZ",
            "name": "GODZ OF SOLARIA WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoDZeDqM8Q7vutygP5MpKYngccUncSYNZch93PPieqbk/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/nft_godz",
                "website": "https://godznft.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ed1934BYVPQQ2KXbzCLxBMtNVZN3SjanTewfR9upbb7L",
            "symbol": "$SAKE",
            "name": "SAKE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ed1934BYVPQQ2KXbzCLxBMtNVZN3SjanTewfR9upbb7L/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/rdPMAqpDRR",
                "twitter": "https://twitter.com/Soul_of_Hidama"
            }
        },
        {
            "chainId": 101,
            "address": "xPAs8dDnMH4xyehXZfiBVLBCPkbQN358VqrAJtwfnKr",
            "symbol": "CSA",
            "name": "Crypto Space Academy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/casyani/cryptospaceacademy/main/CRYPTOSPACEACADEMY.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5MH6q9ZFcLkMp4SYqDCq36QmyWBigmShrQjZrjYd1F2d",
            "symbol": "DHLD",
            "name": "Diamond Hand: Legends",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5MH6q9ZFcLkMp4SYqDCq36QmyWBigmShrQjZrjYd1F2d/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dhlegends",
                "website": "https://www.diamondhandlegends.com"
            }
        },
        {
            "chainId": 103,
            "address": "tstu5tiwUgG8CVyz1YAYmeNkVcQ5QxojuYLiCnXhHtz",
            "symbol": "$testSPS",
            "name": "test sps token on devnet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tstu5tiwUgG8CVyz1YAYmeNkVcQ5QxojuYLiCnXhHtz/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gns4DFoM98sib6XJSojU2hm7C6jU9qYWLzYu1Z7HAb8E",
            "symbol": "WCDL",
            "name": "Wcdolar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/wcdolar.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WcdonaldsNFT",
                "website": "https://wcdonalds.io"
            }
        },
        {
            "chainId": 103,
            "address": "SEobhtZX5eVTpHBKcysxqnMfr6msFWtGzQeYX7cTSmW",
            "symbol": "$TEST",
            "name": "TEST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SEobhtZX5eVTpHBKcysxqnMfr6msFWtGzQeYX7cTSmW/logo.png"
        },
        {
            "chainId": 101,
            "address": "6u1HdBCs2Xsh1xr5PNKfoSKnHGHSoYxR5pqzAAzwCAMT",
            "symbol": "MSCT",
            "name": "Metascape City",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6u1HdBCs2Xsh1xr5PNKfoSKnHGHSoYxR5pqzAAzwCAMT/logo520x520.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2NWB5P7ZftECLAgPHH4weQdDruGf239AQ9hG2gmxucmd",
            "symbol": "DAIS",
            "name": "DaisyDOLLAR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2NWB5P7ZftECLAgPHH4weQdDruGf239AQ9hG2gmxucmd/logo.png",
            "tags": [
                "social-token",
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "7zSTEvkJ21NJzi4FJwVvg3VuSX38B6cWVAzRkDjHbzwJ",
            "symbol": "QORBI",
            "name": "Qorbi Coin",
            "decimals": 9,
            "logoURI": "https://qbaby-web-assets.s3.amazonaws.com/qorbicoin.png",
            "tags": [
                "community-token",
                "game-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/9Rv4nwxx5Y",
                "medium": "https://qorbaby.medium.com/",
                "twitter": "https://twitter.com/QorBaby",
                "website": "https://www.qorbaby.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AeZWihQPDF9KajjMKpWnSZnZeLNVXVov33wkbchZ4SWL",
            "symbol": "MIND",
            "name": "MINDcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/J62F7/Token-Logos/main/mind2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "7bGxwtDyr9b5Hp2wk5Usvc1i5GFADXy5XaoM6tZQRnkP",
            "symbol": "AAA",
            "name": "AAApoc",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solalchemist/mymetaplex/master/AAA...png"
        },
        {
            "chainId": 101,
            "address": "GLUVc3V1Auq6rR8x3kfH28VFrpwZ8mD8qhd6GsK743Vq",
            "symbol": "Down",
            "name": "AREYOUDOWN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/undert0wn/crypto/main/down.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cornwa1ifRukcEgGmipzrcDDqdksQ34hh9Ca4ukn9tet",
            "symbol": "TBFWL",
            "name": "The Big Five Official WHITELIST Token",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/89742297/160547178-746b2b27-85ff-4ccc-b766-f34a0e012a9d.jpg",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GrBqg7aZGVbj3LXu2Lhsewnq5uYfxs9VcXx8La4fKWf3",
            "symbol": "TBFPRE",
            "name": "The Big Five Official PREMINT Token",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/89742297/160547178-746b2b27-85ff-4ccc-b766-f34a0e012a9d.jpg",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE",
            "symbol": "HAWK",
            "name": "Hawksight",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/hawksight",
                "medium": "https://hawksight.medium.com/",
                "twitter": "https://twitter.com/HawksightCo",
                "website": "https://hawksight.co/"
            }
        },
        {
            "chainId": 101,
            "address": "4tGKW2J2owiNLb8y4cvEUcQkgVBY48XshzLqtYLaRXD9",
            "symbol": "SSI",
            "name": "SSI Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SolusiStartup/SSI-Token/main/ssi-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5PE8Y5PPfZgVB4Ak6vLV9K2iBzovCKPwpLZrJZgaXWpR",
            "symbol": "GLI",
            "name": "Green Light Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/gintarastamosiunas/Assets/main/Gtokenlogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "medium": "https://medium.com/@glight.network",
                "twitter": "https://twitter.com/glight_network",
                "website": "https://glight.network/"
            }
        },
        {
            "chainId": 101,
            "address": "4nonTGa2WJZWy9MzmYSWZ6dpTmE3TNmDube2dyD9Q82A",
            "symbol": "SRCM",
            "name": "SourCream Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SourCreamCulture/crypto-coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "VGZw5mKqnaoHafA8kRWX1CZCRF2Etjr4EKnZJuVguot",
            "symbol": "SOLR",
            "name": "Solana Ray",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VGZw5mKqnaoHafA8kRWX1CZCRF2Etjr4EKnZJuVguot/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://home.solr.space/"
            }
        },
        {
            "chainId": 101,
            "address": "FNpCzhQy5NZgEbmcJLedQebpRKRvw8sBGizhYj1uifGX",
            "symbol": "BREAD",
            "name": "DDAC Bread",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/TreeBurgers/DDAC/main/Token.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DULsJwz9E49JTkmSHCUifrzNzzMk9qUHaVxna6xA6umt",
            "symbol": "LYCH",
            "name": "Lychee Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fungtoken/crypto/main/Lychee.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5vwQYD5PobkeJJX6QwPhfbNBwPpaUZTd4b68yZWf1JqB",
            "symbol": "SUC",
            "name": "SOUIL COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SouilCapTn/SOUILCOIN/main/SOUILCOIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Domn87XEqaE31Sq2piAGvUxg79vCdp3RPmW2WSQohird",
            "symbol": "DOM",
            "name": "Domination Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Domn87XEqaE31Sq2piAGvUxg79vCdp3RPmW2WSQohird/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/kaizencorps",
                "twitter": "https://twitter.com/KaizenCorpsNFT",
                "website": "https://domination.kaizencorps.com"
            }
        },
        {
            "chainId": 101,
            "address": "FZBgqzZNMT3pnewCsA7TSp4jQp8mTgGxSBZdcpbpAxDy",
            "symbol": "ICECC",
            "name": "Ic3 Cult Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/supercharged-audi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9mqG8psqZdyXfuNY2RTNb9Ypc9ydfke6kLYhy1t4ErU9",
            "symbol": "S_TOKEN",
            "name": "Solana token on devnet",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/1111-1993/S_TOKEN/generation_2.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NhX5EzH4"
            }
        },
        {
            "chainId": 101,
            "address": "AifYDLtk3vx4b4vFGckkwMoFtXQTcATYZnU3JF6oDgo2",
            "symbol": "PRE",
            "name": "Pierre token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AifYDLtk3vx4b4vFGckkwMoFtXQTcATYZnU3JF6oDgo2/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHVV3NwB77oSnBUqRJNeb6F5x6e3xBrYMrbxDDvdSGnu",
            "symbol": "GRAMZ",
            "name": "Thugverse GRAMZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHVV3NwB77oSnBUqRJNeb6F5x6e3xBrYMrbxDDvdSGnu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thugsteadz",
                "website": "https://thugverse.io"
            }
        },
        {
            "chainId": 103,
            "address": "HPjF6kgyyfRpPkSaC9NNkKPwTrwePzM3R8WRRJQxgeSb",
            "symbol": "CGU",
            "name": "CGU Test",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HPjF6kgyyfRpPkSaC9NNkKPwTrwePzM3R8WRRJQxgeSb/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "medium": "https://medium.com/@phutthisarn",
                "twitter": "https://twitter.com/earthphutthi"
            }
        },
        {
            "chainId": 101,
            "address": "9MRmp2eANQNMfyKAxwG4VknRKN2ojpwhCVYMr18yaCJ5",
            "symbol": "DONE",
            "name": "Only stop after when you are DONE",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/R9XL7FC/done.png",
            "tags": [
                "social-token",
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "5fcJtoNNCpK6t93btzxbGknZLiU6JnZJyG3xp1SgMDLr",
            "symbol": "CRONDO",
            "name": "Crondo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/danny8252/crondo/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7A18J1UHY956Jh9GgVesHttwHSFWMXB3r8oK7T7SQf4b",
            "symbol": "PURGE",
            "name": "Purging Pandas",
            "decimals": 6,
            "logoURI": "https://github.com/SaltyPickleNFT/assets/blob/main/%24PURGE%20COIN.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ZEMKFhxbSH"
            }
        },
        {
            "chainId": 101,
            "address": "7SK32s1JahGje7ETn8WejpByJzoBZfSgeMnQTE6Kzb6u",
            "symbol": "FKCWL",
            "name": "FUNKYCOINWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7SK32s1JahGje7ETn8WejpByJzoBZfSgeMnQTE6Kzb6u/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3rR5yPNbDwtQDDPijKgADbwwUgowk5YFpPnJzyoMUbCn",
            "symbol": "MXT",
            "name": "Max Verstappen Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VanWilgenburgh/token-list/main/assets/mainnet/3rR5yPNbDwtQDDPijKgADbwwUgowk5YFpPnJzyoMUbCn/max-verstappen-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7QAztn6kBapQK5CySjEGtxjg6WijtMbYRZ2ZfRKFwXHN",
            "symbol": "VRTX",
            "name": "VortexLab Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/juancano57/logos_vortex/main/logos/7QAztn6kBapQK5CySjEGtxjg6WijtMbYRZ2ZfRKFwXHN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/VortexLab1",
                "website": "https://vortexlab.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "EHZquc7EgrNGgkPMDFR2YEaEGeMCZGhe7bhyFLB9t2gY",
            "symbol": "FATS",
            "name": "FATS COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EHZquc7EgrNGgkPMDFR2YEaEGeMCZGhe7bhyFLB9t2gY/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FFAhu8UWFQzCg7kfib54hhVuxF6a5zGztNYVTcbQ43n9",
            "symbol": "EuroStableCoin",
            "name": "Blockcoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFAhu8UWFQzCg7kfib54hhVuxF6a5zGztNYVTcbQ43n9/blockPNG.png",
            "tags": [
                "stable-coin",
                "USD-coin-based",
                "USDC"
            ],
            "extensions": {
                "website": "https://blocksto.ne/blockcoin/"
            }
        },
        {
            "chainId": 101,
            "address": "2JBr8X9zABjCbh21t2ZrrWFAT58VRe8jRtaHMPcXqPvo",
            "symbol": "CARDSHARKSMINT",
            "name": "ACES Card Sharks Minting Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2JBr8X9zABjCbh21t2ZrrWFAT58VRe8jRtaHMPcXqPvo/logo.png",
            "extensions": {
                "discord": "https://discord.gg/5gehRmCNem",
                "twitter": "https://twitter.com/TheAcesNFT",
                "website": "https://www.acessharks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DizzyWYL4ht1ek8cvLMHYKj9JrgeAL2j2f1F8ZEs5umA",
            "symbol": "Dizzy",
            "name": "Dizzy Dracos WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DizzyWYL4ht1ek8cvLMHYKj9JrgeAL2j2f1F8ZEs5umA/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DizzyDracos_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "ATTF58JfFUHZktZXyV6aCTwHVCYzPuZBg2hma7J7Ao7n",
            "symbol": "ATMT",
            "name": "Atlaria Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATTF58JfFUHZktZXyV6aCTwHVCYzPuZBg2hma7J7Ao7n/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "GFTYCJzCuQXuzPoZCbVkqVtFBwDdRhQHEe2w6QA36z4Y",
            "symbol": "GOMT",
            "name": "Goofiez Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFTYCJzCuQXuzPoZCbVkqVtFBwDdRhQHEe2w6QA36z4Y/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "9xxof1eFbsHsMvrrvU2SZq7xJbjckPMsmZutLBMDnJgJ",
            "symbol": "SQT",
            "name": "Sequoia Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SequoiaSan/sequoia_crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7pvmfggcaXWrja1TYWrsanMe2MDJe1MZt45r26vhGbQ3",
            "symbol": "YOSH",
            "name": "YoshCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7pvmfggcaXWrja1TYWrsanMe2MDJe1MZt45r26vhGbQ3/logo.JPG",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Ln5Qm3kpJR5bZcu6FyNy5ik5htyzE4dvwLm9pWQUCMg",
            "symbol": "Toon",
            "name": "The Mintoons Token",
            "decimals": 8,
            "logoURI": "https://mintopia.live/tooooon.png",
            "tags": [
                "mintopia-token",
                "community-token",
                "by-the-mintoons"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheMintoons",
                "website": "http://mintopia.live/"
            }
        },
        {
            "chainId": 101,
            "address": "HZPbfecX6T6wYShH2Y4DTpCFZ3vBmZVpujYyq3g3uEpM",
            "symbol": "JEO",
            "name": "JEO Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicholhai/crypto-jeocoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "XzR7CUMqhDBzbAm4aUNvwhVCxjWGn1KEvqTp3Y8fFCD",
            "symbol": "SCAM",
            "name": "Scammer Token Beware",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/xxmafiaxxx/doobietokens/main/scam.png",
            "extensions": {
                "description": "Scammer Token Beware"
            }
        },
        {
            "chainId": 101,
            "address": "sFA2de5kRsAmCev2WAoPCXbCKEd1ZwkvJ3MxPMojw9h",
            "symbol": "RUM",
            "name": "Dope Pirates RUM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sFA2de5kRsAmCev2WAoPCXbCKEd1ZwkvJ3MxPMojw9h/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dopepirates",
                "twitter": "https://twitter.com/dopepirates",
                "website": "https://dopepirates.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2wwQpwaPjwJTfuJuPBkUZVfLJPbhsKHEbVqhVSviq7wd",
            "symbol": "$TNBzWL",
            "name": "TinyBear WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wwQpwaPjwJTfuJuPBkUZVfLJPbhsKHEbVqhVSviq7wd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.io/tinybear_nft",
                "telegram": "https://t.me/tinybear_nft",
                "twitter": "https://twitter.com/tinybear_nft",
                "website": "https://tinybearnft.net"
            }
        },
        {
            "chainId": 101,
            "address": "9pbVhTQbnM8ho5kwqr21EhWVehd1PfBt8tyBxeH2ttmz",
            "symbol": "$TNB",
            "name": "TinyBear Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9pbVhTQbnM8ho5kwqr21EhWVehd1PfBt8tyBxeH2ttmz/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.io/tinybear_nft",
                "telegram": "https://t.me/tinybear_nft",
                "twitter": "https://twitter.com/tinybear_nft",
                "website": "https://tinybearnft.net"
            }
        },
        {
            "chainId": 103,
            "address": "EHZquc7EgrNGgkPMDFR2YEaEGeMCZGhe7bhyFLB9t2gY",
            "symbol": "FATS",
            "name": "FATS COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EHZquc7EgrNGgkPMDFR2YEaEGeMCZGhe7bhyFLB9t2gY/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NovaAMdJA1MCNWG3TiY8C37ezHd8GEpLVE4mTGXjetQ",
            "symbol": "NovaMindNFT",
            "name": "NovaMindNFT",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/915400341992923246/957740284249264189/Screen.png",
            "extensions": {
                "website": "https://twitter.com/novamindnft"
            }
        },
        {
            "chainId": 101,
            "address": "GFoYxfK7AowBTtCe7m2ZzhouL9BvLHBT2JsvNd2FqQwE",
            "symbol": "GOGMT",
            "name": "Goofiez OG Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFTYCJzCuQXuzPoZCbVkqVtFBwDdRhQHEe2w6QA36z4Y/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "H19akeu1XsxLwMFHxfic47H7fpDzHQFnRqT9Y6x4Z1Fn",
            "symbol": "MT",
            "name": "Myuta Token",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/H19akeu1XsxLwMFHxfic47H7fpDzHQFnRqT9Y6x4Z1Fn/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "9THzNrZiF3XNsuuqb9T8gsCGaYnek2Lsg5YKdqMnf8TW",
            "symbol": "SS",
            "name": "Space Stone",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/9THzNrZiF3XNsuuqb9T8gsCGaYnek2Lsg5YKdqMnf8TW/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "9A1oPw1md9q3NxqriN5e3U4ofUgEj6C67PnkKPfUHh2j",
            "symbol": "$",
            "name": "DAWGZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/degendawgz/main/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DegenDawgz",
                "website": "https://www.degendawgz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HUtSa4eT4ccqPU2SMwwAawHJGQoaAvgqwb65CRA8QycZ",
            "symbol": "amUSDC",
            "name": "Accrue MAX USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HUtSa4eT4ccqPU2SMwwAawHJGQoaAvgqwb65CRA8QycZ/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/accrue_finance",
                "website": "https://accrue.finance"
            }
        },
        {
            "chainId": 101,
            "address": "77WGHdztShHUje2YTSy5peuHPMspvWzAg1WVemzC7B6g",
            "symbol": "amSOL",
            "name": "Accrue MAX SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/77WGHdztShHUje2YTSy5peuHPMspvWzAg1WVemzC7B6g/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/accrue_finance",
                "website": "https://accrue.finance"
            }
        },
        {
            "chainId": 101,
            "address": "4SLMzsWGJLhGKoYPzmfV1b89vQ99ocN3ArSafYUtfDPZ",
            "symbol": "amBTC",
            "name": "Accrue MAX BTC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SLMzsWGJLhGKoYPzmfV1b89vQ99ocN3ArSafYUtfDPZ/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/accrue_finance",
                "website": "https://accrue.finance"
            }
        },
        {
            "chainId": 101,
            "address": "HJyCHtR5Cd5w4QsFTZsGWAck5YFCJJz7myJAW8JJFEsP",
            "symbol": "NR6C",
            "name": "NightRunner Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NightRunner6/Crypto1/main/night_runner_by_cybercortex_dds71ki-350t.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6kJFKic3kCxmmYKJbQVTgy6vWxrhZjM2Ho8os4myCSe3",
            "symbol": "HSC",
            "name": "Hallelujah Sunshine Cult",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/hallelujahsunshine/assets/main/HSC%20Logo%20SML.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/groups/hallelujahsunshinecollaborative",
                "twitter": "https://twitter.com/GeneralMayhem15"
            }
        },
        {
            "chainId": 101,
            "address": "AxjUR1RYf2Aj7FThDUQgxa43z3TfXUfUeiNRJcC98dbF",
            "symbol": "BOOK",
            "name": "BibliotecaToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BuffaloChikn/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EPiVDJNJtQV5sU8GoNJmGY4uj5g447hy5ea8MvzTvkSU",
            "symbol": "LAN",
            "name": "LoveAsNFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rashkomi/Crypto/main/LAN.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "ErbCjTBkj3t5xX86VJ899j9dGojDhMcGuLMnwDMgBs4R",
            "symbol": "SYGD",
            "name": "Sygard",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ProjectSytras/King-Beta/master/SYGD_token.png",
            "tags": [
                "utility-token",
                "Commerce"
            ],
            "extensions": {
                "description": "$SYGD is the Reward Token of Alyax Inc, the first-ever web3 enabled, international loyalty program provider on Solana.",
                "discord": "https://discord.gg/alyaxinc",
                "medium": "https://medium.com/@alyax_inc",
                "twitter": "https://twitter.com/AlyaxV3rse_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "CGNT66vj1giPAMy61Ed4g4dq33aSK8xKwzfTBL7WrGov",
            "symbol": "CGNT",
            "name": "Cogent Crypto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CGNT66vj1giPAMy61Ed4g4dq33aSK8xKwzfTBL7WrGov/logo.png"
        },
        {
            "chainId": 101,
            "address": "2bDQkA2wUC1n8Jihccrwf7w9ZvACp5cntvRzjkdwXF9Y",
            "symbol": "TFLO",
            "name": "Time Flow Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SLMzsWGJLhGKoYPzmfV1b89vQ99ocN3ArSafYUtfDPZ/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/GangsterZodiacs",
                "website": "https://gangsterzodiacs.io"
            }
        },
        {
            "chainId": 101,
            "address": "mongopjRpUgnQQpQFiasgFLyo69YXUwFcw3hyqaN8RL",
            "symbol": "MONGO",
            "name": "Mongo",
            "decimals": 0,
            "logoURI": "https://mongomons.com/logo-MONGO.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mongomons",
                "twitter": "https://twitter.com/Mongomonsol",
                "website": "https://mongomons.com"
            }
        },
        {
            "chainId": 103,
            "address": "4CoWi6ptx6UeLTCq8qsbEP7eCmijE8ef1dKuopv993nz",
            "symbol": "FGDT",
            "name": "FurrSols Game Demo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4CoWi6ptx6UeLTCq8qsbEP7eCmijE8ef1dKuopv993nz/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://furrsols.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8E6X9ng5ogUk6SWZUQFoPtZb4jHzTJvqZHxFnPA6UWHj",
            "symbol": "MTM",
            "name": "MetaMoney",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8E6X9ng5ogUk6SWZUQFoPtZb4jHzTJvqZHxFnPA6UWHj/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HxPoEHMt1vKeqjKCePcqTj6yYgn6Xqq1fKTY3Pjx4YrX",
            "symbol": "ZAP",
            "name": "ZAP Token",
            "decimals": 8,
            "logoURI": "https://www.zap.org/static/zapSplash.svg",
            "tags": [
                "whitelist-token",
                "utility-token",
                "community-token",
                "nft"
            ],
            "extensions": {
                "coingeckoId": "zap",
                "coinmarketcap": "https://coinmarketcap.com/currencies/zap",
                "description": "Zap is building critical infrastructure to power the next wave of decentralized applications.",
                "discord": "https://discord.gg/pvHzemX",
                "facebook": "https://www.facebook.com/ZapProtocol",
                "instagram": "https://www.instagram.com/zapprotocol",
                "linkedin": "https://www.linkedin.com/company/zapprotocol",
                "medium": "https://medium.com/the-zap-project",
                "reddit": "https://www.reddit.com/r/ZapProtocol",
                "telegram": "https://t.me/ZapOracles",
                "twitter": "https://twitter.com/ZapProtocol",
                "website": "https://zap.org",
                "whitepaper": "https://app.zap.org/docs/overview"
            }
        },
        {
            "chainId": 101,
            "address": "737U8hYA69cgaN1MudTz4oH2wAPTwvBcQu8PezW2h4Bn",
            "symbol": "USSTT",
            "name": "Unicord Staking System Test Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/737U8hYA69cgaN1MudTz4oH2wAPTwvBcQu8PezW2h4Bn/logo.png"
        },
        {
            "chainId": 101,
            "address": "GMeqxdPtZvwj9rmrCLqdB2MAeJXjmRPnRrgpDLpVqKCh",
            "symbol": "INFOMO",
            "name": "Infomo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GMeqxdPtZvwj9rmrCLqdB2MAeJXjmRPnRrgpDLpVqKCh/logo.png"
        },
        {
            "chainId": 101,
            "address": "ANco2d62WU8D6PqkPh8NRTQitHmACRhbvRsCVud3aqbE",
            "symbol": "ANCO",
            "name": "Ancora WL Pass Official",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANco2d62WU8D6PqkPh8NRTQitHmACRhbvRsCVud3aqbE/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AncoraPay"
            }
        },
        {
            "chainId": 103,
            "address": "9h5PJj8zMDXLL31xzwazM86tnmQe3j1SmnQ4X3D13Y8K",
            "symbol": "ENCS",
            "name": "Encore Smile",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fungtoken/EncoreSmile/main/SmileUpv3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "5vPqJAtSeRqbu7ZUZZT4Q1uksDuVjDc8URe75wga4RYk",
            "symbol": "TKE",
            "name": "TOKELIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tokealin/tokelin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "777cLE28EKhWPSVWmnRw55QJPkagzK5vEaCE3KFhQcJf",
            "symbol": "CLZ",
            "name": "CELESTIALZ-WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/777cLE28EKhWPSVWmnRw55QJPkagzK5vEaCE3KFhQcJf/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CELESTIALZ777",
                "website": "https://www.celestialznft.io"
            }
        },
        {
            "chainId": 101,
            "address": "7G3ius3GViuopZzyVAvoF2DcHyDaG7hpCjDnshNuQbhF",
            "symbol": "RIRI",
            "name": "RippleRick",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7G3ius3GViuopZzyVAvoF2DcHyDaG7hpCjDnshNuQbhF/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "general-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/itsripplerick",
                "website": "https://ripplerick.com"
            }
        },
        {
            "chainId": 103,
            "address": "FkuK7uj9n11wdwNiozMrgFfwjuTWFbZnhQbzpcKyhQsg",
            "symbol": "LONGAN",
            "name": "Longan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fungtoken/Longan/main/Longan.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ACgEL4C2iHr9pafyeJfbvtq7n6GhPgk8ybnrkQ3wY5XK",
            "symbol": "CINC",
            "name": "Coin Invest Capital",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/tychcio1/token-list/main/assets/mainnet/ACgEL4C2iHr9pafyeJfbvtq7n6GhPgk8ybnrkQ3wY5XK/logo.png"
        },
        {
            "chainId": 101,
            "address": "G6MWy5GqsrdwMNQE4ha3hPHvqT47wCqAV4pmzycZzuFM",
            "symbol": "DTKN",
            "name": "DimensionToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DimensionCoin/logoDimensionCoin/main/dimensionlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HPjF6kgyyfRpPkSaC9NNkKPwTrwePzM3R8WRRJQxgeSb",
            "symbol": "CGU",
            "name": "CGU Test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HPjF6kgyyfRpPkSaC9NNkKPwTrwePzM3R8WRRJQxgeSb/logo.png",
            "tags": [
                "meme-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "medium": "https://medium.com/@phutthisarn",
                "twitter": "https://twitter.com/earthphutthi"
            }
        },
        {
            "chainId": 101,
            "address": "skuLLY6NHFrjL3oGpWMrrwjCfWMSvwp6AD7resMF9m7",
            "symbol": "SUMMON",
            "name": "Skullbots",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Neurologist/assets/main/summon.png"
        },
        {
            "chainId": 101,
            "address": "5chyGzuP62SmN5q6M22TojNdDxCjxeo1gnbpbc7RGbTC",
            "symbol": "$TFLO",
            "name": "Time Flow Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GZDragonHead/GZ/main/IMG_2795.PNG",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GangsterZodiacs",
                "website": "https://gangsterzodiacs.io"
            }
        },
        {
            "chainId": 101,
            "address": "6hWf9DXxUu9cjSw63mnyrkrpXinxiQ1BogFRPskpoNNh",
            "symbol": "SLYA",
            "name": "Solely Authentic",
            "decimals": 0,
            "logoURI": "https://github.dev/sa22198/token-list/blob/74157b1b4fa26a3def590e5e51e8e5a30fcad89f/assets/mainnet/6hWf9DXxUu9cjSw63mnyrkrpXinxiQ1BogFRPskpoNNh/solelya2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HqGeQN2dyzBAvgNnWLRi1mUpCzr5mMgtXgiGvtPGfDt6",
            "symbol": "KRF",
            "name": "KryptoForest",
            "decimals": 0,
            "logoURI": "https://github.com/mingle1989/crypto/blob/master/KryptoForestToken_OG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9ezfMjPwsPfRtRi41PER8xFpZDQCm2ccTj488uqGguT6",
            "symbol": "AMETA",
            "name": "Alphaverse Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ezfMjPwsPfRtRi41PER8xFpZDQCm2ccTj488uqGguT6/logo.png"
        },
        {
            "chainId": 103,
            "address": "aPeM74voCnKsRHW2w3WMaWCKhubR96f81cZpZuBBG1Q",
            "symbol": "TLAN",
            "name": "Test Lan Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/c41c6e28c9973bd4a9c125faf90b18dd6a036285/assets/mainnet/aPeM74voCnKsRHW2w3WMaWCKhubR96f81cZpZuBBG1Q/tlan.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/meebmaster_nft"
            }
        },
        {
            "chainId": 101,
            "address": "2ximgbUJ2kZZUUwFietPJuMdSHink3RHREgwVZchViiP",
            "symbol": "c2o",
            "name": "c2o coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/c2ocoin/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://c2ocoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "hAs5nGhLtGTbXGHYGx6bNWaXR59hpQTD1Lh1cqfszCx",
            "symbol": "OBIC",
            "name": "off_bit_coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bndsknzlr/off_bit_coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MGPahMxthDGuZ6wJEPh6ZjGHMnEFwnT1RyrBaCVxGVe",
            "symbol": "MGSS",
            "name": "Magic Soldiers Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MGPahMxthDGuZ6wJEPh6ZjGHMnEFwnT1RyrBaCVxGVe/Mgslogo.png"
        },
        {
            "chainId": 101,
            "address": "GnYmoLzwY9RfpBs5FnWnXJ1ZL2ymbESVj9ihrpbYch9b",
            "symbol": "TTRYB",
            "name": "Test Tryb",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Lagrange-fi/Test-TRYB-logo/main/TTRYB.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/lagrange-fi",
                "github": "https://github.com/Lagrange-fi",
                "twitter": "https://twitter.com/Lagrange_fi",
                "website": "https://lagrange.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "6qmfx7uBiGhv2yaX2LQpP7kjfqe6hViRjEurDRUeZNCZ",
            "symbol": "CRKD",
            "name": "ChrisRocked",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AF7358/ChrisRocked/main/chrisrocked.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J9Rmr7xwtWwKE6cGQeEPejYmydjV8xaeXLrdeDeUjrkm",
            "symbol": "ADPP",
            "name": "Dan",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DanteAlucard98/crypto/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JAbJrmkqV4NnMDcQ1k8dmLfz4n8UWteF8gp5QBT2soeZ",
            "symbol": "CGUM",
            "name": "CGU TEST M",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAbJrmkqV4NnMDcQ1k8dmLfz4n8UWteF8gp5QBT2soeZ/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "medium": "https://medium.com/@phutthisarn"
            }
        },
        {
            "chainId": 101,
            "address": "CAFTP2Yof8bJuwSScigqnZaLQKiBzECgJPxvEDzfivzw",
            "symbol": "CAF",
            "name": "Caffeine",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAFTP2Yof8bJuwSScigqnZaLQKiBzECgJPxvEDzfivzw/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://www.crema.finance/",
                "telegram": "https://t.me/cremafinance",
                "twitter": "https://twitter.com/Crema_Finance",
                "website": "https://discord.gg/cremafinance"
            }
        },
        {
            "chainId": 101,
            "address": "HeTCNpw9jPsrS3Grn1zEruSDWpmcvxMjQmwzcojektpB",
            "symbol": "MORZ",
            "name": "Mornz koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mornarajdeep1991/mornacrypto/main/djmorna.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F3mqxshUtaQUF6M5L7JRYhbqaRYkBwbCKUmKE54MhMA1",
            "symbol": "CTC",
            "name": "Canine Token Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F3mqxshUtaQUF6M5L7JRYhbqaRYkBwbCKUmKE54MhMA1/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/42BmtCUqan",
                "twitter": "https://twitter.com/canidcanines",
                "website": "https://www.canidcanines.io/"
            }
        },
        {
            "chainId": 101,
            "address": "A1H85Aco9XX6ZeeLMdjwF7rosvNThj3dm4K9kRtb77X5",
            "symbol": "SINS",
            "name": "Sins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LouisDeSimone/roxtarrSins/main/roxtarrSins.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gf43qoikf7d2kGrSX7s2iRbGn2SrTFAQoD3uattUEAyS",
            "symbol": "SPRW",
            "name": "The Jack Sparrow Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nicholhai/thejacksparrow/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "4xuicVbNHue2okAYeNphzdGWbyzF4stM39SLmNoD7Fum",
            "symbol": "ALI",
            "name": "Shahid Ali Expert Dev",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4xuicVbNHue2okAYeNphzdGWbyzF4stM39SLmNoD7Fum/logo.png"
        },
        {
            "chainId": 101,
            "address": "GoDjmr61qsywyx63xRWQFZ3VKe1mkVy9w9k1Nzu6wFVH",
            "symbol": "GojiraWL",
            "name": "Gojira WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoDjmr61qsywyx63xRWQFZ3VKe1mkVy9w9k1Nzu6wFVH/logo.png"
        },
        {
            "chainId": 103,
            "address": "MatK11vFCW34rHw1PoryHSUiyo5WZVvzG24M9kwAp2p",
            "symbol": "MATD",
            "name": "Mat Token Devnet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MatK11vFCW34rHw1PoryHSUiyo5WZVvzG24M9kwAp2p/logo.svg"
        },
        {
            "chainId": 101,
            "address": "idoLztG5ZGMVEjjoQWjeSu2eVkVsp3YnrFZKf7dNi4j",
            "symbol": "IDOLZ",
            "name": "IDOLZ Token",
            "decimals": 9,
            "logoURI": "https://arweave.net/mdvmPYEgiX_uPGRtiIZA3HrpilDK5JfW2bXkDBRuzVM?ext=png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "description": "$IDOLZ is an essential part of the Idolz Ecosystem. It will be used as the primary internal currency for minting, trading, and upgrading Idolz NFTs",
                "discord": "https://discord.com/invite/cryptoidolz",
                "twitter": "https://twitter.com/CryptoIdolzNFT",
                "website": "https://cryptoidolz.pro/"
            }
        },
        {
            "chainId": 101,
            "address": "BREADk5Sf1YvgDEkE4BGTJu3zNwhqEaKsYUNpD8d8y3W",
            "symbol": "BDZ",
            "name": "Breadz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BREADk5Sf1YvgDEkE4BGTJu3zNwhqEaKsYUNpD8d8y3W/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thugbirdz",
                "website": "https://www.thugbirdz.com"
            }
        },
        {
            "chainId": 101,
            "address": "7X3ZoBf3CcQEFmCChypPXva1sGRJYwMC1qvVYYnoeN33",
            "symbol": "GUNIT",
            "name": "50 Cent",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7X3ZoBf3CcQEFmCChypPXva1sGRJYwMC1qvVYYnoeN33/50cent.png"
        },
        {
            "chainId": 101,
            "address": "CCPSqDhvJtJpovDRfKdWsVLdo5aYoJumV3f38y2CXhCz",
            "symbol": "SCMM",
            "name": "Another Scammer Token Beware",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/xxmafiaxxx/doobietokens/main/scam2.png",
            "extensions": {
                "description": "Another Scammer Token Beware"
            }
        },
        {
            "chainId": 101,
            "address": "GEhp4L15gp82X3dWfLkQdDwULaPuYr83EJL58Wh1P2Mn",
            "symbol": "Consul",
            "name": "Meta Fox Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEhp4L15gp82X3dWfLkQdDwULaPuYr83EJL58Wh1P2Mn/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6jnEKuc8bM",
                "telegram": "https://t.me/MetaFoxCoin",
                "twitter": "https://twitter.com/MetaFoxCoin",
                "website": "https://foxyplay.online/"
            }
        },
        {
            "chainId": 103,
            "address": "3aQgE11bFh1K24VzHRZrN8M5FjyVJm48guKThUsH2396",
            "symbol": "WL",
            "name": "Solamonsters WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aQgE11bFh1K24VzHRZrN8M5FjyVJm48guKThUsH2396/wl-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6ADhm438NM",
                "twitter": "https://twitter.com/SolamonstersNFT",
                "website": "https://www.solamonsters.com"
            }
        },
        {
            "chainId": 101,
            "address": "3HRgLcJh26k5FHtm921859v9Bg8KcRaJ2199ZPFo1mfu",
            "symbol": "SHARDS",
            "name": "SHARDS",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/3HRgLcJh26k5FHtm921859v9Bg8KcRaJ2199ZPFo1mfu/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "3SrHYJCE3Zxdhq5yZtvvPmU7NXbTkhmjKTiuV5VRJADQ",
            "symbol": "ZIRK",
            "name": "Zirkus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3SrHYJCE3Zxdhq5yZtvvPmU7NXbTkhmjKTiuV5VRJADQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "Enp3qfJR3k7ELvTCjFUhVrBLC6179DancMJ3nvUBEsiY",
            "symbol": "Bravo",
            "name": "Bravo Ambulance Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/paddysc/crypto1/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PLANEQfzaxbUy9KWm3FerN1Zu26EfYgCByonaKq926i",
            "symbol": "Planetarians",
            "name": "The Planetarians WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PLANEQfzaxbUy9KWm3FerN1Zu26EfYgCByonaKq926i/logo.png",
            "tags": [
                "Mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8v2Sn7v5jLpm3jdRkpLVCNbLKm9xdqapr6jwv3XkVBW4",
            "symbol": "LATS",
            "name": "LATS",
            "decimals": 0,
            "logoURI": "https://github.com/MrWeedSeed/LATS/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CBM1gccVCDo1PVXTDd8sBfeLB9QXmNe3CiKZUWfoJs8b",
            "symbol": "LFG",
            "name": "Let's Fucking Go",
            "decimals": 0,
            "logoURI": "https://github.com/qudo-code/assets/blob/master/solana/tokens/LFG/LFG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/_qudo"
            }
        },
        {
            "chainId": 101,
            "address": "S8v4cS7dnKzV6LYvzFPuuiWQMM4KSz7URuGYWMGXyTG",
            "symbol": "VERSE",
            "name": "Verse Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/S8v4cS7dnKzV6LYvzFPuuiWQMM4KSz7URuGYWMGXyTG/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7AR7gh4b",
                "twitter": "https://twitter.com/solarity_VR",
                "website": "https://www.solarity.xyz"
            }
        },
        {
            "chainId": 103,
            "address": "rCLDb3inpxxwcXjKe2WbC25J1WrW9TFjxX85hXqKpgv",
            "symbol": "WL",
            "name": "Solamonsters OG Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rCLDb3inpxxwcXjKe2WbC25J1WrW9TFjxX85hXqKpgv/og-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6ADhm438NM",
                "twitter": "https://twitter.com/SolamonstersNFT",
                "website": "https://www.solamonsters.com"
            }
        },
        {
            "chainId": 101,
            "address": "9qRarZBguWMeD2QMZVxEyVYDWRFKJqbPbyDuJNCriojb",
            "symbol": "SWT",
            "name": "Super Walking Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Leo02607/assetss/main/super_Walking_Token.png",
            "tags": [
                "Gaming",
                "Sport"
            ]
        },
        {
            "chainId": 101,
            "address": "BxqzqEaUbQ4X9e5zgWAm9kPGxffLhKvwyeiPGmshv5gy",
            "symbol": "RENT",
            "name": "Rent",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rentfreejoe/rent/main/rent.png",
            "tags": [
                "general-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Rent_Free_Joe",
                "website": "https://twitter.com/Rent_Free_Joe"
            }
        },
        {
            "chainId": 101,
            "address": "4KwJBSGtpoxGY8pUUEsjpModoYgRGgaqNEZHrd11DzsV",
            "symbol": "NINJAS",
            "name": "Blue Chips Ninjas Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4KwJBSGtpoxGY8pUUEsjpModoYgRGgaqNEZHrd11DzsV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/bc_ninjas/"
            }
        },
        {
            "chainId": 101,
            "address": "e95X3rA68GRqPvDpw9fqeTvMQ1i8toj2sV2iqmRfsbd",
            "symbol": "SEU",
            "name": "Soleur",
            "decimals": 4,
            "logoURI": "https://github.com/Mosorior/soleur/blob/main/Soleur%20Coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/_qudo"
            }
        },
        {
            "chainId": 101,
            "address": "6zNzaKbt6ZkpPsfkWgGFjBoGLJCgm2HAqaytwza1pV6S",
            "symbol": "OSU",
            "name": "OSU Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/livebyv/token-art/main/osu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NftSenshi"
            }
        },
        {
            "chainId": 101,
            "address": "DgT6BQqmmzaaHo7KjoxdswpoC6CoTBNcyD9q3jywEa8w",
            "symbol": "BALANCE",
            "name": "Balance Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/livebyv/token-art/main/balance/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NftSenshi"
            }
        },
        {
            "chainId": 101,
            "address": "neoneXWLNouaSKk2zna7p54qbmsuVUtdmMGzR5MuPd9",
            "symbol": "NEON",
            "name": "Whiteneon Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/whiteneon/token-list/main/assets/mainnet/neoneXWLNouaSKk2zna7p54qbmsuVUtdmMGzR5MuPd9/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://solana.whiteneon.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DiCwzrqQwvgT4bHLKGuNU9XhSY5gFfhKhN6M9UsWZvvo",
            "symbol": "DOGEMFERS",
            "name": "SOL DOGEMFERS WL",
            "decimals": 0,
            "logoURI": "https://dogemfer-ipfs.s3.us-east-1.amazonaws.com/images/1001.png",
            "tags": [
                "community-token",
                "sol-dogemfers",
                "dogemfers"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DogeMfers",
                "website": "https://sol.dogemfer.com"
            }
        },
        {
            "chainId": 101,
            "address": "8aNThehSFoomj3STWb3nfMwTapJyy5WB768Mj6LzWXvz",
            "symbol": "SOLCITYFOU",
            "name": "SolCity Poker Club Founders",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8aNThehSFoomj3STWb3nfMwTapJyy5WB768Mj6LzWXvz/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HRXXo2ufmbZugAnX4V8jyrArupxxYremLSxcv8ZGn4Jv",
            "symbol": "BITCORN",
            "name": "BITCORN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRXXo2ufmbZugAnX4V8jyrArupxxYremLSxcv8ZGn4Jv/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/GJrmqyQKaW",
                "twitter": "https://twitter.com/BitcornStudios",
                "website": "https://bitcornfarms.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BA7khZ2ai6zR8kh3eH996HYk2BkLMo2hwCe7p5nps1qW",
            "symbol": "ZUKI",
            "name": "Zuki",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SnowballedNoot/SPLAkatzuki/main/tokenlogo520.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8UCRqj24VEtwsqCm4k2UHsp8STy2yUqRg6xD4p6g55p6",
            "symbol": "SOLCITYPH3",
            "name": "SolCity Poker Club Phase 3",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UCRqj24VEtwsqCm4k2UHsp8STy2yUqRg6xD4p6g55p6/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "368zP1r8xHD2Vi8VmXcCdVmMTqhAoHj2AmrUtSgZxdA3",
            "symbol": "DCWT",
            "name": "Doodle Collective WL Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/368zP1r8xHD2Vi8VmXcCdVmMTqhAoHj2AmrUtSgZxdA3/DCWT.PNG",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/suuyrnvnk5",
                "twitter": "https://twitter.com/DoodlCollective"
            }
        },
        {
            "chainId": 101,
            "address": "PEso2e1Lq2RVb8XEmQrU9V6oq5HruUXNLS6b81HBtuC",
            "symbol": "$PESO",
            "name": "Peso",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PEso2e1Lq2RVb8XEmQrU9V6oq5HruUXNLS6b81HBtuC/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/kingpin_cartel"
            }
        },
        {
            "chainId": 101,
            "address": "4aji8e3sw122mvosyjxmGSQKNPFh6ixKfHj8nqfLWydx",
            "symbol": "TYC",
            "name": "Tai Yi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4aji8e3sw122mvosyjxmGSQKNPFh6ixKfHj8nqfLWydx/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/hiryu83",
                "website": "https://www.unrealworld.online"
            }
        },
        {
            "chainId": 101,
            "address": "3rkCq2ZAxoDGa3KWGebeiEMN92H5AV9HBLC9eVKDoPv8",
            "symbol": "$EGG",
            "name": "EGG",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/IamDtosh/egg/main/egg.png",
            "tags": [
                "defi",
                "general",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "3ZNEbrWGST4ApvaQjTaSZJJ2c6Exm2bkba1gVNnotgsv",
            "symbol": "YASS",
            "name": "YoloSwag Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrpgmr67/YoloSwag/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/yoloswagcoin"
            }
        },
        {
            "chainId": 101,
            "address": "6fStzwUHEMne7wQyhfwDnZT1YquqkrF8auEscFm6MBSB",
            "symbol": "TBTH",
            "name": "TiteButHole",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jmfp/TiteButHole/main/logosmall.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DUCKexs9TX299vBuxphiZNYPisw1A9kntH8WRi5sy8LV",
            "symbol": "DUCK",
            "name": "Duck Exchange Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUCKexs9TX299vBuxphiZNYPisw1A9kntH8WRi5sy8LV/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/2ySvY6Vrv7",
                "twitter": "https://twitter.com/duck_dex",
                "website": "https://duckdex.github.io"
            }
        },
        {
            "chainId": 101,
            "address": "CLYDr7ZYLL178ibxCuk1ULJGAFtA6Ai3JahcRBZ6fNq",
            "symbol": "CLOUD",
            "name": "Land Cloud Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLYDr7ZYLL178ibxCuk1ULJGAFtA6Ai3JahcRBZ6fNq/logo.svg",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4UhatHUo1haGrFTNshKeQ6vvbx1uDapNGibAUyrxLpJ4",
            "symbol": "Consul",
            "name": "Meta Consul Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/warfare644/metafoxcoin/main/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6jnEKuc8bM",
                "telegram": "https://t.me/MetaFoxCoin",
                "twitter": "https://twitter.com/MetaFoxCoin",
                "website": "https://foxyplay.online/"
            }
        },
        {
            "chainId": 101,
            "address": "5dV35yxrvhES8dkjw1N5JoH2uFa9rGfNtBCftC1TVAiS",
            "symbol": "YAKKL",
            "name": "YAKKL Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/yakkl/solana/main/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/KrcpWmu3WB",
                "twitter": "https://twitter.com/yakklcrypto",
                "website": "https://yakkl.com"
            }
        },
        {
            "chainId": 101,
            "address": "komRFW7sjDx78C7DPJM4qpdr5aSW3jiGa1b3GvaDXoQ",
            "symbol": "KOMR",
            "name": "Komradz NFT Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/komRFW7sjDx78C7DPJM4qpdr5aSW3jiGa1b3GvaDXoQ/logo.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Komradznft",
                "website": "https://www.komradz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GrBcjxq2uMGpzo5JWUnRfSWkFkL4VoE3pCi4Zq1NiPih",
            "symbol": "$PMWL",
            "name": "Pumas Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IamDtosh/pumas/main/pumas.png",
            "tags": [
                "defi",
                "general",
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "Bhp7DkTfy2TYZ2XXtF19puZSg4KZu3GQNR57wpEPnfpR",
            "symbol": "JUSOLv2",
            "name": "JUSOL",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bhp7DkTfy2TYZ2XXtF19puZSg4KZu3GQNR57wpEPnfpR/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "Juiced SOL Carton Token",
                "discord": "https://discord.gg/ZXBRGXccR2",
                "instagram": "https://www.instagram.com/juicedfi",
                "linkedin": "https://www.linkedin.com/company/juicedfi",
                "medium": "https://www.medium.com/@juicedfinance",
                "twitter": "https://twitter.com/JuicedProtocol",
                "website": "https://www.juiced.fi",
                "whitepaper": "https://juiced.fi/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "DcJTzgLtV2Y2b6HQFTkSv3Wsck5uqXCGrAKVa4JiV5zZ",
            "symbol": "MIKO",
            "name": "Mikoverse Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcJTzgLtV2Y2b6HQFTkSv3Wsck5uqXCGrAKVa4JiV5zZ/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mikoverse",
                "medium": "https://medium.com/@MikoVerse",
                "twitter": "https://twitter.com/Mikoverse_NFT",
                "website": "https://mikoverse.io/"
            }
        },
        {
            "chainId": 101,
            "address": "86icjpF6jEwVfEKRpuM6VWE6Ng5w6WG5FWifm6gSu1Gk",
            "symbol": "FWLT",
            "name": "Faviont Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faviont/marketplace/main/logo-wl.png",
            "tags": [
                "whitelist",
                "utility-token"
            ],
            "extensions": {
                "description": "Each token gives you access to buy one NFT on https://faviont.io/",
                "discord": "https://discord.gg/T6mVYKeVYc",
                "twitter": "https://twitter.com/FaviontOfficial",
                "website": "https://faviont.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SMKEg822pUYrgDFRrk4LuK2gLjigYgCjbiGnKBEhzhs",
            "symbol": "SMOKE",
            "name": "SMOKE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SMKEg822pUYrgDFRrk4LuK2gLjigYgCjbiGnKBEhzhs/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/StonedShibaClub",
                "website": "https://www.stonedshibaclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "Czy7tuoHw2hgtNio2qDrz4Un5b1DZix4UaMmV7PFs9ZB",
            "symbol": "UVZH",
            "name": "UVAZUHA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/letstrythisshit/crypto/main/Untitled.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "UVAZUHOS TOKENAS",
                "website": "https://uvazuhatoken.000webhostapp.com"
            }
        },
        {
            "chainId": 101,
            "address": "sMEANebFMnd9uTYpyntGzBmTmzEukRFwCjEcnXT2E8z",
            "symbol": "sMEAN",
            "name": "Staked MEAN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sMEANebFMnd9uTYpyntGzBmTmzEukRFwCjEcnXT2E8z/logo.svg",
            "tags": [
                "stake"
            ],
            "extensions": {
                "discord": "https://discord.meanfi.com/",
                "medium": "https://meandao.medium.com",
                "twitter": "https://twitter.com/meanfinance",
                "website": "https://www.meanfi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2cZv8HrgcWSvC6n1uEiS48cEQGb1d3fiowP2rpa4wBL9",
            "symbol": "ACF",
            "name": "Alien Chicken Farm",
            "decimals": 2,
            "logoURI": "https://shop.alienchickenfarm.com/logos/acf-token.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/playacf"
            }
        },
        {
            "chainId": 101,
            "address": "3LDAW7enNUZ4DjE1jCi1cDpXvXLrJ1rPiECPbcHpMgG2",
            "symbol": "FEED",
            "name": "FEED Token on Alien Chicken Farm",
            "decimals": 2,
            "logoURI": "https://shop.alienchickenfarm.com/logos/feed-token.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/playacf"
            }
        },
        {
            "chainId": 101,
            "address": "FB2duy897mbbMEJy1n4Xd1DcWWJqKVyMnRXEhv6zpzWD",
            "symbol": "dkto",
            "name": "Dkenyang Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brokay02/dkenyangtoken/main/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "8e6zTC5gppWaWcb2UyEW8BXzLwCMTuBHFKyN1JhaXmvj",
            "symbol": "DPC",
            "name": "DP Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HKmus/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToMWZWJ8i1DMat5e7RFsdSr676RoMpvR3JLD62kXaxn",
            "symbol": "MWWL",
            "name": "MetaWatchies Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToMWZWJ8i1DMat5e7RFsdSr676RoMpvR3JLD62kXaxn/logo.png"
        },
        {
            "chainId": 101,
            "address": "G8TzWQCpuUcmbxcPAuRH7L6kE4JWyNYoQzgud55sSo3N",
            "symbol": "TOWOOD",
            "name": "TownWood",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G8TzWQCpuUcmbxcPAuRH7L6kE4JWyNYoQzgud55sSo3N/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://solanatown.com"
            }
        },
        {
            "chainId": 101,
            "address": "SSCdRrLDAtfRfqmxK38osBcCv8QHJiZok9zYhUe4tXx",
            "symbol": "SSC",
            "name": "Stoned Shiba Club Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img924/2938/6XDlIB.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/enigmaventures",
                "website": "https://www.stonedshibaclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3coLAbtoMYXwhXnHbbYqsuwNGBxcDQMYwHYqrWXGdRfw",
            "symbol": "PILL",
            "name": "Pill Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3coLAbtoMYXwhXnHbbYqsuwNGBxcDQMYwHYqrWXGdRfw/logo.png",
            "tags": [
                "pill-token",
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Fbw9wBafzQ",
                "twitter": "https://twitter.com/TheFrameboy",
                "website": "https://www.theframeboy.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3dSveezVS2YpDEfbinyk8iCVej88gjk1Ycdv2jZWGgoT",
            "symbol": "$CHIQ",
            "name": "Honkuga Apes",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/IamDtosh/banana/main/banana%20token.png",
            "tags": [
                "nft",
                "defi"
            ]
        },
        {
            "chainId": 101,
            "address": "AnGcQt5EzPjWUXjWmscS4So6JQkeMCYUbmDHNhCHx72r",
            "symbol": "PIGS",
            "name": "Kirei Pigs OG WL Pass",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AnGcQt5EzPjWUXjWmscS4So6JQkeMCYUbmDHNhCHx72r/logo.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KireiFarm"
            }
        },
        {
            "chainId": 101,
            "address": "GQYaznpvPRfPDtvNq1tnh1UvugenV5PgLJaq2Y5yRvAk",
            "symbol": "WCS",
            "name": "Walker Southern Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/walkersouthern/CryptoCoin/main/Walker%20Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Deadq2dD9TNw6mGCexVRf9WneKEdu1STWhViccC3rwLo",
            "symbol": "Dead",
            "name": "Dead Rejects WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Deadq2dD9TNw6mGCexVRf9WneKEdu1STWhViccC3rwLo/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeadRejcts"
            }
        },
        {
            "chainId": 101,
            "address": "46KLEwWLu6zXjqyG845XKLCQPbTDZxXbUQc28wPAQSMh",
            "symbol": "MFCT",
            "name": "MetaFliesClub",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ezrelativ/crypto/main/logo.jpg",
            "tags": [
                "social-token",
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/49UK5529v2",
                "twitter": "https://twitter.com/metafliesclub",
                "website": "https://www.metafliesclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FjevNEc8sf1HTZx2VkSc34xGedfnRZtHymf5irzj1f33",
            "symbol": "BOOT",
            "name": "KBooty",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/njunplugd/crypto/main/kbooty.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LoL9h4KVh2A9RxuFR9NAc2dPhKnKCJnhpn9mvPTv4v9",
            "symbol": "BAGZ",
            "name": "DEGEN BAGZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LoL9h4KVh2A9RxuFR9NAc2dPhKnKCJnhpn9mvPTv4v9/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DahLysVxXFtUcn7TjXgQLe2VZyhtpawVD9AN63f1gjVW",
            "symbol": "DAHLYS",
            "name": "Dahlys Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DahLysVxXFtUcn7TjXgQLe2VZyhtpawVD9AN63f1gjVW/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dahlysart",
                "website": "https://www.dahlys.art"
            }
        },
        {
            "chainId": 101,
            "address": "6qkfb2MhrA9nfk1iiosNh5ysgAhF8eDP8b3HCpt4LPXZ",
            "symbol": "THANKSANDI",
            "name": "LOST THANKS ANDI COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6qkfb2MhrA9nfk1iiosNh5ysgAhF8eDP8b3HCpt4LPXZ/logo.png",
            "tags": [
                "secondary-toxicboys",
                "toxic"
            ]
        },
        {
            "chainId": 101,
            "address": "9tH3azxj7QxjtKnE9Qepgg7UmNLR56VLXJg8F23F1Grs",
            "symbol": "THANKSANDI2",
            "name": "LOST BUTT COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9tH3azxj7QxjtKnE9Qepgg7UmNLR56VLXJg8F23F1Grs/logo.png",
            "tags": [
                "supertoxicbuyer",
                "t0t0rial"
            ]
        },
        {
            "chainId": 101,
            "address": "StoNBpvhd4Cg4Bz4DMF22goRRHAP6B692WPNeJu9zMy",
            "symbol": "SHIB",
            "name": "Stoned Shiba WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StoNBpvhd4Cg4Bz4DMF22goRRHAP6B692WPNeJu9zMy/logo.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "twitter": "https://twitter.com/StonedShibaClub",
                "website": "https://www.stonedshibaclub.com/"
            }
        },
        {
            "chainId": 102,
            "address": "EU4pwnKynnuheNui1NgAFp2Fc8QKARPf8RnqjK9GT4e1",
            "symbol": "ROST",
            "name": "RossTest",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/d4b7c271eaace6696c88dd2f06bdbb873fe33ff9/assets/mainnet/EU4pwnKynnuheNui1NgAFp2Fc8QKARPf8RnqjK9GT4e1/logo.png",
            "tags": [
                "stable-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "EJnutrcHWQazTLb4spC6Uw5zSd5c3RMJg1a3KezKU984",
            "symbol": "LIDNC",
            "name": "LEADIDNC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5YtGSyQ7YxFgDc9oj4SpZGTjoS5p5Fubkg8sMauQqXoS",
            "symbol": "PULP",
            "name": "PULP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5YtGSyQ7YxFgDc9oj4SpZGTjoS5p5Fubkg8sMauQqXoS/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "Juiced PULP Token",
                "discord": "https://discord.gg/ZXBRGXccR2",
                "instagram": "https://www.instagram.com/juicedfi",
                "linkedin": "https://www.linkedin.com/company/juicedfi",
                "medium": "https://www.medium.com/@juicedfinance",
                "twitter": "https://twitter.com/JuicedProtocol",
                "website": "https://www.juiced.fi",
                "whitepaper": "https://juiced.fi/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "HFFMj1sKtYz2bKssp9ZYx56azCFcJRAyzzgeYEpUcFg7",
            "symbol": "cYa",
            "name": "chyNyca",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/teamb17/chynyca/main/logoChyNyca.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5eUJjbhscHyr53jSBgar84BnLHq4QxchSbm1ugnpfsxq",
            "symbol": "JUBTCv2",
            "name": "JUBTC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5eUJjbhscHyr53jSBgar84BnLHq4QxchSbm1ugnpfsxq/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "Juiced BTC Carton Token",
                "discord": "https://discord.gg/ZXBRGXccR2",
                "instagram": "https://www.instagram.com/juicedfi",
                "linkedin": "https://www.linkedin.com/company/juicedfi",
                "medium": "https://www.medium.com/@juicedfinance",
                "twitter": "https://twitter.com/JuicedProtocol",
                "website": "https://www.juiced.fi",
                "whitepaper": "https://juiced.fi/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "ACQZijQbC9sMManzoSq1LS9PuUEspsgwrqeoeXm9vrc6",
            "symbol": "DDOG",
            "name": "Daisy Dog",
            "decimals": 6,
            "logoURI": "https://github.com/nchapman75/DDog/blob/main/DDOG.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ai5LQP5cT1j7nq2R6yNcc8eowdcurozdrvvse6FkuJqa",
            "symbol": "W3C",
            "name": "W3 Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/W3EvolutionsLLC/w3c-token/main/logo.png",
            "tags": [
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming",
                "whitelist"
            ],
            "extensions": {
                "description": "W3Evolutions Gaming Token",
                "website": "https://www.w3evolutions.com"
            }
        },
        {
            "chainId": 101,
            "address": "stoNeDaST7HWxViXptoVKrwzsbabm4Jg8xeMDEtHAoY",
            "symbol": "SSC",
            "name": "Stoned Shiba Club WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/stoNeDaST7HWxViXptoVKrwzsbabm4Jg8xeMDEtHAoY/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/stonedshibaclub",
                "website": "https://www.stonedshibaclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "BCMSDKLugjyQUWxfZBe7JaDvLTK8HjfVUev64ERdigAn",
            "symbol": "WC",
            "name": "WalkCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wsouthern21/crypto/main/WalkCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6BAfSRm941BnCPszTiWB9YeDkSptYRxMMYKQH4t7iFGc",
            "symbol": "STHC",
            "name": "Southern Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wsouthern21/crypto2/main/Crypto%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GirLsab7zzS2sy6DcMpiqoJHpEhtfdZLQnPtQKiHH7nk",
            "symbol": "AUGIR1S",
            "name": "The Aurora Girls",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GirLsab7zzS2sy6DcMpiqoJHpEhtfdZLQnPtQKiHH7nk/logo.png",
            "tags": [
                "nft",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://t.co/as2tJm3wuW",
                "twitter": "https://twitter.com/TheAuroraGirls",
                "website": "https://solcry.io/"
            }
        },
        {
            "chainId": 101,
            "address": "G4rhtwBmKXkTtXNwyS7qa7KYkWHFYzJ5yhNcG2kedt7N",
            "symbol": "PBWL",
            "name": "Peacebot Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G4rhtwBmKXkTtXNwyS7qa7KYkWHFYzJ5yhNcG2kedt7N/logo.png",
            "extensions": {
                "website": "https://peace-bot.com/"
            }
        },
        {
            "chainId": 101,
            "address": "orcKkqqQoXZDYBrDtKHRNcVN73jW7454eK3dFm9Zi69",
            "symbol": "ORCS",
            "name": "The Orcs WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitcoinblack482/logo/main/the%20orc.png",
            "tags": [
                "NFT",
                "dao",
                "utility-token",
                "meta-verse",
                "game",
                "play2earn",
                "governance"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/theorcs",
                "twitter": "https://twitter.com/TheOrcsNFT",
                "website": "https://theorcs.io"
            }
        },
        {
            "chainId": 101,
            "address": "DuSyBCGuhPvyGu6cSvbZonvQvh8JLyGvXJn1TmkJh6Zn",
            "symbol": "$NEON",
            "name": "NeonGame Credits",
            "decimals": 0,
            "logoURI": "https://arweave.net/VPS4BrOgXT4rg-mwGfCLqXL8vtbII0ianMBsw_L2CeE",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://neon.game"
            }
        },
        {
            "chainId": 101,
            "address": "5iet2XLprsR1ACxC46p6RzPbhu5u7Kcw6gHTYbaLnUWo",
            "symbol": "DIRT",
            "name": "Pup Filthy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5iet2XLprsR1ACxC46p6RzPbhu5u7Kcw6gHTYbaLnUWo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/pupfilthy",
                "website": "https://pupfilthy.com"
            }
        },
        {
            "chainId": 103,
            "address": "HmAb5sNiKhddoNtTU11RwuT4xEc1ZG4X1ZgRXjB9VBUE",
            "symbol": "ANIMET",
            "name": "Animedic Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HmAb5sNiKhddoNtTU11RwuT4xEc1ZG4X1ZgRXjB9VBUE/logo.png",
            "tags": [
                "health",
                "animal"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/animedic",
                "twitter": "https://twitter.com/animedic",
                "website": "https://animedic.github.io"
            }
        },
        {
            "chainId": 101,
            "address": "8gYZZsmP1v2WEebzNL1gH12WYqAFcspVByWZ2ejuJMm5",
            "symbol": "PRISM",
            "name": "Prisma Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8gYZZsmP1v2WEebzNL1gH12WYqAFcspVByWZ2ejuJMm5/icon.png"
        },
        {
            "chainId": 101,
            "address": "C9bdTtvXhNAKyB7FWSCwCgfQVYyU4LCLhxogyM921t73",
            "symbol": "PYRO",
            "name": "PYRO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ddbrain/crypto/main/images/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FpNYrNZeNyskdcLK1saZW4iiGTfAE3BbDBv2tCGvAgjD",
            "symbol": "MKX",
            "name": "MekaX Token",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/102857757/161379695-491afb4f-d004-4902-b422-d930c4e1ccb1.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "DAHLEzHa3vFjmBvqvs5BWmyF4HY85YLFvnUz97dNyyou",
            "symbol": "DAHLYS",
            "name": "Dahlys WL1",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img923/6392/7xzbmS.jpg",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dahlysart",
                "website": "https://www.dahlys.art/"
            }
        },
        {
            "chainId": 101,
            "address": "GQ2taRp7AnriEkRjJaynKT8dFkHNGPP5FozaP9191Qtr",
            "symbol": "ARN",
            "name": "Arenum Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GQ2taRp7AnriEkRjJaynKT8dFkHNGPP5FozaP9191Qtr/logo.png",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "telegram": "https://t.me/arenum_community",
                "telegramAnnouncements": "https://t.me/arenum_announcements",
                "website": "https://arenum.io"
            }
        },
        {
            "chainId": 101,
            "address": "32dwLURkgJLKxG9yNdhFAXP9hxrA2Xw6xXu9gPVAvjYt",
            "symbol": "BPUP",
            "name": "Battle Pups NFT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/32dwLURkgJLKxG9yNdhFAXP9hxrA2Xw6xXu9gPVAvjYt/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/z7PFKJ9n",
                "twitter": "https://twitter.com/BattlePupsNFT",
                "website": "https://battle-pups.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Cue8xLyv9Vnf8zoXW4Jpn2ynqaMk4J8pwczhjDLHFyoK",
            "symbol": "USAT",
            "name": "USAT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cue8xLyv9Vnf8zoXW4Jpn2ynqaMk4J8pwczhjDLHFyoK/logo.png"
        },
        {
            "chainId": 103,
            "address": "8LZoJhzSfRgyxehVkMNkn6uJmuMYRUzSKLGw9JGMPAk5",
            "symbol": "SBHD",
            "name": "Sabari H Dev",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sabari245/sol-token/main/logo.jpg",
            "tags": [
                "dev-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G28mRQwFFM4bY5g6vezU5nVbTTckHoMEoPC2qCXbnewY",
            "symbol": "$GBBB",
            "name": "Green Bubble Bucks",
            "decimals": 5,
            "logoURI": "https://uhlpgtdwixivizncogcqldctszd4fmd2dvocojwozxqznxhynm.arweave.net/odbzTHZF0VRlonGFBYxTlkfCsHod_XCcmzs3hltz4a0?ext=PNG",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "website": "http://greenbubbleinc.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5rMKeugmjjQw4SgxZXJAchPEKHDRh2rHakizKye12GQD",
            "symbol": "HASHA",
            "name": "HSH",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5rMKeugmjjQw4SgxZXJAchPEKHDRh2rHakizKye12GQD/logo.png"
        },
        {
            "chainId": 101,
            "address": "A8VnsxRqLr8oAzJbdv4noEKoo3AXgUy6mmmof2xDbgdJ",
            "symbol": "p2wmgo",
            "name": "p2wmgo Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ITConsilium-Limited/p2wmgo/main/gomob1024.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds",
            "symbol": "FORGE",
            "name": "FORGE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/blocksmithlabs",
                "twitter": "https://twitter.com/BlocksmithLabs",
                "website": "https://blocksmithlabs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "warsF9jr2vq7Y9fmRcC9napUVxpv8wcZmcq8ct8gaz8",
            "symbol": "WARS",
            "name": "GMG Hog Wars",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/warsF9jr2vq7Y9fmRcC9napUVxpv8wcZmcq8ct8gaz8/logo.jpg",
            "tags": [
                "apparel-token",
                "ecommerce-token",
                "fashion-token"
            ],
            "extensions": {
                "description": "WARS is a token for the gm Groundhogs Hog Wars event",
                "twitter": "https://twitter.com/gmGroundhogs"
            }
        },
        {
            "chainId": 101,
            "address": "8fnyQUm2c6MGy7qLq4ogFYntEA2XEts59xqrxhZJAkgL",
            "symbol": "CINI",
            "name": "CoinInvest Capital",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/CoinInvestCapital/token-list/main/assets/mainnet/8fnyQUm2c6MGy7qLq4ogFYntEA2XEts59xqrxhZJAkgL/logo.png"
        },
        {
            "chainId": 101,
            "address": "3G9BX9KArQTz3F2mCz5nMKCgNMmJ81BFkkwbuJi8ezq8",
            "symbol": "RSRDC",
            "name": "WCC RSR DC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3G9BX9KArQTz3F2mCz5nMKCgNMmJ81BFkkwbuJi8ezq8/logo.png",
            "tags": [
                "community",
                "airdrop",
                "wcc"
            ],
            "extensions": {
                "discord": "https://discord.gg/metawhips",
                "twitter": "https://twitter.com/wccmetawhips",
                "website": "https://www.skrrrt.vip"
            }
        },
        {
            "chainId": 101,
            "address": "7fug9XCM8FWVKNrbzGetiZn1MX3S3o81t5H7oRu46inc",
            "symbol": "GHEE",
            "name": "Genest Hudon Exchange Entreprise",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Tomx403/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "215ZhGvosobjXKJDWCFz1xc5RJdL13bxaULwTA8dE4y7",
            "symbol": "FLD",
            "name": "FLUID",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jonesynyc/fluid/main/fluid%20final.trans.png",
            "extensions": {
                "twitter": "https://twitter.com/fluid_nyc"
            }
        },
        {
            "chainId": 101,
            "address": "CBvsvCdc48axE5jfct9x4Qz2sg5aBGyac4fzDKdANv4D",
            "symbol": "Pmtoken",
            "name": "Potato Mango token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBvsvCdc48axE5jfct9x4Qz2sg5aBGyac4fzDKdANv4D/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HELZsSZmdzjsh4yP36cxG9R1z9fenennZtP35FbV6EdF",
            "symbol": "HELLIEZ",
            "name": "HELLIEZ DEATHLIST (WL)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HELZsSZmdzjsh4yP36cxG9R1z9fenennZtP35FbV6EdF/logo.png"
        },
        {
            "chainId": 101,
            "address": "Eh71tDvTBHcJYaXrqwjzqzHR1asbZuVS43sK8PAR4QdW",
            "symbol": "HELZ",
            "name": "HELZ TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Eh71tDvTBHcJYaXrqwjzqzHR1asbZuVS43sK8PAR4QdW/logo.png"
        },
        {
            "chainId": 101,
            "address": "G9QgJYvey5D6stDmKsykTVz2yMJz6Br7i395GUjrPDgb",
            "symbol": "MBI",
            "name": "Mobiuss",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ghosy27/MobiusToken/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://discord.gg/PBYEE8mPsr"
            }
        },
        {
            "chainId": 101,
            "address": "sEEDMvgbMAxXmXLDaYWrT8bhCbjrWSMs2EehJbQsopJ",
            "symbol": "WLSEED",
            "name": "MINT PASS WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Neurologist/assets/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8FrBfqvgaYYwYN4bwEJzurkqThzbyz7gibQeDiCYUcw1",
            "symbol": "RDN",
            "name": "Riden app token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sedoyjan/ridentoken/main/logo.png",
            "tags": [
                "riden"
            ]
        },
        {
            "chainId": 101,
            "address": "4kHnCyYn4D6kquPMrQSZTHrVPuUjRom6CvffRr8i5MBG",
            "symbol": "WINTO",
            "name": "Wintoken",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4kHnCyYn4D6kquPMrQSZTHrVPuUjRom6CvffRr8i5MBG/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/hKuu9pTmrF",
                "twitter": "https://twitter.com/Winto_io"
            }
        },
        {
            "chainId": 101,
            "address": "Gvjfc5USPXCALsQc3jmRLoHWMjkZq7dFv2giWnYLf9SL",
            "symbol": "HODLWL",
            "name": "THPWL",
            "decimals": 0,
            "logoURI": "https://user-images.githubusercontent.com/24758821/161396522-d9a7c647-1506-4af6-b617-7195e302b9d2.png",
            "extensions": {
                "twitter": "https://twitter.com/hodlersproject",
                "website": "https://thehodlersproject.com/"
            }
        },
        {
            "chainId": 101,
            "address": "24JRoY6bNNAGUYpmBJoBuR9sXfsMzRosnP7wyMaDZUxg",
            "symbol": "DHLG",
            "name": "Diamond Hand: Legends Gold",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/24JRoY6bNNAGUYpmBJoBuR9sXfsMzRosnP7wyMaDZUxg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dhlegends",
                "website": "https://www.diamondhandlegends.com"
            }
        },
        {
            "chainId": 101,
            "address": "3hvHyKjbJCrbWkZHPYChnG42qwjbjQjDF5fESydRWiU5",
            "symbol": "LDARWL",
            "name": "LOST DINO ARK ROYAL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3hvHyKjbJCrbWkZHPYChnG42qwjbjQjDF5fESydRWiU5/logo.png",
            "tags": [
                "RoyalToken",
                "LostDinoArkRoyal"
            ]
        },
        {
            "chainId": 101,
            "address": "8ewmsfsNH3C3fjjSK7qsogoMfvushqANVxzSz5jAwBH",
            "symbol": "LDAWL",
            "name": "LOST DINO ARK WHITELIST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ewmsfsNH3C3fjjSK7qsogoMfvushqANVxzSz5jAwBH/logo.png",
            "tags": [
                "WhitelistToken",
                "LostDinoArkWhitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "5AsqjUsxFwkGLh9hwSYwV6rd2h9LXMia6PPLmPLG9vq4",
            "symbol": "TYN",
            "name": "Tachyon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zoexbt/crypto/main/logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AogvAeorTFevSc68RyrcLWQDawXUN4qEJbCKxVnuLHNN",
            "symbol": "ATOGT",
            "name": "Atlaria OG Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATTF58JfFUHZktZXyV6aCTwHVCYzPuZBg2hma7J7Ao7n/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "SPraYi59a21jEhqvPBbWuwmjA4vdTaSLbiRTefcHJSR",
            "symbol": "SPRAY",
            "name": "SPRAY",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPraYi59a21jEhqvPBbWuwmjA4vdTaSLbiRTefcHJSR/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/thugpacaz",
                "twitter": "https://twitter.com/ThugPacaz",
                "website": "https://thugpacaz.com"
            }
        },
        {
            "chainId": 101,
            "address": "G5uEmbn8Vft79cNkRBxc4xoCXUYka9FQcivTrKb155Nz",
            "symbol": "unc",
            "name": "For Ukraine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/nasher207/ukrainian-flag/main/ukrainian%20flag.png",
            "tags": [
                "community",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GpNrpPQTmu5k6TWauyggCwjMyjP7WfGMYkMRWYq7dTF5",
            "symbol": "TLOAN",
            "name": "TLOAN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/akhelbey/tloan/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "The Life of a Negro Token",
                "discord": "https://discord.gg/fPf4HNkc",
                "instagram": "https://www.instagram.com/tloanio/",
                "telegram": "https://t.me/tloanio",
                "twitter": "https://twitter.com/tloanio",
                "website": "https://www.tloan.io",
                "whitepaper": "https://tloan.io/wp-content/uploads/2022/04/White_Paper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "2zPkGDkXyDc7Fs2iNADRobEMpfNvtBcFXpSypgd1TmGP",
            "symbol": "PBN",
            "name": "Procrastabation Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/insert-random-name/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8wtoCiM49VyZo8u5kK23UgQJkPU9oRtc7qYj1wRqCRkb",
            "symbol": "BDNI",
            "name": "BadNightOG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shwa86/bagnightkoaala/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "54tN98XPJFedhCSFWURHhNwUsSeNBSAwndqZkkjP6GEy",
            "symbol": "BAI",
            "name": "BOT AI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Adigodx/Bot-AI/5866a5345bdec1b6204e065279a0829111fb02de/pixil-frame-0.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CtoftNu5RgEwcPBRLfGDoJSL8SrgTy84USQBytyewACQ",
            "symbol": "SMFRS",
            "name": "SNIPER MFERS",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CtoftNu5RgEwcPBRLfGDoJSL8SrgTy84USQBytyewACQ/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/moneynosleep777"
            }
        },
        {
            "chainId": 101,
            "address": "9ztqroLtnJ7LpDts1kryyYP3mepfhJJN5YhESvmqb5pZ",
            "symbol": "KUMPUS",
            "name": "Kumpus Project",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kumpus209/token-list/b7e0b60f00062674e4bc355897d29fa3d083b3dc/assets/mainnet/logo.png",
            "tags": [
                "NFT",
                "Merketplace"
            ],
            "extensions": {
                "telegram": "https://t.me/KumpusProject",
                "twitter": "https://twitter.com/KumpusProject",
                "website": "https://kumpusproject.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7VP7Jyvdpa3yJnajTitACUvB4m3GqzrMbCdLVBMw55Xs",
            "symbol": "honey",
            "name": "Cryptohoney",
            "decimals": 0,
            "logoURI": "https://github.com/cryptohoneyy/cyhy/blob/main/cyhy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "94Korhd7vLtkogbpM1c17fqr1bfjpAYJhwkJTTedQW3x",
            "symbol": "YOTU",
            "name": "Miniyotu Troops Token",
            "decimals": 3,
            "logoURI": "https://media.discordapp.net/attachments/926535226820276264/960073766447243284/yotu.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5MRLB1syeNmUda6wKdZDwjFx6QgAqxMPy6baE7S9rCEN",
            "symbol": "GOOONZ",
            "name": "DragooonFruit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OnChainFoundation/Dragooon/main/frooot.png",
            "tags": [
                "Meme-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "7qzjokh7N8eQm6QFnKuydZd83jMSr65VHooJYnxBYxYB",
            "symbol": "SER",
            "name": "Serial Number",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoChris69/Crypto/main/Serial%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DDaf1JydTVMnenk3Rh7SBKBsXV3gy5QuV99ybYiysrAj",
            "symbol": "AGDT",
            "name": "AvirKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/venkykumar2019/New-Crypto-Avir/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "35Qf788AoDACckoakVqbUxT7FdrKUBDa2f1S2MWUqo5J",
            "symbol": "BCC",
            "name": "BCC Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SolanaTokenMerger/BCC-Coin/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "meme-token",
                "BCC-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bcc_roma",
                "website": "https://www.bccroma.it",
                "youtube": "https://www.youtube.com/user/BCCRoma60"
            }
        },
        {
            "chainId": 101,
            "address": "GPcBCCeg2vNVhPmNSePQj6vWM2dpaB9g2BWsvEqbc4EA",
            "symbol": "NDR",
            "name": "ÈndriuCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sitispam0/crypto/main/endriu.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5GNgqzs26SZgxrB1KivhwT7yctZQ5NRWW8EFaC729Gas",
            "symbol": "EROTICA",
            "name": "EROTICA",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5GNgqzs26SZgxrB1KivhwT7yctZQ5NRWW8EFaC729Gas/logo.png",
            "tags": [
                "community-token",
                "nsfw-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/hub_erotica"
            }
        },
        {
            "chainId": 101,
            "address": "6R5ztjhArGnTPpgX8edFAHizbeBX1yJCMYsmeK6fYZ8g",
            "symbol": "Pogi",
            "name": "Pamogi",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6R5ztjhArGnTPpgX8edFAHizbeBX1yJCMYsmeK6fYZ8g/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://www.pamogi.org"
            }
        },
        {
            "chainId": 101,
            "address": "GFyhVQnqNUY99irkC3NCkuucPZVkFfkGsb91detux7Mu",
            "symbol": "TestPogi",
            "name": "Pamogi Test Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFyhVQnqNUY99irkC3NCkuucPZVkFfkGsb91detux7Mu/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://www.pamogi.org"
            }
        },
        {
            "chainId": 101,
            "address": "ESUJjFm3aKEazEJhVLgdPTxwUyMmR2V9kFNVtZM2CkmS",
            "symbol": "NOBU",
            "name": "NobuKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/healthtech100/NobuKoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LABSwpcfDjvRRMmEs87Y9yrj4pS9eofVS6cSbJm2zCW",
            "symbol": "LABS",
            "name": "LABS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LABSwpcfDjvRRMmEs87Y9yrj4pS9eofVS6cSbJm2zCW/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3DKdpeB8ZQ221nxGLuGivph2FX8kaaYtVznxNqKf6yaU",
            "symbol": "SSG",
            "name": "Sol Shadow Gold",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solshadowgold/solshadowgold/main/ssg.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://find-and-update.company-information.service.gov.uk/company/13753949"
            }
        },
        {
            "chainId": 101,
            "address": "DC1G9rWimCagBKRehYGJ2cHj44BcM2rgvDQHJvbyLGWx",
            "symbol": "CERO",
            "name": "Codinero",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/vaibhav-vemula/codinero-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "toaduF8Ay7udXP77Hi1EysXsRYuutL35vZ6wqMBtVvD",
            "symbol": "TOAD",
            "name": "Toad",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/toaduF8Ay7udXP77Hi1EysXsRYuutL35vZ6wqMBtVvD/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/toadboys",
                "twitter": "https://twitter.com/ToadboysNFT",
                "website": "https://www.toadboys.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8FTDPeF3h5tjiKVwaQaKsEEuD6yPoz8eGjhqVPZb1Bh9",
            "symbol": "CCT",
            "name": "Continental Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/johnwick0008/cctoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HbFsWKt2kWzLqryhDhgu1iFgzW8AoR4CQMfKn8wxGydP",
            "symbol": "TKCR",
            "name": "Thinking Crypto Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ThinkingCrypto/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Thinking Crypto Podcast Community Token",
                "facebook": "https://www.facebook.com/thinkingcrypto/",
                "instagram": "https://www.instagram.com/thinkingcrypto/",
                "linkedin": "https://www.linkedin.com/company/13064904/admin/",
                "reddit": "https://www.reddit.com/r/ThinkingCrypto/",
                "twitter": "https://twitter.com/ThinkingCrypto1",
                "youtube": "https://www.youtube.com/channel/UCjpkwsuHgYx9fBE0ojsJ_-w"
            }
        },
        {
            "chainId": 101,
            "address": "77V9ffb8fT34MQrDqnMBLrmpzy6uGQgPRoo8168sNHzj",
            "symbol": "MATE",
            "name": "Solmate Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolmateBot/Images/main/imageedit_2_3928478281.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Solmate Bot Community Token",
                "discord": "https://discord.gg/FWfFgjTepT",
                "twitter": "https://twitter.com/spruijtstudios"
            }
        },
        {
            "chainId": 101,
            "address": "5jWJPEorC9QeauxLgGMV2iiovj2h6PPMw7V8RC5U4yk4",
            "symbol": "NZo",
            "name": "Net Zero DAO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Rafacramos/NetzeroDAO/main/logo.png",
            "tags": [
                "Utility-token",
                "Governance"
            ]
        },
        {
            "chainId": 101,
            "address": "cophqNMBZ6NpUg4f59TymEiJJKdRh9WtedKsr4STJFS",
            "symbol": "COPS",
            "name": "Cop Community WL Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/copcommunity/theLogo/main/copLogo.png",
            "tags": [
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "28hRrgxNCciriqEkbQyxx3XQxifSWvxWuZ9vGZsjddQj",
            "symbol": "SWCT",
            "name": "SiliconWorx Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/GameSlaveSA/crypto/main/swlogo.png",
            "tags": [
                "utility-token",
                "NFT",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "79dC5fzV4stHh5Xgdj23g8XhU5jJZENdBFdbipA2oJvN",
            "symbol": "PPWLT",
            "name": "PrjctPhoenix WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/79dC5fzV4stHh5Xgdj23g8XhU5jJZENdBFdbipA2oJvN/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5rC7FRUAE9NqVrfpFvJY3VjSd6BcPgPZbJBKyE4NGfq7",
            "symbol": "MAFI",
            "name": "Markenfilm Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/mafi-space/mafi-coin/main/logo.png",
            "tags": [
                "social-token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "5YdW5iu9zzxZKRx7Q2PkUSWTkWHKDBEfWFnEXujNzr6z",
            "symbol": "TRAP",
            "name": "T-RaP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/khanna404/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ChimptYgsiiCJERXS3jnQwh9wBtWPqck4wwMgTtGJLpV",
            "symbol": "CHIMPS",
            "name": "The Chimpions Generation 2 Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img924/3499/jh5v6W.jpg",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheChimpions",
                "website": "https://chimpions.co/"
            }
        },
        {
            "chainId": 101,
            "address": "EFjGYw3f9VrkqDXWKo3dPCaiobDnWRcCmgEtKUQrNoUo",
            "symbol": "CNVS",
            "name": "CryptoGraf Canvas II",
            "decimals": 3,
            "logoURI": "https://arweave.net/D6TOE8xwzJ4aTQMUbgatYednNR6cY5HEiHD5VNync7Q",
            "tags": [
                "fractionalized-nft"
            ],
            "extensions": {
                "vault": "https://fraktion.art/vault/C4Xs5b45H3ZvRnpaQkpAdmBTRa6at2Z8iaaRX1mDZZQZ",
                "vaultPubkey": "C4Xs5b45H3ZvRnpaQkpAdmBTRa6at2Z8iaaRX1mDZZQZ"
            }
        },
        {
            "chainId": 101,
            "address": "Vvt9UyGcNp8orFH9P2YTaqHWp58xQVYx9YawnwZGYXA",
            "symbol": "VERDANT",
            "name": "Verdant Vtopian Coin",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/KmmzW6Z/4d034f3d-fda1-45e9-8007-e549a6c6f68d.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3VVBuocQLf8WjqqyWYWceBiymGkmFkjz1zTV4MKsjNXZ",
            "symbol": "MMT",
            "name": "Metaminez",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VVBuocQLf8WjqqyWYWceBiymGkmFkjz1zTV4MKsjNXZ/logo.png"
        },
        {
            "chainId": 103,
            "address": "4cMdQSr8E77DrWCjr4irqqqaoYeurjAuLaa7xu7xVEfk",
            "symbol": "PAKCOIN",
            "name": "Pak Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4cMdQSr8E77DrWCjr4irqqqaoYeurjAuLaa7xu7xVEfk/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FReLpsqXRQ5Xu9hEmmvEk3vs2uvqieE6JyGNkzMyhkb",
            "symbol": "EGV",
            "name": "eco globe vision",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FReLpsqXRQ5Xu9hEmmvEk3vs2uvqieE6JyGNkzMyhkb/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://eco-globe-vision.link"
            }
        },
        {
            "chainId": 101,
            "address": "CWYigXzWDaFt3snfxSz9upq3TyxiUokNxZt4Eun6t2Lm",
            "symbol": "BTBT",
            "name": "BitBot Token(Dev)",
            "decimals": 9,
            "logoURI": "https://github.com/BitBotDAO/assets/blob/master/img/bitbot-logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/BitBotDao"
            }
        },
        {
            "chainId": 101,
            "address": "FFQGmLCrUBD8DUEvPE5bXi4ZmBniaFxafuKUNUBzdxWE",
            "symbol": "EPCT",
            "name": "Epic Token (Dev)",
            "decimals": 9,
            "logoURI": "https://github.com/EpicDAO/assets/blob/master/img/Epic-logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/Epic_DAO"
            }
        },
        {
            "chainId": 101,
            "address": "8vBej3sZ87b5W4QyEyTtmnqsqA4xwpbABdfKGcQWH7o3",
            "symbol": "GROV",
            "name": "Groove Token (Dev)",
            "decimals": 9,
            "logoURI": "https://github.com/GrooveDAO/assets/blob/master/img/Groove-logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/GrooveDAO"
            }
        },
        {
            "chainId": 101,
            "address": "HAARJNu7UXSAWfGLF2yWBD3Mv3Rakc1naeYgyTbZybWN",
            "symbol": "JCO",
            "name": "Jackoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAARJNu7UXSAWfGLF2yWBD3Mv3Rakc1naeYgyTbZybWN/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/CZexhib"
            }
        },
        {
            "chainId": 101,
            "address": "AumBEDtS1V3UtuAGf1UeW8jUhHuG4HckhFvnBes4tckj",
            "symbol": "G",
            "name": "G",
            "decimals": 9,
            "logoURI": "https://github.com/SolGuards/G/blob/main/logo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolGuards",
                "website": "https://www.solguards.io"
            }
        },
        {
            "chainId": 101,
            "address": "FMEe1VPgtGPp2jMr1Q1VcaSw1PfTBEqkjosk1EESCQN",
            "symbol": "OFM",
            "name": "Outbreak Free Mint",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/FJCztXW/Outbreak-Free-Mint.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5BKKS6cSvydNW882kLJw5EEzyyvZEqE1945EXgUB1Pkv",
            "symbol": "OGH",
            "name": "Outbreak Geisha Holder",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/HDxK8Fc/Outbreak-Geisha-Holder.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9dMYZBjnWTpUMJ9pzNmGMg3AXQyZ2h7HmURa1qt3qNEQ",
            "symbol": "WLOUTBREAK",
            "name": "Outbreak Whitelist",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/VjnqTJy/Outbreak-Whitelist.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AN5yB1R4D9k2VQG7dEEbzrDxAw1GkbCcgVJhAjRseWZ3",
            "symbol": "WLFCFS",
            "name": "WL FCFS",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/G3GbmDb/Outbreak-FCFS.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Sq4tEi2KpjUbqfss5Nwfkf53bgd2RCmBHY8u65UQjBp",
            "symbol": "$SQUEAK",
            "name": "SQUEAK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Sq4tEi2KpjUbqfss5Nwfkf53bgd2RCmBHY8u65UQjBp/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "6KyMx9juomENV4rZzTH1hj5HXqCo1pWx89J94VaAPecz",
            "symbol": "ELAT",
            "name": "E LATS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/e-lats/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FbNGhqrw9Wm1y2mdkBRuhXkBfrh6fTd1gXS7d4oov88b",
            "symbol": "MAG",
            "name": "Meta Ape Gang Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/metaape.jpeg",
            "extensions": {
                "twitter": "https://twitter.com/MetaApeGang"
            }
        },
        {
            "chainId": 101,
            "address": "PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG",
            "symbol": "PRANA",
            "name": "prANA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG/logo.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nirvana_fi",
                "website": "https://nirvana.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "DPThsefJD3CtrkiDTNuFM9cS2b6kPFTQcAfKx4YNX9A6",
            "symbol": "$BOLT",
            "name": "Panzerdogs BOLTs",
            "decimals": 4,
            "logoURI": "https://www.panzerdogs.io/img/BOLT.gif",
            "tags": [
                "utility-token",
                "game-token",
                "GameFi",
                "game-currency",
                "payment-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/panzerdogs",
                "twitter": "https://twitter.com/panzerdogs",
                "website": "https://www.panzerdogs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CEtqLJAFomU9FgL47vZW9LsWgHL9rcT5nyVrna2ddtPa",
            "symbol": "$PANZR",
            "name": "Panzerdogs PANZR",
            "decimals": 2,
            "logoURI": "https://www.panzerdogs.io/img/PANZR.gif",
            "tags": [
                "utility-token",
                "game-token",
                "GameFi",
                "game-currency",
                "payment-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/panzerdogs",
                "twitter": "https://twitter.com/panzerdogs",
                "website": "https://www.panzerdogs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5aNLJRid1y4Y9UJxzsM8yPEDQAfq8rZf5azvoTFtNv69",
            "symbol": "ARX",
            "name": "ARKADIA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5aNLJRid1y4Y9UJxzsM8yPEDQAfq8rZf5azvoTFtNv69/logo.png",
            "tags": [
                "Game-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7JzWxWejsb",
                "twitter": "https://twitter.com/ArkadiaUnivers",
                "website": "https://arkadianft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Gqiou284yNmVtTGVVM8guof4DaQtbrEDC4kgjNHZbbo4",
            "symbol": "SHIVX",
            "name": "Shivx",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/ShivXPrivacy/token-list/48e89eaa1a5b4577fc4f3066e5661933612bdec9/assets/mainnet/Gqiou284yNmVtTGVVM8guof4DaQtbrEDC4kgjNHZbbo4/Logo.png"
        },
        {
            "chainId": 101,
            "address": "DLu9VabVMnEpcU2CZ5rMGAPyMKBQH3RASUnZWz9qgCTv",
            "symbol": "RBWLT",
            "name": "RarityBox WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DLu9VabVMnEpcU2CZ5rMGAPyMKBQH3RASUnZWz9qgCTv/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDC3jaDCVtyxyBLiED14hZcoyZeQzk7Cben6nodibC2f",
            "symbol": "ALLY",
            "name": "AllyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/allyb95/cryp/main/a-logo-1495222655.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kedZM5F8wdK25NU5ScubcpaDjxVKaiNiV2W29fc2NAv",
            "symbol": "LC",
            "name": "Lightning Cell",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kedZM5F8wdK25NU5ScubcpaDjxVKaiNiV2W29fc2NAv/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BzpzLiPKaNt7yzz2r1HSQ5v1L3aXtU16vVpHGbdCkLwU",
            "symbol": "INFMO",
            "name": "INFOMO ALPHA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BzpzLiPKaNt7yzz2r1HSQ5v1L3aXtU16vVpHGbdCkLwU/logo.png"
        },
        {
            "chainId": 101,
            "address": "C27pBNhhNL6YaAR4xUpV9qqigcdo25yMS5UVsnqV9HxT",
            "symbol": "V",
            "name": "Vertical",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C27pBNhhNL6YaAR4xUpV9qqigcdo25yMS5UVsnqV9HxT/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4mKBZqR8EH1dAjtKsbu6zKPJ8haiCvqWKRCSYw1JdNmE",
            "symbol": "POPC",
            "name": "Popcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/georgeamccarthy/Popcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6668ECUWVZjx5HiR74UGcuumtAyW2ser7HBX3RTVBCZF",
            "symbol": "666",
            "name": "666 Whitelist Pass",
            "decimals": 0,
            "logoURI": "http://666serials.xyz/wp-content/uploads/2022/04/6.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8k8nYi4NSigPgk9CijcDJyoraGr273AggWZFgn8Adk1a",
            "symbol": "VSNRY",
            "name": "Visionary Studios Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8k8nYi4NSigPgk9CijcDJyoraGr273AggWZFgn8Adk1a/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "serumV3Usdc": "6cQTBrDUWjSKjeqhAzYE7GA1LjR7QexoSfwGrDEVpLFS",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "8tSb4sMDuwHYcrQbm7urQw4WqLL2MNo71FXv3FCCVEnf",
            "symbol": "MOO",
            "name": "Moomero Equity",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Moomero/moomero-logo/main/Artboard%201.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/moomero_"
            }
        },
        {
            "chainId": 101,
            "address": "A9v6nZmsfjAiby6vJNABQcXCeHE961xvTgMPUNEcVyrp",
            "symbol": "MTB",
            "name": "MetaBridge",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/wisdomeji/Metabridge-logo/main/metabreeeeret.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5ujgCnbYPSatHcw4NcM2A349hz1HELoo2RCRRBiU9Y6x",
            "symbol": "ESX",
            "name": "Estrellax",
            "decimals": 3,
            "logoURI": "https://github.com/lysy-vlc/estrellax-crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "neoneXWLNouaSKk2zna7p54qbmsuVUtdmMGzR5MuPd9",
            "symbol": "NEON",
            "name": "Whiteneon Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/whiteneon/token-list/main/assets/mainnet/neoneXWLNouaSKk2zna7p54qbmsuVUtdmMGzR5MuPd9/logo2.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://solana.whiteneon.com/"
            }
        },
        {
            "chainId": 103,
            "address": "neoneXWLNouaSKk2zna7p54qbmsuVUtdmMGzR5MuPd9",
            "symbol": "NEON",
            "name": "Whiteneon Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/whiteneon/token-list/main/assets/mainnet/neoneXWLNouaSKk2zna7p54qbmsuVUtdmMGzR5MuPd9/logo3.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://solana.whiteneon.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CYCPEtrCYjNycxZiZ2RyeBBUqzsgRh7yGjn4SwX2AnPF",
            "symbol": "ATLAS",
            "name": "Atlascoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CYCPEtrCYjNycxZiZ2RyeBBUqzsgRh7yGjn4SwX2AnPF/logo.png"
        },
        {
            "chainId": 101,
            "address": "PiXELzrxUL2fJjPLw4SxmpPD4zwM5t7aKfNEo1WPxJw",
            "symbol": "PiXEL",
            "name": "PiXEL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PiXELzrxUL2fJjPLw4SxmpPD4zwM5t7aKfNEo1WPxJw/logo.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solsunsets",
                "website": "https://solsunsets.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ToGdR5gjTqDe9SuLb1zcCathHv8KwqZKwHryUyztWsM",
            "symbol": "PBCWL",
            "name": "Paladin Bounty Club WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToGdR5gjTqDe9SuLb1zcCathHv8KwqZKwHryUyztWsM/logo.png",
            "tags": [
                "mint-token",
                "whitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "AZESJgZyDkHW11QA1MWhBa46ne3y4fp88wbtBr8UZR5m",
            "symbol": "BIMA",
            "name": "Bianchi Michele Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kira96033/crypto/main/cry.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "pbLJx61yx7grY458m3WjJhgDiSYZmXBaqLaA6KvKbRE",
            "symbol": "S3X",
            "name": "S3X",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VHITS-Full-Stack/S3X_Token/main/logo.png",
            "tags": [
                "NFT-Token",
                "Swap-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "BmU9dawcCuBPrnxoiH9bW4GSh8wJ3yeQNW5U3uk4CUGa",
            "symbol": "ELONZIL",
            "name": "ElonZilla",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BmU9dawcCuBPrnxoiH9bW4GSh8wJ3yeQNW5U3uk4CUGa/logo.png"
        },
        {
            "chainId": 101,
            "address": "2Wqm8QGYqg78jkFaSxUKXuoLz6HvWm2m9ApnDMnRESb9",
            "symbol": "DAWGZ",
            "name": "Degen Dawgz Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/degendawgztoken/main/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/DegenDawgz",
                "website": "https://www.degendawgz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "79P7Awvs2JUUFPTh17hCvJKwEo7RFexo4ox7HoEuVcVw",
            "symbol": "HUNT",
            "name": "Hunter Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/arshbhar/HUNT/main/hunt.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9P9DouJzKg71PR45NjxQJcArJkpz9xfyyeDxRwVPkL9a",
            "symbol": "PANDM",
            "name": "Plowz and Mowz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9P9DouJzKg71PR45NjxQJcArJkpz9xfyyeDxRwVPkL9a/logo.png"
        },
        {
            "chainId": 101,
            "address": "AzeorE2y9mNQAdkzhjV4KdFEdzRXYbU4o7dpp2uBNqgf",
            "symbol": "SUFZ",
            "name": "SupraFizz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/suprafizz/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solsunsets",
                "website": "https://solsunsets.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Dah1ysVPY2zhnR4M3akE3LFpuZCpA4pbHK5hMbjNBKSc",
            "symbol": "DHLYDT",
            "name": "Dahly Dream Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dah1ysVPY2zhnR4M3akE3LFpuZCpA4pbHK5hMbjNBKSc/logo.png"
        },
        {
            "chainId": 101,
            "address": "G9Tbc3bKxsWcWbYydqeqpDPsjFRfD6zMmeoFBJj1bcHG",
            "symbol": "SPN",
            "name": "Spotter",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mofrey-max/cryptomint/main/logo.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Spotter__NFT",
                "website": "https://www.spotternft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "44d5K8qXur2t2dgiWDpKW4jyBkv5bW4YcbS7zm2vWiJ8",
            "symbol": "WILSSLAP",
            "name": "Will Smith's Slap",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/44d5K8qXur2t2dgiWDpKW4jyBkv5bW4YcbS7zm2vWiJ8/logo.png"
        },
        {
            "chainId": 101,
            "address": "8NKKpjM5vAKDaNBECWC96fTLc7fSfRabNXR1gp55qbv2",
            "symbol": "RUBYSTA",
            "name": "Rubysta Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NKKpjM5vAKDaNBECWC96fTLc7fSfRabNXR1gp55qbv2/logo.png",
            "tags": [
                "RUBYSTA"
            ]
        },
        {
            "chainId": 101,
            "address": "8uKXR3Z3toSXt4i8KMnSWC5ZBwC9sR1caFUUSt3W8inQ",
            "symbol": "BRO",
            "name": "BRO",
            "decimals": 0,
            "logoURI": "https://github.com/1618033988749894/future/blob/main/sqgoldenup.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NqtM2bpU5ZQLhq4HqNZipXQbqxeT3Zx3tqwKkFxprGf",
            "symbol": "HMPY",
            "name": "HompyPompyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aldeot/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8SgWNfuqTxwpRjmJiXr9rhJT5z1LccQZ6JH81S4pZTRy",
            "symbol": "OVIT",
            "name": "OvI Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ViDesignDE/SolanaOVI/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "Fhfucdo8C4QSSVKiuMJBPr2dRSo35GKKNar14shGDEEe",
            "symbol": "PLAY",
            "name": "PLAY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fhfucdo8C4QSSVKiuMJBPr2dRSo35GKKNar14shGDEEe/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Native in game utility token of the Moveworld"
            }
        },
        {
            "chainId": 101,
            "address": "2377XYwns6GBJziPbBBg9EzJWq9TPiunZPiuKB4DdQCR",
            "symbol": "HIPPO",
            "name": "Happy Hippos",
            "decimals": 9,
            "logoURI": "https://static.wixstatic.com/media/628d2a_d22ee80f7d574e39b9ae672911d41f7d~mv2.gif",
            "tags": [
                "utility-token",
                "NFT",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/happyhippos",
                "twitter": "https://twitter.com/happysolhippos",
                "website": "https://www.solhappyhippos.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5HGSVd9uzLn6CmNb4BLdMSbkWFTEf4ZSh1tLWQ6uEEUA",
            "symbol": "FBUTE",
            "name": "FBute Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5HGSVd9uzLn6CmNb4BLdMSbkWFTEf4ZSh1tLWQ6uEEUA/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "crypto-calorie",
                "crypto",
                "fitness",
                "metaverse",
                "nft",
                "burn-to-earn",
                "web3",
                "vr"
            ],
            "extensions": {
                "description": "Fbute token has been designed to reward users for their burned calories.",
                "discord": "https://discord.gg/B3V2GU5DFX",
                "instagram": "https://www.instagram.com/fbutecoin",
                "reddit": "https://www.reddit.com/r/FbuteCoin",
                "telegram": "https://t.me/fitlich",
                "twitter": "https://twitter.com/fbutecoin",
                "website": "https://www.fbute.com"
            }
        },
        {
            "chainId": 101,
            "address": "6c2yTdTz7N7drPo4Pd6LjkvHk5C2mfTJty4PNZC9CYDF",
            "symbol": "MHCNFTZ",
            "name": "MHC Token NFTs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6c2yTdTz7N7drPo4Pd6LjkvHk5C2mfTJty4PNZC9CYDF/logo.png"
        },
        {
            "chainId": 101,
            "address": "3L4dyJkfvsg2tG3yLHJw1PcrVbcrZEi3gxsWQXV9Su51",
            "symbol": "SNT",
            "name": "Social Network Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3L4dyJkfvsg2tG3yLHJw1PcrVbcrZEi3gxsWQXV9Su51/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KRTapyUMe5fW92KZkYoXToFtc6Cn7UG6seaKz646oGu",
            "symbol": "OOINK",
            "name": "OOINK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/oink.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://t.co/K0TdK7RFgI",
                "twitter": "https://twitter.com/kireifarm",
                "website": "http://www.kireifarm.com/"
            }
        },
        {
            "chainId": 101,
            "address": "653jGgvZGY9Ewazf9eMnvAbTZjXFMUpSrGw13eaCKUaP",
            "symbol": "CARTO",
            "name": "Carto Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/653jGgvZGY9Ewazf9eMnvAbTZjXFMUpSrGw13eaCKUaP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.deedmaps.com"
            }
        },
        {
            "chainId": 101,
            "address": "2a2aGyHFkGqsDeK8T9WHqsF7MYa7wJ9XWhfvKAixehDj",
            "symbol": "VAULT",
            "name": "THE VAULT-X DAO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2a2aGyHFkGqsDeK8T9WHqsF7MYa7wJ9XWhfvKAixehDj/logo.png",
            "tags": [
                "NFT",
                "GOVERNANCE",
                "ESCROW",
                "PROTECTION-PROTOCOL"
            ],
            "extensions": {
                "discord": "https://discord.gg/CaSVfkCbvc",
                "twitter": "https://twitter.com/TheVaultDAO",
                "website": "https://thevault-x.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8BKVqwa9kGFkzFqJRRfCDUtKJNqvg991t85MNdpXYMhx",
            "symbol": "LS",
            "name": "Leopards Syndicate Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8BKVqwa9kGFkzFqJRRfCDUtKJNqvg991t85MNdpXYMhx/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Native token of the Leopards Syndicate community"
            }
        },
        {
            "chainId": 101,
            "address": "8UyvxCoVXoVaftWzp7j9yo2sGL2HnHTFDV4capenyFaf",
            "symbol": "BOSA",
            "name": "CoinBosa",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UyvxCoVXoVaftWzp7j9yo2sGL2HnHTFDV4capenyFaf/logo.png"
        },
        {
            "chainId": 101,
            "address": "MHMFaJCu8wWabFghBV759ZaKqhCaqJ1jWpxFzbgmUia",
            "symbol": "PT",
            "name": "Platinum Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MHMFaJCu8wWabFghBV759ZaKqhCaqJ1jWpxFzbgmUia/PtToken.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WtpzxsBTGF",
                "twitter": "https://twitter.com/MH_Multiverse",
                "website": "https://milehighmultiverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "HcGA6XADWN6vrSGWkEDrUh6T3ME17SaJbCyGDD2PvQUT",
            "symbol": "CFPK",
            "name": "CoffeePark",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/martiolozz/solana-listed-tokens/main/HcGA6XADWN6vrSGWkEDrUh6T3ME17SaJbCyGDD2PvQUT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CoffeeParkUSA",
                "website": "https://coffeeparkusa.com/"
            }
        },
        {
            "chainId": 103,
            "address": "EpEvDzoGY31VqMgCDQUWAA2sGvKibYSjcPChMNJ3rVBj",
            "symbol": "MASNASRITKN",
            "name": "NASRI TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/masnasri-a/images/main/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/alienpdev"
            }
        },
        {
            "chainId": 103,
            "address": "8nbuqPoxDu4jYU6mZNTDJ44FdVtUPf7JyKPXYNVdF7Av",
            "symbol": "ONA",
            "name": "ONA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IanXBT/Ona/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "8juDZbaGDFVpeqPFnGBhXvnrb1a4jaewKB1hQxzRxVT8",
            "symbol": "FGM",
            "name": "Following Gods Mission",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Meeker44/Crypto/main/FGMlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MoshMwLkVu4iwrPBaWpYkh43qJiSXsnyzNLuMXFv5F4",
            "symbol": "MOSHI",
            "name": "MOSHI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MoshMwLkVu4iwrPBaWpYkh43qJiSXsnyzNLuMXFv5F4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/moshiheads",
                "twitter": "https://twitter.com/Moshiheads",
                "website": "https://www.moshiheads.com/"
            }
        },
        {
            "chainId": 101,
            "address": "xLvawiUDbXmaLdNi71s4Ry8y4ykXJetwZ9LZ124jMpi",
            "symbol": "XLVA",
            "name": "XLVA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xLvawiUDbXmaLdNi71s4Ry8y4ykXJetwZ9LZ124jMpi/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/WwVJQdD8vV",
                "twitter": "https://twitter.com/GorillaeNFT",
                "website": "https://gorillaenft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ANAxByE6G2WjFp7A4NqtWYXb3mgruyzZYg3spfxe6Lbo",
            "symbol": "ANA",
            "name": "ANA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANAxByE6G2WjFp7A4NqtWYXb3mgruyzZYg3spfxe6Lbo/logo.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nirvana_fi",
                "website": "https://nirvana.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "FiEndP3oMuxNqjjPLUP2ADHWS2V7fT3PfoB66wePCEce",
            "symbol": "REE",
            "name": "99Fiends",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FiEndP3oMuxNqjjPLUP2ADHWS2V7fT3PfoB66wePCEce/99.png",
            "extensions": {
                "twitter": "https://twitter.com/99Fiends"
            }
        },
        {
            "chainId": 101,
            "address": "5zY4wBip1kF8C7p8CTESq61vqGeVdSwLqBv3Y3cr9NUe",
            "symbol": "Sibon-BV",
            "name": "Aandelenregister Sibon BV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5zY4wBip1kF8C7p8CTESq61vqGeVdSwLqBv3Y3cr9NUe/blockPNG.png",
            "tags": [
                "aandelenregister",
                "Sibon-bv",
                "Blockstone"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "website": "https://blocksto.ne/sibon/"
            }
        },
        {
            "chainId": 101,
            "address": "4gC2RFo69c8oGqzqAcrhtQgJCu13EEMWUVtkpVbDjQx2",
            "symbol": "RUFF",
            "name": "CrazyHuskies Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/DEAN3lr/Crazyhuskies/main/pixil-frame-0%20(4).png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/crazyhuskies"
            }
        },
        {
            "chainId": 101,
            "address": "3q8T4e1kvagyJEzUFiJnUSTHZLrAg1ubLfTkGQYXeq1e",
            "symbol": "GIV1",
            "name": "The Giving Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3q8T4e1kvagyJEzUFiJnUSTHZLrAg1ubLfTkGQYXeq1e/logo.svg",
            "tags": [
                "charity"
            ],
            "extensions": {
                "facebook": "https://facebook.com/givingpool",
                "twitter": "https://twitter.com/givingpool",
                "website": "http://thegivingpool.io/"
            }
        },
        {
            "chainId": 103,
            "address": "3q8T4e1kvagyJEzUFiJnUSTHZLrAg1ubLfTkGQYXeq1e",
            "symbol": "GIV1",
            "name": "The Giving Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3q8T4e1kvagyJEzUFiJnUSTHZLrAg1ubLfTkGQYXeq1e/logo.svg",
            "tags": [
                "charity"
            ],
            "extensions": {
                "facebook": "https://facebook.com/givingpool",
                "twitter": "https://twitter.com/givingpool",
                "website": "http://thegivingpool.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4gr66MnuAPXPJfMYCxhKVotQVGwDYRKdSUDhtUT1dQGB",
            "symbol": "Sibon-BV",
            "name": "Aandelen Sibon BV Miep Sibon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4gr66MnuAPXPJfMYCxhKVotQVGwDYRKdSUDhtUT1dQGB/miepPNG.png",
            "tags": [
                "aandelenregister",
                "Sibon-bv",
                "Blockstone"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "website": "https://blocksto.ne/miep-sibon/"
            }
        },
        {
            "chainId": 101,
            "address": "EJ9svufR3WZmokDNno2Bw5ZywUFYXyinopj1V1WXZ8T1",
            "symbol": "DMAD",
            "name": "Diamond Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/kingalpacas2005/fot/main/gfvgkyujhiju.png",
            "tags": [
                "History"
            ]
        },
        {
            "chainId": 101,
            "address": "9GhsR7rpx2UXKvKpU7Z1QMDimdWqnEdjRrgYrr2VARUn",
            "symbol": "GANK",
            "name": "Gank Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/qudo-code/assets/master/solana/tokens/GANK/GANK.jpeg",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://www.leagueoflegends.com/en-us/"
            }
        },
        {
            "chainId": 101,
            "address": "Fmf7mnbUGg2EM83F7pcyPSTU29QWmvj4nSrbanVPi11J",
            "symbol": "NBN",
            "name": "Nothing But Net",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/NBNdao/NBN-say-cheese/main/3.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SkUAuZTNuS12jfCMGbym6zaNnqMKDjbyAZriihf1Ss6",
            "symbol": "$SKEL",
            "name": "SSS Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SkUAuZTNuS12jfCMGbym6zaNnqMKDjbyAZriihf1Ss6/logo.png"
        },
        {
            "chainId": 101,
            "address": "ENoUQgNxqChUcFKYdgVNEXP65rT4e6Ro6ooFkmj4efvb",
            "symbol": "$KULL",
            "name": "METAKUL",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENoUQgNxqChUcFKYdgVNEXP65rT4e6Ro6ooFkmj4efvb/logo.png",
            "tags": [
                "nft",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3MwNU73G7M",
                "twitter": "https://twitter.com/metakul_",
                "website": "https://www.metakul.in/"
            }
        },
        {
            "chainId": 101,
            "address": "B1a2kJpMXbvrrjEah5eipKSQ63x2Wrfa3kcVrR7FS2TZ",
            "symbol": "JBPTK",
            "name": "JBPTK Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/anshulsoni0112/crypto/main/jbp_logo_800x897.png",
            "tags": [
                "social-token",
                "city-token",
                "SmartCity"
            ]
        },
        {
            "chainId": 101,
            "address": "GFQ8vafcdZXyPQpS5dvfbtYT5uiSwFybn2JvC3yJU8Jg",
            "symbol": "SOLF",
            "name": "Solar Flare",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dapperdann46/crypto/main/logo3.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FLLW9PtziXxtin4bvcVKbw1roFPENi5HPADzXdcjGbY1",
            "symbol": "FELLOW",
            "name": "FELLOW",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLLW9PtziXxtin4bvcVKbw1roFPENi5HPADzXdcjGbY1/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fellowapes",
                "twitter": "https://twitter.com/FellowApes",
                "website": "https://www.fellowapes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8WSwqPtE7BGCiD8ZSnBdJPoc26QkiaBKDf4LhN8DjQuD",
            "symbol": "STAD",
            "name": "Stadium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8WSwqPtE7BGCiD8ZSnBdJPoc26QkiaBKDf4LhN8DjQuD/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HyZ2jJ8qC9SQMqZetbmoT3KbRBp4DWcqiQUPvtDg8B75",
            "symbol": "ARTL",
            "name": "Aurora TL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HyZ2jJ8qC9SQMqZetbmoT3KbRBp4DWcqiQUPvtDg8B75/logo.svg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Wo5Y22ZxFuxhhMQ5CU7vZaYm4BCByWv9QUMxaeW9eZS",
            "symbol": "EBX",
            "name": "ebx coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micagommm/crypto/main/input-onlinepngtools.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9K8tMEDxk18873U7re7ksz4uYXtz6ECTCSvTcZyQUHLu",
            "symbol": "AVBE",
            "name": "Aviabee",
            "decimals": 0,
            "logoURI": "https://nikkoagustino.my.id/aviabee-coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://aviabee.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GhZJgY56T5BVWfbPTevJA4jidd4piSY5sw5bdowf6ksP",
            "symbol": "FUNK",
            "name": "funk token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/qudo-code/assets/master/solana/tokens/FUNK/FUNK.jpeg",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://vulfpeck.com/"
            }
        },
        {
            "chainId": 101,
            "address": "qRvWKRP2hdbVi8aCw5f9V2JarxtV6W51dVnm1tyXWXk",
            "symbol": "TLLWL",
            "name": "The Lost Legion WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qRvWKRP2hdbVi8aCw5f9V2JarxtV6W51dVnm1tyXWXk/Logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/thelostlegion",
                "twitter": "https://twitter.com/LostLegionSol"
            }
        },
        {
            "chainId": 101,
            "address": "97j6myGGo1o3r3PW23cbycQXwdm1VUePC4LtL5pzYhnV",
            "symbol": "Aandelen-Sibon-BV",
            "name": "Chantal Sibon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97j6myGGo1o3r3PW23cbycQXwdm1VUePC4LtL5pzYhnV/chantalPNG.png",
            "tags": [
                "Sibon-BV",
                "aandeelhouder",
                "Blockstone"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "website": "https://blocksto.ne/chantal-sibon/"
            }
        },
        {
            "chainId": 101,
            "address": "ErEheZw9La1xiSJbPnzYDGdK87ZAASHRNLQF8NK1mmNP",
            "symbol": "$HVN",
            "name": "Heaven",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ErEheZw9La1xiSJbPnzYDGdK87ZAASHRNLQF8NK1mmNP/logo.png",
            "tags": [
                "NFT",
                "GAMEFI",
                "Staking"
            ],
            "extensions": {
                "description": "Heaven is an SPL token of Heaven Dragons Empire, a premium collection of 1525 Dragons enjoying the clear skies and the royalties from the Empire.",
                "discord": "https://discord.gg/3Uw8p5DRdj",
                "twitter": "https://twitter.com/HeavenDragonsEP"
            }
        },
        {
            "chainId": 101,
            "address": "APJShV3kXDtH6aatqmHCH9Tfodz31cwijj3Et9U8sHSR",
            "symbol": "BLP",
            "name": "BluePay Coin",
            "decimals": 0,
            "logoURI": "https://github.com/kitt2022/crypto/blob/main/logo.png",
            "tags": [
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HavRcoAaHq7SwLK7qB14TRkJ3eq9QQW1cdYLwHZKaNci",
            "symbol": "xGOLD",
            "name": "Synthetic GOLD",
            "decimals": 8,
            "logoURI": "https://synthetify.io/icons/xgold.svg",
            "extensions": {
                "coingeckoId": "tether-gold",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7WLoxCzU5tDi2WQsuGTc7GAE7ZPzf2udNBXBfym1quL7",
            "symbol": "HSHA",
            "name": "HASHAA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7WLoxCzU5tDi2WQsuGTc7GAE7ZPzf2udNBXBfym1quL7/logo.png"
        },
        {
            "chainId": 101,
            "address": "6wcWAqCEmxQkEK6hPEsyvTpiaFFCENww6gxqMTq8YBHE",
            "symbol": "TTST",
            "name": "T\u0026T Socialist Party Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/xxmafiaxxx/doobietokens/main/TTSP.png",
            "extensions": {
                "description": "Trinidad and Tobago Socialist Party Crypto Token",
                "instagram": "https://www.instagram.com/socialismtnt/"
            }
        },
        {
            "chainId": 101,
            "address": "J2xJmMnsZXgaS6DE3sPkNfSYvnCNzJLvFerMsofrmNg5",
            "symbol": "HMTOK",
            "name": "Hemati Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bahmanyjaan/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6PYPFEs225bMemQb24ZfDfTjm6mHpNczJ81LoSPakKWg",
            "symbol": "ROR",
            "name": "Wild Lion",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/itssongbird/roar-token/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/XGKBEhnJUQ",
                "twitter": "https://twitter.com/wildlionclan",
                "website": "https://wildlionclan.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "7pz6yNVAC5yioPpsUFZH6jwYqDZj2W5aM6hyFmsxA3Qo",
            "symbol": "AARK",
            "name": "KADIA COIN",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7pz6yNVAC5yioPpsUFZH6jwYqDZj2W5aM6hyFmsxA3Qo/logo.png",
            "tags": [
                "GAMEFI",
                "NFT",
                "Ecosystem"
            ],
            "extensions": {
                "website": "https://arkadianft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HB7begETKJfYjwfjHbtsLxA1o3n3Tt5KbwQsoDD5bgu8",
            "symbol": "sxy-wl",
            "name": "Space X Yodas Whitelist Token",
            "decimals": 0,
            "logoURI": "https://bafkreif2643iapwypek5ifmzwosd5uv3dtognfxy42br6d4c2k6nl3g6ci.ipfs.dweb.link/?ext=png",
            "tags": [
                "SXL",
                "SXA",
                "SXY"
            ],
            "extensions": {
                "description": "Having SXY will give you exclusive access to a secret location on PlanetX in SXA Metaverse!",
                "discord": "https://discord.gg/Mfgnj3xBk3",
                "twitter": "https://twitter.com/spacexapes"
            }
        },
        {
            "chainId": 101,
            "address": "6hQpbdmmX6gXGUkmjJ82UtfFBzyTP3Hiqv1KZMtHWXpv",
            "symbol": "GMT",
            "name": "Global Micro Tasking",
            "decimals": 6,
            "logoURI": "https://globalmicrotasking.com/Graphics/GMT_lrg.png",
            "extensions": {
                "twitter": "https://twitter.com/globalmicrotask",
                "website": "https://www.globalmicrotasking.com/gmt"
            }
        },
        {
            "chainId": 101,
            "address": "7xhXrYsxkxGKTdMhA3KVo3V88Y1KuAzJ4zMK14rEFmJf",
            "symbol": "TATR",
            "name": "Tater",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BretNazarok/Tater/main/tater2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MBSPuYeEoq6jJ9BS2qXv3DJ4RvrXCDSxkTAz9fBuKdu",
            "symbol": "MBS",
            "name": "MBS WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MBSPuYeEoq6jJ9BS2qXv3DJ4RvrXCDSxkTAz9fBuKdu/logo.png"
        },
        {
            "chainId": 101,
            "address": "FFAfrApKHskMMsnHs8P8WGqDweXCf2AbeMVjz9vbVY4C",
            "symbol": "DUWL",
            "name": "Dropout WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFAfrApKHskMMsnHs8P8WGqDweXCf2AbeMVjz9vbVY4C/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CizWKoDGhVXbFowZr3pMLxY5rnPr7yXtLNN2dQ2AbEDZ",
            "symbol": "ONA",
            "name": "ONA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/IanXBT/Ona/main/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/experienceona",
                "website": "https://www.whatdoyousee.net/"
            }
        },
        {
            "chainId": 101,
            "address": "74XH85tgybENDa4iXzrqFthi643qf9jvqqZ1S97iHqZA",
            "symbol": "NEGRO",
            "name": "NEGRO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/akhelbey/negro/main/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "description": "The Life of a Negro Token",
                "discord": "https://discord.gg/fPf4HNkc",
                "instagram": "https://www.instagram.com/tloanio/",
                "telegram": "https://t.me/tloanio",
                "twitter": "https://twitter.com/tloanio",
                "website": "https://www.tloan.io",
                "whitepaper": "https://tloan.io/wp-content/uploads/2022/04/White_Paper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "Geno53CnJsdiiQsnj5DYxfL9WoLFUEYem78AizQaH8vK",
            "symbol": "HABITAT",
            "name": "Genesis Genopets Habitats Mint Pass",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img923/1323/MolTEv.jpg",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/genopets",
                "website": "https://mainframe.genopets.me/"
            }
        },
        {
            "chainId": 101,
            "address": "FPwHqVqAg9AhUccxUZMHpxdw5YT2SVNa3A67P8CK1aX7",
            "symbol": "BOI",
            "name": "BoiKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/akhelbey/negro/main/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "description": "The Coin Of Dat Boi"
            }
        },
        {
            "chainId": 101,
            "address": "46BvKPBHA3k32dvYWUtCxAzQrxT1BCNkYw5PGL9cwvVL",
            "symbol": "MT",
            "name": "Tamim",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tamimKTH/Tamim-MT-Crypto-Token/main/Screenshot_2022-04-06_at_18.12.02_50.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Frb3DbBKeja7SwhevYaK7x6zxJRnjH6KF6ciVHBSoNu5",
            "symbol": "NCC-WL",
            "name": "NCC-GEN1-WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Frb3DbBKeja7SwhevYaK7x6zxJRnjH6KF6ciVHBSoNu5/NCCWL.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/P8hc8npWRt",
                "twitter": "https://twitter.com/NeonCloudsNFT",
                "website": "https://neonclouds.net/"
            }
        },
        {
            "chainId": 101,
            "address": "3GoDU5se7WRLM9vYMLkDEncafZVhi4swyHaqA6bAG9Fd",
            "symbol": "KALA",
            "name": "Kalauila",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GoDU5se7WRLM9vYMLkDEncafZVhi4swyHaqA6bAG9Fd/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://kalauila.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3LWsCFHgxKGZ8Wkjo6J4dAr9QpcTA28N5RLWh4WV68XX",
            "symbol": "ORNT",
            "name": "Orient",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tunnelstudio/orient/main/logo.png",
            "tags": [
                "Token",
                "Exchange",
                "Freedom"
            ]
        },
        {
            "chainId": 101,
            "address": "DfgYfvfW8cWumofEgRZsAYHhZVDgQbu9z8sGwcKahSho",
            "symbol": "CARTEL",
            "name": "Cartoon Cartel Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ArthurPaivaT/token-list/main/assets/mainnet/DfgYfvfW8cWumofEgRZsAYHhZVDgQbu9z8sGwcKahSho/logo.png"
        },
        {
            "chainId": 101,
            "address": "4sUzhG72Y8qHAmUZBM8hiTWjCU5j3pZgkRztJGLRmwGW",
            "symbol": "TMA",
            "name": "TAIMA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Sanidelavega/crypto/main/taima-584.png",
            "extensions": {
                "twitter": "https://twitter.com/OffTaima",
                "website": "https://thetaima.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ARcfwUzhzmmc5zqSNY8aSVM2cZBB1jZarDLTemsLhMrY",
            "symbol": "BROCCO",
            "name": "Broccolini",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ForkInSpace/broccolini/main/broccolini.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9o55qL5tASRmkjysjKX8zefYpRNfC1YDy6g12M84UEHt",
            "symbol": "EOR",
            "name": "Evolve Our Race",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nedmars27/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CBgExXTC91pz36WxoPSbZDs37DECFDeAMTUbzjhtu9AK",
            "symbol": "DUC",
            "name": "DayUpCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBgExXTC91pz36WxoPSbZDs37DECFDeAMTUbzjhtu9AK/wdp.png",
            "tags": [
                "wdp-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C65amj9JtuuuuQgxwgEdqRMzmUG4jMM7CY5jjQ5nqtL9",
            "symbol": "SACT",
            "name": "Smoker Apes Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C65amj9JtuuuuQgxwgEdqRMzmUG4jMM7CY5jjQ5nqtL9/logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "eraDnpnbm1JfpYUdu164qhuLetPMXEQUUPyB8ZRSriK",
            "symbol": "$ERA",
            "name": "Era",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/eraDnpnbm1JfpYUdu164qhuLetPMXEQUUPyB8ZRSriK/logo.png",
            "tags": [
                "utility-token",
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ageofdefinft",
                "website": "https://www.ageofdefi.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "4q4DTq8cf1gvrRZkgZQ2WkCgALTbm3H7Poa34qhgwEqo",
            "symbol": "SWH",
            "name": "SOLWOW HOTEL TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/husseinalkanani/assets/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4eFU1TAigNZtv4nyDcAsUTZfUUgK6bTdpFSQEgn2H2u6",
            "symbol": "SBOX",
            "name": "Silver box whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JohnnyGoodpick/my-token-repo/main/boxnfttoken.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "GoombqvaLTaLbN3LHuEkqeLBTmwAScRw8DViERXeEFo4",
            "symbol": "GFHWL",
            "name": "Goomies WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Neurologist/assets/main/GFHWL_Token.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3esRcn5KEFVkUrW7pz6xV2mmzhrc2ddjYHP1V7FR2jpj",
            "symbol": "PITF",
            "name": "Pay IT Forward Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3esRcn5KEFVkUrW7pz6xV2mmzhrc2ddjYHP1V7FR2jpj/logo.png",
            "tags": [
                "stablecoin",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DwC83VkKYbqChRtxr14apQtQGYeuoicuvbqCirxyY8kQ",
            "symbol": "HBLM",
            "name": "Hope Blooms",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/matt-cooper-github/hope-blooms/main/Hope%20Blooms%20Icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HNqdtbrRmojH1AcxPwguExzWJ7sCxsZB6skDBZCVRRja",
            "symbol": "BZ",
            "name": "Test BZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3esRcn5KEFVkUrW7pz6xV2mmzhrc2ddjYHP1V7FR2jpj/logo.png",
            "tags": [
                "no-tag",
                "test-bz"
            ]
        },
        {
            "chainId": 101,
            "address": "FAogUQVmijxSQdTiNAV1rrHcqmPRdZYZeFDPd8NsVj2m",
            "symbol": "FELLOWOG",
            "name": "FELLOW APES OG TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FAogUQVmijxSQdTiNAV1rrHcqmPRdZYZeFDPd8NsVj2m/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fellowapes",
                "twitter": "https://twitter.com/FellowApes",
                "website": "https://www.fellowapes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FAWLwPYG4BSTkwnZY6VLTYQeFTYyLFGkwWSzjrU9LZZc",
            "symbol": "FELLOWWL",
            "name": "FELLOW APES WHITELIST TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FAWLwPYG4BSTkwnZY6VLTYQeFTYyLFGkwWSzjrU9LZZc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fellowapes",
                "twitter": "https://twitter.com/FellowApes",
                "website": "https://www.fellowapes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EUfnMfMhx8ZwdYFuDoSZbEdVjsfiGkHEE3Eb7U9hXzhd",
            "symbol": "SLCFE",
            "name": "SOUL CAFE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EUfnMfMhx8ZwdYFuDoSZbEdVjsfiGkHEE3Eb7U9hXzhd/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/fazleedotsol",
                "website": "https://soulcafe.vercel.app/"
            }
        },
        {
            "chainId": 101,
            "address": "9yP5r8DPtPj4o9dHjxCVt6PYBusWCRg6yFpXNN59LUmt",
            "symbol": "$THUMB",
            "name": "THUMB WARS TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9yP5r8DPtPj4o9dHjxCVt6PYBusWCRg6yFpXNN59LUmt/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/thumbwars",
                "instagram": "https://instagram.com/thumbwarsnft",
                "twitter": "https://twitter.com/ThumbWarsNFT",
                "website": "https://www.thumbwars.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9C2Ht3Cd8uNKsxiLQ27qxv9mnQ1iqMPFW2FUwB9DkxFP",
            "symbol": "DUBZ",
            "name": "DUBBIEZZ",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/103150881/162079915-03e783ea-6fd3-4572-893e-30a55cd7f05a.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FDWmQxD9hQruYKtFK8wt6UhKGv6frPvU8EhjKiEPQoyD",
            "symbol": "FADE",
            "name": "FADE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/yazidox/fadedfoxlogo/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FadedFoxess",
                "website": "https://www.fadedfoxes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "58iNDddjnbt1sncnAGDJvAGjKKQt4rQRMo22JHzZRg5L",
            "symbol": "BSCN",
            "name": "BScoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58iNDddjnbt1sncnAGDJvAGjKKQt4rQRMo22JHzZRg5L/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "mMUZw4w23XSxvqdJAkYjoBFzadzcutFz3DaKMxwSvVh",
            "symbol": "CHIBBBB",
            "name": "Chibb token test",
            "decimals": 6,
            "logoURI": "https://github.com/CoderDenx/images/blob/main/Chibreward.png"
        },
        {
            "chainId": 101,
            "address": "7bGxwtDyr9b5Hp2wk5Usvc1i5GFADXy5XaoM6tZQRnkP",
            "symbol": "AAA",
            "name": "AAA",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solalchemist/mymetaplex/master/AAA...png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "medium": "https://alienants.medium.com",
                "twitter": "https://twitter.com/alienantsgen1",
                "website": "https://alienants.gitbook.io"
            }
        },
        {
            "chainId": 101,
            "address": "Ecqbht7deTxwQARtyAi9jMMNxoEf6hBMvBre9FRrpzkU",
            "symbol": "T2pT",
            "name": "Thai2P Token",
            "decimals": 9,
            "logoURI": "https://gcdnb.pbrd.co/images/kGrY4Tguwcou.png?o=1",
            "tags": [
                "utility-token",
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8dpSeVdxWdfRzrAUTddRY12X1KVutXnYKjneap9RsZZ8",
            "symbol": "BZCN",
            "name": "BearzCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8dpSeVdxWdfRzrAUTddRY12X1KVutXnYKjneap9RsZZ8/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Bug_Bearz",
                "website": "https://bugbearz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7fRnceu5d3EsCvmJ6TZj78emeqkyG2E9jQRRnFSRhxQ5",
            "symbol": "POPE",
            "name": "Pope Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pope-dope/crypto/main/pope_dope.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PopeDopeCoin"
            }
        },
        {
            "chainId": 101,
            "address": "B6gUBdUVKG1JooSdASD8QMPuphbMpLt5ZwGtrHS8MfoP",
            "symbol": "MP2P",
            "name": "Molana Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B6gUBdUVKG1JooSdASD8QMPuphbMpLt5ZwGtrHS8MfoP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://molana.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "r4bCUA5kivHegREZ9CFXPBh7Xr9RDpdG92pR8pM8eCa",
            "symbol": "ANTL",
            "name": "Anatoly the Cat",
            "decimals": 9,
            "logoURI": "https://anatolythecat.com/icons/token/token.png",
            "tags": [
                "entertainment",
                "gaming",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/58HVXZRPBt",
                "github": "https://github.com/anatolythecat",
                "twitter": "https://twitter.com/anatoly_the_cat",
                "website": "https://anatolythecat.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6HUvsVDECatJaAiQ7bY26TUKaDoyekuovJ1j1oyvRYQS",
            "symbol": "TMUWL",
            "name": "TMU V1 Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6HUvsVDECatJaAiQ7bY26TUKaDoyekuovJ1j1oyvRYQS/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://www.themonkeyuniverse.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5iEgPvFyVMyiR5iecrWSg7rRfXp2iTiUDzKRne9S9b4X",
            "symbol": "CENT",
            "name": "PowerStreetPro Token",
            "decimals": 6,
            "logoURI": "https://github.com/sethdford/powerstreetpro-ui-v1/blob/main/public/assets/icons/cent.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://github.com/sethdford"
            }
        },
        {
            "chainId": 101,
            "address": "8pVgjaB32pij37e7inDMcHEtXt2rqp2pD5ZZaVuecHHH",
            "symbol": "APR",
            "name": "Aphrodite Coin",
            "decimals": 9,
            "logoURI": "https://demo365.workdemo.xyz/logo_coin.png",
            "tags": [
                "Metaverse-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "FJh9YCSLKmkVW2rSg9BGDdNQviFGQqxgoDEtbphyQWBR",
            "symbol": "MOX",
            "name": "MonsterX",
            "decimals": 9,
            "logoURI": "https://monsterxblob.blob.core.windows.net/moxtokeninfo/Tokenlogos.png",
            "extensions": {
                "twitter": "https://twitter.com/MonsterX_NFT",
                "website": "https://www.monsterx.io/"
            }
        },
        {
            "chainId": 103,
            "address": "ADDrELSQ9wx78xaNhqgZA3uDhmz4fkV4K6TChUVecpW9",
            "symbol": "CIA",
            "name": "Savvy Investors Club Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADDrELSQ9wx78xaNhqgZA3uDhmz4fkV4K6TChUVecpW9/logocia.png",
            "extensions": {
                "facebook": "https://www.facebook.com/clubdesinvestisseursavises"
            }
        },
        {
            "chainId": 101,
            "address": "EcyhULAJyT3Ng6J5uxM6zKMiLwUThTp2Lmn5MnKapCNs",
            "symbol": "TCMK",
            "name": "TechMagik Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stavrozo/TechMagik-crypto/main/tmlogo.png",
            "tags": [
                "social-token",
                "tech-token",
                "community-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4k9g62bc6iMRCb1hGaW2v1YjQfMci7hqbSVWKoaePxhF",
            "symbol": "VOXM",
            "name": "VOXM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/voxelmonkey/VOX/main/VOX.png",
            "tags": [
                "utility-token",
                "general-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7j9nBTTLrQAe1p9Sfz9y323PaGtQWexw3aeN7RnU6tmy",
            "symbol": "SER",
            "name": "SolSer",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Persnickety888/SolSer/main/%24SER.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "chin1S2RorBVLj1RCovBY4ascM5KZ7wtBGrL1RzEK7Z",
            "symbol": "CIHX",
            "name": "Chinmon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/umberto12345/chin-slp-token/main/log.png"
        },
        {
            "chainId": 101,
            "address": "9gHwP8wZz5v2M8yN1HUm3fahF8hopciiDJgTRzkEGqjY",
            "symbol": "ATC",
            "name": "AtlasCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gHwP8wZz5v2M8yN1HUm3fahF8hopciiDJgTRzkEGqjY/logo.png"
        },
        {
            "chainId": 103,
            "address": "HHZVdeyMbjvT9eGBsZtzb16TXQ5wLCVhWzWPBzcWmovC",
            "symbol": "NEA",
            "name": "NEA MDE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/arendon7/NEA-COIN/main/logo.jpeg"
        },
        {
            "chainId": 101,
            "address": "5ngWokdDMmvgYJi1nm9vU7Wb2cvT5yStiwo5a1qZbt6S",
            "symbol": "TOBS",
            "name": "Monstertobs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/monstertobs/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW",
            "symbol": "GLXY",
            "name": "Astrals GLXY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/astralsnft",
                "twitter": "https://twitter.com/Astrals_NFT",
                "website": "https://astralsnft.io"
            }
        },
        {
            "chainId": 101,
            "address": "EWhrR6eMQNS9gFkCocG9PfgNXXqyDjK5VkQVMsHnmYR7",
            "symbol": "TEK",
            "name": "tequila",
            "decimals": 9,
            "logoURI": "https://github.com/cryptoguy55a/solana-staking-contract/blob/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "DKzkt1r6QctnQFx5hMnGomcMimXqVfAyBhMcoKHcBiNK",
            "symbol": "ROUNDIE",
            "name": "ROUNDIE Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DKzkt1r6QctnQFx5hMnGomcMimXqVfAyBhMcoKHcBiNK/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.theroundies.co"
            }
        },
        {
            "chainId": 101,
            "address": "3EdaYamSyMNNGbEPEtCdZM9CrsKzP8i3RoYJ7KKRQ4CM",
            "symbol": "FRIDA",
            "name": "Frida Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/08b4b789cd2061eb439bdc1c3cdeed5590b773c0/assets/mainnet/3EdaYamSyMNNGbEPEtCdZM9CrsKzP8i3RoYJ7KKRQ4CM/logo.png"
        },
        {
            "chainId": 101,
            "address": "Ch4SJ2YwHU7nXC1rv3eja7rP7ZuLM9x1EHGt53RETHnd",
            "symbol": "CHRMS",
            "name": "Clubhouse Dao",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/hallelujahsunshine/assets/main/clubhouse-music-school-icon-black.png"
        },
        {
            "chainId": 101,
            "address": "2hyRbCMr8ZuYN99MnY1PKRCk55stWw1zYHnChTSjFM44",
            "symbol": "VERB",
            "name": "Verb",
            "decimals": 2,
            "logoURI": "https://i.ibb.co/H7BB1VL/VERB-Logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "86aFUzjnSNr3DHTYBBFWoogNfvBCfWkSVYWtjJAWGcCH",
            "symbol": "YAM",
            "name": "YAM Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/metasal1/token-list/1663005b372d43fb1b257bd3064d612fccb6f56c/assets/mainnet/86aFUzjnSNr3DHTYBBFWoogNfvBCfWkSVYWtjJAWGcCH/logo.png",
            "tags": [
                "yam-token"
            ],
            "extensions": {
                "website": "https://www.yamdao.com"
            }
        },
        {
            "chainId": 101,
            "address": "AFtvjpKky4TkErQS6SwMXrX7Mwt41etbfMVM2pampqmy",
            "symbol": "DEAD",
            "name": "deadWonder",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RobertVarney/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EFULmcHqDaskCWzTi81eXyBu9oJtuE3BCnhkM81xq4C2",
            "symbol": "HOODIE",
            "name": "HOODIE BOYS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CryptArt-LLC/444-hood/01bcd4ac4cdc72d3e31173d64dfe68b9a4b6176d/logo.png",
            "tags": [
                "community-token",
                "444-dao-token",
                "444-hoodie-boys"
            ],
            "extensions": {
                "discord": "https://discord.gg/D8bz4mQnNp",
                "twitter": "https://twitter.com/444hoodieboys",
                "website": "https://www.444dao.org/"
            }
        },
        {
            "chainId": 101,
            "address": "4Q3uvtM4Y87dYgYuLkgwLGnuHM7zevgYBm7rD4jB5wWy",
            "symbol": "CHCPS",
            "name": "Chibben Chips token",
            "decimals": 6,
            "logoURI": "https://github.com/CoderDenx/images/blob/main/Chibb%20N%20Chips.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "23K7zGTssP5sDuaVYE9Byits82whseiAHajj5ycJZTFV",
            "symbol": "KWKN",
            "name": "KawaiKoin",
            "decimals": 0,
            "logoURI": "https://github.com/AsTheSeaRises/crypto-stuff/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3pidV843JWztwbRsU183M7GmW6U33o7aDbrSX9LnfVFu",
            "symbol": "ONLY",
            "name": "OnlyFans Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Onlyfanscoin/only/main/Only2%20%202.png",
            "extensions": {
                "twitter": "https://twitter.com/onlyfans_coin",
                "website": "https://onlyfans.com"
            }
        },
        {
            "chainId": 101,
            "address": "AwgoZr72bzfwkZmcCy7nAHskpn3Ha1jAjE67nFP9HMEk",
            "symbol": "BPS",
            "name": "BluePay SHARES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/blueshares/tokenshare/main/logo.png",
            "tags": [
                "shares-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DeciAAiFDriVGkpqAgGDxpBC5SA4cBPrBWUj4WuevgTR",
            "symbol": "DD",
            "name": "Decimus Dynamic Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DeciAAiFDriVGkpqAgGDxpBC5SA4cBPrBWUj4WuevgTR/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/decimusdynamic"
            }
        },
        {
            "chainId": 101,
            "address": "iVMyPF9DBoPxxfhGA58rfYt9HU1JHw56S7YdryZEx96",
            "symbol": "IVM",
            "name": "Investmor Coin",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iVMyPF9DBoPxxfhGA58rfYt9HU1JHw56S7YdryZEx96/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/investmorcoin"
            }
        },
        {
            "chainId": 101,
            "address": "2UePEeCM1Zqt9xajqCaD2ZmrzKGB3d88VXfu4hiZw8is",
            "symbol": "CKWL",
            "name": "Cool Kitties WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2UePEeCM1Zqt9xajqCaD2ZmrzKGB3d88VXfu4hiZw8is/icon.png"
        },
        {
            "chainId": 101,
            "address": "2dfUDFaYGy7Kkp2TiUXHsY9myq2Ws9hjSyb5pPDRVEzY",
            "symbol": "MTKN",
            "name": "Mighty Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2dfUDFaYGy7Kkp2TiUXHsY9myq2Ws9hjSyb5pPDRVEzY/logo.png",
            "tags": [
                "Gaming",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/mightyminions",
                "twitter": "https://twitter.com/mightyminions",
                "website": "https://www.mightyminions.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GZc3D6VCj3AMUed2S8gP7QBvuWPNNB2asSwaXch7ESwJ",
            "symbol": "KOMR",
            "name": "KOMRADZ OFFICIAL WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZc3D6VCj3AMUed2S8gP7QBvuWPNNB2asSwaXch7ESwJ/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EB1dxxDbHSPxpjo5sUmszpcNk64gdAoxdttSq7fVBgGT",
            "symbol": "$RDP",
            "name": "RareDumpies Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/savage0908/RareDumpies-Token/RareDumpies-Token/XpRQAp06_400x400.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EvLyT6PC2UyRUZyhMjvZy1eey6E5jrYqq8fqqvSxD12K",
            "symbol": "UHU",
            "name": "Uhu Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EvLyT6PC2UyRUZyhMjvZy1eey6E5jrYqq8fqqvSxD12K/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://uhutoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EagLEdVKrm6QRfBQzZZjNBY9k3RjGXRhENL77QP7SnXj",
            "symbol": "EAGLEZ-TOKEN",
            "name": "EAGLEZ WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EagLEdVKrm6QRfBQzZZjNBY9k3RjGXRhENL77QP7SnXj/logo.png",
            "tags": [
                "Mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Deci6tiV9g8u2TJCpJcNqsgWFLr1oYooJwejjDRHszQ4",
            "symbol": "DD",
            "name": "DynamicDecimus WHITELIST TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Deci6tiV9g8u2TJCpJcNqsgWFLr1oYooJwejjDRHszQ4/logo.png",
            "tags": [
                "Mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HFE5CwhDzXLYrnfH41712be8Pz498v2yCjyR91jZEqpe",
            "symbol": "HUNKZ",
            "name": "HunkCoinz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HunkCoinz/Logo/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Csc8UDJkVQyuEXN6LpRAv8yZ5NTmEAdEkwrkyNALJo",
            "symbol": "DYLC",
            "name": "DylCo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/YHWH69/tokens/main/DylCo_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9o8j6rM2cUSwq6voyYuouJwyx73Hm5dTS5YQPGQ63Fq2",
            "symbol": "NTBR",
            "name": "NetworkBear Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/NetworkBear/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9372UehjSkhjSrBfujbivdvK8AFTzG96cvCezznLuZ5G",
            "symbol": "BBFY",
            "name": "Boss Baby Family Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/atenidegbeolanrewaju/BB_/main/BBFY.png"
        },
        {
            "chainId": 101,
            "address": "8SVTTQqcfSYiCzZTM3BgPP6XEwzU2j1NmxT5af8g5DFS",
            "symbol": "FALCO",
            "name": "FALCO Token",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmZMjHJrrQ3PWaUPhkcwRi8uYhcBn6r16EpYEWQBaWD9eX",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FLY4TDtDAW8pZLmEDMs4UEtchDwNAZrko79xEY6eSpLJ",
            "symbol": "FLY4",
            "name": "EagleZ FlyList Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLY4TDtDAW8pZLmEDMs4UEtchDwNAZrko79xEY6eSpLJ/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EaglezSOL"
            }
        },
        {
            "chainId": 101,
            "address": "DjEk3aEPX3388FtM2oVpzQQqi6N1efQ21vePpqtHwoTB",
            "symbol": "ITG",
            "name": "ITGnomies",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kaczordd/crypto/main/icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MatA8b1QRcrDSfNtqnPzkbmtq8RmKTw8iZydbMtxV6X",
            "symbol": "CHTKT",
            "name": "Chosen Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MatA8b1QRcrDSfNtqnPzkbmtq8RmKTw8iZydbMtxV6X/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/matukilabs",
                "website": "https://www.matukilabs.io"
            }
        },
        {
            "chainId": 101,
            "address": "95bzgMCtKw2dwaWufV9iZyu64DQo1eqw6QWnFMUSnsuF",
            "symbol": "LADA",
            "name": "LADA Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/95bzgMCtKw2dwaWufV9iZyu64DQo1eqw6QWnFMUSnsuF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3iTWURXxYJtEY5WHTDmA8qEbiDy6KRYU2DEdnrtrgGTS",
            "symbol": "SOLAD",
            "name": "SOLAD AIRDROP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3iTWURXxYJtEY5WHTDmA8qEbiDy6KRYU2DEdnrtrgGTS/logo.png"
        },
        {
            "chainId": 101,
            "address": "okayHk8cku6TyeRP8F1rfZramrJ93Y3wbWpqovacnkS",
            "symbol": "OKAYWL",
            "name": "Okay Bears Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img922/6216/HLXnVT.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/okaybears",
                "website": "https://www.okaybears.com/"
            }
        },
        {
            "chainId": 101,
            "address": "A4vmCTGepYqFT5CtkFgkjtsj7DUUBNAkkMB9G8q6dzKJ",
            "symbol": "SSB",
            "name": "SolanaSuccubus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A4vmCTGepYqFT5CtkFgkjtsj7DUUBNAkkMB9G8q6dzKJ/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/35WUZgCaXM",
                "twitter": "https://twitter.com/SolanaSuccubus",
                "website": "https://solanasuccubus.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5GSC5AqJQPhD3MmhNeYerGBzaJncH7MdFJGme8wTML9g",
            "symbol": "Aandelen",
            "name": "Jansen en Dochters BV - Monique Jansen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5GSC5AqJQPhD3MmhNeYerGBzaJncH7MdFJGme8wTML9g/blockPNG.png",
            "tags": [
                "Jansen-en-Dochters",
                "aandeelhouder",
                "Blockstone"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "website": "https://blocksto.ne/jansen-en-dochters-bv/"
            }
        },
        {
            "chainId": 101,
            "address": "4jSmgM6oatm6Ci3iEh5ksdJstSyvCV2FDJm7T8UGLszy",
            "symbol": "CAYC",
            "name": "Cyber Ape Yacht Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4jSmgM6oatm6Ci3iEh5ksdJstSyvCV2FDJm7T8UGLszy/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://cayc.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DfB1NY8Ftv3rDTnyffSVj5sr3ycFjLoUeNBEkTDvPQYn",
            "symbol": "ERRA",
            "name": "Erra",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DfB1NY8Ftv3rDTnyffSVj5sr3ycFjLoUeNBEkTDvPQYn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BBUiBwwG2pLZFboPpbvqVXACs4r3HrNBaC73zzXspfYW",
            "symbol": "FIYA",
            "name": "Fiya",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BBUiBwwG2pLZFboPpbvqVXACs4r3HrNBaC73zzXspfYW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "E5qNsCX91wqnLQQ25yEHTo3eujWGtqLe9daJvejRxikc",
            "symbol": "WADA",
            "name": "Wada",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5qNsCX91wqnLQQ25yEHTo3eujWGtqLe9daJvejRxikc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "93fFBeiW8Uy9X8evy9QTaxepheJ5aMeaMPNXgcfYPY4p",
            "symbol": "Aandelen",
            "name": "Jansen en Dochters BV - Sophie Jansen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/93fFBeiW8Uy9X8evy9QTaxepheJ5aMeaMPNXgcfYPY4p/blockPNG.png",
            "tags": [
                "Jansen-en-Dochters",
                "aandeelhouder",
                "Blockstone"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "website": "https://blocksto.ne/jansen-en-dochters/"
            }
        },
        {
            "chainId": 101,
            "address": "6Xz4ssMfNLAymMCDHfJonJUYHQhXZFtCdpmbz96o7JoF",
            "symbol": "USAA",
            "name": "UncleSamToken",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Xz4ssMfNLAymMCDHfJonJUYHQhXZFtCdpmbz96o7JoF/logo.png"
        },
        {
            "chainId": 101,
            "address": "DigVMJmcV9pozuQBkdYkzGvW293DXbFVMVAWLz1Fhmkd",
            "symbol": "RugWL",
            "name": "Rug Pass WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DigVMJmcV9pozuQBkdYkzGvW293DXbFVMVAWLz1Fhmkd/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/rugcity",
                "twitter": "https://twitter.com/probrug"
            }
        },
        {
            "chainId": 101,
            "address": "HhEpBeVBkWiurvrUtHYZnFLoLU7pbmy1MBQXuhUMvrtf",
            "symbol": "BAG",
            "name": "Baked Alien Gang",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EduardoNicoleit/token-list/main/assets/mainnet/HhEpBeVBkWiurvrUtHYZnFLoLU7pbmy1MBQXuhUMvrtf/logo.png"
        },
        {
            "chainId": 101,
            "address": "A6n5SnM2G8ZsTdpd85bUn5byvFAcEfqYHcMvQNNZ8tTR",
            "symbol": "WFCWT",
            "name": "Wulfs Frens Club Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A6n5SnM2G8ZsTdpd85bUn5byvFAcEfqYHcMvQNNZ8tTR/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wulffrensclub",
                "twitter": "https://twitter.com/WulfFrensClub",
                "website": "https://wulffrensclub.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "MkM7taZ6sV5UenNX1HcKFJifr4iN4vWwSevdXecrzBj",
            "symbol": "TSSWL",
            "name": "TSS WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MkM7taZ6sV5UenNX1HcKFJifr4iN4vWwSevdXecrzBj/logo512.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3PwanYatzwjyvrJ9LtysRPo7JhT2h1UvuN8P8v3EePLS",
            "symbol": "LMBD",
            "name": "LambdaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LambdaResearchFacility/crypto/main/lambda.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E36LX4XqYmuEzrWL9HH7oXh4iQ1G8GiRc4bvNzV6ZviN",
            "symbol": "2FUCKS",
            "name": "2FUCKS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E36LX4XqYmuEzrWL9HH7oXh4iQ1G8GiRc4bvNzV6ZviN/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "GIVE 2FUCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa",
            "symbol": "NIRV",
            "name": "NIRV",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa/NIRV.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nirvana_fi",
                "website": "https://nirvana.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Er3mhjpxcPhV4kvGS3a9DwQXT3w6kvoT9tjiC8ZmdYmr",
            "symbol": "BEAM",
            "name": "BEAMES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Er3mhjpxcPhV4kvGS3a9DwQXT3w6kvoT9tjiC8ZmdYmr/logo.png",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "THE CLAN",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "CReAmEhV7CCMaQ8YCEc23SAY2ARXnXZQX7M2AkR5LRqw",
            "symbol": "CREAM",
            "name": "Creamy Friend WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitcoinblack482/logo/main/creamy.png",
            "tags": [
                "NFT",
                "dao",
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/creamyfriends",
                "twitter": "https://twitter.com/CreamyFriends"
            }
        },
        {
            "chainId": 101,
            "address": "Gris8EnVQCXV7M5tDVYhb76GG8vcvPyzfKjctejrmFYh",
            "symbol": "DIGIT",
            "name": "DigIT-Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Ralecc/CryptoCoin/main/DigIT-Coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BTy9Ahn61nTz9VhFGStc4CaMkQD5x6v6GYWLWuVxZcSv",
            "symbol": "NPAY",
            "name": "NUBLOPAY",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/nublopayteam/noblopay/main/assets/logo/logo.png",
            "tags": [
                "utility-token",
                "dapp",
                "payments",
                "merchants"
            ],
            "extensions": {
                "description": "NUBLOPAY",
                "twitter": "https://twitter.com/nublopay",
                "website": "https://nublopay.com"
            }
        },
        {
            "chainId": 101,
            "address": "6oyeKEoxqU8KWa6EquV3gAJuYZuFKDj7sJas2K6Tm9Mo",
            "symbol": "MAS",
            "name": "PocketMas",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6oyeKEoxqU8KWa6EquV3gAJuYZuFKDj7sJas2K6Tm9Mo/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "PocketMas Pioneer",
                "website": "https://pocketmasa.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Wurx1CQEpuo8ExhWaMYrz9KErXBrKAdz64ZoRKSjuXy",
            "symbol": "WUR",
            "name": "Wrapped WUR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Wurx1CQEpuo8ExhWaMYrz9KErXBrKAdz64ZoRKSjuXy/wurtulla.png",
            "tags": [
                "wrapped",
                "stablecoin"
            ],
            "extensions": {
                "description": "Wrapped Wurtulla Coin",
                "discord": "https://discord.gg/wurtulla",
                "github": "https://github.com/wurtulla",
                "reddit": "https://www.reddit.com/r/wurtulla",
                "serumV3Usdc": "AMqKRL1M3rh83CZgvQquELCoZRasfr58ZiQVAQXZEerM",
                "twitter": "https://twitter.com/wurtulla",
                "website": "https://wurtulla.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3HDyDDvRTmSMa5QxhUod5gEgVKmTujncwKomxrWxSb8j",
            "symbol": "GFT",
            "name": "GFT Goofiez Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HDyDDvRTmSMa5QxhUod5gEgVKmTujncwKomxrWxSb8j/logo.png",
            "tags": [
                "GFT",
                "Goofiez"
            ],
            "extensions": {
                "website": "https://Goofiez.com"
            }
        },
        {
            "chainId": 101,
            "address": "H8LrRvCNaB5rS164NAQWapLAuMvxK1h7Y32Lhc9aXpES",
            "symbol": "LTBX",
            "name": "Prestige Free Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/W00BACKBABY/LTBX/main/YD5LgKoo.jpg",
            "tags": [
                "NFT",
                "dao",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kTxhrkBmmQ",
                "twitter": "https://twitter.com/GigaDAOio"
            }
        },
        {
            "chainId": 101,
            "address": "5rJ5WA5SfRJeT5ntoeCzCeqsk2gkfqY3tkNLdpyPrcnz",
            "symbol": "BSTCN",
            "name": "BoostCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/abskurz/crypto/main/LastFinalLogo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FxVJbQe6ngLfydDWCcGMJGpxrkkFBvHbyUVsB1ikSNjG",
            "symbol": "KOAL",
            "name": "SluggishKoalas Koal",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Cerebrum-Tech/koala/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "Token that is used to implement Sluggish Koalas Nft Project's utilities",
                "discord": "https://discord.gg/SHFyD7wXxU",
                "instagram": "https://instagram.com/sluggishkoalas",
                "twitter": "https://twitter.com/SluggishKoalas",
                "website": "https://www.sluggishkoalas.com/",
                "whitepaper": "https://sluggish-koalas.gitbook.io/sluggish-koalas-whitepaper/"
            }
        },
        {
            "chainId": 101,
            "address": "KUSHs1xRrv7U3jMUd8g3LjrEc4ZhUx9EBm7Yc6cgCsL",
            "symbol": "$KUSH",
            "name": "KUSH COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUSHs1xRrv7U3jMUd8g3LjrEc4ZhUx9EBm7Yc6cgCsL/logo.png"
        },
        {
            "chainId": 101,
            "address": "Fg8u9R56EStQVshdN5K36w9tmdTjQiS9bGzigE5rNKzs",
            "symbol": "BIKE",
            "name": "DUCATI Monster",
            "decimals": 9,
            "logoURI": "https://imgur.com/x7n7mko.png"
        },
        {
            "chainId": 101,
            "address": "buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW",
            "symbol": "NNI",
            "name": "NeoNomad",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW/logo.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Fj77EYcTNH",
                "twitter": "https://twitter.com/NeoNomadFinance",
                "website": "https://app.neonomad.exchange"
            }
        },
        {
            "chainId": 101,
            "address": "7ke3VBsNshCTH9q8uDHcbMjMskWrZrbDGW4ZCZy9aMPK",
            "symbol": "RKIKE",
            "name": "Rogne Kik Etc",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ke3VBsNshCTH9q8uDHcbMjMskWrZrbDGW4ZCZy9aMPK/logo.png",
            "tags": [
                "RKIKE",
                "Rogne",
                "Kik",
                "Rir"
            ]
        },
        {
            "chainId": 101,
            "address": "4dnqPbYSCRSwe2hsAxfPwfRhsMkkYsU2GF2DgNRvqFaw",
            "symbol": "Aandelen",
            "name": "Jansen en Dochters BV - Brigitte Jansen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dnqPbYSCRSwe2hsAxfPwfRhsMkkYsU2GF2DgNRvqFaw/blockPNG.png",
            "tags": [
                "Jansen-en-Dochters",
                "aandeelhouder",
                "Blockstone"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "website": "https://blocksto.ne/jansen-en-dochters-bv/"
            }
        },
        {
            "chainId": 101,
            "address": "unknXbA1bDg39nuBqVgMNZ5qSZa8pw5HditgkPe5eFA",
            "symbol": "UNKN",
            "name": "UNKN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/unknXbA1bDg39nuBqVgMNZ5qSZa8pw5HditgkPe5eFA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/celestialz",
                "twitter": "https://twitter.com/CELESTIALZ777",
                "website": "https://www.celestialznft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5emjcEHtUJrABN23tE9sRY9YEj8rjXXPgkoD4dbKecuD",
            "symbol": "YOAV",
            "name": "Yoav Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5emjcEHtUJrABN23tE9sRY9YEj8rjXXPgkoD4dbKecuD/logo.png"
        },
        {
            "chainId": 101,
            "address": "2g6H7aqMxsETTUoC5acFYMNX2Zk3y4ahQHzvU5spKavC",
            "symbol": "VOLTAGE",
            "name": "Voltage Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2g6H7aqMxsETTUoC5acFYMNX2Zk3y4ahQHzvU5spKavC/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/hvworldsnft",
                "twitter": "https://twitter.com/hvWorldsNFT_",
                "website": "https://www.highvoltageworlds.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8X4oCMuknAPTV3ofBo7676HfcnGGHocJXSTCgMb97ZzK",
            "symbol": "CIPH",
            "name": "2BitCipher",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8X4oCMuknAPTV3ofBo7676HfcnGGHocJXSTCgMb97ZzK/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7CgtGTKtHTCrWpc5XJM8n8bVrRoA1LXEZrv8JiGWLPaJ",
            "symbol": "DSRPT",
            "name": "Disruptor coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ajithkotian/disruptorcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HmNpBohAJ4Zu5dLuAqnkyhe78ekbG2534ZLQqA9fRqxS",
            "symbol": "kcCSOL",
            "name": "Katana CSOL Covered Call Vault Share",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/HmNpBohAJ4Zu5dLuAqnkyhe78ekbG2534ZLQqA9fRqxS/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "C1v7cf8LCkkFfVycZVnSDZRNuHUUvZqCTZpKg2Cw6G35",
            "symbol": "kcWINJ",
            "name": "Katana WINJ Covered Call Vault Share",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/C1v7cf8LCkkFfVycZVnSDZRNuHUUvZqCTZpKg2Cw6G35/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "3bQnc6nZoS2ZpNfCWDezhResXcWV5Lm41wn4xtRtWyG1",
            "symbol": "kpETH",
            "name": "Katana USDC-ETH Put-Selling Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/3bQnc6nZoS2ZpNfCWDezhResXcWV5Lm41wn4xtRtWyG1/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "7JLmQij2A159HjKXrSDTQYUik83v2qc8VfsNDpLBuwRY",
            "symbol": "kpBTC",
            "name": "Katana USDC-BTC Put-Selling Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/7JLmQij2A159HjKXrSDTQYUik83v2qc8VfsNDpLBuwRY/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "GMGNQYumwEbwKKzEeoEuBKhHRvdepAc8cpVzavM3ptQY",
            "symbol": "kpSOL",
            "name": "Katana USDC-SOL Put-Selling Vault Share",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Katana-Labs/assets/master/token-list/GMGNQYumwEbwKKzEeoEuBKhHRvdepAc8cpVzavM3ptQY/logo.png",
            "tags": [
                "Katana",
                "share-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/katana-hq",
                "github": "https://github.com/Katana-Labs",
                "twitter": "https://twitter.com/Katana_HQ",
                "website": "https://app.katana.so"
            }
        },
        {
            "chainId": 101,
            "address": "GwGk3tXBJDrc7aWJ1XW1ttwboRR359RbprAXGmGDxwSK",
            "symbol": "SWAMP",
            "name": "SWAMP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/DEAN3lr/Skater-Gators/main/Skater%20gator%20image.png",
            "tags": [
                "utility-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/skater_gators"
            }
        },
        {
            "chainId": 101,
            "address": "94noSErv2SUMVcYhCVERbf8Pvx1ohzjYQjeBaZuHMh2e",
            "symbol": "GRC",
            "name": "GraceCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MariBarbu/Cripto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FdqAZGcJtjoCpzURxz6SGXt2KFTeE3qtP8Uj1QTTWE5Y",
            "symbol": "DUFF",
            "name": "DuffelToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MetroMcD/crypto/main/DUFF.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8J7Cir9SmRiRQfkN6QoE1kL354i3uNoyQkngqkg18i79",
            "symbol": "CTZNS",
            "name": "777CTZNS WL TOKEN",
            "decimals": 0,
            "logoURI": "https://777ctzns.com/777.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "APERe98wcFuzQcHkX5aagTEZn2Vm8EAFE4PWBYzUT8d7",
            "symbol": "APER",
            "name": "Aperide",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APERe98wcFuzQcHkX5aagTEZn2Vm8EAFE4PWBYzUT8d7/logo.png",
            "tags": [
                "utility-token",
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/J2jNTFv2PY",
                "twitter": "https://twitter.com/AperideFi",
                "website": "https://aperidefi.com"
            }
        },
        {
            "chainId": 101,
            "address": "BaPYshAdxSE7tUDqbC3cy4P2T3dcRA1ZXw4haQuv5doZ",
            "symbol": "SIP",
            "name": "SIP Coin ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jdraplin/crypto/main/sip-logo-2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A2xFUCsF5nnFMRJqJQNnQTcXzT1AJuv4xofVc4zEyrRp",
            "symbol": "SRC",
            "name": "Sinny Redemption Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2xFUCsF5nnFMRJqJQNnQTcXzT1AJuv4xofVc4zEyrRp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gsviw3EGfYY4a5U3TsE27PCmqHY3D4Tkt39avTeXU7Ya",
            "symbol": "JELL",
            "name": "JellyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Man-in-Black/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9F7zmuj6MsV6JnHreQhRPmzsZ6nF2Fh4nyYWyREWtKRu",
            "symbol": "OERO",
            "name": "OpenEDU Romania",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/neckroRO/OpenEdu-Romania/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "hhoXt8PDi9VpsxyMB4sdoRKVPx9adJ8mq4BVwnjwUck",
            "symbol": "RZ",
            "name": "ReaperZ Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Yosora-sol/Degen/main/KingReaper.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/saP2ygvusx",
                "twitter": "https://twitter.com/DegenReaperz",
                "website": "https://degenreaperz.vercel.app/"
            }
        },
        {
            "chainId": 101,
            "address": "9EKEh1CHMKmyvBTY6qYZm7kgRJE18tCbaY1ZbpdELbVr",
            "symbol": "BULLS",
            "name": "Bull Solana",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/BullSolana/assets/main/Logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DpGrbHz2ZZFayBspSvmmLqsit3377yT9qu4WDr1roBTJ",
            "symbol": "NOCH/USDT",
            "name": "Cropper LP token (NOCH/USDT)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AL5Lk4U9Y77P1zGB1ngGfkWztvr38SsEWC7uGTE881PY",
            "symbol": "ZDRT/USDC",
            "name": "Cropper LP token (ZDRT/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7ajE3K37GGj9zY1MUbq4QFLhbiL3yY5F7saxhZZ5ZQbp",
            "symbol": "MEND/USDC",
            "name": "Cropper LP token (MEND/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "D6SXaSdQpgK9DQSRRcnrT6apdULyUarG34txTYAKupAN",
            "symbol": "FBZ/USDC",
            "name": "Cropper LP token (FBZ/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "HyFEEwqnw3o3MJpED7k3tQm11tyFg5Qd2bUJRXUQ3Bpk",
            "symbol": "PAPPA/USDC",
            "name": "Cropper LP token (PAPPA/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "3kgvCJopjiDHZk92HgE6JX9GbzDHNjKHBfSDdUBq8d7u",
            "symbol": "SIM/USDC",
            "name": "Cropper LP token (SIM/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "69zsVuq3RA4ZSBqtyWdmcuvMYfYRTFKaKYp8xKX8djUW",
            "symbol": "KING/USDC",
            "name": "Cropper LP token (KING/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "FCbwFaX6zXA3BmJBW4JhPAahTn2b8ve6fRMYjmigv4Gw",
            "symbol": "ZBC/USDC",
            "name": "Cropper LP token (ZBC/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "8ahZSvvxRZcs8VK7rvyhVVats8ER1Uhd5FKuUF3pqJ8C",
            "symbol": "ISOL/USDC",
            "name": "Cropper LP token (ISOL/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "3e1GzfcfAPs2J2VjtyNwzdUg1TRWhw28RzmZ5wDZ3ajb",
            "symbol": "Paws/USDC",
            "name": "Cropper LP token (Paws/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4LaxTpMRBBnetws6WGwNad28B8C58brcEDFuNwSUT2sT",
            "symbol": "SOLR/USDC",
            "name": "Cropper LP token (SOLR/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "DXHw3bPzuonXJ6TVUugw65Y9nPDJsS3jcHfVvLDk6WKd",
            "symbol": "PUSSY/USDC",
            "name": "Cropper LP token (PUSSY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4azyRH1JYia3mbZAvcNcVvd9QaxSEziJECyS4EekCi8Z",
            "symbol": "SAMO/SOL",
            "name": "Cropper LP token (SAMO/SOL)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "6p5FXaUsWLRKnyUDUNNBuQX8sBxt2wwf4C5F3ezSF4kE",
            "symbol": "GALAXY/USDC",
            "name": "Cropper LP token (GALAXY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "EVghtk86iV2aTTTTnefaSPCmAdJhwp15fKwF2Qc38iJD",
            "symbol": "INU/USDC",
            "name": "Cropper LP token (INU/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "BVUQwnVN8wne5z1bp3J8c4Vxy6WSRUqfy3DZt2SWRmar",
            "symbol": "BSAMO/USDC",
            "name": "Cropper LP token (BSAMO/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "C9rKsBeZ4hN2LZPak8iD68TzqBLsH9apvDobyDdRBZLt",
            "symbol": "BULL/USDC",
            "name": "Cropper LP token (BULL/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "2CeA2kwbksBsaq864MoWLMgWY1L6LfNMiUqgFa4m8nXc",
            "symbol": "TOX/USDC",
            "name": "Cropper LP token (TOX/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "83GiNfh748ErQhUR8nu1pyMBogxkEGC5Vv2HHswpwazi",
            "symbol": "SAMOY/USDC",
            "name": "Cropper LP token (SAMOY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "7pGw7vJx3c4RhmrNWi7G49czNhcyvvR7yD9LTTsi9ZVW",
            "symbol": "DOGETH/USDC",
            "name": "Cropper LP token (DOGETH/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "3oa14Mcu1syrReMTZ9EuVrrdDexYW6bGUwcH1CwanAUj",
            "symbol": "WAGMI/USDC",
            "name": "Cropper LP token (WAGMI/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Ek8hKQ27JTC6HE2NYcyCAQcMUzxxYjqmugLwt61jpbzL",
            "symbol": "KITTY/USDC",
            "name": "Cropper LP token (KITTY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9u8Dxsxvx6e5JvK3HLqKZtxnVs3XUmxNMKY7eirsFm6x",
            "symbol": "FUM/USDT",
            "name": "Cropper LP token (FUM/USDT)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "6WmvhWXQH5BgqoLNmEfytd12dAdgA7PFTgFv9ho9nQ72",
            "symbol": "DATE/USDC",
            "name": "Cropper LP token (DATE/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "FREaJRk8mUbK7Vc1LMUw9rdtpLAu9g4qk4Yq9rU9wWP8",
            "symbol": "NRA/USDC",
            "name": "Cropper LP token (NRA/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "AaZ5cCNVpSHMg386qZz8wBAQJcEqJnW8eWM7RHgUy3mM",
            "symbol": "CRY/USDC",
            "name": "Cropper LP token (CRY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "EdWqqUz56Jo9QvLqCTg8Q1PR7boC8xMVraAEQqnWgdGZ",
            "symbol": "SAMU/USDC",
            "name": "Cropper LP token (SAMU/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "22obGTGPjKFrXcSAGYbecJAicrhJQvMgdvXjVcY8ZYiw",
            "symbol": "STR/USDC",
            "name": "Cropper LP token (STR/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5HMfLQpR9BKfLfT6PVZVrMuRJNuYDKGHAoD2UJksnGkk",
            "symbol": "PANDA/USDC",
            "name": "Cropper LP token (PANDA/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "CH7ZBJNnJRsUXUK8j2SQhoZbvU4mLWX1M6T98rUejwYT",
            "symbol": "wCAPS_v1/USDC",
            "name": "Cropper LP token (wCAPS_v1/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "dSvuy2L9aa8bPu5MV756Fsb6GmzDwtnaGXVRrNeRigU",
            "symbol": "BABY/USDC",
            "name": "Cropper LP token (BABY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "FsAZn4KUQ7BeELdu7sqfDPn7g22vnNR4HcL4KQVzMBPh",
            "symbol": "CRP/USDT",
            "name": "Cropper LP token (CRP/USDT)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9jY6sYGU5eEs1cL6sv3jHJWxvLyYq5TvYErQFPCRTrHv",
            "symbol": "DoggyStyle/USDC",
            "name": "Cropper LP token (DoggyStyle/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5CGnktbzR3xeyzmk29avbPnN23vCLSnjCXh9Wqvmo5r1",
            "symbol": "SOLBERRY/USDC",
            "name": "Cropper LP token (SOLBERRY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5LRHM9qWDPTEh1LomBy1rd3unb6LXx2Y21WU3RvhRg51",
            "symbol": "SDOGE/USDC",
            "name": "Cropper LP token (SDOGE/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "EuceaH1hj8dixGkNiizRPhrvS57zVX14gij934SZbm4M",
            "symbol": "FTT/CRP",
            "name": "Cropper LP token (FTT/CRP)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "HeHNaTF5s68fMocqaHy6rHphbRXci34QetKSPHiCYFiG",
            "symbol": "NAXAR/USDC",
            "name": "Cropper LP token (NAXAR/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "9Wwb5XSp2sBxTnL3z1J9AJCnDNMvNbg3H32FoC7B7EUb",
            "symbol": "RAY/CRP",
            "name": "Cropper LP token (RAY/CRP)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4sdKddtuMsgX1rQUzeCQzb42nEtwF7tCfMe8TqyZvgWu",
            "symbol": "RAY/USDC",
            "name": "Cropper LP token (RAY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "T4Gy9p5q9rqw2KqdJhTaooKBnq4koXqzXcTfDEw3uea",
            "symbol": "COBAN/USDC",
            "name": "Cropper LP token (COBAN/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "65yAE3W6MoA9KeUYo2iGwddAaqT1qLedYWettprJznWQ",
            "symbol": "FLOKIS/USDC",
            "name": "Cropper LP token (FLOKIS/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "De6ogKa5E8LTYgjPFASNeM815Yqrbb9JWjtJVwMEtY8i",
            "symbol": "BITXBIT/USDC",
            "name": "Cropper LP token (BITXBIT/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "2v86BfdwPkLPmyXDPLsdYRMheBaLmMsEuj9o54YG79Fa",
            "symbol": "DONKEY/USDC",
            "name": "Cropper LP token (DONKEY/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4hzNWj7mzEvACcrPZnUa4CrVygTsVGuqZMRzYkZoQEdj",
            "symbol": "WAG/CRP",
            "name": "Cropper LP token (WAG/CRP)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4ZPDvfGhBYpNyTuFqo79oEDzSMfSDLmxJ4JfyV81ZELN",
            "symbol": "BITCH/USDC",
            "name": "Cropper LP token (BITCH/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4YRSYKcbC3oQSEPEPqcYDHtwDabTBym3YjSsAvnLKdiU",
            "symbol": "SONAR/USDC",
            "name": "Cropper LP token (SONAR/USDC)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Fj9n59ALXoQuePLMksjVuVCdkp9KiHBkSqt7r1u4YGuU",
            "symbol": "SLIM/SOL",
            "name": "Cropper LP token (SLIM/SOL)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Da82KXrqcUnv9oRcoKKUYRWM2yjPesi31GmrgMjZZ6h/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CropperFinance",
                "website": "https://cropper.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "GqKdvkC9JF6qkLpT56MRc6UhDepj7uYt9nVbnm7MWBgR",
            "symbol": "EEZE",
            "name": "Easy as Pi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sm0othgroove/Token_image/main/EEZE_LOGO.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FP3gNbbngnmKZXzuKU78QecAH9QfGkrnUkn2KdA7ERxD",
            "symbol": "AUS",
            "name": "AUSTRALIA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FP3gNbbngnmKZXzuKU78QecAH9QfGkrnUkn2KdA7ERxD/logo.svg",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "OI OI OI",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "HZA2vE3iEVDzVgg82daaettsEUaYohwHGB3QEn1W4eW8",
            "symbol": "AUSSIE",
            "name": "AUSSIE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZA2vE3iEVDzVgg82daaettsEUaYohwHGB3QEn1W4eW8/logo.svg",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "OI OI OI",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "CfoGYZCRSJTvr4QyRMuW5HRnKynsRz2HewTqMHjqVVYY",
            "symbol": "OZZIE",
            "name": "OZZIE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CfoGYZCRSJTvr4QyRMuW5HRnKynsRz2HewTqMHjqVVYY/logo.svg",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "OI OI OI",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "4283jb3WsrrarmgGiPYAfWhmPPvbTMtsAUqX5itNVjge",
            "symbol": "BUCKS",
            "name": "BEVO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4283jb3WsrrarmgGiPYAfWhmPPvbTMtsAUqX5itNVjge/logo.svg",
            "tags": [
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "BEVO BUCKS ROCK",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "FxVdym3AJMQw9AM1wzK7aJVVNBTcnXro2zhYkoBtyUAp",
            "symbol": "TIGR",
            "name": "Tiger by Kab",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theNetworkChuck/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FqS32qJJPyhFDtRgsnAF3M7SQZV8y4qxRUrm9FGzWqnq",
            "symbol": "NPTN",
            "name": "Neptune",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/argonautPH/neptune-sol/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B72NtyLQXgw6sJB3J2vygusgJbpZKAtTmxVNKVhi9HAK",
            "symbol": "YEET",
            "name": "YEET",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B72NtyLQXgw6sJB3J2vygusgJbpZKAtTmxVNKVhi9HAK/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/bunnywarriors",
                "twitter": "https://twitter.com/bunnywarriors_",
                "website": "https://www.bunnywarriors.io/home"
            }
        },
        {
            "chainId": 101,
            "address": "ACKCBoHQ7q6JcpRRbCKAwPJV68QfDo4KJWCpiLQnV9Ca",
            "symbol": "GIDR",
            "name": "Garuda IDR",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ACKCBoHQ7q6JcpRRbCKAwPJV68QfDo4KJWCpiLQnV9Ca/logo.png",
            "tags": [
                "stablecoin",
                "asset"
            ],
            "extensions": {
                "description": "Garuda IDR",
                "twitter": "https://twitter.com/garuda_idr",
                "website": "https://garuda.to/"
            }
        },
        {
            "chainId": 101,
            "address": "AsKEX3XQbDpBuqcm5N8a8DAST65fAqGuBeXXfsEL99MK",
            "symbol": "FWLV2",
            "name": "Faviont Whitelist V2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faviont/marketplace/main/logo-wl.png",
            "tags": [
                "whitelist",
                "utility-token"
            ],
            "extensions": {
                "description": "Each token gives you access to buy one NFT on https://faviont.io/",
                "discord": "https://discord.gg/T6mVYKeVYc",
                "twitter": "https://twitter.com/FaviontOfficial",
                "website": "https://faviont.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DEpY8cody98W6WLw57P2i6kZUcZzJr1oMiDuWCi389n4",
            "symbol": "FTI",
            "name": "FETI Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEpY8cody98W6WLw57P2i6kZUcZzJr1oMiDuWCi389n4/logo520x520.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JNK9NxVGmSdE3rUYgZs2NUYWJgTYXtsm73x9L69hmPf",
            "symbol": "JNKPASS",
            "name": "Junkie Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JNK9NxVGmSdE3rUYgZs2NUYWJgTYXtsm73x9L69hmPf/logo.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "description": "Each token gives you access to buy a discounted Junkie",
                "discord": "https://discord.com/invite/WsRD49UrtT",
                "twitter": "https://twitter.com/777Junkies",
                "website": "https://www.junkiesnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GAWkhunwiseKNqzSD8UCi7dpm2U29GChoa24KNuYgxGn",
            "symbol": "COOKIE",
            "name": "Liberty Cookie",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GAWkhunwiseKNqzSD8UCi7dpm2U29GChoa24KNuYgxGn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/HxaCQScKvC",
                "twitter": "https://twitter.com/Liberty_Cookie",
                "website": "https://www.liberty-cookies.com/"
            }
        },
        {
            "chainId": 101,
            "address": "wmBi6LnKxdvgF9zgoHRantmu7dDLJc4AosTqbztj5cT",
            "symbol": "dagel",
            "name": "dagelan",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/awangee/dagelan/main/dagel/main/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/Sol_Troops",
                "website": "https://www.soltroops.co/"
            }
        },
        {
            "chainId": 101,
            "address": "EA9GBGUxJoNfHLZ5qzLtG6553EmXM5NgsyrMXfZcfQQE",
            "symbol": "WOOT",
            "name": "Women Out Of Tribe",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Awes0me0/WomenOutOfTribe/main/!OG.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "description": "Stake OG Women and Women's Pride NFTs to earn WOOT",
                "discord": "https://discord.gg/womenspride",
                "twitter": "https://twitter.com/WomensPrideNFT",
                "website": "https://www.womenspride.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EXEpxhwgkH7Xw48Ssf5tiNwCy3YXF8xV5z12aWertDfQ",
            "symbol": "REMEDY",
            "name": "The Remedy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Majstx/token-list/26ac8d87388dbc2e71c59ddc69fe6a62ba463c83/assets/mainnet/EXEpxhwgkH7Xw48Ssf5tiNwCy3YXF8xV5z12aWertDfQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.theremedyvi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7AkZxj94Fy8vrLrcFJ39G5vZD339Nshi7kH2VpM6JXje",
            "symbol": "DTWS",
            "name": "Discreet Wellness",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robsd01/crypto/main/DiscreetToken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4a9Lu5A1a2eoREUpv5AfENUnmT2N3N5NTbaYE4GHTWSa",
            "symbol": "LNFTxSLSKIN2",
            "name": "SL Lounge WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4a9Lu5A1a2eoREUpv5AfENUnmT2N3N5NTbaYE4GHTWSa/logo.png",
            "tags": [
                "liqnft-wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PYZWRHgVwM",
                "twitter": "https://twitter.com/liqnft",
                "website": "https://www.liqnft.com/solanaland"
            }
        },
        {
            "chainId": 101,
            "address": "BGrCkH9iBjNrdDLJSpqD8EUVWx8qVD3vXvcJurVc1xsZ",
            "symbol": "BOOGA",
            "name": "BOOGA",
            "decimals": 2,
            "logoURI": "https://arweave.net/X3QsZPOeC1p2uhJDToPTuAeAS6EyWrrHJ943ONARv_g",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetaKongzGC",
                "website": "https://www.metakongz.art/"
            }
        },
        {
            "chainId": 101,
            "address": "7HdV5B1EnnK5cjRir283wza2mm9GDsM8iU7iYQraWXzn",
            "symbol": "WRP",
            "name": "WRP token",
            "decimals": 6,
            "logoURI": "https://drive.google.com/file/d/1UESFAJdzR-_PwyeR3q60qBFkdr0IBDeD/view?usp=sharing",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EaMz63AABkhEity8FdMWfbqT1yk3VSygfszH8euEkLQE",
            "symbol": "BUR",
            "name": "BURE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ValentinDabovski/crypto-bure-token/main/bure-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2nvYxLbe6duVoXdgJeC8my5Q7V5X3bpG3KaFdfixUwkM",
            "symbol": "LLM",
            "name": "Legendary Laohu Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/freespl.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "description": "Legendary Laohu Mint",
                "website": "https://www.kingdompass.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6m6n5aAnaXszk4HY7fzurqjy2CP9uNeQhVq7czu7GWJE",
            "symbol": "BODYE",
            "name": "Bodye Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bodye70/BodyeToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3Dy8KFyvpUJ8nfRCbvk4HLWjNRRzxiVhTeE9PQF9RARD",
            "symbol": "ARNM",
            "name": "Arenum",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Dy8KFyvpUJ8nfRCbvk4HLWjNRRzxiVhTeE9PQF9RARD/logo.png",
            "tags": [
                "game-token"
            ],
            "extensions": {
                "telegram": "https://t.me/arenum_community",
                "telegramAnnouncements": "https://t.me/arenum_announcements",
                "website": "https://arenum.io"
            }
        },
        {
            "chainId": 101,
            "address": "4LTyDfiA7P1WnxVUfSe8qhrjerfd6iKHCs9KtbUEGhAA",
            "symbol": "ENOR",
            "name": "CypherEnormity",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LTyDfiA7P1WnxVUfSe8qhrjerfd6iKHCs9KtbUEGhAA/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BoNE4kp9QQ6mBDPBz1RWPdZFwSevjiGJDBTZQM94fFLb",
            "symbol": "$BONE",
            "name": "Dinodawg Bone",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/ddkb/ddkb-icon.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/dinodawgs",
                "instagram": "https://www.instagram.com/dinodawg_kingdom",
                "twitter": "https://twitter.com/DinodawgKingdom"
            }
        },
        {
            "chainId": 101,
            "address": "8eEg56HBXPG3iHJMNCCFEeVGix7zgHmY48fdC3eLSFUq",
            "symbol": "FLUF",
            "name": "Fluffy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dkocic108/fluffy/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3M4tphj3DiLF4u8W63o9fyiPdb7DT9fuddPSdxSNStiV",
            "symbol": "RAVE",
            "name": "RaveCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/virajdas/RaveCoinCrypto/main/RaveCoinLogoRS.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FX43imWLr8Ld4LjonuTY9VGJBcq3VrV4BEWUXta361pn",
            "symbol": "AXLT",
            "name": "AXOLOTL LABS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ArturoCrz/AXLT-Token/main/logo.png",
            "tags": [
                "NFT",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EtFpJDLvjp5to6MpHa7sgKqkL8m6qTBm4TsBbwj9ggfX",
            "symbol": "GEECH",
            "name": "GeecheeSOL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EtFpJDLvjp5to6MpHa7sgKqkL8m6qTBm4TsBbwj9ggfX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/geecheesol",
                "website": "https://geecheesol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7ftKSttU6yUAnWsWxpRP3LKdQNEto8V4KD9NuWttoVnV",
            "symbol": "JOINTS",
            "name": "TombStoned JOINTS",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ftKSttU6yUAnWsWxpRP3LKdQNEto8V4KD9NuWttoVnV/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "TombStoned High Society",
                "website": "https://www.tombstoned.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "FnBUyCgUzxLVm6DV6xsSFSTrhuQymz2RerZEoNPw2s1N",
            "symbol": "ITOK",
            "name": "iToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MetroMcD/crypto/main/ITOK.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6ouApMromzbiU4M1rtzRLwYowmXFHgohF9tUt2VE7Qa4",
            "symbol": "ARABZ",
            "name": "ArabPunkz Whitelist token",
            "decimals": 9,
            "logoURI": "https://imgur.com/65nEV2W.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "animationUrl": "https://i.imgur.com/bJQjXLk.mp4",
                "description": "ArabPunkz 3D Whitelist redeemable token",
                "imageUrl": "https://arwave.art/upga5v2tzpnak1eqlq5kvc/upga5v2tzp.png",
                "website": "https://arabpunkz.io"
            }
        },
        {
            "chainId": 101,
            "address": "12Mzc4wnxW1t27M1uJ5F8fj4V7FK921gsGG7WsBcnfSX",
            "symbol": "WOW",
            "name": "World of Wolves",
            "decimals": 9,
            "logoURI": "https://bafybeiav3sa2tcy4wmnju5hf7r3nskovfffqb5ffz4g4pshuwuwkpabmsu.ipfs.dweb.link/?ext=png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/alphawolves",
                "twitter": "https://twitter.com/alpha_wolvesnft",
                "website": "https://alphawolvesnft.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "Cr1rbpYZi1W43hcrjHRQVH56msPdCWzdHJVP1y1Roo9i",
            "symbol": "CHINO",
            "name": "CHINO COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CRM-BOTS/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "93iJG6TY2bXb8zUe6gscx3fwvUCZPHVMCrVRg6uFz6ZU",
            "symbol": "SKULL",
            "name": "Spooky Skeleton Society Utility Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/jone6969/Assets/558eaa3c4161c7b95692d83b37a41219337c1357/Skelly-Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/srs5C9ETZJ",
                "twitter": "https://twitter.com/SpookySkellyS_",
                "website": "https://spookyskeletonsociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7nDTAsWEqu6NUQ95TwhaYeszMxWeoYt4698KFApgiUFi",
            "symbol": "PNDBR",
            "name": "PandaBrand",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pandabrand/crypto-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "6jJnEcWe7MFEpRbuPVTCyB8epGnoqdCxfpxEk4LYx6CA",
            "symbol": "BLOOD",
            "name": "MOSQUITO WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MarmosetNFT/MARMO/main/mainnet/asset/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheMarmosetNFT"
            }
        },
        {
            "chainId": 101,
            "address": "HV8RWueWwpRue86SMzXQapxDB5ZEWw5YnVbxuDcihaF5",
            "symbol": "MARMO",
            "name": "MarmosetNFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MarmosetNFT/MARMO/main/mainnet/asset/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheMarmosetNFT"
            }
        },
        {
            "chainId": 103,
            "address": "DToJ26atxLTKNx8tCf6zJq4tT6dmcr85BJfBQoya3uYK",
            "symbol": "BETYAR-Devnet",
            "name": "Betyár Devnet Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DToJ26atxLTKNx8tCf6zJq4tT6dmcr85BJfBQoya3uYK/logo.png",
            "extensions": {
                "description": "BetyarDAO",
                "discord": "https://discord.io/betyardao",
                "instagram": "https://www.instagram.com/betyardao",
                "twitter": "https://twitter.com/betyardao",
                "website": "https://betyardao.hu"
            }
        },
        {
            "chainId": 101,
            "address": "WoBBvtjL6nQWp7QDgBT8DepRyHwDbXoeAhadWuA1G5p",
            "symbol": "WoBB",
            "name": "Wobblebug  Woblist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WoBBvtjL6nQWp7QDgBT8DepRyHwDbXoeAhadWuA1G5p/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/wobblebug/",
                "twitter": "https://twitter.com/Wobblebug",
                "website": "https://www.wobblebug.info/"
            }
        },
        {
            "chainId": 101,
            "address": "FLEXkBfYvTLgirxYE8M5PQRTG17HUWzNgstEqhMe6VZY",
            "symbol": "FLEX",
            "name": "Flex Coin Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLEXkBfYvTLgirxYE8M5PQRTG17HUWzNgstEqhMe6VZY/logo.png"
        },
        {
            "chainId": 101,
            "address": "GgPrwjVQBMHRzXSv8xYbeRcx4uhRxWKW5qp6osMMzNcG",
            "symbol": "$WRENDER",
            "name": "Whitespace_Creatorverse RENDER Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/femolakarot/whitedpace/main/Whitespace-Render-token-logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "NFT-Token"
            ],
            "extensions": {
                "description": "The Whitespace Creatorverse native and governing token, stake creators to earn RENDER",
                "discord": "https://discord.gg/u25c7PJdKz",
                "instagram": "https://www.instagram.com/whitespace_creatorverse",
                "twitter": "https://twitter.com/femolakarot",
                "website": "https://whitespacenft.femolakarot.design/"
            }
        },
        {
            "chainId": 101,
            "address": "GGczxznEoKK4X4jMXeiNARU1uyNLhRaYuM4PP6ueCvcd",
            "symbol": "NIKOIN",
            "name": "Nikoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/akgbar/cryptonikoin/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/E4pN3Yr"
            }
        },
        {
            "chainId": 101,
            "address": "3f4yGBWrNpTJmnzZwW8Nwt7bVmzf3wJLC3fNZKUdkLfn",
            "symbol": "RYPT",
            "name": "RyanPoints",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rbennett71593/rypt/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8UAj3gHMG3cKN66dtm4BSJUpbFPSY1HZGWRtaj6MkGFh",
            "symbol": "AGNI",
            "name": "Agni",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UAj3gHMG3cKN66dtm4BSJUpbFPSY1HZGWRtaj6MkGFh/logo.png",
            "tags": [
                "Gaming",
                "Game",
                "Game-Fi",
                "NFT",
                "NFT-Gaming",
                "Metaverse"
            ],
            "extensions": {
                "description": "Agni is the primary token for the next generation blockchain game AgniMyth.",
                "twitter": "https://twitter.com/agnimyth",
                "website": "https://agnimyth.com"
            }
        },
        {
            "chainId": 101,
            "address": "96K32hBSZEMnq3J717dukcpfe9oBk5mgDNXPMPoANfRF",
            "symbol": "TOMB",
            "name": "Tomb",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sketckypyramids/log/main/log.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "564MW4M8QqnszEdpTFs5vJzWAGH3Dr7WVQtfip7URWwq",
            "symbol": "CYB",
            "name": "CybersecKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sahil2rick/cyberseccrypto/main/CYBERSEC.png",
            "tags": [
                "social-token",
                "Gaming",
                "Game",
                "Game-Fi",
                "NFT",
                "hacking",
                "NFT-Gaming",
                "Metaverse"
            ],
            "extensions": {
                "description": "The CYBERSEC community is a place where pentesters, students, hacker, coders, enthusiasts and allaround CYBERSEC fans come together to help each other.",
                "twitter": "https://twitter.com/CYBERSECGurgaon",
                "website": "https://cybersec.school"
            }
        },
        {
            "chainId": 101,
            "address": "CotjBMa7GVLUP6ajjDbCxoNZBAu9zfkLZzcU5wCLC2Hx",
            "symbol": "COURT",
            "name": "Metacourt",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CotjBMa7GVLUP6ajjDbCxoNZBAu9zfkLZzcU5wCLC2Hx/logo.svg"
        },
        {
            "chainId": 101,
            "address": "6uVeihpFMgW45VTWeRYBBXsmPmgsmq31omL6iGWxjYt7",
            "symbol": "BMW",
            "name": "BMW Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uVeihpFMgW45VTWeRYBBXsmPmgsmq31omL6iGWxjYt7/logo.png"
        },
        {
            "chainId": 101,
            "address": "2P5YxgwZYVynuH2SfHBpboVfc7nHyj5DZXeKqC73B8kk",
            "symbol": "BOE",
            "name": "Block Of Eden",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/blockofeden/crypto/main/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "CZY2dDTb86ARthiLP47hpmEgX1h82XCEr51XWQ5GsZoj",
            "symbol": "UMF",
            "name": "UMF",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CZY2dDTb86ARthiLP47hpmEgX1h82XCEr51XWQ5GsZoj/logo.png",
            "extensions": {
                "description": "pow token backed by FIL mining power"
            }
        },
        {
            "chainId": 101,
            "address": "TTAKPDB1bgY1gpNenFxbLnG2VRFuKnaoFCc6xkY2Cbs",
            "symbol": "TTWL",
            "name": "Tropical Tigers Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TTAKPDB1bgY1gpNenFxbLnG2VRFuKnaoFCc6xkY2Cbs/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "AGTWeVBaAsUqak2nX8L4auTNEkDXbfVRsYfPZpa4LNkS",
            "symbol": "AOTGWL",
            "name": "Apes of the Gods Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGTWeVBaAsUqak2nX8L4auTNEkDXbfVRsYfPZpa4LNkS/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "STNkp36CqWfuDx7E1jXyzDxnRZXtXhcLgXroxYJ3Qa1",
            "symbol": "TSWL",
            "name": "The Solasis Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/STNkp36CqWfuDx7E1jXyzDxnRZXtXhcLgXroxYJ3Qa1/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "DXq8js1uxGNENn97SdtBhRDQ2TMETDxHsRabJPjVh2Q4",
            "symbol": "USAM",
            "name": "UncleSam",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DXq8js1uxGNENn97SdtBhRDQ2TMETDxHsRabJPjVh2Q4/logo.png"
        },
        {
            "chainId": 101,
            "address": "6DnZ8eU2JGmaHDALrR425xes228sFeMEcXezQ4CqdQx4",
            "symbol": "MWT",
            "name": "MetaBards Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6DnZ8eU2JGmaHDALrR425xes228sFeMEcXezQ4CqdQx4/logo.png",
            "tags": [
                "NFT",
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/metabards",
                "twitter": "https://twitter.com/MetaBardsNFT",
                "website": "https://metabards.net/"
            }
        },
        {
            "chainId": 101,
            "address": "76VD6fXjBDhEHGj6bmsKCcA5UrXXRSAbTkZR3sWVsmFL",
            "symbol": "TST",
            "name": "Test Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/thelittlepiggy/assets/main/test-tube.png",
            "tags": [
                "General",
                "Game-Fi",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "4id3Lrw5BJruX7VQ3iRbmpnt8JHYKEkFd47j9NFgirFp",
            "symbol": "UREP",
            "name": "UNIVERSAL REPVBLIK TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4id3Lrw5BJruX7VQ3iRbmpnt8JHYKEkFd47j9NFgirFp/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "UNIVERSAL REPVBLIK TOKEN",
                "instagram": "https://www.instagram.com/universalrepvblik",
                "twitter": "https://twitter.com/URepvblik",
                "website": "https://www.universalrepvblik.com"
            }
        },
        {
            "chainId": 101,
            "address": "Gcg3crzK9expPUEENZpNDg5y3nJ9crhcSzV1MLVgW6gH",
            "symbol": "AXLAB",
            "name": "AXOLOTL LABS DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ArturoCrz/AXLAB-TOKEN/main/logo.png",
            "tags": [
                "NFT",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "B5qmy6nXEHtWmhHoGFM3QuH4ofw9n4gD4eVZ8y8VsCx8",
            "symbol": "GIVDEV",
            "name": "The Giving Pool Devnet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B5qmy6nXEHtWmhHoGFM3QuH4ofw9n4gD4eVZ8y8VsCx8/logo.svg",
            "tags": [
                "Charity",
                "Token",
                "devnet"
            ],
            "extensions": {
                "facebook": "https://facebook.com/givingpool",
                "twitter": "https://twitter.com/givingpool",
                "website": "http://thegivingpool.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GtCaEHFwJTf5uN1AUvJvFDDpKjFTfXnMToqXN8QmB66D",
            "symbol": "BrcPromise",
            "name": "Bricknest Promise Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bricknest/PromiseToken/main/PromiseToken.png"
        },
        {
            "chainId": 101,
            "address": "ECNkBQj5Ee3vNnWoBdmuxMsycbGLsRnQj8u4MA3YQH1G",
            "symbol": "DED",
            "name": "DED TOKEN",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ECNkBQj5Ee3vNnWoBdmuxMsycbGLsRnQj8u4MA3YQH1G/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "5bTTGxcUbG1u2iSEo4DJ6ENRPCyRxZaVZfXgNRQpxruq",
            "symbol": "ZZZ",
            "name": "Zzz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5bTTGxcUbG1u2iSEo4DJ6ENRPCyRxZaVZfXgNRQpxruq/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://149.28.21.55/coin/solana/zzz/"
            }
        },
        {
            "chainId": 101,
            "address": "E4e93CbkTsyPWJ2EYMmDxbTg9dJoE91Rj5Lqq3Pddm5S",
            "symbol": "PLS",
            "name": "Pleasure",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E4e93CbkTsyPWJ2EYMmDxbTg9dJoE91Rj5Lqq3Pddm5S/logo.png"
        },
        {
            "chainId": 101,
            "address": "AK3o9dowLAPX6bnALoEcFMKo1bNaJNTMf7XcWYmQDFHQ",
            "symbol": "CBWL",
            "name": "Cyberbloks whitelist token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AK3o9dowLAPX6bnALoEcFMKo1bNaJNTMf7XcWYmQDFHQ/wl-token3D.png",
            "tags": [
                "Cyberbloks",
                "whitelist-token",
                "nfts"
            ],
            "extensions": {
                "website": "http://www.cyberbloks.com"
            }
        },
        {
            "chainId": 101,
            "address": "HnmucWRmXuCN9hPZpUPqak9sQUKKPaJaZHUK3Ha6QpPR",
            "symbol": "GNC",
            "name": "Greenercoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/EAbdelilah/tokenGNC/main/thumbnail_G%20(1).png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8SxMo8spwojJnReD36RpFhmZzb8UoR2MhWyJfVja5Kx2",
            "symbol": "BAGS",
            "name": "Bandits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8SxMo8spwojJnReD36RpFhmZzb8UoR2MhWyJfVja5Kx2/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4zwvyYh98HjJjCzt2WvHqxfxU3bg25C2cG3rNniKmf3D",
            "symbol": "CBWLF",
            "name": "Cyberbloks whitelist free mint token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4zwvyYh98HjJjCzt2WvHqxfxU3bg25C2cG3rNniKmf3D/wlfree-token3D.png",
            "tags": [
                "Cyberbloks",
                "whitelist-token",
                "nfts"
            ],
            "extensions": {
                "website": "http://www.cyberbloks.com"
            }
        },
        {
            "chainId": 103,
            "address": "3VFFHRZZnXeNjFXmQ5n2hy4jBosZ3WCSLHsqjAjajPU6",
            "symbol": "TFT",
            "name": "Tech Folk",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VFFHRZZnXeNjFXmQ5n2hy4jBosZ3WCSLHsqjAjajPU6/logo.svg",
            "tags": [
                "Tech-Folk",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://web.dev.techfolk.io"
            }
        },
        {
            "chainId": 101,
            "address": "9r4V29zLPD8DXbHR3RVjJb7xrrofEnjB2JHfZiGGLAwp",
            "symbol": "WBNK",
            "name": "WORLDBANK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/OnChainPunk/BANK/main/Bank.png",
            "tags": [
                "utility-token",
                "HODL",
                "OnChainPunk"
            ]
        },
        {
            "chainId": 101,
            "address": "ADcEtKSVKDxBUe3JERgSh9q458w3kRKPMHkihF13vxx2",
            "symbol": "DYOR",
            "name": "NERD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADcEtKSVKDxBUe3JERgSh9q458w3kRKPMHkihF13vxx2/logo.png"
        },
        {
            "chainId": 101,
            "address": "2VdpTjG6qwa9Lw5vT6xHYFY8VB7e1P5Y7KSLrTQWwds4",
            "symbol": "TFT",
            "name": "Tech Folk",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VFFHRZZnXeNjFXmQ5n2hy4jBosZ3WCSLHsqjAjajPU6/logo.svg",
            "tags": [
                "Tech-Folk",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://techfolk.io"
            }
        },
        {
            "chainId": 101,
            "address": "CSPToxnAWVb9YNPfrfhkhq2cterzVtgmfYju7nxg9MAA",
            "symbol": "CSPT",
            "name": "CryptoSport",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CSPToxnAWVb9YNPfrfhkhq2cterzVtgmfYju7nxg9MAA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Crypto-Sport-104470991959649",
                "instagram": "https://www.instagram.com/cryptosport_official/",
                "linkedin": "https://www.linkedin.com/company/cryptosportconnection",
                "twitter": "https://twitter.com/Go_CryptoSport",
                "website": "https://Gocryptosport.com/"
            }
        },
        {
            "chainId": 101,
            "address": "uSuyHGF93xkMU35uUNcAyXWrwLFrzS56kBZYLQVX25R",
            "symbol": "ATVS",
            "name": "Adagio TVs Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uSuyHGF93xkMU35uUNcAyXWrwLFrzS56kBZYLQVX25R/tv.png",
            "tags": [
                "social-token",
                "loop"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Adagio_TVs"
            }
        },
        {
            "chainId": 101,
            "address": "CtBR1SMTEpbNixGdDTD5Xnh4xQQE8NMP2zi5VRWXn3pe",
            "symbol": "SEWL",
            "name": "Snake Eyes WL",
            "decimals": 0,
            "logoURI": "https://cdn.discordapp.com/attachments/962058091661234197/963300850254622770/Untitled_200_200_px_1.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "37qK4Nc6ryVZYfNFufx97nJU6QD2hskToSdgL7VXwoJ4",
            "symbol": "LANA",
            "name": "LANA TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dev8x/token-list/main/assets/mainnet/37qK4Nc6ryVZYfNFufx97nJU6QD2hskToSdgL7VXwoJ4/icon.png",
            "extensions": {
                "medium": "https://lanafinance.medium.com/",
                "twitter": "https://twitter.com/lanaprotocol",
                "website": "https://lanafi.app/"
            }
        },
        {
            "chainId": 101,
            "address": "7JD2vvQu5kbegaxDDWWgbYVpufNx7nsxk3k7CmGK76Yb",
            "symbol": "RMVIP",
            "name": "Romance Meta NFT VIP Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/romeo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AkhxRHBKwhPiv4tvFBQ44nrSmWqM3XqUN7c7nnkPkcAU",
            "symbol": "MOSQUITO",
            "name": "MOSQUITO WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkhxRHBKwhPiv4tvFBQ44nrSmWqM3XqUN7c7nnkPkcAU/mosquito.png",
            "extensions": {
                "twitter": "https://twitter.com/mosquitowarrior"
            }
        },
        {
            "chainId": 101,
            "address": "4x41rDCA5jcXBzBr6e8vWh6nU5TDnRU7U1XPcCZFCUvY",
            "symbol": "WLNOFC",
            "name": "WL NFT OFF ROAD FAN CLUB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4x41rDCA5jcXBzBr6e8vWh6nU5TDnRU7U1XPcCZFCUvY/logo.png",
            "tags": [
                "4x4-Fan-Club",
                "4x4FC",
                "utility-token",
                "virtual-currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NFT_Off_Road_FC",
                "website": "https://nftoffroadfan.club/"
            }
        },
        {
            "chainId": 101,
            "address": "53bovFwsd8eFuBd7tYv9gK8qG2FYJwemLP9zwQsVTJSH",
            "symbol": "ANGELS",
            "name": "Fallen Angels",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/53bovFwsd8eFuBd7tYv9gK8qG2FYJwemLP9zwQsVTJSH/logo.png",
            "tags": [
                "nft-marketplace"
            ],
            "extensions": {
                "twitter": "https://twitter.com/fallenangelsxyz",
                "website": "http://fallenangels.com/"
            }
        },
        {
            "chainId": 101,
            "address": "okaysT2GMaxJgsWR3F4GQKj1bD9Tw23FDjSeuVzeuNW",
            "symbol": "OKAY",
            "name": "Okay Bears WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/okaysT2GMaxJgsWR3F4GQKj1bD9Tw23FDjSeuVzeuNW/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/okaybears",
                "website": "https://www.okaybears.com"
            }
        },
        {
            "chainId": 101,
            "address": "GL7i9TYcE2pKmVYqPrgZht8LcWKBQF4jvDfXQAFDMVwP",
            "symbol": "PO",
            "name": "PO",
            "decimals": 1,
            "logoURI": "https://commons.wikimedia.org/wiki/Commons:Quality_images#/media/File:Gull_portrait_ca_usa.jpg",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "BEBE2QoWKeodTELW8v2aFZHUKsFjiZqb3g9npthHsriJ",
            "symbol": "BEBE",
            "name": "BEBE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEBE2QoWKeodTELW8v2aFZHUKsFjiZqb3g9npthHsriJ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.mekababies.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MRLY2ScVMxXJTieiDi2Ywdm8VjEKeLcY4THL2UyhHRA",
            "symbol": "MNRL",
            "name": "MNRL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MRLY2ScVMxXJTieiDi2Ywdm8VjEKeLcY4THL2UyhHRA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.cosmicsentinels.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Ha4iARiVxSsF4vpViqozeAQfFBxR4yubx2apBbxP7kTA",
            "symbol": "CZT",
            "name": "Canvaz Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/danny8252/czt/main/Logo.png",
            "extensions": {
                "website": "http://cryptocanvaz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2rpR7AagtCaa4Dt9HRFXr7cLGAAyMf1uC8HgYRDhPwCA",
            "symbol": "REE",
            "name": "Reezez",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Delvobit/reezez/main/reezez.png",
            "tags": [
                "social-token",
                "Community-token"
            ],
            "extensions": {
                "website": "https://reezez.com"
            }
        },
        {
            "chainId": 101,
            "address": "HYuUNZbw2K1iJ9j5Sg1e1om8qC49b7595rSMH15sjg2D",
            "symbol": "MATTER",
            "name": "Solarized Matter (Lucid Realms)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYuUNZbw2K1iJ9j5Sg1e1om8qC49b7595rSMH15sjg2D/logo.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lucidrealmsxyz",
                "website": "https://www.lucidrealms.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "TTTetGyXtTwJhGYk1PtCgnfMEeGPpg8kntzJWTCALHh",
            "symbol": "TTWLT",
            "name": "Tropical Tigers WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TTAKPDB1bgY1gpNenFxbLnG2VRFuKnaoFCc6xkY2Cbs/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "HhFp2Ydkx1xHF9R2bKYgkTagsBo8Jnt2NAKsVRSu4r4r",
            "symbol": "DSTACKS",
            "name": "ShortDaddyBigStacks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mydanadesigns/cryptomachine/main/Dana.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HNm1VgnyhaMZZF71RjNFNiYLN76zyZTDcBZPjYveWFXX",
            "symbol": "FRENS",
            "name": "Chimp Frens",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HNm1VgnyhaMZZF71RjNFNiYLN76zyZTDcBZPjYveWFXX/chimp.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "twitter": "https://twitter.com/chimpfrens",
                "website": "https://www.chimpfrens.com/"
            }
        },
        {
            "chainId": 101,
            "address": "84magGbPmN5tjVyGYcPfVG93Eb3kbWMGa6zthFkoGiSN",
            "symbol": "SAMSARIK",
            "name": "Samsarik Capital",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/84magGbPmN5tjVyGYcPfVG93Eb3kbWMGa6zthFkoGiSN/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EE6DKELzAyPgH26Gqrh9zs14QvGrFYrXePBq2bEjpWgv",
            "symbol": "MASH",
            "name": "Mash Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mash111/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Aovr4TdVH6qtZHcv4og6CLqn7gjNYtmDRQULYZSTz1Qf",
            "symbol": "AOVR",
            "name": "ALLOVR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ALLOVRStudios/ovr-program/main/img/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/allovr_protocol",
                "twitter": "https://twitter.com/allovr_protocol",
                "website": "https://www.allovr.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5QEs2UzoefaSoCTDKaaQvce7BDyjQNaAGNs7twH3cVgP",
            "symbol": "TTRYB-USDL",
            "name": "Lagrange TTRYB-USDL LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Lagrange-fi/Test-TRYB-logo/main/TTRYB.png",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "89y8rchTsrNwMzDc4YT8iVtsU6wu6rsab9eLGKhictBK",
            "symbol": "QGL",
            "name": "GlobalQ",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/89y8rchTsrNwMzDc4YT8iVtsU6wu6rsab9eLGKhictBK/logo.png"
        },
        {
            "chainId": 101,
            "address": "RockTZz2q1DoCSt3KDtgSSsDhR3Wi66oeWzNDzgmAXn",
            "symbol": "ROCKT",
            "name": "ROCKY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RockTZz2q1DoCSt3KDtgSSsDhR3Wi66oeWzNDzgmAXn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/Crypto-Sport-104470991959649",
                "instagram": "https://www.instagram.com/cryptosport_official/",
                "linkedin": "https://www.linkedin.com/company/cryptosportconnection",
                "twitter": "https://twitter.com/Go_CryptoSport",
                "website": "https://Gocryptosport.com/"
            }
        },
        {
            "chainId": 101,
            "address": "rainH85N1vCoerCi4cQ3w6mCf7oYUdrsTFtFzpaRwjL",
            "symbol": "RAIN",
            "name": "Rain Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rainH85N1vCoerCi4cQ3w6mCf7oYUdrsTFtFzpaRwjL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://linktr.ee/degeneratetrashpandas",
                "github": "https://github.com/raindrops-protocol/raindrops",
                "twitter": "https://twitter.com/only_raindrops",
                "website": "https://degentrashpandas.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BUSHiaBPmXgtuQ69KGyv24UxZqJmQHzkVpQvUyC5EchZ",
            "symbol": "Bush",
            "name": "Bushido Society WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUSHiaBPmXgtuQ69KGyv24UxZqJmQHzkVpQvUyC5EchZ/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Bushido_Society",
                "website": "https://bushidosociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8sNjQ7xbBeNC44vyyb2Q66FaMPmv6sTsrD89y1KMdcST",
            "symbol": "69MWL",
            "name": "69MIKES Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ScarceProject/crypto/main/070C0720-F125-4539-8AFC-459EA9ECBA33.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/69MIKES_BREH"
            }
        },
        {
            "chainId": 101,
            "address": "GGKBRX7h9dZMn8BrwqmKuT43xFnk8C1vDoiRdNFUEkrM",
            "symbol": "BREH",
            "name": "Breh Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ScarceProject/crypto/main/070C0720-F125-4539-8AFC-459EA9ECBA33.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/69MIKES_BREH"
            }
        },
        {
            "chainId": 101,
            "address": "3ptz14ZNtX5RGUkY5KQwnCYZ8zUtkZYcG6tGAEcrV2WY",
            "symbol": "FUND",
            "name": "Funky Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ptz14ZNtX5RGUkY5KQwnCYZ8zUtkZYcG6tGAEcrV2WY/logo.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FunkyDudesNFT",
                "website": "https://funkydudes.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "DmF48xHb28GqoBY4NLVzcJr8H6qyRRArfDqx6rNruBGa",
            "symbol": "MK",
            "name": "Meta King",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sol-9/crypto/main/ezgif.com-gif-maker.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4eLYNzEr1kTmMCECS1Tsmr9ETnouj29S9aqx6pABJmmC",
            "symbol": "HoneyB",
            "name": "HoneyB",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4eLYNzEr1kTmMCECS1Tsmr9ETnouj29S9aqx6pABJmmC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Bear Universe",
                "twitter": "https://twitter.com/bearuniversenft"
            }
        },
        {
            "chainId": 101,
            "address": "NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44",
            "symbol": "NEON",
            "name": "Neon EVM Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44/neon_token.png",
            "tags": [
                "utility-token",
                "neon-evm-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ApZRBDqYcN",
                "github": "https://github.com/neonlabsorg",
                "twitter": "https://twitter.com/neonlabsorg",
                "website": "https://neon-labs.org/",
                "whitepaper": "https://neon-labs.org/Neon_EVM.pdf"
            }
        },
        {
            "chainId": 102,
            "address": "89dre8rZjLNft7HoupGiyxu3MNftR577ZYu8bHe2kK7g",
            "symbol": "NEON",
            "name": "Neon EVM Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44/neon_token.png",
            "tags": [
                "utility-token",
                "neon-evm-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ApZRBDqYcN",
                "github": "https://github.com/neonlabsorg",
                "twitter": "https://twitter.com/neonlabsorg",
                "website": "https://neon-labs.org/",
                "whitepaper": "https://neon-labs.org/Neon_EVM.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "3KGWxRhQfD4LquDeFf7xEkHtKhtMaVcPX8RSh5DoiwCq",
            "symbol": "TORU",
            "name": "Pizzatoru Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KGWxRhQfD4LquDeFf7xEkHtKhtMaVcPX8RSh5DoiwCq/logo.png",
            "tags": [
                "meme-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://sites.google.com/view/pizzatoru-coin"
            }
        },
        {
            "chainId": 101,
            "address": "33QF5or7ffqbvwGmuLidwimqZze4Cja5n2f8AH9VLUx9",
            "symbol": "SQL",
            "name": "Sheqel",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/Chad-Mann/Sheqel/main/Sheqel-Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Sm5nWgKX4kB5jXhc3Y1Fjx4FfX2fKoxwPX9vq7Fjf2f",
            "symbol": "LIM",
            "name": "limine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Alex000000000010/crypto-limine/main/logo.png",
            "tags": [
                "utility-token",
                "limine",
                "legal-advice-online"
            ],
            "extensions": {
                "website": "https://www.limine.be"
            }
        },
        {
            "chainId": 101,
            "address": "ECPAzxsa4VBALQ4kh4i9mtUdRTBRhGwrzu7Y2YqwZjsi",
            "symbol": "BZT",
            "name": "bZt by Electons",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ECPAzxsa4VBALQ4kh4i9mtUdRTBRhGwrzu7Y2YqwZjsi/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/electons",
                "twitter": "https://twitter.com/electonsnft",
                "website": "https://electons.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BxufMweKEqMPgiq4m4ks3rLuoeTKdHe2FismdnGrbwHV",
            "symbol": "YEAB",
            "name": "YEAB COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HaftamuM/Image/main/Correction%20-%20Animal%20.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://mulubeti.com/"
            }
        },
        {
            "chainId": 101,
            "address": "B93MDnpP14eRZCcNQUYbH5XAQXmdSh3Uq7Y6ya8RVQtF",
            "symbol": "BBST",
            "name": "Black Beard Sociaty Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/adabag-pro/Crypton/main/logo_bbst.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7ZYheubMPjk53vMEmWV1Uiic73g1i12jv2UUbjVi5HYK",
            "symbol": "ORBZ",
            "name": "Planetarians - ORBZ",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ZYheubMPjk53vMEmWV1Uiic73g1i12jv2UUbjVi5HYK/logo.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "twitter": "https://twitter.com/planetariansnft",
                "website": "https://planetarians.io/"
            }
        },
        {
            "chainId": 101,
            "address": "36EsmEsa5rp3VrZAzLna3UFDEBFihwjsRFciUTfoZ5Qt",
            "symbol": "BORGS",
            "name": "BotBorgs - DAO Utility Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LinYu1992/BotBorg_DAO_Utility_Token/main/BotBorgDAOUtilityToken.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Rg28iHdJ6QnZnWpRb6qYvqtvwCTQ6J3uVwJvi7LsAKG",
            "symbol": "IVRY",
            "name": "IVORY TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Rg28iHdJ6QnZnWpRb6qYvqtvwCTQ6J3uVwJvi7LsAKG/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TdnwZyyXjwJxe21As5vjKKfJn9Y7mBeEuKLsHxAKvFV",
            "symbol": "TheSaintsWL",
            "name": "The Saints WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/ae5e93bde4feb14f033707e151870dcb7532ab4b/assets/mainnet/TdnwZyyXjwJxe21As5vjKKfJn9Y7mBeEuKLsHxAKvFV/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thesaints_nft",
                "website": "https://saintsnft.art"
            }
        },
        {
            "chainId": 101,
            "address": "EiThfHf6SMVEPoAds6BjoHu48ayUHRj8drZetG4NVdEn",
            "symbol": "FMI",
            "name": "FMI COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fmicoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B7vkWNFo2aYcYddtwR8fvDwY1Uc54wHfdTXULjrX7KeL",
            "symbol": "FOOD",
            "name": "Foodcoin",
            "decimals": 2,
            "logoURI": "https://cdn.hngr.co/foodcoin-final.gif",
            "extensions": {
                "website": "https://gocaptain.com"
            }
        },
        {
            "chainId": 101,
            "address": "9qT2pfuVZYJisNQTfp1sa917e6t4mbi5Po9D3utwwKyj",
            "symbol": "WCR",
            "name": "RENDER",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/femolakarot/whitespaceNFT/main/Whitespace-Render-token-logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "NFT-Token"
            ],
            "extensions": {
                "description": "The Whitespace Creatorverse native and governing token, stake creators to earn RENDER",
                "discord": "https://discord.gg/u25c7PJdKz",
                "instagram": "https://www.instagram.com/whitespace_creatorverse",
                "twitter": "https://twitter.com/femolakarot",
                "website": "https://whitespacenft.femolakarot.design/"
            }
        },
        {
            "chainId": 101,
            "address": "2XShUPcKwx7MFsx8kVXtd6dvoQq6dYxxJwAXWnTNChEr",
            "symbol": "NC",
            "name": "NobelConnect",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Lindon100/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DrdaGQsBnxUByKPqm3GFPnobW3FF3YNbKmvnLbjvK5v9",
            "symbol": "MOSQUITOWARRIORS",
            "name": "EARLY MINT TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DrdaGQsBnxUByKPqm3GFPnobW3FF3YNbKmvnLbjvK5v9/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/mosquitowarrior"
            }
        },
        {
            "chainId": 101,
            "address": "imsoVR7F3p9KnmAXDB38EiSjYyLqkgQS8QWj4egsgDz",
            "symbol": "IMSOMINT",
            "name": "IMSO Mint Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/N6Y8A1lf3uPfvfwLp_09GsU1Wj_VUvI4mrlHoW6Wlbw",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/w9StbG6Fsa",
                "twitter": "https://twitter.com/IMSO_Game",
                "website": "https://imsogame.com/",
                "youtube": "https://www.youtube.com/playlist?list=PLJWpaLg65q8skSdffF2gDE9m5sbCwZWV8"
            }
        },
        {
            "chainId": 101,
            "address": "MiNAtFMGvaEAJft5VVnVyy5jZy1fBWVEDTuraMmth5P",
            "symbol": "MINA",
            "name": "Solmiantor Official WL token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MiNAtFMGvaEAJft5VVnVyy5jZy1fBWVEDTuraMmth5P/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolminatorNFT",
                "website": "https://www.solminator.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DKEyAMNSVNdFCSUcunFJ4jn7TzZd8ec9Z4XUACaT37mG",
            "symbol": "BattleWL",
            "name": "Battle Drones Wave 2 WL Token",
            "decimals": 0,
            "logoURI": "https://battledrones.io/WL_token.png",
            "tags": [
                "WL"
            ],
            "extensions": {
                "discord": "https://discord.gg/jRz63Dsa3p",
                "twitter": "https://twitter.com/BattleDronesNFT",
                "website": "https://battledrones.io"
            }
        },
        {
            "chainId": 101,
            "address": "MoNKQxmf7jKLrrz695eRGD7z2z13YsLFJaqHM45vPFw",
            "symbol": "MONK",
            "name": "MonkVerse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MoNKQxmf7jKLrrz695eRGD7z2z13YsLFJaqHM45vPFw/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/monkversenfts"
            }
        },
        {
            "chainId": 101,
            "address": "BTuBJVKyD44arpZn5g49h1t9XmBnWWwrGZYkAPqKEJca",
            "symbol": "MINTUS",
            "name": "MintUS WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTuBJVKyD44arpZn5g49h1t9XmBnWWwrGZYkAPqKEJca/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/onlymintus"
            }
        },
        {
            "chainId": 101,
            "address": "moaTKHdUBnQyCTUbaetkPek8tnQkZTN4Z4ALMTHgQr9",
            "symbol": "MoAToken",
            "name": "Ministry of Art - Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/moaTKHdUBnQyCTUbaetkPek8tnQkZTN4Z4ALMTHgQr9/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MinistryArt",
                "website": "https://ministry.art/"
            }
        },
        {
            "chainId": 101,
            "address": "ETbxzGvuzVrCxVN7cNoT6QBEYwFLBwMUwSYX6pUdHyep",
            "symbol": "sTZC",
            "name": "Trezarcoin",
            "decimals": 6,
            "logoURI": "https://trezarcoin.com/wp-content/uploads/2018/02/TZC-1000x.png",
            "tags": [
                "sTZC",
                "utility-token",
                "TZC-on-Sol"
            ],
            "extensions": {
                "description": "sTZC is a bridged version of Trezarcoin on Solana",
                "twitter": "https://twitter.com/Trezarcoin",
                "website": "https://trezarcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "t1tkJghUxXFrpdzuMq114VjJ4aHgHZMftDMcZATHrmG",
            "symbol": "DEAL",
            "name": "IM THAT FAKE TOKEN SELLER",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/t1tkJghUxXFrpdzuMq114VjJ4aHgHZMftDMcZATHrmG/logo.png",
            "tags": [
                "msg-me",
                "in-Discord",
                "SonicHASH9622",
                "HASH-is",
                "The-Symbol",
                "I-want",
                "to-make",
                "A-DEAL"
            ]
        },
        {
            "chainId": 101,
            "address": "Fqd23hsmsyFeZWRzvN8WZUBKhSJFrxrjwVMu3TFM9Ygn",
            "symbol": "MAKE",
            "name": "CONTACTME SonicHASH9622",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fqd23hsmsyFeZWRzvN8WZUBKhSJFrxrjwVMu3TFM9Ygn/logo.png",
            "tags": [
                "msg-me",
                "in-Discord",
                "SonicHASH9622",
                "HASH-is",
                "The-Symbol",
                "I-am",
                "that-fake",
                "token-seller"
            ]
        },
        {
            "chainId": 101,
            "address": "GgF2MBgxNSLJSyTLPnib3zpwFTtpdTmRfFhQ9mUuqdNm",
            "symbol": "NQUACK",
            "name": "NoiaDucks QUACK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GgF2MBgxNSLJSyTLPnib3zpwFTtpdTmRfFhQ9mUuqdNm/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/aet4ZX9Ptp",
                "serumV3Usdc": "GXnDRNRB3SeAKnAKAZX9WCE5LEvXpjUvFYyvmUsUQDUZ",
                "twitter": "https://twitter.com/noiaducks"
            }
        },
        {
            "chainId": 101,
            "address": "GZP2TeKqbDfEbW398Y56up8eYaZyhW7UE4bAaPFdqtct",
            "symbol": "SCW",
            "name": "Sensible Coins Work",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZP2TeKqbDfEbW398Y56up8eYaZyhW7UE4bAaPFdqtct/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/SensibleConcreteWorks",
                "github": "https://github.com/SmartNET-works",
                "instagram": "https://www.instagram.com/SensibleConcrete",
                "linkedin": "https://www.linkedin.com/company/sensible-concrete-works-llc",
                "twitter": "https://twitter.com/SensibleCompany"
            }
        },
        {
            "chainId": 101,
            "address": "Tokykm2subzeF9C3G5nqXds6YxpPeXEKkCD2Rt1jHKk",
            "symbol": "SPWL",
            "name": "Smug Pugs Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Tokykm2subzeF9C3G5nqXds6YxpPeXEKkCD2Rt1jHKk/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thesmugpugs",
                "website": "https://thesmugpugs.com"
            }
        },
        {
            "chainId": 101,
            "address": "ArN66gBHM6izxCSMEbtzHh5oYyvAgA2UKcjXmrnagj3k",
            "symbol": "SKWL",
            "name": "Synthtopia Key WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArN66gBHM6izxCSMEbtzHh5oYyvAgA2UKcjXmrnagj3k/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHGdE3i9eSPq9ZGMJrr4Yxgn9aMBV5tWnZ6UmLQM6prC",
            "symbol": "PLTC",
            "name": "plateauCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/husainunwalla/plateaucoin/main/plateau.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9WgTVodnWjVTwDyp44cEgezKhPhexkJMv1d2MkmGJEWw",
            "symbol": "TMP",
            "name": "MXM Alpha Temp Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9WgTVodnWjVTwDyp44cEgezKhPhexkJMv1d2MkmGJEWw/logo.png",
            "tags": [
                "GameFi",
                "Game"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/mixmob",
                "twitter": "https://twitter.com/mixmoborigin",
                "website": "https://www.mixmob.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DbaNZcWN2UnRFmVXKPVc9ViqebN6jYn7JyexXctvAq5Z",
            "symbol": "NEN",
            "name": "NEN COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jxliana/crypto/main/glow.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D985jiWb7dSCAUNPrzfzzMjPYErDwQsBSvRoyQE2ZQLJ",
            "symbol": "NYC",
            "name": "New York Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/LassePinga/PNG/main/logos/logo_NYC.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "New York Coin - Where dreams are made of!"
            }
        },
        {
            "chainId": 101,
            "address": "6YCVEZYNR452xf4AYDBDPAKbyDokGaqvFaJQGbdoGV6G",
            "symbol": "BHH",
            "name": "Broderson Hamburg",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LassePinga/PNG/main/logos/logo_broderson_hh.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Broderson Tattoos - Real life masterpieces. Digital artworks."
            }
        },
        {
            "chainId": 101,
            "address": "4b8GhzgAPExdcAHTzLppgD4aSqn3UVmJ9pwWTbx3jVT1",
            "symbol": "RAYC",
            "name": "Ray Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dmitry-Raevsky/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7GeFDSxG5jLsRG7rjNd8hFKaVt49wJQt6tdPMENn41v9",
            "symbol": "NOMNOM",
            "name": "nomnom",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GeFDSxG5jLsRG7rjNd8hFKaVt49wJQt6tdPMENn41v9/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaShroomz",
                "website": "https://www.solanashroomz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "SMG8HtMAv1XrtcNW4C2TLoGau9BUrwmfgu6Gm8uyven",
            "symbol": "SMG",
            "name": "SLMAS INC",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SMG8HtMAv1XrtcNW4C2TLoGau9BUrwmfgu6Gm8uyven/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/slmasinc",
                "website": "https://slmas.co"
            }
        },
        {
            "chainId": 101,
            "address": "H2QCpfU42RB7KBouuxkyR4qD27UwPzLoTnCLvSPN2hh3",
            "symbol": "ER",
            "name": "Ernest Beyond",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H2QCpfU42RB7KBouuxkyR4qD27UwPzLoTnCLvSPN2hh3/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AR2KqRonb6ZAspTAJbk2HwCZHgjbz8GL6ZpSA43QsYLo",
            "symbol": "SS",
            "name": "Storm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AR2KqRonb6ZAspTAJbk2HwCZHgjbz8GL6ZpSA43QsYLo/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5AK5t6AqgfFNoMQAdxxaRETS2xyeEXekiiBWdcfAjm4p",
            "symbol": "HH",
            "name": "Hamburg Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LassePinga/PNG/main/logos/logo_hh.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Hansestadt Hamburg Token"
            }
        },
        {
            "chainId": 101,
            "address": "SH1f6At4pScGi42Xfp6tvf9eKirfMd5bi2oPmxdpUAF",
            "symbol": "SH",
            "name": "SmokeHeads WLtoken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SH1f6At4pScGi42Xfp6tvf9eKirfMd5bi2oPmxdpUAF/logo.png"
        },
        {
            "chainId": 103,
            "address": "2cpgKn9RtchCLYiXoU7NJasMWcKLYJndHsYBQjcrTjbp",
            "symbol": "TOM",
            "name": "Tomcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cpgKn9RtchCLYiXoU7NJasMWcKLYJndHsYBQjcrTjbp/siku-square.png"
        },
        {
            "chainId": 101,
            "address": "G2HN9GyKjfkm89Gw1jG3kowfiaTaxvRkRMzrz5QWtwbF",
            "symbol": "BTX",
            "name": "Broderson Tattoo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LassePinga/PNG/main/logos/logo_broderson_tattoo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Broderson Tattoos - Real life masterpieces. Digital artworks."
            }
        },
        {
            "chainId": 101,
            "address": "57ccgdZuFf3ZuzSzuQrRA37dYWho72yK44zQb2NGC4E1",
            "symbol": "SRN",
            "name": "SerenaH",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/benorz/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "ExW7Yek3vsRJcapsdRKcxF9XRRS8zigLZ8nqqdqnWgQi",
            "symbol": "unUSDC",
            "name": "Unloc USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ExW7Yek3vsRJcapsdRKcxF9XRRS8zigLZ8nqqdqnWgQi/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/UnlocNFT",
                "website": "https://unloc.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "9maUhqaoHnYWWfeqr16TTzP9ZH6DCb2FSHf8iF3HLyDh",
            "symbol": "ZEST",
            "name": "ZEST",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9maUhqaoHnYWWfeqr16TTzP9ZH6DCb2FSHf8iF3HLyDh/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "Juiced ZEST Token",
                "discord": "https://discord.gg/ZXBRGXccR2",
                "instagram": "https://www.instagram.com/juicedfi",
                "linkedin": "https://www.linkedin.com/company/juicedfi",
                "medium": "https://www.medium.com/@juicedfinance",
                "twitter": "https://twitter.com/JuicedProtocol",
                "website": "https://www.juiced.fi",
                "whitepaper": "https://juiced.fi/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "2KXuiuKwSxatUSN3bYG9i4Mg9T66PYBPAHvVDtiCfoLm",
            "symbol": "MEN",
            "name": "Mental Protocol",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/MentalProtocol/uploads/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/mentalprotocol",
                "twitter": "https://twitter.com/mentalprotocol",
                "website": "https://mentalprotocol.net/"
            }
        },
        {
            "chainId": 101,
            "address": "JCt4Uq9DnEfUiZ9yQ1ds5c3AnCq76pNHSxCWzLjKSvLJ",
            "symbol": "ONYX",
            "name": "Onyx Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JCt4Uq9DnEfUiZ9yQ1ds5c3AnCq76pNHSxCWzLjKSvLJ/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Darkland_creat",
                "website": "https://darklandcards.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "CGJUd4fXErst425116mVqffFhfmhVbi3YB4WT37DZeEy",
            "symbol": "MISK",
            "name": "MISK Radio",
            "decimals": 9,
            "logoURI": "https://imgur.com/dGGwaMj.jpeg",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "animationUrl": "https://imgur.com/CWFVZxE.mp4",
                "description": "Your number one Radio, Misk FM",
                "imageUrl": "https://imgur.com/dGGwaMj.jpeg",
                "website": "http://www.misk.art"
            }
        },
        {
            "chainId": 101,
            "address": "NodePBhXHVcHom1zekzY4o95EVGg8FtQ29M9pEb3AXJ",
            "symbol": "NODE",
            "name": "Node Monkeys Automation Tool Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img922/6391/uKJaL7.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nodemonkeynft"
            }
        },
        {
            "chainId": 101,
            "address": "4UfwrRqbzjNZbXTKNbWwhScN29Dguj1BiBNwj6JqVsuH",
            "symbol": "SHITZ",
            "name": "The Shitz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4UfwrRqbzjNZbXTKNbWwhScN29Dguj1BiBNwj6JqVsuH/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://theshitz.io"
            }
        },
        {
            "chainId": 101,
            "address": "7VvNMQQZxPXjK7ZJonXGZ8wK7pmkJJpPyuh1NyF3D33v",
            "symbol": "ARABZZ",
            "name": "ArabPunkz 3D Whitelist token",
            "decimals": 9,
            "logoURI": "https://imgur.com/k3MITax.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://i.imgur.com/bJQjXLk.mp4",
                "description": "ArabPunkz 3D Whitelist redeemable token",
                "imageUrl": "https://arwave.art/upga5v2tzpnak1eqlq5kvc/upga5v2tzp.png",
                "website": "https://arabpunkz.io"
            }
        },
        {
            "chainId": 101,
            "address": "DYorSUf2MBWCBwCHSqmqVXeNLxzNPRMcPZgzhgFZhCDq",
            "symbol": "DYOR",
            "name": "DYOR Nerds WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYorSUf2MBWCBwCHSqmqVXeNLxzNPRMcPZgzhgFZhCDq/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DYORNERDS"
            }
        },
        {
            "chainId": 101,
            "address": "73ZW3Fr5aPiZCwX3Qv9EoNdmBhoT6KVpvkZemHViB2af",
            "symbol": "ALDC",
            "name": "ALEDA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AlexDanielC/aledacoin/main/Logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Hi! My name is Alex Daniel and that's my token! Nice to meet you! \u003c3"
            }
        },
        {
            "chainId": 101,
            "address": "FXzrgwqF2rHAEiwa9qs9cdWS9QMmACWXLqzrufQFYmFu",
            "symbol": "TAMASHI",
            "name": "Tamashi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXzrgwqF2rHAEiwa9qs9cdWS9QMmACWXLqzrufQFYmFu/logo.svg",
            "tags": [
                "nfts",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TamashiSoulNFT",
                "website": "https://tamashisoul.world"
            }
        },
        {
            "chainId": 101,
            "address": "6efabccMqpTQR9arTi4FsD778ieyY9tVKjbuTcrtc57T",
            "symbol": "GRCC",
            "name": "Grumpy Cup Coffee Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/samuelseeman/grumpy-cup-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ribb7TpYFS1nwDskSEQ1i4nBJ5T5ZZnJTKHRzYVhwrC",
            "symbol": "RIBBIT",
            "name": "RIBBIT Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ribb7TpYFS1nwDskSEQ1i4nBJ5T5ZZnJTKHRzYVhwrC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wGdfp6TJn9",
                "twitter": "https://twitter.com/execdao"
            }
        },
        {
            "chainId": 101,
            "address": "6t8DKyndGyqnzP63iNU1zMKWrUfvRZo3iNxaeqhd9Q9b",
            "symbol": "DEZN",
            "name": "DeadCitizen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6t8DKyndGyqnzP63iNU1zMKWrUfvRZo3iNxaeqhd9Q9b/logo.png",
            "tags": [
                "community-token",
                "DeadCitizen-token"
            ]
        },
        {
            "chainId": 101,
            "address": "By4SeG9KufEAuBVU4T2wa9s6CfNCPh7YomdzzZAMZoLF",
            "symbol": "RAWR",
            "name": "RAWR Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/omer-demir/token-list/main/assets/mainnet/By4SeG9KufEAuBVU4T2wa9s6CfNCPh7YomdzzZAMZoLF/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/metagonnft"
            }
        },
        {
            "chainId": 101,
            "address": "6qLB8HrouHjQPSAypBfDetAjF1uZJKQjtG7WfpT9Sz5k",
            "symbol": "ARB",
            "name": "Arbor",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/svb1995/crypto/main/Thank.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2EyAXWi1VKFQwBonEAAk6rhe17vvKC9GXMmAk6V27dVw",
            "symbol": "DINOSIL",
            "name": "Dino Silver",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RolandPixeldust/PXCoin/main/Silver.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mentalprotocol",
                "website": "https://twitter.com/PixeldustNFT"
            }
        },
        {
            "chainId": 101,
            "address": "8v65mF5HJ5FGrxFoUX2L1uv26L87Cb3e1ikptji91FBJ",
            "symbol": "ZTBI",
            "name": "Zotobi Coin",
            "decimals": 0,
            "logoURI": "https://github.com/zotobicoin/token/raw/main/zotobi_coin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F5W7UXQBFif6HgQmHRcZ4Tqz4xDAsbVpj2Qe6KTfRXSp",
            "symbol": "1337",
            "name": "Leet Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Peannut/token/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4UFiHL1FzUUQPpjsm1gpVDA9ENQQaB81WdvN4HYW24Pa",
            "symbol": "INCOME",
            "name": "INCOME",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/danielleonardomorales/incometoken/main/incometoken.png",
            "tags": [
                "general"
            ]
        },
        {
            "chainId": 101,
            "address": "8APsy1a6xejoxnoRYFt99GNuhkFvwMtx4W1tXLPw71Sm",
            "symbol": "TWC",
            "name": "THE WOLF CLUB (TWC)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/YBenji/crypto/main/TWCLOGO.png",
            "tags": [
                "social-token",
                "staking"
            ]
        },
        {
            "chainId": 101,
            "address": "Frog3s4ZRPDonJExV2Yw2MgtF7vXMf1f8fx85WM6BV8h",
            "symbol": "SFWL",
            "name": "The Stoned Frogs WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Frog3s4ZRPDonJExV2Yw2MgtF7vXMf1f8fx85WM6BV8h/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheStonedFrogs",
                "website": "https://www.thestonedfrogs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6QSQ28juyiV5bkQ19AttaLPidtGDZbSKPbM2GrvRAAQA",
            "symbol": "THEA",
            "name": "Theadex",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6QSQ28juyiV5bkQ19AttaLPidtGDZbSKPbM2GrvRAAQA/theadex_logo.png",
            "tags": [
                "social-token",
                "community-token",
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theadex2",
                "website": "http://theadex.io"
            }
        },
        {
            "chainId": 101,
            "address": "DBTGhXu22musU5cCMbfx8XaZxgJJHZANK9RRSUE3KMUx",
            "symbol": "ADAL",
            "name": "Ahmadali Jamali",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AhmadAJamali/Crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ATBuXLdQ7GtFtKUsnT7nBSWfS9ZsStzn5d2UhGsQbAfT",
            "symbol": "TMS",
            "name": "Track Master",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/trackmastersteve/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MoRPDFt2wSNBWfS7f3Pch3gehCUFyouLNCqLMQhJUh4",
            "symbol": "MORP",
            "name": "Morpheus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MoRPDFt2wSNBWfS7f3Pch3gehCUFyouLNCqLMQhJUh4/logo.png"
        },
        {
            "chainId": 101,
            "address": "4PJHvHRL44uPEf44LPV6PvwUBMRiKeq5BiLro7ycSAFX",
            "symbol": "SecT",
            "name": "Security Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CrashedNetwork/-ecurity-Token/main/BGC_ccexpress.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3fMN5za4iMmatXCeeWpVSjWDurvFGnNRdZFqTwYc2h8U",
            "symbol": "YDI",
            "name": "You Deserve It",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MrBrewster1970/YDI-Token/main/logo.png",
            "tags": [
                "metaverse",
                "virtual-real-estate",
                "utility-token",
                "community-token",
                "play-to-earn"
            ]
        },
        {
            "chainId": 101,
            "address": "FBCp6ST2R2z7W9fe6xuU9sKPrFDe5cXBuZgjRaYCNssq",
            "symbol": "BPC",
            "name": "Black Penny",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/banton84/Black-Penny/main/logo.png.png",
            "tags": [
                "Social-Token",
                "collectible-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A3LhReqJgCZPsYXiuRqap23JoLaTHyXzFnWFywe3MF2q",
            "symbol": "HAKUJ",
            "name": "Hakuj COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/akgbar/hakujtoken/main/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "DeFi"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bariskgz"
            }
        },
        {
            "chainId": 101,
            "address": "EG1gutpWC9Y9KhpJe83x435Ho5p2spp1fkdHBUHg6uU2",
            "symbol": "RAC",
            "name": "Retro Arcade Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/RetroArcade-project/token/main/Nuevo%20proyecto.png",
            "tags": [
                "Bet-token",
                "collectible-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8KoC6B7ekXKCEucie7skdH3TePx2ja3WeB14bFJKaahX",
            "symbol": "SNECK",
            "name": "PussyCets Sneck",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/93284817/163309826-ae6df615-bbe7-4d24-a522-77414c27e0a4.PNG",
            "tags": [
                "Social-Token",
                "collectible-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9F6STwpygJj3u5afZ15Cr522MFamqbkNJLa7iamW681D",
            "symbol": "CWCV",
            "name": "CowCave",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cowanmd/cowcave/main/IMG_0103.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "3Lsr3Am1GZK9NpFMBWXqrAUBPpNb5HGGLVTA8Tjq9m4y",
            "symbol": "H2O",
            "name": "H2O Token",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/1111-1993/H2O/vlcsnap-error289.png",
            "tags": [
                "Life-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NhX5EzH4"
            }
        },
        {
            "chainId": 101,
            "address": "DKZDN1QM7ZGBfUZhQFPo233ccBxumjLGzFqKvMV43dzH",
            "symbol": "ANONY",
            "name": "Anonymous Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tatikondarahul2001/Crypto_coin/main/logo.png",
            "tags": [
                "social-token",
                "collectible-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7AfuD3nqZEJSF7oaQgEPmeo7zJi1n8Dh7BedYtgRugFk",
            "symbol": "July22ppUSDC",
            "name": "Port Finance 2022 July Principal USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg",
            "tags": [
                "port",
                "lending",
                "principal-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "4Fo8dh9kHn7WGxPy3MdhaBXLjY5DYStbRPwZudCTo5W4",
            "symbol": "July22pyUSDC",
            "name": "Port Finance 2022 July Yield USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg",
            "tags": [
                "port",
                "lending",
                "yield-tokens"
            ],
            "extensions": {
                "website": "https://port.finance"
            }
        },
        {
            "chainId": 101,
            "address": "ExzjFVA1WEKEx7cmavaGkNs1zKLcHWyfsumQqAq381b9",
            "symbol": "ADTVS",
            "name": "Adagio TVs WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ExzjFVA1WEKEx7cmavaGkNs1zKLcHWyfsumQqAq381b9/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Adagio_TVs"
            }
        },
        {
            "chainId": 101,
            "address": "2UhohwrJgZfjH9yRuU32fxVoZG1v9n1aYxPqUGWCR5da",
            "symbol": "JCEC",
            "name": "JapssCrew Entertainments Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ajubajukajoo/assets/main/JCEToken.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://japsscrew.rf.gd"
            }
        },
        {
            "chainId": 101,
            "address": "GuGhzXWo6ut5m7H1Mng2tYsWde65TqXxeV5HXYJmoSaT",
            "symbol": "CSL",
            "name": "CryptoSportsLottery",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chrisjonssil/clst/main/logo.png",
            "tags": [
                "sports-token"
            ]
        },
        {
            "chainId": 101,
            "address": "eRkvH2Q8jtfuJMrCX7mDjcNPBix7xPXGpufypY66fyx",
            "symbol": "BOGWL",
            "name": "Birth of Galaxy WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/eRkvH2Q8jtfuJMrCX7mDjcNPBix7xPXGpufypY66fyx/icon.png"
        },
        {
            "chainId": 103,
            "address": "89y8rchTsrNwMzDc4YT8iVtsU6wu6rsab9eLGKhictBK",
            "symbol": "QGL",
            "name": "GlobalQ",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/89y8rchTsrNwMzDc4YT8iVtsU6wu6rsab9eLGKhictBK/logo.png"
        },
        {
            "chainId": 103,
            "address": "BXkV5Wn1wJKAYrUZtMj1B4NAGgG983SX8iY8n5qwR6nE",
            "symbol": "TINY-DEV",
            "name": "TinyBits (devnet)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXkV5Wn1wJKAYrUZtMj1B4NAGgG983SX8iY8n5qwR6nE/logo.png",
            "tags": [
                "devnet"
            ],
            "extensions": {
                "coingeckoId": "tiny-colony",
                "description": "An expansive pixelated ecosystem on the Solana Blockchain.",
                "discord": "https://discord.gg/AT9caAbY",
                "github": "https://github.com/TinyColonyGame",
                "instagram": "https://www.instagram.com/tinycolonygame",
                "medium": "https://medium.com/@TinyColonyGame",
                "twitter": "https://twitter.com/TinyColonyGame",
                "website": "http://tinycolony.io/"
            }
        },
        {
            "chainId": 101,
            "address": "RMWLNGZ3aujqFnCE2LYLeRFixxKNuudN4ZrdYnQs8FD",
            "symbol": "RMWL",
            "name": "Rag a Muffin WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RMWLNGZ3aujqFnCE2LYLeRFixxKNuudN4ZrdYnQs8FD/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ragamuffinsbw"
            }
        },
        {
            "chainId": 101,
            "address": "KidSCfch9MD1zB12s1UmmviXKDZqFEqJEEGuTMiKNFt",
            "symbol": "KIDS",
            "name": "Carton Kids Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KidSCfch9MD1zB12s1UmmviXKDZqFEqJEEGuTMiKNFt/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CartonkidsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "SnapRSXpdqwuX3YFvQgUoZBDKyY5idv1SRMREh2CcHf",
            "symbol": "SS",
            "name": "Snapshots Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SnapRSXpdqwuX3YFvQgUoZBDKyY5idv1SRMREh2CcHf/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SnapShotsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "ZenjaZLnm7kj6NMD4YcZBJdqzEETN5UjrZfbHdoKWEe",
            "symbol": "ZENJ",
            "name": "Zenjin Viperz Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZenjaZLnm7kj6NMD4YcZBJdqzEETN5UjrZfbHdoKWEe/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZenjinViperz"
            }
        },
        {
            "chainId": 101,
            "address": "orcs67sQW9EHAJqw6ZYHZwbHCY8j7zm4VGc3WUSduUX",
            "symbol": "ORCS",
            "name": "The Orcs Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcs67sQW9EHAJqw6ZYHZwbHCY8j7zm4VGc3WUSduUX/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheOrcsNFT",
                "website": "https://theorcs.io"
            }
        },
        {
            "chainId": 101,
            "address": "4h4LvS6NsVjZ87uBwrYyTeppTm1ii5PtRN9A6Ld2kZjw",
            "symbol": "$FLY",
            "name": "StayFly",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4h4LvS6NsVjZ87uBwrYyTeppTm1ii5PtRN9A6Ld2kZjw/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "merchandise-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EaglezSOL"
            }
        },
        {
            "chainId": 103,
            "address": "9Qi5wVDWRAtcnb9u8h9ERT2qSdtkMjj8iJycfJuTdJJ5",
            "symbol": "AYK",
            "name": "Aayush1234",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlockchainTeamThoughtWin/cropto-solana-logo/main/my_new_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7mUBE2BnPGS7oxhkCFz7GKFsREB1XfoF73ngryiyDwNG",
            "symbol": "HENTAI",
            "name": "HENTAI COIN",
            "decimals": 9,
            "logoURI": "https://github.com/Hentai-Genesis/fav/blob/main/h.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/smolhentai",
                "website": "https://hentai.lol/"
            }
        },
        {
            "chainId": 101,
            "address": "HLVa5jbEhAtN8sgvoeSvP7zZCjxVzd2nhfUD15cKXFv5",
            "symbol": "DROPS",
            "name": "Drops",
            "decimals": 0,
            "logoURI": "https://github.com/Capetownsun/sunny/blob/main/rainbow-drop.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CRmGfCvD3s43v2X6dGMmA9XVCMXy8jacKGQ6H451afgd",
            "symbol": "HVEWL",
            "name": "Heaven Dragons Empire Collection",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRmGfCvD3s43v2X6dGMmA9XVCMXy8jacKGQ6H451afgd/logo.png"
        },
        {
            "chainId": 101,
            "address": "FKebt4KYkV5K6gjn2DNHfoNyhFarThHu2SxrKRFtjgF4",
            "symbol": "RLW",
            "name": "RLW Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Jkei-dev/crypto-rlw/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5LL1vedTP55UGkGF1x6ZRbefPHANveYpvqF18QSwKx2e",
            "symbol": "$RAWR",
            "name": "RAWR Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5LL1vedTP55UGkGF1x6ZRbefPHANveYpvqF18QSwKx2e/logo.png",
            "tags": [
                "nft",
                "game-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metagonnft"
            }
        },
        {
            "chainId": 101,
            "address": "EVjuFPAQTLWdLnqFt7JPs7nEbqcqnG7ZFELSACn57Xzi",
            "symbol": "KRNS",
            "name": "KARENS",
            "decimals": 0,
            "logoURI": "https://github.com/DiscoK1aus/crypto/blob/main/karenlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "K1Lm4h2eHqeZySJSmSA166kJj7j9s36nSaGgFJzhDnc",
            "symbol": "KITS",
            "name": "Kitsune Token",
            "decimals": 3,
            "logoURI": "https://media.discordapp.net/attachments/926535226820276264/964138793315758150/unknown.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HxgAGBTLCeemm4TVqvYSebpUanJKzuztXTHdhuCt4W2i",
            "symbol": "$FLUFF",
            "name": "FLUFF TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/furrsols/assets/main/FLUFF.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Furr_Sols",
                "website": "https://furrsols.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BEEs2KkXbdF5NxTtAN8pM6qthLGapc7vACcA8oCXoYgU",
            "symbol": "BEE",
            "name": "Bee Honey",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEEs2KkXbdF5NxTtAN8pM6qthLGapc7vACcA8oCXoYgU/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/Businessbeesc"
            }
        },
        {
            "chainId": 101,
            "address": "3UQEUUQBatrGkaQSVWNgHGUcATWYgeTF5QBr5Bnazaaa",
            "symbol": "SLPLSS",
            "name": "Sleepless Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3UQEUUQBatrGkaQSVWNgHGUcATWYgeTF5QBr5Bnazaaa/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/sleepless_sols",
                "website": "https://www.sleeplesssols.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CsN3FctQpFeCqKGBkP5mXLdFcpud515v9WQrfWtNatnH",
            "symbol": "ITNW",
            "name": "InfoTech Northwest",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Jakethel/Crypto/main/infotechnorthwest.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4xFVXVK8Hy28MbF1rKGAU4F3sVLbMmzh63wQJeM8k9NW",
            "symbol": "BANG",
            "name": "Bangsta Bear",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/4xFVXVK8Hy28MbF1rKGAU4F3sVLbMmzh63wQJeM8k9NW/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "2dVULCNYvpAeJv4N8SmB2EgxLefawp3QvPcSgSujfj1Q",
            "symbol": "NMW",
            "name": "NMW Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SAVE2014/crypto/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DBLihAKJbDvidBxVXvbf2SnqYCo9B2sRdPQM5iBk9eRG",
            "symbol": "NKT",
            "name": "NOKI Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DBLihAKJbDvidBxVXvbf2SnqYCo9B2sRdPQM5iBk9eRG/logo.png",
            "tags": [
                "stablecoin",
                "asset"
            ],
            "extensions": {
                "website": "https://www.noki-noki.cg/"
            }
        },
        {
            "chainId": 101,
            "address": "BEARSAfSsUmgZNngSeX9CcmLXnSXhdvyVh21Ch1JkCN4",
            "symbol": "OBWL",
            "name": "OKAY BEARS-WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEARSAfSsUmgZNngSeX9CcmLXnSXhdvyVh21Ch1JkCN4/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/okaybears",
                "website": "https://okaybears.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ahiss7vnLA3JZW76TNLkHMHA8FKytdfMnQ5GzEwTt3Ed",
            "symbol": "AHI",
            "name": "Ahi Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/ahiphoenixnft/crypto/main/logo.ahi.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EVERsR6LBAFAiJQ7x53aXSA9kenwvNqWvcmr2LSzqXB6",
            "symbol": "Ever",
            "name": "EverSeed WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EVERsR6LBAFAiJQ7x53aXSA9kenwvNqWvcmr2LSzqXB6/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/playeverseed",
                "website": "https://everseed.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7YnPgaCvBjMvkmyzWSFUrdrooxPxyFSTCePQEg7AkTNZ",
            "symbol": "ASSET",
            "name": "Real Estate Game",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/RealEstateGame/crypto/main/coinREG_001.png",
            "tags": [
                "Social-Token",
                "Realestate-Token",
                "Land-Token",
                "Metaverse-Token",
                "Asset-Token",
                "Property-Token",
                "collectible-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/realestategame_"
            }
        },
        {
            "chainId": 101,
            "address": "4wMR7a4WocQsVdQ47B5hk246c5omx8Wh258TYDvf5v38",
            "symbol": "AST",
            "name": "Awesome Sales Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AlexAaron1/Crypto/main/Screen%20Shot%202022-04-14%20at%2012.58.20%20PM.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/okaybears",
                "website": "https://okaybears.com"
            }
        },
        {
            "chainId": 101,
            "address": "oneiPVVdLSKMAyntNFPq48m4z5B9NQNoHZ3xb1ERUD8",
            "symbol": "ONEIRA",
            "name": "Oneira Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/oneiPVVdLSKMAyntNFPq48m4z5B9NQNoHZ3xb1ERUD8/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/oneira__"
            }
        },
        {
            "chainId": 101,
            "address": "6aJhpKmMpWCRw7vFLc5xxC1fJZGKXZspT5Lxjz5PnWtB",
            "symbol": "SOLARIUM",
            "name": "AGE of SAM token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/3magine/aos-assets/main/coin_small.png",
            "tags": [
                "utility-token",
                "community-coin",
                "game-token",
                "NFT",
                "age-of-sam"
            ],
            "extensions": {
                "discord": "https://discord.gg/ageofsam",
                "twitter": "https://twitter.com/ageofsamnft",
                "website": "https://ageofsam.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Ehbfbo2eYQYcaPeLK7LHN3gZeXfjBQyKopxTQCCKot4i",
            "symbol": "TETO",
            "name": "Poteto",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ehbfbo2eYQYcaPeLK7LHN3gZeXfjBQyKopxTQCCKot4i/logo.svg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/degendojonft",
                "twitter": "https://twitter.com/DegenDojoNFT"
            }
        },
        {
            "chainId": 101,
            "address": "NPCXwv7ag5cFcc4mXnEzCzrsFdCeZLqHx8AXM7W6gPr",
            "symbol": "NPC",
            "name": "NPC Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NPCXwv7ag5cFcc4mXnEzCzrsFdCeZLqHx8AXM7W6gPr/logo.png"
        },
        {
            "chainId": 101,
            "address": "LmbrE6H5LYZUVyFLMTURxxT1bCgBKit3LY2nNg8qVFg",
            "symbol": "LMBR",
            "name": "Warville Lumber",
            "decimals": 6,
            "logoURI": "https://asset.warville.io/token/lmbr.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/warvillenft",
                "website": "https://warville.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DmndK8QHnrqmXrCF4vaG6LZuw18wout7GP9VkZwdojKo",
            "symbol": "DMND",
            "name": "Warville Diamond",
            "decimals": 6,
            "logoURI": "https://asset.warville.io/token/dmnd.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/warvillenft",
                "website": "https://warville.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Food84YUTGWXd2DfYDkDKYRHm64pMe3dcowJkhfFvfmw",
            "symbol": "FOOD",
            "name": "Warville Food",
            "decimals": 6,
            "logoURI": "https://asset.warville.io/token/food.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/warvillenft",
                "website": "https://warville.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8Bm4VdNS9juAtaZVTddgaBbchppDd7M7rerxTVNz5iVv",
            "symbol": "GRAM",
            "name": "Anagram",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Bm4VdNS9juAtaZVTddgaBbchppDd7M7rerxTVNz5iVv/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/anagramgg",
                "website": "https://anagram.gg"
            }
        },
        {
            "chainId": 101,
            "address": "8psN3FhC8FJAD37bRPMNLAYD9PXJnA8zozBn2AfFEoih",
            "symbol": "NEOME",
            "name": "NEOME-com Power to the People",
            "decimals": 9,
            "logoURI": "https://neome.com/token/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "website": "https://www.neome.com"
            }
        },
        {
            "chainId": 101,
            "address": "66EvNhcyyYLBtpLZhCF8pddqzVhKLrVincYiiQw8pCaT",
            "symbol": "OP",
            "name": "Oneiron Coin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/66EvNhcyyYLBtpLZhCF8pddqzVhKLrVincYiiQw8pCaT/One.png",
            "tags": [
                "gaming-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/oneironproject",
                "website": "http://www.oneironproject.com"
            }
        },
        {
            "chainId": 101,
            "address": "wDUsRqqnWQSoanYECLT1PTGvj17WzHwQeujWug6gDb8",
            "symbol": "JSTBZ",
            "name": "JustBaze",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/justbaze/crypto/main/JustBazeCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yHxDCKFCPh3HvXYkENDpA4nSG49Z9ioNrZLZQUwN6W2",
            "symbol": "KUD",
            "name": "Kudos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SecRocker/Crypto/main/Kudo.PNG",
            "tags": [
                "Social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ejk5n6TTb8FsH7UAFu7u4oLT5QfYJ1WgiCfdgzTyVcge",
            "symbol": "JOES",
            "name": "Joes Fat Stacks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jlmcm/JoesFatStacks/main/joefs.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MPRp3T7VewfykhrJTD7end3hNgox5eAuBqoQ1VQjVwb",
            "symbol": "PCC",
            "name": "Pup Club Card Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/metapupsclub/pupcard/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "GKJcQCPuaW6xvkRR6EnRHKP7LGWFYCVCjzLGjoimouTY",
            "symbol": "HEN",
            "name": "HEN COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GKJcQCPuaW6xvkRR6EnRHKP7LGWFYCVCjzLGjoimouTY/102912813.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/smolhentai",
                "website": "https://hentai.lol/"
            }
        },
        {
            "chainId": 101,
            "address": "woTu6cugnrEw6tZqePeXrHGbSJJVsBdHgmQwxTER4R3",
            "symbol": "BUM",
            "name": "Beach Bum Billionaires",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cpwall88/bum/main/logoo.png",
            "tags": [
                "Defi"
            ]
        },
        {
            "chainId": 101,
            "address": "BV2X5dpGqeCrFq4M3h64JsZEqv3Z6snaxkPTmeDhNnD2",
            "symbol": "GBT",
            "name": "Gunnar Beer Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LassePinga/PNG/main/logos/gunnar_beer.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Gunnar San Beer Token!"
            }
        },
        {
            "chainId": 101,
            "address": "3JAtXbi3CZnEVctrwg9w8TWcpw7gtnCZTira33ddGiXg",
            "symbol": "FACE",
            "name": "FACELEZZ TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/facelezz/logo/main/facelogo.png",
            "tags": [
                "nft",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FacelezzNFT",
                "website": "https://facelezz.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "sgsgy4H7TQ5PaSkYPVG7YbU21vnHWQtzwGZf5ftpb5b",
            "symbol": "SGSWL",
            "name": "SGS WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sgsgy4H7TQ5PaSkYPVG7YbU21vnHWQtzwGZf5ftpb5b/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SGSociety_",
                "website": "https://mint.sneakygiraffesociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2TWDSoX19ueT8p5HTwKKxBUVn7Y8BisVWZHw3mHYWqgz",
            "symbol": "THC",
            "name": "TheHighCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2TWDSoX19ueT8p5HTwKKxBUVn7Y8BisVWZHw3mHYWqgz/thc.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WL18mMeknGmb53BWMU6DYCTCBRLpVXbo8yUSFRGXR46",
            "symbol": "SGYC",
            "name": "Skeleton graveyard WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL18mMeknGmb53BWMU6DYCTCBRLpVXbo8yUSFRGXR46/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/SkeletonGraveYC"
            }
        },
        {
            "chainId": 101,
            "address": "AMMCAXk5jt5evNb4PR9KprfxhaM2Tbe2ym45L6cjxcEw",
            "symbol": "NUDI",
            "name": "NUDI Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/divewithgee/nudibranch/main/a.jpg",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MArBPDtkLMb5g2964mx3hjRfTCd3ZeGPVZGpjWp7pWL",
            "symbol": "MRPRBPS2",
            "name": "MiniRoyale S2 Premium BP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MArBPDtkLMb5g2964mx3hjRfTCd3ZeGPVZGpjWp7pWL/logo.png",
            "tags": [
                "game-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/miniroyale",
                "twitter": "https://twitter.com/MiniNations",
                "website": "https://miniroyale.io/"
            }
        },
        {
            "chainId": 103,
            "address": "MArBPDtkLMb5g2964mx3hjRfTCd3ZeGPVZGpjWp7pWL",
            "symbol": "MRPRBPS2",
            "name": "MiniRoyale S2 Premium BP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MArBPDtkLMb5g2964mx3hjRfTCd3ZeGPVZGpjWp7pWL/logo.png",
            "tags": [
                "game-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/miniroyale",
                "twitter": "https://twitter.com/MiniNations",
                "website": "https://miniroyale.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AQpdnoFZKykAdEBNPBie6EshNrcoPiHrqVX2FBmUqLV7",
            "symbol": "COFFM",
            "name": "Coffee-maker",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AQpdnoFZKykAdEBNPBie6EshNrcoPiHrqVX2FBmUqLV7/logo.png",
            "tags": [
                "util-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GXnw9YSt6DANCt84Ti6ZpbaXvrvuEJFCYqrDjygnq4R8",
            "symbol": "ATC",
            "name": "ARTICOIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/mum33n/atc-logo/main/LOGO%20(256).png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/36sYxUUpKn",
                "twitter": "https://twitter.com/Articoin1",
                "website": "https://atcsolana.com/"
            }
        },
        {
            "chainId": 103,
            "address": "9r81b1d2KEtcrwmZr9TnNA8xpCciEWk4UJxbwaAzMfYe",
            "symbol": "HRFX",
            "name": "Hyper Fenix",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/HyperFenix/HyperFenix/main/assets/Logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Mobile cryptocurrency",
                "telegram": "https://t.me/hyperfenix",
                "website": "https://www.hyperfenix.netsons.org/"
            }
        },
        {
            "chainId": 101,
            "address": "4c1Kajn2GDAR7McHfLBWGqRJSEy5ee2MVQTi77DuSqm6",
            "symbol": "SRCN",
            "name": "SaberCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TTVSaber3000/Crypto/main/original_11zon.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HSW1S6xPNR2RXAJKBF6BCSQkoSoLomfm7DVvwydQR2qP",
            "symbol": "SWRD",
            "name": "Sword",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/muzammilkhattri/crypto/main/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.dariusdao.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4oog3cJgry8qrX2KK4nkbq2jZD3vcCmdwpoFykC2fcDW",
            "symbol": "milox",
            "name": "MiloX Token",
            "decimals": 9,
            "logoURI": "https://github.com/miloxOne/miloxtoken/blob/main/miloxpurple.png",
            "tags": [
                "Community-token",
                "utility-token",
                "Social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FFTKNbF95yW764GFKS28wYZETMtQvLPCbEzmEjfpGNkq",
            "symbol": "FRENCHIE",
            "name": "Frenchie Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFTKNbF95yW764GFKS28wYZETMtQvLPCbEzmEjfpGNkq/logo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wqfc7xXHf6",
                "twitter": "https://twitter.com/fancyfrenchnft"
            }
        },
        {
            "chainId": 101,
            "address": "2D2oVygEnGoWrqZVaDvPNPV1iXzJP8NDZ16K2G7Nct9E",
            "symbol": "cyberbabychimps",
            "name": "Cyber chimp Babies Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/CyberChimpsnft/chimpstoken/main/token/unknown%20(2).png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wqfc7xXHf6",
                "twitter": "https://twitter.com/fancyfrenchnft"
            }
        },
        {
            "chainId": 101,
            "address": "S5VeynSoQZtHN3kN8RxsbD87gXgfFjLCUYK2xGiaLee",
            "symbol": "CJC",
            "name": "CJCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mentales2005/CJCoin/main/CJCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9BkVkR8Ff1mCnxVFmvYgqLooifC8ZUWWkxU6JWz5JK3f",
            "symbol": "PRSPR",
            "name": "Prosperity Coin",
            "decimals": 0,
            "logoURI": "https://github.com/prosperitycrypto/prosperitycoin/blob/main/PRSPR.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MFP66DGQUp8D4LmgYb6W93Du5XHA4ctzVwmTHkYZQwL",
            "symbol": "MFP",
            "name": "Mindfolk Pirates Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img924/1131/e5Ubet.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mindfolkart",
                "website": "https://www.mindfolk.art/"
            }
        },
        {
            "chainId": 101,
            "address": "FQtkv5hb7irYUqB6429XLSVfkZD1Kp595xP7jZQQKT2u",
            "symbol": "FPCT",
            "name": "FINGERPRINT CIRCUS TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TerraFirma1/crypto/main/fpct-logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "49AiDWZ1GU2SHuXoiNPVJrW1MN76zFZBNvqud8yoRuCk",
            "symbol": "YATR",
            "name": "Yatr",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/YatterOfficial/YATR-Crypto/main/logo.png",
            "tags": [
                "Yatr",
                "yatter",
                "You-At-A-Resource",
                "Yatr-App-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "EHj9iUmUrQ5bybEernUPpULfPa1YKkrRArGHckYreRkg",
            "symbol": "FTRZ",
            "name": "Fighterz",
            "decimals": 4,
            "logoURI": "https://solaboy.github.io/images/fighterz.png",
            "tags": [
                "gaming",
                "utility-token",
                "solana"
            ],
            "extensions": {
                "description": "$FTRZ is the official utility token for the Bit Fighterz. Use $FTRZ to upgrade your Bit Fighterz! (Exclusive tickets, mints utilizing $FTRZ, in game items)",
                "discord": "https://discord.gg/26DwVZT6",
                "twitter": "https://twitter.com/bitfighterz",
                "website": "https://www.bitfighterz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Wobb898LuextGV9EcPMxEvWzprPSAAmyo475ZBQxgma",
            "symbol": "WOBB",
            "name": "WOBBLEBUG WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Wobb898LuextGV9EcPMxEvWzprPSAAmyo475ZBQxgma/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/wobblebug",
                "website": "https://wobblebug.info"
            }
        },
        {
            "chainId": 101,
            "address": "9DV8ocDmmsJEcPX9jvfepjmsgFk97uVSiZQsP5qbMXb9",
            "symbol": "ONEDROP",
            "name": "OneDropToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9DV8ocDmmsJEcPX9jvfepjmsgFk97uVSiZQsP5qbMXb9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Kd5hAD9gNg",
                "twitter": "https://twitter.com/OneDropNFTs",
                "website": "https://onedropnfts.com"
            }
        },
        {
            "chainId": 101,
            "address": "D4GGY5JRZNmxnw2XWApruyxDfZd7X9N2xZzBxvJawDCv",
            "symbol": "OPD",
            "name": "Oneiron Dark",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4GGY5JRZNmxnw2XWApruyxDfZd7X9N2xZzBxvJawDCv/OPBlack.png",
            "tags": [
                "ownership-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/oneironproject",
                "website": "http://www.oneironproject.com"
            }
        },
        {
            "chainId": 101,
            "address": "8LSLFmXNiuvzWdCfPHtMSnr8k3Knx7AxrYttj3H7HoTu",
            "symbol": "FIRE",
            "name": "FireCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/johnyg127/firecoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KusHsPAeDvxkstzezKxu1FiGQEBU8EhGmPttFuXdZMW",
            "symbol": "KU$H",
            "name": "Stoned Shiba Kush",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KusHsPAeDvxkstzezKxu1FiGQEBU8EhGmPttFuXdZMW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/stonedshibaclub"
            }
        },
        {
            "chainId": 101,
            "address": "2hLu9vvevNRPa8cASbvnest1BrBNAaXL7WiVtvL65QRX",
            "symbol": "ESPC",
            "name": "EsportCurrency",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EsportsCurrency/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5uB44vtqsgWMu2BDr4owwSequHDhDYtJ4pBZn4oUFhfi",
            "symbol": "HODLR",
            "name": "The Hodlers Project Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Randomkushh/gitnote-images/main/images/HODLR%20token.png",
            "extensions": {
                "twitter": "https://twitter.com/hodlersproject",
                "website": "https://thehodlersproject.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GodsvjpSKruMFKaPCBBWw4S66RepEzToPqjUhiheTNMZ",
            "symbol": "SGWL",
            "name": "Stoned Gods WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GodsvjpSKruMFKaPCBBWw4S66RepEzToPqjUhiheTNMZ/logo.png"
        },
        {
            "chainId": 101,
            "address": "9VXYvA15RhQndzjRrt7DyhiXPSurLKBsfwPHMDyN6s5H",
            "symbol": "SolPunks",
            "name": "SolPunks 3D Whitelist",
            "decimals": 9,
            "logoURI": "https://storage.googleapis.com/solpunkz/logo.png",
            "tags": [
                "nft",
                "community-token"
            ],
            "extensions": {
                "animationUrl": "https://storage.googleapis.com/solpunkz/solpunkz.mp4",
                "description": "SolPunks 3D Whitelist redeemable token",
                "imageUrl": "https://arwave.art/gweq2vxtzpngk1eqlq6k/hefh2g.png",
                "website": "https://solpunks.club"
            }
        },
        {
            "chainId": 101,
            "address": "DeadKA6Z6GQfsQWjCehzj1gJQ3oPTEXMpQsyKYJr2qR2",
            "symbol": "DEADREJECT",
            "name": "Dead Reject Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitcoinblack482/logo/main/dead%20reject.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeadRejcts",
                "website": "https://deadrejects.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur",
            "symbol": "PLD",
            "name": "Plutonian DAO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Plutonian DAO Token",
                "serumV3Usdc": "FcAadXjF1bYQxnjFDMN6igivSYeLbabXDKjx284pagBy",
                "twitter": "https://twitter.com/plutoniansgame",
                "website": "https://plutonians.tech"
            }
        },
        {
            "chainId": 101,
            "address": "EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3",
            "symbol": "RPC",
            "name": "Republic Credits",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Republic Credits",
                "serumV3Usdc": "Dujraambe9WWEtgswwccoJWMtE4Rnz2Ue4X2cGJB7keB",
                "twitter": "https://twitter.com/plutoniansgame",
                "website": "https://plutonians.tech"
            }
        },
        {
            "chainId": 101,
            "address": "7p6zGHdmWHvCH4Lsik2MoMBXqPGhFbSPSceSBXd8KNEC",
            "symbol": "PU238",
            "name": "Plutonium 238",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7p6zGHdmWHvCH4Lsik2MoMBXqPGhFbSPSceSBXd8KNEC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Plutonium 238",
                "twitter": "https://twitter.com/plutoniansgame",
                "website": "https://plutonians.tech"
            }
        },
        {
            "chainId": 101,
            "address": "7PAsFWDR9VZosY4NYkXAZZj4kKmHh8zkMh9ea488w8Y6",
            "symbol": "OPR",
            "name": "Oneiron Red",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7PAsFWDR9VZosY4NYkXAZZj4kKmHh8zkMh9ea488w8Y6/OPRed.png",
            "tags": [
                "ownership-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/oneironproject",
                "website": "http://www.oneironproject.com"
            }
        },
        {
            "chainId": 101,
            "address": "CoraLpUPGUUciXJdeS13Ljv6cjXWsnTVSc1ffzFJryib",
            "symbol": "CCT",
            "name": "Crypto Coral Tribe Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CoraLpUPGUUciXJdeS13Ljv6cjXWsnTVSc1ffzFJryib/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/B4MJcX72g9",
                "twitter": "https://twitter.com/CoralTribeNFT",
                "website": "https://www.cryptocoraltribe.io"
            }
        },
        {
            "chainId": 101,
            "address": "CX4y3ACwjmfMfK2nfmx2vRq5KDpQnREjLBHVDERSbsu1",
            "symbol": "EV",
            "name": "Ev Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sergioooo94/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PXLSmSBWHU8yAqNof9Ry2LPsZxHSYB4xXsBaQWUmEzV",
            "symbol": "Pixels",
            "name": "Pixels",
            "decimals": 0,
            "logoURI": "https://arweave.net/GpuEa7JiRH0-trJ-B25Pjh-DYt0-RaXjzPTIEVGUOdk?ext=gif",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solsunsets",
                "website": "https://solsunsets.com/"
            }
        },
        {
            "chainId": 101,
            "address": "44B2P7F4ArPt9E2t7Kzvzx3BVqWV65Bt6qzJZH2YZmwR",
            "symbol": "FTH",
            "name": "Fear The Hill Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BMcBride89/Fear-The-Hill-Coin/main/FTH-logos_transparent%5B1%5D.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CjZUiiXnnjBsALvYLBjwibeBBYSngSKjBE3LbhNZx6yp",
            "symbol": "$TRASH",
            "name": "Trash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CjZUiiXnnjBsALvYLBjwibeBBYSngSKjBE3LbhNZx6yp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Bmx5dep2fM",
                "twitter": "https://twitter.com/OpossumPosseNFT"
            }
        },
        {
            "chainId": 101,
            "address": "WsyX1zaGR6EMkpXdawxtA7W7cq46zx5LaV6rwwb4Zir",
            "symbol": "PTK",
            "name": "PetikuCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SpriteyOnTheDraconic/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8FwNHtCumEnZFAkrQLFEz7ngt2rXPjX3Sqt27d7xp35u",
            "symbol": "WLT1",
            "name": "WhiteList MeltLabs 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FwNHtCumEnZFAkrQLFEz7ngt2rXPjX3Sqt27d7xp35u/logo.png",
            "extensions": {
                "website": "https://www.meltlabs.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "HMBcrrSFdVVn5APB6zT76btXg4TgqBKXioaWzTZDConp",
            "symbol": "WLT2",
            "name": "WhiteList MeltLabs 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HMBcrrSFdVVn5APB6zT76btXg4TgqBKXioaWzTZDConp/logo.png",
            "extensions": {
                "website": "https://www.meltlabs.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "8TmXs3ePSkXNsCrNvMJmgTsdBuHcynCaqVBWmASRVEGV",
            "symbol": "BET",
            "name": "Ayt Bet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AytBet/aytbet/main/logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D735UtZQuPGXWecCJcJwqk35eydNaXk4b9pryGULrP3i",
            "symbol": "BPT",
            "name": "Black Penny token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/banton84/Black-Penny/main/logo.png",
            "tags": [
                "utility-Token",
                "collectible-token",
                "NFT-token"
            ]
        },
        {
            "chainId": 101,
            "address": "p9o6pK39LNrjepTy6vJ9CbebthaMSAdxK797PobEqAf",
            "symbol": "AYLLA",
            "name": "Aylla Coin",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmP4Zz9TLxY5agmLrSi3NJuCc9zxeMYoSzvFe8wUXK3hRT",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "64tNPiqghogsrKuRaHmdP3Pjc39JRvQBnCDh6CjCEw75",
            "symbol": "PROSPER",
            "name": "Prosperity",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Prosperity-Coin/crypto/main/PRSPR.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2poDkzS8LQidSmuEeVtYF2faDUkdU8PsRyc7ZS9qiscA",
            "symbol": "BEARCOIN",
            "name": "Bearcoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/sarwocrypto/token/main/logo.png",
            "tags": [
                "DeFi",
                "DEX"
            ],
            "extensions": {
                "discord": "https://discord.gg/htBfxH9je4",
                "telegram": "https://t.me/bearcoinglobal",
                "twitter": "https://twitter.com/bearcoinsolona"
            }
        },
        {
            "chainId": 101,
            "address": "AnvURtZKryH6WA9zE1JTQM7gnHEHhTzhBnY6825jMwAM",
            "symbol": "MJTK",
            "name": "MJToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jaszczomp71/krypto/main/ikonahawk.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4SGJGMt8hjHKSFVUZPKUUdZL3cK69avvuUJiizmtVDj5",
            "symbol": "CHUBBY",
            "name": "Chubby Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SGJGMt8hjHKSFVUZPKUUdZL3cK69avvuUJiizmtVDj5/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DKhGCZsC57jNLKJXAfCsPbhVuxKdCCHfJYMhLgXuCvVi",
            "symbol": "RCKC",
            "name": "RockyCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/soperio/cripto/main/ratonero.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DP7F9J3X6g2ikVp6Y8WKVnxYwdXHYCeBHbouBbeQbevy",
            "symbol": "CHIEF",
            "name": "CHIEFIN CHIMP COIN",
            "decimals": 6,
            "logoURI": "https://github.com/boosbasement/metaboss/blob/main/Gold%20Status.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5YqSGoJtA5KZY784hoMV9DAkLDE8EGjSNr3YNQoVW8DK",
            "symbol": "MAXI",
            "name": "MAXIG",
            "decimals": 0,
            "logoURI": "https://github.com/uulen1/crypto/blob/main/IMG_6102.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLwKFNCKESMfbYU1o7hzp868AtyFHGpbC9i4jUTHpQb",
            "symbol": "TMDC-X-WL",
            "name": "TMDC-X WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/namu42/ImageRepo/main/WLwKFNCKESMfbYU1o7hzp868AtyFHGpbC9i4jUTHpQb/wl_token_logo.png",
            "tags": [
                "Whitelist-Token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "5g7f9b5HbUVAgPkeDDZFHZ6QP9U8AT4KXMx4pAfUU8Ni",
            "symbol": "AANDEELHOUDERS",
            "name": "Aandeelhoudersregister Jansen en Dochters BV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5g7f9b5HbUVAgPkeDDZFHZ6QP9U8AT4KXMx4pAfUU8Ni/blockPNG.png",
            "tags": [
                "Jansen-en-Dochters",
                "aandeelhouders",
                "register"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/company/blockstone-online/",
                "website": "https://explorer.solana.com/address/JANSen9otUNFWvBcU6tj3bufozybNZVqvtH64ZXZPggL/tokens"
            }
        },
        {
            "chainId": 101,
            "address": "GNC9uTx8dBun94hM8PeRmEGBb7LJ7uKrgNBnooAaQzXN",
            "symbol": "GNC",
            "name": "Gong Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GNC9uTx8dBun94hM8PeRmEGBb7LJ7uKrgNBnooAaQzXN/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/GongMaster6"
            }
        },
        {
            "chainId": 101,
            "address": "LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp",
            "symbol": "LFNTY",
            "name": "Lifinity",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp/logo.svg",
            "extensions": {
                "discord": "https://discord.com/invite/K2tvfcXwWr",
                "medium": "https://medium.com/@lifinity.io",
                "twitter": "https://twitter.com/Lifinity_io",
                "website": "https://lifinity.io/"
            }
        },
        {
            "chainId": 101,
            "address": "xLfNTYy76B8Tiix3hA51Jyvc1kMSFV4sPdR7szTZsRu",
            "symbol": "xLFNTY",
            "name": "xLifinity",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xLfNTYy76B8Tiix3hA51Jyvc1kMSFV4sPdR7szTZsRu/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/K2tvfcXwWr",
                "medium": "https://medium.com/@lifinity.io",
                "twitter": "https://twitter.com/Lifinity_io",
                "website": "https://lifinity.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GENZtsF6jVTLyPToMxJR9jz4VvH5i3QrKhJburvfyDZE",
            "symbol": "GENZ",
            "name": "Gen Zen Early Whitelist",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GENZtsF6jVTLyPToMxJR9jz4VvH5i3QrKhJburvfyDZE/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZenBenmore"
            }
        },
        {
            "chainId": 101,
            "address": "3sDP1g1LpiCqPCc4CsaSpqmEv4Uvf46kzg7XeRdfLsmk",
            "symbol": "BKD",
            "name": "BakedGods WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3sDP1g1LpiCqPCc4CsaSpqmEv4Uvf46kzg7XeRdfLsmk/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FECgEnETvkCeDpJdLyURiHRZNiXw63JfHfxi1fUL3RrR",
            "symbol": "OPrism",
            "name": "Overprism",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FECgEnETvkCeDpJdLyURiHRZNiXw63JfHfxi1fUL3RrR/logo.png",
            "tags": [
                "utility-token",
                "DeFi"
            ],
            "extensions": {
                "description": "BucketHead Studios Utility Token",
                "discord": "https://discord.gg/bucketheadstudios"
            }
        },
        {
            "chainId": 101,
            "address": "7ZeD9kt1J7n61YJ1fJe9dGDaLzKBktieRGHxjkAk1m7r",
            "symbol": "ZILLION",
            "name": "Zillion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ZeD9kt1J7n61YJ1fJe9dGDaLzKBktieRGHxjkAk1m7r/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BlueChipClique"
            }
        },
        {
            "chainId": 101,
            "address": "3mnAvh37Zp3iKTmKh7yVZTu1272EdoLDbYPMbwBGqQzF",
            "symbol": "CHUNG",
            "name": "Chung Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3mnAvh37Zp3iKTmKh7yVZTu1272EdoLDbYPMbwBGqQzF/logo.svg",
            "tags": [
                "social-token",
                "sports-token",
                "utility-token",
                "game-token",
                "community-token",
                "metaverse",
                "GameFi",
                "SocialFi"
            ],
            "extensions": {
                "github": "https://github.com/piuchung",
                "twitter": "https://twitter.com/piuchung",
                "website": "https://pchung.com"
            }
        },
        {
            "chainId": 101,
            "address": "2FtCJ25UmSAHiCwbEccrop24wgybiTYLTYhGXi4nBaVk",
            "symbol": "SHITZ+",
            "name": "The Shitz+",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FtCJ25UmSAHiCwbEccrop24wgybiTYLTYhGXi4nBaVk/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/theshitz",
                "website": "https://theshitz.io"
            }
        },
        {
            "chainId": 101,
            "address": "FXekW7DcvMtfHgamPFk2NddobxnRVxwf592uUpxtykja",
            "symbol": "AWL",
            "name": "Astral Witches WL",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/GokhanCerk/assets/main/aw.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AstraIWitches"
            }
        },
        {
            "chainId": 101,
            "address": "igsvRjB6uyVMGcM9nbWwESxN1eTfVTPiQ1ThoCc8f2g",
            "symbol": "IGS",
            "name": "Ielian Global Standard",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/igsvRjB6uyVMGcM9nbWwESxN1eTfVTPiQ1ThoCc8f2g/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/tatsu",
                "twitter": "https://twitter.com/tatsumeeko",
                "website": "https://tatsumeeko.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2DwfLs6gTAj7e4PHe9XAZDbv9G8At7YqGcHiRhbFeYhk",
            "symbol": "GOLOK",
            "name": "Golok",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DwfLs6gTAj7e4PHe9XAZDbv9G8At7YqGcHiRhbFeYhk/golok.svg",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discordapp.com/users/839508027446788126",
                "twitter": "https://twitter.com/5ud1pt0"
            }
        },
        {
            "chainId": 101,
            "address": "EDLY8hdW9aVYNf12WTsRb6qshNqN2SjG7LbAAyQnLBbU",
            "symbol": "CACLUB",
            "name": "Cannabis Art Club",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/AMAF90/caclub/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://thcamera.pt/nft/"
            }
        },
        {
            "chainId": 101,
            "address": "WLbrRsNqSSeDvDgJ68pwMgGsH5zQfRBFhe7VMvKf2xM",
            "symbol": "KLVX-WL",
            "name": "KLVX-WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/namu42/ImageRepo/main/WLbrRsNqSSeDvDgJ68pwMgGsH5zQfRBFhe7VMvKf2xM/box3.png",
            "tags": [
                "Whitelist-Token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "CoRa1GrotgHW1HVwjR1ZHvABugUxFrYcLwHkiA2gu4KW",
            "symbol": "CoralTribe",
            "name": "CoralTribe Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitcoinblack482/logo/main/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CoralTribeNFT",
                "website": "https://www.cryptocoraltribe.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AYHyLqEdGJJUEDYd5NEHrRXRbCJdiSWZvqAzSpxnuJs7",
            "symbol": "VAULT",
            "name": "VC Vault",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/istracoin-dev/crypto/main/logo.png",
            "tags": [
                "gaming"
            ]
        },
        {
            "chainId": 101,
            "address": "B8U4h9teR9qfjkXRCnmbEcJs8Dimbz2vzdKpHmLPBDVs",
            "symbol": "HND",
            "name": "Hand",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B8U4h9teR9qfjkXRCnmbEcJs8Dimbz2vzdKpHmLPBDVs/LogoH.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3fyPKsFZVmbGPKuYLTf5Pk7NBDQhUqXDMx95gHxmCU4x",
            "symbol": "GOO",
            "name": "GOO Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3fyPKsFZVmbGPKuYLTf5Pk7NBDQhUqXDMx95gHxmCU4x/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.googlitz.info"
            }
        },
        {
            "chainId": 103,
            "address": "ABAJYr778ESNN6XKNG83io5HcbEKmnEY3LLxhw5kx5pB",
            "symbol": "ISSU",
            "name": "issugo coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/issugo/test_solana_crypto/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "mpcqShNDqz3nLGUXg4cr8bkxiCJEwHvdAJCevouj1nU",
            "symbol": "MPCWL",
            "name": "Meta Pups Whitelist",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/metapupsclub/PupMint/main/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metapupsclub",
                "website": "https://www.metapupsclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5QDRAruauPb6pN8oJnHfaNEBr4XZxmwWR6hLs4rha3qs",
            "symbol": "DSLT",
            "name": "Defi-SOL-LT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5QDRAruauPb6pN8oJnHfaNEBr4XZxmwWR6hLs4rha3qs/logo.png",
            "extensions": {
                "discord": "https://discord.gg/6DWz82RWbM",
                "twitter": "https://twitter.com/coherencefi",
                "website": "https://coherence.finance"
            }
        },
        {
            "chainId": 101,
            "address": "uWiSDpV8ZBNmpv54PFDdE1RzqmSyiEKZhvFWR5nCPvz",
            "symbol": "SRMEI",
            "name": "Serum Ecosystem Index",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uWiSDpV8ZBNmpv54PFDdE1RzqmSyiEKZhvFWR5nCPvz/logo.png",
            "extensions": {
                "discord": "https://discord.gg/6DWz82RWbM",
                "twitter": "https://twitter.com/coherencefi",
                "website": "https://coherence.finance"
            }
        },
        {
            "chainId": 101,
            "address": "8BLGq9PG22RKVMy28jKrWEvhS9VMPMPFDEj3pRiTqu7T",
            "symbol": "DRAT",
            "name": "Dragontongue",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/blackbaron43/crypto2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EAzCTpMGRjFmGf4MaiMVNqv6KxQNoM6HEXtPZLEtEivB",
            "symbol": "DECI",
            "name": "Decimus Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAzCTpMGRjFmGf4MaiMVNqv6KxQNoM6HEXtPZLEtEivB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DecimusDynamic",
                "website": "https://www.decimusdynamics.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3evMUC9VTLsf1QSY7UjJMX2coh3ZvNb1pYCbmcTJQ43o",
            "symbol": "SMLWL",
            "name": "SmiliNFT WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3evMUC9VTLsf1QSY7UjJMX2coh3ZvNb1pYCbmcTJQ43o/logo.png",
            "tags": [
                "NFT",
                "Smile",
                "Whitelist"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nftsmilin",
                "website": "http://smilinft.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "GuomCtZ5E8EQAtHFxuPi282w692mKcDNrZ96CiHChLcU",
            "symbol": "SNIA",
            "name": "Sonia Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Firebros1/crypto/main/IMG_0509.jpg",
            "tags": [
                "Fast-Growing",
                "Low-quanity"
            ]
        },
        {
            "chainId": 101,
            "address": "BbBa2LR1JQ7Scq993vPkYoYDpQDntduMAcRS27wWtEi1",
            "symbol": "MORPA",
            "name": "MORP Alpha",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MorpAlpha/crypto/main/MP.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheInfungibles",
                "website": "https://infungibles.io/"
            }
        },
        {
            "chainId": 101,
            "address": "MAKiXQ4symVDjH6CQkeFCYdqovDmokr6ZigwvrNX6RF",
            "symbol": "MAKI",
            "name": "MAKIMA EARLY WL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MAKiXQ4symVDjH6CQkeFCYdqovDmokr6ZigwvrNX6RF/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MakimaNFT"
            }
        },
        {
            "chainId": 101,
            "address": "653yTMNJUUSvyXPA6rinxzNdeWRRw1VtajhEosihTX9K",
            "symbol": "BLK",
            "name": "Blue K",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/avkawakami/bluek/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9uBUX5YMtc3iYSmqmxHne8zyn3Hc2xZ3DazDmdZjaxwr",
            "symbol": "MNWL",
            "name": "MYNFTGG WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9uBUX5YMtc3iYSmqmxHne8zyn3Hc2xZ3DazDmdZjaxwr/logo.png",
            "extensions": {
                "discord": "https://discord.gg/7CqqqfNzEe",
                "twitter": "https://twitter.com/mynftgg",
                "website": "https://mynft.gg"
            }
        },
        {
            "chainId": 101,
            "address": "MPCsKBRTbpNnzJEB9EBxYUF9CZDYuvKBfpVDbTyR1EW",
            "symbol": "MPCLaunch",
            "name": "Meta Pups Club Launching 420",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/metapupsclub/PupMint/main/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metapupsclub",
                "website": "https://www.metapupsclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Eggb3Wuuwb6S7dy2swEZC5kLWR16Tq8F9e41jXym1ZHv",
            "symbol": "EGGMINT",
            "name": "Moondusa Egg Minter",
            "decimals": 0,
            "logoURI": "https://arweave.net/L3vjQctp6E6eRf132es8odtyTZ2FFP0grigdusXE_Ts",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/moondusa",
                "telegram": "https://t.me/MoondusaTheNFT",
                "twitter": "https://twitter.com/Moondusa",
                "website": "https://moondusanft.io"
            }
        },
        {
            "chainId": 101,
            "address": "incdcidLUVUGaMtDhkRdfdVDJG6eAxzjAvRzSxjKWWT",
            "symbol": "INCMINT",
            "name": "Moondusa Incubator Minter",
            "decimals": 0,
            "logoURI": "https://arweave.net/ereeYqNQoJYmXavjVBgApGuDk2TZYktyMIP77GIWmKs",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/moondusa",
                "telegram": "https://t.me/MoondusaTheNFT",
                "twitter": "https://twitter.com/Moondusa",
                "website": "https://moondusanft.io"
            }
        },
        {
            "chainId": 101,
            "address": "13kRkiqq9rUeLqJisE1dWK6d7K1rS4sVQvxoqF5nkWmz",
            "symbol": "EGT",
            "name": "Ecogoldtech SAS Asset",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/13kRkiqq9rUeLqJisE1dWK6d7K1rS4sVQvxoqF5nkWmz/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "security-token",
                "community-token"
            ],
            "extensions": {
                "description": "EGT representa las acciones tokenizadas oficiales de la compañía minera colombiana Ecogoldtech S.A.S. EGT es un token de utilidad para nuestro protocolo de credibilidad universal, respaldado por la solidez, robustez y transparencia de un fuerte motor productivo y comercial basado en oro como commodity y valor refugio a nivel global. La posesión de una acción EGT representa un compromiso entre Ecogoldtech S.A.S y sus accionistas, en el que se comprenden beneficios exclusivos y corporativos a cambio del mantenimiento de la acción durante cierto periodo de tiempo. Creemos que EGT representa la modernización de la industria tradicional en una nueva era digital, con la capacidad de poner a disposición de todos grandes oportunidades económicas y globales.",
                "website": "https://ecogoldtech.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9bU27s4Gq8LwtsT3bKCDXV3tVvBYrzDnBZu37bHMV3u7",
            "symbol": "BOYT",
            "name": "BOYTToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BOYTToken/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5U3FjQwMtD2zWqGnMj7WCQEEEfyUChwPUQXVooWEjGpt",
            "symbol": "MTER",
            "name": "MOTER COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DCooney104/Moter/main/IMG_7142.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "By2g5Ln9tNYr5fbxzT8teBLNrYfjjDfXqbCe5Z8ya9X8",
            "symbol": "UBQUOIN",
            "name": "UBQUOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/By2g5Ln9tNYr5fbxzT8teBLNrYfjjDfXqbCe5Z8ya9X8/logo.png",
            "tags": [
                "fitness-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FEkvXnyW7sPV1Xa4w7SZfMGsGnf5kaCoAAxHQVVfUs2Z",
            "symbol": "ASG",
            "name": "Anima Spirit Gem",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FEkvXnyW7sPV1Xa4w7SZfMGsGnf5kaCoAAxHQVVfUs2Z/logo.png",
            "tags": [
                "nekoverse",
                "game-fi"
            ],
            "extensions": {
                "discord": "https://discord.gg/nekoverse",
                "twitter": "https://twitter.com/Nekoverse_NFT",
                "website": "https://nekoverse.net/"
            }
        },
        {
            "chainId": 101,
            "address": "B3DRD16zzuybK5Ku4Vv79hp6cxkBmZvTnrDx16N5D5ct",
            "symbol": "Champ",
            "name": "Champagne",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B3DRD16zzuybK5Ku4Vv79hp6cxkBmZvTnrDx16N5D5ct/logo.png",
            "tags": [
                "NFT-Reward-Token"
            ],
            "extensions": {
                "discord": "https://t.co/xSDuZpVj7F",
                "instagram": "https://www.instagram.com/the_wolves_club_nft/",
                "twitter": "https://twitter.com/the_wolves_club",
                "website": "https://thewolvesclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2pUjVFjDLM4f5XXZn7b1NmyHhNtUMZvst6FfPU5yonE1",
            "symbol": "CYC",
            "name": "CYCLUS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kristerus/CyclusToken/main/logo.jpg",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/cyclus.coin/"
            }
        },
        {
            "chainId": 101,
            "address": "EWB6knBPpu4LSD5QNYA6tp4QsnnoLcgemhDk2pePPksL",
            "symbol": "KYOX",
            "name": "Kyobo Genesis KYOX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EWB6knBPpu4LSD5QNYA6tp4QsnnoLcgemhDk2pePPksL/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "website": "https://stake.kyobo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3wRoDsZCKosQQdB7bzL46q9qfEK9PPQrQWKx6R8K4Y5Q",
            "symbol": "PEAK",
            "name": "PEAK ATTITUDE COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoBigMike/PEAKATTITUDE/main/PEAK_logo.png",
            "tags": [
                "metaverse-token",
                "nft-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ToDvKbmzMmy5DcMDrJr7dw6PEZR19CQzMzmo7dpQ6Wo",
            "symbol": "CCMINT",
            "name": "Cyberchips Whitelist Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToDvKbmzMmy5DcMDrJr7dw6PEZR19CQzMzmo7dpQ6Wo/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FdnJT3tfvytFmb39C1pWZ2udNbcz3stYKF7ZxhQfJzee",
            "symbol": "BWC",
            "name": "BIGWHITECOCK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SamRice07/Assets/main/128474478-ein-cartoon-penis-mit-haltung-sonnenbrillen-und-daumen-hoch.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6n5FDeJYa5DJcqjzfAChtEwMnWJZAKxuTsFLjqPe27YN",
            "symbol": "BWHC",
            "name": "BIG WHITE HAIRY COCK",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SamRice07/Assets/main/128474478-ein-cartoon-penis-mit-haltung-sonnenbrillen-und-daumen-hoch.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D8tjKUmAGegeeqiWNUYKTiSYH59BWQAUC5ssVno22A5N",
            "symbol": "CSJ",
            "name": "Common Soul",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D8tjKUmAGegeeqiWNUYKTiSYH59BWQAUC5ssVno22A5N/logo.png",
            "tags": [
                "social-token",
                "nft-token",
                "game-token",
                "jjcverse",
                "common-soul"
            ]
        },
        {
            "chainId": 101,
            "address": "9PBAUwKiB5tWjJZF6Ywa1EwdotVTU74iprxTD72Xjvjq",
            "symbol": "RSJ",
            "name": "Rare Soul",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9PBAUwKiB5tWjJZF6Ywa1EwdotVTU74iprxTD72Xjvjq/logo.png",
            "tags": [
                "social-token",
                "nft-token",
                "game-token",
                "jjcverse",
                "common-soul"
            ]
        },
        {
            "chainId": 101,
            "address": "AUFCN7XybLNPgFhjenxMfKnWdFonSdNfyKnVqxDq4gwx",
            "symbol": "LSJ",
            "name": "Legendary Soul",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AUFCN7XybLNPgFhjenxMfKnWdFonSdNfyKnVqxDq4gwx/logo.png",
            "tags": [
                "social-token",
                "nft-token",
                "game-token",
                "jjcverse",
                "common-soul"
            ]
        },
        {
            "chainId": 101,
            "address": "HxBtpdMV1FZ4kDeZLkjeWNyFqhk4riNNnd6vMAGA4aYd",
            "symbol": "SCO",
            "name": "SCOCOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/silverspruce/asssets/main/logo.png",
            "tags": [
                "arbitrage-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4CHXd5uSMb9qrLfQwvyHzDMiKmNKDP8Np23dAQWTKNEy",
            "symbol": "LPFi",
            "name": "LP Finance DAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/LPFi.png",
            "tags": [
                "governance-token",
                "LP-Finance"
            ],
            "extensions": {
                "discord": "https://discord.gg/fbcA5A3T",
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 101,
            "address": "FWU58ZPPArx1H2CreBijPi6GeutCrCBwDNGZSqitXP2x",
            "symbol": "ANZ",
            "name": "ANZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FWU58ZPPArx1H2CreBijPi6GeutCrCBwDNGZSqitXP2x/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "BZoVtTzp5dLu3PM7svq22tbMeUtVhwLJEKFdYxBh8aPb",
            "symbol": "NAB",
            "name": "NAB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZoVtTzp5dLu3PM7svq22tbMeUtVhwLJEKFdYxBh8aPb/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "2qR6rPek1LnGUMfk8KCLkZRUvJyykUP2ekxjpaoXdnpS",
            "symbol": "CBA",
            "name": "CBA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qR6rPek1LnGUMfk8KCLkZRUvJyykUP2ekxjpaoXdnpS/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "57scJda2NVKMK2F5t3dipHv6RrVNqcMKXXsE77LYqJD7",
            "symbol": "WBC",
            "name": "WBC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/57scJda2NVKMK2F5t3dipHv6RrVNqcMKXXsE77LYqJD7/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "ChTL913xim59qeXw5Z4unh5cAYPCEWKJWb7RnzRpE99",
            "symbol": "USB",
            "name": "US Bancorp",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChTL913xim59qeXw5Z4unh5cAYPCEWKJWb7RnzRpE99/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "DVFQo5ttz5SAhFLKPbbgtfMN6HrePHjurmfZKYWLCQ3i",
            "symbol": "JPM",
            "name": "JPMorgan Chase",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DVFQo5ttz5SAhFLKPbbgtfMN6HrePHjurmfZKYWLCQ3i/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "3c6gg3nbzB8tCERQBk8jApUECBktvJrmgeULN9Q7JZSE",
            "symbol": "BOA",
            "name": "Bank Of America",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3c6gg3nbzB8tCERQBk8jApUECBktvJrmgeULN9Q7JZSE/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "7nuLPp3wKCeFmF6tfSr2bYMrXLh9yFpVpg9RwQsh5BqV",
            "symbol": "BANKS",
            "name": "US Bank",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7nuLPp3wKCeFmF6tfSr2bYMrXLh9yFpVpg9RwQsh5BqV/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "CRRPG57uFaG4Rbfauski7PuSRLQDMsoABtF3UTyhFraT",
            "symbol": "GEC",
            "name": "Green Energy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRRPG57uFaG4Rbfauski7PuSRLQDMsoABtF3UTyhFraT/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FEMcWftTLmnG1vuZZCR1qcRvK5dew4heeaG44QNs5EHS",
            "symbol": "FEM",
            "name": "Femnatns Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/v2/225x225q90/923/mCjHD3.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RemnantsNFT",
                "website": "https://theremnantsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "VoxnYpJxkriwgBdARwsYPbheGCjXfWi8NFsDYsfP14Q",
            "symbol": "VOXN",
            "name": "VoxNinjas Loot",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VoxnYpJxkriwgBdARwsYPbheGCjXfWi8NFsDYsfP14Q/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/VoxNinjasNFT"
            }
        },
        {
            "chainId": 101,
            "address": "C4VW9CKc8mPBMmJsqDpTF24TwYpbLW1aTzhRevMfWUXi",
            "symbol": "AW",
            "name": "Astral Witches Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GokhanCerk/assets/main/wl.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AstraIWitches"
            }
        },
        {
            "chainId": 101,
            "address": "9JDC8fFtXNjTDyQbsquAnMPDe66XFXkKcYLubmpywfRg",
            "symbol": "SAMY",
            "name": "SAMYBOT COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoBigMike/SAMYBOT/main/logo_crypto.png",
            "tags": [
                "metaverse-token",
                "nft-token",
                "community-token",
                "business-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "ANqY8h3sRSwkC29FvGJenAh7VGRABVVx7Ls6Mq4BuGT",
            "symbol": "ZIG",
            "name": "ZIG Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANqY8h3sRSwkC29FvGJenAh7VGRABVVx7Ls6Mq4BuGT/logo.png",
            "tags": [
                "ethereum",
                "utility-token"
            ],
            "extensions": {
                "address": "0xb2617246d0c6c0087f18703d576831899ca94f01",
                "assetContract": "https://etherscan.io/token/0xb2617246d0c6c0087f18703d576831899ca94f01",
                "blog": "https://zignaly.com/blog",
                "bridgeContract": "https://etherscan.io/address/0x3265bf56ab6c2e3cb39bb0b1bf41307ed87bfa35",
                "coingeckoId": "zignaly",
                "coinmarketcap": "https://coinmarketcap.com/currencies/zigcoin/",
                "description": "ZIG is the token that powers the Zignaly ecosystem: marketplace, vault and launchpad.",
                "discord": "https://discord.gg/r5qRXDJ",
                "facebook": "https://www.facebook.com/zignaly",
                "github": "https://github.com/zignaly-open",
                "linkedin": "https://www.linkedin.com/company/zignaly",
                "medium": "https://zignaly.medium.com/",
                "telegram": "https://t.me/ZignalyHQ",
                "telegramAnnouncements": "https://t.me/zignalyannouncement",
                "twitter": "https://twitter.com/zignaly",
                "vault": "https://zignaly.com/app/dashboard/#vault",
                "website": "https://zignaly.com/",
                "youtube": "https://www.youtube.com/c/Zignaly"
            }
        },
        {
            "chainId": 101,
            "address": "FzuuDH8W6xYFjCKmPugJ2kiUrX2UpVRaYvhonNVmdFs3",
            "symbol": "PRIMEXBT",
            "name": "PrimeXbt Trading Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/primexbte/primexbt/main/primexbt.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/primexbtp",
                "website": "https://primexbte.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5wM1ukGYSacfAh6QUdjcmftwaZLCfRXFjJBKK9aSCRka",
            "symbol": "BC",
            "name": "Ballistik Coin",
            "decimals": 9,
            "logoURI": "https://static.vecteezy.com/system/resources/previews/003/450/375/original/chainlink-link-coin-icon-isolated-on-white-background-vector.jpg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "Ballistik Coin is the first decentralized, capital efficient and over-collateralized protocol",
                "website": "https://solana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BMwCgMmcvZ1MAc7dF8SyrpLwpTBPZ9yjSy6mdXizRCjJ",
            "symbol": "NUT",
            "name": "Coconut Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/koa808/public-crypto/main/coconutcoin.png",
            "tags": [
                "art-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8M93YmbZjYzAPNsRjRcerbEydk1eG9EHNBitv4PRVCrf",
            "symbol": "FEMN",
            "name": "Remnants Female WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8M93YmbZjYzAPNsRjRcerbEydk1eG9EHNBitv4PRVCrf/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/remnantsnft",
                "twitter": "https://twitter.com/RemnantsNFT",
                "website": "https://www.theremnantsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EubuCzGDjiYfC7YkLj8DgKYpMADgX8QT9nBNqpQedRy2",
            "symbol": "bVIRAL",
            "name": "Viraverse Beta",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EubuCzGDjiYfC7YkLj8DgKYpMADgX8QT9nBNqpQedRy2/logo.png",
            "tags": [
                "stake-pool-token",
                "utility-token",
                "security-token",
                "community-token",
                "viraverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/viraverse",
                "twitter": "https://twitter.com/viraverseio",
                "website": "https://viraverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "ArBHXMmUeNuaacvPm6YnFd23og5pkpNTxm4gsoh259qc",
            "symbol": "TRCO",
            "name": "Trident Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/johno187/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4ZSG9zyxCsDa9VKtXUHkw48aNJS5aikETjA8Z1mP2vcP",
            "symbol": "AJSP",
            "name": "Alain Joseph Stephane Painchaud",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AlainSPainchaud/Crypto/main/TokenOfficiel.png",
            "tags": [
                "utility-token",
                "community-token",
                "business-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D9UA6BBwmqYuasyh8ja3LcQ7Tsdib6aa3pticTsSwRTQ",
            "symbol": "Star",
            "name": "Estrella Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Crypto-Papi/crypto/main/starlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "87dNcieNbSYQXzicLKQpKMHbwbQnKDH9kq6JmQLVw26T",
            "symbol": "MNPY",
            "name": "Moon Pie",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/davisayomah/Moon-Pie-Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DvT3MfLQ5eQTzuNRSVj8bVNZYAPcjXMZh4Qf2LoPX1bS",
            "symbol": "RSPCT",
            "name": "Respect Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kdr6up/RSPCT/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2nwHzfmTjWyHQCNozmmVzViYperzFc5yzLq5Xs2VV4pD",
            "symbol": "DONKEYWL",
            "name": "The Donkey Richards WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2nwHzfmTjWyHQCNozmmVzViYperzFc5yzLq5Xs2VV4pD/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8G91aCq96txspm6SZLcCDnopT36NrZ9nEad2XrEuma8",
            "symbol": "BHS",
            "name": "BioHumans",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/conqueror1234/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7fPemBMNAAnCv2eyN8Y2qHbiawRFk79cQcwMWuETLdk9",
            "symbol": "BAMM",
            "name": "BAM Token - The Sol Den",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fPemBMNAAnCv2eyN8Y2qHbiawRFk79cQcwMWuETLdk9/logo.png",
            "tags": [
                "utility-token",
                "game-token"
            ],
            "extensions": {
                "website": "https://solden.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8bdpcGxmKukUecR9MzUFmqHudRc89i6DZLJGwdXiA28t",
            "symbol": "WPWL1",
            "name": "WOL PLA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8bdpcGxmKukUecR9MzUFmqHudRc89i6DZLJGwdXiA28t/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://wolfeplanet.io"
            }
        },
        {
            "chainId": 101,
            "address": "DEADgNiYjUUNwy614z7TWpumyoL9k3vKD2irEj43awGB",
            "symbol": "DEADWL",
            "name": "Dead Rejects Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/v2/255x255q90/924/82ec2c.png",
            "tags": [
                "whitelist-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/deadrejects",
                "twitter": "https://twitter.com/DeadRejcts",
                "website": "https://deadrejects.com/"
            }
        },
        {
            "chainId": 101,
            "address": "53aeVacJqTA1W3kQXLcGBvT2mxndCGPp7LrYMPGe2L5X",
            "symbol": "SREBC",
            "name": "SREBrasil Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/srebrasil/SREBrasilCoin/main/SREBrasilToken.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Token social para a comunidade SRE Brasil",
                "website": "http://www.srebrasil.com"
            }
        },
        {
            "chainId": 101,
            "address": "AxGonoNW3Qi8GBmcwpRpGE4xPV5v7rz2ug244gHBKfSr",
            "symbol": "KDWL",
            "name": "Kiyo Dragon WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AxGonoNW3Qi8GBmcwpRpGE4xPV5v7rz2ug244gHBKfSr/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "iMGN7vhNW6N9CugeB9Ac3Syd4aS363TspANMoj9wVxu",
            "symbol": "$IMGN",
            "name": "DAHLYS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iMGN7vhNW6N9CugeB9Ac3Syd4aS363TspANMoj9wVxu/logo.png",
            "tags": [
                "STAKING-Tokens"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DahlysArt",
                "website": "https://www.dahlys.art"
            }
        },
        {
            "chainId": 101,
            "address": "wmcSVkHBkGa9MH633Cs1BoPKD2FbuFe8bQBoRTnaEuv",
            "symbol": "GST",
            "name": "Grey Sparrow Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/VorobevSA/solana-token-list/main/assets/mainnet/wmcSVkHBkGa9MH633Cs1BoPKD2FbuFe8bQBoRTnaEuv/big_logo2.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "github": "https://github.com/VorobevSA",
                "linkedin": "https://www.linkedin.com/in/vorobevsa/"
            }
        },
        {
            "chainId": 101,
            "address": "fdtNAbSJtYTDeJxUuoSSH9maQKrJokkJJTJZgSmpZvp",
            "symbol": "SONIA",
            "name": "Sonia Investment Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Firebros1/crypto/main/IMG_0509.jpg",
            "tags": [
                "market-coin",
                "investment-coin"
            ],
            "extensions": {
                "website": "https://soniacoin.weebly.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9jDpKzpHz6fatL8CiJjRhAGsLJmLMzXvynwxY5y7ykKF",
            "symbol": "CAYC",
            "name": "Official Cyber Ape Yacht Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9jDpKzpHz6fatL8CiJjRhAGsLJmLMzXvynwxY5y7ykKF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://cayc.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GkLcXLnY5C36YKqceD3gpnQuej5xEtFBeRqQqFVGSwrf",
            "symbol": "GECKO",
            "name": "GECKO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkLcXLnY5C36YKqceD3gpnQuej5xEtFBeRqQqFVGSwrf/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2EmWCTJj7TsStdFmWckLXsxcU3S91t7SxEXBuzfas4nW",
            "symbol": "STT",
            "name": "STT COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2EmWCTJj7TsStdFmWckLXsxcU3S91t7SxEXBuzfas4nW/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2hwxARuDydQg4K97u9ho38T9Dc3K5ia6UqfAZ7LyeDPH",
            "symbol": "MONN",
            "name": "MonniCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mammimaisteri/MonniCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BFwQLeUYEaU4Sgf6KzxXCcXWzAjEd5iKEjFyE88EpaUE",
            "symbol": "OMU",
            "name": "OMU Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BFwQLeUYEaU4Sgf6KzxXCcXWzAjEd5iKEjFyE88EpaUE/logo.png",
            "tags": [
                "utility-token",
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NEo3D6MXRXf2iAfaqvZYqSmFkfutLvNjm86xmfGWNh5",
            "symbol": "NEO",
            "name": "NEO3D TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/minh-git/docusaurus-2/main/static/img/neo3d-logo.jpg",
            "tags": [
                "NFT",
                "gallery",
                "utility-token",
                "catpunk",
                "metaverse",
                "art"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Neo3Dxyz",
                "website": "https://www.neo3d.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "BmNmsJMqLjqAFVUSdpDvd16jgruoEiAL3r7TCQDHid9J",
            "symbol": "BXT",
            "name": "Business Exchange Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BXT-Project/branding/main/bxt.png",
            "tags": [
                "web3",
                "dapp",
                "nfc",
                "transaction",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BxtProject",
                "website": "https://bxt.org"
            }
        },
        {
            "chainId": 101,
            "address": "TribjRoptuaFXmSgPXWtJQq4WLt1Gu2PHCWCMdnNbSD",
            "symbol": "TRIB",
            "name": "Crypto Coral Tribe Early WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TribjRoptuaFXmSgPXWtJQq4WLt1Gu2PHCWCMdnNbSD/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CoralTribeNFT",
                "website": "https://www.cryptocoraltribe.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4j5wrut5J4Aq81R5daWHUeRwLGz6eBodfRepUnaW473b",
            "symbol": "PICASO",
            "name": "Picaso",
            "decimals": 6,
            "logoURI": "https://github.com/boosbasement/metaboss/blob/main/Abstract%20Art%20Framed%20Art%20Print.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDvsvRRHDSZ3p2LrKDLZaZrme3X7EYTJRUzQDMFGgqWC",
            "symbol": "VRN",
            "name": "Vernolien",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/murasame78/vernolien/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J6zFwmSndSNBbX2z9xgyU1FtxPB2d6UiSr15yCfDW9Y8",
            "symbol": "DEGWL",
            "name": "DegenWhitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J6zFwmSndSNBbX2z9xgyU1FtxPB2d6UiSr15yCfDW9Y8/logo.png",
            "tags": [
                "Whitelist",
                "DegenWhitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "DWPkCJS4hR3DPXGi3jkmLJSHEm6JLgu8hqNEZfyeh9vr",
            "symbol": "OGR",
            "name": "OGRE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/hotmcscott/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D4ZjNpTteRniyVzM2SSknadfaY7NTiC7jQin2D6PgEfP",
            "symbol": "CITI",
            "name": "CITIGROUP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4ZjNpTteRniyVzM2SSknadfaY7NTiC7jQin2D6PgEfP/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "8w4HAirTaY6xVCWpzAb6DiJmmcwD9k5q2De6VyvNJce5",
            "symbol": "WF",
            "name": "Wells Fargo and Company",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8w4HAirTaY6xVCWpzAb6DiJmmcwD9k5q2De6VyvNJce5/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "Cs2rSoSwohbZ33AtSk8mQmVtfv1hfMMmFNiLzuxqQ7bx",
            "symbol": "HSBC",
            "name": "HSBC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cs2rSoSwohbZ33AtSk8mQmVtfv1hfMMmFNiLzuxqQ7bx/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "9Rp8wFHgRX1gRZCqNKecVr1GMikp6VmiUVKWa6ojLBeR",
            "symbol": "OCBC",
            "name": "OCBC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Rp8wFHgRX1gRZCqNKecVr1GMikp6VmiUVKWa6ojLBeR/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "AX4icbfwXHcNhVFRrNyn4aSPSoykEybvEhSp8NBWUkEi",
            "symbol": "BHP",
            "name": "BHP Group Limited",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AX4icbfwXHcNhVFRrNyn4aSPSoykEybvEhSp8NBWUkEi/logo.svg",
            "tags": [
                "mining"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "CPNTQA3MSjZKw5seVi3XxjM4ed6rxK7BDFgUmjHQ2bsB",
            "symbol": "RIO",
            "name": "Rio Tinto Group",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPNTQA3MSjZKw5seVi3XxjM4ed6rxK7BDFgUmjHQ2bsB/logo.svg",
            "tags": [
                "mining"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "HQQuaZegBUBuXA3bYTLC48F2drKjtUhCL5YXCq5Zb5dd",
            "symbol": "CITIGROUP",
            "name": "CITI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HQQuaZegBUBuXA3bYTLC48F2drKjtUhCL5YXCq5Zb5dd/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "2QfYSYA7M6HW6LrLUoQXiUpf8EQnqPvoWdaTzPozfUvc",
            "symbol": "LKF",
            "name": "LKF Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ll336/cryp/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DUkqNMBYqqUacmq68AWH3hkjBsWpzJUQq7RnqFjuPdu",
            "symbol": "CO2",
            "name": "CARBON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUkqNMBYqqUacmq68AWH3hkjBsWpzJUQq7RnqFjuPdu/logo.png"
        },
        {
            "chainId": 102,
            "address": "3FPckdMdGNRyBUTUmMk6rRWHKuNKywUqPtNKvh1GaL4J",
            "symbol": "SIKU",
            "name": "Siku Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FPckdMdGNRyBUTUmMk6rRWHKuNKywUqPtNKvh1GaL4J/siku.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://sikuswap786.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "sva5AYRA8EodCCKEFYFZQfyFS8gXf5rGhnchTDxXYfc",
            "symbol": "SVA",
            "name": "Sovana",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sva5AYRA8EodCCKEFYFZQfyFS8gXf5rGhnchTDxXYfc/logo.png",
            "extensions": {
                "website": "https://www.sovana.world"
            }
        },
        {
            "chainId": 101,
            "address": "VLHLiyR17iN3maDEhLtrFX2K7eFT2wU2bessujU1RBS",
            "symbol": "VLH",
            "name": "Valhalla",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VLHLiyR17iN3maDEhLtrFX2K7eFT2wU2bessujU1RBS/logo_wh.png",
            "extensions": {
                "website": "https://v8mech.com"
            }
        },
        {
            "chainId": 101,
            "address": "8uVpieRrhs6FxfD8tJYqp66Qf4mmU7yvCxun63AZaDpU",
            "symbol": "ASX",
            "name": "ASX Ltd",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8uVpieRrhs6FxfD8tJYqp66Qf4mmU7yvCxun63AZaDpU/logo.svg",
            "tags": [
                "finance"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "DBsh3k16nYoR46eSoL8oyuG9JXjLbD13cRQUtcVBtpAr",
            "symbol": "TELSTRA",
            "name": "Telstra Corporation Ltd",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DBsh3k16nYoR46eSoL8oyuG9JXjLbD13cRQUtcVBtpAr/logo.svg",
            "tags": [
                "communication"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "H47tCXRBtB7pbyBmW7RNYqWxMUWGny59WHbm6mN67LWE",
            "symbol": "MOBILE",
            "name": "TELSTRA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H47tCXRBtB7pbyBmW7RNYqWxMUWGny59WHbm6mN67LWE/logo.svg",
            "tags": [
                "communication"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "7fEJR4PTm8cJwcmiyLjDtt98cUL5ynXJQcB5Y7HFd4pM",
            "symbol": "CELL",
            "name": "PHONE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fEJR4PTm8cJwcmiyLjDtt98cUL5ynXJQcB5Y7HFd4pM/logo.svg",
            "tags": [
                "communication"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "CME7sGyY3jCt2SdxpYfVpqbRBkEE9BvagtAkPTSERGnL",
            "symbol": "PHONE",
            "name": "CELL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CME7sGyY3jCt2SdxpYfVpqbRBkEE9BvagtAkPTSERGnL/logo.svg",
            "tags": [
                "communication"
            ],
            "extensions": {
                "description": "BEVO ROCKS",
                "twitter": "https://twitter.com/OzDive"
            }
        },
        {
            "chainId": 101,
            "address": "GYrcDSCXGWgpLeKSCgNCpHfv4RCG3iSBcQAHgNX9U7wa",
            "symbol": "$PA",
            "name": "Police Academy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GYrcDSCXGWgpLeKSCgNCpHfv4RCG3iSBcQAHgNX9U7wa/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token"
            ],
            "extensions": {
                "website": "https://policeacademynft.com"
            }
        },
        {
            "chainId": 101,
            "address": "DDCEE8BVKbxZ6TXo8HK7g4qUBpaTocNRX4q7ppt5FKA1",
            "symbol": "DDCWL",
            "name": "Dope Duck Club Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DDCEE8BVKbxZ6TXo8HK7g4qUBpaTocNRX4q7ppt5FKA1/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "6jHEQ3HdKb7PpAve2oXpcKRnp6xfEDfM8hWEVBwEE5HP",
            "symbol": "DUDE",
            "name": "Dude Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Randomkushh/gitnote-images/62a3e5727abd6091f4b1e835e1b446a04f9c9ee9/images/dudes%20token.png",
            "extensions": {
                "twitter": "https://twitter.com/cool_dudes_nft",
                "website": "https://www.cooldudes.club/"
            }
        },
        {
            "chainId": 101,
            "address": "EzfnjRUKtc5vweE1GCLdHV4MkDQ3ebSpQXLobSKgQ9RB",
            "symbol": "CSM",
            "name": "Cricket Star Manager",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzfnjRUKtc5vweE1GCLdHV4MkDQ3ebSpQXLobSKgQ9RB/logo.svg",
            "extensions": {
                "website": "https://cricketstarmanager.com/"
            }
        },
        {
            "chainId": 101,
            "address": "J18t9JWmvGkDTMukS53xLoq8sZn47QwnULmiRrorqiCY",
            "symbol": "BHT",
            "name": "Bharath coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Modi08/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "23ochfVnFJcRVsQ43639yY2LbgAzBr6SQefjFeADMsmp",
            "symbol": "SLPZ",
            "name": "SolPeepz WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/23ochfVnFJcRVsQ43639yY2LbgAzBr6SQefjFeADMsmp/Logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SOlPeepzNFT",
                "website": "https://solpeepz.club/"
            }
        },
        {
            "chainId": 101,
            "address": "81mKGZTjMiUbpZi2Gd3Pn4J3Z3u42U7GtSHC4jZeZxdf",
            "symbol": "SolPunk",
            "name": "SolPunks 3D Whitelist ",
            "decimals": 9,
            "logoURI": "https://storage.googleapis.com/solpunkz/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://storage.googleapis.com/solpunkz/solpunkz.mp4",
                "description": "SolPunks 3D Whitelist redeemable token",
                "imageUrl": "https://arwave.art/gweq2vxtzpngk1eqlq6k/hefh2g.png",
                "website": "https://solpunks.club"
            }
        },
        {
            "chainId": 101,
            "address": "bqRNmDUiYFwP3T1kUkvwibkB3xFPKZtE2sQsWHWFewb",
            "symbol": "ELP",
            "name": "eCommerce loyalty program",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Abhijay376/commerce-loyality-1/main/ELP-LOGO-min.png",
            "tags": [
                "commerce"
            ]
        },
        {
            "chainId": 101,
            "address": "3gr25JueJADKQrmqeS6cRQukvbiNffHdMofj6xf7XBV5",
            "symbol": "SLUDO",
            "name": "Solana Ludo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sukhihacker/token-list/main/assets/mainnet/3gr25JueJADKQrmqeS6cRQukvbiNffHdMofj6xf7XBV5/SLUDO.png",
            "tags": [
                "Gaming-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/7xctmZgX33",
                "twitter": "https://twitter.com/SolanaLudo",
                "website": "https://solanaludo.vercel.app"
            }
        },
        {
            "chainId": 101,
            "address": "2hUCytKiWaqQcu2LhL9dYAfeKSET5G5kF7jiDi78VwxJ",
            "symbol": "$LOVE",
            "name": "LOOKS-RARE TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thislooksrare/public/main/logo.png",
            "tags": [
                "ownership-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thislooksrare",
                "website": "https://looks-rare.com"
            }
        },
        {
            "chainId": 101,
            "address": "ptX2XE63jdoA3x9tX1SUgVQpBStgi2516Q4xKR5pWq4",
            "symbol": "BHU",
            "name": "Bhoomi",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BoltyCodes/dede/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ah85aeF2UCUCbjkGABgkXMpua9fMny2LhE2dVogVwjhb",
            "symbol": "MERRA",
            "name": "MERRA",
            "decimals": 6,
            "logoURI": "https://merrabots.com/img/merrabots.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/merrabots",
                "website": "https://merrabots.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CkdnbcYZWTaFghf7FbQfJPPH4EFdWuYmze6o9svxy45h",
            "symbol": "SMLNWL",
            "name": "SmiliNFT Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkdnbcYZWTaFghf7FbQfJPPH4EFdWuYmze6o9svxy45h/logo.png",
            "tags": [
                "nft",
                "whitelist",
                "SmiliNFT"
            ],
            "extensions": {
                "description": "It is a token let you mint first collection of SmiliNFT",
                "twitter": "https://twitter.com/nftsmilin",
                "website": "http://smilinft.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "6Hd62Rsw9cpqpy1nerLa9zGDR5Gbmw8XyUGt7Sbpcf8v",
            "symbol": "NOTSOL1",
            "name": "Not Solana 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gambofafrika/Solana/main/Solana-Crypto-Logo-PNG-File.png",
            "tags": [
                "Social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DecodezX8BBQRwMevyMmezEGzoZ7vcV4CUfydkcbR78Y",
            "symbol": "DCDR",
            "name": "SOL DECODER Whitelist Token",
            "decimals": 9,
            "logoURI": "https://docs.soldecoder.app/uploads/images/system/2022-03/logo-transparent-mine.png",
            "tags": [
                "whitelist-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/sol-decoder",
                "twitter": "https://twitter.com/SOL_Decoder",
                "website": "https://soldecoder.app/"
            }
        },
        {
            "chainId": 101,
            "address": "59ZGPisCwKJh96FqyMePTM6K3XFUF2y4T6YYFPWxGp3d",
            "symbol": "ART",
            "name": "A RODIN TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ARTDEV0/ART/main/logo-art.png",
            "tags": [
                "ownership-token",
                "social-token",
                "art-token"
            ],
            "extensions": {
                "website": "http://www.projectrodin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4M48qacBqq5T5YyJs6392EgxZKRja2i5Hz9uLXQKCYmw",
            "symbol": "SD",
            "name": "Social Donkeys",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/4M48qacBqq5T5YyJs6392EgxZKRja2i5Hz9uLXQKCYmw/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "3YUfxdwg1288BLSqzz6VnVsHwV7vqgbTBoTWqyhP4TEw",
            "symbol": "FLIES",
            "name": "TSF Flies",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3YUfxdwg1288BLSqzz6VnVsHwV7vqgbTBoTWqyhP4TEw/logo.jpg",
            "tags": [
                "nft-token",
                "community-token"
            ],
            "extensions": {
                "description": "Collect TSF Flies token to open your mystery box.",
                "discord": "http://discord.gg/stonedfrogs",
                "twitter": "https://twitter.com/thestonedfrogs",
                "website": "https://www.thestonedfrogs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "E3S8VWKbp15j4aG7UWr1FsRudob16pPuedm3GJiDvhQA",
            "symbol": "GTCO",
            "name": "GatorCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/soundmang/cooldude/main/One_Eyed_Alligator.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JDErCVbaRpapLCzu2c4m2F2FfYkS2tuVRp3voSJ69VBE",
            "symbol": "PDM",
            "name": "Padeum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Qersdyn/crypto/main/Padeum.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZEC53gSgrMuJ4Mbbgxzj1ewHpwyA3o1cKRemx9bxrUo",
            "symbol": "ZEC",
            "name": "ZellionCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZEC53gSgrMuJ4Mbbgxzj1ewHpwyA3o1cKRemx9bxrUo/logo.png"
        },
        {
            "chainId": 101,
            "address": "AZsaSZSZvy1o9465figrnxZbBa2YqfFnin6cMcTyXN1s",
            "symbol": "BHC",
            "name": "Biocoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/conqueror1234/crypto/main/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AKw8GgQdEmCqb1ngTwDCp31pQW7cKiYERk2CrFS7f2oW",
            "symbol": "VBPC",
            "name": "VIRTUAL BIBLE PROJECT COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CryptoBigMike/Virtual_Bible_Project/main/logo_vbpc.png",
            "tags": [
                "metaverse-token",
                "nft-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6QgrTd1r7FLjUzgVXY1dmY9BkS1Uy8BVgunNDbxiSDs7",
            "symbol": "Neek",
            "name": "Neek",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/RaulLL98/neekLogo/main/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "4vp1GYqYkz4qP6UQ1jzgg3MBFb6JJCiUUJKo9W1F2Dds",
            "symbol": "DSKULLYS",
            "name": "Disarticulated Skully's Wl Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/abuzer63/token-wl/main/logo.png",
            "tags": [
                "Nft-Token",
                "Wl-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dskullys",
                "website": "https://www.dskullys.com/"
            }
        },
        {
            "chainId": 101,
            "address": "B4bEXugU97iLaSaXSpEAhDtTVyCyUnafTcBc7r8QQ3mZ",
            "symbol": "ERGO",
            "name": "Ergo Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B4bEXugU97iLaSaXSpEAhDtTVyCyUnafTcBc7r8QQ3mZ/logo.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "website": "https://ergocoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "CNcWCsiggfr6EJcWn3juDKj5L5WygZnEYJ5dXmPuU4mA",
            "symbol": "SMND",
            "name": "SchmeckSand",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sraisl/crypto/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "474QkZFHJ4GjWRBWgBFmQkGZMfhYcqiyQwPj9oim3fp7",
            "symbol": "DDAO",
            "name": "DDAO WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/474QkZFHJ4GjWRBWgBFmQkGZMfhYcqiyQwPj9oim3fp7/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ApsWDNN2hyK82e5jaT3z8R3SVRFsp4XH9QqRhqWownfv",
            "symbol": "CALA",
            "name": "AlexLopezCoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApsWDNN2hyK82e5jaT3z8R3SVRFsp4XH9QqRhqWownfv/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "description": "Por los jajas"
            }
        },
        {
            "chainId": 101,
            "address": "7FiMPtv5p4bHMwpynru624QeQAhcvPfgL1K184sxv7ut",
            "symbol": "JZCN",
            "name": "JizzerCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mowinters/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KVXJhoJLSSQq2EYPYMP4h2v8AJryQQGeDJcpjRLp96K",
            "symbol": "ORBWAY",
            "name": "ORBWAY",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KVXJhoJLSSQq2EYPYMP4h2v8AJryQQGeDJcpjRLp96K/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/planetariansnft",
                "website": "https://planetarians.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8aQUSX3RxjhXNm9azYbCZD1qXK6tzKrWeWyW9YyKvztK",
            "symbol": "A",
            "name": "A COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8aQUSX3RxjhXNm9azYbCZD1qXK6tzKrWeWyW9YyKvztK/logo.png"
        },
        {
            "chainId": 101,
            "address": "E91hiy2y8asBrvxpfjpN9BntYMwvawJ4DSUPTkTcodBL",
            "symbol": "FELINE",
            "name": "Feline Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E91hiy2y8asBrvxpfjpN9BntYMwvawJ4DSUPTkTcodBL/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "description": "Feline Token is a deflationary crypto currency aimed at cat lovers.",
                "website": "http://www.felinetoken.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "DeViLuoFz6JbDsXk3hifyQCx4CGAgAThyyQjSM6jewKK",
            "symbol": "LKEY",
            "name": "Legion Key",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolStonez/Legion-Key-Logo/main/demon_logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Legion Keys are whitelist tokens to gain access to presale and discounted price of Legion of Sol collection",
                "discord": "https://discord.gg/9AK64whHFe",
                "twitter": "https://twitter.com/legion_of_sol",
                "website": "https://legionofsol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ESqzjZoyQgLU2qEPnyu3H5V6A83uBMttViTcJgz5KEMQ",
            "symbol": "MBC-WL",
            "name": "MBC Whitelist Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ESqzjZoyQgLU2qEPnyu3H5V6A83uBMttViTcJgz5KEMQ/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MysticGirlsClub"
            }
        },
        {
            "chainId": 101,
            "address": "7QqbzJ2QLrkE4XV4PtwvHxBWNf5UEUE6EqeSXVsKAcZx",
            "symbol": "MBC-DC",
            "name": "MBC 50% Discount Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ESqzjZoyQgLU2qEPnyu3H5V6A83uBMttViTcJgz5KEMQ/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MysticGirlsClub"
            }
        },
        {
            "chainId": 101,
            "address": "3hMeR4ibt9jczSbaZKkxw49p9XjFcnxcpNqtPGDcnqG6",
            "symbol": "MBC-FR",
            "name": "MBC Free Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ESqzjZoyQgLU2qEPnyu3H5V6A83uBMttViTcJgz5KEMQ/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MysticGirlsClub"
            }
        },
        {
            "chainId": 101,
            "address": "2GpWESikbmmQKTkTkcazYtxg3LAozfV8SQ1ozPAvPRzb",
            "symbol": "TAE",
            "name": "TAE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2GpWESikbmmQKTkTkcazYtxg3LAozfV8SQ1ozPAvPRzb/logo.png"
        },
        {
            "chainId": 101,
            "address": "2u67vqX4dDHippARJBvGib8oorMzT5grWt2aKFmBagax",
            "symbol": "STELLA",
            "name": "Rocstella",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2u67vqX4dDHippARJBvGib8oorMzT5grWt2aKFmBagax/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "community-token",
                "metaverse"
            ],
            "extensions": {
                "discord": "https://discord.com/rocstella",
                "facebook": "https://www.facebook.com/rocstella.io",
                "instagram": "https://www.instagram.com/rocstella.io/",
                "reddit": "https://www.reddit.com/r/Rocstella/",
                "telegram": "https://t.me/rocstella",
                "twitter": "https://twitter.com/rocstella_io",
                "website": "https://rocstella.io"
            }
        },
        {
            "chainId": 101,
            "address": "TowQvaG4JUUDCY6SYrqq4xh45D6Rrv6cUdSjY86sTVb",
            "symbol": "GADO",
            "name": "GADO WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TowQvaG4JUUDCY6SYrqq4xh45D6Rrv6cUdSjY86sTVb/logo.png"
        },
        {
            "chainId": 101,
            "address": "HXpJJYuaG15ZLF9qTj4QuX1o2VvhH3bY2xWj7hVviAcR",
            "symbol": "WPP",
            "name": "Welina Pineapple People",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HXpJJYuaG15ZLF9qTj4QuX1o2VvhH3bY2xWj7hVviAcR/logo.png",
            "extensions": {
                "discord": "https://discord.gg/zFyscmEfQQ",
                "twitter": "https://twitter.com/welina_nft"
            }
        },
        {
            "chainId": 101,
            "address": "9FubRwbEXpXuFh9hs3G8UxcuhGUmUbgJFbUtVs7Ktiji",
            "symbol": "VOR",
            "name": "Vormir",
            "decimals": 0,
            "logoURI": "https://github.com/aeinase/Solana/blob/main/vor.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CCTprR8uQqwhLEDhwA4yWsMrj4D7LqbH39f54ih4w6pb",
            "symbol": "CTWLT",
            "name": "Coral Tribe WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Crypto-Coral-Tribe/media/main/logo.png",
            "tags": [
                "nft",
                "whitelist",
                "whitelist-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Coral Tribe Whitelist token.",
                "discord": "https://discord.gg/B4MJcX72g9",
                "twitter": "https://twitter.com/CoralTribeNFT",
                "website": "https://www.cryptocoraltribe.io"
            }
        },
        {
            "chainId": 101,
            "address": "MEDLjqYrYTWBVViMNomU16i6mbvRg4BrQsi6CWJemqz",
            "symbol": "$MEDALS",
            "name": "MEDALS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MEDLjqYrYTWBVViMNomU16i6mbvRg4BrQsi6CWJemqz/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7KX3BzaUFZ",
                "twitter": "https://twitter.com/Project1776NFT",
                "website": "https://project1776.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Ayf2qMw1G91X1PmoaYjEiAmDmpLi9dC8DhjQaHDu6qFY",
            "symbol": "SPIN",
            "name": "Semper-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ayf2qMw1G91X1PmoaYjEiAmDmpLi9dC8DhjQaHDu6qFY/logo.png"
        },
        {
            "chainId": 101,
            "address": "2qkahneNuubJ6v8PSqVG3knqr8Ndmu2TaxGBtCkUQ8Ak",
            "symbol": "BEEN",
            "name": "Been-Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pandaniel79/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cy5N1dE6HmBwfkp7DgpEzQBpNjTWo4TnPiNbnd3opmdA",
            "symbol": "GGT",
            "name": "Green Grove Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/greengroveio/assets/main/logo64.png",
            "tags": [
                "green-grove-token"
            ],
            "extensions": {
                "description": "Green Grove Token. Love Nature Preserve Green.",
                "github": "https://github.com/greengroveio",
                "telegram": "https://t.me/solgreengrove",
                "twitter": "https://twitter.com/GreenGroveio"
            }
        },
        {
            "chainId": 101,
            "address": "Cq5pHfXB8zM7GGZ9r9MKLeh8jGK4TTzwhD4EhW8VVaCp",
            "symbol": "DDAO",
            "name": "DDAO WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cq5pHfXB8zM7GGZ9r9MKLeh8jGK4TTzwhD4EhW8VVaCp/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "cr8ooTCpZUx4AQW5GquDzsfDNXEN3xxtEYEz8tAjgsw",
            "symbol": "CREATE",
            "name": "CREATE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cr8ooTCpZUx4AQW5GquDzsfDNXEN3xxtEYEz8tAjgsw/logo.png",
            "extensions": {
                "website": "https://www.sovana.world"
            }
        },
        {
            "chainId": 101,
            "address": "WAtEPamrPJxaSrdyJpqroj1FhkUK1ooQWjCiXDrgREi",
            "symbol": "WATER",
            "name": "Refined Water Crystal",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitcoinblack482/logo/main/white.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8cXAosK13961DmvHuYEuZvoTzNS2yrEg2VVby9AZiLGn",
            "symbol": "GOAP",
            "name": "Golden Ticket APE-NBA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8cXAosK13961DmvHuYEuZvoTzNS2yrEg2VVby9AZiLGn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ape_nba"
            }
        },
        {
            "chainId": 101,
            "address": "tFceLf6nLmbWtWjjxEVjmC3Co3r94JNQfbqdbVGnvHt",
            "symbol": "MAC",
            "name": "macnicoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/anemone-fish/crypto-macnicoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "585mJ5PGUEMZuAEnD3G6DqhxZ6wo6hRBL4JYTtcqArqm",
            "symbol": "$TEAK",
            "name": "Teak",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/585mJ5PGUEMZuAEnD3G6DqhxZ6wo6hRBL4JYTtcqArqm/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "noUZHVrcXLW2qBcbQNozzXc73qHvpDoS7TdtijjCzH2",
            "symbol": "NORS",
            "name": "Novavers",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/noUZHVrcXLW2qBcbQNozzXc73qHvpDoS7TdtijjCzH2/logo.svg"
        },
        {
            "chainId": 101,
            "address": "BQwmFvH3ndZCyLdwnC9wcVGi5XxDcTDSVCtZXncGGVhd",
            "symbol": "LMT",
            "name": "LimboCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Novak-Fenocchio/LimboCoin/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "DjUrGLdzQt9WcU2CWZMzedDLjw35sg4ze2LYi9VfLrvk",
            "symbol": "DN",
            "name": "Dinomonz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DjUrGLdzQt9WcU2CWZMzedDLjw35sg4ze2LYi9VfLrvk/logo.png"
        },
        {
            "chainId": 101,
            "address": "8vFjsxK4SHg2XVSB6ofqNNvkFF62fx5Uq588a7f8qrjk",
            "symbol": "SIMS",
            "name": "Simians Token",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/8vFjsxK4SHg2XVSB6ofqNNvkFF62fx5Uq588a7f8qrjk/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "63y6SwXnhdFi9hd58b92FKJ4k79nbAsQFetQNvgbWBj6",
            "symbol": "RISE",
            "name": "RISE (ECORISE)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/63y6SwXnhdFi9hd58b92FKJ4k79nbAsQFetQNvgbWBj6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://ecorise.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Ebb1hE7AN82zMeLLe2JnfJCacnn2zTMP7xfDfqKPcccJ",
            "symbol": "DDLE",
            "name": "DDLE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ebb1hE7AN82zMeLLe2JnfJCacnn2zTMP7xfDfqKPcccJ/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DOODLETOWN_sol"
            }
        },
        {
            "chainId": 101,
            "address": "8GEdymZcgjVku6AfQuQCxqgUk8qpigy5LdP9UTRVcMxw",
            "symbol": "ARABPUNKZ",
            "name": "ArabPunkz swap token",
            "decimals": 9,
            "logoURI": "https://storage.googleapis.com/arabpunkz/arab.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://storage.googleapis.com/arabpunkz/arabpunkz.mp4",
                "description": "ArabPunkz 3D swap token to redeem at arabpunkz.io",
                "imageUrl": "https://arwave.art/upga5v2tzpnak1eqlq5kvc/upga5v2tzt.png",
                "website": "https://arabpunkz.io"
            }
        },
        {
            "chainId": 101,
            "address": "FmzPsB48TSox6VNvpjrciWz43ikS3miBkN9YLZ53heMA",
            "symbol": "ATC",
            "name": "Alex tech coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Alex000000000010/Alex-tech-coin/main/A__5_-removebg-preview.png",
            "tags": [
                "community-token",
                "Alex-tech-coin"
            ],
            "extensions": {
                "website": "https://alextech.be"
            }
        },
        {
            "chainId": 101,
            "address": "99qRyZNnpS8kumjAGC23RsKsxUiFUh2dzM2ea84ufQ5x",
            "symbol": "TLSM",
            "name": "Talisman",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/overload-dev/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5DxzUwbRxdB5Hr67qgQ2uCn1wwc3fN9cjKy4CwuqV17r",
            "symbol": "ALALAWI",
            "name": "Al-Alawi Network Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nalalawi2022/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9LtwWPtRLqwKsicJq4KTLid5hkfovxgDxW8AjNDcbEvM",
            "symbol": "sFFC",
            "name": "staked Fone Fan Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9LtwWPtRLqwKsicJq4KTLid5hkfovxgDxW8AjNDcbEvM/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/FoneFanClub",
                "website": "https://fonefan.club/"
            }
        },
        {
            "chainId": 101,
            "address": "4NMtJnczmqnEGUn9Gd6cKk5ek8Xb5PvTWFkaBgWj7AWi",
            "symbol": "NOVA",
            "name": "Nova-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4NMtJnczmqnEGUn9Gd6cKk5ek8Xb5PvTWFkaBgWj7AWi/logo.png"
        },
        {
            "chainId": 101,
            "address": "BLZgcxVfVMgfK7M2zgGVVrC3H2LkC4N7pYgqS1JJAe9h",
            "symbol": "BFMINT",
            "name": "Blaze Froman Mint Token",
            "decimals": 0,
            "logoURI": "https://vf6wknjlc2ipv4cnwmzzmxt2age65itflbmatfrvswbjvmqj.arweave.net/qX1lNSsWkPrwTbM__zll56AYnuomVYWAmWNZWCmrIJo",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/blazefroman",
                "instagram": "https://www.instagram.com/blazefroman/",
                "twitter": "https://twitter.com/BlazeFroman",
                "website": "https://blazefroman.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8GDe3FA2ciLfCNjsg3E9JZEyN2oyNv6yszy3jn7LLPMR",
            "symbol": "SWTY",
            "name": "Schawty",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/schwty7/cryptooo/main/logo.png",
            "tags": [
                "community-token",
                "schawty",
                "777",
                "schawty777"
            ]
        },
        {
            "chainId": 101,
            "address": "Ac8HFm7bgEm4bNgbUj1woSqnWJDji9gWkc7uH1TosXSy",
            "symbol": "RTWL",
            "name": "RetroTrades WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ac8HFm7bgEm4bNgbUj1woSqnWJDji9gWkc7uH1TosXSy/logo.png",
            "tags": [
                "whitelist-token",
                "retro-trades"
            ],
            "extensions": {
                "discord": "https://discord.gg/retrotrades",
                "twitter": "https://twitter.com/retro_trades",
                "website": "https://retrotrades.com/"
            }
        },
        {
            "chainId": 101,
            "address": "pMMTGYZ4CjvxTxN3TuwCx11y5CW6zz2MBhXMfWsCGvP",
            "symbol": "CORALTRIBENFT",
            "name": "Crypto Coral Tribe WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/robertdoo1/logo/main/xlogo.png",
            "tags": [
                "Nft-Token",
                "Wl-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/B4MJcX72g9",
                "twitter": "https://twitter.com/coraltribenft",
                "website": "https://www.cryptocoraltribe.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8xfL5CPfcDfovbBFHZpxPcHeUcYkbFwCGKm2JtrJr3q2",
            "symbol": "HVCWL",
            "name": "High Voltage Citizens Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8xfL5CPfcDfovbBFHZpxPcHeUcYkbFwCGKm2JtrJr3q2/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/hvworldsnft",
                "twitter": "https://twitter.com/hvWorldsNFT_",
                "website": "https://www.highvoltageworlds.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4hNTCobkw1dKqxj8cBBZYMteH7XN4VczEABWhDRsQ9rr",
            "symbol": "SUUS",
            "name": "SUUS AI Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rxshardcore/cryptosuus/main/suuslogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BEzTDHxrd8WCNNqku744CALPqwepWTkgD2rQWvHt1cMP",
            "symbol": "ANDRE",
            "name": "axdre",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/andre1code/crypto/main/logosol.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "537ZLMxVN8tPrvsyhroC4488s4Ex19f4v764Nre6uhtK",
            "symbol": "CENTAURI",
            "name": "Centauri Currency",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/537ZLMxVN8tPrvsyhroC4488s4Ex19f4v764Nre6uhtK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Centauri is the main currency for the Living Dead Teds economy",
                "discord": "https://discord.gg/livingdeadteds",
                "twitter": "https://twitter.com/LivingDeadTeds",
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "GdsVgJPuCM34tQKQfMpF4mtooGA34oM6NxSpZNrxf1Dx",
            "symbol": "GV",
            "name": "Gods Village",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdsVgJPuCM34tQKQfMpF4mtooGA34oM6NxSpZNrxf1Dx/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Mint token for the SOLGods x Village of Sol collab collection."
            }
        },
        {
            "chainId": 101,
            "address": "WuvZRuMuLVh7XdvzLGd26RhMqgwSqzJGm9bZ7vpimxn",
            "symbol": "WUV",
            "name": "WUV Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WuvZRuMuLVh7XdvzLGd26RhMqgwSqzJGm9bZ7vpimxn/logo.png",
            "tags": [
                "utility-token",
                "smug-pugs"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/thesmugpugs",
                "twitter": "https://twitter.com/thesmugpugs",
                "website": "https://thesmugpugs.com"
            }
        },
        {
            "chainId": 101,
            "address": "EiuUs8ZvHWjMgJEgDYtKZEnCPRxnXqFLRqypU6GuUCrS",
            "symbol": "PWAL",
            "name": "PWAL Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/elbarko/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "PWAL Esports Fan Token."
            }
        },
        {
            "chainId": 101,
            "address": "5E8bAHYXaEaxw3yXDKEnbAjtTM4QRjivRmNDpaczY1gD",
            "symbol": "MFAT",
            "name": "MFA TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MFAToken/mfa-logo/main/mfa.png.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "building the next generation of ecofriendly technology through the blockchain technology",
                "discord": "https://www.discord.gg/GUHt6KmekA",
                "telegram": "https://t.me/MFAToken",
                "twitter": "https://twitter.com/MfaToken",
                "website": "https://www.mfafinance.space/"
            }
        },
        {
            "chainId": 101,
            "address": "GATEsQgEEvavv9gz5mpb4PPDfWNR9PLr3TLq4xuj67SG",
            "symbol": "GOI",
            "name": "Gates of Imaginarium WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GATEsQgEEvavv9gz5mpb4PPDfWNR9PLr3TLq4xuj67SG/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Imaginarium_SOL"
            }
        },
        {
            "chainId": 101,
            "address": "3D4zNUJMqXUcShpFC9BeK3ueGodQ8y85iX1uN34YNYss",
            "symbol": "FEMNS",
            "name": "Remnants Female Sub-WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3D4zNUJMqXUcShpFC9BeK3ueGodQ8y85iX1uN34YNYss/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/remnantsnft",
                "twitter": "https://twitter.com/RemnantsNFT",
                "website": "https://www.theremnantsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FPWsFrQRhJpiCZAAUotNes3XZuBTv8M5yLYU7YYGc61J",
            "symbol": "FPW",
            "name": "Forart Painter Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FPWsFrQRhJpiCZAAUotNes3XZuBTv8M5yLYU7YYGc61J/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ThEJu98i7RUqWpsZNPy7uanted83FP6xGpEpkc9DN2E",
            "symbol": "WYSI",
            "name": "ZenithCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ZenithBlockchain/token-assets/main/zcoincircle.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "A cryptocurrency built for the osu! community.",
                "discord": "https://discord.gg/TkYRqfCT55",
                "twitter": "https://twitter.com/zenithcoin727"
            }
        },
        {
            "chainId": 101,
            "address": "FHr2iKwqV93UyMA6KJoJJgZcT7WXvE9oxX4qCU1iuzR5",
            "symbol": "SSAC",
            "name": "Solana Ape Social",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MichaelohodSOL/SSAC/main/socialape-Recovered.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5e5znzUAuWrM7p96XTZZyn5KWKRikLr1m8rpQESriebT",
            "symbol": "ERNESTWL",
            "name": "Ernest Beyond Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5e5znzUAuWrM7p96XTZZyn5KWKRikLr1m8rpQESriebT/logo.png",
            "extensions": {
                "discord": "https://discord.gg/ernestindisguise"
            }
        },
        {
            "chainId": 101,
            "address": "CcHtGVcHKSy7bGkfu14CsUko5bykGJUSnjKwWQ7HFV6N",
            "symbol": "HSB",
            "name": "Heartsbane",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/xForgex/Heartsbane/main/Heartsbane.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiSAiGzghbx8EUJqjvjAysB7Tw1hLkyLeZn9nDCGNNe",
            "symbol": "dsku",
            "name": "DISARTICULATED SKULLYS EARLY WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiSAiGzghbx8EUJqjvjAysB7Tw1hLkyLeZn9nDCGNNe/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dskullys",
                "website": "https://dskullys.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3KXM56KYWfjRUhsLyNZoWKYv2dgNDaXhF9YKb11iB9kK",
            "symbol": "RABBITS",
            "name": "Rabbit Tkn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KXM56KYWfjRUhsLyNZoWKYv2dgNDaXhF9YKb11iB9kK/logo.png"
        },
        {
            "chainId": 101,
            "address": "LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY",
            "symbol": "LILY",
            "name": "Solily Protocol Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://www.solily.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8yuQCKTxmQTVXcEFUFUsKyFeaMALcQqMSAHzRua3x5BJ",
            "symbol": "SMKR",
            "name": "Stoney",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/cashboysen/StoneyToken/main/Stoney-token.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "36234VF2prDP7YmFPQixUi3ikJYULo34yhRobViwerq1",
            "symbol": "GG",
            "name": "Golem Guardians",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/cashboysen/golem1/main/GolemTokey.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD",
            "symbol": "SIX",
            "name": "Solana Eco Index",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD/logo.png",
            "tags": [
                "Index",
                "SypoolProduct"
            ],
            "extensions": {
                "description": "Solana Eco Index is based on the 5 crypto assets on Solana.",
                "website": "https://sypool.io/"
            }
        },
        {
            "chainId": 101,
            "address": "C22BsnQ7jXT44qwJg1JtcfVxdCtkhjJeN8AKptRv9o1w",
            "symbol": "ZAZA",
            "name": "ZA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ZackAttakk1/crypto/main/za_crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TUXaNJFxPGHUGzAr2pjsYoCZCVCtXKGGWJWFEeKGeRi",
            "symbol": "TOOX",
            "name": "TOOX TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TUXaNJFxPGHUGzAr2pjsYoCZCVCtXKGGWJWFEeKGeRi/logo.png"
        },
        {
            "chainId": 101,
            "address": "baFwxU2f4ePZDWaYFBNWi4xHUFVTVyKiEknCQNfNi7Q",
            "symbol": "RERE",
            "name": "RERE",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TUXaNJFxPGHUGzAr2pjsYoCZCVCtXKGGWJWFEeKGeRi/logo.png",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "EQqRcQ7jL5wdhxqtVdJtnFyCex2pF4NaEKhcHEJFs9P2",
            "symbol": "2",
            "name": "sefesaf",
            "decimals": 1,
            "logoURI": "https://commons.wikimedia.org/wiki/Commons:Quality_images#/media/File:Gull_portrait_ca_usa.jpg",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "N9sYFwgtYwkuCFcDhF7CT1Pv6dXrSSifuKLLbgGVcY8",
            "symbol": "1",
            "name": "11111",
            "decimals": 11,
            "logoURI": "https://commons.wikimedia.org/wiki/Commons:Quality_images#/media/File:Gull_portrait_ca_usa.jpg",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "2EmsWdDVDZ3B1ZDsM8EMk3s53iygwUFdpmxNnr24VBdg",
            "symbol": "TT",
            "name": "ttt",
            "decimals": 2,
            "logoURI": "https://commons.wikimedia.org/wiki/Commons:Quality_images#/media/File:Gull_portrait_ca_usa.jpg",
            "tags": [
                "currency"
            ],
            "extensions": {
                "telegram": "https://t.me/omardd"
            }
        },
        {
            "chainId": 101,
            "address": "6meUpSTqqqj8UE1G46fT4M9C8KTvWunrEpAqRdaSNshe",
            "symbol": "USDP",
            "name": "Solana USDP ",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6meUpSTqqqj8UE1G46fT4M9C8KTvWunrEpAqRdaSNshe/logo.png",
            "tags": [
                "social-platform-tf",
                "assetwallet",
                "stable-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EbMg3VYAE9Krhndw7FuogpHNcEPkXVhtXr7mGisdeaur",
            "symbol": "bSOL-Lido",
            "name": "Lido Bonded SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EbMg3VYAE9Krhndw7FuogpHNcEPkXVhtXr7mGisdeaur/logo.svg",
            "extensions": {
                "github": "https://github.com/ChorusOne/solido",
                "twitter": "https://twitter.com/anchor_protocol",
                "website": "https://anchorprotocol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DYvKZYDjBVTmxg22T3Q6z64oxPTSNkknpptqLswiGk6Z",
            "symbol": "$DARK",
            "name": "DARK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYvKZYDjBVTmxg22T3Q6z64oxPTSNkknpptqLswiGk6Z/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/3DEGTZJ68J",
                "twitter": "https://twitter.com/Arkanauts",
                "website": "https://www.arkanauts.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5nkAGRqgBRJs7fqU4Vkrhu1DDQPKJBvAZsRPGV9vCeYa",
            "symbol": "BSAMO",
            "name": "Baby Samo",
            "decimals": 9,
            "logoURI": "https://babysamo.io/assets/images/babysamo.png",
            "tags": [
                "p2e"
            ],
            "extensions": {
                "website": "https://babysamo.io"
            }
        },
        {
            "chainId": 101,
            "address": "TsmrVzWkfBY3VJDWVRfNjqaaBgmLvT4n9qx1uqCk9Fd",
            "symbol": "SIN",
            "name": "SIN Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TsmrVzWkfBY3VJDWVRfNjqaaBgmLvT4n9qx1uqCk9Fd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/thesaints_nft",
                "website": "https://saintsnft.art"
            }
        },
        {
            "chainId": 101,
            "address": "9xnUf5okunLfCr5dHsZKms3rukkchh2QBavZ7hUH4v7o",
            "symbol": "SOR",
            "name": "Soricu Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sori18/SPC-token/main/logo_token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MindKcJKhBwNAboyrWh8Ku6GGKrP6kS6TSkr7zzWZJ9",
            "symbol": "MINDFOLK",
            "name": "Mind Folk White List Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitcoinblack482/logo/main/mind.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/mindfolk",
                "twitter": "https://twitter.com/mindfolkART",
                "website": "https://www.mindfolk.art/"
            }
        },
        {
            "chainId": 101,
            "address": "DxTqxF6mbaznxyLv3aWHJydoCSiLwjw3xWb5fsqFSMzk",
            "symbol": "USDP-Sol",
            "name": "Social Platform USDP ",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DxTqxF6mbaznxyLv3aWHJydoCSiLwjw3xWb5fsqFSMzk/logo.png",
            "tags": [
                "social-platform-tf",
                "assetwallet",
                "stable-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6D7nXHAhsRbwj8KFZR2agB6GEjMLg4BM7MAqZzRT8F1j",
            "symbol": "GOSU",
            "name": "Gosu",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/jGosu/gosu/main/GOSU%20COIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7sKGwnwpfJCYdwKzGzSUwUUf5TFPh9yuQXcVe3wgUHUm",
            "symbol": "SRBAN",
            "name": "SRBan",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/scoalabritanica/srban/main/logo.png",
            "tags": [
                "srb",
                "srban"
            ],
            "extensions": {
                "website": "https://scoalabritanica.ro"
            }
        },
        {
            "chainId": 101,
            "address": "E5NEDaxz7o8Vix5ZWx4BC5WR9mavFdBd78t5jeorMr4p",
            "symbol": "TOTE",
            "name": "TOTE Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5NEDaxz7o8Vix5ZWx4BC5WR9mavFdBd78t5jeorMr4p/logo.png"
        },
        {
            "chainId": 101,
            "address": "14Zi6xaKrFpEdxQKyU1SnSE8dfa97Gfj1jrtuWCxWnZL",
            "symbol": "WPUFF",
            "name": "Wonderpuff",
            "decimals": 2,
            "logoURI": "https://bafkreifpmmgm3wp7e5u5hhnbxbq5djoh6zhickapb6e3yq2x473pn2w66q.ipfs.nftstorage.link/?ext=png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/8J2tZgHQHu",
                "twitter": "https://twitter.com/smokeheadsnft",
                "website": "https://smokeheads.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9GqndzQdubZTToSdbRqxEtNEjn6kDBoyeVJCAUCTx9x7",
            "symbol": "BWL",
            "name": "Blockchain WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/IMG_4934%20(1)%20(1).png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TompRnwU1mEDtCefeQZrzEgXmZVxYQJuqpoFbAsudKn",
            "symbol": "RC",
            "name": "RagaTest Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TompRnwU1mEDtCefeQZrzEgXmZVxYQJuqpoFbAsudKn/logo.png"
        },
        {
            "chainId": 101,
            "address": "4xc2U5BmAH9N1yH2WeQAKknjMd3sfs47iLc4r5X7bUYp",
            "symbol": "WPWLT",
            "name": "WOLFE PLANET",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4xc2U5BmAH9N1yH2WeQAKknjMd3sfs47iLc4r5X7bUYp/logo.png",
            "tags": [
                "whitelist-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/wolfeplanetnft"
            }
        },
        {
            "chainId": 101,
            "address": "65WJf4jpYjtkjiNo6iMC2zYPsz4y7GfK8sPscaVZkfnb",
            "symbol": "LTQN",
            "name": "LitQoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/acorley094/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AjRCYF6Ve8gw3XBF1CYb5E9jpfruiKJNHCNhkfHuEULv",
            "symbol": "RMBL",
            "name": "RMBL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/RumbleToken/main/logo.png",
            "extensions": {
                "website": "https://www.rumblemonkeys.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9AyeEQHKS49rp2pUTwXkjnC37xXNb9Gpz72rEZQUvJSo",
            "symbol": "SPRZ",
            "name": "SpritzerCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/spritzercoin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GXzNKiMP9YWHMMvWnT2cag8UHx5fgMHRfqmVW22K2hm7",
            "symbol": "BSAMO",
            "name": "BSamo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXzNKiMP9YWHMMvWnT2cag8UHx5fgMHRfqmVW22K2hm7/bsamo.png",
            "tags": [
                "metaverse"
            ],
            "extensions": {
                "website": "https://babysamo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "78fRJMzWdKpeb2cAQ1pe25J5Fth9Mmiufzsi4KfazQdL",
            "symbol": "H2O",
            "name": "Cactus World H2O",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/78fRJMzWdKpeb2cAQ1pe25J5Fth9Mmiufzsi4KfazQdL/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "2F8Zv14u8N5523wrjz85V7FxodrtJx5pCmwmvBimnaUh",
            "symbol": "HVER",
            "name": "HodlVerse Token",
            "decimals": 6,
            "logoURI": "https://github.com/LungTied/assets/blob/main/logo_blue.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "DPHUre2EzbEwpXQ8ratzPTR7oaNWFZDxPoPzjHaqfgbb",
            "symbol": "LIMP",
            "name": "LIMP",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DPHUre2EzbEwpXQ8ratzPTR7oaNWFZDxPoPzjHaqfgbb/logolimp.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "Lionf78kvavZVYrxrTvhkVaAsUPukxxaRa1PSUzVr4Q",
            "symbol": "LPC",
            "name": "Lion Pride Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Lionf78kvavZVYrxrTvhkVaAsUPukxxaRa1PSUzVr4Q/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://t.co/te0ypLTUGO",
                "medium": "https://medium.com/@lionprideclub",
                "telegram": "https://t.me/LionPrideClub",
                "twitter": "https://twitter.com/LionPrideClub",
                "website": "https://lionprideclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Ah4zryc37twq8UMsxhMwqhVz9N9C8t1xzSXhJagMVEFC",
            "symbol": "PAVC",
            "name": "Pav Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Pavelnas/pav-token-logo/master/pav-token-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CzJ1VW76gnBzgYrNhcz5CRN8PcUEV3vSDixoxWsz4PNL",
            "symbol": "WIPS",
            "name": "WIP INTL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wipintl/Tokens22/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AqvSwjspmf5W8w62DXP6sfKfS6DMmPuMU1564pYpBNJr",
            "symbol": "ODLC",
            "name": "OuftiDiLidje Coin",
            "decimals": 8,
            "logoURI": "https://github.com/OuftiDiLidje/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "92Xve6K3BJc1nr571MTPmyXdn18GuBqY95Wvnb1PsKG5",
            "symbol": "MART",
            "name": "MetaArt",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/92Xve6K3BJc1nr571MTPmyXdn18GuBqY95Wvnb1PsKG5/logo.png",
            "tags": [
                "MetaArt"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/metaarttoken",
                "telegram": "https://t.me/metaartglobal",
                "twitter": "https://twitter.com/metaartoffici",
                "website": "https://www.metaarttoken.info/",
                "whitepaper": "https://metaart.network/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "BEDKeYwngxh6ii8zJPJGkGEqE5ocMYtgqy5RYYoxGfDE",
            "symbol": "CCART",
            "name": "CHEETAHS CARTEL",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEDKeYwngxh6ii8zJPJGkGEqE5ocMYtgqy5RYYoxGfDE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Cheetahs_Cartel"
            }
        },
        {
            "chainId": 101,
            "address": "EDkC5pB4TWNL2dcpBFfGpKuJTYzhAPvhrMyXgRnyKFT8",
            "symbol": "SDRAW",
            "name": "SolDragon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EDkC5pB4TWNL2dcpBFfGpKuJTYzhAPvhrMyXgRnyKFT8/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://dragonwar.io"
            }
        },
        {
            "chainId": 101,
            "address": "4hDLn4g4b3NvBzN33nfiHf6W7uX3rfDqkyPt3v4atgbs",
            "symbol": "WFE",
            "name": "Wolfee coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Gorillad/crypto/main/wolfee-logo.png",
            "tags": [
                "social-token",
                "XO-token",
                "community-token",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "5XewXRTjLKGFBZdVjbDr8zq2udSSQ3ANTKHDtKVj3d4h",
            "symbol": "KNTX",
            "name": "Kinetic",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5XewXRTjLKGFBZdVjbDr8zq2udSSQ3ANTKHDtKVj3d4h/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://kineticpay.app/"
            }
        },
        {
            "chainId": 101,
            "address": "BCAph72Lo4kGuF5LnN2KMy5BGwe97eyWkbYZX44AsVeG",
            "symbol": "BCA",
            "name": "BitcodeArt Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCAph72Lo4kGuF5LnN2KMy5BGwe97eyWkbYZX44AsVeG/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.bitcode.art"
            }
        },
        {
            "chainId": 101,
            "address": "3bMghewsbjApquGDRJNRs6J9eqVxiZgc4PZq2CY6Smoq",
            "symbol": "$PICASO",
            "name": "PICASO COIN",
            "decimals": 6,
            "logoURI": "https://github.com/boosbasement/metaboss/blob/main/Abstract%20Art%20Framed%20Art%20Print.png?raw=true",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/PICASOAPES"
            }
        },
        {
            "chainId": 101,
            "address": "yPAhSbua2wEWLtRR2nyxV1xsXogx41JUV3kPmJ9NNxW",
            "symbol": "y-USTv2-3Pool",
            "name": "Yield Mercurial LP Token (USTv2-3Pool)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yPAhSbua2wEWLtRR2nyxV1xsXogx41JUV3kPmJ9NNxW/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CyWGk1hWVQsGmDt3Na5MyNcWaEDh6MSkTCvAdA5h1Ke8",
            "symbol": "SaSN",
            "name": "SaSN Coin",
            "decimals": 9,
            "logoURI": "https://seth.kitchen/images/sasn.png",
            "tags": [
                "social-token",
                "metaverse",
                "game-token",
                "payment-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/MarquettDavon",
                "instagram": "https://www.instagram.com/marquettdavon/?hl=en",
                "website": "https://thesasn.com/",
                "youtube": "https://www.youtube.com/user/catchphrayse"
            }
        },
        {
            "chainId": 101,
            "address": "AUnF9BxPPtZZYdm9dPXMiVeF3TJvPaXPisSYPU5jWp36",
            "symbol": "FLOAT",
            "name": "Floating Apes Affiliation",
            "decimals": 1,
            "logoURI": "https://bafybeidcqhtql7u6ppdgttwrx2e2qhb64mrvhqyilgks43msuhaisz3juu.ipfs.dweb.link/?ext=png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/FNjCyHxQNW",
                "twitter": "https://twitter.com/FloatingApesNFT",
                "website": "https://floatingapesnft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9RNdhLncFBD9E8gX6JyZh6QoxBzqfeivMyBacu42omH8",
            "symbol": "D3D",
            "name": "D3D",
            "decimals": 0,
            "logoURI": "https://bafkreidhiegzjjqnikoevl4fbuuocdrnvmxyy2bnnoeoha6kwykhgscuwm.ipfs.dweb.link/?ext=png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7sBZAuUP",
                "twitter": "https://twitter.com/DeadRejcts",
                "website": "https://discord.gg/deadrejects"
            }
        },
        {
            "chainId": 101,
            "address": "2QQSvZYmMRBvqmJXWeSBNhDvRWQ6b4PgB5cYhVkE1xjt",
            "symbol": "LANA-SOL",
            "name": "LANA SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dev8x/token-list/main/assets/mainnet/2QQSvZYmMRBvqmJXWeSBNhDvRWQ6b4PgB5cYhVkE1xjt/icon.png",
            "extensions": {
                "medium": "https://lanafinance.medium.com/",
                "twitter": "https://twitter.com/lanaprotocol",
                "website": "https://lanafi.app/"
            }
        },
        {
            "chainId": 101,
            "address": "3uJmmABv4B2W7Qih1DdjrzvtdAbkuishC7vqq3UN2X1h",
            "symbol": "uHOD",
            "name": "HOD Utility Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/hodrbrth/assets/main/hodjpg.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "description": "The utility token for House of Dracula NFT Project. Use this token to benefit from the entire HOD ecosystem.",
                "discord": "https://discord.gg/nYaH78mm",
                "instagram": "https://www.instagram.com/wearehod",
                "twitter": "https://twitter.com/wearehod",
                "website": "https://houseofdracula.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3ht2fqucXTo2MxSYf4ynFk38czcacMz8enk3NQBS9QsE",
            "symbol": "END",
            "name": "Coinecny",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PepeUltim/pepe2/main/coinecny.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EHzG5r13iUB1AAYgBZUsqr6wrsgN9Ukt4ti6kiZsYLqk",
            "symbol": "QDRO",
            "name": "Quadro",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/thaguss/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Up16GyRmybEEDfaCsDszkzkvtWgoKDtS4cUyBEjvPBM",
            "symbol": "VAULT",
            "name": "Vandal City Vault",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Up16GyRmybEEDfaCsDszkzkvtWgoKDtS4cUyBEjvPBM/logo.png",
            "tags": [
                "gaming-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6kkSMsndFkxvGLMbmDXrA2rcEgyaipQwQM5mpCCK1hAa",
            "symbol": "SB",
            "name": "Shaaban",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/abeddshaban/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "6kkSMsndFkxvGLMbmDXrA2rcEgyaipQwQM5mpCCK1hAa",
            "symbol": "Sb",
            "name": "Shaaban",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/abeddshaban/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "31GHSSaLHJyUVXzXFLe9GrZYBVeamW81Jgvibz2LkvgC",
            "symbol": "P2PCO",
            "name": "Peer2Peer Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/robbescobedo/P2PCOIN-DEV/main/P2PCOIN.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5ade7tniH92hsvySFFTCLMoDLj1BXndWtwMmqAJVFdmp",
            "symbol": "soI",
            "name": "soIana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Solofuu/sol-project/main/sol%20logo.png",
            "tags": [
                "Social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8zTUiMnMUFeSEV5iZjmY4v8TZTj5fw9Hx7ex9XEZULWC",
            "symbol": "FIT",
            "name": "Fit Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8zTUiMnMUFeSEV5iZjmY4v8TZTj5fw9Hx7ex9XEZULWC/logo.png",
            "tags": [
                "utility-token",
                "fitness-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/fitroomnft",
                "website": "https://www.fitroomproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BAaN4r6pPHNLhm5t5FssMLpcnYFz9MpxUBc1gVox4jRF",
            "symbol": "SHADE",
            "name": "SHADES",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAaN4r6pPHNLhm5t5FssMLpcnYFz9MpxUBc1gVox4jRF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Y72V9wn3Yw",
                "reddit": "https://www.reddit.com/r/AikoNFT/",
                "twitter": "https://twitter.com/aikonft",
                "website": "https://aiko.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HYb8vNQmJxbwcW6vwM6b5pk54uqC3z2VyhvkF6SUJLuE",
            "symbol": "BWL",
            "name": "Bandit Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYb8vNQmJxbwcW6vwM6b5pk54uqC3z2VyhvkF6SUJLuE/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TooaT4kKfpGQ7bD9NsZhEU81qDXoAn2GzqBLyaGY1e8",
            "symbol": "WLHELLCATS",
            "name": "SOLHELLCATS Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TooaT4kKfpGQ7bD9NsZhEU81qDXoAn2GzqBLyaGY1e8/logo.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Fpb5Z3rEkN",
                "twitter": "https://twitter.com/solhellcats",
                "website": "https://www.solhellcats.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Bdx9ATvoc2xnieDPRyeCcxpxNsk9fFUUwixhN4rmH6Lo",
            "symbol": "HELL",
            "name": "HELL Protocol Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bdx9ATvoc2xnieDPRyeCcxpxNsk9fFUUwixhN4rmH6Lo/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "game-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Fpb5Z3rEkN",
                "twitter": "https://twitter.com/solhellcats",
                "website": "https://www.solhellcats.com/"
            }
        },
        {
            "chainId": 103,
            "address": "7nQYf1SWiWzdyb3yBX9zab9faCqtPQojka7Qu7YARiBn",
            "symbol": "OUTO",
            "name": "Outcast Otter Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7nQYf1SWiWzdyb3yBX9zab9faCqtPQojka7Qu7YARiBn/logo.png",
            "extensions": {
                "discord": "https://discord.gg/jQECYpurkm",
                "twitter": "https://twitter.com/outcastotters",
                "website": "https://outcastotters.com"
            }
        },
        {
            "chainId": 101,
            "address": "CUvVMqXAcyFJnwMhojQ9jmGuWrijGt26HfY7b99dyBeB",
            "symbol": "FLWRS",
            "name": "FLWRS Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/pablolefleur/flwrsimage/main/flwrs%20logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ANjyHtneJY7ntKwgd9F5Xy2ui3zzn6NYyGEXQzaVzQnK",
            "symbol": "OCEAN",
            "name": "Rich Water Reserve Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANjyHtneJY7ntKwgd9F5Xy2ui3zzn6NYyGEXQzaVzQnK/logo.png",
            "tags": [
                "currency",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RichwaterNFT",
                "website": "https://www.richwaternft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BBaUcA9HFb6iJgXBzWn7pi1bptuBMETNXzge2BGti9Ts",
            "symbol": "RMBLWL",
            "name": "Rumble Monkeys Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BBaUcA9HFb6iJgXBzWn7pi1bptuBMETNXzge2BGti9Ts/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RumbleMonkeys",
                "website": "https://rumblemonkeys.io"
            }
        },
        {
            "chainId": 101,
            "address": "3VB7TvkTb4VtdxN7sdWe8upRg6hyeUtKKAFEneeRWQAs",
            "symbol": "EGTA",
            "name": "Acción Ecogoldtech SAS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VB7TvkTb4VtdxN7sdWe8upRg6hyeUtKKAFEneeRWQAs/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "security-token",
                "community-token"
            ],
            "extensions": {
                "description": "EGT representa las acciones tokenizadas oficiales de la compañía minera colombiana Ecogoldtech S.A.S. EGT es un token de utilidad para nuestro protocolo de credibilidad universal, respaldado por la solidez, robustez y transparencia de un fuerte motor productivo y comercial basado en oro como commodity y valor refugio a nivel global. La posesión de una acción EGT representa un compromiso entre Ecogoldtech S.A.S y sus accionistas, en el que se comprenden beneficios exclusivos y corporativos a cambio del mantenimiento de la acción durante cierto periodo de tiempo. Creemos que EGT representa la modernización de la industria tradicional en una nueva era digital, con la capacidad de poner a disposición de todos grandes oportunidades económicas y globales.",
                "website": "https://ecogoldtech.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BTiwt1sy2gr5Q2vpfZAQ8uFdy155h8vDo4H44yHuQs8Y",
            "symbol": "RELIC02",
            "name": "FUTURE RELIC 02 Mint Pass",
            "decimals": 0,
            "logoURI": "https://artiftakt3d.s3.amazonaws.com/future-relic.gif",
            "extensions": {
                "description": "Mint pass for FUTURE RELICS 02",
                "imageUrl": "https://artiftakt3d.s3.amazonaws.com/future-relic.gif",
                "twitter": "https://twitter.com/ARTIFAKTS_sol",
                "website": "https://www.future-relics.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "45tMkQQo6edS4qaYkSn6u835KE5J11R8xpp8G2VCSwXE",
            "symbol": "SILT",
            "name": "SILICA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/GameSlaveSA/crypto/main/silicalogo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/silicatoken",
                "website": "http://www.silicatoken.tech"
            }
        },
        {
            "chainId": 101,
            "address": "hsySZyND6UyLm748LDcWG7SLDzyTpgF4jpFnZdMwPXh",
            "symbol": "BVLL-G-OG",
            "name": "BVLL-G OG Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/noFAYZ/bvll-g/main/public/bvllgog.png",
            "extensions": {
                "description": "BVLL-G OG Mint Pass",
                "imageUrl": "https://raw.githubusercontent.com/noFAYZ/bvll-g/main/public/bvllgog.png",
                "website": "https://bvll-g.com/"
            }
        },
        {
            "chainId": 101,
            "address": "B2K7et7RDYjpuaYFcspBYxbnWP7C3v85cQif7ZsntVtz",
            "symbol": "BVLL-G-WL",
            "name": "BVLL-G WL Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/noFAYZ/bvll-g/main/public/bvllgwl.png",
            "extensions": {
                "description": "BVLL-G WL Mint Pass",
                "imageUrl": "https://raw.githubusercontent.com/noFAYZ/bvll-g/main/public/bvllgwl.png",
                "website": "https://bvll-g.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7utH6rGUdnuji8mMmRQxqysYyzTAH7bbL11Abzpon3s1",
            "symbol": "UB",
            "name": "UB WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7utH6rGUdnuji8mMmRQxqysYyzTAH7bbL11Abzpon3s1/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Z7AmnrU8LXpjPj81Ge4hSAB58cG7EAEpNiHv5sNPXBb",
            "symbol": "PANDASK",
            "name": "PandaSk-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Panda36987/PNG/main/dddweewe.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GJ6pXFziLYZXQoEfuhMsemvBYwYi8BbDwBV41gHGPtaF",
            "symbol": "CHRS",
            "name": "Chriscoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vekioschristos/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FmQ7v2QUqXVVtAXkngBh3Mwx7s3mKT55nQ5Z673dURYS",
            "symbol": "DARK",
            "name": "The Dark Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FmQ7v2QUqXVVtAXkngBh3Mwx7s3mKT55nQ5Z673dURYS/logo.png",
            "tags": [
                "nfts",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/anonclub",
                "medium": "https://theanonclub.medium.com/",
                "twitter": "https://twitter.com/theanonclub",
                "website": "https://theanonclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CRWkXevcvWpkftaCFWKnxpeoAiBvRJy4vLCAo8sFqxs5",
            "symbol": "THUGZ",
            "name": "3D THUGZ WHITELIST TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRWkXevcvWpkftaCFWKnxpeoAiBvRJy4vLCAo8sFqxs5/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wK4Sxdjn55",
                "twitter": "https://twitter.com/3d_thugz",
                "website": "https://3dthugz.com"
            }
        },
        {
            "chainId": 101,
            "address": "ToeSuh71yJAWP2EeKwm3wXDpcJKeudpTK86ec4ftTUD",
            "symbol": "CD",
            "name": "Chicken Diddle WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCP8hGKS6fUGfTA1tQxBKzbXuQk7yktzz71pY8LXVJyR/logo.png"
        },
        {
            "chainId": 101,
            "address": "E85VGYoRTojo2tYvoCqEtKUTr16Xcm6DbxcooK1T4mwJ",
            "symbol": "ST",
            "name": "Solmon Trainer Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jwurrow/solmon/a715cead035d9182efe018ff11827ccec4fc7dd1/solmon-token.png",
            "extensions": {
                "description": "Solmon Trainer Whitelist Token",
                "imageUrl": "https://raw.githubusercontent.com/jwurrow/solmon/a715cead035d9182efe018ff11827ccec4fc7dd1/solmon-token.png",
                "website": "https://solmon.game/"
            }
        },
        {
            "chainId": 101,
            "address": "FGWLpx2iQntuhYcLfECbJcDDpQ6LW2feHJA7ZYv3F244",
            "symbol": "FGWL",
            "name": "Float Goat Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGWLpx2iQntuhYcLfECbJcDDpQ6LW2feHJA7ZYv3F244/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "TFTY9zFrum5FkJ63WqJFFSti7mAXyMSNvodx1xnHL1H",
            "symbol": "TFWL",
            "name": "Top Floater Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGWLpx2iQntuhYcLfECbJcDDpQ6LW2feHJA7ZYv3F244/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "SFTZFZd9t4uCmRqdDL3WY248Bbdkdgv5Q6PUHc9bPNC",
            "symbol": "SFWL",
            "name": "Sky Floater Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGWLpx2iQntuhYcLfECbJcDDpQ6LW2feHJA7ZYv3F244/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "GEN3cWvZvGphDWGe534R7bgttgHiZ4TbZ9EUBLgEo6EG",
            "symbol": "GEN3MINT",
            "name": "Gen3 Studios Mint Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/_Q3yzSqPrGlAS6Jr3f5L-y3w9nLm66_0ru8kVXNUYnY",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/haK4f52h",
                "twitter": "https://twitter.com/Gen3studios",
                "website": "https://gen3.studio"
            }
        },
        {
            "chainId": 101,
            "address": "Fb9krWjycVnuxPXo52k5BgHf7tXpG88HsD4aMQB5oXn2",
            "symbol": "CRNY",
            "name": "Corny Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jtcordrick1/crypto/main/corn3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "93dbPLEUYm3Res4t9CTRn1GQq9qs24PdZrxonRicpbr2",
            "symbol": "saSOL",
            "name": "Saturn Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/93dbPLEUYm3Res4t9CTRn1GQq9qs24PdZrxonRicpbr2/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/FQRfWwJzYR",
                "twitter": "https://twitter.com/saturnfi_io"
            }
        },
        {
            "chainId": 101,
            "address": "6mB8su3dqgyw4yZWgWZ43aJwGm8CzLfM8LJAmDHL8cNL",
            "symbol": "WISER",
            "name": "Wiseree Coin",
            "decimals": 9,
            "logoURI": "https://upload.wikimedia.org/wikipedia/commons/0/01/Wiseree_Coin_%24WISER.png",
            "tags": [
                "tokenized-stock"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/kButrWVqCM",
                "website": "https://wiseree.com"
            }
        },
        {
            "chainId": 101,
            "address": "8RKSaCnMxeo23Lcu6CdGSg32NE5Rb7ZN7AK2FjbRAG3v",
            "symbol": "WISEREE",
            "name": "Wiseree",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/Sah8lD1.png",
            "tags": [
                "equity-token",
                "wiseree"
            ]
        },
        {
            "chainId": 101,
            "address": "6fzEHmkH42eekd9g6HyNRtMjjzUg7hHfyH2HppNBVeVe",
            "symbol": "MACTN",
            "name": "Moon Man Crew",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Nazerath/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AtPYo7iR8PSVXWAugzFvHpN7K1AG9PTdXwxEnMjZBBxX",
            "symbol": "DBRB",
            "name": "Doberbull",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/abdallahbaddi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "756wgK33gHAZcLAaS6BTx7jkNetVX6WiMcNmcwrwLWCQ",
            "symbol": "MMWL",
            "name": "Mythic Mushies WL",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/756wgK33gHAZcLAaS6BTx7jkNetVX6WiMcNmcwrwLWCQ/logo.png",
            "extensions": {
                "discord": "http://discord.gg/QT8TX7QDt2",
                "twitter": "https://twitter.com/magic_mushies",
                "website": "https://magicmushies.io"
            }
        },
        {
            "chainId": 101,
            "address": "3NXwmG2qpve7EmRBKA1G8T87s8Vj5H62M7Pt5HYRVuKf",
            "symbol": "omarjjjim",
            "name": "omarjjjim",
            "decimals": 2,
            "logoURI": "https://images.google.com/images/branding/googlelogo/1x/googlelogo_light_color_272x92dp.png",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "2DN94M5KNfpKQNz1ZPwM6m7xJic5kp1WqZ4sNu2hkJt1",
            "symbol": "BOOPY",
            "name": "Shaboopy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jtcordrick3/crypto/main/4162931991598216689a.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2MrEUc687HeyCZbv8GqszwBD2UPNVnRxiWdqdDrRtGpE",
            "symbol": "SCTY",
            "name": "SCARCITY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hazza24951/SWP_NFT/main/logo.png.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Aj7p7pCarQFvtSVv5gvRGbJ5yddpAajqpHgJmZWboTnD",
            "symbol": "IMGN",
            "name": "Imagine Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aj7p7pCarQFvtSVv5gvRGbJ5yddpAajqpHgJmZWboTnD/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DahlysArt",
                "website": "https://www.dahlys.art/"
            }
        },
        {
            "chainId": 101,
            "address": "3vV1J2ZyxhGpWhNxQoCN9o6kKqTSmvwavnagbbtfn9GC",
            "symbol": "DRMS",
            "name": "Dream Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vV1J2ZyxhGpWhNxQoCN9o6kKqTSmvwavnagbbtfn9GC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DahlysArt",
                "website": "https://www.dahlys.art/"
            }
        },
        {
            "chainId": 101,
            "address": "FR56xFsUveu3fhxSP8RLuCeUfCi2EpjNsTNnLhqQncgs",
            "symbol": "SSUT",
            "name": "Space Society Universal Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FR56xFsUveu3fhxSP8RLuCeUfCi2EpjNsTNnLhqQncgs/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/spacesocietynft",
                "website": "https://thespacesociety.co"
            }
        },
        {
            "chainId": 101,
            "address": "75KALs9asCxh1nPaNdL75tqBA8gQuP8mx3psemhZUavG",
            "symbol": "MPT",
            "name": "Mortal Powers Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MortalPowers/MP_Token/main/MortalPowers-Final-Sm.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3yNEo7jmJPPCr5mtHotYAyxdaVJpJS1Jqw9E52dgobu3",
            "symbol": "SGM",
            "name": "SOLGEM",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3yNEo7jmJPPCr5mtHotYAyxdaVJpJS1Jqw9E52dgobu3/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Solg3m",
                "website": "https://solgem.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "JoZoiu7Mxr8P4exP7zcfp5GKg3vv3w3z9q5gpLZ2Jkz",
            "symbol": "JOZO",
            "name": "JOZO GATORS WL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JoZoiu7Mxr8P4exP7zcfp5GKg3vv3w3z9q5gpLZ2Jkz/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/JozoGators"
            }
        },
        {
            "chainId": 101,
            "address": "xkqjobmo1kUgN4P7jcsWe5ud657oA3co4PnwKoQKG12",
            "symbol": "$DRAY",
            "name": "DRAY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/benpazzo/dray/main/assets/draytoken.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "description": "Draygon is DAO and Alpha NFT project. ",
                "discord": "http://discord.gg/draygonco",
                "twitter": "https://twitter.com/DraygonCo",
                "website": "https://draygoninvestment.co/"
            }
        },
        {
            "chainId": 101,
            "address": "519W6ZZnduudeo6tp1YMgeWiNFeoq4kTVS4uMZggKE5r",
            "symbol": "KLGT",
            "name": "Kleyne Gelt",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/klglt/Crypto/main/menorah.png",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 101,
            "address": "7KUcrx2aNjF9H6eLVKCkeYqou7d6KddSTJEM3TCJQPwJ",
            "symbol": "TUNAt",
            "name": "TUNA-test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/hydrated-seal/tunaimage/main/tuna.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/spacesocietynft",
                "website": "https://thespacesociety.co"
            }
        },
        {
            "chainId": 101,
            "address": "kUNGFUYSTn7c6S7nUjTYsKiyTQ4FVf3cPnwW5zzeaLg",
            "symbol": "KFWT",
            "name": "Kungfu Warriors Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/kfw/kfwt-icon.GIF",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/3NRuj7xktT",
                "instagram": "https://www.instagram.com/KungFuWarriors",
                "twitter": "https://twitter.com/KungFuWarriors",
                "website": "https://kungfuwarriors.io/"
            }
        },
        {
            "chainId": 101,
            "address": "MiLKR8QYixQxp5wDXwfC8fLVH4AiAyb3X9YpAcXvtWe",
            "symbol": "KLIM",
            "name": "WiseCats Milk",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/wisecats/milk-icon.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wisecats",
                "instagram": "https://www.instagram.com/wisecatsnft",
                "twitter": "https://twitter.com/WiseCatsNFT",
                "website": "https://www.wisecatsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "DBRL2h748M2DgvfFoWSZhnBCakGWUbY2Z3NzghQcFt3R",
            "symbol": "$BUDZ",
            "name": "BUDZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/KryptoKronikz/budz-icon.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/krypto-kronikz",
                "instagram": "https://www.instagram.com/kryptokronikz",
                "twitter": "https://twitter.com/KryptoKronikz",
                "website": "https://www.kryptokronikz.co.uk/"
            }
        },
        {
            "chainId": 101,
            "address": "G5Lxk35xEXLCrp4PRMpymZGBCuTPRNeDRHfEWyj67fK8",
            "symbol": "PONO",
            "name": "Pono Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G5Lxk35xEXLCrp4PRMpymZGBCuTPRNeDRHfEWyj67fK8/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D4L61nbUsaNngn5rYfAQAhV7QasffCcoKqQitbMyNMtB",
            "symbol": "RCWL",
            "name": "Royal Crows WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4L61nbUsaNngn5rYfAQAhV7QasffCcoKqQitbMyNMtB/rc.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "Royal Crows WL is a whitelist token for RC candy machine."
            }
        },
        {
            "chainId": 101,
            "address": "53PDMXwN8Gv6zq1a8FcqeDRC9quma5RaX9veqWnmFY3p",
            "symbol": "RYCO",
            "name": "Rycoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/265866/crypto/main/rycoin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AEzCK6jhPpZgTwoVh3dpQHR8d5sTxnmSvqegbgsxyfTa",
            "symbol": "APWL",
            "name": "Alpha Pharaohs Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEzCK6jhPpZgTwoVh3dpQHR8d5sTxnmSvqegbgsxyfTa/Logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4gvGHL1mtEBWd1YJUNBJBJJFF9EsGesq1DsKwJXb8QEp",
            "symbol": "RB",
            "name": "ReluBit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/relu1000/ReluBit/main/unnamed.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "63codSeTmtG9BBQmjZdNZiFzWPV3KD8RrNPcXAREvk2F",
            "symbol": "WISR",
            "name": "Wiseree Token",
            "decimals": 9,
            "logoURI": "https://upload.wikimedia.org/wikipedia/commons/0/01/Wiseree_Coin_%24WISER.png",
            "tags": [
                "tokenized-stock",
                "equity-token",
                "wiseree"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/kButrWVqCM",
                "website": "https://wiseree.com"
            }
        },
        {
            "chainId": 103,
            "address": "ToxQEMzPrMFg6EbreSkzP76kVq3QtHbiiYJ9QkpMYjn",
            "symbol": "BT",
            "name": "Brownie Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToxQEMzPrMFg6EbreSkzP76kVq3QtHbiiYJ9QkpMYjn/tokb.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 103,
            "address": "E5d55Pq6SfTHjaLyjrvMb1kdcVeyvifricA9NV1JJtqh",
            "symbol": "CSAP",
            "name": "Crypto Seed Accountancy Platform (devnet)",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/michel47/csap/master/assets/csap.svg",
            "tags": [
                "community-token",
                "utility-token",
                "wrapped",
                "green-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9EPrkNg8jMNhQQM3bpKt2NJHSKQoNCHuaqDF5o4BPvR9",
            "symbol": "phxcoin",
            "name": "PhenomX Coin",
            "decimals": 5,
            "logoURI": "https://bafybeice4ze77lnjlrv42d2pyobeccwc6guyfw7absvt7fhfjv5ofgm5pe.ipfs.nftstorage.link/?ext=svg",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "address": "AJBRVyfxgA29CU2aACYe2Dhs41GYgQDPa7cXod394PS7",
                "blog": "https://www.phenomxhealth.com/blog",
                "description": "PhenomX Coin is the token that power the phenomX ecosystem",
                "discord": "https://discord.gg/vwkCmMQv",
                "imageUrl": "https://gateway.ipfs.io/ipns/QmXdq8c7fUT9Vmo7SkCQG7gPRrZV89L3n5eRdPVnSTG5W7/phxcoin-shinier.svg",
                "linkedin": "https://linkedin.com/company/phenomxhealth",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://phenomxhealth.com",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmXdq8c7fUT9Vmo7SkCQG7gPRrZV89L3n5eRdPVnSTG5W7/whitepaper.html",
                "youtube": "https://www.youtube.com/channel/UChQiCa4_Dqg7Htqop5xbV2g"
            }
        },
        {
            "chainId": 103,
            "address": "PVit7qYXHVt5VExukg9DQgPa2p4QRGfRmU7z8U6e5xq",
            "symbol": "PVIT",
            "name": "e-Vitamins",
            "decimals": 0,
            "logoURI": "https://ipfs.safewatch.care/ipfs/QmdD12pqJHApFrUAbxVs9BwFLhjBtXd5cjZ1fD2YLe3Abs/vitamins-nft.svg",
            "tags": [
                "community-token",
                "utility-token",
                "green-token"
            ],
            "extensions": {
                "address": "FsMKihJmPpnDsXBnTfRiH5ciDi2vyS43UheMLNwKqUXs",
                "blog": "https://www.phenomxhealth.com/blog",
                "description": "PhenomX Virtual Vitamins",
                "discord": "https://discord.gg/vwkCmMQv",
                "imageUrl": "https://gateway.ipfs.io/ipfs/QmdD12pqJHApFrUAbxVs9BwFLhjBtXd5cjZ1fD2YLe3Abs/vitamins-nft.svg",
                "linkedin": "https://linkedin.com/company/phenomxhealth",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://phenomxhealth.com",
                "whitepaper": "https://gateway.ipfs.io/ipfs/QmdD12pqJHApFrUAbxVs9BwFLhjBtXd5cjZ1fD2YLe3Abs/whitepaper.html",
                "youtube": "https://www.youtube.com/channel/UChQiCa4_Dqg7Htqop5xbV2g"
            }
        },
        {
            "chainId": 103,
            "address": "7KUcrx2aNjF9H6eLVKCkeYqou7d6KddSTJEM3TCJQPwJ",
            "symbol": "TUNAt",
            "name": "TUNA-test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/hydrated-seal/tunaimage/main/tuna.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/spacesocietynft",
                "website": "https://thespacesociety.co"
            }
        },
        {
            "chainId": 101,
            "address": "87rSGrpYdmTxfNBf8o2cpyiNcxCmNhUPBXjT8aoyfob5",
            "symbol": "ENX",
            "name": "Equinox",
            "decimals": 9,
            "logoURI": "https://ixrjpmbgkspkjaueqzmyhb3fbca5atffgsrqyt527d2x3ycdlgqq.arweave.net/ReKXsCZUnqSChIZZg4dlCIHQTKU0owxPuvj1feBDWaE",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolminatorNFT",
                "website": "https://www.solminator.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7BaHC9YUWRGqa7BSq3pBMJZSaATaNQRDt7q7AFPbrjcj",
            "symbol": "CHLO",
            "name": "ClorofilaCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/marianelo-crypto/ClorofilaCoin/principal/logo_ClorofilaCoin_CHLO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9H4reH91YZb7Mp7rBGkvzqdgTxHmr56w3ywW5E3nqghN",
            "symbol": "HMOD",
            "name": "Hairmod",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/bteberci/crypto/main/hairmodlogo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Aw6KrYrZW8KEujVBVRfnGGf3mwfqss2JD3muoZtvBuhR",
            "symbol": "BPt",
            "name": "BluePanda",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pppanda369/tokenP/main/tokenP.png",
            "tags": [
                "BPT-Invest"
            ]
        },
        {
            "chainId": 101,
            "address": "3xjxZZxht4HL3SxSDMgMiHckMe7prj9xfCq62RCgiLzA",
            "symbol": "JNDN",
            "name": "JUNDAN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/kingsirc9891/jundan/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GTjk7ejeDEQuJVpSDZHDgbPKqEPEd8ErtK1eUG4Yunzp",
            "symbol": "RETK",
            "name": "Retroken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Gusso0o/logotkn/main/logotkn.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "khashyCKhBmP6mnuUrrLLb41KzLREGA6ojpZjmkbGVg",
            "symbol": "khash",
            "name": "Virtual K(h)ash",
            "decimals": 0,
            "logoURI": "https://bafybeie6htah6p4qixy7mfqfpyqzbymprfooqmavoj4ou5fd5ibvhtbgt4.ipfs.nftstorage.link/?ext=svg",
            "tags": [
                "community-token",
                "utility-token",
                "green-token"
            ],
            "extensions": {
                "address": "AFzvsxCr8PXaNeqhMwN68GvpeskJhr4eXMCQCSUgSZg4",
                "description": "Virtual Khash can be use in the MetaVerse in place of IRL cash.",
                "discord": "https://discord.gg/vwkCmMQv",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://duckduckgo.com/?q=Virtual+K(h)ash+khash",
                "whitepaper": "https://gateway.ipfs.io/ipfs/QmXbedz6CMuP1CyJFsQ95ZnSepAMbuMVdLbfDFtbszeFk4/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "5Ty5cZXX7MrsY9EgFZzJk4dpve8UqXrns8bB8qbaLiFw",
            "symbol": "DogeThug",
            "name": "DogeThug",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dogethug/dogethug/main/logo.png",
            "tags": [
                "social-token",
                "meme-coin",
                "doge"
            ]
        },
        {
            "chainId": 101,
            "address": "FhSL8uPeXtfdMiEwSmPh7VBR2dcTj2AUR64aiFJGiYN2",
            "symbol": "Pulgui",
            "name": "Pulguita Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/VRES2022/crypto/main/pulgui.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fh7ZuBQzTP84y3kyjyBnNivJq7LzZkPJRbUkJaMPQxHg",
            "symbol": "RZPP",
            "name": "Rzeczpospolita Polska",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fh7ZuBQzTP84y3kyjyBnNivJq7LzZkPJRbUkJaMPQxHg/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "97eJ4n5UyhDzZX6qvUKgV5tpJnd6xn2ARrARAzk73Nkf",
            "symbol": "TO$HI",
            "name": "Toshcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97eJ4n5UyhDzZX6qvUKgV5tpJnd6xn2ARrARAzk73Nkf/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qayh5Qc3BBR91KA6CemKN7vmzmexRJJw2KWFPzn53Uw",
            "symbol": "BELL",
            "name": "BELL",
            "decimals": 6,
            "logoURI": "https://cdn.discordapp.com/attachments/928110149388869684/967531744741957632/bell_282.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A12U6EVQZghbQGQ8qedttVgtMBQYFqLTzA9HcSVTToJF",
            "symbol": "BIZ",
            "name": "Biznessmen",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A12U6EVQZghbQGQ8qedttVgtMBQYFqLTzA9HcSVTToJF/BIZ-Logo.png",
            "tags": [
                "game-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://biznessmennft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "STuLiPmUCUtG1hQcwdc9de9sjYhVsYoucCiWqbApbpM",
            "symbol": "sTulip",
            "name": "Staked Tulip",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/8619106/164987734-11c00b2e-07d7-47ce-8441-63b063ec8bb2.png",
            "tags": [
                "solfarm",
                "tulip",
                "staking"
            ]
        },
        {
            "chainId": 101,
            "address": "3DGWuX6nnGmza9Xk6GuXQnUEFRcXmc9y9NYndB96RpHW",
            "symbol": "SUS",
            "name": "Impostor",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3DGWuX6nnGmza9Xk6GuXQnUEFRcXmc9y9NYndB96RpHW/logo.png",
            "tags": [
                "currency",
                "utility-token",
                "meme-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C9x1Kx6A7GvgKfrLWdcNdpzvUK1XwF71UTErxK5mCJSh",
            "symbol": "FOLI",
            "name": "Foli",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9x1Kx6A7GvgKfrLWdcNdpzvUK1XwF71UTErxK5mCJSh/logo.png",
            "tags": [
                "reward-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "49hmSAZ8oqf2u4QQ31qUAk6PHqvMsCBt27zFSx6CsY1w",
            "symbol": "LPROT",
            "name": "Lucky clover Protocol",
            "decimals": 9,
            "logoURI": "https://yourimageshare.com/ib/6AJLQG2VvZ",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/clover_finance"
            }
        },
        {
            "chainId": 101,
            "address": "5grpAJejHkwUaSRedSUw4vWerFGpgtc4gjVxu8GxuVRe",
            "symbol": "HOM3",
            "name": "Hom3 Protocol",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/H2bm0z1/Untitled-design-1.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/hom3/"
            }
        },
        {
            "chainId": 101,
            "address": "7VqabivpX5z9sjd7QjVrGyzQkdrRQFC5jARnBTUQWKjc",
            "symbol": "CAREIV",
            "name": "Care IV Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/crcnum4/careiv/main/assets/logo.png",
            "tags": [
                "social-token",
                "DAO-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CliffC_4"
            }
        },
        {
            "chainId": 101,
            "address": "DsmHzZmJSjtvSabgP1njqYN249UVEvFnNd1F9ZVHPTXR",
            "symbol": "c2oCoinNFT",
            "name": "c2oCoin NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/c2ocoin/nftsolana/main/logoc2o_frente300x300.png",
            "tags": [
                "NFT",
                "NFT-collection",
                "investment-NFT",
                "collection",
                "social-token"
            ],
            "extensions": {
                "website": "https://c2ocoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "BqwNZxHDBm3T2DfNbQdMdrkSHp5fb73MovZd7g5MeeFa",
            "symbol": "ACO",
            "name": "Air Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/moonfall22/logo/main/aircoin.png",
            "tags": [
                "reward-token",
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DJjevoCBomzgNe76pNDd8stAcMS4x7Y99giuNpqGLa2f",
            "symbol": "USTv2-3Pool",
            "name": "Mercurial LP Token (USTv2-3Pool)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJjevoCBomzgNe76pNDd8stAcMS4x7Y99giuNpqGLa2f/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "website": "https://www.mercurial.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "HwxBPJSgiHpwqDFACnN19X9jpDDqs3HpoYoLpR9ocYTU",
            "symbol": "ALC",
            "name": "Al-Alawi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nalalawi2022/Al-Alawi-Coin/main/logo3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe",
            "symbol": "CREAMY",
            "name": "Creamy",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/creamyfriends",
                "serumV3Usdc": "BxTfmxEQf6FQ6F1cQ3fi6o6FPG52hiZXi4DTGYRhsmPo",
                "twitter": "https://twitter.com/CreamyFriends"
            }
        },
        {
            "chainId": 101,
            "address": "WLfsPBJYqawoAK54RxEQk8uNFTxnzjWmYtaApRQJg5e",
            "symbol": "LARTFF",
            "name": "Populart FF",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLfsPBJYqawoAK54RxEQk8uNFTxnzjWmYtaApRQJg5e/logo.png",
            "tags": [
                "FF-Token",
                "community-token",
                "utility-token",
                "populart-nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/populart_nft",
                "website": "https://www.populart.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "WLtPRknre13aWE4QqsSbcEpQG8W4aApUJ3NT4YPfQrr",
            "symbol": "LART",
            "name": "Populart WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLtPRknre13aWE4QqsSbcEpQG8W4aApUJ3NT4YPfQrr/logo.png",
            "tags": [
                "WL-Token",
                "community-token",
                "utility-token",
                "populart-nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/populart_nft",
                "website": "https://www.populart.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "4Ra2qncf7F3t2z8HHtK3mdgmec7wMs9XgW9a45gS8rqR",
            "symbol": "VALT",
            "name": "Valt Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Ra2qncf7F3t2z8HHtK3mdgmec7wMs9XgW9a45gS8rqR/logo.png",
            "tags": [
                "ValtGym",
                "Valt",
                "Whitelist",
                "Metaverse"
            ],
            "extensions": {
                "description": "Valt CryptoPumps NFT Whitelist Token",
                "discord": "https://discord.gg/qYn9gQPPSQ"
            }
        },
        {
            "chainId": 101,
            "address": "C7SBQ7Pb8D9wbkfkvjXRCdAEukubXdYC38fp4yBTz2ue",
            "symbol": "BLOOMIE",
            "name": "BLOOMIE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7SBQ7Pb8D9wbkfkvjXRCdAEukubXdYC38fp4yBTz2ue/logo.svg",
            "tags": [
                "utility-token",
                "game-token"
            ],
            "extensions": {
                "website": "https://bloomverse.io"
            }
        },
        {
            "chainId": 101,
            "address": "DtSs1GN6PzyLWESWf7QoAys5j6GvPoNk2GaiuXx12Y3f",
            "symbol": "CIBA",
            "name": "Ciba Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Curlyfriesssss/crytpo/main/asdw.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TUXUczFDB2w6EqR3gRfhgqLq6xuaAY5SEzTELu2qKGq",
            "symbol": "TuXWL",
            "name": "Tuxedo WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TUXUczFDB2w6EqR3gRfhgqLq6xuaAY5SEzTELu2qKGq/logo.png"
        },
        {
            "chainId": 101,
            "address": "7nTddmDiPiTaahTHfj441PW8bTjLbYUf3KyzrGnankvZ",
            "symbol": "NMR",
            "name": "Node Monkey Free Rental",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7nTddmDiPiTaahTHfj441PW8bTjLbYUf3KyzrGnankvZ/logo.jpg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "This token is for a free rental from the Official Node Monkey marketplace.",
                "twitter": "https://twitter.com/nodemonkeynft"
            }
        },
        {
            "chainId": 101,
            "address": "HnhhXtqCoYzQpV8doDB4MXkPnpUUajUD6BQt6nkpbcMa",
            "symbol": "MYTH",
            "name": "Mythic Mushies Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HnhhXtqCoYzQpV8doDB4MXkPnpUUajUD6BQt6nkpbcMa/logo.png",
            "extensions": {
                "discord": "http://discord.gg/QT8TX7QDt2",
                "twitter": "https://twitter.com/magic_mushies",
                "website": "https://magicmushies.io"
            }
        },
        {
            "chainId": 101,
            "address": "62s6EQ6s3hpRufbispzk5DD1yt7JjPzoTUewNYtMmjFW",
            "symbol": "KPRO",
            "name": "Kproapps Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/62s6EQ6s3hpRufbispzk5DD1yt7JjPzoTUewNYtMmjFW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://kproapps.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7tMVov1P9beex6rphq3iaox1TrTPUscgS8Ay4oLWSSVh",
            "symbol": "MRWL",
            "name": "Mutant Miner WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tMVov1P9beex6rphq3iaox1TrTPUscgS8Ay4oLWSSVh/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "23WuycvPjEuzJTsBPBZqnbFZFcBtBKAMTowUDHwagkuD",
            "symbol": "GEAR",
            "name": "Starbots GEAR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/23WuycvPjEuzJTsBPBZqnbFZFcBtBKAMTowUDHwagkuD/logo.png",
            "tags": [
                "utility-token",
                "play-to-earn",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Starbots_game",
                "website": "https://starbots.net"
            }
        },
        {
            "chainId": 101,
            "address": "FjMQibN74kDTDVwGhMjoPP9YjsrGdULwnCdxK5U6Hxxz",
            "symbol": "PANTHEON",
            "name": "Olympus pantheon",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/dcHtR9V/6.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/Imperius_Ira?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            }
        },
        {
            "chainId": 101,
            "address": "Cwiv21vbFdwJRtGomuCDyWz2w6xvPwoUqTmuyrFtU4Sa",
            "symbol": "VDEF",
            "name": "V-Defi Token",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/PxKBVzV/5.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://twitter.com/definow/"
            }
        },
        {
            "chainId": 101,
            "address": "7bJ4cuaScXBcL6SB5cbypmx9Ra79JrokdYdpxoXc6jBi",
            "symbol": "ATK",
            "name": "AuToKen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gYomii/ATK-Crypto/main/ATK5.png",
            "tags": [
                "Firm-Token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Autos-bot.fr",
                "twitter": "https://twitter.com/SocialMedia_car",
                "website": "https://www.autos-bot.fr"
            }
        },
        {
            "chainId": 101,
            "address": "HrP2nSa1EH5q6SfzRTpuWrGC6QTfQMgboKponkxB7mzH",
            "symbol": "WLMSD",
            "name": "WLMSD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PnW1/WLMSD/main/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ChGzR85wmFFKSpy1NQ9uEgMkmxM9mV5oj4ZX4eWKGHUL",
            "symbol": "PPWL",
            "name": "Polepenguins WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/polepeng.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EpQCQsK6mLg64oVN2VRBvYV5DbzX5wTpVc8fCWS8bVJ2",
            "symbol": "FOS",
            "name": "Founders of Solana",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EpQCQsK6mLg64oVN2VRBvYV5DbzX5wTpVc8fCWS8bVJ2/fos_token_logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FoundersOfSol"
            }
        },
        {
            "chainId": 101,
            "address": "ZyTQ5Fx2c6ArvMivaD8Y4pBrTF7PQsDDfgU5vDgpV6R",
            "symbol": "MILO",
            "name": "Milosound",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crypto-king99/crypto/main/MILOSOUND.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WL3qRqfiBegtSZs5da6yVNkyh7AmZa5aWhZir3xBDAz",
            "symbol": "TMDC-X-WL",
            "name": "TMDCXWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/namu42/ImageRepo/main/WLwKFNCKESMfbYU1o7hzp868AtyFHGpbC9i4jUTHpQb/wl_token_logo.png",
            "tags": [
                "Whitelist-Token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "WLMpGG6n4iaCQY9gEw3eFqTg2XY4JvkRye6KaFQiz6q",
            "symbol": "TMDC-X-WL",
            "name": "TMDC_ WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/namu42/ImageRepo/main/WLwKFNCKESMfbYU1o7hzp868AtyFHGpbC9i4jUTHpQb/wl_token_logo.png",
            "tags": [
                "Whitelist-Token",
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "ToyurSx9LsMTKNoNMZx4EExXgW8DWc6gjw3z2RKfkz7",
            "symbol": "LKC",
            "name": "LKC Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToyurSx9LsMTKNoNMZx4EExXgW8DWc6gjw3z2RKfkz7/logo.png",
            "tags": [
                "lkc-whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HzJuAKFRozvgxKnFvLqtNuc88w8UmB42SQd7vAibVVU",
            "symbol": "ZQWWL",
            "name": "Zero Quantum Warriors WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HzJuAKFRozvgxKnFvLqtNuc88w8UmB42SQd7vAibVVU/zqwlogo.png",
            "extensions": {
                "discord": "https://discord.gg/QnS27AGyeQ",
                "twitter": "https://twitter.com/ZQWarriors",
                "website": "https://zqwarriors.io"
            }
        },
        {
            "chainId": 101,
            "address": "ZMpvsJ51GyYMmD9r3GEW9pCeAYF8TRbmqa9KgBE2aHr",
            "symbol": "MUSA",
            "name": "MUSA MALI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZMpvsJ51GyYMmD9r3GEW9pCeAYF8TRbmqa9KgBE2aHr/logomusa.png",
            "tags": [
                "utility-token",
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ExN3MaZLB5VhjGZ75qUuJ8ENkNiUvCCdA7j85vCcNicQ",
            "symbol": "MODS",
            "name": "MetaOps Genesis",
            "decimals": 9,
            "logoURI": "https://github.com/numpynewb33/token-list/blob/main/assets/mainnet/ExN3MaZLB5VhjGZ75qUuJ8ENkNiUvCCdA7j85vCcNicQ/logo.png?raw=true",
            "tags": [
                "utility-token",
                "reward-token",
                "social-token"
            ],
            "extensions": {
                "description": "This is a reward token that can be claimed from staking MetaOps Genesis NFTs",
                "discord": "https://discord.com/invite/metaops",
                "twitter": "https://twitter.com/metaopsgaming",
                "website": "https://metaopsgaming.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GzTxngZwZ2ZcDydepix1cTMRcooDxfK32Px5ZZkcjtX6",
            "symbol": "DRICH",
            "name": " Donkey Richard Coin",
            "decimals": 9,
            "logoURI": "https://www.dropbox.com/s/cssc839nr1sjedt/Donkey%20Richards%20Logo.jpg?dl=0",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Currency for Use in Donkey Richard Universe",
                "discord": "https://discord.gg/JXQYJfThm6",
                "twitter": "https://twitter.com/TheDnkyRichards",
                "website": "https://www.donkeyrichard.com/"
            }
        },
        {
            "chainId": 101,
            "address": "33EGqaNcakXZNefyZj3WgeP47kMFFLBQPwBBPToywC3W",
            "symbol": "ATLO",
            "name": "ATTOLLO COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jbecks95/crypto/main/LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ccgr6gWvT5U4F38ND2D3EnRPtdp4L9AHpVY3apg35QgS",
            "symbol": "ATLOG",
            "name": "ATLARIA OG TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/loglaunch.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "website": "https://atlaria.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EGFTKxNyoHKa16dsXMvMkoz2VfebkTxPjNBheNbpKRbT",
            "symbol": "ATLWL",
            "name": "ATLARIA WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/loglaunch.png",
            "tags": [
                "mint-token"
            ],
            "extensions": {
                "website": "https://atlaria.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9RbTen9wL7hTZRKZUMBPNpGGKsjRAM8LHokphuoCWK5w",
            "symbol": "BOSSWL",
            "name": "BOSS WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ttarabay/token-list/main/assets/mainnet/9RbTen9wL7hTZRKZUMBPNpGGKsjRAM8LHokphuoCWK5w/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TomonpwmzyveJRXKCTbJxpyDuYLPgpugU3pCK5aceWi",
            "symbol": "$SLURP",
            "name": "SLURP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TomonpwmzyveJRXKCTbJxpyDuYLPgpugU3pCK5aceWi/logo.png"
        },
        {
            "chainId": 101,
            "address": "PToAqfWfY79uFyXaWafC1MNpSnrX3BXAgSJvMgbU1U7",
            "symbol": "BETYAR",
            "name": "Betyár Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PToAqfWfY79uFyXaWafC1MNpSnrX3BXAgSJvMgbU1U7/logo.png",
            "extensions": {
                "description": "BetyarDAO",
                "discord": "https://discord.io/betyardao",
                "instagram": "https://www.instagram.com/betyardao",
                "twitter": "https://twitter.com/betyardao",
                "website": "https://betyardao.hu"
            }
        },
        {
            "chainId": 103,
            "address": "PToAqfWfY79uFyXaWafC1MNpSnrX3BXAgSJvMgbU1U7",
            "symbol": "BETYAR",
            "name": "Betyár Dev Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PToAqfWfY79uFyXaWafC1MNpSnrX3BXAgSJvMgbU1U7/logo.png",
            "extensions": {
                "description": "BetyarDAO",
                "discord": "https://discord.io/betyardao",
                "instagram": "https://www.instagram.com/betyardao",
                "twitter": "https://twitter.com/betyardao",
                "website": "https://betyardao.hu"
            }
        },
        {
            "chainId": 101,
            "address": "Rog3V7CzPXkRd1qotc2JwPuW1ijkZogircFNkGdY5Ej",
            "symbol": "$ROFO",
            "name": "ROFO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Rog3V7CzPXkRd1qotc2JwPuW1ijkZogircFNkGdY5Ej/logo.png",
            "tags": [
                "utility-token",
                "work-to-earn"
            ],
            "extensions": {
                "twitter": "https://twitter.com/robbersfoxes"
            }
        },
        {
            "chainId": 101,
            "address": "Gxn3A9LYAbjx4XqmB5QX6niAJVQVEx2bBaNfWD6jnjXP",
            "symbol": "TT",
            "name": "Tata",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gxn3A9LYAbjx4XqmB5QX6niAJVQVEx2bBaNfWD6jnjXP/logo.png",
            "tags": [
                "utility-token",
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/CXV7CRbr",
                "twitter": "https://twitter.com/Tata_1000Faces",
                "website": "https://www.tata1000faces.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5K6Fj5dxcmCTeGCBHAEh6JRtKdPkoQx4aCRYYtyetbZh",
            "symbol": "BPUPS",
            "name": "Battle Pups WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5K6Fj5dxcmCTeGCBHAEh6JRtKdPkoQx4aCRYYtyetbZh/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H4p41zzwg2gSzSEhhXwvrnGznyHetW9Fgj7UYyjVm7Qd",
            "symbol": "BASH",
            "name": "BASH Rewards",
            "decimals": 2,
            "logoURI": "https://solaboy.github.io/images/bash.jpeg",
            "tags": [
                "meta-verse",
                "utility-token"
            ],
            "extensions": {
                "description": "BASH reward token that can be earned from an interactive game with limitless ideas then redeemed.",
                "discord": "http://discord.gg/NuhBakrakN",
                "instagram": "https://www.instagram.com/coolcucumberbash",
                "twitter": "https://twitter.com/CCBNFT",
                "website": "https://www.coolcucumberbash.io"
            }
        },
        {
            "chainId": 101,
            "address": "EbDzfYUoWs5X9MKQ54Yued9oNbJGwQERgCJpKYBuHiqs",
            "symbol": "CUC",
            "name": "CUCOIN",
            "decimals": 2,
            "logoURI": "https://solaboy.github.io/images/cucoin.jpeg",
            "tags": [
                "meta-verse",
                "utility-token"
            ],
            "extensions": {
                "description": "Cucoin(CUC)  would be used in making in game purchases such as battle passes, power ups, costume, skin/avatar customization.",
                "discord": "http://discord.gg/NuhBakrakN",
                "instagram": "https://www.instagram.com/coolcucumberbash",
                "twitter": "https://twitter.com/CCBNFT",
                "website": "https://www.coolcucumberbash.io"
            }
        },
        {
            "chainId": 101,
            "address": "CrhMZTJdZMNLvZoNdjZT1SFToT34NYLUf2W2VaMLKQqf",
            "symbol": "SAY",
            "name": "Say Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SayNetworkOfficial/say-network-token/master/logo.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Decentralized social media token powering uSay."
            }
        },
        {
            "chainId": 101,
            "address": "8cqc6k5nwoBYJVqGk8dvxyVFHaaGvmUNP5ydpbmASeCZ",
            "symbol": "FANCY",
            "name": "Fancy Potatoes",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/DaPrince2106/Fancy-PNG/main/0023.png",
            "tags": [
                "NFT",
                "Art",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FANCY_POTATOES",
                "website": "https://fancypotatoes.art"
            }
        },
        {
            "chainId": 101,
            "address": "CJ9G8mxRu5jwmtjnCLt4KGCJwKM47GNCTknMUbZXQvN3",
            "symbol": "MRB",
            "name": "Mr Breakfast",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJ9G8mxRu5jwmtjnCLt4KGCJwKM47GNCTknMUbZXQvN3/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/mrbreakfastja",
                "twitter": "https://twitter.com/mrbreakfastja",
                "website": "https://www.mrbreakfastja.com/"
            }
        },
        {
            "chainId": 101,
            "address": "UruquUouaaJ7oo4hUmJ41bk2SXYpQ4PwR9Jnbsk7kHN",
            "symbol": "WRCK",
            "name": "White Rock",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UruquUouaaJ7oo4hUmJ41bk2SXYpQ4PwR9Jnbsk7kHN/logo.jpg",
            "tags": [
                "tokenomics",
                "social-network",
                "new-world-order",
                "community-token"
            ],
            "extensions": {
                "website": "https://thetribes.io"
            }
        },
        {
            "chainId": 101,
            "address": "8zyDKf16k8o3JpJ8A14tZVQdaSvN1mkMVuPSy6Cgjv5C",
            "symbol": "BRCK",
            "name": "Black Rock",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8zyDKf16k8o3JpJ8A14tZVQdaSvN1mkMVuPSy6Cgjv5C/logo.jpg",
            "tags": [
                "tokenomics",
                "social-network",
                "new-world-order",
                "community-token"
            ],
            "extensions": {
                "website": "https://thetribes.io"
            }
        },
        {
            "chainId": 101,
            "address": "7G5sVZBP9322r3DWcTw9pDWz31BicMSP9thKrD55uWp8",
            "symbol": "CADPT",
            "name": "Canadian Dollar Partoken",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7G5sVZBP9322r3DWcTw9pDWz31BicMSP9thKrD55uWp8/logo.png",
            "tags": [
                "stablecoin",
                "utility-token"
            ],
            "extensions": {
                "description": "Canadian Dollar Partoken (CADPT) is a one-for-one Canadian Dollar collateralized stablecoin token.",
                "website": "https://partokens.com"
            }
        },
        {
            "chainId": 101,
            "address": "Az887ysr8zW72pVB2QgyuvomWsrNAaWA9EpbFAmSBAkD",
            "symbol": "COUCH",
            "name": "The Couch Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/couchcoin9990/couch/main/logo.png",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "4ewdr269QKV9SC1N855LUhXAe3YevASjQHoR7bzg9JKW",
            "symbol": "XDRIP",
            "name": "0xDRIP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ewdr269QKV9SC1N855LUhXAe3YevASjQHoR7bzg9JKW/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/0xDRIP"
            }
        },
        {
            "chainId": 101,
            "address": "6z9oBZ84zSx2uPvPofyaAABmBaWUk1BmDkMQryiYorzk",
            "symbol": "PURP",
            "name": "purple squirrel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PurpleSquirrelMedia/SQRL/main/purp-metaverse-utility-token.png",
            "tags": [
                "meta-verse",
                "utility-token"
            ],
            "extensions": {
                "description": "WEB3 based meta verse web utility token based on SOLANA Blockchain",
                "discord": "http://discord.gg/2AKuWnyr",
                "instagram": "https://www.instagram.com/purplesquirrelexchange",
                "twitter": "https://twitter.com/squirrel_eth",
                "website": "https://www.purplesquirrelmedia.io"
            }
        },
        {
            "chainId": 101,
            "address": "HRHdJZcy5BKeY2Twu2k8oJk7uEyDAqtQJ28cXF4V3Bma",
            "symbol": "BLNK",
            "name": "Blank Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/petlikarayan/BLNK-Coin/main/BLNK.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3tW1UvWctG6qsjcpSBKVwKRy1zAwxtEVcZU6icnXXsvp",
            "symbol": "LEFT",
            "name": "Left Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PowerRob/left-token/main/Screenshot%202022-01-17%20132316.png"
        },
        {
            "chainId": 101,
            "address": "HC6Um2wBfVWZYUov6QbwkYV2oNr8VT2RsgufMptPqaCC",
            "symbol": "RGHT",
            "name": "Right Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PowerRob/right-token/main/Screenshot_2022-01-19_142451-removebg-preview.png"
        },
        {
            "chainId": 101,
            "address": "CW2sMRF3JJ7q8rqamJz3iZcdPRNiv3RYKDQ4LfKTkUm7",
            "symbol": "GRT",
            "name": "Greenies Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/drsaint1/drsaint/main/logo512.png",
            "tags": [
                "Meme-Token",
                "NFT",
                "Utility-Token",
                "Social-Token",
                "Social-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/4V9b5qCMDK",
                "twitter": "https://twitter.com/greenie_planet"
            }
        },
        {
            "chainId": 101,
            "address": "5tMhmodggwwcdcQQUZXJabXbU5NAbpWqMXkUjeFs9X72",
            "symbol": "SDWL",
            "name": "SolarDex WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5tMhmodggwwcdcQQUZXJabXbU5NAbpWqMXkUjeFs9X72/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "jBunk7zcKaVuWJtFJcYkStA3aqmEBu4R2jXRHNa9kbh",
            "symbol": "SSSH",
            "name": "SSS Holders SD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/jBunk7zcKaVuWJtFJcYkStA3aqmEBu4R2jXRHNa9kbh/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8vZXUmzowpYJCzVbGXBGqjP8bsQ7VJBJajniHyWuHht3",
            "symbol": "CWGC",
            "name": "CowardCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Swahgner/cowardcoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HK4RnWAiaLJt3kqc3a3B5CdjnKGzBz8ykaemGcyQ9E9S",
            "symbol": "WING",
            "name": "Wings",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MonarchButterflySquad/NFTMonarchsGeneral/main/HK4RnWAiaLJt3kqc3a3B5CdjnKGzBz8ykaemGcyQ9E9S/goldwings.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "NFT Monarch WL token + extra.",
                "discord": "https://discord.gg/nftmonarchs",
                "twitter": "https://twitter.com/NFTMonarchs",
                "website": "https://www.nftmonarchs.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ao8BZG21WAVuPCjSuh5SKM5F8yEMnyrvhJiCMXiGEggD",
            "symbol": "DripChips",
            "name": "Drip Chips",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/BHALEYART/scum-experimental/main/drip-coin-logo.png",
            "tags": [
                "scumbags-nft",
                "utility-token"
            ],
            "extensions": {
                "description": "Drip is a flexible digital currecy created for the Scumbag$ NFT community. For use with Drip partners and related utilities.",
                "discord": "http://discord.gg/scumbags",
                "twitter": "https://twitter.com/scumbags_nft",
                "website": "https://scumbags.io"
            }
        },
        {
            "chainId": 103,
            "address": "3ixUSBBzwKeoaJMcABxhRBaUadVR6SZyzNv2gRcDjpap",
            "symbol": "RBO",
            "name": "Robo Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/robo360/token-list/122f24c7909c6275cc677fbe0ff027247bcbfa2f/assets/mainnet/3ixUSBBzwKeoaJMcABxhRBaUadVR6SZyzNv2gRcDjpap/Robo.png",
            "extensions": {
                "twitter": "https://twitter.com/robo3601"
            }
        },
        {
            "chainId": 101,
            "address": "LUVumGBdVkaPYbGyjjRJtsbYoVtZ1h7AaX1Hh2bcaqn",
            "symbol": "$LUV",
            "name": "LUV",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUVumGBdVkaPYbGyjjRJtsbYoVtZ1h7AaX1Hh2bcaqn/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/loveonsolana"
            }
        },
        {
            "chainId": 101,
            "address": "2PcxBC6AZXPTadHWwBdxcnkrdJVmoGZ2raMLstDQcpJk",
            "symbol": "$PARTS",
            "name": "Space Parts",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2PcxBC6AZXPTadHWwBdxcnkrdJVmoGZ2raMLstDQcpJk/logo.png",
            "tags": [
                "Utility-Token",
                "Social-Token"
            ],
            "extensions": {
                "website": "https://www.solspacemen.com"
            }
        },
        {
            "chainId": 101,
            "address": "USDtoXB6AGW87Wj8p3HNdnVPAgXBpwjTXtVnKgiPR16",
            "symbol": "USD_X",
            "name": "USD for Duck Exchange",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/duckdex/token-list/main/assets/mainnet/USDtoXB6AGW87Wj8p3HNdnVPAgXBpwjTXtVnKgiPR16/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/2ySvY6Vrv7",
                "twitter": "https://twitter.com/duck_dex",
                "website": "https://duckdex.github.io"
            }
        },
        {
            "chainId": 101,
            "address": "6WLdnp1JhBbk2xQ2PqRnade1dZTj1GbGfo8eAz4Skbko",
            "symbol": "FXZ",
            "name": "Foxeez",
            "decimals": 0,
            "logoURI": "https://github.com/foxeez/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AU4bT5vsa8Dqsd9BXEzsW5QSeDQ97QwKx8oEum8GMKpG",
            "symbol": "SAI",
            "name": "SAI Token",
            "decimals": 9,
            "logoURI": "https://github.com/peakypandas/icon/blob/main/SAI.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3rFgy3p5u6NFRbEzDqt1NyHp1g3wX8WiWkEWZkmtxdFm",
            "symbol": "CHOMPS",
            "name": "Shkary Sharks CHOMPS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3rFgy3p5u6NFRbEzDqt1NyHp1g3wX8WiWkEWZkmtxdFm/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "website": "https://shkarysharks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "yP3PcccLpc22nSrJRbRLySuroYpKiVT2ENRfiQGNNAB",
            "symbol": "y-PAI-3Pool",
            "name": "Yield Mercurial LP Token (PAI-3Pool)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yP3PcccLpc22nSrJRbRLySuroYpKiVT2ENRfiQGNNAB/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4AsEadPPuD1183oD6aqBsqrY2afGzZWtxqF1GkAjpQeE",
            "symbol": "LAGERCOIN",
            "name": "Lager Coin",
            "decimals": 0,
            "logoURI": "https://github.com/Paul-Sizon/BeerCoinLogo/blob/main/logo2.png?raw=true",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6x5GqC86iwRGbJAggfSjHuQ6an3bVLyAARvrLEdayuif",
            "symbol": "ARC",
            "name": "Air-Care",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6x5GqC86iwRGbJAggfSjHuQ6an3bVLyAARvrLEdayuif/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/CatVNbgakx",
                "twitter": "https://twitter.com/air_care1"
            }
        },
        {
            "chainId": 101,
            "address": "5U39RWhvpQqkASZBN2wqkii3JBRGwha9uC7xQJodcpFa",
            "symbol": "DCoin",
            "name": "DadoCoin",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/DadoKingCur/crypto/main/pink-blue-d-alphabet-letter-logo-for-business-and-company-with-gradient-design-pastel-colour-for-corporate-identity-vector%20(2).jpg",
            "extensions": {
                "instagram": "https://www.instagram.com/dossais__/",
                "twitter": "https://twitter.com/DadoKingCura"
            }
        },
        {
            "chainId": 101,
            "address": "FvtLzn4xKhpk4Gwhq3SkwvPYdzCXCJ4DoggczmjYcuiG",
            "symbol": "Diamond",
            "name": "Black Diamond",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FvtLzn4xKhpk4Gwhq3SkwvPYdzCXCJ4DoggczmjYcuiG/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/BlackDiamon_sol"
            }
        },
        {
            "chainId": 101,
            "address": "22tWadU1wwH6UNnn14ZdHX7weuWDccP53CWYXdsYq2dE",
            "symbol": "LPG",
            "name": "Little Piggy Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/22tWadU1wwH6UNnn14ZdHX7weuWDccP53CWYXdsYq2dE/logo.png",
            "tags": [
                "NFT",
                "DAO",
                "GameFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/TaWMQbuZ",
                "instagram": "https://www.instagram.com/littlepiggynftclub/",
                "twitter": "https://twitter.com/NFT_LittlePiggy"
            }
        },
        {
            "chainId": 101,
            "address": "F8pZGcGBiN5nd15RKybZmYLszwdejVvVzSMzXSMqvWJj",
            "symbol": "SALSA",
            "name": "Salsa",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/salsalogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/worldoftacos_",
                "website": "https://worldoftaco.com.mx"
            }
        },
        {
            "chainId": 103,
            "address": "DFF6xxSfKnggsdL4MACp2iLJaMtTTDA9gQkDXrAxGqeJ",
            "symbol": "KTST",
            "name": "TEST TOKEN FOR KÖRA",
            "decimals": 8,
            "logoURI": "https://gitcdn.link/cdn/nramamur/koracoin/main/Original.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "KÖRA (KTST) is a utility coin that powers KöRa, a decentralized shipment delivery network connecting shippers with drivers",
                "twitter": "https://twitter.com/koraconnect",
                "website": "https://koraconnect.com"
            }
        },
        {
            "chainId": 101,
            "address": "Gj5CxKTz5m6vMaaq7ksgAPLbZFGTC97EpNinLggB2yax",
            "symbol": "GojiraWhitelist",
            "name": "Gojira WLtoken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gj5CxKTz5m6vMaaq7ksgAPLbZFGTC97EpNinLggB2yax/logo.png"
        },
        {
            "chainId": 101,
            "address": "EgDca7anWBPAqZSeNGMKD1wEzDCGuQsCrqPSheDwJkr2",
            "symbol": "BP",
            "name": "BearPad Token",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/BearPadIO/image/logo.png",
            "tags": [
                "utility-token",
                "defi-token"
            ],
            "extensions": {
                "discord": "https://discord.com/2FzkpNDn5f",
                "telegram": "https://t.me/bearpad_community",
                "twitter": "https://twitter.com/Bear_Pad_Crypto",
                "website": "https://bearpad.io"
            }
        },
        {
            "chainId": 101,
            "address": "EG9JDbEiCFpQ8a9LCi8AXskKvqZy757n3yFCnBvLrZRd",
            "symbol": "OTAKU",
            "name": "OTAKU Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EG9JDbEiCFpQ8a9LCi8AXskKvqZy757n3yFCnBvLrZRd/logo.png"
        },
        {
            "chainId": 101,
            "address": "GvBcdeaThoyzFBzmkv91kz4Z5GMD1PvaQy22JGXeYfeH",
            "symbol": "BOXT",
            "name": "BOXT",
            "decimals": 6,
            "logoURI": "https://testgpex.s3.ap-northeast-1.amazonaws.com/test/coin/2022/04/25/87f9d1a728a84bc1aadc7dac3ed165cd.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/BfvrvSMuVR",
                "twitter": "https://twitter.com/box_trad",
                "website": "https://www.boxtradex.io"
            }
        },
        {
            "chainId": 101,
            "address": "GQnKiouoweU9rv9VMLQbrJwbjL7FLFjXAKp7Pj668iy3",
            "symbol": "AGORA",
            "name": "AGORA",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GQnKiouoweU9rv9VMLQbrJwbjL7FLFjXAKp7Pj668iy3/logo.png",
            "tags": [
                "utility-token",
                "stake-pool-token"
            ],
            "extensions": {
                "description": "The $AGORA token is the shopping currency of the Agora, our Metaverse. It will be used to purchase items inside our Metashops : whether it is for NFTs or material items is up to you. Our goal is to create a currency that bridges blockchain related utility and real life utility. You could potentially plan your next road trip with $AGORA or even purchase your engagement ring with it. The more businesses that partner with us the more $AGORA will have tangible utility.",
                "twitter": "https://twitter.com/lofts_club",
                "website": "https://agoraverse.org/"
            }
        },
        {
            "chainId": 101,
            "address": "7VEyj9ooKPLaxd4rxwRWB4J5Yo1upymWwNs7RL78i8Nj",
            "symbol": "D3DNETWORK",
            "name": "D3D Network",
            "decimals": 0,
            "logoURI": "https://bafkreidhiegzjjqnikoevl4fbuuocdrnvmxyy2bnnoeoha6kwykhgscuwm.ipfs.dweb.link/?ext=png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/saDbn3BFU9",
                "twitter": "https://twitter.com/DeadRejcts",
                "website": "https://deadrejects.com/"
            }
        },
        {
            "chainId": 103,
            "address": "FwM1JzPYupzk6HEJnAqAnL5AFqJCSpkJtgyREfc6aCQN",
            "symbol": "BGTC",
            "name": "Baby Groot Test Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FwM1JzPYupzk6HEJnAqAnL5AFqJCSpkJtgyREfc6aCQN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/azvrma"
            }
        },
        {
            "chainId": 101,
            "address": "FdSBbLHK8hfc6BSqjrhQZaGj7jgd5vfPcchDB2RDAQFA",
            "symbol": "DMNDS",
            "name": "DH Diamonds",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdSBbLHK8hfc6BSqjrhQZaGj7jgd5vfPcchDB2RDAQFA/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://www.cprodiamond.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9t9KEtXY6rxyctXE99mkuk3XbZF8zuEvVoCMWnP4KWU2",
            "symbol": "DHHDY",
            "name": "DH Hoodie",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9t9KEtXY6rxyctXE99mkuk3XbZF8zuEvVoCMWnP4KWU2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.cprodiamond.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8JQBQ2uCLxLP2pskrVpAjuHGQNVbWp3Xaqu3Whr5Ssy",
            "symbol": "DHTSRT",
            "name": "DH Tshirt",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8JQBQ2uCLxLP2pskrVpAjuHGQNVbWp3Xaqu3Whr5Ssy/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.cprodiamond.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Etr45gAt9WKsuWiiHa3vhTNnDSMG8ihKS35RbPoWGww2",
            "symbol": "HSL",
            "name": "HSL Lotto",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Etr45gAt9WKsuWiiHa3vhTNnDSMG8ihKS35RbPoWGww2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Vaf54vCMYg",
                "twitter": "https://twitter.com/HeavensLotto"
            }
        },
        {
            "chainId": 101,
            "address": "ZWL17C2DMPXTAmjHL8nNkg2RFMzMRGXdA28pas8s67a",
            "symbol": "ZWL1T",
            "name": "Zombiees Whitelist 1 Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZWL17C2DMPXTAmjHL8nNkg2RFMzMRGXdA28pas8s67a/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "ZWL2AknBaix1oZMV1QZGDmMWthMkJc44Rk51Z6L2XYc",
            "symbol": "ZWL2T",
            "name": "Zombiees Whitelist 2 Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZWL2AknBaix1oZMV1QZGDmMWthMkJc44Rk51Z6L2XYc/logo.PNG",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "DS9Fzb4QC53aHAgsS4UMG5ZL1ejdU5x4wbAdJ16AY4n7",
            "symbol": "KAD",
            "name": "Kad Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kadcoin/kadcoin/main/kadcoin-01.png",
            "tags": [
                "community-token",
                "NFT"
            ],
            "extensions": {
                "website": "https://www.kadlab.se"
            }
        },
        {
            "chainId": 101,
            "address": "3sNZ2c2uFdzAt15zSw6EzANC9NN4qC3SsrdyTW7tSn7w",
            "symbol": "UAA",
            "name": "Urijah Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/UrijahAl3xander/UrijahCoin/main/UrijahCoinLogo.png",
            "tags": [
                "social-token",
                "utility-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EJfDncsAwuUGKhX5zTpZuUvH2AURhRdReJcWJmueYuNU",
            "symbol": "BCBY",
            "name": "BCBY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EJfDncsAwuUGKhX5zTpZuUvH2AURhRdReJcWJmueYuNU/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ABT3crsLdhx7KdstReh9sGXhZgDkssNXzAZzqNYEfFZ",
            "symbol": "CHUMS",
            "name": "CHUMS",
            "decimals": 6,
            "logoURI": "https://sun9-88.userapi.com/s/v1/if2/KPH5miDn6Uf5zcFpWjmVZwPwJcSZfipH-9ra3x8yJ-OCQ0bwyjKCNgWP3XQvTniNoLIeZpMqpG49AbFYc5Bmc1qB.jpg?size=2560x1919\u0026quality=96\u0026type=album",
            "tags": [
                "GENERAL",
                "FARMING"
            ]
        },
        {
            "chainId": 101,
            "address": "G7S1cJ1TYyHdvBZWCr9LEAQu2HD2mE9D4qxyxtiWTo2q",
            "symbol": "NCL",
            "name": "No Child Labor",
            "decimals": 0,
            "logoURI": "https://github.com/DanaJameel/crypto/blob/main/LogoCoin.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5FozDe1UB9cJZmWKxGg6nBSBSu2A51DXowshCtYeKWEp",
            "symbol": "SKULL",
            "name": "SKULL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5FozDe1UB9cJZmWKxGg6nBSBSu2A51DXowshCtYeKWEp/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ],
            "extensions": {
                "description": "SKULL WL Token "
            }
        },
        {
            "chainId": 101,
            "address": "ZENJfNsHnRSnzMnwsPapKSczkSq4TgHM8548cVyUSS8",
            "symbol": "ZENJW",
            "name": "Zenjin Viperz Early WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZENJfNsHnRSnzMnwsPapKSczkSq4TgHM8548cVyUSS8/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZenjinViperz",
                "website": "https://www.zenjinviperz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EvQPSR1TwrCJwapoXgLtCiEaiRdbBefkNaqPcUPKzJV3",
            "symbol": "PSAGE",
            "name": "Passage Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/njericooper/Passage/main/Official_Passage_Token_Logo_x100.png",
            "tags": [
                "nft-token",
                "community-token",
                "transactional-token"
            ],
            "extensions": {
                "description": "Passage Token is the official currency for The Soverign Nation of New Lemuria",
                "website": "https://passage.trinitymedianetwork.org/"
            }
        },
        {
            "chainId": 101,
            "address": "C3n8hVc6fkTW9rpNaYv3zQT2vUen7Ujzy93F9BWd8F7R",
            "symbol": "FACES",
            "name": "Caras",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C3n8hVc6fkTW9rpNaYv3zQT2vUen7Ujzy93F9BWd8F7R/logo.png",
            "tags": [
                "currency"
            ],
            "extensions": {
                "facebook": "https://facebook.com/jooselito12"
            }
        },
        {
            "chainId": 101,
            "address": "D4KVLfryvPdjPsWRKDWGz4YpqpqZHkrjk1JzUMeBG8JJ",
            "symbol": "APUNK",
            "name": "AsciiPunk Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D4KVLfryvPdjPsWRKDWGz4YpqpqZHkrjk1JzUMeBG8JJ/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ascii_punk"
            }
        },
        {
            "chainId": 101,
            "address": "GEMSkjHriJp95iSZpRgb5ym3RQeaMFig7cfWvnWTT4An",
            "symbol": "GEMS",
            "name": "Good Earth Mood Stones",
            "decimals": 8,
            "logoURI": "https://media.kaijucards.io/misc/gem.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/fVmPr5uefk",
                "twitter": "https://twitter.com/kaijucards",
                "website": "https://kaijucards.io"
            }
        },
        {
            "chainId": 103,
            "address": "4LZBmYxFxxh4DrEbaRTY9tJ5JzsfRPSGXhDswSDR9wSX",
            "symbol": "CFT",
            "name": "CFT Test Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LZBmYxFxxh4DrEbaRTY9tJ5JzsfRPSGXhDswSDR9wSX/logo.png"
        },
        {
            "chainId": 101,
            "address": "AcZLBqhVjqnd4aoyRQ4fQpsBQnPtwNAFFHEmsedctmUR",
            "symbol": "DPAY",
            "name": "Daupay",
            "decimals": 9,
            "logoURI": "https://daupay.com/logo.png",
            "tags": [
                "finance",
                "trading",
                "swap"
            ],
            "extensions": {
                "website": "https://daupay.com"
            }
        },
        {
            "chainId": 101,
            "address": "A3cTUqeYDWiYrapPi9ooanhkn8Cmj82bXxqWxezJW6sf",
            "symbol": "TIGER",
            "name": "TIGER City Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3cTUqeYDWiYrapPi9ooanhkn8Cmj82bXxqWxezJW6sf/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4prmmHLvg9NkZGx2CMg2w9iKCcYiFdSNgK9LSgN4bbCp",
            "symbol": "DARKELVCF",
            "name": "DARKELV Crystal Freak",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4prmmHLvg9NkZGx2CMg2w9iKCcYiFdSNgK9LSgN4bbCp/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AMNrJcZb4efKBjCg9djRmYacLgGRT7fR8LA48evGeLK7",
            "symbol": "DANK",
            "name": "DANK Token",
            "decimals": 6,
            "logoURI": "https://w7.pngwing.com/pngs/541/141/png-transparent-gold-coin-gold-coin-free-to-pull-the-year-of-the-goat-commemorative-coins-free-logo-design-template-animals-gold-coin.png",
            "tags": [
                "DANK",
                "StonedFarms"
            ],
            "extensions": {
                "description": "StonedFarms Dank Token "
            }
        },
        {
            "chainId": 101,
            "address": "HREpY3ciGUwXkjTcsJfEVyWtsvgLLNSoz9KBUVHLGkkK",
            "symbol": "CRETE",
            "name": "CRETERNITY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/creternity/crypto/main/creternity_35.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2g8238SLAkUSoRVATfKT5sV3121J9LUaCvCuez7iyNDW",
            "symbol": "CFSC",
            "name": "CloudFit Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/brandoncamerer/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9z8UpvjyH17UC8FoWFvmkke4hCfMjpFyVExZy61WHH2L",
            "symbol": "LSHARE",
            "name": "LSHARE TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dev8x/token-list/main/assets/mainnet/9z8UpvjyH17UC8FoWFvmkke4hCfMjpFyVExZy61WHH2L/lshare.png",
            "extensions": {
                "medium": "https://lanafinance.medium.com/",
                "twitter": "https://twitter.com/lanaprotocol",
                "website": "https://lanafi.app/"
            }
        },
        {
            "chainId": 101,
            "address": "6rYHThD7ZQAWJmseRzvFro98Pg11K4hYmsHEn9nuM69",
            "symbol": "LSHARE-SOL",
            "name": "LSHARE SOL LP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dev8x/token-list/main/assets/mainnet/6rYHThD7ZQAWJmseRzvFro98Pg11K4hYmsHEn9nuM69/lshare-sol.png",
            "extensions": {
                "medium": "https://lanafinance.medium.com/",
                "twitter": "https://twitter.com/lanaprotocol",
                "website": "https://lanafi.app"
            }
        },
        {
            "chainId": 101,
            "address": "J5VXnHqrr36DxXm3SsXQavfUKZ5DsE9dZxnKeZtUUMkq",
            "symbol": "ANM",
            "name": "ANIMETA TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GaitanaruAndrei/assets/main/bun2_ccexpress.png",
            "tags": [
                "meta-verse",
                "utility-token"
            ],
            "extensions": {
                "description": "ANIMETA(ANM) token that can be earned from an interactive game with limitless ideas then redeemed.",
                "website": "https://www.ani-meta.io"
            }
        },
        {
            "chainId": 101,
            "address": "5XsX5cTfeD5K7nTpJUqNKn8ijAfgtb3beZVSu6jmtXhp",
            "symbol": "BC",
            "name": "Bne Coin",
            "decimals": 0,
            "logoURI": "https://github.dev/bne88/token-list/blob/b8df5ce7990728dd525055df9a8a3c18ddec0810/assets/mainnet/5XsX5cTfeD5K7nTpJUqNKn8ijAfgtb3beZVSu6jmtXhp/crypto.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "APGCvZeM2ZVTjeMftVuVwPAKXfmZRj4AYkjURvwgGvpN",
            "symbol": "JELLYwl2",
            "name": "Jelly Phase 2 WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APGCvZeM2ZVTjeMftVuVwPAKXfmZRj4AYkjURvwgGvpN/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wzEfUjmpFw",
                "twitter": "https://twitter.com/JellyBabiesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "3PGgQnN1icXNscLCjwF6zdyeCrDP4vmBg6LBK5MLdAC4",
            "symbol": "JELLYwl1",
            "name": "Jelly Babies Phase 1 WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PGgQnN1icXNscLCjwF6zdyeCrDP4vmBg6LBK5MLdAC4/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wzEfUjmpFw",
                "twitter": "https://twitter.com/JellyBabiesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "6e5HkEuXdUQrVYxbbAQiNfBQhre79jyrkBqenmPgdqcG",
            "symbol": "JELLYwl3",
            "name": "Jelly Babies Phase 3 WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6e5HkEuXdUQrVYxbbAQiNfBQhre79jyrkBqenmPgdqcG/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wzEfUjmpFw",
                "twitter": "https://twitter.com/JellyBabiesNFT"
            }
        },
        {
            "chainId": 103,
            "address": "CarbonRRCJA2wMpHY2P4iP9KS4DScun5sdbv3PvNa4aZ",
            "symbol": "gcoin",
            "name": "Green Coin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@master/gcoin/gcoin.svg",
            "tags": [
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "The only way to get carbon negative cryptocurrency is using gcoin: a CO2 accountancy NFT",
                "imageUrl": "https://bafybeibcchigmrbxhijavgamnmpg3qcqj4tccgaux64ctirymqetlzng3q.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://ipfs.safewatch.xyz/ipns/QmTo1AnNH7Snu37Dotphw2fX54u1S5VLFpnnERN7GbyUrW/#gcoin",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmTo1AnNH7Snu37Dotphw2fX54u1S5VLFpnnERN7GbyUrW/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "5RRQKdF4MSicGSgx2HiGf9Fr4SN5m5743S3qpbcEc5fk",
            "symbol": "GNE",
            "name": "Project GNE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/vking45/assets/main/gne.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ympTZR9jY8",
                "instagram": "https://instagram.com/projectgne",
                "twitter": "https://twitter.com/projectgne",
                "website": "https://projectgne.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EpjyMYD46ebnpxDqt8qwPsK2MCaqvuhB82UBnwhShoaN",
            "symbol": "BHMT",
            "name": "BioHumans Mint Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/chillingonsol/logo-/main/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ErdkWPCAJP3cXH56ZeRCPqpHkJFtNyF3gPuEjxkwwgBq",
            "symbol": "PH",
            "name": "Phenal",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rohitrj22/phenal/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2wqKmDPQeyJrrBLsFvkebD7xXbwAMdHqb6o1ZrUKWagW",
            "symbol": "ALPHA-WL",
            "name": "ALPHA WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wqKmDPQeyJrrBLsFvkebD7xXbwAMdHqb6o1ZrUKWagW/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FxyCwJDEHXePAE6Fph6eiwsCvkJw8kV8vnPnSwBFdPPW",
            "symbol": "DARKELVTV",
            "name": "DARKELV Tech Vulture",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FxyCwJDEHXePAE6Fph6eiwsCvkJw8kV8vnPnSwBFdPPW/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "853uKMwykX2SjgD7PqunL934xwGy3NhoiZXQFHeRDDTe",
            "symbol": "DARKELVSF",
            "name": "DARKELV Spiky Freak",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/853uKMwykX2SjgD7PqunL934xwGy3NhoiZXQFHeRDDTe/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8RjzDqcg6zS4yPJg7Bsjc3QLdEc9sphbpWEatCngvxmh",
            "symbol": "DARKELVLD",
            "name": "DARKELV Liquid Demon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RjzDqcg6zS4yPJg7Bsjc3QLdEc9sphbpWEatCngvxmh/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "47Qt8pXRinMME4VCypnEqt6SvPzEbMZLyPJyN5TtdAjH",
            "symbol": "DARKELVHA",
            "name": "DARKELV Half Abstract",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/47Qt8pXRinMME4VCypnEqt6SvPzEbMZLyPJyN5TtdAjH/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CweMB86iVRjF7hn4rq2tDcdz6HzDaTNAHEaAB9VKADov",
            "symbol": "RAG",
            "name": "RAM-G",
            "decimals": 0,
            "logoURI": "https://www.ram-g.ml/RamG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Nvs6ebZb",
                "website": "https://www.ram-g.ml"
            }
        },
        {
            "chainId": 101,
            "address": "H5cDniBsyfoddtHrfz6gCSw7R23ZwRuzozD4Fek9WUsS",
            "symbol": "lpUSD",
            "name": "LP Finance USD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/lpUSD.png",
            "tags": [
                "stablecoin",
                "LP-Finance"
            ],
            "extensions": {
                "discord": "https://discord.gg/ug7mstrHNW",
                "telegram": "https://t.me/LP_Defi_Official_group",
                "twitter": "https://twitter.com/LPFinance_"
            }
        },
        {
            "chainId": 101,
            "address": "AotgToH2CXpWh1m8xsCrYQ46fz4n4FdEnL3FgTYcSnt2",
            "symbol": "AOTG",
            "name": "Apes Of the Gods Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AotgToH2CXpWh1m8xsCrYQ46fz4n4FdEnL3FgTYcSnt2/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/apesofthegods",
                "twitter": "https://twitter.com/ApesoftheGods",
                "website": "https://apesofthegods.com/"
            }
        },
        {
            "chainId": 103,
            "address": "ErdkWPCAJP3cXH56ZeRCPqpHkJFtNyF3gPuEjxkwwgBq",
            "symbol": "PH",
            "name": "Phenal",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rohitrj22/phenal/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FdZu3j6QeJQPhXo7fPCQR6dmYy7c5KQsjKvBxi4PFSj2",
            "symbol": "FRTC",
            "name": "FRToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdZu3j6QeJQPhXo7fPCQR6dmYy7c5KQsjKvBxi4PFSj2/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DR8thdhLWjw5ZEahtn53SLgzAWqZQ3ZyhQQCHomC95Ut",
            "symbol": "Voucher",
            "name": "Voucher Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/VoucherSol/Crypto/main/vouchersol-logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "description": "The world's first innovation project integrating blockchain technology into the education system to enhance learning enthusiasm",
                "github": "https://github.com/VoucherSol",
                "twitter": "https://twitter.com/VoucherSol",
                "website": "https://medium.com/@vouchersolana"
            }
        },
        {
            "chainId": 101,
            "address": "9jcibqjEh988j7szCXB9jKgF3hH17CYbjrDYoGYGmmne",
            "symbol": "SOLNANA",
            "name": "Solnana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9jcibqjEh988j7szCXB9jKgF3hH17CYbjrDYoGYGmmne/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/artmonkees",
                "website": "https://artmonkees.io/"
            }
        },
        {
            "chainId": 103,
            "address": "CyRFrieoNB1hxw8bjyoh3y7eYA5QeFS9PsdpkM3VSJwR",
            "symbol": "CHILL",
            "name": "Chill Fish Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CyRFrieoNB1hxw8bjyoh3y7eYA5QeFS9PsdpkM3VSJwR/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AuFj6v1RSGv5fgPAhGMEHy7VEDQD97DRphBMRSo2218q",
            "symbol": "KAV1",
            "name": "Kawaii DAO Vote Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AuFj6v1RSGv5fgPAhGMEHy7VEDQD97DRphBMRSo2218q/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "8uvYjAw7U9DJzpN1rCuxRXGLx4acureuKQwjHyrPcoUP",
            "symbol": "WOOD",
            "name": "WOOD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/5Ns2m2oBWyj7Kz4VgGgenHZA5dUTJ224AbtjUjaDB651/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8fAt3GoA7vp1m6JWw4xKwyXM7QMHrapbA25x5wmkZD2v",
            "symbol": "MAYAX",
            "name": "Mayax",
            "decimals": 4,
            "logoURI": "https://github.com/suleymanusmahmutus/mayax-token/blob/main/$MAYX%20logo.png?raw=true",
            "tags": [
                "NFT",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A823zwVE6dfjJJ3WDcxkPycZX7LkCiF7jazt1V9rxvWr",
            "symbol": "TMMT",
            "name": "The Mouse Mouse Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/llaera/TMMT/main/logoTMMT.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lorislaera",
                "website": "http://loris.lu/"
            }
        },
        {
            "chainId": 101,
            "address": "4qHdtf8B5mWC9dPeZzBryWmrF4s4cyGEdBnYeyNdSP7y",
            "symbol": "DEU",
            "name": "DEUTERIUM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4qHdtf8B5mWC9dPeZzBryWmrF4s4cyGEdBnYeyNdSP7y/deulogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "$DEU is literally the fuel of the entire OG Ali3n ecosystem, it is a Token created on the Solana Blockchain",
                "discord": "https://discord.gg/wU2VVbnJwa",
                "twitter": "https://twitter.com/ogali3n",
                "website": "https://linktr.ee/ogali3n"
            }
        },
        {
            "chainId": 101,
            "address": "ABi53CLHZeS4gFsRhrLwxWg2BD1WyymyXBBgD7aC1svb",
            "symbol": "OGAN3",
            "name": "OG Ali3n WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ABi53CLHZeS4gFsRhrLwxWg2BD1WyymyXBBgD7aC1svb/logo.png",
            "extensions": {
                "description": "This token will grant you access to 1 mint during Presale of OG Ali3n",
                "discord": "https://discord.gg/wU2VVbnJwa",
                "twitter": "https://twitter.com/ogali3n",
                "website": "https://linktr.ee/ogali3n"
            }
        },
        {
            "chainId": 101,
            "address": "JiKavX2Z9muwFR3M9eXn9NgHgsT3xUw7QXKWeid2TnY",
            "symbol": "JIKAN",
            "name": "JIKAN STUDIOS WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JiKavX2Z9muwFR3M9eXn9NgHgsT3xUw7QXKWeid2TnY/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/JIKANSTUDIOS"
            }
        },
        {
            "chainId": 101,
            "address": "B7oUeMmDT2ZjsCXUVtvTBYcBb4jJy5CXW1FE3Wqr68Gb",
            "symbol": "VYV",
            "name": "Vyvest",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Vyvest/vyvest_token/main/logo.png",
            "tags": [
                "utility-token",
                "currency",
                "vyvest"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/vyvest/",
                "twitter": "https://twitter.com/vyvest"
            }
        },
        {
            "chainId": 101,
            "address": "CGpVGTyeofBeQDWRFjyvaC3NzASLNMrKbx1Mno4QSkDb",
            "symbol": "TCAC",
            "name": "TCAC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/TCACBot/TCACBot/main/Token1.png",
            "tags": [
                "NFT",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/nXN6F4YfGd",
                "twitter": "https://twitter.com/TCAC_Solana",
                "website": "https://thecasinoape.club"
            }
        },
        {
            "chainId": 101,
            "address": "4r7pQ4Eu2d8PBukP9XxXymze8a7VKwE6Ta7TqnM7yELQ",
            "symbol": "NSN",
            "name": "NS-Network",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/steebxx/asset/main/nslogo14.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9ycjFE7CrhKY121J3tupqiCNaYsgSvmnszjaGKKWPRHi",
            "symbol": "PHEN",
            "name": "Phenos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rohitrj22/Phenos/main/logo1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bb9MA1zCZ6qHTavbpxGe6Gwei1TrnPqv9AHJ6B38yeut",
            "symbol": "A8",
            "name": "Ancient8",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bb9MA1zCZ6qHTavbpxGe6Gwei1TrnPqv9AHJ6B38yeut/logo.png",
            "tags": [
                "DAO-token",
                "social-token",
                "community-token",
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "address": "Bb9MA1zCZ6qHTavbpxGe6Gwei1TrnPqv9AHJ6B38yeut",
                "blog": "https://blog.ancient8.gg/",
                "discord": "https://discord.com/invite/ancient8",
                "facebook": "https://www.facebook.com/groups/222117549944333",
                "telegram": "https://t.me/ancient8_gg",
                "twitter": "https://twitter.com/Ancient8_gg",
                "website": "https://ancient8.gg/",
                "whitepaper": "https://whitepaper.ancient8.gg/ancient8/",
                "youtube": "https://www.youtube.com/channel/UCv4p3wP6A6Li2UYhGsh3tDQ"
            }
        },
        {
            "chainId": 103,
            "address": "4uQikXdLpygqu5dD76FMeVhm5VadVw4ekqWuXsUDp2Xo",
            "symbol": "AMWL",
            "name": "Alien Mentors WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/alien2.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SLYRnc8efXUZjMVQiG65p2RUsukQPEdgPWLGYn3oHrF",
            "symbol": "SLYR",
            "name": "SuperLayer",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLYRnc8efXUZjMVQiG65p2RUsukQPEdgPWLGYn3oHrF/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/SuperLayerio",
                "website": "https://superlayer.io"
            }
        },
        {
            "chainId": 101,
            "address": "Fe49uKc9471umPfsSqPbJQR473dYMokdqNuLtWLCPxss",
            "symbol": "SLHK",
            "name": "SolheimKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/krso1002/crypto/main/logoSolheinCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7BjLhYaF6cayDiCEZ77UnKuEPmK52CkL5bybJ7ZFrjyu",
            "symbol": "WLS",
            "name": "WLSkeletonz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Deghf666g/wltoken/main/Bones.png?token=GHSAT0AAAAAABTUJFEMQQ75RK4YB3RNOWNKYTJUIEA",
            "tags": [
                "NFT",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SkeletonzNFT",
                "website": "https://skeletons-nft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "942JqBf2PipzDyHM5sQyfLh21XUVFmGAoSReMMe1Gfba",
            "symbol": "SCWL",
            "name": "SolClones WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pablolefleur/solcloneswltoken/main/solcloneswlimg.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J1gpNtGmUqrZ5RuaVLep8UsMjtL1jjiBU7Zvea72xegn",
            "symbol": "MIAPSO",
            "name": "MIAPSO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mihaiapso/1stcrypto/main/256.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "commqeM8PpVGfQqfs8Cdg5xeDshZwmnfbbEJuVbQnDe",
            "symbol": "COMM",
            "name": "Communi3 Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/commqeM8PpVGfQqfs8Cdg5xeDshZwmnfbbEJuVbQnDe/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/communi3",
                "twitter": "https://twitter.com/communi3_io",
                "website": "https://nft.communi3.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GG7y153Enp7VaNbbGKLtgrhY7B8x2PFawvx445FQkf9L",
            "symbol": "OZK",
            "name": "OZAK",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/St33b8n/asset/main/logo.jpg",
            "tags": [
                "social-token",
                "utility-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ZB5G65aYUfnJP9NM3FkpenCYYy198NgJUFG68RdSVXU",
            "symbol": "DOOF",
            "name": "Doof Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/schraderweb/crypto/main/dooflogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6LYebwvX39zn18XA4692s3h8UsbmBzmnLKmzz1CPm8Ta",
            "symbol": "SKNK",
            "name": "SkunkworksCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6LYebwvX39zn18XA4692s3h8UsbmBzmnLKmzz1CPm8Ta/logo.svg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "WMC5QepSBi8wdGt84pa2vvZBuhksEVrADaVNMci8sgq",
            "symbol": "WMC",
            "name": "Wonder Monkeys Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WMC5QepSBi8wdGt84pa2vvZBuhksEVrADaVNMci8sgq/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/wmonkeysc"
            }
        },
        {
            "chainId": 101,
            "address": "C38sEPwJa6zSCmrZjbnmeST3ACjsZiJ3VfZMb3Wz9CVR",
            "symbol": "SPN",
            "name": "Solar Panel",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Acidias/CryptoSolToken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3i6AKKC2WGd7qgqFJmSxKUGBWAui91upsMGu82xszTL2",
            "symbol": "vBARN",
            "name": "BARN",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/3i6AKKC2WGd7qgqFJmSxKUGBWAui91upsMGu82xszTL2/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "EJEnW3ym7CGoRHSaAtx3aEUZ9qMGcRvdHaMuJtgX9bJ3",
            "symbol": "VALLE",
            "name": "Ribrito do Valle",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tarvcoin/crypto/main/logo_valle_token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "APkf6LAn4uiSHWBFuXiaq6GaJQzNHNzcK5TSsxRUA3bh",
            "symbol": "XONE",
            "name": "Xone Spaces",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APkf6LAn4uiSHWBFuXiaq6GaJQzNHNzcK5TSsxRUA3bh/logo.png",
            "tags": [
                "estate-token",
                "metaverse-token",
                "virtual-land",
                "play-to-earn",
                "metaball-nfts",
                "plasmaverse",
                "metaballix"
            ],
            "extensions": {
                "telegram": "https://t.me/metaballix",
                "twitter": "https://twitter.com/metaballix",
                "website": "https://xone.land"
            }
        },
        {
            "chainId": 101,
            "address": "45hSWcupwLpq5AgQU6V6socGn9EsHP59qVeVKmXyrbqi",
            "symbol": "GMC",
            "name": "GMC coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/GalaxyMoonClub/assets/main/assets/GMC.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/galaxymoonclub",
                "twitter": "https://twitter.com/galaxymoonclub",
                "website": "https://www.galaxymoonclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CWLA8Kks4qJ5ain6cdhDnqC2Tns5osbakBLoZvzHhpGV",
            "symbol": "CANDD",
            "name": "CANDY DEALERS WL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWLA8Kks4qJ5ain6cdhDnqC2Tns5osbakBLoZvzHhpGV/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CandyDealersNFT",
                "website": "https://www.candydealersnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9DHhXz8BNcWHmPDQ9Bj418erzEgZsEPFSBCV2yi8XjcC",
            "symbol": "GPV",
            "name": "GP5 Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/AshtonCable/gp5-coin/main/gp5icon.jpg",
            "tags": [
                "social-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "waodQ3T7DKY3LPa2wLnMCnDnYEWvtL6Xj1BfqjDQ89z",
            "symbol": "AOD-WL",
            "name": "Age of DeFi WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/waodQ3T7DKY3LPa2wLnMCnDnYEWvtL6Xj1BfqjDQ89z/wl-logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://ageofdefi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "oaodTnNEYCKMH9yfzgeJkXyKQtu2ZYrkZw93JcP3ECQ",
            "symbol": "AOD-OG",
            "name": "Age of DeFi OG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/oaodTnNEYCKMH9yfzgeJkXyKQtu2ZYrkZw93JcP3ECQ/og-logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://ageofdefi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Dx3qUh6f5GMaBQeMPYKPH1p8uaiCBYLj2eYDzqW6uCjy",
            "symbol": "SFS",
            "name": "SnakeFS",
            "decimals": 9,
            "logoURI": "https://github.com/firassaada/CRYPTO/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CRTRFiHiddN7UMR5LR4SgDB4VuX6s4SKm2Ltms7ui7Tu",
            "symbol": "$CRTR",
            "name": "CRTR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRTRFiHiddN7UMR5LR4SgDB4VuX6s4SKm2Ltms7ui7Tu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/badcreatureSOC",
                "website": "https://www.badcreaturesociety.com/"
            }
        },
        {
            "chainId": 103,
            "address": "851Jvm5mhfpbfd1uThHGy2yxbpbbRbSMSwDiiD4mTAp",
            "symbol": "KGT",
            "name": "KGToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/851Jvm5mhfpbfd1uThHGy2yxbpbbRbSMSwDiiD4mTAp/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CWLAETW6Pty2VqPm3MTs47FhVNdQmEPfDm6yZBkQcZz2",
            "symbol": "SC1M",
            "name": "FAKE TOKEN BUY THE OFFICIAL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWLAETW6Pty2VqPm3MTs47FhVNdQmEPfDm6yZBkQcZz2/logo.png",
            "tags": [
                "scam-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ELECtZbpWTUGcxYTvmqTu2gRH8GVMVWuE4dkULDapp8F",
            "symbol": "ELECTRUM",
            "name": "Electrum",
            "decimals": 9,
            "logoURI": "https://imgur.com/a/QxWOaTj",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Houseofsparta_",
                "website": "https://houseofsparta.art/"
            }
        },
        {
            "chainId": 101,
            "address": "A3epfzWH65DHqYa8ZMkpg1MwPyhV6RU6TeTTuXAzR6TQ",
            "symbol": "USDPT",
            "name": "United States Dollar Partoken",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3epfzWH65DHqYa8ZMkpg1MwPyhV6RU6TeTTuXAzR6TQ/logo.png",
            "tags": [
                "stablecoin",
                "utility-token"
            ],
            "extensions": {
                "description": "United States Dollar Partoken (USDPT) is a one-for-one United States Dollar collateralized stablecoin token.",
                "website": "https://partokens.com/usdpt"
            }
        },
        {
            "chainId": 103,
            "address": "GVEjcbQQK5DdGSYNX1CN2n8X5iThTRXUCzhe3cuW7kcM",
            "symbol": "KBT",
            "name": "KBToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVEjcbQQK5DdGSYNX1CN2n8X5iThTRXUCzhe3cuW7kcM/logo-coin.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "86gDCtTxEa2UssuCGPF8nTGEcXnyPXCPRHVqyWn6rrS8",
            "symbol": "zza",
            "name": "Pizza",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/86gDCtTxEa2UssuCGPF8nTGEcXnyPXCPRHVqyWn6rrS8/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "USXdsB36PkdbzLRdYEHp9oDcaR2Qu9FLG59tyfzkEDW",
            "symbol": "USX",
            "name": "USD Exchange Token for Duck Swap",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/duckdex/token-list/main/assets/mainnet/USXdsB36PkdbzLRdYEHp9oDcaR2Qu9FLG59tyfzkEDW/logo.png",
            "tags": [
                "stablecoin",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/2ySvY6Vrv7",
                "twitter": "https://twitter.com/duck_dex",
                "website": "https://duckdex.github.io"
            }
        },
        {
            "chainId": 101,
            "address": "83AaQeJ5WDZepgZds3TDQNZbJxQdH2rCXD7aXs5WcY61",
            "symbol": "MDAO",
            "name": "Monsta Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/monstadao/logo/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/monstadao_",
                "website": "https://monstadao.com"
            }
        },
        {
            "chainId": 101,
            "address": "2Sqcg3LCSe5zJeYnpYrvB9BbVDgoLkZLqut4GKb8hYFW",
            "symbol": "MDAOWL",
            "name": "Monsta Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/monstadao/logo/main/wl.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://twitter.com/monstadao_"
            }
        },
        {
            "chainId": 101,
            "address": "3akU98ei7LMrB1DwZV2aNZqL1FySLTBE4exCQKYbByBT",
            "symbol": "MCWT",
            "name": "MUTANT CETS WL TOKENS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3akU98ei7LMrB1DwZV2aNZqL1FySLTBE4exCQKYbByBT/logo.png"
        },
        {
            "chainId": 101,
            "address": "DghUbSAzAznDb64xTRc27ye1L3cnwdbxJ1WyBGCzMipv",
            "symbol": "C2C",
            "name": "Creep2CurryNC",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/vk1992op/cryptoPic/main/blockchainlogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6xwLaeaZdV3WTQ3CEaau3CdRggj13Sg91ddNaZCHpr4G",
            "symbol": "ATT",
            "name": "Akvo Test Token",
            "decimals": 9,
            "logoURI": "https://github.com/scgithubcs/AKVOTest/blob/main/akvo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CqoMXWaoTMfYNtTTwTadz9bMxg8DkZJVKtVRcw77nmYg",
            "symbol": "ALT2B",
            "name": "Bitcoin Alternative Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/alt2b/crypto/main/a2BTC%20logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4QYu5xC7yCWj7KWz6vG8GqbQwpF7u6WR87CVNhELvsb2",
            "symbol": "SPXT",
            "name": "Space-X Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/kazilop/SpaceXCoin/main/SPXC.jpg"
        },
        {
            "chainId": 101,
            "address": "To8njmYMGz3x7ZAE2gcQpSyyaHsLz1vd8xzzQD5tfKh",
            "symbol": "CKC",
            "name": "Chill Koala Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/To8njmYMGz3x7ZAE2gcQpSyyaHsLz1vd8xzzQD5tfKh/logo.png"
        },
        {
            "chainId": 101,
            "address": "igYvQesuheKUuvkGS5YnK6HX4udg86Dxbce6TZncqEx",
            "symbol": "GBITS-POC",
            "name": "Gbits-PoC",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/gbits-io/gbits-public-storage/main/gbits-logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DNwaofk8qHpoPx9MZ4aKbvA2isR5d7xv5wcfKnzgV31S",
            "symbol": "FBTB",
            "name": "FBT Beta",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DNwaofk8qHpoPx9MZ4aKbvA2isR5d7xv5wcfKnzgV31S/logo.png",
            "tags": [
                "drive-to-earn",
                "IoT",
                "web3"
            ],
            "extensions": {
                "description": "FBT Beta is the pre-launch token for FrodoBots, which is a decentralised network of tele-operated delivery robots powered by a 'drive-to-earn' model.",
                "discord": "https://discord.com/invite/bwUfNA2xgr",
                "twitter": "https://twitter.com/frodobots",
                "website": "https://www.frodobots.com",
                "youtube": "https://www.youtube.com/channel/UCYTWaYN7a2sCPwG0WSNl3Yg"
            }
        },
        {
            "chainId": 101,
            "address": "8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk",
            "symbol": "$GARY",
            "name": "GARY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg"
        },
        {
            "chainId": 101,
            "address": "CLkGAwfhBmL3oNmNppqPUCYu6sAFzfymkmZ7FP1qQVYS",
            "symbol": "GYS",
            "name": "GYS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLkGAwfhBmL3oNmNppqPUCYu6sAFzfymkmZ7FP1qQVYS/logo.png",
            "extensions": {
                "website": "https://getyoursports.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EZ6eshad6T4Wp4YZhKtkCMJHMfPwyBkLaQCv8ULVagdv",
            "symbol": "CRCO",
            "name": "Crypto's Coffee",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/mihaiapso/newcryptologo/main/newlogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kb2yrgMTpK",
                "instagram": "https://instagram.com/cryptoscoffee?igshid=YmMyMTA2M2Y=",
                "website": "https://www.cryptoscoffee.io/"
            }
        },
        {
            "chainId": 101,
            "address": "k6DGTMnEvS5XZRJpq39rF5WFm2Htukc4iFBTXe6gbPG",
            "symbol": "WLIST",
            "name": "Web3 Trollz Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/k6DGTMnEvS5XZRJpq39rF5WFm2Htukc4iFBTXe6gbPG/UndergroundLogo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SiCKr9KxWv9pA9q35qoaKu9ZhEKZngscLjcREogaobh",
            "symbol": "SBT",
            "name": "SickBoys-WL-Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SiCKr9KxWv9pA9q35qoaKu9ZhEKZngscLjcREogaobh/logo.png"
        },
        {
            "chainId": 101,
            "address": "GPrRVFvMmLyygT3iNCyaGrMAWpyRsGNmmoHzLcQV8gHT",
            "symbol": "WASS",
            "name": "WillThickie ACoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Tedas1/Solana/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3fsRZntJ3mKLXFt2MjgXY7KT8ppSXCpqFGUzTHA37rN4",
            "symbol": "RVALLE",
            "name": "Ribeiro do Valle",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tarvcoin/token-list-v6/main/assets/mainnet/3fsRZntJ3mKLXFt2MjgXY7KT8ppSXCpqFGUzTHA37rN4/logoRvalle.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "tojixS2xsLhpSfYRvLfALmMehyQMEUDwV9RH7TMQmkY",
            "symbol": "$IJOT",
            "name": "Tojiba CPU IJOT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tojibaceo/tojibaceo/main/ijot2.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Tojiba CPU Corps native token IJOT",
                "discord": "https://discord.gg/br3Cfh56kF",
                "twitter": "https://twitter.com/tojibaceo",
                "website": "https://www.tojiba.biz"
            }
        },
        {
            "chainId": 101,
            "address": "G2y1fVA8Rx5dTg21xsDGwJycZAqr4uhLYussCgu1V3LM",
            "symbol": "$KIBBLE",
            "name": "BABC Kibbles",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G2y1fVA8Rx5dTg21xsDGwJycZAqr4uhLYussCgu1V3LM/logo.png",
            "extensions": {
                "website": "https://babclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CyZDc8wbDmtT88YVLAfvrH7QSsGdAreXkNKjWVQDDzCn",
            "symbol": "MFCMC",
            "name": "MFC M1 Mint Confirmation",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CyZDc8wbDmtT88YVLAfvrH7QSsGdAreXkNKjWVQDDzCn/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "description": "This trophy proves that you were one of MFC's first trainers. You were one of the very first 6,969 to join the revolution!",
                "discord": "https://discord.gg/mechafightclub",
                "instagram": "https://www.instagram.com/mechafightclub/",
                "linkedin": "https://www.linkedin.com/company/irreverentlabs/",
                "twitter": "https://twitter.com/MechaFightClub",
                "website": "https://mechafightclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "WL7fRmbPfu3U45ZWPrXyfJTjzneFahC2X2pMsBESL4g",
            "symbol": "ZWLT",
            "name": "Zombiees Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL7fRmbPfu3U45ZWPrXyfJTjzneFahC2X2pMsBESL4g/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "HGthk49ocEHRK4zAEVGrnATug1jo795m8nKmYHMxPFmb",
            "symbol": "RCHG",
            "name": "CheonHae ReeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/carolprogrammer/julieyoon/main/RCoinLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9FtUCM8xVL7SdtC9uPAYX9etoZef3WDM4GPpaiMRS1mg",
            "symbol": "S0l_",
            "name": "S0L0NA_PAY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/peawisam/crypto/main/S0l_logocleane2d.png",
            "tags": [
                "Payments-token"
            ]
        },
        {
            "chainId": 101,
            "address": "YAKUAzPYctt3wFvDWHXkKUa4c3Vxx33Voui2HEiE8gA",
            "symbol": "YAKUx",
            "name": "YAKU x avatars Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YAKUAzPYctt3wFvDWHXkKUa4c3Vxx33Voui2HEiE8gA/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/YakuCorp"
            }
        },
        {
            "chainId": 101,
            "address": "3aX4hAyEZcMCn4DmFGPJSguodx7HrwxHr2dFRCD3ec1K",
            "symbol": "TWD",
            "name": "The Wolf Den WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aX4hAyEZcMCn4DmFGPJSguodx7HrwxHr2dFRCD3ec1K/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/thewolfden",
                "twitter": "https://twitter.com/TheWolfDenNFT"
            }
        },
        {
            "chainId": 101,
            "address": "SouLMDHmcNVqMusHpdCrWQEh9HWiQYdLiR28XkhveSL",
            "symbol": "Deadsouls",
            "name": "Dead Souls Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SouLMDHmcNVqMusHpdCrWQEh9HWiQYdLiR28XkhveSL/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeadSoulsDAO"
            }
        },
        {
            "chainId": 101,
            "address": "2Giihhh4rD5QMF49EExf5k8qbxftaqRWzLi4tS6YcrvR",
            "symbol": "DGOLD",
            "name": "Degen Gold",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Giihhh4rD5QMF49EExf5k8qbxftaqRWzLi4tS6YcrvR/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "$DGOLD is the official governance token for Degen Islands",
                "discord": "https://discord.gg/degenislands",
                "twitter": "https://twitter.com/degenislands",
                "website": "https://degenislands.com"
            }
        },
        {
            "chainId": 101,
            "address": "yCUh3GqnQdus9rj7iEheJDNVsbwYnd4WYgwjQkwnHSH",
            "symbol": "y-cUSDC-cUSDT",
            "name": "Yield Saber LP Token (cUSDC-cUSDT)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yCUh3GqnQdus9rj7iEheJDNVsbwYnd4WYgwjQkwnHSH/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "A4DpjBEAL9U8crHRNKrbtdbH1wziiAkavNKPnwaetnyS",
            "symbol": "TWDNEW",
            "name": "(FIXED) The Wolf Den WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A4DpjBEAL9U8crHRNKrbtdbH1wziiAkavNKPnwaetnyS/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/thewolfden",
                "twitter": "https://twitter.com/TheWolfDenNFT"
            }
        },
        {
            "chainId": 103,
            "address": "3oGc7tXAPDQkM8XtDcVZTs3kUMZUMiTQihhXC6nMpUPJ",
            "symbol": "KBSol",
            "name": "KBSol Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3oGc7tXAPDQkM8XtDcVZTs3kUMZUMiTQihhXC6nMpUPJ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EXwT5bkDvTD136eqySjTu5xsNkeeTa9vdTM6f9NdGn8j",
            "symbol": "VICHE",
            "name": "VICHE of Ukraine Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vicheua/logo/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "9p2xfkvfSrX9Li7fUPog4Z1owetZLnEKxeuexcRMXH1a",
            "symbol": "DICK",
            "name": "DICKY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9p2xfkvfSrX9Li7fUPog4Z1owetZLnEKxeuexcRMXH1a/logo.png"
        },
        {
            "chainId": 101,
            "address": "AaE7ktigdArb5tXRvZPuTYqqKTAzsFoLHa6WQ1icvCEg",
            "symbol": "SEWT",
            "name": "SolExpress WL Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SEPAD12/Logo/main/SE-Logo.png"
        },
        {
            "chainId": 101,
            "address": "HYHtT5bixTKEWPYwiUC5vCqTzSaoTQzNoMmy2GsPDgqQ",
            "symbol": "CROAK",
            "name": "The Great Croak Club Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYHtT5bixTKEWPYwiUC5vCqTzSaoTQzNoMmy2GsPDgqQ/coin.png"
        },
        {
            "chainId": 103,
            "address": "6xwLaeaZdV3WTQ3CEaau3CdRggj13Sg91ddNaZCHpr4G",
            "symbol": "ATT",
            "name": "Akvo Test Token",
            "decimals": 9,
            "logoURI": "https://github.com/scgithubcs/AKVOTest/blob/main/akvo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Do5AbqdEbj742B2Cm8BypAGg3h1skLaAVTbT2mLRcW8c",
            "symbol": "HALO",
            "name": "HALO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/HaloTokenLogo/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "CxtwGnhLFaHNYYJg9wzBjYoyBR7MZDrf3SSEAnobWf7t",
            "symbol": "SHMC",
            "name": "Sheeshmon Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CxtwGnhLFaHNYYJg9wzBjYoyBR7MZDrf3SSEAnobWf7t/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FAn4hcD345ugybXc6aDivPnLAnBLhjNKcRGkZt2bGiCb",
            "symbol": "$BAGS",
            "name": "BAGS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/BAGS_coin.png"
        },
        {
            "chainId": 101,
            "address": "GpVdc5pgP32JU2SNYWWpGerMGNVf7V5t5pgjdLgBQ7gd",
            "symbol": "AUE",
            "name": "Aue",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpVdc5pgP32JU2SNYWWpGerMGNVf7V5t5pgjdLgBQ7gd/logo.png"
        },
        {
            "chainId": 101,
            "address": "8CpBioM1rghHPU6ngsYM5qWNJ3WqZvE4puTC9jc6iWGN",
            "symbol": "ITEM",
            "name": "Item Banc Index",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8CpBioM1rghHPU6ngsYM5qWNJ3WqZvE4puTC9jc6iWGN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://itembancindex.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Bo1hj1f1ms62Cbps9yJxUwCYmsDjXQqjJBq4K9kRKG9w",
            "symbol": "ETHN",
            "name": "Eternel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/8rch/criptologo/creator/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FxPQz8vFuCdKnU5CimjggFqSRa7gr3Bik43cYmQV1bTB",
            "symbol": "GBG",
            "name": "GBG WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mitchcrompton/goldbar/ba790237a4b45f407d05f1aeb6f87294798b8595/GBG-Logo2.png",
            "tags": [
                "whitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "Fi3cR6X8oAKcmYkQmDcfL6ZYT1EVRC8FEPEWvgLJLKPw",
            "symbol": "VNAR",
            "name": "Vnarchy Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BigGucciSosa300/VnarchyToken/main/tokenValoche.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "55UkPdnVYh1EgE46eRaqFUqFxATzLDPhiT5GkSSRcwiN",
            "symbol": "TAZR",
            "name": "Taser",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/djfliq1/taser/main/Taser_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AAKGBKbQVoraQFLTdu7Jc6yg5yzyAfaYFmiAaBxVj1Vd",
            "symbol": "WTK",
            "name": "WenToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/drunkEmu/Wen_Tech_Logo/main/WenToken.png",
            "tags": [
                "burger-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7J3vpKi1zePZu6JMYxWLNd1ueq4RmG5XNFQ4KW6NH5Pq",
            "symbol": "CFC",
            "name": "Chong Family Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/changkilin/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8SDZEuvdWyuKFmX72Hn1t6kFR9JfxiWzNCsyXnJnmAQH",
            "symbol": "CW3T",
            "name": "Communi3 Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EDDARI/Communi3-Whitelist-Token/main/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/communi3",
                "twitter": "https://twitter.com/communi3_io",
                "website": "https://nft.communi3.io/"
            }
        },
        {
            "chainId": 103,
            "address": "J5MjLfQjnHSsWurBgaejgpsomZVRckUYCbiQjPMbYVBf",
            "symbol": "$STKD",
            "name": "Stacked",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/robo360/token-list/8f3ef4c943be3fa71b75ee0ae06e8a4cc6132f06/assets/mainnet/J5MjLfQjnHSsWurBgaejgpsomZVRckUYCbiQjPMbYVBf/Stacked-Verical-White.png",
            "tags": [
                "Governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PompZYYD9zSatYYncTzPQcTvnGUoaDFDptQPnDaRsu3",
            "symbol": "Pompeizz",
            "name": "Pompeizz OG Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PompZYYD9zSatYYncTzPQcTvnGUoaDFDptQPnDaRsu3/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Pompeizz"
            }
        },
        {
            "chainId": 101,
            "address": "EjaC7vKgimdVMyaF7SkNNaY2D8PDRVfAwThuAoeVvE7V",
            "symbol": "BSAMO",
            "name": "BabySamo",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXzNKiMP9YWHMMvWnT2cag8UHx5fgMHRfqmVW22K2hm7/bsamo.png",
            "tags": [
                "p2e"
            ],
            "extensions": {
                "telegram": "https://t.me/babysamoyedin",
                "twitter": "https://twitter.com/babysamoyedin",
                "website": "https://babysamo.io"
            }
        },
        {
            "chainId": 101,
            "address": "4yoWgpCg5KciCPuA6LxDFpJHa53Jjj6XU9RLFJCgdakL",
            "symbol": "$DRIVE",
            "name": "DRIVE COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/DRIVE_coin.png"
        },
        {
            "chainId": 101,
            "address": "Emberc567ToSP9FgAMuJWHXiQnEKVWrWC6gy64zbsK8u",
            "symbol": "EMBER",
            "name": "Meta Drago Ember",
            "decimals": 9,
            "logoURI": "https://metadrago.art/resources/images/EmberLogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/metadrago",
                "twitter": "https://twitter.com/MetaDragoNFT",
                "website": "https://metadrago.art"
            }
        },
        {
            "chainId": 101,
            "address": "Comm3ZKs8EnEvxiNp3X9PhkjWDNc2VDozwYEKDcCzSti",
            "symbol": "Communi",
            "name": "Communi3 WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Comm3ZKs8EnEvxiNp3X9PhkjWDNc2VDozwYEKDcCzSti/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/communi3_io"
            }
        },
        {
            "chainId": 101,
            "address": "FyXLF1fFAnKjby8f2pYXeyBWgB5hmon2DmCjytZEfkcz",
            "symbol": "$ELEVATE",
            "name": "ELEVATE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/ELEVATE_coin.png"
        },
        {
            "chainId": 101,
            "address": "kronKPwYDbmtNgynPMGwyMyV6PrgJffNuvG3EvjTiVa",
            "symbol": "$BUDZ",
            "name": "Krypto Kronikz Budz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/KryptoKronikz/budz-icon.png",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/krypto-kronikz",
                "instagram": "https://www.instagram.com/kryptokronikz",
                "twitter": "https://twitter.com/KryptoKronikz",
                "website": "https://www.kryptokronikz.co.uk/"
            }
        },
        {
            "chainId": 101,
            "address": "HPs8A1WiRypdPwFBG32KaA9qHLt4VDKJ7sWUF5HKoicP",
            "symbol": "FSOSA",
            "name": "FerSosa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/neoxolotl/logo/master/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CxNiD5CKv3JMZc3LDUQjHJX17BFnpGDDU38PGPnnBRx7",
            "symbol": "SMWL",
            "name": "SolMonkeez WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CxNiD5CKv3JMZc3LDUQjHJX17BFnpGDDU38PGPnnBRx7/logo.png"
        },
        {
            "chainId": 101,
            "address": "Aif78Nq87kjvRzk2TGn1Q6r2gECddPHCcxYsdxGpPpDM",
            "symbol": "NS12",
            "name": "Naxar s12",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aif78Nq87kjvRzk2TGn1Q6r2gECddPHCcxYsdxGpPpDM/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "description": "Naxar s12 is an NFTs card for mining tokens in the Boxch app. APY 12%",
                "twitter": "https://twitter.com/_Naxar",
                "website": "https://naxar.net/"
            }
        },
        {
            "chainId": 101,
            "address": "GzAr3oY2nZJnnbsvyRTYy7PW9Dp9hThGgiUpzs3HE8ns",
            "symbol": "$TUITON",
            "name": "TUITION",
            "decimals": 2,
            "logoURI": "https://i.ibb.co/W0Zp6RS/Tuiton-Coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/8GZyJ3BnYw",
                "twitter": "https://twitter.com/DropoutUniv",
                "website": "https://www.dropoutuniversity.co/"
            }
        },
        {
            "chainId": 101,
            "address": "DroidnX5oC42WDw2yfcU1VziNs9CUcTV145p6cZRQiFq",
            "symbol": "DRB",
            "name": "DroidBits",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DroidnX5oC42WDw2yfcU1VziNs9CUcTV145p6cZRQiFq/logo.png",
            "tags": [
                "NFT",
                "Metaverse",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DroidBits"
            }
        },
        {
            "chainId": 101,
            "address": "ABFkiQcLuCjicoCddXHuiPN9S653StWuySCHvWYF5oEy",
            "symbol": "SMWL",
            "name": "SolMonkeez WLToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ABFkiQcLuCjicoCddXHuiPN9S653StWuySCHvWYF5oEy/logo.png",
            "tags": [
                "NFT",
                "dao",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolMonkeez"
            }
        },
        {
            "chainId": 101,
            "address": "FQde6nK4HKfhCNuxZ8FHAJ7EKi7qtxMZbBMSXWeAZX31",
            "symbol": "MGL",
            "name": "Audio Moguls",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/audiomoguls/crypto/main/Token.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4nNWPitwcYv4XMTqxPu8S5Eu1gGmMEwF76GMCGaQGzmp",
            "symbol": "CARC",
            "name": "Crypto Apples Rewards Coins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JADO-artist/Crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "VisDZunhfXf29cxyZ2pGWvsrpye5FnCgScnRZ2d9WVG",
            "symbol": "$VISN",
            "name": "VISN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VisDZunhfXf29cxyZ2pGWvsrpye5FnCgScnRZ2d9WVG/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "tDWgfue1v4JNMHmgxi7Qd2xzNpLhGFhaghcwAvnsYsv",
            "symbol": "$t",
            "name": "tDWOS",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tDWgfue1v4JNMHmgxi7Qd2xzNpLhGFhaghcwAvnsYsv/logo.png",
            "extensions": {
                "discord": "https://discord.gg/gXpvbWftH7",
                "twitter": "https://twitter.com/dwosol",
                "website": "https://www.dwos.app/"
            }
        },
        {
            "chainId": 101,
            "address": "8BLweBxTVWKL1X5ghQF2eb67JReF56zesgTbN91C7nJb",
            "symbol": "ASC",
            "name": "Aliens Space Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BobLiterman/PizzaPal/master/logo.png",
            "extensions": {
                "discord": "https://discord.gg/tUEwbEfZgE",
                "twitter": "https://twitter.com/aliensspaceclub"
            }
        },
        {
            "chainId": 101,
            "address": "omnLJ3LyDz3kGYdDevm5guym7cU95kkz85FU9hvj5cZ",
            "symbol": "OMNI",
            "name": "Omniscient222",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/omnLJ3LyDz3kGYdDevm5guym7cU95kkz85FU9hvj5cZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/K7BpCPSUqv",
                "twitter": "https://twitter.com/Omniscient222",
                "website": "https://www.omniscient222.com"
            }
        },
        {
            "chainId": 101,
            "address": "68zeUZMhsYxVHrETjmVvz8ADZmWP8rvTiLpgq6SbyKdW",
            "symbol": "ISIS",
            "name": "Shariff",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shariffi/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C4xU8aLc1DDjYS2WqvJkqTbZHLMfQs42MkXmViqAhPHE",
            "symbol": "$BST",
            "name": "BatsToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C4xU8aLc1DDjYS2WqvJkqTbZHLMfQs42MkXmViqAhPHE/logo.png"
        },
        {
            "chainId": 101,
            "address": "3DyBTasPCdXPzohx8ri3KAiKXkeugqXt5sSqopNn4G7J",
            "symbol": "TSST",
            "name": "TSS Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3DyBTasPCdXPzohx8ri3KAiKXkeugqXt5sSqopNn4G7J/logo.png",
            "tags": [
                "community-token",
                "Metaverse",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/HnKkJa8P",
                "twitter": "https://twitter.com/TSSCiberseg_ast",
                "website": "https://tssciberseguridad.com"
            }
        },
        {
            "chainId": 101,
            "address": "3HDLkGsdEh9mRupFax1k2RnFJJRNKhqJaouWhVnrGEfK",
            "symbol": "SLMN-WL-T",
            "name": "Solmon WL Token Trainer",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jwurrow/solmon/main/solmon-token.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "website": "https://solmon.game/"
            }
        },
        {
            "chainId": 101,
            "address": "2YmFbkbfPMiUivS5TtSnzLGPM32iapkHBUvKB7XVYuoi",
            "symbol": "$DABL",
            "name": "DABL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/DABL_coin.png"
        },
        {
            "chainId": 101,
            "address": "DmfyVkaHF3xcYoZZJNVQxz32dSfPwrk9fDe5APc8Qop1",
            "symbol": "SLMN-WL",
            "name": "Solmon WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jwurrow/solmon/main/solmon-token.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "website": "https://solmon.game/"
            }
        },
        {
            "chainId": 101,
            "address": "5mRj82qzktE2Ab7ZvoeWUYk6gnuRynTJaPs3YuFQW1gt",
            "symbol": "$CYBER",
            "name": "CYBER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/CYBER_coin.png"
        },
        {
            "chainId": 101,
            "address": "5ETtNMdco1tqLyJuLTFTxwy913ahA7CfAtp3PGuxhMkA",
            "symbol": "P",
            "name": "Pushin' P",
            "decimals": 6,
            "logoURI": "https://github.com/boosbasement/metaboss/blob/main/pushinp.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "64Hw4Hm4WLC1Ty6p8g5vLZNCS37msb9Qq8ZFJE6UConN",
            "symbol": "sAPE",
            "name": "APE (Synthetic)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64Hw4Hm4WLC1Ty6p8g5vLZNCS37msb9Qq8ZFJE6UConN/logo.png",
            "tags": [
                "meme-token",
                "wrapped",
                "synthetic"
            ],
            "extensions": {
                "coingeckoId": "apecoin",
                "coinmarketcap": "https://coinmarketcap.com/currencies/apecoin-ape/",
                "description": "ApeCoin is a decentralized project inspired by Yuga Labs Bored Ape Yacht Club project. ApeCoin was founded to be used within the burgeoning APE Ecosystem, which is supported by the APE Foundation.",
                "twitter": "https://twitter.com/apecoin",
                "website": "https://apecoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BF5JzxS9ZKnv9t84EFFvG5M8JgKJ5XRQvxPsuzHSKXDL",
            "symbol": "AURA",
            "name": "Aura Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BF5JzxS9ZKnv9t84EFFvG5M8JgKJ5XRQvxPsuzHSKXDL/aura.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xmMwQJZDT4",
                "twitter": "https://twitter.com/heyiloveyousol",
                "website": "https://heyiloveu.com"
            }
        },
        {
            "chainId": 101,
            "address": "2R2KgDgAEKksVYbH7GRwuemvnQUg6h6Knhq9mVyNAnzF",
            "symbol": "DYORNERDS",
            "name": "NERDx",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2R2KgDgAEKksVYbH7GRwuemvnQUg6h6Knhq9mVyNAnzF/logo.png"
        },
        {
            "chainId": 101,
            "address": "4ePVDsHuGLNhypuxLdUmyDonYEThfsKTA7Vd3fCxvm2m",
            "symbol": "PRXMD",
            "name": "mzX3z6Nc7a Project X",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/RB5vhYz/1.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "omg6vAatLFChVhicZqFvBLd7vEqJqjp7KPR5CxKjMjR",
            "symbol": "omgWL",
            "name": "Oh My Girls - WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/omg6vAatLFChVhicZqFvBLd7vEqJqjp7KPR5CxKjMjR/logo.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "gciPtrB8rbCVSoJmyvZZZqzUSqSqAdxxEtHzcTo6gp5",
            "symbol": "GC",
            "name": "Galactic Credits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gciPtrB8rbCVSoJmyvZZZqzUSqSqAdxxEtHzcTo6gp5/logo.png"
        },
        {
            "chainId": 101,
            "address": "63DpCYu4LPCqUzM9o7UMiGmsyJ5gVeFE9drzujGqqZHa",
            "symbol": "SLIME",
            "name": "Slime",
            "decimals": 9,
            "logoURI": "https://arweave.net/AJeUAHLNk2pv5P9CIURqu_u6dfSiE_bvVqFDriUzdQw",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/2zvACqhDNz",
                "twitter": "https://twitter.com/WeAreSlimes"
            }
        },
        {
            "chainId": 101,
            "address": "2U2Zbhn6ixvaus9c3SLJiF3XtmbtXwYBt36pSSscH9g2",
            "symbol": "FNAC",
            "name": "F NAc",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2U2Zbhn6ixvaus9c3SLJiF3XtmbtXwYBt36pSSscH9g2/logo.png"
        },
        {
            "chainId": 101,
            "address": "5qf6ftkBT7vDZawJgCj3J4L8Xb25wTcgZftCc4vZTHSM",
            "symbol": "WNAC",
            "name": "W NAc",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5qf6ftkBT7vDZawJgCj3J4L8Xb25wTcgZftCc4vZTHSM/logo.png"
        },
        {
            "chainId": 101,
            "address": "3uKPwVQ7cjnCJWgpFLRfMDkJzAEaV6o7uMJUyZErT6V4",
            "symbol": "$MAES",
            "name": "MAES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/MAES_coin.png"
        },
        {
            "chainId": 101,
            "address": "FgkPeKEDfb2mfbf3at8TVcZE8YdKxpdTTyqabqXqB33Y",
            "symbol": "$ACCESS",
            "name": "ACCESS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/ACCESS_coin.png"
        },
        {
            "chainId": 103,
            "address": "EJWynqh34YPswkdEwBAb76YPX18JdkG4BaXiwDBWg7Bq",
            "symbol": "BIND",
            "name": "Bind Com Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EJWynqh34YPswkdEwBAb76YPX18JdkG4BaXiwDBWg7Bq/BIND.png",
            "extensions": {
                "website": "https://bind.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Ab7jUsWFTdVNmdCC3ZwjbNGnpt9E4qSh9oSZ6hYMBBaw",
            "symbol": "$LEGEND",
            "name": "LEGEND COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/LEGEND_coin.png"
        },
        {
            "chainId": 101,
            "address": "C7nRGKutiD8YBVt1z7TxP5tTuoBac8dDgLoM47tmCjyk",
            "symbol": "JTTE",
            "name": "JOURNEY TO THE EAST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chinahustle/MonkeyKing/main/MKCOIN.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "trpnwGaAvyPXXsYUaN424XUDbnuyt2MKTUFmKHVUDwW",
            "symbol": "TRPWL",
            "name": "Trippin Ape Tribe WL Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/trpnwGaAvyPXXsYUaN424XUDbnuyt2MKTUFmKHVUDwW/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/trippinapetribe",
                "twitter": "https://twitter.com/TrippinApeNFT",
                "website": "https://www.trippinapetribe.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "5EDqX91uTrXWaPXjrJ8wGF2bNDa7t8tYbtAJ4em2Puyu",
            "symbol": "TAKU",
            "name": "TAKUKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ChristosVoutselas/TAKUKoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DopeHrLeKKyMhVaQ1nFDUaiN2ZcoiUTteTJMmpq3vZzB",
            "symbol": "DopeHeadz",
            "name": "DopeHeadz WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DopeHrLeKKyMhVaQ1nFDUaiN2ZcoiUTteTJMmpq3vZzB/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DopeHeadzNFT"
            }
        },
        {
            "chainId": 101,
            "address": "3XJzFnkisu24GkVLGB3ATjUjKV99vY4tQaiHXBuJXenE",
            "symbol": "AJSP20k",
            "name": "AJSP1 20k Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AlainPainchaud/Crypto/main/TokenOfficiel2.png",
            "tags": [
                "utility-token",
                "community-token",
                "business-token",
                "financial-token",
                "monetary-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DophZJDTFC3sYvu7KJC5cDJdDd4UEYne3GkHc3oKY2fh",
            "symbol": "DOPHWLT",
            "name": "DopeHeadz WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DophZJDTFC3sYvu7KJC5cDJdDd4UEYne3GkHc3oKY2fh/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/dopeheadznft"
            }
        },
        {
            "chainId": 101,
            "address": "CkumP8q2xDLFYZ1HgvKPWPEwRdNxgpX1oEEaaGXR3txA",
            "symbol": "ENMA",
            "name": "ENMA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HATEM-CHAABINI/ENMA/main/ENMA.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B71KR82Z2WpyRvz4siHCqVCQE52NuiRvYe1yMqZMcij",
            "symbol": "LMN",
            "name": "Lemon Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/joshtasman/cSolana/main/LMN_LOGO.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "51eur9dnZCchLatjGgz1DhQ82wL7GatirddMtqAaPJvk",
            "symbol": "NHS",
            "name": "SnowCoin Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ZacharyLiebl/SnowCoinSymbol/main/SnowCoinSymbol.png",
            "tags": [
                "nor-easters",
                "experiment"
            ],
            "extensions": {
                "description": "For more info, contact nhscryptocurrency@gmail.com"
            }
        },
        {
            "chainId": 101,
            "address": "2zGGqkFLxHoCQ2F7cbmSvhrdUqvYKdW7nGqoGspAesQN",
            "symbol": "HOMIES",
            "name": "HOMIES",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Homiezforlife/Homieslogo/main/mini.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FgvaSzNW852UGYA84ZkE1MHpUFihbw2xCzq4aqPNRJyq",
            "symbol": "BULUK",
            "name": "Buluk Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JoshMusty/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "Fkh7FJ2F8ogN53GGtqtNMQ1jTwWbmTmCJ8oCGB7qD7oj",
            "symbol": "J",
            "name": "Jimbo Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fkh7FJ2F8ogN53GGtqtNMQ1jTwWbmTmCJ8oCGB7qD7oj/logo.png",
            "tags": [
                "jimbo-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BTCGZYMqyfA8WBKNgwcpZn21ruNBvHvbfnrYNsd4xtTZ",
            "symbol": "btc-g",
            "name": "Green Bitcoin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@master/btcg/btc-g.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "Greening cryptocurrencies one coin at a time",
                "imageUrl": "https://bafybeiakx66r7u5u5sxi24b25k4y5qm6zhpyuetocphl7kfcs4yij6zjgy.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/btcg/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmUs6X2QHrPSAW9mZYtHGNCDjMDWTk7cg3EiEQ1Ao4PvL9/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "DBEW3nPWQMWeSN7bav5RprJRyUopd4NGkhtVsEmoNA94",
            "symbol": "SKIN",
            "name": "SKIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/shark.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/shark_lounge",
                "website": "https://www.sharklounge.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Bq5wDcoXfAjZq7w4JhpcQ5AyG9ugo2xETdA7BefFNu1T",
            "symbol": "LIRA",
            "name": "Lira",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zeinhd/Dota-coin/main/LOGO.PNG.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "AFw8FY138GyFjM2KP254ZAbFdWrG9tyhLM8ct4jAsnT9",
            "symbol": "META",
            "name": "MetaSects",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/sxdhercules/meta/main/MetaSects-removebg-preview-removebg-preview.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "We're the #1 on Solana to build an NFT Marketplace for content creators, Stake to Earn $META. Join the Movement!",
                "twitter": "https://twitter.com/metasectsnft",
                "website": "https://www.metasects.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AB9dE76Sf8c7mi8cxFC7e9n8xz7z4zNQQinEDpgmiHmA",
            "symbol": "ABTWL",
            "name": "Anonymous Beartoshi SRC Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AB9dE76Sf8c7mi8cxFC7e9n8xz7z4zNQQinEDpgmiHmA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://anonbeartoshi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Hd4TfdGjSwtqSBntCCPtF3fKiMHMHUN3mbCmzP8i2asi",
            "symbol": "EUR",
            "name": "Blackrock",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/xBlackrock/EUR_Token/main/Logo.png",
            "tags": [
                "financial-token"
            ]
        },
        {
            "chainId": 103,
            "address": "EGXPbqsSfSG5mRT88XMEYUm8Rf9ZDQAzb8yNJ8dRVyA5",
            "symbol": "GT",
            "name": "Green Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/wajahatrashid1/GreenTokenLogo/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Byn1P6nsVZnKWDLguHgw6aTJ8HDgcjUz5ArboK8irECP",
            "symbol": "CRAB",
            "name": "CryptoCrab",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jsmx125/CryptoCrab/main/CryptoCrab.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BPotsRFqBpxbaggDrgUBN96nAU7hTA7VtFruyidPodJN",
            "symbol": "SONN",
            "name": "SonniCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mammimaisteri/SonniCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HnAq3F9ipwGXKBG3yfHyX8y19m9NiXSd1iphYDvrp1LS",
            "symbol": "KWL",
            "name": "Keikai WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HnAq3F9ipwGXKBG3yfHyX8y19m9NiXSd1iphYDvrp1LS/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/mare_tsuki",
                "website": "https://maretsuki.com"
            }
        },
        {
            "chainId": 101,
            "address": "5QrKoSKw2fYCW4FFHnLnRgTMjRtL6S3YLmEswJYP9B6t",
            "symbol": "DOPE-WL",
            "name": "DopeHeadz-WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5QrKoSKw2fYCW4FFHnLnRgTMjRtL6S3YLmEswJYP9B6t/Logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DopeHeadzNFT"
            }
        },
        {
            "chainId": 103,
            "address": "orcarKHSqC5CDDsGbho8GKvwExejWHxTqGzXgcewB9L",
            "symbol": "ORCA-DEV",
            "name": "Orca Dev",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png",
            "extensions": {
                "medium": "https://orca-so.medium.com",
                "website": "https://orca.so"
            }
        },
        {
            "chainId": 103,
            "address": "Ff5JqsAYUD4vAfQUtfRprT4nXu9e28tTBZTDFMnJNdvd",
            "symbol": "ETH-DEV",
            "name": "Ethereum Dev",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png"
        },
        {
            "chainId": 103,
            "address": "EmXq3Ni9gfudTiyNKzzYvpnQqnJEMRw2ttnVXoJXjLo1",
            "symbol": "USDC-DEV",
            "name": "USDC Dev",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png"
        },
        {
            "chainId": 101,
            "address": "6ercxSiDn2KJ8KZxmCT4eheQqAWN3z17s8eLT3VECuda",
            "symbol": "MOMO",
            "name": "Momonga",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/wilsonv123/momonaga/main/thumbnail_logopng-02.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AVUyMVECTgciKj8FLqzgkyHqngJkhMtXG6QAwQSsPEMb",
            "symbol": "FAS",
            "name": "Finance Aliens Society",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVUyMVECTgciKj8FLqzgkyHqngJkhMtXG6QAwQSsPEMb/logo.png"
        },
        {
            "chainId": 101,
            "address": "soLACdGKTr1PT75KTjj4RaUgA2sDBvCqAd8Zty2W1Yz",
            "symbol": "SOLA",
            "name": "Solaxy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/soLACdGKTr1PT75KTjj4RaUgA2sDBvCqAd8Zty2W1Yz/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "$SOLA Tokenomics with a mission to reward NFT Holders via staking and cultivating a healthy Metaverse Ecosystem.",
                "discord": "https://discord.gg/solaxymetaverse",
                "website": "https://solaxymetaverse.com",
                "whitepaper": "https://solaxymetaverse.com/assets/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "DRyAdYvNHrf4M5yphJeXUCJu6TPiSDoEEtAwbPWMPhTt",
            "symbol": "FAI",
            "name": "Faicoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DRyAdYvNHrf4M5yphJeXUCJu6TPiSDoEEtAwbPWMPhTt/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CH4Z1fw39g2Y9d9f96qiSPUrqJ3CVjJfPWriMLMX1HKi",
            "symbol": "$DEAF",
            "name": "DEAF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/DEAF_coin.png"
        },
        {
            "chainId": 101,
            "address": "ApeWLUZp1WGZdYnFEVubpTEzaNg2cpw182DKitLsyuRd",
            "symbol": "AOWS",
            "name": "Apes of Wall Street Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApeWLUZp1WGZdYnFEVubpTEzaNg2cpw182DKitLsyuRd/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ApesOfWallStNFT",
                "website": "https://www.apesofwallstreetnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CHiKRBLyxcnP9uKCfg63hh2tJMPkwaBtM56uPfsVesqf",
            "symbol": "JPST",
            "name": "JP Free Sloths Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHiKRBLyxcnP9uKCfg63hh2tJMPkwaBtM56uPfsVesqf/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HjUMVG3yQK7uMTq1TerG6C8JzAjRvMdYCoX7ZUzKTgjH",
            "symbol": "MERIT",
            "name": "MERIT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HjUMVG3yQK7uMTq1TerG6C8JzAjRvMdYCoX7ZUzKTgjH/logo.png",
            "tags": [
                "nature"
            ],
            "extensions": {
                "discord": "https://discord.gg/h5hYY4gKfS",
                "facebook": "https://www.facebook.com/SingleEarth",
                "instagram": "https://www.instagram.com/single_earth",
                "twitter": "https://twitter.com/singleearth1",
                "website": "https://single.earth/"
            }
        },
        {
            "chainId": 101,
            "address": "HWLvCf7dwed7WrfiAbesDfSbPZAhfbwwrALv21mZqG8Y",
            "symbol": "HWL",
            "name": "Horseys Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWLvCf7dwed7WrfiAbesDfSbPZAhfbwwrALv21mZqG8Y/logo.png"
        },
        {
            "chainId": 101,
            "address": "6rTMtgErEBbJo4zsHHhK7B2Y1z6JhUMv9MpyX1yTSPTo",
            "symbol": "ASTRTEST",
            "name": "ASTROREAPERZ TEST",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6rTMtgErEBbJo4zsHHhK7B2Y1z6JhUMv9MpyX1yTSPTo/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/astroreaperz"
            }
        },
        {
            "chainId": 101,
            "address": "UoS2FWy8LyEXrvmCFfsLkQtHs47aQ335wKARHBxSMbg",
            "symbol": "CRYO",
            "name": "CRYO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ShahrdadBlockchain/CRYO/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GaZUVNuZkZXjxHQniy6bSNkr7iSAv4iUxrfBj6EXrcXj",
            "symbol": "OMM",
            "name": "ONEUM Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GaZUVNuZkZXjxHQniy6bSNkr7iSAv4iUxrfBj6EXrcXj/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/FSQqhnvZxj",
                "medium": "https://medium.com/@3PMmusicNFT",
                "website": "https://oneum.3pm.earth/"
            }
        },
        {
            "chainId": 101,
            "address": "JACakxoVpG4y2XSfnfCwrDmrKmaXhXzBtuUiPVS2kH4K",
            "symbol": "MSB",
            "name": "Mini Soccer Boys VIP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JACakxoVpG4y2XSfnfCwrDmrKmaXhXzBtuUiPVS2kH4K/logo.png"
        },
        {
            "chainId": 101,
            "address": "BhxkMZCx6nMhiSC53Cbb9rbro3SBwDoopzdCquqJeHpJ",
            "symbol": "ggSOL",
            "name": "ggSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhxkMZCx6nMhiSC53Cbb9rbro3SBwDoopzdCquqJeHpJ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "6JC9vFueQuj45N2eusmL6cxKT6wyY2rymBkcKWtowEAo",
            "symbol": "ggUSDC",
            "name": "ggUSDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JC9vFueQuj45N2eusmL6cxKT6wyY2rymBkcKWtowEAo/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "Eaz9Q3jBv3fR3Mq2DsJWQ4TqDaGhsBcq3XoQWahnEwFd",
            "symbol": "ggmSOL",
            "name": "ggmSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Eaz9Q3jBv3fR3Mq2DsJWQ4TqDaGhsBcq3XoQWahnEwFd/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "G5heESJwK9zTa811CkyFhGL37Nkh4ecYYNWk3XY14gPe",
            "symbol": "EUR",
            "name": "Blackrock Finance",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/xBlackrock/EUR_Token/main/Logo.png",
            "tags": [
                "finance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "qjbfiJcUWf9CXP4LgRapkXmVUeGq1BfrcJLkZeXxQgZ",
            "symbol": "IOS",
            "name": "Iarlaith Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iarlaithos/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9DfciZat8W6fDcwFSFNmf9SN2qdFjVjVYP2sTkJNpwUo",
            "symbol": "Lux",
            "name": "Luchsinger's Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Spencer12219/Crypto/main/logo.png",
            "tags": [
                "Social-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "8cueAYPFnEQPR37ycBeKdMYARwAAYnvEfVH8MwWY1Xmo",
            "symbol": "FLWZ",
            "name": "Flowz Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Ayoalapafuja/Flowz-Coin.png/main/logo.png",
            "tags": [
                "community-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/KhBAHKsF2v",
                "twitter": "https://twitter.com/flowzcoin1",
                "website": "http://flowzcoin.com.ng/"
            }
        },
        {
            "chainId": 101,
            "address": "HVEHJZHzN8Kz9a59Cwv83r4jAHYax5LJFzop99TdiZJn",
            "symbol": "FFn",
            "name": "Pre-Whitelist access Fluffy frens (FFn)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HVEHJZHzN8Kz9a59Cwv83r4jAHYax5LJFzop99TdiZJn/logo.png",
            "extensions": {
                "discord": "https://discord.gg/gmRrSguQKX",
                "twitter": "https://twitter.com/nftfluffyfrens"
            }
        },
        {
            "chainId": 103,
            "address": "EU24g7LE28g6KDEsTFupKgBxzbpMpx3SqgjJ36BmzWmc",
            "symbol": "NTK",
            "name": "NiceToken",
            "decimals": 9,
            "logoURI": "https://github.com/Maverick9081/solana/blob/main/nice.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7t9foh4g1kMucERVbyzwrxhNpnhp4tigwNxJfvC2M2X6",
            "symbol": "SLCF",
            "name": "Solaunchief",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7t9foh4g1kMucERVbyzwrxhNpnhp4tigwNxJfvC2M2X6/logo.png",
            "tags": [
                "social-token",
                "finance-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.solaunchief.com/"
            }
        },
        {
            "chainId": 101,
            "address": "METAXHCNzmSUohBxVAtiKkAJ7W7JK7uUqWGNcepdKxM",
            "symbol": "MCT",
            "name": "Metascape City Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAXHCNzmSUohBxVAtiKkAJ7W7JK7uUqWGNcepdKxM/logo.png",
            "tags": [
                "game-token",
                "NFT",
                "Singapore",
                "Game",
                "Metaverse"
            ],
            "extensions": {
                "description": "Metascape City is the next-gen metaverse empowering players to create, play, compete, and earn.",
                "discord": "https://discord.gg/QT7QEV4s",
                "facebook": "https://www.facebook.com/metascapecity",
                "instagram": "https://www.instagram.com/metascapecity",
                "tiktok": "https://www.tiktok.com/@metascapecity",
                "twitter": "https://twitter.com/MetascapeCity",
                "website": "https://www.metascape.city",
                "youtube": "https://www.youtube.com/channel/UCFdI57Yel-n__BZcnmWj8xw"
            }
        },
        {
            "chainId": 101,
            "address": "FLNiUQLcyuscftADTriTGsQzJJWUbDBvvNTohBEWNYUE",
            "symbol": "HMB",
            "name": "Heavy Metal Bucks",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLNiUQLcyuscftADTriTGsQzJJWUbDBvvNTohBEWNYUE/logo.png",
            "tags": [
                "community-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/vrVMqD6WyJ",
                "twitter": "https://twitter.com/MedievalCatsNFT",
                "website": "https://metarobots.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "5HbkoVbaMnJYEuiTqeC7cBMSK2zG2MFfoxc9e6VkWPQS",
            "symbol": "SCP",
            "name": "SCOPE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/drsaint1/drsaint/29c9fd1942416e3467755ba7fbdaec076c3105cd/scope.jpg",
            "tags": [
                "Gamefi",
                "NFT",
                "Defi"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/UJppkPcqwT",
                "twitter": "https://twitter.com/scope_io",
                "website": "http://scopeportals.io"
            }
        },
        {
            "chainId": 101,
            "address": "EJsBf7Z5UBURSyoTahZuaeHCZnvujV1g8m5cH9KLY6AX",
            "symbol": "BRNI",
            "name": "Berni Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BRNICOIN/brni/main/BERNI2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GQAAhv4TfgcyskQdmHznjhS3WqwC49o8aHyx6YtModvZ",
            "symbol": "IVRY",
            "name": "IVRY TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GQAAhv4TfgcyskQdmHznjhS3WqwC49o8aHyx6YtModvZ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ChywntqwNRzaoWYUcDQ3iALqcwSAVtjM2dPshz5AETMm",
            "symbol": "KTRC",
            "name": "Kotaro Chips",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChywntqwNRzaoWYUcDQ3iALqcwSAVtjM2dPshz5AETMm/logo.png",
            "tags": [
                "NFT",
                "GAMEFI",
                "Staking"
            ],
            "extensions": {
                "description": "Kotaro Chips is an SPL token of Kotaro Sharks, an NFT collection of sharks living on the Solana blockchain that love to play poker and vibe with friends.",
                "discord": "http://discord.gg/kotarosharks",
                "twitter": "https://twitter.com/KotaroSharks"
            }
        },
        {
            "chainId": 101,
            "address": "2qB8M2qCwZUDVsyf1hMFBL41kn3Es1ZA7aEaKP2gWjop",
            "symbol": "PPWL",
            "name": "PixelPilotz WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qB8M2qCwZUDVsyf1hMFBL41kn3Es1ZA7aEaKP2gWjop/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/pixelpilotz",
                "twitter": "https://twitter.com/PixelPilotzNFT",
                "website": "https://pixelpilotz.com"
            }
        },
        {
            "chainId": 101,
            "address": "3PLCY6jcJSVg735CMBfA7jYDACJKfPgh7NJTKMh6TttN",
            "symbol": "Trdz",
            "name": "Tradanza's Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tradanza/cryptologo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Drw2du41PCr9S6VhRqHmujAjdCKwh1JRKZzgtq5n9PpW",
            "symbol": "FBANX",
            "name": "FBANX",
            "decimals": 2,
            "logoURI": "https://solaboy.github.io/images/fbanxlogo.png",
            "tags": [
                "governance-token",
                "exchange"
            ],
            "extensions": {
                "description": "governance token",
                "website": "https://fbanx.org"
            }
        },
        {
            "chainId": 101,
            "address": "FELb3heNi4XA2kM4xTPWprdKaGeAPPK8bNM54CBwwtPG",
            "symbol": "PRCN",
            "name": "ProCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aaravg772/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HR9HW2fygS4RWgn1i3AzxQVayb3ReKpqKKA3TZfWwzHN",
            "symbol": "LTTCE",
            "name": "Lettuce Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/boblettucetv/Lettuce-Token/Crypto/logo.png"
        },
        {
            "chainId": 101,
            "address": "6SURQPXgbgDfeDi7Su2TQvZHTwQJ6fsZrAZAM6yxwnej",
            "symbol": "CP3D",
            "name": "CryptoPicks",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/LegendaryCryptoPick/assets/main/CryptoPicks%201st%20Gen%20Custom%20Print.png",
            "tags": [
                "social-token",
                "music-token",
                "NFT",
                "NFT-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cs2R3beyPt4NGg1ARgk3uP6U3DA8Hpi8SW613WGoRVzC",
            "symbol": "AMOG",
            "name": "ArtMonkees OG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cs2R3beyPt4NGg1ARgk3uP6U3DA8Hpi8SW613WGoRVzC/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/artmonkees",
                "website": "https://artmonkees.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FD12nu5TQDTXTpQkTcThpV5EFCB6Df27CBfPUawizcB2",
            "symbol": "GCOIN",
            "name": "GatorCoin OG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/connellyw/Crypto/main/Gcoin.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://williamconnelly.netlify.app/"
            }
        },
        {
            "chainId": 101,
            "address": "frogmSnr9knrc6CcVMtGrh3KvixZRc67LQYSqoE3ozZ",
            "symbol": "$RIBBIT",
            "name": "Frog Club Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/frogmSnr9knrc6CcVMtGrh3KvixZRc67LQYSqoE3ozZ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EyMzhwdjAMLKsKR1domLdAmTWfc4PWPBPZRuzouvCWiQ",
            "symbol": "WADAL",
            "name": "Adalend",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EyMzhwdjAMLKsKR1domLdAmTWfc4PWPBPZRuzouvCWiQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/ADALend",
                "twitter": "https://twitter.com/ADAlend_finance",
                "website": "https://adalend.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "6PkHPXrfdszddND1Bz6jHaekBTyZr1BAqxNWQT9gCcUv",
            "symbol": "SLMN-WL-TS",
            "name": "Solmon WL Token Trainers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jwurrow/solmon/main/solmon-token.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "website": "https://solmon.game/"
            }
        },
        {
            "chainId": 101,
            "address": "CrKj3wahgHc76yUE5gL3WLbRW5g5rK88vAtUqRcsSCnX",
            "symbol": "TNC",
            "name": "Tonic",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CrKj3wahgHc76yUE5gL3WLbRW5g5rK88vAtUqRcsSCnX/logo.png",
            "extensions": {
                "discord": "https://discord.com/invite/cyberpharmacy",
                "twitter": "https://twitter.com/cyber_pharmacy",
                "website": "https://cyberpharmacy.io"
            }
        },
        {
            "chainId": 101,
            "address": "7TSMDTH15FddZfZHX1pPuDynM3N62BCHbHtLvRNvmSmL",
            "symbol": "ANGEL",
            "name": "Fallen Angel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TSMDTH15FddZfZHX1pPuDynM3N62BCHbHtLvRNvmSmL/logo.png",
            "tags": [
                "nft-marketplace"
            ],
            "extensions": {
                "twitter": "https://twitter.com/fallenangelsxyz",
                "website": "http://fallenangels.com/"
            }
        },
        {
            "chainId": 101,
            "address": "39wLCg3JHBC52Q44Gd1SkZc5wtm7cWf6MCLhJPcH9rLH",
            "symbol": "OACZ",
            "name": "Oasis Civilization Whitelist Token",
            "decimals": 0,
            "logoURI": "https://media.discordapp.net/attachments/926535226820276264/970657108481687562/unknown.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TATBsxWJsKQ92VZuufKcibXuZp6npDdUQeZxPQy9Mug",
            "symbol": "TAT",
            "name": "Trippin Ape Tribe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bitcoinblack482/logo/main/tat.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/trippinapetribe",
                "twitter": "https://twitter.com/TrippinApeNFT",
                "website": "https://www.trippinapetribe.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "3nG1CTgfiCBMCwzhEwtfYaXGECXWGbc5pm2kdn5xAsGS",
            "symbol": "PBTKN",
            "name": "Peanut Bean",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/qwdavis/crypto2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4CqGRYLzvj3ibufGJdLmdK2CzvDA5k82NVav4a5EKCBG",
            "symbol": "COCU",
            "name": "Cocucoin",
            "decimals": 9,
            "logoURI": "https://cocucoin.com/logo.png",
            "tags": [
                "social-token",
                "nft",
                "swap",
                "trade",
                "finance"
            ],
            "extensions": {
                "website": "https://cocucoin.com/"
            }
        },
        {
            "chainId": 101,
            "address": "WLwanMkvjXzJCogizrQgZc8MuZ4aSXsZ2KXm8aaZ6FY",
            "symbol": "MIDHWL",
            "name": "MOUSE IN DA HOUSE WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLwanMkvjXzJCogizrQgZc8MuZ4aSXsZ2KXm8aaZ6FY/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "ogRmfJLMDyS1nLgCmQYNKJJVa7NgfgUR87yuTbUeCUz",
            "symbol": "MIDHOG",
            "name": "MOUSE IN DA HOUSE OG TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLwanMkvjXzJCogizrQgZc8MuZ4aSXsZ2KXm8aaZ6FY/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "3hCHhVwAR6ySDCyHPjy6iuny6Actvhz5ZX4WQUq2p9G4",
            "symbol": "ART",
            "name": "AUREUS Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3hCHhVwAR6ySDCyHPjy6iuny6Actvhz5ZX4WQUq2p9G4/logo.png",
            "tags": [
                "social-token",
                "ART"
            ]
        },
        {
            "chainId": 101,
            "address": "4DDaY3VGL1URApnehqbRtseHBCSWnC1kMmbbFyvWxheQ",
            "symbol": "WKT",
            "name": "Walker Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4DDaY3VGL1URApnehqbRtseHBCSWnC1kMmbbFyvWxheQ/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GHmAAkHZxGvGno3jRehkC4CjGJh5qgxLKHF5tPqQLPg1",
            "symbol": "USDr",
            "name": "Ratio Finance USD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHmAAkHZxGvGno3jRehkC4CjGJh5qgxLKHF5tPqQLPg1/logo.png",
            "extensions": {
                "telegram": "https://t.me/ratiofinance",
                "twitter": "https://twitter.com/RatioFinance",
                "website": "https://ratio.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "6aFtCUxRMekeFphwM9xJ526UohPJbYU6zVxXWm1X81WD",
            "symbol": "SWLT",
            "name": "Sloth WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6aFtCUxRMekeFphwM9xJ526UohPJbYU6zVxXWm1X81WD/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TKDrcm3n4mfXFfPKZoLp5soRSdFQSmyWLdomdKL3ktU",
            "symbol": "TENKAI",
            "name": "Tenkai Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TKDrcm3n4mfXFfPKZoLp5soRSdFQSmyWLdomdKL3ktU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/PfrZe9hPcP",
                "serumV3Usdc": "DnBj2xqxwjDYCNnegpFuaFqiYktvZpz9a7gvdbodjTU",
                "twitter": "https://twitter.com/TenkaiApes",
                "website": "https://tenkaiapes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8UVGdzdXc76z8zoH3Fd4KupEVFCSWDGiStRYbLbopQhe",
            "symbol": "QNUR",
            "name": "Qnur Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kai-ckul/qnur/main/qnur.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EdUE6yWLV4tnTZvBKasHAsmbTh8Ed5vWkY4WXQbux8yk",
            "symbol": "BLD",
            "name": "DB Blood",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdUE6yWLV4tnTZvBKasHAsmbTh8Ed5vWkY4WXQbux8yk/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EhecatlUniverse"
            }
        },
        {
            "chainId": 101,
            "address": "hcxCW1FXVyXbDC1B8Gtv4PvrxP3ANYtBAXJtRzYow6M",
            "symbol": "MILK",
            "name": "SolanaBossBabies Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/husky-696/Public-images/758a44b7ec793855303aef7d945dcd33ad5dd6c5/logo.png.png?token=AYKN4WAHS4SGW6KWG35KAF3COGA72",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WL3JbsR9VGjFP7maGkAC6LfS9s3JFuCWeU5JAB9Gi4G",
            "symbol": "MIDHWLT",
            "name": "MOUS IN DA HOUS WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLwanMkvjXzJCogizrQgZc8MuZ4aSXsZ2KXm8aaZ6FY/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "ogtWYbiHXF32wdTvhKv8CfHn3FThf2MfhCPi6Y3Fc1G",
            "symbol": "MIDHOGT",
            "name": "MOUS IN DA HOUS OG TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLwanMkvjXzJCogizrQgZc8MuZ4aSXsZ2KXm8aaZ6FY/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "avsfwCwXjMreP2eg5z5cJBxL5bdULJoU8fb9ZGYGzXM",
            "symbol": "AVTR",
            "name": "Solavatar Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/avsfwCwXjMreP2eg5z5cJBxL5bdULJoU8fb9ZGYGzXM/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solavatar3"
            }
        },
        {
            "chainId": 101,
            "address": "JCexpmKnwUQXNPr1Z32UgxhUBzeyCxqBEGDMALqBNs32",
            "symbol": "1337",
            "name": "1337",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/1337Crypto/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7U3HPA9rYUQmfF52oFE3F4h7r3S8VVM28BUK9UzYe1PN",
            "symbol": "OUD",
            "name": "OUD",
            "decimals": 9,
            "logoURI": "https://imgur.com/YqxwgC4.png"
        },
        {
            "chainId": 101,
            "address": "kiNG3f22n1i4pbXrxJz1Zviwe3FNrpS5ZqwfYTf2N33",
            "symbol": "KoDS",
            "name": "kings of da Street WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kiNG3f22n1i4pbXrxJz1Zviwe3FNrpS5ZqwfYTf2N33/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kingsofdastreet"
            }
        },
        {
            "chainId": 101,
            "address": "58xx9DVzXCic3GhKwBWW5mpBg7ABkjVFpLpqU2DgfrjU",
            "symbol": "MRBOT",
            "name": "Mrbot Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58xx9DVzXCic3GhKwBWW5mpBg7ABkjVFpLpqU2DgfrjU/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/zZttYaVEQ9",
                "twitter": "https://twitter.com/mrbotnft",
                "website": "https://mrbot.tech/"
            }
        },
        {
            "chainId": 101,
            "address": "5nmzJr8bu1Ahgc5nX46ku4Z3dJqdmh68DG1jLmwBHHsh",
            "symbol": "BONF",
            "name": "Bonfire Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bonfiretkn/crypto/main/bonfire-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6PMHW4wgQhmnefMpkJSxJ3UAY752zeDyHy18oZh23iyU",
            "symbol": "GRC",
            "name": "GRCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6PMHW4wgQhmnefMpkJSxJ3UAY752zeDyHy18oZh23iyU/GRCoin.png",
            "tags": [
                "IP-currency"
            ]
        },
        {
            "chainId": 101,
            "address": "4pLbiXeTym6XNkPWUw88V5JKYFErJa9bX9tV9hdHtdtN",
            "symbol": "SBWL",
            "name": "Solana Brains Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pLbiXeTym6XNkPWUw88V5JKYFErJa9bX9tV9hdHtdtN/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ],
            "extensions": {
                "website": "https://solbrains.com"
            }
        },
        {
            "chainId": 101,
            "address": "Hb7XvrJS7G4CrauV7w1N8hkVSECHNNjwXYvXgVHsAFUJ",
            "symbol": "READ",
            "name": "ReadON Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EEGG1MLcWwBQVNwmhoNxMdTjVuuRX24o7Gym2MzQmRST/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://readon.me"
            }
        },
        {
            "chainId": 101,
            "address": "9DpmWKvVZCYAWBknkLYhKYQGeB9GoEeoXoZvLkw57xzX",
            "symbol": "WCFC",
            "name": "world cup fans coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9DpmWKvVZCYAWBknkLYhKYQGeB9GoEeoXoZvLkw57xzX/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HuNcdrQ47VnUWLcujhhKwXaQQTYTBSsSguQNQgUexWL8",
            "symbol": "YUNG",
            "name": "Yung Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/h3ll0x/yungtokenlogo/main/yungimg.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://yungmoney.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5osL1DnezspfmAd2Qn1PXsQniZGrzRXrxUNCPXbbrr78",
            "symbol": "DON",
            "name": "Dongri Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5osL1DnezspfmAd2Qn1PXsQniZGrzRXrxUNCPXbbrr78/logo.png",
            "extensions": {
                "website": "https://dongri.eth.limo"
            }
        },
        {
            "chainId": 103,
            "address": "BtX7BuKkNKPMWH7QGyvNMQS7VsQBABEL69P6CMMjQzTu",
            "symbol": "KGNFT",
            "name": "KGNFToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtX7BuKkNKPMWH7QGyvNMQS7VsQBABEL69P6CMMjQzTu/logo.png",
            "tags": [
                "whitelist-token",
                "nfts"
            ]
        },
        {
            "chainId": 102,
            "address": "9jtWhaRL9wysgWjXPTBBzyPa7CNP9zMMiDZR87tgVNDR",
            "symbol": "USDT",
            "name": "xldUSDT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "tether"
            }
        },
        {
            "chainId": 102,
            "address": "Er2hR74LfFa7NJiVUnSfUg2sgu45Bq1pzZyHzPLXG1AT",
            "symbol": "SLP",
            "name": "xldSLP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hpngEp1v3CXpeKB81Gw4sv7YvwUVRKvY3SGag9ND8Q4/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "smooth-love-potion"
            }
        },
        {
            "chainId": 102,
            "address": "8LyHLBW4VXDziGRmsH9694TRYsf66BH4HemnnrN29tyZ",
            "symbol": "DAI",
            "name": "xldDai",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o/logo.png",
            "tags": [
                "wrapped"
            ],
            "extensions": {
                "coingeckoId": "dai"
            }
        },
        {
            "chainId": 103,
            "address": "HMnX3nmicC3Te2xChzKS1rwwfBqL9TVjNRwzLCvd344C",
            "symbol": "humanx",
            "name": "HumanX Token",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/humanX/humanx.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "This token is used to exchange message with any wallet addresse",
                "imageUrl": "https://bafybeicghunoek4cafdi7snklb2dilxxkgh3olbmd4eazmlpsjzcjbg5te.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/humanX/#humanx",
                "whitepaper": "https://gateway.ipfs.io/ipns/Qmad1CJThM8zYym23da8s89MFw8dcuSdRmhyySTgtNVbPu/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "7xyUV7Hev7W6m21DWcjBE8ArZEfPtyFqztcKbTHkQC59",
            "symbol": "DRA",
            "name": "Dragnarok",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/justclumsyguy/dragnaroklogo/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metadragnarok",
                "website": "https://metadragnarok.com"
            }
        },
        {
            "chainId": 101,
            "address": "FfLSQYsLDrDj7viJxb8hFLyVbPEjd1pxctrdZj34WYwQ",
            "symbol": "MSFRG",
            "name": "Mistery Frog token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FfLSQYsLDrDj7viJxb8hFLyVbPEjd1pxctrdZj34WYwQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "CQz5Nauawfq5fRBu37k8h6e4TteAFj1LwXoq8UuHoQfq",
            "symbol": "CFWL",
            "name": "Crypto Frogz WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CQz5Nauawfq5fRBu37k8h6e4TteAFj1LwXoq8UuHoQfq/logo.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GzHyjtEn2iZ8aQdRUGS54U3TXAQNP2egcKrAxwEcVhPT",
            "symbol": "S0L",
            "name": "S0lana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/peawis/success/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Jme67iCWHqMKifUkiXvvC862Ek7TFTPWshpgnV52saP",
            "symbol": "HNDT",
            "name": "HAND (Devnet)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Jme67iCWHqMKifUkiXvvC862Ek7TFTPWshpgnV52saP/HandTLog.png",
            "tags": [
                "devnet"
            ]
        },
        {
            "chainId": 101,
            "address": "22KASgAQksz3TFvHzFy93aQvh6MWAj9UsUrnzsbfG5wh",
            "symbol": "TWL",
            "name": "Track WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/22KASgAQksz3TFvHzFy93aQvh6MWAj9UsUrnzsbfG5wh/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dogetrack",
                "twitter": "https://twitter.com/theDogeTrack",
                "website": "https://dogetrack.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BooSt1WnnkqvGePCsQjiS7yF8XEznxkwDPCxupvpUMVC",
            "symbol": "BOOST",
            "name": "Boost",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BooSt1WnnkqvGePCsQjiS7yF8XEznxkwDPCxupvpUMVC/logo.png"
        },
        {
            "chainId": 101,
            "address": "7dCG3R5sCLnEySHkkSJxHK3MfEKEbeKxgcuqRnkmTysU",
            "symbol": "SOUL",
            "name": "Dead Souls - SOUL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LinYu1992/dead_souls_token/main/Coin.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeadSoulsDAO"
            }
        },
        {
            "chainId": 101,
            "address": "2znADNrRYvai8gfej1mcV2YbNvehY6cBSALvuWnuvNPN",
            "symbol": "SIN",
            "name": "S7N Coin",
            "decimals": 4,
            "logoURI": "https://bafybeib2znxf6whdtq354t6jsyejs7nlnevqd2pjxatr5ft2tgsgyrazb4.ipfs.nftstorage.link",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SevenDeadly5ins",
                "website": "https://www.sevendeadlysins.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8cwkCtsAa51fdDxknVaAnFqBAsA6975DmCggf2LnThi6",
            "symbol": "RAT",
            "name": "Ratio",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TATOWASTAKEN/crypto/main/crypto%20logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hj9V5W4R9BxKUJYC36X5Biayv6FwZz5mmfV3JY4LxF8z",
            "symbol": "MELLOWWL",
            "name": "Mellowmen WL Token",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/38QD4yg.png"
        },
        {
            "chainId": 101,
            "address": "FvmtrsisrSCMnWHjrTeBFfMrWLXpfYfsZqF8RXcPffiS",
            "symbol": "SHNL",
            "name": "Shannel Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Thesisshahrdad/Shannel-Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "aYZPYgohjK6LYM8o1v6pnr3ZinhuRzSHd6TRDVDUBkK",
            "symbol": "BTL",
            "name": "BitLegacy Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aYZPYgohjK6LYM8o1v6pnr3ZinhuRzSHd6TRDVDUBkK/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/bitlegacytoken",
                "website": "https://bitlegacytoken.com"
            }
        },
        {
            "chainId": 101,
            "address": "7seYZoh8kAG7akPrVAB6yQj9A9AvXXaL75r1boiQYWo2",
            "symbol": "MTCA",
            "name": "MITICA",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/pizzamitica/mitica/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.pizza-mitica.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6EKUZutUq3ACUMpqmWVbzaNwyQmofZ3N3J8euy6NoFka",
            "symbol": "DENJI",
            "name": "Denji Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6EKUZutUq3ACUMpqmWVbzaNwyQmofZ3N3J8euy6NoFka/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MakimaNFT"
            }
        },
        {
            "chainId": 101,
            "address": "3hEN3maDrmuoj89EDV452RRoRqDfZeeR4auoRs5mXF9u",
            "symbol": "FLAME",
            "name": "FLAME",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3hEN3maDrmuoj89EDV452RRoRqDfZeeR4auoRs5mXF9u/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Offical Daring Dragons Phoenix Token, powering the DDP universe",
                "twitter": "https://twitter.com/Daring_Dragons"
            }
        },
        {
            "chainId": 101,
            "address": "FASohke1j5eRAwQBMqTs3W9d3N54xFqNAg3nZRmGKQdk",
            "symbol": "AQUA",
            "name": "AQUA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FASohke1j5eRAwQBMqTs3W9d3N54xFqNAg3nZRmGKQdk/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CoralTribeNFT"
            }
        },
        {
            "chainId": 101,
            "address": "8rgGrnETmAbMCmgAWgUA67ydf7CzMyd5pErGZiHvWxwN",
            "symbol": "BSHD",
            "name": "Bushido Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/stan14100/token-icon/main/bshd-token.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/bushidosociety",
                "instagram": "https://www.instagram.com/bushido_society",
                "twitter": "https://twitter.com/Bushido_Society",
                "website": "https://bushidosociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "SMKdxDRkGU4NVhi36cXbYMzCGhkj48VVsWHiybAQDwC",
            "symbol": "SMKWL",
            "name": "Smokeville WL",
            "decimals": 0,
            "logoURI": "https://shmokeville.com/logo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Smokeville WL Token",
                "discord": "https://discord.gg/qrucgaZUvX",
                "twitter": "https://twitter.com/Smokevillesol",
                "website": "https://shmokeville.com"
            }
        },
        {
            "chainId": 101,
            "address": "CEZCH6PZjUw8RXfhsS8vSo4wGeoMMhAyzaFWHpXp3tRn",
            "symbol": "GGADTT",
            "name": "GGA DAO Test Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CEZCH6PZjUw8RXfhsS8vSo4wGeoMMhAyzaFWHpXp3tRn/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "xoy8fyDQ1iCEyUPdKf43ezKB7SCX9F69uqAHTFR5mcq",
            "symbol": "DENJI",
            "name": "Denji Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xoy8fyDQ1iCEyUPdKf43ezKB7SCX9F69uqAHTFR5mcq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MakimaNFT"
            }
        },
        {
            "chainId": 101,
            "address": "4hqG3UyaWVJ74wWhBrCKcoXBmpPiXJE7pj7m9GS7Z3mX",
            "symbol": "SS",
            "name": "Solana Scouts",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4hqG3UyaWVJ74wWhBrCKcoXBmpPiXJE7pj7m9GS7Z3mX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solanascouts",
                "twitter": "https://twitter.com/SolanaScouts",
                "website": "https://solanascouts.io/"
            }
        },
        {
            "chainId": 103,
            "address": "EoC7RvPmwbXL9nAmCwfKZTd7aTiC9VzTS6J4j8hLNCfV",
            "symbol": "eo-coin",
            "name": "Eco Organic Coin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@master/eoc/eo-coin.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "The most Sustainable NFT with on 61mg CO2eq",
                "imageUrl": "https://bafybeigijghqbqzju7ldhacu6n3vst7wutwbv7fuvtpaeh63kzjxsprdpa.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/eoc/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmVYhabrNidUzhLmDpbB28JxK12mUbagDuEj8VnwdMugqr/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "G1izd6XghBaCRw3gS2sRwD2JJdmAnFQWinnfYCvSzfuZ",
            "symbol": "$GLIZZY",
            "name": "GLIZZY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G1izd6XghBaCRw3gS2sRwD2JJdmAnFQWinnfYCvSzfuZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GlizzyRoyale"
            }
        },
        {
            "chainId": 101,
            "address": "6piwLtPi6sYjvE1reSEpmAXfcJqJtyGp3fahhEU7VF2C",
            "symbol": "SKZ",
            "name": "The SNKRZ Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6piwLtPi6sYjvE1reSEpmAXfcJqJtyGp3fahhEU7VF2C/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "A Klaytn-based Walk-To-Earn social fitness platform",
                "discord": "https://discord.com/invite/thesnkrz",
                "twitter": "https://twitter.com/theSNKRZ",
                "website": "https://www.thesnkrz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7zbt7qwXUdEnmpLfFdN2M749aTRT1aKcoob5SpRAsRgU",
            "symbol": "CLX",
            "name": "RightClickable",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zbt7qwXUdEnmpLfFdN2M749aTRT1aKcoob5SpRAsRgU/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/rightclickable_",
                "website": "https://rightclickable.com"
            }
        },
        {
            "chainId": 101,
            "address": "EB6YDsfxseRJuJjCasDBDEyVjXrUchYWbwskhXg9N4yQ",
            "symbol": "SCK",
            "name": "Konpeito Shinobi Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/konpeitoshinobi/KonpeitoShinobiToken/main/1.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Konpeito Shinobi is a collection of unique Shinobi's Warrior living on the Solana blockchain",
                "discord": "https://discord.gg/HjASJGv9rf",
                "twitter": "https://twitter.com/konpeitoshinobi",
                "website": "https://konpeitoshinobi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FreeGzaYADRmRJSEUXJSodjUwGhNuZb72kQsNhBhja4X",
            "symbol": "FSHIN",
            "name": "Free Shinobi Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FreeGzaYADRmRJSEUXJSodjUwGhNuZb72kQsNhBhja4X/logo.png"
        },
        {
            "chainId": 101,
            "address": "8nHuHov45Ji77dB6qeCty5DEYSLpm62ntdc5JvdqJ215",
            "symbol": "TMAX",
            "name": "TendieMax",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OakmontLabs/TendieMaxLogo/main/TendieMaxLogo.png",
            "tags": [
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Pm6hMSp4XwCp2tXUBBby2bNqXTDvt7N85PRAoVJ9JtW",
            "symbol": "FEN",
            "name": "FenCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fennar01/crypto/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hiw1D95aNJ2CaooFf1arD5W56ZpuDbxLcvzWE72fDBgN",
            "symbol": "WLFA",
            "name": "WL Faviont",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/faviont/marketplace/main/logo-wl.png",
            "tags": [
                "whitelist",
                "utility-token"
            ],
            "extensions": {
                "description": "Each token gives you access to buy one NFT on https://faviont.io/",
                "twitter": "https://twitter.com/FaviontOfficial",
                "website": "https://faviont.io/"
            }
        },
        {
            "chainId": 101,
            "address": "A7e46NH9UMeCARWtNNoVSkR55DxEaAgQGiXLYwxLW36z",
            "symbol": "PAWR",
            "name": "Pet Solciety",
            "decimals": 2,
            "logoURI": "https://bafkreiefof3cjzgsd2xvfcijcf3r3az2rgycad7ycpveghjlmo6r5surh4.ipfs.dweb.link/?ext=png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/R5jF7rd5cg",
                "twitter": "https://twitter.com/PetSolciety",
                "website": "https://www.petsolciety.com"
            }
        },
        {
            "chainId": 101,
            "address": "EKMJyNS156WWC812B4W3Vvc1Zn4moRHWMa6viY9R2ft5",
            "symbol": "ERA$",
            "name": "Age of DeFi",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EKMJyNS156WWC812B4W3Vvc1Zn4moRHWMa6viY9R2ft5/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AgeofDeFiNFT",
                "website": "https://ageofdefi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7XBMMSWMfXwshMWoPj7CL2WxudW5dY6UZCUVc3dDjaEG",
            "symbol": "AGE$",
            "name": "Age of Defi coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7XBMMSWMfXwshMWoPj7CL2WxudW5dY6UZCUVc3dDjaEG/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AgeofDeFiNFT",
                "website": "https://ageofdefi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DkPn7EwX229HDWL9suY9o2GN6iov2pMpXhvujkUjav6A",
            "symbol": "SOLRS",
            "name": "Solana Rays",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DkPn7EwX229HDWL9suY9o2GN6iov2pMpXhvujkUjav6A/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DZa5FzdNsNQBYvsMTQLK2V3pVgGT1jY3RvM1LkHFmWJX",
            "symbol": "SME",
            "name": "Smeme token from duongkhongam on Devnet",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmPnMzkxkakrq251duZuUJjknYoK3dZcAsKCPmj3HTZhMB",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 103,
            "address": "8TvE458haYwqiDiFDFezMf4591SWX7E8LcjWKeDTEbZB",
            "symbol": "FTT",
            "name": "Fikapark Team Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8TvE458haYwqiDiFDFezMf4591SWX7E8LcjWKeDTEbZB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "A Fikapark Team Token for team member",
                "website": "https://www.fikapark.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MouSJPowxJoEWzxPnv9b27KzUXbfVbHxyqKDnHrGSLB",
            "symbol": "MIDH",
            "name": "MousInDaHous WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MouSJPowxJoEWzxPnv9b27KzUXbfVbHxyqKDnHrGSLB/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MousInDaHous"
            }
        },
        {
            "chainId": 101,
            "address": "72m6ir6cDdPzfaFJZzdP1r5nyMxrnYyaHtbqf1zHUjq7",
            "symbol": "BCANDY",
            "name": "BCANDY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72m6ir6cDdPzfaFJZzdP1r5nyMxrnYyaHtbqf1zHUjq7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ve4UA2Xc",
                "twitter": "https://twitter.com/mirlnft",
                "website": "https://mirl.club/"
            }
        },
        {
            "chainId": 103,
            "address": "J2HoBAy2v9GkpVaVmLd64FXPRgVYBankNJG4znGjtk6Y",
            "symbol": "LxQ",
            "name": "Lx Q Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J2HoBAy2v9GkpVaVmLd64FXPRgVYBankNJG4znGjtk6Y/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9v3MJ7U7CquqB6jB9dC9FXkrboSqyD28eL7szUyhRXkG",
            "symbol": "$WILD",
            "name": "WILD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9v3MJ7U7CquqB6jB9dC9FXkrboSqyD28eL7szUyhRXkG/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3BkAHG3m",
                "twitter": "https://twitter.com/Arkanauts",
                "website": "https://www.arkanauts.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CasBBiWGCNebBzG4cQSkM8WrpgwzDXN88eNAKRz5Rsqv",
            "symbol": "CHSSO",
            "name": "CHESSO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gotthelucas/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "G7VgKoTadSDrMaQ85xQhh1RFdBhpWXrPzSpV99L3DTL2",
            "symbol": "PICS",
            "name": "Presence Pictures Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/edmundleewen/presenceCoin/main/appImage180.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ES4isqfcWbeBZwtH2eLRjk5oUzaMyZZrW2uhDM6yde6h",
            "symbol": "GMT",
            "name": "Sugar Rush WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ES4isqfcWbeBZwtH2eLRjk5oUzaMyZZrW2uhDM6yde6h/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GoofiezNFT"
            }
        },
        {
            "chainId": 101,
            "address": "2w5pRDS18kdTHvFkf8QTFQma8GgHQRgKHNE9psm9Ntyp",
            "symbol": "VORD",
            "name": "Vordium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Vordium/VORD-Engine/main/Assets/Mainnet/vordlogo1.png",
            "tags": [
                "utility-token",
                "Network-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6SUmHppQdVY44VXh7svyb9jhWJbiQSwvKQBW4oegmx3m",
            "symbol": "RSN",
            "name": "Resin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/h3ll0x/resintokenimage/main/resin.gif",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BrainStoners",
                "website": "https://brainstoners.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9Uhcb3LFzC17PE6FYRLFEkfWApHzi44M78mMjWQfyMQq",
            "symbol": "CLEN",
            "name": "Cleen Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jumanaanimation2/cleencoin/main/cleencoin.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8uHgyhcpx5Dje7xssJUfka1RRhAsFWtiVxGf6wPoC7jp",
            "symbol": "DLC",
            "name": "Crypto Dealer Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8uHgyhcpx5Dje7xssJUfka1RRhAsFWtiVxGf6wPoC7jp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://cryptodealer.app"
            }
        },
        {
            "chainId": 101,
            "address": "4W6mt3HgYzauN1G2uaTQkno47wz49LwjhoEBhFtLFyoy",
            "symbol": "RICHEE",
            "name": "Richee",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw/logo.png"
        },
        {
            "chainId": 101,
            "address": "GoWJXzxT8ADHp3w42mzCzBc61kMApvSL4e8ck8TxMFXY",
            "symbol": "$GOJIT",
            "name": "GOJIT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoWJXzxT8ADHp3w42mzCzBc61kMApvSL4e8ck8TxMFXY/logo.png"
        },
        {
            "chainId": 101,
            "address": "86ywhGx2vMPUQht6VbxNA6T6WxzGLSYJsmMptgGTMcy6",
            "symbol": "PILLZ",
            "name": "PILLZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DopeHeadzNFT/Logo/main/Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DopeHeadzNFT",
                "website": "https://dopeheadz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HvMpFPhGaD3KTXizY1bhxcSgzpJazZ2JmxdhwkLWYyNS",
            "symbol": "CTP",
            "name": "Certified Plant Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/iviyanStoyanov/crypto/main/IMG_1446.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EC7T3ViyVpFEcBoEWWH9jdk1vypsr4DpwguRixg9Cjbr",
            "symbol": "ALTRNE",
            "name": "altug super token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EC7T3ViyVpFEcBoEWWH9jdk1vypsr4DpwguRixg9Cjbr/logo.png"
        },
        {
            "chainId": 101,
            "address": "T18azEDeWpKw8GHwFaQV5UAt9hnH6WqzHhGaS4A27nt",
            "symbol": "CBNX",
            "name": "Carbonix",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/josepastor28/carbonix/main/logo.png",
            "tags": [
                "carbon-offset"
            ]
        },
        {
            "chainId": 101,
            "address": "6cpdEa1qKx62RefDAWxBpyQDBR84kkeS6EAx6FuMdxt9",
            "symbol": "DGT",
            "name": "Da0 Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cpdEa1qKx62RefDAWxBpyQDBR84kkeS6EAx6FuMdxt9/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://da0.vercel.app/"
            }
        },
        {
            "chainId": 101,
            "address": "ECDy7WSQvwdg3XLoTacGPmAEeA9TqLmGGZKb612Vy3UH",
            "symbol": "MFC",
            "name": "MattiFinanceCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mammimaisteri/MattiFinanceCoin/main/logo.png",
            "tags": [
                "social-token",
                "financial-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mattifinance",
                "website": "https://mattifinance.com/"
            }
        },
        {
            "chainId": 101,
            "address": "kidsYgCH9dDZWgVxTDJRQEHXVCjEcqUpxf4P2i94UgK",
            "symbol": "CKWL",
            "name": "Cartonkids WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kidsYgCH9dDZWgVxTDJRQEHXVCjEcqUpxf4P2i94UgK/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CartonkidsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "CtkUPWL1v3QrtwNhvRCEafs6Qz1ACatGtVPrMrNiY6qK",
            "symbol": "MOVE",
            "name": "Move-n-Earn",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CtkUPWL1v3QrtwNhvRCEafs6Qz1ACatGtVPrMrNiY6qK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/profile.php?id=100081109356224",
                "linkedin": "https://www.linkedin.com/company/move-n-earn/",
                "telegram": "https://t.me/movenearn",
                "twitter": "https://twitter.com/move_nearn",
                "website": "https://movenearn.com",
                "whitepaper": "https://movenearn.com/whitepaper/"
            }
        },
        {
            "chainId": 101,
            "address": "EEzrQRxj7f2m7f3tw45w6PKF1xrT5e9ed2YKZCVNNfxd",
            "symbol": "BUD",
            "name": "Buddy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/utsablohani/crypto/main/profile.jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8qxUJ19Hn2u7rXvtdjAogvVmVRSqGiy2CQn1hVx2WtS4",
            "symbol": "BAGz",
            "name": "BAGz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8qxUJ19Hn2u7rXvtdjAogvVmVRSqGiy2CQn1hVx2WtS4/bagz.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LilDirtbagzNFT",
                "website": "https://lildirtbagz.com"
            }
        },
        {
            "chainId": 101,
            "address": "8ZSJTmL42LgTrC1qY7AZQkLYT1EZquQA1cA3ze4bSjvq",
            "symbol": "NEON",
            "name": "NEON EVM",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ZSJTmL42LgTrC1qY7AZQkLYT1EZquQA1cA3ze4bSjvq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Neon is an Ethereum Virtual Machine with the scalability and liquidity of Solana",
                "telegram": "https://t.me/NeonLabsCommunity",
                "twitter": "https://twitter.com/neonlabsorg",
                "website": "https://neon-labs.org/",
                "whitepaper": "https://docs.neon-labs.org/"
            }
        },
        {
            "chainId": 101,
            "address": "4L9aFEye7jYiPFywFJh33ad37owHwCETLgrLPxziSMoK",
            "symbol": "MKYWL",
            "name": "Mafia Monkey Club",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4L9aFEye7jYiPFywFJh33ad37owHwCETLgrLPxziSMoK/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4XR4VkM75Armv6czmd89H7fTsf6evAGoawUgqWQUoyxF",
            "symbol": "RSTK",
            "name": "Rose Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Suchenski1/RoseToken/082a56b6d2ff5db3798089fc3e7c7aa68ddf34af/RoseToken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SPSzWFd2YpwbifRqoow76N31RcnFPH17vmq6AGCXt3C",
            "symbol": "SPARK",
            "name": "Spark Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPSzWFd2YpwbifRqoow76N31RcnFPH17vmq6AGCXt3C/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/XTZRas4VN4",
                "twitter": "https://twitter.com/StonedGnomes",
                "website": "https://www.stonedgnomes.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ASqpLQXayEH8JT1dFcamUY7RQPPvJhQdwPPUduEfAdKX",
            "symbol": "IBHC",
            "name": "IBHC WhiteList Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Felibrain/IBHC/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "MELLiWTDA5YN82arABoKMJZtc968tHeS1euQvM2qSPM",
            "symbol": "EV1",
            "name": "MELLOW MEN WL TOKEN OFFICIAL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MELLiWTDA5YN82arABoKMJZtc968tHeS1euQvM2qSPM/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MellowMenNFT",
                "website": "https://mellowmen.io/"
            }
        },
        {
            "chainId": 101,
            "address": "84EM7P6QLVZvfnzCiVLzMJqUX5rLufenZHAy13ad67dN",
            "symbol": "POKR",
            "name": "POKERSTAR",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theshortietate/crypto/main/pokrlogo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "7rbvUFP8s5eyL9ddi3bDTancoC8NQx7Z1iQg76u1JaSm",
            "symbol": "USDC_v1",
            "name": "USDC (devnet)",
            "decimals": 6,
            "logoURI": "https://ik.imagekit.io/aupdcdycx/logo_uWzBB6tWc.png",
            "tags": [
                "usdc-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DdDAzP342MFa1NKH4YJE3TsRLvhVGFH1datM9Ea64Jom",
            "symbol": "USDT_v1",
            "name": "USDT (devnet)",
            "decimals": 18,
            "logoURI": "https://ik.imagekit.io/aupdcdycx/logo_AO3hVNihw.png",
            "tags": [
                "usdt-token"
            ]
        },
        {
            "chainId": 103,
            "address": "98xqW2DYKFGPnWV54GEKBEzLvjyaHGJJaB73d1Rrh5EM",
            "symbol": "NEAR_v1",
            "name": "NEAR (devnet)",
            "decimals": 18,
            "logoURI": "https://ik.imagekit.io/aupdcdycx/logo__F7IIL-Z5.png",
            "tags": [
                "near-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9qQsUf9KCzmWGk3yiSRvieTMwkRcAFhYuh6xmUvdnwao",
            "symbol": "LUNA_v1",
            "name": "LUNA(-devnet)",
            "decimals": 9,
            "logoURI": "https://ik.imagekit.io/aupdcdycx/logo_W2dAAG3qK.png",
            "tags": [
                "luna-token"
            ]
        },
        {
            "chainId": 103,
            "address": "D5hPszEtcNxXj6njbjdCiFA18K1DSjFF5gewV7582M9M",
            "symbol": "LINK_v1",
            "name": "LINK(devnet)",
            "decimals": 18,
            "logoURI": "https://ik.imagekit.io/aupdcdycx/logo_5-VOl4q9r.png",
            "tags": [
                "link-token"
            ]
        },
        {
            "chainId": 101,
            "address": "58S4HYzoZyriMNN5oJJWYCcy1YFW7BYZ6SwU9YizCygx",
            "symbol": "KEPL",
            "name": "Kepler Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58S4HYzoZyriMNN5oJJWYCcy1YFW7BYZ6SwU9YizCygx/logo.png",
            "tags": [
                "governance-token",
                "game-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/keplerhomes",
                "medium": "https://medium.com/@KeplerHomes",
                "telegram": "https://t.me/KeplerHomes",
                "twitter": "https://twitter.com/keplerhomes",
                "website": "https://kepler.homes"
            }
        },
        {
            "chainId": 101,
            "address": "G4tJQthj5dvnRhRGd8YxqbhvGNMfVKvHvtWdrovEuhPY",
            "symbol": "GLAXXYSK",
            "name": "Galaxxy Sky Earth",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/GalaxySkyEarth/SkyEarth/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Bws9nKC4RqmZH1kD9sjv5KGUmYsNJs15fBbohHCchWs",
            "symbol": "BIOSPIEL",
            "name": "Biospiel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/okaydokies/bios/main/logo.png",
            "tags": [
                "coin"
            ],
            "extensions": {
                "description": "Biospiel helps you connect with peers in the medical community",
                "facebook": "https://www.facebook.com/biospiel",
                "linkedin": "https://www.linkedin.com/company/biospiel",
                "twitter": "https://twitter.com/biospiel",
                "website": "https://biospiel.com"
            }
        },
        {
            "chainId": 101,
            "address": "34TNFU9QLVraxfnCM3MHgeS3SKQ6RYw2iBogUzXoPbLD",
            "symbol": "SAIGO",
            "name": "Saigo Monkeys",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Tynew240/token-list/1676617702a122d2c52e1bc6302d4da2e279407c/assets/mainnet/34TNFU9QLVraxfnCM3MHgeS3SKQ6RYw2iBogUzXoPbLD/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/saigomonkeys",
                "website": "https://www.saigomonkeys.net/"
            }
        },
        {
            "chainId": 101,
            "address": "CxpDKeFxfmLD8L172vgaMGWsLPS9eK1FaZtf4tqbGi1G",
            "symbol": "TGT",
            "name": "Timee Game Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CxpDKeFxfmLD8L172vgaMGWsLPS9eK1FaZtf4tqbGi1G/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DxMwCmHfLhpUoT1UwmXka8cG7RodgRJN9nic5bLfHaEp",
            "symbol": "TMT",
            "name": "Timee Metaverse Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DxMwCmHfLhpUoT1UwmXka8cG7RodgRJN9nic5bLfHaEp/logo.png",
            "tags": [
                "metaverse-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AE1DgVx1W6QCF2kbMZ1wSZ58DubcrpVJqogtiS4bU7ZP",
            "symbol": "CO2",
            "name": "CARBON CO2 coin for a cleaner planet",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AE1DgVx1W6QCF2kbMZ1wSZ58DubcrpVJqogtiS4bU7ZP/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://www.coinco2.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7ejAbuUUJvM9BsartLv1X8vams6EMgxSsii8nUvADQsJ",
            "symbol": "WNGG",
            "name": "Wingig coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/knagu81/kncrypto/main/wngg.png",
            "tags": [
                "Service-token"
            ]
        },
        {
            "chainId": 103,
            "address": "C5EXuMYCou7Ea4yXbcrdj51RAgvN8i8brLnwcLxyzk7V",
            "symbol": "ASTT",
            "name": "Akvo Sphere Test Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scgitcs/Akvo-Sphere-Test-Token/main/5.jpg",
            "tags": [
                "stablecoin",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CBoa6CMrKRHyxayyP3ws7ysQCmXJnNKcJYkTQi4tu44q",
            "symbol": "HYPE",
            "name": "Hype BA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/IpawnprozNetwork/LogoHype/main/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Hype B.A Official NFT VIP Pass for Kika 5339 every Tuesday x Wed 00:30 ART",
                "website": "https://linktr.ee/hypeba"
            }
        },
        {
            "chainId": 101,
            "address": "t7jeZu9TmKS9AiVcmkmP2TBQNXmapbMNWZCx3B7QiRH",
            "symbol": "OCT",
            "name": "Oeantoken",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/t7jeZu9TmKS9AiVcmkmP2TBQNXmapbMNWZCx3B7QiRH/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "address": "t7jeZu9TmKS9AiVcmkmP2TBQNXmapbMNWZCx3B7QiRH",
                "description": "OCT is a ECO utility token that aims to develop new methods to clean and recycle marine trash and fund other different cleanups allover the world",
                "discord": "https://discord.gg/mryy6CfwPq",
                "website": "https://www.oceantokens.org/"
            }
        },
        {
            "chainId": 101,
            "address": "4RjwajAfPkfDGSygCQ1rSDSUrchCbKgYGF4tuiSY1bCj",
            "symbol": "DMS",
            "name": "Demons Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4RjwajAfPkfDGSygCQ1rSDSUrchCbKgYGF4tuiSY1bCj/DMS.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AYCL5cian1kGAVxhz6PAKit2BWto3wNz7Am98YMq29xU",
            "symbol": "DSK",
            "name": "Dings Kitchen",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AYCL5cian1kGAVxhz6PAKit2BWto3wNz7Am98YMq29xU/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8g3aGbs8gGH1q6smHPaxf4v8JjdDXMyMnW3sQQFSjyPP",
            "symbol": "VYP",
            "name": "VYNQX",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/unknowncac/vynqx/main/V.png",
            "tags": [
                "payment-token",
                "Service-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BvEj2MNMPsUrD4vSk7NHs4TtRcCcJd75Wx5HvVbY4rbK",
            "symbol": "DEDS",
            "name": "Decimus Dynamics Token",
            "decimals": 9,
            "logoURI": "https://github.com/DecimusDynamics/assets/blob/main/decimus-icon-1x1-black.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DecimusDynamic",
                "website": "http://decimusdynamics.io/"
            }
        },
        {
            "chainId": 101,
            "address": "TengNGeocJ5GWmBZC9x8mEnFdau4kuKMQfFZkvBUaL7",
            "symbol": "$TEKASHITEK",
            "name": "TEKASHI TEK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TengNGeocJ5GWmBZC9x8mEnFdau4kuKMQfFZkvBUaL7/logo.png"
        },
        {
            "chainId": 101,
            "address": "DQkEumGtdZ7eWkqMZiUhxyiKgkb4CnuEsroXmCgPkTSE",
            "symbol": "MOOVE",
            "name": "Moove Coin Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DQkEumGtdZ7eWkqMZiUhxyiKgkb4CnuEsroXmCgPkTSE/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://moovecoin.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4WGHyeYYRNsgmCkpMjwr816ZeP6he1m5vaTMJk4vG1Mr",
            "symbol": "SKG",
            "name": "Starz Kitchen",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4WGHyeYYRNsgmCkpMjwr816ZeP6he1m5vaTMJk4vG1Mr/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PUhuAtMHsKavMTwZsLaDeKy2jb7ciETHJP7rhbKLJGY",
            "symbol": "USN",
            "name": "USN (Allbridge from Near)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PUhuAtMHsKavMTwZsLaDeKy2jb7ciETHJP7rhbKLJGY/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "coingeckoId": "usn"
            }
        },
        {
            "chainId": 101,
            "address": "B6pqF66ovQrZB8Nwaq1b4iA99DnTSMAuvLRS2KFp3Nuq",
            "symbol": "TREF",
            "name": "TREFOIL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B6pqF66ovQrZB8Nwaq1b4iA99DnTSMAuvLRS2KFp3Nuq/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "address": "B6pqF66ovQrZB8Nwaq1b4iA99DnTSMAuvLRS2KFp3Nuq",
                "description": "TREF is a utility token that aims to develop new methods to utilize, socialize and fund other different startups in blockchain space allover the world",
                "twitter": "https://twitter.com/TrefoilToken"
            }
        },
        {
            "chainId": 101,
            "address": "2rLTJzSj6J6f4poPAKojS2sbdtRry7b8k4XoEMKPwner",
            "symbol": "PENNY",
            "name": "Penny Auction",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2rLTJzSj6J6f4poPAKojS2sbdtRry7b8k4XoEMKPwner/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://onepenny.vercel.app/"
            }
        },
        {
            "chainId": 101,
            "address": "9i2A1xy9HvnJGU11pAFiFYDsizNW9GwtStzVGGk2LkjX",
            "symbol": "MRCO",
            "name": "Marcio's",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nascar-bit/logo/main/IMAGEM.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8RjdnCsNa3hTq8XPNhP4RMTcEPCrNPJuV7mE3qH4iPit",
            "symbol": "HOOK",
            "name": "HOOKAH",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/333.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GHg6T1Y7df4MQ4oAbNQU3gTMXuQDciChcG3p5zkQCS5G",
            "symbol": "ROWY",
            "name": "Rowy Coin Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHg6T1Y7df4MQ4oAbNQU3gTMXuQDciChcG3p5zkQCS5G/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://rowy.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4jrEk3D6fLgrXAUj9uAffxYJq3bdygfB4KdFrBaEApSy",
            "symbol": "OGREJUICE",
            "name": "Ogre Juice",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4jrEk3D6fLgrXAUj9uAffxYJq3bdygfB4KdFrBaEApSy/logo.png"
        },
        {
            "chainId": 101,
            "address": "DJpta5LoqgCADSeS3WWE28MbtFab8EmQRLVvjHtgRBSK",
            "symbol": "GEEXOOG",
            "name": "GEEXO OG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJpta5LoqgCADSeS3WWE28MbtFab8EmQRLVvjHtgRBSK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/exfYm6uJay",
                "instagram": "https://www.instagram.com/geexolotls/",
                "twitter": "https://twitter.com/geexolotls",
                "website": "https://www.geexolotls.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FetnyCdiczgvMEDf2kw9VCf7QwwEQLiWE2cWApqKGXvm",
            "symbol": "ARTW",
            "name": "Artwork",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FetnyCdiczgvMEDf2kw9VCf7QwwEQLiWE2cWApqKGXvm/Artworklogo.png"
        },
        {
            "chainId": 101,
            "address": "DtafxUvXULXETGtgL2S1Vp6ggfB2nEEM8RtvLSNbGzNS",
            "symbol": "FMAT",
            "name": "Free Mint Animalz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DtafxUvXULXETGtgL2S1Vp6ggfB2nEEM8RtvLSNbGzNS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AnimalzNFT"
            }
        },
        {
            "chainId": 101,
            "address": "FffNDEb4jm5cp3burLawTV17PDXjexuN3MzdDahkKtaf",
            "symbol": "AWLT",
            "name": "Animalz WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FffNDEb4jm5cp3burLawTV17PDXjexuN3MzdDahkKtaf/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AnimalzNFT"
            }
        },
        {
            "chainId": 101,
            "address": "4a6qUaq8cfgDZeEzGLPahdkx2NkrLtJmCjobYxFfCrsQ",
            "symbol": "HLPR",
            "name": "Helper Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Skyvi0/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AzAh47oXdGkLKAXQHMNthjuCHmkRYq8uCh1upB6H5Wic",
            "symbol": "DREAMERWL",
            "name": "DREAMERS Whitelist",
            "decimals": 0,
            "logoURI": "https://dreamers.mypinata.cloud/ipfs/QmQRdFURoJLKa5vVVm2p6s6d3b9dYJL4M4KPn38nKGzgYf",
            "tags": [
                "DREAMERS-Whitelist"
            ]
        },
        {
            "chainId": 101,
            "address": "BBsbWcS6hPBuMwj5MtAerf3LEfQibh9hgXA3n4WA6Dni",
            "symbol": "BB",
            "name": "Baby Catlien Token",
            "decimals": 0,
            "logoURI": "https://media.discordapp.net/attachments/941805628354093118/971189365634113577/517.png?width=670\u0026height=670",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ta8pat4jJYEeeaCGKaDzZpU74EovPsuhM8am56Mazs",
            "symbol": "NECTAR",
            "name": "Nectar Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/agave-com/nectar-token/main/logo-sq.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HU676nHJTxXqBy4NSpbYZWoGExSsDCJRP4vicx5Aiqvb",
            "symbol": "OST",
            "name": "Otherside Talents Token",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/HU676nHJTxXqBy4NSpbYZWoGExSsDCJRP4vicx5Aiqvb/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "TakAzdtCRwFbE6GzHG5eVVSqptRqDtqBkW4AKsyTJ3B",
            "symbol": "TAKA",
            "name": "Takashi Ronin Official WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TakAzdtCRwFbE6GzHG5eVVSqptRqDtqBkW4AKsyTJ3B/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TakashiRonin"
            }
        },
        {
            "chainId": 101,
            "address": "B7mXkkZgn7abwz1A3HnKkb18Y6y18WcbeSkh1DuLMkee",
            "symbol": "fUSD",
            "name": "Synthetic USD (Fabric)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/ec3521b6-b382-4efb-1d16-7b60c75b9100/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://app.fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7nvyNSn5gWrpfE8Qp4uLPUFz7g6uHKVyLNYeHcbq81Aa",
            "symbol": "fXAU",
            "name": "Synthetic Gold (Fabric)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/44d5355a-45e3-4c2d-decb-0cf723959900/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://app.fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FkZefzUKuJpdy3FY5twzsSE4CsvYmN5CwDGFpwGQVTbg",
            "symbol": "fBTC",
            "name": "Synthetic Bitcoin (Fabric)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/ec84365d-c9a9-45b6-afeb-4ca5ca831600/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://app.fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2JkA55iDDkNfhqedZgGWau5rUW8prcdJVhNKQP7cqdZR",
            "symbol": "fEUR",
            "name": "Synthetic Euro (Fabric)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/1ed84129-6754-4668-7e4b-2cf143f5a100/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://app.fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HTPKGJ4tX7hUnQKami9rGDYwekc2eCLokNT2z41EU8gS",
            "symbol": "fGBP",
            "name": "Synthetic British Pound (Fabric)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/b00332ce-5694-4d33-09fc-d523b63bbe00/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://app.fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CVVxVkn5L58sg9eoi1EsoUypcrqPUfUxa8gC51swwMCd",
            "symbol": "fJPY",
            "name": "Synthetic Japanese Yen (Fabric)",
            "decimals": 8,
            "logoURI": "https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/ebe7a85c-369a-4001-44a6-cc64a3f98500/public",
            "tags": [
                "fabric",
                "synthetics"
            ],
            "extensions": {
                "github": "https://github.com/fabric-foundation/",
                "medium": "https://xfabric.medium.com/",
                "twitter": "https://twitter.com/official_fabric",
                "website": "https://app.fsynth.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6",
            "symbol": "USH",
            "name": "Hedge USD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/hedge",
                "serumV3Usdc": "6aRwQtvTcHeRTtGxQRhqViwMF1XPEn271CgGEx3YAyEY",
                "twitter": "https://twitter.com/HedgeLabs",
                "website": "https://www.hedge.so/"
            }
        },
        {
            "chainId": 101,
            "address": "5PmpMzWjraf3kSsGEKtqdUsCoLhptg4yriZ17LKKdBBy",
            "symbol": "HDG",
            "name": "Hedge Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5PmpMzWjraf3kSsGEKtqdUsCoLhptg4yriZ17LKKdBBy/logo.png",
            "tags": [
                "community-token",
                "governance-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/hedge",
                "serumV3Usdc": "7HrUmsVM7poJdjgoteTVzx3VMFyLcAhdR8JMbuFD6VNq",
                "twitter": "https://twitter.com/HedgeLabs",
                "website": "https://www.hedge.so/"
            }
        },
        {
            "chainId": 101,
            "address": "Fe9aWgTcboSvNZsE71fVDw3GRr2x9GFxtpEbu5fnKV81",
            "symbol": "DRT",
            "name": "Datalus Reward Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fe9aWgTcboSvNZsE71fVDw3GRr2x9GFxtpEbu5fnKV81/logo.png",
            "tags": [
                "utility-coin"
            ],
            "extensions": {
                "telegram": "https://t.me/DatalusCorp",
                "twitter": "https://twitter.com/DatalusCorp",
                "website": "https://datalus.us"
            }
        },
        {
            "chainId": 101,
            "address": "BLtjtxkrwN4qLqFEV3VBL5Ko9kWa1GGJzppMe6DedYGQ",
            "symbol": "JRO",
            "name": "JaRo",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLtjtxkrwN4qLqFEV3VBL5Ko9kWa1GGJzppMe6DedYGQ/logo.png",
            "tags": [
                "jaro"
            ],
            "extensions": {
                "website": "https://github.com/JaroToken"
            }
        },
        {
            "chainId": 101,
            "address": "817qdEXjxTMKZesfMJTq2JE9WGbZyYNjGNhUubHswh6V",
            "symbol": "DROPS",
            "name": "UltraDrop Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/817qdEXjxTMKZesfMJTq2JE9WGbZyYNjGNhUubHswh6V/logo.svg",
            "tags": [
                "currency",
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/ultradrop.io",
                "twitter": "https://twitter.com/ultradropio",
                "website": "https://ultradrop.io"
            }
        },
        {
            "chainId": 101,
            "address": "hf6wNMHsJZejxWR5E5GNByPxqrzCSbK2a344v5bJX8r",
            "symbol": "AKHEL",
            "name": "AKHEL OG",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/akhelbey/akhels/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/gGgAPFthTA"
            }
        },
        {
            "chainId": 101,
            "address": "Du57WgpJKWhwoD2UGVcJu6BSsdvmGmKTjNz5vV4yfVSz",
            "symbol": "SQFT",
            "name": "Square Feet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pilotdane/crypto/main/sqftlogo.png",
            "tags": [
                "realestate-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5A8B98xa5ghN5ZwT8Eu52kaFTvkmqofXsT6o7TdUEDNU",
            "symbol": "DCCOIN",
            "name": "Dream City",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5A8B98xa5ghN5ZwT8Eu52kaFTvkmqofXsT6o7TdUEDNU/logo.png"
        },
        {
            "chainId": 101,
            "address": "Cac7ifBsj4C6B1YDmkqut3xwGYKdfdQ2cA9v6smMPuD1",
            "symbol": "H2O",
            "name": "WATER H2O",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cac7ifBsj4C6B1YDmkqut3xwGYKdfdQ2cA9v6smMPuD1/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/H2oToken",
                "website": "https://www.tokenh2o.com/"
            }
        },
        {
            "chainId": 101,
            "address": "XavAGq7fE9SwdbwsvMqmieHyZB6MtiLpagY3A8huHvo",
            "symbol": "YXAV",
            "name": "Yaku Corp l Avatar X WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/XavAGq7fE9SwdbwsvMqmieHyZB6MtiLpagY3A8huHvo/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/YakuCorp",
                "website": "https://www.yakushima.io/"
            }
        },
        {
            "chainId": 101,
            "address": "rHtaCP5RsCKcT8QcbqKB2iJBTMz9g6PipgaQrPWzFSW",
            "symbol": "DFX",
            "name": "Defects",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rHtaCP5RsCKcT8QcbqKB2iJBTMz9g6PipgaQrPWzFSW/dfxlogo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/zgeaF3egxp",
                "twitter": "https://twitter.com/risingdefects",
                "website": "https://risingdefects.com"
            }
        },
        {
            "chainId": 101,
            "address": "ADQwix6UMnhZ13iAd5xQMWFUuw8cJRGj1RioqP3GZebg",
            "symbol": "SIGHT",
            "name": "Sight",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADQwix6UMnhZ13iAd5xQMWFUuw8cJRGj1RioqP3GZebg/logo.png",
            "tags": [
                "utility-coin"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ReptilianReneg"
            }
        },
        {
            "chainId": 101,
            "address": "LLFp7NAT1u3cz2cGZw18kfgfEA9zeBdiDC2uoX6TY2W",
            "symbol": "LLF",
            "name": "Lazy Lizard Fund",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LazyLizardFund/LazyLizardFund/main/LazyLizardFundLogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mruKkteqmx",
                "twitter": "https://twitter.com/LazyLizardFund"
            }
        },
        {
            "chainId": 101,
            "address": "eY8xqkXmTUsZ6FSTYq9hg54c9KvgZzJbxtRnfwofeKW",
            "symbol": "$HIGH",
            "name": "HIGH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/StndKng/tknlogo/main/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KangaroosStoned",
                "website": "https://stonedkangaroos.io/"
            }
        },
        {
            "chainId": 101,
            "address": "tama3h1XJnmS4Lo8P4TZVA3Fjt5utNL6PdbePqx5hnj",
            "symbol": "TMAP",
            "name": "Tamaki Apes Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tama3h1XJnmS4Lo8P4TZVA3Fjt5utNL6PdbePqx5hnj/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TamakiApes",
                "website": "https://tamakiapes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EwmUZLCKPhjs3tdLddX1PFYzbRiPrq8fjgZfkaYVP7Jy",
            "symbol": "LFND",
            "name": "LIT FUND",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/dth5geg.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C5z57xrw52",
                "telegram": "https://t.me/litfund",
                "twitter": "https://twitter.com/litfund_defi",
                "website": "https://lit.fund"
            }
        },
        {
            "chainId": 101,
            "address": "CxQ51bSL1f1HgJZLHZDKZEE6u38DLJfdCzbR6WPLoK6j",
            "symbol": "ANR",
            "name": "Annora",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CxQ51bSL1f1HgJZLHZDKZEE6u38DLJfdCzbR6WPLoK6j/logoanr.png",
            "tags": [
                "game-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8J6ABr7JhByRQ6D9rBTgBdC4D5qf1Q2vzq3mhhzPGxh1",
            "symbol": "SRIP",
            "name": "SoulReapers WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DefiTokens/assets/main/WL.png",
            "extensions": {
                "twitter": "https://twitter.com/solanalostsouls",
                "website": "https://discord.com/invite/efkrna3jbs"
            }
        },
        {
            "chainId": 101,
            "address": "CZw1Dxzn3thg42sKDNZweWjEpjaJpKYN9maAYdHnDvNU",
            "symbol": "SLC",
            "name": "SLOTH INU TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Jono131122/slothcoin/main/transparent-sloth-20.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2mSSUPJYLVGRxvsLcR2B8dwtG6KuYWsGpJaCyZAQwTPW",
            "symbol": "SPARR",
            "name": "Savannah Sparrows Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mSSUPJYLVGRxvsLcR2B8dwtG6KuYWsGpJaCyZAQwTPW/logo.png",
            "tags": [
                "game-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/jJyzV95bdG",
                "twitter": "https://twitter.com/SavanahSparrowS",
                "website": "https://www.savannahsparrows.com"
            }
        },
        {
            "chainId": 101,
            "address": "6N9pFW6Vr6G5WtTzS4sCTLcQLLnTpLXuiEENQuY5nqpi",
            "symbol": "YAFAE",
            "name": "YafaTek E-Money",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yafatek/token-info/master/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/YafaTechnologi1",
                "website": "https://yafatek.dev/"
            }
        },
        {
            "chainId": 103,
            "address": "Dy7FC4Ayeh28CMo8bxxtTfcw7ySZZFhwZFRxvgxkq1KC",
            "symbol": "$MUSHCOIN",
            "name": "MUSH COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Carstino333/Token22/main/43649a65148d9e9046b81afa6551d4e9b4791120_original.png"
        },
        {
            "chainId": 101,
            "address": "6B4SQkzmWtQNJXQ9uhvL7cxid4S3JdZRrQj9YZkbfSJE",
            "symbol": "SSWL",
            "name": "Savannah Sparrows WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6B4SQkzmWtQNJXQ9uhvL7cxid4S3JdZRrQj9YZkbfSJE/logo.png",
            "tags": [
                "whitelist-ticket"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SavanahSparrowS",
                "website": "https://www.savannahsparrows.com"
            }
        },
        {
            "chainId": 103,
            "address": "CUQf6iejbk9uHqrrWU3fz5Xe8p7H26i16EjUH8avGy6m",
            "symbol": "BWUSD",
            "name": "BWUSD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CUQf6iejbk9uHqrrWU3fz5Xe8p7H26i16EjUH8avGy6m/logo.png"
        },
        {
            "chainId": 101,
            "address": "AU9tge2N6pT5Y7FsiTi8gFxeLoQ7GL9GZXmYMYaeZCnT",
            "symbol": "Unity",
            "name": "Unity Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/chillingonsol/logo/main/unity.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AYiYVS2nSV8cRoYY3HJH9YF7fnQMQBmp2UMu6CGHnvUE",
            "symbol": "DMCN",
            "name": "DumCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Godflay/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TripBPFeezN4Naxg6Ztx5a8g2vv8fimswhte3QE3Zna",
            "symbol": "TAPE",
            "name": "Trippin Ape Tribe Whitelist Token",
            "decimals": 9,
            "logoURI": "https://imagizer.imageshack.com/img922/6869/YUVPPX.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/trippinapenft",
                "website": "https://www.trippinapetribe.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "3swraHsc77KMg1tFvwH3tfYcd8SWr5fcUhtmRxjavG7H",
            "symbol": "KS",
            "name": "KALISTEN - Train To Earn",
            "decimals": 9,
            "logoURI": "https://kalisten.app/wp-content/uploads/2022/05/kalisten_token.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kalisten",
                "twitter": "https://twitter.com/kalisten_",
                "website": "https://kalisten.app/"
            }
        },
        {
            "chainId": 101,
            "address": "J8xdo42P8B7j6jJ8qmZJscevLgveQEGciBv14FE5yXJp",
            "symbol": "CBTLT",
            "name": "Cyberbloks Tournament Lottery Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J8xdo42P8B7j6jJ8qmZJscevLgveQEGciBv14FE5yXJp/cb.jpg",
            "tags": [
                "lottery-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9oHjAGRgt6VBpZupKxV2w2XdmfpQs9sKPjpzXaHxCG98",
            "symbol": "WANG",
            "name": "Big Wang Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SuperStarShen/PaulCrypto/main/logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SoLF9MkoQcdZguEv1kM2ZFWm9C4EfoP4ZaHZDhhKWoJ",
            "symbol": "Solful",
            "name": "Solful Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoLF9MkoQcdZguEv1kM2ZFWm9C4EfoP4ZaHZDhhKWoJ/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolfulNFT"
            }
        },
        {
            "chainId": 101,
            "address": "JDABJJtPKr4mMzoKXzS128VTmZZdDmCbd4GPy71J3zn1",
            "symbol": "CLSC",
            "name": "ClassCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/huzaifa132/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8hwHhAxzrKtuN8GfotLTUYXXLEiX9qrzPYkahzuByWGP",
            "symbol": "$SHINE",
            "name": "SHINE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/SHINE_coin.png"
        },
        {
            "chainId": 101,
            "address": "9tNQydfWR9sAphTrGNj7ZrcVjs9QiQNXrzn4Hq9xin44",
            "symbol": "$MOJO",
            "name": "MOJO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/MOJO_coin.png"
        },
        {
            "chainId": 101,
            "address": "3nmTsvHUoyWFRoksBWU9oHaHwGsezAp97hgrrnc8brNo",
            "symbol": "$LOOP",
            "name": "LOOP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/LOOP_coin.png"
        },
        {
            "chainId": 101,
            "address": "2Y1E1KgmXYgfLKR7e6o9xzwgZ53rYjLPyqmEeDPacgZi",
            "symbol": "$DUNK",
            "name": "DUNK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/DUNK_coin.png"
        },
        {
            "chainId": 101,
            "address": "5De7hU2PZFsSb6nJaetgZ2aZNEMDsC9A8Bp8G54x9BX7",
            "symbol": "$DRPOWER",
            "name": "DRPOWER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/DRPOWER_coin.png"
        },
        {
            "chainId": 101,
            "address": "GjEhhrAjBjoyZgYBDnHXGRuYRKzFZs4XsrGREcFRRfTb",
            "symbol": "$FLOW",
            "name": "FLOW",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/FLOW_coin.png"
        },
        {
            "chainId": 101,
            "address": "5JNGgEcU9EYAa5PoyQiTUoRfgP3fpBGHfXK33ZRggDZo",
            "symbol": "$EASE",
            "name": "EASE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/EASE_coin.png"
        },
        {
            "chainId": 101,
            "address": "6GNwqhgq3saN6dX94kDrxc8dj4xAcgnwK9njqbrHjmt2",
            "symbol": "$LVRG",
            "name": "LEVERAGE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/LVRG_coin.png"
        },
        {
            "chainId": 101,
            "address": "7ZX9G8cjcWDZEs6ugATmjA5MR3Ao8aAtETn5c2vd6qoi",
            "symbol": "$RSIST",
            "name": "RESIST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/RSIST_coin.png"
        },
        {
            "chainId": 101,
            "address": "27QmTVixhZP5xLM2iYsdmpfhVNsThUS2ZvtmZ3AmNhED",
            "symbol": "$ANTI",
            "name": "ANTI SOCIAL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/ANTI_coin.png"
        },
        {
            "chainId": 101,
            "address": "Es9ogKeDecth3erJanqKFsBLgU9bQCtsjNetRUsqiJto",
            "symbol": "$WELLNESS",
            "name": "WELLNESS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/WELLNESS_coin.png"
        },
        {
            "chainId": 101,
            "address": "6MrPA5iQJPftr5PmQh4TueKh9TLjLpotaJNUjczFxzs8",
            "symbol": "HUZAIFA",
            "name": "HuziCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GithubCreator32/cypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FoMQuZCEUqgj29hDyj6xwHFngctW7F8An2t3mEqbgkmj",
            "symbol": "LSTC",
            "name": "Lester Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Realifyy/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CkfKdxxSL7Zy1EDSp7Xk2NLVWE18UmauBysNCME5zvbc",
            "symbol": "SHIZOKU",
            "name": "SHIZOKU clans",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CkfKdxxSL7Zy1EDSp7Xk2NLVWE18UmauBysNCME5zvbc/shizoku.png",
            "extensions": {
                "twitter": "https://twitter.com/Shizoku_clans",
                "website": "https://shizoku.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9bMsXVhmdgaMEVREDA8YLA1YiwgYc85ByontdW2fEAPh",
            "symbol": "JIT",
            "name": "JitCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9bMsXVhmdgaMEVREDA8YLA1YiwgYc85ByontdW2fEAPh/logo.png",
            "tags": [
                "Jitcoin",
                "JTC",
                "jitcoin",
                "jtc",
                "JitCoin"
            ],
            "extensions": {
                "discord": "https://jitcoin.6ex.dev/discord",
                "github": "https://github.com/JitcoinOfficial",
                "website": "https://jitcoin.6ex.dev"
            }
        },
        {
            "chainId": 101,
            "address": "C2wbTUaWGqEu3hdsAyK5eni4h1bb58eYDvBMsZvzXaBK",
            "symbol": "Level",
            "name": "Level coin",
            "decimals": 9,
            "logoURI": "https://api.mehmetsteakhouse.com/download/logo.png",
            "tags": [
                "service-token",
                "community-token",
                "payment-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/mehmet_steakhouse",
                "website": "https://mehmetsteakhouse.com"
            }
        },
        {
            "chainId": 101,
            "address": "J2SVSPEu6ZefWH16BgPHpH6vU43MeEcRZm5Npm1ycQUi",
            "symbol": "$UPGRADE",
            "name": "UPGRADE COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/UPGRADE_coin.png"
        },
        {
            "chainId": 101,
            "address": "3xqWRbQp3F9zY39YEoR8MWsZ6ri9aKkPeXBn3qPgUsCy",
            "symbol": "WAKA",
            "name": "Waka coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3xqWRbQp3F9zY39YEoR8MWsZ6ri9aKkPeXBn3qPgUsCy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HnEWCEGpezrBMcsMNrmuHLBG9vpEvS5RFaPYDYGVcuTV",
            "symbol": "ALTUG",
            "name": "altug oren social token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HnEWCEGpezrBMcsMNrmuHLBG9vpEvS5RFaPYDYGVcuTV/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qDvxTbv1RL1RDdiedk4NSVPAK6XhqvskEowMxn9TJKA",
            "symbol": "UPAWL",
            "name": "UPA Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qDvxTbv1RL1RDdiedk4NSVPAK6XhqvskEowMxn9TJKA/upa_wl.png",
            "tags": [
                "whitelist-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Rz7cuV8js2",
                "twitter": "https://twitter.com/UnitedPandas_io",
                "website": "https://unitedpandas.io"
            }
        },
        {
            "chainId": 103,
            "address": "GyRtgk4dsNMDX17X9qejNb62mHMHCWhJdzYjy9tzvtko",
            "symbol": "ACTC",
            "name": "ActivCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ActivDoctors/assets/main/imgs/ado_thmb.png"
        },
        {
            "chainId": 101,
            "address": "5MBrosbZcwVfjeharePsEMWw71kDYLZB9ye49fuPX5yr",
            "symbol": "MZAR",
            "name": "MayaZar coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SarthakBehura/mayazar/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "w7E7qsHhdJS2ueqyUKAqPWacgpeFyDQGAmwa5DvQBpo",
            "symbol": "RIX",
            "name": "RichCoin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/w7E7qsHhdJS2ueqyUKAqPWacgpeFyDQGAmwa5DvQBpo/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kh4SwzzaRH",
                "twitter": "https://twitter.com/richboxIO",
                "website": "https://richbox.io/"
            }
        },
        {
            "chainId": 101,
            "address": "G8i2y7wTgjaUx4im9hhA57QbEH3rgNyqqbJzXqXAXtrw",
            "symbol": "NS15",
            "name": "Naxar s15",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G8i2y7wTgjaUx4im9hhA57QbEH3rgNyqqbJzXqXAXtrw/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "description": "Naxar s15 is an NFTs card for mining tokens in the Boxch app. APY 15%",
                "twitter": "https://twitter.com/_Naxar",
                "website": "https://naxar.net/"
            }
        },
        {
            "chainId": 101,
            "address": "HK497ys4h2EkYnZV9knaGWzecSixfwmhde3LNrknrEms",
            "symbol": "NB",
            "name": "Naxar Black",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HK497ys4h2EkYnZV9knaGWzecSixfwmhde3LNrknrEms/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "description": "Naxar Black is an NFTs card for mining tokens in the Boxch app. APY 20%",
                "twitter": "https://twitter.com/_Naxar",
                "website": "https://naxar.net/"
            }
        },
        {
            "chainId": 101,
            "address": "3rRUroe4oRpzC297wzDUtZxPgCZxMgnfKBmmLMvA7zVz",
            "symbol": "NG",
            "name": "Naxar Gold",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3rRUroe4oRpzC297wzDUtZxPgCZxMgnfKBmmLMvA7zVz/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "description": "Naxar Gold is an NFTs card for mining tokens in the Boxch app. APY 25%",
                "twitter": "https://twitter.com/_Naxar",
                "website": "https://naxar.net/"
            }
        },
        {
            "chainId": 101,
            "address": "AEB7dHS8CjR9qNCoWwBotvcdoEJHWdCstVQ73FKknLFf",
            "symbol": "NU",
            "name": "Naxar Ultra",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEB7dHS8CjR9qNCoWwBotvcdoEJHWdCstVQ73FKknLFf/logo.png",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "description": "Naxar Ultra is an NFTs card for mining tokens in the Boxch app. APY 30%",
                "twitter": "https://twitter.com/_Naxar",
                "website": "https://naxar.net/"
            }
        },
        {
            "chainId": 101,
            "address": "ShMkWbNimso7jtbsob1ehn7Tk4sVM2Ns2aBPaqT4wtK",
            "symbol": "SHMK",
            "name": "Shmoke Coin",
            "decimals": 6,
            "logoURI": "https://shmokeville.com/logo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Shmoke Coin",
                "discord": "https://discord.gg/qrucgaZUvX",
                "twitter": "https://twitter.com/Smokevillesol",
                "website": "https://shmokeville.com"
            }
        },
        {
            "chainId": 101,
            "address": "ToTxS3Mi6krv1j76P4KMhZp9PU7FpnPnwxnrnD3aUJJ",
            "symbol": "TOTXS3",
            "name": "TotalTokenTransient",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToTxS3Mi6krv1j76P4KMhZp9PU7FpnPnwxnrnD3aUJJ/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "TotalTokenTransient is a fun coin!"
            }
        },
        {
            "chainId": 101,
            "address": "oHLy9rACUZoniX3gmTm1ABRnonPX5mWzgdUBk6GtGUf",
            "symbol": "SWERV",
            "name": "SWERV",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/Zues369/SWERV-logo/main/logo.png",
            "tags": [
                "payment-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Creative Collective. Hip Hop, R\u0026B, World Sounds y más. Es Otra Onda SWERV , token value 1usdt = 10 SWERV tokens",
                "discord": "https://discord.gg/3yNwFyPzUb",
                "instagram": "https://www.ins.com/swervba/",
                "website": "https://linktr.ee/Swervba"
            }
        },
        {
            "chainId": 101,
            "address": "DU1uTzkfAMWYhPs5GdprFYmGSMcEFZvX4ScbpBxkBdDf",
            "symbol": "PMG",
            "name": "Paulism Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Grit-Dev/crypto1/main/TryTwo(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75qDcsoXcgU16E2yoyXBfPvxSVSTNej3uK48iAwpmZyW",
            "symbol": "AIC",
            "name": "AI Crew Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/75qDcsoXcgU16E2yoyXBfPvxSVSTNej3uK48iAwpmZyW/logo.png",
            "tags": [
                "utility-token",
                "nft-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AI_Crew_Sol"
            }
        },
        {
            "chainId": 101,
            "address": "LostdSbLeuUnWyzyYbojwB1ZQHbnLZvuJ9fKpeEEYhP",
            "symbol": "LISWL",
            "name": "Lost in Smoke WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LostdSbLeuUnWyzyYbojwB1ZQHbnLZvuJ9fKpeEEYhP/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lostinsmoke_nft"
            }
        },
        {
            "chainId": 101,
            "address": "DbreB1y7f52k5x1AHrJio18eBBNRcjb97BKLtaEKgstX",
            "symbol": "LMCFPWL",
            "name": "LMC Founder's Pass WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DbreB1y7f52k5x1AHrJio18eBBNRcjb97BKLtaEKgstX/logo.jpg",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/loosemoosecrew",
                "twitter": "https://twitter.com/LooseMooseCrew",
                "website": "https://loosemoosecrew.com"
            }
        },
        {
            "chainId": 101,
            "address": "cyEAh83RvJJgwcKPBXpY35dnwWT47Mon3K36YpTHwWG",
            "symbol": "PPB",
            "name": "BaCoin",
            "decimals": 0,
            "logoURI": "https://ibb.co/xScBGjH",
            "tags": [
                "nft-token"
            ],
            "extensions": {
                "description": "Pluto Pig BaCoin, Pigs will fly, it's just a matter of time!",
                "twitter": "https://twitter.com/PlutoPigsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "HLqtPVVzMgW6c7xFgJXAskXFcSvFyGHE8taz7KB39QjH",
            "symbol": "FINE",
            "name": "Finery",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FineryNft/Logo/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ed5ZEiM7rcbp6TwcJnPYzCcefdTSjixWnArWbHiVuN9k",
            "symbol": "DOMOBe",
            "name": "DomoCoin Beta",
            "decimals": 8,
            "logoURI": "https://domocoin.net/wp-content/uploads/2022/05/DomoCoin-Beta-Logo.png",
            "tags": [
                "utiliy-token",
                "social-token"
            ],
            "extensions": {
                "description": "DomoCoin Beta is a utility-token seeking to fund the development of DomoCoin and the DomoArcade platform.",
                "reddit": "https://www.reddit.com/r/DomoCoin/",
                "website": "https://domocoin.net"
            }
        },
        {
            "chainId": 101,
            "address": "2shTBTRGMKVgZw3eWxJvRHvsxGJAUaDZjdHDeMmUvEBv",
            "symbol": "ITA",
            "name": "ITALIA",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2shTBTRGMKVgZw3eWxJvRHvsxGJAUaDZjdHDeMmUvEBv/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "description": "ITA is the fan token of Italy",
                "twitter": "https://twitter.com/Azzurri_En",
                "website": "https://www.italia.it/"
            }
        },
        {
            "chainId": 101,
            "address": "7FuP2KsccsYA54XQaLJ5y4M2fbagbsfc9ZSQHHAsgTnL",
            "symbol": "DTLG",
            "name": "DataAlg",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7FuP2KsccsYA54XQaLJ5y4M2fbagbsfc9ZSQHHAsgTnL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "DTLG is the token for invest in DataAlg quantitative investment firm trading in Cryptos and NFTs",
                "twitter": "https://twitter.com/DataAlgOfficial"
            }
        },
        {
            "chainId": 101,
            "address": "CartMGitU7CXZA8XBWv8tKYhU8mV594LHLred2U8kHQe",
            "symbol": "cartoonkids",
            "name": "Kids cartoon Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CartMGitU7CXZA8XBWv8tKYhU8mV594LHLred2U8kHQe/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KidsCartoonNFT"
            }
        },
        {
            "chainId": 101,
            "address": "EnTRfLu1uGKhx21VwAr5Truv8u7PhRENh73jcXeCbDda",
            "symbol": "XCO",
            "name": "Monaco",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bramdaniel1/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "85niRdAwMf27oicsdCXytPTsExA1A8YdJNMZAmoQ839Y",
            "symbol": "TACO",
            "name": "TacoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bramdaniel1/TacoToken/main/TacoLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9xG7cQZSR5CezW18DxW135RRV3wSs54T2FWSCSGJHErb",
            "symbol": "PBSC",
            "name": "PBS Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrpalin/crypto-image/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4mgZTMQxAxAEMt7JKgF2rAkvjiPCQajnoVoChcmT8ypg",
            "symbol": "HIT",
            "name": "HICON",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/DevListKj/ListLogoCli/main/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BoBoWy2Z4QvyZRw7JdNS1dxXPA56DNrxCX97YEzobcA6",
            "symbol": "BOW",
            "name": "BOW",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoBoWy2Z4QvyZRw7JdNS1dxXPA56DNrxCX97YEzobcA6/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/bobosofwar",
                "website": "https://bobosofwar.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FsniTTtb9GeGq1DHkipxera4bsgFkb19maBLKZwMe7in",
            "symbol": "TIZ",
            "name": "Tiziran",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pirahansiah/solana_token/main/tiziran.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5nqU8WzBstVyh44K6xnEuQb5P1TJd1zrLcAGEJNFt4tP",
            "symbol": "RAIDEN",
            "name": "Raiden City",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5nqU8WzBstVyh44K6xnEuQb5P1TJd1zrLcAGEJNFt4tP/logo.svg",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/raidencity",
                "instagram": "https://www.instagram.com/raidencity",
                "medium": "https://raidencity.medium.com/",
                "twitter": "https://twitter.com/raidencity",
                "website": "https://www.raiden.city/"
            }
        },
        {
            "chainId": 101,
            "address": "WWY8cb8Ln7r1UUrTSMyXXGwc7PTcwjmodruRtXpYtJ4",
            "symbol": "PDL",
            "name": "Padel Coin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WWY8cb8Ln7r1UUrTSMyXXGwc7PTcwjmodruRtXpYtJ4/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BoHGPNU3oyk7cyhFq84bfjCAwNdfKr4nsNytcWCM4fDg",
            "symbol": "OGNINJAS",
            "name": "OG token BCNinjas Generation 2",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/GPR3bpm/1.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "44thGfHRsdCB61NAkrDFe6djRrwWZegTTqrs1bwMeAGu",
            "symbol": "44TH",
            "name": "44TH",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/44thGfHRsdCB61NAkrDFe6djRrwWZegTTqrs1bwMeAGu/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Bic8w5K9tvHDY7XfiT7fLTdiAkQWURAKkcEs8EsXpxbY",
            "symbol": "TROOP",
            "name": "Troop Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bic8w5K9tvHDY7XfiT7fLTdiAkQWURAKkcEs8EsXpxbY/logo.png",
            "tags": [
                "utility-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "LaXyFtJ9JWq7r7gsCRk7JbUaD45FTirAwyUR5WzyVer",
            "symbol": "Solaxy",
            "name": "Solaxy Metaverse Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LaXyFtJ9JWq7r7gsCRk7JbUaD45FTirAwyUR5WzyVer/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolaxyMetaverse"
            }
        },
        {
            "chainId": 101,
            "address": "4NDCtPowqWxgiwWcZ1Nzw2odYGUrV2WPJaPowrHcp86u",
            "symbol": "OMO",
            "name": "OMO DeFi",
            "decimals": 0,
            "logoURI": "https://github.com/defimill/logo/blob/main/Logo-512x512.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8UdHFarzkrq3tu6bvLkMRssFKVoFe8mMmkCrVQGC7BND",
            "symbol": "TTCCMint",
            "name": "TTCC Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UdHFarzkrq3tu6bvLkMRssFKVoFe8mMmkCrVQGC7BND/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/UrraBQjMYf",
                "twitter": "https://twitter.com/TimeTravelingCC"
            }
        },
        {
            "chainId": 101,
            "address": "HALoiQHVad4zaH4yhyiZg6E2Pc9hTp4Pp3Fhoo9Et12h",
            "symbol": "HALO",
            "name": "dHALO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HALoiQHVad4zaH4yhyiZg6E2Pc9hTp4Pp3Fhoo9Et12h/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/divinedogsnft",
                "twitter": "https://twitter.com/DivineDogsNFT",
                "website": "https://www.divinedogsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5MdKp8XXwLibRXbyYN8osLsVfKgiWxxcRqrM1zJmYRmz",
            "symbol": "HOPE",
            "name": "HOPE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/letushope/hope-token/main/logo/logo.png",
            "tags": [
                "community-token",
                "DAO-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BHz39KcF6taQSUmNvDCYTnjAdeN95UrtduBVaAV5AxtQ",
            "symbol": "BH",
            "name": "BucketHeadz WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BHz39KcF6taQSUmNvDCYTnjAdeN95UrtduBVaAV5AxtQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "BucketHeadz WL Token",
                "discord": "https://discord.gg/bucketheadz",
                "twitter": "https://twitter.com/BucketHeadzNFT",
                "website": "http://bucketheadz.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "Ev7xV5Qmf3LfHrNZ4rXVyyzqWqf6AqrfG7eR297MueyN",
            "symbol": "GravMot",
            "name": "GRAVMOT - Gravitational Motor NFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AlainJSPainchaud/Crypto/main/GravitationMotorToken.png",
            "tags": [
                "art-token",
                "business-token",
                "financial-token",
                "monetary-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EZKwSV8LwPv9zW6JBpbvMEeHDopTt3xsa8u44jg6bzLh",
            "symbol": "GRHB",
            "name": "Graham Bucks",
            "decimals": 0,
            "logoURI": "https://github.com/apejawn/GBS/blob/main/GB-512x512.png",
            "tags": [
                "Family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9qtQ7g6dcFQS47YfQR48XFNvYDnoDwr86ze6hTDVfzTX",
            "symbol": "CMMN",
            "name": "Common Token",
            "decimals": 0,
            "logoURI": "https://github.com/defiquaz/CMMNBRND/blob/main/CBLOGO-512x512.png",
            "tags": [
                "utility-token",
                "Community-Token",
                "DAO-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "GS7uX4SbCqftqJtfMkXBkZUrvZmXjXZxyp75KqqrS4aB",
            "symbol": "TNDC",
            "name": "Tunisian Dinar Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nouraouioua/tndc/main/logo.png",
            "tags": [
                "TND-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "14UKc9anWCiEdcfhLvfoLZUZ82aDMf9fGqLxoM7BoLRS",
            "symbol": "GECKOS",
            "name": "Galactic Geckos Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/PfaeoXm.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "galactic-geckos",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/14UKc9anWCiEdcfhLvfoLZUZ82aDMf9fGqLxoM7BoLRS"
            }
        },
        {
            "chainId": 101,
            "address": "2ErYR9qxz3jPZX6soEUt6FCVhHK3gLam1yiDBrznPk9q",
            "symbol": "RORYS",
            "name": "Aurory Floor Index",
            "decimals": 2,
            "logoURI": "https://aurory.io/content/img/header/logo.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "aurory",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/2ErYR9qxz3jPZX6soEUt6FCVhHK3gLam1yiDBrznPk9q"
            }
        },
        {
            "chainId": 101,
            "address": "7aDkYnSyKUnQgnedoJyjs7fNt3Ef1B4rqaoGRoXSQKMx",
            "symbol": "BIRDZ",
            "name": "Thugbirdz Floor Index",
            "decimals": 2,
            "logoURI": "https://www.arweave.net/nGvadeW0UuvIgzZUyNKaQ-c8400CDQn1FwNthOJ_KUw?ext=png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "thugbirdz",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/7aDkYnSyKUnQgnedoJyjs7fNt3Ef1B4rqaoGRoXSQKMx"
            }
        },
        {
            "chainId": 101,
            "address": "7jpqRdTrzHQB2qADggdDaUqaxMb3aiQboFpwpQn1bkLn",
            "symbol": "DAPES",
            "name": "Degenerate Ape Academy Floor Index",
            "decimals": 2,
            "logoURI": "https://cloudflare-ipfs.com/ipfs/bafkreie44hlb5xve225n6p2siebaevxiwha37aefyvs2ul2kx2akalnree",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "daa",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/7jpqRdTrzHQB2qADggdDaUqaxMb3aiQboFpwpQn1bkLn"
            }
        },
        {
            "chainId": 101,
            "address": "5dgSRQ4oL8C942K4qPBuhjkbTNHtfqHMADhYE25PmhPG",
            "symbol": "SHARKS",
            "name": "Rogue Sharks Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/HQ7XdEM.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "rogue-sharks",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/5dgSRQ4oL8C942K4qPBuhjkbTNHtfqHMADhYE25PmhPG"
            }
        },
        {
            "chainId": 101,
            "address": "Egw6UvRKgMkUxARzyj2WjmEnd8Pa5f4KTMEUN2BmwnLn",
            "symbol": "SSC",
            "name": "Shadowy Super Coder Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/Hqc29kA.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "ssc",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/Egw6UvRKgMkUxARzyj2WjmEnd8Pa5f4KTMEUN2BmwnLn"
            }
        },
        {
            "chainId": 101,
            "address": "BdETrEGESCFx4jjaRWSC4tSU6Gp347w7pNdq2hxT7uRS",
            "symbol": "PANDAS",
            "name": "Degenerate Trash Pandas Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/3c4fcde4c8ce6dbea98feca031f86a7e/54075bc7",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "trash-pandas",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/BdETrEGESCFx4jjaRWSC4tSU6Gp347w7pNdq2hxT7uRS"
            }
        },
        {
            "chainId": 101,
            "address": "BuX8DoFn7ogeXucC14yZDHyZGP3KnLCX1KNv7qFC5jDj",
            "symbol": "GPETS",
            "name": "Genopets Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/F2J6DpS.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "genopets",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/BuX8DoFn7ogeXucC14yZDHyZGP3KnLCX1KNv7qFC5jDj"
            }
        },
        {
            "chainId": 101,
            "address": "GogRTbcBiMrB9891gX8wcbMYyMTFnW9LtDoR2K9EetHa",
            "symbol": "SOLSTDS",
            "name": "Solsteads Floor Index",
            "decimals": 2,
            "logoURI": "https://www.arweave.net/WKfr0c79-4ExF_lEBkl4eQlqnPXrNWIj5W7CppmJZw4?ext=png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "solsteads",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/GogRTbcBiMrB9891gX8wcbMYyMTFnW9LtDoR2K9EetHa"
            }
        },
        {
            "chainId": 101,
            "address": "CcCHwdGUj9wocqysgowdqk2BSJ7NLfMR1vsedFT26rhx",
            "symbol": "BSS",
            "name": "Basis Markets Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/H0TSm4U.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "basis-markets",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/CcCHwdGUj9wocqysgowdqk2BSJ7NLfMR1vsedFT26rhx"
            }
        },
        {
            "chainId": 101,
            "address": "E5UNCyiF1xrCqKozyFuiBkYH678BhftBo8Q1GreukBq3",
            "symbol": "ENVIRO",
            "name": "Enviro Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/245e46f41039aaa1000250cec209bdc3/6ce42bdc",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "enviro",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/E5UNCyiF1xrCqKozyFuiBkYH678BhftBo8Q1GreukBq3"
            }
        },
        {
            "chainId": 101,
            "address": "HY9LZ7TsDABoMzYfZvBwrLEUVEAWE1kGF8XS1JvbMN9u",
            "symbol": "CATS",
            "name": "Wise Cats Index",
            "decimals": 2,
            "logoURI": "https://cloudflare-ipfs.com/ipfs/bafkreih55niv5sh7gjeqdfvoehthuzewbtrxf367nbdbk5wgmgl5weamwm",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "wise-cats",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/HY9LZ7TsDABoMzYfZvBwrLEUVEAWE1kGF8XS1JvbMN9u"
            }
        },
        {
            "chainId": 101,
            "address": "663rbtf1FHhz1kQFAq41z63ViigQr8zAvZpNKJbZbF6C",
            "symbol": "CTZN",
            "name": "Citizens Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/17098df55330304080e6ec85174ce2be/9b0790be",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "citizens",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/663rbtf1FHhz1kQFAq41z63ViigQr8zAvZpNKJbZbF6C"
            }
        },
        {
            "chainId": 101,
            "address": "5hzLftaEyGTQJnoxhGxAvsLkmdjYsbx9YdjNwfNmtq2s",
            "symbol": "PARTICLES",
            "name": "Particles Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/W8Lo4lY.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "particles",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/5hzLftaEyGTQJnoxhGxAvsLkmdjYsbx9YdjNwfNmtq2s"
            }
        },
        {
            "chainId": 101,
            "address": "CT81fJ8ReVt3aNPqZr3xZvgJ7jjEfrzaXh3condAXHXP",
            "symbol": "SOLPUNKS",
            "name": "SolPunks Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/17eC2nf.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "solpunks",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/CT81fJ8ReVt3aNPqZr3xZvgJ7jjEfrzaXh3condAXHXP"
            }
        },
        {
            "chainId": 101,
            "address": "BVGiPUve3dtMLNw2iv3tA7NN8Jv198Ha9FmPXKMUDkjF",
            "symbol": "SKULLYS",
            "name": "Disarticulated Skullys Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/dskullys_pfp_1649719436844.jpeg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "skullyz",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/BVGiPUve3dtMLNw2iv3tA7NN8Jv198Ha9FmPXKMUDkjF"
            }
        },
        {
            "chainId": 101,
            "address": "FmoKY2ERGmE9NzrYphAJcqH5BPRy2Hs4VomRfu8Qgt7Y",
            "symbol": "MONKES",
            "name": "SMB Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/FczxrLa.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "smb",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/FmoKY2ERGmE9NzrYphAJcqH5BPRy2Hs4VomRfu8Qgt7Y"
            }
        },
        {
            "chainId": 101,
            "address": "4zPtzz7cBcz6ZNoMTCYPh1juGCFpp59BBYTTybjwmnRo",
            "symbol": "DEGODS",
            "name": "DeGods Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/2kWcR3L.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "degods",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/4zPtzz7cBcz6ZNoMTCYPh1juGCFpp59BBYTTybjwmnRo"
            }
        },
        {
            "chainId": 101,
            "address": "B8sv5Z6n1cooCUFjvVgEKnU1mx1vCmWZGeoyavu1V3v2",
            "symbol": "GODS",
            "name": "SOLGods Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/UclQYJVh.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "solgods",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/B8sv5Z6n1cooCUFjvVgEKnU1mx1vCmWZGeoyavu1V3v2"
            }
        },
        {
            "chainId": 101,
            "address": "3MkJycarius9FkEzzMDCXdtVfGndv19uXW4z1qNth7nW",
            "symbol": "WHALES",
            "name": "Catalina Whale Mixer Floor Index",
            "decimals": 2,
            "logoURI": "https://cloudflare-ipfs.com/ipfs/bafkreicadecx3rbffm6tqtgtc67bi6r2xiibtmawcejje7pfu7id2zxwwy",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "catalina-whales",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/3MkJycarius9FkEzzMDCXdtVfGndv19uXW4z1qNth7nW"
            }
        },
        {
            "chainId": 101,
            "address": "GhMNTD2abFCg4ApKp581ifWUNF3m7wwLtPJLpsVaawe6",
            "symbol": "CETS",
            "name": "Cets On Creck Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/cats_on_crack_pfp_1644850873089.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "cets-on-creck",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/GhMNTD2abFCg4ApKp581ifWUNF3m7wwLtPJLpsVaawe6"
            }
        },
        {
            "chainId": 101,
            "address": "8CmKs6xeWyrgTwBQPUtq7HdbEHvkV9F3NARx2GMX9wFZ",
            "symbol": "LITJESUS",
            "name": "Lit Jesus Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/gn2iGiB.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "lit-jesus",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/8CmKs6xeWyrgTwBQPUtq7HdbEHvkV9F3NARx2GMX9wFZ"
            }
        },
        {
            "chainId": 101,
            "address": "AiCE5ByrXWdKizTFQURngECuLRRrHxmyPtXbUKsxwQR2",
            "symbol": "ATADIAN",
            "name": "OG Atadians Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/atadians_pfp_1646721263627.gif",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "og-atadians",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/AiCE5ByrXWdKizTFQURngECuLRRrHxmyPtXbUKsxwQR2"
            }
        },
        {
            "chainId": 101,
            "address": "2EyqaC7zo6TYEKTebBZCchiAdwmqEzsxLxA2MzCYhBcf",
            "symbol": "POMP",
            "name": "Pompeizz Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/e7f4eef4c9f95f892f81aef0e4713639/84651cac",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "pompeizz",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/2EyqaC7zo6TYEKTebBZCchiAdwmqEzsxLxA2MzCYhBcf"
            }
        },
        {
            "chainId": 101,
            "address": "Ca6XebDSTEMFJbJMB6ob6CbMWYcL3GtJFsCVFWiMoeMD",
            "symbol": "BIRDZ",
            "name": "Bit Birdz Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/e7f4eef4c9f95f892f81aef0e4713639/84651cac",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "bit-birdz",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/Ca6XebDSTEMFJbJMB6ob6CbMWYcL3GtJFsCVFWiMoeMD"
            }
        },
        {
            "chainId": 101,
            "address": "cCUYsVip3Ve2EbStXE9EVncPiRdcjTH7LfFKaB8g55d",
            "symbol": "NFTREES",
            "name": "NFTrees Floor Index",
            "decimals": 2,
            "logoURI": "https://pbs.twimg.com/profile_images/1437707791334998017/0oxy-zEY_400x400.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "nftrees",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/cCUYsVip3Ve2EbStXE9EVncPiRdcjTH7LfFKaB8g55d"
            }
        },
        {
            "chainId": 101,
            "address": "H3VPWCEDpUpbykaozNGTPEuG9Gsdkrp4u9cpzJos3FHu",
            "symbol": "MONKELABS",
            "name": "Monkelabs Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/37fd1a44d683784b3b6195f0e5dec266/8196493e",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "monkelabs",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/H3VPWCEDpUpbykaozNGTPEuG9Gsdkrp4u9cpzJos3FHu"
            }
        },
        {
            "chainId": 101,
            "address": "FRsyPFrp657gKPPTDNP8ZPGAPXfRJaCLkhfdPsGxJY7p",
            "symbol": "DYORNERDS",
            "name": "DYOR NERDS Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/dyor_nerds_pfp_1647844662971.jpeg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "dyor-nerds",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/FRsyPFrp657gKPPTDNP8ZPGAPXfRJaCLkhfdPsGxJY7p"
            }
        },
        {
            "chainId": 101,
            "address": "HQTUikPhVJ3V8bZfTGNFiy169MZfLP7fkKoJK85gUswG",
            "symbol": "SOULT",
            "name": "Soul Tools Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/soultools_pfp_1648142538448.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "soul-tools",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/HQTUikPhVJ3V8bZfTGNFiy169MZfLP7fkKoJK85gUswG"
            }
        },
        {
            "chainId": 101,
            "address": "DzUbqmFeZNxYj112sFuYNVdQ18ezk1pAKEtrCSMtE4Fb",
            "symbol": "PESKYPNGN",
            "name": "Pesky Penguins Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/37nsjBZ.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "pesky-penguins",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/DzUbqmFeZNxYj112sFuYNVdQ18ezk1pAKEtrCSMtE4Fb"
            }
        },
        {
            "chainId": 101,
            "address": "8e2G3tCTvKAosq4BnYbDczboRVhy7xaXwbTSJeXEefJX",
            "symbol": "JCATS",
            "name": "Jungle Cats Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/AEPNTTQ.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "jungle-cats",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/8e2G3tCTvKAosq4BnYbDczboRVhy7xaXwbTSJeXEefJX"
            }
        },
        {
            "chainId": 101,
            "address": "GpScy7hnuSHPWQmQR6HFGjEqFwzTH6J4kL4TRQZovHF",
            "symbol": "GEMMY",
            "name": "Gemmy Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/gemmy_pfp_1651420536407.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "gemmy",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/GpScy7hnuSHPWQmQR6HFGjEqFwzTH6J4kL4TRQZovHF"
            }
        },
        {
            "chainId": 101,
            "address": "E4oEnayacCpyKLQQeRqSm8bG5wPqDMZuun6fEXgoLNwF",
            "symbol": "ORCSFI",
            "name": "The Orcs Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/theorcs_pfp_1650812101467.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "orcs",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/E4oEnayacCpyKLQQeRqSm8bG5wPqDMZuun6fEXgoLNwF"
            }
        },
        {
            "chainId": 101,
            "address": "GvpkzEc4kiKS3xzNLFoc3k8HUVxYCAU8CYBkhAUKsSZ5",
            "symbol": "LAMAS",
            "name": "Sollamas Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/12d27b39d0669181ee7bc21b6cced591/b4e0a300",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "sollamas",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/GvpkzEc4kiKS3xzNLFoc3k8HUVxYCAU8CYBkhAUKsSZ5"
            }
        },
        {
            "chainId": 101,
            "address": "9EgSSSAkeo8S4PDX6FqQoMLcUxgLfMaJFSDmkV78LErS",
            "symbol": "FLARES",
            "name": "Lifinity Flares Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/6972d5c2efb77d49be97b07ccf4fbc69/e9572fb8",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "lifinity-flares",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/9EgSSSAkeo8S4PDX6FqQoMLcUxgLfMaJFSDmkV78LErS"
            }
        },
        {
            "chainId": 101,
            "address": "CP8CaP7GmSVUo9j3L8dwDKVR6i1kvcCUn1ubGGhc2V2M",
            "symbol": "SURF",
            "name": "Serum Surfers Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/serum_surfers_pfp_1650376607466.gif",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "serum-surfers",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/CP8CaP7GmSVUo9j3L8dwDKVR6i1kvcCUn1ubGGhc2V2M"
            }
        },
        {
            "chainId": 101,
            "address": "fLoeAqCfMiS3Uaj6aXSCGhf2ZE9znWz7WjTPCD2Rgnf",
            "symbol": "MMCC",
            "name": "Meerkats Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachmentThumbnails/da62eb10c452a9b2a9b768d4aedd13b5/676b0660",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "mmcc",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/fLoeAqCfMiS3Uaj6aXSCGhf2ZE9znWz7WjTPCD2Rgnf"
            }
        },
        {
            "chainId": 101,
            "address": "2fgYu8vYZhvVsocNM4y4HcrZCCPXYcoo8mZof5hJ3miw",
            "symbol": "DRONIES",
            "name": "Dronies Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachments/cfa00eda53df579fda06157ece315602/dc4ab85c/Dronies.gif",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "dronies",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/2fgYu8vYZhvVsocNM4y4HcrZCCPXYcoo8mZof5hJ3miw"
            }
        },
        {
            "chainId": 101,
            "address": "sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh",
            "symbol": "sRLY",
            "name": "sRLY (Rally Solana)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq/logo.png",
            "extensions": {
                "website": "https://rly.network"
            }
        },
        {
            "chainId": 101,
            "address": "GLeHPn4ZqRvAqN9LVDeWrcUtNi7YtQs3oewz7aFfngwG",
            "symbol": "WLNINJAS",
            "name": "WL token BCNinjas Generation 2",
            "decimals": 0,
            "logoURI": "https://i.ibb.co/JkT8swM/2.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hyko95PcrPNcvYugHcHsD9UBk47ckEoe1S9TED4xwGme",
            "symbol": "ENTROPY",
            "name": "ENTROPY CASES DEFINITION",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AlainJSP/crypto/main/EntropyNFT.png",
            "tags": [
                "utility-token",
                "art-token",
                "business-token",
                "financial-token",
                "monetary-token",
                "currency-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5NDSgbvRxNwZUgwcTDZRBxfYgAGwRxYhYahhRRvXsAmT",
            "symbol": "NNT",
            "name": "NiceNewToken",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5NDSgbvRxNwZUgwcTDZRBxfYgAGwRxYhYahhRRvXsAmT/NNTlogo.svg",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 102,
            "address": "iT6Rzh8BRP9GMevCXDAvuKsLPp4Gp5rfP422P8vJk9Q",
            "symbol": "mo",
            "name": "moso-token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MoneshROR/icury_token/main/logo.png",
            "tags": [
                "art-token",
                "business-token",
                "financial-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8u3o8FJ2YAQLWdUrGg8C7YYXgdpspjf7NLUBtUqQUFgq",
            "symbol": "WAGBULL",
            "name": "WAGBULL",
            "decimals": 9,
            "logoURI": "https://rawcdn.githack.com/okaybulls/token/fb8f19a8139c0be093815f0b9fc0ff80133c0e36/token.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "49AAjXJMHqEHKkEhZfHVmtB6YYGRkrmQYZqkCYPGhDS5",
            "symbol": "PMGK",
            "name": "PaulMcG Koin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Grit-Dev/crypto1/main/TryTwo(1).png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2owxEk5TBpRKwt8N1hTG6w52XqRBDj85s5fzU8Kcfwu1",
            "symbol": "REPUB1",
            "name": "NFT OF THE UNIVERSAL SOVEREIGN REPUBLICS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AlainJosephStephanePainchaud/crypto2/main/RepublicToken..png",
            "tags": [
                "art-token",
                "financial-token",
                "monetary-token",
                "educational-token",
                "project-token"
            ],
            "extensions": {
                "description": "Universal Sovereign Republics have been founded in the atomic vacuum on April 15th, 2013 by Dr. AJSP",
                "twitter": "https://twitter.com/RepubSouveraine",
                "website": "https://www.republiquesouveraine.org"
            }
        },
        {
            "chainId": 101,
            "address": "5t8Z73FYA3qH8Q9jkvAT32dpia8HT6e4gojJzQ8Hx1jP",
            "symbol": "PCHC",
            "name": "PEACHCOIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5t8Z73FYA3qH8Q9jkvAT32dpia8HT6e4gojJzQ8Hx1jP/logo.png",
            "tags": [
                "community-token",
                "PEACHCOIN"
            ],
            "extensions": {
                "website": "https://peachcoin.net/"
            }
        },
        {
            "chainId": 101,
            "address": "5AnEGwYSHhdJiBQ1p2UkhjNw9LgnhDXEN2GMgQqmobFD",
            "symbol": "BLUG",
            "name": "Blue Ghost",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/RioDroid/crypto1/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9dbktd9NRs4qyqP4sr91WzbXqWX3Mfu7fbgCgn6QFdqJ",
            "symbol": "PXRWL",
            "name": "Pixel Racers WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9dbktd9NRs4qyqP4sr91WzbXqWX3Mfu7fbgCgn6QFdqJ/6e062aaf-12fe-4758-8430-12e069091298.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/sJyp4gMGJD",
                "twitter": "https://twitter.com/pixelracersnft",
                "website": "https://pixelracers.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Tokn8FgLAGUBpWScUHfWf6HUB372CM5bZczpv7uC4me",
            "symbol": "EH",
            "name": "Empty Header Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Tokn8FgLAGUBpWScUHfWf6HUB372CM5bZczpv7uC4me/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "Empty Headers is a wide collection of NFTs on Solana Blockchain.",
                "twitter": "https://twitter.com/emptyheadernft",
                "website": "https://emptyheader.com"
            }
        },
        {
            "chainId": 101,
            "address": "FF75evgj5bFWiBuu9MfLF54imFo8ypSYwfC6xQSiKYcJ",
            "symbol": "MBOT",
            "name": "MBOT Mint PassToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FF75evgj5bFWiBuu9MfLF54imFo8ypSYwfC6xQSiKYcJ/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AttCypPTiTgGpxeHYeFZ6dTwZqd1iqf5MPoG12TQrf1C",
            "symbol": "HTO",
            "name": "Heaven Land Metaverse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD/logo.png",
            "tags": [
                "utility-token",
                "governance-token",
                "metaverse"
            ],
            "extensions": {
                "twitter": "https://twitter.com/heavenland_io",
                "website": "https://heavenland.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CMHrkMFNNqdQNYEyMQK4FEV5nf4xgxkH1aUvRizdsnha",
            "symbol": "VTest",
            "name": "VTest (VAHA test coin)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CMHrkMFNNqdQNYEyMQK4FEV5nf4xgxkH1aUvRizdsnha/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://global.vaha.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CALusHembJf3tQ69BxFbLRUSpGRwKzEnLKWUPhQo5dFk",
            "symbol": "calUSD",
            "name": "calUSD Stablecoin",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/8619106/167318344-e9ea9147-8969-4818-877c-5c75de10aecc.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/Me2zTTdQpu",
                "medium": "https://medium.com/@CalciferFinance",
                "twitter": "https://twitter.com/CalciferFi",
                "website": "https://calcifer.fi/"
            }
        },
        {
            "chainId": 103,
            "address": "F8hUpNakKw4i8aqGASTbLZUVR4uJ1tFHFCiMi8Wc5HbY",
            "symbol": "W$",
            "name": "W Token For Testing",
            "decimals": 9,
            "logoURI": "https://imgur.com/a/homEoCO",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "W token top top top",
                "github": "https://github.com/ayumi760405"
            }
        },
        {
            "chainId": 101,
            "address": "SPLFjZvdxzVW5pHbXWhUdDSumyN38Ep3pCGWqzSfiuG",
            "symbol": "SPLIFF",
            "name": "SPLIFF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SPLFjZvdxzVW5pHbXWhUdDSumyN38Ep3pCGWqzSfiuG/logo.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BqZdsThwaCLRbhDkszc6dRommQtPeEyfmYgWpiALku9o",
            "symbol": "TMK",
            "name": "Tamaki",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BqZdsThwaCLRbhDkszc6dRommQtPeEyfmYgWpiALku9o/logo.png",
            "tags": [
                "utility-token",
                "governance-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TamakiApes"
            }
        },
        {
            "chainId": 101,
            "address": "ri3iwFvNLQXsVrP7uWcxUxtuYVVgnR6Nrm8mU5jrgkc",
            "symbol": "CTH",
            "name": "CRYPTO TREASURE HUNTER",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/BLMcrypto/token-list/main/assets/mainnet/ri3iwFvNLQXsVrP7uWcxUxtuYVVgnR6Nrm8mU5jrgkc/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4BU2ZbCiRv8NRg59qt55XEo3WqWpZC6dmtV4yc9Xsxo8",
            "symbol": "DTC",
            "name": "Damoov TravellingCoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/VorobevSA/solana-token-image-storage/main/assets/mainnet/4BU2ZbCiRv8NRg59qt55XEo3WqWpZC6dmtV4yc9Xsxo8/DamoovTravellingCounts.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Mobile-Telematics",
                "website": "https://www.damoov.com"
            }
        },
        {
            "chainId": 101,
            "address": "4Ca87EUd63nMymw3AdeH3YZhiCTV3rLLBu8tNcR1CRFW",
            "symbol": "DEC",
            "name": "Damoov EcoCoin",
            "decimals": 2,
            "logoURI": "https://github.com/VorobevSA/solana-token-image-storage/blob/main/assets/mainnet/4Ca87EUd63nMymw3AdeH3YZhiCTV3rLLBu8tNcR1CRFW/DamoovEcoDrivingCoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Mobile-Telematics",
                "website": "https://www.damoov.com"
            }
        },
        {
            "chainId": 101,
            "address": "BoyBCgc8uupc1J6v9po4zP6WEyuUWgZjJMMLS3oMmfH1",
            "symbol": "DDC",
            "name": "Damoov DriveCoin",
            "decimals": 2,
            "logoURI": "https://github.com/VorobevSA/solana-token-image-storage/blob/main/assets/mainnet/BoyBCgc8uupc1J6v9po4zP6WEyuUWgZjJMMLS3oMmfH1/DamoovDrivecoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Mobile-Telematics",
                "website": "https://www.damoov.com"
            }
        },
        {
            "chainId": 101,
            "address": "5cR6hJJbtV4d14aAtwSeW2oNk8CrCAZphkhXbS45edxH",
            "symbol": "DSC",
            "name": "Damoov SafeCoin",
            "decimals": 2,
            "logoURI": "https://github.com/VorobevSA/solana-token-image-storage/blob/main/assets/mainnet/5cR6hJJbtV4d14aAtwSeW2oNk8CrCAZphkhXbS45edxH/DamoovSafecoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Mobile-Telematics",
                "website": "https://www.damoov.com"
            }
        },
        {
            "chainId": 101,
            "address": "34n9iy2ZiTvVzsC8EFJtKV3SwtG3wv8obUsWHZ5JeFyg",
            "symbol": "JKBC",
            "name": "JokubasCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/JokubasGaid/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CurZY9UonzDNmDfB9SgjYAJz2hj6Kx4VYLLkzGozJfJB",
            "symbol": "MWTK",
            "name": "Meraki WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/merakitoken.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5e2fjEHEMn2SrAqdUAvSPc5LPK1GC5abfXn4di6Cvapg",
            "symbol": "MLR",
            "name": "Meliora",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5e2fjEHEMn2SrAqdUAvSPc5LPK1GC5abfXn4di6Cvapg/meliora.jpg",
            "tags": [
                "carbon-offset"
            ],
            "extensions": {
                "description": "MLR is used for transaction data storage. All transactions are happening through Meliora - a carbon offset marketplace",
                "website": "https://melioraapp.com"
            }
        },
        {
            "chainId": 101,
            "address": "ACWkwWhjZE4JZZpcbMawQ7D5GZHnh1U3aQ5epZTxwQ2b",
            "symbol": "EMBA",
            "name": "Ember",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ACWkwWhjZE4JZZpcbMawQ7D5GZHnh1U3aQ5epZTxwQ2b/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "31RdZYE4qNMxy64gq252PHxH9eNmLVJ4oRX4g3zTpAgt",
            "symbol": "FRO",
            "name": "Frosty",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/31RdZYE4qNMxy64gq252PHxH9eNmLVJ4oRX4g3zTpAgt/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5J3Pa7uXZvxL1pn18hq5rnK7h2ASQHknYuFB8fGsabaZ",
            "symbol": "ROOT",
            "name": "Root",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5J3Pa7uXZvxL1pn18hq5rnK7h2ASQHknYuFB8fGsabaZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9CWCftESNbotd1vwDbzgeBL1JphShcCGEzFZtygTFLxM",
            "symbol": "CC",
            "name": "Clown Collective Token",
            "decimals": 2,
            "logoURI": "https://metadata.clowncollective.io/cc-wl-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Clowncollective",
                "twitter": "https://twitter.com/ClownColl3ctive",
                "website": "https://www.clowncollective.io"
            }
        },
        {
            "chainId": 101,
            "address": "NortQGrHwSajv5dfeCdQp6tbrccPtH9Bt8jcu9GDp9v",
            "symbol": "NORTHMAN",
            "name": "The Northman Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NortQGrHwSajv5dfeCdQp6tbrccPtH9Bt8jcu9GDp9v/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheNorthmanNFT"
            }
        },
        {
            "chainId": 101,
            "address": "866Z6snjQuaAzs6gubHJwPQso6jCrPHrZCY7GqoFVAEE",
            "symbol": "cstlUSDC",
            "name": "Castle Vault USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/castle-finance/castle-lp-token-list/main/assets/mainnet/866Z6snjQuaAzs6gubHJwPQso6jCrPHrZCY7GqoFVAEE/icon.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CastleFinance",
                "website": "https://app.castle.finance/vault/3tBqjyYtf9Utb1NNsx4o7AV1qtzHoxsMXgkmat3rZ3y6"
            }
        },
        {
            "chainId": 101,
            "address": "9ET2QCQJdFkeKkuaampNbmicbA8eLYauFCWch9Ddh9p5",
            "symbol": "CTI",
            "name": "ClinTex CTI",
            "decimals": 9,
            "logoURI": "https://clintex.io/cti_sml.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "ClinTex CTI: Clinical Trials Intelligence.",
                "twitter": "https://twitter.com/ClinTexCTi",
                "website": "https://clintex.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Gu2ouFDwgFzwHSWqNQmfNxu2wcKkPwaVh4yWewzD3rb6",
            "symbol": "SLNDWL",
            "name": "Soland Presale Whitelist",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/logo-soland.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yYR3HA3XZ3r474YSrS5i1uSkxHeKjSBXZQKQeJDQr5n",
            "symbol": "$MGK",
            "name": "Magiken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BlackFlame94/Magicards/main/logo.png",
            "tags": [
                "gaming",
                "nfts",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Ac2kit8FHmzSK6Xkvsk5UTAL3cwVfbppvcWAGYdVCQnU",
            "symbol": "ONYX",
            "name": "ONYX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ferasawadi/onyx-assets/main/onyx-logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLTu66hPZSdCBjH86zkdv8Q3a1HWZvgsYU995a5JWCt",
            "symbol": "MUNSTER",
            "name": "DopeMunsterz WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLTu66hPZSdCBjH86zkdv8Q3a1HWZvgsYU995a5JWCt/logo.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "description": "Each token gives you access to buy a DopeMunster",
                "discord": "https://discord.com/invite/WBAkPTmv",
                "twitter": "https://twitter.com/DopeMunsterz"
            }
        },
        {
            "chainId": 101,
            "address": "HVVS4QVsYwLL7LuvzU5V6Qbot5DjtKYcewaugHEN1gAP",
            "symbol": "BUCKS",
            "name": "Meta Bucks",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/HVVS4QVsYwLL7LuvzU5V6Qbot5DjtKYcewaugHEN1gAP/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "KiDzL41CLQ2A8Jv9civn6xeGZaV5vbZBJG8SqqyTbhK",
            "symbol": "trippykids",
            "name": "trippy kidz Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KiDzL41CLQ2A8Jv9civn6xeGZaV5vbZBJG8SqqyTbhK/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TrippyKidzNFT"
            }
        },
        {
            "chainId": 101,
            "address": "TRENpVRAR9LiZgyYv9zWrQwYqSHa7ThCYdbpFCJixj1",
            "symbol": "TREN",
            "name": "Trenbolone",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TRENpVRAR9LiZgyYv9zWrQwYqSHa7ThCYdbpFCJixj1/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/YokedYetis"
            }
        },
        {
            "chainId": 101,
            "address": "9TFB5m9EKKGSqRqGiohTv6bGAWCFVNqossmjzQohL9N8",
            "symbol": "MPTKN",
            "name": "WyvernLabs Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/redwyvernlabs.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3LE7aFahiHN3rLzuLYAL9HeDgiwp1oX1Sg69ZTX8Z61b",
            "symbol": "OGTKN",
            "name": "WyvernLabs OG Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/pinkwyvernlabs.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kazth76gv4CyufQLiNmYVQVnascfSMjQ4m168ZRsWg5",
            "symbol": "WLTKN",
            "name": "WyvernLabs WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/purplewyvernlabs.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7BTQFmXVZZwwRratNKNpohsLsj4FnCQVQmtY4AR1KgyA",
            "symbol": "FGAUTH",
            "name": "Flaming Galah Mint Token",
            "decimals": 0,
            "logoURI": "https://galah.community/src/img/v1/auth_token.png",
            "tags": [
                "whitelist-token",
                "security-token"
            ],
            "extensions": {
                "description": "A token allowing you to mint a Flaming Galah and join the Galah DAO.",
                "discord": "https://discord.gg/J8Hmep7ZCY",
                "instagram": "https://www.instagram.com/flaming.galahs/",
                "twitter": "https://twitter.com/GalahDAO",
                "website": "https://galah.community/"
            }
        },
        {
            "chainId": 101,
            "address": "ykTEJEezJRBFx5FyWA3X2hRnWjg9C31ZbZDYHWS8yAa",
            "symbol": "SDC",
            "name": "Safe Driving Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5cR6hJJbtV4d14aAtwSeW2oNk8CrCAZphkhXbS45edxH/DamoovSafeCoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Mobile-Telematics",
                "website": "https://www.damoov.com"
            }
        },
        {
            "chainId": 101,
            "address": "AteG8TcRrukZh76yknzY1RkHxGPeG9P1YUgfjxCjg59M",
            "symbol": "EDC",
            "name": "ECO Driving Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Ca87EUd63nMymw3AdeH3YZhiCTV3rLLBu8tNcR1CRFW/DamoovEcoDrivingCoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Mobile-Telematics",
                "website": "https://www.damoov.com"
            }
        },
        {
            "chainId": 101,
            "address": "CvbLKTnBB9zcZHe3P8MDNTMEoMYFcEf16j1YEiSDPJF3",
            "symbol": "PDC",
            "name": "Performance Driving Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4BU2ZbCiRv8NRg59qt55XEo3WqWpZC6dmtV4yc9Xsxo8/DamoovTravellingCoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Mobile-Telematics",
                "website": "https://www.damoov.com"
            }
        },
        {
            "chainId": 101,
            "address": "8S2ASX2WteonH3bgzgmCpbeQ58kzNVpyeinG71xEWtbW",
            "symbol": "DRC",
            "name": "Drivecoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoyBCgc8uupc1J6v9po4zP6WEyuUWgZjJMMLS3oMmfH1/DamoovDriveCoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Mobile-Telematics",
                "website": "https://www.damoov.com"
            }
        },
        {
            "chainId": 101,
            "address": "BVzXdyYVJPXZuEGnQE5Qb2gzFJHcqTfpvPUvvhDkFqe4",
            "symbol": "ICY",
            "name": "icury",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/prakash-syndy/icury-token/main/logo-icury.png",
            "tags": [
                "icecat-token"
            ],
            "extensions": {
                "website": "https://www.icury.com"
            }
        },
        {
            "chainId": 103,
            "address": "BiTkfYDcjCPQu1NsVo5AH7SaNT7tZ37zKcZtc5G4KQ7B",
            "symbol": "vslcard",
            "name": "VSL Car D Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/vietsaclo/Rust.Solana.Tutorial/develop/install-01/vslcard.svg",
            "tags": [
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "MELTC3bafNmWRvUNrFm86aWgyDH7sHPz4L8575tVLmH",
            "symbol": "MLWL",
            "name": "MELT LABS WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MELTC3bafNmWRvUNrFm86aWgyDH7sHPz4L8575tVLmH/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MeltLabs"
            }
        },
        {
            "chainId": 101,
            "address": "EeiNMyKPDUhdkc8xDupMgMgnSos4GNc7RtLy8S1HB1tq",
            "symbol": "BOOGY",
            "name": "Boogy Token",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/EeiNMyKPDUhdkc8xDupMgMgnSos4GNc7RtLy8S1HB1tq/logo.png?raw=true"
        },
        {
            "chainId": 102,
            "address": "DnVRxspeQ6Hy3PjfA1JkTDAUcHrf5cvBf2MG5hMLBXJB",
            "symbol": "ULT",
            "name": "UALIT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/oklitovchenko/logos/main/png/ualit.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GU3eBCktj187ior5PMUzWRn14NZvE2z3W5siEzkuNaaE",
            "symbol": "BWRO",
            "name": "Bawer Online",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Bawer1077/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NFTUcWc3R1cGzpsh9UzGeKnVeXDTJQjRVL6wUM9Qt98",
            "symbol": "NFTU",
            "name": "NFTU",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NFTUcWc3R1cGzpsh9UzGeKnVeXDTJQjRVL6wUM9Qt98/logo.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "BDMW2XP4ZJt6hB8CDKrVWb6kSP1wApf3x9on3Rkp7PAi",
            "symbol": "WUWD",
            "name": "Wulfwaerd Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Mhaug80/Crypto/main/Earth.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "tEArpddkwAQKkCd4jcWqSDp2w1aejGkwSrUu7gjQRPc",
            "symbol": "TEAR",
            "name": "Tear",
            "decimals": 9,
            "logoURI": "https://arweave.net/rPlrQvCvaSANJ6u6rPjo9tH5WwCRubgSmPeE9Q-zqus",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/eyeguysocialclb",
                "website": "https://www.eyeguysocialclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BridfBr4ye3KJP8KjCdt72NkBnyQ1NbpPPN3Z7RtHZUN",
            "symbol": "Birdged",
            "name": "The Bridged Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BridfBr4ye3KJP8KjCdt72NkBnyQ1NbpPPN3Z7RtHZUN/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheFracture_"
            }
        },
        {
            "chainId": 101,
            "address": "F5tmDpUQaH8QKpv5LihZnfcmMoKtB1HZxyzEfBgLJeaN",
            "symbol": "GNOWL",
            "name": "Crypto Gnomes WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F5tmDpUQaH8QKpv5LihZnfcmMoKtB1HZxyzEfBgLJeaN/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "69mPSwgv1jC2SfXP1xNKTQr4GhAaEEQubdtFWe8yT5EB",
            "symbol": "VRX",
            "name": "VirtX Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/69mPSwgv1jC2SfXP1xNKTQr4GhAaEEQubdtFWe8yT5EB/logo.png",
            "tags": [
                "Metaverse",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fc7RRrbXEbrL2KyCiKbd1BiStWuGpxPXHYotTvvoSYYC",
            "symbol": "JUN",
            "name": "Juniverse Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fc7RRrbXEbrL2KyCiKbd1BiStWuGpxPXHYotTvvoSYYC/logo.png",
            "tags": [
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9XL58QrV9ZQHVeyq8tUvByJDjmDz65geCBiRPcnTtPCq",
            "symbol": "GLKV",
            "name": "Gullkorv Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/OAlRa/glkv/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8M7ngdTiASGintaxxpTfutU36tAi98TGGiA3ndGehQmw",
            "symbol": "SPNS",
            "name": "SpoonsCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Midwaymodder/crypto/main/SPNSlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EuttjwkfdDAfF1KoUuLAafC7bsH4du1P7WQKqyvT9757",
            "symbol": "WARI",
            "name": "Opportunities NFTs",
            "decimals": 9,
            "logoURI": "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/EuttjwkfdDAfF1KoUuLAafC7bsH4du1P7WQKqyvT9757/logo.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DpsZg8zMTsdycpikKgYXbZ5FsztBeBiFue2ZgVFErCLs",
            "symbol": "AKELANS",
            "name": "AKELANS",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DpsZg8zMTsdycpikKgYXbZ5FsztBeBiFue2ZgVFErCLs/logo.png",
            "tags": [
                "meme-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Me72Wkq2ce",
                "twitter": "https://twitter.com/Akelasolana",
                "website": "https://akelatoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8txs8CweQjWqiJS9hzYuuisDNfHb8EL4TCkTLUxrZ2WP",
            "symbol": "SHRED",
            "name": "Shred Threads Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/JHockman43/Shred-Threads-Project/main/ST%20logo%20520.png",
            "tags": [
                "NFT-token",
                "community-token",
                "payment-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/ZEWbK8PFXy",
                "instagram": "https://www.instagram.com/shredthreadsnft/",
                "twitter": "https://twitter.com/ShredThreadsNFT",
                "website": "https://www.shredthreadsnfts.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7G3Nzr99ePGSnABp8uSzpQnQGUaWpj2RohRynMtqiuzZ",
            "symbol": "$KOHOLA",
            "name": "KOHOLA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/koholaToken/main/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/Fut_Whale"
            }
        },
        {
            "chainId": 101,
            "address": "8uTnKtEXxdfoJWN9md4dEgBvTYq1S9WsCtLUgZuMk5eU",
            "symbol": "SCAM",
            "name": "SCAM TOKEN PLEASE IGNORE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tokenauth/xnft-token/main/logo/logo.png",
            "tags": [
                "community-token",
                "NFT-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DVFS3CY1VEneDm3zMUZwi5b9ZLk4oJP3UuLpxRYEMywp",
            "symbol": "AYK",
            "name": "AYAKO Whitelist Token",
            "decimals": 0,
            "logoURI": "https://i.postimg.cc/XN1xtY1C/5ape-Sn-Ur-400x400.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Ayako_sol",
                "website": "http://ayako-sol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "SwiMNJ49SxkqMaVWLGGVRH25kE5dBnD2RQoiQUnKtMC",
            "symbol": "xSWIM",
            "name": "Swim Staked SWIM",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SwiMNJ49SxkqMaVWLGGVRH25kE5dBnD2RQoiQUnKtMC/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "SwiMNJ49SxkqMaVWLGGVRH25kE5dBnD2RQoiQUnKtMC",
            "symbol": "xSWIM",
            "name": "Swim Staked SWIM",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SwiMNJ49SxkqMaVWLGGVRH25kE5dBnD2RQoiQUnKtMC/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "FfV1vXZw6bnRLbvuuyySSi5S9pwr1Hs7BbJRKb5oryhQ",
            "symbol": "NUGA",
            "name": "NUGA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FfV1vXZw6bnRLbvuuyySSi5S9pwr1Hs7BbJRKb5oryhQ/logo.jpg",
            "tags": [
                "tokenomics",
                "social-network",
                "new-world-order",
                "community-token"
            ],
            "extensions": {
                "website": "https://thetribes.io"
            }
        },
        {
            "chainId": 101,
            "address": "2w45gq6Q4WrhMaYrKFV9HDb9fjNrs7hWkTnkca4QRw9k",
            "symbol": "MPOTION",
            "name": "Monsta Potion",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/monstadao/logo/main/monsta-potion.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/monstadao_",
                "website": "https://monstadao.com"
            }
        },
        {
            "chainId": 101,
            "address": "FF7rjqkufosL6kTxeXum4BxRhhDrrJXp4VcNZ3bwPjUU",
            "symbol": "WRB",
            "name": "Whitelist Rotten Ville Bust Sculptures",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FF7rjqkufosL6kTxeXum4BxRhhDrrJXp4VcNZ3bwPjUU/LogoRottenVille_Token.png",
            "tags": [
                "whitelist-token",
                "rv",
                "ky",
                "bust-claim"
            ],
            "extensions": {
                "discord": "https://discord.gg/QeR9yvSWvk",
                "twitter": "https://twitter.com/rotten_ville",
                "website": "https://rottenville.io"
            }
        },
        {
            "chainId": 103,
            "address": "HLPKuGCo5a1xki2N8zt19QiCeRfuy1kHtmc8V2vJQxqt",
            "symbol": "UNNJ",
            "name": "socialUni",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HLPKuGCo5a1xki2N8zt19QiCeRfuy1kHtmc8V2vJQxqt/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Wave8p5c8mkGk8ekXnwRGaoCk3ApMRQq7AM7KQuJniK",
            "symbol": "Wave",
            "name": "The wave Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Wave8p5c8mkGk8ekXnwRGaoCk3ApMRQq7AM7KQuJniK/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ThewavesTech"
            }
        },
        {
            "chainId": 101,
            "address": "CftMwm8645rEvpmzHyJuaHFVbUqnAzemjbDYKwfsquss",
            "symbol": "RICH",
            "name": "deMonopoly game Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CftMwm8645rEvpmzHyJuaHFVbUqnAzemjbDYKwfsquss/logo.png",
            "tags": [
                "NFT-token",
                "community-token",
                "payment-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/ZPQMd8G5aa",
                "twitter": "https://twitter.com/deMonopolyNFT",
                "website": "http://www.demonopoly.fun/"
            }
        },
        {
            "chainId": 103,
            "address": "8GzvVqMkLkFp3f24s9Gn9WeCeP65eoJVTqhxZeobZGWR",
            "symbol": "TAM",
            "name": "Tam Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8GzvVqMkLkFp3f24s9Gn9WeCeP65eoJVTqhxZeobZGWR/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://tam-vn.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9uUzFsTmaPXgMmoXvLVgCAwXPxo2zgiXYHN9WrU9BxLx",
            "symbol": "DNRG",
            "name": "Jin's Divine Energy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Crypto-G0d/Holy-Energy/main/JinDivineEnergy.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KomoNuvereGko9jNKUgb3AJZdG3pDaXjbph77ZWvFy1",
            "symbol": "KOMO",
            "name": "Komoverse Token",
            "decimals": 2,
            "logoURI": "https://komoverse.io/assets/img/favicon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/komoverse",
                "github": "https://github.com/komoverse",
                "instagram": "https://instagram.com/komoverse",
                "linkedin": "https://www.linkedin.com/company/komoverse",
                "medium": "https://medium.com/@komoverse",
                "telegram": "https://t.me/komoverse",
                "tiktok": "https://www.tiktok.com/@komoverse",
                "twitter": "https://twitter.com/komoverse",
                "website": "https://komoverse.io",
                "youtube": "https://youtube.com/c/Komoverse"
            }
        },
        {
            "chainId": 101,
            "address": "2uYR4bf8FYsW29RV2QpoT7Qv3xbjzEyiov8njassCeR7",
            "symbol": "HYPE",
            "name": "HYPE Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Salro1g/Token_spl/main/logo.png"
        },
        {
            "chainId": 102,
            "address": "CBzPZ92VgdrXQWGd5gE1x7PXwtMfzf6EM8K1ZTFmSHXr",
            "symbol": "BETX",
            "name": "BetDEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HX7SXjc2D1FDY9pxk2AUaePWp54hkUnyyCLpHYe9CV78/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/BetDEXLabs",
                "website": "https://www.betdex.com/"
            }
        },
        {
            "chainId": 103,
            "address": "7rcZJW5TQVcUzRE8aHdHb7woGH7uTqDpj81AroikXXSX",
            "symbol": "BETX",
            "name": "BetDEX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HX7SXjc2D1FDY9pxk2AUaePWp54hkUnyyCLpHYe9CV78/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/BetDEXLabs",
                "website": "https://www.betdex.com/"
            }
        },
        {
            "chainId": 103,
            "address": "2quyJixqNe3id7TY54vYzhtLYNYLYdfsbbA9nfsoxriF",
            "symbol": "BIND",
            "name": "BIND",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2quyJixqNe3id7TY54vYzhtLYNYLYdfsbbA9nfsoxriF/BIND.svg",
            "extensions": {
                "website": "https://bind.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5ffMTb6rKYswSfnJM6CYYu5Z6rabLeS7sSHo2KSLRxAc",
            "symbol": "TB",
            "name": "TwoBits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aterzos/two-cents-img/main/twocents-1208151868.jpeg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GRDWJqzoSWNM67uW951wTHftTM7vznnFnugaAzCYM7MA",
            "symbol": "GEO",
            "name": "Geo",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/GeomyCode/assets/main/icons/geo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "GFfhm8Bt4P5957iDVaMcKjodaFWqxx2CJWLSCnUQY2oB",
            "symbol": "BALGR",
            "name": "BALGREEN More Balance More Green",
            "decimals": 6,
            "logoURI": "https://github.com/sparkrisp/balgreen/blob/main/BGREEN_isoLogo_1.png",
            "tags": [
                "ecologic-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CLubYr6VMX5tr1GNaVe1eGTd1stARjiQKmP5hqwC41qD",
            "symbol": "CLUB",
            "name": "THE CLUB WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLubYr6VMX5tr1GNaVe1eGTd1stARjiQKmP5hqwC41qD/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theclubnft"
            }
        },
        {
            "chainId": 101,
            "address": "UrbatHbZxMFiHdqLNw2NkjBhqKDEPjrxssuFq4Zuh5e",
            "symbol": "UBWLT",
            "name": "Urbats WhiteListToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UrbatHbZxMFiHdqLNw2NkjBhqKDEPjrxssuFq4Zuh5e/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "description": "Use this token to participate in the presale of our collection! Keep it in your wallet until the Mint.",
                "discord": "https://discord.com/invite/XbPkjgbnUc",
                "facebook": "https://www.facebook.com/urbats.io",
                "instagram": "https://www.instagram.com/urbats.io/",
                "twitter": "https://twitter.com/Urban_Bats",
                "website": "https://urbats.io/"
            }
        },
        {
            "chainId": 101,
            "address": "B5UrfiGMqqnr9afZP6HNYHjcawQucmzunU4PMo7u93ba",
            "symbol": "SIVA",
            "name": "SivaCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sivacoin/logo/main/siva-logo-2k.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Community engagement reward token (engage2earn)",
                "discord": "https://discord.gg/TF2R73RWQt",
                "twitter": "https://twitter.com/sivacoin",
                "website": "https://siva.gg"
            }
        },
        {
            "chainId": 103,
            "address": "CaRbonA6QNTWAKhq1oaH8BVBRyNvYkWYBLb4KqkeQ5jH",
            "symbol": "carbon",
            "name": "Carbon Credit Coin",
            "decimals": 6,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/carbon/carbon-credit.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "description": "Proof of Carbon Sequestration to be used as Carbon credit (unit: 1kg CO2e)",
                "imageUrl": "https://bafybeid7dw4tdtbuj5pn4gqlqooajzfaecujcupwzgu2bp6p6z46qlv2g4.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "website": "https://PurpleZone.github.io/NFTs/carbon/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmNwPaqvPMZKxEDZB8vr63wTmf5bZ2JocYY7HamWY8ws3E/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "GcexYp3KGE8YkpKwaCJKxMFd33Q2xF9ba1RNRrh43sFm",
            "symbol": "KUPA",
            "name": "Kuta Paisa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/haider954/kutapaisa/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "p1u2eUjLLm7Q5P3WFhcXF972dsdWdcZ4x6hEyTtNLAb",
            "symbol": "LSC",
            "name": "LegendCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Legendstyler00/legendcoin/main/lele2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E3ph1yXJ6ChaVjeLZzVkGSaVChj7iDzY4YWzwWDTF98T",
            "symbol": "MIN",
            "name": "Made in Thailand",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/logo-min.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/ThailandSuppliers",
                "telegram": "https://t.me/made_in_thailand",
                "website": "https://www.ezdal.com"
            }
        },
        {
            "chainId": 101,
            "address": "spr1yvmfxbPVW9Pn1Nn5uHJzunKXZGLwytSvGgHNv46",
            "symbol": "SPRAY",
            "name": "SPRAY - Oh My Girls",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/spr1yvmfxbPVW9Pn1Nn5uHJzunKXZGLwytSvGgHNv46/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7F85925Q5RnvyRwbfBd8UQvRW25fMrvFrMErgN3M5wCT",
            "symbol": "ASHIRA",
            "name": "ashiraCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/ashiraCoin/crypto/main/ashiraCoin%20logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9nGDRoax8XNQow3HLUiZ6JfMGUiS3WDK1fLz8LZ7KCJV",
            "symbol": "CC",
            "name": "Clown Collective WL Token",
            "decimals": 2,
            "logoURI": "https://metadata.clowncollective.io/cc-wl-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/Clowncollective",
                "twitter": "https://twitter.com/ClownColl3ctive",
                "website": "https://www.clowncollective.io"
            }
        },
        {
            "chainId": 101,
            "address": "9STaUry5ZwR214wMqsWEFWXpvseuyWCddFLMLKAMyeG",
            "symbol": "$CRYPTID",
            "name": "CRYPTID",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/cryptidToken/main/logo.jpg",
            "extensions": {
                "twitter": "https://twitter.com/TheSasquatchnft"
            }
        },
        {
            "chainId": 101,
            "address": "HSW6TGC6AeTgRX51Rt8ksbSMzJmC3kGeHeaxyxWGuPwd",
            "symbol": "TCOIN",
            "name": "TesterCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/qetohotest/test_cryptocurrency_token_solana/main/token_test.png",
            "tags": [
                "test_token",
                "token_test"
            ]
        },
        {
            "chainId": 101,
            "address": "EUWZ574ghzvs8Yj78QARDq9WQyQYxt1rWGz3dNfTEdqx",
            "symbol": "SLP",
            "name": "SOLPLUS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EUWZ574ghzvs8Yj78QARDq9WQyQYxt1rWGz3dNfTEdqx/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3z2vPLw1k9whGwM2KqMMVruKGZmecfEFmeAiXzqhXrUr",
            "symbol": "NESTWL",
            "name": "Nest Arcade WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3z2vPLw1k9whGwM2KqMMVruKGZmecfEFmeAiXzqhXrUr/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E1mL8g2pFWcBqR8xM9Z6jM8cyGHTv2cwcv8XbRmpKpLo",
            "symbol": "FEHU",
            "name": "Fehu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1mL8g2pFWcBqR8xM9Z6jM8cyGHTv2cwcv8XbRmpKpLo/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://valhallavikings.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FdviznPoMEakdJ37fikNxhoscyruUHSHNkKyvntSqbuo",
            "symbol": "$CATNIP",
            "name": "CATNIP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdviznPoMEakdJ37fikNxhoscyruUHSHNkKyvntSqbuo/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WrUgj3BhrN",
                "twitter": "https://twitter.com/FatCatsCapital",
                "website": "https://fatcatscapital.com"
            }
        },
        {
            "chainId": 101,
            "address": "27YTm6d9hbcMAjfahTcMhj1ghBceB18hwEm8C4L38VXS",
            "symbol": "PLVS",
            "name": "PLVS VLTRA",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/27YTm6d9hbcMAjfahTcMhj1ghBceB18hwEm8C4L38VXS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/plvsvltra_inv"
            }
        },
        {
            "chainId": 101,
            "address": "HawL4cqiNoYdTre8bExsEM3TqrUqigd6YV4fJsNMVEng",
            "symbol": "SST",
            "name": "Solana Scouts Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HawL4cqiNoYdTre8bExsEM3TqrUqigd6YV4fJsNMVEng/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solanascouts",
                "twitter": "https://twitter.com/SolanaScouts",
                "website": "https://solanascouts.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AkAF22MXs5xcwH1BineDvrD81sFV1dCuXX5PQzYdko46",
            "symbol": "WNDY",
            "name": "Wandyclub Social Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkAF22MXs5xcwH1BineDvrD81sFV1dCuXX5PQzYdko46/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/wandyclub",
                "website": "https://www.wandyclub.com"
            }
        },
        {
            "chainId": 101,
            "address": "Fr5PGegS78PVfXAKNdxBv2NqWSvropf28xhnhDmwP9V5",
            "symbol": "BYUL",
            "name": "ByulCoin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fr5PGegS78PVfXAKNdxBv2NqWSvropf28xhnhDmwP9V5/logo.png",
            "tags": [
                "community-token",
                "ByulCoin"
            ],
            "extensions": {
                "website": "http://star-nft.co.kr/"
            }
        },
        {
            "chainId": 101,
            "address": "WLTQmV3VDxgUqvEPyBv3nCCMdfkV2b92yC4KRtvZajN",
            "symbol": "LPWLT",
            "name": "Lion Pride Collective Whitelist Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLTQmV3VDxgUqvEPyBv3nCCMdfkV2b92yC4KRtvZajN/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://t.co/te0ypLTUGO",
                "medium": "https://medium.com/@lionprideclub",
                "telegram": "https://t.me/LionPrideClub",
                "twitter": "https://twitter.com/LionPrideClub",
                "website": "https://lionprideclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ELXgUrK1F8hoVzcrE5HD8bTJn1QZ9mGWMA9dM4kpEaJX",
            "symbol": "KME",
            "name": "Kamen Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELXgUrK1F8hoVzcrE5HD8bTJn1QZ9mGWMA9dM4kpEaJX/logo.svg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/kamennft",
                "instagram": "https://www.instagram.com/kamennft/",
                "twitter": "https://twitter.com/kamen_nft",
                "website": "https://www.kamennft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GUn2MnTKac8wUCm9TroPtcPhFz9eQQe8W3kSu3oAA6Yx",
            "symbol": "BYL",
            "name": "BYUL",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUn2MnTKac8wUCm9TroPtcPhFz9eQQe8W3kSu3oAA6Yx/logo.png",
            "tags": [
                "community-token",
                "BYULCOIN"
            ],
            "extensions": {
                "website": "http://star-nft.co.kr/"
            }
        },
        {
            "chainId": 101,
            "address": "3GhLNM5EuuuBPZyMRweMbGgMWaVdA5YpLWU1NRAERi6d",
            "symbol": "LITIT",
            "name": "litit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GhLNM5EuuuBPZyMRweMbGgMWaVdA5YpLWU1NRAERi6d/lit.it-token-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://lit.it"
            }
        },
        {
            "chainId": 101,
            "address": "5vRFqzW3pkw2bcjaVwM3Sdzhnf3Czg67a2L3iDKbY78n",
            "symbol": "VRKWL",
            "name": "vrakka WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5vRFqzW3pkw2bcjaVwM3Sdzhnf3Czg67a2L3iDKbY78n/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLTJb89azAxv6wiPM2yGR1v6CLEoEkSrC1C5JqgsF5h",
            "symbol": "LIONWLT",
            "name": "Lion Pride NFT Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLTJb89azAxv6wiPM2yGR1v6CLEoEkSrC1C5JqgsF5h/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://t.co/te0ypLTUGO",
                "medium": "https://medium.com/@lionprideclub",
                "telegram": "https://t.me/LionPrideClub",
                "twitter": "https://twitter.com/LionPrideClub",
                "website": "https://lionprideclub.io/"
            }
        },
        {
            "chainId": 101,
            "address": "42EcKSvDBQrezYudAF8DHGwDzU677kYkJMJqWKtaKiyh",
            "symbol": "SUN",
            "name": "SUN COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/femi-taiwo/token-list/main/assets/mainnet/42EcKSvDBQrezYudAF8DHGwDzU677kYkJMJqWKtaKiyh/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sfA67huhJ4",
                "website": "https://thedreammakersclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "828oFZuUAtisZ1n17DEH2yys9foTXgJz7xqZj9YwGubu",
            "symbol": "ANTLER",
            "name": "antler",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/828oFZuUAtisZ1n17DEH2yys9foTXgJz7xqZj9YwGubu/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Utility token for the Loose Moose Crew ecosystem",
                "discord": "https://discord.gg/loosemoosecrew",
                "twitter": "https://twitter.com/LooseMooseCrew",
                "website": "https://loosemoosecrew.com"
            }
        },
        {
            "chainId": 101,
            "address": "HRdi71vK23SRF4V5G1SnpNQFSNrnkbyeRsiwj4HWC7Fe",
            "symbol": "BLD",
            "name": "Blood Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRdi71vK23SRF4V5G1SnpNQFSNrnkbyeRsiwj4HWC7Fe/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/UndeadSols"
            }
        },
        {
            "chainId": 101,
            "address": "3TspxMnkVCMZbJyz4TPj7i7tKVdUbCs9pGidQXqqUJSB",
            "symbol": "CORNY",
            "name": "CORN COB COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tseibel/crypto/main/corn_square.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GLitgDLt6T4HNpo2CTcaWeCqZLhKNneTgvyPPnckd9bA",
            "symbol": "GLIT",
            "name": "Glitch",
            "decimals": 9,
            "logoURI": "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/GLitgDLt6T4HNpo2CTcaWeCqZLhKNneTgvyPPnckd9bA/logo.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "42QCiaETY8Xobnky47bRGr7K4pudAVr4nqgMMZfSZKEB",
            "symbol": "THEIR",
            "name": "Test HEIR Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/42QCiaETY8Xobnky47bRGr7K4pudAVr4nqgMMZfSZKEB/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EwKzqVEaE3H6YBA16pndGzN7zWiMLZWbrzwWvseUSeeD",
            "symbol": "GCAC",
            "name": "GavCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DjBigG122/new-crypto-logo/main/crypto-logo-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BTCG4EGjivgfgnfKfkqgZkDXuj5KdTeWuJsEGFFzkjai",
            "symbol": "btc-g",
            "name": "Greener Bitcoin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/btcg/btc-g.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "bitcoin",
                "description": "Greening cryptocurrencies one coin at a time",
                "imageUrl": "https://bafybeiakx66r7u5u5sxi24b25k4y5qm6zhpyuetocphl7kfcs4yij6zjgy.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/btcg/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmUs6X2QHrPSAW9mZYtHGNCDjMDWTk7cg3EiEQ1Ao4PvL9/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "JCS2Fd7QwmXFR4pbeHCtsY4aCuzm6PMgb3nctK513svU",
            "symbol": "FALK",
            "name": "Falko Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FALKO99/FALKO/main/FALKO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PPP3KgQo2Vs5MkqpKHTH3H9BorCh1EYwt7cnjjavthL",
            "symbol": "trippykid",
            "name": "Trippykidz Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PPP3KgQo2Vs5MkqpKHTH3H9BorCh1EYwt7cnjjavthL/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TrippyKidzNFT"
            }
        },
        {
            "chainId": 101,
            "address": "DkfTo5ozVuzn8ge8pxXdSA7auHWAtn5VzwoXV4dvXuii",
            "symbol": "HC",
            "name": "Hurel Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/martinhurel/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "gr6V8YHjagNd4DvTBMrHY14GTu4b9CdFegQ7oCkvVNM",
            "symbol": "EZT",
            "name": "EZT Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/gr6V8YHjagNd4DvTBMrHY14GTu4b9CdFegQ7oCkvVNM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://www.ezhigh.co.kr"
            }
        },
        {
            "chainId": 101,
            "address": "1ZKwykzoyXHKZdayRAJHQ7ZY8fcZrfmwhuwPLxKdEW3",
            "symbol": "DHL",
            "name": "Diamond Hand: Legends Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1ZKwykzoyXHKZdayRAJHQ7ZY8fcZrfmwhuwPLxKdEW3/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/dhlegends",
                "website": "https://www.diamondhandlegends.com"
            }
        },
        {
            "chainId": 101,
            "address": "2Brf6wicHUX2MKkGxFdpCMaSuwZdgkikDfTCx9Vzauzk",
            "symbol": "LENI",
            "name": "LeninCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/seanmcalevey/lenin_coin/main/lenin_coin_logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SKC5GoMp18Yd9YLLrbWUphy9d85YuHBwv1HHLJM3f5w",
            "symbol": "SKCT",
            "name": "SKC Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SKC5GoMp18Yd9YLLrbWUphy9d85YuHBwv1HHLJM3f5w/logo.png"
        },
        {
            "chainId": 101,
            "address": "GGTGLHPUaEmGSQMo89FoYXJywuQBPnbqHuBjHivgpKZY",
            "symbol": "GOBLET",
            "name": "Golden Goblet",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGTGLHPUaEmGSQMo89FoYXJywuQBPnbqHuBjHivgpKZY/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/pqVDAw7DE9",
                "twitter": "https://twitter.com/goblinraidsnft",
                "website": "https://goblinraid.passivepad.cc"
            }
        },
        {
            "chainId": 103,
            "address": "5g5Yc2jNizJFfCqf4oauxZdQTqHAaDiLE183TSuAb51A",
            "symbol": "TSBT",
            "name": "Test boy Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5g5Yc2jNizJFfCqf4oauxZdQTqHAaDiLE183TSuAb51A/logo.png"
        },
        {
            "chainId": 101,
            "address": "A5NF1e6RnYkVwtg3V3z1qeUz4PZfHCXmQ9RotuJWgi6F",
            "symbol": "VYNC",
            "name": "VYNK CHAIN",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5NF1e6RnYkVwtg3V3z1qeUz4PZfHCXmQ9RotuJWgi6F/logo.png",
            "tags": [
                "utility-token",
                "VYNCTOKEN",
                "community-token"
            ],
            "extensions": {
                "website": "https://vynkchain.org"
            }
        },
        {
            "chainId": 101,
            "address": "JQDgss9tWA4sAsmLgSY2f5M7h1DduT4wXvt3E4RnSuJ",
            "symbol": "NC",
            "name": "Nueve Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JQDgss9tWA4sAsmLgSY2f5M7h1DduT4wXvt3E4RnSuJ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EkhbjG14GJdnWkKSTdnPSG8xhFDmh855XK1xKAFjYmUR",
            "symbol": "$BDAWG",
            "name": "BDAWG",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/BDAWG_coin.png"
        },
        {
            "chainId": 103,
            "address": "BL7nWUGANnKHMiJtVWAUJp8xbuCoqsgPcLBXPffU9Td8",
            "symbol": "CDFI",
            "name": "Cleandefi",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BL7nWUGANnKHMiJtVWAUJp8xbuCoqsgPcLBXPffU9Td8/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://www.cleandefi.com"
            }
        },
        {
            "chainId": 101,
            "address": "BL7nWUGANnKHMiJtVWAUJp8xbuCoqsgPcLBXPffU9Td8",
            "symbol": "CDFI",
            "name": "Cleandefi",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BL7nWUGANnKHMiJtVWAUJp8xbuCoqsgPcLBXPffU9Td8/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://www.cleandefi.com"
            }
        },
        {
            "chainId": 101,
            "address": "3G6UDViPSymTnpEoFeoPqMRjA1VE1HqWLVf8kHULewaj",
            "symbol": "GRASS",
            "name": "Grass",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PnW1/grassToken/main/logo.png"
        },
        {
            "chainId": 102,
            "address": "B8mnGmyZ91zRbVZW1sxy4wjDAgV5vZCGn3eh4mB4gGYF",
            "symbol": "GYDC",
            "name": "GYD Coin",
            "decimals": 9,
            "logoURI": "https://cricnation592.sfo3.cdn.digitaloceanspaces.com/logo.png",
            "tags": [
                "utility-coin",
                "social-defi"
            ]
        },
        {
            "chainId": 101,
            "address": "GoLDYyyiVeXnVf9qgoK712N5esm1cCbHEK9aNJFx47Sx",
            "symbol": "GOLDY",
            "name": "DeFi Land Gold",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoLDYyyiVeXnVf9qgoK712N5esm1cCbHEK9aNJFx47Sx/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/defiland",
                "medium": "https://defiland.medium.com/",
                "telegram": "https://t.me/defiland_official",
                "twitter": "https://twitter.com/DeFi_Land",
                "website": "https://defiland.app/"
            }
        },
        {
            "chainId": 103,
            "address": "BTCG2sUyE8qtfLNuk73cdEET2YQVbgrc7VzdW5B4RZ3b",
            "symbol": "green",
            "name": "Greener Bitcoin (dev)",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/btcg/btc-g.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "bitcoin",
                "description": "Greening cryptocurrencies one coin at a time",
                "imageUrl": "https://bafybeiakx66r7u5u5sxi24b25k4y5qm6zhpyuetocphl7kfcs4yij6zjgy.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/btcg/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmUs6X2QHrPSAW9mZYtHGNCDjMDWTk7cg3EiEQ1Ao4PvL9/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "BTCdSsSQeS44k2kgpo8URUZWrACHvHAdoLewt3Q3EGU6",
            "symbol": "DBTC",
            "name": "Dirty BitCoin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/btcd/dirty-bitcoin.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "Bitcoin pending to carbon removal",
                "imageUrl": "https://bafybeidawinaasorjj6i6ftg5kgdlaibtwxaxhetioefi2scnzny5ai73u.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/btcd/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmTqYzytHkEjTZyBxBBPbvMyXqUABc55aSwGUNbAYtVzXu/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "CarBoNrDsrBtp4YgjuxjXQnrpaLDB4HoL2dDLBFUnZjq",
            "symbol": "kg",
            "name": "Removed Carbon Coin",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/sequestred/carbon-removed.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "Carbon Sequestration (unit: 1kg CO2e)",
                "imageUrl": "https://bafybeidf6nrmoxuik2y2g27jnxuj5c3gphlr75ndrkqx267h3nwszh7uya.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/sequestred/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmNwPaqvPMZKxEDZB8vr63wTmf5bZ2JocYY7HamWY8ws3E/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "C1RcPUJHx1Mon7RqDck2KUco8jkYraMjNCdA3K2NP7Wk",
            "symbol": "colorcoin",
            "name": "Colored Generic Token",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/colorcoin/colorcoin.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "This generic token is the equivalent of color coin on bitcoin network: it carries a color: 32B hash as an addr",
                "imageUrl": "https://bafybeihkbjkjiqsvdez4jxiwiqbaeamp7gvidia4ss6htobcqsydpfmx3u.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/colorcoin",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmTSW3uzsinLRN6TGt1igwuY6aMcMidmRpUR6fhhSG7Euv/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "GcoinFPXjY1iWwLCgAuYm6Eks18bP4KKP3FUVg9G53X7",
            "symbol": "FGC",
            "name": "Fractional Green Coin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@master/gcoin/gcoin.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "The only way to get carbon negative cryptocurrency is using gcoin: a CO2 accountancy NFT",
                "imageUrl": "https://bafybeifpmi7gyvlze7jumfkqwcvi6vjtz2ojropt32knktwrzwlr5hw6fa.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://ipfs.safewatch.care/ipns/QmTo1AnNH7Snu37Dotphw2fX54u1S5VLFpnnERN7GbyUrW/#gcoin",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmTo1AnNH7Snu37Dotphw2fX54u1S5VLFpnnERN7GbyUrW/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "giftiAvNxpqPLzvrAu78xfgZ3Z9o64ecSSLKG3GkEvc",
            "symbol": "gift",
            "name": "Quantifed Gift",
            "decimals": 0,
            "logoURI": "https://bafybeighotrgs2akl3tfsnznwpepzgk2taams4bgix5kxc5zgiq5dvyqnq.ipfs.nftstorage.link/?ext=svg",
            "tags": [
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "description": "A gift goes long way, this is a NFT you can donnate with the playload you want",
                "discord": "https://discord.gg/vwkCmMQv",
                "imageUrl": "https://ipfs.safewatch.care/ipfs/QmUw3iyMPqKuajnLRevFyEU8Yn1ZGLGA2pb8A11BBuj4NQ/gift.svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://ipfs.safewatch.xyz/ipfs/QmUw3iyMPqKuajnLRevFyEU8Yn1ZGLGA2pb8A11BBuj4NQ/#gift",
                "whitepaper": "https://gateway.ipfs.io/ipfs/QmUw3iyMPqKuajnLRevFyEU8Yn1ZGLGA2pb8A11BBuj4NQ/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "PHXCEm9BjgB5nxySKvGVb7naLS2fcU2DqdU529sGa8G",
            "symbol": "phxshare",
            "name": "PhenomX Shares",
            "decimals": 6,
            "logoURI": "https://bafybeice4ze77lnjlrv42d2pyobeccwc6guyfw7absvt7fhfjv5ofgm5pe.ipfs.nftstorage.link/?ext=svg",
            "tags": [
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "description": "PhenomX Health shares",
                "discord": "https://discord.gg/vwkCmMQv",
                "imageUrl": "https://ipfs.safewatch.care/ipfs/QmepciACTEREwoeoMxLJfaGcPD56DHefJCeYbPKDyZBo6B/phxcoin.svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://ipfs.safewatch.care/ipfs/QmepciACTEREwoeoMxLJfaGcPD56DHefJCeYbPKDyZBo6B/#phxcoin",
                "whitepaper": "https://gateway.ipfs.io/ipfs/QmepciACTEREwoeoMxLJfaGcPD56DHefJCeYbPKDyZBo6B/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "pmfa5SgJwSrmDf66gvob2tUyzx3vSZSZ63pff3yGXiH",
            "symbol": "pmfa",
            "name": "PMF A-Coin",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@master/pmfa/pmfa.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "PMF-A Token",
                "imageUrl": "https://bafybeicskrgdfnhrwn5jjz2l3h7kk5au6fz6hivjfxq2mv4lw5wymfejpm.ipfs.nftstorage.link/?ext=svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://ipfs.safewatch.care/ipns/QmVfmosDQdVzHRH2cGgWyXKsZ5s5iub677ZtUcq6Ao2DJt/#pmfa",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmVfmosDQdVzHRH2cGgWyXKsZ5s5iub677ZtUcq6Ao2DJt/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "NfTyU3JxnZtticXZ5LsmzxHsUbCemLox66huiWqmNtc",
            "symbol": "WNFT",
            "name": "Wrapped NFT",
            "decimals": 9,
            "logoURI": "https://bafybeihlud5s72vd2h7bd5njpkxc6c546y3uhfdo3w56ujrcfhf2dr6dme.ipfs.nftstorage.link/?ext=svg",
            "tags": [
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "description": "NFT are nothing else than glorified hash, so by wrapping a hash in an NFT we have a generic vehicle to manage any NFTs",
                "discord": "https://discord.gg/vwkCmMQv",
                "imageUrl": "https://ipfs.safewatch.care/ipfs/QmetuPoEjrgbzMjCHPsybpndbaDmBSLaNZutFA5hwFzgJS/WNFT.svg",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://ipfs.safewatch.care/ipfs/QmetuPoEjrgbzMjCHPsybpndbaDmBSLaNZutFA5hwFzgJS/#WNFT",
                "whitepaper": "https://gateway.ipfs.io/ipfs/QmetuPoEjrgbzMjCHPsybpndbaDmBSLaNZutFA5hwFzgJS/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "CArBo4puwrkEsEqDY5SDxNwj31bi3V2ZubimRNSwXVNr",
            "symbol": "kg",
            "name": "Carbon Credit",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/carbon/carbon-credit.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "wrapped-bitcoin",
                "description": "Proof of Carbon Sequestration to be used as Carbon credit (unit: 1kg CO2e)",
                "imageUrl": "https://bafybeid7dw4tdtbuj5pn4gqlqooajzfaecujcupwzgu2bp6p6z46qlv2g4.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "website": "https://PurpleZone.github.io/NFTs/carbon/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmNwPaqvPMZKxEDZB8vr63wTmf5bZ2JocYY7HamWY8ws3E/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "D1pyrui915KubTU59ByWWKuNfNHc9wmJEAq23CGDsGiw",
            "symbol": "MIND",
            "name": "MIND",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D1pyrui915KubTU59ByWWKuNfNHc9wmJEAq23CGDsGiw/logo.png",
            "extensions": {
                "discord": "https://discord.gg/goi",
                "twitter": "https://twitter.com/Imaginarium_SOL"
            }
        },
        {
            "chainId": 101,
            "address": "3XtB8t3xsSDb7itwrub75R7S3urV2HzHQKS3d6MuBBJW",
            "symbol": "$SC",
            "name": "Stoiccoin",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3XtB8t3xsSDb7itwrub75R7S3urV2HzHQKS3d6MuBBJW/logo.png"
        },
        {
            "chainId": 101,
            "address": "2Fn8iT9MtLZCvu72ntoT7QpdWtQx4aZhGLb48AAbrqw6",
            "symbol": "PPGT",
            "name": "PotterPlaysGames Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PotterPlaysGames/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CiBddaPynSdAG2SkbrusBfyrUKdCSXVPHs6rTgSEkfsV",
            "symbol": "gSOL",
            "name": "GooseFX LP Token (gSOL)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CiBddaPynSdAG2SkbrusBfyrUKdCSXVPHs6rTgSEkfsV/icon.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cDEPXpY26q",
                "telegram": "https://t.me/goosefx",
                "twitter": "https://twitter.com/GooseFX1",
                "website": "https://goosefx.io"
            }
        },
        {
            "chainId": 101,
            "address": "7Hvq1zbYWmBpJ7qb4AZSpC1gLC95eBdQgdT3aLQyq6pG",
            "symbol": "gUSDC",
            "name": "GooseFX LP Token (gUSDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Hvq1zbYWmBpJ7qb4AZSpC1gLC95eBdQgdT3aLQyq6pG/logo.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cDEPXpY26q",
                "telegram": "https://t.me/goosefx",
                "twitter": "https://twitter.com/GooseFX1",
                "website": "https://goosefx.io"
            }
        },
        {
            "chainId": 101,
            "address": "iVMbf6rkeZKXXLGNjrJhMZL3KFDuo4h1C3RqAyeYaBa",
            "symbol": "IVM",
            "name": "InvestmorCoin",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iVMbf6rkeZKXXLGNjrJhMZL3KFDuo4h1C3RqAyeYaBa/logo.png",
            "extensions": {
                "website": "https://investmorcoin.com"
            }
        },
        {
            "chainId": 101,
            "address": "5aqxDnPXy6kqTBTQWRYBQ8RdsmjoY9TNLPRjV3PiFKAB",
            "symbol": "NBUS",
            "name": "Incubus",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5aqxDnPXy6kqTBTQWRYBQ8RdsmjoY9TNLPRjV3PiFKAB/logo.png",
            "tags": [
                "community-token",
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZenJuJnFPUrja8HZ74Pj5tySvaJ6xCBZPm6ghT6v3mr",
            "symbol": "ZVWL",
            "name": "Zenjin Viperz WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZenJuJnFPUrja8HZ74Pj5tySvaJ6xCBZPm6ghT6v3mr/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZenjinViperz"
            }
        },
        {
            "chainId": 101,
            "address": "FDU3nHdhdaj6Wk35pKqXsDaoDDZTysCqkkSnQY8HcZ8E",
            "symbol": "JUMP",
            "name": "Double Jump",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDU3nHdhdaj6Wk35pKqXsDaoDDZTysCqkkSnQY8HcZ8E/logo.png"
        },
        {
            "chainId": 101,
            "address": "bELTRVTyKjwQxya7CztPdvpHTHXuLKmowsbeD2NUHpD",
            "symbol": "HHXC",
            "name": "How High Extra Credit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bELTRVTyKjwQxya7CztPdvpHTHXuLKmowsbeD2NUHpD/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HhSqZi6u5jYruwxN4r4vRfkRmqp2tNvqwXw4wEXVkP6b",
            "symbol": "ERAS",
            "name": "EraSpheres",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HhSqZi6u5jYruwxN4r4vRfkRmqp2tNvqwXw4wEXVkP6b/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/REPrVxA2gN",
                "telegram": "https://t.me/eraspheres",
                "twitter": "https://twitter.com/eraspheres",
                "website": "https://eraspheres.com/"
            }
        },
        {
            "chainId": 101,
            "address": "WLUczF9P5wKLfq53ZSR9UGgBmwxXcELXpECnRyzS2Jp",
            "symbol": "CHEESMT",
            "name": "CHEES Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLUczF9P5wKLfq53ZSR9UGgBmwxXcELXpECnRyzS2Jp/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DGEz5naP1RuvitQSvrFXNg8sM6waaEjkwj3P4VCVAm1t",
            "symbol": "TestA",
            "name": "TestAAAAA",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DGEz5naP1RuvitQSvrFXNg8sM6waaEjkwj3P4VCVAm1t/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "Co2FP2AdUaKmUMLg4aGRTTNqKAQEwgAgAh5RVFXJhzar",
            "symbol": "kgCO2eq",
            "name": "Carbon Footprint",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/kgCO2e/carbon-weight.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "euro-tether",
                "description": "Accounting for CO2eq emissions",
                "imageUrl": "https://bafybeidyuuvtfjbkz3pivhtc5ttdi6up3evhrxjkbd6fpuwla3hoj6x3lu.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/kgCO2e/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmepRU1Rr2SLURq9m2AkiyC1E8hRMBzetrq5TrQxCxNh8E/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "FFAWLA1yaqYXmbYVofEQYH19mz7WZDb57V2h63BsvoKo",
            "symbol": "FFA-WL",
            "name": "FFA Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FFAWLA1yaqYXmbYVofEQYH19mz7WZDb57V2h63BsvoKo/logo.png",
            "tags": [
                "utility-token",
                "whitelist-ticket",
                "FFA-whitelist",
                "FFA"
            ],
            "extensions": {
                "description": "Each ticket gives you access to buy one NFT on https://www.fancyfoxassociation.org/ before the mint date and with a discount!!",
                "discord": "https://discord.gg/9ynuEbpzcR",
                "telegram": "https://t.me/+cypOc8aptmhlNWQ1",
                "twitter": "https://twitter.com/fancyfoxassoc",
                "website": "https://www.fancyfoxassociation.org/"
            }
        },
        {
            "chainId": 101,
            "address": "PHNX6NaMPbSPNvhDWEvr18dAfjM4Qz2PBYvm5NvgcD9",
            "symbol": "PHNX",
            "name": "PHOENIX BURN ",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PHNX6NaMPbSPNvhDWEvr18dAfjM4Qz2PBYvm5NvgcD9/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "PHOENIX BURN"
            }
        },
        {
            "chainId": 101,
            "address": "DTMnsXFNxkeLAHWYqGrfzjKArqpqzLaTMpdkzkaFUcDA",
            "symbol": "POH",
            "name": "POH Crypto",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Procuste34/poh_crypto/main/poh_image.png",
            "tags": [
                "meme",
                "ginette",
                "nft"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/mpbj_memes/",
                "twitter": "https://twitter.com/ProcusteiOS"
            }
        },
        {
            "chainId": 101,
            "address": "CSKh2PUQ3WENAKBjMTd2su8MFENU9roRw5p1hJ5meTSC",
            "symbol": "RCHWST",
            "name": "deMonopoly Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CSKh2PUQ3WENAKBjMTd2su8MFENU9roRw5p1hJ5meTSC/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3d1oXrdZATm2vcZ44tUtBmWcAXPziWmZfxbsVt9smHkC",
            "symbol": "wrBTC-USDC",
            "name": "Player 2 LP Token (wrBTC-USDC)",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3d1oXrdZATm2vcZ44tUtBmWcAXPziWmZfxbsVt9smHkC/logo_P2_black.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/player2",
                "github": "https://github.com/player2world",
                "reddit": "https://www.reddit.com/r/player2world",
                "twitter": "https://twitter.com/player2world"
            }
        },
        {
            "chainId": 101,
            "address": "BHW6DFARzuPRySYebEnTLQKzNV8p8nJgC8VMSXGhnqiM",
            "symbol": "LILLY",
            "name": "LILLY",
            "decimals": 0,
            "logoURI": "https://imgur.com/wVWGpJL.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://imgur.com/1O39DGg.mp4",
                "description": "lilly forever",
                "imageUrl": "https://imgur.com/wVWGpJL.png",
                "website": "https://www.bloomon.nl"
            }
        },
        {
            "chainId": 101,
            "address": "3hnUjiTnCnSS1FmdFDxCPgMAb7r724Boa3vWb3p3WJuV",
            "symbol": "TRIPWL",
            "name": "Trippin' Ape Tribe WL",
            "decimals": 0,
            "logoURI": "https://pbs.twimg.com/profile_images/1516190526592139266/tTBE_jP5_400x400.jpg",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Es624KL9Z86oig5VpLwu61eHEZfcMNW5NgdqQWouhpsg",
            "symbol": "KGWT",
            "name": "Kawaii Ghouls Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/IvaldiS6/image/main/Tiny_logo.png",
            "tags": [
                "social-token",
                "whitelist-token",
                "community-token",
                "utility-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6FedugQ",
                "twitter": "https://twitter.com/KawaiiGhoulsNFT",
                "website": "https://www.kawaiighouls.art"
            }
        },
        {
            "chainId": 101,
            "address": "3EfhVMtQpWRRTDyhYudsBx6uEZzK89HhE8j1pSyACKeX",
            "symbol": "JS",
            "name": "JASMIN",
            "decimals": 0,
            "logoURI": "https://imgur.com/PAA21On.gif",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "9kRUGxsMKtTSpW4JjCLnf1DQQV5pafETHbcB2npvyaX1",
            "symbol": "ORCH",
            "name": "ORCHID",
            "decimals": 0,
            "logoURI": "https://imgur.com/zPr9R8D.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "animationUrl": "https://imgur.com/1O39DGg.mp4",
                "description": "Venus de Fleur",
                "imageUrl": "https://imgur.com/a59QP7g.png",
                "website": "https://www.venusetfleur.com"
            }
        },
        {
            "chainId": 101,
            "address": "FzeuYTjS42pi2SZN1HMWwgH86A3v8dLyt52SuLFrinK1",
            "symbol": "CCNHS",
            "name": "ColdCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ZacharyLiebl/SnowCoinSymbol/main/SnowCoinSymbol.png",
            "tags": [
                "nor-easters",
                "experiment"
            ],
            "extensions": {
                "description": "For more info, contact nhscryptocurrency@gmail.com"
            }
        },
        {
            "chainId": 101,
            "address": "B3z92d9ZN8zeRMaXkJGbLhKRHwLn2Pw78WwjZrAV9E1g",
            "symbol": "SEEL",
            "name": "Sealcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sealcakes/crypto/main/PNG-images-Seal-18png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RPEbt7HCzbJGL24VCzr17pPspFaieFQ44skWsM2332Y",
            "symbol": "RPE",
            "name": "Royal Panther Empire Coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/rpectdev/RPE-token/main/RPE-COIn.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/9WAp99TVqZ",
                "instagram": "https://www.instagram.com/royalpantherempire/",
                "twitter": "https://twitter.com/royalpantheremp",
                "website": "https://royalpantherempire.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6ot4f3FMZSsK9dpBSiqeCSrQXVvTNtpYptk5da75LYqA",
            "symbol": "MKD",
            "name": "MKD STABLECOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MKD-Stable-token/logo/main/logo.png",
            "tags": [
                "stablecoin",
                "stable-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "78WCPzsCiyAsHRfYLnwfBfEQT4yBX7g5gfKjbzj9WjqL",
            "symbol": "HIT",
            "name": "HICOIN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78WCPzsCiyAsHRfYLnwfBfEQT4yBX7g5gfKjbzj9WjqL/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8FtAbvh2Q1TL5PbWE529NFbuj5zxnAVR6NByxampNc1c",
            "symbol": "LCTN",
            "name": "Luna Capsule Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/lunacapsuletoken/lunacapsuletoken/main/logo.png",
            "tags": [
                "airdrop-token",
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/lunacapsuletoken",
                "twitter": "https://twitter.com/lunacapstoken"
            }
        },
        {
            "chainId": 101,
            "address": "8dK5tfnxtbCRmqev2iJGqsizRxcPrpaNLSjuVokcbtDx",
            "symbol": "Shake",
            "name": "Shake",
            "decimals": 2,
            "logoURI": "https://i.postimg.cc/7PcVcwjG/BUDDIES.png",
            "extensions": {
                "twitter": "https://twitter.com/potprincessNFT",
                "website": "https://www.potprincess.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "JoZohHHuqmXoKy38z7xnSohYQAsYRtQ8JkjRYKGnSRB",
            "symbol": "JGWL",
            "name": "JoZo Gators WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JoZohHHuqmXoKy38z7xnSohYQAsYRtQ8JkjRYKGnSRB/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/JozoGators"
            }
        },
        {
            "chainId": 101,
            "address": "AXBDeu9xakvdJ1dgpnhVrmwKffbhNqSHZgNb4gM99xYC",
            "symbol": "AYKWL",
            "name": "AYAKO Whitelist Mint Token",
            "decimals": 0,
            "logoURI": "https://i.postimg.cc/XN1xtY1C/5ape-Sn-Ur-400x400.png",
            "tags": [
                "whitelist-token",
                "Verified_QD"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Ayako_sol",
                "website": "http://ayako-sol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8VPcCHQ5FRnZ4ihYLEqkkDY5d8ers9rjQ7mo95Y18JJ4",
            "symbol": "LDTG2",
            "name": "LDT Gen 2 Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8VPcCHQ5FRnZ4ihYLEqkkDY5d8ers9rjQ7mo95Y18JJ4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Living Dead Teds Gen 2 Mint Token",
                "discord": "https://discord.gg/livingdeadteds",
                "twitter": "https://twitter.com/LivingDeadTeds",
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "DuS8UxSX2f2Trniw9WeXpQgM94zZy67KZe9Z93vuNVh7",
            "symbol": "LDTG2WL",
            "name": "LDT Gen 2 WL Mint Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DuS8UxSX2f2Trniw9WeXpQgM94zZy67KZe9Z93vuNVh7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Living Dead Teds Gen 2 WL Mint Token",
                "discord": "https://discord.gg/livingdeadteds",
                "twitter": "https://twitter.com/LivingDeadTeds",
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "DriLz492FPfYforf7PVDaoxBJE3WwNhqDqa5VGDtCUn1",
            "symbol": "LDTG1ASC",
            "name": "LDT Gen 1 Ascension Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DriLz492FPfYforf7PVDaoxBJE3WwNhqDqa5VGDtCUn1/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Living Dead Teds Gen 1 Ascension Token",
                "discord": "https://discord.gg/livingdeadteds",
                "twitter": "https://twitter.com/LivingDeadTeds",
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "EKfDCMnjkx9f919JRpfAn4kG8vVg5BEkjBnuDa4HSmfw",
            "symbol": "MICA",
            "name": "MICA Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micasdad/crypto/main/rsz_1img_5819.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CE4R1hVveA3qkxX6D8dM5gnds2U5RJAxXLa84RW8Ufjd",
            "symbol": "SPRAGUE",
            "name": "Sprague Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/spraguehouse/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4B619RbcXbXrKTzNVgDSRiUn9wfxWgA1w1oFLveGacNy",
            "symbol": "HCOIN",
            "name": "Hydrogencoin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/dataalg/Hydrogen/main/4B619RbcXbXrKTzNVgDSRiUn9wfxWgA1w1oFLveGacNy/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Hydrogencoin is a digital currency founded by DataAlg. It aims to develop green hydrogen economies.",
                "twitter": "https://twitter.com/hydrogencoin_io",
                "website": "http://hydrogencoin.io"
            }
        },
        {
            "chainId": 101,
            "address": "RoniLMQHDYpTFqs3wKrWwT2eVv8XSPSR9XuxKiRt8RN",
            "symbol": "Takashironin",
            "name": "Takashi Ronin Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RoniLMQHDYpTFqs3wKrWwT2eVv8XSPSR9XuxKiRt8RN/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TakashiRonin"
            }
        },
        {
            "chainId": 101,
            "address": "9tTzGfAy53kPNNUZqQ3hCwjSTzvFDRbhekCqkGN11ZaC",
            "symbol": "ABC",
            "name": "Assassin Brothers Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/assassinbrothers/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CAPLiX3SbW4fEUoQWb92EUNTqqDyKssqgbHWCa5gmmRc",
            "symbol": "FCHT",
            "name": "Fight Capital Handshake Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/metapupsclub/log/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Officially Licensed, Limited Edition Fighters, Immortalized On The Blockchain",
                "twitter": "https://twitter.com/FightCapitalNFT",
                "website": "http://fightcapital.io"
            }
        },
        {
            "chainId": 101,
            "address": "32WEN49r7DqeyNArGd2hGcFinCCTFVrXjZSpMftA12ym",
            "symbol": "ICED",
            "name": "Icedawn Productions Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/32WEN49r7DqeyNArGd2hGcFinCCTFVrXjZSpMftA12ym/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DGJMicLBpwAc7UphNQ44qGVy6jgQkRwJEaKXx5mRKmA5",
            "symbol": "MURC",
            "name": "MurciCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thorium31/Murchi/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gtw39NL6rLvWrFQfatbvxEWZsGdU54EKLmqyjo5FQqkk",
            "symbol": "DAKN",
            "name": "Dakender Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dakender/Dakender-Coin/main/logo-dak.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EABJLPosabmVSpGdvxsQHfCg9qGhTf3a1oaAmbjKa92V",
            "symbol": "STBN",
            "name": "Stewbunie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dakender/Stewbunie-Coin/main/logo-stew.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GPxMP8BAoT5FULh1yK7Nu1bHSyBofUhWtUK5MWJ3hemp",
            "symbol": "$HEMP",
            "name": "CANNAVERSE HEMP TOKEN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GPxMP8BAoT5FULh1yK7Nu1bHSyBofUhWtUK5MWJ3hemp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4Cz1spVURCwkwPVaAgGazrx12X8UT4YzEXoCivHkih3J",
            "symbol": "DRNC",
            "name": "DRN Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DuaneHill67/drn-crypto-01/main/drn-education-logo-crypto-300x300.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9UGMFdqeQbNqu488mKYzsAwBu6P2gLJnsFeQZ29cGSEw",
            "symbol": "SNV",
            "name": "Solniverse",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9UGMFdqeQbNqu488mKYzsAwBu6P2gLJnsFeQZ29cGSEw/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://solniverse.net/"
            }
        },
        {
            "chainId": 101,
            "address": "3YHijuF9GJLEFY1njrgKhhqvwSKUAruEAsKBZceNebpi",
            "symbol": "DIPC",
            "name": "DipCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/E-Liz/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7B9ALzzrs2iefZvrDVwZiP8GrFw3957UyExpBAhFYKq2",
            "symbol": "WLHALO",
            "name": "WL HALO LABS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/halowl.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8S46ooYiMtmrvpJxg3P3cteEtPuhbvfM65rXj9ywdD4q",
            "symbol": "FFn",
            "name": "Fluffy Frens Whitelist Access Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8S46ooYiMtmrvpJxg3P3cteEtPuhbvfM65rXj9ywdD4q/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/nftfluffyfrens"
            }
        },
        {
            "chainId": 101,
            "address": "SPCc5sh97jK7bvtxjvGZy94DX6Zi7GDQuZdxDFkxe4h",
            "symbol": "SPCTK",
            "name": "BHSG Spaceship Claim Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JetNnLZpbECCfyeZSvJdgg5gf37QfCkPrp2X8XfmNWn/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bountyhunterspaceguild.com"
            }
        },
        {
            "chainId": 101,
            "address": "BENVbd7vc1sAks5xeAKMQVHP3qTqTXSHAJqTgbSgUdLw",
            "symbol": "WTOY",
            "name": "Whappy TOY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/WTOY.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WcdonaldsNFT",
                "website": "https://wcdonalds.io"
            }
        },
        {
            "chainId": 101,
            "address": "5Gdcbs6SFEUyVb7z88obUToe1gRgUxPQQV95zrh3MZL",
            "symbol": "WNFT",
            "name": "Whappy NFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/WNFT.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WcdonaldsNFT",
                "website": "https://wcdonalds.io"
            }
        },
        {
            "chainId": 101,
            "address": "9QF3bA4kyccT241Pg9zC3ew63UAJokWnGCWMaa36WfnV",
            "symbol": "WTIK",
            "name": "Whappy Ticket",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/WTIK.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WcdonaldsNFT",
                "website": "https://wcdonalds.io"
            }
        },
        {
            "chainId": 101,
            "address": "GpEbwsDYr5ER68oQPTf92YLT5HjQW9BV4XSa2yS4LfpT",
            "symbol": "WTRY",
            "name": "Whappy Try Again",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/WTRY.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WcdonaldsNFT",
                "website": "https://wcdonalds.io"
            }
        },
        {
            "chainId": 101,
            "address": "9Z9rp7jqeNprtdMBmFTeHaiy3dcAXbDC7uFQT9mUXZbv",
            "symbol": "TAJ",
            "name": "TajCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TajCoin54/Crypto/main/TajCoin-Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HczeKYQmeY6dNiEseTwWhtmgmzUuDNhNjgHggXkp1sCX",
            "symbol": "eL",
            "name": "eLcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HczeKYQmeY6dNiEseTwWhtmgmzUuDNhNjgHggXkp1sCX/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CapiuqcvXkaueU7PpUvPUFciLr84zbvVRqknjMT4svmn",
            "symbol": "Droid",
            "name": "Droid Capital Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CapiuqcvXkaueU7PpUvPUFciLr84zbvVRqknjMT4svmn/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/droidcapital"
            }
        },
        {
            "chainId": 101,
            "address": "5LwkUHbof6yxaVJcc7TTc1oMoJpoJxEouidAamvwtCsX",
            "symbol": "LYFE",
            "name": "LYFE Token",
            "decimals": 2,
            "logoURI": "https://media.discordapp.net/attachments/926535226820276264/974752195864068126/20220514_004708.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "odtZbFYqBbthaRU8nNKABN3yWB9sTrpTQrFq1XkDEdJ",
            "symbol": "ODW",
            "name": "Optimus Dino Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/odtZbFYqBbthaRU8nNKABN3yWB9sTrpTQrFq1XkDEdJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "HUuncuXwqsPLwyLJicWgk99BE61gEhn3nBmU94S8EMCn",
            "symbol": "INVNCM",
            "name": "The Invasion Commander WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theinvasionnft/invasiontoken/main/commandertoken.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theinvasion_nft"
            }
        },
        {
            "chainId": 101,
            "address": "Z1hJj8M518eMgErW8PYAQzv3u9EQjsHYvjbkgy51GtE",
            "symbol": "INVNLT",
            "name": "The Invasion Lieutenant FCFS WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/theinvasionnft/invasiontoken/main/lieutenanttoken.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theinvasion_nft"
            }
        },
        {
            "chainId": 101,
            "address": "NXH66NhJZ3woe1KCYFGwSJHTtpELuv4Mf8YoVthWtce",
            "symbol": "MECH",
            "name": "MECH - Art Mechanism",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/theinvasionnft/invasiontoken/main/mechtoken.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theinvasion_nft"
            }
        },
        {
            "chainId": 101,
            "address": "5yBCtc2zMHwrK5YZafwwp153QYcJuGLZC5Xsujj2wZMh",
            "symbol": "AC",
            "name": "AC Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Fansub-Dev/fansub-crypto/main/logo.png",
            "tags": [
                "community",
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/fansublive",
                "website": "https://fansub.live"
            }
        },
        {
            "chainId": 101,
            "address": "DAG2E3z5MCjkcfjepiU3oTYo751xQR5Ak9qWgSkKc6au",
            "symbol": "WLDAG",
            "name": "DAG Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAG2E3z5MCjkcfjepiU3oTYo751xQR5Ak9qWgSkKc6au/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/drunkapegangdao/",
                "twitter": "https://twitter.com/DAGDAOv2",
                "website": "https://www.drunkapegang.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4ViRrJaAzSkUifscKU3mRjP7sMH5AiHPs6q36hjoN6vE",
            "symbol": "$BACON",
            "name": "BACON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ViRrJaAzSkUifscKU3mRjP7sMH5AiHPs6q36hjoN6vE/logo.png",
            "tags": [
                "utility-token",
                "nft-token",
                "cryptogame-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BACOIN_Official"
            }
        },
        {
            "chainId": 101,
            "address": "CJx9of9U5KsfYXcvPU5Wi3KNBpo8nLpqehVLffokYR2i",
            "symbol": "RMCF",
            "name": "Real Madrid CF",
            "decimals": 0,
            "logoURI": "https://imgur.com/BlY3PNB.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "2xugPpLrsGxNq7zMC93bWPoL4YAQR3gQztoiB62wfDwu",
            "symbol": "AJAX",
            "name": "Ajax",
            "decimals": 0,
            "logoURI": "https://imgur.com/IcU4ub0.jpeg"
        },
        {
            "chainId": 101,
            "address": "6z1oue9xiJCHcDqsyeTZ3NHFVzqMHQSoZmTvVamdW2MZ",
            "symbol": "BIG",
            "name": "UNKWN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/h3ll0x/bigdaddytestpic/main/pinkguy.png",
            "tags": [
                "utility-token",
                "virtual-currency"
            ],
            "extensions": {
                "twitter": "https://twitter.com/salestest11",
                "website": "https://www.celestialzmarketplace.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8BDTKgdCcLYPKTgKF7tnY3CkF6sZarDwXm18Co7VZr9Y",
            "symbol": "SPHERES",
            "name": "Era Spheres",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8BDTKgdCcLYPKTgKF7tnY3CkF6sZarDwXm18Co7VZr9Y/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/REPrVxA2gN",
                "telegram": "https://t.me/eraspheres",
                "twitter": "https://twitter.com/eraspheres",
                "website": "https://eraspheres.com/"
            }
        },
        {
            "chainId": 101,
            "address": "TupVw9WuoZQZ3Zb2kegZvf8xrwEo2EGwx7gB79P5gx4",
            "symbol": "SLEEPY",
            "name": "Sleepy",
            "decimals": 9,
            "logoURI": "https://github.com/boosbasement/metaboss/blob/main/$sleepy.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "27KXQsTik6Uw67TMjRTaA6mciKYBqDATgwTobnCgSpQ2",
            "symbol": "NF1",
            "name": "Neon Formula One",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/felle64/crypto/main/0315-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CaRBon66o8vaibpjDdAGz1Gt9GetvSFnXVUP1DoJE1L3",
            "symbol": "kg",
            "name": "Fractional Carbon Credit",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/carbon/carbon-credit.svg",
            "tags": [
                "carbon-coin",
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "Proof of Carbon Sequestration to be used as Carbon credit (unit: 1kg CO2e)",
                "imageUrl": "https://bafybeid7dw4tdtbuj5pn4gqlqooajzfaecujcupwzgu2bp6p6z46qlv2g4.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "website": "https://PurpleZone.github.io/NFTs/carbon/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmNwPaqvPMZKxEDZB8vr63wTmf5bZ2JocYY7HamWY8ws3E/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "7eYfqg7yeWVSVQPRP4LEo7oZ93RaCXExn4JLgrcWtUd6",
            "symbol": "DOMEIN",
            "name": "Domein Bergen community-coin",
            "decimals": 0,
            "logoURI": "https://github.com/SicoDM/crypto/blob/main/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8CVx21yr9oCdJj2zAxG6bo3L8DczBNnTGuZnK9DckyJx",
            "symbol": "MCPS",
            "name": "Monkey CryptoPunks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8CVx21yr9oCdJj2zAxG6bo3L8DczBNnTGuZnK9DckyJx/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Monkey CryptoPunks is a limited collection of 9,999 NFTs, each with their own unique characteristics that live on the Solana Blockchain.",
                "discord": "https://discord.com/invite/ksnEANHtqE",
                "twitter": "https://twitter.com/mk_cryptopunks",
                "website": "https://www.monkeycryptopunks.com/"
            }
        },
        {
            "chainId": 101,
            "address": "aoUpST1bQEgCqYGbgq1e1rU4wyg2bf8R7UfAJSjbKX7",
            "symbol": "GOOM",
            "name": "GoomCoin",
            "decimals": 6,
            "logoURI": "https://drive.google.com/file/d/1hacK8KWncwESO_qamhNzQEJsm_AnAigb/view",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/NT83yQv3qd",
                "twitter": "https://twitter.com/TheGoomies",
                "website": "https://www.goomies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5BwDHmAdRqv8Rbg73zHEgbbuLJ4TezoZWNzfcYy76Lfp",
            "symbol": "GC",
            "name": "Goubi Coin",
            "decimals": 0,
            "logoURI": "https://github.com/judysoukkari/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GXwm1z14osgRaQRNaszvLx3bTCdj3ANYZ7vbv6gwAYEP",
            "symbol": "AX",
            "name": "AX Coin",
            "decimals": 0,
            "logoURI": "https://github.com/amelie-project/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4LAKVnUQFNSiMLdqNboynMf3KjctauodmEuEA6S3Xqu6",
            "symbol": "GNS",
            "name": "Genius Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jslhost/genius_coin_logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 102,
            "address": "Figt9t7AcUbYBxRaUZu7tkLZt3sDwk2Ge5c5RtLMUKvg",
            "symbol": "STT",
            "name": "Solana Test Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/daddydonke/gem-farm-ui/main/public/images/icon.png",
            "tags": [
                "testtoken"
            ],
            "extensions": {
                "website": "https://www.solana.com/"
            }
        },
        {
            "chainId": 102,
            "address": "CEhqvEgXz86KhRHBfyJD48eCZ9kocJnfSKy53DrcYyrX",
            "symbol": "NST",
            "name": "New Solana Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/InnoVetrov/Blockchain-token/main/logo.jpg",
            "tags": [
                "newtoken"
            ],
            "extensions": {
                "website": "https://www.solana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "C4mtUoREqECRPC78tBBfjLuC5TwVaLhPDaz6Rb3ddyBo",
            "symbol": "INNO",
            "name": "Inno Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/InnoVetrov/Blockchain-token/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8AuVD7P3WBZQcYCy8npWjF5RNgM8Sp3FcnbGVxndYUZi",
            "symbol": "NgMp",
            "name": "Neon Game Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8AuVD7P3WBZQcYCy8npWjF5RNgM8Sp3FcnbGVxndYUZi/logo.png",
            "tags": [
                "community-token",
                "mint-token"
            ],
            "extensions": {
                "website": "https://neon.game"
            }
        },
        {
            "chainId": 101,
            "address": "AqZ6KDci9ichNuz1WVYiWxmUdfsHsbXeYsSyJffF26kn",
            "symbol": "FION",
            "name": "Finoa Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shahinabdi/Crypto/main/Logo.png",
            "tags": [
                "social-token",
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "LjjVwwYJLCRW9vegYFj7y8kVp1nfw9j3nMG3VEkeMGr",
            "symbol": "FEED",
            "name": "Feederino",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jbeckmann1/feederino.coin/main/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9P3rqFGoJNbNgGtEaVX9rADzBY1L9DM37viqxUvByQU9",
            "symbol": "LTC",
            "name": "Lotus Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Aoisuki/coin_project/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ZZZE4qEtwCEgNqhQVLkRqnY2rBwsWL3W1XaYDkhVRUx",
            "symbol": "ZZZE",
            "name": "ZENJIN VIPERZ OFFICIAL WL TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZZZE4qEtwCEgNqhQVLkRqnY2rBwsWL3W1XaYDkhVRUx/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZenjinViperz"
            }
        },
        {
            "chainId": 101,
            "address": "HrTAyM65ePyJ48uSV4zw7oP7kDqNaM7MxwAUbbGwNVP5",
            "symbol": "GGWL",
            "name": "Ghekre Gorillaz WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HrTAyM65ePyJ48uSV4zw7oP7kDqNaM7MxwAUbbGwNVP5/icon.png"
        },
        {
            "chainId": 101,
            "address": "BgnUXNGACSKpoba3P54APdQRSCtghSZndH5dq6kT6uR2",
            "symbol": "DCATGT",
            "name": "DCAT GOLD TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dcatmusicnft/token-list/main/assets/mainnet/BgnUXNGACSKpoba3P54APdQRSCtghSZndH5dq6kT6uR2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dcatmetaverse",
                "instagram": "https://www.instagram.com/dcatmusicnft",
                "telegram": "https://t.me/dcatmetaverse",
                "twitter": "https://twitter.com/dcatmetaverse",
                "website": "https://www.dcatmusic.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6q7mSkFsgCspNwZCgBTgo7Q1RcmKo7P4DpKFy3PxAUXg",
            "symbol": "NOROI",
            "name": "Atama NOROI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AtamaNFT/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/adeEYRAbmy",
                "twitter": "https://twitter.com/NorowaretaUchu",
                "website": "https://atamanft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4NBLvi2sEzRFDUreaHARCagWTvzMWVjhw3qfDW3agF7J",
            "symbol": "CDT",
            "name": "Crypto Deity Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/b4dcat404/crypto_deity/main/logo.png",
            "tags": [
                "social-token",
                "crypto-deity-token",
                "gaming"
            ]
        },
        {
            "chainId": 101,
            "address": "NovawtDyKZ6feVg3EUjuZfVUtqtjGP4fkXwuQZWQS86",
            "symbol": "NOVA",
            "name": "NOVA PROGRAMMABLE ASSETS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A/logo.svg",
            "extensions": {
                "twitter": "https://twitter.com/NovaFinance_"
            }
        },
        {
            "chainId": 101,
            "address": "HhWdM2VApdTkBL8sWgURrKhc3yGaGYtyKk2majWsC5dn",
            "symbol": "$SLCF",
            "name": "Solaunchief Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HhWdM2VApdTkBL8sWgURrKhc3yGaGYtyKk2majWsC5dn/logo.png",
            "tags": [
                "social-token",
                "finance-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solaunchief",
                "website": "https://www.solaunchief.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "4LmBST72YGpmv4GA26dXQs2ocwECmt19SYA7j4Ar1URb",
            "symbol": "Soil",
            "name": "Soil",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Datatolk/Solana/main/Soil%20logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9bnKvEK2ymzbrtKGeCSbDtn7Dr1afCQoTTH1y4fL5seK",
            "symbol": "RBN",
            "name": "Robin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9bnKvEK2ymzbrtKGeCSbDtn7Dr1afCQoTTH1y4fL5seK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/XcYRWpbm7Z",
                "instagram": "https://www.instagram.com/_robin_token_/",
                "telegram": "https://t.me/robintoken",
                "twitter": "https://twitter.com/_Robin_Token_",
                "website": "https://robintoken.net/"
            }
        },
        {
            "chainId": 101,
            "address": "6MwT3xNSDqqe3zu4JX8iSFzdqU8xfv1MDA3u4brJ87Ct",
            "symbol": "DWR",
            "name": "Deep-Waters",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwr-research/DWResearch/main/dwfinaudit.png",
            "tags": [
                "utility-token",
                "community-token",
                "governance-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A7myaSTanmpsa79NiMojGkxU9WfWDjS6wE7ei6EjxmV5",
            "symbol": "ACHV",
            "name": "AchievementCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/YenteCeulemans/Crypto/main/CryptoCirkel.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FfMRKofNLF3Z38qcuvP72xr4t39kYnWUhwfgt8X7jigd",
            "symbol": "CARTON",
            "name": "CARTON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FfMRKofNLF3Z38qcuvP72xr4t39kYnWUhwfgt8X7jigd/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CartonkidsNFT",
                "website": "https://www.cartonkids.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "FtssCcc6aYaadQbuopCPJnpaku3YaVkP7kB1KNzF2nkR",
            "symbol": "JOTC",
            "name": "JokerOfTimeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrlowbot/Crypto/main/crypto.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gi2AYxsjmRTDTKbNSaq7ieZ2WmWLZyvgQtnupqFPiDH7",
            "symbol": "AMCWL",
            "name": "Ancient Meta Cats WL Access",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gi2AYxsjmRTDTKbNSaq7ieZ2WmWLZyvgQtnupqFPiDH7/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/metacats",
                "instagram": "https://www.instagram.com/AncientMetaCats/",
                "twitter": "https://twitter.com/AncientMetaCats",
                "website": "https://ancientmetacats.io/"
            }
        },
        {
            "chainId": 101,
            "address": "DvaTtZB5XbJysHvAi4qAgN9gEQU3g4Dgy1w2eo5vuLUV",
            "symbol": "HTC",
            "name": "HF-Fund Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DvaTtZB5XbJysHvAi4qAgN9gEQU3g4Dgy1w2eo5vuLUV/fh-logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://www.fh-fund.org.ua/en"
            }
        },
        {
            "chainId": 101,
            "address": "TokkUDv5pRKTJNAs8ceraoKC2JUThjuj8LAHXoTAUsZ",
            "symbol": "$SCREAM",
            "name": "SCREAM",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TokkUDv5pRKTJNAs8ceraoKC2JUThjuj8LAHXoTAUsZ/logo.png"
        },
        {
            "chainId": 101,
            "address": "GLS5WLSpjdDo7z9AVscccA85vfqt1qHTuMiU6oynYCyB",
            "symbol": "NEKO",
            "name": "NEKO Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GLS5WLSpjdDo7z9AVscccA85vfqt1qHTuMiU6oynYCyB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/Ge3XntNb53",
                "instagram": "https://instagram.com/pluginio",
                "telegram": "https://t.me/pluginio",
                "twitter": "https://twitter.com/pluginio",
                "website": "https://plugin.io"
            }
        },
        {
            "chainId": 101,
            "address": "DKwsWeqHrB8R1u2DFMHKtq4iqaQNgPgUbHTJyXPqkTzK",
            "symbol": "swimUSD-avalUSDC",
            "name": "Swim AVAX USDC Meta-pool LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DKwsWeqHrB8R1u2DFMHKtq4iqaQNgPgUbHTJyXPqkTzK/lp_metapool_avalanche_usdc.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "5rwvDmUbcnZTwZ4Zywev2wnDbyDDD2vcsGU2Xmy7aRNS",
            "symbol": "swimUSD-avalUSDT",
            "name": "Swim AVAX USDT Meta-pool LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5rwvDmUbcnZTwZ4Zywev2wnDbyDDD2vcsGU2Xmy7aRNS/lp_metapool_avalanche_usdt.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "ANFojEXhiEQQoovhBs77XmBQuqbe59UBygRWViyf4945",
            "symbol": "swimUSD-polyUSDC",
            "name": "Swim Polygon USDC Meta-pool LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANFojEXhiEQQoovhBs77XmBQuqbe59UBygRWViyf4945/lp_metapool_polygon_usdc.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "2Nx6L79dHHgHcJtNfZWukQkWZvf5h4bps34zuh1gjtdP",
            "symbol": "swimUSD-polyUSDT",
            "name": "Swim Polygon USDT Meta-pool LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Nx6L79dHHgHcJtNfZWukQkWZvf5h4bps34zuh1gjtdP/lp_metapool_polygon_usdt.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "J5ifGexAQTg76TresJhJSqTPJLT6BNxrV5rwNJTTz4Cx",
            "symbol": "swimUSD-fantUSDC",
            "name": "Swim Fantom USDC Meta-pool LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J5ifGexAQTg76TresJhJSqTPJLT6BNxrV5rwNJTTz4Cx/lp_metapool_fantom_usdc.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "3eXCU7YoiCq3rZ6787pPFJE7TXBsKuTZ49wH2kFnuTeF",
            "symbol": "swimUSD-auroUSN",
            "name": "Swim Aurora USN Meta-pool LP",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3eXCU7YoiCq3rZ6787pPFJE7TXBsKuTZ49wH2kFnuTeF/lp_metapool_aurora_usn.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "9qRe2nBrR2rTXxRaV1PZN9hZnqq3UXgoFWTbP6NE3MEu",
            "symbol": "swimUSD-auroUSDC",
            "name": "Swim Aurora USDC Meta-pool LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9qRe2nBrR2rTXxRaV1PZN9hZnqq3UXgoFWTbP6NE3MEu/lp_metapool_aurora_usdc.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "4XPDxtGbcM7bAPKZxALd2s862n3WoG4xPPvyCPVULKAb",
            "symbol": "swimUSD-auroUSDT",
            "name": "Swim Aurora USDT Meta-pool LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4XPDxtGbcM7bAPKZxALd2s862n3WoG4xPPvyCPVULKAb/lp_metapool_aurora_usdt.svg",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "blog": "https://blog.swim.io",
                "discord": "https://discord.gg/wGrxQ7GAgP",
                "telegram": "https://t.me/joinchat/Mnc1WjrKcq8yYTM1",
                "twitter": "https://twitter.com/SwimProtocol",
                "website": "https://swim.io/",
                "whitepaper": "https://swim.io/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "2ng5Y3i1qXPfSNSuyps4GzE2csa9V268SrwHkxwhkZ7R",
            "symbol": "3KNG",
            "name": "3KingzKaterin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WiZzMaN47/CryptoToken/main/PicsArt_02-11-02.05.26.jpg",
            "tags": [
                "social-token",
                "3KingzKaterin",
                "3KNG",
                "420",
                "MzpHX420"
            ]
        },
        {
            "chainId": 101,
            "address": "c2Moo4p2343VHsEtCVXSsx5D1dLCdhJQAb8dGXsjBsy",
            "symbol": "YUKAG",
            "name": "YUKON Silver",
            "decimals": 9,
            "logoURI": "https://static.wixstatic.com/media/4b5c4d_70908113a6b94c1ea6034a291ca026c6~mv2.png",
            "tags": [
                "community-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CNLTUm3bYaF9HKQPEjvMFVH7QWnng1QWTeTXZzavLPDq",
            "symbol": "LW-WL",
            "name": "Luna Wolves WL Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/TDt30NbOgs7CCG-tP-2HCfUlOACXPDEukfv26CEB2No?ext=gif",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/alphawolves",
                "twitter": "https://twitter.com/lunawolvesnft",
                "website": "https://www.worldofwolves.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "FdabruMo1vKVdFMVKiCCN3iJZyvCEFNkGjJVKfL3LZs9",
            "symbol": "INTEL",
            "name": "INTEL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FdabruMo1vKVdFMVKiCCN3iJZyvCEFNkGjJVKfL3LZs9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://sectorh.io/"
            }
        },
        {
            "chainId": 101,
            "address": "HixHwyVf1fLLYbEHdjEjmhrYN274EVDKENa9xoogqXdv",
            "symbol": "XCHI",
            "name": "XCHI Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HixHwyVf1fLLYbEHdjEjmhrYN274EVDKENa9xoogqXdv/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Mantis_Chick"
            }
        },
        {
            "chainId": 101,
            "address": "HJK8mdAx5gZqW7Y9riSF3A1gR41pmR26c4GhmHtcvuz",
            "symbol": "CLDZ",
            "name": "Clouderz Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/elijahbrookss/crypto/main/noob_with_panV4.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "79BXbiiN5HcXH1vRHbQgs9T9Qmae9por26ULEMXuaP7L",
            "symbol": "$PARENT",
            "name": "PARENT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/PARENT_coin.png"
        },
        {
            "chainId": 101,
            "address": "7pd9nmEbT55hkzL4n8kXutzgyjSEUnvfFvHAs9saDsfx",
            "symbol": "$JRNY",
            "name": "JRNY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/JRNY_coin.png"
        },
        {
            "chainId": 101,
            "address": "EKh6pXWD4pp3U32j7zEe6Bx2LQtwyhuJLz5QV27Kwo4m",
            "symbol": "$GSWL",
            "name": "GSWL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/GSWL_coin.png"
        },
        {
            "chainId": 101,
            "address": "FKuNiWqyAaNpc6LpNvR84qcX411WPm3UT42jZy6wZeRf",
            "symbol": "NSOL",
            "name": "EdenSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FKuNiWqyAaNpc6LpNvR84qcX411WPm3UT42jZy6wZeRf/logo.png",
            "extensions": {
                "website": "https://edensol.net/"
            }
        },
        {
            "chainId": 101,
            "address": "2Dms1zJEcmRsxv4QP3JZfMz9Bo8arYAsYdeSGyncnnFM",
            "symbol": "SUNF",
            "name": "SunflowerF",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/ELFatimazahra02/crypto/main/attachment_60759885.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5kDKM99M919G4xQgbUQLAbTHmN4msR5JsRP9aSbDrfMz",
            "symbol": "$WFC",
            "name": "Wild Fox Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/WildFoxClub/asset/main/Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://wildfoxclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FMYExjhXQHErTxAFFwMp7VRjvtoYKWciyztxwsrAdAmf",
            "symbol": "CRS",
            "name": "Car Shadows",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shadowhashem/shadowcars/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3v2o7RSMPpXCPjmE3ANeiqUTHnKX2CFMVuoAj2EdR9ve",
            "symbol": "$HEART",
            "name": "HEART",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/HeartOfficial/Heart-v1/main/Heart%20Logo%20V1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FD2AYBKWQJ3oySdffANDCejBGYv3etrfzuyhKoMg1sab",
            "symbol": "LEAN",
            "name": "Leancoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Phongii/crypto/main/logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6JKtKEFiyp67VPN61nTCyUpY1cLrAZ7qpXiBsKqWZqWh",
            "symbol": "tsRAY",
            "name": "tsRAY",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/8619106/168856965-d216c773-a3ad-4e9c-b6f8-f64025c735a5.png",
            "tags": [
                "solfarm",
                "tulip",
                "lending",
                "strategy"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tulip",
                "twitter": "https://twitter.com/TulipProtocol",
                "website": "https://tulip.garden/"
            }
        },
        {
            "chainId": 101,
            "address": "AHV6CBjuT7M2HMMKDa5gRSEoBcJGWVjURCBhpgMbyESX",
            "symbol": "tsUSDT",
            "name": "tsUSDT",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/8619106/168857135-62593b94-1dcd-42d7-8373-ee505a006ed9.png",
            "tags": [
                "solfarm",
                "tulip",
                "lending",
                "strategy"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tulip",
                "twitter": "https://twitter.com/TulipProtocol",
                "website": "https://tulip.garden/"
            }
        },
        {
            "chainId": 101,
            "address": "DgnaQdr5VkNbH7n8qN471Hgsw19hUbMBhUs1N3MQKgtu",
            "symbol": "tsSOL",
            "name": "tsSOL",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/8619106/168851932-17ddaac9-979b-4c2f-9838-4a397d961722.png",
            "tags": [
                "solfarm",
                "tulip",
                "lending",
                "strategy"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tulip",
                "twitter": "https://twitter.com/TulipProtocol",
                "website": "https://tulip.garden/"
            }
        },
        {
            "chainId": 101,
            "address": "FrYsXT2tdyRCL9wGapgWkL1YAWU8pUk7x4rcR2LLhade",
            "symbol": "MARS",
            "name": "MissionMars",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fboch25/solana-token-creation/main/iphone_app60x60%403x.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7iE3sX7yTTg9Pn8P4nC99gii9MezNK9cPVz3XqEj17yg",
            "symbol": "HUB",
            "name": "HUBTOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7iE3sX7yTTg9Pn8P4nC99gii9MezNK9cPVz3XqEj17yg/logo.png",
            "extensions": {
                "discord": "https://discord.gg/gamehubnft",
                "twitter": "https://twitter.com/GameHubNFT",
                "website": "https://www.gamehub.is/"
            }
        },
        {
            "chainId": 101,
            "address": "BANANAs2SCcdFmZw3ahhM9PpQ1GhdgpxJ6mcARTuSzQc",
            "symbol": "BANANA",
            "name": "AOWS Banana",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BANANAs2SCcdFmZw3ahhM9PpQ1GhdgpxJ6mcARTuSzQc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/apesofwallstreet",
                "twitter": "https://twitter.com/ApesOfWallStNFT",
                "website": "https://www.apesofwallstreetnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "2mtG2SXAUPBewyFX4evjmCBH5MZHAiA7VFmVVFTxcvQA",
            "symbol": "PAGMI",
            "name": "PAGMI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mtG2SXAUPBewyFX4evjmCBH5MZHAiA7VFmVVFTxcvQA/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/partyparrotsdao"
            }
        },
        {
            "chainId": 101,
            "address": "q4n1gKnvhPXiHNq5EroZDEbHt1WFuJ1tJjj5xUX5B5G",
            "symbol": "LYUM",
            "name": "Project Lylium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/proDestiny/TokenLylium/main/LOGO.jpg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5XG32F6fSbnAzyG348FjUnSLQxw3cxgPFnBSo7UfSXKH",
            "symbol": "DWBLN",
            "name": "Dawbloon",
            "decimals": 9,
            "logoURI": "https://imgix.cosmicjs.com/556be6e0-d608-11ec-bb19-d9085ce408df-Icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6H2U4ueSn1K1U9LSMGNV4u2oTeApup47J38hn5BJ63Qa",
            "symbol": "LMC",
            "name": "Limbocoin ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Novak-Fenocchio/LimboCoin/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "6G2pWrzW5SzsQbpU6LLn5AJwV9FASCWvTNfAPKyFCKHV",
            "symbol": "JELLY_WL_ONE",
            "name": "JELLYBABIES PHASE ONE WHITELIST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PGgQnN1icXNscLCjwF6zdyeCrDP4vmBg6LBK5MLdAC4/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wzEfUjmpFw",
                "twitter": "https://twitter.com/JellyBabiesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "9VthVfjnNoeBzPpN4Dk8U4Nhn5g96ddLb8aAc9xJKiGv",
            "symbol": "JELLY_WL_TWO",
            "name": "JELLYBABIES PHASE TWO WHITELIST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PGgQnN1icXNscLCjwF6zdyeCrDP4vmBg6LBK5MLdAC4/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wzEfUjmpFw",
                "twitter": "https://twitter.com/JellyBabiesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "73pWtZwP3bq5XUYQdPi4jkchGKJpL3pR5U93rh1oFJV3",
            "symbol": "JELLY_WL_THREE",
            "name": "JELLYBABIES PHASE THREE WHITELIST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PGgQnN1icXNscLCjwF6zdyeCrDP4vmBg6LBK5MLdAC4/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wzEfUjmpFw",
                "twitter": "https://twitter.com/JellyBabiesNFT"
            }
        },
        {
            "chainId": 103,
            "address": "nchrLQC3YT6kepn9JvepyDDwRMQDyZXqQ29P85Q22qf",
            "symbol": "a-btc",
            "name": "Anchored Bitcoin",
            "decimals": 10,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/anchorcoin/anchorcoin.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "bitcoin",
                "description": "Anchoring Bitcoin to the right value: people",
                "imageUrl": "https://bafybeignxjpwr5lp7zcnxlitnz2kf65zclybls2hxlwc7rgzmqp7ys54ta.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/anchorcoin/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmXwMs5cRBesM4fWY6Sdqz9unkMUfCNmmypj19c1JvBDYk/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "7i7LiEhcmoqSefeWG2v9v7nXoXNywBT3aMgnt7sz5fAZ",
            "symbol": "GLUG",
            "name": "GLUG Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/GlugToken/main/glugtoken.png",
            "tags": [
                "art-token",
                "financial-token",
                "monetary-token",
                "educational-token",
                "project-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZEBAESPFUs5BxapADhzLCuNCdKxbXD9SwZ48StYrh9X",
            "symbol": "1689",
            "name": "1689 Continental",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kryptoenterprise/Crypto/main/LogoPlain.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ANbgghBCZS7bN899tcS4RK6ndtAShoLEoReDN8W6ujPL",
            "symbol": "RCN",
            "name": "Rapid Cash Network",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rohitz308/crypto/main/Rapid%20Cash.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CZeroeJm4gfmdeL5vUy5VLUJm3aqRf2dgs7fz6DhC6vs",
            "symbol": "btc-z",
            "name": "Bitcoin CarbonZero",
            "decimals": 10,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/btcz/btc-z.svg",
            "tags": [
                "zerocarbon-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "Removing Carbon in cryptocurrencies one coin at a time",
                "imageUrl": "https://bafybeiaey7aarrnghsu6ziv6cofpqftj22c4ssyf4d74feccfgkmsw63du.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/btcz/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmYaMUFdqHmTk4V3HLfHwK3pq8d3Tqpk8s96ftb7WUkS1w/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "J64w7g38vBf6XZwie14pJJYSwdTxutEX5XB1dDcDBH4n",
            "symbol": "$YUMI",
            "name": "YUMI Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J64w7g38vBf6XZwie14pJJYSwdTxutEX5XB1dDcDBH4n/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Utility Token For Takashi Ronin",
                "discord": "https://discord.gg/takashironin",
                "twitter": "https://twitter.com/TakashiRonin",
                "website": "https://www.takashironin.com"
            }
        },
        {
            "chainId": 101,
            "address": "6cwW3gBpWnBU8UAsximLQVfbMySJqBG6LkFsozkr7BTg",
            "symbol": "$MEAT",
            "name": "Dino Jump MEAT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cwW3gBpWnBU8UAsximLQVfbMySJqBG6LkFsozkr7BTg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dinojump",
                "twitter": "https://twitter.com/dinojumpnft",
                "website": "http://dinojump.co/"
            }
        },
        {
            "chainId": 101,
            "address": "BoM2h2rFPgqwjgq5qS41tKHZ18C6tqm9EimhXk6ojw8z",
            "symbol": "TICKET",
            "name": "Paradise Ticket",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/ParadiseAutomata/token-list/main/assets/mainnet/BoM2h2rFPgqwjgq5qS41tKHZ18C6tqm9EimhXk6ojw8z/logo.png",
            "tags": [
                "DeFi"
            ]
        },
        {
            "chainId": 101,
            "address": "HotDogCsQgc25peDiEq8rxX7sopMdVJBUABxm8fwqBC5",
            "symbol": "HOTDOG",
            "name": "Hotdog",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HotDogCsQgc25peDiEq8rxX7sopMdVJBUABxm8fwqBC5/logo.png"
        },
        {
            "chainId": 101,
            "address": "7uzUfnJrRrSPhBapKgo9hxKuVugEccXLRr9Mgaqg1Hog",
            "symbol": "HUGS",
            "name": "Hugs Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/hugs-platform/hugs-token-assets/main/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "The Decentralized Crowdsourcing Platform",
                "github": "https://github.com/hugs-platform",
                "linkedin": "https://www.linkedin.com/company/hugsplatform",
                "twitter": "https://twitter.com/HugsPlatform",
                "website": "https://www.hugsplatform.com",
                "whitepaper": "https://docs.hugsplatform.com"
            }
        },
        {
            "chainId": 101,
            "address": "EDMcdz5QTtfDowHrzPYR1WDw23BhwBJBUbJjFo1RhmdT",
            "symbol": "LTW",
            "name": "LTW Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EDMcdz5QTtfDowHrzPYR1WDw23BhwBJBUbJjFo1RhmdT/ltwtoken.png"
        },
        {
            "chainId": 101,
            "address": "FujiSB9NYj8bGeEh77bibsK9PnBhRNXLZ5V4t6aMBtfK",
            "symbol": "FUJILIST",
            "name": "Fujilist Token",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/Ve9wr7a_d.webp?maxwidth=760\u0026fidelity=grand",
            "tags": [
                "DeFi"
            ]
        },
        {
            "chainId": 101,
            "address": "FfHyRCUVhsgBkWijV6PXpAzP5Tdkf86eYUof6ZZfx6Kb",
            "symbol": "UZM",
            "name": "UzbMoon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Khxuja1/CryptoUZS/main/uzs-min.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "gLhY2arqFpmVGkpbBbTi3TeWbsWevA8dqrwbKacK3vJ",
            "symbol": "tuUSDT",
            "name": "tuUSDT",
            "decimals": 6,
            "logoURI": "https://user-images.githubusercontent.com/8619106/169034746-d2bef331-4829-442f-8f91-5556e7c60d28.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tulip",
                "twitter": "https://twitter.com/TulipProtocol",
                "website": "https://tulip.garden/"
            }
        },
        {
            "chainId": 101,
            "address": "8jqjjbFtVQ9u2fDyqNWA6vCuU3wepnfafuaHTLYw8aog",
            "symbol": "OATSU",
            "name": "OATSU",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/OATSU_COIN_ICON.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Tv7peSKQ3JTR5J1dYubnqhf8t4TaAGMiEv86eZaWsM3",
            "symbol": "LOTTERY",
            "name": "TSF LOTTERY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Tv7peSKQ3JTR5J1dYubnqhf8t4TaAGMiEv86eZaWsM3/logo.jpg",
            "tags": [
                "nft-token",
                "community-token"
            ],
            "extensions": {
                "description": "Collect TSF Flies token to open your mystery box.",
                "discord": "http://discord.gg/stonedfrogs",
                "twitter": "https://twitter.com/thestonedfrogs",
                "website": "https://www.thestonedfrogs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7vG8c9DyEUj3k6Sf2FYZ9NsN7nUXDE4XWzvkAoUQwjCp",
            "symbol": "BLD",
            "name": "Bloodlust",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vG8c9DyEUj3k6Sf2FYZ9NsN7nUXDE4XWzvkAoUQwjCp/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/UndeadSols"
            }
        },
        {
            "chainId": 101,
            "address": "Gx2RyCAui6nbkC2eY3T1J53Le9UfNYUzFaiNGogvqmYF",
            "symbol": "BNLU",
            "name": "Banalu",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/techsteramman/banalu/main/banalu%20coin.png",
            "tags": [
                "dodgy-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CTYiHf58UGShfHtpkTwx7vjPDA779dd6iVaeD281fEVx",
            "symbol": "HUNT",
            "name": "Hunter Diamond",
            "decimals": 9,
            "logoURI": "https://tokenhunters.app/assets/img/icons/chest-close.svg"
        },
        {
            "chainId": 101,
            "address": "GRsoqmhsS7fCLpEqqE7oRM92ag3WVy8VbJAi6KfWSeHS",
            "symbol": "BBI",
            "name": "Bridgesplit Brand Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/lbvoBFh.png",
            "tags": [
                "unstoppable-domains",
                "domains",
                "bridgesplit-fraction",
                "curated-index"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/curated-index/GRsoqmhsS7fCLpEqqE7oRM92ag3WVy8VbJAi6KfWSeHS"
            }
        },
        {
            "chainId": 101,
            "address": "4DUQcmSn13YoXHAMfPAT3WDsQb8SmmnC7nom4hcAwRHD",
            "symbol": "Grams",
            "name": "Grams",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4DUQcmSn13YoXHAMfPAT3WDsQb8SmmnC7nom4hcAwRHD/grams_coin.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/gFQdk9wt7z",
                "twitter": "https://twitter.com/StonerCupsNFT",
                "website": "https://stonercups.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2Xy3Z4fW8wmEPMopehpzAaS6VKiVUJU8BGbDppPUKsX8",
            "symbol": "DACO",
            "name": "DanielCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ch-daniel/crypto/main/token-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4VcGrPUMkicSjwEapYznsXo78rPpdZk9tbTgB1vHaSrA",
            "symbol": "SPIRIT",
            "name": "LOST SPIRIT TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DefiTokens/assets/main/spirit.png"
        },
        {
            "chainId": 101,
            "address": "AYKo5aPAtTVy88aSvQ2HmXFzcNEnU5rG9z99LViBDeiG",
            "symbol": "AYKO",
            "name": "AYKO Protocol",
            "decimals": 9,
            "logoURI": "https://i.postimg.cc/BbvHCJz1/IMG-0977.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AyakoSol",
                "website": "http://ayako-sol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3w7WvrnzdEsWq7qPciqZM6SXx8YZUj7stEgAE4WRuzRd",
            "symbol": "DTKN",
            "name": "Demigod Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3w7WvrnzdEsWq7qPciqZM6SXx8YZUj7stEgAE4WRuzRd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://demigodcats.com"
            }
        },
        {
            "chainId": 101,
            "address": "G33nLPsvygo4qT6ocxA5TuJng7d6BGK6844DCLB3kcfC",
            "symbol": "AMIGO-WL",
            "name": "AMIGO-WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheAmigosNFT/Logo/main/Logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/theamigosnft",
                "website": "http://theamigos.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6mYq94Uo1ciebHZuUhn7waNKV1Xh5tKdQdJToFTJQWb5",
            "symbol": "UNCLES",
            "name": "UNCLS",
            "decimals": 9,
            "logoURI": "https://i.pinimg.com/564x/9d/84/75/9d8475e2fc5877f9fc312cc53e3c0bb0.jpg",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "726Uy6q8QY4NAr2hrmPFWgMr8QXSAt3xN7PFqXzS3o8d",
            "symbol": "MiFi",
            "name": "MindFi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/726Uy6q8QY4NAr2hrmPFWgMr8QXSAt3xN7PFqXzS3o8d/logo.png"
        },
        {
            "chainId": 101,
            "address": "crSLiJCoVd3UG5KKtj5Yy6c9kqW9Y7afTZefcV6c5tZ",
            "symbol": "CRAX",
            "name": "Bears on Crek Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/crSLiJCoVd3UG5KKtj5Yy6c9kqW9Y7afTZefcV6c5tZ/logo.png",
            "tags": [
                "bears",
                "Crax"
            ]
        },
        {
            "chainId": 101,
            "address": "8oXhRzmhmf12yrhqHWuso4bKqGnsLNmBfAY2U8w7HwWW",
            "symbol": "GBARS",
            "name": "Gold Bars",
            "decimals": 9,
            "logoURI": "https://raw.githack.com/solanafrontier/tokenlogo/main/frontier.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4vasoS3ZVVEaaoMqPFJUbd7s5sbJE2WVt8a6QZNZx64u",
            "symbol": "KUNAI",
            "name": "Kunai",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/floppy-labs/public-images/master/kunaitoken.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FTaa1sN6woeeDFtSXJ7VsNZiVQKiwXpCnR5WfhsPDRks",
            "symbol": "LYME",
            "name": "Lymewares",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTaa1sN6woeeDFtSXJ7VsNZiVQKiwXpCnR5WfhsPDRks/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6235wbvtTomM4nZ74BJJzyuiV4y9H2hGw8u5me1UjoNn",
            "symbol": "ZNX",
            "name": "ZoneX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZoneX8/ZoneX/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J837mp2mBaSeKeRfwPLyCHunxj9GyUPMp9czdhAW1LyG",
            "symbol": "MTTC",
            "name": "Tsivery",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MTsivery/crypto/main/logo_mttc.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7n2VscxSrpGLBMCXgV9f5rgpKY6LV9U48CmJRN1cq9yJ",
            "symbol": "LDT",
            "name": "Lil Token",
            "decimals": 0,
            "logoURI": "https://github.com/LilDAOs/lilthings/blob/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "spl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EmpqzDmk9iqQn93uDBFcJqiuYCEV6f9iXHJTt4SxjcEN",
            "symbol": "MOFROG",
            "name": "MoFroggie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dakender/MoFroggie-Coin/main/logo-mofroggie.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E7QfCszwdutZovb8rYAPUfj338M3C4szZPPY8RceTiXm",
            "symbol": "BLEND",
            "name": "blendhit",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/redazul/s3/main/blend_coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "blendhit is a competitive 3D community",
                "discord": "https://discord.gg/HDky5cNeSB",
                "twitter": "https://twitter.com/blendhit",
                "website": "https://www.blendhit.com"
            }
        },
        {
            "chainId": 101,
            "address": "CLNTcXKJEqaiKZ53jMTXMuAnTmz7iULKPxSXy6aSaU66",
            "symbol": "CLNT",
            "name": "Coolant (BitWhips)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CLNTcXKJEqaiKZ53jMTXMuAnTmz7iULKPxSXy6aSaU66/logo.png",
            "extensions": {
                "discord": "https://discord.gg/bitwhips",
                "twitter": "https://twitter.com/bitwhips",
                "website": "https://bitwhips.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AUZ89SBMNtcX3H72nShgAnLxFcv8g8UxytN9H68smps6",
            "symbol": "LH",
            "name": "Lee Henson",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Uplordi/crypto/main/logo.png.png",
            "tags": [
                "social-token",
                "community-token",
                "yoroshii"
            ]
        },
        {
            "chainId": 101,
            "address": "EkB2Z2jZDLN8YQVnKeJfAYeoUhCVXtwfmq8eS9fH6kpL",
            "symbol": "RSPT",
            "name": "MKTV Entertainment Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EkB2Z2jZDLN8YQVnKeJfAYeoUhCVXtwfmq8eS9fH6kpL/logo.png",
            "tags": [
                "entertainment-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BCBYNAjzL4xqA8KoaqftqFNoq7jKfa5J4YB2nca2VFfp",
            "symbol": "BCBYWP",
            "name": "BCBY Whale Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCBYNAjzL4xqA8KoaqftqFNoq7jKfa5J4YB2nca2VFfp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3wDZqcNeNzzmrSMn5cTmGK8werqWqRPKqb25HLzHmsA9",
            "symbol": "THCxWL",
            "name": "THC WL TOKEN",
            "decimals": 6,
            "logoURI": "https://cannabiznft.io/assets/images/logo-final.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DK6F53Wm1FWzAguhbt6dPgxoXbDhvDZax1u5sf6pE54L",
            "symbol": "ROOTER",
            "name": "ROOTER TEST TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DK6F53Wm1FWzAguhbt6dPgxoXbDhvDZax1u5sf6pE54L/logo.png"
        },
        {
            "chainId": 101,
            "address": "82EhaAkps3mQWjxiftLFYzEK3xNA2hE4s8BbsUYDAx1f",
            "symbol": "PiPCoin",
            "name": "PanizInParis Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/shahinabdi/Crypto/main/Logo.png",
            "tags": [
                "social-token",
                "NFT",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CrWM7qs9JSzDntLrxtKxuuWdi96zqtxKbx8kQpJ7X725",
            "symbol": "MEL",
            "name": "Mel Coin",
            "decimals": 9,
            "logoURI": "https://gateway.pinata.cloud/ipfs/QmS7x3KF6Gjos396R6gYgFUoK9q7cK9Vv8RPg7KugDBDv5",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6DAQD2CTgBjQW2no6CTUfUXj7U6cvbtRcHSVoMVVWpJS",
            "symbol": "OMENSSOUL",
            "name": "OMENS SOUL CHEST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6DAQD2CTgBjQW2no6CTUfUXj7U6cvbtRcHSVoMVVWpJS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jkYNtg3Rx2",
                "website": "https://omens.art"
            }
        },
        {
            "chainId": 101,
            "address": "PrDgwZN6dQjDLVaTRZN4rhA78EQh76TESsHtFyCB9SG",
            "symbol": "JUTC",
            "name": "JUngle TreAzure Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/catblue88/token-test/main/JUTClogo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3uB5EV7BMFRp1sTnjegeJjyYDpTijgfepEzjY29j9GFx",
            "symbol": "MBSTAKING",
            "name": "Moonbears staking",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/coin.png",
            "tags": [
                "NFTs",
                "Staking"
            ]
        },
        {
            "chainId": 101,
            "address": "4f4D9hsgi2LmMG25MVKnGchybhd6nfZGM6CSuoS9Ynu3",
            "symbol": "TCWT",
            "name": "Trippy Cats WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Deghf666g/catswl/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FrGoxNkySvFmnnCzuwAVZhCz4PbfcyFTBvUc6rNqNH6J",
            "symbol": "YUKAU",
            "name": "YUKON Gold",
            "decimals": 9,
            "logoURI": "https://static.wixstatic.com/media/4b5c4d_db96c2a1b13142fd961d7e05fdfcaf90~mv2.png",
            "tags": [
                "community-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BCBYTtmof1sApJsnrb3yqCsyXcArkUHk6LHNKK5B9poE",
            "symbol": "BCBYHP",
            "name": "BCBY Holder Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCBYNAjzL4xqA8KoaqftqFNoq7jKfa5J4YB2nca2VFfp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2jmhtbcbnmqpTaL7ythKYPS64fdb9VGcTNpXoHNbGPrN",
            "symbol": "PORES",
            "name": "Mushie Spores",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2jmhtbcbnmqpTaL7ythKYPS64fdb9VGcTNpXoHNbGPrN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/magic_mushies",
                "website": "https://magicmushies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BCBYet7eHAwFiPEurNGF1vMdauLehR2PPhqqAMJmSH9E",
            "symbol": "BCBYWL",
            "name": "BCBY WL Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCBYNAjzL4xqA8KoaqftqFNoq7jKfa5J4YB2nca2VFfp/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "41EY95rbDfpebvAXyxmrKHdvShBZ9ASBcRmrke7xqki2",
            "symbol": "U2",
            "name": "U2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/41EY95rbDfpebvAXyxmrKHdvShBZ9ASBcRmrke7xqki2/logo.png",
            "tags": [
                "utility-token",
                "dex",
                "defi",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "discord": "https://discord.gg/rwZ5pycE",
                "twitter": "https://twitter.com/youdex_io",
                "website": "https://youdex.io/"
            }
        },
        {
            "chainId": 103,
            "address": "GobzzzFQsFAHPvmwT42rLockfUCeV3iutEkK218BxT8K",
            "symbol": "G3",
            "name": "G3 Token",
            "decimals": 9,
            "logoURI": "https://static.particle.network/g3.png",
            "tags": [
                "community-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7XVbmgaYHV3BWQvauR77yCRiWwRJUoiCdwN2rnZbJCC1",
            "symbol": "YUKCU",
            "name": "YUKON Copper",
            "decimals": 9,
            "logoURI": "https://static.wixstatic.com/media/4b5c4d_557ccbfa50ec43bc81376c0d96462187~mv2.png",
            "tags": [
                "community-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BjZ5Hazjyp9LrzfapAHYZuceWm6zJZDqMH1QPCWtsouq",
            "symbol": "AERA",
            "name": "Aera Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjZ5Hazjyp9LrzfapAHYZuceWm6zJZDqMH1QPCWtsouq/logo.png",
            "tags": [
                "aera-token",
                "utility-token"
            ],
            "extensions": {
                "website": "https://aeralife.com"
            }
        },
        {
            "chainId": 103,
            "address": "X71v8NH6dbLwPsn4TR1Tx38K4uWgGZ78mC599XSPJox",
            "symbol": "XMLZW",
            "name": "XMLZW Test",
            "decimals": 18,
            "logoURI": "https://github.com/LeviFiks/asf/blob/main/fikusa.svg",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6MTCiSHqANSXinWrJtdpXV7KVtSMkhxhJMFDpEsptjU4",
            "symbol": "SSTAR",
            "name": "Seoul Stars",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6MTCiSHqANSXinWrJtdpXV7KVtSMkhxhJMFDpEsptjU4/logo.png",
            "tags": [
                "gaming",
                "nfts",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "SSTAR is the governance token for Seoul Stars, the leading K-pop virtual artist metaverse and play-to-earn/sing-to-earn music game on Solana",
                "discord": "https://discord.gg/seoulstars",
                "telegram": "https://t.me/seoulstars",
                "twitter": "https://twitter.com/seoulstarsnft",
                "website": "https://www.seoulstars.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6FefhPSmUF9nxW6Jp5Tn6ieGA7aghvRExx5zNNkAsB7c",
            "symbol": "SCI",
            "name": "SCIENCE",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/science.png",
            "tags": [
                "staking-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CcREDkwUxBVoazUe4a8Lw9Jrb483EVpmsw9bzscp7se2",
            "symbol": "kg",
            "name": "Carbon Credit Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/carbon/carbon-credit.svg",
            "tags": [
                "carbon-coin",
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "solana",
                "description": "Proof of Carbon Sequestration to be used as Carbon credit (unit: 1kg CO2e)",
                "imageUrl": "https://bafybeid7dw4tdtbuj5pn4gqlqooajzfaecujcupwzgu2bp6p6z46qlv2g4.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "website": "https://PurpleZone.github.io/NFTs/carbon/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmNwPaqvPMZKxEDZB8vr63wTmf5bZ2JocYY7HamWY8ws3E/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "5ftoDyQvRRL9wFXmaHVN4vYqfdjWue8woQSQ1T8RpinA",
            "symbol": "GCW",
            "name": "GamCheckedWorks",
            "decimals": 9,
            "logoURI": "https://github.com/vuanhtuanvn85/token-list/tree/main/assets/mainnet/5ftoDyQvRRL9wFXmaHVN4vYqfdjWue8woQSQ1T8RpinA/logo.png"
        },
        {
            "chainId": 101,
            "address": "4c5K1DJ3TcUkHt68PrBAzrYJj35r5hv9PAVsL2owYwn5",
            "symbol": "PCC",
            "name": "Purpose Cash",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/purposecash/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B1WXiYQB1CUtdVfPWYMKHenFkpqQc4kujvmBMjP2h5Mb",
            "symbol": "CLST",
            "name": "Celestia",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/timotheecaumartin/crypto-celestia/main/CELESTIA.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5tcVUoxhedTqt9A2gxxToRGSCWDcbK6AC67xayrgW3Sf",
            "symbol": "SKK",
            "name": "Skirk Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/lilhaay1/crypto/main/logo.png",
            "tags": [
                "gaming",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DripS2zAsRhwuALQp2cBW7vBB8LTqYnqAGivtUpEXDWV",
            "symbol": "DRIP",
            "name": "Drips",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/drip/dripcoin_small.gif",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "https://www.discord.gg/drippieverse",
                "instagram": "https://www.instagram.com/drippieverse/",
                "twitter": "https://twitter.com/drippieverse",
                "website": "https://www.drippies.space/"
            }
        },
        {
            "chainId": 101,
            "address": "3rnxGhmJLTB8ds98MXBQJtHKzgSD6MtRTo7phuydyu5x",
            "symbol": "GNN",
            "name": "GYNNA",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3rnxGhmJLTB8ds98MXBQJtHKzgSD6MtRTo7phuydyu5x/logo.png",
            "tags": [
                "comunity-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6EaDCUvz",
                "twitter": "https://twitter.com/gynna_nft",
                "website": "https://gynna.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7GZ1wiXg1ZSCgJ8GmnHEEyMvH7CkNiKChkRpyUNJhSbb",
            "symbol": "TROJAN",
            "name": "Kleos Trojan",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/L0rjuAC.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "description": "Kleos Trojan is the whitelist token to the Kleos Mint.",
                "twitter": "https://twitter.com/kleos_nft",
                "website": "https://kleosnft.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "BAM2LDmxSjbkRNVQC3bNYo514tGSXTKH3NrW3xQEAkwX",
            "symbol": "BAMMM",
            "name": "BAM (The Sol Den)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAM2LDmxSjbkRNVQC3bNYo514tGSXTKH3NrW3xQEAkwX/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/thesoldennfts",
                "website": "https://solden.io/"
            }
        },
        {
            "chainId": 101,
            "address": "E8oV8JywpgPxEzaugAzW4bapoN3krEFX9mCoPgvrdTXH",
            "symbol": "tuSHDW",
            "name": "tuSHDW",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/8619106/169387149-0e34721b-616f-473b-b18d-e46ca40e38ce.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tulip",
                "twitter": "https://twitter.com/TulipProtocol",
                "website": "https://tulip.garden/"
            }
        },
        {
            "chainId": 101,
            "address": "GNjwMCt8GmPwQHy45UDzeprHkSTsNQHddsWXADz1HE6H",
            "symbol": "tuBASIS",
            "name": "tuBASIS",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/8619106/169387491-e7e6c298-eda3-4aed-9e8e-e5e4e8c7c10f.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tulip",
                "twitter": "https://twitter.com/TulipProtocol",
                "website": "https://tulip.garden/"
            }
        },
        {
            "chainId": 101,
            "address": "CEGSnMwZmF512Uva5rTiF1Gx1byMDNdT7UpD8vNXuTQJ",
            "symbol": "tuGMT",
            "name": "tuGMT",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/8619106/169387895-c8604043-2e6c-4769-9400-e535fea11e9f.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tulip",
                "twitter": "https://twitter.com/TulipProtocol",
                "website": "https://tulip.garden/"
            }
        },
        {
            "chainId": 101,
            "address": "Ghvf8bVnDjv1nbi9b3PjSw9iBWFy2ApmdCUgzmPFmNVi",
            "symbol": "tuGST",
            "name": "tuGST",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/8619106/169388131-07867c9e-76bd-4659-8fd4-a0ea57317208.png",
            "tags": [
                "tulip-protocol",
                "lending",
                "collateral-tokens"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tulip",
                "twitter": "https://twitter.com/TulipProtocol",
                "website": "https://tulip.garden/"
            }
        },
        {
            "chainId": 101,
            "address": "Ewf86g35EWcr5dyLSunQkCt5pcnR3hY6bnDAPCKcaye",
            "symbol": "FLINT",
            "name": "FLNT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PnW1/flintToken/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "ANMSoho4nmt5ACg4uM9EeprwvjNJAPFXieaS7sECZ3s4",
            "symbol": "ANMS",
            "name": "Anonymous Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANMSoho4nmt5ACg4uM9EeprwvjNJAPFXieaS7sECZ3s4/logo.png",
            "tags": [
                "staking",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ACDealerNFT",
                "website": "https://anonymouscryptodealer.io"
            }
        },
        {
            "chainId": 101,
            "address": "8z3LQnxfAgTwLjedPyxeRBUHKWmjz1bU7aEccpi3jECr",
            "symbol": "KLEOSWL",
            "name": "Kleos WhiteList",
            "decimals": 1,
            "logoURI": "https://github.com/elonsdev/token-list/blob/add-kleos/assets/mainnet/8z3LQnxfAgTwLjedPyxeRBUHKWmjz1bU7aEccpi3jECr/trojan.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "description": "Kleos Trojan is the whitelist token to the Kleos Mint.",
                "twitter": "https://twitter.com/kleos_nft",
                "website": "https://kleosnft.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "EqxvSkL78VCnzJbfetznFytJdSfaXqZ29wSGKhYuAfQL",
            "symbol": "VIKN",
            "name": "Vikash Network",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/VikashAnandJha/token-list/8e5527428ecec8d96be8e9061652ad34e855952f/assets/mainnet/EqxvSkL78VCnzJbfetznFytJdSfaXqZ29wSGKhYuAfQL/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "VIKN is a Social token of Vikash Network",
                "twitter": "https://twitter.com/vikashanandjha"
            }
        },
        {
            "chainId": 101,
            "address": "JE3YG49wCFQwemcHQo6JLeGmSxgVGgSTpgEm4Eu69Vmk",
            "symbol": "HCOMB",
            "name": "Party Bears Honeycomb",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/IcyyApex/assets/main/HCOMB.png",
            "tags": [
                "staking",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HHfq3uy65SAZgLjpDsJ4WrbjNGpSTfD6UFyQgr2Jxz9i",
            "symbol": "THCxWL",
            "name": "THC WL TOKENS",
            "decimals": 9,
            "logoURI": "https://cannabiznft.io/assets/images/logo-final.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CannabizNFT",
                "website": "https://cannabiznft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5n74w9iCaS3ZpQudGx4hVyupCXcxLomhL6xcatoZXbnS",
            "symbol": "CVGen2xWL",
            "name": "CV Gen2 WL Token",
            "decimals": 9,
            "logoURI": "https://culturevultures.io/gen2/assets/images/cvgen2_logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Vulturesnft",
                "website": "https://culturevultures.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3hv4K3mUaBEDBt4jfWvHQCBdX512r7f23PVCZRGaiDRq",
            "symbol": "XZ",
            "name": "XZAV",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Moonsight91/fndfgdhdhdg/main/xzavicon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2tQrhk5cH41AvqVvwXyvfWQc3tRhuZZSWKvAPAmKVsbw",
            "symbol": "Milei",
            "name": "Javier Milei",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/javiermileiToken/Logo/main/logo.png",
            "tags": [
                "carbon-coin",
                "green-coin",
                "community-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen a Token for Javier Milei,Viva la libertad,carajo. Milei token value 1usdt = 10 Milei tokens",
                "discord": "https://discord.gg/3yNwFyPzUb",
                "instagram": "https://www.instagram.com/lalibertadavanzaoficial/",
                "website": "https://lalibertadavanza.com.ar/"
            }
        },
        {
            "chainId": 101,
            "address": "8ymjMjitLchSFU9zkcbjsJENhSXou4YKh7RD2U3yvqdJ",
            "symbol": "KSW",
            "name": "KillSwitch (Ethereum)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ymjMjitLchSFU9zkcbjsJENhSXou4YKh7RD2U3yvqdJ/logo.svg",
            "tags": [
                "DeFi"
            ],
            "extensions": {
                "discord": "https://discord.gg/vsnK4sYGB4",
                "telegram": "https://t.me/killswitch_global",
                "twitter": "https://twitter.com/KillSwitch_Defi",
                "website": "https://killswitch.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "5BbEGUsoqdNxH16ZrDBMzo9J1kFn2fN6TikYxaFSgddW",
            "symbol": "2XD",
            "name": "2XD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5BbEGUsoqdNxH16ZrDBMzo9J1kFn2fN6TikYxaFSgddW/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "2XD Token",
                "discord": "https://discord.gg/prcWsTvTm9",
                "medium": "https://medium.com/@2XD",
                "twitter": "https://twitter.com/2XDProtocol",
                "website": "https://2xd.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "89iVdMKfyXc5NYmtY9BoGQbps92JGcJ1w594nC7TmVrF",
            "symbol": "CKOG",
            "name": "Cultured Kongz OG Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/89iVdMKfyXc5NYmtY9BoGQbps92JGcJ1w594nC7TmVrF/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/gSHWqephYV",
                "twitter": "https://twitter.com/CulturedKongz"
            }
        },
        {
            "chainId": 101,
            "address": "FUXDfQ2BgeGbzkd37L1TuSe6gPdeDjQra8FbLezr1Nzf",
            "symbol": "FUXD",
            "name": "FUXD",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FUXDfQ2BgeGbzkd37L1TuSe6gPdeDjQra8FbLezr1Nzf/logo.png",
            "extensions": {
                "description": "FUXD Token",
                "discord": "https://discord.gg/prcWsTvTm9",
                "medium": "https://medium.com/@2XD",
                "twitter": "https://twitter.com/2XDProtocol",
                "website": "https://2xd.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "6iDZ3fvuzrnodMp8mrpPynBRzi7fYkw6JMdTUJLGoi6S",
            "symbol": "LFNDWL",
            "name": "LIT FUND WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/dth5geg.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C5z57xrw52",
                "telegram": "https://t.me/litfund",
                "twitter": "https://twitter.com/litfund_defi",
                "website": "https://lit.fund"
            }
        },
        {
            "chainId": 101,
            "address": "Gv2Fgq7AGyRhp4ch5cmdV1H2AGKS9FxKCWkMnxzWwmMm",
            "symbol": "KLEOSM",
            "name": "Kleos Mint Token",
            "decimals": 1,
            "logoURI": "https://github.com/solana-labs/token-list/blob/b62bae5a442b3b6e218b7b7e27ccab52a6a7e24e/assets/mainnet/Gv2Fgq7AGyRhp4ch5cmdV1H2AGKS9FxKCWkMnxzWwmMm/trojan.png",
            "tags": [
                "whitelist"
            ],
            "extensions": {
                "description": "KleosM is the mintlist token to the Kleos Mint.",
                "twitter": "https://twitter.com/kleos_nft",
                "website": "https://kleosnft.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "97HZYZt1APW4HhA1MZxUnnwyhXaYqtpFUVwCKT9xrsHA",
            "symbol": "2XD-USDC",
            "name": "2XD-USDC LP",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97HZYZt1APW4HhA1MZxUnnwyhXaYqtpFUVwCKT9xrsHA/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "2XD-USDC LP Token",
                "discord": "https://discord.gg/prcWsTvTm9",
                "medium": "https://medium.com/@2XD",
                "twitter": "https://twitter.com/2XDProtocol",
                "website": "https://2xd.fi/"
            }
        },
        {
            "chainId": 101,
            "address": "HkJnGnKCoGUF4sL9j8aFfFZozEQpKuj557nFUUvKJC3d",
            "symbol": "SLTK",
            "name": "Soltok",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HkJnGnKCoGUF4sL9j8aFfFZozEQpKuj557nFUUvKJC3d/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.underspenders.com"
            }
        },
        {
            "chainId": 101,
            "address": "FJqUunUUhJffBdrheanuBJySmS2UEShoGLtxKHfBch5R",
            "symbol": "ECLPS",
            "name": "SOL Eclipse",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FJqUunUUhJffBdrheanuBJySmS2UEShoGLtxKHfBch5R/eclipse.png",
            "tags": [
                "to-the-moon"
            ]
        },
        {
            "chainId": 101,
            "address": "CLUbn9QKBwbX3rfFTuvQG3HgERzu7R9naLfJas3WuPgx",
            "symbol": "CLUB",
            "name": "THECLUB",
            "decimals": 9,
            "logoURI": "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/CLUbn9QKBwbX3rfFTuvQG3HgERzu7R9naLfJas3WuPgx/logo.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CczfrwwXahja6Ey3CfQH6mqH6PYAf14QQoGaWZAXVDtX",
            "symbol": "COINT",
            "name": "CoINT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ismaelfema/coint/main/coint.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5q5gWXXT315DnaaoCwrQD39XNxvF5PRLriwMzW6t2338",
            "symbol": "BOSS",
            "name": "The Boss Baby Family",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/atenidegbeolanrewaju/BB_/main/BBFY.png"
        },
        {
            "chainId": 101,
            "address": "D7rPWtAUPRsccndR94M6y3tET85KQiv5v9u6E3YtMjC5",
            "symbol": "GROV",
            "name": "Groove Token",
            "decimals": 9,
            "logoURI": "https://bafybeibrkixvmd3d4urfqfh3dev53gf3pefrhjaoqbbiegwjbcwivuukhy.ipfs.dweb.link/GrooveCoin.png",
            "tags": [
                "governance-token",
                "utility-token",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GrooveDAO",
                "website": "https://groove.day"
            }
        },
        {
            "chainId": 101,
            "address": "HmR2bYbMjRLU81extUiuztz7y79CKYKVZftJC6xLXmJh",
            "symbol": "DVOP",
            "name": "DevOps Token",
            "decimals": 9,
            "logoURI": "https://bafybeicxitbpsfddpmwi2ymxw2wlyxvdrxwwiukxyokptro62at4l3zj4i.ipfs.dweb.link/DVOPCoin.png",
            "tags": [
                "utility-token",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GrooveDAO",
                "website": "https://groove.day"
            }
        },
        {
            "chainId": 101,
            "address": "5Vo6asJMB5jL9KzcVYwW2gyACzennwW8Adf2LZNusw52",
            "symbol": "EPCT",
            "name": "Epics Token",
            "decimals": 9,
            "logoURI": "https://bafybeibal7k2hz6frznyjbl4qcnzcwlsuzxmquatrsaly6ttmuppgmdvwe.ipfs.dweb.link/EpicsCoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EpicsDAO",
                "website": "https://epics.dev"
            }
        },
        {
            "chainId": 101,
            "address": "C5q2yAAgU9rtNdyFJBXcvjmTqf57ejP1iLGZ4bGScFCg",
            "symbol": "EPCD",
            "name": "Epics DAO Token",
            "decimals": 9,
            "logoURI": "https://bafybeifyk4rgmkqcj3kfvzzgjnrf7zvftye5gfcgzbbs2a6caqyphtiiuu.ipfs.dweb.link/EpicsCoinD.png",
            "tags": [
                "governance-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/EpicsDAO",
                "website": "https://epics.dev"
            }
        },
        {
            "chainId": 101,
            "address": "8sfuYgBRH8VBbXWAzyAWNuY8uBhnQgjfd4k5vB5JyoqJ",
            "symbol": "BTBT",
            "name": "bitbot Token",
            "decimals": 9,
            "logoURI": "https://bafybeidmrk25f4ozrsrmodi5bojedomfpowaw42idjxll7hu4cgol4g6my.ipfs.dweb.link/bitbotCoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bitbotDAO",
                "website": "https://bit-bot.app"
            }
        },
        {
            "chainId": 101,
            "address": "AeDGpPrtf1SCcgLT99131xu7C5q3xt2BH1G4Wrri6UHn",
            "symbol": "BTBD",
            "name": "bitbot DAO Token",
            "decimals": 9,
            "logoURI": "https://bafybeiamycg7urzu3rityjodb7hgvsiw7rd44erlgcysucbiauxgppgv7a.ipfs.dweb.link/bitbotCoinD.png",
            "tags": [
                "governance-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/bitbotDAO",
                "website": "https://bit-bot.app"
            }
        },
        {
            "chainId": 101,
            "address": "DfLAX4Cak5A6bDkhnrRJAQtPvQVbfNvY5E2wJ7Q2CSb3",
            "symbol": "OLIV",
            "name": "Olivia",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/akaPierre/olivia/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AWKNP2bYEi5B5JqACwaRudrmVwvdcVWxATkrTdyDzwVX",
            "symbol": "LFNDWL",
            "name": "LIT FUND WL",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/dth5geg.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C5z57xrw52",
                "telegram": "https://t.me/litfund",
                "twitter": "https://twitter.com/litfund_defi",
                "website": "https://lit.fund"
            }
        },
        {
            "chainId": 101,
            "address": "3oscotmVu2wcPoGWxa4ZzxK1FLApCQQBJk9mXtsC26Qy",
            "symbol": "WTOY",
            "name": "Whappy TOYs",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/WTOY.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WcdonaldsNFT",
                "website": "https://wcdonalds.io"
            }
        },
        {
            "chainId": 101,
            "address": "Pirapf3w881Hv5FXjRRpWmEP34WZNDHpV9Jd6DV2o8p",
            "symbol": "Deadpirate",
            "name": "Dead Pirates Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Pirapf3w881Hv5FXjRRpWmEP34WZNDHpV9Jd6DV2o8p/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeadPiratess"
            }
        },
        {
            "chainId": 101,
            "address": "REguZ4WixdYQeLscUbSzHo3MJP4LmoH4cqosXpA3rwp",
            "symbol": "AFR",
            "name": "ReGuLaTeD WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/REguZ4WixdYQeLscUbSzHo3MJP4LmoH4cqosXpA3rwp/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RegulatedNFT"
            }
        },
        {
            "chainId": 101,
            "address": "CGdaZn9CAgsCCapWFTRmdRq9e24gJQet9Ts55V1Dqjo5",
            "symbol": "DAZE",
            "name": "DAZE Utility Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BASCDAO/token-art/3e57fb729cc82a2790533212ed719ffaad3ef965/logo.svg",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AWLXYnJJ9ScoSc9t56CKEPRTuaunqrakcnFwt6AvdXuT",
            "symbol": "ZOOSHIB",
            "name": "ZOO Shibtok",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Wisemunk/My_Solana_coin/main/Logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://futureshiba.000webhostapp.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6tkJPYnMEWGgWPnixqj1qZpN4AL7Dag73EmfRPKNXs1",
            "symbol": "Pie",
            "name": "Pie Network",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/cryptonetwork777/crypto/main/logo.png",
            "tags": [
                "airdrop-token",
                "community-token"
            ],
            "extensions": {
                "telegram": "https://t.me/network_pie",
                "twitter": "https://twitter.com/pie_network"
            }
        },
        {
            "chainId": 101,
            "address": "71MwukaK2Hudp862BC7X7e8Ggyzqx1aMQD611nRM85Vu",
            "symbol": "JUN",
            "name": "Junkiez Token",
            "decimals": 9,
            "logoURI": "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/71MwukaK2Hudp862BC7X7e8Ggyzqx1aMQD611nRM85Vu/logo.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ReGuwoy3cEGfWC7Ko28EeYL1BJg1usVvHD1huyEpamp",
            "symbol": "ReGuLaTeD",
            "name": "ReGuLaTeD Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ReGuwoy3cEGfWC7Ko28EeYL1BJg1usVvHD1huyEpamp/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RegulatedNFT"
            }
        },
        {
            "chainId": 101,
            "address": "2tVaYxWZW9YkGBqUkmoZ6Wnq3ALndTKWoVKNqpwGVngb",
            "symbol": "COA",
            "name": "COA Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/coatsandknights/coa-token/main/coa-token.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/NBQ5YbPHNM",
                "twitter": "https://twitter.com/coatsandknights",
                "website": "https://coatsandknights.io"
            }
        },
        {
            "chainId": 101,
            "address": "87pGBJh1Ts8jGSgD81nXfnRBE773yKdai1ceS4fZ2qc5",
            "symbol": "OGGY",
            "name": "OggyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexkroeker177/oggycoin/main/OGGY.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7GptK2dfK4u8yNU7MUs8eJoFypkuNaKYTR2pG2WfYpcq",
            "symbol": "CNANA",
            "name": "SolMonkeez Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GptK2dfK4u8yNU7MUs8eJoFypkuNaKYTR2pG2WfYpcq/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8JgJUfALp74xF1zdvU3tsbqn2u8ZvaDBmefuU9Lp759L",
            "symbol": "PUNK",
            "name": "PUNK",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/roben-punk/roben-token/main/RP.jpeg",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "8UheazYYUJBWWXvxD6g2q1STGvcaAchLoz3ftwRfoEzs",
            "symbol": "TABE",
            "name": "TabeCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MiltronBee/Tabecoin/main/tabecoin.fw.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FwHjnVkpcxPaabZbJsrjYeFBJkGh1aHxjtBszJUSpHRn",
            "symbol": "ANGLS",
            "name": "Solana Angels NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FwHjnVkpcxPaabZbJsrjYeFBJkGh1aHxjtBszJUSpHRn/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "governance-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/TMQdq7Naq5",
                "twitter": "https://twitter.com/solangels_nft",
                "website": "https://solangelsnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6M9omYci4d51LDueVspcazVJECUb4nMQWeX4YKcqq8KS",
            "symbol": "RG",
            "name": "GLORY",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6M9omYci4d51LDueVspcazVJECUb4nMQWeX4YKcqq8KS/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9oquRLMWrtf7oHNwZDwhZfz1AYUVAqBA9V9rc74nzTvR",
            "symbol": "JOSK",
            "name": "Jolly WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9oquRLMWrtf7oHNwZDwhZfz1AYUVAqBA9V9rc74nzTvR/logo.png",
            "tags": [
                "Whitelist-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CSuPPjRAFH3zt4SMrHRDoZpHapeZAHKPiCJ31KEZS7r5",
            "symbol": "SKHTST",
            "name": "Sikho Coin Test",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CSuPPjRAFH3zt4SMrHRDoZpHapeZAHKPiCJ31KEZS7r5/logo.jpg",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FyNuYGBBry5LAtPEkh8Y73izjTUNT2td2J3sGCK7E9Ju",
            "symbol": "VIBEZ",
            "name": "VIBEZ",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/FyNuYGBBry5LAtPEkh8Y73izjTUNT2td2J3sGCK7E9Ju/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "2zwkTwiN4ABxovt2FaBQpRzVD4Kar7sHi3azBRQZfXSr",
            "symbol": "ALPACA",
            "name": "LLAMA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Eziollama/llamatoken/main/llama-logo.jpeg",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "BQTG5Jah1aLwaMkHgUuoQ1KYjCfmjd9YgHfagQ76AhNS",
            "symbol": "CUB",
            "name": "CUB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cubclub/club007/main/logo-cub.jpeg",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "DG2kMSpmGaFxUSVAFLHk75VzJ6iQEQx3UzGkLoGrAX4D",
            "symbol": "RVLN",
            "name": "Revolution Token",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/Bearzuki/token/logo%20bright.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BearzukiNFT"
            }
        },
        {
            "chainId": 101,
            "address": "EHXVTtFYT1bo4YaHxayHKdpho5G8kdtDY8Uain5rE71G",
            "symbol": "MPOX",
            "name": "MonkeyPOX",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EHXVTtFYT1bo4YaHxayHKdpho5G8kdtDY8Uain5rE71G/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Et4Ni9HzKXByeMx6bPmJLHwLTzhLojxbZe5pZJgpwjYr",
            "symbol": "PENGUIN",
            "name": "Pengu",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Pawel-Pe/storage/main/logo-penguin.jpeg",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "DeGbpw8kYC8YCiH4f6JL9ppMWdJ2nC6GZAxasFXfaqVW",
            "symbol": "TREE",
            "name": "TREE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/crypto-tree/tree-of-life/main/treelogo.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "53y2xph9jy2wFTBC1jgZXo1kan8XAuMPLj6x7UgyMvXB",
            "symbol": "YUKPT",
            "name": "YUKON Platinum",
            "decimals": 9,
            "logoURI": "https://static.wixstatic.com/media/4b5c4d_8e19a4a1c2404935875498c203190430~mv2.png",
            "tags": [
                "community-token",
                "trading-token"
            ],
            "extensions": {
                "website": "https://www.yukonmining.io"
            }
        },
        {
            "chainId": 101,
            "address": "4mXbAjYsLCYVbFu7PedBY6wcfCSH6KVyNtSZNbvMsjbH",
            "symbol": "POLE",
            "name": "Pole Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/zball07/crypto/main/POLE%20Token%20Logo.png",
            "tags": [
                "utility-token",
                "payment-token",
                "investment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "66XLRNqb1CnPd1ZooD5hSNtz56SNNwywACTFzWq9Nvyp",
            "symbol": "SHOW",
            "name": "theShow Plus Coin",
            "decimals": 9,
            "logoURI": "https://storage.googleapis.com/theshowcoin-asset/imgs/token-coin/logo.png"
        },
        {
            "chainId": 101,
            "address": "GJGCVHGErDYkiPvnzq7cBUi2P1GsT6zhxVhVnkA8TARY",
            "symbol": "SOLRUM",
            "name": "Mutant Solrum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJGCVHGErDYkiPvnzq7cBUi2P1GsT6zhxVhVnkA8TARY/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MascNft",
                "website": "https://mutantapesolana.club"
            }
        },
        {
            "chainId": 101,
            "address": "5f8UTs1h73cpzZK8xU82gafyLjR43JFHQdT3A3WVNAS8",
            "symbol": "MLWT",
            "name": "Mutant Labs whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5f8UTs1h73cpzZK8xU82gafyLjR43JFHQdT3A3WVNAS8/logo.png"
        },
        {
            "chainId": 101,
            "address": "GroARooBMki2hcpLP6QxEAgwyNgW1zwiJf8x1TfTVkPa",
            "symbol": "GROAR",
            "name": "Jungle Cats Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GroARooBMki2hcpLP6QxEAgwyNgW1zwiJf8x1TfTVkPa/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://junglecats.io/"
            }
        },
        {
            "chainId": 101,
            "address": "5iXRqsoxw5yf5b7AUZ2nNSJL8k4EocYHtWB9VndSRGDr",
            "symbol": "GGWL",
            "name": "GolemGuardians W/L",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/cashboysen/golem1/main/GolemTokey.png"
        },
        {
            "chainId": 101,
            "address": "F5FsobHiQ1SAd7HNJBhLoyrtZxrWzYwCKJrZ6mqqjYJ6",
            "symbol": "RTG",
            "name": "Road To Glory",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mazagangi7/Road-to-Glory/main/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5bfheMDpL1rDFzo53nWx2zSjsKNVG9wdYDtuhDnnabu7",
            "symbol": "PxBC",
            "name": "PxB Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/PriceFB/PixelBabes-website/main/assets/images/pxbc.png",
            "tags": [
                "community-token",
                "utility-token",
                "investment-token"
            ],
            "extensions": {
                "website": "https://www.pixelbabes.io"
            }
        },
        {
            "chainId": 101,
            "address": "9TeUnTLnQCRAC3erDjgXrpnVgkwcP283VEWM5o8QTNXF",
            "symbol": "OKAYMAYC",
            "name": "Mutant Serum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TeUnTLnQCRAC3erDjgXrpnVgkwcP283VEWM5o8QTNXF/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/okaybayc",
                "website": "https://okaybayc.com"
            }
        },
        {
            "chainId": 101,
            "address": "sEedsCkfvPzjnfPNWVJAeNkNZf8yWTwZF3jh42R4X25",
            "symbol": "SEEDS",
            "name": "TSF SEEDS",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sEedsCkfvPzjnfPNWVJAeNkNZf8yWTwZF3jh42R4X25/logo.jpg",
            "tags": [
                "nft-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/stonedfrogs",
                "twitter": "https://twitter.com/thestonedfrogs",
                "website": "https://www.thestonedfrogs.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GkpbHQu2zYmJxyp93p9wTX3uHjsFt8ZGeomVwZkGwXLH",
            "symbol": "sGENE",
            "name": "Synthetic Genopets",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkpbHQu2zYmJxyp93p9wTX3uHjsFt8ZGeomVwZkGwXLH/logo.png",
            "tags": [
                "genopets",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/genopets",
                "twitter": "https://twitter.com/genopets",
                "website": "https://genopets.me"
            }
        },
        {
            "chainId": 101,
            "address": "DhpikB5Qf4YZRzeGpFiAcZdSPAETS1mLF94PZz3oUos1",
            "symbol": "NAPE",
            "name": "NAPE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/NotBoredApe/token-list/main/assets/mainnet/DhpikB5Qf4YZRzeGpFiAcZdSPAETS1mLF94PZz3oUos1/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "43nugMCbD2J7BDiDDTxUD15jcLnexdPtftPdANYy9tc6",
            "symbol": "QKA",
            "name": "QuokkaCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43nugMCbD2J7BDiDDTxUD15jcLnexdPtftPdANYy9tc6/quokka.png",
            "extensions": {
                "discord": "https://discord.gg/E237janjhJ"
            }
        },
        {
            "chainId": 101,
            "address": "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
            "symbol": "$SNEK",
            "name": "SNEK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/zen-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/ZenjinViperz",
                "twitter": "https://twitter.com/ZenjinViperz",
                "website": "https://www.zenjinviperz.io/"
            }
        },
        {
            "chainId": 103,
            "address": "CZero7niHAHbW29oVf2XoCwDWznBaDbd2NxRq9zXfGW4",
            "symbol": "btc-z",
            "name": "CarbonZero BTC",
            "decimals": 10,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/btcz/btc-z.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "bitcoin",
                "description": "Removing Carbon in cryptocurrencies one coin at a time",
                "imageUrl": "https://bafybeiaey7aarrnghsu6ziv6cofpqftj22c4ssyf4d74feccfgkmsw63du.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/btcz/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmYaMUFdqHmTk4V3HLfHwK3pq8d3Tqpk8s96ftb7WUkS1w/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "CHNKTCk77cP7U15xC6UQC2zB2HQY8nnEwTD5RFvYK9Gn",
            "symbol": "SCREW",
            "name": "Meta Robots WL token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CHNKTCk77cP7U15xC6UQC2zB2HQY8nnEwTD5RFvYK9Gn/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9Tn4dFiSokzKDoiAHVMH2L7TqaRwKbGDz4PCsMS6RkbC",
            "symbol": "PROFT",
            "name": "Profit protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Tn4dFiSokzKDoiAHVMH2L7TqaRwKbGDz4PCsMS6RkbC/logo.png",
            "tags": [
                "social-token",
                "SocialFi"
            ]
        },
        {
            "chainId": 101,
            "address": "HD2WMkhqBqHBeuPaJfziGwSGTaaJTVfWs9drW7yTsLtk",
            "symbol": "NORY",
            "name": "NORY Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HD2WMkhqBqHBeuPaJfziGwSGTaaJTVfWs9drW7yTsLtk/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NoryozNFT"
            }
        },
        {
            "chainId": 101,
            "address": "D5fQ4jY4GxKfTjdqe7Rnwbu1wN1DLu7mwtmJxPsT9Zr6",
            "symbol": "FERO",
            "name": "Forest Industry staking token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D5fQ4jY4GxKfTjdqe7Rnwbu1wN1DLu7mwtmJxPsT9Zr6/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G2VJZGHdH6K6Ck4XDc9b6Nmi32c67gtZXrwufT4RFuHK",
            "symbol": "DON",
            "name": "THE DON FAMILY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/atenidegbeolanrewaju/BB_/main/DON.jpeg"
        },
        {
            "chainId": 101,
            "address": "9fdPtdnXYJGJgaioSXn4GdoPLmcbFpji68BuN7Xsjr45",
            "symbol": "MYWL",
            "name": "Minty Wl",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MintyAutomation/minty-logo/main/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/minty",
                "twitter": "https://twitter.com/mintyautomation"
            }
        },
        {
            "chainId": 101,
            "address": "J5kDSC7iaz69uq8vAV6iim5RpxbdvHyghDd6ecNbvNBY",
            "symbol": "MYWLG",
            "name": "Minty Wl Guaranteed",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MintyAutomation/minty-logo/main/logo.png",
            "tags": [
                "wl-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/minty",
                "twitter": "https://twitter.com/mintyautomation"
            }
        },
        {
            "chainId": 103,
            "address": "yca52CtBFG9skA8FVLfvsVcbFZMfUWvmyBt4hGWzVuF",
            "symbol": "YCA",
            "name": "YC Apocalypse",
            "decimals": 1,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/YCA/yca-coin.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "The YCA token is part of our REGS program at EO Technologies to move beyond YC apocalypse",
                "imageUrl": "https://bafybeidzazuktrg74jmbz67jpddi34chsldu2mxyrbncbx6ocvubdmdpyy.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/YCA/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmNe4W3y2NHEpRChzPT6GjXoHUAQCAwWLMLMLzkijUCQKq/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "FCWL8z8rrryZGs3BKSiiEeggmGv9sSdvs4jcJbm8LLYh",
            "symbol": "FCWLT",
            "name": "Fine Cats Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCWL8z8rrryZGs3BKSiiEeggmGv9sSdvs4jcJbm8LLYh/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BB2gejCCLsh84iJPjsoZivSEBWn3Y6zZLk6XnVKhPGCv",
            "symbol": "BBCA2WLT",
            "name": "Big Brain Clique Alpha WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BB2gejCCLsh84iJPjsoZivSEBWn3Y6zZLk6XnVKhPGCv/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BMWToutR4MZJfywAKq6w8qTU4DPE6vb9cXFhFaThEnxe",
            "symbol": "BMVWWLT",
            "name": "BMV Wolves Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BMWToutR4MZJfywAKq6w8qTU4DPE6vb9cXFhFaThEnxe/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6N3sRS3FGZszkSYgVe4zk8W42jPpQKfcqpe7fv1eC5UF",
            "symbol": "BLACKB",
            "name": "Black Bear",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/harper-bear/bearT/main/logo.jpeg",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "BENSbkQQjkNywEo6Q1M5PoNCugvg7Efe1xCXzTYSyt1V",
            "symbol": "Riot",
            "name": "LeagueOfLegends",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/LeagueofLegendsToken/Logo/main/logo.png",
            "tags": [
                "riot-coin",
                "Riotpoint-coin",
                "NFT-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen a Token for LeagueofLegends Riot token value 1usdt = 10 Riot tokens",
                "discord": "https://discord.gg/3yNwFyPzUb",
                "instagram": "https://www.instagram.com/CarlosVerbelen/",
                "website": "https://unstoppabledomains.com/d/zues369.nft"
            }
        },
        {
            "chainId": 101,
            "address": "8KnaNdNFh2rnhysmBwK3tzaKkz6ywD2JQ63yDjcRpQMk",
            "symbol": "NTGC",
            "name": "NateTheGreatCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Coolnatethegreat/Crypto/main/logoforcryptotoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GfZTt4bgmeKmhTaMfpxvfRrnWkiDJ8yzpZ7AFbrJFpbo",
            "symbol": "NBCC",
            "name": "NaByCoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Sestere-IA/NabyCoCoin/main/WhatsApp%20Image%202022-05-21%20at%2015.22.59%20(1).jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DzogDPd7CcjRGYZgG8mVybvhrxDnB7zn9VwXMvZFM2nG",
            "symbol": "GREENGLASS",
            "name": "GREEN GLASS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/green.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5DcMU3LVh5gjimK5hK6qDBb6cJq5ktUV3vBsUzntK9BD",
            "symbol": "MFT",
            "name": "Mura Fan Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/blink3301/MFT/main/logo.png",
            "tags": [
                "social-token",
                "fan-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KidzxFVUbMLqfh6CUXMAPRzkuW4UWUGuUJ8WmYEZ2bn",
            "symbol": "KTWL",
            "name": "KidzTokyo WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KidzxFVUbMLqfh6CUXMAPRzkuW4UWUGuUJ8WmYEZ2bn/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KidzTokyo"
            }
        },
        {
            "chainId": 101,
            "address": "Hajw7G1jUtLRLBjZjTeaBwuE9nkViomFvtfRnZVNZ9bm",
            "symbol": "MURPH",
            "name": "MurphCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/S3ANZ13/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3xFBvqGAGidvXYJvW1i5Vjwbv7N4CT6grW6ePXdQcJuJ",
            "symbol": "GBS",
            "name": "Good Boy Sammy",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KattariStudios/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6xv1QnqPAHQbwx73sNMdeocRvdyKbLmkmFXZSsJ9JtMh",
            "symbol": "IceFrog",
            "name": "Dota",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/IcefrogDota/Dotalogo/main/logo.png",
            "tags": [
                "dota-coin",
                "iceFrog-coin",
                "governance-token",
                "NFT-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for Dota NFT token value 1usdt = 10 Icefrog tokens",
                "discord": "https://discord.gg/3yNwFyPzUb",
                "twitter": "https://twitter.com/IceFrog",
                "website": "https://unstoppabledomains.com/d/zues369.nft"
            }
        },
        {
            "chainId": 101,
            "address": "MTSUtji2nigjKJLoqCgvpdqoDSwpjCoFo8rWRPS8uvS",
            "symbol": "MITSU",
            "name": "Mitsu Bears Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MTSUtji2nigjKJLoqCgvpdqoDSwpjCoFo8rWRPS8uvS/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://mitsubears.io/"
            }
        },
        {
            "chainId": 103,
            "address": "3Cvq1aEYCHbg2b5VpnQU884QGXr6VkkFsqywkrdH8Hv3",
            "symbol": "DECRBNX",
            "name": "DeCrbnX PoC",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Cvq1aEYCHbg2b5VpnQU884QGXr6VkkFsqywkrdH8Hv3/logo.png",
            "tags": [
                "utility-token",
                "nft"
            ],
            "extensions": {
                "description": "DeCrbnX Voluntary Carbon Trading Platform",
                "website": "https://www.decrbnx.com/"
            }
        },
        {
            "chainId": 101,
            "address": "FMjZMJswdCrK9U3aogY1a82iGnYdFcWTEPFzt6Kv61v5",
            "symbol": "U1C",
            "name": "Un1ted Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FMjZMJswdCrK9U3aogY1a82iGnYdFcWTEPFzt6Kv61v5/logo.png",
            "tags": [
                "un1ted-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "t99hPXrjb1Zcx2QRLAhLhundAibYKSULQksPuEsNWSk",
            "symbol": "BFS",
            "name": "Best Friend Sammy",
            "decimals": 0,
            "logoURI": "https://github.com/PKattari22/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "HGXMNjuvCi1rRKKE4Uxq2QCsLfzuJQAbfMsrMmftchAj",
            "symbol": "KRSh",
            "name": "KRISH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/noahdark/image-crypto/main/krish.png",
            "tags": [
                "community-token",
                "trading-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C5yrBrY6XRaaxCZ7FDHMhkRPpKmYuFG4A3rAMDJqgXhy",
            "symbol": "APXWL",
            "name": "Airplex WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C5yrBrY6XRaaxCZ7FDHMhkRPpKmYuFG4A3rAMDJqgXhy/logo.png",
            "tags": [
                "Whitelist",
                "APX"
            ]
        },
        {
            "chainId": 101,
            "address": "GNMuD3LYBi2JMoCpggr5rAhuiPi8sNuhX8t9rK13bkfp",
            "symbol": "BaBi",
            "name": "baby coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GNMuD3LYBi2JMoCpggr5rAhuiPi8sNuhX8t9rK13bkfp/logo.png",
            "extensions": {
                "description": "baby coin which is release from LonYui|mai hua shao nian",
                "website": "https://0xflower.shop/"
            }
        },
        {
            "chainId": 101,
            "address": "3AGhCVBunbbo2bc2bkzLXaRu5rZXgMBpsK1svhjgwcGE",
            "symbol": "SHRTBK",
            "name": "SchruteBuck",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/S3ANZ13/Crypto/S3ANZ13-SchruteBucks/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5QTEfnox5cFCCMCmggGNftAvZ8wwvpttBUuiTQr6Sswq",
            "symbol": "MSBS",
            "name": "MSB Service",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5QTEfnox5cFCCMCmggGNftAvZ8wwvpttBUuiTQr6Sswq/logo.png"
        },
        {
            "chainId": 101,
            "address": "RRTBB9xWcYD9DvaTX7R8UgYbNmxDkVsM2jo1iEXm1Et",
            "symbol": "RR",
            "name": "Roadhouse Runts Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RRTBB9xWcYD9DvaTX7R8UgYbNmxDkVsM2jo1iEXm1Et/logo.png"
        },
        {
            "chainId": 103,
            "address": "NfWLyJSeXsEwWxgvZxuq3nhSXDnqkCKzf3YGSrLgTPx",
            "symbol": "nfwl",
            "name": "Non Fungible Wallet",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/wallet/nf-wallet.svg",
            "tags": [
                "green-wallet",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "usd-coin",
                "description": "NFWL: is a subwallet to your wallet for organizing your precious sustainable tokens",
                "imageUrl": "https://bafybeibmzz5vapcfx7daybdhbfqxjlwcrszlbq6j2gvayexn4rl6olndoe.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/wallet/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmPhMEKGiuyXg9eq2x8aEgjnEMzR5tHEcuSBSMayzR9uVr/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "768S3Qsg5YbmmoDinvshAVhYjSnLq5VPrnvzVxhLNxjW",
            "symbol": "HMO",
            "name": "HammoCoin",
            "decimals": 0,
            "logoURI": "https://github.com/pmjmay/crypto/blob/main/HammoCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkY8QUqn7DRm1TUVtEJ8kiycotDvuaNSKCn1YchJpLHS",
            "symbol": "LNDC",
            "name": "Lean Do",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/vitorcriptos/solana/main/icone.png"
        },
        {
            "chainId": 101,
            "address": "FjA8FNXryYsPEoND6RfUoXffXhZMpy6YDA83G5pz5bwP",
            "symbol": "RGD",
            "name": "ReGuLaTeD Stake Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FjA8FNXryYsPEoND6RfUoXffXhZMpy6YDA83G5pz5bwP/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/RegulatedNFT"
            }
        },
        {
            "chainId": 103,
            "address": "X6Redh1rbeDW7XBdvwNSjf71iw8KsrRhbjhPnS8BriY",
            "symbol": "X6R",
            "name": "6th eXtinction Rebellion",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/X6R/X6R.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "This token is a membership token for the X6R group acting in fixing the world.%0A--@cryptolake2",
                "imageUrl": "https://bafybeifokz3w73lskgqe5z34ucv2e52jpb4xd3gc5iuert5ivlpzpzgm2a.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/X6R/",
                "whitepaper": "https://gateway.ipfs.io/ipns/Qmbsa2XG9WzBgVNGaUKnTv3CvnfXJtDxHX2LsHMLRfsTaS/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "miWLpCeMRnJ5zEhH952cYAQK4KN9MPbCrnXKMSnFpCE",
            "symbol": "MIWL",
            "name": "Minibot WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/miWLpCeMRnJ5zEhH952cYAQK4KN9MPbCrnXKMSnFpCE/ssataei.png"
        },
        {
            "chainId": 101,
            "address": "8PMGziWVwD7foVgX3WMpm3MkEkXLdwTTgZBUESXK1Nrp",
            "symbol": "OSOULS",
            "name": "OMENS SOULS CHEST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PMGziWVwD7foVgX3WMpm3MkEkXLdwTTgZBUESXK1Nrp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jkYNtg3Rx2",
                "website": "https://omens.art"
            }
        },
        {
            "chainId": 101,
            "address": "TGTj1biiXa6JNg39a9i8rzQHzgDCjawQtr7xyDvKaVr",
            "symbol": "TGT",
            "name": "Tapn Game Token",
            "decimals": 6,
            "logoURI": "https://static.tapn.gg/token/tgt-signet.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/XhHrn2kef3",
                "twitter": "https://twitter.com/tapngg",
                "website": "https://tapn.gg"
            }
        },
        {
            "chainId": 101,
            "address": "GKUSBwYCx3DVBJoCqsEJ1xpY9qJ8aBQXeL4V1F57TApN",
            "symbol": "AVCD",
            "name": "Avocado",
            "decimals": 0,
            "logoURI": "https://github.com/Nillidok/Avocado/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CyCtkcjsFtCKQysCLzZ9UunTpXdMSkzvnVByewmjbYxn",
            "symbol": "SAMX",
            "name": "Super Automobile Mangement eXpert",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/fullstackdev0410/spl-crypto/main/token.PNG",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HuwsCqtiosGwxiqpd51DMn8y7FBkJSvrLV2DFa2dpoHD",
            "symbol": "OKCT",
            "name": "OddKey Cafe' Tokens",
            "decimals": 0,
            "logoURI": "https://arweave.net/PIBs4KTZANdrMV6DTzt-M75DpC67zGF1poRtOSznhhw",
            "extensions": {
                "discord": "https://discord.gg/oddkeynft",
                "serumV3Usdc": "9hBhJZEcXi7PBBv2fPLsaTjKEcwtcCkYVfmgxAdZBFd3",
                "twitter": "https://twitter.com/OddKeyCafe",
                "website": "https://oddkey.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BKQjRqhganhpqWsJhdBHbXKGVuvXdcu4bd5GWJfXJGEP",
            "symbol": "DALSNA",
            "name": "dalsna",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKQjRqhganhpqWsJhdBHbXKGVuvXdcu4bd5GWJfXJGEP/Original.png"
        },
        {
            "chainId": 101,
            "address": "tripUsjy3rYrWpw6D2fj6dWhXCZSog7nzYmMXXp2wN6",
            "symbol": "APE",
            "name": "Trippin Ape Tribe Token WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/tripUsjy3rYrWpw6D2fj6dWhXCZSog7nzYmMXXp2wN6/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/trippinapetribe",
                "twitter": "https://twitter.com/trippinapenft"
            }
        },
        {
            "chainId": 101,
            "address": "E8G4uo2i9d12aGnXDHXXcw6hU2fh2NytR5XR3qurTLBx",
            "symbol": "WNDO",
            "name": "WNDO",
            "decimals": 9,
            "logoURI": "https://static1.squarespace.com/static/6252f1590fac8d79fb6d772e/t/627682f25295251e0967c29c/1651933939477/wndocoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "WNDO is a utility token used on the WNDO platform.  Buy stuff in the real world get rewarded in WNDO!",
                "website": "https://www.wndo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "D9yWWB2NpBdmYHgzSXa5VfHWB6pWrJSpYzvr6ecVEmqb",
            "symbol": "BRA",
            "name": "Bragabilia",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/BBBragerton/token-v3/main/BToken.png",
            "tags": [
                "nft-token",
                "community-token",
                "asset-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H9bisooRJP317gRKqdqDf1pzQC6VcWBCRFfthLKrpCwT",
            "symbol": "BNNA",
            "name": "The Moonkees Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/TheMoonkeesNFT/TheMoonkees_logo/main/assets/Logo%20transparente%20blanco.png",
            "tags": [
                "Utility-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/themoonkeesnft",
                "twitter": "https://twitter.com/TheMoonkeesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "HAYjU4YMHT7rjguMNCBg2XEmYErxCoFVd8xtDCtP1ANK",
            "symbol": "$HAY",
            "name": "Horseys HAY Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAYjU4YMHT7rjguMNCBg2XEmYErxCoFVd8xtDCtP1ANK/logo.png"
        },
        {
            "chainId": 101,
            "address": "GdTXHhRr9UoqaYj2MC9hPz4mjE3vigGbUEfjaDCU2onq",
            "symbol": "FIRE",
            "name": "Fire Rune",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdTXHhRr9UoqaYj2MC9hPz4mjE3vigGbUEfjaDCU2onq/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Fire Rune token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "8hHra5o2LYCUrog9KrR39uT6WW4meTBtHKCDJbyr44ox",
            "symbol": "EARTH",
            "name": "Earth Rune",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8hHra5o2LYCUrog9KrR39uT6WW4meTBtHKCDJbyr44ox/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Earth Rune token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "JAJGBFCgN8YBP1ked9yRd5j52gWfyrkJAHEPQa4UUPDr",
            "symbol": "ICE",
            "name": "Ice Rune",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAJGBFCgN8YBP1ked9yRd5j52gWfyrkJAHEPQa4UUPDr/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Ice Rune token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "A1iT9r65MpvabW47nfvKwBDhwMjrja2ub6Fs9iD2k46U",
            "symbol": "SAND",
            "name": "Sand Rune",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A1iT9r65MpvabW47nfvKwBDhwMjrja2ub6Fs9iD2k46U/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Sand Rune token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "EUvo8GYTf2ntMGVkRqpWdN4DjAfyxkPvCKfguSuRNpK2",
            "symbol": "DRGN",
            "name": "Dragon Scale",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EUvo8GYTf2ntMGVkRqpWdN4DjAfyxkPvCKfguSuRNpK2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Dragon Scale token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "HEqxKLV8kW2XYd8XxCH7nPxCa5AYxhF1EqpsXCMUWDpa",
            "symbol": "CORN",
            "name": "Corn",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEqxKLV8kW2XYd8XxCH7nPxCa5AYxhF1EqpsXCMUWDpa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Corn token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "4LW9ELPQg45772qU7ZT9q8BP19o3fuXcv8P15fs1MYuX",
            "symbol": "PRICK",
            "name": "Prickly Pears",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4LW9ELPQg45772qU7ZT9q8BP19o3fuXcv8P15fs1MYuX/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Prickly Pears token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "EdWYL5Z4tCCsMtdxPXT5iV9Ycg46U6mvCJDsQV9zQkCm",
            "symbol": "HOLLY",
            "name": "Snow Holly",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdWYL5Z4tCCsMtdxPXT5iV9Ycg46U6mvCJDsQV9zQkCm/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Snow Holly token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "FyctSeF2E7pcM3GRQdtfeAYsCPeHNWkgmdYGebB3y97A",
            "symbol": "MAGM",
            "name": "Magma Peppers",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FyctSeF2E7pcM3GRQdtfeAYsCPeHNWkgmdYGebB3y97A/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Magma Peppers token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "6EMv9MPiTL5oWzkBHr9XmHHtRpUk12nKGfqboDFyY87P",
            "symbol": "PINEH",
            "name": "Pine Honey",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6EMv9MPiTL5oWzkBHr9XmHHtRpUk12nKGfqboDFyY87P/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Pine Honey token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "FjJyWJfnb39fdsswQmzNbVxCsH976SQZGFPzXvN5ufwc",
            "symbol": "SCORP",
            "name": "Sand Scorpion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FjJyWJfnb39fdsswQmzNbVxCsH976SQZGFPzXvN5ufwc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Sand Scorpion token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "CnSNBakQSv2MW7pdvdcAHpmUTmvPoChg3NfRtZ7HSaGA",
            "symbol": "HARE",
            "name": "Ice Hare",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CnSNBakQSv2MW7pdvdcAHpmUTmvPoChg3NfRtZ7HSaGA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Ice Hare token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 101,
            "address": "HvCbPvCoDhynUffEYP8DeNDxDQ7hetnBxqFWFM6Svsa4",
            "symbol": "MICE",
            "name": "Ash Mice",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HvCbPvCoDhynUffEYP8DeNDxDQ7hetnBxqFWFM6Svsa4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Ash Mice token for SolCraft Game",
                "discord": "https://discord.gg/solcraft",
                "twitter": "https://twitter.com/solcraftgame",
                "website": "https://solcraft.online/"
            }
        },
        {
            "chainId": 103,
            "address": "CRLKh4simiVwwtgyNL21EGmixS5viCETUDyhfiKZXZXj",
            "symbol": "CRPTLK",
            "name": "cryptolake",
            "decimals": 9,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/cryptolake/cryptolake.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "A token for CryptoLake membership --@cryptolake2",
                "imageUrl": "https://bafybeibdnlyepxwur4twznpbriutw24qjzdgxslh6k7jg455d2sbzn3gie.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/cryptolake/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmUbroCsNCYvDsHiAx2etSw5xfHpkFUpqum5hLajnoxd5k/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "HPwpvG7M1aqkrbZMRH7EjB8ZadG8jTsQ3g7U4Utb2Apc",
            "symbol": "TRAVEL",
            "name": "Destinator Travel Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HPwpvG7M1aqkrbZMRH7EjB8ZadG8jTsQ3g7U4Utb2Apc/logo.png",
            "tags": [
                "nft-token",
                "metaverse-token",
                "staking-token"
            ],
            "extensions": {
                "description": "Destinator Travel Token - To be used for purchases inside the Destinator Metaverse and NFT Staking platform.",
                "website": "https://www.destinator.io"
            }
        },
        {
            "chainId": 101,
            "address": "7d9YNkXGxL8gN9dkZVw1Bt2yQyvXhvatHsC1J4w96F7w",
            "symbol": "CVGen2xWL",
            "name": "CV Gen2 WL Tokens",
            "decimals": 0,
            "logoURI": "https://culturevultures.io/gen2/assets/images/cvgen2_logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Vulturesnft",
                "website": "https://culturevultures.io/"
            }
        },
        {
            "chainId": 101,
            "address": "23CqJ2yUqyQYchdoK3uKAt2adC23UZ9TtPsquFhtwrB1",
            "symbol": "JAP",
            "name": "JAP00_",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/23CqJ2yUqyQYchdoK3uKAt2adC23UZ9TtPsquFhtwrB1/logo.jpg",
            "tags": [
                "utiliy-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3DWL5LRJwWiXzDRUYJ1Ky2Dv6TRx5v4F7KSrCq1o6LJr",
            "symbol": "3DFUNWL",
            "name": "3D FunGuyz WL",
            "decimals": 0,
            "logoURI": "https://bafkreibp4wzfh5lrqr3gqlcduk4hk64qwmosyryhwrqjaa7qqcggyrylqe.ipfs.nftstorage.link/?ext=png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "CONGRATS! You have received a WL token for the 3D FunGuyz Mint! Mint Price: 4 SOL, Wen Mint: May 26th 2022, Total Supply: 3000",
                "discord": "https://discord.gg/dEKxrxt8ac",
                "twitter": "https://twitter.com/FunGuyzNFT",
                "website": "https://funguyz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9TYa4pa644XhZAAK25oAotKiUF8345QefGYV5q7KrebN",
            "symbol": "IDIOT",
            "name": "4 IDIOTS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TYa4pa644XhZAAK25oAotKiUF8345QefGYV5q7KrebN/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "4yx2gD7heLLTHGLN4CGPCaGYsCRfMnadcq8nLm1aWXDk",
            "symbol": "FAME",
            "name": "FAME",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4yx2gD7heLLTHGLN4CGPCaGYsCRfMnadcq8nLm1aWXDk/logo.png"
        },
        {
            "chainId": 101,
            "address": "7Sp7Rk23f1czHPUhscoXo8cXm6ZSLsDHYNdzCpgQsEzk",
            "symbol": "EPAL",
            "name": "EPAL Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/EPAL21/sol-token/main/epal-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ETnTP9jVHByQTse6zeohCAZAD5zHpynsCY4Zq9EQSKSW",
            "symbol": "HYPEBAC",
            "name": "HYpe Book and Claim",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ETnTP9jVHByQTse6zeohCAZAD5zHpynsCY4Zq9EQSKSW/HY.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6RXd57bcmKQLyhwBfzBjr5U2xyZfv843ceBeZki49dRM",
            "symbol": "FREE",
            "name": "FREE ReGuLaTeD Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6RXd57bcmKQLyhwBfzBjr5U2xyZfv843ceBeZki49dRM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "A token at the heart of the ReGuLaTeD ecosystem.",
                "discord": "https://discord.gg/regulated",
                "twitter": "https://twitter.com/RegulatedNFT",
                "website": "https://www.regulatednft.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FUjcq8j9j1bAJnnEBwGQYT3WGUVLEDBjbR8ZgtcYJsAW",
            "symbol": "1LOVE",
            "name": "Kocham Cie",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/EPAL21/Kocham-Cie/main/kochamcie-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GgZGpi7ycFNNFTt33rKH8gbP5dpD3PVHKBhKa5UHqCck",
            "symbol": "CRAY",
            "name": "CRAY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GgZGpi7ycFNNFTt33rKH8gbP5dpD3PVHKBhKa5UHqCck/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://peppyplatypus.com/"
            }
        },
        {
            "chainId": 103,
            "address": "7rQBH5uxDHbcP34zbEic8HDroejAyzwJnM96mbfgztFN",
            "symbol": "VDAO",
            "name": "Virgin DAO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rQBH5uxDHbcP34zbEic8HDroejAyzwJnM96mbfgztFN/logo.png",
            "extensions": {
                "discord": "https://discord.gg/https://discord.gg/NpZhjmTxY4",
                "twitter": "https://twitter.com/virgindao_sol"
            }
        },
        {
            "chainId": 101,
            "address": "BzzCLSUrTM8qK2m1m8NrGEfXBY7kuQmhUeFjvFLM78T3",
            "symbol": "SPC",
            "name": "SOULPATS",
            "decimals": 0,
            "logoURI": "https://github.com/swordphish3d/crypto/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DwH6w7pqjX923YbE94grsVe6Fbb2GPesYPSDPHuSTGLb",
            "symbol": "SMC",
            "name": "Simcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Simon271207/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Kec1tnaGXP9DggbauY9ieGQSy4pjH8HJzUEMHqhKBQz",
            "symbol": "ATOMS",
            "name": "Hexa Digital Assets",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hexadigitalasset/Atoms_Logo/main/ATOMS_LOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7v4nVURDQDFVTr2sWMBpXzkPuXQR49uuBytMFdcZyks3",
            "symbol": "SPRK",
            "name": "Spark Coin",
            "decimals": 0,
            "logoURI": "https://github.com/prichards2921/Spark/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8jojCnqTnauGXkgjUzB8GhZd22GSBAjGwkh21SNCVMYx",
            "symbol": "NOTOKAY",
            "name": "Not Okay",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/qudo-code/assets/master/solana/tokens/NOTOKAY/NOTOKAY.jpg"
        },
        {
            "chainId": 101,
            "address": "AXneQDz2f8QcVuPmxNNmme1EZZvKkPxnfP4maB5KHPFp",
            "symbol": "CozyC",
            "name": "CozyCub",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/LVi8Qmi.jpeg",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "4ntVBcshvLiUFD1uhXC3pUsqrPzFZJpg1NgfkmAfcb8s",
            "symbol": "SEAN",
            "name": "Sean",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dickmasterson/seanies/master/sean-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E16AdwkqMrmWpVamKL2U8zwJLTjpA8dMsoedPqQNXAoo",
            "symbol": "SMOOCH",
            "name": "Smooches",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dickmasterson/seanies/master/smooch-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7ttRirA9PD94MDR2KYfhA7DW8uXe4x9oVE4i7t9KcAab",
            "symbol": "ZALI",
            "name": "Zalina",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ttRirA9PD94MDR2KYfhA7DW8uXe4x9oVE4i7t9KcAab/logo.png",
            "tags": [
                "governance-token"
            ],
            "extensions": {
                "description": "Governance token for zalina.finance",
                "twitter": "https://twitter.com/ZalinaFinance",
                "website": "https://zalina.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "Ef38uckDt5igFxn9kc2tAQQKVmvVat9PMxjybh9ZvvuL",
            "symbol": "PR",
            "name": "Passroom WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ef38uckDt5igFxn9kc2tAQQKVmvVat9PMxjybh9ZvvuL/logo.png",
            "tags": [
                "WL-token"
            ]
        },
        {
            "chainId": 101,
            "address": "87vA6oDxoTKbh7BLYsqNTrfVw1Udiu5zFy2Tw87gjytY",
            "symbol": "HPWL",
            "name": "Honest Poker Founder's Pass WL",
            "decimals": 0,
            "logoURI": "https://github.com/Honest-Labs/token-images/blob/main/assets/honest-poker-founders-wl.png",
            "tags": [
                "honest-poker"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HonestPoker_",
                "website": "https://honest.poker/"
            }
        },
        {
            "chainId": 101,
            "address": "AqtMo5WyQmi3dQsuuQfgxKM3Gah5QKgZBdTnifSzehJq",
            "symbol": "VDAO",
            "name": "Virgin DAO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AqtMo5WyQmi3dQsuuQfgxKM3Gah5QKgZBdTnifSzehJq/logo.png",
            "extensions": {
                "discord": "https://discord.gg/https://discord.gg/NpZhjmTxY4",
                "twitter": "https://twitter.com/virgindao_sol"
            }
        },
        {
            "chainId": 101,
            "address": "B8WJxSDHCHM43WcfrC8HzBvg4cYRVA2EQiERxBtPQ99T",
            "symbol": "OR5",
            "name": "Algorithm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Uplordi/crypto/main/logo.png.png",
            "tags": [
                "social-token",
                "community-token",
                "yoroshii"
            ]
        },
        {
            "chainId": 101,
            "address": "7ViSurf5Ve2a8qDWFYsfU8GFmRttQvS5paJ8L94QZgo7",
            "symbol": "BBSAMO",
            "name": "BabySamoio",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ViSurf5Ve2a8qDWFYsfU8GFmRttQvS5paJ8L94QZgo7/bbsamo.png",
            "tags": [
                "p2e"
            ],
            "extensions": {
                "telegram": "https://t.me/babysamoio",
                "website": "https://babysamo.io"
            }
        },
        {
            "chainId": 101,
            "address": "AnUrP8xSfYNke1KpkryMpMB67bbde8XrXb6XaFctNyB6",
            "symbol": "GP",
            "name": "GeorgePelal Coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/georgepelal/ok/main/mylogo.png"
        },
        {
            "chainId": 101,
            "address": "2G8y27mY1LWKaAAMDdFsGMpz9wQ1TgpkgChUGeVXu2wk",
            "symbol": "MNL",
            "name": "MONELA",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/MoneyLandVerse/pr/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C5az2gHKae",
                "linkedin": "https://www.linkedin.com/company/moneyland-metaverse",
                "medium": "https://medium.com/@MonelaVerse",
                "reddit": "https://www.reddit.com/r/Monelaverse",
                "telegram": "https://t.me/moneyland_verse",
                "twitter": "https://twitter.com/monelaverse"
            }
        },
        {
            "chainId": 101,
            "address": "3DFUNAzqFZTKHHHovSV3iJGvudnAPSoKoXe2XH6SBF6i",
            "symbol": "3DFUN",
            "name": "3D FunGuyz Discounted WL",
            "decimals": 0,
            "logoURI": "https://bafkreihebzouugjwf32gryfh7hqraxc5kue4rbsei3w57pks4phmpdfudq.ipfs.nftstorage.link/?ext=png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "CONGRATS! You have received a discounted WL token for the 3D FunGuyz Mint for having 2 or more FunGuyz in your wallet! Mint Price: 3 SOL, Wen Mint: May 26th 2022, Total Supply: 3000",
                "discord": "https://discord.gg/dEKxrxt8ac",
                "twitter": "https://twitter.com/FunGuyzNFT",
                "website": "https://funguyz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9XoLtLc3vGFocje9Jojm6nDkjz82mZQYbBPLDYvFafJL",
            "symbol": "AZDWL",
            "name": "Azuria Droids Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AzuriaDev/token-images/bacedbbe6f30a1781aa498ae7db070486acf6bba/azuria-droids-whitelist-token.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AzuriaMetaverse",
                "website": "https://www.azuria.world/"
            }
        },
        {
            "chainId": 101,
            "address": "BkCwEZ5Zbv85dAT9rD6mu19i26RY72gBWETr1bb8Wssv",
            "symbol": "SWDTKN",
            "name": "Sword Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/HitBox2022/crypto/main/logo.png",
            "tags": [
                "utility-token",
                "game-token",
                "game-currency",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "The one token bringing the traditional and blockchain gaming world together",
                "website": "http://swordsofblood.com"
            }
        },
        {
            "chainId": 101,
            "address": "boooCKXQn9YTK2aqN5pWftQeb9TH7cj7iUKuVCShWQx",
            "symbol": "BOO",
            "name": "Boo Network",
            "decimals": 9,
            "logoURI": "https://arweave.net/Reo2_w4k9PGdtYX3p8BllNZpSbbIJ-zype0qdbQgsoE",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GhostKidDAO",
                "website": "https://tinyurl.com/ghostkiddao"
            }
        },
        {
            "chainId": 101,
            "address": "77Rg8gKfGeozwDP1obvHU53fCpP5MzUs6pH4p4jj2kQ2",
            "symbol": "PRTO2",
            "name": "Proteau",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/GuillaumePB/proteaucrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Demon1dfcWS8nfQbwwpgXTD5cQsFChjGEER7VHuJndTD",
            "symbol": "LMT",
            "name": "Legion Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SolStonez/Legion-Key-Logo/main/legion_mint_token.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Each Legion Mint Token is good for one free Legion of Sol mint. My name is legion: for we are many.",
                "discord": "https://discord.gg/legionofsol",
                "twitter": "https://twitter.com/legion_of_sol",
                "website": "https://legionofsol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7YTpvsi55fzuwj3E4kpzFCWT71SuUf2YcMdVgGFE9fwr",
            "symbol": "FTS",
            "name": "Fatsas Coin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/georgepelal/ok/main/fatsas1.png"
        },
        {
            "chainId": 101,
            "address": "BLyV6szCZ7Ypye8AHXyHDmjC4uC73sGvEJoMwoVQw3Te",
            "symbol": "TFOXES",
            "name": "Transdimensional Fox Federation Floor Index",
            "decimals": 2,
            "logoURI": "https://cloudflare-ipfs.com/ipfs/bafkreiaw7wgcu3o7xwmm3mbmm65pco5nti7etquvp7xlo6atgbksirb2va",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "tfoxes",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/BLyV6szCZ7Ypye8AHXyHDmjC4uC73sGvEJoMwoVQw3Te"
            }
        },
        {
            "chainId": 101,
            "address": "EK6j5Shv99xttoT3F2DfG8uQMoX6NoAZgTuYwCvrHzqo",
            "symbol": "LIONESS",
            "name": "Jungle Cats Lioness Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachments/5befbef5bb59eb122426dac8db5a0b2f/2327b76a/QueenLion.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "jungle-cats-lioness",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/EK6j5Shv99xttoT3F2DfG8uQMoX6NoAZgTuYwCvrHzqo"
            }
        },
        {
            "chainId": 101,
            "address": "uL2qhMckUAroJPt2MLHwEeppJNYE3wBAGFMCs3anwXn",
            "symbol": "DRIPP",
            "name": "Drippies Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/drippies_pfp_1648415706808.gif",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "drippies",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/uL2qhMckUAroJPt2MLHwEeppJNYE3wBAGFMCs3anwXn"
            }
        },
        {
            "chainId": 101,
            "address": "9j7pLeELCPTnXYXcHSiGSuYr1UE7cTaAd16kiH2AiNs5",
            "symbol": "DRAKOSE",
            "name": "Drakos Unchained Expansion Floor Index",
            "decimals": 2,
            "logoURI": "https://dl.airtable.com/.attachments/28e237c4f048b44953ce81d43ea0c1cb/1f0a0154/ME-PFP.gif",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "drakos-expansion",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/9j7pLeELCPTnXYXcHSiGSuYr1UE7cTaAd16kiH2AiNs5"
            }
        },
        {
            "chainId": 101,
            "address": "5iUW2aLFsSD5oRkmxpRfKFffvKvTeFmEruABLYyY2MRX",
            "symbol": "HELIX",
            "name": "Metahelix Floor Index",
            "decimals": 2,
            "logoURI": "https://creator-hub-prod.s3.us-east-2.amazonaws.com/metahelix_pfp_1647817083595.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "metahelix",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/5iUW2aLFsSD5oRkmxpRfKFffvKvTeFmEruABLYyY2MRX"
            }
        },
        {
            "chainId": 101,
            "address": "GoatLVmpgEcSLyNMpzxe5UUTLpFd5eVvkSEHY7AsZfGc",
            "symbol": "TheGoat",
            "name": "Great Goats Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoatLVmpgEcSLyNMpzxe5UUTLpFd5eVvkSEHY7AsZfGc/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GreatGoatsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "64r432xziX6XHx8QMuHa4Ck7ayzVg2fe7cpPvFD3MPYX",
            "symbol": "MSCN",
            "name": "Morris Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thomasokc/crypto/main/morrisCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HCs795QpfXP6nVkPvzfgaFDoTyGgxG5vdBdS9hr6GDYL",
            "symbol": "STNY",
            "name": "STONEY TOKEN",
            "decimals": 5,
            "logoURI": "https://pasteboard.co/a7bWqj2fB5V4.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9dnPpnfUemGweue1qmQiaHdygnYMNREuY3DLYUHQu9DE",
            "symbol": "QUANT",
            "name": "Quantum Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/Mpume-algorith/crypto-token/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FnNQQjWpeFRMxS75GV9NTPzsBntmQRdKYM21Sr4B5V1t",
            "symbol": "ZADN",
            "name": "Zaiden Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dakender/Zaiden-Coin/main/logo-zaiden.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DRt2JmUCYDoVYfbAnAitCNetB1PUgbwpsZfhfhEJLyFs",
            "symbol": "FBTC",
            "name": "Flash Ceypto Carency",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/Crypto-carency/crypto/main/crypto-logo.png"
        },
        {
            "chainId": 101,
            "address": "BjM9tHajUkjeFsGuymBUer4ZKLA4t37ehERW3dxRGbCJ",
            "symbol": "AL3XA",
            "name": "Alexa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jpw5/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "6DMw6AM1jSNAspTDo2FFr9GU5hTPhdFWnvNDtaxdhZwt",
            "symbol": "JD3Coin",
            "name": "JD3 Coin",
            "decimals": 2,
            "logoURI": "https://staging.genwin.app/web/media/4e3ecedb-1fb0-4833-aaba-4b0c6436ae2f.png"
        },
        {
            "chainId": 101,
            "address": "4gN1u9LNBnvFERpDU3SrMusMJ1gKLbE9XB6EYraQkWTf",
            "symbol": "DGWL",
            "name": "Degenz Game Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4gN1u9LNBnvFERpDU3SrMusMJ1gKLbE9XB6EYraQkWTf/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/degenzgame",
                "twitter": "https://twitter.com/degenzgame",
                "website": "https://degenz.game"
            }
        },
        {
            "chainId": 101,
            "address": "DoLDqhWPLrAH4mzsGo54AshM6k94T23GnaFmGVdLTR4r",
            "symbol": "mKOGWL",
            "name": "mK OG Chef Whitelist",
            "decimals": 0,
            "logoURI": "https://metakitchendev.onrender.com/static/media/MK-coin.4572f8b4b0d0901217de.png",
            "tags": [
                "whitelist-token",
                "GameFi",
                "Gaming"
            ],
            "extensions": {
                "description": "Whitelist token for metaKitchen OG Chefs",
                "discord": "https://discord.gg/metakitchen",
                "instagram": "https://www.instagram.com/metakitchennft/",
                "twitter": "https://twitter.com/meta_Kitchen",
                "website": "https://metakitchen.io/",
                "whitepaper": "https://metakitchen.gitbook.io/metakitchen-wp/wb1pN4vYLKsa8w4IX5qL/"
            }
        },
        {
            "chainId": 101,
            "address": "D5FeXZ9aernffbG6UebUkfX612AyohHeoXFZABLVmMoX",
            "symbol": "TAT",
            "name": "Trippin Ape Tribe (Whitelist Token)",
            "decimals": 0,
            "logoURI": "https://pbs.twimg.com/profile_images/1516190526592139266/tTBE_jP5_400x400.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TrippinApeNFT"
            }
        },
        {
            "chainId": 101,
            "address": "Dc3E5zi7V9MyTrrmD72NoX4pRmDh8xABsFmCVdCme82L",
            "symbol": "RCDWL",
            "name": "RCD WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dc3E5zi7V9MyTrrmD72NoX4pRmDh8xABsFmCVdCme82L/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/qmS6PWM75w",
                "twitter": "https://twitter.com/CryptureDefense",
                "website": "https://www.randomcrypturedefense.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5EcRg4CXDXhNzecAH5JeFXuyRMWoXAu2S4YUwpHfKru2",
            "symbol": "INCOIN",
            "name": "Incog-coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hoffers83/crypto/main/Logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5qhyR2rYEZfGeJBKUdjom8pJNkqCBsYaZAtqsKAQhjoS",
            "symbol": "MFS",
            "name": "Metadopt Free Sloth",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5qhyR2rYEZfGeJBKUdjom8pJNkqCBsYaZAtqsKAQhjoS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "This token gives you a free sloth mint on Metadopts Avatar Builder.",
                "discord": "https://discord.gg/GKvxz6knjs",
                "twitter": "https://twitter.com/metadopt"
            }
        },
        {
            "chainId": 101,
            "address": "dogNpVvUavBx65BXETRFhwByMw65cAzFxTjbc7uD8br",
            "symbol": "CDFM",
            "name": "Corrupted Dogs OG Free Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/dogNpVvUavBx65BXETRFhwByMw65cAzFxTjbc7uD8br/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CorruptedDogs"
            }
        },
        {
            "chainId": 101,
            "address": "dogMuvvxb6LDEhfUFxYxHRKnR4NUtzz8BPHcit8mfpm",
            "symbol": "CDWL",
            "name": "Corrupted Dogs WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/dogMuvvxb6LDEhfUFxYxHRKnR4NUtzz8BPHcit8mfpm/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CorruptedDogs"
            }
        },
        {
            "chainId": 101,
            "address": "479u78dczfjx9J5u32yGt1nTquM5f9zAFLeuy2eU7YnG",
            "symbol": "LRNST",
            "name": "Learner Shelter",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Rishikesh13882/png-token/main/ss.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ZRPxYn8rRs",
                "website": "https://learnershelter.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6s17gerWtun1TkDiWakEGWMnxTBiyYABEU19BpuB65FR",
            "symbol": "KUK",
            "name": "KUK",
            "decimals": 9,
            "logoURI": "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/6s17gerWtun1TkDiWakEGWMnxTBiyYABEU19BpuB65FR/logo.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6cSq646iR9kfU2BJuFdFjKbvD5NjygZbhv71WNAzLiTk",
            "symbol": "BUGA",
            "name": "BUGA Coin",
            "decimals": 8,
            "logoURI": "https://cdn.shopify.com/s/files/1/1061/1924/products/Skull_Emoji_Icon_8cee31f2-35dd-42e7-b757-3cb8cfe72437_1024x1024.png?v=1571606093",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "okayaeHG5rLEy3tQJsVj2ppQp3qhrXZfRHf6uG4siUF",
            "symbol": "OBWL",
            "name": "Okaybulls WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/okayaeHG5rLEy3tQJsVj2ppQp3qhrXZfRHf6uG4siUF/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/okaybulls"
            }
        },
        {
            "chainId": 101,
            "address": "8RGdXex7StmeGsxmTcZDt37qhmtiRpDmHJDyCA97nhaY",
            "symbol": "FTT",
            "name": "Felix Thoen Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/FelixThoen/crypto/main/newlixn.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CKXFoHkE5yYaCXapwNQqRogfpKXmB3DgxP6Pbuw7AZ13",
            "symbol": "JD3C",
            "name": "JD3 Dev Coin",
            "decimals": 2,
            "logoURI": "https://staging.genwin.app/web/media/4e3ecedb-1fb0-4833-aaba-4b0c6436ae2f.png"
        },
        {
            "chainId": 101,
            "address": "2qmM8tCYvYeXz9pztHLoNmshqmAf8ZQ5btUxStBekxBj",
            "symbol": "$INDOI",
            "name": "INDOI token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/ahmaaz10/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7NX2zna24nt9ZoKhYiqs6R63yZeeuviB8GtBcHr539zQ",
            "symbol": "SHEN",
            "name": "Arab Shaheen",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/atadar/ArabShaheen/main/assets/mainnet/7NX2zna24nt9ZoKhYiqs6R63yZeeuviB8GtBcHr539zQ/shaheen.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2YCQcQgy9nNhgukjAur1jCvMXgSTQ5FVDc3ae3BcspXS",
            "symbol": "VRS",
            "name": "VeraSaw Plant Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2YCQcQgy9nNhgukjAur1jCvMXgSTQ5FVDc3ae3BcspXS/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/YBYgAtX6",
                "telegram": "https://t.me/VerasawOfficialGroup",
                "twitter": "https://twitter.com/VeraSaw",
                "youtube": "https://www.youtube.com/channel/UC_zgKg0gt2fu_oar0JSKY0Q"
            }
        },
        {
            "chainId": 101,
            "address": "CYFWeAYftmLUDLoxCfP1bvKn5NFD6G6g7vWKWm28ELAh",
            "symbol": "SCHCO",
            "name": "SchrodinGersCoin",
            "decimals": 0,
            "logoURI": "https://github.com/SchrodingersCoinS/crypto/blob/main/SchrodingersCoin.png",
            "tags": [
                "Memes"
            ]
        },
        {
            "chainId": 101,
            "address": "3WKbcYBMKx9vX7419Nft7Anh2X5LHwsiSpm3PwnAAWU8",
            "symbol": "SolPen",
            "name": "SolanaPenguin",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/NWW0MOs.png",
            "tags": [
                "nft"
            ]
        },
        {
            "chainId": 101,
            "address": "aro9zmkh9pxkKN9F1FZntQDQCyfbyqv9QdqAwWc7nfJ",
            "symbol": "ARO",
            "name": "ArrorDefi",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aro9zmkh9pxkKN9F1FZntQDQCyfbyqv9QdqAwWc7nfJ/logo.png",
            "tags": [
                "utility-token",
                "ecosystem-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ZH8GYu77fA",
                "twitter": "https://twitter.com/arrordefi",
                "website": "https://arrordefi.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EAUQ1HsgGX2Xgjc5kYYJ5AxxQnNmhbG4xrWUZp49NN1N",
            "symbol": "MTWL",
            "name": "Miner Tools",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAUQ1HsgGX2Xgjc5kYYJ5AxxQnNmhbG4xrWUZp49NN1N/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "B63QS4EuLN1b4UfHF1mqymvQsqJdHzmqA45Uy455upeS",
            "symbol": "WLTVP",
            "name": "TVP WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/georgiod9/tvp/master/logo.png"
        },
        {
            "chainId": 101,
            "address": "GGfRamVXbvqW389d4Za2pR1Cw7hwoxLd8usrnosLG5kb",
            "symbol": "MTF",
            "name": "MTFISH Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/TamasGMI/MonTheFishery/main/Mon%20The%20Fishery%20(2).png",
            "tags": [
                "Utility-Token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/monthefishery",
                "twitter": "https://twitter.com/monthefishery"
            }
        },
        {
            "chainId": 103,
            "address": "D4NhKJGBgNTQwmtgVco2b9wYaXcgeb488QicPfbvLEhz",
            "symbol": "BDG",
            "name": "La Bodegueta",
            "decimals": 0,
            "logoURI": "https://ipfs.io/ipfs/QmUu6HGQXMB2f69ch9PTjeh3nYbZgRTXgMztUJcn9XAHp3",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.labodeguetademollet.com"
            }
        },
        {
            "chainId": 101,
            "address": "5P4XspXXzCxyKH7uB4ygWByv1fJxrPGomqTEsURbZvvb",
            "symbol": "ZEIT",
            "name": "ZeitCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ZeitsCoin/cripto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4i3285PyW8ansfoHQWH3AadVV6UP39mdxhHS8xBdMtph",
            "symbol": "HPWL",
            "name": "Honest Poker WL (Founder's Pass)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4i3285PyW8ansfoHQWH3AadVV6UP39mdxhHS8xBdMtph/logo.png",
            "tags": [
                "honest-poker"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HonestPoker_",
                "website": "https://honest.poker/"
            }
        },
        {
            "chainId": 101,
            "address": "FEXbpVRgJ6GZcpwutRSHdktXso7J5XCCgNDcvPiX2Nrj",
            "symbol": "MLO",
            "name": "Mottilio",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FEXbpVRgJ6GZcpwutRSHdktXso7J5XCCgNDcvPiX2Nrj/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/mottimobile",
                "instagram": "https://www.instagram.com/mottimobile",
                "twitter": "https://twitter.com/mottimobile",
                "website": "https://motti.com"
            }
        },
        {
            "chainId": 101,
            "address": "ToPBXCrquTpnEz24bWeYkuXUDmnXdeGGSgTgPeX1sPF",
            "symbol": "ZLOWL",
            "name": "Planet Zloba - WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/6827050a10738590ef327b4c761519fe01913f2d/assets/mainnet/ToPBXCrquTpnEz24bWeYkuXUDmnXdeGGSgTgPeX1sPF/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PlanetZloba",
                "website": "https://www.planetzloba.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CYbLZDG7TexKi2axdh4gQGLV3FnXvBgfJgLtixhKVytZ",
            "symbol": "FUEL",
            "name": "Biker",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CYbLZDG7TexKi2axdh4gQGLV3FnXvBgfJgLtixhKVytZ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/skullbots",
                "twitter": "https://twitter.com/SKULLBOTS",
                "website": "https://www.skullbots.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3YZhqNRn8Vnaf5K3X7j1w4s7djwHQVRmALF56ZfCkTBa",
            "symbol": "SIXGE",
            "name": "Sol Information Exchange",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rasiel506/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Tu4ai99TGWwEAa9m284UDbPJELJuZQUZoQ18Jxf86Wu",
            "symbol": "HPWL",
            "name": "Honest Poker WL (Founders Pass)",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Tu4ai99TGWwEAa9m284UDbPJELJuZQUZoQ18Jxf86Wu/logo.png",
            "tags": [
                "honest-poker"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HonestPoker_",
                "website": "https://honest.poker/"
            }
        },
        {
            "chainId": 101,
            "address": "DCpgVPDSfW2AzuLep2DYtjpFTD25u1R59oej6zzraLMj",
            "symbol": "PLRS",
            "name": "Polaris Coin",
            "decimals": 0,
            "logoURI": "https://uploads-ssl.webflow.com/6235ef598201565a09d70196/628da08b096a51491a3842a9_plrs.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7UvcxpsYpDov3Q9GJU3HiHUVnVCEnY96XPAkD1LTDcLe",
            "symbol": "ALBY",
            "name": "Alby Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7UvcxpsYpDov3Q9GJU3HiHUVnVCEnY96XPAkD1LTDcLe/alby.png",
            "tags": [
                "personal-token",
                "alby",
                "in-alby-we-trust",
                "invest-in-alby"
            ],
            "extensions": {
                "description": "Congratulations! You are one of the holders of ALBY COIN! If you own this coin, Alby thanks you for being an overall awesome person and having chill vibes and stuff! You can continue to earn Alby coin by investing in Alby's overall quality of life, make sure to mention your wallet address."
            }
        },
        {
            "chainId": 101,
            "address": "4an6Mhiij3EXpkkti1rcerHdcucg7suEvycgqAC73LkU",
            "symbol": "POTT",
            "name": "Pot Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tranmduke/crypto/5d7e4529068479dd17ffbe78964b70dd152c0902/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FBHFo6uQheu5WNXhryDUpkDwyCQP6e1iTF56veLVJu3a",
            "symbol": "SFWLT",
            "name": "Stoned Farms WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FBHFo6uQheu5WNXhryDUpkDwyCQP6e1iTF56veLVJu3a/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/stonedfarms",
                "website": "https://stonedfarms.io/"
            }
        },
        {
            "chainId": 101,
            "address": "F3bHfhwKKvsDjy76BzBio9fHrg7rtPNg1xifpgevdvLn",
            "symbol": "PRTTTT",
            "name": "PRT TOOLS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F3bHfhwKKvsDjy76BzBio9fHrg7rtPNg1xifpgevdvLn/logo.png"
        },
        {
            "chainId": 101,
            "address": "CB8wh1bXWugaqaqcA2KxTv1nf5zyU5oTsT6XvT3A6uyh",
            "symbol": "NWL",
            "name": "NAKAMA WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/nakamawl.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "P1utjqwB7hEcgDe3u8oHeRCtfyWQY2XEhhCyPFYVhu7",
            "symbol": "PLMTX",
            "name": "Plutonium X",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/P1utjqwB7hEcgDe3u8oHeRCtfyWQY2XEhhCyPFYVhu7/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/ttcc",
                "twitter": "https://twitter.com/TimeTravelingCC"
            }
        },
        {
            "chainId": 101,
            "address": "Bone6zEsectLFPtSGHDya6mRvrKVZ7kyeUUc1uVFu7pB",
            "symbol": "BONE",
            "name": "BONE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bone6zEsectLFPtSGHDya6mRvrKVZ7kyeUUc1uVFu7pB/logo.png"
        },
        {
            "chainId": 101,
            "address": "BEoAR8HvD8kFWjLyxcRbLcNyVctPjss7iGZwzeV3PHAg",
            "symbol": "NYBC",
            "name": "Nyabi Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/B0on/crypton/main/nyabc.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "666YXKdQzN49gzQetYffQUhy4hLxEB31PZkRew4VrXAj",
            "symbol": "SHOIT",
            "name": "Shoit Coin",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/106226389/170191878-49041947-b736-4224-992c-a2602fdc3657.JPG",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/shoit_coin"
            }
        },
        {
            "chainId": 101,
            "address": "RAcgAKBWGWqeZgQawUhLXbKaPTYAVYNFYcKyigGJhxX",
            "symbol": "RAC",
            "name": "Renegades Access Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RAcgAKBWGWqeZgQawUhLXbKaPTYAVYNFYcKyigGJhxX/logo.svg"
        },
        {
            "chainId": 101,
            "address": "ATCvehjZsCC8JBdiKXj41xdpVKrtmBf2x9qwFHrrL2Nd",
            "symbol": "ATC",
            "name": "Altered Token Coins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATCvehjZsCC8JBdiKXj41xdpVKrtmBf2x9qwFHrrL2Nd/logo.svg"
        },
        {
            "chainId": 101,
            "address": "RUWV9EDJkyA3ua3CgNsZVdRJUPp9KrpmeM8i1AfK6We",
            "symbol": "AlphaClub",
            "name": "AlphaClub",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RUWV9EDJkyA3ua3CgNsZVdRJUPp9KrpmeM8i1AfK6We/logo.png",
            "tags": [
                "social-token",
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/AlphaClubSOL",
                "website": "https://alphapass.net"
            }
        },
        {
            "chainId": 101,
            "address": "rJ6DSDeAftuwTrEjmKpuUm9cmG5dgb4GiDD2oPPWTME",
            "symbol": "Kiks",
            "name": "Kikser shit coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Kikser1214/test/main/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Kikser11"
            }
        },
        {
            "chainId": 101,
            "address": "Bdidi76zPEDAfJTUX9xSfvwGEWy6PGwuazgKW26BEcv",
            "symbol": "MODO",
            "name": "Modo Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/6eorgeLucas/ModoCoins/main/MODOCOIN_Logo_circle.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/networkchuck",
                "website": "https://networkchuck.com",
                "youtube": "https://www.youtube.com/channel/UC9x0AN7BWHpCDHSm9NiJFJQ"
            }
        },
        {
            "chainId": 101,
            "address": "HArMoNyavXSMMD96pTTDoTtpw1y7ERAXNrHa3WVn6cDM",
            "symbol": "HRMY",
            "name": "Harmonya",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HArMoNyavXSMMD96pTTDoTtpw1y7ERAXNrHa3WVn6cDM/logo.png",
            "tags": [
                "game-token",
                "game-currency",
                "game",
                "utility-token",
                "nft",
                "gaming",
                "metaverse"
            ],
            "extensions": {
                "animationUrl": "https://harmonya.s3-eu-west-3.amazonaws.com/logo.png",
                "description": "Harmonya is a metaverse atoll, you can own your villa in a floating island, earn crypto, NFT, play p2e games and more",
                "discord": "https://discord.gg/STWZw5wc",
                "facebook": "https://www.facebook.com/Harmonya-102230569096215",
                "github": "https://github.com/HarmonyaDAO",
                "instagram": "https://www.instagram.com/harmonyameta",
                "linkedin": "https://www.linkedin.com/company/harmonya-metaverse/",
                "medium": "https://bit.ly/HRYmedium",
                "reddit": "https://www.reddit.com/r/Harmonyameta/",
                "telegram": "https://t.me/harmonyameta",
                "telegramAnnouncements": "https://t.me/harmonyameta",
                "twitter": "https://twitter.com/harmonyameta",
                "website": "https://linktr.ee/harmonyameta",
                "whitepaper": "https://harmonya-metaverse.gitbook.io/harmonya-white-paper/"
            }
        },
        {
            "chainId": 103,
            "address": "4SEY65PouYay9yzqex62ygAGKXrui4NbNoG8yqqzz8PQ",
            "symbol": "LZV",
            "name": "LazyVaccine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4SEY65PouYay9yzqex62ygAGKXrui4NbNoG8yqqzz8PQ/logo.png",
            "tags": [
                "social-token",
                "learn-and-earn"
            ],
            "extensions": {
                "twitter": "https://twitter.com/lazyvaccine",
                "website": "https://lazyvaccine.com"
            }
        },
        {
            "chainId": 101,
            "address": "8Ytv2K8w2yPG6YwwTSJ7Ay5uJJk1p8Lzw9ddtJq9Fz3W",
            "symbol": "SLT",
            "name": "Solotto Club",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Ytv2K8w2yPG6YwwTSJ7Ay5uJJk1p8Lzw9ddtJq9Fz3W/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2Vs8Xvhc7kMBBbniTCGhUYwAu74GSYVDAqyzxh6zro8x",
            "symbol": "HANGAR",
            "name": "Hangar Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Shoryn/hangarcoin/main/HangarPoundcoin112.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8SKcTZ2KsVpPJVU3QSHQD5uRQpw5WS4VsVfEVwhKMLCF",
            "symbol": "DARKM",
            "name": "Dark Matter",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8SKcTZ2KsVpPJVU3QSHQD5uRQpw5WS4VsVfEVwhKMLCF/darkmatter.png"
        },
        {
            "chainId": 103,
            "address": "2BBTUkry8pAtQCQUoCNgB2uWWZNhosw2urHKL9QkTTUA",
            "symbol": "YUN",
            "name": "Yun Token for devnet",
            "decimals": 9,
            "logoURI": "https://i.imgur.com/PD1IjE9.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Yun Token on devnet"
            }
        },
        {
            "chainId": 101,
            "address": "9Nxsu2QQZZYSkpy9c9YFiLWG3QZwDXXFdpwCcXR2QNks",
            "symbol": "DSCTK",
            "name": "Disctok",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/moradev/disctok/main/icon.jpg",
            "tags": [
                "game-token",
                "game-currency",
                "game",
                "utility-token",
                "gaming",
                "metaverse"
            ]
        },
        {
            "chainId": 101,
            "address": "GsZcjMnaqw6VVpTtQa4ggkjT9w3wNiupUA4AYT2WKTXC",
            "symbol": "DTN",
            "name": "Detown",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GsZcjMnaqw6VVpTtQa4ggkjT9w3wNiupUA4AYT2WKTXC/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/detown_io",
                "website": "https://www.detown.io"
            }
        },
        {
            "chainId": 101,
            "address": "KoinFMYtye41ax7ehQiTFp6PCRXviqdBn1VuP3NYLsn",
            "symbol": "Koinz",
            "name": "Cultured Kongz Koinz",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KoinFMYtye41ax7ehQiTFp6PCRXviqdBn1VuP3NYLsn/icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/gSHWqephYV",
                "twitter": "https://twitter.com/CulturedKongz"
            }
        },
        {
            "chainId": 101,
            "address": "4GuCMVuAky4ctt7Syb4ZneQtabA4pQ4yqVkeVoGuQHf2",
            "symbol": "$INTL",
            "name": "INTL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CMHayden/crypto/master/intl-min.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Utility Token for Meta Minters"
            }
        },
        {
            "chainId": 101,
            "address": "7V5AaqHTwiySegaAmNPLekQfTAoK4WvEVgfi2R8V44tB",
            "symbol": "rFRAKT",
            "name": "Random FRAKTs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7V5AaqHTwiySegaAmNPLekQfTAoK4WvEVgfi2R8V44tB/rFRAKT.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/CUvaGx32Z1p9Wia2Xheod37ii62byAxhNv8r2UJX729Y/buy"
            }
        },
        {
            "chainId": 101,
            "address": "EmvtEzATa3n766yxojGZJmpSzkTxsCdDSX2zgRMZEoaQ",
            "symbol": "rPWNG",
            "name": "Random Pawnshop Gnomies",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EmvtEzATa3n766yxojGZJmpSzkTxsCdDSX2zgRMZEoaQ/rPWNG.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/FvKPzBfDx6AgBhkwweFnth2g9Mfd21vT9Lr7yoxUQsp8/buy"
            }
        },
        {
            "chainId": 101,
            "address": "6RBwVuqgBsYsWXmEhV72MSBZMawuy9XxDpm9uzffxmw1",
            "symbol": "rHRHC",
            "name": "Random High Roller Hippo Clique",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6RBwVuqgBsYsWXmEhV72MSBZMawuy9XxDpm9uzffxmw1/rHRHC.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/CA5qBCJS5wwdvMmt2ANLUxUecmNjf7Py9s7N1UEJ1cxy/buy"
            }
        },
        {
            "chainId": 101,
            "address": "7BzULwTHqMCc9Qo7qVFn27UxHgb9SPev3EsbbmQ4YNzw",
            "symbol": "rLLAMA",
            "name": "Random Sollamas",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7BzULwTHqMCc9Qo7qVFn27UxHgb9SPev3EsbbmQ4YNzw/rSLM.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/AaYBdEnjqYoay3ozs2tY1tm9kFXxjUrAZeNZngnqwmV1/buy"
            }
        },
        {
            "chainId": 101,
            "address": "4PhPtyBhmMYBLjiJPr3wef2syoMSJYn5WcNgXxvmG3NZ",
            "symbol": "rPUNK",
            "name": "Random Solpunks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4PJ8yD2hPXVGxnYcmjBoDyDHKAMEAKjGgAfvEqmZUaAw/logo.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/HfEJXp9YNdrrPuqAoaeKzL3pyCvsdnVQxHXpf7KVTsjY/buy"
            }
        },
        {
            "chainId": 101,
            "address": "2qq3zxV9qBenTZLWRhmcSJdPFqdTGDDgc1aVQUCTs9Bu",
            "symbol": "rSPTRL",
            "name": "Random Sol Patrol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qq3zxV9qBenTZLWRhmcSJdPFqdTGDDgc1aVQUCTs9Bu/rSPTRL.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/7Bjj5LSjvC6Sumvx3L3gK25AGVGhFfPyZWkm1xep8kXv/buy"
            }
        },
        {
            "chainId": 101,
            "address": "ugKuq43fnPEcEeH12gCfETbshMRJ8nD2qXmcbyNHaEb",
            "symbol": "rTHUG",
            "name": "Random Thugbirdz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ugKuq43fnPEcEeH12gCfETbshMRJ8nD2qXmcbyNHaEb/rTHUG.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/3da5KmaRp9gHx2wzv8PdZHpxXWvbHMRUxCK9FCLvHGZr/buy"
            }
        },
        {
            "chainId": 101,
            "address": "9m8E1yLHaG1B2TFSNeWahsitQh5yQRnrbyw756HFAcEa",
            "symbol": "rLGND",
            "name": "Random Blockasset Legends",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9m8E1yLHaG1B2TFSNeWahsitQh5yQRnrbyw756HFAcEa/rLGND.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/EHNimBvyzYB8qJvpXzaGudDZ3eNUnezyCMLxigXvTqwU/buy"
            }
        },
        {
            "chainId": 101,
            "address": "8BobtXuP8hD69rZTLZiubSEbmQWpbmaJwyoayzQYyxs3",
            "symbol": "rNBC",
            "name": "Random Bat City Underground",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8BobtXuP8hD69rZTLZiubSEbmQWpbmaJwyoayzQYyxs3/rNBC.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/FuasyZT2HZDsz5ndmFP9XeuQneK7prDwRugenqkPC1TB/buy"
            }
        },
        {
            "chainId": 101,
            "address": "9akvvCgpFc7LkpESHCSacrPPH7SztbvuAXNvJkzZSZWu",
            "symbol": "rTRTL",
            "name": "Random Turtles",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9akvvCgpFc7LkpESHCSacrPPH7SztbvuAXNvJkzZSZWu/rTRTL.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/GaTnrfAh8JsNxpVi8oKCoyeWwXySCju55GJfTaVTrGep/buy"
            }
        },
        {
            "chainId": 101,
            "address": "7uENff26kbM3zP9YhYj4MdSzS5nGoEDSeHs81zQ7Gp2J",
            "symbol": "rATRNT",
            "name": "Random Aiternate",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uENff26kbM3zP9YhYj4MdSzS5nGoEDSeHs81zQ7Gp2J/rATRNT.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/8ezfmbfr5rRNrvM1z4ZqWTjf9mbcpozpgFxgSbmmR2c2/buy"
            }
        },
        {
            "chainId": 101,
            "address": "Vjq9T5xmqRzLXQRyvigzyZzpHCGCsbYAJ7afLVuF8j9",
            "symbol": "rZOOM",
            "name": "Random Zaysan Raptors",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Vjq9T5xmqRzLXQRyvigzyZzpHCGCsbYAJ7afLVuF8j9/rZOOM.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/2pCdQHULNgwesN7dQdRW93GFsLb99GpNC4wT9V1jvUvB/buy"
            }
        },
        {
            "chainId": 101,
            "address": "7Mfsbr8vS2LjWTFspTgfLPWm7s77zvJsevBuW4P9MZ3m",
            "symbol": "rSAMO",
            "name": "Random Samo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Mfsbr8vS2LjWTFspTgfLPWm7s77zvJsevBuW4P9MZ3m/rSAMO.png",
            "tags": [
                "frakt-nft-pool"
            ],
            "extensions": {
                "website": "https://frakt.xyz/pools/6oufJYFSNcWEBV465FDywN2VbYftnXff9KuNnebkc8YD/buy"
            }
        },
        {
            "chainId": 101,
            "address": "6349D3Ads4cVDLjUyjBEjnxC3ehgJX5W536VG7XP5a6S",
            "symbol": "LExLOCOS",
            "name": "Luna Exiled Locos",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6349D3Ads4cVDLjUyjBEjnxC3ehgJX5W536VG7XP5a6S/logo.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LocosNft",
                "website": "https://locosnft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AHT7EvoyMP84XpyaEmt9Zhn9eTiUCL1oYEF8oYRocs6C",
            "symbol": "LFNDFT",
            "name": "LIT FUND FT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/dth5geg.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C5z57xrw52",
                "telegram": "https://t.me/litfund",
                "twitter": "https://twitter.com/litfund_defi",
                "website": "https://lit.fund"
            }
        },
        {
            "chainId": 101,
            "address": "7b75gMTwUS6rfN1CNoZnaAdgYwPejpLJrXBpmFpBqp5Y",
            "symbol": "LFNDFC",
            "name": "LIT FUND FC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/dth5geg.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/C5z57xrw52",
                "telegram": "https://t.me/litfund",
                "twitter": "https://twitter.com/litfund_defi",
                "website": "https://lit.fund"
            }
        },
        {
            "chainId": 101,
            "address": "GyvLD8DxSEVffv22eEWQ6NdJMbtzoYfPhue7XxCXMKqr",
            "symbol": "MEC",
            "name": "UNCHAINED",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GyvLD8DxSEVffv22eEWQ6NdJMbtzoYfPhue7XxCXMKqr/logo.png"
        },
        {
            "chainId": 101,
            "address": "ConFQvWZxf3Qdb7yMWPw2Ja1pHPuvYVpbKKunUJ36qqo",
            "symbol": "SPAP",
            "name": "Sugar Push Alpha Pass",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ConFQvWZxf3Qdb7yMWPw2Ja1pHPuvYVpbKKunUJ36qqo/logo.png",
            "tags": [
                "community-token",
                "nfts"
            ]
        },
        {
            "chainId": 101,
            "address": "BabMZvYFxkGtoMuGTwNNLfoLa9HyDGjAc18Qq9gL7e2e",
            "symbol": "DADO",
            "name": "DadoBucks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BabMZvYFxkGtoMuGTwNNLfoLa9HyDGjAc18Qq9gL7e2e/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JiGoA4fCoYDbdCUVmGEaLytnHT42BXhEmwacqM3RjUf",
            "symbol": "Hounds",
            "name": "Jigoku Hounds Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JiGoA4fCoYDbdCUVmGEaLytnHT42BXhEmwacqM3RjUf/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/JigokuHounds"
            }
        },
        {
            "chainId": 101,
            "address": "9VkCgoLv8aXnNXafJkjPqdKp4NXSQ2Yzhba789gL6oTt",
            "symbol": "STONY",
            "name": "Stony Tokens",
            "decimals": 8,
            "logoURI": "https://pasteboard.co/j2ew86rkNCX0.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Dhtv79Gax1gwvWNQCKFW4oUCsMLcmUbsZ6vdaCFYP2Ko",
            "symbol": "PERTEL",
            "name": "Cets Pertel",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/5nmPVbN/Untitled-design-4.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://discord.gg/ANg8B3FW55"
            }
        },
        {
            "chainId": 101,
            "address": "DMZ2tx9KLEFNmzaarujHjEBjEHV5mbfQvW2Jo5qrzGxy",
            "symbol": "BUGA_",
            "name": "BUGA_ Coin",
            "decimals": 8,
            "logoURI": "https://cdn.shopify.com/s/files/1/1061/1924/products/Skull_Emoji_Icon_8cee31f2-35dd-42e7-b757-3cb8cfe72437_1024x1024.png?v=1571606093",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "hhCTVAJHNcDZAoQUXcDrsewomL6L2oDVttyFnP6631t",
            "symbol": "GT",
            "name": "GreenTech",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/chaddieboy1/crypto/main/Capture.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D4JJfAe5ZVTkySAFTzzc2Tmn5xFzEghBTCWXC6ELrUgN",
            "symbol": "FRESH",
            "name": "Fresh Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FreshCats/FRESH/main/Token.png",
            "tags": [
                "Utility-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "RoTksthszZDGGZq4uZZZxVmV7xsaz4X8kBESMyeLZkE",
            "symbol": "RoT",
            "name": "Rotten Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RoTksthszZDGGZq4uZZZxVmV7xsaz4X8kBESMyeLZkE/RoT_Coin.png",
            "tags": [
                "rottenville-project",
                "community-coin",
                "utility-token",
                "rotten",
                "kyo"
            ],
            "extensions": {
                "discord": "https://discord.gg/dHDwCRwH6b",
                "twitter": "https://twitter.com/rotten_ville",
                "website": "https://rottenville.io"
            }
        },
        {
            "chainId": 101,
            "address": "RAZRymwM9F2nP9ZAsojyKRoneydftoqztsx3MnRS9MC",
            "symbol": "RAZR",
            "name": "Razr Finance",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RAZRymwM9F2nP9ZAsojyKRoneydftoqztsx3MnRS9MC/RAZR.png",
            "tags": [
                "utility-token",
                "defi-token",
                "staking-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/HXa3qAYe",
                "github": "https://github.com/solrazr-app",
                "medium": "https://medium.com/@SolRazr_App",
                "telegram": "https://t.me/solrazr_app",
                "twitter": "https://twitter.com/Solrazr_App",
                "website": "https://razr.finance"
            }
        },
        {
            "chainId": 101,
            "address": "GTu5VbPJiECrtNrPSDvCTuT3BP1TNewMmxmShuhn3b1G",
            "symbol": "RayWJ",
            "name": "Your Favorite Martian",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/raywilliamjohnson/logo/main/logo.png",
            "tags": [
                "RayWJ-coin",
                "Metaverse-coin",
                "governance-token",
                "EqualsThree-token",
                "NFT-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for raywilliamjohnson for his june 1st Yourfavoritemartian reborn date and NFT token value 1usdt = 10 RWJ tokens",
                "discord": "https://discord.gg/3yNwFyPzUb",
                "facebook": "https://www.facebook.com/raywilliamjohnson",
                "github": "https://github.com/raywilliamjohnson",
                "instagram": "https://www.instagram.com/raywilliamjohnson/",
                "linkedin": "https://www.linkedin.com/in/ray-william-j-84538a7b/",
                "medium": "https://joinentre.com/?referredBy=el0nmusk",
                "twitter": "https://twitter.com/RayWJ",
                "website": "https://linktr.ee/Zues369"
            }
        },
        {
            "chainId": 103,
            "address": "AvtWDVgef2Yx68Yh67eNjS1CYkeXZNECoWJ7og4xYfrc",
            "symbol": "SVT",
            "name": "Solvenir Test",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AvtWDVgef2Yx68Yh67eNjS1CYkeXZNECoWJ7og4xYfrc/logo.png",
            "tags": [
                "currency"
            ]
        },
        {
            "chainId": 103,
            "address": "3GWsMY8REuofBM4fsa9h3mJSkLtJRzSCJJDvjKdSMMXg",
            "symbol": "HIT",
            "name": "Hoi it tien token",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/10118456/170443817-4850ba13-f523-4c0d-b553-b56ec0a30a44.png"
        },
        {
            "chainId": 101,
            "address": "Hefou8y5H8zDvb162mrKoanGJyyosx93nm9471oyUrkS",
            "symbol": "HeFo",
            "name": "HeFo TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hefou8y5H8zDvb162mrKoanGJyyosx93nm9471oyUrkS/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/sol_heyfox"
            }
        },
        {
            "chainId": 101,
            "address": "9qaZLzuNMFgyqYihpbwgyUtFDXM1Ht9e3GWebZ2FNWhH",
            "symbol": "SUNC",
            "name": "Suny Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9qaZLzuNMFgyqYihpbwgyUtFDXM1Ht9e3GWebZ2FNWhH/logo.png",
            "tags": [
                "sunycoin"
            ]
        },
        {
            "chainId": 103,
            "address": "4e4sKGSGVzm5kFSAPPKmCxz7jBCUAixcSCAL1BbkrEPU",
            "symbol": "HPT",
            "name": "HyunPoongToken",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4e4sKGSGVzm5kFSAPPKmCxz7jBCUAixcSCAL1BbkrEPU/logo.png"
        },
        {
            "chainId": 101,
            "address": "2R5Gtuayh7E4DCqtMKyBQRMthi51Y2da81XBF3S1mC59",
            "symbol": "$WingsWL",
            "name": "Wings WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2R5Gtuayh7E4DCqtMKyBQRMthi51Y2da81XBF3S1mC59/logo.png",
            "extensions": {
                "discord": "https://discord.gg/hKuu9pTmrF",
                "twitter": "https://twitter.com/Winto_io"
            }
        },
        {
            "chainId": 101,
            "address": "Ge15KTDNgqtaM7BGSBD5fSawJHxS8S7FFH9aZniaZ2kX",
            "symbol": "MTM",
            "name": "Motum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mtm-token/mtm-token/main/MTM3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Cf4yCbJs2LZeNLN1jRvLk8q6tgBh7MMG1Rg1fKf5MxbL",
            "symbol": "OSM",
            "name": "Osmium",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cf4yCbJs2LZeNLN1jRvLk8q6tgBh7MMG1Rg1fKf5MxbL/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/MinersofMars",
                "website": "https://www.minersofmars.com/"
            }
        },
        {
            "chainId": 103,
            "address": "2jeTEbshhhKSCXZ82J7LkxGnUk4bhDXccFJqkbbaiwiL",
            "symbol": "DOJOCOINONE",
            "name": "DOJO TEST COIN ONE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2jeTEbshhhKSCXZ82J7LkxGnUk4bhDXccFJqkbbaiwiL/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/ARTayade"
            }
        },
        {
            "chainId": 101,
            "address": "uGPpPFGMNUbiEZG4kihsqXAGQcb3Egb8mks2pUTzSkj",
            "symbol": "WingsWL",
            "name": "Wings WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uGPpPFGMNUbiEZG4kihsqXAGQcb3Egb8mks2pUTzSkj/logo.png",
            "extensions": {
                "discord": "https://discord.gg/hKuu9pTmrF",
                "twitter": "https://twitter.com/Winto_io"
            }
        },
        {
            "chainId": 103,
            "address": "GUfxKpaqxBXdcgtcnWEmprXXsfprMktijcEySYq5TwG4",
            "symbol": "TTB_WL_v1",
            "name": "TTB Whitelist Token v1",
            "decimals": 9,
            "logoURI": "https://media.terminatorteddybearz.com/image/ttb_logo.svg",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/RfCSySm7Us",
                "twitter": "https://twitter.com/terminatorbearz",
                "website": "https://www.terminatorteddybearz.com"
            }
        },
        {
            "chainId": 101,
            "address": "5AJhdosQ88U1g2a4bhmxXpRVGiujKRwrWcAmd7iuWtQe",
            "symbol": "BokitaPasion",
            "name": "CABJ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Novak-Fenocchio/LimboCoin/main/bokitapasionlogo.png"
        },
        {
            "chainId": 101,
            "address": "fppTWtc6UvGtbtrDTJ52bMmQAkfbRVXZNrtKyWsJdaz",
            "symbol": "MoMu",
            "name": "MojaMula",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/fppTWtc6UvGtbtrDTJ52bMmQAkfbRVXZNrtKyWsJdaz/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "mojamula-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2bAXDGxtgpH4Qyg6kqSsE9ojMw6TNCnDZQXhtkV9hjEj",
            "symbol": "SIA",
            "name": "Shapz Intelligence Artificial",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2bAXDGxtgpH4Qyg6kqSsE9ojMw6TNCnDZQXhtkV9hjEj/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://shapz.org"
            }
        },
        {
            "chainId": 101,
            "address": "36eZaAUA92VL84LBbXbPJbxqRDyRYpqwMWjZGsED1Lyd",
            "symbol": "ARYA",
            "name": "ARYA TOKEN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/36eZaAUA92VL84LBbXbPJbxqRDyRYpqwMWjZGsED1Lyd/logo.png",
            "tags": [
                "test"
            ]
        },
        {
            "chainId": 101,
            "address": "o7Xdr1sQZpFnxP6yKsYCh6rmyx9PfQ4L4AXAYYXoW3g",
            "symbol": "EMOTION",
            "name": "Learn2Earn DAO",
            "decimals": 6,
            "logoURI": "https://l2edao.s3.amazonaws.com/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Emotion token is the l2eDAO protocol that revolutionizes the process of education. Learn, create, battle, build metaverse and make money with the new skills you learned from the community.",
                "discord": "https://discord.gg/RzMwkYEKJ9",
                "twitter": "https://twitter.com/l2eDAO",
                "website": "https://learntoearn.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "GanGt4btK71F4SChwakzh69bh2T7rBsAjmSE6rRWUSJM",
            "symbol": "Gangsta",
            "name": "kings of da street Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GanGt4btK71F4SChwakzh69bh2T7rBsAjmSE6rRWUSJM/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kingsofdastreet"
            }
        },
        {
            "chainId": 101,
            "address": "4ErSwaQufa3rsyDfXq5rNSmvpHDmdTk4MifhFnFeKQEZ",
            "symbol": "GHST",
            "name": "GHST Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ErSwaQufa3rsyDfXq5rNSmvpHDmdTk4MifhFnFeKQEZ/logo.png"
        },
        {
            "chainId": 101,
            "address": "wpHYDrmVvVoVqTXKFywkyWdCFKNGQLhd1gDaQ8BuDzw",
            "symbol": "ESD",
            "name": "ElectroShoeDemo",
            "decimals": 0,
            "logoURI": "https://i.postimg.cc/fWYZ76Mq/Screenshot-2022-05-26-230810.png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "website": "https://www.electroshoe.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CDTvfESJbaBDzKV11wTLxmMiutrc5jwBZjASysLbAovF",
            "symbol": "$KIDZ",
            "name": "Trippy Kidz Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CDTvfESJbaBDzKV11wTLxmMiutrc5jwBZjASysLbAovF/kidzz.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TrippyKidzNFT",
                "website": "https://trippykidz.net/"
            }
        },
        {
            "chainId": 101,
            "address": "3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF",
            "symbol": "STYLE",
            "name": "STYLE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF/style.png",
            "tags": [
                "utility-token",
                "fashion",
                "wte",
                "wear-to-earn"
            ],
            "extensions": {
                "discord": "https://discord.gg/anybodies",
                "twitter": "https://twitter.com/anybodiesNFT",
                "website": "https://www.anybodies.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BogkiGRxMWym5ZeZMAVJb3qedBuWTDGkQNTXQGEWHy7F",
            "symbol": "TEMPLE",
            "name": "TEMPLE TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BogkiGRxMWym5ZeZMAVJb3qedBuWTDGkQNTXQGEWHy7F/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/phrRD3n5",
                "twitter": "https://twitter.com/temple_token"
            }
        },
        {
            "chainId": 101,
            "address": "FWUvCEr1c379gYUa75x4bw2GgipcuWpaSHKZpYzJKtyt",
            "symbol": "SGMC",
            "name": "SugMa Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Cao-Tony/Sugma-Coin/main/SugMa%20Coin%20Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2LSXNVyqVRdJWGgnZQdxjDLbsSiPwSpmt73jruXgQUPG",
            "symbol": "MCBD",
            "name": "McBeard Gaming",
            "decimals": 0,
            "logoURI": "https://github.com/McBeardGaming/crypto/blob/main/61lY1kT-_400x400.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HgW82hmbEYnguazY4X9tWBsAFHnz1Vfxa9qzp7ujz19G",
            "symbol": "ILU",
            "name": "LOVE333",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HgW82hmbEYnguazY4X9tWBsAFHnz1Vfxa9qzp7ujz19G/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B4pijSKo7Miijt9osw13HeYs6TuSGttYNFNdjVqBDHaS",
            "symbol": "Davos",
            "name": "World Economic Forum",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/WorldEconomic-Forum/logo/main/logo.png",
            "tags": [
                "Davos-coin",
                "WEF-coin",
                "governance-token",
                "Great-Reset-token",
                "Klaus-Schwab-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for The Great Reset W.E.F and Klaus Schwab 1usdt = 2 Davos tokens",
                "discord": "https://discord.gg/3yNwFyPzUb",
                "facebook": "https://www.facebook.com/CarlosVerbelen",
                "github": "https://github.com/WorldEconomic-Forum",
                "instagram": "https://www.instagram.com/moonbootscapital/",
                "linkedin": "https://search.brave.com/search?q=Carlos+Verbelen\u0026source=desktop",
                "medium": "https://joinentre.com/?referredBy=el0nmusk",
                "twitter": "https://twitter.com/Davos",
                "website": "https://linktr.ee/Zues369"
            }
        },
        {
            "chainId": 101,
            "address": "9usn7CnYvbe3Yq9yqNaA8oKSxZbUPobRzbYh83xAoumX",
            "symbol": "ELKO",
            "name": "Elkoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9usn7CnYvbe3Yq9yqNaA8oKSxZbUPobRzbYh83xAoumX/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6f8q3YJNixh3qnTkM5BipUEoWPW7enz5iDN5HLBZcWbv",
            "symbol": "SAAG",
            "name": "SAAG Power",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/sminomas/crypto/main/SAAG.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BhyD7wsPdSfqfDWpbx7S99GAXqHJhX4QsviNNJ6oSzqu",
            "symbol": "$CIETY",
            "name": "CIETY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhyD7wsPdSfqfDWpbx7S99GAXqHJhX4QsviNNJ6oSzqu/Logo.png",
            "tags": [
                "The-Solciety",
                "utility-token",
                "NFT-Project"
            ],
            "extensions": {
                "description": "The Solciety NFT",
                "twitter": "https://twitter.com/TheSolcietyNFT"
            }
        },
        {
            "chainId": 101,
            "address": "4p4Nsn5WLbzq6GXH8j8ADqMFdUxF6vTTEM4QYjScRbm7",
            "symbol": "RTTY",
            "name": "RatCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mroberts72/crypto/main/4p4Nsn5WLbzq6GXH8j8ADqMFdUxF6vTTEM4QYjScRbm7.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4rfw89NXRNfg9tG2cWYp2XuuLQDd82AKbtk2hqzmbhnc",
            "symbol": "CCT",
            "name": "ChatChain",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/onuraladi/chatchain/main/chatchain.png",
            "tags": [
                "The-ChatChain",
                "utility-token",
                "Blockchain-Project"
            ]
        },
        {
            "chainId": 101,
            "address": "CtFVBBKmnsbcKYGZeYo7qK7tEvEe1nJxPKZRse81K5N3",
            "symbol": "VTRS",
            "name": "VITA RES",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MKD-Stable-token/vtrs/main/logo%20vtrs.png",
            "tags": [
                "community-token",
                "utility-token",
                "asset-token"
            ],
            "extensions": {
                "website": "https://www.vitares.com.mk/"
            }
        },
        {
            "chainId": 101,
            "address": "8KD9AtYCeZqt7eCpdwEDvGNnMkEJyq8YhdrQ9pDiNd4d",
            "symbol": "CSM",
            "name": "Cesium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/brewsterbonifacio/Cesium/main/cesium.png",
            "tags": [
                "cesium-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6MqWmAxxJJVYzsdTrR7EjyZccEXu9BRfgVukVPVJiW6G",
            "symbol": "DNLAB",
            "name": "Digital Nomad Labs",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6MqWmAxxJJVYzsdTrR7EjyZccEXu9BRfgVukVPVJiW6G/logo.png",
            "tags": [
                "DNLAB",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DNomadLabs",
                "website": "https://www.digitalnomadlabs.io"
            }
        },
        {
            "chainId": 103,
            "address": "2p3D3M5HFUd2UXAwJ5BuoKQHhZ6gM5FZd1kKgmLqLd8H",
            "symbol": "DGUST",
            "name": "D'GUST Restaurante",
            "decimals": 0,
            "logoURI": "https://ipfs.io/ipfs/Qmf6kHuQKsahHx6vmoHG6TTv5i5YsNXhiF2ZffghbTvfzE",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "6zvSWwTESBNGGZxvygZZv82UouWXS8jEwppxaYnRu75t",
            "symbol": "BOCA",
            "name": "EnBoca Restaurante",
            "decimals": 0,
            "logoURI": "https://ipfs.io/ipfs/QmSEVTzh7a9Q3iMr5Rzt8779FhfefBvhD4JkjGnJc3yc8z",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7Eb1v8yoS14H1hmF8HRWGmgq6SdHHfkUcy6v2biCePF3",
            "symbol": "DRIM",
            "name": "Drimly Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Eb1v8yoS14H1hmF8HRWGmgq6SdHHfkUcy6v2biCePF3/logo.png"
        },
        {
            "chainId": 103,
            "address": "F8rxpjPfYb5qmgvVVEtEQ2Vy872A13W1B4FwEL97FbiV",
            "symbol": "JOC",
            "name": "JOCONDE TOK",
            "decimals": 0,
            "logoURI": "https://ipfs.io/ipfs/QmQ1yQSVkNzZXF6sLyhAa8AyayKfh8dbnBGavfTfMpi9hP",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GooWYbh2Ao8WMfwRyWSEhAUMZqKnkHUpmpsLgXdLoN3k",
            "symbol": "MTM",
            "name": "M0TUM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mtmToken/mtm-token/main/MTM3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "D77WLoiZxiFyN8kuzHfGxTjgf6HZ7HPXysK1ZQbK3iK7",
            "symbol": "YSSC",
            "name": "Yellow Square Stable Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D77WLoiZxiFyN8kuzHfGxTjgf6HZ7HPXysK1ZQbK3iK7/logo.png",
            "tags": [
                "yellowsquare",
                "yssc",
                "stablecoin"
            ]
        },
        {
            "chainId": 101,
            "address": "H8fPkH7c712okbKH1Uskc3KjQryMkr2aBpFceXYxXo4B",
            "symbol": "GNXW",
            "name": "Genetix Whitelist ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kyawmin5644/genetixlogo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "D7DQMKopKbfscH1dgZkvS3aktVb9TDvSpUtytcf9gpEQ",
            "symbol": "SOLNFTS",
            "name": "Official Solana NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/4e126bd41e31ef0443b932c022613c9403aba567/assets/mainnet/D7DQMKopKbfscH1dgZkvS3aktVb9TDvSpUtytcf9gpEQ/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://solscan.space"
            }
        },
        {
            "chainId": 101,
            "address": "7CLDJc5aKJp2WYLGVyNAwdbcnvdKdZuKggNKGo3GigYL",
            "symbol": "$BEARS",
            "name": "Moonbears Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/token_mb.png",
            "tags": [
                "NFT",
                "Staking"
            ]
        },
        {
            "chainId": 101,
            "address": "GrG4YS1C4jTfkdQmESaxrjW3n9vnBMCtbwM2gCkyajKS",
            "symbol": "$NAKA",
            "name": "Nakama Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/token_mb.png",
            "tags": [
                "NFT",
                "Staking"
            ]
        },
        {
            "chainId": 101,
            "address": "HcFuzSqHQhitpYqHkxBYs5vFSURAtSXojNdmQ7vBUimB",
            "symbol": "LDTASC",
            "name": "LDT Ascension Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HcFuzSqHQhitpYqHkxBYs5vFSURAtSXojNdmQ7vBUimB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Living Dead Teds Gen 1 Ascension Token",
                "discord": "https://discord.gg/livingdeadteds",
                "twitter": "https://twitter.com/LivingDeadTeds",
                "website": "https://livingdeadteds.com"
            }
        },
        {
            "chainId": 101,
            "address": "5BHXrVz8ogSyU8hMkmHsvMek67yAZPox5npWXWGUPxEW",
            "symbol": "GNWL",
            "name": "Genetix Primate Whitelist",
            "decimals": 9,
            "logoURI": "https://github.com/genetix00717/logo/blob/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DKCP3dFJrLLATpkeZ3JWAkhWuD45ruyoJndZRsPZzpvH",
            "symbol": "FINE",
            "name": "FINE",
            "decimals": 9,
            "logoURI": "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/DKCP3dFJrLLATpkeZ3JWAkhWuD45ruyoJndZRsPZzpvH/logo.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FEmN4zkCPqB6uGYB6LA3uHu2X6cayLx2quxpzZkhaNBw",
            "symbol": "GNXWL",
            "name": "Genetix WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/genetix00717/logo/main/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SoULrrVFwaqHt8HkDFwR9jP6dUCumw99oRLpgxaKFu6",
            "symbol": "$SOUL",
            "name": "Jigoku Hounds Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SoULrrVFwaqHt8HkDFwR9jP6dUCumw99oRLpgxaKFu6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.jigokuhounds.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7wLNtLG7edmmiJyuWAaWekLxYAUp2uXWHNAP6L8fVurF",
            "symbol": "INCENTIVE",
            "name": "The Incentive",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7wLNtLG7edmmiJyuWAaWekLxYAUp2uXWHNAP6L8fVurF/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/The_Incentive",
                "website": "https://www.theincentive.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "MLKmUCaj1dpBY881aFsrBwR9RUMoKic8SWT3u1q5Nkj",
            "symbol": "MILK",
            "name": "MILK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MLKmUCaj1dpBY881aFsrBwR9RUMoKic8SWT3u1q5Nkj/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/udderchaos",
                "twitter": "https://twitter.com/udderchaossol",
                "website": "https://udderchaos.io/"
            }
        },
        {
            "chainId": 101,
            "address": "EXC3Fw3H2FNeiPuo5c5SQk9VSEM3gfFi6QmqSTizZ9zW",
            "symbol": "KOCO",
            "name": "KokoCoin ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EXC3Fw3H2FNeiPuo5c5SQk9VSEM3gfFi6QmqSTizZ9zW/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AgxJ2YykLYcFmK1mbzPJ2F98bnv4h3DpF1juGjC9yoTj",
            "symbol": "FATCOIN",
            "name": "Fatma token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AgxJ2YykLYcFmK1mbzPJ2F98bnv4h3DpF1juGjC9yoTj/logo.png",
            "tags": [
                "school-token",
                "IA",
                "Fatma"
            ]
        },
        {
            "chainId": 101,
            "address": "Aq7HPu6zCFQ1zD5KWQGJF1CC8DxCsYJM2gfcu3NxUbtB",
            "symbol": "TSZC",
            "name": "The Share Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MasterPhooey/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2kujJrETYrfimaL8BSXwrms9oqt9pdRPnjDWdRTySuXi",
            "symbol": "INTI",
            "name": "Inti",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2kujJrETYrfimaL8BSXwrms9oqt9pdRPnjDWdRTySuXi/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "RKTQTjGy4Q31gzoixWfRpXUzywj3bUztEh7RQsJn5Bc",
            "symbol": "ROCKET",
            "name": "RocketDAO Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RKTQTjGy4Q31gzoixWfRpXUzywj3bUztEh7RQsJn5Bc/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/J3BgFWxh5x",
                "twitter": "https://twitter.com/Rocketsauce_",
                "website": "https://rocketsauce.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "5npveyhnNWfYbt8Z9xb4btvPrns3BQNHgw6XkqTuQGs",
            "symbol": "PHOTON",
            "name": "Dimenxion Light",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5npveyhnNWfYbt8Z9xb4btvPrns3BQNHgw6XkqTuQGs/logophoton.png",
            "tags": [
                "game-token",
                "Governance"
            ],
            "extensions": {
                "website": "https://dimenxion.io/"
            }
        },
        {
            "chainId": 101,
            "address": "61XKqkU4eBvE7u7dV9e59Ln8UzWBQqbXoemXPqL14iXe",
            "symbol": "$MIGO",
            "name": "TheAmigos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/TheAmigosOfficial/theamigos/main/amigos.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/theamigosnft",
                "twitter": "https://twitter.com/theamigosnft",
                "website": "https://theamigos.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3VX51z65JKez2LYFTdYBfC9T5vWfSXFfrpdjrfeZCrkA",
            "symbol": "BBKS",
            "name": "BananaBucks",
            "decimals": 0,
            "logoURI": "https://github.com/mr365k/token-list/blob/1d4afce7c923a613540c5c793956ddae3d3ab607/assets/mainnet/3VX51z65JKez2LYFTdYBfC9T5vWfSXFfrpdjrfeZCrkA/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9HhZRiV63c4pMc4xEscrGDh6Xu17gPSpkCMvSb5tmGis",
            "symbol": "$MIGO",
            "name": "Amigos",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AmigosOfficial/Amigos/main/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/theamigosnft",
                "twitter": "https://twitter.com/theamigosnft",
                "website": "https://theamigos.io/"
            }
        },
        {
            "chainId": 103,
            "address": "treessKmT2gWMhn2hiQ5QMuTPCdCLZ3wpVdE3SUB8Yp",
            "symbol": "trees",
            "name": "Forest Trees",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/forest/tree.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "Living Tree Accountancy Coin --@cryptolake2",
                "imageUrl": "https://bafybeieoh4lkabcaenwb255hmvuvqzec6rql5khnzb3xyn43jjozftynwu.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/forest/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmYGRYVdvxxfL7B2jjHWss1FyU9fhQNFLihsWENCK6n5YP/whitepaper.pdf"
            }
        },
        {
            "chainId": 103,
            "address": "9pio5iDDvov4PtdmZHjWV4zmkA5zTFVbh4eDBYKr5Ywz",
            "symbol": "SUBLI",
            "name": "SUBLIME P",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9pio5iDDvov4PtdmZHjWV4zmkA5zTFVbh4eDBYKr5Ywz/espsubl.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5VKHAZNgZVFKXPo2Ju732FmGzZc6WjyxenCQnF7XfHt5",
            "symbol": "TC1001",
            "name": "TheCoder1001 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TheCoder1001/crypto/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CiDX6Hn7J5UrPuf6eMmHt2XViEBRgH7Nd2dZbHVg45i1",
            "symbol": "NFTMC",
            "name": "NFT Master Class",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CiDX6Hn7J5UrPuf6eMmHt2XViEBRgH7Nd2dZbHVg45i1/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9bqwh1dX5XZ8QuJWt5bAg6NCemRjg6xf7QquTEBPMNut",
            "symbol": "METASIGN",
            "name": "METASIGNS Mint Token",
            "decimals": 0,
            "logoURI": "https://artiftakt3d.s3.amazonaws.com/metasigns.png",
            "extensions": {
                "twitter": "https://twitter.com/metasigns_app",
                "website": "https://metasigns.app"
            }
        },
        {
            "chainId": 101,
            "address": "9XxqhRpiNQJ4dViBY1A6nSaUoUgQ9m9JZjCwj3oVu3We",
            "symbol": "BAGZ",
            "name": "Hidden BAGZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/HiddenBoyz/BAGZ/main/BAGZ%20Logo.png"
        },
        {
            "chainId": 101,
            "address": "2SJduTt8KtPLEtz53fRKtVAsPaxcod4UeKvnodwz2CPD",
            "symbol": "$KAGE",
            "name": "KAGE Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2SJduTt8KtPLEtz53fRKtVAsPaxcod4UeKvnodwz2CPD/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://city-savior-scratch.netlify.app"
            }
        },
        {
            "chainId": 101,
            "address": "4YnWvpPRFypDAjzDbTegBivy6hbDp8UAMRKrCkmvAhdP",
            "symbol": "$OIL",
            "name": "SolBots OIL",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4YnWvpPRFypDAjzDbTegBivy6hbDp8UAMRKrCkmvAhdP/logo.png",
            "tags": [
                "utility-token",
                "dao",
                "staking-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/6YVFUE5wjN",
                "twitter": "https://twitter.com/SolBotsDAO"
            }
        },
        {
            "chainId": 101,
            "address": "AwoT6gj8cpadFabDfNKsUrrsvQTX1iAtkNiPUw2mmhsV",
            "symbol": "DKCOIN",
            "name": "DoodleKoalas Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AwoT6gj8cpadFabDfNKsUrrsvQTX1iAtkNiPUw2mmhsV/logo.png",
            "tags": [
                "community-token",
                "social-token",
                "nft",
                "charity",
                "dao"
            ],
            "extensions": {
                "discord": "https://discord.gg/fFEvtKCZtm",
                "instagram": "https://www.instagram.com/doodlekoalas",
                "twitter": "https://twitter.com/DoodleKoalas",
                "website": "https://doodlekoalas.com"
            }
        },
        {
            "chainId": 101,
            "address": "9z54BqRBCiYGn6rHKc3LRgLqmLM5Y2PmBs65RnmxdueG",
            "symbol": "RWT",
            "name": "Robillionare Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/RWT/main/logo.jpg",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DNAdXVQZjBa77jHoqij52RDhkDkBq6iCLsJwCRfhzYz2",
            "symbol": "DNA",
            "name": "DNA",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DNAdXVQZjBa77jHoqij52RDhkDkBq6iCLsJwCRfhzYz2/logo.jpg",
            "extensions": {
                "discord": "https://discord.gg/g4u6QCPk3S",
                "twitter": "https://twitter.com/AncestorNFT",
                "website": "https://ancestors.digital/"
            }
        },
        {
            "chainId": 101,
            "address": "2HXfRTmmCEUZJDbqySfvETewMUxujod1ZwUHJMrHGCEf",
            "symbol": "$FRAIS",
            "name": "Miss Frais",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2HXfRTmmCEUZJDbqySfvETewMUxujod1ZwUHJMrHGCEf/logo.png",
            "tags": [
                "utility-token",
                "dao",
                "staking-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/rJjeXWYxwk",
                "twitter": "https://twitter.com/MissFrais",
                "website": "https://missfrais.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2rTsbuDKMxnvZ3zH3MbUF1mdnhRkCBjFjPPFEzLiVuEA",
            "symbol": "LANG",
            "name": "LangueCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/thiagoDMDiniz/coin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4VvPCvcU7QDQK3exQ1BDPVUVMwR2SEhsi5Qr64Yat3am",
            "symbol": "Phoo",
            "name": "aHPhooey Coins",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MasterPhooey/crypto/main/logo2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BL94oogdyQ9S8K7JyAUXSPZxZ8S4w28vQGELZppCXxqS",
            "symbol": "ReHa",
            "name": "RetroHacked",
            "decimals": 0,
            "logoURI": "https://retrohacked.com/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://retrohacked.com"
            }
        },
        {
            "chainId": 101,
            "address": "B8w5nikfJeyZ2npZVUKp92pui7DsvsX5P9UWtTitd8yM",
            "symbol": "SIGS",
            "name": "Sig Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SigTL/SigCrypto/main/Sig.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Ax2MkQAaS722tzToaoHQe16GVWVJvofWp1tFTdsTJhk",
            "symbol": "RALMOND",
            "name": "ROASTED ALMOND",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/STARSCREAM-SAYS/almond/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GYhxEd1EcpAcqtBVFwihsMfJ5CYkECXJCACNZCETKeyj",
            "symbol": "SNN",
            "name": "Bulls News",
            "decimals": 9,
            "logoURI": "https://dm.sattonft.com/media/tokens/bac/news.jpg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BossBullsClub"
            }
        },
        {
            "chainId": 101,
            "address": "queLNbM3wSpS2eYwDe2VdAvPLpaVDE3QNiRs2i8S3bp",
            "symbol": "QUELLEN",
            "name": "Quellen Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/queLNbM3wSpS2eYwDe2VdAvPLpaVDE3QNiRs2i8S3bp/logo.png",
            "tags": [
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BJkKpQ1tE81msuAxguGSmL47KnxXsNjFG9EJE1e2Sixk",
            "symbol": "ANUS",
            "name": "Uranus Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/realbuckroberts/uranustoken/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GJkijTYMxiVga8VSMGnH9wwz954fuRG7jb1JzVApfnrF",
            "symbol": "MNSC",
            "name": "ManasCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MartinDarko/ManasCoin/main/manascoin.png",
            "tags": [
                "mint-token"
            ]
        },
        {
            "chainId": 101,
            "address": "61QYeabDU9sb9UmFJjcApznWigRxLyFjKD3dpD7xHPUL",
            "symbol": "YOU",
            "name": "Youtube",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/61QYeabDU9sb9UmFJjcApznWigRxLyFjKD3dpD7xHPUL/logo.png"
        },
        {
            "chainId": 103,
            "address": "BuSqnbU8HMyDrzmBzh9wC33gyKMp2EPpDbhcNu6FL2Un",
            "symbol": "DGUST",
            "name": "DGUST Rest",
            "decimals": 1,
            "logoURI": "https://ipfs.io/ipfs/Qmf6kHuQKsahHx6vmoHG6TTv5i5YsNXhiF2ZffghbTvfzE",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "634PuhuzcE3JoF5nurbBwQKn6ehYD2fwD4nr38uhQB12",
            "symbol": "BOCA",
            "name": "EnBoca Rest",
            "decimals": 1,
            "logoURI": "https://ipfs.io/ipfs/QmSEVTzh7a9Q3iMr5Rzt8779FhfefBvhD4JkjGnJc3yc8z",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CqgizwKk9957rhLkM5oZTmcGZGwTRauYB4HgvAxhG1Fq",
            "symbol": "NAMI",
            "name": "NAMI",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/nkmatoken.png",
            "tags": [
                "NFT",
                "Staking"
            ]
        },
        {
            "chainId": 103,
            "address": "GYWjRN1ZBNpHzW6KaiKa9F1tSWyJjyNS8r1VugLyeNky",
            "symbol": "ProRe",
            "name": "Manly Marine Pde",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rflawn/prorelogo/main/prorelogo.png",
            "tags": [
                "security-token"
            ],
            "extensions": {
                "website": "https://prore.io"
            }
        },
        {
            "chainId": 101,
            "address": "Fkf2WbzRuULKsDv4WK2T4VGFNNjfziUAHZ3Z3tB8oFnS",
            "symbol": "MNLY1",
            "name": "Manly 1",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rflawn/PreReTokensList/main/mnly1.png",
            "tags": [
                "security-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5DYkrfkZ9SG9NqBq2yYqRbUWAibK4E5QaNwV4aqpDb6K",
            "symbol": "SBYC",
            "name": "Solana Bored Yacht Club Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5DYkrfkZ9SG9NqBq2yYqRbUWAibK4E5QaNwV4aqpDb6K/SBYC.png",
            "tags": [
                "utility-token",
                "nft",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SB5Vr6EFRqeLws4eg6LVMXJg9dFrfowEqiknAcYvoAY",
            "symbol": "SOLBARSWL1",
            "name": "SOLBars Tier 1 Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SB5Vr6EFRqeLws4eg6LVMXJg9dFrfowEqiknAcYvoAY/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SB2KhzJb2kqhkYC6J5DKw449wiHf7gEbGtKA9NEnbaN",
            "symbol": "SOLBARSWL2",
            "name": "SOLBars Tier 2 Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SB2KhzJb2kqhkYC6J5DKw449wiHf7gEbGtKA9NEnbaN/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CcHazxxuN1UG86ubpRWZhrpF8vNvY1kqxAevtwvFc34t",
            "symbol": "SPZ",
            "name": "Sponscoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CcHazxxuN1UG86ubpRWZhrpF8vNvY1kqxAevtwvFc34t/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://sponsor.me/"
            }
        },
        {
            "chainId": 101,
            "address": "J9QoYEwtScVwQRv4YztM5i799ubovW7xaFfCuiuKjegn",
            "symbol": "QK",
            "name": "Quak Quak",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ARX4567/crypto-1/main/crypto1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DGPw3zcZuMxoVmnEQA9uhZDn9Tg2ynyaBrbULRQ8y1Gh",
            "symbol": "DEGEPASS",
            "name": "DEGEPASS",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DGPw3zcZuMxoVmnEQA9uhZDn9Tg2ynyaBrbULRQ8y1Gh/logo.png",
            "tags": [
                "security-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/A3PeauVFTr",
                "twitter": "https://twitter.com/degenespace",
                "website": "https://degenerative.space/"
            }
        },
        {
            "chainId": 101,
            "address": "3JyJDoZJywcje4kGSWni822NsXeMNz8UXAMFdWN6Gf41",
            "symbol": "WL_BNNA",
            "name": "WL tier Moonkee",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/TheMoonkeesNFT/TheMoonkees_logo/main/assets/imgs/wl%20token%20img.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7s4xD49kUHGpXcbZzCWx65GvL7a5pCCji9bGFpgzq1Wn",
            "symbol": "DROB",
            "name": "Drake Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/drakesbigday/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Xve8emm4jeZq6xzxPQfwNKz77gkxYZQHKKGT3YBVuCe",
            "symbol": "KeithJulie",
            "name": "Keith and Julie Stewart",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/YellowLittleTurtle/JnK/main/JnK.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CAVrUnfJ1mZB4252hsBBATowtoWEe2yECAYJZBuQRUAU",
            "symbol": "BRNDA",
            "name": "Bernd Alpha",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAVrUnfJ1mZB4252hsBBATowtoWEe2yECAYJZBuQRUAU/logo.png",
            "tags": [
                "community-token",
                "memes-token",
                "coin"
            ]
        },
        {
            "chainId": 101,
            "address": "8wpUGC6mQ7CSVYvQxxPKJA6a2Qu3JJtGMc8MNDFRsHsm",
            "symbol": "FFCN",
            "name": "Fede Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Fedef01/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CWhr7vJ1rCSgSMCcwMxdLVWdCf4KkSjZEeSmmh1poVXb",
            "symbol": "CBR",
            "name": "Community Beer Run Token",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/Jd2MCtj.png",
            "tags": [
                "bridgesplit-fraction",
                "daao",
                "barrel-dao",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/fjrFpbweAP",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/vault/CWhr7vJ1rCSgSMCcwMxdLVWdCf4KkSjZEeSmmh1poVXb"
            }
        },
        {
            "chainId": 101,
            "address": "GHJxwtgZcjNBXQJ5bAcByvnxN1BX6kKk5wndq9nVuJCE",
            "symbol": "OGBNNA",
            "name": "OG Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/TheMoonkeesNFT/TheMoonkees_logo/main/assets/imgs/og%20token%20img.png",
            "tags": [
                "OG-Tier-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "HeJvBuBjpeP8SNyipv5rr2FkyH7dWAUhVvfk9KFBUV5c",
            "symbol": "ASTC",
            "name": "AstorCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AstorLabka/crypto/main/AstorCOIN.png",
            "tags": [
                "payment-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HWo5NmPfNJ1LjdHTMZcXwPDrNQXX54TPySFbm2CWcZBj",
            "symbol": "NALA",
            "name": "Nala",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/geneva-crypto/crypto/main/Nala.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8jjZtvDQ2WTX4gbHdNBu5XbhoAJt6bD2FQHp2JsZ7atb",
            "symbol": "AC",
            "name": "AstronomicCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/philipmaciag/Crypto/main/D.T.S.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9C1qd5yrzqFpmypvA8nsK2oRxmua9PkxbSLH6BMo63JF",
            "symbol": "TZERO",
            "name": "T Zero Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SergoKalandadze/t-zero/main/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "GooNRf7PTVSMDkkiEtXWsoMaTHf3W5oaeWgmvQBduH3q",
            "symbol": "Goons",
            "name": "Galactic Goons Gen 2 Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GooNRf7PTVSMDkkiEtXWsoMaTHf3W5oaeWgmvQBduH3q/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/galacticgoonsio"
            }
        },
        {
            "chainId": 101,
            "address": "Arca7FdReYC8hRhKzeuKSp79dWn6qGSd2t5MPvPHAT2T",
            "symbol": "Arcanist",
            "name": "Zankoku labs gen 2 Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Arca7FdReYC8hRhKzeuKSp79dWn6qGSd2t5MPvPHAT2T/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ZankokuLabz"
            }
        },
        {
            "chainId": 101,
            "address": "CUBSiWEwRnyEvtbYv3ZgRUL5tqrDjXHUwiGdzcpfTud8",
            "symbol": "WAGEH",
            "name": "Okay Cubs Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CUBSiWEwRnyEvtbYv3ZgRUL5tqrDjXHUwiGdzcpfTud8/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CN2sduoDjPQqUeGmtiaEqrxXVGacVZt69oA91zAKuW4o",
            "symbol": "JAVI",
            "name": "Javi Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Telvy/javitoken/main/javitokenlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D6iruuWMixNyMKvqyLNTyFEHyc8EhuKfW2dzsde7Jar7",
            "symbol": "ITC",
            "name": "Smarttttt Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D6iruuWMixNyMKvqyLNTyFEHyc8EhuKfW2dzsde7Jar7/logo.png"
        },
        {
            "chainId": 101,
            "address": "HhgxwkVVHVoYCib3EpWyXTnbmrNfusvLmEhXkXcFf3ft",
            "symbol": "TSCM",
            "name": "The Shady Class Mint",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/flipthetip/test-tsc/main/coin2_ccexpress%20(1)_adobe_express.png",
            "tags": [
                "minting-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9AsPKqMkwrXr2EgN2rMM1Uaq31oNydwtPexCLQp2LWJE",
            "symbol": "GROW",
            "name": "Grow Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ecotoken123/icon/main/grow.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AqneFjZurwRuHR9Kt67tXxvJ1Ki9hkPys7D336uMTRzN",
            "symbol": "TANBAY",
            "name": "Tanilba Bay",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rflawn/PreReTokensList/main/TanilbaBay1.png",
            "tags": [
                "security-token"
            ]
        },
        {
            "chainId": 101,
            "address": "PigobSSPVECtDpdo1wv7Nzvvq7jKY9KfcsQWqFSitME",
            "symbol": "PIGLETS",
            "name": "Pixel Piglets Adoption Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PigobSSPVECtDpdo1wv7Nzvvq7jKY9KfcsQWqFSitME/logo.png"
        },
        {
            "chainId": 101,
            "address": "pWLbnNUQGrWB5Y94QaRGpxQPv8j7PhzSqj6SWTRjSoM",
            "symbol": "PIGLETSWL",
            "name": "Pixel Piglets Mint Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pWLbnNUQGrWB5Y94QaRGpxQPv8j7PhzSqj6SWTRjSoM/logo.png"
        },
        {
            "chainId": 101,
            "address": "3DFUNs4vDYJcq5suDoFEDGhpbhxFgFius5kgFXBfnNxK",
            "symbol": "3DFUNGUYZ",
            "name": "3DFunGuyz Discounted WL",
            "decimals": 0,
            "logoURI": "https://bafkreihebzouugjwf32gryfh7hqraxc5kue4rbsei3w57pks4phmpdfudq.ipfs.nftstorage.link/?ext=png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "CONGRATS! You have received a discounted WL token for the 3D FunGuyz Mint for having 2 or more FunGuyz in your wallet! Mint Price: 2.5 SOL, Total Supply: 4500",
                "discord": "https://discord.gg/dEKxrxt8ac",
                "twitter": "https://twitter.com/FunGuyzNFT",
                "website": "https://funguyz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ENdCXVtcFEzpVCbsedLinVWbwEbR3yfWwdKVBn1hirhk",
            "symbol": "ONE",
            "name": "One Cash",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ENdCXVtcFEzpVCbsedLinVWbwEbR3yfWwdKVBn1hirhk/logo.png"
        },
        {
            "chainId": 101,
            "address": "SakcBPcSQtSXxuxLGXhz9nJnmR4Z4HFY9Qbja8mvQib",
            "symbol": "ORNG",
            "name": "Orangefarm",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/XCHFOREVER/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5DttD6HS352t5ZwKM7gRAYB8suXErif4DrYgbRr6nbgY",
            "symbol": "LCT",
            "name": "Linea Codice Token",
            "decimals": 0,
            "logoURI": "https://github.com/ddeabreu/cryptoSolanaLineaCoinTokenPicture/blob/main/logo_LTC_lite.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "894Kdz8jCFeBfhDBm4VSRypvTgcRTdtAWxNyFQb2y5Ug",
            "symbol": "boUSDC",
            "name": "Boson USDC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/894Kdz8jCFeBfhDBm4VSRypvTgcRTdtAWxNyFQb2y5Ug/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://boson.so"
            }
        },
        {
            "chainId": 101,
            "address": "6xw18ELzvZqmSvKVPwPgrNxL4tBTqDw52WSdk9FH6aEq",
            "symbol": "HLM",
            "name": "HEALIUM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6xw18ELzvZqmSvKVPwPgrNxL4tBTqDw52WSdk9FH6aEq/logo.png",
            "tags": [
                "utility-token",
                "metaverse-token",
                "metaverse-currency",
                "healthcare-token"
            ],
            "extensions": {
                "description": "The Healium project is a blockchain-based token network that safely and reliably stores and manages high-value personal information such as individual's genetic data.",
                "facebook": "https://www.facebook.com/Healium-101849422353501/",
                "instagram": "https://www.instagram.com/healium_global/"
            }
        },
        {
            "chainId": 101,
            "address": "22Pqy9aTRXLN9oe9yEbdpJtmqf5m9mhDj7hqY7TSd2ks",
            "symbol": "PUFFWL",
            "name": "Solpuff WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/puff.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/solpuffss",
                "website": "https://solpuffs.art"
            }
        },
        {
            "chainId": 101,
            "address": "6AdBHQwRxcU3LMWpR9HFkwindpeBu6z1wFyP4E8mk4Ge",
            "symbol": "$IRON",
            "name": "Mindfolk Iron",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6AdBHQwRxcU3LMWpR9HFkwindpeBu6z1wFyP4E8mk4Ge/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mindfolk",
                "twitter": "https://twitter.com/mindfolkART",
                "website": "https://www.mindfolk.art/"
            }
        },
        {
            "chainId": 101,
            "address": "DbBJEc1NhBQhHJrVoXYyaTmqHbekiz6DhZ8H5T4KY5fQ",
            "symbol": "$STONE",
            "name": "Mindfolk Stone",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DbBJEc1NhBQhHJrVoXYyaTmqHbekiz6DhZ8H5T4KY5fQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mindfolk",
                "twitter": "https://twitter.com/mindfolkART",
                "website": "https://www.mindfolk.art/"
            }
        },
        {
            "chainId": 103,
            "address": "Bwe9F8rUtcPAUmgxXFatJVe7pWxTaHumwj5opnGvSXKf",
            "symbol": "SWGT",
            "name": "SWG token",
            "decimals": 9,
            "logoURI": "https://i.postimg.cc/zvFKYFMz/SWGT.png",
            "tags": [
                "coin"
            ]
        },
        {
            "chainId": 101,
            "address": "DwbxvyYUTwmyLCQ8ozfTrTGkzbvk4uevr9ZawxVkGtNj",
            "symbol": "RIDDLE",
            "name": "RIDDLE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DwbxvyYUTwmyLCQ8ozfTrTGkzbvk4uevr9ZawxVkGtNj/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "metaverse-token",
                "metaverse-currency"
            ],
            "extensions": {
                "discord": "https://discord.gg/aYCQfQR2Js",
                "twitter": "https://twitter.com/lorekeeperio",
                "website": "https://lorekeeper.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CK5K8GUKSGt5u5aJ7wHfeiCTgWMhcEVgo5zxAQ42msv9",
            "symbol": "PNP",
            "name": "Probably Nothing Potion",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CK5K8GUKSGt5u5aJ7wHfeiCTgWMhcEVgo5zxAQ42msv9/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/pnsc420"
            }
        },
        {
            "chainId": 101,
            "address": "BR1CK2GpuUqNUrS8Kk7WDXXiSMaAppKFPhkzbXxFZtVg",
            "symbol": "BRICK",
            "name": "Brick",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BR1CK2GpuUqNUrS8Kk7WDXXiSMaAppKFPhkzbXxFZtVg/logo.jpg",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HeavyDutyBuild",
                "website": "https://heavyduty.builders/"
            }
        },
        {
            "chainId": 103,
            "address": "BR1CK2GpuUqNUrS8Kk7WDXXiSMaAppKFPhkzbXxFZtVg",
            "symbol": "BRICK",
            "name": "Brick",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BR1CK2GpuUqNUrS8Kk7WDXXiSMaAppKFPhkzbXxFZtVg/logo.jpg",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HeavyDutyBuild",
                "website": "https://heavyduty.builders/"
            }
        },
        {
            "chainId": 101,
            "address": "oGZfELJgJfDe7gccD5Z4Xf5CDoLqEhSiKqyC36BrHpN",
            "symbol": "OG",
            "name": "Zi-Bots OG Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/oGZfELJgJfDe7gccD5Z4Xf5CDoLqEhSiKqyC36BrHpN/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "seoGAYDRW1TKHyojzZRkjBSjNuwPnsYSTUjDUXMNykg",
            "symbol": "SEOG",
            "name": "Zi-Bots SEOG Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/seoGAYDRW1TKHyojzZRkjBSjNuwPnsYSTUjDUXMNykg/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WL43J7vzgyYRNNmDBvDK2CtuKHoQHdPoL1gL5XTpPeP",
            "symbol": "WL",
            "name": "Zi-Bots Whitelist Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WL43J7vzgyYRNNmDBvDK2CtuKHoQHdPoL1gL5XTpPeP/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "iAnJGaHwpDjqw4mDQ1b7PfyMnp41W8Xnqt4gD9kpjvF",
            "symbol": "SVT",
            "name": "SVT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iAnJGaHwpDjqw4mDQ1b7PfyMnp41W8Xnqt4gD9kpjvF/logo.png"
        },
        {
            "chainId": 101,
            "address": "97pNcyxA4d3vcAxra6co4AZkqxuJ7N1XqWWVbSaqEak",
            "symbol": "GPT",
            "name": "GPT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/97pNcyxA4d3vcAxra6co4AZkqxuJ7N1XqWWVbSaqEak/logo.png"
        },
        {
            "chainId": 101,
            "address": "Fg9CgaQFdnfzTs2N5szFTm85JGjgsVtqzVBrgcLQU6j",
            "symbol": "SPRL",
            "name": "SPIRAL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/willbov/SPIRAL/main/spirallogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3DWLgzpD6z9PrUY7Hk9CyHHTrGk28aHwcyPhgdQmExTt",
            "symbol": "3DFGWL",
            "name": "3DFunGuyz WL",
            "decimals": 0,
            "logoURI": "https://bafkreibp4wzfh5lrqr3gqlcduk4hk64qwmosyryhwrqjaa7qqcggyrylqe.ipfs.nftstorage.link/?ext=png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "description": "CONGRATS! You have received a WL token for the 3D FunGuyz Mint! Mint Price: 2.75 SOL, Total Supply: 4500",
                "discord": "https://discord.gg/dEKxrxt8ac",
                "twitter": "https://twitter.com/FunGuyzNFT",
                "website": "https://funguyz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3p2LXnEDQvMGLE7wVz49ys79E2UceihCANh26wwQxmt5",
            "symbol": "UM",
            "name": "Um Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ethan-new/cryptoimage/main/start.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CHtRriqEcMCg9A48neeWEH31gMMagf3VrGudYe1Jp3Tb",
            "symbol": "CCC",
            "name": "Conspiracy Crypto Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Abhi-bot-dot/ConpiracyCrypto/main/CCC.png",
            "tags": [
                "Not-So-True-token",
                "Social-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "AFvt4EhTybpRHJpU5X9kYTWDqsgwD9gxittNtSsqMQxX",
            "symbol": "bUSDC",
            "name": "Bosonso USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AFvt4EhTybpRHJpU5X9kYTWDqsgwD9gxittNtSsqMQxX/logo.png",
            "tags": [
                "stablecoin"
            ],
            "extensions": {
                "website": "https://boson.so"
            }
        },
        {
            "chainId": 101,
            "address": "AXY11ExJToaGPKp7BmGjkzitfs1ta7cmjJn58Pn2t3Z1",
            "symbol": "AXY",
            "name": "AXY Network",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AXY11ExJToaGPKp7BmGjkzitfs1ta7cmjJn58Pn2t3Z1/logo.jpg",
            "tags": [
                "Utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "AXY11ExJToaGPKp7BmGjkzitfs1ta7cmjJn58Pn2t3Z1",
            "symbol": "AXY",
            "name": "AXY Network",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AXY11ExJToaGPKp7BmGjkzitfs1ta7cmjJn58Pn2t3Z1/logo.jpg",
            "tags": [
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5NRMCHoJtq5vNgxmNgDzAqroKxDWM6mmE8HQnt7p4yLM",
            "symbol": "gg_SOL",
            "name": "gg_SOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5NRMCHoJtq5vNgxmNgDzAqroKxDWM6mmE8HQnt7p4yLM/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "HAYwz6cHGuGAvLNifqGypH4mzv8fF5wv9SvcYLRGd18Q",
            "symbol": "gg_USDC",
            "name": "gg_USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAYwz6cHGuGAvLNifqGypH4mzv8fF5wv9SvcYLRGd18Q/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "7V3hgNMhVHwLPsyD5ujG248PV1hwnsvpBrAMeWXWboRZ",
            "symbol": "gg_mSOL",
            "name": "gg_mSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7V3hgNMhVHwLPsyD5ujG248PV1hwnsvpBrAMeWXWboRZ/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "9oLbMFr1AedcbXjqNge4QkrQMw5Jeae3eTbPQ94Zp1aD",
            "symbol": "ggUSDT",
            "name": "ggUSDT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9oLbMFr1AedcbXjqNge4QkrQMw5Jeae3eTbPQ94Zp1aD/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "Er1sCVW55YH6gMTc8HdvZwZ9YrWksLAso6HtA3knXBKU",
            "symbol": "ggBTC",
            "name": "ggBTC",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Er1sCVW55YH6gMTc8HdvZwZ9YrWksLAso6HtA3knXBKU/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "AEag8CEYbzNRE1cLkNmQdBzF5dNpWrhv5zfZMR5xWhpA",
            "symbol": "ggsoETH",
            "name": "ggsoETH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEag8CEYbzNRE1cLkNmQdBzF5dNpWrhv5zfZMR5xWhpA/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "Fn4Nz4G6fbK7556XUJQxovYgRjEXgWQYTVj5yCLx2pyv",
            "symbol": "ggstSOL",
            "name": "ggstSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fn4Nz4G6fbK7556XUJQxovYgRjEXgWQYTVj5yCLx2pyv/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "82NfNroRav4h4ecRwcTz46oufQBLRhtvWMJNx9WrzuKd",
            "symbol": "ggscnSOL",
            "name": "ggscnSOL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/82NfNroRav4h4ecRwcTz46oufQBLRhtvWMJNx9WrzuKd/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "CA3oB8EiByQ4yrcexSJDbfGvk1CJrPRcZf2UE14ZRvtx",
            "symbol": "ggSRM",
            "name": "ggSRM",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CA3oB8EiByQ4yrcexSJDbfGvk1CJrPRcZf2UE14ZRvtx/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "AJd15mm73d86ny8worSc8aoLLF6EM1DGPSvi4GscaoQt",
            "symbol": "ggFTT",
            "name": "ggFTT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJd15mm73d86ny8worSc8aoLLF6EM1DGPSvi4GscaoQt/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "GfSggsLj9ZcYcHxanX85d7nas7s1GJPMuDgMCWDhoi7Z",
            "symbol": "ggMNGO",
            "name": "ggMNGO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfSggsLj9ZcYcHxanX85d7nas7s1GJPMuDgMCWDhoi7Z/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "7NF4QChyNo7knUAe5YAXt8JUKLEuHRDgtC5aoVhZyUGx",
            "symbol": "CHEDDAR",
            "name": "Cheddar token ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7NF4QChyNo7knUAe5YAXt8JUKLEuHRDgtC5aoVhZyUGx/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "mon58485x1UPPuE38MCuLbK8dYBz48PSwYudmvyPKJc",
            "symbol": "MONMINT",
            "name": "Mondays Mint Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/oSYcdW4EN3z2L0dtoNzC9paXQ-GXbMyF-XrsCLRqf4M",
            "tags": [
                "Whitelist-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/GrmUcQkEgk",
                "twitter": "https://twitter.com/MondaysNFT",
                "website": "https://mondaysnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "B33DfL1MDvXF2bLGaVyWsqvJEJ17NRHpBrCeQJpjLWBd",
            "symbol": "SKY",
            "name": "SKY CASH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B33DfL1MDvXF2bLGaVyWsqvJEJ17NRHpBrCeQJpjLWBd/logo.png"
        },
        {
            "chainId": 101,
            "address": "99QTDsAikCevpXyAf4o741rWXyfCdG3mbZXtbAiHu6Dw",
            "symbol": "Meta",
            "name": "Fâcebook",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/facebooktoken/zuckerberg/main/logo.png",
            "tags": [
                "Davos-coin",
                "WEF-coin",
                "governance-token",
                "Great-Reset-token",
                "Zuckerberg-coin",
                "Meta-coin",
                "zues369-token",
                "Agartha-token",
                "Facebook-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for The Great Reset W.E.F and Klaus Schwab and for the metaverse you will be happy with nothing only  1usdt = 2 facebook tokens",
                "discord": "https://discord.gg/3yNwFyPzUb",
                "facebook": "https://www.facebook.com/CarlosVerbelen",
                "github": "https://github.com/facebooktoken",
                "instagram": "https://www.instagram.com/moonbootscapital/",
                "linkedin": "https://search.brave.com/search?q=Carlos+Verbelen\u0026source=desktop",
                "medium": "https://joinentre.com/?referredBy=el0nmusk",
                "twitter": "https://twitter.com/dionysus3301",
                "website": "https://linktr.ee/Zues369"
            }
        },
        {
            "chainId": 101,
            "address": "Edc86RmbY2qW4VpvKa8kAxNYoVPGfZ6wfCowojbnfo3",
            "symbol": "BNANAS",
            "name": "Bananas Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Edc86RmbY2qW4VpvKa8kAxNYoVPGfZ6wfCowojbnfo3/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/apefoodnft"
            }
        },
        {
            "chainId": 101,
            "address": "AzrvVcNiVhDaJKMsPTtwC4iYdAGNSqnw4VurVULU1Non",
            "symbol": "BADS",
            "name": "Bad Dog Syndicate",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AzrvVcNiVhDaJKMsPTtwC4iYdAGNSqnw4VurVULU1Non/logo.png"
        },
        {
            "chainId": 101,
            "address": "FLSBhSdzujtrTtuwufuGSDdTZ5iywwXQizyqfmW3igmG",
            "symbol": "NLY",
            "name": "NELOY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/neloy21/neloy21/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "A2TNE7FPEHNxb1VANCzzCEC2LaZqvB3KJtW9wtFEo6d6",
            "symbol": "CCQ",
            "name": "Crypto Conquer",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2TNE7FPEHNxb1VANCzzCEC2LaZqvB3KJtW9wtFEo6d6/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Crypto Conquer is a browser-based strategy P2E game. And CCQ is the utility token of the game.",
                "discord": "https://discord.gg/x8mC3VjqMD",
                "medium": "https://crypto-conquer.medium.com/",
                "twitter": "https://twitter.com/CryptoConquer1",
                "website": "https://crypto-conquer.com/"
            }
        },
        {
            "chainId": 101,
            "address": "ADjyVvJWLds8dEr3c4cXJi642QSFsxr4vzHjpKYv6cJT",
            "symbol": "hiw",
            "name": "hiw",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ADjyVvJWLds8dEr3c4cXJi642QSFsxr4vzHjpKYv6cJT/logo.png",
            "tags": [
                "video-gamer",
                "gamling-games",
                "sol-token"
            ],
            "extensions": {
                "description": "all the best!",
                "discord": "https://discord.gg/UzVWbJcffY",
                "github": "https://github.com/hiw-app/",
                "website": "https://hiw.app/"
            }
        },
        {
            "chainId": 101,
            "address": "5NT5tfMvwr9cnyRHcHZJHFxuDeW2YAoJVU5dATCNVvta",
            "symbol": "ZONA",
            "name": "AriZONA Stablecoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5NT5tfMvwr9cnyRHcHZJHFxuDeW2YAoJVU5dATCNVvta/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/arizonastable",
                "website": "https://arizonastablecoin.org"
            }
        },
        {
            "chainId": 101,
            "address": "898Vy3qq3pMRbAXpznFkYSDv5tK4hrN8ufAUuKkZHMXa",
            "symbol": "ggORCA",
            "name": "ggORCA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/898Vy3qq3pMRbAXpznFkYSDv5tK4hrN8ufAUuKkZHMXa/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "hVjPJDPnHT1FuujEyoj77Y42J9R95P9ePaQCfuhUH1Q",
            "symbol": "MAWL",
            "name": "MrAeyko WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/hVjPJDPnHT1FuujEyoj77Y42J9R95P9ePaQCfuhUH1Q/logo.png"
        },
        {
            "chainId": 101,
            "address": "2ntB5rMBVc3D1TUzeYhxXzXKLCrvkji23rSX6aaCaFyq",
            "symbol": "DAN",
            "name": "Dan Dxcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DanielDerefaka/dxcoin/main/dx3.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6UH4W2kPZp2Zij4uZiqQo47QkB1mToThvyVKrFE6JpZD",
            "symbol": "ODWL",
            "name": "ODKings WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6UH4W2kPZp2Zij4uZiqQo47QkB1mToThvyVKrFE6JpZD/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "77KjqeAQbLuzg5KHP5xobUSBg4GSkFfa489m9ahegrvw",
            "symbol": "SLKT",
            "name": "Kumpagun Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kumpagun/token_img/main/logo.png"
        },
        {
            "chainId": 103,
            "address": "9j4C3i8YfMWnoQGMuxrFijLfzXpss1JncYZqzi4VeKNL",
            "symbol": "VIVI",
            "name": "VIVI",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Extoken/vivi-token-logo/main/vivi-min.png",
            "tags": [
                "vivi-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5idSc21Ht4FTC7jSNe34d6v5FmY8gonswYHpgC7QZCZW",
            "symbol": "PRS",
            "name": "Perseus Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5idSc21Ht4FTC7jSNe34d6v5FmY8gonswYHpgC7QZCZW/logo.png",
            "tags": [
                "perseus",
                "fintech",
                "token",
                "investment",
                "fund"
            ],
            "extensions": {
                "discord": "https://discord.gg/perseusfintech",
                "twitter": "https://twitter.com/perseusfintech",
                "website": "https://perseustoken.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9uwH7hinGD2kSc9PE9cXG3hc3aAAEBbpgzZvEQEWd8yU",
            "symbol": "TRGD",
            "name": "GhostDragon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TRGhostDragon/crypto/main/TRGhostdragon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GFdambb9r51DMu6Hmiz85pupX1o1P5okScgcem7KC5Tp",
            "symbol": "SSLT",
            "name": "Legacy Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFdambb9r51DMu6Hmiz85pupX1o1P5okScgcem7KC5Tp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solanascouts",
                "twitter": "https://twitter.com/SolanaScouts",
                "website": "https://solanascouts.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7mrnhJzpriLSBLe8Ku35MniLg2AUKhPKxv4H7qXAUy1x",
            "symbol": "OST",
            "name": "OST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7mrnhJzpriLSBLe8Ku35MniLg2AUKhPKxv4H7qXAUy1x/logo.png"
        },
        {
            "chainId": 101,
            "address": "8MbxiaDynCMXbkpBYDS69BKz6p5uWJn5djYK6jvz88UA",
            "symbol": "ITC",
            "name": "Inventors Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8MbxiaDynCMXbkpBYDS69BKz6p5uWJn5djYK6jvz88UA/logo.png"
        },
        {
            "chainId": 101,
            "address": "ToJfCKXhxRoAjtXkYdcrRuL6L9cAy2iMpnHx1Kvm91j",
            "symbol": "SRVWL",
            "name": "Sardines Revolt WL Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToJfCKXhxRoAjtXkYdcrRuL6L9cAy2iMpnHx1Kvm91j/logo.jpg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/sardinesrevolt",
                "twitter": "https://twitter.com/SardinesRevolt",
                "website": "https://sardinesrevolt.com"
            }
        },
        {
            "chainId": 101,
            "address": "Fma1X1sawXiwdi5nNLbA6AABmr8mvKFM2AzrxMFastyM",
            "symbol": "SRVFM",
            "name": "Sardines Revolt Free Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fma1X1sawXiwdi5nNLbA6AABmr8mvKFM2AzrxMFastyM/logo.jpg",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/sardinesrevolt",
                "twitter": "https://twitter.com/SardinesRevolt",
                "website": "https://sardinesrevolt.com"
            }
        },
        {
            "chainId": 101,
            "address": "HH6g9wQrYfxTeywuwm2WiMwqs1RDGqXXDSXb1mPjyquv",
            "symbol": "LIGMAWL",
            "name": "LIGMABULLS WL TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HH6g9wQrYfxTeywuwm2WiMwqs1RDGqXXDSXb1mPjyquv/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4ATAC6sV9JdDBXn6VMuAUKEuVxuT19nhKMuAoFv1rpGm",
            "symbol": "AP",
            "name": "AP COIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/amanprak/hello-world/master/logo.png",
            "tags": [
                "social-token",
                "ap"
            ]
        },
        {
            "chainId": 101,
            "address": "7MSyMyUZPiuEVoZVE4uGBMHJTVfkJRnVasKxPA2F8qJJ",
            "symbol": "TSB",
            "name": "The TSB TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7MSyMyUZPiuEVoZVE4uGBMHJTVfkJRnVasKxPA2F8qJJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "TATsdYBQRdCGvpHVxPqcAvo3tAsyjgChhrdPGvRieg5",
            "symbol": "AKEOWL",
            "name": "Akeo Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TATsdYBQRdCGvpHVxPqcAvo3tAsyjgChhrdPGvRieg5/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3YYdtfawNNVGocdazExoCV61zvKP7FJQh7JdKLeoW52i",
            "symbol": "LBRD",
            "name": "LIL BREAD TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LilBreadToken/uploads/main/logo.png",
            "tags": [
                "NFTs",
                "utility-token",
                "Lil-List-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8m9KkHduzyGFRVCpLZGqoHx39XgrxvWysePKucxS2Ccm",
            "symbol": "XPEN",
            "name": "Ned Peruvian Sol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8m9KkHduzyGFRVCpLZGqoHx39XgrxvWysePKucxS2Ccm/logo.png",
            "tags": [
                "currency",
                "stablecoin"
            ],
            "extensions": {
                "website": "https://ned.finance/"
            }
        },
        {
            "chainId": 102,
            "address": "8m9KkHduzyGFRVCpLZGqoHx39XgrxvWysePKucxS2Ccm",
            "symbol": "XPEN",
            "name": "Ned Peruvian Sol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8m9KkHduzyGFRVCpLZGqoHx39XgrxvWysePKucxS2Ccm/logo.png",
            "tags": [
                "currency",
                "stablecoin"
            ],
            "extensions": {
                "website": "https://ned.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "8GXJkHvXM7vifVjpWqNaU2z3LSdLjbVGiGZ9P3XmvXPJ",
            "symbol": "DDC",
            "name": "Didine Coin",
            "decimals": 0,
            "logoURI": "https://i.postimg.cc/q7J0Jqz5/Sans-titre-removebg-preview-1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AoqPs243Hh5LrzVmRamz9pEyiemzTBi5N1b4uiVsfrgU",
            "symbol": "IUX",
            "name": "IUX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AoqPs243Hh5LrzVmRamz9pEyiemzTBi5N1b4uiVsfrgU/logo.png"
        },
        {
            "chainId": 101,
            "address": "3yVqA5Grz3F4cjm3hNPLGLUeYV9nNdK5m7nAyYG7hu6d",
            "symbol": "IVY",
            "name": "IVY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3yVqA5Grz3F4cjm3hNPLGLUeYV9nNdK5m7nAyYG7hu6d/logo.png"
        },
        {
            "chainId": 103,
            "address": "6suwWxyex9tNBy5Pp8reczw6viRNHkQFQeTctXS5EQfo",
            "symbol": "DBC",
            "name": "DBank Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6suwWxyex9tNBy5Pp8reczw6viRNHkQFQeTctXS5EQfo/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://dbank.to/"
            }
        },
        {
            "chainId": 101,
            "address": "EcY4RE86crAWjLbENWQbtnrP76jF4V6Gr9rf3MyuKAX4",
            "symbol": "LB$",
            "name": "Lightning Bolt",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Bhuvana1708/assets/main/images.png"
        },
        {
            "chainId": 101,
            "address": "7fK1TTaswaiu371mJW9yHUeSMgzeAuzmvbyDLeAQEGkY",
            "symbol": "sBOT",
            "name": "Reward Staked BOT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fK1TTaswaiu371mJW9yHUeSMgzeAuzmvbyDLeAQEGkY/logo.png",
            "tags": [
                "utility-token",
                "play-to-earn",
                "game-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Starbots_game",
                "website": "https://starbots.net"
            }
        },
        {
            "chainId": 101,
            "address": "Cerd5qB6jvMTW7YvswVVn3bDrUHWfGKYdUkgmn2M2WeM",
            "symbol": "PHE",
            "name": "PHENOMENON",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cerd5qB6jvMTW7YvswVVn3bDrUHWfGKYdUkgmn2M2WeM/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "kidsxKYSqXg3P3ySuzuzXDRcZvEyA6h1D22vu9xSaqh",
            "symbol": "GKWL",
            "name": "Gooey Kids WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kidsxKYSqXg3P3ySuzuzXDRcZvEyA6h1D22vu9xSaqh/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GooeyKids"
            }
        },
        {
            "chainId": 101,
            "address": "A88nzMeNHiaRKMMGU1Pzd1HgaBJUgzvGZYvDFzJvuTpi",
            "symbol": "NERO",
            "name": "Neronumis",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/xBlackrock/Neronumis/main/Logo.png",
            "tags": [
                "financial-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BMDL7j4okArqyWYmEkpAdWy3HBviRbaz1M3WkrbGib2e",
            "symbol": "BMVDIA",
            "name": "BMV Diamond Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BMDL7j4okArqyWYmEkpAdWy3HBviRbaz1M3WkrbGib2e/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BMV5eEuMtpvNCKa2SHipxNxYMq3HWLWu6RrjaFYwpiRK",
            "symbol": "BMVDEG",
            "name": "BMV Degen Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BMV5eEuMtpvNCKa2SHipxNxYMq3HWLWu6RrjaFYwpiRK/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KattxNYAhfvAMMNrSy1y3wbjojzBGGtv9xxfg1jz7pE",
            "symbol": "KattX",
            "name": "KattX",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KattxNYAhfvAMMNrSy1y3wbjojzBGGtv9xxfg1jz7pE/logo.png",
            "tags": [
                "P2E"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kattx_p2e",
                "website": "https://KattX.io"
            }
        },
        {
            "chainId": 101,
            "address": "88YZ2fR3FdcAGri929QiKcvFLJ9357rYV1UKq8hSK4ca",
            "symbol": "JADScoin",
            "name": "JAD's Apple Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/JADO-artist/JAD-s-Apple-Coin/main/JADS%20Apples%20v2%20300x300%20PNG.png",
            "tags": [
                "utility-token",
                "play-to-earn",
                "game-token",
                "charity-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H5up2BhVFd34Q8jpPHcSUCromQGK5d7HXBSyCXZ8rwXQ",
            "symbol": "RTNKV",
            "name": "Ratunkov",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/rtnkv/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4pmhFQQYVEEe5y6UKSNwMta35LoU1A6MVmDTTZHoRbnE",
            "symbol": "DEGG",
            "name": "Ducky Egg",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pmhFQQYVEEe5y6UKSNwMta35LoU1A6MVmDTTZHoRbnE/logo.png",
            "extensions": {
                "description": "Main currency in the Crypto Ducky Universe",
                "twitter": "https://twitter.com/CryptoDuckyNFT",
                "website": "https://cryptoducky.co/"
            }
        },
        {
            "chainId": 101,
            "address": "XwTZraiF1dVh69cZ2SpqyjDLmei2uVps5CYHD9vqK6d",
            "symbol": "BURR",
            "name": "Burrito Boyz Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/EKuyqK8.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "Burrito-Boyz",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/XwTZraiF1dVh69cZ2SpqyjDLmei2uVps5CYHD9vqK6d"
            }
        },
        {
            "chainId": 103,
            "address": "HakC4nWpLTmQ6TBsR1X2xZmSgH4zA1j5d3GPHgHb3YiY",
            "symbol": "SAPS",
            "name": "SAPS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HakC4nWpLTmQ6TBsR1X2xZmSgH4zA1j5d3GPHgHb3YiY/logo.png"
        },
        {
            "chainId": 101,
            "address": "CYqZ4FG2Jb5Z1CWgdojej9Svhvvo8ohKEAuDvzG3iQ3N",
            "symbol": "DRIP",
            "name": "DripCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Ashtar-Global-Solutions/spl-tokens/main/drip/dripcoin_small.gif",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "discord": "https://www.discord.gg/drippieverse",
                "instagram": "https://www.instagram.com/drippieverse/",
                "twitter": "https://twitter.com/drippieverse",
                "website": "https://www.drippies.space/"
            }
        },
        {
            "chainId": 101,
            "address": "EHaEBhYHWA7HSphorXXosysJem6qF4agccoqDqQKCUge",
            "symbol": "WEYU",
            "name": "WEYU",
            "decimals": 8,
            "logoURI": "https://weyu.io/img/WEYU.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "address": "0xFAfD4CB703B25CB22f43D017e7e0d75FEBc26743",
                "assetContract": "https://bscscan.com/address/0xFAfD4CB703B25CB22f43D017e7e0d75FEBc26743",
                "coingeckoId": "weyu",
                "discord": "https://discord.com/invite/weyu",
                "serumV3Usdc": "4hvgayPdWGdMeQREv4JGGfs83beV9d4biQV8AUhUMQmT",
                "telegram": "https://t.me/WEYUchat",
                "twitter": "https://twitter.com/weyuofficial",
                "website": "https://weyu.io"
            }
        },
        {
            "chainId": 103,
            "address": "FivPj9ujVaWoXvHenMMp193jKQzFztNUeDtHD6MW4DT",
            "symbol": "SHEEP",
            "name": "SHEEP Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FivPj9ujVaWoXvHenMMp193jKQzFztNUeDtHD6MW4DT/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sleepagotchi",
                "twitter": "https://twitter.com/sleepagotchi",
                "website": "https://sleepagotchi.com"
            }
        },
        {
            "chainId": 101,
            "address": "FeAzPEz5MBFJ2gZ8PgydstXr6bq6C9hHPYmKhVNZVps9",
            "symbol": "3MOJIWL",
            "name": "3moji Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeAzPEz5MBFJ2gZ8PgydstXr6bq6C9hHPYmKhVNZVps9/3moji-logo.png",
            "tags": [
                "whitelist-token",
                "3moji"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/3Y9nVzwaBN",
                "instagram": "https://instagram.com/3mojiHQ",
                "twitter": "https://twitter.com/3mojiHQ",
                "website": "https://3moji.app"
            }
        },
        {
            "chainId": 101,
            "address": "BhevmzbDAVs4aPdBBhVZkA7ifQAQ2FwMEK7bW8ggGjiq",
            "symbol": "ARSE",
            "name": "Asses For The Masses",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AssesForTheMasses/nfttoken/main/AFTM.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "2UQ2RTfaTnTXuT5mcUnNm4bgDnCUG6qJwwmPq6e49esP",
            "symbol": "teste",
            "name": "TESTANDO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rtnkv/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8881rZ8Pod31H4u4ReLfnwEZHq5ByPGHXmudEg5UJjXZ",
            "symbol": "808",
            "name": "Section 808",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8881rZ8Pod31H4u4ReLfnwEZHq5ByPGHXmudEg5UJjXZ/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/808section",
                "youtube": "https://www.youtube.com/channel/UCj7ye7sYS9Xa_wOoz-Om0qw"
            }
        },
        {
            "chainId": 101,
            "address": "CKdZ6N2NJynPm7E565ihKaxhW3fRLEGN2VW9Q9dbDe1j",
            "symbol": "CTxWL",
            "name": "Community WL",
            "decimals": 0,
            "logoURI": "https://communityproject.io/assets/images/logo-final.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://communityproject.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4B49SgAWkVBUATcbj5BAV4WZJ3TUY1P2x4mFY5BpKzBP",
            "symbol": "HL",
            "name": "vials",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4B49SgAWkVBUATcbj5BAV4WZJ3TUY1P2x4mFY5BpKzBP/logo.png"
        },
        {
            "chainId": 101,
            "address": "FKvSGUTY4UUkHRKoCFdVtvu7iTgjYuQCuppNMncNZt3q",
            "symbol": "ALU",
            "name": "The Smile syndicate",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FKvSGUTY4UUkHRKoCFdVtvu7iTgjYuQCuppNMncNZt3q/logo.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "twitter": "https://twitter.com/808section",
                "youtube": "https://www.youtube.com/channel/UCj7ye7sYS9Xa_wOoz-Om0qw"
            }
        },
        {
            "chainId": 101,
            "address": "FgninkTPtP8WSgPQx3TtCY6WkPFkMxESatLFVUA28Bz",
            "symbol": "LMQ",
            "name": "lightning mcqueen",
            "decimals": 9,
            "logoURI": "https://github.com/Bhuvana1708/assets/blob/main/lightning-mcqueen-logo-115635113620szlbwmpry.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "viviwYspWBiKrddggUrtwjJJkZnCLwkPDeE3aVkTGwV",
            "symbol": "ViVi",
            "name": "ViVi",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/viviwYspWBiKrddggUrtwjJJkZnCLwkPDeE3aVkTGwV/logo.png"
        },
        {
            "chainId": 103,
            "address": "6ybkEkMNv9NWC4GZMunCkwdFjMFNMcmehBcewYEW4eDh",
            "symbol": "DPP",
            "name": "PokeCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LindleyLentati/token-list/main/assets/mainnet/6ybkEkMNv9NWC4GZMunCkwdFjMFNMcmehBcewYEW4eDh/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3gscwUozHw4GTKVbJVkUJfmziFbMdtdSMQcnri352Vcc",
            "symbol": "LILOG",
            "name": "Lil OG Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LilDAOs/lilthings/main/OG-HLDRS.png",
            "tags": [
                "utility-token",
                "community-token",
                "spl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "E4vVNGSNiD21XEvGuxXkgo2j24HBjLMjuswNqebrg6Xz",
            "symbol": "JKCHA",
            "name": "Jackie Chan Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MikeHawkeCF/Crypto/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "CZERo1zERhXQqGbMvWb9rJEsqNSujymo4UkJuUrkSR86",
            "symbol": "BTC-Z",
            "name": "Bitcoin CarbonZero (dev)",
            "decimals": 10,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/btcz/btc-z.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "bitcoin",
                "description": "Removing Carbon in cryptocurrencies one coin at a time --@cryptolake2",
                "imageUrl": "https://bafybeigchm62vwln6pdy6ausntwbwjbnjkqkgcj6ltdp222n3lnmetrpdy.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/btcz/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmRwfstMcXG28Emuo9eHwvnkeAuQrWanaKx7YPT5YASYa1/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "pLtMXLgfyTsRfZyxnFkJpWqHBxMTvkr4tyMLgyj9wrY",
            "symbol": "PLTMX",
            "name": "PlutoniumX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pLtMXLgfyTsRfZyxnFkJpWqHBxMTvkr4tyMLgyj9wrY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/ttcc",
                "twitter": "https://twitter.com/TimeTravelingCC"
            }
        },
        {
            "chainId": 101,
            "address": "GXPKDRcdwg9X6i8idxFd4bRmkW63dEaeQSJGBVFgXQfF",
            "symbol": "DBC",
            "name": "Domein Bergen Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/domeinbergen/domein/main/logo.png",
            "tags": [
                "community-coin"
            ],
            "extensions": {
                "website": "https://www.domeinbergen.nl/coin/"
            }
        },
        {
            "chainId": 101,
            "address": "8C8YZ1BqU2Lu4wQZcboc7znD6axPdLdU2iTi91nauVo4",
            "symbol": "SHP",
            "name": "ShipHero",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8C8YZ1BqU2Lu4wQZcboc7znD6axPdLdU2iTi91nauVo4/logo.png",
            "tags": [
                "corporate-token",
                "social-token"
            ],
            "extensions": {
                "description": "ShipHero - shipping and logistics software for eCommerce",
                "facebook": "https://www.facebook.com/shiphero",
                "github": "https://github.com/ShipHero",
                "instagram": "https://www.instagram.com/weareshiphero",
                "linkedin": "https://www.linkedin.com/company/shiphero",
                "twitter": "https://twitter.com/weareshiphero",
                "website": "https://shiphero.com/",
                "youtube": "https://www.youtube.com/c/ShipHero"
            }
        },
        {
            "chainId": 101,
            "address": "Aw57FecWU8RBvPXiLbp9oyKpjAeEcCtQHjqVsV2Ln2Bs",
            "symbol": "KCDWL",
            "name": "KUB CAPITAL DAO Whitelist token for Humanoid NFT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aw57FecWU8RBvPXiLbp9oyKpjAeEcCtQHjqVsV2Ln2Bs/logo.png"
        },
        {
            "chainId": 101,
            "address": "7qTJKupfW63phMUH2YHNYQrvXu989n6URZs5wBFA2VFY",
            "symbol": "ANSU",
            "name": "AnsuKoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7qTJKupfW63phMUH2YHNYQrvXu989n6URZs5wBFA2VFY/ansu-logo.svg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SusHi7jkadUEmE7L4CvkNyZroXMDrx1saBFfDSpq6tx",
            "symbol": "$USHI",
            "name": "Suteki Sushi",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SusHi7jkadUEmE7L4CvkNyZroXMDrx1saBFfDSpq6tx/logo.png",
            "tags": [
                "Utility-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Suteki_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "J9pQtMVtsAQ2ZeeEApebC1G2WvRHEWdHCx4J8ZqR2EKx",
            "symbol": "BROT",
            "name": "Bronze Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/tokebronze/crypto/main/logo.png.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WLtxyA4FdeCpMkZEXtr6FxasUyH4E5CEh5kwFmcYywt",
            "symbol": "HGWL",
            "name": "Haunted Goats Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLtxyA4FdeCpMkZEXtr6FxasUyH4E5CEh5kwFmcYywt/logo.png"
        },
        {
            "chainId": 101,
            "address": "4d9PTJ3cV8MPHKtpgDctwbzqb9Fujznc6nw6jF56jtZm",
            "symbol": "NF1",
            "name": "Natisni F1 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/pratcheth/crypto/main/logo1.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "description": "Natisni F1 - Decentralized Cryptocurrency Token going to be used in the new BNT reality",
                "facebook": "https://www.facebook.com/natisnif1",
                "youtube": "https://www.youtube.com/channel/UCmiLnG7aCFBD86Wpn0hJ30Q"
            }
        },
        {
            "chainId": 101,
            "address": "HqmEgqFheNVdXUkQirytWQ8VuvxnBJJDk1Ds6xp4dagU",
            "symbol": "LINX",
            "name": "LINX",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HqmEgqFheNVdXUkQirytWQ8VuvxnBJJDk1Ds6xp4dagU/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "description": "Cyber linx token",
                "twitter": "https://twitter.com/CyberlinxNFT",
                "website": "https://cyber-linx.io"
            }
        },
        {
            "chainId": 101,
            "address": "GGLDx4LZTgz3kMcRpWv3JN2BJkRaZnU8rwG5ANfU3rXw",
            "symbol": "$GGLD",
            "name": "Guild Saga Gold",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGLDx4LZTgz3kMcRpWv3JN2BJkRaZnU8rwG5ANfU3rXw/guild-saga-gold-logo.png",
            "tags": [
                "play-to-earn",
                "gameFi",
                "metaverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/guildsaga",
                "twitter": "https://twitter.com/GuildSaga",
                "website": "https://www.guildsaga.com/",
                "youtube": "https://www.youtube.com/channel/UCHGKEU8ojaRoGe3w_uUi_KQ"
            }
        },
        {
            "chainId": 101,
            "address": "HAriirKUY6fWN9gQrDUFSsAs7drF96rdK6ov8v2hvti1",
            "symbol": "MONWL",
            "name": "Mondays Whitelist Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/M1r2Lpz4jiuXfqbiZkPwoYh6GP_HR2TU6KN2u5E-N2w",
            "tags": [
                "Whitelist-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/GrmUcQkEgk",
                "twitter": "https://twitter.com/MondaysNFT",
                "website": "https://mondaysnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "2qYy33Gef6iwp4t33rtKAJe49v1MWGiPKrbZeiu5kYp7",
            "symbol": "LDSP",
            "name": "Lil DAOs Presale",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/defimill/logo/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "spl-token"
            ]
        },
        {
            "chainId": 103,
            "address": "2zyi3dXdL4JByspLTHui2wkfKfbtemoB1raMx5fFY8sj",
            "symbol": "AMCK",
            "name": "Amcuk Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2zyi3dXdL4JByspLTHui2wkfKfbtemoB1raMx5fFY8sj/logo.png",
            "tags": [
                "amcuk-token"
            ],
            "extensions": {
                "website": "https://nextjssssss.vercel.app/"
            }
        },
        {
            "chainId": 101,
            "address": "2ybz3ehQnePURs5tbxQDAo3TpKArqxdbB5yoABjC5TnP",
            "symbol": "SUWL",
            "name": "Solana Underdogs WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ybz3ehQnePURs5tbxQDAo3TpKArqxdbB5yoABjC5TnP/logo.png"
        },
        {
            "chainId": 101,
            "address": "Bap2Xh2fef8F2u86LAH6LDABGukRMfcmuPoW6zAHH5ew",
            "symbol": "CREATEDBY",
            "name": "CreatedBy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bap2Xh2fef8F2u86LAH6LDABGukRMfcmuPoW6zAHH5ew/logo.png",
            "extensions": {
                "discord": "https://discord.gg/CreatedByIO",
                "twitter": "https://twitter.com/CreatedByIO",
                "website": "https://createdby.io"
            }
        },
        {
            "chainId": 101,
            "address": "roccqR8b2s7srXwWGNpoe31zhThcjeQPKy2ghq2ccn4",
            "symbol": "ROCC",
            "name": "Reels of Change Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/roccqR8b2s7srXwWGNpoe31zhThcjeQPKy2ghq2ccn4/ROCC.PNG",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/reelsofchange",
                "twitter": "https://twitter.com/reelsofchange",
                "website": "https://reelsofchange.com/"
            }
        },
        {
            "chainId": 101,
            "address": "USBrKQa3vh17XpqgLpJSxwGBBVMsBus47VRXbNAnE7R",
            "symbol": "USBWL",
            "name": "Ruggaz USB Key WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/TreeBurgers/TokenIMGs/main/Token%20log.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AKHbkyPVJBQta31kd3AaFNP2vzNKCkAqa113DCi411tt",
            "symbol": "HYWD",
            "name": "YogaWorld Passport MintList",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKHbkyPVJBQta31kd3AaFNP2vzNKCkAqa113DCi411tt/logo.PNG",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/helloyogaworlddao",
                "twitter": "https://twitter.com/helloyogaworld",
                "website": "https://helloyogaworld.com/"
            }
        },
        {
            "chainId": 101,
            "address": "EZYWh5RCBUTny3fVkiqJXfRC2eZ7H4iPTRJK8xEa4Dju",
            "symbol": "HYWD",
            "name": "New Moon 30 May 2022 POAP",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EZYWh5RCBUTny3fVkiqJXfRC2eZ7H4iPTRJK8xEa4Dju/logo.png",
            "tags": [
                "utility-token",
                "proof-of-attendance",
                "30-May-2022"
            ],
            "extensions": {
                "instagram": "https://www.instagram.com/helloyogaworlddao",
                "twitter": "https://twitter.com/helloyogaworld",
                "website": "https://helloyogaworld.com/"
            }
        },
        {
            "chainId": 103,
            "address": "G1vquBd67oRH4UvVvadksCK4fNvqdHGgGzLGrXY4nJGA",
            "symbol": "BADGE",
            "name": "Badge Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G1vquBd67oRH4UvVvadksCK4fNvqdHGgGzLGrXY4nJGA/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "This is a new token"
            }
        },
        {
            "chainId": 101,
            "address": "SutepRbXHCCXLfPHpXWjTdXpdESWw5UL65gCK96KoLq",
            "symbol": "Suteki",
            "name": "SUTEKI WL l Mint 3 June",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SutepRbXHCCXLfPHpXWjTdXpdESWw5UL65gCK96KoLq/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Suteki_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "3yeGqnYLXya7zPbTkEt2d84F489eV9mNia4WQHY3JefA",
            "symbol": "TRB",
            "name": "Trippin' Ape Tribe Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3yeGqnYLXya7zPbTkEt2d84F489eV9mNia4WQHY3JefA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TrippinApeNFT",
                "website": "https://www.trippinapetribe.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "5Em52b2Q72hEpWAuJ44j9L79pQqrx4Ry9AGtziqTNsDi",
            "symbol": "SANN",
            "name": "Sand",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Em52b2Q72hEpWAuJ44j9L79pQqrx4Ry9AGtziqTNsDi/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BytiEsvJAzjaKsyFBVV5BuqowM6k41v6iKYnpx2YmPpa",
            "symbol": "RANN",
            "name": "Rain",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BytiEsvJAzjaKsyFBVV5BuqowM6k41v6iKYnpx2YmPpa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CuwxGp35WX2fN4Q3QR9QYCf9upKxDNx94r2kErXtai9E",
            "symbol": "WINN",
            "name": "Wind",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CuwxGp35WX2fN4Q3QR9QYCf9upKxDNx94r2kErXtai9E/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/LadderCaster",
                "website": "https://laddercaster.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4gRMLjKu38oXEDRJ7zZiUu8hZ3NLEtZtfz5VwF7wdKQz",
            "symbol": "POOCOIN",
            "name": "Poo Coin",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4gRMLjKu38oXEDRJ7zZiUu8hZ3NLEtZtfz5VwF7wdKQz/logo.png",
            "tags": [
                "community-token",
                "meme-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HNohCm8oGNA2qY3MPLz16vEUNfLKV7UXhY2NrhwtJgP2",
            "symbol": "PORTARI",
            "name": "TRANSPORTARI",
            "decimals": 0,
            "logoURI": "https://arweave.net/7Xq547eUy3PnpnxmnGIHSgjo281DoMPsZSwyDnWDEoo?ext=png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BohemiaArtFair"
            }
        },
        {
            "chainId": 101,
            "address": "Dj7qHPhVGa4JTMETZwbrTY1hdfe4TVbk2b46mFpKqb6H",
            "symbol": "BOOT",
            "name": "Bootstrap DAO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dj7qHPhVGa4JTMETZwbrTY1hdfe4TVbk2b46mFpKqb6H/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BootstrapDao"
            }
        },
        {
            "chainId": 101,
            "address": "BSdaqrvjJZ7ekPKtyuhBZuhsGENiQSV4CRGs8Xt3nkDe",
            "symbol": "DOODLE",
            "name": "Doodle Bear Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/SolanaDoodleBears/DOODLE/main/Doodle%20Coin%20Graphic.png"
        },
        {
            "chainId": 103,
            "address": "5sCMLzJVgCAj929oxSoegYxn9SDPaZ2sxEsP5barqktE",
            "symbol": "BOSS",
            "name": "Boss Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/mapys/cs-tokens/master/assets/boss/cs-boss-01.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "This is a test token by MapyS"
            }
        },
        {
            "chainId": 101,
            "address": "FeKpHboWsrVXUNkqXwVZjhMgibDX3aSrqRscCQa2PDuj",
            "symbol": "GRAHAMS",
            "name": "Graham Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/0xMill/uploads/main/logo.png",
            "tags": [
                "utlity-token",
                "family-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GGEMxCsqM74URiXdY46VcaSW73a4yfHfJKrJrUmDVpEF",
            "symbol": "$GGEM",
            "name": "Guild Saga Gems",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGEMxCsqM74URiXdY46VcaSW73a4yfHfJKrJrUmDVpEF/guild-saga-gems-logo.png",
            "tags": [
                "play-to-earn",
                "gameFi",
                "metaverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/guildsaga",
                "twitter": "https://twitter.com/GuildSaga",
                "website": "https://www.guildsaga.com/",
                "youtube": "https://www.youtube.com/channel/UCHGKEU8ojaRoGe3w_uUi_KQ"
            }
        },
        {
            "chainId": 101,
            "address": "AkuJBEHVMceYm2n1rpxDAyzKW9Wr62hSEc2kR4AijvHg",
            "symbol": "$BSC1",
            "name": "BSC1 Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ben941/crpto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4nhQdXfoHvCCVnyZQg3awXqPrKL89Ys7Rbe77oXM47GG",
            "symbol": "AIO",
            "name": "AIO Exchange Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/adminesc/AIO_EXCHANGE_Token/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hat4RZHuSdDGWAwYQkHRzQsKuhtdwJuqugcPz2W4yU5M",
            "symbol": "LVSH",
            "name": "LAVISH",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/PnW1/LavishToken/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "3iEcmKH1FXUGo29Cmt9BjAYWpZyiYDev9jWRcsfjQwiD",
            "symbol": "MLT",
            "name": "Möthlist Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3iEcmKH1FXUGo29Cmt9BjAYWpZyiYDev9jWRcsfjQwiD/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3cwRkFarVySLQJF4yX2aB1EwkCKmtPGujt7Jn5PTRomc",
            "symbol": "AMCUK",
            "name": "Amcuk Token 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3cwRkFarVySLQJF4yX2aB1EwkCKmtPGujt7Jn5PTRomc/logo.png",
            "tags": [
                "amcuk2-token"
            ],
            "extensions": {
                "website": "https://nextjs123-iota.vercel.app/"
            }
        },
        {
            "chainId": 101,
            "address": "Tozqsk9qvvNNeVN1SGEbm2VipDB28JHWrPfHorUjTCu",
            "symbol": "DR",
            "name": "Doperoos WL Token",
            "decimals": 9,
            "logoURI": "https://iili.io/XtcRyX.png",
            "tags": [
                "Whitelist-token"
            ],
            "extensions": {
                "facebook": "https://discord.gg/Doperoos",
                "twitter": "https://twitter.com/Doperoos"
            }
        },
        {
            "chainId": 101,
            "address": "Dyusz2c5DhUSq17jp6uBCVHWwEHVDgk2hyUDSoaTqMxD",
            "symbol": "WMA",
            "name": "Whitelist Mini Arena",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dyusz2c5DhUSq17jp6uBCVHWwEHVDgk2hyUDSoaTqMxD/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7fDeF3F6FJQLFPmvs5ZGzqN34AohBtTRNXbpSiMk3s1f",
            "symbol": "AMEC",
            "name": "Ame Combine",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Ame-Combine/AME-Token/main/AME.png",
            "tags": [
                "social-token",
                "competition-token",
                "gaming-token",
                "science-token",
                "charity-token",
                "Farming-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AkeoLsFzjQ3ZtKXwrGVaa6MSuU7X5wiBX9L5AKAPoFzp",
            "symbol": "AKEO",
            "name": "Akeo Token",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkeoLsFzjQ3ZtKXwrGVaa6MSuU7X5wiBX9L5AKAPoFzp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/teamakeo",
                "twitter": "https://twitter.com/yournfts_co",
                "website": "https://yournfts.co/team-akeo/"
            }
        },
        {
            "chainId": 101,
            "address": "FcbeZknpxoaW48uNayNvEyHFJwhs7f4MMUy3DRDcYCC1",
            "symbol": "CBMN",
            "name": "Cyber Mine Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/cyber-mine/token/main/logo.svg",
            "tags": [
                "utility-token",
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FRPN7LQDwFuxFsGBSxjLXftVBGFG1jNHYcAQYNLp6PDF",
            "symbol": "LCC",
            "name": "LCC Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FRPN7LQDwFuxFsGBSxjLXftVBGFG1jNHYcAQYNLp6PDF/logo512.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://lunarcalendarcelebrities.com"
            }
        },
        {
            "chainId": 101,
            "address": "12mKpWXFrQrozv64KeFnXKKaYSH14JdmRiCVyHcWseB5",
            "symbol": "Bird",
            "name": "BlueBell",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Krisztian7/image/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "54WShMVn3GKf6xNLc55jidj4M8VW1jpmzdvV7MJPX5JK",
            "symbol": "WRLD",
            "name": "Trippy World",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54WShMVn3GKf6xNLc55jidj4M8VW1jpmzdvV7MJPX5JK/logo.png",
            "tags": [
                "utlity-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/trippyworldlabs"
            }
        },
        {
            "chainId": 101,
            "address": "HYE47nodTfX6HgG75XrgUEcaK2ezhvDVURmi5MTswe1J",
            "symbol": "GNIX",
            "name": "Gnixcoin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/grasberg/gnixcoin/main/coinlogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "SaLtectw2FsXhqdsV4fkAV2RCscFM9uBxmWA7R6L5ZS",
            "symbol": "SALT",
            "name": "Salt",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SaLtectw2FsXhqdsV4fkAV2RCscFM9uBxmWA7R6L5ZS/salt.png",
            "tags": [
                "utility-token",
                "community-token",
                "currency"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/presidentialpeanutsnft",
                "twitter": "https://twitter.com/Peanuts_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "HSD3FWjqEbzEAdrWhP5cycjKapG3yVsEAFfAa7VUATTK",
            "symbol": "TBCN",
            "name": "Toros",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Copyrightsworld/cwcoin/main/torocoin-logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "14UMe2amWfXj1CrM7C9kFkTQ6PtX5aT1fdsVGqBZaXCT",
            "symbol": "XMON",
            "name": "XMON",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/14UMe2amWfXj1CrM7C9kFkTQ6PtX5aT1fdsVGqBZaXCT/logo.jpg?raw=true"
        },
        {
            "chainId": 101,
            "address": "DucksMNu1erAbMyLEpE8M1sJqjcjQEkyWZz5GfNhSGmK",
            "symbol": "DCD",
            "name": "Diamond (Ducks Casino)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DucksMNu1erAbMyLEpE8M1sJqjcjQEkyWZz5GfNhSGmK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DucksCasino",
                "twitter": "https://twitter.com/BoosterDucks",
                "website": "https://duckscasino.net"
            }
        },
        {
            "chainId": 103,
            "address": "DucksMNu1erAbMyLEpE8M1sJqjcjQEkyWZz5GfNhSGmK",
            "symbol": "DCD",
            "name": "Diamond (Ducks Casino)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DucksMNu1erAbMyLEpE8M1sJqjcjQEkyWZz5GfNhSGmK/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://devnet.duckscasino.net"
            }
        },
        {
            "chainId": 101,
            "address": "ApRE8SyBaFAppCRHNJRCvjXzQQPnPAzGfYNd3ZgGkTF4",
            "symbol": "CIZ",
            "name": "Collective Investment Zone",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApRE8SyBaFAppCRHNJRCvjXzQQPnPAzGfYNd3ZgGkTF4/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "3Co1nhkhbEA6m5m2Db2gSYB7oZGJ18nnebWdxVCpbeDn",
            "symbol": "BPWL",
            "name": "Bad Penguins WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/BPLOGO.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDEqEpFgTrBawzDgTG1eyH8Go9PX84LCEC8Qjt8T4jFN",
            "symbol": "BOTS",
            "name": "Skullbots Biker Gang Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/HSn9q9n.jpg",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "skullbots-biker-gang",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/HDEqEpFgTrBawzDgTG1eyH8Go9PX84LCEC8Qjt8T4jFN"
            }
        },
        {
            "chainId": 101,
            "address": "8F2d7rKydG5V6bupdCNBQ1L8RVAYS6VGBHcCeu3C14bA",
            "symbol": "MCHA",
            "name": "Mineroschapines",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kalesha666/MCHA-COIN/main/1.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/mineroschapines",
                "website": "https://mineroschapines.space"
            }
        },
        {
            "chainId": 101,
            "address": "ZAWLf8vbJBFxodpzjpARyfiKccxbYPBBsiuFropUD63",
            "symbol": "JUNKY",
            "name": "Junky Bears Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/FreshCats/wes/main/Junky.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HmKT2GJQsfmJU44Xyygzrkey8uzfmCmroFSCdZja8jRD",
            "symbol": "LIS",
            "name": "Lis",
            "decimals": 9,
            "logoURI": "https://github.com/MatheusWells/token-list/blob/main/assets/mainnet/HmKT2GJQsfmJU44Xyygzrkey8uzfmCmroFSCdZja8jRD/logo.png?raw=true",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4MSLQ5EGQTg3KmwcxWaDZYM4vKMbQ7nZYz4FAEDCQdHN",
            "symbol": "Fardin",
            "name": "The King",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4MSLQ5EGQTg3KmwcxWaDZYM4vKMbQ7nZYz4FAEDCQdHN/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solardexofficialchat",
                "twitter": "https://twitter.com/solar_dex",
                "website": "https://solardex.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "4TAratS4ST2QpEFqBvYMNAsA1ZS4WsDyZhawK9w6GkZw",
            "symbol": "KWL",
            "name": "KurumaNFT WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4TAratS4ST2QpEFqBvYMNAsA1ZS4WsDyZhawK9w6GkZw/logo.png"
        },
        {
            "chainId": 101,
            "address": "WLQ7FjhxPnbJgomY5bXUYzFzvJi1qZj58yupo9HQDi5",
            "symbol": "$ATP",
            "name": "Atypical WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WLQ7FjhxPnbJgomY5bXUYzFzvJi1qZj58yupo9HQDi5/logo.png",
            "tags": [
                "Whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FiRE67XRcLmVaHnxo46VAVEtvTto9XZMPCqUvC2JSLef",
            "symbol": "FIRE",
            "name": "Legion Fire",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/SolStonez/Legion-Key-Logo/main/fire-token-logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "A utility token for the Legion of Sol Collection",
                "discord": "https://discord.gg/legionofsol",
                "twitter": "https://twitter.com/legion_of_sol",
                "website": "https://legionofsol.io/"
            }
        },
        {
            "chainId": 101,
            "address": "BQgFm6S7jsmSzGjBZv2FADy4zzeieHoQiySNQRCosgaC",
            "symbol": "ROY",
            "name": "Fully Doxxed",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQgFm6S7jsmSzGjBZv2FADy4zzeieHoQiySNQRCosgaC/logos.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solardexofficialchat",
                "twitter": "https://twitter.com/solar_dex",
                "website": "https://solardex.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "BYCn578MDW8JTSZ5EYeiTkcZNAxMLmhAVi21RMysUxiZ",
            "symbol": "TLP",
            "name": "The Lazy Panda",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYCn578MDW8JTSZ5EYeiTkcZNAxMLmhAVi21RMysUxiZ/logos.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "telegram": "https://t.me/solardexofficialchat",
                "twitter": "https://twitter.com/solar_dex",
                "website": "https://solardex.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "48ntzCLdm9apLbZ8JV5b2s3teMZ1ij1XqokJiqiRYmb3",
            "symbol": "RESTACK",
            "name": "Restack Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/48ntzCLdm9apLbZ8JV5b2s3teMZ1ij1XqokJiqiRYmb3/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://restack.ai"
            }
        },
        {
            "chainId": 101,
            "address": "BoBa5GSvGYDbjHe5FNGQ3dDhNES7z2T9aFG5Gr8qfGqe",
            "symbol": "SUN",
            "name": "NFTabs - SUNSHINE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LinYu1992/nftabs_sunshine_token/main/Sunshine-Token.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nonfungibletabs"
            }
        },
        {
            "chainId": 101,
            "address": "5WCHkLyayksYDk2r8xVhKVie6QT7dv84cwtbCsvJ8gPY",
            "symbol": "BRR",
            "name": "Yik Yak Yetis",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WCHkLyayksYDk2r8xVhKVie6QT7dv84cwtbCsvJ8gPY/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://yikyakyetis.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5nHt8TrjJN9U37WKaCpWftGfsKV91nXwiphJRbMo299p",
            "symbol": "BANDS",
            "name": "BANDS",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5nHt8TrjJN9U37WKaCpWftGfsKV91nXwiphJRbMo299p/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/npuf3zk2uV",
                "twitter": "https://twitter.com/BanditBoysDAO"
            }
        },
        {
            "chainId": 101,
            "address": "BhetEMsWMyDMMgaYzXwGTnd5EyBnrjuPAQFmMzttJAG",
            "symbol": "ONFR",
            "name": "OnFire",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/streetU/streetU/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DVZs9KsqDE",
                "website": "https://travelstones.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "xe6zfMp7qPNTZVogSy3w2Gg4SVfZjC69SFDcKf9zKyp",
            "symbol": "RSB",
            "name": "Renaissance sportive de Berkane",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/streetU/streetU/main/rsb-logo-min.png",
            "tags": [
                "social-token",
                "RSB",
                "Bekrane",
                "fan-token",
                "soccer",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/DVZs9KsqDE",
                "instagram": "https://www.instagram.com/rsbfootball/"
            }
        },
        {
            "chainId": 101,
            "address": "672LxNFXwEwEniFhXQ2P2BMJpz9N54enUcsyJKz9uB3m",
            "symbol": "XVP",
            "name": "Xvegas Vip Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/672LxNFXwEwEniFhXQ2P2BMJpz9N54enUcsyJKz9uB3m/logo.png"
        },
        {
            "chainId": 101,
            "address": "7Rp4Esjx3y7ELQmaGSbzFL4cdgTRFaKmFpHfKbbuCXe2",
            "symbol": "MIZU",
            "name": "Mizushi Dragons Club Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/nintoku/picture/main/mizucoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/mizushiDC",
                "website": "https://www.mizushidc.io"
            }
        },
        {
            "chainId": 101,
            "address": "5H3EHgNgX6vCog2JQwv5LfkhTWLvcd1EP6CSMzym1QSF",
            "symbol": "BLAZE",
            "name": "Investoner Dragons Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/InvestonerD/BLAZE-Icon/main/assets/Blaze%20Icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/d8R3MHrK7B",
                "twitter": "https://twitter.com/InvstonerDragon",
                "website": "https://investonerdragons.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ah78nhRGzsiNNBKfHzrEd9Y4Th5ASv145w8Bw2cJb6PB",
            "symbol": "TGC",
            "name": "Griffin Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/FMC/main/family.png"
        },
        {
            "chainId": 101,
            "address": "8C4thx6WC5doUYy7jbnD9Yq5i414ZtZnL9dCyc5jadB7",
            "symbol": "JBZ",
            "name": "Just Beatz Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/ROYAL/main/royal.jpg"
        },
        {
            "chainId": 101,
            "address": "6AQkpGv2haWpHAnxWimZ2JZDJ8fmFFxq8YP1V2bSuEgn",
            "symbol": "OG420",
            "name": "BluntDAO",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/OG420/main/bluntdao.png"
        },
        {
            "chainId": 101,
            "address": "8jpi3f1ZCa3e4QciqjkrhHMGm2b6F6PHSJVuxW6nx2RG",
            "symbol": "NZD",
            "name": "New Zealand Digital Dollar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CryptoShree/NZD/main/nzdd.png"
        },
        {
            "chainId": 101,
            "address": "AZKb6hEi98PtQTgs8vk7H2Ahhp6cTov7ayiTXJHLjQSa",
            "symbol": "BBCS",
            "name": "Billionaire Bears Club",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/DwightKurtS/Dwights-repository/main/logo.png.png"
        },
        {
            "chainId": 101,
            "address": "9eczmrsLTsA32QTjpNkKoDaFK573NqA4tA7DAgdDSJw9",
            "symbol": "BIBS",
            "name": "BibsCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/holder21/crplogo/main/biblogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2xBa3dBS9rh2P16Piafx79miRB6XdCRrtCzvX1VCDF5K",
            "symbol": "FOKK",
            "name": "Fork You Kwon",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/whatelse114/tokenmoken/main/blackmoon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ADszhGBuRvTcX7QJZvEGKDeCzxbF1EiqVecGYtsaAYy",
            "symbol": "$WOOOF",
            "name": "WOOOF coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ADszhGBuRvTcX7QJZvEGKDeCzxbF1EiqVecGYtsaAYy/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solfriends",
                "instagram": "https://www.instagram.com/solfriendsnft",
                "twitter": "https://twitter.com/SOLFriends",
                "website": "https://solfriendsnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "4qagvcSsN4ovsUsSs1hBh9Qzt5SZHsfNu9BtjQsJyK7W",
            "symbol": "Trippin",
            "name": "Fractionalized Trippin' Ape Tribe -7656",
            "decimals": 2,
            "logoURI": "https://bafybeiavcp2gzugzpl6rqgpynulbjpky4j4d7czugpl4x3prskifhlcc4q.ipfs.nftstorage.link/7656.png?ext=png",
            "tags": [
                "bridgesplit-fraction",
                "trippin-ape-tribe",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/vault/4qagvcSsN4ovsUsSs1hBh9Qzt5SZHsfNu9BtjQsJyK7W"
            }
        },
        {
            "chainId": 101,
            "address": "2UZg26rjTyz8fbZtxxXnuAhfGqc5qNnQ1esxGDuDTwC6",
            "symbol": "MBLOCK",
            "name": "Noneyblock Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/themopixel/moneyblock/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3YcXhmc3eX",
                "instagram": "https://www.instagram.com/MoneyblockNFT",
                "twitter": "https://twitter.com/MoneyblockNFT",
                "website": "https://moneyblock.org"
            }
        },
        {
            "chainId": 101,
            "address": "goatmBAgLyFoHTyyg9NNqGLV4LqTGyjAx1HBn3P1NkD",
            "symbol": "$GOAT",
            "name": "GOAT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/goatmBAgLyFoHTyyg9NNqGLV4LqTGyjAx1HBn3P1NkD/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GreatGoatsNFT",
                "website": "https://greatgoats.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3WA8kxjyBTsfvxdTdmJeRtk1SKWCXYNp6QK4n4j5Zsbg",
            "symbol": "$IMPACT",
            "name": "IMPACT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LexyVolpe/token/main/60kb.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "54RrzWybeyfJUTYgMCifGepyq9PYFMoDqN5Q4spdw35o",
            "symbol": "MECCA",
            "name": "MECCA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/54RrzWybeyfJUTYgMCifGepyq9PYFMoDqN5Q4spdw35o/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/QHzmnNK7Cn",
                "instagram": "https://www.instagram.com/primecyborgsociety/",
                "twitter": "https://twitter.com/primecyborgnft",
                "website": "https://primecyborgsociety.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CjozvA9tbGS6sL4EtcHJ2YN9mTjPs5NsW4EqND1b1UnU",
            "symbol": "KEDO",
            "name": "KING EDO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CjozvA9tbGS6sL4EtcHJ2YN9mTjPs5NsW4EqND1b1UnU/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "NFT-Reward-Token"
            ]
        },
        {
            "chainId": 103,
            "address": "CjozvA9tbGS6sL4EtcHJ2YN9mTjPs5NsW4EqND1b1UnU",
            "symbol": "KEDO",
            "name": "KING EDO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CjozvA9tbGS6sL4EtcHJ2YN9mTjPs5NsW4EqND1b1UnU/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "NFT-Reward-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "CXvk6rH2vKYcPrUrakQA3RxgUzaaEDTjUYxE7NvsqfuE",
            "symbol": "$BSC2",
            "name": "BSC2 Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ben941/dexlab/main/dexlablogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F4Jb7MHFNnuFqUFLWCkYgtJojNTuyKe6PTXp9SrLepmJ",
            "symbol": "TBONE",
            "name": "T-BONE Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/mrcglyn/tbone/main/image%20(2).png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "hqYm3owx1dh1gSyJJmaV8niBMw7fVLg2ZJ6cnqJTSwS",
            "symbol": "CEDAR",
            "name": "CEDAR Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/hqYm3owx1dh1gSyJJmaV8niBMw7fVLg2ZJ6cnqJTSwS/logo.png",
            "tags": [
                "social-token",
                "community-token",
                "NFT-Reward-Token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cedar",
                "twitter": "https://twitter.com/CedarBNPL",
                "website": "https://www.ced.ar/"
            }
        },
        {
            "chainId": 101,
            "address": "6EGvh2GsH2r4XxHVuHFVvodQGMo12jMSRHhm9L9ePSHG",
            "symbol": "TLGT",
            "name": "TalgotPay Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/jemccottry/talgotpaylogo/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9XEqMCMeteQNjeE4w1aCS2c4Ew2XRa1923YkeuC3Kg6q",
            "symbol": "CORK",
            "name": "CorkDAO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XEqMCMeteQNjeE4w1aCS2c4Ew2XRa1923YkeuC3Kg6q/logo.png"
        },
        {
            "chainId": 101,
            "address": "EFErmuMPx6md1Y7u5sCrwyyPSLftLBbtuLspWs27jsuS",
            "symbol": "CLESS",
            "name": "Coinless",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/CLEM-2/crypto/main/dark-rose-logo-illustration_13606-2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7ZzAUhzJkjtxVC2bS9JTJJiH9Xxn63arfpFWLuA6hjz1",
            "symbol": "LPT",
            "name": "LovePortionToken - SK",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/streetU/streetU/main/SPT/logo.png",
            "tags": [
                "love-token",
                "KTR",
                "LKRMN",
                "SM",
                "ZZRL"
            ]
        },
        {
            "chainId": 101,
            "address": "8i3YUt4kw76AbtXCb6hCxR6k3vHTfAWNsuf5QD67XWwm",
            "symbol": "TRUE",
            "name": "True Degenz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8i3YUt4kw76AbtXCb6hCxR6k3vHTfAWNsuf5QD67XWwm/logotrue.png",
            "tags": [
                "community-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.gg/tczqVNxdaw",
                "twitter": "https://twitter.com/TrueDegenz",
                "website": "https://magiceden.io/marketplace/truedegenz/"
            }
        },
        {
            "chainId": 101,
            "address": "ATVMRCJ8CLpfFiQq1S95LPCXKB3XCKM1EdAVeq4zEPq1",
            "symbol": "SAWT",
            "name": "Super Apes Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATVMRCJ8CLpfFiQq1S95LPCXKB3XCKM1EdAVeq4zEPq1/Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/superapeuniversity",
                "twitter": "https://twitter.com/SuperApeUniv",
                "website": "https://superape.university/"
            }
        },
        {
            "chainId": 101,
            "address": "7j2uJRQKgh1z2wnjAZhrYZBqvBkAYZGG7Cu2ageavGem",
            "symbol": "CKSH",
            "name": "CurtKash",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/curtkellum/token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ChRdev5KUUaqySyttxDmfKUnXdcsCSCZhmaVTyL5Yn1q",
            "symbol": "DREG",
            "name": "Deelerz Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChRdev5KUUaqySyttxDmfKUnXdcsCSCZhmaVTyL5Yn1q/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3mL97yd4B9hVFJzQfqXhy5NcgJdzYPhcSvAMCcNabmFF",
            "symbol": "SHRUB",
            "name": "Shrub Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ethankremerdojc/crypto/main/shrub.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DiRHqRqHK7ze2ffBDpLtzswuTYPmWDJh5t2J8bH9AoKr",
            "symbol": "TSG",
            "name": "Trippin Stoned Goats Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DiRHqRqHK7ze2ffBDpLtzswuTYPmWDJh5t2J8bH9AoKr/logo.png",
            "extensions": {
                "discord": "https://discord.gg/8drenSfXde",
                "twitter": "https://twitter.com/TrippinStondeG"
            }
        },
        {
            "chainId": 101,
            "address": "2uHq9w2NnAfrVYWkmPiVx34L7q9AcfPKgFAHAJrPPXLr",
            "symbol": "BRP",
            "name": "Breeding Potion",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MentieMark/token-list/main/assets/mainnet/2uHq9w2NnAfrVYWkmPiVx34L7q9AcfPKgFAHAJrPPXLr/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "NECTLWv5SsQDGbVgSzUA4p5hm4HXkQF8CWkwm9qtUsw",
            "symbol": "NECTR",
            "name": "Saint Skully NECTR",
            "decimals": 9,
            "logoURI": "https://saintskullynft.com/Resources/tokenlogo.png",
            "tags": [
                "utility-token",
                "NFT"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/saintskully",
                "twitter": "https://twitter.com/SaintSkullyNFT",
                "website": "https://saintskullynft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GKZ1pt5vbMfd3zGxpqfjku12Mw1gp7BaM9eGx9V2fc4b",
            "symbol": "JUTSU",
            "name": "JUTSU",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GKZ1pt5vbMfd3zGxpqfjku12Mw1gp7BaM9eGx9V2fc4b/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/susanoo50",
                "website": "https://susanoo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FidHzAe6A1mvjyX3Y9rSZTsp76bGhL7ii9UpdNiJ92Fm",
            "symbol": "POCK",
            "name": "Pocket Money",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/MEHColeman/solana-management/master/images/pocket.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9j7TLzTcdH5UVTB1aSCsM4BvH6u34MmWZQtHxLJF4i4W",
            "symbol": "GOO",
            "name": "Gooey Kids Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Gooey-Kids/gootoken/main/GOOLOGO.png.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GooeyKids"
            }
        },
        {
            "chainId": 101,
            "address": "BfBSte7UXbxNJpSsE2qB18KGq7ucYEYAsgt8bRB8aaKC",
            "symbol": "HSE",
            "name": "House Points",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MEHColeman/solana-management/master/images/house-points.png",
            "tags": [
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G1ZkHFuVCWtdzbWPcdHtNLgcpBT3ajSBNRefMXhDfpof",
            "symbol": "DBT",
            "name": "Dead Bear Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G1ZkHFuVCWtdzbWPcdHtNLgcpBT3ajSBNRefMXhDfpof/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EgGpJgeKbSV1pdDMZW69FvJW4jdvFC5nuMeJQvAYL7z6",
            "symbol": "EGPLNT",
            "name": "Eggplant",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ryisaac/cryptocubs/main/egplnt.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AxKxDxkomutHSoj8Wper6KAvw35tmYtsaALBxLih9VfU",
            "symbol": "SNAXY",
            "name": "SNAXY Token",
            "decimals": 10,
            "logoURI": "https://raw.githubusercontent.com/SNAXUS/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FE4HfAPhoJbGXdQiEbimBZYipcMBJRjGWA5CRHsy8453",
            "symbol": "WHTE",
            "name": "WHITES ELIXIR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FE4HfAPhoJbGXdQiEbimBZYipcMBJRjGWA5CRHsy8453/logo.png",
            "tags": [
                "Utility-Token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/TtjGgkcMyq",
                "twitter": "https://twitter.com/WinterWhitesNFT",
                "website": "https://winterwhitesnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "DMBo73NzTWGrLFFaYCy12h3zPD3zq4GZP4PEWMWBPvft",
            "symbol": "DMB",
            "name": "DEGEN MONKE BUILDERS Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMBo73NzTWGrLFFaYCy12h3zPD3zq4GZP4PEWMWBPvft/logo.png"
        },
        {
            "chainId": 103,
            "address": "cLimizHeLvuHKRrrRMpjnYKChycMA2C7FLRc8ArBtBn",
            "symbol": "/",
            "name": "Climate FireFly Community",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/climfly/climfly.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "Fireflies are shining in sync by parametric resonance, and so can we... we adopt the firefly model to coordonate our actions to restorative climate projects --@cryptolake2",
                "imageUrl": "https://bafybeibicq2hau6ffvql2kpxz77jj4up5g2ho2exfhx5t22wgfd4fe5pi4.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/climfly/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmXha6AQpPas4VBnLT7VWX8k6FiQNbtRzz2nGz3aFh6g3r/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "FcwjMNe8LJny6V6eN9wGD9fTK4BwjZpEYJVC2csreU8g",
            "symbol": "CANDL",
            "name": "Candle Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FcwjMNe8LJny6V6eN9wGD9fTK4BwjZpEYJVC2csreU8g/candle-coin.png",
            "tags": [
                "utility-token",
                "social-token",
                "community-token",
                "rewards-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/chartsmaster1",
                "website": "https://watchlist101.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MeQt5kcmcUD1Zee7GHoHL25RRwP9FwzK6crfJ32Ec7X",
            "symbol": "KUJO",
            "name": "KujoCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/petern21kite/crypto/main/KUJO.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HW3WVuUtkitLWUByL8TfgX5gvBZycfciWgJi3PGSWbfa",
            "symbol": "DBWL",
            "name": "ND Dominion Badges WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kikser1214/Kikser-rep/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Zm5CvezwTE",
                "twitter": "https://twitter.com/NezumiDominion",
                "website": "https://www.nezumidominion.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5wUhVhUphHXVz6CKmAF7MUrFSwv95soo3WQz59ynotyM",
            "symbol": "DCODE",
            "name": "SOL Decoder Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5wUhVhUphHXVz6CKmAF7MUrFSwv95soo3WQz59ynotyM/logo.png",
            "tags": [
                "sol-decoder",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sol-decoder",
                "twitter": "https://twitter.com/SOL_Decoder",
                "website": "https://soldecoder.app"
            }
        },
        {
            "chainId": 101,
            "address": "GrRFEaihH1bKvG3QvuiA8nnbLz4oVwPbwbukwqot26Gr",
            "symbol": "MAXC",
            "name": "MaxCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Tensive8599/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "To7eo6cfLM4y24hRgKmb1ZyArrPCvb2NUa4kiTZGn1L",
            "symbol": "CRCWL",
            "name": "Cyber Raccoons Club WL Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/To7eo6cfLM4y24hRgKmb1ZyArrPCvb2NUa4kiTZGn1L/CRCWL.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/cyberraccoonsclub",
                "twitter": "https://twitter.com/Cyber_Raccoons"
            }
        },
        {
            "chainId": 101,
            "address": "GosCFTFRVSry5EnGY5g2SXAfHhF5htLnuGWe6kcKHGCc",
            "symbol": "SPRT",
            "name": "GLC Spirit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GosCFTFRVSry5EnGY5g2SXAfHhF5htLnuGWe6kcKHGCc/SPIRIT-LOGO.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://minter.ghostlifeclub.com/"
            }
        },
        {
            "chainId": 101,
            "address": "BGXVW4XcsvsRw26m36sTX4dnUHRQidqHT5ruq8Vu7kdB",
            "symbol": "TOOT",
            "name": "Teaching Our Offspring Traits",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PNCG1805/tokens/main/TOOTS.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2RKKXHZhaHhSLKKnfzoEu7LxrrZCgrMwFkJQQRaQDL2Y",
            "symbol": "VyAu",
            "name": "Vy Au LLC",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/YellowLittleTurtle/vyau/main/Logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/profile.php?id=100005127072098/"
            }
        },
        {
            "chainId": 101,
            "address": "c3x8f2dh7Xgs4hUiusYcE7vsFC2CgKFYdeDkS6R5FL5",
            "symbol": "BPWL",
            "name": "Battlepunk Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/c3x8f2dh7Xgs4hUiusYcE7vsFC2CgKFYdeDkS6R5FL5/logo.png",
            "tags": [
                "nft",
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9TiijFqGLwPtfXpmfhD5nSmG6cZyxrDqiMqJtr4hTADH",
            "symbol": "STNK",
            "name": "Stink Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/bentenavery/Stink-Logo/main/Stink.png",
            "tags": [
                "nft",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AoUHZ5GC1oJo4usygkrvEb7izy6KBaHjRpzKfPv3WUn9",
            "symbol": "SHHH",
            "name": "Demon",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AoUHZ5GC1oJo4usygkrvEb7izy6KBaHjRpzKfPv3WUn9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SpeechlessDemo"
            }
        },
        {
            "chainId": 103,
            "address": "cfpsVJTbr5hhnmjmRV9HDm8jWw4kxumgQu74S9USX78",
            "symbol": "tons",
            "name": "Carbon Footprint Standard (dev)",
            "decimals": 12,
            "logoURI": "https://cdn.jsdelivr.net/gh/PurpleZone/NFTs@latest/CFS/cfprint.svg",
            "tags": [
                "green-coin",
                "community-token",
                "utility-token",
                "colored-token"
            ],
            "extensions": {
                "coingeckoId": "polkadot",
                "description": "We want to define a standard for reporting emriession so we avoid double credits --@cryptolake2",
                "imageUrl": "https://bafybeida3n3qqvzyuathjngiqaaepyqec6ex47ngac5to2uzwv7nyv327u.ipfs.nftstorage.link/?ext=svg",
                "linkedin": "https://www.linkedin.com/company/eco-organic-technology",
                "telegram": "https://t.me/cryptolake2",
                "website": "https://PurpleZone.github.io/NFTs/CFS/",
                "whitepaper": "https://gateway.ipfs.io/ipns/QmdpuWueP9Fv1C3FThRNTGxnWyM7orz8rHmQ8L8YvdUxJo/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "FhkAPgX25YZBVTR57HGSbMUVGTt2kMaBPZKRgKbte1Qs",
            "symbol": "PORTARI",
            "name": "TRANSPORTARI II",
            "decimals": 0,
            "logoURI": "https://arweave.net/4FkQpGdO7i2uFuVg_BIt1rnMKoZ0pVvx_BqBzxE5whk?ext=png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BohemiaArtFair"
            }
        },
        {
            "chainId": 101,
            "address": "6RSzbYemvP6GbiECfVP2QmN9E7ZYjtCKQ9kEfyDWk67f",
            "symbol": "GORE",
            "name": "Read2Earn",
            "decimals": 9,
            "logoURI": "https://i.ibb.co/c2fXCZV/gore-logo.png",
            "tags": [
                "Read2Earn",
                "GORE"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SolanaLoops"
            }
        },
        {
            "chainId": 101,
            "address": "SMTyWUTw9gFr9MAs8nebGT7a2L65HzGimgB3Sha4Qwa",
            "symbol": "SlMtsWL",
            "name": "Soul Mates' White List",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SMTyWUTw9gFr9MAs8nebGT7a2L65HzGimgB3Sha4Qwa/logo.png"
        },
        {
            "chainId": 101,
            "address": "7e4c4hi81VBnEnmJyHBa6RaieiqmXzQJ2WWgqqFNe5wY",
            "symbol": "GLI",
            "name": "G Light Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/gintarastamosiunas/Assets/main/Gtokenlogo32x32.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "medium": "https://medium.com/@glight.network",
                "twitter": "https://twitter.com/glight_network",
                "website": "https://glight.network/"
            }
        },
        {
            "chainId": 101,
            "address": "DeDUUQbkbpVWNMrS2djKrgZm8SjGKcH3YEVcE74yejgs",
            "symbol": "AD",
            "name": "AKVO DROP",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/AkvoDrop/Akvo_Drop/main/coin.png",
            "tags": [
                "stablecoin",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CLDnSM5PyjHEHGndxt4WHqa2ehJsnWPcPc4BH5Y9aesB",
            "symbol": "AVTUR",
            "name": "PLANE-X AVTUR",
            "decimals": 9,
            "logoURI": "https://arweave.net/HpdUi39S2ixPus6cU74LeoXaKwWcxezIUTQkvVV9XKs",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/93n7gXEhna",
                "twitter": "https://twitter.com/PlaneX_io",
                "website": "https://plane-x.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GaQ5CaE3b8meA5HAbRbcU61iNNyrHARHBp9qGaQmLbD9",
            "symbol": "CHBGA",
            "name": "CHB GALP (Ray CHB/USDC)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GaQ5CaE3b8meA5HAbRbcU61iNNyrHARHBp9qGaQmLbD9/logo.png",
            "tags": [
                "LP-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Charactbit",
                "website": "https://raydium.io/liquidity/add/?coin0=YtfMZ4jg2ubdz4GasY86iuGjHdo5rCPJnFqgSf8gxAz\u0026coin1=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v\u0026fixed=coin0\u0026ammId=a5sAFsEMzNWZMQViXZWQhg11Wp4QxMS8fCSEYr18Qxi"
            }
        },
        {
            "chainId": 101,
            "address": "DPU4yN7jSA6R4bHp4nMq61AWyXufXHhvvfQaNJnnnEST",
            "symbol": "CANNAHEMP",
            "name": "Cannaverse Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DPU4yN7jSA6R4bHp4nMq61AWyXufXHhvvfQaNJnnnEST/logo.png",
            "tags": [
                "utility-token",
                "governance-token",
                "gaming",
                "NFTs"
            ],
            "extensions": {
                "description": "Cannaverse. Never Smoke Alone!",
                "discord": "https://discord.gg/cannaverse",
                "instagram": "https://www.instagram.com/cannaversenft/",
                "twitter": "https://twitter.com/cannaverse_",
                "website": "https://cannaverse.gg"
            }
        },
        {
            "chainId": 101,
            "address": "Huay6iZ2RWmTnNo86vCKDM2FYeME3tgPq2hLUoymiGfg",
            "symbol": "AAT",
            "name": "ANON",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/alphaanons/assets/main/ANON%20TOKEN.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6a44qufY3eSi6fjFSZix5nw1WxWxDC2gtAZWSj3eaRhE",
            "symbol": "BPM",
            "name": "Beats Per Minute",
            "decimals": 9,
            "logoURI": "https://static.wixstatic.com/media/1bf597_94cc05e168bc4ced8f3ac32c2d599779~mv2.png",
            "tags": [
                "community-token",
                "trading-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9GnyJrLewtJqLRsotkgMpPs5y52jhyAp4L6CZ4fY39oi",
            "symbol": "HEUR",
            "name": "hEURault",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jeuxder4/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "BKCVqw8B2JhnXw1Nv7K8EMa37EeJRoJeyva14HTijUFb",
            "symbol": "AKDC",
            "name": "AK Digital Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/aadharshkannan/netsillyassets/main/ak-dc.png",
            "tags": [
                "community-token",
                "trading-token",
                "utility-token"
            ],
            "extensions": {
                "description": "AK Shopping Contoso",
                "website": "https://damp-atoll-82810.herokuapp.com"
            }
        },
        {
            "chainId": 101,
            "address": "C1WBUPDDuNvFMdwfPRzAhum3HsLUpYEzV78LfZhd6WbJ",
            "symbol": "Coop1",
            "name": "Coop1",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop1.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "7w3wZGWQdEjj2WRj7HevG61rA4hKhTkuNsVusvUunRr4",
            "symbol": "BLOOD",
            "name": "HUM BLOOD",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/7w3wZGWQdEjj2WRj7HevG61rA4hKhTkuNsVusvUunRr4/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "EAv8t3gCJpZ2M45oZNtpURmiis1H9kgUgfod27fJGrxo",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - DappieGang Common",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-Common.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "258rJVdsxBBrtWRjUZqvJ4YZrL6byGbDhSKNkf94wwRA",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - DappieGang Common",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-DappieGang-Common.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "64gXuummCXmQpmCGyufc9zUShSaMgkjTbFyeKeXkBqu2",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - DappieGang LegendaryRobot",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-LegendaryRobot.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "3wdg53Mxs4k82PSrq93yx953ACvB4CdvDwQpzcjxvk8p",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - DappieGang LegendaryRobot",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-LegendaryRobot.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "BF948N21VtSvhT8Vj1yQfm3xiNyf5Q94AF1ghbivaFMe",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - DappieGang LegendaryZombie",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-LegendaryZombie.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "J6TuGUWvpKUHU4EtKvtSdXvR9hSZFcPvV3YcPoeQgppt",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - DappieGang LegendaryZombie",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-DappieGang-LegendaryZombie.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "GiLh8u8dsEkhy6ovZ4wNtgQucuMR1s1mYpkH36yjTMvt",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - DappieGang LegendaryPattern",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-LegendaryPattern.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "HuKUZRierfye1R92g43jXFz2Ed3LjznnZMjRhPLg8u2F",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - DappieGang LegendaryPattern",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-DappieGang-LegendaryPattern.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "Ff1oW6cmB2JJoia8j3GgW5o3LfXgr19PYiEpfnfcrkum",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - DappieGang LegendaryAlien",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-LegendaryAlien.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "EXUcCXy5LKjpCN1Hmy9u8hq7jP4k7Du6cKNtgMEAKYQN",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - DappieGang LegendaryAlien",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-DappieGang-LegendaryAlien.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "FXFxM5wmEh34VNQHkwk2k57ExSB4J84imPxxPXp6dcpk",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - DappieGang Genesis",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTP-DappieGang-Genesis.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "7fjVDDR1HNHz4d38i7B29cPFfFPESNfAze7yU85EyPas",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - DappieGang Genesis",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-DappieGang-Genesis.png",
            "tags": [
                "utility-token",
                "NFTU",
                "DAPG",
                "Dappio"
            ]
        },
        {
            "chainId": 101,
            "address": "8a3NjzFNcXXWRUV243jBs5GwdTSppFbpVo4vDydUtsbB",
            "symbol": "WLTUBBY",
            "name": "Cryptotubbies WL Token",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8a3NjzFNcXXWRUV243jBs5GwdTSppFbpVo4vDydUtsbB/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C2ugNeSLkydYYt79qXZA7jUEaiUCrNuQyeVMUHw56gPx",
            "symbol": "Coop2",
            "name": "Coop2",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop2.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "C6qxybmG1gbWttHBXHgVvxhFnq4TjLm6TyAJT4MNR9pF",
            "symbol": "SNWLP",
            "name": "SolNut WL Pass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/scarceproject/crypto/main/solnutlogo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C3QuMRve5XZMfqJssLmbB4RbQ2tJ3HAcxaHLbWckBnXM",
            "symbol": "Coop3",
            "name": "Coop3",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop3.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "C4ccMsGtaE8SStmphbPBRVLBS2KFfyE1prRYVHmwyxGJ",
            "symbol": "Coop4",
            "name": "Coop4",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop4.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "C5f7KKzxw8x679eG4RTyDomQbpo7pp88ZtejPXtwVhXr",
            "symbol": "Coop5",
            "name": "Coop5",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop5.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "C6HVur5XXfEyfV7T8zh72Qi72f119w3wDurXpQS3Ave7",
            "symbol": "Coop6",
            "name": "Coop6",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop6.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "C7NWtjsboAMU4YjemGpkWT9p4HR6xg14y18A5yduoQGW",
            "symbol": "Coop7",
            "name": "Coop7",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop7.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 103,
            "address": "CVj2x7ZfVtud4ZTdXJFkuynyu3EqqisexMymD9pzaaXb",
            "symbol": "HOTT",
            "name": "Test create new token",
            "decimals": 0,
            "logoURI": "https://github.com/Heorhii90/Surface/blob/master/static/basketbol.png"
        },
        {
            "chainId": 101,
            "address": "wU6JU9RXyrMm8rLBcb8gQPmbaYFVU1xH91WmtqcUnvu",
            "symbol": "$LGD",
            "name": "Urban Legend WLCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/wU6JU9RXyrMm8rLBcb8gQPmbaYFVU1xH91WmtqcUnvu/logo.png",
            "tags": [
                "Whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "boyeMJs9YsLhEYHHQHcnjHLUvm6ez8dxRMD9y3ttWR4",
            "symbol": "BOFYWL",
            "name": "Blood of Yakuza Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/boyeMJs9YsLhEYHHQHcnjHLUvm6ez8dxRMD9y3ttWR4/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3XR1TzVyt5iqQ8Aa3wmjUzSLy61HVnFGL44EVn23Tb9b",
            "symbol": "VIRT",
            "name": "Virtue",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Vince489/VIRT_Image/main/white-bg%2C-no-shadow-designify.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "F5B6zTXkTf332Qbzid6APVxf9Rj3kpoR5Z4LFwzTJ2q1",
            "symbol": "Engine",
            "name": "Engine",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F5B6zTXkTf332Qbzid6APVxf9Rj3kpoR5Z4LFwzTJ2q1/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8xZsRrhcrEFzhW2o9hYGfVbJA9HV6reTgdzc5TeAXVXn",
            "symbol": "NMC",
            "name": "NemeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexneme/NemeCoin/main/nmc.png",
            "tags": [
                "social-token",
                "newera-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3UP1fR9NRPYidmSRaryMwo1wLR2ntaT19kiExbVvA7dP",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - SovanaEggs Legendary",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DappioWonderland/nftu-metadata/main/images/logos/NFTP-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "C9ib14i581Pmm3Axfk8cmh2mVrv43X1W7QRgt5GF6JA2",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - SovanaEggs NonLegendary",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DappioWonderland/nftu-metadata/main/images/logos/NFTP-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "GxvcVeGGPpCnZMskYXmq7JoEvEheP9brayJMMj7HPEZk",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - MonkeyKingdom NormDiamondBaepe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DappioWonderland/nftu-metadata/main/images/logos/NFTP-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "5CBrWhoqPEGJGGsxE3ciAYmXiKZhFhg4BYC75vyb668X",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - MonkeyKingdom NormWukong",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DappioWonderland/nftu-metadata/main/images/logos/NFTP-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "HkSSKQZhisy2ojxrxURsLgj4sbCMwSPfRwe9iCjRta5j",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - MonkeyKingdom RareDiamondBaepe",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DappioWonderland/nftu-metadata/main/images/logos/NFTP-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "FVAJCnSRVSXoRfZVAXYtVFUEHtupzxJKMEGNscwHhVPG",
            "symbol": "NFTP",
            "name": "NFTU Prove Token - MonkeyKingdom RareWukong",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/DappioWonderland/nftu-metadata/main/images/logos/NFTP-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "J3ffpVHxQJCgDWBxH5p9rRh4EcDMEdSWziKiJ1poyWJB",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - SovanaEggs Legendary",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "CAtPAw22tx8pTq5H1C4ZDbKvpzTKg9b1t7C8Bv4uJikr",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - SovanaEggs NonLegendary",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "HyGK4XbxhQC3mw2Sub6XcGvDNQYp3o2PkqPMqSTxTifU",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - MonkeyKingdom NormDiamondBaepe",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "HtDhfAivJjNECiYjXQ7Ae15ap15HjkpHsmTFKabkRCCF",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - MonkeyKingdom NormWukong",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "CKFnaPcANz7Qy7ayoifVYystg4CJQBPH4CF9eQDfNs83",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - MonkeyKingdom RareDiamondBaepe",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "YyVqftwUkPCdr7c5mZJxzLv4UPTtWsGT3EvtxvvoU4f",
            "symbol": "NFTF",
            "name": "NFTU Farm Token - MonkeyKingdom RareWukong",
            "decimals": 0,
            "logoURI": "https://github.com/DappioWonderland/nftu-metadata/raw/main/images/logos/NFTF-General-Small.png",
            "tags": [
                "utility-token",
                "NFTU"
            ]
        },
        {
            "chainId": 101,
            "address": "6XVqqSjq2WPH1hUpP7X52bhdriPob1m9KZgG2REMhZGa",
            "symbol": "DBC",
            "name": "Domein Bergen Coin ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/domeinbergen/dbc/main/logo.png",
            "tags": [
                "community-coin"
            ],
            "extensions": {
                "website": "https://www.domeinbergen.nl/coin/"
            }
        },
        {
            "chainId": 101,
            "address": "HvYf1hTdD177smRDqfFWGByyaa3Cm5viUH3eUyHwDNxg",
            "symbol": "SUSSY",
            "name": "Sussy Cat Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HvYf1hTdD177smRDqfFWGByyaa3Cm5viUH3eUyHwDNxg/logosussy.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DGXtHxKMFhb82RsqihyEHksgWWe3DXBXEvKjz24JruAP",
            "symbol": "WOB",
            "name": "WOB Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/tradefortendies/tokens/master/wobtoken-small.gif",
            "tags": [
                "utlity-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wobbleverse",
                "twitter": "https://twitter.com/Wobblebug",
                "website": "https://www.wobblebug.info"
            }
        },
        {
            "chainId": 101,
            "address": "J3mhAVvPLqGWnnPqxqacpjdXHMvw8W9xTQLGDVXsvXWk",
            "symbol": "SLC",
            "name": "Solaris Coin ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Walanm/solaris-token/main/naodaprasaberainda.png",
            "tags": [
                "community-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "C8heG3mgqJD9intRmmHW2TdrRzq7bpY6C8rdAFTvDs92",
            "symbol": "Coop8",
            "name": "Coop8",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop8.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "C9AzQsLHL5ZaiAKbA6NUSkHBLNb39D728XyhXPAsdX9i",
            "symbol": "Coop9",
            "name": "Coop9",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop9.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "C7711kBpo4GUigL4gPEkzzMoAFWQ7tseyi7vrNn7TkpU",
            "symbol": "Coop77",
            "name": "Coop77",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop77.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "C88ZBBwdwGfN1BmMdGuNpCUrtS8Uh1i2zVFyhctP1j13",
            "symbol": "Coop88",
            "name": "Coop88",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop88.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CAXHfpDjvMMNMBNkQJtRGKhy8rRik7rWVpkjPMwEhb8Z",
            "symbol": "CoopA",
            "name": "CoopA",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopA.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CBZTD4NipeyTmUE1iUSAqXEohvQgoWvxAedgdJRCCYUz",
            "symbol": "CoopB",
            "name": "CoopB",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopB.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CCiQgNvzgB58CB8drzXnS2BeYGFFatLiCnpNra2VY3CD",
            "symbol": "CoopC",
            "name": "CoopC",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopC.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CDJmSZQSADF26A7mZwWXXPr54FP4At67Hnd6GDYfHdpN",
            "symbol": "CoopD",
            "name": "CoopD",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopD.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CEwTat9GvXK5VQR7Wsua86xZapRfftqEZdpDEDedEeRT",
            "symbol": "CoopE",
            "name": "CoopE",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopE.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CFcAr92YJ7v1adUxrM9RuD9JeuuAdECByUuQbU8Jqrkm",
            "symbol": "CoopF",
            "name": "CoopF",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopF.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CGBQmtuJx66qPY6J7LivCjzAouGXSrdAD5Rni1wGnfCa",
            "symbol": "CoopG",
            "name": "CoopG",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopG.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CHk83wcWWrkRDSvAQMqrGBAg53jzzf51er7VUALR6d6o",
            "symbol": "CoopH",
            "name": "CoopH",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopH.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "Ciwo8LtuTLRoYcVP9YiQFLSaRVu3CLKfdWWygNXPGKex",
            "symbol": "CoopI",
            "name": "CoopI",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopI.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CJXVyMT9TTr79bktNijBFBLGvWroZqz6Xjq9jr3CgFss",
            "symbol": "CoopJ",
            "name": "CoopJ",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopJ.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CKyYdbJsJ1gMJCkX3v6TUV5yeQD6q8UjKgV4gxTmhYY",
            "symbol": "CoopK",
            "name": "CoopK",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopK.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CLF375njKDsP4AZLavDTVciHoJdHnjp25k3TU64vF3QU",
            "symbol": "CoopL",
            "name": "CoopL",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopL.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CMWK3ogBj3bevy3cyf8bzFrSmm7uC2oYSA7Dm9vns7Ux",
            "symbol": "CoopM",
            "name": "CoopM",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopM.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CNYQBfnQeM79yDmtNoEuUBo44HAuu7tjY7Y9X8GG7M6d",
            "symbol": "CoopN",
            "name": "CoopN",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopN.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CowyWJ6tH1N15Ta6uxh26iGrSwxvenXEiVbdT8imcg8w",
            "symbol": "CoopO",
            "name": "CoopO",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopO.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CPY2RV4zetHSW9riP84zo7tE36QKeQvfKikhzzfpCUbw",
            "symbol": "CoopP",
            "name": "CoopP",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopP.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CQykKhnPGSWTs8UYnzxj5o43fQp8m7DXYNCD7NPVuQzv",
            "symbol": "CoopQ",
            "name": "CoopQ",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopQ.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CRmsDWECgdLPzWXPJcfcadCopYbWmPGqJEGsYjwy1yAu",
            "symbol": "CoopR",
            "name": "CoopR",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopR.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CSYTyzJipx1PxtTHkyZvhZurJgdZZgy3oNsHvtXf3mSd",
            "symbol": "CoopS",
            "name": "CoopS",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopS.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CTTUQUdARq51Tzb3SdcGrdM6tA95HRnCHjU7FWuMtnTq",
            "symbol": "CoopT",
            "name": "CoopT",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopT.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CUpT1zW1Ri8xNbSKyxkU46E82k3TKdpRqAy3hGgkQ21D",
            "symbol": "CoopU",
            "name": "CoopU",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopU.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CVi6UYVRbsVuiNzs5CTZfMwbcDWYKrBDRSVnFN4s4KTc",
            "symbol": "CoopV",
            "name": "CoopV",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopV.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CWJj2JNANtpka6RcTSDgitLaKgajcjT4d8tBKiNhYsDn",
            "symbol": "CoopW",
            "name": "CoopW",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopW.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CXS8SG1uokQXriMzFjBD1XEyQBf4Ev56etDX5yMmLxXa",
            "symbol": "CoopX",
            "name": "CoopX",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopX.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CYWjFKVhMCNH3bYKr9461X15DuxpNHsTuQmoY3csp7Gk",
            "symbol": "CoopY",
            "name": "CoopY",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopY.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CZy33ScavvvsRL7Tr6wBwB17zuBYHx8bbQUn7pJhD5wQ",
            "symbol": "CoopZ",
            "name": "CoopZ",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopZ.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CBitZh1j9sh3eo9wrEdAD3Zvd25poENGZryghmTWGoNf",
            "symbol": "CoopBit",
            "name": "CoopBit",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopBit.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "Coopxohj8JUx8D7tk4wE9yVtmmHTqofbTscNN8pThbhX",
            "symbol": "CoopCrowd",
            "name": "CoopCrowd",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopCrowd.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CoopRfrqrYzhKo8h9KdhZZ8bkzBfVRMd7YZW5XL2BYiV",
            "symbol": "CoopLife",
            "name": "CoopLife",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopLife.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CpAYxMwTkvbDLnN1uxFCMRCsNN4UMraUrpbM6qiSz3UN",
            "symbol": "CoopPay",
            "name": "CoopPay",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopPay.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "CWLTpcfrBnmn4KfWMuSRRhxJax8pdX4fn7dhooARvhLb",
            "symbol": "CoopWallet",
            "name": "CoopWallet",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/CoopWallet.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "5MBu3PwyitjSmwEJmjEJ2MezX899F3KeWVnc5FeKLPab",
            "symbol": "MLAB",
            "name": "MALUNGO LAB TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5MBu3PwyitjSmwEJmjEJ2MezX899F3KeWVnc5FeKLPab/logo.png",
            "tags": [
                "community-token",
                "Utility-token",
                "NFT-Token",
                "NFT"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MALUNGO_LAB"
            }
        },
        {
            "chainId": 101,
            "address": "ExLjCck16LmtH87hhCAmTk4RWv7getYQeGhLvoEfDLrH",
            "symbol": "SWRD",
            "name": "SWRD",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ExLjCck16LmtH87hhCAmTk4RWv7getYQeGhLvoEfDLrH/logo.png"
        },
        {
            "chainId": 101,
            "address": "Bz9Sk66n3SqfEUNwJWMFAbdmDz3CXBbS9RWbk9qECyFu",
            "symbol": "SPECTRE",
            "name": "SPECTRE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bz9Sk66n3SqfEUNwJWMFAbdmDz3CXBbS9RWbk9qECyFu/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/KGEA8YsH8A",
                "twitter": "https://twitter.com/ghoulies_souls"
            }
        },
        {
            "chainId": 101,
            "address": "8rTEdhm9EPtmgwkePgqnguNNRGyiGkHYvgeAnEE7k1jk",
            "symbol": "MONA",
            "name": "Monaco Protocol Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8rTEdhm9EPtmgwkePgqnguNNRGyiGkHYvgeAnEE7k1jk/logo.png"
        },
        {
            "chainId": 101,
            "address": "FmAugjcE35w4mLbj6i2e3aSnsWSwb5G8ZTPjwVKreSba",
            "symbol": "MONACO",
            "name": "Monaco Protocol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8rTEdhm9EPtmgwkePgqnguNNRGyiGkHYvgeAnEE7k1jk/logo.png"
        },
        {
            "chainId": 101,
            "address": "EG55kTkxsmpJFb6p2qafVC35XJ8Xd1kyJ53xhBNGzeyn",
            "symbol": "FUCK",
            "name": "For Then Solana",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EG55kTkxsmpJFb6p2qafVC35XJ8Xd1kyJ53xhBNGzeyn/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/nftfts",
                "website": "https://ftsolana.com"
            }
        },
        {
            "chainId": 103,
            "address": "HmitieGZtQ7LRmiFBAA66Yd9uv1Wv1B4qkyLWiK2EeJ5",
            "symbol": "WOOD",
            "name": "WOOD - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/5Ns2m2oBWyj7Kz4VgGgenHZA5dUTJ224AbtjUjaDB651/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "3jczzBwwGB8EMjWLi5DKRatFLtFYCSXBKY46NpqX2fPv",
            "symbol": "STONE",
            "name": "STONE - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/2NzAWwLj8GcW3wT9LHSdsyeMTMPCdNp23EAg2YwrLjCz/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "6dZH7pLMqyKPMvgh8zCxPgbrK5kv2EJyqwDFn2tw5i7t",
            "symbol": "IRON",
            "name": "IRON - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/ExKEMV2Z9dSHdRg6AkcFMXLPUqzta7zcyK2UQk3a4soy/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "2VRRsGmeEBp4LyqWqMVtjq9F1yGoQ4SGsrjd4kP49tUQ",
            "symbol": "GRAIN",
            "name": "GRAIN - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/2VRRsGmeEBp4LyqWqMVtjq9F1yGoQ4SGsrjd4kP49tUQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8Mu5tLNnDvkZgyZVpD4h3SLvxbptHwKBMMLuHRQmsjPP",
            "symbol": "RNG",
            "name": "Renegade",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Mu5tLNnDvkZgyZVpD4h3SLvxbptHwKBMMLuHRQmsjPP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://myrenegade.net"
            }
        },
        {
            "chainId": 101,
            "address": "YztUyLrMBQLmQSQAcmxG1B6VKq1JPkzWGcH6ueDFeFp",
            "symbol": "TVS",
            "name": "TheVisionariesWL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YztUyLrMBQLmQSQAcmxG1B6VKq1JPkzWGcH6ueDFeFp/logo.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/WFd3tCV48v",
                "twitter": "https://twitter.com/Visionaries_SOL",
                "website": "https://thevisionaries-solana.com/"
            }
        },
        {
            "chainId": 101,
            "address": "CnDTSapPuQqSV18uohWSJvKF7dvASj1yksMJsMbDTgTJ",
            "symbol": "VIVE",
            "name": "Vive",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CnDTSapPuQqSV18uohWSJvKF7dvASj1yksMJsMbDTgTJ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/d7FYAQuxH3",
                "twitter": "https://twitter.com/ProjectPigeons",
                "website": "https://www.projectpigeon.io"
            }
        },
        {
            "chainId": 101,
            "address": "G6yUqWAUp6p8PvCV9uB1D5iqV2eHm8rQM1vxcJHF8db5",
            "symbol": "BCAP",
            "name": "NukaBots Bottle Cap Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Nes7777/assetslogo/main/logo.png"
        },
        {
            "chainId": 101,
            "address": "A3VPCwZxKUu1KFLBiJqspPyqUTBCEAajoTxMd2tURFtd",
            "symbol": "BOW",
            "name": "Bow Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3VPCwZxKUu1KFLBiJqspPyqUTBCEAajoTxMd2tURFtd/logo.png"
        },
        {
            "chainId": 101,
            "address": "wBUGLCwGFqrP5z8CzKuqcmZtzJdbzni2JckyKQsS4Jz",
            "symbol": "wBUG",
            "name": "Wobblebug WL Token l Mint 9 June",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/wBUGLCwGFqrP5z8CzKuqcmZtzJdbzni2JckyKQsS4Jz/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Wobblebug",
                "website": "https://www.wobblebug.info/"
            }
        },
        {
            "chainId": 101,
            "address": "DDthcF7L3ALPZGk3WKbKEBxhXceTtVXZshD9qSFJKfGd",
            "symbol": "SOUL",
            "name": "SOUL",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/DDthcF7L3ALPZGk3WKbKEBxhXceTtVXZshD9qSFJKfGd/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "YEDif6VtDyJMV3ue4cWSN1f8baZVCScedtXiPoroZFC",
            "symbol": "ITMAFIA",
            "name": "Italian Mafia Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YEDif6VtDyJMV3ue4cWSN1f8baZVCScedtXiPoroZFC/italianmafiatoken.jpg",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "Funny italian mafia token, nothing serious, nothing special."
            }
        },
        {
            "chainId": 101,
            "address": "2XdP7FPDZa6RM6j96VbjxBfzKh2QkKDfENZDd7gocxjG",
            "symbol": "BLOOD",
            "name": "HOM BLOOD",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/2XdP7FPDZa6RM6j96VbjxBfzKh2QkKDfENZDd7gocxjG/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "9d12fy7zmCrhixEXrt4j22yydmAENVPBcr5ZGb3QWSuM",
            "symbol": "LNC",
            "name": "LINCOIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/khairfa/lincoin-logo/main/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "NFT",
                "Meta"
            ],
            "extensions": {
                "website": "https://lincoin.org"
            }
        },
        {
            "chainId": 101,
            "address": "2mTCc7PKM5Sm999ogLUzbxyaKbwrMsGofSZNSk1XdE1h",
            "symbol": "BDTX",
            "name": "Block Duelers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mTCc7PKM5Sm999ogLUzbxyaKbwrMsGofSZNSk1XdE1h/logo.png",
            "tags": [
                "Gaming",
                "NFTs"
            ],
            "extensions": {
                "website": "https://blockduelers.io"
            }
        },
        {
            "chainId": 101,
            "address": "6ABQdaTwRvmacto7aeRBGghS6Pxctd6cFGL8gDdwV1dd",
            "symbol": "XTR",
            "name": "Extra Reality",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ABQdaTwRvmacto7aeRBGghS6Pxctd6cFGL8gDdwV1dd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TRECO_XTR",
                "website": "https://tradecoin.space/"
            }
        },
        {
            "chainId": 101,
            "address": "7fporwTn4bFPmFptdWtJKbKWf8FXWBMRwwB8maTJJTw4",
            "symbol": "CRYGT",
            "name": "Crypto Guru Token",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fporwTn4bFPmFptdWtJKbKWf8FXWBMRwwB8maTJJTw4/logo.png",
            "tags": [
                "social-token",
                "underground-economy",
                "fun-token",
                "digital-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "3FGQFHTXCQ2LcqXMJRzHeU1gUwJfQLvXmv9YNXiqbA4h",
            "symbol": "BOTH",
            "name": "Sereyboth",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FGQFHTXCQ2LcqXMJRzHeU1gUwJfQLvXmv9YNXiqbA4h/logo.png"
        },
        {
            "chainId": 101,
            "address": "C1oKvxgmSFZauwK4s7Y3ohAwQyYsa2qmGkMGvgJdf8TP",
            "symbol": "Coop10",
            "name": "Coop10",
            "decimals": 6,
            "logoURI": "https://cooplife.biz/images/Coop10.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://facebook.com/Coopbit",
                "instagram": "https://www.instagram.com/CoopBits",
                "twitter": "https://twitter.com/Coopbitss",
                "website": "https://coopcrowd.com"
            }
        },
        {
            "chainId": 101,
            "address": "EXCALQoRV4ABi29qJN43G1yCkZKHX9Q6oLN2StFc1vX",
            "symbol": "EXCAL",
            "name": "EXCAL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EXCALQoRV4ABi29qJN43G1yCkZKHX9Q6oLN2StFc1vX/logo.png"
        },
        {
            "chainId": 101,
            "address": "kshrEkxuc7zPAvLxvabxoERKxK6BfariPcjBoiHvM7B",
            "symbol": "SCROLL",
            "name": "StepN Scroll",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kshrEkxuc7zPAvLxvabxoERKxK6BfariPcjBoiHvM7B/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://stepn.guide/"
            }
        },
        {
            "chainId": 101,
            "address": "G9sWx5Mm1dxJr2Sx34CCrwxJi9CBfKZki1Lkm7V1zHnV",
            "symbol": "Noiz",
            "name": "Noiz",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ruginc/token-list/main/assets/mainnet/G9sWx5Mm1dxJr2Sx34CCrwxJi9CBfKZki1Lkm7V1zHnV/noiz.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CorruptedDogs",
                "website": "https://corrupted.dog"
            }
        },
        {
            "chainId": 101,
            "address": "CwHHQVVYFmChNA4ryqBJ32T3m4DhWyz5dsXRayCiyBcg",
            "symbol": "Zeteo",
            "name": "Universal Zetetic Society",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/facebooktoken/zuckerberg/main/logo.png",
            "tags": [
                "governance-token",
                "Great-Reset-token",
                "9th-Baronet-token",
                "NFT-coin",
                "zues369-token",
                "Agartha-token",
                "IHS-token",
                "Ladyblount-token",
                "zeteticus-token",
                "ZionGenG-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for The Great Reset W.E.F for Catholic Zeteticus Societas you will be happy with nothing only  1usdt = 2 Zeteo tokens, Token info follows - Universal Zetetic Society, Founded in New York in Sept. 1873, and in London in Dec 1873, as The Zetetic Society, by Parallax, is now firmly established by E.A.M.B., (Lady Blount), Ed. of The Earth, throughout the civilized world. Many local branches of the organization have been started, during the past five years, in all the principal countries, with the exception of Russia, where The Earth is not allowed to circulate. PRESIDENT: LADY E. A. M. BLOUNT, Vice-PRESIDENT: C. De LACY EVANS, (M.R.C.S., Ph. D etc late Surgeon, Gold Coast Author of Errors of Astronomy ",
                "discord": "https://discord.gg/3yNwFyPzUb",
                "facebook": "https://www.facebook.com/CarlosVerbelen",
                "github": "https://github.com/Universal-Zetetic-Society",
                "instagram": "https://www.instagram.com/moonbootscapital/",
                "linkedin": "https://search.brave.com/search?q=Carlos+Verbelen\u0026source=desktop",
                "medium": "https://joinentre.com/?referredBy=el0nmusk",
                "twitter": "https://twitter.com/dionysus3301",
                "website": "https://linktr.ee/Zues369"
            }
        },
        {
            "chainId": 101,
            "address": "4ihMnPfPCRqHiQHB1pjqX43ZUMoGow9GFobhWgjVa1Wm",
            "symbol": "EYEZ",
            "name": "EYEZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ihMnPfPCRqHiQHB1pjqX43ZUMoGow9GFobhWgjVa1Wm/logoeyez.svg",
            "tags": [
                "eyez"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/solluminati",
                "twitter": "https://twitter.com/SolluminatiNFT",
                "website": "https://solluminati.io"
            }
        },
        {
            "chainId": 101,
            "address": "61vUk1iELKFMbSm3aAd9uzoV7tdHGaTLC5Z7Nuzg1FoX",
            "symbol": "FROG",
            "name": "FROG Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/61vUk1iELKFMbSm3aAd9uzoV7tdHGaTLC5Z7Nuzg1FoX/logo.png"
        },
        {
            "chainId": 101,
            "address": "MWL8k1ESrzD4zWiar7jKfFWZwNn4VSHd9rJgcYMeaui",
            "symbol": "MDWL",
            "name": "Mundus District Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MWL8k1ESrzD4zWiar7jKfFWZwNn4VSHd9rJgcYMeaui/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3v8wVJo4yPqnyKmv44JKYps7ZiBp4CCSwnfSzznEMA1D",
            "symbol": "POINT",
            "name": "Bandwagon NFTs Point Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3v8wVJo4yPqnyKmv44JKYps7ZiBp4CCSwnfSzznEMA1D/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9bS5zhzUEhRKdMS1XReGWQQgwqa9ifPW35qRjnAuidgV",
            "symbol": "CUBEWL",
            "name": "CubeHead Punks WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9bS5zhzUEhRKdMS1XReGWQQgwqa9ifPW35qRjnAuidgV/logo.png"
        },
        {
            "chainId": 101,
            "address": "Bcjd9NYXTfxUoArVc1Pzu6FyXrNCM9nzg2nzdk9Gd9qb",
            "symbol": "IPT",
            "name": "Insurance Protocol Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bcjd9NYXTfxUoArVc1Pzu6FyXrNCM9nzg2nzdk9Gd9qb/logo.png"
        },
        {
            "chainId": 101,
            "address": "C4Vt9yF3G1vLBr1kWH8f4bFZJYTfmDND4sStXERWkT4F",
            "symbol": "LUMDAO",
            "name": "Luminary DAO",
            "decimals": 3,
            "logoURI": "https://github.com/ConsciousEnergy/token-list/tree/main/assets/mainnet/C4Vt9yF3G1vLBr1kWH8f4bFZJYTfmDND4sStXERWkT4F/logo.png",
            "tags": [
                "utility-token",
                "WL-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/AHy4BCAy5C",
                "twitter": "https://twitter.com/luminarydao",
                "website": "https://luminarydao.com"
            }
        },
        {
            "chainId": 101,
            "address": "EKdr6jB38NkDYTM5Gk7aLQLsPXiivj6cc1MzzYNeBfzo",
            "symbol": "IDOL",
            "name": "High-Dol",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/WeedoVerse/IDOL-logo/main/High-Dol.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Hzt7PPmaRJsyh7VLksPMdQDCee32W1Bngp7NYRdMHSkb",
            "symbol": "ACIDAS",
            "name": "ACIDAS Token",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/kargoshi/assets/main/Acid_cap20.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "47d1wFpmfYLFxkMJfNkx1Q1yU7TP1pyu3CtEBENvK7xE",
            "symbol": "RLSC",
            "name": "Royal Leo Silver Coin",
            "decimals": 0,
            "logoURI": "https://www.royalleo.com/src/rlscLogo.png"
        },
        {
            "chainId": 101,
            "address": "3Bcpkr5GGceFDDRGxi6Te6oUhAejfbeheb8v1DVPBpQc",
            "symbol": "RLGC",
            "name": "Royal Leo Gold Coin",
            "decimals": 0,
            "logoURI": "https://www.royalleo.com/src/rlgcLogo.png"
        },
        {
            "chainId": 101,
            "address": "6dmkrp3XjSGyd2kWQCnKtvaufBR4ov7ZDAsrWbGbYTbX",
            "symbol": "CANAR",
            "name": "Canarcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/miguelgargallo/canarchain.org/main/logo/logo.png",
            "tags": [
                "handshake",
                "bitcoin",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/7hPH5U5TH4",
                "medium": "https://penc.medium.com",
                "twitter": "https://twitter.com/canarchain",
                "website": "https://canarchain.org"
            }
        },
        {
            "chainId": 101,
            "address": "wibinXNwn8TyWUfUTxCmknBQk1Dm7Jao3Fve3ptoJgB",
            "symbol": "$WIBIN",
            "name": "WIBIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/wibinXNwn8TyWUfUTxCmknBQk1Dm7Jao3Fve3ptoJgB/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/WibinWolves"
            }
        },
        {
            "chainId": 101,
            "address": "knowbYbddMYPNYPNDERbqk1Kw8VUGpuNWYSQv7RCMSj",
            "symbol": "$KNOW",
            "name": "Knowledge Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/knowbYbddMYPNYPNDERbqk1Kw8VUGpuNWYSQv7RCMSj/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PassroomNFT"
            }
        },
        {
            "chainId": 101,
            "address": "8PFGtQdP6CHeSmMS9mCWgqCAhfQzXWSSTrk5TL8sVLaY",
            "symbol": "SHWL",
            "name": "Silver Hands WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PFGtQdP6CHeSmMS9mCWgqCAhfQzXWSSTrk5TL8sVLaY/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SilverHandsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "8phLV3YLRwud8QohZQnAdKvhjUbte2V75M4wB5uYwPjs",
            "symbol": "SSGWL",
            "name": "Shin Sengoku WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8phLV3YLRwud8QohZQnAdKvhjUbte2V75M4wB5uYwPjs/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/shinsengokuNFT"
            }
        },
        {
            "chainId": 101,
            "address": "5aYEpMuqNAqwtkKNc475JgFkN1pSgt5MkTjYJRZNQjek",
            "symbol": "HDCO",
            "name": "HARDCORES",
            "decimals": 4,
            "logoURI": "http://hardcores.tech/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5nZRjxpFtge4LUCpLLvfVf1NJ7nu3oJXDtcLKw7BQPYx",
            "symbol": "$in$",
            "name": "SinLab Token",
            "decimals": 9,
            "logoURI": "https://github.com/solana-labs/token-list/blob/dc50676181e27aa94f193221b470ac6ab73deaea/assets/mainnet/1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6Byz39E31u7vyLX2JJD9mANUVrdTkmPgfks2RsivtS1H",
            "symbol": "Fame",
            "name": "Famecoin_",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Byz39E31u7vyLX2JJD9mANUVrdTkmPgfks2RsivtS1H/logo.png",
            "tags": [
                "Talent-Token",
                "Sports-Token",
                "Artists-Token",
                "Athletes-Token",
                "Performers-Token",
                "Personalities-Token",
                "Personality-Token",
                "Utility-Tokens",
                "Gaming",
                "NFT"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/profile.php?id=100061613650712",
                "instagram": "https://www.instagram.com/famecoin_",
                "linkedin": "https://www.linkedin.com/in/famecoin-788a4721b/",
                "twitter": "https://twitter.com/famecoin_",
                "website": "https://www.famecoin.com/",
                "youtube": "https://www.youtube.com/user/famecoin"
            }
        },
        {
            "chainId": 103,
            "address": "JEC53jV7dDCd5xVNh28EgK6GjHa1UforPBDMQr6s2fY1",
            "symbol": "RIMOT",
            "name": "RiMoT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/crcnum4/rm-asset/main/assets/logo.png",
            "tags": [
                "beta-token"
            ],
            "extensions": {
                "website": "http://rimot.com"
            }
        },
        {
            "chainId": 101,
            "address": "DUnH17nSFDxvvpTUVLnVMACypDNQ299T4KH61LRfPotz",
            "symbol": "BUSD",
            "name": "Blackrock USD",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/xBlackrock/Blackrock_USD/main/LOGO.png",
            "tags": [
                "financial-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2qyNVSZhh7byyhuxBjoLfcQqNDDVv7PK93UVWQQt88Dc",
            "symbol": "GHGC",
            "name": "GameingHubGameCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/prijak/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8zWqx2bBtx51pbg8ofEU8MHwVEtHygCom5WxHsyTfioc",
            "symbol": "BPML",
            "name": "Battlepunk Mintlist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8zWqx2bBtx51pbg8ofEU8MHwVEtHygCom5WxHsyTfioc/logo.png",
            "tags": [
                "nft",
                "whitelist-token"
            ]
        },
        {
            "chainId": 103,
            "address": "2EVBNgp4JbqZ7iPUbJDu6sgPaxEzUhQo17YB7Vo3s7vG",
            "symbol": "$TENKA",
            "name": "TestTenka",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2EVBNgp4JbqZ7iPUbJDu6sgPaxEzUhQo17YB7Vo3s7vG/logo.png"
        },
        {
            "chainId": 101,
            "address": "F8Wh3zT1ydxPYfQ3p1oo9SCJbjedqDsaC1WaBwh64NHA",
            "symbol": "SSURF",
            "name": "Serum Surfers Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F8Wh3zT1ydxPYfQ3p1oo9SCJbjedqDsaC1WaBwh64NHA/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "k3eMNFATUu36UvXjgN17bDFo9rrBM7oNw8tRgnHDpsL",
            "symbol": "PX_OG",
            "name": "ProjectX OG Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/k3eMNFATUu36UvXjgN17bDFo9rrBM7oNw8tRgnHDpsL/og_ticket.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ProjectX_sol",
                "website": "https://xtcejorp.com"
            }
        },
        {
            "chainId": 101,
            "address": "B4KvbdQYomQJqnfzvjAoaNhWGiJVdSRQWLyCQ3cm74Ts",
            "symbol": "PX_WL",
            "name": "ProjectX WL Ticket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B4KvbdQYomQJqnfzvjAoaNhWGiJVdSRQWLyCQ3cm74Ts/wl_ticket.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ProjectX_sol",
                "website": "https://xtcejorp.com"
            }
        },
        {
            "chainId": 101,
            "address": "5zJ9buoFkmBQBAiE8cnWrtyhAftdGT8ecKzz1LY1ApGV",
            "symbol": "DON$",
            "name": "DONNIEBAGZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DonatelloDaDon/OGDONS/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetaLoDons",
                "website": "https://metalodons-ogs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7R4pJpJdzapj2hEfzQR5bp2ApDjqXNRUA5GTngC6xRZX",
            "symbol": "GB",
            "name": "GameBeef",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7R4pJpJdzapj2hEfzQR5bp2ApDjqXNRUA5GTngC6xRZX/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FUgHqdgCrahWJJVUYLy1wJLj1x6nXNqrLzt5YCcWjibc",
            "symbol": "D0N$",
            "name": "D0NNIE-BAGZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DonatelloDaDon/OGDONS/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetaLoDons",
                "website": "https://metalodons-ogs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "5wA3agdxtgddRoM17iCBPbcdS3KwfL7XQ4NxdV7FvusU",
            "symbol": "WMEOW",
            "name": "WAGMIMEOW",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/BreezyBabieMint/crypto/main/ObBIQopK_400x400.png"
        },
        {
            "chainId": 101,
            "address": "CcCWaUcVVN9qDN6YR4rH9uJa6K22qo1zCLQZxPgcK2fP",
            "symbol": "MOVEFI",
            "name": "Move-n-Earn Governance Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CcCWaUcVVN9qDN6YR4rH9uJa6K22qo1zCLQZxPgcK2fP/logo.png",
            "tags": [
                "governance-token",
                "movefi",
                "move-n-earn"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/profile.php?id=100081109356224",
                "linkedin": "https://www.linkedin.com/company/move-n-earn/",
                "telegram": "https://t.me/movenearn",
                "twitter": "https://twitter.com/move_nearn",
                "website": "https://www.movenearn.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7vVRYg24HvVmgxV4neAog66GjW6aCUwnArvjDn6bgrX5",
            "symbol": "NECTAR2",
            "name": "NECTAR2 Token",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/agave-com/nectar-token/main/logo-sq.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DEVJp3frkYy9D8oSYcgAapHGAD9pLv4c9ssfBFyNZyJ8",
            "symbol": "MKM",
            "name": "MEKAMOUNTS WL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEVJp3frkYy9D8oSYcgAapHGAD9pLv4c9ssfBFyNZyJ8/mkm747-wl.png",
            "tags": [
                "wl-coin-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MEKAMOUNTS",
                "website": "https://discord.gg/mekamounts"
            }
        },
        {
            "chainId": 101,
            "address": "86dwHw2kRkfXWddAJ94zuWvhnKN66tN8nkvZ8LWYNp9g",
            "symbol": "MCLAB",
            "name": "MAD CAT LAB",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/madcatlab01/MAD-CAT-LAB/main/test11.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9EmR4ZiZfSRKxWXEUik6dtyGJ47dXzgDZcq2TinAf2Fy",
            "symbol": "JSC",
            "name": "JUEGA COIN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/juegaseguro/juega-coin/main/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DkH9gvzRCZzCfYynHgPhk1sbeSATTds81UfRJUvqBC3F",
            "symbol": "SHOP",
            "name": "ShopToken",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DkH9gvzRCZzCfYynHgPhk1sbeSATTds81UfRJUvqBC3F/logo.png",
            "tags": [
                "shopping"
            ]
        },
        {
            "chainId": 103,
            "address": "62hW2YrTn73no9WTYjg3Us9BdiFguNwzrhNNLyCEpZV9",
            "symbol": "CTS",
            "name": "CTS Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/wfp2002/solana-Token/main/logo.png",
            "tags": [
                "governance-token",
                "cts",
                "ctsnew"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com",
                "linkedin": "https://www.linkedin.com"
            }
        },
        {
            "chainId": 101,
            "address": "63x2LRcTKVKkZmgq9sse6CytHAjYrEKFVfdMGYRC6QW9",
            "symbol": "HDCN",
            "name": "HDCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dhorvath333/hdcoin-logo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GuLM1PRgJzFGuumTKT7Nkk531R9LXKkbau6bmmZrRZ94",
            "symbol": "MKS",
            "name": "Milkshake",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GuLM1PRgJzFGuumTKT7Nkk531R9LXKkbau6bmmZrRZ94/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "DjrL2ATiHzTg5Rg2EFD24gbV3vLBkeX9Hw29u1KyF15r",
            "symbol": "tUSDC",
            "name": "Tokr USDC",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DjrL2ATiHzTg5Rg2EFD24gbV3vLBkeX9Hw29u1KyF15r/logo.png",
            "extensions": {
                "github": "https://github.com/Tokr-Labs",
                "linkedin": "https://www.linkedin.com/company/tokrlabs/",
                "twitter": "https://twitter.com/tokrlabs",
                "website": "https://www.tokrlabs.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "Db93jCqGGjXXywN4rVAgW1uWdQ4bNAW3qzeyfscv23pK",
            "symbol": "ASR",
            "name": "Asterisk",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hotdogofcoldwar/asterisk/main/logo_800_wh.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "47R68vQesdgEAjJN84x7KCHVhabiNenAFfypudyPyemd",
            "symbol": "GREED",
            "name": "Greed Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/47R68vQesdgEAjJN84x7KCHVhabiNenAFfypudyPyemd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GreedyGhostsDAO",
                "website": "https://greedyghosts.io"
            }
        },
        {
            "chainId": 101,
            "address": "4TbrD8U64HHJPJW7QXvGPUuRc4ekmbXHwF3CcZjxZLLL",
            "symbol": "Fresh",
            "name": "Fresh Fruit Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Palthe/Freshfruit/main/FreshFruit.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5JsuZkXobxwRhtaGH7KNE3yv2RLZuDkExx7q5ypXyKAD",
            "symbol": "CHAC",
            "name": "CHAIR-Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Chair-Guy/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5TuPwHGENJ8ZyvYZB28taMyh1uFUZH4bdFQfTbSxF1vj",
            "symbol": "$CACTI",
            "name": "CACTI TOKEN",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5TuPwHGENJ8ZyvYZB28taMyh1uFUZH4bdFQfTbSxF1vj/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FeVooZDV8ihVhwuHVbagriw6rtonLdysXQ52VwqTaVAd",
            "symbol": "$RYO",
            "name": "Shin Sengoku RYO",
            "decimals": 9,
            "logoURI": "https://storage.googleapis.com/shinsengoku-assets-public/ryo-coin.png",
            "extensions": {
                "twitter": "https://twitter.com/shinsengokuNFT"
            }
        },
        {
            "chainId": 101,
            "address": "WkDp8Wb31GBkP67KPV5hoSprLpqaW8arZ96R3zRrmWi",
            "symbol": "APE",
            "name": "Claim your Bored Apes for Solana",
            "decimals": 0,
            "logoURI": "https://i.imgur.com/zQebtI8.png",
            "tags": [
                "nfts"
            ],
            "extensions": {
                "description": "http://boredapeyachtclubsol.com/. Enter the link and retrieve your Free Ape from Ethereum, now on Solana!",
                "imageUrl": "https://i.imgur.com/zQebtI8.png"
            }
        },
        {
            "chainId": 101,
            "address": "4ECiWwt2g7TB623UAkyKVWBmcGHGzSTg8pxxknVqhz3X",
            "symbol": "NDQF",
            "name": "ND Queen's Favour",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kikser1214/queen/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Zm5CvezwTE",
                "twitter": "https://twitter.com/NezumiDominion",
                "website": "https://www.nezumidominion.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9UQJAKB8F1A2Gcz4RsnothGx5aFSvpRzLCNuGdBF2EPQ",
            "symbol": "SOX",
            "name": "Socks",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Vasil789/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/OkaySocksNFT"
            }
        },
        {
            "chainId": 101,
            "address": "76WzDF99CQZoBjh5wz9WD3LQxVd5Mg2GMENcHRh6wih4",
            "symbol": "MTY",
            "name": "MTY Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/SaviourProject/Mighty-Token-logo/main/logo.png",
            "tags": [
                "Utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "75EjBJtt2To6ThGyzpJor3mrieXHUvRyATvTKdkuMmuH",
            "symbol": "RTRO",
            "name": "Retro",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/josh2212/Retro/main/retro.png",
            "tags": [
                "NFTs",
                "utility-token",
                "currency-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EB3BkQScKDHvBXhoiRJoaYTQLovCExt6sQs8dJBTDgQh",
            "symbol": "ETRK",
            "name": "Entrik",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Huffy519/Entrik-Logo/main/elogo.png",
            "tags": [
                "Utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "8s8kL6Xnry39bBj9JYhsqm9ojYNyp9ywzF23tif86G8h",
            "symbol": "EIGHT",
            "name": "Eighth Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8s8kL6Xnry39bBj9JYhsqm9ojYNyp9ywzF23tif86G8h/logo.png",
            "tags": [
                "eighthcoin",
                "eight"
            ]
        },
        {
            "chainId": 103,
            "address": "C4VgdQfLjMr2oWoFygk3KY6uM8kbPEgGvTgj7FPWZjCy",
            "symbol": "BAAN",
            "name": "BAAN Token",
            "decimals": 9,
            "logoURI": "https://i.postimg.cc/FzTd0ccs/48342a315c92449483b0f4d6e69c177a.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4aYNkmdnNzsbTSkMSVpG28zfWwnqS4GxnY9bcq5BH2km",
            "symbol": "TA",
            "name": "The Assembly - Mayans",
            "decimals": 0,
            "logoURI": "https://cdn.theassembly.gg/mayans/images/985.png?ext=png",
            "tags": [
                "nft"
            ],
            "extensions": {
                "discord": "https://discord.gg/theassembly",
                "twitter": "https://twitter.com/theassemblynft"
            }
        },
        {
            "chainId": 101,
            "address": "B3EyMsa3LfS68e7mDT3PGH7eAPCoHikXxeqQtgBm8tri",
            "symbol": "CMC",
            "name": "ChromosomeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vihaan-s1ngla/ChromosomeCoinLogo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C8uDXHquGdgk3zPKsewSY9JWRcDFXzrMYfqPhe8jNNpM",
            "symbol": "RAKE",
            "name": "RAKE Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C8uDXHquGdgk3zPKsewSY9JWRcDFXzrMYfqPhe8jNNpM/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.enrake.com/RAKE/"
            }
        },
        {
            "chainId": 101,
            "address": "64fe4pZcmwZ776F9az8aUGpxrA4MomFwFava8gE9ZqTh",
            "symbol": "ROCW",
            "name": "ROCW",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/apecrew/assets/main/Grey.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/9e8R48zMUC",
                "twitter": "https://twitter.com/robotcrewnft"
            }
        },
        {
            "chainId": 101,
            "address": "FxbkaJPXVJNBz5MGLyPPnAAsZeAHyj6FvvrM2wq3gQfb",
            "symbol": "TAP",
            "name": "Alien Planet",
            "decimals": 9,
            "logoURI": "https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/FxbkaJPXVJNBz5MGLyPPnAAsZeAHyj6FvvrM2wq3gQfb/logo.png?raw=true"
        },
        {
            "chainId": 101,
            "address": "CTcpr3ZmdSkPp5nqZb7uPUu4RRUzoEgtpFjTFVWrYLGq",
            "symbol": "$NERC",
            "name": "NERCOIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jacktheminter/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ingaAW6YRGVFa4NASZFi9TEhxQcuRfGumGLMVxRSav9",
            "symbol": "IGTY",
            "name": "Ingenuity Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ingaAW6YRGVFa4NASZFi9TEhxQcuRfGumGLMVxRSav9/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5hWAkBVZoMq7pGc4k9HsDhfe8cp3WJmTj7iFTqBdv5sv",
            "symbol": "MONEY",
            "name": "Moneyblock's Official Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Camelspit/cryptocoin/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3YcXhmc3eX",
                "instagram": "https://instagram.com/MoneyblockNFT",
                "twitter": "https://twitter.com/MoneyblockNFT",
                "website": "https://moneyblock.org"
            }
        },
        {
            "chainId": 101,
            "address": "fV4L2AkdDDzBGMhMHUJWgXNx5EnEVoUKnAsLfZvHLzp",
            "symbol": "KHRYSOS",
            "name": "Khrysos coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/fV4L2AkdDDzBGMhMHUJWgXNx5EnEVoUKnAsLfZvHLzp/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/solinos",
                "twitter": "https://twitter.com/SolinosEC",
                "website": "https://solinos.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "EZANpsohLdDWTT8xrQbYBwrVSz6bg31qRycMw6TTr3bp",
            "symbol": "DWT",
            "name": "DeFrame WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EZANpsohLdDWTT8xrQbYBwrVSz6bg31qRycMw6TTr3bp/logo.png",
            "tags": [
                "NFT",
                "wl-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DeFrameOfficial",
                "website": "https://www.deframe.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "GavGtgnCW8XnsVDbczD9BNo41iaUdqaMKZTbriCnZ2Xa",
            "symbol": "$CTT",
            "name": "Caspian Tiger",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/ArifMammadov/ctt-logo/main/logo%20(1).png",
            "tags": [
                "CaspianTiger"
            ]
        },
        {
            "chainId": 101,
            "address": "AaSk75oPCsbrgceKVNUvBWhC7NWNfSwNXPRmw7ZigYU3",
            "symbol": "MONEY",
            "name": "Moneyblock Official Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Mopixelz/crypto/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3YcXhmc3eX",
                "instagram": "https://instagram.com/MoneyblockNFT",
                "twitter": "https://twitter.com/MoneyblockNFT",
                "website": "https://moneyblock.org"
            }
        },
        {
            "chainId": 101,
            "address": "HwtcNv5rE6djJUw35FzMUWPSALhC54XGDriwyMxjEUDB",
            "symbol": "SKEY",
            "name": "Skey",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SkeyGitHub/skey/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/ZpWDwytKxT"
            }
        },
        {
            "chainId": 103,
            "address": "95scnhfHszv2e1yxtnNuLi9CGi1BvtdpcgLeymdDQRP",
            "symbol": "FLOUR",
            "name": "FLOUR - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/95scnhfHszv2e1yxtnNuLi9CGi1BvtdpcgLeymdDQRP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 103,
            "address": "E8ikFWZAo6kiGrsHghUTn3ysfpKVJqyGJfXC2q75fa1C",
            "symbol": "BREAD",
            "name": "BREAD - Metaversium",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/dwrx/token-list/main/assets/mainnet/E8ikFWZAo6kiGrsHghUTn3ysfpKVJqyGJfXC2q75fa1C/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/metaversiumgame",
                "website": "https://metaversium.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6uPNk3w4zne1hUMuqogZt4VxMu77rcuEr2CUfms7Faz3",
            "symbol": "SUGA",
            "name": "Sugar Realm Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uPNk3w4zne1hUMuqogZt4VxMu77rcuEr2CUfms7Faz3/SUGA.png",
            "tags": [
                "SUGA",
                "sugar_realm"
            ],
            "extensions": {
                "discord": "https://discord.gg/sugarrealm",
                "medium": "https://medium.com/@sugarrealmnft",
                "twitter": "https://twitter.com/SugarRealmNFT",
                "website": "https://sugarrealm.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2KLRorQP7aWRGFnKfWQK7yS9cAcJVZPSmMdMpqPkivFY",
            "symbol": "BJBC",
            "name": "Brandon Jacobson Business Card",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/IvaldiS6/image/main/BJBC_Logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "linkedin": "https://www.linkedin.com/in/brandon-jacobson-89588617b/",
                "twitter": "https://twitter.com/SecurePy",
                "website": "https://github.com/IvaldiS6"
            }
        },
        {
            "chainId": 101,
            "address": "woLFbe6J43ZPdb2nZ3GRzVEV5153HsZB1kgZYf2ePwF",
            "symbol": "LWWL",
            "name": "Lost Wolves WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/woLFbe6J43ZPdb2nZ3GRzVEV5153HsZB1kgZYf2ePwF/logo.png",
            "tags": [
                "nft",
                "WL-token",
                "LostWolves"
            ],
            "extensions": {
                "discord": "https://discord.gg/lostwolves",
                "twitter": "https://twitter.com/LostWolvesNFT",
                "website": "https://lostwolves.io/"
            }
        },
        {
            "chainId": 101,
            "address": "GAsp3xeG4LQLjyJnT7ochh9gKaF17c7ssTjjPS8B1mQv",
            "symbol": "ggRAY",
            "name": "ggRAY",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GAsp3xeG4LQLjyJnT7ochh9gKaF17c7ssTjjPS8B1mQv/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/SgKffK9X76",
                "twitter": "https://twitter.com/goblingold_fi",
                "website": "https://goblin.gold"
            }
        },
        {
            "chainId": 101,
            "address": "DTPMARh15YSqggNbMLECj8RxVoxfhtobyyCLiwEeVwZu",
            "symbol": "DTPM",
            "name": "Degen Trash Panda Merch Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/jEVnuhOv79oTVfksVmlJhc7E1GsLgeXM8_7aAp1TjiY",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/4wH2dZbDH2",
                "twitter": "https://twitter.com/DegenTrashPanda",
                "website": "https://degentrashpandas.com"
            }
        },
        {
            "chainId": 101,
            "address": "rogue3qZEABqmjpL9orMosdBh2a1rfmpoDKfPrztG1K",
            "symbol": "ROGUE",
            "name": "ROGUE Token",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rogue3qZEABqmjpL9orMosdBh2a1rfmpoDKfPrztG1K/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/roguesharks",
                "twitter": "https://twitter.com/RogueSharks",
                "website": "https://www.roguesharks.org/"
            }
        },
        {
            "chainId": 101,
            "address": "To6JP3WdnzttxLJzjE3dtxEQ7V5j67tffRPW6UYngZn",
            "symbol": "TG",
            "name": "Tokyo Glitch Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/To6JP3WdnzttxLJzjE3dtxEQ7V5j67tffRPW6UYngZn/logo.png"
        },
        {
            "chainId": 101,
            "address": "MARKh1FM3eWqtuAzUytgoLaVcKNo5iRJLK7kvvfmwY6",
            "symbol": "$MARK",
            "name": "Mark Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MARKh1FM3eWqtuAzUytgoLaVcKNo5iRJLK7kvvfmwY6/logo.png"
        },
        {
            "chainId": 101,
            "address": "BEERyhYPMdofQP4dLrw3LN5DtU536KzWonhHbYSr7Fbp",
            "symbol": "$BEER",
            "name": "Red Panda Party Club Beer Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BEERyhYPMdofQP4dLrw3LN5DtU536KzWonhHbYSr7Fbp/logo.png"
        },
        {
            "chainId": 101,
            "address": "541xHAw1xSH6aCUBDJR3zcgBBbedBm2LU3yoqqhGvVA",
            "symbol": "SLVR",
            "name": "Silver Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/541xHAw1xSH6aCUBDJR3zcgBBbedBm2LU3yoqqhGvVA/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SilverHandsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "C4CQ4wk96FrBiUFcPGQoZ9hHYd82gRT9uUQprYmb6aJF",
            "symbol": "SOLOT",
            "name": "Solot ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C4CQ4wk96FrBiUFcPGQoZ9hHYd82gRT9uUQprYmb6aJF/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/wxe2vRaVZp",
                "twitter": "https://twitter.com/solotmachinehd"
            }
        },
        {
            "chainId": 101,
            "address": "5VgCcY21XsrCgAPqdKtii3ryFcoyyaARuFcSkF5mEi4j",
            "symbol": "RL",
            "name": "Red Lion",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/redhorn1991/RedHornCrypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "K1EFss6frvERxWBF3qBmuApG1qFBA8GVXyi2viXGqXK",
            "symbol": "$KIEF",
            "name": "KIEF",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/K1EFss6frvERxWBF3qBmuApG1qFBA8GVXyi2viXGqXK/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/tstonedfrogz",
                "twitter": "https://twitter.com/TStonedFrogz",
                "website": "https://tstonedfrogz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "kozyGtC1y9hRDhvwiLDyvxdREQUyjfjMjFSBTnE492T",
            "symbol": "KOZY",
            "name": "Kozy Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kozyGtC1y9hRDhvwiLDyvxdREQUyjfjMjFSBTnE492T/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KozyKoalasNFT"
            }
        },
        {
            "chainId": 101,
            "address": "ReEfAp8QkiW3J7JaRJpNWvrJKWY77t4f4UuBKQ5Kpvu",
            "symbol": "$REEFER",
            "name": "REEFER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ReEfAp8QkiW3J7JaRJpNWvrJKWY77t4f4UuBKQ5Kpvu/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MadOnReefer",
                "website": "https://madonreefer.com/"
            }
        },
        {
            "chainId": 103,
            "address": "BRVzbeeF2tufboGFQGgXsD6CCP2NVddCJ6cUz6VbjK8o",
            "symbol": "HLT",
            "name": "Healthy Life Token",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRVzbeeF2tufboGFQGgXsD6CCP2NVddCJ6cUz6VbjK8o/HLTCoin.png",
            "tags": [
                "stepogram-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6nyiZ7uHKPHRhZa6nw2eBS4njxStM6kmEUr6uLFhJz9Z",
            "symbol": "PCS",
            "name": "Precious Jewelry",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6nyiZ7uHKPHRhZa6nw2eBS4njxStM6kmEUr6uLFhJz9Z/pcs_ico.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://precious.capital/"
            }
        },
        {
            "chainId": 101,
            "address": "6CKXpW4KAiAJt9Exj9nneqS2vyFqj8ABRMvXyYhoa2M9",
            "symbol": "WOW",
            "name": "WOWswap Token (Portal)",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6CKXpW4KAiAJt9Exj9nneqS2vyFqj8ABRMvXyYhoa2M9/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "address": "0x4da996c5fe84755c80e108cf96fe705174c5e36a",
                "assetContract": "https://bscscan.com/address/0x4da996c5fe84755c80e108cf96fe705174c5e36a",
                "bridgeContract": "https://bscscan.com/address/0xb6f6d86a8f9879a9c87f643768d9efc38c1da6e7",
                "coingeckoId": "wowswap",
                "coinmarketcap": "https://coinmarketcap.com/ru/currencies/wowswap/",
                "description": "WOWswap is decentralized leverage trading \u0026 lending protocol that runs on Ethereum, BNB Chain, Avalanche, IoTeX, Solana and Polygon.",
                "discord": "https://discord.gg/KFQa6pECmY",
                "medium": "https://wowswap-io.medium.com/",
                "reddit": "https://www.reddit.com/r/Wowswap_io/",
                "telegram": "https://t.me/wowswap",
                "twitter": "https://twitter.com/Wowswap_io",
                "website": "https://wowswap.io/"
            }
        },
        {
            "chainId": 101,
            "address": "3TsqAMYmuRX1ct14GQbN5Unu3J1UUJawHFDtEu8notMe",
            "symbol": "NOTME",
            "name": "Notme Or You",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/server.png",
            "tags": [
                "whitelist-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6LetM1yRt8zPtyZdsRfdy7EPYPhZhs1ojzDqgPPPv3Wa",
            "symbol": "MONEY",
            "name": "Moneyblock",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kryptoknife/crypto/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/3YcXhmc3eX",
                "instagram": "https://instagram.com/MoneyblockNFT",
                "twitter": "https://twitter.com/MoneyblockNFT",
                "website": "https://moneyblock.org"
            }
        },
        {
            "chainId": 101,
            "address": "CZxkoQ6rjc7bAm3ZZekaRmtbtb5bDNLc67HCbe4zHKT3",
            "symbol": "CONGA",
            "name": "CONGA COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/congaFX/token/main/CongaFX%20Icon.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ELo1g1ZxbYenka1dKgn9Hr4TWY7UZVw4iPb4BaYu64Ve",
            "symbol": "QANON",
            "name": "QAnon Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELo1g1ZxbYenka1dKgn9Hr4TWY7UZVw4iPb4BaYu64Ve/logo.svg"
        },
        {
            "chainId": 101,
            "address": "3wBnQPk4CgaCXWDSGxKFYiKhRznzyCBnsLNeoLmMkVwk",
            "symbol": "SHOE",
            "name": "Horsemen Society",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Horsemen369/shoelog/main/shoelogo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/HorsemenNft"
            }
        },
        {
            "chainId": 101,
            "address": "F9acm8phUVEtnUW9XFNs1E6qX3tM5owipeJ6UKf4j8Zc",
            "symbol": "MCS",
            "name": "Melvins Cool Shit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Hekko90/MTSK/main/Melvins%20Cool%20Shit.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DGPw3zcZuMxoVmnEQA9uhZDn9Tg2ynyaBrbULRQ8y1Gh",
            "symbol": "DEGEPASS",
            "name": "degenerative multipass",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DGPw3zcZuMxoVmnEQA9uhZDn9Tg2ynyaBrbULRQ8y1Gh/logo.png",
            "tags": [
                "security-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/A3PeauVFTr",
                "twitter": "https://twitter.com/degenespace",
                "website": "https://degenerative.space/"
            }
        },
        {
            "chainId": 101,
            "address": "kGFcaA8Xcyr9BtusTMHXJHvsBe6mU5yTb4hbhA6EnXA",
            "symbol": "$NATU",
            "name": "NATU Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kGFcaA8Xcyr9BtusTMHXJHvsBe6mU5yTb4hbhA6EnXA/logo_natu.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/nahavi",
                "twitter": "https://twitter.com/WanderingNahavi",
                "website": "https://nahavi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "2TyHNzQQqoytGnznVnFbFvg8qQf9ASeaPW9TzNxhC3GF",
            "symbol": "DRACCOIN",
            "name": "Draconic",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/furfibre/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B8WQQJ5uBXqGFYbdHhX6Y4YWxXHBtAFEL7YriKtyHH8o",
            "symbol": "MANI",
            "name": "Mania Token",
            "decimals": 9,
            "logoURI": "https://resource-branding-assets.s3.us-west-2.amazonaws.com/sponsor_communi3/nft_mint_token_img.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/communi3"
            }
        },
        {
            "chainId": 101,
            "address": "J5kquaWo4zCzfwtiec8ZCyW9XjpzqgD5cPV4A7KLCNRh",
            "symbol": "HYWDFB",
            "name": "First Breath",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J5kquaWo4zCzfwtiec8ZCyW9XjpzqgD5cPV4A7KLCNRh/logo.png",
            "tags": [
                "utility-token",
                "proof-of-attendance"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/helloyogaworld",
                "instagram": "https://www.instagram.com/helloyogaworlddao",
                "twitter": "https://twitter.com/helloyogaworld",
                "website": "https://helloyogaworld.com/"
            }
        },
        {
            "chainId": 101,
            "address": "77WM3FzFJKMAzgGptDK2dFSmBKfSDjt6fFAeFZZqgHUd",
            "symbol": "HYWDKA",
            "name": "22 06 11 Global Flow 001 Kenya Africa",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/77WM3FzFJKMAzgGptDK2dFSmBKfSDjt6fFAeFZZqgHUd/logo.png",
            "tags": [
                "utility-token",
                "proof-of-attendance"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/helloyogaworld",
                "instagram": "https://www.instagram.com/helloyogaworlddao",
                "twitter": "https://twitter.com/helloyogaworld",
                "website": "https://helloyogaworld.com/"
            }
        },
        {
            "chainId": 101,
            "address": "GLRD5VsCsKr4E1h22PFgfgP6pYaxv3o1doRnSeTWtw1v",
            "symbol": "RGTT",
            "name": "Rug-Tug Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/RandomHorizon/Rug-Tug-logo/main/rugtuglogo.png",
            "tags": [
                "Social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DwY5Ks3KbvfVMd4jA1gELokSSAru6xaVNrCQsGoQAMES",
            "symbol": "ANGK",
            "name": "AngkorCoin",
            "decimals": 0,
            "logoURI": "https://github.com/tiloukim/AngkorCoin/blob/main/ANGKLOGO.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "facebook": "https://www.facebook.com/angkorcrypto",
                "instagram": "https://www.instagram.com/angkorcrypto",
                "twitter": "https://twitter.com/angkorcrypto"
            }
        },
        {
            "chainId": 101,
            "address": "MciwQ8uyfRYZMGauY6HdvkoUtuZVfLQqq5wcwBLGFEJ",
            "symbol": "MC",
            "name": "MechaCats WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MciwQ8uyfRYZMGauY6HdvkoUtuZVfLQqq5wcwBLGFEJ/logo.png"
        },
        {
            "chainId": 101,
            "address": "CEJEdxUY67GLg5MJoagzgDhJ9LmuZYjr7styRX4tz4pk",
            "symbol": "LDF",
            "name": "LO DEI FONG",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CEJEdxUY67GLg5MJoagzgDhJ9LmuZYjr7styRX4tz4pk/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3G23ehQ6puSm6e29q7CN6uA1kMVpwiTsMWJb3KrE5RwC",
            "symbol": "SNY",
            "name": "SUN NAM YUEN",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3G23ehQ6puSm6e29q7CN6uA1kMVpwiTsMWJb3KrE5RwC/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8kEmKXTR36G96QUk6m5y4MeRNCg7Z3HjtUJSahWedPos",
            "symbol": "WWH",
            "name": "WaitWaitHea",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8kEmKXTR36G96QUk6m5y4MeRNCg7Z3HjtUJSahWedPos/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB",
            "symbol": "DMV",
            "name": "DARK COIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/Shaik-Sirajuddin/RepoForUploadsNeverDelete/master/Images/darkcoin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dh3gWTFj",
                "facebook": "https://www.facebook.com/darkcoindmv",
                "github": "https://github.com/darkcoindmv",
                "instagram": "https://www.instagram.com/helloyogaworlddao",
                "reddit": "https://www.reddit.com/user/darkcoindmv",
                "telegram": "https://t.me/darkcoindmv",
                "twitter": "https://twitter.com/darkcoindmv",
                "website": "https://www.darktoken.org"
            }
        },
        {
            "chainId": 101,
            "address": "AKVYLV1cZakQ5HKGiTu9UR9uEv7TwA7VeZyvcL3mHfs3",
            "symbol": "DBDWL",
            "name": "DegenBoyz WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/dbdwl.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BRKZbe8hFPmiJeBa94yARQ2RQ8KJQgRdnGMo7o6Wu1DC",
            "symbol": "BRICKZ",
            "name": "BRICKZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/thomasbuck810/BRICKZ/main/BRICKZ-2.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/metafigz",
                "twitter": "https://twitter.com/metafigznft",
                "website": "https://metafigz.com"
            }
        },
        {
            "chainId": 101,
            "address": "6ncN7QFWWGHAfN4Fb83bcVkKD1VmpfdPpKkH7m2qPLRU",
            "symbol": "TGWL",
            "name": "Tokyo Glitch Whitelist Token l TG",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ncN7QFWWGHAfN4Fb83bcVkKD1VmpfdPpKkH7m2qPLRU/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 103,
            "address": "FUonikhC5D3WBU7zkVoYWYTKMwarxFbyfuC54Hd29UPw",
            "symbol": "TBDG1",
            "name": "T_BDG_1",
            "decimals": 1,
            "logoURI": "https://ipfs.io/ipfs/QmVAs4Bi62kYkUku2HJ7aRnmuCLCQpXoRd7PCHZJxjXTze",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TooZz17K5vBgs4QoN9sPQQv3WRgCKuZCUr5g4AnjaBy",
            "symbol": "MJDWL",
            "name": "Mahjong Dogs Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MahjongDogs/assets/main/MJDWL%20Token.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/F7qWPFD5z6",
                "twitter": "https://twitter.com/mahjongdogs",
                "website": "https://www.mahjongdogs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3t1VbGif4EuuUyxjqCeTnzWBAeSxBVTJTuPZRgchC16B",
            "symbol": "CUPT",
            "name": "Champions Uniting Programme Token",
            "decimals": 0,
            "logoURI": "https://github.dev/Shent/token-list/assets/3t1VbGif4EuuUyxjqCeTnzWBAeSxBVTJTuPZRgchC16B/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh",
            "symbol": "ARB",
            "name": "ARB Protocol",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ARBProtocol/arbprotocol/main/IMG_3600.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/M4F8RKqgce",
                "twitter": "https://twitter.com/arbprotocol"
            }
        },
        {
            "chainId": 101,
            "address": "65MEBrX7oZ5UgLd3U5r27XhYManEAzHfdNiyniCy9eyW",
            "symbol": "WAGMI",
            "name": "WagmiTicket",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Afterglow421/logop/main/logo/logo.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wagmibros",
                "twitter": "https://twitter.com/SolanaWagmiBros",
                "website": "https://wagmibros.club/"
            }
        },
        {
            "chainId": 101,
            "address": "AgMRXkwzXk8BwUs8e58wF5LhvLfCieL2tEG9ASdueA52",
            "symbol": "4LC",
            "name": "Four Leaf Clovers",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kevinyuhk/crypto/main/Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EqCgGcBmRKo6sP3T84oKZBmH19bSGK4Npi5ySGCeHF23",
            "symbol": "OKE",
            "name": "Kuma Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EqCgGcBmRKo6sP3T84oKZBmH19bSGK4Npi5ySGCeHF23/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "The FOOD \u0026 Backbone to the Kuma Kingdom",
                "website": "https://okaykumas.com"
            }
        },
        {
            "chainId": 101,
            "address": "9LDkpLUuETkmYd7KrdPXgzMdKs5hMSKt177UepRW66x6",
            "symbol": "STRYS",
            "name": "Strays Fish Bone",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9LDkpLUuETkmYd7KrdPXgzMdKs5hMSKt177UepRW66x6/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/jn5athqc",
                "twitter": "https://twitter.com/modcatsclub"
            }
        },
        {
            "chainId": 101,
            "address": "DUM5J59yrYGukU52i1hC6YGZQ8Bfg7ppVpsYwJDF41f6",
            "symbol": "NKL",
            "name": "Nickel",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUM5J59yrYGukU52i1hC6YGZQ8Bfg7ppVpsYwJDF41f6/logo.png",
            "extensions": {
                "twitter": "https://twitter.com/HeroesOfAstron",
                "website": "https://heroesofastron.com"
            }
        },
        {
            "chainId": 101,
            "address": "FxcEpasqzkwo5YzZLADLwL9ySLQnpwFCzzxuc9GP5ytu",
            "symbol": "$PANFT",
            "name": "PA NFT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FxcEpasqzkwo5YzZLADLwL9ySLQnpwFCzzxuc9GP5ytu/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token"
            ],
            "extensions": {
                "website": "https://policeacademynft.com"
            }
        },
        {
            "chainId": 101,
            "address": "943NSd9g9t71hZcw3AR3qbHMVCxy6sXWwYKELbzJjVms",
            "symbol": "NATU",
            "name": "NATU",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/943NSd9g9t71hZcw3AR3qbHMVCxy6sXWwYKELbzJjVms/natu.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/nahavi",
                "twitter": "https://twitter.com/WanderingNahavi",
                "website": "https://nahavi.io/"
            }
        },
        {
            "chainId": 101,
            "address": "4tejdaGTug9xWg58f92zmFMrsoWqCnvt8WaVuCiP3uUy",
            "symbol": "ICHIGO",
            "name": "ICHIGO",
            "decimals": 8,
            "logoURI": "https://ichigo.network/logos/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ichigonetwork",
                "website": "https://ichigo.network/"
            }
        },
        {
            "chainId": 101,
            "address": "MJfiLqG26ysMHqzg6quVoUERBh3hHo41JJwtg7crmU2",
            "symbol": "MJDOG",
            "name": "Mahjong Dogs OG Whitelist Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MahjongDogs/assets/main/MJDOG%20Token.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/F7qWPFD5z6",
                "twitter": "https://twitter.com/mahjongdogs",
                "website": "https://www.mahjongdogs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "wofMXpfx7RrbPUsUbUmcYGFsbCX8drjtgP6siXQDPhN",
            "symbol": "PFWL",
            "name": "Private Foxes Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/wofMXpfx7RrbPUsUbUmcYGFsbCX8drjtgP6siXQDPhN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/privatefoxes",
                "twitter": "https://twitter.com/privatefoxes",
                "website": "https://www.privatefoxes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "C7wDAivGdaVHtLJqiSfjgZWUwy1c53YNsDWtf8iKWCJj",
            "symbol": "PNL",
            "name": "CRYPTOPNL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/R-MiN/PNL/main/LOGO.jpg",
            "tags": [
                "NFT"
            ]
        },
        {
            "chainId": 101,
            "address": "5s2qavsSBLHuoyiNY394t81FtE2YU6PkDdqy39a2mXdk",
            "symbol": "KUMA",
            "name": "Official Kuma Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5s2qavsSBLHuoyiNY394t81FtE2YU6PkDdqy39a2mXdk/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/krT4seXKcV",
                "twitter": "https://twitter.com/okaykumasdao",
                "website": "https://okaykumas.com"
            }
        },
        {
            "chainId": 101,
            "address": "DGQJyKwk5NQgzUphc3QMiWFrKXkHFACPiQ3ofGojExHM",
            "symbol": "ALMT",
            "name": "Almat",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/vlada84/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "223Zo5YTCawWQ3AQcwJCKoxrvPHu6L6Wb5QC7nCvWLQ2",
            "symbol": "$GABY",
            "name": "GABY CORNWALL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/223Zo5YTCawWQ3AQcwJCKoxrvPHu6L6Wb5QC7nCvWLQ2/logo.png"
        },
        {
            "chainId": 101,
            "address": "PLaYysuBswgDZB2vXCz89pukmkcd5QjvBoSxfVwBaKZ",
            "symbol": "SHrouded",
            "name": "Shrouded Playground Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PLaYysuBswgDZB2vXCz89pukmkcd5QjvBoSxfVwBaKZ/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ShroudedNFT"
            }
        },
        {
            "chainId": 101,
            "address": "En1DhX7z4vAYxLu74AHXaL8DRhJ1Trwr4ShbsTf8SVz",
            "symbol": "KRMC",
            "name": "Karen R M Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dakender/Karen-Coin/main/logo-karen.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GvmTHnBcj6t9uRShDggjJfAFEWs3wJpEpNYAAAPwuu8N",
            "symbol": "SNOW",
            "name": "Nan Feng Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GvmTHnBcj6t9uRShDggjJfAFEWs3wJpEpNYAAAPwuu8N/logo.png"
        },
        {
            "chainId": 101,
            "address": "DyYPwCYLNrxKyBS53xhEN3PPD8GECbkVUwRqWU6Shru3",
            "symbol": "BSPCP",
            "name": "Brainstorm Capital Investment",
            "decimals": 0,
            "logoURI": "https://github.com/seanklee0810/bchain/blob/main/logo.png",
            "tags": [
                "social-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "27Rd4SZ75RkFDdf2PVX79Z23hzMuZyEBWABkt4Pa3qDB",
            "symbol": "TARP",
            "name": "THE AFFORDABLE RENT PROJECT",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/SGTCHIKO/TARP/main/TARP_LOGO.png?token=GHSAT0AAAAAABVCIKZJ2OZXTZ5LTUQ7BWMEYVFOUFQ",
            "tags": [
                "Rent",
                "Apartments",
                "Home",
                "Real-Estate",
                "Rent-is-to-high"
            ],
            "extensions": {
                "website": "http://theaffordablerentproject.com"
            }
        },
        {
            "chainId": 101,
            "address": "9Cu2sChBnfCJGKUXXXhuvHbTDiEv9Y7KZqPAtLtVeof3",
            "symbol": "DS",
            "name": "Duck Skull",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Cu2sChBnfCJGKUXXXhuvHbTDiEv9Y7KZqPAtLtVeof3/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xbDCUaJUgh"
            }
        },
        {
            "chainId": 101,
            "address": "81buE3LLcsURUSR3KG4mJkYTvpGVeyrSES3txNtXgKem",
            "symbol": "FTKC",
            "name": "KULTURE Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/micusmedia/MMGCrypto/main/KULTURE_COIN_200.png",
            "tags": [
                "social-token",
                "economic-token",
                "kulturecoin-token"
            ]
        },
        {
            "chainId": 103,
            "address": "3ev8N5p8jGw1yF6uQkR5zDWWpo6fsAev23RyNwvqzHv3",
            "symbol": "$TENKA",
            "name": "Ignite Tournament",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ev8N5p8jGw1yF6uQkR5zDWWpo6fsAev23RyNwvqzHv3/logo.png"
        },
        {
            "chainId": 101,
            "address": "7ctmggF48CzBq6L6JvmcM5KrjfRm4zMir1ycVoFfkd31",
            "symbol": "CDG",
            "name": "Crypto Digging Gem",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/quachthanhhmd/token-store/master/assets/cdg.png"
        },
        {
            "chainId": 103,
            "address": "9zMUxkyMmQF2GuxQ7aZqmvuqttsb9ru9n1zn9gF9KhTG",
            "symbol": "TBDG2",
            "name": "T_BDG_2",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9zMUxkyMmQF2GuxQ7aZqmvuqttsb9ru9n1zn9gF9KhTG/B_BARRE.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3aKHn2FYrTx2A9vFQwGoeAugcWsA1SJfp44g3AVReRJe",
            "symbol": "CAST",
            "name": "The Castle",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3aKHn2FYrTx2A9vFQwGoeAugcWsA1SJfp44g3AVReRJe/logo.png",
            "tags": [
                "meme-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/CastleTheKings"
            }
        },
        {
            "chainId": 101,
            "address": "BWMkZUCaG23uHUTEUC9S32cimW9p8qaKMHj2vEfskHtr",
            "symbol": "$points",
            "name": "Mammadhuseynov",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jaknve/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HvHq52D47mNLK5LDrZ3quJfmX2B6Z9RkSJGPyq1pAr36",
            "symbol": "LOX",
            "name": "Loxus",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HvHq52D47mNLK5LDrZ3quJfmX2B6Z9RkSJGPyq1pAr36/logo.png"
        },
        {
            "chainId": 101,
            "address": "4u7M8yLZ3jK4tP8TZqLwSqK4Zw9EgiSJmvQUi4pjnkYM",
            "symbol": "$INT",
            "name": "intellicoin",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/Saeidjamali/intellicoin/main/intellicoing.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "odfLnBGecR5jmzd2aLvqRP1BHAQybAwCK2bCtbQbmZ5",
            "symbol": "$LTE",
            "name": "Listen to Earn",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/gthiktm/-LTE/main/tokenicon.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ExH7L93cNaB1jJcExTf8anwGQ7VbBg6zGvAowQ85Hcu7",
            "symbol": "HCHI",
            "name": "HaechiCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yuujinhwng/crypto/main/haechilogos.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6wAsMDoZo7oPpvh5wyqmEaQ3PSCsZj3X45LqBUh8myZv",
            "symbol": "OrbitWL",
            "name": "Unfrgtn Orbit Whitelist",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wAsMDoZo7oPpvh5wyqmEaQ3PSCsZj3X45LqBUh8myZv/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/UnfrgtnOrbit",
                "website": "https://unfrgtn.space"
            }
        },
        {
            "chainId": 101,
            "address": "HFtQgjTJPs88q3JBhHrmWYoj985k2eSD3Dsx5RsBRSvc",
            "symbol": "TAZ",
            "name": "Taz Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/antar4/TazCoin/main/TazCoin-logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8Tjjm9zB8sLJNFNALq7MRrnL9ygY354XX7dV4zkcb4Tj",
            "symbol": "Rev-WL",
            "name": "Re-Evolution Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Tjjm9zB8sLJNFNALq7MRrnL9ygY354XX7dV4zkcb4Tj/RevWLIcon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Re_EvolutionNFT"
            }
        },
        {
            "chainId": 101,
            "address": "HCpyiCXvziKByTGoQMLUmooAWBkCLvZmzB9D6TyPdACR",
            "symbol": "qdot",
            "name": "qdot",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/CleverChuk/qdot@main/assets/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://musicq.club/"
            }
        },
        {
            "chainId": 101,
            "address": "FWzbsdoXocZHiDAUhtC3ur3kMusnWMjJYye29R4GuxcQ",
            "symbol": "SXWT",
            "name": "SolExpress Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SEPAD12/Logo/main/SE-Logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HQ2YfjamQg5y5utHrhC3ERgJfFFHy7N6nRQeW4WD8F4X",
            "symbol": "INVC",
            "name": "INVERSO COIN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/INVERSOCoin/CryptoLOGO/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2AovdGs88UWaLgjyzxUepQMGHP8CPHyrXg2hSHpePRen",
            "symbol": "TakeoWL",
            "name": "Takeo Cats Whitelist",
            "decimals": 8,
            "logoURI": "https://cdn.jsdelivr.net/gh/samuKatana/WL_LOGO_TOKEN/wllogo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TakeoNFT",
                "website": "https://www.takeo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8BdWjY3KPmr5KjrXRyaWr3K7gA9KFdjugcEodjpTDSxx",
            "symbol": "TakeoCatsWL",
            "name": "Takeo Whitelist",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/samuKatana/WL_LOGO_TOKEN/wllogo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TakeoNFT",
                "website": "https://www.takeo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6PA4dYYF654sfUK1eDd2RCihbF6L71JyGoyAfcUb9V5u",
            "symbol": "BECCA",
            "name": "Becca Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dakender/Becca-Coin/main/logo-becca.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ESVGBqewDDcmY9WYX9LekSy8E1v8Q8sXXK5WkQAtCWRM",
            "symbol": "ITC",
            "name": "InventorsCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ESVGBqewDDcmY9WYX9LekSy8E1v8Q8sXXK5WkQAtCWRM/logo.png"
        },
        {
            "chainId": 101,
            "address": "neoSCa3b4HJR6TF3pVQzph3yrcd97ZMovCVaRC8bRss",
            "symbol": "$NEO",
            "name": "Neo Hunters Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/neoSCa3b4HJR6TF3pVQzph3yrcd97ZMovCVaRC8bRss/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/neohunters",
                "twitter": "https://twitter.com/neo_hunters",
                "website": "https://neohunters.io/"
            }
        },
        {
            "chainId": 101,
            "address": "7zuDvbyjckburc6hASJ7TtXzPFoe9ps4bgq39Zvs4hPg",
            "symbol": "ICNT",
            "name": "iConnectandmore Token",
            "decimals": 0,
            "logoURI": "https://github.com/iconnectandmore/tokens/blob/main/logo-image-iconn-200x200.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2nmwnHy3mg7sVdKszSYfgT1hdAMEv168oYgEimSufwdW",
            "symbol": "PZHDOG",
            "name": "Pizza Headz OG Pielist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2nmwnHy3mg7sVdKszSYfgT1hdAMEv168oYgEimSufwdW/logo.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PizzaHeadzNFT",
                "website": "https://www.pizzaheadz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "FsHfCRt4A5aiVEgY2Daxqf24vuMCQepJvydkb4Yc8QAd",
            "symbol": "PZHDPL",
            "name": "Pizza Headz Pielist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FsHfCRt4A5aiVEgY2Daxqf24vuMCQepJvydkb4Yc8QAd/logo.jpg",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PizzaHeadzNFT",
                "website": "https://www.pizzaheadz.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CUejbcKs5w2c5M5FPzUetwPcwJ8QycKTua3FhwnQhyHh",
            "symbol": "HANDT",
            "name": "HAND DEV",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CUejbcKs5w2c5M5FPzUetwPcwJ8QycKTua3FhwnQhyHh/LogoHS.png"
        },
        {
            "chainId": 101,
            "address": "EVyTumcuRtkbWvnHCLf6safa8GjZpw2uuQYzKD6GfMoy",
            "symbol": "JACK",
            "name": "JACKPOTCOIN",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EVyTumcuRtkbWvnHCLf6safa8GjZpw2uuQYzKD6GfMoy/logo.png",
            "tags": [
                "community-token",
                "JACKCOIN"
            ],
            "extensions": {
                "website": "http://jackpotcast.com/"
            }
        },
        {
            "chainId": 101,
            "address": "SKSE1d9hHn4jJGejGzuwnpD8DMM5TBKQ18ikUFSrx1C",
            "symbol": "sKSE",
            "name": "Banksea Staking Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SKSE1d9hHn4jJGejGzuwnpD8DMM5TBKQ18ikUFSrx1C/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/F4AcB4ycCQ",
                "twitter": "https://twitter.com/banksea_finance",
                "website": "https://banksea.finance"
            }
        },
        {
            "chainId": 103,
            "address": "ND8Hje1MuZUqMYbxSh8gQCooMSuddky1NBwmX5NpsM9",
            "symbol": "PUMA",
            "name": "PumaCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MauriRamos19/puma-coin-project/main/Recursos/logo.png",
            "tags": [
                "utility-token",
                "social-token",
                "stable-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7S42pFMbsEsHZfAPyswEQHQkaFrJcdYnXqmFb2Cb5zwv",
            "symbol": "McBall",
            "name": "BC Capital",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ksleetech/BSPCP/main/McLogo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DAPE1HkT8uHykg6oi9ooFp8ZVWppH4sVoDUg3rcqQPeA",
            "symbol": "DAPE",
            "name": "DeApe DAO Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAPE1HkT8uHykg6oi9ooFp8ZVWppH4sVoDUg3rcqQPeA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://deapes.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "DoCjPWEQgA1Crk1J3rrkPp9b3piGJw9G2wVsEuRz1oVS",
            "symbol": "NA",
            "name": "Noah Raffle WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DoCjPWEQgA1Crk1J3rrkPp9b3piGJw9G2wVsEuRz1oVS/logo.jpg",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CyBzRibJpFRFE5jarrkNizrqJNsUc3yZYfquY8Kr3tBY",
            "symbol": "TC",
            "name": "TC Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/wajahatrashid1/crypto/main/logoo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/KeniaRutishaus1"
            }
        },
        {
            "chainId": 101,
            "address": "BZtVdUaAweWzNZLKrvk2iULAjDYTs6boQEFKa4EoyhiB",
            "symbol": "SYB",
            "name": "SYB Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZtVdUaAweWzNZLKrvk2iULAjDYTs6boQEFKa4EoyhiB/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GKYYvdRQCivXWFWY7GbQmwfemW1KFLycqj8nmmdBy1ao",
            "symbol": "passat",
            "name": "Passat Token",
            "decimals": 1,
            "logoURI": "https://fra1.digitaloceanspaces.com/facewallet-prod/public/e04f59a8-6d27-4e57-a393-6e061c7f1933",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9vXd2WXo3oM6kSJoeY6GNFgXi7WDtZPXuDZ183qpUjdr",
            "symbol": "BCO2",
            "name": "BLUE CARBON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vXd2WXo3oM6kSJoeY6GNFgXi7WDtZPXuDZ183qpUjdr/logo.png"
        },
        {
            "chainId": 101,
            "address": "D5wuTKz1KWXun8tE8ANsJTH1CX7o1xmMwKyTfGUasT52",
            "symbol": "GCO2",
            "name": "GOLD CARBON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D5wuTKz1KWXun8tE8ANsJTH1CX7o1xmMwKyTfGUasT52/logo.png"
        },
        {
            "chainId": 101,
            "address": "3A3faka1wemjLX4dUSXmxNXBKodRxvPTmcvN1aeKsfNm",
            "symbol": "SCO2",
            "name": "SILVER CARBON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3A3faka1wemjLX4dUSXmxNXBKodRxvPTmcvN1aeKsfNm/logo.png"
        },
        {
            "chainId": 101,
            "address": "9Z3ejVAEeCY6tapfMUMGCfwwfxQXwH8tM1gJ5CkH5XQE",
            "symbol": "PCO2",
            "name": "PLATINUM CARBON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9Z3ejVAEeCY6tapfMUMGCfwwfxQXwH8tM1gJ5CkH5XQE/logo.png"
        },
        {
            "chainId": 101,
            "address": "4R8gJi5PMH8g2bg8tw9uyiG7i6uxiyc8CRQKfap7vpt6",
            "symbol": "FCO2",
            "name": "FARMERS CARBON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4R8gJi5PMH8g2bg8tw9uyiG7i6uxiyc8CRQKfap7vpt6/logo.png"
        },
        {
            "chainId": 101,
            "address": "2M9dSPWE8ZgryZhTrtrZv43wxuFDAJAwp9pzUPpEuc6D",
            "symbol": "$SEED",
            "name": "SEED",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/SEED_coin.png"
        },
        {
            "chainId": 101,
            "address": "9EP6rW2q6YteTQBsdxVR6rS8B6rcr9EriBnAdfK4Nqn2",
            "symbol": "$CNECTR",
            "name": "CONNECTOR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/CNECTR_coin.png"
        },
        {
            "chainId": 101,
            "address": "8q7rfS8M8jir8U3VdKXsNk7zEitdq9p8WpbpRg7K4cMd",
            "symbol": "$PIPER",
            "name": "PIPER",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/puresec-ng/gary/main/PIPER_coin.png"
        },
        {
            "chainId": 101,
            "address": "2bESmZmQLgMUtzfiyPMPG7zJHwDCeV8a7z4fe3bDsrBS",
            "symbol": "TakeoCatsWL",
            "name": "Takeo NFT Whitelist",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/samuKatana/WL_LOGO_TOKEN/wllogo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TakeoNFT",
                "website": "https://www.takeo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "RLNF6G4CJT9NMAjNuYtPjU43S2Fx31UKzgEWgsympgJ",
            "symbol": "$CHIN",
            "name": "CryptoChins",
            "decimals": 9,
            "logoURI": "https://arweave.net/zxNiI1tp9VV-tEY1nMfNY59JvAymwQS-_b-022kKImg?ext=png",
            "tags": [
                "utility-token",
                "social-token",
                "nft-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/cryptochins",
                "website": "https://thecryptochins.com"
            }
        },
        {
            "chainId": 101,
            "address": "TMPLE59azbK8tJHzkyofoj7oTuScWR5VK8oAbXAwW5u",
            "symbol": "$TMPL",
            "name": "Temple Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TMPLE59azbK8tJHzkyofoj7oTuScWR5VK8oAbXAwW5u/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TempleBeasts"
            }
        },
        {
            "chainId": 101,
            "address": "DAPECzzTd4PQ9JR7UWDpmM798boAFv1oLBdN8QGFQb1z",
            "symbol": "DAPE",
            "name": "DeApes DAO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAPECzzTd4PQ9JR7UWDpmM798boAFv1oLBdN8QGFQb1z/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://deapes.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "MLUPoikDQq2Vem7CQ1HFnF2p1VieUmDSshCYisALLhe",
            "symbol": "$TaKeoWL",
            "name": "TakeoNFT Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/samuKatana/WL_LOGO_TOKEN/main/wllogo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 103,
            "address": "A4G3NcWwU5eFfHJAS6qST4vwqPVe2SczxdgDCtYeajZx",
            "symbol": "XC",
            "name": "XCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A4G3NcWwU5eFfHJAS6qST4vwqPVe2SczxdgDCtYeajZx/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://bxplus.co"
            }
        },
        {
            "chainId": 101,
            "address": "34BNRduGN8fiRCVgu6hjDUa5mqyvtuSw7mA7wwNAeSUy",
            "symbol": "LSD",
            "name": "Lunar System Dollar",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/34BNRduGN8fiRCVgu6hjDUa5mqyvtuSw7mA7wwNAeSUy/logo.png"
        },
        {
            "chainId": 101,
            "address": "kbuNPojG4aVbTQ7gWcmmga5YNyny5BHHYatFaKXuKxU",
            "symbol": "TERIUM",
            "name": "Terium",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kbuNPojG4aVbTQ7gWcmmga5YNyny5BHHYatFaKXuKxU/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Swap your gift on terium.info",
                "website": "https://terium.info"
            }
        },
        {
            "chainId": 101,
            "address": "54i1hUrQM4pCcyje62pbm8usj2Bxa8ywEF6hi3XP6uzx",
            "symbol": "NSTR",
            "name": "Nestor Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ribj93/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "7QtUroWAcpwyHVgsaiYyNnfWMU899fk288T5LXVBu9hh",
            "symbol": "TBDG3",
            "name": "T_BDG_3",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7QtUroWAcpwyHVgsaiYyNnfWMU899fk288T5LXVBu9hh/B_ROND_1.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDMsanNyhoKB9qDFtxVY1RQaFw7h1XAVLmBTdtQ7nBVe",
            "symbol": "HDMWL",
            "name": "HDM Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDMsanNyhoKB9qDFtxVY1RQaFw7h1XAVLmBTdtQ7nBVe/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G541xHAw1xSH6aCUBDJR3zcgBBbedBm2LU3yoqqhGvVA",
            "symbol": "SILVR",
            "name": "Mined Silver",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G541xHAw1xSH6aCUBDJR3zcgBBbedBm2LU3yoqqhGvVA/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8ZFkaYFn6AtkrwE469k4YXmzy8GHKK2BCdyY2gmSaEn6",
            "symbol": "BKB",
            "name": "Blockombat",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Robby3082/test-token/main/BKB%20Logo.png",
            "tags": [
                "Utility-token"
            ],
            "extensions": {
                "description": "Creator: Blockombat Team",
                "website": "https://blockombat.com"
            }
        },
        {
            "chainId": 101,
            "address": "D7duYpwjf4zTUPHRpy6Wvu4viNLWDWSYcDHMH1ssJv3s",
            "symbol": "hust",
            "name": "Hustle",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LouisDeSimone/dietToken/main/gymapes.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8b9mPcqdgU495nLdCbWdsZ81Bk81mKLoRRfmW1zabkTz",
            "symbol": "ONEPAT",
            "name": "One Peace",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Krishna-Thomas/onepeacetoken/main/onepeacetoken.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gj4a2baCEWv9yKzM4wvPN3S1aGzdbponTaK6cFBYdEcP",
            "symbol": "LOX",
            "name": "Loxus Pay",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gj4a2baCEWv9yKzM4wvPN3S1aGzdbponTaK6cFBYdEcP/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/unmUvytk8X",
                "instagram": "https://www.instagram.com/loxus_updates/",
                "twitter": "https://twitter.com/loxus_updates",
                "website": "https://www.loxus.org/"
            }
        },
        {
            "chainId": 101,
            "address": "4V9NQ4F4R2mG5VJhEAet6iLKN4MBoLxSqX3PJirN5qsU",
            "symbol": "NVRLCC",
            "name": "Car Culture",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4V9NQ4F4R2mG5VJhEAet6iLKN4MBoLxSqX3PJirN5qsU/logo.png",
            "tags": [
                "community",
                "airdrop",
                "NVRLCC"
            ],
            "extensions": {
                "discord": "https://discord.gg/metawhips",
                "twitter": "https://twitter.com/wccmetawhips",
                "website": "https://www.skrrrt.vip"
            }
        },
        {
            "chainId": 101,
            "address": "m3uDaCvukJENJhHkyLEs3nRzd8JHMSjUfL9QMyPJmdL",
            "symbol": "$HOOT",
            "name": "owlz hoot",
            "decimals": 7,
            "logoURI": "https://raw.githubusercontent.com/Verorian/icon/main/istockphoto-1069798050-170667a.jpg"
        },
        {
            "chainId": 101,
            "address": "211KTsj51pCuapiUMPGKJwDYPB55LuiJpQx1ojiiinSh",
            "symbol": "STPD",
            "name": "STOOPID Apes Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/211KTsj51pCuapiUMPGKJwDYPB55LuiJpQx1ojiiinSh/logo.png"
        },
        {
            "chainId": 101,
            "address": "2cQgtwwJUi5uSoB4jFJ4v6GVGe9YQjsVF8tRpWLHMx12",
            "symbol": "LSD",
            "name": "LUNAR-SYSTEM-DOLLAR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cQgtwwJUi5uSoB4jFJ4v6GVGe9YQjsVF8tRpWLHMx12/logo.png"
        },
        {
            "chainId": 101,
            "address": "3wmsJkKWLdFT4tF4rG8zUZQ8M4hKUDtDuJW8q6i9KbgF",
            "symbol": "TDG",
            "name": "TrueSight DAO - Governance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3wmsJkKWLdFT4tF4rG8zUZQ8M4hKUDtDuJW8q6i9KbgF/logo.png",
            "tags": [
                "governance-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "description": "TSG is a governance token for our universal credibility protocol. Our purpose is to build a better world based on verifiable truth. We believe liberal democratic societies around the world are increasingly destablized by information pollution and this leads to ignorant decisions which cause human suffering. Our mission is fight information pollution by providing individuals with the clarity to make critical decisions.",
                "discord": "https://discord.gg/gEfypKdCEW",
                "facebook": "https://www.facebook.com/TrueSightMe",
                "imageUrl": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3wmsJkKWLdFT4tF4rG8zUZQ8M4hKUDtDuJW8q6i9KbgF/logo.png",
                "reddit": "https://www.reddit.com/r/truesightme/",
                "website": "https://truesight.me"
            }
        },
        {
            "chainId": 101,
            "address": "3CKoFsaEYUgLH7ZYHyoADFpoo6NwysrvzMTeyE1akEtC",
            "symbol": "GECKO",
            "name": "Gecko Token",
            "decimals": 2,
            "logoURI": "https://fuzionblox.com/gecko_icon.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://fuzionblox.com"
            }
        },
        {
            "chainId": 101,
            "address": "ToPGAbmu9c47RhsK7HneUSAPio8uAKR9MGuQbcV2yW7",
            "symbol": "KUL",
            "name": "Kulture",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ToPGAbmu9c47RhsK7HneUSAPio8uAKR9MGuQbcV2yW7/logo.png"
        },
        {
            "chainId": 101,
            "address": "8YPwxJ7geGS8jjtTYzKaYbgkFWBQQHKtfHS4g2nzXcHt",
            "symbol": "WAR",
            "name": "WAR Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8YPwxJ7geGS8jjtTYzKaYbgkFWBQQHKtfHS4g2nzXcHt/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://warlandnft.art"
            }
        },
        {
            "chainId": 101,
            "address": "HELLBR3SoErMY1JkcnHMes3xTYoHaRMFJQGzWMATpQf9",
            "symbol": "$HELL",
            "name": "HELL",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HELLBR3SoErMY1JkcnHMes3xTYoHaRMFJQGzWMATpQf9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheSneakyDevils",
                "website": "https://www.thesneakydevils.com/"
            }
        },
        {
            "chainId": 101,
            "address": "98ouGzbMV2DnHfn3Uc7oahwvDP4Cf7Ls77JsBiARdjvn",
            "symbol": "DBC",
            "name": "DBank Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/98ouGzbMV2DnHfn3Uc7oahwvDP4Cf7Ls77JsBiARdjvn/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://dbank.to/"
            }
        },
        {
            "chainId": 101,
            "address": "4i565nbEspucgEDbNFNmk47LmJaftQtWUjT9Kx2KnhLA",
            "symbol": "$GFY",
            "name": "GOOFY",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4i565nbEspucgEDbNFNmk47LmJaftQtWUjT9Kx2KnhLA/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/GoofyDao"
            }
        },
        {
            "chainId": 101,
            "address": "FMXwmGWkZvNYUGYZfsx2Kkku9WTdhpjU1qyDaNoW3URn",
            "symbol": "HDMFM",
            "name": "HDM Free Mint",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FMXwmGWkZvNYUGYZfsx2Kkku9WTdhpjU1qyDaNoW3URn/logo.png",
            "tags": [
                "whitelist-token"
            ]
        },
        {
            "chainId": 101,
            "address": "61GPeHD7K3GgeijVbYfE3NcZjLLhXuj19WEWRFmjyeeP",
            "symbol": "TIGER",
            "name": "TIGER KOIN",
            "decimals": 6,
            "logoURI": "https://github.com/theaveasso/tigerkoin/blob/main/logo/tigerkoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FXe7K4EtThAzsu7dphLBKtbjFwE3AeMaFC7kwVd3ifhS",
            "symbol": "xC98",
            "name": "Synthetic Coin98",
            "decimals": 6,
            "logoURI": "https://www.synthetify.io/icons/xc98.svg",
            "extensions": {
                "coingeckoId": "coin98",
                "twitter": "https://twitter.com/synthetify",
                "website": "https://synthetify.io/"
            }
        },
        {
            "chainId": 101,
            "address": "E6HjU9u1HrrCQs3ZKjcVZLUp4oXjNoMs5rjGNXC4Ufrd",
            "symbol": "MCY",
            "name": "MEGACY",
            "decimals": 6,
            "logoURI": "https://megacy.io/images/Logo%20page.svg",
            "extensions": {
                "discord": "https://discord.gg/XBamgzuA",
                "twitter": "https://twitter.com/MEGACY_Official",
                "website": "https://megacy.io"
            }
        },
        {
            "chainId": 101,
            "address": "uU1bU1ccSXRTsUNQkDdwbWuFfqZ9iPkxfKnWQff4wrg",
            "symbol": "DGD",
            "name": "Degoddess Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uU1bU1ccSXRTsUNQkDdwbWuFfqZ9iPkxfKnWQff4wrg/DGD.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AG5j4hhrd1ReYi7d1JsZL8ZpcoHdjXvc8sdpWF74RaQh",
            "symbol": "svtOKAY",
            "name": "Okay Bears Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AG5j4hhrd1ReYi7d1JsZL8ZpcoHdjXvc8sdpWF74RaQh/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9puTadWjsBwmvkpdEychJ79FLu2uFTC3MkvwEuRD6ssa",
            "symbol": "ERTC",
            "name": "Eratosian Credit",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ajm24027/crypto/main/Eratosian-Credits-Logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TooGMgD3Xa5qmuzQax8cMd33vJSPgJavqixQ3WKAYYo",
            "symbol": "PUT",
            "name": "Peaceful Utility Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MikeStone6/SPL-Token-Creation/main/logo.png?token=GHSAT0AAAAAABSPTF373RJV5QKVLBRSTDIWYVINTUA",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://peacefulpigeons.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "E2VVnLTRGtqWc2K9dzHwUr14yEc5jZM1Bh42VyRspJm9",
            "symbol": "HCODE",
            "name": "Heisenberg Code",
            "decimals": 18,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E2VVnLTRGtqWc2K9dzHwUr14yEc5jZM1Bh42VyRspJm9/HCODE.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Heisenberg_Code",
                "website": "http://HeisenbergCode.com"
            }
        },
        {
            "chainId": 101,
            "address": "A8VuM8oJfZjB4cbUWTBdc9pPmPoMyUjqa7TtzG3fboGe",
            "symbol": "FADED",
            "name": "Faded Foxes token",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/NinaSnkrs/Superpals/fadedfoxes.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/FadedFoxess"
            }
        },
        {
            "chainId": 101,
            "address": "4cH3CvtwURFUVDnV1urH41kwnxfKsYFybonfctxwNSBS",
            "symbol": "PALS",
            "name": "Superpals Token",
            "decimals": 0,
            "logoURI": "https://cdn.jsdelivr.net/gh/NinaSnkrs/Superpals/superpalslogo.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/SuperpalsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "4Qr3mocySorrF19rkHDcCVD3E4V3xYcriEnxH7rPoxm1",
            "symbol": "WBC",
            "name": "Wordbee Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/antar4/WordbeeCoin/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "GtwdPgvF46rRrVnABd1tYqQJruwXtjTokFQPpqnsJrxH",
            "symbol": "DST",
            "name": "DST",
            "decimals": 9,
            "logoURI": "https://static.134.226.99.88.clients.your-server.de:8092/logo/dst.png"
        },
        {
            "chainId": 103,
            "address": "BXd2N7o4x62cqztqi1CxsAHZziTPZanZzQW1uscXnEa7",
            "symbol": "DHT",
            "name": "Dog Hard Token",
            "decimals": 9,
            "logoURI": "https://static.134.226.99.88.clients.your-server.de:8092/logo/dht.png"
        },
        {
            "chainId": 101,
            "address": "2gneDznGY3HQd33C6mP9PjEtmCU6t43wEdmKJCao5mAd",
            "symbol": "AOPO",
            "name": "AOPOCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gazza12401/aopo/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GQSfive4rvart6wwJXD5iuSeX4jQ9HRofDzBDrM1Yan3",
            "symbol": "ATHR",
            "name": "AITHER",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/FifthFaction/FifthFaction/main/token.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9ncNKy1uetJD96kBDuspbrTpuS8oAAyca4xC4YvXTJr",
            "symbol": "TBDG4",
            "name": "T_BDG_4",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ncNKy1uetJD96kBDuspbrTpuS8oAAyca4xC4YvXTJr/B_ROND_2.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "4QjMj1tF3dgJXtbQGS1MM5TWiPN8nEefmypUWzKg6TP3",
            "symbol": "$TENKA",
            "name": "Ignite Tournaments",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4QjMj1tF3dgJXtbQGS1MM5TWiPN8nEefmypUWzKg6TP3/logo.png"
        },
        {
            "chainId": 103,
            "address": "8CscLiWHiUMCPQ9Ss4xowtn6JtBhmQRtu4sYd2m8hWi3",
            "symbol": "MRF",
            "name": "MetaRealFight",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/metarealfight/MRF-Token/main/MRFLOGOCUBE.png"
        },
        {
            "chainId": 101,
            "address": "BjkAiRpUhtQZk4mZuTXGkb6pM92A6Kr7GkjmExzfYdaK",
            "symbol": "JAGO",
            "name": "JAGO SOLANA",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjkAiRpUhtQZk4mZuTXGkb6pM92A6Kr7GkjmExzfYdaK/robot.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Jimberly20",
                "website": "https://jimgogarty.com/"
            }
        },
        {
            "chainId": 101,
            "address": "9esJPERfsEV8hedoXkrBJ74ZJ8BNQEU2WBRuyxomxeBN",
            "symbol": "PPenguin",
            "name": "PixelPenguin",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/BreezyBabieMint/trtrtr/main/server_icon.png"
        },
        {
            "chainId": 101,
            "address": "3UYUHHubaSehdoqADUspBsvTjW8B3LLc1jP271skhRRz",
            "symbol": "LRN",
            "name": "Learn",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/alexmita04/gm-logo/main/learn_logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3cPjFH55nsuscA4VeMZFTK3p7JNgVPsM4kegxNT4uagA",
            "symbol": "GWLS",
            "name": "Go Wellness",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Go-wellness/Token-logo/main/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "description": "GWLS is a token for our move to earn dapp",
                "discord": "https://discord.com/invite/eFvswRkhQz",
                "reddit": "https://www.reddit.com/user/GoWellnessApp",
                "twitter": "https://twitter.com/GoWellnessApp",
                "website": "https://gowellness.app/"
            }
        },
        {
            "chainId": 101,
            "address": "DivUSnR1VD8LfXjdto8GKiaFavCee5jG1aeT6RmGWpam",
            "symbol": "MONOG",
            "name": "Mondays OG Token",
            "decimals": 0,
            "logoURI": "https://arweave.net/gM12e9ompfPSSMfYA7u1WUPTUSDq0vs8US-YDjuFXRw",
            "tags": [
                "Whitelist-token"
            ],
            "extensions": {
                "discord": "http://discord.gg/GrmUcQkEgk",
                "twitter": "https://twitter.com/MondaysNFT",
                "website": "https://mondaysnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "GLgABgDnNZS9FWuCFSZYvcQqu28zjEa8mKuYQ7DxxR8D",
            "symbol": "$WLDF",
            "name": "Wild Friends Coin",
            "decimals": 9,
            "logoURI": "https://github.com/ingrego19980907/for-solana-token/blob/main/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/WildFriendsNFT"
            }
        },
        {
            "chainId": 101,
            "address": "7k27YrYpfZ5f6nDKefoGMBUghxjenpEmSj1AVq5qCfsE",
            "symbol": "$LUCK",
            "name": "LUCKOIN",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/luckynoti/crypto/main/LUCK.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/OnesiesArmy"
            }
        },
        {
            "chainId": 101,
            "address": "FZ1HHg2TbihMCdRisYeXQjAtRB7dzghEt3XWopvDAHZW",
            "symbol": "SXA-3D-WL",
            "name": "Space X Apes 3D Wbitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZ1HHg2TbihMCdRisYeXQjAtRB7dzghEt3XWopvDAHZW/logo.png",
            "extensions": {
                "discord": "https://discord.gg/Mfgnj3xBk3",
                "twitter": "https://twitter.com/spacexapes"
            }
        },
        {
            "chainId": 101,
            "address": "DJ5TCEN2epuVfqMkgVozSUCTLHi71RNRhQXELCVw12L4",
            "symbol": "DJNRB",
            "name": "Danjon Reborn derug",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJ5TCEN2epuVfqMkgVozSUCTLHi71RNRhQXELCVw12L4/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Z2SZ22AMnE",
                "twitter": "https://twitter.com/DanjonReborn"
            }
        },
        {
            "chainId": 101,
            "address": "AEiGBSooswQgA1nB3AC8QVTDncpnpcqYFE163WwquTFz",
            "symbol": "CRIM",
            "name": "Crimson",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/flipthetip/test-tsc/main/W3B.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BZQqhz2jvPBC1QDYdFyiqSo7zVXU5KofWv3rUG8fLhrA",
            "symbol": "CCB",
            "name": "CalculatorBari",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BZQqhz2jvPBC1QDYdFyiqSo7zVXU5KofWv3rUG8fLhrA/logo.jpg",
            "tags": [
                "Calculator-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "82hjawkN9hPBnB3SENwRyJnhdEgWy9iNFUkags2iBqbf",
            "symbol": "$CALS",
            "name": "CALORIES",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/HadesThaGod/token-list/main/assets/mainnet/82hjawkN9hPBnB3SENwRyJnhdEgWy9iNFUkags2iBqbf/logohd.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "To3vvPkM9EEYHKXGPRuBukBbJcD79tT8fhAGHXqQpku",
            "symbol": "MJWL",
            "name": "Mahjong Dogs Whitelist Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MahjongDogs/assets/main/MJWL.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/F7qWPFD5z6",
                "twitter": "https://twitter.com/mahjongdogs",
                "website": "https://www.mahjongdogs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "MJmojunjQmo4i7zHFnzm7qAS3wsTcsAYCQCzWW7yiH8",
            "symbol": "MJOG",
            "name": "Mahjong Dogs OG Tokens",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/MahjongDogs/assets/main/MJOG.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/F7qWPFD5z6",
                "twitter": "https://twitter.com/mahjongdogs",
                "website": "https://www.mahjongdogs.com/"
            }
        },
        {
            "chainId": 101,
            "address": "TtiEDoN8e4a1BuLSLk9jXeEsgqhWDHSzp3Cv3sHyJWG",
            "symbol": "MIX",
            "name": "MIXED",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TtiEDoN8e4a1BuLSLk9jXeEsgqhWDHSzp3Cv3sHyJWG/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ChanpuruRabbits"
            }
        },
        {
            "chainId": 101,
            "address": "mokbjVzjKtUF87rChcRoF8ZBTT2SwTuovLXbUkhAw8Y",
            "symbol": "GOD",
            "name": "LOVE GOD",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/krazyivan46/LoveGod/125a9eae9beb9e5df1873175ece7436e947e0118/sun.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HQZa7v6bpYzBun8HdPvB7MQGtrS44e9638rML9jTVujn",
            "symbol": "SHTR",
            "name": "ShenTaro",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ShenTaro/ShenTaro/main/logo.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "5QMPH5cCrxiQ59gBvqqtauDoA2DGBgaP7qGyRGGXztBz",
            "symbol": "SEND",
            "name": "Sendinair Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/sendinair/logo/main/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4UDbU1he3FzNNQieGcoS3wjXbrm8BDLzM38U8PVzuw36",
            "symbol": "DGENZ",
            "name": "Degen Coinz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Wcworker/Wcdonalds/main/DGENZ.png",
            "tags": [
                "community-token",
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/DegenboyzDao",
                "website": "https://degenboyzdao.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "8TkVVFpdo1WRQvjtQzMzR8LisoAnp2gCs8qTMNVeSXgN",
            "symbol": "SPEE",
            "name": "SpaceEve",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/leonardleonard/cyption/main/bighorse.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BR7Bq2E7oH7RCb8b1fq2zCo1N7Ad85Ju8rnNp1e8UXs",
            "symbol": "KAA",
            "name": "Kaavs Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/cybersecmom/crypto/main/kaavs.png",
            "tags": [
                "social-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/kaavs"
            }
        },
        {
            "chainId": 101,
            "address": "8UaGbxQbV9v2rsMxy2G2NEh1JYQ8T9dmggq1oesrXD9c",
            "symbol": "RIBH",
            "name": "Ribh",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8UaGbxQbV9v2rsMxy2G2NEh1JYQ8T9dmggq1oesrXD9c/logo.png",
            "tags": [
                "DeFi",
                "stablecoin"
            ],
            "extensions": {
                "discord": "https://discord.gg/vC4cmuZw",
                "linkedin": "https://linkedin.com/company/ribh",
                "twitter": "https://twitter.com/Ribh00857956",
                "website": "https://ribh.finance"
            }
        },
        {
            "chainId": 101,
            "address": "2ez6ioJ5vpNonQEj3GBiHaK5yVu46QuQVLBnbjjiShvi",
            "symbol": "SXA3DWL",
            "name": "Space X Apes 3D Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ez6ioJ5vpNonQEj3GBiHaK5yVu46QuQVLBnbjjiShvi/logo.png",
            "extensions": {
                "discord": "https://discord.gg/Mfgnj3xBk3",
                "twitter": "https://twitter.com/spacexapes"
            }
        },
        {
            "chainId": 101,
            "address": "BoeDfSFRyaeuaLP97dhxkHnsn7hhhes3w3X8GgQj5obK",
            "symbol": "svtFFF",
            "name": "Famous Fox Federation Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BoeDfSFRyaeuaLP97dhxkHnsn7hhhes3w3X8GgQj5obK/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "Ca5eaXbfQQ6gjZ5zPVfybtDpqWndNdACtKVtxxNHsgcz",
            "symbol": "svtSMB",
            "name": "Solana Monkey Business Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ca5eaXbfQQ6gjZ5zPVfybtDpqWndNdACtKVtxxNHsgcz/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "Bp6k6xacSc4KJ5Bmk9D5xfbw8nN42ZHtPAswEPkNze6U",
            "symbol": "svtPSK",
            "name": "Pesky Penguins Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bp6k6xacSc4KJ5Bmk9D5xfbw8nN42ZHtPAswEPkNze6U/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "Bc1PESHDH2VEbAorhiwVBcnPH1FNwsX7TnbzpPELGpUi",
            "symbol": "svtGENO",
            "name": "Genopets Genesis Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bc1PESHDH2VEbAorhiwVBcnPH1FNwsX7TnbzpPELGpUi/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "DCgRa2RR7fCsD63M3NgHnoQedMtwH1jJCwZYXQqk9x3v",
            "symbol": "svtDGOD",
            "name": "DeGods Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DCgRa2RR7fCsD63M3NgHnoQedMtwH1jJCwZYXQqk9x3v/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "DpEoLovqcQDD2QkjybbFezRKbwiVxmYaNMEiEocP2Ucm",
            "symbol": "ALUM",
            "name": "Alumina",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DpEoLovqcQDD2QkjybbFezRKbwiVxmYaNMEiEocP2Ucm/alumina.png",
            "tags": [
                "dApp",
                "develop"
            ]
        },
        {
            "chainId": 101,
            "address": "Ea5yp55vuw7GQRPNFiFAvcbD8pfFjfF5B68dMqugT6pT",
            "symbol": "STAK",
            "name": "Stake Pot",
            "decimals": 9,
            "logoURI": "https://s3.ap-southeast-1.amazonaws.com/media.stake.pot/token-logo.jpg",
            "tags": [
                "Staking"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Stake_pot",
                "website": "https://stakepot.net/",
                "youtube": "https://www.youtube.com/channel/UCzn6CGi2FRAEBWND1Gn_EXg"
            }
        },
        {
            "chainId": 101,
            "address": "8vkTew1mT8w5NapTqpAoNUNHW2MSnAGVNeu8QPmumSJM",
            "symbol": "svtWAVE",
            "name": "Playground Waves Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8vkTew1mT8w5NapTqpAoNUNHW2MSnAGVNeu8QPmumSJM/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "3b9wtU4VP6qSUDL6NidwXxK6pMvYLFUTBR1QHWCtYKTS",
            "symbol": "svtEPOCH",
            "name": "Playground Epochs Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3b9wtU4VP6qSUDL6NidwXxK6pMvYLFUTBR1QHWCtYKTS/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "EiasWmzy9MrkyekABHLfFRkGhRakaWNvmQ8h5DV86zyn",
            "symbol": "svtVSNRY",
            "name": "Visionary Studios Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EiasWmzy9MrkyekABHLfFRkGhRakaWNvmQ8h5DV86zyn/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "4wGimtLPQhbRT1cmKFJ7P7jDTgBqDnRBWsFXEhLoUep2",
            "symbol": "svtFLARE",
            "name": "Lifinity Flares Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wGimtLPQhbRT1cmKFJ7P7jDTgBqDnRBWsFXEhLoUep2/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "3GQqCi9cuGhAH4VwkmWD32gFHHJhxujurzkRCQsjxLCT",
            "symbol": "svtGGSG",
            "name": "Galactic Geckos Space Garage Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GQqCi9cuGhAH4VwkmWD32gFHHJhxujurzkRCQsjxLCT/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "8W2ZFYag9zTdnVpiyR4sqDXszQfx2jAZoMcvPtCSQc7D",
            "symbol": "svtCWM",
            "name": "The Catalina Whale Mixer Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8W2ZFYag9zTdnVpiyR4sqDXszQfx2jAZoMcvPtCSQc7D/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "6F5A4ZAtQfhvi3ZxNex9E1UN5TK7VM2enDCYG1sx1AXT",
            "symbol": "svtDAPE",
            "name": "Degenerate Ape Academy Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6F5A4ZAtQfhvi3ZxNex9E1UN5TK7VM2enDCYG1sx1AXT/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "5NRkDy2avdRZcoAvBRRSFrFwY7uZsCTsKXtUSLdYGLag",
            "symbol": "svtTHUG",
            "name": "Thugbirdz Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5NRkDy2avdRZcoAvBRRSFrFwY7uZsCTsKXtUSLdYGLag/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9FjYMr4BDNzZPYzKEfmieVpUjsha2Nt84TW6nf2B7wr1",
            "symbol": "MAYCOIN",
            "name": "Maycoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9FjYMr4BDNzZPYzKEfmieVpUjsha2Nt84TW6nf2B7wr1/logo.png",
            "tags": [
                "utility-token",
                "stable-token"
            ],
            "extensions": {
                "website": "https://maycoin.org/"
            }
        },
        {
            "chainId": 101,
            "address": "3hEg9WVDbPP6MeAFxQ1K5UkhsQgh3NudoPgWaBb7dV6t",
            "symbol": "PAC",
            "name": "PlutoArtsCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3hEg9WVDbPP6MeAFxQ1K5UkhsQgh3NudoPgWaBb7dV6t/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://plutoarts.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6Fwhvgxy3jZfpxBR1C6DqU8xrmVhfjXAZEM4qu7kha4b",
            "symbol": "deDUST",
            "name": "deDUST",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg",
            "tags": [
                "NFT"
            ],
            "extensions": {
                "website": "https://docs.dustprotocol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2oPjhzsLmoeieYCtMVy5pdixm52116c2Hf1SwFPZtJCM",
            "symbol": "DARK",
            "name": "DARKHOOD",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2oPjhzsLmoeieYCtMVy5pdixm52116c2Hf1SwFPZtJCM/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/kjQJ3PPR6C",
                "twitter": "https://twitter.com/darkhoodnft",
                "website": "https://darkhood.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "7ZYwaUfN1BftozKcXv1a3Vhkd32y1VjD1NrDJRoDTDfR",
            "symbol": "BPLW",
            "name": "BPLW",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ZYwaUfN1BftozKcXv1a3Vhkd32y1VjD1NrDJRoDTDfR/logo.png",
            "tags": [
                "whitelist-token",
                "mint-token",
                "whitelist"
            ],
            "extensions": {
                "description": "Bizarre Platypus Legendary Whitelist",
                "twitter": "https://twitter.com/bizarreplatypus",
                "website": "https://market.bizarreplatypus.io/"
            }
        },
        {
            "chainId": 101,
            "address": "6THoystBVULftbbmjAWh39X6rkUW6tmBxSkryP3K3Zqx",
            "symbol": "JUSOLm",
            "name": "JUSOLm",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6THoystBVULftbbmjAWh39X6rkUW6tmBxSkryP3K3Zqx/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "Juiced SOL Mercurial Carton Token",
                "discord": "https://discord.gg/ZXBRGXccR2",
                "instagram": "https://www.instagram.com/juicedfi",
                "linkedin": "https://www.linkedin.com/company/juicedfi",
                "medium": "https://www.medium.com/@juicedfinance",
                "twitter": "https://twitter.com/JuicedProtocol",
                "website": "https://www.juiced.fi",
                "whitepaper": "https://juiced.fi/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "8Tn5rShodF6Y3wHwwPicB73v3VyZPN6kDYj55vCxW8yp",
            "symbol": "STAR",
            "name": "STAR",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/cybiz-tva/star-token/main/star_logo.png",
            "tags": [
                "Social-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "9kGmcsP5fQ6YdUhzq45dHQeMsTj66iZvt9NtRgFUEF1Y",
            "symbol": "JUBTCm",
            "name": "JUBTCm",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9kGmcsP5fQ6YdUhzq45dHQeMsTj66iZvt9NtRgFUEF1Y/logo.png",
            "tags": [
                "lp-token"
            ],
            "extensions": {
                "description": "Juiced BTC Mercurial Carton Token",
                "discord": "https://discord.gg/ZXBRGXccR2",
                "instagram": "https://www.instagram.com/juicedfi",
                "linkedin": "https://www.linkedin.com/company/juicedfi",
                "medium": "https://www.medium.com/@juicedfinance",
                "twitter": "https://twitter.com/JuicedProtocol",
                "website": "https://www.juiced.fi",
                "whitepaper": "https://juiced.fi/whitepaper.pdf"
            }
        },
        {
            "chainId": 101,
            "address": "61bjnxUgvq332wDVe1mtQ7TjFUNTxHGjgfebuj3WaihY",
            "symbol": "SHAKING",
            "name": "SHAKING",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/61bjnxUgvq332wDVe1mtQ7TjFUNTxHGjgfebuj3WaihY/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/9xdBTR2a3Y",
                "twitter": "https://twitter.com/ShakySocials"
            }
        },
        {
            "chainId": 101,
            "address": "6Fne1xxkJB9s8VZZpQuJ55tHZuG64d4p5qkE2qbstP1e",
            "symbol": "WNDO-LP",
            "name": "WNDO-LP",
            "decimals": 0,
            "logoURI": "https://static1.squarespace.com/static/6252f1590fac8d79fb6d772e/t/62a9dec0fbc2fd196557dc98/1655299776527/wndocoinLP.png",
            "tags": [
                "utility-token",
                "aldrin-wndo-lp"
            ],
            "extensions": {
                "description": "WNDO is a utility token used on the WNDO platform.  Buy stuff in the real world get rewarded in WNDO!",
                "discord": "https://discord.gg/8J7V9qTbj6",
                "twitter": "https://twitter.com/wndotoken",
                "website": "https://www.wndo.io/"
            }
        },
        {
            "chainId": 101,
            "address": "8H8HZgsLxGjqc496w5ejxPBzegZkM1NPjawmPbNtJygz",
            "symbol": "VOC",
            "name": "Voice operation coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8H8HZgsLxGjqc496w5ejxPBzegZkM1NPjawmPbNtJygz/logo.png",
            "tags": [
                "signalvoc"
            ],
            "extensions": {
                "website": "http://signalvoc.io/"
            }
        },
        {
            "chainId": 101,
            "address": "UtxmnUbYn194TDKo4wiyXLhA2mbTFnTCEjxdLPiPBKy",
            "symbol": "SUL",
            "name": "Sul Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/igorsul/sul-token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6xoY3ZUeGMdoXgZg4yyDXiLSFUwwfEgrdguKaCip9Hkx",
            "symbol": "LVUNI",
            "name": "LOVE UNIVERSE",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/krazyivan46/universe/main/bigsun.png",
            "tags": [
                "community-token",
                "utility-token",
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "J2X22dpPLyQCsTb4pB4623pvdZzGiNn27YycZvoSub32",
            "symbol": "C+H",
            "name": "C+H Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/hrodriguezfigueroa/crypto/main/crytpologo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DsKBCYD9bqppJTLgzEM5YBmZm2M5EvJLXmKTDDoych47",
            "symbol": "TOPAZ",
            "name": "TOPAZ",
            "decimals": 9,
            "logoURI": "https://github.com/drsaint1/drsaint/blob/main/gemsto.png",
            "tags": [
                "Utility-token",
                "community-token",
                "Meme-token",
                "Gaming",
                "DeFi-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/sErP34DJj5",
                "twitter": "https://twitter.com/topaz_sol"
            }
        },
        {
            "chainId": 101,
            "address": "5zAAGYqrQqSun77QkHd3RhpM45ktEDmKXDvLy34WrKAy",
            "symbol": "TLC",
            "name": "Tender Loving Care Initiative",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5zAAGYqrQqSun77QkHd3RhpM45ktEDmKXDvLy34WrKAy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DXA9itWDGmGgqqUoHnBhw6CjvJKMUmTMKB17hBuoYkfQ",
            "symbol": "svtHNYG",
            "name": "Honey Genesis Bee Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DXA9itWDGmGgqqUoHnBhw6CjvJKMUmTMKB17hBuoYkfQ/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "CT1iZ7MJzm8Riy6MTgVht2PowGetEWrnq1SfmUjKvz8c",
            "symbol": "svtBV",
            "name": "Balloonsville Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CT1iZ7MJzm8Riy6MTgVht2PowGetEWrnq1SfmUjKvz8c/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "ApBYh9W4n8ENAvuDDo7cqpJirMXbzzRYfsTYvNDxmcB2",
            "symbol": "SHWL",
            "name": "SharkListed",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ApBYh9W4n8ENAvuDDo7cqpJirMXbzzRYfsTYvNDxmcB2/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Shark Company NFT Official WL Token",
                "discord": "https://discord.gg/AzEDxKQf9v",
                "instagram": "https://instagram.com/ofa_nft?igshid=YmMyMTA2M2Y=",
                "twitter": "https://twitter.com/OFA_NFT",
                "website": "https://www.shark-company-nft.com/"
            }
        },
        {
            "chainId": 101,
            "address": "586Y61CZjs2cYWJHxBkoYAWzr4Uw6tmNLnGiMspen5hL",
            "symbol": "BCWL",
            "name": "Baked Cups White List Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/586Y61CZjs2cYWJHxBkoYAWzr4Uw6tmNLnGiMspen5hL/bc_wl_token.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "website": "https://stonercups.io/"
            }
        },
        {
            "chainId": 101,
            "address": "AADeU92VvTiNT94zk7GKryNyPhp2FWo5KXHZHHq8iHTc",
            "symbol": "TRPN",
            "name": "Trippin Coin",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AADeU92VvTiNT94zk7GKryNyPhp2FWo5KXHZHHq8iHTc/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "A coin created for use within the Trippin Bears DAO"
            }
        },
        {
            "chainId": 101,
            "address": "4Ey2wMeAKPPtSjuP4ZEQWzMVWUYauAhwEWLa7RJ1Wsqz",
            "symbol": "SLNB",
            "name": "SolanBots Whitelist",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJKX1cX2SPPaTdYBeuriUeQUUEpi2UGBGGPQthNMrgaa/logo.png"
        },
        {
            "chainId": 101,
            "address": "BYfBdm1sqa5x3BeqGb2UJ4M732XR6zaXJ8dxppUZhPoe",
            "symbol": "MOON",
            "name": "MOON",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYfBdm1sqa5x3BeqGb2UJ4M732XR6zaXJ8dxppUZhPoe/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/FqgeDjfu4v",
                "twitter": "https://twitter.com/TheMooners_NFT"
            }
        },
        {
            "chainId": 101,
            "address": "3XwZtyP5g2ku5cNzLXYcBYygvToXKdjw73GSRq73QYLQ",
            "symbol": "KREECHURE",
            "name": "Kreechures Floor Index",
            "decimals": 2,
            "logoURI": "https://i.imgur.com/xR8HIrL.png",
            "tags": [
                "fraction-index",
                "bridgesplit-fraction",
                "kreechures",
                "fnft"
            ],
            "extensions": {
                "discord": "https://discord.gg/bridgesplit",
                "twitter": "https://twitter.com/bridgesplit",
                "vault": "https://app.bridgesplit.com/fractions/3XwZtyP5g2ku5cNzLXYcBYygvToXKdjw73GSRq73QYLQ"
            }
        },
        {
            "chainId": 101,
            "address": "7xDgN47oTKfLpCtxd8fdnSfK4phC2WMo5QwwMKxePRHZ",
            "symbol": "$HUGZ",
            "name": "HUGZ",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xDgN47oTKfLpCtxd8fdnSfK4phC2WMo5QwwMKxePRHZ/hugs_token.png",
            "tags": [
                "nft",
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "9ZcYhfJyoVa5nWjJFLdfkdfR6wBsk9jgXWXDqREHnsDx",
            "symbol": "KTSC",
            "name": "Kitsune coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Walanm/kitsune-token/main/kitsune.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G2PFwn3qKfYoA4jEcP6AJ6JSS8GizDSqjdsadJpoLYxo",
            "symbol": "PARSOC",
            "name": "ParallelCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/ParallelSociety/ParallelCoin/main/ParallelCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KidWuYmiamWLXKkwkPiA3Fsfg5TExwDjB3UMA5t5jJQ",
            "symbol": "KDZ",
            "name": "Kidz",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KidWuYmiamWLXKkwkPiA3Fsfg5TExwDjB3UMA5t5jJQ/logo.png"
        },
        {
            "chainId": 101,
            "address": "ShyGFzMVVp9fVibf5ZnGVbgC9s9NkspZzqT8t6Dh4hx",
            "symbol": "ShyGuy",
            "name": "Shy Guys Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ShyGFzMVVp9fVibf5ZnGVbgC9s9NkspZzqT8t6Dh4hx/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/shyguysnfts"
            }
        },
        {
            "chainId": 101,
            "address": "AVWsBmfbzLehU9KDQCBuxFWc5cWauJGumL2iw8jYA4at",
            "symbol": "BDLT",
            "name": "BeardedLight",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/viledrow/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GBWT2DcrsgB6FpqtQQNLQp8ZX3wED4JTVHspMzYxGmbn",
            "symbol": "GBWT",
            "name": "Great Bulls Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GBWT2DcrsgB6FpqtQQNLQp8ZX3wED4JTVHspMzYxGmbn/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EH6SBH3DFTvJ1ViFFfTDdUMHF79T8T8UXdH5rTagRZcX",
            "symbol": "MARY",
            "name": "Weed Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/dpnlv/cryto-weed/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 103,
            "address": "J7M4qmEz3LPdVVcYzjmBinXWUk51Fp9B6SX5dHdzYneT",
            "symbol": "PLV",
            "name": "Planetverse Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/wooleah/planetverse-logo/main/logo.png",
            "tags": [
                "community-token",
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "5GovthC2NfAUwuYpSGmFbFT3pF1gMrJ5Hakbb817JYc5",
            "symbol": "RD",
            "name": "Race Dev",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/farhan067/RaceDev/main/logo.jpg"
        },
        {
            "chainId": 101,
            "address": "EmLJ8cNEsUtboiV2eiD6VgaEscSJ6zu3ELhqixUP4J56",
            "symbol": "svtTHUGZ",
            "name": "Thugbirdz - Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EmLJ8cNEsUtboiV2eiD6VgaEscSJ6zu3ELhqixUP4J56/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "4MSMKZwGnkT8qxK8LsdH28Uu8UfKRT2aNaGTU8TEMuHz",
            "symbol": "svtGENE",
            "name": "Genopets Genesis - Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4MSMKZwGnkT8qxK8LsdH28Uu8UfKRT2aNaGTU8TEMuHz/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "BjqFNKk8h4QYc2FptDNeccEtZPitKzeSgjxdTvYTRApT",
            "symbol": "E2N",
            "name": "Entertainment 29",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kumpagun/token_img/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "WERKZCY6o4eYu9cSh94s1RYC9rQG1parxuvwoW6FZAa",
            "symbol": "$WERK",
            "name": "WERK",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WERKZCY6o4eYu9cSh94s1RYC9rQG1parxuvwoW6FZAa/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/BakedBeavers",
                "website": "https://bakedbeavers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HYtdDGdMFqBrtyUe5z74bKCtH2WUHZiWRicjNVaHSfkg",
            "symbol": "svtAURY",
            "name": "Aurory - Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYtdDGdMFqBrtyUe5z74bKCtH2WUHZiWRicjNVaHSfkg/logo.png",
            "tags": [
                "utility-token",
                "solvent-droplet"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "9acdc5M9F9WVM4nVZ2gPtVvkeYiWenmzLW9EsTkKdsUJ",
            "symbol": "svtGOON",
            "name": "Gooney Toons Solvent Droplet",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9acdc5M9F9WVM4nVZ2gPtVvkeYiWenmzLW9EsTkKdsUJ/logo.png",
            "tags": [
                "solvent-droplet",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/thK8BCtQbM",
                "github": "https://github.com/solventprotocol",
                "medium": "https://medium.com/@solventprotocol",
                "telegram": "https://t.me/solventprotocol",
                "twitter": "https://twitter.com/solventprotocol",
                "website": "https://solvent.xyz"
            }
        },
        {
            "chainId": 101,
            "address": "BAKeByLipzovqThC8bMpgEycUCTvNcMU82aKu2L5ihcY",
            "symbol": "$BAKE",
            "name": "Baked Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/CMHayden/crypto/master/baked_logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "TLAJct3sZjNPCeu3gX2ia9XXYTGRyRgXN7c6NETQj3p",
            "symbol": "$TLA",
            "name": "TLA Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TLAJct3sZjNPCeu3gX2ia9XXYTGRyRgXN7c6NETQj3p/logo.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/thelostapesnft",
                "twitter": "https://twitter.com/thelostapesnft",
                "website": "https://thelostapes.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6Tf26EZ2F8efATQpodGKYMNMZccCTL1VPYzcC4kPF6cC",
            "symbol": "FOXIE",
            "name": "FOXIE TOKEN",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Tf26EZ2F8efATQpodGKYMNMZccCTL1VPYzcC4kPF6cC/FOXIE_coin.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://www.foxtopia.io/"
            }
        },
        {
            "chainId": 101,
            "address": "ETPz31G7uXGCAv8o2bDhWmx9ejZvNdmirg9x62N3AAga",
            "symbol": "RAID",
            "name": "RAID TOKEN",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/DefiTokens/assets/main/RAID%20TOKEN.png",
            "extensions": {
                "twitter": "https://twitter.com/SolRaiderz",
                "website": "https://solraiderz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2XwJ3VjMgSgAHUSTTg4p8649tjYSJWWyyNpg7VvBfEoH",
            "symbol": "KPFC",
            "name": "KPF SI Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2XwJ3VjMgSgAHUSTTg4p8649tjYSJWWyyNpg7VvBfEoH/logo.png"
        },
        {
            "chainId": 101,
            "address": "DCdMWbCEbVWQi6i4ndYBAGJkZSy3ZKSRwqJo9TyhBmxR",
            "symbol": "ARTMOB",
            "name": "Art of Mob",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DCdMWbCEbVWQi6i4ndYBAGJkZSy3ZKSRwqJo9TyhBmxR/logo2.png",
            "tags": [
                "game",
                "ticket",
                "fungible",
                "key",
                "puzzle"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/artofmob",
                "twitter": "https://twitter.com/artofmob",
                "website": "https://www.artofmob.io/"
            }
        },
        {
            "chainId": 101,
            "address": "9EZKnUZW24dvRyfcfNCbVVmTHfCfenaL4DxNuA2JhVg6",
            "symbol": "WA1JAY",
            "name": "WA1JAY QSO Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EZKnUZW24dvRyfcfNCbVVmTHfCfenaL4DxNuA2JhVg6/logo.png",
            "tags": [
                "ham-radio",
                "amateur-radio",
                "utility-token"
            ],
            "extensions": {
                "github": "https://github.com/wa1jay",
                "twitter": "https://twitter.com/_wa1jay_",
                "website": "https://www.wa1jay.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3Gbprit9N4kaGSTtLyEw99EunmQG4XC7fKZ3TguczAkm",
            "symbol": "$FBD",
            "name": "Fat Baby Dino",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Amboooo/token-list/main/assets/mainnet/3Gbprit9N4kaGSTtLyEw99EunmQG4XC7fKZ3TguczAkm/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AfnUWNAnTNVMw3nYGV1mBpBtabBQBtjp1PCF7Jqri9gp",
            "symbol": "Cruz",
            "name": "RojanCrypto",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Rojankhzxrtoken/logo/main/logo.png",
            "tags": [
                "Rojan-token",
                "OLAGG-token",
                "felpu-coin",
                "NFT-coin",
                "Stream-token",
                "Twitch-token",
                "Whitelist-token",
                "Metaverse-token",
                "souvenir-token",
                "Social-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for Nicolás Santa Cruz Metaverse of rojankhzxr ",
                "discord": "https://discord.gg/Rojan",
                "facebook": "https://facebook.com/RojankhzxrOP",
                "instagram": "https://instagram.com/Rojankhzxr",
                "linkedin": "https://search.brave.com/search?q=Carlos+Verbelen\u0026source=desktop",
                "medium": "https://joinentre.com/?referredBy=el0nmusk",
                "twitter": "https://twitter.com/Rojankhzxr",
                "website": "https://linktr.ee/Zues369"
            }
        },
        {
            "chainId": 101,
            "address": "6tFQQmUfMY8463jxDfTd6RaJesL2aHMq4HFp6S8Jtzm8",
            "symbol": "CLYDE",
            "name": "Clyde Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/DeltaClyde/Clyde/main/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Clydenft",
                "medium": "https://gitbook.clydenft.com/",
                "twitter": "https://twitter.com/ClydeNFT_"
            }
        },
        {
            "chainId": 101,
            "address": "CERSmXza6DcU3vy61sVRHsZBXPsEaHG5edu4jx9VZPvm",
            "symbol": "DCSC",
            "name": "DCS Coin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/ikhsanhdn/dcs/main/COIN.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WjqR6J4Fsf",
                "twitter": "https://twitter.com/dcsnft",
                "website": "https://dcsnft.com"
            }
        },
        {
            "chainId": 101,
            "address": "CoTPSHUat1Tt3tAVPKmxMYNCqCcdaN2625WexfDVxWaK",
            "symbol": "MPD",
            "name": "CoscuArmy",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/coscuToken/logo/main/logo.png",
            "tags": [
                "coscu-token",
                "perez-token",
                "coscuArmy-coin",
                "NikeNFT-coin",
                "Stream-token",
                "Twitch-token",
                "Whitelist-token",
                "Metaverse-token",
                "souvenir-token",
                "Social-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for Martin Pérez Disalvo Metaverse of coscu ",
                "discord": "https://www.twitch.tv/coscu",
                "facebook": "https://www.youtube.com/user/Shacoscu",
                "instagram": "https://www.instagram.com/martinpdisalvo/",
                "linkedin": "https://search.brave.com/search?q=Carlos+Verbelen\u0026source=desktop",
                "medium": "https://joinentre.com/?referredBy=el0nmusk",
                "twitter": "https://twitter.com/Martinpdisalvo",
                "website": "https://linktr.ee/Zues369"
            }
        },
        {
            "chainId": 101,
            "address": "rekTBEUuEBVz6mqbfLmE9d9JR4jrxV5r91LJfGdzufq",
            "symbol": "Rekt",
            "name": "Rekt",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rekTBEUuEBVz6mqbfLmE9d9JR4jrxV5r91LJfGdzufq/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/RRacoonClub"
            }
        },
        {
            "chainId": 101,
            "address": "H9d8boHN7RbUgAC22fJtVtq1iV3DCpHmuPNZe7v3Et9b",
            "symbol": "NVRCC",
            "name": "Car Culture Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H9d8boHN7RbUgAC22fJtVtq1iV3DCpHmuPNZe7v3Et9b/logo.png",
            "tags": [
                "community",
                "airdrop",
                "NVRCC"
            ],
            "extensions": {
                "discord": "https://discord.gg/metawhips",
                "twitter": "https://twitter.com/wccmetawhips",
                "website": "https://www.skrrrt.vip"
            }
        },
        {
            "chainId": 101,
            "address": "PZTeHGRrybTNY5bvCATmLyZB9DZjQcSwCyrjXmaTZWi",
            "symbol": "ZLO",
            "name": "Planet Zloba Token",
            "decimals": 9,
            "logoURI": "https://cdn.discordapp.com/attachments/949073265530396692/987038425179910194/Webp.net-gifmaker_11.gif",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/PlanetZloba",
                "website": "https://www.planetzloba.com/"
            }
        },
        {
            "chainId": 101,
            "address": "AGFNz4kCrWNrzV3E84MLPVu7qwbYdYbw3gkHBGHG2jXg",
            "symbol": "SUGAR",
            "name": "SUGAR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFNz4kCrWNrzV3E84MLPVu7qwbYdYbw3gkHBGHG2jXg/logo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/ImmoralKids"
            }
        },
        {
            "chainId": 103,
            "address": "334K1HSC9cRgZHCiSB1auwUo6qTe6hZzyuKJhkj1V3iF",
            "symbol": "_RED",
            "name": "Dash Red",
            "decimals": 0,
            "logoURI": "https://uploads-ssl.webflow.com/61f2155bfe47bd05cae702bb/61f21670d6560ecc93050888_New%20Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/WjqR6J4Fsf",
                "twitter": "https://twitter.com/dcsnft",
                "website": "https://google.com"
            }
        },
        {
            "chainId": 101,
            "address": "siked6oyQgYee8tGpRP1P5WubqCEz4YSwiMGBvhY5o8",
            "symbol": "SIKE",
            "name": "Sukon Sike Token",
            "decimals": 9,
            "logoURI": "https://media.discordapp.net/attachments/978989653766078515/978993565529174016/PFP.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/yd22GdWx59",
                "twitter": "https://twitter.com/SukonNFT"
            }
        },
        {
            "chainId": 101,
            "address": "vip3cYmE2vuBoNA92h1URVNgXWu23k31b1fbL3b9rVy",
            "symbol": "OVIP",
            "name": "Outerverse Passport Pre-Sale Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vip3cYmE2vuBoNA92h1URVNgXWu23k31b1fbL3b9rVy/spl-passport.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/outerverse",
                "instagram": "https://instagram.com/outsideio",
                "twitter": "https://twitter.com/outsideio",
                "website": "https://outside.io"
            }
        },
        {
            "chainId": 101,
            "address": "7yXDS8PyirBD1beaXJPt5hSX3a5H6VLYDSxHJ5Ce86RJ",
            "symbol": "TSL",
            "name": "Tshadderlouch",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7yXDS8PyirBD1beaXJPt5hSX3a5H6VLYDSxHJ5Ce86RJ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "C6ZkcqRDdBxWoXvgsEuPxEWbmJTSssf3stqMznfETcAT",
            "symbol": "DGEM",
            "name": "DinoGem",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/RolandPixeldust/PXCoin/main/degem.gif",
            "tags": [
                "community-token",
                "metaverse-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TeddyTRexNFT",
                "website": "https://linktr.ee/teddytrex"
            }
        },
        {
            "chainId": 101,
            "address": "DhcHSWkfB7mz5mhMLJ2hBkGEJBvGbcj2ZCA1NNLb4BR5",
            "symbol": "wDUST",
            "name": "wDUST",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg",
            "tags": [
                "NFT",
                "social-token"
            ],
            "extensions": {
                "website": "https://docs.dustprotocol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "NTEo5UrWK8t5xATPjCRsxgZF9NHWNj6xCV5AVXFupzp",
            "symbol": "NOTE",
            "name": "Note",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NTEo5UrWK8t5xATPjCRsxgZF9NHWNj6xCV5AVXFupzp/logo.png"
        },
        {
            "chainId": 101,
            "address": "3TMxuBEMAV3BQunMBrFtKf8UQT2LmJchVbnV2o2ddkZU",
            "symbol": "Orbs",
            "name": "Orbits",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3TMxuBEMAV3BQunMBrFtKf8UQT2LmJchVbnV2o2ddkZU/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/UnfrgtnOrbit",
                "website": "https://unfrgtn.space"
            }
        },
        {
            "chainId": 103,
            "address": "6nyiZ7uHKPHRhZa6nw2eBS4njxStM6kmEUr6uLFhJz9Z",
            "symbol": "PCS",
            "name": "Precious Jewelry",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6nyiZ7uHKPHRhZa6nw2eBS4njxStM6kmEUr6uLFhJz9Z/pcs_ico.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://precious.capital/"
            }
        },
        {
            "chainId": 101,
            "address": "6nZ4YgDjENVa7y9P8YBxnfgR8CkTxzuQo82w1Bv1tmZE",
            "symbol": "Waifu",
            "name": "Vkryp",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/Vkryp/logo/main/logo.png",
            "tags": [
                "Vkryp-token",
                "Waifu-token",
                "VkrypArmy-coin",
                "NFT-coin",
                "Twitch-token",
                "Whitelist-token",
                "Metaverse-token",
                "Souvenir-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for Valentina Krypt  Metaverse of Vkryp ",
                "discord": "https://www.youtube.com/channel/UCyc2NPlfZEwKZnqwFpjUZpA?view_as=subscriber",
                "facebook": "https://www.facebook.com/ValentinaKryp",
                "instagram": "https://www.instagram.com/vkryp",
                "linkedin": "https://search.brave.com/search?q=Carlos+Verbelen\u0026source=desktop",
                "medium": "https://joinentre.com/?referredBy=el0nmusk",
                "twitter": "https://twitter.com/VKryp",
                "website": "https://linktr.ee/Zues369"
            }
        },
        {
            "chainId": 101,
            "address": "44QG67xkB8ZTKwov3HyBFmapchQZyH4UZEQVv5ddVoEw",
            "symbol": "KILL",
            "name": "kilua",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/kiluavineeth/crypto/main/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CiCUpSy2nZiL5AraQb2G1yqehJa1fzYJecR9W3ytBEDy",
            "symbol": "BUNJ",
            "name": "BunjCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jmowers21/Crypto/main/BunjCoin.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "yUNZUz7N5gfvdfgPaz3ddSVcKD3UbgHQYoSWitTt6jL",
            "symbol": "y-USN-4Pool",
            "name": "Yield Mercurial LP Token (USN-4Pool)",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yUNZUz7N5gfvdfgPaz3ddSVcKD3UbgHQYoSWitTt6jL/logo.svg",
            "tags": [
                "yield-token"
            ],
            "extensions": {
                "website": "https://bunnyducky.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8e6GfNcPA4Dm4QaAM7braoRnJW65VkzBg4WDrsqcSE4q",
            "symbol": "Ozark",
            "name": "Ozarks",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8e6GfNcPA4Dm4QaAM7braoRnJW65VkzBg4WDrsqcSE4q/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/degodsnft",
                "website": "https://degods.com"
            }
        },
        {
            "chainId": 101,
            "address": "9HYUHuQPixPvRnWCaVWcwFX9o2GGDBJiPWTVveoPVViA",
            "symbol": "SUITES",
            "name": "Sol Suites Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9HYUHuQPixPvRnWCaVWcwFX9o2GGDBJiPWTVveoPVViA/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wzEfUjmpFw",
                "twitter": "https://twitter.com/JellyBabiesNFT"
            }
        },
        {
            "chainId": 101,
            "address": "Gnu31UNtmpX5aEG49pLonWPoxpi6mw1KCoS7YHtT2eay",
            "symbol": "Skov",
            "name": "UberDanger",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/uberdangerNFT/logo/main/logo.png",
            "tags": [
                "UberDander-token",
                "skov-token",
                "choTastic-token",
                "NFT-coin",
                "Stream-token",
                "Twitch-token",
                "Whitelist-token",
                "Metaverse-token",
                "souvenir-token",
                "Social-token"
            ],
            "extensions": {
                "description": "Made by Carlos Verbelen for Jonathan Skov  Metaverse of UberDanger ",
                "discord": "https://t.co/hKwN9HIpR9",
                "facebook": "https://www.twitch.tv/uberdanger",
                "instagram": "https://t.co/f9BqQSXJx1",
                "linkedin": "https://search.brave.com/search?q=Carlos+Verbelen\u0026source=desktop",
                "medium": "https://joinentre.com/?referredBy=el0nmusk",
                "twitter": "https://twitter.com/UberDanger",
                "website": "https://linktr.ee/Zues369"
            }
        },
        {
            "chainId": 101,
            "address": "EMESiMvw9SAEy3qkG1ehCEh1GPa8D6WvGfJsm2MqvXC1",
            "symbol": "WENLIST",
            "name": "WenList Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EMESiMvw9SAEy3qkG1ehCEh1GPa8D6WvGfJsm2MqvXC1/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/hDHMmyATkp",
                "twitter": "https://twitter.com/wenmint_nft",
                "website": "https://linktr.ee/wenmint"
            }
        },
        {
            "chainId": 101,
            "address": "GcPoD4ACCTq3aiY87guMpnspGys5q6D5De1nK6fdJHrH",
            "symbol": "HEAVEN",
            "name": "HEAVEN AngelzDAO",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/archangelraphael6/heaven/main/%EF%BF%BDheaven.png"
        },
        {
            "chainId": 101,
            "address": "AeQtqTrBZ8JjboVSc5tYiLds9xEr2PiHqv4c4wrUYxkX",
            "symbol": "GLCO2",
            "name": "GOLD CARBON CO2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AeQtqTrBZ8JjboVSc5tYiLds9xEr2PiHqv4c4wrUYxkX/logo.png"
        },
        {
            "chainId": 101,
            "address": "7yBVTVAyV1m93snvzsEiWYfYYLFHbVEAkZjwaMX7ihkp",
            "symbol": "SLCO2",
            "name": "SILVER CARBON CO2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7yBVTVAyV1m93snvzsEiWYfYYLFHbVEAkZjwaMX7ihkp/logo.png"
        },
        {
            "chainId": 101,
            "address": "94k97BcKwqc5hRrjhhP9UnTfZ9NZWvHrqNprwFAG6ZZe",
            "symbol": "PLCO2",
            "name": "PLATINUM CARBON CO2",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94k97BcKwqc5hRrjhhP9UnTfZ9NZWvHrqNprwFAG6ZZe/logo.png"
        },
        {
            "chainId": 101,
            "address": "BscWcg4xTkqDVuCBHJyzaCzuCGoxU3eCiMyZW8mqQtSr",
            "symbol": "Lingo",
            "name": "Mundo Lingo",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/Kironu/lingo/main/logo.png",
            "tags": [
                "Event-token",
                "Language-token",
                "Community-token",
                "souvenir-token",
                "Social-token"
            ],
            "extensions": {
                "description": "Created by the Mundo Lingo Community",
                "facebook": "https://www.facebook.com/mundolingo",
                "instagram": "https://www.instagram.com/mundolingo/",
                "linkedin": "https://www.linkedin.com/company/mundo-lingo",
                "telegram": "https://t.me/mundolingo",
                "twitter": "https://twitter.com/MundoLingoDAO",
                "website": "https://mundolingo.org"
            }
        },
        {
            "chainId": 103,
            "address": "4xGpVniM6jfZmM1RQPhRND66BgjwxyaymKCftCu3JHUT",
            "symbol": "Blik",
            "name": "Blik",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/ariyila1/Blik1/main/bliklogo.png",
            "tags": [
                "social-token",
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4s9xjCxps1oAGiTkc3nWCjYREHW73vtKxnftygPejqLs",
            "symbol": "Peng",
            "name": "PengSol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4s9xjCxps1oAGiTkc3nWCjYREHW73vtKxnftygPejqLs/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/pengsol_",
                "website": "https://pengsol.com"
            }
        },
        {
            "chainId": 101,
            "address": "5EpU2fsLxxhyKDrhC33RED6sJrhbEDhbofos2ebuGwS2",
            "symbol": "KASG",
            "name": "Safari Guide WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5EpU2fsLxxhyKDrhC33RED6sJrhbEDhbofos2ebuGwS2/logo.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 101,
            "address": "91bysDbvspZCTHydDffNEDHiGmw8C3vDtYhPt5GtVi6G",
            "symbol": "KAZK",
            "name": "Zoo Keeper WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/91bysDbvspZCTHydDffNEDHiGmw8C3vDtYhPt5GtVi6G/logo.png",
            "tags": [
                "wl-token"
            ]
        },
        {
            "chainId": 103,
            "address": "98xavC6UB6bWvWTfLca45Jv2aEXo5hWnDBWiB8PApseY",
            "symbol": "PQL",
            "name": "Piqsol",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVvSwwQDpqWTQu739vqatKyyCXan7G1fibU9d5cXvXeX/piqsol.png",
            "tags": [
                "social-token",
                "community-token",
                "utility-token",
                "nft-market"
            ],
            "extensions": {
                "description": "Fractional Solana NFT Marketplace",
                "discord": "https://discord.gg/TmKBmyrGHY",
                "facebook": "https://www.facebook.com/piqsol",
                "instagram": "https://www.instagram.com/piqsol.art",
                "medium": "https://medium.com/@social_91523",
                "reddit": "https://www.reddit.com/user/Piqsol_NFT",
                "telegram": "https://t.me/Piqsol",
                "twitter": "https://twitter.com/Piqsol"
            }
        },
        {
            "chainId": 101,
            "address": "3npZ7QNBiRLYNrGqEy4Uefm5GXdruPBoxS7FktV2bkE3",
            "symbol": "SHUERN",
            "name": "Shuern Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3npZ7QNBiRLYNrGqEy4Uefm5GXdruPBoxS7FktV2bkE3/logo.png",
            "tags": [
                "utility-token",
                "stable-token"
            ],
            "extensions": {
                "website": "https://shuern.com/"
            }
        },
        {
            "chainId": 101,
            "address": "8Jy2rosjj6mQWr7LUKZ8EKkH62hpjJUmuSarkR6GWTUe",
            "symbol": "PHUTO",
            "name": "Phuto Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Jy2rosjj6mQWr7LUKZ8EKkH62hpjJUmuSarkR6GWTUe/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://phuto.one/"
            }
        },
        {
            "chainId": 101,
            "address": "GrfuLNraAr4Niq8hVp6g8jgYiBCd3KHyDkq5aP29M1dD",
            "symbol": "OFC",
            "name": "Osmium Foundation Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GrfuLNraAr4Niq8hVp6g8jgYiBCd3KHyDkq5aP29M1dD/logo.png",
            "tags": [
                "utility-token",
                "stable-token"
            ],
            "extensions": {
                "website": "https://osmiumfoundation.org/"
            }
        },
        {
            "chainId": 101,
            "address": "FHFFNMpS2asn6MBdRxdRTgLe9Epg4B6RKbmztrwr6VuH",
            "symbol": "SEACoin",
            "name": "Seeing Eye Arts Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FHFFNMpS2asn6MBdRxdRTgLe9Epg4B6RKbmztrwr6VuH/logo.png",
            "tags": [
                "utility-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://seeingeyefilms.com/"
            }
        },
        {
            "chainId": 101,
            "address": "HcmaQogPXJBNDBWNHCEDSDuLQ8sRkYbEogCbRdW2EJJP",
            "symbol": "ANC",
            "name": "Ansh",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AshuJoshi001/crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3EhmFENLbXU6eDhWM15EJj84gabPwCDVKDc9dHd3vYZn",
            "symbol": "TZAR",
            "name": "TZAR",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EhmFENLbXU6eDhWM15EJj84gabPwCDVKDc9dHd3vYZn/logo.png"
        },
        {
            "chainId": 101,
            "address": "A7CiKDRc3hQBezJq3Pe4g8fdKitJzaZwA8KRJNJnSwRJ",
            "symbol": "LAWLESS",
            "name": "Lawless Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A7CiKDRc3hQBezJq3Pe4g8fdKitJzaZwA8KRJNJnSwRJ/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "MDNTEcP4wVVWcpeQvojy1VnunpwtiydMZVzPeWWZYP3",
            "symbol": "MIDNIGHT",
            "name": "MIDNIGHT",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MDNTEcP4wVVWcpeQvojy1VnunpwtiydMZVzPeWWZYP3/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MidnightPanthrs",
                "website": "https://www.midnightpanthers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Cr45u6cdxB8oz7m3f8b89eacpwZh9DsRdQsNSzwmxh9R",
            "symbol": "GM",
            "name": "GM",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Cr45u6cdxB8oz7m3f8b89eacpwZh9DsRdQsNSzwmxh9R/logo.png",
            "tags": [
                "utility-token",
                "community-token"
            ],
            "extensions": {
                "website": "https://gmchimps.com"
            }
        },
        {
            "chainId": 101,
            "address": "GkywroLpkvYQc5dmFfd2RchVYycXZdaA5Uzix42iJdNo",
            "symbol": "DROID",
            "name": "Droid DAO Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/LinYu1992/Droid_Capital_Token/main/Droid_coin_tiny_1.png",
            "tags": [
                "utility-token",
                "community-token",
                "social-token"
            ],
            "extensions": {
                "website": "https://droidcapital.net/"
            }
        },
        {
            "chainId": 101,
            "address": "GCkaPdsEtN8bU9zVFhLL9yfXX82mGjtNkasU6bChMwYC",
            "symbol": "SNDT",
            "name": "Sharded Ninjas Dao Token",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/pboudib/token-list/main/assets/mainnet/GCkaPdsEtN8bU9zVFhLL9yfXX82mGjtNkasU6bChMwYC/logo.png",
            "tags": [
                "utility-token",
                "community-token",
                "nft-token"
            ],
            "extensions": {
                "discord": "https://www.discord.gg/hPvMSfxYha",
                "twitter": "https://twitter.com/pixeledfaces"
            }
        },
        {
            "chainId": 101,
            "address": "BoNEQFUfvNMWww7afX4HPyjJvT4XcQsohUgxtuNKK6vW",
            "symbol": "BONE",
            "name": "BONE Protocol",
            "decimals": 9,
            "logoURI": "https://user-images.githubusercontent.com/99852311/174297041-c9e4f9a4-7505-4486-94a0-22bd377e213f.png",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/bulldogbillionaires",
                "twitter": "https://twitter.com/bbsolana",
                "website": "https://docs.boneprotocol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "7gsBUE9ZBSWL3AAx9guwBt2maoVTqv1xo2KHzMorzftj",
            "symbol": "NDWL1",
            "name": "Nezumi Dominion WL1 Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kikser1214/wl1/main/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Zm5CvezwTE",
                "twitter": "https://twitter.com/NezumiDominion",
                "website": "https://www.nezumidominion.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Cw2yB2XvmmLpPz6EjxUns3F2yeuB6XCHfKa5qEfp7ddT",
            "symbol": "NDWL2",
            "name": "Nezumi Dominion WL2 Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Kikser1214/wl2/main/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/Zm5CvezwTE",
                "twitter": "https://twitter.com/NezumiDominion",
                "website": "https://www.nezumidominion.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6Q5wxz96Z49i7teDYVh1EpMFHbx4BfvQhVQdWWes7CeS",
            "symbol": "CKIN",
            "name": "CryptoKin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/MartinDarko/CryptoKin/main/logo.png",
            "tags": [
                "mint-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6UtdXDUWwd1fQ3ASSXe3JDy2q3TCoYkD11hZZ6Fk6jxy",
            "symbol": "LAZ",
            "name": "Lazar",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/aleks989/token-list/main/assets/mainnet/6UtdXDUWwd1fQ3ASSXe3JDy2q3TCoYkD11hZZ6Fk6jxy/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2W1DhZqkxpaUXnmndWXjCdCorW927o5iX1uVJWzPQn2U",
            "symbol": "HECTA",
            "name": "Hecta Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/Paradox-Coin/Paradox-Token/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GE5PkNtgA5DFidWEp6q7QuxBzT1AKbdjGiBQzUCUeiCg",
            "symbol": "LTMS",
            "name": "Let Me Speak Airdrop",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GE5PkNtgA5DFidWEp6q7QuxBzT1AKbdjGiBQzUCUeiCg/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "http://letmespeak.mint-link.live/"
            }
        },
        {
            "chainId": 101,
            "address": "2wc5AMR6MbV4RJh9mP5mzppvWYD88GEufrgJFh4Uwcge",
            "symbol": "HugMe",
            "name": "Hug me",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wc5AMR6MbV4RJh9mP5mzppvWYD88GEufrgJFh4Uwcge/logo.png",
            "tags": [
                "social-token",
                "underground-economy",
                "fun-token",
                "digital-coin"
            ]
        },
        {
            "chainId": 101,
            "address": "D3csbk4gBLzWMB2xjAYSoF6Gz8ZVS9R1QRs3KYLfaW7Y",
            "symbol": "ABT",
            "name": "Astrobabies Staking Token",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/0nkery/token-list/main/assets/mainnet/D3csbk4gBLzWMB2xjAYSoF6Gz8ZVS9R1QRs3KYLfaW7Y/logo.jpg",
            "tags": [
                "NFT",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/astrobabies",
                "instagram": "https://www.instagram.com/astrobabiesnft/",
                "twitter": "https://twitter.com/astrobabiesnft",
                "website": "https://www.astrobabies.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CergxRKPPUGkksdConEbvBweoyAhdyetVCgXuLzLAJmb",
            "symbol": "YTWL",
            "name": "Yeah Tigers Whitelist Serum",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CergxRKPPUGkksdConEbvBweoyAhdyetVCgXuLzLAJmb/logo.png",
            "tags": [
                "game",
                "ticket",
                "fungible",
                "key",
                "puzzle"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/yeahtigers",
                "twitter": "https://twitter.com/yeahtigers",
                "website": "https://www.yeahtigers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "74B7abWPggkf9r5vgNVMYk6MjXZhRg4dNeZzfAArfBXb",
            "symbol": "IDRK",
            "name": "IDR Kita",
            "decimals": 2,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/74B7abWPggkf9r5vgNVMYk6MjXZhRg4dNeZzfAArfBXb/logo.png",
            "tags": [
                "STABLECOIN"
            ]
        },
        {
            "chainId": 101,
            "address": "7GnAtLAoiXYhow3mE7szXHfkmWA5QEitbiHDXE4so9Jd",
            "symbol": "PengT",
            "name": "PengSolT",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GnAtLAoiXYhow3mE7szXHfkmWA5QEitbiHDXE4so9Jd/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/pengsol_",
                "website": "https://pengsol.com"
            }
        },
        {
            "chainId": 101,
            "address": "5VfPb7ndQRmi3fMQ3imVSMLJaB9VzGipSgCcyUfu9ySe",
            "symbol": "MOLE",
            "name": "molecoin",
            "decimals": 4,
            "logoURI": "https://talpa.cc/moles/place_mole.png",
            "tags": [
                "utility-token",
                "community-token",
                "spl-token",
                "social-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/mole",
                "website": "https://talpa.cc"
            }
        },
        {
            "chainId": 101,
            "address": "HitPa8XDRpaWzsLL3FwawBiRXS85wZ5Fc7fKdmY6uR5",
            "symbol": "HIT",
            "name": "HIT",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HitPa8XDRpaWzsLL3FwawBiRXS85wZ5Fc7fKdmY6uR5/Logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/nr38uAWK",
                "twitter": "https://twitter.com/HPenguinSM",
                "website": "https://hitmanpenguin.space/"
            }
        },
        {
            "chainId": 101,
            "address": "NortpVhRSY6qnx5gdT4QCibxdPuHDirWDweUnet9Boa",
            "symbol": "NORTH",
            "name": "The Northman NORTH",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NortpVhRSY6qnx5gdT4QCibxdPuHDirWDweUnet9Boa/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TheNorthmanNFT"
            }
        },
        {
            "chainId": 101,
            "address": "FEBoVniZ7CLoo3aRhDfZcBmPKQ2xjQCbeYjsZds9dzDp",
            "symbol": "BRSTRAIN",
            "name": "Brazilian Strain",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FEBoVniZ7CLoo3aRhDfZcBmPKQ2xjQCbeYjsZds9dzDp/logo.png",
            "tags": [
                "utility"
            ]
        },
        {
            "chainId": 101,
            "address": "7j6y5iZAZQkpV1U8EsEf6qu9fT4rVbP8ymCzb29av2KM",
            "symbol": "AF",
            "name": "Apes Fiction",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7j6y5iZAZQkpV1U8EsEf6qu9fT4rVbP8ymCzb29av2KM/logo.png",
            "tags": [
                "community-token",
                "meme-token",
                "apes"
            ],
            "extensions": {
                "twitter": "https://twitter.com/apesfiction",
                "website": "https://www.apesfiction.io"
            }
        },
        {
            "chainId": 103,
            "address": "9nqJa23g5jHjz4H8rSkXnFeUJgS4C9R26RRHPZMRqs5G",
            "symbol": "STONE",
            "name": "Scoby Stone",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nqJa23g5jHjz4H8rSkXnFeUJgS4C9R26RRHPZMRqs5G/logo.png"
        },
        {
            "chainId": 101,
            "address": "FoxeHgvq2qdPpD1AhaY9t7dX94uipnoNq2KyBAZHin69",
            "symbol": "PFWL",
            "name": "Private Foxes WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoxeHgvq2qdPpD1AhaY9t7dX94uipnoNq2KyBAZHin69/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/privatefoxes"
            }
        },
        {
            "chainId": 101,
            "address": "AZrUhAmYhaEC2HpKvJLnVhULHs2oHwCa6hZSFAGbS1uL",
            "symbol": "ASTRO",
            "name": "ASTRONOVA",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AZrUhAmYhaEC2HpKvJLnVhULHs2oHwCa6hZSFAGbS1uL/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Astronova is an nft project having 777 unique collectible cards based on Astrology having the 12 Zodiac signs, their constellations, signs, ruling planets and characters which are divided into some specials cards in each style.",
                "discord": "https://discord.gg/yksmDwJMhD",
                "instagram": "https://www.instagram.com/astronovanft/",
                "twitter": "https://twitter.com/astronovanfts",
                "website": "https://astronova.live/"
            }
        },
        {
            "chainId": 101,
            "address": "E2ckjDzdKzLt9XJmDGryDKxX5AFZZHGuY6nuYrW7HXEb",
            "symbol": "DMSCOGWL",
            "name": "DMSC OG-Collection WL Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E2ckjDzdKzLt9XJmDGryDKxX5AFZZHGuY6nuYrW7HXEb/logo.png",
            "tags": [
                "nft",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dmsc",
                "twitter": "https://twitter.com/DMSCNFT",
                "website": "https://www.dmsc-official.com"
            }
        },
        {
            "chainId": 101,
            "address": "7f97kMzEtcj1YBzqicYxy8B4kHURFQsGhDVhnxeLgapT",
            "symbol": "DDHK",
            "name": "DDH Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/yadavplusshubham/crpytosol/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3xNzdRumes6PKefrHYbfhCLAGJfBQQhYKTV2fgPrPat4",
            "symbol": "GOONZ-WL",
            "name": "GOONZ-WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gangstagoonz/Logo/main/logo.png",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/gangstagoonz",
                "twitter": "https://twitter.com/GangstaGoonz",
                "website": "https://gangstagoonz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "2g8dn7SA5xSkKELXpXmwe4bESacyaZgAS3m8M6ktRZ5s",
            "symbol": "JOC",
            "name": "Jero Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PIZZAMANXD/jerocoins/main/JeroCoins.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FUtxQAXuC4TgQvF7C5RTRsgqHvPqn4iHhMicRZKV775E",
            "symbol": "$PIGGY",
            "name": "Piggy Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FUtxQAXuC4TgQvF7C5RTRsgqHvPqn4iHhMicRZKV775E/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2gErBLEkw6YEzEJJoLybWd9vPJ4MNw7vLtchhWS7huZP",
            "symbol": "THC",
            "name": "TheHotboxCoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2gErBLEkw6YEzEJJoLybWd9vPJ4MNw7vLtchhWS7huZP/thc.png",
            "tags": [
                "utility-token",
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "G7PE6VyAFG7KG6qRAwXVHZhmJ4weETLU9UZqpPfA5evy",
            "symbol": "WAGMI-T2",
            "name": "WagmiTicket Tier 2",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Afterglow421/logotier2/main/logo/logo.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wagmibros",
                "twitter": "https://twitter.com/SolanaWagmiBros",
                "website": "https://wagmibros.club/"
            }
        },
        {
            "chainId": 101,
            "address": "Bdnh7zauhuBMDfWqMwDjMpmizAsYVznxzbT2ZUpvc19v",
            "symbol": "WAGMI-T3",
            "name": "WagmiTicket Tier 3",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Afterglow421/logotier3/main/logo/logo.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wagmibros",
                "twitter": "https://twitter.com/SolanaWagmiBros",
                "website": "https://wagmibros.club/"
            }
        },
        {
            "chainId": 101,
            "address": "BDThRUJADfNAVFmRKwQQZDS2WbVNu9A5aAeDELJGd1f1",
            "symbol": "M2SNL",
            "name": "M2SNL Coin",
            "decimals": 9,
            "logoURI": "https://github.com/m2snl/tokens/blob/main/m2snllogo-image-iconn-200x200.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JB65JZqmHs6mWcDtZcd7btYUs1RJs5qaPbTnuLR4xVjC",
            "symbol": "WXTCH",
            "name": "Wxtchcoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/matt-mac-btc/crypto/main/Webp.net-resizeimage.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AKF8z6wXgAiEhQ6vxTNTvo9tkMYYAngjHjt2uVjDasdU",
            "symbol": "MPNY",
            "name": "MonetPenny",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AKF8z6wXgAiEhQ6vxTNTvo9tkMYYAngjHjt2uVjDasdU/MonetPenny.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "http://monetpenny.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Cs3aQavALzbmozW4YtDfby9sSgfjdFdSJSJC9UEtBJYK",
            "symbol": "mvWALL",
            "name": "Metaversol Wall Piece",
            "decimals": 0,
            "logoURI": "https://assets.metaversol.com/buildingpieces/wallOG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Metaversol Building Pieces power building within The Expanse.",
                "discord": "https://discord.gg/metaversol",
                "twitter": "https://twitter.com/MxMetaversol",
                "website": "https://metaversol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6CNfYu4id92MVw98Xu3G4pTrK7x9ZSa7CxuThsCHWuTW",
            "symbol": "MBT",
            "name": "MetaBear token",
            "decimals": 0,
            "logoURI": "https://bafkreic2lqgk5223jcubgqewlig3tqaftq535ty3zyv7bb6njr7ivlaw3e.ipfs.nftstorage.link/",
            "tags": [
                "whitelist-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MetaBearNFT1",
                "website": "https://metabear-nft.com"
            }
        },
        {
            "chainId": 101,
            "address": "5ZvNHyLDZaxUTgTZ7BseAw3A9cqhcLrA9UCB1T2uCEH8",
            "symbol": "HORD",
            "name": "Hoard Gold",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5ZvNHyLDZaxUTgTZ7BseAw3A9cqhcLrA9UCB1T2uCEH8/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FoKYVPRDkYL9WN2C1of3Qm7PosCnMhbP2wPb2J89f8Yi",
            "symbol": "LTST",
            "name": "LIL TOAST TOKEN",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/LilDAOs/lilthings/main/logo.png",
            "tags": [
                "NFTs",
                "Utility-token",
                "Burn-token",
                "Community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2hs7TfCKgFCFb8i6mxbHT75RXgYQZXqahdVJkjsD999p",
            "symbol": "2ELT",
            "name": "2ELeet",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/stauffera12/2ELeet-token/main/Logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7EcoEurccG8R1ukYw6MzoU1HSADA6AnWtNf2sCHV1BWd",
            "symbol": "mvFOU",
            "name": "Metaversol Foundation Piece",
            "decimals": 0,
            "logoURI": "https://assets.metaversol.com/buildingpieces/foundationOG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Metaversol Building Pieces power building within The Expanse.",
                "discord": "https://discord.gg/metaversol",
                "twitter": "https://twitter.com/MxMetaversol",
                "website": "https://metaversol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6Ak6Kv9C7FFYx21F7b8qW6pfi7pjQyvZ73hbukFiXch5",
            "symbol": "mvDOOR",
            "name": "Metaversol Door Piece",
            "decimals": 0,
            "logoURI": "https://assets.metaversol.com/buildingpieces/doorOG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Metaversol Building Pieces power building within The Expanse.",
                "discord": "https://discord.gg/metaversol",
                "twitter": "https://twitter.com/MxMetaversol",
                "website": "https://metaversol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "G1EK7j5MWpPZsWmDqsJdPuyr3JyVx2UC9HcB636rN2Qh",
            "symbol": "mvFLOOR",
            "name": "Metaversol Floor Piece",
            "decimals": 0,
            "logoURI": "https://assets.metaversol.com/buildingpieces/floorOG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Metaversol Building Pieces power building within The Expanse.",
                "discord": "https://discord.gg/metaversol",
                "twitter": "https://twitter.com/MxMetaversol",
                "website": "https://metaversol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "3d7FkLQcxzh8rweXfS7PN3otDK3Le2V1sW1ZuvGJdvu5",
            "symbol": "mvWINDOW",
            "name": "Metaversol Window Piece",
            "decimals": 0,
            "logoURI": "https://assets.metaversol.com/buildingpieces/windowOG.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "Metaversol Building Pieces power building within The Expanse.",
                "discord": "https://discord.gg/metaversol",
                "twitter": "https://twitter.com/MxMetaversol",
                "website": "https://metaversol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "Emead5KsJBa1i53JTMmLJ4MAQrAtQ8x59JdL76jUr751",
            "symbol": "JUST",
            "name": "Just Bear Token",
            "decimals": 5,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Emead5KsJBa1i53JTMmLJ4MAQrAtQ8x59JdL76jUr751/logoJB.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "8tz5RTHk79tMUkP7E7hEoWuBCFQcKaTuJjyH8SwGFz7y",
            "symbol": "BYC",
            "name": "bubblyCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/rubikron/crypto2/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9nhuxLhaMGwUdAcnn53vJeXBt3qtjycSFqGefYGA829n",
            "symbol": "SHROOM",
            "name": "Trippin' Ape Tribe Mushroom Claim Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nhuxLhaMGwUdAcnn53vJeXBt3qtjycSFqGefYGA829n/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/TrippinApeNFT",
                "website": "https://www.trippinapetribe.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "29FFvE1yK7N2rw4UszxBfWtdaTpdh6fNj4Gh4pfNYTgK",
            "symbol": "mBTC",
            "name": "Mirrored Bitcoin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/ZuluSpl0it/crypto_logos/main/logo.png",
            "tags": [
                "wrapped",
                "wormhole"
            ],
            "extensions": {
                "assetContract": "https://solscan.io/token/29FFvE1yK7N2rw4UszxBfWtdaTpdh6fNj4Gh4pfNYTgK",
                "coinmarketcap": "https://coinmarketcap.com/currencies/mirrored-bitcoin/",
                "description": "Mirrored Bitcoin is a synthetic asset that mirrors BTC by reflecting the exchange prices on-chain.",
                "medium": "https://medium.com/mirror-protocol",
                "whitepaper": "https://docs.mirror.finance/"
            }
        },
        {
            "chainId": 101,
            "address": "6UZsQ5sCGCSJyxHbYrEjZTWvXmYsWEcKbd77AKi3oxxi",
            "symbol": "$TRUST",
            "name": "TRUST Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/rabindrarajawat/arr-solana/main/trust.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 103,
            "address": "B78NMBvVjrn2USLt4uy723qmN3fMhQ6cD5AuJtxqTtGL",
            "symbol": "TOM+JERRY",
            "name": "Tom and Jerry",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B78NMBvVjrn2USLt4uy723qmN3fMhQ6cD5AuJtxqTtGL/logo.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "KzkscetEFcPpwVQV2vfgs7zgA8W1gwdK7w8dJpfq25V",
            "symbol": "KZK",
            "name": "Kaizokoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KzkscetEFcPpwVQV2vfgs7zgA8W1gwdK7w8dJpfq25V/logo.png",
            "tags": [
                "NFT-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/k_mechanics",
                "website": "https://kaizokumechanics.io/"
            }
        },
        {
            "chainId": 101,
            "address": "CUR9kjRj86tD8Yb19hzoL182bz5U2CuFbkBNBbDVgQVk",
            "symbol": "$DAFT",
            "name": "DAFT Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/likebad/token-list/main/assets/mainnet/CUR9kjRj86tD8Yb19hzoL182bz5U2CuFbkBNBbDVgQVk/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FLWT2P3hdpPACswnkSeV6wivjGJrdP7XBzGhzTQD4Q4h",
            "symbol": "FLWT",
            "name": "Fine Lions Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLWT2P3hdpPACswnkSeV6wivjGJrdP7XBzGhzTQD4Q4h/logo.png",
            "tags": [
                "social-token",
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4LfWKsex7BCMTkBA7wn7RcfMYenKa1rSzzodk8Lwm4YB",
            "symbol": "$SNPDAO",
            "name": "SNIPEDAO",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/SnipeDAO123/coin/main/coin_PNG36944.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "H6ceG7s976p2XRKo8EhB3Mbfrro3YxRQfZjdr3z6eL4e",
            "symbol": "ORIGIN",
            "name": "Origin",
            "decimals": 8,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H6ceG7s976p2XRKo8EhB3Mbfrro3YxRQfZjdr3z6eL4e/logo.svg"
        },
        {
            "chainId": 101,
            "address": "5tB5D6DGJMxxHYmNkfJNG237x6pZGEwTzGpUUh62yQJ7",
            "symbol": "ROA",
            "name": "ROA CORE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5tB5D6DGJMxxHYmNkfJNG237x6pZGEwTzGpUUh62yQJ7/logo.png",
            "tags": [
                "utility-token",
                "NFT",
                "Metaverse"
            ],
            "extensions": {
                "discord": "https://discord.gg/f7JEbyNhG2",
                "telegram": "https://t.me/ROALAND_Notice",
                "twitter": "https://twitter.com/klayroaland",
                "website": "http://roaland.foundation"
            }
        },
        {
            "chainId": 102,
            "address": "2n4pLBdDtciUsEyR2WoS2cTgDiyTHysVQF4caHttV44v",
            "symbol": "USDT",
            "name": "USDT Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2n4pLBdDtciUsEyR2WoS2cTgDiyTHysVQF4caHttV44v/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://twitter.com/Tether_to"
            }
        },
        {
            "chainId": 102,
            "address": "5j2dS5kLdJowd6NpQ1pVykyyKDvRPZrHZGHgtyVG4PbD",
            "symbol": "LUNA",
            "name": "LUNA Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5j2dS5kLdJowd6NpQ1pVykyyKDvRPZrHZGHgtyVG4PbD/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://www.terra.money"
            }
        },
        {
            "chainId": 102,
            "address": "5oCd9mvp5zMy3bFHmyJrL6CMpcKCJt3peSRLrcEz5BW4",
            "symbol": "LINK",
            "name": "Link Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5oCd9mvp5zMy3bFHmyJrL6CMpcKCJt3peSRLrcEz5BW4/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://chain.link"
            }
        },
        {
            "chainId": 101,
            "address": "GamixUFH75ezjhPLH33QDFLezqTTsaXJDpyptABNHgpF",
            "symbol": "Shinigami",
            "name": "Shinigami Whitelist Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GamixUFH75ezjhPLH33QDFLezqTTsaXJDpyptABNHgpF/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Shinigami_SOL"
            }
        },
        {
            "chainId": 101,
            "address": "8BPiyiiC1NrmMWpyfrCmkFUkHmProC7dUBkn5twdZVMu",
            "symbol": "SNOWB",
            "name": "SnowBunie Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Dakender/Snowbunie-Coin/main/logo-snowbunie.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ELPHvy7GG3Z2niLAqtftVmQS3BvqqnsbfTngstWxYQu1",
            "symbol": "PATEL",
            "name": "Patelsakti",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELPHvy7GG3Z2niLAqtftVmQS3BvqqnsbfTngstWxYQu1/logo.png",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 103,
            "address": "7QLBsngBHcrJaZgpRjApGq7jgL7hzKdmVjyGbjUPj6u4",
            "symbol": "NSC",
            "name": "NeoSol Coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Walanm/crypto-logo/main/coin.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3tdV5k1aHV9TTuX66VF9DRL43b88LUMGC9PJbixNeMX1",
            "symbol": "GATE",
            "name": "GATE",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/DEAN3lr/HeavenDAO/main/%24GATE%20LOGO%20FINALIZED.png",
            "extensions": {
                "discord": "https://discord.gg/AKWJkzYKeE",
                "twitter": "https://twitter.com/HeavenDAO_SOL"
            }
        },
        {
            "chainId": 101,
            "address": "98Cz3pqMoNhR2w5UCAf4LBZ1FVc1L1yNxEk7gYyJV4NY",
            "symbol": "WL-Makaliilabs",
            "name": "Whitelist token Makalii Labs",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/pkpe/Dgenerate-Monkiez/3ee196c075eefb1424fa24f35e8f102dbdb34e33/12.png",
            "tags": [
                "WhiteList-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2E6Wt8f4rzKBtbabBNS2MSMZ2KX57TpK2bLWT8Gq9QDe",
            "symbol": "GOONZ",
            "name": "GOONZ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/gangstagoonz/Logo/main/tokenLogo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/gangstagoonz",
                "twitter": "https://twitter.com/GangstaGoonz",
                "website": "https://gangstagoonz.com/"
            }
        },
        {
            "chainId": 101,
            "address": "94JLbzRdcsaU3hDWFDT6TdFrKAct2rMCGBmt4St7KiVi",
            "symbol": "sjZT",
            "name": "sjZtoken",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94JLbzRdcsaU3hDWFDT6TdFrKAct2rMCGBmt4St7KiVi/logo.png",
            "tags": [
                "community-token"
            ],
            "extensions": {
                "website": "https://ad.nl"
            }
        },
        {
            "chainId": 101,
            "address": "6h7CpUqee5fACGNJMBc3WaRZiD9QRwWa7SNHPdWqNFPN",
            "symbol": "BMT",
            "name": "BunnyMask Token",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/KaoruYakabe/BunnyMasks/main/logo.PNG",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4C4ps2tct2sY8iSG1n5dzakHmSMxT1KgB9ofjaQJ3o1e",
            "symbol": "RSCL",
            "name": "RASCALS",
            "decimals": 1,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4C4ps2tct2sY8iSG1n5dzakHmSMxT1KgB9ofjaQJ3o1e/logo.png",
            "tags": [
                "Reward-Token"
            ]
        },
        {
            "chainId": 101,
            "address": "t2XBdWoCgBiiMFUSjxDtEJd84juJf5symYorzQ7PVxM",
            "symbol": "BABW",
            "name": "Build-A-Bear",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/INVERSOCoin/BABWCOIN/main/BABLOGO.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2PHMZHEnChKsBjRoAQSUAAkb2eUi8KAKYF9kpjbfP41i",
            "symbol": "NDOWL",
            "name": "New Degen Order WL Token",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2PHMZHEnChKsBjRoAQSUAAkb2eUi8KAKYF9kpjbfP41i/logo.png",
            "tags": [
                "Whitelist-Token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/NewDegenOrder",
                "website": "https://www.newdegenorder.com"
            }
        },
        {
            "chainId": 101,
            "address": "DGvuKn4smMNsmJbXXbQbn36wUfYFycjYqDYooFwDxzgz",
            "symbol": "POKE",
            "name": "PokeCoin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/PokeSaibott/Crypto/main/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "4kx8TkCWqGg9b9o8KmgsnS4voyxsBaaXRDGibPZ2MZVa",
            "symbol": "ACP",
            "name": "American Crypto Project",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/AmericanCryptoProject/UnitedStatesofAmerica/main/muse-token%20(1).png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GVqekT8RTeiHrMokgjLkexDcCkGkZYG1yF1ZPfwrDV3Q",
            "symbol": "INKA-COIN",
            "name": "Inka Coin",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVqekT8RTeiHrMokgjLkexDcCkGkZYG1yF1ZPfwrDV3Q/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/xQRTBcxb",
                "twitter": "https://twitter.com/OpportunitiesIO"
            }
        },
        {
            "chainId": 101,
            "address": "89XR1xC6uiV9qUbnoY4jTgTo9wtfS4DvyffCaWJFNo6F",
            "symbol": "YHHY",
            "name": "YHHYZZQ",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/89XR1xC6uiV9qUbnoY4jTgTo9wtfS4DvyffCaWJFNo6F/logo.png",
            "tags": [
                "social-token"
            ],
            "extensions": {
                "website": "https://bakedbeavers.com/"
            }
        },
        {
            "chainId": 101,
            "address": "4U4iYLTBChN3HdyuS1DfRqZ54Y772MVFYRvhoJLQUF8H",
            "symbol": "REALZ",
            "name": "Fuzion Realz Coin",
            "decimals": 2,
            "logoURI": "https://fuzionblox.com/fuzion_icon.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "REALZ Coin backed by bullion",
                "website": "https://fuzionblox.com"
            }
        },
        {
            "chainId": 101,
            "address": "kiTkNc7nYAu8dLKjQFYPx3BqdzwagZGBUrcb7d4nbN5",
            "symbol": "KI",
            "name": "Genopets Ki",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kiTkNc7nYAu8dLKjQFYPx3BqdzwagZGBUrcb7d4nbN5/logo.png",
            "tags": [
                "genopets",
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/genopets",
                "twitter": "https://twitter.com/genopets",
                "website": "https://genopets.me"
            }
        },
        {
            "chainId": 101,
            "address": "VRGEZtRamTVKinargJ7WstJHPSzYY9oGSQDYn6awDf8",
            "symbol": "$VRGE",
            "name": "VRGE Capacity",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/VRGEZtRamTVKinargJ7WstJHPSzYY9oGSQDYn6awDf8/logo.jpg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/Exiles_NFT",
                "website": "https://www.exilelabs.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "7xnRz9Cgh8QvbeqERG9AGoZkTjvX8dHQuBdKJz2hhzy1",
            "symbol": "MFCT",
            "name": "Mifacto Agro",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xnRz9Cgh8QvbeqERG9AGoZkTjvX8dHQuBdKJz2hhzy1/logo.jpg",
            "tags": [
                "community-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GdCpmasA74p8qcpKsySPwyAWEc8fiwXeM58J7CyRJUvM",
            "symbol": "GTCO2",
            "name": "CARBON CO2 GREEN TREE",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdCpmasA74p8qcpKsySPwyAWEc8fiwXeM58J7CyRJUvM/logo.png"
        },
        {
            "chainId": 101,
            "address": "DAP2s9i9d2GDrvAWKZ9dcQjVaXvJs97aGfQzTJSbwXW9",
            "symbol": "$DAPP",
            "name": "Dapper Ducks Web3",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAP2s9i9d2GDrvAWKZ9dcQjVaXvJs97aGfQzTJSbwXW9/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dapperducks",
                "website": "https://dapperducks.io"
            }
        },
        {
            "chainId": 101,
            "address": "DAPP6sHUBfVDRgZkcbXKPPDpFodJ7u58fYiByREX6BN",
            "symbol": "DAPP",
            "name": "Dapper Ducks Coin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DAPP6sHUBfVDRgZkcbXKPPDpFodJ7u58fYiByREX6BN/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/dapperducks",
                "website": "https://dapperducks.io"
            }
        },
        {
            "chainId": 101,
            "address": "HW6jM7jAZjHMYQf2u6eC91Efvm9CeQ2epq8gDizBYRS5",
            "symbol": "ZEB",
            "name": "ZEB Token",
            "decimals": 9,
            "logoURI": "https://img.freepik.com/free-vector/cute-banana-cartoon-mascot-logo_194935-71.jpg?w=2000",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "website": "https://jrby.xyz/"
            }
        },
        {
            "chainId": 101,
            "address": "Appn8xsma7ajLzsEADNhihNLJt2xVz8V1iqJa6zizqyU",
            "symbol": "WAGMSG",
            "name": "Make Solana Great",
            "decimals": 6,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Appn8xsma7ajLzsEADNhihNLJt2xVz8V1iqJa6zizqyU/logowagmsg.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/MakeSolanaGreat"
            }
        },
        {
            "chainId": 101,
            "address": "5KNQQe9gfSfgigddMGv5cWg1Z3zFodQP1b2bLDjGuvdE",
            "symbol": "WAGMI-T1",
            "name": "WagmiTicket Tier 1",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/Afterglow421/logotier1/main/logo/logo.png",
            "tags": [
                "utility-token",
                "whitelist-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/wagmibros",
                "twitter": "https://twitter.com/SolanaWagmiBros",
                "website": "https://wagmibros.club/"
            }
        },
        {
            "chainId": 101,
            "address": "4vqAHZgaQT6e1vGVhFjZVXGEejxJsiXpxsmjmw8LPtRK",
            "symbol": "WEDAO",
            "name": "WEDAO",
            "decimals": 8,
            "logoURI": "https://website-bucket.ams3.digitaloceanspaces.com/wedao.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/weyu",
                "telegram": "https://t.me/WEYUchat",
                "twitter": "https://twitter.com/weyuofficial",
                "website": "https://weyu.io"
            }
        },
        {
            "chainId": 101,
            "address": "Boxch4vwBdKjZjE4hixCaoQFvv16P9vwRHTFHWbC5o6f",
            "symbol": "BOXCH",
            "name": "Boxch",
            "decimals": 4,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Boxch4vwBdKjZjE4hixCaoQFvv16P9vwRHTFHWbC5o6f/logo.png",
            "extensions": {
                "instagram": "https://instagram.com/boxch_dapp",
                "telegram": "https://t.me/boxch",
                "twitter": "https://twitter.com/Boxch_dApp",
                "website": "https://boxch.net"
            }
        },
        {
            "chainId": 101,
            "address": "7P2n6h9J7BpPy4X9T8US7CPLrXzwnaSCkpXNDogNmkZv",
            "symbol": "XEGG",
            "name": "Project X Egg",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7P2n6h9J7BpPy4X9T8US7CPLrXzwnaSCkpXNDogNmkZv/logo.png",
            "tags": [
                "game",
                "ticket",
                "fungible",
                "key",
                "puzzle"
            ],
            "extensions": {
                "discord": "https://discord.com/invite/projectxsol",
                "twitter": "https://twitter.com/project_x_sol",
                "website": "https://www.projectxsol.com/"
            }
        },
        {
            "chainId": 101,
            "address": "6xPDRNZj5R5xekE2UBxBPvnaGQPi9QusNdwWbxSfDiMF",
            "symbol": "HRKJ",
            "name": "Harukeija tokens",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/harukeija/solana-public/main/HRKJ.jpg",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5qgWQNcFFxK6FQbiop3Gwbn1gPn4pwKm2Lk78S91JdBQ",
            "symbol": "SOLNFT0",
            "name": "Official Solana NFTs",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5qgWQNcFFxK6FQbiop3Gwbn1gPn4pwKm2Lk78S91JdBQ/logo.png",
            "tags": [
                "social-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AshJQ5LwqPMY75nJMpfVKBZwRUSYjPUjteRGfXC11zVA",
            "symbol": "roofBO",
            "name": "Roof Ballet Offense",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AshJQ5LwqPMY75nJMpfVKBZwRUSYjPUjteRGfXC11zVA/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "A3e98uof2bx412kojKTeLm1GFqQ4Y3hbkF7q1HUDUhMu",
            "symbol": "chooseRG",
            "name": "Choose Record Glance",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3e98uof2bx412kojKTeLm1GFqQ4Y3hbkF7q1HUDUhMu/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9gEdQy5os9myi4UGpzLwBvW795Eo8oFRtTdq8ttQyWtB",
            "symbol": "stakeGB",
            "name": "Stake Greet Beef",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gEdQy5os9myi4UGpzLwBvW795Eo8oFRtTdq8ttQyWtB/logo.png"
        },
        {
            "chainId": 101,
            "address": "DMbb79fgxR3fKKVKWcbetMCuxNNtJnfjY3qqAE4G4wJf",
            "symbol": "DUSK",
            "name": "Dusk",
            "decimals": 3,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMbb79fgxR3fKKVKWcbetMCuxNNtJnfjY3qqAE4G4wJf/logo.svg",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "discord": "https://discord.gg/milliononmars",
                "twitter": "https://twitter.com/MilliononMars",
                "website": "https://milliononmars.io/"
            }
        },
        {
            "chainId": 101,
            "address": "Hy2MBvogiASpQidJnfkBbm31uoPPB7Q99fcKBcEHRiFf",
            "symbol": "GMOOLAH",
            "name": "Gmoolah",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hy2MBvogiASpQidJnfkBbm31uoPPB7Q99fcKBcEHRiFf/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "twitter": "https://twitter.com/iamgmoot"
            }
        },
        {
            "chainId": 101,
            "address": "DMT5qtsrFicxvaFnnFwJJtNVU53V85Rt2Xhf6rRM3ySQ",
            "symbol": "DMT",
            "name": "Pineal Renegades OG WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMT5qtsrFicxvaFnnFwJJtNVU53V85Rt2Xhf6rRM3ySQ/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "The evolution of the authentication process of fine art. DMT",
                "discord": "https://discord.gg/pinealrenegades",
                "twitter": "https://twitter.com/PinealRenegade"
            }
        },
        {
            "chainId": 101,
            "address": "DMTRrwYy8ipGxNFJewx3cj5UZYRTzh5ypn2xRGMUnKTi",
            "symbol": "DMT",
            "name": "Pineal Renegades WL",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMTRrwYy8ipGxNFJewx3cj5UZYRTzh5ypn2xRGMUnKTi/logo.png",
            "tags": [
                "utility-token"
            ],
            "extensions": {
                "description": "The evolution of the authentication process of fine art. DMT",
                "discord": "https://discord.gg/pinealrenegades",
                "twitter": "https://twitter.com/PinealRenegade"
            }
        },
        {
            "chainId": 101,
            "address": "7AJRNAZFVe8Kfd6sVNmwAEnJ9LEGkqwvHpjV6MqjsiWw",
            "symbol": "DGZ",
            "name": "Degen Zer0ez",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7AJRNAZFVe8Kfd6sVNmwAEnJ9LEGkqwvHpjV6MqjsiWw/logo.png"
        },
        {
            "chainId": 101,
            "address": "GeJ3ZUT42ywiaj9jUJQr7XPpBWyrKgpmRsdaH6wamvJy",
            "symbol": "BTC",
            "name": "BTC Bitcoin",
            "decimals": 9,
            "logoURI": "https://raw.githubusercontent.com/BTCWALLETCOIN/BTC/main/assets/Bitcoin-BTC-icon%20(2).png",
            "tags": [
                "social-BTC"
            ]
        },
        {
            "chainId": 101,
            "address": "7Le24F69ULJomYyuPNdQGN3j6b1eWzZQdVG73dDce3Ev",
            "symbol": "TU-S",
            "name": "TU-Sofia",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/studenttusofia/Tu-Sofia/main/Logo-TU-blue-3D.png"
        },
        {
            "chainId": 101,
            "address": "EdFCpx5o7MYLbivpc7pmYazcZ2LsQfDuN8YtYaGokVG5",
            "symbol": "SWORD",
            "name": "SWORD token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdFCpx5o7MYLbivpc7pmYazcZ2LsQfDuN8YtYaGokVG5/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HbTGRmLYKR3FpteZgKDixhf9vKgkgXXq3Eg4kkDdAxsX",
            "symbol": "DRGN",
            "name": "DRGN token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HbTGRmLYKR3FpteZgKDixhf9vKgkgXXq3Eg4kkDdAxsX/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AyvLMr3x3L2ye2Gmb3Lm5F8sgutWm3kjSagVoaUqc8x5",
            "symbol": "KFC",
            "name": "KFC token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AyvLMr3x3L2ye2Gmb3Lm5F8sgutWm3kjSagVoaUqc8x5/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2QHx6MmrsAXSKLynJ55GofBbveYaDPLvn6qgdefey5za",
            "symbol": "GMT",
            "name": "GMT token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2QHx6MmrsAXSKLynJ55GofBbveYaDPLvn6qgdefey5za/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FDM3oFJ8tfr9VnZMFrFKvGLAUzaVhQyfgxv2YhkBjsBL",
            "symbol": "GST",
            "name": "GST token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FDM3oFJ8tfr9VnZMFrFKvGLAUzaVhQyfgxv2YhkBjsBL/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5mofayP9HdvG2eZBxMJdyHHyVmzEZDfPBYw5weRCVqfF",
            "symbol": "LFW",
            "name": "LFW token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5mofayP9HdvG2eZBxMJdyHHyVmzEZDfPBYw5weRCVqfF/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DmjFxVRPW9eVVRSFRWif4W3L27qccSRfy9EWA9E8uRfE",
            "symbol": "OCT",
            "name": "OCT token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DmjFxVRPW9eVVRSFRWif4W3L27qccSRfy9EWA9E8uRfE/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gqakp3W1HAChncCfQPS3YgdagaBYbC4voSFUfYQFRYxp",
            "symbol": "RENA",
            "name": "RENA token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gqakp3W1HAChncCfQPS3YgdagaBYbC4voSFUfYQFRYxp/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EnuMQfSqi7vWq69yF2popGkBg2M7ioUxZ2snT9igkuff",
            "symbol": "SAMO",
            "name": "SAMO token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EnuMQfSqi7vWq69yF2popGkBg2M7ioUxZ2snT9igkuff/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CK1ASJbXw8kWaL2TBYo9RutTo8rRoZTodVSTqDivqJe9",
            "symbol": "BRT",
            "name": "BRT token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CK1ASJbXw8kWaL2TBYo9RutTo8rRoZTodVSTqDivqJe9/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fxu6z7E8zfTznYksPGpw1qkxEwRQy9srr8gsWzbv6Z6P",
            "symbol": "FITFI",
            "name": "FITFI token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fxu6z7E8zfTznYksPGpw1qkxEwRQy9srr8gsWzbv6Z6P/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ChR6hCfGXoepQbF5WtKjtdTonfxfE7Aq3TXBJR2yXZ4r",
            "symbol": "ORBR",
            "name": "ORBR token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChR6hCfGXoepQbF5WtKjtdTonfxfE7Aq3TXBJR2yXZ4r/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DTthchfXvySKiA4TDffJ9zMfJeQ4LuptLpKniSXKzVkQ",
            "symbol": "RACA",
            "name": "RACA token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DTthchfXvySKiA4TDffJ9zMfJeQ4LuptLpKniSXKzVkQ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9vagRqeuePiHniXNR6uGQbMYJabbjqKQsokL5QNPRXT8",
            "symbol": "STEP",
            "name": "STEP token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vagRqeuePiHniXNR6uGQbMYJabbjqKQsokL5QNPRXT8/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "ABBniPgn4CUCBBjcW2EhenumA3kicQoTW5qfn7QdFzVm",
            "symbol": "MOOV",
            "name": "MOOV token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ABBniPgn4CUCBBjcW2EhenumA3kicQoTW5qfn7QdFzVm/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FpwnXczytURSz6eYukSj2UKQ1VrMiG4bS12sXpeZZTQs",
            "symbol": "DOSE",
            "name": "DOSE token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FpwnXczytURSz6eYukSj2UKQ1VrMiG4bS12sXpeZZTQs/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHsWGNYXFNh9SJN6cvqhhFp4ZAsEhUpKkYkaaFVUwThR",
            "symbol": "ALN",
            "name": "ALN token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHsWGNYXFNh9SJN6cvqhhFp4ZAsEhUpKkYkaaFVUwThR/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GMuHxwuCX6ZBeSWUYrvtqS8uTHVRXNEK7QVxNvUDmhkX",
            "symbol": "PAY",
            "name": "PAY token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GMuHxwuCX6ZBeSWUYrvtqS8uTHVRXNEK7QVxNvUDmhkX/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Fz52DPFWRM9uNu2Yh2oLVDWq4M5kDpBQoU3jg1ZQgVJC",
            "symbol": "100000X",
            "name": "100000X token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fz52DPFWRM9uNu2Yh2oLVDWq4M5kDpBQoU3jg1ZQgVJC/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EVNzPTXqQ7kyK9vVpnto21PCiWkmiBLb1kFtV4sXNvvE",
            "symbol": "DOG",
            "name": "DOG token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EVNzPTXqQ7kyK9vVpnto21PCiWkmiBLb1kFtV4sXNvvE/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7GUmDJcifBwi9jTJEVD53WQkk4AdNButpnPYFtKNyevb",
            "symbol": "DPET",
            "name": "DPET token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GUmDJcifBwi9jTJEVD53WQkk4AdNButpnPYFtKNyevb/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "29SjUVVLEn4f9z2dpAaWPE4VvhiY7idMiSwLPCcXgKK6",
            "symbol": "1000ELON",
            "name": "1000ELON token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/29SjUVVLEn4f9z2dpAaWPE4VvhiY7idMiSwLPCcXgKK6/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EEMHAvDepm58TPpJDtj34xMKyk2XxPYu4HeLqbEbPLcC",
            "symbol": "KINGSHIB",
            "name": "KINGSHIB token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EEMHAvDepm58TPpJDtj34xMKyk2XxPYu4HeLqbEbPLcC/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DxtKsGtacUb9AGV9RwmEt6P8ahhkNSMCZdnv1RpRYSv6",
            "symbol": "LUS",
            "name": "LUS token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DxtKsGtacUb9AGV9RwmEt6P8ahhkNSMCZdnv1RpRYSv6/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "GYYViEaPHFHeeka88pR33GA4QrU9G9q13tmEz5ZHo3Kr",
            "symbol": "1000AKITA",
            "name": "1000AKITA token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GYYViEaPHFHeeka88pR33GA4QrU9G9q13tmEz5ZHo3Kr/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "Gu62HyfNFVPrbUx74n3qayDJWKaFPd3UJcGG8WLcgHq9",
            "symbol": "ALICE",
            "name": "ALICE token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gu62HyfNFVPrbUx74n3qayDJWKaFPd3UJcGG8WLcgHq9/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "EHSSivVbRsuXymnSSvF4ctNgGs6J1Km7dNpyw4Xrmvcw",
            "symbol": "MBOX",
            "name": "MBOX token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EHSSivVbRsuXymnSSvF4ctNgGs6J1Km7dNpyw4Xrmvcw/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "D9Swj9JRoJ7iDBQYp3CENRf1HZsvyEM7ZDHV11Sapheu",
            "symbol": "MONS",
            "name": "MONS token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D9Swj9JRoJ7iDBQYp3CENRf1HZsvyEM7ZDHV11Sapheu/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5QKuk1XoAePenzWkq3JdunTgAAriL45JSxPGEZib5mY1",
            "symbol": "10SAFEMOON",
            "name": "10SAFEMOON token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5QKuk1XoAePenzWkq3JdunTgAAriL45JSxPGEZib5mY1/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "JDQwFwLuudcRJSqeYZJgKT5XhuSgNLHapjr2ZTYTYNRB",
            "symbol": "SPELLFIRE",
            "name": "SPELLFIRE token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JDQwFwLuudcRJSqeYZJgKT5XhuSgNLHapjr2ZTYTYNRB/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "9AaKcdu5eymQwR19qwYFsqiqcXgL3iAxMQyshRpbqg6z",
            "symbol": "SRM",
            "name": "SRM token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9AaKcdu5eymQwR19qwYFsqiqcXgL3iAxMQyshRpbqg6z/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "7uYyHx7CHcRBzYCoQj7NfW2qora7NnNYRBY6CSjQJ9wo",
            "symbol": "XWG",
            "name": "XWG token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uYyHx7CHcRBzYCoQj7NfW2qora7NnNYRBY6CSjQJ9wo/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "B7wpoTERtwZ8wMcSGPew9dAQcwW9ab3hQjh8caghuErK",
            "symbol": "GOLD",
            "name": "GOLD token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B7wpoTERtwZ8wMcSGPew9dAQcwW9ab3hQjh8caghuErK/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "BCYTxCKsKUsRMBPH9VzeC35U4NZBg6JxjFe2iNR43ewm",
            "symbol": "THG",
            "name": "THG token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BCYTxCKsKUsRMBPH9VzeC35U4NZBg6JxjFe2iNR43ewm/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "2SJUtWahbkSAw7ZyDiSVLGvknXNKCBr395bNi2QqJYxQ",
            "symbol": "DOGE",
            "name": "DOGE token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2SJUtWahbkSAw7ZyDiSVLGvknXNKCBr395bNi2QqJYxQ/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AhMJmyAB7K4ciVBXNNtaEmP4d8ewJNDAT6xhWgqqH2eT",
            "symbol": "SHIB",
            "name": "SHIB token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AhMJmyAB7K4ciVBXNNtaEmP4d8ewJNDAT6xhWgqqH2eT/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HYf2xLNT5rLqytSrrg5asAaL3WxuEiYxp2YMnSSrxf7K",
            "symbol": "APEINU",
            "name": "APEINU token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYf2xLNT5rLqytSrrg5asAaL3WxuEiYxp2YMnSSrxf7K/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6U2TwCcYnQSP6nMuG3YoJRGiSxHThadwhbidUP2Mucha",
            "symbol": "DORA",
            "name": "DORA token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6U2TwCcYnQSP6nMuG3YoJRGiSxHThadwhbidUP2Mucha/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "CtzEtECi1RhhLBZFCBk7RZjpRvpHYUwmxXMdL41QJ8Se",
            "symbol": "FAR",
            "name": "FAR token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CtzEtECi1RhhLBZFCBk7RZjpRvpHYUwmxXMdL41QJ8Se/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "795k81ZeCCkm3pqH4jVskVW7SgmkexhV3v4pWya5eEfK",
            "symbol": "FLM",
            "name": "FLM token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/795k81ZeCCkm3pqH4jVskVW7SgmkexhV3v4pWya5eEfK/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "FAXztAoS7NNfwHWGzXDmRKDm9hzaxVQH3kEE2zo1BHj2",
            "symbol": "GOLDMINER",
            "name": "GOLDMINER token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FAXztAoS7NNfwHWGzXDmRKDm9hzaxVQH3kEE2zo1BHj2/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "HDkX8rMuQjBMe5NXLzFCCShbhBwwbjGaKwuTWxRxUaon",
            "symbol": "HE",
            "name": "HE token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDkX8rMuQjBMe5NXLzFCCShbhBwwbjGaKwuTWxRxUaon/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6e6HVPTsT4Dt8EK5q5S5PXL1iMVeVrwrTL7rqtDyvqNs",
            "symbol": "KWS",
            "name": "KWS token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6e6HVPTsT4Dt8EK5q5S5PXL1iMVeVrwrTL7rqtDyvqNs/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "41WCpp1epP67M2FDgtvq73p8Z2boJtrxhvUHFfGQYCWf",
            "symbol": "SFM",
            "name": "SFM token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/41WCpp1epP67M2FDgtvq73p8Z2boJtrxhvUHFfGQYCWf/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "6HSEFZh3sWPqWxn1ouHfdpKqvpdNUXMjUgX8VJA7Q63x",
            "symbol": "BNX",
            "name": "BNX token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6HSEFZh3sWPqWxn1ouHfdpKqvpdNUXMjUgX8VJA7Q63x/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "5d9AGGUNeyiFwo13WpWzYoWXK5W7uSzWYZLiCmoUocDC",
            "symbol": "KP3R",
            "name": "KP3R token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5d9AGGUNeyiFwo13WpWzYoWXK5W7uSzWYZLiCmoUocDC/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DWWk3kX2PBFi8Mdeueq8Quhy38CcsCpgMvQPoAeaB6D1",
            "symbol": "REVOLAND",
            "name": "REVOLAND token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DWWk3kX2PBFi8Mdeueq8Quhy38CcsCpgMvQPoAeaB6D1/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "AtYcMWksYseUs4thuh1dw734ALd8XpTEBLGNjtZsz6YX",
            "symbol": "SASHIMI",
            "name": "SASHIMI token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AtYcMWksYseUs4thuh1dw734ALd8XpTEBLGNjtZsz6YX/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "3ktQNv8ELGGrJxQytnMR2JMzmUrTvswtbG538xT4visY",
            "symbol": "GAE",
            "name": "GAE token coin",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3ktQNv8ELGGrJxQytnMR2JMzmUrTvswtbG538xT4visY/logo.png",
            "tags": [
                "utility-token"
            ]
        },
        {
            "chainId": 101,
            "address": "DHs9mG4EQqq1pvXFHt8HZuvZQCr6Xi5GjGbASsj4TNZm",
            "symbol": "JFRM",
            "name": "Jeffrium",
            "decimals": 0,
            "logoURI": "https://raw.githubusercontent.com/jeffreydrew/pandacoin/main/pandafinal.png",
            "tags": [
                "social-token"
            ]
        }
    ],
    "version": {
        "major": 0,
        "minor": 3,
        "patch": 3
    }
}
