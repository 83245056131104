"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChildren = exports.getRealms = exports.getRealmFromEntry = exports.getEntryAddressFromEntry = exports.getRealmAddressFromName = exports.getEntryAddressFromName = void 0;
const umi_1 = require("@metaplex-foundation/umi");
const client_1 = require("../client");
const umi_web3js_adapters_1 = require("@metaplex-foundation/umi-web3js-adapters");
const GOVERNANCE_ENTRY_DISCRIMINANT = Uint8Array.from([107, 36, 237, 168, 199, 164, 138, 165]);
const deserilizer = (0, client_1.getGovernanceEntryAccountDataSerializer)();
const getEntryAddressFromName = (umi, name, config, governanceProgram) => {
    const [realmEntryAddress, __] = umi.eddsa.findPda(client_1.GOV_BOARDING_PROGRAM_ID, [Buffer.from("entry"),
        Buffer.from(name),
        (0, umi_1.publicKeyBytes)(config),
        (0, umi_1.publicKeyBytes)(governanceProgram)
    ]);
    return realmEntryAddress;
};
exports.getEntryAddressFromName = getEntryAddressFromName;
const getRealmAddressFromName = (umi, name, governanceProgram) => {
    const [realmAddress, _] = umi.eddsa.findPda(governanceProgram, [Buffer.from("governance"),
        Buffer.from(name)]);
    return realmAddress;
};
exports.getRealmAddressFromName = getRealmAddressFromName;
const getEntryAddressFromEntry = (umi, entry) => {
    return (0, exports.getEntryAddressFromName)(umi, entry.name, entry.config, entry.governanceProgram);
};
exports.getEntryAddressFromEntry = getEntryAddressFromEntry;
const getRealmFromEntry = (umi, entry) => {
    return (0, exports.getRealmAddressFromName)(umi, entry.name, entry.governanceProgram);
};
exports.getRealmFromEntry = getRealmFromEntry;
const getRealms = (umi, config, requestStatus) => __awaiter(void 0, void 0, void 0, function* () {
    const accounts = yield umi.rpc.getProgramAccounts(client_1.GOV_BOARDING_PROGRAM_ID, {
        filters: [
            {
                memcmp: {
                    offset: 0,
                    bytes: GOVERNANCE_ENTRY_DISCRIMINANT,
                },
            },
            {
                memcmp: {
                    offset: 8,
                    bytes: (0, umi_web3js_adapters_1.toWeb3JsPublicKey)(config).toBuffer(),
                }
            },
            {
                memcmp: {
                    offset: 73,
                    bytes: new Uint8Array([requestStatus]),
                }
            }
        ]
    });
    return accounts.map((account) => {
        return deserilizer.deserialize(account.data)[0];
    });
});
exports.getRealms = getRealms;
const getChildren = (umi, config, parentEntry, requestStatus) => __awaiter(void 0, void 0, void 0, function* () {
    const realmAccount = yield umi.rpc.getAccount(parentEntry);
    let result = [];
    if (realmAccount.exists) {
        const parentData = deserilizer.deserialize(realmAccount.data)[0];
        const childRealms = (0, umi_1.unwrapOption)(parentData.childRealms);
        if (childRealms == null) {
            return [];
        }
        const childAccounts = yield umi.rpc.getAccounts(childRealms);
        const parentRealm = (0, exports.getRealmFromEntry)(umi, parentData);
        for (const childEntryRawData of childAccounts.map((a) => {
            if (a.exists) {
                return a.data;
            }
            else {
                return null;
            }
        }).filter((a) => a != null)) {
            const childEntryData = deserilizer.deserialize(childEntryRawData)[0];
            const parentLinks = (0, umi_1.unwrapOption)(childEntryData.parentLinks);
            if (parentLinks == null) {
                return [];
            }
            console.log("Parent links", parentLinks);
            console.log("parent entry", parentEntry);
            const relevantParentLink = parentLinks.realms.find(pl => pl.parentRealm === parentRealm);
            console.log("Relevant parent link", relevantParentLink);
            if (relevantParentLink == null) {
                return [];
            }
            if (relevantParentLink.status === requestStatus && childEntryData.config == config) {
                result.push(childEntryData);
            }
        }
    }
    return result;
});
exports.getChildren = getChildren;
