import { jsx, jsxs } from 'react/jsx-runtime';
import 'react';
import { Typography } from '../../components/Typography/Typography.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgClose } from '../../shared/assets/close.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../utils/constants/colors.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/multi-wallet';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../context/ThemeContext/ThemeContext.js';
import 'react-dom';
import 'react-focus-lock';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../config/ApiEndpoint.js';
import '@dynamic-labs/rpc-providers';
import '../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import '../../context/MockContext/MockContext.js';
import '../../context/QrCodeContext/QrCodeContext.js';
import 'react-i18next';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../context/LoadingContext/LoadingContext.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import 'yup';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import 'formik';
import { Button } from '../../components/Button/Button.js';
import { ModalHeader } from '../../components/ModalHeader/ModalHeader.js';
import { IconButton } from '../../components/IconButton/IconButton.js';
import '../../components/Alert/Alert.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/MenuList/Dropdown/Dropdown.js';

const BridgeNextWalletToConnectLayout = ({ chainName, chainIcon, onClickAction, onClickClose }) => {
    const closeButton = onClickClose && (jsx(IconButton, { type: 'button', onClick: onClickClose, "data-testid": 'close-button', children: jsx(SvgClose, {}) }));
    return (jsxs("div", { className: 'bridge-next-wallet-to-connect-layout', children: [jsx(ModalHeader, { trailing: closeButton, alignContent: 'bottom', children: chainIcon && (jsx("div", { className: 'bridge-next-wallet-to-connect-layout__chain-icon', children: chainIcon })) }), jsxs("div", { className: 'bridge-next-wallet-to-connect-layout__body', children: [jsxs(Typography, { color: 'primary', variant: 'title', children: ["Nice! Now let\u2019s connect your ", chainName, " wallet"] }), jsx(Button, { expanded: true, buttonPadding: 'large', onClick: onClickAction, children: `Connect ${chainName} wallet` })] })] }));
};

export { BridgeNextWalletToConnectLayout };
