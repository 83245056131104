"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataStreamFilters = exports.dataStreamCompositeFilter = void 0;
const dataStreamTextFilter = {
    equals: (value) => {
        return { operator: "==", values: [value], type: "TEXT" };
    },
    notEquals: (value) => {
        return { operator: "!=", values: [value], type: "TEXT" };
    },
    or: (...filters) => {
        return { type: "OR", filters };
    },
};
const dataStreamEnumFilter = {
    equals: (value) => {
        return { operator: "==", values: [value], type: "ENUM" };
    },
    notEquals: (value) => {
        return { operator: "!=", values: [value], type: "ENUM" };
    },
    or: (...filters) => {
        return { type: "OR", filters };
    },
};
const dataStreamDateFilter = {
    equals: (value) => {
        return { operator: "==", values: [value.getTime() / 1000], type: "DATE" };
    },
    notEquals: (value) => {
        return { operator: "!=", values: [value.getTime() / 1000], type: "DATE" };
    },
    greaterThan: (value) => {
        return { operator: ">", values: [value.getTime() / 1000], type: "DATE" };
    },
    greaterThanEquals: (value) => {
        return { operator: ">=", values: [value.getTime() / 1000], type: "DATE" };
    },
    lessThan: (value) => {
        return { operator: "<", values: [value.getTime() / 1000], type: "DATE" };
    },
    lessThanEquals: (value) => {
        return { operator: "<=", values: [value.getTime() / 1000], type: "DATE" };
    },
    or: (...filters) => {
        return { type: "OR", filters };
    },
    between: (min, max) => {
        return {
            operator: "between",
            values: [min.getTime() / 1000, max.getTime() / 1000],
            type: "DATE",
        };
    },
};
const dataStreamBooleanFilter = {
    equals: (value) => {
        return { operator: "==", values: [value], type: "BOOLEAN" };
    },
};
const dataStreamNumericFilter = {
    equals: (value) => {
        return { operator: "==", values: [value], type: "NUMERIC" };
    },
    notEquals: (value) => {
        return { operator: "!=", values: [value], type: "NUMERIC" };
    },
    greaterThan: (value) => {
        return { operator: ">", values: [value], type: "NUMERIC" };
    },
    greaterThanEquals: (value) => {
        return { operator: ">=", values: [value], type: "NUMERIC" };
    },
    lessThan: (value) => {
        return { operator: "<", values: [value], type: "NUMERIC" };
    },
    lessThanEquals: (value) => {
        return { operator: "<=", values: [value], type: "NUMERIC" };
    },
    between: (min, max) => {
        return { operator: "between", values: [min, max], type: "NUMERIC" };
    },
    or: (...filters) => {
        return { type: "OR", filters };
    },
    and: (...filters) => {
        return { type: "AND", filters };
    },
};
const dataStreamCompositeFilter = (filter, fields) => {
    return {
        type: "COMPOSITE",
        filter,
        fields,
    };
};
exports.dataStreamCompositeFilter = dataStreamCompositeFilter;
exports.dataStreamFilters = {
    boolean: dataStreamBooleanFilter,
    composite: exports.dataStreamCompositeFilter,
    date: dataStreamDateFilter,
    numeric: dataStreamNumericFilter,
    text: dataStreamTextFilter,
    enum: dataStreamEnumFilter,
    is: {
        and: (value) => value.type === "AND",
        boolean: (value) => value.type === "BOOLEAN",
        composite: (value) => value.type === "COMPOSITE",
        date: (value) => value.type === "DATE",
        numeric: (value) => value.type === "NUMERIC",
        or: (value) => value.type === "OR",
        text: (value) => value.type === "TEXT",
        enum: (value) => value.type === "ENUM",
    },
};
