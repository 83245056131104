import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import 'react';
import '@dynamic-labs/wallet-book';
import { shortenWalletAddress } from '../../shared/utils/functions/shortenWalletAddress/shortenWalletAddress.js';
import '@dynamic-labs/utils';
import '../../utils/constants/colors.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import { useFetchNameService } from '../../widgets/DynamicWidget/hooks/useFetchNameService/useFetchNameService.js';
import { StatusDot } from '../StatusDot/StatusDot.js';
import { Typography } from '../Typography/Typography.js';
import '../../config/ApiEndpoint.js';
import '@dynamic-labs/multi-wallet';
import '@dynamic-labs/rpc-providers';
import '../../utils/hooks/useWallets/utils/verifyMagicIsSetup/verifyMagicIsSetup.js';
import '../../context/DynamicContext/DynamicContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import 'react-dom';
import '../ShadowDOM/ShadowDOM.js';
import '../../context/ThemeContext/ThemeContext.js';
import 'react-focus-lock';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../IconButton/IconButton.js';
import '../Alert/Alert.js';
import 'react-i18next';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'formik';
import 'yup';
import '../InlineWidget/InlineWidget.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../OverlayCard/OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import '../../context/MockContext/MockContext.js';
import '../../context/QrCodeContext/QrCodeContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { usePromise } from '../../utils/hooks/usePromise/usePromise.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'qrcode';
import '../../context/LoadingContext/LoadingContext.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/types';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';

const UserAddress = ({ userProfile, walletConnector }) => {
    var _a;
    const walletAddress = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.verifiedCredentials.find((verifiedCredential) => verifiedCredential.id === userProfile.lastVerifiedCredentialId)) === null || _a === void 0 ? void 0 : _a.address;
    const nameService = useFetchNameService(walletAddress);
    const { data: walletConnectorAddress } = usePromise(() => walletConnector === null || walletConnector === void 0 ? void 0 : walletConnector.fetchPublicAddress(), {
        enabled: !userProfile && Boolean(walletConnector),
    });
    return (jsxs("div", { className: 'user-address__container', children: [jsx(StatusDot, { variant: 'green', containerClassName: 'user-address__status-dot' }), (nameService === null || nameService === void 0 ? void 0 : nameService.name) ? (jsxs(Fragment, { children: [jsx(Typography, { variant: 'numbers_big', color: 'primary', className: 'user-address', children: nameService.name }), jsx("div", { className: 'dynamic-widget-wallet-header__seperator' }), jsx(Typography, { variant: 'numbers_medium', color: 'secondary', className: 'user-address', children: shortenWalletAddress(walletAddress, 3, 3) })] })) : (jsx(Typography, { variant: 'numbers_big', color: 'primary', className: 'user-address', children: shortenWalletAddress(walletAddress || walletConnectorAddress, 4, 4) }))] }));
};

export { UserAddress };
